import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";
import {
  buildQueryString,
  parseParams,
  alertMessage
} from "@utils/function";
import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";

// get list data đội trưởng ký bảng kê
export const handleListDataDTKBK = (
  setLoading,
  location,
  setDataDTKBK,
  setTempDataDTKBK,
  setTotalCount,
  setTempTotalCount,
  filterConditions,
  setSelectedRowKeys
) => {
  let url = "";
  url = `${Endpoint.LIST_DOI_TRUONG_KBK}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
    ? parseParams(location.search)
    : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      setSelectedRowKeys([]);
      if (res.data !== null) {
        setDataDTKBK(res.data);
        setTempDataDTKBK(res.data);
        setTotalCount(res.paging.totalCount);
        setTempTotalCount(res.paging.totalCount);
      } else setDataDTKBK([]);
    },
  });
};

export const submitDoiTruongXacNhan = (
  t,
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.DOI_TRUONG_XAC_NHAN,
    method: METHOD_PUT,
    payload: {
      id: selectedRowKeys,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const submitDTKyHSM = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.GCS_DT_KY_HSM,
    method: METHOD_PUT,
    payload: {
      id: selectedRowKeys,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
      }
    },
  });
};

//đội trưởng trả lại bảng kê
export const handleDoiTruongTraLaiBK = (
  id,
  values,
  setLoading,
  setFetchDataTable,
  closeModal,
  t,
) => {
  authPostData({
    url: Endpoint.DOI_TRUONG_TRA_LAI_BK,
    method: METHOD_PUT,
    payload: {
      id: id,
      lyDoTraLai: values.lyDoTraLai
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        closeModal();
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};