import { Col, Row, Input, Button, Form, Typography } from "antd";
import React from "react";
import LoadingComponent from "@components/Loading";
import { debounce } from "lodash";
import FormComponent from "@components/Form";
import { ContainerFormInput, TableBody, TableHeader } from "../../css-styled";
import InputFormatter from "@components/InputFormatter";
import { TYPE_INPUT } from "@utils/constants";

export default function TableThongTinVatTu(props) {
  const {
    dataBB,
    dataBM,
    dataTH,
    t,
    dispatch,
    closeModal,
    loading,
    handleChangeBB,
    handleChangeBM,
    handleChangeTH,
    form,
    onFinishThongTin,
    handleFieldChange,
  } = props;

  return (
    <LoadingComponent loading={loading}>
      <FormComponent
        form={form}
        autoComplete="off"
        name="thongTinVatTu"
        onFinish={onFinishThongTin}
        initialValues={
          {
            // ...dataCongTo,
          }
        }
        scrollToFirstError
        layout="vertical"
        onValuesChange={debounce(handleFieldChange, 200)}
      >
        <Row gutters={24}>
          <Col span={24} md={24}>
            <h5 className="fs-14px font-bold">Danh sách vật tư bên bán</h5>
          </Col>
          <Col span={24} md={24}>
            <div className="table-fixed">
              <TableHeader>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell" width="10%">
                      STT
                    </th>
                    <th className="ant-table-cell" width="30%">
                      Chủng loại vật tư
                    </th>
                    <th className="ant-table-cell" width="10%">
                      Đơn vị tính
                    </th>
                    <th className="ant-table-cell" width="15%">
                      Dự toán
                    </th>
                    <th className="ant-table-cell" width="15%">
                      Thực tế
                    </th>
                    <th className="ant-table-cell" width="20%">
                      Chênh lệch
                    </th>
                  </tr>
                </thead>
              </TableHeader>
              <TableBody>
                <table>
                  <tbody className="ant-table-tbody">
                    {dataBB.map((item, index) => (
                      <tr
                        data-row-key={index}
                        key={index}
                        className={`ant-table-row ant-table-row-level-${index} editable-row`}
                      >
                        <Form.Item name={`duToanBBId-${index}`} hidden>
                          <Input value={item[`duToanBBId-${index}`]} />
                        </Form.Item>
                        <Form.Item name={`isVatTuNgoaiBB-${index}`} hidden>
                          <Input value={item[`isVatTuNgoaiBB-${index}`]} />
                        </Form.Item>
                        <td
                          className="ant-table-cell table-text-center"
                          width="10%"
                        >
                          <span>{item.stt}</span>
                        </td>
                        <td className="ant-table-cell" width="30%">
                          <span>{item.chungLoaiVatTu}</span>
                        </td>
                        <td
                          className="ant-table-cell table-text-center"
                          width="10%"
                        >
                          <span>{item.donViTinh}</span>
                        </td>
                        <td
                          className="ant-table-cell table-text-right"
                          width="15%"
                        >
                          <span>{item.soLuongDuToan}</span>
                        </td>
                        <td className="ant-table-cell" width="15%">
                          <ContainerFormInput name={`thucTeBB-${index}`}>
                            <InputFormatter
                              defaultValue={item.thucTe}
                              onChange={(value) =>
                                handleChangeBB(item, index, value)
                              }
                              className="input-number-right"
                              controls={false}
                              type={TYPE_INPUT.DECIMAL}
                            />
                          </ContainerFormInput>
                        </td>
                        <td
                          className="ant-table-cell"
                          width="20%"
                          style={{ textAlign: "right" }}
                        >
                          <ContainerFormInput
                            // name={`chechLechBB-${index}`}
                            shouldUpdate={(prevValues, curValues) =>
                              prevValues[`chechLechBB-${index}`] !==
                              curValues[`chechLechBB-${index}`]
                            }
                          >
                            {({ getFieldValue }) => {
                              const value =
                                getFieldValue(`chechLechBB-${index}`) ||
                                undefined;
                              const thucTe =
                                getFieldValue(`thucTeBB-${index}`) || undefined;
                              const duToan = item.soLuongDuToan;

                              return value ? (
                                <Typography.Text
                                  className="ant-form-text"
                                  strong
                                  level={5}
                                  type={
                                    Number(value) !== 0 &&
                                    Number(thucTe) > Number(duToan)
                                      ? "danger"
                                      : "default"
                                  }
                                >
                                  {value}
                                </Typography.Text>
                              ) : (
                                <Typography.Text
                                  className="ant-form-text"
                                  strong
                                  level={5}
                                  type={
                                    Number(
                                      Math.round(
                                        (item.soLuongDuToan -
                                          item[`thucTeBB-${index}`]) *
                                          1000
                                      ) / 1000
                                    ) !== 0
                                      ? "danger"
                                      : "default"
                                  }
                                >
                                  {Math.round(
                                    (item.soLuongDuToan -
                                      item[`thucTeBB-${index}`]) *
                                      1000
                                  ) / 1000}
                                </Typography.Text>
                              );
                            }}
                          </ContainerFormInput>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TableBody>
            </div>
          </Col>
        </Row>

        <Row gutters={24} className="mt-16px">
          <Col span={24} md={24}>
            <h5 className="fs-14px font-bold">Danh sách vật tư bên mua</h5>
          </Col>
          <Col span={24} md={24}>
            <div className="table-fixed">
              <TableHeader>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell" width="10%">
                      STT
                    </th>
                    <th className="ant-table-cell" width="30%">
                      Chủng loại vật tư
                    </th>
                    <th className="ant-table-cell" width="10%">
                      Đơn vị tính
                    </th>
                    <th className="ant-table-cell" width="15%">
                      Dự toán
                    </th>
                    <th className="ant-table-cell" width="15%">
                      Thực tế
                    </th>
                    <th className="ant-table-cell" width="20%">
                      Chênh lệch
                    </th>
                  </tr>
                </thead>
              </TableHeader>
              <TableBody>
                <table>
                  <tbody className="ant-table-tbody">
                    {dataBM.map((item, index) => (
                      <tr
                        data-row-key={index}
                        key={index}
                        className={`ant-table-row ant-table-row-level-${index} editable-row`}
                      >
                        <Form.Item name={`duToanBMId-${index}`} hidden>
                          <Input value={item[`duToanBMId-${index}`]} />
                        </Form.Item>
                        <Form.Item name={`isVatTuNgoaiBM-${index}`} hidden>
                          <Input value={item[`isVatTuNgoaiBM-${index}`]} />
                        </Form.Item>
                        <td
                          className="ant-table-cell table-text-center"
                          width="10%"
                        >
                          <span>{item.stt}</span>
                        </td>
                        <td className="ant-table-cell" width="30%">
                          <span>{item.chungLoaiVatTu}</span>
                        </td>
                        <td
                          className="ant-table-cell table-text-center"
                          width="10%"
                        >
                          <span>{item.donViTinh}</span>
                        </td>
                        <td
                          className="ant-table-cell table-text-right"
                          width="15%"
                        >
                          <span>{item.soLuongDuToan}</span>
                        </td>
                        <td className="ant-table-cell" width="15%">
                          <ContainerFormInput name={`thucTeBM-${index}`}>
                            <InputFormatter
                              defaultValue={item.thucTe}
                              onChange={(value) =>
                                handleChangeBM(item, index, value, item.thucTe)
                              }
                              className="input-number-right"
                              controls={false}
                              type={TYPE_INPUT.DECIMAL}
                            />
                          </ContainerFormInput>
                        </td>
                        <td
                          className="ant-table-cell"
                          width="20%"
                          style={{ textAlign: "right" }}
                        >
                          <ContainerFormInput
                            // name={`chechLechBM-${index}`}
                            shouldUpdate={(prevValues, curValues) =>
                              prevValues[`chechLechBM-${index}`] !==
                              curValues[`chechLechBM-${index}`]
                            }
                          >
                            {({ getFieldValue }) => {
                              const value =
                                getFieldValue(`chechLechBM-${index}`) ||
                                undefined;
                              const thucTe =
                                getFieldValue(`thucTeBM-${index}`) || undefined;
                              const duToan = item.soLuongDuToan;
                              return value ? (
                                <Typography.Text
                                  className="ant-form-text"
                                  strong
                                  level={5}
                                  type={
                                    Number(value) !== 0 &&
                                    Number(thucTe) > Number(duToan)
                                      ? "danger"
                                      : "default"
                                  }
                                >
                                  {value}
                                </Typography.Text>
                              ) : (
                                <Typography.Text
                                  className="ant-form-text"
                                  strong
                                  level={5}
                                  type={
                                    Number(
                                      Math.round(
                                        (item.soLuongDuToan -
                                          item[`thucTeBM-${index}`]) *
                                          1000
                                      ) / 1000
                                    ) !== 0
                                      ? "danger"
                                      : "default"
                                  }
                                >
                                  {Math.round(
                                    (item.soLuongDuToan -
                                      item[`thucTeBM-${index}`]) *
                                      1000
                                  ) / 1000}
                                </Typography.Text>
                              );
                            }}
                          </ContainerFormInput>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TableBody>
            </div>
          </Col>
        </Row>

        <Row gutters={24} className="mt-16px">
          <Col span={24} md={24}>
            <h5 className="fs-14px font-bold">Danh sách vật tư thu hồi</h5>
          </Col>
          <Col span={24} md={24}>
            <div className="table-fixed">
              <TableHeader>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell" width="10%">
                      STT
                    </th>
                    <th className="ant-table-cell" width="30%">
                      Chủng loại vật tư
                    </th>
                    <th className="ant-table-cell" width="10%">
                      Đơn vị tính
                    </th>
                    <th className="ant-table-cell" width="15%">
                      Dự toán
                    </th>
                    <th className="ant-table-cell" width="15%">
                      Thực tế
                    </th>
                    <th className="ant-table-cell" width="20%">
                      Chênh lệch
                    </th>
                  </tr>
                </thead>
              </TableHeader>
              <TableBody>
                <table>
                  <tbody className="ant-table-tbody">
                    {dataTH.map((item, index) => (
                      <tr
                        data-row-key={index}
                        key={index}
                        className={`ant-table-row ant-table-row-level-${index} editable-row`}
                      >
                        <Form.Item name={`duToanTHId-${index}`} hidden>
                          <Input value={item[`duToanTHId-${index}`]} />
                        </Form.Item>
                        <Form.Item name={`isVatTuNgoaiTH-${index}`} hidden>
                          <Input value={item[`isVatTuNgoaiTH-${index}`]} />
                        </Form.Item>
                        <td
                          className="ant-table-cell table-text-center"
                          width="10%"
                        >
                          <span>{item.stt}</span>
                        </td>
                        <td className="ant-table-cell" width="30%">
                          <span>{item.chungLoaiVatTu}</span>
                        </td>
                        <td
                          className="ant-table-cell table-text-center"
                          width="10%"
                        >
                          <span>{item.donViTinh}</span>
                        </td>
                        <td
                          className="ant-table-cell table-text-right"
                          width="15%"
                        >
                          <span>{item.soLuongDuToan}</span>
                        </td>
                        <td className="ant-table-cell" width="15%">
                          <ContainerFormInput name={`thucTeTH-${index}`}>
                            <InputFormatter
                              defaultValue={item.thucTe}
                              onChange={(value) =>
                                handleChangeTH(item, index, value, item.thucTe)
                              }
                              className="input-number-right"
                              controls={false}
                              type={TYPE_INPUT.DECIMAL}
                            />
                          </ContainerFormInput>
                        </td>
                        <td
                          className="ant-table-cell"
                          width="20%"
                          style={{ textAlign: "right" }}
                        >
                          <ContainerFormInput
                            // name={`chechLechTH-${index}`}
                            shouldUpdate={(prevValues, curValues) =>
                              prevValues[`chechLechTH-${index}`] !==
                              curValues[`chechLechTH-${index}`]
                            }
                          >
                            {({ getFieldValue }) => {
                              const value =
                                getFieldValue(`chechLechTH-${index}`) ||
                                undefined;
                              const thucTe =
                                getFieldValue(`thucTeTH-${index}`) || undefined;
                              const duToan = item.soLuongDuToan;
                              return value ? (
                                <Typography.Text
                                  className="ant-form-text"
                                  strong
                                  level={5}
                                  type={
                                    Number(value) !== 0 &&
                                    Number(thucTe) > Number(duToan)
                                      ? "danger"
                                      : "default"
                                  }
                                >
                                  {value}
                                </Typography.Text>
                              ) : (
                                <Typography.Text
                                  className="ant-form-text"
                                  strong
                                  level={5}
                                  type={
                                    Number(
                                      Math.round(
                                        (item.soLuongDuToan -
                                          item[`thucTeTH-${index}`]) *
                                          1000
                                      ) / 1000
                                    ) !== 0
                                      ? "danger"
                                      : "default"
                                  }
                                >
                                  {Math.round(
                                    (item.soLuongDuToan -
                                      item[`thucTeTH-${index}`]) *
                                      1000
                                  ) / 1000}
                                </Typography.Text>
                              );
                            }}
                          </ContainerFormInput>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TableBody>
            </div>
          </Col>
        </Row>
        <div className="ant-modal-footer text-center">
          <Button type="default" onClick={() => dispatch(closeModal())}>
            {t("button.dong_lai")}
          </Button>
          <Button
            key="submit"
            type="primary"
            className="button-primary"
            htmlType="submit"
            form="thongTinVatTu"
            // onClick={() =>
            //   handleSubmitThongTin(setLoading, dataBB, dataBM, dataTH)
            // }
          >
            Cập nhật vật tư
          </Button>
        </div>
      </FormComponent>
    </LoadingComponent>
  );
}
