import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import FormTheoMucDich from "./form/form-theo-muc-dich";

export default function CSSuDungDienPanels(props) {
  const { detailCongSuat } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [data] = useState({
    tongCongSuatLapDat: 0,
    congSuatSuDungCaoNhat: 0,
    capDienApSuDung: 0,
    congSuatSuDungTrungBinh: 0,
  });
  useEffect(() => {
    if (detailCongSuat && Object.keys(detailCongSuat).length > 0) {
      form.setFieldsValue({
        tongCongSuatLapDat: detailCongSuat.tongCongSuatLapDat
          ? detailCongSuat.tongCongSuatLapDat
          : 0,
        congSuatSuDungCaoNhat: detailCongSuat.congSuatSuDungCaoNhat
          ? detailCongSuat.congSuatSuDungCaoNhat
          : 0,
        capDienApSuDung: detailCongSuat.capDienApSuDung
          ? detailCongSuat.capDienApSuDung
          : 0,
        congSuatSuDungTrungBinh: detailCongSuat.congSuatSuDungTrungBinh
          ? detailCongSuat.congSuatSuDungTrungBinh
          : 0,
      });
    } else {
      form.setFieldsValue({ ...data });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailCongSuat]);
  return <FormTheoMucDich form={form} t={t} />;
}
