import React, { useCallback } from "react";
import { Form, Modal, Input, Row, Col } from "antd";

import { Endpoint } from "@utils/endpoint";
import Selection from "@components/Select";
import ButtonComponent from "@components/Button";
function ModalCreateMenuItem(props) {
  const { visible, close, moduleSelected, addMenuItem } = props;
  const [form] = Form.useForm();
  const onFinish = useCallback(
    (values) => {
      addMenuItem(values);
      close();
    },
    [addMenuItem, close]
  );
  return (
    <Modal visible={visible} title="Thêm mới" onCancel={close} footer={false}>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          name="menuName"
          label="Tên menu"
          rules={[
            {
              required: true,
              message: "Tên menu không được để trống",
            },
          ]}
        >
          <Input type="text" size="default" />
        </Form.Item>
        <Form.Item
          name="pageId"
          label="Chọn trang"
          rules={[
            {
              required: true,
              message: "Trang không được để trống",
            },
          ]}
        >
          <Selection
            url={`${Endpoint.GET_PAGE}?moduleId=${moduleSelected}`}
            formKey="pageId"
            form={form}
          />
        </Form.Item>
        <Row justify="center" gutter={8}>
          <Col span={12}>
            <ButtonComponent
              className="full"
              type="dash"
              size="large"
              onClick={close}
            >
              Đóng
            </ButtonComponent>
          </Col>
          <Col span={12}>
            <ButtonComponent
              type="primary"
              htmlType="submit"
              size="large"
              className="full"
            >
              Lưu
            </ButtonComponent>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
export default ModalCreateMenuItem;
