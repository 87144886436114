import { Endpoint } from "@utils/endpoint";
import { authGetData, authGetMultiplesRequest } from "@utils/request";
import { buildQueryString, parseParams } from "@utils/function";

export const handleListBaoCao = (
  setLoading,
  setData,
  setTotalCount,
  filterConditions
) => {
  authGetData({
    url:
      Endpoint.GET_LIST_BAO_CAO_TINH_HINH_PHAT_TRIEN +
      "?" +
      buildQueryString(parseParams(filterConditions)),
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setData(res.data);
        setTotalCount(res.paging);
      }
    },
  });
};

export const handleMultiBoLoc = (setLoading, setDonVi) => {
  const endpoints = [`${Endpoint.LIST_DON_VI}?pageSize=-1`];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDonVi(res[0].data.data);
    },
  });
};
