import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APIDongBoTiepNhanYeuCau = {
  SYNC_TIEP_NHAN_YEU_CAU: `${BASE_API_URL_CAP_DIEN}/tiep-nhan-yeu-cau/sync`,
  SYNC_TIEP_NHAN_YEU_CAU_THOIGIAN: `${BASE_API_URL_CAP_DIEN}/tiep-nhan-yeu-cau/sync-thoi-gian`,
  LIST_TIEP_NHAN_YEU_CAU: `${BASE_API_URL_CAP_DIEN}/tiep-nhan-yeu-cau/list`,

  //Đồng bộ thời gian
  LIST_DONG_BO_THOI_GIAN: `${BASE_API_URL_CAP_DIEN}/tien-ich/dong-bo-thoi-gian`,
};
