import { createSlice } from "@reduxjs/toolkit";
export const namespace = "systemConfig";

const INITAL_STATE = {
  collapsed: false,
  modal: {
    visible: false,
    content: null,
    title: null,
  },
  scrollHeight: true,
};

const slice = createSlice({
  name: namespace,
  initialState: INITAL_STATE,
  reducers: {
    handleCollapse: (state) => {
      return {
        ...state,
        collapsed: !state.collapsed,
      };
    },
    handleCollapseWidth: (state) => {
      return {
        ...state,
        collapsed: true,
      };
    },
    handleScroll: (state, action) => {
      return {
        ...state,
        scrollHeight:
          action.payload.scrollHeight === undefined ||
          action.payload.scrollHeight === true
            ? true
            : false,
      };
    },
    openModal: (state, action) => {
      return {
        ...state,
        modal: {
          visible: true,
          content: action.payload.content,
          title: action.payload.title,
        },
      };
    },
    closeModal: (state) => {
      return {
        ...state,
        modal: {
          visible: false,
          content: null,
          title: null,
        },
        scrollHeight: true,
      };
    },
  },
});

export const reducer = slice.reducer;

export const {
  handleCollapse,
  openModal,
  closeModal,
  handleScroll,
  handleCollapseWidth,
} = slice.actions;

export const systemConfigSelector = (state) => state[namespace];
