import { Endpoint } from "@utils/endpoint";
import { authSelector } from "@state/auth";
import { Col, Form, Input, Row, Select, Tooltip, Button } from "antd";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import DatePickerComponent from "@components/DatePicker";
import moment from "moment";
import Selection from "@components/Select";
import FormComponent from "@components/Form";
import { ENUM_GCSTRANGTHAISO, ENUM_KY_GCS } from "@utils/constants";
const { Option } = Select;

export function ListFilterCapNhatChiSo(props) {
  const { handleSearch, clearFilter, filterConditions, tooltip } =
    props;
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        DonViId: user.unitId.toString(),
      }}
    >
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="DonViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Selection
                url={Endpoint.GET_UNIT_LIST}
                form={form}
                formKey="DonViId"
                notClear={true}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.DonViId !== currentValues.DonViId) {
                form.setFieldsValue({
                  DoiId: undefined,
                });
              }
              return prevValues.DonViId !== currentValues.DonViId;
            }}
          >
            {({ getFieldValue }) => {
              if (getFieldValue("DonViId")) {
                return (
                  <Form.Item name="ToDoiId" label="Tổ đội">
                    <Selection
                      url={`${Endpoint.GET_TEAM_LIST}?donviId=${getFieldValue(
                        "DonViId"
                      )}`}
                      formKey="ToDoiId"
                      form={form}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="ToDoiId" label="Tổ đội">
                  <Selection
                    url={`${Endpoint.GET_TEAM_LIST}?donviId=${user.unitId}`}
                    formKey="ToDoiId"
                    form={form}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="MaSo" label="Mã sổ">
            <Input
              suffix={
                <Tooltip title={tooltip}>
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="TenSo" label="Tên sổ">
            <Input
              suffix={
                <Tooltip title={tooltip}>
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="Ky" label="Kỳ GCS">
            <Select defaultValue={ENUM_KY_GCS.KY_1} placeholder={"Tất cả"} allowClear>
              <Option value={ENUM_KY_GCS.KY_1}>1</Option>
              <Option value={ENUM_KY_GCS.KY_2}>2</Option>
              <Option value={ENUM_KY_GCS.KY_3}>3</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="Ngay" label="Ngày ghi">
            <DatePickerComponent
              defaultValue={moment()}
              format={"YYYY-MM-DD"}
              form={form}
              formKey={"Ngay"}
              placeholder={"Chọn ngày ghi"}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="MaTrangThai" label="Trạng thái">
            <Select defaultValue={ENUM_GCSTRANGTHAISO.DA_PHAN_CONG} placeholder={"Tất cả"} allowClear>
              <Option value={ENUM_GCSTRANGTHAISO.DA_PHAN_CONG}>Chưa thực hiện</Option>
              <Option value={[ENUM_GCSTRANGTHAISO.DANG_CAP_NHAT_MDMS,
              // ENUM_GCSTRANGTHAISO.XUAT_GHI_LAI_BANG_MTB,
              ENUM_GCSTRANGTHAISO.XUAT_GHI_LAI_BANG_MDMS].toString()}>Đang thực hiện</Option>
              <Option value={ENUM_GCSTRANGTHAISO.KET_THUC_MDMS_CHUYEN_MTB_HHU.toString()}>Chờ chuyển HHU/MTB</Option>
              <Option value={ENUM_GCSTRANGTHAISO.HHU_DA_LAY_DU_LIEU.toString()}>Đã chuyển HHU</Option>
              <Option value={[ENUM_GCSTRANGTHAISO.CHUYEN_LAY_DL_BANG_MTB,
              ENUM_GCSTRANGTHAISO.XUAT_GHI_LAI_BANG_MTB,
              ENUM_GCSTRANGTHAISO.DA_LAY_SO_VE_MTB].toString()}>Đã chuyển MTB</Option>
              <Option value={[ENUM_GCSTRANGTHAISO.DA_CAP_NHAT_DU_MDMS,
              ENUM_GCSTRANGTHAISO.HHU_DA_GUI_DU_DL,
              ENUM_GCSTRANGTHAISO.MTB_DA_GUI_DU_LIEU,
              ENUM_GCSTRANGTHAISO.DANG_XAC_NHAN_CAC_CAP].toString()}>Đã đủ chỉ số</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Button className="mr-24px" onClick={clear}>
          Bỏ lọc
        </Button>
        <Button type="primary" htmlType="submit" form="filter-form">
          Tìm kiếm
        </Button>
      </Row>
    </FormComponent>
  );
}
