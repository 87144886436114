import React from "react";
import { Column } from "@ant-design/plots";
import { Empty } from "antd";

function BarChartComponent(props) {
  const { data, headerText } = props;

  const config = {
    data,
    xField: "loaiDichVu",
    yField: "soLuong",
    label: {
      position: "middle",
      // 'top', 'bottom', 'middle',
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      soLuong: {
        alias: "Số lượng",
      },
    },
  };
  return (
    <div className="bieu-do-theo-don-vi-quan-ly">
      <h2>{headerText}</h2>
      {data.length > 0 ? <Column {...config} /> : <Empty />}
    </div>
  );
}

export default BarChartComponent;
