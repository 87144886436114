import {  Modal, notification } from "antd";
import { useCallback, useState } from "react";
import Content from "./content";
import { submitImportPC } from "../services";
import { ENUM_MUCDICH_SDD } from "@utils/constants";

export default function ImportModal(props) {
  const { visible, handleCloseImport, dataNV, setFetchDataTable, setDataResponse, dataApGiaUyQuyen, dataApGiaUyQuyenMacDinh } = props;
  const [loading, setLoading] = useState(false);
  // const [nhanVien, setNhanVien] = useState();
  const [uyQuyen, setUyQuyen] = useState(dataApGiaUyQuyenMacDinh && dataApGiaUyQuyenMacDinh.value);
  const [loaiKH, setLoaiKH] = useState(ENUM_MUCDICH_SDD.SINHHOAT);
  const [fileExNew, setFileExNew] = useState({});

  //remove file
  const removeFile = useCallback(() => {
    setFileExNew({ fileExNew: {} });
  }, []);

  const beforeUpload = (file) => {
    const isTypeImage =
      file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel";
      console.log(file.type);
    if (!isTypeImage) {
      notification.error({
        message: `Thông báo`,
        description: "Chỉ được phép tải tệp định dạng .xls, .xlsx",
      });
    }
    return isTypeImage;
  };

  //upload file excel
  const uploadFile = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      event.target.value = null;
      if (beforeUpload(file)) {
        setFileExNew(file);
      }else return;
    }
  };

  // const changeNV = useCallback((values) => {
  //   setNhanVien(values);
  // }, []);

  const changeUyQuyen = useCallback((values) => {
    setUyQuyen(values);
  }, []);

  const changeLoaiKH = useCallback((values) => {
    setLoaiKH(values);
  }, []);

  //import file excel
  const handleImportFile = useCallback((values) => {
    submitImportPC(setLoading, fileExNew, values.userId, values.doiId, uyQuyen, setFetchDataTable, handleCloseImport, setDataResponse, loaiKH)

  }, [fileExNew, uyQuyen, setFetchDataTable, handleCloseImport, setDataResponse, loaiKH]);

  return (
    <Modal
      title="Phân công file Import"
      visible={visible}
      footer={null}
      onCancel={() => handleCloseImport()}
    >
      <Content
        fileEx={fileExNew}
        uploadFile={uploadFile}
        handleImportFile={handleImportFile}
        loading={loading}
        removeFile={removeFile}
        dataNV={dataNV}
        handleCloseImport={handleCloseImport}
        // changeNV={changeNV}
        changeUyQuyen={changeUyQuyen}
        changeLoaiKH={changeLoaiKH}
        dataApGiaUyQuyen={dataApGiaUyQuyen}
        dataApGiaUyQuyenMacDinh={dataApGiaUyQuyenMacDinh}
      />
    </Modal>
  );
}
