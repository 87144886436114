import React, { useEffect, useState, useCallback } from "react";
import { Select } from "antd";
import { authGetData } from "@utils/request";
import { removeAccents } from "@utils/function";
import { NAME_TB_TIEN_DIEN_LAN_2 } from "../nhan-tin-email-zalo/constant";
const { Option } = Select;
const SelectionDoiTuong = (props) => {
  const {
    // selectiName = "name",
    data,
    setData,
    url,
    placeholder = "Tất cả",
    form,
    formKey,
    disabled,
    mode,
    setValue,
    handleDeselect,
    notClear,
    defaultValue,
    isCustom,
    valueKey,
    valueName,
    setInitial,
  } = props;
  const [loading, setLoading] = useState(false);

  const getData = useCallback(() => {
    authGetData({
      url,
      setLoading,
      onSuccess: (res) => {
        setData(res.data);
        if (setInitial) {
          setInitial(
            res.data.find((item) => item.name === NAME_TB_TIEN_DIEN_LAN_2).value
          );
        }
      },
    });
  }, [url]);

  useEffect(() => {
    if (url && typeof url === "string") {
      getData();
    }
  }, [getData, url]);
  const handleChange = useCallback(
    (value) => {
      if (form) {
        form.setFieldsValue({
          [formKey]: value,
        });
      }
      if (setValue) {
        setValue(value);
      }
    },
    [form, formKey, setValue]
  );
  return (
    <Select
      defaultValue={defaultValue}
      maxTagCount={mode === "multiple" || "tags" ? "responsive" : null}
      value={form && form.getFieldValue(formKey)}
      placeholder={placeholder}
      showSearch
      mode={mode}
      allowClear={notClear ? false : true}
      loading={loading}
      onChange={handleChange}
      onDeselect={handleDeselect}
      disabled={disabled}
      style={{ width: "100%" }}
      filterOption={(input, option) => {
        if (option && option.children) {
          return (
            removeAccents(option.children)
              .toLowerCase()
              .indexOf(removeAccents(input).toLowerCase()) >= 0
          );
        }
      }}
    >
      {data && data.length
        ? data.map((item, idx) => (
            <Option key={idx} value={item.name}>
              {/* {item[selectiName]} */}
              {isCustom && valueName ? item[valueName] : item.name}
            </Option>
          ))
        : undefined}
    </Select>
  );
};

export default SelectionDoiTuong;
