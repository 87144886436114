import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useEffect,
} from "react";
import Filter from "@components/Filter/Filter";
import { TableStyled, Container } from "./css-styled";
import { Modal, Tag, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_LOAI_KIEM_TRA,
  // AP_GIA_TRANG_THAI,
  // FORMAT_MONTH_ENGLISH,
} from "@utils/constants";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { handleListBKNVCanTao, submitTaoBangKe } from "./services";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilter } from "./list-bo-loc";
import CheckPermission from "@components/CheckPermission";
import { ap_gia } from "@permissions/e_apgia";
import TableComponent from "@components/TableComponent";
import ButtonComponent from "@components/Button";
import { useTranslation } from "react-i18next";
import ChiTietDiemDo from "../../common/chi-tiet-diem-do";
import { openModal } from "@state/system-config/reducer";
import { FilePdfOutlined, FileOutlined } from "@ant-design/icons";
// import moment from "moment";

export default function Index() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector(authSelector);
  const [listBKNVCanTao, setListBKNVCanTao] = useState([]);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    ...parseParams(location.search),
    LoaiKiemTra: ENUM_LOAI_KIEM_TRA.DINHMUC,
    // ThangKiemTra: moment().format(FORMAT_MONTH_ENGLISH),
    // TenNhanVien: user.id
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };
  const handleSearch = useCallback((values) => {
    setSelectedRowKeys([]);
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
      Tram: values.Tram
        ? Array.isArray(values.Tram) && values.Tram.length > 0
          ? values.Tram.join()
          : values.Tram
        : [],
    }));
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      LoaiKiemTra: ENUM_LOAI_KIEM_TRA.DINHMUC,
      // ThangKiemTra: moment().format(FORMAT_MONTH_ENGLISH),
      // TenNhanVien: user.id
    });
  }, [user.unitId]);

  //search list
  const getListBKNVCanTao = useCallback(() => {
    handleListBKNVCanTao(
      setLoading,
      location,
      setListBKNVCanTao,
      setTotalCount,
      filterConditions
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getListBKNVCanTao();
  }, [getListBKNVCanTao]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListBKNVCanTao();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListBKNVCanTao, setFetchDataTable]);

  const detailDiemDo = (record) => {
    return dispatch(
      openModal({
        content: <ChiTietDiemDo detailRecord={record} />,
        size: "large",
        title: "Chi tiết điểm đo",
      })
    );
  };

  const detailDD = (record) => {
    return {
      onDoubleClick: () => {
        detailDiemDo(record);
      },
    };
  };
  const columnsDM = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => detailDiemDo(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      fixed: "left",
      sorter: true,
    },
    {
      title: "Tên Khách hàng",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
      sorter: true,
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      key: "maKhachHang",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Số công tơ",
      dataIndex: "soCongTo",
      key: "soCongTo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Thay đổi định mức",
      dataIndex: "loaiCongViec",
      key: "loaiCongViec",
      render: (text, record) => (
        <div className="table-text-center">
          {record.thayDoi ? "Có" : "Không"}
        </div>
      ),
      width: "10%",
    },
    {
      title: "Ngày áp dụng mới",
      dataIndex: "ngayApDungMoi",
      key: "ngayApDungMoi",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
    },
    {
      title: "Định mức trước kiểm tra",
      dataIndex: "soHo",
      key: "soHo",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Định mức sau kiểm tra",
      dataIndex: "soHoMoi",
      key: "soHoMoi",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Thay đổi (+/-)",
      dataIndex: "thayDoiSoHo",
      key: "thayDoiSoHo",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThaiYeuCau",
      key: "trangThaiYeuCau",
      render: (text) => (
        <Tag
          style={{ display: "block", textAlign: "center" }}
          color={"#26c0e9"}
        >
          {text}
        </Tag>
      ),
      width: "10%",
    },
    {
      title: "KH xác nhận",
      dataIndex: "nhanVien",
      key: "nhanVien",
      render: () => (
        <div className="table-text-center">
          <span>Đã ký</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "Mã bảng kê",
      dataIndex: "maBangKe",
      key: "maBangKe",
      render: (text) => <span>{text}</span>,
      width: "9%",
    },
    {
      title: "Tác vụ",
      dataIndex: "",
      key: "tacVu",
      render: (text, record) => (
        <div className="table-text-center">
          {record.pathFileBienBan ? (
            <CheckPermission permissionCode={ap_gia.e15x}>
              {/* <Button
                className="action-button"
                style={{
                  backgroundColor: "#F4A460",
                  borderColor: "#F4A460",
                }}
                type="primary"
              >
                <a
                  href={record.pathFileBienBan}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Xem biên bản
                </a>
              </Button> */}
              <Tooltip title="Xem biên bản">
                <a
                  href={record.pathFileBienBan}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FilePdfOutlined
                    style={{ fontSize: "22px" }}
                  />
                </a>
              </Tooltip>
            </CheckPermission>
          ) : null}
        </div>
      ),
      width: "4%",
      fixed: "right"
    },
  ];

  const columnsAG = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => detailDiemDo(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      fixed: "left",
      sorter: true,
    },
    {
      title: "Tên Khách hàng",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
      sorter: true,
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      key: "maKhachHang",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Số công tơ",
      dataIndex: "soCongTo",
      key: "soCongTo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Thay đổi định mức",
      dataIndex: "loaiCongViec",
      key: "loaiCongViec",
      render: (text, record) => (
        <div className="table-text-center">
          {record.thayDoi ? "Có" : "Không"}
        </div>
      ),
      width: "10%",
    },
    {
      title: "Ngày áp dụng mới",
      dataIndex: "ngayApDungMoi",
      key: "ngayApDungMoi",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
    },
    {
      title: "Chuỗi giá trước kiểm tra",
      dataIndex: "chuoiGia",
      key: "chuoiGia",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "Chuỗi giá sau kiểm tra",
      dataIndex: "chuoiGiaMoi",
      key: "chuoiGiaMoi",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    // {
    //   title: "Thay đổi (+/-)",
    //   dataIndex: "thayDoiSoHo",
    //   key: "thayDoiSoHo",
    //   render: (text) => (
    //     <div className="table-text-left">
    //       <span>{text}</span>
    //     </div>
    //   ),
    //   width: "8%",
    // },
    {
      title: "Trạng thái",
      dataIndex: "trangThaiYeuCau",
      key: "trangThaiYeuCau",
      render: (text) => (
        <Tag
          style={{ display: "block", textAlign: "center" }}
          color={"#26c0e9"}
        >
          {text}
        </Tag>
      ),
      width: "10%",
    },
    {
      title: "KH xác nhận",
      dataIndex: "nhanVien",
      key: "nhanVien",
      render: () => (
        <div className="table-text-center">
          <span>Đã ký</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "Mã bảng kê",
      dataIndex: "maBangKe",
      key: "maBangKe",
      render: (text) => <span>{text}</span>,
      width: "9%",
    },
    {
      title: "Tác vụ",
      dataIndex: "",
      key: "tacVu",
      render: (text, record) => (
        <div className="table-text-center">
          {record.pathFileBienBan ? (
            <CheckPermission permissionCode={ap_gia.e15x}>
              <Tooltip title="Xem biên bản">
                <a
                  href={record.pathFileBienBan}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FilePdfOutlined
                    style={{ fontSize: "22px" }}
                  />
                </a>
              </Tooltip>
            </CheckPermission>
          ) : null}
          {record.pathFileBienBanGiay ?
            <CheckPermission permissionCode={ap_gia.e15x}>
              <Tooltip title="Xem BBAG giấy">
                <a
                  href={record.pathFileBienBanGiay}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FileOutlined
                    style={{ fontSize: "22px", marginLeft: '10px' }}
                  />
                </a>
              </Tooltip>
            </CheckPermission> : null}
        </div>
      ),
      width: "5%",
      fixed: "right"
    },
  ];

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
    columnWidth: "3%",
    fixed: "left",
  };

  const genExtra = useCallback(
    () => (
      <div>
        <CheckPermission permissionCode={ap_gia.e15l}>
          <ButtonComponent
            size="default"
            type="primary"
            className="mr-5px"
            disabled={!selectedRowKeys.length}
            onClick={() => handleTaoBangKe()}
          >
            Tạo bảng kê
          </ButtonComponent>
        </CheckPermission>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRowKeys]
  );

  const handleTaoBangKe = () => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: `Các yêu cầu đã chọn sẽ được tạo bảng kê tương ứng!`,
      onOk() {
        //call api
        submitTaoBangKe(
          selectedRowKeys.toString(),
          setLoading,
          setFetchDataTable,
          t,
          setSelectedRowKeys
        );
      },
      onCancel() {},
      okText: "Đồng ý",
      cancelText: "Hủy",
    });
  };

  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          filterScreen={"LapBK"}
        />
      </Filter>
      <Container>
        <TableStyled>
          <TableComponent
            header={`Lập bảng kê (Tổng ${selectedRowKeys.length}/${totalCount})`}
            handleSearch={handleSearch}
            dataSource={listBKNVCanTao}
            columns={customColumn(
              filterConditions.LoaiKiemTra === ENUM_LOAI_KIEM_TRA.DINHMUC
                ? columnsDM
                : columnsAG,
              filterConditions
            )}
            totalData={totalCount}
            renderExtraAction={() => genExtra()}
            loading={loading}
            scrollX={2500}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            rowSelection={rowSelection}
            onRow={detailDD}
            rowKey="id"
            isNoResize={true}
          />
        </TableStyled>
      </Container>
    </Fragment>
  );
}
