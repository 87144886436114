import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  handleDownload,
  selectBctdctdvkhCMIS3,
  SelectionLoaiYeuCau,
} from "./service";

export default function BaoCaoTheoDoiCMIS3() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);

  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Báo cáo theo dõi dịch vụ khách hàng
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              initialValues={{
                reportType:
                  Endpoint.BCCTDVKH_THSL_12_DICH_VU_DIEN_LUC_THEO_PT_DIEN_TU,
                unitId: user.user.unitCode,
              }}
              colon={false}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
            >
              <Form.Item
                name="reportType"
                label="Loại báo cáo"
                labelAlign="left"
                required
              >
                <Select options={selectBctdctdvkhCMIS3}></Select>
              </Form.Item>

              {/* Đơn vị */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") !==
                  Endpoint.BCCTDVKH_TH_SO_KHACH_HANG_CAI_APP_ZALO &&
                  getFieldValue("reportType") !== Endpoint.BCWEHN_TH_TAT_CA_DV_DIEN_TIEP_NHAN ? (
                    <Form.Item
                      name="unitId"
                      label="Đơn vị"
                      labelAlign="left"
                      required
                    >
                      <Selection
                        url={Endpoint.GET_TEAM_LIST_CODE}
                        formKey="unitId"
                        form={form}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              {/* Loại yêu cầu */}

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") ===
                  Endpoint.BCCTDVKH_THSL_12_DICH_VU_DIEN_LUC_THEO_PT_DIEN_TU ? (
                    <Form.Item
                      name="LoaiYeuCau"
                      label="Loại yêu cầu"
                      labelAlign="left"
                      required
                    >
                      <SelectionLoaiYeuCau
                        formKey="LoaiYeuCau"
                        form={form}
                        url={Endpoint.BCCTDVKH_LOAI_YEU_CAU}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              {/* Từ ngày */}

              <Form.Item
                name="TuNgay"
                label="Từ ngày"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="Day"
                  formKey="TuNgay"
                  form={form}
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>

              {/* Đến ngày  */}

              <Form.Item
                name="DenNgay"
                label="Đến ngày"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="Day"
                  formKey="DenNgay"
                  form={form}
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>
              <Row>
                <Col span={4}></Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>
                  {/* 
                  <Button
                    type="primary"
                    loading={loading}
                    className="ml-20px"
                    onClick={() => {
                      handleOpenPdf(form.getFieldValue(), setLoading);
                    }}
                  >
                    In báo cáo
                  </Button> */}
                </Col>
              </Row>
            </FormComponent>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
