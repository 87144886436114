import TrangChu from "@modules/dang-ky-thanh-toan/index";
import DanhSachDichVuThanhToan from "@modules/dang-ky-thanh-toan/quan-ly-dich-vu-thanh-toan/danh-sach-dich-vu-thanh-toan/index";
import ThongTinKhuyenMai from "@modules/dang-ky-thanh-toan/quan-ly-khuyen-mai/thong-tin-khuyen-mai/index";
import DashBoard from "@modules/dang-ky-thanh-toan/trang-chu-dashboard/index";
import { dang_ky_thanh_toan } from "@permissions/h_dangkythanhtoan/index";
import { MODULE_THANHTOAN } from "@utils/constants";
import NoDongCatDien from "@modules/dang-ky-thanh-toan/no-dong-cat-dien/danh-sach-hoa-don-no-dong-cat-dien/index";
import QuanLyThongTinHoTroKhachHang from "@modules/dang-ky-thanh-toan/quan-ly-thong-tin-ho-tro-khach-hang";
const mainPath = "/dang-ky-thanh-toan";

const routes = [
  {
    title: "Trang chủ",
    path: mainPath,
    privateRoute: true,
    component: TrangChu,
    subItems: [],
    permissionCode: MODULE_THANHTOAN,
  },
  {
    title: "Quản lý dịch vụ thanh toán",
    path: mainPath + "/ql-dich-vu-thanh-toan",
    privateRoute: true,
    permissionCode: dang_ky_thanh_toan.h1,
    subItems: [
      {
        subTitle: "Dashboard",
        subPath: "/dashboard",
        subComponent: DashBoard,
        permissionCode: dang_ky_thanh_toan.h11,
      },
      {
        subTitle: "Danh sách tài khoản của khách hàng",
        subPath: "/ds-tai-khoan-cua-khach-hang",
        subComponent: DanhSachDichVuThanhToan,
        permissionCode: dang_ky_thanh_toan.h12,
      },
    ],
  },
  {
    title: "Quản lý khuyến mãi",
    path: mainPath + "/ql-khuyen-mai",
    privateRoute: true,
    permissionCode: dang_ky_thanh_toan.h2,
    subItems: [
      {
        subTitle: "Thông tin khuyến mãi",
        subPath: "/thong-tin-khuyen-mai",
        subComponent: ThongTinKhuyenMai,
        permissionCode: dang_ky_thanh_toan.h21,
      },
    ],
  },
  {
    title: "Quản lý thông tin hỗ trợ khách hàng",
    path: mainPath + "/ql-thong-tin-ho-tro-khach-hang",
    privateRoute: true,
    permissionCode: dang_ky_thanh_toan.h3,
    component: QuanLyThongTinHoTroKhachHang,
    subItems: [],
  },
  {
    title: "Cập nhật trạng thái đóng cắt điện",
    path: mainPath + "/cap-nhat-trang-thai-dong-cat-dien",
    privateRoute: true,
    permissionCode: dang_ky_thanh_toan.h3,
    component: NoDongCatDien,
    subItems: [],
  },
];

export default routes;
