const c1 = {
  c1: "c1",
  c1x: "c1x",
};
const c11 = {
  c11: "c11",
  c11x: "c11x",
};
const c2 = {
  c2: "c2",
  c2x: "c2x",
};
const c21 = {
  c21: "c21",
  c21x: "c21x",
};
const c22 = {
  c22: "c22",
  c22x: "c22x",
};
const c23 = {
  c23: "c23",
  c23x: "c23x",
};
const c24 = {
  c24: "c24",
  c24x: "c24x",
};
const c3 = {
  c3: "c3",
  c3x: "c3x",
};
const c31 = {
  c31: "c31",
  c31x: "c31x",
};
const c32 = {
  c32: "c32",
  c32x: "c32x",
};
const c33 = {
  c33: "c33",
  c33x: "c33x",
};
const c34 = {
  c34: "c34",
  c34x: "c34x",
};
const c35 = {
  c35: "c35",
  c35x: "c35x",
};
const c36 = {
  c36: "c36",
  c36x: "c36x",
};
const c37 = {
  c37: "c37",
  c37x: "c37x",
};
const c38 = {
  c38: "c38",
  c38x: "c38x",
};
const c4 = {
  c4: "c4",
  c4x: "c4x",
};
const c41 = {
  c41: "c41",
  c41x: "c41x",
  c41k: "c41k",
  c41h: "c41h",
  c41tl: "c41tl",
};
const c42 = {
  c42: "c42",
  c42x: "c42x",
  c42k: "c42k",
  c42h: "c42h",
  c42tl: "c42tl",
};
const c43 = {
  c43: "c43",
  c43x: "c43x",
  c43h: "c43h",
};
const c5 = {
  c5: "c5",
  c5x: "c5x",
};
const c51 = {
  c51: "c51",
  c51x: "c51x",
  c51k: "c51k",
  c51h: "c51h",
  c51tl: "c51tl",
};
const c52 = {
  c52: "c52",
  c52x: "c52x",
  c52k: "c52k",
  c52h: "c52h",
  c52tl: "c52tl",
};
const c6 = {
  c6: "c6",
  c6x: "c6x",
};
const c61 = {
  c61: "c61",
  c61e: "c61e",
  c61i: "c61i",
};
const c62 = {
  c62: "c62",
  c62e: "c62e",
  c62i: "c62i",
};
const c63 = {
  c63: "c63",
  c63e: "c63e",
  c63i: "c63i",
};
const c64 = {
  c64: "c64",
  c64e: "c64e",
  c64i: "c64i",
};
const c65 = {
  c65: "c65",
  c65e: "c65e",
  c65i: "c65i",
};
const c66 = {
  c66: "c66",
  c66e: "c66e",
  c66i: "c66i",
};
const c67 = {
  c67: "c67",
  c67e: "c67e",
  c67i: "c67i",
};
const c68 = {
  c68: "c68",
  c68e: "c68e",
  c68i: "c68i",
};
const c69 = {
  c69: "c69",
  c69e: "c69e",
  c69i: "c69i",
};
const c610 = {
  c610: "c610",
  c610e: "c610e",
  c610i: "c610i",
};
const c611 = {
  c611: "c611",
  c611e: "c611e",
  c611i: "c611i",
};

const c7 = {
  c7: "c7",
};
const c71 = {
  c71: "c71",
  c71x: "c71x",
  c71t: "c71t",
  c71h: "c71h",
};
const c72 = {
  c72: "c72",
  c72x: "c72x",
};
const c73 = {
  c73: "c73",
  c73x: "c73x",
};
const c74 = {
  c74: "c74",
  c74x: "c74x",
};
const c75 = {
  c75: "c75",
  c75x: "c75x",
  c75c: "c75c",
  c75h: "c75h",
};

const gndn = {
  c: "c",
};

export const giao_nhan_dien_nang = {
  ...gndn,
  ...c1,
  ...c11,
  ...c2,
  ...c21,
  ...c22,
  ...c23,
  ...c24,
  ...c3,
  ...c31,
  ...c32,
  ...c33,
  ...c34,
  ...c35,
  ...c36,
  ...c37,
  ...c38,
  ...c4,
  ...c41,
  ...c42,
  ...c43,
  ...c5,
  ...c51,
  ...c52,
  ...c6,
  ...c61,
  ...c62,
  ...c63,
  ...c64,
  ...c65,
  ...c66,
  ...c67,
  ...c68,
  ...c69,
  ...c610,
  ...c611,
  ...c7,
  ...c71,
  ...c72,
  ...c73,
  ...c74,
  ...c75,
};
