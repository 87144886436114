import { BASE_API_URL_GNDN } from "@utils/constants";

export const APIBangKe = {
  LIST_DATA_BANG_KE: `${BASE_API_URL_GNDN}/bangke/danh-sach-bang-ke`,
  LIST_LOAI_BANG_KE: `${BASE_API_URL_GNDN}/bangke/loai-bang-ke`,
  XAC_NHAN_BANG_KE_BIEN_BAN: `${BASE_API_URL_GNDN}/bangke/xacnhan-bang-ke-bienban`,
  XAC_NHAN_BANG_KE_BAO_CAO: `${BASE_API_URL_GNDN}/bangke/xacnhan-bang-ke-baocao`,
  KY_HSM_BANG_KE_BIEN_BAN: `${BASE_API_URL_GNDN}/bangke/ky-bang-ke-bienban`,
  KY_HSM_BANG_KE_BAO_CAO: `${BASE_API_URL_GNDN}/bangke/ky-bang-ke-baocao`,
  KY_HSM_BANG_KE: `${BASE_API_URL_GNDN}/bangke/ky-bang-ke`,
  HUY_BANG_KE: `${BASE_API_URL_GNDN}/bangke/huy-bang-ke`,
  TRA_LAI_BANG_KE_BIEN_BAN: `${BASE_API_URL_GNDN}/bangke/tralai-bang-ke-bienban`,
  TRA_LAI_BANG_KE_BAO_CAO: `${BASE_API_URL_GNDN}/bangke/tralai-bang-ke-baocao`,
  SHOW_BIEN_BAN_BANG_KE: `${BASE_API_URL_GNDN}/bangke/show-pdf`,
};
