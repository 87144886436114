import { FORMAT_ENGLISH } from "@utils/constants";
import { alertMessage } from "@utils/function";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import ThongTinChungTabs from "./layout";
import moment from "moment";

const arrLoaiBienBan = [
  // {
  //   value: 0,
  //   name: "Treo mới",
  // },
  {
    value: 1,
    name: "Thay thế định kỳ ",
  },
  {
    value: 2,
    name: "Thay thế cháy hỏng",
  },
  {
    value: 3,
    name: "Tháo thu hồi",
  },
];

const arrViTriTreo = [
  {
    value: 2,
    name: "Trong nhà",
  },
  {
    value: 1,
    name: "Trên tường ngoài nhà ",
  },
  {
    value: 0,
    name: "Tại cột số",
  },
  {
    value: 3,
    name: "Vị trí khác",
  },
];

export default function Index(props) {
  const {
    detail,
    dataLyDo,
    form,
    detailCTTreo,
    detailCTThao,
    setIsResetIsThao,
    isResetIsTUTI,
    setIsResetIsTUTI,
    // defaultCTTreo,
    setSoCot,
    dataDetaiCTTreolUpdate,
    setChangeTab1,
    doingChange,
    dataNVNiemPhong,
    dataNVTreoThao,
    dataSBB,
    getDataLyDo,
    setNgay,
    ngay,
    detailHoSo,
  } = props;
  const [isCheck1, setIsCheck1] = useState(
    detail?.isTreoThaoTUTI ? detail?.isTreoThaoTUTI : false
  );
  const [isCheck2, setIsCheck2] = useState(
    detail?.isDoXa || detail?.isDoXa === null ? true : false
  );

  const [valueLoaiBienBan, setValueLoaiBB] = useState(
    detail && detail.loaiBienBan ? detail.loaiBienBan.toString() : null
  );
  const [oldValueLoaiBB, setOldValueLoaiBB] = useState(
    detail && detail.loaiBienBan ? detail.loaiBienBan.toString() : null
  );

  useEffect(() => {
    if (isResetIsTUTI) {
      form.setFieldsValue({
        isTreoThaoTUTI: false,
      });
      if (detail?.isTreoThaoTUTI === isCheck1) setChangeTab1(false);
      else setChangeTab1(true);
      setIsCheck1(false);
      setIsResetIsTUTI(false);
    }
  }, [
    detail?.isTreoThaoTUTI,
    form,
    isCheck1,
    isResetIsTUTI,
    setChangeTab1,
    setIsResetIsTUTI,
  ]);

  useEffect(() => {
    if (isCheck1 === detail?.isTreoThaoTUTI && doingChange === false)
      setChangeTab1(false);
    else setChangeTab1(true);
  }, [detail?.isTreoThaoTUTI, doingChange, isCheck1, setChangeTab1]);
  const [dataCTTreo, setDataCTTreo] = useState(detailCTTreo);
  //change input
  const onChange = (e, viTri) => {
    if (viTri === 1) {
      if (e.target.checked) {
        if (
          dataDetaiCTTreolUpdate.maCongTo !== "" &&
          dataDetaiCTTreolUpdate.maCongTo !== null &&
          detailCTThao &&
          !form.getFieldValue("isThaoCongTo")
        ) {
          Modal.warning({
            centered: true,
            title: "Xác nhận",
            content:
              "Thông tin công tơ treo mới đã được đăng ký, bạn hãy xóa thông tin công tơ treo mới trước khi chọn [Treo tháo TU/TI chốt chỉ số?",
            onOk() {
              setIsCheck1(false);
              form.setFieldsValue({
                isTreoThaoTUTI: false,
                isThaoCongTo: form.getFieldValue("isThaoCongTo"),
              });
              setIsResetIsThao(false);
              setDataCTTreo({
                ...dataCTTreo,
              });
            },
            okText: "Đồng ý",
            okButtonProps: { type: "primary" },
          });
          // Modal.confirm({
          //   centered: true,
          //   title: "Xác nhận",
          //   content:
          //     "Thông tin công tơ treo mới đã được đăng ký, bạn hãy xóa thông tin công tơ treo mới trước khi chọn [Treo tháo TU/TI chốt chỉ số?",
          //   onOk() {
          //     setIsCheck1(true);
          //     form.setFieldsValue({
          //       isTreoThaoTUTI: true,
          //       isThaoCongTo: false,
          //     });
          //     if (detail?.isTreoThaoTUTI === e.target.checked) {
          //       setChangeTab1(true);
          //     }
          //     setIsResetIsThao(true);
          //     setIsCheck1(true);
          //     setDataCTTreo(defaultCTTreo);
          //   },
          //   onCancel() {
          //     setIsCheck1(false);
          //     form.setFieldsValue({
          //       isTreoThaoTUTI: false,
          //       isThaoCongTo: form.getFieldValue("isThaoCongTo"),
          //     });
          //     setIsResetIsThao(false);
          //     setDataCTTreo({
          //       ...dataCTTreo,
          //     });
          //   },
          //   okText: "Đồng ý",
          //   okButtonProps: { type: "primary" },
          //   cancelText: "Hủy",
          // });
        } else if (
          (detailCTThao && detailCTThao.isThaoCongTo) ||
          (detailCTThao && form.getFieldValue("isThaoCongTo"))
        ) {
          Modal.confirm({
            centered: true,
            title: "Xác nhận",
            content:
              "Chọn [Treo tháo TU/TI chốt chỉ số] thì không được phép chọn [Chỉ tháo công tơ]. Hệ thống sẽ tự động xử lý không chọn [Chỉ tháo công tơ]?",
            onOk() {
              form.setFieldsValue({
                isTreoThaoTUTI: true,
                isThaoCongTo: false,
              });
              if (detail?.isTreoThaoTUTI === e.target.checked) {
                setChangeTab1(true);
              }

              setIsResetIsThao(true);
              setIsCheck1(true);
            },
            onCancel() {
              form.setFieldsValue({
                isTreoThaoTUTI: false,
                isThaoCongTo: form.getFieldValue("isThaoCongTo"),
              });
              setIsResetIsThao(false);
              setIsCheck1(false);
            },
            okText: "Đồng ý",
            okButtonProps: { type: "primary" },
            cancelText: "Hủy",
          });
        } else {
          form.setFieldsValue({
            isTreoThaoTUTI: true,
          });
          setIsCheck1(true);
        }
      } else {
        form.setFieldsValue({
          isTreoThaoTUTI: false,
        });
        setIsCheck1(false);
      }
    } else {
      setIsCheck2(e.target.checked);
      form.setFieldsValue({
        isDoXa: e.target.checked,
      });
    }
  };

  //set form field
  useEffect(() => {
    form.setFieldsValue({
      loaiBienBan:
        detail?.loaiBienBan !== null && parseInt(detail?.loaiBienBan) <= 3
          ? detail?.loaiBienBan.toString()
          : "1",
      lyDoTreoThaoId: detail?.lyDoTreoThaoId,
      viTriTreoThao:
        (detail?.viTriTreoThao !== null &&
          parseInt(detail?.viTriTreoThao) > 0) ||
        (detail?.viTriTreoThao !== "" && parseInt(detail?.viTriTreoThao) > 0)
          ? detail?.viTriTreoThao.toString()
          : parseInt(detail?.viTriTreoThao) === 0
          ? "0"
          : null,
      soCot: detail?.soCot,
      soHop: detail?.soHop,
      hsn: detail?.hsn,
      maChiHop: detail?.maChiHop,
      isDoXa: detail?.isDoXa || detail?.isDoXa === null ? true : false,
      isTreoThaoTUTI: detail?.isTreoThaoTUTI ? detail?.isTreoThaoTUTI : false,
      gioDoXa: detail?.gioDoXa,
      phutDoXa: detail?.phutDoXa,
      hesid: detail?.hesid,
      gatewayID: detail?.gatewayID,
      maChiBOOC: detail?.maChiBOOC ? detail?.maChiBOOC : null,
      soVienChiBOOC: detail?.soVienChiBOOC ? detail?.soVienChiBOOC : null,
      soVienChiHop: detail?.soVienChiHop ? detail?.soVienChiHop : null,
      nhanVienTreoThaoId: detail?.nhanVienTreoThaoId
        ? detail?.nhanVienTreoThaoId
        : null,
      nhanVienNiemPhongId: detail?.nhanVienNiemPhongId
        ? detail?.nhanVienNiemPhongId
        : null,
    });
  }, [detail, form]);

  //Thay đổi loại biên bản
  const handleChangeLoaiBB = (value) => {
    let validate = false;
    if (detailHoSo.maLoaiYeuCau !== "CDHA_D") {
      validate = false;
      setValueLoaiBB(value);
      setOldValueLoaiBB(value);
    } else {
      if (
        detailCTThao === null ||
        (detailCTThao && detailCTThao.maCongTo === null)
      ) {
        if (value === "0" || value === 0) {
          validate = false;
          setValueLoaiBB(value);
          setOldValueLoaiBB(value);
          form.setFieldsValue({
            loaiBienBan: value,
          });
        } else {
          alertMessage(
            "error",
            "Thông báo!",
            "Không có công tơ đang treo. Chỉ cho phép chọn loại biên bản [Treo mới]"
          );
          validate = true;
          setValueLoaiBB(oldValueLoaiBB);
          setOldValueLoaiBB(oldValueLoaiBB);
          form.setFieldsValue({
            loaiBienBan: oldValueLoaiBB,
          });
        }
      } else if (value === "3" || value === 3) {
        if (
          (dataDetaiCTTreolUpdate.maCongTo !== null &&
            !form.getFieldValue("isTreoThaoTUTI")) ||
          form.getFieldValue("isNangHaTI") ||
          form.getFieldValue("isNangHaTU")
        ) {
          alertMessage(
            "error",
            "Thông báo!",
            "Thông tin thiết bị (Công tơ/TU/TI) treo mới đã đăng ký, bạn hãy xóa các thông tin này trước khi thay đổi loại biên bàn là [Tháo thu hồi]"
          );
          validate = true;
          setValueLoaiBB(oldValueLoaiBB);
          setOldValueLoaiBB(oldValueLoaiBB);
          form.setFieldsValue({
            loaiBienBan: oldValueLoaiBB,
          });
        } else {
          validate = false;
          setValueLoaiBB(value);
          setOldValueLoaiBB(value);
          form.setFieldsValue({
            loaiBienBan: value,
          });
        }
      }
    }

    if (!validate) {
      form.setFieldsValue({
        lyDoTreoThaoId: null,
      });
      getDataLyDo(value);
    }

    // if (value === "3") {
    //   if (dataDetaiCTTreolUpdate.maCongTo === null) {
    //     alertMessage(
    //       "error",
    //       "Thông báo",
    //       'Đang'
    //     );
    //   }
    // }
  };

  const handleChangeDate = (date) => {
    let convert = "";
    if (date === null) convert = null;
    else convert = moment(date).format(FORMAT_ENGLISH);
    setNgay(convert);
  };

  return (
    <ThongTinChungTabs
      detail={detail}
      dataLyDo={dataLyDo}
      setSoCot={setSoCot}
      dataNVNiemPhong={dataNVNiemPhong}
      dataNVTreoThao={dataNVTreoThao}
      onChange={onChange}
      isCheck2={isCheck2}
      isCheck1={isCheck1}
      arrLoaiBienBan={arrLoaiBienBan}
      arrViTriTreo={arrViTriTreo}
      dataSBB={dataSBB}
      handleChangeLoaiBB={handleChangeLoaiBB}
      valueLoaiBienBan={valueLoaiBienBan}
      handleChangeDate={handleChangeDate}
      ngay={ngay}
    />
  );
}
