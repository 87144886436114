import React, { memo, useEffect, useRef, useState } from "react";
import { Form, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import GhiNhanKetQuaModal from "./modal-ghi-nhan-ket-qua";
import { handeCheckXacNhan, submitThongTinCT } from "../../services";
import { scrollToTop } from "@utils/function";
import { xacNhanThiCongSelector } from "@state/cap-dien/thi-cong/xac-nhan-thi-cong";
import {
  notSavedCongTo,
  notSavedThongTinThiCong,
  setCanShowDialogLeavingTC,
  notSavedThiCong,
} from "@state/cap-dien/thi-cong/xac-nhan-thi-cong/reducer";
import { useNavigatingAway } from "@hooks/useNavigatingAway";
import ModalWarningChange from "@components/Layout/ModalWarningChange";

function Index(props) {
  const { detail, closeModal, setFetchDataTable } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("1");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [initCongTo, setInitCongTo] = useState(false);
  const [initVatTu, setInitVatTu] = useState(false);
  const [, setLoading] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [textError, setTextError] = useState("");

  const { thongTin, congTo, thiCong, canShowDialogLeavingPage } = useSelector(
    xacNhanThiCongSelector
  );
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  useEffect(() => {
    if (thongTin || congTo || thiCong) {
      dispatch(setCanShowDialogLeavingTC(true));
    } else {
      dispatch(setCanShowDialogLeavingTC(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thongTin || congTo || thiCong]);

  useEffect(() => {
    if (showDialogLeavingPage) {
      ModalWarningChange(
        () => {
          confirmNavigation();
          if (thongTin) dispatch(notSavedThongTinThiCong(false));
          if (congTo) dispatch(notSavedCongTo(false));
          if (thiCong) dispatch(notSavedThiCong(false));
        },
        () => {
          cancelNavigation();
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelNavigation, confirmNavigation, showDialogLeavingPage]);
  const callback = (key) => {
    if (thongTin || congTo || thiCong) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: "Lưu thay đổi?",
        content:
          "Nếu bạn di chuyển di chuyển / đóng tab khác mà không lưu, những thay đổi bạn đã thực hiện trước đó sẽ bị mất",
        onOk() {
          setSelectedTab(key);
          if (thongTin) {
            dispatch(notSavedThongTinThiCong(false));
            setInitVatTu(true);
          }
          if (congTo) {
            dispatch(notSavedCongTo(false));
            setInitCongTo(true);
          }
          if (thiCong) {
            //call api check xác nhận thi công
            handeCheckXacNhan(
              detail,
              setLoading,
              setSelectedTab,
              setTextError,
              key
            );
            dispatch(notSavedThiCong(false));
          }
        },
        onCancel() {},
        okText: "Đồng ý",
        okButtonProps: { type: "primary" },
        cancelText: "Hủy",
      });
    } else {
      setSelectedTab(key);
    }
  };

  const onFinishCongTo = (values) => {
    submitThongTinCT(
      detail,
      setLoadingSubmit,
      values,
      setFetchDataTable,
      (value) => dispatch(notSavedCongTo(value))
    );
  };

  const ref1 = useRef();
  useEffect(() => {
    if (scroll) scrollToTop(ref1);
  }, [scroll]);

  return (
    <div ref={ref1}>
      <GhiNhanKetQuaModal
        selectedTab={selectedTab}
        callback={callback}
        dispatch={dispatch}
        closeModal={closeModal}
        // buttonBottomBar={buttonBottomBar}
        t={t}
        form={form}
        handleSubmitCongTo={onFinishCongTo}
        setFetchDataTable={setFetchDataTable}
        detail={detail}
        loadingSubmit={loadingSubmit}
        initCongTo={initCongTo}
        setInitCongTo={setInitCongTo}
        initVatTu={initVatTu}
        setInitVatTu={setInitVatTu}
        textError={textError}
        scroll={scroll}
        setScroll={setScroll}
      />
    </div>
  );
}

export default memo(Index);
