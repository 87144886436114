import {
  ENUM_DIACHINH,
  METHOD_POST,
  METHOD_PUT,
  STATUSCODE_200,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  parseParams,
  alertMessage,
  getErrorMessage,
} from "@utils/function";
import {
  authDeleteData,
  authGetData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";

//get list data
export const handleListData = (
  setLoading,
  filterConditions,
  setDataDoi,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.LIST_DON_VI}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDoi(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataDoi([]);
    },
  });
};

//get list data
export const handleMultileListData = (
  setLoading,
  setDataNganHang,
  setDataDiaChinh
) => {
  const endpoints = [
    `${Endpoint.LIST_NGAN_HANG}?PageSize=-1`,
    `${Endpoint.LIST_DIA_CHINH}?CapDonVi=${ENUM_DIACHINH.CAPHAI}&PageSize=-1`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      if (res[0].data.data !== null) setDataNganHang(res[0].data.data);
      else setDataNganHang([]);
      if (res[1].data.data !== null) setDataDiaChinh(res[1].data.data);
      else setDataDiaChinh([]);
    },
  });
};

export const handleListApGiaUyQuyen = (
  setLoading,
  unit,
  setDataApGiaUyQuyen,
  setVisible,
) => {
  authGetData({
    url: `${Endpoint.COMMON_DM_LIST_AP_GIA_UY_QUYEN}?DonViId=${unit}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataApGiaUyQuyen(res.data);
        setVisible(true);
      } else setDataApGiaUyQuyen([]);
    },
  });
};

//create data
const handleCreateData = (data, setLoading, closeModal, getListData, form) => {
  authPostData({
    url: Endpoint.CREATE_DON_VI,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        closeModal();
        getListData();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

//update data
const handleUpdateData = (data, setLoading, closeModal, getListData, form) => {
  authPostData({
    url: Endpoint.UPDATE_DON_VI,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListData();
        closeModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

//remove data
export const handleRemoveData = (ids, setLoading, getListData, t) => {
  authDeleteData({
    url: Endpoint.REMOVE_DON_VI + "?Ids=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) getListData();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

//submit form
export const handleSubmitForm = (
  values,
  detaiDataTable,
  setLoading,
  closeModal,
  getListData,
  form,
  // isCompany,
  diaChinh,
  vung,
  bankId,
  loaiDonVi,
  dienThoai,
  ngayQuyetDinh,
  apGiaUyQuyen
) => {
  const data = {
    ...values,
    // isTongCongTy: isCompany,
    soThuTu: parseInt(values.soThuTu),
    quanId: diaChinh,
    vungDonGia: vung,
    nganHangId: bankId,
    loaiDonVi: loaiDonVi,
    dienThoai: dienThoai,
    ngayQuyetDinh: ngayQuyetDinh === "Invalid date" ? null : ngayQuyetDinh,
    uyQuyenAGId: apGiaUyQuyen
  };

  if (detaiDataTable.id) {
    data.id = detaiDataTable.id;
  }

  if (data.maDinhDanh === undefined || data.maDinhDanh === "") {
    form.setFields([
      {
        name: "maDinhDanh",
        errors: ["Mã định danh không được bỏ trống."],
      },
    ]);
  } else {
    handleCreateUpdate(data, setLoading, closeModal, getListData, form);
  }
};

// create/update dữ liệu
const handleCreateUpdate = (
  data,
  setLoading,
  closeModal,
  getListData,
  form
) => {
  if (data.id) {
    //sửa dữ liệu

    handleUpdateData(data, setLoading, closeModal, getListData, form);
  } else {
    //tạo dữ liệu

    handleCreateData(data, setLoading, closeModal, getListData, form);
  }
};
