import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { authSelector } from "@state/auth";
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select } from "antd";
import { t } from "i18next";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { handleSubmitForm } from "./service";

function ModalThongTinDonViGiaoNhan(props) {
  const { visible, closeModal, getListDataDanhMucDVGiaoNhan, detailDataTable } =
    props;
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  useEffect(() => {
    if (detailDataTable.trangThai === 1) {
      setChecked(false);
    }
    if (detailDataTable.trangThai === 0) {
      setChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    const value = {
      ...values,
      trangThai: Number(`${values.trangThai === true ? 0 : 1}`),
    };

    handleSubmitForm(
      value,
      setLoading,
      closeModal,
      getListDataDanhMucDVGiaoNhan,
      detailDataTable,
      form
    );
  };
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={
          detailDataTable.id
            ? "Sửa đơn vị giao nhận"
            : "Thông tin đơn vị giao nhận"
        }
        visible={visible}
        onCancel={closeModal}
        width={800}
        footer={null}
      >
        <FormComponent
          onFinish={onFinish}
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          name="form-thong-tin-dvgn"
          initialValues={{
            ...detailDataTable,
          }}
          colon={false}
        >
          <Form.Item
            name="maDonVi"
            label="Mã đơn vị giao nhận"
            labelAlign="left"
            required
          >
            <Input placeholder="Nhập mã đơn vị giao nhận" />
          </Form.Item>
          <Form.Item
            name="tenDonVi"
            label="Tên đơn vị"
            labelAlign="left"
            required
          >
            <Input placeholder="Nhập tên đơn vị" />
          </Form.Item>
          <Form.Item
            name="trangThai"
            labelAlign="left"
            valuePropName="checked"
            initialValue={false}
          >
            <Row span={24}>
              <Col span={8}></Col>
              <Col span={12}>
                {" "}
                <Checkbox key={1} checked={checked} onChange={onChange}>
                  Ngừng hoạt động
                </Checkbox>
              </Col>
            </Row>
          </Form.Item>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-thong-tin-dvgn"
                loading={loading}
              >
                Cập nhật
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(ModalThongTinDonViGiaoNhan);
