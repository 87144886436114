import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";

export const selectBaoCaoSMS = [
  {
    label: "Biểu 1A: Báo cáo sản lượng tin nhắn theo đơn vị",
    id: 1,
    value: Endpoint.BC_SAN_LUONG_TIN_NHAN_THEO_DON_VI,
  },
  {
    label: "Biểu 1B: Báo cáo sản lượng tin nhắn theo ngày",
    value: Endpoint.BC_SAN_LUONG_THEO_NGAY,
  },
  {
    label: "Biểu 2: Báo cáo sản lượng tin nhắn lỗi theo đơn vị",
    value: Endpoint.BC_SAN_LUONG_TN_LOI_THEO_DV,
  },
  {
    label: "Biểu 3: Danh sách khách hàng chưa gửi tin nhắn",
    value: Endpoint.DANH_SACH_KH_CHUA_GUI_TIN_NHAN,
  },
  {
    label:
      "Biểu chi tiết: Danh sách khách hàng đăng ký sai số điện thoại theo đơn vị",
    value: Endpoint.DANH_SACH_KH_DKY_SAI_DV_HOAC_SAI_SDT_CT,
  },
  {
    label:
      "Biểu tổng hợp: Danh sách khách hàng đăng ký sai số điện thoại theo đơn vị",
    value: Endpoint.DANH_SACH_KH_DKY_SAI_DV_HOAC_SAI_SDT_TH,
  },
  {
    label:
      "Biểu chi tiết: Danh sách khách hàng đăng ký sai số điện thoại theo đơn vị (30 ngày không cập nhật)",
    value: Endpoint.DANH_SACH_KH_30_NGAY_CHUA_CAP_NHAT,
  },
];
export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      maDonVi: data.MaDonVi,
      PhanLoai: data.PhanLoai,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      maDonVi: data.MaDonVi,
      PhanLoai: data.PhanLoai,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
    })
  );

  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
    },
  });
};
