/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useCallback, useLayoutEffect, useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Filter from "../../common/Filter";
import { TableStyled, Container } from "../../common/phan-cong/css-styled";
import { Tag, Form, Input, Button, Row, Col, Modal } from "antd";
import { useSelector } from "react-redux";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE, ENUM_GCSTRANGTHAISO, ENUM_KY_GCS } from "@utils/constants";
import { authSelector } from "@state/auth/reducer";
import { customColumn, handlePagination, parseParams, removeUndefinedAttribute } from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilterPhancong } from "./list-bo-loc";
import PhanCongModal from "../modal/modal-phan-cong";
import {
  handleListDataDH,
  handleListDataDT,
  handleSubmitPhanCong,
  handleHuyPhanCong,
  handleListNVTheoDoi,
  handleListDoi,
  handleCheckPC,
  getListPostionService,
  handleHuyPhanCongNhieu,
} from "./services";
import CheckPermission from "@components/CheckPermission";
import { ghi_chi_so } from "@permissions/d_gcs";
import TableComponent from "@components/TableComponent";
import "moment/locale/vi";
import moment from "moment";
import { isEmpty } from "lodash";

export default function Index(props) {
  const { permission, fetchDataTableIfNeed, setFetchDataTable } = props;
  const { t } = useTranslation();
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [term, setTerm] = useState("");
  const [dataPhanCongGCS, setDataPhanCongGCS] = useState([]);
  const [, setLoadingTenNV] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [tempDataPhanCongGCS, setTempDataPhanCongGCS] = useState([]);
  const [tempTotalCount, setTempTotalCount] = useState(0);
  const [isDT, setIsDT] = useState(false);
  const [dataDoi, setDataDoi] = useState([]);
  const [dataNV, setDataNV] = useState([]);
  const [visiblePC, setVisiblePC] = useState(false);
  const [loading, setLoading] = useState(true);
  const [disablePC, setDisablePC] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    ...parseParams(location.search),
    Ky: ENUM_KY_GCS.KY_1,
    TrangThai: ENUM_GCSTRANGTHAISO.DA_LAY_BIEU_TU_CMIS,
    NgayGhi: moment().format("YYYY-MM-DD"),
    orderBy: "maSoGCS",
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const onChangePagination = (paging, _filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setSelectedRowKeys([]);
  };
  const changeTerm = (value) => {
    setTerm(value.target.value);
  };

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
      MaSo: values.MaSo === undefined ? values.MaSo : values.MaSo.trim(),
      TenSo: values.TenSo === undefined ? values.TenSo : values.TenSo.trim(),
    }));
  }, []);

  const SearchTerm = useCallback(() => {
    if (term.trim().length > 0) {
      let listTerm = [];
      listTerm = tempDataPhanCongGCS.filter(
        (data) =>
          data.maSoGCS.toUpperCase().indexOf(term.trim().toUpperCase()) > -1 ||
          data.tenSoGCS.toUpperCase().indexOf(term.trim().toUpperCase()) > -1
      );
      setDataPhanCongGCS(listTerm);
      setTotalCount(listTerm.length);
    } else {
      setDataPhanCongGCS(tempDataPhanCongGCS);
      setTotalCount(tempTotalCount);
    }
  }, [tempDataPhanCongGCS, tempTotalCount, term]);
  //search list
  const getListData = () => {
    if (permission === ghi_chi_so.d12) {
      handleListDataDH(
        location,
        filterConditions,
        setLoading,
        setDataPhanCongGCS,
        setTempDataPhanCongGCS,
        setTotalCount,
        setTempTotalCount
      );
      return;
    }
    handleListDataDT(
      location,
      filterConditions,
      setLoading,
      setDataPhanCongGCS,
      setTempDataPhanCongGCS,
      setTotalCount,
      setTempTotalCount
    );
  };

  useEffect(() => {
    getListData();
  }, [user, location]);

  useEffect(() => {
    getListPostionService(setLoading, user, setIsDT);
  }, [user]);

  useEffect(() => {
    if (user && user.unitId) {
      handleListDoi(setLoading, setDataDoi, user.unitId);
    }
  }, [user]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListData();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListData, setFetchDataTable]);

  useEffect(() => {
    const listSelectRowKey = selectedRowKeys.filter((item) => {
      return dataPhanCongGCS.some((e) => e.id === item);
    });
    setSelectedRowKeys([...listSelectRowKey]);
  }, [dataPhanCongGCS]);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      Ky: ENUM_KY_GCS.KY_1,
      TrangThai: ENUM_GCSTRANGTHAISO.DA_LAY_BIEU_TU_CMIS,
      NgayGhi: moment().format("YYYY-MM-DD"),
      orderBy: "maSoGCS",
    });
  }, [user.unitId]);

  const columns = [
    {
      title: t("phan_cong.ma_so"),
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      render: (text) => <span>{text}</span>,
      width: "8%",
      sorter: true,
      fixed: "left",
    },
    {
      title: t("phan_cong.ten_so"),
      dataIndex: "tenSoGCS",
      key: "tenSoGCS",
      render: (text) => <span>{text}</span>,
      width: "16%",
      sorter: true,
    },
    {
      title: t("phan_cong.hinh_thuc_gcs"),
      dataIndex: "hinhThucGCS",
      key: "hinhThucGCS",
      render: (text, record) =>
        record.hinhThucGCS === "" || record.hinhThucGCS === undefined ? (
          <div className="table-text-center">
            <span>MDMS</span>
          </div>
        ) : (
          <div className="table-text-center">
            <span>{record.hinhThucGCS}</span>
          </div>
      ),
      width: "10%",
    },
    {
      title: t("phan_cong.ky_gcs"),
      dataIndex: "kyGCS",
      key: "kyGCS",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "6%",
    },
    {
      title: t("phan_cong.ngay_ghi"),
      dataIndex: "ngayGhi",
      key: "ngayGhi",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "6%",
    },
    {
      title: t("phan_cong.thang_nam"),
      dataIndex: "thangNam",
      key: "thangNam",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: t("phan_cong.to_doi"),
      dataIndex: "tenDoi",
      key: "tenDoi",
      render: (text) => <span>{text}</span>,
      width: "13%",
    },
    {
      title: t("phan_cong.nhan_vien_gcs"),
      dataIndex: "tenNhanVien",
      key: "tenNhanVien",
      render: (text) => <span>{text}</span>,
      width: "11%",
    },
    {
      title: t("phan_cong.trang_thai"),
      dataIndex: "trangThaiPhanCong",
      key: "trangThaiPhanCong",
      className: "text-center",
      render: (text, record) => (
        record.trangThaiPhanCong === ENUM_GCSTRANGTHAISO.DA_PHAN_CONG ?
          <Tag style={{ display: "block", textAlign: "center" }} color={"#26c0e9"}>{"Đã phân công"}</Tag> :
          <Tag style={{ display: "block", textAlign: "center" }} color={"#faad14"}>{"Chưa phân công"}</Tag>
      ),
      width: "11%",
    },
    {
      title: t("phan_cong.tac_vu"),
      dataIndex: "tacVu",
      key: "tacVu",
      render: (text, record) => (
        <CheckPermission permissionCode={permission === ghi_chi_so.d12 ? ghi_chi_so.d12h : ghi_chi_so.d31h}>
          <Button
            hidden={record.trangThaiPhanCong === ENUM_GCSTRANGTHAISO.DA_LAY_BIEU_TU_CMIS}
            type="primary"
            style={{ display: "block", margin: "auto" }}
            onClick={() => handleHuyPC(record.id, record.maSoGCS)}
          >
            Hủy phân công
          </Button>
        </CheckPermission>
      ),
    },
  ];

  // check trang thai phan cong
  const handleOpenModal = useCallback(() => {
    setDisablePC(true);
    handleCheckPC(selectedRowKeys, setLoading, setVisiblePC, t);
  }, [dataDoi, form, isDT, selectedRowKeys, t, user.teamName]);

  // call API get nhan vien theo doi
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeDoi = (value) => {
    handleListNVTheoDoi(value, setLoadingTenNV, setDataNV, form);
    setDisablePC(true);
  };

  const handleChangeNV = (value) => {
    if (value !== undefined) {
      setDisablePC(false);
    }
  };

  // Phân công
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFinish = (values) => {
    //call api
    const listMaSo = [];
    for (let i = 0; i < selectedRowKeys.length; i++) {
      listMaSo.push(dataPhanCongGCS.filter((data) => data.id === selectedRowKeys[i])[0].maSoGCS);
    }
    handleSubmitPhanCong(
      values.doiId,
      values.userId,
      t,
      setLoading,
      handleCloseModalPC,
      setFetchDataTable,
      setSelectedRowKeys,
      listMaSo,
      user,
      form
    );
  };

  // Hủy phân công
  const handleHuyPC = (id, maSo) => {
    handleHuyPhanCong(id, maSo, setLoading, getListData, t);
  };

  const submitHuyPCNhieu = useCallback(() => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: `Bạn chắc chắn hủy phân công các sổ đã chọn?`,
      onOk() {
        handleHuyPhanCongNhieu(selectedRowKeys, setLoading, setFetchDataTable, setSelectedRowKeys);
      },
      onCancel() {},
      okText: "Đồng ý",
      cancelText: "Hủy",
    });
  }, [selectedRowKeys, setFetchDataTable]);

  //đóng popup modal Phân công
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCloseModalPC = () => {
    setVisiblePC(false);
    setLoading(false);
  };

  //render popup
  const showModalPC = useMemo(
    () =>
      visiblePC && (
        <PhanCongModal
          handleChangeDoi={handleChangeDoi}
          isDT={isDT}
          form={form}
          visible={visiblePC}
          dataDoi={dataDoi}
          dataNV={dataNV}
          onFinish={onFinish}
          closeModal={handleCloseModalPC}
          disablePC={disablePC}
          handleChangeNV={handleChangeNV}
          setFetchDataTable={setFetchDataTable}
        />
      ),
    [dataDoi, dataNV, disablePC, form, handleChangeDoi, isDT, onFinish, setFetchDataTable, visiblePC]
  );

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: "2%",
    fixed: "left",
  };

  const handleCheckDisablePhanCong = () => {
    const listMasoChuaPhanCong = selectedRowKeys.filter((item) => {
      const index = dataPhanCongGCS.findIndex((e) => e.id === item);
      const isCheck = index >= 0 && dataPhanCongGCS[index]?.trangThaiPhanCong !== ENUM_GCSTRANGTHAISO.DA_PHAN_CONG;
      return isCheck;
    });
    if (!isEmpty(listMasoChuaPhanCong)) {
      return false;
    }
    return true;
  };

  const hadleCheckDisableHuyPhanCong = () => {
    const listMasoDaPhanCong = selectedRowKeys.filter((item) => {
      const index = dataPhanCongGCS.findIndex((e) => e.id === item);
      const isCheck = index >= 0 && dataPhanCongGCS[index]?.trangThaiPhanCong === ENUM_GCSTRANGTHAISO.DA_PHAN_CONG;
      return isCheck;
    });
    if (!isEmpty(listMasoDaPhanCong)) {
      return false;
    }
    return true;
  };
  return (
    <Fragment>
      <Filter>
        <ListFilterPhancong clearFilter={clearFilter} filterConditions={filterConditions} handleSearch={handleSearch} />
      </Filter>
      <Container>
        <Row style={{ marginBottom: "-33px", marginLeft: "10px" }}>
          <span style={{ marginTop: "6px" }}>Danh sách sổ GCS: </span>
          <Col span={24} md={8}>
            <Input
              placeholder="Nhập mã sổ, tên sổ"
              style={{ marginLeft: 20, marginBottom: 35, width: 300 }}
              onChange={changeTerm}
              onPressEnter={() => SearchTerm()}
            />
          </Col>
        </Row>
        <CheckPermission permissionCode={permission === ghi_chi_so.d12 ? ghi_chi_so.d12pc : ghi_chi_so.d31pc}>
          <Button
            type="primary"
            onClick={() => handleOpenModal()}
            disabled={handleCheckDisablePhanCong()}
            style={{ float: "right", marginTop: "-34px", marginRight: "150px" }}
            loading={loading}
          >
            Phân công
          </Button>
        </CheckPermission>
        <CheckPermission permissionCode={permission === ghi_chi_so.d12 ? ghi_chi_so.d12h : ghi_chi_so.d31h}>
          <Button
            type="primary"
            onClick={() => submitHuyPCNhieu()}
            disabled={hadleCheckDisableHuyPhanCong()}
            style={{ float: "right", marginTop: "-34px", marginRight: "10px" }}
            loading={loading}
          >
            Hủy phân công
          </Button>
        </CheckPermission>
        <TableStyled>
          <TableComponent
            header={"Phân công GCS"}
            handleSearch={handleSearch}
            dataSource={dataPhanCongGCS}
            listFilter={<ListFilterPhancong form={form} />}
            columns={customColumn(columns, filterConditions)}
            filterConditions={filterConditions}
            loading={loading}
            setFetchDataTable={setFetchDataTable}
            onChangePagination={onChangePagination}
            totalData={totalCount}
            pagination={filterConditions}
            scrollX={1700}
            rowSelection={rowSelection}
            rowKey="id"
          />
        </TableStyled>
      </Container>
      {showModalPC}
    </Fragment>
  );
}
