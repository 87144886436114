import { buildQueryString, parseParams } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authGetMultiplesRequest } from "@utils/request";
export const handleListBaoCao = (
  filterCondition,
  setLoading,
  setData,
  setTotalCount
) => {
  authGetData({
    url:
      Endpoint.GET_LIST_DICH_VU_DIEN +
      "?" +
      buildQueryString(parseParams(filterCondition)),
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        // const note = {
        //   congTyDienLuc: "(1)",
        //   tongSoTiepNhan: "(2)=(3+4+5+6+7+8)",
        //   congDVCQG: "(3)",
        //   webCSKH: "(4)",
        //   email: "(5)",
        //   appCSKH: "(6)",
        //   tongDai: "(7)",
        //   phongGDDL: "(8)",
        //   tyleTiepNhanDVDQuaCSKH: "(9)=(3+4+5+6+7)/2",
        //   tyleTiepNhanDVDQuaDVCQG: "(10)=(3)/2",
        // };
        // res.data.unshift(note);
        setData(res.data.datas);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};

export const handleMultiBoLoc = (
  setLoading,
  setLoaiYeuCau,
  setSoPha,
  setHinhThucLapDat,
  setHinhThucNopTien,
  setTinhTrang,
  setMucDich,
  setDonVi
) => {
  const endpoints = [
    `${Endpoint.COMMON_LIST_LOAI_YEU_CAU}`,
    `${Endpoint.COMMON_LIST_MUC_DICH}`,
    `${Endpoint.COMMON_LIST_SO_PHA}`,
    `${Endpoint.COMMON_LIST_HINH_THUC_LAP_DAT}`,
    `${Endpoint.COMMON_LIST_HINH_THUC_TRA_TIEN}`,
    `${Endpoint.GET_UNIT_LIST}`,
    `${Endpoint.COMMON_LIST_TINH_TRANG_YEU_CAU}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setLoaiYeuCau(res[0].data.data);
      setMucDich(res[1].data.data);
      setSoPha(res[2].data.data);
      setHinhThucLapDat(res[3].data.data);
      setHinhThucNopTien(res[4].data.data);
      setDonVi(res[5].data.data);
      setTinhTrang(res[6].data.data);
    },
  });
};
