import {
  default as DatePicker,
  default as DatePickerComponent,
} from "@components/DatePicker";
import FormComponent from "@components/Form";
import { closeModal } from "@state/system-config/reducer";
import { FORMAT_ENGLISH, FORMAT_MONTH } from "@utils/constants";
import moment from "moment/moment";
import { alertMessage, removeAccents } from "@utils/function";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
} from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  getAnhXaDiemDo,
  getCapDienAp,
  getHinhThucThanhToan,
  getLayThongTin,
  getTinhChatDD,
  getTram,
  handlerSubmitThayDoiDiemDo,
  TaoMaNgayHieuLucThayDoi,
} from "./services";

export default function ModalThayDoiTTDiemDo(props) {
  const {
    filterConditions,
    detail,
    setLoadingDanhSach,
    setDataDanhSach,
    getDanhSachDiemDo,
  } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const [dataThongTin, setDataThongTin] = useState([]);
  const [urlCapDienAp, setUrlCapDienAp] = useState([]);
  const [urlHTTT, setUrlHTTT] = useState([]);
  const [urlTinhChatDD, setUrlTinhChatDD] = useState([]);
  const [urlTram, setUrlTram] = useState([]);
  const [valueDD, setValueDD] = useState();
  const [dataAnhXa, setDataAnhXa] = useState([]);
  const [taoMaHieuLuc, setTaoMaHieuLuc] = useState([]);
  useEffect(() => {
    getCapDienAp(filterConditions, setLoading, setUrlCapDienAp);
    getHinhThucThanhToan(filterConditions, setLoading, setUrlHTTT);
    getTinhChatDD(filterConditions, setLoading, setUrlTinhChatDD);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions, form]);
  // lấy tính chất trạm theo điểm đo
  useEffect(() => {
    if (dataThongTin) {
      setValueDD(dataThongTin.tinhChatDiemDoId || urlTinhChatDD[0]?.id);
    }
  }, [dataThongTin, urlTinhChatDD]);

  useEffect(() => {
    if (valueDD) {
      getTram(setLoading, setUrlTram, valueDD);
    }
  }, [valueDD]);

  //  lấy api ngày thay đổi
  useEffect(() => {
    if (taoMaHieuLuc) {
      form.setFieldsValue({
        ...taoMaHieuLuc,
        ngayHieuLuc: taoMaHieuLuc.ngayHieuLuc,
      });
    }
  }, [form, taoMaHieuLuc]);

  // fetch data vào trong input
  useEffect(() => {
    form.setFieldsValue({
      ...dataThongTin,
      maDiemDo: dataThongTin.maDiemDo,
      maDiemDoNoiBo: dataThongTin.maDiemDoNoiBo,
      soBienBan: dataThongTin.soBienBan,
      capDienApId: dataThongTin.capDienApId,
      hinhThucThanhToanId: dataThongTin.hinhThucThanhToanId,
      tinhChatDiemDoId: dataThongTin.tinhChatDiemDoId,
      tramId: dataThongTin.tramId,
      thongTinDiemDo: dataThongTin.thongTinDiemDo,
    });
  }, [dataThongTin, form]);

  // flấy dữ liệu đo xa vào input
  useEffect(() => {
    form.setFieldsValue({
      ...dataAnhXa,
      ...taoMaHieuLuc,
      maDiemDoDoXa: dataAnhXa.maDiemDoDoXa,
      thangLaySoLieu: taoMaHieuLuc.ngayHieuLuc,
    });
  }, [dataAnhXa, form, taoMaHieuLuc]);

  // fetch api tạo mã điểm đo tự sinh
  const getTaoMa = useCallback(() => {
    TaoMaNgayHieuLucThayDoi(setTaoMaHieuLuc, setLoading, form, detail);
  }, [detail, form]);

  useEffect(() => {
    getTaoMa();
  }, [getTaoMa]);

  //  get api lấy thông tin điểm đo
  const getListLayThongTin = useCallback(() => {
    getLayThongTin(filterConditions, setLoading, setDataThongTin, detail);
  }, [detail, filterConditions]);

  useEffect(() => {
    getListLayThongTin();
  }, [getListLayThongTin]);

  //  fetch api đo xa
  const getListAnhXa = useCallback(() => {
    getAnhXaDiemDo(filterConditions, setLoading, setDataAnhXa, detail);
  }, [detail, filterConditions]);

  useEffect(() => {
    getListAnhXa();
  }, [getListAnhXa]);

  // submit dữ liệu khi bấm cập nhật
  const onFinish = (values) => {
    const payload = {
      ...values,
      kenhGiao: isValueCGKG ? "G" : isValueCNKG ? "N" : null,
      kenhNhan: isValueCGKN ? "G" : isValueCNKN ? "N" : null,
      diemDoIdCu: dataThongTin.id,
    };
    let isDup = false;
    if (
      payload.kenhGiao === payload.kenhNhan &&
      payload.kenhGiao !== null &&
      payload.kenhNhan !== null
    ) {
      alertMessage(
        "error",
        "Thông báo!",
        "Kênh giao và kênh nhận không được chọn cùng chiều"
      );
      isDup = true;
    } else {
      isDup = false;
    }
    if (!isDup)
      handlerSubmitThayDoiDiemDo(
        payload,
        closeModal,
        setLoadingDanhSach,
        form,
        filterConditions,
        dispatch,
        setDataDanhSach,
        getDanhSachDiemDo,
        setLoading
      );
  };

  // chọn điểm đo mới hiển thị mã trạm và sẽ trống khi thay đổi trạm
  const handlerChangTram = (value) => {
    setValueDD(value);
    form.setFieldsValue({
      tramId: undefined,
    });
  };

  const dispatch = useDispatch();
  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  // set kenh giao/kenh nhan
  const [isKGCG, setIsKGCG] = useState(false);
  const [isKGCN, setIsKGCN] = useState(false);
  const [isKNCG, setIsKNCG] = useState(false);
  const [isKNCN, setIsKNCN] = useState(false);
  const [isValueCGKG, setValueIsCGKG] = useState(false);
  const [isValueCNKG, setValueIsCNKG] = useState(false);
  const [isValueCGKN, setValueIsCGKN] = useState(false);
  const [isValueCNKN, setValueIsCNKN] = useState(false);

  useEffect(() => {
    const CGKG =
      dataAnhXa?.kenhGiao && dataAnhXa?.kenhGiao.replaceAll(" ", "") === "G"
        ? true
        : false;
    setValueIsCGKG(CGKG);

    const CNKG =
      dataAnhXa?.kenhGiao && dataAnhXa?.kenhGiao.replaceAll(" ", "") === "N"
        ? true
        : false;
    setValueIsCNKG(CNKG);
    const CGKN =
      dataAnhXa?.kenhNhan && dataAnhXa?.kenhNhan.replaceAll(" ", "") === "G"
        ? true
        : false;
    setValueIsCGKN(CGKN);
    const CNKN =
      dataAnhXa?.kenhNhan && dataAnhXa?.kenhNhan.replaceAll(" ", "") === "N"
        ? true
        : false;
    setValueIsCNKN(CNKN);
  }, [dataAnhXa]);

  const onChangeCheck = (e, field) => {
    if (field === "chieuGiaoKG") {
      if (e.target.checked) {
        setIsKGCN(true);
        setValueIsCNKG(false);
      } else {
        setIsKGCN(false);
      }
      setValueIsCGKG(e.target.checked);
    } else if (field === "chieuNhanKG") {
      if (e.target.checked) {
        setIsKGCG(true);
        setValueIsCGKG(false);
      } else {
        setIsKGCG(false);
      }
      setValueIsCNKG(e.target.checked);
    } else if (field === "chieuGiaoKN") {
      if (e.target.checked) {
        setIsKNCN(true);
        setValueIsCNKN(false);
      } else {
        setIsKNCN(false);
      }
      setValueIsCGKN(e.target.checked);
    } else {
      if (e.target.checked) {
        setIsKNCG(true);
        setValueIsCGKN(false);
      } else {
        setIsKNCG(false);
      }
      setValueIsCNKN(e.target.checked);
    }
  };

  return (
    <Fragment>
      <Spin spinning={loading}>
        <Container>
          <Divider orientation="left">1. Thông tin điểm đo</Divider>
          <FormComponent layout="vertical" onFinish={onFinish} form={form}>
            <Row gutter={8}>
              <Col span={8}>
                <Form.Item name="maDiemDo" label="Mã điểm đo ">
                  <Input
                    style={{ border: "1px solid #d9d9d9" }}
                    type="text"
                    size="default"
                    form={form}
                    placeholder="Nhập mã điểm đo"
                    disabled
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="ngayHieuLuc" label="Ngày thay đổi" required>
                  <DatePicker
                    formKey="ngayHieuLuc"
                    format={FORMAT_ENGLISH}
                    form={form}
                    inputReadOnly={true}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="maDiemDoNoiBo" label="Mã điểm đo nội bộ">
                  <Input
                    type="text"
                    size="default"
                    placeholder="Nhập mã điểm đo nội bộ"
                    formKey="maDiemDoNoiBo"
                    form={form}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="soBienBan" label="Số biên bản" required>
                  <Input
                    type="text"
                    formKey="soBienBan"
                    size="default"
                    placeholder=" Nhập mã điểm đo nội bộ"
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="capDienApId" label="Cấp điện áp " required>
                  <Select
                    formKey="capDienApId"
                    placeholder="Chọn cấp điện áp"
                    form={form}
                  >
                    {urlCapDienAp &&
                      urlCapDienAp.length &&
                      urlCapDienAp.map((item, idx) => (
                        <Option
                          key={idx}
                          value={item.id ? item.id.toString() : item.id}
                        >
                          {item.maCapDienAp} - {item.moTa}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="hinhThucThanhToanId"
                  label="Hình thức thanh toán"
                >
                  <Select
                    formKey="hinhThucThanhToanId"
                    placeholder="Hình thức thanh toán"
                  >
                    {urlHTTT &&
                      urlHTTT.length &&
                      urlHTTT.map((item, idx) => (
                        <Option
                          key={idx}
                          value={item.id ? item.id.toString() : item.id}
                        >
                          {item.maHinhThuc} - {item.noiDung}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={8}>
                <Form.Item
                  name="tinhChatDiemDoId"
                  label="Tính chất điểm đo"
                  required
                >
                  <Select
                    formKey="tinhChatDiemDoId"
                    placeholder="Chọn tính chất điểm đo"
                    form={form}
                    onChange={handlerChangTram}
                  >
                    {urlTinhChatDD &&
                      urlTinhChatDD.length &&
                      urlTinhChatDD.map((item, idx) => (
                        <Option
                          key={idx}
                          value={item.id ? item.id.toString() : item.id}
                        >
                          {item.maTinhChatDiemDo} - {item.moTa}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="tramId" label="Trạm" required>
                  <Select
                    formKey="tramId"
                    placeholder="Chọn trạm"
                    form={form}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      if (option && option.children) {
                        return (
                          removeAccents(option.children)
                            .toLowerCase()
                            .indexOf(removeAccents(input).toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {urlTram &&
                      urlTram.length &&
                      urlTram.map((item, idx) => (
                        <Option
                          key={idx}
                          value={item.id ? item.id.toString() : item.id}
                        >
                          {item?.moTa}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item
                  name="thongTinDiemDo"
                  label="
                Thông tin điểm đo"
                  required
                >
                  <Input
                    formKey="thongTinDiemDo"
                    placeholder="Chọn Thông tin điểm đo"
                    form={form}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left">2.Ánh xạ điểm đo(đo xa)</Divider>
            <Row gutter={12}>
              <Col span={8}>
                <Form.Item name="maDiemDoXa" label="Mã điểm đo ánh xạ (đo xa)">
                  <Input
                    type="text"
                    formKey="maDiemDoXa"
                    size="default"
                    placeholder=" Nhập mã điểm đo ánh xạ (đo xa)"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="thangLaySoLieu" label="Tháng lấy số liệu">
                  <DatePickerComponent
                    defaultValue={
                      filterConditions
                        ? moment(filterConditions)
                        : moment(moment(), FORMAT_MONTH)
                    }
                    picker="month"
                    formKey="thangLaySoLieu"
                    format={"YYYY-MM"}
                    form={form}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Form.Item name="kenhGiao" label="Kênh giao">
                  <Checkbox
                    onChange={(e) => onChangeCheck(e, "chieuGiaoKG")}
                    disabled={isKGCG}
                    checked={isValueCGKG}
                  >
                    Chiều giao
                  </Checkbox>
                  <Checkbox
                    onChange={(e) => onChangeCheck(e, "chieuNhanKG")}
                    disabled={isKGCN}
                    checked={isValueCNKG}
                  >
                    Chiều nhận
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="kenhNhan" label="Kênh nhận">
                  <Checkbox
                    onChange={(e) => onChangeCheck(e, "chieuGiaoKN")}
                    disabled={isKNCG}
                    checked={isValueCGKN}
                  >
                    Chiều giao
                  </Checkbox>
                  <Checkbox
                    onChange={(e) => onChangeCheck(e, "chieuNhanKN")}
                    disabled={isKNCN}
                    checked={isValueCNKN}
                  >
                    Chiều nhận
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Divider></Divider>
            <Row justify="center" gutter={12}>
              <Col span={4}>
                <Button
                  type="block"
                  htmlType="submit"
                  size="large"
                  className="full"
                  onClick={handleCloseModal}
                >
                  Đóng lại
                </Button>
              </Col>
              <Col span={4}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="full"
                  loading={loading}
                >
                  Cập nhật
                </Button>
              </Col>
            </Row>
          </FormComponent>
        </Container>
      </Spin>
    </Fragment>
  );
}
const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
`;
