import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { getErrorMessage } from "@utils/function";
import { authGetData, authPostData } from "@utils/request";

export const handleXacNhan = (
  setLoading,
  payload,
  setFetchList,
  closeModal
) => {
  authPostData({
    url: Endpoint.XAC_NHAN_AN_TOAN_CAP_DIEN,
    method: METHOD_PUT,
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
        closeModal();
      }
    },
  });
};

export const handleListPhieuLenh = (params, setLoading, setData) => {
  authGetData({
    url: `${Endpoint.GET_PHIEU_LENH_CONG_TAC_CAP_DIEN}?maSSONVTT=${params.maSSONVTT}&maSSONVNP=${params.maSSONVNP}&ngayTreoThao=${params.ngayTreoThao}&maDonVi=${params.maDonVi}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        // getListData();
        const data = [];
        if (res.data.length > 0) {
          res.data.forEach((el, i) => {
            data.push({
              ...el,
              stt: i + 1,
            });
          });
        }
        setData(data);
      }
    },
  });
};

export const handleReturnHoSo = (
  values,
  detail,
  setLoading,
  setFetchDataTable,
  closeModal,
  form
) => {
  const data = {
    ...values,
    hoSoId: detail.id,
  };
  authPostData({
    url: Endpoint.TRA_LAI_THI_CONG,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchDataTable(true);
        closeModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};
