import { BASE_API_URL_KT_THIETBI } from "@utils/constants";

export const APIBangKe = {
  LIST_BANGKE: `${BASE_API_URL_KT_THIETBI}/bangkethietbicongto/list`,
  GET_BANGKE: `${BASE_API_URL_KT_THIETBI}/bangkethietbicongto`,
  POST_BANGKE: `${BASE_API_URL_KT_THIETBI}/bangkethietbicongto`,
  PUT_BANGKE: `${BASE_API_URL_KT_THIETBI}/bangkethietbicongto`,
  LIST_CT_BANGKE: `${BASE_API_URL_KT_THIETBI}/congto/list-cong-to-tao-bang-ke`,
  TAO_BANG_KE: `${BASE_API_URL_KT_THIETBI}/bangkethietbicongto`,
  KIEM_TRA_LAI: `${BASE_API_URL_KT_THIETBI}/bangkethietbicongto/kiem-tra-lai`,
  PDF_BANGKE: `${BASE_API_URL_KT_THIETBI}/bangkethietbicongto/get-file-pdf-bang-ke`,
  HUY_BANGKE: `${BASE_API_URL_KT_THIETBI}/bangkethietbicongto/huy-bang-ke`,
  XACNHAN_BANGKE: `${BASE_API_URL_KT_THIETBI}/bangkethietbicongto/nv-xac-nhan-bang-ke`,
  TRA_BANGKE: `${BASE_API_URL_KT_THIETBI}/bangkethietbicongto/tra-lai-bang-ke`,
  KY_BANGKE: `${BASE_API_URL_KT_THIETBI}/bangkethietbicongto/xac-thuc-ky-so`,
  GET_HASH_KY_BANGKE: `${BASE_API_URL_KT_THIETBI}/bangkethietbicongto/get-file-hash-ky-so`,
  LEAD_XACNHAN_BANGKE: `${BASE_API_URL_KT_THIETBI}/bangkethietbicongto/xac-nhan-bang-ke`,
  KYSO_HSM_KTCT: `${BASE_API_URL_KT_THIETBI}/bangkethietbicongto/ky-duyet-hsm-bang-ke`,
};
