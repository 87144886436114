import React, { useState } from "react";
import { useSelector } from "react-redux";
import QuanLyDiemDoVaTramFilter from "./quan-li-diem-do-va-tram-list-bo-loc";
import styled from "styled-components";
import { parseParams } from "@utils/function";
import { useLocation } from "react-router-dom";
import MapComponent from "./ban-do-diem-do-va-tram";
const QuanLyDiemDoVaTram = () => {
  const donViId = useSelector((state) => state.auth.user.unitId);
  const teamId = useSelector((state) => state.auth.user.teamId);
  const location = useLocation();

  const [danhSachDiemDo, setDanhSachDiemDo] = useState([]);

  const [filterMapConditions] = useState({
    DonViId: donViId,
    DoiId: teamId,
    ...parseParams(location.search),
  });

  const diemDoDangHoatDong = danhSachDiemDo.filter(
    (diemDo) => diemDo.trangThaiDiemDo === "Đang hoạt động"
  );
  const diemDoNgungHoatDong = danhSachDiemDo.filter(
    (diemDo) => diemDo.trangThaiDiemDo === "Ngừng hoạt động"
  );

  return (
    <Styled>
      <div className="thong-ke-so-luong-diem-do">
        <div className="thong-ke-diem-do">
          <p>Đang sử dụng: {diemDoDangHoatDong.length}</p>
          <p>Ngừng sử dụng: {diemDoNgungHoatDong.length}</p>
        </div>
        <div>
          <p>Kết quả tổng hợp: {danhSachDiemDo.length} Mã điểm đo</p>
        </div>
      </div>

      <div className="quan-ly-diem-do-va-tram">
        <div className="quan-ly-diem-do-va-tram-filter">
          <QuanLyDiemDoVaTramFilter
            filterMapConditions={filterMapConditions}
            setDanhSachDiemDo={setDanhSachDiemDo}
          />
        </div>
        <MapWrapper>
          <MapComponent viTriDiemDo={danhSachDiemDo} />
        </MapWrapper>
      </div>
    </Styled>
  );
};

export default QuanLyDiemDoVaTram;
const Styled = styled.div`
.thong-ke-so-luong-diem-do {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px 0;

  .thong-ke-diem-do{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  }
}
    .quan-ly-diem-do-va-tram {
      position: relative;
      padding: 0 5px;

     
      .quan-ly-diem-do-va-tram-filter {
        position: absolute;
        width: 95%;
        padding: 20px 0 0 20px;
        z-index: 1;
      }

      .hien-thi-ban-do {
        position: relative;
      }
    }
`;
const MapWrapper = styled.div`
  height: calc(100vh - 92px);
  width: 100%;
  text-align: center;
`;
