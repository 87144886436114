import { Form, Button } from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import ChiPhiModal from "../modal";
import {
  apiUpdateThongTinChung,
  handleGetThongTinChung,
  handleListDataCN,
  handleListDataDDK,
  handleListDataKhac,
  handleListVatTu,
  handleRemoveData,
} from "../services";
import LayoutPanelsBaoCao from "./layout";
import { TYPE_CHI_PHI } from "@utils/constants";

export default function Index(props) {
  const { typeDetail, detailBaoCao, getListBaoCao, thangBaoCao } = props;
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState({});
  const [visible, setVisible] = useState(false);
  const [typeChiPhi, setTypeChiPhi] = useState(1);
  const [form] = Form.useForm();
  const [dataNgam, setDataNgam] = useState([]);
  const [dataDDK, setDataDDK] = useState([]);
  const [dataKhac, setDataKhac] = useState([]);

  const getListDataCN = useCallback(() => {
    handleListDataCN(detailBaoCao, setLoading, setDataNgam);
  }, [detailBaoCao]);

  useEffect(() => {
    getListDataCN();
  }, [getListDataCN]);

  const getListDataDDK = useCallback(() => {
    handleListDataDDK(detailBaoCao, setLoading, setDataDDK);
  }, [detailBaoCao]);

  useEffect(() => {
    getListDataDDK();
  }, [getListDataDDK]);

  const getListDataKhac = useCallback(() => {
    handleListDataKhac(detailBaoCao, setLoading, setDataKhac);
  }, [detailBaoCao]);

  useEffect(() => {
    getListDataKhac();
  }, [getListDataKhac]);

  const [detailThongTin, setDetailThongTin] = useState({});

  useEffect(() => {
    handleGetThongTinChung(setLoading, detailBaoCao, setDetailThongTin);
  }, [detailBaoCao]);

  //submit thông tin chung
  const onFinishThongTinChung = (values) => {
    const data = {
      hoSoId: detailBaoCao.hoSoId,
      ...values,
      chiPhiTBA: values.chiPhiTBA ? parseInt(values.chiPhiTBA) : null,
      congSuatTBA: values.congSuatTBA ? parseInt(values.congSuatTBA) : null,
      soLuongMBA: values.soLuongMBA ? parseInt(values.soLuongMBA) : null,
      thangBaoCao: thangBaoCao,
    };
    apiUpdateThongTinChung(setLoading, data, getListBaoCao);
  };

  const columnsDetail = [
    {
      title: "Tên loại cáp",
      key: "tenCap",
      dataIndex: "tenCap",
      width: "45%",
    },
    {
      title: "Đơn vị tính",
      key: "donViTinh",
      dataIndex: "donViTinh",
      width: "15%",
    },
    {
      title: "Số lượng",
      key: "soLuong",
      dataIndex: "soLuong",
      width: "20%",
      render: (text) => <div className="table-text-right">{text}</div>,
    },
    {
      title: "Tổng chi phí",
      key: "tongChiPhi",
      dataIndex: "tongChiPhi",
      width: "20%",
      render: (text) => <div className="table-text-right">{text}</div>,
    },
  ];

  // useEffect(() => {
  //   const column = [...newColumns];
  //   if (typeDetail) {
  //     delete column[4];
  //   }
  //   setNewColumns(column);
  // }, [newColumns, typeDetail]);

  const genExtra = (type) => (
    <div>
      {typeDetail ? (
        <div></div>
      ) : (
        <Button
          size="small"
          type="primary"
          className="mr-5px"
          onClick={() => handleOpenModal({}, type)}
        >
          Thêm mới
        </Button>
      )}
    </div>
  );

  //hiển thị popup
  const handleOpenModal = useCallback(
    (row, type) => {
      if (row !== undefined) setDetail(row);
      else setDetail({});
      setTypeChiPhi(type);
      setVisible(!visible);
    },
    [visible]
  );

  //đóng popup

  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
    setTypeChiPhi(0);
  };

  //xóa dữ liệu

  const handleDeleteData = useCallback(
    (id, type) => {
      handleRemoveData(
        thangBaoCao,
        id,
        type === TYPE_CHI_PHI.CAP_NGAM
          ? getListDataCN
          : type === TYPE_CHI_PHI.DDK
          ? getListDataDDK
          : getListDataKhac,

        setLoading,
        type
      );
    },
    [getListDataCN, getListDataDDK, getListDataKhac, thangBaoCao]
  );

  const [dataVatTu, setDataVatTu] = useState([]);
  useEffect(() => {
    if (!typeDetail) handleListVatTu(setLoading, setDataVatTu, detailBaoCao);
  }, [detailBaoCao, typeDetail]);

  const showModal = useCallback(
    () =>
      visible && (
        <ChiPhiModal
          detail={detail}
          visible={visible}
          closeModal={handleCloseModal}
          typeChiPhi={typeChiPhi}
          getListData={
            typeChiPhi === TYPE_CHI_PHI.CAP_NGAM
              ? getListDataCN
              : typeChiPhi === TYPE_CHI_PHI.DDK
              ? getListDataDDK
              : getListDataKhac
          }
          detailBaoCao={detailBaoCao}
          thangBaoCao={thangBaoCao}
          dataVatTu={dataVatTu}
        />
      ),
    [
      dataVatTu,
      detail,
      detailBaoCao,
      getListDataCN,
      getListDataDDK,
      getListDataKhac,
      thangBaoCao,
      typeChiPhi,
      visible,
    ]
  );

  const renderColumns = useCallback(
    (type) => {
      const columns = [
        {
          title: "Tên loại cáp",
          key: "tenCap",
          dataIndex: "tenCap",
          width: "35%",
        },
        {
          title: "Đơn vị tính",
          key: "donViTinh",
          dataIndex: "donViTinh",
          width: "15%",
        },
        {
          title: "Số lượng",
          key: "soLuong",
          dataIndex: "soLuong",
          width: "20%",
          render: (text) => <div className="table-text-right">{text}</div>,
        },
        {
          title: "Tổng chi phí",
          key: "tongChiPhi",
          dataIndex: "tongChiPhi",
          width: "20%",
          render: (text) => <div className="table-text-right">{text}</div>,
        },
        {
          title: "Tác vụ",
          width: "10%",
          render: (row) => (
            <div className="action-table-column">
              <div
                className="action-icon"
                onClick={() => handleOpenModal(row, type)}
              >
                <PencilIcon />
              </div>
              <div
                className="action-icon"
                onClick={() => handleDeleteData(row.id, type)}
              >
                <TrashIcon />
              </div>
            </div>
          ),
        },
      ];
      return columns;
    },
    [handleDeleteData, handleOpenModal]
  );

  return (
    <Fragment>
      <LayoutPanelsBaoCao
        loading={loading}
        form={form}
        onFinishThongTinChung={onFinishThongTinChung}
        typeDetail={typeDetail}
        columnsDetail={columnsDetail}
        renderColumns={renderColumns}
        genExtra={genExtra}
        dataNgam={dataNgam}
        dataDDK={dataDDK}
        dataKhac={dataKhac}
        detailBaoCao={detailBaoCao}
        detailThongTin={detailThongTin}
        setTypeChiPhi={setTypeChiPhi}
      />
      {showModal()}
    </Fragment>
  );
}
