import React, { memo, useState, useRef, useCallback, useEffect } from "react";
import { Button, Modal, Row, Form, Col, Input } from "antd";
import LoadingComponent from "@components/Loading";
import { useTranslation } from "react-i18next";
import FormComponent from "@components/Form";
import { PlusOutlined } from "@ant-design/icons";
import { Endpoint } from "@utils/endpoint";
import { authPostData } from "@utils/request";
import { METHOD_POST, STATUSCODE_200, } from "@utils/constants";
import { getErrorMessage } from "@utils/function";
import styled from "styled-components";


function ThemChiSo({
  showModal,
  setShowModal,
  closeThemChiSo,
  chiSoMoi,
  anhGCS,
  id,
  setFetchDataTable
}) {
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState();
  const [chiSo, setChiSo] = useState(chiSoMoi);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const inputRef = useRef(null);

  const handleFileRead = async (event) => {
    const file = event.target.files[0]
    event.target.value = null;
    const base64 = await convertBase64(file)
    setImageUrl(base64);
  }
  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
  function toDataURL(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
  const handleClick = () => {
    inputRef.current.click();
  };
  const changeChiSo = useCallback((values) => {
    setChiSo(values.target.value)
  }, []);

  const handleRemove = useCallback(() => {
    setImageUrl(null);
  }, []);

  useEffect(() => {
    toDataURL(anhGCS, function (dataUrl) {
      setImageUrl(dataUrl)
    })
  }, [anhGCS]);

  const onFinish = useCallback(
    () => {
      authPostData({
        url: Endpoint.CAP_NHAT_CHI_SO,
        method: METHOD_POST,
        payload: {
          id: id,
          chiSoMoi: chiSo,
          anhGCS: imageUrl === null ? null : imageUrl.split(',')[1]
        },
        setLoading,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200) {
            setFetchDataTable(true)
            setShowModal(false)
          } else {
            getErrorMessage(res, form);
          }
        },
      });
    },
    [chiSo, form, id, imageUrl, setFetchDataTable, setShowModal]
  );

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={"Cập nhật chỉ số"}
        visible={showModal}
        onCancel={closeThemChiSo}
        footer={null}
        width="30%"
      >
        <FormComponent
          form={form}
          name="form-cap-nhat-chi-so"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
          }}
        >
          <Row gutter={24}>
            <Col span={6}>
              <span>Chỉ số mới:</span> <span style={{ color: "red" }}>*</span>
            </Col>
            <Col span={18}>
              <Form.Item label="" name="chiSoMoi" required>
                <Input
                  style={{ width: 150, marginRight: 10 }} defaultValue={chiSoMoi} onChange={changeChiSo}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <span>Tải ảnh:</span> <span style={{ color: "red" }}>*</span>
            </Col>
            <Col span={18}>
              <Form.Item name="anhGCS" label="">
                <AvatarUploadContainerStyled>
                  <input
                    className="input-upload"
                    type="file"
                    name="myImage"
                    onChange={handleFileRead}
                    ref={inputRef}
                  />
                  <div className="avatar-preview" onClick={handleClick}>
                    {imageUrl ? (
                      <img src={imageUrl} alt="avatar" width={300} height={300} />
                    ) : (
                      <div className="no-image">
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    )}
                  </div>
                  <div className="remove-button">
                    <Button
                      type="danger"
                      size="small"
                      onClick={() => handleRemove()}
                    >
                      Xóa
                    </Button>
                  </div>
                </AvatarUploadContainerStyled>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeThemChiSo} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-cap-nhat-chi-so"
                loading={loading}
              >
                Xác nhận
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}
const AvatarUploadContainerStyled = styled.div`
  display: flex;
  align-items: end;
  .avatar-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
    margin: auto
    .no-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100px;
      height: 100px;
    }
  }
  .input-upload {
    display: none !important;
  }
  .remove-button {
    margin-left: 10px;
  }
`;
export default memo(ThemChiSo);
