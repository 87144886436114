import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  parseParams,
  alertMessage,
  getErrorMessage,
} from "@utils/function";
import { authDeleteData, authGetData, authPostData } from "@utils/request";

export const handleListData = (
  setLoading,
  filterConditions,
  setDataCauHinhChung,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.LIST_CAU_HINH_CHUNG}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataCauHinhChung(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataCauHinhChung([]);
    },
  });
};

const handleCreateData = (data, setLoading, closeModal, getListData, form) => {
  authPostData({
    url: Endpoint.CREATE_CAU_HINH_CHUNG,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        closeModal();
        getListData();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

const handleUpdateData = (data, setLoading, closeModal, getListData, form) => {
  authPostData({
    url: Endpoint.UPDATE_CAU_HINH_CHUNG,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListData();
        closeModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleRemoveData = (ids, setLoading, getListCauHinhChung, t) => {
  authDeleteData({
    url: Endpoint.REMOVE_CAU_HINH_CHUNG + "?Ids=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) getListCauHinhChung();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

export const handleSubmitForm = (
  values,
  detaiDataTable,
  setLoading,
  closeModal,
  getListData,
  form
) => {
  const data = {
    ...values,
  };

  if (detaiDataTable.id) {
    data.id = detaiDataTable.id;
  }
  // return data;
  handleCreateUpdate(data, setLoading, closeModal, getListData, form);
};

const handleCreateUpdate = (
  data,
  setLoading,
  closeModal,
  getListData,
  form
) => {
  if (data.id) {
    //sửa dữ liệu

    handleUpdateData(data, setLoading, closeModal, getListData, form);
  } else {
    //tạo dữ liệu

    handleCreateData(data, setLoading, closeModal, getListData, form);
  }
};
