import { APIBCCongto } from "../../api-url/bao-cao-kinh-doanh/bc-cong-to/index";
import { APIBCkinhdoanh } from "../../api-url/bao-cao-kinh-doanh/bc-quy-trinh-kinh-doanh/bao-cao-kinh-doanh";
import { APIBCTonghoptonthat } from "../../api-url/bao-cao-kinh-doanh/bc-quy-trinh-kinh-doanh/bao-cao-tong-hop-ton-that";
import { APIBCCongno } from "../../api-url/bao-cao-kinh-doanh/bc-cong-no/index";
import { APIBCCTKD } from "../../api-url/bao-cao-kinh-doanh/bc-quy-trinh-kinh-doanh/bc-cong-tac-kinh-doanh-dich-vu-khach-hang-moi";
import { APIBCApGiaBanDien } from "../../api-url/bao-cao-kinh-doanh/bc-ap-gia-ban-dien";
import { APIBCDichVuBanLeDienNang } from "../../api-url/bao-cao-kinh-doanh/bc-dich-vu-ban-le-dien-nang";
import { APIBCHeThongDoXa } from "../../api-url/bao-cao-kinh-doanh/bc-he-thong-do-xa";
import { APIBCTheoDoiPhatTrienKH } from "../../api-url/bao-cao-kinh-doanh/bc-theo-doi-phat-trien-khach-hang";
import { APIBCKHPhien9 } from "../../api-url/bao-cao-kinh-doanh/bao-cao-khach-hang-f9";
import { APIBCCapDien } from "../../api-url/bao-cao-kinh-doanh/bao-cao-cap-dien";
import { APIBCCongTacDichVuKH } from "../../api-url/bao-cao-kinh-doanh/bao-cao-cong-tac-dich-vu-khach-hang";
import { APIBCTinhHinhSMS } from "../../api-url/bao-cao-kinh-doanh/bao-cao-tinh-hinh-sms";
import { APIBCTinhHinhEmail } from "../../api-url/bao-cao-kinh-doanh/bao-cao-tinh-hinh-email";
import { APIBCGhiChiSo } from "../../api-url/bao-cao-kinh-doanh/bao-cao-ghi-chi-so";
import { APIBCTonThat } from "../../api-url/bao-cao-kinh-doanh/bao-cao-ton-that";
import { APIBCHoaDon } from "../../api-url/bao-cao-kinh-doanh/bao-cao-hoa-don";
import { APIBCWebEVNHaNoi } from "../../api-url/bao-cao-kinh-doanh/bao-cao-web-evn-ha-noi";
import { APIBCKhac } from "../../api-url/bao-cao-kinh-doanh/bao-cao-khac";
import { APIBCNgienCuuPhuTai } from "../../api-url/bao-cao-kinh-doanh/nghien-cuu-phu-tai";
import { APIBCTKThongTinLienHe } from "../../api-url/bao-cao-kinh-doanh/bao-cao-thong-ke-thong-tin-lien-he";
import { ApiCommonBCKD } from "../../api-url/bao-cao-kinh-doanh/common";

const CommonBCKD = {
  GET_TEAM_LIST_CODE_BCKD: ApiCommonBCKD.GET_TEAM_LIST_CODE_BCKD,
};

const BCCT = {
  // Báo cáo tổng hợp sô lượng công tơ
  BCCT_TH_SOLUONG_CONGTO_TVQ_60NGAY:
    APIBCCongto.BCCT_TH_SOLUONG_CONGTO_TVQ_60NGAY,
  BCCT_CHI_TIET_CONG_TO_60NGAY_EXCEL:
    APIBCCongto.BCCT_CHI_TIET_CONG_TO_60NGAY_EXCEL,
  BCCT_TH_SOLUONG_CONGTO_TVQ_60NGAY_EXCEL:
    APIBCCongto.BCCT_TH_SOLUONG_CONGTO_TVQ_60NGAY_EXCEL,
  BCCT_CHITIET_CONG_TO_GUI_TRA_KHO_CAPTREN_EXCEL:
    APIBCCongto.BCCT_CHITIET_CONG_TO_GUI_TRA_KHO_CAPTREN_EXCEL,
  BCCT_CHI_TIET_CONG_TO_60NGAY: APIBCCongto.BCCT_CHI_TIET_CONG_TO_60NGAY,
  BCCT_CHITIET_CONG_TO_GUI_TRA_KHO_CAPTREN:
    APIBCCongto.BCCT_CHITIET_CONG_TO_GUI_TRA_KHO_CAPTREN,
  BCCT_CHI_TIET_CONG_TO_GUITRA_KHO_EXCEL:
    APIBCCongto.BCCT_CHI_TIET_CONG_TO_GUITRA_KHO_EXCEL,
  BCCT_CHI_TIET_CONG_TO_TREN_NHAN_KIEM_DINH_EXCEL:
    APIBCCongto.BCCT_CHI_TIET_CONG_TO_TREN_NHAN_KIEM_DINH_EXCEL,
  BCCT_THSL_CONG_TO_TREN_CAP_TREN_BO_EXCEL:
    APIBCCongto.BCCT_THSL_CONG_TO_TREN_CAP_TREN_BO_EXCEL,
  BCCT_THSL_CONG_TO_GUI_TRA_LAI_KHO_EXCEL:
    APIBCCongto.BCCT_THSL_CONG_TO_GUI_TRA_LAI_KHO_EXCEL,
  BCCT_THSL_CONG_TO_CAP_TREN_NHANKD_EXCEL:
    APIBCCongto.BCCT_THSL_CONG_TO_CAP_TREN_NHANKD_EXCEL,
  BCCT_THSL_CONG_TO_CAP_TREN_PHAN_BO_PDF:
    APIBCCongto.BCCT_THSL_CONG_TO_CAP_TREN_PHAN_BO_PDF,
  BCCT_CT_CONG_TO_TREN_CAP_TREN_BO:
    APIBCCongto.BCCT_CT_CONG_TO_TREN_CAP_TREN_BO,
  BCCT_THSL_CONG_TO_KHONG_DUNG_6_THANG_TRO_LEN:
    APIBCCongto.BCCT_THSL_CONG_TO_KHONG_DUNG_6_THANG_TRO_LEN,
  BCCT_CT_CONG_TO_K_DUNG_6_THANG_TRO_LEN:
    APIBCCongto.BCCT_CT_CONG_TO_K_DUNG_6_THANG_TRO_LEN,
  BCCT_THSL_CONG_TO_CAP_TREN_THANH_LY:
    APIBCCongto.BCCT_THSL_CONG_TO_CAP_TREN_THANH_LY,
  BCCT_CT_CONG_TO_CAP_TREN_THANH_LY:
    APIBCCongto.BCCT_CT_CONG_TO_CAP_TREN_THANH_LY,
  BCCT_CT_NHAP_KHO_CONG_TO: APIBCCongto.BCCT_CT_NHAP_KHO_CONG_TO,
  BCCT_BCTH_CONG_TO_CAP_TREN_MOI: APIBCCongto.BCCT_BCTH_CONG_TO_CAP_TREN_MOI,
  BCCT_CT_CONG_TO_6_THANG_KHONG_LEN_CHI_SO_MOI:
    APIBCCongto.BCCT_CT_CONG_TO_6_THANG_KHONG_LEN_CHI_SO_MOI,
  BCCT_CT_CONG_TO_GUI_KIEM_DINH_MOI:
    APIBCCongto.BCCT_CT_CONG_TO_GUI_KIEM_DINH_MOI,
  BCCT_CT_CONG_TO_PHAN_BO_MOI: APIBCCongto.BCCT_CT_CONG_TO_PHAN_BO_MOI,
  CT_KIEM_DINH_CONG_TO_MOI: APIBCCongto.CT_KIEM_DINH_CONG_TO_MOI,
  BCCT_CT_NHAP_KHO_SAU_KD_CONG_TO_MOI:
    APIBCCongto.BCCT_CT_NHAP_KHO_SAU_KD_CONG_TO_MOI,
  BCCT_TH_CONG_TAC_KD_CONG_TO_TU_TI_MOI:
    APIBCCongto.BCCT_TH_CONG_TAC_KD_CONG_TO_TU_TI_MOI,
  BCCT_TH_CONG_TO_DEN_HAN_THAY_DINH_KY_MOI:
    APIBCCongto.BCCT_TH_CONG_TO_DEN_HAN_THAY_DINH_KY_MOI,
  BCCT_THCT_PHAN_BO_MOI: APIBCCongto.BCCT_THCT_PHAN_BO_MOI,
  BCCT_TH_THIET_BI_MOI: APIBCCongto.BCCT_TH_THIET_BI_MOI,

  //Báo cáo Tổng hợp thiết bị đo đếm 2 cấp
  BCCT_BAO_CAO_NANG_SUAT_SU_DUNG_CT_TT_TON_KHO:
    APIBCCongto.BCCT_BAO_CAO_NANG_SUAT_SU_DUNG_CT_TT_TON_KHO,
  BCCT_BAO_CAO_NHU_CAU_SU_DUNG_CT_TRONG_KHO_DUOI_LUOI:
    APIBCCongto.BCCT_BAO_CAO_NHU_CAU_SU_DUNG_CT_TRONG_KHO_DUOI_LUOI,
  BCCT_BAO_CAO_TONG_HOP_DO_DEM_CL: APIBCCongto.BCCT_BAO_CAO_TONG_HOP_DO_DEM_CL,
  BCCT_TH_CT_MHC_TL_PT_MOI: APIBCCongto.BCCT_TH_CT_MHC_TL_PT_MOI,
  BCCT_TH_TH_KD_CT: APIBCCongto.BCCT_TH_TH_KD_CT,
  BCCT_TH_TH_KT_DK_CT: APIBCCongto.BCCT_TH_TH_KT_DK_CT,
  BCCT_DANH_SACH_KT_DK_CT: APIBCCongto.BCCT_DANH_SACH_KT_DK_CT,
  BCCT_TH_THPT_CT: APIBCCongto.BCCT_TH_THPT_CT,
  BCCT_SLTB_DD_GUI_KD_QUAYVONG: APIBCCongto.BCCT_SLTB_DD_GUI_KD_QUAYVONG,
  BCCT_SLTB_DD_NHAN_KD_QUAYVONG: APIBCCongto.BCCT_SLTB_DD_NHAN_KD_QUAYVONG,
  BCCT_SLTB_DD_GUI_KD_LAI: APIBCCongto.BCCT_SLTB_DD_GUI_KD_LAI,
  BCCT_SLTB_DD_NHAN_KD_LAI: APIBCCongto.BCCT_SLTB_DD_NHAN_KD_LAI,
  BCCT_SLTB_DD_GUI_TRA: APIBCCongto.BCCT_SLTB_DD_GUI_TRA,
  BCCT_SLTB_DD_HIEN_GUI_TRA: APIBCCongto.BCCT_SLTB_DD_HIEN_GUI_TRA,
  BCCT_SLTB_DD_GUI_THANH_LY: APIBCCongto.BCCT_SLTB_DD_GUI_THANH_LY,
  BCCT_SLTB_DD_NHAN_GUI_THANH_LY: APIBCCongto.BCCT_SLTB_DD_NHAN_GUI_THANH_LY,
  BCCT_SLTB_DD_DA_THANH_LY: APIBCCongto.BCCT_SLTB_DD_DA_THANH_LY,
  BCCT_SLTB_DD_PHAN_BO: APIBCCongto.BCCT_SLTB_DD_PHAN_BO,
  BCCT_SLTB_DD_DA_NHAN_PHAN_BO: APIBCCongto.BCCT_SLTB_DD_DA_NHAN_PHAN_BO,
  BCCT_SLCL_CONG_TO_TD_SAU_TBA_CC: APIBCCongto.BCCT_SLCL_CONG_TO_TD_SAU_TBA_CC,
  BCCT_THTH_VAN_HANH_TBDD_TREN_LUOI:
    APIBCCongto.BCCT_THTH_VAN_HANH_TBDD_TREN_LUOI,
  BCCT_TBDD_DEN_HAN_KD: APIBCCongto.BCCT_TBDD_DEN_HAN_KD,
  BCCT_THTB_TON_KHO: APIBCCongto.BCCT_THTB_TON_KHO,
  BCCT_THTB_DUOI_LUOI: APIBCCongto.BCCT_THTB_DUOI_LUOI,

  //Báo cáo công tác quản lý thiết bị đo đếm
  BCCT_TH_TH_THAY_CT_DK: APIBCCongto.BCCT_TH_TH_THAY_CT_DK,
  BCCT_THTH_THAY_TUTI_DK: APIBCCongto.BCCT_THTH_THAY_TUTI_DK,
  BCCT_SLTB_DD_TON_DUOI_LUOI: APIBCCongto.BCCT_SLTB_DD_TON_DUOI_LUOI,
  BCCT_SLTB_DD_TON_DUOI_LUOI_CKD: APIBCCongto.BCCT_SLTB_DD_TON_DUOI_LUOI_CKD,
  BCCT_SLTB_DD_TON_CHUA_SU_DUNG: APIBCCongto.BCCT_SLTB_DD_TON_CHUA_SU_DUNG,
  BCCT_SLTB_DD_TON_QUA_60_NGAY: APIBCCongto.BCCT_SLTB_DD_TON_QUA_60_NGAY,
  BCCT_SLTB_DD_DANG_VAN_HANH_TL: APIBCCongto.BCCT_SLTB_DD_DANG_VAN_HANH_TL,
  BCCT_SLTB_DD_DANG_VAN_HANH_TL_DHKD:
    APIBCCongto.BCCT_SLTB_DD_DANG_VAN_HANH_TL_DHKD,
  BCCT_DANH_SACH_THPT_CT: APIBCCongto.BCCT_DANH_SACH_THPT_CT,
  BCCT_DANH_MUC_KHO_THIET_BI: APIBCCongto.BCCT_DANH_MUC_KHO_THIET_BI,

  //Tinh hinh kiem tra dinh ky cong to
  BCCT_THEM_KT_DK_CT: APIBCCongto.BCCT_THEM_KT_DK_CT,
  BCCT_SUA_KT_DK_CT: APIBCCongto.BCCT_SUA_KT_DK_CT,

  //Tinh hinh phuc tra cong to
  BCCT_THEM_THPT_CT: APIBCCongto.BCCT_THEM_THPT_CT,
  BCCT_SUA_PHUC_TRA_CONG_TO: APIBCCongto.BCCT_SUA_PHUC_TRA_CONG_TO,

  // tinh hinh cong to trong kho duoi luoi
  BCCT_DS_TINH_HINH_CONG_TO_TRONG_KHO_DUOI_LUOI:
    APIBCCongto.BCCT_DS_TINH_HINH_CONG_TO_TRONG_KHO_DUOI_LUOI,
  BCCT_THEM_TINH_HINH_CONG_TO_TON_KHO_DUOI_LUOI:
    APIBCCongto.BCCT_THEM_TINH_HINH_CONG_TO_TON_KHO_DUOI_LUOI,

  //tinh hinh cong to trong kho ton kho
  BCCT_DS_TINH_HINH_CONG_TO_TRONG_KHO_TON_KHO:
    APIBCCongto.BCCT_DS_TINH_HINH_CONG_TO_TRONG_KHO_TON_KHO,
  BCCT_THEM_TINH_HINH_CONG_TO_TRONG_KHO_TON_KHO:
    APIBCCongto.BCCT_THEM_TINH_HINH_CONG_TO_TRONG_KHO_TON_KHO,

  BCCT_LOAI_CONG_TO: APIBCCongto.BCCT_LOAI_CONG_TO,
};

const BCKD = {
  // Báo cáo kinh doanh
  BCKD_BAN_DIEN_THEO_PHU_TAI: APIBCkinhdoanh.BCKD_BAN_DIEN_THEO_PHU_TAI,
  BCKD_TH_CAC_CHI_TIEU_KD: APIBCkinhdoanh.BCKD_TH_CAC_CHI_TIEU_KD,
  BCKD_TH_CAC_CHI_TIEU_CHAT_LUONG_DV_KH:
    APIBCkinhdoanh.BCKD_TH_CAC_CHI_TIEU_CHAT_LUONG_DV_KH,
  BCKD_TH_BAN_DIEN_CUA_CAC_DON_VI:
    APIBCkinhdoanh.BCKD_TH_BAN_DIEN_CUA_CAC_DON_VI,
  BCKD_BAN_DIEN_THEO_NGANH_NGHE: APIBCkinhdoanh.BCKD_BAN_DIEN_THEO_NGANH_NGHE,
  BCKD_BAN_DIEN_THEO_DOI_TUONG_GIA:
    APIBCkinhdoanh.BCKD_BAN_DIEN_THEO_DOI_TUONG_GIA,
  BC_TH_HOA_DON_TGD: APIBCkinhdoanh.BC_TH_HOA_DON_TGD,
  BC_TH_HD_HOA_DON_TK: APIBCkinhdoanh.BC_TH_HD_HOA_DON_TK,
  BCKD_SAN_LUONG_TIET_KIEM_DIEN: APIBCkinhdoanh.BCKD_SAN_LUONG_TIET_KIEM_DIEN,
  BCKD_SO_DU_VA_TAI_KHOAN_THU: APIBCkinhdoanh.BCKD_SO_DU_VA_TAI_KHOAN_THU,
  BCKD_TH_BAN_DIEN: APIBCkinhdoanh.BCKD_TH_BAN_DIEN,
  BCKD_CHI_TIEU_CHAT_LUONG_CSKH: APIBCkinhdoanh.BCKD_CHI_TIEU_CHAT_LUONG_CSKH,
  BCKD_TONG_HOP_DIEN_NANG_GIAO_NHAN_DAU_NGUON:
    APIBCkinhdoanh.BCKD_TONG_HOP_DIEN_NANG_GIAO_NHAN_DAU_NGUON,
  BCKD_SO_CONG_TO_CAC_DON_VI: APIBCkinhdoanh.BCKD_SO_CONG_TO_CAC_DON_VI,
  BCKD_SO_HOP_DONG_MUA_BAN_DIEN: APIBCkinhdoanh.BCKD_SO_HOP_DONG_MUA_BAN_DIEN,
  BCKD_TI_LE_DIEN_NANG_TRUYEN_TAI:
    APIBCkinhdoanh.BCKD_TI_LE_DIEN_NANG_TRUYEN_TAI,
  //Danh sach nhan vien
  BCKD_DANH_SACH_NHAN_VIEN: APIBCkinhdoanh.BCKD_DANH_SACH_NHAN_VIEN,
  //Báo cáo tin nhắn zalo
  BCKD_BC_TN_ZALO: APIBCkinhdoanh.BCKD_BC_TN_ZALO,
  //Báo cáo phân hệ hóa đơn
  BCKD_BC_KHNC_PHU_TAI: APIBCkinhdoanh.BCKD_BC_KHNC_PHU_TAI,
  BCKD_TK_KH_CO_SL_TU_1_3: APIBCkinhdoanh.BCKD_TK_KH_CO_SL_TU_1_3,
  BCKD_CT_KH_CO_SL_TU_1_3: APIBCkinhdoanh.BCKD_CT_KH_CO_SL_TU_1_3,
  // Danh sách khách hàng cảnh báo áp giá hành chính nhân sự
  BCKD_DSKH_CANH_BAO_AP_GIA_HANH_CHINH_NHAN_SU:
    APIBCkinhdoanh.BCKD_DSKH_CANH_BAO_AP_GIA_HANH_CHINH_NHAN_SU,
};

const BCCTKD = {
  //Báo cáo công tác kinh doanh dịch vụ khách hàng mới
  BCCTKD_GIA_KH_LAP_DAT_MAT_TROI_AP_MAI:
    APIBCCTKD.BCCTKD_GIA_KH_LAP_DAT_MAT_TROI_AP_MAI,

  BCCTKD_QLSD_KIM_CHI_THEO_MA_KIM_LUY_KE:
    APIBCCTKD.BCCTKD_QLSD_KIM_CHI_THEO_MA_KIM_LUY_KE,

  BCCTKD_QLSD_KIM_CHI_THEO_MA_KIM: APIBCCTKD.BCCTKD_QLSD_KIM_CHI_THEO_MA_KIM,

  BCCTKD_CT_QL_SU_DUNG_KIM_CHI_NIEM_PHONG_TONG_HOP_LUY_KE:
    APIBCCTKD.BCCTKD_CT_QL_SU_DUNG_KIM_CHI_NIEM_PHONG_TONG_HOP_LUY_KE,

  BCCTKD_CT_QL_KIM_CHI_NIEM_PHONG_TONG_HOP:
    APIBCCTKD.BCCTKD_CT_QL_KIM_CHI_NIEM_PHONG_TONG_HOP,

  BCCTKD_DSKH_LAP_DAT_MAT_TROI_AP_MAI:
    APIBCCTKD.BCCTKD_DSKH_LAP_DAT_MAT_TROI_AP_MAI,

  BCCTKD_CHI_TIET_KH_LAP_DAT_MAT_TROI_AP_MAI:
    APIBCCTKD.BCCTKD_CHI_TIET_KH_LAP_DAT_MAT_TROI_AP_MAI,

  BCCTKD_DS_SU_DUNG_KIM_CHI_NIEM_PHONG:
    APIBCCTKD.BCCTKD_DS_SU_DUNG_KIM_CHI_NIEM_PHONG,

  BCCTKD_THEM_SU_DUNG_KIM_CHI_NIEM_PHONG:
    APIBCCTKD.BCCTKD_THEM_SU_DUNG_KIM_CHI_NIEM_PHONG,

  BCCTKD_XOA_SU_DUNG_KIM_CHI_NIEM_PHONG:
    APIBCCTKD.BCCTKD_XOA_SU_DUNG_KIM_CHI_NIEM_PHONG,

  BCCTKD_DS_DOI: APIBCCTKD.BCCTKD_DS_DOI,

  BCCTKD_DS_NHAN_VIEN: APIBCCTKD.BCCTKD_DS_NHAN_VIEN,
};

const BCTHTT = {
  //Báo cáo tổng hợp tổn thất
  BCTHTT_TY_LE_DIEN_NANG_TRUYEN_TAI:
    APIBCTonghoptonthat.BCTHTT_TY_LE_DIEN_NANG_TRUYEN_TAI,
  BCTHTT_TH_TON_THAT_DN_TRAM_CONG_CONG:
    APIBCTonghoptonthat.BCTHTT_TH_TON_THAT_DN_TRAM_CONG_CONG,

  BCTHTT_BC_TON_THAT_LO_DUONG_DAY:
    APIBCTonghoptonthat.BCTHTT_BC_TON_THAT_LO_DUONG_DAY,
  BCTHTT_BC_TON_THAT_TRUNG_AP_NGAY_1:
    APIBCTonghoptonthat.BCTHTT_BC_TON_THAT_TRUNG_AP_NGAY_1,
  BCTHTT_BC_TON_THAT_DUONG_DAY_NGAY_1:
    APIBCTonghoptonthat.BCTHTT_BC_TON_THAT_DUONG_DAY_NGAY_1,
  BCTHTT_TH_CHI_TIEU_TON_THAT_DN_THEO_PHIEN_GHI_CHI_SO:
    APIBCTonghoptonthat.BCTHTT_TH_CHI_TIEU_TON_THAT_DN_THEO_PHIEN_GHI_CHI_SO,
  BCTHTT_BC_SAN_LUONG_DIEN_NANG_DAU_NGUON_3_GIA:
    APIBCTonghoptonthat.BCTHTT_BC_SAN_LUONG_DIEN_NANG_DAU_NGUON_3_GIA,
};

const BCCN = {
  //Tổng hợp thanh toán qua ngân hàng tổ chức trung gian thanh toán
  BCCN_BC_KHACHHANG_THUC_TRUY_NO_QUA_ECPAY:
    APIBCCongno.BCCN_BC_KHACHHANG_THUC_TRUY_NO_QUA_ECPAY,
  BCCN_BC_CT_KHACHHANG_TRICH_NO_TU_DONG:
    APIBCCongno.BCCN_BC_CT_KHACHHANG_TRICH_NO_TU_DONG,
  BCCN_BAO_CAO_GIAM_SAT_CONG_TAC_THU_TIEN_DIEN_CUA_NGUOI_THUE_NHA:
    APIBCCongno.BCCN_BAO_CAO_GIAM_SAT_CONG_TAC_THU_TIEN_DIEN_CUA_NGUOI_THUE_NHA,
  BCCN_BC_SO_THU_DICH_VU_TIEN_DIEN_QUA_NH_TGTGTT:
    APIBCCongno.BCCN_BC_SO_THU_DICH_VU_TIEN_DIEN_QUA_NH_TGTGTT,
  BCCN_BC_SO_THU_DICH_VU_TIEN_DIEN_QUA_NH_TGTGTT_DONVI:
    APIBCCongno.BCCN_BC_SO_THU_DICH_VU_TIEN_DIEN_QUA_NH_TGTGTT_DONVI,
  BCCN_BC_SO_THU_DICH_VU_TIEN_DIEN_QUA_NH_TGTGTT_THEONGAY:
    APIBCCongno.BCCN_BC_SO_THU_DICH_VU_TIEN_DIEN_QUA_NH_TGTGTT_THEONGAY,
  BCCN_BC_SO_THU_ECPAY_KENH_AUTO: APIBCCongno.BCCN_BC_SO_THU_ECPAY_KENH_AUTO,
  BCCN_BC_TH_SO_THU_THEO_CAC_PHUONG_THUC_VA_HINH_THUC_THU:
    APIBCCongno.BCCN_BC_TH_SO_THU_THEO_CAC_PHUONG_THUC_VA_HINH_THUC_THU,
  BCCN_BC_TH_THU_NOP_QUA_NH: APIBCCongno.BCCN_BC_TH_THU_NOP_QUA_NH,
  BCCN_THU_HO_CAC_TCTG_VA_NGAN_HANG:
    APIBCCongno.BCCN_THU_HO_CAC_TCTG_VA_NGAN_HANG,
  BCCN_TH_THANH_TOAN_24_48H: APIBCCongno.BCCN_TH_THANH_TOAN_24_48H,
  BCCN_DS_THU_TIEN_DIEN_CUA_NGUOI_THUE_NHA:
    APIBCCongno.BCCN_DS_THU_TIEN_DIEN_CUA_NGUOI_THUE_NHA,
  BCCN_THEM_THU_TIEN_DIEN_CUA_NGUOI_THUE_NHA:
    APIBCCongno.BCCN_THEM_THU_TIEN_DIEN_CUA_NGUOI_THUE_NHA,
  //Báo cáo chấm điểm dòng tiền
  BCCN_BC_CHAM_DIEM_DONG_TIEN: APIBCCongno.BCCN_BC_CHAM_DIEM_DONG_TIEN,
  BCCN_BIEU_TY_LE_TH_THU_NOP_TIEN_DIEN:
    APIBCCongno.BCCN_BIEU_TY_LE_TH_THU_NOP_TIEN_DIEN,

  //Báo cáo quản lý dòng tiền
  BCCN_BC_TONG_HOP_DONG_TIEN: APIBCCongno.BCCN_BC_TONG_HOP_DONG_TIEN,
  BCCN_BC_CHI_TIET_DONG_TIEN_THEO_NGAY_CUA_TUNG_DON_VI:
    APIBCCongno.BCCN_BC_CHI_TIET_DONG_TIEN_THEO_NGAY_CUA_TUNG_DON_VI,

  //Báo cáo hỗ trợ công nợ và dòng tiền
  BCCN_BIEU6_BANG_KE_THU_HO_CONG_TY_DIEN_LUC_KHAC:
    APIBCCongno.BCCN_BIEU6_BANG_KE_THU_HO_CONG_TY_DIEN_LUC_KHAC,
  BCCN_BIEU11_SO_THU_DICH_VU_TIEN_DIEN_NGAN_HANG_TO_CHUC_TGTT:
    APIBCCongno.BCCN_BIEU11_SO_THU_DICH_VU_TIEN_DIEN_NGAN_HANG_TO_CHUC_TGTT,
  BCCN_BANG_KE_HOA_DON_DUOC_CONG_TY_DIEN_LUC_KHAC_THU_HO:
    APIBCCongno.BCCN_BANG_KE_HOA_DON_DUOC_CONG_TY_DIEN_LUC_KHAC_THU_HO,
  BCCN_TONG_HOP_HOA_DON_DUOC_CONG_TY_DIEN_LUC_KHAC_THU_HO:
    APIBCCongno.BCCN_TONG_HOP_HOA_DON_DUOC_CONG_TY_DIEN_LUC_KHAC_THU_HO,
  BCCN_TONG_HOP_HOA_DON_THU_HO_CONG_TY_DIEN_LUC_KHAC:
    APIBCCongno.BCCN_TONG_HOP_HOA_DON_THU_HO_CONG_TY_DIEN_LUC_KHAC,
  BCCN_TONG_HOP_KHACH_HANG_THANH_TOAN_PHI_CAT_DIEN:
    APIBCCongno.BCCN_TONG_HOP_KHACH_HANG_THANH_TOAN_PHI_CAT_DIEN,
  BCCN_TONG_HOP_KHACH_HANG_THANH_TOAN_TREN_WEB:
    APIBCCongno.BCCN_TONG_HOP_KHACH_HANG_THANH_TOAN_TREN_WEB,
  BCCN_TONG_HOP_THU_HO_TONG_CONG_TY:
    APIBCCongno.BCCN_TONG_HOP_THU_HO_TONG_CONG_TY,

  //Báo cáo dư nợ thủy nông
  BCCN_BC_DU_NO_THUY_NONG: APIBCCongno.BCCN_BC_DU_NO_THUY_NONG,

  //Báo cáo dư nọ đèn đường
  BCCN_BC_DU_NO_DEN_DUONG: APIBCCongno.BCCN_BC_DU_NO_DEN_DUONG,

  //Tổng hợp số tiền đã thu của khách dùng điện 13g
  BCCN_TH_SO_TIEN_DA_THU_CUA_KHACH_DUNG_DIEN_13G:
    APIBCCongno.BCCN_TH_SO_TIEN_DA_THU_CUA_KHACH_DUNG_DIEN_13G,
  BCCN_TH_SO_TIEN_DA_THU_CUA_KHACH_DUNG_DIEN_13G_MOI:
    APIBCCongno.BCCN_TH_SO_TIEN_DA_THU_CUA_KHACH_DUNG_DIEN_13G_MOI,
  BCCN_DANH_SACH_CAP_NHAT_13H: APIBCCongno.BCCN_DANH_SACH_CAP_NHAT_13H,
  BCCN_THEM_CAP_NHAT_13H: APIBCCongno.BCCN_THEM_CAP_NHAT_13H,

  //Tổng hợp số tiền khách hàng còn nợ 13h
  BCCN_TH_SO_TIEN_KHACH_HANG_CON_NO_13H:
    APIBCCongno.BCCN_TH_SO_TIEN_KHACH_HANG_CON_NO_13H,
  //Báo cáo số thu thu theo hóa đơn
  BCCN_BC_SO_THU_THEO_HOA_DON: APIBCCongno.BCCN_BC_SO_THU_THEO_HOA_DON,

  //Báo cáo tỷ lệ số thu theo số tiền
  BCCN_BC_SO_THU_THEO_SO_TIEN: APIBCCongno.BCCN_BC_SO_THU_THEO_SO_TIEN,

  //Báo cáo tổng hợp và quản lí dòng tiền
  BCCN_BC_TONG_HOP_VA_QUAN_LI_DONG_TIEN:
    APIBCCongno.BCCN_BC_TONG_HOP_VA_QUAN_LI_DONG_TIEN,

  //Báo cáo tổng hợp khách hàng nợ quá hạn
  BCCN_BC_TONG_HOP_KH_NO_QUA_HAN: APIBCCongno.BCCN_BC_TONG_HOP_KH_NO_QUA_HAN,

  //Báo cáo kiểm kê 0 giờ
  BCCN_BANG_KIEM_KE_CONG_NO_PHAI_TRA:
    APIBCCongno.BCCN_BANG_KIEM_KE_CONG_NO_PHAI_TRA,
  BCCN_BANG_KIEM_KE_CONG_NO_PHAI_THU:
    APIBCCongno.BCCN_BANG_KIEM_KE_CONG_NO_PHAI_THU,
  BCCN_BC_SO_PHAI_THU_TRU_SO_PHAI_TRA:
    APIBCCongno.BCCN_BC_SO_PHAI_THU_TRU_SO_PHAI_TRA,
};

const BCAGBD = {
  //Khách hàng mua điện sinh hoạt có từ hơn 1 định mức trở lên
  BCAGBD_DS_TOAN_BO_KHACH_HANG: APIBCApGiaBanDien.BCAGBD_DS_TOAN_BO_KHACH_HANG,
  BCAGBD_DSKH_QUA_HAN_KIEM_TRA_AP_GIA:
    APIBCApGiaBanDien.BCAGBD_DSKH_QUA_HAN_KIEM_TRA_AP_GIA,
  BCAGBD_DSKH_DA_KIEM_TRA_AP_GIA:
    APIBCApGiaBanDien.BCAGBD_DSKH_DA_KIEM_TRA_AP_GIA,
  BCAGBD_DSKH_DEN_HAN_KIEM_TRA_AP_GIA:
    APIBCApGiaBanDien.BCAGBD_DSKH_DEN_HAN_KIEM_TRA_AP_GIA,
  BCAGBD_DSKH_CO_THAY_DOI_SAU_KHI_KIEM_TRA_AP_GIA:
    APIBCApGiaBanDien.BCAGBD_DSKH_CO_THAY_DOI_SAU_KHI_KIEM_TRA_AP_GIA,

  //Báo cáo giá bán điện theo lộ đường dây
  BCAGBD_BC_GIA_BAN_DIEN_THEO_LO_DUONG_DAY:
    APIBCApGiaBanDien.BCAGBD_BC_GIA_BAN_DIEN_THEO_LO_DUONG_DAY,

  //Báo cáo tổng hợp
  BCAGBD_TH_GIA_BAN_DIEN_BINH_QUAN:
    APIBCApGiaBanDien.BCAGBD_TH_GIA_BAN_DIEN_BINH_QUAN,
  BCAGBD_TH_CONG_TAC_AP_GIA_BAN_DIEN:
    APIBCApGiaBanDien.BCAGBD_TH_CONG_TAC_AP_GIA_BAN_DIEN,
  BCAGBD_BCKQ_DIEU_TRA_DINH_MUC_CAC_HO_SU_DUNG_DIEN_SINH_HOAT:
    APIBCApGiaBanDien.BCAGBD_BCKQ_DIEU_TRA_DINH_MUC_CAC_HO_SU_DUNG_DIEN_SINH_HOAT,

  //Báo cáo áp giá bán điện B09
  BCAGBD_BC_CONG_TO_TINH_TOI_THOI_DIEM:
    APIBCApGiaBanDien.BCAGBD_BC_CONG_TO_TINH_TOI_THOI_DIEM,
  BCAGBD_BCKQ_KT_DINH_MUC_CAC_HO_SD_CO_2_DINH_MUC:
    APIBCApGiaBanDien.BCAGBD_BCKQ_KT_DINH_MUC_CAC_HO_SD_CO_2_DINH_MUC,
  BCAGBD_BC_SL_HOP_DONG_MUA_BAN_DIEN_TINH_TOI_THOI_DIEM:
    APIBCApGiaBanDien.BCAGBD_BC_SL_HOP_DONG_MUA_BAN_DIEN_TINH_TOI_THOI_DIEM,
  BCAGBD_BC_TH_CT_KT_AP_GIA_BAN_DIEN_TINH_TOI_THOI_DIEM:
    APIBCApGiaBanDien.BCAGBD_BC_TH_CT_KT_AP_GIA_BAN_DIEN_TINH_TOI_THOI_DIEM,

  //Báo cáo áp giá bán điện B09 mới
  BCAGBD_KH_CHO_THUE_TRO_DA_AP_DINH_MUC_SINH_HOAT_BAC_THANG:
    APIBCApGiaBanDien.BCAGBD_KH_CHO_THUE_TRO_DA_AP_DINH_MUC_SINH_HOAT_BAC_THANG,
  BCAGBD_KH_CHO_THUE_TRO_DA_AP_DINH_MUC_SINH_HOAT_CUA_BAC_3:
    APIBCApGiaBanDien.BCAGBD_KH_CHO_THUE_TRO_DA_AP_DINH_MUC_SINH_HOAT_CUA_BAC_3,
  BCAGBD_TK_DS_DIEM_DO_KIEM_TRA_CO_GIA_BENH_VIEN_TH:
    APIBCApGiaBanDien.BCAGBD_TK_DS_DIEM_DO_KIEM_TRA_CO_GIA_BENH_VIEN_TH,
  BCAGBD_TK_DS_DIEM_DO_KIEM_TRA_CO_GIA_HANH_CHINH_SU_NGHIEM:
    APIBCApGiaBanDien.BCAGBD_TK_DS_DIEM_DO_KIEM_TRA_CO_GIA_HANH_CHINH_SU_NGHIEM,
  BCAGBD_TK_DS_DIEM_DO_KIEM_TRA_CO_GIA_SAN_XUAT:
    APIBCApGiaBanDien.BCAGBD_TK_DS_DIEM_DO_KIEM_TRA_CO_GIA_SAN_XUAT,
  BCAGBD_TK_DS_DIEM_DO_SINH_HOAT_CO_NHIEU_MD_SU_DUNG_DIEN:
    APIBCApGiaBanDien.BCAGBD_TK_DS_DIEM_DO_SINH_HOAT_CO_NHIEU_MD_SU_DUNG_DIEN,
  BCAGBD_TK_DS_DIEM_DO_SINH_HOAT_KT_CO_SO_DINH_MUC_LON_HON_BANG_2:
    APIBCApGiaBanDien.BCAGBD_TK_DS_DIEM_DO_SINH_HOAT_KT_CO_SO_DINH_MUC_LON_HON_BANG_2,
  BCAGBD_KTGS_CONG_TAC_THU_TIEN_DIEN:
    APIBCApGiaBanDien.BCAGBD_KTGS_CONG_TAC_THU_TIEN_DIEN,
  BCAGBD_CHI_TIET_KTGS_CONG_TAC_THU_TIEN_DIEN:
    APIBCApGiaBanDien.BCAGBD_CHI_TIET_KTGS_CONG_TAC_THU_TIEN_DIEN,
  //Khách hàng mua điện ngoài mục đích sinh hoạt
  BCAGBD_NMDSH_DANH_MUC_NGANH_NGHE:
    APIBCApGiaBanDien.BCAGBD_NMDSH_DANH_MUC_NGANH_NGHE,
  BCAGBD_NMDSH_DSKH_CO_THAY_DOI_SAU_KHI_KT_AP_GIA:
    APIBCApGiaBanDien.BCAGBD_NMDSH_DSKH_CO_THAY_DOI_SAU_KHI_KT_AP_GIA,
  BCAGBD_NMDSH_DSKH_DA_KT_AP_GIA:
    APIBCApGiaBanDien.BCAGBD_NMDSH_DSKH_DA_KT_AP_GIA,
  BCAGBD_NMDSH_DSKH_DEN_HAN_KT_AP_GIA:
    APIBCApGiaBanDien.BCAGBD_NMDSH_DSKH_DEN_HAN_KT_AP_GIA,
  BCAGBD_NMDSH_DSKH_QUA_HAN_KT_AP_GIA:
    APIBCApGiaBanDien.BCAGBD_NMDSH_DSKH_QUA_HAN_KT_AP_GIA,
  BCAGBD_NMDSH_DS_TOAN_BO_KHACH_HANG:
    APIBCApGiaBanDien.BCAGBD_NMDSH_DS_TOAN_BO_KHACH_HANG,
};

const BCDVBLDN = {
  BCDVBLDN_BC_CONG_TAC_TO_CHUC_VA_HOAT_DONG_BAN_LE_DIEN_NANG:
    APIBCDichVuBanLeDienNang.BCDVBLDN_BC_CONG_TAC_TO_CHUC_VA_HOAT_DONG_BAN_LE_DIEN_NANG,
  BCDVBLDN_CAP_NHAT_CT_TCVHD_BAN_LE_DIEN_NANG:
    APIBCDichVuBanLeDienNang.BCDVBLDN_CAP_NHAT_CT_TCVHD_BAN_LE_DIEN_NANG,
};

const BCHTDX = {
  //Báo cáo hệ thống đo xa
  BCHTDX_DANH_SACH_MA_TRAM: APIBCHeThongDoXa.BCHTDX_DANH_SACH_MA_TRAM,
  BCHTDX_BCSL_CHUNG_LOAI_CONG_TO_DEN_HAN_THAY_DINH_KY:
    APIBCHeThongDoXa.BCHTDX_BCSL_CHUNG_LOAI_CONG_TO_DEN_HAN_THAY_DINH_KY,
  BCHTDX_BCSL_CHUNG_LOAI_CONG_TO_THEO_TRAM:
    APIBCHeThongDoXa.BCHTDX_BCSL_CHUNG_LOAI_CONG_TO_THEO_TRAM,
  BCHTDX_DS_CAC_DIEM_DO_CO_DL_PHUC_VU_LAP_HOA_DON:
    APIBCHeThongDoXa.BCHTDX_DS_CAC_DIEM_DO_CO_DL_PHUC_VU_LAP_HOA_DON,
  BCHTDX_SL_KH_LON_DA_LAP_DO_XA: APIBCHeThongDoXa.BCHTDX_SL_KH_LON_DA_LAP_DO_XA,
  BCHTDX_TONG_HOP_DD_MAT_KET_NOI:
    APIBCHeThongDoXa.BCHTDX_TONG_HOP_DD_MAT_KET_NOI,
  BCHTDX_DS_DD_THANH_LY_VA_DD_THAY_THE:
    APIBCHeThongDoXa.BCHTDX_DS_DD_THANH_LY_VA_DD_THAY_THE,
};

const BCTDPTKH = {
  //Báo cáo Theo dõi phát triển khách hàng
  BCTDPTKH_BC_KT_GIAM_SAT_BAN_DIEN:
    APIBCTheoDoiPhatTrienKH.BCTDPTKH_BC_KT_GIAM_SAT_BAN_DIEN,
  BCTDPTKH_BC_KT_GIAM_SAT_CONG_TAC_THU_TIEN_DIEN:
    APIBCTheoDoiPhatTrienKH.BCTDPTKH_BC_KT_GIAM_SAT_CONG_TAC_THU_TIEN_DIEN,
};

const BCKHP9 = {
  //Báo cáo khách hàng phiên 9
  BCKHP9_BC_TTKH_P9_THAY_DOI_TRONG_THANG:
    APIBCKHPhien9.BCKHP9_BC_TTKH_P9_THAY_DOI_TRONG_THANG,
  BCKHP9_BC_TTKH_P9_DANG_LAP_CONG_TO_DIEN_TU:
    APIBCKHPhien9.BCKHP9_BC_TTKH_P9_DANG_LAP_CONG_TO_DIEN_TU,
  BCKHP9_BC_TTKH_P9_DANG_LAP_CONG_TO_CO_KHI:
    APIBCKHPhien9.BCKHP9_BC_TTKH_P9_DANG_LAP_CONG_TO_CO_KHI,
  BCCD_BC_TINH_HINH_CAP_DIEN_MOI_SHKV_THANH_PHO:
    APIBCKHPhien9.BCCD_BC_TINH_HINH_CAP_DIEN_MOI_SHKV_THANH_PHO,
  BCCD_BC_TINH_HINH_CAP_DIEN_MOI_SHKV_NONG_THON:
    APIBCKHPhien9.BCCD_BC_TINH_HINH_CAP_DIEN_MOI_SHKV_NONG_THON,
  BCCD_BC_TINH_HINH_CAP_DIEN_MOI_KH_NGOAI_SINH_HOAT:
    APIBCKHPhien9.BCCD_BC_TINH_HINH_CAP_DIEN_MOI_KH_NGOAI_SINH_HOAT,
  BCCD_HOAN_TAT_HSCD_HA_AP: APIBCKHPhien9.BCCD_HOAN_TAT_HSCD_HA_AP,
  BCCD_CT_KH_CAP_DIEN_HA_AP: APIBCKHPhien9.BCCD_CT_KH_CAP_DIEN_HA_AP,
};

const BCCD = {
  //Báo cáo cấp điện
  BCCD_TH_TRANG_WEB_CAP_DIEN: APIBCCapDien.BCCD_TH_TRANG_WEB_CAP_DIEN,
  BCCD_BC_TINH_HINH_CAP_DIEN_MOI_SHKV_THANH_PHO:
    APIBCCapDien.BCCD_BC_TINH_HINH_CAP_DIEN_MOI_SHKV_THANH_PHO,
  BCCD_BC_TINH_HINH_CAP_DIEN_MOI_SHKV_NONG_THON:
    APIBCCapDien.BCCD_BC_TINH_HINH_CAP_DIEN_MOI_SHKV_NONG_THON,
  BCCD_BC_TINH_HINH_CAP_DIEN_MOI_KH_NGOAI_SINH_HOAT:
    APIBCCapDien.BCCD_BC_TINH_HINH_CAP_DIEN_MOI_KH_NGOAI_SINH_HOAT,
  BCCD_HOAN_TAT_HSCD_HA_AP: APIBCCapDien.BCCD_HOAN_TAT_HSCD_HA_AP,
  BCCD_CT_KH_CAP_DIEN_HA_AP: APIBCCapDien.BCCD_CT_KH_CAP_DIEN_HA_AP,
  BCCD_THOI_GIAN_TB_GQCD: APIBCCapDien.BCCD_THOI_GIAN_TB_GQCD,
  BCCD_TH_HS_CO_TRO_NGAI: APIBCCapDien.BCCD_TH_HS_CO_TRO_NGAI,
};

const BCCTDVKH = {
  //  Bao cao he sinh thai moi
  BCHST_BC_TONG_HOP: APIBCCongTacDichVuKH.BCHST_BC_TONG_HOP,
  BCHST_TONG_HOP_SLKH_DANG_KY: APIBCCongTacDichVuKH.BCHST_TONG_HOP_SLKH_DANG_KY,
  BCHST_CHI_TIET_HST_KH: APIBCCongTacDichVuKH.BCHST_CHI_TIET_HST_KH,
  BCHST_TH_KHACH_HANG_SU_DUNG_HST:
    APIBCCongTacDichVuKH.BCHST_TH_KHACH_HANG_SU_DUNG_HST,
  //Báo cáo Công tác dịch vụ khách hàng
  BCCTDVKH_BAO_CAO_CTKH_DANG_KY_DV_CONG:
    APIBCCongTacDichVuKH.BCCTDVKH_BAO_CAO_CTKH_DANG_KY_DV_CONG,
  BCCTDVKH_TIEP_NHAN_DANG_KY_DV_DIEN_DT:
    APIBCCongTacDichVuKH.BCCTDVKH_TIEP_NHAN_DANG_KY_DV_DIEN_DT,
  BCCTDVKH_TH_THU_CSKH_CUA_CAC_DON_VI:
    APIBCCongTacDichVuKH.BCCTDVKH_TH_THU_CSKH_CUA_CAC_DON_VI,
  BCCTDVKH_BC_YEU_CAU_THUC_HIEN_THEO_PHUONG_THUC_DIEN_TU:
    APIBCCongTacDichVuKH.BCCTDVKH_BC_YEU_CAU_THUC_HIEN_THEO_PHUONG_THUC_DIEN_TU,
  BCCTDVKH_BC_GIAI_QUYET_QUA_HAN_CAC_DICH_VU_DIEN:
    APIBCCongTacDichVuKH.BCCTDVKH_BC_GIAI_QUYET_QUA_HAN_CAC_DICH_VU_DIEN,
  BCCTDVKH_BC_TIEP_NHAN_DANG_KY_DICH_VU_DIEN_TU:
    APIBCCongTacDichVuKH.BCCTDVKH_BC_TIEP_NHAN_DANG_KY_DICH_VU_DIEN_TU,
  BCCTDVKH_TY_LE_TT_DVD_KHONG_DUNG_TIEN_MAT:
    APIBCCongTacDichVuKH.BCCTDVKH_TY_LE_TT_DVD_KHONG_DUNG_TIEN_MAT,
  BCCTDVKH_TY_LE_TT_DVD_KHONG_DUNG_TIEN_MAT_CT:
    APIBCCongTacDichVuKH.BCCTDVKH_TY_LE_TT_DVD_KHONG_DUNG_TIEN_MAT_CT,
  //Bao cáo kinh doanh mới
  BCCTDVKH_BC_DSKH_DIEU_CHINH_HOA_DON:
    APIBCCongTacDichVuKH.BCCTDVKH_BC_DSKH_DIEU_CHINH_HOA_DON,
  BCCTDVKH_BC_THKH_DIEU_CHINH_HOA_DON:
    APIBCCongTacDichVuKH.BCCTDVKH_BC_THKH_DIEU_CHINH_HOA_DON,
  BCCTDVKH_BC_KH_LAP_DAT_KY_HD_DIEN_MTAM:
    APIBCCongTacDichVuKH.BCCTDVKH_BC_KH_LAP_DAT_KY_HD_DIEN_MTAM,
  BCCTDVKH_BC_THANH_TOAN_TIEN_MTAM_CHO_KH:
    APIBCCongTacDichVuKH.BCCTDVKH_BC_THANH_TOAN_TIEN_MTAM_CHO_KH,

  // Baó cáo theo dõi dịch vụ khách hàng trên CMIS3
  BCCTDVKH_LOAI_YEU_CAU: APIBCCongTacDichVuKH.BCCTDVKH_LOAI_YEU_CAU,
  BCCTDVKH_THSL_12_DICH_VU_DIEN_LUC_THEO_PT_DIEN_TU:
    APIBCCongTacDichVuKH.BCCTDVKH_THSL_12_DICH_VU_DIEN_LUC_THEO_PT_DIEN_TU,
  BCCTDVKH_BC_GIAI_QUYET_QUA_HAN_CAC_DICH_VU_DIEN_HBMBĐ:
    APIBCCongTacDichVuKH.BCCTDVKH_BC_GIAI_QUYET_QUA_HAN_CAC_DICH_VU_DIEN_HBMBĐ,
  BCCTDVKH_TH_SO_KHACH_HANG_CAI_APP_ZALO:
    APIBCCongTacDichVuKH.BCCTDVKH_TH_SO_KHACH_HANG_CAI_APP_ZALO,
  BCCTDVKH_BC_THEO_DOI_DVKH_CMIS3:
    APIBCCongTacDichVuKH.BCCTDVKH_BC_THEO_DOI_DVKH_CMIS3,
};

const BCTHSMS = {
  BCTHSMS_CT_TIN_NHAN_THEO_CU_PHAP:
    APIBCTinhHinhSMS.BCTHSMS_CT_TIN_NHAN_THEO_CU_PHAP,
  BCTHSMS_DS_TIN_NHAN_KHONG_THANH_CONG:
    APIBCTinhHinhSMS.BCTHSMS_DS_TIN_NHAN_KHONG_THANH_CONG,
  BCTHSMS_DS_TIN_NHAN_MO_THAY_DOI_SO_DIEN_THOAI:
    APIBCTinhHinhSMS.BCTHSMS_DS_TIN_NHAN_MO_THAY_DOI_SO_DIEN_THOAI,
  BCTHSMS_DS_TIN_NHAN_THANH_CONG_THEO_NGAY:
    APIBCTinhHinhSMS.BCTHSMS_DS_TIN_NHAN_THANH_CONG_THEO_NGAY,
  BCTHSMS_THSL_TIN_NHAN_THEO_TUNG_DICH_VU:
    APIBCTinhHinhSMS.BCTHSMS_THSL_TIN_NHAN_THEO_TUNG_DICH_VU,
  BCTHSMS_CT_TIN_NHAN_SAI_CU_PHAP:
    APIBCTinhHinhSMS.BCTHSMS_CT_TIN_NHAN_SAI_CU_PHAP,
  BCTHSMS_BC_THSL_ANH_GHI_CHI_SO:
    APIBCTinhHinhSMS.BCTHSMS_BC_THSL_ANH_GHI_CHI_SO,
  BCTHSMS_BC_CHI_TIET_ANH_GHI_CHI_SO:
    APIBCTinhHinhSMS.BCTHSMS_BC_CHI_TIET_ANH_GHI_CHI_SO,
  BCTHSMS_TIM_KIEM: APIBCTinhHinhSMS.BCTHSMS_TIM_KIEM,
  BCTHSMS_CT_TIN_NHAN_8088: APIBCTinhHinhSMS.BCTHSMS_CT_TIN_NHAN_8088,
  BCTHSMS_DOI_SOAT_TIN_NHAN_8088:
    APIBCTinhHinhSMS.BCTHSMS_DOI_SOAT_TIN_NHAN_8088,
};

const BCTHEmail = {
  BCTHE_CT_SO_LUONG_THU_DIEN_TU_HOP_LE_DA_GUI:
    APIBCTinhHinhEmail.BCTHE_CT_SO_LUONG_THU_DIEN_TU_HOP_LE_DA_GUI,
  BCTHE_TH_SO_LUONG_THU_DIEN_TU_HOP_LE_DA_GUI:
    APIBCTinhHinhEmail.BCTHE_TH_SO_LUONG_THU_DIEN_TU_HOP_LE_DA_GUI,
};

const BCGCS = {
  BCGCS_GIAM_SAT_CHI_SO_THEO_NGAY:
    APIBCGhiChiSo.BCGCS_GIAM_SAT_CHI_SO_THEO_NGAY,
  BCGCS_GIAM_SAT_CHI_SO_THEO_THANG:
    APIBCGhiChiSo.BCGCS_GIAM_SAT_CHI_SO_THEO_THANG,
  BCGCS_DS_CHI_TIET_SO_GHI_CHI_SO:
    APIBCGhiChiSo.BCGCS_DS_CHI_TIET_SO_GHI_CHI_SO,
  BCGCS_DS_CAC_SO_GHI_CHI_SO_DUOC_TAO:
    APIBCGhiChiSo.BCGCS_DS_CAC_SO_GHI_CHI_SO_DUOC_TAO,
  BCGCS_TH_SO_GCS_DAY_TU_XML_VE_CMIS:
    APIBCGhiChiSo.BCGCS_TH_SO_GCS_DAY_TU_XML_VE_CMIS,
  BCGCS_DS_GCS_XUAT_XML: APIBCGhiChiSo.BCGCS_DS_GCS_XUAT_XML,
};

const BCTT = {
  BCTT_TT_CAC_DIEM_DO_DAU_NGUON_TBACC_THEO_PHIEN_GCS:
    APIBCTonThat.BCTT_TT_CAC_DIEM_DO_DAU_NGUON_TBACC_THEO_PHIEN_GCS,
  BCTT_TH_CAC_DIEM_DO_KHCD_THEO_PHIEN_GCS:
    APIBCTonThat.BCTT_TH_CAC_DIEM_DO_KHCD_THEO_PHIEN_GCS,
  BCTT_BC_THUONG_PHAM: APIBCTonThat.BCTT_BC_THUONG_PHAM,
};

const BCHD = {
  BCHD_TINH_HINH_SDHD_THEO_THANG: APIBCHoaDon.BCHD_TINH_HINH_SDHD_THEO_THANG,
  BCHD_TINH_HINH_SDHD_THEO_QUY: APIBCHoaDon.BCHD_TINH_HINH_SDHD_THEO_QUY,
  BCHD_TINH_HINH_SDHD_THEO_NAM: APIBCHoaDon.BCHD_TINH_HINH_SDHD_THEO_NAM,
  BCHD_TRA_CUU_HD_THOAI_HOAN: APIBCHoaDon.BCHD_TRA_CUU_HD_THOAI_HOAN,
};

const BCWEHN = {
  BCWEHN_CT_VE_VIEC_DANH_GIA_MUC_DO_HAI_LONG_KH_EVN_HN:
    APIBCWebEVNHaNoi.BCWEHN_CT_VE_VIEC_DANH_GIA_MUC_DO_HAI_LONG_KH_EVN_HN,
  BCWEHN_TH_17_DICH_VU_TIEP_NHAN:
    APIBCWebEVNHaNoi.BCWEHN_TH_17_DICH_VU_TIEP_NHAN,
  BCWEHN_TH_TAT_CA_DV_DIEN_TIEP_NHAN:
    APIBCWebEVNHaNoi.BCWEHN_TH_TAT_CA_DV_DIEN_TIEP_NHAN,
  BCWEHN_TH_VE_VIEC_DANH_GIA_MUC_DO_HAI_LONG_KH_EVN_HA_NOI:
    APIBCWebEVNHaNoi.BCWEHN_TH_VE_VIEC_DANH_GIA_MUC_DO_HAI_LONG_KH_EVN_HA_NOI,
  BCWEHN_TK_CT_SLKH_SU_DUNG_APP_EVN_HN:
    APIBCWebEVNHaNoi.BCWEHN_TK_CT_SLKH_SU_DUNG_APP_EVN_HN,
  BCWEHN_TK_NGUOI_DANG_NHAP_ONLINE_HE_THONG:
    APIBCWebEVNHaNoi.BCWEHN_TK_NGUOI_DANG_NHAP_ONLINE_HE_THONG,
  BCWEHN_TKTHSLKH_SU_DUNG_APP_EVN_HN:
    APIBCWebEVNHaNoi.BCWEHN_TKTHSLKH_SU_DUNG_APP_EVN_HN,
};

const BCK = {
  BCK_CHI_TIET_HDMBDSH_KY_DIEN_TU: APIBCKhac.BCK_CHI_TIET_HDMBDSH_KY_DIEN_TU,
  BCK_SL_HOP_DONG_MUA_BAN_DIEN_SHKDT:
    APIBCKhac.BCK_SL_HOP_DONG_MUA_BAN_DIEN_SHKDT,
  BCK_NHU_CAU_CONG_TO_DUOI_LUOI_TRONG_KHO:
    APIBCKhac.BCK_NHU_CAU_CONG_TO_DUOI_LUOI_TRONG_KHO,
  BCK_BC_DANH_SACH_YEU_CAU_SDT_NHIEU_HO_SO:
    APIBCKhac.BCK_BC_DANH_SACH_YEU_CAU_SDT_NHIEU_HO_SO,
  TK_TONG_HOP_SO_LUONG_KH_LAY_MK: APIBCKhac.TK_TONG_HOP_SO_LUONG_KH_LAY_MK,
  TK_CHI_TIET_SO_LUONG_KH_LAY_MK: APIBCKhac.TK_CHI_TIET_SO_LUONG_KH_LAY_MK,
  TONG_HOP_DANH_GIA_KHAO_SAT: APIBCKhac.TONG_HOP_DANH_GIA_KHAO_SAT,
  CHI_TIET_DANH_GIA_KHAO_SAT: APIBCKhac.CHI_TIET_DANH_GIA_KHAO_SAT,
  TK_SO_LUONG_GIAO_DICH_DIEM_THU: APIBCKhac.TK_SO_LUONG_GIAO_DICH_DIEM_THU,

  //Cap nhat bao cao nhu cau cong to
  BCK_SUA_CONG_TO_TRONG_KHO_DUOI_LUOI:
    APIBCKhac.BCK_SUA_CONG_TO_TRONG_KHO_DUOI_LUOI,
};

const BC_NCPT = {
  BC_NCPT_THEM_CONG_SUAT_YEU_CAU:
    APIBCNgienCuuPhuTai.BC_NCPT_THEM_CONG_SUAT_YEU_CAU,
  BC_NCPT_CONG_SUAT_YEU_CAU: APIBCNgienCuuPhuTai.BC_NCPT_CONG_SUAT_YEU_CAU,
  BC_NCPT_SUA_CONG_SUAT_YEU_CAU:
    APIBCNgienCuuPhuTai.BC_NCPT_SUA_CONG_SUAT_YEU_CAU,
  BC_NCPT_DANH_SACH_NGAY_DUOC_TINH_DE_DUA_RA_BIEU_DO:
    APIBCNgienCuuPhuTai.BC_NCPT_DANH_SACH_NGAY_DUOC_TINH_DE_DUA_RA_BIEU_DO,
  BC_NCPT_DANH_SACH_LOAI_NGAY: APIBCNgienCuuPhuTai.BC_NCPT_DANH_SACH_LOAI_NGAY,
  BC_NCPT_DANH_SACH_KHU_CONG_NGHIEP:
    APIBCNgienCuuPhuTai.BC_NCPT_DANH_SACH_KHU_CONG_NGHIEP,
  BC_NCPT_DANH_SACH_DIEM_DO_DRMS:
    APIBCNgienCuuPhuTai.BC_NCPT_DANH_SACH_DIEM_DO_DRMS,
  BC_NCPT_THEM_NGAY_DUOC_TINH_DE_DUA_RA_BIEU_DO_CO_SO:
    APIBCNgienCuuPhuTai.BC_NCPT_THEM_NGAY_DUOC_TINH_DE_DUA_RA_BIEU_DO_CO_SO,
  BC_NCPT_CHI_TIET_NGAY_DUOC_TINH_DE_DUA_RA_BIEU_DO_CO_SO:
    APIBCNgienCuuPhuTai.BC_NCPT_CHI_TIET_NGAY_DUOC_TINH_DE_DUA_RA_BIEU_DO_CO_SO,
  BC_NCPT_SUA_NGAY_DUOC_TINH_DE_DUA_RA_BIEU_DO_SO:
    APIBCNgienCuuPhuTai.BC_NCPT_SUA_NGAY_DUOC_TINH_DE_DUA_RA_BIEU_DO_SO,
  BC_NCPT_THEM_DIEM_DO_DRMS: APIBCNgienCuuPhuTai.BC_NCPT_THEM_DIEM_DO_DRMS,
  BC_NCPT_CHI_TIET_THEM_DIEM_DO_DRMS:
    APIBCNgienCuuPhuTai.BC_NCPT_CHI_TIET_THEM_DIEM_DO_DRMS,
};

const BCTKThongTinLienHe = {
  BCTK_TKTT_LIEN_HE_LIEN_QUAN_DEN_KH_CUA_CTDL:
    APIBCTKThongTinLienHe.BCTK_TKTT_LIEN_HE_LIEN_QUAN_DEN_KH_CUA_CTDL,
  BCTK_TKTT_LIEN_HE_LIEN_QUAN_DEN_KH_DO_TT_CSKH_CAP_NHAT:
    APIBCTKThongTinLienHe.BCTK_TKTT_LIEN_HE_LIEN_QUAN_DEN_KH_DO_TT_CSKH_CAP_NHAT,
  BCCD_TKTT_LIENHE_LIEN_QUAN_DEN_KH_CUA_CAC_CTDL_VA_TT_CSKH:
    APIBCTKThongTinLienHe.BCCD_TKTT_LIENHE_LIEN_QUAN_DEN_KH_CUA_CAC_CTDL_VA_TT_CSKH,
  BCCD_THSL_TTLH_LQ_DEN_KH_THEO_TUNG_CTDL_CUA_TOAN_TCT:
    APIBCTKThongTinLienHe.BCCD_THSL_TTLH_LQ_DEN_KH_THEO_TUNG_CTDL_CUA_TOAN_TCT,
};
const index = {
  ...CommonBCKD,
  ...BCCT,
  ...BCKD,
  ...BCTHTT,
  ...BCCN,
  ...BCAGBD,
  ...BCCTKD,
  ...BCDVBLDN,
  ...BCHTDX,
  ...BCTDPTKH,
  ...BCKHP9,
  ...BCCD,
  ...BCCTDVKH,
  ...BCTHSMS,
  ...BCTHEmail,
  ...BCGCS,
  ...BCTT,
  ...BCHD,
  ...BCWEHN,
  ...BCK,
  ...BC_NCPT,
  ...BCTKThongTinLienHe,
};

export default index;
