import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button, Row, Col, Form, Divider, Spin } from "antd";
import styled from "styled-components";
import FormComponent from "@components/Form";
import InputComponent from "@components/Input";
import { Endpoint } from "@utils/endpoint";
import { authPostData, authGetData } from "@utils/request";
import { getErrorForm } from "@utils/function";
import { STATUSCODE_200 } from "@utils/constants";
import PermissionList from "./list-permission";
import { useDispatch } from "react-redux";
import { closeModal } from "@state/system-config/reducer";
export default function ModalCreatePermission({ refresh, roleId, isView }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [permissionSelected, setPermissionSelected] = useState([]);
  const myRef = useRef(null);

  useEffect(() => {
    if (roleId) {
      authGetData({
        url: `${Endpoint.ROLE}/get-role-detail?roleId=${roleId}`,
        setLoading,
        onSuccess: (res) => {
          setPermissionSelected(res.data.permissions);
          form.setFieldsValue({
            moduleId: res.data.moduleId,
            description: res.data.description,
            name: res.data.name,
          });
        },
      });
    }
  }, [roleId, form]);
  const onFinish = useCallback(
    (values) => {
      authPostData({
        url: `${Endpoint.ROLE}`,
        method: roleId ? "PUT" : "POST",
        payload: {
          ...values,
          permissions: permissionSelected,
          id: roleId ? roleId : undefined,
        },
        setLoading,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            refresh();
            dispatch(closeModal());
          } else {
            getErrorForm(res, form);
            myRef.current.scrollIntoView();
          }
        },
      });
    },
    [dispatch, form, permissionSelected, refresh, roleId]
  );

  return (
    <Wrapper ref={myRef}>
      <Spin spinning={loading}>
        <FormComponent
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          scrollToFirstError={true}
        >
          <Row gutter={6} className="pd-20px">
            <Col span={8}>
              <Form.Item name="name" label="Tên quyền">
                <InputComponent
                  type="text"
                  size="default"
                  form={form}
                  formKey="name"
                  disabled={isView}
                />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item name="description" label="Diễn giải">
                <InputComponent
                  type="text"
                  size="default"
                  form={form}
                  formKey="description"
                  disabled={isView}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider className="mg-unset" />
          <PermissionList
            setPermissionSelected={setPermissionSelected}
            permissionSelected={permissionSelected}
            isView={isView}
          />
          {!isView && (
            <div className="modal-footer">
            <Button type="primary" htmlType="submit" >
              Lưu
            </Button>
          </div>
          )}
        </FormComponent>
      </Spin>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  .pad15 {
    padding: 15px;
  }
  .borb {
    border-bottom: 1px solid #333;
  }
  .mab15 {
    margin-bottom: 15px;
  }
  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
  }
`;
