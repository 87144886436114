import { authGetData, authGetMultiplesRequest } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { STATUSCODE_200 } from "@utils/constants";

export const getXemLaiPACDService = (
  detail,
  setDataPAXemLai,
  setVisibleXemLaiPACD,
  visibleXemLaiPACD
) => {
  const endpoints = [
    `${Endpoint.KS_GET_PHUONG_AN_CAP_DIEN}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    `${Endpoint.KS_TONG_CONG_SUAT}?HoSoId=${detail?.hoSoId ?? detail?.id}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading: () => false,
    onSuccess: (res) => {
      if (res[0]?.data !== null && res[1]?.data !== null) {
        setDataPAXemLai({ ...res[0].data.data, ...res[1].data.data });
        setVisibleXemLaiPACD(!visibleXemLaiPACD);
      }
    },
  });
};

export const checkTraHoSoService = (detail, setVisibleTraHoSo, visibleTraHoSo) => {
  const url = `${Endpoint.CHECK_TRA_HO_SO}?HoSoId=${
    detail?.hoSoId ?? detail?.id
  }`;
  authGetData({
    url,
    setLoading: () => false,
    onSuccess: (res) => {
      if (res && res.data && res.statusCode === STATUSCODE_200) {
        setVisibleTraHoSo(!visibleTraHoSo);
      }
    },
  });
};
