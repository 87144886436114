import LoadingComponent from "@components/Loading";
import TableComponent from "@components/TableComponent";

export default function Index(props) {
  const { columns, genExtra, loading, dataSource, type, header, typeDetail } =
    props;
  return (
    <LoadingComponent loading={loading}>
      <TableComponent
        header={header}
        renderExtraAction={typeDetail ? () => null : () => genExtra(type)}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scrollY={300}
      />
    </LoadingComponent>
  );
}
