/* eslint-disable no-unused-vars */
import { closeModal } from "@state/system-config/reducer";
import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  handleHuyPhuongThuc,
  handlerXoaTraCuuThongTinDiemDo,
} from "../../modal/services";
import HuyModal from "./modal-huy";

function Index({
  visible,
  handleCloseModal,
  title,
  dataTable,
  setFetchList,
  type,
  content,
  dataTablePT,
  getListChiSoPhuongThuc,
  filterConditions,
  setLoadingDanhSach,
  setDataDanhSach,
  setFilterConditions,
  dispatch,
  setIndexPage,
  indexPage,
}) {
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    if (type === 1) {
      if (dataTable && dataTable.length > 0) {
        const id = dataTable[0].id;
        handlerXoaTraCuuThongTinDiemDo(
          setLoading,
          id,
          setFetchList,
          filterConditions,
          setLoadingDanhSach,
          setDataDanhSach,
          setFilterConditions,
          handleCloseModal,
          dispatch,
          setIndexPage,
          indexPage
        );
      }
    } else if (type === 2) {
      if (dataTablePT && dataTablePT.length > 0) {
        const data = [];
        if (dataTablePT[0]?.id1) data.push(dataTablePT[0]?.id1);
        if (dataTablePT[0]?.id2) data.push(dataTablePT[0]?.id2);
        if (dataTablePT[0]?.id3) data.push(dataTablePT[0]?.id3);
        if (dataTablePT[0]?.id4) data.push(dataTablePT[0]?.id4);
        handleHuyPhuongThuc(
          setLoading,
          data,
          handleCloseModal,
          setFetchList,
          getListChiSoPhuongThuc
        );
      }
    }
  };

  return (
    <HuyModal
      loading={loading}
      visible={visible}
      closeModal={handleCloseModal}
      handleSubmit={handleSubmit}
      content={content}
      title={title}
    />
  );
}

export default memo(Index);
