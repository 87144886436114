import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { Button, Col, Divider, Form, Row, Spin } from "antd";
import React, { Fragment, useState } from "react";
import styled from "styled-components";

export default function BaoCaoDoiSoatAppCSKH() {
  const [loading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // authPostData({
    //   url: ``,
    //   method: "POST",
    //   setLoading,
    //   payload: values,
    //   onSuccess: (res) => {
    //     console.log("res", res);
    //   },
    // });
    console.log("values", values);
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      width: "70px",
    },
    {
      title: "Loại dịch vụ",
      dataIndex: "unitId",
      key: "unitId",
    },
    {
      title: "Số lượng tin nhắn",
      dataIndex: "time",
      key: "time",
    },
  ];

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Hiển thị báo cáo
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="reportType"
                    label="Tên báo cáo"
                    labelAlign="left"
                  >
                    <Selection url={[]} formKey="reportType" form={form} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    name="reportStartDate"
                    label="Ngày bắt đầu"
                    labelAlign="left"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <DatePickerComponent
                      picker="Day"
                      formKey="reportStartDate"
                      form={form}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="reportEndDate"
                    label="Ngày kết thức"
                    labelAlign="right"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <DatePickerComponent
                      picker="Day"
                      formKey="reportEndDate"
                      form={form}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end">
                <Button type="dasher" className="mr-5px">
                  Bỏ lọc
                </Button>
                <Button type="primary" className="mr-5px">
                  Xem báo cáo
                </Button>
                <Button type="primary">Xuất Excel</Button>
              </Row>
            </FormComponent>
            <Divider className="section-text-line" orientation="left"></Divider>
            <TableComponent
              header={"Kết quả hiển thị"}
              columns={columns}
              dataSource={null}
            />
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
