import { Form, Button, Modal, Row, Col, Select } from "antd";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { Container } from "./css-styled";

export default function Index(props) {
  const {
    visible,
    closeModal,
    loading,
    form,
    handleSubmit,
    t,
    tenDonVi,
    dataDonVi,
  } = props;
  return (
    <Modal
      title="Chuyển đơn vị khác"
      visible={visible}
      onCancel={closeModal}
      className="modal"
      footer={null}
      destroyOnClose={true}
    >
      <LoadingComponent loading={loading}>
        <FormComponent
          form={form}
          name="form-submit"
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{}}
          autoComplete="off"
        >
          <Container>
            <Row gutter={24}>
              <Col span={10}>
                <Form.Item>
                  {t("giao_dich_vien.don_vi_quan_ly_hien_tai")}
                </Form.Item>
              </Col>
              <Col span={14}>
                <Form.Item className="font-bold">{tenDonVi}</Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={t("giao_dich_vien.don_vi_quan_ly_moi")}
              name="donViMoiId"
              className="required-field"
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {dataDonVi && dataDonVi.length > 0
                  ? dataDonVi.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.id}>
                          {item.tenDonVi}
                        </Select.Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>
            <Row gutter={24} justify="center">
              <Col span={12} style={{ textAlign: "right" }}>
                <Button
                  key="back"
                  onClick={closeModal}
                  className="button-closed"
                >
                  {t("button.dong_lai")}
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  key="submit"
                  type="primary"
                  className="button-primary"
                  htmlType="submit"
                  form="form-submit"
                  loading={loading}
                >
                  {t("button.dong_y")}
                </Button>
              </Col>
            </Row>
          </Container>
        </FormComponent>
      </LoadingComponent>
    </Modal>
  );
}
