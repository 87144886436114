import { Menu } from "antd";
import { useDispatch } from "react-redux";
import { openModal, closeModal } from "@state/system-config/reducer";
import TaiAnhHoSoModal from "@modules/tich-hop-CRM/common-ho-so/tai-anh-ho-so/index";

import XacNhanHoanThanhModal from "./xac-nhan-hoan-thanh-modal/index";
export default function ListMenu(props) {
  const {
    detail,
    setData,
    setListLoading,
    filterConditions,
    setTotal,
    location,
  } = props;
  const dispatch = useDispatch();
  return (
    <Menu mode="vertical" style={{ width: "100%" }}>
      <Menu.Item
        eventKey="1"
        onClick={() =>
          dispatch(
            openModal({
              title: "Tải ảnh hồ sơ",
              content: (
                <TaiAnhHoSoModal closeModal={closeModal} detail={detail} />
              ),
              size: "large",
            })
          )
        }
      >
        Tải ảnh hồ sơ
      </Menu.Item>
      <Menu.Item
        eventKey="2"
        onClick={() =>
          dispatch(
            openModal({
              title: "Xác nhận hoàn thành",
              content: (
                <XacNhanHoanThanhModal
                  closeModal={closeModal}
                  detail={detail}
                  setData={setData}
                  setListLoading={setListLoading}
                  filterConditions={filterConditions}
                  setTotal={setTotal}
                  location={location}
                />
              ),
              size: "large",
            })
          )
        }
      >
        Xác nhận hoàn thành
      </Menu.Item>
    </Menu>
  );
}
