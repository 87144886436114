import { Fragment, useState } from "react";
import Table from "../../common/danh-sach-cong-to";
import {
  ENUM_DS_CONGTO,
  TYPE_PHANCONG,
  TYPE_TABLE_CONGTO,
} from "@utils/constants";

export default function CongViecPhucTra() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [fetchList, setFetchList] = useState(false);

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  return (
    <Fragment>
      <Table
        typeList={TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA}
        typeDS={ENUM_DS_CONGTO.PHUCTRA_PKD}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        rowSelection={rowSelection}
        selectedRowKeys={selectedRowKeys}
        header={"Phân công kiểm tra phúc tra"}
        isGenExtra={true}
        typePhanCong={TYPE_PHANCONG.PHONGKINHDOANH}
        setSelectedRowKeys={setSelectedRowKeys}
        isHuyPhanCong={true}
      />
    </Fragment>
  );
}
