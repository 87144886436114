import styled from "styled-components";

export const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
`;

export const ContainerPie = styled.div`
  display: flex;
  justify-content: space-around;
  .container {
    display: flex;
    flex-direction: column-reverse;
  }
  .container > .value {
    text-align: center;
    font-weight: 600;
  }
`;

export const ContainerPT = styled.div`
  display: flex;
  .container {
    display: flex;
    flex-direction: column-reverse;
    margin-right: 30px;
  }
  .container > .value {
    font-weight: 600;
  }
`;
