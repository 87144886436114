import LoadingComponent from "@components/Loading";
import { INDEX_TAB2, METHOD_POST } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { Button, Modal, Tabs } from "antd";
import FileSaver from "file-saver";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import LogModal from "../modal-log";
import { callAPIImportFile } from "../services";
import Content from "./content";

const { TabPane } = Tabs;

export default function ImportModal(props) {
  const { visible, close, getListData } = props;
  const [loading, setLoading] = useState(false);
  const [fileExNew, setFileExNew] = useState({});
  const [fileExOld, setFileExOld] = useState({});
  const [keyTab, setKeyTab] = useState("1");
  const [visibleLog, setVisibleLog] = useState(false);
  const [dataLog, setDataLog] = useState([]);
  const [messErr, setMessErr] = useState("");
  const [messErr2, setMessErr2] = useState("");
  const onChange = useCallback((key) => {
    setKeyTab(key);
  }, []);

  //dowmload template excel
  const downloadFile = () => {
    let url =
      process.env.PUBLIC_URL + "/assets/file/template_import_vattu_moi.xlsx";
    let name = "template_import_vattu_moi.xlsx";
    if (keyTab === INDEX_TAB2) {
      url =
        process.env.PUBLIC_URL + "/assets/file/template_import_vattu_cu.xlsx";
      name = "template_import_vattu_cu.xlsx";
    }
    FileSaver.saveAs(url, name);
  };

  //remove file
  const removeFile = useCallback(() => {
    if (keyTab === INDEX_TAB2) {
      setFileExOld({ fileExOld: {} });
      setMessErr2("");
    } else {
      setMessErr("");
      setFileExNew({ fileExNew: {} });
    }
  }, [keyTab]);

  //render extra button tải template
  const renderExport = (
    <Link to={"/tien-ich-chung/danh-muc/vat-tu"} onClick={downloadFile}>
      <Button className="btn-bg-yellow" type="primary">
        Tải Template
      </Button>
    </Link>
  );

  //upload file excel
  const uploadFile = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      event.target.value = null;
      if (keyTab === INDEX_TAB2) {
        setMessErr2("");
        setFileExOld(file);
      } else {
        setFileExNew(file);
        setMessErr("");
      }
    }
  };

  const handleCloseModal = () => {
    setVisibleLog(false);
  };

  //import file excel
  const handleImportFile = useCallback(() => {
    let url = Endpoint.IMPORT_FILE_VAT_TU_MOI;
    let payload = fileExNew;
    if (keyTab === INDEX_TAB2) {
      url = Endpoint.IMPORT_FILE_VAT_TU_CO_SAN;
      payload = fileExOld;
    }
    const method = METHOD_POST;
    callAPIImportFile({
      url,
      method,
      setLoading,
      payload: { fileExcel: payload },
      setFileExOld,
      setFileExNew,
      keyTab,
      setDataLog,
      setMessErr,
      setMessErr2,
      getListData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileExNew, fileExOld, keyTab]);

  const showModalLog = useCallback(() => {
    return (
      visibleLog && (
        <LogModal
          visible={visibleLog}
          close={handleCloseModal}
          keyTab={keyTab}
          dataSource={dataLog}
        />
      )
    );
  }, [dataLog, keyTab, visibleLog]);
  return (
    <Modal
      title="Tải vật tư mới / có sẵn"
      width={"50%"}
      visible={visible}
      footer={null}
      onCancel={() => close()}
    >
      <LoadingComponent loading={loading}>
        <Tabs
          defaultActiveKey="1"
          type="card"
          tabBarExtraContent={renderExport}
          onChange={onChange}
        >
          <TabPane tab="Vật tư mới" key="1">
            <Content
              fileEx={fileExNew}
              uploadFile={uploadFile}
              handleImportFile={handleImportFile}
              loading={loading}
              removeFile={removeFile}
              messErr={messErr}
            />
          </TabPane>
          <TabPane tab="Vật tư có sẵn" key="2">
            <Content
              fileEx={fileExOld}
              uploadFile={uploadFile}
              handleImportFile={handleImportFile}
              loading={loading}
              removeFile={removeFile}
              messErr={messErr2}
            />
          </TabPane>
        </Tabs>
      </LoadingComponent>

      {showModalLog()}
    </Modal>
  );
}
