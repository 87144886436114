import styled from "styled-components";

export const Container = styled.div`
  padding: 0px 12px;
  tbody tr td:last-child div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const ContainerButonKySo = styled.div`
  > button {
    background-color: #faad14;
    border-color: #faad14;
  }
  > button:hover {
    background-color: #faad14;
    border-color: #faad14;
  }
`;
