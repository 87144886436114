import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { handleKhoiPhucThiCong } from "../../services";
import KhoiPhucThiCongModal from "./modal-khoi-phuc-thi-cong";

function Index({ visible, handleCloseModal, setFetchDataTable, detail }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = () => {
    const hosoId = detail.id;
    handleKhoiPhucThiCong(
      t,
      setLoading,
      handleCloseModal,
      setFetchDataTable,
      hosoId
    );
  };

  return (
    <KhoiPhucThiCongModal
      t={t}
      loading={loading}
      visible={visible}
      handleCloseModal={handleCloseModal}
      handleSubmit={handleSubmit}
    />
  );
}

export default Index;
