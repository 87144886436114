import { Form, Input, Button, Modal, Row, Col, Select } from "antd";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { TYPE_CHI_PHI } from "@utils/constants";
import { Fragment } from "react";
import { removeAccents } from "@utils/function";

const { Option } = Select;

export default function UIChiPhi(props) {
  const {
    loading,
    typeChiPhi,
    visible,
    closeModal,
    form,
    onFinish,
    dataVatTu,
    detail,
    inputRef,
  } = props;

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={
          typeChiPhi === TYPE_CHI_PHI.CAP_NGAM
            ? "Chi phí cáp ngầm"
            : typeChiPhi === TYPE_CHI_PHI.DDK
            ? "Chi phí DDK"
            : "Chi phí khác"
        }
        visible={visible}
        onCancel={closeModal}
        className="modal"
        footer={null}
      >
        <FormComponent
          autoComplete="off"
          form={form}
          name="form-chi-phi"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            ...detail,
          }}
          scrollToFirstError
        >
          {typeChiPhi === TYPE_CHI_PHI.KHAC ? (
            <Fragment>
              <Form.Item
                label={"Tên chi phí khác"}
                name="tenChiPhiKhac"
                className="required-field"
              >
                <Input ref={inputRef} />
              </Form.Item>
              <Form.Item
                label={"Đơn vị tính"}
                name="donViTinh"
                className="required-field"
              >
                <Input />
              </Form.Item>
            </Fragment>
          ) : null}
          {typeChiPhi !== TYPE_CHI_PHI.KHAC ? (
            <Form.Item
              label={"Tên vật tư"}
              name="vatTuId"
              className="required-field"
            >
              <Select
                showSearch
                allowClear
                filterOption={(input, option) => {
                  if (option && option.children) {
                    return (
                      removeAccents(option.children)
                        .toLowerCase()
                        .indexOf(removeAccents(input).toLowerCase()) >= 0
                    );
                  }
                }}
              >
                {dataVatTu && dataVatTu.length > 0
                  ? dataVatTu.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.tenVatTu}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          ) : null}

          <Form.Item label="Số lượng" name="soLuong" className="required-field">
            <Input
              type="number"
              ref={typeChiPhi === TYPE_CHI_PHI.KHAC ? null : inputRef}
              min={0}
            />
          </Form.Item>
          <Form.Item label="Chi phí" name="chiPhi" className="required-field">
            <Input type="number" min={0} />
          </Form.Item>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                Đóng lại
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-chi-phi"
                loading={loading}
              >
                {detail.id ? "Cập nhật" : "Tạo mới"}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}
