import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Modal,
  Row,
  Col,
  Select,
  Popconfirm,
  DatePicker,
} from "antd";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { ENUM_DUTRU, FORMAT_DATE } from "@utils/constants";
import moment from "moment";
import locale from "antd/es/date-picker/locale/vi_VN";
const { Option } = Select;

export default function UIThongTinHoSo(props) {
  const {
    visible,
    closeModal,
    handleSubmit,
    t,
    thongTinHoSo,
    loadingDTLC,
    listDuToan,
    listLoaiCap,
    flagDTLC,
    setFlagDTLC,
    form
  } = props;
  
  const [hasKhaiGiaToDuToan, setHasKhaiGiaToDuToan] = useState(false);
  const [visiblePop, setVisiblePop] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (flagDTLC) {
      if (thongTinHoSo.loaiDuToan === ENUM_DUTRU.KHAI_GIA) {
        form.setFieldsValue({
          ...thongTinHoSo,
          ngayLapKhaiGia: thongTinHoSo.ngayLapKhaiGia
            ? moment(thongTinHoSo.ngayLapKhaiGia)
            : "",
        });
      } else {
        form.setFieldsValue({
          loaiDuToan: thongTinHoSo.loaiDuToan,
          loaiCap: thongTinHoSo.loaiCap,
        });
        setIsDisabled(true);
      }
      setFlagDTLC(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagDTLC]);

  const handleChangeLoaiDuToan = (value) => {
    setHasKhaiGiaToDuToan(false);
    if (parseInt(value, 10) === ENUM_DUTRU.KHAI_GIA) {
      //Khai gia
      const { ngayLapKhaiGia, boPhanLap, boPhanKiemTra } = thongTinHoSo;
      form.setFieldsValue({
        ngayLapKhaiGia: ngayLapKhaiGia ? moment(ngayLapKhaiGia) : "",
        boPhanLap: boPhanLap,
        boPhanKiemTra: boPhanKiemTra,
      });
      setIsDisabled(false);
    } else {
      //Du toan
      setIsDisabled(true);
      const { loaiDuToan } = thongTinHoSo;
      form.setFieldsValue({
        ngayLapKhaiGia: "",
        boPhanLap: null,
        boPhanKiemTra: null,
      });
      if (loaiDuToan === ENUM_DUTRU.KHAI_GIA) {
        setHasKhaiGiaToDuToan(true);
      }
    }
    form.setFieldsValue({ loaiDuToan: value });
  };
  const handleChangeLoaiCap = (value) => {
    form.setFieldsValue({ loaiCap: value });
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setVisiblePop(false);
  };
  const showPopconfirm = () => {
    setVisiblePop(true);
  };
  return (
    <Modal
      title={"Thông tin hồ sơ"}
      visible={visible}
      onCancel={closeModal}
      className="modal-thong-tin-ho-so-vat-tu"
      footer={null}
    >
      <LoadingComponent loading={loadingDTLC}>
        <FormComponent
          form={form}
          name="form-thong-tin-ho-so-vat-tu"
          onFinish={handleSubmit}
          initialValues={{}}
          layout="horizontal"
          scrollToFirstError
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={24} md={24}>
              <Form.Item label={"Loại dự toán"} name="loaiDuToan">
                <Select
                  placeholder="Chọn loại dự toán"
                  onChange={handleChangeLoaiDuToan}
                >
                  {listDuToan &&
                    listDuToan.map((element, index) => {
                      return (
                        <Option value={element.value} key={index}>
                          {element.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={24}>
              <Form.Item label={"Loại cáp"} name="loaiCap">
                <Select
                  placeholder="Chọn loại cáp"
                  onChange={handleChangeLoaiCap}
                >
                  {listLoaiCap &&
                    listLoaiCap.map((element, index) => (
                      <Option value={element.value} key={index}>
                        {element.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={24}>
              <Form.Item label={"Ngày lập khai giá"} name="ngayLapKhaiGia">
                <DatePicker
                  locale={locale}
                  format={FORMAT_DATE}
                  disabled={isDisabled}
                />
              </Form.Item>
            </Col>
            {/* <Col span={24} md={24}>
              <Form.Item label={"Bộ phận lập"} name="boPhanLap">
                <Input disabled={isDisabled} />
              </Form.Item>
            </Col>
            <Col span={24} md={24}>
              <Form.Item label={"Bộ phận kiểm tra"} name="boPhanKiemTra">
                <Input disabled={isDisabled} />
              </Form.Item>
            </Col> */}
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              {hasKhaiGiaToDuToan && thongTinHoSo.isVatTuMuaNgoai ? (
                <Popconfirm
                  title="Có vật tư ngoài hệ thống trong danh sách vật tư khách hàng. Nếu chọn dự toán thì hệ thống chỉ tính tiền vật tư trong hệ thống."
                  visible={visiblePop}
                  onConfirm={handleOk}
                  onCancel={handleCancel}
                  okText="Đồng ý"
                  cancelText="Hủy"
                >
                  <Button type="primary" onClick={showPopconfirm}>
                    Cập nhât
                  </Button>
                </Popconfirm>
              ) : (
                <Button
                  key="submit"
                  type="primary"
                  className="button-primary"
                  htmlType="submit"
                  form="form-thong-tin-ho-so-vat-tu"
                >
                  Cập nhật
                </Button>
              )}
            </Col>
          </Row>
        </FormComponent>
      </LoadingComponent>
    </Modal>
  );
}
