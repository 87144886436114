import TableComponent from "@components/TableComponent";
import { headerWithSummary } from "@utils/function";

export default function Index(props) {
  const {
    // totalCount,
    loading,
    columns,
    data,
    // countTotals,
    noteColumns,
    // onChangePagination,
    header,
  } = props;

  return (
    // <Container>
    <TableComponent
      header={header}
      loading={loading}
      scrollY={450}
      scrollX={1500}
      columns={columns}
      pagination={false}
      dataSource={data}
      rowKey={(row) => row.stt}
      // summary={(pageData) => countTotals(pageData)}
      components={{
        header: {
          wrapper: (props) => headerWithSummary(props, noteColumns),
        },
      }}
    />
    // </Container>
  );
}
