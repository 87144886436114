import { Endpoint } from "@utils/endpoint";
import { buildQueryString } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";

export const getListTrangThai = (setData) => {
  authGetData({
    url: `${Endpoint.CRM_LIST_TRANG_THAI_YEU_CAU}`,
    setLoading: () => {},
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

//CRM_DANH_SACH_YEU_CAU_TRA_CUU

export const listLinhVuc = (setLoading, setData) => {
  authGetData({
    url: `${Endpoint.CRM_LIST_LINH_VUC}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getDanhSachYeuCau = (setListLoading, setData, query, filterConditions, setTotal) => {
  authGetData({
    url: `${Endpoint.CRM_DANH_SACH_YEU_CAU_TRA_CUU}?${query}`,
    setLoading: setListLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              STT: (filterConditions.pageIndex - 1) * filterConditions.pageSize + (index + 1),
            };
          })
        );
        setTotal(res.paging.totalCount);
      }
    },
  });
};

export const getLinhVucList = (
  setLoading,
  setData,
  filterConditions,
  setTotal,
  location,
  setListLoading,
  setLinhVucList,
  form
) => {
  authGetData({
    url: `${Endpoint.CRM_LIST_LINH_VUC}`,
    setLoading,
    onSuccess: (res) => {
      setLinhVucList(res.data);
      const current_query = filterConditions;
      const updated_query = {
        ...current_query,
        pageSize: filterConditions.pageSize,
        pageIndex: filterConditions.pageIndex,
      };
      if (res.data.length === 1) {
        form.setFieldsValue({
          LinhVuc: res.data[0].value || undefined,
        });
      }
      const query = buildQueryString(updated_query);
      getDanhSachYeuCau(setListLoading, setData, query, filterConditions, setTotal);
    },
  });
};

export const exportExcel = ({filterConditions}) => {
  const query = buildQueryString(filterConditions);
  downloadFile({
    endpoint: `${Endpoint.CRM_EXPORT_DANH_SACH_YEU_CAU_TRA_CUU}?${query}`,
    setLoading: () => {},
  });
};
