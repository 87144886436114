import { Menu } from "antd";
import { openModal, closeModal } from "@state/system-config/reducer";
import TaiAnhHoSoKhachHang from "../../common-ho-so/tai-anh-ho-so-khach-hang";
import {
  LITMIT_TRANGTHAI,
  MATRIX_CAP_DIEN,
  SEQUENCE_TRANGTHAI,
} from "@utils/constants";
import CheckStatus from "@components/CheckStatus";
import { cap_dien } from "@permissions/a_capdien";
import CheckPermission from "@components/CheckPermission";
import XemLaiBBLapDatComponent from "@modules/cap-dien/common-ho-so/xem-bien-ban-lap-dat";

const { SubMenu } = Menu;

export default function ListMenu(props) {
  const {
    t,
    dispatch,
    detail,
    handleOpenDuyetHoSo,
    handleOpenTraHoSo,
    handleOpenInHoSoCMIS,
    handleOpenXemLaiPACD,
    handleOpenQuyetToanKH,
    handleOpenQuyetToanCT,
    handleOpenInBieuKG,
    inBienBanApGia,
    handleOpenInPhuTai,
  } = props;
  return (
    <Menu mode="vertical" style={{ width: "100%" }} selectedKeys={["0"]}>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.TAIANH}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.KIEMDUYET_DUYETHS[1]}
        permissionCode={cap_dien.a51_tai_anh_hskh}
      >
        <Menu.Item
          eventKey="2"
          onClick={() =>
            dispatch(
              openModal({
                content: (
                  <TaiAnhHoSoKhachHang
                    closeModal={closeModal}
                    detail={detail}
                  />
                ),
                size: "large",
              })
            )
          }
        >
          {t("common_cap_dien.tai_anh_ho_so_khach_hang")}
        </Menu.Item>
      </CheckStatus>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.XACNHANHS}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.KIEMDUYET_DUYETHS[1]}
        permissionCode={cap_dien.a51_duyet_hs}
      >
        <Menu.Item eventKey="11" onClick={handleOpenDuyetHoSo}>
          {t("common_cap_dien.kiem_duyet_ho_so")}
        </Menu.Item>
      </CheckStatus>

      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.TRALAIHS}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.KIEMDUYET_DUYETHS[1]}
        permissionCode={cap_dien.a51_tra_hs}
      >
        <Menu.Item eventKey="12" onClick={handleOpenTraHoSo}>
          {t("common_cap_dien.tra_ho_so")}
        </Menu.Item>
      </CheckStatus>
      <CheckPermission permissionCode={cap_dien.a51_in_hs_cmis}>
        <Menu.Item eventKey="3" onClick={handleOpenInHoSoCMIS}>
          {t("common_cap_dien.in_ho_so_cmis")}
        </Menu.Item>
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a51_in_hs_web}>
        {Number(detail.maTrangThai) >= LITMIT_TRANGTHAI ? (
          <SubMenu
            eventKey="inHoSoWeb"
            title={t("common_cap_dien.in_ho_so_web")}
          >
            <Menu.Item eventKey="4" onClick={handleOpenXemLaiPACD}>
              {t("common_cap_dien.xem_lai_phuong_an_cap_dien")}
            </Menu.Item>
            <XemLaiBBLapDatComponent idHoSo={detail?.id ?? detail?.hoSoId} />
            <Menu.Item eventKey="20" onClick={handleOpenInPhuTai}>
              In biểu đồ phụ tải
            </Menu.Item>
            <Menu.Item eventKey="5" onClick={handleOpenQuyetToanKH}>
              {t("common_cap_dien.quyet_toan_khach_hang")}
            </Menu.Item>
            <Menu.Item eventKey="6" onClick={handleOpenQuyetToanCT}>
              {t("common_cap_dien.quyet_toan_cong_ty")}
            </Menu.Item>
            <Menu.Item eventKey="7" onClick={handleOpenInBieuKG}>
              {t("common_cap_dien.in_bieu_khai_gia")}
            </Menu.Item>
            <Menu.Item eventKey="8" onClick={inBienBanApGia}>
              {t("common_cap_dien.in_bien_ban_ap_gia")}
            </Menu.Item>
          </SubMenu>
        ) : null}
      </CheckPermission>
    </Menu>
  );
}
