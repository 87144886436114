import { authGetData, authGetMultiplesRequest } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { STATUSCODE_200 } from "@utils/constants";

export const checkTraHoSoService = (
  detail,
  setVisibleTraHoSo,
  visibleTraHoSo
) => {
  const url = `${Endpoint.CHECK_TRA_HO_SO}?HoSoId=${
    detail?.hoSoId ?? detail?.id
  }`;
  authGetData({
    url,
    setLoading: () => false,
    onSuccess: (res) => {
      if (res && res.data && res.statusCode === STATUSCODE_200) {
        setVisibleTraHoSo(!visibleTraHoSo);
      }
    },
  });
};

// call api xem lại phương án cấp điện

export const handleXemLaiPACD = (
  detail,
  setDataPAXemLai,
  setVisibleXemLaiPACD,
  visibleXemLaiPACD
) => {
  const endpoints = [
    `${Endpoint.KS_GET_PHUONG_AN_CAP_DIEN}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    `${Endpoint.KS_TONG_CONG_SUAT}?HoSoId=${detail?.hoSoId ?? detail?.id}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading: () => false,
    onSuccess: (res) => {
      if (res[0]?.data !== null && res[1]?.data !== null) {
        setDataPAXemLai({ ...res[0].data.data, ...res[1].data.data });
        setVisibleXemLaiPACD(!visibleXemLaiPACD);
      }
    },
  });
};


// call api get message lãnh đạo ký

export const getMessageLanhDaoKy = (detail, setDataMessage) => {
  const url = `${Endpoint.GET_MESSAGE_LANH_DAO_KY}?HoSoId=${detail.id}`;
  authGetData({
    url,
    setLoading: () => false,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setDataMessage(res.data);
      }
    },
  });
};
