import { useRef, useState, useEffect } from "react";
import { Form, Button, Modal, Row, Col, Checkbox, Input, Select } from "antd";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { handleUpdateData } from "./services";

const { TextArea } = Input;
const { Option } = Select;

export default function CauHinhModal({
  detail,
  visible,
  closeModal,
  getListHoSo,
  dataViTri,
}) {
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(detail.sendMail);

  //submit form
  const onFinish = (values) => {
    //call api
    handleUpdateData(
      detail,
      checked,
      setLoading,
      values,
      form,
      getListHoSo,
      closeModal
    );
  };

  //checked send email
  const handleSend = (e) => {
    setChecked(e.target.checked);
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();

    // eslint-disable-next-line
  }, []);

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={"Cập nhật hồ sơ FLOW"}
        visible={visible}
        onCancel={closeModal}
        className="modal"
        footer={null}
      >
        <FormComponent
          autoComplete="off"
          form={form}
          name="form-submit"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            ...detail,
            sendMail: checked,
            doiTuongNhanMails: detail.doiTuongNhanMail
              ? detail.doiTuongNhanMail
              : [],
          }}
          scrollToFirstError
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="doiTuongNhanMails" label="Đối tượng nhận Mail">
                <Select mode="multiple" placeholder="Chọn đối tượng nhận Mail">
                  {dataViTri && dataViTri.length > 0
                    ? dataViTri.map((item, index) => (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={18}>
              <Form.Item name="tenFlow" label="Tên FLOW">
                <TextArea rows={3} ref={inputRef} disabled />
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Item name="sendMail">
                <Checkbox onChange={handleSend} defaultChecked={checked}>
                  Send Mail
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                Đóng lại
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-submit"
                loading={loading}
              >
                Cập nhật
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}
