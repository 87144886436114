import React from "react";
import { Button, Modal, Row, Col, Select, Form } from "antd";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { Container } from "./css-styled";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";
import {
  ENUM_DS_CONGTO,
  ENUM_HINHTHUC_KTCT,
  TYPE_PHANCONG,
} from "@utils/constants";
const { Option } = Select;
export default function PhanCongModal(props) {
  const {
    loading,
    visiblePhanCong,
    handleCloseModal,
    onFinish,
    form,
    dataDoi,
    typePhanCong,
    typeDS,
  } = props;
  return (
    <Modal
      title={"Phân công"}
      visible={visiblePhanCong}
      onCancel={handleCloseModal}
      footer={null}
    >
      <LoadingComponent loading={loading}>
        <FormComponent
          form={form}
          name="form-tra-doi"
          onFinish={onFinish}
          initialValues={{}}
          layout="vertical"
          autoComplete="off"
        >
          <Container>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label={"Tổ đội"}
                  name="doiId"
                  className="required-field"
                >
                  <Select
                    loading={loading}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataDoi &&
                      dataDoi.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.tenDoi}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => {
                    if (prevValues.doiId !== currentValues.doiId) {
                      form.setFieldsValue({
                        nguoiDuocPhanCongId: undefined,
                      });
                    }
                    return prevValues.doiId !== currentValues.doiId;
                  }}
                >
                  {({ getFieldValue }) => {
                    if (getFieldValue("doiId")) {
                      return (
                        <Form.Item
                          name="nguoiDuocPhanCongId"
                          label="Nhân viên kiểm tra"
                          className={
                            typePhanCong === TYPE_PHANCONG.DOITRUONG
                              ? "required-field"
                              : ""
                          }
                        >
                          <Selection
                            url={`${Endpoint.LIST_NVKT}?DoiId=${getFieldValue(
                              "doiId"
                            )}`}
                            formKey="nguoiDuocPhanCongId"
                            form={form}
                          />
                        </Form.Item>
                      );
                    }
                    return (
                      <Form.Item
                        name="nguoiDuocPhanCongId"
                        label="Nhân viên phúc tra"
                        className={
                          typePhanCong === TYPE_PHANCONG.DOITRUONG
                            ? "required-field"
                            : ""
                        }
                      >
                        <Select />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} md={24}>
                <Form.Item name="hinhThucGCS" label="Hình thức phúc tra">
                  {typeDS === ENUM_DS_CONGTO.DINHKY_DT ||
                  typeDS === ENUM_DS_CONGTO.DINHKY_PKD ? (
                    <Select disabled>
                      <Option value={ENUM_HINHTHUC_KTCT.MTB}>MTB</Option>
                      <Option value={ENUM_HINHTHUC_KTCT.MDMS}>MDMS</Option>
                    </Select>
                  ) : (
                    <Select>
                      <Option value={ENUM_HINHTHUC_KTCT.MTB}>MTB</Option>
                      <Option value={ENUM_HINHTHUC_KTCT.MDMS}>MDMS</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} justify="center">
              <Col span={12} style={{ textAlign: "right" }}>
                <Button
                  key="back"
                  onClick={handleCloseModal}
                  className="button-closed"
                >
                  {"Đóng lại"}
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  key="submit"
                  type="primary"
                  className="button-primary"
                  htmlType="submit"
                  form="form-tra-doi"
                >
                  {"Cập nhật"}
                </Button>
              </Col>
            </Row>
          </Container>
        </FormComponent>
      </LoadingComponent>
    </Modal>
  );
}
