import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authPostData } from "@utils/request";

export const handleReturn = (
  payload,
  setLoading,
  setFetchList,
  setSelectedRowKeys,
  closeModal
) => {
  authPostData({
    url: Endpoint.NVANTOAN_TRA_LAI,
    method: METHOD_PUT,
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchList(true);
        setSelectedRowKeys([]);
        closeModal();
      }
    },
  });
};
