import { Menu } from "antd";
import { openModal, closeModal } from "@state/system-config/reducer";
// import ModalGhiNhanKetQua from "./modals/ghi-nhan-ket-qua";
import ModalLapBienBanLapDat from "./modals/bien-ban-lap-dat";
import CheckStatus from "@components/CheckStatus";
import { MATRIX_CAP_DIEN, SEQUENCE_TRANGTHAI } from "@utils/constants";
import { handleCheckXacNhan } from "./services";
import { useState } from "react";
import CheckPermission from "@components/CheckPermission";
import { cap_dien } from "@permissions/a_capdien";

const { SubMenu } = Menu;

export default function ListMenu(props) {
  const {
    dispatch,
    handleOpenModalTroNgai,
    handleOpenModalInHoSoCMIS,
    handleOpenModalXemLaiPhuongAnCapDien,
    handleOpenModalQuyetToanKhachHang,
    handleOpenModalQuyetToanCongTy,
    handleOpenModalInBieuKhaiGia,
    inBienBanApGia,
    t,
    detail,
    setFetchDataTable,
    handleOpenInPhuTai,
  } = props;

  const [, setLoading] = useState(false);
  return (
    <Menu mode="vertical" style={{ width: "100%" }} selectedKeys={["0"]}>
      <CheckPermission permissionCode={cap_dien.a101_bb_lap_dat}>
        <Menu.Item
          eventKey="1"
          onClick={() =>
            dispatch(
              openModal({
                content: (
                  <ModalLapBienBanLapDat
                    // detailApGia={row}
                    // handleUpdate={handleUpdate}
                    detail={detail}
                    closeModal={closeModal}
                    setFetchDataTable={setFetchDataTable}
                  />
                ),
                title: "Lập biên bản lắp đặt đường dây",
                size: "large",
              })
            )
          }
        >
          {"Lập biên bản lắp đặt đường dây"}
        </Menu.Item>
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a101_kq_thi_cong}>
        <Menu.Item
          eventKey="2"
          onClick={() =>
            handleCheckXacNhan(
              detail,
              setLoading,
              dispatch,
              openModal,
              closeModal,
              setFetchDataTable
            )
          }
        >
          {"Ghi nhận kết quả thi công"}
        </Menu.Item>
      </CheckPermission>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.TRONGAI}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.XACNHANTHICONG[1]}
        permissionCode={cap_dien.a101_cap_nhat_tro_ngai}
      >
        <Menu.Item eventKey="4" onClick={handleOpenModalTroNgai}>
          {" "}
          Cập nhật trở ngại
        </Menu.Item>
      </CheckStatus>
      <CheckPermission permissionCode={cap_dien.a101_in_hs_cmis}>
        <Menu.Item eventKey="5" onClick={handleOpenModalInHoSoCMIS}>
          {" "}
          {t("common_cap_dien.in_ho_so_cmis")}
        </Menu.Item>
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a101_in_hs_web}>
        <SubMenu eventKey="sub1" title={t("common_cap_dien.in_ho_so_web")}>
          <Menu.Item
            eventKey="6"
            onClick={handleOpenModalXemLaiPhuongAnCapDien}
          >
            {" "}
            {t("common_cap_dien.xem_lai_phuong_an_cap_dien")}
          </Menu.Item>
          <Menu.Item eventKey="20" onClick={handleOpenInPhuTai}>
            In biểu đồ phụ tải
          </Menu.Item>
          <Menu.Item eventKey="7" onClick={handleOpenModalQuyetToanKhachHang}>
            {" "}
            {t("common_cap_dien.quyet_toan_khach_hang")}
          </Menu.Item>
          <Menu.Item eventKey="8" onClick={handleOpenModalQuyetToanCongTy}>
            {" "}
            {t("common_cap_dien.quyet_toan_cong_ty")}
          </Menu.Item>
          <Menu.Item eventKey="9" onClick={handleOpenModalInBieuKhaiGia}>
            {" "}
            {t("common_cap_dien.in_bieu_khai_gia")}
          </Menu.Item>
          <Menu.Item eventKey="10" onClick={inBienBanApGia}>
            {" "}
            {t("common_cap_dien.in_bien_ban_ap_gia")}
          </Menu.Item>
        </SubMenu>
      </CheckPermission>
    </Menu>
  );
}
