import { authSelector } from "@state/auth";
import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TabsContainer } from "../bao-cao-tong-hop/css-styled";
import { handleMultiBoLoc } from "./services";
import BaoCaoCapDienHaAp from "./tabs/bao-cao-cap-dien-ha-ap";
import CapNhatBaoCaoDienHaAp from "./tabs/cap-nhat-cap-dien-ha-ap";

const { TabPane } = Tabs;

export default function Index() {
  const [, setLoading] = useState(false);
  const { user } = useSelector(authSelector);
  const [donVi, setDonVi] = useState([]);
  const [changedDonViId, setChangedDonViId] = useState(user.unitId);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    handleMultiBoLoc(setLoading, setDonVi);
  }, []);

  const callback = () => {};

  const handleChangeUnit = (value) => {
    setChangedDonViId(value);
  };

  //choice row table
  const onSelectChange = (selectedRowKeys) => {
    // let chot = false;
    // let huyChot = false;
    // data.map((item) => {
    //   if (selectedRowKeys.includes(item.id)) {
    //     if (
    //       stringToSlug(item.trangThai) ===
    //         TYPE_TRANGTHAIBAOCAO_TONGHOP.DACHOT ||
    //       stringToSlug(item.trangThai) ===
    //         TYPE_TRANGTHAIBAOCAO_TONGHOP.CHOTMOTPHAN
    //     )
    //       return (chot = true);
    //     if (
    //       stringToSlug(item.trangThai) === TYPE_TRANGTHAIBAOCAO_TONGHOP.CHUACHOT
    //     )
    //       return (huyChot = true);
    //   }

    //   return true;
    // });
    // setDisabledDaChot(chot);
    // setDisabledHuyChot(huyChot);

    setSelectedRowKeys(selectedRowKeys);
  };
  return (
    <TabsContainer defaultActiveKey="1" onChange={callback}>
      <TabPane tab="Cập nhật cấp điện hạ áp" key="1">
        <CapNhatBaoCaoDienHaAp
          donVi={donVi}
          handleChangeUnit={handleChangeUnit}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      </TabPane>
      <TabPane tab="Báo cáo cấp điện hạ áp" key="2">
        <BaoCaoCapDienHaAp
          donVi={donVi}
          changedDonViId={changedDonViId}
          handleChangeUnit={handleChangeUnit}
        />
      </TabPane>
    </TabsContainer>
  );
}
