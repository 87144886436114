import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";
import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import {
  buildQueryString,
  parseParams,
  alertMessage,
} from "@utils/function";

// get list data NV ký bảng kê
export const handleListDataNVGCSKBK = (
  setLoading,
  location,
  setDataNVKBK,
  setTempDataNVKBK,
  setTotalCount,
  setTempTotalCount,
  filterConditions
) => {
  let url = "";
  url =  `${Endpoint.LIST_NV_GCS_KBK}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
    ? parseParams(location.search)
    : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataNVKBK(res.data);
        setTempDataNVKBK(res.data);
        setTotalCount(res.paging.totalCount);
        setTempTotalCount(res.paging.totalCount);
      } else setDataNVKBK([]);
    },
  });
};

export const submitNhanVienXacNhan = (
  t,
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.NHAN_VIEN_XAC_NHAN,
    method: METHOD_PUT,
    payload: {
      id: selectedRowKeys,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const handleNhanVienTraBK = (
  id,
  values,
  setLoading,
  setFetchDataTable,
  closeModal,
  t,
) => {
  authPostData({
    url: Endpoint.NHAN_VIEN_TRA_BK,
    method: METHOD_PUT,
    payload: {
      id: id,
      lyDoTraLai: values.lyDoTraLai
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        closeModal();
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const handleNhanVienTaoBK = (
  id,
  loaiBK,
  setLoading,
  setFetchDataTable,
) => {
  authPostData({
    url: Endpoint.GCS_NV_TAO_BANG_KE,
    method: METHOD_POST,
    payload: {
      id: id,
      loaiBangKe: loaiBK
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        window.open(res.data, "_blank");
        setFetchDataTable(true);
      }
    },
  });
};