import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { FORMAT_DATE, FORMAT_ENGLISH } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Form, InputNumber, Modal, Row, Select } from "antd";
import { Option } from "antd/lib/mentions";
import { t } from "i18next";
import moment from "moment";
import React, { memo, useState } from "react";
import { handleUpdateData } from "../service";

export function ModalCapNhatNgayDuocTinh(props) {
  const { visible, closeModal, getListData, detailDataTable } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  console.log(detailDataTable);

  const onFinish = (values) => {
    console.log(values);
    const data = {
      id: detailDataTable.ID,
      loaingay: values.LOAI_NGAY1,
      ngaytu: values.TU_NGAY,
      ngayden: values.DEN_NGAY,
    };
    const url = Endpoint.BC_NCPT_SUA_NGAY_DUOC_TINH_DE_DUA_RA_BIEU_DO_SO;
    const [yearStart, monthStart, dayStart] = values.ngaytu.split("-");
    const ngaytu = [dayStart, monthStart, yearStart].join("/");
    const [yearEnd, monthEnd, dayEnd] = values.ngayden.split("-");
    const ngayden = [dayEnd, monthEnd, yearEnd].join("/");
    const payload = {
      ...values,
      ngaytu: ngaytu,
      ngayden: ngayden,
      id: detailDataTable.ID,
    };
    handleUpdateData(payload, setLoading, closeModal, getListData, form, url);
  };
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title="Sửa thông tin"
        visible={visible}
        onCancel={closeModal}
        width={1200}
        footer={null}
      >
        <FormComponent
          onFinish={onFinish}
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          name="form-update-ngayduoctinh"
          initialValues={{
            ...detailDataTable,
            ngaytu: detailDataTable.TU_NGAY,
            ngayden: detailDataTable.DEN_NGAY,
            loaingay: detailDataTable.LOAI_NGAY1,
          }}
          colon={false}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                name="ngaytu"
                label="Từ ngày"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="Day"
                  formKey="ngaytu"
                  form={form}
                  format={"YYYY-MM-DD"}
                  defaultValue={detailDataTable.TU_NGAY}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="ngayden"
                label="Đến ngày"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="Day"
                  formKey="ngayden"
                  form={form}
                  format={"YYYY-MM-DD"}
                  defaultValue={detailDataTable.DEN_NGAY}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="loaingay"
                label="Loại ngày"
                labelAlign="left"
                required
              >
                <Select
                  placeholder="Chọn loại ngày"
                  formKey="loaingay"
                  defaultValue={detailDataTable.LOAI_NGAY1}
                >
                  <Option value="0">Ngày thường</Option>
                  <Option value="1">Ngày nghỉ</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>

          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-update-ngayduoctinh"
                loading={loading}
              >
                Lưu
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(ModalCapNhatNgayDuocTinh);
