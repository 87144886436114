import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useEffect,
} from "react";
import Filter from "@components/Filter/Filter";
import { TableStyled, Container } from "./css-styled";
import { Tag, Modal } from "antd";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_ENGLISH,
  // AP_GIA_DB_WEB,
  ENUM_MUCDICH_SDD,
  MONTH_STRING
} from "@utils/constants";
import {
  customColumn,
  handlePagination,
  // parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import {
  handleListData,
  submitDongBoVeWeb,
  exportExcel,
} from "./services";
import { useSearchParams } from "react-router-dom";
import { ListFilter } from "./list-bo-loc";
import { ap_gia } from "@permissions/e_apgia";
import CheckPermission from "@components/CheckPermission";
import TableComponent from "@components/TableComponent";
import ButtonComponent from "@components/Button";
import moment from "moment";

export default function Index() {
  const [dataLayDuLieuCMIS, setDataLayDuLieuCMIS] = useState([]);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [listLayHS, setListLayHS] = useState([]);
  const [dataResponse, setDataResponse] = useState();
  // const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    LoaiKhachHang: ENUM_MUCDICH_SDD.SINHHOAT,
    TuNgay: moment(moment().startOf(MONTH_STRING)).format(FORMAT_ENGLISH),
    DenNgay: moment().format(FORMAT_ENGLISH),
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const onChangePagination = (paging, _filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    let sortKey = "orderBy";
    let currentKey = "orderByDesc";
    if (sorter.order === "descend") {
      sortKey = "orderByDesc";
      currentKey = "orderBy";
    } else {
      sortKey = "orderBy";
      currentKey = "orderByDesc";
    }
    const filter = {
      ...filterConditions,
      pageIndex: paging.current,
      pageSize: paging.pageSize,
      [sortKey]: sorter.order ? sorter.field : undefined,
      [currentKey]: undefined,
    };
    handleListData(
      // location,
      filter,
      setLoading,
      setDataLayDuLieuCMIS,
      setTotalCount
    );
  };
  const handleSearch = useCallback((values) => {
    setSelectedRowKeys([]);
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values
    }));
    const filter = { ...filterConditions, pageIndex: DEFAULT_PAGEINDEX, ...values };
    handleListData(
      // location,
      filter,
      setLoading,
      setDataLayDuLieuCMIS,
      setTotalCount
    );
    setDataResponse();
  }, [filterConditions]);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      // DonViId: user.unitId,
      LoaiKhachHang: ENUM_MUCDICH_SDD.SINHHOAT,
      TuNgay: moment(moment().startOf(MONTH_STRING)).format(FORMAT_ENGLISH),
      DenNgay: moment().format(FORMAT_ENGLISH),
    });
    setDataResponse();
  }, []);

  //search list
  const getListData = useCallback(() => {
    handleListData(
      // location,
      filterConditions,
      setLoading,
      setDataLayDuLieuCMIS,
      setTotalCount
    );
  }, [filterConditions]);

  // useEffect(() => {
  //   getListData();
  // }, [getListData]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListData();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListData, setFetchDataTable]);

  const columns = [
    {
      title: "Mã điểm đo",
      dataIndex: "mA_DDO",
      key: "mA_DDO",
      render: (text) => <span>{text}</span>,
      width: "10%",
      sorter: true,
      fixed: "left"
    },
    {
      title: "Mã khách hàng",
      dataIndex: "mA_KHANG",
      key: "mA_KHANG",
      render: (text) => <span>{text}</span>,
      width: "10%",
      sorter: true,
      fixed: "left"
    },
    {
      title: "Tên KH",
      dataIndex: "teN_KHANG",
      key: "teN_KHANG",
      render: (text) => <span>{text}</span>,
      width: "12%",
      sorter: true,
    },
    {
      title: "Ngày áp dụng",
      dataIndex: "ngaY_HLUC_STR",
      key: "ngaY_HLUC_STR",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
      sorter: true,
    },
    {
      title: "Loại khách hàng",
      dataIndex: "loaiKhachHang",
      key: "loaiKhachHang",
      render: (text, record) => (
        record.loaI_KHANG?.toString() === ENUM_MUCDICH_SDD.NGOAISINHHOAT ?
          <span>{"Ngoài sinh hoạt"}</span> :
          <span>{"Sinh hoạt"}</span>
      ),
      width: "12%",
      sorter: true,
    },
    {
      title: "Cấp điện áp",
      dataIndex: "teN_CAPDA",
      key: "teN_CAPDA ",
      render: (text) => <span>{text}</span>,
      width: "10%",
    },
    {
      title: "Số hộ",
      dataIndex: "sO_HO_STR",
      key: "sO_HO_STR",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },

    {
      title: "Nhóm ngành nghề",
      dataIndex: "teN_NHOMNN",
      key: "teN_NHOMNN",
      render: (text) => <span>{text}</span>,
      width: "8%",
    },
    {
      title: "Chuỗi giá",
      dataIndex: "chuoI_GIA",
      key: "chuoI_GIA",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
    },
    {
      title: "Loại điểm đo",
      dataIndex: "teN_DDO",
      key: "teN_DDO",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Trạm",
      dataIndex: "teN_TRAM",
      key: "teN_TRAM",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Sổ GCS",
      dataIndex: "mA_SOGCS",
      key: "mA_SOGCS",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Địa chỉ",
      //dchI_KH_LHE
      dataIndex: "duonG_PHO",
      key: "duonG_PHO",
      render: (text) => <span>{text}</span>,
      width: "16%",
    },
    {
      title: "Mã bảng kê",
      dataIndex: "maBangKe",
      key: "maBangKe",
      render: (text) => <span>{text}</span>,
      width: "9%",
    },
    {
      title: "Trạng thái",
      dataIndex: "isWebSync",
      key: "isWebSync",
      render: (text, record) => (
        record.isWebSync ?
          <Tag style={{ display: "block", textAlign: "center" }} color={"#26c0e9"}>{"Đã đồng bộ về web"}</Tag> :
          <Tag style={{ display: "block", textAlign: "center" }} color={"#faad14"}>{"Chưa đồng bộ về web"}</Tag>
      ),
      width: "8%",
    },
  ];
  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    const activeServiceList = dataLayDuLieuCMIS.filter((item) => {
      return selectedRowKeys.includes(item.key);
    });
    setListLayHS((oldState) => ([
      ...oldState,
      ...activeServiceList
    ]));
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
    columnWidth: "3%",
    fixed: "left",
    // getCheckboxProps: (record) => ({
    //   disabled: record.isPhanCong,
    // }),
  };

  const handleXuatExcel = () => {
    setDataResponse();
    exportExcel(filterConditions, setLoading);
  };

  const genExtra = useCallback(
    () => (
      <div>
        <CheckPermission permissionCode={ap_gia.e31d}>
          <ButtonComponent
            type="primary"
            style={{ marginRight: "10px" }}
            onClick={() => handleLayDLCMIS()}
            disabled={selectedRowKeys.length === 0}
          >
            Đồng bộ về Web
          </ButtonComponent>
        </CheckPermission>
        <CheckPermission permissionCode={ap_gia.e31d}>
          <ButtonComponent
            type="primary"
            style={{ marginRight: "10px" }}
            onClick={() => handleXuatExcel()}
          >
            Xuất Excel
          </ButtonComponent>
        </CheckPermission>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRowKeys, listLayHS, handleXuatExcel]
  );

  const handleLayDLCMIS = () => {
    const listLayHSDB = listLayHS.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.key === value.key
      ))
    )
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: "Bạn muốn đồng bộ các yêu cầu đã chọn về Web?",
      onOk() {
        //call api
        submitDongBoVeWeb(setLoading, setFetchDataTable, listLayHSDB, setSelectedRowKeys, setListLayHS, setDataResponse);
      },
      onCancel() { },
      okText: "Đồng ý",
      cancelText: "Hủy",
    });
  };

  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
        />
      </Filter>
      <Container>
        {
          dataResponse &&
          <>
            <div style={{ color: "#0dcb0d", fontWeight: "bold ", marginLeft: 10 }}>Kết quả đồng bộ:</div>
            <div style={{ marginLeft: 20 }}>
              <div >
                <span style={{ width: 200, display: 'inline-block' }}>
                  Tổng số yêu cầu:
                </span>
                <span>{dataResponse?.tongSoYeuCau}</span>
              </div>
              <div>
                <span style={{ width: 200, display: 'inline-block' }}>
                  Tổng số yêu cập nhật:
                </span>
                <span>{dataResponse?.yeuCauUpdate}</span>
              </div>
              <div>
                <span style={{ width: 200, display: 'inline-block' }}>
                  Tổng số yêu cầu thành công:
                </span>
                <span>{dataResponse?.yeuCauSuccess}</span>
              </div>
              <div>
                <span style={{ width: 200, display: 'inline-block' }}>
                  Tổng số yêu cầu thất bại:
                </span>
                <span>{dataResponse?.yeuCauError}</span>
              </div>
            </div>
          </>
        }
        {/* <span style={{ marginLeft: 10, fontSize: 20, fontWeight: "bold" }}>Tổng:
          <span style={{ color: "blue" }}> {selectedRowKeys.length}/{totalCount}</span>
        </span> */}
        <TableStyled>
          <TableComponent
            header={`Lấy dữ liệu từ CMIS (Tổng ${selectedRowKeys.length}/${totalCount})`}
            handleSearch={handleSearch}
            dataSource={dataLayDuLieuCMIS}
            columns={customColumn(columns, filterConditions)}
            totalData={totalCount}
            renderExtraAction={() => genExtra()}
            loading={loading}
            scrollX={2800}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            rowSelection={rowSelection}
            rowKey="key"
          />
        </TableStyled>
      </Container>
    </Fragment>
  );
}
