import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import {
  buildQueryString,
  getErrorMessage,
  parseParams,
} from "@utils/function";
import { authGetData, authPostData, downloadFile } from "@utils/request";
import { alertMessage422 } from "../service";

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
    })
  );

  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};

// Cập nhật bán lẻ
export const handleCapNhatBanLe = (data, setLoading, url, form, close) => {
  authPostData({
    url: url,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        form.resetFields();
        close();
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
