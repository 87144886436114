import { useState, useEffect } from "react";
import { Button, Modal, Row, Col } from "antd";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";


export default function KyDuyetModal(props) {
  const {
    visible,
    closeModal,
    onFinish,
    form,
  } = props;
  const [loading,] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  return (
    <Modal
      title="Nhân viên áp giá ký duyệt"
      visible={visible}
      onCancel={closeModal}
      className="modal-bpag-ky"
      footer={null}
    >
      <LoadingComponent loading={loading}>
        <FormComponent
          form={form}
          name="form-bpag-ky"
          layout="horizontal"
        >
          <Row gutter={24}>
            <Col>
              <div>
                Các yêu cầu đã chọn sẽ chuyển tiếp cho Bộ phận áp giá!
              </div>
              <div>
                Bạn chắc chắn muốn thực hiện?
              </div>
            </Col>
          </Row>
          <br />
          <Row gutter={24} justify="center">
            <Button key="back" onClick={closeModal} className="button-closed mr-24px">
              Đóng lại
            </Button>
            <Button
              key="submit"
              type="primary"
              className="button-primary"
              form="form-bpag-ky"
              onClick={() => onFinish()}
            >
              Xác nhận
            </Button>
          </Row>
        </FormComponent>
      </LoadingComponent>
    </Modal>
  );
}
