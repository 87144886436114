import { Col, DatePicker, Form, Input, Modal } from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import { callAPISearchCodeCT, handleGetCongToCMIS } from "../../services";
import { alertMessage } from "@utils/function";
import { RowTable } from "./css-styled";
import moment from "moment";
import { FORMAT_DATE } from "@utils/constants";
import DSCongToModal from "../../modal-ds-cong-to";
import ThongTinTreoTabs from "./layout";

const arrayLoaiDiemDo = [
  {
    value: 1,
    name: "Công tơ cơ HC/điện tử đo đếm KT",
  },
  {
    value: 2,
    name: "Công tơ cơ HC/điện tử đo đếm KT+VC",
  },
  {
    value: 3,
    name: "Công tơ điện tử bán 3 giá",
  },
  {
    value: 5,
    name: "Công tơ điện tử 3 giá bán 1 giá",
  },
  {
    value: 8,
    name: "Công tơ điện tử 3 giá, có giá KT",
  },
];

export default function Index(props) {
  const {
    detail,
    form,
    thongTinHienTai,
    setMaCongToTreo,
    defaultCTTreo,
    detailCTThao,
    setDataDetaiCTTreolUpdate,
    defaultLoaiDiemDo,
    setChangeTab2,
    detailHoSo,
    isDelCT,
    setIsDelCT,
    maCongToTreo,
    loaiBienBan,
    setChiSoMoiMax,
  } = props;
  const [detailCTTreo, setDetailCTTreo] = useState(detail);

  const [isChanged, setIsChanged] = useState(detailCTTreo?.isThayDoiLoaiDiemDo);
  const [isLT, setIsLT] = useState(detailCTTreo?.lapTrinhLaiCongTo);
  const [isSoNhan, setIsSoNhan] = useState(detailCTTreo?.layBoChiSoNhan);
  const [viTri, setViTri] = useState(0);
  const [dataCMIS, setDataCMIS] = useState({});
  const [changeDD, setChangeDD] = useState(defaultLoaiDiemDo);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dataCongTo, setDataCongTo] = useState([]);
  const [typeThietBi, setTypeThietBi] = useState("");
  //click row
  const handleClickViewCamera = useCallback(
    (url, index) => {
      setViTri(index !== undefined ? index : viTri);
      form.setFieldsValue({
        ngayPMaxThao: detailCTTreo.boChiSos[index].ngayPMax,
        pMaxThao: detailCTTreo.boChiSos[index].pMax,
        chiSoMoiThao: detailCTTreo.boChiSos[index].chiSoMoi,
      });
    },
    [detailCTTreo.boChiSos, form, viTri]
  );

  //xóa công tơ
  const handleDeleteCongTo = () => {
    setDetailCTTreo(defaultCTTreo);
    setIsLT(false);
    setIsSoNhan(false);
    form.setFieldsValue({
      isThayDoiLoaiDiemDo: false,
      lapTrinhLaiCongTo: false,
      layBoChiSoNhan: false,
    });
    setMaCongToTreo("");
    setIsDelCT(true);
  };

  //change checkbox
  const handleChanged = (e, viTri) => {
    if (viTri === 1) {
      form.setFieldsValue({
        isThayDoiLoaiDiemDo: e.target.checked,
      });
      setIsChanged(e.target.checked);
    } else if (viTri === 2) {
      if (e.target.checked) {
        if (detailCTTreo.maCongTo === null || detailCTTreo.maCongTo === "") {
          setDetailCTTreo({
            ...detailCTThao,
            vhCong: detailCTTreo.vhCong,
          });
          setIsLT(e.target.checked);
          form.setFieldsValue({
            lapTrinhLaiCongTo: true,
          });
          setMaCongToTreo(detailCTThao.maCongTo);
          detailCTThao.boChiSos.forEach((item, index) => {
            form.setFieldsValue({
              [`chiSoMoi-${index}`]: item.chiSoMoi,
              [`ngayPmax-${index}`]:
                item.ngayPMax === null || item.ngayPMax === undefined
                  ? moment(moment(), FORMAT_DATE)
                  : moment(item.ngayPMax, FORMAT_DATE),
              [`pMax-${index}`]: item.pMax,
            });
          });
          setDataCMIS(defaultCTTreo);
        } else {
          Modal.confirm({
            centered: true,
            title: "Xác nhận",
            content:
              "Bạn đã nhập thông tin công tơ treo khác với công tơ tháo. Bạn có đồng ý xóa công tơ treo hiện tại và công tơ tháo sẽ là công tơ treo",
            onOk() {
              setDetailCTTreo({
                ...detailCTThao,
                vhCong: detailCTTreo.vhCong,
              });
              setIsLT(e.target.checked);
              form.setFieldsValue({
                lapTrinhLaiCongTo: true,
              });
              setMaCongToTreo(detailCTThao.maCongTo);
              detailCTThao.boChiSos.forEach((item, index) => {
                form.setFieldsValue({
                  [`chiSoMoi-${index}`]: item.chiSoMoi,
                  [`ngayPmax-${index}`]:
                    item.ngayPMax === null || item.ngayPMax === undefined
                      ? moment(moment(), FORMAT_DATE)
                      : moment(item.ngayPMax, FORMAT_DATE),
                  [`pMax-${index}`]: item.pMax,
                });
              });
              setDataCMIS(defaultCTTreo);
            },
            onCancel() {
              setIsLT(false);
              form.setFieldsValue({
                lapTrinhLaiCongTo: false,
              });
            },
            okText: "Đồng ý",
            okButtonProps: { type: "primary" },
            cancelText: "Hủy",
          });
        }
      } else {
        Modal.confirm({
          centered: true,
          title: "Xác nhận",
          content:
            "Bạn muốn thực hiện hủy chọn [Lập trình lại công tơ], thông tin công tơ treo sẽ bị xóa.",
          onOk() {
            setDetailCTTreo(defaultCTTreo);
            setIsLT(false);
            form.setFieldsValue({
              lapTrinhLaiCongTo: false,
            });
            detailCTThao.boChiSos.forEach((item, index) => {
              form.setFieldsValue({
                [`chiSoMoi-${index}`]: "0",
                [`ngayPmax-${index}`]: moment(moment(), FORMAT_DATE),
                [`pMax-${index}`]: null,
              });
            });
            setMaCongToTreo("");
            setDataCMIS(defaultCTTreo);
          },
          onCancel() {
            form.setFieldsValue({
              lapTrinhLaiCongTo: true,
            });
            setIsLT(true);
          },
          okText: "Đồng ý",
          okButtonProps: { type: "primary" },
          cancelText: "Hủy",
        });
      }
    } else {
      debugger;
      setIsSoNhan(e.target.checked);
      form.setFieldsValue({
        layBoChiSoNhan: e.target.checked,
      });
      const newData = [];
      let arr = [];
      if (e.target.checked) {
        for (let i = 0; i < detailCTTreo.boChiSos.length; i++) {
          if (detailCTTreo.boChiSos[i].bcs[1] !== "N") {
            const filter = detailCTTreo.boChiSos.filter(
              (el) => el.bcs === detailCTTreo.boChiSos[i].bcs[0] + "N"
            );
            if (filter.length === 0) {
              newData.push({
                bcs: detailCTTreo.boChiSos[i].bcs[0] + "N",
                chiSoCu: detailCTTreo.boChiSos[i].chiSoCu,
                chiSoMoi: detailCTTreo.boChiSos[i].chiSoMoi,
                image: detailCTTreo.boChiSos[i].image,
                ngayPMax: detailCTTreo.boChiSos[i].ngayPMax,
                pMax: detailCTTreo.boChiSos[i].pMax,
                sanLuongMoi: detailCTTreo.boChiSos[i].sanLuongMoi,
                thuTu: 0,
              });
            }
          }
        }
        arr = detailCTTreo.boChiSos.concat(newData);
        setDetailCTTreo({
          ...detailCTTreo,
          boChiSos: arr,
        });
      } else {
        arr = detailCTTreo.boChiSos.filter(function (value, index, arr) {
          return value.bcs.indexOf("N") === -1;
        });
      }
      arr.forEach((item, index) => {
        form.setFieldsValue({
          [`chiSoMoi-${index}`]: item.chiSoMoi,
          [`ngayPmax-${index}`]:
            item.ngayPMax === null || item.ngayPMax === undefined
              ? moment(moment(), FORMAT_DATE)
              : moment(item.ngayPMax, FORMAT_DATE),
          [`pMax-${index}`]: item.pMax,
        });
      });
      setDetailCTTreo({
        ...detailCTTreo,
        boChiSos: arr,
      });
    }
  };

  useEffect(() => {
    if (
      detail.lapTrinhLaiCongTo === isLT &&
      detail.isThayDoiLoaiDiemDo === isChanged &&
      detail.layBoChiSoNhan === isSoNhan
    )
      setChangeTab2(false);
    else setChangeTab2(true);
  }, [
    detail.isThayDoiLoaiDiemDo,
    detail.lapTrinhLaiCongTo,
    detail.layBoChiSoNhan,
    isChanged,
    isLT,
    isSoNhan,
    setChangeTab2,
  ]);

  //change điểm đo
  const handleChangeLDD = (value) => {
    if (detailCTTreo.boChiSos.length > 0) {
      const arrBCS = [];
      detailCTTreo.boChiSos.forEach((item) => {
        arrBCS.push(item.bcs);
      });
      if (value === 1 || value === "1") {
        if (arrBCS.indexOf("KT") > -1) {
          setChangeDD(value);
        } else
          alertMessage(
            "error",
            "Thông báo",
            "Loại điểm đo 1 thì công tơ treo mới cần có biểu KT !"
          );
      } else if (value === 2 || value === "2") {
        if (arrBCS.indexOf("KT") > -1 && arrBCS.indexOf("VC") > -1) {
          setChangeDD(value);
        } else
          alertMessage(
            "error",
            "Thông báo",
            "Loại điểm đo 2 thì công tơ treo mới cần có biểu KT;VC hoặc BT;VC !"
          );
      } else if (
        value === 3 ||
        value === "3" ||
        value === 5 ||
        value === "5" ||
        value === 8 ||
        value === "8"
      ) {
        if (detailCTTreo.vhCong === "DT" || detailCTTreo.vhCong === "D1") {
          setChangeDD(value);
          if (
            arrBCS.indexOf("BT") === -1 &&
            arrBCS.indexOf("CD") === -1 &&
            arrBCS.indexOf("TD") === -1
          ) {
            alertMessage(
              "error",
              "Thông báo!",
              "Loại điểm đo 3/5/8 thì công tơ treo mới cần có biểu BT;CD;TD !"
            );
          }
        } else
          alertMessage(
            "error",
            "Thông báo",
            "Điểm đo loại 3, 5, 8 chỉ treo công tơ điện từ !"
          );
      } else setChangeDD(value);
    } else setChangeDD(value);
  };

  const handleCloseModal = () => {
    setVisible(false);
  };

  //Hàm search call api công tơ
  const getListCongToCMIS = useCallback(
    (value, type) => {
      if (value !== "") {
        handleGetCongToCMIS(
          detailHoSo?.id,
          value,
          setLoading,
          setDataCongTo,
          setVisible,
          type,
          setTypeThietBi
        );
      }
    },
    [detailHoSo?.id]
  );

  //search mã công tơ
  const handleSearchBarCode = useCallback(
    (value) => {
      if (value === detailCTTreo?.maCongTo) {
        alertMessage(
          "error",
          "Thông báo",
          "Vui lòng xóa thông tin công tơ treo mới trước khi tìm kiếm công tơ khác"
        );
      } else
        callAPISearchCodeCT(
          setLoading,
          setDetailCTTreo,
          setDataCMIS,
          value,
          detailCTTreo,
          changeDD,
          setMaCongToTreo,
          form,
          thongTinHienTai?.soPha,
          setIsDelCT,
          setVisible,
          maCongToTreo,
          loaiBienBan,
          setIsSoNhan
        );
    },
    [
      changeDD,
      detailCTTreo,
      form,
      loaiBienBan,
      maCongToTreo,
      setIsDelCT,
      setMaCongToTreo,
      thongTinHienTai?.soPha,
    ]
  );

  // Kiểm tra điều kiện để setData tương ứng Treo or Tháo
  const renderDSCT = useCallback(() => {
    return (
      visible && (
        <DSCongToModal
          loading={loading}
          visible={visible}
          closeModal={handleCloseModal}
          data={dataCongTo}
          handleSearch={getListCongToCMIS}
          typeThietBi={typeThietBi}
          handleSearchBarCode={handleSearchBarCode}
        />
      )
    );
  }, [
    visible,
    loading,
    dataCongTo,
    getListCongToCMIS,
    typeThietBi,
    handleSearchBarCode,
  ]);

  //change input value
  const handleChangeInput = useCallback(
    (e, field) => {
      const max = detailCTTreo.soCuaSo;
      let valueText = "9";
      for (let i = 1; i < max; i++) {
        valueText += "9";
      }
      if (field === "chiSoMoi") {
        form.setFieldsValue({
          [`${field}-${viTri}`]: e.target.value,
        });
        //12-9 Tắt valid cong to treo

        // if (parseInt(e.target.value) < 0 || e.target.value === "") {
        //   form.setFields([
        //     {
        //       name: [`chiSoMoi-${viTri}`],
        //       errors: [`Không được nhập bé hơn 0`],
        //     },
        //   ]);
        //   setChiSoMoiMax(true);
        // } else if (
        //   e.target.value.length >= 2 &&
        //   e.target.value[0] === "0" &&
        //   e.target.value[1] >= "0"
        // ) {
        //   form.setFields([
        //     {
        //       name: [`chiSoMoi-${viTri}`],
        //       errors: [`Nhập sai dữ liệu`],
        //     },
        //   ]);
        //   setChiSoMoiMax(true);
        // } else if (parseInt(e.target.value) > parseInt(valueText)) {
        //   form.setFields([
        //     {
        //       name: [`chiSoMoi-${viTri}`],
        //       errors: [`Chỉ số treo phải bé hơn hoặc bằng ${valueText}`],
        //     },
        //   ]);
        //   setChiSoMoiMax(true);
        // } else {
        //   form.setFields([
        //     {
        //       name: [`chiSoMoi-${viTri}`],
        //       errors: false,
        //     },
        //   ]);
        //   form.setFieldsValue({
        //     [`${"chiSoMoi"}-${viTri}`]: e.target.value,
        //   });
        //   setChiSoMoiMax(false);
        // }
      } else
        form.setFieldsValue({
          [`${field}-${viTri}`]: e.target.value,
        });
    },
    [detailCTTreo.soCuaSo, form, setChiSoMoiMax, viTri]
  );

  //change date
  const handleOnChangeDate = useCallback(
    (date, dateString) => {
      form.setFieldsValue({
        [`ngayPMax-${viTri}`]: dateString,
      });
    },
    [form, viTri]
  );

  useEffect(() => {
    form.setFieldsValue({
      isThayDoiLoaiDiemDo: detailCTTreo.isThayDoiLoaiDiemDo,
      layBoChiSoNhan: detailCTTreo.layBoChiSoNhan,
      lapTrinhLaiCongTo: detailCTTreo.lapTrinhLaiCongTo,
      loaiDiemDo: changeDD,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (detailCTTreo.boChiSos.length > 0) {
      detailCTTreo.boChiSos.forEach((item, index) => {
        if (item.ngayPMax) {
          const convert =
            item.ngayPMax.split("/")[1] +
            "/" +
            item.ngayPMax.split("/")[0] +
            "/" +
            item.ngayPMax.split("/")[2];
          form.setFieldsValue({
            [`ngayPmax-${index}`]: moment(convert),
          });
        } else {
          form.setFieldsValue({
            [`ngayPmax-${index}`]: moment(),
          });
        }
      });
    }
  }, [detailCTTreo.boChiSos, form]);

  useEffect(() => {
    if (setDataDetaiCTTreolUpdate) setDataDetaiCTTreolUpdate(detailCTTreo);
  }, [detailCTTreo, setDataDetaiCTTreolUpdate]);

  useEffect(() => {
    if (detailCTTreo && detailCTTreo.boChiSos.length > 0) {
      detailCTTreo.boChiSos.forEach((item, index) => {
        let convert = moment();
        if (item.ngayPMax) {
          convert = moment(
            item.ngayPMax.split("/")[1] +
              "/" +
              item.ngayPMax.split("/")[0] +
              "/" +
              item.ngayPMax.split("/")[2]
          );
        }
        form.setFieldsValue({
          [`pMax-${index}`]: item.pMax ?? 0,
          [`chiSoMoi-${index}`]: item.chiSoMoi ?? 0,
          [`ngayPmax-${index}`]: convert,
        });
      }, []);
    }
  }, [detailCTTreo, form]);

  //render table
  const renderTable = useCallback(() => {
    if (detailCTTreo?.boChiSos?.length > 0)
      return (
        <RowTable gutter={24}>
          {detailCTTreo.boChiSos.map((item, index) => (
            <Fragment key={index}>
              <Col
                span={4}
                style={{
                  padding: "8px",
                  borderBottom: "1px solid #f5f5f5",
                  cursor: "pointer",
                  fontWeight: "bold",
                  backgroundColor: viTri === index ? "#FFF2CC" : "#fff",
                }}
                onClick={() =>
                  handleClickViewCamera(
                    item.image && item.image.filePath,
                    index
                  )
                }
              >
                <div style={{ marginBottom: "16px" }}>BCS</div>
                <div style={{ paddingTop: "4px" }}>Ngày Pmax</div>
              </Col>
              <Col
                span={6}
                style={{
                  padding: "8px",
                  borderBottom: "1px solid #f5f5f5",
                  cursor: "pointer",
                  fontWeight: "bold",
                  backgroundColor: viTri === index ? "#FFF2CC" : "#fff",
                }}
                onClick={() =>
                  handleClickViewCamera(
                    item.image && item.image.filePath,
                    index
                  )
                }
              >
                <div style={{ marginBottom: "16px", color: "#0018FF" }}>
                  {item.bcs}
                </div>
                <Form.Item name={`ngayPmax-${index}`} style={{ margin: 0 }}>
                  <DatePicker
                    format={FORMAT_DATE}
                    onChange={handleOnChangeDate}
                    readOnly={viTri !== index}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col
                span={4}
                style={{
                  padding: "8px",
                  borderBottom: "1px solid #f5f5f5",
                  cursor: "pointer",
                  fontWeight: "bold",
                  backgroundColor: viTri === index ? "#FFF2CC" : "#fff",
                }}
              ></Col>
              <Col
                span={4}
                style={{
                  padding: "8px",
                  borderBottom: "1px solid #f5f5f5",
                  cursor: "pointer",
                  fontWeight: "bold",
                  backgroundColor: viTri === index ? "#FFF2CC" : "#fff",
                }}
                onClick={() =>
                  handleClickViewCamera(
                    item.image && item.image.filePath,
                    index
                  )
                }
              >
                <div style={{ marginBottom: "16px" }}>Chỉ số treo</div>
                <div style={{ paddingTop: "4px" }}>Pmax</div>
              </Col>
              <Col
                span={6}
                style={{
                  padding: "8px",
                  borderBottom: "1px solid #f5f5f5",
                  cursor: "pointer",
                  fontWeight: "bold",
                  backgroundColor: viTri === index ? "#FFF2CC" : "#fff",
                }}
                onClick={() =>
                  handleClickViewCamera(
                    item.image && item.image.filePath,
                    index
                  )
                }
              >
                <div style={{ marginBottom: "8px" }}>
                  <Form.Item name={`chiSoMoi-${index}`} style={{ margin: 0 }}>
                    <Input
                      placeholder="Nhập chỉ số mới"
                      onChange={(e) => handleChangeInput(e, "chiSoMoi")}
                      readOnly={viTri !== index}
                      style={{ color: "#0018FF", textAlign: "right" }}
                      type="number"
                      min={0}
                    />
                  </Form.Item>
                </div>
                <Form.Item name={`pMax-${index}`} style={{ margin: 0 }}>
                  <Input
                    placeholder="Nhập Pmax"
                    type="number"
                    style={{ textAlign: "right" }}
                    onChange={(e) => handleChangeInput(e, "pMax")}
                    readOnly={viTri !== index}
                  />
                </Form.Item>
              </Col>
            </Fragment>
          ))}
        </RowTable>
      );
  }, [
    detailCTTreo.boChiSos,
    handleChangeInput,
    handleClickViewCamera,
    handleOnChangeDate,
    viTri,
  ]);

  return (
    <ThongTinTreoTabs
      detailCTTreo={detailCTTreo}
      detailCTThao={detailCTThao}
      form={form}
      thongTinHienTai={thongTinHienTai}
      handleDeleteCongTo={handleDeleteCongTo}
      isChanged={isChanged}
      handleChanged={handleChanged}
      loading={loading}
      arrayLoaiDiemDo={arrayLoaiDiemDo}
      renderTable={renderTable}
      changeDD={changeDD}
      handleChangeLDD={handleChangeLDD}
      isLT={isLT}
      getListCongToCMIS={getListCongToCMIS}
      isSoNhan={isSoNhan}
      renderDSCT={renderDSCT}
      isDelCT={isDelCT}
      loaiBienBan={loaiBienBan}
    />
  );
}
