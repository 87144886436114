/* eslint-disable react-hooks/exhaustive-deps */
import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { closeModal, openModal } from "@state/system-config/reducer";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE, QUAN_LY_LOG } from "@utils/constants";

import {
  buildQueryString,
  convertUtcToLocalTime,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { Form } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import BoLocLog from "./bo-loc";
import QuanLyLogDetail from "../quan-ly-log-detail";
import { getListDataExternal, getListModule } from "../services";
const QuanLyLogExternal = () => {
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [isDoubleClick] = useState(true);
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: parseParams(location.search).pageIndex || DEFAULT_PAGEINDEX,
    pageSize: parseParams(location.search).pageSize || DEFAULT_PAGESIZE,
     // module đầu tiên trong danh sách
     moduleCode: "a",
    ...parseParams(location.search),
  });

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const handleGetListInternal = useCallback(() => {
    const query = buildQueryString(filterConditions);
    getListDataExternal(setLoading, setData, filterConditions, setTotal, query);
  }, [filterConditions]);

  useEffect(() => {
    handleGetListInternal();
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [filterConditions]);

  const [listModule, setlLstModule] = useState([]);

  useEffect(() => {
    getListModule((res) => {
      const optionMap = res.map((item) => {
        return {
          value: item.moduleCode,
          label: item.shortName,
        };
      });
      setlLstModule(optionMap);
    });
  }, []);

  const detailHoSo = (record) => {
    if (isDoubleClick)
      return {
        onDoubleClick: () => {
          dispatch(
            openModal({
              content: <QuanLyLogDetail detailHoSo={record} closeModal={closeModal} activeKey={QUAN_LY_LOG.HE_THONG_NGOAI} filterConditions={filterConditions}/>,
              size: "large",
              title: <>Chi tiết yêu cầu</>,
            })
          );
        },
      };
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      align: "center",
      width: "80px",
    },
    {
      title: "Thời gian",
      render: (text, record) => `${convertUtcToLocalTime(record.startTimeStamp, "YYYY-MM-DD HH:mm:ss")}/${convertUtcToLocalTime(record.finishTimeStamp, "YYYY-MM-DD HH:mm:ss")}`,
      align: "center",
      width: "200px",
    },
    {
      title: "Url",
      dataIndex: "path",
      align: "left",
      width: "400px",
    },
    {
      title: "Method",
      dataIndex: "method",
      align: "left",
      width: "100px",
    },
    {
      title: "Request",
      render: (text) => {
        return <>{text}</>;
      },
      dataIndex: "requestBody",
      align: "left",
      width: "400px",
    },
    {
      title: "Response",
      render: (text) => {
        return <>{text}</>;
      },
      dataIndex: "responseBody",
      align: "left",
      width: "400px",
    },
    {
      title: "HttpCode",
      dataIndex: "statusCode",
      align: "left",
      with: "50px",
    },
    {
      title: "User name",
      dataIndex: "userName",
      align: "left",
      with: "50px",
    },
    {
      title: "Exception",
      dataIndex: "exceptionMessage",
      align: "left",
      with: "400px",
    },
  ];

  useLayoutEffect(() => {
    form.setFieldsValue({
      ...filterConditions,
      toDate: filterConditions.toDate ? moment(new Date(filterConditions.toDate)) : null,
      fromDate: filterConditions.fromDate ? moment(new Date(filterConditions.fromDate)) : null,
    });
  }, [filterConditions]);

  useLayoutEffect(() => {
    form.setFieldsValue({
      fromDate: filterConditions.toDate ? moment(new Date(filterConditions.toDate)) : moment(moment().startOf('day').toISOString()),
      toDate: filterConditions.fromDate ? moment(new Date(filterConditions.fromDate)) : moment(new Date().toISOString())
  })
  }, []);

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => {
      return {
        ...oldState,
        ...values,
        ...(values.fromDate && {
          fromDate: moment(values.fromDate).toISOString(),
        }),
        ...(values.toDate && {
          toDate: moment(values.toDate).toISOString(),
        }),
      };
    });
  }, []);

  const clearFilter = () => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
    form.resetFields();
  };

  return (
    <Style>
      <Filter>
        <BoLocLog
          form={form}
          handleSearch={handleSearch}
          filterConditions={filterConditions}
          clearFilter={clearFilter}
          listModule={listModule}
        />
      </Filter>
      <TableComponent
        dataSource={data}
        columns={columns}
        scrollX={2000}
        expandable={false}
        header="Danh sách"
        rowKey="key"
        loading={loading}
        pagination={filterConditions}
        onChangePagination={onChangePagination}
        totalData={total}
        onRow={detailHoSo}
      />
    </Style>
  );
};

export default QuanLyLogExternal;

const Style = styled.div`
  #collapse-container {
    padding: 20px 0px;
  }
`;
