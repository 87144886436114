import { Fragment, useState } from "react";
import Table from "../../common/danh-sach-bao-cao";
import { TYPE_TABLE_BAOCAO } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";

export default function Index() {
  const [fetchList, setFetchList] = useState(false);

  return (
    <Fragment>
      <Table
        url={Endpoint.BC_CT_PHUCTRA}
        typeList={TYPE_TABLE_BAOCAO.PHUCTRADAKIEMTRA}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        header={"Báo cáo chi tiết công tơ phúc tra đã kiểm tra"}
      />
    </Fragment>
  );
}
