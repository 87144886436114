import { BASE_API_URL_GNDN } from "@utils/constants";

export const APIGiaoNhanDienNang = {
  //Danh muc
  GNDN_DANH_MUC_DON_VI_GIAO_NHAN: `${BASE_API_URL_GNDN}/danhmuc/danh-muc-don-vi-giao-nhan`,
  GNDN_DANH_MUC_TRAM: `${BASE_API_URL_GNDN}/danhmuc/danh-muc-tram`,
  GNDN_TIM_KIEM_TRAM: `${BASE_API_URL_GNDN}/danhmuc/danh-muc-tim-kiem-tram`,
  GNDN_DS_CAP_DA: `${BASE_API_URL_GNDN}/danhmuc/ds-cap-dien-ap`,

  // api màn hình cập nhật thông tin điểm đo
  SO_LUONG_DIEM_DO: `${BASE_API_URL_GNDN}/diemdo/so-luong-diem-do`,
  DANH_SACH_DIEM_DO: `${BASE_API_URL_GNDN}/diemdo/danh-sach-diem-do-hoat-dong`,
  DANH_SACH_DIEM_DO_DVK: `${BASE_API_URL_GNDN}/diemdo/danh-sach-diem-do-don-vi-khac`,
  DANH_SACH_DIEM_DO_KHONG_HD: `${BASE_API_URL_GNDN}/diemdo/danh-sach-diem-do-khong-hoat-dong`,
  TAO_MA_DD_NGAY_HIEU_LUC: `${BASE_API_URL_GNDN}/diemdo/tao-ma-diem-do-va-ngay-hieu-luc`,
  // Khai báo điểm đo
  KHAI_BAO_DIEM_DO: `${BASE_API_URL_GNDN}/diemdo/khai-bao-diem-do`,
  THAY_DOI_DIEM_DO: `${BASE_API_URL_GNDN}/diemdo/thay-doi-diem-do`,
  // lay thong tin diem do
  LAY_THONG_TIN_DIEM_DO: `${BASE_API_URL_GNDN}/diemdo/lay-thong-tin-diem-do`,
  GET_ANH_XA_DIEM_DO: `${BASE_API_URL_GNDN}/diemdo/get-anh-xa-diem-do`,
  TAO_NGAY_HIEU_LUC_THAY_DOI: `${BASE_API_URL_GNDN}/diemdo/tao-ngay-hieu-luc`,
  // END
  XOA_DIEM_DO: `${BASE_API_URL_GNDN}/diemdo/xoa-diem-do`,
  NGUNG_DIEM_DO: `${BASE_API_URL_GNDN}/diemdo/ngung-diem-do`,
  THAY_DOI_PHUONG_THUC: `${BASE_API_URL_GNDN}/diemdo/thay-doi-phuong-thuc`,
  SUA_DOI_PHUONG_THUC: `${BASE_API_URL_GNDN}/diemdo/sua-doi-phuong-thuc`,
  XOA_TC_THONG_TIN_DIEM_DO: `${BASE_API_URL_GNDN}/diemdo/xoa-diem-do`,
  HUY_PHUONG_THUC: `${BASE_API_URL_GNDN}/diemdo/xoa-phuong-thuc`,
  LAY_CHI_SO_THAY_DOI_PHUONG_THUC: `${BASE_API_URL_GNDN}/diemdo/lay-chi-so-thay-doi-phuong-thuc`,
  LAY_CHI_SO_SUA_PHUONG_THUC: `${BASE_API_URL_GNDN}/diemdo/lay-chi-so-sua-phuong-thuc`,
  LAY_PHUONG_THUC_MAN_SUA: `${BASE_API_URL_GNDN}/diemdo/lay-phuong-thuc-man-sua`,
  // LIST API GET_SELECTION KHAI BAO DIEM DO CALLBACK
  GET_SELECTION_CAP_DIEN_AP: `${BASE_API_URL_GNDN}/diemdo/get-cap-dien-ap`,
  GET_SELECTION_HINH_THUC_TT: `${BASE_API_URL_GNDN}/diemdo/get-hinh-thuc-thanh-toan`,
  GET_SELECTION_HT_DIEM_DO: `${BASE_API_URL_GNDN}/diemdo/get-tinh-chat-diem-do`,
  GET_SELECTION_TRAM: `${BASE_API_URL_GNDN}/diemdo/get-tram`,
  GET_SELECTION_DVGN: `${BASE_API_URL_GNDN}/diemdo/get-all-don-vi-giao-nhan`,

  // LIST API PHÂN QUYỀN ĐƠN VỊ
  GET_ALL_TRAM: `${BASE_API_URL_GNDN}/diemdo/get-all-tram`,
  GET_LIST_ANH_XA_DIEM_DO: `${BASE_API_URL_GNDN}/diemdo/list-anh-xa-tt-diem-do`,
  GET_ALL_DONVI_GIAONHAN: `${BASE_API_URL_GNDN}/diemdo/ds-don-vi-giao-nhan`,
  GET_DS_PHANQUYEN_DIEM_DO: `${BASE_API_URL_GNDN}/diemdo/ds-phan-quyen-diem-do`,
  POST_CHANGE_TRAM: `${BASE_API_URL_GNDN}/diemdo/phan-quyen-don-vi-diem-do`,

  // BIEN BAN TREO THAO
  GET_DANH_SACH_DIEM_DO: `${BASE_API_URL_GNDN}/bienbantreothao/danh-sach-diem-do`,
  GET_CHECK_VA_GET_BIEN_BAN: `${BASE_API_URL_GNDN}/bienbantreothao/check-get-bien-ban`,
  POST_CONG_TO: `${BASE_API_URL_GNDN}/bienbantreothao/get-cong-to`,
  POST__TAO_BIEN_BAN: `${BASE_API_URL_GNDN}/bienbantreothao/bien-ban`,
  DELETE_BIEN_BAN: `${BASE_API_URL_GNDN}/bienbantreothao/xoa-bien-ban`,
  PUT_SUA_BIEN_BAN: `${BASE_API_URL_GNDN}/bienbantreothao/bien-ban`,
  POST_LAY_BIEN_BAN_TU_CMIS: `${BASE_API_URL_GNDN}/bienbantreothao/lay-bien-ban-tu-cmis`,

  // API LAY THONG TIN
  LAY_LICH_SU_DIEM_DO: `${BASE_API_URL_GNDN}/diemdo/lay-lich-su-diem-do`,
  LAY_LICH_SU_PHUONG_THUC: `${BASE_API_URL_GNDN}/diemdo/lay-lich-su-phuong-thuc`,
  LAY_CHI_SO_LS_PHUONG_THUC: `${BASE_API_URL_GNDN}/diemdo/lay-ds-chi-so-theo-diem-do`,
  LAY_PHUONG_THUC_TAB: `${BASE_API_URL_GNDN}/diemdo/lay-phuong-thuc`,
  GET_THANGLAMVIEC: `${BASE_API_URL_GNDN}/diemdo/lay-thang-lam-viec`,

  // API ANH XA THONG TIN
  POST_ANH_XA_THONG_TIN: `${BASE_API_URL_GNDN}/diemdo/anh-xa-thong-tin-diem-do`,
  LAY_CHI_SO_DOXA_ANHXA: `${BASE_API_URL_GNDN}/diemdo/lay-chi-so-doxa-anhxa`,

  // API BÁO CÁO
  PDF_CHITIET_DNGN: `${BASE_API_URL_GNDN}/baocao/detail-dngn-pdf`,
  EXCEL_CHITIET_DNGN: `${BASE_API_URL_GNDN}/baocao/detail-dngn-excel`,

  PDF_TONGHOP_DNGN: `${BASE_API_URL_GNDN}/baocao/summary-dngn-pdf`,
  EXCEL_TONGHOP_DNGN: `${BASE_API_URL_GNDN}/baocao/summary-dngn-excel`,

  PDF_CHITIET_CSCT_DNGN: `${BASE_API_URL_GNDN}/baocao/chi-tiet-chi-so-dddngn-pdf`,
  EXCEL_CHITIET_CSCT_DNGN: `${BASE_API_URL_GNDN}/baocao/chi-tiet-chi-so-dddngn-excel`,

  PDF_BB_QTDN_PHANKHANG: `${BASE_API_URL_GNDN}/baocao/bbqtdnpk-pdf`,
  EXCEL_BB_QTDN_PHANKHANG: `${BASE_API_URL_GNDN}/baocao/bbqtdnpk-excel`,

  PDF_SAISO_TRAM110: `${BASE_API_URL_GNDN}/baocao/report-error-station-pdf`,
  EXCEL_SAISO_TRAM110: `${BASE_API_URL_GNDN}/baocao/report-error-station-excel`,

  PDF_CTDN_THEOTRAM: `${BASE_API_URL_GNDN}/baocao/bao-cao-giao-nhan-tai-tram-pdf`,
  EXCEL_CTDN_THEOTRAM: `${BASE_API_URL_GNDN}/baocao/bao-cao-giao-nhan-tai-tram-excel`,

  DANH_SACH_CHITIET_SANLUONG: `${BASE_API_URL_GNDN}/baocao/bcctsl`,
  PDF_CHITIET_SANLUONG: `${BASE_API_URL_GNDN}/baocao/bcctsl-pdf`,
  EXCEL_CHITIET_SANLUONG: `${BASE_API_URL_GNDN}/baocao/bcctsl-excel`,

  DANHSACH_DDTT_TRONGTHANG: `${BASE_API_URL_GNDN}/baocao/report-summary`,
  PDF_DDTT_TRONGTHANG: `${BASE_API_URL_GNDN}/baocao/report-summary-pdf`,
  EXCEL_DDTT_TRONGTHANG: `${BASE_API_URL_GNDN}/baocao/report-summary-excel`,

  PDF_SL_DAUNGUON_BAGIA: `${BASE_API_URL_GNDN}/baocao/bcsldn-3gia-pdf`,
  EXCEL_SL_DAUNGUON_BAGIA: `${BASE_API_URL_GNDN}/baocao/bcsldn-3gia-excel`,

  PDF_CHITIET_DN_TUDUNG: `${BASE_API_URL_GNDN}/baocao/tu-dung-tu-bu-pdf`,
  EXCEL_CHITIET_DN_TUDUNG: `${BASE_API_URL_GNDN}/baocao/tu-dung-tu-bu-excel`,

  PDF_BB_QTDN_GIAONHAN: `${BASE_API_URL_GNDN}/baocao/bien-ban-quyet-toan-dien-nang-pdf`,
  EXCEL_BB_QTDN_GIAONHAN: `${BASE_API_URL_GNDN}/baocao/bien-ban-quyet-toan-dien-nang-excel`,
};
