import FormComponent from "@components/Form";
import { FORMAT_ENGLISH } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  Spin,
} from "antd";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { selectBCTongHop } from "./service";

export default function Bieu17ThslTnCacHinhThuc(props) {
  const { screen, handleChange } = props;
  const { Option } = Select;
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [donVi, setDonvi] = useState(1);
  const [, setDataTable] = useState([]);
  const [form] = Form.useForm();
  const [filterConditions, setFilterConditions] = useState({
    ...parseParams(location.search),
    TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
    DenNgay: moment().format(FORMAT_ENGLISH),
  });

  const onFinish = () => {
    const queryString = buildQueryString(
      parseParams({
        PhanLoai: donVi,
        TuNgay: filterConditions.TuNgay,
        DenNgay: filterConditions.DenNgay,
      })
    );
    authGetData({
      url: `${Endpoint.BCTH_TONG_SO_LUONG_TN_QUA_CAC_HT}?${queryString}`,
      setLoading,
      onSuccess: (res) => {
        if (res) {
          console.log(res);
          setDataTable(res.data);
        }
      },
    });
  };
  const onDownloadExcel = () => {
    const queryString = buildQueryString(
      parseParams({
        PhanLoai: donVi,
        TuNgay: filterConditions?.TuNgay,
        DenNgay: filterConditions?.DenNgay,
      })
    );
    const endpoint = `${Endpoint.BCTH_TONG_SO_LUONG_TN_QUA_CAC_HT_EXCEL}?${queryString}`;
    if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
  };

  const onChangeDonvi = (e) => {
    setDonvi(e);
  };

  const handleStartDate = (e, dateString) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      TuNgay: dateString?.split(/\D/)?.reverse()?.join("-"),
    }));
  };

  const handleEndDate = (e, dateString) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      DenNgay: dateString?.split(/\D/)?.reverse()?.join("-"),
    }));
  };

  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              initialValues={{ reportType: screen }}
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="reportType"
                    label="Tên báo cáo"
                    labelAlign="left"
                  >
                    <Row>
                      <Col span={24} pull="8">
                        <Select
                          defaultValue={screen}
                          placeholder="Chọn báo cáo"
                          options={selectBCTongHop}
                          onChange={(e) => handleChange(e)}
                        ></Select>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="unitId" label="Phân loại" labelAlign="left">
                    <Row>
                      <Col span={24} pull="8">
                        <Select defaultValue={"1"} onChange={onChangeDonvi}>
                          <Option value="1">
                            Thông báo TĐ lần 1 (SMS), Dịch vụ điện tử (SMS)
                          </Option>
                          <Option value="2">Thông báo TĐ lần 1 (Zalo)</Option>
                          <Option value="3">
                            Thông báo TĐ lần 1 (App CSKH)
                          </Option>
                          <Option value="4">
                            Tin nhắn dịch vụ khác qua Cổng thông báo CSKH
                          </Option>
                        </Select>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Form.Item
                    name="TuNgay"
                    label="Ngày bắt đầu"
                    labelAlign="left"
                  >
                    <Row>
                      <Col span={20} push={4}>
                        <DatePicker
                          picker="Day"
                          formKey="TuNgay"
                          form={form}
                          format="DD/MM/YYYY"
                          defaultValue={
                            filterConditions.TuNgay
                              ? moment(filterConditions.TuNgay, FORMAT_ENGLISH)
                              : moment().startOf("month").format(FORMAT_ENGLISH)
                          }
                          onChange={handleStartDate}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
                <Col span={6} push={1}>
                  <Form.Item
                    name="DenNgay"
                    label="Ngày kết thúc"
                    labelAlign="left"
                  >
                    <Row>
                      <Col span={20} push={2}>
                        <DatePicker
                          picker="Day"
                          formKey="DenNgay"
                          format="DD/MM/YYYY"
                          form={form}
                          defaultValue={
                            filterConditions.DenNgay
                              ? moment(filterConditions.DenNgay, FORMAT_ENGLISH)
                              : moment()
                          }
                          onChange={handleEndDate}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
                <Col span={4} push={1}>
                  <Form.Item></Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={onDownloadExcel}
                      className="full"
                    >
                      Xuất Excel
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </FormComponent>
            <Divider className="section-text-line" orientation="left"></Divider>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
