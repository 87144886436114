import { memo, useEffect, useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import "moment/locale/vi";
import moment from "moment";
import { formatDateMonth } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import { ENUM_PHANCONG } from "@utils/constants";
import {
  getListData,
  getListUserService,
  handleSubmitDataService,
} from "./services";
import ModalPhanCong from "./modal-phan-cong";

function PhanCong({
  visible,
  handleCloseModal,
  detailHoSo,
  typePhanCong,
  setFetchDataTable,
  donViId,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [ngayHen, setNgayHen] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [dataPhanCong, setDataPhanCong] = useState({});
  const [tenDonVi, setTenDonVi] = useState();
  const [dataDoi, setDataDoi] = useState([]);
  const [dataNguoiKhaoSat, setNguoiKhaoSat] = useState([]);
  const [firstDataDoi, setFirstDataDoi] = useState();
  const [doiId, setDoiId] = useState();
  // onchange ngày

  const onChangeNgayHen = (value) => {
    setNgayHen(value);
  };
  // onchange đội

  const handleDoi = (value) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      ...values,
      donId: value,
      nguoiKSId: null,
    });
    setDoiId(value);

    if (value === undefined) {
      const values = form.getFieldsValue();
      form.setFieldsValue({
        ...values,
        nguoiKSId: null,
      });
      setNguoiKhaoSat(null);
    }
  };
  // submit call api phân công

  const handleSubmitData = (values) => {
    const data = {
      ...values,
      hoSoId: detailHoSo.id,
    };
    const day = moment(ngayHen);
    const convert =
      day.year() +
      "-" +
      formatDateMonth(day.month() + 1) +
      "-" +
      formatDateMonth(day.date());
    data.ngayHen = convert;
    let url = "";
    if (typePhanCong === ENUM_PHANCONG.PHANCONGLAI)
      url = Endpoint.UPDATE_PHAN_CONG_LAI;
    else url = Endpoint.UPDATE_PHAN_CONG;

    handleSubmitDataService(
      url,
      setLoading,
      data,
      setFetchDataTable,
      handleCloseModal,
      form
    );
  };
  // get list người khảo sát theo doiId

  const getListUser = (doiId) => {
    getListUserService(doiId, setLoading, setNguoiKhaoSat);
  };
  // call api khi mở modal

  useEffect(() => {
    const endpoints = [
      `${Endpoint.GET_DON_VI + "?Id=" + donViId}`,
      `${
        Endpoint.LIST_DOI + "?OrderBy=tenDoi&PageSize=-1&DonViId=" + donViId
      } `,
      `${Endpoint.GET_PHAN_CONG + "?HoSoId=" + detailHoSo.id} `,
    ];
    getListData(
      endpoints,
      setLoading,
      setTenDonVi,
      setDataDoi,
      setFirstDataDoi,
      setDataPhanCong
    );
    // eslint-disable-next-line
  }, [detailHoSo]);

  // set data default value

  useEffect(() => {
    if (dataPhanCong && Object.keys(dataPhanCong).length > 0) {
      form.setFieldsValue({
        ngayHen: dataPhanCong.ngayHen ? moment(dataPhanCong.ngayHen) : moment(),
        doiId: dataPhanCong.doiId ? dataPhanCong.doiId : firstDataDoi,
        nguoiKSId: dataPhanCong.doiId ? dataPhanCong.nguoiKSId : null,
      });
      if (dataPhanCong && dataPhanCong.ngayHen) {
        setNgayHen(moment(dataPhanCong.ngayHen));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPhanCong]);

  useEffect(() => {
    if (form.getFieldValue("doiId")) {
      getListUser(form.getFieldValue("doiId"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue("doiId"), doiId]);

  return (
    <ModalPhanCong
      loading={loading}
      typePhanCong={typePhanCong}
      visible={visible}
      handleCloseModal={handleCloseModal}
      form={form}
      handleSubmitData={handleSubmitData}
      ngayHen={ngayHen}
      tenDonVi={tenDonVi}
      handleDoi={handleDoi}
      dataDoi={dataDoi}
      dataNguoiKhaoSat={dataNguoiKhaoSat}
      onChangeNgayHen={onChangeNgayHen}
      t={t}
    ></ModalPhanCong>
  );
}

export default memo(PhanCong);
