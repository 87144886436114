import { BASE_API_URL_GHI_CHI_SO } from "@utils/constants";
export const APIBaoCaoGCS = {
  // 3. Danh sách các sổ GCS theo từng đơn vị, chi tiết đến đội quản lý
  LIST_SO_GCS_DV_DOI_QL: `${BASE_API_URL_GHI_CHI_SO}/baocao/so-gcs`,
  EXCEL_SO_GCS_DV_DOI_QL: `${BASE_API_URL_GHI_CHI_SO}/baocao/so-gcs-excel`,
  PDF_SO_GCS_DV_DOI_QL: `${BASE_API_URL_GHI_CHI_SO}/baocao/so-gcs-pdf`,
  // 4.Danh sách các sổ GCS xuất dữ liệu trước x
  LIST_SO_GCS_XUAT_TRUOC_NGAY: `${BASE_API_URL_GHI_CHI_SO}/baocao/so-gcs-xuat-truoc-ngay`,
  EXCEL_SO_GCS_XUAT_TRUOC_NGAY: `${BASE_API_URL_GHI_CHI_SO}/baocao/so-gcs-xuat-truoc-ngay-excel`,
  PDF_SO_GCS_XUAT_TRUOC_NGAY: `${BASE_API_URL_GHI_CHI_SO}/baocao/so-gcs-xuat-truoc-ngay-pdf`,
  // 5. Danh sách các sổ GCS xuất dữ liệu trước x ngày(1-31)
  LIST_SO_GCS_X_NGAY: `${BASE_API_URL_GHI_CHI_SO}/baocao/so-gcs-x-ngay`,
  EXCEL_SO_GCS_X_NGAY: `${BASE_API_URL_GHI_CHI_SO}/baocao/so-gcs-x-ngay-excel`,
  PDF_SO_GCS_X_NGAY: `${BASE_API_URL_GHI_CHI_SO}/baocao/so-gcs-x-ngay-pdf`,

  // 6. Bảng kê danh sách các khách hàng cập nhật chỉ số thay đổi chỉ số (chỉ số cũ, chỉ số đã sửa)
  LIST_BKDS_KH_THAY_CS: `${BASE_API_URL_GHI_CHI_SO}/baocao/khach-hang-thay-cs`,
  EXCEL_BKDS_KH_THAY_CS: `${BASE_API_URL_GHI_CHI_SO}/baocao/khach-hang-thay-cs-excel`,
  PDF_BKDS_KH_THAY_CS: `${BASE_API_URL_GHI_CHI_SO}/baocao/khach-hang-thay-cs-pdf`,

  // 8. Danh sách các sổ chưa thực hiện ký số, ký số chưa đầy đủ
  LIST_SO_CHUA_KY_SO: `${BASE_API_URL_GHI_CHI_SO}/baocao/so-chua-ky-so`,
  EXCEL_SO_CHUA_KY_SO: `${BASE_API_URL_GHI_CHI_SO}/baocao/so-chua-ky-so-excel`,
  PDF_SO_CHUA_KY_SO: `${BASE_API_URL_GHI_CHI_SO}/baocao/so-chua-ky-so-pdf`,

  // Toa do
  LIST_MA_SO_BAO_CAO_GCS: `${BASE_API_URL_GHI_CHI_SO}/baocao/get-maso`,
  TOA_DO_KHACH_HANG: `${BASE_API_URL_GHI_CHI_SO}/baocao/toa-do-khach-hang`,
  TOA_DO_KHACH_HANG_EXCEL: `${BASE_API_URL_GHI_CHI_SO}/baocao/toa-do-khach-hang-excel`,
  TOA_DO_KHACH_HANG_PDF: `${BASE_API_URL_GHI_CHI_SO}/baocao/toa-do-khach-hang-pdf`,

  // Tong hop anh chup
  TONG_HOP_ANH_CHUP_LIST: `${BASE_API_URL_GHI_CHI_SO}/baocao/bao-cao-tong-hop-anh-chup-gcs`,
  TONG_HOP_ANH_CHUP_EXCEL: `${BASE_API_URL_GHI_CHI_SO}/baocao/bao-cao-tong-hop-anh-chup-gcs-excel`,
  TONG_HOP_ANH_CHUP_PDF: `${BASE_API_URL_GHI_CHI_SO}/baocao/bao-cao-tong-hop-anh-chup-gcs-pdf`,
};
