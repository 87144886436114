import CheckStatus from "@components/CheckStatus";
import { MATRIX_CAP_DIEN, SEQUENCE_TRANGTHAI } from "@utils/constants";
import { Menu } from "antd";

export default function ListMenu(props) {
  const { handleOpenModalXacNhan } = props;
  return (
    <Menu mode="vertical" style={{ width: "100%" }} selectedKeys={["0"]}>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.XACNHANTHUTIENTUNVKS}
        maTrangThai={999}
        matrix={MATRIX_CAP_DIEN.XACNHANTHUTIENNVKS[1]}
      >
        <Menu.Item eventKey="1" onClick={handleOpenModalXacNhan}>
          Xác nhận thu tiền từ NVKS
        </Menu.Item>
      </CheckStatus>
    </Menu>
  );
}
