import FormComponent from "@components/Form";
import { Row } from "antd";
import { Container } from "../../css-styled";
import NhatKyPanel from "../../panels/nhat-ky";

export default function NhatKy(props) {
  // eslint-disable-next-line no-unused-vars
  const {
    detail
  } = props;
  return (
    // <LoadingComponent >
      <FormComponent
        autoComplete="off"
        name="form-chi-tiet-cong-to"
        initialValues={{}}
        layout="vertical"
        onValuesChange={false}
        scrollToFirstError
      >
        <Container>
          <Row gutter={24}>
            <NhatKyPanel
              detail={detail}
              scroll={{ y: 520 }}
              pagination={false}
            />
          </Row>
        </Container>
      </FormComponent>
    // </LoadingComponent>
  );
}
