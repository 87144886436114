import { authGetMultiplesRequest, authPostData } from "@utils/request";
import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { getErrorMessage } from "@utils/function";
import { Endpoint } from "@utils/endpoint";

export const getListDonViService = (donViId, setLoading, setTenDonVi) => {
  const endpoints = [`${Endpoint.GET_DON_VI + "?Id=" + donViId}`];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      if (res[0].data !== null) {
        setTenDonVi(res[0].data.data.tenDonVi);
      } else setTenDonVi("");
    },
  });
};

export const submitChuyenDonViService = (
  value,
  detail,
  setLoading,
  setFetchDataTable,
  closeModal,
  form
) => {
  const data = {
    ...value,
  };
  data.hoSoId = detail.id;
  authPostData({
    url: Endpoint.XU_LY_CHUYEN_DON_VI,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        // fetch data HoSo

        setFetchDataTable(true);
        closeModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};
