import { Col } from "antd";
import styled from "styled-components";

export const CustomRow = styled(Col)`
  .ant-form-item-control-input-content {
    position: relative;
  }
  .ant-form-item-control-input-content:before {
    content: "/";
    width: 10px;
    position: absolute;
    top: 4px;
    right: -20px;
  }
`;
