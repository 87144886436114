/* eslint-disable no-unused-vars */
import React, { memo, useState } from "react";
import { handleXacNhanSL } from "../../services";
import ConfirmModal from "./modal-confirm";

function Index({
  visible,
  handleCloseModal,
  setVisible,
  content,
  title,
  setFetchListDiemDo,
  thangLamViecNew,
  user,
  thangLamViec,
  disabled,
}) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (thangLamViecNew !== "") {
      const thang = thangLamViecNew.split("/")[0];
      const nam = thangLamViecNew.split("/")[1];
      const data = {
        thang: parseInt(thang),
        nam: parseInt(nam),
        donViId: user.unitId,
      };
      handleXacNhanSL(setFetchListDiemDo, setLoading, data, handleCloseModal);
    } else {
      const thang = thangLamViec.split("-")[1];
      const nam = thangLamViec.split("-")[0];
      const data = {
        thang: parseInt(thang),
        nam: parseInt(nam),
        donViId: user.unitId,
      };
      handleXacNhanSL(setFetchListDiemDo, setLoading, data, handleCloseModal);
    }
  };

  return (
    <ConfirmModal
      loading={loading}
      visible={visible}
      closeModal={handleCloseModal}
      handleSubmit={handleSubmit}
      content={content}
      title={title}
    />
  );
}

export default memo(Index);
