import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_MONTH_ENGLISH,
} from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { Table } from "antd";
import Text from "antd/lib/typography/Text";
import { parseInt } from "lodash";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { getChiTietTGGCS } from "./services";
import BaoCaoChiTietThoiGian from "./table";

export default function CTGCS() {
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const { user } = useSelector(authSelector);
  const [dataChiTietTheoTGGCS, setdataChiTietTheoTGGCS] = useState();
  const [totalCount, setTotalCount] = useState();
  const location = useLocation();

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
    DonViId: user.unitId,
    ...parseParams(location.search),
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const getChiTiet = useCallback(() => {
    getChiTietTGGCS(
      filterConditions,
      setLoading,
      setdataChiTietTheoTGGCS,
      setTotalCount
    );
  }, [filterConditions]);

  useEffect(() => {
    getChiTiet();
  }, [getChiTiet]);

  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        ...values,
      }));
    },
    [setFilterConditions]
  );

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
      DonViId: user.unitId,
    });
  }, [user.unitId]);

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 70,
      align: "center",
      render: (a, b, index) => {
        return (
          (filterConditions.pageIndex - 1) * filterConditions.pageSize +
          (index + 1)
        );
      },
      fixed: "left",
    },

    {
      title: "Đơn vị",
      dataIndex: "maDonVi",
      key: "maDonVi",
      width: 150,
      align: "center",
      fixed: "left",
    },
    {
      title: "Đội QLD",
      dataIndex: "doiQLD",
      key: "doiQLD",
      width: 100,
      align: "center",
      fixed: "left",
    },
    {
      title: "Mã sổ GCS",
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      width: 150,
      fixed: "left",
    },
    {
      title: "Mã trạm",
      dataIndex: "maTramList",
      key: "maTramList",
      render: (text) => {
        return <span>{text.join(",")}</span>;
      },
      width: 150,
    },
    {
      title: "Ngày GCS",
      dataIndex: "ngayGCS",
      key: "ngayGCS",
      width: 150,
      render: (ngayGCS) => {
        if (ngayGCS) return <span>{ngayGCS}</span>;
      },
    },
    {
      title: "Kỳ",
      dataIndex: "ky",
      key: "ky",
      align: "center",
      width: 55,
    },
    {
      title: "Tổng số KH",
      dataIndex: "tongSoKH",
      key: "tongSoKH",
      width: 150,
    },
    {
      title: "Tổng sản lượng",
      dataIndex: "tongSanLuong",
      key: "tongSanLuong",
      width: 150,
    },
    {
      title: "Loại sổ",
      dataIndex: "loaiSo",
      key: "loaiSo",
      width: 150,
    },
    {
      title: "Hình thức GCS",
      dataIndex: "hinhThucGCS",
      key: "hinhThucGCS",
      width: 150,
    },
    {
      title: "Thời gian nhận sổ từ CMIS",
      dataIndex: "thoiGianNhanSoTuCMIS",
      key: "thoiGianNhanSoTuCMIS",
      width: 150,
    },
    {
      title: "Thời gian phân công sổ",
      dataIndex: "thoiGianPhanCongSo",
      key: "thoiGianPhanCongSo",
      width: 150,
    },
    {
      title: "Thời gian lấy dữ liệu MDMS",
      dataIndex: "thoiGianLayDuLieuMDMS",
      key: "thoiGianLayDuLieuMDMS",
      width: 150,
    },
    {
      title: "Thời gian lấy dữ liệu HHU",
      dataIndex: "thoiGianLayDuLieuHHU",
      key: "thoiGianLayDuLieuHHU",
      width: 150,
    },
    {
      title: "Thời gian lấy dữ liệu MTB",
      dataIndex: "thoiGianLayDuLieuMTB",
      key: "thoiGianLayDuLieuMTB",
      width: 150,
    },
    {
      title: "Thời gian chuyển dữ liệu về",
      dataIndex: "thoiGianChuyenDuLieuVe",
      key: "thoiGianChuyenDuLieuVe",
      width: 150,
    },
    {
      title: "Thời gian đối soát",
      dataIndex: "thoiGianDoiSoat",
      key: "thoiGianDoiSoat",
      width: 150,
      render: (thoiGianDoiSoat) => {
        if (thoiGianDoiSoat) return <span>{thoiGianDoiSoat}</span>;
      },
    },
    {
      title: "Thời gian NV GCS ký",
      dataIndex: "thoiGianNVGCSKy",
      key: "thoiGianNVGCSKy",
      width: 150,
    },
    {
      title: "Thời gian đội trưởng ký",
      dataIndex: "thoiGianDoiTruongKy",
      key: "thoiGianDoiTruongKy",
      width: 150,
    },
    {
      title: "Thời gian chuyển dữ liệu CMIS",
      dataIndex: "thoiGianChuyenDuLieuCMIS",
      key: "thoiGianChuyenDuLieuCMIS",
      width: 150,
    },
    {
      title: "Thời gian thực hiện",
      dataIndex: "thoiGianThucHien",
      key: "thoiGianThucHien",
      width: 150,
    },
    {
      title: "Chênh lệch ngày GCS và ngày lấy dữ liệu lần cuối",
      dataIndex: "chenhLechNgayGCS",
      key: "chenhLechNgayGCS",
      width: 150,
    },
    {
      title: "Chênh lệch ngày xuất dữ liệu - ngày GCS",
      dataIndex: "chenhLechNgayXuatDuLieu",
      key: "chenhLechNgayXuatDuLieu",
      width: 150,
    },
    {
      title: "Chênh lệch ngày nhận dữ liệu - ngày GCS",
      dataIndex: "chenhLechNgayNhanDuLieu",
      key: "chenhLechNgayNhanDuLieu",
      width: 150,
    },
  ];
  const countTotals = (pageData) => {
    let countKy = 0;
    let counttongSoKH = 0;
    let counttongSanLuong = 0;
    // let countthoiGianThucHien = 0;
    pageData.forEach(({ ky, tongSoKH, tongSanLuong }) => {
      countKy += parseInt(ky);
      counttongSoKH += parseInt(tongSoKH);
      counttongSanLuong += parseFloat(tongSanLuong);
      // countthoiGianThucHien += thoiGianThucHien;
    });
    return (
      <Table.Summary.Row
        className="summary"
        style={{ backgroundColor: "#ccccb3" }}
        colSpan={2}
      >
        <Table.Summary.Cell
          className="summary"
          align="center"
          index={0}
          colSpan={2}
        >
          <Text className="font-bold">Tổng cộng</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold summary"
          align="center"
          index={1}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold summary"
          align="center"
          index={2}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold summary"
          align="center"
          index={3}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold"
          align="center"
          index={4}
        ></Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={5}>
          <Text>{countKy}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={6}>
          <Text>{counttongSoKH}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={7}>
          <Text>{counttongSanLuong}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold"
          align="center"
          index={8}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold"
          align="center"
          index={9}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold"
          align="center"
          index={10}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold"
          align="center"
          index={11}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold"
          align="center"
          index={12}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold"
          align="center"
          index={13}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold"
          align="center"
          index={14}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold"
          align="center"
          index={15}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold"
          align="center"
          index={16}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold"
          align="center"
          index={17}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold"
          align="center"
          index={18}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold"
          align="center"
          index={19}
        ></Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={20}>
          <Text>{0}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={21}>
          <Text>0</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={22}>
          <Text>0</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={23}>
          <Text>0</Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };
  return (
    <BaoCaoChiTietThoiGian
      columns={columns}
      handleSearch={handleSearch}
      filterConditions={filterConditions}
      loading={loading}
      setLoading={setLoading}
      onChangePagination={onChangePagination}
      data={dataChiTietTheoTGGCS}
      totalCount={totalCount}
      scrollX={1400}
      countTotals={countTotals}
      clearFilter={clearFilter}
    />
  );
}
