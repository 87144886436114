import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
import { alertMessage422 } from "../service";

export const selectBCTDPTKH = [
  {
    label: "1. Báo cáo kiểm tra giám sát bán điện",
    value: Endpoint.BCTDPTKH_BC_KT_GIAM_SAT_BAN_DIEN,
  },
  {
    label:
      "2. Báo cáo kiểm tra, giám sát công tác thu tiền điện của người thuê nhà",
    value: Endpoint.BCTDPTKH_BC_KT_GIAM_SAT_CONG_TAC_THU_TIEN_DIEN,
  },
];

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.ThangNam && { Nam: data.ThangNam.split("-")[0] }),
      ...(data.ThangNam && { Thang: data.ThangNam.split("-")[1] }),
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      LoaiCongTo: data.LoaiCongTo,
      KiemDinh: data.KiemDinh,
      KhoThietBi: data.KhoThietBi,
      SoPha: data.SoPha,
      TinhTrang: data.TinhTrang,
      SoNgay: data.SoNgay,
      LoaiKhachHang: data.LoaiKhachHang,
      LuyKe: data.LuyKe,
    })
  );
  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
