import { buildQueryString, parseParams } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";

export const handleListData = (
  location,
  filterConditions,
  setLoading,
  setDataKTGS,
  setTotalCount,
  setSelectedRowKeys
) => {
  let url = "";
  url = `${Endpoint.AG_BP_LIST_KTGS}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      setSelectedRowKeys([]);
      if (res.data !== null) {
        setDataKTGS(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataKTGS([]);
    },
  });
};

export const submitChuyenKTGS = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.AG_BP_CHUYEN_KTGS,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys.toString(),
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
      }
    },
  });
};