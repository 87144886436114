import { useEffect } from "react";
import { Button, Modal, Row, Col } from "antd";
import FormComponent from "@components/Form";
import CheckPermission from "@components/CheckPermission";
import { ap_gia } from "@permissions/e_apgia";


export default function KyDuyetModal(props) {
  const {
    visible,
    handleCloseModalKy,
    onFinish,
    form,
    openModalKySo,
    loading,
    dataCauHinh
  } = props;

  useEffect(() => {
    form.resetFields();
  }, [form]);

  return (
    <Modal
      title="Phòng kinh doanh ký duyệt"
      visible={visible}
      onCancel={handleCloseModalKy}
      className="modal-bpag-ky"
      footer={null}
    >
      <FormComponent
        form={form}
        name="form-bpag-ky"
        layout="horizontal"
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col>
            <div>
              Các yêu cầu áp giá đã chọn sẽ được chuyển tiếp cho Lãnh đạo!
            </div>
            <div>
              Bạn chắc chắn muốn thực hiện?
            </div>
          </Col>
        </Row>
        <br />
        <Row gutter={24} justify="center">
          <Button key="back" onClick={handleCloseModalKy} className="button-closed mr-24px">
            Đóng lại
          </Button>
          {
            dataCauHinh !== 1 &&
            <CheckPermission permissionCode={ap_gia.e32k}>
              <Button
                type="primary"
                className="button-primary mr-24px"
                form="form-bpag-ky"
                onClick={() => openModalKySo()}
                loading={loading}
              >
                Ký HSM
              </Button>
            </CheckPermission>
          }
          <CheckPermission permissionCode={ap_gia.e32x}>
            <Button
              // key="submit"
              type="primary"
              className="button-primary"
              form="form-bpag-ky"
              onClick={() => onFinish()}
              loading={loading}
            >
              Xác nhận
            </Button>
          </CheckPermission>
        </Row>
      </FormComponent>
    </Modal>
  );
}
