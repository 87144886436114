import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  alertMessage,
  buildQueryString,
  getErrorForm,
  getErrorMessage,
  parseParams,
} from "@utils/function";
import { authDeleteData, authGetData, authPostData } from "@utils/request";

// get list data Danh Muc DVGN
export const handleListDataDanhMucDVGiaoNhan = (
  setLoading,
  location,
  setDataDVGiaoNhan,
  setTotalCount,
  filterConditions
) => {
  let url = "";
  url = `${Endpoint.GNDN_DANH_MUC_DON_VI_GIAO_NHAN}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDVGiaoNhan(res.data);
      } else setDataDVGiaoNhan([]);
    },
  });
};

//submit form
export const handleSubmitForm = (
  value,
  setLoading,
  closeModal,
  getListDataDanhMucDVGiaoNhan,
  detailDataTable,
  form
) => {
  const data = {
    ...value,
  };

  if (detailDataTable.id) {
    data.id = detailDataTable.id;
  }

  handleCreateUpdate(
    data,
    setLoading,
    closeModal,
    getListDataDanhMucDVGiaoNhan,
    form
  );
};

// create data DVGN
export const handleCreateDVGN = (
  data,
  setLoading,
  getListDataDanhMucDVGiaoNhan,
  closeModal,
  form
) => {
  authPostData({
    url: Endpoint.GNDN_DANH_MUC_DON_VI_GIAO_NHAN,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        closeModal();
        getListDataDanhMucDVGiaoNhan();
      }
      if (res.statusCode === 422) {
        getErrorForm(res, form);
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

//update data DVGN
const handleUpdateDVGN = (
  data,
  setLoading,
  closeModal,
  getListDataDanhMucDVGiaoNhan,
  form
) => {
  authPostData({
    url: Endpoint.GNDN_DANH_MUC_DON_VI_GIAO_NHAN,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListDataDanhMucDVGiaoNhan();
        closeModal();
      }
      if (res.statusCode === 422) {
        getErrorForm(res, form);
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

//remove data DVGN
export const handleRemoveDVGN = (
  ids,
  setLoading,
  getListDataDanhMucDVGiaoNhan,
  t
) => {
  authDeleteData({
    url: Endpoint.GNDN_DANH_MUC_DON_VI_GIAO_NHAN + "?id=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data)
        getListDataDanhMucDVGiaoNhan();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

// create/update dữ liệu
const handleCreateUpdate = (
  data,
  setLoading,
  closeModal,
  getListDataDanhMucDVGiaoNhan,
  form
) => {
  console.log(data.id);
  if (data.id) {
    //sửa dữ liệu
    console.log("sua", data);

    handleUpdateDVGN(
      data,
      setLoading,
      closeModal,
      getListDataDanhMucDVGiaoNhan,
      form
    );
  } else {
    //tạo dữ liệu
    handleCreateDVGN(
      data,
      setLoading,
      closeModal,
      getListDataDanhMucDVGiaoNhan,
      form
    );
  }
};
