import React, { Fragment, useState } from "react";
import { Divider, Spin, Form, Row, Col, Button, Select } from "antd";
import styled from "styled-components";
import { Endpoint } from "@utils/endpoint";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import DatePickerComponent from "@components/DatePicker";
import { EndpointBTLTNTD, handleDownload, handleOpenPdf } from "./service";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
export default function BieuTiLeThuNopTienDien() {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);

  const onFinish = (values) => {
    handleDownload({ ...values, reportType: EndpointBTLTNTD }, setLoading);
  };

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Biểu tỷ lệ thực hiện thu nộp tiền điện
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              initialValues={{
                unitId: user.user.unitCode,
                LoaiKhachHang: "-1",
              }}
              colon={false}
            >
              <Form.Item
                name="unitId"
                label="Đơn vị"
                labelAlign="left"
                required
              >
                <Selection
                  url={Endpoint.GET_TEAM_LIST_CODE}
                  formKey="unitId"
                  form={form}
                />
              </Form.Item>
              <Form.Item
                name="LoaiKhachHang"
                label="Loại khách hàng"
                labelAlign="left"
                required
              >
                <Select
                  placeholder="Chọn loại khách hàng"
                  formKey="LoaiKhachHang"
                >
                  <Option value="-1">Tất cả</Option>
                  <Option value="0">Sinh hoạt</Option>
                  <Option value="1">Ngoài sinh hoạt</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="reportDate"
                label="Tháng năm"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="month"
                  formKey="reportDate"
                  form={form}
                  format={"YYYY-MM"}
                />
              </Form.Item>
              <Row>
                <Col span={4}></Col>
                <Col span={15}>
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>
                  {/* <Button
                    type="primary"
                    loading={loading}
                    className="ml-20px"
                    onClick={() => {
                      handleOpenPdf(
                        {
                          ...form.getFieldValue(),
                          reportType: EndpointBTLTNTD,
                        },
                        setLoading
                      );
                    }}
                  >
                    In báo cáo
                  </Button> */}
                </Col>
              </Row>
            </FormComponent>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
