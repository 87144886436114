import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import CapDienVersionModal from "./modal-cap-dien-version";

import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import { tien_ich_chung } from "@permissions/m_tienichchung";
import CheckPermission from "@components/CheckPermission";
import { handleListData, handleRemoveData, handleListDataOs } from "./services";
import Table from "./table";
import { CheckCircleFilled } from "@ant-design/icons";

export default function Index() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [detailVersion, setDetailVersion] = useState({});
  const [dataVersion, setDataVersion] = useState([]);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const [dataOs, setDataOs] = useState([]);

  const location = useLocation();
  // eslint-disable-next-line no-unused-vars

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //khai bái columns
  const columns = [
    {
      title: "Version number",
      dataIndex: "versionNumber",
      key: "versionNumber",
      width: "15%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Build number",
      dataIndex: "buildNumber",
      key: "buildNumber",
      width: "15%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Os",
      dataIndex: "os",
      key: "os",
      width: "10%",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Update required",
      dataIndex: "updateRequired",
      key: "updateRequired",
      render: (actived) => (
        <div className="table-text-center">
          {actived ? (
            <CheckCircleFilled style={{ fontSize: "20px", color: "#03A4FF" }} />
          ) : null}
        </div>
      ),
      width: "10%",
    },
    {
      title: "Hoạt động",
      dataIndex: "isActive",
      key: "isActive",
      render: (actived) => (
        <div className="table-text-center">
          {actived ? (
            <CheckCircleFilled style={{ fontSize: "20px", color: "#03A4FF" }} />
          ) : null}
        </div>
      ),
      width: "10%",
    },
    {
      title: "Mô tả",
      dataIndex: "moTa",
      key: "moTa",
      width: "30%",
      render: (text) => (
        <div className="table-text-left">
          <span>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </span>
        </div>
      ),
      sorter: true,
    },
    {
      title: t("tac_vu"),
      key: "action",
      align: "center",
      render: (row) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={tien_ich_chung.m2s}>
            <div className="action-icon" onClick={() => handleOpenModal(row)}>
              <PencilIcon />
            </div>
          </CheckPermission>
          <CheckPermission permissionCode={tien_ich_chung.m2x}>
            <div
              className="action-icon"
              onClick={() => handleDeleteData(row.id)}
            >
              <TrashIcon />
            </div>
          </CheckPermission>
        </div>
      ),
    },
  ];

  //fetch cấu hình chung

  const getListData = useCallback(() => {
    handleListData(setLoading, filterConditions, setDataVersion, setTotalCount);
  }, [filterConditions]);

  useEffect(() => {
    getListData();
    // eslint-disable-next-line
  }, [getListData]);

  //thay đổi pagination table

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //tìm kiếm dữ liệu

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);

  //clear search
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  // Hiển thị  các buttom tạo mới, ...

  const genExtra = () => (
    <div>
      <CheckPermission permissionCode={tien_ich_chung.m2t}>
        <Button
          size="small"
          type="primary"
          className="mr-5px"
          onClick={() => handleOpenModal()}
        >
          Tạo mới
        </Button>
      </CheckPermission>
    </div>
  );

  //xóa dữ liệu

  const handleDeleteData = useCallback(
    (ids) => {
      handleRemoveData(ids, setLoading, getListData, t);
    },
    [getListData, t]
  );

  //hiển thị popup, set dữ liệu chi tiết

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDetailVersion(row);
      else setDetailVersion({});
      setVisible(!visible);
    },
    [visible]
  );
  //đóng popup

  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
  };
  //render popup

  const showModal = useMemo(
    () =>
      visible && (
        <CapDienVersionModal
          detaiDataTable={detailVersion}
          visible={visible}
          closeModal={handleCloseModal}
          getListData={getListData}
          dataOs={dataOs}
        />
      ),
    // eslint-disable-next-line
    [visible]
  );

  useEffect(() => {
    handleListDataOs(setLoading, setDataOs);
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Table
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        genExtra={genExtra}
        dataSource={dataVersion}
        columns={columns}
        totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        t={t}
      />
      {showModal}
    </Fragment>
  );
}
