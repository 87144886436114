import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { TABLE_COLUMNS_THEO_LOAI_YEU_CAU } from "@modules/tich-hop-CRM/common-ho-so/constant";
import CRMFilter from "@modules/tich-hop-CRM/common-ho-so/list-bo-loc";
import { closeModal, openModal } from "@state/system-config/reducer";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Style } from "../../css-styled";

import { dateConditionLogic } from "@modules/tich-hop-CRM/common-ho-so/functions";
import {
  buildQueryString,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { Form } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import ThongTinChiTietTabs from "../../common-ho-so/chi-tiet-yeu-cau/index";
import { getYeuCauDangThucHien } from "./services";

function YeuCauDangThucHien() {
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [form] = Form.useForm();
  const [isDoubleClick] = useState(true);

  const dispatch = useDispatch();
  const donViId = useSelector((state) => state.auth.user.unitId);

  const listTrangThai = [
    {
      name: "Đã phân công",
      value: "ca71dfb1-b72f-4bdc-865b-01c0e3d1f3f9",
    },
    {
      name: "Đã lấy về máy tính bảng",
      value: "0d8e7f9c-0ec6-485a-9261-dd47ad3c628b",
    },
  ];

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DenNgay: `${moment().get("year")}-${
      moment().get("month") + 1
    }-${moment().get("date")}`,
    TuNgay: `${moment().get("year")}-${
      moment().get("month") + 1
    }-${moment().get("date")}`,
    DonViId: donViId,

    ...parseParams(location.search),
  });

  const columns = TABLE_COLUMNS_THEO_LOAI_YEU_CAU.LOAI_4;

  const handleSearch = useCallback(
    (values) => {
      const ojbStartDate = new Date(values.TuNgay);
      const objEndDate = new Date(values.DenNgay);
      const dateCondition = dateConditionLogic(ojbStartDate, objEndDate);

      const payload = {
        TuNgay: dateCondition.formatStartDate,
        DenNgay: dateCondition.formatEndDate,
        ...(values.KenhId && { KenhId: values.KenhId }),
        ...(values.DichVuId && { DichVuId: values.DichVuId }),
        ...(values.DoiId && { DoiId: values.DoiId }),
        ...(values.DonViId && { DonViId: values.DonViId }),
        ...(values.TuKhoaTimKiem && { TuKhoaTimKiem: values.TuKhoaTimKiem }),
        ...(values.TrangThai && { TrangThai: values.TrangThai }),
        ...(values.LoaiYeuCau && { LoaiYeuCau: values.LoaiYeuCau }),
        pageIndex: DEFAULT_PAGEINDEX,
        pageSize: DEFAULT_PAGESIZE,
      };
      setFilterConditions(payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterConditions]
  );

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  useEffect(() => {
    const current_query = filterConditions;

    const updated_query = {
      ...current_query,
      pageSize: filterConditions.pageSize,
      pageIndex: filterConditions.pageIndex,
    };

    const query = buildQueryString(updated_query);

    getYeuCauDangThucHien(
      setLoading,
      setData,
      query,
      filterConditions,
      setTotal
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions]);

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const detailHoSo = (record) => {
    if (isDoubleClick)
      return {
        onDoubleClick: () => {
          dispatch(
            openModal({
              content: (
                <ThongTinChiTietTabs
                  detailHoSo={record}
                  closeModal={closeModal}
                />
              ),
              size: "large",
              title: <>Chi tiết yêu cầu</>,
            })
          );
        },
      };
  };

  const clearFilter = useCallback(() => {
    const payload = {
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DenNgay: `${moment().get("year")}-${
        moment().get("month") + 1
      }-${moment().get("date")}`,
      TuNgay: `${moment().subtract(7, "d").format("YYYY-MM-DD")}`,
      DonViId: donViId,
    };
    setFilterConditions(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Style>
      <div className="tich-hop-CRM-gdv">
        <Filter>
          <CRMFilter
            filterConditions={filterConditions}
            handleSearch={handleSearch}
            form={form}
            clearFilter={clearFilter}
            listTrangThai={listTrangThai}
          />
        </Filter>
        <div className="tich-hop-CRM-gdv-content">
          <TableComponent
            dataSource={data}
            columns={columns}
            scrollX={1600}
            expandable={false}
            header="Danh sách"
            rowKey="key"
            loading={loading}
            totalData={total}
            pagination={filterConditions}
            onChangePagination={onChangePagination}
            onRow={detailHoSo}
          />
        </div>
      </div>
    </Style>
  );
}

export default YeuCauDangThucHien;
