import { Button, Col, Form, Input, Row, Tooltip, Select } from "antd";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { useCallback, useEffect } from "react";
import FormComponent from "@components/Form";

const { Option } = Select;

export function ListFilter(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    tooltip,
    dataChungLoai,
  } = props;
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  const vatTu = [
    {
      value: null,
      name: "Tất cả",
    },
    {
      value: true,
      name: "Vật tư chính",
    },
  ];
  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      autoComplete="off"
    >
      <Row gutter={24} justify="space-between" align="middle">
        <Col span={24} md={6}>
          <Form.Item name="searchTerm" label="Tìm kiếm dữ liệu">
            <Input
              prefix={<SearchOutlined />}
              suffix={
                <Tooltip title={tooltip}>
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="isVatTuChinh" label="Vật tư chính">
            <Select defaultValue={null} allowClear>
              {vatTu && vatTu.length > 0
                ? vatTu.map((item, index) => (
                    <Option key={index} value={item.value}>
                      {item.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="chungLoai" label="Chủng loại">
            <Select allowClear>
              {dataChungLoai && dataChungLoai.length > 0
                ? dataChungLoai.map((item, index) => (
                    <Option key={index} value={item.value.toString()}>
                      {item.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6} style={{ textAlign: "right" }}>
          <Button className="mr-24px" onClick={clear}>
            Bỏ lọc
          </Button>
          <Button type="primary" htmlType="submit" form="filter-form">
            Tìm kiếm
          </Button>
        </Col>
      </Row>
    </FormComponent>
  );
}
