import { Table } from "antd";
import { Fragment } from "react";

export default function RenderContent(props) {
  const { content, columns, dataSource } = props;

  return (
    <Fragment>
      <div
        style={{ marginBottom: "12px", fontSize: "16px", fontWeight: "bold" }}
      >
        {content}
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ y: 300 }}
        rowKey={(obj) => obj.stt}
      />
    </Fragment>
  );
}
