import { Fragment } from "react";
import { Row, Col, Form, Input, DatePicker } from "antd";
import moment from "moment";
import { FORMAT_DATE, TYPE_PHIEUXUAT_CHUCNANG } from "@utils/constants";
import { disabledDateFuture } from "@utils/function";

export default function Index(props) {
  const {
    form,
    type,
    nguoiTao,
    tenLoaiTT,
    ngayTao,
    tenDoi,
    lyDo,
    isTraLai,
    nguoiSuaPhieu,
  } = props;

  return (
    <Fragment>
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="loaiTreoThao" label="Loại treo tháo">
            <Input defaultValue={tenLoaiTT} disabled={true} />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="doiId" label="Đội">
            <Input defaultValue={tenDoi} disabled={true} />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="ngayTao" label="Ngày tạo">
            <DatePicker
              defaultValue={moment(ngayTao)}
              format={FORMAT_DATE}
              form={form}
              notClear={false}
              disabledDate={disabledDateFuture}
              allowClear={false}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="nguoiTao" label="Người tạo">
            <Input
              defaultValue={
                type === TYPE_PHIEUXUAT_CHUCNANG.TAOMOI
                  ? nguoiTao
                  : nguoiSuaPhieu
              }
              disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>
      {type === TYPE_PHIEUXUAT_CHUCNANG.THAYDOI ? (
        <Fragment>
          {isTraLai ? (
            <h3>
              Lý do trả lại:
              <span
                style={{
                  color: "red",
                  fontWeight: "bold",
                  paddingLeft: "24px",
                }}
              >
                {lyDo}
              </span>
            </h3>
          ) : null}
        </Fragment>
      ) : null}
    </Fragment>
  );
}
