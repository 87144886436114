import { APIPKDTreoThao } from "@apiUrl/treo-thao/phong-kinh-doanh";
import { APIDoiTruongTreoThao } from "@apiUrl/treo-thao/doi-truong";
import { APINVTreoThao } from "@apiUrl/treo-thao/nv-treo-thao";
import { APIThiCongTreoThao } from "@apiUrl/treo-thao/thi-cong";
import { APIAnToanTreoThao } from "@apiUrl/treo-thao/nv-an-toan";
import { APIPVTTreoThao } from "@apiUrl/treo-thao/phong-vat-tu";
import { APILanhDaoTreoThao } from "@apiUrl/treo-thao/lanh-dao";
import { APIBaoCaoTreoThao } from "@apiUrl/treo-thao/bao-cao";
import { APITraCuuTreoThao } from "@apiUrl/treo-thao/tra-cuu-tim-kiem";
import { APICommonTreoThao } from "@apiUrl/treo-thao/common";
import { APICauHinhTreoThao } from "../../api-url/treo-thao/cau-hinh";
export const TreoThao_Endpoint = {
  ...APIPKDTreoThao,
  ...APIDoiTruongTreoThao,
  ...APINVTreoThao,
  ...APIThiCongTreoThao,
  ...APIAnToanTreoThao,
  ...APIPVTTreoThao,
  ...APILanhDaoTreoThao,
  ...APIBaoCaoTreoThao,
  ...APITraCuuTreoThao,
  ...APICommonTreoThao,
  ...APICauHinhTreoThao,
};
