import { Endpoint } from "@utils/endpoint";
import React from "react";
import Screen1 from "./screen/screen1";
export default function BKKiemSoatCS() {
  const columns = [
    {
      title: "STT",
      align: "center",
      width: 70,
      render: (text, row) => {
        if (row.children && row.children.length) {
          return null;
        }
        return <span>{row.stt}</span>;
      },
    },
    {
      title: "Mã Điểm đo",
      width: "200px",
      render: (text, row) => {
        if (row.children && row.children.length) {
          return {
            children: (
              <span>{row.maSoGCS ? row.maSoGCS : row.maTramMaSoGcs}</span>
            ),
            props: {
              style: { fontWeight: "bold" },
              colSpan: 16,
            },
          };
        }
        return row.tongSoDiemDo ? (
          {
            children: <span>Tổng số:&emsp;{row.tongSoDiemDo} điểm đo</span>,
            props: {
              style: { fontWeight: "bold" },
              colSpan: 4,
              align: "end",
            },
          }
        ) : (
          <span>{row.maDiemDo}</span>
        );
      },
    },
    {
      title: "Mã GC",
      align: "center",
      render: (text, row) => {
        if (row.children && row.children.length) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return row.tongSoDiemDo ? (
          { props: { style: { display: "none" } } }
        ) : (
          <span>{row.maGC}</span>
        );
      },
    },
    {
      title: "Tên KH",
      width: "200px",
      render: (text, row) => {
        if (row.children && row.children.length) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return row.tongSoDiemDo ? (
          { props: { style: { display: "none" } } }
        ) : (
          <span>{row.tenKhachHang}</span>
        );
      },
    },
    {
      title: "Địa chỉ",
      width: "150px",
      render: (text, row) => {
        if (row.children && row.children.length) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return row.tongSoDiemDo ? (
          { props: { style: { display: "none" } } }
        ) : (
          <span>{row.diaChi}</span>
        );
      },
    },
    {
      title: "Loại BCS",
      align: "center",
      render: (text, row) => {
        if (row.children && row.children.length) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return <span>{row.loaiBCS}</span>;
      },
    },
    {
      title: "Số hộ",
      align: "center",
      render: (text, row) => {
        if (row.children && row.children.length) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return <span>{row.soHo}</span>;
      },
    },
    {
      title: "Số C.Tơ",
      width: "170px",
      render: (text, row) => {
        if (row.children && row.children.length) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        // return {
        //   children: <span>{row.soCto}</span>,
        //   props: {
        //     align: "end",
        //   },
        // };
        return (row.tongSanLuongThao !== null && row.tongSanLuongThao !== undefined)
          ? {
              children: (
                <span>Tổng sản lượng tháo:&emsp; {row.tongSanLuongThao}</span>
              ),
              props: {
                style: { fontWeight: "bold", textAlign: "end" },
                colSpan: 3,
              },
            }
          : {
              children: <span>{row.soCto}</span>,
              props: {
                align: "end",
              },
            };
      },
    },
    {
      title: "Tình trạng",
      align: "center",
      render: (text, row) => {
        if (row.children && row.children.length) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        // return <span>{row.tinhTrang}</span>;
        return (row.tongSanLuongThao !== null && row.tongSanLuongThao !== undefined)
          ? { props: { style: { display: "none" } } }
          : {
              children: <span>{row.tinhTrang}</span>,
              props: {
                align: "end",
              },
            };
      },
    },
    {
      title: "CS Đ.Kỳ",
      render: (text, row) => {
        if (row.children && row.children.length) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        // return {
        //   children: <span>{row.csdk}</span>,
        //   props: {
        //     align: "end",
        //   },
        // };
        return (row.tongSanLuongThao !== null && row.tongSanLuongThao !== undefined)
          ? { props: { style: { display: "none" } } }
          : {
              children: <span>{row.csdk}</span>,
              props: {
                align: "end",
              },
            };
      },
    },
    {
      title: "CS C.Kỳ",
      render: (text, row) => {
        if (row.children && row.children.length) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return row.tongDienNang
          ? {
              children: (
                <span>Tổng điện năng (kWh):&emsp; {row.tongDienNang}</span>
              ),
              props: {
                style: { fontWeight: "bold", textAlign: "end" },
                colSpan: 4,
              },
            }
          : {
              children: <span>{row.csck}</span>,
              props: {
                align: "end",
              },
            };
      },
    },
    {
      title: "Sản lượng tháo",
      render: (text, row) => {
        if (row.children && row.children.length) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }

        return row.tongDienNang
          ? { props: { style: { display: "none" } } }
          : {
              children: <span>{row.sanLuongThao}</span>,
              props: {
                align: "end",
              },
            };
      },
    },
    {
      title: "HSN",

      render: (text, row) => {
        if (row.children && row.children.length) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return row.tongDienNang
          ? { props: { style: { display: "none" } } }
          : {
              children: <span>{row.heSoNhan}</span>,
              props: {
                align: "center",
              },
            };
      },
    },
    {
      title: "DNTT",
      render: (text, row) => {
        if (row.children && row.children.length) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return row.tongDienNang
          ? { props: { style: { display: "none" } } }
          : {
              children: <span>{row.dntt}</span>,
              props: {
                align: "end",
              },
            };
      },
    },
    {
      title: "DNTT K.Trước",
      render: (text, row) => {
        if (row.children && row.children.length) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        // return {
        //   children: <span>{row.dnttkTruoc}</span>,
        //   props: {
        //     align: "end",
        //   },
        // };
        return row.tongDienNangTieuThu
          ? {
              children: (
                <span>Tổng DNTT (kWh):&emsp; {row.tongDienNangTieuThu}</span>
              ),
              props: {
                style: { fontWeight: "bold", textAlign: "end" },
                colSpan: 3,
              },
            }
          : {
              children: <span>{row.dnttkTruoc}</span>,
              props: {
                align: "end",
              },
            };
      },
    },
    {
      title: "CL(%)",
      render: (text, row) => {
        if (row.children && row.children.length) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        // return {
        //   children: <span>{row.cl}</span>,
        //   props: {
        //     align: "end",
        //   },
        // };
        return row.tongDienNangTieuThu
          ? { props: { style: { display: "none" } } }
          : {
              children: <span>{row.cl}</span>,
              props: {
                align: "end",
              },
            };
      },
    },
    {
      title: "Số cột",
      render: (text, row) => {
        if (row.children && row.children.length) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        // return {
        //   children: <span>{row.soCot}</span>,
        //   props: {
        //     align: "end",
        //   },
        // };
        return row.tongDienNangTieuThu
          ? { props: { style: { display: "none" } } }
          : {
              children: <span>{row.soCot}</span>,
              props: {
                align: "end",
              },
            };
      },
    },
  ];
  const endPoint = {
    LIST: Endpoint.LIST_BIEN_BAN_KSCS,
    EXCEL: Endpoint.XUAT_EXCEL_BANG_KE_KSCS,
    PDF: Endpoint.XUAT_PDF_BANG_KE_KSCS,
    ...Endpoint,
  };
  return (
    <Screen1
      header={"Bảng kê kiểm soát chỉ số"}
      columns={columns}
      endPoint={endPoint}
      rowKey={"id"}
      scrollX={2500}
    />
  );
}
