import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Tag } from "antd";
import Table from "./table";

import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { tien_ich_chung } from "@permissions/m_tienichchung";
import CheckPermission from "@components/CheckPermission";
import TrangThaiModal from "./modal-trang-thai";
import { useLocation, useSearchParams } from "react-router-dom";
import PencilIcon from "@assets/icon/PencilIcon";
import { handleListData } from "./services";
import { CheckCircleFilled } from "@ant-design/icons";
import EllipsisTooltip from "@components/EllipsisTooltip";

export default function Index() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [detailTrangThai, setDetailTrangThai] = useState({});
  const [loading, setLoading] = useState(true);
  const [dataTrangThai, setDataTrangThai] = useState([]);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });
  //khai bái columns

  const columns = [
    {
      title: t("trang_thai.ten_trang_thai"),
      dataIndex: "tenTrangThai",
      key: "tenTrangThai",
      width: "30%",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: t("trang_thai.mo_ta"),
      dataIndex: "moTaTrangThai",
      key: "moTaTrangThai",
      width: "35%",
      render: (text) => <EllipsisTooltip title={text}>{text}</EllipsisTooltip>,
      sorter: true,
    },
    {
      title: t("trang_thai.mau"),
      dataIndex: "mau",
      key: "mau",
      width: "15%",
      render: (text) => (
        <div className="table-text-center">
          <Tag color={text}>{text}</Tag>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Hiển thị",
      dataIndex: "displayFlag",
      key: "displayFlag",
      render: (actived) => (
        <div className="table-text-center">
          {actived ? (
            <CheckCircleFilled style={{ fontSize: "20px", color: "#03A4FF" }} />
          ) : null}
        </div>
      ),
      width: "10%",
    },
    {
      title: t("tac_vu"),
      key: "action",
      align: "center",
      render: (row) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={tien_ich_chung.m111s}>
            <div className="action-icon" onClick={() => handleOpenModal(row)}>
              <PencilIcon />
            </div>
          </CheckPermission>
        </div>
      ),
    },
  ];

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);
  //fetch dữ liệu

  const getListData = useCallback(() => {
    handleListData(
      setLoading,
      filterConditions,
      setDataTrangThai,
      setTotalCount
    );
  }, [filterConditions]);

  useEffect(() => {
    getListData();
    // eslint-disable-next-line
  }, [getListData]);
  //thay đổi pagination table

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };
  // Hiển thị  các buttom tạo mới, ...

  const genExtra = () => <div></div>;
  //tìm kiếm dữ liệu

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  //hiển thị popup, set dữ liệu chi tiết

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDetailTrangThai(row);
      else setDetailTrangThai({});
      setVisible(!visible);
    },
    [visible]
  );
  //đóng popup

  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
  };
  //render popup

  const showModal = useMemo(
    () =>
      visible && (
        <TrangThaiModal
          detaiDataTable={detailTrangThai}
          visible={visible}
          closeModal={handleCloseModal}
          getListData={getListData}
        />
      ),
    // eslint-disable-next-line
    [visible]
  );

  return (
    <Fragment>
      <Table
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        genExtra={genExtra}
        dataSource={dataTrangThai}
        columns={columns}
        totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        t={t}
      />
      {showModal}
    </Fragment>
  );
}
