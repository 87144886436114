import TrangChu from "@modules/trung-ap/index";
import KiemTraDieuKienDongDien from "@modules/trung-ap/kiem-tra-dk-dong-dien-nghiem-thu/index";
import ThoaThuanDauNoi from "@modules/trung-ap/thoa-thuan-dau-noi/index";
import TongHopHoSoDangKy from "@modules/trung-ap/tong-hop-ho-so-dang-ky/index";
import { trung_ap } from "@permissions/l_trungap";
import { MODULE_TRUNG_AP } from "@utils/constants";

const mainPath = "/trung-ap";
const routes = [
  {
    title: "",
    path: mainPath,
    privateRoute: true,
    component: TrangChu,
    subItems: [],
    permissionCode: MODULE_TRUNG_AP,
  },
  {
    title: "Thỏa thuận đầu nối",
    path: mainPath + "/thoa-thuan-dau-noi",
    privateRoute: true,
    subItems: [],
    component: ThoaThuanDauNoi,
    permissionCode: trung_ap.l1,
  },
  {
    title: "Kiểm tra điều kiện đóng điện & nghiệm thu",
    path: mainPath + "/kiem-tra-dieu-kien-dong-dien-nghiem-thu",
    privateRoute: true,
    subItems: [],
    component: KiemTraDieuKienDongDien,
    permissionCode: trung_ap.l2,
  },
  {
    title: "Tổng hợp hồ sơ đăng ký",
    path: mainPath + "/tong-hop-ho-so-dang-ky",
    privateRoute: true,
    subItems: [],
    component: TongHopHoSoDangKy,
    permissionCode: trung_ap.l3,
  },
];

export default routes;
