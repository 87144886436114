import React from 'react';
import { Table, Collapse, ConfigProvider, Checkbox } from "antd";
import vn from "antd/es/locale-provider/vi_VN";
import { TableStyled, Container } from "./css-styled";
import { CaretRightOutlined } from "@ant-design/icons";
import {
  customColumn, headerWithSummary
} from "@utils/function";
const { Panel } = Collapse;
export default function TableQuanLyChiSo(props) {
  const {
    rowSelection,
    handleSearch,
    data,
    filterConditions,
    loading,
    EditableRow,
    EditableCell,
    handleOpenChiTietCongToModal,
    header,
    detail,
    handleChange,
    cauHinhValue,
    filter,
    handleSubmitQuet,
    renderExtraAction,
    isDB
  } = props;
  const noteColumns = [
    {
      title: "",
      fixed: true
    },
    {
      title: "1",
    },
    {
      title: "2",
    },
    {
      title: "3",
    },
    {
      title: "4",
    },
    {
      title: "5",
    },
    {
      title: "6",
    },
    {
      title: "7",
    },
    {
      title: "8",
    },
    {
      title: "9",
    },
    {
      title: "10",
    },
    {
      title: "11=(8-9)/8",
    },
    {
      title: "12",
    },
    {
      title: "13",
    },
    {
      title: "14",
    },
    {
      title: "15",
    },
  ];
  const columns = [
    {
      title: "Tên khách hàng",
      dataIndex: "tenKhachhang",
      key: "tenKhachhang",
      render: (text) => (
        <div>{text}</div>
      ),
      width: "10%",
    },
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text, record) => (
        record.id ?
          <div className="table-text-left">
            <span
              style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}
              onClick={() => handleOpenChiTietCongToModal(record.id)}>{record.maDiemDo}</span>
          </div> :
          <div></div>
      ),
      width: "6%",
    },
    {
      title: "Mã công tơ",
      dataIndex: "maThietBi",
      key: "maThietBi",
      render: (text) => (
        <div>{text}</div>
      ),
      width: "6%",
    },
    {
      title: "Số công tơ",
      dataIndex: "soThietBi",
      key: "soThietBi",
      render: (text) => (
        <div>{text}</div>
      ),
      width: "5%",
    },
    {
      title: "Ngày kiểm định",
      dataIndex: "ngayKiemDinh",
      key: "ngayKiemDinh",
      render: (text) => (
        <div className="table-text-center">{text}</div>
      ),
      width: "5%",
    },
    {
      title: "Số pha",
      dataIndex: "soPha",
      key: "soPha",
      render: (text) => (
        <div className="table-text-center">{text}</div>
      ),
      width: "4%",
    },
    {
      title: "BCS",
      dataIndex: "bcs",
      key: "bcs",
      render: (text) => <span>{text}</span>,

      width: "4%",
    },
    {
      title: "Chỉ số tháo",
      dataIndex: "chiSoCMIS",
      key: "chiSoCMIS",
      render: (text) => (
        <div className="table-text-right">{text}</div>
      ),
      width: "5%",
    },
    {
      title: "Chỉ số HES",
      dataIndex: "chiSoHes",
      key: "chiSoHes",
      render: (text) => (
        <div className="table-text-right">{text}</div>
      ),
      width: "5%",
    },
    {
      title: "Chỉ số X5",
      dataIndex: "chiSoX5",
      key: "chiSoX5",
      render: (text) => (
        <div className="table-text-right">{text}</div>
      ),
      width: "6%",
      editable: true,
      onCell: (record) => ({
        record,
        editable: record.isSelected === "3" || record.isSelected === "4" ||  isDB === true ? false : true,
        title: 'Chỉ số X5',
        dataIndex: 'chiSoX5',
        style: record.chiSoX5 !== null && record.chiSoX5 !== undefined ? {
          // backgroundColor: record.chiSoX5 !== record.chiSoHes ? 'yellow' : '',
          color: record.checkSG || record.checkSN ? "red" : "",
          fontWeight: record.checkSG || record.checkSN ? "bold" : "",
        } : {},
      }),
    },
    {
      title: "% chênh lệch",
      dataIndex: "chenhLechSanLuong",
      key: "chenhLechSanLuong",
      render: (text) => (
        <div className="table-text-right">{text}</div>
      ),
      onCell: (record) => ({
        record,
        style: cauHinhValue && Math.abs(record.chenhLechSanLuong) >= cauHinhValue ? {
          color: "purple", fontWeight: "bold"
        } : {}
      }),
      width: "5%",
      filters: filter ? [
        {
          text: ">= giá trị cấu hình",
          value: 1
        },
        {
          text: "< giá trị cấu hình",
          value: 2
        }
      ] : filter,
      onFilter: (value, record) => value === 1 ?
        record.greater === true :
        record.smaller === true,
      ellipsis: true,
    },
    {
      title: "Tình trạng",
      dataIndex: "isScanned",
      key: "isScanned",
      render: (text, record) => (
        record.id ?
        <div className="table-text-center">
        <Checkbox checked={record.isScanned === "1" ? true : false}
          onClick={() => handleSubmitQuet(record.id, record.isScanned)}
          disabled={record.isSelected === "3" || record.isSelected === "4" ||  isDB === true }
        ></Checkbox>
      </div>:
          <div></div>
      ),
      width: "5%",
      // onCell: (record) => ({
      //   style: record.isScanned === "0" ? {
      //     backgroundColor: 'yellow'
      //   } : {},
      // }),
      filters: filter ? [
        {
          text: "Đã quẹt",
          value: "1"
        },
        {
          text: "Chưa quẹt",
          value: "0"
        }
      ] : filter,
      onFilter: (value, record) => record.isScanned === value,
      ellipsis: true,
    },
    {
      title: "Kết quả giao nhận",
      dataIndex: "isSelected",
      key: "isSelected",
      render: (text, record) => (
        record.id ?
          (record.isSelected === "2" || record.isSelected === "4" ?
            <div style={{ fontWeight: "bold", color: "red" }} className="table-text-center">{"Không đạt"}</div> :
            record.isSelected === "1" || record.isSelected === "3"?
              <div style={{ fontWeight: "bold", color: "#1915eb" }} className="table-text-center">{"Đạt"}</div> :
              <div></div>
          ) :
          <div></div>
      ),
      onCell: (record) => ({
        record,
        editable: record.id === undefined || record.isSelected === "3" || record.isSelected === "4" ||  isDB === true ? false : true,
        title: 'Kết quả giao nhận',
        dataIndex: 'isSelected',
        style: record.isSelected === "0" && record.id !== undefined ? {
          backgroundColor: 'yellow'
        } : {},
      }),
      // onCell: (record) => ({
      //   style: record.trangThai === null ? {
      //     backgroundColor: 'yellow'
      //   } : {},
      // }),
      width: "7%",
      filters: filter ? [
        {
          text: "Đạt",
          value: ["1", "3"]
        },
        {
          text: "Không đạt",
          value: ["2", "4"]
        },
        {
          text: "Chưa xác nhận",
          value: "0"
        }
      ] : filter,
      onFilter: (value, record) => value.includes(record.isSelected),
      ellipsis: true,
    },
    {
      title: "Năm sản xuất",
      dataIndex: "namSanXuat",
      key: "namSanXuat",
      render: (text) => <span>{text}</span>,
      width: "4%",
    },
    {
      title: "Ngày tháo công tơ",
      dataIndex: "ngayBienDongCT",
      key: "ngayBienDongCT",
      render: (text) => (
        <div className="table-text-center">{text}</div>
      ),
      width: "5%",
    },
  ]


  return (
    <Container>
      <Collapse
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            style={{ fontSize: 18 }}
            rotate={isActive ? 90 : 0}
          />
        )}
      >
        <Panel
          header={header}
          extra={renderExtraAction ? renderExtraAction() : null}
          // extra={genExtra()}
          collapsible={"header"}
          key="1"
        >
          <ConfigProvider locale={vn}>
            <TableStyled>
              <Table
                onChange={handleChange}
                loading={loading}
                handleSearch={handleSearch}
                dataSource={data}
                columns={customColumn(columns, filterConditions)}
                // totalData={totalCount}
                bordered
                onRow={detail}
                pagination={
                  false
                }
                scroll={{ x: 2500, y: "calc(100vh - 360px)" }}
                rowSelection={rowSelection}
                rowKey="rowNumber"
                components={{
                  header: {
                    wrapper: (props) => headerWithSummary(props, noteColumns),
                  },
                  body: {
                    row: EditableRow,
                    cell: EditableCell,
                  },
                }}
                rowClassName={() => "editable-cell"}
              />

            </TableStyled>
          </ConfigProvider>
        </Panel>

      </Collapse>
    </Container>
  );
}

