import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { alertMessage } from "@utils/function";
import { authPostData } from "@utils/request";

export const callAPICancelYC = (
  payload,
  setLoading,
  setFetchList,
  setSelectedRowKeys,
  handleCloseModalCancel,
  setShowPopup,
  setDataErrors
) => {
  authPostData({
    url: Endpoint.CANCEL_YC_TREO_THAO,
    method: METHOD_POST,
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchList(true);
        setSelectedRowKeys([]);
        handleCloseModalCancel();
        if (res.data?.yeuCauErrors && res.data?.yeuCauErrors?.length > 0) {
          setDataErrors(res.data.yeuCauErrors);
          setShowPopup(true);
        }
        else {
          alertMessage(
            "success",
            "Thông báo!",
            "Hủy yêu cầu treo tháo thành công"
          );
        }
      }
    },
  });
};
