import { memo, useEffect, useRef, useState } from "react";
import { Form, Input, Button, Modal, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { createOrUpdateService } from "./services";

const { TextArea } = Input;

function LoaiYeuCauModal({
  detailLoaiYeuCau,
  visible,
  closeModal,
  getListLoaiYeuCau,
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const handleSubmit = (values) => {
    const data = {
      ...values,
      soThuTu: parseInt(values.soThuTu),
      soNgay: parseInt(values.soNgay),
      maNhomYeuCau: parseInt(values.maNhomYeuCau),
    };
    if (detailLoaiYeuCau.id) {
      data.id = detailLoaiYeuCau.id;
    }
    handleCreateUpdate(data, form);
  };

  const handleSoThuTu = (e) => {
    if (e.target.value === "")
      form.setFieldsValue({
        soThuTu: 0,
      });
  };

  const handleMaNhomYeuCau = (e) => {
    if (e.target.value === "")
      form.setFieldsValue({
        maNhomYeuCau: 0,
      });
  };

  const handleSoNgay = (e) => {
    if (e.target.value === "")
      form.setFieldsValue({
        soNgay: 0,
      });
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();
    // eslint-disable-next-line
  }, []);

  //hàm xử lý call api tạo hoặc sửa

  const handleCreateUpdate = (data, form) => {
    createOrUpdateService(data, setLoading, getListLoaiYeuCau, closeModal, form)
  };

  return (
    <Modal
      title={
        detailLoaiYeuCau.id
          ? t("loai_yeu_cau.cap_nhat_loai_yeu_cau")
          : t("loai_yeu_cau.tao_loai_yeu_cau")
      }
      visible={visible}
      onCancel={closeModal}
      className="modal-loai-yeu-cau"
      footer={null}
    >
      <LoadingComponent loading={loading}>
        <FormComponent
        autoComplete="off"
          form={form}
          name="form-loai-yeu-cau"
          onFinish={handleSubmit}
          initialValues={{
            ...detailLoaiYeuCau,
            soThuTu:
              detailLoaiYeuCau.soThuTu !== undefined
                ? detailLoaiYeuCau.soThuTu
                : 0,
            maNhomYeuCau:
              detailLoaiYeuCau.maNhomYeuCau !== undefined
                ? detailLoaiYeuCau.maNhomYeuCau
                : 0,
            soNgay:
              detailLoaiYeuCau.soNgay !== undefined
                ? detailLoaiYeuCau.soNgay
                : 0,
          }}
          layout="vertical"
        >
          <Row gutter={24}>
            <Col span={24} md={12}>
              <Form.Item
                label={t("loai_yeu_cau.ma_loai_yeu_cau")}
                name="maLoaiYeuCau"
                className="required-field"
              >
                <Input ref={inputRef} placeholder="Nhập mã loại yêu cầu" />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t("loai_yeu_cau.ten_loai_yeu_cau")}
                name="tenLoaiYeuCau"
              >
                <Input placeholder="Nhập tên loại yêu cầu" />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t("loai_yeu_cau.ma_nhom_yeu_cau")}
                name="maNhomYeuCau"
              >
                <Input
                  type="number"
                  min={0}
                  onChange={handleMaNhomYeuCau}
                  placeholder="Nhập mã nhóm yêu cầu"
                />
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Form.Item
                label={t("loai_yeu_cau.ma_doi_tuong")}
                name="maDoiTuong"
              >
                <Input placeholder="Nhập mã đối tượng" />
              </Form.Item>
            </Col>
            <Col span={24} md={10}>
              <Form.Item label={t("loai_yeu_cau.ma_tto")} name="maTTO">
                <Input placeholder="Nhập mã TTO" />
              </Form.Item>
            </Col>
            <Col span={24} md={7}>
              <Form.Item label={t("loai_yeu_cau.so_thu_tu")} name="soThuTu">
                <Input
                  type="number"
                  min={0}
                  onChange={handleSoThuTu}
                  placeholder="Nhập số thứ tự"
                />
              </Form.Item>
            </Col>
            <Col span={24} md={7}>
              <Form.Item label={t("loai_yeu_cau.so_ngay")} name="soNgay">
                <Input
                  type="number"
                  min={0}
                  onChange={handleSoNgay}
                  placeholder="Nhập số ngày"
                />
              </Form.Item>
            </Col>
            <Col span={24} md={24}>
              <Form.Item label={t("loai_yeu_cau.mo_ta")} name="moTa">
                <TextArea rows={3} placeholder="Nhập mô tả" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-loai-yeu-cau"
                loading={loading}
              >
                {detailLoaiYeuCau.id
                  ? t("button.cap_nhat")
                  : t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </LoadingComponent>
    </Modal>
  );
}
export default memo(LoaiYeuCauModal);
