import { memo, useEffect, useRef, useState } from "react";
import { Form, Input, Button, Modal, Row, Col, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { handleSubmitForm } from "./services";

function EmailModal({ detailEmail, visible, closeModal, getListEmail }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const [useSSL, setUseSSL] = useState(detailEmail.useSSL);

  const onFinish = (values) => {
    handleSubmitForm(
      values,
      detailEmail,
      setLoading,
      closeModal,
      getListEmail,
      form
    );
  };
  const handleChangeUseSSL = (e) => {
    setUseSSL(e.target.checked);
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();
    // eslint-disable-next-line
  }, []);

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={
          detailEmail.id ? t("email.cap_nhat_email") : t("email.tao_email")
        }
        visible={visible}
        onCancel={closeModal}
        className="modal-email"
        footer={null}
      >
        <FormComponent
          form={form}
          name="form-email"
          onFinish={onFinish}
          initialValues={{ ...detailEmail }}
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={24} md={12}>
              <Form.Item
                label={t("email.from_email")}
                name="fromEmail"
                className="required-field"
              >
                <Input ref={inputRef} placeholder="Nhập from email" />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t("email.user_email")}
                name="userGmail"
                className="required-field"
              >
                <Input placeholder="Nhập user email" />
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Form.Item
                label={t("email.password")}
                name="password"
                className="required-field"
              >
                <Input.Password placeholder="Nhập mật khẩu" />
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Form.Item
                label={t("email.host_mail")}
                name="hostMail"
                className="required-field"
              >
                <Input placeholder="Nhập host mail" />
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Form.Item
                label={t("email.port_mail")}
                name="portMail"
                className="required-field"
              >
                <Input placeholder="Nhập port mail" />
              </Form.Item>
            </Col>

            <Col
              span={24}
              md={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Item
                label={""}
                name="useSSL"
                style={{ marginBottom: "0" }}
                valuePropName="checked"
              >
                <Checkbox checked={useSSL} onChange={handleChangeUseSSL}>
                  {t("email.use_ssl")}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-email"
              >
                {detailEmail.id ? t("button.cap_nhat") : t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}
export default memo(EmailModal);
