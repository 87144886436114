import { FORMAT_DATE, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import {
  authGetData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { alertMessage } from "@utils/function";
import moment from "moment";
import { closeModal } from "@state/system-config/reducer";
import {
  getSaveDataPopup,
  setCanShowDialogLeaving,
} from "@state/treo-thao/nhan-vien-treo-thao/reducer";
import { handleLapBBLDDuongDay } from "../../services";

const convertDateMoment = (date, month, year, number) => {
  const convertDate = month + 1 + "/" + date + "/" + (year + number);
  return convertDate;
};

export const handleMultiList = (
  setLoading,
  // setLoaiYeuCau,
  // setLoaiLyDo,
  setDataNiemPhong,
  setDataTreoThao,
  detailHoSo
) => {
  const endpoints = [
    // `${Endpoint.COMMON_LIST_LOAI_YEU_CAU}`,
    // `${Endpoint.LIST_LY_DO_TREO_THAO}`,
    `${Endpoint.LIST_NHAN_VIEN_TREO_THAO_NGHIEM_THU}?HoSoId=${detailHoSo?.id}`,
    `${Endpoint.LIST_NHAN_VIEN_NIEM_PHONG_NGHIEM_THU}?HoSoId=${detailHoSo?.id}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      // setLoaiYeuCau(res[0].data.data);
      // let dataLyDo = [];
      // if (res[1].data.data.length > 0) {
      //   dataLyDo = res[1].data.data.filter(
      //     (el) => el.nhom === 1 || el.nhom === "1"
      //   );
      // }
      // setLoaiLyDo(dataLyDo);
      setDataNiemPhong(res[1].data.data);
      setDataTreoThao(res[0].data.data);
    },
  });
};

export const callAPISearchCodeCT = (
  setLoading,
  setDetailCTTreo,
  setDataCMIS,
  maThietBi,
  detail,
  changeDD,
  setMaCongToTreo,
  form,
  soPhaHienTai,
  setIsDelCT,
  setVisible,
  maCongToTreo,
  loaiBienBan,
  setIsSoNhan
) => {
  authGetData({
    url:
      Endpoint.GET_SYNC_LIST_THIET_BI_MOBILE +
      "?maThietBi=" +
      maThietBi +
      "&loaiThietBi=CTO",
    setLoading,
    onSuccess: (res) => {
      setVisible(true);
      if (res.statusCode === STATUSCODE_200 && res.data) {
        const arrBCS = [];
        res.data.boChiSos.forEach((item) => {
          arrBCS.push(item.bcs);
        });

        let congTo2Chieu = form.getFieldValue("layBoChiSoNhan")
          ? form.getFieldValue("layBoChiSoNhan")
          : false;
        if (arrBCS.length > 0) {
          const arr = arrBCS.filter((el) => el.includes("N"));
          if (arr.length > 0) congTo2Chieu = true;
        }
        form.setFieldsValue({
          layBoChiSoNhan: congTo2Chieu,
        });
        setIsSoNhan(congTo2Chieu);

        let validate = true;

        let ngayKiemDinh = moment().format("MM/DD/YYYY");
        if (res.data.ngayKiemDinh) {
          ngayKiemDinh =
            res.data.ngayKiemDinh.split("/")[1] +
            "/" +
            res.data.ngayKiemDinh.split("/")[0] +
            "/" +
            res.data.ngayKiemDinh.split("/")[2];
        }
        if (moment(ngayKiemDinh) <= moment("12/31/2019")) {
          if (parseInt(soPhaHienTai) === 1) {
            const convertDate = convertDateMoment(
              moment(ngayKiemDinh).date(),
              moment(ngayKiemDinh).month(),
              moment(ngayKiemDinh).year(),
              5
            );
            if (moment(convertDate) <= moment()) {
              alertMessage(
                "error",
                "Thông báo!",
                "Vượt quá thời gian kiểm định."
              );
              validate = true;
            } else validate = false;
          } else {
            const convertDate = convertDateMoment(
              moment(ngayKiemDinh).date(),
              moment(ngayKiemDinh).month(),
              moment(ngayKiemDinh).year(),
              2
            );
            if (moment(convertDate) <= moment()) {
              alertMessage(
                "error",
                "Thông báo!",
                "Vượt quá thời gian kiểm định."
              );
              validate = true;
            } else validate = false;
          }
        } else {
          if (parseInt(soPhaHienTai) === 1) {
            if (
              changeDD === 1 ||
              changeDD === "1" ||
              changeDD === 2 ||
              changeDD === "2"
            ) {
              const convertDate = convertDateMoment(
                moment(ngayKiemDinh).date(),
                moment(ngayKiemDinh).month(),
                moment(ngayKiemDinh).year(),
                5
              );
              if (moment(convertDate) <= moment()) {
                alertMessage(
                  "error",
                  "Thông báo!",
                  "Vượt quá thời gian kiểm định."
                );
                validate = true;
              } else validate = false;
            } else {
              const convertDate = convertDateMoment(
                moment(ngayKiemDinh).date(),
                moment(ngayKiemDinh).month(),
                moment(ngayKiemDinh).year(),
                6
              );
              if (moment(convertDate) <= moment()) {
                alertMessage(
                  "error",
                  "Thông báo!",
                  "Vượt quá thời gian kiểm định."
                );
                validate = true;
              } else validate = false;
            }
          } else {
            if (
              changeDD === 1 ||
              changeDD === "1" ||
              changeDD === 2 ||
              changeDD === "2"
            ) {
              const convertDate = convertDateMoment(
                moment(ngayKiemDinh).date(),
                moment(ngayKiemDinh).month(),
                moment(ngayKiemDinh).year(),
                4
              );
              if (moment(convertDate) <= moment()) {
                alertMessage(
                  "error",
                  "Thông báo!",
                  "Vượt quá thời gian kiểm định."
                );
                validate = true;
              } else validate = false;
            } else {
              const convertDate = convertDateMoment(
                moment(ngayKiemDinh).date(),
                moment(ngayKiemDinh).month(),
                moment(ngayKiemDinh).year(),
                3
              );
              if (moment(convertDate) <= moment()) {
                alertMessage(
                  "error",
                  "Thông báo!",
                  "Vượt quá thời gian kiểm định."
                );
                validate = true;
              } else validate = false;
            }
          }
        }
        if (!validate) {
          if (parseInt(res.data.soPha) === parseInt(soPhaHienTai)) {
            if (
              (changeDD === undefined && loaiBienBan === "4") ||
              (changeDD === undefined && loaiBienBan === 4) ||
              (changeDD === null && loaiBienBan === "4") ||
              (changeDD === null && loaiBienBan === 4)
            ) {
              setDetailCTTreo(res.data);
              setMaCongToTreo(maThietBi);
              setDataCMIS(res.data);
              setIsDelCT(false);
              setVisible(false);
            } else if (changeDD === 2 || changeDD === "2") {
              if (
                (arrBCS.indexOf("KT") > -1 && arrBCS.indexOf("VC") > -1) ||
                (arrBCS.indexOf("BT") > -1 && arrBCS.indexOf("VC") > -1)
              ) {
                if (res.data.boChiSos.length > 0)
                  res.data.boChiSos.forEach((item, index) => {
                    form.setFieldsValue({
                      [`chiSoMoi-${index}`]: item.chiSoMoi
                        ? item.chiSoMoi
                        : "0",
                      [`ngayPmax-${index}`]: item.ngayPMax
                        ? moment(item.ngayPMax, FORMAT_DATE)
                        : moment(moment(), FORMAT_DATE),
                      [`pMax-${index}`]: item.pMax ? item.pMax : null,
                    });
                  });
                setDetailCTTreo(res.data);
                setMaCongToTreo(maThietBi);
                setDataCMIS(res.data);
                setIsDelCT(false);
                setVisible(false);
              } else {
                alertMessage(
                  "error",
                  "Thông báo!",
                  "Loại điểm đo 2 thì công tơ treo mới cần có biểu KT;VC hoặc BT;VC !"
                );
                setDetailCTTreo(detail);
                setMaCongToTreo(maCongToTreo);
                setDataCMIS(detail);
                setIsDelCT(false);
                setVisible(true);
              }
            } else if (changeDD === 1 || changeDD === "1") {
              if (arrBCS.indexOf("KT") > -1) {
                if (res.data.boChiSos.length > 0)
                  res.data.boChiSos.forEach((item, index) => {
                    form.setFieldsValue({
                      [`chiSoMoi-${index}`]: item.chiSoMoi
                        ? item.chiSoMoi
                        : "0",
                      [`ngayPmax-${index}`]: item.ngayPMax
                        ? moment(item.ngayPMax, FORMAT_DATE)
                        : moment(moment(), FORMAT_DATE),
                      [`pMax-${index}`]: item.pMax ? item.pMax : null,
                    });
                  });
                setDetailCTTreo(res.data);
                setMaCongToTreo(maThietBi);
                setDataCMIS(res.data);
                setIsDelCT(false);
                setVisible(false);
              } else {
                alertMessage(
                  "error",
                  "Thông báo!",
                  "Loại điểm đo 1 thì công tơ treo mới cần có biểu KT !"
                );
                setDetailCTTreo(detail);
                setMaCongToTreo(maCongToTreo);
                setDataCMIS(detail);
                setIsDelCT(false);
                setVisible(true);
              }
            } else {
              if (res.data.vhCong === "DT" || res.data.vhCong === "D1") {
                if (res.data.boChiSos.length > 0) {
                  res.data.boChiSos.forEach((item, index) => {
                    form.setFieldsValue({
                      [`chiSoMoi-${index}`]: item.chiSoMoi
                        ? item.chiSoMoi
                        : "0",
                      [`ngayPmax-${index}`]: item.ngayPMax
                        ? moment(item.ngayPMax, FORMAT_DATE)
                        : moment(moment(), FORMAT_DATE),
                      [`pMax-${index}`]: item.pMax ? item.pMax : null,
                    });
                  });
                  setDetailCTTreo(res.data);
                  setMaCongToTreo(maThietBi);
                  setDataCMIS(res.data);
                  setIsDelCT(false);
                  setVisible(false);
                }

                if (
                  arrBCS.indexOf("BT") === -1 &&
                  arrBCS.indexOf("CD") === -1 &&
                  arrBCS.indexOf("TD") === -1
                ) {
                  alertMessage(
                    "error",
                    "Thông báo!",
                    "Loại điểm đo 3/5/8 thì công tơ treo mới cần có biểu BT;CD;TD !"
                  );
                  setDetailCTTreo(detail);
                  setMaCongToTreo(maCongToTreo);
                  setDataCMIS(detail);
                  setIsDelCT(false);
                  setVisible(true);
                }
              } else {
                alertMessage(
                  "error",
                  "Thông báo!",
                  "Điểm đo loại 3, 5, 8 chỉ treo công tơ điện tử !"
                );
                setDetailCTTreo(detail);
                setMaCongToTreo(maCongToTreo);
                setDataCMIS(detail);
                setIsDelCT(false);
                setVisible(true);
              }
            }
          } else {
            alertMessage(
              "error",
              "Thông báo!",
              "Số pha của công tơ mới phải bằng số pha của điểm đo"
            );
            setDetailCTTreo(detail);
            setMaCongToTreo(maCongToTreo);
            setDataCMIS(detail);
            setIsDelCT(false);
            setVisible(true);
          }
        }
      } else {
        alertMessage(
          "warning",
          "Thông báo!",
          "Không tìm thấy thông tin thiết bị !"
        );
        setDetailCTTreo(detail);
        setMaCongToTreo(maCongToTreo);
        setDataCMIS(detail);
        setIsDelCT(false);
        setVisible(true);
      }
    },
  });
};

//Lấy công tơ Treo từ CMIS

export const handleGetCongToCMIS = (
  id,
  value,
  setLoading,
  setListCongToCMIS,
  setVisible,
  type,
  setTypeThietBi
) => {
  authGetData({
    url: `${Endpoint.LIST_THIET_BI_CMIS}?HoSoId=${id}&SoThietBi=${value}&LoaiThietBi=${type}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setListCongToCMIS(res.data);
        setTypeThietBi(type);
        setVisible(true);
      }
    },
  });
};
export const handleUpdateCTSYNC = (
  setLoading,
  payload,
  setFetchList,
  setDisabledXN,
  setDataSoBB,
  detailHoSo,
  setDataLapDat,
  dispatch,
  loading,
  setChangeTab1,
  setChangeTab2,
  setChangeTab3
) => {
  let values = {
    ...payload,
    thongTinChung: {
      ...payload.thongTinChung,
      loaiDiemDo:
        payload?.congToTreo && payload?.congToTreo?.loaiDiemDo !== null
          ? Number(payload.congToTreo.loaiDiemDo)
          : payload.thongTinChung.loaiDiemDo,
    },
    congToTreo: null,
  };
  if (payload.congToTreo !== null) {
    values = {
      ...values,
      congToTreo: {
        ...payload.congToTreo,
        loaiDiemDo: Number(payload.congToTreo.loaiDiemDo),
      },
    };
  }
  authPostData({
    url: Endpoint.UPDATE_SYNC_DONG_BO_CAP_NHAT_BIEN_BAN_LAP_DAT,
    method: METHOD_PUT,
    setLoading,
    payload: values,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
        setDisabledXN(false);
        setDataSoBB(res.data.soBienBan);
        const payload = {
          hoSoId: detailHoSo.id,
          isWeb: true,
        };
        handleLapBBLDDuongDay(
          setLoading,
          loading,
          payload,
          dispatch,
          setFetchList,
          detailHoSo,
          (data) => {
            setDataLapDat(data);
          }
        );
        setChangeTab1(false);
        setChangeTab2(false);
        setChangeTab3(false);
        dispatch(getSaveDataPopup(false));
        dispatch(setCanShowDialogLeaving(false));
      } else setDisabledXN(true);
    },
  });
};

export const handleXacNhanChuyenTC = (
  setLoading,
  payload,
  setFetchList,
  dispatch
) => {
  authPostData({
    url: Endpoint.XAC_NHAN_CHUYEN_THI_CONG,
    method: METHOD_PUT,
    setLoading,
    payload,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
        if (dispatch) {
          dispatch(getSaveDataPopup(false));
          dispatch(setCanShowDialogLeaving(false));
          dispatch(closeModal());
        }
      }
    },
  });
};

//Lấy link file Biên bản lắp đặt
export const handleGetFileBBLD = (hoSoId, setLoading, onComplete) => {
  authPostData({
    url: Endpoint.GET_FILE_FILE_BBLD,
    method: METHOD_PUT,
    payload: {
      hoSoId,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        onComplete(res.data);
      } else {
        onComplete("error");
      }
    },
  });
};
