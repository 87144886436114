import React, { useEffect } from "react";
import {
  Button,
  Collapse,
  Row,
  Col,
  Checkbox,
  Form,
  Input,
  AutoComplete,
  Select,
  Typography,
} from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import FormComponent from "@components/Form";
import {
  ContainerBody,
  ContainerBodyPanel,
  ContainerFormCheckBox,
  TableMBD,
} from "../../css-styled";
import LoadingComponent from "@components/Loading";
import {
  CMIS_TREOTHAO_THICONG,
  CONG_TO_THAO,
  FORMAT_DATE,
  TYPE_THIET_BI,
} from "@utils/constants";
import TableComponent from "@components/TableComponent";
import moment from "moment";

const { Panel } = Collapse;
const { Search } = Input;
const { Option } = Select;

export default function LapBienBanLapDatModal(props) {
  const {
    dataLyDoTreoThao,
    form,
    onChangeTreo,
    onChangeThao,
    handleSearch,
    // handleSearchThao,
    dispatch,
    t,
    closeModal,
    onFinish,
    // detailTreo,
    // detailThao,
    loading,
    dataDetailBBLD,
    isShowCongToThao,
    isBieuGia,
    handleChange,
    setVisibleInBBLD,
    displaySearch,
    detailCongToThaoCmis,
    typeCallAPI,
    isShowCongToTreo,
    columnsTi,
    columnsTu,
    dataMayBienDong,
    columnsTiThao,
    columnsTuThao,
    dataTU,
    dataTI,
    isShowTI,
    isShowTU,
    dataTUThao,
    dataTIThao,
    isShowTIThao,
    isShowTUThao,
    handleChangeTI,
    handleChangeTU,
  } = props;

  useEffect(() => {
    form.setFieldsValue({
      //Set Thông tin chung
      tenNhanVien: dataDetailBBLD.tenNhanVien
        ? dataDetailBBLD.tenNhanVien
        : form.getFieldValue("tenNhanVien") !== "" ||
          form.getFieldValue("tenNhanVien") !== null
        ? form.getFieldValue("tenNhanVien")
        : null,
      soBienBan: dataDetailBBLD?.soBienBan
        ? dataDetailBBLD.soBienBan
        : form.getFieldValue("soBienBan") !== "" ||
          form.getFieldValue("soBienBan") !== null
        ? form.getFieldValue("soBienBan")
        : null,
      // nhieuBieuGia: dataDetailBBLD.nhieuBieuGia
      //   ? dataDetailBBLD.nhieuBieuGia
      //   : false,
      lyDoTreoThaoId: dataDetailBBLD.lyDoTreoThaoId
        ? dataDetailBBLD.lyDoTreoThaoId
        : form.getFieldValue("lyDoTreoThaoId") !== "" ||
          form.getFieldValue("lyDoTreoThaoId") !== null
        ? form.getFieldValue("lyDoTreoThaoId")
        : null,
      maKhachHang: dataDetailBBLD.maKhachHang
        ? dataDetailBBLD.maKhachHang
        : null,
      //Set data Treo
      soBienBanTreo:
        typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
        Object.keys(detailCongToThaoCmis).length > 0 &&
        detailCongToThaoCmis.soBienBan
          ? detailCongToThaoCmis.soBienBan
          : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.soBienBan
          ? dataDetailBBLD.congToTreo.soBienBan
          : form.getFieldValue("soBienBanTreo") !== "" ||
            form.getFieldValue("soBienBanTreo") !== null
          ? form.getFieldValue("soBienBanTreo")
          : null,
      tinhTrangNiemPhongTreo:
        typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
        Object.keys(detailCongToThaoCmis).length > 0 &&
        detailCongToThaoCmis.tinhTrangChiNiemPhong
          ? detailCongToThaoCmis.tinhTrangChiNiemPhong
          : dataDetailBBLD.congToTreo &&
            dataDetailBBLD.congToTreo.tinhTrangChiNiemPhong
          ? dataDetailBBLD.congToTreo.tinhTrangChiNiemPhong
          : form.getFieldValue("tinhTrangNiemPhongTreo") !== "" ||
            form.getFieldValue("tinhTrangNiemPhongTreo") !== null
          ? form.getFieldValue("tinhTrangNiemPhongTreo")
          : null,
      heSoNhanHeThongTreo:
        typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
        Object.keys(detailCongToThaoCmis).length > 0 &&
        detailCongToThaoCmis.heSoNhanHeThong
          ? detailCongToThaoCmis.heSoNhanHeThong
          : dataDetailBBLD.congToTreo &&
            dataDetailBBLD.congToTreo.heSoNhanHeThong
          ? dataDetailBBLD.congToTreo.heSoNhanHeThong
          : form.getFieldValue("heSoNhanHeThongTreo") !== "" ||
            form.getFieldValue("heSoNhanHeThongTreo") !== null
          ? form.getFieldValue("heSoNhanHeThongTreo")
          : null,
      maSoChiHomTreo:
        typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
        Object.keys(detailCongToThaoCmis).length > 0 &&
        detailCongToThaoCmis.maSoChiHom
          ? detailCongToThaoCmis.maSoChiHom
          : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.maSoChiHom
          ? dataDetailBBLD.congToTreo.maSoChiHom
          : form.getFieldValue("maSoChiHomTreo") !== "" ||
            form.getFieldValue("maSoChiHomTreo") !== null
          ? form.getFieldValue("maSoChiHomTreo")
          : null,
      soVienChiHomTreo:
        typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
        Object.keys(detailCongToThaoCmis).length > 0 &&
        detailCongToThaoCmis.soVienChiHom
          ? detailCongToThaoCmis.soVienChiHom
          : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.soVienChiHom
          ? dataDetailBBLD.congToTreo.soVienChiHom
          : form.getFieldValue("soVienChiHomTreo") !== "" ||
            form.getFieldValue("soVienChiHomTreo") !== null
          ? form.getFieldValue("soVienChiHomTreo")
          : null,
      kimNiemChiSoTreo:
        typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
        Object.keys(detailCongToThaoCmis).length > 0 &&
        detailCongToThaoCmis.kimNiemChiSo
          ? detailCongToThaoCmis.kimNiemChiSo
          : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.kimNiemChiSo
          ? dataDetailBBLD.congToTreo.kimNiemChiSo
          : form.getFieldValue("kimNiemChiSoTreo") !== "" ||
            form.getFieldValue("kimNiemChiSoTreo") !== null
          ? form.getFieldValue("kimNiemChiSoTreo")
          : null,
      //Set data Thông tin Tháo
      heSoNhanHeThongThao:
        Object.keys(detailCongToThaoCmis).length > 0 &&
        detailCongToThaoCmis.heSoNhanHeThong
          ? detailCongToThaoCmis.heSoNhanHeThong
          : dataDetailBBLD.congToThao &&
            dataDetailBBLD.congToThao.heSoNhanHeThong
          ? dataDetailBBLD.congToThao.heSoNhanHeThong
          : form.getFieldValue("heSoNhanHeThongThao") !== "" ||
            form.getFieldValue("heSoNhanHeThongThao") !== null
          ? form.getFieldValue("heSoNhanHeThongThao")
          : null,
      tinhTrangNiemPhongThao:
        Object.keys(detailCongToThaoCmis).length > 0 &&
        detailCongToThaoCmis.tinhTrangChiNiemPhong
          ? detailCongToThaoCmis.tinhTrangChiNiemPhong
          : dataDetailBBLD.congToThao &&
            dataDetailBBLD.congToThao.tinhTrangChiNiemPhong
          ? dataDetailBBLD.congToThao.tinhTrangChiNiemPhong
          : form.getFieldValue("tinhTrangNiemPhongThao") !== "" ||
            form.getFieldValue("tinhTrangNiemPhongThao") !== null
          ? form.getFieldValue("tinhTrangNiemPhongThao")
          : null,
    });

    // for (let i = 0; i < dataTI.length; i++) {
    //   form.setFieldsValue({
    //     [`soThietBi-${i}-ti`]: dataTI[i][`soThietBi-${i}-ti`],
    //     [`namSanXuat-${i}-ti`]: dataTI[i][`namSanXuat-${i}-ti`]
    //       ? moment(dataTI[i][`namSanXuat-${i}-ti`])
    //       : form.getFieldValue(`namSanXuat-${i}-ti`) !== "" ||
    //         form.getFieldValue(`namSanXuat-${i}-ti`) !== null
    //       ? form.getFieldValue(`namSanXuat-${i}-ti`)
    //       : moment(),
    //     [`ngayKiemDinh-${i}-ti`]: dataTI[i][`ngayKiemDinh-${i}-ti`]
    //       ? moment(dataTI[i][`ngayKiemDinh-${i}-ti`], FORMAT_DATE)
    //       : form.getFieldValue(`ngayKiemDinh-${i}-ti`) !== "" ||
    //         form.getFieldValue(`ngayKiemDinh-${i}-ti`) !== null
    //       ? form.getFieldValue(`ngayKiemDinh-${i}-ti`)
    //       : moment(moment(), FORMAT_DATE),
    //     [`loai-${i}-ti`]:
    //       dataTI[i][`loai-${i}-ti`] !== "" || dataTI[i][`loai-${i}-ti`] !== null
    //         ? dataTI[i][`loai-${i}-ti`]
    //         : form.getFieldValue(`loai-${i}-ti`) !== "" ||
    //           form.getFieldValue(`loai-${i}-ti`) !== null
    //         ? form.getFieldValue(`ngayKiemDinh-${i}-ti`)
    //         : null,
    //     [`tySoBien-${i}-ti`]: dataTI[i][`tySoBien-${i}-ti`]
    //       ? dataTI[i][`tySoBien-${i}-ti`]
    //       : form.getFieldValue(`tySoBien-${i}-ti`) !== "" ||
    //         form.getFieldValue(`tySoBien-${i}-ti`) !== null
    //       ? form.getFieldValue(`tySoBien-${i}-ti`)
    //       : null,
    //     [`chiHopVien-${i}-ti`]: dataTI[i][`chiHopVien-${i}-ti`]
    //       ? dataTI[i][`chiHopVien-${i}-ti`]
    //       : form.getFieldValue(`chiHopVien-${i}-ti`) !== "" ||
    //         form.getFieldValue(`chiHopVien-${i}-ti`) !== null
    //       ? form.getFieldValue(`chiHopVien-${i}-ti`)
    //       : null,
    //     [`temKDVien-${i}-ti`]: dataTI[i][`temKDVien-${i}-ti`]
    //       ? dataTI[i][`temKDVien-${i}-ti`]
    //       : form.getFieldValue(`temKDVien-${i}-ti`) !== "" ||
    //         form.getFieldValue(`temKDVien-${i}-ti`) !== null
    //       ? form.getFieldValue(`temKDVien-${i}-ti`)
    //       : null,
    //   });
    // }

    // for (let i = 0; i < dataTU.length; i++) {
    //   form.setFieldsValue({
    //     [`soThietBi-${i}-tu`]: dataTU[i][`soThietBi-${i}-tu`],
    //     [`namSanXuat-${i}-tu`]: dataTU[i][`namSanXuat-${i}-tu`]
    //       ? moment(dataTU[i][`namSanXuat-${i}-tu`])
    //       : "",
    //     [`ngayKiemDinh-${i}-tu`]: dataTU[i][`ngayKiemDinh-${i}-tu`]
    //       ? moment(dataTU[i][`ngayKiemDinh-${i}-tu`], FORMAT_DATE)
    //       : moment(moment(), FORMAT_DATE),
    //     [`loai-${i}-tu`]: dataTU[i][`loai-${i}-tu`],
    //     [`tySoBien-${i}-tu`]: dataTU[i][`tySoBien-${i}-tu`],
    //     [`chiHopVien-${i}-tu`]: dataTU[i][`chiHopVien-${i}-tu`],
    //     [`temKDVien-${i}-tu`]: dataTU[i][`temKDVien-${i}-tu`],
    //   });
    // }
  }, [
    dataDetailBBLD,
    dataMayBienDong,
    dataTI,
    dataTU,
    detailCongToThaoCmis,
    form,
    typeCallAPI,
  ]);

  useEffect(() => {
    if (dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.soCongTo) {
      form.setFields([
        {
          name: ["congToTreo"],
          errors: false,
        },
      ]);
    }
    if (dataDetailBBLD.congToThao && dataDetailBBLD.congToThao.soCongTo) {
      form.setFields([
        {
          name: ["congToThao"],
          errors: false,
        },
      ]);
    }
  }, [dataDetailBBLD, form]);

  return (
    <LoadingComponent loading={loading}>
      <FormComponent
        form={form}
        name="form-submit"
        onFinish={onFinish}
        initialValues={{}}
        layout="vertical"
        autoComplete="off"
        scrollToFirstError
      >
        <ContainerBody>
          <Row gutter={24} justify="space-between" align="middle">
            <Col span={24} md={24}>
              <h6 className="fs-14px c-t-label font-bold">Thông tin chung</h6>
            </Col>
            <Col span={24} md={4}>
              <Form.Item
                name="soBienBan"
                label="Số biên bản"
                // className="form-label-dl"
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col span={24} md={5}>
              <ContainerFormCheckBox name="nhieuBieuGia">
                <Checkbox onChange={handleChange} checked={isBieuGia}>
                  Biên bản lắp đặt nhiều biểu giá
                </Checkbox>
              </ContainerFormCheckBox>
            </Col> */}
            <Col span={24} md={5}>
              <Form.Item name="tenNhanVien" label="Nhân viên treo tháo">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={5}>
              <Form.Item name="lyDoTreoThaoId" label="Lý do treo tháo">
                <Select
                  loading={loading}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataLyDoTreoThao && dataLyDoTreoThao.length > 0
                    ? dataLyDoTreoThao.map((item, index) => {
                        if (item.value !== null) {
                          return (
                            <Option key={index} value={item.value}>
                              {item.name}
                            </Option>
                          );
                        }
                        return null;
                      })
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={6}>
              <Form.Item
                label="Mã khách hàng"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.maKhachHang !== curValues.maKhachHang
                }
              >
                {({ getFieldValue }) => {
                  const maKhachHang = getFieldValue("maKhachHang") || undefined;
                  return maKhachHang ? (
                    <Typography.Text className="ant-form-text" strong>
                      {maKhachHang}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
              {/* <Form.Item label="Mã khách hàng" name="maKhachHang">
                <Input disabled={false} />
              </Form.Item> */}
            </Col>

            <Col span={24} md={4} style={{ textAlign: "right" }}>
              <Button
                onClick={() => setVisibleInBBLD(true)}
                type="primary"
                className="a-to-button"
              >
                Biên bản lắp đặt
              </Button>
            </Col>
            <Col span={24} md={5}>
              <ContainerFormCheckBox name="nhieuBieuGia">
                <Checkbox onChange={handleChange} checked={isBieuGia}>
                  Biên bản lắp đặt nhiều biểu giá
                </Checkbox>
              </ContainerFormCheckBox>
            </Col>
          </Row>
        </ContainerBody>
        <Collapse
          defaultActiveKey={["1", "2"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined
              style={{ fontSize: 18 }}
              rotate={isActive ? 90 : 0}
            />
          )}
        >
          <Panel header={"Công tơ treo"} collapsible={"header"} key="1">
            <Row
              gutter={24}
              style={{ display: isShowCongToTreo ? "flex" : "none" }}
            >
              <Col span={16}></Col>
              <Col span={24} md={8}>
                <AutoComplete
                  dropdownClassName="certain-category-search-dropdown"
                  dropdownMatchSelectWidth={300}
                  style={{
                    width: "100%",
                  }}
                >
                  <Search
                    placeholder="Nhập số công tơ treo"
                    allowClear={true}
                    enterButton="Tìm"
                    onSearch={(value) => handleSearch(value, TYPE_THIET_BI.CT)}
                    onChange={onChangeTreo}
                    onKeyDown={(e) =>
                      e.keyCode === 13 ? e.preventDefault() : ""
                    }
                  />
                </AutoComplete>
              </Col>
            </Row>
            <ContainerBodyPanel>
              <Row gutter={24}>
                {/* <Col span={6}>
                  <Form.Item
                    name="soBienBanTreo"
                    label="Số biên bản"
                    className="form-label-dl"
                  >
                    <Input />
                  </Form.Item>
                </Col> */}
                <Col span={8}>
                  <Form.Item
                    name="maSoChiHomTreo"
                    label="Mã số chì hòm"
                    className="form-label-dl"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="soVienChiHomTreo"
                    label="Số viên chì hòm"
                    className="form-label-dl"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="kimNiemChiSoTreo"
                    label="Kìm niêm chì số"
                    className="form-label-dl"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="heSoNhanHeThongTreo"
                    label="Hệ số nhân hệ thống đo đếm"
                    className="form-label-dl"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="tinhTrangNiemPhongTreo"
                    label="Tình trạng chì niêm phong"
                    className="form-label-dl"
                  >
                    <Input type="text" />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
                <Col span={6}>
                  <Form.Item name="congToTreo" style={{ marginBottom: 0 }}>
                    <h6 className="fs-12px c-t-label">Số công tơ</h6>
                    <h5 className="fs-14px font-bold">
                      {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                      Object.keys(detailCongToThaoCmis).length > 0 &&
                      detailCongToThaoCmis.soCongTo !== null
                        ? detailCongToThaoCmis.soCongTo
                        : dataDetailBBLD.congToTreo &&
                          dataDetailBBLD.congToTreo.soCongTo !== null
                        ? dataDetailBBLD.congToTreo.soCongTo
                        : null}
                    </h5>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Mã công tơ</h6>
                  <h5 className="fs-14px font-bold">
                    {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                    Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.maCongTo !== null
                      ? detailCongToThaoCmis.maCongTo
                      : dataDetailBBLD.congToTreo &&
                        dataDetailBBLD.congToTreo.maCongTo !== null
                      ? dataDetailBBLD.congToTreo.maCongTo
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Loại công tơ</h6>
                  <h5 className="fs-14px font-bold">
                    {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                    Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.loaiCongTo !== null
                      ? detailCongToThaoCmis.loaiCongTo
                      : dataDetailBBLD.congToTreo &&
                        dataDetailBBLD.congToTreo.loaiCongTo !== null
                      ? dataDetailBBLD.congToTreo.loaiCongTo
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Hệ số nhân</h6>
                  <h5 className="fs-14px font-bold">
                    {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                    Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.heSoNhan !== null
                      ? detailCongToThaoCmis.heSoNhan
                      : dataDetailBBLD.congToTreo &&
                        dataDetailBBLD.congToTreo.heSoNhan !== null
                      ? dataDetailBBLD.congToTreo.heSoNhan
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Nước sản xuất</h6>
                  <h5 className="fs-14px font-bold">
                    {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                    Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.maNuoc !== null
                      ? detailCongToThaoCmis.maNuoc
                      : dataDetailBBLD.congToTreo &&
                        dataDetailBBLD.congToTreo.maNuoc !== null
                      ? dataDetailBBLD.congToTreo.maNuoc
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Năm sản xuất</h6>
                  <h5 className="fs-14px font-bold">
                    {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                    Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.namSanXuat !== null
                      ? detailCongToThaoCmis.namSanXuat
                      : dataDetailBBLD.congToTreo &&
                        dataDetailBBLD.congToTreo.namSanXuat !== null
                      ? dataDetailBBLD.congToTreo.namSanXuat
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Hằng số K</h6>
                  <h5 className="fs-14px font-bold">
                    {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                    Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.hangSoK !== null
                      ? detailCongToThaoCmis.hangSoK
                      : dataDetailBBLD.congToTreo &&
                        dataDetailBBLD.congToTreo.hangSoK !== null
                      ? dataDetailBBLD.congToTreo.hangSoK
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">BCS</h6>
                  <h5 className="fs-14px font-bold">
                    {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                    Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.bcs !== null
                      ? detailCongToThaoCmis.bcs
                      : dataDetailBBLD.congToTreo &&
                        dataDetailBBLD.congToTreo.bcs !== null
                      ? dataDetailBBLD.congToTreo.bcs
                      : null}
                  </h5>
                </Col>

                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Dòng điện</h6>
                  <h5 className="fs-14px font-bold">
                    {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                    Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.dongDien !== null
                      ? detailCongToThaoCmis.dongDien
                      : dataDetailBBLD.congToTreo &&
                        dataDetailBBLD.congToTreo.dongDien !== null
                      ? dataDetailBBLD.congToTreo.dongDien
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Điện áp</h6>
                  <h5 className="fs-14px font-bold">
                    {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                    Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.dienAp !== null
                      ? detailCongToThaoCmis.dienAp
                      : dataDetailBBLD.congToTreo &&
                        dataDetailBBLD.congToTreo.dienAp !== null
                      ? dataDetailBBLD.congToTreo.dienAp
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">
                    Mã chì kiểm định, lập trình
                  </h6>
                  <h5 className="fs-14px font-bold">
                    {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                    Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.maChiKiemDinh !== null
                      ? detailCongToThaoCmis.maChiKiemDinh
                      : dataDetailBBLD.congToTreo &&
                        dataDetailBBLD.congToTreo.maSoChiKiemDinh !== null
                      ? dataDetailBBLD.congToTreo.maSoChiKiemDinh
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">
                    SL chì kiểm định, lập trình
                  </h6>
                  <h5 className="fs-14px font-bold">
                    {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                    Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.slChiKiemDinh !== null
                      ? detailCongToThaoCmis.slChiKiemDinh
                      : dataDetailBBLD.congToTreo &&
                        dataDetailBBLD.congToTreo.soVienChiKiemDinh !== null
                      ? dataDetailBBLD.congToTreo.soVienChiKiemDinh
                      : null}
                  </h5>
                </Col>

                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Mã tem kiểm định</h6>
                  <h5 className="fs-14px font-bold">
                    {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                    Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.maTemKiemDinh !== null
                      ? detailCongToThaoCmis.maTemKiemDinh
                      : dataDetailBBLD.congToTreo &&
                        dataDetailBBLD.congToTreo.maSoTemKiemDinh !== null
                      ? dataDetailBBLD.congToTreo.maSoTemKiemDinh
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Ngày kiểm định</h6>
                  <h5 className="fs-14px font-bold">
                    {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                    Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.ngayKiemDinh !== null
                      ? detailCongToThaoCmis.ngayKiemDinh
                      : dataDetailBBLD.congToTreo &&
                        dataDetailBBLD.congToTreo.ngayKiemDinh !== null
                      ? dataDetailBBLD.congToTreo.ngayKiemDinh
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">CCX</h6>
                  <h5 className="fs-14px font-bold">
                    {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                    Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.ccx !== null
                      ? detailCongToThaoCmis.ccx
                      : dataDetailBBLD.congToTreo &&
                        dataDetailBBLD.congToTreo.ccx !== null
                      ? dataDetailBBLD.congToTreo.ccx
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Số lần cảnh báo</h6>
                  <h5 className="fs-14px font-bold">
                    {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                    Object.keys(`detailCongToThaoCmis`).length > 0 &&
                    detailCongToThaoCmis.soLanCanhBao !== null
                      ? detailCongToThaoCmis.soLanCanhBao
                      : dataDetailBBLD.congToTreo &&
                        dataDetailBBLD.congToTreo.soLanCanhBao !== null
                      ? dataDetailBBLD.congToTreo.soLanCanhBao
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">
                    Mã chì quang, hộp đấu dây
                  </h6>
                  <h5 className="fs-14px font-bold">
                    {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                    Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.maChiQuanHopDauDay !== null
                      ? detailCongToThaoCmis.maChiQuanHopDauDay
                      : dataDetailBBLD.congToTreo &&
                        dataDetailBBLD.congToTreo.maChiQuanHopDauDay !== null
                      ? dataDetailBBLD.congToTreo.maChiQuanHopDauDay
                      : null}
                  </h5>
                </Col>

                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Số tem cổng quang</h6>
                  <h5 className="fs-14px font-bold">
                    {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                    Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.soTemCongQuang !== null
                      ? detailCongToThaoCmis.soTemCongQuang
                      : dataDetailBBLD.congToTreo &&
                        dataDetailBBLD.congToTreo.soTemCongQuan !== null
                      ? dataDetailBBLD.congToTreo.soTemCongQuan
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">
                    Số viên mã số chì cổng quang
                  </h6>
                  <h5 className="fs-14px font-bold">
                    {typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
                    Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.soVienMaSoChiCongQuang !== null
                      ? detailCongToThaoCmis.soVienMaSoChiCongQuang
                      : dataDetailBBLD.congToTreo &&
                        dataDetailBBLD.congToTreo.soVienMaSoChiCongQuang !==
                          null
                      ? dataDetailBBLD.congToTreo.soVienMaSoChiCongQuang
                      : null}
                  </h5>
                </Col>
                <Col span={24}>
                  <Row gutter={24}>
                    <Col md={4}>
                      <ContainerFormCheckBox name="dataTi">
                        <Checkbox onChange={handleChangeTI} checked={isShowTI}>
                          Thông tin TI
                        </Checkbox>
                      </ContainerFormCheckBox>
                    </Col>
                    <Col md={4}>
                      <ContainerFormCheckBox name="dataTu">
                        <Checkbox onChange={handleChangeTU} checked={isShowTU}>
                          Thông tin TU
                        </Checkbox>
                      </ContainerFormCheckBox>
                    </Col>
                  </Row>
                  <TableMBD style={{ display: isShowTI ? "block" : "none" }}>
                    <TableComponent
                      id={"custom-collapse-container"}
                      pagination={false}
                      scrollY={300}
                      header="Máy biến dòng TI"
                      dataSource={dataTI}
                      columns={columnsTi}
                    />
                  </TableMBD>
                  <TableMBD style={{ display: isShowTU ? "block" : "none" }}>
                    <TableComponent
                      id={"custom-collapse-container"}
                      pagination={false}
                      scrollY={300}
                      header="Máy biến dòng TU"
                      dataSource={dataTU}
                      columns={columnsTu}
                    />
                  </TableMBD>
                </Col>
              </Row>
            </ContainerBodyPanel>
          </Panel>

          <Panel
            header={"Công tơ tháo"}
            collapsible={"header"}
            key="2"
            style={{ display: isShowCongToThao ? "block" : "none" }}
          >
            <Row
              gutter={24}
              style={{ display: displaySearch ? "none" : "flex" }}
            >
              <Col span={16}></Col>
              <Col span={24} md={8}>
                <AutoComplete
                  dropdownClassName="certain-category-search-dropdown"
                  dropdownMatchSelectWidth={300}
                  style={{
                    width: "100%",
                  }}
                  disabled={displaySearch}
                >
                  <Search
                    placeholder="Nhập số công tơ tháo"
                    allowClear={true}
                    enterButton="Tìm"
                    onSearch={(value) => handleSearch(value, CONG_TO_THAO)}
                    onChange={onChangeThao}
                    onKeyDown={(e) =>
                      e.keyCode === 13 ? e.preventDefault() : ""
                    }
                  />
                </AutoComplete>
              </Col>
            </Row>
            <ContainerBodyPanel>
              <Row gutter={24}>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Số biên bản</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.soBienBan !== null
                      ? detailCongToThaoCmis.soBienBan
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Mã số chì hòm</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.maSoChiHom !== null
                      ? detailCongToThaoCmis.maSoChiHom
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Số viên chì hòm</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.soVienChiHom !== null
                      ? detailCongToThaoCmis.soVienChiHom
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Kìm niêm chì số</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.kimNiemChiSo !== null
                      ? detailCongToThaoCmis.kimNiemChiSo
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="heSoNhanHeThongThao"
                    label="Hệ số nhân hệ thống đo đếm"
                    className="form-label-dl"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="tinhTrangNiemPhongThao"
                    label="Tình trạng chì niêm phong"
                    className="form-label-dl"
                  >
                    <Input type="text" />
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
                <Col span={6}>
                  <Form.Item name="congToThao" style={{ marginBottom: 0 }}>
                    <h6 className="fs-12px c-t-label">Số công tơ</h6>
                    <h5 className="fs-14px font-bold">
                      {Object.keys(detailCongToThaoCmis).length > 0 &&
                      detailCongToThaoCmis.soCongTo !== null
                        ? detailCongToThaoCmis.soCongTo
                        : null}
                    </h5>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Mã công tơ</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.maCongTo !== null
                      ? detailCongToThaoCmis.maCongTo
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Loại công tơ</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.loaiCongTo !== null
                      ? detailCongToThaoCmis.loaiCongTo
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Hệ số nhân</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.heSoNhan !== null
                      ? detailCongToThaoCmis.heSoNhan
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Nước sản xuất</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.maNuoc !== null
                      ? detailCongToThaoCmis.maNuoc
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Năm sản xuất</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.namSanXuat !== null
                      ? detailCongToThaoCmis.namSanXuat
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Hằng số K</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.hangSoK !== null
                      ? detailCongToThaoCmis.hangSoK
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">BCS</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.bcs !== null
                      ? detailCongToThaoCmis.bcs
                      : null}
                  </h5>
                </Col>

                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Dòng điện</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.dongDien !== null
                      ? detailCongToThaoCmis.dongDien
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Điện áp</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.dienAp !== null
                      ? detailCongToThaoCmis.dienAp
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">
                    Mã chì kiểm định, lập trình
                  </h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.maChiKiemDinh !== null
                      ? detailCongToThaoCmis.maChiKiemDinh
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">
                    SL chì kiểm định, lập trình
                  </h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.slChiKiemDinh !== null
                      ? detailCongToThaoCmis.slChiKiemDinh
                      : null}
                  </h5>
                </Col>

                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Mã tem kiểm định</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.maTemKiemDinh !== null
                      ? detailCongToThaoCmis.maTemKiemDinh
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Ngày kiểm định</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.ngayKiemDinh !== null
                      ? detailCongToThaoCmis.ngayKiemDinh
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">CCX</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.ccx !== null
                      ? detailCongToThaoCmis.ccx
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Số lần cảnh báo</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.soLanCanhBao !== null
                      ? detailCongToThaoCmis.soLanCanhBao
                      : null}
                  </h5>
                </Col>
                {/* <Col span={6}>
                  <h6 className="fs-12px c-t-label">Mã số chì hòm</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.maSoChiHom !== null
                      ? detailCongToThaoCmis.maSoChiHom
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Số viên chì hòm</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.soVienChiHom !== null
                      ? detailCongToThaoCmis.soVienChiHom
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Kìm niêm chì số</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.kimNiemChiSo !== null
                      ? detailCongToThaoCmis.kimNiemChiSo
                      : null}
                  </h5>
                </Col> */}
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">
                    Mã chì quang, hộp đấu dây
                  </h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.maChiQuanHopDauDay !== null
                      ? detailCongToThaoCmis.maChiQuanHopDauDay
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">Số tem cổng quang</h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.soTemCongQuang !== null
                      ? detailCongToThaoCmis.soTemCongQuang
                      : null}
                  </h5>
                </Col>
                <Col span={6}>
                  <h6 className="fs-12px c-t-label">
                    Số viên mã số chì cổng quang
                  </h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.soVienMaSoChiCongQuang !== null
                      ? detailCongToThaoCmis.soVienMaSoChiCongQuang
                      : null}
                  </h5>
                </Col>
                {/* <Col span={6}>
                  <h6 className="fs-12px c-t-label">
                    Tình trạng chì niêm phong
                  </h6>
                  <h5 className="fs-14px font-bold">
                    {Object.keys(detailCongToThaoCmis).length > 0 &&
                    detailCongToThaoCmis.tinhTrangNiemPhong !== null
                      ? detailCongToThaoCmis.tinhTrangNiemPhong
                      : null}
                  </h5>
                </Col> */}
                <Col span={24}>
                  <TableMBD
                    style={{ display: isShowTIThao ? "block" : "none" }}
                  >
                    <TableComponent
                      id={"custom-collapse-container"}
                      pagination={false}
                      scrollY={300}
                      header="Máy biến dòng TI"
                      dataSource={dataTIThao}
                      columns={columnsTiThao}
                    />
                  </TableMBD>
                  <TableMBD
                    style={{ display: isShowTUThao ? "block" : "none" }}
                  >
                    <TableComponent
                      id={"custom-collapse-container"}
                      pagination={false}
                      scrollY={300}
                      header="Máy biến dòng TU"
                      dataSource={dataTUThao}
                      columns={columnsTuThao}
                    />
                  </TableMBD>
                </Col>
              </Row>
            </ContainerBodyPanel>
          </Panel>
        </Collapse>
        <div className="ant-modal-footer text-center">
          <Button type="default" onClick={() => dispatch(closeModal())}>
            {t("button.dong_lai")}
          </Button>
          <Button
            key="submit"
            type="primary"
            className="button-primary"
            htmlType="submit"
            form="form-submit"
          >
            Cập nhật
          </Button>
        </div>
      </FormComponent>
    </LoadingComponent>
  );
}
