import { useCallback } from "react";
import DSCongToModal from "./modal";

// const data = [
//   {
//     maNhanVien: "1",
//     soPhaStr: "1",
//     maKho: "12",
//     ngayBDong: "2019-01-01T00:00:00",
//     tenNVKD: "Nguyễn A",
//     maNVKD: "A",
//     dienAp: "220",
//     maSoTemKiemDinh: "19A",
//     ngayKiemDinh: "15/01/2019",
//     maBDong: "A",
//     maDonViKD: "01",
//     namSanXuatStr: "2017",
//     dongDien: "1(80)",
//     maChungLoai: "581",
//     vhCong: "D1",
//     soHuu: "0",
//     seryTemKiemDinh: "972611",
//     maSoChiKiemDinh: "VN/N30-KĐ2.2/05-28",
//     loaiSoHuu: "0",
//     loaiCongTo: "HHM-11 5(80)A",
//     soVienChiKiemDinh: "1",
//     bcs: "KT;KN",
//     maCongTo: "584201717062184",
//     ngayNhap: "18/05/2020",
//     soBienBanKinhDoanh: "DT224208",
//     soCongTo: "1",
//     maDonViSD: "PD0200",
//     soBienBan: "23.4",
//     heSoNhan: null,
//     tySoBien: "600/111",
//     soPha: 1,
//     namSanXuat: 2017,
//   },
//   {
//     maNhanVien: "2",
//     soPhaStr: "2",
//     maKho: "22",
//     ngayBDong: "2019-01-01T00:00:00",
//     tenNVKD: "Nguyễn A2",
//     maNVKD: "A2",
//     dienAp: "220",
//     maSoTemKiemDinh: "19A",
//     ngayKiemDinh: "15/01/2019",
//     maBDong: "A",
//     maDonViKD: "02",
//     namSanXuatStr: "2017",
//     dongDien: "2(80)",
//     maChungLoai: "581",
//     vhCong: "D2",
//     soHuu: "0",
//     seryTemKiemDinh: "972622",
//     maSoChiKiemDinh: "VN/N30-KĐ2.2/05-28",
//     loaiSoHuu: "0",
//     loaiCongTo: "HHM-11 5(80)BBB, 220-230V",
//     soVienChiKiemDinh: "1",
//     bcs: "KT;BN",
//     maCongTo: "584201717062184",
//     ngayNhap: "18/05/2020",
//     soBienBanKinhDoanh: "DT224208",
//     soCongTo: "1",
//     maDonViSD: "PD0200",
//     soBienBan: "223.4",
//     heSoNhan: null,
//     tySoBien: "600/222",
//     soPha: 2,
//     namSanXuat: 2017,
//   },
//   {
//     maNhanVien: "3",
//     soPhaStr: "3",
//     maKho: "33",
//     ngayBDong: "2019-01-01T00:00:00",
//     tenNVKD: "Nguyễn A3",
//     maNVKD: "A3",
//     dienAp: "220",
//     maSoTemKiemDinh: "19A",
//     ngayKiemDinh: "15/01/2019",
//     maBDong: "A",
//     maDonViKD: "03",
//     namSanXuatStr: "2017",
//     dongDien: "3(80)",
//     maChungLoai: "581",
//     vhCong: "D3",
//     soHuu: "0",
//     seryTemKiemDinh: "972633",
//     maSoChiKiemDinh: "VN/N30-KĐ2.2/05-28",
//     loaiSoHuu: "0",
//     loaiCongTo: "HHM-33 5(80)DDD, 220-230V",
//     soVienChiKiemDinh: "3",
//     bcs: "KT;KN",
//     maCongTo: "584201717062184",
//     ngayNhap: "18/05/2020",
//     soBienBanKinhDoanh: "DT224208",
//     soCongTo: "1",
//     maDonViSD: "PD0200",
//     soBienBan: "333.4",
//     heSoNhan: null,
//     tySoBien: "600/333",
//     soPha: 3,
//     namSanXuat: 2017,
//   },
// ];

export default function Index(props) {
  const {
    loading,
    visible,
    closeModal,
    data,
    handleSearch,
    typeThietBi,
    handleSearchBarCode,
  } = props;

  //chọn data treo
  const handleChoice = useCallback(
    (item) => {
      handleSearchBarCode(item?.maCongTo);
    },
    [handleSearchBarCode]
  );

  return (
    <DSCongToModal
      loading={loading}
      visible={visible}
      closeModal={closeModal}
      data={data}
      handleSearch={handleSearch}
      typeThietBi={typeThietBi}
      handleChoice={handleChoice}
    />
  );
}
