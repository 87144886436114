import CheckPermission from "@components/CheckPermission";
import { Button, Modal, notification } from "antd";
import { useState } from "react";
import { apiChotBaoCao } from "../services";

export default function Index(props) {
  const {
    endpoint,
    permissionCode,
    text,
    payload,
    getListBaoCao,
    setSelectedRowKeys,
    disabled,
    message,
    normal,
  } = props;
  const [loading, setLoading] = useState(false);

  //call api chốt báo cáo
  const handleChotBaoCao = () => {
    payload && payload.ids && payload.ids.length > 0
      ? Modal.confirm({
          centered: true,
          title: "Xác nhận",
          content: "Bạn chắc chắn muốn duyệt báo cáo?",
          onOk() {
            apiChotBaoCao(
              endpoint,
              setLoading,
              payload,
              getListBaoCao,
              setSelectedRowKeys,
              normal
            );
          },
          onCancel() {},
          okText: "Đồng ý",
          okButtonProps: { type: "danger" },
          cancelText: "Hủy",
        })
      : normal
      ? Modal.confirm({
          centered: true,
          title: "Xác nhận",
          content: message,
          onOk() {
            apiChotBaoCao(
              endpoint,
              setLoading,
              payload,
              getListBaoCao,
              setSelectedRowKeys,
              normal
            );
          },
          onCancel() {},
          okText: "Đồng ý",
          okButtonProps: { type: "danger" },
          cancelText: "Hủy",
        })
      : notification.warning({
          message: `Thông báo`,
          description: "Bạn hãy chọn dữ liệu muốn chốt duyệt báo cáo",
          duration: 3,
        });
  };

  return (
    <CheckPermission permissionCode={permissionCode}>
      <Button
        type="primary"
        className="mr-24px"
        key="chot-bao-cao"
        onClick={handleChotBaoCao}
        loading={loading}
        disabled={disabled || payload.ids?.length === 0}
      >
        {text ? text : "Chốt báo cáo"}
      </Button>
    </CheckPermission>
  );
}
