import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Collapse } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  openFilters,
  closeFilters,
  filterCapDien,
} from "@state/cap-dien/system-filter/reducer";
import { KEY_PANEL1, KEY_PANEL0 } from "@utils/constants";

const { Panel } = Collapse;

export default function Filter(props) {
  const dispatch = useDispatch();
  const { openFilter } = useSelector(filterCapDien);
  const [defaultKey, setDefaultKey] = useState([]);

  const callback = (key) => {
    if (key.length === 0) dispatch(closeFilters());
    else dispatch(openFilters());
  };

  useEffect(() => {
    if (openFilter) setDefaultKey([KEY_PANEL1]);
    else setDefaultKey([KEY_PANEL0]);
  }, [openFilter]);

  return (
    <CollapseContainer
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <FilterText className={isActive ? "active" : ""}>
          <span style={{ fontSize: "14px" }}>Bộ lọc</span>
          <FilterOutlined style={{ fontSize: 16 }} />
        </FilterText>
      )}
      onChange={callback}
      defaultActiveKey={defaultKey}
      activeKey={defaultKey}
    >
      <Panel key={KEY_PANEL1}>{props.children}</Panel>
    </CollapseContainer>
    // color: #279bef;
  );
}
const CollapseContainer = styled(Collapse)`
  width: 100%;
  background: #fff;
  border: none;
  border-radius: unset;
  .ant-collapse-header {
    min-height: 28px;
  }
  .active {
    color: #405cab;
  }
  .ant-collapse-content-box {
    padding: 15px 30px;
  }
  .filter-action {
    display: flex;
    justify-content: end;
    align-items: end;
  }
`;
const FilterText = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  > span {
    margin-right: 10px;
  }
`;
