import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { handlePagination } from "@utils/function";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  exportData,
  handleDownload,
  selectBcthsms,
  selectTenCuPhap,
} from "./service";

export default function TinNhanMoMT() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);
  const [data, setData] = useState([]);
  const [baocao, setBaocao] = useState();
  const [columns, setColumns] = useState();

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });
  //pagination table
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  useEffect(() => {
    if (baocao === Endpoint.BCTHSMS_DS_TIN_NHAN_THANH_CONG_THEO_NGAY) {
      setColumns(columnsBieu3);
    } else if (
      baocao === Endpoint.BCTHSMS_DS_TIN_NHAN_MO_THAY_DOI_SO_DIEN_THOAI
    ) {
      setColumns(columnsBieu4);
    } else if (baocao === Endpoint.BCTHSMS_DS_TIN_NHAN_KHONG_THANH_CONG) {
      setColumns(columnsBieu5);
    } else if (baocao === Endpoint.BCTHSMS_CT_TIN_NHAN_SAI_CU_PHAP) {
      setColumns(columnsBieu6);
    } else {
      setColumns([]);
    }
  }, [baocao]);

  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };

  const columnsBieu3 = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      align: "center",
    },
    {
      title: "Thời gian",
      dataIndex: "NGAY",
      key: "NGAY",
      align: "center",
    },
    {
      title: "Số lượng tin nhắn",
      dataIndex: "SL",
      key: "SL",
      align: "center",
    },
  ];

  const columnsBieu4 = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      width: "5%",
      align: "center",
    },
    {
      title: "Số điện thoại",
      dataIndex: "MSISDN",
      key: "MSISDN",
      align: "center",
    },
    {
      title: "Cú pháp",
      dataIndex: "CP",
      key: "CP",
      align: "center",
    },
    {
      title: "Thời gian gủi MO",
      dataIndex: "MO_TIME",
      key: "MO_TIME",
      align: "center",
    },
    {
      title: "Nội dung",
      dataIndex: "ND",
      key: "ND",
      align: "center",
    },
    {
      title: "Thời gian nhận MT",
      dataIndex: "MT_TIME",
      key: "MT_TIME",
      align: "center",
    },
  ];

  const columnsBieu5 = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      width: "10%",
      align: "center",
    },
    {
      title: "Số điện thoại",
      dataIndex: "MSISDN",
      key: "MSISDN",
      align: "center",
    },
    {
      title: "Cú pháp",
      dataIndex: "MSGBODY",
      key: "MSGBODY",
      align: "center",
    },
  ];

  const columnsBieu6 = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      align: "center",
      width: "5%",
    },
    {
      title: "Số điện thoại",
      dataIndex: "MSISDN",
      key: "MSISDN",
      align: "center",
    },
    {
      title: "Cú pháp",
      dataIndex: "CP",
      key: "CP",
      align: "center",
    },
    {
      title: "Thời gian gửi MO",
      dataIndex: "MO_TIME",
      key: "MO_TIME",
      align: "center",
    },
    {
      title: "Nội dung",
      dataIndex: "ND",
      key: "ND",
      align: "center",
    },
    {
      title: "Thời gian nhận MT",
      dataIndex: "MT_TIME",
      key: "MT_TIME",
      align: "center",
    },
  ];

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Tin nhắn mo_mt
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              initialValues={{
                reportType: Endpoint.BCTHSMS_THSL_TIN_NHAN_THEO_TUNG_DICH_VU,
                unitId: user.user.unitCode,
                TenCuPhap: "GCS",
              }}
              colon={false}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
            >
              <Form.Item
                name="reportType"
                label="Loại báo cáo"
                labelAlign="left"
                required
              >
                <Select
                  options={selectBcthsms}
                  onChange={() => {
                    setBaocao(form.getFieldValue("reportType"));
                    setData([]);
                  }}
                ></Select>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") ===
                  Endpoint.BCTHSMS_CT_TIN_NHAN_THEO_CU_PHAP ? (
                    <Form.Item
                      name="TenCuPhap"
                      label="Tên cú pháp"
                      labelAlign="left"
                      required
                    >
                      <Select
                        options={selectTenCuPhap}
                        formKey="TenCuPhap"
                        form={form}
                        placeholder="Chọn tên cú pháp"
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              <Form.Item
                name="TuNgay"
                label="Ngày bắt đầu"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="Day"
                  formKey="TuNgay"
                  form={form}
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>

              <Form.Item
                name="DenNgay"
                label="Ngày kết thúc"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="Day"
                  formKey="DenNgay"
                  form={form}
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>

              <Row>
                <Col span={4}></Col>
                <Col>
                  {baocao ===
                    Endpoint.BCTHSMS_DS_TIN_NHAN_THANH_CONG_THEO_NGAY ||
                  baocao ===
                    Endpoint.BCTHSMS_DS_TIN_NHAN_MO_THAY_DOI_SO_DIEN_THOAI ||
                  baocao === Endpoint.BCTHSMS_DS_TIN_NHAN_KHONG_THANH_CONG ||
                  baocao === Endpoint.BCTHSMS_CT_TIN_NHAN_SAI_CU_PHAP ? (
                    <Button
                      type="primary"
                      loading={loading}
                      className="mr-20px"
                      onClick={() => {
                        exportData(form.getFieldValue(), setLoading, setData);
                      }}
                    >
                      Xem bảng kê
                    </Button>
                  ) : null}
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>

                  {/* <Button
                    type="primary"
                    loading={loading}
                    className="ml-20px"
                    onClick={() => {
                      handleOpenPdf(form.getFieldValue(), setLoading);
                    }}
                  >
                    In báo cáo
                  </Button> */}
                </Col>
              </Row>
            </FormComponent>
            <Divider className="section-text-line" orientation="left"></Divider>
            {baocao === Endpoint.BCTHSMS_DS_TIN_NHAN_THANH_CONG_THEO_NGAY ||
            baocao === Endpoint.BCTHSMS_DS_TIN_NHAN_MO_THAY_DOI_SO_DIEN_THOAI ||
            baocao === Endpoint.BCTHSMS_DS_TIN_NHAN_KHONG_THANH_CONG ||
            baocao === Endpoint.BCTHSMS_CT_TIN_NHAN_SAI_CU_PHAP ? (
              <TableComponent
                header={"Tổng số lượng theo ngày"}
                columns={columns}
                dataSource={data}
                pagination={filterConditions}
                onChangePagination={onChangePagination}
              />
            ) : null}
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
