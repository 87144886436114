import React, {
  Fragment,
  useState,
  useLayoutEffect,
  useCallback,
  useEffect,
} from "react";
import styled from "styled-components";
import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";

import ModalCreatePermission from "./modal-create-permission";
import { useDispatch } from "react-redux";
import { openModal } from "@state/system-config/reducer";
import { useSearchParams, useLocation } from "react-router-dom";
import { authGetData, authDeleteData } from "@utils/request";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import { ListFilter } from "./list-bo-loc";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  buildQueryString,
  parseParams,
  removeUndefinedAttribute,
  customColumn,
  handlePagination,
} from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import UserAssignedTable from "./table-user-assigned";
import ButtonComponent from "@components/Button";
import { permission } from "@permissions/k_qtht";
import CheckPermission from "@components/CheckPermission";
import { Tooltip } from "antd";
import moment from "moment";
import { FORMAT_DATE } from "@utils/constants";
import { EyeOutlined } from "@ant-design/icons";
export default function QuanLyPhanQuyen() {
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const getRoleList = useCallback(() => {
    authGetData({
      url: `${Endpoint.ROLE}?${buildQueryString(parseParams(location.search))}`,
      setLoading,
      onSuccess: (res) => {
        setDataSource(res.data);
        setTotalCount(res.paging.totalCount);
      },
    });
  }, [location.search]);
  useEffect(() => {
    getRoleList();
  }, [getRoleList]);

  const genExtra = useCallback(
    () => (
      <CheckPermission permissionCode={permission.k1_tao}>
        <ButtonComponent
          size="small"
          type="primary"
          onClick={() =>
            dispatch(
              openModal({
                title: "Tạo mới quyền",
                content: <ModalCreatePermission refresh={getRoleList} />,
                size: "large",
              })
            )
          }
        >
          Tạo mới
        </ButtonComponent>
      </CheckPermission>
    ),
    [dispatch, getRoleList]
  );
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };
  const handleDeleteRole = useCallback(
    (data) => {
      authDeleteData({
        url: `${Endpoint.ROLE}/${data.id}`,
        setLoading,
        content: `Quyền này đang được gán cho ${data.userRolesCount} người dùng, bạn có chắc chắn xóa quyền này?`,
        onSuccess: () => {
          getRoleList();
        },
      });
    },
    [getRoleList]
  );

  const columns = [
    {
      title: "Tên quyền",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "Diễn giải",
      dataIndex: "description",
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      className: "text-center",
      sorter: true,
      render: (createdDate) => (
        <span>
          {createdDate ? moment(createdDate).format(FORMAT_DATE) : null}
        </span>
      ),
    },
    {
      title: "Tác vụ",
      render: (row) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={permission.k1_view}>
            <Tooltip title={"Xem"}>
              <ButtonComponent
                type="link"
                onClick={() =>
                  dispatch(
                    openModal({
                      title: "Xem chi tiết quyền",
                      content: (
                        <ModalCreatePermission
                          refresh={getRoleList}
                          roleId={row.id}
                          isView
                        />
                      ),
                      size: "large",
                    })
                  )
                }
                className="pd-5px"
              >
                <EyeOutlined size={30} style={{fontSize: 18}}/>
              </ButtonComponent>
            </Tooltip>
          </CheckPermission>
          <CheckPermission permissionCode={permission.k1_sua}>
            <Tooltip title={"Sửa"}>
              <ButtonComponent
                type="link"
                onClick={() =>
                  dispatch(
                    openModal({
                      title: "Chỉnh sửa quyền",
                      content: (
                        <ModalCreatePermission
                          refresh={getRoleList}
                          roleId={row.id}
                        />
                      ),
                      size: "large",
                    })
                  )
                }
                className="pd-5px"
              >
                <PencilIcon />
              </ButtonComponent>
            </Tooltip>
          </CheckPermission>
          <CheckPermission permissionCode={permission.k1_xoa}>
            <Tooltip title={"Xóa"}>
              <ButtonComponent
                type="link"
                onClick={() => handleDeleteRole(row)}
                className="pd-5px"
              >
                <TrashIcon />
              </ButtonComponent>
            </Tooltip>
          </CheckPermission>
        </div>
      ),
    },
  ];
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      ...values,
    }));
  }, []);
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);
  const rowSelection = {
    onChange: (selectedRowKey) => {
      setSelectedRowKey(selectedRowKey);
    },
  };
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
        />
      </Filter>
      <Container>
        <Wrapper>
          <TableComponent
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            header={"Danh sách quyền"}
            renderExtraAction={() => genExtra()}
            dataSource={dataSource}
            columns={customColumn(columns, filterConditions)}
            totalData={totalCount}
            loading={loading}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
          />
        </Wrapper>
      </Container>
      <UserAssignedTable roleId={selectedRowKey} />
    </Fragment>
  );
}
const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
`;

const Wrapper = styled.div`
  .ant-collapse {
    margin-bottom: 15px;
  }
`;
