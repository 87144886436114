import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  alertMessage,
  buildQueryString,
  getErrorMessage,
} from "@utils/function";
import {
  authDeleteData,
  authGetData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";

//get list data

export const handleListData = (
  setLoading,
  filterConditions,
  setDataChungLoaiCongTo,
  setTotalCount,
  parseParams
) => {
  authGetData({
    url: `${Endpoint.LIST_CHUNG_LOAI_CONG_TO}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataChungLoaiCongTo(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataChungLoaiCongTo([]);
    },
  });
};

//get data

export const handleGetDataDetail = (setLoading, id, setDataDetail) => {
  authGetData({
    url: `${Endpoint.GET_CHUNG_LOAI_CONG_TO + "?Id=" + id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDetail(res.data);
      } else setDataDetail([]);
    },
  });
};

//get multilelist data

export const handleMultileListData = (setLoading, setDataSoPha) => {
  const endpoints = [`${Endpoint.COMMON_LIST_SO_PHA + "?PageSize=-1"}`];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      if (res[0].data.data !== null) setDataSoPha(res[0].data.data);
      else setDataSoPha([]);
    },
  });
};

//create data

const handleCreateData = (
  data,
  setLoading,
  closeModal,
  getListChungLoaiCongTo,
  form
) => {
  authPostData({
    url: Endpoint.CREATE_CHUNG_LOAI_CONG_TO,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        closeModal();
        getListChungLoaiCongTo();
      } else getErrorMessage(res, form);
    },
  });
};

//update data

const handleUpdateData = (
  data,
  setLoading,
  closeModal,
  getListChungLoaiCongTo,
  form
) => {
  authPostData({
    url: Endpoint.UPDATE_CHUNG_LOAI_CONG_TO,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        closeModal();
        getListChungLoaiCongTo();
      } else getErrorMessage(res, form);
    },
  });
};

//remove data

export const handleRemoveData = (
  ids,
  setLoading,
  getListChungLoaiCongTo,
  t
) => {
  authDeleteData({
    url: Endpoint.REMOVE_CHUNG_LOAI_CONG_TO + "?Ids=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data)
        getListChungLoaiCongTo();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

//submit form

export const handleSubmitForm = (
  values,
  setLoading,
  closeModal,
  getListData,
  form
  // dispatch
) => {
  const data = {
    ...values,
  };

  if (values.id) {
    data.id = values.id;
  }
  handleCreateUpdate(data, setLoading, closeModal, getListData, form);
};

// create/update dữ liệu

const handleCreateUpdate = (
  data,
  setLoading,
  closeModal,
  getListData,
  form
) => {
  if (data.id) {
    //sửa dữ liệu

    handleUpdateData(data, setLoading, closeModal, getListData, form);
  } else {
    //tạo dữ liệu

    handleCreateData(data, setLoading, closeModal, getListData, form);
  }
};
