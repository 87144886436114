import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import TableComponent from "@components/TableComponent";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import { handlePagination } from "@utils/function";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import moment from "moment";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { exportData, handleDownload, selectBCMOMT } from "./service";

export default function Bieu5(props) {
  const { screen, handleChange } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });

  const onFinish = (values) => {
    exportData(values, setLoading, setData);
  };
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      width: "10%",
    },
    {
      title: "Số điện thoại",
      dataIndex: "MSISDN",
      key: "MSISDN",
      width: "40%",
    },
    {
      title: "Cú pháp",
      dataIndex: "MSGBODY",
      key: "MSGBODY",
      width: "50%",
    },
  ];

  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              initialValues={{ reportType: screen }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="reportType"
                    label="Tên báo cáo"
                    labelAlign="left"
                  >
                    <Select
                      options={selectBCMOMT}
                      formKey="reportType"
                      form={form}
                      onChange={() =>
                        handleChange(form.getFieldValue("reportType"))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    name="TuNgay"
                    label="Ngày bắt đầu"
                    labelAlign="left"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <DatePickerComponent
                      picker="Day"
                      formKey="TuNgay"
                      defaultValue={moment().startOf("month")}
                      form={form}
                      format={"YYYY-MM-DD"}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="DenNgay"
                    label="Ngày kết thức"
                    labelAlign="right"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <DatePickerComponent
                      picker="Day"
                      formKey="DenNgay"
                      form={form}
                      format={"YYYY-MM-DD"}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="end">
                <Button type="primary" htmlType="submit" className="mr-5px">
                  Xem báo cáo
                </Button>

                <Button
                  type="primary"
                  onClick={() => {
                    handleDownload(form.getFieldValue(), setLoading);
                  }}
                >
                  Xuất Excel
                </Button>
              </Row>
            </FormComponent>
            <TableComponent
              header={"Kết quả hiển thị"}
              columns={columns}
              dataSource={data}
              pagination={filterConditions}
              onChangePagination={onChangePagination}
            />
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
