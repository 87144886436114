import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE, OPTION_CHENH_LECH_BAO_CAO_PHAN_HE_HOA_DON } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { handlePagination } from "@utils/function";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { exportData, handleDownload, selectBCHD } from "./service";

export default function BaoCaoHoaDon() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);
  const [baocao, setBaocao] = useState(Endpoint.BCKD_TK_KH_CO_SL_TU_1_3);
  const [data, setData] = useState([]);

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });

  //pagination table
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };
  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };

  const columns = [
    {
      title: "Mã đơn vị",
      dataIndex: "MA_DVIQLY",
      key: "MA_DVIQLY",
      align: "center",
    },
    {
      title: "Mã hóa đơn",
      dataIndex: "ID_HDON",
      key: "ID_HDON",
      align: "center",
    },
    {
      title: "Mã khách hàng",
      dataIndex: "MA_KHANG",
      key: "MA_KHANG",
      align: "center",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "TEN_KHANG",
      key: "TEN_KHANG",
      align: "center",
    },
    {
      title: "Địa chỉ",
      dataIndex: "DCHI_KHANG",
      key: "DCHI_KHANG",
      align: "center",
    },
    {
      title: "Mã số GCS",
      dataIndex: "MA_SOGCS",
      key: "MA_SOGCS",
      align: "center",
    },
    {
      title: "Kỳ",
      dataIndex: "KY",
      key: "KY",
      width: "5%",
      align: "center",
    },
    {
      title: "Tháng",
      dataIndex: "THANG",
      key: "THANG",
      width: "6%",
      align: "center",
    },
    {
      title: "Năm",
      dataIndex: "NAM",
      key: "NAM",
      width: "5%",
      align: "center",
    },
    {
      title: "Điện tiêu thụ",
      dataIndex: "DIEN_TTHU",
      key: "DIEN_TTHU",
      align: "center",
    },
    {
      title: "Số tiền",
      dataIndex: "SO_TIEN",
      key: "SO_TIEN",
      align: "center",
    },
    {
      title: "Tiền giá trị gia tăng",
      dataIndex: "TIEN_GTGT",
      key: "TIEN_GTGT",
      align: "center",
    },
    {
      title: "Loại hóa đơn",
      dataIndex: "LOAI_HDON",
      key: "LOAI_HDON",
      align: "center",
    },
    {
      title: "Loại điều chỉnh",
      dataIndex: "LOAIDIEUCHINH",
      key: "LOAIDIEUCHINH",
      align: "center",
    },
    {
      title: "Lý do sai",
      dataIndex: "LYDOSAI",
      key: "LYDOSAI",
      align: "center",
    },
  ];

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Báo cáo phân hệ hóa đơn
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              initialValues={{
                reportType: Endpoint.BCKD_TK_KH_CO_SL_TU_1_3,
                unitId: user.user.unitCode,
                chenhLech: OPTION_CHENH_LECH_BAO_CAO_PHAN_HE_HOA_DON.DUOI_1_3.value,
              }}
              colon={false}
            >
              <Form.Item name="reportType" label="Báo cáo" labelAlign="left" required>
                <Select
                  placeholder="Tất cả"
                  options={selectBCHD}
                  formKey="reportType"
                  form={form}
                  onChange={() => setBaocao(form.getFieldValue("reportType"))}
                />
              </Form.Item>

              <Form.Item name="unitId" label="Tên đơn vị" labelAlign="left" required>
                <Selection url={Endpoint.GET_TEAM_LIST_CODE} formKey="unitId" form={form} />
              </Form.Item>

              {baocao !== Endpoint.BCKD_TK_KH_CO_SL_TU_1_3 ? (
                <Form.Item name="reportDate" label="Tháng / Năm" labelAlign="left" required>
                  <DatePickerComponent picker="month" formKey="reportDate" form={form} />
                </Form.Item>
              ) : (
                <Form.Item name="reportDate" label="Tháng báo cáo" labelAlign="left" required>
                  <DatePickerComponent picker="month" formKey="reportDate" form={form} />
                </Form.Item>
              )}
              {baocao === Endpoint.BCKD_CT_KH_CO_SL_TU_1_3 && (
                <Form.Item name="ChenhLech" label="Chênh lệch" labelAlign="left" required>
                  <Select
                    formKey="ChenhLech"
                    form={form}
                    options={Object.keys(OPTION_CHENH_LECH_BAO_CAO_PHAN_HE_HOA_DON).map(
                      (key) => OPTION_CHENH_LECH_BAO_CAO_PHAN_HE_HOA_DON[key]
                    )}
                  />
                </Form.Item>
              )}

              <Row>
                <Col span={4}> </Col>
                <Col>
                  {baocao === Endpoint.BCHD_TRA_CUU_HD_THOAI_HOAN ? (
                    <Button
                      type="primary"
                      loading={loading}
                      className="mr-20px"
                      onClick={() => {
                        exportData(form.getFieldValue(), setLoading, setData);
                      }}
                    >
                      Xem bảng kê
                    </Button>
                  ) : null}

                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>
                </Col>
              </Row>
            </FormComponent>
            <Divider className="section-text-line" orientation="left"></Divider>
            {baocao === Endpoint.BCHD_TRA_CUU_HD_THOAI_HOAN ? (
              <TableComponent
                header="Thông tin hiển thị"
                columns={columns}
                dataSource={data}
                scrollX={1300}
                pagination={filterConditions}
                onChangePagination={onChangePagination}
              />
            ) : null}
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
