import React, { useEffect, useRef } from "react";
import LoadingComponent from "@components/Loading";
import { Button, Collapse, Row } from "antd";
import FormComponent from "@components/Form";
import { TYPE_PHIEUTRA_CHUCNANG } from "@utils/constants";
import ThongTinChung from "./panels/thong-tin-chung";
import DSVatTuThuHoiTable from "./panels/danh-sach-vt-thu-hoi";
import DSYCTable from "./panels/danh-sach-yc-vt-thu-hoi";
import DSVatTuTraLaiTable from "./panels/danh-sach-vt-tra-lai";
import { useDispatch } from "react-redux";
import { closeModal } from "@state/system-config/reducer";
import { scrollToTop } from "@utils/function";

const { Panel } = Collapse;

export default function LayoutPhieuTra(props) {
  const {
    loading,
    form,
    onFinishSubmit,
    type,
    dataTH,
    columnsTH,
    dataSelectDSYCLQ,
    onSearch,
    dataYCVTTH,
    columnsYCVTTH,
    handleCreateLQ,
    nguoiTao,
    dataDetail,
    disabled,
    valueSearch,
    tenLoaiTT,
    ngayTao,
    tenDoi,
    dataLoaiTT,
    dataDoi,
    changeValueDoi,
    valueDoi,
    firstDoi,
    firstLoaiTT,
    isChanged,
    loaiCongTo,
    dataSourceDSVTTL,
    setDataSourceDSVTTL,
    lyDo,
    isTraLai,
    selectRowKeys,
  } = props;
  const dispatch = useDispatch();
  const ref1 = useRef();

  useEffect(() => {
    scrollToTop(ref1);
  }, []);

  return (
    <LoadingComponent loading={loading}>
      <div ref={ref1}>
        <FormComponent
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onFinishSubmit}
          autoComplete="off"
        >
          <Collapse
            defaultActiveKey={
              tenLoaiTT && tenDoi ? ["1", "2", "3", "4"] : ["1", "3"]
            }
          >
            <Panel header="Thông tin chung" key="1">
              <ThongTinChung
                form={form}
                nguoiTao={nguoiTao}
                tenLoaiTT={tenLoaiTT}
                ngayTao={ngayTao}
                tenDoi={tenDoi}
                dataDetail={dataDetail}
                dataLoaiTT={dataLoaiTT}
                dataDoi={dataDoi}
                changeValueDoi={changeValueDoi}
                valueDoi={valueDoi}
                firstDoi={firstDoi}
                firstLoaiTT={firstLoaiTT}
                isChanged={isChanged}
                lyDo={lyDo}
                isTraLai={isTraLai}
                type={type}
              />
            </Panel>
            <Panel header="Danh sách vật tư thu hồi" key="2">
              <DSVatTuThuHoiTable dataTH={dataTH} columnsTH={columnsTH} />
            </Panel>
            <Panel header="Danh sách vật tư trả lại" key="3">
              <DSVatTuTraLaiTable
                loaiCongTo={loaiCongTo}
                dataSourceDSVTTL={dataSourceDSVTTL}
                setDataSourceDSVTTL={setDataSourceDSVTTL}
                selectRowKeys={selectRowKeys}
                form={form}
              />
            </Panel>
            <Panel header="Danh sách yêu cầu có vật tư thu hồi" key="4">
              <DSYCTable
                onSearch={onSearch}
                dataYCVTTH={dataYCVTTH}
                columns={columnsYCVTTH}
                dataSelectDSYCLQ={dataSelectDSYCLQ}
                handleCreateLQ={handleCreateLQ}
                disabled={disabled}
                valueSearch={valueSearch}
              />
            </Panel>
          </Collapse>
          <Row gutter={24} justify="center" style={{ marginTop: "48px" }}>
            <Button
              key="back"
              onClick={() => dispatch(closeModal())}
              className="button-closed mr-5px"
            >
              Đóng lại
            </Button>
            <Button
              type="primary"
              key="submit"
              className="button-closed mr-5px"
              loading={loading}
              htmlType="submit"
            >
              {type === TYPE_PHIEUTRA_CHUCNANG.THAYDOI ? "Cập nhật" : "Tạo mới"}
            </Button>
          </Row>
        </FormComponent>
      </div>
    </LoadingComponent>
  );
}
