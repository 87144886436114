import CheckPermission from "@components/CheckPermission";
import { treo_thao } from "@permissions/b_treothao";
import {
  ENUM_DS_YEU_CAU,
  ENUM_LOAI_PHIEU,
  FUNC_ID_DSYC_TREOTHAO,
  TYPE_PHIEUTRA_CHUCNANG,
} from "@utils/constants";
import { Button } from "antd";
import { Fragment, useState } from "react";
import DanhSachYeuCau from "../../common-ver1/danh-sach-yeu-cau";
import { useSelector, useDispatch } from "react-redux";
import { authSelector } from "@state/auth";
import { openModal } from "@state/system-config/reducer";
import { alertMessage } from "@utils/function";
import TaoSuaPhieuTra from "../../common-ver1/thao-tac-phieu-tra/tao-sua-phieu";

export default function Index() {
  const localFilter = localStorage.getItem("createFilterPhieu");
  if (localFilter === "undefined" || localFilter === "null") {
    localStorage.removeItem("createFilterPhieu");
  }
  const { user } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [fetchList, setFetchList] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [arrSelectedRows, setArrSelectedRows] = useState([]);
  const [isTongCongTy, setIsTongCongTy] = useState(
    user.isTongCongTy ? true : false
  );

  //button
  const genExtra = (filterConditions) => (
    <CheckPermission permissionCode={treo_thao.b22t}>
      <Button
        type="primary"
        className="mr-5px"
        onClick={() => handleOpenTaoPhieu(filterConditions)}
        disabled={isTongCongTy ? true : false}
      >
        Tạo phiếu trả vật tư
      </Button>
    </CheckPermission>
  );

  //trùng value trong arr
  const findInArray = (value, arr) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== value) return false;
    }
    return true;
  };

  //open modal tạo phiếu
  const handleOpenTaoPhieu = (filterConditions) => {
    let isDoi = false;
    const arrDoi = [];
    const arrLoaiTreoThao = [];
    // let isToDoi = false;
    arrSelectedRows.map((item) => {
      arrDoi.push(item.doiId);
      arrLoaiTreoThao.push(item.loaiTreoThao);
      if (item.doiId === "" || item.doiId === undefined || item.doiId === null)
        isDoi = true;
      return false;
    });
    const isDuplicateDoi = findInArray(arrDoi[0], arrDoi);
    const isDuplicateTT = findInArray(arrLoaiTreoThao[0], arrLoaiTreoThao);
    if (isDoi) {
      alertMessage(
        "warning",
        "Thông báo",
        "Có yêu cầu chưa xác định Đội. Hãy xác định Đội trước khi tạo phiếu trả vật tư!"
      );
    } else {
      if (!isDuplicateDoi) {
        alertMessage(
          "warning",
          "Thông báo",
          "Chỉ cho phép tạo phiếu trả vật tư của các yêu cầu có cùng tổ đội!"
        );
      } else if (!isDuplicateTT) {
        alertMessage(
          "warning",
          "Thông báo",
          "Chỉ cho phép tạo phiếu trả vật tư của các yêu cầu có cùng loại treo tháo!"
        );
      } else {
        return dispatch(
          openModal({
            content: (
              <TaoSuaPhieuTra
                data={arrSelectedRows}
                type={TYPE_PHIEUTRA_CHUCNANG.TAOMOI}
                selectRowKeys={selectedRowKeys}
                typeLoaiPhieu={ENUM_LOAI_PHIEU.PHIEU_TRAVT}
                typeTreoThao={arrSelectedRows[0]?.loaiTreoThao}
                typeYeuCau={arrSelectedRows[0]?.loaiYeuCau}
                dispatch={dispatch}
                nguoiTao={user.name}
                doiId={arrSelectedRows[0]?.doiId}
                setFetchList={setFetchList}
                filterConditions={filterConditions}
                funcId={FUNC_ID_DSYC_TREOTHAO.DOITRUONG_CANTRAVT}
              />
            ),
            size: "large",
            title: "Tạo mới phiếu trả vật tư",
          })
        );
      }
    }
  };

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    //Lấy giá trị key đã chọn
    setSelectedRowKeys(selectedRowKeys);
    //Lấy giá trị các dòng được chọn
    setArrSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  return (
    <Fragment>
      <DanhSachYeuCau
        funcId={FUNC_ID_DSYC_TREOTHAO.DOITRUONG_CANTRAVT}
        typeList={ENUM_DS_YEU_CAU.DOITRUONG_YEUCAUCANTRAVATTU}
        genExtra={genExtra}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        rowSelection={rowSelection}
        setIsTongCongTy={setIsTongCongTy}
        localFilter={localFilter ? JSON.parse(localFilter) : null}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    </Fragment>
  );
}
