import TableComponent from "@components/TableComponent";
import { Container } from "./css-styled";

export default function TableCauHinh(props) {
  const { data, loading, columns } = props;

  return (
    <Container>
      <TableComponent
        header={"Bảng Cấu hình gửi mail"}
        // renderExtraAction={false}
        dataSource={data}
        columns={columns}
        // totalData={totalCount}
        loading={loading}
        pagination={false}
        scrollY={600}
      />
    </Container>
  );
}
