import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useEffect,
  useMemo
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import Filter from "../../common/Filter";
import TableComponent from "@components/TableComponent";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilterCauHinhKyBK } from "./list-bo-loc";
import { Container } from "./css-styled";
import { handleListDataCauHinhNV, submitThemMoiCauHinhNV, submitXoaCauHinhNV, submitUpdateCauHinhNV, handleListDataDsSoGCS } from "./services";
import CheckPermission from "@components/CheckPermission";
import { ghi_chi_so } from "@permissions/d_gcs";
import { Form, Button, } from "antd";
import CauHinhNVModal from "./modal-cau-hinh-nv";
import ButtonComponent from "@components/Button";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";
import { removeAccents } from "@utils/function";

export default function Index() {
  const { t } = useTranslation();
  const { user } = useSelector(authSelector)
  const [form] = Form.useForm();
  const [dataCauHinhNV, setDataCauHinhNV] = useState([]);
  const [dataDsSoGCS, setDataDsSoGCS] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [, setSoGCS] = useState();
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [editingKey, setEditingKey] = useState('');
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    DonViId: user.unitId,
    orderBy: "maSo"
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu
  const getListDataCauHinhKBK = useCallback(() => {
    handleListDataCauHinhNV(
      setLoading,
      location,
      setDataCauHinhNV,
      setTotalCount,
      filterConditions
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    handleListDataDsSoGCS(setLoading, setDataDsSoGCS);
  }, []);

  useEffect(() => {
    getListDataCauHinhKBK();
  }, [getListDataCauHinhKBK]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListDataCauHinhKBK();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListDataCauHinhKBK, setFetchDataTable]);
  //pagination table
  const onChangePagination = (paging, _filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //tìm kiếm dữ liệu
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      orderBy: "maSo"
    });
  }, [user.unitId]);
  const handleChangeSo = (value) => {
    setSoGCS(value)
  };
  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      tenDoi: '',
      tenNhanVien: '',
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (record) => {
    const row = await form.validateFields();
    submitUpdateCauHinhNV(record, row, setLoading, setFetchDataTable, setEditingKey)
  };

  const EditableCell = ({
    editing,
    dataIndex,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing && dataIndex === "tenDoi" ? (
          <Form.Item
            name="toDoiId"
            style={{
              margin: 0,
            }}
            rules={[
              { required: true, message: "Đội không được để trống" },
            ]}
          >
            <Selection
              url={`${Endpoint.GET_TEAM_LIST}?donviId=${user.unitId}`}
              formKey="toDoiId"
              form={form}
            />
          </Form.Item>
        ) : editing && dataIndex === "tenNhanVien" ?
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.toDoiId !== currentValues.toDoiId) {
                form.setFieldsValue({
                  tenNhanVien: undefined,
                });
              }
              return prevValues.tenDoi !== currentValues.tenDoi;
            }}
            rules={[
              { required: true, message: "Nhân viên không được để trống" },
            ]}
          >
            {({ getFieldValue }) => {
              if (getFieldValue("toDoiId")) {
                return (
                  <Form.Item name="tenNhanVien" style={{ margin: 0 }}
                    rules={[
                      { required: true, message: "Nhân viên không được để trống" },
                    ]}
                    showSearch
                    filterOption={(input, option) => {
                      if (option && option.children) {
                        return (
                          removeAccents(option.children)
                            .toLowerCase()
                            .indexOf(removeAccents(input).toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    <Selection
                      url={`${Endpoint.NHAN_VIEN_THEO_DOI}?doiId=${getFieldValue(
                        "toDoiId"
                      )}`}
                      formKey="tenNhanVien"
                      form={form}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="tenNhanVien" style={{ margin: 0 }}
                  rules={[
                    { required: true, message: "Nhân viên không được để trống" },
                  ]}
                  showSearch
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  <Selection
                    url={`${Endpoint.NHAN_VIEN_THEO_DOI}?doiId=${getFieldValue(
                      "toDoiId"
                    )}`}
                    formKey="tenNhanVien"
                    form={form}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          : (
            children
          )}
      </td>
    );
  };

  const columns = [
    {
      title: "Đơn vị",
      dataIndex: "tenDonVi",
      key: "tenDonVi",
      render: (text) => (
        <div>
          <span>{text}</span>
        </div>
      ),
      width: "20%",
      sorter: true,
    },
    {
      title: "Mã sổ",
      dataIndex: "maSo",
      key: "maSo",
      render: (text) => (
        <div>
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Tên sổ",
      dataIndex: "tenSo",
      key: "tenSo",
      render: (text) => (
        <span>{text}</span>
      ),
      width: "15%",
      sorter: true,
    },
    {
      title: "Tên đội",
      dataIndex: "tenDoi",
      key: "tenDoi",
      render: (text) => <span>{text}</span>,
      width: "20%",
      editable: true,
    },
    {
      title: "Tên nhân viên",
      dataIndex: "tenNhanVien",
      key: "tenDoi",
      render: (text) => <span>{text}</span>,
      width: "20%",
      editable: true,
    },
    {
      title: t("cau_hinh_kbk.tac_vu"),
      dataIndex: "",
      key: "tacVu",
      className: "text-center",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button type="primary" onClick={() => save(record)}
              style={{
                marginRight: 10,
              }}
            >
              Lưu
            </Button>
            <Button onClick={cancel}>Bỏ qua</Button>
          </span>
        ) : (
          <span>
            <CheckPermission permissionCode={ghi_chi_so.d71s}>
              <Button type="primary" disabled={editingKey !== ''} onClick={() => edit(record)}
                style={{
                  marginRight: 8,
                }}>
                Thay đổi NV
              </Button>
            </CheckPermission>
            <CheckPermission permissionCode={ghi_chi_so.d71x}>
              <Button
                type="danger"
                onClick={() => handleXoaCauHinhNV(record.id)}
              >
                Xóa
              </Button>
            </CheckPermission>
          </span>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        key: col.key,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const handleXoaCauHinhNV = useCallback(
    (id) => {
      submitXoaCauHinhNV(id, setLoading, setFetchDataTable);
    },
    []
  );
  const handleOpenModal = useCallback(() => {
    setVisible(true);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFinish = (values) => {
    //call api
    submitThemMoiCauHinhNV(
      values,
      setLoading,
      handleCloseModal,
      setFetchDataTable
    );
  };
  //render popup
  const showModal = useMemo(
    () =>
      visible && (
        <CauHinhNVModal
          visible={visible}
          dataDsSoGCS={dataDsSoGCS}
          handleChangeSo={handleChangeSo}
          onFinish={onFinish}
          closeModal={handleCloseModal}
        />
      ),
    [dataDsSoGCS, onFinish, visible]
  );
  const genExtra = useCallback(
    () => (
      <div>
        <CheckPermission permissionCode={ghi_chi_so.d71t}>
          <ButtonComponent
            type="primary"
            onClick={() => handleOpenModal()}
          >
            Thêm mới
          </ButtonComponent>
        </CheckPermission>
      </div>
    ),
    [handleOpenModal]
  );
  return (
    <Fragment>
      <Filter>
        <ListFilterCauHinhKyBK
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          dataDsSoGCS={dataDsSoGCS}
        />
      </Filter>
      <Container>
        <Form form={form} component={false}>
          <TableComponent
            header="Cấu hình NV GCS mặc định"
            dataSource={dataCauHinhNV}
            columns={customColumn(mergedColumns, filterConditions)}
            totalData={totalCount}
            loading={loading}
            onChangePagination={onChangePagination}
            renderExtraAction={() => genExtra()}
            pagination={filterConditions}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
          />
        </Form>
      </Container>
      {showModal}
    </Fragment>
  );
}
