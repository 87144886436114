import { openModal, closeModal } from "@state/system-config/reducer";
import { Menu } from "antd";
import { useDispatch } from "react-redux";
import TaiAnhHoSoModal from "@modules/tich-hop-CRM/common-ho-so/tai-anh-ho-so/index";
export default function ListMenu({ detail }) {
  const dispatch = useDispatch();
  return (
    <Menu mode="vertical" style={{ width: "100%" }}>
      <Menu.Item
        eventKey="1"
        onClick={() =>
          dispatch(
            openModal({
              title: "Tải ảnh hồ sơ",
              content: (
                <TaiAnhHoSoModal closeModal={closeModal} detail={detail} />
              ),
              size: "large",
            })
          )
        }
      >
        Tải ảnh hồ sơ
      </Menu.Item>
    </Menu>
  );
}
