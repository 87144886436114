import React, { useEffect, useRef, useState, useCallback } from "react";
import { Input, Form, Button } from "antd";
import styled from "styled-components";
import { LeftOutlined, LockOutlined } from "@ant-design/icons";
import FormComponent from "@components/Form";
import { useTranslation } from "react-i18next";
import { postData } from "@utils/request";
import { STATUSCODE_200 } from "@utils/constants";
import { getErrorForm } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
export default function ForgotPasswordForm(props) {
  const { t } = useTranslation();
  const { setStep } = props;
  const [loading, setLoading] = useState();
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const onFinish = useCallback(
    (values) => {
      postData({
        url: `${Endpoint.RESET_PASSWORD}`,
        method: "POST",
        payload: {
          ...values,
        },
        setLoading,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            setVisiblePassword(true);
          } else {
            getErrorForm(res, form);
          }
        },
      });
    },
    [form]
  );

  return (
    <Container>
      <div className="title">
        <span>{t("quen_mat_khau")}</span>
      </div>
      <FormComponent
        form={form}
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        {visiblePassword && (
          <Form.Item name="newPassword">
            <Input.Password
              size="large"
              placeholder={t("Mật khẩu mới")}
              prefix={<LockOutlined style={{ color: "#279BEF" }} />}
              ref={inputRef}
            />
          </Form.Item>
        )}
        {visiblePassword && (
          <Form.Item name="confirmNewPassword">
            <Input.Password
              size="large"
              placeholder={t("Xác nhận mật khẩu mới")}
              prefix={<LockOutlined style={{ color: "#279BEF" }} />}
              ref={inputRef}
            />
          </Form.Item>
        )}
        <Button
          htmlType="submit"
          type="primary"
          size="large"
          className="full"
          loading={loading}
          disabled={visiblePassword}
        >
          {/* {visiblePassword ? "Khôi phục mật khẩu" : "Gửi mã OTP"} */}
          Đổi mật khẩu
        </Button>
        <div className="note">
          Mật khẩu mới sẽ được gửi vào email đã liên kết với tài khoản.
        </div>
        <div className="go-back" onClick={() => setStep(1)}>
          <LeftOutlined />
          <span>{t("dang_nhap")}</span>
        </div>
      </FormComponent>
    </Container>
  );
}

const Container = styled.div`
  color: #1f1f1f;
  .title {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 32px;
  }
  .note {
    text-align: center;
    margin-top: 10px;
    font-size: 12px;
  }
  .go-back {
    color: #279bef;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
    > span {
      margin-left: 5px;
    }
    :hover {
      cursor: pointer;
    }
  }
`;
