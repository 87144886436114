import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { handleUpdateData } from "./services";
import ModalKhaoSatLai from "./modal-khao-sat-lai";

export default function KhaoSatLaiModal({
  visible,
  closeModal,
  setFetchDataTable,
  detail,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    const data = { hoSoId: detail.id };
    handleUpdateData(data, setLoading, closeModal, setFetchDataTable);
  };

  return (
    <ModalKhaoSatLai
      loading={loading}
      visible={visible}
      closeModal={closeModal}
      handleSubmit={handleSubmit}
      t={t}
    ></ModalKhaoSatLai>
  );
}
