import React from "react";
import { Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { ContainerMultiUpload } from "@modules/cap-dien/common-ho-so/tai-anh-ho-so-khach-hang/css-styled";
export default function ImageMultipleUpload(props) {
  const {
    setImageFiles,
    imageFiles,
    fileToDataUri,
    setIsUpload,
    isSubmit,
    multiple,
    messErr,
    acceptMess,
    accept,
  } = props;
  const beforeUpload = (file) => {
    const isTypeImage =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "application/pdf";
    if (!isTypeImage) {
      message.error(
        messErr
          ? messErr
          : "Chỉ được phép tải ảnh định dạng .JPEG, .JPG, .PNG, .PDF !"
      );
    }
    const isLt5M = file.size / 1024 / 1024 <= 5;
    if (!isLt5M) {
      message.error(`Ảnh ${file.name} vượt quá 5MB`);
    }
    return isTypeImage && isLt5M;
  };

  const uploadAvatar = async ({ fileList: newList }) => {
    setIsUpload(false);
    let newImagesPromises = [];
    for (let i = 0; i < newList.length; i++) {
      const checkBefore = beforeUpload(newList[i]);
      if (checkBefore) {
        if (multiple === false) {
          newImagesPromises = [];
        }
        if (newList[i]?.upload) {
          newImagesPromises.push(newList[i]);
        } else newImagesPromises.push(fileToDataUri(newList[i].originFileObj));
      }
    }
    const newImages = await Promise.all(newImagesPromises);

    setImageFiles(newImages);
    setIsUpload(true);
  };

  return (
    <ContainerMultiUpload
      customRequest={() => false}
      multiple={multiple === undefined || multiple === null ? true : false}
      fileList={isSubmit ? [] : imageFiles}
      onChange={uploadAvatar}
      itemRender={() => (
        // originNode, file, currFileList, actions
        <></>
        // <DragableUploadListItem
        //   originNode={originNode}
        //   file={file}
        //   fileList={currFileList}
        //   moveRow={moveRow}
        //   actions={actions}
        // />
      )}
      onRemove={() => {
        // console.log(file);
      }}
      accept={accept ? accept : ".jpg, .jpeg, .png, .pdf"}
    >
      <Button>
        <div className="upload-container">
          <span className="upload-choice">
            <UploadOutlined /> Chọn tệp
          </span>
          <span>
            {acceptMess
              ? acceptMess
              : "Có thể kéo & thả để tải tệp (*.png, *.jpg, *.jpeg, *.pdf và ảnh không quá 5MB)"}
          </span>
        </div>
      </Button>
    </ContainerMultiUpload>
  );
}
