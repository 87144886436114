import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import { buildQueryString, handlePagination, parseParams, removeUndefinedAttribute } from "@utils/function";

import ThongTinChiTietTabs from "@modules/tich-hop-CRM/common-ho-so/chi-tiet-yeu-cau/index";
import { dateConditionLogic } from "@modules/tich-hop-CRM/common-ho-so/functions";
import CRMFilter from "@modules/tich-hop-CRM/common-ho-so/list-bo-loc";
import { closeModal, openModal } from "@state/system-config/reducer";
import { Button, Col, Form, Row } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { Style } from "../../css-styled";
import DuyetYeuCauModal from "./modal/duyet-yeu-cau-modal";
import TraYeuCauModal from "./modal/tra-yeu-cau-modal";
import { getDanhSachDuyetDoi, getLinhVucList, putKyHSM, putTraLaiYeuCau, putXacNhanYeuCau } from "./services";
function DuyetYeuCau() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const donViId = useSelector((state) => state.auth.user.unitId);
  const [isDoubleClick] = useState(true);
  const [listLoading, setListLoading] = useState(false);
  const [linhVucList, setLinhVucList] = useState([]);
  const [loadingKyHSM, setLoadingKyHSM] = useState(false);

  const listTrangThai = [
    {
      name: "Chờ phòng kinh doanh duyệt",
      value: "6fa63b1f-a8f6-4db1-9bb6-ec93a7ba071e",
    },
  ];

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DenNgay: `${moment().format("YYYY-MM-DD")}`,
    TuNgay: `${moment().subtract(7, "d").format("YYYY-MM-DD")}`,
    DonViId: donViId,
    ...parseParams(location.search),
  });
  const [visibleDuyet, setVisibleDuyet] = useState(false);
  const [visibleTra, setVisibleTra] = useState(false);
  const [dataIds, setDataIds] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      width: "80px",
      align: "center",
    },
    {
      title: "Mã yêu cầu",
      dataIndex: "maYeuCau",
      align: "center",
      width: "80px",
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      align: "left",
      width: "100px",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "tenKhachHang",
      width: "100px",
      align: "left",
    },
    {
      title: "Kết quả xử lý",
      dataIndex: "ketQuaXuLy",
      width: "150px",
      align: "left",
    },
    {
      title: "Ngày yêu cầu",
      dataIndex: "ngayYeuCau",
      align: "center",
      width: "140px",
      render: (text, record) => {
        if (record.ngayYeuCau) {
          return <>{moment(record.ngayYeuCau).format("DD/MM/YYYY HH:mm")}</>;
        }
      },
    },
    {
      title: "Ngày tiếp nhận",
      dataIndex: "ngayTiepNhan",
      align: "center",
      width: "140px",
      render: (text, record) => {
        if (record.ngayTiepNhan) {
          return <>{moment(record.ngayTiepNhan).format("DD/MM/YYYY HH:mm")}</>;
        }
      },
    },
    {
      title: "Người yêu cầu",
      dataIndex: "nguoiYeuCau",
      align: "left",
      width: "100px",
    },
    {
      title: "Điện thoại",
      dataIndex: "dienThoai",
      align: "center",
      width: "110px",
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      width: "110px",
      align: "left",
    },
    {
      title: "Loại yêu cầu",
      dataIndex: "loaiYeuCau",
      width: "140px",
      align: "left",
    },
    {
      title: "Biên bản",
      dataIndex: "bienBan",
      width: "70px",
      render: (data) => {
        return (
          data && (
            <Button style={{ backgroundColor: "rgb(244, 164, 96)" }}>
              <a href={data} target="_blank" rel="noreferer noreferrer">
                Xem{" "}
              </a>
            </Button>
          )
        );
      },

      align: "center",
    },
    {
      title: "Phiếu yêu cầu",
      dataIndex: "phieuYeuCau",
      render: (link) => {
        return (
          link && (
            <a href={link} target="_blank" rel="noreferer noreferrer">
              Xem{" "}
            </a>
          )
        );
      },
      width: "75px",

      align: "center",
    },
    {
      title: "Nhân viên thực hiện",
      dataIndex: "nhanVienThucHien",
      width: "100px",
      align: "left",
    },
    {
      title: "Đội",
      dataIndex: "tenDoi",
      width: "100px",
      align: "left",
    },
    {
      title: "Web id",
      dataIndex: "webId",
      width: "80px",
      align: "center",
    },
  ].filter((item) => !item.hidden);
  const dispatch = useDispatch();

  useEffect(() => {
    if (filterConditions.TuNgay && filterConditions.DenNgay) {
      form.setFieldsValue({
        TuNgay: moment(new Date(filterConditions.TuNgay)),
        DenNgay: moment(new Date(filterConditions.DenNgay)),
        ...filterConditions,
      });
    } else {
      form.setFieldsValue({
        TuNgay: moment(),
        DenNgay: moment(),
        ...filterConditions,
      });
    }
  }, [filterConditions, form]);

  const handleSearch = useCallback(
    (values) => {
      const ojbStartDate = new Date(values.TuNgay);
      const objEndDate = new Date(values.DenNgay);
      const dateCondition = dateConditionLogic(ojbStartDate, objEndDate);

      const payload = {
        TuNgay: dateCondition.formatStartDate,
        DenNgay: dateCondition.formatEndDate,
        ...(values.KenhId && { KenhId: values.KenhId }),
        ...(values.DichVuId && { DichVuId: values.DichVuId }),
        ...(values.DoiId && { DoiId: values.DoiId }),
        ...(values.DonViId && { DonViId: values.DonViId }),
        ...(values.TuKhoaTimKiem && { TuKhoaTimKiem: values.TuKhoaTimKiem }),
        ...(values.TrangThai && { TrangThai: values.TrangThai }),
        ...(values.LoaiYeuCau && { LoaiYeuCau: values.LoaiYeuCau }),
        pageIndex: DEFAULT_PAGEINDEX,
        pageSize: DEFAULT_PAGESIZE,
      };
      setDataIds([]);
      setSelectedRowKeys([]);
      setFilterConditions(payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterConditions]
  );

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      const idArray = selectedRows.map((item) => {
        return item.id;
      });
      setDataIds(idArray);
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
    selectedRowKeys,
  };

  const handleCloseModalDuyet = () => {
    setVisibleDuyet(false);
  };

  const handleConfirmDuyet = () => {
    setVisibleDuyet(true);
  };

  const handleSubmitDuyet = () => {
    const payload = {
      id: dataIds,
    };

    putXacNhanYeuCau(
      payload,
      setLoading,
      setListLoading,
      location,
      filterConditions,
      setData,
      setTotal,
      setVisibleDuyet,
      setSelectedRowKeys
    );
  };

  const handleGetDanhSachChuyenDoi = () => {
    const query = buildQueryString(filterConditions)
    getDanhSachDuyetDoi(setListLoading, setData, query, filterConditions, setTotal)
  }

  const handleKyHSM = () => {
    const payload = {
      id: dataIds,
    };
    putKyHSM({
      payload,
      setLoadingKyHSM,
      onSuccess: () => {
        handleGetDanhSachChuyenDoi();
        setVisibleDuyet(false);
        setSelectedRowKeys([]);
        setDataIds([]);
      },
    });
  };

  const handleConfirmTra = () => {
    setVisibleTra(true);
  };

  const handleCloseModalTra = () => {
    setVisibleTra(false);
  };

  const handleSubmitTra = (value) => {
    const payload = {
      lyDoTraLai: value.LyDoTraLai,
      id: dataIds,
    };

    putTraLaiYeuCau(
      payload,
      setLoading,
      setListLoading,
      location,
      filterConditions,
      setData,
      setTotal,
      setVisibleTra,
      setSelectedRowKeys
    );
  };

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const handleGetListLinhVuc = () => {
    getLinhVucList(setLoading, form, setData, filterConditions, setTotal, location, setListLoading, setLinhVucList);
  };

  useEffect(() => {
    handleGetListLinhVuc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions]);

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const detailHoSo = (record) => {
    if (isDoubleClick)
      return {
        onDoubleClick: () => {
          dispatch(
            openModal({
              content: <ThongTinChiTietTabs detailHoSo={record} closeModal={closeModal} />,
              size: "large",
              title: <>Chi tiết yêu cầu</>,
            })
          );
        },
      };
  };

  const clearFilter = useCallback(() => {
    const payload = {
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DenNgay: `${moment().format("YYYY-MM-DD")}`,
      TuNgay: `${moment().subtract(7, "d").format("YYYY-MM-DD")}`,
      DonViId: donViId,
      LinhVuc: linhVucList[0].value,
    };

    setFilterConditions(payload);
    setDataIds([]);
    setSelectedRowKeys([]);
    form.setFieldsValue({
      LinhVuc: linhVucList[0].value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Style>
      <div className="tich-hop-CRM-gdv">
        <Filter>
          <CRMFilter
            handleSearch={handleSearch}
            filterConditions={filterConditions}
            form={form}
            clearFilter={clearFilter}
            listTrangThai={listTrangThai}
          />
        </Filter>
        <div className="tich-hop-CRM-gdv-content">
          <Row gutter={24}>
            <Col span={6} offset={18}>
              <div className="tich-hop-CRM-gdv-content-button">
                <Button onClick={handleConfirmTra} disabled={dataIds.length < 1}>
                  Trả yêu cầu
                </Button>
                <Button type="primary" onClick={handleConfirmDuyet} disabled={dataIds.length < 1}>
                  Duyệt yêu cầu
                </Button>
              </div>
            </Col>
          </Row>
          <TableComponent
            dataSource={data}
            columns={columns}
            scrollX={1600}
            expandable={false}
            header="Danh sách"
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            loading={listLoading}
            rowKey="key"
            totalData={total}
            pagination={filterConditions}
            onChangePagination={onChangePagination}
            onRow={detailHoSo}
          />
          <DuyetYeuCauModal
            handleCloseModal={handleCloseModalDuyet}
            visible={visibleDuyet}
            handleSubmit={handleSubmitDuyet}
            loading={loading}
            loadingKyHSM={loadingKyHSM}
            handleKyHSM={handleKyHSM}
          />
          <TraYeuCauModal
            handleCloseModal={handleCloseModalTra}
            visible={visibleTra}
            handleSubmit={handleSubmitTra}
            loading={loading}
          />
        </div>
      </div>
    </Style>
  );
}

export default DuyetYeuCau;
