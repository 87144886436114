import { closeModal } from "@state/system-config/reducer";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authPostData } from "@utils/request";

export function handleDongBoAnhCMIS(
  payload,
  setLoading,
  setFetchDataTable,
  dispatch,
  setDongBoTay
) {
  authPostData({
    url: Endpoint.DONG_BO_ANH_CMIS_TIEN_ICH,
    payload,
    method: METHOD_POST,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 || res.data) {
        if (setDongBoTay) {
          setDongBoTay(true);
        } else {
          console.log("auto", 123);
          dispatch(closeModal());
        }
        setFetchDataTable(true);
      }
    },
  });
}
