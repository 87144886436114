import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { handleCreateCongToTonKhoDL, SelectLoaiCongTo } from "../service";

// modal cập nhật tình hình công tơ tồn kho

function ModalCapNhatThCongToTonKho(props) {
  const {
    visible,
    onCancel,
    dataTable,
    filterConditions,
    setFilterConditions,
    getListData,
    loading,
    setLoading,
  } = props;
  const user = useSelector(authSelector);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    const url = Endpoint.BCCT_THEM_TINH_HINH_CONG_TO_TRONG_KHO_TON_KHO;
    const data = {
      ma_dviqly: values.unitId,
      ...(values.reportDate && { nam: values.reportDate.split("-")[0] }),
      ...(values.reportDate && { thang: values.reportDate.split("-")[1] }),
      ma_cloai: values.ma_cloai,
      cto_kho: values.cto_kho,
      cto_sdung: values.cto_sdung,
      cto_ko_sdung: values.cto_ko_sdung,
      nguyen_nhan: values.nguyen_nhan,
    };
    handleCreateCongToTonKhoDL(data, setLoading, getListData, url, form);
  };

  //HandleChange Năm
  const handleChange = (value) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      Nam: moment(value).get("year"),
    }));
  };

  const columns = [
    {
      title: "Đơn vị",
      dataIndex: "MA_DVIQLY",
      key: "MA_DVIQLY",
      render: (text) => <p>{text}</p>,
      width: "10%",
    },
    {
      title: "Tháng",
      dataIndex: "THANG",
      key: "THANG",
      width: "10%",
    },
    {
      title: "Năm",
      dataIndex: "NAM",
      key: "NAM",
      width: "10%",
    },
    {
      title: "Công tơ trong kho",
      dataIndex: "CTO_KHO",
      key: "CTO_KHO",
      width: "15%",
    },
    {
      title: "công tơ sử dụng",
      dataIndex: "CTO_SDUNG",
      key: "CTO_SDUNG",
      width: "15%",
    },
    {
      title: "Công tơ không sử dụng",
      dataIndex: "CTO_KO_SDUNG",
      key: "CTO_KO_SDUNG",
      width: "15%",
    },
    {
      title: "Nguyên nhân",
      dataIndex: "NGUYEN_NHAN",
      key: "NGUYEN_NHAN",
    },
  ];

  return (
    <Modal
      visible={visible}
      title="Cập nhật tình hình công tơ tồn kho"
      onCancel={onCancel}
      width={"70%"}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <FormComponent
          onFinish={onFinish}
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 16 }}
          initialValues={{
            unitId: user.user.unitCode,
          }}
        >
          <Row>
            <Col span={16}>
              <Form.Item name="unitId" label="Đơn vị" labelAlign="left">
                <Selection
                  url={Endpoint.GET_TEAM_LIST_CODE}
                  formKey="unitId"
                  form={form}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <Form.Item name="reportDate" label="Tháng /năm" labelAlign="left">
                <DatePickerComponent
                  picker="month"
                  formKey="reportDate"
                  form={form}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <Form.Item name="ma_cloai" label="Loại công tơ" labelAlign="left">
                <SelectLoaiCongTo
                  url={Endpoint.BCCT_LOAI_CONG_TO}
                  formKey="ma_cloai"
                  form={form}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <Form.Item
                name="cto_kho"
                label="Trước phúc tra"
                labelAlign="left"
              >
                <InputNumber placeholder="Nhập số lượng công tơ trước phúc tra" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <Form.Item
                name="cto_sdung"
                label="Công tơ sử dụng"
                labelAlign="left"
              >
                <InputNumber placeholder="Nhập số lượng công tơ sử dụng" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <Form.Item
                name="cto_ko_sdung"
                label="Công tơ không sử dụng"
                labelAlign="left"
              >
                <InputNumber placeholder="Nhập số lượng công tơ không sử dụng" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <Form.Item
                name="nguyen_nhan"
                label="Nguyên nhân"
                labelAlign="left"
              >
                <Input.TextArea
                  showCount
                  maxLength={500}
                  rows={4}
                  placeholder="Nhập nguyên nhân"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Cập nhật
            </Button>
          </Form.Item>
        </FormComponent>
        <Divider className="section-text-line" orientation="left"></Divider>
        <Row>
          <Col>
            <Space style={{ marginBottom: "1rem" }}>
              <Typography.Text>Năm: </Typography.Text>
              <DatePicker
                picker="year"
                defaultValue={moment(filterConditions?.Nam, "YYYY")}
                onChange={(value) => handleChange(value)}
              />
            </Space>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={dataTable}
          scroll={{ y: 420 }}
          pagination={false}
        />
      </Spin>
    </Modal>
  );
}
export default memo(ModalCapNhatThCongToTonKho);
