import { customColumn } from "@utils/function";
import { Button } from "antd";
import { ModalTable } from "../../css-styled";
import TableDuLieuTab1 from "../../thong-ke-tiep-nhan/table";
export default function ModalDuLieu(props) {
  const {
    visible,
    closeModal,
    isThang,
    columns,
    data,
    // countTotals,
    noteColumns,
    filterConditions,
  } = props;
  return (
    <ModalTable
      title="Xem bảng dữ liệu"
      visible={visible}
      width={"95%"}
      style={{ top: "1%" }}
      onCancel={closeModal}
      className="modal-table"
      footer={[
        <div style={{ textAlign: "center" }} key="footer-key">
          <Button key="cancel" onClick={closeModal}>
            Đóng lại
          </Button>
        </div>,
      ]}
    >
      <TableDuLieuTab1
        header={
          isThang
            ? "Bảng dữ liệu tháng hiện tại"
            : "Bảng dữ liệu lũy kế đến tháng hiện tại"
        }
        columns={customColumn(columns, filterConditions)}
        data={data}
        // countTotals={countTotals}
        noteColumns={noteColumns}
      />
    </ModalTable>
  );
}
