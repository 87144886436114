import { Form } from "antd";
import styled from "styled-components";

export const ContainerXacNhanThiCong = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 20%);
  width: 524px;
`;

export const ContainerBox = styled.div`
  height: 96px;
  padding: 20px 0px;
  text-align: center;
  background: #e3f1fc;
  margin-top: 24px;
`;

export const ContainerBody = styled.div`
  padding: 16px 24px;
`;

export const ContainerBodyPanel = styled.div`
  padding: 16px 32px;
`;

export const ContainerModalTroNgai = styled.div`
  padding: 0px 36px;
`;

export const ContainerFormInput = styled(Form.Item)`
  margin-bottom: 0;
`;
export const ContainerWarning = styled.div`
  padding-left: 120px;
  font-size: 18px;
  color: #ff4d4f;
`;

export const ContainerFormCheckBox = styled(Form.Item)`
  margin: 0;
`;

export const TableHeader = styled.table`
  table-layout: auto;
  width: 100%;
`;

export const TableBody = styled.div`
  max-height: 350px;
  overflow: auto;

  table {
    width: 100%;
  }
`;

export const HeaderSearchResult = styled.div`
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  background-color: #279bef;
  height: 32px;
  border-radius: 4px 4px 0px 0px;
  color: #ffffff;
  align-items: center;
  padding: 10px;
`;
export const ItemSearchResult = styled.div`
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  color: #1f1f1f;
  align-items: center;
  line-height: 20px;
`;

export const Container = styled.div`
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #dadada;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const ButtonSearchResult = styled.div`
  text-align: center;
  padding: 10px;
`;

export const TableMBD = styled.div`
  #custom-collapse-container {
    padding: 0;
  }
  .table-component-panel .ant-collapse-content.ant-collapse-content-active {
    min-height: 300px;
  }
`;
