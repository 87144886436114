import { API_QUAN_LY_LO_TRINH } from "@apiUrl/lo-trinh-nhan-vien/quan-ly-lo-trinh/index";
import { API_QUAN_LY_DIEM_DO } from "@apiUrl/lo-trinh-nhan-vien/quan-ly-diem-do/index";

export const LOTRINH_Endpoint = {
  //Quan ly diem do
  LOTRINH_QUAN_LY_DIEM_DO: API_QUAN_LY_DIEM_DO.QUAN_LY_DIEM_DO,
  // EXport danh sach quan ly diem do
  EXPORT_QUAN_LY_DIEM_DO: API_QUAN_LY_DIEM_DO.EXPORT_QUAN_LY_DIEM_DO,
  // List Tram
  lOTRINH_LIST_TRAM: API_QUAN_LY_DIEM_DO.QUAN_LY_DIEM_DO_LIST_TRAM,
  // Dong bo diem do tu CMIS
  LOTRINH_DONG_BO_DIEM_DO_TU_CMIS: API_QUAN_LY_DIEM_DO.DONG_BO_DIEM_DO_TU_CMIS,
  // So GCS
  LOTRINH_SO_GCS: API_QUAN_LY_DIEM_DO.DANH_SACH_SO_GCS,

  // Quan ly lo trinh
  LOTRINH_QUAN_LY_LO_TRINH: API_QUAN_LY_LO_TRINH.QUAN_LY_LO_TRINH,
  // Danh sach nhan vien
  LOTRINH_DANH_SACH_NHAN_VIEN:
    API_QUAN_LY_LO_TRINH.QUAN_LY_LO_TRINH_DANH_SACH_NHAN_VIEN,

  // Báo cáo vị trí nhân viên
  LOTRINH_BAO_CAO_VI_TRI_NHAN_VIEN: API_QUAN_LY_LO_TRINH.BAO_CAO_LICH_SU_VI_TRI,

  // Trang thai nhan vien
  LOTRINH_TRANG_THAI_NHAN_VIEN: API_QUAN_LY_LO_TRINH.TRANG_THAI_NHAN_VIEN,
};
