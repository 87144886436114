import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
export const handleListData = (
  location,
  filterConditions,
  setLoading,
  setData,
  setTotalData
) => {
  let url = "";
  url = `${Endpoint.HO_SO_DANG_KY}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      setData(
        res.data.data.map((item, index) => {
          return {
            ...item,
            STT:
              (filterConditions.pageIndex - 1) * filterConditions.pageSize +
              index +
              1,
          };
        })
      );
      setTotalData(res.data.total);
    },
  });
};

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      maDonVi: data.maDonVi,
      denNgay: data.denNgay.format("YYYY/MM/DD"),
      tuNgay: data.tuNgay.format("YYYY/MM/DD"),
      keyWord: data.keyWord,
      pageIndex: 1,
      pageSize: 100000,
    })
  );
  const endpoint = `${Endpoint.HO_SO_DANG_KY}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};
