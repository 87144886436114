import FormComponent from "@components/Form";
import InputComponent from "@components/Input";
import Selection from "@components/Select";
import { closeModal } from "@state/system-config/reducer";
import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { getErrorForm } from "@utils/function";
import { authPostData } from "@utils/request";
import { Button, Checkbox, Col, Form, Row, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
export default function EditPageForm({
  page,
  getPageList,
  getPageDetail,
  dispatch,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (page) {
      form.setFieldsValue(page);
    }
  }, [page, form]);

  const onFinish = useCallback(
    (values) => {
      const data = {
        ...values,
        id: page.id,
      };
      authPostData({
        url: `${Endpoint.PAGE}`,
        method: "PUT",
        setLoading,
        payload: {
          ...data,
        },
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && page) {
            getPageList();
            getPageDetail();
            dispatch(closeModal());
          } else {
            getErrorForm(res, form);
          }
        },
      });
    },
    [form, getPageDetail, getPageList, page, dispatch]
  );
  return (
    <Spin spinning={loading}>
      <FormComponent
        onFinish={onFinish}
        colon={false}
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="moduleId"
              label="Module"
              labelAlign="left"
              required
            >
              <Selection
                url={`${Endpoint.PAGE}/modules`}
                form={form}
                formKey="moduleId"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              name="pageName"
              label="Tên trang"
              required
              labelAlign="left"
            >
              <InputComponent
                type="text"
                size="default"
                form={form}
                formKey="pageName"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item name="url" label="Đường dẫn" required labelAlign="left">
              <InputComponent
                type="text"
                size="default"
                form={form}
                formKey="url"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col offset={4} span={24}>
            <Form.Item name="trangThai" valuePropName="checked">
              <Checkbox>Hoạt động</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} justify="center">
          <Col span={4}>
            <Button className="full" onClick={() => dispatch(closeModal())}>
              {t("Đóng lại")}
            </Button>
          </Col>
          <Col span={4}>
            <Button type="primary" className="full" htmlType="submit">
              {t("Lưu")}
            </Button>
          </Col>
        </Row>
      </FormComponent>
    </Spin>
  );
}
