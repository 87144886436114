import { authDeleteData, authGetData, authPostData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import {
  alertMessage,
  buildQueryString,
  getErrorMessage,
  parseParams,
} from "@utils/function";
import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { t } from "i18next";

export const getListLyDoTreoThaoService = (
  filterConditions,
  setLoading,
  setDataLyDoTreoThao,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.LIST_LY_DO_TREO_THAO}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataLyDoTreoThao(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataLyDoTreoThao([]);
    },
  });
};

export const deleteLyDoTreoThaoService = (
  ids,
  setLoading,
  getListLyDoTreoThao
) => {
  authDeleteData({
    url: Endpoint.REMOVE_LY_DO_TREO_THAO + "?Ids=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) getListLyDoTreoThao();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

export const createOrUpdateLyDoTreoThao = (
  data,
  setLoading,
  getListLyDoTreoThao,
  closeModal,
  form
) => {
  if (data.id) {
    authPostData({
      url: Endpoint.UPDATE_LY_DO_TREO_THAO,
      method: METHOD_PUT,
      payload: data,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          getListLyDoTreoThao();
          closeModal();
        } else getErrorMessage(res, form);
      },
    });
  } else {
    authPostData({
      url: Endpoint.CREATE_LY_DO_TREO_THAO,
      method: METHOD_POST,
      payload: data,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          getListLyDoTreoThao();
          closeModal();
        } else getErrorMessage(res, form);
      },
    });
  }
};
