import { Fragment } from "react";

import { customColumn } from "@utils/function";
import { ListFilter } from "./list-bo-loc";
import { Container } from "./css-styled";
import styled from "styled-components";
import TableComponent from "@components/TableComponent";
export default function BangKeTable(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    genExtra,
    dataSource,
    columns,
    totalCount,
    loading,
    rowSelection,
    headerTable,
    donVi,
    loaiBangKe,
    isNotSelect,
    firstLoaiBangKe,
    handleChangeThangLV,
    locale,
    form,
  } = props;

  const addRow = (
    <AddingRowContainer>
      <BlankLeft />

      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "19.8%",
          backgroundColor: "#f5d4d4",
          height: "100%",
          border: "1px solid black",
          fontWeight: "500",
          fontSize: "16px",
        }}
      >
        Đã ký
      </span>
      <SoLuongKyWrapper>
        {dataSource.soLuongNgayKi1 ? dataSource.soLuongNgayKi1 : 0} {"/"} {dataSource?.tongSoBangKe ? dataSource.tongSoBangKe : 0}
      </SoLuongKyWrapper>
      <SoLuongKyWrapper>
        {dataSource.soLuongNgayKi2 ? dataSource.soLuongNgayKi2 : 0} {"/"} {dataSource?.tongSoBangKe ? dataSource.tongSoBangKe : 0}
      </SoLuongKyWrapper>
      <SoLuongKyWrapper>
        {dataSource.soLuongNgayKi3 ? dataSource.soLuongNgayKi3 : 0} {"/"} {dataSource?.tongSoBangKe ? dataSource.tongSoBangKe : 0}
      </SoLuongKyWrapper>
      <SoLuongKyWrapper>
        {dataSource.soLuongNgayKi4 ? dataSource.soLuongNgayKi4 : 0} {"/"} {dataSource?.tongSoBangKe ? dataSource.tongSoBangKe : 0}
      </SoLuongKyWrapper>
      
      <BlankRight />
    </AddingRowContainer>
  );
  return (
    <Fragment>
      <WrapperFilter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          donVi={donVi}
          loaiBangKe={loaiBangKe}
          firstLoaiBangKe={firstLoaiBangKe}
          handleChangeThangLV={handleChangeThangLV}
          locale={locale}
          form={form}
        />
        <>Tổng: {dataSource.tongSoBangKe} bản ghi</>
      </WrapperFilter>

      <Container>
        <TableComponent
          header={headerTable}
          renderExtraAction={() => genExtra()}
          dataSource={dataSource.bangKeResponses}
          columns={customColumn(columns, filterConditions)}
          totalData={totalCount}
          loading={loading}
          scrollX={1}
          scrollY={530}
          rowSelection={isNotSelect ? null : rowSelection}
          addRow={addRow}
        />
      </Container>
    </Fragment>
  );
}

const WrapperFilter = styled.div`
  padding: 10px 20px;
`;

const AddingRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 45px;
  text-align: center;
  background-color: white;
`;
const SoLuongKyWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5d4d4;
  width: 10.8%;
  height: 100%;
  border: 1px solid black;
  font-size: 16px;
  font-weight: 500;
`;

const BlankLeft = styled.span`
  width: calc(15% + 50px);
  height: 100%;
`;
const BlankRight = styled.span`
  width: 21%;
  height: 100%;

`;
