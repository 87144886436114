import React from "react";
import { Button, Col, Modal, Row } from "antd";

import LoadingComponent from "@components/Loading";
import { Container } from "./css-styled";

export default function ChuyenTCModal(props) {
  const { loading, visible, closeModal, handleXN } = props;
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={"Xác nhận chuyển an toàn"}
        visible={visible}
        onCancel={closeModal}
        footer={null}
      >
        <Container>
          <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
            Hồ sơ của bạn đã Lập biên bản lắp đặt đường dây. Xác nhận sẽ chuyển
            tiếp cho Ban an toàn ! (Hồ sơ không cần treo tháo sẽ được chuyển
            tiếp cho Nghiệm thu)
          </h4>

          <Row gutter={24} justify="center" style={{ marginTop: "28px" }}>
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                Đóng lại
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                className="primary"
                onClick={handleXN}
                key="confirm"
                loading={loading}
              >
                Đồng ý
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal>
    </LoadingComponent>
  );
}
