import { BASE_API_URL_DANH_MUC } from "@utils/constants";

export const APINganHang = {
  LIST_NGAN_HANG: `${BASE_API_URL_DANH_MUC}/ngan-hang/list`,
  SYNC_NGAN_HANG: `${BASE_API_URL_DANH_MUC}/ngan-hang/sync`,
  GET_NGAN_HANG: `${BASE_API_URL_DANH_MUC}/ngan-hang/get`,
  CREATE_NGAN_HANG: `${BASE_API_URL_DANH_MUC}/ngan-hang/create`,
  UPDATE_NGAN_HANG: `${BASE_API_URL_DANH_MUC}/ngan-hang/update`,
  REMOVE_NGAN_HANG: `${BASE_API_URL_DANH_MUC}/ngan-hang/delete`,
};
