import React from "react";
import { Button, Form, DatePicker, Row, Col } from "antd";
import FormComponent from "@components/Form";
import moment from "moment";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import { FORMAT_DATE } from "@utils/constants";
import LoadingComponent from "@components/Loading";
import {
  Container,
  ContainerH1,
  ContainerInfo,
  ContainerInfoBody,
  ContainerInfoCol,
} from "./css-styled";
import CheckPermission from "@components/CheckPermission";
import { cap_dien } from "@permissions/a_capdien";
import { disabledDateFuture } from "@utils/function";

export default function Index(props) {
  const {
    typeDongBo,
    form,
    handleSubmit,
    loading,
    ngay,
    handleChangeNgay,
    t,
    dataSync,
  } = props;
  return (
    <LoadingComponent loading={loading}>
      <Container>
        <FormComponent
          form={form}
          name="form-ho-so-cmis"
          layout="horizonal"
          onFinish={handleSubmit}
          initialValues={{ ngayDongBo: moment(ngay) }}
          autoComplete="off"
          style={{ marginBottom: "40px" }}
        >
          <Row gutter={24} align="middle" justify="center">
            <Col span={24} md={10}>
              <Form.Item
                label="Ngày đồng bộ"
                name="ngayDongBo"
                className="required-field"
                style={{ marginBottom: 0 }}
              >
                <DatePicker
                  locale={locale}
                  format={FORMAT_DATE}
                  allowClear={false}
                  onChange={handleChangeNgay}
                  placeholder={FORMAT_DATE}
                  disabledDate={disabledDateFuture}
                />
              </Form.Item>
            </Col>
            <CheckPermission
              permissionCode={
                typeDongBo ? cap_dien.a83_cmis : cap_dien.a404_dong_bo_cmis
              }
            >
              <Col span={24} md={4}>
                <Button
                  type="primary"
                  className="btn-bg-yellow"
                  htmlType="submit"
                  form="form-ho-so-cmis"
                  key="submit"
                  loading={loading}
                >
                  {t("button.dong_bo")}
                </Button>
              </Col>
            </CheckPermission>
          </Row>
        </FormComponent>
        {Object.keys(dataSync).length > 0 ? (
          <ContainerInfo gutter={24} align="middle" justify="center">
            <ContainerInfoCol span={24} md={24}>
              <ContainerH1>Thông tin khi đồng bộ CMIS</ContainerH1>
              <ContainerInfoBody>
                <h3>
                  Tổng số hồ sơ CMIS:
                  <span className="total"> {dataSync.tongSoHoSoCMIS}</span>
                </h3>
                <h3>
                  Số hồ sơ đồng bộ thành công:
                  <span className="total-success">
                    {" "}
                    {dataSync.soHoSoDongBoThanhCong}
                  </span>
                </h3>
                <h3>
                  Số hồ sơ đồng bộ thất bại:
                  <span className="total-fail">
                    {" "}
                    {dataSync.soHoSoDongBoThatBai}
                  </span>
                </h3>
              </ContainerInfoBody>
            </ContainerInfoCol>
          </ContainerInfo>
        ) : null}
      </Container>
    </LoadingComponent>
  );
}
