import {
  buildQueryString,
  formatDisplayMoney,
  parseParams,
} from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authGetMultiplesRequest } from "@utils/request";
import ThongTinChiTietTabs from "../../common-ho-so/chi-tiet-ho-so";
import { closeModal, openModal } from "@state/system-config/reducer";

export const handleListBaoCao = (
  filterConditions,
  setLoading,
  setData,
  setTotalCount
) => {
  authGetData({
    url:
      Endpoint.GET_LISt_CHAM_TIEN_DO +
      "?" +
      buildQueryString(parseParams(filterConditions)),
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setData(res.data);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};

export const handleMultiBoLoc = (
  setLoading,
  setMucDich,
  setSoPha,
  setDonVi,
  setChamTienDo,
  setLoaiYeuCau
) => {
  const endpoints = [
    `${Endpoint.COMMON_LIST_MUC_DICH}`,
    `${Endpoint.COMMON_LIST_SO_PHA}`,
    `${Endpoint.GET_UNIT_LIST}`,
    `${Endpoint.LIST_NGAY_CHAM_TIEN_DO}`,
    `${Endpoint.COMMON_LIST_LOAI_YEU_CAU}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setMucDich(res[0].data.data);
      setSoPha(res[1].data.data);
      setDonVi(res[2].data.data);
      setChamTienDo(res[3].data.data);
      setLoaiYeuCau(res[4].data.data);
    },
  });
};

export const handleListColumns = (setLoading, setDataColumns, dispatch) => {
  authGetData({
    url: Endpoint.GET_LIST_COLUMNS_BAO_CAO_CHAM_TIEN_DO,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        const detailHoSo = (record) => {
          return dispatch(
            openModal({
              content: (
                <ThongTinChiTietTabs
                  detailHoSo={record}
                  closeModal={closeModal}
                />
              ),
              size: "large",
              title: `${
                record.maYeuCau !== null ? record.maYeuCau + " - " : ""
              }${record.tenKhachHang !== null ? record.tenKhachHang : ""}`,
            })
          );
        };

        const columns = [
          {
            title: "STT",
            dataIndex: "stt",
            key: "stt",
            hide: false,
            disabled: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 60,
            fixed: "left",
          },
          {
            title: "Mã đơn vị",
            dataIndex: "maDonVi",
            key: "maDonVi",
            hide: false,
            disabled: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 100,
            sorter: true,
            fixed: "left",
          },
          {
            title: "Đội Quản lý",
            dataIndex: "maDoi",
            key: "maDoi",
            hide: false,
            disabled: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 110,
            sorter: true,
            fixed: "left",
          },
          {
            title: "Tên Khách hàng",
            dataIndex: "tenKhachHang",
            key: "tenKhachHang",
            hide: false,
            disabled: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 200,
            sorter: true,
            fixed: "left",
          },
          {
            title: "Mã yêu cầu",
            dataIndex: "maYeuCau",
            key: "maYeuCau",
            hide: false,
            disabled: true,
            render: (text, record) => (
              <div
                className="table-text-left ant-btn-link cursor-pointer"
                onClick={() => detailHoSo(record)}
              >
                <span>{text}</span>
              </div>
            ),
            width: 150,
            sorter: true,
            fixed: "left",
          },
          {
            title: "Mã khách hàng",
            dataIndex: "maKhachHang",
            key: "maKhachHang",
            hide: true,
            disabled: false,
            sorter: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 150,
          },
          {
            title: "Địa chỉ",
            dataIndex: "diaChi",
            key: "diaChi",
            hide: true,
            disabled: false,
            sorter: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 250,
          },
          {
            title: "Điện thoại",
            dataIndex: "dienThoai",
            key: "dienThoai",
            hide: true,
            disabled: false,
            sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 100,
          },
          {
            title: "Số CMT",
            dataIndex: "soCMT",
            key: "soCMT",
            hide: true,
            disabled: false,
            sorter: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 100,
          },

          {
            title: "Toạ độ X",
            dataIndex: "toaDoX",
            key: "toaDoX",
            hide: true,
            disabled: false,
            sorter: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 100,
          },
          {
            title: "Toạ độ Y",
            dataIndex: "toaDoY",
            key: "toaDoY",
            hide: true,
            disabled: false,
            sorter: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 100,
          },
          {
            title: "Nguồn tiếp nhận",
            dataIndex: "nguonTiepNhan",
            key: "nguonTiepNhan",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Loại hợp đồng",
            dataIndex: "loaiHopDong",
            key: "loaiHopDong",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Hình thức lắp đặt",
            dataIndex: "hinhThucLapDat",
            key: "hinhThucLapDat",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 150,
          },
          {
            title: "Chi phí",
            dataIndex: "chiPhi",
            key: "chiPhi",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
                {/* <span>{text ? formatDisplayMoney(text, 0) : null}</span> */}
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 100,
          },
          {
            title: "Hình thức nộp tiền",
            dataIndex: "hinhThucNopTien",
            key: "hinhThucNopTien",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Công suất tối đa",
            dataIndex: "congSuatToiDa",
            key: "congSuatToiDa",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Cáp chính",
            dataIndex: "capChinh",
            key: "capChinh",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            // sorter: true,
            width: 150,
          },
          {
            title: "Số lượng cáp",
            dataIndex: "soLuongCap",
            key: "soLuongCap",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            // sorter: true,
            width: 100,
          },
          {
            title: "Số pha",
            dataIndex: "soPha",
            key: "soPha",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 100,
          },
          {
            title: "Tên công tơ",
            dataIndex: "tenCongTo",
            key: "tenCongTo",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            // sorter: true,
            width: 180,
          },
          {
            title: "Mã hiệu công tơ",
            dataIndex: "maHieuCongTo",
            key: "maHieuCongTo",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            // sorter: true,
            width: 120,
          },
          {
            title: "Hồ sơ có trở ngại",
            dataIndex: "hoSoCoTroNgai",
            key: "hoSoCoTroNgai",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 150,
          },
          {
            title: "Hồ sơ trả lại",
            dataIndex: "hoSoCoTraLai",
            key: "hoSoCoTraLai",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Số lượng hồ sơ xác định chủ thể",
            dataIndex: "soLuongHoSoXNCT",
            key: "soLuongHoSoXNCT",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Số lượng hồ sơ xác nhận địa điểm mua điện",
            dataIndex: "soLuongHoSoXNDDMD",
            key: "soLuongHoSoXNDDMD",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Số lượng ảnh hiện trường",
            dataIndex: "soLuongAnhHienTruong",
            key: "soLuongAnhHienTruong",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Số lượng ảnh còn lại",
            dataIndex: "soLuongAnhConLai",
            key: "soLuongAnhConLai",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Ngày đăng ký",
            dataIndex: "ngayDangKy",
            key: "ngayDangKy",
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Ngày giao khảo sát",
            dataIndex: "ngayGiaoKS",
            key: "ngayGiaoKS",
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },

          {
            title: "Ngày hoàn thành khảo sát",
            dataIndex: "ngayHoanThanhKS",
            key: "ngayHoanThanhKS",
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Ngày hoàn thành PACĐ",
            dataIndex: "ngayHoanThanhPACD",
            key: "ngayHoanThanhPACD",
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Ngày PKD duyệt PACĐ",
            dataIndex: "ngayPKDDuyet",
            key: "ngayPKDDuyet",
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Ngày Lãnh đạo duyệt PACĐ",
            dataIndex: "ngayLDDuyet",
            key: "ngayLDDuyet",
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Ngày lập phiếu thu",
            dataIndex: "ngayLapPhieuThu",
            key: "ngayLapPhieuThu",
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Ngày nộp tiền",
            dataIndex: "ngayNopTien",
            key: "ngayNopTien",
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          // {
          //   title: "Ngày giao thi công",
          //   dataIndex: "ngayGiaoTC",
          //   key: "ngayGiaoTC",
          //   render: (text) => (
          //     <div className="table-text-center">
          //       <span>{text}</span>
          //     </div>
          //   ),
          //   hide: true,
          //   disabled: false,
          //   sorter: true,
          //   width: 150,
          // },
          {
            title: "Ngày thi công lắp đặt",
            dataIndex: "ngayGiaoTCLD",
            key: "ngayGiaoTCLD",
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Ngày nghiệm thu đóng điện",
            dataIndex: "ngayNghiemThu",
            key: "ngayNghiemThu",
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Ngày hoàn tất hồ sơ (CMIS)",
            dataIndex: "ngayHoanTat",
            key: "ngayHoanTat",
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Ngày kết thúc (CMIS)",
            dataIndex: "ngayKetThuc",
            key: "ngayKetThuc",
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Tổng số ngày thực hiện",
            dataIndex: "tongSoNgay",
            key: "tongSoNgay",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            sorter: true,
            width: 120,
          },
          {
            title: "Lý do chậm",
            dataIndex: "lyDoCham",
            key: "lyDoCham",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            hide: true,
            disabled: false,
            width: 200,
          },
        ];
        const newColumns = columns.filter((column) => {
          return !res.data.some((item) => {
            if (
              column.dataIndex ===
              item.key.replace(
                item.key.charAt(0),
                item.key.charAt(0).toLocaleLowerCase()
              )
            ) {
              column.hide = item.value;
              column.group = item.group;
            }
            return null;
          });
        });
        setDataColumns(newColumns);
      }
    },
  });
};
