import {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useLayoutEffect,
  // useMemo,
} from "react";
import { useTranslation } from "react-i18next";
// import { Button, Form } from "antd";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  TABINDEX_CMIS,
} from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";

import { useLocation, useSearchParams } from "react-router-dom";
import Table from "./table";
import {
  handleListData,
} from "./services";
import { Button } from "antd";
import { dongBoCMIS } from "../services";
import { Endpoint } from "@utils/endpoint";
import CheckPermission from "@components/CheckPermission";
import { tien_ich_chung } from "@permissions/m_tienichchung";

export default function Index() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dataChungLoaiCongTo, setDataChungLoaiCongTo] = useState([]);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu

  const getListChungLoaiCongTo = useCallback(() => {
    handleListData(
      setLoading,
      filterConditions,
      setDataChungLoaiCongTo,
      setTotalCount,
      parseParams
    );
  }, [filterConditions]);

  useEffect(() => {
    getListChungLoaiCongTo();
    // eslint-disable-next-line
  }, [getListChungLoaiCongTo]);

  //thay đổi pagination table

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //Khai báo hiển thị columns

  const columns = [
    {
      title: "Mã chủng loại",
      dataIndex: "maChungLoai",
      key: "maChungLoai",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "7%",
      sorter: true,
    },
    {
      title: "Loại công tơ",
      dataIndex: "loaiCongTo",
      key: "loaiCongTo",
      render: (text) => <span>{text}</span>,
      width: "15%",
      sorter: true,
    },
    {
      title: "Số pha",
      dataIndex: "soPha",
      key: "soPha",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "5%",
      sorter: true,
    },
    {
      title: "Số dây",
      dataIndex: "soDay",
      key: "soDay",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "5%",
      sorter: true,
    },
    {
      title: "Số chỉ số",
      dataIndex: "soCSo",
      key: "soCSo",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "5%",
      sorter: true,
    },
    {
      title: "Cấp chính xác",
      dataIndex: "capCXac",
      key: "capCXac",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "7%",
      sorter: true,
    },
    {
      title: "Dòng điện",
      dataIndex: "dongDien",
      key: "dongDien",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "7%",
      sorter: true,
    },
    {
      title: "Điện áp",
      dataIndex: "dienAp",
      key: "dienAp",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "7%",
      sorter: true,
    },
    {
      title: "Hệ số K",
      dataIndex: "hSoK",
      key: "hSoK",
      render: (text) => <span>{text}</span>,
      width: "10%",
      sorter: true,
    },
    {
      title: "Cấp chính xác PK",
      dataIndex: "capCXacPK",
      key: "capCXacPK",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "7%",
      sorter: true,
    },
    {
      title: "Loại TTGT",
      dataIndex: "loaiTTGT",
      key: "loaiTTGT",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "5%",
      sorter: true,
    },
    {
      title: "Loại CuDT",
      dataIndex: "loaiCuDT",
      key: "loaiCuDT",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "5%",
      sorter: true,
    },
    {
      title: "Mã chức năng",
      dataIndex: "maChucNang",
      key: "maChucNang",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
  ];

  //tìm kiếm dữ liệu

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  //hàm xóa dữ liệu

  // const handleDeleteData = useCallback((ids) => {
  //   handleRemoveData(ids, setLoading, getListChungLoaiCongTo, t);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  //hiển thị các button t

  const genExtra = () => (
    <div>
      <CheckPermission permissionCode={tien_ich_chung.m122cmis}>
        <Button
          size="small"
          type="primary"
          className="btn-bg-yellow"
          onClick={handleDongBoCMIS}
          loading={loading}
        >
          Đồng bộ CMIS
        </Button>
      </CheckPermission>
    </div>
  );

  const handleDongBoCMIS = useCallback(() => {
    const url = Endpoint.DONG_BO_CMIS + `?tabIndex=${TABINDEX_CMIS.LOAI_CTO}`;
    dongBoCMIS(url, setLoading, getListChungLoaiCongTo);
  }, [getListChungLoaiCongTo]);

  //hiển thị popup, set dữ liệu chi tiết

  // const handleOpenModal = useCallback(
  //   (row) => {
  //     if (row !== undefined) setDataDetail(row);
  //     else setDataDetail({});
  //     setVisible(!visible);
  //   },
  //   [visible]
  // );

  //đóng popup

  // const handleCloseModal = () => {
  //   setVisible(false);
  //   setLoading(false);
  // };

  //render popup

  // const showModal = useMemo(
  //   () =>
  //     visible && (
  //       <ChungLoaiCongToModal
  //         detailChungLoaiCongTo={dataDetail}
  //         closeModal={handleCloseModal}
  //         visible={visible}
  //         getListChungLoaiCongTo={getListChungLoaiCongTo}
  //         soPha={soPha}
  //         loading={loading}
  //         setLoading={setLoading}
  //         form={form}
  //         t={t}
  //       />
  //     ),
  //   // eslint-disable-next-line
  //   [visible]
  // );

  return (
    <Fragment>
      <Table
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        genExtra={genExtra}
        dataSource={dataChungLoaiCongTo}
        columns={columns}
        totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        t={t}
      />
      {/* {showModal} */}
    </Fragment>
  );
}
