import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { getErrorMessage, removeAccents } from "@utils/function";
import { authGetData, authPostData } from "@utils/request";
import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import { useCallback, useEffect, useState } from "react";

export const SelectKhuCongNghiep = (props) => {
  const {
    url,
    placeholder = "Chọn kho thiết bị",
    form,
    formKey,
    disabled,
    mode,
    setValue,
    handleDeselect,
    notClear,
    defaultValue,
  } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(typeof url === "string" ? [] : url);

  const getData = useCallback(() => {
    authGetData({
      url,
      setLoading,
      onSuccess: (res) => {
        if (res.length > 0) setData(res);
        else setData(res.data);
      },
    });
  }, [url]);

  useEffect(() => {
    if (url && typeof url === "string") {
      getData();
    }
  }, [getData, url]);

  const handleChange = useCallback(
    (value) => {
      if (form) {
        form.setFieldsValue({
          [formKey]: value,
        });
      }
      if (setValue) {
        setValue(value);
      }
    },
    [form, formKey, setValue]
  );
  return (
    <Select
      defaultValue={defaultValue}
      maxTagCount={mode === "multiple" || "tags" ? "responsive" : null}
      value={form && form.getFieldValue(formKey)}
      placeholder={placeholder}
      showSearch
      mode={mode}
      allowClear={notClear ? false : true}
      loading={loading}
      onChange={handleChange}
      onDeselect={handleDeselect}
      disabled={disabled}
      style={{ width: "100%" }}
      filterOption={(input, option) => {
        if (option && option.children) {
          return (
            removeAccents(option.children)
              .toLowerCase()
              .indexOf(removeAccents(input).toLowerCase()) >= 0
          );
        }
      }}
    >
      {data.map((item, idx) => (
        <Option
          key={idx}
          value={item.PD18_KCN1 ? item.PD18_KCN1.toString() : item.PD18_KCN1}
        >
          {item.TEN_KHUCN}
        </Option>
      ))}
    </Select>
  );
};

//create data
export const handleCreateData = (data, setLoading, getListData, form, url) => {
  authPostData({
    url: url,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        console.log(getListData);
        getListData();
        form.resetFields();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

//UpdateData
export const handleUpdateData = (
  data,
  setLoading,
  closeModal,
  getListData,
  form,
  url
) => {
  authPostData({
    url: url,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        getListData();
        closeModal();
        form.resetFields();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};
