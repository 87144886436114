import { useState, useEffect } from "react";
import { Form, Button, Modal, Row, Col, Select } from "antd";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";

const { Option } = Select;

export default function PhanCongModal(props) {
  const {
    dataDsSoGCS,
    handleChangeSo,
    visible,
    closeModal,
    onFinish,
    // loadingTenNV
  } = props;
  const [loading,] = useState(false);
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [form]);

  return (
    <Modal
      title="Gán NV GCS mặc định"
      visible={visible}
      onCancel={closeModal}
      className="modal-phan-cong"
      footer={null}
      width="50%"
    >
      {/* <LoadingComponent loading={loadingTenNV}> */}
      <FormComponent
        form={form}
        name="form-phan-cong"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          donViId: user.unitId.toString(),
        }}
      >
        <Row gutter={24}>
          <Col span={24} md={12}>
            <Form.Item name="donViId" label="Đơn vị">
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item name="maSoGCS" label="Chọn mã sổ">
              <Select onChange={handleChangeSo} mode="multiple">
                {dataDsSoGCS && dataDsSoGCS.length > 0
                  ? (
                    dataDsSoGCS.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                  )
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item name="doiId" label="Tổ đội">
              <Selection
                url={`${Endpoint.GET_TEAM_LIST}?donviId=${user.unitId}`}
                formKey="doiId"
                form={form}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                if (prevValues.doiId !== currentValues.doiId) {
                  form.setFieldsValue({
                    userId: undefined,
                  });
                }
                return prevValues.doiId !== currentValues.doiId;
              }}
            >
              {({ getFieldValue }) => {
                if (getFieldValue("doiId")) {
                  return (
                    <Form.Item name="userId" label="Nhân viên">
                      <Selection
                        url={`${Endpoint.NHAN_VIEN_THEO_DOI}?doiId=${getFieldValue(
                          "doiId"
                        )}`}
                        formKey="userId"
                        form={form}
                      />
                    </Form.Item>
                  );
                }
                return (
                  <Form.Item name="userId" label="Nhân viên">
                    <Selection
                      // url={`${Endpoint.NHAN_VIEN_THEO_DOI}?doiId=${getFieldValue(
                      //   "doiId"
                      // )}`}
                      // formKey="userId"
                      // form={form}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} justify="center">
          <Col span={12} style={{ textAlign: "right" }}>
            <Button key="back" onClick={closeModal} className="button-closed">
              Đóng lại
            </Button>
          </Col>
          <Col span={12}>
            <Button
              key="submit"
              type="primary"
              className="button-primary"
              htmlType="submit"
              form="form-phan-cong"
              loading={loading}
            >
              Lưu lại
            </Button>
          </Col>
        </Row>
      </FormComponent>
      {/* </LoadingComponent> */}
    </Modal>
  );
}
