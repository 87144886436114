/* eslint-disable no-unused-vars */
import { Button, Col, Input, Modal, Row, Table } from "antd";
import { useCallback, useEffect, useState } from "react";
import { TableStyled } from "../css-styled";

export default function ModalDanhSachDiemDoChuaNhap(props) {
  const { data, visible, close } = props;
  const [dataTable, setDaTaTable] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [paging, setPaging] = useState({
    current: 1,
    pageSize: 10,
  });
  useEffect(() => {
    setDaTaTable(data);
    setDataSource(data);
  }, [data]);
  // event search
  const onPressEnter = (event) => {
    const { keyCode, target } = event;
    const { value } = target;
    if (keyCode === 13) {
      const findItems = dataSource.filter(
        (item) =>
          item.maDiemDo.toLowerCase().includes(value.toLowerCase()) ||
          item.thongTinDiemDo.toLowerCase().includes(value.toLowerCase())
      );
      setPaging({
        current: 1,
        pageSize: 10,
      });
      setDaTaTable(findItems);
    }
  };

  const columns = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      with: "20%",
      render: (text) => <div className="table-text-left">{text}</div>,
    },
    {
      title: "Tên trạm",
      dataIndex: "tenTram",
      key: "tenTram",
      width: "20%",
      render: (text) => <div className="table-text-left">{text}</div>,
    },
    {
      title: "Tính chất điểm đo",
      dataIndex: "tinhChatDiemDo",
      key: "tinhChatDiemDo",
      width: "20%",
      render: (text) => <div className="table-text-left">{text}</div>,
    },
    {
      title: "Thông tin điểm đo",
      dataIndex: "thongTinDiemDo",
      key: "thongTinDiemDo",
      width: "20%",
      render: (text) => <div className="table-text-left">{text}</div>,
    },
    {
      title: "Đơn vị quản lý",
      dataIndex: "donViQuanLy",
      key: "donViQuanLy",
      width: "20%",
      render: (text) => <div className="table-text-left">{text}</div>,
    },
  ];

  const onChangePagination = (paging) => {
    setPaging(paging);
  };
  return (
    <Modal title="Danh sách điểm đo chưa nhập" visible={visible} onCancel={close} footer={null} width="80%">
      <Row>
        <Col span={12} style={{ marginBottom: 12 }}>
          <Input className="input" onKeyDown={onPressEnter} placeholder={"Tìm theo mã điểm đo, thông tin điểm đo"} />
        </Col>
        <Col span={24}>
          <TableStyled>
            <Table
              columns={columns}
              dataSource={dataTable}
              onChange={onChangePagination}
              pagination={{
                total: dataTable.length,
                current: paging.current,
                pageSize: paging.pageSize,
              }}
              rowKey="maDiemDo"
            />
          </TableStyled>
        </Col>
      </Row>
    </Modal>
  );
}
