import { BASE_API_URL_CAP_DIEN } from "@utils/constants";
import { BASE_API_URL } from "@utils/constants";
import { BASE_API_URL_DANH_MUC } from "@utils/constants";
export const APICommon = {
  COMMON_LIST_SO_PHA: `${BASE_API_URL_CAP_DIEN}/common/list-so-pha`,
  COMMON_LIST_TINH_TRANG_SU_DUNG: `${BASE_API_URL_CAP_DIEN}/common/list-tinh-trang-su-dung`,
  COMMON_HINH_THUC_GHI_CHI_SO: `${BASE_API_URL_CAP_DIEN}/common/list-hinh-thuc-ghi-chi-so`,
  COMMON_LIST_LOAI_HOP_DONG: `${BASE_API_URL_CAP_DIEN}/common/list-loai-hop-dong`,
  COMMON_LIST_HINH_THUC_TRA_TIEN: `${BASE_API_URL_CAP_DIEN}/common/list-hinh-thuc-tra-tien`,
  COMMON_LIST_TINH_TRANG_THU: `${BASE_API_URL_CAP_DIEN}/common/list-tinh-trang-thu`,
  COMMON_LIST_GIOI_TINH: `${BASE_API_URL_CAP_DIEN}/common/list-gioi-tinh`,
  COMMON_LIST_DON_VI_TINH: `${BASE_API_URL_CAP_DIEN}/common/list-don-vi-tinh`,
  COMMON_LIST_HINH_THUC_LAP_DAT: `${BASE_API_URL_CAP_DIEN}/common/list-hinh-thuc-lap-dat`,
  COMMON_LIST_CHUNG_LOAI_VAT_TU: `${BASE_API_URL_CAP_DIEN}/common/list-chung-loai-vat-tu`,
  COMMON_LIST_LOAI_DU_TOAN: `${BASE_API_URL_CAP_DIEN}/common/list-loai-du-toan`,
  COMMON_LIST_LOAI_CAP: `${BASE_API_URL_CAP_DIEN}/common/list-loai-cap`,
  COMMON_LIST_CONG_TAC_VAT_TU: `${BASE_API_URL_CAP_DIEN}/common/list-cong-tac-vat-tu`,
  COMMON_LIST_MUC_DICH: `${BASE_API_URL_CAP_DIEN}/common/list-muc-dich`,
  COMMON_LIST_TRANG_THAI: `${BASE_API_URL_CAP_DIEN}/common/list-trang-thai`,
  COMMON_LIST_LOAI_YEU_CAU: `${BASE_API_URL_CAP_DIEN}/common/list-loai-yeu-cau`,
  COMMON_LIST_LANH_DAO: `${BASE_API_URL}/common/list-lanh-dao`,
  COMMON_LIST_NGAN_HANG: `${BASE_API_URL_DANH_MUC}/common/list-ngan-hang`,
  COMMON_LIST_OS: `${BASE_API_URL_CAP_DIEN}/common/list-os`,
  COMMON_LIST_TINH_TRANG_YEU_CAU: `${BASE_API_URL_CAP_DIEN}/common/list-tinh-trang-tiep-nhan`,
  LIST_NGAY_CHAM_TIEN_DO: `${BASE_API_URL_CAP_DIEN}/common/list-ngay-cham-tien-do`,

  IN_BIEU_DO_PHU_TAI_CAP_DIEN: `${BASE_API_URL_CAP_DIEN}/khao-sat/in-bieu-do-phu-tai`,
};
