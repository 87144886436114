import { Fragment, useState } from "react";
import Table from "../../common/danh-sach-cong-to";
import {
  ENUM_DS_CONGTO,
  TYPE_PHANCONG,
  TYPE_TABLE_CONGTO,
} from "@utils/constants";

export default function CongViecPhucTra() {
  const [fetchList, setFetchList] = useState(false);

  const kiemTraLai = () => {};
  const columns = [
    {
      title: "Họ tên khách hàng",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Mã GCS",
      dataIndex: "maGCS",
      key: "maGCS",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Chủng loại công tơ",
      dataIndex: "chungLoaiCongTo",
      key: "chungLoaiCongTo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Đã ghi chỉ số",
      dataIndex: "daGhiChiSo",
      key: "daGhiChiSo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "5%",
      children: [
        {
          title: "Ngày ghi",
          dataIndex: "ngayGhi",
          key: "ngayGhi",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
        },
        {
          title: "Chỉ số",
          dataIndex: "chiSo",
          key: "chiSo",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
        },
      ],
    },
    {
      title: "Thực hiện phúc tra",
      dataIndex: "thucHienPhucTra",
      key: "thucHienPhucTra",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "20%",
      children: [
        {
          title: "Ngày phúc tra",
          dataIndex: "ngayPhucTra",
          key: "ngayPhucTra",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
        },
        {
          title: "Chỉ số phúc tra",
          dataIndex: "chiSoPhucTra",
          key: "chiSoPhucTra",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
        },
        {
          title: "Tình trạng bên trong hòm(hòm, cáp, mã chì hòm, đánh đai...)",
          dataIndex: "tinhTrangBenTrong",
          key: "tinhTrangBenTrong",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
        },
        {
          title: "Tình trạng hoạt động(bình thường, kẹt, chết...)",
          dataIndex: "tinhTrangHoatDong",
          key: "tinhTrangHoatDong",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
        },
      ],
    },
    {
      title: "Kết luận(kiến nghị)",
      dataIndex: "ketLuan",
      key: "ketLuan",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Tác vụ",
      key: "action",
      align: "center",
      width: "7%",
      render: (row) => (
        <div
          className="ant-btn-link cursor-pointer"
          style={{ textAlign: "center" }}
          onClick={() => kiemTraLai(row.id)}
        >
          <span> Kiểm tra lại</span>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <Table
        typeList={TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA}
        typeDS={ENUM_DS_CONGTO.PHUCTRA_NVKT}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        columnsTB={columns}
        header={"Phân công kiểm tra phúc tra"}
        isGenExtra={true}
        typePhanCong={TYPE_PHANCONG.NHANVIEN}
      />
    </Fragment>
  );
}
