import { isEqual, pick } from "lodash";

const shouldUpdateMaso = ({ prevValues, currentValues, setQueryMaso, form, setListMaso, fieldUpdate, isFilterScreen }) => {
  let listFieldCompare = [];

  if (isFilterScreen) {
    listFieldCompare = ["donViId", "kyGCS", "thangNam", "tramId", "toDoiId"];
  } else {
    listFieldCompare = ["DonViId", "KyGCS", "ThangNam", "TramId", "ToDoiId"];
  }

  const prevValuesCompare = pick(prevValues, listFieldCompare);
  const currentValuesCompare = pick(currentValues, listFieldCompare);
  if (!isEqual(prevValuesCompare, currentValuesCompare)) {
    setQueryMaso({
      ...currentValues,
      pageIndex: 1,
      pageSize: 50,
    });
    form.setFieldsValue({
      [fieldUpdate]: [],
    });
    setListMaso([]);
  }
};
export default shouldUpdateMaso;
