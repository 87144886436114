import moment from "moment";
import "moment/locale/vi";
import { METHOD_POST, STATUSCODE_200, TYPE_DONGBO } from "@utils/constants";
import { authPostData } from "@utils/request";
import { formatDDMMYYYYVN, formatYYYYMMDDString } from "@utils/function";
import { Endpoint } from "@utils/endpoint";

export const submitDongBoService = (
  typeDongBo,
  values,
  setLoading,
  setDataSync
) => {
  const ngay =
    values.ngayDongBo !== null ? moment(values.ngayDongBo) : moment();
  const convert = formatDDMMYYYYVN(ngay.year(), ngay.month(), ngay.date());
  let data = {
    ngayDongBo: convert,
  };
  let url = Endpoint.SYNC_TIEP_NHAN_YEU_CAU;
  if (typeDongBo === TYPE_DONGBO.THUTIEN) {
    url = Endpoint.DONG_BO_THU_TIEN;
    data = {
      ngayNopTien: formatYYYYMMDDString(ngay.year(), ngay.month(), ngay.date()),
    };
  }
  authPostData({
    url,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setDataSync(res.data);
      }
    },
  });
};
