import { BASE_API_URL_KT_THIETBI } from "@utils/constants";

const prefix = "/baocao";

export const APIBaoCao = {
  BC_TONG_CONG_TO: `${BASE_API_URL_KT_THIETBI}${prefix}/tong-cong-to-trong-thang`,
  BC_CT_KHONGDAT: `${BASE_API_URL_KT_THIETBI}${prefix}/cong-to-khong-dat`,
  BC_KTCT_TRONGTHANG: `${BASE_API_URL_KT_THIETBI}${prefix}/kiem-tra-cong-to-trong-thang`,

  BC_PT_NGOAIHIENTRUONG: `${BASE_API_URL_KT_THIETBI}${prefix}/phuc-tra-ngoai-hien-truong-trong-thang`,
  BC_PT_TRONGTHANG: `${BASE_API_URL_KT_THIETBI}${prefix}/phuc-tra-trong-thang`,
  BC_TRONGTHANG_THEO_NV: `${BASE_API_URL_KT_THIETBI}${prefix}/kiem-tra-thiet-bi-trong-thang-theo-nhan-vien`,
  BC_KT_THIETBI_HANGNGAY: `${BASE_API_URL_KT_THIETBI}${prefix}/bieu-kiem-tra-thiet-bi-hang-ngay`,

  BC_CT_BATTHUONG: `${BASE_API_URL_KT_THIETBI}${prefix}/bao-cao-bat-thuong`,
  BC_CT_DINHKY: `${BASE_API_URL_KT_THIETBI}${prefix}/bao-cao-cong-to-da-kiem-tra-dinh-ky`,
  BC_CT_PHUCTRA: `${BASE_API_URL_KT_THIETBI}${prefix}/bao-cao-cong-to-da-phuc-tra`,

  EXCEL_BC_CT_BATTHUONG: `${BASE_API_URL_KT_THIETBI}${prefix}/xuat-excel-bao-cao-bat-thuong`,
  EXCEL_BC_CT_DINHKY: `${BASE_API_URL_KT_THIETBI}${prefix}/xuat-excel-bao-cao-cong-to-da-kiem-tra-dinh-ky`,
  EXCEL_BC_CT_PHUCTRA: `${BASE_API_URL_KT_THIETBI}${prefix}/xuat-excel-bao-cao-cong-to-da-phuc-tra`,

  PDF_BC_CT_BATTHUONG: `${BASE_API_URL_KT_THIETBI}${prefix}/xuat-pdf-bao-cao-bat-thuong`,
  PDF_BC_CT_DINHKY: `${BASE_API_URL_KT_THIETBI}${prefix}/xuat-pdf-bao-cao-cong-to-da-kiem-tra-dinh-ky`,
  PDF_BC_CT_PHUCTRA: `${BASE_API_URL_KT_THIETBI}${prefix}/xuat-pdf-bao-cao-cong-to-da-phuc-tra`,
};
