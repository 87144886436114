import { APIAnhXaThongTin } from "../../api-url/giao-nhan-dien-nang/quan-ly-diem-do/anh-xa-thong-tin";
import { APIBangKe } from "../../api-url/giao-nhan-dien-nang/bang-ke";
import { APIGiaoNhanDienNang } from "../../api-url/giao-nhan-dien-nang/danh-muc";
import { APIQLChiSo } from "../../api-url/giao-nhan-dien-nang/quan-ly-chi-so";
import { APITongHopSL } from "../../api-url/giao-nhan-dien-nang/tong-hop-so-lieu";

export const GNDN_Endpoint = {
  //Danh muc
  GNDN_DANH_MUC_DON_VI_GIAO_NHAN:
    APIGiaoNhanDienNang.GNDN_DANH_MUC_DON_VI_GIAO_NHAN,
  GNDN_DANH_MUC_TRAM: APIGiaoNhanDienNang.GNDN_DANH_MUC_TRAM,
  GNDN_DS_CAP_DA: APIGiaoNhanDienNang.GNDN_DS_CAP_DA,
  // API màn hình cập nhật thông tin điểm đo
  SO_LUONG_DIEM_DO: APIGiaoNhanDienNang.SO_LUONG_DIEM_DO,
  DANH_SACH_DIEM_DO: APIGiaoNhanDienNang.DANH_SACH_DIEM_DO,
  DANH_SACH_DIEM_DO_DVK: APIGiaoNhanDienNang.DANH_SACH_DIEM_DO_DVK,
  DANH_SACH_DIEM_DO_KHONG_HD: APIGiaoNhanDienNang.DANH_SACH_DIEM_DO_KHONG_HD,
  TAO_MA_DD_NGAY_HIEU_LUC: APIGiaoNhanDienNang.TAO_MA_DD_NGAY_HIEU_LUC,
  // API MAN KHAI BAO DIEM DO
  KHAI_BAO_DIEM_DO: APIGiaoNhanDienNang.KHAI_BAO_DIEM_DO,
  THAY_DOI_DIEM_DO: APIGiaoNhanDienNang.THAY_DOI_DIEM_DO,
  XOA_DIEM_DO: APIGiaoNhanDienNang.XOA_DIEM_DO,
  NGUNG_DIEM_DO: APIGiaoNhanDienNang.NGUNG_DIEM_DO,
  // THAY DOI THONG TIN DIEM DO
  LAY_THONG_TIN_DIEM_DO: APIGiaoNhanDienNang.LAY_THONG_TIN_DIEM_DO,
  GET_ANH_XA_DIEM_DO: APIGiaoNhanDienNang.GET_ANH_XA_DIEM_DO,
  TAO_NGAY_HIEU_LUC_THAY_DOI: APIGiaoNhanDienNang.TAO_NGAY_HIEU_LUC_THAY_DOI,
  // EMD
  // LIST API GET_SELECTION KHAI BAO DIEM DO CALLBACK
  GET_SELECTION_CAP_DIEN_AP: APIGiaoNhanDienNang.GET_SELECTION_CAP_DIEN_AP,
  GET_SELECTION_HINH_THUC_TT: APIGiaoNhanDienNang.GET_SELECTION_HINH_THUC_TT,
  GET_SELECTION_HT_DIEM_DO: APIGiaoNhanDienNang.GET_SELECTION_HT_DIEM_DO,
  GET_SELECTION_TRAM: APIGiaoNhanDienNang.GET_SELECTION_TRAM,
  GET_SELECTION_DVGN: APIGiaoNhanDienNang.GET_SELECTION_DVGN,

  // API GET PHÂN QUYỀN ĐƠN VỊ

  GET_ALL_TRAM: APIGiaoNhanDienNang.GET_ALL_TRAM,
  GET_LIST_ANH_XA_DIEM_DO: APIGiaoNhanDienNang.GET_LIST_ANH_XA_DIEM_DO,
  GET_ALL_DONVI_GIAONHAN: APIGiaoNhanDienNang.GET_ALL_DONVI_GIAONHAN,
  GET_DS_PHANQUYEN_DIEM_DO: APIGiaoNhanDienNang.GET_DS_PHANQUYEN_DIEM_DO,
  POST_CHANGE_TRAM: APIGiaoNhanDienNang.POST_CHANGE_TRAM,

  //  api BIEN BAN TREO THAO
  GET_DANH_SACH_DIEM_DO: APIGiaoNhanDienNang.GET_DANH_SACH_DIEM_DO,
  GET_CHECK_VA_GET_BIEN_BAN: APIGiaoNhanDienNang.GET_CHECK_VA_GET_BIEN_BAN,
  POST_CONG_TO: APIGiaoNhanDienNang.POST_CONG_TO,
  POST__TAO_BIEN_BAN: APIGiaoNhanDienNang.POST__TAO_BIEN_BAN,
  DELETE_BIEN_BAN: APIGiaoNhanDienNang.DELETE_BIEN_BAN,
  PUT_SUA_BIEN_BAN: APIGiaoNhanDienNang.PUT_SUA_BIEN_BAN,
  POST_LAY_BIEN_BAN_TU_CMIS: APIGiaoNhanDienNang.POST_LAY_BIEN_BAN_TU_CMIS,

  // api lấy thông tin
  LAY_LICH_SU_DIEM_DO: APIGiaoNhanDienNang.LAY_LICH_SU_DIEM_DO,
  LAY_LICH_SU_PHUONG_THUC: APIGiaoNhanDienNang.LAY_LICH_SU_PHUONG_THUC,
  LAY_CHI_SO_LS_PHUONG_THUC: APIGiaoNhanDienNang.LAY_CHI_SO_LS_PHUONG_THUC,
  LAY_PHUONG_THUC_TAB: APIGiaoNhanDienNang.LAY_PHUONG_THUC_TAB,
  THAY_DOI_PHUONG_THUC: APIGiaoNhanDienNang.THAY_DOI_PHUONG_THUC,
  SUA_DOI_PHUONG_THUC: APIGiaoNhanDienNang.SUA_DOI_PHUONG_THUC,
  HUY_PHUONG_THUC: APIGiaoNhanDienNang.HUY_PHUONG_THUC,
  LAY_CHI_SO_THAY_DOI_PHUONG_THUC:
    APIGiaoNhanDienNang.LAY_CHI_SO_THAY_DOI_PHUONG_THUC,
  LAY_CHI_SO_SUA_PHUONG_THUC: APIGiaoNhanDienNang.LAY_CHI_SO_SUA_PHUONG_THUC,
  LAY_PHUONG_THUC_MAN_SUA: APIGiaoNhanDienNang.LAY_PHUONG_THUC_MAN_SUA,
  // API Anh Xa Thong Tin
  POST_ANH_XA_THONG_TIN: APIGiaoNhanDienNang.POST_ANH_XA_THONG_TIN,
  LAY_CHI_SO_DOXA_ANHXA: APIGiaoNhanDienNang.LAY_CHI_SO_DOXA_ANHXA,
   

  // API BÁO CÁO

  PDF_CHITIET_DNGN: APIGiaoNhanDienNang.PDF_CHITIET_DNGN,
  EXCEL_CHITIET_DNGN: APIGiaoNhanDienNang.EXCEL_CHITIET_DNGN,

  PDF_TONGHOP_DNGN: APIGiaoNhanDienNang.PDF_TONGHOP_DNGN,
  EXCEL_TONGHOP_DNGN: APIGiaoNhanDienNang.EXCEL_TONGHOP_DNGN,

  PDF_CHITIET_CSCT_DNGN: APIGiaoNhanDienNang.PDF_CHITIET_CSCT_DNGN,
  EXCEL_CHITIET_CSCT_DNGN: APIGiaoNhanDienNang.EXCEL_CHITIET_CSCT_DNGN,

  PDF_BB_QTDN_PHANKHANG: APIGiaoNhanDienNang.PDF_BB_QTDN_PHANKHANG,
  EXCEL_BB_QTDN_PHANKHANG: APIGiaoNhanDienNang.EXCEL_BB_QTDN_PHANKHANG,

  PDF_SAISO_TRAM110: APIGiaoNhanDienNang.PDF_SAISO_TRAM110,
  EXCEL_SAISO_TRAM110: APIGiaoNhanDienNang.EXCEL_SAISO_TRAM110,

  PDF_CTDN_THEOTRAM: APIGiaoNhanDienNang.PDF_CTDN_THEOTRAM,
  EXCEL_CTDN_THEOTRAM: APIGiaoNhanDienNang.EXCEL_CTDN_THEOTRAM,

  DANHSACH_CHITIET_SANLUONG: APIGiaoNhanDienNang.DANH_SACH_CHITIET_SANLUONG,
  PDF_CHITIET_SANLUONG: APIGiaoNhanDienNang.PDF_CHITIET_SANLUONG,
  EXCEL_CHITIET_SANLUONG: APIGiaoNhanDienNang.EXCEL_CHITIET_SANLUONG,

  DANHSACH_DDTT_TRONGTHANG: APIGiaoNhanDienNang.DANHSACH_DDTT_TRONGTHANG,
  PDF_DDTT_TRONGTHANG: APIGiaoNhanDienNang.PDF_DDTT_TRONGTHANG,
  EXCEL_DDTT_TRONGTHANG: APIGiaoNhanDienNang.EXCEL_DDTT_TRONGTHANG,

  PDF_SL_DAUNGUON_BAGIA: APIGiaoNhanDienNang.PDF_SL_DAUNGUON_BAGIA,
  EXCEL_SL_DAUNGUON_BAGIA: APIGiaoNhanDienNang.EXCEL_SL_DAUNGUON_BAGIA,

  PDF_CHITIET_DN_TUDUNG: APIGiaoNhanDienNang.PDF_CHITIET_DN_TUDUNG,
  EXCEL_CHITIET_DN_TUDUNG: APIGiaoNhanDienNang.EXCEL_CHITIET_DN_TUDUNG,

  PDF_BB_QTDN_GIAONHAN: APIGiaoNhanDienNang.PDF_BB_QTDN_GIAONHAN,
  EXCEL_BB_QTDN_GIAONHAN: APIGiaoNhanDienNang.EXCEL_BB_QTDN_GIAONHAN,

  ...APIBangKe,
  ...APIQLChiSo,
  ...APITongHopSL,

  ...APIAnhXaThongTin,
};
