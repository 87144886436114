import React, { memo, useState } from "react";
import { Form } from "antd";
import TraLaiBangKe from "./modal-tra-lai-bang-ke";
import { handleHuyBangKe } from "./services";

function Index({
  visibleTraLai,
  handleCloseModal,
  selectedRowKeys,
  setFetchList,
  setFetchDataTable,
  typeDS,
  id,
  content
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // call api hủy bảng kê

  const onFinish = (values) => {
    const data = {
      ...values,
      maLoaiDanhSach: typeDS,
      bangKeId: id,
    };

    handleHuyBangKe(
      setLoading,
      setFetchDataTable,
      handleCloseModal,
      data,
      form
    );
  };

  return (
    <TraLaiBangKe
      loading={loading}
      visibleTraLai={visibleTraLai}
      handleCloseModal={handleCloseModal}
      selectedRowKeys={selectedRowKeys}
      setFetchList={setFetchList}
      onFinish={onFinish}
      form={form}
      content={content}
    />
  );
}

export default memo(Index);
