import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";

import { Button } from "antd";
import { tien_ich_chung } from "@permissions/m_tienichchung";
import CheckPermission from "@components/CheckPermission";
import HinhThucLapDatModal from "./modal-hinh-thuc-lap-dat";
import { useTranslation } from "react-i18next";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import { handleListData, handleRemoveData } from "./services";
import Table from "./table";
import { CheckCircleFilled } from "@ant-design/icons";

export default function Index() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [detailHinhThuc, setDetailHinhThuc] = useState({});
  const [dataHinhThuc, setDataHinhThuc] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu

  const getListHinhThuc = useCallback(() => {
    handleListData(
      setLoading,
      filterConditions,
      setDataHinhThuc,
      setTotalCount
    );
  }, [filterConditions]);

  useEffect(() => {
    getListHinhThuc();
    // eslint-disable-next-line
  }, [getListHinhThuc]);

  //thay đổi pagination table

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //hiển thị các button thêm,...

  const genExtra = () => (
    <div>
      <CheckPermission permissionCode={tien_ich_chung.m118t}>
        <Button
          size="small"
          type="primary"
          className="mr-5px"
          onClick={() => handleOpenModal()}
        >
          {t("button.tao_moi")}
        </Button>
      </CheckPermission>
    </div>
  );

  const formatter = (value) =>
    value !== null ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;

  //khai bái columns

  const columns = [
    {
      title: "Mã hình thức",
      dataIndex: "maHinhThuc",
      key: "maHinhThuc",
      sorter: true,
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: "Tên hình thức",
      dataIndex: "tenHinhThuc",
      key: "tenHinhThuc",
      sorter: true,
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "Bên cung cấp cáp chính",
      dataIndex: "benCungCapCapChinh",
      key: "benCungCapCapChinh",
      sorter: true,
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "Bên cung cấp phụ kiện",
      dataIndex: "benCungCapPhuKien",
      key: "benCungCapPhuKien",
      sorter: true,
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "Bên cung cấp nhân công lắp đặt",
      dataIndex: "benCungCapNhanCongLapDat",
      key: "benCungCapNhanCongLapDat",
      sorter: true,
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "Tổng chi phí",
      dataIndex: "tongChiPhi",
      key: "tongChiPhi",
      sorter: true,
      render: (text) => (
        <div className="table-text-right">
          <span>{formatter(text)}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "Trạng thái",
      dataIndex: "isActive",
      key: "isActive",
      render: (actived) => (
        <div className="table-text-center">
          {actived ? (
            <CheckCircleFilled style={{ fontSize: "20px", color: "#03A4FF" }} />
          ) : null}
        </div>
      ),
      width: "9%",
    },
    {
      title: t("tac_vu"),
      key: "action",
      fixed: "right",
      render: (row) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={tien_ich_chung.m118s}>
            <div className="action-icon" onClick={() => handleOpenModal(row)}>
              <PencilIcon />
            </div>
          </CheckPermission>
          <CheckPermission permissionCode={tien_ich_chung.m118x}>
            <div
              className="action-icon"
              onClick={() => handleDeleteData(row.id)}
            >
              <TrashIcon />
            </div>
          </CheckPermission>
        </div>
      ),
    },
  ];

  //tìm kiếm dữ liệu

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  //xóa dữ liệu

  const handleDeleteData = useCallback(
    (ids) => {
      handleRemoveData(ids, setLoading, getListHinhThuc, t);
    },
    [getListHinhThuc, t]
  );

  //hiển thị popup, set dữ liệu chi tiết

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDetailHinhThuc(row);
      else setDetailHinhThuc({});
      setVisible(!visible);
    },
    [visible]
  );

  //đóng popup

  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
  };

  //render popup

  const showModal = useMemo(
    () =>
      visible && (
        <HinhThucLapDatModal
          detailHinhThuc={detailHinhThuc}
          visible={visible}
          closeModal={handleCloseModal}
          getListHinhThuc={getListHinhThuc}
        />
      ),
    // eslint-disable-next-line
    [visible]
  );

  return (
    <Fragment>
      <Table
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        genExtra={genExtra}
        dataSource={dataHinhThuc}
        columns={columns}
        totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
      />
      {showModal}
    </Fragment>
  );
}
