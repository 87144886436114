import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { Row } from "antd";
import { Container } from "../../css-styled";
import ThongTinChungPanel from "../../panels/thong-tin-chung";
import ThongTinKiemTraPanel from "../../panels/thong-tin-kiem-tra";

export default function ThongTinCongToChiTiet(props) {
  // eslint-disable-next-line no-unused-vars
  const {
    form,
    loading,
    typeSoPha,
    typeList,
    columnsPT,
    dataTable,
    dataDetail,
  } = props;
  return (
    <LoadingComponent loading={loading}>
      <FormComponent
        autoComplete="off"
        form={form}
        name="form-chi-tiet-cong-to"
        initialValues={{}}
        layout="vertical"
        onValuesChange={false}
        scrollToFirstError
      >
        <Container>
          <Row gutter={24}>
            <ThongTinChungPanel
              typeSoPha={typeSoPha}
              typeList={typeList}
              scroll={{ y: 520 }}
              pagination={false}
            />
            <ThongTinKiemTraPanel
              typeSoPha={typeSoPha}
              typeList={typeList}
              columnsPT={columnsPT}
              dataTable={dataTable}
              dataDetail={dataDetail}
            />
          </Row>
        </Container>
      </FormComponent>
    </LoadingComponent>
  );
}
