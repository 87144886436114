import React, { useEffect, useRef } from "react";
import LoadingComponent from "@components/Loading";
import { Button, Collapse, Row } from "antd";
import FormComponent from "@components/Form";
import { TYPE_PHIEUXUAT_CHUCNANG } from "@utils/constants";
import ThongTinChung from "./panels/thong-tin-chung";
import DSVatTuTable from "./panels/danh-sach-vt";
import DSVatTuLienQuanTable from "./panels/danh-sach-vt-lien-quan";
import { closeModal } from "@state/system-config/reducer";
import { scrollToTop } from "@utils/function";

const { Panel } = Collapse;

export default function LayoutPhieuXuat(props) {
  const {
    loading,
    form,
    onFinishSubmit,
    type,
    dataVT,
    columns,
    onSearch,
    dataDSYCLQ,
    columnsLQ,
    dataVTLQ,
    //doi,
    nguoiTao,
    handleCreateLQ,
    valueSearch,
    tenLoaiTT,
    ngayTao,
    tenDoi,
    lyDo,
    dispatch,
    disabled,
    isTraLai,
    nguoiSuaPhieu,
  } = props;
  const ref1 = useRef();

  useEffect(() => {
    scrollToTop(ref1);
  }, []);
  return (
    <LoadingComponent loading={loading}>
      <div ref={ref1}>
        <FormComponent
          form={form}
          name="form-submit"
          layout="vertical"
          onFinish={onFinishSubmit}
          autoComplete="off"
        >
          <Collapse defaultActiveKey={["1", "2", "3"]}>
            <Panel header="Thông tin chung" key="1">
              <ThongTinChung
                form={form}
                type={type}
                nguoiTao={nguoiTao}
                tenLoaiTT={tenLoaiTT}
                ngayTao={ngayTao}
                tenDoi={tenDoi}
                lyDo={lyDo}
                isTraLai={isTraLai}
                nguoiSuaPhieu={nguoiSuaPhieu}
              />
            </Panel>
            <Panel header="Danh sách vật tư" key="2">
              <DSVatTuTable
                //components={components}
                dataVT={dataVT}
                columns={columns}
              />
            </Panel>
            <Panel header="Danh sách yêu cầu liên quan" key="3">
              <DSVatTuLienQuanTable
                onSearch={onSearch}
                dataVTLQ={dataVTLQ}
                columnsLQ={columnsLQ}
                dataDSYCLQ={dataDSYCLQ}
                handleCreateLQ={handleCreateLQ}
                valueSearch={valueSearch}
                disabled={disabled}
              />
            </Panel>
          </Collapse>
          <Row gutter={24} justify="center" style={{ marginTop: "48px" }}>
            <Button
              key="back"
              onClick={() => dispatch(closeModal())}
              className="button-closed mr-5px"
            >
              Đóng lại
            </Button>

            <Button
              type="primary"
              key="submit"
              className="button-closed mr-5px"
              loading={loading}
              htmlType="submit"
              form="form-submit"
            >
              {type === TYPE_PHIEUXUAT_CHUCNANG.THAYDOI
                ? "Cập nhật"
                : "Tạo mới"}
            </Button>
          </Row>
        </FormComponent>
      </div>
    </LoadingComponent>
  );
}
