import { Endpoint } from "@utils/endpoint";

import { authGetData } from "@utils/request";

export const getDanhSachDuyet = (
  setLoading,
  setData,
  query,
  filterConditions,
  setTotal
) => {
  authGetData({
    url: `${Endpoint.NVGS_DANH_SACH_YEU_CAU}?${query}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              STT:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            };
          })
        );
        setTotal(res.paging.totalCount);
      }
    },
  });
};
