import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import LoadingComponent from "@components/Loading";
import { ColCustomCheck } from "./css-styled";
import { TYPE_THIET_BI } from "@utils/constants";
import ThongTinChung from "../thong-tin-chung";
import { Fragment } from "react";

const { Option } = Select;
const { Search } = Input;

export default function ThongTinTreoTabs(props) {
  const {
    detailCTTreo,
    detailCTThao,
    form,
    handleDeleteCongTo,
    isChanged,
    handleChanged,
    loading,
    arrayLoaiDiemDo,
    renderTable,
    changeDD,
    handleChangeLDD,
    isLT,
    getListCongToCMIS,
    isSoNhan,
    renderDSCT,
    isDelCT,
  } = props;

  return (
    <LoadingComponent loading={loading}>
      <Row gutter={24}>
        <Col span={24}>
          <Row gutter={24}>
            {/* <Col
              span={8}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "12px",
              }}
            >
              <h6 className="fs-14px c-t-label">
                Loại công tơ hiện tại{" "}
                <span
                  className="fs-16px font-bold ml-24px"
                  style={{ color: "#0000FF" }}
                >
                  {thongTinHienTai?.loaiCongTo}
                </span>
              </h6>
            </Col>

            <Col
              span={4}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "12px",
              }}
            >
              <h6 className="fs-14px c-t-label">
                Số pha{" "}
                <span
                  className="fs-16px font-bold ml-24px"
                  style={{ color: "#0000FF" }}
                >
                  {thongTinHienTai?.soPha}
                </span>
              </h6>
            </Col>
            <Col
              span={4}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "12px",
              }}
            >
              <h6 className="fs-14px c-t-label">
                VH Công{" "}
                <span
                  className="fs-16px font-bold ml-24px"
                  style={{ color: "#0000FF" }}
                >
                  {thongTinHienTai?.vhCong}
                </span>
              </h6>
            </Col> */}
            <Col span={16}></Col>
            <Col
              span={8}
              style={{
                textAlign: "right",
                marginBottom: "12px",
              }}
            >
              <Button
                danger
                onClick={handleDeleteCongTo}
                style={{ width: "50%", margin: "0 auto" }}
                disabled={!detailCTTreo?.maCongTo}
              >
                Xóa công tơ
              </Button>
            </Col>
            <ThongTinChung
              detail={detailCTTreo}
              formThao={false}
              form={form}
              isDelCT={isDelCT}
            />
          </Row>

          <Row gutter={24}>
            
              <Fragment>
                <ColCustomCheck span={5}>
                  <Form.Item
                    label="Thay đổi lại điểm đo"
                    name="isThayDoiLoaiDiemDo"
                  >
                    <Checkbox
                      value={isChanged}
                      onChange={(e) => handleChanged(e, 1)}
                    />
                  </Form.Item>
                </ColCustomCheck>
                <Col span={19} md={8}>
                  <Form.Item
                    name="loaiDiemDo"
                    style={{ width: "100%", margin: 0 }}
                  >
                    <Select
                      value={changeDD}
                      placeholder="Chọn loại điểm đo"
                      disabled={!isChanged}
                      onChange={handleChangeLDD}
                    >
                      {arrayLoaiDiemDo.map((item, index) => (
                        <Option key={index} value={item.value.toString()}>
                          {`${item.value} - ${item.name}`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Fragment>

            <Col span={24} md={11}>
              <Form.Item name="qrCode" style={{ margin: 0 }}>
                <Search
                  disabled={isLT}
                  placeholder="Nhập số công tơ"
                  allowClear={false}
                  enterButton="Tìm"
                  onSearch={(value) =>
                    getListCongToCMIS(value, TYPE_THIET_BI.CT)
                  }
                  onKeyDown={(e) =>
                    e.keyCode === 13 ? e.preventDefault() : ""
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12} md={5}>
              <Form.Item
                name="lapTrinhLaiCongTo"
                style={{ marginTop: "12px", marginBottom: "12px" }}
              >
                <Checkbox
                  checked={isLT}
                  onChange={(e) => handleChanged(e, 2)}
                  disabled={detailCTThao && !detailCTThao?.boChiSos?.length}
                >
                  <span>Lập trình lại công tơ</span>
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={12} md={4}>
              <Form.Item
                name="layBoChiSoNhan"
                style={{ marginTop: "12px", marginBottom: "12px" }}
              >
                <Checkbox
                  checked={isSoNhan}
                  onChange={(e) => handleChanged(e, 3)}
                  disabled={!detailCTTreo?.boChiSos?.length}
                >
                  <span>Công tơ 2 chiều</span>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          {renderTable()}
        </Col>
      </Row>
      {renderDSCT()}
    </LoadingComponent>
  );
}
