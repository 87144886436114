import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { customColumn } from "@utils/function";
import { Fragment } from "react";
import { Container } from "./css-styled";
import ListFilter from "./list-bo-loc.jsx";

export default function BaoCaoTongHopSoPhaTable(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    onChangePagination,
    loading,
    columns,
    data,
    countTotals,
    setType,
  } = props;
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          loading={loading}
          setType={setType}
        />
      </Filter>
      <Container>
        <TableComponent
          header={"Báo cáo tổng hợp số pha"}
          loading={loading}
          onChangePagination={onChangePagination}
          scrollY={500}
          columns={customColumn(columns, filterConditions)}
          dataSource={data}
          summary={(pageData) => countTotals(pageData)}
          isNoResize
        />
      </Container>
    </Fragment>
  );
}
