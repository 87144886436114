import { BASE_API_URL_BAO_CAO } from "@utils/constants";

export const APIBCHeThongDoXa = {
  BCHTDX_TONG_HOP_DD_MAT_KET_NOI: `${BASE_API_URL_BAO_CAO}/bckd/bchtdx/bchtdx/ds-diem-do-mat-ket-noi`,
  BCHTDX_DS_DD_THANH_LY_VA_DD_THAY_THE: `${BASE_API_URL_BAO_CAO}/bckd/bchtdx/bchtdx/ds-diem-do-thanh-ly-va-diem-do-thay-the-tai-cong-ty-dien-luc`,
  BCHTDX_DANH_SACH_MA_TRAM: `${BASE_API_URL_BAO_CAO}/bckd/bchtdx/bchtdx/danh-sach-ma-tram`,
  BCHTDX_BCSL_CHUNG_LOAI_CONG_TO_DEN_HAN_THAY_DINH_KY: `${BASE_API_URL_BAO_CAO}/bckd/bchtdx/bchtdx/bao-cao-slcl-cong-to-den-han-thay-dinh-ky-theo-tram`,
  BCHTDX_BCSL_CHUNG_LOAI_CONG_TO_THEO_TRAM: `${BASE_API_URL_BAO_CAO}/bckd/bchtdx/bchtdx/slcl-cong-to-theo-tram`,
  BCHTDX_DS_CAC_DIEM_DO_CO_DL_PHUC_VU_LAP_HOA_DON: `${BASE_API_URL_BAO_CAO}/bckd/bchtdx/bchtdx/ds-cac-diem-do-cos-dl-phuc-vu-lhd`,
  BCHTDX_SL_KH_LON_DA_LAP_DO_XA: `${BASE_API_URL_BAO_CAO}/bckd/bchtdx/bchtdx/slkh-lon-da-lap-do-xa-theo-5tppt`,
};
