import { Form } from "antd";
import { useCallback, useEffect, useState } from "react";
import { handleMultiBoLoc } from "./services";
import FormBoLoc from "./form-bo-loc";

export default function ListFilter(props) {
  const { handleSearch, clearFilter, filterConditions, setType } = props;
  const [, setLoading] = useState(false);
  const [loaiYeuCau, setLoaiYeuCau] = useState([]);
  const [form] = Form.useForm();
  const [mucDich, setMucDich] = useState([]);
  const [hinhThucLapDat, setHinhThucLapDat] = useState([]);

  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);

  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  useEffect(() => {
    handleMultiBoLoc(setLoading, setLoaiYeuCau, setMucDich, setHinhThucLapDat);
  }, []);

  return (
    <FormBoLoc
      form={form}
      handleSearch={handleSearch}
      loaiYeuCau={loaiYeuCau}
      filterConditions={filterConditions}
      clear={clear}
      mucDich={mucDich}
      hinhThucLapDat={hinhThucLapDat}
      setType={setType}
    />
  );
}
