import { HIDE_SELECT_APGIA } from "@utils/constants";
import { removeAccents } from "@utils/function";
import { Button, Col, Row, Select, Tabs } from "antd";
import { Fragment } from "react";
import ListImageTab from "../../../../common-ho-so/tai-anh-ho-so-khach-hang/tabs/tab-danh-sach-anh";
import { ContainerDetail } from "../../css-styled";
import ApGiaHoSoTabs from "../../tabs/ap-gia-ho-so";
const { TabPane } = Tabs;
const { Option } = Select;
export default function ApGiaHoSoDetail(props) {
  const {
    t,
    loading,
    detailApGia,
    detail,
    closePopup,
    handleSubmit,
    loadingSubmit,
    dataLoaiGT,
    firstData,
    handleChangeType,
    callback,
    indexTab,
    dataImages,
    listId,
  } = props;

  return (
    <Fragment>
      <ContainerDetail className="modal-body">
        <Tabs defaultActiveKey={indexTab} onChange={callback}>
          <TabPane tab={t("ap_gia_ho_so.ap_gia_ho_so")} key="1">
            <ApGiaHoSoTabs
              loading={loading}
              detailApGia={detailApGia}
              detail={detail}
            />
          </TabPane>
          <TabPane tab={t("ap_gia_ho_so.anh_hien_truong")} key="2">
            <Row gutter={24} style={{ marginTop: "24px" }}>
              <Col span={8}>
                <div style={{ marginTop: "4px" }}>
                  <span>
                    {t("giao_dich_vien.tiep_nhan_phan_cong.chon_loai_giay_to")}
                  </span>
                </div>
              </Col>
              <Col span={16} md={8}>
                <Select
                  onChange={handleChangeType}
                  defaultValue={firstData}
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {dataLoaiGT && dataLoaiGT.length
                    ? dataLoaiGT.map((item, index) => (
                        <Option value={item.id} key={index}>
                          {item.tenLoaiHoSo}
                        </Option>
                      ))
                    : null}
                </Select>
              </Col>
            </Row>
            <ListImageTab
              loading={loading}
              dataLoaiGT={dataLoaiGT}
              firstLoaiGT={firstData}
              handleChangeType={handleChangeType}
              dataImages={dataImages}
              type={HIDE_SELECT_APGIA}
              listId={listId}
            />
          </TabPane>
        </Tabs>
      </ContainerDetail>

      <div className="ant-modal-footer text-center">
        <Button type="default" onClick={closePopup}>
          {t("button.dong_lai")}
        </Button>
        {indexTab === "1" ? (
          <Button
            type="primary"
            className
            onClick={() => handleSubmit()}
            loading={loadingSubmit}
          >
            {t("ap_gia_ho_so.xac_nhan_ap_gia")}
          </Button>
        ) : null}
      </div>
    </Fragment>
  );
}
