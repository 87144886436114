import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData, authDeleteData } from "@utils/request";
import {
  buildQueryString,
  parseParams,
  getErrorMessage,
  alertMessage,
  formatYYYYMMDDString,
} from "@utils/function";
import moment from "moment";

// get list data Chung Thu
export const handleListDataQLChungThu = (
  setLoading,
  location,
  filterConditions,
  setDataQLChungThu,
  setTotalCount
) => {
  authGetData({
    // url: `${Endpoint.LIST_CHUNG_THU}?${buildQueryString(
    //   parseParams(location.search)
    // )}`,
    url: `${Endpoint.LIST_CHUNG_THU}?${buildQueryString(
      Object.keys(parseParams(location.search)).length > 0
        ? parseParams(location.search)
        : filterConditions
    )}`,
    setLoading,
    onSuccess: (res) => {
      const base="data:image/png;base64,"
      if (res.data !== null) {
        res.data.forEach(function (row, i) {
          if (row.chuKy !== null && row.chuKy.substring(0, row.chuKy.indexOf(',')) + 1 !== base) {
            row.chuKy = base.concat(res.data[i].chuKy);
          }
        });
        setDataQLChungThu(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataQLChungThu([]);
    },
  });
};

// create data Chung Thu
export const handleCreateChungThu = (
  data,
  setLoading,
  getListDataChungThu,
  closeModal,
  form
) => {
  authPostData({
    url: Endpoint.CREATE_CHUNG_THU,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        closeModal();
        getListDataChungThu();
      } else {
        const message = JSON.parse(res.message)[0]?.value || "";
        getErrorMessage(res, form);
        alertMessage("error", "Thông báo !", message)
      }
    },
  });
};

//update data Chung Thu
const handleUpdateChungThu = (
  data,
  setLoading,
  closeModal,
  getListDataChungThu,
  form
) => {
  authPostData({
    url: Endpoint.UPDATE_CHUNG_THU,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListDataChungThu();
        closeModal();
      } else {
        const message = JSON.parse(res.message)[0]?.value || "";
        getErrorMessage(res, form);
        alertMessage("error", "Thông báo !", message)
      }
    },
  });
};

//remove data Chung Thu
export const handleRemoveChungThu = (
  ids,
  setLoading,
  getListDataChungThu,
  t
) => {
  authDeleteData({
    url: Endpoint.REMOVE_CHUNG_THU + "?id=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) getListDataChungThu();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

//submit form
export const handleSubmitForm = (
  values,
  detailDataTable,
  setLoading,
  closeModal,
  getListDataChungThu,
  form,
  unitId,
  ngayBatDau,
  signature
) => {
  const data = {
    ...values,
    donViId: unitId,
    chuKy: signature
  };
  const from = moment(values.ngayBatDau ? values.ngayBatDau : ngayBatDau);
  data.ngayBatDau = formatYYYYMMDDString(
    from.year(),
    from.month(),
    from.date()
  );

  const to = moment(values.ngayKetThuc);
  if (moment(to).isValid()) {
    data.ngayKetThuc = formatYYYYMMDDString(to.year(), to.month(), to.date());
  } else data.ngayKetThuc = null;

  if (detailDataTable.id) {
    data.id = detailDataTable.id;
  }

  handleCreateUpdate(data, setLoading, closeModal, getListDataChungThu, form);
};

// create/update dữ liệu
const handleCreateUpdate = (
  data,
  setLoading,
  closeModal,
  getListDataChungThu,
  form
) => {
  if (data.id) {
    //sửa dữ liệu
    handleUpdateChungThu(
      data,
      setLoading,
      closeModal,
      getListDataChungThu,
      form
    );
  } else {
    //tạo dữ liệu
    handleCreateChungThu(
      data,
      setLoading,
      closeModal,
      getListDataChungThu,
      form
    );
  }
};
