import { Fragment, useMemo, useState } from "react";
import LamMoiHoSoModal from "../../common-ho-so/lam-moi-ho-so";
import ListMenu from "./list-menu";

export default function ListChucNang(props) {
  const { data, setFetchDataTable, setIsDoubleClick, detail } = props;
  const [visible, setVisible] = useState(false);

  const handleOpenModal = () => {
    setVisible(!visible);
    setIsDoubleClick(false);
  };

  const handleCloseModal = () => {
    setVisible(false);
    setIsDoubleClick(true);
  };

  const showModal = useMemo(
    () =>
      visible && (
        <LamMoiHoSoModal
          visible={visible}
          closeModal={handleCloseModal}
          data={data}
          setFetchDataTable={setFetchDataTable}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, setFetchDataTable, visible]
  );
  return (
    <Fragment>
      <ListMenu handleOpenModal={handleOpenModal} detail={detail} />
      {showModal}
    </Fragment>
  );
}
