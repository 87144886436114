import { Tabs } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  padding: 0px 24px;
`;

export const ButtonClose = styled.div`
  margin-top: 32px;
  text-align: center;
`;

export const CollapseContainer = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px;
`;
export const TabContainer = styled.div`
  margin-top: 20px;
  .ant-tabs-nav {
    background: #fafafa;
    font-weight: bold;
    padding: 0 16px;
  }

  .ant-tabs-content-holder {
    padding: 16px;
  }

  .ant-collapse-content-box {
    padding: 16px !important;
  }
`;

export const ContainerCheckBox = styled.div`
  margin-bottom: 15px;
`;

export const TabsDetailContainer = styled(Tabs)`
  padding: 10px;
`;
