import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import { authSelector } from "@state/auth";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { handleDownload, handleOpenPdf, selectBcthsms } from "../service";

export default function Bieu1(props) {
  const { screen, handleChange } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);
  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Tin nhắn mo_mt
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              initialValues={{ reportType: screen, unitId: user.user.unitCode }}
              colon={false}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
            >
              <Form.Item
                name="reportType"
                label="Loại báo cáo"
                labelAlign="left"
                required
              >
                <Select
                  options={selectBcthsms}
                  onChange={() =>
                    handleChange(form.getFieldValue("reportType"))
                  }
                ></Select>
              </Form.Item>

              <Form.Item
                name="TuNgay"
                label="Ngày bắt đầu"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="Day"
                  formKey="TuNgay"
                  form={form}
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>

              <Form.Item
                name="DenNgay"
                label="Ngày kết thúc"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="Day"
                  formKey="DenNgay"
                  form={form}
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>

              <Row>
                <Col span={4}></Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>
                </Col>
                <Col>
                  {/* <Button
                    type="primary"
                    loading={loading}
                    className="ml-20px"
                    onClick={() => {
                      handleOpenPdf(form.getFieldValue(), setLoading);
                    }}
                  >
                    In báo cáo
                  </Button> */}
                </Col>
              </Row>
            </FormComponent>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
