import { Col } from "antd";
import styled from "styled-components";

export const NgayXuatKho = styled(Col)`
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 24px;
  label {
    margin-right: 12px;
    width: 25%;
  }
  .ant-picker {
    width: 100%;
  }
`;
