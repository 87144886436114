import { numberWithNoSpaces, numberWithSpaces } from "@utils/function";
import { useEffect } from "react";

const { Input } = require("antd");
const { useState } = require("react");

const NumericInput = (props) => {
  const { onChange, disabledfield, digit, isNegativeValue, value = "" } = props;
  const [valueInput, setValueInput] = useState();

  useEffect(() => {
    setValueInput(value);
  }, [value]);

  const handleBlur = (event, record) => {
    if (disabledfield) {
      return;
    }
    const { value } = event.target;

    setValueInput(numberWithSpaces(value));
  };
  const handleChange = (e) => {
    if (disabledfield === "true") return;
    const inputValue = numberWithNoSpaces(e.target.value);
    let regex = /^(\d*)\.?(\d){0,1}$/;

    if (digit === "true") {
      regex = /^-?[0,4]\d*(\.\d+)?$/;
    }
    if (isNegativeValue) {
      regex = /^[-]?[0-9]*\.?[0-9]*$/;
    }
    if (
      (regex.test(+inputValue) ||
        !inputValue ||
        (isNegativeValue && inputValue === "-")) &&
      !(inputValue?.split(".")[1]?.length > 1)
    ) {
      setValueInput(inputValue);
      onChange(inputValue);
    }
  };

  return (
    <Input
      style={{ textAlign: "right" }}
      {...props}
      onChange={handleChange}
      value={valueInput}
      maxLength={25}
      onBlur={(e) => {
        handleBlur(e);
      }}
      min={0}
    />
  );
};
export default NumericInput;
