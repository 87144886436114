import { buildQueryString, getErrorMessage } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData, downloadFile, authDeleteData } from "@utils/request";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { alertMessage } from "@utils/function"

export const handleListDataTram = (setLoading, setDataNhomTram, id) => {
  authGetData({
    url: `${Endpoint.LIST_TRAM_CT}?donviId=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        res.data.forEach(function (row, i) {
          row.maTram = res.data[i].name.substr(0, res.data[i].name.indexOf('-')).trim();
        });
        setDataNhomTram(res.data);
      }
      else setDataNhomTram(res.data);
    },
  });
};

export const handleListData = (
  // location,
  filterConditions,
  setLoading,
  setDataLayDuLieuCMIS,
  setTotalCount
) => {
  let url = "";
  url = `${Endpoint.AG_LIST_HS_CMIS}?${buildQueryString(
     filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        res.data.forEach(object => {
          object.key = object.mA_DDO + object.mA_KHANG + object.ngaY_HLUC  + object.chuoI_GIA;
        });
        setDataLayDuLieuCMIS(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataLayDuLieuCMIS([]);
    },
  });
};

export const submitDongBoVeWeb = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys,
  setListLayHS,
  setDataResponse
) => {
  authPostData({
    url: Endpoint.AG_DONG_BO_VE_WEB,
    method: METHOD_POST,
    payload: {
      params: selectedRowKeys
    },
    setLoading: setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setDataResponse(res.data);
        setSelectedRowKeys([]);
        setListLayHS([]);
      }
    },
  });
};

export const submitHuyYeuCau = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys,
  setListLayHS,
) => {
  authPostData({
    url: Endpoint.AG_PKD_HUY_YC,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys.toString()
    },
    setLoading: setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
        setListLayHS([]);
      }
    },
  });
};

export const exportExcel = (filterConditions, setLoading) => {
  const query = buildQueryString(filterConditions);
  downloadFile({
    endpoint: `${Endpoint.AG_XUAT_EXCEL_DONG_BO}?${query}`,
    setLoading,
  });
};

export const handleSubmitPhanCong = (
  doiId,
  setLoading,
  handleCloseModalPC,
  setFetchDataTable,
  setSelectedRowKeys,
  selectedRowKeys,
  setListIdPC,
  form

) => {
  authPostData({
    url: Endpoint.AG_PKD_PHAN_CONG,
    method: METHOD_POST,
    payload: {
      doiId: doiId,
      ids: selectedRowKeys,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModalPC();
        setSelectedRowKeys([]);
        setListIdPC([]);
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleHuyPhanCong = (id, setLoading, getListData, t) => {
  authDeleteData({
    url: `${Endpoint.AG_PKD_HUY_PHAN_CONG}/${id}`,
    setLoading,
    content: `Bạn chắc chắn muốn hủy phân công ?`,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) getListData();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};