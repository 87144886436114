import { Table, Checkbox, Row, Col } from "antd";
import { ContainerCheckBox } from "../../../../css-styled";

export default function Layout(props) {
  const { data, columns, isNameTreo, isCheck, isTU } = props;
  return (
    <Row gutter={24}>
      <Col span={24} md={24}>
        <ContainerCheckBox>
          <Checkbox
            disabled
            defaultChecked={isCheck}
            className="fs-14px font-bold"
          >
            {isNameTreo ? "Nâng hạ công suất" : isTU ? "Tháo TU" : "Tháo TI"}
          </Checkbox>
        </ContainerCheckBox>
      </Col>
      <Col span={24} md={24}>
        <Table
          dataSource={data}
          columns={columns}
          pagination={false}
          scroll={{ y: 300, x: 1000 }}
          rowKey={(obj) => obj.ma}
        />
      </Col>
    </Row>
  );
}
