import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData } from "@utils/request";

export const getDataB8_DoiSoat = (
  payload,
  setLoading,
  setDataB8,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.B8_DOI_SOAT_THEO_NGAY}?${buildQueryString(
      parseParams(payload)
    )}`,

    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataB8(
          res.data.map((item, index) => {
            return {
              ...item,
              STT: index + 1,
            };
          })
        );
        setTotalCount(res.data.paging);
      }
    },
  });
};
