import React, { Fragment } from "react";
import { authSelector } from "@state/auth/reducer";
import { useSelector } from "react-redux";
export default function CheckStatus(props) {
  const { sequenceNumberTrangThai, maTrangThai, matrix, permissionCode } =
    props;
  const { user } = useSelector(authSelector);
  let isActive = false;
  if (
    permissionCode &&
    user &&
    user.permission &&
    user.permission.length &&
    !user.permission.find((value) => value.includes(permissionCode))
  ) {
    isActive = false;
  } else {
    if (
      Array.isArray(sequenceNumberTrangThai) &&
      sequenceNumberTrangThai instanceof Array
    ) {
      sequenceNumberTrangThai.forEach((item) => {
        if (
          Array.isArray(matrix[item]) &&
          matrix[item].length &&
          matrix[item].includes(maTrangThai)
        ) {
          isActive = true;
        }
      });
    } else {
      isActive =
        Array.isArray(matrix[sequenceNumberTrangThai]) &&
        matrix[sequenceNumberTrangThai].length &&
        matrix[sequenceNumberTrangThai].includes(maTrangThai);
    }
  }

  if (!isActive) return null;
  return <Fragment>{props.children}</Fragment>;
}
