/* eslint-disable react-hooks/exhaustive-deps */
import EllipsisTooltip from "@components/EllipsisTooltip";
import FormComponent from "@components/Form";
import ModalWarningChange from "@components/Layout/ModalWarningChange";
import { notSavedPhanQuyenDiemDo } from "@state/giao-nhan-dien-nang/reducer";
import { nullToString, removeAccents, stringToSlug } from "@utils/function";
import { Button, ConfigProvider, Form, Table, Select, Transfer } from "antd";
import vn from "antd/es/locale-provider/vi_VN";
import { difference, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getData, getDataTram, onFinish } from "./services";

export default function TablePhanHeQuyenDV(props) {
  const { form, setFilterConditions, setLoading, filterConditions, loading } = props;

  const [dataTable, setDataTable] = useState([]);
  const [dataTramTCT, setDataTramTCT] = useState();
  const [dataTramToanDV, setDataTramToanDV] = useState();
  const [targetKeys, setTargetKeys] = useState();
  const [windowHeight, setWindowHeight] = useState();
  const [originData, setOriginData] = useState();

  const [listDiemDoSelected, setListDiemDoSelected] = useState([]);

  const { Option } = Select;

  const dispatch = useDispatch();

  useEffect(() => {
    setWindowHeight(window.innerHeight > 660 ? 18 : 10);
  }, []);

  const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => {
    return (
      <Transfer {...restProps}>
        {({
          direction,
          filteredItems,
          onItemSelectAll,
          onItemSelect,
          selectedKeys: listSelectedKeys,
          disabled: listDisabled,
        }) => {
          const columns = direction === "left" ? leftColumns : rightColumns;
          const rowSelection = {
            getCheckboxProps: (item) => ({
              disabled: listDisabled || item.disabled,
            }),

            onSelectAll(selected, selectedRows) {
              const treeSelectedKeys = selectedRows.filter((item) => !item.isMove).map(({ key }) => key);
              const diffKeys = selected
                ? difference(treeSelectedKeys, listSelectedKeys)
                : difference(listSelectedKeys, treeSelectedKeys);
              onItemSelectAll(diffKeys, selected);
            },
            onSelect({ key }, selected) {
              onItemSelect(key, selected);
            },
            selectedRowKeys: listSelectedKeys,
          };
          return (
            <Table
              rowSelection={rowSelection}
              pagination={{ pageSize: windowHeight }}
              loading={loading}
              columns={columns}
              dataSource={filteredItems}
              size="small"
              style={{
                pointerEvents: listDisabled ? "none" : undefined,
              }}
              onRow={({ key, disabled: itemDisabled }) => ({
                onClick: () => {
                  if (itemDisabled || listDisabled) return;
                  onItemSelect(key, !listSelectedKeys.includes(key));
                },
              })}
            />
          );
        }}
      </Transfer>
    );
  };

  useEffect(() => {
    if (originData && originData.length > 0 && originData !== targetKeys) {
      dispatch(notSavedPhanQuyenDiemDo(true));
    } else {
      dispatch(notSavedPhanQuyenDiemDo(false));
    }
  }, [originData, targetKeys, filterConditions.DonViGNId, filterConditions.TramId]);

  const leftTableColumns = [
    {
      title: "Mã điểm đo",
      align: "center",
      width: 120,
      render: (record) => (
        <EllipsisTooltip line="1" placement="topRight" title={record.tram}>
          {record.maDiemDo}
        </EllipsisTooltip>
      ),
    },
    {
      title: "Thông tin điểm đo",
      align: "center",
      render: (record) => (
        <EllipsisTooltip line="1" placement="topRight" title={record.tram}>
          {record.thongTinDiemDo}
        </EllipsisTooltip>
      ),
    },
    {
      title: "Trạm",
      align: "center",
      render: (record) => (
        <EllipsisTooltip line="1" placement="topRight" title={record.tram}>
          {record.tram}
        </EllipsisTooltip>
      ),
    },
  ];
  const rightTableColumns = [
    {
      title: "Mã điểm đo",
      align: "center",
      width: 120,
      render: (record) => (
        <EllipsisTooltip line="1" placement="topRight" title={record.tram}>
          {record.maDiemDo}
        </EllipsisTooltip>
      ),
    },
    {
      title: "Thông tin điểm đo",
      align: "center",
      render: (record) => (
        <EllipsisTooltip line="1" placement="topRight" title={record.tram}>
          {record.thongTinDiemDo}
        </EllipsisTooltip>
      ),
    },
    {
      title: "Trạm",
      align: "center",
      render: (record) => (
        <EllipsisTooltip line="1" placement="topRight" title={record.tram}>
          {record.tram}
        </EllipsisTooltip>
      ),
    },
  ];

  useEffect(() => {
    getDataTram(setDataTramTCT, setDataTramToanDV, setLoading);
  }, [setLoading]);

  useEffect(() => {
    if (filterConditions.TramId && filterConditions.TramId.length > 0) {
      getData(filterConditions, setDataTable, setTargetKeys, setOriginData, setLoading);
    }
  }, [filterConditions.TramId, filterConditions.DonViGNId, filterConditions, setLoading]);

  const [defaultValueTram, setDefaultValueTram] = useState(undefined);
  const [valueTram, setValueTram] = useState(defaultValueTram);
  const [defaultValueDonVi, setDefaultValueDonVi] = useState(undefined);
  const [valueDonVi, setValueDonVi] = useState(defaultValueTram);

  const onChangeSelectTram = (e) => {
    if (originData && originData.length > 0 && originData !== targetKeys) {
      ModalWarningChange(
        () => {
          form.setFieldsValue({
            Tram: e,
          });
          setValueTram(e);
          setDefaultValueTram(e);
          setFilterConditions((oldState) => ({
            ...oldState,
            TramId: e,
          }));
        },
        () => {
          form.setFieldsValue({
            Tram: defaultValueTram,
          });
        }
      );
    } else {
      form.setFieldsValue({
        Tram: e,
      });
      setValueTram(e);
      setDefaultValueTram(e);
      setFilterConditions((oldState) => ({
        ...oldState,
        TramId: e,
      }));
    }
  };
  const onChangeSelectDonVi = (e) => {
    if (originData && originData.length > 0 && originData !== targetKeys) {
      ModalWarningChange(
        () => {
          form.setFieldsValue({
            Tram: e,
          });
          setValueDonVi(e);
          setDefaultValueDonVi(e);
          setFilterConditions((oldState) => ({
            ...oldState,
            DonViGNId: e,
          }));
        },
        () => {
          form.setFieldsValue({
            DonViGNId: defaultValueDonVi,
          });
        }
      );
    } else {
      form.setFieldsValue({
        DonViGNId: e,
      });
      setValueDonVi(e);
      setDefaultValueDonVi(e);
      setFilterConditions((oldState) => ({
        ...oldState,
        DonViGNId: e,
      }));
    }
  };

  const onChange = (nextTargetKeys, direction) => {

    //move to left
    if (direction === "left") {
      const listItemMoveToLeft = difference(targetKeys, nextTargetKeys);

      const restOfList = listDiemDoSelected.filter((item) => {
        return !listItemMoveToLeft.includes(item)
      })

      setListDiemDoSelected([...restOfList]);
    } else {
      //move to right
      const listItemMoveToRight = difference(nextTargetKeys, targetKeys);

      setListDiemDoSelected([...listItemMoveToRight, ...listDiemDoSelected]);
    }

    setTargetKeys(nextTargetKeys);
  };

  const onChangeDiemDo = () => {
    onFinish(listDiemDoSelected, filterConditions, setDataTable, setTargetKeys, setOriginData, setLoading, setListDiemDoSelected);
  };

  return (
    <ContainerStyled>
      <Content>
        <FormComponent onFinish={onChangeDiemDo} form={form}>
          <ConfigProvider locale={vn}>
            <TableStyled id="container">
              <Title>Phân quyền đơn vị điểm đo</Title>
              <div
                style={{
                  padding: "8px 20px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <SpanText style={{ paddingBottom: 8 }}>
                  Thực hiện thay đổi đơn vị quản lý cho các điểm đo. Ấn [Cập nhật] để lưu thông tin thay đổi
                </SpanText>
                <Button type="primary" htmlType="submit" disabled={isEmpty(listDiemDoSelected)}>
                  Cập nhật
                </Button>
              </div>
              <Container>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                  }}
                >
                  <Item>
                    <TitleItem>Danh sách điểm đo toàn TCT</TitleItem>
                    <div className="flex pb10">
                      <SpanText className="minw150">Chọn trạm</SpanText>
                      <Form.Item name="Tram">
                        <Select
                          formKey="Tram"
                          onChange={onChangeSelectTram}
                          style={{ minWidth: 200 }}
                          className="mw300 ml-20px"
                          placeholder="Chọn trạm"
                          showSearch
                          filterOption={(input, option) => {
                            if (option && option.children) {
                              return (
                                removeAccents(option.children).toLowerCase().indexOf(removeAccents(input).toLowerCase()) >= 0
                              );
                            }
                          }}
                          value={valueTram}
                        >
                          {dataTramTCT && dataTramTCT.length > 0
                            ? dataTramTCT?.map((item, idx) => (
                                <Option key={idx} value={item.id ? item.id.toString() : item.id}>
                                  {item?.moTa}
                                </Option>
                              ))
                            : undefined}
                        </Select>
                      </Form.Item>
                    </div>
                  </Item>
                  <div style={{ width: "47px" }}></div>
                  <ItemRight>
                    <TitleItem>Danh sách điểm đo toàn đơn vị</TitleItem>
                    <div className="flex pb10">
                      <SpanText className="minw150">Đơn vị</SpanText>
                      <Form.Item name="DonViGNId">
                        <Select
                          style={{ minWidth: 200 }}
                          formKey="DonViGNId"
                          onChange={onChangeSelectDonVi}
                          className="mw300 ml-20px"
                          placeholder="Chọn Đơn Vị"
                          showSearch
                          filterOption={(input, option) => {
                            if (option && option.children) {
                              return (
                                removeAccents(option.children).toLowerCase().indexOf(removeAccents(input).toLowerCase()) >= 0
                              );
                            }
                          }}
                          value={valueDonVi}
                        >
                          {dataTramToanDV && dataTramToanDV?.length > 0
                            ? dataTramToanDV?.map((item, idx) => (
                                <Option key={idx} value={item.id ? item.id.toString() : item.id}>
                                  {item?.tenDonVi}
                                </Option>
                              ))
                            : undefined}
                        </Select>
                      </Form.Item>
                    </div>
                  </ItemRight>
                </div>
              </Container>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  padding: "10px",
                  width: "100%",
                }}
              >
                <TableTransfer
                  rowKey={(record) => record.id}
                  dataSource={dataTable}
                  targetKeys={targetKeys}
                  pagination={20}
                  locale={{
                    itemsUnit: "Điểm đo",
                    itemUnit: "Điểm đo",
                    searchPlaceholder: "Tìm kiếm điểm đo",
                  }}
                  showSearch
                  onChange={onChange}
                  filterOption={(inputValue, item) =>
                    stringToSlug(removeAccents(nullToString(item?.maDiemDo)))?.indexOf(
                      stringToSlug(removeAccents(nullToString(inputValue)))
                    ) !== -1 ||
                    stringToSlug(removeAccents(nullToString(item?.tram)))?.indexOf(
                      stringToSlug(removeAccents(nullToString(inputValue)))
                    ) !== -1 ||
                    stringToSlug(removeAccents(nullToString(item?.thongTinDiemDo)))?.indexOf(
                      stringToSlug(removeAccents(nullToString(inputValue)))
                    ) !== -1
                  }
                  leftColumns={leftTableColumns}
                  rightColumns={rightTableColumns}
                />
              </div>
            </TableStyled>
          </ConfigProvider>
        </FormComponent>
      </Content>
    </ContainerStyled>
  );
}

const ContainerStyled = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const TableStyled = styled.div`
  .ant-table-tbody > tr > td {
    padding: 2px !important;
  }
  .ant-form-item {
    margin: 0;
  }
  .ant-transfer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .ant-transfer-list {
    width: 100%;
  }
  .ant-transfer-operation {
    margin-left: 1%;
    margin-right: 1%;
  }
  .ant-btn-primary {
    border-color: none;
  }
  .ant-table {
    font-size: 14px !important;
  }
`;
const Content = styled.div`
  // border: 1px solid #e9e5e5;
  border-radius: 2px;
`;

const Container = styled.div`
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
`;
const Title = styled.div`
  font-size: 20px;
  padding: 0 20px;
`;
const SpanText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Item = styled.div`
  width: ${window.innerHeight > 660 ? 50 : 48.3}%;
  border: 1px solid #e9e5e5;
  border-radius: 2px;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
`;
const ItemRight = styled.div`
  width: ${window.innerHeight > 660 ? 50 : 48.3}%;
  border: 1px solid #e9e5e5;
  border-radius: 2px;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: ${window.innerHeight > 660 ? 10 : 0}px;
`;
const TitleItem = styled.div`
  font-size: 18px;
`;
