import { memo, useState, useEffect } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { getListDonViService, submitChuyenDonViService } from "./services";
import ModalChuyenDonVi from "./modal-chuyen-don-vi";

function ModalChuyenDonViKhac({
  visible,
  closeModal,
  dataDonVi,
  setFetchDataTable,
  detail,
  donViId,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [tenDonVi, setTenDonVi] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (value) => {
    submitChuyenDonViService(
      value,
      detail,
      setLoading,
      setFetchDataTable,
      closeModal,
      form
    );
  };

  useEffect(() => {
    if (visible) {
      getListDonViService(donViId, setLoading, setTenDonVi);
      form.setFieldsValue({
        donViMoiId:
          Array.isArray(dataDonVi) &&
          dataDonVi.find((rs) => rs.id === detail.donViId)
            ? detail.donViId
            : null,
      });
    }
    // eslint-disable-next-line
  }, [visible]);

  return (
    <ModalChuyenDonVi
      visible={visible}
      closeModal={closeModal}
      loading={loading}
      form={form}
      handleSubmit={handleSubmit}
      t={t}
      tenDonVi={tenDonVi}
      dataDonVi={dataDonVi}
    />
  );
}

export default memo(ModalChuyenDonViKhac);
