import { Col, Form, Row, Select, Button, Tooltip, Input } from "antd";
import DatePickerComponent from "@components/DatePicker";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import FormComponent from "@components/Form";
import { removeAccents } from "@utils/function";
import moment from "moment";
import { FORMAT_DATE, FORMAT_ENGLISH, MONTH_STRING } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import Selection from "@components/Select";

const { Option } = Select;

const dataSoPha = [
  {
    value: null,
    name: "Tất cả",
  },
  {
    value: 1,
    name: "1 Pha",
  },
  {
    value: 3,
    name: "3 Pha",
  },
];

export default function FormBoLoc(props) {
  const {
    user,
    form,
    handleSearch,
    clear,
    donVi,
    // tram,
    filterConditions,
    loaiTreoThao,
    trangThai,
    handleChange,
  } = props;

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
      }}
      autoComplete="off"
    >
      <Row gutter={24}>
        <Col span={24} md={8}>
          <Form.Item name="donViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Select
                showSearch
                filterOption={(input, option) => {
                  if (option && option.children) {
                    return (
                      removeAccents(option.children)
                        .toLowerCase()
                        .indexOf(removeAccents(input).toLowerCase()) >= 0
                    );
                  }
                }}
                onChange={(value) => handleChange(value)}
              >
                {donVi && donVi.length > 0
                  ? donVi.map((item, index) => (
                      <Option key={index} value={item.id.toString()}>
                        {item.tenDonVi}
                      </Option>
                    ))
                  : null}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.donViId !== currentValues.donViId) {
                form.setFieldsValue({
                  doiId: undefined,
                });
              }
              return prevValues.donViId !== currentValues.donViId;
            }}
          >
            {({ getFieldValue }) => {
              if (getFieldValue("donViId")) {
                return (
                  <Form.Item name="doiId" label="Đội">
                    <Selection
                      url={`${Endpoint.GET_TEAM_LIST}?donviId=${getFieldValue(
                        "donViId"
                      )}`}
                      formKey="doiId"
                      form={form}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="doiId" label="Đội">
                  <Selection
                    url={`${Endpoint.GET_TEAM_LIST}?donviId=${user.unitId}`}
                    formKey="doiId"
                    form={form}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          {/* <Form.Item name="to" label="Tổ">
            <Select placeholder="Tất cả" allowClear>
              {to && to.length > 0
                ? to.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item> */}
        </Col>

        <Col span={24} md={5}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.donViId !== currentValues.donViId) {
                form.setFieldsValue({
                  tramId: undefined,
                });
              }
              return prevValues.donViId !== currentValues.donViId;
            }}
          >
            {({ getFieldValue }) => {
              if (getFieldValue("donViId")) {
                return (
                  <Form.Item name="tramId" label="Trạm">
                    <Selection
                      url={`${
                        Endpoint.COMMON_LIST_TRAM_TREO_THAO
                      }?donviId=${getFieldValue("donViId")}`}
                      formKey="tramId"
                      form={form}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="tramId" label="Trạm">
                  <Selection
                    url={`${Endpoint.COMMON_LIST_TRAM_TREO_THAO}?donviId=${user.unitId}`}
                    formKey="tramId"
                    form={form}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        {/* <Col span={24} md={5}>
          <Form.Item name="tramId" label="Trạm">
            <Select
              placeholder="Tất cả"
              allowClear
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {tram && tram.length > 0
                ? tram.map((item, index) => {
                    if (item.key !== null) {
                      return (
                        <Option key={index} value={item.key.toString()}>
                          {item.value}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col> */}

        <Col span={24} md={5}>
          <Form.Item name="trangThaiId" label="Trạng thái">
            <Select
              placeholder="Tất cả"
              allowClear
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {trangThai && trangThai.length > 0
                ? trangThai.map((item, index) => {
                    if (item.key !== null) {
                      return (
                        <Option key={index} value={item.key.toString()}>
                          {item.value}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="soPha" label="Số pha">
            <Select
              placeholder="Tất cả"
              allowClear
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {dataSoPha.map((item, index) => {
                if (item.value !== null) {
                  return (
                    <Option key={index} value={item.value.toString()}>
                      {item.name}
                    </Option>
                  );
                }
                return null;
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="ngayBatDauTT" label="Từ ngày">
            <DatePickerComponent
              defaultValue={
                filterConditions.ngayBatDauTT
                  ? moment(filterConditions.ngayBatDauTT, FORMAT_ENGLISH)
                  : moment(moment().startOf(MONTH_STRING), FORMAT_DATE)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"ngayBatDauTT"}
              notClear={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="ngayKetThucTT" label="Đến ngày">
            <DatePickerComponent
              defaultValue={
                filterConditions.ngayKetThucTT
                  ? moment(filterConditions.ngayKetThucTT, FORMAT_ENGLISH)
                  : moment(moment(), FORMAT_DATE)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"ngayKetThucTT"}
              notClear={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="loaiTreoThao" label="Loại treo tháo">
            <Select
              placeholder="Tất cả"
              allowClear
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {loaiTreoThao && loaiTreoThao.length > 0
                ? loaiTreoThao.map((item, index) => {
                    if (
                      item.value === null ||
                      item.value === 4 ||
                      item.value === 5 ||
                      item.value === 6
                    ) {
                      return null;
                    } else {
                      return (
                        <Option key={index} value={item.value.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="searchTerm" label="Từ khóa">
            <Input
              allowClear
              prefix={<SearchOutlined />}
              suffix={
                <Tooltip title="Tìm theo mã điểm đo, tên, điện thoại, địa chỉ khách hàng">
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
        <Col
          span={24}
          md={4}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button className="mr-24px" onClick={clear}>
            Bỏ lọc
          </Button>

          <Button type="primary" htmlType="submit" form="filter-form">
            Tìm kiếm
          </Button>
        </Col>
      </Row>
    </FormComponent>
  );
}
