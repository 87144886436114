import { Button, Modal, Row, Col, DatePicker } from "antd";
import LoadingComponent from "@components/Loading";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import moment from "moment";
import { FORMAT_DATE } from "@utils/constants";
import { NgayXuatKho } from "../css-styled";

export default function XacNhanXuatVatTuModal(props) {
  const {
    t,
    visible,
    handleCloseModal,
    handleChangeDate,
    onSubmitXacNhan,
    loading,
  } = props;
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={t("vat_tu.ho_so")}
        className="modal-xac-nhan-vat-tu"
        visible={visible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Row gutter={24}>
          <Col span={24} md={24}>
            Hồ sơ của bạn đang ở Phòng vật tư. Xác nhận sẽ chuyển tiếp cho Đội
            thi công !
          </Col>
          <Col span={24} md={24}>
            Ngày xuất kho được sử dụng để xác định đơn giá khi quyết toán.
          </Col>
          <NgayXuatKho span={24} md={24}>
            <label>Ngày xuất kho</label>
            <DatePicker
              locale={locale}
              format={FORMAT_DATE}
              className="datepicker-text-bold"
              onChange={handleChangeDate}
              defaultValue={moment(moment(), FORMAT_DATE)}
              allowClear={false}
            />
          </NgayXuatKho>
        </Row>
        <Row gutter={24} justify="center">
          <Col span={12} style={{ textAlign: "right" }}>
            <Button
              key="back"
              onClick={handleCloseModal}
              className="button-closed"
            >
              {t("button.dong_lai")}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              key="submit"
              type="primary"
              className="button-primary"
              onClick={onSubmitXacNhan}
            >
              {t("button.xac_nhan")}
            </Button>
          </Col>
        </Row>
      </Modal>
    </LoadingComponent>
  );
}
