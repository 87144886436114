const { useIsOverflow } = require("@hooks/useIsOverflow");
const { Tooltip } = require("antd");
const { useState, useRef } = require("react");

export default function EllipsisTooltip(props) {
  const [visible, setVisible] = useState(false);
  const ref = useRef();
  const isOverflow = useIsOverflow(ref);
  const handleVisibleChange = (visible) => {
    if (isOverflow) {
      setVisible(visible);
    }
  };
  return (
    <div className="table-text-left">
      <Tooltip
        visible={visible}
        title={props.title}
        placement={props.placement ? props.placement : "topLeft"}
        // overlayInnerStyle={{ width: "400px" }}
        key="giaTri"
        onVisibleChange={handleVisibleChange}
      >
        <div
          ref={ref}
          style={{
            display: "-webkit-box",
            lineHeight: "1.3em",
            WebkitLineClamp: props.line ? props.line : "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {props.children}
        </div>
      </Tooltip>
    </div>
  );
}
