import { Modal } from "antd";
import styled from "styled-components";

export const ContainerChoice = styled.div`
  display: flex;
  align-items: center;
  // position: fixed;
  // top: 153px;
  // box-shadow: 5px 5px rgb(0 0 0 / 15%);
  // z-index: 99999;
  // background: #ffffff;
  width: 100%;
  .text {
    width: 20%;
  }
  .choice {
    width: 30%;
  }
`;

export const StyleButton = styled.div`
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: #1088e9;
  color: #fff;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 95px;
  right: 95px;
  cursor: pointer;
`;
export const ImagePreviewOperations = styled.div`
  font-size: 24px;
  border: unset;
  position: fixed;
  top: 0px;
  cursor: pointer;
  z-index: 999;
`;

export const ModalContainer = styled(Modal)`
  .ant-modal-content {
    height: 100%;
    background-color: #00000073;
  }

  .ant-modal-body {
    height: 100%;
  }
`;
