import { TOKEN_NAME } from "@utils/constants";
import Axios from "axios";

export async function authPostFileData({
  url,
  method,
  payload,
  setLoading,
  onSuccess,
}) {
  setLoading(true);
  try {
    const res = await authPostFormData(url, method, payload);
    if (res && res.data) {
      onSuccess(res.data);
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

export async function authPutFileData({
  url,
  method,
  payload,
  setLoading,
  onSuccess,
}) {
  setLoading(true);
  try {
    const res = await authPostFormData(url, method, payload);
    if (res && res.data) {
      onSuccess(res.data);
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

async function authPostFormData(endpoint, method, payload) {
  const token = localStorage.getItem(TOKEN_NAME);
  const body = {};
  Object.keys(payload).forEach((key) => {
    if (
      payload[key] ||
      typeof payload[key] === "boolean" ||
      typeof payload[key] === "number"
    ) {
      body[key] = payload[key];
    }
    return {};
  });
  const formData = new FormData();
  Object.keys(body).forEach((key) => {
    if (key !== "File") {
      return formData.append(key, body[key]);
    }
  });
  if (body.File) {
    // eslint-disable-next-line prefer-const
    for (let i = 0; i < body.File.length; i++) {
      formData.append("File", body.File[i]);
    }
  }
  return await Axios({
    headers: {
      Authorization: `Bearer ${token}`,
      ContentType: "multipart/form-data",
    },
    method: method,
    url: endpoint,
    data: formData,
  });
}
