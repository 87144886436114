import { BASE_API_URL_CSKH } from "@utils/constants";

export const APIKYSO = {
  // list doi tuong
  LIST_DOI_TUONG_KY_SO: `${BASE_API_URL_CSKH}/doituong/dropdown-file-ky-so`,
  // TAO BIEN BAN THONG BAO KY SO GUI KH
  TAO_BIEN_BAN_KY_SO_GUI_KH: `${BASE_API_URL_CSKH}/bienbanthongbao/tao`,
  HUY_BIEN_BAN_KY_SO_GUI_KH: `${BASE_API_URL_CSKH}/bienbanthongbao/huy`,
  HUY_XACNHAN_BIEN_BAN_KY_SO_GUI_KH: `${BASE_API_URL_CSKH}/bienbanthongbao/huy-xac-nhan`,
  XACNHAN_BIEN_BAN_KY_SO_GUI_KH: `${BASE_API_URL_CSKH}/bienbanthongbao/xac-nhan`,
  KYSO_BIEN_BAN_KY_SO_GUI_KH: `${BASE_API_URL_CSKH}/bienbanthongbao/kyso`,
  HUY_KYSO_BIEN_BAN_KY_SO_GUI_KH: `${BASE_API_URL_CSKH}/bienbanthongbao/huy-kyso`,
};

export const APINhanTinChoKH = {
  // Danh sach nhan tin cho khach hang
  CSKH_BAO_CAO_EMAIL: `${BASE_API_URL_CSKH}/doituong/dropdown-email`,
  CSKH_BAO_CAO_ZALO: `${BASE_API_URL_CSKH}/doituong/dropdown-zalo`,
  CSKH_BAO_CAO_APP: `${BASE_API_URL_CSKH}/doituong/dropdown-app`,
  CSKH_NHAN_TIN: `${BASE_API_URL_CSKH}/doituong/dropdown-nhan-tin`,
  CSKH_DOITUONG_DROPDOWN: `${BASE_API_URL_CSKH}/doituong/dropdown-co-cu-phap`,
  CSKH_SUA_CU_PHAP: `${BASE_API_URL_CSKH}/doituong/cu-phap`,
  CSKH_DOITUONG: `${BASE_API_URL_CSKH}/doituong`,
  CSKH_NT_ZALO_EMAIL: `${BASE_API_URL_CSKH}/nhantin/zalo-email`,
  CSKH_XUAT_NT_SMS: `${BASE_API_URL_CSKH}/nhantin/xuat-nhan-tin-sms`,
  CSKH_NT_SMS: `${BASE_API_URL_CSKH}/nhantin/sms`,
  CSKH_XUAT_NT_ZALO_EMAIL: `${BASE_API_URL_CSKH}/nhantin/xuat-nhan-tin-mail-zalo`,
};

export const APIDanhSachSo = {
  // Danh sach so
  CSKH_DSS_SL_QUA_150: `${BASE_API_URL_CSKH}/dssogcs/slqua150sovoitt`,
  CSKH_DSS_SL_QUA_130: `${BASE_API_URL_CSKH}/dssogcs/slqua130sovoitt`,
  CSKH_DSS_TBTD_LAN_2: `${BASE_API_URL_CSKH}/dssogcs/khdetbtiendienlan2`,
  CSKH_DSS_TBTD_LAN_2_KTC: `${BASE_API_URL_CSKH}/dssogcs/khdetbtiendienlan2-ktc`,
  CSKH_DSS_TB_NGUNG_CAP_DIEN: `${BASE_API_URL_CSKH}/dssogcs/khdetbtamngungcapdien`,
  CSKH_DSS_TB_THAY_DK_CT: `${BASE_API_URL_CSKH}/dssogcs/tbkhthaydinhkycongto`,
  CSKH_DSS_TB_CHI_SO_THAY_DK_CT: `${BASE_API_URL_CSKH}/dssogcs/tbcsthaycongtodinhky`,
  CSKH_DSS_TB_LICH_GHI_CS: `${BASE_API_URL_CSKH}/dssogcs/tbthaydoilichgcs`,
  CSKH_DSS_TB_LICH_GHI_CS_TET: `${BASE_API_URL_CSKH}/dssogcs/tbthaydoilichgcstet`,
  CSKH_DSS_TB_KH_2_DINH_MUC_TRO_LEN: `${BASE_API_URL_CSKH}/dssogcs/tbtktkh2dinhmuctrolen`,
  CSKH_DSS_TB_KIEM_TRA_AP_GIA_BAN_DIEN: `${BASE_API_URL_CSKH}/dssogcs/tbktapgiabandien`,
  CSKH_DSS_TB_XACNHAN_SLDIEN_MT: `${BASE_API_URL_CSKH}/dssogcs/tbxnsldienmt`,
  CSKH_DSS_KH_6_THANG_KHONG_DUNG: `${BASE_API_URL_CSKH}/dssogcs/dssokh6thangkdung`,
};
export const APIDanhSachKH = {
  // Danh sach kh
  CSKH_DSkh_SL_QUA_150: `${BASE_API_URL_CSKH}/dskh/slqua150sovoitt`,
  CSKH_DSkh_SL_QUA_130: `${BASE_API_URL_CSKH}/dskh/slqua130sovoitt`,
  CSKH_DSkh_TBTD_LAN_2: `${BASE_API_URL_CSKH}/dskh/detbtiendienlan2`,
  CSKH_DSkh_TBTD_LAN_2_KTC: `${BASE_API_URL_CSKH}/dskh/detbtiendienlan2-ktc`,
  CSKH_DSkh_TB_NGUNG_CAP_DIEN: `${BASE_API_URL_CSKH}/dskh/detbtamngungcapdien`,
  CSKH_DSkh_TB_THAY_DK_CT: `${BASE_API_URL_CSKH}/dskh/tbkhthaydinhkycongto`,
  CSKH_DSkh_TB_CHI_SO_THAY_DK_CT: `${BASE_API_URL_CSKH}/dskh/tbchisothaycongtodinhky`,
  CSKH_DSkh_TB_LICH_GHI_CS: `${BASE_API_URL_CSKH}/dskh/tbthaydoilichgcs`,
  CSKH_DSkh_TB_LICH_GHI_CS_TET: `${BASE_API_URL_CSKH}/dskh/tbthaydoilichgcstet`,
  CSKH_DSkh_TB_KH_2_DINH_MUC_TRO_LEN: `${BASE_API_URL_CSKH}/dskh/tbktkh2dinhmuctrolen`,
  CSKH_DSkh_TB_KIEM_TRA_AP_GIA_BAN_DIEN: `${BASE_API_URL_CSKH}/dskh/ktapgiabandien`,
  CSKH_DSkh_TB_XACNHAN_SLDIEN_MT: `${BASE_API_URL_CSKH}/dskh/tbxacnhansldienmt`,
  CSKH_DSkh_KH_6_THANG_KHONG_DUNG: `${BASE_API_URL_CSKH}/dskh/dskh6thangkdung`,
};

// api bao cao
export const APIBAOCAOKIEMSOAT = {
  BC_SAN_LUONG_TIN_NHAN_THEO_DON_VI: `${BASE_API_URL_CSKH}/baocao/bao-cao-san-luong-tin-nhan-theo-don-vi`,
  BC_SL_THEO_DV_EXCEL: `${BASE_API_URL_CSKH}/baocao/bao-cao-san-luong-tin-nhan-theo-don-vi-excel`,
  BC_SAN_LUONG_THEO_NGAY: `${BASE_API_URL_CSKH}/baocao/bao-cao-san-luong-tin-nhan-theo-ngay`,
  BC_SL_THEO_NGAY_EXCEL: `${BASE_API_URL_CSKH}/baocao/bao-cao-san-luong-tin-nhan-theo-ngay-excel`,
  BC_SAN_LUONG_TN_LOI_THEO_DV: `${BASE_API_URL_CSKH}/baocao/bao-cao-san-luong-tin-nhan-loi-theo-theo-don-vi`,
  BC_LOI_DV_EXCEL: `${BASE_API_URL_CSKH}/baocao/bao-cao-san-luong-tin-nhan-loi-theo-theo-don-vi-excel`,
  DANH_SACH_KH_CHUA_GUI_TIN_NHAN: `${BASE_API_URL_CSKH}/baocao/danh-sach-khach-hang-chua-gui-tin-nhan`,
  BC_CHUA_GUI_TIN_NHAN_EXCEL: `${BASE_API_URL_CSKH}/baocao/danh-sach-khach-hang-chua-gui-tin-nhan-excel`,
  DANH_SACH_KH_DKY_SAI_DV_HOAC_SAI_SDT_CT: `${BASE_API_URL_CSKH}/baocao/bieu-chi-tiet-dskhdksdvhssdttdv`,
  DS_CT_EXCEL: `${BASE_API_URL_CSKH}/baocao/bieu-chi-tiet-dskhdksdvhssdttdv-excel`,
  DANH_SACH_KH_DKY_SAI_DV_HOAC_SAI_SDT_TH: `${BASE_API_URL_CSKH}/baocao/bieu-tong-hop-dskhdksdvhssdttdv`,
  DS_th_EXCEL: `${BASE_API_URL_CSKH}/baocao/bieu-tong-hop-dskhdksdvhssdttdv-excel`,
  DANH_SACH_KH_30_NGAY_CHUA_CAP_NHAT: `${BASE_API_URL_CSKH}/baocao/bieu-chi-tiet-30-ngay-chua-cap-nhat`,
  DS_30_NGAY_EXCEL: `${BASE_API_URL_CSKH}/baocao/bieu-chi-tiet-30-ngay-chua-cap-nhat-excel`,
};
// bao cao doi soat ems
export const APIBAOCAODOISOATSMS = {
  B1_TH_TIN_NHAN_TOAN_CONG_TY: `${BASE_API_URL_CSKH}/baocao/tdl1-th-so-luong-tin-nhan-cua-ttct`,
  B1_EXCEL: `${BASE_API_URL_CSKH}/baocao/tdl1-th-so-luong-tin-nhan-cua-ttct-excel`,
  B2_CT_SL_TN_THEO_CTY_DIEN_LUC: `${BASE_API_URL_CSKH}/baocao/tdl1-ctsltn-theo-tung-ctdl`,
  B2_EXCEL: `${BASE_API_URL_CSKH}/baocao/tdl1-ctsltn-theo-tung-ctdl-excel`,
  B3_TH_TIN_NHAN_THEO_TUNG_NHA_MANG: `${BASE_API_URL_CSKH}/baocao/tdl1-th-so-luong-tin-nhan-da-th-theo-tung-mang`,
  B3_EXCEL: `${BASE_API_URL_CSKH}/baocao/tdl1-th-so-luong-tin-nhan-da-th-theo-tung-mang-excel`,
  B4_DOI_SOAT_SL_TIN_NHAN_THANH_CONG: `${BASE_API_URL_CSKH}/baocao/tdl1-doi-soat-sltn-da-th-thanh-cong`,
  B4_EXCEL: `${BASE_API_URL_CSKH}/baocao/tdl1-doi-soat-sltn-da-th-thanh-cong-excel`,
  DOITUONG_SMS_TIEN_DIEN_L1: `${BASE_API_URL_CSKH}/doituong/dropdown-sms-tien-dien`,
  DOITUONG_SMS_DICH_VU_KHAC: `${BASE_API_URL_CSKH}/doituong/dropdown-sms-khac`,

  B5_CHI_TIET_SL_TIN_NHAN_THANH_CONG: `${BASE_API_URL_CSKH}/baocao/tdl1-ctsl-tin-nhan-tc-theo-tung-ctdl`,
  B5_EXCEL: `${BASE_API_URL_CSKH}/baocao/tdl1-ctsl-tin-nhan-tc-theo-tung-ctdl-excel`,
  B7_DS_TN_CHUA_GUI_KH: `${BASE_API_URL_CSKH}/baocao/tdl1-ds-tin-nhan-chua-gui-cho-kh`,
  B7_EXCEL: `${BASE_API_URL_CSKH}/baocao/tdl1-ds-tin-nhan-chua-gui-cho-kh-excel`,
  B8_DOI_SOAT_THEO_NGAY: `${BASE_API_URL_CSKH}/baocao/tdl1-doi-soat-theo-ngay`,
  B8_EXCEL: `${BASE_API_URL_CSKH}/baocao/tdl1-doi-soat-theo-ngay-excel`,
  B9_DOI_SOAT_NGAY_THEO_DON_VI: `${BASE_API_URL_CSKH}/baocao/tdl1-doi-soat-ngay-theo-don-vi`,
  B9_EXCEL: `${BASE_API_URL_CSKH}/baocao/tdl1-doi-soat-ngay-theo-don-vi-excel`,

  // DVK
  B1_DVK_TH_TIN_NHAN_TOAN_CONG_TY: `${BASE_API_URL_CSKH}/baocao/dvk-b09-th-tin-nhan-toan-tct`,
  B1_EXPORT_EXCEL: `${BASE_API_URL_CSKH}/baocao/dvk-b09-th-tin-nhan-toan-tct-excel`,
  B2_DVK_CT_SL_TN_THEO_CTY_DIEN_LUC: `${BASE_API_URL_CSKH}/baocao/dvk-chi-tiet-sltn-theo-tung-ctdl`,
  B2_EXPORT_EXCEL: `${BASE_API_URL_CSKH}/baocao/dvk-chi-tiet-sltn-theo-tung-ctdl-excel`,
  B4_DVK_DOI_SOAT_SL_TIN_NHAN_THANH_CONG: `${BASE_API_URL_CSKH}/baocao/dvk-doi-soat-sltn-da-th-thanh-cong`,
  B4_EXPORT_EXCEL: `${BASE_API_URL_CSKH}/baocao/dvk-doi-soat-sltn-da-th-thanh-cong-excel`,
  // th-tabpane
  B1_TH_TH_TIN_NHAN_TOAN_CONG_TY: `${BASE_API_URL_CSKH}/baocao/th-tong-hop-tin-nhan-toan-tct`,
  B1_EXPORT_EXCEL_TH: `${BASE_API_URL_CSKH}/baocao/th-tong-hop-tin-nhan-toan-tct-excel`,
  B2_EXPORT_EXCEL_TH: `${BASE_API_URL_CSKH}/baocao/th-chi-tiet-sltn-theo-tung-ctdl-excel`,
  B2_TH_CT_SL_TN_THEO_CTY_DIEN_LUC: `${BASE_API_URL_CSKH}/baocao/th-chi-tiet-sltn-theo-tung-ctdl`,
  B4_TH_DOI_SOAT: `${BASE_API_URL_CSKH}/baocao/th-doi-soat-sltn-da-thtc-sms`,
  B4_TH_DOI_SOAT_EXCEL: `${BASE_API_URL_CSKH}/baocao/th-doi-soat-sltn-da-thtc-sms-excel`,
  B4_TH_DOI_SOAT_GOP_EP: `${BASE_API_URL_CSKH}/baocao/th-doi-soat-sltn-da-thtc-da-gop-ep-sms`,
  B4_TH_DOI_SOAT_GOP_EP_EXCEL: `${BASE_API_URL_CSKH}/baocao/th-doi-soat-sltn-da-thtc-da-gop-ep-sms-excel`,
};
// bao cao doi soat email
export const APIBAOCAODOISOATEMAIL = {
  TH_GUI_TINH_HINH_THONG_BAO_EMAIL: `${BASE_API_URL_CSKH}/baocao/b09ththgtbckhhtemail`,
  TH_GUI_TINH_HINH_THONG_BAO_EMAIL_EXCEL: `${BASE_API_URL_CSKH}/baocao/b09ththgtbckhhtemail-excel`,

  BC_TH_SL_THU_DIEN_TU_HOP_LE: `${BASE_API_URL_CSKH}/baocao/tong-hop-hop-le-da-gui`,
  BC_CHI_TIET_SL_THU_DIEN_TU_HOP_LE: `${BASE_API_URL_CSKH}/baocao/chi-tiet-hop-le-da-gui`,
  BC_TH_SL_THU_DIEN_TU_KHONG_HOP_LE: `${BASE_API_URL_CSKH}/baocao/tong-hop-gui-khong-hop-le`,
  BC_CHI_TIET_SL_THU_DIEN_TU_KHONG_HOP_LE: `${BASE_API_URL_CSKH}/baocao/chi-tiet-gui-khong-hop-le`,
  DANH_SACH_KH_DANG_KY_EMAIL: `${BASE_API_URL_CSKH}/baocao/dskhdksdemail`,
};
export const APIBaoCaoKySo = {
  TONG_SO_LUONG_KY_S0: `${BASE_API_URL_CSKH}/baocao/tong-so-luong-ky-so`,
  TONG_SO_EXCEL: `${BASE_API_URL_CSKH}/baocao/tong-so-luong-ky-so-excel`,

  CHI_TIET_SO_LUONG_KY_S0: `${BASE_API_URL_CSKH}/baocao/chi-tiet-luong-ky-so`,
  CHI_TIET_EXCEL: `${BASE_API_URL_CSKH}/baocao/chi-tiet-so-luong-ky-so-excel`,
};
// bao cao doi soat zalo
export const APIBAOCAODOISOATZALO = {
  TH_TINH_HINH_NT_TB_KH_ZALO: `${BASE_API_URL_CSKH}/baocao/b09ththnttbkhhtzalo`,
  B09_TB_EXCEL: `${BASE_API_URL_CSKH}/baocao/b09ththnttbkhhtzalo-excel`,
  TH_TINH_HINH_NT_TB_TIEN_DIEN_L1_ZALO: `${BASE_API_URL_CSKH}/baocao/b09ththnttbtdl1khhtzalo`,
  B09_Th_EXCEL: `${BASE_API_URL_CSKH}/baocao/b09ththnttbtdl1khhtzalo-excel`,
  CHI_TIET_TH_NT_TB_TIEN_DIEN_L1_ZALO: `${BASE_API_URL_CSKH}/baocao/b09ctthnttbtdl1khhtzalo`,
  B09_CT_EXCEL: `${BASE_API_URL_CSKH}/baocao/b09ctthnttbtdl1khhtzalo-excel`,
  DS_KH_DANG_KY_ZALO_TONG_HOP: `${BASE_API_URL_CSKH}/baocao/danh-sach-khach-hang-dang-ky-zalo-tong-hop`,
  DS_ZALO_TH_EXCEL: `${BASE_API_URL_CSKH}/baocao/danh-sach-khach-hang-dang-ky-zalo-tong-hop-excel`,
  DS_KH_DANG_KY_ZALO_CHI_TIET: `${BASE_API_URL_CSKH}/baocao/danh-sach-khach-hang-dang-ky-zalo-chi-tiet`,
  DS_ZALO_CT_EXCEL: `${BASE_API_URL_CSKH}/baocao/danh-sach-khach-hang-dang-ky-zalo-chi-tiet-excel`,
  DANH_SACH_KH_GUI_TN_THANH_CONG_TH: `${BASE_API_URL_CSKH}/baocao/danh-sach-gui-tin-nhan-thanh-cong-tong-hop`,
  DS_TC_TH_EXCEL: `${BASE_API_URL_CSKH}/baocao/danh-sach-gui-tin-nhan-thanh-cong-tong-hop-excel`,
  DANH_SACH_KH_GUI_TN_THANH_CONG_CHI_TIET: `${BASE_API_URL_CSKH}/baocao/danh-sach-gui-tin-nhan-thanh-cong-chi-tiet`,
  DS_TC_CT_EXCEL: `${BASE_API_URL_CSKH}/baocao/danh-sach-gui-tin-nhan-thanh-cong-chi-tiet-excel`,
  DANH_SACH_KH_GUI_TN_KO_THANH_CONG_TH: `${BASE_API_URL_CSKH}/baocao/danh-sach-gui-tin-nhan-khong-thanh-cong-tong-hop`,
  DS_KO_TC_TH_EXCEL: `${BASE_API_URL_CSKH}/baocao/danh-sach-gui-tin-nhan-khong-thanh-cong-tong-hop-excel`,
  DANH_SACH_KH_GUI_TN_KO_THANH_CONG_CHI_TIET: `${BASE_API_URL_CSKH}/baocao/danh-sach-gui-tin-nhan-khong-thanh-cong-chi-tiet`,
  DS_KO_TC_CT_EXCEL: `${BASE_API_URL_CSKH}/baocao/danh-sach-gui-tin-nhan-khong-thanh-cong-chi-tiet-excel`,
  BC_TH_TIN_NHAN: `${BASE_API_URL_CSKH}/baocao/bao-cao-tong-hop-tin-nhan`,
  BC_TH_TN_EXCEL: `${BASE_API_URL_CSKH}/baocao/bao-cao-tong-hop-tin-nhan-excel`,
  BC_TH_TY_LE_NHAN_TIN: `${BASE_API_URL_CSKH}/baocao/bao-cao-tong-hop-ty-le-nhan-tin`,
  BC_TH_TLTN_EXCEL: `${BASE_API_URL_CSKH}/baocao/bao-cao-tong-hop-ty-le-nhan-tin-excel`,
  BC_SL_KH_PHAT_TRIEN_MOI_EVNHANOI: `${BASE_API_URL_CSKH}/baocao/bao-cao-so-luong-khach-hang-phat-trien-moi-da-quan-tam`,
  BC_EVNHANOI_EXCEL: `${BASE_API_URL_CSKH}/baocao/bao-cao-so-luong-khach-hang-phat-trien-moi-da-quan-tam-excel`,
  BC_DS_KH_PHAT_TRIEN_MOI: `${BASE_API_URL_CSKH}/baocao/ds-khach-hang-phat-trien-moi`,
  BC_PTM_EXCEL: `${BASE_API_URL_CSKH}/baocao/ds-khach-hang-phat-trien-moi-excel`,
};

// báo cáo bcth

export const APIBaoCaoTH = {
  BCTH_TONG_SO_LUONG_TN_QUA_CAC_HT: `${BASE_API_URL_CSKH}/baocao/bcth-so-luong-tin-nhan-qua-cac-hinh-thuc`,
  BCTH_TONG_SO_LUONG_TN_QUA_CAC_HT_EXCEL: `${BASE_API_URL_CSKH}/baocao/bcth-so-luong-tin-nhan-qua-cac-hinh-thuc-excel`,
  BCTH_TONG_SO_LUONG_KH_SUDUNG_APP: `${BASE_API_URL_CSKH}/baocao/bcth-so-luong-kh-su-dung-app`,
  BCTH_TONG_SO_LUONG_KH_SUDUNG_APP_EXCEL: `${BASE_API_URL_CSKH}/baocao/bcth-so-luong-kh-su-dung-app-excel`,
  BCTH_TONG_SO_KH_CAI_APP_THEO_HDH: `${BASE_API_URL_CSKH}/baocao/bcth-so-luong-kh-cai-dat-app-theo-hdh`,
  BCTH_TONG_SO_KH_CAI_APP_THEO_HDH_EXCEL: `${BASE_API_URL_CSKH}/baocao/bcth-so-luong-kh-cai-dat-app-theo-hdh-excel`,
  BCTH_TONG_SO_LUONG_KH_YEU_CAU_CO_SDT: `${BASE_API_URL_CSKH}/baocao/bcth-danh-sach-yeu-cau-co-sdt-nhieu-ho-so`,
  BCTH_TONG_SO_LUONG_KH_YEU_CAU_CO_SDT_EXCEL: `${BASE_API_URL_CSKH}/baocao/bcth-danh-sach-yeu-cau-co-sdt-nhieu-ho-so-excel`,
};

// bc cổng tin nhắn cskh
export const APIBaoCaoCongTinNhanCSKH = {
  BC_CONG_TIN_NHAN_CSKH: `${BASE_API_URL_CSKH}/baocao/cong-tin-nhan-cskh-excel`,
};

export const APIBCDOISOATSMSCovid4 = {
  BCDOISOATSMSCOVID4_CHI_TIET_SO_LUONG_TIN_NHAN_THEO_TUNG_CONG_TY_DIEN_LUC: `${BASE_API_URL_CSKH}/baocao/chi-tiet-sl-tin-nhan-theo-tung-ctdl-covid4`,
  BCDOISOATSMSCOVID4_DOI_SOAT_SL_TN_DA_THUC_HIEN_THANH_CONG: `${BASE_API_URL_CSKH}/baocao/doi-soat-sltn-da-th-thanh-cong-covid4`,
};

export const APIDSKHHDVSMS = {
  DSKHHDVSMS_DS_KHACH_HANG_HUY_DICH_VU_SMS: `${BASE_API_URL_CSKH}/baocao/dskh-huy-dv-nhan-tin-nhan-sms`,
};

export const APIBAOCAO_DSBRANDNAME = {
  BCBN_TONG_HOP_THEO_TOAN_DV: `${BASE_API_URL_CSKH}/baocao/br-tong-hop-theo-toan-don-vi`,
  BCBN_TONG_HOP_THEO_TOAN_DV_EXCEL: `${BASE_API_URL_CSKH}/baocao/br-tong-hop-theo-toan-don-vi-excel`,
  BCBN_BIEU2_CHITIET: `${BASE_API_URL_CSKH}/baocao/br-chi-tiet`,
  BCBN_BIEU2_CHITIET_EXCEL: `${BASE_API_URL_CSKH}/baocao/br-chi-tiet-excel`,
};
export const APIBAOCAO_EP = {
  BCBEP_TH_TIN_NHAN: `${BASE_API_URL_CSKH}/baocao/thsl-tin-nhan-tct`,
  BCBEP_TH_TIN_NHAN_EXCEL: `${BASE_API_URL_CSKH}/baocao/thsl-tin-nhan-tct-excel`,
  BCBEP_CT_TIN_NHAN: `${BASE_API_URL_CSKH}/baocao/ep-chi-tiet-sl-tin-nhan-theo-tung-ctdl`,
  BCBEP_CT_TIN_NHAN_EXCEL: `${BASE_API_URL_CSKH}/baocao/ep-chi-tiet-sl-tin-nhan-theo-tung-ctdl-excel`,
  BCBEP_TIN_NHAN_THTC: `${BASE_API_URL_CSKH}/baocao/doi-soat-sltn-da-thuc-hien-thanh-cong`,
  BCBEP_TIN_NHAN_THTC_EXCEL: `${BASE_API_URL_CSKH}/baocao/doi-soat-sltn-da-thuc-hien-thanh-cong-excel`,
};

export const APIBCDOISOATKHACHHANG = {
  BCDSKH_THSL_TIN_NHAN_CUA_TOAN_TONG_CONG_TY: `${BASE_API_URL_CSKH}/baocao/thsl-tin-nhan-toan-tct`,
  BCDSKH_CTSL_TIN_NHAN_THEO_TUNG_CONG_TY_DIEN_LUC: `${BASE_API_URL_CSKH}/baocao/chi-tiet-sl-tin-nhan-theo-tung-ctdl`,
};

export const APITNCHUYENDOISO = {
  TNCDS_THSL_KH_DANG_KY_NHAN_THONG_BAO_EMAIL_ZALO: `${BASE_API_URL_CSKH}/baocao/thslkh-dang-ky-nhan-tb-qua-em-ap-ep-za`,
  TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_EMAIL: `${BASE_API_URL_CSKH}/baocao/ctkh-dang-ky-nhan-tb-qua-email`,
  TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_APP: `${BASE_API_URL_CSKH}/baocao/ctkh-dang-ky-nhan-tb-qua-app`,
  TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_ZALO: `${BASE_API_URL_CSKH}/baocao/ctkh-dang-ky-nhan-tb-qua-zalo`,
  TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_EPOINT: `${BASE_API_URL_CSKH}/baocao/ctkh-dang-ky-nhan-tb-qua-epoint`,
  TNCDS_CT_TB_TIEN_DIEN_1_LAN_GUI_EMAIL_ZALO: `${BASE_API_URL_CSKH}/baocao/cttb-tien-dien-lan-1-gui-qua-em-ap-ep-za`,
  TNCDS_CT_TB_TIEN_DIEN_2_GUIQUA_EMZA8055: `${BASE_API_URL_CSKH}/baocao/cskh_cttbtiendienlan2guiquaemza8055`,
  TNCDS_CT_TB_THSL_TIN_NHAN_QUA_CAC_HINH_THUC: `${BASE_API_URL_CSKH}/baocao/cskh_thsl_tinnhanquacachinhthuc`,
  TNCDS_CT_TB_THSL_KH_DANG_KY_ID_ZALO: `${BASE_API_URL_CSKH}/baocao/cskh_thslkh_dangkytrungidzalo`,
  TNCDS_CT_TB_THSL_DANG_KY_SU_DUNG_HST: `${BASE_API_URL_CSKH}/baocao/cskh_thslkh_sudunghesinhthai`,
  TNCDS_CT_TB_THSL_NHAN_THONG_TIN_QUA_ZALO: `${BASE_API_URL_CSKH}/baocao/cskh_thslkh_nhanthongtinquazalo`,
  TNCDS_CT_TB_KH_HUY_TB_HST: `${BASE_API_URL_CSKH}/baocao/cskh_chitietskkh_huynhantbquahst`,
  TNCDS_CT_DS_KH_QUAN_LY_CUA_CTY_DIEN_LUC: `${BASE_API_URL_CSKH}/baocao/cskh_chitietdskh_qlcuactdl`,
};

export const APIBCDOISOATDTXD = {
  BCDSDTXD_DOI_SOAT_SO_LUONG_TIN_NHAN_DA_THUC_HIEN_TC: `${BASE_API_URL_CSKH}/baocao/doi-soat-sl-tn-da-thtc`,
};

export const APITIM_KIEM_TIN_NHAN = {
  TKTN_TN_SMS_TRACUU_TDLAN_1: `${BASE_API_URL_CSKH}/tracuu/tin-nhan-sms-tien-dien-lan-1`,
  TKTN_TN_SMS_TRACUU_DICHVUKHAC_VA_DICHVU_DT: `${BASE_API_URL_CSKH}/tracuu/tin-nhan-sms-dich-vu-khac-va-dich-vu-dien-tu`,
  TKTN_CONG_TIN_NHAN_CSKH_LOG_SMS: `${BASE_API_URL_CSKH}/tracuu/log-sms`,
  TKTN_CONG_TIN_NHAN_CSKH_LOG_EMAIL: `${BASE_API_URL_CSKH}/tracuu/log-email`,
  TKTN_CONG_TIN_NHAN_CSKH_LOG_ZALO: `${BASE_API_URL_CSKH}/tracuu/log-zalo`,
};

export const APITIM_KIEM_THONGTIN_KH = {
  TKTTKH_KH_SU_DUNG_APP: `${BASE_API_URL_CSKH}/tracuu/khach-hang-su-dung-app`,
  TKTTKH_KH_SU_DUNG_APP_EXCEL: `${BASE_API_URL_CSKH}/tracuu/khach-hang-su-dung-app-excel`,
  TKTTKH_KH_SU_DUNG_EMAIL: `${BASE_API_URL_CSKH}/tracuu/khach-hang-su-dung-email`,
  TKTTKH_KH_SU_DUNG_EMAIL_EXCEL: `${BASE_API_URL_CSKH}/tracuu/khach-hang-su-dung-email-excel`,
  TKTTKH_KH_SU_DUNG_ZALO: `${BASE_API_URL_CSKH}/tracuu/khach-hang-su-dung-zalo`,
  TKTTKH_KH_SU_DUNG_ZALO_EXCEL: `${BASE_API_URL_CSKH}/tracuu/khach-hang-su-dung-zalo-excel`,
};

export const APIBCDOISOATSMSCovid2 = {
  BCDOISOATSMSCOVID2_CHI_TIET_SO_LUONG_TIN_NHAN_THEO_TUNG_CONG_TY_DIEN_LUC: `${BASE_API_URL_CSKH}/baocao/chi-tiet-sl-tin-nhan-theo-tung-ctdl-covid2`,
  BCDOISOATSMSCOVID2_DOI_SOAT_SL_TN_DA_THUC_HIEN_THANH_CONG: `${BASE_API_URL_CSKH}/baocao/doi-soat-sltn-da-th-thanh-cong-covid2`,
};
