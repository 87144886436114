import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_MONTH_ENGLISH,
} from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { Table } from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { getLichGCSTheoThang } from "./services";
import BaoCaoGCSTheoThang from "./table";

export default function GCSTheoThang() {
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const { user } = useSelector(authSelector);
  const [dataTheoThang, setDataTheoThang] = useState();
  const [totalCount, setTotalCount] = useState();
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
    DonViId: user.unitId,
    ...parseParams(location.search),
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);
  const getLichGCS = useCallback(() => {
    getLichGCSTheoThang(
      filterConditions,
      setLoading,
      setDataTheoThang,
      setTotalCount
    );
  }, [filterConditions]);

  useEffect(() => {
    getLichGCS();
  }, [getLichGCS]);

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      ...values,
    }));
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
      DonViId: user.unitId,
    });
  }, [user.unitId]);

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const columns = [
    {
      title: "STT",
      key: "index",
      align: "center",
      render: (a, b, index) => {
        return (
          (filterConditions.pageIndex - 1) * filterConditions.pageSize +
          (index + 1)
        );
      },
      width: 50,
      fixed: "left",
    },
    {
      title: "Đơn vị",
      dataIndex: "maDonVi",
      key: "maDonVi",
      align: "center",
      width: 100,
      fixed: "left",
    },
    {
      title: "Mã sổ GCS",
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      align: "center",
      fixed: "left",
      width: 100,
    },
    {
      title: "SL khách hàng",
      dataIndex: "slkh",
      key: "slkh",
      align: "center",
      width: 100,
    },
    {
      title: "Ngày ghi tháng N-2",
      dataIndex: "ngayGhiThangN_2",
      key: "ngayGhiThangN_2",
      align: "center",
      width: 100,
    },
    {
      title: "Ngày ghi tháng N-1",
      dataIndex: "ngayGhiThangN_1",
      key: "ngayGhiThangN_1",
      align: "center",
      width: 100,
    },
    {
      title: "Ngày ghi trong tháng N",
      dataIndex: "ngayGhiTrongThang",
      key: "ngayGhiTrongThang",
      align: "center",
      width: 100,
    },
    {
      title: "Chênh lệch N-1",
      dataIndex: "chenhLechN_1",
      key: "chenhLechN_1",
      align: "center",
      width: 100,
    },
    {
      title: "Chênh lệch N-2",
      dataIndex: "chenhLechN_2",
      key: "chenhLechN_2",
      align: "center",
      width: 100,
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      width: 100,
    },
    {
      title: "Ghi chú",
      dataIndex: "ghiChu",
      key: "ghiChu",
      width: 100,
    },
  ];
  const countTotals = (pageData) => {
    let countslkh = 0;
    let countchenhLechN_1 = 0;
    let countchenhLechN_2 = 0;
    pageData.forEach(({ slkh, chenhLechN_1, chenhLechN_2 }) => {
      countslkh += parseInt(slkh);
      countchenhLechN_1 += parseInt(chenhLechN_1);
      countchenhLechN_2 += parseInt(chenhLechN_2);
    });
    return (
      <Table.Summary.Row style={{ backgroundColor: "#ccccb3" }} colSpan={2}>
        <Table.Summary.Cell
          align="center"
          index={0}
          colSpan={2}
          className="summary"
        >
          <Text className="font-bold">Tổng cộng</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell
          className="summary"
          align="center"
          index={1}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="summary font-bold"
          align="center"
          index={2}
        >
          <Text> {countslkh}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell
          className="summary"
          align="center"
          index={3}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold"
          align="center"
          index={4}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold"
          align="center"
          index={5}
        ></Table.Summary.Cell>
        <Table.Summary.Cell className="summary" align="center" index={6}>
          <Text> {countchenhLechN_1}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={7}>
          <Text> {countchenhLechN_2}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold"
          align="center"
          index={7}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold"
          align="center"
          index={9}
        ></Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };
  return (
    <BaoCaoGCSTheoThang
      columns={columns}
      handleSearch={handleSearch}
      filterConditions={filterConditions}
      loading={loading}
      setLoading={setLoading}
      onChangePagination={onChangePagination}
      data={dataTheoThang}
      totalCount={totalCount}
      countTotals={countTotals}
      scrollX={1400}
      clearFilter={clearFilter}
    />
  );
}
