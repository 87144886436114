import { useEffect } from "react";
import { Button, Modal, Row, Col } from "antd";
import FormComponent from "@components/Form";
// import LoadingComponent from "@components/Loading";
import CheckPermission from "@components/CheckPermission";
import { ap_gia } from "@permissions/e_apgia";


export default function KyDuyetModal(props) {
  const {
    visible,
    handleCloseModalKy,
    onFinish,
    form,
    openModalKySo,
    loading
  } = props;
  // const [loading, ] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  return (
    <Modal
      title="Lãnh đạo ký duyệt"
      visible={visible}
      onCancel={handleCloseModalKy}
      className="modal-bpag-ky"
      footer={null}
    >
      {/* <LoadingComponent loading={loading}> */}
        <FormComponent
          form={form}
          name="form-bpag-ky"
          layout="horizontal"
          onFinish={onFinish}
        >
          <Row gutter={24}>
            <Col>
              <div>
                Các yêu cầu áp giá đã chọn sẽ được chuyển tiếp cho Phòng kinh doanh!
              </div>
              <div>
                Bạn chắc chắn muốn thực hiện?
              </div>
            </Col>
          </Row>
          <br />
          <Row gutter={24} justify="center">
            <Button key="back" onClick={handleCloseModalKy} className="button-closed mr-24px">
              Đóng lại
            </Button>
            <CheckPermission permissionCode={ap_gia.e41k}>
              <Button
                // key="submit"
                type="primary"
                className="button-primary mr-24px"
                form="form-bpag-ky"
                onClick={() => openModalKySo()}
                loading={loading}
              >
                Ký HSM
              </Button>
            </CheckPermission>
            <CheckPermission permissionCode={ap_gia.e41k}>
              <Button
                // key="submit"
                type="primary"
                className="button-primary"
                form="form-bpag-ky"
                onClick={() => onFinish()}
                loading={loading}
              >
                Xác nhận
              </Button>
            </CheckPermission>

          </Row>
        </FormComponent>
      {/* </LoadingComponent> */}
    </Modal>
  );
}
