import { INDEX_TAB3 } from "@utils/constants";
import LoadingComponent from "@components/Loading";
import { Col, Row } from "antd";
import TyLeGiaBanDienTabs from "../../../../nhan-vien-khao-sat/lap-phuong-an/tabs/tab-ty-le-gia-ban-dien";

export default function ApGiaHoSoTabs(props) {
  const { t, loading, detailApGia, LIST_TILEGIABANDIEN, detail } = props;

  return (
    <LoadingComponent loading={loading}>
      <Row gutter={24} style={{ marginTop: "12px" }}>
        <Col span={24} lg={9}>
          <h6 className="fs-12px c-t-label">{t("ap_gia_ho_so.khach_hang")}</h6>
          <h5 className="fs-14px font-bold">{detailApGia.tenKhachHang}</h5>
        </Col>
        <Col span={12} md={8} lg={9}>
          <h6 className="fs-12px c-t-label">
            {t("ap_gia_ho_so.ma_yeu_cau_khieu_nai")}
          </h6>
          <h5 className="fs-14px font-bold">{detailApGia.maYeuCauKhieuNai}</h5>
        </Col>
        <Col span={12} md={8} lg={6}>
          <h6 className="fs-12px c-t-label">
            {t("ap_gia_ho_so.muc_dich_su_dung")}
          </h6>
          <h5 className="fs-14px font-bold">{detailApGia.mucDichSuDung}</h5>
        </Col>

        <Col span={12} md={8} lg={9}>
          <h6 className="fs-12px c-t-label">{t("ap_gia_ho_so.dai_dien")}</h6>
          <h5 className="fs-14px font-bold">{detailApGia.nguoiDaiDien}</h5>
        </Col>
        <Col span={12} md={8} lg={9}>
          <h6 className="fs-12px c-t-label">{t("ap_gia_ho_so.chuc_vu")}</h6>
          <h5 className="fs-14px font-bold">{detailApGia.chucVu}</h5>
        </Col>
        <Col span={16} lg={6}>
          <h6 className="fs-12px c-t-label">
            {t("ap_gia_ho_so.loai_cong_to")}
          </h6>
          <h5 className="fs-14px font-bold">{detailApGia.loaiCongTo}</h5>
        </Col>

        <Col span={24} lg={9}>
          <h6 className="fs-12px c-t-label">
            {t("ap_gia_ho_so.dia_chi_giao_dich")}
          </h6>
          <h5 className="fs-14px font-bold">{detailApGia.diaChiGiaoDich}</h5>
        </Col>
        <Col span={24} lg={9}>
          <h6 className="fs-12px c-t-label">
            {t("ap_gia_ho_so.dia_chi_su_dung")}
          </h6>
          <h5 className="fs-14px font-bold">{detailApGia.diaChiSuDung}</h5>
        </Col>
        <Col span={12} lg={6}>
          <h6 className="fs-12px c-t-label">
            {t("ap_gia_ho_so.cong_suat_su_dung")}
          </h6>
          <h5 className="fs-14px font-bold"> {detailApGia.congSuatSuDung}</h5>
        </Col>
      </Row>

      <div className="table mt-24px">
        <TyLeGiaBanDienTabs
          detaiHoSo={detail}
          typeTiLe={LIST_TILEGIABANDIEN.APGIA}
          indexTab={INDEX_TAB3}
        />
      </div>
    </LoadingComponent>
  );
}
