import styled from "styled-components";
export const Style = styled.div`
  .tich-hop-CRM-gdv-content {
    padding: 20px;
    .tich-hop-CRM-gdv-content-button {
      display: flex;
      gap: 10px;
      justify-content: right;
      margin-right: 10px;
    }
    .yeu-cau-da-phan-cong-button {
      text-align: right;
      margin-right: 10px;
    }
  }
  .tich-hop-CRM-list-bo-loc {
    .tich-hop-CRM-list-bo-loc-button {
      display: flex;
      gap: 20px;
      align-items: center;
    }
  }
  .tich-hop-CRM-gdv {
    .ant-tabs-nav {
      padding: 0 10px;
    }
    .ant-row.ant-form-item {
      padding: 0 10px;
    }
  }
  .yeu-cau-cho-huy-table {
    .yeu-cau-cho-huy-table-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;
