/* eslint-disable no-unused-vars */
import React, { memo, useState } from "react";
import LamMoiModal from "./modal-lam-moi";
import { handleLamMoi } from "./services";
function Index({
  visible,
  handleCloseModal,
  setVisibleLamMoi,
  selectedRowKeys,
  setFetchDataTable,
  setSelectedRowKeys,
  isPhucTra,
}) {
  const [loading, setLoading] = useState(false);
  // hàm call api làm mới yêu cầu
  console.log("setSelectedRowKeys", selectedRowKeys);
  const handleSubmit = () => {
    const data = {
      congToIds: selectedRowKeys.toString(),
      isPhucTra: isPhucTra ? true : false,
    };
    handleLamMoi(
      data,
      setLoading,
      setFetchDataTable,
      setVisibleLamMoi,
      setSelectedRowKeys
    );
  };
  return (
    <LamMoiModal
      loading={loading}
      visible={visible}
      closeModal={handleCloseModal}
      handleSubmit={handleSubmit}
    />
  );
}

export default memo(Index);
