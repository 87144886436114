import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "antd";

import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  TABINDEX_CMIS,
} from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";

import TaoVatTuModal from "./tao-vat-tu";
import CapNhatVatTuModal from "./cap-nhat-vat-tu";
import { useLocation, useSearchParams } from "react-router-dom";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import { tien_ich_chung } from "@permissions/m_tienichchung";
import CheckPermission from "@components/CheckPermission";
import { closeModal, openModal } from "@state/system-config/reducer";
import { useDispatch } from "react-redux";
import Table from "./table";
import {
  getListThiCong,
  handleListData,
  handleListLoaiChiPhi,
  handleListLoaiYC,
  handleListTableColumns,
  handleRemoveData,
} from "./services";
import HinhThucThiCong from "../hinh-thuc-thi-cong";
import { CheckCircleFilled } from "@ant-design/icons";
import EllipsisTooltip from "@components/EllipsisTooltip";
import { Endpoint } from "@utils/endpoint";
import { dongBoCMIS } from "../services";
import ImportModal from "./modal-import";

export default function Index() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [dataVatTu, setDataVatTu] = useState([]);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [dataHinhThuc, setDataHinhThuc] = useState([]);
  const [dataHinhThucNone, setDataHinhThucNone] = useState([]);
  const [dataLoaiChiPhi, setDataLoaiChiPhi] = useState([]);
  const [visibelHT, setVisibleHT] = useState(false);
  const [dataLoaiYC, setDataLoaiYC] = useState([]);
  const [tableColums, setTableColums] = useState([]);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu

  const getListData = useCallback(() => {
    handleListData(setLoading, filterConditions, setDataVatTu, setTotalCount);
  }, [filterConditions]);

  useEffect(() => {
    getListData();
    // eslint-disable-next-line
  }, [getListData]);

  useEffect(() => {
    getListThiCong(setLoading, setDataHinhThuc, true);
    getListThiCong(setLoading, setDataHinhThucNone, false);
    handleListLoaiChiPhi(setLoading, setDataLoaiChiPhi);
    handleListLoaiYC(setLoading, setDataLoaiYC);
    handleListTableColumns(setLoading, setTableColums);
  }, []);

  const [nameFields, setNameFields] = useState([]);
  const [countYC, setCountYC] = useState([]);
  useEffect(() => {
    const arrIdLoaiYC = [];
    const arrTenLoaiYC = [];
    const arrLoaiDG = [];
    const arrName = [];
    if (tableColums.length > 0) {
      tableColums.map((column) => {
        arrLoaiDG.push(column.loaiDonGia);
        if (column.loaiYeuCaus) {
          setCountYC(column.loaiYeuCaus.length);
          column.loaiYeuCaus.map((item) => {
            arrName.push(item.ma);
            arrIdLoaiYC.push(item.loaiYeuCauId);
            arrTenLoaiYC.push(item.tenLoaiYeuCau);
            return null;
          });
        }
        return null;
      });
    }
    setNameFields(arrName);
  }, [tableColums]);

  //thay đổi pagination table

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const dataChungLoai = [
    {
      value: 0,
      name: "Vật tư khác",
    },
    {
      value: 1,
      name: "Dây cáp",
    },
    {
      value: 2,
      name: "Công tơ",
    },
    {
      value: 3,
      name: "Hòm công tơ",
    },
  ];

  // Hiển thị  các buttom tạo mới, ...
  const genExtra = () => (
    <Fragment>
      <CheckPermission permissionCode={tien_ich_chung.m11httc}>
        <Button
          size="small"
          type="primary"
          className="mr-5px"
          onClick={() => handleOpenHinhThuc()}
        >
          Hình thức thi công
        </Button>
      </CheckPermission>
      <CheckPermission permissionCode={tien_ich_chung.m11tao}>
        <Button
          size="small"
          type="primary"
          className="mr-5px"
          onClick={() =>
            dispatch(
              openModal({
                content: (
                  <TaoVatTuModal
                    getListData={getListData}
                    dataHinhThuc={dataHinhThuc}
                    dataLoaiChiPhi={dataLoaiChiPhi}
                    dataChungLoai={dataChungLoai}
                    dataLoaiYC={dataLoaiYC}
                    dataHinhThucNone={dataHinhThucNone}
                    tableColums={tableColums}
                    nameFields={nameFields}
                    countYC={countYC}
                    closeModal={closeModal}
                    dispatch={dispatch}
                  />
                ),
                size: "large",
                title: "Tạo vật tư",
              })
            )
          }
        >
          Tạo mới
        </Button>
      </CheckPermission>

      <CheckPermission permissionCode={tien_ich_chung.m11import}>
        <Button
          size="small"
          type="primary"
          className="btn-bg-yellow"
          onClick={handleOpenImport}
        >
          Tải vật tư
        </Button>
      </CheckPermission>
    </Fragment>
  );

  const [visibleImport, setVisibleImport] = useState(false);
  const handleOpenImport = useCallback(() => {
    setVisibleImport(!visibleImport);
  }, [visibleImport]);

  const handleCloseImport = () => {
    setVisibleImport(!visibleImport);
  };

  const showImport = useCallback(() => {
    return (
      visibleImport && (
        <ImportModal
          visible={visibleImport}
          close={handleCloseImport}
          getListData={getListData}
        />
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleImport]);

  //khai bái columns

  const columns = [
    {
      title: "Mã vật tư",
      dataIndex: "maVatTu",
      key: "maVatTu",
      width: "10%",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Tên vật tư",
      dataIndex: "tenVatTu",
      key: "tenVatTu",
      width: "27%",
      render: (text) => <EllipsisTooltip title={text}>{text}</EllipsisTooltip>,
      sorter: true,
    },
    {
      title: "Ngày hiệu lực",
      dataIndex: "ngayHieuLuc",
      key: "ngayHieuLuc",
      width: "10%",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Ngày hết hiệu lực",
      dataIndex: "ngayHetHieuLuc",
      key: "ngayHetHieuLuc",
      width: "15%",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Đơn vị tính",
      dataIndex: "donViTinh",
      key: "donViTinh",
      width: "8%",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Vật tư chính",
      dataIndex: "isVatTuChinh",
      key: "isVatTuChinh",
      width: "10%",
      render: (actived) => (
        <div className="table-text-center">
          {actived ? (
            <CheckCircleFilled style={{ fontSize: "20px", color: "#03A4FF" }} />
          ) : null}
        </div>
      ),
    },
    {
      title: "Chủng loại",
      dataIndex: "chungLoai",
      key: "chungLoai",
      width: "10%",
      render: (text) => (
        <div className="table-text-center">
          <span>
            {dataChungLoai.map((item) => {
              if ([text].includes(item.value)) return item.name;
              return null;
            })}
          </span>
        </div>
      ),
      sorter: true,
    },
    {
      title: t("tac_vu"),
      key: "action",
      align: "center",
      // width: "10%",
      render: (row) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={tien_ich_chung.m11sua}>
            <div
              className="action-icon"
              onClick={() =>
                dispatch(
                  openModal({
                    content: (
                      <CapNhatVatTuModal
                        getListData={getListData}
                        dataHinhThuc={dataHinhThuc}
                        detailTableData={row}
                        dataLoaiChiPhi={dataLoaiChiPhi}
                        dataChungLoai={dataChungLoai}
                        tableColums={tableColums}
                        nameFields={nameFields}
                        dataLoaiYC={dataLoaiYC}
                        dataHinhThucNone={dataHinhThucNone}
                        countYC={countYC}
                        closeModal={closeModal}
                        dispatch={dispatch}
                      />
                    ),
                    size: "large",
                    title: "Cập nhật vật tư",
                  })
                )
              }
            >
              <PencilIcon />
            </div>
          </CheckPermission>
          <CheckPermission permissionCode={tien_ich_chung.m11xoa}>
            <div
              className="action-icon"
              onClick={() => handleDeleteData(row.vatTuId)}
            >
              <TrashIcon />
            </div>
          </CheckPermission>
        </div>
      ),
    },
  ];

  //tìm kiếm dữ liệu

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  //xóa dữ liệu

  const handleDeleteData = useCallback(
    (ids) => {
      handleRemoveData(ids, setLoading, getListData, t);
    },
    [getListData, t]
  );

  const handleOpenHinhThuc = useCallback(() => {
    setVisibleHT(!visibelHT);
  }, [visibelHT]);
  //đóng popup

  const handleCloseHinhThuc = () => {
    setVisibleHT(false);
  };

  const showModalHT = useMemo(
    () =>
      visibelHT && (
        <Modal
          title={"Hình thức thi công"}
          visible={visibelHT}
          onCancel={handleCloseHinhThuc}
          className="modal-uy-quyen"
          footer={null}
          width={"60%"}
        >
          <HinhThucThiCong skip={"false"} />
        </Modal>
      ),
    // eslint-disable-next-line
    [visibelHT]
  );

  return (
    <Fragment>
      <Table
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        genExtra={genExtra}
        dataSource={dataVatTu}
        columns={columns}
        totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        t={t}
        dataChungLoai={dataChungLoai}
      />
      {showModalHT}
      {showImport()}
    </Fragment>
  );
}
