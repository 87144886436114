import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import SelectionDoiTuong from "@modules/cham-soc-khach-hang/common/SelectDoiTuong";
import { NAME_TB_TIEN_DIEN_LAN_2 } from "@modules/cham-soc-khach-hang/nhan-tin-email-zalo/constant";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { Col, Divider, Form, Row, Select, Spin } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import XuatExcel from "../bc-doi-soat-sms/tabs/xuat-excel";

const optionsTrangThai = [
  {
    label: "Thành Công",
    value: 2,
  },
  {
    label: "Không Thành Công",
    value: 3,
  },
];
const optionsHinhThuc = [
  {
    label: "SMS",
    value: 1,
  },
  {
    label: "Email",
    value: 2,
  },
  {
    label: "Zalo",
    value: 3,
  },
];

export default function BaoCaoCongTinNhanCSKH() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [maDonVi, setMaDonVi] = useState("");
  const [dataDoiTuong, setDataDoiTuong] = useState([]);
  const [initialDoiTuong, setInitialDoiTuong] = useState("");

  const [filterConditions, setFilterConditions] = useState({
    TuNgay: moment().startOf("month").format("YYYY-MM-DD"),
    DenNgay: moment().format("YYYY-MM-DD"),
    HinhThuc: optionsHinhThuc[0].value,
  });
 
  useEffect(() => {
    if(initialDoiTuong) {
      setFilterConditions((oldState) => ({
      ...oldState,
      DoiTuongId: initialDoiTuong,
    }));
    }
  }, [initialDoiTuong]);
  useEffect(() => {
    setFilterConditions((oldState) => ({
      ...oldState,
      MaDonVi: maDonVi,
    }));
  }, [maDonVi]);

  const handleChangeDoiTuong = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      // DoiTuongId: values,
      DoiTuongId: dataDoiTuong.find((item) => item.name === values).value,
    }));
  };

  const handleChangeTrangThai = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      TrangThai: values,
    }));
  };
  const handleChangeHinhThuc = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      HinhThuc: values,
    }));
  };
  const handleChangeTuNgay = (date, dateString) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      TuNgay: moment(date).format("YYYY-MM-DD"),
    }));
  };
  const handleChangeDenNgay = (date, dateString) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      DenNgay: moment(date).format("YYYY-MM-DD"),
    }));
  };

  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              form={form}
              initialValues={{
                HinhThuc: optionsHinhThuc[0].value,
              }}
            >
              <Row>
                <Col span={15}>
                  <Form.Item
                    name="MaDonVi"
                    label="Đơn vị"
                    labelAlign="left"
                    labelCol={{ span: 4 }}
                  >
                    <Selection
                      url={Endpoint.GET_TEAM_LIST_CODE}
                      formKey="MaDonVi"
                      form={form}
                      setValue={setMaDonVi}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={15}>
                  <Form.Item
                    name="DoiTuongId"
                    label="Đối tượng"
                    labelAlign="left"
                    labelCol={{ span: 4 }}
                    rules={[
                      {
                        required: true,
                        message: "Đối tượng không được để trống",
                      },
                    ]}
                  >
                    <SelectionDoiTuong
                      defaultValue={NAME_TB_TIEN_DIEN_LAN_2}
                      data={dataDoiTuong}
                      setData={setDataDoiTuong}
                      url={Endpoint.CSKH_NHAN_TIN}
                      placeholder="Chọn đối tượng"
                      formKey="DoiTuongId"
                      form={form}
                      setValue={handleChangeDoiTuong}
                      setInitial={setInitialDoiTuong}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    name="TrangThai"
                    label="Trạng thái"
                    labelAlign="left"
                    labelCol={{ span: 5 }}
                  >
                    <Select
                      placeholder={"Tất cả"}
                      options={optionsTrangThai}
                      onChange={handleChangeTrangThai}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="HinhThuc"
                    label="Hình thức"
                    labelAlign="right"
                    labelCol={{ span: 5 }}
                  >
                    <Select
                      defaultValue={optionsHinhThuc[0].value}
                      options={optionsHinhThuc}
                      onChange={handleChangeHinhThuc}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    name="TuNgay"
                    label="Ngày bắt đầu"
                    labelAlign="left"
                    labelCol={{ span: 5 }}
                  >
                    <DatePickerComponent
                      picker="Day"
                      formKey="TuNgay"
                      defaultValue={moment().startOf("month")}
                      form={form}
                      format={"YYYY-MM-DD"}
                      onChange={handleChangeTuNgay}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="DenNgay"
                    label="Ngày kết thúc"
                    labelAlign="right"
                    labelCol={{ span: 5 }}
                  >
                    <DatePickerComponent
                      picker="Day"
                      formKey="DenNgay"
                      form={form}
                      format={"YYYY-MM-DD"}
                      onChange={handleChangeDenNgay}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="end">
                <XuatExcel
                  endpoint={
                    Endpoint.BAOCAO_CONG_TINNHAN_CSKH_EXCEL +
                    "?" +
                    buildQueryString(parseParams(filterConditions))
                  }
                  text={"Xuất Excel"}
                  key={"xuat-excel"}
                />
              </Row>
            </FormComponent>
            <Divider className="section-text-line" orientation="left"></Divider>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
  .ant-form-item-required {
    flex-direction: row;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    content: "";
    display: none;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
    color: #ff4d4f;
    content: "*";
    display: inline-block;
    font-family: SimSun, sans-serif;
    font-size: 14px;
    line-height: 1;
    margin-right: 4px;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
