import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import ButtonComponent from "@components/Button";
import TableComponent from "@components/TableComponent";
import { Endpoint } from "@utils/endpoint";
import { authDeleteData, authGetData } from "@utils/request";
import { Form, Tooltip } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import ModalSuaThongTinDoiTuong from "./modal-sua-doi-tuong";
import ModalThongTinDoiTuong from "./modal-thong-tin-doi-tuong";
const ThongTinDoiTuong = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState();
  const [dataDoituong, setDataDoituong] = useState();
  const [Visible, setVisible] = useState(false);
  const [VisibleEdit, setVisibleEdit] = useState(false);

  const onCancel = useCallback(() => {
    setVisible(false);
    setVisibleEdit(false);
    form.resetFields();
  }, [form]);

  const getData = () => {
    authGetData({
      url: `${Endpoint.CSKH_DOITUONG}`,
      setLoading,
      onSuccess: (res) => {
        if (res) {
          setData(res.data);
        }
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = useCallback((id) => {
    authDeleteData({
      url: `${Endpoint.CSKH_DOITUONG}?id=${id}`,
      setLoading,
      onSuccess: () => {
        authGetData({
          url: `${Endpoint.CSKH_DOITUONG}`,
          setLoading,
          onSuccess: (res) => {
            if (res) {
              setData(res.data);
            }
          },
        });
      },
    });
  }, []);

  const handleOpenModalEditUser = useCallback((doituong) => {
    setVisibleEdit(true);
    setDataDoituong(doituong);
  }, []);

  const columns = [
    {
      title: "Nội dung",
      dataIndex: "noidung",
      key: "noidung",
      width: 300,
      render: (text, record, index) => {
        return (
          <>
            <span key={index}>{record.noiDung}</span>
          </>
        );
      },
    },
    {
      title: "Phân loại",
      dataIndex: "phanloai",
      key: "phanloai",
      render: (text, record, index) => {
        return (
          <>
            <span key={index}>{record.phanLoai}</span>
          </>
        );
      },
    },
    {
      title: "Thứ tự hiển thị",
      dataIndex: "thutuhienthi",
      key: "thutuhienthi",
      width: 100,
      align: "center",
      render: (text, record, index) => {
        return (
          <>
            <span key={index}>{record.stt}</span>
          </>
        );
      },
    },
    {
      title: "Đối tượng nhắn tin ở Web",
      dataIndex: "DoiTuongNhanTinWeb",
      align: "center",
      key: "DoiTuongNhanTinWeb",
      render: (text, record, index) => {
        return (
          <>
            {record.isDoiTuongNhanTinWeb === true ? (
              <span key={index}>O</span>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Đối tượng cú pháp",
      dataIndex: "DoiTuongCuPhap",
      align: "center",
      key: "DoiTuongCuPhap",
      render: (text, record, index) => {
        return (
          <>
            {record.isDoiTuongCuPhap === true ? (
              <span key={index}>O</span>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Báo cáo đối soát SMS - Tiền điện lần 1",
      dataIndex: "BCdoisoatsms",
      align: "center",
      key: "BCdoisoatsms",
      render: (text, record, index) => {
        return (
          <>
            {record.isBaoCaoDoiSoatSMSLan1 === true ? (
              <span key={index}>O</span>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Báo cáo đối soát SMS - Dịch vụ khác",
      dataIndex: "BaoCaoDoiSoatSMSDVK",
      align: "center",
      key: "BaoCaoDoiSoatSMSDVK",
      render: (text, record, index) => {
        return (
          <>
            {record.isBaoCaoDoiSoatSMSDichVuKhac === true ? (
              <span key={index}>O</span>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Báo cáo đối soát Email",
      dataIndex: "BaoCaoDoiSoatEmail",
      align: "center",
      key: "BaoCaoDoiSoatEmail",
      render: (text, record, index) => {
        return (
          <>
            {record.isBaoCaoDoiSoatEmail === true ? (
              <span key={index}>O</span>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Báo cáo đối soát Zalo",
      dataIndex: "BaoCaoDoiSoatZalo",
      align: "center",
      key: "BaoCaoDoiSoatZalo",
      render: (text, record, index) => {
        return (
          <>
            {record.isBaoCaoDoiSoatZalo === true ? (
              <span key={index}>O</span>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Báo cáo đối soát App CSKH",
      dataIndex: "BaoCaoDoiSoatApp",
      align: "center",
      key: "BaoCaoDoiSoatApp",
      render: (text, record, index) => {
        return (
          <>
            {record.isBaoCaoDoiSoatAppCSKH === true ? (
              <span key={index}>O</span>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Tác vụ",
      fixed: "right",
      width: 100,
      render: (row) => (
        <div className="action-table-column">
          <Tooltip title={"Sửa"}>
            <ButtonComponent
              type="link"
              onClick={() => handleOpenModalEditUser(row)}
              className="pd-5px"
            >
              <PencilIcon />
            </ButtonComponent>
          </Tooltip>
          <Tooltip title={"Xóa"}>
            <ButtonComponent
              type="link"
              onClick={() => handleDelete(row.id)}
              className="pd-5px"
            >
              <TrashIcon />
            </ButtonComponent>
          </Tooltip>
        </div>
      ),
    },
  ];

  const genExtra = useCallback(
    () => (
      <ButtonComponent
        size="small"
        type="primary"
        onClick={() => setVisible(true)}
      >
        Tạo mới
      </ButtonComponent>
      // </CheckPermission>
    ),
    []
  );

  return (
    <Container>
      <ModalThongTinDoiTuong
        getData={getData}
        visible={Visible}
        form={form}
        setVisible={setVisible}
        onCancel={onCancel}
      />
      <TableComponent
        header={"Danh sách đối tượng"}
        renderExtraAction={() => genExtra()}
        dataSource={data}
        columns={columns}
        loading={loading}
        scrollX={1600}
      />
      <ModalSuaThongTinDoiTuong
        doituong={dataDoituong}
        getData={getData}
        form={form}
        onCancel={onCancel}
        visible={VisibleEdit}
      />
      ;
    </Container>
  );
};

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
  .ant-table-thead {
    .ant-table-cell {
      background: #d9d9d9;
      color: #1f1f1f;
      font-size: 13px;
    }
  }
  .ant-table-cell-scrollbar {
    right: 0px !important;
  }
`;

export default ThongTinDoiTuong;
