import { BASE_API_URL_DANH_MUC } from "@utils/constants";

export const APILo = {
  LIST_LO: `${BASE_API_URL_DANH_MUC}/lo/list`,
  SYNC_LO: `${BASE_API_URL_DANH_MUC}/lo/sync`,
  CREATE_LO: `${BASE_API_URL_DANH_MUC}/lo/create`,
  UPDATE_LO: `${BASE_API_URL_DANH_MUC}/lo/update`,
  REMOVE_LO: `${BASE_API_URL_DANH_MUC}/lo/delete`,
  GET_LO: `${BASE_API_URL_DANH_MUC}/lo/get`,
};
