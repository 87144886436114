import React, { memo, useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { handleApGiaHoSo, handleDetailHoSoApGia } from "../../services";
import ApGiaHoSoDetail from "./detail-ap-gia-ho-so";
import {
  handleListImage,
  handleListLoaiGT,
} from "@modules/cap-dien/common-ho-so/tai-anh-ho-so-khach-hang/services";
import { handleScroll } from "@state/system-config/reducer";
import { INDEX_TAB2 } from "@utils/constants";

function Index({ detail, closeModal, setFetchDataTable }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detailApGia, setDetailApGia] = useState({});
  const [dataLoaiGT, setDataLoaiGT] = useState([]);
  const [firstData, setFistData] = useState("");
  const [indexTab, setIndexTab] = useState("1");
  const [dataImages, setDataImage] = useState([]);
  const [listId, setListId] = useState([]);

  // thông tin hồ sơ

  useEffect(() => {
    handleDetailHoSoApGia(setLoading, setDetailApGia, detail.id);
    handleListLoaiGT(setLoading, setDataLoaiGT, setFistData);
  }, [detail.id]);
  // handle submit call api áp giá

  const handleSubmit = () => {
    handleApGiaHoSo(
      setLoadingSubmit,
      setFetchDataTable,
      dispatch,
      closeModal,
      detail.id
    );
  };

  const closePopup = () => {
    dispatch(closeModal());
    setFetchDataTable(true);
  };

  const handleChangeType = (value) => {
    setFistData(value);
    scrollToTop(`data-${value}`);
  };

  const scrollToTop = (element) => {
    document
      .getElementById(element)
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const callback = (key) => {
    if (key === INDEX_TAB2) {
      dispatch(
        handleScroll({
          scrollHeight: false,
        })
      );
    } else {
      dispatch(
        handleScroll({
          scrollHeight: true,
        })
      );
    }
    setIndexTab(key);
  };

  const getListImages = useCallback(() => {
    handleListImage(detail, setLoading, setDataImage, setListId);
  }, [detail]);

  useEffect(() => {
    getListImages();
  }, [getListImages]);

  return (
    <ApGiaHoSoDetail
      t={t}
      loading={loading}
      detailApGia={detailApGia}
      detail={detail}
      closePopup={closePopup}
      handleSubmit={handleSubmit}
      loadingSubmit={loadingSubmit}
      dataLoaiGT={dataLoaiGT}
      firstData={firstData}
      handleChangeType={handleChangeType}
      callback={callback}
      indexTab={indexTab}
      dataImages={dataImages}
      listId={listId}
    />
  );
}

export default memo(Index);
