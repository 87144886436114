import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import { Option } from "antd/lib/mentions";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { handleDownload, handleOpenPdf } from "./service";

export default function LienHeLienQuanDenKHTheoTungCTDLCuaToanTCT(props) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    handleDownload(
      {
        ...values,
        reportType:
          Endpoint.BCCD_THSL_TTLH_LQ_DEN_KH_THEO_TUNG_CTDL_CUA_TOAN_TCT,
      },
      setLoading
    );
  };
  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Tổng hợp số liệu thông tin liên hệ liên quan đến khách hàng theo từng
          cty điện lực của toàn tổng công ty
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              colon={false}
              initialValues={{
                LoaiBaoCao: "ALL",
              }}
            >
              <Form.Item
                name="LoaiBaoCao"
                label="Loại báo cáo "
                labelAlign="left"
                required
              >
                <Select placeholder="Tất cả" formKey="LoaiBaoCao">
                  <Option value="ALL">Tất cả</Option>
                  <Option value="X3">X3</Option>
                  <Option value="DONVI">Đơn vị</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="TuNgay"
                label="Từ ngày"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="Day"
                  formKey="TuNgay"
                  form={form}
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>
              <Form.Item
                name="DenNgay"
                label="Đến ngày"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="Day"
                  formKey="DenNgay"
                  form={form}
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>

              <Row>
                <Col span={4}></Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>
                </Col>
                <Col>
                  {/* <Button
                    type="primary"
                    loading={loading}
                    className="ml-20px"
                    onClick={() => {
                      handleOpenPdf(
                        {
                          ...form.getFieldValue(),
                          reportType:
                            Endpoint.BCCD_THSL_TTLH_LQ_DEN_KH_THEO_TUNG_CTDL_CUA_TOAN_TCT,
                        },
                        setLoading
                      );
                    }}
                  >
                    In báo cáo
                  </Button> */}
                </Col>
              </Row>
            </FormComponent>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
