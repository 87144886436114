import CheckPermission from "@components/CheckPermission";
import { cap_dien } from "@permissions/a_capdien";
import { LITMIT_TRANGTHAI, SHOW_UPLOAD_SCAN } from "@utils/constants";
import { Menu } from "antd";
import TaiAnhHoSoKhachHang from "../../common-ho-so/tai-anh-ho-so-khach-hang";
const { SubMenu } = Menu;

export default function ListMenu(props) {
  const {
    t,
    dispatch,
    openModal,
    closeModal,
    detail,
    handleOpenModalInHoSoCMIS,
    handleOpenModalXemLaiPhuongAnCapDien,
    handleOpenModalQuyetToanKhachHang,
    handleOpenModalQuyetToanCongTy,
    handleOpenModalInBieuKhaiGia,
    inBienBanApGia,
    handleOpenInPhuTai,
    funcID,
  } = props;

  return (
    <Menu mode="vertical" style={{ width: "100%" }} selectedKeys={["0"]}>
      <CheckPermission permissionCode={cap_dien.a401_tai_anh_hskh}>
        {SHOW_UPLOAD_SCAN.includes(detail.maTrangThai) ? (
          <Menu.Item
            eventKey="5"
            onClick={() =>
              dispatch(
                openModal({
                  content: (
                    <TaiAnhHoSoKhachHang
                      closeModal={closeModal}
                      detail={detail}
                      isShowLanhDao={true}
                      funcID={funcID}
                    />
                  ),
                  size: "large",
                })
              )
            }
          >
            {t("common_cap_dien.tai_anh_ho_so_khach_hang")}
          </Menu.Item>
        ) : null}
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a401_in_hs_cmis}>
        <Menu.Item eventKey="8" onClick={handleOpenModalInHoSoCMIS}>
          {" "}
          {t("common_cap_dien.in_ho_so_cmis")}
        </Menu.Item>
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a401_in_hs_web}>
        {Number(detail.maTrangThai) >= LITMIT_TRANGTHAI ? (
          <SubMenu eventKey="sub1" title={t("common_cap_dien.in_ho_so_web")}>
            <Menu.Item
              eventKey="9"
              onClick={handleOpenModalXemLaiPhuongAnCapDien}
            >
              {" "}
              {t("common_cap_dien.xem_lai_phuong_an_cap_dien")}
            </Menu.Item>
            <Menu.Item eventKey="20" onClick={handleOpenInPhuTai}>
              In biểu đồ phụ tải
            </Menu.Item>
            <Menu.Item
              eventKey="10"
              onClick={handleOpenModalQuyetToanKhachHang}
            >
              {" "}
              {t("common_cap_dien.quyet_toan_khach_hang")}
            </Menu.Item>
            <Menu.Item eventKey="11" onClick={handleOpenModalQuyetToanCongTy}>
              {" "}
              {t("common_cap_dien.quyet_toan_cong_ty")}
            </Menu.Item>
            <Menu.Item eventKey="12" onClick={handleOpenModalInBieuKhaiGia}>
              {" "}
              {t("common_cap_dien.in_bieu_khai_gia")}
            </Menu.Item>
            <Menu.Item eventKey="13" onClick={inBienBanApGia}>
              {" "}
              {t("common_cap_dien.in_bien_ban_ap_gia")}
            </Menu.Item>
          </SubMenu>
        ) : null}
      </CheckPermission>
    </Menu>
  );
}
