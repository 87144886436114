import { Fragment } from "react";
import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { Container } from "./css-styled";
import ListFilter from "./list-bo-loc";

export default function ListYeuCauTable(props) {
  const {
    typeList,
    loading,
    dataSource,
    totalData,
    handleSearch,
    clearFilter,
    onChangePagination,
    filterConditions,
    rowSelection,
    genExtra,
    user,
    columns,
    header,
    loaiBangKe,
    trangThai,
    firstTrangThai,
    rowKey,
    onRow,
    checkTraLai,
    onChangeTrangThai,
    urlXuatExcel,
    permissionExcel,
  } = props;
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          tooltip={"Tìm kiếm theo mã bảng kê, loại bảng kê"}
          user={user}
          typeList={typeList}
          loaiBangKe={loaiBangKe}
          trangThai={trangThai}
          firstTrangThai={firstTrangThai}
          checkTraLai={checkTraLai}
          onChangeTrangThai={onChangeTrangThai}
          urlXuatExcel={urlXuatExcel}
          permissionExcel={permissionExcel}
        />
      </Filter>
      <Container>
        <TableComponent
          header={header}
          renderExtraAction={() => genExtra()}
          dataSource={dataSource}
          columns={columns}
          totalData={totalData}
          loading={loading}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
          scrollY={520}
          scrollX={1400}
          rowSelection={rowSelection ? rowSelection : null}
          rowKey={rowKey}
          onRow={onRow ? onRow : null}
          pageSizeOptionKTCT={
            totalData > 500
              ? [10, 20, 50, 100, 200, 500, totalData]
              : [10, 20, 50, 100, 200, 500]
          }
        />
      </Container>
    </Fragment>
  );
}
