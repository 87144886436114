import { Collapse } from "antd";
import { useState } from "react";
import ThongTinDangTreo from "./thong-tin-dang-treo";
import ThongTinTreoMoi from "./thong-tin-treo-moi-ti";
import ThongTinTreoMoiTU from "./thong-tin-treo-moi-tu";

const { Panel } = Collapse;

export default function Index(props) {
  const {
    detailTITreoMoi,
    detailTIDangTreo,
    detailTUTreoMoi,
    detailTUDangTreo,
    setIsChangedTIMoi,
    setIsChangedTUMoi,
    soPha,
    form,
    setCustomTINew,
    setCustomTUNew,
    setChangeTab3,
  } = props;

  const [dataTITreo, setDataTITreo] = useState(detailTIDangTreo);
  const [dataTUTreo, setDataTUTreo] = useState(detailTUDangTreo);
  return (
    <Collapse
      defaultActiveKey={["1", "2", "3", "4"]}
      style={{ marginTop: "12px" }}
    >
      <Panel header="Thông tin TI ĐANG TREO" key="1">
        <ThongTinDangTreo
          form={form}
          detail={dataTITreo}
          setDataTreo={setDataTITreo}
        />
      </Panel>
      {form.getFieldValue("loaiBienBan") === "3" || soPha === 1 ? null : ( //
        <Panel header="Thông tin TI TREO MỚI" key="2">
          <ThongTinTreoMoi
            form={form}
            detail={detailTITreoMoi}
            setIsChangedTIMoi={setIsChangedTIMoi}
            dataTreo={dataTITreo}
            setCustomData={setCustomTINew}
            setChangeTab3={setChangeTab3}
          />
        </Panel>
      )}

      <Panel header="Thông tin TU ĐANG TREO" key="3">
        <ThongTinDangTreo
          form={form}
          detail={detailTUDangTreo}
          isTU
          setDataTreo={setDataTUTreo}
        />
      </Panel>
      {form.getFieldValue("loaiBienBan") === "3" || soPha === 1 ? null : ( //
        <Panel header="Thông tin TU TREO MỚI" key="4">
          <ThongTinTreoMoiTU
            form={form}
            detail={detailTUTreoMoi}
            setIsChanged={setIsChangedTUMoi}
            dataTreo={dataTUTreo}
            setCustomData={setCustomTUNew}
            setChangeTab3={setChangeTab3}
          />
        </Panel>
      )}
    </Collapse>
  );
}
