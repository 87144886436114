import React, { Fragment } from "react";
import {
  Col,
  Row,
  Form,
  DatePicker,
  TimePicker,
  Input,
  Typography,
} from "antd";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import {
  ENUM_MODE_PHUONGANCAPDIEN,
  FORMAT_DATE,
  FORMAT_TIME,
} from "@utils/constants";
import moment from "moment";

const { TextArea } = Input;

export default function UILuuYThiCong(props) {
  const {
    setValidateFieldsName,
    validateFieldsName,
    mode,
    handleValidateFieldNames,
    form,
  } = props;
  return (
    <Fragment>
      <Col span={24}>
        <span className="fs-16px font-bold">4. Lưu ý khi thi công</span>
      </Col>
      <Col span={24}>
        <Row gutter={24}>
          <Col span={24} md={4} style={{ paddingTop: 8 }}>
            <span className="fs-14px">Thời gian thi công</span>
          </Col>
          <Col span={24} md={6}>
            <Row>
              <Col flex={0.5}>
                <div className="fs-14px" style={{ paddingTop: 6 }}>
                  Từ:
                </div>
              </Col>
              <Col flex={4}>
                {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
                  <Form.Item
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues._thoiGianThiCongTu !==
                      curValues._thoiGianThiCongTu
                    }
                  >
                    {({ getFieldValue }) => {
                      const thoiGianThiCongTu =
                        getFieldValue("_thoiGianThiCongTu") || undefined;
                      return thoiGianThiCongTu ? (
                        <Typography.Text className="ant-form-text" strong>
                          {`${moment(thoiGianThiCongTu).format(FORMAT_TIME)}`}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="_thoiGianThiCongTu"
                    validateTrigger={handleValidateFieldNames(
                      "_thoiGianThiCongTu"
                    )}
                    rules={[
                      {
                        validator(_, value) {
                          if (value) {
                            if (
                              !form.getFieldValue("_thoiGianThiCongDen") ||
                              !form.getFieldValue("_ngayThiCong")
                            ) {
                              const errors = [];
                              if (!form.getFieldValue("_thoiGianThiCongDen")) {
                                errors.push({
                                  name: "_thoiGianThiCongDen",
                                  errors: ["Không được phép để trống"],
                                });
                              }
                              if (!form.getFieldValue("_ngayThiCong")) {
                                errors.push({
                                  name: "_ngayThiCong",
                                  errors: ["Không được phép để trống"],
                                });
                              }
                              form.setFields([...errors]);
                              form.scrollToField(errors[0]?.name);
                            }
                          } else {
                            const errors = [
                              {
                                name: "_thoiGianThiCongDen",
                                errors: [],
                              },
                              {
                                name: "_ngayThiCong",
                                errors: [],
                              },
                            ];
                            form.setFields([...errors]);
                          }
                          return Promise.resolve(value);
                        },
                      },
                    ]}
                    style={{ width: "100%", padding: 0 }}
                  >
                    <TimePicker
                      format={FORMAT_TIME}
                      locale={{
                        ...locale,
                        lang: {
                          ...locale.lang,
                          ok: "Chọn",
                        },
                      }}
                      disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                      onBlur={() =>
                        setValidateFieldsName([
                          ...validateFieldsName,
                          "_thoiGianThiCongTu",
                        ])
                      }
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={24} md={6}>
            <Row>
              <Col flex={0.5}>
                <div style={{ paddingTop: 6 }}>Đến: </div>
              </Col>
              <Col flex={4}>
                {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
                  <Form.Item
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues._thoiGianThiCongDen !==
                      curValues._thoiGianThiCongDen
                    }
                  >
                    {({ getFieldValue }) => {
                      const thoiGianThiCongDen =
                        getFieldValue("_thoiGianThiCongDen") || undefined;
                      return thoiGianThiCongDen ? (
                        <Typography.Text className="ant-form-text" strong>
                          {`${moment(thoiGianThiCongDen).format(FORMAT_TIME)}`}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="_thoiGianThiCongDen"
                    validateTrigger={handleValidateFieldNames(
                      "_thoiGianThiCongDen"
                    )}
                    rules={[
                      {
                        validator(_, value) {
                          if (value) {
                            if (
                              !form.getFieldValue("_thoiGianThiCongTu") ||
                              !form.getFieldValue("_ngayThiCong")
                            ) {
                              const errors = [];
                              if (!form.getFieldValue("_thoiGianThiCongTu")) {
                                errors.push({
                                  name: "_thoiGianThiCongTu",
                                  errors: ["Không được phép để trống"],
                                });
                              }
                              if (!form.getFieldValue("_ngayThiCong")) {
                                errors.push({
                                  name: "_ngayThiCong",
                                  errors: ["Không được phép để trống"],
                                });
                              }
                              form.setFields([...errors]);
                              form.scrollToField(errors[0]?.name);
                            }
                          } else {
                            const errors = [
                              {
                                name: "_thoiGianThiCongTu",
                                errors: [],
                              },
                              {
                                name: "_ngayThiCong",
                                errors: [],
                              },
                            ];
                            form.setFields([...errors]);
                          }
                          return Promise.resolve(value);
                        },
                      },
                    ]}
                  >
                    <TimePicker
                      format={FORMAT_TIME}
                      locale={{
                        ...locale,
                        lang: {
                          ...locale.lang,
                          ok: "Chọn",
                        },
                      }}
                      className="x"
                      disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                      onBlur={() =>
                        setValidateFieldsName([
                          ...validateFieldsName,
                          "_thoiGianThiCongDen",
                        ])
                      }
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={24} md={8}>
            <Row>
              <Col flex={0.5}>
                <div style={{ paddingTop: 6 }}>Ngày: </div>
              </Col>
              <Col flex={7.5}>
                {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
                  <Form.Item
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues._ngayThiCong !== curValues._ngayThiCong
                    }
                  >
                    {({ getFieldValue }) => {
                      const ngayThiCong =
                        getFieldValue("_ngayThiCong") || undefined;
                      return ngayThiCong ? (
                        <Typography.Text className="ant-form-text" strong>
                          {`${moment(ngayThiCong).format(FORMAT_DATE)}`}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="_ngayThiCong"
                    validateTrigger={handleValidateFieldNames("_ngayThiCong")}
                    rules={[
                      {
                        validator(_, value) {
                          if (value) {
                            if (
                              !form.getFieldValue("_thoiGianThiCongTu") ||
                              !form.getFieldValue("_thoiGianThiCongDen")
                            ) {
                              const errors = [];
                              if (!form.getFieldValue("_thoiGianThiCongTu")) {
                                errors.push({
                                  name: "_thoiGianThiCongTu",
                                  errors: ["Không được phép để trống"],
                                });
                              }
                              if (!form.getFieldValue("_thoiGianThiCongDen")) {
                                errors.push({
                                  name: "_thoiGianThiCongDen",
                                  errors: ["Không được phép để trống"],
                                });
                              }
                              form.setFields([...errors]);
                              form.scrollToField(errors[0]?.name);
                            }
                          } else {
                            const errors = [
                              {
                                name: "_thoiGianThiCongTu",
                                errors: [],
                              },
                              {
                                name: "_thoiGianThiCongDen",
                                errors: [],
                              },
                            ];
                            form.setFields([...errors]);
                          }
                          return Promise.resolve(value);
                        },
                      },
                    ]}
                  >
                    <DatePicker
                      locale={locale}
                      format={FORMAT_DATE}
                      disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                      onBlur={() =>
                        setValidateFieldsName([
                          ...validateFieldsName,
                          "_ngayThiCong",
                        ])
                      }
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={24} className="mt-16px">
          <Col span={4}>
            <span className="fs-14px">Ghi chú thi công</span>
          </Col>
          <Col span={20}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.ghiChuThiCong !== curValues.ghiChuThiCong
                }
              >
                {({ getFieldValue }) => {
                  const ghiChuThiCong =
                    getFieldValue("ghiChuThiCong") || undefined;
                  return ghiChuThiCong ? (
                    <Typography.Text strong>{ghiChuThiCong}</Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            ) : (
              <Form.Item name="ghiChuThiCong">
                <TextArea
                  rows={3}
                  placeholder="Lưu ý khi thi công"
                  disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Col>
    </Fragment>
  );
}
