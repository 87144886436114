import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { authGetData, authPostData, downloadFile } from "@utils/request";
import { buildQueryString, parseParams } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import { alertMessage422 } from "../service";

export const EndpointBTLTNTD = Endpoint.BCCN_BIEU_TY_LE_TH_THU_NOP_TIEN_DIEN;
export const EndpointBCCDDT = Endpoint.BCCN_BC_CHAM_DIEM_DONG_TIEN;
export const EndpointBCDNTN = Endpoint.BCCN_BC_DU_NO_THUY_NONG;
export const EndpointBCDNDD = Endpoint.BCCN_BC_DU_NO_DEN_DUONG;
export const EndpointSTDTCKDD13G =
  Endpoint.BCCN_TH_SO_TIEN_DA_THU_CUA_KHACH_DUNG_DIEN_13G;

export const EndpointTHSTKHCN13H =
  Endpoint.BCCN_TH_SO_TIEN_KHACH_HANG_CON_NO_13H;

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LuyKe: data.LuyKe,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      LoaiKhachHang: data.LoaiKhachHang,
      LoaiTongHop: data.LoaiTongHop,
      SoNgay: data.SoNgay,
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LuyKe: data.LuyKe,
      LoaiBC: data.LoaiBC,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      LoaiKhachHang: data.LoaiKhachHang,
      LoaiTongHop: data.LoaiTongHop,
      SoNgay: data.SoNgay,
    })
  );

  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};

export const handleCapNhatBaoCao = (data, setLoading, url, form) => {
  authPostData({
    url: url,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        form.resetFields();
      }
    },
  });
};

export const handleListBaoCao = (
  url,
  filterConditions,
  setLoading,
  setDataTable,
  setVisible
) => {
  const queryString = buildQueryString(parseParams(filterConditions));
  authGetData({
    url: `${url}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataTable(res.data);
        setVisible(true);
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
