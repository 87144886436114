import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Form, Modal, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import LoadingComponent from "@components/Loading";
import {
  handleListDataTime,
  handleRemoveListTime,
  handleSubmitForm,
} from "./services";
import { FORMAT_ENGLISH } from "@utils/constants";
import moment from "moment";
import LayoutModal from "./layout-modal";

function CauHinhHoSoModal({ detail, visible, closeModal, getListData }) {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [dataTime, setDataTime] = useState({});
  const [backPaging, setBackPaging] = useState(1);
  const [paging, setPaging] = useState({});
  const [isAdd, setIsAdd] = useState(false);
  const [searchTerm, setSearchTerm] = useState({
    id: detail.id,
    pageIndex: 1,
  });

  const handleListTime = useCallback(() => {
    handleListDataTime(
      searchTerm,
      setLoading,
      setDataTime,
      setPaging
      // setNgayBatDau,
      // setNgayKetThuc
    );
  }, [searchTerm]);

  useEffect(() => {
    handleListTime();
  }, [handleListTime]);

  //change ngày hiệu lực
  const handleChangeBatDau = (date) => {
    let convert = "";
    if (date === null) convert = undefined;
    else convert = moment(date).format(FORMAT_ENGLISH);
    setDataTime({
      ...dataTime,
      ngayHieuLuc: convert,
    });
  };

  //change ngày hết hiệu lực
  const handleChangeKetThuc = (date) => {
    let convert = "";
    if (date === null) convert = undefined;
    else convert = moment(date).format(FORMAT_ENGLISH);
    setDataTime({
      ...dataTime,
      ngayHetHieuLuc: convert,
    });
  };

  //submit data
  const handleSubmitData = (values) => {
    if (isAdd) {
      values.isThemThongTin = true;
    } else values.isThemThongTin = false;

    values.hoSoCauHinhThoiGianHieuLuc = {
      id: isAdd ? null : dataTime.id,
      giaTri: values.giaTri,
      ngayHieuLuc: dataTime.ngayHieuLuc,
      ngayHetHieuLuc: dataTime.ngayHetHieuLuc,
    };

    delete values.giaTri;
    delete values.ngayHetHieuLuc;
    delete values.ngayHieuLuc;

    handleSubmitForm(values, detail, setLoading, closeModal, getListData, form);
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();
    // eslint-disable-next-line
  }, []);

  //change pagination
  const handleChangePage = useCallback(
    (page) => {
      setSearchTerm({
        ...searchTerm,
        pageIndex: page,
      });
      setBackPaging(page);
    },
    [searchTerm]
  );

  //render pagination html
  const renderPagination = useCallback(
    () =>
      !isAdd &&
      dataTime &&
      Object.keys(dataTime).length > 0 && (
        <Pagination
          total={paging?.totalCount}
          defaultPageSize={1}
          onChange={handleChangePage}
          current={paging?.pageIndex}
        />
      ),
    [dataTime, handleChangePage, isAdd, paging?.pageIndex, paging?.totalCount]
  );

  //add data thông tin đơn giá
  const handleAddThongTin = useCallback(() => {
    // setPaging({
    //   ...paging,
    //   totalCount: (paging.totalCount += 1),
    //   pageIndex: (paging.totalCount += 1),
    // });

    // const ngay = moment(
    //   detail.hoSoCauHinhThoiGianHieuLucs[detail.hoSoCauHinhThoiGianHieuLucs.length - 1].ngayHieuLuc
    // )
    //   .add(1, "d")
    //   .format(FORMAT_ENGLISH);

    setDataTime({
      id: null,
      giaTri: "",
      ngayHieuLuc: undefined,
      ngayHetHieuLuc: undefined,
    });

    setIsAdd(true);
  }, []);

  //back
  const handleBack = useCallback(() => {
    setSearchTerm({
      ...searchTerm,
      pageIndex: backPaging,
    });
    setIsAdd(false);
  }, [backPaging, searchTerm]);

  //xóa thông tin
  const handleDeleteThongTin = useCallback(
    (id) => {
      Modal.confirm({
        centered: true,
        title: "Xác nhận",
        content: `Bạn chắc rằng muốn xóa thông tin đơn giá này?`,
        onOk() {
          //call api
          handleRemoveListTime(id, setLoading, handleListTime);
        },
        onCancel() {},
        okText: "Đồng ý",
        cancelText: "Hủy",
      });
    },
    [handleListTime]
  );

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={detail.id ? "Cập nhật cấu hình hồ sơ" : "Tạo mới cấu hình hồ sơ"}
        visible={visible}
        onCancel={closeModal}
        className="modal-cau-hinh"
        footer={null}
      >
        <LayoutModal
          closeModal={closeModal}
          form={form}
          handleSubmitData={handleSubmitData}
          detail={detail}
          // ngayBatDau={ngayBatDau}
          // ngayKetThuc={ngayKetThuc}
          dataTime={dataTime}
          t={t}
          inputRef={inputRef}
          renderPagination={renderPagination}
          isAdd={isAdd}
          handleAddThongTin={handleAddThongTin}
          handleDeleteThongTin={handleDeleteThongTin}
          handleChangeBatDau={handleChangeBatDau}
          handleChangeKetThuc={handleChangeKetThuc}
          loading={loading}
          handleBack={handleBack}
        />
      </Modal>
    </LoadingComponent>
  );
}

export default memo(CauHinhHoSoModal);
