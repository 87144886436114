import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Form } from "antd";
import {
  FORMAT_ENGLISH_TIME_FULL,
  TYPE_PHIEUXUAT_CHUCNANG,
} from "@utils/constants";
import TrashIcon from "@assets/icon/TrashIcon";
import {
  getListdataDSYCLQ,
  handleCreateOrUpdate,
  handleShowInfomationPhieu,
  handleShowYeuCauByPhieu,
} from "../services";
import LayoutPhieuXuat from "./layout";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Index(props) {
  const {
    data,
    type,
    setFetchList,
    selectRowKeys,
    typeLoaiPhieu,
    nguoiTao,
    typeTreoThao,
    doiId,
    filterConditions,
  } = props;
  const [loading, setLoading] = useState(false);
  const [dataVT, setDataVT] = useState([]);
  const [dataVTLQ, setDataVTLQ] = useState([]);
  const [dataDSYCLQ, setDataDSYCLQ] = useState([]);
  //data
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [tenLoaiTT, setTenLoaiTT] = useState(
    data && data.length > 0 ? data[0].tenLoaiTreoThao : null
  );
  const [tenDoi, setTenDoi] = useState(
    data && data.length > 0 ? data[0].doi : null
  );
  const [ngayTao] = useState(
    data && data.length > 0 ? data[0].ngayTaoDate : null
  );
  const [lyDo, setLyDo] = useState("");
  const [isTraLai, setIsTraLai] = useState(false);
  //Set thông tin người tao
  const [nguoiSuaPhieu, setNguoiSuaPhieu] = useState(
    data && data.length > 0 ? data[0].nguoiTao : null
  );
  const [yeuCauIds, setYeuCauIds] = useState(
    type === TYPE_PHIEUXUAT_CHUCNANG.TAOMOI ? selectRowKeys : []
  );
  const defaultColumns =
    type === TYPE_PHIEUXUAT_CHUCNANG.TAOMOI
      ? [
          {
            title: "STT",
            dataIndex: "stt",
            key: "stt",
            render: (text, record, index) => (
              <div className="table-text-right">{index + 1}</div>
            ),
            width: "10%",
          },
          {
            title: "Chủng loại",
            dataIndex: "chungLoai",
            key: "chungLoai",
            render: (text) => <div className="table-text-center">{text}</div>,
            width: "15%",
          },
          {
            title: "Loại công tơ",
            dataIndex: "loaiCongTo",
            key: "loaiCongTo",
            render: (text) => <div className="table-text-left">{text}</div>,
            width: "30%",
          },
          {
            title: "Mô tả",
            dataIndex: "moTa",
            key: "moTa",
            render: (text) => <div className="table-text-left">{text}</div>,
            width: "30%",
          },
          {
            title: "Số lượng yêu cầu",
            dataIndex: "soLuongYeuCau",
            key: "soLuongYeuCau",
            render: (text) => <div className="table-text-right">{text}</div>,
            width: "15%",
          },
        ]
      : //Kiểm tra thêm điều kiện nếu isTraLai bằng false, sẽ không hiển thị các column soLuongCoTheXuat, chenhLech
      isTraLai === false
      ? [
          {
            title: "STT",
            dataIndex: "stt",
            key: "stt",
            render: (text, record, index) => (
              <div className="table-text-right">{index + 1}</div>
            ),
            width: "10%",
          },
          {
            title: "Chủng loại",
            dataIndex: "chungLoai",
            key: "chungLoai",
            render: (text) => <div className="table-text-center">{text}</div>,
            width: "15%",
          },
          {
            title: "Loại công tơ",
            dataIndex: "loaiCongTo",
            key: "loaiCongTo",
            render: (text) => <div className="table-text-left">{text}</div>,
            width: "20%",
          },
          {
            title: "Mô tả",
            dataIndex: "moTa",
            key: "moTa",
            render: (text) => <div className="table-text-left">{text}</div>,
            width: "25%",
          },
          {
            title: "Số lượng yêu cầu",
            dataIndex: "soLuongYeuCau",
            key: "soLuongYeuCau",
            render: (text) => <div className="table-text-right">{text}</div>,
            width: "10%",
          },
        ]
      : [
          {
            title: "STT",
            dataIndex: "stt",
            key: "stt",
            render: (text, record, index) => (
              <div className="table-text-right">{index + 1}</div>
            ),
            width: "10%",
          },
          {
            title: "Chủng loại",
            dataIndex: "chungLoai",
            key: "chungLoai",
            render: (text) => <div className="table-text-center">{text}</div>,
            width: "10%",
          },
          {
            title: "Loại công tơ",
            dataIndex: "loaiCongTo",
            key: "loaiCongTo",
            render: (text) => <div className="table-text-left">{text}</div>,
            width: "20%",
          },
          {
            title: "Mô tả",
            dataIndex: "moTa",
            key: "moTa",
            render: (text) => <div className="table-text-left">{text}</div>,
            width: "25%",
          },
          {
            title: "Số lượng yêu cầu",
            dataIndex: "soLuongYeuCau",
            key: "soLuongYeuCau",
            render: (text) => <div className="table-text-right">{text}</div>,
            width: "10%",
          },

          {
            title: "Số lượng có thể xuất",
            dataIndex: "soLuongCoTheXuat",
            key: "soLuongCoTheXuat",
            render: (text, record) => (
              <div
                className="table-text-right"
                style={
                  record?.soLuongCoTheXuat >= record?.soLuongYeuCau
                    ? { color: "black" }
                    : { color: "red" }
                }
              >
                {text}
              </div>
            ),
            width: "15%",
            editable: true,
          },
          {
            title: "Chênh lệch",
            dataIndex: "chenhLech",
            key: "chenhLech",
            render: (_, record, index) => (
              <Form.Item
                name={`chechLech-${index}`}
                style={{ marginBottom: 0, fontSize: "16px" }}
              >
                <div
                  className="table-text-right"
                  style={
                    record.soLuongYeuCau -
                      (record.soLuongCoTheXuat === undefined ||
                      record.soLuongCoTheXuat === null
                        ? 0
                        : record.soLuongCoTheXuat) >
                    0
                      ? { color: "blue" }
                      : record.soLuongYeuCau -
                          (record.soLuongCoTheXuat === undefined ||
                          record.soLuongCoTheXuat === null
                            ? 0
                            : record.soLuongCoTheXuat) <
                        0
                      ? { color: "red" }
                      : { color: "black" }
                  }
                >
                  {record.soLuongCoTheXuat === undefined ||
                  record.soLuongCoTheXuat === null
                    ? record.soLuongYeuCau
                    : record.soLuongYeuCau - record.soLuongCoTheXuat}
                </div>
              </Form.Item>
            ),
            width: "10%",
          },
        ];

  const columnsLQ = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text, record, index) => (
        <div className="table-text-right">{index + 1}</div>
      ),
      width: "5%",
    },
    {
      title: "Chủng loại",
      dataIndex: "chungLoai",
      key: "chungLoai",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "8%",
    },
    {
      title: "Loại công tơ treo",
      dataIndex: "loaiCongTo",
      key: "loaiCongTo",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
    },
    {
      title: "Ngày treo tháo",
      dataIndex: "ngayTreoThao",
      key: "ngayTreoThao",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "10%",
    },
    {
      title: "Loại treo tháo",
      dataIndex: "loaiTreoThao",
      key: "loaiTreoThao",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Trạm",
      dataIndex: "tram",
      key: "tram",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "7%",
    },
    {
      title: "Đội",
      dataIndex: "doi",
      key: "doi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
    },
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "12%",
    },
    {
      title: "Tên KH - SĐT",
      dataIndex: "tenKH",
      key: "tenKH",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
    },
    {
      title: "Tác vụ",
      dataIndex: "tacVu",
      key: "tacVu",
      fixed: "right",
      render: (_, record) => (
        <Fragment>
          {dataVTLQ.length === 1 ? null : (
            <div className="action-table-column">
              <div
                className="action-icon"
                onClick={() => handleDeleteData(record.id)}
              >
                <TrashIcon />
              </div>
            </div>
          )}
        </Fragment>
      ),
      width: "5%",
    },
  ];

  const handleSave = (row) => {
    const newData = [...dataVT];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataVT(newData);
  };

  // const components = {
  //   body: {
  //     row: EditableRow,
  //     cell: EditableCell,
  //   },
  // };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  //list data vt lien quan
  const getListDataVTLQ = useCallback(() => {
    if (type === TYPE_PHIEUXUAT_CHUCNANG.TAOMOI) {
      handleShowInfomationPhieu(
        setLoading,
        setDataVT,
        setDataVTLQ,
        selectRowKeys,
        typeLoaiPhieu
      );
    } else {
      handleShowYeuCauByPhieu(
        setLoading,
        setDataVT,
        setDataVTLQ,
        selectRowKeys,
        setTenLoaiTT,
        // setNgayTao,
        setTenDoi,
        setLyDo,
        setIsTraLai,
        setNguoiSuaPhieu,
        setYeuCauIds
      );
    }
  }, [selectRowKeys, typeLoaiPhieu, type]);

  useEffect(() => {
    getListDataVTLQ();
  }, [getListDataVTLQ]);

  useEffect(() => {
    getListdataDSYCLQ(
      setLoading,
      setDataDSYCLQ,
      typeLoaiPhieu,
      typeTreoThao,
      doiId,
      yeuCauIds
    );
  }, [typeLoaiPhieu, typeTreoThao, doiId, yeuCauIds]);

  const [valueSearch, setValueSearch] = useState(undefined);
  //search dữ liệu yêu cầu liên quan
  const onSearch = (value) => {
    setValueSearch(value);
    let disabled = false;
    if (value === "" || value === undefined || value === null) disabled = true;
    else disabled = false;
    setDisabled(disabled);
  };

  //xóa dữ liệu
  const handleDeleteData = useCallback(
    (id) => {
      const newData = dataVTLQ.filter((item) => item.id === id);
      setDataDSYCLQ(dataDSYCLQ.concat(newData));

      //Tìm vị trí nếu có tồn tại trong mảng.
      const indexSearch = dataVTLQ.findIndex((e) => e.id === id);
      //xóa phần tử trong mảng
      const newDataVTLQ = dataVTLQ.filter((e) => e.id !== id);
      setDataVTLQ(newDataVTLQ);

      // Tìm kiếm dữ liệu tương ứng trong mảng để xử lý
      dataVT.map((el, index) => {
        if (el.chungLoai === dataVTLQ[indexSearch]?.chungLoai)
          return (dataVT[index].soLuongYeuCau = parseInt(el.soLuongYeuCau) - 1);
        return null;
      });

      // eslint-disable-next-line array-callback-return
      dataVT.map((el) => {
        if (
          el.chungLoai === dataVTLQ[indexSearch]?.chungLoai &&
          el.soLuongYeuCau <= 0
        )
          return null;
      });
      const filtered = dataVT.filter((el) => {
        return el.soLuongYeuCau !== 0;
      });
      setDataVT(filtered);
    },
    [dataVTLQ, dataVT, dataDSYCLQ]
  );

  //TẠO YÊU CẦU LIÊN QUAN
  const handleCreateLQ = () => {
    let arr = [];
    dataDSYCLQ.map((item) => {
      // Kiểm tra yêu cầu được chọn có chứa trong DS Tìm KIếm(dataDSYCLQ) chưa?
      if ([valueSearch].includes(item.id)) {
        arr = [...dataVTLQ, item];
      }
      return true;
    });

    // Cập nhật lại state của mảng
    setDataVTLQ(arr);
    //Tìm vị trí nếu có tồn tại trong mảng.
    const indexSearch = dataDSYCLQ.findIndex((e) => e.id === valueSearch);
    let isDuplicate = false;
    //Tim kiếm dữ liệu tương ứng trong mảng để thêm
    dataVT.map((item) => {
      if ([dataDSYCLQ[indexSearch]?.chungLoai].includes(item?.chungLoai)) {
        if (item.soLuongYeuCau === null || item.soLuongYeuCau === "null")
          item.soLuongYeuCau = 1;
        else item.soLuongYeuCau = parseInt(item.soLuongYeuCau) + 1;
        isDuplicate = true;
      }
      return true;
    });
    if (!isDuplicate) {
      dataDSYCLQ[indexSearch].soLuongYeuCau = 1;
      setDataVT(dataVT.concat([dataDSYCLQ[indexSearch]]));
    }
    const newData = dataDSYCLQ.filter((item) => item.id !== valueSearch);
    setDataDSYCLQ(newData);
    setValueSearch(undefined);
    setDisabled(true);
  };

  //submit dữ liệu
  const onFinishSubmit = (values) => {
    const toDay = moment().format(FORMAT_ENGLISH_TIME_FULL);
    const newDataVTXuat = [];
    dataVT.map((item) => {
      return newDataVTXuat.push({
        chungLoaiId: item.chungLoaiId,
        soLuongYeuCau: item.soLuongYeuCau,
        chungLoai: item.chungLoai,
        soLuongCoTheXuat: item.soLuongCoTheXuat
          ? parseInt(item.soLuongCoTheXuat)
          : null,
      });
    });
    let id = null;
    if (type === TYPE_PHIEUXUAT_CHUCNANG.THAYDOI) {
      id = selectRowKeys.toString();
      const ids = [];
      dataVTLQ.map((item) => {
        ids.push(item.id);
        return true;
      });
      //Gán lại giá trị
    }
    const yeuCaus = [];
    dataVTLQ.map((item) => {
      return yeuCaus.push(item.id);
    });
    //request data to server
    const payload = {
      loaiPhieuVatTu: typeLoaiPhieu,
      loaiTreoThao: typeTreoThao,
      doiId: doiId,
      ngayTao: values.ngayTao
        ? moment(values.ngayTao).format(FORMAT_ENGLISH_TIME_FULL)
        : ngayTao
        ? moment(ngayTao).format(FORMAT_ENGLISH_TIME_FULL)
        : toDay,
      yeuCaus: yeuCaus,
      vatTuXuats: newDataVTXuat,
      vatTuThuHois: [],
      vatTuTraLais: [],
      id: id,
    };
    //Call api Create/ Update
    handleCreateOrUpdate(
      payload,
      setLoading,
      type,
      setFetchList,
      form,
      dispatch,
      navigation,
      filterConditions
    );
  };

  //từ chối
  const handleTuChoi = () => {};
  return (
    <Fragment>
      <LayoutPhieuXuat
        loading={loading}
        form={form}
        onFinishSubmit={onFinishSubmit}
        type={type}
        dataVT={dataVT}
        columns={columns}
        onSearch={onSearch}
        dataDSYCLQ={dataDSYCLQ}
        columnsLQ={columnsLQ}
        dataVTLQ={dataVTLQ}
        handleTuChoi={handleTuChoi}
        handleCreateLQ={handleCreateLQ}
        valueSearch={valueSearch}
        setFetchList={setFetchList}
        selectRowKeys={selectRowKeys}
        nguoiTao={nguoiTao}
        tenLoaiTT={tenLoaiTT}
        ngayTao={ngayTao}
        tenDoi={tenDoi}
        lyDo={lyDo}
        dispatch={dispatch}
        disabled={disabled}
        isTraLai={isTraLai}
        nguoiSuaPhieu={nguoiSuaPhieu}
      />
    </Fragment>
  );
}
