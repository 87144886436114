import ModalLog from "./modal";

export default function LogModal(props) {
  const { visible, close, keyTab, dataSource } = props;
  const columns = [
    {
      title: "STT File Excel",
      dataIndex: "stt",
      key: "stt",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "15%",
    },
    {
      title: "Lỗi",
      dataIndex: "message",
      key: "message",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "85%",
    },
  ];
  return (
    <ModalLog
      keyTab={keyTab}
      visible={visible}
      dataSource={dataSource}
      columns={columns}
      close={close}
    />
  );
}
