import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
import { alertMessage422 } from "../service";

export const selectBCHD = [
  {
    label: "1. Thống kê khách hàng có sản lượng từ 1,3 lần",
    value: Endpoint.BCKD_TK_KH_CO_SL_TU_1_3,
  },
  {
    label: "2. Chi tiết khách hàng có sản lượng từ 1,3 lần",
    value: Endpoint.BCKD_CT_KH_CO_SL_TU_1_3,
  },
  {
    label: "3. Báo cáo tình hình sử dụng hóa đơn theo tháng",
    value: Endpoint.BCHD_TINH_HINH_SDHD_THEO_THANG,
  },
  {
    label: "4. Báo cáo tình hình sử dụng hóa đơn theo quý",
    value: Endpoint.BCHD_TINH_HINH_SDHD_THEO_QUY,
  },
  {
    label: "5. Báo cáo tình hình sử dụng hóa đơn theo năm",
    value: Endpoint.BCHD_TINH_HINH_SDHD_THEO_NAM,
  },
  {
    label: "6. Báo cáo tra cứu hóa đơn điều chỉnh",
    value: Endpoint.BCHD_TRA_CUU_HD_THOAI_HOAN,
  },
];

export const exportData = (data, setLoading, setData) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LoaiKhachHang: data.LoaiKhachHang,
      MDKepChi: data.mucdich,
      MaNhanVien: data.MaNhanVien,
    })
  );
  authGetData({
    url: `${data.reportType}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        console.log(res.data);
        setData(res.data);
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LoaiYeuCau: data.LoaiYeuCau,
      ChenhLech: data.ChenhLech
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
    })
  );
  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
