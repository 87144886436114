import { Modal } from "antd";
import React, { useState } from "react";
import ConfirmModal from "../modal-confirm";
import { handleXacNhan } from "./services";

export default function Index(props) {
  const {
    visible,
    closeModal,
    selectedRowKeys,
    setFetchList,
    setSelectedRowKeys,
    data,
    nvnp,
    nvtt,
  } = props;
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});

  const onFinish = () => {
    const payload = {
      ids: selectedRowKeys,
    };
    let isSubmit = true;
    if (data && Object.keys(selected).length > 0) {
      payload.phieuLenhCongTac = selected;
      isSubmit = true;
    } else {
      isSubmit = false;
    }

    if (isSubmit) {
      handleXacNhan(
        setLoading,
        payload,
        setFetchList,
        closeModal,
        setSelectedRowKeys
      );
    } else {
      Modal.confirm({
        centered: true,
        title: "Xác nhận",
        content:
          "Chưa chọn phiếu lệnh công tác, bạn có chắc chắn muốn tiếp tục?",
        onOk() {
          handleXacNhan(
            setLoading,
            payload,
            setFetchList,
            closeModal,
            setSelectedRowKeys
          );
        },
        onCancel() {},
        okText: "Đồng ý",
        okButtonProps: { type: "primary" },
        cancelText: "Hủy",
      });
    }
  };

  const onChangedSelect = (value) => {
    let arr = [];
    if (value !== undefined) {
      arr = data.filter((el) => el.maPhieuLenh === value);
    } else arr = [{}];
    setSelected(arr[0]);
  };
  return (
    <ConfirmModal
      loading={loading}
      visible={visible}
      closeModal={closeModal}
      onFinish={onFinish}
      title="Xác nhận an toàn"
      content="Bạn đồng ý xác nhận an toàn thì yêu cầu sẽ được chuyển sang bước tiếp
      theo."
      textButton="Xác nhận"
      dataSelect={data}
      onChangedSelect={onChangedSelect}
      selected={selected}
      nvtt={nvtt}
      nvnp={nvnp}
    />
  );
}
