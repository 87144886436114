const l1 = {
  l1: "l1",
  l1x: "l1x",
};
const l2 = {
  l2: "l2",
  l2x: "l2x",
};
const l3 = {
  l3: "l3",
  l3x: "l3x",
};

const trungAp = {
  l: "l",
};

export const trung_ap = {
  ...trungAp,
  ...l1,
  ...l2,
  ...l3,
};
