import { Form } from "antd";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import UIThongTinHoSo from "./ui-modal/thong-tin-ho-so";
function ThongTinHoSoModal({
  visible,
  closeModal,
  updateThongTinHoSo,
  thongTinHoSo,
  setLoadingDTLC,
  listDuToan,
  listLoaiCap,
  flagDTLC,
  loadingDTLC,
  setFlagDTLC,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  //submit dữ liệu

  const handleSubmit = (values) => {
    updateThongTinHoSo(values, form);
  };

  return (
    <UIThongTinHoSo
      visible={visible}
      closeModal={closeModal}
      handleSubmit={handleSubmit}
      t={t}
      thongTinHoSo={thongTinHoSo}
      listDuToan={listDuToan}
      listLoaiCap={listLoaiCap}
      flagDTLC={flagDTLC}
      setLoadingDTLC={setLoadingDTLC}
      loadingDTLC={loadingDTLC}
      setFlagDTLC={setFlagDTLC}
      form={form}
    />
  );
}

export default memo(ThongTinHoSoModal);
