import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_ENGLISH,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Divider, Form, Input, Row, Select, Spin } from "antd";
import React, { Fragment, useCallback, useState } from "react";
import styled from "styled-components";
import * as ColumnName from "./columnNames";
import moment from "moment";
import { exportData, handleDownload, selectTNCDS } from "./service";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";

export default function TinNhanChuyenDoiSo() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);
  const [data, setData] = useState([]);
  const [screen, setScreen] = useState([]);
  const [table, setTable] = useState(ColumnName.colummsTHSL);

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    TuNgay: moment().startOf("month").format("YYYY-MM-DD"),
    DenNgay: moment().format("YYYY-MM-DD"),
  });

  const onFinish = (values) => {
    exportData(values, setLoading, setData);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback((value) =>
    value === Endpoint.TNCDS_THSL_KH_DANG_KY_NHAN_THONG_BAO_EMAIL_ZALO
      ? setTable(ColumnName.colummsTHSL)
      : value === Endpoint.TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_EMAIL
      ? setTable(ColumnName.colummsKHnhanEmail)
      : value === Endpoint.TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_APP
      ? setTable(ColumnName.colummsKHnhanApp)
      : value === Endpoint.TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_ZALO
      ? setTable(ColumnName.colummsKHnhanZalo)
      : value === Endpoint.TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_EPOINT
      ? setTable(ColumnName.colummsKHnhanEpoint)
      : value === Endpoint.TNCDS_CT_TB_TIEN_DIEN_1_LAN_GUI_EMAIL_ZALO
      ? setTable(ColumnName.colummsCTTBTienDienLan1)
      : setTable("")
  );

  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              initialValues={{
                reportType:
                  Endpoint.TNCDS_THSL_KH_DANG_KY_NHAN_THONG_BAO_EMAIL_ZALO,
              }}
            >
              <Form.Item
                name="reportType"
                label="Tên báo cáo"
                labelAlign="left"
              >
                <Select
                  options={selectTNCDS}
                  formKey="reportType"
                  form={form}
                  onChange={(e) => {
                    handleChange(form.getFieldValue("reportType"));
                    setScreen(e);
                  }}
                  defaultValue={
                    Endpoint.TNCDS_THSL_KH_DANG_KY_NHAN_THONG_BAO_EMAIL_ZALO
                  }
                />
              </Form.Item>
              <Form.Item name="unitId" label="Đơn vị" labelAlign="left">
                <Selection
                  url={Endpoint.GET_TEAM_LIST_CODE}
                  formKey="unitId"
                  form={form}
                />
              </Form.Item>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    name="TuNgay"
                    label="Ngày bắt đầu"
                    labelAlign="left"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <DatePickerComponent
                      formKey="TuNgay"
                      form={form}
                      defaultValue={
                        filterConditions.TuNgay
                          ? moment(filterConditions.TuNgay, "YYYY-MM-DD")
                          : moment()
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="DenNgay"
                    label="Ngày kết thức"
                    labelAlign="right"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <DatePickerComponent
                      formKey="DenNgay"
                      form={form}
                      defaultValue={
                        filterConditions.DenNgay
                          ? moment(filterConditions.DenNgay, "YYYY-MM-DD")
                          : moment()
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              {screen === Endpoint.TNCDS_CT_TB_THSL_KH_DANG_KY_ID_ZALO ? (
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item
                      name="SoLuongTrung"
                      label="Số lượng trùng từ"
                      labelAlign="left"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <Input formKey="SoLuongTrung" form={form} />
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}

              <Row justify="end">
                <Button
                  type="primary"
                  onClick={() => {
                    handleDownload(form.getFieldValue(), setLoading);
                  }}
                >
                  Xuất Excel
                </Button>
              </Row>
            </FormComponent>
            <Divider className="section-text-line" orientation="left"></Divider>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
