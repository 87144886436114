import BaoCaoChiTietTabs from "../../../bao-cao-chi-tiet";
export default function Index(props) {
  const {
    redirect,
    setRedirect,
    setIsSubmit,
    isSubmit,
    newFilter,
    keyTabs,
    // callFirst,
    // setCallFirst,
  } = props;
  return (
    <BaoCaoChiTietTabs
      redirect={redirect}
      setRedirect={setRedirect}
      setIsSubmit={setIsSubmit}
      isSubmit={isSubmit}
      newFilter={newFilter}
      keyTabs={keyTabs}
      // callFirst={callFirst}
      // setCallFirst={setCallFirst}
    />
  );
}
