import React, { Fragment, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import FormComponent from "@components/Form";
import { ENUM_VATTU, TYPE_INPUT } from "@utils/constants";
import LoadingComponent from "@components/Loading";
import InputFormatter from "@components/InputFormatter";

const { Option } = Select;
const { TextArea } = Input;

const UICoSan = ({
  t,
  listVatTuTheoNgay,
  listHTTC,
  // detailVatTu,
  handleVatTuChange,
  listChiPhi,
}) => {
  return (
    <Fragment>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            label={t("vat_tu.vat_tu")}
            name="vatTuId"
            className="required-field"
          >
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                  option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
              // disabled={detailVatTu?.id}
              onChange={handleVatTuChange}
            >
              {listVatTuTheoNgay &&
                listVatTuTheoNgay.map((item, index) => (
                  <Option key={index} value={item.vatTuId} id={item.maVatTu}>
                    {item.tenVaMaVatTu}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Hình thức thi công"
            name="hinhThucThiCongId"
            className="required-field"
          >
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listHTTC &&
                listHTTC.map((item, index) => (
                  <Option key={index} value={item.id ?? item.hinhThucThiCongId}>
                    {item.tenHinhThucTC ?? item.tenHinhThucThiCong}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t("vat_tu.vat_tu_khach_hang.so_luong")}
            name="soLuong"
            className="required-field"
          >
            <InputNumber min={0} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Định mức"
            name="loaiChiPhiGianTiepId"
            // className="required-field"
          >
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listChiPhi &&
                listChiPhi.map((item, index) => (
                  <Option key={index} value={item.loaiChiPhiId}>
                    {item.tenLoaiChiPhi}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} style={{ display: "flex", alignItems: "center" }}>
          <Form.Item
            label=""
            name="isVatTuPhuTinhThue"
            className="required-field"
            style={{ marginBottom: 10 }}
            valuePropName="checked"
          >
            <Checkbox>Vật tư phụ tính thuế</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  );
};

const UIMuaNgoai = ({ listChiPhi, t }) => {
  return (
    <Fragment>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            label="Tên vật tư"
            name="tenVatTu"
            className="required-field"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Đơn vị tính"
            name="donViTinh"
            className="required-field"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t("vat_tu.vat_tu_khach_hang.so_luong")}
            name="soLuong"
            className="required-field"
          >
            <InputNumber min={0} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Đơn giá" name="donGia" className="required-field">
            <InputFormatter min={0} type={TYPE_INPUT.VND} />
          </Form.Item>
        </Col>
        <Col span={12} style={{ display: "flex", alignItems: "center" }}>
          <Form.Item
            label=""
            name="isDayCapDanDien"
            className="required-field"
            style={{ marginBottom: 20 }}
            valuePropName="checked"
          >
            <Checkbox>Dây cáp dẫn điện</Checkbox>
          </Form.Item>
        </Col>
        <Col span={12} style={{ display: "flex", alignItems: "center" }}>
          <Form.Item
            label=""
            name="isVatTuPhuTinhThue"
            className="required-field"
            style={{ marginBottom: 20 }}
            valuePropName="checked"
          >
            <Checkbox>Vật tư phụ tính thuế</Checkbox>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Định mức"
            name="loaiChiPhiGianTiepId"
            // className="required-field"
          >
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listChiPhi &&
                listChiPhi.map((item, index) => (
                  <Option key={index} value={item.loaiChiPhiId}>
                    {item.tenLoaiChiPhi}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Ghi chú" name="ghiChu" className="required-field">
            <TextArea row={3} />
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  );
};

export default function UIVatTuDuToan(props) {
  const {
    visible,
    closeModal,
    form,
    t,
    handleSubmitData,
    detailVatTu,
    handleChangeTenVatTu,
    dataChungLoaiVatTu,
    loading,
    typeVatTu,
    listVatTuTheoNgay,
    listHTTC,
    listChiPhi,
    handleVatTuChange,
  } = props;

  useEffect(() => {
    if (
      !(detailVatTu && Object.keys(detailVatTu).length > 0 && detailVatTu.id) &&
      typeVatTu === ENUM_VATTU.COSAN
    ) {
      if (Array.isArray(listHTTC) && listHTTC.length > 0) {
        form.setFieldsValue({
          hinhThucThiCongId: `${
            listHTTC[0].id ?? listHTTC[0].hinhThucThiCongId
          }`,
        });
      }
    }
  }, [detailVatTu, form, listHTTC, typeVatTu]);

  return (
    <Modal
      title={
        typeVatTu === ENUM_VATTU.COSAN
          ? "Vật tư có sẵn - Phần chi phí của Khách hàng"
          : "Vật tư mua ngoài - Phần chi phí của Khách hàng"
      }
      visible={visible}
      onCancel={closeModal}
      footer={null}
      width={800}
    >
      <FormComponent
        form={form}
        name="form-vat-tu-co-san"
        layout="vertical"
        onFinish={handleSubmitData}
        initialValues={
          {
            // ...detailVatTu,
          }
        }
        autoComplete="off"
      >
        <LoadingComponent loading={loading}>
          {typeVatTu === ENUM_VATTU.COSAN ? (
            <UICoSan
              t={t}
              listVatTuTheoNgay={listVatTuTheoNgay}
              listHTTC={listHTTC}
              detailVatTu={detailVatTu}
              handleVatTuChange={handleVatTuChange}
              listChiPhi={listChiPhi}
            />
          ) : (
            <UIMuaNgoai
              handleChangeTenVatTu={handleChangeTenVatTu}
              dataChungLoaiVatTu={dataChungLoaiVatTu}
              t={t}
              listChiPhi={listChiPhi}
            />
          )}

          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button
                key="cancel"
                type="default"
                onClick={closeModal}
                className="button-closed"
              >
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                name="form-vat-tu-co-san"
                loading={loading}
              >
                {t("button.cap_nhat")}
              </Button>
            </Col>
          </Row>
        </LoadingComponent>
      </FormComponent>
    </Modal>
  );
}
