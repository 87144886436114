import Filter from "@components/Filter/Filter";
import { customColumn, headerWithSummary } from "@utils/function";
import { Fragment } from "react";
import { Container } from "./css-styled";
import ListFilter from "./list-bo-loc";
import TableComponent from "@components/TableComponent";

export default function BaoCaoTiepNhanDVTable(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    totalCount,
    loading,
    columns,
    data,
    countTotals,
    noteColumns,
    onChangePagination,
    header,
    setType,
  } = props;
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          setType={setType}
        />
      </Filter>
      <Container>
        <TableComponent
          header={header}
          totalData={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          scrollY={450}
          columns={customColumn(columns, filterConditions)}
          pagination={false}
          dataSource={data}
          summary={(pageData) => countTotals(pageData)}
          components={{
            header: {
              wrapper: (props) => headerWithSummary(props, noteColumns),
            },
          }}
          isNoResize
        />
      </Container>
    </Fragment>
  );
}
