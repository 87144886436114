import { Fragment, Suspense, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import TroNgaiKhaoSatModal from "../../nhan-vien-khao-sat/lap-phuong-an/modals/modal-tro-ngai-khao-sat";
import ListMenu from "./list-menu";
import ModalXacNhanNghiemThu from "./modal";
import { handleXemLaiPACD } from "./services";
import ModalQuyetToanCongTy from "../../common-ho-so/quyet-toan-cong-ty";
import ModalInHoSoCMIS from "../../common-ho-so/in-ho-so-CMIS";
import ModalXemLaiPhuongAnCapDien from "../../common-ho-so/xem-lai-phuong-an-cap-dien";
import ModalQuyetToanKhachHang from "../../common-ho-so/quyet-toan-khach-hang";
import {
  downLoadBienBan,
  getPDFBienBanKhaiGia,
} from "../../nhan-vien-khao-sat/services";
import PDFModal from "@modules/cap-dien/common-ho-so/pdf-viewer";
import { inBienBan, inBieuDoPhuTai, downloadExcelBieuDoPT } from "../../common-ho-so/services";
import { TYPE_BIEN_BAN } from "@utils/constants";
import generateChart from "@modules/cap-dien/common-ho-so/pdf-viewer/charts/generate-chart";
import ReactPDF from "@react-pdf/renderer";
import BieuDoPhuTai from "@modules/cap-dien/common-ho-so/pdf-viewer/bieu-do-phu-tai";
import { alertMessage } from "@utils/function";

export default function ListChucNang(props) {
  const {
    detail,
    setFetchDataTable,
    funcID,
    dataTroNgai,
    loading,
    setIsDoubleClick,
  } = props;

  const [visibleXacNhanNghiemThu, setVisibleXacNhanNghiemThu] = useState(false);
  const [visibleTroNgaiKS, setVisibleTroNgaiKS] = useState(false);
  const [visibleCMIS, setVisibleCMIS] = useState(false);
  const [visibleXemLaiPACD, setVisibleXemLaiPACD] = useState(false);
  const [visibleQuyetToanKhachHang, setVisibleQuyetToanKhachHang] =
    useState(false);
  const [visibleQuyetToanCongTy, setVisibleQuyetToanCongTy] = useState(false);
  const [visibleInBieuKhaiGia, setVisibleInBieuKhaiGia] = useState(false);
  const [dataPAXemLai, setDataPAXemLai] = useState({});
  const { t } = useTranslation();
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const handleOpenModalXacNhanNghiemThu = () => {
    setVisibleXacNhanNghiemThu(!visibleXacNhanNghiemThu);
    setIsDoubleClick(false);
  };

  const handleOpenModalTroNgaiKS = () => {
    setVisibleTroNgaiKS(!visibleTroNgaiKS);
    setIsDoubleClick(false);
  };

  const handleCloseModalXacNhanNghiemThu = () => {
    setVisibleXacNhanNghiemThu(false);
    setIsDoubleClick(true);
  };

  const handleCloseModalTroNgaiKS = () => {
    setVisibleTroNgaiKS(false);
    setIsDoubleClick(true);
  };

  const handleOpenModalInHoSoCMIS = () => {
    setVisibleCMIS(!visibleCMIS);
    setIsDoubleClick(false);
  };
  const handleOpenModalXemLaiPhuongAnCapDien = () => {
    handleXemLaiPACD(
      detail,
      setDataPAXemLai,
      setVisibleXemLaiPACD,
      visibleXemLaiPACD
    );
    setIsDoubleClick(false);
  };
  const handleOpenModalQuyetToanKhachHang = () => {
    setVisibleQuyetToanKhachHang(!visibleQuyetToanKhachHang);
    setIsDoubleClick(false);
  };
  const handleOpenModalQuyetToanCongTy = () => {
    setVisibleQuyetToanCongTy(!visibleQuyetToanCongTy);
    setIsDoubleClick(false);
  };
  const handleOpenModalInBieuKhaiGia = () => {
    setVisibleInBieuKhaiGia(!visibleInBieuKhaiGia);
    setIsDoubleClick(false);
  };

  // đóng modal

  const handleCloseModalInHoSoCMIS = () => {
    setVisibleCMIS(false);
    setIsDoubleClick(true);
  };
  const handleCloseModalXemLaiPhuongAnCapDien = () => {
    setVisibleXemLaiPACD(false);
    setIsDoubleClick(true);
  };
  //modal xác nhận nghiệm thu

  const showModalXacNhanNghiemThu = useMemo(
    () =>
      visibleXacNhanNghiemThu && (
        <ModalXacNhanNghiemThu
          visibleXacNhan={visibleXacNhanNghiemThu}
          handleCloseModalXacNhan={handleCloseModalXacNhanNghiemThu}
          setFetchDataTable={setFetchDataTable}
          detail={detail}
        />
      ),
    // eslint-disable-next-line
    [visibleXacNhanNghiemThu]
  );

  const showModalTroNgaiKS = useMemo(
    () =>
      visibleTroNgaiKS && (
        <TroNgaiKhaoSatModal
          visible={visibleTroNgaiKS}
          closeModal={handleCloseModalTroNgaiKS}
          setFetchDataTable={setFetchDataTable}
          funcID={funcID}
          dataTroNgai={dataTroNgai}
          loading={loading}
          detail={detail}
          title={"Phòng tài chính cập nhật trở ngại"}
          text={"Ngày hẹn"}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataTroNgai, detail, funcID, loading, setFetchDataTable, visibleTroNgaiKS]
  );
  //modal quyết toán khách hàng

  const showModalQuyettoanKhachHang = useMemo(
    () =>
      visibleQuyetToanKhachHang && (
        <ModalQuyetToanKhachHang
          visible={visibleQuyetToanKhachHang}
          detail={detail}
          setVisibleQuyetToanKhachHang={setVisibleQuyetToanKhachHang}
          visibleQuyetToanKhachHang={visibleQuyetToanKhachHang}
        />
      ),
    // eslint-disable-next-line
    [visibleQuyetToanKhachHang]
  );
  //modal quyết toán công ty

  const showModalQuyetToanCongTy = useMemo(
    () =>
      visibleQuyetToanCongTy && (
        <ModalQuyetToanCongTy
          visible={visibleQuyetToanCongTy}
          detail={detail}
          setVisibleQuyetToanCongTy={setVisibleQuyetToanCongTy}
          visibleQuyetToanCongTy={visibleQuyetToanCongTy}
        />
      ),
    // eslint-disable-next-line
    [visibleQuyetToanCongTy]
  );
  //modal in hồ sơ cmis

  const showModalInHoSoCMIS = useMemo(
    () =>
      visibleCMIS && (
        <ModalInHoSoCMIS
          visible={visibleCMIS}
          detail={detail}
          handleCloseModal={handleCloseModalInHoSoCMIS}
        />
      ),
    // eslint-disable-next-line
    [visibleCMIS]
  );

  //modal xem lại phương án cấp điện

  const showModalXemLaiPhuongAnCapDien = useMemo(
    () =>
      visibleXemLaiPACD && (
        <ModalXemLaiPhuongAnCapDien
          visible={visibleXemLaiPACD}
          handleCloseModal={handleCloseModalXemLaiPhuongAnCapDien}
          detail={detail}
          dataPAXemLai={dataPAXemLai}
        />
      ),
    // eslint-disable-next-line
    [visibleXemLaiPACD]
  );

  const [loadingBBApGia, setLoadingBBApGia] = useState(false);
  const [visibleInBBApGia, setVisibleInBBApGia] = useState(false);
  const inBienBanApGia = () => {
    setVisibleInBBApGia(!visibleInBBApGia);
    setIsDoubleClick(false);
  };
  const showModalInBienBanApGia = useMemo(
    () =>
      visibleInBBApGia && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visibleInBBApGia}
            setVisible={setVisibleInBBApGia}
            title={`${TYPE_BIEN_BAN.AP_GIA} ${detail?.maYCKNCmis} ${detail.tenKhachHang}`}
            getBienBan={(setUrl) => showModalBBApGia(setUrl)}
            loadingGetBienBan={loadingBBApGia}
            isDownloadExcel={false}
            isReload={false}
            type={TYPE_BIEN_BAN.AP_GIA}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visibleInBBApGia]
  );

  const showModalBBApGia = useCallback(
    (setUrl) => {
      inBienBan(
        detail,
        setLoadingBBApGia,
        (value) => {
          setUrl(value);
        },
        setLoadingPDF,
        t
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );

  //BIỂU ĐỒ PHỤ TẢI
  const [loadingPhuTai, setLoadingPhuTai] = useState(false);
  const [loadingDownloadPT, setLoadinDownloadPT] = useState(false)
  const [visiblePhuTai, setVisiblePhuTai] = useState(false);
  const handleOpenInPhuTai = () => {
    setVisiblePhuTai(!visiblePhuTai);
    setIsDoubleClick(false);
  };

  //show popup
  const showModalInBieuDoPhuTai = useMemo(
    () =>
      visiblePhuTai && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visiblePhuTai}
            setVisible={setVisiblePhuTai}
            title={`In biểu đồ phụ tải`}
            getBienBan={(setUrl) => callAPIBieuDoPhuTai(setUrl)}
            loadingGetBienBan={loadingPhuTai}
            isDownloadExcel={true}
            isReload={false}
            downloadFileExcel={() =>
              downloadExcelBieuDoPT(detail, setLoadinDownloadPT)
            }
            loadingDownload={loadingDownloadPT}
            // type={TYPE_BIEN_BAN.AP_GIA}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visiblePhuTai]
  );

  //call api
  const callAPIBieuDoPhuTai = useCallback(
    (setUrl) => {
      inBieuDoPhuTai(
        detail,
        setLoadingPhuTai,
        async (values) => {
          if(values === 'error-1'){
            alertMessage("error", t("modal.thong_bao"), "Tổng công suất lắp đặt phải > 40");
            setVisiblePhuTai(false)
            return;
          }
          if (values && Object.values(values).length > 0) {
            const labels = values?.duLieuBieuDoCongSuat?.xValues || [];
            const datasets = [
              {
                label: "Công suất",
                fill: false,
                data: values?.duLieuBieuDoCongSuat?.yValues?.data || [], // Add data to the chart
              },
            ];
            const title = values?.duLieuBieuDoCongSuat?.title || "";
            const tilteY = values?.duLieuBieuDoCongSuat?.yInfo?.tilteY || "";

            const chartImage = await generateChart(
              datasets,
              labels,
              title,
              tilteY
            );

            const blob = await ReactPDF.pdf(
              <BieuDoPhuTai
                chartImage={chartImage}
                tenDonVi={values.tenDonVi}
                tenKhachHang={values.tenKhachHang}
                diaChiSuDungDien={values.diaChiSuDungDien}
                ngayHienTai={values.ngayHienTai}
                congSuatSuDungTrungBinhNgay={values.congSuatSuDungTrungBinhNgay}
                congSuatSuDungLonNhatTrongNgay={
                  values.congSuatSuDungLonNhatTrongNgay
                }
                congSuatSuDungNhoNhatTrongNgay={
                  values.congSuatSuDungNhoNhatTrongNgay
                }
                luongDienSuDungTrungBinhThang={
                  values.luongDienSuDungTrungBinhThang
                }
                congSuatLonNhatGioCaoDiemNgay={
                  values.congSuatLonNhatGioCaoDiemNgay
                }
                congSuatLonNhatGioCaoDiemToi={
                  values.congSuatLonNhatGioCaoDiemToi
                }
              />
            ).toBlob();
            const url = URL.createObjectURL(blob);
            setUrl(url);
          } else {
            setUrl("error");
          }
        },
        setLoadingPDF,
        t
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );
  //END BIỂU ĐỒ PHỤ TẢI

  const showModalPdfKhaiGia = useMemo(
    () =>
      visibleInBieuKhaiGia && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visibleInBieuKhaiGia}
            setVisible={setVisibleInBieuKhaiGia}
            title={`${TYPE_BIEN_BAN.KHAI_GIA} ${detail?.maYCKNCmis} ${detail.tenKhachHang}`}
            getBienBan={(setUrl) => showModalPDF(setUrl)}
            downloadFileExcel={() =>
              downLoadBienBan(detail, setLoadingDownload)
            }
            setLoadingDownload={setLoadingDownload}
            loadingDownload={loadingDownload}
            loadingGetBienBan={loadingPDF}
            isDownloadExcel={true}
            isReload={true}
            type={TYPE_BIEN_BAN.KHAI_GIA}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visibleInBieuKhaiGia]
  );

  const showModalPDF = useCallback(
    (setUrl) => {
      getPDFBienBanKhaiGia(
        detail,
        (value) => {
          setUrl(value);
        },
        setLoadingPDF,
        t
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );

  return (
    <Fragment>
      <ListMenu
        handleOpenModalXacNhanNghiemThu={handleOpenModalXacNhanNghiemThu}
        handleOpenModalTroNgaiKS={handleOpenModalTroNgaiKS}
        detail={detail}
        t={t}
        handleOpenModalInHoSoCMIS={handleOpenModalInHoSoCMIS}
        handleOpenModalXemLaiPhuongAnCapDien={
          handleOpenModalXemLaiPhuongAnCapDien
        }
        handleOpenModalQuyetToanKhachHang={handleOpenModalQuyetToanKhachHang}
        handleOpenModalQuyetToanCongTy={handleOpenModalQuyetToanCongTy}
        handleOpenModalInBieuKhaiGia={handleOpenModalInBieuKhaiGia}
        inBienBanApGia={inBienBanApGia}
        handleOpenInPhuTai={handleOpenInPhuTai}
      />
      {showModalTroNgaiKS}
      {showModalXacNhanNghiemThu}
      {showModalInHoSoCMIS}
      {showModalXemLaiPhuongAnCapDien}
      {showModalQuyettoanKhachHang}
      {showModalQuyetToanCongTy}
      {showModalPdfKhaiGia}
      {showModalInBienBanApGia}
      {showModalInBieuDoPhuTai}
    </Fragment>
  );
}
