import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useEffect,
  useMemo
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Filter from "@components/Filter/Filter";
import { TableStyled, Container } from "./css-styled";
import { Button, Tag, Form, Tooltip, Modal, notification } from "antd";
import { authSelector } from "@state/auth/reducer";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE, AP_GIA_TRANG_THAI, ENUM_MUCDICH_SDD } from "@utils/constants";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import {
  handleListData,
  // handleListNVAG,
  handleSubmitPhanCong,
  handleHuyPhanCong,
  handleApGiaUyQuyenMacDinh,
  handleListApGiaUyQuyen,
  submitHuyYeuCau,
  submitTraYC
} from "./services";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilter } from "../../common/list-bo-loc";
import CheckPermission from "@components/CheckPermission";
import { ap_gia } from "@permissions/e_apgia";
import PhanCongModal from "../../common/modal/modal-phan-cong";
import TableComponent from "@components/TableComponent";
import ButtonComponent from "@components/Button";
import { openModal } from "@state/system-config/reducer";
import ChiTietDiemDo from "../../common/chi-tiet-diem-do";
import TrashIcon from "@assets/icon/TrashIcon";
import { InfoCircleOutlined } from "@ant-design/icons";
// import moment from "moment";
import ImportModal from "./modal-import";

export default function Index() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { user } = useSelector(authSelector);
  const [dataPhanCong, setDataPhanCong] = useState([]);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [visiblePC, setVisiblePC] = useState(false);
  // const [dataNV, setDataNV] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingTenNV, setLoadingTenNV] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataResponse, setDataResponse] = useState();
  const location = useLocation();
  const [biTraLai, setBiTraLai] = useState(false);
  const [dataApGiaUyQuyen, setDataApGiaUyQuyen] = useState([]);
  const [dataApGiaUyQuyenMacDinh, setDataApGiaUyQuyenMacDinh] = useState();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    ...parseParams(location.search),
    TrangThai: AP_GIA_TRANG_THAI.DA_LAY_TU_WEB,
    // ThangKiemTra: moment().format(FORMAT_MONTH_ENGLISH),
    // LoaiKhachHang: ENUM_MUCDICH_SDD.SINHHOAT,
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const onChangePagination = (paging, _filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setSelectedRowKeys([]);
  };
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
      BiTraLai: biTraLai ? biTraLai : null,
      Tram: values.Tram
        ? Array.isArray(values.Tram) && values.Tram.length > 0
          ? values.Tram.join()
          : values.Tram
        : [],
    }));
    setDataResponse();
  }, [biTraLai]);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      TrangThai: AP_GIA_TRANG_THAI.DA_LAY_TU_WEB,
      // ThangKiemTra: moment().format(FORMAT_MONTH_ENGLISH),
      // LoaiKhachHang: ENUM_MUCDICH_SDD.SINHHOAT,
    });
  }, [user.unitId]);
  //search list
  const getListData = useCallback(() => {
    handleListData(location,
      filterConditions,
      setLoading,
      setDataPhanCong,
      setTotalCount,
      setSelectedRowKeys
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getListData();
  }, [getListData]);

  useEffect(() => {
    handleApGiaUyQuyenMacDinh(setLoading, user.unitId, setDataApGiaUyQuyenMacDinh);
    handleListApGiaUyQuyen(setLoading, user.unitId, setDataApGiaUyQuyen);
  }, [user.unitId]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListData();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListData, setFetchDataTable]);

  const checkTraLai = (e) => {
    setBiTraLai(e.target.checked);
  };

  const detailDiemDo = (record) => {
    return dispatch(
      openModal({
        content: (
          <ChiTietDiemDo
            detailRecord={record}
          />
        ),
        size: "large",
        title: `Chi tiết điểm đo - ${record.maDiemDo}`
      })
    );
  };

  const detailDD = (record) => {
    return {
      onDoubleClick: () => {
        detailDiemDo(record)
      },
    };
  };

  const columns = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => detailDiemDo(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
      fixed: "left"
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      key: "maKhachHang",
      render: (text) => (
        <span>{text}</span>
      ),
      width: "10%",
      sorter: true,
      fixed: "left"
    },
    {
      title: "Tên KH",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => <span>{text}</span>,
      width: "12%",
      sorter: true,
    },
    {
      title: "Điện thoại",
      dataIndex: "soDienThoai",
      key: "soDienThoai",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: 'Trước kiểm tra',
      children: [
        {
          title: 'Ngày áp dụng',
          dataIndex: "ngayApDung",
          key: "ngayApDung",
          render: (text) => (
            <div className="table-text-center">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
        {
          title: 'Số hộ',
          dataIndex: "soHo",
          key: "soHo",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
        },
        {
          title: "Chuỗi giá",
          dataIndex: "chuoiGia",
          key: "chuoiGia",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        }
      ],
    },
    {
      title: 'Sau kiểm tra',
      children: [
        {
          title: 'Ngày áp dụng',
          dataIndex: "ngayApDungMoi",
          key: "ngayApDungMoi",
          render: (text) => (
            <div className="table-text-center">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
        {
          title: 'Số hộ',
          dataIndex: "soHoMoi",
          key: "soHoMoi",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
        },
        {
          title: "Chuỗi giá",
          dataIndex: "chuoiGiaMoi",
          key: "chuoiGiaMoi",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        }
      ],
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text, record) => (
        <>
        {
          record.trangThai?.toString() === AP_GIA_TRANG_THAI.DA_PHAN_CONG ?
          <Tag style={{ display: "block", textAlign: "center" }} color={"#26c0e9"}>{"Đã phân công"}</Tag> :
          <Tag style={{ display: "block", textAlign: "center" }} color={"#faad14"}>{"Chưa phân công"}</Tag>
        }
          {record.biTraLai ? (
            <div
              className="table-text-center"
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: "#FF5247",
                cursor: "pointer",
              }}
            >
              <Tooltip title={record.lyDoTraLai}>
                <InfoCircleOutlined /> Bị trả lại
              </Tooltip>
            </div>
          ) : null}
        </>
      ),
      width: "8%",
    },
    // {
    //   title: "Tháng kiểm tra",
    //   dataIndex: "thangKiemTra",
    //   key: "thangKiemTra",
    //   render: (text) => (
    //     <div className="table-text-center">
    //       <span>{text}</span>
    //     </div>
    //   ),
    //   width: "7%",
    // },
    {
      title: "Loại khách hàng",
      dataIndex: "loaiKhachHang",
      key: "loaiKhachHang",
      render: (text, record) => (
        record.loaiKhachHang?.toString() === ENUM_MUCDICH_SDD.NGOAISINHHOAT ?
          <span>{"Ngoài sinh hoạt"}</span> :
          <span>{"Sinh hoạt"}</span>
      ),
      width: "8%",
    },
    {
      title: "Trạm",
      dataIndex: "tram",
      key: "tram",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: "Sổ GCS",
      dataIndex: "soGCS",
      key: "soGCS",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => <span>{text}</span>,
      width: "16%",
    },
    {
      title: "Mã bảng kê",
      dataIndex: "maBangKe",
      key: "maBangKe",
      render: (text) => <span>{text}</span>,
      width: "9%",
    },
    {
      title: "Tác vụ",
      dataIndex: "",
      key: "tacVu",
      fixed: "right",
      render: (text, record) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={ap_gia.e21h}>
            {
              record.trangThai?.toString() === AP_GIA_TRANG_THAI.DA_PHAN_CONG &&
              <Tooltip title={"Hủy phân công"}>
                <ButtonComponent type="link" onClick={() => handleHuyPC(record.id)} className="pd-5px">
                  <TrashIcon />
                </ButtonComponent>
              </Tooltip>
            }

          </CheckPermission>

        </div>

      ),
      width: "4%",
    },
  ];

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: "3%",
    fixed: "left",
    // getCheckboxProps: (record) => ({
    //   disabled:
    //     record.trangThai?.toString() === AP_GIA_TRANG_THAI.DA_PHAN_CONG,
    // }),
  };
  // check trang thai phan cong
  const handleOpenModal = useCallback(() => {
    const activeServiceList = dataPhanCong.filter((item) => {
      return selectedRowKeys.includes(item.id);
    });
    const listIdPC = activeServiceList.filter((item) => item.trangThai?.toString() !== AP_GIA_TRANG_THAI.DA_PHAN_CONG);
    // handleListNVAG(user, setLoadingTenNV, setDataNV);
    if (listIdPC?.length === 0) {
      notification.error({
        message: `Thông báo`,
        description: `Vui lòng chọn các yêu cầu chưa được phân công!`,
      });
    } else {
      setVisiblePC(true)
    }
  }, [dataPhanCong, selectedRowKeys]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFinish = (values) => {
    const activeServiceList = dataPhanCong.filter((item) => {
      return selectedRowKeys.includes(item.id);
    });
    const listIdPC = activeServiceList.filter((item) => item.trangThai?.toString() !== AP_GIA_TRANG_THAI.DA_PHAN_CONG).map((a) => a.id);

    //call api
    handleSubmitPhanCong(
      values.userId,
      values.uyQuyenId,
      values.doiId,
      setLoadingTenNV,
      handleCloseModalPC,
      setFetchDataTable,
      setSelectedRowKeys,
      listIdPC.toString(),
      form
    );
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCloseModalPC = () => {
    setVisiblePC(false);
    setLoading(false);
  };
  const showModalPC = useMemo(
    () =>
      visiblePC && (
        <PhanCongModal
          visible={visiblePC}
          // dataNV={dataNV}
          onFinish={onFinish}
          closeModal={handleCloseModalPC}
          loadingTenNV={loadingTenNV}
          dataApGiaUyQuyen={dataApGiaUyQuyen}
          dataApGiaUyQuyenMacDinh={dataApGiaUyQuyenMacDinh}
        />
      ),
    [dataApGiaUyQuyen, dataApGiaUyQuyenMacDinh, loadingTenNV, onFinish, visiblePC]
  );

  // Hủy phân công
  const handleHuyPC = useCallback(
    (id) => {
      handleHuyPhanCong(id, setLoading, getListData, t);
    },
    [getListData, t]
  );

  const genExtra = useCallback(
    () => (
      <div>
        <CheckPermission permissionCode={ap_gia.e21p}>
            <ButtonComponent
              type="primary"
              style={{ marginRight: "10px" }}
              onClick={() => handleHuyYeuCau()}
              disabled={selectedRowKeys.length === 0}
            >
              Hủy yêu cầu
            </ButtonComponent>
          </CheckPermission>
          <CheckPermission permissionCode={ap_gia.e21p}>
            <ButtonComponent
              type="primary"
              style={{ marginRight: "10px" }}
              onClick={() => handleTraYC()}
              disabled={selectedRowKeys.length === 0}
            >
              Trả lại yêu cầu cho PKD
            </ButtonComponent>
          </CheckPermission>
        <CheckPermission permissionCode={ap_gia.e21p}>
          <ButtonComponent
            type="primary"
            style={{ marginRight: "10px" }}
            onClick={() => handleOpenModal()}
            disabled={selectedRowKeys.length === 0}
          >
            Phân công
          </ButtonComponent>
        </CheckPermission>
        <CheckPermission permissionCode={ap_gia.e21p}>
          <Button
            type="primary"
            onClick={handleOpenImport}
          >
            Import
          </Button>
        </CheckPermission>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRowKeys]
  );

  const [visibleImport, setVisibleImport] = useState(false);
  const handleOpenImport = useCallback(() => {
    // handleListNVAG(user, setLoadingTenNV, setDataNV);
    setVisibleImport(!visibleImport);
  }, [visibleImport]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCloseImport = () => {
    setVisibleImport(!visibleImport);
  };

  const showImport = useCallback(() => {
    return (
      visibleImport && (
        <ImportModal
          visible={visibleImport}
          handleCloseImport={handleCloseImport}
          // dataNV={dataNV}
          setFetchDataTable={setFetchDataTable}
          setDataResponse={setDataResponse}
          dataApGiaUyQuyen={dataApGiaUyQuyen}
          dataApGiaUyQuyenMacDinh={dataApGiaUyQuyenMacDinh}
        />
      )
    );
  }, [visibleImport, handleCloseImport, dataApGiaUyQuyen, dataApGiaUyQuyenMacDinh]);

  const handleHuyYeuCau = () => {
    setDataResponse();

    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: `Bạn đã chọn ${selectedRowKeys.length} yêu cầu để Hủy.\r Ấn Đồng ý, yêu cầu sẽ được xóa khỏi hệ thống, bạn có chắc chắn muốn hủy?`,
      onOk() {
        //call api
        submitHuyYeuCau(setLoading, setFetchDataTable, selectedRowKeys.toString(), setSelectedRowKeys);
      },
      onCancel() { },
      okText: "Đồng ý",
      cancelText: "Hủy",
    });

  };
  const handleTraYC = () => {
    setDataResponse();
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: `Bạn muốn trả lại các yêu cầu này cho Phòng kinh doanh?`,
      onOk() {
        //call api
        submitTraYC(setLoading, setFetchDataTable, selectedRowKeys.toString(), setSelectedRowKeys);
      },
      onCancel() { },
      okText: "Đồng ý",
      cancelText: "Hủy",
    });

  };
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          checkTraLai={checkTraLai}
          screen={"PC"}
        />
      </Filter>
      <Container>
        {
          dataResponse &&
          <>
            <div style={{ color: "#0dcb0d", fontWeight: "bold ", marginLeft: 10 }}>Kết quả phân công:</div>
            <div style={{ marginLeft: 20 }}>
              <div >
                <span style={{ width: 200, display: 'inline-block' }}>
                  Tổng số yêu cầu:
                </span>
                <span>{dataResponse?.tongSoYeuCau}</span>
              </div>
              <div>
                <span style={{ width: 200, display: 'inline-block' }}>
                  Tổng số yêu cầu CMIS:
                </span>
                <span>{dataResponse?.soYeuCauCmis}</span>
              </div>
              <div>
                <span style={{ width: 200, display: 'inline-block' }}>
                  Tổng số yêu cầu cập nhật:
                </span>
                <span>{dataResponse?.yeuCauUpdate}</span>
              </div>
              <div>
                <span style={{ width: 200, display: 'inline-block' }}>
                  Tổng số yêu cầu thành công:
                </span>
                <span>{dataResponse?.yeuCauSuccess}</span>
              </div>
              <div>
                <span style={{ width: 200, display: 'inline-block' }}>
                  Tổng số yêu cầu thất bại:
                </span>
                <span>{dataResponse?.yeuCauError}</span>
              </div>
            </div>
          </>
        }
        <TableStyled>
          <TableComponent
            header={"Phân công công việc"}
            handleSearch={handleSearch}
            dataSource={dataPhanCong}
            columns={customColumn(columns, filterConditions)}
            totalData={totalCount}
            renderExtraAction={() => genExtra()}
            loading={loading}
            scrollX={3000}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            rowSelection={rowSelection}
            rowKey="id"
            onRow={detailDD}
          />
        </TableStyled>
      </Container>
      {showModalPC}
      {showImport()}
    </Fragment>
  );
}
