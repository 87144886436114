import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ModalInHoSoCMIS from "../../common-ho-so/in-ho-so-CMIS";
import ModalXemLaiPhuongAnCapDien from "../../common-ho-so/xem-lai-phuong-an-cap-dien";
import ModalQuyetToanKhachHang from "../../common-ho-so/quyet-toan-khach-hang";
import ModalQuyetToanCongTy from "../../common-ho-so/quyet-toan-cong-ty";
import ModalTraDoiThiCong from "./modal/modal-tra-doi-thi-cong";
import ModalXacNhanNghiemThu from "./modal/modal-xac-nhan-nghiem-thu";

import { Fragment, Suspense, useCallback, useMemo, useState } from "react";
import ListMenu from "./list-menu";
import { handleXemLaiPhuongAn } from "./services";
import {
  downLoadBienBan,
  getPDFBienBanKhaiGia,
} from "../../nhan-vien-khao-sat/services";
import PDFModal from "@modules/cap-dien/common-ho-so/pdf-viewer";
import {
  inBienBan,
  inBieuDoPhuTai,
  downloadExcelBieuDoPT,
} from "../../common-ho-so/services";
import { TYPE_BIEN_BAN } from "@utils/constants";
import generateChart from "@modules/cap-dien/common-ho-so/pdf-viewer/charts/generate-chart";
import ReactPDF from "@react-pdf/renderer";
import BieuDoPhuTai from "@modules/cap-dien/common-ho-so/pdf-viewer/bieu-do-phu-tai";
import { handleGetFileBBLD } from "@modules/cap-dien/thi-cong/lap-bbld-duong-day/modals/popup-bien-ban/services";
import { alertMessage } from "@utils/function";
import { Modal } from "antd";

export default function ListChucNang(props) {
  const { detail, setFetchDataTable, setIsDoubleClick } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [visibleCMIS, setVisibleCMIS] = useState(false);
  const [visibleXemLaiPhuongAnCapDien, setVisibleXemLaiPhuongAnCapDien] =
    useState(false);
  const [visibleQuyetToanKhachHang, setVisibleQuyetToanKhachHang] =
    useState(false);
  const [visibleQuyetToanCongTy, setVisibleQuyetToanCongTy] = useState(false);
  const [visibleInBieuKhaiGia, setVisibleInBieuKhaiGia] = useState(false);
  const [visibleXacNhanNghiemThu, setVisibleXacNhanNghiemThu] = useState(false);
  const [visibleTraDoiThiCong, setVisibleTraDoiThiCong] = useState(false);
  const [dataPAXemLai, setDataPAXemLai] = useState({});
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  // open modal

  const handleOpenModalXacNhanNghiemThu = () => {
    if (detail?.ddkError) {
      Modal.confirm({
        centered: true,
        title: "Xác nhận",
        content: `Điểm đo của hồ sơ ${detail.maYCKNCmis} chưa nhập chỉ số định kỳ (DDK), bạn có muốn tiếp tục ?`,
        onOk() {
          setVisibleXacNhanNghiemThu(!visibleXacNhanNghiemThu);
          setIsDoubleClick(false);
        },
        onCancel() {},
        okText: "Đồng ý",
        okButtonProps: { type: "danger" },
        cancelText: "Hủy",
      });
    } else {
      setVisibleXacNhanNghiemThu(!visibleXacNhanNghiemThu);
      setIsDoubleClick(false);
    }
  };
  const handleOpenModalTraDoiThiCong = () => {
    setVisibleTraDoiThiCong(!visibleTraDoiThiCong);
    setIsDoubleClick(false);
  };
  const handleOpenModalInHoSoCMIS = () => {
    setVisibleCMIS(!visibleCMIS);
    setIsDoubleClick(false);
  };
  const handleOpenModalXemLaiPhuongAnCapDien = () => {
    handleXemLaiPhuongAn(
      detail,
      setDataPAXemLai,
      setVisibleXemLaiPhuongAnCapDien,
      visibleXemLaiPhuongAnCapDien
    );
    setIsDoubleClick(false);
  };
  const handleOpenModalQuyetToanKhachHang = () => {
    setVisibleQuyetToanKhachHang(!visibleQuyetToanKhachHang);
    setIsDoubleClick(false);
  };
  const handleOpenModalQuyetToanCongTy = () => {
    setVisibleQuyetToanCongTy(!visibleQuyetToanCongTy);
    setIsDoubleClick(false);
  };
  const handleOpenModalInBieuKhaiGia = () => {
    setVisibleInBieuKhaiGia(!visibleInBieuKhaiGia);
    setIsDoubleClick(false);
  };

  // đóng modal

  const handleCloseModalXacNhanNghiemThu = () => {
    setVisibleXacNhanNghiemThu(false);
    setIsDoubleClick(true);
  };
  const handleCloseModalTraDoiThiCong = () => {
    setVisibleTraDoiThiCong(false);
    setIsDoubleClick(true);
  };
  const handleCloseModalInHoSoCMIS = () => {
    setVisibleCMIS(false);
    setIsDoubleClick(true);
  };
  const handleCloseModalXemLaiPhuongAnCapDien = () => {
    setVisibleXemLaiPhuongAnCapDien(false);
    setIsDoubleClick(true);
  };
  //modal xác nhận nghiệm thu

  const showModalXacNhanNghiemThu = useMemo(
    () =>
      visibleXacNhanNghiemThu && (
        <ModalXacNhanNghiemThu
          visibleXacNhan={visibleXacNhanNghiemThu}
          handleCloseModalXacNhan={handleCloseModalXacNhanNghiemThu}
          setFetchDataTable={setFetchDataTable}
          detail={detail}
          setVisibleXacNhanNghiemThu={setVisibleXacNhanNghiemThu}
        />
      ),
    // eslint-disable-next-line
    [visibleXacNhanNghiemThu]
  );
  //modal trả đội thi công

  const showModalTraDoiThiCong = useMemo(
    () =>
      visibleTraDoiThiCong && (
        <ModalTraDoiThiCong
          visibleTraDoi={visibleTraDoiThiCong}
          handleCloseModalTraDoi={handleCloseModalTraDoiThiCong}
          setFetchDataTable={setFetchDataTable}
          detail={detail}
        />
      ),
    // eslint-disable-next-line
    [visibleTraDoiThiCong]
  );
  //modal in hồ sơ cmis

  const showModalInHoSoCMIS = useMemo(
    () =>
      visibleCMIS && (
        <ModalInHoSoCMIS
          visible={visibleCMIS}
          handleCloseModal={handleCloseModalInHoSoCMIS}
          setFetchDataTable={setFetchDataTable}
          detail={detail}
        />
      ),
    // eslint-disable-next-line
    [visibleCMIS]
  );
  //modal quyết toán khách hàng

  const showModalQuyettoanKhachHang = useMemo(
    () =>
      visibleQuyetToanKhachHang && (
        <ModalQuyetToanKhachHang
          visible={visibleQuyetToanKhachHang}
          detail={detail}
          setVisibleQuyetToanKhachHang={setVisibleQuyetToanKhachHang}
          visibleQuyetToanKhachHang={visibleQuyetToanKhachHang}
        />
      ),
    // eslint-disable-next-line
    [visibleQuyetToanKhachHang]
  );
  //modal quyết toán công ty

  const showModalQuyetToanCongTy = useMemo(
    () =>
      visibleQuyetToanCongTy && (
        <ModalQuyetToanCongTy
          visible={visibleQuyetToanCongTy}
          detail={detail}
          setVisibleQuyetToanCongTy={setVisibleQuyetToanCongTy}
          visibleQuyetToanCongTy={visibleQuyetToanCongTy}
        />
      ),
    // eslint-disable-next-line
    [visibleQuyetToanCongTy]
  );

  //in biên bản áp giá
  const [, setLoading] = useState(false);
  const [loadingBBApGia, setLoadingBBApGia] = useState(false);
  const [visibleInBBApGia, setVisibleInBBApGia] = useState(false);
  const inBienBanApGia = () => {
    setVisibleInBBApGia(!visibleInBBApGia);
    setIsDoubleClick(false);
  };
  const showModalInBienBanApGia = useMemo(
    () =>
      visibleInBBApGia && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visibleInBBApGia}
            setVisible={setVisibleInBBApGia}
            title={`${TYPE_BIEN_BAN.AP_GIA} ${detail?.maYCKNCmis} ${detail.tenKhachHang}`}
            getBienBan={(setUrl) => showModalBBApGia(setUrl)}
            loadingGetBienBan={loadingBBApGia}
            isDownloadExcel={false}
            isReload={false}
            type={TYPE_BIEN_BAN.AP_GIA}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visibleInBBApGia]
  );

  const showModalBBApGia = useCallback(
    (setUrl) => {
      inBienBan(
        detail,
        setLoadingBBApGia,
        (value) => {
          setUrl(value);
        },
        setLoadingPDF,
        t
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );

  //BIỂU ĐỒ PHỤ TẢI
  const [loadingPhuTai, setLoadingPhuTai] = useState(false);
  const [loadingDownloadPT, setLoadinDownloadPT] = useState(false);
  const [visiblePhuTai, setVisiblePhuTai] = useState(false);
  const handleOpenInPhuTai = () => {
    setVisiblePhuTai(!visiblePhuTai);
    setIsDoubleClick(false);
  };

  //show popup
  const showModalInBieuDoPhuTai = useMemo(
    () =>
      visiblePhuTai && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visiblePhuTai}
            setVisible={setVisiblePhuTai}
            title={`In biểu đồ phụ tải`}
            getBienBan={(setUrl) => callAPIBieuDoPhuTai(setUrl)}
            loadingGetBienBan={loadingPhuTai}
            isDownloadExcel={true}
            isReload={false}
            downloadFileExcel={() =>
              downloadExcelBieuDoPT(detail, setLoadinDownloadPT)
            }
            loadingDownload={loadingDownloadPT}
            // type={TYPE_BIEN_BAN.AP_GIA}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visiblePhuTai]
  );

  //call api
  const callAPIBieuDoPhuTai = useCallback(
    (setUrl) => {
      inBieuDoPhuTai(
        detail,
        setLoadingPhuTai,
        async (values) => {
          if(values === 'error-1'){
            alertMessage("error", t("modal.thong_bao"), "Tổng công suất lắp đặt phải > 40");
            setVisiblePhuTai(false)
            return;
          }
          if (values && Object.values(values).length > 0) {
            const labels = values?.duLieuBieuDoCongSuat?.xValues || [];
            const datasets = [
              {
                label: "Công suất",
                fill: false,
                data: values?.duLieuBieuDoCongSuat?.yValues?.data || [], // Add data to the chart
              },
            ];
            const title = values?.duLieuBieuDoCongSuat?.title || "";
            const tilteY = values?.duLieuBieuDoCongSuat?.yInfo?.tilteY || "";

            const chartImage = await generateChart(
              datasets,
              labels,
              title,
              tilteY
            );

            const blob = await ReactPDF.pdf(
              <BieuDoPhuTai
                chartImage={chartImage}
                tenDonVi={values.tenDonVi}
                tenKhachHang={values.tenKhachHang}
                diaChiSuDungDien={values.diaChiSuDungDien}
                ngayHienTai={values.ngayHienTai}
                congSuatSuDungTrungBinhNgay={values.congSuatSuDungTrungBinhNgay}
                congSuatSuDungLonNhatTrongNgay={
                  values.congSuatSuDungLonNhatTrongNgay
                }
                congSuatSuDungNhoNhatTrongNgay={
                  values.congSuatSuDungNhoNhatTrongNgay
                }
                luongDienSuDungTrungBinhThang={
                  values.luongDienSuDungTrungBinhThang
                }
                congSuatLonNhatGioCaoDiemNgay={
                  values.congSuatLonNhatGioCaoDiemNgay
                }
                congSuatLonNhatGioCaoDiemToi={
                  values.congSuatLonNhatGioCaoDiemToi
                }
              />
            ).toBlob();
            const url = URL.createObjectURL(blob);
            setUrl(url);
          } else {
            setUrl("error");
          }
        },
        setLoadingPDF,
        t
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );
  //END BIỂU ĐỒ PHỤ TẢI

  //biên bản lắp đặt
  const [loadingBBLD, setLoadingBBLD] = useState(false);
  const [visibleInBBLD, setVisibleInBBLD] = useState(false);

  const showModalInBienBanLapDat = useMemo(
    () =>
      visibleInBBLD && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visibleInBBLD}
            setVisible={setVisibleInBBLD}
            title="Xem trước in biên bản lắp đặt"
            getBienBan={(setUrl) => showModalBBLapDat(setUrl)}
            loadingGetBienBan={loadingBBLD}
            isDownloadExcel={false}
            isReload={false}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visibleInBBLD]
  );

  const base64toBlob = (data) => {
    try {
      const base64WithoutPrefix = data.substr(
        "data:application/pdf;base64,".length
      );
      const bytes = atob(base64WithoutPrefix);
      let length = bytes.length;
      const out = new Uint8Array(length);

      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      return new Blob([out], { type: "application/pdf" });
    } catch (error) {
      alertMessage(
        "error",
        t("modal.thong_bao"),
        "Định dạng file không hỗ trợ!"
      );
    }
  };

  const showModalBBLapDat = useCallback(
    (setUrl) => {
      handleGetFileBBLD(detail.id, setLoadingBBLD, (file) => {
        const blob = base64toBlob(file);
        const url = URL.createObjectURL(blob);
        setUrl(url);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );
  //end biên bản lắp đặt

  //modal xem lại phương án cấp điện

  const showModalXemLaiPhuongAnCapDien = useMemo(
    () =>
      visibleXemLaiPhuongAnCapDien && (
        <ModalXemLaiPhuongAnCapDien
          visible={visibleXemLaiPhuongAnCapDien}
          handleCloseModal={handleCloseModalXemLaiPhuongAnCapDien}
          setFetchDataTable={setFetchDataTable}
          detail={detail}
          dataPAXemLai={dataPAXemLai}
        />
      ),
    // eslint-disable-next-line
    [visibleXemLaiPhuongAnCapDien]
  );

  const showModalPdfKhaiGia = useMemo(
    () =>
      visibleInBieuKhaiGia && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visibleInBieuKhaiGia}
            setVisible={setVisibleInBieuKhaiGia}
            title={`${TYPE_BIEN_BAN.KHAI_GIA} ${detail?.maYCKNCmis} ${detail.tenKhachHang}`}
            getBienBan={(setUrl) => showModalPDF(setUrl)}
            downloadFileExcel={() =>
              downLoadBienBan(detail, setLoadingDownload)
            }
            setLoadingDownload={setLoadingDownload}
            loadingDownload={loadingDownload}
            loadingGetBienBan={loadingPDF}
            isDownloadExcel={true}
            isReload={true}
            type={TYPE_BIEN_BAN.KHAI_GIA}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visibleInBieuKhaiGia]
  );

  const showModalPDF = useCallback(
    (setUrl) => {
      getPDFBienBanKhaiGia(
        detail,
        (value) => {
          setUrl(value);
        },
        setLoadingPDF,
        t
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );

  return (
    <Fragment>
      <ListMenu
        t={t}
        dispatch={dispatch}
        detail={detail}
        handleOpenModalXacNhanNghiemThu={handleOpenModalXacNhanNghiemThu}
        handleOpenModalTraDoiThiCong={handleOpenModalTraDoiThiCong}
        handleOpenModalInHoSoCMIS={handleOpenModalInHoSoCMIS}
        handleOpenModalXemLaiPhuongAnCapDien={
          handleOpenModalXemLaiPhuongAnCapDien
        }
        handleOpenModalQuyetToanKhachHang={handleOpenModalQuyetToanKhachHang}
        handleOpenModalQuyetToanCongTy={handleOpenModalQuyetToanCongTy}
        handleOpenModalInBieuKhaiGia={handleOpenModalInBieuKhaiGia}
        inBienBanApGia={inBienBanApGia}
        setLoading={setLoading}
        handleOpenInPhuTai={handleOpenInPhuTai}
        setVisibleInBBLD={setVisibleInBBLD}
      />
      {showModalTraDoiThiCong}
      {showModalXacNhanNghiemThu}
      {showModalInHoSoCMIS}
      {showModalXemLaiPhuongAnCapDien}
      {showModalQuyettoanKhachHang}
      {showModalQuyetToanCongTy}
      {showModalPdfKhaiGia}
      {showModalInBienBanApGia}
      {showModalInBieuDoPhuTai}
      {showModalInBienBanLapDat}
    </Fragment>
  );
}
