import { closeModal } from "@state/system-config/reducer";
import { Button, Tabs } from "antd";
import { Fragment } from "react";
import { Container } from "./css-styled";
import ChiTietYeuCauTab from "./tabs/chi-tiet-yeu-cau";
import LichSuThaoTacTab from "./tabs/lich-su-thao-tac";
import DanhSachAnh from "./tabs/danh-sach-anh"
const { TabPane } = Tabs;

export default function ChiTietYeuCauModal(props) {
  const {
    indexTab,
    callback,
    dataDetail,
    loading,
    dataTienTrinh,
    dispatch,
    t,
    detailHoSo,
  } = props;
  return (
    <Fragment>
      <Container className="modal-body">
        <Fragment>
          <Tabs defaultActiveKey={indexTab} onChange={callback}>
            <TabPane tab={"Thông tin yêu cầu"} key="1">
              <ChiTietYeuCauTab
                detailYeuCau={dataDetail}
                loading={loading}
                detailHoSo={detailHoSo}
              />
            </TabPane>

            <TabPane tab={"Lịch sử thao tác"} key="2">
              <LichSuThaoTacTab
                dataTienTrinh={dataTienTrinh}
                loading={loading}
                detailHoSo={detailHoSo}
              />
            </TabPane>

            <TabPane tab={"Danh sách ảnh"} key="3">
              <DanhSachAnh
                detailHoSo={detailHoSo}
              />
            </TabPane>
          </Tabs>
        </Fragment>
      </Container>

      <div className="ant-modal-footer text-center">
        <Button type="default" onClick={() => dispatch(closeModal())}>
          {t("button.dong_lai")}
        </Button>
      </div>
    </Fragment>
  );
}
