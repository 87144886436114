import { BASE_API_URL_APGIA } from "@utils/constants";

export const APIAPGIA = {
    // áp giá new version
    AG_COMMON_NHOM_NN: `${BASE_API_URL_APGIA}/common/nhom-nganh-nghe-all`,
    AG_LIST_NV_PHAN_CONG: `${BASE_API_URL_APGIA}/common/nhanvien`,
    AG_CHI_TIET_YC: `${BASE_API_URL_APGIA}/common/chi-tiet-yeu-cau`,
    AG_CHI_TIET_BK: `${BASE_API_URL_APGIA}/common/chi-tiet-bang-ke`,

    AG_LIST_PHAN_CONG: `${BASE_API_URL_APGIA}/bophanapgia/danh-sach-yeu-cau-phan-cong`,
    AG_PHAN_CONG: `${BASE_API_URL_APGIA}/bophanapgia/them-phan-cong-yeu-cau`,
    AG_HUY_PHAN_CONG: `${BASE_API_URL_APGIA}/bophanapgia`,
    AG_BP_LIST_YC: `${BASE_API_URL_APGIA}/bophanapgia/danh-sach-yeu-cau-ky-duyet`,
    AG_BP_XAC_NHAN_YC: `${BASE_API_URL_APGIA}/bophanapgia/xac-nhan-yeu-cau`,
    AG_BP_TRA_LAI_YC: `${BASE_API_URL_APGIA}/bophanapgia/tra-lai-yeu-cau`,
    AG_BP_KY_NHAY: `${BASE_API_URL_APGIA}/bophanapgia/ky-nhay-bien-ban`,
    AG_BP_LIST_KTGS: `${BASE_API_URL_APGIA}/bophanapgia/danh-sach-kiem-tra-giam-sat`,
    AG_BP_CHUYEN_KTGS: `${BASE_API_URL_APGIA}/bophanapgia/kiem-tra-giam-sat`,
    AG_BP_HUY_YC: `${BASE_API_URL_APGIA}/bophanapgia/huy-yeu-cau`,
    AG_BP_TRA_YC: `${BASE_API_URL_APGIA}/bophanapgia/tra-lai-yeu-cau-pkd`,

    AG_LIST_YEU_CAU_DA_PC: `${BASE_API_URL_APGIA}/nhanvienapgia/danh-sach-yeu-cau-da-phan-cong`,
    AG_LIST_YEU_CAU_DANG_THUC_HIEN: `${BASE_API_URL_APGIA}/nhanvienapgia/danh-sach-yeu-cau-da-dong-bo-ve-mtb`,
    AG_LIST_YEU_CAU_MTB_DA_GUI_DL: `${BASE_API_URL_APGIA}/nhanvienapgia/danh-sach-yeu-cau-mtb-da-gui-du-lieu`,
    AG_LIST_YEU_CAU_KH_CHUA_KY: `${BASE_API_URL_APGIA}/nhanvienapgia/danh-sach-yeu-cau-khach-hang-chua-ky`,
    AG_NV_LIST_YEU: `${BASE_API_URL_APGIA}/nhanvienapgia/danh-sach-duyet-yeu-cau-nhan-vien`,

    AG_LAM_MOI: `${BASE_API_URL_APGIA}/nhanvienapgia/lam-moi-yeu-cau`,
    AG_NV_XAC_NHAN: `${BASE_API_URL_APGIA}/nhanvienapgia/xac-nhan-yeu-cau`,
    AG_NV_KIEM_TRA_LAI: `${BASE_API_URL_APGIA}/nhanvienapgia/kiem-tra-lai-yeu-cau`,

    AG_NV_LIST_XAC_NHAN_BK: `${BASE_API_URL_APGIA}/bangke/danh-sach-xac-nhan-bang-ke`,
    AG_NV_XAC_NHAN_BK: `${BASE_API_URL_APGIA}/bangke/nhan-vien-xac-nhan`,
    AG_NV_KY_BK: `${BASE_API_URL_APGIA}/bangke/nhan-vien-ky-so-hsm`,
    AG_NV_HUY_BK:  `${BASE_API_URL_APGIA}/bangke`,
    AG_TAO_BANG_KE: `${BASE_API_URL_APGIA}/bangke/pdf-bang-ke`,
    AG_BP_LIST_BK: `${BASE_API_URL_APGIA}/bangke/danh-sach-bang-ke-phong-ap-gia`,
    AG_BP_XAC_NHAN_BK:  `${BASE_API_URL_APGIA}/bangke/phong-ap-gia-xac-nhan`,
    AG_BP_KY_BK: `${BASE_API_URL_APGIA}/bangke/phong-ap-gia-ky-so-hsm`,
    AG_BP_TRA_LAI_BK: `${BASE_API_URL_APGIA}/bangke/phong-ap-gia-tra-lai`,
    AG_BP_TRA_LAI_BK_YC: `${BASE_API_URL_APGIA}/bangke/phong-ap-gia-tra-lai-by-id`,

    AG_LIST_HS_CMIS: `${BASE_API_URL_APGIA}/cmis/lay-yeu-cau-dinh-muc-ap-gia-tu-cmis`,
    AG_DONG_BO_VE_WEB: `${BASE_API_URL_APGIA}/cmis/dong-bo-yeu-cau-tu-cmis-ve-web`,
    AG_DONG_BO_DM_VE_CMIS: `${BASE_API_URL_APGIA}/cmis/dong-bo-thong-tin-dinh-muc-ve-cmis`,
    AG_DONG_BO_AG_VE_CMIS: `${BASE_API_URL_APGIA}/cmis/dong-bo-thong-tin-ap-gia-ve-cmis`,
    AG_IMPORT_PC: `${BASE_API_URL_APGIA}/cmis/import-excel-va-phan-cong`,
    AG_XUAT_EXCEL_DONG_BO: `${BASE_API_URL_APGIA}/cmis/lay-yeu-cau-tu-cmis-excel`,

    AG_LANH_DAO_LIS_YC: `${BASE_API_URL_APGIA}/lanhdao/danh-sach-yeu-cau-ky-duyet`,
    AG_LANH_DAO_XAC_NHAN_YC: `${BASE_API_URL_APGIA}/lanhdao/xac-nhan-yeu-cau`,
    AG_LD_TRA_LAI_YC: `${BASE_API_URL_APGIA}/lanhdao/tra-lai-yeu-cau`,
    AG_LD_KY_SO: `${BASE_API_URL_APGIA}/lanhdao/ky-so-hsm`,

    AG_PKD_LIS_YC: `${BASE_API_URL_APGIA}/phongkinhdoanh/danh-sach-yeu-cau-ky-duyet`,
    AG_PKD_XAC_NHAN_YC: `${BASE_API_URL_APGIA}/phongkinhdoanh/xac-nhan-yeu-cau`,
    AG_PKD_TRA_LAI_YC: `${BASE_API_URL_APGIA}/phongkinhdoanh/tra-lai-yeu-cau`,
    AG_PKD_KY_HSM: `${BASE_API_URL_APGIA}/phongkinhdoanh/ky-so-hsm`,
    AG_PKD_LIS_YC_DB: `${BASE_API_URL_APGIA}/phongkinhdoanh/danh-sach-yeu-cau-dong-bo-cmis`,
    AG_PKD_TRA_LAI_YC_DB: `${BASE_API_URL_APGIA}/phongkinhdoanh/tra-lai-yeu-cau-dong-bo-cmis`,
    AG_PKD_HUY_YC: `${BASE_API_URL_APGIA}/phongkinhdoanh/huy-yeu-cau`,
    AG_PKD_PHAN_CONG: `${BASE_API_URL_APGIA}/phongkinhdoanh/them-phan-cong-yeu-cau`,
    AG_PKD_HUY_PHAN_CONG: `${BASE_API_URL_APGIA}/phongkinhdoanh`,
    AG_PKD_LIST_YC_PHAN_CONG: `${BASE_API_URL_APGIA}/phongkinhdoanh/danh-sach-yeu-cau-phan-cong`,

    AG_TIM_KIEM_YC: `${BASE_API_URL_APGIA}/timkiem/danh-sach-tim-kiem`,
    AG_TIM_KIEM_YC_EXCEL: `${BASE_API_URL_APGIA}/timkiem/danh-sach-tim-kiem-excel`,
    AG_TIM_KIEM_BK: `${BASE_API_URL_APGIA}/timkiem/danh-sach-tim-kiem-bang-ke`,

    AG_KY_NHAY_BIEN_BAN: `${BASE_API_URL_APGIA}/phongkinhdoanh/ky-nhay-bien-ban`,

    AG_KY_NHAY_LIST: `${BASE_API_URL_APGIA}/configkynhay/list`,
    AG_KY_NHAY_DETAIL: `${BASE_API_URL_APGIA}/configkynhay`,
    AG_KY_NHAY_DELETE: `${BASE_API_URL_APGIA}/configkynhay`,
    AG_KY_NHAY_USER: `${BASE_API_URL_APGIA}/configkynhay/user`,
    AG_KY_NHAY_CREATE: `${BASE_API_URL_APGIA}/configkynhay/create`,
    AG_KY_NHAY_UPDATE: `${BASE_API_URL_APGIA}/configkynhay/update`,

    AG_DONG_BO_ANH_HT: `${BASE_API_URL_APGIA}/yeu-cau/anh-hien-truong-sync`,

    AG_UPLOAD_BB_GIAY: `${BASE_API_URL_APGIA}/bienban/upload-bien-ban-giay`,
};
