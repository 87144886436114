import imagePDF from "@assets/images/image-pdf.png";
import noImage from "@assets/images/no-image.png";
import { Button, Col, Image, Modal, Row } from "antd";
import { useState } from "react";
import { handleDeleteImage } from "../../crm-services";
import LoadingComponent from "@components/Loading";
export default function ListImage(props) {
  const {
    loading,
    dataImage,
    showSliderImage,
    setCaption,
    setModalSrc,
    setImageIndex,
    imageIndex,
    setSectionIndex,
    setCurrentSectionLength,
    setHideButtonLeft,
    setHideButtonRight,
    showModal,
    setShowModal,
    modalSrc,
    plusSlides,
    hideButtonLeft,
    hideButtonRight,
    note,
    detail,
    setDataImage,
    setLoading,
    // removeImage,
    // remove,
  } = props;
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [idDelete, setIdDelete] = useState();
  const handleCloseModal = () => {
    setVisibleDelete(false);
  };
  // const [, setLoading] = useState(false);
  const handleDeleteImg = (id) => {
    setVisibleDelete(true);
    setIdDelete(id);
  };
  const handleSubmit = () => {
    handleDeleteImage(
      idDelete,
      setLoading,
      detail,
      setDataImage,
      setVisibleDelete
    );
  };

  return (
    <LoadingComponent loading={loading}>
      <Row gutter={24} style={{ margin: 0 }}>
        {dataImage &&
          dataImage.map((item, index) => {
            return (
              <Col
                span={24}
                md={8}
                key={index}
                style={{
                  textAlign: "center",
                  marginTop: "24px",
                  // marginBottom: pic.ghiChu ? "20px" : "0",
                }}
              >
                <div
                  className="image-container"
                  style={{ position: "relative" }}
                >
                  <Image
                    preview={false}
                    src={item.pathImage}
                    alt={""}
                    width={"100%"}
                    height={"350px"}
                    onError={(e) => {
                      e.currentTarget.src = noImage;
                    }}
                    style={{ objectFit: "cover", cursor: "pointer" }}
                    onClick={() => {
                      showSliderImage();
                      setCaption(item);
                      setModalSrc(item.pathImage);
                      setImageIndex(index);
                      setSectionIndex(index);
                      // setNote(pic.ghiChu);
                      setCurrentSectionLength(dataImage.length);
                      if (index === 0) setHideButtonLeft(true);
                      else setHideButtonLeft(false);
                      if (dataImage.length === index + 1)
                        setHideButtonRight(true);
                      else setHideButtonRight(false);
                    }}
                  />
                  <Button
                    type="danger"
                    size="small"
                    onClick={() => handleDeleteImg(item.id)}
                    style={{ position: "absolute", right: "0" }}
                  >
                    Xóa
                  </Button>
                </div>
              </Col>
            );
          })}
      </Row>
      <Modal
        visible={showModal}
        width={800}
        onCancel={() => false}
        onOk={() => false}
        footer={
          note ? (
            <div
              style={{
                // border: "1px solid #f0f0f0",
                // borderRadius: "0px 0px 8px 8px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                padding: "4px",
                textAlign: "center",
              }}
            >
              {note}
            </div>
          ) : (
            []
          )
        }
        className="modal-image"
      >
        {/* <div style={{ textAlign: "right" }}> */}
        <Button
          className="close"
          onClick={() => setShowModal(false)}
          style={{
            fontSize: "24px",
            border: "unset",
            position: "absolute",
            top: "0",
            right: "10px",
          }}
        >
          &times;
        </Button>
        {/* </div> */}

        <div
          className="mySlides"
          style={{
            display: showModal ? "block" : "none",
            marginTop: "20px",
            height: "450px",
          }}
        >
          <Image
            className="modal-content"
            src={modalSrc?.indexOf(".pdf") > -1 ? imagePDF : modalSrc}
            alt=""
            height={"100%"}
            width={"100%"}
            onError={(e) => {
              e.currentTarget.src = noImage;
            }}
            style={{ padding: 0, objectFit: "contain" }}
          />

          <Button
            className="prev"
            onClick={() => {
              plusSlides(imageIndex - 1);
            }}
            style={{
              position: "absolute",
              top: "50%",
              left: "-10%",
              transform: "translate(50%, -50%)",
            }}
            disabled={hideButtonLeft ? true : false}
          >
            &#10094;
          </Button>
          <Button
            className="next"
            onClick={() => {
              plusSlides(imageIndex + 1);
            }}
            style={{
              position: "absolute",
              top: "50%",
              right: "-10%",
              transform: "translate(-50%, -50%)",
            }}
            disabled={hideButtonRight ? true : false}
          >
            &#10095;
          </Button>
        </div>
      </Modal>
      <Modal
        title="XÓA ẢNH."
        visible={visibleDelete}
        onCancel={handleCloseModal}
        footer={null}
      >
        <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
          Bạn có chắc chắn muốn xóa ảnh này?
        </h4>
        <Row gutter={24} justify="center" style={{ marginTop: "20px" }}>
          <Col span={12} style={{ textAlign: "right" }}>
            <Button
              key="back"
              onClick={handleCloseModal}
              className="button-closed"
            >
              Đóng lại
            </Button>
          </Col>
          <Col span={12}>
            <Button
              key="submit"
              type="danger"
              className="button-primary"
              htmlType="submit"
              onClick={handleSubmit}
              loading={loading}
            >
              Xóa
            </Button>
          </Col>
        </Row>
      </Modal>
    </LoadingComponent>
  );
}
