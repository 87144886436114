import { Fragment } from "react";
import { Form, Col, Row, Input } from "antd";
import { useTranslation } from "react-i18next";
export default function PanelsThongTinveDiemDoHienTai() {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Row gutter={24}>
        <Col span={24} md={12}>
          <Form.Item
            label={t("cap_nhat_ho_so.dia_chi_su_dung_dien")}
            name="diaChiCapDienCmis"
          >
            <Input
              style={{
                border: "none",
                backgroundColor: "#fff",
                color: "black",
                paddingLeft: 0,
              }}
              disabled={true}
              className="fs-14px font-bold"
            ></Input>
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            label={t("cap_nhat_ho_so.muc_dich_su_dung_dien")}
            name="mucDichSinhHoatCmis"
          >
            <Input
              style={{
                border: "none",
                backgroundColor: "#fff",
                color: "black",
                paddingLeft: 0,
              }}
              disabled={true}
              className="fs-14px font-bold"
            ></Input>
          </Form.Item>
        </Col>

        <Col span={24} md={12} lg={8}>
          <Form.Item
            label={t("cap_nhat_ho_so.cong_suat_su_dung")}
            name="congSuatCmis"
          >
            <Input
              style={{
                border: "none",
                backgroundColor: "#fff",
                color: "black",
                paddingLeft: 0,
              }}
              disabled={true}
              className="fs-14px font-bold"
            ></Input>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8}>
          <Form.Item label={t("cap_nhat_ho_so.so_pha")} name="soPhaCmis">
            <Input
              style={{
                border: "none",
                backgroundColor: "#fff",
                color: "black",
                paddingLeft: 0,
              }}
              disabled={true}
              className="fs-14px font-bold"
            ></Input>
          </Form.Item>
        </Col>

        <Col span={24} md={12} lg={8}>
          <Form.Item label={t("cap_nhat_ho_so.dien_ap")} name="dienApCmis">
            <Input
              style={{
                border: "none",
                backgroundColor: "#fff",
                color: "black",
                paddingLeft: 0,
              }}
              disabled={true}
              className="fs-14px font-bold"
            ></Input>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8}>
          <Form.Item label={t("cap_nhat_ho_so.lap_qua_ti")} name="lapQuaTiCmis">
            <Input
              style={{
                border: "none",
                backgroundColor: "#fff",
                color: "black",
                paddingLeft: 0,
              }}
              disabled={true}
              className="fs-14px font-bold"
            ></Input>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={8}>
          <Form.Item
            label={t("cap_nhat_ho_so.tram_cap_dien")}
            name="tenTramCmis"
          >
            <Input
              style={{
                border: "none",
                backgroundColor: "#fff",
                color: "black",
                paddingLeft: 0,
              }}
              disabled={true}
              className="fs-14px font-bold"
            ></Input>
          </Form.Item>
        </Col>

        <Col span={24} md={12} lg={8}>
          <Form.Item
            label={t("cap_nhat_ho_so.vi_tri_lap_dat_cong_to")}
            name="viTriCongToCmis"
          >
            <Input
              style={{
                border: "none",
                backgroundColor: "#fff",
                color: "black",
                paddingLeft: 0,
              }}
              disabled={true}
              className="fs-14px font-bold"
            ></Input>
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  );
}
