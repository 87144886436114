import { Form } from "antd";
import { useCallback, useEffect, useState } from "react";
import { handleMultiDS } from "../services";
import FormBoLoc from "./form-bo-loc";

export default function ListFilterBatThuong(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    tooltip,
    user,
    typeList,
  } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [trangThai, setTrangThai] = useState([]);
  const [tram, setTram] = useState([]);

  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  useEffect(() => {
    handleMultiDS(setLoading, setTrangThai, setTram);
  }, []);

  return (
    <FormBoLoc
      user={user}
      form={form}
      handleSearch={handleSearch}
      tooltip={tooltip}
      clear={clear}
      filterConditions={filterConditions}
      trangThai={trangThai}
      typeList={typeList}
      loading={loading}
      tram={tram}
    />
  );
}
