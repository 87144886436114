import React, { useCallback, useEffect } from "react";

import styled from "styled-components";
import { systemConfigSelector, closeModal } from "@state/system-config/reducer";
import { useSelector, useDispatch } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import { WIDTH_LEFT_MENU } from "@utils/constants";
import { useLocation } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { lapPhuongAnSelector } from "@state/cap-dien/nhan-vien-khao-sat/lap-phuong-an/reducer";
import ModalWarningChange from "@components/Layout/ModalWarningChange";
import {
  notSavedPhuongAnCapDien,
  notSavedThongTinCoBan,
  rangeOfChange,
} from "@state/cap-dien/nhan-vien-khao-sat/lap-phuong-an/reducer";
import { xacNhanThiCongSelector } from "@state/cap-dien/thi-cong/xac-nhan-thi-cong";
import {
  notSavedCongTo,
  notSavedThiCong,
  notSavedThongTinThiCong,
} from "@state/cap-dien/thi-cong/xac-nhan-thi-cong/reducer";
// import { phanQuyenDiemDoSelector } from "@state/giao-nhan-dien-nang";
// import { notSavedPhanQuyenDiemDo } from "@state/giao-nhan-dien-nang/reducer";
import { yeuCauDuocPhanCongSelector } from "@state/treo-thao/nhan-vien-treo-thao";
import { getSaveDataPopup } from "@state/treo-thao/nhan-vien-treo-thao/reducer";
import { Grid, Modal } from "antd";
import {
  callAPIImageCMIS,
  saveDongBoImageScan,
  taiGiayToScanSelector,
} from "@state/cap-dien/tai-anh-scan/reducer";

const { useBreakpoint } = Grid;
export default function ModalComponent(props) {
  const { collapsed, modal, scrollHeight } = useSelector(systemConfigSelector);
  const dispatch = useDispatch();
  const location = useLocation();
  const { phuongAnCapDien, thongTinCoBan } = useSelector(lapPhuongAnSelector);
  const { saveData } = useSelector(yeuCauDuocPhanCongSelector);
  // const { phanQuyenDiemDo } = useSelector(phanQuyenDiemDoSelector);
  const { thongTin, congTo, thiCong } = useSelector(xacNhanThiCongSelector);
  const { dongBoImage } = useSelector(taiGiayToScanSelector);

  const screens = useBreakpoint();
  const mobile = screens.xs && !screens.md;
  useEffect(() => {
    if (
      !phuongAnCapDien &&
      !thongTinCoBan &&
      !thongTin &&
      !congTo &&
      !thiCong &&
      // !phanQuyenDiemDo &&
      !saveData
    ) {
      dispatch(closeModal());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, dispatch]);

  const renderSystemConfig = useCallback(() => {
    const style = {
      left: WIDTH_LEFT_MENU,
      display: "none",
    };
    if (collapsed) {
      style.left = 80;
    }
    if (mobile) {
      style.left = 0;
    }
    if (modal.visible) {
      style.display = "block";
    }
    return style;
  }, [collapsed, mobile, modal.visible]);

  const onClose = useCallback(() => {
    if (dongBoImage === false) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: "Xác nhận?",
        content:
          "Tồn tại ảnh/tài liệu chưa đồng bộ về CMIS, hệ thống sẽ tự động đồng bộ sau khi đóng màn hình !",
        onOk() {
          dispatch(callAPIImageCMIS(true));
          dispatch(saveDongBoImageScan(true));
          dispatch(closeModal());
        },
        onCancel() {},
        okText: "Đồng ý",
        okButtonProps: { type: "primary" },
        cancelText: "Hủy",
      });
    } else {
      if (
        phuongAnCapDien ||
        thongTinCoBan ||
        thongTin ||
        congTo ||
        thiCong ||
        // phanQuyenDiemDo ||
        saveData
      ) {
        ModalWarningChange(
          () => {
            dispatch(rangeOfChange(false));
            if (phuongAnCapDien) dispatch(notSavedPhuongAnCapDien(false));
            if (thongTinCoBan) dispatch(notSavedThongTinCoBan(false));
            if (thongTin) dispatch(notSavedThongTinThiCong(false));
            // if (phanQuyenDiemDo) dispatch(notSavedPhanQuyenDiemDo(false));
            if (congTo) dispatch(notSavedCongTo(false));
            if (thiCong) dispatch(notSavedThiCong(false));
            if (saveData) dispatch(getSaveDataPopup(false));
            dispatch(closeModal());
          },
          () => false // do nothing
        );
      } else {
        dispatch(closeModal());
      }
    }
  }, [
    congTo,
    dispatch,
    dongBoImage,
    phuongAnCapDien,
    saveData,
    thiCong,
    thongTin,
    thongTinCoBan,
  ]);
  return (
    <ModalWrapper widthLeftMenu={WIDTH_LEFT_MENU}>
      <div
        className={`modal-container ${
          scrollHeight ? "auto-scroll" : "no-scroll"
        }`}
        style={renderSystemConfig()}
      >
        <div className="modal-header">
          <span>{modal && modal.title ? modal.title : ""}</span>
          <div className="close-icon" onClick={onClose}>
            <CloseOutlined />
          </div>
        </div>
        <Scrollbars
          style={{ height: "calc(100vh - 96px)" }}
          autoHide
          id="scroll-bar"
        >
          <div
            className={`modal-content ${
              collapsed ? "collapsed-active" : "collapsed-unactive"
            }`}
          >
            {modal.content}
          </div>
        </Scrollbars>
      </div>
      {props.children}
    </ModalWrapper>
  );
}

const ModalWrapper = styled.div`
  .modal-container {
    position: fixed;
    inset: 0;
    z-index: 999;
    height: 100%;
    background-color: #fff;
    top: 48px;
  }
  .modal-header {
    height: 48px;
    background: #279bef;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    .close-icon {
      :hover {
        cursor: pointer;
      }
    }
  }
  .collapsed-active {
    .modal-footer {
      width: 100%;
    }
  }
  .collapsed-unactive {
    .modal-footer {
      width: calc(100vw - ${(props) => props.widthLeftMenu}px);
    }
  }
`;
