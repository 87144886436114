import { memo, useState } from "react";
import { Form, Button, Modal, Row, Col, Select } from "antd";
import { useTranslation } from "react-i18next";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import "moment/locale/vi";
import { handleCreateUpdateService } from "./services";
const { Option } = Select;

function GhiChiSoModal({
  detailGhiChiSo,
  visible,
  closeModal,
  getListGhiChiSo,
  user,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // const inputRef = useRef(null);
  const [form] = Form.useForm();
  // const [isActive, setIsActive] = useState(detailGhiChiSo.tinhTrang);

  const handleSubmitData = (values) => {
    const data = {
      ...values,
      // tinhTrang: isActive ? 1 : 0,
    };

    if (detailGhiChiSo.id) {
      data.ids = [detailGhiChiSo.id];
    }
    handleCreateUpdate(data, form);
  };

  // useEffect(() => {
  //   if (visible) inputRef.current.focus();
  //   //Set Active
  //   if (detailGhiChiSo && detailGhiChiSo.id) {
  //     setIsActive(isActive);
  //   } else {
  //     setIsActive(true);
  //   }
  //   // eslint-disable-next-line
  // }, []);

  //xử lý call api sửa/tạo

  const handleCreateUpdate = (data, form) => {
    handleCreateUpdateService(
      data,
      setLoading,
      getListGhiChiSo,
      closeModal,
      form
    );
  };

  //Thay đổi field TrangThai
  // const handleChangeActive = (e) => {
  //   setIsActive(e.target.checked);
  // };

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={
          detailGhiChiSo.id ? "Cập nhật số ghi chỉ số" : "Tạo số ghi chỉ số"
        }
        visible={visible}
        onCancel={closeModal}
        className="modal-uy-quyen"
        footer={null}
      >
        <FormComponent
          autoComplete="off"
          form={form}
          name="form-submit"
          layout="vertical"
          onFinish={handleSubmitData}
          initialValues={{
            ...detailGhiChiSo,
          }}
        >
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item>
                <span>{t("ti_le_ban_dien.don_vi_quan_ly")}</span>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item>
                <span className="font-bold">{user}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <span>Mã sổ GCS</span>
              </Form.Item>
            </Col>
            
            <Col span={16}>
              <Form.Item>
                <span className="font-bold">{detailGhiChiSo.maSoGCS}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <span>Tên sổ GCS</span>
              </Form.Item>
            </Col>
            
            <Col span={16}>
              <Form.Item>
                <span className="font-bold">{detailGhiChiSo.tenSoGCS}</span>
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item
                label="Mã sổ GCS"
                name="maSoGCS"
                className="required-field"
              >
                <Input ref={inputRef} placeholder="Nhập mã sổ GCS" disabled/>
              </Form.Item>
            </Col> */}
            {/* <Col span={24}>
              <Form.Item
                label="Tên số GCS"
                name="tenSoGCS"
                className="required-field"
              >
                <Input placeholder="Nhập tên sổ GCS" />
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item
                label="Loại sổ"
                name="loaiSo"
                className="required-field"
              >
                <Select>
                  <Option value={null}>--Chọn--</Option>
                  <Option value={1}>TP</Option>
                  <Option value={2}>DN</Option>
                  <Option value={3}>KH</Option>
                </Select>
              </Form.Item>
            </Col> */}
            <Col span={8}>
              <Form.Item>
                <span>Hình thức GCS</span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label=""
                name="hinhThucGCS"
                className="required-field"
              >
                <Select>
                  <Option value={null}>--Chọn--</Option>
                  <Option value={'MDMS'}>MDMS</Option>
                  <Option value={'HHU'}>HHU</Option>
                  <Option value={'MTB'}>MTB</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={8}>
              <Form.Item label="Số kỳ" name="soKy" className="required-field">
                <Input type="number" max={3} min={1} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Ngày ghi"
                name="ngayGhi"
                className="required-field"
              >
                <Input type="number" max={31} min={1} />
              </Form.Item>
            </Col> */}
            {/* <Col span={24} style={{ display: "flex", alignItems: "center" }}>
              <Form.Item
                label=""
                name="tinhTrang"
                className="required-field"
                style={{ marginBottom: 10 }}
              >
                <Checkbox>Đang hoạt động</Checkbox>
                <Checkbox onChange={handleChangeActive} checked={isActive}>
                  Hoạt động
                </Checkbox>
              </Form.Item>
            </Col> */}
            {/* <Col span={24}>
              <Form.Item label="Tên đơn vị" name="donViId">
                <Select>
                  <Option value={null}>--Chọn--</Option>
                  {dataDonVi && dataDonVi.length > 0
                    ? dataDonVi.map((item, index) => (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Số kỳ" name="soKy">
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label="Ngày ghi" name="ngayGhi">
                <DatePicker
                  locale={locale}
                  format={FORMAT_DATE}
                  onChange={handleChangeNgay}
                  placeholder={FORMAT_DATE}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Hình thức" name="hinhThuc">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Loại số ghi chỉ số" name="loaiSoGCS">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tình trạng" name="tinhTrang">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Trạng thái" name="trangThai">
                <Input />
              </Form.Item>
            </Col> */}
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-submit"
                loading={loading}
              >
                {detailGhiChiSo.id ? t("button.cap_nhat") : t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(GhiChiSoModal);
