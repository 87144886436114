
import React, { Fragment, useState, useEffect, useCallback, useMemo } from "react";
import { Tooltip } from "antd";
import CauHinhCToThao from "./cau-hinh";
import { getCauHinhchiSo, submitDeleteCauHinh } from "./services";
import { TableStyled, Containers } from "./css-styled";
import TableComponent from "@components/TableComponent";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import {
  customColumn,
  handlePagination,
} from "@utils/function";
import ButtonComponent from "@components/Button";
import CheckPermission from "@components/CheckPermission";
import { chi_so_cong_to } from "@permissions/i_chisocongto";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import { DEFAULT_PAGESIZE, DEFAULT_PAGEINDEX } from "@utils/constants";
import moment from "moment";

export default function Index() {
  const { user } = useSelector(authSelector);
  const [dataCauHinh, setDataCauHinh] = useState([]);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cauHinhChiSo, setCauHinhChiSo] = useState();
  const [visibleModal, setVisibleModal] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });
  // search list
  const getListDataCauHinh = useCallback(() => {
    getCauHinhchiSo(
      setLoading,
      filterConditions,
      setDataCauHinh,
      setTotalCount
    );
  }, [filterConditions]);

  useEffect(() => {
    getListDataCauHinh();
  }, [getListDataCauHinh, user]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListDataCauHinh();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListDataCauHinh, setFetchDataTable]);

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const handleOpenModal = useCallback(
    (row) => {
      setCauHinhChiSo(row);
      setVisibleModal(true)
    }, []);
  const handleCloseModal = () => {
    setVisibleModal(false);
  };
  //render popup chi tiết công tơ
  const showModal = useMemo(
    () =>
      visibleModal && (
        <CauHinhCToThao
          visible={visibleModal}
          closeModal={handleCloseModal}
          cauHinhChiSo={cauHinhChiSo}
          getCauHinh={getListDataCauHinh}
          handleCloseModal={handleCloseModal}
        />
      ),
    [visibleModal, cauHinhChiSo, getListDataCauHinh]
  );

  //xóa dữ liệu
  const handleDelete = useCallback(
    (id) => {
      submitDeleteCauHinh(id, setLoading, getListDataCauHinh);
    },
    [getListDataCauHinh]
  );
  const columns = [
    {
      title: "Ngày áp dụng",
      dataIndex: "ngayApDung",
      key: "ngayApDung",
      render: (text) =>
        <div className="table-text-center">
          <span>{text}</span>
        </div>,
      width: "40%",
      fixed: "left"
    },
    {
      title: "Chênh lệch sản lượng(%)",
      dataIndex: "chenhLechSanLuong",
      key: "chenhLechSanLuong",
      render: (text, record) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "40%",
    },
    {
      title: "Tác vụ",
      dataIndex: "tacVu",
      key: "tacVu",
      render: (_, record) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={chi_so_cong_to.i4s}>
            <Tooltip title={"Sửa"}>
              <ButtonComponent
                type="link"
                onClick={() => handleOpenModal(record)}
                className="pd-5px"
                disabled={moment() >= moment(record.ngayApDung, "DD-MM-YYYY")}
              >
                <PencilIcon />
              </ButtonComponent>
            </Tooltip>
          </CheckPermission>
          <CheckPermission permissionCode={chi_so_cong_to.i4x}>
            <Tooltip title={"Xóa"}>
              <ButtonComponent
                type="link"
                onClick={() => handleDelete(record.id)}
                className="pd-5px"
                disabled={moment() >= moment(record.ngayApDung, "DD-MM-YYYY")}
              >
                <TrashIcon />
              </ButtonComponent>
            </Tooltip>
          </CheckPermission>
        </div>
      ),
      // width: "2%",
    },
  ];
  const genExtra = useCallback(
    () => (
      <div>
        <CheckPermission permissionCode={chi_so_cong_to.i4t}>
          <ButtonComponent
            size="default"
            type="primary"
            className="mr-5px"
            onClick={() => handleOpenModal()}
          >
            Tạo mới
          </ButtonComponent>
        </CheckPermission>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <Fragment>
      <Containers>
        <TableStyled>
          <TableComponent
            header={"Danh sách cấu  hình"}
            dataSource={dataCauHinh}
            columns={customColumn(columns)}
            loading={loading}
            totalData={totalCount}
            renderExtraAction={() => genExtra()}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
          />
        </TableStyled>
      </Containers>
      {showModal}
    </Fragment>
  );
}
