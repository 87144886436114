import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  getErrorMessage,
  parseParams,
} from "@utils/function";
import { authDeleteData, authGetData, authPostData } from "@utils/request";

export const tiepNhanYeuCauCMIS = (
  payload,
  setLoading,
  setFetchList,
  setVisibled,
  setDataNoti
) => {
  authGetData({
    url: `${Endpoint.TIEP_NHAN_YEU_CAU_TREO_THAO_CMIS}?${buildQueryString(
      parseParams(payload)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchList(true);
        if (res.data) {
          setVisibled(true);
          setDataNoti(res.data);
        }
      }
    },
  });
};

export const handleGetDoi = (setLoading, setDataDoi, donviId) => {
  authGetData({
    url: `${Endpoint.GET_TEAM_LIST}?donviId=${donviId}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataDoi(res.data);
      }
    },
  });
};

export const handleGanDoi = (
  setLoading2,
  payload,
  handleCloseModal,
  setFetchList,
  setSelectedRowKeys,
  form2
) => {
  authPostData({
    url: Endpoint.UPDATE_TO_DOI_TREO_THAO,
    method: METHOD_PUT,
    payload,
    setLoading: setLoading2,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
        setSelectedRowKeys([]);
        handleCloseModal();
      } else {
        getErrorMessage(res, form2);
      }
    },
  });
};

//Call api Get list Lý Do Treo Tháo
/**
 *
 * @param {boolean} setLoading
 * @param {array} setDataLyDoTreoThao
 * @param {int} loaiBienBan
 */
export const handleGetDataLyDoTreoThao = (
  setLoading,
  setDataLyDoTreoThao,
  loaiBienBan
) => {
  authGetData({
    url: `${Endpoint.GET_LY_DO_TREO_THAO}?LoaiBienBan=${loaiBienBan}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setDataLyDoTreoThao(res.data);
      } else {
        setDataLyDoTreoThao([]);
      }
    },
  });
};

/**
 *
 * @param {@boolean} setLoadingLyDo
 * @param {*} payload
 * @param {*} handleCloseModal
 * @param {*} setFetchList
 * @param {*} setSelectedRowKeys
 * @param {*} formLyDo
 */
export const handleUpdateLydo = (
  setLoadingLyDo,
  payload,
  handleCloseModalLyDo,
  setFetchList,
  setSelectedRowKeys,
  formLyDo
) => {
  authPostData({
    url: Endpoint.UPDATE_LY_DO_TREO_THAO,
    method: METHOD_PUT,
    payload,
    setLoading: setLoadingLyDo,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
        setSelectedRowKeys([]);
        handleCloseModalLyDo();
      } else {
        getErrorMessage(res, formLyDo);
      }
    },
  });
};

export const callAPIRemoveYC = (
  selectedRowKeys,
  setLoading,
  setFetchList,
  setSelectedRowKeys
) => {
  authDeleteData({
    url: Endpoint.REMOVE_YC_TREO_THAO + "?ids=" + selectedRowKeys,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchList(true);
        setSelectedRowKeys([]);
      }
    },
    content: "Bạn chắc rằng muốn xóa yêu cầu này?",
    title: "Xóa dữ liệu",
  });
};
