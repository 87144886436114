import { authSelector } from "@state/auth";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { handleListBaoCao } from "./services";
import BaoCaoTongHopTTKH from "./ui-bao-cao";
import { handleMultiBoLoc } from "./services";
import moment from "moment";
import { FORMAT_ENGLISH, TYPE_CHANGE_DATE } from "@utils/constants";

export default function Index() {
  // const [, setSearchParams] = useSearchParams();
  // const location = useLocation();
  // const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [donVi, setDonVi] = useState([]);
  // const [data, setData] = useState([]);
  // const [totalCount, setTotalCount] = useState(0);
  const { user } = useSelector(authSelector);
  // const [tuThang, setTuThang] = useState("");
  // const [denThang, setDenThang] = useState("");
  // const [disableDenThang, setDisableDenThang] = useState(true);
  // const { height } = useWindowSize();
  //filter
  // const [filterConditions, setFilterConditions] = useState({
  //   // pageIndex: DEFAULT_PAGEINDEX,
  //   // pageSize: DEFAULT_PAGESIZE,
  //   ...parseParams(location.search),
  //   donViId: user.unitId,
  // });

  // useLayoutEffect(() => {
  //   setSearchParams(removeUndefinedAttribute(filterConditions));
  // }, [setSearchParams, filterConditions]);

  // //change pagination
  // const onChangePagination = (paging, filters, sorter) => {
  //   handlePagination(paging, sorter, setFilterConditions);
  // };

  // //fetch báo cáo
  // const getListBaoCao = useCallback(() => {
  //   handleListBaoCao(setLoading, setData, setTotalCount, filterConditions);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

  //search
  // const handleSearch = useCallback((values) => {
  //   setFilterConditions((oldState) => ({
  //     ...oldState,
  //     pageIndex: DEFAULT_PAGEINDEX,
  //     ...values,
  //     donViId: values.donViId,
  //     tuThang: formatSplitDate(values.tuThang, false),
  //     denThang: formatSplitDate(values.denThang, false),
  //     thangKiemTra: formatSplitDate(values.thangKiemTra, false),
  //   }));
  // }, []);

  // //clear search
  // const clearFilter = useCallback(() => {
  //   setFilterConditions({
  //     pageIndex: DEFAULT_PAGEINDEX,
  //     pageSize: DEFAULT_PAGESIZE,
  //     donViId: user.unitId,
  //   });
  // }, [user.unitId]);

  // useEffect(() => {
  //   getListBaoCao();
  // }, [getListBaoCao]);

  // const handleTuThang = useCallback(
  //   (value) => {
  //     setTuThang(value);
  //     setDisableDenThang(false);
  //   },
  //   // eslint-disable-next-line
  //   [tuThang]
  // );

  // const handleDenThang = useCallback(
  //   (value) => {
  //     setDenThang(value);
  //   },
  //   // eslint-disable-next-line
  //   [denThang]
  // );

  // const columns = [
  //   {
  //     title: "STT",
  //     dataIndex: "stt",
  //     key: "stt",
  //     render: (text) => (
  //       <div className="table-text-center">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: "4%",
  //   },
  //   {
  //     title: `Thông tin đã phát triển khách hàng từ tháng  ${
  //       filterConditions.tuThang
  //         ? filterConditions.tuThang.split("-")[1]
  //         : "..."
  //     } năm ${
  //       filterConditions.tuThang
  //         ? filterConditions.tuThang.split("-")[0]
  //         : "..."
  //     } đến tháng ${
  //       filterConditions.denThang
  //         ? filterConditions.denThang.split("-")[0]
  //         : "..."
  //     } Năm ${
  //       filterConditions.denThang
  //         ? filterConditions.denThang.split("-")[1]
  //         : "..."
  //     }`,
  //     children: [
  //       {
  //         title: "Mã khách hàng",
  //         dataIndex: "maKhachHang",
  //         key: "maKhachHang",
  //         render: (text) => (
  //           <div className="table-text-left">
  //             <span>{text}</span>
  //           </div>
  //         ),
  //         width: "8%",
  //       },
  //       {
  //         title: "Tên khách hàng",
  //         dataIndex: "tenKhachHang",
  //         key: "tenKhachHang",
  //         render: (text) => (
  //           <div className="table-text-left">
  //             <span>{text}</span>
  //           </div>
  //         ),
  //         width: "13%",
  //       },
  //       {
  //         title: "Ngày hiệu lực",
  //         dataIndex: "ngayHieuLuc",
  //         key: "ngayHieuLuc",
  //         render: (text) => (
  //           <div className="table-text-center">
  //             <span>{text}</span>
  //           </div>
  //         ),
  //         width: "8%",
  //       },
  //       {
  //         title: "Mã sổ GCS",
  //         dataIndex: "soGCS",
  //         key: "soGCS",
  //         render: (text) => (
  //           <div className="table-text-right">
  //             <span>{text}</span>
  //           </div>
  //         ),
  //         width: "8%",
  //       },
  //       {
  //         title: "Địa chỉ",
  //         dataIndex: "diaChi",
  //         key: "diaChi",
  //         render: (text) => (
  //           <div className="table-text-left">
  //             <span>{text}</span>
  //           </div>
  //         ),
  //         width: "17%",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Thông tin của tháng được tra cứu",
  //     children: [
  //       {
  //         title: "Mã khách hàng",
  //         dataIndex: "maKhachHangDuocTraCuu",
  //         key: "maKhachHangDuocTraCuu",
  //         render: (text) => (
  //           <div className="table-text-left">
  //             <span>{text}</span>
  //           </div>
  //         ),
  //         width: "8%",
  //       },
  //       {
  //         title: "Tên khách hàng",
  //         dataIndex: "tenKhachHangDuocTraCuu",
  //         key: "tenKhachHangDuocTraCuu",
  //         render: (text) => (
  //           <div className="table-text-left">
  //             <span>{text}</span>
  //           </div>
  //         ),
  //         width: "13%",
  //       },
  //       {
  //         title: "Ngày hiệu lực",
  //         dataIndex: "ngayHieuLucDuocTraCuu",
  //         key: "ngayHieuLucDuocTraCuu",
  //         render: (text) => (
  //           <div className="table-text-center">
  //             <span>{text}</span>
  //           </div>
  //         ),
  //         width: "8%",
  //       },
  //       {
  //         title: "Mã sổ GCS",
  //         dataIndex: "soGCSDuocTraCuu",
  //         key: "soGCSDuocTraCuu",
  //         render: (text) => (
  //           <div className="table-text-right">
  //             <span>{text}</span>
  //           </div>
  //         ),
  //         width: "8%",
  //       },
  //       {
  //         title: "Địa chỉ",
  //         dataIndex: "diaChiDuocTraCuu",
  //         key: "diaChiDuocTraCuu",
  //         render: (text) => (
  //           <div className="table-text-left">
  //             <span>{text}</span>
  //           </div>
  //         ),
  //         width: "17%",
  //       },
  //     ],
  //   },
  // ];

  const toDay = moment().format(FORMAT_ENGLISH);
  const [tuNgay, setTuNgay] = useState(toDay);
  const [denNgay, setDenNgay] = useState(toDay);
  const [thangKT, setThangKT] = useState(toDay);
  const [unit, setUnit] = useState(user.unitId);

  //change date
  const handleChangeNgay = (date, type) => {
    let convert = "";
    if (date === null) convert = undefined;
    else convert = moment(date).format(FORMAT_ENGLISH);
    if (type === TYPE_CHANGE_DATE.TUNGAY) setTuNgay(convert);
    else if (type === TYPE_CHANGE_DATE.DENNGAY) setDenNgay(convert);
    else setThangKT(convert);
  };

  //change unit
  const handleChangeUnit = (value) => {
    setUnit(value);
  };

  useEffect(() => {
    handleMultiBoLoc(setLoading, setDonVi);
  }, []);

  return (
    <BaoCaoTongHopTTKH
      user={user}
      donVi={donVi}
      loading={loading}
      handleChangeNgay={handleChangeNgay}
      handleChangeUnit={handleChangeUnit}
      tuNgay={tuNgay}
      denNgay={denNgay}
      thangKT={thangKT}
      unit={unit}
    />
  );
}
