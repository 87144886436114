import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";

export const getListDonVi = (setLoading, setData) => {
  authGetData({
    url: `${Endpoint.GET_UNIT_LIST}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getListTeam = (setLoading, setData, donVi) => {
  authGetData({
    url: `${Endpoint.GET_TEAM_LIST}?DonViId=${donVi}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

//GET_NGAN_HANG

export const getListNganHang = (setLoading, setData) => {
  authGetData({
    url: `${Endpoint.DANH_SACH_NGAN_HANG_THANH_TOAN}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

//DANH_SACH_DICH_VU_THANH_TOAN

export const getDanhSachDichVuThanhToan = (
  setListLoading,
  setData,
  query,
  filterConditions,
  setTotal
) => {
  authGetData({
    url: `${Endpoint.DANH_SACH_DICH_VU_THANH_TOAN}?${query}`,
    setLoading: setListLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              STT:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            };
          })
        );
        setTotal(res.paging.totalCount);
      }
    },
  });
};
