/* eslint-disable react-hooks/exhaustive-deps */
import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { closeModal, openModal } from "@state/system-config/reducer";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE, QUAN_LY_LOG } from "@utils/constants";

import {
  buildQueryString,
  convertUtcToLocalTime,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { Form } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import BoLocLog from "./bo-loc";
import QuanLyLogDetail from "../quan-ly-log-detail";
import { getListDataInternal, getListModule } from "../services";
const QuanLyLogInternal = () => {
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [listModule, setlLstModule] = useState([]);

  useEffect(() => {
    getListModule((res) => {
      const optionMap = res.map((item) => {
        return {
          value: item.moduleCode,
          label: item.shortName,
        };
      });
      setlLstModule(optionMap);
    });
  }, []);

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: parseParams(location.search).pageIndex || DEFAULT_PAGEINDEX,
    pageSize: parseParams(location.search).pageSize || DEFAULT_PAGESIZE,
    // module đầu tiên trong danh sách
    moduleCode: "a",
    ...parseParams(location.search),
  });

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const handleGetListInternal = () => {
    const query = buildQueryString(filterConditions);
    getListDataInternal(setLoading, setData, filterConditions, setTotal, query);
  };

  useEffect(() => {
    handleGetListInternal();
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [filterConditions]);

  const detailHoSo = (record) => {
    return {
      onDoubleClick: () => {
        dispatch(
          openModal({
            content: (
              <QuanLyLogDetail
                detailHoSo={record}
                closeModal={closeModal}
                activeKey={QUAN_LY_LOG.NOI_BO}
                filterConditions={filterConditions}
              />
            ),
            size: "large",
            title: <>Chi tiết yêu cầu</>,
          })
        );
      },
    };
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      align: "center",
      width: "80px",
    },
    {
      title: "Thời gian",
      render: (text, record) => convertUtcToLocalTime(record.timeStamp, "YYYY-MM-DD HH:mm:ss"),
      align: "center",
      width: "200px",
    },
    {
      title: "Path",
      dataIndex: "path",
      align: "left",
      width: "400px",
    },
    {
      title: "Method",
      dataIndex: "method",
      align: "left",
      width: "100px",
    },
    {
      title: "Request Body",
      render: (text) => {
        return <>{text}</>;
      },
      dataIndex: "requestBody",
      align: "left",
      width: "400px",
    },
    {
      title: "Response Body",
      render: (text) => {
        return <>{text}</>;
      },
      dataIndex: "responseBody",
      align: "left",
      width: "400px",
    },
    {
      title: "Status code",
      dataIndex: "statusCode",
      align: "left",
      width: "100px",
    },
    {
      title: "User name",
      dataIndex: "userName",
      align: "left",
      width: "150px",
    },
    {
      title: "OS",
      dataIndex: "os",
      align: "left",
      width: "100px",
    },
    {
      title: "appVersion",
      dataIndex: "appVersion",
      align: "left",
      width: "150px",
    },
    {
      title: "Build Number",
      dataIndex: "buildNumber",
      align: "left",
      width: "150px",
    },
  ];

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => {
      return {
        ...oldState,
        ...values,
        ...(values.fromDate && {
          fromDate: moment(values.fromDate).toISOString(),
        }),
        ...(values.toDate && {
          toDate: moment(values.toDate).toISOString(),
        }),
      };
    });
  }, []);

  useLayoutEffect(() => {
    form.setFieldsValue({
      ...filterConditions,
      toDate: filterConditions.toDate ? moment(new Date(filterConditions.toDate)) : null,
      fromDate: filterConditions.fromDate ? moment(new Date(filterConditions.fromDate)) : null,
    });
  }, [filterConditions]);

  useLayoutEffect(() => {
    form.setFieldsValue({
      fromDate: filterConditions.toDate
        ? moment(new Date(filterConditions.toDate))
        : moment(moment().startOf("day").toISOString()),
      toDate: filterConditions.fromDate ? moment(new Date(filterConditions.fromDate)) : moment(new Date().toISOString()),
    });
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
    form.resetFields();
  }, [form]);

  return (
    <Style>
      <Filter>
        <BoLocLog
          form={form}
          handleSearch={handleSearch}
          filterConditions={filterConditions}
          clearFilter={clearFilter}
          listModule={listModule}
        />
      </Filter>
      <TableComponent
        dataSource={data}
        columns={columns}
        scrollX={2000}
        expandable={false}
        header="Danh sách"
        rowKey="key"
        loading={loading}
        pagination={filterConditions}
        onChangePagination={onChangePagination}
        totalData={total}
        onRow={detailHoSo}
      />
    </Style>
  );
};

export default QuanLyLogInternal;

const Style = styled.div`
  #collapse-container {
    padding: 20px 0px;
  }
`;
