import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
import { alertMessage422 } from "../service";

export const selectBCTHE = [
  {
    label: "1. Báo cáo chi tiết số lượng thư điện tử đã gửi theo từng đơn vị ",
    value: Endpoint.BCTHE_CT_SO_LUONG_THU_DIEN_TU_HOP_LE_DA_GUI,
  },
  {
    label:
      "2. Báo cáo tổng hợp số lượng thư điện tử hợp lệ đã gửi theo từng đơn vị ",
    value: Endpoint.BCTHE_TH_SO_LUONG_THU_DIEN_TU_HOP_LE_DA_GUI,
  },
];

export const selectBcdichvucong = [
  {
    label: "1. Báo cáo chi tiết khách hàng đăng ký dịch vụ công của các đơn vị",
    value: Endpoint.BCCTDVKH_BAO_CAO_CTKH_DANG_KY_DV_CONG,
  },
  {
    label: "2. Tiếp nhận đăng ký dịch vụ điện điện tử",
    value: Endpoint.BCCTDVKH_TIEP_NHAN_DANG_KY_DV_DIEN_DT,
  },
  {
    label: "3. Tổng hợp thu CSKH của các đơn vị",
    value: Endpoint.BCCTDVKH_TH_THU_CSKH_CUA_CAC_DON_VI,
  },
];
export const selectBcdichvudientu = [
  {
    label: "1. Báo cáo yêu cầu thực hiện theo phương thức điện tử",
    value: Endpoint.BCCTDVKH_BC_YEU_CAU_THUC_HIEN_THEO_PHUONG_THUC_DIEN_TU,
  },
  {
    label:
      "2. Báo cáo giải quyết quá hạn các dịch vụ điện trong quá trình thực hiện HĐMBĐ",
    value: Endpoint.BCCTDVKH_BC_GIAI_QUYET_QUA_HAN_CAC_DICH_VU_DIEN,
  },
  {
    label: "3. Báo cáo tiếp nhận đăng ký dịch vụ điện điện tử",
    value: Endpoint.BCCTDVKH_BC_TIEP_NHAN_DANG_KY_DICH_VU_DIEN_TU,
  },
];

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LoaiYeuCau: data.LoaiYeuCau,
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
    })
  );
  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
