import { useDispatch } from "react-redux";
import { closeModal } from "@state/system-config/reducer";
import { useTranslation } from "react-i18next";
import TraHoSoModal from "../../common-ho-so/tra-ho-so";
import { Fragment, Suspense, useCallback, useMemo, useState } from "react";
import ModalInHoSoCMIS from "../../common-ho-so/in-ho-so-CMIS";
import ModalXemLaiPhuongAnCapDien from "../../common-ho-so/xem-lai-phuong-an-cap-dien";
import ModalQuyetToanKhachHang from "../../common-ho-so/quyet-toan-khach-hang";
import ModalQuyetToanCongTy from "../../common-ho-so/quyet-toan-cong-ty";
import KinhDoanhDuyetModal from "../../common-ho-so/modal-duyet-ho-so/kinh-doanh";
import { Endpoint } from "@utils/endpoint";
import {
  downLoadBienBan,
  getPDFBienBanKhaiGia,
} from "../../nhan-vien-khao-sat/services";
import PDFModal from "@modules/cap-dien/common-ho-so/pdf-viewer";

import ListMenu from "./list-menu";
import {
  inBienBan,
  inBieuDoPhuTai,
  downloadExcelBieuDoPT,
} from "../../common-ho-so/services";
import { checkTraHoSoService, handleOpenXemLaiPACDService } from "./services";
import { TYPE_BIEN_BAN } from "@utils/constants";
import generateChart from "@modules/cap-dien/common-ho-so/pdf-viewer/charts/generate-chart";
import ReactPDF from "@react-pdf/renderer";
import BieuDoPhuTai from "@modules/cap-dien/common-ho-so/pdf-viewer/bieu-do-phu-tai";
import { alertMessage } from "@utils/function";

export default function ListChucNang(props) {
  const { detail, setFetchDataTable, setIsDoubleClick } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [visibleTraHoSo, setVisibleTraHoSo] = useState(false);
  const [visibleCMIS, setVisibleCMIS] = useState(false);

  //sub
  const [visibleXemLaiPACD, setVisibleXemLaiPACD] = useState(false);
  const [visibleQuyetToanKH, setVisibleQuyetToanKH] = useState(false);
  const [visibleQuyetToanCT, setVisibleQuyetToanCT] = useState(false);
  const [visibleInBieuKG, setVisibleInBieuKG] = useState(false);
  const [visibleDuyetHoSo, setVisibleDuyetHoSo] = useState(false);
  const [dataPAXemLai, setDataPAXemLai] = useState({});
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);

  /* Handle Open Modal */
  const handleOpenTraHoSo = () => {
    checkTraHoSoService(detail, setVisibleTraHoSo, visibleTraHoSo);
    setIsDoubleClick(false);
  };
  const handleOpenInHoSoCMIS = () => {
    setVisibleCMIS(!visibleCMIS);
    setIsDoubleClick(false);
  };
  const handleOpenXemLaiPACD = () => {
    const url = `${Endpoint.KS_GET_PHUONG_AN_CAP_DIEN}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`;
    handleOpenXemLaiPACDService(
      url,
      setDataPAXemLai,
      setVisibleXemLaiPACD,
      visibleXemLaiPACD,
      detail
    );
    setIsDoubleClick(false);
  };
  const handleOpenQuyetToanKH = () => {
    setVisibleQuyetToanKH(!visibleQuyetToanKH);
    setIsDoubleClick(false);
  };
  const handleOpenQuyetToanCT = () => {
    setVisibleQuyetToanCT(!visibleQuyetToanCT);
    setIsDoubleClick(false);
  };
  const handleOpenInBieuKG = () => {
    setVisibleInBieuKG(!visibleInBieuKG);
    setIsDoubleClick(false);
  };
  const handleOpenDuyetHoSo = () => {
    setVisibleDuyetHoSo(!visibleCMIS);
    setIsDoubleClick(false);
  };

  /* Handle Close Modal */

  const handleCloseTraHoSo = () => {
    setVisibleTraHoSo(false);
    setIsDoubleClick(true);
  };
  const handleCloseInHoSoCMIS = () => {
    setVisibleCMIS(false);
    setIsDoubleClick(true);
  };
  const handleCloseXemLaiPACD = () => {
    setVisibleXemLaiPACD(false);
    setIsDoubleClick(true);
  };
  const handleCloseDuyetHoSo = () => {
    setVisibleDuyetHoSo(false);
    setIsDoubleClick(true);
  };

  /* Modal */

  //modal trả hồ sơ
  const showModalTraHoSo = useMemo(
    () =>
      visibleTraHoSo && (
        <TraHoSoModal
          visible={visibleTraHoSo}
          closeModal={handleCloseTraHoSo}
          title={"Phòng kinh doanh trả lại hồ sơ"}
          setFetchDataTable={setFetchDataTable}
          detail={detail}
          contentMessage="Hồ sơ sẽ được trả về cho Nhân viên khảo sát !"
        />
      ),
    // eslint-disable-next-line
    [visibleTraHoSo]
  );
  //modal in hồ sơ cmis
  const showModalInHoSoCMIS = useMemo(
    () =>
      visibleCMIS && (
        <ModalInHoSoCMIS
          visible={visibleCMIS}
          handleCloseModal={handleCloseInHoSoCMIS}
          setFetchDataTable={setFetchDataTable}
          detail={detail}
        />
      ),
    // eslint-disable-next-line
    [visibleCMIS]
  );
  //modal xem lại phương án cấp điện
  const showModalXemLaiPhuongAnCapDien = useMemo(
    () =>
      visibleXemLaiPACD && (
        <ModalXemLaiPhuongAnCapDien
          visible={visibleXemLaiPACD}
          handleCloseModal={handleCloseXemLaiPACD}
          setFetchDataTable={setFetchDataTable}
          detail={detail}
          dataPAXemLai={dataPAXemLai}
        />
      ),
    // eslint-disable-next-line
    [visibleXemLaiPACD]
  );

  const showModalQuyettoanKhachHang = useMemo(
    () =>
      visibleQuyetToanKH && (
        <ModalQuyetToanKhachHang
          visible={visibleQuyetToanKH}
          detail={detail}
          setVisibleQuyetToanKhachHang={setVisibleQuyetToanKH}
          visibleQuyetToanKhachHang={visibleQuyetToanKH}
        />
      ),
    // eslint-disable-next-line
    [visibleQuyetToanKH]
  );

  //modal quyết toán công ty
  const showModalQuyetToanCongTy = useMemo(
    () =>
      visibleQuyetToanCT && (
        <ModalQuyetToanCongTy
          visible={visibleQuyetToanCT}
          detail={detail}
          setVisibleQuyetToanCongTy={setVisibleQuyetToanCT}
          visibleQuyetToanCongTy={visibleQuyetToanCT}
        />
      ),
    // eslint-disable-next-line
    [visibleQuyetToanCT]
  );

  //in hồ sơ áp giá

  const [loadingBBApGia, setLoadingBBApGia] = useState(false);
  const [visibleInBBApGia, setVisibleInBBApGia] = useState(false);
  const inBienBanApGia = () => {
    setVisibleInBBApGia(!visibleInBBApGia);
    setIsDoubleClick(false);
  };
  const showModalInBienBanApGia = useMemo(
    () =>
      visibleInBBApGia && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visibleInBBApGia}
            setVisible={setVisibleInBBApGia}
            title={`${TYPE_BIEN_BAN.AP_GIA} ${detail?.maYCKNCmis} ${detail.tenKhachHang}`}
            getBienBan={(setUrl) => showModalBBApGia(setUrl)}
            loadingGetBienBan={loadingBBApGia}
            isDownloadExcel={false}
            isReload={false}
            type={TYPE_BIEN_BAN.AP_GIA}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visibleInBBApGia]
  );

  const showModalBBApGia = useCallback(
    (setUrl) => {
      inBienBan(
        detail,
        setLoadingBBApGia,
        (value) => {
          setUrl(value);
        },
        setLoadingPDF,
        t
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );

  //BIỂU ĐỒ PHỤ TẢI
  const [loadingPhuTai, setLoadingPhuTai] = useState(false);
  const [loadingDownloadPT, setLoadinDownloadPT] = useState(false);
  const [visiblePhuTai, setVisiblePhuTai] = useState(false);
  const handleOpenInPhuTai = () => {
    setVisiblePhuTai(!visiblePhuTai);
    setIsDoubleClick(false);
  };

  //show popup
  const showModalInBieuDoPhuTai = useMemo(
    () =>
      visiblePhuTai && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visiblePhuTai}
            setVisible={setVisiblePhuTai}
            title={`In biểu đồ phụ tải`}
            getBienBan={(setUrl) => callAPIBieuDoPhuTai(setUrl)}
            loadingGetBienBan={loadingPhuTai}
            isDownloadExcel={true}
            isReload={false}
            downloadFileExcel={() =>
              downloadExcelBieuDoPT(detail, setLoadinDownloadPT)
            }
            loadingDownload={loadingDownloadPT}
            // type={TYPE_BIEN_BAN.AP_GIA}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visiblePhuTai]
  );

  //call api
  const callAPIBieuDoPhuTai = useCallback(
    (setUrl) => {
      inBieuDoPhuTai(
        detail,
        setLoadingPhuTai,
        async (values) => {
          if(values === 'error-1'){
            alertMessage("error", t("modal.thong_bao"), "Tổng công suất lắp đặt phải > 40");
            setVisiblePhuTai(false)
            return;
          }
          if (values && Object.values(values).length > 0) {
            const labels = values?.duLieuBieuDoCongSuat?.xValues || [];
            const datasets = [
              {
                label: "Công suất",
                fill: false,
                data: values?.duLieuBieuDoCongSuat?.yValues?.data || [], // Add data to the chart
              },
            ];
            const title = values?.duLieuBieuDoCongSuat?.title || "";
            const tilteY = values?.duLieuBieuDoCongSuat?.yInfo?.tilteY || "";

            const chartImage = await generateChart(
              datasets,
              labels,
              title,
              tilteY
            );

            const blob = await ReactPDF.pdf(
              <BieuDoPhuTai
                chartImage={chartImage}
                tenDonVi={values.tenDonVi}
                tenKhachHang={values.tenKhachHang}
                diaChiSuDungDien={values.diaChiSuDungDien}
                ngayHienTai={values.ngayHienTai}
                congSuatSuDungTrungBinhNgay={values.congSuatSuDungTrungBinhNgay}
                congSuatSuDungLonNhatTrongNgay={
                  values.congSuatSuDungLonNhatTrongNgay
                }
                congSuatSuDungNhoNhatTrongNgay={
                  values.congSuatSuDungNhoNhatTrongNgay
                }
                luongDienSuDungTrungBinhThang={
                  values.luongDienSuDungTrungBinhThang
                }
                congSuatLonNhatGioCaoDiemNgay={
                  values.congSuatLonNhatGioCaoDiemNgay
                }
                congSuatLonNhatGioCaoDiemToi={
                  values.congSuatLonNhatGioCaoDiemToi
                }
              />
            ).toBlob();
            const url = URL.createObjectURL(blob);
            setUrl(url);
          } else {
            setUrl("error");
          }
        },
        setLoadingPDF,
        t
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );
  //END BIỂU ĐỒ PHỤ TẢI

  //modal Phong kinh doanh duyet ho so
  const showModalPKD = useMemo(
    () =>
      visibleDuyetHoSo && (
        <KinhDoanhDuyetModal
          visible={visibleDuyetHoSo}
          closeModal={handleCloseDuyetHoSo}
          setFetchDataTable={setFetchDataTable}
          detail={detail}
        />
      ),
    // eslint-disable-next-line
    [visibleDuyetHoSo]
  );

  const showModalPdfKhaiGia = useMemo(
    () =>
      visibleInBieuKG && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visibleInBieuKG}
            setVisible={setVisibleInBieuKG}
            title={`${TYPE_BIEN_BAN.KHAI_GIA} ${detail?.maYCKNCmis} ${detail.tenKhachHang}`}
            getBienBan={(setUrl) => showModalPDF(setUrl)}
            downloadFileExcel={() =>
              downLoadBienBan(detail, setLoadingDownload)
            }
            setLoadingDownload={setLoadingDownload}
            loadingDownload={loadingDownload}
            loadingGetBienBan={loadingPDF}
            isDownloadExcel={true}
            isReload={true}
            type={TYPE_BIEN_BAN.KHAI_GIA}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visibleInBieuKG]
  );

  const showModalPDF = useCallback(
    (setUrl) => {
      getPDFBienBanKhaiGia(
        detail,
        (value) => {
          setUrl(value);
        },
        setLoadingPDF,
        t
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );

  return (
    <Fragment>
      <ListMenu
        handleOpenDuyetHoSo={handleOpenDuyetHoSo}
        closeModal={closeModal}
        detail={detail}
        handleOpenTraHoSo={handleOpenTraHoSo}
        handleOpenInHoSoCMIS={handleOpenInHoSoCMIS}
        handleOpenXemLaiPACD={handleOpenXemLaiPACD}
        handleOpenQuyetToanKH={handleOpenQuyetToanKH}
        handleOpenQuyetToanCT={handleOpenQuyetToanCT}
        handleOpenInBieuKG={handleOpenInBieuKG}
        inBienBanApGia={inBienBanApGia}
        dispatch={dispatch}
        t={t}
        handleOpenInPhuTai={handleOpenInPhuTai}
      ></ListMenu>
      {showModalTraHoSo}
      {showModalInHoSoCMIS}
      {showModalXemLaiPhuongAnCapDien}
      {showModalQuyettoanKhachHang}
      {showModalQuyetToanCongTy}
      {showModalPdfKhaiGia}
      {showModalPKD}
      {showModalInBienBanApGia}
      {showModalInBieuDoPhuTai}
    </Fragment>
  );
}
