import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useEffect,
} from "react";
import Filter from "@components/Filter/Filter";
import { TableStyled, Container } from "./css-styled";
import { Form, Tag, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  MONTH_STRING,
  FORMAT_ENGLISH,
  AP_GIA_TRANG_THAI_BK
} from "@utils/constants";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilterBK } from "../../common/list-bo-loc-ky-bk";
import CheckPermission from "@components/CheckPermission";
import { ap_gia } from "@permissions/e_apgia";
import TableComponent from "@components/TableComponent";
import { handleListBK } from "./services";
import { openModal } from "@state/system-config/reducer";
import ChiTietBangKe from "../../common/chi-tiet-bang-ke";
import moment from "moment";
import { InfoCircleOutlined, FilePdfOutlined } from "@ant-design/icons";

export default function Index() {
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [listBK, setListBK] = useState([]);
  const [, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [biTraLai, setBiTraLai] = useState(false);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    ...parseParams(location.search),
    NgayBatDau: moment(moment().startOf(MONTH_STRING)).format(FORMAT_ENGLISH),
    NgayKetThuc: moment().format(FORMAT_ENGLISH),
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu
  const getListBK = useCallback(() => {
    handleListBK(
      setLoading,
      location,
      setListBK,
      setTotalCount,
      filterConditions,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getListBK();
  }, [getListBK]);

  const onChangePagination = (paging, _filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //tìm kiếm dữ liệu
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
      TraLai: biTraLai ? biTraLai : null
    }));
  }, [biTraLai]);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      TuNgay: moment(moment().startOf(MONTH_STRING)).format(FORMAT_ENGLISH),
      DenNgay: moment().format(FORMAT_ENGLISH),
    });
  }, [user.unitId]);

  const detailBangKe = (record) => {
    return dispatch(
      openModal({
        content: (
          <ChiTietBangKe
            detail={record}
          />
        ),
        size: "large",
        title: `Chi tiết bảng kê - ${record.maBangKe}`
      })
    );
  };

  const detailBK = (record) => {
    return {
      onDoubleClick: () => {
        detailBangKe(record)
      },
    };
  };

  const checkTraLai = (e) => {
    setBiTraLai(e.target.checked);
  };

  const columns = [
    {
      title: "Mã bảng kê",
      dataIndex: "maBangKe",
      key: "maBangKe",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => detailBangKe(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
      fixed: "left"
    },
    {
      title: "Loại bảng kê",
      dataIndex: "loaiBangKe",
      key: "loaiBangKe",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "NV lập bảng kê",
      dataIndex: "tenNhanVien",
      key: "tenNhanVien",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "14%",
    },
    {
      title: "Ngày tạo",
      dataIndex: "thoiGianTao",
      key: "thoiGianTao",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Chữ ký 1",
      dataIndex: "chuKi1",
      key: "chuKi1",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Ngày ký 1",
      dataIndex: "ngayKi1",
      key: "ngayKi1",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
    },
    {
      title: "Chữ ký 2",
      dataIndex: "chuKi2",
      key: "chuKi2",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
    },
    {
      title: "Ngày ký 2",
      dataIndex: "ngayKi2",
      key: "ngayKi2",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text, record) => (
        <>
          <div className="table-text-center">
            {
              record.trangThai?.toString() === AP_GIA_TRANG_THAI_BK.CHO_NVAG_DUYET ? (
                <Tag style={{ display: "block", textAlign: "center" }} color={"#26c0e9"}>
                  {"Chờ NV áp giá duyệt"}
                </Tag>
              ) : (
                record.trangThai?.toString() === AP_GIA_TRANG_THAI_BK.CHO_BPAG_DUYET ? (
                  <Tag style={{ display: "block", textAlign: "center" }} color={"#949d09"}>
                    {"Chờ bộ phập áp giá duyệt"}
                  </Tag>
                ) : (
                  <Tag style={{ display: "block", textAlign: "center" }} color={"#2a7e07"}>
                    {"Bộ phận áp giá đã duyệt"}
                  </Tag>
                )
              )
            }
          </div>
          {record.traLai ?
            <div
              className="table-text-center"
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: "#FF5247",
                cursor: "pointer",
              }}
            >
              <Tooltip title={record.lyDoTraLai}>
                <InfoCircleOutlined /> Bị trả lại
              </Tooltip>
            </div> : null
          }
        </>
      ),
      width: "8%",
    },
    {
      title: "Tác vụ",
      dataIndex: "",
      key: "tacVu",
      render: (text, record) => (
        <div className="table-text-center">
          {record.filePath ?
            <CheckPermission permissionCode={ap_gia.e52xb}>
              <Tooltip title="Xem bảng kê">
              <a
                href={record.filePath}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FilePdfOutlined
                  style={{ fontSize: "22px" }}
                />
              </a>
            </Tooltip>
            </CheckPermission>
            : null
          }
        </div>
      ),
      fixed: "right"
    },
  ];

  return (
    <Fragment>
      <Filter>
        <ListFilterBK
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          checkTraLai={checkTraLai}
        />
      </Filter>
      <Container>
        <TableStyled>
          <TableComponent
            header={"Tra cứu bảng kê"}
            dataSource={listBK}
            listFilter={<ListFilterBK form={form} />}
            columns={customColumn(columns, filterConditions)}
            totalData={totalCount}
            loading={loading}
            scrollX={2500}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            onRow={detailBK}
            rowKey="id"
          />
        </TableStyled>
      </Container>
    </Fragment>
  );
}
