import TrangChu from "@modules/lo-trinh-nhan-vien";
import QuanLyDiemDoVaTram from "@modules/lo-trinh-nhan-vien/quan-ly-lo-trinh-nhan-vien/quan-ly-diem-do-va-tram/index";
import QuanLyLoTrinh from "@modules/lo-trinh-nhan-vien/quan-ly-lo-trinh-nhan-vien/quan-ly-lo-trinh/index";
import { lo_trinh_nhan_vien } from "@permissions/g_lotrinhnhanvien";
import { MODULE_LOTRINH } from "@utils/constants";
const mainPath = "/quan-ly-lo-trinh";

const routes = [
  {
    title: "",
    path: mainPath,
    privateRoute: true,
    component: TrangChu,
    subItems: [],
    permissionCode: MODULE_LOTRINH,
  },
  {
    title: "Quản lý lộ trình nhân viên",
    path: mainPath + "/quan-ly-lo-trinh-nhan-vien",
    privateRoute: true,
    permissionCode: lo_trinh_nhan_vien.g1,
    subItems: [
      {
        subTitle: "Quản lý điểm đo và trạm",
        subPath: "/quan-ly-diem-do-va-tram",
        subComponent: QuanLyDiemDoVaTram,
        permissionCode: lo_trinh_nhan_vien.g11,
      },
      {
        subTitle: "Quản lý lộ trình",
        subPath: "/quan-ly-lo-trinh",
        subComponent: QuanLyLoTrinh,
        permissionCode: lo_trinh_nhan_vien.g12,
      },
    ],
  },
];
export default routes;
