import { authPostData, authGetData } from "@utils/request";
import { alertMessage } from "@utils/function";
import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
//

// get danh sách vật tư quyết toán công ty

export const handleListQuyetToanCongTy = (
  hoSoId,
  setLoading,
  setListQuyetToanCongTy,
  setDataQuyetToanCongTy
) => {
  authGetData({
    url: `${Endpoint.GET_LAP_BANG_QUYET_TOAN_CONGTY}?HoSoId=${hoSoId}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataQuyetToanCongTy(res.data);
        setListQuyetToanCongTy(res.data.vatTus);
      } else {
        setDataQuyetToanCongTy({});
        setListQuyetToanCongTy([]);
      }
    },
  });
};

// hoàn thành quyết toán công ty

export const handleHoanThanhQuyetToanCongTy = (
  hoSoId,
  setLoading,
  handleCloseModal,
  setFetchDataTable,
  setDisableButton
) => {
  authPostData({
    url: Endpoint.HOAN_THANH_QUYET_TOAN_CONGTY,
    method: METHOD_POST,
    setLoading,
    payload: hoSoId,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModal();
        setDisableButton(true);
      } else {
        alertMessage("error", "Thông báo", res.message);
      }
    },
  });
};

//submit form

export const handleSubmitService = (
  values,
  detailLapQuyetToanCongTy,
  setLoading,
  handleCloseModal,
  getListDanhSach,
  t,
  detailHoSo
) => {
  const data = {
    ...values,
    hoSoId: detailHoSo.id,
  };
  if (detailLapQuyetToanCongTy.quyetToanId) {
    data.id = detailLapQuyetToanCongTy.quyetToanId;
  }
  handleUpdate(data, setLoading, handleCloseModal, getListDanhSach, t);
};

//Update data

export const handleUpdate = (
  data,
  setLoading,
  getListDanhSach,
  handleCloseModal,
  t
) => {
  data.tenVatTuThietBi = null;
  data.donViTinh = null;
  data.chungLoai = null;
  authPostData({
    url: `${Endpoint.UPDATE_VAT_TU_QUYET_TOAN_CONGTY}`,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200 && res.data) {
        getListDanhSach();
        handleCloseModal();
      } else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};
