import React, { useState, useRef, useCallback, useEffect } from "react";
import { Modal, Table, Collapse, ConfigProvider } from "antd";
import LoadingComponent from "@components/Loading";
import { TableStyled, ContainerStyled } from "./css-styled";
import { CaretRightOutlined } from "@ant-design/icons";
import vn from "antd/es/locale-provider/vi_VN";
import { handleListLichSuSo, downLoadLSSo } from "./services";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import { handlePagination } from "@utils/function";
import ButtonComponent from "@components/Button";
const { Panel } = Collapse;
export default function LichSuSoModal(props) {
  const tableRef = useRef(null);
  const {
    visible,
    closeModal,
    lichGCSId,
    tenSoGCS,
    ky,
    thangNam
  } = props;
  const [loading, setLoading] = useState(false);
  const [dataLichSuSo, setDataLichSuSo] = useState([]);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const [filterLichSuSo, setFilterLichSuSo] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    LichGCSId: lichGCSId
  });

  const getListDataLichSuSo = useCallback(() => {
    handleListLichSuSo(filterLichSuSo, setLoading, setDataLichSuSo, setTotalCount);
  }, [filterLichSuSo]);

  useEffect(() => {
    getListDataLichSuSo();
  }, [getListDataLichSuSo]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListDataLichSuSo();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListDataLichSuSo, setFetchDataTable]);

  //pagination table
  const onChangePagination = (paging, _filters, sorter) => {
    handlePagination(paging, sorter, setFilterLichSuSo);
  };

  const genExtra = useCallback(
    () => (
      <div>
          <ButtonComponent
            size="default"
            type="primary"
            className="mr-5px"
            onClick={() => downLoadLSSo(lichGCSId, setLoading)}
          >
            Xuất lịch sử sổ
          </ButtonComponent>
      </div>
    ),
    [lichGCSId]
  );

  const columns = [
    {
      title: "Thao tác",
      dataIndex: "noidung",
      key: "noidung",
      render: (_text, record) => (
        <div className="table-text-left">
          <span>{record.noidung}</span>
          <div>{record.lyDoTraBangKe}</div>
        </div>
      ),
      width: "50%",
    },
    {
      title: "Ngày thực hiện",
      dataIndex: "logDate",
      key: "logDate",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "30%",
    },
    {
      title: "Người thực hiện",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "20%",
    },
  ];

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={`Lịch sử của sổ: ${tenSoGCS}`}
        visible={visible}
        onCancel={closeModal}
        footer={null}
        width="70%"
      >
        <ContainerStyled>
          <Collapse
            defaultActiveKey={["1"]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined
                style={{ fontSize: 18 }}
                rotate={isActive ? 90 : 0}
              />
            )}
          >
            <Panel
              header={`Chi tiết biến động  Kỳ ${ky} - tháng ${thangNam}`}
              collapsible={"header"}
              key="1"
              extra={genExtra()}
            >
              <ConfigProvider locale={vn}>
                <TableStyled
                  id="container"
                  ref={tableRef}
                >
                  <Table
                    header={"Chi tiết biến động"}
                    dataSource={dataLichSuSo}
                    columns={(columns)}
                    totalData={totalCount}
                    loading={loading}
                    onChange={onChangePagination}
                    pagination={
                      filterLichSuSo ? 
                      {
                        total: filterLichSuSo,
                        showTotal: (total) => `Tổng ${total} bản ghi`,
                        defaultpageSize: 3,
                        defaultCurrent: 1,
                        current: parseInt(filterLichSuSo.pageIndex),
                        pageSize: parseInt(filterLichSuSo.pageSize),
                        showSizeChanger: true,
                      } : false
                    }
                    bordered={true}
                  />
                </TableStyled>
              </ConfigProvider>
            </Panel>
          </Collapse>
        </ContainerStyled>
      </Modal>
    </LoadingComponent>
  );
}
