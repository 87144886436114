import React, { Fragment, memo, useState } from "react";
import { Button, Modal, Image } from "antd";
import LoadingComponent from "@components/Loading";
function ViewImage({
  showModal,
  setShowModal,
  src,
  closeImage
}) {
  const [loading,] = useState(false);
  return (
    <Fragment>
      <LoadingComponent loading={loading}></LoadingComponent>
      <Modal
        visible={showModal}
        width={800}
        onCancel={closeImage}
        footer={[]}
        className="modal-image"
      >
        <Button
          className="close"
          onClick={() => setShowModal(false)}
          style={{
            fontSize: "24px",
            border: "unset",
            position: "absolute",
            top: "0",
            right: "10px",
          }}
        >
          &times;
        </Button>
        <div
          className="mySlides"
          style={{
            display: showModal ? "block" : "none",
            marginTop: "20px",
            height: "450px",
          }}
        >
          <Image
            className="modal-content"
            src={src}
            alt=""
            height={"100%"}
            width={"100%"}
            style={{ padding: 0, objectFit: "contain" }}
          />
        </div>
      </Modal>
    </Fragment>
  );
}
export default memo(ViewImage);
