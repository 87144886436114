import { Modal, Row } from "antd";
import styled from "styled-components";

export const ContainerTab4 = styled(Row)`
  text-align: center;
  // margin-top: 32px;
  margin: 32px 0 0 0 !important;

  // border-bottom: 8px solid #e6f7ff;

  .title {
    text-align: left;
    margin: 0;
    background: #e6f7ff;
  }

  .title h2 {
    margin-bottom: 12px;
  }
  .container {
    padding: 12px;
    margin-bottom: 12px;
    margin-top: 12px;
  }
  .box {
    // background: #a5a5a5;
    padding: 24px;
    border: 1px solid #f5f5f5;
    box-shadow: 5px 5px 5px 5px rgb(0 0 0 / 15%);
  }

  .box h3 {
    font-size: 24px;
  }

  .box h1 {
    font-size: 30px;
    margin: 0;
  }

  .box1 {
    background: blue;
  }

  .box1 h1,
  .box1 h3,
  .box3 h1,
  .box3 h3 {
    color: #ffffff;
  }

  .box2 {
    background: yellow;
  }

  .box3 {
    background: green;
  }
`;

export const ContainerStyled = styled.div`
  padding: 10px;
`;

export const SelectContent = styled.div`
  position: fixed;
  right: 22px;
  z-index: 9;
  background: #a5a5a5;

  .container {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .label {
    text-align: center;
    width: 100%;
  }

  .select-unit {
    min-width: 350px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 12px;
  background: #e6f7ff;
  margin-left: 12px;
  margin-right: 12px;

  h2 {
    margin: 0;
  }
  button {
    margin-right: 24px;
  }
`;

export const ContainerThongKe = styled.div`
  padding: 24px 12px;

  .ant-row {
    margin: 0 !important;
  }

  // .title {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  // }

  .box {
    padding: 12px;
    border: 1px solid #f5f5f5;
    box-shadow: 0 5px 5px 5px rgb(0 0 0 / 15%);
  }
  .box h3 {
    text-align: center;
    font-weight: bold;
  }

  .box canvas {
    height: 300px !important;
  }

  .row3 {
    margin-top: 24px;
  }

  .row3 .box {
    width: 50%;
    margin: 0 auto;
  }
`;

export const ButtonChiTiet = styled.div`
  position: fixed;
  bottom: 60px;
  right: 40px;
  background: #f5f5f5;
`;

export const ContainerTiepNhan = styled(Row)`
  margin: 32px 0 0 0 !important;

  .title {
    background: #e6f7ff;
    margin: 0;
  }

  // .title h2 {
  //   margin: 0;
  // }

  .container {
    padding: 12px;
  }

  .title-chart {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .box {
    border: 1px solid #f5f5f5;
    box-shadow: 0 5px 5px 5px rgb(0 0 0 / 15%);
  }
  .box .box-container {
    padding: 12px;
  }
  .box .box-container h3 {
    text-align: center;
    font-weight: bold;
  }
`;

export const TongHopTrangThaiContainer = styled.div`
  margin-top: 32px;
  margin-left: 12px;
  margin-right: 12px;
  h2 {
    margin: 0;
    background: #e6f7ff;
  }
  .container {
    padding: 24px 12px;
  }
  .box {
    padding: 24px;
    border: 1px solid #f5f5f5;
    box-shadow: 0 5px 5px 5px rgb(0 0 0 / 15%);
  }

  .box h3 {
    margin-top: 8px;
    margin-bottom: 24px;
    text-align: center;
    font-weight: bold;
  }
`;

export const ScrollTop = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  cursor: pointer;

  svg {
    width: 40px;
    height: 40px;
  }
`;

export const ModalTable = styled(Modal)`
  .ant-table-tbody tr:last-child {
    background: rgb(255, 234, 234);
  }

  .ant-table-tbody tr:last-child td span {
    font-weight: bold;
  }
`;
