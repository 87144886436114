import { Button, Col, Row, Spin, Tabs } from "antd";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import TabThongTinDiemDo from "../tabs/tabContainer-tt-diem-do";
import {
  getLichSuPT,
  getTTDiemDo,
  getLichSuDiemDo,
  getChiSoPT,
} from "./services";
import TableThayDoiDiemDo from "./table/table-thay-doi-tt-diem-do";
import TableThayDoiPhuongThuc from "./table/table-thay-doi-tt-phuong-thuc";
import HuyModal from "../common/modal-huy";
import { getDanhSachDiemDo } from "../services";
import { useDispatch } from "react-redux";
import { closeModal } from "@state/system-config/reducer";
export default function ModalTraCuuTTDiemDO(props) {
  const {
    detail,
    type,
    setFilterConditions,
    filterConditions,
    setIndexPage,
    indexPage,
    setDataDanhSach,
  } = props;
  const { TabPane } = Tabs;
  const [loading, setLoading] = useState(false);
  const [dataTableDD, setDataTableDD] = useState([]);
  const [dataTablePT, setDataTablePT] = useState([]);
  const [dataThongTin, setDataThongTin] = useState();
  const [fetchListDD, setFetchListDD] = useState(false);
  const [fetchListPT, setFetchListPT] = useState(false);
  const [indexTab, setIndexTab] = useState(type);
  const [visibleHuyDD, setVisibleHuyDD] = useState(false);
  const [visibleHuyPT, setVisibleHuyPT] = useState(false);
  const [loadingTableDD, setLoadingTableDD] = useState(false);
  const [loadingTablePT, setLoadingTablePT] = useState(false);
  const [dataChiSoPTTable, setDataChiSoPTTable] = useState([]);
  const dispatch = useDispatch();

  // call api Thông tin điểm đo
  useEffect(() => {
    getTTDiemDo(setLoading, setDataThongTin, detail?.id);
  }, [detail]);
  // call api lịch sử điểm đo tab điểm đo

  const getListTraCuuDD = useCallback(() => {
    getLichSuDiemDo(
      setLoadingTableDD,
      setDataTableDD,
      detail.maDiemDo,
      dispatch,
      closeModal,
      setFilterConditions,
      filterConditions
    );
  }, [detail.maDiemDo, setFilterConditions, dispatch, filterConditions]);
  // call api lịch sử phương thức tab phương thức
  const getListTraCuuPT = useCallback(() => {
    getLichSuPT(
      setLoading,
      setDataTablePT,
      detail.maDiemDo,
      setDataChiSoPTTable
    );
  }, [detail]);

  useEffect(() => {
    getListTraCuuPT();
  }, [getListTraCuuPT]);

  // call api chỉ số phương thức trong tab lịch sử phương thức
  const getListChiSoPhuongThuc = useCallback(() => {
    getChiSoPT(setLoading, setDataChiSoPTTable, detail.maDiemDo);
  }, [detail]);

  // fetch chỉ số phương thức
  useEffect(() => {
    getListChiSoPhuongThuc();
  }, [getListChiSoPhuongThuc]);

  useEffect(() => {
    getListTraCuuDD();
  }, [getListTraCuuDD]);

  useEffect(() => {
    if (fetchListDD) {
      getListTraCuuDD();
      setFetchListDD(!fetchListDD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchListDD]);

  useEffect(() => {
    if (fetchListPT) {
      getListTraCuuPT();
      setFetchListPT(!fetchListPT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchListPT]);

  const handleOpenModalHuyDD = () => {
    setVisibleHuyDD(true);
  };

  const handleCloseModalHuyDD = () => {
    setVisibleHuyDD(false);
  };

  const handleOpenModalHuyPT = () => {
    setVisibleHuyPT(true);
  };

  const handleCloseModalHuyPT = () => {
    setVisibleHuyPT(false);
  };

  const changeTab = (activeKey) => {
    setIndexTab(activeKey);
  };

  const actionCancle = (
    <Button
      className="mr-24px"
      style={{}}
      type="danger"
      onClick={
        parseInt(indexTab) === 1 ? handleOpenModalHuyDD : handleOpenModalHuyPT
      }
      size="default"
      disabled={
        parseInt(indexTab) === 1 ? !dataTableDD.length : !dataTablePT.length
      }
    >
      Hủy thay đổi
    </Button>
  );

  const showModalHuyDD = useMemo(
    () =>
      visibleHuyDD && (
        <HuyModal
          visible={visibleHuyDD}
          handleCloseModal={handleCloseModalHuyDD}
          dispatch={dispatch}
          title={"Hủy điểm đo"}
          dataTable={dataTableDD}
          setLoadingDanhSach={setLoadingTableDD}
          setFetchList={setFetchListDD}
          getDanhSachDiemDo={getDanhSachDiemDo}
          setFilterConditions={setFilterConditions}
          filterConditions={filterConditions}
          type={1}
          content={"Bạn có muốn hủy thông tin thay đổi điểm đo gần nhất không?"}
          setIndexPage={setIndexPage}
          indexPage={indexPage}
          setDataDanhSach={setDataDanhSach}
        />
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleHuyDD]
  );

  const showModalHuyPT = useMemo(
    () =>
      visibleHuyPT && (
        <HuyModal
          visible={visibleHuyPT}
          handleCloseModal={handleCloseModalHuyPT}
          title={"Hủy phương thức"}
          setFetchList={setFetchListPT}
          getListChiSoPhuongThuc={getListChiSoPhuongThuc}
          type={2}
          content={
            "Bạn có muốn hủy thông tin thay đổi phương thức gần nhất không?"
          }
          dataTablePT={dataTablePT}
          dataChiSoPTTable={dataChiSoPTTable}
          setIndexPage={setIndexPage}
          indexPage={indexPage}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleHuyPT]
  );

  return (
    <Fragment>
      <Container>
        <Spin spinning={loading}>
          <Row>
            <Col span={24}>
              <TabThongTinDiemDo dataThongTin={dataThongTin} detail={detail} />
            </Col>
          </Row>
        </Spin>
        <div className="tab-pane-action" style={{ paddingTop: 6 }}>
          <Tabs
            className="tabs-container"
            tabBarExtraContent={actionCancle}
            defaultActiveKey={type === 1 ? "1" : "2"}
            onChange={changeTab}
          >
            <TabPane tab="Lịch sử thay đổi điểm đo" key="1">
              <TableThayDoiDiemDo
                detail={detail}
                rowKey={"id"}
                dataTable={dataTableDD}
                loading={loadingTableDD}
                bordered
              />
            </TabPane>
            <TabPane tab="Lịch sử thay đổi phương thức" key="2">
              <TableThayDoiPhuongThuc
                detail={detail}
                rowKey={"id"}
                dataChiSoPTTable={dataChiSoPTTable}
                setDataChiSoPTTable={setDataChiSoPTTable}
                dataTable={dataTablePT}
                loading={loadingTablePT}
                bordered
              />
            </TabPane>
          </Tabs>
        </div>
      </Container>
      {showModalHuyDD}
      {showModalHuyPT}
    </Fragment>
  );
}
const Container = styled.div`
  margin: 12px;
  padding: 0px 10px;
  .ant-table-tbody > tr > td {
    font-size: 14px !important;
  }
`;
