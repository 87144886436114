import { authSelector } from "@state/auth";
import {
  alertMessage,
  buildQueryString,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import BaoCaoTable from "./table";
import { handleListData } from "./services";
import { Typography, Table } from "antd";
import {
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import moment from "moment";
import { Endpoint } from "@utils/endpoint";
import { downloadFile } from "@utils/request";

const { Text } = Typography;

export default function Index() {
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { user } = useSelector(authSelector);
  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);
  //filter
  const [filterConditions, setFilterConditions] = useState({
    // pageIndex: DEFAULT_PAGEINDEX,
    // pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    donViId: user.unitId,
    tinhTrangTiepNhan: "1",
    startDateBaoCao: moment(moment().startOf(MONTH_STRING)).format(
      FORMAT_ENGLISH
    ),
    endDateBaoCao: moment().format(FORMAT_ENGLISH),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //change pagination
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  };

  //fetch list
  const getListData = useCallback(() => {
    handleListData(filterConditions, setLoading, setDataSource, setTotalCount);
  }, [filterConditions]);

  const [filterExcel, setFilterExcel] = useState(false);

  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type]);

  const columns = [
    {
      title: "STT",
      key: "stt",
      dataIndex: "stt",
      render: (text, record, index) => (
        <div className="table-text-right">{index + 1}</div>
      ),
      width: "5%",
      rowSpan: 2,
    },
    {
      title: "Đơn vị",
      key: "maDonVi",
      dataIndex: "maDonVi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
      rowSpan: 2,
      sorter: true,
    },
    {
      title: "Tuần/Tháng/Quý/Năm",
      children: [
        {
          title: `Khách hàng sử dụng
          "Trọn gói dịch vụ"`,
          key: "tronGoiDichVu",
          dataIndex: "tronGoiDichVu",
          render: (text) => <div className="table-text-right">{text}</div>,
          width: "13%",
          sorter: true,
        },
        {
          title: `Khách hàng sử dụng dịch vụ lắp đặt theo chiều dài dây dẫn từ sau công tơ.`,
          key: "lapDatTheoChieuDaiDayDan",
          dataIndex: "lapDatTheoChieuDaiDayDan",
          render: (text) => <div className="table-text-right">{text}</div>,
          width: "17%",
          sorter: true,
        },
        {
          title: `Khách hàng đầu tư cáp
          vật tư và thuê ngành điện lắp đặt.`,
          key: "khachHangTuTucVatTu",
          dataIndex: "khachHangTuTucVatTu",
          render: (text) => <div className="table-text-right">{text}</div>,
          width: "15%",
          sorter: true,
        },
        {
          title: `Khách hàng đầu tư cáp
          thuê ngành điện cung cấp vật tư và lắp đặt.`,
          key: "dauTuCapThueNganhDienCungCapVatTuVaLapDat",
          dataIndex: "dauTuCapThueNganhDienCungCapVatTuVaLapDat",
          render: (text) => <div className="table-text-right">{text}</div>,
          width: "15%",
          sorter: true,
        },
        {
          title: `Khách hàng tự
          túc hoàn toàn`,
          key: "dauTuCapVatTuThueNganhDienLapDat",
          dataIndex: "dauTuCapVatTuThueNganhDienLapDat",
          render: (text) => <div className="table-text-right">{text}</div>,
          width: "12%",
          sorter: true,
        },
      ],
    },
    {
      title: "Tổng số",
      key: "tongSo",
      dataIndex: "tongSo",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "10%",
      rowSpan: 2,
    },
  ];

  //search
  const handleSearch = useCallback(
    (values) => {
      if (values.startDateBaoCao <= values.endDateBaoCao) {
        setFilterConditions(() => ({
          ...values,
        }));
        handleAPI();
      } else {
        alertMessage("error", "Thông báo", "Khoảng ngày báo cáo không hợp lệ");
      }
    },
    [handleAPI]
  );

  //clear
  const clearFilter = useCallback(() => {
    setFilterConditions({
      // pageIndex: DEFAULT_PAGEINDEX,
      // pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
      tinhTrangTiepNhan: "1",
      startDateBaoCao: moment(moment().startOf(MONTH_STRING)).format(
        FORMAT_ENGLISH
      ),
      endDateBaoCao: moment().format(FORMAT_ENGLISH),
    });
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  }, [user.unitId]);

  //footer total count table
  const countTotals = (pageData) => {
    let tronGoi = 0;
    let lapDat = 0;
    let dauTuCapVatTu = 0;
    let dauTuCapVatThue = 0;
    let khTuTuc = 0;
    let tong = 0;
    let countDonVi = 0;

    pageData.forEach(
      ({
        tronGoiDichVu,
        lapDatTheoChieuDaiDayDan,
        khachHangTuTucVatTu,
        dauTuCapThueNganhDienCungCapVatTuVaLapDat,
        dauTuCapVatTuThueNganhDienLapDat,
        tongSo,
      }) => {
        countDonVi += 1;
        tronGoi += tronGoiDichVu;
        lapDat += lapDatTheoChieuDaiDayDan;
        dauTuCapVatTu += khachHangTuTucVatTu;
        dauTuCapVatThue += dauTuCapThueNganhDienCungCapVatTuVaLapDat;
        khTuTuc += dauTuCapVatTuThueNganhDienLapDat;
        tong += tongSo;
      }
    );
    return (
      <Table.Summary fixed>
        <Table.Summary.Row style={{ backgroundColor: "#FFEAEA" }}>
          <Table.Summary.Cell align="center" colSpan={1} index={0}>
            <Text className="font-bold">Tổng cộng</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={1}>
            <Text className="font-bold">{countDonVi}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="font-bold" align="right" index={2}>
            <Text>{tronGoi}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="font-bold" align="right" index={3}>
            <Text> {lapDat}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="font-bold" align="right" index={4}>
            <Text>{dauTuCapVatTu}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="font-bold" align="right" index={5}>
            <Text> {dauTuCapVatThue}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="font-bold" align="right" index={6}>
            <Text>{khTuTuc}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="font-bold" align="right" index={8}>
            <Text>{tong}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  useEffect(() => {
    if (filterExcel) {
      const endpoint = `${
        Endpoint.EXPORT_EXCEL_BAO_CAO_TONG_HOP_HTLD
      }?${buildQueryString(parseParams(filterConditions))}`;
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel]);

  useEffect(() => {
    if (type === ENUM_BUTTON_TYPE.TIM_KIEM) getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListData]);

  return (
    <BaoCaoTable
      columns={columns}
      handleSearch={handleSearch}
      clearFilter={clearFilter}
      filterConditions={filterConditions}
      loading={loading}
      onChangePagination={onChangePagination}
      dataSource={dataSource}
      totalCount={totalCount}
      countTotals={countTotals}
      setType={setType}
    />
  );
}
