import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { LIST_TYPE_CUSTOMER } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { handleDownload, selectBCCNVDT } from "./bc-chi-tiet-bc-ho-tro-cong-no-va-dong-tien/service";
import { selectNganHang } from "./bc-chi-tiet-tong-hop-thanh-toan-qua-ngan-hang/service";

export default function BaoCaoHTCNVaDongTien() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);

  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Báo cáo hỗ trợ công nợ và dòng tiền
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              initialValues={{
                reportType: Endpoint.BCCN_BIEU6_BANG_KE_THU_HO_CONG_TY_DIEN_LUC_KHAC,
                unitId: user.user.unitCode,
                LoaiKhachHang: LIST_TYPE_CUSTOMER.ALL.value,
              }}
              colon={false}
            >
              <Form.Item name="reportType" label="Loại báo cáo" labelAlign="left" required>
                <Select options={selectBCCNVDT} placeholder="Tất cả" formKey="reportType" form={form} />
              </Form.Item>
              <Form.Item name="unitId" label="Đơn vị" labelAlign="left" required>
                <Selection url={Endpoint.GET_TEAM_LIST_CODE} formKey="unitId" form={form} />
              </Form.Item>
              {/*     Tháng năm */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") === Endpoint.BCCN_BIEU11_SO_THU_DICH_VU_TIEN_DIEN_NGAN_HANG_TO_CHUC_TGTT ? (
                    <Form.Item name="reportDate" label="Tháng/Năm" labelAlign="left" required>
                      <DatePickerComponent picker="month" formKey="reportDate" form={form} format={"YYYY-MM"} />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              {/* Ngân hàng  */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") === Endpoint.BCCN_BIEU11_SO_THU_DICH_VU_TIEN_DIEN_NGAN_HANG_TO_CHUC_TGTT ? (
                    <Form.Item name="BankId" label="Ngân hàng" labelAlign="left" required>
                      <Select options={selectNganHang} formKey="BankId" placeholder="Chọn ngân hàng" />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              {/* Từ Ngày */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") !== Endpoint.BCCN_BIEU11_SO_THU_DICH_VU_TIEN_DIEN_NGAN_HANG_TO_CHUC_TGTT ? (
                    <Form.Item name="TuNgay" label="Từ ngày" labelAlign="left" required>
                      <DatePickerComponent formKey="TuNgay" form={form} picker="day" format={"YYYY-MM-DD"} />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              {/* Đến Ngày */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") !== Endpoint.BCCN_BIEU11_SO_THU_DICH_VU_TIEN_DIEN_NGAN_HANG_TO_CHUC_TGTT ? (
                    <Form.Item name="DenNgay" label="Đến ngày" labelAlign="left" required>
                      <DatePickerComponent picker="day" formKey="DenNgay" form={form} format={"YYYY-MM-DD"} />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              <Row>
                <Col span={4}></Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>

                  {/* <Button
                    type="primary"
                    loading={loading}
                    className="ml-20px"
                    onClick={() => {
                      handleOpenPdf(form.getFieldValue(), setLoading);
                    }}
                  >
                    In báo cáo
                  </Button> */}
                </Col>
              </Row>
            </FormComponent>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
