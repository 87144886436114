import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";

import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import { Button, Col, Form, Row } from "antd";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Style } from "./css-styled";
import HoSoNhanSuModal from "./modal/ho-so-nhan-su-modal";
import {
  buildQueryString,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import KiemTraThongTinBoLoc from "./list-bo-loc";
import { getDanhSachNhanVien } from "./services";

export default function Index() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const donViId = useSelector((state) => state.auth.user.unitId);
  const [isDoubleClick] = useState(true);


  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: donViId,
    ...parseParams(location.search),
  });
  const [visible, setVisible] = useState(false);
  const [dataIds, setDataIds] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      title: "Đơn vị",
      dataIndex: "donViId",
    },
    {
      title: "Phòng ban",
      dataIndex: "phongBan",
    },
    {
      title: "Tên nhân viên",
      dataIndex: "tenNhanVien",
    },
    {
      title: "Chức danh",
      dataIndex: "chucDanh",
    },
   
    {
      title: "Nghề CNKT",
      dataIndex: "ngheCNKT",
    },
    {
      title: "Hồ sơ",
      dataIndex: "hoSo",
      render: () => {
        return (
         
            <Button onClick={handleOpenModal}>
              Xem{" "}
            </Button>
         
        );
      },
    },

  ];

  const handleSearch = useCallback(
    (values) => {
      const payload = {
        ...(values.DonViId && { DonViId: values.DonViId }),
        ...(values.TenNhanVienHRMS && { TenNhanVienHRMS: values.TenNhanVienHRMS }),
        pageIndex: DEFAULT_PAGEINDEX,
        pageSize: DEFAULT_PAGESIZE,
      };
      setFilterConditions(payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterConditions]
  );

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  useEffect(() => {
    const query = buildQueryString(filterConditions);
    getDanhSachNhanVien(
      setListLoading,
      setData,
      query,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions]);

  //clear bộ lọc
  const clearFilter = useCallback(() => {
    const payload = {
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: donViId,
    };
    setFilterConditions(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModal = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const detailHoSo = (record) => {
    if (isDoubleClick)
      return {
        onDoubleClick: () => {
          setVisible(true);
        },
      };
  };


  return (
    <Style>
      <div>
        <Filter>
          <KiemTraThongTinBoLoc
            handleSearch={handleSearch}
            filterConditions={filterConditions}
            clearFilter={clearFilter}
            form={form}
          />
        </Filter>
        <div>
          <TableComponent
            dataSource={data}
            columns={columns}
            expandable={false}
            header="Danh sách nhân viên"
        
            loading={listLoading}
            rowKey="key"
            totalData={total}
            pagination={filterConditions}
            onChangePagination={onChangePagination}
            onRow={detailHoSo}
          />
          <HoSoNhanSuModal
            handleCloseModal={handleCloseModal}
            visible={visible}
            loading={loading}
            setLoading={setLoading}
            filterConditions={filterConditions}
          />
        </div>
      </div>
    </Style>
  );
};
