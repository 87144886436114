import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, authGetMultiplesRequest } from "@utils/request";

export const handleList = (
  filterConditions,
  setLoading,
  setDataSource,
  setTotalCount
) => {
  authGetData({
    url:
      Endpoint.GET_LIST_LOG_THAO_TAC +
      "?" +
      buildQueryString(parseParams(filterConditions)),
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataSource(res.data);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};

export const handleMultiBoLoc = (setLoading, setDonVi, setTaiKhoan) => {
  const endpoints = [
    `${Endpoint.GET_UNIT_LIST}`,
    `${Endpoint.USER}?pageSize=-1`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDonVi(res[0].data.data);
      setTaiKhoan(res[1].data.data);
    },
  });
};
