import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import {
  buildQueryString,
  getErrorMessage,
  parseParams,
  removeAccents,
} from "@utils/function";
import { authGetData, authPostData, downloadFile } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { alertMessage422 } from "../service";
import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import { useCallback, useEffect } from "react";
import { useState } from "react";
export const SelectionDanhSachNhanVien = (props) => {
  const {
    url,
    placeholder = "Chọn nhân viên",
    form,
    formKey,
    disabled,
    mode,
    setValue,
    handleDeselect,
    notClear,
    defaultValue,
  } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(typeof url === "string" ? [] : url);
  const getData = useCallback(() => {
    authGetData({
      url,
      setLoading,
      onSuccess: (res) => {
        if (res.length > 0) setData(res);
        else setData(res.data);
      },
    });
  }, [url]);

  useEffect(() => {
    if (url && typeof url === "string") {
      getData();
    }
  }, [getData, url]);
  const handleChange = useCallback(
    (value) => {
      if (form) {
        form.setFieldsValue({
          [formKey]: value,
        });
      }
      if (setValue) {
        setValue(value);
      }
    },
    [form, formKey, setValue]
  );

  return (
    <Select
      defaultValue={defaultValue}
      value={form && form.getFieldValue(formKey)}
      placeholder={placeholder}
      showSearch
      mode={mode}
      allowClear={notClear ? false : true}
      loading={loading}
      onChange={handleChange}
      onDeselect={handleDeselect}
      disabled={disabled}
      style={{ width: "100%" }}
      filterOption={(input, option) => {
        if (option && option.children) {
          return (
            removeAccents(option.children)
              .toLowerCase()
              .indexOf(removeAccents(input).toLowerCase()) >= 0
          );
        }
      }}
    >
      {data && data.length
        ? data.map((item, idx) => (
            <Option
              key={idx}
              value={item.MA_NVIEN ? item.MA_NVIEN.toString() : item.MA_NVIEN}
            >
              {item.TEN_NVIEN}
            </Option>
          ))
        : undefined}
    </Select>
  );
};

export const SelectionDanhSachDoi = (props) => {
  const {
    url,
    placeholder = "Chọn đội",
    form,
    formKey,
    disabled,
    mode,
    setValue,
    handleDeselect,
    notClear,
    defaultValue,
  } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(typeof url === "string" ? [] : url);
  const getData = useCallback(() => {
    authGetData({
      url,
      setLoading,
      onSuccess: (res) => {
        if (res.length > 0) setData(res);
        else setData(res.data);
      },
    });
  }, [url]);

  useEffect(() => {
    if (url && typeof url === "string") {
      getData();
    }
  }, [getData, url]);
  const handleChange = useCallback(
    (value) => {
      if (form) {
        form.setFieldsValue({
          [formKey]: value,
        });
      }
      if (setValue) {
        setValue(value);
      }
    },
    [form, formKey, setValue]
  );

  return (
    <Select
      defaultValue={defaultValue}
      value={form && form.getFieldValue(formKey)}
      placeholder={placeholder}
      showSearch
      mode={mode}
      allowClear={notClear ? false : true}
      loading={loading}
      onChange={handleChange}
      onDeselect={handleDeselect}
      disabled={disabled}
      style={{ width: "100%" }}
      filterOption={(input, option) => {
        if (option && option.children) {
          return (
            removeAccents(option.children)
              .toLowerCase()
              .indexOf(removeAccents(input).toLowerCase()) >= 0
          );
        }
      }}
    >
      {data && data.length
        ? data.map((item, idx) => (
            <Option
              key={idx}
              value={item.mA_TO ? item.mA_TO.toString() : item.mA_TO}
            >
              {item.teN_TO}
            </Option>
          ))
        : undefined}
    </Select>
  );
};

export const selectBcphhd = [
  {
    label: "Thống kê khách hàng có sản lượng từ 1,3 lần",
    value: Endpoint.BCKD_TK_KH_CO_SL_TU_1_3,
  },
];

export const EndpointBCTNZALO = Endpoint.BCKD_BC_TN_ZALO;
export const EndpointHDHoaDonTK = Endpoint.BC_TH_HD_HOA_DON_TK;
export const EndpointTHHoaDonTGD = Endpoint.BC_TH_HOA_DON_TGD;
export const EndpointDSKHCanhBaoApGiaHanhChinh =
  Endpoint.BCKD_DSKH_CANH_BAO_AP_GIA_HANH_CHINH_NHAN_SU;

export const exportData = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LoaiKhachHang: data.LoaiKhachHang,
      MDKepChi: data.mucdich,
      MaNhanVien: data.MaNhanVien,
    })
  );
  authGetData({
    url: `${data.reportType}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LoaiKhachHang: data.LoaiKhachHang,
      MDKepChi: data.mucdich,
      MaNhanVien: data.MaNhanVien,
    })
  );
  // Có chọn lũy kế
  if(data.accumulated){
    if(data.reportType === Endpoint.BCCTKD_CT_QL_KIM_CHI_NIEM_PHONG_TONG_HOP){
      data.reportType = Endpoint.BCCTKD_CT_QL_SU_DUNG_KIM_CHI_NIEM_PHONG_TONG_HOP_LUY_KE
    }
    if(data.reportType === Endpoint.BCCTKD_QLSD_KIM_CHI_THEO_MA_KIM){
      data.reportType = Endpoint.BCCTKD_QLSD_KIM_CHI_THEO_MA_KIM_LUY_KE
    }
  }
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LoaiKhachHang: data.LoaiKhachHang,
      MDKepChi: data.mucdich,
      MaNhanVien: data.MaNhanVien,
    })
  );
  console.log(queryString);
  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};

export const handleCreate = (data, setLoading, getListData, closeModal, url, form) => {
  authPostData({
    url: url,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        form.resetFields();
        closeModal()
        getListData();
      } else {
        getErrorMessage(res);
      }
    },
  });
};

export const getListKimChi = (data, setLoading, setListData, url) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
    })
  );
  authGetData({
    url: `${url}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setListData(res.data);
      } else {
        getErrorMessage(res);
      }
    },
  });
};
export const xoaSuDungKimChiNP = (payload, setLoading, getListData, url) => {
  authPostData({
    url: url,
    method: METHOD_POST,
    payload: payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListData();
      } else {
        getErrorMessage(res);
      }
    },
  });
};
