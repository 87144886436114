import { Col, Form, Row, Select, Button, DatePicker, TreeSelect, Modal, notification } from "antd";
import React, { useCallback, useState, useEffect } from "react";
import FormComponent from "@components/Form";
import { ContainerCMIS, Container, TableStyled } from "./css-styled";
import {
  FORMAT_ENGLISH,
  MONTH_STRING,
  FORMAT_DATE,
} from "@utils/constants";
import { useLocation } from "react-router-dom";
import { submitLayDLCMIS, getDanhSachBienBan } from "./services";
import ButtonComponent from "@components/Button";
import { getListDonViService, getBienBanService } from "../common/services";
import TableComponent from "@components/TableComponent";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import moment from "moment";
// import LoadingComponent from "@components/Loading";
import CheckPermission from "@components/CheckPermission";
import { chi_so_cong_to } from "@permissions/i_chisocongto";
import { Link } from "react-router-dom";
// import { SHOW_CHILD } from "rc-tree-select";
export default function Index() {
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [, setLoadingBB] = useState(true);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [ngayBatDau, setNgayBatDau] = useState(moment().startOf(MONTH_STRING).format(FORMAT_ENGLISH));
  const [ngayKetThuc, setNgayKetThuc] = useState(moment().format(FORMAT_ENGLISH));
  const [dataDonVi, setDataDonVi] = useState([]);
  const [idBienBan, setIdBienban] = useState([]);
  const [bienBan, setBienban] = useState();
  const [dataDB, setDataDB] = useState([]);
  const [dataBienBan, setDataBienban] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filterBB, setFilterBB] = useState({
    DonViId: user.unitId,
    NgayBatDau: moment().startOf(MONTH_STRING).format(FORMAT_ENGLISH),
    NgayKetThuc: moment().format(FORMAT_ENGLISH),
  });
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { SHOW_CHILD } = TreeSelect;

  useEffect(() => {
    getListDonVi();
  }, []);

  const getListDonVi = () => {
    getListDonViService(setLoading, setDataDonVi);
  };

  const getBienBan = useCallback(() => {
    getBienBanService(setLoadingBB, location, setDataBienban, filterBB);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBB]);

  const getListDLBienBan = useCallback(() => {
    if (!idBienBan || idBienBan.length === 0) {
      notification.error({
        message: `Thông báo`,
        description: "ID biên bản không được để trống",
      });
    } else {
      getDanhSachBienBan(setLoading, setDataSource, idBienBan, dataBienBan);
    }
  }, [dataBienBan, idBienBan]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListDLBienBan();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataTableIfNeed, setFetchDataTable]);

  const handleChangeRange = useCallback((values) => {
    if (values !== null && moment(values[0]).isValid() && moment(values[1]).isValid()) {
      setNgayBatDau(moment(values[0]).format(FORMAT_ENGLISH));
      setNgayKetThuc(moment(values[1]).format(FORMAT_ENGLISH));
      setFilterBB((oldState) => ({
        ...oldState,
        NgayBatDau: moment(values[0]).format(FORMAT_ENGLISH),
        NgayKetThuc: moment(values[1]).format(FORMAT_ENGLISH),
      }));
      setBienban([]);
      setIdBienban([]);
      setDataSource([]);
      setSelectedRowKeys([]);
      // setSelectedValues();
    } else {
      setNgayBatDau();
      setNgayKetThuc();
      setFilterBB((oldState) => ({
        ...oldState,
        NgayBatDau: null,
        NgayKetThuc: null,
      }));
      setBienban([]);
      setIdBienban([]);
      setDataSource([]);
      setSelectedRowKeys([]);
      // setSelectedValues();
    }
  }, []);

  const handleChangeDonVi = useCallback((value) => {
    setFilterBB((oldState) => ({
      ...oldState,
      DonViId: value,
      NgayBatDau: ngayBatDau,
      NgayKetThuc: ngayKetThuc,
    }));
    setBienban([]);
    setIdBienban([]);
    setDataSource([]);
    setSelectedRowKeys([]);
  }, [ngayBatDau, ngayKetThuc]);

  const changeIDBienBan = useCallback((values) => {
    setIdBienban(values);
    setBienban(values);
  }, []);

  useEffect(() => {
    getBienBan();
  }, [getBienBan]);

  const columns = [
    {
      title: "Ngày gửi kiểm định",
      dataIndex: "ngayGui",
      key: "ngayGui",
      render: (text) => (
        <div className="table-text-center">{text}</div>
      ),
      width: "35%",
    },
    {
      title: "ID biên bản",
      dataIndex: "idBienBan",
      key: "idBienBan",
      render: (text, record) => (
        record.trangThai !== 1 ?
          <Link to={`/kiem-tra-chi-so-cong-to-thao/cap-nhat-chi-so?DonViId=${user.unitId}&MaBienBan=${text}&NgayBatDau=${moment(record.ngayGui, FORMAT_DATE).format(FORMAT_ENGLISH)}&NgayKetThuc=${moment(record.ngayGui, FORMAT_DATE).format(FORMAT_ENGLISH)}&IsDongBoLenCmis=${record.trangThai}`} target="_blank" rel="noopener noreferrer">
            <div className="table-text-center">{text}</div>
          </Link> :
          <div className="table-text-center">{text}</div>
      ),
      width: "35%",
    },
    {
      title: "Tình trạng",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text, record) => (
        record.trangThai === 1 ?
          <div style={{ fontWeight: "bold", color: "green" }} className="table-text-center">{"Chưa lấy về Web"}</div> :
          record.trangThai === 2 ?
            <div style={{ fontWeight: "bold", color: "blue" }} className="table-text-center">{"Đã lấy về Web"}</div> :
            <div style={{ fontWeight: "bold", color: "orange" }} className="table-text-center">{"Đã đồng bộ về CMIS"}</div>
      ),
      // width: "30%",
    }
  ]

  const onFinish = useCallback(() => {
    const listBB = dataSource.filter(function (itm) {
      return selectedRowKeys.indexOf(itm.idBienBan) > -1;
    })
    const newArrayOfObj = listBB.map(({
      idBienBan: id,
      ngayGui: ngayKiemDinh,
    }) => ({
      id,
      ngayKiemDinh,
    }));
    const objectValue = {
      thongTinBienBans: newArrayOfObj,
      donViId: filterBB.DonViId,
    }
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: "Bạn muốn đồng bộ thông tin các biên bản đã chọn về Web?",
      onOk() {
        submitLayDLCMIS(setLoading, setDataDB, objectValue, setSelectedRowKeys, setFetchDataTable)
      },
      onCancel() { },
      okText: "Đồng ý",
      okButtonProps: { type: "primary" },
      cancelText: "Hủy",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowKeys]);

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: "3%",
    fixed: "left",
    getCheckboxProps: (record) => ({
      disabled: (record.trangThai === 3)
    }),
  };
  const genExtra = useCallback(
    () => (
      <div>
        <CheckPermission permissionCode={chi_so_cong_to.i2l}>
          <ButtonComponent
            size="default"
            type="primary"
            className="mr-5px"
            disabled={!selectedRowKeys.length}
            onClick={() => onFinish()}
          >
            Đồng bộ thông tin biên bản về Web
          </ButtonComponent>
        </CheckPermission>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRowKeys]
  );

  const tProps = {
    treeData: dataBienBan,
    showSearch: true,
    treeNodeFilterProp: 'title',
    value: bienBan,
    onChange: changeIDBienBan,
    treeCheckable: true,
    showCheckedStrategy: SHOW_CHILD,
    maxTagCount: 3,
    allowClear: true,
    style: {
      width: '100%',
      marginTop: '8px'
    },
  };

  return (
    <FormComponent
      form={form}
      name="filter-form"
      // onFinish={onFinish}
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
      }}
      autoComplete="off"
    >
      {/* <LoadingComponent> */}
      <ContainerCMIS>
        {/* <div style={{color: "red", marginBottom: 10}}>
            Bắt buộc phải nhập Số biên bản gửi kiểm định hoặc Ngày gửi kiểm định
          </div> */}
        <Row gutter={24}>
          <Col span={24} md={6}>
            <Form.Item name="DonViId" label="Đơn vị gửi">
              {!user.isTongCongTy ? (
                <Select defaultValue={user.unitId} disabled>
                  <Option value={user.unitId}>{user.unitName}</Option>
                </Select>
              ) : (
                <Select onChange={handleChangeDonVi} defaultValue={user.unitId.toString()}>
                  {dataDonVi && dataDonVi.length > 0
                    ? dataDonVi.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <div style={{ paddingBottom: "8px" }}>
              <span>
                Ngày gửi kiểm định
              </span>
            </div>
            <RangePicker
              format={FORMAT_DATE}
              onChange={handleChangeRange}
              defaultValue={[moment().startOf(MONTH_STRING), moment()]}
              allowClear={false}
              style={{ display: "flex" }}
            />
          </Col>
          <Col span={24} md={8}>
            <span>ID biên bản</span> <span style={{ color: "red" }}>*</span>
            {/* <Form.Item name="IDBienBan" label="ID biên bản" required> */}
            <TreeSelect {...tProps} />
            {/* </Form.Item> */}
          </Col>
        </Row>
        <Row justify="end">
          <CheckPermission permissionCode={chi_so_cong_to.i2l}>
            <Button type="primary" onClick={() => getListDLBienBan()}>
              Lấy danh sách biên bản
            </Button>
          </CheckPermission>
        </Row>
        {
          dataDB && dataDB.length > 0 ?
            <div style={{ color: "#0dcb0d", fontWeight: "bold " }}>Đã đồng bộ thành công:</div> :
            null
        }
        {dataDB && dataDB.length > 0 ? (
          dataDB.map((item) => (
            <div style={{ marginLeft: 20 }}>
              <span>
                Biên bản {item.bienBan}, tổng {item.tongSo} công tơ
              </span>
            </div>
          ))
        ) : null}
      </ContainerCMIS>

      <Container>
        <TableStyled>
          <TableComponent
            header={"Lấy công tơ tháo từ CMIS"}
            dataSource={dataSource}
            columns={columns}
            renderExtraAction={() => genExtra()}
            rowSelection={rowSelection}
            rowKey="idBienBan"
            loading={loading}
          />
        </TableStyled>
      </Container>
      {/* </LoadingComponent> */}
    </FormComponent>
  );
}
