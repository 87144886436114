import { Row, Col, Card } from "antd";
import { useState } from "react";
import PhucTra from "./chart/phuc-tra";
import KTCTTrongThangPie from "./chart/kiem-tra-cong-to";
import PTNgoaiHTTrongThangPie from "./chart/pt-ngoai-hien-truong";
import KTTBHangNgay from "./chart/kt-pt-hien-truong-hang-ngay";
import KTTBNVTrongThang from "./chart/kt-pt-tung-nv-thang-hien-tai";

import { Container } from "./css-styled";

export default function BaoCaoNhanh() {
  const [titleKTCTTrongThang, setTitleKTCTTrongThang] = useState("Biểu đồ");
  const [titlePTHTTrongThang, setTitlePTHTTrongThang] = useState("Biểu đồ");
  const [titleKTTBHangNgay, setTtitleKTTBHangNgay] = useState("Biểu đồ");

  return (
    <Container>
      <Row gutter={24} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <Card
            title={titleKTCTTrongThang}
            bordered={true}
            headStyle={{ fontSize: 16, fontWeight: "bold" }}
          >
            <KTCTTrongThangPie setTitle={setTitleKTCTTrongThang} />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            title={titlePTHTTrongThang}
            bordered={true}
            headStyle={{ fontSize: 16, fontWeight: "bold" }}
          >
            <PTNgoaiHTTrongThangPie setTitle={setTitlePTHTTrongThang} />
          </Card>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginBottom: 10 }}>
        <Col span={24}>
          <Card
            title={"Phúc tra"}
            bordered={true}
            headStyle={{ fontSize: 16, fontWeight: "bold" }}
          >
            <PhucTra />
          </Card>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginBottom: 10 }}>
        <Col span={24}>
          <Card
            title={titleKTTBHangNgay}
            bordered={true}
            headStyle={{ fontSize: 16, fontWeight: "bold" }}
          >
            <KTTBHangNgay setTitle={setTtitleKTTBHangNgay} />
          </Card>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginBottom: 10 }}>
        <Col span={24}>
          <Card
            title={
              "Kế hoạch và kết quả kiểm tra công tơ, phúc tra của từng nhân viên của tháng hiện tại"
            }
            bordered={true}
            headStyle={{ fontSize: 16, fontWeight: "bold" }}
          >
            <KTTBNVTrongThang />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
