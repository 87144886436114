import { Fragment } from "react";
import { Row, Col, Form, Checkbox, Input, DatePicker } from "antd";
import moment from "moment";
import { FORMAT_DATE } from "@utils/constants";
import { disabledDateFuture } from "@utils/function";

const { TextArea } = Input;

export default function Index(props) {
  const { form, checkTraLai, defautInfo, isTraLai } = props;
  return (
    <Fragment>
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="loaiTreoThao" label="Loại treo tháo">
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="doiId" label="Đội">
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="ngayTao" label="Ngày tạo">
            <DatePicker
              defaultValue={moment(defautInfo.ngayTao)}
              format={FORMAT_DATE}
              form={form}
              notClear={false}
              disabledDate={disabledDateFuture}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="nguoiTao" label="Người tạo">
            <Input disabled={true} />
          </Form.Item>
        </Col>

        <Col span={24} md={6} style={{ display: "flex", alignItems: "center" }}>
          <Form.Item name="isTraLai" style={{ margin: 0 }}>
            <Checkbox onChange={checkTraLai}>Trả lại phiếu</Checkbox>
          </Form.Item>
        </Col>
        {isTraLai ? (
          <Col span={24} md={18}>
            <Form.Item name="lyDoTraLai" className="requird-field">
              <TextArea rows={3} />
            </Form.Item>
          </Col>
        ) : null}
      </Row>
    </Fragment>
  );
}
