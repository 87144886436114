import DanhSachYeuCau from "../../common-ver1/danh-sach-yeu-cau";
import { Fragment, useState } from "react";
import { treo_thao } from "@permissions/b_treothao";
import { ENUM_DS_YEU_CAU, FUNC_ID_DSYC_TREOTHAO } from "@utils/constants";

export default function Index() {
  const [fetchList, setFetchList] = useState(false);

  const genExtra = () => {
    <div></div>;
  };

  return (
    <Fragment>
      <DanhSachYeuCau
        funcId={FUNC_ID_DSYC_TREOTHAO.TRACUU_YEUCAU}
        typeList={ENUM_DS_YEU_CAU.TIMKIEMTRACUU_PHIEUVT}
        genExtra={genExtra}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={false}
        permissionCodeBB={treo_thao.b91bb}
      />
    </Fragment>
  );
}
