import { Form } from "antd";
import { useState } from "react";
import ModalKyDuyet from "./modal";

import { callAPIKyBangKe, callAPIKyHSM } from "./services";

export default function Index(props) {
  const {
    urlXN,
    urlHSM,
    closeModal,
    visible,
    setFetchList,
    setSelectedRowKeys,
    title,
    content,
    loaiKy,
  } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  //xác nhận
  const handleXacNhan = () => {
    callAPIKyBangKe(
      urlXN,
      setLoading,
      setFetchList,
      closeModal,
      setSelectedRowKeys,
      loaiKy
    );
  };

  //ký hsm
  const handleKyHSM = () => {
    callAPIKyHSM(
      urlHSM,
      setLoading,
      setFetchList,
      closeModal,
      setSelectedRowKeys,
      loaiKy
    );
  };

  return (
    <ModalKyDuyet
      title={title}
      visible={visible}
      handleXacNhan={handleXacNhan}
      handleKyHSM={handleKyHSM}
      content={content}
      closeModal={closeModal}
      loading={loading}
      form={form}
    />
  );
}
