import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  alertMessage,
  buildQueryString,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { downloadFile } from "@utils/request";
import moment from "moment";

import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { handleListBaoCao, handleListColumns } from "./services";
import ChiTietChiPhiQuyetToan from "./table";

export default function Index() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { user } = useSelector(authSelector);
  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);
  //filter
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    donViId: user.unitId,
    loaiHopDong: searchParams.get("loaiHopDong")
      ? searchParams.get("loaiHopDong")
      : user.isTongCongTy
      ? 0
      : undefined,
    soPha: searchParams.get("soPha")
      ? searchParams.get("soPha")
      : user.isTongCongTy
      ? "1"
      : undefined,
    startDateBaoCao: moment(moment().startOf(MONTH_STRING)).format(
      FORMAT_ENGLISH
    ),
    endDateBaoCao: moment().format(FORMAT_ENGLISH),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //change pagination
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  };

  //fetch báo cáo
  const getListBaoCao = useCallback(() => {
    handleListBaoCao(setLoading, setData, setTotalCount, filterConditions);
  }, [filterConditions]);

  const [filterExcel, setFilterExcel] = useState(false);

  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type]);

  //search
  // const handleSearch = useCallback((values) => {
  //   setFilterConditions((oldState) => ({
  //     ...oldState,
  //     ...values,
  //     loaiHopDong: values.loaiHopDong,
  //   }));
  // }, []);
  const handleSearch = useCallback(
    (values) => {
      if (values.startDateBaoCao <= values.endDateBaoCao) {
        setFilterConditions(() => ({
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
          ...values,
          // donViId: values.donViId,
          // loaiHopDong: values.loaiHopDong,
        }));
        handleAPI();
      } else {
        alertMessage("error", "Thông báo", "Khoảng ngày báo cáo không hợp lệ");
      }
    },
    [handleAPI]
  );

  //clear search
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
      loaiHopDong: user.isTongCongTy ? 0 : undefined,
      soPha: user.isTongCongTy ? "1" : undefined,
      startDateBaoCao: moment(moment().startOf(MONTH_STRING)).format(
        FORMAT_ENGLISH
      ),
      endDateBaoCao: moment().format(FORMAT_ENGLISH),
    });
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  }, [user.isTongCongTy, user.unitId]);

  useEffect(() => {
    if (filterExcel) {
      const endpoint = `${
        Endpoint.EXPORT_EXCEL_BAO_CAO_CHI_PHI_QUYET_TOAN
      }?${buildQueryString(parseParams(filterConditions))}`;
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel]);

  useEffect(() => {
    if (type === ENUM_BUTTON_TYPE.TIM_KIEM) getListBaoCao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListBaoCao]);

  const [dataColumns, setDataColumns] = useState([]);
  //fetch columns
  const getListColumns = useCallback(() => {
    handleListColumns(setLoading, setDataColumns, dispatch);
  }, [dispatch]);

  useEffect(() => {
    getListColumns();
  }, [getListColumns]);

  // const columns = [
  //   {
  //     title: "STT",
  //     dataIndex: "stt",
  //     key: "stt",
  //     hide: false,
  //     disabled: true,
  //     group: 1,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-left">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: "2%",
  //   },
  //   {
  //     title: "Đơn vị",
  //     dataIndex: "donVi",
  //     key: "donVi",
  //     hide: false,
  //     group: 1,
  //     disabled: true,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-left">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: "5%",
  //   },
  //   {
  //     title: "Tên khách hàng",
  //     dataIndex: "tenKhachHang",
  //     key: "tenKhachHang",
  //     hide: false,
  //     disabled: true,
  //     group: 1,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-left">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: "7%",
  //   },
  //   {
  //     title: "Mã yêu cầu",
  //     dataIndex: "maYeuCau",
  //     key: "maYeuCau",
  //     hide: false,
  //     disabled: true,
  //     group: 1,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: "5%",
  //   },
  //   {
  //     title: "Điện thoại",
  //     dataIndex: "dienThoai",
  //     key: "dienThoai",
  //     hide: true,
  //     disabled: false,
  //     group: 1,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-center">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 30,
  //   },
  //   {
  //     title: "Loại hợp đồng",
  //     dataIndex: "loaiHopDong",
  //     key: "loaiHopDong",
  //     hide: true,
  //     disabled: false,
  //     group: 1,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-left">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 50,
  //   },
  //   {
  //     title: "Hình thức lắp đặt",
  //     dataIndex: "hinhThucLapDat",
  //     key: "hinhThucLapDat",
  //     hide: true,
  //     disabled: false,
  //     group: 1,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-left">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 50,
  //   },
  //   {
  //     title: "Công suất tối đa",
  //     dataIndex: "congSuatToiDa",
  //     key: "congSuatToiDa",
  //     hide: true,
  //     disabled: false,
  //     group: 1,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 50,
  //   },
  //   {
  //     title: "Số pha",
  //     dataIndex: "soPha",
  //     key: "soPha",
  //     hide: true,
  //     disabled: false,
  //     group: 1,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 30,
  //   },
  //   {
  //     title: "Tổng chi phí thực hiện cấp mới",
  //     dataIndex: "tongChiPhiCapMoi",
  //     key: "tongChiPhiCapMoi",
  //     hide: true,
  //     disabled: false,
  //     group: 1,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 70,
  //   },
  //   {
  //     title: "Tổng chi phí thực hiện cấp mới (Có VAT)",
  //     dataIndex: "tongChiPhiCapMoiVAT",
  //     key: "tongChiPhiCapMoiVAT",
  //     hide: true,
  //     disabled: false,
  //     group: 1,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 70,
  //   },
  //   {
  //     title: "Trạng thái",
  //     dataIndex: "trangThai",
  //     key: "trangThai",
  //     hide: true,
  //     disabled: false,
  //     group: 1,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-left">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 70,
  //   },
  //   {
  //     title: "Ngày hoàn tất hồ sơ (CMIS)",
  //     dataIndex: "ngayHoantatHoSo",
  //     key: "ngayHoantatHoSo",
  //     hide: true,
  //     disabled: false,
  //     group: 1,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-center">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 50,
  //   },
  //   {
  //     title: "Tổng số ngày thực hiện",
  //     dataIndex: "tongSoNgayThucHien",
  //     key: "tongSoNgayThucHien",
  //     hide: true,
  //     disabled: false,
  //     group: 1,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 50,
  //   },
  //   {
  //     title: "Địa chỉ",
  //     dataIndex: "diaChi",
  //     key: "diaChi",
  //     hide: true,
  //     disabled: false,
  //     group: 1,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-left">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 100,
  //   },
  //   {
  //     title: "Tên công tơ",
  //     dataIndex: "tenCongTo",
  //     key: "tenCongTo",
  //     hide: true,
  //     disabled: false,
  //     group: 2,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-left">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 100,
  //   },
  //   {
  //     title: "Giá",
  //     dataIndex: "giaCongTo",
  //     key: "giaCongTo",
  //     hide: true,
  //     disabled: false,
  //     group: 2,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 20,
  //   },
  //   {
  //     title: "Cáp trước công tơ",
  //     dataIndex: "capTruocCongTo",
  //     key: "capTruocCongTo",
  //     hide: true,
  //     disabled: false,
  //     group: 2,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-left">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 50,
  //   },
  //   {
  //     title: "Số lượng cáp",
  //     dataIndex: "soLuongCap",
  //     key: "soLuongCap",
  //     hide: true,
  //     disabled: false,
  //     group: 2,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 20,
  //   },
  //   {
  //     title: "Giá",
  //     dataIndex: "giaSoLuongCap",
  //     key: "giaSoLuongCap",
  //     hide: true,
  //     disabled: false,
  //     group: 2,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 30,
  //   },
  //   {
  //     title: "Vật tư phụ (liệt kê hết vào 1 ô)",
  //     dataIndex: "vatTuPhu",
  //     key: "vatTuPhu",
  //     hide: true,
  //     disabled: false,
  //     group: 2,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-left">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 30,
  //   },
  //   {
  //     title: "Giá",
  //     dataIndex: "giaVatTuPhu",
  //     key: "giaVatTuPhu",
  //     hide: true,
  //     disabled: false,
  //     group: 2,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 20,
  //   },
  //   {
  //     title: "Nhân công",
  //     dataIndex: "nhanCong",
  //     key: "nhanCong",
  //     hide: true,
  //     disabled: false,
  //     group: 2,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-left">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 20,
  //   },
  //   {
  //     title: "MTC",
  //     dataIndex: "mtc",
  //     key: "mtc",
  //     hide: true,
  //     disabled: false,
  //     group: 2,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-left">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 10,
  //   },
  //   {
  //     title: "Chi phí khác",
  //     dataIndex: "chiPhiKhac",
  //     key: "chiPhiKhac",
  //     hide: true,
  //     disabled: false,
  //     group: 2,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 20,
  //   },
  //   {
  //     title: "Tổng chi phí cáp trước",
  //     dataIndex: "tongChiPhi",
  //     key: "tongChiPhi",
  //     hide: true,
  //     disabled: false,
  //     group: 2,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 20,
  //   },
  //   {
  //     title: "Tổng chi phí cáp trước (Có VAT)",
  //     dataIndex: "tongChiPhiVAT",
  //     key: "tongChiPhiVAT",
  //     hide: true,
  //     disabled: false,
  //     group: 2,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 20,
  //   },

  //   {
  //     title: "Tên công tơ",
  //     dataIndex: "tenCongToSau",
  //     key: "tenCongToSau",
  //     hide: true,
  //     disabled: false,
  //     group: 3,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-left">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 100,
  //   },
  //   {
  //     title: "Giá",
  //     dataIndex: "giaCongToSau",
  //     key: "giaCongToSau",
  //     hide: true,
  //     disabled: false,
  //     group: 3,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 20,
  //   },
  //   {
  //     title: "Cáp trước công tơ",
  //     dataIndex: "capTruocCongToSau",
  //     key: "capTruocCongToSau",
  //     hide: true,
  //     disabled: false,
  //     group: 3,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-left">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 50,
  //   },
  //   {
  //     title: "Số lượng cáp",
  //     dataIndex: "soLuongCapSau",
  //     key: "soLuongCapSau",
  //     hide: true,
  //     disabled: false,
  //     group: 3,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 20,
  //   },
  //   {
  //     title: "Giá",
  //     dataIndex: "giaSoLuongCapSau",
  //     key: "giaSoLuongCapSau",
  //     hide: true,
  //     disabled: false,
  //     group: 3,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 30,
  //   },
  //   {
  //     title: "Vật tư phụ (liệt kê hết vào 1 ô)",
  //     dataIndex: "vatTuPhuSau",
  //     key: "vatTuPhuSau",
  //     hide: true,
  //     disabled: false,
  //     group: 3,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-left">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 30,
  //   },
  //   {
  //     title: "Giá",
  //     dataIndex: "giaVatTuPhuSau",
  //     key: "giaVatTuPhuSau",
  //     hide: true,
  //     disabled: false,
  //     group: 3,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 20,
  //   },
  //   {
  //     title: "Nhân công",
  //     dataIndex: "nhanCongSau",
  //     key: "nhanCongSau",
  //     hide: true,
  //     disabled: false,
  //     group: 3,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-left">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 20,
  //   },
  //   {
  //     title: "MTC",
  //     dataIndex: "mtcSau",
  //     key: "mtcSau",
  //     hide: true,
  //     disabled: false,
  //     group: 3,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-left">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 10,
  //   },
  //   {
  //     title: "Chi phí khác",
  //     dataIndex: "chiPhiKhacSau",
  //     key: "chiPhiKhacSau",
  //     hide: true,
  //     disabled: false,
  //     group: 3,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 20,
  //   },
  //   {
  //     title: "Tổng chi phí cáp trước",
  //     dataIndex: "tongChiPhiSau",
  //     key: "tongChiPhiSau",
  //     hide: true,
  //     disabled: false,
  //     group: 3,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 20,
  //   },
  //   {
  //     title: "Tổng chi phí cáp trước (Có VAT)",
  //     dataIndex: "tongChiPhiVATSau",
  //     key: "tongChiPhiVATSau",
  //     hide: true,
  //     disabled: false,
  //     group: 3,
  //     sorter: true,
  //     render: (text) => (
  //       <div className="table-text-right">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: 20,
  //   },
  // ];
  const renderComponent = useCallback(() => {
    if (dataColumns.length > 0)
      return (
        <ChiTietChiPhiQuyetToan
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          totalCount={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          columns={dataColumns}
          data={data}
          getListColumns={getListColumns}
          setType={setType}
        />
      );
  }, [
    clearFilter,
    data,
    dataColumns,
    filterConditions,
    getListColumns,
    handleSearch,
    loading,
    totalCount,
  ]);
  return <Fragment>{renderComponent()}</Fragment>;
}
