import { createSlice } from "@reduxjs/toolkit";
export const namespace = "quanLyChiSo";

const INITAL_STATE = {
  errorBoChiSo: false,
  san_luong: {
    value: 0,
    total: 0,
    chenh_lech: 0,
  },
  tramIdSelected: "",
  diemDoIdSelected: null,
  loadingGetDiemDo: false,
  danhSachDiemDoCoChiSoNhapSai: [],
};

const slice = createSlice({
  name: namespace,
  initialState: INITAL_STATE,
  reducers: {
    startGetDanhSachDiemDo: (state, action) => {
      return {
        ...state,
        loadingGetDiemDo: action.payload,
      };
    },
    saveBoChiSo: (state, action) => {
      return {
        ...state,
        san_luong: {
          value: action.payload.value,
          total: action.payload.total,
          chenh_lech: action.payload.chenh_lech,
        },
      };
    },
    selectTram: (state, action) => {
      return {
        ...state,
        tramIdSelected: action.payload,
      };
    },
    selectDiemDo: (state, action) => {
      return {
        ...state,
        diemDoIdSelected: action.payload,
      };
    },
    updateDanhSachDiemDoCoChiSoNhapSai: (state, action) => {
      return {
        ...state,
        danhSachDiemDoCoChiSoNhapSai: action.payload,
      };
    },
    clear: () => {
      return INITAL_STATE;
    },
  },
});

export const reducer = slice.reducer;

export const {
  saveBoChiSo,
  selectTram,
  selectDiemDo,
  clear,
  startGetDanhSachDiemDo,
  updateDanhSachDiemDoCoChiSoNhapSai,
} = slice.actions;

export const quanLyChiSoSelector = (state) => state[namespace];
