import CheckPermission from "@components/CheckPermission";
import { ENUM_DS_YEU_CAU } from "@utils/constants";
import { Button } from "antd";
import { Fragment, useCallback, useState } from "react";
import DanhSachYeuCau from "../../common-ver1/danh-sach-yeu-cau";
import XacNhanAnToanModal from "../../common-ver1/xac-nhan-an-toan";
import { treo_thao } from "@permissions/b_treothao";
import { handleListPhieuLenh } from "@modules/treo-thao/common-ver1/xac-nhan-an-toan/services";
import { alertMessage } from "@utils/function";
import ModalReturn from "./modal";

export default function Index() {
  const [fetchList, setFetchList] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleTL, setVisibleTL] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [arrRowKeys, setArrRowKeys] = useState([]);
  const [, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const findInArray = (value, arr) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== value) return false;
    }
    return true;
  };

  //open modal
  const handleOpenModal = () => {
    if (arrRowKeys.length > 0) {
      let showPopup = false;
      const dataDate = [];
      const dataCodeNVTT = [];
      const dataCodeNVNP = [];

      arrRowKeys.forEach((item) => {
        dataDate.push(item.ngayTreoThao);
        dataCodeNVTT.push(item.maSSONVTT);
        dataCodeNVNP.push(item.maSSONVNP);
      });
      const isDateDup = findInArray(dataDate[0], dataDate);
      const isCodeNVTTDup = findInArray(dataCodeNVTT[0], dataCodeNVTT);
      const isCodeNVNPDup = findInArray(dataCodeNVNP[0], dataCodeNVNP);
      if (isDateDup && isCodeNVTTDup && isCodeNVNPDup) {
        showPopup = true;
      } else {
        showPopup = false;
        alertMessage(
          "error",
          "Thông báo!",
          "Các yêu cầu đã chọn không trùng Ngày treo tháo, Nhân viên treo tháo hoặc Nhân viên niêm phong"
        );
      }

      if (showPopup) {
        setVisible(!visible);
        const params = {
          maSSONVTT: arrRowKeys[0].maSSONVTT,
          maSSONVNP: arrRowKeys[0].maSSONVNP,
          ngayTreoThao: arrRowKeys[0].ngayTreoThao,
          maDonVi: arrRowKeys[0].maDonVi,
        };
        handleListPhieuLenh(params, setLoading, setData);
      }
    }
  };

  //close modal
  const handleCloseModal = () => {
    setVisible(false);
  };

  //show UI modal nhận yêu cầu cmis
  const showModal = useCallback(() => {
    return (
      visible && (
        <XacNhanAnToanModal
          visible={visible}
          closeModal={handleCloseModal}
          setFetchList={setFetchList}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          data={data}
          nvtt={arrRowKeys[0].nhanVienTreoThao}
          nvnp={arrRowKeys[0].nhanVienNiemPhong}
        />
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowKeys, visible, data]);

  const handleOpenModalTL = useCallback(() => {
    setVisibleTL(!visibleTL);
  }, [visibleTL]);

  const handleCloseModalTL = () => {
    setVisibleTL(false);
  };

  const showModalReturn = useCallback(() => {
    return (
      visibleTL && (
        <ModalReturn
          setSelectedRowKeys={setSelectedRowKeys}
          visible={visibleTL}
          closeModal={handleCloseModalTL}
          setFetchList={setFetchList}
          selectedRowKeys={selectedRowKeys}
        />
      )
    );
  }, [selectedRowKeys, visibleTL]);

  //button nhận yêu cầu
  const genExtra = () => (
    <div>
      <CheckPermission permissionCode={treo_thao.b51tl}>
        <Button
          type="primary"
          key="tra-lai"
          disabled={!selectedRowKeys.length}
          onClick={handleOpenModalTL}
          className="mr-5px btn-bg-yellow"
        >
          Trả lại
        </Button>
      </CheckPermission>
      <CheckPermission permissionCode={treo_thao.b51xn}>
        <Button
          type="primary"
          key="dong-bo"
          disabled={!selectedRowKeys.length}
          onClick={handleOpenModal}
        >
          Xác nhận an toàn
        </Button>
      </CheckPermission>
    </div>
  );

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys, arrayRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    setArrRowKeys(arrayRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  return (
    <Fragment>
      <DanhSachYeuCau
        typeList={ENUM_DS_YEU_CAU.NVANTOAN_XACNHANANTOAN}
        genExtra={genExtra}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        rowSelection={rowSelection}
        setSelectedRowKeys={setSelectedRowKeys}
      />
      {showModal()}
      {showModalReturn()}
    </Fragment>
  );
}
