import BaoCaoPhanHeHoaDon from "../bao-cao-quy-trinh-kinh-doanh/bao-cao-phan-he-hoa-don";
import { Endpoint } from "@utils/endpoint";

const BaoCaoPhanHeHoaDonNghienCuuPhuTai = () => {
  const optionBcphhdNghienCuu = [
    {
    label: "Báo cáo khách hàng nghiên cứu phụ tải",
    value: Endpoint.BCKD_BC_KHNC_PHU_TAI,
    },
  ];
  return <BaoCaoPhanHeHoaDon optionBcphhdNghienCuu={optionBcphhdNghienCuu} />;
};

export default BaoCaoPhanHeHoaDonNghienCuuPhuTai;
