import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
  Fragment,
} from "react";
import { Button, Tag, Tooltip, Dropdown } from "antd";
import { closeModal, openModal } from "@state/system-config/reducer";
import ThongTinChiTietTabs from "../../common-ho-so/chi-tiet-ho-so";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// import { alertMessage } from "@utils/function";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  CheckCircleFilled,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import Table from "../../common-ho-so/table";
import { handleListData } from "../../common-ho-so/services";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_API,
} from "@utils/constants";
import ListChucNang from "./list-chuc-nang";
import { authSelector } from "@state/auth/reducer";
import CheckPermission from "@components/CheckPermission";
// import { submitDongBoService } from "./services";
import { cap_dien } from "@permissions/a_capdien";
import DongBoYeuThoiGian from "../../common-ho-so/dong-bo-thoi-gian";
import { useMemo } from "react";
import { Grid } from "antd";
const { useBreakpoint } = Grid;

export default function Index() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector(authSelector);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [isDoubleClick, setIsDoubleClick] = useState(true);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    donViId: user.unitId,
    ...parseParams(location.search),
  });
  const [, setSearchParams] = useSearchParams();
  const [dataHoSo, setDataHoSo] = useState([]);
  const [loading, setLoading] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visibleDongBo, setVisibleDongBo] = useState(false);
  const [rowId, setRowId] = useState(null);
  const screens = useBreakpoint();
  const mobile = screens.xs && !screens.md;

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const getListHoSo = useCallback(
    (hasMessage) => {
      handleListData(
        ENUM_API.DONGBOTHOIGIAN,
        location,
        filterConditions,
        setLoading,
        setDataHoSo,
        setTotalCount,
        hasMessage
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );

  /**
   *
   * @param {*} selected
   */
  const onSelectChange = (selected) => {
    setSelectedRowKeys(selected);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
    // preserveSelectedRowKeys: true,
  };

  const genExtra = () => (
    <CheckPermission permissionCode={cap_dien.a404_dong_bo_tg}>
      <Button
        size="small"
        type="primary"
        className="btn-bg-yellow"
        onClick={handleOpenModalDongBo}
        loading={loading}
        disabled={!selectedRowKeys.length}
      >
        Đồng bộ thời gian
      </Button>
    </CheckPermission>
  );

  //open modal
  const handleOpenModalDongBo = () => {
    setVisibleDongBo(!visibleDongBo);
    setIsDoubleClick(false);
  };

  //close modal
  const handleCloseModalDongBo = useCallback(() => {
    setVisibleDongBo(false);
    setIsDoubleClick(true);
  }, [setIsDoubleClick]);

  //render modal
  const showModalDongBo = useMemo(
    () =>
      visibleDongBo && (
        <DongBoYeuThoiGian
          detail={selectedRowKeys}
          visible={visibleDongBo}
          closeModal={handleCloseModalDongBo}
          setFetchDataTable={setFetchDataTable}
          getListHoSo={getListHoSo}
          isMultiple
          setSelectedRowKeys={setSelectedRowKeys}
        />
      ),
    [
      selectedRowKeys,
      getListHoSo,
      handleCloseModalDongBo,
      setFetchDataTable,
      visibleDongBo,
    ]
  );

  // const handleDongBoCMIS = useCallback(() => {
  //   submitDongBoService(
  //     selectedRowKeys,
  //     setLoading,
  //     setFetchDataTable,
  //     getListHoSo
  //   );
  // }, [getListHoSo, selectedRowKeys]);

  useEffect(() => {
    getListHoSo();
    // eslint-disable-next-line
  }, [getListHoSo]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      let hasMessage = true;
      if (filterConditions.hasOwnProperty("searchTerm")) {
        hasMessage = false;
      }
      getListHoSo(hasMessage);
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, filterConditions, getListHoSo, setFetchDataTable]);

  //columns
  const columns = [
    {
      title: "",
      key: "",
      dataIndex: "",
      fixed: "left",
      width: mobile ? "1.5%" : "3%",
      render: (row) => (
        <div
          style={{ textAlign: "center" }}
          onClick={() => setRowId(row?.id ?? row?.hoSoId)}
        >
          <Dropdown
            overlay={
              <div
                className="list-function"
                style={{
                  boxShadow:
                    " 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
                }}
              >
                <ListChucNang
                  setIsDoubleClick={setIsDoubleClick}
                  setFetchDataTable={setFetchDataTable}
                  loading={loading}
                  detail={row}
                  getListHoSo={getListHoSo}
                />
              </div>
            }
            trigger={["click"]}
          >
            {mobile ? (
              <MoreOutlined />
            ) : (
              <Button type="primary" icon={<MenuOutlined />}></Button>
            )}
          </Dropdown>
        </div>
      ),
    },
    {
      title: t("ap_gia_ho_so.stt"),
      dataIndex: "stt",
      key: "stt",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "2.5%",
    },
    {
      title: t("ap_gia_ho_so.ngay_dang_ky"),
      dataIndex: "ngayTiepNhan",
      key: "ngayTiepNhan",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "7%",
      sorter: true,
    },
    {
      title: t("ap_gia_ho_so.loai_yeu_cau"),
      dataIndex: "tenLoaiYeuCau",
      key: "tenLoaiYeuCau",
      render: (text) => <span>{text}</span>,
      width: "7%",
      sorter: true,
    },
    {
      title: t("ap_gia_ho_so.ma_yeu_cau"),
      dataIndex: "maYCKNCmis",
      key: "maYCKNCmis",
      render: (text) => <span>{text}</span>,
      width: "8%",
      sorter: true,
    },
    {
      title: t("ap_gia_ho_so.ten_khach_hang"),
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => <span>{text}</span>,
      width: "10%",
      sorter: true,
    },
    {
      title: t("ap_gia_ho_so.so_ngay_lam_viec"),
      dataIndex: "soNgayLamViecTrenTongSo",
      key: "soNgayLamViecTrenTongSo",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
      sorter: true,
    },
    {
      title: "Trạng thái hồ sơ",
      dataIndex: "tenTrangThai",
      key: "tenTrangThai",
      className: "text-center",
      render: (text, record) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Tag color={record.mauTrangThai}>{record.tenTrangThai}</Tag>
          {record.traLaiKhaoSat ? (
            <div
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: "#FF5247",
                cursor: "pointer",
              }}
            >
              <Tooltip title={record.noiDungTraHoSo}>
                <InfoCircleOutlined /> Hồ sơ trả lại
              </Tooltip>
            </div>
          ) : null}
        </div>
      ),
      width: "10%",
    },
    {
      title: "Trạng thái CMIS",
      dataIndex: "trangThaiCmis",
      key: "trangThaiCmis",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "7%",
      sorter: true,
    },
    {
      title: "Trạng thái đồng bộ",
      dataIndex: "trangThaiDongBo",
      key: "trangThaiDongBo",
      className: "text-center",
      render: (text) => (
        <div className="table-text-center">
          {text ? (
            <Fragment>
              <CheckCircleFilled
                style={{ fontSize: "20px", color: "#03A4FF" }}
              />{" "}
              <span>Đã đồng bộ</span>
            </Fragment>
          ) : (
            <Fragment>
              <ExclamationCircleOutlined
                style={{ fontSize: "20px", color: "#e2741b" }}
              />{" "}
              <span>Chưa đồng bộ</span>
            </Fragment>
          )}
        </div>
      ),
      width: "8%",
    },
    {
      title: "Thời gian đồng bộ",
      dataIndex: "ngayDongBo",
      key: "ngayDongBo",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "7%",
      sorter: true,
    },
    {
      title: "Người đồng bộ",
      dataIndex: "nguoiDongBo",
      key: "nguoiDongBo",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
      sorter: true,
    },
    {
      title: t("ap_gia_ho_so.dia_chi"),
      dataIndex: "diaChiCapDien",
      key: "diaChiCapDien",
      render: (text) => <span>{text}</span>,
      width: "15%",
      sorter: true,
    },
  ];

  //new columns
  const addColumns = () => {
    let newColumns = [];
    newColumns = columns;
    return newColumns;
  };

  //tìm kiếm dữ liệu

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
      donViId: values.donViId,
      maTrangThai: values.maTrangThai
        ? Array.isArray(values.maTrangThai) && values.maTrangThai.length > 0
          ? values.maTrangThai.join()
          : values.maTrangThai
        : [],
      mucDich:
        values.mucDich !== null && values.mucDich !== undefined
          ? values.mucDich.toString()
          : undefined,
    }));
  }, []);
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
    });
  }, [user.unitId]);

  // xem chi tiết hồ sơ khi click đúp

  const detailHoSo = (record) => {
    if (isDoubleClick)
      return {
        onDoubleClick: () => {
          dispatch(
            openModal({
              content: (
                <ThongTinChiTietTabs
                  detailHoSo={record}
                  closeModal={closeModal}
                />
              ),
              size: "large",
              title: `${
                record.maYCKNCmis !== null ? record.maYCKNCmis + " - " : ""
              }${record.tenKhachHang !== null ? record.tenKhachHang : ""}`,
            })
          );
        },
      };
  };

  return (
    <Fragment>
      <Table
        listFilter={3}
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        renderExtraAction={genExtra}
        t={t}
        dataHoSo={dataHoSo}
        addColumns={addColumns}
        totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        onRow={detailHoSo}
        rowKey="hoSoId"
        rowSelection={rowSelection}
        rowId={rowId}
      />
      {showModalDongBo}
    </Fragment>
  );
}
