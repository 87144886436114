import styled from "styled-components";
import { Col, Collapse } from "antd";

export const ContainerBody = styled.div`
  padding: 16px 24px;

  .ant-tabs-nav {
    position: fixed;
    background: #ffffff;
    z-index: 999;
    top: 96px;
    width: 100%;
  }

  .ant-tabs-content-holder {
    padding-top: 52px;
  }
`;

export const TextBottom = styled(Col)`
  padding-bottom: 8px;
`;

export const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
`;

export const ContainerFormDangKy = styled.div`
  padding: 0 36px;
  position: relative;
`;
export const TextMiddleFormDangKy = styled.div`
  item-align: center;
  text-align: center;
`;
export const VerticalTextFormDangKy = styled.div`
  left: 0;
  top: 230px;
  transform: rotate(-90deg);
  transform-origin: right, top;
  -ms-transform: rotate(-90deg);
  -ms-transform-origin: right, top;
  -webkit-transform: rotate(-90deg);
  -webkit-transform-origin: right, top;
  position: absolute;
`;

export const FieldSetContainer = styled.div`
  .ant-divider-horizontal.ant-divider-with-text-center::before,
  .ant-divider-horizontal.ant-divider-with-text-center::after {
    border-top: 2px solid red;
  }
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

export const ContainerTroNgai = styled.div`
  padding: 0px 36px;
`;

export const TextContent = styled.div`
  padding-top: 16px;
  font-weight: bold;
  font-size: 14px;
`;

export const ContainerBodySoDoCapDien = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  overflow: "hidden";
`;

export const TableWrapper = styled.div`
  .ant-spin-nested-loading::-webkit-scrollbar {
    background-color: unset;
    display: block;
    width: 0.1px;
  }
`;

export const PanelWrapper = styled(Collapse.Panel)`
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

export const PictureCardContainer = styled.div`
  display: inline-block;
  width: 700px;
  height: 500px;
  margin: 0 8px 8px 0;
  vertical-align: top;
`;
