import { removeAccents } from "@utils/function";
import { Table, Row, Col, Select, Button } from "antd";
import { Fragment } from "react";

const { Option } = Select;

export default function Index(props) {
  const {
    onSearch,
    dataVTLQ,
    columnsLQ,
    dataDSYCLQ,
    handleCreateLQ,
    valueSearch,
    disabled,
  } = props;
  return (
    <Fragment>
      <Row gutter={24} style={{ paddingBottom: "24px" }}>
        <Col span={24} md={8} />
        <Col
          span={24}
          md={16}
          style={{ justifyContent: "end", display: "flex" }}
        >
          <Select
            showSearch
            filterOption={(input, option) => {
              if (option && option.children) {
                return (
                  removeAccents(option.children)
                    .toLowerCase()
                    .indexOf(removeAccents(input).toLowerCase()) >= 0
                );
              }
            }}
            placeholder="Tìm theo mã điểm đo, tên khách hàng"
            onChange={onSearch}
            onKeyDown={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
            value={valueSearch}
            allowClear
            style={{ width: "55%" }}
          >
            {dataDSYCLQ && dataDSYCLQ.length > 0
              ? dataDSYCLQ.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item?.maDiemDo + " - " + item?.tenKH}
                  </Option>
                ))
              : null}
          </Select>
          <Button
            type="primary"
            style={{ marginLeft: "8px" }}
            onClick={handleCreateLQ}
            disabled={disabled}
          >
            Thêm yêu cầu
          </Button>
        </Col>
      </Row>

      <Table
        dataSource={dataVTLQ}
        columns={columnsLQ}
        pagination={false}
        scroll={{ y: 300, x: 1500 }}
        rowKey={(obj) => obj.id}
      />
    </Fragment>
  );
}
