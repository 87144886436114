import React, { Fragment } from "react";
import { Col, Form, Typography, Tooltip, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import "moment/locale/vi";

export default function TramCapDienPanels(props) {
  const {
    dataSoPha,
    dataTinhTrangSuDung,
    dataHinhThucGhiChiSo,
    dataLoaiHopDong,
    dataGioiTinhKH,
    dataPhuongAn,
    dataTinhTrangThu,
    dataMucDich,
    dataDiaChinh,
    dataHinhThucTraTien,
    dataThongTinCoBan,
    dataXaPhuongGiaoDich,
    dataXaPhuongSuDung,
  } = props;
  const innerWidth = window.innerWidth;
  const { t } = useTranslation();
  return (
    <Fragment>
      <Col span={24} md={6}>
        <Form.Item
          label={t("cap_nhat_ho_so.ten_khach_hang")}
          shouldUpdate={(prevValues, curValues) =>
            prevValues.gioiTinhKH !== curValues.gioiTinhKH
          }
        >
          <Typography.Text className="ant-form-text" strong>
            {dataThongTinCoBan.tenKH
              ? `${
                  (dataGioiTinhKH &&
                    dataGioiTinhKH.find(
                      (x) => x.value === dataThongTinCoBan?.gioiTinhKH
                    )?.name) ||
                  ""
                } - ${dataThongTinCoBan?.tenKH}`
              : ""}
          </Typography.Text>
        </Form.Item>
      </Col>

      <Col span={24} md={6}>
        <Form.Item
          label={t("cap_nhat_ho_so.nguoi_dai_dien")}
          shouldUpdate={(prevValues, curValues) =>
            prevValues.gioiTinhNDD !== curValues.gioiTinhNDD
          }
        >
          <Typography.Text className="ant-form-text" strong>
            {dataThongTinCoBan.tenNDD
              ? `${
                  (dataGioiTinhKH &&
                    dataGioiTinhKH.find(
                      (x) => x.value === dataThongTinCoBan?.gioiTinhNDD
                    )?.name) ||
                  ""
                } - ${dataThongTinCoBan?.tenNDD}`
              : ""}
          </Typography.Text>
        </Form.Item>
      </Col>

      <Col span={24} md={8}>
        <Form.Item label={"Chức vụ người đại diện"}>
          <Typography.Text className="ant-form-text" strong>
            {dataThongTinCoBan?.chucVu}
          </Typography.Text>
        </Form.Item>
      </Col>

      <Col span={24} md={6}>
        <Form.Item label={t("cap_nhat_ho_so.chung_minh_thu_ho_chieu")}>
          <Typography.Text className="ant-form-text" strong>
            {dataThongTinCoBan?.cmt}
          </Typography.Text>
        </Form.Item>
      </Col>
      <Col span={24} md={6}>
        <Form.Item label={t("cap_nhat_ho_so.ngay_cap")}>
          <Typography.Text className="ant-form-text" strong>
            {dataThongTinCoBan?.ngayCap
              ? `${dataThongTinCoBan?.ngayCap.split("T")[0].split("-")[2]}/${
                  dataThongTinCoBan?.ngayCap.split("-")[1]
                }/${dataThongTinCoBan?.ngayCap.split("-")[0]}`
              : ""}
          </Typography.Text>
        </Form.Item>
      </Col>
      <Col span={24} md={12}>
        <Form.Item label={t("cap_nhat_ho_so.noi_cap")}>
          <Typography.Text className="ant-form-text" strong>
            {dataThongTinCoBan?.noiCap}
          </Typography.Text>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Typography.Text className="ant-form-text">
          {t("cap_nhat_ho_so.dia_chi_giao_dich")}
        </Typography.Text>
      </Col>
      <Col span={24} md={6}>
        <Form.Item label={t("cap_nhat_ho_so.quan_huyen")}>
          <Typography.Text className="ant-form-text" strong>
            {(dataDiaChinh &&
              dataDiaChinh.find(
                (x) => x.id === dataThongTinCoBan?.quanHuyenGiaoDichId
              )?.tenDiaChinh) ||
              ""}
          </Typography.Text>
        </Form.Item>
      </Col>
      <Col span={24} md={6}>
        <Form.Item label={t("cap_nhat_ho_so.phuong_xa")}>
          <Typography.Text className="ant-form-text" strong>
            {(dataXaPhuongGiaoDich &&
              dataXaPhuongGiaoDich.find(
                (x) => x.id === dataThongTinCoBan?.phuongXaGiaoDichId
              )?.tenDiaChinh) ||
              ""}
          </Typography.Text>
        </Form.Item>
      </Col>

      <Col span={24} md={12}>
        <Form.Item label={t("cap_nhat_ho_so.dia_chi")}>
          <Typography.Text className="ant-form-text" strong>
            {dataThongTinCoBan?.diaChiGiaoDich}
          </Typography.Text>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Typography.Text className="ant-form-text">
          {t("cap_nhat_ho_so.dia_chi_su_dung_dien")}
        </Typography.Text>
      </Col>
      <Col span={24} md={6}>
        <Form.Item label={t("cap_nhat_ho_so.quan_huyen")}>
          <Typography.Text className="ant-form-text" strong>
            {(dataDiaChinh &&
              dataDiaChinh.find(
                (x) => x.id === dataThongTinCoBan?.quanHuyenSuDungId
              )?.tenDiaChinh) ||
              ""}
          </Typography.Text>
        </Form.Item>
      </Col>
      <Col span={24} md={6}>
        <Form.Item label={t("cap_nhat_ho_so.phuong_xa")}>
          <Typography.Text className="ant-form-text" strong>
            {(dataXaPhuongSuDung &&
              dataXaPhuongSuDung.find(
                (x) => x.id === dataThongTinCoBan?.phuongXaSuDungId
              )?.tenDiaChinh) ||
              ""}
          </Typography.Text>
        </Form.Item>
      </Col>

      <Col span={24} md={12}>
        <Form.Item label={t("cap_nhat_ho_so.dia_chi")}>
          <Typography.Text className="ant-form-text" strong>
            {dataThongTinCoBan?.diaChiSuDung}
          </Typography.Text>
        </Form.Item>
      </Col>

      <Col span={24} md={6}>
        <Form.Item label={t("cap_nhat_ho_so.so_dien_thoai_di_dong")}>
          <Typography.Text className="ant-form-text" strong>
            {dataThongTinCoBan?.sdt}
          </Typography.Text>
        </Form.Item>
      </Col>
      <Col span={24} md={6}>
        <Form.Item label={t("cap_nhat_ho_so.so_dien_thoai_khac")}>
          <Typography.Text className="ant-form-text" strong>
            {dataThongTinCoBan?.sdtKhac}
          </Typography.Text>
        </Form.Item>
      </Col>
      <Col span={24} md={12}>
        <Form.Item label={t("cap_nhat_ho_so.email")}>
          <Typography.Text className="ant-form-text" strong>
            {dataThongTinCoBan?.email}
          </Typography.Text>
        </Form.Item>
      </Col>

      {dataThongTinCoBan.isNangCongSuat ? (
        <Fragment>
          <Col span={24} md={6}>
            <Form.Item
              label={
                innerWidth < 1600 ? (
                  <Tooltip
                    title={t("cap_nhat_ho_so.cong_suat_su_dung_dien_lon_nhat")}
                  >
                    {t("cap_nhat_ho_so.cong_suat_su_dung_dien_lon_nhat")}
                  </Tooltip>
                ) : (
                  t("cap_nhat_ho_so.cong_suat_su_dung_dien_lon_nhat")
                )
              }
              className="text-ellipsis"
            >
              <Typography.Text className="ant-form-text" strong>
                {dataThongTinCoBan?.congSuatSuDung}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item label={t("cap_nhat_ho_so.so_pha_cu")}>
              <Typography.Text className="ant-form-text" strong>
                {(dataSoPha &&
                  dataSoPha.find(
                    (x) =>
                      x.value !== null && x.value === dataThongTinCoBan?.soPhaCu
                  )?.name) ||
                  ""}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item label={t("cap_nhat_ho_so.muc_dich_su_dung_cu")}>
              <Typography.Text className="ant-form-text" strong>
                {(dataMucDich &&
                  dataMucDich.find(
                    (x) =>
                      x.value !== null &&
                      x.value === parseInt(dataThongTinCoBan?.mucDichSuDungCu)
                  )?.name) ||
                  ""}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item label={t("cap_nhat_ho_so.cuong_do_cu")}>
              <Typography.Text className="ant-form-text" strong>
                {dataThongTinCoBan?.cuongDoCu}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item label={t("cap_nhat_ho_so.hieu_dien_the_cu")}>
              <Typography.Text className="ant-form-text" strong>
                {dataThongTinCoBan?.hieuDienTheCu}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item label={t("cap_nhat_ho_so.ti_cu")}>
              <Typography.Text className="ant-form-text" strong>
                {dataThongTinCoBan?.tiCu}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item label={t("cap_nhat_ho_so.ly_do_nang_cong_suat")}>
              <Typography.Text className="ant-form-text" strong>
                {dataThongTinCoBan?.lyDoNangCongSuat}
              </Typography.Text>
            </Form.Item>
          </Col>
        </Fragment>
      ) : null}
      <Col span={24} md={6}>
        <Form.Item label={t("cap_nhat_ho_so.so_pha")}>
          <Typography.Text className="ant-form-text" strong>
            {(dataSoPha &&
              dataSoPha.find(
                (x) => x.value !== null && x.value === dataThongTinCoBan?.soPha
              )?.name) ||
              ""}
          </Typography.Text>
        </Form.Item>
      </Col>
      <Col span={24} md={6}>
        <Form.Item label={t("cap_nhat_ho_so.tinh_trang_su_dung_dien")}>
          <Typography.Text className="ant-form-text" strong>
            {(dataTinhTrangSuDung &&
              dataTinhTrangSuDung.find(
                (x) =>
                  x.value !== null &&
                  x.value === dataThongTinCoBan?.tinhTrangSuDungDienId
              )?.name) ||
              ""}
          </Typography.Text>
        </Form.Item>
      </Col>

      <Col span={24} md={6}>
        <Form.Item label={t("cap_nhat_ho_so.hinh_thuc_gcs_hang_thang")}>
          <Typography.Text className="ant-form-text" strong>
            {(dataHinhThucGhiChiSo &&
              dataHinhThucGhiChiSo.find(
                (x) =>
                  x.value !== null &&
                  x.value === dataThongTinCoBan?.hinhThucGhiChiSoId
              )?.name) ||
              ""}
          </Typography.Text>
        </Form.Item>
      </Col>
      <Col span={24} md={6}>
        <Form.Item label={t("cap_nhat_ho_so.loai_hop_dong")}>
          <Typography.Text className="ant-form-text" strong>
            {(dataLoaiHopDong &&
              dataLoaiHopDong.find(
                (x) =>
                  x.value !== null &&
                  x.value === dataThongTinCoBan?.loaiHopDongId
              )?.name) ||
              ""}
          </Typography.Text>
        </Form.Item>
      </Col>
      <Col span={24} md={6}>
        <Form.Item label={t("cap_nhat_ho_so.hinh_thuc_tra_tien_hang_thang")}>
          <Typography.Text className="ant-form-text" strong>
            {(dataHinhThucTraTien &&
              dataHinhThucTraTien.find(
                (x) =>
                  x.value !== null &&
                  x.value === dataThongTinCoBan?.hinhThucTraTienId
              )?.name) ||
              ""}
          </Typography.Text>
        </Form.Item>
      </Col>
      <Col span={24} md={6}>
        <Form.Item label={t("cap_nhat_ho_so.hinh_thuc_khac")}>
          <Typography.Text className="ant-form-text" strong>
            {dataThongTinCoBan?.hinhThucTraTienKhac}
          </Typography.Text>
        </Form.Item>
      </Col>
      <Col span={24} md={6}>
        <Form.Item label={"Lãnh đạo ký"}>
          <Typography.Text className="ant-form-text" strong>
            {/* {(dataLanhDao &&
              dataLanhDao.find(
                (x) =>
                  x.value !== null && x.value === dataThongTinCoBan?.lanhDaoId
              )?.name) ||
              ""} */}
            {dataPhuongAn?.userNamLanhDaoDuyetHS
              ? dataPhuongAn?.userNamLanhDaoDuyetHS + " - "
              : ""}
            {dataPhuongAn?.tenLanhDaoDuyetHS}
          </Typography.Text>
        </Form.Item>
      </Col>

      <Col span={24} md={6}>
        <Form.Item label={t("cap_nhat_ho_so.tinh_trang_thu")}>
          <Typography.Text className="ant-form-text" strong>
            {(dataTinhTrangThu &&
              dataTinhTrangThu.find(
                (x) =>
                  x.value !== null &&
                  x.value === dataThongTinCoBan?.tinhTrangThuId
              )?.name) ||
              ""}
          </Typography.Text>
        </Form.Item>
      </Col>
      <Col span={24} md={6} style={{ display: "flex", alignItems: "center" }}>
        <Form.Item style={{ marginBottom: "0" }}>
          <Checkbox checked={dataThongTinCoBan?.isDatCoc} disabled>
            Cần nộp tiền đặt cọc
          </Checkbox>
        </Form.Item>
      </Col>
    </Fragment>
  );
}
