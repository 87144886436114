import { BASE_API_URL_CRM } from "@utils/constants";

export const API_COMMON_LIST_CRM = {
  CRM_LIST_NHAN_VIEN_PHONG_KD: `${BASE_API_URL_CRM}/common/nhan-vien-phong-kinh-doanh`,
  CRM_LIST_DANH_SACH_DOI_TRUONG: `${BASE_API_URL_CRM}/common/danh-sach-doi-truong`,
  CRM_LIST_DANH_SACH_KENH: `${BASE_API_URL_CRM}/common/danh-sach-kenh`,
  CRM_LIST_LOAI_DICH_VU: `${BASE_API_URL_CRM}/common/loai-dich-vu`,
  CRM_LIST_TRANG_THAI_YEU_CAU: `${BASE_API_URL_CRM}/common/trang-thai-yeu-cau`,
  CRM_LIST_LINH_VUC: `${BASE_API_URL_CRM}/common/linh-vuc`,
  CRM_LIST_NV_DIEU_DO: `${BASE_API_URL_CRM}/common/nhan-vien-dieu-do`,
  CRM_TAI_ANH: `${BASE_API_URL_CRM}/hosoimage`,
  CRM_LOAI_YEU_CAU: `${BASE_API_URL_CRM}/common/loai-yeu-cau`,
  CRM_LIST_NHAN_VIEN_GIAM_SAT: `${BASE_API_URL_CRM}/common/nhan-vien-giam-sat`,
  CRM_LIST_PHONG_THUC_HIEN: `${BASE_API_URL_CRM}/common/phong-thuc-hien`,
  CRM_UPDATE_HOP_DONG_KY_SO: `${BASE_API_URL_CRM}/kyso/update-hop-dong-da-ky`,
  CRM_CHI_TIET_YEU_CAU: `${BASE_API_URL_CRM}/yeucau/chi-tiet-yeu-cau`,
  CRM_LICH_SU_THAO_TAC: `${BASE_API_URL_CRM}/yeucau/lich-su-thao-tac`,
};
