import { useState, useEffect } from "react";
import { Form, Button, Modal, Row, Col } from "antd";
// import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";

export default function PhanCongModal(props) {
  const {
    visible,
    closeModal,
    onFinish,
    loadingPC,
  } = props;
  const [loading,] = useState(false);
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [form]);

  return (
    <Modal
      title="Phân Công Áp Giá"
      visible={visible}
      onCancel={closeModal}
      className="modal-phan-cong"
      footer={null}
    >
      <LoadingComponent loading={loadingPC}>
        <Form
          form={form}
          name="form-phan-cong"
          layout="horizontal"
          onFinish={onFinish}
        >
          <Row gutter={24}>
            <Col span={6}>
              <span>Đơn vị:</span>
            </Col>
            <Col span={18}>
              <Form.Item name="donViId" label="">
                <span>{user.unitName}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <span>Đội:</span>
            </Col>
            <Col span={18}>
              <Form.Item name="doiId" label=""
                rules={[
                  {
                    required: true,
                    message: `Đội không được bỏ trống.`,
                  },
                ]}
              >
                <Selection
                  url={`${Endpoint.GET_TEAM_LIST}?donviId=${user.unitId}`}
                  formKey="doiId"
                  form={form}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                Đóng lại
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-phan-cong"
                loading={loading}
              >
                Phân Công
              </Button>
            </Col>
          </Row>
        </Form>
      </LoadingComponent>
    </Modal>
  );
}
