import { Button, Col, Modal, Row } from "antd";
import { ContainerConfirm } from "../css-styled";

export default function ConfirmModal(props) {
  const { loading, visible, closeModal, handleSubmit, content, title } = props;
  return (
    <Modal title={title} visible={visible} onCancel={closeModal} footer={null}>
      <ContainerConfirm>
        <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
          {content}
        </h4>
        <Row gutter={24} justify="center" style={{ marginTop: "48px" }}>
          <Col span={12} style={{ textAlign: "right" }}>
            <Button key="back" onClick={closeModal} className="button-closed">
              Đóng lại
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              className="btn-bg-yellow"
              loading={loading}
              onClick={handleSubmit}
              key="confirm"
            >
              Đồng ý
            </Button>
          </Col>
        </Row>
      </ContainerConfirm>
    </Modal>
  );
}
