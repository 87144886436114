import { authDeleteData, authGetData, authPostData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  parseParams,
  alertMessage,
  getErrorMessage,
} from "@utils/function";
import { STATUSCODE_200, METHOD_PUT, METHOD_POST } from "@utils/constants";

export const getListLoaiYeuCauService = (
  filterConditions,
  setLoading,
  setDataLoaiYeuCau,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.LIST_LOAI_YEU_CAU}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataLoaiYeuCau(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataLoaiYeuCau([]);
    },
  });
};

export const deleteLoaiYeuCauService = (
  setLoading,
  ids,
  getListLoaiYeuCau,
  t
) => {
  authDeleteData({
    url: Endpoint.REMOVE_LOAI_YEU_CAU + "?Ids=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) getListLoaiYeuCau();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

export const createOrUpdateService = (
  data,
  setLoading,
  getListLoaiYeuCau,
  closeModal,
  form
) => {
  if (data.id) {
    //Sửa

    authPostData({
      url: Endpoint.UPDATE_LOAI_YEU_CAU,
      method: METHOD_PUT,
      payload: data,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          getListLoaiYeuCau();
          closeModal();
        } else {
          getErrorMessage(res, form);
        }
      },
    });
  } else {
    //tạo

    authPostData({
      url: Endpoint.CREATE_LOAI_YEU_CAU,
      method: METHOD_POST,
      payload: data,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          getListLoaiYeuCau();
          closeModal();
        } else getErrorMessage(res, form);
      },
    });
  }
};
