import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { Style } from "@modules/dang-ky-thanh-toan/css-styled";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  buildQueryString,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { Form } from "antd";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { dateConditionLogic } from "../../common/functions";
import ListBoLoc from "./list-bo-loc";
import { getDanhSachDichVuThanhToan } from "./services";
function DanhSachTaiKhoanNganHang() {
  const [form] = Form.useForm();
  const location = useLocation();
  const donViId = useSelector((state) => state.auth.user.unitId);
  const doiId = useSelector((state) => state.auth.user.teamId);
  // Table state
  const [listLoading, setListLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();

  const [searchParams, setSearchParams] = useSearchParams();

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DenNgay: `${moment().format("YYYY-MM-DD")}`,
    TuNgay: `${moment().subtract(7, "d").format("YYYY-MM-DD")}`,
    DonViId: donViId,
    DoiId: doiId,
    ...parseParams(location.search),
  });

  // Search by filter
  const handleSearch = useCallback(
    (values) => {
      const ojbStartDate = new Date(values.TuNgay);
      const objEndDate = new Date(values.DenNgay);
      const dateCondition = dateConditionLogic(ojbStartDate, objEndDate);

      form.setFieldsValue({
        TuNgay: moment(dateCondition.formatStartDate),
        DenNgay: moment(dateCondition.formatEndDate),
      });

      const payload = {
        TuNgay: dateCondition.formatStartDate,
        DenNgay: dateCondition.formatEndDate,
        ...(values.TuKhoaTimKiem && { SearchTerm: values.TuKhoaTimKiem }),
        ...(values.DoiId && { DoiId: values.DoiId }),
        ...(values.DonViId && { DonViId: values.DonViId }),
        ...(values.NganHang && { MaNganHang: values.NganHang }),
        pageIndex: DEFAULT_PAGEINDEX,
        pageSize: DEFAULT_PAGESIZE,
      };

      const query = buildQueryString(payload);

      setSearchParams(query);

      getDanhSachDichVuThanhToan(
        setListLoading,
        setData,
        query,
        filterConditions,
        setTotal
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterConditions, setSearchParams]
  );

  // Search by default condition
  const clearFilter = useCallback(() => {
    const payload = {
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DenNgay: `${moment().format("YYYY-MM-DD")}`,
      TuNgay: `${moment().subtract(7, "d").format("YYYY-MM-DD")}`,
      ...(donViId && { DonViId: donViId }),
      ...(doiId && { DoiId: doiId }),
    };
    setFilterConditions(payload);
    // const query = buildQueryString(payload);
    setSearchParams(payload);
    // getDanhSachDichVuThanhToan(
    //   setListLoading,
    //   setData,
    //   query,
    //   filterConditions,
    //   setTotal
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donViId, filterConditions]);

  // onChange PageSize and PageIdex
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  // fake data table and columns

  const columns = [
    {
      title: "STT",
      width: "80px",
      align: "center",
      dataIndex: "STT",
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      width: "100px",
      align: "left",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "tenKhachHang",
      width: "100px",
      align: "left",
    },
    {
      title: "Ngân hàng",
      dataIndex: "nganHang",
      width: "100px",
      align: "left",
    },
    {
      title: "Ngày đăng ký",
      render: (_, record) => (
        <>{moment(record.ngayDangKy).format("DD/MM/YYYY")}</>
      ),
      width: "100px",
      align: "center",
    },
    {
      title: "Đơn vị",
      dataIndex: "tenDonVi",
      width: "100px",
      align: "left",
    },
    {
      title: "Tổ đội",
      dataIndex: "toDoi",
      width: "100px",
      align: "left",
    },
    {
      title: "Nhân viên",
      dataIndex: "tenNhanVien",
      width: "100px",
      align: "left",
    },
  ];

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  useEffect(() => {
    const current_query =
      Object.keys(parseParams(location.search)).length > 0
        ? parseParams(location.search)
        : filterConditions;
    const updated_query = {
      ...current_query,
      pageSize: filterConditions.pageSize,
      pageIndex: filterConditions.pageIndex,
    };
    form.setFieldsValue({
      TuNgay: moment(new Date(searchParams.get("TuNgay"))),
      DenNgay: moment(new Date(searchParams.get("DenNgay"))),
    });
    const query = buildQueryString(updated_query);

    getDanhSachDichVuThanhToan(
      setListLoading,
      setData,
      query,
      filterConditions,
      setTotal
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions]);

  return (
    <Style>
      <div className="dang-ky-thanh-toan">
        <Filter>
          <ListBoLoc
            form={form}
            filterConditions={filterConditions}
            handleSearch={handleSearch}
            clearFilter={clearFilter}
          />
        </Filter>
        <div className="dang-ky-thanh-toan-content">
          <TableComponent
            loading={listLoading}
            dataSource={data}
            columns={columns}
            expandable={false}
            header="Danh sách"
            rowKey="key"
            totalData={total}
            pagination={filterConditions}
            onChangePagination={onChangePagination}
            // onRow={detailHoSo}
          />
        </div>
      </div>
    </Style>
  );
}

export default DanhSachTaiKhoanNganHang;
