import { closeModal } from "@state/system-config/reducer";
import {
  LOAI_PHIEU_TREOTHAO,
  METHOD_POST,
  METHOD_PUT,
  NAVIGATE_TREOTHAO,
  NAVIGATE_TREOTHAO_DOITRUONG,
  NAVIGATE_TREOTHAO_PHIEUCHOTRAVT,
  STATUSCODE_200,
  TYPE_PHIEUTRA_CHUCNANG,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { getErrorMessage } from "@utils/function";
import {
  authGetData,
  authDeleteData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";

export const handleListTH = (setLoading, setDataTH) => {
  authGetData({
    url: "",
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataTH(res.data);
      }
    },
  });
};

export const handleListVTTL = (setLoading, setDataVTTL) => {
  authGetData({
    url: "",
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataVTTL(res.data);
      }
    },
  });
};

export const handleListYCVTTL = (setLoading, setDataYCVTTL) => {
  authGetData({
    url: "",
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataYCVTTL(res.data);
      }
    },
  });
};

export const getListMulti = (setLoading, setDataDSYCLQ, setLoaiCongTo) => {
  const endpoints = [];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      if (res.data.length > 0 && res.data) {
        setDataDSYCLQ(res[0].data.data);
        setLoaiCongTo(res[1].data.data);
      }
    },
  });
};

export const handleRemoveVTLQ = (setLoading, id, getListDataYCVTTH) => {
  authDeleteData({
    url: "?id=" + id,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) getListDataYCVTTH();
    },
  });
};

export const handleMulti = (setLoading, setNguoiTao) => {
  const endpoints = [`${Endpoint.COMMON_LIST_NGUOI_TAO}`];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      if (res.length > 0) {
        setNguoiTao(res[0].data.data);
      }
    },
  });
};

//

export const handleShowInfomationPhieu = (
  setLoading,
  setDataTH,
  setDataVTTL,
  setDataYCVTTH,
  selectRowKeys,
  loaiPhieuVatTu
) => {
  const payload = {
    loaiPhieuVatTu: loaiPhieuVatTu,
    yeuCauIds: selectRowKeys,
  };

  authPostData({
    url: Endpoint.DETAIL_YEU_CAU_PHIEU_XUAT_VAT_TU,
    payload,
    method: METHOD_POST,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        const dataVT = [];
        const dataVTTHLQ = [];
        const dataVTTL = [];
        // const keyData = [];
        res.data.map((item, index) => {
          dataVT.push({
            stt: index + 1,
            chungLoaiId: item.chungLoaiId,
            maCongTo: item.maCongTo,
            congToId: item.congToId,
            loaiCongTo: item.loaiCongTo,
            soLuongThuHoi: item.soLuongThuHoi,
          });
          dataVTTHLQ.push({
            stt: index + 1,
            id: item.id,
            maCongTo: item.maCongTo,
            congToId: item.congToId,
            loaiTreoThao: item.loaiTreoThao,
            chungLoai: item.chungLoai,
            loaiCongTo: item.loaiCongTo,
            ngayTreoThao: item.ngayTreoThao,
            tram: item.tram,
            doi: item.doi,
            maDiemDo: item.maDiemDo,
            tenKH: item.tenKH,
            soLuongThuHoi: item.soLuongThuHoi,
          });
          return true;
        });
        setDataTH(dataVT);
        setDataVTTL(dataVTTL);
        setDataYCVTTH(dataVTTHLQ);
      }
    },
  });
};

export const getListdataDSYCLQ = (
  setLoading,
  setDataSelectDSYCLQ,
  typeLoaiPhieu,
  typeTreoThao,
  yeuCauIds,
  doiId
) => {
  const payload = {
    loaiPhieuVatTu: typeLoaiPhieu,
    loaiTreoThao: typeTreoThao,
    doiId: doiId,
    yeuCauIds: yeuCauIds,
  };
  authPostData({
    url: `${Endpoint.SEARCH_YEU_CAU_PHIEU_VAT_TU}`,
    method: METHOD_POST,
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataSelectDSYCLQ(res.data);
      }
    },
  });
};

export const handleCreateOrUpdate = (
  payload,
  setLoading,
  type,
  setFetchList,
  form,
  dispatch,
  navigate,
  filterConditions
  // closeModal
) => {
  let url = "";
  let method = METHOD_POST;
  if (type === TYPE_PHIEUTRA_CHUCNANG.TAOMOI) {
    url = Endpoint.CREATE_PHIEU_VAT_TU;
  } else if (type === TYPE_PHIEUTRA_CHUCNANG.TRALAI_DUYET) {
    url = Endpoint.TRALAI_DUYET_PHIEU_XUAT_VAT_TU;
  } else {
    url = Endpoint.UPDATE_PHIEU_VAT_TU;
    method = METHOD_PUT;
  }

  authPostData({
    url,
    payload,
    method,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        if (type === TYPE_PHIEUTRA_CHUCNANG.THAYDOI) {
          setFetchList(true);
        }

        dispatch(closeModal());
        if (type === TYPE_PHIEUTRA_CHUCNANG.TAOMOI) {
          navigate(
            NAVIGATE_TREOTHAO +
              NAVIGATE_TREOTHAO_DOITRUONG +
              NAVIGATE_TREOTHAO_PHIEUCHOTRAVT
          );
          localStorage.setItem(
            "createFilterPhieu",
            JSON.stringify(filterConditions)
          );
        }
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleYeuCau = (
  setLoading,
  selectRowKeys,
  setDataYCVTTH,
  setTenLoaiTT,
  setLyDo,
  setIsTraLai,
  setTenDoi,
  setYeuCauIds
) => {
  authGetData({
    url: `${Endpoint.LIST_YEU_CAU_PHIEU_VAT_TU}?Id=${selectRowKeys}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data && res.statusCode === STATUSCODE_200) {
        const yeuCauIds = [];
        if (res.data && res.data.yeuCaus.length > 0) {
          res.data.yeuCaus.map((item) => {
            return yeuCauIds.push(item.id);
          });
        }
        setYeuCauIds(yeuCauIds);
        setDataYCVTTH(res.data.yeuCaus);
        setTenLoaiTT(res.data.thongTinChung.tenLoaiTreoThao);
        setLyDo(res.data.thongTinChung.lyDoTraLai);
        setIsTraLai(res.data.thongTinChung.isTraLai);
        setTenDoi(res.data.thongTinChung.doi);
      }
    },
  });
};

const checkCurrentDataLCT = (loaiCongTo, selectedsLCT, chungLoaiId) => {
  const arr = [];
  const arr2 = selectedsLCT.filter((value) => value !== chungLoaiId);
  loaiCongTo.map((item) => {
    if (!arr2.includes(item.chungLoaiId)) {
      arr.push(item);
    }
    return [];
  });
  return arr;
};
export const handleVatTu = (
  setLoading,
  selectRowKeys,
  loaiCongTo,
  form,
  setDataSourceDSVTTL,
  setDataTH
) => {
  authGetData({
    url: `${Endpoint.LIST_VAT_TU_PHIEU_VAT_TU}?Id=${selectRowKeys}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data && res.statusCode === STATUSCODE_200) {
        const { vatTuTraLais, vatTuThuHois } = res.data;
        const data = [];
        if (vatTuThuHois.length > 0) {
          // eslint-disable-next-line array-callback-return
          vatTuThuHois.map((item, index) => {
            data.push({
              ...item,
              stt: index + 1,
            });
          });
        }

        const VTTL = [];
        if (vatTuTraLais.length) {
          const selectedsLCT = vatTuTraLais.map((item) => item.chungLoaiId);
          const arrChungLoaiId = [];
          // eslint-disable-next-line array-callback-return
          loaiCongTo.map((item) => {
            arrChungLoaiId.push(item.chungLoaiId);
          });
          vatTuTraLais.map((item, index) => {
            VTTL.push({
              stt: index + 1,
              chungLoai: item.chungLoai,
              chungLoaiId: item.chungLoaiId,
              [`loaiCongTo-${index + 1}`]: arrChungLoaiId.includes(
                item.chungLoaiId
              )
                ? item.chungLoaiId
                : item.loaiCongTo,
              duLieuLoaiCongTo: checkCurrentDataLCT(
                loaiCongTo,
                selectedsLCT,
                item.chungLoaiId
              ),
              moTa: item.moTa,
              soLuongTon: item.soLuongTon,
              [`soLuongTra-${index + 1}`]: item.soLuongTra,
            });

            return true;
          });
        }

        VTTL.forEach((item) => {
          return form.setFieldsValue({
            [`soLuongTra-${item.stt}`]: item[`soLuongTra-${item.stt}`],
          });
        });
        setDataSourceDSVTTL(VTTL);
        setDataTH(data);
      }
    },
  });
};

export const handleShowYeuCauByPhieu = (
  setLoading,
  setDataTH,
  // setDataVTTL,
  setDataSourceDSVTTL,
  setDataYCVTTH,
  selectRowKeys,
  setTenLoaiTT,
  // setNgayTao,
  setTenDoi,
  loaiCongTo,
  form,
  setLyDo,
  setIsTraLai,
  setYeuCauIds
  // setKeyData
) => {
  //Call api sửa phiếu. pass yêu cầu id
  const endpoints = [
    `${Endpoint.LIST_YEU_CAU_PHIEU_VAT_TU}?Id=${selectRowKeys}`,
    `${Endpoint.LIST_VAT_TU_PHIEU_VAT_TU}?Id=${selectRowKeys}`,
  ];

  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      if (res.length > 0) {
        const data = [];
        if (res[1].data.data.vatTuThuHois.length > 0) {
          // eslint-disable-next-line array-callback-return
          res[1].data.data.vatTuThuHois.map((item, index) => {
            data.push({
              ...item,
              stt: index + 1,
            });
          });
        }
        setDataTH(data);
        const yeuCauIds = [];
        if (res[0].data.data && res[0].data.data.yeuCaus.length > 0) {
          res[0].data.data.yeuCaus.map((item) => {
            return yeuCauIds.push(item.id);
          });
        }
        setYeuCauIds(yeuCauIds);
        setDataYCVTTH(res[0].data.data.yeuCaus);
        setTenLoaiTT(res[0].data.data.thongTinChung.tenLoaiTreoThao);
        setLyDo(res[0].data.data.thongTinChung.lyDoTraLai);
        setIsTraLai(res[0].data.data.thongTinChung.isTraLai);
        setTenDoi(res[0].data.data.thongTinChung.doi);
        const VTTL = [];
        res[1].data.data.vatTuTraLais.map((item, index) => {
          VTTL.push({
            stt: index + 1,
            chungLoai: item.chungLoai,
            chungLoaiId: item.chungLoaiId,
            loaiCongTo: item.chungLoaiId,
            duLieuLoaiCongTo: loaiCongTo.length > 0 ? loaiCongTo : [item],
            moTa: item.moTa,
            soLuongTon: item.soLuongTon,
            [`soLuongTra-${index + 1}`]: item.soLuongTra,
          });

          return true;
        });
        VTTL.forEach((item) => {
          return form.setFieldsValue({
            [`soLuongTra-${item.stt}`]: item[`soLuongTra-${item.stt}`],
          });
        });
        setDataSourceDSVTTL(VTTL);
      }
    },
  });
};

export const handleChiTietPhieuDuyet = (
  selectRowKeys,
  setLoading,
  setDataTH,
  setDataVTTL,
  setDefaultInfo,
  typeLoaiPhieu
) => {
  const url =
    Endpoint.CHI_TIET_PHIEU_VAT_TU_DUYET_TRALAI +
    "?phieuVatTuId=" +
    selectRowKeys +
    "&loaiPhieu=" +
    typeLoaiPhieu;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data && res.statusCode === STATUSCODE_200) {
        setDataTH(res.data.vatTuThuHois);
        setDataVTTL(res.data.vatTuTraLais);
        res.data.ngayTao = res.data.ngayTao.split("/").reverse().join("-");
        setDefaultInfo(res.data);
      }
    },
  });
};

export const getListDataLoaiCT = (
  setLoading,
  setLoaiCongTo,
  doiId,
  setDataSourceDSVTTL
) => {
  authGetData({
    url: Endpoint.LIST_LOAI_CONG_TO_TREO_THAO + "?doiId=" + doiId,
    setLoading,
    onSuccess: (res) => {
      if (res.data && res.statusCode === STATUSCODE_200) {
        setLoaiCongTo(res.data);
        const arr = [];

        setDataSourceDSVTTL(arr);
      }
    },
  });
};

export const getDataMulti = (
  setLoading,
  setDataLoaiTT,
  setFirstLoaiTT,
  setDataDoi,
  setFirstDoi,
  type,
  funcId,
  donViId
) => {
  const endpoints = [
    `${Endpoint.COMMON_LIST_LOAI_TREO_THAO}?Type=${type}&FuncId=${funcId}`,
    `${Endpoint.GET_TEAM_LIST}?donviId=${donViId}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      if (res.length > 0) {
        setDataLoaiTT(res[0].data.data);
        setFirstLoaiTT(res[0].data.data[0].value);
        setDataDoi(res[1].data.data);
        setFirstDoi(res[1].data.data[0].value);
      }
    },
  });
};

export const callAPIXacNhanPhieu = (
  isTraLai,
  typeLoaiPhieu,
  payload,
  setLoading,
  setFetchList,
  form,
  dispatch,
  closeModal
) => {
  let url = Endpoint.PVT_XAC_NHAN_XUAT_TRA_VT;
  if (typeLoaiPhieu === LOAI_PHIEU_TREOTHAO.PHIEUTRA) {
    url = Endpoint.PVT_XAC_NHAN_PHIEU_TRA_VT;
  }
  if (isTraLai) {
    url = Endpoint.PVT_TU_CHOI_VAT_TU;
  }

  authPostData({
    url,
    payload,
    method: METHOD_PUT,
    setLoading,
    onSuccess: (res) => {
      if (res.data && res.statusCode === STATUSCODE_200) {
        setFetchList(true);
        dispatch(closeModal());
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};
