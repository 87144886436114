import React, { Fragment, useEffect, useRef, useState } from "react";
import LoadingComponent from "@components/Loading";
import { Button, Col, Collapse, Row } from "antd";
import FormComponent from "@components/Form";
import { ENUM_KYSO } from "@utils/constants";
import KySoComponent from "@components/KySo";
import ThongTinChung from "./panels/thong-tin-chung";
import DSVatTuThuHoiTable from "./panels/danh-sach-vt-thu-hoi";
import DSVatTuTraLaiTable from "./panels/danh-sach-vt-tra-lai";
import { useDispatch } from "react-redux";
import { closeModal } from "@state/system-config/reducer";
import { Endpoint } from "@utils/endpoint";
import { scrollToTop } from "@utils/function";
import ButtonKySoHSM from "../../ky-so-hsm";

const { Panel } = Collapse;

export default function LayoutPhieuTra(props) {
  const {
    loading,
    form,
    onFinishSubmit,
    type,
    checkTraLai,
    dataTH,
    columnsTH,
    dataVTTL,
    selectRowKeys,
    defaultInfo,
    handleAdd,
    components,
    setFetchList,
    nguoiTao,
    dataDetail,
    isTraLai,
    tenLoaiTT,
    ngayTao,
    tenDoi,
    columnsTraLai,
    maTrangThai,
  } = props;
  const [, setLoading] = useState(false);
  const dispatch = useDispatch();
  const ref1 = useRef();

  useEffect(() => {
    scrollToTop(ref1);
  }, []);

  return (
    <LoadingComponent loading={loading}>
      <div ref={ref1}>
        <FormComponent
          form={form}
          name="form-submit"
          layout="vertical"
          onFinish={onFinishSubmit}
          autoComplete="off"
        >
          <Collapse defaultActiveKey={["1", "2", "3"]}>
            <Panel header="Thông tin chung" key="1">
              <ThongTinChung
                form={form}
                type={type}
                checkTraLai={checkTraLai}
                nguoiTao={nguoiTao}
                tenLoaiTT={tenLoaiTT}
                ngayTao={ngayTao}
                tenDoi={tenDoi}
                dataDetail={dataDetail}
                isTraLai={isTraLai}
                defaultInfo={defaultInfo}
              />
            </Panel>
            <Panel header="Danh sách vật tư thu hồi" key="2">
              <DSVatTuThuHoiTable dataTH={dataTH} columnsTH={columnsTH} />
            </Panel>
            <Panel header="Danh sách vật tư trả lại" key="3">
              <DSVatTuTraLaiTable
                dataVTTL={dataVTTL}
                columns={columnsTraLai}
                handleAdd={handleAdd}
                component={components}
              />
            </Panel>
          </Collapse>
          <Row gutter={24}>
            <Col span={24}>
              {isTraLai ? (
                <h4>Phiếu sẽ được trả về cho Đội trưởng !</h4>
              ) : (
                <h4>Phiếu sẽ được chuyển tiếp cho Lãnh đạo !</h4>
              )}
            </Col>
          </Row>
          <Row gutter={24} justify="center" style={{ marginTop: "48px" }}>
            <Button
              key="back"
              onClick={() => dispatch(closeModal())}
              className="button-closed mr-5px"
            >
              Đóng lại
            </Button>
            {isTraLai ? (
              <Button
                type="primary"
                key="tu-choi"
                loading={loading}
                htmlType="submit"
                form="form-submit"
                className="button-closed mr-5px btn-bg-yellow"
              >
                Trả lại
              </Button>
            ) : null}
            {isTraLai ? null : (
              <Fragment>
                <Button
                  type="primary"
                  key="submit"
                  className="button-closed mr-5px"
                  loading={loading}
                  htmlType="submit"
                  form="form-submit"
                >
                  Xác nhận
                </Button>
                <ButtonKySoHSM
                  url={Endpoint.KY_SO_PHIEU_VAT_TU_HSM}
                  params={{ phieuIds: selectRowKeys, vatTuTraLais: dataVTTL }}
                  setFetchList={setFetchList}
                  data={dataVTTL}
                />
              </Fragment>
            )}
          </Row>
        </FormComponent>
      </div>
    </LoadingComponent>
  );
}
