import { BCS_GNDN, ENUM_QL_CHI_SO_GNDN_LOAI_NHAP } from "@utils/constants";
import { Button, Col, Input, message, Modal, Row, Select } from "antd";
import React, { Fragment, useMemo, useState } from "react";
import { getDanhSachDiemDoChuaNhapCS, handleCapNhatChiSo, handleDoXa, handleHuyChiSo } from "./service";

import { updateDanhSachDiemDoCoChiSoNhapSai } from "@state/giao-nhan-dien-nang/quan-ly-chi-so/reducer";
import { numberWithNoSpaces } from "@utils/function";
import { isEmpty, isNull } from "lodash";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { calculateLayDoXa } from "./calculate";
import ModalDanhSachDiemDoChuaNhap from "./modal-danh-sach-diem-do-chua-nhap";
message.config({
  duration: 5,
  maxCount: 1,
});
export default function DanhSachDiemDoHeader(props) {
  const {
    handleKeyDownSearchTerm,
    totalData,
    selectedRowKeys,
    setSelectedRowKeys,
    disableActionBtn,
    dataSource,
    tramId,
    refresh,
    filter,
    setFilter,
    currentPage,
    setLoadingList,
    setDataSource,
    dataSourceOriginal,
    setDataSourceInitial,
  } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingLayDoXaAll, setLoadingLayDoXaAll] = useState(false);
  const [loadingCapNhatCS, setLoadingCapNhatCS] = useState(false);
  const [loadingHuyCS, setLoadingHuyCS] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dataChuaNhap, setDataChuaNhap] = useState(false);

  const handleCapNhatCS = () => {
    const getValue = (value) => {
      if ((!isNull(value) && !isEmpty(value)) || value === 0) {
        return +numberWithNoSpaces(value);
      }
      return null;
    };

    const dataMap = dataSourceOriginal.map((item) => {
      return {
        ...item,
        chiSoCuoi: getValue(item.chiSoCuoi),
        chiSoDau: getValue(item.chiSoDau),
        sanLuong: getValue(item.sanLuong),
        sanLuongTrucTiep: getValue(item.sanLuongTrucTiep),
      };
    });

    let arrToUpdate = [];
    for (let index = 0; index < dataMap.length; index++) {
      const element = dataMap[index];
        const findBoSo = dataMap.filter((item) => item.diemDoId === element.diemDoId);
        const isCheck = findBoSo?.some((item) => isNull(item.chiSoCuoi));
        if (!isCheck && !isEmpty(findBoSo)) {
          arrToUpdate = arrToUpdate.concat(findBoSo.filter((item) => item.boSo === element.boSo));
        }
    }
    const recordChiSoCuoiNhoHonChiSoDau = arrToUpdate.some((item) => item.chiSoCuoi < item.chiSoDau);
    if (recordChiSoCuoiNhoHonChiSoDau) {
      message.error("Chỉ số cuối kỳ phải lớn hơn hoặc bằng chỉ số đầu kỳ.");
    } else {
      if (!isEmpty(arrToUpdate)) {
        handleCapNhatChiSo({
          data: {
            chiSos: arrToUpdate,
          },
          setLoading: setLoadingCapNhatCS,
          callback: () => {
            const queryString = tramId ? { tramId } : { pageIndex: currentPage };
            getDanhSachDiemDoChuaNhapCS({
              queryString,
              setLoading: setLoadingList,
              callback: (res) => {
                if (!isEmpty(res.data)) {
                  setVisible(true);
                  setDataChuaNhap(res.data);
                } else {
                  setVisible(false);
                  setDataChuaNhap([]);
                  // refresh();
                }
              },
            });
          },
        });
      } else {
        message.error(`Chỉ các điểm đo nhập đủ bộ chỉ số mới có thể cập nhật! Bạn không có điểm đo nào thỏa mãn điều kiện.`);
      }
    }
  };

  const alertMessage = (data) => {
    message.success(
      <div style={{ textAlign: "start" }}>
        <div style={{ marginBottom: 5 }}>
          Tổng số điểm đo không lấy đo xa do không có mã điểm đo ánh xạ:
          <span style={{ fontWeight: 600 }}> {data.khongCoMaDoXa}</span>
        </div>
        <div style={{ marginBottom: 5 }}>
          Tổng số điểm đo lấy đo xa thành công:
          <span style={{ fontWeight: 600 }}> {data.thanhCong}</span>
        </div>
        <div style={{ marginBottom: 5 }}>
          Tổng số điểm đo lấy đo xa lỗi:
          <span style={{ fontWeight: 600 }}> {data.loi}</span>
        </div>
      </div>
    );
  };

  const handleCloseModal = () => {
    setVisible(false);
    dispatch(updateDanhSachDiemDoCoChiSoNhapSai([]));
    // refresh();
  };

  const startLayDoXa = (diemDoIds, setLoading) => {
    handleDoXa({
      diemDoIds,
      setLoading,
      callback: (res) => {
        calculateLayDoXa({ dataSource, res, dispatch, setDataSource, dataSourceOriginal, setDataSourceInitial });
        alertMessage(res);
      },
    });
  };
  const handleLayDoXa = () => {
    if (selectedRowKeys && selectedRowKeys.length) {
      const diemDoIds = [];
      for (let index = 0; index < selectedRowKeys.length; index++) {
        const rowKey = selectedRowKeys[index];
        const findDiemDo = dataSource.find((item) => item.id === rowKey);
        if (findDiemDo) {
          diemDoIds.push(findDiemDo.diemDoId);
        }
      }
      startLayDoXa(diemDoIds, setLoading);
    }
  };

  const handleLayDoXaAll = () => {
    const diemDoIds = dataSource.map((item) => item.diemDoId);

    const arr = [...new Set(diemDoIds.filter((i) => i !== null))];
    startLayDoXa(arr, setLoadingLayDoXaAll);
  };

  const handleHuyCS = () => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: "Bạn có chắc chắn muốn hủy chỉ số?",
      onOk() {
        if (selectedRowKeys && selectedRowKeys.length) {
          const diemDoIds = [];
          for (let index = 0; index < selectedRowKeys.length; index++) {
            const rowKey = selectedRowKeys[index];
            const findDiemDo = dataSource.find((item) => item.id === rowKey);
            if (findDiemDo) {
              diemDoIds.push(findDiemDo.diemDoId);
            }
          }
          handleHuyChiSo({
            setSelectedRowKeys,
            setLoading: setLoadingHuyCS,
            data: {
              diemDoIds,
            },
            refresh,
          });
        }
      },
      onCancel() {},
      okText: "Đồng ý",
      okButtonProps: { type: "primary" },
      cancelText: "Hủy",
    });
  };

  const listOptionLoaiNhap = useMemo(() => {
    return Object.keys(ENUM_QL_CHI_SO_GNDN_LOAI_NHAP).map((key) => {
      return {
        value: ENUM_QL_CHI_SO_GNDN_LOAI_NHAP[key]?.value,
        title: ENUM_QL_CHI_SO_GNDN_LOAI_NHAP[key]?.title,
      };
    });
  }, []);

  return (
    <Fragment>
      <Row>
        <Col span={4}>
          <div
            style={{
              fontSize: 16,
              fontWeight: 600,
              marginTop: 3,
            }}
          >
            Danh sách điểm đo
          </div>
        </Col>
        <Col span={3}>
          <div className="mb0" style={{ padding: "5px 0px" }}>
            <span style={{ fontWeight: 600 }}>{totalData}</span> bản ghi
          </div>
        </Col>
        <Col span={5}>
          <div className="mb0" style={{ display: "flex" }}>
            <DescriptionStyled>
              <div
                className="item"
                style={{
                  backgroundColor: "greenyellow",
                }}
              />
              <div
                className="item"
                style={{
                  backgroundColor: "#ffffff",
                }}
              />
            </DescriptionStyled>
            <div style={{ marginTop: 5 }}>Chỉ số nhập thủ công</div>
          </div>
        </Col>
        <Col span={4}>
          <div className="mb0" style={{ display: "flex" }}>
            <DescriptionStyled>
              <div
                className="item"
                style={{
                  backgroundColor: "#F8FF53",
                }}
              />
              <div
                className="item"
                style={{
                  backgroundColor: "#8fbc8f",
                }}
              />
            </DescriptionStyled>
            <div style={{ marginTop: 5 }}>Chỉ số lấy đo xa</div>
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-end">
            <Button
              type="primary"
              onClick={handleLayDoXa}
              disabled={!selectedRowKeys.length || disableActionBtn || loading || loadingLayDoXaAll}
              loading={loading}
            >
              Lấy đo xa các điểm đã chọn
            </Button>
            <Button
              className="ml10"
              type="primary"
              onClick={handleLayDoXaAll}
              disabled={disableActionBtn || !dataSource.length || loadingLayDoXaAll || loadingCapNhatCS || loading}
              loading={loadingLayDoXaAll}
            >
              Lấy đo xa tất cả
            </Button>
          </div>
        </Col>
      </Row>
      <Row justify="space-between" className="mt-16px">
        <Col span={9}>
          <Input
            className="input "
            onKeyDown={handleKeyDownSearchTerm}
            onChange={(e) => {
              setFilter({ ...filter, keyWord: e.target.value });
            }}
            placeholder={"Tìm theo mã điểm đo, thông tin điểm đo"}
            disabled={disableActionBtn}
            value={filter.keyWord}
          />
        </Col>
        <Col span={3}>
          <Select
            onChange={(e) => {
              setFilter({ ...filter, loaiNhap: e });
            }}
            placeholder="Loại nhập"
            style={{ marginLeft: 22, width: "90%" }}
            value={filter.loaiNhap}
          >
            {listOptionLoaiNhap.map((item, id) => {
              return (
                <Select.Option key={id} value={item.value}>
                  {item.title}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
        <Col span={6}>
          <p className="textColorBlue" style={{ paddingTop: 7, marginLeft: 22, width: 320 }}>
            F2: Chỉ số cuối bằng chỉ số đầu{" "}
          </p>
        </Col>
        <Col span={6}>
          <div className="flex-center">
            <Button
              className="btn-bg-yellow"
              onClick={handleCapNhatCS}
              type="submit"
              disabled={disableActionBtn || loadingCapNhatCS || loadingLayDoXaAll || loading}
              loading={loadingCapNhatCS}
            >
              Cập nhật
            </Button>
            <Button
              className="btn-bg-yellow ml10"
              onClick={handleHuyCS}
              disabled={!selectedRowKeys.length || disableActionBtn || loadingHuyCS}
              loading={loadingHuyCS}
            >
              Hủy chỉ số
            </Button>
          </div>
        </Col>
      </Row>
      {visible && <ModalDanhSachDiemDoChuaNhap data={dataChuaNhap} visible={visible} close={handleCloseModal} />}
    </Fragment>
  );
}

const DescriptionStyled = styled.div`
  display: flex;
  height: 22px;
  margin-top: 2px;
  border-radius: 4px;
  border: 0.2px solid #e9e5e5;
  .item {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 0.2px solid #e9e5e5;
  }
`;
