import { Button } from "antd";

export default function ButtonKySoHSM(props) {
  const { handleClick, loading, disabled } = props;
  return (
    <Button
      type="primary"
      onClick={() => handleClick()}
      loading={loading}
      disabled={disabled}
    >
      Ký số
    </Button>
  );
}
