import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_DATE,
  FORMAT_ENGLISH,
  FORMAT_MONTH,
  MONTH_STRING,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  formatDateWithTimezone,
  parseParams,
} from "@utils/function";
import { downloadFile } from "@utils/request";
import { Button, Col, DatePicker, Form, Row, Select } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { selectBCBrandName } from "./service";

export default function TongHopTheoDonVi(props) {
  const location = useLocation();
  const [form] = Form.useForm();
  const { screen, handleChange } = props;
  const { Option } = Select;
  const [filterConditions, setFilterConditions] = useState({
    ...parseParams(location.search),
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    TuNgay: formatDateWithTimezone(`${moment(moment()._d).startOf("month")}`),
    DenNgay: formatDateWithTimezone(`${moment(moment()._d)}`),
    MaDonVI: "all",
  });

  const onDownloadExcel = () => {
    const queryString = buildQueryString(
      parseParams({
        MaDonVI: filterConditions.MaDonVI,
        TuNgay: filterConditions?.TuNgay,
        DenNgay: filterConditions?.DenNgay,
      })
    );
    const endpoint = `${Endpoint.BCBN_TONG_HOP_THEO_TOAN_DV_EXCEL}?${queryString}`;
    if (endpoint !== undefined)
      downloadFile({ endpoint, setLoading: () => {} });
  };

  const onFinish = () => {};

  const handleStartDate = (e) => {
    console.log("e1", e);
    setFilterConditions((oldState) => ({
      ...oldState,
      TuNgay: formatDateWithTimezone(e?._d),
    }));
  };

  const handleEndDate = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      DenNgay: formatDateWithTimezone(e?._d),
    }));
  };

  const onChangeDV = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      MaDonVI: e,
    }));
  };

  return (
    <Container>
      <ContentWrapper>
        <FormComponent
          onFinish={onFinish}
          initialValues={{
            reportType: screen,
            TuNgay: form.setFieldsValue(filterConditions.TuNgay)
              ? form.setFieldsValue(filterConditions.TuNgay)
              : moment().startOf(MONTH_STRING),
          }}
          form={form}
          labelCol={{ span: 3 }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="reportType"
                label="Tên báo cáo"
                labelAlign="left"
              >
                <Select
                  placeholder="Tất cả"
                  options={selectBCBrandName}
                  onChange={(e) => handleChange(e)}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
          <Col span={24}>
            <Form.Item name="unitId" label="Đơn vị" labelAlign="left">
              <Select defaultValue={"all"} onChange={onChangeDV}>
                <Option value="11">CÔNG TY ĐIỆN LỰC HOÀN KIẾM</Option>
                <Option value="12">CÔNG TY ĐIỆN LỰC HAI BÀ TRƯNG</Option>
                <Option value="13">CÔNG TY ĐIỆN LỰC BA ĐÌNH</Option>
                <Option value="14">CÔNG TY ĐIỆN LỰC ĐỐNG ĐA</Option>
                <Option value="15">CÔNG TY ĐIỆN LỰC NAM TỪ LIÊM</Option>
                <Option value="16">CÔNG TY ĐIỆN LỰC THANH TRÌ</Option>
                <Option value="17">CÔNG TY ĐIỆN LỰC GIA LÂM</Option>
                <Option value="18">CÔNG TY ĐIỆN LỰC ĐÔNG ANH</Option>
                <Option value="19">CÔNG TY ĐIỆN LỰC SÓC SƠN</Option>
                <Option value="20">CÔNG TY ĐIỆN LỰC TÂY HỒ</Option>
                <Option value="21">CÔNG TY ĐIỆN LỰC THANH XUÂN</Option>
                <Option value="22">CÔNG TY ĐIỆN LỰC CẦU GIẤY</Option>
                <Option value="23">CÔNG TY ĐIỆN LỰC HOÀNG MAI</Option>
                <Option value="24">CÔNG TY ĐIỆN LỰC LONG BIÊN</Option>
                <Option value="25">CÔNG TY ĐIỆN LỰC MÊ LINH</Option>
                <Option value="26">CÔNG TY ĐIỆN LỰC HÀ ĐÔNG</Option>
                <Option value="27">CÔNG TY ĐIỆN LỰC SƠN TÂY</Option>
                <Option value="28">CÔNG TY ĐIỆN LỰC CHƯƠNG MỸ</Option>
                <Option value="29">CÔNG TY ĐIỆN LỰC THẠCH THẤT</Option>
                <Option value="30">CÔNG TY ĐIỆN LỰC THƯỜNG TÍN</Option>
                <Option value="31">CÔNG TY ĐIỆN LỰC BA VÌ</Option>
                <Option value="32">CÔNG TY ĐIỆN LỰC ĐAN PHƯỢNG</Option>
                <Option value="33">CÔNG TY ĐIỆN LỰC HOÀI ĐỨC</Option>
                <Option value="34">CÔNG TY ĐIỆN LỰC MỸ ĐỨC</Option>
                <Option value="35">CÔNG TY ĐIỆN LỰC PHÚ XUYÊN</Option>
                <Option value="36">CÔNG TY ĐIỆN LỰC PHÚC THỌ</Option>
                <Option value="37">CÔNG TY ĐIỆN LỰC QUỐC OAI</Option>
                <Option value="38">CÔNG TY ĐIỆN LỰC Thanh Oai</Option>
                <Option value="39">CÔNG TY ĐIỆN LỰC ỨNG HÒA</Option>
                <Option value="40">CÔNG TY ĐIỆN LỰC BẮC TỪ LIÊM</Option>
                <Option value="-1">
                  TỔNG CÔNG TY ĐIỆN LỰC THÀNH PHỐ HÀ NỘI
                </Option>
                <Option selected="selected" value="all">
                  Tất cả đơn vị
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Row gutter={24}>
            <Col span={7}>
              <Form.Item
                name="TuNgay"
                label="Từ tháng"
                labelAlign="left"
                labelCol={{ span: 11 }}
              >
                <div style={{ marginLeft: "-2.4%" }}>
                  <DatePicker
                    formKey="TuNgay"
                    picker="month"
                    format={FORMAT_MONTH}
                    defaultValue={
                      filterConditions.TuNgay
                        ? moment(filterConditions.TuNgay, FORMAT_ENGLISH)
                        : moment()
                    }
                    form={form}
                    onChange={handleStartDate}
                  />
                </div>
              </Form.Item>
            </Col>

            <Col span={7}>
              <Form.Item
                name="DenNgay"
                label="Đến tháng"
                labelAlign="right"
                labelCol={{ span: 11 }}
              >
                <DatePicker
                  formKey="DenNgay"
                  format={FORMAT_MONTH}
                  picker="month"
                  form={form}
                  onChange={handleEndDate}
                  defaultValue={
                    filterConditions.DenNgay
                      ? moment(filterConditions.DenNgay, FORMAT_ENGLISH)
                      : moment()
                  }
                />
              </Form.Item>
            </Col>

            <Col span={8} style={{ paddingLeft: 50 }}>
              <Button type="primary" onClick={onDownloadExcel}>
                Xuất Excel
              </Button>
            </Col>
          </Row>
          <Row justify="end"></Row>
        </FormComponent>
      </ContentWrapper>
    </Container>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
