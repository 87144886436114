const n1 = {
  n1: "n1",
  n1x: "n1x",
};

const n2 = {
  n2: "n2",
};
const n21 = {
  n21: "n21",
  n21x: "n21x",
  n21i: "n21i",
  n21g: "n21g",
  n21p: "n21p",
  n21h: "n21h",
  n21d: "n21d",
};
const n22 = {
  n22: "n22",
  n22x: "n22x",
  n22i: "n22i",
  n22g: "n22g",
  n22p: "n22p",
  n22h: "n22h",
};
const n23 = {
  n23: "n23",
  n23x: "n23x",
  n23xn: "n23xn",
  n23k: "n23k",
  n23t: "n23t",
};
const n24 = {
  n24: "n24",
  n24db: "n24db",
};

const n3 = {
  n3: "n3",
};
const n31 = {
  n31: "n31",
  n31x: "n31x",
  n31i: "n31i",
  n31g: "n31g",
  n31p: "n31p",
  n31h: "n31h",
  n31d: "n31d",
};
const n32 = {
  n32: "n32",
  n32x: "n32x",
  n32i: "n32i",
  n32g: "n32g",
  n32p: "n32p",
  n32h: "n32h",
};
const n33 = {
  n33: "n33",
  n33x: "n33x",
  n33xn: "n33xn",
  n33k: "n33k",
  n33t: "n33t",
};

const n34 = {
  n34: "n34",
  n34cn: "n34cn",
  n34c: "n34c",
};

const n4 = {
  n4: "n4",
};
const n41 = {
  n41: "n41",
  n41x: "n41x",
  n41lm: "n41lm",
};
const n42 = {
  n42: "n42",
  n42x: "n42x",
  n42c: "n42c",
  n42kt: "n42kt",
};
const n43 = {
  n43: "n43",
  n43x: "n43x",
  n43lm: "n43lm",
};
const n44 = {
  n44: "n44",
  n44x: "n44x",
  n44c: "n44c",
  n44kt: "n44kt",
};
const n45 = {
  n45: "n45",
  n45x: "n45x",
  n45h: "n45h",
  n45xn: "n45xn",
};
const n46 = {
  n46: "n46",
  n46xn: "n46xn",
  n46h: "n46h",
};
const n47 = {
  n47: "n47",
  n47x: "n47x",
  n47c: "n47c",
  n47kt: "n47kt",
};
const n48 = {
  n48: "n48",
  n48cn: "n48cn",
  n48c: "n48c",
};

const n5 = {
  n5: "n5",
};
const n51 = {
  n51: "n51",
  n51x: "n51x",
  n51e: "n51e",
};
const n52 = {
  n52: "n52",
  n52x: "n52x",
  n52e: "n52e",
};
const n53 = {
  n53: "n53",
  n53x: "n53x",
  n53e: "n53e",
};

const n6 = {
  n6: "n6",
};
const n61 = {
  n61: "n61",
  n61x: "n61x",
  n61ex: "n61ex",
};
const n62 = {
  n62: "n62",
  n62x: "n62x",
  n62ex: "n62ex",
};
const n63 = {
  n63: "n63",
  n63x: "n63x",
  n63ex: "n63ex",
};

const n7 = {
  n7: "n7",
};
const n71 = {
  n71: "n71",
  n71x: "n71x",
  n71k: "n71k",
  n71xn: "n71xn",
  n71t: "n71t",
};

export const kt_tb_do_dem = {
  ...n1,
  ...n2,
  ...n21,
  ...n22,
  ...n23,
  ...n24,
  ...n3,
  ...n31,
  ...n32,
  ...n33,
  ...n34,
  ...n4,
  ...n41,
  ...n42,
  ...n43,
  ...n44,
  ...n45,
  ...n46,
  ...n47,
  ...n48,
  ...n5,
  ...n51,
  ...n52,
  ...n53,
  ...n6,
  ...n61,
  ...n62,
  ...n63,
  ...n7,
  ...n71,
};
