import { Form, Modal } from "antd";
import React, { useEffect, useState } from "react";
import ModalXNAnToan from "./layout";
import { handleListPhieuLenh, handleXacNhan } from "../services";

export default function Index(props) {
  const { visible, closeModal, detail, setFetchList } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    const params = {
      maSSONVTT: detail.maSSONVTT,
      maSSONVNP: detail.maSSONVNP,
      ngayTreoThao: detail.ngayTreoThao,
      maDonVi: detail.maDonVi,
    };
    handleListPhieuLenh(params, setLoading, setData);
  }, [detail]);

  const onFinish = () => {
    const payload = {
      hoSoId: detail.hoSoId ?? detail.id,
    };
    let isSubmit = true;
    if (data && Object.keys(selected).length > 0) {
      payload.phieuLenhCongTac = selected;
      isSubmit = true;
    } else {
      isSubmit = false;
    }

    if (isSubmit) {
      handleXacNhan(setLoading, payload, setFetchList, closeModal);
    } else {
      Modal.confirm({
        centered: true,
        title: "Xác nhận",
        content:
          "Chưa chọn phiếu lệnh công tác, bạn có chắc chắn muốn tiếp tục?",
        onOk() {
          handleXacNhan(setLoading, payload, setFetchList, closeModal);
        },
        onCancel() {},
        okText: "Đồng ý",
        okButtonProps: { type: "primary" },
        cancelText: "Hủy",
      });
    }

    // handleXacNhan(setLoading, payload, setFetchList, closeModal);
  };

  const onChangedSelect = (value) => {
    let arr = [];
    if (value !== undefined) {
      arr = data.filter((el) => el.stt === value);
    } else arr = [{}];
    setSelected(arr[0]);
  };

  return (
    <ModalXNAnToan
      loading={loading}
      visible={visible}
      closeModal={closeModal}
      onFinish={onFinish}
      dataSelect={data}
      form={form}
      onChangedSelect={onChangedSelect}
      selected={selected}
      nvtt={detail.nhanVienTreoThao}
      nvnp={detail.nhanVienNiemPhong}
    />
  );
}
