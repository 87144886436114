import { memo, useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { handleSubmitService } from "./services";
import ModalSuaQuyetToan from "./ui-modal/modal-sua-quyet-toan-khach-hang";

function Index({
  detailLapQuyetToanCongTy,
  visible,
  handleCloseModal,
  getListDanhSach,
  detailHoSo,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  //Call api service
  const handleSubmitData = (values) => {
    handleSubmitService(
      values,
      detailLapQuyetToanCongTy,
      setLoading,
      handleCloseModal,
      getListDanhSach,
      t,
      detailHoSo
    );
  };
  return (
    <ModalSuaQuyetToan
      loading={loading}
      visible={visible}
      handleCloseModal={handleCloseModal}
      handleSubmitData={handleSubmitData}
      detailLapQuyetToanCongTy={detailLapQuyetToanCongTy}
      form={form}
      t={t}
    ></ModalSuaQuyetToan>
  );
}

export default memo(Index);
