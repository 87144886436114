import { Button, Col, Modal, Row } from "antd";
import React from "react";
function DuyetYeuCauModal(props) {
  const { visible, handleCloseModal, handleSubmit, loading, loadingKyHSM, handleKyHSM = () => {} } = props;

  return (
    // <LoadingComponent loading={loading}>
    <Modal
      title="YÊU CẦU ĐANG Ở GDV."
      visible={visible}
      onCancel={handleCloseModal}
      footer={null}
    >
      <h4 className="fs-14px" style={{ color: "#1F1F1F", textAlign: "center" }}>
        Yêu cầu sẽ được GDV ký số/xác nhận.
        <br />
        Sau đó yêu cầu được chuyển sang bước tiếp theo.
      </h4>
      <Row gutter={24} justify="center" style={{ marginTop: "20px" }}>
        <Col span={8} style={{ textAlign: "right" }}>
          <Button
            key="back"
            onClick={handleCloseModal}
            className="button-closed"
          >
            Đóng lại
          </Button>
        </Col>
        <Col span={8} style={{ textAlign: "center" }}>
          <Button
            key="submit"
            type="primary"
            className="button-primary"
            htmlType="submit"
            onClick={handleSubmit}
            loading={loading}
          >
            Xác nhận
          </Button>
        </Col>
        <Col span={8}>
          <Button loading={loadingKyHSM} onClick={handleKyHSM} type="danger">
            Ký HSM
          </Button>
        </Col>
      </Row>
    </Modal>
    // </LoadingComponent>
  );
}

export default DuyetYeuCauModal;
