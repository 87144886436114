import FormComponent from "@components/Form";
import TableComponent from "@components/TableComponent";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_MONTH_ENGLISH,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { authGetData } from "@utils/request";
import { Button, Col, Divider, Form, Row, Select, DatePicker } from "antd";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";
import { selecPhanLoai, selectBaoCaoKySo } from "../services";
import XuatExcel from "../../bc-doi-soat-sms/tabs/xuat-excel";
import Selection from "@components/Select";
import { useSelector } from "react-redux";

export default function ChiTietSlFileKySo(props) {
  const { screen, handleChange } = props;
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [data, setData] = useState([]);
  const [maDonVi, setMaDonVi] = useState(null);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState();
  const [form] = Form.useForm();
  const [filterConditions, setFilterConditions] = useState({
    pageSize: DEFAULT_PAGESIZE,
    pageIndex: DEFAULT_PAGEINDEX,
    ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
    // ...parseParams(location.search),
    phanLoai: "e12382fc-5ebb-474d-a1a7-de277b424031",
    ...(user.unitCode && {MaDonVi: user.unitCode}),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  // const getListData = useCallback(() => {
  //   if (filterConditions.ThangNam) {
  //     const queryString = buildQueryString(
  //       parseParams({
  //         ...filterConditions,
  //       })
  //     );
  //     authGetData({
  //       url: `${Endpoint.CHI_TIET_SO_LUONG_KY_S0}?${queryString}`,
  //       setLoading,
  //       onSuccess: (res) => {
  //         if (res && res.data) {
  //           setData(res.data);
  //           setTotalCount(res.paging.totalCount);
  //         }
  //       },
  //     });
  //   }
  // }, [filterConditions]);

  const handleSearch = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      phanLoai: filterConditions.phanLoai,
      ThangNam: moment(values.ThangNam).format("YYYY-MM"),
      MaDonVi: values.MaDonVi,
    }));

    if (filterConditions.ThangNam) {
      const queryString = buildQueryString(
        parseParams({
          ...filterConditions,
          phanLoai: values.phanLoai,
          ThangNam: moment(values.ThangNam).format("YYYY-MM"),
          MaDonVi: values.MaDonVi,
        })
      );
      authGetData({
        url: `${Endpoint.CHI_TIET_SO_LUONG_KY_S0}?${queryString}`,
        setLoading,
        onSuccess: (res) => {
          if (res && res.data) {
            setData(res.data);
            setTotalCount(res.paging.totalCount);
          }
        },
      });
    }
  };

  // useEffect(() => {
  //   getListData();
  // }, [getListData]);

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };
  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      width: 40,
      align: "center",
      render: (a, b, index) => {
        return (
          (filterConditions.pageIndex - 1) * filterConditions.pageSize +
          (index + 1)
        );
      },
    },
    {
      title: "Mã đơn vị",
      dataIndex: "maDonVi",
      key: "maDonVi",
      align: "center",
      width: 100,
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      key: "maKhachHang",
      align: "center",
      width: 100,
    },
    {
      title: "Tháng",
      dataIndex: "thang",
      key: "thang",
      width: 70,
      align: "center",
    },
    {
      title: "Năm",
      dataIndex: "nam",
      key: "nam",
      width: 70,
      align: "center",
    },
    {
      title: "Thời gian ký",
      dataIndex: "thoiGianKy",
      key: "thoiGianKy",
      width: 100,
      // render: (thoiGianKy) => {
      //   const bcdate = new Date(thoiGianKy);
      //   return (
      //     <span>
      //       {bcdate
      //         .toLocaleDateString("en-HK", {
      //           thoiGianKy,
      //         })
      //         .toLocaleUpperCase()}
      //     </span>
      //   );
      // },
    },
  ];
  const optionValuePhanLoai = [
    {
      label: "Thông báo TĐ lần 2",
    },
  ];
  const handleChangePhanLoai = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      phanLoai: e,
    }));
  };
  const handleChangeThang = (date, dateString) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      ThangNam: date.format("YYYY-MM"),
    }));
  };

  useEffect(() => {
    if(maDonVi) {
      setFilterConditions((oldState) => ({
      ...oldState,
      MaDonVi: maDonVi,
    }));
    }
    
  }, [maDonVi]);

  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <FormComponent
            onFinish={handleSearch}
            form={form}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            initialValues={{
              reportType: screen,
              phanLoai: "e12382fc-5ebb-474d-a1a7-de277b424031",
              MaDonVi: user.unitCode,
            }}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="reportType"
                  label="Tên báo cáo"
                  labelAlign="left"
                >
                  <Select
                    formKey="reportType"
                    form={form}
                    options={selectBaoCaoKySo}
                    onChange={() =>
                      handleChange(form.getFieldValue("reportType"))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="phanLoai" label="Phân loại" labelAlign="left">
                  <Select
                    formKey="phanLoai"
                    form={form}
                    options={selecPhanLoai}
                    defaultValue={optionValuePhanLoai}
                    onChange={handleChangePhanLoai}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="MaDonVi" label="Đơn vị" labelAlign="left">
                  <Selection
                    url={Endpoint.GET_TEAM_LIST_CODE}
                    formKey="MaDonVi"
                    form={form}
                    setValue={setMaDonVi}
                    defaultValue={user.unitName}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={6} md={12}>
                <Form.Item
                  name="ThangNam"
                  label="Tháng năm"
                  labelCol={{ span: 6 }}
                  labelAlign="left"
                >
                  <DatePicker
                    picker="month"
                    defaultValue={moment()}
                    defaultPickerValue={moment()}
                    format={"YYYY-MM"}
                    onChange={handleChangeThang}
                  />
                </Form.Item>
              </Col>
              <Row justify="end">
                <Button type="primary" className="mr-5px" htmlType="submit">
                  Xem báo cáo
                </Button>
                <XuatExcel
                  endpoint={
                    Endpoint.CHI_TIET_EXCEL +
                    "?" +
                    buildQueryString(parseParams(filterConditions))
                  }
                  text={"Xuất Excel"}
                  key={"xuat-excel"}
                />
              </Row>
            </Row>
          </FormComponent>
          <Divider className="section-text-line" orientation="left"></Divider>
          <TableComponent
            header={"Chi tiết số lượng file ký số"}
            columns={columns}
            dataSource={data}
            loading={loading}
            filterConditions={filterConditions}
            totalCount={totalCount}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
          />
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
