import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, authPostData } from "@utils/request";

export const getDataThongTinAnhXa = (
  setDataTramTCT,
  setDataTramToanDV,
  setLoading
) => {
  authGetData({
    url: `${Endpoint?.GET_ALL_TRAM}`,
    setLoading,
    onSuccess: (res) => {
      if (res.length > 0) setDataTramTCT(res);
      else setDataTramTCT(res.data);
    },
  });
};

export const PostAnhXaThongTin = (
  setDataTramTCT,
  setDataTramToanDV,
  setLoading,
  targetKeys,
  filterConditions
) => {
  authPostData({
    url: `${Endpoint.POST_CHANGE_TRAM}`,
    method: METHOD_PUT,
    payload: {
      diemDoId: targetKeys,
      maDiemDoDoXa: filterConditions.DonViGNId,
      ngayTao: filterConditions.DonViGNId,
      kenhGiao: filterConditions.DonViGNId,
      kenhNhan: filterConditions.DonViGNId,
    },
    setLoading,
    onSuccess: () => {},
  });
};

export const handleListData = (
  term,
  setLoading,
  setDataSource,
  setDetail,
  setIsCGKG,
  setIsCNKG,
  setIsCGKN,
  setIsCNKN,
  setValueIsCGKG,
  setValueIsCNKG,
  setValueIsCGKN,
  setValueIsCNKN,
  thang,
  nam,
  selectedRowKey
) => {
  const queryString1 = buildQueryString(
    parseParams({
      SearchTerm: term,
      Thang: thang,
      Nam: nam,
      layDoXa: false,
    })
  );

  authGetData({
    url: `${Endpoint.GET_LIST_ANH_XA_DIEM_DO}?${queryString1}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setDataSource(
          res.data.map((e, index) => ({ ...e, stt: index + 1, key: index + 1 }))
        );
        if (res.data.length > 0) {
          setDetail(res.data[selectedRowKey - 1]);
          setIsCGKG(
            res.data[selectedRowKey - 1].kenhGiao === "N" ? true : false
          );
          setIsCNKG(
            res.data[selectedRowKey - 1].kenhGiao === "G" ? true : false
          );
          setIsCGKN(
            res.data[selectedRowKey - 1].kenhNhan === "N" ? true : false
          );
          setIsCNKN(
            res.data[selectedRowKey - 1].kenhNhan === "G" ? true : false
          );

          setValueIsCGKG(
            res.data[selectedRowKey - 1].kenhGiao === "N" ? false : true
          );
          setValueIsCNKG(
            res.data[selectedRowKey - 1].kenhGiao === "G" ? false : true
          );
          setValueIsCGKN(
            res.data[selectedRowKey - 1].kenhNhan === "N" ? false : true
          );
          setValueIsCNKN(
            res.data[selectedRowKey - 1].kenhNhan === "G" ? false : true
          );
        }
      }
    },
  });
};

export const handleLayDoXa = (
  term,
  setLoading,
  setDataSource,
  dataSource,
  setDetail,
  thang,
  nam,
  maDoXa
) => {
  const queryString = buildQueryString(
    parseParams({
      SearchTerm: term,
      Thang: thang,
      Nam: nam,
      MaDoXa: maDoXa,
      layDoXa: true,
    })
  );
  authGetData({
    url: `${Endpoint.GET_LIST_ANH_XA_DIEM_DO}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setDataSource(
          res.data.map((e, index) => ({ ...e, stt: index + 1, key: index + 1 }))
        );
        if (dataSource.length > 0) {
          setDetail(dataSource[0]);
        }
      }
    },
  });
};

export const callAPILayDoXa = (setLoading, getListData, payload) => {
  authPostData({
    url: Endpoint.LAY_CHI_SO_DOXA_ANHXA,
    payload,
    method: METHOD_POST,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListData(res.data);
      }
    },
  });
};

export const callAPIUpdateThongTin = (payload, setLoading, getListData) => {
  authPostData({
    url: Endpoint.UPDATE_THONG_TIN_DIEM_DO,
    payload,
    method: METHOD_PUT,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListData();
      }
    },
  });
};
