import { Endpoint } from "@utils/endpoint";
import { Table } from "antd";
import Text from "antd/lib/typography/Text";
import Screen1 from "../common-screen/screen1";
const DsSoChuaKySo = () => {
  const columns = [
    {
      title: "STT",
      dataIndex: "key",
      width: "70px",
      align: "center",
    },
    {
      title: "Mã đơn vị",
      dataIndex: "maDonVi",
      width: "150px",
    },
    {
      title: "Mã sổ",
      dataIndex: "maSo",
      width: "150px",
    },
    {
      title: "Tổng số KH",
      dataIndex: "tongSoKH",
      align: "center",
    },
    {
      title: "Loại sổ",
      dataIndex: "loaiSo",
      align: "center",
    },
    {
      title: "Kỳ",
      dataIndex: "ky",
      align: "center",
    },
    {
      title: "Hình thức GCS",
      dataIndex: "hinhThucGCS",
      align: "center",
    },
    {
      title: "Ngày GCS",
      align: "center",
      dataIndex: "ngayGCS",
      width: "100px",
      render: (ngayGCS) => {
        if (ngayGCS) return <span>{ngayGCS}</span>;
      },
    },
    {
      title: "Ngày NVGCS ký số",
      align: "center",
      width: "100px",
      dataIndex: "ngayNVGCS",
      render: (ngayNVGCS) => {
        if (ngayNVGCS) return <span>{ngayNVGCS}</span>;
      },
    },
    {
      title: "Ngày đội trưởng ký sổ",
      align: "center",
      width: "100px",
      dataIndex: "ngayDoiKySo",
      render: (ngayDoiKySo) => {
        return <span>{ngayDoiKySo}</span>;
      },
    },
    {
      title: "Ngày NV điều hành ký số",
      align: "center",
      width: "100px",
      dataIndex: "ngayNVDieuHanhKySo",
      render: (ngayNVDieuHanhKySo) => {
        return <span>{ngayNVDieuHanhKySo}</span>;
      },
    },
    {
      title: "Ngày lãnh đạo ký số",
      align: "center",
      width: "100px",
      dataIndex: "ngayLanhDaoKySo",
      render: (ngayLanhDaoKySo) => {
        return <span>{ngayLanhDaoKySo}</span>;
      },
    },
    {
      title: "Tổng các bộ phận ký số",
      dataIndex: "tongCacBoPhanKySo",
      align: "center",
    },
    {
      title: "Các bộ phận thiếu ký số",
      dataIndex: "cacBoPhanThieuKySo",
      align: "center",
    },
    {
      title: "Chênh lệch",
      dataIndex: "chenhLech",
      align: "center",
    },
    {
      title: "Ghi chú",
      dataIndex: "ghichu",
    },
  ];
  const countTotals = (pageData) => {
    let countslkh = 0;
    pageData.forEach(({ slkh }) => {
      countslkh += parseInt(slkh);
    });
    return (
      <Table.Summary.Row style={{ backgroundColor: "#e0e0d1" }}>
        <Table.Summary.Cell align="center" index={0} colSpan={3}>
          <Text className="font-bold">Tổng cộng</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={1}>
          <Text> {countslkh}</Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };
  const endPoint = {
    LIST: Endpoint.LIST_SO_CHUA_KY_SO,
    EXCEL: Endpoint.EXCEL_SO_CHUA_KY_SO,
    PDF: Endpoint.PDF_SO_CHUA_KY_SO,
    ...Endpoint,
  };

  return (
    <Screen1
      columns={columns}
      endPoint={endPoint}
      scrollX={2500}
      countTotals={countTotals}
    />
  );
};

export default DsSoChuaKySo;
