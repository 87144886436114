/* eslint-disable no-unused-vars */
import React, { memo, useState } from "react";
import HuyPhanCongModal from "./modal-huy-phan-cong";
import { handleHuyPhanCong } from "./services";
function Index({
  visible,
  handleCloseModal,
  setVisibleHuyPC,
  id,
  setFetchDataTable,
  typeHuy,
  setSelectedRowKeys,
}) {
  const [loading, setLoading] = useState(false);
  // hàm call api hủy phân công

  const handleSubmit = () => {
    const data = {
      congToIds: id,
      pkdHuy: typeHuy ? true : false,
    };
    handleHuyPhanCong(
      data,
      setLoading,
      setFetchDataTable,
      setVisibleHuyPC,
      setSelectedRowKeys
    );
  };
  return (
    <HuyPhanCongModal
      loading={loading}
      visible={visible}
      closeModal={handleCloseModal}
      handleSubmit={handleSubmit}
    />
  );
}

export default memo(Index);
