import { Button, Col, Form, Row, Select } from "antd";
import FormComponent from "@components/Form";
// import { Endpoint } from "@utils/endpoint";
import {
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
  FORMAT_MONTH,
} from "@utils/constants";
import "moment/locale/vi";
import moment from "moment";
import DatePickerComponent from "@components/DatePicker";
import { cap_dien } from "@permissions/a_capdien";
// import ExportExcel from "../common/xuat-excel";
import { removeAccents } from "@utils/function";
import CheckPermission from "@components/CheckPermission";

const { Option } = Select;

export default function FormBoLoc(props) {
  const {
    form,
    handleSearch,
    user,
    filterConditions,
    clear,
    donVi,
    loaiYeuCau,
    setType,
  } = props;
  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
        loaiTongHopId: null,
      }}
      autoComplete="off"
    >
      <Row gutter={24} justify="space-between" align="middle">
        <Col span={24} md={12}>
          <Form.Item name="donViId" label="Đơn vị">
            <Select
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
              disabled={!user.isTongCongTy ? true : false}
            >
              {donVi && donVi.length > 0
                ? donVi.map((item, index) => (
                    <Option key={index} value={item.value}>
                      {item.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item name="loaiYeuCauId" label="Loại tổng hợp">
            <Select placeholder="Tất cả" allowClear>
              {loaiYeuCau && loaiYeuCau.length > 0
                ? loaiYeuCau.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="ngayBaoCao" label="Tháng báo cáo">
            <DatePickerComponent
              defaultValue={
                filterConditions.ngayBaoCao
                  ? moment(filterConditions.ngayBaoCao, FORMAT_ENGLISH)
                  : moment(moment(), FORMAT_MONTH)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"ngayBaoCao"}
              picker="month"
              notClear={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={24} style={{ textAlign: "right" }}>
          <Button className="mr-24px" onClick={clear} key={1}>
            Bỏ lọc
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            form="filter-form"
            className="mr-24px"
            key={2}
            onClick={() => setType(ENUM_BUTTON_TYPE.TIM_KIEM)}
          >
            Tìm kiếm
          </Button>
          <CheckPermission permissionCode={cap_dien.a590_xuat}>
            <Button
              type="primary"
              className="mr-24px"
              htmlType="submit"
              form="filter-form"
              onClick={() => setType(ENUM_BUTTON_TYPE.XUAT_EXCEL)}
            >
              Xuất excel
            </Button>
          </CheckPermission>
          <CheckPermission permissionCode={cap_dien.a590_xuat_chi_tiet}>
            <Button
              type="primary"
              className="mr-24px"
              htmlType="submit"
              form="filter-form"
              onClick={() => setType(ENUM_BUTTON_TYPE.XUAT_EXCEL_CHI_TIET)}
            >
              Xuất Excel chi tiết
            </Button>
          </CheckPermission>
        </Col>
      </Row>
    </FormComponent>
  );
}
