import {
  Form,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  Checkbox,
  Select,
  Collapse,
} from "antd";
import FormComponent from "@components/Form";
import moment from "moment";
import { FORMAT_DATE } from "@utils/constants";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import LoadingComponent from "@components/Loading";
import { Container } from "./css-styled";
import Columns from "../columns-don-gia";
import { useDispatch } from "react-redux";
import { closeModal } from "@state/system-config/reducer";
const { Option } = Select;
const { Panel } = Collapse;

export default function Index(props) {
  const {
    loading,
    onFinish,
    ngayBatDau,
    // ngayKetThuc,
    vatTuChinh,
    form,
    handleChangeVatTuChinh,
    handleChangeMany,
    handleChangeBatDau,
    handleChangeKetThuc,
    many,
    dataSource,
    handleAdd,
    t,
    // dataLoaiChiPhi,
    dataChungLoai,
    dataHinhThuc,
    tableColums,
    nameFields,
    handleRemove,
    setKeyData,
  } = props;

  const dispath = useDispatch();
  return (
    <LoadingComponent loading={loading}>
      <Container>
        <FormComponent
          autoComplete="off"
          form={form}
          name="form-vat-tu"
          onFinish={onFinish}
          initialValues={{
            ngayHieuLuc: moment(ngayBatDau, FORMAT_DATE),
            ngayHetHieuLuc: null,
            isVatTuChinh: vatTuChinh,
            donGiaVatTu: 0,
            donGiaMayThiCong: 0,
            donGiaNhanCongNoiBo: 0,
          }}
          layout="vertical"
          scrollToFirstError
        >
          <Row gutter={24}>
            <Col span={24} md={12}>
              <Form.Item
                label="Mã vật tư"
                name="maVatTu"
                className="required-field"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label="Tên vật tư"
                name="tenVatTu"
                className="required-field"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label="Đơn vị tính"
                name="donViTinh"
                className="required-field"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label="Chủng loại"
                name="chungLoai"
                className="required-field"
              >
                <Select>
                  {dataChungLoai.map((item, index) => (
                    <Option value={item.value.toString()} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={4} style={{ display: "flex" }}>
              <Form.Item
                name="isVatTuChinh"
                style={{
                  marginBottom: 0,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox onChange={handleChangeVatTuChinh}>
                  Vật tư chính
                </Checkbox>
              </Form.Item>
            </Col>

            <Col span={24} md={20} style={{ display: "flex" }}>
              <Form.Item
                name="loaiHinhThucThiCong"
                style={{
                  marginBottom: 0,
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onChange={handleChangeMany}
              >
                <Checkbox>
                  Là vật tư nhiều mức đơn giá dịch vụ ứng với các hình thức thi
                  công
                </Checkbox>
              </Form.Item>
            </Col>
            {/* <Col span={24} md={12}>
              <Form.Item
                name="loaiChiPhiGianTiepId"
                label="Loại chi phí gián tiếp"
                className="required-field"
              >
                <Select>
                  {dataLoaiChiPhi && dataLoaiChiPhi.length > 0
                    ? dataLoaiChiPhi.map((item, index) => (
                        <Option value={item.loaiChiPhiId} key={index}>
                          {item.tenLoaiChiPhi}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col> */}
          </Row>

          <Collapse defaultActiveKey={["1"]}>
            <Panel header="Thông tin đơn giá" key="1">
              <Row gutter={24}>
                <Col span={24} md={12}>
                  <Form.Item
                    label="Ngày hiệu lực"
                    name="ngayHieuLuc"
                    className="required-field"
                  >
                    <DatePicker
                      locale={locale}
                      format={FORMAT_DATE}
                      onChange={handleChangeBatDau}
                      placeholder={FORMAT_DATE}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item label="Ngày hết hiệu lực" name="ngayHetHieuLuc">
                    <DatePicker
                      locale={locale}
                      format={FORMAT_DATE}
                      onChange={handleChangeKetThuc}
                      placeholder={FORMAT_DATE}
                      allowClear={true}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={8}>
                  <Form.Item
                    label="Đơn giá vật liệu"
                    name="donGiaVatTu"
                    // className="required-field"
                  >
                    <Input
                      style={{ textAlign: "right" }}
                      placeholder="Nhập đơn giá vật liệu"
                      type="number"
                      min={0}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={8}>
                  <Form.Item
                    label="Đơn giá máy thi công"
                    name="donGiaMayThiCong"
                    // className="required-field"
                  >
                    <Input
                      style={{ textAlign: "right" }}
                      placeholder="Nhập đơn giá máy thi công"
                      type="number"
                      min={0}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={8}>
                  <Form.Item
                    label="Đơn giá nhân công quyết toán (Nội bộ)"
                    name="donGiaNhanCongNoiBo"
                    // className="required-field"
                  >
                    <Input
                      style={{ textAlign: "right" }}
                      placeholder="Nhập đơn giá nhân công quyết toán (Nội bộ)"
                      type="number"
                      min={0}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} style={{ textAlign: "right" }}>
                  <Columns
                    dataSource={dataSource}
                    handleAdd={handleAdd}
                    isHinhThuc={many}
                    dataHinhThuc={dataHinhThuc}
                    tableColums={tableColums}
                    nameFields={nameFields}
                    handleRemove={handleRemove}
                    setKeyData={setKeyData}
                    form={form}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <Row gutter={24} justify="center" style={{ marginTop: "24px" }}>
            <Col span={12} style={{ textAlign: "right" }}>
              <Button
                key="back"
                onClick={() => dispath(closeModal())}
                className="button-closed"
              >
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-vat-tu"
                loading={loading}
              >
                {t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Container>
    </LoadingComponent>
  );
}
