import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import LogoEVNHN from "@assets/images/logo-evn-hn.png";
import Avatar from "@assets/images/avatar-default.png";
import LogoutIcon from "@assets/images/icon/logout.png";
import BuildingIcon from "@assets/icon/BuildingIcon";
import PhoneIcon from "@assets/icon/PhoneIcon";
import SystemItem from "@components/SystemItem";
import { Row, Col, Spin, Image, Input } from "antd";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import { authGetData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { signOut } from "@state/auth/reducer";
import { useDispatch } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { lapPhuongAnSelector } from "@state/cap-dien/nhan-vien-khao-sat/lap-phuong-an";
import { yeuCauDuocPhanCongSelector } from "@state/treo-thao/nhan-vien-treo-thao";
import { phanQuyenDiemDoSelector } from "@state/giao-nhan-dien-nang";
import {
  notSavedPhanQuyenDiemDo,
  setCanShowDialogLeavingTT,
} from "@state/giao-nhan-dien-nang/reducer";
import {
  notSavedPhuongAnCapDien,
  notSavedThongTinCoBan,
  rangeOfChange,
  setCanShowDialogLeavingPACD,
} from "@state/cap-dien/nhan-vien-khao-sat/lap-phuong-an/reducer";
import {
  getSaveDataPopup,
  setCanShowDialogLeaving,
} from "@state/treo-thao/nhan-vien-treo-thao/reducer";
import { closeModal } from "@state/system-config/reducer";
export default function HomePage() {
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const { user } = auth;
  const [avatar, setAvatar] = useState();
  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState([]);
  const [dataModule, setDataModule] = useState([]);
  const { phuongAnCapDien, thongTinCoBan } = useSelector(lapPhuongAnSelector);
  const { saveData } = useSelector(yeuCauDuocPhanCongSelector);
  const { phanQuyenDiemDo } = useSelector(phanQuyenDiemDoSelector);
  const getUserAccountHome = useCallback(() => {
    authGetData({
      url: `${Endpoint.GET_PROFILE}`,
      setLoading,
      onSuccess: (res) => {
        setAvatar(res.data.avatar);
      },
    });
  }, []);
  useEffect(() => {
    // getUserAccountHome();
  }, []);
  useEffect(() => {
    authGetData({
      url: `${Endpoint.GET_MODULE_LIST}`,
      setLoading,
      onSuccess: (res) => {
        if (res && res.data) {
          setModules(res.data);
          setDataModule(res.data);
        }
      },
    });
  }, []);

  useEffect(() => {
    dispatch(rangeOfChange(false));
    if (phuongAnCapDien) dispatch(notSavedPhuongAnCapDien(false));
    if (thongTinCoBan) dispatch(notSavedThongTinCoBan(false));
    dispatch(setCanShowDialogLeavingPACD(false));
    if (phanQuyenDiemDo) dispatch(notSavedPhanQuyenDiemDo(false));
    dispatch(setCanShowDialogLeavingTT(false));
    if (saveData) dispatch(getSaveDataPopup(false));
    dispatch(setCanShowDialogLeaving(false));
    dispatch(closeModal());
  }, [dispatch, phanQuyenDiemDo, phuongAnCapDien, saveData, thongTinCoBan]);

  const handleSignOut = () => {
    dispatch(signOut({ isLoginSSO: auth.isLoginSSO }));
    dispatch(rangeOfChange(false));
    if (phuongAnCapDien) dispatch(notSavedPhuongAnCapDien(false));
    if (thongTinCoBan) dispatch(notSavedThongTinCoBan(false));
    dispatch(setCanShowDialogLeavingPACD(false));
    if (phanQuyenDiemDo) dispatch(notSavedPhanQuyenDiemDo(false));
    dispatch(setCanShowDialogLeavingTT(false));
    if (saveData) dispatch(getSaveDataPopup(false));
    dispatch(setCanShowDialogLeaving(false));
    dispatch(closeModal());
  };

  const handleChange = useCallback(
    (e) => {
      let arr = [];
      const arr1 = [];
      const arr2 = [];
      // eslint-disable-next-line array-callback-return
      modules.map((item) => {
        if (item.shortName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0) {
          arr1.push(item);
        } else arr2.push(item);
      });
      if (arr1.length > 0) arr = arr1;
      else arr = arr2;
      setDataModule(arr);
    },
    [modules]
  );

  return (
    <Container>
      <MenuContainer className="full">
        <Row gutter={16} className="full mg-unset" justify="space-between">
          <Col span={10}>
            <div className="flex-column full">
              <div className="logo-evn-hn">
                <img src={LogoEVNHN} alt="logo" />
              </div>
              <div className="profile-container">
                <div className="content-wrapper">
                  <div className="avatar">
                    <Image
                      preview={false}
                      src={avatar ? avatar : Avatar}
                      alt="avatar"
                      width={96}
                      height={96}
                      onError={(e) => {
                        e.currentTarget.src = Avatar;
                      }}
                    />
                  </div>
                  <div className="profile">
                    <span className="profile_name">{user.name || ""}</span>
                    <div className="profile_content">
                      <PhoneIcon />
                      <span>{user.phoneNumber || ""}</span>
                    </div>
                    <div className="profile_content">
                      <BuildingIcon />
                      <span>{`${user.teamName ? `${user.teamName} - ` : ""}${user.unitName || ""}`}</span>
                    </div>
                  </div>
                </div>
                <div className="logout-home" onClick={handleSignOut}>
                  <p>Đăng xuất</p>
                  <img src={LogoutIcon} alt="logout" />
                </div>
              </div>
            </div>
          </Col>
          <Col span={14}>
            <Scrollbars autoHide className="scroll-bars">
              <div
                style={{
                  display: "flex",
                  padding: "0 10px",
                  alignItems: "center",
                  paddingBottom: "20px",
                }}
              >
                <div style={{ width: "51%" }}></div>
                <div style={{ width: "49%" }}>
                  <Input onChange={handleChange} allowClear placeholder="Tìm kiếm Modules" />
                </div>
              </div>
              <div style={{ padding: "0px 10px" }}>
                <Spin spinning={loading}>
                  <Row gutter={8}>
                    {dataModule && dataModule.length ? (
                      dataModule.map((item, idx) => {
                        if (item.shortName) {
                          if (
                            (user.permission &&
                              user.permission.length &&
                              user.permission.find((i) => i.charAt(0) === item.moduleCode)) ||
                            user.isAdministrator
                          ) {
                            return (
                              <Col span={12} key={idx}>
                                <SystemItem item={item} />
                              </Col>
                            );
                          }
                          return null;
                        }
                        return null;
                      })
                    ) : (
                      <div style={{ height: "300px" }}></div>
                    )}
                  </Row>
                </Spin>
              </div>
            </Scrollbars>
          </Col>
        </Row>
      </MenuContainer>
    </Container>
  );
}
const Container = styled.div`
  height: 100vh;
  padding: 45px;
  background: linear-gradient(180deg, #1f61b6 0%, #5698ef 100%);
`;
const MenuContainer = styled.div`
  display: flex;
  background: #f5f5f5;
  box-shadow: 0px 12px 22px -6px rgba(0, 21, 71, 0.2), 0px 14px 64px -4px rgba(91, 92, 96, 0.2);
  border-radius: 8px;
  padding: 20px;
  .logo-evn-hn {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .profile-container {
    display: flex;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    .logout {
      text-align: center;
      color: #ff5247;
      :hover {
        cursor: pointer;
      }
      > span {
        margin-right: 10px;
      }
    }
    .content-wrapper {
      display: flex;
    }
    .avatar {
      width: 96px;
      height: 96px;
      border-radius: 8px;
      margin-right: 15px;
    }
    .profile_name {
      font-size: 24px;
      line-height: 32px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .profile_content {
      display: flex;
      justify-content: left;
      align-items: center;
      margin: 10px 0px;
      > span {
        margin-left: 5px;
        font-size: 16px;
        line-height: 20px;
        color: #405cab;
      }
    }
  }
`;
