import React, { Fragment, useCallback, useEffect, useState } from "react";
import { authGetData, authPostData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { Table, Row, Col, ConfigProvider, Checkbox, message } from "antd";
import ButtonComponent from "@components/Button";
import vn from "antd/es/locale-provider/vi_VN";
import { DEFAULT_PAGEINDEX, STATUSCODE_200 } from "@utils/constants";
import {
  buildQueryString,
  customColumn,
  handlePagination,
  getSelectedRowKeys,
} from "@utils/function";
export default function DataDecentralizationForm({
  user,
  getUserList,
  activeKey,
  getUserDetail,
}) {
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [userInfo] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: 999,
  });
  const [isAddAllTeam, setIsAddAllTeam] = useState(false);

  const getTeamByUnit = useCallback(() => {
    authGetData({
      url: `${Endpoint.USER}/user-detail?id=${user ? user.id : userInfo.id}`,
      setLoading,
      onSuccess: (res) => {
        setIsAddAllTeam(res.data.isAllTeamPermission);
      },
    });
    if (user.id) {
      authGetData({
        url: `${Endpoint.USER_CLAIM}/team-paging?unitId=${user.unitId}&userId=${
          user.id
        }&${buildQueryString(filterConditions)}`,
        setLoading,
        onSuccess: (res) => {
          if (res.data && res.data.length) {
            getUserList();
            setDataSource(res.data);
            setSelectedRowKeys(getSelectedRowKeys(res.data));
          }
        },
      });
    }
  }, [user, getUserList, filterConditions, userInfo]);
  useEffect(() => {
    if (activeKey === "3") {
      getTeamByUnit();
    } else {
      setSelectedRowKeys([]);
      setDataSource([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey]);
  useEffect(() => {
    if (user && user.isAllTeamPermission) {
      setIsAddAllTeam(user.isAllTeamPermission);
    } else {
      setIsAddAllTeam(false);
    }
  }, [user, user.isAllTeamPermission, setIsAddAllTeam]);

  const columns = [
    {
      title: "Tên đội",
      dataIndex: "name",
    },
  ];
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: isAddAllTeam
      ? dataSource.map((item) => item.id)
      : selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: isAddAllTeam || user.teamId === record.id,
    }),
  };

  const save = useCallback(() => {
    if (user && user.id) {
      authPostData({
        url: `${Endpoint.USER_CLAIM}`,
        method: "PUT",
        payload: {
          userId: user.id,
          teamId: selectedRowKeys,
          isAddAllTeam,
        },
        setLoading,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            getTeamByUnit();
            getUserDetail();
          } else {
            if (res.data && res.data.teamId) {
              message.config({
                duration: 0.5,
                maxCount: 1,
              });
              message.error(res.data.teamId);
            }
          }
        },
      });
    }
  }, [selectedRowKeys, user, getTeamByUnit, isAddAllTeam, getUserDetail]);
  const handleCheckAssignAll = useCallback(
    (event) => {
      const { checked } = event.target;
      setIsAddAllTeam(checked);
      if (!checked) {
        if (user.teamId) {
          setSelectedRowKeys([user.teamId]);
        } else {
          setSelectedRowKeys([]);
        }
      }
    },
    [user]
  );
  return (
    <Fragment>
      <div className="mb-15px">
        <span className="mr-5px">Đơn vị quản lý: </span>
        <b>{user.unitName}</b>
      </div>
      <div className="mb-15px">
        <span className="mr-5px">Tất cả Tổ/Đội: </span>
        <Checkbox onChange={handleCheckAssignAll} checked={isAddAllTeam} />
      </div>
      <Row className="mb-15px" gutter={6}>
        <Col span={3}>
          <ButtonComponent
            type="primary"
            className="full"
            disabled={loading}
            onClick={save}
          >
            Lưu
          </ButtonComponent>
        </Col>
      </Row>
      <ConfigProvider locale={vn}>
        <Table
          loading={loading}
          columns={customColumn(columns, filterConditions)}
          dataSource={dataSource}
          rowKey={(record) => record.id}
          onChange={onChangePagination}
          rowSelection={rowSelection}
          pagination={false}
        />
      </ConfigProvider>
    </Fragment>
  );
}
