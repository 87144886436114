import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";

export const getMenuListToCustom = (moduleId, setLoading, setDataSource) => {
  authGetData({
    url: `${Endpoint.MENU}?moduleId=${moduleId}`,
    setLoading,
    onSuccess: (res) => {
      setDataSource(res.data);
    },
  });
};
