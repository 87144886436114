import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import { Endpoint } from "@utils/endpoint";
import { Button, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useCallback, useState } from "react";
import styled from "styled-components";
import Bieu2ChiTietslTinNhanTheoTungCty from "./bieu2-chi-tiet-sl-tin-nhan-theo-tung-cty";
import { exportData, handleDownload, selectBCDSKH } from "./service";

export default function Bieu1ThslTinNhanToanTongCty() {
  const [setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [screen, setScreen] = useState();

  const optionValue = [
    {
      label: "Biểu 1: Tổng hợp số lượng tin nhắn của toàn Tổng công ty",
    },
  ];

  const onFinish = (values) => {
    const dataRequest = {
      ...values,
      reportType: Endpoint.BCDSKH_THSL_TIN_NHAN_CUA_TOAN_TONG_CONG_TY,
    };
    exportData(dataRequest, setLoading, setData);
  };

  const handleChange = useCallback((values) => {
    setScreen(values);
  }, []);

  return (
    <Fragment>
      {screen === Endpoint.BCDSKH_THSL_TIN_NHAN_CUA_TOAN_TONG_CONG_TY ? (
        <Bieu1ThslTinNhanToanTongCty
          screen={screen}
          handleChange={handleChange}
        />
      ) : screen ===
        Endpoint.BCDSKH_CTSL_TIN_NHAN_THEO_TUNG_CONG_TY_DIEN_LUC ? (
        <Bieu2ChiTietslTinNhanTheoTungCty
          screen={screen}
          handleChange={handleChange}
        />
      ) : (
        <Container>
          <ContentWrapper>
            <Spin spinning={loading}>
              <FormComponent
                onFinish={onFinish}
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
              >
                <Form.Item
                  name="reportType"
                  label="Tên báo cáo"
                  labelAlign="left"
                >
                  <Select
                    options={selectBCDSKH}
                    formKey="reportType"
                    form={form}
                    defaultValue={optionValue}
                    onChange={() => {
                      handleChange(form.getFieldValue("reportType"));
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="reportDate"
                  label="Tháng năm"
                  labelAlign="left"
                >
                  <DatePickerComponent
                    picker="month"
                    formKey="reportDate"
                    form={form}
                  />
                </Form.Item>
                <Row justify="end">
                  <Button
                    type="primary"
                    onClick={() => {
                      handleDownload(
                        {
                          ...form.getFieldValue(),
                          reportType:
                            Endpoint.BCDSKH_THSL_TIN_NHAN_CUA_TOAN_TONG_CONG_TY,
                        },
                        setLoading
                      );
                    }}
                  >
                    Xuất Excel
                  </Button>
                </Row>
              </FormComponent>
              <Divider
                className="section-text-line"
                orientation="left"
              ></Divider>
            </Spin>
          </ContentWrapper>
        </Container>
      )}
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
