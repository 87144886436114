import { authGetMultiplesRequest, authPostData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { alertMessage } from "@utils/function";


export const handleOpenXemLaiPACDService = (
  url,
  setDataPAXemLai,
  setVisibleXemLaiPhuongAnCapDien,
  visibleXemLaiPhuongAnCapDien,
  detail
) => {
const endpoints = [
    url,
    `${Endpoint.KS_TONG_CONG_SUAT}?HoSoId=${detail?.hoSoId ?? detail?.id}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading: () => false,
    onSuccess: (res) => {
      if (res[0]?.data !== null && res[1]?.data !== null) {
        setDataPAXemLai({ ...res[0].data.data, ...res[1].data.data });
        setVisibleXemLaiPhuongAnCapDien(!visibleXemLaiPhuongAnCapDien);
      }
    },
  });
};
// từ chối quyết toán

export const handleTuChoiQuyetToan = (
  hoSoId,
  setLoading,
  closeModal,
  setFetchDataTable
) => {
  authPostData({
    url: Endpoint.TU_CHOI_QUYET_TOAN,
    method: METHOD_POST,
    setLoading,
    payload: hoSoId,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        closeModal();
      } else {
        alertMessage("error", "Thông báo", res.message);
      }
    },
  });
};
// xác nhận quyết toán

export const handleXacNhanQuyetToan = (
  hoSoId,
  setLoading,
  closeModal,
  setFetchDataTable
) => {
  authPostData({
    url: Endpoint.XAC_NHAN_QUYET_TOAN,
    method: METHOD_POST,
    setLoading,
    payload: hoSoId,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        closeModal();
      } else {
        alertMessage("error", "Thông báo", res.message);
      }
    },
  });
};
