import { TYPE_INPDF_GNDN } from "@utils/constants";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import FormBaoCao from "../common-bao-cao";

export default function Index() {
  const isTongCongTy = useSelector((state) => state.auth.user.isTongCongTy);

  return (
    <Fragment>
      <FormBaoCao
        typeInPDF={TYPE_INPDF_GNDN.DIEMDO_BIENDONG_THANG}
        disableLuyKe={true}
        disableLuyKeQuy={true}
        disableLuyKeNam={true}
        disableTram={true}
        allDVGiaoNhan={!isTongCongTy}
        checkTableAll={isTongCongTy}
        validate={isTongCongTy}
      />
    </Fragment>
  );
}
