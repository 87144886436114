import { ENUM_FUNCTION } from "@utils/constants";
import {
  authGetData,
  authGetMultiplesRequest,
  // authPostData,
} from "@utils/request";
import { Endpoint } from "@utils/endpoint";

export const handleListTroNgai = (setLoading, setDataTroNgai) => {
  authGetData({
    url: `${Endpoint.XU_LY_LIST_TRO_NGAI}?funcId=${ENUM_FUNCTION.HSDANGTREOTHAO}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) setDataTroNgai(res.data);
    },
  });
};

// // call api xác nhận khôi phục thi công

// export const handleKhoiPhucThiCong = (
//   t,
//   setLoading,
//   handleCloseModal,
//   setFetchDataTable,
//   hosoId
// ) => {
//   const data = {
//     hosoId,
//   };
//   authPostData({
//     url: Endpoint.KHOI_PHUC_THI_CONG,
//     setLoading,
//     payload: data,
//     method: METHOD_PUT,
//     onSuccess: (res) => {
//       if (res.statusCode === STATUSCODE_200) {
//         setFetchDataTable(true);
//         handleCloseModal();
//       }
//     },
//   });
// };

export const handleXemLaiPhuongAn = (
  detail,
  setDataPAXemLai,
  setVisibleXemLaiPhuongAnCapDien,
  visibleXemLaiPhuongAnCapDien
) => {
  const endpoints = [
    `${Endpoint.KS_GET_PHUONG_AN_CAP_DIEN}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    `${Endpoint.KS_TONG_CONG_SUAT}?HoSoId=${detail?.hoSoId ?? detail?.id}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading: () => false,
    onSuccess: (res) => {
      if (res[0]?.data !== null && res[1]?.data !== null) {
        setDataPAXemLai({ ...res[0].data.data, ...res[1].data.data });
        setVisibleXemLaiPhuongAnCapDien(!visibleXemLaiPhuongAnCapDien);
      }
    },
  });
};
