import { Tabs, Upload } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;

  tr {
    cursor: pointer;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
  }
`;

export const Style = styled.div`
  .tai-anh-ho-so-tab {
    .ant-row.ant-form-item {
      padding: 0 !important;
    }
  }
  .tai-anh-ho-so-container > div {
    align-items: flex-start;
  }
  .danh-sach-anh {
    margin-top: 20px;
    .ant-divider-inner-text {
      font-size: 20px;
      font-weight: 550;
    }
    .danh-sach-anh-list {
      padding-bottom: 20px;
    }
  }
`;

export const ContainerBody = styled.div`
  padding: 16px 24px;
`;

export const ContainerMultiUpload = styled(Upload)`
  .ant-upload.ant-upload-select {
    width: 100%;
  }
  button {
    height: 100px;
    width: 100%;
    background: rgb(250, 250, 250);
    border: 1px dashed rgb(217, 217, 217);
    text-align: center;
  }

  .upload-container {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .upload-choice {
      font-size: 16px;
    }
  }
`;

export const ContainerChoice = styled.div`
  display: flex;
  align-items: center;
  // position: fixed;
  // top: 153px;
  // box-shadow: 5px 5px rgb(0 0 0 / 15%);
  // z-index: 99999;
  // background: #ffffff;
  width: 100%;
  .text {
    width: 20%;
  }
  .choice {
    width: 30%;
  }
`;

export const TabsListContainer = styled(Tabs)`
  margin-left: -12px;
  margin-right: -12px;
  .ant-tabs-nav {
    position: fixed;
    top: 153px;
    box-shadow: 5px 5px rgb(0 0 0 / 15%);
    border: 1px solid #f0f0f0;
    z-index: 99999;
    background: #ffffff;
    width: 100%;
  }
`;
