import { memo, useEffect, useRef, useState } from "react";
import { Form, Input, Button, Modal, Row, Col, Checkbox, Select } from "antd";
import { useTranslation } from "react-i18next";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { handleSubmitForm } from "./services";
import { OS, SPECIAL_CHARACTERS } from "@utils/constants";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const { Option } = Select;

function CauHinhChungModal({
  detaiDataTable,
  visible,
  closeModal,
  getListData,
  dataOs,
}) {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
      [""],
      [""],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];
  const handleSubmitData = (values) => {
    handleSubmitForm(
      values,
      detaiDataTable,
      setLoading,
      closeModal,
      getListData,
      form
    );
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      os: detaiDataTable.os === OS.ANDROID ? OS.ANDROID_NUM : OS.IOS_NUM,
    });
    // eslint-disable-next-line
  }, [form]);

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={detaiDataTable.id ? "Cập nhật vesion" : "Tạo version"}
        visible={visible}
        onCancel={closeModal}
        className="modal-version"
        footer={null}
      >
        <FormComponent
          form={form}
          name="form-version"
          layout="vertical"
          onFinish={handleSubmitData}
          initialValues={{
            ...detaiDataTable,
            moTa: detaiDataTable.id ? detaiDataTable.moTa : "",
          }}
          scrollToFirstError
          autoComplete="off"
        >
          <Form.Item label={"Version number"} name="versionNumber">
            <Input
              ref={inputRef}
              placeholder="Nhập version"
              onKeyDown={(e) =>
                SPECIAL_CHARACTERS.includes(e.key) && e.preventDefault()
              }
            />
          </Form.Item>
          <Form.Item label={"Build number"} name="buildNumber">
            <Input
              placeholder="Nhập số build"
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
            />
          </Form.Item>
          <Form.Item label={"Os"} name="os">
            <Select
              placeholder="--Chọn--"
              disabled={detaiDataTable.id ? true : false}
            >
              {dataOs && dataOs.length > 0
                ? dataOs.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="" name="updateRequired" valuePropName="checked">
                <Checkbox>Update Required</Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="" name="isActive" valuePropName="checked">
                <Checkbox>Hoạt động</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} style={{ height: 200, justifyContent: "center" }}>
            <Form.Item
              label={"Mô tả"}
              name="moTa"
              style={{ width: "100%", padding: "0px 10px" }}
            >
              <ReactQuill
                modules={modules}
                formats={formats}
                style={{ height: 120 }}
              />
            </Form.Item>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-version"
                loading={loading}
              >
                {detaiDataTable.id ? t("button.cap_nhat") : t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(CauHinhChungModal);
