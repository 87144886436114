import { authSelector } from "@state/auth";
import { Col, Form, Row, Select, Checkbox, DatePicker, Button, TreeSelect } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import FormComponent from "@components/Form";
import { ENUM_KY_GCS, ENUM_LOC_CONG_TO, ENUM_GCSTRANGTHAISO } from "@utils/constants";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import CheckPermission from "@components/CheckPermission";
import { ghi_chi_so } from "@permissions/d_gcs";
const { Option } = Select;
export function ListFilterDS(props) {
  const { handleSearch,
    filterConditions,
    filterSoGCS,
    dataDonVi,
    handleChangeDonVi,
    handleChangeCheckBox,
    checked,
    disableChecked,
    dataSoGCS,
    handleChangeTrangThai,
    handleChangeKyGCS,
    handleChangeThangNam,
    handleTuDongDS,
    handleHoanThanhDS,
    handleChangeSoGCS,
    permission,
    handleSelect,
    handleUnselect,
    selectedValues,
    listSelect } =
    props;
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  useEffect(() => {
    form.setFieldsValue(filterSoGCS);
  }, [form, filterSoGCS]);

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
      }}
    >
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="DonViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Select onChange={handleChangeDonVi}>
                {dataDonVi && dataDonVi.length > 0
                  ? (
                    dataDonVi.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                  )
                  : null}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={2}>
          <Form.Item name="Ky" label="Kỳ GCS">
            <Select defaultValue={ENUM_KY_GCS.KY_1} onChange={handleChangeKyGCS}>
              <Option value={ENUM_KY_GCS.KY_1}>1</Option>
              <Option value={ENUM_KY_GCS.KY_2}>2</Option>
              <Option value={ENUM_KY_GCS.KY_3}>3</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={3}>
          <Form.Item label="Tháng năm">
            <DatePicker
              locale={locale}
              format={"MM-YYYY"}
              defaultValue={moment()}
              picker="month"
              allowClear={false}
              onChange={handleChangeThangNam}
              placeholder={"Chọn ngày ghi"}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="LocCongTo" label="Lọc công tơ">
            <Select placeholder={"Tất cả"} allowClear>
              <Option value={ENUM_LOC_CONG_TO.CONG_TO_DAT}>Công tơ đạt</Option>
              <Option value={ENUM_LOC_CONG_TO.CONG_TO_KHONG_DAT}>
                Công tơ không đạt
              </Option>
              <Option value={ENUM_LOC_CONG_TO.CONG_TO_CHUA_DOI_SOAT}>
                Công tơ chưa đối soát
              </Option>
              <Option value={ENUM_LOC_CONG_TO.CONG_TO_CO_ANH}>
                Công tơ có ảnh
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item name="TrangThai" label="Trạng thái">
            <Select placeholder={"Tất cả"} allowClear onChange={handleChangeTrangThai}
              defaultValue={[ENUM_GCSTRANGTHAISO.MTB_DA_GUI_DU_LIEU].toString()}
            >
              {/* <Option value={[ENUM_GCSTRANGTHAISO.DA_CAP_NHAT_DU_MDMS,
              ENUM_GCSTRANGTHAISO.HHU_DA_GUI_DU_DL].toString()}>Đạt</Option> */}
              <Option value={[ENUM_GCSTRANGTHAISO.MTB_DA_GUI_DU_LIEU].toString()}>Chưa đối soát tự động</Option>
              <Option value={ENUM_GCSTRANGTHAISO.HOAN_TAT_DS_TU_DONG}>Đã đối soát tự động</Option>
              <Option value={[ENUM_GCSTRANGTHAISO.DANG_XAC_NHAN_CAC_CAP].toString()}>Đã hoàn thành đối soát</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={4} style={{ marginTop: 34 }}>
          <Form.Item name="check" label="">
            <Checkbox onChange={handleChangeCheckBox} checked={checked} disabled={disableChecked}>Sổ GCS bị trả lại</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24} md={8}>
          <span>Sổ GCS</span> <span style={{ color: "red" }}>*</span>
          <TreeSelect
            allowClear={true}
            treeCheckable={true}
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            style={{ width: "400px", marginTop: "5px" }}
            dropdownStyle={{ maxHeight: "300px" }}
            onChange={handleChangeSoGCS}
            value={selectedValues}
            maxTagCount={3}
            maxTagPlaceholder={omittedValues =>
              `+ ${omittedValues.length} ...`
            }
            showSearch
            filterTreeNode
            treeNodeFilterProp="title"
            treeDefaultExpandAll
            treeData={dataSoGCS?.length > 0 ?
              [
                {
                  title: (
                    <React.Fragment>
                      {selectedValues?.length === dataSoGCS.length && (
                        <span
                          style={{
                            display: "inline-block",
                            color: "#ccc",
                            cursor: "not-allowed"
                          }}
                        >
                          Chọn tất cả
                        </span>
                      )}
                      {selectedValues?.length < dataSoGCS.length && (
                        <span
                          onClick={handleSelect}
                          style={{
                            display: "inline-block",
                            color: "#286FBE",
                            cursor: "pointer"
                          }}
                        >
                          Chọn tất cả
                        </span>
                      )}
                      &nbsp;&nbsp;&nbsp;
                      {selectedValues?.length === 0 && (
                        <span
                          style={{
                            display: "inline-block",
                            color: "#ccc",
                            cursor: "not-allowed"
                          }}
                        >
                          Bỏ chọn tất cả
                        </span>
                      )}
                      {selectedValues?.length > 0 && (
                        <span
                          onClick={handleUnselect}
                          style={{
                            display: "inline-block",
                            color: "#286FBE",
                            cursor: "pointer"
                          }}
                        >
                          Bỏ chọn tất cả
                        </span>
                      )}
                    </React.Fragment>
                  ),
                  value: "xxx",
                  disableCheckbox: true,
                  disabled: true
                },
                ...listSelect
              ] : undefined}
          />
          {/* </Form.Item> */}
        </Col>
      </Row>
      <Row justify="end">
        <Button
          className="mr-24px"
          type="primary"
          htmlType="submit"
          form="filter-form"
        >
          Tìm kiếm
        </Button>
        <CheckPermission permissionCode={
          permission === ghi_chi_so.d15 ? ghi_chi_so.d15td :
            (permission === ghi_chi_so.d22 ? ghi_chi_so.d22td : ghi_chi_so.d32td)}>
          <Button
            className="mr-24px"
            type="primary"
            form="filter-form"
            onClick={() => handleTuDongDS()}
          >
            Tự động đối soát
          </Button>
        </CheckPermission>
        <CheckPermission permissionCode={
          permission === ghi_chi_so.d15 ? ghi_chi_so.d15ht :
            (permission === ghi_chi_so.d22 ? ghi_chi_so.d22ht : ghi_chi_so.d32ht)}>
          <Button
            type="primary"
            form="filter-form"
            style={{ backgroundColor: "orange", borderColor: "orange" }}
            onClick={() => handleHoanThanhDS()}
          >
            Hoàn thành đối soát
          </Button>
        </CheckPermission>
      </Row>
    </FormComponent>
  );
}
