import { Button, Col, Modal, Row } from "antd";
import { Container } from "./css-styled";

export default function ConfirmModal(props) {
  const { loading, visible, closeModal, handleSubmit } = props;
  return (
    <Modal
      title={"Làm mới yêu cầu"}
      visible={visible}
      onCancel={closeModal}
      footer={null}
    >
      <Container>
        <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
          Bạn chắc chắn muốn làm mới các yêu cầu đã chọn ?
        </h4>
        <Row gutter={24} justify="center" style={{ marginTop: "48px" }}>
          <Col span={12} style={{ textAlign: "right" }}>
            <Button key="back" onClick={closeModal} className="button-closed">
              Đóng lại
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              className="btn-bg-yellow"
              loading={loading}
              onClick={handleSubmit}
              key="confirm"
            >
              Xác nhận
            </Button>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}
