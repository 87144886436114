import { authSelector } from "@state/auth";
import {
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
// import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  alertMessage,
  buildQueryString,
  // currencyFormatVND,
  handlePagination,
  // handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { downloadFile } from "@utils/request";
// import { Table } from "antd";
// import Text from "antd/lib/typography/Text";
import moment from "moment";

import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { handleListBaoCao } from "./services";
import BCTongHopChiPhiQuyetToan from "./table";

export default function Index() {
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [vat, setVAT] = useState("");
  const { user } = useSelector(authSelector);
  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);
  //filter
  const [filterConditions, setFilterConditions] = useState({
    // pageIndex: DEFAULT_PAGEINDEX,
    // pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    donViId: user.unitId,
    startDateBaoCao: moment(moment().startOf(MONTH_STRING)).format(
      FORMAT_ENGLISH
    ),
    endDateBaoCao: moment().format(FORMAT_ENGLISH),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  // //change pagination
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  };

  //fetch báo cáo
  const getListBaoCao = useCallback(() => {
    handleListBaoCao(
      setLoading,
      setData,
      setTotalCount,
      filterConditions,
      setVAT
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const [filterExcel, setFilterExcel] = useState(false);

  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type]);

  //search
  const handleSearch = useCallback(
    (values) => {
      if (values.startDateBaoCao <= values.endDateBaoCao) {
        setFilterConditions(() => ({
          ...values,
        }));
        handleAPI();
      } else {
        alertMessage("error", "Thông báo", "Khoảng ngày báo cáo không hợp lệ");
      }
    },
    [handleAPI]
  );

  //clear search
  const clearFilter = useCallback(() => {
    setFilterConditions({
      // pageIndex: DEFAULT_PAGEINDEX,
      // pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
      startDateBaoCao: moment(moment().startOf(MONTH_STRING)).format(
        FORMAT_ENGLISH
      ),
      endDateBaoCao: moment().format(FORMAT_ENGLISH),
    });
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  }, [user.unitId]);

  useEffect(() => {
    if (filterExcel) {
      const endpoint = `${
        Endpoint.EXPORT_EXCEL_BAO_CAO_TONG_HOP_CHI_PHI_QUYET_TOAN
      }?${buildQueryString(parseParams(filterConditions))}`;
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel]);

  useEffect(() => {
    if (type === ENUM_BUTTON_TYPE.TIM_KIEM) getListBaoCao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListBaoCao]);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "4%",
      fixed: true,
    },
    {
      title: "Đơn vị",
      dataIndex: "maDonVi",
      key: "maDonVi",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "7%",
      fixed: true,
      sorter: true,
    },
    {
      title: "Tổng số hồ sơ",
      dataIndex: "tongHoSo",
      key: "tongHoSo",
      rowSpan: 2,
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
      fixed: true,
      sorter: true,
    },
    {
      title: "Chi phí cáp trước công tơ",
      children: [
        {
          title: "Vật liệu",
          dataIndex: "vatLieuVTCT",
          key: "vatLieuVTCT",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
          sorter: true,
        },
        {
          title: "Nhân công",
          dataIndex: "nhanCongVTCT",
          key: "nhanCongVTCT",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
          sorter: true,
        },
        {
          title: "MTC",
          dataIndex: "mtcVTCT",
          key: "mtcVTCT",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "4%",
          sorter: true,
        },
        {
          title: "Chi phí khác",
          dataIndex: "chiPhiKhacVTCT",
          key: "chiPhiKhacVTCT",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "7%",
          sorter: true,
        },
        {
          title: "Tổng chi phí cáp trước (chưa VAT)",
          dataIndex: "tongChiPhiCapTruocVTCT",
          key: "tongChiPhiCapTruocVTCT",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "7%",
          sorter: true,
        },
        {
          title: "Tổng chi phí cáp trước (có VAT)",
          dataIndex: "tongChiPhiCapTruocVATVTCT",
          key: "tongChiPhiCapTruocVATVTCT",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "7%",
          sorter: true,
        },
      ],
    },
    {
      title: "Chi phí cáp sau công tơ",
      children: [
        {
          title: "Vật liệu",
          dataIndex: "vatLieuVTCS",
          key: "vatLieuVTCS",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
          sorter: true,
        },
        {
          title: "Nhân công",
          dataIndex: "nhanCongVTCS",
          key: "nhanCongVTCS",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
          sorter: true,
        },
        {
          title: "MTC",
          dataIndex: "mtcVTCS",
          key: "mtcVTCS",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "4%",
          sorter: true,
        },
        {
          title: "Chi phí khác",
          dataIndex: "chiPhiKhacVTCS",
          key: "chiPhiKhacVTCS",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "7%",
          sorter: true,
        },
        {
          title: "Tổng chi phí cáp sau (chưa VAT)",
          dataIndex: "tongChiPhiCapTruocVTCS",
          key: "tongChiPhiCapTruocVTCS",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "7%",
          sorter: true,
        },
        {
          title: "Tổng chi phí cáp sau (có VAT)",
          dataIndex: "tongChiPhiCapTruocVATVTCS",
          key: "tongChiPhiCapTruocVATVTCS",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "7%",
          sorter: true,
        },
      ],
    },
    {
      title: "Tổng chi phí thực hiện cấp mới",
      dataIndex: "tongChiPhiThucHien",
      key: "tongChiPhiThucHien",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "7%",
      sorter: true,
    },
  ];

  // const countTotals = (pageData) => {
  //   let countDonVi = 0;
  //   let countTongHoSo = 0;
  //   let countVatLieuVTCT = 0;
  //   let countNhanCongVTCT = 0;
  //   let countMtcVTCT = 0;
  //   let countChiPhiKhacVTCT = 0;
  //   // let countTongChiPhiCapTruocVTCT = 0;
  //   // let countTongChiPhiCapTruocVATVTCT = 0;
  //   let countVatLieuVTCS = 0;
  //   let countNhanCongVTCS = 0;
  //   let countMtcVTCS = 0;
  //   let countChiPhiKhacVTCS = 0;
  //   // let countTongChiPhiCapTruocVTCS = 0;
  //   // let countTongChiPhiCapTruocVATVTCS = 0;
  //   // let countTongChiPhiThucHien = 0;

  //   pageData.forEach(
  //     ({
  //       tongHoSo,
  //       vatLieuVTCTDec,
  //       nhanCongVTCTDec,
  //       mtcVTCTDec,
  //       chiPhiKhacVTCTDec,
  //       // tongChiPhiCapTruocVTCT,
  //       // tongChiPhiCapTruocVATVTCT,
  //       vatLieuVTCSDec,
  //       nhanCongVTCSDec,
  //       mtcVTCSDec,
  //       chiPhiKhacVTCSDec,
  //       // tongChiPhiCapTruocVTCS,
  //       // tongChiPhiCapTruocVATVTCS,
  //       // tongChiPhiThucHien,
  //     }) => {
  //       countDonVi += 1;
  //       countTongHoSo += parseInt(tongHoSo);
  //       countVatLieuVTCT += vatLieuVTCTDec;
  //       countNhanCongVTCT += nhanCongVTCTDec;
  //       countMtcVTCT += mtcVTCTDec;
  //       countChiPhiKhacVTCT += chiPhiKhacVTCTDec;
  //       // countTongChiPhiCapTruocVTCT += tongChiPhiCapTruocVTCT);
  //       // countTongChiPhiCapTruocVATVTCT += tongChiPhiCapTruocVATVTCT);
  //       countVatLieuVTCS += vatLieuVTCSDec;
  //       countNhanCongVTCS += nhanCongVTCSDec;
  //       countMtcVTCS += mtcVTCSDec;
  //       countChiPhiKhacVTCS += chiPhiKhacVTCSDec;
  //       // countTongChiPhiCapTruocVTCS += tongChiPhiCapTruocVTCS);
  //       // countTongChiPhiCapTruocVATVTCS += tongChiPhiCapTruocVATVTCS);
  //       // countTongChiPhiThucHien += tongChiPhiThucHien);
  //     }
  //   );
  //   return (
  //     <Table.Summary fixed>
  //       <Table.Summary.Row style={{ background: "#FFEAEA" }}>
  //         <Table.Summary.Cell align="center" colSpan={1} index={0}>
  //           <Text className="font-bold">Tổng cộng</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={1} className="baocaoTongHop">
  //           <Text className="font-bold">{countDonVi}</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={2} className="baocaoTongHop">
  //           <Text className="font-bold">{countTongHoSo}</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={3}>
  //           <Text className="font-bold">
  //             {currencyFormatVND(countVatLieuVTCT)}
  //           </Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={4}>
  //           <Text className="font-bold">
  //             {currencyFormatVND(countNhanCongVTCT)}
  //           </Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={5}>
  //           <Text className="font-bold">{currencyFormatVND(countMtcVTCT)}</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={6}>
  //           <Text className="font-bold">
  //             {currencyFormatVND(countChiPhiKhacVTCT)}
  //           </Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={7}>
  //           <Text className="font-bold">
  //             {currencyFormatVND(countVatLieuVTCT - countChiPhiKhacVTCT)}
  //           </Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={8}>
  //           <Text className="font-bold">
  //             {currencyFormatVND(
  //               (countVatLieuVTCT - countChiPhiKhacVTCT) * 110
  //             )}
  //           </Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={9}>
  //           <Text className="font-bold">
  //             {currencyFormatVND(countVatLieuVTCS)}
  //           </Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={10}>
  //           <Text className="font-bold">
  //             {currencyFormatVND(countNhanCongVTCS)}
  //           </Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={11}>
  //           <Text className="font-bold">{currencyFormatVND(countMtcVTCS)}</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={12}>
  //           <Text className="font-bold">
  //             {currencyFormatVND(countChiPhiKhacVTCS)}
  //           </Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={13}>
  //           <Text className="font-bold">
  //             {currencyFormatVND(countVatLieuVTCS - countChiPhiKhacVTCS)}
  //           </Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={14}>
  //           <Text className="font-bold">
  //             {currencyFormatVND(
  //               (countVatLieuVTCS - countChiPhiKhacVTCS) * 110
  //             )}
  //           </Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={15}>
  //           <Text className="font-bold">
  //             {currencyFormatVND(
  //               (countVatLieuVTCT - countChiPhiKhacVTCT) * 110 +
  //                 (countVatLieuVTCS - countChiPhiKhacVTCS) * 110
  //             )}
  //           </Text>
  //         </Table.Summary.Cell>
  //       </Table.Summary.Row>
  //     </Table.Summary>
  //   );
  // };
  return (
    <BCTongHopChiPhiQuyetToan
      handleSearch={handleSearch}
      clearFilter={clearFilter}
      filterConditions={filterConditions}
      totalCount={totalCount}
      loading={loading}
      scrollX={2000}
      scrollY={500}
      columns={columns}
      data={data}
      // countTotals={countTotals}
      onChangePagination={onChangePagination}
      vat={vat}
      setType={setType}
    />
  );
}
