import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

import CauHinhHoSoModal from "./modal-cau-hinh-ho-so";
import PencilIcon from "@assets/icon/PencilIcon";
import CheckPermission from "@components/CheckPermission";
import { handleListData } from "./services";
import Table from "./table";
import { cap_dien } from "@permissions/a_capdien";
import { useLocation, useSearchParams } from "react-router-dom";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";

export default function Index() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = useState({});
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [, setSearchParams] = useSearchParams();
  const location = useLocation();

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //thay đổi pagination table

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //khai bái columns

  const columns = [
    {
      title: "Tên cấu hình",
      dataIndex: "tenCauHinh",
      key: "tenCauHinh",
      width: "35%",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    // {
    //   title: "Giá trị",
    //   dataIndex: "giaTri",
    //   key: "giaTri",
    //   width: "15%",
    //   render: (text) => (
    //     <div className="table-text-right">
    //       <span>{text}</span>
    //     </div>
    //   ),
    //   sorter: true,
    // },
    {
      title: "Đơn vị tính",
      dataIndex: "donViTinh",
      key: "donViTinh",
      width: "15%",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Ghi chú",
      dataIndex: "ghiChu",
      key: "ghiChu",
      width: "40%",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Tác vụ",
      key: "",
      dataIndex: "",
      // width: "10%",
      render: (row) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={cap_dien.a70_sua}>
            <div className="action-icon" onClick={() => handleOpenModal(row)}>
              <PencilIcon />
            </div>
          </CheckPermission>
        </div>
      ),
    },
  ];

  //fetch cấu hình chung

  const getListData = useCallback(() => {
    handleListData(filterConditions, setLoading, setData, setTotalCount);
  }, [filterConditions]);

  useEffect(() => {
    getListData();
  }, [getListData]);

  //hiển thị popup, set dữ liệu chi tiết

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDetail(row);
      else setDetail({});
      setVisible(!visible);
    },
    [visible]
  );
  //đóng popup

  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
  };
  //render popup

  const showModal = useCallback(
    () =>
      visible && (
        <CauHinhHoSoModal
          detail={detail}
          visible={visible}
          closeModal={handleCloseModal}
          getListData={getListData}
        />
      ),
    [detail, getListData, visible]
  );

  return (
    <Fragment>
      <Table
        dataSource={data}
        columns={columns}
        totalCount={totalCount}
        loading={loading}
        filterConditions={filterConditions}
        onChangePagination={onChangePagination}
      />
      {showModal()}
    </Fragment>
  );
}
