import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tag, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import { closeModal, openModal } from "@state/system-config/reducer";
// import ThongTinChiTietTabs from "../common-ho-so/chi-tiet-ho-so";
import {
  InfoCircleOutlined,
  // RetweetOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import Table from "./table";
import { handleListData } from "./services";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_FUNCTION,
  FORMAT_ENGLISH,
} from "@utils/constants";
import { closeModal, openModal } from "@state/system-config/reducer";
import ThongTinChiTietTabs from "../common-ho-so/chi-tiet-ho-so";
import { redirectByMaTrangThai } from "./function";
import moment from "moment";

export default function Index(props) {
  const {
    firstColumns,
    onRow,
    fetchDataTableIfNeed,
    setFetchDataTable,
    rowSelection,
    funcID,
    // condition,
    typeAPI,
    renderExtraAction,
    listFilter,
    urlXuatExcel,
    permissionExcel,
    rowKey,
    rowId
  } = props;
  const { t } = useTranslation();
  const { user, menuList } = useSelector(authSelector);
  const [loading, setLoading] = useState(true);
  const [dataHoSo, setDataHoSo] = useState([]);
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  // const dispatch = useDispatch();
  const navigation = useNavigate();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    funcID:
      funcID !== null && funcID !== undefined ? funcID.toString() : undefined,
    ...parseParams(location.search),
    donViId: user.unitId,
  });
  const [isCanhBao, setIsCanhBao] = useState(false);

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const getListHoSo = useCallback(
    (hasMessage) => {
      handleListData(
        typeAPI,
        location,
        filterConditions,
        setLoading,
        setDataHoSo,
        setTotalCount,
        hasMessage
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );

  useEffect(() => {
    getListHoSo();
  }, [getListHoSo]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      let hasMessage = true;
      if (filterConditions.hasOwnProperty("searchTerm")) {
        hasMessage = false;
      }
      getListHoSo(hasMessage);
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, filterConditions, getListHoSo, setFetchDataTable]);

  const detailHoSo = (record) => {
    return dispatch(
      openModal({
        content: (
          <ThongTinChiTietTabs detailHoSo={record} closeModal={closeModal} />
        ),
        size: "large",
        title: `${record.maYCKNCmis !== null ? record.maYCKNCmis + " - " : ""}${
          record.tenKhachHang !== null ? record.tenKhachHang : ""
        }`,
      })
    );
  };

  const columns =
    funcID === ENUM_FUNCTION.XACNHANANTOAN
      ? [
          {
            title: "",
            dataIndex: "",
            key: "",
          },
          {
            title: t("ap_gia_ho_so.stt"),
            dataIndex: "stt",
            key: "stt",
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            width: "3%",
            sorter: false,
          },
          {
            title: t("ap_gia_ho_so.ngay_dang_ky"),
            dataIndex: "ngayDangKy",
            key: "ngayDangKy",
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            width: "5%",
            sorter: true,
          },
          {
            title: t("ap_gia_ho_so.loai_yeu_cau"),
            dataIndex: "tenLoaiYeuCau",
            key: "tenLoaiYeuCau",
            render: (text) => <span>{text}</span>,
            width: "7%",
            sorter: true,
          },
          {
            title: "Ngày treo tháo",
            dataIndex: "ngayTreoThao",
            key: "ngayTreoThao",
            render: (text) => (
              <div className="table-text-center ">
                <span>{text}</span>
              </div>
            ),
            width: "7%",
            sorter: true,
          },
          {
            title: "Tên NVTT",
            dataIndex: "nhanVienTreoThao",
            key: "nhanVienTreoThao",
            render: (text) => (
              <div className="table-text-left ">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
            sorter: true,
          },
          {
            title: "Tên NVNP",
            dataIndex: "nhanVienNiemPhong",
            key: "nhanVienNiemPhong",
            render: (text) => (
              <div className="table-text-left ">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
            sorter: true,
          },
          {
            title: t("ap_gia_ho_so.ma_yeu_cau"),
            dataIndex: "maYCKNCmis",
            key: "maYCKNCmis",
            render: (text, record) => (
              <div
                className="table-text-left ant-btn-link cursor-pointer"
                onClick={() => detailHoSo(record)}
              >
                <span>{text}</span>
              </div>
            ),
            width: "9%",
            sorter: true,
          },
          {
            title: t("ap_gia_ho_so.ten_khach_hang"),
            dataIndex: "tenKhachHang",
            key: "tenKhachHang",
            render: (text, record) => (
              <div
                className="table-text-left ant-btn-link cursor-pointer"
                onClick={() => detailHoSo(record)}
              >
                <span>{text}</span>
              </div>
            ),
            width: "11%",
            sorter: true,
          },
          {
            title: t("ap_gia_ho_so.so_cmt"),
            dataIndex: "cmnd",
            key: "cmnd",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "6%",
            sorter: true,
          },
          {
            title: t("ap_gia_ho_so.dien_thoai"),
            dataIndex: "dienThoai",
            key: "dienThoai",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "6%",
            sorter: true,
          },
          {
            title: t("ap_gia_ho_so.nguon_tiep_nhan"),
            dataIndex: "nguonTiepNhan",
            key: "nguonTiepNhan",
            render: (text) => <span>{text}</span>,
            width: "5%",
            sorter: true,
          },
          {
            title: t("ap_gia_ho_so.trang_thai"),
            dataIndex: "tenTrangThai",
            key: "tenTrangThai",
            className: "text-center",
            render: (text, record) => (
              <div
                style={
                  funcID !== ENUM_FUNCTION.TRACUUHOSO
                    ? { display: "flex", flexDirection: "column" }
                    : {
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                      }
                }
                onClick={() =>
                  funcID !== ENUM_FUNCTION.TRACUUHOSO
                    ? false
                    : handleClickTrangThai(record)
                }
              >
                <Tag color={record.mau}>{record.tenTrangThai}</Tag>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {record.traLaiKhaoSat ? (
                    <div
                      style={{
                        fontSize: "12px",
                        marginTop: "4px",
                        color: "#FF5247",
                        cursor: "pointer",
                      }}
                    >
                      <Tooltip title={record.noiDungTraHoSo}>
                        <InfoCircleOutlined /> Hồ sơ trả lại
                      </Tooltip>
                    </div>
                  ) : null}
                  {record?.flagCanhBao ? (
                    <div
                      style={{
                        marginTop: "4px",
                        color: "#FF5247",
                        cursor: "pointer",
                        marginLeft: record.traLaiKhaoSat ? "8px" : 0,
                      }}
                    >
                      <Tooltip title="Hồ sơ xử lý quá hạn">
                        <WarningOutlined />
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
                {funcID === ENUM_FUNCTION.XACNHANTHICONG && record.daLapBBLD && (
                  <div
                    style={{
                      fontSize: "12px",
                      marginTop: "4px",
                      color: "blue",
                      cursor: "pointer",
                      marginLeft:
                        record.traLaiKhaoSat || record?.flagCanhBao ? "8px" : 0,
                    }}
                  >
                    Đã lập biên bản
                  </div>
                )}
              </div>
            ),
            width: "11%",
          },
          {
            title: t("ap_gia_ho_so.so_pha"),
            dataIndex: "soPha",
            key: "soPha",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "5%",
            sorter: true,
          },
          {
            title: t("ap_gia_ho_so.so_ngay_lam_viec"),
            dataIndex: "snlvandtsn",
            key: "snlvandtsn",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "6%",
            sorter: true,
          },
          {
            title: t("ap_gia_ho_so.dia_chi"),
            dataIndex: "diaChiCapDien",
            key: "diaChiCapDien",
            render: (text) => <span>{text}</span>,
            width: "10%",
            sorter: true,
          },
        ]
      : [
          {
            title: "",
            dataIndex: "",
            key: "",
          },
          {
            title: t("ap_gia_ho_so.stt"),
            dataIndex: "stt",
            key: "stt",
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            width: "2.5%",
            sorter: false,
          },
          {
            title: t("ap_gia_ho_so.ma_yeu_cau"),
            dataIndex: "maYCKNCmis",
            key: "maYCKNCmis",
            render: (text, record) => (
              <div
                className="table-text-left ant-btn-link cursor-pointer"
                onClick={() => detailHoSo(record)}
              >
                <span>{text}</span>
              </div>
            ),
            width: "7%",
            sorter: true,
          },
          {
            title: t("ap_gia_ho_so.ten_khach_hang"),
            dataIndex: "tenKhachHang",
            key: "tenKhachHang",
            render: (text, record) => (
              <div
                className="table-text-left ant-btn-link cursor-pointer"
                onClick={() => detailHoSo(record)}
              >
                <span>{text}</span>
              </div>
            ),
            width: "11%",
            sorter: true,
          },
          {
            title: t("ap_gia_ho_so.dien_thoai"),
            dataIndex: "dienThoai",
            key: "dienThoai",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "6%",
            sorter: true,
          },
          {
            title: t("ap_gia_ho_so.dia_chi"),
            dataIndex: "diaChiCapDien",
            key: "diaChiCapDien",
            render: (text) => <span>{text}</span>,
            width: "18%",
            sorter: true,
          },
          {
            title: t("ap_gia_ho_so.so_pha"),
            dataIndex: "soPha",
            key: "soPha",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "5%",
            sorter: true,
          },
          {
            title: t("ap_gia_ho_so.trang_thai"),
            dataIndex: "tenTrangThai",
            key: "tenTrangThai",
            className: "text-center",
            render: (text, record) => (
              <div
                style={
                  funcID !== ENUM_FUNCTION.TRACUUHOSO
                    ? { display: "flex", flexDirection: "column" }
                    : {
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                      }
                }
                onClick={() =>
                  funcID !== ENUM_FUNCTION.TRACUUHOSO
                    ? false
                    : handleClickTrangThai(record)
                }
              >
                <Tag color={record.mau}>{record.tenTrangThai}</Tag>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {record.traLaiKhaoSat ? (
                    <div
                      style={{
                        fontSize: "12px",
                        marginTop: "4px",
                        color: "#FF5247",
                        cursor: "pointer",
                      }}
                    >
                      <Tooltip title={record.noiDungTraHoSo}>
                        <InfoCircleOutlined /> Hồ sơ trả lại
                      </Tooltip>
                    </div>
                  ) : null}
                  {record?.flagCanhBao ? (
                    <div
                      style={{
                        marginTop: "4px",
                        color: "#FF5247",
                        cursor: "pointer",
                        marginLeft: record.traLaiKhaoSat ? "8px" : 0,
                      }}
                    >
                      <Tooltip title="Hồ sơ xử lý quá hạn">
                        <WarningOutlined />
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
                {record?.isAutoSync ? (
                  <div
                    style={{
                      fontSize: "12px",
                      marginTop: "4px",
                    }}
                  >
                    Tự động đồng bộ
                  </div>
                ) : null}
                {funcID === ENUM_FUNCTION.XACNHANTHICONG && record.daLapBBLD && (
                  <div
                    style={{
                      fontSize: "12px",
                      marginTop: "4px",
                      color: "blue",
                      cursor: "pointer",
                      marginLeft:
                        record.traLaiKhaoSat || record?.flagCanhBao ? "8px" : 0,
                    }}
                  >
                    Đã lập biên bản
                  </div>
                )}
              </div>
            ),
            width: "11%",
          },
          {
            title: t("ap_gia_ho_so.loai_yeu_cau"),
            dataIndex: "tenLoaiYeuCau",
            key: "tenLoaiYeuCau",
            render: (text) => <span>{text}</span>,
            width: "7%",
            sorter: true,
          },
          {
            title: t("ap_gia_ho_so.ngay_dang_ky"),
            dataIndex: "ngayDangKy",
            key: "ngayDangKy",
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            width: "6%",
            sorter: true,
          },
          {
            title: t("ap_gia_ho_so.so_ngay_lam_viec"),
            dataIndex: "snlvandtsn",
            key: "snlvandtsn",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "7%",
            sorter: true,
          },
          {
            title: t("ap_gia_ho_so.so_cmt"),
            dataIndex: "cmnd",
            key: "cmnd",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "6%",
            sorter: true,
          },
          {
            title: t("ap_gia_ho_so.nguon_tiep_nhan"),
            dataIndex: "nguonTiepNhan",
            key: "nguonTiepNhan",
            render: (text) => <span>{text}</span>,
            // width: "7%",
            sorter: true,
          },
        ];

  const addColumns = () => {
    let newColumns = [];
    if (Object.keys(firstColumns).length === 0) {
      delete columns[0];
    } else {
      columns[0] = firstColumns;
      // columns[0].width = "3%";
      columns[0].fixed = "left";
    }

    newColumns = columns;
    return newColumns;
  };

  //change cảnh báo
  const checkCanhBao = (e) => {
    setIsCanhBao(e.target.checked);
  };

  //tìm kiếm dữ liệu
  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        pageIndex: DEFAULT_PAGEINDEX,
        ...values,
        donViId: values.donViId,
        mucDich:
          values.mucDich !== null && values.mucDich !== undefined
            ? values.mucDich.toString()
            : undefined,
        funcID:
          funcID !== null && funcID !== undefined
            ? funcID.toString()
            : undefined,
        maTrangThai: values.maTrangThai
          ? Array.isArray(values.maTrangThai) && values.maTrangThai.length > 0
            ? values.maTrangThai.join()
            : values.maTrangThai
          : [],
        isCanhBao: isCanhBao,
        startDate: moment(values.startDate).format(FORMAT_ENGLISH),
        endDate: moment(values.endDate).format(FORMAT_ENGLISH),
      }));
    },
    [funcID, isCanhBao]
  );

  //clear bộ lọc
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      funcID:
        funcID !== null && funcID !== undefined ? funcID.toString() : undefined,
      donViId: user.unitId,
    });
  }, [funcID, user.unitId]);

  const handleClickTrangThai = (values) => {
    const maTrangThai = values?.maTrangThai;
    const maYCKNCmis = values?.maYCKNCmis;
    redirectByMaTrangThai(
      maTrangThai,
      navigation,
      maYCKNCmis,
      user,
      values,
      menuList
    );
  };

  return (
    <Table
      listFilter={listFilter}
      handleSearch={handleSearch}
      clearFilter={clearFilter}
      filterConditions={filterConditions}
      funcID={funcID}
      t={t}
      renderExtraAction={renderExtraAction}
      dataHoSo={dataHoSo}
      addColumns={addColumns}
      totalCount={totalCount}
      loading={loading}
      onChangePagination={onChangePagination}
      onRow={onRow}
      rowSelection={rowSelection}
      rowKey={rowKey}
      checkCanhBao={checkCanhBao}
      urlXuatExcel={urlXuatExcel}
      permissionExcel={permissionExcel}
      rowId={rowId ? rowId : null}
    />
  );
}
