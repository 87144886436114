import { Col, Form, Row, Select, Button } from "antd";
import DatePickerComponent from "@components/DatePicker";
import {
  ENUM_BUTTON_TYPE,
  FORMAT_DATE,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import moment from "moment";
import FormComponent from "@components/Form";
import { cap_dien } from "@permissions/a_capdien";
import CheckPermission from "@components/CheckPermission";

const { Option } = Select;

export default function FormBoLoc(props) {
  const {
    form,
    handleSearch,
    user,
    mucDich,
    soPha,
    loaiYeuCau,
    tinhTrang,
    hinhThucLapDat,
    clear,
    filterConditions,
    setType,
  } = props;
  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
        tinhTrangTiepNhan: "1",
      }}
      autoComplete="off"
    >
      <Row gutter={24}>
        {/* <Col span={24} md={6}>
          <Form.Item name="donViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Select
                showSearch
                filterOption={(input, option) => {
                  if (option && option.children) {
                    return (
                      removeAccents(option.children)
                        .toLowerCase()
                        .indexOf(removeAccents(input).toLowerCase()) >= 0
                    );
                  }
                }}
              >
                {donVi && donVi.length > 0
                  ? donVi.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                  : null}
              </Select>
            )}
          </Form.Item>
        </Col> */}
        <Col span={24} md={6}>
          <Form.Item name="loaiYeuCauId" label="Loại tổng hợp">
            <Select placeholder="Tất cả" allowClear>
              {loaiYeuCau && loaiYeuCau.length > 0
                ? loaiYeuCau.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="soPha" label="Số pha">
            <Select placeholder="Tất cả" allowClear>
              {soPha && soPha.length > 0
                ? soPha.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="hinhThucLapDatId" label="Hình thức lắp đặt">
            <Select placeholder="Tất cả" allowClear>
              {hinhThucLapDat && hinhThucLapDat.length > 0
                ? hinhThucLapDat.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col span={24} md={6}>
          <Form.Item name="hinhThucNopTien" label="Hình thức nộp tiền">
            <Select placeholder="Tất cả" allowClear>
              {hinhThucNopTien && hinhThucNopTien.length > 0
                ? hinhThucNopTien.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col> */}
        <Col span={24} md={6}>
          <Form.Item name="tinhTrangTiepNhan" label="Tình trạng">
            <Select placeholder="Tất cả" allowClear={false}>
              {tinhTrang && tinhTrang.length > 0
                ? tinhTrang.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="loaiHopDong" label="Loại hợp đồng">
            <Select placeholder="Tất cả" allowClear>
              {mucDich && mucDich.length > 0
                ? mucDich.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item name="startDateBaoCao" label="Từ ngày">
            <DatePickerComponent
              defaultValue={
                filterConditions.startDateBaoCao
                  ? moment(filterConditions.startDateBaoCao, FORMAT_ENGLISH)
                  : moment(moment().startOf(MONTH_STRING), FORMAT_DATE)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"startDateBaoCao"}
              notClear={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item name="endDateBaoCao" label="Đến ngày">
            <DatePickerComponent
              defaultValue={
                filterConditions.endDateBaoCao
                  ? moment(filterConditions.endDateBaoCao, FORMAT_ENGLISH)
                  : moment(moment(), FORMAT_DATE)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"endDateBaoCao"}
              notClear={true}
            />
          </Form.Item>
        </Col>

        <Col
          span={24}
          md={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button className="mr-24px" onClick={clear}>
            Bỏ lọc
          </Button>
          <Button
            type="primary"
            className="mr-24px"
            htmlType="submit"
            form="filter-form"
            onClick={() => setType(ENUM_BUTTON_TYPE.TIM_KIEM)}
          >
            Tìm kiếm
          </Button>
          <CheckPermission permissionCode={cap_dien.a505_xuat}>
            <Button
              type="primary"
              className="mr-24px"
              htmlType="submit"
              form="filter-form"
              onClick={() => setType(ENUM_BUTTON_TYPE.XUAT_EXCEL)}
            >
              Xuất excel
            </Button>
          </CheckPermission>
        </Col>
      </Row>
    </FormComponent>
  );
}
