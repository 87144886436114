import { ENUM_DS_BANGKE, TYPE_TABLE_CONGTO } from "@utils/constants";
import { Fragment, useState } from "react";
import Table from "../../common/ky-duyet-bang-ke";

export default function Index() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [fetchList, setFetchList] = useState(false);

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Fragment>
      <Table
        typeList={TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA}
        typeDS={ENUM_DS_BANGKE.CHOKHKY_PKD_CMIS}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={false}
        rowSelection={rowSelection}
        selectedRowKeys={selectedRowKeys}
        header={"Khách hàng chưa ký"}
      />
    </Fragment>
  );
}
