import { Endpoint } from "@utils/endpoint";
import {
  authDeleteData,
  authGetData,
  authGetMultiplesRequest,
  authPostData,
  downloadFile,
} from "@utils/request";
import {
  ENUM_DIACHINH,
  ENUM_FUNCTION,
  STATUSCODE_200,
  CO_QUAN,
  METHOD_POST,
  ENUM_MODE_PHUONGANCAPDIEN,
  LIST_TILEGIABANDIEN,
  METHOD_PUT,
  ENUM_VATTU,
} from "@utils/constants";
import {
  getErrorMessage,
  alertMessage,
  buildQueryString,
  parseParams,
} from "@utils/function";

export const handleXemLaiPhuongAn = (
  detail,
  setDataPAXemLai,
  setVisibleXemLaiPACD,
  visibleXemLaiPACD
) => {
  const endpoints = [
    `${Endpoint.KS_GET_PHUONG_AN_CAP_DIEN}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    `${Endpoint.KS_TONG_CONG_SUAT}?HoSoId=${detail?.hoSoId ?? detail?.id}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading: () => false,
    onSuccess: (res) => {
      if (res[0]?.data !== null && res[1]?.data !== null) {
        setDataPAXemLai({ ...res[0].data.data, ...res[1].data.data });
        setVisibleXemLaiPACD(!visibleXemLaiPACD);
      }
    },
  });
};

export const handleListTroNgai = (setLoading, setDataTroNgai) => {
  authGetData({
    url: `${Endpoint.XU_LY_LIST_TRO_NGAI}?funcId=${ENUM_FUNCTION.LAPPHUONGAN}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) setDataTroNgai(res.data);
    },
  });
};

export const handleMultiBoLoc = (
  setLoading,
  setLoaiYeuCau,
  setMucDich,
  setSoPha
) => {
  const endpoints = [
    `${Endpoint.COMMON_LIST_LOAI_YEU_CAU}`,
    `${Endpoint.COMMON_LIST_MUC_DICH}`,
    `${Endpoint.COMMON_LIST_SO_PHA}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setLoaiYeuCau(res[0].data.data);
      setMucDich(res[1].data.data);
      setSoPha(res[2].data.data);
    },
  });
};

export const handleGetThongTinCoBan = (
  hoSo,
  setLoading,
  setDataThongTinCoBan
) => {
  authGetData({
    url: `${Endpoint.KS_GET_THONG_TIN_CO_BAN + "?HoSoId=" + hoSo.id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataThongTinCoBan(res.data);
      } else setDataThongTinCoBan({});
    },
  });
};

export const handleMultiTabsThongTin = (
  hoSoId,
  setLoadingSubmit,
  setDataDiaChinh,
  setDataSoPha,
  setDataTinhTrangSuDung,
  setDataHinhThucGhiChiSo,
  setDataLoaiHopDong,
  setDataHinhThucTraTien,
  setDataTinhTrangThu,
  setDataGioiTinhKhachHang,
  setDataLanhDao,
  setDataMucDich,
  mode
) => {
  const endpoints = [
    `${Endpoint.LIST_DIA_CHINH}?CapDonVi=${ENUM_DIACHINH.CAPHAI}&PageSize=-1`,
    `${Endpoint.COMMON_LIST_SO_PHA + "?PageSize=-1"}`,
    `${Endpoint.COMMON_LIST_TINH_TRANG_SU_DUNG + "?PageSize=-1"}`,
    `${Endpoint.COMMON_HINH_THUC_GHI_CHI_SO + "?PageSize=-1"}`,
    `${Endpoint.COMMON_LIST_LOAI_HOP_DONG + "?PageSize=-1"}`,
    `${Endpoint.COMMON_LIST_HINH_THUC_TRA_TIEN + "?PageSize=-1"}`,
    `${
      Endpoint.COMMON_LIST_TINH_TRANG_THU +
      `?PageSize=-1${
        mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI
          ? ""
          : "&FuncId=" + ENUM_FUNCTION.LAPPHUONGAN
      }`
    }`,
    `${Endpoint.COMMON_LIST_GIOI_TINH + "?PageSize=-1"}`,
    `${Endpoint.COMMON_LIST_LANH_DAO + "?HoSoId=" + hoSoId}`,
    `${Endpoint.COMMON_LIST_MUC_DICH}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading: setLoadingSubmit,
    onSuccess: (res) => {
      if (res.data !== null) setDataDiaChinh(res[0].data.data);
      else setDataDiaChinh([]);

      if (res.data !== null) setDataSoPha(res[1].data.data);
      else setDataSoPha([]);

      if (res.data !== null) setDataTinhTrangSuDung(res[2].data.data);
      else setDataTinhTrangSuDung([]);

      if (res.data !== null) setDataHinhThucGhiChiSo(res[3].data.data);
      else setDataHinhThucGhiChiSo([]);

      if (res.data !== null) setDataLoaiHopDong(res[4].data.data);
      else setDataLoaiHopDong([]);

      if (res.data !== null) setDataHinhThucTraTien(res[5].data.data);
      else setDataHinhThucTraTien([]);

      if (res.data !== null) setDataTinhTrangThu(res[6].data.data);
      else setDataTinhTrangThu([]);

      if (res.data !== null) setDataGioiTinhKhachHang(res[7].data.data);
      else setDataGioiTinhKhachHang([]);

      if (res.data !== null) setDataLanhDao(res[8].data.data);
      else setDataLanhDao([]);

      if (res.data !== null) setDataMucDich(res[9].data.data);
      else setDataMucDich([]);
    },
  });
};

export const handleSaveThongTin = (
  data,
  setLoading,
  setFetchDataTable,
  setInitialValues,
  setNotSaved,
  setDisplayNguoiDaiDien,
  form,
  onComplete
) => {
  if (data.hoSoId) {
    authPostData({
      url: Endpoint.KS_SAVE_THONG_TIN_CO_BAN,
      method: METHOD_POST,
      payload: data,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          onComplete(data);
          setFetchDataTable(true);
          setInitialValues({ ...data });
          setNotSaved(false);
          //Đặt lại giá trị displayNguoiDaiDien
          if (data.gioiTinhKH === parseInt(CO_QUAN)) {
            setDisplayNguoiDaiDien(false);
          } else {
            setDisplayNguoiDaiDien(true);
          }
        } else {
          getErrorMessage(res, form);
        }
      },
    });
  }
};

export const handlePhuongXaSuDung = (
  setLoading,
  value,
  setDataXaPhuongSuDung,
  form
) => {
  authGetData({
    url: `${Endpoint.LIST_PHUONG_XA}?QuanHuyenId=${value}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataXaPhuongSuDung(res.data);
      } else setDataXaPhuongSuDung([]);
    },
  });
  form.setFieldsValue({
    phuongXaSuDungId: null,
  });
};

export const handlePhuongGiaoDich = (
  setLoading,
  value,
  setDataXaPhuongGiaoDich,
  form
) => {
  authGetData({
    url: `${Endpoint.LIST_PHUONG_XA}?QuanHuyenId=${value}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataXaPhuongGiaoDich(res.data);
      } else setDataXaPhuongGiaoDich([]);
    },
  });
  form.setFieldsValue({
    phuongXaGiaoDichId: null,
  });
};

export const handleListPhuongXaSd = (setLoading, id, setDataXaPhuongSuDung) => {
  authGetData({
    url: `${Endpoint.LIST_PHUONG_XA}?QuanHuyenId=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataXaPhuongSuDung(res.data);
      } else setDataXaPhuongSuDung([]);
    },
  });
};

export const handleListPhuongXaGd = (
  setLoading,
  id,
  setDataXaPhuongGiaoDich
) => {
  authGetData({
    url: `${Endpoint.LIST_PHUONG_XA}?QuanHuyenId=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataXaPhuongGiaoDich(res.data);
      } else setDataXaPhuongGiaoDich([]);
    },
  });
};

export const handleGetCongSuat = (detailLapPhuongAn, setLoading, setData) => {
  const endpoint = `${Endpoint.KS_DETAIL_CONG_SUAT}?HoSoId=${
    detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
  }`;
  authGetData({
    url: endpoint,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setData(res.data);
      } else setData({});
    },
  });
};

export const handleListChungLoaiVatTu = (setLoading, setDataChungLoaiVatTu) => {
  const endpoints = [
    // `${Endpoint.DETAIL_HO_SO + "?HoSoId=" + detail.id}`,
    //`${Endpoint.LIST_DIA_CHINH}?CapDonVi=${ENUM_DIACHINH.CAPHAI}&PageSize=-1`,
    `${Endpoint.COMMON_LIST_CHUNG_LOAI_VAT_TU}?funcId=6`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDataChungLoaiVatTu(res[0].data.data);
    },
  });
};

export const handleGetPhuongAnCapDien = (
  detailLapPhuongAn,
  dataPAXemLai,
  mode,
  setDataPhuongAn,
  setLoading,
  setListTram,
  setListLo,
  setListQuyen,
  setListHinhThucLapDat,
  setFlag
) => {
  const endpoints = [
    `${Endpoint.COMMON_LIST_TRAM}?HoSoId=${
      detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
    }`,
    `${Endpoint.COMMON_LIST_LO}?HoSoId=${
      detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
    }`,
    `${Endpoint.COMMON_LIST_QUYEN}?HoSoId=${
      detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
    }`,
    `${Endpoint.COMMON_DM_LIST_HINH_THUC_LAP_DAT}`,
    `${Endpoint.KS_TONG_CONG_SUAT}?HoSoId=${
      detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
    }`,
    `${Endpoint.KS_GET_PHUONG_AN_CAP_DIEN}?HoSoId=${
      detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
    }`,
  ];
  if (
    dataPAXemLai &&
    Object.keys(dataPAXemLai).length > 0 &&
    mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI
  ) {
    setDataPhuongAn(dataPAXemLai);
    endpoints.splice(-1);
  }
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      if (res[0]?.data !== null) {
        setListTram(res[0].data.data);
      }
      if (res[1]?.data !== null) {
        setListLo(res[1].data.data);
      }
      if (res[2]?.data !== null) {
        setListQuyen(res[2].data.data);
      }
      if (res[3]?.data !== null) {
        setListHinhThucLapDat(res[3].data.data);
      }

      if (mode === ENUM_MODE_PHUONGANCAPDIEN.XEMTRENTAB) {
        if (res[5]?.data !== null && res[4]?.data !== null) {
          setDataPhuongAn({ ...res[5].data.data, ...res[4]?.data.data });
        }
      }
      setFlag(true);
    },
  });
};

export const handleUpdatePhuongAnCapDien = (
  convertDataThoiGianThiCong,
  values,
  detailLapPhuongAn,
  setLoading,
  setInitialValues,
  setNotSaved,
  form,
  onComplete
) => {
  const data = convertDataThoiGianThiCong({
    ...values,
    hoSoId: detailLapPhuongAn?.hoSoId,
    isTreoThao: true,
    ghiChuThiCong:
      values.ghiChuThiCong !== null
        ? values.ghiChuThiCong.replace(/(?:\r\n|\r|\n)/g, " ")
        : values.ghiChuThiCong,
  });
  authPostData({
    url: `${Endpoint.KS_SAVE_PHUONG_AN_CAP_DIEN}`,
    method: METHOD_POST,
    setLoading,
    payload: { ...data },
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        // getDataPhuongAnCapDien();
        onComplete(data);
        setInitialValues({ ...values });
        setNotSaved(false);
      } else {
        const string = res?.message;
        const rl = [
          { ThoiGianThiCongTu: "_thoiGiaThiCongTu" },
          { ThoiGianThiCongDen: "_thoiGianThiCongDen" },
        ];
        const result = rl.reduce(
          (f, s) => `${f}`.replace(Object.keys(s)[0], s[Object.keys(s)[0]]),
          string
        );
        getErrorMessage({ ...res, message: result }, form);
      }
    },
  });
};

export const handleListTyLe = (
  typeTiLe,
  detaiHoSo,
  setLoading,
  setDataGiaBanDien,
  setTotaCount
) => {
  let url = "";
  if (typeTiLe === LIST_TILEGIABANDIEN.APGIA)
    url =
      Endpoint.AP_GIA_TI_LE_BAN_DIEN +
      "?hoSoId=" +
      detaiHoSo.id +
      "&PageSize=-1";
  else
    url = Endpoint.KS_LIST_TY_LE_GIA_BAN_DIEN + "?hoSoId=" + detaiHoSo.hoSoId;
  authGetData({
    url: url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataGiaBanDien(res.data);
        setTotaCount(res.paging.totalRecords);
      } else setDataGiaBanDien([]);
    },
  });
};

export const handleMultiAPITyLe = (
  typeTiLe,
  detaiHoSo,
  setLoading,
  setDataTiLe,
  setDataGiaBanDien,
  setTotaCount
) => {
  let urlTiLeTable = "";
  // let urlMucDich = "";
  if (typeTiLe === LIST_TILEGIABANDIEN.APGIA) {
    urlTiLeTable =
      Endpoint.AP_GIA_TI_LE_BAN_DIEN +
      "?hoSoId=" +
      detaiHoSo.id +
      "&PageSize=-1";
    // urlMucDich =
    //   Endpoint.LIST_TI_LE_GIA_BAN_DIEN_AP_GIA + "?hoSoId=" + detaiHoSo.id;
  } else {
    urlTiLeTable =
      Endpoint.KS_LIST_TY_LE_GIA_BAN_DIEN + "?hoSoId=" + detaiHoSo.hoSoId;
    // urlMucDich = Endpoint.LIST_GIA_BAN_DIEN + "?PageSize=-1";
  }
  const endpoints = [Endpoint.LIST_GIA_BAN_DIEN + "?PageSize=-1", urlTiLeTable];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDataTiLe(res[0].data.data);
      setDataGiaBanDien(res[1].data.data);
      setTotaCount(res[1].data.paging.totalRecords);
    },
  });
};

export const handleDeleteTyLe = (ids, setLoading, fetchDataGiaBanDien, t) => {
  authDeleteData({
    url: Endpoint.KS_REMOVE_TY_LE_GIA_BAN_DIEN + "?Id=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) fetchDataGiaBanDien();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

export const handleUpdateCongSuatSD = (
  data,
  detailLapPhuongAn,
  setLoadingIndex,
  setFetchDataCSTB,
  handleCloseModal,
  form
) => {
  authPostData({
    url: Endpoint.KS_UPDATE_THIET_BI_DIEN,
    method: METHOD_PUT,
    payload: { ...data, hoSoId: detailLapPhuongAn?.hoSoId },
    setLoading: setLoadingIndex,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataCSTB(true);
        handleCloseModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleCreateCongSuatSD = (
  data,
  detailLapPhuongAn,
  setLoadingIndex,
  setFetchDataCSTB,
  handleCloseModal,
  form
) => {
  authPostData({
    url: Endpoint.KS_CREATE_THIET_BI_DIEN,
    method: METHOD_POST,
    payload: { ...data, hoSoId: detailLapPhuongAn?.hoSoId },
    setLoading: setLoadingIndex,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataCSTB(true);
        handleCloseModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleRemoveCongSuatSD = (
  id,
  setLoadingIndex,
  setFetchDataCSTB,
  handleCloseModal,
  t,
  detailLapPhuongAn
) => {
  authDeleteData({
    url:
      Endpoint.KS_REMOVE_THIET_BI_DIEN +
      "?Id=" +
      id +
      "&HoSoId=" +
      (detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id),
    setLoading: setLoadingIndex,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataCSTB(true);
        handleCloseModal();
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const handleListVatTuKhachHang = (
  setLoading,
  setListVatTuKH,
  t,
  detailLapPhuongAn
) => {
  authGetData({
    url: `${Endpoint.KS_LIST_VAT_TU_KHACH_HANG}?HoSoId=${
      detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
    }&pageSize=-1`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setListVatTuKH(res.data);
      } else {
        setListVatTuKH([]);
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const handleListVatTuCongTy = (
  setLoading,
  setDataCongTy,
  t,
  detailLapPhuongAn,
  setPaginationCty,
  pagination
) => {
  authGetData({
    url: `${Endpoint.KS_LIST_VAT_TU_CONG_TY}?OrderBy=tenVatTu&HoSoId=${
      detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
    }${
      Object.keys(pagination).length > 0
        ? "&" + buildQueryString(pagination)
        : ""
    }`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataCongTy(res.data);
        setPaginationCty(res.paging);
      } else {
        setDataCongTy([]);
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const handleRemoveVatTu = (ids, setLoading, getListData, t) => {
  authDeleteData({
    url: Endpoint.REMOVE_VAT_TU + "?IDs=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) getListData();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

export const handleCreateVatTu = (
  data,
  setLoading,
  form,
  getListData,
  closeModal
) => {
  authPostData({
    url: Endpoint.CREATE_VAT_TU,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        getListData();
        closeModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleUpdateVatTu = (
  data,
  setLoading,
  form,
  getListData,
  closeModal
) => {
  authPostData({
    url: Endpoint.UPDATE_VAT_TU,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        getListData();
        closeModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleSubmitVatTuKH = (
  values,
  typeVatTu,
  setLoading,
  form,
  t,
  handleCloseModal
) => {
  const data = {
    ...values,
  };
  let url = "";
  if (typeVatTu === ENUM_VATTU.COSAN) url = Endpoint.UPDATE_VAT_TU_CO_SAN;
  else url = Endpoint.UPDATE_VAT_TU_KHACH_HANG;
  authPostData({
    url: url,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        alertMessage(
          "success",
          t("modal.sua_thanh_cong"),
          t("cau_hinh.message.cap_nhat_thanh_cong")
        );
        handleCloseModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleUpdateTiLeBD = (
  data,
  setLoading,
  fetchDataGiaBanDien,
  handleCloseModal,
  form
) => {
  authPostData({
    url: Endpoint.KS_UPDATE_TY_LE_GIA_BAN_DIEN,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        fetchDataGiaBanDien();
        handleCloseModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleCreateTiLeBD = (
  data,
  setLoading,
  fetchDataGiaBanDien,
  handleCloseModal,
  form
) => {
  authPostData({
    url: Endpoint.KS_CREATE_TY_LE_GIA_BAN_DIEN,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        fetchDataGiaBanDien();
        handleCloseModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleSubmitTroNgaiKS = (
  data,
  setLoadingSubmit,
  setFetchDataTable,
  closeModal,
  form
) => {
  authPostData({
    url: Endpoint.XU_LY_CAP_NHAT_TRO_NGAI,
    method: METHOD_PUT,
    payload: data,
    setLoading: setLoadingSubmit,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        closeModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleListLoaiTroNgai = (
  funcID,
  value,
  setLoadingTenTroNgai,
  setData,
  form,
  dataTroNgai,
  setDisable
) => {
  authGetData({
    url: `${Endpoint.XU_LY_LIST_TRO_NGAI}?funcId=${funcID}&loaiTroNgaiId=${value}`,
    setLoading: setLoadingTenTroNgai,
    onSuccess: (res) => {
      if (res.data !== null) {
        setData(res.data);
        form.resetFields(["troNgaiId"]);
      } else setData(res.data);
    },
  });
  dataTroNgai.map((item) => {
    if ([value].includes(item.id)) {
      if (
        (funcID === 4 && item.tinhChat === "3") ||
        (funcID === 5 && item.tinhChat === "3") ||
        (funcID === 6 && item.tinhChat === "3") ||
        (funcID === 7 && item.tinhChat === "3")
      ) {
        setDisable(true);
      } else setDisable(false);

      return false;
    }
    return true;
  });
};

/**
 * Service Dự trù
 */

/**
 * Lấy data dự toán và loại cáp
 * @param {*} setLoading
 * @param {*} setDataLoaiDuToan
 * @param {*} setDataLoaiCap
 * @param {*} setFlag
 */
export const getDataDuToanLoaiCap = (
  setLoading,
  setDataLoaiDuToan,
  setDataLoaiCap,
  setFlag
) => {
  const endpoints = [
    `${Endpoint.COMMON_LIST_LOAI_DU_TOAN}`,
    `${Endpoint.COMMON_LIST_LOAI_CAP}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      if (res[0].data !== null) setDataLoaiDuToan(res[0].data?.data);
      if (res[1].data !== null) setDataLoaiCap(res[1].data?.data);
      setFlag(true);
    },
  });
};

/**
 * List vật tư theo ngày tiếp nhận
 * @param {*} detailLapPhuongAn
 * @param {*} setLoading
 * @param {*} setListVatTu
 * @param {*} t
 */
export const getListVatTuTheoNgayTiepNhan = (
  detailLapPhuongAn,
  setLoading,
  setListVatTu,
  t
) => {
  authGetData({
    url: `${Endpoint.LIST_VAT_TU_THEO_NGAY_TIEP_NHAN}?HoSoId=${
      detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
    }`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setListVatTu(res.data);
      } else {
        setListVatTu([]);
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

/**
 * Tạo vật tư công ty
 * @param {*} data
 * @param {*} refreshTable
 * @param {*} setLoading
 * @param {*} handleCloseModal
 * @param {*} form
 */
export const createVatTuCongTy = (
  data,
  refreshTable,
  setLoading,
  handleCloseModal,
  form
) => {
  authPostData({
    url: Endpoint.KS_CREATE_VAT_TU_CONG_TY,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        refreshTable();
        handleCloseModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const updateVatTuCongTy = (
  data,
  setLoading,
  refreshTable,
  handleCloseModal,
  form
) => {
  authPostData({
    url: Endpoint.KS_UPDATE_VAT_TU_CONG_TY,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        refreshTable();
        handleCloseModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const deleteVatTuCongTy = (
  id,
  setLoading,
  refreshTable,
  t,
  detailLapPhuongAn
) => {
  authDeleteData({
    url:
      Endpoint.KS_DELETE_VAT_TU_CONG_TY +
        "?Id=" +
        id +
        "&HoSoId=" +
        detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) refreshTable();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

export const getListVatTuHinhThucThiCong = (
  detailLapPhuongAn,
  setLoading,
  setListHTTC,
  setListVatTu,
  t
) => {
  const endpoints = [
    `${Endpoint.LIST_VAT_TU_THEO_NGAY_TIEP_NHAN}?HoSoId=${
      detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
    }`,
    `${Endpoint.LIST_HINH_THUC_THI_CONG}?SkipNone=${true}&PageSize=-1`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      if (res[0].data.statusCode === STATUSCODE_200 && res[0].data) {
        setListVatTu(res[0].data.data);
      } else {
        setListVatTu([]);
        alertMessage("error", t("modal.thong_bao"), res[0].data.message);
      }
      if (res[1].data.statusCode === STATUSCODE_200 && res[1].data) {
        setListHTTC(res[1].data.data);
      } else {
        setListHTTC([]);
        alertMessage("error", t("modal.thong_bao"), res[1].data.message);
      }
    },
  });
};

//Thu Hồi

export const handleListVatTuCongTyThuHoi = (
  setLoading,
  setDataCongTy,
  t,
  detailLapPhuongAn
) => {
  authGetData({
    url: `${Endpoint.KS_LIST_VAT_TU_CONG_TY_THU_HOI}?HoSoId=${
      detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
    }&pageSize=-1`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataCongTy(res.data);
      } else {
        setDataCongTy([]);
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

/**
 * Tạo vật tư công ty thu hoi
 * @param {*} data
 * @param {*} refreshTable
 * @param {*} setLoading
 * @param {*} handleCloseModal
 * @param {*} form
 */
export const createVatTuCongTyThuHoi = (
  data,
  refreshTable,
  setLoading,
  handleCloseModal,
  form
) => {
  authPostData({
    url: Endpoint.KS_CREATE_VAT_TU_CONG_TY_THU_HOI,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        refreshTable();
        handleCloseModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const updateVatTuCongTyThuHoi = (
  data,
  setLoading,
  refreshTable,
  handleCloseModal,
  form
) => {
  authPostData({
    url: Endpoint.KS_UPDATE_VAT_TU_CONG_TY_THU_HOI,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        refreshTable();
        handleCloseModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const deleteVatTuCongTyThuHoi = (
  id,
  setLoading,
  refreshTable,
  t,
  detailLapPhuongAn
) => {
  authDeleteData({
    url:
      Endpoint.KS_DELETE_VAT_TU_CONG_TY_THU_HOI +
        "?Id=" +
        id +
        "&HoSoId=" +
        detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) refreshTable();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

/**
 * Thông tin dự toán
 * @param {*} detailLapPhuongAn
 * @param {*} setLoading
 * @param {*} setThongTinHoSo
 * @param {*} t
 */
export const getThongTinHoSo = (
  detailLapPhuongAn,
  setLoading,
  setThongTinHoSo,
  t
) => {
  authGetData({
    url: `${Endpoint.KS_GET_THONG_TIN_HO_SO}?HoSoId=${
      detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
    }`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setThongTinHoSo(res.data);
      } else {
        setThongTinHoSo({});
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

/**
 * Lưu thông tin dự toán
 * @param {*} data
 * @param {*} setLoading
 * @param {*} refreshTable
 * @param {*} handleCloseModal
 * @param {*} form
 */
export const saveThongTinHoSo = (
  data,
  setLoading,
  refreshTable,
  handleCloseModal,
  form
) => {
  authPostData({
    url: Endpoint.KS_SAVE_THONG_TIN_HO_SO,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        refreshTable();
        handleCloseModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const getListLoaiChiPhi = (setLoading, setListChiPhi, t) => {
  authGetData({
    url: `${Endpoint.LIST_LOAI_CHI_PHI}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setListChiPhi(res.data);
      } else {
        setListChiPhi([]);
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

//Khach hang du toan

/**
 * Tạo vật tư khach hang du toan
 * @param {*} data
 * @param {*} refreshTable
 * @param {*} setLoading
 * @param {*} handleCloseModal
 * @param {*} form
 */
export const createVatTuKHDT = (
  data,
  refreshTable,
  setLoading,
  handleCloseModal,
  form,
  typeVatTu,
  onComplete
) => {
  authPostData({
    url:
      typeVatTu === ENUM_VATTU.COSAN
        ? Endpoint.KS_CREATE_VAT_TU_KHACH_HANG_DU_TOAN_CO_SAN
        : Endpoint.KS_CREATE_VAT_TU_KHACH_HANG_DU_TOAN_MUA_NGOAI,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        onComplete(res.data);
        refreshTable();
        handleCloseModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const updateVatTuKHDT = (
  data,
  setLoading,
  refreshTable,
  handleCloseModal,
  form,
  typeVatTu,
  onComplete
) => {
  authPostData({
    url:
      typeVatTu === ENUM_VATTU.COSAN
        ? Endpoint.KS_UPDATE_VAT_TU_KHACH_HANG_DU_TOAN_CO_SAN
        : Endpoint.KS_UPDATE_VAT_TU_KHACH_HANG_DU_TOAN_MUA_NGOAI,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        onComplete(res.data);
        refreshTable();
        handleCloseModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const deleteVatTuKHDT = (
  id,
  setLoading,
  refreshTable,
  t,
  typeVatTu,
  detailLapPhuongAn,
  onComplete
) => {
  authDeleteData({
    url:
      typeVatTu === ENUM_VATTU.COSAN
        ? Endpoint.KS_DELETE_VAT_TU_KHACH_HANG_DU_TOAN_CO_SAN +
            "?Id=" +
            id +
            "&HoSoId=" +
            detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
        : Endpoint.KS_DELETE_VAT_TU_KHACH_HANG_DU_TOAN_MUA_NGOAI +
            "?Id=" +
            id +
            "&HoSoId=" +
            detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        refreshTable();
        onComplete(res.data);
      } else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

export const getVatTuKHDT = (id, onComplete, setLoading, t, typeVatTu) => {
  authGetData({
    url:
      typeVatTu === ENUM_VATTU.COSAN
        ? `${Endpoint.KS_GET_VAT_TU_KHACH_HANG_DU_TOAN_CO_SAN}?Id=${id}`
        : `${Endpoint.KS_GET_VAT_TU_KHACH_HANG_DU_TOAN_MUA_NGOAI}?Id=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        onComplete(res.data);
      } else {
        onComplete({});
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

//Khach hang khai gia

/**
 * Tạo vật tư khach hang khai gia
 * @param {*} data
 * @param {*} refreshTable
 * @param {*} setLoading
 * @param {*} handleCloseModal
 * @param {*} form
 */
export const createVatTuKHKG = (
  data,
  refreshTable,
  setLoading,
  handleCloseModal,
  form,
  typeVatTu,
  onComplete
) => {
  authPostData({
    url:
      typeVatTu === ENUM_VATTU.COSAN
        ? Endpoint.KS_CREATE_VAT_TU_KHACH_HANG_KHAI_GIA_CO_SAN
        : Endpoint.KS_CREATE_VAT_TU_KHACH_HANG_KHAI_GIA_MUA_NGOAI,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        refreshTable();
        handleCloseModal();
        onComplete(res.data);
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const updateVatTuKHKG = (
  data,
  setLoading,
  refreshTable,
  handleCloseModal,
  form,
  typeVatTu,
  onComplete
) => {
  authPostData({
    url:
      typeVatTu === ENUM_VATTU.COSAN
        ? Endpoint.KS_UPDATE_VAT_TU_KHACH_HANG_KHAI_GIA_CO_SAN
        : Endpoint.KS_UPDATE_VAT_TU_KHACH_HANG_KHAI_GIA_MUA_NGOAI,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        refreshTable();
        handleCloseModal();
        onComplete(res?.data);
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const deleteVatTuKHKG = (
  id,
  setLoading,
  refreshTable,
  t,
  typeVatTu,
  detailLapPhuongAn,
  onComplete
) => {
  authDeleteData({
    url:
      typeVatTu === ENUM_VATTU.COSAN
        ? Endpoint.KS_DELETE_VAT_TU_KHACH_HANG_KHAI_GIA_CO_SAN +
            "?Id=" +
            id +
            "&HoSoId=" +
            detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
        : Endpoint.KS_DELETE_VAT_TU_KHACH_HANG_KHAI_GIA_MUA_NGOAI +
            "?Id=" +
            id +
            "&HoSoId=" +
            detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        refreshTable();
        onComplete(res.data);
      } else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

export const getVatTuKHKG = (id, onComplete, setLoading, t, typeVatTu) => {
  authGetData({
    url:
      typeVatTu === ENUM_VATTU.COSAN
        ? `${Endpoint.KS_GET_VAT_TU_KHACH_HANG_KHAI_GIA_CO_SAN}?Id=${id}`
        : `${Endpoint.KS_GET_VAT_TU_KHACH_HANG_KHAI_GIA_MUA_NGOAI}?Id=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        onComplete(res.data);
      } else {
        onComplete({});
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const getChiPhiByHTLD = (
  hinhThucLapDatId,
  detailLapPhuongAn,
  onComplete,
  setLoading,
  t
) => {
  authGetData({
    url: `${Endpoint.KS_GET_CHI_PHI_HINH_THUC_LAP_DAT}?HoSoId=${
      detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
    }${hinhThucLapDatId ? "&HinhThucLapDatId=" + hinhThucLapDatId : ""}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        onComplete(res.data);
      } else {
        onComplete(0);
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const getPDFBienBanKhaiGia = (
  detailLapPhuongAn,
  onComplete,
  setLoading,
  t
) => {
  authGetData({
    url: `${Endpoint.KS_IN_BIEN_BAN_KHAI_GIA_PDF}?HoSoId=${
      detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
    }`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        onComplete(res.data);
      } else {
        onComplete("error");
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const getExcelBienBanKhaiGia = (
  detailLapPhuongAn,
  onComplete,
  setLoading,
  t
) => {
  authGetData({
    url: `${Endpoint.KS_IN_BIEN_BAN_KHAI_GIA_EXCEL}?HoSoId=${
      detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
    }`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        onComplete(res.data);
      } else {
        onComplete(0);
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};
export const downLoadBienBan = (detailLapPhuongAn, setLoading) => {
  downloadFile({
    endpoint: `${Endpoint.KS_IN_BIEN_BAN_KHAI_GIA_EXCEL}?HoSoId=${
      detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
    }`,
    setLoading,
  });
};

export const handleNgayHenKS = (detail, setLoading, setNgay) => {
  authGetData({
    url: Endpoint.GET_NGAY_HEN_TIEP_THEO + "?hoSoId=" + detail.id,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setNgay(res.data);
      }
    },
  });
};

export const getListDayDanDien = (
  detailLapPhuongAn,
  onComplete,
  setLoading,
  t
) => {
  authGetData({
    url: `${Endpoint.KS_LIST_DAY_DAN_DIEN}?HoSoId=${
      detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
    }`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        onComplete(res.data);
      } else {
        onComplete([]);
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const getDonGiaNhanCong = (
  detailLapPhuongAn,
  vatTuId,
  hinhThucThiCongId,
  hinhThucLapDatId,
  onComplete,
  setLoading,
  t
) => {
  const obj = {
    HoSoId: detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id,
    VatTuThoiGianHieuLucId: vatTuId,
    HinhThucThiCongId: hinhThucThiCongId,
    HinhThucLapDatId: hinhThucLapDatId,
  };
  authGetData({
    url: `${Endpoint.GET_DON_GIA_NHAN_CONG}?${buildQueryString(
      parseParams(obj)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        onComplete(res.data);
      } else {
        onComplete([]);
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const getTongCongSuat = (detailLapPhuongAn, setLoading, callBack) => {
  authGetData({
    url: `${Endpoint.KS_TONG_CONG_SUAT}?HoSoId=${
      detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id
    }`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        callBack(res.data);
      }
    },
  });
};

export const handleListTyLeGiaBanDien = (
  detaiHoSo,
  setLoading,
  setDataGiaBanDien
) => {
  let url = "";
  url = Endpoint.KS_LIST_TY_LE_GIA_BAN_DIEN + "?hoSoId=" + detaiHoSo.id;
  authGetData({
    url: url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataGiaBanDien(res.data);
      } else setDataGiaBanDien([]);
    },
  });
};
