import PencilIcon from "@assets/icon/PencilIcon";
import ButtonComponent from "@components/Button";
import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import { handlePagination } from "@utils/function";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import React, { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { handleCreateCongTo } from "../service";
import { ModalSuaTHPhucTraCongTo } from "./md-sua/md-sua-th-phuc-tra-cong-to";

function ModalCapNhatThPhucTraCongTo(props) {
  const {
    visible,
    onCancel,
    dataTable,
    filterConditions,
    setFilterConditions,
    getListData,
    loading,
    setLoading,
  } = props;
  const [form] = Form.useForm();
  const [detailTable, setDetailTable] = useState();
  const user = useSelector(authSelector);
  const [visibleModalUpdate, setVisibleModalUpdate] = useState();

  //pagination table
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //Handle change năm

  //HandleChange Năm
  const handleChange = (value) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      Nam: moment(value).get("year"),
    }));
  };

  //Onfinish Cập nhật

  const onFinish = (values) => {
    const url = Endpoint.BCCT_THEM_THPT_CT;
    const data = {
      ma_dviqly: values.unitId,
      ...(values.reportDate && { nam: values.reportDate.split("-")[0] }),
      ...(values.reportDate && { thang: values.reportDate.split("-")[1] }),
      ptr_truoc: values.ptr_truoc,
      ptr_sau: values.ptr_sau,
    };
    handleCreateCongTo(data, setLoading, getListData, url, form);
  };

  //Open-close modal
  const handleOpenModal = useCallback(
    (row) => {
      console.log(row);
      setDetailTable(row);
      setVisibleModalUpdate(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleModalUpdate]
  );

  const handleCloseModal = () => {
    setVisibleModalUpdate(false);
    setLoading(false);
  };
  //Columns
  const columns = [
    {
      title: "Mã đơn vị",
      dataIndex: "MA_DVIQLY",
      key: "MA_DVIQLY",
      width: "15%",
      align: "center",
    },
    {
      title: "Tháng",
      dataIndex: "THANG",
      key: "THANG",
      align: "center",
    },
    {
      title: "Năm",
      dataIndex: "NAM",
      key: "NAM",
      align: "center",
    },
    {
      title: "Trước phúc tra",
      dataIndex: "PTR_TRC",
      key: "PTR_TRC",
      align: "center",
    },
    {
      title: "Sau phúc tra",
      dataIndex: "PTR_SAU",
      key: "PTR_SAU",
      align: "center",
    },
    {
      title: "Tác vụ",
      width: 100,
      fixed: "right",
      render: (row) => (
        <div className="action-table-column">
          <Tooltip title={"Sửa"}>
            <ButtonComponent
              type="link"
              onClick={() => handleOpenModal(row)}
              className="pd-5px"
            >
              <PencilIcon />
            </ButtonComponent>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Modal
      visible={visible}
      title="Cập nhật tình hình phúc tra công tơ"
      onCancel={onCancel}
      width={"70%"}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <FormComponent
          onFinish={onFinish}
          form={form}
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 18 }}
          initialValues={{
            ma_dviqly: user.user.unitCode,
            ptr_sau: 0,
            ptr_truoc: 0,
          }}
          colon={false}
        >
          <Form.Item name="unitId" label="Đơn vị" labelAlign="left" required>
            <Selection
              url={Endpoint.GET_TEAM_LIST_CODE}
              formKey="unitId"
              form={form}
            />
          </Form.Item>

          <Form.Item
            name="reportDate"
            label="Tháng / năm"
            labelAlign="left"
            required
          >
            <DatePickerComponent
              picker="month"
              formKey="reportDate"
              form={form}
            />
          </Form.Item>

          <Row>
            <Col span={9}>
              <Form.Item
                name="ptr_truoc"
                label="Trước phúc tra"
                labelAlign="left"
                required
                labelCol={{ span: 8 }}
              >
                <InputNumber
                  formKey="ptr_truoc"
                  placeholder="Nhập số lượng công tơ trước phúc tra"
                />
              </Form.Item>
            </Col>
            <Col span={2}></Col>
            <Col span={10}>
              <Form.Item
                name="ptr_sau"
                label="Sau phúc tra"
                labelAlign="left"
                required
                labelCol={{ span: 6 }}
              >
                <InputNumber
                  formKey="ptr_sau"
                  placeholder="Nhập số lượng công tơ sau phúc tra"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Cập nhật
            </Button>
          </Form.Item>
        </FormComponent>
        <Divider className="section-text-line" orientation="left"></Divider>
        <Row>
          <Col>
            <Space style={{ marginBottom: "1rem" }}>
              <Typography.Text>Năm: </Typography.Text>
              <DatePicker
                picker="year"
                defaultValue={moment(filterConditions?.Nam, "YYYY")}
                onChange={(value) => handleChange(value)}
              />
            </Space>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={dataTable}
          scroll={{ y: 420 }}
          pagination={false}
        />
        {visibleModalUpdate ? (
          <ModalSuaTHPhucTraCongTo
            detailTable={detailTable}
            visible={visibleModalUpdate}
            closeModal={handleCloseModal}
            getListData={getListData}
            detailDataTable
          />
        ) : null}
      </Spin>
    </Modal>
  );
}

export default memo(ModalCapNhatThPhucTraCongTo);
