import { useState, useEffect } from "react";
import { Form, Button, Modal, Row, Col, Select } from "antd";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { removeAccents } from "@utils/function";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";

const { Option } = Select;

export default function PhanCongModal(props) {
  const {
    handleChangeDoi,
    dataDoi,
    dataNV,
    visible,
    closeModal,
    onFinish,
    form,
    handleChangeNV,
    disablePC,
    isDT
  } = props;
  const [loading,] = useState(false);
  const { user } = useSelector(authSelector);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  return (
    <Modal
      title="Phân Công Ghi Chỉ Số"
      visible={visible}
      onCancel={closeModal}
      className="modal-phan-cong"
      footer={null}
    >
      <LoadingComponent loading={loading}>
        <FormComponent
          form={form}
          name="form-phan-cong"
          layout="horizontal"
          onFinish={onFinish}
        >
          <Row gutter={24}>
            <Col span={6}>
              <span>Tổ đội:</span>
            </Col>
            <Col span={18}>
              <Form.Item name="doiId" label="">
                <Select onChange={handleChangeDoi} defaultValue={isDT ? dataDoi.find(element => element.name === user.teamName)?.value : "" }>
                  {dataDoi && dataDoi.length > 0
                    ? dataDoi.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <span>Nhân viên GCS:</span>
            </Col>
            <Col span={18}>
              <Form.Item name="userId" className="required-field">
                <Select showSearch onChange={handleChangeNV} filterOption={(input, option) => {
                  if (option && option.children) {
                    return (
                      removeAccents(option.children)
                        .toLowerCase()
                        .indexOf(removeAccents(input).toLowerCase()) >= 0
                    );
                  }
                }}>
                  {dataNV && dataNV.length > 0
                    ? dataNV.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                Đóng lại
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-phan-cong"
                loading={loading}
                disabled={disablePC}
              >
                Phân Công
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </LoadingComponent>
    </Modal>
  );
}
