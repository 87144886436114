import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  handlePagination,
  parseParams,
} from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
import { Button, Col, Divider, Form, Input, Row, Select, Spin } from "antd";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  KH_DANGKI_SUDUNG_EMAIL,
  KH_DANGKI_SUDUNG_ZALO,
  KH_SUDUNG_APP_CSKH,
} from "./constants";
export default function ThongTinKhachHang() {
  const { Option } = Select;
  const unitId = useSelector((state) => state.auth.user.unitId);

  const [loading, setLoading] = useState(false);
  const [donVi, setDonVi] = useState();
  const [loaiKH, setLoaiKH] = useState(0);
  const location = useLocation();
  const [data, setData] = useState();
  const [form] = Form.useForm();
  const [filterConditions, setFilterConditions] = useState({
    DonViId: unitId,
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  const onFinish = () => {
    const queryString = buildQueryString(
      parseParams({
        DonViId: filterConditions.DonViId,
        MaKhachHang: filterConditions?.MaKhachHang,
      })
    );
    if (loaiKH === 0) {
      authGetData({
        url: `${Endpoint.TKTTKH_KH_SU_DUNG_APP}?${queryString}`,
        setLoading,
        onSuccess: (res) => {
          if (res) {
            setData(res?.data);
          }
        },
      });
    }
    if (loaiKH === 1) {
      authGetData({
        url: `${Endpoint.TKTTKH_KH_SU_DUNG_EMAIL}?${queryString}`,
        setLoading,
        onSuccess: (res) => {
          if (res) {
            setData(res?.data);
          }
        },
      });
    }
    if (loaiKH === 2) {
      authGetData({
        url: `${Endpoint.TKTTKH_KH_SU_DUNG_ZALO}?${queryString}`,
        setLoading,
        onSuccess: (res) => {
          if (res) {
            setData(res?.data);
          }
        },
      });
    }
  };
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const handleDonVi = (e) => {
    setDonVi(e);
    setFilterConditions((oldState) => ({
      ...oldState,
      DonViId: e,
    }));
  };

  const handleLoaiKH = (e) => {
    setLoaiKH(e);
    setData([]);
  };

  const handleMaDonVi = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      MaKhachHang: e.target.value,
    }));
  };

  const onDownloadExcel = () => {
    const queryString = buildQueryString(
      parseParams({
        DonViId: donVi,
        MaKhachHang: filterConditions?.MaKhachHang,
      })
    );
    if (loaiKH === 0) {
      const endpoint = `${Endpoint.TKTTKH_KH_SU_DUNG_APP_EXCEL}?${queryString}`;
      if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
    }
    if (loaiKH === 1) {
      const endpoint = `${Endpoint.TKTTKH_KH_SU_DUNG_EMAIL_EXCEL}?${queryString}`;
      if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
    }
    if (loaiKH === 2) {
      const endpoint = `${Endpoint.TKTTKH_KH_SU_DUNG_ZALO_EXCEL}?${queryString}`;
      if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
    }
  };

  const columns =
    loaiKH === 0
      ? KH_SUDUNG_APP_CSKH
      : loaiKH === 1
      ? KH_DANGKI_SUDUNG_EMAIL
      : loaiKH === 2
      ? KH_DANGKI_SUDUNG_ZALO
      : KH_SUDUNG_APP_CSKH;
  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <Spin spinning={loading}>
            <Form
              layout="vertical"
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ unitId: unitId }}

            >
              <Row gutter={10}>
                <Col span={8}>
                  <Form.Item name="unitId" label="Đơn vị" labelAlign="left">
                    <Selection
                      url={Endpoint.GET_UNIT_LIST}
                      placeholder="Chọn đơn vị"
                      formKey="unitId"
                      form={form}
                      setValue={handleDonVi}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="loaikhachhang"
                    label="Loại khách hàng"
                    labelAlign="left"
                  >
                    <Select defaultValue={0} onChange={handleLoaiKH}>
                      <Option value={0}>Khách hàng sử dụng App CSKH</Option>
                      <Option value={1}>
                        Khách hàng đăng ký sử dụng Email
                      </Option>
                      <Option value={2}>Khách hàng đăng ký sử dụng Zalo</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="makhachhang"
                    label="Mã khách hàng"
                    labelAlign="left"
                  >
                    <Input onChange={handleMaDonVi} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="&nbsp;">
                    <Button type="primary" htmlType="submit" className="full">
                      Tìm kiếm
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="&nbsp;">
                    <Button
                      type="primary"
                      onClick={onDownloadExcel}
                      className="full"
                    >
                      Xuất Excel
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <div className="btn-export-baocao">
                <Row gutter={16}></Row>
              </div>
            </Form>
            <Divider className="section-text" orientation="left"></Divider>
            {data && data?.length > 0 ? (
              <TableComponent
                header={"Kết quả hiển thị"}
                columns={columns}
                dataSource={data}
                onChangePagination={onChangePagination}
                pagination={filterConditions}
                scrollX={1200}
              />
            ) : (
              <TableComponent
                header={"Kết quả hiển thị"}
                columns={columns}
                dataSource={null}
                scrollX={1200}
              />
            )}
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
