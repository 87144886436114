import { authSelector } from "@state/auth";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import { customColumn, parseParams } from "@utils/function";
import { Form, Input } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import TablePhanHeQuyenDV from "./table";

export default function PQDonViDiemDo() {
  const location = useLocation();
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    ...parseParams(location.search),
  });

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, setFetchDataTable]);
  const columns = [
    {
      title: "Mã điểm đo",
      dataIndex: `madiemdo`,
      align: "center",
      render: (text, record) => {
        form.setFieldsValue({
          [`madiemdo-${record?.id}`]: record.madiemdo,
        });
        return (
          <Form.Item name={`madiemdo-${record?.madiemdo}`}>
            <Input className="flexCenter" defaultValue={record?.madiemdo} />
          </Form.Item>
        );
      },
    },
    {
      title: "E1.02 - Đông Anh (32)",
      dataIndex: `khuvuc`,
      render: (text, record) => {
        form.setFieldsValue({
          [`stt-${record?.khuvuc}`]: record.khuvuc,
        });
        return (
          <Form.Item name={`stt-${record?.khuvuc}`}>
            <Input className="flexCenter" />
          </Form.Item>
        );
      },
      align: "center",
    },
    {
      title: "Trạm",
      dataIndex: `tram`,
      align: "center",
      render: (text, record) => {
        form.setFieldsValue({
          [`stt-${record?.tram}`]: record.tram,
        });
        return (
          <Form.Item name={`stt-${record?.tram}`}>
            <Input className="flexCenter" />
          </Form.Item>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Container>
        <TablePhanHeQuyenDV
          loading={loading}
          setFilterConditions={setFilterConditions}
          form={form}
          filterConditions={filterConditions}
          setLoading={setLoading}
        />
      </Container>
    </Fragment>
  );
}
const Container = styled.div`
  padding: 0 10px;
`;
