import { Col, Table, Button, Image, Modal } from "antd";
import { Fragment, useState, useMemo } from "react";
import { TableStyled } from "../css-styled";
import { FileImageTwoTone } from "@ant-design/icons";

export default function Index(props) {
  const { detail } = props;

  const [showModal, setShowModal] = useState(false);
  const [modalSrc, setModalSrc] = useState("");

  const handleOpenModal = (value) => {
    setShowModal(true);
    setModalSrc(value);
  };

  const column = [
    {
      title: "Loại BCS",
      dataIndex: "loaiBCS",
      key: "loaiBCS",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    // {
    //   title: "Mục đích sử dụng điện",
    //   dataIndex: "mucDich",
    //   key: "mucDich",
    //   render: () =>
    //     detail.loaiKhachHang?.toString() === ENUM_MUCDICH_SDD.SINHHOAT ? (
    //       <span>{"Sinh hoạt"}</span>
    //     ) : (
    //       <span>{"Ngoài sinh hoạt"}</span>
    //     ),
    //   width: "15%",
    // },
    {
      title: "Mục đích sử dụng điện",
      dataIndex: "maNhomNganhNghe",
      key: "maNhomNganhNghe",
      render: (text) => <span>{text}</span>,
      width: "15%",
    },
    {
      title: "Mã ngành nghề",
      dataIndex: "maNganhNghe",
      key: "maNganhNghe",
      render: (text) => <span>{text}</span>,
      width: "15%",
    },
    {
      title: "Nhóm giá",
      dataIndex: "nhomGia",
      key: "nhomGia",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Thời gian bán điện",
      dataIndex: "thoiGianBanDien",
      key: "thoiGianBanDien",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "Định mức",
      dataIndex: "dinhMuc",
      key: "dinhMuc",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "Loại định mức",
      dataIndex: "loaiDinhMuc",
      key: "loaiDinhMuc",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Số hộ",
      dataIndex: "soHo",
      key: "soHo",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Đơn giá",
      dataIndex: "donGia",
      key: "donGia",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "Mục đích",
      dataIndex: "mucDich",
      key: "mucDich",
      render: (text) => (
        <div>
          <span>{text}</span>
        </div>
      ),
      width: "20%",
    },
  ];

  const column2 = [
    {
      title: "Loại BCS",
      dataIndex: "loaiBCS",
      key: "loaiBCS",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "HSN",
      dataIndex: "heSoNhan",
      key: "heSoNhan",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "CS cũ",
      dataIndex: "chiSoCu",
      key: "chiSoCu",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "CS mới",
      dataIndex: "chiSoMoi",
      key: "chiSoMoi",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "SL mới",
      dataIndex: "sanLuong",
      key: "sanLuong",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Tình trạng",
      dataIndex: "tinhTrang",
      key: "tinhTrang",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "Ảnh",
      dataIndex: "filePath",
      key: "filePath",
      render: (record) => (
        <>
          <div className="table-text-center">
            <span>
              {record ? (
                <FileImageTwoTone
                  style={{ fontSize: 22 }}
                  onClick={() => {
                    // setModalSrc(record.filePath);
                    handleOpenModal(record);
                  }}
                />
              ) : null}
            </span>
          </div>
        </>
      ),
      width: "5%",
    },
  ];

  const showModalPC = useMemo(
    () =>
      showModal && (
        <Modal
          visible={showModal}
          width={800}
          onCancel={() => false}
          onOk={() => false}
          className="modal-image"
          footer={null}
          style={{ display: "block", textAlign: "center" }}
        >
          <Button
            className="close"
            onClick={() => setShowModal(false)}
            style={{
              fontSize: "20px",
              border: "unset",
              position: "absolute",
              top: "0",
              right: "10px",
            }}
          >
            &times;
          </Button>

          <Image
            className="modal-content"
            src={modalSrc}
            alt=""
            height={"100%"}
            width={"50%"}
            style={{ objectFit: "contain", padding: 0 }}
          />
        </Modal>
      ),
    [modalSrc, showModal]
  );

  return (
    <Fragment>
      <Col span={24}>
        <TableStyled>
          <Table
            dataSource={detail?.chiTietApGias}
            columns={column}
            pagination={false}
            scroll={{ y: 700 }}
            // key="index"
            bordered={true}
          />
        </TableStyled>
        <br />
        {detail?.chiSoChots && detail.chiSoChots.length > 0 && (
          <TableStyled>
            <Table
              dataSource={detail?.chiSoChots}
              columns={column2}
              pagination={false}
              scroll={{ y: 700 }}
              // key="index"
              bordered={true}
            />
          </TableStyled>
        )}
      </Col>
      {showModalPC}
    </Fragment>
  );
}
