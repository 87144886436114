import { alertMessage422 } from "@modules/bao-cao-kinh-doanh/service";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";

export const selectBCChiTiet = [
  {
    label:
      "1. Thống kê danh sách điểm đo đã kiểm tra áp giá có giá sản xuất (SXBT)",
    value: Endpoint.BCAGBD_TK_DS_DIEM_DO_KIEM_TRA_CO_GIA_SAN_XUAT,
  },
  {
    label:
      "2. Thống kê danh sách điểm đo đã kiểm tra áp giá có giá hành chính sự nghiệm, chiếu sáng công cộng (CSCC)",
    value: Endpoint.BCAGBD_TK_DS_DIEM_DO_KIEM_TRA_CO_GIA_HANH_CHINH_SU_NGHIEM,
  },
  {
    label:
      "3. Thống kê danh sách điểm đo đã kiểm tra áp giá có giá bệnh viện, trường học (CQBV)",
    value: Endpoint.BCAGBD_TK_DS_DIEM_DO_KIEM_TRA_CO_GIA_BENH_VIEN_TH,
  },
  {
    label:
      "4. Thống kê danh sách điểm đo đã kiểm tra áp giá có nhiều mục đích sử dụng điện trong cùng 1 công tơ",
    value: Endpoint.BCAGBD_TK_DS_DIEM_DO_SINH_HOAT_CO_NHIEU_MD_SU_DUNG_DIEN,
  },
  {
    label:
      "5. Thống kê danh sách điểm đo sinh hoạt đã kiểm tra áp giá có số định mức >=2",
    value:
      Endpoint.BCAGBD_TK_DS_DIEM_DO_SINH_HOAT_KT_CO_SO_DINH_MUC_LON_HON_BANG_2,
  },
];

export const selectBCThueTro = [
  {
    label:
      "1. Khách hàng cho thuê trọ đã áp dụng mức giá sinh hoạt bậc thang cho người thuê nhà để ở",
    value: Endpoint.BCAGBD_KH_CHO_THUE_TRO_DA_AP_DINH_MUC_SINH_HOAT_BAC_THANG,
  },
  {
    label:
      "2. Khách hàng cho thuê trọ đã áp dụng mức giá sinh hoạt bậc 3 cho người thuê nhà để ở",
    value: Endpoint.BCAGBD_KH_CHO_THUE_TRO_DA_AP_DINH_MUC_SINH_HOAT_CUA_BAC_3,
  },

  {
    label:
      "3. Báo cáo kiểm tra , giám sát ,công tác thu tiền điện người thuê nhà",
    value: Endpoint.BCAGBD_KTGS_CONG_TAC_THU_TIEN_DIEN,
  },
  {
    label:
      "4. Báo cáo chi tiết kiểm tra, giám sát công tác thu tiền điện của người thuê nhà",
    value: Endpoint.BCAGBD_CHI_TIET_KTGS_CONG_TAC_THU_TIEN_DIEN,
  },
]

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
    })
  );
  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
