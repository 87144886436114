import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, authGetMultiplesRequest } from "@utils/request";

//api list yêu cầu
export const handleListData = (
  filterConditions,
  setLoading,
  setDataSource,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.LIST_DATA_BANG_KE}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataSource(res.data);
        setTotalCount(res.data.length);
      }
    },
  });
};

export const handleMultiBoLoc = (
  setLoading,
  setDonVi,
  setLoaiBangKe,
  setIdDonViTCT
) => {
  const endpoints = [
    `${Endpoint.GNDN_DANH_MUC_DON_VI_GIAO_NHAN}`,
    `${Endpoint.LIST_LOAI_BANG_KE}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      const arr = [];
      if (res[0].data.data) {
        res[0].data.data.forEach((item) => {
          if (
            item.laDongBo === true &&
            item.maDonVi !== "HT" &&
            item.maDonVi !== "HY" &&
            item.maDonVi !== "LT" &&
            item.maDonVi !== "PDBC" &&
            item.maDonVi !== "PDHD" &&
            item.maDonVi !== "PDTC" &&
            item.maDonVi !== "VP"
          ) {
            arr.push(item);
          }
        });
      }
      if (res[0].data.data) {
        res[0].data.data.forEach((item) => {
          if (item.maDonVi === "PD") {
            setIdDonViTCT(item.id);
          }
        });
      }
      setDonVi(arr);

      setLoaiBangKe(res[1].data.data);
    },
  });
};

export const handleXemBienBan = (url, setLoading, onComplete) => {
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        onComplete(res.data);
      } else {
        onComplete("error");
      }
    },
  });
};
