import { REACT_APP_MAP_API_KEY } from "@utils/constants";
import { Spin } from "antd";
import React, { useEffect, useRef } from "react";
import TramIcon from "@modules/lo-trinh-nhan-vien/assets/tram-bien-ap-icon.png";
import DiemDoDangHDIcon from "@modules/lo-trinh-nhan-vien/assets/diem-do-dang-hd-icon.png";
import DiemDoNgungHDIcon from "@modules/lo-trinh-nhan-vien/assets/diem-do-ngung-hd-icon.png";
import moment from "moment";
function MapContentComponent({ viTriDiemDo }) {
  const ref = useRef();

  useEffect(() => {
    const map = new window.mapboxgl.Map({
      container: ref.current,
      center: [105.842392, 21.025755],
      zoom: 9.5,
    });
    new window.ekmapplf.VectorBaseMap("OSM:Bright", REACT_APP_MAP_API_KEY).addTo(map);

    // Đánh dấu vị trí trạm

    if (viTriDiemDo && viTriDiemDo.length) {
      viTriDiemDo.map((diemDo) => {
        const tramPopUp = document.createElement("div");
        const icon = TramIcon;
        const color = "blue";

        tramPopUp.className = "cursor-pointer";
        tramPopUp.innerHTML = `<div style="color: ${color};"class="font-bold"><img src=${icon} width=15 height=15 alt="icon-substation"><div>${diemDo.maTram}</div></div>`;

        const popup = new window.mapboxgl.Popup({
          offset: 25,
        })
          .setHTML(
            `
        <div style="text-align: left">
        <p style="color: blue; font-weight: bold;">Trạm biến áp: ${diemDo?.tenTram ? diemDo.tenTram : ""}</p>
        <p>Đơn vị quản lý: ${diemDo?.donVi ? diemDo.donVi : ""}</p>
        <p>Mã trạm: ${diemDo?.maTram ? diemDo.maTram : ""}</p>
        <p>Địa chỉ trạm biến áp: ${diemDo?.diaChiTram ? diemDo.diaChiTram : ""}</p>
        <p>Loại trạm: ${diemDo?.loaiTram ? diemDo.loaiTram : ""}</p>
        <p>Công suất - cấp điện áp: ${diemDo?.congSuat ? diemDo.congSuat : ""}</p>
        </div>
        `
          )
          .setMaxWidth("500px");
        if (!diemDo.longitudeTram || !diemDo.latitudeTram || diemDo.latitudeTram < -90 || diemDo.latitudeTram > 90) {
          return <></>;
        }
        return new window.mapboxgl.Marker(tramPopUp)
          .setLngLat([diemDo.longitudeTram || 0, diemDo.latitudeTram || 0])
          .setPopup(popup)
          .addTo(map);
      });
    }

    // Điểm đo
    if (viTriDiemDo && viTriDiemDo.length) {
      viTriDiemDo.map((diemDo) => {
        const hinhAnhDiemDo = diemDo.anhCongTo || [];
        const el = document.createElement("div");
        let icon = DiemDoDangHDIcon;
        let color = "blue";
        if (diemDo.trangThaiDiemDo === "Ngừng hoạt động") {
          icon = DiemDoNgungHDIcon;
          color = "red";
        }
        el.className = "cursor-pointer";
        el.innerHTML = `<div style="color: ${color};"class="font-bold"><img src=${icon} width=15 height=15 alt="icon-substation"><div>${diemDo.maDiemDo}</div></div>`;

        const popup = new window.mapboxgl.Popup({ offset: 25 })
          .setHTML(
            `
          <div style='text-align: left'>
         <div>
          <p style="color: blue; font-weight: bold">Mã điểm đo: ${diemDo?.maDiemDo ? diemDo.maDiemDo : ""} - Mã công tơ: ${
              diemDo?.maCongTo ? diemDo.maCongTo : ""
            }</p>
          <p>Mã khách hàng: ${diemDo?.maKH ? diemDo.maKH : ""} - Tên khách hàng: ${diemDo?.tenKH ? diemDo.tenKH : ""}</p>
          <p>Địa chỉ sử dụng điện: ${diemDo?.diaChiSuDungDien ? diemDo.diaChiSuDungDien : ""}</p>
          <p>Mã GC: ${diemDo?.maGC ? diemDo.maGC : ""}</p>
          <p>Đơn vị quản lý: ${diemDo?.donVi ? diemDo.donVi : ""}</p>
          <p>Đội quản lý: ${diemDo?.toDoi ? diemDo.toDoi : ""}</p>
          <p>Trạng thái điểm đo: ${diemDo.trangThaiDiemDo}</p>
          <p>
            Ngày giờ cập nhật: ${moment(diemDo.ngayCapNhat).format("YYYY-MM-DD HH:mm:ss")}
          </p>
        </div>
        <div style="display: flex; justify-content: space-between">
        <img src=${hinhAnhDiemDo[0] || ""} width=120 alt="hinh anh cong to">
        <img src=${hinhAnhDiemDo[1] || ""} width=120 alt="hinh anh cong to">
        <img src=${hinhAnhDiemDo[2] || ""} width=120 alt="hinh anh cong to">
        </div>
          </div>
        `
          )
          .setMaxWidth("500px");

        if (!diemDo.longitudeDiemDo || !diemDo.latitudeDiemDo || diemDo.latitudeDiemDo < -90 || diemDo.latitudeDiemDo > 90) {
          return <></>;
        }
        return new window.mapboxgl.Marker(el)
          .setLngLat([diemDo.longitudeDiemDo || 0, diemDo.latitudeDiemDo || 0])
          .setPopup(popup)

          .addTo(map);
      });
    }
  });
  return <div ref={ref} style={{ width: "100%", height: "100%" }}></div>;
}

function MapComponent({ viTriDiemDo, loading }) {
  return (
    <div style={{ width: "100%", height: "100%", padding: 10 }}>
      {loading ? <Spin /> : <MapContentComponent viTriDiemDo={viTriDiemDo} />}
    </div>
  );
}

export default MapComponent;
