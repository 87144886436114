import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
  FORMAT_MONTH_ENGLISH,
  INDEX_TAB2,
} from "@utils/constants";
import {
  buildQueryString,
  formatSplitDate,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";

import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { handleListBaoCao, handleListColumns } from "./services";
import BaoCaoChiTietTable from "./table";
import moment from "moment";
import { closeModal, openModal } from "@state/system-config/reducer";
import ThongTinChiTietTabs from "../../common-ho-so/chi-tiet-ho-so";
import { Endpoint } from "@utils/endpoint";
import { downloadFile } from "@utils/request";

export default function Index(props) {
  const {
    redirect,
    setRedirect,
    setIsSubmit,
    isSubmit,
    newFilter,
    keyTabs,
    // callFirst,
    // setCallFirst,
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { user } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);
  //filter
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    ngayBaoCao: searchParams.get("ngayBaoCao")
      ? searchParams.get("ngayBaoCao")
      : newFilter && newFilter.ngayBaoCao
      ? newFilter.ngayBaoCao
      : formatSplitDate(moment().format(FORMAT_MONTH_ENGLISH), true),
    donViId: searchParams.get("donViId")
      ? searchParams.get("donViId")
      : user.unitId,
    loaiHoSo: "1",
    loaiBaoCao: "1",
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //change pagination
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
    if (keyTabs === INDEX_TAB2) {
      setRedirect(true);
    }
  };

  //fetch báo cáo
  const getListBaoCao = useCallback(() => {
    handleListBaoCao(filterConditions, setLoading, setData, setTotalCount);
  }, [filterConditions]);

  const [value, setValue] = useState(
    filterConditions && filterConditions.loaiBaoCao
      ? filterConditions.loaiBaoCao
      : "1"
  );
  const handleChange = (value) => {
    setValue(value);
  };

  const [filterExcel, setFilterExcel] = useState(false);

  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type]);
  //search
  const handleSearch = useCallback(
    (values) => {
      // const thang = moment(values.thangBaoCao);
      if (value === "4") {
        setFilterConditions(() => ({
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
          ...values,
          donViId: values.donViId,
        }));
      } else {
        const date = moment(values.ngayBaoCao).format(FORMAT_ENGLISH);
        setFilterConditions(() => ({
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
          ...values,
          donViId: values.donViId,
          ngayBaoCao: date,
          // ngayBaoCao: formatSplitDate(values.ngayBaoCao, true),
        }));
      }
      handleAPI();
      if (newFilter !== undefined) setRedirect(true);
    },
    [newFilter, setRedirect, value, handleAPI]
  );

  //clear search
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
      ngayBaoCao: formatSplitDate(moment().format(FORMAT_MONTH_ENGLISH), true),
      loaiHoSo: "1",
      loaiBaoCao: "1",
    });
    setValue("1");
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  }, [user.unitId]);

  useEffect(() => {
    if (filterExcel) {
      const endpoint = `${
        Endpoint.EXPORT_EXCEL_BAO_CAO_CHI_TIET
      }?${buildQueryString(parseParams(filterConditions))}`;
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel, isSubmit, keyTabs]);

  useEffect(() => {
    if (
      !isSubmit &&
      keyTabs === undefined &&
      type === ENUM_BUTTON_TYPE.TIM_KIEM
    ) {
      getListBaoCao();
    }
  }, [getListBaoCao, isSubmit, keyTabs, type]);

  useEffect(() => {
    if (type === ENUM_BUTTON_TYPE.TIM_KIEM) {
      // if ((redirect && keyTabs === INDEX_TAB2) || callFirst) {
      //   getListBaoCao();
      //   setRedirect(false);
      //   setIsSubmit(false);
      //   // setCallFirst(false);
      // }
      if (redirect && keyTabs === INDEX_TAB2) {
        getListBaoCao();
        setRedirect(false);
        setIsSubmit(false);
        // setCallFirst(false);
      }
    }
  }, [
    // callFirst,
    filterConditions,
    getListBaoCao,
    isSubmit,
    keyTabs,
    newFilter,
    redirect,
    // setCallFirst,
    setIsSubmit,
    setRedirect,
    type,
  ]);

  useEffect(() => {
    if (
      isSubmit &&
      keyTabs === INDEX_TAB2 &&
      type === ENUM_BUTTON_TYPE.TIM_KIEM
    ) {
      setFilterConditions(newFilter);
    }
  }, [filterConditions, isSubmit, keyTabs, newFilter, type]);

  const [dataColumns, setDataColumns] = useState([]);

  //fetch columns
  const getListColumns = useCallback(() => {
    handleListColumns(setLoading, setDataColumns, dispatch);
  }, [dispatch]);

  useEffect(() => {
    getListColumns();
  }, [getListColumns]);

  const detailHoSo = useCallback(
    (record) => {
      return {
        onDoubleClick: () => {
          dispatch(
            openModal({
              content: (
                <ThongTinChiTietTabs
                  detailHoSo={record}
                  closeModal={closeModal}
                />
              ),
              size: "large",
              title: `Thông tin chi tiết hồ sơ`,
            })
          );
        },
      };
    },
    [dispatch]
  );

  const renderComponent = useCallback(() => {
    if (dataColumns.length > 0 && filterConditions)
      return (
        <BaoCaoChiTietTable
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          totalCount={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          columns={dataColumns}
          data={data}
          getListColumns={getListColumns}
          newFilter={newFilter}
          onRow={detailHoSo}
          handleChange={handleChange}
          value={value}
          setType={setType}
        />
      );
  }, [
    clearFilter,
    data,
    dataColumns,
    detailHoSo,
    filterConditions,
    getListColumns,
    handleSearch,
    loading,
    newFilter,
    onChangePagination,
    totalCount,
    value,
  ]);

  return <Fragment>{renderComponent()}</Fragment>;
}
