import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useMemo,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import Filter from "../../common/Filter";
import { Container, TableStyled } from "../../common/ky-bang-ke/css-styled";
import TableComponent from "@components/TableComponent";
import { Input, Form, Row, Col, Button, Tag, Modal } from "antd";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilterKyBangKe } from "./list-bo-loc";
import PhongKDTraBKModal from "./modal-pkd-tra-bk";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_LOAI_BANG_KE,
  ENUM_TRANGTHAI_PKDKBK,
  ENUM_KY_GCS,
} from "@utils/constants";
import { handleListDataPhongKDKBK, submitPhongKDXacNhan, submitPhongKDKyHSM } from "./services";
// import { Endpoint } from "@utils/endpoint";
import { ghi_chi_so } from "@permissions/d_gcs";
// import KySoComponent from "@components/KySo";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import CheckPermission from "@components/CheckPermission";
import moment from "moment";
import "moment/locale/vi";
// import LichSuSoModal from "../../common/modal/lich-su-so/modal-lich-su-so";
import { getListLoaiBK } from "../../cau-hinh/cau-hinh-ky-bang-ke/services";

export default function Index() {
  const { t } = useTranslation();
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [dataPhongKDKBK, setDataPhongKDKBK] = useState([]);
  const [id, setID] = useState([]);
  // const [lichGCSId, setLichGCSId] = useState([]);
  // const [tenSoGCS, setTenSoGCS] = useState();
  // const [ky, setKy] = useState();
  // const [thangNam, setThangNam] = useState();
  // const [visibleLichSu, setVisibleLichSu] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loaiBK, setLoaiBK] = useState([]);
  const [firstLoaiBK, setFirstLoaiBK] = useState("");
  const [tempDataPhongKDKBK, setTempDataPhongKDKBK] = useState([]);
  const [tempTotalCount, setTempTotalCount] = useState(0);
  const [, setSearchParams] = useSearchParams();
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [term, setTerm] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const location = useLocation();
  const [checked, setChecked] = useState();
  const [lyDoTraLai, setLyDoTraLai] = useState([]);
  const [filterConditions, setFilterConditions] = useState({});

  useEffect(() => {
    getListLoaiBK(setLoading, setLoaiBK, setFirstLoaiBK);
  }, []);

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu
  const getListDataPhongKDKBK = useCallback(() => {
    handleListDataPhongKDKBK(
      setLoading,
      location,
      setDataPhongKDKBK,
      setTempDataPhongKDKBK,
      setTotalCount,
      setTempTotalCount,
      filterConditions,
      setSelectedRowKeys
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (firstLoaiBK !== "")
      {
        setFilterConditions({
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
          DonViId: user.unitId,
          ...parseParams(location.search),
          Ky: ENUM_KY_GCS.KY_1,
          TrangThai: ENUM_TRANGTHAI_PKDKBK.CHUA_KY.toString(),
          NgayGhi: moment().format("YYYY-MM-DD"),
          LoaiBangKeId: firstLoaiBK,
          orderBy: "maSo"
        });
      }
    // eslint-disable-next-line
  }, [firstLoaiBK]);

  useEffect(() => {
    if (filterConditions && Object.keys(filterConditions).length > 0) {
      getListDataPhongKDKBK();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListDataPhongKDKBK]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListDataPhongKDKBK();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListDataPhongKDKBK, setFetchDataTable]);

  //pagination table
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const changeTerm = (value) => {
    setTerm(value.target.value);
  };

  //tìm kiếm dữ liệu
  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        pageIndex: DEFAULT_PAGEINDEX,
        ...values,
        BiTraLai: checked,
        MaSo: values.MaSo === undefined ? values.MaSo : values.MaSo.trim(),
        TenSo: values.TenSo === undefined ? values.TenSo : values.TenSo.trim(),
      }));
    },
    [checked]
  );

  const SearchTerm = useCallback(() => {
    if (term.trim().length > 0) {
      let listTerm = [];
      listTerm = tempDataPhongKDKBK.filter(
        (data) =>
          data.maSo.toUpperCase().indexOf(term.trim().toUpperCase()) > -1 ||
          data.tenSo.toUpperCase().indexOf(term.trim().toUpperCase()) > -1
      );
      setDataPhongKDKBK(listTerm);
      setTotalCount(listTerm.length);
    } else {
      setDataPhongKDKBK(tempDataPhongKDKBK);
      setTotalCount(tempTotalCount);
    }
  }, [tempDataPhongKDKBK, tempTotalCount, term]);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      Ky: ENUM_KY_GCS.KY_1,
      TrangThai: ENUM_TRANGTHAI_PKDKBK.CHUA_KY.toString(),
      NgayGhi: moment().format("YYYY-MM-DD"),
      LoaiBangKeId: firstLoaiBK,
      orderBy: "maSo"
    });
  }, [firstLoaiBK, user.unitId]);

  const columns = [
    {
      title: t("ky_bang_ke.ma_so"),
      dataIndex: "maSo",
      key: "maSo",
      render: (text) => (
        <div className="table-text-left">
          <span
            // style={{
            //   textDecoration: "underline",
            //   color: "blue",
            //   cursor: "pointer",
            // }}
            // onClick={() =>
            //   handleOpenLichSuSoModal(
            //     record.id,
            //     record.tenSo,
            //     record.ky,
            //     record.thangName
            //   )
            // }
          >
            {text}
          </span>
        </div>
      ),
      width: "10%",
      sorter: true,
      fixed: "left",
    },
    {
      title: t("ky_bang_ke.ten_so"),
      dataIndex: "tenSo",
      key: "tenSo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "13%",
      sorter: true,
    },
    {
      title: t("ky_bang_ke.hinh_thuc_gcs"),
      dataIndex: "hinhThuc",
      key: "hinhThuc",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: t("ky_bang_ke.ky_gcs"),
      dataIndex: "ky",
      key: "ky",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: t("ky_bang_ke.ngay_ghi"),
      dataIndex: "ngayGhi",
      key: "ngayGhi",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: t("ky_bang_ke.to_doi"),
      dataIndex: "toDoi",
      key: "toDoi",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "13%",
    },
    {
      title: t("ky_bang_ke.nhan_vien_gcs"),
      dataIndex: "tenNhanVien",
      key: "tenNhanVien",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "13%",
    },
    {
      title: t("ky_bang_ke.loai_bang_ke"),
      dataIndex: "loaiBangKe",
      key: "loaiBangKe",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: t("ky_bang_ke.trang_thai"),
      dataIndex: "trangThai",
      key: "trangThai",
      className: "text-center",
      render: (text, record) => (
        <div className="table-text-center">
          {record.trangThai === ENUM_TRANGTHAI_PKDKBK.CHUA_KY ? (
            "Chưa ký"
          ) : record.trangThai === ENUM_TRANGTHAI_PKDKBK.DA_KY &&
            record.traLai !== 0 ? (
            <div>
              Đã ký{" "}
              <Tag color={"red"} style={{ fontSize: 14 }}>
                {"bị trả lại"}
              </Tag>
            </div>
          ) : (
            "Đã ký"
          )}
        </div>
      ),
      width: "9%",
    },
    {
      title: t("ky_bang_ke.tac_vu"),
      dataIndex: "",
      key: "tacVu",
      className: "text-center",
      render: (record) => (
        <span>
          {record.filePDF ?
            <CheckPermission permissionCode={ghi_chi_so.d41v}>
              <Button
                style={{ marginTop: 0, marginRight: 0, float: "none" }}
                type="primary"
              >
                <a
                  href={record.filePDF}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Xem
                </a>
              </Button>
            </CheckPermission> :
            null}
          <CheckPermission permissionCode={ghi_chi_so.d41tl}>
            {(record.trangThai === ENUM_TRANGTHAI_PKDKBK.CHUA_KY ||
              (record.trangThai !== ENUM_TRANGTHAI_PKDKBK.CHUA_KY &&
                record.traLai !== 0)) &&
              record.loaiBangKe === ENUM_LOAI_BANG_KE.BKCS.toString() ? (
              <Button
                type="danger"
                style={{
                  marginLeft: 8,
                }}
                onClick={() => handleOpenModal(record.id, record.lyDoTraLai)}
              >
                Trả lại
              </Button>
            ) : null}
          </CheckPermission>
        </span>
      ),
    },
  ];

  //open popup
  const handleOpenModal = (id, lyDoTraLai) => {
    setVisible(true);
    setID(id);
    setLyDoTraLai(lyDoTraLai);
  };

  //đóng popup
  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
    // setVisibleLichSu(false);
  };

  //render popup
  const showModal = useMemo(
    () =>
      visible && (
        <PhongKDTraBKModal
          visible={visible}
          closeModal={handleCloseModal}
          setFetchDataTable={setFetchDataTable}
          id={id}
          lyDoTraLai={lyDoTraLai}
        />
      ),
    [visible, id, lyDoTraLai]
  );

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: "2%",
    fixed: "left",
    getCheckboxProps: (record) => ({
      disabled:
        record.trangThai === ENUM_TRANGTHAI_PKDKBK.DA_KY && record.traLai === 0,
    }),
  };

  const handleChangeCheckBox = useCallback((e) => {
    setChecked(e.target.checked === true ? 4 : null);
  }, []);

  //open popup lịch sử sổ
  // const handleOpenLichSuSoModal = (lichGCSId, tenSoGCS, ky, thangNam) => {
  //   setVisibleLichSu(true);
  //   setLichGCSId(lichGCSId);
  //   setTenSoGCS(tenSoGCS);
  //   setKy(ky);
  //   setThangNam(thangNam);
  // };

  //render popup lịch sử sổ
  // const showLichSuSoModal = useMemo(
  //   () =>
  //     visibleLichSu && (
  //       <LichSuSoModal
  //         visible={visibleLichSu}
  //         closeModal={handleCloseModal}
  //         lichGCSId={lichGCSId}
  //         tenSoGCS={tenSoGCS}
  //         ky={ky}
  //         thangNam={thangNam}
  //       />
  //     ),
  //   [visibleLichSu, lichGCSId, tenSoGCS, ky, thangNam]
  // );

  //Phòng kinh doanh xác nhận
  const handlePhongKDXacNhan = () => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: `Các bảng kê được chọn sẽ chuyển sang bước Đẩy sổ lên CMIS!`,
      onOk() {
        //call api
        submitPhongKDXacNhan(t, setLoading, setFetchDataTable, selectedRowKeys);
        setSelectedRowKeys([]);
      },
      onCancel() { },
      okText: "Đồng ý",
      cancelText: "Hủy",
    });
  };

  //Phòng kinh doanh Ký HSM
  const handlePhongKDKyHSM = () => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: `Các bảng kê được chọn sẽ chuyển sang bước xử lý tiếp theo!`,
      onOk() {
        //call api
        submitPhongKDKyHSM(setLoading, setFetchDataTable, selectedRowKeys, setSelectedRowKeys);
      },
      onCancel() { },
      okText: "Đồng ý",
      cancelText: "Hủy",
    });
  };

  return (
    <Fragment>
      <Filter>
        <ListFilterKyBangKe
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          checked={checked}
          handleChangeCheckBox={handleChangeCheckBox}
          loaiBK={loaiBK}
          firstLoaiBK={firstLoaiBK}
        />
      </Filter>
      <Container>
        <Row style={{ marginBottom: -18 }}>
          <span style={{ marginTop: 6, marginLeft: 10 }}>
            Danh sách bảng kê
          </span>
          <Col span={24} md={8}>
            <Input
              placeholder="Nhập mã sổ, tên sổ"
              style={{ marginBottom: 20, marginLeft: 20, width: 300 }}
              onChange={changeTerm}
              onPressEnter={() => SearchTerm()}
            />
          </Col>
        </Row>
        <CheckPermission permissionCode={ghi_chi_so.d41ky}>
          <Button
            className="btn-confirm"
            type="primary"
            disabled={!selectedRowKeys.length}
            onClick={() => handlePhongKDKyHSM()}
            // style={{ right: 195 }}
            style={{ right: 100 }}
            loading={loading}
          >
            Ký số HSM
          </Button>
        </CheckPermission>
        <CheckPermission permissionCode={ghi_chi_so.d41xn}>
          <Button
            className="btn-confirm"
            type="primary"
            disabled={!selectedRowKeys.length}
            onClick={() => handlePhongKDXacNhan()}
          >
            Xác nhận
          </Button>
        </CheckPermission>
        {/* <KySoComponent
          url={Endpoint.GCS_KY_SO}
          urlGetHash={Endpoint.PHONG_KD_KY_SO}
          payload={{
            id: selectedRowKeys,
          }}
          permissionCode={ghi_chi_so.d41ky}
          disabled={!selectedRowKeys.length}
          setFetchDataTable={setFetchDataTable}
          reload={getListDataPhongKDKBK}
          setLoading={setLoading}
          loading={loading}
        /> */}
        <TableStyled>
          <TableComponent
            header={t("ky_bang_ke.ky_bang_ke")}
            dataSource={dataPhongKDKBK}
            listFilter={<ListFilterKyBangKe form={form} />}
            columns={customColumn(columns, filterConditions)}
            totalData={totalCount}
            scrollX={2000}
            loading={loading}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            rowSelection={rowSelection}
          />
        </TableStyled>
      </Container>
      {showModal}
      {/* {showLichSuSoModal} */}
    </Fragment>
  );
}
