import { BASE_API_URL_BAO_CAO } from "@utils/constants";

export const APIBCCongTacDichVuKH = {
  // bao cao he sinh thai moi
  BCHST_BC_TONG_HOP: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/khsdhesinhthai/tong-hop-kh-su-dung-hst`,
  BCHST_TONG_HOP_SLKH_DANG_KY: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/khsdhesinhthai/tong-hop-slkh-dang-ky-nhan-tb-qua-hst`,
  BCHST_CHI_TIET_HST_KH: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/khsdhesinhthai/chi-tiet-khsd-he-sinh-thai`,
  BCHST_TH_KHACH_HANG_SU_DUNG_HST: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/khsdhesinhthai/bc-tong-hop-kh-su-dung-he-sinh-thai`,

  //Báo cáo dịch vụ công
  BCCTDVKH_BAO_CAO_CTKH_DANG_KY_DV_CONG: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/bcdvcong/chi-tiet-kh-dang-ky-dv-cong-cua-cac-dv`,
  BCCTDVKH_TIEP_NHAN_DANG_KY_DV_DIEN_DT: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/bcdvcong/tiep-nhan-dkdvd-dien-tu`,
  BCCTDVKH_TH_THU_CSKH_CUA_CAC_DON_VI: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/bcdvcong/tong-hop-thu-cskh-cua-cac-don-vi`,
  BCCTDVKH_BC_YEU_CAU_THUC_HIEN_THEO_PHUONG_THUC_DIEN_TU: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/bcdvdt/cac-yc-thuc-hien-theo-ptdt`,
  BCCTDVKH_BC_GIAI_QUYET_QUA_HAN_CAC_DICH_VU_DIEN: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/bcdvdt/giai-quyet-qh-cac-dvd-trong-qtthhdmb`,
  BCCTDVKH_BC_TIEP_NHAN_DANG_KY_DICH_VU_DIEN_TU: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/bcdvdt/tiep-nhan-dang-kydvddt`,
  BCCTDVKH_TY_LE_TT_DVD_KHONG_DUNG_TIEN_MAT: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/bcdvcong/tltt-dv-dien-khong-dung-tien-mat`,
  BCCTDVKH_TY_LE_TT_DVD_KHONG_DUNG_TIEN_MAT_CT: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/bcdvcong/tltt-dv-dien-khong-dung-tien-mat-chi-tiet`,
  //Báo cáo kinh doanh mới
  BCCTDVKH_BC_DSKH_DIEU_CHINH_HOA_DON: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/bckdmoi/ds-khach-hang-dieu-chinh-hoa-don`,
  BCCTDVKH_BC_THKH_DIEU_CHINH_HOA_DON: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/bckdmoi/tong-hop-kh-dieu-chinh-hoa-don`,
  BCCTDVKH_BC_KH_LAP_DAT_KY_HD_DIEN_MTAM: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/bckdmoi/ve-viec-kh-lap-dat-va-khdd-mat-troi-mn`,
  BCCTDVKH_BC_THANH_TOAN_TIEN_MTAM_CHO_KH: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/bckdmoi/ve-viec-tttdd-mat-troi-mai-nha-cho-kh`,

  //Báo cáo theo dõi công tác dịch vụ khách hàng trên CMIS3
  BCCTDVKH_LOAI_YEU_CAU: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/bctdctdvkhcmis3/loai-yeu-cau`,
  BCCTDVKH_THSL_12_DICH_VU_DIEN_LUC_THEO_PT_DIEN_TU: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/bctdctdvkhcmis3/thsl-12-dv-dien-th-theo-ptdt`,
  BCCTDVKH_BC_GIAI_QUYET_QUA_HAN_CAC_DICH_VU_DIEN_HBMBĐ: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/bctdctdvkhcmis3/gq-qua-han-cdv-dien-trong-qtthhdmbd`,
  BCCTDVKH_TH_SO_KHACH_HANG_CAI_APP_ZALO: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/bctdctdvkhcmis3/th-so-kh-cai-app-zalo`,
  BCCTDVKH_BC_THEO_DOI_DVKH_CMIS3: `${BASE_API_URL_BAO_CAO}/bckd/bcctdvkh/bctdctdvkhcmis3/td-cong-tac-dvkh-tren-cmis3`,
};
