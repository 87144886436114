/* eslint-disable no-unused-vars */
import React, { memo, useState } from "react";
import { handleKTLai } from "../services";
import ConfirmModal from "./modal-confirm";
function Index({
  visibleKiemTraLai,
  handleCloseModal,
  setVisibleKiemTraLai,
  id,
  handleLayDuLieu,
}) {
  const [loading, setLoading] = useState(false);
  // call api kiểm tra lại

  const handleSubmit = () => {
    const data = {
      congToIds: id,
    };
    handleKTLai(data, setLoading, setVisibleKiemTraLai, handleLayDuLieu);
  };
  return (
    <ConfirmModal
      loading={loading}
      visible={visibleKiemTraLai}
      closeModal={handleCloseModal}
      handleSubmit={handleSubmit}
    />
  );
}

export default memo(Index);
