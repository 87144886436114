import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import Table from "./table";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  TABINDEX_CMIS,
} from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { handleListDataNN } from "./services";
import { Endpoint } from "@utils/endpoint";
import { dongBoCMIS } from "../services";
import { Button } from "antd";
import CheckPermission from "@components/CheckPermission";
import { tien_ich_chung } from "@permissions/m_tienichchung";

export default function Index() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [dataNN, setDataNN] = useState([]);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  //khai báo columns
  const columns = [
    {
      title: "Mã ngành nghề",
      dataIndex: "maNN",
      key: "maNN",
      render: (text) => <span>{text}</span>,
      width: "20%",
      sorter: true,
    },
    {
      title: "Mã nhóm ngành nghề",
      dataIndex: "maNhomNN",
      key: "maNhomNN",
      render: (text) => <span>{text}</span>,
      width: "20%",
      sorter: true,
    },
    {
      title: "Tên ngành nghề",
      dataIndex: "tenNN",
      key: "tenNN",
      render: (text) => <span>{text}</span>,
    },
  ];

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);
  //fetch dữ liệu

  const getListDataNN = useCallback(() => {
    handleListDataNN(setLoading, filterConditions, setDataNN, setTotalCount);
  }, [filterConditions]);

  useEffect(() => {
    getListDataNN();
    // eslint-disable-next-line
  }, [getListDataNN]);

  //thay đổi pagination table
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  // Hiển thị button đồng bộ, ...
  const genExtra = () => (
    <CheckPermission permissionCode={tien_ich_chung.m126cmis}>
      <Button
        size="small"
        type="primary"
        className="btn-bg-yellow"
        onClick={handleDongBoCMIS}
        loading={loading}
      >
        Đồng bộ CMIS
      </Button>
    </CheckPermission>
  );

  const handleDongBoCMIS = useCallback(() => {
    const url = Endpoint.DONG_BO_CMIS + `?tabIndex=${TABINDEX_CMIS.NGANH_NGHE}`;
    dongBoCMIS(url, setLoading, getListDataNN);
  }, [getListDataNN]);

  //tìm kiếm dữ liệu
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  return (
    <Fragment>
      <Table
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        genExtra={genExtra}
        dataSource={dataNN}
        columns={columns}
        totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        t={t}
      />
    </Fragment>
  );
}
