import FormComponent from "@components/Form";
import ListAnh from "./list-anh/index";
import { dataURLtoFile } from "@utils/function";
import { Button, Divider, Form } from "antd";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import UploadImage from "./tai-anh/index";
import { Style } from "../css-styled";
import { TaiAnhHoSo } from "../crm-services";
function TaiAnhHoSoTab({ detail, closeModal }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState([]);
  const [dataImage, setDataImage] = useState();
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const [loadingListAnh, setLoadingListAnh] = useState(false);

  const handleFinish = useCallback(() => {
    const file = dataURLtoFile(imageFile[0].base64Data, imageFile[0].name);
    TaiAnhHoSo(
      setLoading,
      file,
      setImageFile,
      detail,
      setDataImage,
      setIsSubmit,
      setLoadingListAnh
    );
  }, [detail, imageFile]);

  return (
    <Style>
      <div className="tai-anh-ho-so-tab">
        <FormComponent form={form} onFinish={handleFinish}>
          <div className="tai-anh-ho-so-container">
            <Button
              type="primary"
              htmlType="submit"
              disabled={imageFile && imageFile.length < 1}
              loading={loading}
              style={{ margin: "12px 36px" }}
            >
              Tải ảnh
            </Button>
            <UploadImage
              setImageFiles={setImageFile}
              imageFiles={imageFile}
              isSubmit={isSubmit}
              loading={loading}
            />
          </div>
          <div className="danh-sach-anh">
            <Divider>Danh sách ảnh đã tải</Divider>
            <div className="danh-sach-anh-list">
              <ListAnh
                detail={detail}
                setDataImage={setDataImage}
                dataImage={dataImage}
                setLoadingListAnh={setLoadingListAnh}
                loadingListAnh={loadingListAnh}
              />
            </div>
          </div>
          <div className="ant-modal-footer text-center">
            <Button type="default" onClick={() => dispatch(closeModal())}>
              Đóng lại
            </Button>
          </div>
        </FormComponent>
      </div>
    </Style>
  );
}

export default TaiAnhHoSoTab;
