import { BASE_API_URL_GHI_CHI_SO } from "@utils/constants";
export const APIDieuHanhGCS = {
  LIST_PHAN_CONG_DH: `${BASE_API_URL_GHI_CHI_SO}/phancong/dieu-hanh-phan-cong`,
  LIST_PHAN_CONG_DT: `${BASE_API_URL_GHI_CHI_SO}/phancong/doi-truong-phan-cong`,
  PHAN_CONG: `${BASE_API_URL_GHI_CHI_SO}/phancong`,
  HUY_PHAN_CONG: `${BASE_API_URL_GHI_CHI_SO}/phancong`,
  CHECK_PHAN_CONG: `${BASE_API_URL_GHI_CHI_SO}/phancong/kiem-tra-chi-so`,
  GCS_HUY_PC_NHIEU: `${BASE_API_URL_GHI_CHI_SO}/phancong/huy-phan-cong`,

  LIST_LAY_DU_LIEU_TU_CMIS: `${BASE_API_URL_GHI_CHI_SO}/lichgcs/lay-du-lieu-tu-cmis`,
  LIST_DAY_SO_VE_CMIS: `${BASE_API_URL_GHI_CHI_SO}/lichgcs/lay-danh-sach-day-ve-cmis`,
  LAY_DU_LIEU_TU_CMIS: `${BASE_API_URL_GHI_CHI_SO}/lichgcs/pull`,
  CHECK_TRA_SO_VE_CMIS: `${BASE_API_URL_GHI_CHI_SO}/lichgcs/cancel-check`,
  TRA_SO_VE_CMIS: `${BASE_API_URL_GHI_CHI_SO}/lichgcs/cancel`,
  DAY_SO_VE_CMIS: `${BASE_API_URL_GHI_CHI_SO}/lichgcs/push`,
  LIST_CAP_NHAT_DU_LIEU_SO: `${BASE_API_URL_GHI_CHI_SO}/lichgcs/lay-danh-sach-cap-nhat-du-lieu-so`,
  LIST_DS_DOI_SOAT_DL: `${BASE_API_URL_GHI_CHI_SO}/lichgcs/lay-danh-doi-soat-lieu-so`,
  LAY_CHI_SO_MDMS: `${BASE_API_URL_GHI_CHI_SO}/lichgcs/get-mdms`,
  CHUYEN_HHU: `${BASE_API_URL_GHI_CHI_SO}/lichgcs/chuyen-hhu`,
  CHUYEN_MTB: `${BASE_API_URL_GHI_CHI_SO}/lichgcs/chuyen-mtb`,
  LIST_SO_GCS_DOI_SOAT: `${BASE_API_URL_GHI_CHI_SO}/lichgcs/lay-danh-lich-gcs-can-doi-soat`,
  LIST_SO_GCS_DOI_SOAT_DH: `${BASE_API_URL_GHI_CHI_SO}/lichgcs/lay-danh-lich-gcs-dieu-hanh-can-doi-soat`,
  LIST_SO_GCS_DOI_SOAT_DT: `${BASE_API_URL_GHI_CHI_SO}/lichgcs/lay-danh-lich-gcs-doi-truong-can-doi-soat`,
  XUAT_GHI_LAI_MTB:  `${BASE_API_URL_GHI_CHI_SO}/lichgcs/xuat-ghi-lai-mtb`,
  TU_DONG_DS:  `${BASE_API_URL_GHI_CHI_SO}/lichgcs/tu-dong-doi-soat`,
  HOAN_THANH_DS:  `${BASE_API_URL_GHI_CHI_SO}/lichgcs/hoan-thanh-doi-soat`,
  CHECKBOX_DS: `${BASE_API_URL_GHI_CHI_SO}/chiso/checkbox-doi-soat`,
  CAP_NHAT_CHI_SO: `${BASE_API_URL_GHI_CHI_SO}/chiso/cap-nhap-chi-so`,
  HUY_DAY_SO_VE_CMIS: `${BASE_API_URL_GHI_CHI_SO}/lichgcs/huy-day-so`,

  GCS_NV_TAO_BANG_KE:  `${BASE_API_URL_GHI_CHI_SO}/lichgcs/tao-bang-ke`,
};
