import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  parseParams,
  getErrorMessage,
} from "@utils/function";
import { authGetData, authPostData } from "@utils/request";
// call api list trạng thái

export const handleListData = (
  setLoading,
  filterConditions,
  setDataTrangThai,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.LIST_TRANG_THAI}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataTrangThai(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataTrangThai([]);
    },
  });
};
// call api sửa trạng thái

const handleUpdateData = (data, setLoading, closeModal, getListData, form) => {
  authPostData({
    url: Endpoint.UPDATE_TRANG_THAI,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListData();
        closeModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};
// submit gọi api thêm hoặc sửa trạng thái

export const handleSubmitForm = (
  values,
  detaiDataTable,
  setLoading,
  closeModal,
  getListData,
  form,
  color
) => {
  const data = {
    ...values,
    mau: color.hex,
  };

  if (detaiDataTable.id) {
    data.id = detaiDataTable.id;
  }
  // return data;

  handleCreateUpdate(data, setLoading, closeModal, getListData, form);
};
// hàm gộp api thêm và sửa trạng thái

const handleCreateUpdate = (
  data,
  setLoading,
  closeModal,
  getListData,
  form
) => {
  if (data.id) {
    //update trạng thái

    handleUpdateData(data, setLoading, closeModal, getListData, form);
  }
};
