import { useState, useEffect } from "react";
import { Form } from "antd";
import ModalReturn from "./layout-modal";
import { handleReturn } from "./services";

export default function Index(props) {
  const {
    visible,
    closeModal,
    selectedRowKeys,
    setSelectedRowKeys,
    setFetchList,
  } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);
  const onFinish = () => {
    const payload = {
      ids: selectedRowKeys,
    };
    handleReturn(
      payload,
      setLoading,
      setFetchList,
      setSelectedRowKeys,
      closeModal
    );
  };
  return (
    <ModalReturn
      visible={visible}
      closeModal={closeModal}
      loading={loading}
      form={form}
      onFinish={onFinish}
    />
  );
}
