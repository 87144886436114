import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";

export const getTienDoThucHien = (setLoading, query, setData) => {
  authGetData({
    url: `${Endpoint.TIEN_DO}?${query}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setData(res.data.data);
      }
    },
  });
};
