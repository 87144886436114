/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { Select } from "antd";
import { authGetData } from "@utils/request";
import { removeAccents } from "@utils/function";
import { isUndefined } from "lodash";
const { Option } = Select;
const Selection = (props) => {
  const {
    // selectiName = "name",
    url,
    placeholder = "Tất cả",
    form,
    formKey,
    disabled,
    mode,
    setValue,
    handleDeselect,
    notClear,
    defaultValue,
    isCustom,
    valueKey,
    valueName,
    filterKey,
  } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(typeof url === "string" ? [] : url);

  const handleSetInitialValue = (data) => {
    const isCheck = data.some((item) => item.value === form.getFieldValue([formKey]));
    if (isCheck) {
      return;
    }
    form.setFieldsValue({ [formKey]: data[0].value });
  };

  const getData = () => {
    authGetData({
      url,
      setLoading,
      onSuccess: (res) => {
        if (res.length > 0) {
          setData(res);
        } else {
          if (!isUndefined(filterKey)) {
            const data = res.data.filter((item) => item.value !== filterKey);
            handleSetInitialValue(data);
            setData(data);
            return;
          }
          setData(res.data);
        }
      },
    });
  };

  useEffect(() => {
    if (url && typeof url === "string") {
      getData();
    }
  }, [url, filterKey]);
  const handleChange = useCallback(
    (value) => {
      if (form) {
        form.setFieldsValue({
          [formKey]: value,
        });
      }
      if (setValue) {
        setValue(value);
      }
    },
    [form, formKey, setValue]
  );
  return (
    <Select
      defaultValue={defaultValue}
      maxTagCount={mode === "multiple" || "tags" ? "responsive" : null}
      value={form && form.getFieldValue(formKey)}
      placeholder={placeholder}
      showSearch
      mode={mode}
      allowClear={notClear ? false : true}
      loading={loading}
      onChange={handleChange}
      onDeselect={handleDeselect}
      disabled={disabled}
      style={{ width: "100%" }}
      filterOption={(input, option) => {
        if (option && option.children) {
          return removeAccents(option.children).toLowerCase().indexOf(removeAccents(input).toLowerCase()) >= 0;
        }
      }}
    >
      {data && data.length
        ? data.map((item, idx) => (
            <Option key={idx} value={item.value ? item.value.toString() : isCustom && valueKey ? item[valueKey] : item.value}>
              {/* {item[selectiName]} */}
              {isCustom && valueName ? item[valueName] : item.name}
            </Option>
          ))
        : undefined}
    </Select>
  );
};

export default Selection;
