import { LockOutlined, UserOutlined } from "@ant-design/icons";
import FormComponent from "@components/Form";
import { signInFinish, signInSSO } from "@state/auth/reducer";
import { STATUSCODE_200, UNGDUNG_KINHDOANH } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { getErrorForm, redirectSso } from "@utils/function";
import { postData } from "@utils/request";
import { Button, Form, Input, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
export default function LoginForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [form] = Form.useForm();

  const params = new URLSearchParams(window.location.search);
  const ticket = params.get("ticket");

  const inputRef = useRef(null);
  const onFinish = (values) => {
    postData({
      url: `${Endpoint.LOGIN}`,
      method: "POST",
      payload: {
        ...values,
      },
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          dispatch(signInFinish(res.data));
        } else {
          getErrorForm(res, form);
        }
      },
    });
  };

  useEffect(() => {
    inputRef.current.focus();
    if (ticket) {
      postData({
        url: `${Endpoint.LOGIN_SSO}`,
        setLoading,
        payload: { ticket: ticket, appCode: UNGDUNG_KINHDOANH },
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res?.data?.accessToken) {
            dispatch(signInFinish(res?.data?.accessToken));
            dispatch(signInSSO());
            return;
          }
          // logout sso
          setDisableSubmit(true);
          setTimeout(() => {
            setDisableSubmit(false);
            redirectSso({ type: "logout" });
          }, 5000);
          getErrorForm(res, form);
        },
      });
    }
  }, []);

  const onSubmitSSO = () => {
    redirectSso({ type: "login" });
  };

  return (
    <Container>
      <Spin spinning={loading}>
        <div className="title">
          <span>{t("dang_nhap")}</span>
        </div>
        <FormComponent form={form} name="basic" layout="vertical" onFinish={onFinish} autoComplete="off">
          <Form.Item name="userName">
            <Input
              size="large"
              placeholder={t("tai_khoan")}
              prefix={<UserOutlined style={{ color: "#279BEF" }} />}
              ref={inputRef}
            />
          </Form.Item>
          <Form.Item name="password">
            <Input.Password size="large" placeholder={t("mat_khau")} prefix={<LockOutlined style={{ color: "#279BEF" }} />} />
          </Form.Item>
          <Button htmlType="submit" type="primary" size="large" className="full" disabled={loading || disableSubmit}>
            {t("dang_nhap")}
          </Button>
          <ButtonSSO htmlType="button" type="primary" size="large" className="full" onClick={onSubmitSSO}>
            {t("dang_nhap_SSO")}
          </ButtonSSO>
        </FormComponent>
      </Spin>
    </Container>
  );
}

const Container = styled.div`
  color: #1f1f1f;
  .title {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 32px;
  }
  .footer-form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    .forgot-password {
      color: #ff5247;
      :hover {
        cursor: pointer;
      }
    }
  }
`;

const ButtonSSO = styled(Button)`
  margin-top: 15px;
  background-color: #ff5247 !important;
  border-color: #ff5247 !important;
  :hover {
    background-color: #ff5247;
    border-color: #ff5247;
    opacity: 0.9;
  }
`;
