import { Form } from "antd";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import { useCallback, useEffect, useState } from "react";
import { handleMultiBoLoc } from "./services";
import FormBoLoc from "./form-bo-loc";

export default function ListFilter(props) {
  const { handleSearch, clearFilter, filterConditions, setType } = props;
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [, setLoading] = useState(false);
  const [loaiYeuCau, setLoaiYeuCau] = useState([]);
  const [mucDich, setMucDich] = useState([]);
  const [soPha, setSoPha] = useState([]);
  const [hinhThucLapDat, setHinhThucLapDat] = useState([]);
  const [hinhThucNopTien, setHinhThucNopTien] = useState([]);
  const [donVi, setDonVi] = useState([]);

  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  useEffect(() => {
    handleMultiBoLoc(
      setLoading,
      setLoaiYeuCau,
      setMucDich,
      setSoPha,
      setHinhThucLapDat,
      setHinhThucNopTien,
      setDonVi
    );
  }, []);

  const handleChangeUnit = (value) => {
    donVi.map((item) => {
      if ([value].includes(item.id)) {
        if (
          (form.getFieldValue("loaiHopDong") === undefined ||
            form.getFieldValue("loaiHopDong") === null) &&
          (form.getFieldValue("soPha") === undefined ||
            form.getFieldValue("soPha") === null)
        ) {
          if (item.isTongCongTy)
            form.setFieldsValue({
              loaiHopDong: 0,
              soPha: "1",
            });
          else
            form.setFieldsValue({
              loaiHopDong: null,
              soPha: null,
            });
        }
      }
      return true;
    });
  };

  return (
    <FormBoLoc
      form={form}
      handleSearch={handleSearch}
      user={user}
      donVi={donVi}
      loaiYeuCau={loaiYeuCau}
      soPha={soPha}
      hinhThucLapDat={hinhThucLapDat}
      hinhThucNopTien={hinhThucNopTien}
      filterConditions={filterConditions}
      mucDich={mucDich}
      clear={clear}
      handleChangeUnit={handleChangeUnit}
      setType={setType}
    />
  );
}
