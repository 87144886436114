import { FORMAT_MONTH_ENGLISH, MONTH_STRING } from "@utils/constants";
import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Form, Row, Select } from "antd";
import React, { useCallback, useEffect } from "react";
import moment from "moment";

const Filterscreen1 = (props) => {
  const {
    setFilterConditions,
    clearSearch,
    isStatusRow,
    filterConditions,
    user,
  } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);

  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        ...values,
      }));
    },
    [setFilterConditions]
  );

  const clear = useCallback(() => {
    form.resetFields();
    clearSearch();
  }, [clearSearch, form]);

  const handleChangeUnit = useCallback(() => {
    form.setFieldsValue({
      ToDoiId: undefined,
    });
  }, [form]);

  return (
    <FormComponent
      form={form}
      initialValues={{
        DonViId: user.unitId,
      }}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
    >
      <Row gutter={24}>
        <Col span={24} md={isStatusRow ? 8 : 12} sm={16}>
          <Form.Item name="DonViId" label="Đơn vị">
            <Selection
              url={Endpoint.GET_UNIT_LIST}
              form={form}
              formKey="DonViId"
              setValue={handleChangeUnit}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.DonViId !== currentValues.DonViId
            }
          >
            {({ getFieldValue }) => {
              if (getFieldValue("DonViId")) {
                return (
                  <Form.Item name="ToDoiId" label="Tổ/Đội">
                    <Selection
                      url={`${
                        Endpoint.AUTH_LIST_TO_DOI
                      }?DonViId=${getFieldValue("DonViId")}`}
                      formKey="ToDoiId"
                      form={form}
                      disabled={!getFieldValue("DonViId")}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="ToDoiId" label="Tổ/Đội">
                  <Select disabled />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24} md={isStatusRow ? 5 : 6}>
          <Form.Item
            name="ThangNam"
            label={isStatusRow ? "Tháng" : "Năm"}
            labelAlign="right"
          >
            <DatePickerComponent
              defaultValue={
                filterConditions?.ThangNam
                  ? moment(filterConditions?.ThangNam, FORMAT_MONTH_ENGLISH)
                  : moment().startOf(MONTH_STRING)
              }
              picker="month"
              formKey="ThangNam"
              format={FORMAT_MONTH_ENGLISH}
              form={form}
            />
          </Form.Item>
        </Col>
        {isStatusRow && (
          <Col span={24} md={5}>
            <Form.Item name="Status" label="Trạng thái" labelAlign="right">
              <Select options={isStatusRow} placeholder="Tất cả" />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row justify="end">
        <Button className="mr-24px" onClick={clear}>
          Bỏ lọc
        </Button>
        <Button type="primary" htmlType="submit">
          Tìm kiếm
        </Button>
      </Row>
    </FormComponent>
  );
};

export default Filterscreen1;
