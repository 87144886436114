import { Row, Col, Form, Button, Space, Radio, Select } from "antd";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import DatePickerComponent from "@components/DatePicker";
import moment from "moment";
import {
  FORMAT_DATE,
  FORMAT_ENGLISH,
  FORMAT_MONTH,
  LOAIBAOCAO,
  MONTH_STRING,
} from "@utils/constants";
import { removeAccents } from "@utils/function";

const { Option } = Select;

export default function BaoCaoCapDienHaApLayout(props) {
  const {
    loading,
    form,
    onFinish,
    handleChangeType,
    loaiBaoCao,
    handleChangeValue,
    value,
    donVi,
    changedDonViId,
    handleChangeUnit,
    // date,
    arrLoaiBaoCao,
    loaiBaoCaoExcel,
  } = props;
  return (
    <LoadingComponent loading={loading}>
      <FormComponent
        form={form}
        name="form-bao-cao"
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
        style={{ margin: "0 auto", width: "80%" }}
        initialValues={{
          donViId: changedDonViId.toString(),
          loaiBaoCao: "1",
          loaiBaoCaoExcel: 1,
        }}
      >
        {/* <h3 style={{ color: "#FF0000", fontSize: "16px" }}>
          Chỉ cho phép xuất dữ liệu đã được chốt báo cáo !
        </h3> */}
        <Row gutter={24}>
          <Col span={24} md={10}>
            <Form.Item name="donViId" label="Đơn vị" className="required-field">
              <Select
                onChange={handleChangeUnit}
                showSearch
                filterOption={(input, option) => {
                  if (option && option.children) {
                    return (
                      removeAccents(option.children)
                        .toLowerCase()
                        .indexOf(removeAccents(input).toLowerCase()) >= 0
                    );
                  }
                }}
              >
                {donVi && donVi.length > 0
                  ? donVi.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={value === "4" ? 3 : 4}>
            <Form.Item name="loaiBaoCao" label="Loại báo cáo">
              <Select onChange={handleChangeValue} defaultValue={loaiBaoCao}>
                {LOAIBAOCAO && LOAIBAOCAO.length > 0
                  ? LOAIBAOCAO.filter(
                      (data) => data.value !== 2 && data.value !== 3
                    ).map((item, index) => (
                      <Option key={index} value={item.value.toString()}>
                        {item.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          {value === "1" ? (
            <Col span={24} md={4}>
              <Form.Item name="thangBaoCao" label="Tháng báo cáo">
                <DatePickerComponent
                  defaultValue={moment(moment(), FORMAT_MONTH)}
                  format={FORMAT_ENGLISH}
                  form={form}
                  formKey={"thangBaoCao"}
                  picker="month"
                  notClear={true}
                />
              </Form.Item>
            </Col>
          ) : (
            <>
              <Col span={24} md={5}>
                <Form.Item name="startDateBaoCao" label="Từ ngày">
                  <DatePickerComponent
                    defaultValue={moment(
                      moment().startOf(MONTH_STRING),
                      FORMAT_DATE
                    )}
                    format={FORMAT_ENGLISH}
                    form={form}
                    formKey={"startDateBaoCao"}
                    // picker="date"
                    notClear={true}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={5}>
                <Form.Item name="endDateBaoCao" label="Đến ngày">
                  <DatePickerComponent
                    defaultValue={moment(moment(), FORMAT_DATE)}
                    format={FORMAT_ENGLISH}
                    form={form}
                    formKey={"endDateBaoCao"}
                    // picker="date"
                    notClear={true}
                  />
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={24} md={8}>
            <span>Loại báo cáo</span>
          </Col>
          <Col span={24} md={16}>
            <Form.Item name="loaiBaoCaoExcel">
              <Radio.Group
                onChange={handleChangeType}
                defaultValue={loaiBaoCaoExcel}
              >
                <Space direction="vertical">
                  {arrLoaiBaoCao.map((item, index) => (
                    <Radio value={item.value} key={index}>
                      {item.name}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button type="primary" htmlType="submit" form="form-bao-cao">
              Xuất excel
            </Button>
          </Col>
        </Row>
      </FormComponent>
    </LoadingComponent>
  );
}
