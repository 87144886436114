import { Button } from "antd";
import styled from "styled-components";

export const ButtonXacNhan = styled(Button)`
  width: 260px;
  height: 52px;
  font-size: 16px;
  background: #279bef;
  color: #fff;
`;
