import { BASE_API_URL_CRM } from "@utils/constants";

export const API_KINH_DOANH = {
  CRMKD_DANH_SACH_YEU_CAU_DANG_THUC_HIEN: `${BASE_API_URL_CRM}/yeucau/yeu-cau-phong-kinh-doanh`,
  CRMKD_XAC_NHAN: `${BASE_API_URL_CRM}/phongkinhdoanh`,
  CRMKD_DUYET_YEU_CAU_DOI: `${BASE_API_URL_CRM}/yeucau/kinh-doanh-duyet-yeu-cau-doi`,
  CRMKD_DUYET_YEU_CAU_PKD: `${BASE_API_URL_CRM}/yeucau/duyet-nhan-vien-kinh-doanh`,
  CRM_TRUONG_PKD_XAC_NHAN: `${BASE_API_URL_CRM}/xacnhan/truong-phong`,
  CRM_TRUONG_PKD_KY_SO: `${BASE_API_URL_CRM}/kyso/truong-phong-kinh-doanh`,
  CRM_TRUONG_PKD_TRA_LAI: `${BASE_API_URL_CRM}/tralai/truong-phong-kinh-doanh`,
  CRM_TRUONG_PKD_KY_HSM: `${BASE_API_URL_CRM}/kyhsm/truong-phong-kinh-doanh`,
};
