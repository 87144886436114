import { APIAPGIA } from "../../api-url/ap-gia";
export const APGIA_Endpoint = {
    // áp giá new version
    AG_COMMON_NHOM_NN: APIAPGIA.AG_COMMON_NHOM_NN,
    AG_LIST_NV_PHAN_CONG: APIAPGIA.AG_LIST_NV_PHAN_CONG,
    AG_CHI_TIET_YC: APIAPGIA.AG_CHI_TIET_YC,
    AG_CHI_TIET_BK: APIAPGIA.AG_CHI_TIET_BK,

    AG_LIST_PHAN_CONG: APIAPGIA.AG_LIST_PHAN_CONG,
    AG_PHAN_CONG: APIAPGIA.AG_PHAN_CONG,
    AG_HUY_PHAN_CONG: APIAPGIA.AG_HUY_PHAN_CONG,
    AG_BP_LIST_YC: APIAPGIA.AG_BP_LIST_YC,
    AG_BP_XAC_NHAN_YC: APIAPGIA.AG_BP_XAC_NHAN_YC,
    AG_BP_TRA_LAI_YC: APIAPGIA.AG_BP_TRA_LAI_YC,
    AG_BP_KY_NHAY: APIAPGIA.AG_BP_KY_NHAY,
    AG_BP_LIST_KTGS: APIAPGIA.AG_BP_LIST_KTGS,
    AG_BP_CHUYEN_KTGS: APIAPGIA.AG_BP_CHUYEN_KTGS,

    AG_LIST_YEU_CAU_DA_PC: APIAPGIA.AG_LIST_YEU_CAU_DA_PC,
    AG_LIST_YEU_CAU_DANG_THUC_HIEN: APIAPGIA.AG_LIST_YEU_CAU_DANG_THUC_HIEN,
    AG_LIST_YEU_CAU_MTB_DA_GUI_DL: APIAPGIA.AG_LIST_YEU_CAU_MTB_DA_GUI_DL,
    AG_LIST_YEU_CAU_KH_CHUA_KY: APIAPGIA.AG_LIST_YEU_CAU_KH_CHUA_KY,
    AG_NV_LIST_YEU: APIAPGIA.AG_NV_LIST_YEU,

    AG_TAO_BANG_KE: APIAPGIA.AG_TAO_BANG_KE,
    AG_LAM_MOI: APIAPGIA.AG_LAM_MOI,
    AG_NV_XAC_NHAN: APIAPGIA.AG_NV_XAC_NHAN,
    AG_NV_KY_BK: APIAPGIA.AG_NV_KY_BK,
    AG_NV_KIEM_TRA_LAI: APIAPGIA.AG_NV_KIEM_TRA_LAI,
    AG_BP_XAC_NHAN_BK:  APIAPGIA.AG_BP_XAC_NHAN_BK,
    AG_BP_KY_BK: APIAPGIA.AG_BP_KY_BK,
    AG_BP_TRA_LAI_BK: APIAPGIA.AG_BP_TRA_LAI_BK,
    AG_BP_TRA_LAI_BK_YC: APIAPGIA.AG_BP_TRA_LAI_BK_YC,
    AG_BP_HUY_YC: APIAPGIA.AG_BP_HUY_YC,
    AG_BP_TRA_YC: APIAPGIA.AG_BP_TRA_YC,

    AG_NV_LIST_XAC_NHAN_BK: APIAPGIA.AG_NV_LIST_XAC_NHAN_BK,
    AG_NV_XAC_NHAN_BK: APIAPGIA.AG_NV_XAC_NHAN_BK,
    AG_NV_HUY_BK: APIAPGIA.AG_NV_HUY_BK,
    AG_BP_LIST_BK: APIAPGIA.AG_BP_LIST_BK,

    AG_LIST_HS_CMIS: APIAPGIA.AG_LIST_HS_CMIS,
    AG_DONG_BO_VE_WEB: APIAPGIA.AG_DONG_BO_VE_WEB,
    AG_DONG_BO_DM_VE_CMIS: APIAPGIA.AG_DONG_BO_DM_VE_CMIS,
    AG_DONG_BO_AG_VE_CMIS: APIAPGIA.AG_DONG_BO_AG_VE_CMIS,
    AG_IMPORT_PC: APIAPGIA.AG_IMPORT_PC,
    AG_XUAT_EXCEL_DONG_BO: APIAPGIA.AG_XUAT_EXCEL_DONG_BO,

    AG_LANH_DAO_LIS_YC: APIAPGIA.AG_LANH_DAO_LIS_YC,
    AG_LANH_DAO_XAC_NHAN_YC:APIAPGIA.AG_LANH_DAO_XAC_NHAN_YC,
    AG_LD_TRA_LAI_YC:APIAPGIA.AG_LD_TRA_LAI_YC,
    AG_LD_KY_SO: APIAPGIA.AG_LD_KY_SO,

    AG_PKD_LIS_YC: APIAPGIA.AG_PKD_LIS_YC,
    AG_PKD_XAC_NHAN_YC: APIAPGIA.AG_PKD_XAC_NHAN_YC,
    AG_PKD_TRA_LAI_YC: APIAPGIA.AG_PKD_TRA_LAI_YC,
    AG_PKD_KY_HSM: APIAPGIA.AG_PKD_KY_HSM,
    AG_PKD_LIS_YC_DB: APIAPGIA.AG_PKD_LIS_YC_DB,
    AG_PKD_TRA_LAI_YC_DB: APIAPGIA.AG_PKD_TRA_LAI_YC_DB,
    AG_PKD_HUY_YC: APIAPGIA.AG_PKD_HUY_YC,
    AG_PKD_PHAN_CONG: APIAPGIA.AG_PKD_PHAN_CONG,
    AG_PKD_HUY_PHAN_CONG: APIAPGIA.AG_PKD_HUY_PHAN_CONG,
    AG_PKD_LIST_YC_PHAN_CONG: APIAPGIA.AG_PKD_LIST_YC_PHAN_CONG,

    AG_TIM_KIEM_YC: APIAPGIA.AG_TIM_KIEM_YC,
    AG_TIM_KIEM_BK: APIAPGIA.AG_TIM_KIEM_BK,
    AG_TIM_KIEM_YC_EXCEL: APIAPGIA.AG_TIM_KIEM_YC_EXCEL,

    AG_KY_NHAY_BIEN_BAN: APIAPGIA.AG_KY_NHAY_BIEN_BAN,

    AG_KY_NHAY_LIST: APIAPGIA.AG_KY_NHAY_LIST,
    AG_KY_NHAY_DETAIL: APIAPGIA.AG_KY_NHAY_DETAIL,
    AG_KY_NHAY_DELETE: APIAPGIA.AG_KY_NHAY_DELETE,
    AG_KY_NHAY_USER: APIAPGIA.AG_KY_NHAY_USER,
    AG_KY_NHAY_CREATE: APIAPGIA.AG_KY_NHAY_CREATE,
    AG_KY_NHAY_UPDATE: APIAPGIA.AG_KY_NHAY_UPDATE,

    AG_DONG_BO_ANH_HT: APIAPGIA.AG_DONG_BO_ANH_HT,

    AG_UPLOAD_BB_GIAY: APIAPGIA.AG_UPLOAD_BB_GIAY,
};
