import { notification } from "antd";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { inExcel, inPDF } from "../common-bao-cao/service";

// PDF
export const handleViewPDF = (
  validate,
  checkTableAll,
  setFilterConditions,
  filterConditions,
  form,
  luyKe,
  luyKeQuy,
  luyKeNam,
  selectedRowKeys,
  checkAllDonVi,
  setLoading,
  typeInPDF
) => {
  const selectedAllTram =
    form.getFieldValue("tramIds") === undefined
      ? false
      : form.getFieldValue("tramIds")?.includes("All");

  if (validate === true) {
    if (checkTableAll === true) {
      setFilterConditions({
        ...filterConditions,
        thang: Number(
          `${moment(form.getFieldValue("reportDate")).format("MM")}`
        ),
        nam: Number(
          `${moment(form.getFieldValue("reportDate")).format("YYYY")}`
        ),
        // tramIds: form.getFieldValue("tramIds"),
        // ...(!selectedAllTram && { tramIds: form.getFieldValue("tramIds") }),
        isLuyke: luyKe ? true : false,
        isLuyKequy: luyKeQuy ? true : false,
        donViIds:
           ( (selectedRowKeys.includes("eba0a775-b9cc-43f8-9e8b-8a0b5c6fc48f") ||
        selectedRowKeys.includes("f6c97ebe-2144-43a8-9f42-cd1e051e7f46")))
            ? checkAllDonVi
            : selectedRowKeys,
      });
      const payload = {
        ...filterConditions,
        thang: Number(
          `${moment(form.getFieldValue("reportDate")).format("MM")}`
        ),
        nam: Number(
          `${moment(form.getFieldValue("reportDate")).format("YYYY")}`
        ),
        // tramIds: form.getFieldValue("tramIds"),
        ...(!selectedAllTram && { tramIds: form.getFieldValue("tramIds") }),
        ...(luyKeNam && { isLuyKeNam: luyKeNam ? true : false }),

        donViIds:
           ( (selectedRowKeys.includes("eba0a775-b9cc-43f8-9e8b-8a0b5c6fc48f") ||
        selectedRowKeys.includes("f6c97ebe-2144-43a8-9f42-cd1e051e7f46")))
            ? checkAllDonVi
            : selectedRowKeys,
        isLuyke: luyKe ? true : false,
        isLuyKequy: luyKeQuy ? true : false,
      };

      inPDF(
        setLoading,
        (value) => {
          if (value !== "error") {
            window.open(value);
          }
        },
        typeInPDF,
        payload
      );
    } else if (checkTableAll === undefined && selectedRowKeys.length > 0) {
      setFilterConditions({
        ...filterConditions,
        thang: Number(
          `${moment(form.getFieldValue("reportDate")).format("MM")}`
        ),
        nam: Number(
          `${moment(form.getFieldValue("reportDate")).format("YYYY")}`
        ),
        // tramIds: form.getFieldValue("tramIds"),
        // ...(!selectedAllTram && { tramIds: form.getFieldValue("tramIds") }),

        isLuyke: luyKe ? true : false,
        isLuyKequy: luyKeQuy ? true : false,
        donViIds:
           ( (selectedRowKeys.includes("eba0a775-b9cc-43f8-9e8b-8a0b5c6fc48f") ||
        selectedRowKeys.includes("f6c97ebe-2144-43a8-9f42-cd1e051e7f46")))
            ? checkAllDonVi
            : selectedRowKeys,
      });
      const payload = {
        ...filterConditions,
        thang: Number(
          `${moment(form.getFieldValue("reportDate")).format("MM")}`
        ),
        nam: Number(
          `${moment(form.getFieldValue("reportDate")).format("YYYY")}`
        ),
        // tramIds: form.getFieldValue("tramIds"),
        ...(!selectedAllTram && { tramIds: form.getFieldValue("tramIds") }),
        ...(luyKeNam && { isLuyKeNam: luyKeNam ? true : false }),

        donViIds:
           ( (selectedRowKeys.includes("eba0a775-b9cc-43f8-9e8b-8a0b5c6fc48f") ||
        selectedRowKeys.includes("f6c97ebe-2144-43a8-9f42-cd1e051e7f46")))
            ? checkAllDonVi
            : selectedRowKeys,
        isLuyke: luyKe ? true : false,
        isLuyKequy: luyKeQuy ? true : false,
      };
      inPDF(
        setLoading,
        (value) => {
          if (value !== "error") {
            window.open(value);
          }
        },
        typeInPDF,
        payload
      );
    } else {
      notification.error(
        {
          message: "Thông báo!",
          description: "Hãy chọn đơn vị.",
        },
        500
      );
    }
  } else {
    setFilterConditions({
      ...filterConditions,
      thang: Number(`${moment(form.getFieldValue("reportDate")).format("MM")}`),
      nam: Number(`${moment(form.getFieldValue("reportDate")).format("YYYY")}`),
      // tramIds: form.getFieldValue("tramIds"),
      // ...(!selectedAllTram && { tramIds: form.getFieldValue("tramIds") }),

      isLuyke: luyKe ? true : false,
      isLuyKequy: luyKeQuy ? true : false,
      donViIds:
         (selectedRowKeys.includes("eba0a775-b9cc-43f8-9e8b-8a0b5c6fc48f") ||
        selectedRowKeys.includes("f6c97ebe-2144-43a8-9f42-cd1e051e7f46"))
          ? checkAllDonVi
          : selectedRowKeys,
    });
    const payload = {
      ...filterConditions,
      thang: Number(`${moment(form.getFieldValue("reportDate")).format("MM")}`),
      nam: Number(`${moment(form.getFieldValue("reportDate")).format("YYYY")}`),
      donViIds:
         (selectedRowKeys.includes("eba0a775-b9cc-43f8-9e8b-8a0b5c6fc48f") ||
        selectedRowKeys.includes("f6c97ebe-2144-43a8-9f42-cd1e051e7f46"))
          ? checkAllDonVi
          : selectedRowKeys,
      // tramIds: form.getFieldValue("tramIds"),
      ...(!selectedAllTram && { tramIds: form.getFieldValue("tramIds") }),
      ...(luyKeNam && { isLuyKeNam: luyKeNam ? true : false }),

      isLuyke: luyKe ? true : false,
      isLuyKequy: luyKeQuy ? true : false,
    };
    inPDF(
      setLoading,
      (value) => {
        if (value !== "error") {
          window.open(value);
        }
      },
      typeInPDF,
      payload
    );
  }
};

// Excel
export const handleExportExcel = (
  validate,
  checkTableAll,
  setFilterConditions,
  filterConditions,
  form,
  luyKe,
  luyKeQuy,
  luyKeNam,
  selectedRowKeys,
  checkAllDonVi,
  setLoading,
  typeInPDF
) => {
  const selectedAllTram =
    form.getFieldValue("tramIds") === undefined
      ? false
      : form.getFieldValue("tramIds")?.includes("All");
  if (validate === true) {
    if (checkTableAll === true) {
      setFilterConditions({
        ...filterConditions,
        thang: Number(
          `${moment(form.getFieldValue("reportDate")).format("MM")}`
        ),
        nam: Number(
          `${moment(form.getFieldValue("reportDate")).format("YYYY")}`
        ),
        // tramIds: form.getFieldValue("tramIds"),
        // ...(!selectedAllTram && { tramIds: form.getFieldValue("tramIds") }),

        isLuyke: luyKe ? true : false,
        isLuyKequy: luyKeQuy ? true : false,
        donViIds:
            (selectedRowKeys.includes("eba0a775-b9cc-43f8-9e8b-8a0b5c6fc48f") ||
        selectedRowKeys.includes("f6c97ebe-2144-43a8-9f42-cd1e051e7f46"))
            ? checkAllDonVi
            : selectedRowKeys,
      });
      const payload = {
        ...filterConditions,
        thang: Number(
          `${moment(form.getFieldValue("reportDate")).format("MM")}`
        ),
        nam: Number(
          `${moment(form.getFieldValue("reportDate")).format("YYYY")}`
        ),
        // tramIds: form.getFieldValue("tramIds"),
        ...(!selectedAllTram && { tramIds: form.getFieldValue("tramIds") }),

        isLuyke: luyKe ? true : false,
        isLuyKequy: luyKeQuy ? true : false,
        ...(luyKeNam && { isLuyKeNam: luyKeNam ? true : false }),
        donViIds:
           (selectedRowKeys.includes("eba0a775-b9cc-43f8-9e8b-8a0b5c6fc48f") ||
        selectedRowKeys.includes("f6c97ebe-2144-43a8-9f42-cd1e051e7f46"))
            ? checkAllDonVi
            : selectedRowKeys,
      };
      inExcel(setLoading, typeInPDF, payload);
    } else if (checkTableAll === undefined && selectedRowKeys.length > 0) {
      setFilterConditions({
        ...filterConditions,
        thang: Number(
          `${moment(form.getFieldValue("reportDate")).format("MM")}`
        ),
        nam: Number(
          `${moment(form.getFieldValue("reportDate")).format("YYYY")}`
        ),
        // tramIds: form.getFieldValue("tramIds"),
        // ...(!selectedAllTram && { tramIds: form.getFieldValue("tramIds") }),

        isLuyke: luyKe ? true : false,
        isLuyKequy: luyKeQuy ? true : false,
        donViIds:
           (selectedRowKeys.includes("eba0a775-b9cc-43f8-9e8b-8a0b5c6fc48f") ||
        selectedRowKeys.includes("f6c97ebe-2144-43a8-9f42-cd1e051e7f46"))
            ? checkAllDonVi
            : selectedRowKeys,
      });
      const payload = {
        ...filterConditions,
        thang: Number(
          `${moment(form.getFieldValue("reportDate")).format("MM")}`
        ),
        nam: Number(
          `${moment(form.getFieldValue("reportDate")).format("YYYY")}`
        ),
        // tramIds: form.getFieldValue("tramIds"),
        ...(!selectedAllTram && { tramIds: form.getFieldValue("tramIds") }),

        isLuyke: luyKe ? true : false,
        isLuyKequy: luyKeQuy ? true : false,
        ...(luyKeNam && { isLuyKeNam: luyKeNam ? true : false }),

        donViIds:
            (selectedRowKeys.includes("eba0a775-b9cc-43f8-9e8b-8a0b5c6fc48f") ||
        selectedRowKeys.includes("f6c97ebe-2144-43a8-9f42-cd1e051e7f46"))
            ? checkAllDonVi
            : selectedRowKeys,
      };
      inExcel(setLoading, typeInPDF, payload);
    } else {
      notification.error(
        {
          message: "Thông báo!",
          description: "Hãy chọn đơn vị.",
        },
        500
      );
    }
  } else {
    setFilterConditions({
      ...filterConditions,
      thang: Number(`${moment(form.getFieldValue("reportDate")).format("MM")}`),
      nam: Number(`${moment(form.getFieldValue("reportDate")).format("YYYY")}`),
      // tramIds: form.getFieldValue("tramIds"),
      // ...(!selectedAllTram && { tramIds: form.getFieldValue("tramIds") }),

      isLuyke: luyKe ? true : false,
      isLuyKequy: luyKeQuy ? true : false,
      donViIds:
         (selectedRowKeys.includes("eba0a775-b9cc-43f8-9e8b-8a0b5c6fc48f") ||
        selectedRowKeys.includes("f6c97ebe-2144-43a8-9f42-cd1e051e7f46"))
          ? checkAllDonVi
          : selectedRowKeys,
    });
    const payload = {
      ...filterConditions,
      thang: Number(`${moment(form.getFieldValue("reportDate")).format("MM")}`),
      nam: Number(`${moment(form.getFieldValue("reportDate")).format("YYYY")}`),
      // tramIds: form.getFieldValue("tramIds"),
      ...(!selectedAllTram && { tramIds: form.getFieldValue("tramIds") }),

      isLuyke: luyKe ? true : false,
      isLuyKequy: luyKeQuy ? true : false,
      ...(luyKeNam && { isLuyKeNam: luyKeNam ? true : false }),

      donViIds:
        ( (selectedRowKeys.includes("eba0a775-b9cc-43f8-9e8b-8a0b5c6fc48f") ||
        selectedRowKeys.includes("f6c97ebe-2144-43a8-9f42-cd1e051e7f46")))
          ? checkAllDonVi
          : selectedRowKeys,
    };

    inExcel(setLoading, typeInPDF, payload);
  }
};

const SELECT_ALL_OPTION = {
  label: "Tất cả",
  value: "_SELECT_ALL_OPTION",
};

// SElect Tram
export function useSelectAllOption(options) {
  const optionsWithAllOption = useMemo(
    () => [SELECT_ALL_OPTION, ...options],
    [options]
  );
  const getValueFromEvent = useCallback(
    (value, selections) => {
      if (!selections?.length) {
        return value;
      }
      if (!selections?.some((s) => s.value === SELECT_ALL_OPTION.value)) {
        return value;
      }
      const labelInValue = typeof value[0]?.label === "string";
      // if "Select All" option selected, set value to all options
      // also keep labelInValue in consideration
      return labelInValue ? options : options.map((o) => o.value);
    },
    [options]
  );

  return [getValueFromEvent, optionsWithAllOption];
}
