import { TYPE_PHIEU_YEUCAU } from "@utils/constants";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { handleKySo } from "../ky-so-hsm/services";
import ModalConfirm from "./modal";
import { handleXacNhanKyDuyet } from "./services";

export default function Index(props) {
  const {
    title,
    visible,
    closeModal,
    payload,
    url,
    setFetchList,
    content,
    dataSource,
    type,
    setSelectedRowKeys,
    isKySo,
    typeClick,
  } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onSuccess = () => {
    if (typeClick === "ky-so") {
      const params = payload;
      handleKySo(
        setLoading,
        url,
        params,
        setFetchList,
        dispatch,
        closeModal,
        setSelectedRowKeys
      );
    } else
      handleXacNhanKyDuyet(
        url,
        payload,
        setLoading,
        setFetchList,
        closeModal,
        setSelectedRowKeys,
        isKySo
      );
  };

  const defaultColumns =
    type === TYPE_PHIEU_YEUCAU.YEUCAU
      ? [
          {
            title: "STT",
            dataIndex: "stt",
            key: "stt",
            render: (text, record, index) => (
              <div className="table-text-right">{index + 1}</div>
            ),
            width: "10%",
          },
          {
            title: "Mã điểm đo",
            dataIndex: "maDiemDo",
            key: "maDiemDo",
            render: (text) => <div className="table-text-left">{text}</div>,
            width: "35%",
          },
          {
            title: "Tên Khách hàng - Số điện thoại",
            dataIndex: "tenKHVaSDT",
            key: "tenKHVaSDT",
            render: (text) => <div className="table-text-left">{text}</div>,
            width: "55%",
          },
        ]
      : [
          {
            title: "STT",
            dataIndex: "stt",
            key: "stt",
            render: (text, record, index) => (
              <div className="table-text-right">{index + 1}</div>
            ),
            width: "10%",
          },
          {
            title: "Mã phiếu",
            dataIndex: "maPhieu",
            key: "maPhieu",
            render: (text) => <div className="table-text-left">{text}</div>,
            width: "30%",
          },
          {
            title: "Loại phiếu",
            dataIndex: "loaiPhieu",
            key: "loaiPhieu",
            render: (text) => <div className="table-text-left">{text}</div>,
            width: "25%",
          },
          {
            title: "Người tạo",
            dataIndex: "nguoiTao",
            key: "nguoiTao",
            render: (text) => <div className="table-text-left">{text}</div>,
            width: "35%",
          },
        ];
  return (
    <ModalConfirm
      title={title}
      visible={visible}
      closeModal={closeModal}
      loading={loading}
      onSuccess={onSuccess}
      content={content}
      columns={defaultColumns}
      dataSource={dataSource}
    />
  );
}
