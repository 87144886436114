import { alertMessage422 } from "@modules/bao-cao-kinh-doanh/service";
import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";

export const optionBc = [
  {
    value: Endpoint.BCKD_TH_CAC_CHI_TIEU_KD,
    label: "1. Tổng hợp các chỉ tiêu kinh doanh",
  },
  {
    value: Endpoint.BCKD_TH_CAC_CHI_TIEU_CHAT_LUONG_DV_KH,
    label: "2. Tổng hợp các chỉ tiêu chất lượng dịch vụ khách hàng",
  },
  {
    value: Endpoint.BCKD_TONG_HOP_DIEN_NANG_GIAO_NHAN_DAU_NGUON,
    label: "3. Tổng hợp điện năng giao nhận đầu nguồn các đơn vị",
  },
  {
    value: Endpoint.BCKD_TH_BAN_DIEN_CUA_CAC_DON_VI,
    label: "4. Tổng hợp bán điện của các đơn vị",
  },
  {
    value: Endpoint.BCKD_TI_LE_DIEN_NANG_TRUYEN_TAI,
    label: "5. Tỷ lệ điện năng truyền tải và phân phối các đơn vị",
  },
  {
    value: Endpoint.BCKD_SO_CONG_TO_CAC_DON_VI,
    label: "6. Tổng hợp số công tơ của các đơn vị",
  },
  {
    value: Endpoint.BCKD_SO_HOP_DONG_MUA_BAN_DIEN,
    label: "7. Tổng hợp số hợp đồng mua bán điện các đơn vị",
  },
  {
    value: Endpoint.BCKD_BAN_DIEN_THEO_PHU_TAI,
    label: "8. Chi tiết bán điện theo thành phần phụ tải",
  },
  {
    value: Endpoint.BCKD_BAN_DIEN_THEO_DOI_TUONG_GIA,
    label: "9. Chi tiết bán điện theo đối tượng giá",
  },
  {
    value: Endpoint.BCKD_SO_DU_VA_TAI_KHOAN_THU,
    label: "10. Chi tiết số thu và số dư các khoản phải thu",
  },
  {
    value: Endpoint.BCKD_SAN_LUONG_TIET_KIEM_DIEN,
    label: "11. Chi tiết sản lượng tiết kiệm điện các đơn vị",
  },
  {
    value: Endpoint.BCKD_BAN_DIEN_THEO_NGANH_NGHE,
    label: "12. Báo cáo chi tiết bán điện theo ngành nghề",
  },
];

export const exportData = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
    })
  );
  authGetData({
    url: `${data.reportType}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.MaDonVi,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.MaDonVi,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
    })
  );
  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
