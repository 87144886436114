import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_MONTH_ENGLISH,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { downloadFile } from "@utils/request";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { Fragment, useCallback, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { selectBaoCaoDoiSoatZalo } from "../services";
// Danh sách khách hàng gửi tin nhắn không thành công tổng hợp
export default function DSKHGuiTinNhanKhongThanhCong(props) {
  const { screen, handleChange } = props;
  const [, setSearchParams] = useSearchParams();
  const { user } = useSelector(authSelector);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [form] = Form.useForm();
  const [filterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
    ...parseParams(location.search),
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const handleSearch = useCallback((values) => {
    const params = { ...values };
    const endpoint =
      Endpoint.DS_KO_TC_TH_EXCEL + "?" + buildQueryString(parseParams(params));
    downloadFile({
      endpoint,
      setLoading,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <FormComponent
            onFinish={handleSearch}
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            initialValues={{
              reportType: screen,
            }}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="reportType"
                  label="Tên báo cáo"
                  labelAlign="left"
                >
                  <Select
                    formKey="reportType"
                    form={form}
                    options={selectBaoCaoDoiSoatZalo}
                    onChange={() =>
                      handleChange(form.getFieldValue("reportType"))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="DonViId" label="Đơn vị" labelAlign="left">
                  <Selection
                    url={Endpoint.GET_UNIT_LIST}
                    formKey="DonViId"
                    form={form}
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
              <Col span={12}>
                <Form.Item
                  name="PhanLoaiKhachHang"
                  label="Phân loại khách hàng"
                  labelAlign="left"
                  labelCol={{ span: 8 }}
                >
                  <Select defaultValue="all" name="PhanLoaiKhachHang">
                    <Option value="all"> Tất cả</Option>
                    <Option value="0">Sinh hoạt</Option>
                    <Option value="1">Ngoài sinh hoạt</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col span={24}>
                <Form.Item name="DoiTuongId" label="Nội dung" labelAlign="left">
                  <Selection
                    url={Endpoint.CSKH_BAO_CAO_EMAIL}
                    formKey="DoiTuongId"
                    form={form}
                    placeholder="Tất cả"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6} md={12}>
                <Form.Item
                  name="ThangNam"
                  label="Tháng năm"
                  labelAlign="left"
                  labelCol={{ span: 8 }}
                >
                  <Row>
                    <Col span={20}>
                      <DatePickerComponent
                        picker="month"
                        formKey="ThangNam"
                        form={form}
                        format="YYYY-MM"
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Row justify="end" gutter={24}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Xuất excel
                </Button>
              </Row>
            </Row>
          </FormComponent>
          <Divider className="section-text-line" orientation="left"></Divider>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  margin-top: 40px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
