import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, getErrorForm, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";

export const selectBaoCaoDoiSoatZalo = [
  {
    label: "B09 - Tổng hợp tình hình nhắn tin thông báo KH hình thức Zalo",
    value: Endpoint.TH_TINH_HINH_NT_TB_KH_ZALO,
    key: 1,
  },
  {
    label:
      "B09 - Tổng hợp tình hình nhắn tin thông báo tiền điện lần 1 KH hình thức Zalo",
    value: Endpoint.TH_TINH_HINH_NT_TB_TIEN_DIEN_L1_ZALO,
    key: 2,
  },
  {
    label:
      "B09 - Chi tiết tình hình nhắn tin thông báo tiền điện lần 1 KH hình thức Zalo",
    key: 3,
    value: Endpoint.CHI_TIET_TH_NT_TB_TIEN_DIEN_L1_ZALO,
  },
  {
    label: "Danh sách khách hàng đăng ký Zalo tổng hợp",
    key: 4,
    value: Endpoint.DS_KH_DANG_KY_ZALO_TONG_HOP,
  },
  {
    label: "Danh sách khách hàng đăng ký Zalo chi tiết",
    key: 5,
    value: Endpoint.DS_KH_DANG_KY_ZALO_CHI_TIET,
  },
  {
    label: "Danh sách khách hàng gửi tin nhắn thành công tổng hợp",
    key: 6,
    value: Endpoint.DANH_SACH_KH_GUI_TN_THANH_CONG_TH,
  },
  {
    label: "Danh sách khách hàng gửi tin nhắn thành công chi tiết",
    value: Endpoint.DANH_SACH_KH_GUI_TN_THANH_CONG_CHI_TIET,
    key: 7,
  },
  {
    label: "Danh sách khách hàng gửi tin nhắn không thành công tổng hợp",
    key: 8,
    value: Endpoint.DANH_SACH_KH_GUI_TN_KO_THANH_CONG_TH,
  },
  {
    label: "Danh sách khách hàng gửi tin nhắn không thành công chi tiết",
    key: 9,
    value: Endpoint.DANH_SACH_KH_GUI_TN_KO_THANH_CONG_CHI_TIET,
  },
  {
    label: "Báo cáo tổng hợp tin nhắn",
    key: 10,
    value: Endpoint.BC_TH_TIN_NHAN,
  },
  {
    label: "Báo cáo tổng hợp tỷ lệ nhắn tin",
    key: 11,
    value: Endpoint.BC_TH_TY_LE_NHAN_TIN,
  },
  {
    label:
      "Báo cáo số lượng khách hàng phát triển mới đã quan tâm. Đăng ký MKH trên trang EVNHANOI",
    key: 12,
    value: Endpoint.BC_SL_KH_PHAT_TRIEN_MOI_EVNHANOI,
  },
  {
    label: "Báo cáo danh sách khách hàng phát triển mới",
    key: 13,
    value: Endpoint.BC_DS_KH_PHAT_TRIEN_MOI,
  },
];

export const exportData = (data, setLoading, setData, form) => {
  const queryString = buildQueryString(
    parseParams({
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      DonViId: data.unitId,
      TuNgay: data.TuNgay,
      DoiTuongId: data.DoiTuongId,
      PhanLoaiKhachHang: data.PhanLoaiKhachHang,
      DenNgay: data.DenNgay,
      TenCuPhap: data.TenCuPhap,
      MaKhachHang: data.MaKhachHang,
    })
  );
  authGetData({
    url: `${data.reportType}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              STT: index + 1,
            };
          })
        );
      } else {
        getErrorForm(res, form);
      }
    },
  });
};

export const handleDownload = (data) => {
  const queryString = buildQueryString(
    parseParams({
      DonViId: data.DonViId,
      MaDonVi: data.MaDonVi,
      PhanLoaiKhachHang: data.PhanLoaiKhachHang,
      DoiTuongId: data.DoiTuongId,
      ThangNam: data.ThangNam,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  console.log("endpont", endpoint);
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading: () => {} });
};
