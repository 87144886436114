import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData } from "@utils/request";

export const getDataBieu3_TongHop = (
  payload,
  setLoading,
  setDataBieu3TH,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.B3_TH_TIN_NHAN_THEO_TUNG_NHA_MANG}?${buildQueryString(
      parseParams(payload)
    )}`,

    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataBieu3TH(
          res.data.map((item, index) => {
            return {
              ...item,
              STT: index + 1,
            };
          })
        );
        setTotalCount(res.data.paging);
      }
    },
  });
};
