
import { Col, Row, Button } from "antd";
import { Container } from "../../css-styled";
import AnhHienTruong from "../../panels/anh-hien-truong";
export default function HinhAnhHienTruong(props) {
  const { dataAnh, dongBoAnhHT, syncAnhHienTruong } = props;

  return (
    // <LoadingComponent loading={false}>
    <Container>
      {dataAnh && dataAnh.length > 0 ? (
        <Row
          gutter={24}
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <Col
            span={24}
          >
            <AnhHienTruong dataAnh={dataAnh} />
          </Col>
        </Row>
      ) : (
        <>
          <Row
            gutter={24}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div style={{ fontSize: 18, textAlign: "center", fontWeight: 600 }}>
              Không có hình ảnh hiện trường!
            </div>
          </Row>
          {syncAnhHienTruong &&
            <Row
              gutter={24}
              style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
            >
              <Button type="primary" onClick={() => dongBoAnhHT()}>
                Đồng bộ ảnh hiện trường
              </Button>
            </Row>}
        </>
      )}
    </Container>
    // </LoadingComponent>
  );
}
