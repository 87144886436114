import { UploadOutlined } from "@ant-design/icons";
import TrashIcon from "@assets/icon/TrashIcon";
import { Button } from "antd";
import { useCallback } from "react";
import { ContainerUpload, UploadFileContainer } from "../css-styled";

export default function Content(props) {
  const { fileEx, loading, removeFile, handleImportFile, uploadFile, messErr } =
    props;

  //render file đã upload
  const renderFile = useCallback(() => {
    if (fileEx && fileEx.name) {
      return (
        <div
          key={fileEx.name}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "12px",
            width: "85%",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%" }}>{fileEx.name}</div>
          <div onClick={() => removeFile()} style={{ cursor: "pointer" }}>
            <TrashIcon />
          </div>
        </div>
      );
    } else return null;
  }, [fileEx, removeFile]);

  return (
    <div>
      <ContainerUpload>
        <div className="choice-file">
          <UploadFileContainer>
            <input type="file" onChange={uploadFile} accept=".xls, .xlsx" />
            <p>
              <span>
                <UploadOutlined /> Chọn tệp
              </span>
              <br />
              <span>Có thể kéo & thả để tải tệp (*.xlsx)</span>
            </p>
          </UploadFileContainer>
        </div>

        <div style={{ width: "15%", textAlign: "right" }}>
          <Button
            type="primary"
            onClick={handleImportFile}
            loading={loading}
            disabled={!fileEx.name}
          >
            Tải lên
          </Button>
        </div>
      </ContainerUpload>
      {renderFile()}
      {messErr !== "" ? (
        <div style={{ marginTop: "12px", color: "red", fontSize: "16px" }}>
          {messErr}
        </div>
      ) : null}
    </div>
  );
}
