import { memo, useRef, useState, useEffect } from "react";
import { Form, Input, Button, Modal, Row, Col, DatePicker, Select } from "antd";
import FormComponent from "@components/Form";
import { useTranslation } from "react-i18next";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import moment from "moment";
import { FORMAT_DATE } from "@utils/constants";
import LoadingComponent from "@components/Loading";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import { handleSubmitForm } from "./services";
const { Option } = Select;

function ImeiModal({ detailImei, visible, closeModal, getListImei, dataUser }) {
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(
    detailImei.nguoiDungId !== undefined ? detailImei.nguoiDungId : null
  );
  const toDay = moment().format("YYYY-MM-DD");
  const [ngay, setNgay] = useState(
    detailImei.ngayCap === null
      ? undefined
      : detailImei.ngayCap
      ? detailImei.ngayCap
      : toDay
  );

  // handle form
  const onFinish = (values) => {
    const data = {
      ...values,
      nguoiDungId: values.nguoiDungId !== undefined ? userId : null,
      ngayCap: ngay,
    };
    //Call services handle submit form
    handleSubmitForm(
      data,
      detailImei,
      setLoading,
      closeModal,
      getListImei,
      form
    );
  };

  const handlChangeNgay = (date) => {
    let convert = "";
    if (date === null) convert = null;
    else convert = moment(date).format("YYYY-MM-DD");
    setNgay(convert);
  };

  //thay đổi người dùng

  const handleChangeUser = (value) => {
    setUserId(value);
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();

    // eslint-disable-next-line
  }, []);

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={detailImei.id ? t("imei.cap_nhat_imei") : t("imei.tao_imei")}
        visible={visible}
        onCancel={closeModal}
        className="modal-tram"
        footer={null}
      >
        <FormComponent
        autoComplete="off"
          form={form}
          name="form-imei"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            ...detailImei,
            ngayCap: ngay === undefined ? undefined : moment(ngay),
          }}
        >
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item>
                <span>{t("uy_quyen.title.ma_don_vi_quan_ly")}</span>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item>
                <span className="font-bold">{user.unitName}</span>
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t("imei.ma_imei")}
                name="maImei"
                className="required-field"
              >
                <Input ref={inputRef} placeholder="Nhập mã imei" />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label={t("imei.loai_may")} name="loaiMay">
                <Input placeholder="Nhập loại máy" />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label={t("imei.ngay_cap")} name="ngayCap">
                <DatePicker
                  locale={locale}
                  format={FORMAT_DATE}
                  onChange={handlChangeNgay}
                  placeholder={FORMAT_DATE}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label={t("imei.ten_nguoi_cap")} name="tenNguoiCap">
                <Input placeholder="Nhập tên người cấp" />
              </Form.Item>
            </Col>
            <Col span={24} md={24}>
              <Form.Item label={t("imei.nguoi_dung")} name="nguoiDungId">
                <Select
                  onChange={handleChangeUser}
                  defaultValue={userId}
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={null}>--Chọn--</Option>
                  {dataUser && dataUser.length > 0
                    ? dataUser.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.nguoiDung}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-imei"
                loading={loading}
              >
                {detailImei.id ? t("button.cap_nhat") : t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(ImeiModal);
