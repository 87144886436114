/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import LoadingComponent from "@components/Loading";
import TaiAnhThanhToan from "@modules/dang-ky-thanh-toan/common/tai-anh";
import { removeAccents } from "@utils/function";
import { Button, Col, DatePicker, Form, Image, Input, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Style } from "../css-styled";
import { getChiTietKhuyenMai, suaThongTinKhuyenMai } from "../services";
function SuaThongTinKhuyenMaiModal({
  closeModal,
  listNganHang,
  setListLoading,
  setData,
  location,
  filterConditions,
  setTotal,
  id,
  total,
  dispatch,
  disable,
}) {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [addData, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataItem, setDataItem] = useState();
  const [fileListEdit, setFileListEdit] = useState([]);
  const [maKhuyenMai, setMaKhuyenMai] = useState();
  const [url, setUrl] = useState();
  const { RangePicker } = DatePicker;
  // create new
  const handleFinish = (values) => {
    const payloadEdit = {
      TenKhuyenMai: values.tenKhuyenMai,
      MaKhuyenMai: maKhuyenMai,
      MaNganHang: values.nganHang,
      ThongTinChiTiet: addData,
      ...(values.hanKhuyenMai && {
        TuNgay: values.hanKhuyenMai[0].format("YYYY-MM-DD"),
      }),
      ...(values.hanKhuyenMai && {
        DenNgay: values.hanKhuyenMai[1].format("YYYY-MM-DD"),
      }),
      ...(fileListEdit.length > 0 && { File: fileListEdit }),
      NoiDung: values.noiDung,
      ...(url &&
        url.length > 0 && {
          Url: url,
        }),
    };
    suaThongTinKhuyenMai(
      payloadEdit,
      setLoading,
      setFileList,
      setListLoading,
      setData,
      location,
      filterConditions,
      setTotal,
      dispatch,
      closeModal,
      id
    );
  };

  console.log("dataItem", dataItem);

  useEffect(() => {
    if (dataItem && dataItem.listImage) {
      dataItem?.listImage.map((item, id) => {
        if (item?.pathImage?.split(",").length > 1) {
          item.pathImage.split(",").map((el, index) => {
            const fileName = `MyFile${index}.png`;
            fetch(el).then(async (response) => {
              const contentType = response.headers.get("content-type");
              const blob = await response.blob();
              const originFileObj = new File([blob], fileName, { contentType });
              setFileList((prev) => [...prev, { originFileObj, thumbUrl: el }]);
            });
          });
        } else {
          const fileName = `MyFile${id}.png`;
          fetch(item).then(async (response) => {
            const contentType = response.headers.get("content-type");
            const blob = await response.blob();
            const originFileObj = new File([blob], fileName, { contentType });
            setFileList((prev) => [
              ...prev,
              { originFileObj, thumbUrl: item.pathImage },
            ]);
          });
        }
      });
    }
  }, [dataItem]);

  useEffect(() => {
    getChiTietKhuyenMai(id, setLoading, setDataItem);
  }, [id]);

  useEffect(() => {
    if (dataItem) {
      form.setFieldsValue({
        ...dataItem,
        nganHang: dataItem.maNganHang,
        ...(dataItem.tuNgay && {
          hanKhuyenMai: [moment(dataItem.tuNgay), moment(dataItem.denNgay)],
        }),
      });
      setMaKhuyenMai(dataItem.maKhuyenMai);
      if (dataItem.thongTinChiTiet) {
        setValue(dataItem.thongTinChiTiet);
      }
    }
  }, [dataItem, form]);

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setValue(data);
  };

  useEffect(() => {
    form.setFieldsValue({
      maKhuyenMai: `KM_TPB_00${total + 2}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("fileList", fileList);

  useEffect(() => {
    const fileListUrl = fileList.map((item) => item.thumbUrl);
    console.log("fileListUrl", fileListUrl);
    if (dataItem) {
      if (dataItem?.listImage[0]?.pathImage?.split(",").length > 1) {
        setUrl(
          [
            ...dataItem.listImage[0].pathImage
              .split(",")
              .filter((item) => fileListUrl.includes(item)),
            dataItem?.listImage[1]?.pathImage,
          ].filter((item) => item !== undefined)
        );
      } else {
        setUrl(
          dataItem.listImage
            .filter((item) => fileListUrl.includes(item.pathImage))
            .map((item) => item.pathImage)
        );
      }
    }
  }, [dataItem, fileList]);

  console.log("url", url);
  useEffect(() => {
    setFileListEdit((prev) =>
      prev.filter((item) =>
        fileList.map((item) => item.originFileObj).includes(item)
      )
    );
  }, [fileList]);

  useEffect(() => {
    if (!url) {
      setFileList([]);
    }
  }, [url]);

  return (
    <LoadingComponent loading={loading}>
      <Style>
        <div className="them-moi-thong-tin">
          <Form
            form={form}
            onFinish={handleFinish}
            layout="vertical"
            // initialValues={{
            //   hanKhuyenMai: [moment(), moment()],
            // }}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="tenKhuyenMai"
                  label="Tên khuyến mãi"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập tên khuyến mãi!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input disabled={disable} />
                </Form.Item>
              </Col>
              {/* <Col span={6}>
                <Form.Item name="maKhuyenMai" label="Mã khuyến mãi" required
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập mã khuyến mãi!",
                      // whitespace: true
                    },
                  ]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col> */}
              <Col span={6}>
                <Form.Item
                  name="nganHang"
                  label="Ngân hàng/Ví điện tử"
                  required
                  className="them-moi-thong-tin-nganHang"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng chọn ngân hàng!",
                    },
                  ]}
                >
                  <Select
                    disabled={disable}
                    allowClear
                    showSearch
                    placeholder="--Chọn ngân hàng--"
                    filterOption={(input, option) => {
                      if (option && option.children) {
                        return (
                          removeAccents(option.children)
                            .toLowerCase()
                            .indexOf(removeAccents(input).toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {listNganHang &&
                      listNganHang.map((item, id) => {
                        return (
                          <Select.Option key={id} value={item.value}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col span={6}>
                <Form.Item name="LoaiCTKM" label="Loại CTKM">
                  <Select mode='tags' maxTagCount='responsive' />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item name="noiDung" label="Mô tả ngắn">
                  <Input.TextArea rows={4} disabled={disable} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={`${disable ? "Danh sách ảnh" : "Chọn ảnh"}`}>
                  {disable ? (
                    url &&
                    url.length > 0 &&
                    url.map((item, id) => {
                      return <Image src={item} key={id} />;
                    })
                  ) : (
                    <TaiAnhThanhToan
                      setFileList={setFileList}
                      fileList={fileList}
                      setFileListEdit={setFileListEdit}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="thongTinChiTiet" label="Nội dung khuyến mãi">
                  <CKEditor
                    editor={ClassicEditor}
                    data={addData}
                    onChange={handleChange}
                    disabled={disable}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "|",
                        "insertTable",
                        "tableColumn",
                        "tableRow",
                        "mergeTableCells",
                        "|",
                        "undo",
                        "redo",
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="hanKhuyenMai" label="Hạn khuyến mãi">
                  <RangePicker format={"DD/MM/YYYY"} disabled={disable} />
                </Form.Item>
              </Col>
              <Col span={8} offset={8} style={{ textAlign: "center" }}>
                {disable ? (
                  <Button onClick={() => dispatch(closeModal())}>
                    Đóng lại
                  </Button>
                ) : (
                  <>
                    <Button
                      onClick={() => dispatch(closeModal())}
                      type="danger"
                      style={{ marginRight: "5px" }}
                    >
                      Hủy
                    </Button>

                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginLeft: "5px" }}
                    >
                      Lưu
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </Style>
    </LoadingComponent>
  );
}

export default SuaThongTinKhuyenMaiModal;
