import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { handleDownload, handleOpenPdf, selectBCTT } from "./service";

export default function BaoCaoThuongPham() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);
  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Báo cáo thương phẩm
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 18 }}
              initialValues={{
                reportType: Endpoint.BCTT_TH_CAC_DIEM_DO_KHCD_THEO_PHIEN_GCS,
                unitId: user.user.unitCode,
              }}
              colon={false}
            >
              <Form.Item
                name="reportType"
                label="Báo cáo"
                labelAlign="left"
                required
              >
                <Select
                  formKey="reportType"
                  placeholder="Tất cả"
                  form={form}
                  options={selectBCTT}
                />
              </Form.Item>

              <Form.Item
                name="unitId"
                label="Tên đơn vị"
                labelAlign="left"
                required
              >
                <Selection
                  url={Endpoint.GET_TEAM_LIST_CODE}
                  formKey="unitId"
                  form={form}
                />
              </Form.Item>

              <Form.Item
                name="reportDate"
                label="Tháng / Năm"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="month"
                  formKey="reportDate"
                  form={form}
                />
              </Form.Item>

              <Row>
                <Col span={4}></Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>

                  {/* <Button
                    type="primary"
                    loading={loading}
                    className="ml-20px"
                    onClick={() => {
                      handleOpenPdf(form.getFieldValue(), setLoading);
                    }}
                  >
                    In báo cáo
                  </Button> */}
                </Col>
              </Row>
            </FormComponent>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;

const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
