import { Select, Tooltip } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { ContainerStyled, ScrollTop, SelectContent } from "./css-styled";
import ThongKeTiepNhan from "./thong-ke-tiep-nhan";
import ThongKeTrangThai from "./thong-ke-trang-thai";
import LoadingComponent from "@components/Loading";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import {
  handleListNguonTiepNhan,
  handleListThongKeKQGQn,
  handleListYeuCauChamTD,
  handleListYeuCauTheoTT,
  handleMultiBoLoc,
} from "./services";
import TongHopYCTheoTrangThai from "./tong-hop-yc-cap-dien-theo-trang-thai";
import YeuCauChamTienDo from "./yeu-cau-cham-tien-do";
import { customColumn, removeAccents, scrollToTop } from "@utils/function";
import ThongKeTiepNhanModal from "./modals/thong-ke-tiep-nhan";
import TiepNhanModal from "./modals/thong-ke-trang-thai";
import { UpCircleFilled } from "@ant-design/icons";
import { useWindowSize } from "@hooks/useWindowSize";
const { Option } = Select;

const configPie = {
  appendPadding: 10,
  // data,
  angleField: "y",
  colorField: "name",
  radius: 0.8,
  // color: ["#5B9BD5", "#ED7D31", "#A5A5A5", "#FFD96A", "#74ff6b", "#14e2c7"],
  label: {
    type: "outer",
    labelHeight: 28,
    offset: window.innerWidth <= 1550 ? "17%" : "20%",
    // content: "{name} {percentage}",
    content: ({ percent }) =>
      percent * 100 > 0 && (percent * 100) % 1 === 0
        ? `${(percent * 100).toFixed(0)}%`
        : percent * 100 > 0 && !(percent * 100) % 1 === 0
        ? `${(percent * 100).toFixed(1)}%`
        : `${(percent * 100).toFixed(0)}%`,
    style: {
      fontSize: 14,
      textAlign: "center",
      fontWeight: 600,
    },
  },
  legend: {
    position: "bottom",
    offsetX: -180,
    offsetY: 80,
  },
  interactions: [{ type: "pie-legend-active" }, { type: "element-active" }],
  // interactions: [
  //   // {
  //   //   name: "pie-legend-active",
  //   // },
  //   {
  //     type: "element-active",
  //   },
  // ],
};

const configBar = {
  xField: "yValues",
  yField: "name",
  barWidthRatio: 0.8,
  // seriesField: "type",
  legend: {
    position: "top-left",
  },
  label: {
    style: {
      fill: "#0F0F1A",
      fontSize: 14,
    },
    position: "right",
  },

  xAxis: {
    label: {
      style: {
        fill: "#0F0F1A",
        fontSize: 14,
      },
    },
  },
  yAxis: {
    label: {
      style: {
        fill: "#0F0F1A",
        fontSize: 14,
      },
    },
  },
  tooltip: {
    customContent: (title, items) => {
      return (
        <div className="tooltip-line-bar">
          <ul style={{ paddingLeft: 0 }} className="tooltip-chart-bar">
            {items?.map((item, index) => {
              const { value } = item;
              return (
                <span
                  key={item.name}
                  className="g2-tooltip-list-item"
                  data-index={index}
                  style={{
                    marginBottom: 4,
                    display: "flex",
                    alignItems: "center",
                    fontSize: 14,
                  }}
                >
                  <span
                    style={{
                      display: "inline-flex",
                      flex: 1,
                      justifyContent: "space-between",
                      fontSize: 14,
                    }}
                  >
                    <span
                      style={{
                        marginRight: 16,
                        color: item.color,
                        fontSize: 14,
                      }}
                    >
                      {item.title}:
                    </span>
                    <span
                      className="g2-tooltip-list-item-value"
                      style={{ color: item.color, fontSize: 14 }}
                    >
                      {value}
                    </span>
                  </span>
                </span>
              );
            })}
          </ul>
        </div>
      );
    },
  },
};

export default function Index() {
  const ref = useRef();
  const { user } = useSelector(authSelector);
  const [donVi, setDonVi] = useState([]);
  const [loading, setLoading] = useState(false);
  //phần 1
  const [dataMonth, setDataMonth] = useState([]);
  const [dataLK, setDataLK] = useState([]);
  const [dataBDThang, setDataBDThang] = useState([]);
  const [dataBDLK, setDataBDLK] = useState([]);
  const [titleThangP1, setTitlteThangP1] = useState("");
  const [titleLKP1, setTitlteLKP1] = useState("");

  //phần 2
  const [dataYCThang, setDataYCThang] = useState([]);
  const [dataYCQuy, setDataYCQuy] = useState([]);
  const [dataYCNam, setDataYCNam] = useState([]);
  const [dataTableKQGQ, setDataTableKQGQ] = useState([]);
  const [titleThang, setTitleThang] = useState("");
  const [titleQuy, setTitleQuy] = useState("");
  const [titleNam, setTitleNam] = useState("");

  //phần 3
  const [dataTongHop, setDataTongHop] = useState([]);
  const [titleP3, setTitleP3] = useState("");

  //phần 4
  const [dataChamTienDo, setDataChamTienDo] = useState([[]]);

  const [filterConditions, setFilterConditions] = useState({
    donViId: user.unitId,
  });

  const { width } = useWindowSize();

  //fetch biểu đồ nguồn tiếp nhận
  const getListNguonTiepNhan = useCallback(() => {
    handleListNguonTiepNhan(
      filterConditions.donViId,
      setLoading,
      setDataBDThang,
      setDataBDLK,
      setDataMonth,
      setDataLK,
      setTitlteThangP1,
      setTitlteLKP1
    );
  }, [filterConditions]);

  //fetch thống kê kết quả giải quyết
  const getListThongKeKQGQ = useCallback(() => {
    handleListThongKeKQGQn(
      filterConditions.donViId,
      setLoading,
      setDataYCThang,
      setDataYCQuy,
      setDataYCNam,
      setDataTableKQGQ,
      setTitleThang,
      setTitleQuy,
      setTitleNam
    );
  }, [filterConditions]);

  //fetch yêu cầu theo trạng thái
  const getListYeuCauTheoTT = useCallback(() => {
    handleListYeuCauTheoTT(
      filterConditions.donViId,
      setLoading,
      setDataTongHop,
      setTitleP3
    );
  }, [filterConditions]);

  const getistYeuCauChamTD = useCallback(() => {
    handleListYeuCauChamTD(
      filterConditions.donViId,
      setLoading,
      setDataChamTienDo
    );
  }, [filterConditions]);

  useEffect(() => {
    getListNguonTiepNhan();
    getListThongKeKQGQ();
    getListYeuCauTheoTT();
    getistYeuCauChamTD();
  }, [
    getListNguonTiepNhan,
    getListThongKeKQGQ,
    getListYeuCauTheoTT,
    getistYeuCauChamTD,
  ]);

  const columnsYC = [
    {
      title: "Tên yêu cầu",
      key: "tenYeuCau",
      dataIndex: "tenYeuCau",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "40%",
    },
    {
      title: titleThang,
      key: "thang",
      dataIndex: "thang",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "20%",
    },
    {
      title: titleQuy,
      key: "quy",
      dataIndex: "quy",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "20%",
    },
    {
      title: titleNam,
      key: "nam",
      dataIndex: "nam",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "20%",
    },
  ];

  //convert chart pie
  const configChart = (
    objectConfig,
    data,
    color,
    position,
    offsetX,
    offsetY
  ) => {
    const config = {
      data: data,
      color: color,
      legend: {
        offsetX: offsetX,
        offsetY: offsetY,
        position: position,
        maxItemWidth: 300,
      },
    };
    return { ...objectConfig, ...config };
  };

  // const getMaxValue = (maxLimit) => {
  //   do {
  //     maxLimit = parseInt(maxLimit.toFixed(0)) + 1;
  //   } while (maxLimit.toFixed(0) % 4 !== 0);
  // };

  function getDivision(number, subLength) {
    const intNumber = parseInt(number);
    //get số mũ của 10 ví dụ intNumber.toString().length - subLength = 2 => 10^2
    return Math.pow(10, intNumber.toString().length - subLength);
  }

  function getMaxLineChart(maxValue, percent, interval) {
    const max = maxValue + maxValue * percent;
    const division = getDivision(max, 1);
    // Math.floor => trả về số nguyên lớn nhất nhỏ hơn hoặc bằng một số nhất định. ví dụ 8.8 = 8, -5.05 = -6
    let result = Math.floor(max / division) * division + division;
    let divisionResult = getDivision(max, 2);
    const totalLength = maxValue.toString().length;
    if (totalLength === Number(division.toFixed(0)).toString().length)
      divisionResult = division;

    for (let i = 0; i < 10; i++) {
      if (result % interval === 0) return result;
      else result += divisionResult;
    }
    return result;
  }

  //convert chart bar
  const configChartBar = (objectConfig, data) => {
    const maxValue = Math.max(...data.map((o) => o.yValues));

    const config = {
      data: data,
      xAxis: {
        maxLimit: getMaxLineChart(maxValue, 0.1, 4),
      },
    };
    return { ...objectConfig, ...config };
  };

  //modal tab1
  const [visible, setVisible] = useState(false);
  const [isThang, setIsThang] = useState(false);
  const handleOpenModal = useCallback(
    (isThang) => {
      setVisible(!visible);
      setIsThang(isThang);
    },
    [visible]
  );

  const handleCloseModal = () => {
    setVisible(false);
    setIsThang(false);
  };

  const showModalDataTab1 = useCallback(() => {
    return (
      visible && (
        <ThongKeTiepNhanModal
          user={user}
          data={isThang ? dataMonth : dataLK}
          isThang={isThang}
          visible={visible}
          closeModal={handleCloseModal}
          filterConditions={filterConditions}
        />
      )
    );
  }, [dataLK, dataMonth, filterConditions, isThang, user, visible]);
  //end modal tab1

  //modal tab 2
  const [visibleTab2, setVisibleTab2] = useState(false);

  const handleOpenModalChiTietTab2 = useCallback(() => {
    setVisibleTab2(!visibleTab2);
  }, [visibleTab2]);

  const handleCloseModalChiTietTab2 = () => {
    setVisibleTab2(false);
  };

  const showModalDataTab2 = useCallback(() => {
    return (
      visibleTab2 && (
        <TiepNhanModal
          visible={visibleTab2}
          closeModal={handleCloseModalChiTietTab2}
          columns={customColumn(columnsYC, filterConditions)}
          data={dataTableKQGQ}
          titleThang={titleThang}
          titleQuy={titleQuy}
          titleNam={titleNam}
        />
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTableKQGQ, visibleTab2]);
  //end modal tab 2

  useEffect(() => {
    handleMultiBoLoc(setLoading, setDonVi);
  }, []);

  //select unit
  const renderSelectUnit = (
    <SelectContent>
      <div className="container">
        <label className="label">Chọn đơn vị</label>
        <Select
          className="select-unit"
          defaultValue={user.unitId}
          showSearch
          filterOption={(input, option) => {
            if (option && option.children) {
              return (
                removeAccents(option.children)
                  .toLowerCase()
                  .indexOf(removeAccents(input).toLowerCase()) >= 0
              );
            }
          }}
          onChange={(value) => setFilterConditions({ donViId: value })}
        >
          {donVi && donVi.length > 0
            ? donVi.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.tenDonVi}
                </Option>
              ))
            : null}
        </Select>
      </div>
    </SelectContent>
  );

  return (
    <LoadingComponent loading={loading}>
      <div ref={ref}>
        <ContainerStyled>
          {renderSelectUnit}
          <ThongKeTiepNhan
            configChart={configChart}
            configPie={configPie}
            dataBDThang={dataBDThang}
            dataBDLK={dataBDLK}
            handleOpenModal={handleOpenModal}
            titleLKP1={titleLKP1}
            titleThangP1={titleThangP1}
            width={width}
          />
          <ThongKeTrangThai
            configChart={configChart}
            dataYCThang={dataYCThang}
            dataYCQuy={dataYCQuy}
            dataYCNam={dataYCNam}
            configPie={configPie}
            handleOpenModalChiTietTab2={handleOpenModalChiTietTab2}
            titleThang={titleThang}
            titleNam={titleNam}
            titleQuy={titleQuy}
            width={width}
          />
          <TongHopYCTheoTrangThai
            config={configChartBar(configBar, dataTongHop)}
            titleP3={titleP3}
          />
          <YeuCauChamTienDo data={dataChamTienDo} width={width} />
        </ContainerStyled>
      </div>
      <ScrollTop onClick={() => scrollToTop(ref)}>
        <Tooltip title="Lên đầu trang">
          <UpCircleFilled />
        </Tooltip>
      </ScrollTop>
      {showModalDataTab1()}
      {showModalDataTab2()}
    </LoadingComponent>
  );
}
