import { Button, Col, Form, Image, Row } from "antd";
import ImageMultipleUpload from "@components/ImageMultipleUpload";
import { Container } from "./css-styled";
import LoadingComponent from "@components/Loading";
import { useCallback } from "react";
import imagePDF from "../../../../../../assets/images/image-pdf.png";

export default function PopupTaiAnh(props) {
  const {
    setImageFiles,
    imageFiles,
    loading,
    fileToDataUri,
    isUpload,
    setIsUpload,
    isSubmit,
  } = props;

  //remove image
  const removeImage = useCallback(
    (fileImage) => {
      const newArr = [...imageFiles];
      const filterArr = newArr.filter((file) => file !== fileImage);
      setImageFiles(filterArr);
    },
    [imageFiles, setImageFiles]
  );

  const renderImages = useCallback(() => {
    if (imageFiles.length === 0) return <div className="image-preview"></div>;
    else
      return (
        <div className="image-preview">
          <Row gutter={24}>
            {imageFiles &&
              imageFiles.length &&
              imageFiles.map((file, idx) => {
                return (
                  <Col
                    span={8}
                    md={3}
                    style={{ marginTop: "12px", paddingRight: 0 }}
                    key={idx}
                  >
                    <div className="image-item">
                      <Image
                        src={
                          file.name?.indexOf(".pdf") > -1
                            ? imagePDF
                            : file.base64Data
                        }
                        alt="avatar"
                        height={165}
                        width={"100%"}
                        preview={false}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                        }}
                      >
                        <Button
                          type="danger"
                          size="small"
                          onClick={() => removeImage(file)}
                        >
                          X
                        </Button>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      );
  }, [imageFiles, removeImage]);

  return (
    <LoadingComponent loading={loading}>
      <Container>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="anh">
              <ImageMultipleUpload
                setImageFiles={setImageFiles}
                imageFiles={imageFiles}
                remove={removeImage}
                fileToDataUri={fileToDataUri}
                isUpload={isUpload}
                isSubmit={isSubmit}
                setIsUpload={setIsUpload}
              >
                <div
                  style={{
                    width: "40%",
                    height: 50,
                    background: "#fafafa",
                    border: "1px dashed #d9d9d9",
                    textAlign: "center",
                    lineHeight: "50px",
                  }}
                >
                  Chọn tệp
                </div>
              </ImageMultipleUpload>
            </Form.Item>
          </Col>
          <Col span={24}>{renderImages()}</Col>
          {/* <Col span={6}>
              <AvatarUploadContainerStyled>
                <input
                  className="custom-file-input"
                  type="file"
                  name="myImage"
                  onChange={uploadAvatar}
                  ref={inputRef}
                  accept="image/png, image/jpeg, image/jpg, image/gif, application/pdf"
                />
              </AvatarUploadContainerStyled>
            </Col> */}
        </Row>
      </Container>
      {/* </FormComponent> */}
    </LoadingComponent>
  );
}
