import React, { Fragment } from "react";
import ImageBackgroundEVN from "@assets/images/login-background.png";
import { Col, Row } from "antd";
const Index = () => {
  return (
    <Fragment>
      <Row style={{ paddingTop: "40px" }}>
        <Col xs={{ span: 7, offset: 1 }} lg={{ span: 6, offset: 2 }}>
          <h1 style={{ fontSize: "24px", fontWeight: "600" }}>Giới thiệu</h1>
          <p></p>
        </Col>
        <Col xs={{ span: 7, offset: 1 }} lg={{ span: 6, offset: 2 }}>
          <h1 style={{ fontSize: "24px", fontWeight: "600" }}>
            Mô tả hệ thống
          </h1>
          <p></p>
        </Col>
        <Col xs={{ span: 7, offset: 1 }} lg={{ span: 6, offset: 2 }}>
          <h1 style={{ fontSize: "24px", fontWeight: "600" }}>
            Tài liệu hướng dẫn
          </h1>
          <p>Hướng dẫn sử dụng hệ thống.</p>
          <p>
            <a href="/">Xem</a>
          </p>
        </Col>
      </Row>
      <Row gutter={24} style={{ margin: 0, textAlign: "center" }}>
        <Col span={24}>
          <img src={ImageBackgroundEVN} alt="No Result" />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Index;
