import CheckPermission from "@components/CheckPermission";
import { Button, Tooltip } from "antd";
import { Fragment, useCallback, useState } from "react";
import { handleXacNhanKyDuyet } from "./services";
import XacNhanKyDuyetModal from "./modal";
import { CheckCircleTwoTone } from "@ant-design/icons";

export default function Index(props) {
  const {
    url,
    permissionCode,
    setFetchList,
    payload,
    disabled,
    content,
    title,
    isKySo,
    urlKySo,
    urlGetHash,
    permissionCodeKySo,
    payloadKySo,
    isIcon,
    text,
    setSelectedRowKeys,
  } = props;
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  //ký duyệt dữ liệu
  const handleKyDuyetDuLieu = useCallback(() => {
    handleXacNhanKyDuyet(
      url,
      payload,
      setLoading,
      setFetchList,
      handleCloseModal,
      setSelectedRowKeys
    );
  }, [payload, setFetchList, setSelectedRowKeys, url]);

  const handleOpenModal = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const handleCloseModal = () => {
    setVisible(false);
  };

  const showModalConfirm = useCallback(() => {
    return (
      visible && (
        <XacNhanKyDuyetModal
          content={content}
          title={title}
          visible={visible}
          closeModal={handleCloseModal}
          loading={loading}
          onFinish={handleKyDuyetDuLieu}
          isKySo={isKySo}
          urlKySo={urlKySo}
          urlGetHash={urlGetHash}
          setFetchList={setFetchList}
          permissionCode={permissionCodeKySo}
          payloadKySo={payloadKySo}
        />
      )
    );
  }, [
    content,
    handleKyDuyetDuLieu,
    isKySo,
    loading,
    payloadKySo,
    permissionCodeKySo,
    setFetchList,
    title,
    urlGetHash,
    urlKySo,
    visible,
  ]);

  return (
    <Fragment>
      <CheckPermission permissionCode={permissionCode}>
        {isIcon ? (
          <Tooltip title={text ? text : "Xác nhận"}>
            <div style={{ color: "#279BEF", fontSize: 20, marginLeft: 10 }}>
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: "18px" }}
                onClick={handleOpenModal}
              />
            </div>
          </Tooltip>
        ) : (
          <Button
            type="primary"
            onClick={handleOpenModal}
            className="mr-5px"
            disabled={disabled}
          >
            {text ? text : "Xác nhận"}
          </Button>
        )}
      </CheckPermission>
      {showModalConfirm()}
    </Fragment>
  );
}
