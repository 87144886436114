import TableComponent from "@components/TableComponent";
// import { renderBodyTable, renderHeaderTable } from "@utils/function";
export default function HideShowColumnsTable(props) {
  const {
    disableGetQueryParams,
    dataSource = [],
    columns,
    header,
    totalData,
    rowSelection,
    // onRow,
    loading,
    onChangePagination,
    pagination,
    scrollX,
    rowKey,
    expandable,
    summary,
    genExtra,
  } = props;
  return (
    <TableComponent
      header={header}
      renderExtraAction={() => genExtra()}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      onChangePagination={onChangePagination}
      components={
        {
          // header: {
          //   row: (props) => renderHeaderTable(props, columns),
          // },
          // body:
          //   dataSource.length > 0
          //     ? {
          //         row: (props) => renderBodyTable(props, columns),
          //       }
          //     : null,
        }
      }
      disableGetQueryParams={disableGetQueryParams}
      totalData={totalData}
      rowSelection={rowSelection}
      // onRow={onRow}
      pagination={pagination}
      scrollX={scrollX}
      rowKey={rowKey}
      expandable={expandable}
      summary={summary}
      scrollY={500}
    />
  );
}
