import { buildQueryString, parseParams } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";

export const handleListData = (
  location,
  filterConditions,
  setLoading,
  setDataYCLanhDao,
  setTotalCount,
  setSelectedRowKeys
) => {
  let url = "";
  url = `${Endpoint.AG_LANH_DAO_LIS_YC}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      setSelectedRowKeys([]);
      if (res.data !== null) {
        setDataYCLanhDao(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataYCLanhDao([]);
    },
  });
};

export const submitLDXacNhan = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys,
  handleCloseModalKy
) => {
  authPostData({
    url: Endpoint.AG_LANH_DAO_XAC_NHAN_YC,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys.toString(),
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
        handleCloseModalKy();
      }
    },
  });
};
export const submitLDKySo = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys,
  handleCloseModalKy
) => {
  authPostData({
    url: Endpoint.AG_LD_KY_SO,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
        handleCloseModalKy();
      }
    },
  });
};
export const submitLDTraLai = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys,
  handleCloseModalTraLai,
  lyDo,
) => {
  authPostData({
    url: Endpoint.AG_LD_TRA_LAI_YC,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys.toString(),
      lyDoTraLai: lyDo
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
        handleCloseModalTraLai();
      }
    },
  });
};