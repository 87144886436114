import { Col, Form, Row, Button, Select } from "antd";
import { Endpoint } from "@utils/endpoint";
import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import moment from "moment";
import {
  ENUM_BUTTON_TYPE,
  ENUM_LOAIBAOCAO_VATTU,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import { removeAccents } from "@utils/function";
import { cap_dien } from "@permissions/a_capdien";
import Selection from "@components/Select";
import CheckPermission from "@components/CheckPermission";

const { Option } = Select;

export default function FormBoLoc(props) {
  const {
    form,
    handleSearch,
    loaiYeuCau,
    filterConditions,
    clear,
    soPha,
    user,
    handleChangeUnit,
    donVi,
    disabled,
    setType,
  } = props;
  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      autoComplete="off"
      initialValues={{
        loaiBaoCao: ENUM_LOAIBAOCAO_VATTU.VATTUKH,
        donViId: user.unitId.toString(),
      }}
    >
      <Row gutter={24}>
        <Col span={24} md={8}>
          <Form.Item name="donViId" label="Đơn vị">
            <Select
              onChange={handleChangeUnit}
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {donVi && donVi.length > 0
                ? donVi.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.tenDonVi}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.donViId !== currentValues.donViId) {
                form.setFieldsValue({
                  doiId: undefined,
                });
              }
              return prevValues.donViId !== currentValues.donViId;
            }}
          >
            {({ getFieldValue }) => {
              if (getFieldValue("donViId")) {
                return (
                  <Form.Item name="doiId" label="Đội">
                    <Selection
                      url={`${
                        Endpoint.LIST_DOI_SO_LUONG_VAT_TU
                      }?donViId=${getFieldValue("donViId")}`}
                      formKey="doiId"
                      form={form}
                      defaultValue={null}
                      disabled={disabled}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="doiId" label="Đội">
                  <Selection
                    url={`${Endpoint.LIST_DOI_SO_LUONG_VAT_TU}?donViId=${user.unitId}`}
                    formKey="doiId"
                    form={form}
                    defaultValue={user.isTongCongTy ? null : undefined}
                    disabled={disabled}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item name="loaiYeuCauId" label="Loại tổng hợp">
            <Select placeholder="Tất cả" allowClear={true}>
              {loaiYeuCau && loaiYeuCau.length > 0
                ? loaiYeuCau.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item name="loaiBaoCao" label="Loại báo cáo">
            <Select placeholder="Tất cả" allowClear={false}>
              <Option value={ENUM_LOAIBAOCAO_VATTU.VATTUKH}>
                Vật tư khách hàng
              </Option>
              <Option value={ENUM_LOAIBAOCAO_VATTU.VATTUCTY}>
                Vật tư công ty
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item name="ngayBatDau" label="Từ ngày">
            <DatePickerComponent
              defaultValue={
                filterConditions.ngayBatDau
                  ? moment(filterConditions.ngayBatDau, FORMAT_ENGLISH)
                  : moment().startOf(MONTH_STRING)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"ngayBatDau"}
              notClear={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item name="ngayKetThuc" label="Đến ngày">
            <DatePickerComponent
              defaultValue={
                filterConditions.ngayKetThuc
                  ? moment(filterConditions.ngayKetThuc, FORMAT_ENGLISH)
                  : moment()
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"ngayKetThuc"}
              notClear={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item name="soPha" label="Số pha">
            <Select placeholder="Tất cả" allowClear={true}>
              {soPha && soPha.length > 0
                ? soPha.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col
          span={24}
          md={9}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button className="mr-24px" onClick={clear}>
            Bỏ lọc
          </Button>
          <Button
            type="primary"
            className="mr-24px"
            htmlType="submit"
            form="filter-form"
            onClick={() => setType(ENUM_BUTTON_TYPE.TIM_KIEM)}
          >
            Tìm kiếm
          </Button>
          <CheckPermission permissionCode={cap_dien.a580_xuat}>
            <Button
              type="primary"
              className="mr-24px"
              htmlType="submit"
              form="filter-form"
              onClick={() => setType(ENUM_BUTTON_TYPE.XUAT_EXCEL)}
            >
              Xuất excel
            </Button>
          </CheckPermission>
        </Col>
      </Row>
    </FormComponent>
  );
}
