import { useEffect } from "react";
import { Button, Modal, Row, Col } from "antd";
import FormComponent from "@components/Form";
import CheckPermission from "@components/CheckPermission";
import { ap_gia } from "@permissions/e_apgia";

export default function KyDuyetModal(props) {
  const {
    visible,
    closeModal,
    onFinish,
    form,
    openModalKySo,
    loading,
    dataCauHinh
  } = props;

  useEffect(() => {
    form.resetFields();
  }, [form]);

  return (
    <Modal
      title="Bộ phận áp giá ký duyệt"
      visible={visible}
      onCancel={closeModal}
      className="modal-bpag-ky"
      footer={null}
    >
      {/* <LoadingComponent loading={loading}> */}
        <FormComponent
          form={form}
          name="form-bpag-ky"
          layout="horizontal"
          onFinish={onFinish}
        >
          <Row gutter={24}>
            <Col>
              <div>
                Các yêu cầu áp giá đã chọn sẽ được chuyển tiếp cho Phòng kinh doanh!
              </div>
              <div>
                Bạn chắc chắn muốn thực hiện?
              </div>
            </Col>
          </Row>
          <br />
          <Row gutter={24} justify="center">
            <Button key="back" onClick={closeModal} className="button-closed mr-24px">
              Đóng lại
            </Button>
            {
              dataCauHinh !== 2 &&
              <CheckPermission permissionCode={ap_gia.e22k}>
                <Button
                  type="primary"
                  className="button-primary mr-24px"
                  form="form-bpag-ky"
                  onClick={() => openModalKySo()}
                  loading={loading}
                >
                  Ký HSM
                </Button>
              </CheckPermission>
            }
            <CheckPermission permissionCode={ap_gia.e22x}>
              <Button
                type="primary"
                className="button-primary"
                form="form-bpag-ky"
                onClick={() => onFinish()}
                loading={loading}
              >
                Xác nhận
              </Button>
            </CheckPermission>
          </Row>
        </FormComponent>
      {/* </LoadingComponent> */}
    </Modal>
  );
}
