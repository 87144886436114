import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";
import { METHOD_POST, STATUSCODE_200, ENUM_DS_INBK } from "@utils/constants";
import {
  buildQueryString,
  parseParams,
  alertMessage
} from "@utils/function";

// get list data danh sách đối soát dữ liệu
export const handleListData = (
  t,
  setLoading,
  filterConditions,
  location,
  setDataDoiSoat,
  setTempDataDoiSoat,
  setTotalCount,
  setTempTotalCount,
) => {
  const lc = parseParams(location.search);
  const paramsLocation = {
    ...lc,
    lichGCSs: filterConditions.lichGCSs
      ? filterConditions.lichGCSs.toString()
      : "",
  };
  let url = "";
  url = `${Endpoint.LIST_DS_DOI_SOAT_DL}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? paramsLocation
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDoiSoat(res.data);
        setTempDataDoiSoat(res.data)
        setTotalCount(res.paging?.totalCount);
        setTempTotalCount(res.paging?.totalCount);
      }
      else {
        setDataDoiSoat([])
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

// get list data danh sách đối soát dữ liệu
export const handleListSoGCS = (
  setLoading,
  filterSoGCS,
  setDataSoGCS,
  soGCS,
  setDaDS,
  setDSDat,
  setDSKhongDat,
  setTotal,
  setTempDaDS,
  setTempDSDat,
  setTempDSKhongDat,
  setTempTotal,
  setAllIds,
  setListSelect
) => {
  let url = "";
  url = `${Endpoint.LIST_SO_GCS_DOI_SOAT}?${buildQueryString(filterSoGCS)}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        const listSelect = []
        for (let i = 0; i < res.data?.length; i++) {
          listSelect.push({
            title: `[${res.data[i].maSoGCS}] ${res.data[i].tenSoGCS}`,
            value: res.data[i].id
          })
        }
        setListSelect(listSelect)
        setAllIds(listSelect.map(({ value }) => value));
        setDataSoGCS(res.data)
        if (soGCS?.length > 0) {
          let daDS = 0;
          let dsKhongDat = 0;
          let tongSo = 0;
          for (let i = 0; i < soGCS?.length; i++) {
            for (let j = 0; j < res.data?.length; j++) {
              if (res.data[j].id === soGCS[i]) {
                daDS += res.data[j].daSoatDat;
                dsKhongDat += res.data[j].doiSoatKhongDat;
                tongSo += res.data[j].soLuongChiSo;
              }
            }
          }
          setDaDS(daDS);
          setDSDat(daDS - dsKhongDat);
          setDSKhongDat(dsKhongDat);
          setTotal(tongSo);
          setTempDaDS(daDS);
          setTempDSDat(daDS - dsKhongDat);
          setTempDSKhongDat(dsKhongDat);
          setTempTotal(tongSo)
        }
      } else setDataSoGCS(res.data)
    },
  });
};
// get list data danh sách đối soát dữ liệu
export const handleListSoGCSDT = (
  setLoading,
  filterSoGCS,
  setDataSoGCS,
  soGCS,
  setDaDS,
  setDSDat,
  setDSKhongDat,
  setTotal,
  setTempDaDS,
  setTempDSDat,
  setTempDSKhongDat,
  setTempTotal,
  setAllIds,
  setListSelect
) => {
  let url = "";
  url = `${Endpoint.LIST_SO_GCS_DOI_SOAT_DT}?${buildQueryString(filterSoGCS)}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataSoGCS(res.data)
        const listSelect = []
        for (let i = 0; i < res.data?.length; i++) {
          listSelect.push({
            title: `[${res.data[i].maSoGCS}] ${res.data[i].tenSoGCS}`,
            value: res.data[i].id
          })
        }
        setListSelect(listSelect)
        setAllIds(listSelect.map(({ value }) => value));
        if (soGCS?.length > 0) {
          let daDS = 0;
          let dsKhongDat = 0;
          let tongSo = 0;
          for (let i = 0; i < soGCS?.length; i++) {
            for (let j = 0; j < res.data?.length; j++) {
              if (res.data[j].id === soGCS[i]) {
                daDS += res.data[j].daSoatDat;
                dsKhongDat += res.data[j].doiSoatKhongDat;
                tongSo += res.data[j].soLuongChiSo;
              }
            }
          }
          setDaDS(daDS);
          setDSDat(daDS - dsKhongDat);
          setDSKhongDat(dsKhongDat);
          setTotal(tongSo);
          setTempDaDS(daDS);
          setTempDSDat(daDS - dsKhongDat);
          setTempDSKhongDat(dsKhongDat);
          setTempTotal(tongSo)
        }
      } else setDataSoGCS(res.data)
    },
  });
};

// get list data danh sách đối soát dữ liệu
export const handleListSoGCSDH = (
  setLoading,
  filterSoGCS,
  setDataSoGCS,
  soGCS,
  setDaDS,
  setDSDat,
  setDSKhongDat,
  setTotal,
  setTempDaDS,
  setTempDSDat,
  setTempDSKhongDat,
  setTempTotal,
  setAllIds,
  setListSelect
) => {
  let url = "";
  url = `${Endpoint.LIST_SO_GCS_DOI_SOAT_DH}?${buildQueryString(filterSoGCS)}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataSoGCS(res.data)
        const listSelect = []
        for (let i = 0; i < res.data?.length; i++) {
          listSelect.push({
            title: `[${res.data[i].maSoGCS}] ${res.data[i].tenSoGCS}`,
            value: res.data[i].id
          })
        }
        setListSelect(listSelect)
        setAllIds(listSelect.map(({ value }) => value));
        if (soGCS?.length > 0) {
          let daDS = 0;
          let dsKhongDat = 0;
          let tongSo = 0;
          for (let i = 0; i < soGCS?.length; i++) {
            for (let j = 0; j < res.data?.length; j++) {
              if (res.data[j].id === soGCS[i]) {
                daDS += res.data[j].daSoatDat;
                dsKhongDat += res.data[j].doiSoatKhongDat;
                tongSo += res.data[j].soLuongChiSo;
              }
            }
          }
          setDaDS(daDS);
          setDSDat(daDS - dsKhongDat);
          setDSKhongDat(dsKhongDat);
          setTotal(tongSo);
          setTempDaDS(daDS);
          setTempDSDat(daDS - dsKhongDat);
          setTempDSKhongDat(dsKhongDat);
          setTempTotal(tongSo);
        }
      } else setDataSoGCS(res.data)
    },
  });
};

export const submitXuatGhiLaiMDMS = (
  t,
  setLoading,
  setFetchDataTable,
  listId,
  setFilterConditions,
  getListSoGCS,
  setSoGCS,
  setSelectedValues
) => {
  authPostData({
    url: Endpoint.LAY_CHI_SO_MDMS,
    method: METHOD_POST,
    payload: {
      ids: listId.toString(),
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedValues([]);
        setFilterConditions((oldState) => ({
          ...oldState,
          lichGCSs: undefined
        }));
        getListSoGCS()
        setSoGCS([]);
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};
export const submitXuatGhiLaiMTB = (
  t,
  setLoading,
  setFetchDataTable,
  listId,
  setFilterConditions,
  getListSoGCS,
  setSoGCS,
  setSelectedValues
) => {
  authPostData({
    url: Endpoint.XUAT_GHI_LAI_MTB,
    method: METHOD_POST,
    payload: {
      ids: listId.toString(),
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedValues([]);
        setFilterConditions((oldState) => ({
          ...oldState,
          lichGCSs: undefined
        }));
        getListSoGCS();
        setSoGCS([]);
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const submitHoanThanhDS = (
  t,
  setLoading,
  setFetchDataTable,
  listId,
) => {
  authPostData({
    url: Endpoint.HOAN_THANH_DS,
    method: METHOD_POST,
    payload: {
      ids: listId,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable();
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};
export const submitTuDongDS = (
  t,
  setLoading,
  listId,
  setFetchDataTable,
  // getListSoGCS,
  onSuccessTuDongDs = () => {}
) => {
  authPostData({
    url: Endpoint.TU_DONG_DS,
    method: METHOD_POST,
    payload: {
      ids: listId,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        // getListSoGCS();
        onSuccessTuDongDs()
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const submitChangeCheckbox = (
  t,
  setLoading,
  id,
  trangThai,
  setFetchDataTable,
  getListSoGCS
) => {
  authPostData({
    url: Endpoint.CHECKBOX_DS,
    method: METHOD_POST,
    payload: {
      id: id,
      trangThaiDoiSoat: trangThai
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        getListSoGCS();
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
        setFetchDataTable(true)
      }
    },
  });
};

export const submitInBK = (
  bangKe,
  setLoading,
  listMaSo,
  filterSoGCS
) => {
  authGetData({
    url: `${bangKe === ENUM_DS_INBK.KH_KHONG_CHUP_ANH ? Endpoint.XUAT_PDF_CUSTOMER_NO_CAP :
          (bangKe === ENUM_DS_INBK.KH_KHONG_DUOC_DS ? Endpoint.XUAT_PDF_KHONG_DOI_SOAT :
            (bangKe === ENUM_DS_INBK.KH_DA_DS ? Endpoint.XUAT_PDF_DA_DOI_SOAT :
              (bangKe === ENUM_DS_INBK.KH_DS_DAT ? Endpoint.XUAT_PDF_DOI_SOAT_DAT :
                  Endpoint.XUAT_PDF_DOI_SOAT_KHONG_DAT))
          )}?MaSoGCS=${listMaSo.toString()}&KyGCS=${filterSoGCS.Ky}&ThangNam=${filterSoGCS.Nam}-${filterSoGCS.Thang}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200 && res.data) {
        window.open(res.data, "_blank");
      }
    },
  });
};

export const getListDonViService = (setLoading, setDataDonVi) => {
  authGetData({
    url: `${Endpoint.GET_UNIT_LIST}?pageSize=-1`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDonVi(res.data);
      } else setDataDonVi([]);
    },
  });
};

export const submitChuyenHHU = (
  t,
  setLoading,
  setFetchDataTable,
  listId,
  setFilterConditions,
  getListSoGCS,
  setSoGCS,
  setSelectedValues
) => {
  authPostData({
    url: Endpoint.CHUYEN_HHU,
    method: METHOD_POST,
    payload: {
      ids: listId.toString(),
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedValues([]);
        setFilterConditions((oldState) => ({
          ...oldState,
          lichGCSs: undefined
        }));
        getListSoGCS()
        setSoGCS([]);
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};