import { BASE_API_URL_BAO_CAO } from "@utils/constants";

export const APIBCWebEVNHaNoi = {
  BCWEHN_CT_VE_VIEC_DANH_GIA_MUC_DO_HAI_LONG_KH_EVN_HN: `${BASE_API_URL_BAO_CAO}/bckd/webevn/bcwebevn/ct-ve-viec-danh-gia-muc-do-hai-long-kh-evn-ha-noi`,
  BCWEHN_TH_17_DICH_VU_TIEP_NHAN: `${BASE_API_URL_BAO_CAO}/bckd/webevn/bcwebevn/th-17-dich-vu-dien-tiep-nhan`,
  BCWEHN_TH_TAT_CA_DV_DIEN_TIEP_NHAN: `${BASE_API_URL_BAO_CAO}/bckd/webevn/bcwebevn/th-tat-ca-dich-vu-dien-tiep-nhan`,
  BCWEHN_TH_VE_VIEC_DANH_GIA_MUC_DO_HAI_LONG_KH_EVN_HA_NOI: `${BASE_API_URL_BAO_CAO}/bckd/webevn/bcwebevn/th-ve-viec-danh-gia-muc-do-hai-long-kh-evn-ha-noi`,
  BCWEHN_TK_CT_SLKH_SU_DUNG_APP_EVN_HN: `${BASE_API_URL_BAO_CAO}/bckd/webevn/bcwebevn/tk-chi-tiet-slkh-su-dung-app-evn-hanoi`,
  BCWEHN_TK_NGUOI_DANG_NHAP_ONLINE_HE_THONG: `${BASE_API_URL_BAO_CAO}/bckd/webevn/bcwebevn/tk-nguoi-dang-nhap-online-he-thong`,
  BCWEHN_TKTHSLKH_SU_DUNG_APP_EVN_HN: `${BASE_API_URL_BAO_CAO}/bckd/webevn/bcwebevn/tkthslkh-su-dung-app-evn-ha-noi`,
};
