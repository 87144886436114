import { Fragment, useState } from "react";
import PhieuXuatVTTable from "../../common-ver1/phieu-vat-tu";
import { treo_thao } from "@permissions/b_treothao";
import { useDispatch } from "react-redux";
import { openModal } from "@state/system-config/reducer";
import XemPhieu from "../../common-ver1/xem-phieu";
import { ENUM_PHIEU_VAT_TU, LOAI_YEUCAU } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";

export default function Index() {
  const [fetchList, setFetchList] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [arrSelectedRows, setArrSelectedRows] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [codeStatus, setCodeStatus] = useState(0);
  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setCodeStatus(selectedRows[0]?.maTrangThai);
    setArrSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  //show popup xem phiếu
  const renderPopup = (detail) => {
    return dispatch(
      openModal({
        content: (
          <XemPhieu detail={detail} typeTreoThao={LOAI_YEUCAU.TREOTHAO} />
        ),
        size: "large",
        title: "Xem phiếu",
      })
    );
  };

  //get payload cho xác nhận
  const handleGetPayloadXN = (row) => {
    const payload = {
      ids: row ? [row.id] : selectedRowKeys,
    };

    return payload;
  };
  return (
    <Fragment>
      <PhieuXuatVTTable
        url={Endpoint.LIST_PHIEU_VAT_TU}
        funcId={ENUM_PHIEU_VAT_TU.PVTXACNHANXUATVT}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        rowSelection={rowSelection}
        selectedRowKeys={selectedRowKeys}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        renderPopup={renderPopup}
        permissionCodeXacNhan={treo_thao.b62xn}
        // permissionCodeKySo={treo_thao.b27k}
        // permissionCodeXemPhieu={treo_thao.b27v}
        urlXN={Endpoint.PVT_XAC_NHAN_VAT_TU}
        urlXNNotMany={""}
        contentXN="Xác nhận xuất vật tư, yêu cầu sẽ được chuyển tiếp cho Đội trưởng để phân công treo tháo !"
        titleXN="Phòng vật tư xác nhận"
        // isIcon={false}
        // isKySo={true}
        // urlKySo={Endpoint.XAC_THUC_KY_SO_PHIEUVT}
        // urlKySo={Endpoint.KY_SO_PHIEU_VAT_TU_HSM}
        // urlGetHash={Endpoint.KY_SO_GET_HASH_PHIEUVT}
        // payloadKySo={{
        //   phieuIds: selectedRowKeys.toString(),
        //   maTrangThai: codeStatus,
        // }}
        payloadKySo={{
          phieuIds: selectedRowKeys.toString(),
        }}
        payloadXN={handleGetPayloadXN}
        setSelectedRowKeys={setSelectedRowKeys}
        arrSelectedRows={arrSelectedRows}
      />
    </Fragment>
  );
}
