import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, isFloat, numberWithSpaces, roundingNumberAfterDecimal } from "@utils/function";
import { authGetData, authPostData } from "@utils/request";
import { cloneDeep, isEmpty, isNumber, subtract } from "lodash";
import { fixedNumber, getChiSoCuoi } from "./calculate";
export const getListDiemDo = ({ setDataSource, setLoading, queryString, setDataSourceInitial, setPageInfo = () => {} }) => {
  authGetData({
    url: `${Endpoint.QLCS_GET_DANH_SACH_DIEMDO}?${buildQueryString(queryString)}`,
    setLoading,
    onSuccess: (res) => {
      setPageInfo((pre) => {
        return {
          ...pre,
          totalPages: res.paging?.totalPages,
        };
      });
      const arr = [];
      const arrTable = [];
      if (res.statusCode === STATUSCODE_200 && res.data) {
        const source = res.data;
        for (let index = 0; index < source.length; index++) {
          const element = source[index];
          if (!isEmpty(element.diemDos)) {
            arrTable.push(element);
            const diemDos = element.diemDos;
            for (let idx = 0; idx < diemDos.length; idx++) {
              const elementDiemDo = diemDos[idx];
              const chiSoDau = fixedNumber(elementDiemDo.chiSoDau);
              const chiSoCuoi = getChiSoCuoi({ record: elementDiemDo });
              let sanLuong = fixedNumber(elementDiemDo.sanLuong);
              const sanLuongTrucTiep = fixedNumber(elementDiemDo.sanLuongTrucTiep);
              const heSoNhan = elementDiemDo.heSoNhan;
              const sanLuongThao = fixedNumber(elementDiemDo.sanLuongThao);
              let tongCong = roundingNumberAfterDecimal({ number: elementDiemDo.tongCong });
              if (isNumber(chiSoCuoi)) {
                const roundIndex = fixedNumber(chiSoCuoi - chiSoDau);

                sanLuong = fixedNumber(roundIndex * heSoNhan);
                tongCong = fixedNumber((sanLuongTrucTiep || 0) + (sanLuong || 0) + (sanLuongThao || 0));
              }
              arr.push({
                ...elementDiemDo,
                id: elementDiemDo.boChiSoId,
                diemDoId: element.diemDoId,
                tenDiemDo: element.tenDiemDo,
                maDoXa: element.maDoXa,
                chiSoDau: numberWithSpaces(chiSoDau),
                chiSoCuoi: numberWithSpaces(chiSoCuoi),
                sanLuongTrucTiep: numberWithSpaces(sanLuongTrucTiep),
                tongCong: numberWithSpaces(tongCong),
                loaiNhap: elementDiemDo.loaiNhap,
                sanLuong: numberWithSpaces(sanLuong),
              });
            }
          }
        }
      }
      setDataSource(cloneDeep(arr));
      setDataSourceInitial(cloneDeep(arr));
    },
  });
};

export const getDataDetailDiemDo = ({ setDetailDiemDo, setLoading, id }) => {
  authGetData({
    url: `${Endpoint.QLCS_GET_DETAIL_DIEMDO}?id=${id}`,
    setLoading,
    onSuccess: (res) => {
      setDetailDiemDo(res.data);
    },
  });
};

export const getDataTram = ({ setListTram, setLoading, setTramIDSelected }) => {
  authGetData({
    url: `${Endpoint.QLCS_GET_DANH_SACH_TRAM}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setListTram(res.data.danhSachTram);
        setTramIDSelected(res.data.danhSachTram[0].tramId);
      }
    },
  });
};

export const handleDoXa = ({ diemDoIds, setLoading, callback }) => {
  authPostData({
    url: `${Endpoint.QLCS_LAY_DOXA}`,
    setLoading,
    method: METHOD_PUT,
    payload: { diemDoIds },
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        callback(res.data);
      }
    },
  });
};

export const handleCapNhatChiSo = ({ setLoading, data, callback }) => {
  authPostData({
    url: `${Endpoint.QLCS_CAPNHAT_CHISO}`,
    setLoading,
    method: METHOD_PUT,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        callback(res);
      }
    },
  });
};

export const handleHuyChiSo = ({ setSelectedRowKeys, setLoading, data, refresh }) => {
  authPostData({
    url: `${Endpoint.QLCS_HUY_CHISO}`,
    setLoading,
    method: METHOD_PUT,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setSelectedRowKeys([]);
        refresh();
      }
    },
  });
};

export const getDanhSachDiemDoChuaNhapCS = ({ queryString, callback, setLoading }) => {
  authGetData({
    url: `${Endpoint.QLCS_DIEM_DO_CHUA_NHAP_CHI_SO}?${buildQueryString(queryString)}`,
    setLoading,
    onSuccess: (res) => {
      callback(res);
    },
  });
};
