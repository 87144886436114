import { Col, Form, Row, Select, Button } from "antd";
import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
// import CheckPermission from "@components/CheckPermission";
import { cap_dien } from "@permissions/a_capdien";
import moment from "moment";
import {
  ENUM_BUTTON_TYPE,
  ENUM_QUY,
  FORMAT_DATE,
  FORMAT_ENGLISH,
  FORMAT_MONTH,
  FORMAT_MONTH_ENGLISH,
  FORMAT_YEAR,
  LOAIBAOCAO,
} from "@utils/constants";
import { removeAccents } from "@utils/function";
import { Fragment } from "react";
import CheckPermission from "@components/CheckPermission";

const { Option } = Select;

export default function FormBoLoc(props) {
  const {
    form,
    handleSearch,
    user,
    clear,
    filterConditions,
    donVi,
    loaiHS,
    loaiYeuCau,
    mucDich,
    soPha,
    newFilter,
    handleChange,
    value,
    setType,
  } = props;
  const date = filterConditions.ngayBaoCao
    ? moment(filterConditions.ngayBaoCao).format(FORMAT_ENGLISH)
    : moment().format(FORMAT_ENGLISH);
  const quy =
    date.split("-")[1] === ENUM_QUY.QUY1
      ? 1
      : date.split("-")[1] === ENUM_QUY.QUY2
      ? 2
      : date.split("-")[1] === ENUM_QUY.QUY3
      ? 3
      : 4;
  const nam = date.split("-")[0];

  return (
    <FormComponent
      form={form}
      name="filter-form-chi-tiet"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
        loaiBaoCao: "1",
        loaiHoSo: "1",
      }}
      autoComplete="off"
    >
      <Row gutter={24}>
        <Col span={24} md={8}>
          <Form.Item name="donViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Select
                showSearch
                filterOption={(input, option) => {
                  if (option && option.children) {
                    return (
                      removeAccents(option.children)
                        .toLowerCase()
                        .indexOf(removeAccents(input).toLowerCase()) >= 0
                    );
                  }
                }}
              >
                {donVi && donVi.length > 0
                  ? donVi.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                  : null}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="soPha" label="Số pha">
            <Select placeholder="Tất cả" allowClear>
              {soPha && soPha.length > 0
                ? soPha.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="mucDichSuDung" label="Mục đích sử dụng">
            <Select placeholder="Tất cả" allowClear>
              {mucDich && mucDich.length > 0
                ? mucDich.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="loaiHoSo" label="Loại hồ sơ">
            <Select placeholder="Tất cả" allowClear>
              {loaiHS && loaiHS.length > 0
                ? loaiHS.map((item, index) => {
                    if (item.id !== 0) {
                      return (
                        <Option key={index} value={item.id.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={value === "4" ? 4 : 6}>
          <Form.Item name="loaiYeuCauId" label="Loại yêu cầu">
            <Select placeholder="Tất cả" allowClear>
              {loaiYeuCau && loaiYeuCau.length > 0
                ? loaiYeuCau.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={value === "4" ? 4 : 6}>
          <Form.Item name="loaiBaoCao" label="Loại báo cáo">
            <Select onChange={handleChange}>
              {LOAIBAOCAO && LOAIBAOCAO.length > 0
                ? LOAIBAOCAO.map((item, index) => (
                    <Option key={index} value={item.value.toString()}>
                      {item.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>

        {value === "4" ? (
          <Fragment>
            <Col span={24} md={4}>
              <Form.Item name="ngayBatDau" label="Từ ngày">
                <DatePickerComponent
                  defaultValue={
                    filterConditions?.ngayBatDau
                      ? moment(filterConditions?.ngayBatDau, FORMAT_ENGLISH)
                      : moment(moment(), FORMAT_DATE)
                  }
                  format={FORMAT_ENGLISH}
                  form={form}
                  formKey={"ngayBatDau"}
                  notClear={true}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={4}>
              <Form.Item name="ngayKetThuc" label="Đến ngày">
                <DatePickerComponent
                  defaultValue={
                    filterConditions?.ngayKetThuc
                      ? moment(filterConditions?.ngayKetThuc, FORMAT_ENGLISH)
                      : moment(moment(), FORMAT_DATE)
                  }
                  format={FORMAT_ENGLISH}
                  form={form}
                  formKey={"ngayKetThuc"}
                  notClear={true}
                />
              </Form.Item>
            </Col>
          </Fragment>
        ) : (
          <Col span={24} md={4}>
            <Form.Item name="ngayBaoCao" label="Tháng báo cáo">
              {value === "1" ? (
                <DatePickerComponent
                  defaultValue={
                    filterConditions.ngayBaoCao
                      ? moment(filterConditions.ngayBaoCao, FORMAT_ENGLISH)
                      : moment(moment(), FORMAT_MONTH)
                  }
                  format={FORMAT_ENGLISH}
                  form={form}
                  formKey={"ngayBaoCao"}
                  picker="month"
                  notClear={true}
                  filterConditions={filterConditions}
                />
              ) : value === "3" ? (
                <DatePickerComponent
                  defaultValue={
                    filterConditions.ngayBaoCao
                      ? moment(filterConditions.ngayBaoCao, FORMAT_YEAR)
                      : moment(moment(), FORMAT_MONTH)
                  }
                  format={FORMAT_YEAR}
                  form={form}
                  formKey={"ngayBaoCao"}
                  picker="year"
                  notClear={true}
                  filterConditions={filterConditions}
                />
              ) : value === "2" ? (
                <DatePickerComponent
                  defaultValue={
                    filterConditions.ngayBaoCao
                      ? moment(nam, "YYYY").quarter(quy).startOf("quarter")
                      : null
                  }
                  format={FORMAT_MONTH_ENGLISH}
                  form={form}
                  formKey={"ngayBaoCao"}
                  picker="quarter"
                  notClear={true}
                />
              ) : null}
              {/* <DatePickerComponent
              defaultValue={
                filterConditions.ngayBaoCao
                  ? moment(filterConditions.ngayBaoCao, FORMAT_ENGLISH)
                  : moment(moment(), FORMAT_MONTH)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"ngayBaoCao"}
              picker="month"
              notClear={true}
              filterConditions={filterConditions}
            /> */}
            </Form.Item>
          </Col>
        )}
        <Col
          span={24}
          md={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          {newFilter !== undefined ? null : (
            <Button className="mr-24px" onClick={clear}>
              Bỏ lọc
            </Button>
          )}

          <Button
            type="primary"
            htmlType="submit"
            form="filter-form-chi-tiet"
            className="mr-24px"
            onClick={() => setType(ENUM_BUTTON_TYPE.TIM_KIEM)}
          >
            Tìm kiếm
          </Button>
          <CheckPermission permissionCode={cap_dien.a504_xuat}>
            <Button
              type="primary"
              className="mr-24px"
              htmlType="submit"
              form="filter-form-chi-tiet"
              onClick={() => setType(ENUM_BUTTON_TYPE.XUAT_EXCEL)}
            >
              Xuất excel
            </Button>
          </CheckPermission>
        </Col>
      </Row>
    </FormComponent>
  );
}
