import { Form, Input, Button, Modal, Row, Col, Checkbox, Grid } from "antd";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import {
  Container,
  DivCenter,
} from "../../lap-quyet-toan-cong-ty/ui-modal/css-styled";
import InputFormatter from "@components/InputFormatter";
import { TYPE_INPUT } from "@utils/constants";
const { useBreakpoint } = Grid;

export default function Index(props) {
  const {
    loading,
    visible,
    handleCloseModal,
    handleSubmitData,
    detailLapQuyetToanCongTy,
    form,
    t,
  } = props;
  const screens = useBreakpoint();
  const mobile = (screens.xs || screens.sm || screens.md) && !screens.lg;

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={"Thay đổi thông tin vật tư"}
        visible={visible}
        onCancel={handleCloseModal}
        className="modal-lap-quyet-toan-vat-tu"
        width={"55%"}
        footer={null}
      >
        <FormComponent
          autoComplete="off"
          form={form}
          name="form-lap-quyet-toan-vat-tu"
          layout="vertical"
          onFinish={handleSubmitData}
          initialValues={{ ...detailLapQuyetToanCongTy }}
        >
          <Container>
            <Row gutter={24}>
              <Col span={24} md={24} lg={6}>
                <div>Tên vật tư</div>
              </Col>
              <Col span={24} lg={18}>
                <Form.Item name="tenVatTu">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} md={24} lg={6}>
                <div>Số lượng thực tế</div>
              </Col>
              <Col span={24} lg={18}>
                <Form.Item name="soLuongThucTe">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} md={24} lg={6}>
                <div>Đơn vị tính</div>
              </Col>
              <Col span={24} lg={18}>
                <Form.Item name="donViTinh">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} md={24} lg={6}>
                <div>Chủng loại</div>
              </Col>
              <Col span={24} lg={18}>
                <Form.Item name="chungLoai">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} md={24} lg={6}>
                <div>Định mức</div>
              </Col>
              <Col span={24} md={24} lg={12}>
                <Form.Item name="loaiChiPhiGianTiep">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span={24} md={24} lg={6}>
                <Form.Item name="isVatTuPhuTinhThue" valuePropName="checked">
                  <Checkbox>
                    {t("quyet_toan.lap_quyet_toan_cong_ty.vat_tu_tinh_thue")}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} lg={6}>
                <DivCenter
                  style={
                    mobile
                      ? {
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: 900,
                        }
                      : null
                  }
                >
                  Đơn giá
                </DivCenter>
              </Col>
              <Col span={24} lg={6}>
                <Form.Item label="Vật tư" name="donGiaVatTu">
                  <InputFormatter type={TYPE_INPUT.DECIMAL} decimals={2} />
                </Form.Item>
              </Col>
              <Col span={24} lg={6}>
                <Form.Item label="Nhân công" name="donGiaNhanCong">
                  <InputFormatter type={TYPE_INPUT.DECIMAL} decimals={2} />
                </Form.Item>
              </Col>
              <Col span={24} lg={6}>
                <Form.Item label="Máy thi công" name="donGiaMTC">
                  <InputFormatter type={TYPE_INPUT.DECIMAL} decimals={2} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} lg={6}>
                <DivCenter
                  style={
                    mobile
                      ? {
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: 900,
                        }
                      : null
                  }
                >
                  Hệ số điều chỉnh
                </DivCenter>
              </Col>
              <Col span={24} lg={6}>
                <Form.Item label="k1nc" name="k1NC">
                  <InputFormatter type={TYPE_INPUT.DECIMAL} decimals={2} />
                </Form.Item>
              </Col>
              <Col span={24} lg={6}>
                <Form.Item label="k2nc" name="k2NC">
                  <InputFormatter type={TYPE_INPUT.DECIMAL} decimals={2} />
                </Form.Item>
              </Col>
              <Col span={24} lg={6}>
                <Form.Item label="mtc" name="mtc">
                  <InputFormatter type={TYPE_INPUT.DECIMAL} decimals={2} />
                </Form.Item>
              </Col>
              <Col span={18} style={{ display: "none" }}>
                <Form.Item name="IsVatTuKH" valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} justify="center">
              <Col span={12} style={{ textAlign: "right" }}>
                <Button
                  key="back"
                  onClick={handleCloseModal}
                  className="button-closed"
                >
                  {t("button.dong_lai")}
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  key="submit"
                  type="primary"
                  className="button-primary"
                  htmlType="submit"
                  form="form-lap-quyet-toan-vat-tu"
                >
                  {t("button.cap_nhat")}
                </Button>
              </Col>
            </Row>
          </Container>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}
