import FormComponent from "@components/Form";
import TableComponent from "@components/TableComponent";
import { Endpoint } from "@utils/endpoint";
import { authGetData, downloadFile } from "@utils/request";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { selectBCTongHop } from "./service";

export default function BieuThslSuDungApp(props) {
  const { screen, handleChange } = props;
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = () => {
    const endpoint = `${Endpoint.BCTH_TONG_SO_LUONG_KH_SUDUNG_APP_EXCEL}`;
    if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
  };

  // useEffect(() => {
  //   authGetData({
  //     url: `${Endpoint.BCTH_TONG_SO_LUONG_KH_SUDUNG_APP}`,
  //     setLoading,
  //     onSuccess: (res) => {
  //       if (res) {
  //         setDataTable(res.data);
  //       }
  //     },
  //   });
  // }, []);

  const handleGetDataTable = () => {
    authGetData({
      url: `${Endpoint.BCTH_TONG_SO_LUONG_KH_SUDUNG_APP}`,
      setLoading,
      onSuccess: (res) => {
        if (res) {
          setDataTable(res.data);
        }
      },
    });
  };

  const columns = [
    {
      width: 150,
      title: "Nội dung",
      dataIndex: "noidung",
      key: "noidung",
    },
    {
      width: 70,
      title: "C01",
      dataIndex: "c01",
      key: "C01",
    },
    {
      title: "C02",
      width: 70,
      dataIndex: "c02",
      key: "C02",
    },
    {
      title: "C03",
      width: 70,
      dataIndex: "c03",
      key: "C03",
    },
    {
      width: 70,
      title: "C04",
      dataIndex: "c04",
      key: "C04",
    },
    {
      width: 70,
      title: "C05",
      dataIndex: "c05",
      key: "C05",
    },
    {
      title: "C06",
      width: 70,
      dataIndex: "c06",
      key: "C06",
    },
    {
      width: 70,
      title: "C07",
      dataIndex: "c07",
      key: "C07",
    },
    {
      width: 70,
      title: "C08",
      dataIndex: "c08",
      key: "C08",
    },
    {
      title: "C09",
      width: 70,
      dataIndex: "c09",
      key: "C09",
    },
    {
      title: "C10",
      width: 70,
      dataIndex: "c10",
      key: "C10",
    },
    {
      title: "C11",
      width: 70,
      dataIndex: "c11",
      key: "C11",
    },
    {
      title: "C12",
      width: 70,
      dataIndex: "c12",
      key: "C12",
    },
    {
      title: "C13",
      width: 70,
      dataIndex: "c13",
      key: "C13",
    },
    {
      title: "C14",
      width: 70,
      dataIndex: "c14",
      key: "C14",
    },
    {
      title: "C15",
      width: 70,
      dataIndex: "c15",
      key: "C15",
    },
    {
      title: "C16",
      width: 70,
      dataIndex: "c16",
      key: "C16",
    },
    {
      title: "C17",
      width: 70,
      dataIndex: "c17",
      key: "C17",
    },
    {
      title: "C18",
      width: 70,
      dataIndex: "c18",
      key: "C18",
    },
    {
      title: "C19",
      width: 70,
      dataIndex: "c19",
      key: "C19",
    },
    {
      title: "C20",
      width: 70,
      dataIndex: "c20",
      key: "C20",
    },
    {
      title: "C21",
      width: 70,
      dataIndex: "c21",
      key: "C21",
    },
    {
      title: "C22",
      width: 70,
      dataIndex: "c22",
      key: "C22",
    },
    {
      title: "C23",
      width: 70,
      dataIndex: "c23",
      key: "C23",
    },
    {
      title: "C24",
      width: 70,
      dataIndex: "c24",
      key: "C24",
    },
    {
      title: "C25",
      width: 70,
      dataIndex: "c25",
      key: "C25",
    },
    {
      title: "C26",
      width: 70,
      dataIndex: "c26",
      key: "C26",
    },
    {
      title: "C27",
      width: 70,
      dataIndex: "c27",
      key: "C27",
    },
    {
      title: "C28",
      width: 70,
      dataIndex: "c28",
      key: "C28",
    },
    {
      title: "C29",
      width: 70,
      dataIndex: "c29",
      key: "C29",
    },
    {
      title: "C30",
      width: 70,
      dataIndex: "c30",
      key: "C30",
    },
    {
      width: 150,
      title: "Tổng",
      dataIndex: "tong",
      key: "tong",
    },
  ];

  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              initialValues={{ reportType: screen }}
              form={form}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <Row>
                <Col span={17}>
                  <Form.Item
                    name="reportType"
                    label="Tên báo cáo"
                    labelAlign="left"
                  >
                    <Row>
                      <Col span={24} pull="7">
                        <Select
                          defaultValue={screen}
                          placeholder="Chọn báo cáo"
                          options={selectBCTongHop}
                          onChange={(e) => handleChange(e)}
                        ></Select>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
                <Col span={3} pull={2}>
                  <Form.Item>
                    <Button
                      type="primary"
                      className="full"
                      onClick={handleGetDataTable}
                    >
                      Xem
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={4} pull={2}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="full">
                      Xuất Excel
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Row></Row>
            </FormComponent>
            <Divider className="section-text-line" orientation="left"></Divider>
            <TableComponent
              header={"Kết quả hiển thị"}
              columns={columns}
              dataSource={dataTable}
              scrollX={1200}
            />
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
