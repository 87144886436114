import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { authPostData } from "@utils/request";

export const handleSubmit = (
  setLoading,
  url,
  params,
  setFetchList,
  closeModal
) => {
  authPostData({
    url,
    setLoading,
    payload: params,
    method: METHOD_POST,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
        closeModal();
      }
    },
  });
};

export const callAPIKyBangKe = (
  urlXN,
  setLoading,
  setFetchList,
  closeModal,
  setSelectedRowKeys,
  loaiKy
) => {
  urlXN.forEach((item) => {
    if (item?.ListBaoCao) {
      
      authPostData({
        url: item.url,
        method: METHOD_POST,
        payload: {
          listTinhTrangBangKeIds:  item.ListBaoCao.map((baocao) => baocao.id),
          loaiKy,
        },
        setLoading,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            setSelectedRowKeys([]);
            setFetchList(true);
            closeModal();
          }
        },
      });
    } else if (item?.ListBienBan) {
     
      authPostData({
        url: item.url,
        method: METHOD_POST,
        payload: {
          listTinhTrangBangKeIds: item.ListBienBan.map((bienban) => bienban.id),
          loaiKy,
        },
        setLoading,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            setSelectedRowKeys([]);
            setFetchList(true);
            closeModal();
          }
        },
      });
    }
  });
};

export const callAPIKyHSM = (
  urlHSM,
  setLoading,
  setFetchList,
  closeModal,
  setSelectedRowKeys,
  loaiKy
) => {
  
  urlHSM.forEach((item) => {
    if (item?.ListBaoCao) {
      
      authPostData({
        url: item.url,
        method: METHOD_POST,
        payload: {
          listTinhTrangBangKeIds:  item.ListBaoCao.map((baocao) => baocao.id),
          loaiKy,
        },
        setLoading,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            setSelectedRowKeys([]);
            setFetchList(true);
            closeModal();
          }
        },
      });
    } else if (item?.ListBienBan) {
     
      authPostData({
        url: item.url,
        method: METHOD_POST,
        payload: {
          listTinhTrangBangKeIds: item.ListBienBan.map((bienban) => bienban.id),
          loaiKy,
        },
        setLoading,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            setSelectedRowKeys([]);
            setFetchList(true);
            closeModal();
          }
        },
      });
    }
  });
 
};
