/* eslint-disable no-unused-vars */
import EllipsisTooltip from "@components/EllipsisTooltip";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import { numberWithSpaces } from "@utils/function";
import { Button, Col, DatePicker, Form, Row, Table, Tag } from "antd";
import locale from "antd/es/date-picker/locale/vi_VN";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getThangLamViec } from "../tinh-san-luong/services";
import { Container } from "./css-styled";
import {
  getListChiSoCmis, getListDongBoCmis,
  handleDongBoCmis
} from "./services";

export default function Index() {
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingTBCS, setLoadingTBCS] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [thangLamViec, setThangLamViec] = useState("");
  const [thangLamViecNew, setThangLamViecNew] = useState("");
  const [dataDongBoCmis, setDataDongBoCmis] = useState([]);
  const [dataChiSoCmis, setDataChiSoCmis] = useState([]);
  const [fetchList, setFetchList] = useState(false);
  const [isRowClassName, setIsRowClassName] = useState("");

  //Hiển thị checkbox ở đầu mỗi bảng

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.isExistWebOrDuplicateKey === true,
    }),
  };
  // column table định kỳ

  const columns = [
    {
      title: "Đơn vị",
      dataIndex: "tenDonVi",
      key: "tenDonVi",
      with: "35%",
      render: (text, record) => <div className="table-text-left">{text}</div>,
    },
    {
      title: "Trạng thái của đơn vị",
      dataIndex: "trangThaiDongBo",
      key: "trangThaiDongBo",
      width: "20%",
      render: (text) => (
        <div className="table-text-center">
          {text ? (
            <Tag
              color={
                text === "Chưa đồng bộ"
                  ? "rgb(55 188 188)"
                  : text === "Đã đồng bộ"
                  ? "#d5a6bd"
                  : ""
              }
            >
              {text}
            </Tag>
          ) : null}
        </div>
      ),
    },
    {
      title: "Tổng số điểm đo",
      dataIndex: "soDiemDo",
      key: "soDiemDo",
      width: "20%",
      render: (text) => <div className="table-text-right">{text}</div>,
    },
    {
      title: "Ngày đồng bộ",
      dataIndex: "ngayDongBo",
      key: "ngayDongBo",
      width: "25%",
      render: (text) =>
        text ? (
          <div className="table-text-center">
            {moment(text).format("DD/MM/YYYY HH:mm:ss")}
          </div>
        ) : null,
    },
  ];
  const columnsTableChiSo = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "index",
      render: (item, record, index) => (
        <div className="table-text-center">{index + 1}</div>
      ),
      with: "3%",
      sorter: false,
    },
    {
      title: "Mã sổ GCS",
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      with: "7%",
      render: (text, record) => (
        <EllipsisTooltip line="1" placement="topRight" title={text}>
          {text}
        </EllipsisTooltip>
      ),
    },
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      width: "10%",
    },
    {
      title: "Mã điểm đo CMIS",
      dataIndex: "maDiemDoCmis",
      key: "maDiemDoCmis",
      width: "14%",
      render: (text, record) => (
        <EllipsisTooltip line="1" placement="topRight" title={text}>
          {text}
        </EllipsisTooltip>
      ),
    },
    {
      title: "Loại BCS",
      dataIndex: "loaiBoChiSo",
      key: "loaiBoChiSo",
      width: "10%",
      render: (text, record) => (
        <EllipsisTooltip line="1" placement="topRight" title={text}>
          <div className="table-text-center">{text}</div>
        </EllipsisTooltip>
      ),
    },
    {
      title: "HSN",
      dataIndex: "hsn",
      key: "hsn",
      width: "7%",
      render: (text, record) => (
        <EllipsisTooltip line="1" placement="topRight" title={text}>
          <div className="table-text-center">{text}</div>
        </EllipsisTooltip>
      ),
    },
    {
      title: "Chỉ số cũ",
      dataIndex: "chiSoCu",
      key: "chiSoCu",
      width: "10%",

      render: (text, record) => (
        <EllipsisTooltip line="1" placement="topRight" title={text}>
          <div className="table-text-right">{numberWithSpaces(text)}</div>
        </EllipsisTooltip>
      ),
    },
    {
      title: "Chỉ số mới",
      dataIndex: "chiSoMoi",
      key: "chiSoMoi",
      width: "10%",

      render: (text, record) => (
        <EllipsisTooltip line="1" placement="topRight" title={text}>
          <div className="table-text-right">{numberWithSpaces(text)}</div>
        </EllipsisTooltip>
      ),
    },
    {
      title: "Sản lượng",
      dataIndex: "sanLuong",
      key: "sanLuong",
      width: "10%",

      render: (text, record) => (
        <EllipsisTooltip line="1" placement="topRight" title={text}>
          <div className="table-text-right">{numberWithSpaces(text)}</div>
        </EllipsisTooltip>
      ),
    },
    {
      title: "Sản lượng trực tiếp",
      dataIndex: "sanLuongTrucTiep",
      key: "sanLuongTrucTiep",
      render: (text, record) => (
        <EllipsisTooltip line="1" placement="topRight" title={text}>
          <div className="table-text-right">{numberWithSpaces(text)}</div>
        </EllipsisTooltip>
      ),
    },
  ];
  const handleChangeThangLV = (value, dateString) => {
    setIsRowClassName("");
    setDataChiSoCmis([]);
    setThangLamViecNew(dateString);
    const thang = dateString.split("/")[0];
    const nam = dateString.split("/")[1];
    getListDongBoCmis(setDataDongBoCmis, setLoading, thang, nam);
  };

  const handleDBVeCMIS = () => {
    setIsRowClassName("");
    if (thangLamViecNew !== "") {
      const thang = thangLamViecNew.split("/")[0];
      const nam = thangLamViecNew.split("/")[1];
      const data = {
        thang: parseInt(thang),
        nam: parseInt(nam),
        ids: selectedRowKeys,
      };
      handleDongBoCmis(setFetchList, setLoading, data, setSelectedRowKeys);
    } else {
      const thang = thangLamViec.split("-")[1];
      const nam = thangLamViec.split("-")[0];
      const data = {
        thang: parseInt(thang),
        nam: parseInt(nam),
        ids: selectedRowKeys,
      };
      handleDongBoCmis(setFetchList, setLoading, data, setSelectedRowKeys);
    }
  };

  const handleChiSoCMIS = (record) => {
    if (thangLamViecNew !== "") {
      const thang = thangLamViecNew.split("/")[0];
      const nam = thangLamViecNew.split("/")[1];
      getListChiSoCmis(
        setDataChiSoCmis,
        setLoadingTBCS,
        thang,
        nam,
        record.donViId
      );
    } else {
      const thang = thangLamViec.split("-")[1];
      const nam = thangLamViec.split("-")[0];
      getListChiSoCmis(
        setDataChiSoCmis,
        setLoadingTBCS,
        thang,
        nam,
        record.donViId
      );
    }
  };

  useEffect(() => {
    getThangLamViec(setThangLamViec, setLoading);
  }, []);

  useEffect(() => {
    if (thangLamViec !== "") {
      form.setFieldsValue({
        thangNam: moment(thangLamViec),
      });
    }
  }, [form, thangLamViec]);

  useEffect(() => {
    if (thangLamViec !== "") {
      const thang = thangLamViec.split("-")[1];
      const nam = thangLamViec.split("-")[0];
      getListDongBoCmis(setDataDongBoCmis, setLoading, thang, nam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thangLamViec]);

  useEffect(() => {
    if (fetchList) {
      getThangLamViec(setThangLamViec, setLoading);
      const thang = thangLamViec.split("-")[1];
      const nam = thangLamViec.split("-")[0];
      getListDongBoCmis(setDataDongBoCmis, setLoading, thang, nam);
      setFetchList(!fetchList);
    }
  }, [fetchList]);

  return (
    <FormComponent
      form={form}
      name="filter-form"
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
      }}
      autoComplete="off"
    >
      <LoadingComponent loading={loading}>
        <Container>
          <Row gutter={24}>
            <Col span={7} md={7}>
              <Form.Item name="thangNam" label="Tháng Năm">
                <DatePicker
                  format="MM/YYYY"
                  picker={"month"}
                  locale={locale}
                  form={form}
                  allowClear={false}
                  onChange={handleChangeThangLV}
                />
              </Form.Item>
            </Col>
            <Col
              span={17}
              md={17}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginTop: 5,
              }}
            >
              <Button
                type="primary"
                className="mr-24px"
                disabled={!selectedRowKeys.length}
                onClick={handleDBVeCMIS}
              >
                Đồng bộ về CMIS
              </Button>
            </Col>
            <Col span={24} md={24}>
              <Table
                columns={columns}
                dataSource={dataDongBoCmis}
                scroll={{ y: 130 }}
                rowSelection={rowSelection}
                rowKey={"donViId"}
                rowClassName={(record, index) =>
                  record.donViId === isRowClassName ? "ant-table-row-click" : ""
                }
                pagination={false}
                onRow={(record) => ({
                  onClick: (e) => {
                    setIsRowClassName(record.donViId);
                    handleChiSoCMIS(record);
                  },
                })}
              ></Table>
            </Col>
            <Col span={24} md={24} style={{ marginTop: 20 }}>
              <TableComponent
                columns={columnsTableChiSo}
                dataSource={dataChiSoCmis}
                scroll={{ y: 580, x: true }}
                rowKey={"donViId"}
                pagination={false}
                loading={loadingTBCS}
                bordered={true}
              ></TableComponent>
            </Col>
          </Row>
        </Container>
      </LoadingComponent>
    </FormComponent>
  );
}
