import { Column } from "@ant-design/plots";
import LoadingComponent from "@components/Loading";
import { Fragment, useEffect, useState } from "react";
import { handleKTTBHangNgay } from "../services";

export default function CongToKhongDatColumn(props) {
  const { setTitle } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    handleKTTBHangNgay(setLoading, setData, (values) => {
      setTitle(values?.title ?? "Biểu đồ");
    });
  }, [setTitle]);

  const config = {
    data,
    isGroup: true,
    xField: "xValues",
    yField: "yValues",
    seriesField: "name",
    columnWidthRatio: 0.8,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    layout: [
      {
        type: "vertical",
      },
    ],
  };
  return (
    <Fragment>
      <LoadingComponent loading={loading}>
        <Column {...config} height={300} width={300} />
      </LoadingComponent>
    </Fragment>
  );
}
