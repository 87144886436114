import {
  ENUM_DS_YEU_CAU,
  ENUM_LOAI_PHIEU,
  FORMAT_DATE,
  FORMAT_ENGLISH,
  FUNC_ID_DSYC_TREOTHAO,
  MONTH_STRING,
  TYPE_PHIEUXUAT_CHUCNANG,
} from "@utils/constants";
import { Button, Col, Form, Input, Row } from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import DanhSachYeuCau from "../../common-ver1/danh-sach-yeu-cau";
import CapNhatMaCL from "../../common-ver1/cap-nhat-ma-cl-cong-to";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "@state/system-config/reducer";
import CheckPermission from "@components/CheckPermission";
import { treo_thao } from "@permissions/b_treothao";
import { authSelector } from "@state/auth";
import { alertMessage } from "@utils/function";
import TaoSuaPhieuXuat from "../../common-ver1/thao-tac-phieu-xuat/tao-sua-phieu";
import {
  callAPIRemoveYC,
  handleGetDataLyDoTreoThao,
  handleUpdateLydo,
} from "./services";
import ModalLyDo from "./modal-ly-do";
import { tiepNhanYeuCauCMIS } from "@modules/treo-thao/nhan-vien-kinh-doanh/tiep-nhan-yeu-cau/services";
import ModalNoti from "../../nhan-vien-kinh-doanh/tiep-nhan-yeu-cau/modal-noti-cmis";
import moment from "moment";
import FormComponent from "@components/Form";
import DatePickerComponent from "@components/DatePicker";

export default function Index() {
  const localFilter = localStorage.getItem("createFilterPhieu");
  if (localFilter === "undefined" || localFilter === "null") {
    localStorage.removeItem("createFilterPhieu");
  }
  const { user } = useSelector(authSelector);
  const [fetchList, setFetchList] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isTongCongTy, setIsTongCongTy] = useState(
    user.isTongCongTy ? true : false
  );
  const dispatch = useDispatch();
  const [formlyDo] = Form.useForm();
  const [arrSelectedRows, setArrSelectedRows] = useState([]);
  const [dataLyDo, setDataLyDo] = useState([]);
  const [visibledLyDo, setVisibledLyDo] = useState(false);
  const [disabledLyDo, setDisabledLyDo] = useState(true);
  const [loaiTreoThao, setLoaiTreoThao] = useState();
  const [loading, setLoading] = useState(false);
  const [visibled, setVisibled] = useState(false);
  const [dataNoti, setDataNoti] = useState({});
  const [form] = Form.useForm();
  const handleCloseModalNoti = () => {
    setVisibled(false);
  };

  const handleCMIS = (values) => {
    const payload = {
      ...values,
    };
    tiepNhanYeuCauCMIS(
      payload,
      setLoading,
      setFetchList,
      setVisibled,
      setDataNoti
    );
  };

  const showModalNoti = useCallback(() => {
    return (
      visibled && (
        <ModalNoti
          visible={visibled}
          closeModal={handleCloseModalNoti}
          data={dataNoti}
        />
      )
    );
  }, [visibled, dataNoti]);

  //button nhận yêu cầu
  const genExtra = (filterConditions) => (
    <Fragment>
      <CheckPermission permissionCode={treo_thao.b21x}>
        <Button
          danger
          className="mr-5px"
          disabled={!selectedRowKeys.length}
          onClick={() => handleDeleteYC()}
          loading={loading}
        >
          Xóa yêu cầu
        </Button>
      </CheckPermission>
      <CheckPermission permissionCode={treo_thao.b21t}>
        <Button
          type="primary"
          className="mr-5px"
          onClick={() => handleOpenTaoPhieu(filterConditions)}
          disabled={isTongCongTy || !selectedRowKeys.length ? true : false}
        >
          Tạo phiếu đề nghị xuất kho
        </Button>
      </CheckPermission>

      <CheckPermission permissionCode={treo_thao.b21s}>
        <Button
          type="primary"
          className="mr-5px"
          onClick={() => handleOpenModal()}
          disabled={!selectedRowKeys.length}
        >
          Cập nhật Chủng loại
        </Button>
      </CheckPermission>

      <CheckPermission permissionCode={treo_thao.b21ld}>
        <Button
          type="primary"
          disabled={isTongCongTy || !selectedRowKeys.length}
          onClick={() => handleOpenModalLyDo()}
          loading={loading}
        >
          Cập nhật lý do treo tháo
        </Button>
      </CheckPermission>
    </Fragment>
  );

  const handleDeleteYC = useCallback(() => {
    callAPIRemoveYC(
      selectedRowKeys,
      setLoading,
      setFetchList,
      setSelectedRowKeys
    );
  }, [selectedRowKeys]);

  const findInArray = (value, arr) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== value) return false;
    }
    return true;
  };

  const handleOpenModalLyDo = useCallback(() => {
    if (disabledLyDo) {
      alertMessage(
        "warning",
        "Thông báo!",
        "Không thể cập nhật lý do khi khác loại treo tháo!"
      );
    } else {
      setVisibledLyDo(!visibledLyDo);
    }
  }, [visibledLyDo, disabledLyDo]);

  const handleCloseModalLyDo = () => {
    setVisibledLyDo(false);
  };

  const showModalLyDo = useCallback(() => {
    return (
      visibledLyDo && (
        <ModalLyDo
          visible={visibledLyDo}
          closeModal={handleCloseModalLyDo}
          dataLyDo={dataLyDo}
          tenDonVi={user.unitName}
          onFinish={onFinishLyDo}
          form={formlyDo}
          loading={loading}
        />
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLyDo, visibledLyDo, user.unitName]);

  const handleOpenTaoPhieu = useCallback(
    (filterConditions) => {
      let isChungLoai = false;
      let isDoi = false;
      let isSoCongToTreo = false;
      const arrDoi = [];
      const arrLoaiTreoThao = [];
      // let isToDoi = false;
      arrSelectedRows.map((item) => {
        arrDoi.push(item.doiId);
        arrLoaiTreoThao.push(item.loaiTreoThao);
        if (
          item.chungLoai === "" ||
          item.chungLoai === undefined ||
          item.chungLoai === null
        )
          isChungLoai = true;
        if (
          item.doiId === "" ||
          item.doiId === undefined ||
          item.doiId === null
        )
          isDoi = true;
        if (
          item.soCongToTreo === null ||
          item.soCongToTreo === "" ||
          item.soCongToTreo === undefined
        )
          isSoCongToTreo = true;
        return false;
      });
      const isDuplicateDoi = findInArray(arrDoi[0], arrDoi);
      const isDuplicateTT = findInArray(arrLoaiTreoThao[0], arrLoaiTreoThao);
      if (!isSoCongToTreo) {
        alertMessage(
          "warning",
          "Thông báo",
          "Yêu cầu đã được gán công tơ treo trên CMIS, vui lòng tiếp tục bước Phân công !"
        );
      } else {
        if (isDoi) {
          alertMessage(
            "warning",
            "Thông báo",
            "Có yêu cầu chưa xác định Đội. Hãy xác định Đội trước khi tạo phiếu xuất vật tư!"
          );
        } else {
          if (isChungLoai) {
            alertMessage(
              "warning",
              "Thông báo",
              "Có yêu cầu chưa xác định chúng loại công tơ. Hãy xác định chủng loại công tơ trước khi tạo phiếu xuất vật tư!"
            );
          } else {
            if (!isDuplicateDoi) {
              alertMessage(
                "warning",
                "Thông báo",
                "Chỉ cho phép tạo phiếu xuất vật tư của các yêu cầu có cùng tổ đội!"
              );
            } else if (!isDuplicateTT) {
              alertMessage(
                "warning",
                "Thông báo",
                "Chỉ cho phép tạo phiếu xuất vật tư của các yêu cầu có cùng loại treo tháo!"
              );
            } else {
              return dispatch(
                openModal({
                  content: (
                    <TaoSuaPhieuXuat
                      data={arrSelectedRows}
                      type={TYPE_PHIEUXUAT_CHUCNANG.TAOMOI}
                      selectRowKeys={selectedRowKeys}
                      typeLoaiPhieu={ENUM_LOAI_PHIEU.PHIEU_XUATVT}
                      typeTreoThao={arrSelectedRows[0].loaiTreoThao}
                      dispatch={dispatch}
                      nguoiTao={user.name}
                      doiId={arrSelectedRows[0].doiId}
                      setFetchList={setFetchList}
                      filterConditions={filterConditions}
                    />
                  ),
                  size: "large",
                  title: "Tạo mới phiếu xuất vật tư",
                })
              );
            }
          }
        }
      }
    },
    [arrSelectedRows, dispatch, selectedRowKeys, user.name]
  );

  //Hiển thị checkbox ở đầu mỗi bảng
  // const onSelectChange = (selectedRowKeys, selectedRows) => {
  //   //Lấy giá trị key đã chọn
  //   setSelectedRowKeys(selectedRowKeys);
  //   //Lấy giá trị các dòng được chọn
  //   setArrSelectedRows(selectedRows);
  // };
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    if (selectedRows.length > 0) {
      let disabled = false;
      let distinct = selectedRows[0].loaiTreoThao;
      if (selectedRows && selectedRows.length > 1) {
        const newArr = [];
        for (let i = 0; i < selectedRows.length; i++) {
          newArr.push(selectedRows[i].loaiTreoThao);
        }
        distinct = newArr[0];
        for (let j = 0; j < newArr.length; j++) {
          if (distinct !== newArr[j]) {
            disabled = true;
          }
        }
      }
      //Kiểm tra nếu disable là false, thì mới gán giá trị loaiTreoThao
      if (!disabled) {
        setLoaiTreoThao(distinct);
      }
      setDisabledLyDo(disabled);
    }
    setSelectedRowKeys(selectedRowKeys);
    setArrSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  const [isUpdate, setIsUpdate] = useState(false);
  const [dataUpdate, setDataUpdate] = useState([]);

  //show modal cập nhật CL
  const handleOpenModal = () => {
    let isSoCongToTreo = false;
    arrSelectedRows.forEach((item) => {
      if (
        item.soCongToTreo === null ||
        item.soCongToTreo === "" ||
        item.soCongToTreo === undefined
      ) {
        isSoCongToTreo = true;
      }
    });
    if (!isSoCongToTreo) {
      alertMessage(
        "warning",
        "Thông báo",
        "Yêu cầu đã được gán công tơ treo trên CMIS, vui lòng tiếp tục bước Phân công !"
      );
    } else {
      return dispatch(
        openModal({
          content: (
            <CapNhatMaCL
              setFetchList={setFetchList}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              setIsUpdate={setIsUpdate}
              funcId={FUNC_ID_DSYC_TREOTHAO.DOITRUONG_CANXUATVT}
            />
          ),
          size: "large",
          title: `Cập nhật chủng loại công tơ`,
        })
      );
    }
  };

  const getDataLyDo = useCallback(() => {
    if (!disabledLyDo) {
      handleGetDataLyDoTreoThao(setLoading, setDataLyDo, loaiTreoThao);
    }
  }, [disabledLyDo, loaiTreoThao]);

  useEffect(() => {
    getDataLyDo();
  }, [getDataLyDo]);

  useEffect(() => {
    const newArr = [];
    if (isUpdate) {
      dataUpdate.map((item) => {
        if (selectedRowKeys.includes(item.id)) {
          newArr.push(item);
        }
        return true;
      });
      setArrSelectedRows(newArr);
    }
  }, [dataUpdate, isUpdate, selectedRowKeys]);

  const onFinishLyDo = (values) => {
    if (selectedRowKeys.length > 0 && values.lyDoId !== undefined) {
      const payload = {
        ids: selectedRowKeys.toString(),
        ...values,
      };
      handleUpdateLydo(
        setLoading,
        payload,
        handleCloseModalLyDo,
        setFetchList,
        setSelectedRowKeys,
        formlyDo
      );
    } else {
      alertMessage("warning", "Thông báo!", "Lý do không được để trống!");
    }
  };

  const genExtraDongBo = () => (
    <CheckPermission permissionCode={treo_thao.b11db}>
      <FormComponent
        form={form}
        name="dong-bo-yc"
        onFinish={handleCMIS}
        layout="horizonal"
        initialValues={{}}
        autoComplete="off"
      >
        <Row gutter={24} style={{ alignItems: "baseline", height: "32px" }}>
          <Col span={24} md={5}>
            <Form.Item name="maDiemDo">
              <Input
                placeholder="Nhập mã điểm đo"
                allowClear
                // onChange={handleChangeMaDD}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={5}>
            <Form.Item name="maSoGCS">
              <Input
                placeholder="Nhập sổ GCS"
                allowClear
                // onChange={handleChangeMaKH}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={5}>
            <Form.Item name="tuNgay">
              <DatePickerComponent
                defaultValue={moment(
                  moment().startOf(MONTH_STRING),
                  FORMAT_DATE
                )}
                format={FORMAT_ENGLISH}
                form={form}
                formKey={"tuNgay"}
                notClear={true}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={5}>
            <Form.Item name="denNgay">
              <DatePickerComponent
                defaultValue={moment(moment(), FORMAT_DATE)}
                format={FORMAT_ENGLISH}
                form={form}
                formKey={"denNgay"}
                notClear={true}
              />
            </Form.Item>
          </Col>
          <Col
            span={24}
            md={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              form="dong-bo-yc"
              disabled={isTongCongTy}
              style={{
                width: "100%",
                // marginRight: "12px",
              }}
            >
              Đồng bộ CMIS
            </Button>
          </Col>
        </Row>
      </FormComponent>
    </CheckPermission>
  );

  return (
    <Fragment>
      <DanhSachYeuCau
        funcId={FUNC_ID_DSYC_TREOTHAO.DOITRUONG_CANXUATVT}
        typeList={ENUM_DS_YEU_CAU.DOITRUONG_YEUCAUCANXUATVATTU}
        genExtra={genExtra}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        rowSelection={rowSelection}
        setIsTongCongTy={setIsTongCongTy}
        localFilter={localFilter ? JSON.parse(localFilter) : null}
        setSelectedRowKeys={setSelectedRowKeys}
        setDataUpdate={setDataUpdate}
        setIsUpdate={setIsUpdate}
        genExtraDongBo={genExtraDongBo}
      />
      {showModalLyDo()}
      {showModalNoti()}
    </Fragment>
  );
}
