import { Upload } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
`;

export const HeaderTable = styled.div`
  font-weight: bold;
  text-align: center;
  border: 1px solid #f0f0f0;
  background: rgb(217, 217, 217);
  width: 100%;
`;

export const BodyTable = styled.div`
  font-weight: bold;
  text-align: center;
  border: 1px solid #f0f0f0;
  width: 100%;
`;

export const ContainerMultiUpload = styled(Upload)`
  width: 85%;
  .ant-upload.ant-upload-select {
    width: 100%;
  }
  button {
    height: 100px;
    width: 100%;
    background: rgb(250, 250, 250);
    border: 1px dashed rgb(217, 217, 217);
    text-align: center;
  }

  .upload-container {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .upload-choice {
      font-size: 16px;
    }
  }
`;

export const ContainerUpload = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;

  .choice-file {
    position: relative;
    width: 90%;
    height: 100px;
  }
`;

export const UploadFileContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  border: 1px dashed rgb(217, 217, 217);
  background: rgb(250, 250, 250);

  p {
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 1;
    color: #000000;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }
`;
