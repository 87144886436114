import { BASE_API_URL_DANH_MUC } from "@utils/constants";

export const APIHinhThucThiCong = {
  LIST_HINH_THUC_THI_CONG: `${BASE_API_URL_DANH_MUC}/hinh-thuc-thi-cong/list`,
  SYNC_HINH_THUC_THI_CONG: `${BASE_API_URL_DANH_MUC}/hinh-thuc-thi-cong/sync`,
  CREATE_HINH_THUC_THI_CONG: `${BASE_API_URL_DANH_MUC}/hinh-thuc-thi-cong/create`,
  UPDATE_HINH_THUC_THI_CONG: `${BASE_API_URL_DANH_MUC}/hinh-thuc-thi-cong/update`,
  REMOVE_HINH_THUC_THI_CONG: `${BASE_API_URL_DANH_MUC}/hinh-thuc-thi-cong/delete`,
  GET_HINH_THUC_THI_CONG: `${BASE_API_URL_DANH_MUC}/hinh-thuc-thi-cong/get`,
};
