import React from "react";
import ListMenu from "./list-menu";

function ListChucNang({ detail }) {
  return (
    <>
      <ListMenu detail={detail} />
    </>
  );
}

export default ListChucNang;
