import CheckPermission from "@components/CheckPermission";
import XemLaiBBLapDatComponent from "@modules/cap-dien/common-ho-so/xem-bien-ban-lap-dat";
import { cap_dien } from "@permissions/a_capdien";
import { LITMIT_TRANGTHAI, SHOW_UPLOAD } from "@utils/constants";
import { Menu } from "antd";

import { checkPermissionViewImage } from "./services";
const { SubMenu } = Menu;

export default function ListMenu(props) {
  const {
    t,
    dispatch,
    openModal,
    ApGiaHoSoDetail,
    detail,
    closeModal,
    setFetchDataTable,
    handleOpenInHoSoCMIS,
    inBienBanApGia,
    handleOpenXemLaiPACD,
    handleOpenQuyetToanKH,
    handleOpenQuyetToanCT,
    handleOpenInBieuKG,
    setLoading,
    handleOpenInPhuTai,
    handleTraLaiApGia,
  } = props;

  return (
    <Menu mode="vertical" style={{ width: "100%" }} selectedKeys={["0"]}>
      <CheckPermission permissionCode={cap_dien.a21_ap_gia_hs}>
        <Menu.Item
          eventKey="1"
          onClick={() =>
            dispatch(
              openModal({
                content: (
                  <ApGiaHoSoDetail
                    detail={detail}
                    closeModal={closeModal}
                    setFetchDataTable={setFetchDataTable}
                  />
                ),
                size: "large",
              })
            )
          }
        >
          {t("ap_gia_ho_so.ap_gia_ho_so")}
        </Menu.Item>
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a21tl}>
        <Menu.Item eventKey="125" onClick={handleTraLaiApGia}>
          Trả lại áp giá
        </Menu.Item>
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a21_tai_anh_hskh}>
        {SHOW_UPLOAD.includes(detail.maTrangThai) ? (
          <Menu.Item
            eventKey="2"
            onClick={() => {
              checkPermissionViewImage(
                detail,
                setLoading,
                dispatch,
                openModal,
                closeModal
              );
            }}
          >
            {t("common_cap_dien.tai_anh_ho_so_khach_hang")}
          </Menu.Item>
        ) : null}
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a21_in_hs_cmis}>
        <Menu.Item eventKey="3" onClick={handleOpenInHoSoCMIS}>
          {t("common_cap_dien.in_ho_so_cmis")}
        </Menu.Item>
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a11_in_hs_web}>
        {Number(detail.maTrangThai) >= LITMIT_TRANGTHAI ? (
          <SubMenu
            eventKey="inHoSoWeb"
            title={t("common_cap_dien.in_ho_so_web")}
          >
            <Menu.Item eventKey="4" onClick={handleOpenXemLaiPACD}>
              {t("common_cap_dien.xem_lai_phuong_an_cap_dien")}
            </Menu.Item>
            <XemLaiBBLapDatComponent idHoSo={detail?.id ?? detail?.hoSoId} />
            <Menu.Item eventKey="20" onClick={handleOpenInPhuTai}>
              In biểu đồ phụ tải
            </Menu.Item>
            <Menu.Item eventKey="5" onClick={handleOpenQuyetToanKH}>
              {t("common_cap_dien.quyet_toan_khach_hang")}
            </Menu.Item>
            <Menu.Item eventKey="6" onClick={handleOpenQuyetToanCT}>
              {t("common_cap_dien.quyet_toan_cong_ty")}
            </Menu.Item>
            <Menu.Item eventKey="7" onClick={handleOpenInBieuKG}>
              {t("common_cap_dien.in_bieu_khai_gia")}
            </Menu.Item>
            <Menu.Item eventKey="8" onClick={inBienBanApGia}>
              {t("common_cap_dien.in_bien_ban_ap_gia")}
            </Menu.Item>
          </SubMenu>
        ) : null}
      </CheckPermission>
    </Menu>
  );
}
