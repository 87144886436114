import { BASE_API_URL_THANH_TOAN } from "@utils/constants";

export const API_QUAN_LY_KHUYEN_MAI_THANH_TOAN = {
  THONG_TIN_KHUYEN_MAI_THANH_TOAN: `${BASE_API_URL_THANH_TOAN}/khuyenmai/danh-sach-khuyen-mai`,
  SUA_THONG_TIN_KHUYEN_MAI_THANH_TOAN: `${BASE_API_URL_THANH_TOAN}/khuyenmai/update-khuyen-mai`,
  TAO_THONG_TIN_KHUYEN_MAI_THANH_TOAN: `${BASE_API_URL_THANH_TOAN}/khuyenmai/create-khuyen-mai`,
  XOA_THONG_TIN_KHUYEN_MAI_THANH_TOAN: `${BASE_API_URL_THANH_TOAN}/khuyenmai/delete-khuyen-mai`,
  CHI_TIET_KHUYEN_MAI_THANH_TOAN: `${BASE_API_URL_THANH_TOAN}/khuyenmai/chi-tiet-khuyen-mai`,
  CODE_KHUYEN_MAI_THANH_TOAN: `${BASE_API_URL_THANH_TOAN}/khuyenmai/get-code`,
};
