import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { authGetData, authPostData } from "@utils/request";
import { getErrorMessage } from "@utils/function";
import { Endpoint } from "@utils/endpoint";

export const handleMultiNhanVien = (
  setLoading,
  setDataTreoThao,
  setDataNiemPhong,
  selectedRowKeys
) => {
  authGetData({
    url: `${Endpoint.LIST_NHAN_VIEN_TREO_THAO_OR_NIEM_PHONG}?Ids=${selectedRowKeys}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data)
        setDataTreoThao(res.data.nhanVienTreoThaos);
      setDataNiemPhong(res.data.nhanVienNiemPhongs);
    },
  });
};

export const handleSubmitPhanCong = (
  setLoading,
  payload,
  setFetchList,
  form,
  closeModal,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.PHAN_CONG_DU_LIEU,
    method: METHOD_PUT,
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
        setSelectedRowKeys([]);
        closeModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleListLog = (setLoading, setDataNVLog) => {
  authGetData({
    url: `${Endpoint.LIST_DATA_LOG_PHAN_CONG}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataNVLog(res.data);
      }
    },
  });
};
