import { Fragment, useMemo, useState } from "react";
import ListMenu from "./list-menu";
import ModalXacNhan from "./modal";

export default function ListChucNang(props) {
  const { detail, setFetchDataTable, setIsDoubleClick } = props;

  const [visibleXacNhan, setVisibleXacNhan] = useState(false);
  const handleOpenModalXacNhan = () => {
    setVisibleXacNhan(!visibleXacNhan);
    setIsDoubleClick(false);
  };

  const handleCloseModalXacNhan = () => {
    setVisibleXacNhan(false);
    setIsDoubleClick(true);
  };

  //modal xác nhận nghiệm thu

  const showModalXacNhanNghiemThu = useMemo(
    () =>
      visibleXacNhan && (
        <ModalXacNhan
          visibleXacNhan={visibleXacNhan}
          handleCloseModalXacNhan={handleCloseModalXacNhan}
          setFetchDataTable={setFetchDataTable}
          detail={detail}
        />
      ),
    // eslint-disable-next-line
    [visibleXacNhan]
  );

  return (
    <Fragment>
      <ListMenu
        handleOpenModalXacNhan={handleOpenModalXacNhan}
        detail={detail}
      />
      {showModalXacNhanNghiemThu}
    </Fragment>
  );
}
