import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { FORMAT_MONTH_ENGLISH } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Form, Row, Select } from "antd";
import moment from "moment";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
export default function FilterThang(props) {
  const { clearFilter, filterConditions, loading, handleSearch, loadingBtn } =
    props;
  const [form] = Form.useForm();
  const { user } = useSelector(authSelector);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  const handleChangeUnit = useCallback(() => {
    form.setFieldsValue({
      TodoiId: undefined,
    });
  }, [form]);

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      initialValues={{
        DonViId: user.unitId,
      }}
      layout="vertical"
      autoComplete="off"
    >
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item name="DonViId" label="Đơn vị">
            <Selection
              url={Endpoint.GET_UNIT_LIST}
              form={form}
              formKey="DonViId"
              setValue={handleChangeUnit}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.DonViId !== currentValues.DonViId
            }
          >
            {({ getFieldValue }) => {
              if (getFieldValue("DonViId")) {
                return (
                  <Form.Item name="TodoiId" label="Tổ/Đội">
                    <Selection
                      url={`${
                        Endpoint.AUTH_LIST_TO_DOI
                      }?DonViId=${getFieldValue("DonViId")}`}
                      formKey="TodoiId"
                      form={form}
                      disabled={!getFieldValue("DonViId")}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="TodoiId" label="Tổ/Đội">
                  <Select disabled />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={4} md={3}>
          <Form.Item name="ThangNam" label="Tháng" labelAlign="right">
            <Row>
              <Col span={20}>
                <DatePickerComponent
                  defaultValue={
                    filterConditions.ThangNam
                      ? moment(filterConditions.ThangNam)
                      : moment(moment(), FORMAT_MONTH_ENGLISH)
                  }
                  picker="month"
                  format={"YYYY-MM"}
                  formKey="ThangNam"
                  form={form}
                />
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <ButtonFilter>
          <Col span={3} md={2}>
            <Form.Item>
              <Button className="btn-fillter" onClick={clear}>
                Bỏ lọc
              </Button>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Button
              className="btn-fillter"
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loadingBtn}
            >
              Tìm kiếm
            </Button>
          </Col>
        </ButtonFilter>
      </Row>
    </FormComponent>
  );
}

const ButtonFilter = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
`;
