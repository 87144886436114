import DatePickerComponent from "@components/DatePicker";
import Filter from "../../../common/Filter";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_YEAR,
  FORMAT_MONTH_ENGLISH
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { Button, Col, Form, Row, Select, Spin } from "antd";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Export } from "../../export/Export";
import { getDanhSachCongToNoCap } from "./services";
import SelectMaSoGCS from "../common/SelectGetMaSoGCS";
import shouldUpdateMaso from "../common/should-update-ma-so";
export default function BKKhongChupAnh() {
  const [, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [dataCustomerNoCap, setDataCustomerNoCap] = useState();
  const [totalCount, setTotalCount] = useState();
  const [form] = Form.useForm();
  const { user } = useSelector(authSelector);

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    KyGCS: 1,
    ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
    ...parseParams(location.search),
  });
  const [query, setQuery] = useState({
    pageIndex: 1,
    pageSize: 50,
  });
  const [listMaso, setListMaso] = useState([]);
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const queryString = buildQueryString(
    parseParams({
      DonViId: filterConditions.DonViId,
      ToDoiId: filterConditions.ToDoiId,
      MaSoGCS: filterConditions.MaSoGCS,
      TramId: filterConditions.TramId,
      KyGCS: filterConditions.KyGCS,
      ThangNam: filterConditions.ThangNam === null ? undefined : filterConditions.ThangNam,
    })
  );


  useEffect(() => {
    const query = {
      ...filterConditions
    }
    getDanhSachCongToNoCap(
      query,
      setLoading,
      setDataCustomerNoCap,
      setTotalCount
    );
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      ...values,
      MaSoGCS: values.MaSoGCS?.toString(),
    }));

    const query = {
      ...filterConditions,
      DonViId: values.DonViId,
      ToDoiId: values.ToDoiId,
      MaSoGCS: values.MaSoGCS,
      TramId: values.TramId,
      KyGCS: values.KyGCS,
      ThangNam: values.ThangNam === null ? undefined : values.ThangNam,
    }

    getDanhSachCongToNoCap(
      query,
      setLoading,
      setDataCustomerNoCap,
      setTotalCount
    );
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangePagination = (paging, sorter) => {
    // console.log("paging", paging);
    // console.log("sorter", sorter);
    handlePagination(paging, sorter, setFilterConditions);
  };
  const clear = useCallback(() => {
    form.resetFields();
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      KyGCS: 1,
      ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
    });


    const InitQuery = {
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      KyGCS: 1,
      ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
    }

    getDanhSachCongToNoCap(
      InitQuery,
      setLoading,
      setDataCustomerNoCap,
      setTotalCount
    );
    // form.setFieldsValue({ MaSoGCS: undefined });
  }, [form, user.unitId]);

  const genExtra = useCallback(
    () => (
      <div className="export">
        <Export
          excelEndpoint={`${Endpoint.XUAT_EXCEL_CUSTOMER_NO_CAP}?${queryString}`}
          pdfEndpoint={`${Endpoint.XUAT_PDF_CUSTOMER_NO_CAP}?${queryString}`}
          setLoading={setLoading}
        />
      </div>
    ),
    [queryString]
  );

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 70,
      align: "center",
      render: (a, b, index) => {
        return (
          (filterConditions.pageIndex - 1) * filterConditions.pageSize +
          (index + 1)
        );
      },
    },
    {
      title: "Thông tin công tơ",
      dataIndex: "thongTinCongTo",
      key: "thongTinCongTo",
      children: [
        {
          title: "Tên KH",
          dataIndex: "tenKhachHang",
          key: "tenKhachHang",
          width: 200,
        },
        {
          title: "Địa chỉ",
          dataIndex: "diaChi",
          key: "diaChi",
          width: 200,
        },
        {
          title: "Chỉ số cũ",
          dataIndex: "chiSoCu",
          key: "chiSoCu",
          width: 100,
        },
        {
          title: "Chỉ số mới",
          dataIndex: "chiSoMoi",
          key: "chiSoMoi",
          width: 100,
        },
        {
          title: "Tình trạng mới",
          dataIndex: "newIndex",
          key: "newIndex",
          width: 100,
        },
        {
          title: "DNTT tháng 1",
          dataIndex: "dnttThang1",
          key: "dnttThang1",
          width: 100,
        },
        {
          title: "DNTT tháng 0",
          dataIndex: "dnttThang2",
          key: "dnttThang2",
          width: 100,
        },
        {
          title: "DNTT tháng -1",
          dataIndex: "dnttThang3",
          key: "dnttThang3",
          width: 100,
        },
        {
          title: "ĐNTT",
          dataIndex: "dntt",
          key: "dntt",
          width: 100,
        },
        {
          title: "Loại bộ chỉ số",
          dataIndex: "loaiBoCS",
          key: "loaiBoCS",
          width: 100,
        },
        {
          title: "Thời gian ghi",
          dataIndex: "thoiGianGhi",
          key: "thoiGianGhi",
          width: 100,
          // render: (thoiGianGhi) => {
          //   return <span>{formatDateWithTimezoneGB(thoiGianGhi)}</span>;
          // },
        },
        {
          title: "Số hộ",
          dataIndex: "soho",
          key: "soho",
          width: 100,
        },
        {
          title: "Mã ghi chữ",
          dataIndex: "maGhiChu",
          key: "maGhiChu",
          width: 100,
        },
        {
          title: "Mã trạm",
          dataIndex: "maTram",
          key: "maTram",
          width: 100,
        },
        {
          title: "Mã C.Tơ",
          dataIndex: "maCongTo",
          key: "maCongTo",
          width: 100,
        },
        {
          title: "Mã KH",
          dataIndex: "maKhachHang",
          key: "maKhachHang",
          width: 100,
        },
        {
          title: "Đối soát",
          dataIndex: "doiSoat",
          key: "doiSoat",
          width: 100,
        },
      ],
    },
  ];
  return (
    <Fragment>
      <Container>
        <Spin spinning={loading}>
          <Filter>
            <FormComponent
              form={form}
              initialValues={{ KyGCS: "1", DonViId: user.unitId }}
              name="filter-form"
              onFinish={handleSearch}
              layout="vertical"
            >
              <Row gutter={24}>
                <Col span={24} md={6}>
                  <Form.Item name="DonViId" label="Đơn vị">
                    <Selection
                      url={Endpoint.GET_UNIT_LIST}
                      form={form}
                      formKey="DonViId"
                    // setValue={handleChangeUnit}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.DonViId !== currentValues.DonViId
                    }
                  >
                    {({ getFieldValue }) => {
                      if (getFieldValue("DonViId")) {
                        return (
                          <Form.Item name="ToDoiId" label="Tổ/Đội">
                            <Selection
                              url={`${Endpoint.AUTH_LIST_TO_DOI
                                }?DonViId=${getFieldValue("DonViId")}`}
                              formKey="ToDoiId"
                              form={form}
                              disabled={!getFieldValue("DonViId")}
                            />
                          </Form.Item>
                        );
                      }
                      return (
                        <Form.Item name="ToDoiId" label="Tổ/Đội">
                          <Select disabled />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={24} md={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => {
                      if (prevValues.DonViId !== currentValues.DonViId) {
                        form.setFieldsValue({
                          TramId: undefined,
                        });
                      }
                      return prevValues.DonViId !== currentValues.DonViId;
                    }}
                  >
                    {({ getFieldValue }) => {
                      if (getFieldValue("DonViId")) {
                        return (
                          <Form.Item name="TramId" label="Trạm">
                            <Selection
                              url={`${Endpoint.LIST_TRAM_CT
                                }?donviId=${getFieldValue("DonViId")}`}
                              formKey="TramId"
                              form={form}
                            />
                          </Form.Item>
                        );
                      }
                      return (
                        <Form.Item name="TramId" label="Trạm">
                          <Selection
                            url={`${Endpoint.LIST_TRAM_CT}?donviId=${user.unitId}`}
                            formKey="TramId"
                            form={form}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={24} md={3}>
                  <Form.Item name="KyGCS" label="Kỳ GCS" labelAlign="right">
                    <Select>
                      <Select.Option value="1">1</Select.Option>
                      <Select.Option value="2">2</Select.Option>
                      <Select.Option value="3">3</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} md={3}>
                  <Form.Item
                    name="ThangNam"
                    label="Tháng /Năm"
                    labelAlign="right"
                  >
                    <DatePickerComponent
                      defaultValue={
                        filterConditions.ThangNam
                          ? moment(filterConditions.ThangNam)
                          : moment(moment(), FORMAT_YEAR)
                      }
                      picker="month"
                      formKey="ThangNam"
                      format={"YYYY-MM"}
                      form={form}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} md={24}>
                <Form.Item
                    name="MaSoGCS"
                    label="Mã sổ"
                    shouldUpdate={(prevValues, currentValues) => {
                      shouldUpdateMaso({prevValues, currentValues, setQueryMaso: setQuery, form, setListMaso, fieldUpdate:"MaSoGCS"})
                    }}
                  >
                    <SelectMaSoGCS
                      query={query}
                      setQuery={setQuery}
                      url={Endpoint.LIST_MA_SO_BANG_KE_NO_TO_DOI}
                      formKey="MaSoGCS"
                      form={form}
                      loading
                      mode={"multiple"}
                      listMaso={listMaso}
                      setListMaso={setListMaso}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end">
                <Button className="mr-24px" onClick={clear}>
                  Bỏ lọc
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Tìm kiếm
                </Button>
              </Row>
            </FormComponent>
          </Filter>
          <TableComponent
            header={"Danh sách khách hàng không chụp ảnh"}
            renderExtraAction={() => genExtra()}
            dataSource={dataCustomerNoCap}
            columns={customColumn(columns, filterConditions)}
            totalData={totalCount}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            scrollX={1400}
          />
        </Spin>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
  .ant-collapse.ant-collapse-icon-position-left {
    margin-top: 20px;
  }
`;
