import { useState } from "react";
import UploadImage from "./layout";
import { Modal } from "antd";
import {
  handleUploadBBAGPDF,
} from "./services";

export default function Index(props) {
  const {
    visible,
    closeModal,
    idUpload,
    setFetchDataTable,
  } = props;
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const [isSubmit] = useState(false);
  //upload image
  const handleUploadImage = () => {
    const payload = {
      id: idUpload,
      fileName: imageFiles[0].name,
      base64Data: imageFiles[0].base64Data.split(";base64,")[1],
    };
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: `Sau khi xác nhận KH đã ký sẽ không thể thay đổi thông tin biên bản hoặc thay đổi BB giấy đã upload, bạn có chắc chắn muốn tiếp tục?`,
      onOk() {
        //call api
        handleUploadBBAGPDF(
          setLoading,
          payload,
          setFetchDataTable,
          closeModal,
        );
      },
      onCancel() { },
      okText: "Đồng ý",
      cancelText: "Hủy",
    });
    
  };
  const fileToDataUri = (image) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { type, name, size } = image;
      reader.addEventListener("load", () => {
        res({
          base64Data: reader.result,
          name,
          type,
          size,
          upload: true,
        });
      });
      reader.readAsDataURL(image);
    });
  };

  return (
    <UploadImage
      visible={visible}
      closeModal={closeModal}
      handleUploadImage={handleUploadImage}
      loading={loading}
      fileToDataUri={fileToDataUri}
      imageFiles={imageFiles}
      setImageFiles={setImageFiles}
      isSubmit={isSubmit}
      isUpload={isUpload}
      setIsUpload={setIsUpload}
    />
  );
}
