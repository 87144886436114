import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APICauHinhHoSo = {
  LIST_CAU_HINH_HO_SO: `${BASE_API_URL_CAP_DIEN}/ho-so-cau-hinh/list`,
  CREATE_CAU_HINH_HO_SO: `${BASE_API_URL_CAP_DIEN}/ho-so-cau-hinh/create`,
  UPDATE_CAU_HINH_HO_SO: `${BASE_API_URL_CAP_DIEN}/ho-so-cau-hinh/update`,
  REMOVE_CAU_HINH_HO_SO: `${BASE_API_URL_CAP_DIEN}/ho-so-cau-hinh/delete`,
  LIST_THOI_GIAN_HIEN_LUC_CAU_HINH_HO_SO: `${BASE_API_URL_CAP_DIEN}/ho-so-cau-hinh/list-thoi-gian-hieu-luc`,
  REMOVE_THOI_GIAN_HIEN_LUC_CAU_HINH_HO_SO: `${BASE_API_URL_CAP_DIEN}/ho-so-cau-hinh/delete-thoi-gian-hieu-luc`,
};
