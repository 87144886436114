import ReactDOMServer from "react-dom/server";
import moment from "moment";
const dateFilterCondition = (objStartDate, objEndDate) => {
  const isStartDateAfterEndDate = moment(objStartDate).isAfter(
    moment(objEndDate)
  );
  return {
    isStartDateAfterEndDate,
  };
};

export const dateConditionLogic = (objStartDate, objEndDate) => {
  const dateCondition = dateFilterCondition(objStartDate, objEndDate);
  // eslint-disable-next-line prefer-const
  let formatStartDate = `${moment(objStartDate).format("YYYY-MM-DD")}`;
  // eslint-disable-next-line prefer-const
  let formatEndDate = `${moment(objEndDate).format("YYYY-MM-DD")}`;

  if (dateCondition.isStartDateAfterEndDate) {
    formatStartDate = `${moment().startOf("month").format("YYYY-MM-DD")}`;
    formatEndDate = `${moment().endOf("month").format("YYYY-MM-DD")}`;
  }

  return { formatStartDate, formatEndDate };
};

export function encodeSvg(reactElement) {
  return (
    "data:image/svg+xml," +
    escape(ReactDOMServer.renderToStaticMarkup(reactElement))
  );
}

export function tenNhanVienIcon(tenNhanVien) {
  if (tenNhanVien.length > 11) {
    const arrayTenNhanVien = tenNhanVien.split(" ");
    const doDaiTenNhanVien = arrayTenNhanVien.length;

    const tenNhanVienRutGon =
      arrayTenNhanVien[doDaiTenNhanVien - 2] +
      " " +
      arrayTenNhanVien[doDaiTenNhanVien - 1];
    return tenNhanVienRutGon;
  } else {
    return tenNhanVien;
  }
}
