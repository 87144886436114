import { Menu } from "antd";

export default function ListMenu({
  row,
  setIsShowDetail,
  setDetail,
}) {
  return (
    <Menu mode="vertical" style={{ width: "100%" }}>
      <Menu.Item
        eventKey="1"
        onClick={
          () => {
            setIsShowDetail(true);
            setDetail(row);
          }
        }
      >
        Cập nhật yêu cầu
      </Menu.Item>
    </Menu>
  );
}
