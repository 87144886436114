import CheckStatus from "@components/CheckStatus";
import {
  MATRIX_CAP_DIEN,
  SEQUENCE_TRANGTHAI,
  LITMIT_TRANGTHAI,
} from "@utils/constants";
import CheckPermission from "@components/CheckPermission";
import { cap_dien } from "@permissions/a_capdien";
import { Menu } from "antd";
import XemLaiBBLapDatComponent from "@modules/cap-dien/common-ho-so/xem-bien-ban-lap-dat";

const { SubMenu } = Menu;

export default function ListMenu(props) {
  const {
    handleOpenDuyetHoSo,
    handleOpenTraHoSo,
    handleOpenModalInHoSoCMIS,
    handleOpenModalXemLaiPhuongAnCapDien,
    handleOpenModalQuyetToanKhachHang,
    handleOpenModalQuyetToanCongTy,
    handleOpenModalInBieuKhaiGia,
    inBienBanApGia,
    detail,
    handleOpenInPhuTai,
  } = props;
  return (
    <Menu mode="vertical" style={{ width: "100%" }} selectedKeys={["0"]}>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.XACNHANHS}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.LD_DUYETHS[1]}
        permissionCode={cap_dien.a71_duyet_hs}
      >
        <Menu.Item eventKey="1" onClick={handleOpenDuyetHoSo}>
          Duyệt hồ sơ
        </Menu.Item>
      </CheckStatus>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.TRALAIHS}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.LD_DUYETHS[1]}
        permissionCode={cap_dien.a71_tra_hs}
      >
        <Menu.Item eventKey="2" onClick={handleOpenTraHoSo}>
          Trả hồ sơ
        </Menu.Item>
      </CheckStatus>
      <Menu.Item eventKey="4" onClick={handleOpenModalInHoSoCMIS}>
        In hồ sơ CMIS
      </Menu.Item>
      <CheckPermission permissionCode={cap_dien.a71_in_hs_web}>
        {Number(detail.maTrangThai) >= LITMIT_TRANGTHAI ? (
          <SubMenu eventKey="inHoSoWeb" title="In hồ sơ Web">
            <Menu.Item
              eventKey="5"
              onClick={handleOpenModalXemLaiPhuongAnCapDien}
            >
              Xem lại phương án cấp điện
            </Menu.Item>
            <XemLaiBBLapDatComponent idHoSo={detail?.id ?? detail?.hoSoId} />
            <Menu.Item eventKey="20" onClick={handleOpenInPhuTai}>
              In biểu đồ phụ tải
            </Menu.Item>
            <Menu.Item eventKey="6" onClick={handleOpenModalQuyetToanKhachHang}>
              Quyết toán khách hàng
            </Menu.Item>
            <Menu.Item eventKey="7" onClick={handleOpenModalQuyetToanCongTy}>
              Quyết toán công ty
            </Menu.Item>
            <Menu.Item eventKey="8" onClick={handleOpenModalInBieuKhaiGia}>
              In biểu khai giá
            </Menu.Item>
            <Menu.Item eventKey="9" onClick={inBienBanApGia}>
              In biên bản áp giá
            </Menu.Item>
            {/* <Menu.Item eventKey="10">
          In phiếu tiếp nhận thông tin đề nghị mua điện
        </Menu.Item> */}
          </SubMenu>
        ) : null}
      </CheckPermission>
    </Menu>
  );
}
