import { Form, Input, Button, Row, Col, Checkbox, Select } from "antd";
import FormComponent from "@components/Form";

const { Option } = Select;

export default function FormVatTu(props) {
  const {
    form1,
    detailTableData,
    vatTuChinh,
    onFinishVatTu,
    dataChungLoai,
    handleChangeVatTuChinh,
    handleChangeMany,
    many,
    // dataLoaiChiPhi,
    loading,
    t,
  } = props;
  return (
    <FormComponent
      autoComplete="off"
      form={form1}
      name="form-vat-tu"
      key={1}
      initialValues={{
        ...detailTableData,
        chungLoai: detailTableData.chungLoai.toString(),
      }}
      layout="vertical"
      onFinish={onFinishVatTu}
      scrollToFirstError
    >
      <Row gutter={24}>
        <Col span={24} md={12}>
          <Form.Item label="Mã vật tư" name="maVatTu">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            label="Tên vật tư"
            name="tenVatTu"
            className="required-field"
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            label="Đơn vị tính"
            name="donViTinh"
            className="required-field"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            label="Chủng loại"
            name="chungLoai"
            className="required-field"
          >
            <Select>
              {dataChungLoai.map((item, index) => (
                <Option value={item.value.toString()} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={4} style={{ display: "flex" }}>
          <Form.Item
            name="isVatTuChinh"
            style={{
              marginBottom: 0,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox onChange={handleChangeVatTuChinh} checked={vatTuChinh}>
              Vật tư chính
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={24} md={20} style={{ display: "flex" }}>
          <Form.Item
            name="loaiHinhThucThiCong"
            onChange={handleChangeMany}
            style={{
              marginBottom: 0,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox disabled checked={many} />
            <span style={{ marginLeft: "8px" }}>
              Là vật tư nhiều mức đơn giá dịch vụ ứng với các hình thức thi công
            </span>
          </Form.Item>
        </Col>
        {/* <Col span={24} md={12}>
          <Form.Item name="loaiChiPhiGianTiepId" label="Loại chi phí gián tiếp">
            <Select>
              {dataLoaiChiPhi && dataLoaiChiPhi.length > 0
                ? dataLoaiChiPhi.map((item, index) => (
                    <Option value={item.loaiChiPhiId} key={index}>
                      {item.tenLoaiChiPhi}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col> */}
      </Row>
      <Row gutter={24} justify="center" style={{ marginTop: "12px" }}>
        <Col span={24} style={{ textAlign: "right", marginBottom: "12px" }}>
          <Button
            key={1}
            type="primary"
            className="button-primary"
            htmlType="submit"
            loading={loading}
          >
            {t("button.cap_nhat")}
          </Button>
        </Col>
      </Row>
    </FormComponent>
  );
}
