import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  Select,
  // Input,
  AutoComplete,
} from "antd";
import FormComponent from "@components/Form";
import { Container } from "./css-styled";
import LoadingComponent from "@components/Loading";
import { Fragment, useCallback } from "react";
import DatePickerComponent from "@components/DatePicker";
import moment from "moment";
import { FORMAT_DATE, FORMAT_ENGLISH } from "@utils/constants";

const { Option } = Select;

export default function FormPhanCong(props) {
  const {
    visible,
    closeModal,
    form,
    onFinish,
    filterUnit,
    loading,
    dataTreoThao,
    dataNiemPhong,
    // valueCot1Row1,
    // valueCot1Row2,
    // valueCot1Row3,
    // valueCot2Row1,
    // valueCot2Row2,
    // valueCot2Row3,
    // isDisCot1Row1,
    // isDisCot1Row2,
    // isDisCot1Row3,
    // isDisCot2Row1,
    // isDisCot2Row2,
    // isDisCot2Row3,
    // handleChangeInput,
    // onFocusInput,
    // renderPopupSearch,
    dataNVLog,
    disabledDate,
  } = props;

  const onChange = useCallback(
    (value, fieldSelect, fieldChange, columns) => {
      const dataFilter = dataNVLog.filter((el) => el.id === value);
      if (dataFilter.length > 0) {
        if (columns === "cot1") {
          form.setFieldsValue({
            [`${fieldSelect}`]: dataFilter[0].tenNhanVien,
            [`${fieldChange}`]: dataFilter[0].maNhanVien,
          });
        } else {
          form.setFieldsValue({
            [`${fieldSelect}`]: dataFilter[0].maNhanVien,
            [`${fieldChange}`]: dataFilter[0].tenNhanVien,
          });
        }
      }
    },
    [dataNVLog, form]
  );
  const renderHoTro = useCallback(() => {
    return (
      <Fragment>
        <Col span={12}>
          <Form.Item name="hoTenNVHT1" className="required-field">
            <AutoComplete
              allowClear
              placeholder="Điền họ và tên nhân viên"
              filterOption={(inputValue, option) =>
                option.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
              onChange={(value) =>
                onChange(value, "hoTenNVHT1", "maNVHT1", "cot1")
              }
            >
              {dataNVLog.map(({ id, tenNhanVien }) => (
                <Fragment>
                  {tenNhanVien ? <Option key={id}>{tenNhanVien}</Option> : null}
                </Fragment>
              ))}
            </AutoComplete>
            {/* <Input.Group compact> */}
            {/* <Input
              allowClear
              placeholder="Điền họ và tên nhân viên"
              value={valueCot1Row1}
              onChange={(e) => handleChangeInput(e, "cot1", 1)}
              onFocus={() => onFocusInput("cot1", 1, valueCot1Row1)}
              ref={ref1}
            /> */}
            {/* </Input.Group> */}
          </Form.Item>
          {/* {renderPopupSearch(isDisCot1Row1, "hoTen", "cot1", 1)} */}
        </Col>
        <Col span={12}>
          <Form.Item name="maNVHT1" className="required-field">
            <AutoComplete
              allowClear
              placeholder="Điền mã nhân viên"
              filterOption={(inputValue, option) =>
                option.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
              onChange={(value) =>
                onChange(value, "maNVHT1", "hoTenNVHT1", "cot2")
              }
            >
              {dataNVLog.map(({ id, maNhanVien }) => (
                <Fragment>
                  {maNhanVien ? <Option key={id}>{maNhanVien}</Option> : null}
                </Fragment>
              ))}
            </AutoComplete>
          </Form.Item>
          {/* {renderPopupSearch(isDisCot2Row1, "maNhanVien", "cot2", 1)} */}
        </Col>
        <Col span={12}>
          <Form.Item name="hoTenNVHT2" className="required-field">
            <AutoComplete
              allowClear
              placeholder="Điền họ và tên nhân viên"
              filterOption={(inputValue, option) =>
                option.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
              onChange={(value) =>
                onChange(value, "hoTenNVHT2", "maNVHT2", "cot1")
              }
            >
              {dataNVLog.map(({ id, tenNhanVien }) => (
                <Fragment>
                  {tenNhanVien ? <Option key={id}>{tenNhanVien}</Option> : null}
                </Fragment>
              ))}
            </AutoComplete>
            {/* <Input.Group compact>
              <Input
                allowClear
                placeholder="Điền họ và tên nhân viên"
                value={valueCot1Row2}
                onChange={(e) => handleChangeInput(e, "cot1", 2)}
                onFocus={() => onFocusInput("cot1", 2, valueCot1Row2)}
              />
            </Input.Group> */}
          </Form.Item>
          {/* {renderPopupSearch(isDisCot1Row2, "hoTen", "cot1", 2)} */}
        </Col>
        <Col span={12}>
          <Form.Item name="maNVHT2" className="required-field">
            <AutoComplete
              allowClear
              placeholder="Điền mã nhân viên"
              filterOption={(inputValue, option) =>
                option.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
              onChange={(value) =>
                onChange(value, "maNVHT2", "hoTenNVHT2", "cot2")
              }
            >
              {dataNVLog.map(({ id, maNhanVien }) => (
                <Fragment>
                  {maNhanVien ? <Option key={id}>{maNhanVien}</Option> : null}
                </Fragment>
              ))}
            </AutoComplete>
            {/* <Input.Group compact>
              <Input
                allowClear
                placeholder="Điền mã nhân viên"
                value={valueCot2Row2}
                onFocus={() => onFocusInput("cot2", 2, valueCot2Row2)}
                onChange={(e) => handleChangeInput(e, "cot2", 2)}
              />
            </Input.Group> */}
          </Form.Item>
          {/* {renderPopupSearch(isDisCot2Row2, "maNhanVien", "cot2", 2)} */}
        </Col>
        <Col span={12}>
          <Form.Item name="hoTenNVHT3" className="required-field">
            <AutoComplete
              allowClear
              placeholder="Điền họ và tên nhân viên"
              filterOption={(inputValue, option) =>
                option.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
              onChange={(value) =>
                onChange(value, "hoTenNVHT3", "maNVHT3", "cot1")
              }
            >
              {dataNVLog.map(({ id, tenNhanVien }) => (
                <Fragment>
                  {tenNhanVien ? <Option key={id}>{tenNhanVien}</Option> : null}
                </Fragment>
              ))}
            </AutoComplete>
            {/* <Input.Group compact>
              <Input
                allowClear
                placeholder="Điền họ và tên nhân viên"
                value={valueCot1Row3}
                onChange={(e) => handleChangeInput(e, "cot1", 3)}
                onFocus={() => onFocusInput("cot1", 3, valueCot1Row3)}
              />
            </Input.Group> */}
          </Form.Item>
          {/* {renderPopupSearch(isDisCot1Row3, "hoTen", "cot1", 3)} */}
        </Col>
        <Col span={12}>
          <Form.Item name="maNVHT3" className="required-field">
            <AutoComplete
              allowClear
              placeholder="Điền mã nhân viên"
              filterOption={(inputValue, option) =>
                option.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
              onChange={(value) =>
                onChange(value, "maNVHT3", "hoTenNVHT3", "cot2")
              }
            >
              {dataNVLog.map(({ id, maNhanVien }) => (
                <Fragment>
                  {maNhanVien ? <Option key={id}>{maNhanVien}</Option> : null}
                </Fragment>
              ))}
            </AutoComplete>
            {/* <Input.Group compact>
              <Input
                allowClear
                placeholder="Điền mã nhân viên"
                value={valueCot2Row3}
                onChange={(e) => handleChangeInput(e, "cot2", 3)}
                onFocus={() => onFocusInput("cot2", 3, valueCot2Row3)}
              />
            </Input.Group> */}
          </Form.Item>

          {/* {renderPopupSearch(isDisCot2Row3, "maNhanVien", "cot2", 3)} */}
        </Col>
      </Fragment>
    );
  }, [dataNVLog, onChange]);
  return (
    <Modal
      title="Phân công"
      visible={visible}
      onCancel={closeModal}
      footer={null}
    >
      <FormComponent
        form={form}
        name="form-phan-cong"
        onFinish={onFinish}
        initialValues={{
          ngayTreoThao: moment(moment(), FORMAT_DATE),
        }}
        layout="vertical"
        autoComplete="off"
      >
        <LoadingComponent loading={loading}>
          <Container>
            <Row gutter={24} style={{ marginBottom: "16px" }}>
              <Col span={8}>
                <span>Đơn vị quản lý: </span>
              </Col>
              <Col span={16}>
                <span className="font-bold">{filterUnit}</span>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} className="mt-8px">
                <Form.Item
                  label="Nhân viên treo tháo"
                  name="nhanVienTreoThaoId"
                  className="required-field"
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Chọn nhân viên treo tháo"
                  >
                    {dataTreoThao && dataTreoThao.length > 0
                      ? dataTreoThao.map((item, index) => (
                          <Option value={item.id} key={index}>
                            {`${item.tenVaTK}${
                              item.cmisCode ? " - " + item.cmisCode : ""
                            }`}
                          </Option>
                        ))
                      : null}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Nhân viên niêm phong"
                  name="nhanVienNiemPhongId"
                  className="required-field"
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Chọn nhân viên niêm phong"
                  >
                    {dataNiemPhong && dataNiemPhong.length > 0
                      ? dataNiemPhong.map((item, index) => (
                          <Option value={item.id} key={index}>
                            {`${item.tenVaTK}${
                              item.cmisCode ? " - " + item.cmisCode : ""
                            }`}
                          </Option>
                        ))
                      : null}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Ngày treo tháo"
                  name="ngayTreoThao"
                  className="required-field"
                >
                  <DatePickerComponent
                    format={FORMAT_ENGLISH}
                    form={form}
                    formKey={"ngayTreoThao"}
                    notClear={true}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} style={{ padding: "0 12px 8px" }}>
                <span>Hỗ trợ</span>
              </Col>
              {renderHoTro()}
            </Row>
            <Row gutter={24} justify="center">
              <Col span={12} style={{ textAlign: "right" }}>
                <Button
                  key="back"
                  onClick={closeModal}
                  className="button-closed"
                >
                  Đóng lại
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  key="submit"
                  type="primary"
                  className="button-primary"
                  htmlType="submit"
                  form="form-phan-cong"
                >
                  Phân công
                </Button>
              </Col>
            </Row>
          </Container>
        </LoadingComponent>
      </FormComponent>
    </Modal>
  );
}
