import { Button, Collapse, Form, Row, Tabs, Tooltip } from "antd";
import LoadingComponent from "@components/Loading";
import ThongTinHientai from "./thong-tin-hien-tai";
import ThongTinChung from "./thong-tin-chung";
import Congto from "./cong-to";
import Tuti from "./tu-ti";
import { debounce } from "lodash";
import { ButtonXacNhan } from "../../css-styled";

const { Panel } = Collapse;

export default function LapBBLDDuongDay(props) {
  const {
    loading,
    load,
    detail,
    genExtra,
    soCot,
    form,
    handleChangeValueForm,
    onFinish,
    keyTab,
    callback,
    changeTab1,
    changeTab2,
    changeTab3,
    dataLyDo,
    getDataLyDo,
    setIsResetIsTUTI,
    setIsResetIsThao,
    isResetIsTUTI,
    defaultCTTreo,
    setSoCot,
    dataDetaiCTTreolUpdate,
    setChangeTab1,
    setChangeTab2,
    setChangeTab3,
    doingChange,
    dataNVNiemPhong,
    dataNVTreoThao,
    isResetIsThao,
    setDataDetaiCTTreolUpdate,
    detailHoSo,
    setCustomTUNew,
    setIsChangedTUMoi,
    setIsChangedTIMoi,
    setCustomTINew,
    showModalInBienBanLapDat,
    handleTypeClick,
    setIsDelCT,
    isDelCT,
    disbledXN,
    dataSBB,
    setChiSoMoiMax,
    setNgay,
    ngay,
  } = props;
  return (
    <LoadingComponent loading={loading || load}>
      <Collapse defaultActiveKey={["1"]}>
        <Panel header="Thông tin công tơ hiện tại" key="1" extra={genExtra()}>
          <ThongTinHientai
            detail={detail?.thongTinHienTai}
            soCot={soCot}
            maDiemDo={detail?.thongTinChung?.maDiemDo}
          />
        </Panel>
      </Collapse>
      <Form
        form={form}
        name="lap-bb"
        onValuesChange={debounce(handleChangeValueForm, 200)}
        // labelAlign="left"
        onFinish={onFinish}
        initialValues={{
          ...detail,
          ...detail.thongTinChung,
          ...detail.congToThao,
          ...detail.congToTreo,
          ...detail.tuDangTreo,
          ...detail.tiDangTreo,
        }}
        layout="vertical"
        autoComplete="off"
        scrollToFirstError
      >
        <Tabs
          activeKey={keyTab}
          defaultActiveKey="1"
          type="card"
          style={{ padding: "8px" }}
          onChange={callback}
        >
          <Tabs.TabPane
            tab={
              <div>
                <span style={{ color: changeTab1 ? "#ff4d4f" : "#1892FF" }}>
                  Thông tin chung
                </span>
              </div>
            }
            key="1"
          >
            <ThongTinChung
              detail={detail?.thongTinChung}
              dataLyDo={dataLyDo}
              getDataLyDo={getDataLyDo}
              form={form}
              detailCTTreo={detail?.congToTreo}
              detailCTThao={detail?.congToThao}
              setIsResetIsThao={setIsResetIsThao}
              isResetIsTUTI={isResetIsTUTI}
              setIsResetIsTUTI={setIsResetIsTUTI}
              defaultCTTreo={defaultCTTreo}
              setSoCot={setSoCot}
              dataDetaiCTTreolUpdate={dataDetaiCTTreolUpdate}
              setChangeTab1={setChangeTab1}
              doingChange={doingChange}
              dataNVTreoThao={dataNVTreoThao}
              dataNVNiemPhong={dataNVNiemPhong}
              dataSBB={dataSBB}
              setNgay={setNgay}
              ngay={ngay}
              detailHoSo={detailHoSo}
            />
            {/* </Row> */}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <div>
                <span style={{ color: changeTab2 ? "#ff4d4f" : "#1892FF" }}>
                  Công tơ
                </span>
              </div>
            }
            key="2"
          >
            <Congto
              detailCTThao={detail?.congToThao}
              detailCTTreo={detail?.congToTreo}
              thongTinHT={detail?.thongTinHienTai}
              form={form}
              thongTinChung={detail?.thongTinChung}
              thongTinHienTai={detail?.thongTinHienTai}
              isResetIsThao={isResetIsThao}
              setIsResetIsThao={setIsResetIsThao}
              setIsResetIsTUTI={setIsResetIsTUTI}
              defaultCTTreo={defaultCTTreo}
              setDataDetaiCTTreolUpdate={setDataDetaiCTTreolUpdate}
              setChangeTab2={setChangeTab2}
              detailHoSo={detailHoSo}
              setIsDelCT={setIsDelCT}
              isDelCT={isDelCT}
              isTreoThao={detail.isTreoThao}
              isCapDien={detail.isCapDien}
              setChiSoMoiMax={setChiSoMoiMax}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <div>
                <Tooltip
                  title={
                    detail?.thongTinChung.soPha === 1
                      ? "TI/TU chỉ áp dụng cho công tơ 3 pha !"
                      : ""
                  }
                >
                  <span
                    style={{
                      color: changeTab3
                        ? "#ff4d4f"
                        : detail?.thongTinChung.soPha === 1
                        ? ""
                        : "#1892FF",
                    }}
                  >
                    TU / TI
                  </span>
                </Tooltip>
              </div>
            }
            key="3"
            disabled={detail?.thongTinChung.soPha === 1}
          >
            <Tuti
              detailTIDangTreo={detail.tiDangTreo}
              detailTITreoMoi={detail.tiTreoMoi}
              detailTUDangTreo={detail?.tuDangTreo}
              detailTUTreoMoi={detail?.tuTreoMoi}
              setIsChangedTUMoi={setIsChangedTUMoi}
              setIsChangedTIMoi={setIsChangedTIMoi}
              form={form}
              soPha={detail?.thongTinChung.soPha}
              setCustomTINew={setCustomTINew}
              setCustomTUNew={setCustomTUNew}
              setChangeTab3={setChangeTab3}
              thongTinChung={detail?.thongTinChung}
              detailHoSo={detailHoSo}
            />
          </Tabs.TabPane>
        </Tabs>
        <Row gutter={24} align="center" style={{ marginTop: "56px" }}>
          <Button
            type="primary"
            htmlType="submit"
            form="lap-bb"
            style={{
              width: "200px",
              height: "52px",
              fontSize: "16px",
              marginRight: "12px",
            }}
            onClick={(e) => handleTypeClick(e, "cap-nhat")}
          >
            Cập nhật
          </Button>
          <ButtonXacNhan
            htmlType="submit"
            form="lap-bb"
            onClick={(e) => handleTypeClick(e, "xac-nhan")}
            disabled={disbledXN}
          >
            Xác nhận chuyển an toàn
          </ButtonXacNhan>
        </Row>
      </Form>
      {showModalInBienBanLapDat}
    </LoadingComponent>
  );
}
