import { closeModal, openModal } from "@state/system-config/reducer";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";
import CapNhatThongTinChiSo from "@modules/treo-thao/common-ver1/cap-nhat-thong-tin-chi-so";

export const handleGetDataChiSo = (
  setLoading,
  loading,
  selectedRowKeys,
  dispatch,
  setFetchList
) => {
  authGetData({
    url:
      Endpoint.GET_DATA_CHI_SO_TREO_THAo +
      "?yeuCauId=" +
      selectedRowKeys.toString(),
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        return dispatch(
          openModal({
            content: (
              <CapNhatThongTinChiSo
                data={res.data}
                loading={loading}
                setFetchList={setFetchList}
              />
            ),
            size: "large",
            title: "Cập nhật chỉ số công tơ",
          })
        );
      }
    },
  });
};

export const handleUpdateChiSo = (
  setLoading,
  payload,
  setFetchList,
  dispatch
) => {
  authPostData({
    url: Endpoint.UPDATE_DATA_CHI_SO_TREO_THAo,
    method: METHOD_POST,
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
        dispatch(closeModal());
      }
    },
  });
};
