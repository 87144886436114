// Cấp điện tổng

const cd = {
  a: "a",
};

const a0 = {
  a0: "a0",
};
///////////// GIAO DỊCH VIÊN ///////////////

const a1 = {
  a1: "a1",
};
// Tiếp nhận phân công

const a11 = {
  a11: "a11",
  a11v: "a11v",
  a11_sua: "a11u",
  a11_phan_cong: "a11p",
  a11_xn_chuyen_ap_gia: "a11x",
  a11_tai_anh_hskh: "a11t",
  a11_chuyen_don_vi: "a11c",
  a11_in_hs_cmis: "a11i",
  a11_in_hs_web: "a11e",
  a11_xoa_hs: "a11d",
};
// Hồ sơ đã phân công

const a12 = {
  a12: "a12",
  a12v: "a12v",
  a12_sua: "a12u",
  a12_phan_cong: "a12p",
  a12_xn_chuyen_ap_gia: "a12x",
  a12_tai_anh_hskh: "a12t",
  a12_chuyen_don_vi: "a12c",
  a12_in_hs_cmis: "a12i",
  a12_in_hs_web: "a12e",
};
// Quản lý hồ sơ có trở ngại

const a13 = {
  a13: "a13",
  a13v: "a13v",
  a13_phan_cong_lai: "a13p",
  a13_khao_sat_lai: "a13k",
  a13_chuyen_don_vi: "a13c",
  a13_huy: "a13h",
  a13_in_hs_cmis: "a13i",
  a13_in_hs_web: "a13e",
};
///////////// ÁP GIÁ ///////////////

const a2 = {
  a2: "a2",
};
// Áp giá hồ sơ

const a21 = {
  a21: "a21",
  a21v: "a21v",
  a21_ap_gia_hs: "a21a",
  a21_tai_anh_hskh: "a21t",
  a21_in_hs_cmis: "a21i",
  a21_in_hs_web: "a21e",
  a21tl: "a21tl",
};
///////////// NHÂN VIÊN KHẢO SÁT ///////////////

const a3 = {
  a3: "a3",
  a3v: "a3v",
};
// Hồ sơ được phân công

const a31 = {
  a31: "a31",
  a31v: "a31v",
};
// Hồ sơ đang khảo sát

const a32 = {
  a32: "a32",
  a32v: "a32v",
  a32_lam_moi_hs: "a32l",
};
// Lập phương án

const a33 = {
  a33: "a33",
  a33v: "a33v",
  a33_tai_anh_hskh: "a33t",
  a33_lap_phuong_an: "a33l",
  a33_xn_chuyen_doi_truong: "a33x",
  a33_chuyen_ap_gia: "a33c",
  a33_tro_ngai_khao_sat: "a33tr",
  a33_in_hs_cmis: "a33i",
  a33_in_hs_web: "a33e",
};
//Hồ sơ trở ngại

const a34 = {
  a34: "a34",
  a34v: "a34v",
  a34_khao_sat_lai: "a34k",
  a34_in_hs_cmis: "a34i",
  a34_in_hs_web: "a34e",
};
///////////// ĐỘI TRƯỞNG ///////////////

const a4 = {
  a4: "a4",
};
// Duyệt hồ sơ

const a41 = {
  a41: "a41",
  a41v: "a41v",
  a41_tai_anh_hskh: "a41t",
  a41_duyet_hs: "a41d",
  a41_tra_hs: "a41tr",
  a41_in_hs_cmis: "a41i",
  a41_in_hs_web: "a41e",
};
///////////// KIỂM DUYỆT ///////////////

const a5 = {
  a5: "a5",
};
// Duyệt hồ sơ

const a51 = {
  a51: "a51",
  a51v: "a51v",
  a51_tai_anh_hskh: "a51t",
  a51_duyet_hs: "a51d",
  a51_tra_hs: "a51tr",
  a51_in_hs_cmis: "a51i",
  a51_in_hs_web: "a51e",
};
///////////// PHÒNG KINH DOANH ///////////////

const a6 = {
  a6: "a6",
};
// Duyệt hồ sơ

const a61 = {
  a61: "a61",
  a61v: "a61v",
  a61_tai_anh_hs: "a61t",
  a61_duyet_hs: "a61d",
  a61_tra_hs: "a61tr",
  a61_in_hs_cmis: "a61i",
  a61_in_hs_web: "a61e",
};
///////////// LÃNH ĐẠO ///////////////

const a7 = {
  a7: "a7",
};
// Duyệt hồ sơ

const a71 = {
  a71: "a71",
  a71v: "a71v",
  a71_duyet_hs: "a71d",
  a71_tra_hs: "a71t",
  a71_in_hs_cmis: "a71i",
  a71_in_hs_web: "a71e",
};
// Duyệt quyết toán

const a72 = {
  a72: "a72",
  a72v: "a72v",
  a72_ky_duyet_tc: "a72k",
  a72_in_hs_cmis: "a72i",
  a72_in_hs_web: "a72e",
};
///////////// TÀI CHÍNH ///////////////

const a8 = {
  a8: "a8",
};
// Xác nhận thu tiền

const a81 = {
  a81: "a81",
  a81v: "a81v",
  a81_xn_thu_tien: "a81x",
  a81_cap_nhat_tro_ngai: "a81c",
  a81_in_hs_cmis: "a81i",
  a81_in_hs_web: "a81e",
};
// Xác nhận thu tiền NVKS

const a82 = {
  a82: "a82",
  a82v: "a82v",
  a82_xn_thu_tien: "a82x",
};

//đồng bộ thu tiền
const a83 = {
  a83: "a83",
  a83v: "a83v",
  a83_cmis: "a83c",
};

//khôi phục lại tài chính
const a84 = {
  a84: "a84",
  a84v: "a84v",
  a84_khoi_phuc: "a84k",
  a84_in_hs_cmis: "a84i",
  a84_in_hs_web: "a84e",
};

///////////// VẬT TƯ ///////////////

const a9 = {
  a9: "a9",
};
// Xác nhận vật tư

const a91 = {
  a91: "a91",
  a91v: "a91v",
  a91_xn_xuat_vt: "a91x",
  a91_in_hs_cmis: "a91i",
  a91_in_hs_web: "a91e",
  a91ex: "a91ex",
};
///////////// THI CÔNG ///////////////

const a10 = {
  a10: "a10",
  a10v: "a10v",
};
// Xác nhận thi công

const a101 = {
  a101: "a101",
  a101v: "a101v",
  a101_bb_lap_dat: "a101l",
  a101_kq_thi_cong: "a101g",
  a101_cap_nhat_tro_ngai: "a101c",
  a101_in_hs_cmis: "a101i",
  a101_in_hs_web: "a101e",
};
// Khôi phục thi công

const a102 = {
  a102: "a102",
  a102v: "a102v",
  a102_khoi_phuc_tc: "a102k",
  a102_in_hs_cmis: "a102i",
  a102_in_hs_web: "a102e",
};

//lập biên bản lắp đặt đường dây
const a103 = {
  a103: "a103",
  a103v: "a103v",
  a103ld: "a103ld",
  a103xn: "a103xn",
  a103_in_hs_cmis: "a103i",
  a103_in_hs_web: "a103e",
};

//Hồ sơ được phân công
const a104 = {
  a104: "a104",
  a104v: "a104v",
  a104_in_hs_cmis: "a104i",
  a104_in_hs_web: "a104e",
  a104tl: "a104tl",
};

//Hồ sơ đang treo tháo
const a105 = {
  a105: "a105",
  a105v: "a105v",
  a105_lam_moi_hs: "a105l",
  a105_in_hs_cmis: "a105i",
  a105_in_hs_web: "a105e",
};
///////////// NGHIỆM THU ///////////////

const a20 = {
  a20: "a20",
  a20v: "a20v",
};
// Nghiệm thu hồ sơ

const a201 = {
  a201: "a201",
  a201v: "a201v",
  a201_tai_anh_hskh: "a201t",
  a201xn: "a201x",
  a201_tra_doi_tc: "a201tr",
  a201_in_hs_cmis: "a201i",
  a201_in_hs_web: "a201e",
};
///////////// QUYẾT TOÁN ///////////////

const a30 = {
  a30: "a30",
  a30v: "a30v",
};
// Lập quyết toán công ty

const a301 = {
  a301: "a301",
  a301v: "a301v",
  a301_quyet_toan_cty: "a301q",
  a301_in_hs_cmis: "a301i",
  a301_in_hs_web: "a301e",
};
// Lập quyết toán khách hàng

const a302 = {
  a302: "a302",
  a302v: "a302v",
  a302_quyet_toan_kh: "a302q",
  a302_tra_qt_cty: "a302t",
  a302_in_hs_cmis: "a302i",
  a302_in_hs_web: "a302e",
};
// Xác nhận quyết toán

const a303 = {
  a303: "a303",
  a303v: "a303v",
  a303_xn_tc_qt: "a303x",
  a303_in_hs_cmis: "a303i",
  a303_in_hs_web: "a303e",
};
///////////// TIỆN ÍCH ///////////////

const a40 = {
  a40: "a40",
  a40v: "a40v",
};
// Tải ảnh giấy tờ scan

const a401 = {
  a401: "a401",
  a401v: "a401v",
  a401_tai_anh_hskh: "a401t",
  a401_in_hs_cmis: "a401i",
  a401_in_hs_web: "a401e",
};
// Tra cứu hồ sơ

const a402 = {
  a402: "a402",
  a402v: "a402v",
  a402_in_hs_cmis: "a402i",
  a402_in_hs_web: "a402e",
  a402ex: "a402ex",
};
// Đồng bộ yêu cầu CMIS

const a403 = {
  a403: "a403",
  a403v: "a403v",
  a404_dong_bo_cmis: "a403d",
};
// Đồng bộ thời gian

const a404 = {
  a404: "a404",
  a404v: "a404v",
  a404_dong_bo_tg: "a404d",
};
///////////// BÁO CÁO ///////////////

const a50 = {
  a50: "a50",
};
// Báo cáo tổng hợp

const a501 = {
  a501: "a501",
  a501v: "a501v",
  a501_chot: "a501c",
  a501_huy: "a501h",
  a501_chi_tiet_cmis: "a501i",
  a501_chi_tiet: "a501t",
  a501_xuat: "a501x",
  a501_tong_hop: "a501th",
};

// Báo cáo chi tiết

const a502 = {
  a502: "a502",
  a502v: "a502v",
  a502_xuat: "a502x",
};
// Báo cáo danh sách chậm tiến độ
const a503 = {
  a503: "a503",
  a503v: "a503v",
  a503_xuat: "a503x",
};
// Báo cáo tổng hợp số pha

const a504 = {
  a504: "a504",
  a504v: "a504v",
  a504_xuat: "a504x",
};
// Báo cáo tiếp nhận các dịch vụ điện

const a505 = {
  a505: "a505",
  a505v: "a505v",
  a505_xuat: "a505x",
};
// Tạo mới kế hoạch tiếp nhận bán điện

const a506 = {
  a506: "a506",
  a506v: "a506v",
  a506_them: "a506t",
  a506_xuat: "a506x",
};
// Báo cáo kế hoạch tiếp nhận

const a507 = {
  a507: "a507",
  a507v: "a507v",
  a507_xuat: "a507x",
  a507_them: "a507t",
};
// Báo cáo cấp điện 89 đến 160 kVA

const a508 = {
  a508: "a508",
  a508v: "a508v",
  a508_chot: "a508c",
  a508_huy: "a508h",
  a508_xuat: "a508x",
  a508_sua: "a508s",
  a508_chi_tiet: "a508d",
  a508_theo_tram: "a508tr",
  a508_theo_xay_tram: "a508xt",
  a508_theo_nang_xuat: "a508nx",
  a508_theo_chuyen_dung: "a508cd",
};
// Tổng hợp theo hình thức lắp đặt

const a509 = {
  a509: "a509",
  a509v: "a509v",
  a509_xuat: "a509x",
};
// Chi tiết theo hình thức lắp đặt

const a510 = {
  a510: "a510",
  a510v: "a510v",
  a510_xuat: "a510x",
};
// Tổng hợp chi phí quyết toán

const a520 = {
  a520: "a520",
  a520v: "a520v",
  a520_xuat: "a520x",
};
// Chi tiết chi phí quyết toán

const a530 = {
  a530: "a530",
  a530v: "a530v",
  a530_xuat: "a530x",
};
// Báo cáo tổng hợp thông tin khách hàng

const a540 = {
  a540: "a540",
  a540v: "a540v",
  a540_xuat: "a540x",
};
// Báo cáo danh sách khách hàng

const a550 = {
  a550: "a550",
  a550v: "a550v",
  a550_xuat: "a550x",
};
// Báo cáo tình hình phát triển theo lô

const a560 = {
  a560: "a560",
  a560v: "a560v",
  a560_xuat: "a560x",
};

//báo cáo log thao tác

const a570 = {
  a570: "a570",
  a570v: "a570v",
  a570_xuat: "a570x",
};

//báo cáo số lượng vật tư

const a580 = {
  a580: "a580",
  a580v: "a580v",
  a580_xuat: "a580x",
};

//báo cáo chỉ tiêu chất lượng
const a590 = {
  a590: "a590",
  a590v: "a590v",
  a590_xuat: "a590x",
  a590_xuat_chi_tiet: "a590xct",
};

//Tổng hợp trang web cấp điẹn
const a591 = {
  a591: "a591",
  a591v: "a591v",
  a591_xuat: "a591x",
};

//Báo cáo cấp điẹne
const a592 = {
  a592: "a592",
  a592v: "a592v",
  a592_xuat: "a592x",
};

//Báo cáo theo dõi phát triển khách hàng
const a593 = {
  a593: "a593",
  a593v: "a593v",
  a593_xuat: "a593x",
};

//Báo cáo khách hàng phiên 9
const a594 = {
  a594: "a594",
  a594v: "a594v",
  a594_xuat: "a594x",
};

///////////// HỒ SƠ - cấu hình gửi mail (thay thế hồ sơ flow)///////////////

const a60 = {
  a60: "a60",
  a60v: "a60v",
  a60_them: "a60t",
  a60_sua: "a60s",
  a60_xoa: "a60x",
};
// Hồ sơ flow

const a601 = {
  a601: "a601",
  a601v: "a601v",
  a601_them: "a601t",
  a601_sua: "a601s",
  a601_xoa: "a601x",
};

//cấu hình hồ sơ
const a70 = {
  a70: "a70",
  a70_xem: "a70v",
  a70_sua: "a70s",
};

//thời gian làm việc
const a80 = {
  a80: "a80",
  a80_xem: "a80v",
  a80_sua: "a80s",
};

//Ban an toàn
const a90 = {
  a90: "a90",
};
//Ban an toàn - xác nhận an toàn
const a901 = {
  a901: "a901",
  a901_xem: "a901v",
  a901_xn: "a901xn",
  a901_in_hs_cmis: "a901i",
  a901_in_hs_web: "a901e",
  a901tl: "a901tl",
};

export const cap_dien = {
  ...cd,
  ...a1,
  ...a11,
  ...a12,
  ...a13,
  ...a2,
  ...a21,
  ...a3,
  ...a31,
  ...a32,
  ...a33,
  ...a34,
  ...a4,
  ...a41,
  ...a5,
  ...a51,
  ...a6,
  ...a61,
  ...a7,
  ...a71,
  ...a72,
  ...a8,
  ...a81,
  ...a82,
  ...a83,
  ...a84,
  ...a9,
  ...a91,
  ...a10,
  ...a101,
  ...a102,
  ...a103,
  ...a104,
  ...a105,
  ...a20,
  ...a201,
  ...a30,
  ...a301,
  ...a302,
  ...a303,
  ...a40,
  ...a401,
  ...a402,
  ...a403,
  ...a404,
  ...a50,
  ...a501,
  ...a502,
  ...a503,
  ...a504,
  ...a505,
  ...a506,
  ...a507,
  ...a508,
  ...a509,
  ...a510,
  ...a520,
  ...a530,
  ...a540,
  ...a550,
  ...a560,
  ...a570,
  ...a60,
  ...a601,
  ...a70,
  ...a80,
  ...a580,
  ...a590,
  ...a591,
  ...a592,
  ...a593,
  ...a594,
  ...a0,
  ...a90,
  ...a901,
};
