import LoadingComponent from "@components/Loading";
import { Button, Tabs } from "antd";
import ThongTinYeuCau from "./panels/thong-tin-yeu-cau";
import ThongTinCongTo from "./panels/cong-to";
import ThongTinTITU from "./panels/ti-tu";
import ChiSo from "./panels/chi-so";
import { useEffect, useRef, useState } from "react";
import { scrollToTop } from "@utils/function";
import { closeModal } from "@state/system-config/reducer";
import { useDispatch } from "react-redux";
import { ButtonClose, TabContainer, TabsDetailContainer } from "./css-styled";
import { handleListLoaiGT } from "./services";
import AnhHienTruong from "./panels/anh";
import ThacTacNguoiDung from "../thao-tac-tien-trinh";
import PhieuLenhCongTac from "./panels/phieu-lenh";

const { TabPane } = Tabs;

export default function Index(props) {
  const { yeuCauId, detail, loading } = props;
  const ref1 = useRef();
  const dispatch = useDispatch();
  const [, setLoading] = useState(false);
  const [dataLoaiGT, setDataLoaiGT] = useState([]);
  const [firstData, setFistData] = useState("");
  const [activeKey, setActiveKey] = useState("1");
  useEffect(() => {
    scrollToTop(ref1);
    handleListLoaiGT(setLoading, setDataLoaiGT, setFistData);
  }, []);

  const onChangeTabs = () => {};

  useEffect(() => {
    if (detail?.congToThao !== null && detail?.congToTreo !== null) {
      setActiveKey("4");
    } else {
      setActiveKey("1");
    }
  }, [detail?.congToThao, detail?.congToTreo]);

  return (
    <LoadingComponent loading={loading}>
      <div ref={ref1}>
        <TabsDetailContainer defaultActiveKey="1">
          <TabPane tab="Thông tin yêu cầu" key="1">
            <ThongTinYeuCau detail={detail?.thongTinYeuCau} />
            <TabContainer>
              <Tabs defaultActiveKey={activeKey} onChange={onChangeTabs}>
                {detail?.congToThao !== null && detail?.congToTreo !== null ? (
                  <TabPane tab="Công tơ" key="1">
                    <ThongTinCongTo
                      detail={detail}
                      isThaoCongTo={detail?.congToThao.isThaoCongTo}
                      isLapTrinhLaiCongTo={
                        detail?.congToTreo.isLapTrinhLaiCongTo
                      }
                    />
                  </TabPane>
                ) : null}
                {detail?.congToThao !== null &&
                detail?.congToThao.tIs.length === 0 &&
                detail?.congToTreo !== null &&
                detail?.congToTreo.tIs.length === 0 &&
                detail?.congToThao !== null &&
                detail?.congToThao.tUs.length === 0 &&
                detail?.congToTreo !== null &&
                detail?.congToTreo.tUs.length === 0 ? null : (
                  <TabPane tab="TI / TU" key="2">
                    <ThongTinTITU detail={detail} />
                  </TabPane>
                )}
                {detail?.congToThao &&
                detail?.congToThao?.chiSoThaos === null &&
                detail?.congToTreo &&
                detail?.congToTreo?.chiSoTreos === null ? null : (
                  <TabPane tab="Chỉ số" key="3">
                    <ChiSo detail={detail} />
                  </TabPane>
                )}

                <TabPane tab="Ảnh hiện trường" key="4">
                  <AnhHienTruong
                    yeuCauId={yeuCauId}
                    dataLoaiGT={dataLoaiGT}
                    firstDataValue={firstData}
                    setDataLoaiGT={setDataLoaiGT}
                  />
                </TabPane>
                {detail && detail?.coPhieuLenh && (
                  <TabPane tab="Phiếu lệnh công tác" key="5">
                    <PhieuLenhCongTac data={detail?.phieuLenhCongTac} />
                  </TabPane>
                )}
              </Tabs>
            </TabContainer>
          </TabPane>
          <TabPane tab="Thao tác người dùng" key="2">
            <ThacTacNguoiDung dataSource={detail?.logs} />
          </TabPane>
        </TabsDetailContainer>
      </div>
      <ButtonClose>
        <Button onClick={() => dispatch(closeModal())}>Đóng lại</Button>
      </ButtonClose>
    </LoadingComponent>
  );
}
