import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
import { alertMessage422 } from "../service";

export const selectBCWebEVN = [
  {
    label: "1. Tổng hợp về việc đánh giá mức độ hài lòng khách hàng EVN Hà Nội",
    value: Endpoint.BCWEHN_TH_VE_VIEC_DANH_GIA_MUC_DO_HAI_LONG_KH_EVN_HA_NOI,
  },
  {
    label: "2. Chi tiết về việc đánh giá mức độ hài lòng EVN Hà Nội",
    value: Endpoint.BCWEHN_CT_VE_VIEC_DANH_GIA_MUC_DO_HAI_LONG_KH_EVN_HN,
  },
  {
    label: "3. Tổng hợp 17 dịch vụ điện tiếp nhận",
    value: Endpoint.BCWEHN_TH_17_DICH_VU_TIEP_NHAN,
  },
  {
    label:
      "4. Báo cáo thống kê tổng hợp số lượng khách hàng sử dụng App EVN Hà Nội",
    value: Endpoint.BCWEHN_TKTHSLKH_SU_DUNG_APP_EVN_HN,
  },
  {
    label:
      "5. Báo cáo thống kê chi tiết số lượng khách hàng sử dụng App EVN Hà Nội",
    value: Endpoint.BCWEHN_TK_CT_SLKH_SU_DUNG_APP_EVN_HN,
  },
  {
    label: "6. Thống kê người đăng nhập, online hệ thống",
    value: Endpoint.BCWEHN_TK_NGUOI_DANG_NHAP_ONLINE_HE_THONG,
  },
  {
    label: "7. Báo cáo thống kê tổng hợp số lượng khách hàng lấy lại mật khẩu",
    value: Endpoint.TK_TONG_HOP_SO_LUONG_KH_LAY_MK,
  },
  {
    label: "8. Báo cáo thống kê chi tiết số lượng khách hàng lấy lại mật khẩu",
    value: Endpoint.TK_CHI_TIET_SO_LUONG_KH_LAY_MK,
  },
  {
    label:
      "9. Báo cáo tổng hợp kết quả đánh giá khảo sát chất lượng trang web evnhanoi.vn của khách hàng",
    value: Endpoint.TONG_HOP_DANH_GIA_KHAO_SAT,
  },
  {
    label:
      "10. Báo cáo chi tiết kết quả đánh giá khảo sát chất lượng trang web evnhanoi.vn của khách hàng",
    value: Endpoint.CHI_TIET_DANH_GIA_KHAO_SAT,
  },
  {
    label: "11. Báo cáo thống kê số lượng phòng giao dịch,số lượng điểm thu",
    value: Endpoint.TK_SO_LUONG_GIAO_DICH_DIEM_THU,
  },
];

export const exportData = (data, setLoading, setData) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LoaiYeuCau: data.LoaiYeuCau,
    })
  );
  authGetData({
    url: `${data.reportType}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setData(res.data);
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LoaiYeuCau: data.LoaiYeuCau,
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LoaiYeuCau: data.LoaiYeuCau,
    })
  );
  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
