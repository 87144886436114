import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import TableComponent from "@components/TableComponent";
import { authDeleteData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { ConfigProvider, Tooltip } from "antd";
import vn from "antd/es/locale-provider/vi_VN";
import TrashIcon from "@assets/icon/TrashIcon";
import { handlePagination } from "@utils/function";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  quanTriNguoiDungSelector,
  getPermissionDataAssignedForUser,
} from "@state/quan-tri-he-thong/quan-ly-nguoi-dung/reducer";
import { useSelector, useDispatch } from "react-redux";
export default function PermissionAssignedTable({ userId }) {
  const dispatch = useDispatch();
  const { permissionAssignedUserList } = useSelector(quanTriNguoiDungSelector);
  const { dataSource, totalCount } = permissionAssignedUserList;
  const [loading, setLoading] = useState(false);

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });
  useEffect(() => {
    setLoading(permissionAssignedUserList.loading);
    setFilterConditions(permissionAssignedUserList.filterConditions);
  }, [permissionAssignedUserList]);
  const getRoleByUser = useCallback(() => {
    if (userId) {
      dispatch(getPermissionDataAssignedForUser({ filterConditions, userId }));
    }
  }, [filterConditions, userId, dispatch]);
  useEffect(() => {
    getRoleByUser();
  }, [getRoleByUser]);

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const handleDeleteRoleAssignedForUser = useCallback(
    (id) => {
      if (userId) {
        authDeleteData({
          url: `${Endpoint.USER}/delete-user-role?userId=${userId}&id=${id}`,
          setLoading,
          onSuccess: () => {
            getRoleByUser();
          },
        });
      }
    },
    [getRoleByUser, userId]
  );

  const columns = [
    {
      title: "Tên quyền",
      dataIndex: "roleName",
    },
    {
      title: "Diễn giải",
      dataIndex: "description",
    },
    {
      title: "Tác vụ",
      fixed: "right",
      render: (row) => (
        <div className="action-table-column">
          <Tooltip title={"Xóa"}>
            <div
              className="action-icon"
              onClick={() => handleDeleteRoleAssignedForUser(row.id)}
            >
              <TrashIcon />
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];
  return (
    <Container>
      <ConfigProvider locale={vn}>
        <TableComponent
          header={"Danh sách quyền đã đăng ký"}
          dataSource={userId ? dataSource : []}
          columns={columns}
          totalData={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
          disableGetQueryParams={true}
        />
      </ConfigProvider>
    </Container>
  );
}
const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
`;
