import { BASE_API_URL_DANH_MUC } from "@utils/constants";

export const APISoGhiChiSo = {
  LIST_SO_GHI_CHI_SO: `${BASE_API_URL_DANH_MUC}/so-ghi-chi-so/list`,
  SYNC_SO_GHI_CHI_SO: `${BASE_API_URL_DANH_MUC}/so-ghi-chi-so/sync`,
  GET_SO_GHI_CHI_SO: `${BASE_API_URL_DANH_MUC}/so-ghi-chi-so/get`,
  CREATE_SO_GHI_CHI_SO: `${BASE_API_URL_DANH_MUC}/so-ghi-chi-so/create`,
  UPDATE_SO_GHI_CHI_SO: `${BASE_API_URL_DANH_MUC}/so-ghi-chi-so/update`,
  REMOVE_SO_GHI_CHI_SO: `${BASE_API_URL_DANH_MUC}/so-ghi-chi-so/delete`,
};
