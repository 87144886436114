import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData } from "@utils/request";

//get list data
export const handleListData = (
  setLoading,
  filterConditions,
  setDataDiaChinh,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.LIST_DIA_CHINH}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDiaChinh(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataDiaChinh([]);
    },
  });
};

// //create data
// const handleCreateData = (data, setLoading, closeModal, getListData, form) => {
//   authPostData({
//     url: Endpoint.CREATE_CAU_HINH_DONG_BO,
//     method: METHOD_POST,
//     payload: data,
//     setLoading,
//     onSuccess: (res) => {
//       if (res.statusCode === STATUSCODE_200 && res.data) {
//         closeModal();
//         getListData();
//       } else {
//         getErrorMessage(res, form);
//       }
//     },
//   });
// };

// //update data
// const handleUpdateData = (data, setLoading, closeModal, getListData, form) => {
//   authPostData({
//     url: Endpoint.UPDATE_CAU_HINH_DONG_BO,
//     method: METHOD_PUT,
//     payload: data,
//     setLoading,
//     onSuccess: (res) => {
//       if (res.statusCode === STATUSCODE_200 && res.data) {
//         getListData();
//         closeModal();
//       } else {
//         getErrorMessage(res, form);
//       }
//     },
//   });
// };

// //remove data
// export const handleRemoveData = (ids, setLoading, getListData, t) => {
//   authDeleteData({
//     url: Endpoint.REMOVE_CAU_HINH_DONG_BO + "?Ids=" + ids,
//     setLoading,
//     onSuccess: (res) => {
//       if (res.statusCode === STATUSCODE_200 && res.data) getListData();
//       else alertMessage("error", t("modal.thong_bao"), res.message);
//     },
//   });
// };

// //submit form
// export const handleSubmitForm = (
//   values,
//   detaiDataTable,
//   setLoading,
//   closeModal,
//   getListData,
//   form,
//   tuNgay,
//   denNgay
// ) => {
//   const data = {
//     ...values,
//   };

//   const from = moment(values.tuNgay ? values.tuNgay : tuNgay);
//   data.tuNgay = formatYYYYMMDDString(from.year(), from.month(), from.date());

//   const to = moment(values.denNgay ? values.denNgay : denNgay);
//   data.denNgay = formatYYYYMMDDString(to.year(), to.month(), to.date());

//   if (detaiDataTable.id) {
//     data.id = detaiDataTable.id;
//   }

//   handleCreateUpdate(data, setLoading, closeModal, getListData, form);
// };

// // create/update dữ liệu
// const handleCreateUpdate = (
//   data,
//   setLoading,
//   closeModal,
//   getListData,
//   form
// ) => {
//   if (data.id) {
//     //sửa dữ liệu

//     handleUpdateData(data, setLoading, closeModal, getListData, form);
//   } else {
//     //tạo dữ liệu

//     handleCreateData(data, setLoading, closeModal, getListData, form);
//   }
// };
