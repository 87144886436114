import DatePickerComponent from "@components/DatePicker";
import { Button, Col, Form, Input, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LoadingComponent from "@components/Loading";
import { XacNhanKinhDoanh, getChiTietYeuCau } from "../services";
function ThongTinYeuCauTab({
  detail,
  setData,
  closeModal,
  setListLoading,
  filterConditions,
  setTotal,
  location,
}) {
  const [dataConfirm, setDataConFirm] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingConFirm, setLoadingConFirm] = useState(false);
  const dispatch = useDispatch();
  const { id } = detail;
  useEffect(() => {
    getChiTietYeuCau(setLoadingConFirm, id, setDataConFirm);
  }, [id]);

  useEffect(() => {
    form.setFieldsValue({
      maKhachHang: dataConfirm?.maKhachHang,
      tenKhachHang: dataConfirm?.tenKhachHang,
      dienThoaiKhachHang: dataConfirm?.dienThoaiKhachHang,
      diaChiKhachHang: dataConfirm?.diaChiKhachHang,
      phienGCS: dataConfirm?.phienGCS,
      maTramCMIS: dataConfirm?.maTramCMIS,
      tenTramPP: dataConfirm?.tenTramPP,
      maTramPP: dataConfirm?.maTramPP,

      toDoiKH: dataConfirm?.toDoiKH,
      maYeuCau: dataConfirm?.maYeuCau,
      loaiYeuCau: dataConfirm?.loaiYeuCau,
      trangThaiYeuCau: dataConfirm?.trangThaiYeuCau,
      tenNguoiYeuCau: dataConfirm?.nguoiYeuCau,
      dienThoaiNguoiYeuCau: dataConfirm?.dienThoaiNguoiYeuCau,
      soLanGoi: dataConfirm?.soLanGoi,
      diaChiNguoiYeuCau: dataConfirm?.diaChiNguoiYeuCau,
      ngayYeuCau: moment(new Date(dataConfirm?.ngayYeuCau)),
      ngayTiepNhan: dataConfirm?.ngayTiepNhan,
      kenhTiepNhan: dataConfirm?.kenhTiepNhan,
      nguoiTao: dataConfirm?.nguoiTao,
      nguoiTiepNhan: dataConfirm?.nguoiTiepNhan,
      noiDungYeuCau: dataConfirm?.noiDungYeuCau,
      diaChiKH: dataConfirm?.diaChiKhachHang,
    });
  }, [dataConfirm, form]);

  const handleSubmit = (value) => {
    const payload = {
      id: [detail.id],
      ketQuaXuLy: value.KetQuaXuLy,
    };
    XacNhanKinhDoanh(
      setLoading,
      payload,
      setData,
      closeModal,
      dispatch,
      setListLoading,
      filterConditions,
      setTotal,
      location
    );
  };

  return (
    <LoadingComponent loading={loadingConFirm}>
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Mã khách hàng" name="maKhachHang">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Tên khách hàng" name="tenKhachHang">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Điện thoại khách hàng" name="dienThoaiKhachHang">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item label="Địa chỉ khách hàng" name="diaChiKhachHang">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Phiên GCS" name="phienGCS">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Mã trạm CMIS" name="maTramCMIS">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Tên trạm PP" name="tenTramPP">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Mã trạm PP" name="maTramPP">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Tổ/đội KH" name="toDoiKH">
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Mã tin" name="maYeuCau">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Loại yêu cầu" name="loaiYeuCau">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Trạng thái yêu cầu" name="trangThaiYeuCau">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Tên người yêu cầu" name="tenNguoiYeuCau">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Điện thoại" name="dienThoaiNguoiYeuCau">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Số lần gọi" name="soLanGoi">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item label="Địa chỉ người Y/C" name="diaChiNguoiYeuCau">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Ngày yêu cầu" name="ngayYeuCau">
              <DatePickerComponent
                notClear
                formKey="NgayYeuCau"
                form={form}
                inputReadOnly={true}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Ngày tiếp nhận" name="ngayTiepNhan">
              <DatePickerComponent
                notClear
                formKey="NgayTiepNhan"
                form={form}
                inputReadOnly={true}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Kênh tiếp nhận" name="kenhTiepNhan">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Người tạo" name="nguoiTao">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Người tiếp nhận" name="nguoiTiepNhan">
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Nội dung yêu cầu" name="noiDungYeuCau">
              <Input.TextArea row={4} disabled={true} />
            </Form.Item>
          </Col>
          <br />
          <Col span={24}>
            <Form.Item
              label="Kết quả xử lý"
              required
              name="KetQuaXuLy"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập kết quả xử lý!",
                },
              ]}
            >
              <Input.TextArea row={4} />
            </Form.Item>
          </Col>
          <Col span={8} offset={8} style={{ textAlign: "center" }}>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Xác nhận
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </LoadingComponent>
  );
}

export default ThongTinYeuCauTab;
