import { Form, Button, Modal, Row, Col, Select } from "antd";
// import FormComponent from "@components/Form";
import DatePickerComponent from "@components/DatePicker";
import moment from "moment";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import React, { useCallback, useState, } from "react";
import { submitDongBoCmis } from "./services";
const { Option } = Select;

export default function DongBoCMIS(props) {
  const {
    visible,
    closeModal,
    setFetchDataTable,
    handleCloseModalDongBo,
    dataNhanVien,
    filterConditions,
    setIsDB
    // dataChiTietDB
  } = props;
  const [form] = Form.useForm();
  const { user } = useSelector(authSelector);
  const [loading, setLoading] = useState(false);

  const handleDongBo = useCallback((values) => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: "Bạn muốn đồng bộ về CMIS ?",
      onOk() {
        submitDongBoCmis(
          setLoading,
          setFetchDataTable,
          handleCloseModalDongBo,
          moment(values.ngayNhan, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          values.maKho,
          dataNhanVien.find(x => x.value === values.maNhanVienGui).userName,
          filterConditions.MaBienBan,
          setIsDB
          );
      },
      onCancel() { },
      okText: "Đồng ý",
      okButtonProps: { type: "danger" },
      cancelText: "Hủy",
    });
  }, [dataNhanVien, filterConditions.MaBienBan, handleCloseModalDongBo, setFetchDataTable, setIsDB]);

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };
  return (
    <Modal
      title="Đồng bộ về CMIS"
      visible={visible}
      onCancel={closeModal}
      className="modal-dong-bo"
      footer={null}
    >
      <Form
        form={form}
        name="form-dong-bo"
        layout="horizontal"
        onFinish={handleDongBo}
        initialValues={{
          DonViId: user.unitId.toString(),
          maNhanVienGui: dataNhanVien.some(element => element.value === user.id) ? user.id : "",
        }}
      >
        <Row gutter={24}>
          <Col span={6}>
            <span>Ngày nhận:</span><span style={{ color: "red" }}>*</span>
          </Col>
          <Col span={18}>
            <Form.Item name="ngayNhan" label=""
            rules={[
              {
                required: true,
                message: `Ngày nhận không được bỏ trống.`,
              },
            ]}
            >
              <DatePickerComponent
                defaultValue={moment()}
                format={"YYYY-MM-DD"}
                form={form}
                formKey={"ngayNhan"}
                notClear={true}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <span>Kho:</span><span style={{ color: "red" }}>*</span>
          </Col>
          <Col span={18}>
            <Form.Item name="maKho" label=""
            rules={[
              {
                required: true,
                message: `Mã kho không được bỏ trống.`,
              },
            ]}
            >
              <Select>
                <Option value={201}>Kho dữ liệu công tơ quay vòng chưa thực hiện P7.9</Option>
                <Option value={211}>Kho dữ liệu công tơ quay vòng chưa thực hiện P8.9</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <span>Nhân viên nhận:</span><span style={{ color: "red" }}>*</span>
          </Col>
          <Col span={18}>
            <Form.Item name="maNhanVienGui" label=""
            rules={[
              {
                required: true,
                message: `Nhân viên nhận không được bỏ trống.`,
              },
            ]}
            >
              {dataNhanVien && dataNhanVien.length > 0 ?
              <Select defaultValue={dataNhanVien.some(element => element.value === user.id) ? user.id : "" }>
                {
                  dataNhanVien.map((item) => (
                    <Option key={item.value} value={item.value}>
                      {item.name}
                    </Option>
                  ))
                }
              </Select> :
              <Select style={{display: "block"}}></Select>
            }
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} justify="center">
          <Col span={12} style={{ textAlign: "right" }}>
            <Button key="back" onClick={closeModal} className="button-closed">
              Đóng lại
            </Button>
          </Col>
          <Col span={12}>
            <Button
              key="submit"
              type="primary"
              className="button-primary"
              htmlType="submit"
              form="form-dong-bo"
              loading={loading}
              // disabled={dataChiTietDB}
            >
              Đồng bộ
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
