import { Fragment } from "react";
import { Row, Col } from "antd";

export default function Index(props) {
  const { items } = props;

  return (
    <Fragment>
      <Row gutter={24}>
        <Col span={8}>
          <h6 className="fs-12px c-t-label">Loại treo tháo</h6>
          <h5 className="fs-14px font-bold">{items} </h5>
        </Col>
        <Col span={8}>
          <h6 className="fs-12px c-t-label">Người yêu cầu: </h6>
          <h5 className="fs-14px font-bold"> </h5>
        </Col>
        <Col span={8}>
          <h6 className="fs-12px c-t-label">Ngày yêu cầu: </h6>
          <h5 className="fs-14px font-bold"> </h5>
        </Col>
        <Col span={8}></Col>
        <Col span={8}>
          <h6 className="fs-12px c-t-label">Người xuất kho:</h6>
          <h5 className="fs-14px font-bold"> </h5>
        </Col>
        <Col span={8}>
          <h6 className="fs-12px c-t-label">Ngày xuất kho: </h6>
          <h5 className="fs-14px font-bold"> </h5>
        </Col>
      </Row>
    </Fragment>
  );
}
