import { ENUM_DS_BANGKE } from "@utils/constants";
import { Fragment, useState } from "react";
import Table from "../common/ky-duyet-bang-ke";

export default function Index() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [fetchList, setFetchList] = useState(false);
  const [isDisableAction, setIsDisableAction] = useState(false);

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
    getCheckboxProps: (record) => ({
      disabled: record.isDisable === true,
    }),
  };

  return (
    <Fragment>
      <Table
        typeDS={ENUM_DS_BANGKE.PHUCTRA_BPPT}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        rowSelection={isDisableAction ? false : rowSelection}
        selectedRowKeys={selectedRowKeys}
        header={"Bảng kê ( Danh sách bảng kê - Ký duyệt bảng bảng kê)"}
        setSelectedRowKeys={setSelectedRowKeys}
        setIsDisableAction={setIsDisableAction}
        isDisableAction={isDisableAction}
      />
    </Fragment>
  );
}
