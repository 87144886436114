import { Table, Button, ConfigProvider } from "antd";
import vn from "antd/es/locale-provider/vi_VN";
// import { Scrollbars } from "react-custom-scrollbars";
import { TableWrapper } from "@modules/cap-dien/nhan-vien-khao-sat/css-styled";
import { ENUM_MODE_PHUONGANCAPDIEN } from "@utils/constants";

export default function FormSuDung(props) {
  const { handleOpenModal, columns, countTotals, dataTable, mode } = props;
  const scroll = {
    width: "100%",
    height: 340,
  };

  return (
    <div className="table mt-24px">
      {mode !== ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
        <Button
          type="primary"
          size="small"
          style={{ float: "right", marginBottom: 10 }}
          onClick={handleOpenModal}
        >
          Tạo mới
        </Button>
      ) : null}

      <TableWrapper>
        <ConfigProvider locale={vn}>
          <div className="table-fixed">
            <Table
              columns={columns}
              summary={countTotals}
              dataSource={dataTable}
              pagination={false}
              rowKey="id"
              scroll={{ y: scroll.height, x: "1000px" }}
              bordered
              // components={dataTable.length > 0 ? components : null}
            />
          </div>
        </ConfigProvider>
      </TableWrapper>
    </div>
  );
}
