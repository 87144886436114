import { DeleteOutlined } from "@ant-design/icons";
import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import { Button, Checkbox, Col, Divider, Form, Input, Row, Select, Spin } from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { listOptionBCKDKHmoi } from "./constant";
import { ModalTaoMoi } from "./modal-tao-moi";

import { getListKimChi, handleDownload, SelectionDanhSachNhanVien, xoaSuDungKimChiNP } from "./service";
export default function BaoCaoCTKDDVKHMoi({ optionType, initialReportType, titlePage }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);
  const [maDonVi, setMaDonVi] = useState(user.user.unitCode);
  const [visibleTaoMoi, setVisibleTaoMoi] = useState(false);
  const [baocao, setBaocao] = useState(initialReportType || Endpoint.BCCTKD_DSKH_LAP_DAT_MAT_TROI_AP_MAI);
  const [data, setData] = useState([]);

  const handleXoaSuDungKimChiNP = (record) => {
    const urlXoaSDKimChiNP = Endpoint.BCCTKD_XOA_SU_DUNG_KIM_CHI_NIEM_PHONG;
    const payload = {
      pma_dviqly: record.MA_DVIQLY,
      pma_nv_sd: record.MA_NVIEN_SD,
      pid_kimchi: record.ID_KIMCHI,
      pngay_giaonhan: moment(record.NGAY_GIAONHAN).format("DD/MM/YYYY"),
    };
    xoaSuDungKimChiNP(payload, setLoading, getListDanhSachKimChiNP, urlXoaSDKimChiNP);
  };

  const getListDanhSachKimChiNP = () => {
    getListKimChi(form.getFieldsValue(), setLoading, setData, Endpoint.BCCTKD_DS_SU_DUNG_KIM_CHI_NIEM_PHONG);
  };
  const columns = [
    {
      title: "Đội",
      dataIndex: "MA_DOI",
      key: "MA_DOI",
      align: "center",
      width: "5%",
    },
    {
      title: "Nhân viên sử dụng",
      dataIndex: "TEN_NVIEN_SD",
      key: "TEN_NVIEN_SD",
    },
    {
      title: "Nhân viên giao nhận",
      dataIndex: "TEN_NVIEN_GD",
      key: "TEN_NVIEN_GD",
      align: "center",
    },
    {
      title: "Ngày giao nhận",
      dataIndex: "NGAY_GIAONHAN",
      key: "NGAY_GIAONHAN",
      align: "center",
      render: (value) => {
        return value ? moment(value).format("DD/MM/YYYY") : "";
      },
    },
    {
      title: "Mã hiệu kìm niêm phong",
      dataIndex: "MA_KIM",
      key: "MA_KIM",
      align: "center",
    },
    {
      title: "Mục đích kẹp",
      dataIndex: "MA_LOAI",
      key: "MA_LOAI",
      align: "center",
    },
    {
      title: "Cấp mới (Viên)",
      dataIndex: "CAPMOI_VIEN",
      key: "CAPMOI_VIEN",
      align: "center",
    },
    {
      title: "Cấp mới (Dây)",
      dataIndex: "CAPMOI_DAY",
      key: "CAPMOI_DAY",
      align: "center",
    },
    {
      title: "Kẹp hỏng (Viên)",
      dataIndex: "HONG_VIEN",
      key: "HONG_VIEN",
      align: "center",
    },
    {
      title: "Kẹp hỏng (Dây)",
      dataIndex: "HONG_DAY",
      key: "HONG_DAY",
      align: "center",
    },
    {
      title: "Thu hồi (Viên)",
      dataIndex: "THUHOI_VIEN",
      key: "THUHOI_VIEN",
      align: "center",
    },
    {
      title: "Giờ giao",
      dataIndex: "GIO_GIAO",
      key: "GIO_GIAO",
      align: "center",
    },
    {
      title: "Giờ trả",
      dataIndex: "GIO_TRA",
      key: "GIO_TRA",
      align: "center",
    },
    {
      title: "Số lượng biên bản",
      dataIndex: "SOLUONG_BIENBAN",
      key: "SOLUONG_BIENBAN",
      align: "center",
    },
    {
      title: "Nội dung",
      dataIndex: "NOIDUNG",
      key: "NOIDUNG",
      align: "center",
    },
    {
      title: "Giải trình",
      dataIndex: "GIAI_TRINH",
      key: "GIAI_TRINH",
      align: "center",
    },
    {
      title: "Thu hồi (Dây)",
      dataIndex: "THUHOI_DAY",
      key: "THUHOI_DAY",
      align: "center",
    },
    {
      title: "#",
      width: "4%",
      align: "center",
      render: (record) => {
        return (
          <Button type="primary" onClick={() => handleXoaSuDungKimChiNP(record)} className="mr-5px">
            <DeleteOutlined />
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    form.setFieldsValue({ MaNhanVien: undefined });
  }, [maDonVi]);

  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };

  const closeModalTaoMoi = () => {
    setVisibleTaoMoi(false);
  };

  const visibleModal = useMemo(
    () =>
      visibleTaoMoi && (
        <ModalTaoMoi
          maDonVi={maDonVi}
          visible={visibleTaoMoi}
          closeModal={closeModalTaoMoi}
          getListData={getListDanhSachKimChiNP}
        />
      )
  );
  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          {titlePage || <>Báo cáo công tác kinh doanh dịch vụ khách hàng (Mới)</>}
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              initialValues={{
                reportType: initialReportType || Endpoint.BCCTKD_DSKH_LAP_DAT_MAT_TROI_AP_MAI,
                mucdich: "0",
                unitId: user.user.unitCode,
              }}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              colon={false}
            >
              <Form.Item name="reportType" label="Loại báo cáo" labelAlign="left" required>
                <Select
                  placeholder="Tất cả"
                  options={optionType || listOptionBCKDKHmoi}
                  onChange={() => setBaocao(form.getFieldValue("reportType"))}
                ></Select>
              </Form.Item>

              <Form.Item name="unitId" label="Tên đơn vị" labelAlign="left" required>
                <Selection url={Endpoint.GET_TEAM_LIST_CODE} formKey="unitId" form={form} setValue={setMaDonVi} />
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") === Endpoint.BCCTKD_QLSD_KIM_CHI_THEO_MA_KIM ||
                  getFieldValue("reportType") === Endpoint.BCCTKD_QLSD_KIM_CHI_THEO_MA_KIM_LUY_KE ? (
                    <Form.Item name="MaNhanVien" label="Nhân viên" labelAlign="left" required>
                      <SelectionDanhSachNhanVien
                        formKey="MaNhanVien"
                        form={form}
                        placeholder="Chọn nhân viên"
                        url={`${Endpoint.BCKD_DANH_SACH_NHAN_VIEN}?MaDonVi=${maDonVi}`}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") === Endpoint.BCCTKD_CT_QL_KIM_CHI_NIEM_PHONG_TONG_HOP ||
                  getFieldValue("reportType") === Endpoint.BCCTKD_QLSD_KIM_CHI_THEO_MA_KIM ||
                  getFieldValue("reportType") === Endpoint.BCCTKD_QLSD_KIM_CHI_THEO_MA_KIM_LUY_KE ? (
                    <Form.Item name="reportDate" label="Tháng/Năm" labelAlign="left" required>
                      <DatePickerComponent picker="month" formKey="reportDate" form={form} />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") === Endpoint.BCCTKD_DSKH_LAP_DAT_MAT_TROI_AP_MAI ||
                  getFieldValue("reportType") === Endpoint.BCCTKD_CHI_TIET_KH_LAP_DAT_MAT_TROI_AP_MAI ? (
                    <>
                      <Form.Item name="TuNgay" label="Từ ngày" labelAlign="left" required>
                        <DatePickerComponent formKey="TuNgay" form={form} picker="day" format={"YYYY-MM-DD"} />
                      </Form.Item>

                      <Form.Item name="DenNgay" label="Đến ngày" labelAlign="left" required>
                        <DatePickerComponent picker="day" formKey="DenNgay" form={form} format={"YYYY-MM-DD"} />
                      </Form.Item>
                    </>
                  ) : null
                }
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") === Endpoint.BCCTKD_CT_QL_KIM_CHI_NIEM_PHONG_TONG_HOP ||
                  getFieldValue("reportType") === Endpoint.BCCTKD_QLSD_KIM_CHI_THEO_MA_KIM ? (
                    <Form.Item name="mucdich" label="Mục đích kẹp chì" labelAlign="left" required>
                      <Select placeholder="Chọn mục đích kẹp chì" formKey="mucdich">
                        <Option value="0">Tất cả</Option>
                        <Option value="1">Cấp mới</Option>
                        <Option value="2">Định kỳ</Option>
                        <Option value="3">Cháy hỏng</Option>
                      </Select>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              <Row>
                <Col span={4}></Col>
                <Col>
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
                    //
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("reportType") === Endpoint.BCCTKD_CT_QL_KIM_CHI_NIEM_PHONG_TONG_HOP ||
                      getFieldValue("reportType") === Endpoint.BCCTKD_QLSD_KIM_CHI_THEO_MA_KIM ? (
                        <Form.Item name="accumulated" valuePropName="checked" noStyle>
                          <Checkbox className="mr-10px">Lũy kế</Checkbox>
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>
                  <Button type="primary" htmlType="submit" className="mr-5px">
                    Xuất báo cáo
                  </Button>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("reportType") === Endpoint.BCCTKD_CT_QL_KIM_CHI_NIEM_PHONG_TONG_HOP ||
                      getFieldValue("reportType") === Endpoint.BCCTKD_QLSD_KIM_CHI_THEO_MA_KIM ? (
                        <>
                          {getFieldValue("reportType") === Endpoint.BCCTKD_QLSD_KIM_CHI_THEO_MA_KIM && (
                            <Button type="primary" onClick={() => setVisibleTaoMoi(true)} className="mr-5px">
                              Tạo mới
                            </Button>
                          )}
                          <Button type="primary" onClick={getListDanhSachKimChiNP}>
                            Lấy danh sách
                          </Button>
                        </>
                      ) : null
                    }
                  </Form.Item>
                </Col>
              </Row>
            </FormComponent>
            {baocao === Endpoint.BCCTKD_CT_QL_KIM_CHI_NIEM_PHONG_TONG_HOP ||
            baocao === Endpoint.BCCTKD_QLSD_KIM_CHI_THEO_MA_KIM ? (
              <TableComponent header={"Danh sách kìm chì"} columns={columns} dataSource={data} scrollX={2500} />
            ) : null}

            {visibleModal}
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;