import Filter from "../../../common/Filter";
import TableComponent from "@components/TableComponent";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_KY_GCS,
  ENUM_LOAI_BANG_KE,
} from "@utils/constants";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import { Container, TableStyled } from "../../../common/ky-bang-ke/css-styled";
import FilterBkChiSo from "./filter";
import { handleListDataChiSo } from "./services";
import moment from "moment";
import "moment/locale/vi";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import { Form, Button } from "antd";

export default function BkChiSo() {
  const { t } = useTranslation();
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [dataChiSo, setDataChiSo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const location = useLocation();

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    DonViId: user.unitId,
    Ky: ENUM_KY_GCS.KY_1,
    NgayGhi: moment().format("YYYY-MM-DD"),
    LoaiBangKe: ENUM_LOAI_BANG_KE.BKCS,
    orderBy: "maSo"
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu
  const getListDataChiSo = useCallback(() => {
    handleListDataChiSo(
      setLoading,
      location,
      setDataChiSo,
      setTotalCount,
      filterConditions
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getListDataChiSo();
  }, [getListDataChiSo]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListDataChiSo();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //pagination table
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //tìm kiếm dữ liệu
  const handleSearch = useCallback((values) => {
    console.log("Values", values);
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      Ky: ENUM_KY_GCS.KY_1,
      NgayGhi: moment().format("YYYY-MM-DD"),
      LoaiBangKe: ENUM_LOAI_BANG_KE.BKCS,
      orderBy: "maSo"
    });
  }, [user.unitId]);

  const columns = [
    {
      title: t("ky_bang_ke.ma_so"),
      dataIndex: "maSo",
      key: "maSo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
      sorter: true,
    },
    {
      title: t("ky_bang_ke.ten_so"),
      dataIndex: "tenSo",
      key: "tenSo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "14%",
      sorter: true,
    },
    {
      title: t("ky_bang_ke.hinh_thuc_gcs"),
      dataIndex: "hinhThuc",
      key: "hinhThuc",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: t("ky_bang_ke.ky_gcs"),
      dataIndex: "ky",
      key: "ky",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "6%",
      sorter: true,
    },
    {
      title: t("ky_bang_ke.ngay_ghi"),
      dataIndex: "ngayGhi",
      key: "ngayGhi",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: t("ky_bang_ke.thang_nam"),
      dataIndex: "thangName",
      key: "thangName",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: t("ky_bang_ke.to_doi"),
      dataIndex: "toDoi",
      key: "toDoi",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "14%",
    },
    {
      title: t("ky_bang_ke.nhan_vien_gcs"),
      dataIndex: "tenNhanVien",
      key: "tenNhanVien",
      render: (text) => <span>{text}</span>,
      width: "14%",
    },
    {
      title: t("ky_bang_ke.loai_bang_ke"),
      dataIndex: "loaiBangKe",
      key: "loaiBangKe",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: t("ky_bang_ke.tac_vu"),
      dataIndex: "",
      key: "tacVu",
      render: (record) => (
        <div className="action-table-column">
          <Button
            className="action-button"
            style={{ backgroundColor: "#F4A460", borderColor: "#F4A460" }}
            type="primary"
          >
            <a href={record.filePDF} target="_blank" rel="noopener noreferrer">
              Xem
            </a>
          </Button>
        </div>
      ),
    },
  ];

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: "2%",
  };

  return (
    <Fragment>
      <Filter>
        <FilterBkChiSo
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
        />
      </Filter>
      <Container>
        <TableStyled>
          <TableComponent
            header={"Bảng kê chỉ số"}
            dataSource={dataChiSo}
            listFilter={<FilterBkChiSo form={form} />}
            columns={customColumn(columns, filterConditions)}
            totalData={totalCount}
            scrollX={1700}
            loading={loading}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            rowSelection={rowSelection}
          />
        </TableStyled>
      </Container>
    </Fragment>
  );
}
