import { BASE_API_URL_BAO_CAO } from "@utils/constants";

export const APIBCApGiaBanDien = {
  //Khách hàng mua điện sinh hoạt có từ hơn 1 định mức trở lên
  BCAGBD_DS_TOAN_BO_KHACH_HANG: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/khmdshcth1dmtl/danh-sach-toan-bo-kh`,
  BCAGBD_DSKH_QUA_HAN_KIEM_TRA_AP_GIA: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/khmdshcth1dmtl/dskh-qua-han-kiem-tra-ap-gia`,
  BCAGBD_DSKH_DA_KIEM_TRA_AP_GIA: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/khmdshcth1dmtl/dskh-da-kiem-tra-ap-gia`,
  BCAGBD_DSKH_DEN_HAN_KIEM_TRA_AP_GIA: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/khmdshcth1dmtl/dskh-den-han-kiem-tra-ap-gia`,
  BCAGBD_DSKH_CO_THAY_DOI_SAU_KHI_KIEM_TRA_AP_GIA: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/khmdshcth1dmtl/dskh-co-thay-doi-sau-khi-kiem-tra-ap-gia`,

  //Báo cáo giá bán điện theo lộ đường dây
  BCAGBD_BC_GIA_BAN_DIEN_THEO_LO_DUONG_DAY: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/bcgbdtldd/bao-cao-gia-ban-dien-theo-lo-duong-day`,

  //Báo cáo tổng hợp
  BCAGBD_TH_GIA_BAN_DIEN_BINH_QUAN: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/bcth/tong-hop-gia-ban-dien-binh-quan`,
  BCAGBD_TH_CONG_TAC_AP_GIA_BAN_DIEN: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/bcth/thct-ap-gia-ban-dien`,
  BCAGBD_BCKQ_DIEU_TRA_DINH_MUC_CAC_HO_SU_DUNG_DIEN_SINH_HOAT: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/bcth/bckqdtdmch-su-dung-dien-sinh-hoat`,

  //Báo cáo áp giá bán điện B09
  BCAGBD_BC_CONG_TO_TINH_TOI_THOI_DIEM: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/bcagbdb09/bao-cao-cong-to-tinh-toi-thoi-diem`,
  BCAGBD_BCKQ_KT_DINH_MUC_CAC_HO_SD_CO_2_DINH_MUC: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/bcagbdb09/bao-cao-kqkt-dinh-muc-cac-ho-sd-co-2-dinh-muc`,
  BCAGBD_BC_SL_HOP_DONG_MUA_BAN_DIEN_TINH_TOI_THOI_DIEM: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/bcagbdb09/bao-cao-so-luong-hop-dong-mua-ban-dien`,
  BCAGBD_BC_TH_CT_KT_AP_GIA_BAN_DIEN_TINH_TOI_THOI_DIEM: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/bcagbdb09/bao-cao-tong-hop-ktag-ban-dien`,

  //Báo cáo áp giá bán điện B09 mới
  BCAGBD_KH_CHO_THUE_TRO_DA_AP_DINH_MUC_SINH_HOAT_BAC_THANG: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/bcgbdb09bm/khach-hang-cho-thue-tro-da-ap-dinh-muc-gia-sinh-hoat-bac-thang-cho-nguoi-thue-nha-deo`,
  BCAGBD_KH_CHO_THUE_TRO_DA_AP_DINH_MUC_SINH_HOAT_CUA_BAC_3: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/bcgbdb09bm/khach-hang-cho-thue-tro-da-ap-dinh-muc-gia-sinh-hoat-cua-bac-3-cho-nguoi-thue-nha-deo`,
  BCAGBD_TK_DS_DIEM_DO_KIEM_TRA_CO_GIA_BENH_VIEN_TH: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/bcgbdb09bm/thong-ke-sach-diem-do-kiem-tra-co-gia-benh-vien-truong-hoc-cqbv`,
  BCAGBD_TK_DS_DIEM_DO_KIEM_TRA_CO_GIA_HANH_CHINH_SU_NGHIEM: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/bcgbdb09bm/thong-ke-danh-sach-diem-do-kiem-tra-co-gia-hanh-chinh-su-nghiem-chieu-sang-cong-cong-csscc`,
  BCAGBD_TK_DS_DIEM_DO_KIEM_TRA_CO_GIA_SAN_XUAT: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/bcgbdb09bm/thong-ke-danh-sach-diem-do-kiem-tra-co-gia-san-xuat-sxbt`,
  BCAGBD_TK_DS_DIEM_DO_SINH_HOAT_CO_NHIEU_MD_SU_DUNG_DIEN: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/bcgbdb09bm/thong-ke-danh-sach-diem-do-kiem-tra-co-nhieu-muc-dich-du-dien-trong-cung-1-cong-to`,
  BCAGBD_TK_DS_DIEM_DO_SINH_HOAT_KT_CO_SO_DINH_MUC_LON_HON_BANG_2: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/bcgbdb09bm/thong-ke-danh-sach-diem-do-sinh-hoat-tra-co-so-dinh-much-lon-hon-bang2`,
  BCAGBD_KTGS_CONG_TAC_THU_TIEN_DIEN: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/bcgbdb09bm/ktgs-cong-tac-thu-tien-dien-ntn`,
  BCAGBD_CHI_TIET_KTGS_CONG_TAC_THU_TIEN_DIEN: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/bcgbdb09bm/ct-ktgs-cong-tac-thu-td-cua-ntn`,
  //Khách hàng mua điện ngoài mục đích sinh hoạt
  BCAGBD_NMDSH_DANH_MUC_NGANH_NGHE: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/khmdnmdsh/danh-muc-nganh-nghe`,
  BCAGBD_NMDSH_DSKH_CO_THAY_DOI_SAU_KHI_KT_AP_GIA: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/khmdnmdsh/danh-sach-khach-hang-co-thay-doi-sau-khi-kiem-tra-ap-gia`,
  BCAGBD_NMDSH_DSKH_DA_KT_AP_GIA: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/khmdnmdsh/danh-sach-khach-hang-da-kiem-tra-ap-gia`,
  BCAGBD_NMDSH_DSKH_DEN_HAN_KT_AP_GIA: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/khmdnmdsh/danh-sach-khach-hang-den-hen-kiem-tra-ap-gia`,
  BCAGBD_NMDSH_DSKH_QUA_HAN_KT_AP_GIA: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/khmdnmdsh/danh-sach-khach-hang-qua-han-kiem-tra-ap-gia`,
  BCAGBD_NMDSH_DS_TOAN_BO_KHACH_HANG: `${BASE_API_URL_BAO_CAO}/bckd/bcagbd/khmdnmdsh/danh-sach-toan-bo-kh`,
};
