/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useCallback, useLayoutEffect, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Filter from "../../common/Filter";
import { TableStyled, Container } from "./css-styled";
import { Button, Tag, Input, Row, Col, Modal } from "antd";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE, ID_DOES_NOT_EXIST } from "@utils/constants";
import { customColumn, handlePagination, parseParams, removeUndefinedAttribute } from "@utils/function";
import { handleListData, submitLayDuLieuCMIS, handleCheckTraSo, handleTraSo } from "./services";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilterDieuHanh } from "./list-bo-loc";
import CheckPermission from "@components/CheckPermission";
import { ghi_chi_so } from "@permissions/d_gcs";
import { ENUM_GCSTRANGTHAISO, ENUM_KY_GCS } from "@utils/constants";
import TableComponent from "@components/TableComponent";
import moment from "moment";
import { isEmpty } from "lodash";

export default function Index() {
  const { t } = useTranslation();
  const { user } = useSelector(authSelector);
  const [dataLayDuLieuCMIS, setDataLayDuLieuCMIS] = useState([]);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const [tempDataLayDuLieuCMIS, setTempDataLayDuLieuCMIS] = useState([]);
  const [tempTotalCount, setTempTotalCount] = useState(0);
  const [term, setTerm] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    ...parseParams(location.search),
    Ky: ENUM_KY_GCS.KY_1,
    MaTrangThai: ENUM_GCSTRANGTHAISO.DA_LAY_LICH_GCS_TU_CMIS.toString(),
    NgayGhi: moment().format("DD"),
    Thang: moment().format("MM"),
    Nam: moment().format("YYYY"),
    orderBy: "maSoGCS",
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [filterConditions]);

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setSelectedRowKeys([]);
  };
  const changeTerm = (value) => {
    setTerm(value.target.value);
  };
  const handleSearch = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
      MaSo: values.MaSo === undefined ? values.MaSo : values.MaSo.trim(),
      TenSo: values.TenSo === undefined ? values.TenSo : values.TenSo.trim(),
      NgayGhi: !values.Ngay ? values.Ngay : values.Ngay.substring(8, 10),
      Thang: !values.Ngay ? values.Ngay : values.Ngay.substring(5, 7),
      Nam: !values.Ngay ? values.Ngay : values.Ngay.substring(0, 4),
    }));
  };

  const SearchTerm = useCallback(() => {
    if (term.trim().length > 0) {
      let listTerm = [];
      listTerm = tempDataLayDuLieuCMIS.filter(
        (data) =>
          data.maSoGCS.toUpperCase().indexOf(term.trim().toUpperCase()) > -1 ||
          data.tenSoGCS.toUpperCase().indexOf(term.trim().toUpperCase()) > -1
      );
      setDataLayDuLieuCMIS(listTerm);
      setTotalCount(listTerm.length);
    } else {
      setDataLayDuLieuCMIS(tempDataLayDuLieuCMIS);
      setTotalCount(tempTotalCount);
    }
  }, [tempDataLayDuLieuCMIS, tempTotalCount, term]);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      Ky: ENUM_KY_GCS.KY_1,
      MaTrangThai: ENUM_GCSTRANGTHAISO.DA_LAY_LICH_GCS_TU_CMIS.toString(),
      NgayGhi: moment().format("DD"),
      Thang: moment().format("MM"),
      Nam: moment().format("YYYY"),
      orderBy: "maSoGCS",
    });
  }, [user.unitId]);

  //search list
  const getListData = () => {
    handleListData(
      location,
      filterConditions,
      setLoading,
      setDataLayDuLieuCMIS,
      setTempDataLayDuLieuCMIS,
      setTotalCount,
      setTempTotalCount
    );
  };

  useEffect(() => {
    getListData();
  }, [location]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListData();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, location, setFetchDataTable]);

  const columns = [
    {
      title: "Mã sổ",
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
      sorter: true,
      fixed: "left",
    },
    {
      title: "Tên sổ",
      dataIndex: "tenSoGCS",
      key: "tenSoGCS",
      render: (text) => <span>{text}</span>,
      width: "24%",
      sorter: true,
    },
    {
      title: "Kỳ GCS",
      dataIndex: "ky",
      key: "ky",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
      sorter: true,
    },
    {
      title: "Ngày ghi",
      dataIndex: "ngayGhi",
      key: "ngayGhi",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
    },
    {
      title: "Tháng năm",
      dataIndex: "thangNam",
      key: "thangNam",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
    },
    {
      title: "Trạng thái",
      dataIndex: "tenTrangThai",
      key: "tenTrangThai",
      render: (text, record) =>
        record.maTrangThai === ENUM_GCSTRANGTHAISO.DA_LAY_LICH_GCS_TU_CMIS.toString() ? (
          <Tag style={{ display: "block", textAlign: "center" }} color={"#faad14"}>
            {"Chưa lấy"}
          </Tag>
        ) : (
          <Tag style={{ display: "block", textAlign: "center" }} color={"#26c0e9"}>
            {"Đã lấy"}
          </Tag>
        ),
      width: "16%",
    },
    {
      title: "Tác vụ",
      dataIndex: "tacVu",
      key: "tacVu",
      render: (text, record) => (
        <CheckPermission permissionCode={ghi_chi_so.d11ts}>
          <Button
            hidden={record.maTrangThai === ENUM_GCSTRANGTHAISO.DA_LAY_LICH_GCS_TU_CMIS.toString()}
            type="primary"
            style={{ display: "block", margin: "auto" }}
            onClick={() => handleTraSoCMIS(record.id, record.maSo)}
          >
            Hủy nhận sổ GCS
          </Button>
        </CheckPermission>
      ),
    },
  ];

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const handleCheckDisableHuyNhan = () => {
    const listMasoKhacChuaLay = selectedRowKeys.filter(
      (item) => {
        const indexRow = dataLayDuLieuCMIS.findIndex((e) => e.uniqueId === item);
        return indexRow >= 0 && dataLayDuLieuCMIS[indexRow]?.maTrangThai !== ENUM_GCSTRANGTHAISO.DA_LAY_LICH_GCS_TU_CMIS.toString()
      }
    );
    if(!isEmpty(listMasoKhacChuaLay)){
      return false;
    }
    return true;
  }

  const hadleCheckDisableLayDuLieu = () => {
    const listMasoChuaLay = selectedRowKeys.filter(
      (item) => {
        const indexRow = dataLayDuLieuCMIS.findIndex((e) => e.uniqueId === item);
        return indexRow >= 0 && dataLayDuLieuCMIS[indexRow]?.maTrangThai === ENUM_GCSTRANGTHAISO.DA_LAY_LICH_GCS_TU_CMIS.toString()
      }
    );
    
    if(!isEmpty(listMasoChuaLay)){
      return false;
    }
    return true;
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: "3%",
    fixed: "left",
  };

  // Lấy dữ liệu CMIS
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLayDuLieuCMIS = () => {
    //call api
    submitLayDuLieuCMIS(t, setFetchDataTable, selectedRowKeys, setSelectedRowKeys, dataLayDuLieuCMIS);
  };
  // Trả sổ
  const handleTraSoCMIS =  (id) => {
    handleCheckTraSo(id, getListData, t, setSelectedRowKeys);
  };
   
  // Trả sổ
  const handleTraSoCMISAll = useCallback(() => {
    const listRowSelect = dataLayDuLieuCMIS
      .filter((item) => selectedRowKeys.includes(item.uniqueId))
      .filter((item) => item.maTrangThai !== ENUM_GCSTRANGTHAISO.DA_LAY_LICH_GCS_TU_CMIS.toString());
    const listIdSelect = listRowSelect.map((item) => item.id);
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: "Bạn chắc chắn muốn hủy tất cả sổ đã chọn ?",
      onOk() {
        handleTraSo(listIdSelect, getListData, t, setSelectedRowKeys);
      },
      onCancel() {},
      okText: "Đồng ý",
      cancelText: "Hủy",
    });
  }, [getListData, selectedRowKeys, t]);

  return (
    <Fragment>
      <Filter>
        <ListFilterDieuHanh handleSearch={handleSearch} clearFilter={clearFilter} filterConditions={filterConditions} />
      </Filter>
      <Container>
        <Row style={{ marginBottom: "-33px", marginLeft: "10px" }}>
          <Col span={24} md={12}>
            <span style={{ marginTop: "6px" }}>Danh sách sổ GCS: </span>
            <Input
              placeholder="Nhập mã sổ, tên sổ"
              style={{ marginLeft: 20, marginBottom: 35, width: 300 }}
              onChange={changeTerm}
              onPressEnter={() => SearchTerm()}
            />
          </Col>
          <Col span={24} md={12} style={{ position: "absolute", right: 20 }}>
            <CheckPermission permissionCode={ghi_chi_so.d11ld}>
              <Button
                type="primary"
                onClick={handleLayDuLieuCMIS}
                disabled={hadleCheckDisableLayDuLieu()}
                style={{ marginRight: "10px" }}
                loading={loading}
              >
                Lấy dữ liệu từ CMIS
              </Button>
            </CheckPermission>
            <CheckPermission permissionCode={ghi_chi_so.d11ts}>
              <Button type="primary" onClick={handleTraSoCMISAll} disabled={handleCheckDisableHuyNhan()} loading={loading}>
                Hủy nhận tất cả
              </Button>
            </CheckPermission>
          </Col>
        </Row>
        <TableStyled>
          <TableComponent
            header={"Nhận sổ từ CMIS"}
            handleSearch={handleSearch}
            dataSource={dataLayDuLieuCMIS}
            columns={customColumn(columns, filterConditions)}
            totalData={totalCount}
            loading={loading}
            scrollX={1200}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            rowSelection={rowSelection}
            rowKey="uniqueId"
          />
        </TableStyled>
      </Container>
    </Fragment>
  );
}