import { Button } from "antd";
import moment from "moment";

export const TABLE_COLUMNS_THEO_LOAI_YEU_CAU = {
  LOAI_1: [
    {
      title: "STT",
      width: "80px",
      align: "center",
      dataIndex: "STT",
    },
    {
      title: "Mã yêu cầu",
      dataIndex: "maYeuCau",
      width: "80px",
      align: "center",
    },
    {
      title: "Ngày Y/C",
      dataIndex: "ngayYeuCau",
      width: "140px",
      align: "center",
      render: (text, record) => {
        if (record.ngayYeuCau) {
          return <>{moment(record.ngayYeuCau).format("DD/MM/YYYY HH:mm")}</>;
        }
      },
    },
    {
      title: "Ngày tiếp nhận",
      dataIndex: "ngayTiepNhan",
      width: "140px",
      align: "center",
      render: (text, record) => {
        if (record.ngayTiepNhan) {
          return <>{moment(record.ngayTiepNhan).format("DD/MM/YYYY HH:mm")}</>;
        }
      },
    },
    {
      title: "Ngày mất điện",
      dataIndex: "ngayMatDien",
      width: "140px",
      align: "center",
      render: (text, record) => {
        if (record.ngayMatDien) {
          return <>{moment(record.ngayMatDien).format("DD/MM/YYYY HH:mm")}</>;
        }
      },
    },
    {
      title: "Mã KH",
      dataIndex: "maKhachHang",
      width: "100px",
      align: "left",
    },
    {
      title: "Tên KH",
      dataIndex: "tenKhachHang",
      width: "100px",
      align: "left",
    },
    {
      title: "Tổ/Đội KH",
      dataIndex: "toDoiKhachHang",
      width: "80px",
      align: "center",
    },

    {
      title: "Người Y/C",
      dataIndex: "nguoiYeuCau",
      width: "140px",
      align: "left",
    },
    {
      title: "Nội dung Y/C",
      dataIndex: "noiDungYeuCau",
      width: "400px",
      align: "left",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChiKhachHang",
      width: "100px",
      align: "left",
    },
    {
      title: "Điện thoại",
      dataIndex: "dienThoai",
      width: "110px",
      align: "left",
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      width: "110px",
      align: "left",
    },
    {
      title: "Loại yêu cầu",
      dataIndex: "loaiYeuCau",
      width: "140px",
      align: "left",
    },
    {
      title: "Đội",
      dataIndex: "tenDoi",
      width: "100px",
      align: "center",
    },
    {
      title: "Web id",
      dataIndex: "webId",
      width: "80px",
      align: "center",
    },
  ],
  LOAI_3: [
    {
      title: "STT",
      dataIndex: "STT",
      width: "80px",
      align: "center",
    },
    {
      title: "Mã yêu cầu",
      dataIndex: "maYeuCau",
      align: "center",
      width: "80px",
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      align: "left",
      width: "100px",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "tenKhachHang",
      width: "100px",
      align: "left",
    },
    {
      title: "Kết quả xử lý",
      dataIndex: "ketQuaXuLy",
      width: "150px",
      align: "left",
    },
    {
      title: "Ngày yêu cầu",
      dataIndex: "ngayYeuCau",
      align: "center",
      width: "140px",
      render: (text, record) => {
        if (record.ngayYeuCau) {
          return <>{moment(record.ngayYeuCau).format("DD/MM/YYYY HH:mm")}</>;
        }
      },
    },
    {
      title: "Ngày tiếp nhận",
      dataIndex: "ngayTiepNhan",
      align: "center",
      width: "140px",
      render: (text, record) => {
        if (record.ngayTiepNhan) {
          return <>{moment(record.ngayTiepNhan).format("DD/MM/YYYY HH:mm")}</>;
        }
      },
    },
    {
      title: "Người yêu cầu",
      dataIndex: "nguoiYeuCau",
      align: "left",
      width: "100px",
    },
    {
      title: "Điện thoại",
      dataIndex: "dienThoai",
      align: "center",
      width: "110px",
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      width: "110px",
      align: "left",
    },
    {
      title: "Loại yêu cầu",
      dataIndex: "loaiYeuCau",
      width: "140px",
      align: "left",
    },
    {
      title: "Biên bản",
      dataIndex: "bienBan",
      width: "70px",
      render: (data) => {
        return (
          data && (
            <Button style={{ backgroundColor: "rgb(244, 164, 96)" }}>
              <a href={data} target="_blank" rel="noreferer noreferrer">
                Xem{" "}
              </a>
            </Button>
          )
        );
      },
      align: "center",
    },
    {
      title: "Phiếu yêu cầu",
      dataIndex: "phieuYeuCau",
      render: (link) => {
        return (
          link && (
            <a href={link} target="_blank" rel="noreferer noreferrer">
              Xem{" "}
            </a>
          )
        );
      },
      width: "75px",

      align: "center",
    },
    {
      title: "Nhân viên thực hiện",
      dataIndex: "nhanVienThucHien",
      width: "100px",
      align: "left",
    },
    {
      title: "Đội",
      dataIndex: "tenDoi",
      width: "100px",
      align: "left",
    },
  ],
  LOAI_4: [
    {
      title: "STT",
      dataIndex: "STT",
      width: "80px",
      align: "center",
    },
    {
      title: "Mã tin",
      dataIndex: "maYeuCau",
      width: "80px",
      align: "center",
    },
    {
      title: "Ngày Y/C",
      dataIndex: "ngayYeuCau",
      width: "140px",
      align: "center",
      render: (text, record) => {
        if (record.ngayYeuCau) {
          return <>{moment(record.ngayYeuCau).format("DD/MM/YYYY HH:mm")}</>;
        }
      },
    },
    {
      title: "Ngày tiếp nhận",
      dataIndex: "ngayTiepNhan",
      width: "140px",
      align: "center",
      render: (text, record) => {
        if (record.ngayTiepNhan) {
          return <>{moment(record.ngayTiepNhan).format("DD/MM/YYYY HH:mm")}</>;
        }
      },
    },
    {
      title: "Ngày hết hạn",
      dataIndex: "ngayHetHan",
      width: "140px",
      align: "center",
      render: (text, record) => {
        if (record.ngayHetHan) {
          return <>{moment(record.ngayHetHan).format("DD/MM/YYYY HH:mm")}</>;
        }
      },
    },
    {
      title: "Ngày mất điện",
      dataIndex: "ngayMatDien",
      width: "140px",
      align: "center",
      render: (text, record) => {
        if (record.ngayMatDien) {
          return <>{moment(record.ngayMatDien).format("DD/MM/YYYY HH:mm")}</>;
        }
      },
    },
    {
      title: "Mã KH",
      dataIndex: "maKhachHang",
      width: "120px",
      align: "left",
    },
    {
      title: "Tên KH",
      dataIndex: "tenKhachHang",
      width: "140px",
      align: "left",
    },

    {
      title: "Người Y/C",
      dataIndex: "nguoiYeuCau",
      width: "140px",
      align: "left",
    },
    {
      title: "Nội dung Y/C",
      dataIndex: "noiDungYeuCau",
      width: "400px",
      align: "left",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChiKhachHang",
      width: "150px",
      align: "left",
    },
    {
      title: "Điện thoại",
      dataIndex: "dienThoai",
      width: "110px",
      align: "left",
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      width: "120px",
      align: "left",
    },
    {
      title: "Loại yêu cầu",
      dataIndex: "loaiYeuCau",
      width: "140px",
      align: "left",
    },
    {
      title: "Người giám sát",
      dataIndex: "nguoiGiamSat",
      width: "140px",
      align: "left",
    },
    {
      title: "Đội",
      dataIndex: "tenDoi",
      width: "120px",
      align: "left",
    },
    {
      title: "Web id",
      dataIndex: "webId",
      width: "80px",
      align: "center",
    },
  ],
};
