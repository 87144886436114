import LoadingComponent from "@components/Loading";
import { Tabs } from "antd";
import ThongTinYeuCau from "./panels/thong-tin-yeu-cau";
import ThongTinCongTo from "./panels/cong-to";
import ThongTinTITU from "./panels/ti-tu";
import ChiSo from "./panels/chi-so";
import { TabContainer } from "../../css-styled";
import { ENUM_SOPHA } from "@utils/constants";
import PhieuLenhCongTac from "./panels/phieu-lenh";

const { TabPane } = Tabs;
export default function Index(props) {
  const { detail, loading, maLoaiYeuCau, recordRow } = props;
  const onChangeTabs = () => {};

  return (
    <LoadingComponent loading={loading}>
      <ThongTinYeuCau detail={detail?.thongTinYeuCau} />
      <TabContainer>
        <Tabs defaultActiveKey="1" onChange={onChangeTabs}>
          {detail?.congToThao && detail?.congToTreo ? (
            <TabPane tab="Công tơ" key="1">
              <ThongTinCongTo
                detail={detail}
                isThaoCongTo={detail?.congToThao?.isThaoCongTo}
                isLapTrinhLaiCongTo={detail?.congToTreo?.isLapTrinhLaiCongTo}
                maLoaiYeuCau={maLoaiYeuCau}
              />
            </TabPane>
          ) : null}

          {detail?.congToThao &&
          detail?.congToThao?.chiSoThaos === null &&
          detail?.congToTreo &&
          detail?.congToTreo?.chiSoTreos === null ? null : (
            <TabPane tab="Chỉ số" key="3">
              <ChiSo detail={detail} />
            </TabPane>
          )}

          {parseInt(recordRow?.soPha) === ENUM_SOPHA.BA_PHA &&
          ((detail.congToThao &&
            detail.congToThao.tIs !== null &&
            detail.congToThao.tIs.length > 0) ||
            (detail.congToTreo &&
              detail.congToTreo.tIs !== null &&
              detail.congToTreo.tIs.length > 0) ||
            (detail.congToThao &&
              detail.congToThao.tUs !== null &&
              detail.congToThao.tUs.length > 0) ||
            (detail.congToTreo &&
              detail.congToTreo.tUs !== null &&
              detail.congToTreo.tUs.length > 0)) ? (
            <TabPane tab="TI / TU" key="2">
              <ThongTinTITU detail={detail} maLoaiYeuCau={maLoaiYeuCau} />
            </TabPane>
          ) : null}
          {detail && detail?.coPhieuLenh && (
            <TabPane tab="Phiếu lệnh công tác" key="5">
              <PhieuLenhCongTac data={detail.phieuLenhCongTac} />
            </TabPane>
          )}
        </Tabs>
      </TabContainer>
    </LoadingComponent>
  );
}
