import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Bieu1 from "./bc-chi-tiet-tin-nhan-momt/bieu1";
import Bieu2 from "./bc-chi-tiet-tin-nhan-chu-dong-mt/bieu2";
import Bieu3 from "./bc-chi-tiet-tin-nhan-chu-dong-mt/bieu3";
import Bieu4 from "./bc-chi-tiet-tin-nhan-chu-dong-mt/bieu4";
import Bieu5 from "./bc-chi-tiet-tin-nhan-chu-dong-mt/bieu5";
import Bieu6 from "./bc-chi-tiet-tin-nhan-chu-dong-mt/bieu6";
import Bieu7 from "./bc-chi-tiet-tin-nhan-chu-dong-mt/bieu7";
import Bieu8 from "./bc-chi-tiet-tin-nhan-chu-dong-mt/bieu8";
import Bieu9 from "./bc-chi-tiet-tin-nhan-chu-dong-mt/bieu9";
import { selectBcthsmsChuDong } from "./service";

export default function TinNhanChuDongMT() {
  const [screen, setScreen] = useState();
  const [loading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // authPostData({
    //   url: ``,
    //   method: "POST",
    //   setLoading,
    //   payload: values,
    //   onSuccess: (res) => {
    //     console.log("res", res);
    //   },
    // });
    console.log("values", values);
  };

  useEffect(() => {
    setScreen(selectBcthsmsChuDong[0].value);
  }, []);

  const handleChange = useCallback((values) => {
    setScreen(values);
  }, []);

  return (
    <Fragment>
      {screen === 1 ? (
        <Bieu1
          screen={screen}
          setScreen={setScreen}
          handleChange={handleChange}
        />
      ) : screen === 2 ? (
        <Bieu2
          screen={screen}
          setScreen={setScreen}
          handleChange={handleChange}
        />
      ) : screen === 3 ? (
        <Bieu3
          screen={screen}
          setScreen={setScreen}
          handleChange={handleChange}
        />
      ) : screen === 4 ? (
        <Bieu4
          screen={screen}
          setScreen={setScreen}
          handleChange={handleChange}
        />
      ) : screen === 5 ? (
        <Bieu5
          screen={screen}
          setScreen={setScreen}
          handleChange={handleChange}
        />
      ) : screen === 6 ? (
        <Bieu6
          screen={screen}
          setScreen={setScreen}
          handleChange={handleChange}
        />
      ) : screen === 7 ? (
        <Bieu7
          screen={screen}
          setScreen={setScreen}
          handleChange={handleChange}
        />
      ) : screen === 8 ? (
        <Bieu8
          screen={screen}
          setScreen={setScreen}
          handleChange={handleChange}
        />
      ) : screen === 9 ? (
        <Bieu9
          screen={screen}
          setScreen={setScreen}
          handleChange={handleChange}
        />
      ) : (
        <Container>
          <Divider className="section-text" orientation="left">
            Tin nhắn chủ động mo_mt
          </Divider>
          <ContentWrapper>
            <Spin spinning={loading}>
              <FormComponent
                onFinish={onFinish}
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                initialValues={{ reportType: screen }}
              >
                <Form.Item
                  name="reportName"
                  label="Tên báo cáo"
                  labelAlign="left"
                >
                  <Select
                    placeholder="Tất cả"
                    options={selectBcthsmsChuDong}
                    onChange={() =>
                      handleChange(form.getFieldValue("reportName"))
                    }
                  ></Select>
                </Form.Item>

                <Form.Item
                  name="reportType"
                  label="Loại báo cáo"
                  labelAlign="left"
                >
                  <Selection
                    // url={Endpoint.GET_UNIT_LIST}
                    formKey="reportType"
                    form={form}
                  />
                </Form.Item>

                <Form.Item name="unitId" label="Đơn vị" labelAlign="left">
                  <Selection
                    url={Endpoint.GET_UNIT_LIST}
                    formKey="unitId"
                    form={form}
                  />
                </Form.Item>

                <Form.Item
                  name="reportStartDate"
                  label="Ngày bắt đầu"
                  labelAlign="left"
                >
                  <DatePickerComponent
                    picker="Day"
                    formKey="reportStartDate"
                    form={form}
                  />
                </Form.Item>

                <Form.Item
                  name="reportEndDate"
                  label="Ngày kết thức"
                  labelAlign="left"
                >
                  <DatePickerComponent
                    picker="Day"
                    formKey="reportEndDate"
                    form={form}
                  />
                </Form.Item>

                <Row>
                  <Col span={4}></Col>
                  <Col>
                    <Button type="primary" htmlType="submit">
                      Xuất báo cáo
                    </Button>

                    <Button
                      type="primary"
                      loading={loading}
                      className="ml-20px"
                      // onClick={() => {
                      //   handleOpenPdf(form.getFieldValue(), setLoading);
                      // }}
                    >
                      In báo cáo
                    </Button>
                  </Col>
                </Row>
              </FormComponent>
            </Spin>
          </ContentWrapper>
        </Container>
      )}
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
