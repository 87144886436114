import { useState } from "react";
import ListImage from "./list-image";

export default function DanhSachAnhTabs({
  loading,
  dataLoaiGT,
  firstLoaiGT,
  handleChangeType,
  dataImages,
  type,
  listId,
}) {
  const [showModal, setShowModal] = useState(false);
  const [, setCaption] = useState("");
  const [modalSrc, setModalSrc] = useState("");
  const [sectionIndex, setSectionIndex] = useState(null);
  const [imageIndex, setImageIndex] = useState(null);
  const [keyImage, setKeyImage] = useState(0);
  const [currentSectionLength, setCurrentSectionLength] = useState(null);

  const [hideButtonLeft, setHideButtonLeft] = useState(false);
  const [hideButtonRight, setHideButtonRight] = useState(false);
  const [note, setNote] = useState("");

  const showSliderImage = (index) => {
    if (sectionIndex <= 0 && imageIndex === 0) {
      setHideButtonLeft(true);
    } else setHideButtonLeft(false);
    if (
      dataImages[index][sectionIndex - 1] === dataImages[index].length - 1 &&
      imageIndex === dataImages[index].length - 1
    )
      setHideButtonRight(true);
    else setHideButtonRight(false);
    setShowModal(true);
  };

  const plusSlides = (n, index) => {
    const nextIndex = n;
    if (nextIndex <= 0 && sectionIndex <= 1) {
      setHideButtonLeft(true);
    } else setHideButtonLeft(false);
    if (nextIndex === dataImages[index].length - 1) {
      setHideButtonRight(true);
    } else setHideButtonRight(false);
    let nextImage = nextIndex;
    let nextImageIndex = nextIndex;
    let nextSectionIndex = sectionIndex;
    let nextSectionLength = currentSectionLength;
    let note = dataImages[index][nextIndex]?.ghiChu;
    if (nextIndex >= sectionIndex) {
      nextImage = dataImages[index][nextIndex].filePath;
      nextImageIndex = nextIndex;
      nextSectionIndex = sectionIndex;
      nextSectionLength = currentSectionLength;
      note = dataImages[index][nextIndex].ghiChu;
    } else {
      nextImage = dataImages[index][sectionIndex - 1].filePath;
      nextImageIndex = 0;
      nextSectionIndex = sectionIndex - 1;
      nextSectionLength = dataImages[index][sectionIndex - 1].filePath;
      note = dataImages[index][sectionIndex - 1].ghiChu;
    }

    setCaption(nextImage);
    setModalSrc(nextImage);
    setImageIndex(nextImageIndex);
    setSectionIndex(nextSectionIndex);
    setCurrentSectionLength(nextSectionLength);
    setNote(note);
    setKeyImage(index);
  };

  return (
    <ListImage
      loading={loading}
      dataImages={dataImages}
      showSliderImage={showSliderImage}
      setCaption={setCaption}
      setModalSrc={setModalSrc}
      setImageIndex={setImageIndex}
      imageIndex={imageIndex}
      setSectionIndex={setSectionIndex}
      setCurrentSectionLength={setCurrentSectionLength}
      setHideButtonLeft={setHideButtonLeft}
      setHideButtonRight={setHideButtonRight}
      showModal={showModal}
      setShowModal={setShowModal}
      modalSrc={modalSrc}
      plusSlides={plusSlides}
      hideButtonLeft={hideButtonLeft}
      hideButtonRight={hideButtonRight}
      dataLoaiGT={dataLoaiGT}
      firstLoaiGT={firstLoaiGT}
      handleChangeType={handleChangeType}
      type={type}
      note={note}
      setNote={setNote}
      keyImage={keyImage}
      setKeyImage={setKeyImage}
      listId={listId}
    />
  );
}
