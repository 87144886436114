import Filter from "../../../common/Filter";
import TableComponent from "@components/TableComponent";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { Fragment, useCallback } from "react";
import { Export } from "../../export/Export";
import { FillterNam } from "./list-bo-loc";

export default function BaoCaoGCSTheoNam(props) {
  const {
    columns,
    handleSearch,
    clearFilter,
    filterConditions,
    loading,
    data,
    setLoading,
    onChangePagination,
    totalCount,
    countTotals,
  } = props;
  const queryString = buildQueryString(
    parseParams({
      ...filterConditions,
    })
  );
  const genExtra = useCallback(
    () => (
      <div className="export">
        <Export
          excelEndpoint={`${Endpoint.LICH_GCS_EXCEL}?${queryString}`}
          pdfEndpoint={`${Endpoint.LICH_GCS_PDF}?${queryString}`}
          setLoading={setLoading}
        />
      </div>
    ),
    [queryString, setLoading]
  );
  return (
    <Fragment>
      <Filter>
        <FillterNam
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
        />
      </Filter>
      <TableComponent
        header={"Tổng hợp ghi chỉ số theo Năm"}
        columns={columns}
        filterConditions={filterConditions}
        totalCount={totalCount}
        loading={loading}
        scrollX={1400}
        dataSource={data}
        setLoading={setLoading}
        pagination={filterConditions}
        renderExtraAction={() => genExtra()}
        onChangePagination={onChangePagination}
        summary={(pageData) => countTotals(pageData)}
      />
    </Fragment>
  );
}
