import { Fragment } from "react";
import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { Container } from "./css-styled";
import ListFilterDinhKy from "./filter-bc-ct-dk/list-bo-loc";
import ListFilterPhucTra from "./filter-bc-ct-pt/list-bo-loc";
import ListFilterBatThuong from "./filter-bc-bat-thuong/list-bo-loc";
import { TYPE_TABLE_BAOCAO } from "@utils/constants";

export default function ListYeuCauTable(props) {
  const {
    typeList,
    loading,
    dataSource,
    totalData,
    handleSearch,
    clearFilter,
    onChangePagination,
    filterConditions,
    rowSelection,
    genExtra,
    user,
    columns,
    header,
  } = props;
  return (
    <Fragment>
      {typeList === TYPE_TABLE_BAOCAO.BATTHUONG ? (
        <Filter>
          <ListFilterBatThuong
            handleSearch={handleSearch}
            clearFilter={clearFilter}
            filterConditions={filterConditions}
            tooltip={
              "Tìm kiếm theo mã điểm đo, mã công tơ, tên khách hàng, địa chỉ"
            }
            user={user}
            typeList={typeList}
          />
        </Filter>
      ) : typeList === TYPE_TABLE_BAOCAO.DINHKYDAKIEMTRA ? (
        <Filter>
          <ListFilterDinhKy
            handleSearch={handleSearch}
            clearFilter={clearFilter}
            filterConditions={filterConditions}
            tooltip={
              "Tìm kiếm theo mã điểm đo, mã công tơ, tên khách hàng, địa chỉ"
            }
            user={user}
            typeList={typeList}
          />
        </Filter>
      ) : typeList === TYPE_TABLE_BAOCAO.PHUCTRADAKIEMTRA ? (
        <Filter>
          <ListFilterPhucTra
            handleSearch={handleSearch}
            clearFilter={clearFilter}
            filterConditions={filterConditions}
            tooltip={
              "Tìm kiếm theo mã điểm đo, mã công tơ, tên khách hàng, địa chỉ"
            }
            user={user}
            typeList={typeList}
          />
        </Filter>
      ) : null}

      <Container>
        <TableComponent
          header={header}
          renderExtraAction={() => genExtra()}
          dataSource={dataSource}
          columns={columns}
          totalData={totalData}
          loading={loading}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
          // onRow={onRow ? onRow : null}
          scrollX={2400}
          scrollY={520}
          rowSelection={rowSelection ? rowSelection : null}
          pageSizeOptionKTCT={
            totalData > 500
              ? [10, 20, 50, 100, 200, 500, totalData]
              : [10, 20, 50, 100, 200, 500]
          }
          // rowKey={rowKey}
        />
      </Container>
    </Fragment>
  );
}
