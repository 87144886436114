import React, { useEffect, useState } from "react";
import { Form } from "antd";
import PhanCongModal from "./modal-phan-cong";
import {
  handleListLog,
  handleMultiNhanVien,
  handleSubmitPhanCong,
} from "./services";
import { FORMAT_ENGLISH } from "@utils/constants";
import moment from "moment";

export default function Index({
  visible,
  closeModal,
  selectedRowKeys,
  setFetchList,
  filterUnit,
  setSelectedRowKeys,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [dataTreoThao, setDataTreoThao] = useState([]);
  const [dataNiemPhong, setDataNiemPhong] = useState([]);
  const [dataNVLog, setDataNVLog] = useState([]);
  // const [valueCot1Row1, setValueCot1Row1] = useState("");
  // const [valueCot1Row2, setValueCot1Row2] = useState("");
  // const [valueCot1Row3, setValueCot1Row3] = useState("");
  // const [valueCot2Row1, setValueCot2Row1] = useState("");
  // const [valueCot2Row2, setValueCot2Row2] = useState("");
  // const [valueCot2Row3, setValueCot2Row3] = useState("");
  // const [isDisCot1Row1, setIsDisCot1Row1] = useState(false);
  // const [isDisCot1Row2, setIsDisCot1Row2] = useState(false);
  // const [isDisCot1Row3, setIsDisCot1Row3] = useState(false);
  // const [isDisCot2Row1, setIsDisCot2Row1] = useState(false);
  // const [isDisCot2Row2, setIsDisCot2Row2] = useState(false);
  // const [isDisCot2Row3, setIsDisCot2Row3] = useState(false);

  //submit xác nhận phân công
  const onFinish = (values) => {
    // console.log(values);
    // handleCloseAllPopup();
    const payload = {
      ...values,
      ids: selectedRowKeys,
      ngayTreoThao: moment(values.ngayTreoThao).format(FORMAT_ENGLISH),
      hoTenNVHT1: values.hoTenNVHT1,
      hoTenNVHT2: values.hoTenNVHT2,
      hoTenNVHT3: values.hoTenNVHT3,
      maNVHT1: values.maNVHT1,
      maNVHT2: values.maNVHT2,
      maNVHT3: values.maNVHT3,
    };
    //call api
    handleSubmitPhanCong(
      setLoading,
      payload,
      setFetchList,
      form,
      closeModal,
      setSelectedRowKeys
    );
  };

  useEffect(() => {
    handleMultiNhanVien(
      setLoading,
      setDataTreoThao,
      setDataNiemPhong,
      selectedRowKeys
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //fetch list nv hỗ trợ log
  useEffect(() => {
    handleListLog(setLoading, setDataNVLog);
  }, []);

  //close popup search
  // const handleCloseAllPopup = () => {
  //   setIsDisCot1Row1(false);
  //   setIsDisCot1Row2(false);
  //   setIsDisCot1Row3(false);
  //   setIsDisCot2Row1(false);
  //   setIsDisCot2Row2(false);
  //   setIsDisCot2Row3(false);
  // };
  const disabledDate = (current) => {
    const customDate = moment().format("DD/MM/YYYY");
    return current && current < moment(customDate, "DD/MM/YYYY");
  };
  return (
    <PhanCongModal
      visible={visible}
      closeModal={closeModal}
      form={form}
      dataTreoThao={dataTreoThao}
      dataNiemPhong={dataNiemPhong}
      onFinish={onFinish}
      loading={loading}
      filterUnit={filterUnit}
      dataNVLog={dataNVLog}
      disabledDate={disabledDate}
      // valueCot1Row1={valueCot1Row1}
      // valueCot1Row2={valueCot1Row2}
      // valueCot1Row3={valueCot1Row3}
      // valueCot2Row1={valueCot2Row1}
      // valueCot2Row2={valueCot2Row2}
      // valueCot2Row3={valueCot2Row3}
      // setValueCot1Row1={setValueCot1Row1}
      // setValueCot1Row2={setValueCot1Row2}
      // setValueCot1Row3={setValueCot1Row3}
      // setValueCot2Row1={setValueCot2Row1}
      // setValueCot2Row2={setValueCot2Row2}
      // setValueCot2Row3={setValueCot2Row3}
      // isDisCot1Row1={isDisCot1Row1}
      // isDisCot1Row2={isDisCot1Row2}
      // isDisCot1Row3={isDisCot1Row3}
      // isDisCot2Row1={isDisCot2Row1}
      // isDisCot2Row2={isDisCot2Row2}
      // isDisCot2Row3={isDisCot2Row3}
      // setIsDisCot1Row1={setIsDisCot1Row1}
      // setIsDisCot1Row2={setIsDisCot1Row2}
      // setIsDisCot1Row3={setIsDisCot1Row3}
      // setIsDisCot2Row1={setIsDisCot2Row1}
      // setIsDisCot2Row2={setIsDisCot2Row2}
      // setIsDisCot2Row3={setIsDisCot2Row3}
      // handleCloseAllPopup={handleCloseAllPopup}
    />
  );
}
