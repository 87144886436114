import { Col, Row } from "antd";

export default function Index(props) {
  const { detail } = props;
  return (
    <Row gutter={24} style={{ padding: "16px" }}>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Mã điểm đo</h6>
        <h5 className="fs-14px font-bold">{detail?.maDiemDo}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Đội</h6>
        <h5 className="fs-14px font-bold">{detail?.tenDoi}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Sổ GCS</h6>
        <h5 className="fs-14px font-bold">{detail?.soGCS}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Trạm</h6>
        <h5 className="fs-14px font-bold">{detail?.tram}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Loại treo tháo</h6>
        <h5 className="fs-14px font-bold">{detail?.loaiTreoThao}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Lý do treo tháo</h6>
        <h5 className="fs-14px font-bold">{detail?.lyDoTreoThao}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Số BB</h6>
        <h5 className="fs-14px font-bold">{detail?.soBienBan}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Treo tháo TU/TI chốt chỉ số</h6>
        <h5 className="fs-14px font-bold">
          {detail?.tutiChotSo ? "Có" : "Không"}
        </h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Loại điểm đo</h6>
        <h5 className="fs-14px font-bold">{detail?.loaiDiemDo}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Số pha</h6>
        <h5 className="fs-14px font-bold">{detail?.soPha}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Vị trí lắp đặt</h6>
        <h5 className="fs-14px font-bold">{detail?.viTriLapDat}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Số cột/hộp</h6>
        <h5 className="fs-14px font-bold">{detail?.soCotHop}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Đo xa</h6>
        <h5 className="fs-14px font-bold">{detail?.doXa}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Thời gian</h6>
        <h5 className="fs-14px font-bold">{detail?.thoiGian}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">HESID</h6>
        <h5 className="fs-14px font-bold">{detail?.hesId}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">GatewayID</h6>
        <h5 className="fs-14px font-bold">{detail?.gatewayId}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Ngày treo tháo</h6>
        <h5 className="fs-14px font-bold">{detail?.ngayTreoThao}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">NV treo tháo</h6>
        <h5 className="fs-14px font-bold">
          {detail?.userNameNVTreoThao} - {detail?.nhanVienTreoThao}
        </h5>
      </Col>
      <Col span={12} md={12}>
        <h6 className="fs-12px c-t-label">NV niêm phong</h6>
        <h5 className="fs-14px font-bold">
          {detail?.userNameNVNiemPhong} - {detail?.nhanVienNiemPhong}
        </h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Mã khách hàng</h6>
        <h5 className="fs-14px font-bold">{detail?.maKhachHang}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Tên khách hàng</h6>
        <h5 className="fs-14px font-bold">{detail?.tenKhachHang}</h5>
      </Col>
      <Col span={12} md={12}>
        <h6 className="fs-12px c-t-label">Tên người đại diện</h6>
        <h5 className="fs-14px font-bold">{detail?.tenNguoiDaiDien}</h5>
      </Col>
      <Col span={12} md={12}>
        <h6 className="fs-12px c-t-label">Địa chỉ dùng điện</h6>
        <h5 className="fs-14px font-bold">{detail?.diaChiDungDien}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Số điện thoại</h6>
        <h5 className="fs-14px font-bold">{detail?.soDienThoai}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Email</h6>
        <h5 className="fs-14px font-bold">{detail?.email}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Hệ số nhân đo đếm </h6>
        <h5 className="fs-14px font-bold">{detail?.hsn}</h5>
      </Col>
    </Row>
  );
}
