import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData } from "@utils/request";

export const getDataB4_Gop_Ep = (payload, setLoading, setDataB4_TH_EP) => {
  authGetData({
    url: `${Endpoint.B4_TH_DOI_SOAT_GOP_EP}?${buildQueryString(
      parseParams(payload)
    )}`,

    setLoading,
    onSuccess: (res) => {
      console.log("res", res);
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataB4_TH_EP(res.data);
      }
    },
  });
};
