import {
  Fragment,
  memo,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import PDFModal from "@modules/cap-dien/common-ho-so/pdf-viewer";
import { downLoadBienBan, getPDFBienBanKH } from "./services";
import { TYPE_BIEN_BAN } from "@utils/constants";

function Index({
  detail,
  setVisibleQuyetToanKhachHang,
  visibleQuyetToanKhachHang,
}) {
  const { t } = useTranslation();
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const inBienBanKH = useMemo(
    () =>
      visibleQuyetToanKhachHang && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visibleQuyetToanKhachHang}
            setVisible={setVisibleQuyetToanKhachHang}
            title={`${TYPE_BIEN_BAN.QUYETTOAN_KH} ${detail?.maYCKNCmis} ${detail.tenKhachHang}`}
            getBienBan={(setUrl) => showModalPDF(setUrl)}
            downloadFileExcel={() =>
              downLoadBienBan(detail, setLoadingDownload)
            }
            setLoadingDownload={setLoadingDownload}
            loadingDownload={loadingDownload}
            loadingGetBienBan={loadingPDF}
            isDownloadExcel={true}
            isReload={true}
            type={TYPE_BIEN_BAN.QUYETTOAN_KH}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visibleQuyetToanKhachHang]
  );

  const showModalPDF = useCallback(
    (setUrl) => {
      getPDFBienBanKH(
        detail,
        (value) => {
          setUrl(value);
        },
        setLoadingPDF,
        t
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );

  return <Fragment>{inBienBanKH}</Fragment>;
}

export default memo(Index);
