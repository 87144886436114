import { buildQueryString, parseParams } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";

export const handleListData = (
  location,
  filterConditions,
  setLoading,
  setDataYCPKD,
  setTotalCount,
  setSelectedRowKeys
) => {
  let url = "";
  url = `${Endpoint.AG_PKD_LIS_YC}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      setSelectedRowKeys([]);
      if (res.data !== null) {
        setDataYCPKD(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataYCPKD([]);
    },
  });
};
export const submitPKDXacNhan = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys,
  handleCloseModalKy
) => {
  authPostData({
    url: Endpoint.AG_PKD_XAC_NHAN_YC,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys.toString(),
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
        handleCloseModalKy();
      }
    },
  });
};

export const submitPKDKySo = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys,
  handleCloseModalKy
) => {
  authPostData({
    url: Endpoint.AG_PKD_KY_HSM,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
        handleCloseModalKy();
      }
    },
  });
};

export const submitPKDKyNhayBB = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys,
  handleCloseModalKy
) => {
  authPostData({
    url: Endpoint.AG_KY_NHAY_BIEN_BAN,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
        handleCloseModalKy();
      }
    },
  });
};

export const submitPKDTraLai = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys,
  handleCloseModalTraLai,
  lyDo,
) => {
  authPostData({
    url: Endpoint.AG_PKD_TRA_LAI_YC,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys.toString(),
      lyDoTraLai: lyDo
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
        handleCloseModalTraLai();
      }
    },
  });
};

export const getDataCauHinh = (setLoading, setDataCauHinh) => {
  authGetData({
    url: Endpoint.AG_KY_NHAY_USER,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataCauHinh(res.data);
      } else setDataCauHinh();
    },
  });
};