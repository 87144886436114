import { openModal } from "@state/system-config/reducer";
import { Menu } from "antd";
import { useDispatch } from "react-redux";
import ModalSuaPhuongThuc from "./modal/modal-sua-phuong-thuc";
import ModalThayDoiPhuongThuc from "./modal/modal-thay-doi-phuong-thuc";
import ModalThayDoiTTDiemDo from "./modal/modal-thay-doi-tt-diem-do";
import ModalTraCuuTTDiemDO from "./modal/modal-tra-cuu-tt-diem-do";
export default function ListMenu({
  closeModal,
  visible,
  detail,
  // handlerOpenXoaDiemDo,
  handlerNgungDiemDo,
  getDanhSachDiemDo,
  setLoadingDanhSach,
  setDataDanhSach,
  setFilterConditions,
  filterConditions,
  isSuaPhuongThuc,
  setIndexPage,
  indexPage,
}) {
  const dispatch = useDispatch();
  return (
    // selectedKeys={["0"]}
    <Menu mode="vertical" style={{ width: "100%" }}>
      <Menu.Item
        eventKey="1"
        onClick={() =>
          dispatch(
            openModal({
              title: "Thay đổi thông tin điểm đo",
              content: (
                <ModalThayDoiTTDiemDo
                  visible={visible}
                  closeModal={closeModal}
                  detail={detail}
                  setLoadingDanhSach={setLoadingDanhSach}
                  setDataDanhSach={setDataDanhSach}
                  getDanhSachDiemDo={getDanhSachDiemDo}
                />
              ),
              size: "large",
            })
          )
        }
      >
        Thay đổi thông tin điểm đo
      </Menu.Item>
      <Menu.Item
        eventKey="2"
        onClick={() =>
          dispatch(
            openModal({
              title: "Thay đổi phương thức giao nhận",
              content: (
                <ModalThayDoiPhuongThuc
                  visible={visible}
                  detail={detail}
                  closeModal={closeModal}
                  setLoadingDanhSach={setLoadingDanhSach}
                  setDataDanhSach={setDataDanhSach}
                  getDanhSachDiemDo={getDanhSachDiemDo}
                />
              ),
              size: "large",
            })
          )
        }
      >
        Thay đổi phương thức
      </Menu.Item>
      {isSuaPhuongThuc && (
        <Menu.Item
          eventKey="3"
          onClick={() =>
            dispatch(
              openModal({
                title: "Sửa phương thức",
                content: (
                  <ModalSuaPhuongThuc
                    visible={visible}
                    closeModal={closeModal}
                    detail={detail}
                    getDanhSachDiemDo={getDanhSachDiemDo}
                  />
                ),
                size: "large",
              })
            )
          }
        >
          Sửa phương thức
        </Menu.Item>
      )}
      <Menu.Item eventKey="4" onClick={handlerNgungDiemDo}>
        Ngưng điểm đo
      </Menu.Item>
      <Menu.Item
        eventKey="5"
        onClick={() =>
          dispatch(
            openModal({
              title: "Tra cứu thông tin điểm đo",
              content: (
                <ModalTraCuuTTDiemDO
                  detail={detail}
                  type={1}
                  setFilterConditions={setFilterConditions}
                  filterConditions={filterConditions}
                  setIndexPage={setIndexPage}
                  indexPage={indexPage}
                  setDataDanhSach={setDataDanhSach}
                />
              ),
              size: "large",
            })
          )
        }
      >
        Tra cứu thông tin điểm đo
      </Menu.Item>
      <Menu.Item
        eventKey="6"
        onClick={() =>
          dispatch(
            openModal({
              title: "Tra cứu thông tin phương thức",
              content: <ModalTraCuuTTDiemDO detail={detail} type={2} />,
              size: "large",
            })
          )
        }
      >
        Tra cứu thông tin phương thức
      </Menu.Item>
      {/* <Menu.Item eventKey="7" onClick={handlerOpenXoaDiemDo}>
        Xóa điểm đo
      </Menu.Item> */}
    </Menu>
  );
}
