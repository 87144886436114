import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APIThiCong = {
  KHOI_PHUC_THI_CONG: `${BASE_API_URL_CAP_DIEN}/thi-cong/khoi-phuc-thi-cong`,
  XAC_NHAN_THI_CONG: `${BASE_API_URL_CAP_DIEN}/thi-cong/xac-nhan-thi-cong`,
  GET_THONG_TIN_VAT_TU: `${BASE_API_URL_CAP_DIEN}/thi-cong/get-thong-tin-vat-tu`,
  UPDATE_THONG_TIN_VAT_TU_THI_CONG: `${BASE_API_URL_CAP_DIEN}/thi-cong/update-thong-tin-vat-tu`,
  GET_THONG_TIN_CONG_TO: `${BASE_API_URL_CAP_DIEN}/thi-cong/get-thong-tin-cong-to`,
  UPDATE_THONG_TIN_CONG_TO: `${BASE_API_URL_CAP_DIEN}/thi-cong/update-thong-tin-cong-to`,
  CHECK_GHI_NHAN_THI_CONG: `${BASE_API_URL_CAP_DIEN}/thi-cong/check-ghi-nhan-thi-cong`,
  CHECK_XAC_NHAN_THI_CONG: `${BASE_API_URL_CAP_DIEN}/thi-cong/check-xac-nhan-thi-cong`,
  GET_CONG_TO_CMIS: `${BASE_API_URL_CAP_DIEN}/thi-cong/list-cong-to-cmis`,
  GET_DETAIL_CONG_TO_CMIS: `${BASE_API_URL_CAP_DIEN}/thi-cong/get-thong-tin-cong-to-cmis`,
  GET_BIEN_BAN_LAP_DAT: `${BASE_API_URL_CAP_DIEN}/thi-cong/get-bien-ban-lap-dat`,
  CREATE_OR_UPDATE_BIEN_BAN_LAP_DAT: `${BASE_API_URL_CAP_DIEN}/thi-cong/create-or-update-bbld`,
  GET_LINK_FILE_BBLD: `${BASE_API_URL_CAP_DIEN}/thi-cong/get-file-bien-ban-lap-dat`,
  GET_FILE_FILE_BBLD: `${BASE_API_URL_CAP_DIEN}/thi-cong/get-file-pdf-bbld`,
  GET_CT_THAO_CMIS: `${BASE_API_URL_CAP_DIEN}/thi-cong/get-thiet-bi-hien-tai`,
  LIST_THIET_BI_CMIS: `${BASE_API_URL_CAP_DIEN}/thi-cong/list-thiet-bi-cmis`,
  GET_LAP_DAT_BIEN_BAN_DUONG_DAY: `${BASE_API_URL_CAP_DIEN}/thi-cong/get-bien-ban-lap-dat-tt`,
  UPDATE_SYNC_DONG_BO_CAP_NHAT_BIEN_BAN_LAP_DAT: `${BASE_API_URL_CAP_DIEN}/thi-cong/update-bien-ban-lap-dat-tt`,
  XAC_NHAN_CHUYEN_THI_CONG: `${BASE_API_URL_CAP_DIEN}/thi-cong/xac-nhan-chuyen-thi-cong`,
  THI_CONG_TRA_LAI_HO_SO: `${BASE_API_URL_CAP_DIEN}/thi-cong/tra-lai-ve-lap-bbtt`,
};
