import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import CapNhatCLCongToModal from "./modal";
import { handleUpdate, handleMultiDS } from "./services";
import { useDispatch } from "react-redux";
import { closeModal } from "@state/system-config/reducer";

const { Option } = Select;

export default function Index(props) {
  const { setFetchList, selectedRowKeys,setSelectedRowKeys, setIsUpdate, funcId } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [chungLoai, setChungLoai] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [choiceValue, setChoiceValue] = useState("");

  //cập nhật CL công tơ
  const handleSubmit = (values) => {
    const congToChungLoais = [];

    for (let i = 0; i < dataSource.length; i++) {
      //Validate form chungLoaiId
      if (
        values[`chungLoaiId-${i}`] === "" ||
        values[`chungLoaiId-${i}`] === null ||
        values[`chungLoaiId-${i}`] === undefined
      ) {
        form.setFields([
          {
            name: [`chungLoaiId-${i}`],
            errors: [`Không được bỏ trống`],
          },
        ]);
      } else {
        congToChungLoais.push({
          yeuCauId: dataSource[i].yeuCauId,
          chungLoaiId: values[`chungLoaiId-${i}`],
        });
      }
    }
    const payload = {
      congToChungLoais: congToChungLoais,
      funcId: funcId,
    };
    if (congToChungLoais.length === dataSource.length) {
      //gọi api update
      handleUpdate(
        setLoading,
        payload,
        setFetchList,
        closeModal,
        dispatch,
        setIsUpdate,
        setSelectedRowKeys
      );
    }
  };

  //lấy data chủng loại
  useEffect(() => {
    handleMultiDS(selectedRowKeys, setLoading, setChungLoai, setDataSource);
  }, [selectedRowKeys]);

  //chọn chủng loại mặc định
  const handleSelect = (value) => {
    setChoiceValue(value);
  };

  //thay đổi dữ liệu từng row
  const handleChange = (value, index) => {
    form.setFieldsValue({
      [`chungLoaiId-${index}`]: value,
    });
  };

  //áp dụng 1 chủng loại mặc định được chọn vào table
  const filterAllValue = () => {
    selectedRowKeys.map((_, index) => {
      return form.setFieldsValue({
        [`chungLoaiId-${index}`]: choiceValue,
      });
    });
  };

  //tạo columns
  const columns = [
    {
      title: "Hạn KĐ",
      dataIndex: "ngayHanKD",
      key: "ngayHanKD",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "10%",
    },
    {
      title: "Ngày treo tháo",
      dataIndex: "ngayTreoThao",
      key: "ngayTreoThao",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "10%",
    },
    {
      title: "Loại treo tháo",
      dataIndex: "loaiTreoThao",
      key: "loaiTreoThao",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "9%",
    },
    {
      title: "Trạm",
      dataIndex: "tram",
      key: "tram",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "9%",
    },
    {
      title: "Đội",
      dataIndex: "doi",
      key: "doi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "9%",
    },
    {
      title: "Tên KH - SĐT",
      dataIndex: "tenKHVaSdt",
      key: "tenKHVaSdt",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
    },
    {
      title: "Chủng loại hiện tại",
      dataIndex: "chungLoaiCTTreo",
      key: "chungLoaiCTTreo",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "18%",
    },
    {
      title: "Chủng loại mới",
      dataIndex: "chungLoaiMoi",
      key: "chungLoaiMoi",
      render: (_, record, index) => (
        <Form.Item name={`chungLoaiId-${index}`} style={{ margin: 0 }}>
          <Select
            placeholder="Chọn chủng loại mới"
            showSearch
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => handleChange(value, index)}
          >
            {chungLoai && chungLoai.length
              ? chungLoai.map((item, inx) => (
                  <Option key={inx} value={item.id}>
                    {`${item.maChungLoai} - ${item.loaiCongTo}`}
                  </Option>
                ))
              : null}
          </Select>
        </Form.Item>
      ),
      width: "20%",
    },
  ];

  return (
    <CapNhatCLCongToModal
      chungLoai={chungLoai}
      handleSubmit={handleSubmit}
      form={form}
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      filterAllValue={filterAllValue}
      handleSelect={handleSelect}
      choiceValue={choiceValue}
    />
  );
}
