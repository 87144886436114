import React, { Fragment } from "react";
import { ConfigProvider, Table } from "antd";
import vn from "antd/es/locale-provider/vi_VN";
import { TableWrapper } from "@modules/cap-dien/nhan-vien-khao-sat/css-styled";

export default function TableThuHoi(props) {
  const { genExtra, columns, data } = props;
  const scroll = {
    height: 340,
  };
  return (
    <Fragment>
      {genExtra()}
      <TableWrapper>
        <ConfigProvider locale={vn}>
          <Table
            dataSource={data}
            columns={columns}
            pagination={false}
            rowKey={(object) => object.id ?? object.vatTuId}
            bordered
            scroll={{ y: scroll.height, x: 800 }}
          />
        </ConfigProvider>
      </TableWrapper>
    </Fragment>
  );
}
