import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Form, Input, Row, Tooltip } from "antd";
import React, { useCallback, useEffect } from "react";
export function ListFilter(props) {
  const { handleSearch, clearFilter, filterConditions } = props;
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
    >
      <Row gutter={24} justify="space-between" align="middle">
        <Col span={24} md={6}>
          <Form.Item name="moduleId" label="Module">
            <Selection
              url={Endpoint.LIST_SELECT_MODULE}
              form={form}
              formKey="moduleId"
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item name="searchTerm" label="Tìm kiếm tên trang">
            <Input
              prefix={<SearchOutlined />}
              suffix={
                <Tooltip title={"Hỗ trợ tìm kiếm theo Tên trang"}>
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
        <Col span={6} align="end">
          <Button className="mr-24px" onClick={clear}>
            Bỏ lọc
          </Button>
          <Button type="primary" htmlType="submit" form="filter-form">
            Tìm kiếm
          </Button>
        </Col>
      </Row>
    </FormComponent>
  );
}
