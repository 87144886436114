import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData } from "@utils/request";

export const getListDataB4_DVK = (payload, setLoading, setDataB4) => {
  authGetData({
    url: `${Endpoint.B4_DVK_DOI_SOAT_SL_TIN_NHAN_THANH_CONG}?${buildQueryString(
      parseParams(payload)
    )}`,

    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataB4(res.data);
      }
    },
  });
};
