import styled from "styled-components";

export const Container = styled.div`
  padding: 0px 36px;
  // margin-top: 132px;
`;

export const AvatarUploadContainerStyled = styled.div`
  .custom-file-input {
    width: 165px;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: "Chọn ảnh từ trình duyệt";
    display: inline-block;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 5px 8px;
    cursor: pointer;
    font-weight: 500;
  }
  .custom-file-input:hover::before {
    color: #40a9ff;
    border-color: #40a9ff;
  }
`;
