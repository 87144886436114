import React, { useState } from "react";
import { handleXacNhanChuyenTC } from "../popup-bien-ban/services";
import ChuyenTCModal from "./layout";

export default function Index(props) {
  const { visible, closeModal, detailHoSo, setFetchDataTable } = props;
  const [loading, setLoading] = useState(false);

  //xóa dữ liệu

  const handleXN = () => {
    const payload = {
      hoSoId: detailHoSo?.id,
    };
    handleXacNhanChuyenTC(setLoading, payload, setFetchDataTable);
  };

  return (
    <ChuyenTCModal
      loading={loading}
      visible={visible}
      closeModal={closeModal}
      handleXN={handleXN}
    />
  );
}
