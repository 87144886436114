import { memo, useEffect, useRef, useState } from "react";
import { Form, Input, Button, Modal, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { handleSubmitForm } from "./services";

const { TextArea } = Input;

function CauHinhChungModal({
  detaiDataTable,
  visible,
  closeModal,
  getListData,
}) {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const handleSubmitData = (values) => {
    handleSubmitForm(
      values,
      detaiDataTable,
      setLoading,
      closeModal,
      getListData,
      form
    );
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();
    // eslint-disable-next-line
  }, []);

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={
          detaiDataTable.id
            ? t("cau_hinh.cap_nhat_cau_hinh")
            : t("cau_hinh.tao_cau_hinh")
        }
        visible={visible}
        onCancel={closeModal}
        className="modal-cau-hinh"
        footer={null}
      >
        <FormComponent
          form={form}
          name="form-cau-hinh"
          layout="vertical"
          onFinish={handleSubmitData}
          initialValues={{ ...detaiDataTable }}
          scrollToFirstError
          autoComplete="off"
        >
          <Form.Item
            label={t("cau_hinh.title.ten_cau_hinh")}
            name="tenCauHinh"
            className="required-field"
          >
            <Input
              ref={detaiDataTable.id ? null : inputRef}
              placeholder="Nhập tên cấu hình"
              disabled={detaiDataTable.id ? true : false}
            />
          </Form.Item>
          <Form.Item
            label={t("cau_hinh.title.gia_tri")}
            name="giaTri"
            className="required-field"
          >
            <Input
              placeholder="Nhập giá trị"
              ref={detaiDataTable.id ? inputRef : null}
            />
          </Form.Item>
          <Form.Item label={t("cau_hinh.title.ghi_chu")} name="ghiChu">
            <TextArea rows={3} placeholder="Nhập ghi chú" />
          </Form.Item>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-cau-hinh"
                loading={loading}
              >
                {detaiDataTable.id ? t("button.cap_nhat") : t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(CauHinhChungModal);
