import styled from "styled-components";

export const Container = styled.div`
  padding: 16px 24px;
`;
export const ContainerTab = styled.div`
  padding: 0px 36px;
`;

export const ChiTietYeuCauStyle = styled.div`
  .field_wrap {
    display: flex;

    h5.c-t-label {
      flex: 0 0 150px;
    }
  }
`;
