import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Pie } from "@ant-design/plots";
import { authPostData } from "@utils/request";
import { Col, Divider, Row } from "antd";
import BaoCaoCham from "./bccham";
import BKTheoDoi from "./bktheodoitt";
import ThongKeTable from "./thong-ke-table";
import ThongKeLich from "./thongkelich";
import NoRecordFound from "@components/NoRecordFound";
import { Endpoint } from "@utils/endpoint";

const BaoCaoNhanh = () => {
  function renameKey(datachart, newKeys) {
    const keyValues = Object.keys(datachart).map((key) => {
      const newKey = newKeys[key] || key;
      return { [newKey]: datachart[key] };
    });
    return Object.assign({}, ...keyValues);
  }
  const config = useMemo(
    () => ({
      appendPadding: 10,
      angleField: "value",
      colorField: "type",
      name: "name",
      radius: 0.9,
      label: {
        type: "inner",
        offset: "-30%",
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
          fontSize: 14,
          textAlign: "center",
        },
      },
      interactions: [
        {
          type: "element-active",
        },
      ],
    }),
    []
  );
  const [datachart, setDataChart] = useState(null);
  const [loading, setLoading] = useState();
  const getChartDasboard = useCallback((values) => {
    authPostData({
      url: `${Endpoint.SO_DA_GHI_CHUA_GHI}`,
      method: "POST",
      setLoading,
      payload: { ...values },
      onSuccess: (res) => {
        const newKeys = { name: "type", y: "value" };
        const renamedObj = res.data.series.data.map((e) =>
          renameKey(e, newKeys)
        );
        setDataChart(renamedObj);
      },
    });
  }, []);

  useEffect(() => {
    getChartDasboard();
  }, [getChartDasboard]);
  const renderContent = useCallback(() => {
    if (!loading && !datachart) {
      return (
        <div className="text-center">
          <NoRecordFound />
        </div>
      );
    }
    if (datachart) {
      return <Pie {...config} data={datachart} />;
    }
  }, [datachart, loading, config]);
  return (
    <Fragment>
      {" "}
      <div className="chart-container">
        <div className="wrapcontainer">
          <Divider
            orientation="center"
            className="section-text"
            plain="true"
          ></Divider>
          <Row gutter={10} align="middle">
            <Col span={12}>
              <h2 className="chart">Biểu đồ tổng sổ</h2>
              {renderContent()}
            </Col>
            <Col span={12}>
              <BaoCaoCham />
            </Col>
          </Row>
          <Row>
            <Divider></Divider>
            <Col span={22}>
              <BKTheoDoi />
            </Col>
            <Col span={2}></Col>
          </Row>
          <Row>
            <Divider></Divider>
            <Col span={24}>
              <ThongKeLich />
            </Col>
          </Row>
          <Row>
            <Divider></Divider>
            <Col span={24}>
              <ThongKeTable />
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};
export default BaoCaoNhanh;
