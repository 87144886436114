import DatePickerComponent from "@components/DatePicker";
// import Filter from "@components/Filter/Filter";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { downloadFile } from "@utils/request";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { selectBaoCaoSMS } from "../../services";
// import XuatExcel from "../../tabs/xuat-excel";
import { getDataB9_DoiSoat } from "./services";
export default function Bieu9DoiSoatDonVi(props) {
  const { screen, handleChange } = props;
  const [loading, setLoading] = useState();
  const [data, setDataB9] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const { Option } = Select;
  const [, setSearchParams] = useSearchParams();
  const user = useSelector(authSelector);
  const location = useLocation();
  const [form] = Form.useForm();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    MaDonVi: user.user.unitCode,
    TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
    DenNgay: moment().format(FORMAT_ENGLISH),
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const [filterExcel, setFilterExcel] = useState(false);
  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type]);

  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        MaDonVi: values.MaDonVi,
        phanLoai: values.PhanLoai,
        doiTuong: values.doiTuong,
        TuNgay: values.TuNgay,
        DenNgay: values.DenNgay,
      }));
      const payload = {
        MaDonVi: values.MaDonVi,
        phanLoai: values.PhanLoai,
        doiTuong: values.doiTuong,
        TuNgay: values.TuNgay,
        DenNgay: values.DenNgay,
      };
      handleAPI();
      getDataB9_DoiSoat(payload, setLoading, setDataB9, setTotalCount);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleAPI]
  );
  const defaultOptionValue = "e12382fc-5ebb-474d-a1a7-de277b424007";

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      width: 70,
      align: "center",
      render: (a, b, idx) => {
        return (
          (filterConditions.pageIndex - 1) * (filterConditions.pageSize + 1) +
          (idx + 1)
        );
      },
    },
    {
      title: "Mã đơn vị",
      dataIndex: "MA_DVIQLY",
      width: 150,
    },
    {
      title: "Số lượng đã nhắn viettel",
      dataIndex: "SL_DANHAN_VIETTEL",
      width: 150,
    },
    {
      title: "Số lượng chưa nhắn viettel",
      dataIndex: "SL_KODANHAN_VIETTEL",
      width: 150,
    },
    {
      title: "Số lượng đã nhắn Vina",
      dataIndex: "SL_DANHAN_VINA",
      width: 150,
    },
    {
      title: "Số lượng chưa nhắn VINA",
      dataIndex: "SL_KONHAN_VINA",
      width: 100,
    },
    {
      title: "Số lượng đã nhắn Mobi",
      dataIndex: "SL_DANHAN_MOBI",
      width: 150,
    },
    {
      title: "Số lượng chưa nhắn MOBI",
      dataIndex: "SL_KONHAN_MOBI",
      width: 100,
    },
    {
      title: "Số lượng đã nhắn VNMB",
      dataIndex: "SL_DANHAN_VNMOBILE",
      width: 100,
    },
    {
      title: "Số lượng đã nhắn GTEL",
      dataIndex: "SL_DANHAN_GTEL",
      width: 150,
    },
    {
      title: "Số lượng chưa nhắn GTEL",
      dataIndex: "SL_KONHAN_GTEL",
      width: 150,
    },
    {
      title: "Số lượng chưa nhắn VNMB",
      dataIndex: "SL_KONHAN_VNMOBILE",
      width: 100,
    },
    {
      title: "Tổng",
      dataIndex: "TONG",
      width: 100,
    },
    {
      title: "Tổng không nhắn",
      dataIndex: "TONG_KONHAN",
      width: 100,
    },
  ];

  const fakeTableTitle = "Biểu 9: Danh sách đối soát theo ngày đơn vị";
  useEffect(() => {
    if (filterExcel) {
      const endpoint =
        Endpoint.B9_EXCEL +
        "?" +
        buildQueryString(parseParams(filterConditions));
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel]);
  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <FormComponent
            onFinish={handleSearch}
            form={form}
            initialValues={{
              reportType: screen,
              doiTuong: defaultOptionValue,
              MaDonVi: user.user.unitCode,
            }}
          >
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="reportType"
                  label="Tên báo cáo"
                  labelAlign="left"
                  labelCol={{ span: 3 }}
                >
                  <Select
                    formKey="reportType"
                    form={form}
                    options={selectBaoCaoSMS}
                    onChange={() =>
                      handleChange(form.getFieldValue("reportType"))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="PhanLoai"
                  label="Phân loại"
                  labelCol={{ span: 3 }}
                  labelAlign="left"
                >
                  <Select defaultValue="2">
                    <Option value="all"> Tất cả</Option>
                    <Option value="2">Thông báo tiền điện lần 1</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="doiTuong"
                  label="Nội dung"
                  labelCol={{ span: 3 }}
                  labelAlign="left"
                >
                  <Selection
                    url={Endpoint.DOITUONG_SMS_TIEN_DIEN_L1}
                    formKey="doiTuong"
                    form={form}
                    defaultValue="Tất cả"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="TuNgay"
                  label="Ngày bắt đầu"
                  labelAlign="left"
                  labelCol={{ span: 9 }}
                >
                  <div style={{ marginLeft: "3%" }}>
                    <DatePickerComponent
                      formKey="TuNgay"
                      form={form}
                      format={FORMAT_ENGLISH}
                      defaultValue={
                        filterConditions.TuNgay
                          ? moment(filterConditions.TuNgay, FORMAT_ENGLISH)
                          : moment()
                      }
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="DenNgay"
                  label="Ngày kết thúc"
                  labelAlign="left"
                  labelCol={{ span: 11 }}
                >
                  <DatePickerComponent
                    formKey="DenNgay"
                    form={form}
                    format={FORMAT_ENGLISH}
                    defaultValue={
                      filterConditions.DenNgay
                        ? moment(filterConditions.DenNgay, FORMAT_ENGLISH)
                        : moment()
                    }
                  />
                </Form.Item>
              </Col>
              <Row justify="end">
                <Button
                  type="primary"
                  className="mr-5px"
                  htmlType="submit"
                  onClick={() => setType(ENUM_BUTTON_TYPE.TIM_KIEM)}
                >
                  Xem báo cáo
                </Button>
                <Button
                  type="primary"
                  className="mr-5px"
                  htmlType="submit"
                  onClick={() => setType(ENUM_BUTTON_TYPE.XUAT_EXCEL)}
                >
                  Xuất excel
                </Button>
                {/* <XuatExcel
                  endpoint={
                    Endpoint.B9_EXCEL +
                    "?" +
                    buildQueryString(parseParams(filterConditions))
                  }
                  text={"Xuất Excel"}
                  key={"xuat-excel"}
                /> */}
              </Row>
            </Row>
          </FormComponent>
          <Divider className="section-text-line" orientation="left"></Divider>
          <TableComponent
            header={fakeTableTitle}
            columns={columns}
            dataSource={data}
            totalCount={totalCount}
            loading={loading}
            pagination={filterConditions}
            onChangePagination={onChangePagination}
            scrollX={1400}
            filterConditions={filterConditions}
          />
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 10px 16px;
`;
