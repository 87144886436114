import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { authPostData } from "@utils/request";

export const handleSubmit = (
  setLoading,
  url,
  params,
  setFetchList,
  closeModal
) => {
  authPostData({
    url,
    setLoading,
    payload: params,
    method: METHOD_POST,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
        closeModal();
      }
    },
  });
};
