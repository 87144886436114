import { closeModal } from "@state/system-config/reducer";
import { Fragment, useMemo, useState } from "react";
import ModalNgungDiemDo from "./common/xoa-diem-do/modal-ngung-diem-do";
import ModalXoaDiemDo from "./common/xoa-diem-do/modal-xoa-diem-do";
import ListMenu from "./list-menu";
export default function ListChucNang(props) {
  const {
    visible,
    setDataDanhSach,
    detail,
    setLoadingDanhSach,
    getDanhSachDiemDo,
    result,
    getListIndex,
    setFilterConditions,
    filterConditions,
    isSuaPhuongThuc,
    setIndexPage,
    indexPage,
  } = props;
  const [visibleXoaDiemDo, setVisibleXoaDiemDo] = useState(false);
  const [visibleNgungDiemDo, setVisibleNgungDiemDo] = useState(false);
  // open modal xoa diem do
  const handlerOpenXoaDiemDo = () => {
    setVisibleXoaDiemDo(true);
  };

  const handlerNgungDiemDo = () => {
    setVisibleNgungDiemDo(true);
  };
  // close modal xoa diem do
  const handleCloseModal = () => {
    setVisibleXoaDiemDo(false);
    setVisibleNgungDiemDo(false);
  };

  // show modal xoa diem do
  const showModalXoaDiemDo = useMemo(
    () =>
      visibleXoaDiemDo && (
        <ModalXoaDiemDo
          visible={visibleXoaDiemDo}
          handleCloseModal={handleCloseModal}
          detail={detail}
          // setLoading={setLoading}
          setLoadingDanhSach={setLoadingDanhSach}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleXoaDiemDo]
  );
  // ngung diem do
  const showModalNgungDiemDo = useMemo(
    () =>
      visibleNgungDiemDo && (
        <ModalNgungDiemDo
          visible={visibleNgungDiemDo}
          handleCloseModal={handleCloseModal}
          detail={detail}
          setLoadingDanhSach={setLoadingDanhSach}
          getDanhSachDiemDo={getDanhSachDiemDo}
          setDataDanhSach={setDataDanhSach}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleNgungDiemDo]
  );
  return (
    <Fragment>
      <ListMenu
        detail={detail}
        visible={visible}
        closeModal={closeModal}
        result={result}
        setFilterConditions={setFilterConditions}
        filterConditions={filterConditions}
        getListIndex={getListIndex}
        getDanhSachDiemDo={getDanhSachDiemDo}
        setLoadingDanhSach={setLoadingDanhSach}
        setDataDanhSach={setDataDanhSach}
        handlerOpenXoaDiemDo={handlerOpenXoaDiemDo}
        handlerNgungDiemDo={handlerNgungDiemDo}
        isSuaPhuongThuc={isSuaPhuongThuc}
        setIndexPage={setIndexPage}
        indexPage={indexPage}
      />
      {showModalXoaDiemDo}
      {showModalNgungDiemDo}
    </Fragment>
  );
}
