import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Form } from "antd";

import LayoutPhieuTra from "./layout";
import { handleChiTietPhieuDuyet } from "../services";
import { callAPIXacNhanPhieu } from "../services";
import { closeModal } from "@state/system-config/reducer";
import { useDispatch } from "react-redux";

export default function Index(props) {
  const {
    type,
    setFetchList,
    selectRowKeys,
    typeLoaiPhieu,
    nguoiTao,
    maTrangThai,
  } = props;
  const [loading, setLoading] = useState([]);
  const [dataTH, setDataTH] = useState([]);
  const [dataVTTL, setDataVTTL] = useState([]);
  const [defaultInfo, setDefaultInfo] = useState({});
  const [form] = Form.useForm();
  const [isTraLai, setIsTraLai] = useState(false);
  const dispatch = useDispatch();
  //const [doi, setDoi] = useState([]);
  // const [nguoiTao, setNguoiTao] = useState([]);

  const columnsTH = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text, record, index) => (
        <div className="table-text-center">{index + 1}</div>
      ),
      width: "10%",
    },
    {
      title: "Mã công tơ",
      dataIndex: "maCongTo",
      key: "maCongTo",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "20%",
    },
    {
      title: "Loại công tơ",
      dataIndex: "loaiCongTo",
      key: "loaiCongTo",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "50%",
    },
    {
      title: "Số lượng thu hồi",
      dataIndex: "soLuongThuHoi",
      key: "soLuongThuHoi",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "20%",
    },
    // {
    //   title: "Tác vụ",
    //   render: (row) => (
    //     <div className="action-table-column">
    //       <div className="action-icon" onClick={() => handleDeleteData(row.id)}>
    //         <TrashIcon />
    //       </div>
    //     </div>
    //   ),
    //   width: "5%",
    // },
  ];

  const columnsTraLai = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text, record, index) => (
        <div className="table-text-center">{index + 1}</div>
      ),
      width: "10%",
    },
    {
      title: "Chủng loại",
      dataIndex: "chungLoai",
      key: "chungLoai",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "15%",
    },
    {
      title: "Loại công tơ",
      dataIndex: "loaiCongTo",
      key: "loaiCongTo",
      render: (text) => (
        <div className="table-text-left">{text}</div>
        // <Form.Item name={`loaiCongTo-${index}`}>
        //   <Select placeholder="Chọn loại công tơ">
        //     {loaiCongTo && loaiCongTo.length > 0
        //       ? loaiCongTo.map((item, index) => (
        //           <Option key={index} value={item.id}>
        //             {item.tenCongTo}
        //           </Option>
        //         ))
        //       : null}
        //   </Select>
        // </Form.Item>
      ),
      width: "30%",
    },
    {
      title: "Mô tả",
      dataIndex: "moTa",
      key: "moTa",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "30%",
    },
    {
      title: "Số lượng trả",
      dataIndex: "soLuongTra",
      key: "soLuongTra",
      render: (text) => (
        <div className="table-text-right">{text}</div>
        // <Form.Item name={`soLuongTra-${index}`}>
        //   <Input />
        // </Form.Item>
      ),
      width: "15%",
      editable: true,
    },
  ];

  //call api list vt thu hồi / danh sách yc có vật tư thu hồi
  const getListData = useCallback(() => {
    handleChiTietPhieuDuyet(
      selectRowKeys,
      setLoading,
      setDataTH,
      setDataVTTL,
      setDefaultInfo,
      typeLoaiPhieu
    );
  }, [selectRowKeys, typeLoaiPhieu]);

  useEffect(() => {
    getListData();
  }, [getListData]);

  //change trả lại
  const checkTraLai = (e) => {
    setIsTraLai(e.target.checked);
  };

  //submit dữ liệu
  const onFinishSubmit = (values) => {
    let payload = {};
    if (isTraLai) {
      payload = {
        id: selectRowKeys.toString(),
        loaiPhieuVatTu: typeLoaiPhieu,
        lyDoTraLai: values.lyDoTraLai,
      };
    } else {
      const data = [];
      // eslint-disable-next-line array-callback-return
      dataVTTL.map((item) => {
        data.push({
          chungLoaiId: item.chungLoaiId,
          chungLoai: item.chungLoai,
          soLuongTon: item.soLuongTon,
          soLuongTra: item.soLuongTra,
        });
      });
      payload = {
        id: selectRowKeys.toString(),
        vatTuTraLais: data,
      };
    }
    callAPIXacNhanPhieu(
      isTraLai,
      typeLoaiPhieu,
      payload,
      setLoading,
      setFetchList,
      form,
      dispatch,
      closeModal
    );
  };

  return (
    <Fragment>
      <LayoutPhieuTra
        loading={loading}
        form={form}
        onFinishSubmit={onFinishSubmit}
        type={type}
        checkTraLai={checkTraLai}
        dataTH={dataTH}
        columnsTH={columnsTH}
        dataVTTL={dataVTTL}
        nguoiTao={nguoiTao}
        isTraLai={isTraLai}
        selectRowKeys={selectRowKeys}
        defaultInfo={defaultInfo}
        setFetchList={setFetchList}
        columnsTraLai={columnsTraLai}
        maTrangThai={maTrangThai}
      />
    </Fragment>
  );
}
