import { Col } from "antd";
import styled from "styled-components";

export const ContainerStyled = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;
export const TableStyled = styled.div`
  .ant-table-tbody > tr > td {
    padding: 8px !important;
  }
  .ant-table-thead {
    .ant-table-cell {
      background: #d9d9d9;
      color: #1f1f1f;
      font-size: 13px;
    }
  }
  .ant-form-item {
    margin: 0;
  }
`;
export const Content = styled.div`
  border: 1px solid #e9e5e5;
  border-radius: 4px;
`;

export const Container = styled.div`
  padding: 20px;
  .ant-table-cell {
    padding: 8px !important;
  }

  .ant-table-content .ant-table-thead .ant-table-cell {
    text-align: center !important;
  }
`;
export const Title = styled.div`
  font-size: 20px;
  padding: 10px;
`;
export const SpanText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Item = styled.div`
  border: 1px solid #e9e5e5;
  border-radius: 4px;
  padding: 10px;
`;
export const TitleItem = styled.div`
  font-size: 20px;
  padding-top: 10px;
`;
export const ItemBtn = styled.div`
  display: flex;
  width: 92px;
`;

export const ContainerForm = styled.div`
  border: 1px solid #000;
  border-radius: 3px;
  padding: 10px 20px;

  h2 {
    margin: 0;
  }

  .ant-row.ant-form-item {
    margin: 0 0 10px;
  }

  .ant-col.ant-form-item-label {
    text-align: left;
  }
  .ant-table-cell {
    padding: 8px !important;
  }
`;
export const ThangLaySoLieu = styled.div`
  border: 1px solid #000;
  border-radius: 3px;
  padding: 10px 20px;
  display: flex;
  .ant-form-item {
    margin: 0;
  }
  .ant-table-cell {
    padding: 8px !important;
  }
`;
export const ColCheck = styled(Col)`
  display: flex;
  align-items: baseline;
  .label {
    margin-right: 20px;
    // width: 25%;
  }
  padding-bottom: 10px;

  .ant-row.ant-form-item {
    margin: 0;
    width: 100%;
  }

  .ant-col.ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-radio-group {
    display: flex;
  }
`;

export const ColButton = styled(Col)`
  text-align: right;

  button.btn-bg-yellow {
    margin-right: 24px;
  }

  @media screen and (max-width: 1400px) {
    button.btn-bg-yellow {
      margin-right: 4px;
    }
  }
`;

export const TableContainer = styled.div`
  @media screen and (max-width: 1440px) {
    .ant-table-thead .ant-table-cell {
      font-size: 14px !important;
      padding: 10px;
    }
  }
  .ant-table-tbody > tr > td {
    padding: 4px !important;
  }
`;
