import PencilIcon from "@assets/icon/PencilIcon";
import ButtonComponent from "@components/Button";
import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import TableComponent from "@components/TableComponent";
import { openModal } from "@state/system-config/reducer";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  METHOD_GET,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { formatDateWithTimezoneGB, handlePagination } from "@utils/function";
import { authGetData } from "@utils/request";
import { Button, Form, Select, Spin, Tooltip } from "antd";
import { Option } from "antd/lib/mentions";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import ModalEditCongSuat from "./modal/ModalEditCongSuat";
import ModalCapNhatNgayDuocTinh from "./modal/ModalEditNgayDuocTinh.jsx";
import { handleCreateData } from "./service";
export default function NgayDuocTinhDeDuaRaBDCoSo() {
  const [screen, setScreen] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const [visibleModalUpdate, setVisibleModalUpdate] = useState();
  const [detailTable, setDetailTable] = useState();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });
  const getListNgayDuocTinh = () => {
    authGetData({
      url: `${Endpoint.BC_NCPT_DANH_SACH_NGAY_DUOC_TINH_DE_DUA_RA_BIEU_DO}`,
      method: METHOD_GET,
      payload: {},
      setLoading,
      onSuccess: (res) => {
        setData(res.data);
      },
    });
  };

  useEffect(() => {
    getListNgayDuocTinh();
  }, []);

  //pagination table
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const onFinish = (values) => {
    const url = Endpoint.BC_NCPT_THEM_NGAY_DUOC_TINH_DE_DUA_RA_BIEU_DO_CO_SO;
    const [yearStart, monthStart, dayStart] = values.ngaytu.split("-");
    const ngaytu = [dayStart, monthStart, yearStart].join("/");
    const [yearEnd, monthEnd, dayEnd] = values.ngayden.split("-");
    const ngayden = [dayEnd, monthEnd, yearEnd].join("/");
    const payload = { ...values, ngaytu: ngaytu, ngayden: ngayden };
    handleCreateData(payload, setLoading, getListNgayDuocTinh, form, url);
  };

  const handleOpenModal = useCallback(
    (row) => {
      console.log(row);
      setDetailTable(row);
      setVisibleModalUpdate(true);
    },
    [visibleModalUpdate]
  );

  const handleCloseModal = () => {
    setVisibleModalUpdate(false);
    setLoading(false);
  };

  const showModal = useMemo(
    () =>
      visibleModalUpdate && (
        <ModalCapNhatNgayDuocTinh
          detailDataTable={detailTable}
          visible={visibleModalUpdate}
          closeModal={handleCloseModal}
          getListData={getListNgayDuocTinh}
        />
      ),
    [visibleModalUpdate, data, getListNgayDuocTinh]
  );

  const columns = [
    {
      title: "Từ ngày",
      dataIndex: "TU_NGAY",
      key: "TU_NGAY",
      align: "center",
      sorter: (a, b) => new Date(a.TU_NGAY) - new Date(b.TU_NGAY),
      render: (TU_NGAY) => {
        return <span>{formatDateWithTimezoneGB(TU_NGAY)}</span>;
      },
    },
    {
      title: "Đến ngày",
      dataIndex: "DEN_NGAY",
      key: "DEN_NGAY",
      align: "center",
      sorter: (a, b) => new Date(a.DEN_NGAY) - new Date(b.DEN_NGAY),
      render: (DEN_NGAY) => {
        return <span>{formatDateWithTimezoneGB(DEN_NGAY)}</span>;
      },
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "NGAY_CAPNHAT",
      key: "NGAY_CAPNHAT",
      align: "center",
      sorter: (a, b) => new Date(a.NGAY_CAPNHAT) - new Date(b.NGAY_CAPNHAT),
      render: (NGAY_CAPNHAT) => {
        return <span>{formatDateWithTimezoneGB(NGAY_CAPNHAT)}</span>;
      },
    },
    {
      title: "Hiệu lực",
      dataIndex: "HIEU_LUC1",
      key: "HIEU_LUC1",
      align: "center",
    },
    {
      title: "Loại ngày",
      dataIndex: "LOAI_NGAY1",
      key: "LOAI_NGAY1",
      align: "center",
    },
    {
      title: "Tác vụ",
      width: 100,
      fixed: "right",
      render: (row) => (
        <div className="action-table-column">
          <Tooltip title={"Sửa"}>
            <ButtonComponent
              type="link"
              onClick={() => handleOpenModal(row)}
              className="pd-5px"
            >
              <PencilIcon />
            </ButtonComponent>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <Container>
        {showModal}
        <ContentWrapper>
          <Spin spinning={loading}>
            <Title>Ngày được tính để đưa ra biểu đồ cơ sở</Title>
            <FormComponent
              onFinish={onFinish}
              initialValues={{ reportType: screen }}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              colon={false}
            >
              <Form.Item
                name="ngaytu"
                label="Ngày bắt đầu"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  formKey="ngaytu"
                  form={form}
                  picker="day"
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>

              <Form.Item
                name="ngayden"
                label="Ngày kết thúc"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  formKey="ngayden"
                  form={form}
                  picker="day"
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>

              <Form.Item
                name="loaingay"
                label="Loại ngày"
                labelAlign="left"
                required
              >
                <Select placeholder="Chọn loại ngày">
                  <Option value="0">Ngày thường</Option>
                  <Option value="1">Ngày nghỉ</Option>
                </Select>
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginBottom: "20px" }}
              >
                Cập nhật
              </Button>
            </FormComponent>

            <TableComponent
              header="Thông tin hiển thị"
              columns={columns}
              dataSource={data}
              pagination={filterConditions}
              onChangePagination={onChangePagination}
            />
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}
const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;

const Title = styled.div`
  font-size: 20px;
  padding-bottom: 25px;
  color: red;
`;
