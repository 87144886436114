import React, { useState } from "react";
import "moment/locale/vi";
import UILuuYThiCong from "./ui-panels/luu-y-thi-cong";

export default function LuuYThiCongPanels(props) {
  const { form, mode } = props;
  const [validateFieldsName, setValidateFieldsName] = useState([]);

  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return "onChange";
    return "onBlur";
  };
  return (
    <UILuuYThiCong
      handleValidateFieldNames={handleValidateFieldNames}
      form={form}
      setValidateFieldsName={setValidateFieldsName}
      validateFieldsName={validateFieldsName}
      mode={mode}
    />
  );
}
