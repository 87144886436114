import ButtonComponent from "@components/Button";
import Selection from "@components/Select";
import { FORMAT_MONTH, METHOD_GET } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData } from "@utils/request";
import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import { Fragment, useCallback, useEffect, useState } from "react";
import CheckPermission from "@components/CheckPermission";
import { cham_soc_khach_hang } from "@permissions/j_cskh";
import SelectionSo from "../../common/SelectSo";


export function FillterKySoTaoFile(props) {
  const {
    filterConditions,
    setFilterConditions,
    setLoading,
    handelCreateFile,
    setDoituongId,
    doituongId,
    handelDeleteFile,
    setData,
    setUrlDataKH,
    form,
    urlDataKH,
  } = props;
  const [urlMaSo, setUrlMaSo] = useState("");
  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    authGetData({
      url: `${Endpoint.CSKH_DOITUONG}`,
      method: METHOD_GET,
      setLoading,
      onSuccess: (res) => {
        setData(res);
      },
    });
  }, [setLoading, setData]);
  const handleChangeToDoi = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      ma_to_1: values,
      p_ma_sogcs: [],
    }));
    form.setFieldsValue({"DanhSachSo": undefined})
    setIsDisable(false)
  };
  const handleChangeKy = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      p_ky: values,
    }));
  };
  const handleChangeDoiTuong = (values) => {
    setDoituongId(values);
    setFilterConditions((oldState) => ({
      ...oldState,
      doiTuongId: values,
      p_ma_sogcs: [],
    }));
    form.setFieldsValue({"DanhSachSo": undefined})
    setIsDisable(false)
  };

  useEffect(() => {
    const maSoValue = {
      TBNGUNGCCD: "e12382fc-5ebb-474d-a1a7-de277b424031",
      TBTIENDIENL2: "e12382fc-5ebb-474d-a1a7-de277b424030",
    };
    const queryMaSo = buildQueryString(
      parseParams({
        doiTuongId: filterConditions?.doiTuongId,
        id_hinhthuc: 5,
        ma_to_1: filterConditions?.ma_to_1,
        nam_cu: filterConditions?.nam_cu,
        nam_ht: filterConditions?.nam_ht,
        p_ky: filterConditions?.p_ky,
        p_nam: filterConditions?.p_nam,
        p_thang: filterConditions?.p_thang,
        thang_cu: filterConditions?.thang_cu,
        thang_ht: filterConditions?.thang_ht,
        Loai_cto: filterConditions?.Loai_cto,
        p_TrangThaiID: filterConditions?.p_TrangThaiID,
      })
    );
    if (
      doituongId === maSoValue.TBNGUNGCCD &&
      filterConditions.ma_to_1 &&
      filterConditions.p_ky &&
      filterConditions.p_nam
    ) {
      setUrlMaSo(`${Endpoint.CSKH_DSS_TB_NGUNG_CAP_DIEN}?${queryMaSo}`);
      setUrlDataKH(`${Endpoint.CSKH_DSkh_TB_NGUNG_CAP_DIEN}`);
    }
    if (
      doituongId === maSoValue.TBTIENDIENL2 &&
      filterConditions.ma_to_1 &&
      filterConditions.p_ky &&
      filterConditions.p_nam
    ) {
      setUrlMaSo(`${Endpoint.CSKH_DSS_TBTD_LAN_2}?${queryMaSo}`);
      setUrlDataKH(`${Endpoint.CSKH_DSkh_TBTD_LAN_2}`);
    }
  }, [doituongId, urlDataKH, filterConditions, setUrlDataKH]);

  const handleNgayCatDien = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      ngayCatDien: values,
    }));
  };
  const handleNgayDSSO = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        p_ma_sogcs: values,
      }));
    },
    [setFilterConditions]
  );
  const handleChangeGio = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      gio: parseInt(e.target.value),
    }));
  };
  const handleChangePhut = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      phut: parseInt(e.target.value),
    }));
  };
  const hanldeHanThanhToan = (dates, dateStrings) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      hanThanhToan: dateStrings,
    }));
  };
  const hanldeHanThangNam = (dates, dateStrings) => {
    const dateCu = moment(dates._d).subtract(1, "months");
    setFilterConditions((state) => ({
      ...state,
      nam_ht: parseInt(dateStrings.split("/")[1]),
      nam_cu: parseInt(dateCu.year().toString()),
      thang_ht: parseInt(dateStrings.split("/")[0]),
      thang_cu: parseInt(`${dateCu.month() + 1}`),
      p_nam: parseInt(dateStrings.split("/")[1]),
      p_thang: parseInt(dateStrings.split("/")[0]),
    }));
  };
  const options = [
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3",
      value: 3,
    },
  ];

  return (
    <>
      <Fragment>
        <Form
          form={form}
          layout="vertical"
          className="form-content"
          onFinish={handelCreateFile}
        >
          <Row gutter={26}>
            <div style={{ paddingBottom: 20, width: "100%", display: "flex" }}>
              <Col span={12}>
                <Form.Item
                  name="DoiTuongId"
                  label="Đối tượng"
                  labelAlign="left"
                  rules={[
                    {
                      required: true,
                      message: "Danh sách sổ không được để trống",
                    },
                  ]}
                >
                  <Selection
                    url={Endpoint.LIST_DOI_TUONG_KY_SO}
                    placeholder="Chọn đối tượng"
                    formKey="DoiTuongId"
                    form={form}
                    setValue={handleChangeDoiTuong}
                  />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item name="ky" label="Kỳ" labelAlign="left">
                  {form.setFieldsValue({
                    [`ky`]: 1,
                  })}
                  <Select
                    options={options}
                    defaultValue={1}
                    onChange={handleChangeKy}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="ThangNam"
                  label="Tháng/Năm"
                  labelAlign="left"
                  rules={[
                    {
                      required: true,
                      message: "Tháng năm không được để trống",
                    },
                  ]}
                >
                  {form.setFieldsValue({
                    [`ThangNam`]: moment(),
                  })}
                  <DatePicker
                    picker="month"
                    formKey="ThangNam"
                    onChange={hanldeHanThangNam}
                    format={FORMAT_MONTH}
                    defaultValue={moment()}
                    form={form}
                  />
                </Form.Item>
              </Col>
            </div>
          </Row>
          <Row gutter={24}>
            <div style={{ paddingBottom: 20, width: "100%", display: "flex" }}>
              {doituongId === "e12382fc-5ebb-474d-a1a7-de277b424031" ? (
                <>
                  <Col span={5}>
                    <Form.Item
                      name="NgayGioCatDien"
                      label="Ngày cắt điện"
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: "Ngày cắt điện không được để trống",
                        },
                      ]}
                    >
                      <DatePicker
                        formKey="NgayGioCatDien"
                        form={form}
                        onChange={handleNgayCatDien}
                        format={"DD-MM-YYYY"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item label="Giờ" name="Gio" className="hours-filed">
                      {form.setFieldsValue({
                        [`Gio`]: 8,
                      })}
                      <Input
                        type="number"
                        min={0}
                        max={23}
                        placeholder="Nhập giờ"
                        defaultValue={8}
                        onChange={handleChangeGio}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label="Phút"
                      name="Phut"
                      className="minute-field"
                    >
                      {form.setFieldsValue({
                        [`Phut`]: 0,
                      })}
                      <Input
                        type="number"
                        min={0}
                        defaultValue={0}
                        max={59}
                        placeholder="Nhập phút"
                        onChange={handleChangePhut}
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : null}

              {doituongId === "e12382fc-5ebb-474d-a1a7-de277b424030" ? (
                <Col span={5}>
                  <Form.Item
                    name="HanThanhToan"
                    label="Hạn thanh toán"
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: "Hạn thanh toán không được để trống",
                      },
                    ]}
                  >
                    <DatePicker
                      formKey="HanThanhToan"
                      form={form}
                      onChange={hanldeHanThanhToan}
                      format={"DD-MM-YYYY"}
                    />
                  </Form.Item>
                </Col>
              ) : null}
            </div>
          </Row>
          <Row></Row>
          <Row>
            <Col span={5} style={{ paddingRight: "20px" }}>
              <Form.Item
                name="ToDoiId"
                label="Tổ đội"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Tổ đội không được để trống",
                  },
                ]}
              >
                <Selection
                  url={Endpoint.TODOI_LOGIN}
                  placeholder="Chọn tổ đội"
                  formKey="ToDoiId"
                  setValue={handleChangeToDoi}
                  form={form}
                />
              </Form.Item>
            </Col>
            <Col span={13}>
              <Form.Item
                name="DanhSachSo"
                label="Danh sách sổ"
                rules={[
                  {
                    required: true,
                    message: "Danh sách sổ không được để trống",
                  },
                ]}
              >
                {doituongId &&
                doituongId.length > 0 &&
                filterConditions?.ma_to_1 ? (
                  <SelectionSo
                    url={urlMaSo}
                    formKey="DanhSachSo"
                    placeholder="Chọn danh sách sổ"
                    form={form}
                    loading
                    mode={"multiple"}
                    setValue={handleNgayDSSO}
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : (
                  <Select disabled />
                )}
              </Form.Item>
            </Col>
           

            <Col offset={1} span={5} style={{ paddingTop: "30px" }}>
              <CheckPermission
                permissionCode={cham_soc_khach_hang.j11h}
              >
                <ButtonComponent
                style={{ marginRight: "26px" }}
                  type="danger"
                  onClick={handelDeleteFile}
                  size="default"
                >
                  Hủy tạo file
                </ButtonComponent>
              </CheckPermission>
              <CheckPermission permissionCode={cham_soc_khach_hang.j11t}>
                <ButtonComponent
                  type="primary"
                  htmlType="submit"
                  size="default"
                >
                  Tạo File
                </ButtonComponent>
              </CheckPermission>
            </Col>

            {doituongId === "e12382fc-5ebb-474d-a1a7-de277b424031" ? (
              <div style={{ paddingTop: 30, color: "red", fontSize: 16 }}>
                Lưu ý: Kiểm tra những trường hợp Khách hàng chưa gửi tin nhắn
                thông báo tiền điện lần 2 trước khi thực hiện gửi thông báo
                ngừng cấp điện!
              </div>
            ) : null}
          </Row>
        </Form>
      </Fragment>
    </>
  );
}
