import { Col, Form, Row, Button, Select } from "antd";
import { Endpoint } from "@utils/endpoint";
import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import moment from "moment";
import {
  ENUM_BUTTON_TYPE,
  FORMAT_DATE,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import { removeAccents } from "@utils/function";
// import ExportExecel from "../../common-ver1/xuat-excel";
// import { treo_thao } from "@permissions/b_treothao";
import Selection from "@components/Select";
import { useCallback, useState } from "react";
import CheckPermission from "@components/CheckPermission";
import { treo_thao } from "@permissions/b_treothao";

const { Option } = Select;

const dataSoPha = [
  {
    value: null,
    name: "Tất cả",
  },
  {
    value: 1,
    name: "1 Pha",
  },
  {
    value: 3,
    name: "3 Pha",
  },
];

const loaiTreoThao = [
  {
    value: 0,
    name: "Treo mới",
  },
  {
    value: 1,
    name: "Định kỳ",
  },
  {
    value: 2,
    name: "Thay thế cháy hỏng",
  },
  {
    value: 3,
    name: "Tháo thu hồi",
  },
];

export default function FormBoLoc(props) {
  const {
    form,
    handleSearch,
    loaiTT,
    filterConditions,
    clear,
    user,
    donVi,
    setType,
  } = props;
  const [isTongCongTy, setIsTongCongTy] = useState(user.isTongCongTy);

  //tìm kiếm tổng công ty của select đơn vị
  const handleFindUnit = useCallback(
    (donViId) => {
      const unit = donVi.find((item) => item.id === donViId);
      if (unit !== undefined) {
        setIsTongCongTy(unit.isTongCongTy);
      }
    },
    [donVi, setIsTongCongTy]
  );

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      autoComplete="off"
      initialValues={{}}
    >
      <Row gutter={24}>
        <Col span={24} md={8}>
          <Form.Item name="donViId" label="Đơn vị">
            <Select
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
              disabled={!user.isTongCongTy ? true : false}
              onChange={handleFindUnit}
            >
              {donVi && donVi.length > 0
                ? donVi.map((item, index) => (
                    <Option key={index} value={item.id.toString()}>
                      {item.tenDonVi}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.donViId !== currentValues.donViId) {
                form.setFieldsValue({
                  doiId: undefined,
                });
              }
              return prevValues.donViId !== currentValues.donViId;
            }}
          >
            {({ getFieldValue }) => {
              if (getFieldValue("donViId")) {
                return (
                  <Form.Item name="doiId" label="Đội">
                    <Selection
                      url={`${Endpoint.GET_TEAM_LIST}?donviId=${getFieldValue(
                        "donViId"
                      )}`}
                      formKey="doiId"
                      form={form}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="doiId" label="Đội">
                  <Selection
                    url={`${Endpoint.GET_TEAM_LIST}?donviId=${user.unitId}`}
                    formKey="doiId"
                    form={form}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.donViId !== currentValues.donViId) {
                form.setFieldsValue({
                  tramId: undefined,
                });
              }
              return prevValues.donViId !== currentValues.donViId;
            }}
          >
            {({ getFieldValue }) => {
              if (getFieldValue("donViId")) {
                return (
                  <Form.Item name="tramId" label="Trạm">
                    <Selection
                      url={`${
                        Endpoint.COMMON_LIST_TRAM_TREO_THAO
                      }?donviId=${getFieldValue("donViId")}`}
                      formKey="tramId"
                      form={form}
                      disabled={isTongCongTy}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="tramId" label="Trạm">
                  <Selection
                    url={`${Endpoint.COMMON_LIST_TRAM_TREO_THAO}?donviId=${user.unitId}`}
                    formKey="tramId"
                    form={form}
                    disabled={isTongCongTy}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item name="loaiTreoThao" label="Loại treo tháo">
            <Select placeholder="Tất cả" allowClear>
              {loaiTT && loaiTT.length > 0
                ? loaiTT.map((item, index) => {
                    if (
                      item.value === null ||
                      item.value === 4 ||
                      item.value === 5 ||
                      item.value === 6
                    ) {
                      return null;
                    } else {
                      return (
                        <Option key={index} value={item.value.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item name="soPha" label="Số pha">
            <Select
              placeholder="Tất cả"
              allowClear
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {dataSoPha.map((item, index) => {
                if (item.value !== null) {
                  return (
                    <Option key={index} value={item.value.toString()}>
                      {item.name}
                    </Option>
                  );
                }
                return null;
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item name="tuNgay" label="Từ ngày">
            <DatePickerComponent
              defaultValue={
                filterConditions.tuNgay
                  ? moment(filterConditions.tuNgay, FORMAT_ENGLISH)
                  : moment().startOf(MONTH_STRING)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"tuNgay"}
              notClear={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item name="denNgay" label="Đến ngày">
            <DatePickerComponent
              defaultValue={
                filterConditions.denNgay
                  ? moment(filterConditions.denNgay, FORMAT_ENGLISH)
                  : moment(moment(), FORMAT_DATE)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"denNgay"}
              notClear={true}
            />
          </Form.Item>
        </Col>
        <Col
          span={24}
          md={9}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button className="mr-24px" onClick={clear}>
            Bỏ lọc
          </Button>
          <Button
            type="primary"
            className="mr-24px"
            htmlType="submit"
            onClick={() => setType(ENUM_BUTTON_TYPE.TIM_KIEM)}
            form="filter-form"
          >
            Tìm kiếm
          </Button>
          <CheckPermission permissionCode={treo_thao.b82x}>
            <Button
              type="primary"
              htmlType="submit"
              form="filter-form"
              onClick={() => setType(ENUM_BUTTON_TYPE.XUAT_EXCEL)}
            >
              Xuất excel
            </Button>
          </CheckPermission>

          {/* <ExportExecel
            endpoint={`${
              Endpoint.EXPORT_EXCEL_HAN_KIEM_DINH_CT
            }?${buildQueryString(parseParams(filterConditions))}`}
            permissionCode={treo_thao.b81x}
            text={"Xuất excel"}
            key={"xuat-excel"}
          /> */}
        </Col>
      </Row>
    </FormComponent>
  );
}
