import ButtonComponent from "@components/Button";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { systemConfigSelector } from "@state/system-config/reducer";
import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { parseParams, removeUndefinedAttribute } from "@utils/function";
import { authPostData } from "@utils/request";
import { Form, Spin } from "antd";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import MenuList from "./MenuList";
import { authSelector, getMenuList } from "@state/auth/reducer";
import { getMenuListToCustom } from "./services";
export default function QuanLyMenu() {
  const dispatch = useDispatch();
  const { moduleId } = useSelector(authSelector);
  const { collapsed } = useSelector(systemConfigSelector);
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [moduleSelected, setModuleSelected] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    if (location.search) {
      const params = parseParams(location.search);
      if (params.moduleId) {
        getMenuListToCustom(params.moduleId, setLoading, setDataSource);
        setModuleSelected(params.moduleId);
        form.setFieldsValue({
          moduleId: params.moduleId,
        });
      }
    } else {
      setModuleSelected(null);
      form.setFieldsValue({
        moduleId: null,
      });
    }
  }, [location, form]);
  const handleSelectModule = useCallback(
    (value) => {
      if (value) {
        getMenuListToCustom(value, setLoading, setDataSource);
      }
      setModuleSelected(value);
      setSearchParams(removeUndefinedAttribute({ moduleId: value }));
    },
    [setSearchParams]
  );
  const save = useCallback(
    (newMenu) => {
      authPostData({
        url: `${Endpoint.MENU}`,
        method: "POST",
        payload: {
          data: newMenu,
          moduleId: moduleSelected,
        },
        setLoading,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            if (moduleId === moduleSelected) {
              dispatch(getMenuList({ moduleId }));
            }
            getMenuListToCustom(moduleSelected, () => {}, setDataSource);
          }
        },
      });
    },
    [moduleSelected, dispatch, moduleId]
  );
  return (
    <Fragment>
      <Spin spinning={loading}>
        <Container>
          <FormComponent layout="vertical" form={form}>
            <Form.Item name="moduleId" label="Module">
              <Selection
                url={Endpoint.LIST_SELECT_MODULE}
                setValue={handleSelectModule}
                form={form}
                formKey="moduleId"
              />
            </Form.Item>
          </FormComponent>
          <MenuList
            moduleSelected={moduleSelected}
            save={save}
            dataSource={dataSource}
            setDataSource={setDataSource}
          />
        </Container>

        <FooterContainer
          className={`${collapsed ? "collapsed-active" : "collapsed-unactive"}`}
        >
          <div className="modal-footer">
            <ButtonComponent
              type="primary"
              onClick={() => save(dataSource)}
              htmlType="submit"
              disabled={!moduleSelected}
            >
              Lưu
            </ButtonComponent>
          </div>
        </FooterContainer>
      </Spin>
    </Fragment>
  );
}
const Container = styled.div`
  padding: 10px 10px 96px 10px;
`;
const FooterContainer = styled.div`
  .modal-footer {
    width: 100%;
  }
`;
