import { memo, Fragment } from "react";
import { Table, ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import LoadingComponent from "@components/Loading";
import { ContainerTab } from "../css-styled";
import vn from "antd/es/locale-provider/vi_VN";

function Index({ dataNhatKiHoSo, loading }) {
  const { t } = useTranslation();
  //khai bái columns

  const columns = [
    {
      title: t("ap_gia_ho_so.stt"),
      dataIndex: "stt",
      key: "stt",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "10%",
    },
    {
      title: t("duyet_ho_so.ngay"),
      dataIndex: "ngay",
      key: "ngay",
      width: "15%",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: t("duyet_ho_so.nguoi_dung"),
      dataIndex: "nguoiDung",
      key: "nguoiDung",
      width: "18%",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: t("duyet_ho_so.bo_phan"),
      dataIndex: "boPhan",
      key: "boPhan",
      width: "17%",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: t("duyet_ho_so.noi_dung"),
      dataIndex: "noiDung",
      key: "noiDung",
      width: "40%",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <LoadingComponent loading={loading}>
        <ConfigProvider locale={vn}>
          <ContainerTab>
            <Table
              dataSource={dataNhatKiHoSo}
              columns={columns}
              pagination={false}
              scroll={{ y: 700, x: 800 }}
            />
          </ContainerTab>
        </ConfigProvider>
      </LoadingComponent>
    </Fragment>
  );
}

export default memo(Index);
