import PencilIcon from "@assets/icon/PencilIcon";
import ButtonComponent from "@components/Button";
import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import TableComponent from "@components/TableComponent";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  METHOD_GET,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { formatDateWithTimezoneGB, handlePagination } from "@utils/function";
import { authGetData } from "@utils/request";
import { Button, Form, InputNumber, Spin, Tooltip } from "antd";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import ModalCapNhatCongSuatDYCTGEVNHN from "./modal/ModalEditCongSuat";
import { handleCreateData } from "./service";
export default function CongSuatDuocYCSeTietGiamEVNHaNoi() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [detailTable, setDetailTable] = useState();
  const [visibleModalUpdate, setVisibleModalUpdate] = useState();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });
  //pagination table
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const handleOpenModal = useCallback(
    (row) => {
      setDetailTable(row);
      setVisibleModalUpdate(true);
    },
    [visibleModalUpdate]
  );

  const handleCloseModal = () => {
    setVisibleModalUpdate(false);
    setLoading(false);
  };

  const getListDataCongSuat = () => {
    authGetData({
      url: `${Endpoint.BC_NCPT_CONG_SUAT_YEU_CAU}`,
      method: METHOD_GET,
      payload: {},
      setLoading,
      onSuccess: (res) => {
        setData(res.data);
      },
    });
  };

  useEffect(() => getListDataCongSuat(), []);

  const onFinish = (values) => {
    const url = Endpoint.BC_NCPT_THEM_CONG_SUAT_YEU_CAU;
    const [year, month, day] = values.ngaytietgiam.split("-");
    const ngaytietgiam = [day, month, year].join("/");
    const payload = { ...values, ngaytietgiam: ngaytietgiam };
    handleCreateData(payload, setLoading, getListDataCongSuat, form, url);
  };

  const showModal = useMemo(
    () =>
      visibleModalUpdate && (
        <ModalCapNhatCongSuatDYCTGEVNHN
          detailDataTable={detailTable}
          visible={visibleModalUpdate}
          closeModal={handleCloseModal}
          getListData={getListDataCongSuat}
        />
      ),
    [visibleModalUpdate, data, getListDataCongSuat]
  );

  const columns = [
    {
      title: "Ngày tiết giảm",
      dataIndex: "NGAYTIETGIAM",
      key: "NGAYTIETGIAM",
      align: "center",
      width: "15%",
      sorter: (a, b) => new Date(a.NGAYTIETGIAM) - new Date(b.NGAYTIETGIAM),
      render: (NGAYTIETGIAM) => {
        return <span>{formatDateWithTimezoneGB(NGAYTIETGIAM)}</span>;
      },
    },
    {
      title: "Công suất tiết giảm",
      dataIndex: "CONGSUAT_TIETGIAM",
      key: "CONGSUAT_TIETGIAM",
      sorter: (a, b) => a.CONGSUAT_TIETGIAM - b.CONGSUAT_TIETGIAM,
      align: "center",
    },
    {
      title: "Ca 1 (22h-6h)",
      dataIndex: "CA1",
      key: "CA1",
      sorter: (a, b) => a.CA1 - b.CA1,
      align: "center",
    },
    {
      title: "Ca 2 (6h-14h)",
      dataIndex: "CA2",
      key: "CA2",
      sorter: (a, b) => a.CA2 - b.CA2,
      align: "center",
    },
    {
      title: "Ca 3 (14h-22h)",
      dataIndex: "CA3",
      key: "CA3",
      sorter: (a, b) => a.CA3 - b.CA3,
      align: "center",
    },
    {
      title: "Tác vụ",
      width: 100,
      fixed: "right",
      render: (row) => (
        <div className="action-table-column">
          <Tooltip title={"Sửa"}>
            <ButtonComponent
              type="link"
              onClick={() => handleOpenModal(row)}
              className="pd-5px"
            >
              <PencilIcon />
            </ButtonComponent>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <Container>
        {showModal}
        <ContentWrapper>
          <Spin spinning={loading}>
            <Title>
              Cập nhật công suất được yêu cầu sẽ tiết giảm của EVNHANOI
            </Title>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              colon={false}
            >
              <Form.Item
                name="ngaytietgiam"
                label="Ngày tiết giảm:"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  formKey="ngaytietgiam"
                  form={form}
                  picker="day"
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>
              <Form.Item
                name="csuat_tietgiam"
                label="Công suất tiết giảm:"
                labelAlign="left"
                required
              >
                <InputNumber placeholder="Nhập công suất tiết giảm"></InputNumber>
              </Form.Item>
              <Form.Item
                name="ca1"
                label="Ca 1 (22h-6h):"
                labelAlign="left"
                required
              >
                <InputNumber placeholder="Nhập ca 1"></InputNumber>
              </Form.Item>
              <Form.Item
                name="ca2"
                label="Ca 2 (6h-14h):"
                labelAlign="left"
                required
              >
                <InputNumber placeholder="Nhập ca 2"></InputNumber>
              </Form.Item>
              <Form.Item
                name="ca3"
                label="Ca 3 (14h-22h):"
                labelAlign="left"
                required
              >
                <InputNumber placeholder="Nhập ca 3"></InputNumber>
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginBottom: "20px" }}
              >
                Cập nhật
              </Button>
            </FormComponent>
            <TableComponent
              header="Thông tin hiển thị"
              columns={columns}
              dataSource={data}
              pagination={filterConditions}
              onChangePagination={onChangePagination}
            />
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}
const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;

const Title = styled.div`
  font-size: 20px;
  padding-bottom: 25px;
  color: red;
`;
