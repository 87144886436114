import { authSelector } from "@state/auth";
import { Form } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { handleMultiBoLoc } from "./services";
import FormBoLoc from "./form-bo-loc";

export default function ListFilter(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    tooltip,
    newFilter,
    handleChange,
    value,
    setType,
  } = props;
  const { user } = useSelector(authSelector);
  const [loading, setLoading] = useState(false);
  const [loaiYeuCau, setLoaiYeuCau] = useState([]);
  const [loaiHS, setLoaiHS] = useState([]);
  const [mucDich, setMucDich] = useState([]);
  const [soPha, setSoPha] = useState([]);
  const [donVi, setDonVi] = useState([]);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  useEffect(() => {
    handleMultiBoLoc(
      setLoading,
      setLoaiHS,
      setLoaiYeuCau,
      setMucDich,
      setSoPha,
      setDonVi
    );
  }, []);

  return (
    <FormBoLoc
      form={form}
      handleSearch={handleSearch}
      user={user}
      loading={loading}
      loaiHS={loaiHS}
      loaiYeuCau={loaiYeuCau}
      mucDich={mucDich}
      soPha={soPha}
      donVi={donVi}
      tooltip={tooltip}
      clear={clear}
      filterConditions={filterConditions}
      newFilter={newFilter}
      handleChange={handleChange}
      value={value}
      setType={setType}
    />
  );
}
