import React, { useEffect, useState, useCallback } from "react";
import { Form } from "antd";
import moment from "moment";
import "moment/locale/vi";
import {
  FORMAT_DATE,
  FORMAT_ENGLISH,
  TYPEPOPUP_TRONGAI,
} from "@utils/constants";
import { useTranslation } from "react-i18next";
import { formatYYYYMMDDString } from "@utils/function";
import UITroNgaiKhaoSat from "./ui-modal/tro-ngai-khao-sat";
import {
  handleListLoaiTroNgai,
  handleNgayHenKS,
  handleSubmitTroNgaiKS,
} from "../../services";

function TroNgaiKhaoSatModal({
  visible,
  closeModal,
  funcID,
  dataTroNgai,
  loading,
  setFetchDataTable,
  detail,
  title,
  text,
  type,
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loadingTenTroNgai, setLoadingTenTroNgai] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [data, setData] = useState([]);
  const toDay = moment().format(FORMAT_DATE);
  const [ngay, setNgay] = useState(toDay);
  const [disable, setDisable] = useState(false);
  const [, setLoading] = useState(false);

  const handleGetNgayKS = useCallback(() => {
    handleNgayHenKS(detail, setLoading, setNgay);
  }, [detail]);

  useEffect(() => {
    if (type === TYPEPOPUP_TRONGAI.TYPE_NVKS) handleGetNgayKS();
  }, [handleGetNgayKS, type]);

  //submit dữ liệu

  const handleSubmit = (values) => {
    const data = {
      ...values,
    };
    const ngayHen = moment(values.ngayHen ? values.ngayHen : ngay);

    data.ngayHen = formatYYYYMMDDString(
      ngayHen.year(),
      ngayHen.month(),
      ngayHen.date()
    );

    if (disable) {
      delete data.ngayHen;
    }

    data.hoSoId = detail.id;
    data.funcId = funcID;

    handleSubmitTroNgaiKS(
      data,
      setLoadingSubmit,
      setFetchDataTable,
      closeModal,
      form
    );
  };
  const handleChangeLoaiTroNgai = (value) => {
    handleListLoaiTroNgai(
      funcID,
      value,
      setLoadingTenTroNgai,
      setData,
      form,
      dataTroNgai,
      setDisable
    );
  };
  const handleChangeNgay = (date) => {
    let convert = "";
    if (date === null) convert = null;
    else convert = moment(date).format(FORMAT_ENGLISH);
    setNgay(convert);
  };
  return (
    <UITroNgaiKhaoSat
      loadingSubmit={loadingSubmit}
      visible={visible}
      closeModal={closeModal}
      form={form}
      handleSubmit={handleSubmit}
      ngay={ngay}
      handleChangeLoaiTroNgai={handleChangeLoaiTroNgai}
      dataTroNgai={dataTroNgai}
      loadingTenTroNgai={loadingTenTroNgai}
      data={data}
      handleChangeNgay={handleChangeNgay}
      disable={disable}
      loading={loading}
      t={t}
      title={title}
      text={text}
    />
  );
}

export default TroNgaiKhaoSatModal;
