import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import UIXacNhanNghiemThu from "./ui-modal/xac-nhan-nghiem-thu";
import { handleSubmitXacNhan } from "../services";
import { handleMultiNhanVien } from "../services";

export default function XacNhanNghiemThuModal({
  visibleXacNhan,
  handleCloseModalXacNhan,
  setFetchDataTable,
  detail,
  setVisibleXacNhanNghiemThu,
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dataTreoThao, setDataTreoThao] = useState({});
  const [dataNiemPhong, setDataNiemPhong] = useState({});
  //submit xác nhận nghiệm thu

  const onFinishNghiemThu = () => {
    handleSubmitXacNhan(
      setLoading,
      detail,
      setFetchDataTable,
      form,
      setVisibleXacNhanNghiemThu
      // dataTreoThao,
      // dataNiemPhong
    );
  };
  useEffect(() => {
    if (detail && detail.id)
      handleMultiNhanVien(
        setLoading,
        setDataTreoThao,
        setDataNiemPhong,
        detail
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UIXacNhanNghiemThu
      t={t}
      visibleXacNhan={visibleXacNhan}
      handleCloseModalXacNhan={handleCloseModalXacNhan}
      form={form}
      dataTreoThao={dataTreoThao}
      dataNiemPhong={dataNiemPhong}
      onFinishNghiemThu={onFinishNghiemThu}
      loading={loading}
    />
  );
}
