import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { dateConditionLogic } from "@modules/tich-hop-CRM/common-ho-so/functions";
import { closeModal, openModal } from "@state/system-config/reducer";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { Button, Form } from "antd";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import ThongTinChiTietTabs from "../../common-ho-so/chi-tiet-yeu-cau/index";
import { Style } from "../../css-styled";
import CRMSearchFilter from "./bo-loc-tra-cuu";

import { getLinhVucList, getListTrangThai } from "./services";
function TraCuuYeuCau() {
  const [form] = Form.useForm();
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const donViId = useSelector((state) => state.auth.user.unitId);
  const [isDoubleClick] = useState(true);
  const dispatch = useDispatch();
  const [, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DenNgay: `${moment().format("YYYY-MM-DD")}`,
    TuNgay: `${moment().subtract(7, "d").format("YYYY-MM-DD")}`,
    DonViId: donViId,
    ...parseParams(location.search),
  });
  const [listTrangThai, setListTrangThai] = useState([]);
  const [linhVucList, setLinhVucList] = useState([]);

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      width: "80px",
      align: "center",
    },
    {
      title: "Mã yêu cầu",
      dataIndex: "maYeuCau",
      align: "center",
      width: "80px",
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      align: "left",
      width: "100px",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "tenKhachHang",
      width: "100px",
      align: "left",
    },
    {
      title: "Kết quả xử lý",
      dataIndex: "ketQuaXuLy",
      width: "150px",
      align: "left",
    },
    {
      title: "Ngày yêu cầu",
      dataIndex: "ngayYeuCau",
      align: "center",
      width: "140px",
      render: (text, record) => {
        if (record.ngayYeuCau) {
          return <>{moment(record.ngayYeuCau).format("DD/MM/YYYY HH:mm")}</>;
        }
      },
    },
    {
      title: "Ngày tiếp nhận",
      dataIndex: "ngayTiepNhan",
      align: "center",
      width: "140px",
      render: (text, record) => {
        if (record.ngayTiepNhan) {
          return <>{moment(record.ngayTiepNhan).format("DD/MM/YYYY HH:mm")}</>;
        }
      },
    },
    {
      title: "Người yêu cầu",
      dataIndex: "nguoiYeuCau",
      align: "left",
      width: "100px",
    },
    {
      title: "Điện thoại",
      dataIndex: "dienThoai",
      align: "center",
      width: "110px",
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      width: "110px",
      align: "left",
    },
    {
      title: "Loại yêu cầu",
      dataIndex: "loaiYeuCau",
      width: "140px",
      align: "left",
    },
    {
      title: "Biên bản",
      dataIndex: "bienBan",
      width: "70px",
      render: (data) => {
        return (
          data && (
            <Button style={{ backgroundColor: "rgb(244, 164, 96)" }}>
              <a href={data} target="_blank" rel="noreferer noreferrer">
                Xem{" "}
              </a>
            </Button>
          )
        );
      },
      hidden: data.length > 0 && data.map((item) => item.bienBan).length === 0,
      align: "center",
    },
    {
      title: "Phiếu yêu cầu",
      dataIndex: "phieuYeuCau",
      render: (link) => {
        return (
          link && (
            <a href={link} target="_blank" rel="noreferer noreferrer">
              Xem{" "}
            </a>
          )
        );
      },
      width: "100px",
      hidden:
        data.length > 0 && data.map((item) => item.phieuYeuCau).length === 0,
      align: "center",
    },
    {
      title: "Nhân viên thực hiện",
      dataIndex: "nhanVienThucHien",
      width: "100px",
      align: "left",
    },
    {
      title: "Đội",
      dataIndex: "tenDoi",
      width: "100px",
      align: "left",
    },
    {
      title: "Web id",
      dataIndex: "webId",
      width: "80px",
      align: "center",
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    getListTrangThai(setListTrangThai);
  }, []);

  const handleSearch = useCallback(
    (values) => {
      const ojbStartDate = new Date(values.TuNgay);
      const objEndDate = new Date(values.DenNgay);
      const dateCondition = dateConditionLogic(ojbStartDate, objEndDate);

      const payload = {
        TuNgay: dateCondition.formatStartDate,
        DenNgay: dateCondition.formatEndDate,
        ...(values.KenhId && { KenhId: values.KenhId }),
        ...(values.LoaiYeuCau && { LoaiYeuCau: values.LoaiYeuCau }),
        ...(values.DonViId && { DonViId: values.DonViId }),
        ...(values.TuKhoaTimKiem && { TuKhoaTimKiem: values.TuKhoaTimKiem }),
        ...(values.LinhVuc && { LinhVuc: values.LinhVuc }),
        ...(values.TrangThai && { MaTrangThai: values.TrangThai }),
        ...(values.LoaiYeuCau && { LoaiYeuCau: values.LoaiYeuCau }),
        pageIndex: DEFAULT_PAGEINDEX,
        pageSize: DEFAULT_PAGESIZE,
      };
      setFilterConditions(payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterConditions]
  );

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  useEffect(() => {
    getLinhVucList(
      setLoading,
      setData,
      filterConditions,
      setTotal,
      location,
      setListLoading,
      setLinhVucList,
      form
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions]);

  const detailHoSo = (record) => {
    if (isDoubleClick)
      return {
        onDoubleClick: () => {
          dispatch(
            openModal({
              content: (
                <ThongTinChiTietTabs
                  detailHoSo={record}
                  closeModal={closeModal}
                />
              ),
              size: "large",
              title: <>Chi tiết yêu cầu</>,
            })
          );
        },
      };
  };

  const clearFilter = useCallback(() => {
    const payload = {
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DenNgay: `${moment().format("YYYY-MM-DD")}`,
      TuNgay: `${moment().subtract(7, "d").format("YYYY-MM-DD")}`,
      DonViId: donViId,
      LinhVuc: linhVucList[0].value,
    };

    form.setFieldsValue({
      LinhVuc: linhVucList[0].value,
    });
    setFilterConditions(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Style>
      <div className="tich-hop-CRM-gdv">
        <Filter>
          <CRMSearchFilter
            handleSearch={handleSearch}
            filterConditions={filterConditions}
            form={form}
            clearFilter={clearFilter}
            listTrangThai={listTrangThai}
            // doiId={"null"}
          />
        </Filter>
        <div className="tich-hop-CRM-gdv-content">
          <TableComponent
            loading={listLoading}
            dataSource={data}
            columns={columns}
            scrollX={1600}
            expandable={false}
            header="Danh sách"
            rowKey="key"
            totalData={total}
            pagination={filterConditions}
            onChangePagination={onChangePagination}
            onRow={detailHoSo}
          />
        </div>
      </div>
    </Style>
  );
}

export default TraCuuYeuCau;
