import { Fragment, useEffect } from "react";
import { Row, Col, Form, Input, DatePicker, Select } from "antd";
import moment from "moment";
import { FORMAT_DATE, TYPE_PHIEUTRA_CHUCNANG } from "@utils/constants";
import { disabledDateFuture, removeAccents } from "@utils/function";
import { Option } from "antd/lib/mentions";

export default function Index(props) {
  const {
    form,
    nguoiTao,
    tenLoaiTT,
    ngayTao,
    tenDoi,
    dataLoaiTT,
    dataDoi,
    changeValueDoi,
    valueDoi,
    firstDoi,
    firstLoaiTT,
    isChanged,
    lyDo,
    isTraLai,
    type,
  } = props;

  useEffect(() => {
    if (tenLoaiTT && tenDoi) {
      form.setFieldsValue({
        loaiTreoThao: tenLoaiTT,
        doiId: tenDoi,
      });
    } else
      form.setFieldsValue({
        loaiTreoThao: firstLoaiTT,
        doiId: firstDoi,
      });
  }, [firstDoi, firstLoaiTT, form, tenDoi, tenLoaiTT]);

  useEffect(() => {
    if (!isChanged && tenDoi === null) {
      form.setFieldsValue({
        doiId: firstDoi,
      });
    }
  }, [firstDoi, form, isChanged, tenDoi]);
  return (
    <Fragment>
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="loaiTreoThao" label="Loại treo tháo">
            {tenLoaiTT === null ? (
              <Select
                placeholder="Chọn loại treo tháo"
                showSearch
                filterOption={(input, option) => {
                  if (option && option.children) {
                    return (
                      removeAccents(option.children)
                        .toLowerCase()
                        .indexOf(removeAccents(input).toLowerCase()) >= 0
                    );
                  }
                }}
              >
                {dataLoaiTT && dataLoaiTT.length > 0
                  ? dataLoaiTT.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                  : null}
              </Select>
            ) : (
              <Input defaultValue={tenLoaiTT} disabled />
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="doiId" label="Đội">
            {tenDoi === null ? (
              <Select
                placeholder="Chọn đội"
                showSearch
                filterOption={(input, option) => {
                  if (option && option.children) {
                    return (
                      removeAccents(option.children)
                        .toLowerCase()
                        .indexOf(removeAccents(input).toLowerCase()) >= 0
                    );
                  }
                }}
                onChange={changeValueDoi}
                value={valueDoi}
              >
                {dataDoi && dataDoi.length > 0
                  ? dataDoi.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                  : null}
              </Select>
            ) : (
              <Input defaultValue={tenDoi} disabled />
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="ngayTao" label="Ngày tạo">
            <DatePicker
              defaultValue={moment(ngayTao)}
              format={FORMAT_DATE}
              form={form}
              notClear={false}
              disabledDate={disabledDateFuture}
              allowClear={false}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="nguoiTao" label="Người tạo">
            <Input defaultValue={nguoiTao} disabled={true} />
          </Form.Item>
        </Col>
      </Row>
      {type === TYPE_PHIEUTRA_CHUCNANG.TAOMOI ? null : (
        <Fragment>
          {isTraLai ? (
            <h3>
              Lý do trả lại:
              <span
                style={{
                  color: "red",
                  fontWeight: "bold",
                  paddingLeft: "24px",
                }}
              >
                {lyDo}
              </span>
            </h3>
          ) : null}
        </Fragment>
      )}
    </Fragment>
  );
}
