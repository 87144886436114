import CheckPermission from "@components/CheckPermission";
import { treo_thao } from "@permissions/b_treothao";
import { Button, Modal } from "antd";
import { Fragment, useState } from "react";
// import CapNhatMaCL from "../../common-ver1/cap-nhat-ma-cl-cong-to";
import { useDispatch } from "react-redux";
// import { openModal } from "@state/system-config/reducer";
import DanhSachYeuCau from "../../common-ver1/danh-sach-yeu-cau";
import { ENUM_DS_YEU_CAU, FUNC_ID_DSYC_TREOTHAO } from "@utils/constants";
import { callAPICancel, handleDetailYC } from "./services";

export default function Index() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const [arrSelectRowKeys, setArrSelectRowKeys] = useState([]);
  const [fetchList, setFetchList] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    // setArrSelectRowKeys(rowSelection);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  //Hiển thị  các buttom tạo mới, ...
  const genExtra = () => (
    <div>
      <CheckPermission permissionCode={treo_thao.b32tt}>
        <Button
          type="primary"
          className="mr-5px"
          onClick={() => handleOpenModalUpdateInfo()}
          disabled={!selectedRowKeys.length || selectedRowKeys.length >= 2}
        >
          Lập BBTT
        </Button>
      </CheckPermission>
      <CheckPermission permissionCode={treo_thao.b32h}>
        <Button
          // type="primary"
          danger
          className=""
          onClick={() => handleCancel()}
          disabled={!selectedRowKeys.length}
        >
          Hủy phân công
        </Button>
      </CheckPermission>
    </div>
  );

  //lập biên bản
  const handleOpenModalUpdateInfo = () => {
    handleDetailYC(
      setLoading,
      loading,
      dispatch,
      setFetchList,
      selectedRowKeys,
      setSelectedRowKeys
    );
  };

  //hủy phân công
  const handleCancel = () => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: `Bạn đồng ý thực hiện Trả yêu cầu về cho Đội QLĐ để phân công cho NV khác ?`,
      onOk() {
        //call api
        callAPICancel(
          setLoading,
          selectedRowKeys,
          setSelectedRowKeys,
          setFetchList
        );
      },
      onCancel() {},
      okText: "Đồng ý",
      cancelText: "Hủy",
    });
  };

  return (
    <Fragment>
      <DanhSachYeuCau
        funcId={FUNC_ID_DSYC_TREOTHAO.NVTREOTHAO_PHANCONG}
        typeList={ENUM_DS_YEU_CAU.NVTREOTHAO_YCPHANCONG}
        genExtra={genExtra}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        rowSelection={rowSelection}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    </Fragment>
  );
}
