const d1 = {
  d1: "d1",
  d1v: "d1v",
};
const d11 = {
  d11: "d11",
  d11v: "d11v",
  d11ld: "d11ld",
  d11ts: "d11ts",
};
const d12 = {
  d12: "d12",
  d12v: "d12v",
  d12pc: "d12pc",
  d12h: "d12h",
};
const d13 = {
  d13: "d13",
  d13v: "d13v",
  d13xn: "d13xn",
  d13ky: "d13ky",
  d13tl: "d13tl",
};
const d14 = {
  d14: "d14",
  d14ds: "d14ds",
  d14tl: "d14tl",
};
const d15 = {
  d15: "d15",
  d15v: "d15v",
  d15ht: "d15ht",
  d15td: "d15td",
  d15p: "d15p",
  d15xg: "d15xg",
};
const d2 = {
  d2: "d2",
  d2v: "d2v",
};
const d21 = {
  d21: "d21",
  d21v: "d21v",
  d21u: "d21u",
  d21ch: "d21ch",
  d21nh: "d21nh",
  d21cu: "d21cu",
};
const d22 = {
  d22: "d22",
  d22v: "d22v",
  d22ht: "d22ht",
  d22td: "d22td",
  d22p: "d22p",
  d22xu: "d22xu",
};
const d23 = {
  d23: "d23",
  d23v: "d23v",
  d23xn: "d23xn",
  d23tl: "d23tl",
};
const d3 = {
  d3: "d3",
  d3v: "d3v",
};
const d31 = {
  d31: "d31",
  d31pc: "d31pc",
  d31h: "d31h",
};
const d32 = {
  d32: "d32",
  d32v: "d32v",
  d32ht: "d32ht",
  d32td: "d32td",
  d32p: "d32p",
  d32xg: "d32xg",
};
const d33 = {
  d33: "d33",
  d33v: "d33v",
  d33xn: "d33xn",
  d33tl: "d33tl",
  d33ky: "d33ky",
};
const d4 = {
  d4: "d4",
  d4v: "d4v",
};
const d41 = {
  d41: "d41",
  d41v: "d41v",
  d41xn: "d41xn",
  d41ky: "d41ky",
  d41tl: "d41tl",
};
const d5 = {
  d5: "d5",
  d5v: "d5v",
  d5p: "d5p",
  d5x: "d5x",
};
const d51 = {
  d51: "d51",
  d51v: "d51v",
  d51p: "d51p",
  d51x: "d51x",
};
const d52 = {
  d52: "d52",
  d52v: "d52v",
  d52p: "d52p",
  d52x: "d52x",
};
const d53 = {
  d53: "d53",
  d53v: "d53v",
  d53p: "d53p",
};
const d54 = {
  d54: "d54",
  d54v: "d54v",
  d54p: "d54p",
  d54x: "d54x",
};
const d55 = {
  d55: "d55",
  d55v: "d55v",
  d55p: "d55p",
  d55x: "d55x",
};
const d56 = {
  d56: "d53",
  d56v: "d56v",
  d56p: "d56p",
};
const d57 = {
  d57: "d57",
  d57v: "d57v",
  d57p: "d57p",
};
const d58 = {
  d58: "d58",
  d58v: "d58v",
  d58p: "d58p",
};
const d59 = {
  d59: "d59",
  d59v: "d59v",
  d59p: "d59p",
};
const d510 = {
  d510: "d510",
  d510v: "d510v",
  d510p: "d510p",
};
const d511 = {
  d511: "d511",
  d511v: "d511v",
  d511p: "d511p",
};
const d512 = {
  d512: "d512",
  d512v: "d512v",
  d512p: "d512p",
};
const d513 = {
  d513: "d513",
  d513v: "d513v",
  d513p: "d513p",
};
const d514 = {
  d514: "d514",
  d514v: "d514v",
  d514p: "d514p",
  d514x: "d514x",
};
const d515 = {
  d515: "d515",
  d515v: "d515v",
  d515p: "d515p",
};
const d516 = {
  d516: "d516",
  d516v: "d516v",
  d516p: "d516p",
};
const d517 = {
  d517: "d517",
  d517v: "d517v",
  d517p: "d517p",
  d517x: "d517x",
};
const d6 = {
  d6: "d6",
  d6v: "d6v",
  d6x: "d6x",
  d6p: "d6p",
};
const d61 = {
  d61: "d61",
  d61v: "d61v",
  d61x: "d61x",
  d61p: "d61p",
};
const d62 = {
  d62: "d61",
  d62v: "d62v",
  d62x: "d62x",
  d62p: "d62p",
};
const d63 = {
  d63: "d63",
  d63v: "d63v",
  d63x: "d63x",
  d63p: "d63p",
};
const d64 = {
  d64: "d64",
  d64v: "d64v",
  d64x: "d64x",
  d64p: "d64p",
};
const d65 = {
  d65: "d65",
  d65v: "d65v",
  d65x: "d65x",
  d65p: "d65p",
};
const d66 = {
  d66: "d66",
  d66v: "d66v",
  d66x: "d66x",
  d66p: "d66p",
};
const d67 = {
  d67: "d67",
  d67v: "d67v",
  d67x: "d67x",
  d67p: "d67p",
};
const d68 = {
  d68: "d68",
  d68v: "d68v",
  d68x: "d68x",
  d68p: "d68p",
};
const d69 = {
  d69: "d69",
  d69v: "d69v",
  d69x: "d69x",
  d69p: "d69p",
};
const d610 = {
  d610: "d610",
  d610v: "d610v",
  d610x: "d610x",
  d610p: "d610p",
};
const d611 = {
  d611: "d611",
  d611v: "d611v",
  d611x: "d611x",
  d611p: "d611p",
};
const d612 = {
  d612: "d611",
  d612v: "d612v",
  d612x: "d612x",
  d612p: "d612p",
};
const d613 = {
  d613: "d613",
  d613v: "d613v",
  d613x: "d613x",
  d613p: "d613p",
};
const d7 = {
  d7: "d7",
  d7v: "d7v",
};
const d71 = {
  d71: "d71",
  d71v: "d71v",
  d71t: "d71t",
  d71s: "d71s",
  d71x: "d71x",
};
const d72 = {
  d72: "d72",
  d72v: "d72v",
  d72tm: "d72tm",
  d72s: "d72s",
  d72x: "d72x",
  d72lu: "d72lu",
};
const d73 = {
  d73: "d73",
  d73v: "d73v",
  d73tm: "d73tm",
  d73s: "d73s",
  d73x: "d73x",
  d73lu: "d73lu",
};
const d8 = {
  d8: "d8",
  d8v: "d8v",
};
const d81 = {
  d81: "d81",
  d81v: "d81v",
};
const d9 = {
  d9: "d9",
  d9v: "d9v",
};
const gcs = {
  d: "d",
};

export const ghi_chi_so = {
  ...gcs,
  ...d1,
  ...d11,
  ...d12,
  ...d13,
  ...d14,
  ...d15,
  ...d2,
  ...d21,
  ...d22,
  ...d23,
  ...d3,
  ...d31,
  ...d32,
  ...d33,
  ...d4,
  ...d41,
  ...d5,
  ...d51,
  ...d52,
  ...d53,
  ...d54,
  ...d55,
  ...d56,
  ...d57,
  ...d58,
  ...d59,
  ...d510,
  ...d511,
  ...d512,
  ...d513,
  ...d514,
  ...d515,
  ...d516,
  ...d517,
  ...d6,
  ...d61,
  ...d62,
  ...d63,
  ...d64,
  ...d65,
  ...d66,
  ...d67,
  ...d68,
  ...d69,
  ...d610,
  ...d611,
  ...d612,
  ...d613,
  ...d7,
  ...d71,
  ...d72,
  ...d73,
  ...d8,
  ...d81,
  ...d9,
};
