import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import TableComponent from "@components/TableComponent";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_DATE,
  FORMAT_ENGLISH,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  formatDateWithTimezone,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
import { ENUM_BUTTON_TYPE } from "@utils/constants";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { selectBCDOISOATEP } from "./service";

export default function Bieu4(props) {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);
  const [form] = Form.useForm();
  const [dataTable, setDataTable] = useState([]);
  const [, setSearchParams] = useSearchParams();
  const { screen, handleChange } = props;
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    TuNgay: formatDateWithTimezone(`${moment(moment()._d).startOf("month")}`),
    DenNgay: formatDateWithTimezone(`${moment(moment()._d)}`),
    ...parseParams(location.search),
  });
  const [filterExcel, setFilterExcel] = useState(false);
  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type]);

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        TuNgay: formatDateWithTimezone(values.TuNgay),
        DenNgay: formatDateWithTimezone(values.DenNgay),
      }));
      handleAPI();
    },
    [handleAPI]
  );

  const getDataBieu4 = useCallback(() => {
    const queryString = buildQueryString(
      parseParams({
        TuNgay: filterConditions.TuNgay,
        DenNgay: filterConditions.DenNgay,
      })
    );
    authGetData({
      url: `${Endpoint.BCBEP_TIN_NHAN_THTC}?${queryString}`,
      setLoading,
      onSuccess: (res) => {
        if (res) {
          console.log(res);
          setDataTable(
            res.data.map((item, index) => {
              return {
                ...item,
                STT: index + 1,
              };
            })
          );
        }
      },
    });
  }, [filterConditions.DenNgay, filterConditions.TuNgay]);

  useEffect(() => {
    getDataBieu4();
  }, [getDataBieu4]);
  useEffect(() => {
    if (filterExcel) {
      const endpoint =
        Endpoint.BCBEP_TIN_NHAN_THTC_EXCEL +
        "?" +
        buildQueryString(parseParams(filterConditions));
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel]);

  const columns = [
    {
      title: "STT",
      align: "center",
      width: 70,
      dataIndex: "STT",
      key: "STT",
    },
    {
      title: "Mã đơn vị",
      width: 150,
      dataIndex: "MA_DVIQLY",
      key: "MA_DVIQLY",
    },
    {
      width: 150,
      title: "Số lượng đã nhắn viettel",
      dataIndex: "SL_DANHAN_VIETTEL",
      key: "SL_DANHAN_VIETTEL",
      align: "center",
    },
    {
      title: "Đơn giá viettel",
      width: 150,
      dataIndex: "DONGIA_VIETTEL",
      align: "center",
      key: "DONGIA_VIETTEL",
    },
    {
      width: 150,
      title: "Tiền Viettel",
      align: "center",
      dataIndex: "TIEN_VIETTEL",
      key: "TIEN_VIETTEL",
    },
    {
      width: 150,
      title: "Số lượng đã nhắn Vina",
      align: "center",
      dataIndex: "SL_DANHAN_VINA",
      key: "SL_DANHAN_VINA",
    },
    {
      width: 150,
      title: "Đơn giá Vina",
      align: "center",
      dataIndex: "DONGIA_VINA",
      key: "DONGIA_VINA",
    },
    {
      width: 150,
      title: "Tiền Vina",
      align: "center",
      dataIndex: "TIEN_VINA",
      key: "TIEN_VINA",
    },
    {
      width: 150,
      title: "Số lượng đã nhắn Mobi",
      align: "center",
      dataIndex: "SL_DANHAN_MOBI",
      key: "SL_DANHAN_MOBI",
    },
    {
      width: 150,
      title: "Đơn giá Mobi",
      align: "center",
      dataIndex: "DONGIA_MOBI",
      key: "DONGIA_MOBI",
    },
    {
      width: 150,
      title: "Tiền Mobi",
      align: "center",
      dataIndex: "TIEN_MOBI",
      key: "TIEN_MOBI",
    },
    {
      width: 150,
      title: "Số lượng đã nhắn VNMB",
      align: "center",
      dataIndex: "TONG",
      key: "TONG",
    },
    {
      width: 150,
      title: "Đơn giá VNMB",
      align: "center",
      dataIndex: "DONGIA_HT",
      key: "DONGIA_HT",
    },
    {
      width: 150,
      title: "Tiền VNMB",
      align: "center",
      dataIndex: "TIEN_HT",
      key: "TIEN_VNMB",
    },
    {
      width: 150,
      title: "Số lượng đã nhắn GTEL",
      align: "center",
      dataIndex: "SL_DANHAN_BL",
      key: "SL_DANHAN_BL",
    },
    {
      width: 150,
      title: "Đơn giá GTEL",
      align: "center",
      dataIndex: "DONGIA_BL",
      key: "DONGIA_BL",
    },
    {
      width: 150,
      title: "Tiền GTEL",
      align: "center",
      dataIndex: "TIEN_BL",
      key: "TIEN_BL",
    },
    {
      width: 150,
      title: "Số lượng đã nhắn ITEL",
      align: "center",
      dataIndex: "SL_DANHAN_HT",
      key: "SL_DANHAN_HT",
    },
    {
      width: 150,
      title: "Đơn giá ITEL",
      align: "center",
      dataIndex: "DONGIA_ITEL",
      key: "DONGIA_ITEL",
    },
    {
      width: 150,
      title: "Tiền ITEL",
      align: "center",
      dataIndex: "TIEN_ITEL",
      key: "TIEN_ITEL",
    },
    {
      width: 150,
      title: "Tổng đã nhắn",
      align: "center",
      dataIndex: "TONG_DANHAN",
      key: "TONG_DANHAN",
    },
    {
      width: 150,
      title: "Tổng tiền đã nhắn",
      align: "center",
      dataIndex: "TONG",
      key: "TONG",
    },
  ];

  const handleStartDate = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      TuNgay: formatDateWithTimezone(e?._d),
    }));
  };

  const handleEndDate = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      DenNgay: formatDateWithTimezone(e?._d),
    }));
  };

  return (
    <Container>
      <ContentWrapper>
        <FormComponent
          onFinish={handleSearch}
          initialValues={{ reportType: screen }}
          form={form}
          labelCol={{ span: 3 }}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="reportType"
                label="Tên báo cáo"
                labelAlign="left"
              >
                <Select
                  placeholder="Tất cả"
                  options={selectBCDOISOATEP}
                  onChange={(e) => handleChange(e)}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={7}>
              <Form.Item
                name="TuNgay"
                label="Ngày bắt đầu"
                format="DD/MM/YYYY"
                labelAlign="left"
                labelCol={{ span: 11 }}
              >
                <div style={{ marginLeft: "-2.4%" }}>
                  <DatePickerComponent
                    formKey="TuNgay"
                    form={form}
                    onChange={handleStartDate}
                    defaultValue={
                      filterConditions.TuNgay
                        ? moment(filterConditions.TuNgay, FORMAT_ENGLISH)
                        : moment().startOf(FORMAT_DATE)
                    }
                  />
                </div>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                name="DenNgay"
                label="Ngày kết thúc"
                format="DD/MM/YYYY"
                labelAlign="right"
                labelCol={{ span: 11 }}
              >
                <DatePickerComponent
                  formKey="DenNgay"
                  form={form}
                  onChange={handleEndDate}
                  defaultValue={
                    filterConditions.DenNgay
                      ? moment(filterConditions.DenNgay, FORMAT_ENGLISH)
                      : moment().startOf(FORMAT_DATE)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8} style={{ paddingLeft: 50 }}>
              {" "}
              <Button
                type="primary"
                className="mr-5px"
                htmlType="submit"
                onClick={() => setType(ENUM_BUTTON_TYPE.TIM_KIEM)}
              >
                Xem báo cáo
              </Button>
              <Button
                type="primary"
                className="mr-5px"
                htmlType="submit"
                onClick={() => setType(ENUM_BUTTON_TYPE.XUAT_EXCEL)}
              >
                Xuất excel
              </Button>
            </Col>
          </Row>
          <Row justify="end"></Row>
        </FormComponent>
        <Divider className="section-text-line" orientation="left"></Divider>
        {dataTable && dataTable?.length > 0 ? (
          <TableComponent
            header={
              "Biểu 4_CC: Đối soát số lượng tin nhắn đã thực hiện thành công"
            }
            columns={columns}
            dataSource={dataTable}
            loading={loading}
            scrollX={1500}
          />
        ) : (
          <TableComponent
            header={
              "Biểu 4_CC: Đối soát số lượng tin nhắn đã thực hiện thành công"
            }
            columns={columns}
            loading={loading}
            dataSource={null}
            scrollX={1400}
          />
        )}
      </ContentWrapper>
    </Container>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
