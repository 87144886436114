import { memo, useEffect, useRef, useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { handleCreateTiLeBD, handleUpdateTiLeBD } from "../../services";
import UITiLeBanDien from "./ui-modal/ti-le-ban-dien";
import { ERROR_MAX_INT, LIST_TILEGIABANDIEN, MAX_INT } from "@utils/constants";
function TyLeBanDienModal({
  detailLeBanDien,
  visible,
  handleCloseModal,
  fetchDataGiaBanDien,
  dataTiLe,
  detaiHoSo,
  typeTiLe,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const [readOnly, setReadOnly] = useState(
    detailLeBanDien.tiLeGiaBanDienId !== undefined ? true : false
  );
  const [tenMucDich, setTenMucDich] = useState(detailLeBanDien.mucDichSuDung);
  const [giaTriGioBT, setGiaTriGioBT] = useState(detailLeBanDien.gioBinhThuong);
  const [giaTriGioCD, setGiaTriGioCD] = useState(detailLeBanDien.gioCaoDiem);
  const [giaTriGioTD, setGiaTriGioTD] = useState(detailLeBanDien.gioThapDiem);
  const [giaTriGioKT, setGiaTriGioKT] = useState(detailLeBanDien.gioKT);
  const [idBanDien, setIdBanDien] = useState(
    detailLeBanDien.tiLeGiaBanDienId ? detailLeBanDien.tiLeGiaBanDienId : null
  );
  const [isKhongTheoTG, setIsKhongTheoTG] = useState(
    detailLeBanDien.isKhongTheoTG ? detailLeBanDien.isKhongTheoTG : false
  );

  const [enterMucDich, setEnterMucDich] = useState("");
  const [enterTiLe, setEnterTiLe] = useState("");
  const [enterGioBT, setEnterGioBT] = useState("");
  const [enterGioCD, setEnterGioCD] = useState("");
  const [enterGioTD, setEnterGioTD] = useState("");
  const [enterGioKT, setEnterGioKT] = useState("");
  const [donViTinh, setDonViTinh] = useState(
    detailLeBanDien.isPhanTram ? detailLeBanDien.isPhanTram : 0
  );

  const handleSubmitData = (values) => {
    const data = {
      ...values,
      hoSoId:
        typeTiLe === LIST_TILEGIABANDIEN.APGIA
          ? detaiHoSo.id
          : detaiHoSo.hoSoId,
      tiLePhanTram:
        parseInt(values.tiLePhanTram) !== null
          ? parseInt(values.tiLePhanTram)
          : null,
      isPhanTram:
        values.isPhanTram !== null ? parseInt(values.isPhanTram) : donViTinh,
      isKhongTheoTG: isKhongTheoTG ? isKhongTheoTG : false,
    };

    if (values.tiLePhanTram > MAX_INT) {
      const errors = [];
      errors.push({
        name: "tiLePhanTram",
        errors: [ERROR_MAX_INT],
      });
      form.setFields([...errors]);
      form.scrollToField(errors[0]?.name);
    }
    if (detailLeBanDien.id) {
      data.id = detailLeBanDien.id;
    }
    handleCreateUpdate(data, form);
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();
    // eslint-disable-next-line
  }, []);

  const handleCreateUpdate = (data, form) => {
    if (data.id) {
      //sửa tỉ lệ bán điện

      handleUpdateTiLeBD(
        data,
        setLoading,
        fetchDataGiaBanDien,
        handleCloseModal,
        form
      );
    } else {
      //tạo tỉ lệ bán điện

      handleCreateTiLeBD(
        data,
        setLoading,
        fetchDataGiaBanDien,
        handleCloseModal,
        form
      );
    }
  };

  const handleChangeMucDich = (value) => {
    dataTiLe.map((item) => {
      if ([value].includes(item.id)) {
        if (item.isKhongTheoTG) {
          setGiaTriGioKT(item.gioKT);
        } else {
          setGiaTriGioBT(item.gioBinhThuong);
          setGiaTriGioCD(item.gioCaoDiem);
          setGiaTriGioTD(item.gioThapDiem);
        }
        setTenMucDich(item.mucDichSuDung);
        setIsKhongTheoTG(item.isKhongTheoTG);
        return false;
      }
      return true;
    });
    if (value === null) {
      form.setFieldsValue({
        mucDichSuDung: enterMucDich,
        tiLePhanTram: enterTiLe,
        gioBinhThuong: enterGioBT,
        gioCaoDiem: enterGioCD,
        gioThapDiem: enterGioTD,
        isPhanTram: donViTinh,
        gioKT: enterGioKT,
      });
    }
    setIdBanDien(value);
  };

  useEffect(() => {
    if (idBanDien !== null) {
      form.setFieldsValue({
        mucDichSuDung: tenMucDich,
        gioBinhThuong: giaTriGioBT,
        gioCaoDiem: giaTriGioCD,
        gioThapDiem: giaTriGioTD,
        gioKT: giaTriGioKT,
      });
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
  }, [
    detailLeBanDien.mucDichSuDung,
    form,
    giaTriGioBT,
    giaTriGioCD,
    giaTriGioTD,
    giaTriGioKT,
    idBanDien,
    tenMucDich,
  ]);

  const getMucDich = (e) => {
    setEnterMucDich(e.target.value);
  };

  const getTiLe = (e) => {
    setEnterTiLe(e.target.value);
  };

  const getGioBT = (e) => {
    setEnterGioBT(e.target.value);
  };

  const getGioCD = (e) => {
    setEnterGioCD(e.target.value);
  };

  const getGioTD = (e) => {
    setEnterGioTD(e.target.value);
  };

  const getGioKT = (e) => {
    setEnterGioKT(e.target.value);
  };

  const handleChangeDonViTinh = (value) => {
    setDonViTinh(value);
  };

  //Thay đổi field TrangThai
  const handleChangeActive = (e) => {
    setIsKhongTheoTG(e.target.checked);
  };

  return (
    <UITiLeBanDien
      loading={loading}
      detailLeBanDien={detailLeBanDien}
      visible={visible}
      handleCloseModal={handleCloseModal}
      form={form}
      handleSubmitData={handleSubmitData}
      idBanDien={idBanDien}
      t={t}
      donViTinh={donViTinh}
      handleChangeDonViTinh={handleChangeDonViTinh}
      handleChangeMucDich={handleChangeMucDich}
      dataTiLe={dataTiLe}
      readOnly={readOnly}
      getMucDich={getMucDich}
      handleChangeActive={handleChangeActive}
      isKhongTheoTG={isKhongTheoTG}
      getTiLe={getTiLe}
      getGioTD={getGioTD}
      getGioBT={getGioBT}
      getGioCD={getGioCD}
      getGioKT={getGioKT}
      inputRef={inputRef}
    />
  );
}

export default memo(TyLeBanDienModal);
