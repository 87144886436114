//CRM_TRUONG_PKD_TRA_LAI
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, authPutData } from "@utils/request";

//CRMKD_DUYET_YEU_CAU_DOI

export const getDanhSachDuyetPKD = (
  setListLoading,
  setData,
  query,
  filterConditions,
  setTotal
) => {
  authGetData({
    url: `${Endpoint.CRMKD_DUYET_YEU_CAU_PKD}?${query}`,
    setLoading: setListLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              STT:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            };
          })
        );
        setTotal(res.paging.totalCount);
      }
    },
  });
};

export const putTraLaiYeuCau = (
  payload,
  setLoading,
  setListLoading,
  location,
  filterConditions,
  setData,
  setTotal,
  setVisibleTra,
  setSelectedRowKeys
) => {
  authPutData({
    url: `${Endpoint.CRM_TRUONG_PKD_TRA_LAI}`,
    method: "PUT",
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        const query =
          Object.keys(parseParams(location.search)).length > 0
            ? buildQueryString(parseParams(location.search))
            : buildQueryString(filterConditions);
        getDanhSachDuyetPKD(
          setListLoading,
          setData,
          query,
          filterConditions,
          setTotal
        );
        setVisibleTra(false);
        setSelectedRowKeys([]);
      }
    },
  });
};

//CRM_TRUONG_PKD_XAC_NHAN

export const putXacNhanYeuCau = (
  payload,
  setLoading,
  setListLoading,
  location,
  filterConditions,
  setData,
  setTotal,
  setVisibleXacNhan,
  setSelectedRowKeys
) => {
  authPutData({
    url: `${Endpoint.CRM_TRUONG_PKD_XAC_NHAN}`,
    method: "PUT",
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        const query =
          Object.keys(parseParams(location.search)).length > 0
            ? buildQueryString(parseParams(location.search))
            : buildQueryString(filterConditions);
        getDanhSachDuyetPKD(
          setListLoading,
          setData,
          query,
          filterConditions,
          setTotal
        );
        setVisibleXacNhan(false);
        setSelectedRowKeys([]);
      }
    },
  });
};

export const getLinhVucList = (
  setLoading,
  form_filter,
  setData,
  filterConditions,
  setTotal,
  location,
  setListLoading,
  setLinhVucList
) => {
  authGetData({
    url: `${Endpoint.CRM_LIST_LINH_VUC}`,
    setLoading,
    onSuccess: (res) => {
      setLinhVucList(res.data);

      const current_query = filterConditions;
      const updated_query = {
        ...current_query,
        pageSize: filterConditions.pageSize,
        pageIndex: filterConditions.pageIndex,
      };
      form_filter.setFieldsValue({
        LinhVuc: res.data[0].value || undefined,
      });
      const query = buildQueryString(updated_query);
      getDanhSachDuyetPKD(
        setListLoading,
        setData,
        query,
        filterConditions,
        setTotal
      );
    },
  });
};

export const putKyHSM = ({ payload, setLoadingKyHSM, onSuccess }) => {
  authPutData({
    url: `${Endpoint.CRM_TRUONG_PKD_KY_HSM}`,
    method: "PUT",
    payload,
    setLoading: setLoadingKyHSM,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        onSuccess();
      }
    },
  });
};
