import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { customColumn } from "@utils/function";
import { Fragment } from "react";
import { ListFilter } from "../list-bo-loc";
import { Container } from "./css-styled";

export default function Index(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    genExtra,
    dataLyDoTreoThao,
    totalCount,
    loading,
    onChangePagination,
    columns,
  } = props;
  return (
    <Fragment>
      <Filter>
        <ListFilter
          tooltip={"Tìm kiếm theo Mã lý do hoặc Tên lý do"}
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
        />
      </Filter>
      <Container>
        <TableComponent
          header={"Danh sách lý do treo tháo"}
          renderExtraAction={() => genExtra()}
          dataSource={dataLyDoTreoThao}
          columns={customColumn(columns, filterConditions)}
          totalData={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
          scrollY={530}
        />
      </Container>
    </Fragment>
  );
}
