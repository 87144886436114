import { Fragment, useState } from "react";
import Table from "../../common/danh-sach-cong-to";
import { ENUM_DS_CONGTO, TYPE_TABLE_CONGTO } from "@utils/constants";
import { kt_tb_do_dem } from "@permissions/n_kttbdodem";
import { Endpoint } from "@utils/endpoint";

export default function CongViecPhucTra() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [fetchList, setFetchList] = useState(false);

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Fragment>
      <Table
        typeDS={ENUM_DS_CONGTO.PHUCTRA_TRACUU}
        typeList={TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={false}
        rowSelection={rowSelection}
        selectedRowKeys={selectedRowKeys}
        header={"Phân công kiểm tra phúc tra"}
        urlXuatExcel={Endpoint.XUAT_EXCEL_PHUCTRA}
        permissionExcel={kt_tb_do_dem.n62ex}
      />
    </Fragment>
  );
}
