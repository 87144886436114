import { useState } from "react";
import { useDispatch } from "react-redux";
import ButtonKySoHSM from "./button";
import { handleKySo } from "./services";
export default function Index(props) {
  const { url, params, setFetchList, disabled } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleClick = () => {
    handleKySo(setLoading, url, params, setFetchList, dispatch);
  };
  return (
    <ButtonKySoHSM
      handleClick={handleClick}
      loading={loading}
      disabled={disabled}
    />
  );
}
