import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";
import { Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import CreatePageForm from "./form-create-page";
import EditPageForm from "./form-edit-page";

export default function ModalCreateOrEditPage(props) {
  const { page, getPageList, dispatch } = props;
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState(null);
  const getPageDetail = useCallback(() => {
    authGetData({
      url: `${Endpoint.PAGE}/get-page?id=${page.id}`,
      setLoading,
      onSuccess: (res) => {
        setPageInfo(res.data);
      },
    });
  }, [page]);
  useEffect(() => {
    if (page && page.id) {
      getPageDetail();
    }
  }, [getPageDetail, page]);

  const renderFirst = useCallback(() => {
    if (loading) {
      return <div className="text-center">Đang tải dữ liệu...</div>;
    }
    if (!loading && !page && !pageInfo) {
      return (
        <CreatePageForm
          dispatch={dispatch}
          getPageList={getPageList}
          page={pageInfo}
        />
      );
    }
    if (!loading && pageInfo) {
      return (
        <EditPageForm
          dispatch={dispatch}
          page={pageInfo}
          getPageList={getPageList}
          getPageDetail={getPageDetail}
        />
      );
    }
  }, [loading, getPageDetail, getPageList, pageInfo, page, dispatch]);
  return (
    <Spin spinning={loading}>
      <Container>{renderFirst()}</Container>
    </Spin>
  );
}

const Container = styled.div`
  padding: 10px 20px;
`;
