import {
  BO_CHI_SO_KTCT,
  ENUM_SOPHA,
  TYPE_TABLE_CONGTO,
} from "@utils/constants";
import { Col, Form, Row, Table, Typography } from "antd";
import { Fragment } from "react";

export default function ThongTinKiemTraPanel({
  typeSoPha,
  typeList,
  columnsPT,
  dataTable,
  dataDetail,
}) {
  return (
    <Fragment>
      {typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA ||
      typeList === TYPE_TABLE_CONGTO.CHISO_EVN_HES ||
      typeList === TYPE_TABLE_CONGTO.DONGBO_CMIS ? (
        <Col span={24}>
          <span className="fs-16px font-bold">Thông tin phúc tra</span>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Mã công tơ"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.maCongTo !== curValues.maCongTo
                }
              >
                {({ getFieldValue }) => {
                  const maCongTo = getFieldValue("maCongTo") || undefined;
                  return maCongTo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {maCongTo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Loại công tơ"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.loaiCongTo !== curValues.loaiCongTo
                }
              >
                {({ getFieldValue }) => {
                  const loaiCongTo = getFieldValue("loaiCongTo") || undefined;
                  return loaiCongTo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {loaiCongTo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Năm SX/ Nước SX"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.namVaNuocSanXuat !== curValues.namVaNuocSanXuat
                }
              >
                {({ getFieldValue }) => {
                  const namVaNuocSanXuat =
                    getFieldValue("namVaNuocSanXuat") || undefined;
                  return namVaNuocSanXuat ? (
                    <Typography.Text className="ant-form-text" strong>
                      {namVaNuocSanXuat}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="Tình trạng công tơ"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tinhTrangCongTo !== curValues.tinhTrangCongTo
                }
              >
                {({ getFieldValue }) => {
                  const tinhTrangCongTo =
                    getFieldValue("tinhTrangCongTo") || undefined;
                  return tinhTrangCongTo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tinhTrangCongTo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Kết luận"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.ketLuan !== curValues.ketLuan
                }
              >
                {({ getFieldValue }) => {
                  const ketLuan = getFieldValue("ketLuan") || undefined;
                  return ketLuan ? (
                    <Typography.Text
                      className="ant-form-text"
                      style={{ color: "rgb(18 34 239)" }}
                      strong
                    >
                      {ketLuan &&
                        ketLuan.split("/n").map((item) => {
                          return (
                            <Fragment>
                              <span>{item}</span>
                              <br />
                            </Fragment>
                          );
                        })}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Sai số công tơ"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.ketQuaSaiSoCongTo !== curValues.ketQuaSaiSoCongTo
                }
              >
                {({ getFieldValue }) => {
                  const ketQuaSaiSoCongTo =
                    getFieldValue("ketQuaSaiSoCongTo") || undefined;
                  return ketQuaSaiSoCongTo ? (
                    <Typography.Text
                      className="ant-form-text"
                      style={{ color: "rgb(18 34 239)" }}
                      strong
                    >
                      {ketQuaSaiSoCongTo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Mã số chì hòm"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.maSoChiHom !== curValues.maSoChiHom
                }
              >
                {({ getFieldValue }) => {
                  const maSoChiHom = getFieldValue("maSoChiHom") || undefined;
                  return maSoChiHom ? (
                    <Typography.Text className="ant-form-text" strong>
                      {maSoChiHom}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="Số lượng chì hòm"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.soLuongChiHom !== curValues.soLuongChiHom
                }
              >
                {({ getFieldValue }) => {
                  const soLuongChiHom =
                    getFieldValue("soLuongChiHom") || undefined;
                  return soLuongChiHom ? (
                    <Typography.Text className="ant-form-text" strong>
                      {soLuongChiHom}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Tem"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tem !== curValues.tem
                }
              >
                {({ getFieldValue }) => {
                  const tem = getFieldValue("tem") || undefined;
                  return tem ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tem}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Đai"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.dai !== curValues.dai
                }
              >
                {({ getFieldValue }) => {
                  const dai = getFieldValue("dai") || undefined;
                  return dai ? (
                    <Typography.Text className="ant-form-text" strong>
                      {dai}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Khóa"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.khoa !== curValues.khoa
                }
              >
                {({ getFieldValue }) => {
                  const khoa = getFieldValue("khoa") || undefined;
                  return khoa ? (
                    <Typography.Text className="ant-form-text" strong>
                      {khoa}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="Sổ GCS"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.maSoGhiChiSo !== curValues.maSoGhiChiSo
                }
              >
                {({ getFieldValue }) => {
                  const maSoGhiChiSo =
                    getFieldValue("maSoGhiChiSo") || undefined;
                  return maSoGhiChiSo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {maSoGhiChiSo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Ngày GCS"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.ngayGhiChiSo !== curValues.ngayGhiChiSo
                }
              >
                {({ getFieldValue }) => {
                  const ngayGhiChiSo =
                    getFieldValue("ngayGhiChiSo") || undefined;
                  return ngayGhiChiSo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {ngayGhiChiSo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Kỳ GCS"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.kyGCS !== curValues.kyGCS
                }
              >
                {({ getFieldValue }) => {
                  const kyGCS = getFieldValue("kyGCS") || undefined;
                  return kyGCS ? (
                    <Typography.Text className="ant-form-text" strong>
                      {kyGCS}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Ngày phúc tra"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.ngayPhucTra !== curValues.ngayPhucTra
                }
              >
                {({ getFieldValue }) => {
                  const ngayPhucTra = getFieldValue("ngayPhucTra") || undefined;
                  return ngayPhucTra ? (
                    <Typography.Text className="ant-form-text" strong>
                      {ngayPhucTra}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Chênh lệch %"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.chenhLechPhanTram !== curValues.chenhLechPhanTram
                }
              >
                {({ getFieldValue }) => {
                  const chenhLechPhanTram =
                    getFieldValue("chenhLechPhanTram") || undefined;
                  return chenhLechPhanTram ? (
                    <Typography.Text className="ant-form-text" strong>
                      {chenhLechPhanTram}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Chênh lệch Kwh"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.chenhLechKwh !== curValues.chenhLechKwh
                }
              >
                {({ getFieldValue }) => {
                  const chenhLechKwh =
                    getFieldValue("chenhLechKwh") || undefined;
                  return chenhLechKwh ? (
                    <Typography.Text className="ant-form-text" strong>
                      {chenhLechKwh}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          <div className="table-fixed">
            <Table
              dataSource={dataTable}
              columns={columnsPT}
              pagination={false}
            ></Table>
          </div>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Kiến nghị cần giải quyết"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.kienNghiCanGiaiQuyet !==
                  curValues.kienNghiCanGiaiQuyet
                }
              >
                {({ getFieldValue }) => {
                  const kienNghiCanGiaiQuyet =
                    getFieldValue("kienNghiCanGiaiQuyet") || undefined;
                  return kienNghiCanGiaiQuyet ? (
                    <Typography.Text className="ant-form-text" strong>
                      {kienNghiCanGiaiQuyet &&
                        kienNghiCanGiaiQuyet.split("/n/n").map((item) => {
                          return (
                            <Fragment>
                              <span>{item}</span>
                              <br />
                            </Fragment>
                          );
                        })}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      ) : typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY &&
        typeSoPha === ENUM_SOPHA.MOT_PHA ? (
        <Col span={24}>
          <span className="fs-16px font-bold">Thông tin kiểm tra</span>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Mã công tơ"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.maCongTo !== curValues.maCongTo
                }
              >
                {({ getFieldValue }) => {
                  const maCongTo = getFieldValue("maCongTo") || undefined;
                  return maCongTo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {maCongTo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Loại công tơ"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.loaiCongTo !== curValues.loaiCongTo
                }
              >
                {({ getFieldValue }) => {
                  const loaiCongTo = getFieldValue("loaiCongTo") || undefined;
                  return loaiCongTo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {loaiCongTo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Năm SX/ Nước SX"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.namVaNuocSanXuat !== curValues.namVaNuocSanXuat
                }
              >
                {({ getFieldValue }) => {
                  const namVaNuocSanXuat =
                    getFieldValue("namVaNuocSanXuat") || undefined;
                  return namVaNuocSanXuat ? (
                    <Typography.Text className="ant-form-text" strong>
                      {namVaNuocSanXuat}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Tình trạng công tơ"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tinhTrangCongTo !== curValues.tinhTrangCongTo
                }
              >
                {({ getFieldValue }) => {
                  const tinhTrangCongTo =
                    getFieldValue("tinhTrangCongTo") || undefined;
                  return tinhTrangCongTo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tinhTrangCongTo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Kết luận"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.ketLuan !== curValues.ketLuan
                }
              >
                {({ getFieldValue }) => {
                  const ketLuan = getFieldValue("ketLuan") || undefined;
                  return ketLuan ? (
                    <Typography.Text
                      className="ant-form-text"
                      style={{ color: "rgb(18 34 239)" }}
                      strong
                    >
                      {ketLuan &&
                        ketLuan.split("/n").map((item) => {
                          return (
                            <Fragment>
                              <span>{item}</span>
                              <br />
                            </Fragment>
                          );
                        })}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Sai số công tơ"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.ketQuaSaiSoCongTo !== curValues.ketQuaSaiSoCongTo
                }
              >
                {({ getFieldValue }) => {
                  const ketQuaSaiSoCongTo =
                    getFieldValue("ketQuaSaiSoCongTo") || undefined;
                  return ketQuaSaiSoCongTo ? (
                    <Typography.Text
                      className="ant-form-text"
                      style={{ color: "rgb(18 34 239)" }}
                      strong
                    >
                      {ketQuaSaiSoCongTo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="U"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.u !== curValues.u
                }
              >
                {({ getFieldValue }) => {
                  const u = getFieldValue("u") || undefined;
                  return u ? (
                    <Typography.Text className="ant-form-text" strong>
                      {u}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="I"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.i !== curValues.i
                }
              >
                {({ getFieldValue }) => {
                  const i = getFieldValue("i") || undefined;
                  return i ? (
                    <Typography.Text className="ant-form-text" strong>
                      {i}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="K"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.heSoK !== curValues.heSoK
                }
              >
                {({ getFieldValue }) => {
                  const heSoK = getFieldValue("heSoK") || undefined;
                  return heSoK ? (
                    <Typography.Text className="ant-form-text" strong>
                      {heSoK}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="CCX"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.ccx !== curValues.ccx
                }
              >
                {({ getFieldValue }) => {
                  const ccx = getFieldValue("ccx") || undefined;
                  return ccx ? (
                    <Typography.Text className="ant-form-text" strong>
                      {ccx}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          {dataDetail &&
          Object.keys(dataDetail).length > 0 &&
          dataDetail.loaiBoChiSo === BO_CHI_SO_KTCT.KT ? (
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  label="KT"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoKT !== curValues.chiSoKT
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoKT = getFieldValue("chiSoKT") || undefined;
                    return chiSoKT ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoKT}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>
            </Row>
          ) : dataDetail &&
            Object.keys(dataDetail).length > 0 &&
            dataDetail.loaiBoChiSo === BO_CHI_SO_KTCT.KT_VC ? (
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  label="KT"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoKT !== curValues.chiSoKT
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoKT = getFieldValue("chiSoKT") || undefined;
                    return chiSoKT ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoKT}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="VC"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoVC !== curValues.chiSoVC
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoVC = getFieldValue("chiSoVC") || undefined;
                    return chiSoVC ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoVC}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>
            </Row>
          ) : dataDetail &&
            Object.keys(dataDetail).length > 0 &&
            dataDetail.loaiBoChiSo === BO_CHI_SO_KTCT.KT_KN ? (
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  label="KT"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoKT !== curValues.chiSoKT
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoKT = getFieldValue("chiSoKT") || undefined;
                    return chiSoKT ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoKT}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="KN"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoKN !== curValues.chiSoKN
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoKN = getFieldValue("chiSoKN") || undefined;
                    return chiSoKN ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoKN}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>
            </Row>
          ) : dataDetail &&
            Object.keys(dataDetail).length > 0 &&
            (dataDetail.loaiBoChiSo.indexOf(BO_CHI_SO_KTCT.BN) !== -1 ||
              dataDetail.loaiBoChiSo.indexOf(BO_CHI_SO_KTCT.CN) !== -1 ||
              dataDetail.loaiBoChiSo.indexOf(BO_CHI_SO_KTCT.TN) !== -1) ? (
            <Fragment>
              <Row gutter={24}>
                <Col span={5}>
                  <Form.Item
                    label="BT"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoBT !== curValues.chiSoBT
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoBT = getFieldValue("chiSoBT") || undefined;
                      return chiSoBT ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoBT}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    label="CD"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoCD !== curValues.chiSoCD
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoCD = getFieldValue("chiSoCD") || undefined;
                      return chiSoCD ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoCD}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>{" "}
                <Col span={5}>
                  <Form.Item
                    label="TD"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoTD !== curValues.chiSoTD
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoTD = getFieldValue("chiSoTD") || undefined;
                      return chiSoTD ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoTD}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>{" "}
                <Col span={5}>
                  <Form.Item
                    label="VC"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoVC !== curValues.chiSoVC
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoVC = getFieldValue("chiSoVC") || undefined;
                      return chiSoVC ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoVC}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="SG"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoSG !== curValues.chiSoSG
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoSG = getFieldValue("chiSoSG") || undefined;
                      return chiSoSG ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoSG}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={5}>
                  <Form.Item
                    label="BN"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoBN !== curValues.chiSoBN
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoBN = getFieldValue("chiSoBN") || undefined;
                      return chiSoBN ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoBN}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    label="CN"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoCD !== curValues.chiSoCD
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoCD = getFieldValue("chiSoCD") || undefined;
                      return chiSoCD ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoCD}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>{" "}
                <Col span={5}>
                  <Form.Item
                    label="TN"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoTN !== curValues.chiSoTN
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoTN = getFieldValue("chiSoTN") || undefined;
                      return chiSoTN ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoTN}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>{" "}
                <Col span={5}>
                  <Form.Item
                    label="VN"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoVN !== curValues.chiSoVN
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoVN = getFieldValue("chiSoVN") || undefined;
                      return chiSoVN ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoVN}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="SN"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoSN !== curValues.chiSoSN
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoSN = getFieldValue("chiSoSN") || undefined;
                      return chiSoSN ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoSN}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
              </Row>{" "}
            </Fragment>
          ) : (
            <Row gutter={24}>
              <Col span={5}>
                <Form.Item
                  label="BT"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoBT !== curValues.chiSoBT
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoBT = getFieldValue("chiSoBT") || undefined;
                    return chiSoBT ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoBT}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="CD"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoCD !== curValues.chiSoCD
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoCD = getFieldValue("chiSoCD") || undefined;
                    return chiSoCD ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoCD}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>{" "}
              <Col span={5}>
                <Form.Item
                  label="TD"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoTD !== curValues.chiSoTD
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoTD = getFieldValue("chiSoTD") || undefined;
                    return chiSoTD ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoTD}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>{" "}
              <Col span={5}>
                <Form.Item
                  label="VC"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoVC !== curValues.chiSoVC
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoVC = getFieldValue("chiSoVC") || undefined;
                    return chiSoVC ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoVC}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="SG"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoSG !== curValues.chiSoSG
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoSG = getFieldValue("chiSoSG") || undefined;
                    return chiSoSG ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoSG}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="Mã chì kiểm định"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.maSoChiKiemDinh !== curValues.maSoChiKiemDinh
                }
              >
                {({ getFieldValue }) => {
                  const maSoChiKiemDinh =
                    getFieldValue("maSoChiKiemDinh") || undefined;
                  return maSoChiKiemDinh ? (
                    <Typography.Text className="ant-form-text" strong>
                      {maSoChiKiemDinh}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Chì hộp đấu dây"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.chiHopDauDay !== curValues.chiHopDauDay
                }
              >
                {({ getFieldValue }) => {
                  const chiHopDauDay =
                    getFieldValue("chiHopDauDay") || undefined;
                  return chiHopDauDay ? (
                    <Typography.Text className="ant-form-text" strong>
                      {chiHopDauDay}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Chì hòm"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.chiHom !== curValues.chiHom
                }
              >
                {({ getFieldValue }) => {
                  const chiHom = getFieldValue("chiHom") || undefined;
                  return chiHom ? (
                    <Typography.Text className="ant-form-text" strong>
                      {chiHom}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Kẹp chì niêm phong"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.kepChiNiemPhong !== curValues.kepChiNiemPhong
                }
              >
                {({ getFieldValue }) => {
                  const kepChiNiemPhong =
                    getFieldValue("kepChiNiemPhong") || undefined;
                  return kepChiNiemPhong ? (
                    <Typography.Text className="ant-form-text" strong>
                      {kepChiNiemPhong}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="Tem"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tinhTrangCongTo !== curValues.tinhTrangCongTo
                }
              >
                {({ getFieldValue }) => {
                  const tem = getFieldValue("tem") || undefined;
                  return tem ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tem}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Đai"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.dai !== curValues.dai
                }
              >
                {({ getFieldValue }) => {
                  const dai = getFieldValue("dai") || undefined;
                  return dai ? (
                    <Typography.Text className="ant-form-text" strong>
                      {dai}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Khóa"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.khoa !== curValues.khoa
                }
              >
                {({ getFieldValue }) => {
                  const khoa = getFieldValue("khoa") || undefined;
                  return khoa ? (
                    <Typography.Text className="ant-form-text" strong>
                      {khoa}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Sơ đồ đấu dây"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.soDoDauDay !== curValues.soDoDauDay
                }
              >
                {({ getFieldValue }) => {
                  const soDoDauDay = getFieldValue("soDoDauDay") || undefined;
                  return soDoDauDay ? (
                    <Typography.Text className="ant-form-text" strong>
                      {soDoDauDay}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Ghi chú"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.ghiChu !== curValues.ghiChu
                }
              >
                {({ getFieldValue }) => {
                  const ghiChu = getFieldValue("ghiChu") || undefined;
                  return ghiChu ? (
                    <Typography.Text className="ant-form-text" strong>
                      {ghiChu}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Kiến nghị cần giải quyết"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.kienNghiCanGiaiQuyet !==
                  curValues.kienNghiCanGiaiQuyet
                }
              >
                {({ getFieldValue }) => {
                  const kienNghiCanGiaiQuyet =
                    getFieldValue("kienNghiCanGiaiQuyet") || undefined;
                  return kienNghiCanGiaiQuyet ? (
                    <Typography.Text className="ant-form-text" strong>
                      {kienNghiCanGiaiQuyet &&
                        kienNghiCanGiaiQuyet.split("/n/n").map((item) => {
                          return (
                            <Fragment>
                              <span>{item}</span>
                              <br />
                            </Fragment>
                          );
                        })}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      ) : typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY &&
        typeSoPha === ENUM_SOPHA.BA_PHA ? (
        <Col span={24}>
          <span className="fs-16px font-bold">Thông tin kiểm tra</span>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Mã công tơ"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.maCongTo !== curValues.maCongTo
                }
              >
                {({ getFieldValue }) => {
                  const maCongTo = getFieldValue("maCongTo") || undefined;
                  return maCongTo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {maCongTo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Loại công tơ"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.loaiCongTo !== curValues.loaiCongTo
                }
              >
                {({ getFieldValue }) => {
                  const loaiCongTo = getFieldValue("loaiCongTo") || undefined;
                  return loaiCongTo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {loaiCongTo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Năm SX/ Nước SX"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.namVaNuocSanXuat !== curValues.namVaNuocSanXuat
                }
              >
                {({ getFieldValue }) => {
                  const namVaNuocSanXuat =
                    getFieldValue("namVaNuocSanXuat") || undefined;
                  return namVaNuocSanXuat ? (
                    <Typography.Text className="ant-form-text" strong>
                      {namVaNuocSanXuat}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Tình trạng công tơ"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tinhTrangCongTo !== curValues.tinhTrangCongTo
                }
              >
                {({ getFieldValue }) => {
                  const tinhTrangCongTo =
                    getFieldValue("tinhTrangCongTo") || undefined;
                  return tinhTrangCongTo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tinhTrangCongTo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Kết luận"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.ketLuan !== curValues.ketLuan
                }
              >
                {({ getFieldValue }) => {
                  const ketLuan = getFieldValue("ketLuan") || undefined;
                  return ketLuan ? (
                    <Typography.Text
                      className="ant-form-text"
                      style={{ color: "rgb(18 34 239)" }}
                      strong
                    >
                      {ketLuan &&
                        ketLuan.split("/n").map((item) => {
                          return (
                            <Fragment>
                              <span>{item}</span>
                              <br />
                            </Fragment>
                          );
                        })}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Sai số công tơ"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.ketQuaSaiSoCongTo !== curValues.ketQuaSaiSoCongTo
                }
              >
                {({ getFieldValue }) => {
                  const ketQuaSaiSoCongTo =
                    getFieldValue("ketQuaSaiSoCongTo") || undefined;
                  return ketQuaSaiSoCongTo ? (
                    <Typography.Text
                      className="ant-form-text"
                      style={{ color: "rgb(18 34 239)" }}
                      strong
                    >
                      {ketQuaSaiSoCongTo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="U"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.u !== curValues.u
                }
              >
                {({ getFieldValue }) => {
                  const u = getFieldValue("u") || undefined;
                  return u ? (
                    <Typography.Text className="ant-form-text" strong>
                      {u}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="I"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.i !== curValues.i
                }
              >
                {({ getFieldValue }) => {
                  const i = getFieldValue("i") || undefined;
                  return i ? (
                    <Typography.Text className="ant-form-text" strong>
                      {i}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="K"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.heSoK !== curValues.heSoK
                }
              >
                {({ getFieldValue }) => {
                  const heSoK = getFieldValue("heSoK") || undefined;
                  return heSoK ? (
                    <Typography.Text className="ant-form-text" strong>
                      {heSoK}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="CCX"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.ccx !== curValues.ccx
                }
              >
                {({ getFieldValue }) => {
                  const ccx = getFieldValue("ccx") || undefined;
                  return ccx ? (
                    <Typography.Text className="ant-form-text" strong>
                      {ccx}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          {dataDetail &&
          Object.keys(dataDetail).length > 0 &&
          dataDetail.loaiBoChiSo === BO_CHI_SO_KTCT.KT ? (
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  label="KT"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoKT !== curValues.chiSoKT
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoKT = getFieldValue("chiSoKT") || undefined;
                    return chiSoKT ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoKT}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>
            </Row>
          ) : dataDetail &&
            Object.keys(dataDetail).length > 0 &&
            dataDetail.loaiBoChiSo === BO_CHI_SO_KTCT.KT_VC ? (
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  label="KT"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoKT !== curValues.chiSoKT
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoKT = getFieldValue("chiSoKT") || undefined;
                    return chiSoKT ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoKT}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="VC"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoVC !== curValues.chiSoVC
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoVC = getFieldValue("chiSoVC") || undefined;
                    return chiSoVC ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoVC}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>
            </Row>
          ) : dataDetail &&
            Object.keys(dataDetail).length > 0 &&
            dataDetail.loaiBoChiSo === BO_CHI_SO_KTCT.KT_KN ? (
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  label="KT"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoKT !== curValues.chiSoKT
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoKT = getFieldValue("chiSoKT") || undefined;
                    return chiSoKT ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoKT}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="KN"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoKN !== curValues.chiSoKN
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoKN = getFieldValue("chiSoKN") || undefined;
                    return chiSoKN ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoKN}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>
            </Row>
          ) : dataDetail &&
            Object.keys(dataDetail).length > 0 &&
            (dataDetail.loaiBoChiSo.indexOf(BO_CHI_SO_KTCT.BN) !== -1 ||
              dataDetail.loaiBoChiSo.indexOf(BO_CHI_SO_KTCT.CN) !== -1 ||
              dataDetail.loaiBoChiSo.indexOf(BO_CHI_SO_KTCT.TN) !== -1) ? (
            <Fragment>
              <Row gutter={24}>
                <Col span={5}>
                  <Form.Item
                    label="BT"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoBT !== curValues.chiSoBT
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoBT = getFieldValue("chiSoBT") || undefined;
                      return chiSoBT ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoBT}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    label="CD"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoCD !== curValues.chiSoCD
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoCD = getFieldValue("chiSoCD") || undefined;
                      return chiSoCD ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoCD}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>{" "}
                <Col span={5}>
                  <Form.Item
                    label="TD"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoTD !== curValues.chiSoTD
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoTD = getFieldValue("chiSoTD") || undefined;
                      return chiSoTD ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoTD}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>{" "}
                <Col span={5}>
                  <Form.Item
                    label="VC"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoVC !== curValues.chiSoVC
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoVC = getFieldValue("chiSoVC") || undefined;
                      return chiSoVC ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoVC}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="SG"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoSG !== curValues.chiSoSG
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoSG = getFieldValue("chiSoSG") || undefined;
                      return chiSoSG ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoSG}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={5}>
                  <Form.Item
                    label="BN"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoBN !== curValues.chiSoBN
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoBN = getFieldValue("chiSoBN") || undefined;
                      return chiSoBN ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoBN}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    label="CN"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoCD !== curValues.chiSoCD
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoCD = getFieldValue("chiSoCD") || undefined;
                      return chiSoCD ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoCD}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>{" "}
                <Col span={5}>
                  <Form.Item
                    label="TN"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoTN !== curValues.chiSoTN
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoTN = getFieldValue("chiSoTN") || undefined;
                      return chiSoTN ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoTN}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>{" "}
                <Col span={5}>
                  <Form.Item
                    label="VN"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoVN !== curValues.chiSoVN
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoVN = getFieldValue("chiSoVN") || undefined;
                      return chiSoVN ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoVN}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="SN"
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.chiSoSN !== curValues.chiSoSN
                    }
                  >
                    {({ getFieldValue }) => {
                      const chiSoSN = getFieldValue("chiSoSN") || undefined;
                      return chiSoSN ? (
                        <Typography.Text className="ant-form-text" strong>
                          {chiSoSN}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
              </Row>{" "}
            </Fragment>
          ) : (
            <Row gutter={24}>
              <Col span={5}>
                <Form.Item
                  label="BT"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoBT !== curValues.chiSoBT
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoBT = getFieldValue("chiSoBT") || undefined;
                    return chiSoBT ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoBT}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="CD"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoCD !== curValues.chiSoCD
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoCD = getFieldValue("chiSoCD") || undefined;
                    return chiSoCD ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoCD}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>{" "}
              <Col span={5}>
                <Form.Item
                  label="TD"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoTD !== curValues.chiSoTD
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoTD = getFieldValue("chiSoTD") || undefined;
                    return chiSoTD ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoTD}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>{" "}
              <Col span={5}>
                <Form.Item
                  label="VC"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoVC !== curValues.chiSoVC
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoVC = getFieldValue("chiSoVC") || undefined;
                    return chiSoVC ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoVC}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="SG"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.chiSoSG !== curValues.chiSoSG
                  }
                >
                  {({ getFieldValue }) => {
                    const chiSoSG = getFieldValue("chiSoSG") || undefined;
                    return chiSoSG ? (
                      <Typography.Text className="ant-form-text" strong>
                        {chiSoSG}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="Pmax"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.pMax !== curValues.pMax
                }
              >
                {({ getFieldValue }) => {
                  const pMax = getFieldValue("pMax") || undefined;
                  return pMax ? (
                    <Typography.Text className="ant-form-text" strong>
                      {pMax}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="H1Pmax"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.h1Max !== curValues.h1Max
                }
              >
                {({ getFieldValue }) => {
                  const h1Max = getFieldValue("h1Max") || undefined;
                  return h1Max ? (
                    <Typography.Text className="ant-form-text" strong>
                      {h1Max}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="t/đ Pmax"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tdpMax !== curValues.tdpMax
                }
              >
                {({ getFieldValue }) => {
                  const tdpMax = getFieldValue("tdpMax") || undefined;
                  return tdpMax ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tdpMax}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="t/đ H1 Pmax"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tdH1Max !== curValues.tdH1Max
                }
              >
                {({ getFieldValue }) => {
                  const tdH1Max = getFieldValue("tdH1Max") || undefined;
                  return tdH1Max ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tdH1Max}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Tình trạng niêm phong"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tinhTrangNiemPhong !== curValues.tinhTrangNiemPhong
                }
              >
                {({ getFieldValue }) => {
                  const tinhTrangNiemPhong =
                    getFieldValue("tinhTrangNiemPhong") || undefined;
                  return tinhTrangNiemPhong ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tinhTrangNiemPhong}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Kẹp chì niêm phong"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.kepChiNiemPhong !== curValues.kepChiNiemPhong
                }
              >
                {({ getFieldValue }) => {
                  const kepChiNiemPhong =
                    getFieldValue("kepChiNiemPhong") || undefined;
                  return kepChiNiemPhong ? (
                    <Typography.Text className="ant-form-text" strong>
                      {kepChiNiemPhong}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Sơ đồ đấu dây"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.soDoDauDay !== curValues.soDoDauDay
                }
              >
                {({ getFieldValue }) => {
                  const soDoDauDay = getFieldValue("soDoDauDay") || undefined;
                  return soDoDauDay ? (
                    <Typography.Text className="ant-form-text" strong>
                      {soDoDauDay}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="Mã số chì kiểm định"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.maSoChiKiemDinh !== curValues.maSoChiKiemDinh
                }
              >
                {({ getFieldValue }) => {
                  const maSoChiKiemDinh =
                    getFieldValue("maSoChiKiemDinh") || undefined;
                  return maSoChiKiemDinh ? (
                    <Typography.Text className="ant-form-text" strong>
                      {maSoChiKiemDinh}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Số lượng"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.soLuongChiKiemDinh !== curValues.soLuongChiKiemDinh
                }
              >
                {({ getFieldValue }) => {
                  const soLuongChiKiemDinh =
                    getFieldValue("soLuongChiKiemDinh") || undefined;
                  return soLuongChiKiemDinh ? (
                    <Typography.Text className="ant-form-text" strong>
                      {soLuongChiKiemDinh}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Mã số chì lập trình"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.maSoChiLapTrinh !== curValues.maSoChiLapTrinh
                }
              >
                {({ getFieldValue }) => {
                  const maSoChiLapTrinh =
                    getFieldValue("maSoChiLapTrinh") || undefined;
                  return maSoChiLapTrinh ? (
                    <Typography.Text className="ant-form-text" strong>
                      {maSoChiLapTrinh}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Số lượng lập trình"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.soLuongChiLapTrinh !== curValues.soLuongChiLapTrinh
                }
              >
                {({ getFieldValue }) => {
                  const soLuongChiLapTrinh =
                    getFieldValue("soLuongChiLapTrinh") || undefined;
                  return soLuongChiLapTrinh ? (
                    <Typography.Text className="ant-form-text" strong>
                      {soLuongChiLapTrinh}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="Mã số chì cổng quan"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.maSoChiCongQuang !== curValues.maSoChiCongQuang
                }
              >
                {({ getFieldValue }) => {
                  const maSoChiCongQuang =
                    getFieldValue("maSoChiCongQuang") || undefined;
                  return maSoChiCongQuang ? (
                    <Typography.Text className="ant-form-text" strong>
                      {maSoChiCongQuang}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Số lượng"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.soLuongChiCongQuang !==
                  curValues.soLuongChiCongQuang
                }
              >
                {({ getFieldValue }) => {
                  const soLuongChiCongQuang =
                    getFieldValue("soLuongChiCongQuang") || undefined;
                  return soLuongChiCongQuang ? (
                    <Typography.Text className="ant-form-text" strong>
                      {soLuongChiCongQuang}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Mã số hộp chì đấu dây"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.maSoHopChiDauDay !== curValues.maSoHopChiDauDay
                }
              >
                {({ getFieldValue }) => {
                  const maSoHopChiDauDay =
                    getFieldValue("maSoHopChiDauDay") || undefined;
                  return maSoHopChiDauDay ? (
                    <Typography.Text className="ant-form-text" strong>
                      {maSoHopChiDauDay}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Số lượng đấu dây"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.soLuongHopChiDauDay !==
                  curValues.soLuongHopChiDauDay
                }
              >
                {({ getFieldValue }) => {
                  const soLuongHopChiDauDay =
                    getFieldValue("soLuongHopChiDauDay") || undefined;
                  return soLuongHopChiDauDay ? (
                    <Typography.Text className="ant-form-text" strong>
                      {soLuongHopChiDauDay}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="Mã số chì hòm"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.maSoChiHom !== curValues.maSoChiHom
                }
              >
                {({ getFieldValue }) => {
                  const maSoChiHom = getFieldValue("maSoChiHom") || undefined;
                  return maSoChiHom ? (
                    <Typography.Text className="ant-form-text" strong>
                      {maSoChiHom}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Số lượng"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.soLuongChiHom !== curValues.soLuongChiHom
                }
              >
                {({ getFieldValue }) => {
                  const soLuongChiHom =
                    getFieldValue("soLuongChiHom") || undefined;
                  return soLuongChiHom ? (
                    <Typography.Text className="ant-form-text" strong>
                      {soLuongChiHom}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Tem quản lý kiểm định"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.temQuanLyKiemDinh !== curValues.temQuanLyKiemDinh
                }
              >
                {({ getFieldValue }) => {
                  const temQuanLyKiemDinh =
                    getFieldValue("temQuanLyKiemDinh") || undefined;
                  return temQuanLyKiemDinh ? (
                    <Typography.Text className="ant-form-text" strong>
                      {temQuanLyKiemDinh}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Ngày kiểm định của tem"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.ngayDanTemKiemDinh !== curValues.ngayDanTemKiemDinh
                }
              >
                {({ getFieldValue }) => {
                  const ngayDanTemKiemDinh =
                    getFieldValue("ngayDanTemKiemDinh") || undefined;
                  return ngayDanTemKiemDinh ? (
                    <Typography.Text className="ant-form-text" strong>
                      {ngayDanTemKiemDinh}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Số tem niêm phong cổng quang"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.soTemNiemPhongCongQuang !==
                  curValues.soTemNiemPhongCongQuang
                }
              >
                {({ getFieldValue }) => {
                  const soTemNiemPhongCongQuang =
                    getFieldValue("soTemNiemPhongCongQuang") || undefined;
                  return soTemNiemPhongCongQuang ? (
                    <Typography.Text className="ant-form-text" strong>
                      {soTemNiemPhongCongQuang}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Số lần cảnh báo"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.soLanCanhBao !== curValues.soLanCanhBao
                }
              >
                {({ getFieldValue }) => {
                  const soLanCanhBao =
                    getFieldValue("soLanCanhBao") || undefined;
                  return soLanCanhBao ? (
                    <Typography.Text className="ant-form-text" strong>
                      {soLanCanhBao}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Kiến nghị cần giải quyết"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.kienNghiCanGiaiQuyet !==
                  curValues.kienNghiCanGiaiQuyet
                }
              >
                {({ getFieldValue }) => {
                  const kienNghiCanGiaiQuyet =
                    getFieldValue("kienNghiCanGiaiQuyet") || undefined;
                  return kienNghiCanGiaiQuyet ? (
                    <Typography.Text className="ant-form-text" strong>
                      {kienNghiCanGiaiQuyet &&
                        kienNghiCanGiaiQuyet.split("/n/n").map((item) => {
                          return (
                            <Fragment>
                              <span>{item}</span>
                              <br />
                            </Fragment>
                          );
                        })}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      ) : null}
    </Fragment>
  );
}
