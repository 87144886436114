import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleListChungLoaiVatTu } from "../../../services";
import DuTruVatTuCapDienTabs from "./tabs-du-tru-vat-tu-cap-dien";

function Index(props) {
  const { indexTab, detailLapPhuongAn, mode } = props;
  const [, setLoading] = useState(true);
  const [dataChungLoaiVatTu, setDataChungLoaiVatTu] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    handleListChungLoaiVatTu(setLoading, setDataChungLoaiVatTu);
  }, []);

  return (
    <DuTruVatTuCapDienTabs
      dataChungLoaiVatTu={dataChungLoaiVatTu}
      t={t}
      indexTab={indexTab}
      detailLapPhuongAn={detailLapPhuongAn}
      mode={mode}
    />
  );
}
export default memo(Index);
