import React from "react";
import { useTranslation } from "react-i18next";
import { LIST_TILEGIABANDIEN } from "@utils/constants";
import ApGiaHoSoTabs from "./tabs-ap-gia-ho-so";
export default function Index({ loading, detailApGia, detail }) {
  const { t } = useTranslation();
  return (
    <ApGiaHoSoTabs
      t={t}
      loading={loading}
      detailApGia={detailApGia}
      LIST_TILEGIABANDIEN={LIST_TILEGIABANDIEN}
      detail={detail}
    />
  );
}
