import { Endpoint } from "@utils/endpoint";
import React from "react";
import Screen1 from "./screen/screen1";

export default function PhieuXacMinhChiSoCongTo() {
  const columns = [
    {
      title: "STT",
      dataIndex: "key",
      width: 50,
      align: "center",
    },
    {
      title: "Họ tên khách hàng",
      dataIndex: "hoTenKhachHang",
      align: "center",
      width: 200,
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      align: "center",
      width: 200,
    },
    {
      title: "Mã GCS",
      dataIndex: "maGCS",
      align: "center",
      width: 100,
    },
    {
      title: "Số CT",
      dataIndex: "soCongTo",
      align: "center",
      width: 100,
    },
  ];
  const endPoint = {
    LIST: Endpoint.LIST_PHIEU_XAC_MINH,
    EXCEL: undefined,
    PDF: Endpoint.XUAT_PDF_PHIEU_XAC_MINH,
    ...Endpoint,
  };
  return (
    <Screen1
      header={"Phiếu xác minh chỉ số công tơ"}
      columns={columns}
      endPoint={endPoint}
    />
  );
}
