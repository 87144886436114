import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { handleDataHoSo, handleListImage, handleListLoaiGT } from "./services";
import ChiTietHoSoModal from "./modal-chi-tiet-ho-so";
import { useTranslation } from "react-i18next";
import { INDEX_TAB2 } from "@utils/constants";
import { handleScroll } from "@state/system-config/reducer";

function Index({ detailHoSo }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState([]);
  const [dataTroNgai, setDataTroNgai] = useState([]);
  const [dataTraLai, setDataTraLai] = useState([]);
  const [, setDataAnhHoSo] = useState([]);
  const [dataNhatKiHoSo, setDataNhatKiHoSo] = useState([]);
  const [dataTienTrinh, setDataTienTrinh] = useState([]);
  const [indexTab, setIndexTab] = useState("1");
  const [dataImages, setDataImages] = useState();
  const [dataLoaiGT, setDataLoaiGT] = useState([]);
  const [firstLoaiGT, setFirstLoaiGT] = useState("");
  const [listId, setListId] = useState([]);
  const [nguoiThan, setNguoiThan] = useState([]);
  const [thongTinCongTo, setThongTinCongTo] = useState({});

  const callback = (key) => {
    if (key === INDEX_TAB2) {
      dispatch(
        handleScroll({
          scrollHeight: false,
        })
      );
    } else {
      dispatch(
        handleScroll({
          scrollHeight: true,
        })
      );
    }
    setIndexTab(key);
  };

  useEffect(() => {
    handleDataHoSo(
      detailHoSo.id ?? detailHoSo.hoSoId,
      setLoadingDetail,
      setDataDetail,
      setDataAnhHoSo,
      setDataNhatKiHoSo,
      setDataTienTrinh,
      setDataTroNgai,
      setDataTraLai,
      setNguoiThan,
      setThongTinCongTo
    );
    handleListLoaiGT(
      detailHoSo.id ?? detailHoSo.hoSoId,
      setLoading,
      setDataLoaiGT,
      setFirstLoaiGT
    );
  }, [detailHoSo]);

  const getListImages = useCallback(() => {
    handleListImage(detailHoSo, setLoading, setDataImages, setListId);
  }, [detailHoSo]);

  useEffect(() => {
    getListImages();
  }, [getListImages]);

  const handleChangeType = (value) => {
    setFirstLoaiGT(value);
    scrollToTop(`data-${value}`);
  };

  const scrollToTop = (element) => {
    document
      .getElementById(element)
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <ChiTietHoSoModal
      indexTab={indexTab}
      callback={callback}
      dataDetail={dataDetail}
      loading={loading}
      loadingDetail={loadingDetail}
      dataNhatKiHoSo={dataNhatKiHoSo}
      dataTienTrinh={dataTienTrinh}
      dispatch={dispatch}
      t={t}
      dataLoaiGT={dataLoaiGT}
      firstLoaiGT={firstLoaiGT}
      handleChangeType={handleChangeType}
      dataImages={dataImages}
      listId={listId}
      dataTraLai={dataTraLai}
      dataTroNgai={dataTroNgai}
      nguoiThan={nguoiThan}
      thongTinCongTo={thongTinCongTo}
      maLoaiYeuCau={detailHoSo?.maLoaiYeuCau}
      recordRow={detailHoSo}
    />
  );
}

export default memo(Index);
