import React, { Fragment } from "react";
import { Col, Divider, Row } from "antd";
import FormComponent from "@components/Form";
import ThongTinChungPanels from "../../panels/panels-phuong-an-cap-dien/panels-thong-tin-chung";
import TramCapDienPanels from "../../panels/panels-phuong-an-cap-dien/panels-tram-cap-dien";
import CongToPanels from "../../panels/panels-phuong-an-cap-dien/panels-cong-to";
import LuuYThiCongPanels from "../../panels/panels-phuong-an-cap-dien/panels-luu-y-thi-cong";
import SoDoCapDienPanels from "../../panels/panels-phuong-an-cap-dien/panels-so-do-cap-dien";
import LoadingComponent from "@components/Loading";
import { debounce } from "lodash";
import Title from "antd/lib/typography/Title";
import { ENUM_MODE_PHUONGANCAPDIEN } from "@utils/constants";
import CongSuatThietBiTabs from "../tab-cong-suat-thiet-bi-dien";
import DuTruVatTuCapDienTabs from "../tab-du-tru-vat-tu-cap-dien";
import TyLeGiaBanDienPanels from "../../panels/panels-phuong-an-cap-dien/panels-ty-le-gia-ban-dien";
import ThongTinCoBanPanels from "../../panels/panels-phuong-an-cap-dien/panels-thong-tin-co-ban";

export default function PhuongAnCapDienTabs(props) {
  const {
    loading,
    form,
    handleSubmit,
    handleFieldChange,
    mode,
    listTram,
    listLo,
    listQuyen,
    listHinhThucLapDat,
    dataPhuongAn,
    loadingDayDanDien,
    listDayDanDien,
    detailLapPhuongAn,
    setChangeSoDo,
    dataSoPha,
    dataTinhTrangSuDung,
    dataHinhThucGhiChiSo,
    dataLoaiHopDong,
    dataGioiTinhKH,
    dataLanhDao,
    dataTinhTrangThu,
    dataMucDich,
    dataDiaChinh,
    dataHinhThucTraTien,
    dataThongTinCoBan,
    dataXaPhuongGiaoDich,
    dataXaPhuongSuDung,
  } = props;

  return (
    <LoadingComponent loading={loading}>
      <FormComponent
        autoComplete="off"
        form={form}
        name="form-phuong-an-cap-dien"
        onFinish={handleSubmit}
        initialValues={{}}
        layout="vertical"
        onValuesChange={debounce(handleFieldChange, 200)}
        scrollToFirstError
      >
        <Row gutter={24}>
          {/* <Col span={24}>
            <Row gutter={24}>
              <Col span={24} style={{ height: "35px" }}>
                <Space direction="horizontal">
                  <div className="fs-14px" style={{ marginBottom: 22 }}>
                    Lãnh đạo duyệt hồ sơ:{" "}
                  </div>
                  <Form.Item
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.userNamLanhDaoDuyetHS !==
                      curValues.userNamLanhDaoDuyetHS
                    }
                  >
                    {({ getFieldValue }) => {
                      const userNamLanhDaoDuyetHS =
                        getFieldValue("userNamLanhDaoDuyetHS") || undefined;
                      return userNamLanhDaoDuyetHS ? (
                        <Typography.Text
                          className="ant-form-text"
                          strong
                          level={5}
                        >
                          {userNamLanhDaoDuyetHS}&nbsp;&nbsp;-
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.tenLanhDaoDuyetHS !==
                      curValues.tenLanhDaoDuyetHS
                    }
                    style={{ marginLeft: "-10px" }}
                  >
                    {({ getFieldValue }) => {
                      const tenLanhDaoDuyetHS =
                        getFieldValue("tenLanhDaoDuyetHS") || undefined;
                      return tenLanhDaoDuyetHS ? (
                        <Typography.Text
                          className="ant-form-text"
                          strong
                          level={5}
                        >
                          {tenLanhDaoDuyetHS}
                        </Typography.Text>
                      ) : null;
                    }}
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          </Col> */}
          {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
            <Fragment>
              <Col span={24} md={24}>
                <Divider orientation="left" orientationMargin="0">
                  <Title level={4}>I. Thông tin cơ bản</Title>
                </Divider>
              </Col>
              <ThongTinCoBanPanels
                dataSoPha={dataSoPha}
                dataTinhTrangSuDung={dataTinhTrangSuDung}
                dataHinhThucGhiChiSo={dataHinhThucGhiChiSo}
                dataLoaiHopDong={dataLoaiHopDong}
                dataGioiTinhKH={dataGioiTinhKH}
                dataLanhDao={dataLanhDao}
                dataTinhTrangThu={dataTinhTrangThu}
                dataMucDich={dataMucDich}
                dataDiaChinh={dataDiaChinh}
                dataHinhThucTraTien={dataHinhThucTraTien}
                dataThongTinCoBan={dataThongTinCoBan}
                dataXaPhuongGiaoDich={dataXaPhuongGiaoDich}
                dataXaPhuongSuDung={dataXaPhuongSuDung}
                dataPhuongAn={dataPhuongAn}
              />
            </Fragment>
          ) : null}
          {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
            <Col span={24} md={24}>
              <Divider orientation="left" orientationMargin="0">
                <Title level={4}>II. Phương án cấp điện</Title>
              </Divider>
            </Col>
          ) : null}

          <ThongTinChungPanels form={form} mode={mode} />
          <TramCapDienPanels
            listTram={listTram}
            listLo={listLo}
            listQuyen={listQuyen}
            mode={mode}
          />
          <CongToPanels
            form={form}
            listHinhThucLapDat={listHinhThucLapDat}
            mode={mode}
            dataPhuongAn={dataPhuongAn}
            loadingDayDanDien={loadingDayDanDien}
            listDayDanDien={listDayDanDien}
          />
          <LuuYThiCongPanels form={form} mode={mode} />
          <SoDoCapDienPanels
            form={form}
            mode={mode}
            handleFieldChange={handleFieldChange}
            setChangeSoDo={setChangeSoDo}
          />
          {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
            <TyLeGiaBanDienPanels detaiHoSo={detailLapPhuongAn} />
          ) : null}
          {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
            <Fragment>
              <Col span={24} md={24}>
                <Divider orientation="left" orientationMargin="0">
                  <Title level={4}>III. Công suất, thiết bị sử dụng điện</Title>
                </Divider>
              </Col>
              <Col span={24} md={24}>
                <CongSuatThietBiTabs
                  detailLapPhuongAn={detailLapPhuongAn}
                  indexTab={"2"}
                  mode={mode}
                />
              </Col>
            </Fragment>
          ) : null}
          {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
            <Fragment>
              <Col span={24} md={24} style={{ marginTop: 24 }}>
                <Divider orientation="left" orientationMargin="0">
                  <Title level={5}>IV. Dự trù vật tư cấp điện và chi phí</Title>
                </Divider>
              </Col>
              <Col span={24} md={24}>
                <DuTruVatTuCapDienTabs
                  detailLapPhuongAn={detailLapPhuongAn}
                  indexTab={"3"}
                  mode={mode}
                />
              </Col>
            </Fragment>
          ) : null}
        </Row>
      </FormComponent>
    </LoadingComponent>
  );
}
