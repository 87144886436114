import { Checkbox, Col, Form, Row } from "antd";
import { Fragment } from "react";
import ThongTinChung from "../thong-tin-chung";

import { ColLgSpan, TableInfo } from "./css-styled";

export default function ThongTinThaoTabs(props) {
  const { detail, form, isThaoCongTo, handleChange } = props;
  return (
    <Fragment>
      <Row
        gutter={24}
        align="center"
        justify="center"
        style={{ alignItems: "center" }}
      >
        <Col
          span={24}
          md={24}
          style={{ display: "flex", alignItems: "center", marginTop: "2px" }}
        >
          <Form.Item name={"defaultCTThao"} hidden />
          <Form.Item name="isThaoCongTo" style={{ marginBottom: "12px" }}>
            <Checkbox checked={isThaoCongTo} onChange={(e) => handleChange(e)}>
              Chỉ tháo công tơ
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <ThongTinChung detail={detail} formThao form={form} />
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <TableInfo gutter={24}>
            {detail.boChiSos.map((item, index) => (
              <Fragment key={index}>
                <Col span={2}>
                  <div style={{ marginBottom: "8px", fontWeight: "bold" }}>
                    BCS
                  </div>
                  <div style={{ fontWeight: "bold" }}>Pmax</div>
                </Col>
                <Col span={4}>
                  <div
                    style={{
                      marginBottom: "8px",
                      fontWeight: "bold",
                      color: "#0018FF",
                    }}
                  >
                    {item.bcs}
                  </div>
                  <div style={{ fontWeight: "bold" }}>{item.pMax}</div>
                </Col>
                <ColLgSpan span={4}>
                  <div
                    style={{
                      marginBottom: "8px",
                      fontWeight: "bold",
                      color: "#027A06",
                    }}
                  >
                    CS đầu kỳ
                  </div>
                  <div></div>
                </ColLgSpan>
                <Col span={4}>
                  <div style={{ marginBottom: "8px", fontWeight: "bold" }}>
                    {item.chiSoCu}
                  </div>
                  <div></div>
                </Col>
                <ColLgSpan span={4}>
                  <div style={{ marginBottom: "8px", fontWeight: "bold" }}>
                    CS cuối kỳ
                  </div>
                  <div style={{ fontWeight: "bold" }}>Sản lượng</div>
                </ColLgSpan>
                <Col span={4}>
                  <div
                    style={{
                      marginBottom: "8px",
                      color: "#0000FF",
                      fontWeight: "bold",
                    }}
                  >
                    {item.chiSoMoi}
                  </div>
                  <div style={{ fontWeight: "bold", color: "#F77A52" }}>
                    {item.sanLuongMoi}
                  </div>
                </Col>
                <Col span={4}></Col>
              </Fragment>
            ))}
          </TableInfo>
        </Col>
      </Row>
    </Fragment>
  );
}
