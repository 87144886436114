import React from "react";
import { Button, Modal } from "antd";
import LoadingComponent from "@components/Loading";
import { Container } from "./css-styled";

export default function Index(props) {
  const {
    visible,
    closeModal,
    handleDuyetHoSo,
    handleXacThucChuKy,
    t,
    loadingDuyet,
    loadingXacThu,
  } = props;

  return (
    <LoadingComponent loading={loadingDuyet || loadingXacThu}>
      <Modal
        title={"Hồ sơ đang ở Bộ phận kinh doanh"}
        visible={visible}
        onCancel={closeModal}
        footer={null}
      >
        <Container>
          <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
            Hồ sơ của bạn đang ở Phòng kinh doanh. Xác nhận sẽ chuyển tiếp cho
            Lãnh đạo !
          </h4>
        </Container>
        <div style={{ marginTop: "48px", textAlign: "center" }}>
          <Button
            key="back"
            onClick={closeModal}
            className="button-closed"
            style={{ marginRight: "24px" }}
          >
            {t("button.dong_lai")}
          </Button>
          <Button
            type="primary"
            className="btn-bg-yellow"
            onClick={handleDuyetHoSo}
            loading={loadingDuyet}
            key="agree"
            style={{ marginRight: "24px" }}
          >
            {t("button.xac_nhan")}
          </Button>
          {/* <Button
            type="primary"
            className="btn-bg-yellow"
            onClick={handleXacThucChuKy}
            loading={loadingXacThu}
            key="confirm"
          >
            {t("button.xac_thuc_chu_ky")}
          </Button> */}
        </div>
      </Modal>
    </LoadingComponent>
  );
}
