import React, { useState, useEffect, Fragment, useCallback } from "react";
import TableThongTinVatTu from "./table-thong-tin-vat-tu";
import { handleDetailThongTin, submitThongTinVT } from "../../services";
import { Form } from "antd";
import { isEqual } from "lodash";
import { notSavedThongTinThiCong } from "@state/cap-dien/thi-cong/xac-nhan-thi-cong/reducer";

export default function ThongTinVatTuTabs(props) {
  const {
    t,
    dispatch,
    closeModal,
    detail,
    setFetchDataTable,
    initVatTu,
    setInitVatTu,
    setScroll,
  } = props;

  const [form] = Form.useForm();
  const [dataBB, setDataBB] = useState([]);
  const [dataBM, setDataBM] = useState([]);
  const [dataTH, setDataTH] = useState([]);

  const [loading, setLoading] = useState(false);
  // const [indexChangeBB, setindexChangeBB] = useState(-1);
  const handleChangeBB = (item, index, value) => {
    form.setFieldsValue({
      [`chechLechBB-${index}`]:
        Math.round((item.soLuongDuToan - value) * 1000) / 1000,
    });
  };

  // const [indexChangeBM, setindexChangeBM] = useState(-1);
  const handleChangeBM = (item, index, value) => {
    // setindexChangeBM(index);
    form.setFieldsValue({
      [`chechLechBM-${index}`]:
        Math.round((item.soLuongDuToan - value) * 1000) / 1000,
    });
  };

  // const [indexChangeTH, setindexChangeTH] = useState(-1);
  const handleChangeTH = (item, index, value) => {
    // setindexChangeTH(index);
    form.setFieldsValue({
      [`chechLechTH-${index}`]:
        Math.round((item.soLuongDuToan - value) * 1000) / 1000,
    });
  };

  //call api data
  const getDetailThongTin = useCallback(() => {
    handleDetailThongTin(
      detail,
      setLoading,
      setDataBB,
      setDataBM,
      setDataTH,
      setFetchDataTable
    );
  }, [detail, setFetchDataTable]);

  //fetch data
  useEffect(() => {
    getDetailThongTin();
  }, [getDetailThongTin]);

  //get init value
  useEffect(() => {
    dataBB.map((item, index) => {
      return form.setFieldsValue({
        [`thucTeBB-${index}`]: item[`thucTeBB-${index}`],
        [`duToanBBId-${index}`]: item[`duToanBBId-${index}`],
        [`isVatTuNgoaiBB-${index}`]: item[`isVatTuNgoaiBB-${index}`],
      });
    });
    dataBM.map((item, index) => {
      return form.setFieldsValue({
        [`thucTeBM-${index}`]: item[`thucTeBM-${index}`],
        [`duToanBMId-${index}`]: item[`duToanBMId-${index}`],
        [`isVatTuNgoaiBM-${index}`]: item[`isVatTuNgoaiBM-${index}`],
      });
    });
    dataTH.map((item, index) => {
      return form.setFieldsValue({
        [`thucTeTH-${index}`]: item[`thucTeTH-${index}`],
        [`duToanTHId-${index}`]: item[`duToanTHId-${index}`],
        [`isVatTuNgoaiTH-${index}`]: item[`isVatTuNgoaiTH-${index}`],
      });
    });
  }, [dataBB, dataBM, dataTH, form]);

  //submit
  const onFinishThongTin = (values) => {
    submitThongTinVT(
      detail,
      setLoading,
      dataBB,
      dataBM,
      dataTH,
      setFetchDataTable,
      values,
      setScroll,
      form,
      (value) => dispatch(notSavedThongTinThiCong(value))
    );
  };

  //change field
  const handleFieldChange = () => {
    const vatTuBenBans = [];
    const vatTuBenMuas = [];
    const vatTuBenTHs = [];
    dataBB.map((item, index) => {
      return vatTuBenBans.push({
        chungLoaiVatTu: item.chungLoaiVatTu,
        donViTinh: item.donViTinh,
        soLuongDuToan: item.soLuongDuToan,
        stt: item.stt,
        [`duToanBBId-${index}`]: form.getFieldValue(`duToanBBId-${index}`),
        [`thucTeBB-${index}`]: form.getFieldValue(`thucTeBB-${index}`),
        [`isVatTuNgoaiBB-${index}`]: item[`isVatTuNgoaiBB-${index}`],
      });
    });
    dataBM.map((item, index) => {
      return vatTuBenMuas.push({
        chungLoaiVatTu: item.chungLoaiVatTu,
        donViTinh: item.donViTinh,
        soLuongDuToan: item.soLuongDuToan,
        stt: item.stt,
        [`duToanBMId-${index}`]: form.getFieldValue(`duToanBMId-${index}`),
        [`thucTeBM-${index}`]: form.getFieldValue(`thucTeBM-${index}`),
        [`isVatTuNgoaiBM-${index}`]: item[`isVatTuNgoaiBM-${index}`],
      });
    });
    dataTH.map((item, index) => {
      return vatTuBenTHs.push({
        chungLoaiVatTu: item.chungLoaiVatTu,
        donViTinh: item.donViTinh,
        soLuongDuToan: item.soLuongDuToan,
        stt: item.stt,
        [`duToanTHId-${index}`]: form.getFieldValue(`duToanTHId-${index}`),
        [`thucTeTH-${index}`]: form.getFieldValue(`thucTeTH-${index}`),
        [`isVatTuNgoaiTH-${index}`]: item[`isVatTuNgoaiTH-${index}`],
      });
    });
    if (
      isEqual(vatTuBenBans, dataBB) &&
      isEqual(vatTuBenMuas, dataBM) &&
      isEqual(vatTuBenTHs, dataTH)
    ) {
      dispatch(notSavedThongTinThiCong(false));
      setInitVatTu(true);
    } else {
      dispatch(notSavedThongTinThiCong(true));
    }
  };

  //init value
  useEffect(() => {
    if (initVatTu) {
      dataBB.map((item, index) => {
        return form.setFieldsValue({
          [`thucTeBB-${index}`]: item[`thucTeBB-${index}`],
          [`duToanBBId-${index}`]: item[`duToanBBId-${index}`],
          [`isVatTuNgoaiBB-${index}`]: item[`isVatTuNgoaiBB-${index}`],
        });
      });
      dataBM.map((item, index) => {
        return form.setFieldsValue({
          [`thucTeBM-${index}`]: item[`thucTeBM-${index}`],
          [`duToanBMId-${index}`]: item[`duToanBMId-${index}`],
          [`isVatTuNgoaiBM-${index}`]: item[`isVatTuNgoaiBM-${index}`],
        });
      });
      dataTH.map((item, index) => {
        return form.setFieldsValue({
          [`thucTeTH-${index}`]: item[`thucTeTH-${index}`],
          [`duToanTHId-${index}`]: item[`duToanTHId-${index}`],
          [`isVatTuNgoaiTH-${index}`]: item[`isVatTuNgoaiTH-${index}`],
        });
      });
    }
  }, [dataBB, dataBM, dataTH, form, initVatTu]);

  return (
    <Fragment>
      <TableThongTinVatTu
        form={form}
        dataBB={dataBB}
        dataBM={dataBM}
        dataTH={dataTH}
        t={t}
        dispatch={dispatch}
        closeModal={closeModal}
        loading={loading}
        handleChangeBB={handleChangeBB}
        // indexChangeBB={indexChangeBB}
        handleChangeBM={handleChangeBM}
        // indexChangeBM={indexChangeBM}
        handleChangeTH={handleChangeTH}
        // indexChangeTH={indexChangeTH}
        onFinishThongTin={onFinishThongTin}
        handleFieldChange={handleFieldChange}
      />
    </Fragment>
  );
}
