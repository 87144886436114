import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import TyLeBanDienModal from "../../modals/modal-ty-le-ban-dien";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import {
  handleDeleteTyLe,
  handleListTyLe,
  handleMultiAPITyLe,
} from "../../../services";
import TyLeGiaBanDienTabs from "./tabs-ty-le-gia-ban-dien";

export default function Index({ detaiHoSo, typeTiLe }) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [detailLeBanDien, setDetailTyLeBanDien] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataGiaBanDien, setDataGiaBanDien] = useState([]);
  const [dataTiLe, setDataTiLe] = useState([]);
  const [totalCount, setTotaCount] = useState(0);
  //fetch dữ liệu tỷ lệ giá bán điện

  const fetchDataGiaBanDien = () => {
    //call api
    handleListTyLe(
      typeTiLe,
      detaiHoSo,
      setLoading,
      setDataGiaBanDien,
      setTotaCount
    );
  };

  useEffect(() => {
    handleMultiAPITyLe(
      typeTiLe,
      detaiHoSo,
      setLoading,
      setDataTiLe,
      setDataGiaBanDien,
      setTotaCount
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Hiển thị  các buttom tạo mới, ...

  const genExtra = () => (
    <div style={{ textAlign: "right" }}>
      <Button
        size="small"
        type="primary"
        className="mr-5px"
        onClick={() => handleOpenModal()}
      >
        {t("button.tao_moi")}
      </Button>
    </div>
  );
  //khai bái columns

  const columns = [
    {
      title: t("lap_phuong_an.thiet_bi.muc_dich_su_dung"),
      dataIndex: "mucDichSuDung",
      key: "mucDichSuDung",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "35%",
    },
    {
      title: t("ap_gia_ho_so.ti_le_%_hoac_kwh"),
      dataIndex: "tiLePhanTram",
      key: "tiLePhanTram",
      render: (text, record) => (
        <div className="table-text-right">
          <span>
            {record.tiLePhanTram +
              " " +
              (record.isPhanTram === 0 ? "kWh" : "%")}
          </span>
        </div>
      ),
      width: "10%",
    },
    {
      title: t("ap_gia_ho_so.gia_ban_dien_chua_thue"),
      children: [
        {
          title: "Không theo thời gian",
          dataIndex: "gioKT",
          key: "gioKT",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "15%",
        },
        {
          title: "Theo thời gian",
          children: [
            {
              title: t("ap_gia_ho_so.gio_binh_thuong"),
              dataIndex: "gioBinhThuong",
              key: "gioBinhThuong",
              render: (text) => (
                <div className="table-text-right">
                  <span>{text}</span>
                </div>
              ),
              width: "10%",
            },
            {
              title: t("ap_gia_ho_so.gio_cao_diem"),
              dataIndex: "gioCaoDiem",
              key: "gioCaoDiem",
              render: (text) => (
                <div className="table-text-right">
                  <span>{text}</span>
                </div>
              ),
              width: "10%",
            },
            {
              title: t("ap_gia_ho_so.gio_thap_diem"),
              dataIndex: "gioThapDiem",
              key: "gioThapDiem",
              render: (text) => (
                <div className="table-text-right">
                  <span>{text}</span>
                </div>
              ),
              width: "10%",
            },
          ],
          width: "30%",
        },
      ],
    },
    {
      title: t("tac_vu"),
      key: "action",
      fixed: "right",
      width: "10%",
      render: (row) => (
        <div className="action-table-column">
          <div className="action-icon" onClick={() => handleOpenModal(row)}>
            <PencilIcon />
          </div>
          <div className="action-icon" onClick={() => handleDeleteData(row.id)}>
            <TrashIcon />
          </div>
        </div>
      ),
    },
  ];

  //xử lý call api sửa/tạo

  //xóa dữ liệu

  const handleDeleteData = useCallback(
    (ids) => {
      //call api
      handleDeleteTyLe(ids, setLoading, fetchDataGiaBanDien, t);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  //hiển thị popup, set dữ liệu chi tiết

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDetailTyLeBanDien(row);
      else setDetailTyLeBanDien({});
      setVisible(!visible);
    },
    [visible]
  );

  //đóng popup

  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
  };

  //render popup

  const showModal = useMemo(
    () =>
      visible && (
        <TyLeBanDienModal
          detailLeBanDien={detailLeBanDien}
          visible={visible}
          fetchDataGiaBanDien={fetchDataGiaBanDien}
          handleCloseModal={handleCloseModal}
          dataTiLe={dataTiLe}
          detaiHoSo={detaiHoSo}
          typeTiLe={typeTiLe}
        />
      ),
    // eslint-disable-next-line
    [visible]
  );

  return (
    <Fragment>
      <TyLeGiaBanDienTabs
        loading={loading}
        genExtra={genExtra()}
        dataGiaBanDien={dataGiaBanDien}
        columns={columns}
        totalCount={totalCount}
      />
      {showModal}
    </Fragment>
  );
}
