import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import { Option } from "antd/lib/mentions";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { handleDownload, selectBcphhd } from "./service";
export default function BaoCaoPhanHeHoaDon({optionBcphhdNghienCuu}) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);

  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };

  const setInitValueReportType = () => {
    if(optionBcphhdNghienCuu){
      return optionBcphhdNghienCuu[0]?.value
    }
    return selectBcphhd[0]?.value
  }

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Báo cáo phân hệ hóa đơn
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              initialValues={{
                reportType: setInitValueReportType(),
                LoaiKhachHang: "1",
                unitId: user.user.unitCode,
              }}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              colon={false}
            >
              <Form.Item
                name="reportType"
                label="Loại báo cáo"
                labelAlign="left"
                required
              >
                <Select placeholder="Tất cả" options={optionBcphhdNghienCuu || selectBcphhd}></Select>
              </Form.Item>

              <Form.Item
                name="unitId"
                label="Tên đơn vị"
                labelAlign="left"
                required
              >
                <Selection
                  url={Endpoint.GET_TEAM_LIST_CODE}
                  formKey="unitId"
                  form={form}
                />
              </Form.Item>

              <Form.Item
                name="reportDate"
                label="Tháng báo cáo"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="month"
                  formKey="reportDate"
                  form={form}
                />
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") ===
                  Endpoint.BCKD_BC_KHNC_PHU_TAI ? (
                    <Form.Item
                      name="LoaiKhachHang"
                      label="Loại khách hàng"
                      labelAlign="left"
                      required
                    >
                      <Select
                        placeholder="Chọn loại khách hàng"
                        formKey="LoaiKhachHang"
                      >
                        <Option value="1">Sinh hoạt</Option>
                        <Option value="0">Ngoài sinh hoạt</Option>
                      </Select>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              <Row>
                <Col span={4}></Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>
                </Col>
                <Col span={8}>
                  {/* <Button type="primary" loading={loading} className="ml-20px">
                    In báo cáo
                  </Button> */}
                </Col>
              </Row>
            </FormComponent>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
