import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import {
  buildQueryString,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";

import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { handleListBaoCao, handleListColumns } from "./services";
import BaoCaoDSChamTienDoTable from "./table";
import moment from "moment";
import { Endpoint } from "@utils/endpoint";
import { downloadFile } from "@utils/request";

export default function Index(props) {
  const { redirect, setRedirect } = props;
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { user } = useSelector(authSelector);
  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);
  //filter
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    donViId: user.unitId,
    loaiBaoCao: "1",
    ngayBaoCao: moment(moment().startOf(MONTH_STRING)).format(FORMAT_ENGLISH),
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //change pagination
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  };

  //fetch báo cáo
  const getListBaoCao = useCallback(() => {
    handleListBaoCao(filterConditions, setLoading, setData, setTotalCount);
  }, [filterConditions]);

  const [value, setValue] = useState("1");
  const handleChange = (value) => {
    setValue(value);
  };

  const [filterExcel, setFilterExcel] = useState(false);

  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type]);

  //search
  const handleSearch = useCallback(
    (values) => {
      if (value === "4") {
        setFilterConditions(() => ({
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
          ...values,
          donViId: values.donViId,
        }));
      } else {
        const date = moment(values.ngayBaoCao).format(FORMAT_ENGLISH);
        setFilterConditions(() => ({
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
          ...values,
          donViId: values.donViId,
          ngayBaoCao: date,
        }));
      }
      handleAPI();
    },
    [value, handleAPI]
  );

  //clear search
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
      loaiBaoCao: "1",
    });
    setValue("1");
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  }, [user.unitId]);

  useEffect(() => {
    if (filterExcel) {
      const endpoint = `${
        Endpoint.EXPORT_EXCEL_CHAM_TIEN_DO
      }?${buildQueryString(parseParams(filterConditions))}`;
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel]);

  useEffect(() => {
    if (type === ENUM_BUTTON_TYPE.TIM_KIEM) getListBaoCao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListBaoCao]);

  useEffect(() => {
    if (redirect) {
      getListBaoCao();
      setRedirect(false);
    }
  }, [getListBaoCao, redirect, setRedirect]);

  const [dataColumns, setDataColumns] = useState([]);
  //fetch columns
  const getListColumns = useCallback(() => {
    handleListColumns(setLoading, setDataColumns, dispatch);
  }, [dispatch]);

  useEffect(() => {
    getListColumns();
  }, [getListColumns]);

  const renderComponent = useCallback(() => {
    if (dataColumns.length > 0)
      return (
        <BaoCaoDSChamTienDoTable
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          totalCount={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          columns={dataColumns}
          data={data}
          getListColumns={getListColumns}
          handleChange={handleChange}
          value={value}
          setType={setType}
        />
      );
  }, [
    clearFilter,
    data,
    dataColumns,
    filterConditions,
    getListColumns,
    handleSearch,
    loading,
    totalCount,
    value,
  ]);

  return <Fragment>{renderComponent()}</Fragment>;
}
