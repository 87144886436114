import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import TableComponent from "@components/TableComponent";
import { authGetData, authDeleteData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { ConfigProvider, Tooltip } from "antd";
import { useLocation } from "react-router-dom";
import vn from "antd/es/locale-provider/vi_VN";
import TrashIcon from "@assets/icon/TrashIcon";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  buildQueryString,
  parseParams,
  handlePagination,
} from "@utils/function";
import moment from "moment";
import { FORMAT_DATE } from "@utils/constants";
export default function UserAssignedTable({ roleId }) {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  const getUserByRole = useCallback(() => {
    if (roleId) {
      authGetData({
        url: `${
          Endpoint.ROLE
        }/get-user-role?roleId=${roleId}&${buildQueryString(filterConditions)}`,
        setLoading,
        onSuccess: (res) => {
          setDataSource(res.data);
          setTotalCount(res.paging.totalCount);
        },
      });
    }
  }, [filterConditions, roleId]);
  useEffect(() => {
    getUserByRole();
  }, [getUserByRole]);

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const handleDeleteRoleAssignedForUser = useCallback(
    (id) => {
      if (roleId) {
        authDeleteData({
          url: `${Endpoint.ROLE}/delete-user-role?roleId=${roleId}&userId=${id}`,
          setLoading,
          onSuccess: () => {
            getUserByRole();
          },
        });
      }
    },
    [getUserByRole, roleId]
  );

  const columns = [
    {
      title: "Đơn vị",
      dataIndex: "unitName",
    },
    {
      title: "Tên đăng nhập",
      dataIndex: "userName",
    },
    {
      title: "Tên đầy đủ",
      dataIndex: "name",
    },
    {
      title: "Mã nhân viên",
      dataIndex: "cmis_Code",
    },
    {
      title: "Trạng thái",
      dataIndex: "actived",
      render: (actived) => (
        <span className={actived ? `active-color` : `unactive-color`}>
          {actived ? "Hoạt động" : "Khóa"}
        </span>
      ),
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      render: (createdDate) => (
        <div>
          {createdDate ? moment(createdDate).format(FORMAT_DATE) : null}
        </div>
      ),
    },

    {
      title: "Tác vụ",
      fixed: "right",
      render: (row) => (
        <div className="action-table-column">
          <Tooltip title={"Xóa"}>
            <div
              className="action-icon"
              onClick={() => handleDeleteRoleAssignedForUser(row.id)}
            >
              <TrashIcon />
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Container>
      <ConfigProvider locale={vn}>
        <TableComponent
          header={"Danh sách người dùng"}
          dataSource={roleId ? dataSource : []}
          columns={columns}
          totalData={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
          disableGetQueryParams={true}
          scrollX={1200}
        />
      </ConfigProvider>
    </Container>
  );
}
const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
`;
