import { Col, Form, Row, Select, Button, Tooltip, Input } from "antd";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import FormComponent from "@components/Form";
import { Fragment } from "react";
import DatePickerComponent from "../../datepicker/DatePicker";
import moment from "moment";
import { FORMAT_DATE, FORMAT_ENGLISH } from "@utils/constants";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";

const { Option } = Select;

export default function FormBoLoc(props) {
  const { user, form, handleSearch, tooltip, clear, soPha, filterConditions } =
    props;
  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
      }}
      autoComplete="off"
    >
      <Row gutter={24}>
        <Fragment>
          <Col span={24} md={8}>
            <Form.Item name="donViId" label="Đơn vị">
              {!user.isTongCongTy ? (
                <Select defaultValue={user.unitId} disabled>
                  <Option value={user.unitId}>{user.unitName}</Option>
                </Select>
              ) : (
                <Selection
                  url={Endpoint.LIST_DONVI_KTCT}
                  form={form}
                  formKey="donViId"
                  notClear={true}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={5}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                if (prevValues.donViId !== currentValues.donViId) {
                  form.setFieldsValue({
                    doiId: undefined,
                  });
                }
                return prevValues.donViId !== currentValues.donViId;
              }}
            >
              {({ getFieldValue }) => {
                if (getFieldValue("donViId")) {
                  return (
                    <Form.Item name="doiId" label="Đội">
                      <Selection
                        url={`${Endpoint.LIST_DOI_KTCT}?donviId=${getFieldValue(
                          "donViId"
                        )}`}
                        formKey="doiId"
                        form={form}
                      />
                    </Form.Item>
                  );
                }
                return (
                  <Form.Item name="doiId" label="Đội">
                    <Selection
                      url={`${Endpoint.LIST_DOI_KTCT}?donviId=${user.unitId}`}
                      formKey="doiId"
                      form={form}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={24} md={5}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                if (prevValues.donViId !== currentValues.donViId) {
                  form.setFieldsValue({
                    tramId: undefined,
                  });
                }
                return prevValues.donViId !== currentValues.donViId;
              }}
            >
              {({ getFieldValue }) => {
                if (getFieldValue("donViId")) {
                  return (
                    <Form.Item name="tramId" label="Trạm">
                      <Selection
                        mode="multiple"
                        url={`${Endpoint.LIST_TRAM_CT}?donviId=${getFieldValue(
                          "donViId"
                        )}`}
                        formKey="tramId"
                        form={form}
                      />
                    </Form.Item>
                  );
                }
                return (
                  <Form.Item name="tramId" label="Trạm">
                    <Selection
                      mode="multiple"
                      url={`${Endpoint.LIST_TRAM_CT}?donviId=${user.unitId}`}
                      formKey="tramId"
                      form={form}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item name="soPha" label="Số pha">
              <Select placeholder="Tất cả" allowClear>
                {soPha && soPha.length > 0
                  ? soPha.map((item, index) => {
                      if (item.value !== null) {
                        return (
                          <Option key={index} value={item.value.toString()}>
                            {item.name}
                          </Option>
                        );
                      }
                      return null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={4}>
            <Form.Item name="ngayKiemTraTuNgay" label="Ngày treo tháo">
              <DatePickerComponent
                defaultValue={
                  filterConditions.ngayTaoTuNgay
                    ? moment(filterConditions.ngayTaoTuNgay, FORMAT_ENGLISH)
                    : moment(moment(), FORMAT_DATE)
                }
                format={FORMAT_ENGLISH}
                form={form}
                formKey={"ngayKiemTraTuNgay"}
                notClear={false}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={4}>
            <Form.Item name="ngayKiemTraDenNgay" label="&nbsp;">
              <DatePickerComponent
                defaultValue={
                  filterConditions.ngayTaoDenNgay
                    ? moment(filterConditions.ngayTaoDenNgay, FORMAT_ENGLISH)
                    : moment(moment(), FORMAT_DATE)
                }
                format={FORMAT_ENGLISH}
                form={form}
                formKey={"ngayKiemTraDenNgay"}
                notClear={false}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={10}>
            <Form.Item name="searchTerm" label="Từ khóa">
              <Input
                prefix={<SearchOutlined />}
                suffix={
                  <Tooltip title={tooltip}>
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Col>
          <Col
            span={24}
            md={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button className="mr-24px" onClick={clear}>
              Bỏ lọc
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              form="filter-form"
              className="mr-24px"
            >
              Tìm kiếm
            </Button>
          </Col>
        </Fragment>
      </Row>
    </FormComponent>
  );
}
