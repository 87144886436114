import { createSlice } from "@reduxjs/toolkit";
export const namespace = "yeuCauDangThucHien";

const INITAL_STATE = {
  xacNhanHoanThanh: false,
  canShowDialogLeavingPage: false,
};

const slice = createSlice({
  name: namespace,
  initialState: INITAL_STATE,
  reducers: {
    notSavedXacNhanHoanThanh: (state, action) => {
      return {
        ...state,
        xacNhanHoanThanh: action.payload,
      };
    },
    setCanShowDialogLeavingPage: (state, action) => {
      return {
        ...state,
        canShowDialogLeavingPage: action.payload,
      };
    },
  },
});

export const reducer = slice.reducer;

export const { notSavedXacNhanHoanThanh, setCanShowDialogLeavingPage } =
  slice.actions;

export const kinhDoanhSelector = (state) => state[namespace];
