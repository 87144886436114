import { authGetMultiplesRequest } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";

export const getListNganHangService = (
  filterConditions,
  setLoading,
  setDataNganHang,
  setTotalCount
) => {
  const endpoints = [
    `${Endpoint.LIST_NGAN_HANG}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataNganHang(res[0].data.data);
        setTotalCount(res[0].data.paging.totalCount);
      } else setDataNganHang([]);
    },
  });
};
