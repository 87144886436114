import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { handleUpdateData } from "./services";
import ModalLamMoiHoSo from "./modal-lam-moi-ho-so";

export default function LamMoiHoSoModal({
  visible,
  closeModal,
  setFetchDataTable,
  data,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleConfirm = () => {
    const value = { hoSoId: data.id };
    handleUpdateData(value, setLoading, closeModal, setFetchDataTable, t);
  };

  return (
    <ModalLamMoiHoSo
      loading={loading}
      visible={visible}
      closeModal={closeModal}
      handleConfirm={handleConfirm}
      t={t}
    ></ModalLamMoiHoSo>
  );
}
