import React from "react";
import { Button, Col, Modal, Row } from "antd";
import LoadingComponent from "@components/Loading";
import { Container } from "./css-styled";
export default function Index(props) {
  const { loading, visible, closeModal, handleDuyetHoSo, t } = props;
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={"Hồ sơ đang ở đội trưởng"}
        visible={visible}
        onCancel={closeModal}
        footer={null}
      >
        <Container>
          <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
          Hồ sơ của bạn đang ở Đội trưởng. Xác nhận sẽ chuyển tiếp cho bộ phận Kiểm duyệt !
          </h4>
          <Row gutter={24} justify="center" style={{ marginTop: "48px" }}>
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                className="btn-bg-yellow"
                loading={loading}
                onClick={handleDuyetHoSo}
                key="confirm"
              >
                {t("button.xac_thuc_chu_ky")}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal>
    </LoadingComponent>
  );
}
