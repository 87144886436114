import {
  Fragment,
  useCallback,
  useState,
  useEffect
} from "react";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import TableComponent from "@components/TableComponent";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE, ENUM_KY_NHAY } from "@utils/constants";
import {
  customColumn, handlePagination,
} from "@utils/function";
import { useLocation } from "react-router-dom";
import { Container } from "./css-styled";
import { removeAccents } from "@utils/function";
import {
  handleListDataCauHinh,
  submitThemMoiCauHinh,
  submitXoaCauHinh,
  submitUpdateCauHinh,
  getListDonViService
} from "./services";
import CheckPermission from "@components/CheckPermission";
import { ap_gia } from "@permissions/e_apgia";
import { Form, Row, Col, Button, Collapse, Select, Tooltip } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
const { Panel } = Collapse;
const { Option } = Select;

export default function Index() {
  const { user } = useSelector(authSelector)
  const [form] = Form.useForm();
  const [dataCauHinh, setDataCauHinh] = useState([]);
  const [loading, setLoading] = useState(true);
  const [donVi, setDonVi] = useState(user.unitId);
  const [dataDonVi, setDataDonVi] = useState();
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [viTri, setViTri] = useState(ENUM_KY_NHAY.ALL);
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [editingKey, setEditingKey] = useState('');
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });

  //fetch dữ liệu
  const getListDataCauHinh = useCallback(() => {
    handleListDataCauHinh(
      setLoading,
      location,
      setDataCauHinh,
      setTotalCount,
      filterConditions
    );
  }, [filterConditions, location]);

  useEffect(() => {
    getListDonViService(setLoading, setDataDonVi);
  }, []);

  useEffect(() => {
    getListDataCauHinh();
  }, [getListDataCauHinh]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListDataCauHinh();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListDataCauHinh, setFetchDataTable]);
  //pagination table
  const onChangePagination = (paging, _filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      viTriCongViec: '',
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (id) => {
    const row = await form.validateFields();
    submitUpdateCauHinh(id, row, setLoading, setFetchDataTable, setEditingKey)
  };

  const EditableCell = ({
    editing,
    dataIndex,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
          >
            <Select>
              <Option value={ENUM_KY_NHAY.ALL}>Tất cả</Option>
              <Option value={ENUM_KY_NHAY.PAG}>Bộ phận áp giá</Option>
              <Option value={ENUM_KY_NHAY.PKD}>Phòng kinh doanh</Option>
            </Select>
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  const columns = [
    {
      title: "Đơn vị",
      dataIndex: "tenDonVi",
      key: "tenDonVi",
      render: (text) => (
        <span>{text}</span>
      ),
      width: "30%",
    },
    {
      title: "Vị trí công việc",
      dataIndex: "viTriCongViec",
      key: "viTriCongViec",
      render: (text, record) => (
        record.viTriCongViec === ENUM_KY_NHAY.ALL ?
          <span>{"Tất cả"}</span> :
          (record.viTriCongViec === ENUM_KY_NHAY.PAG ?
            <span>{"Bộ phận áp giá"}</span> :
            <span>{"Phòng kinh doanh"}</span>)
      ),
      width: "50%",
      editable: true,
    },
    {
      title: "Tác vụ",
      dataIndex: "",
      key: "tacVu",
      className: "text-center",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button type="primary" onClick={() => save(record.id)}
              style={{
                marginRight: 10,
              }}
            >
              Lưu
            </Button>
            <Button onClick={cancel}>Bỏ qua</Button>
          </span>
        ) : (
          <span>
            <CheckPermission permissionCode={ap_gia.e61s}>
              {/* <Button type="primary" disabled={editingKey !== ''} onClick={() => edit(record)}
                style={{
                  marginRight: 8,
                }}>
                Sửa
              </Button> */}
              <Tooltip title={"Sửa"}>
                <Button type="link" disabled={editingKey !== ''} onClick={() => edit(record)} className="pd-5px">
                  <PencilIcon />
                </Button>
              </Tooltip>
            </CheckPermission>
            <CheckPermission permissionCode={ap_gia.e61x}>
              {/* <Button
                type="danger"
                onClick={() => handleXoaCauHinh(record.id)}
              >
                Xóa
              </Button> */}
              <Tooltip title={"Xóa"}>
                <Button type="link" onClick={() => handleXoaCauHinh(record.id)} className="pd-5px">
                  <TrashIcon />
                </Button>
              </Tooltip>
            </CheckPermission>
          </span>
        );
      },
      // width: "20%",
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        key: col.key,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const handleXoaCauHinh = useCallback(
    (id) => {
      submitXoaCauHinh(id, setLoading, setFetchDataTable);
    }, []);

  const changeViTri = (value) => {
    setViTri(value)
  };

  const changeDonVi = (value) => {
    setDonVi(value)
  };


  const handleThem = useCallback(() => {
    submitThemMoiCauHinh(viTri, setLoading, setFetchDataTable, donVi)
  }, [donVi, viTri]);
  return (
    <Fragment>
      <Container style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <Collapse
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined
              style={{ fontSize: 18 }}
              rotate={isActive ? 90 : 0}
            />
          )}
          style={{ marginBottom: 15 }}
        >
          <Panel
            header={"Đăng ký cấu hình"}
            collapsible={"header"}
            key="1"
          >
            <Row gutter={24} justify="space-between" align="middle">
              <Col span={24} md={6}>
                <Form.Item name="DonViId" label="Đơn vị" style={{ margin: 0 }}>
                  {!user.isTongCongTy ? (
                    <Select defaultValue={user.unitId} disabled>
                      <Option value={user.unitId}>{user.unitName}</Option>
                    </Select>
                  ) : (
                    <Select allowClear onChange={changeDonVi} defaultValue={user.unitId}
                      showSearch filterOption={(input, option) => {
                        if (option && option.children) {
                          return (
                            removeAccents(option.children)
                              .toLowerCase()
                              .indexOf(removeAccents(input).toLowerCase()) >= 0
                          );
                        }
                      }}>
                      {(dataDonVi && dataDonVi.length > 0)
                        ? dataDonVi.map((item, index) => {
                          if (item.value !== null) {
                            return (
                              <Option key={index} value={item.value}>
                                {item.name}
                              </Option>
                            );
                          }
                          return null;
                        })
                        : null}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={24} md={6}>
                <Form.Item label="Vị trí công việc" style={{ margin: 0 }}>
                  <Select defaultValue={ENUM_KY_NHAY.ALL} onChange={changeViTri}>
                    <Option value={ENUM_KY_NHAY.ALL}>Tất cả</Option>
                    <Option value={ENUM_KY_NHAY.PAG}>Bộ phận áp giá</Option>
                    <Option value={ENUM_KY_NHAY.PKD}>Phòng kinh doanh</Option>
                  </Select>
                </Form.Item>

              </Col>
              <Col span={24} md={6}>
                <CheckPermission permissionCode={ap_gia.e61t}>
                  <Button type="primary" onClick={() => handleThem()} form="filter-form">
                    Thêm
                  </Button>
                </CheckPermission>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Container>
      <Container>
        <Form form={form} component={false}>
          <TableComponent
            header={"Danh sách cấu hình ký nháy"}
            dataSource={dataCauHinh}
            columns={customColumn(mergedColumns, filterConditions)}
            totalData={totalCount}
            loading={loading}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
          />
        </Form>
      </Container>
    </Fragment>
  );
}
