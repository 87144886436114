import {
  METHOD_GET,
  METHOD_POST,
  STATUSCODE_200,
  TYPE_TABLE_CONGTO,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, getErrorMessage } from "@utils/function";
import {
  authGetData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";
// call api đồng bộ từ cmis

export const handleDongBoCmis = (
  setLoading,
  setDataSource,
  typeDongBo,
  form,
  setTotalCount,
  filterConditions
) => {
  authGetData({
    url:
      typeDongBo === TYPE_TABLE_CONGTO.KIEMTRADINHKY
        ? `${Endpoint.BONGBO_CMIS_DK}?${buildQueryString(filterConditions)}`
        : typeDongBo === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA
        ? `${Endpoint.BONGBO_CMIS_PT}?${buildQueryString(filterConditions)}`
        : "",
    method: METHOD_GET,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data.data) {
        setDataSource(res.data.data);
        setTotalCount(res.data.toTalRow);
      } else {
        setDataSource([]);
        getErrorMessage(res, form);
      }
    },
  });
};
// call api get multilist data dropdown

export const handleMultiDS = (setLoading, setSoPha) => {
  const endpoints = [`${Endpoint.COMMON_LIST_SO_PHA}`];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setSoPha(res[0].data.data);
    },
  });
};
// call api định kỳ đồng bộ về web

export const handleDKDongBoVeWeb = (
  values,
  setLoading,
  setDataSync,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.DK_DONGBO_VE_WEB,
    method: METHOD_POST,
    payload: { listYeuCau: values },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataSync(res.data);
        setSelectedRowKeys([]);
      } else {
        getErrorMessage(res);
        setSelectedRowKeys([]);
      }
    },
  });
};
// call api phúc tra đồng bộ về web

export const handlePTDongBoVeWeb = (
  values,
  setLoading,
  setDataSync,
  setSelectedRowKeys
) => {
  const data = {
    yeuCauPhucTras: values,
  };
  authPostData({
    url: Endpoint.PT_DONGBO_VE_WEB,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataSync(res.data);
        setSelectedRowKeys([]);
      } else {
        getErrorMessage(res);
      }
    },
  });
};
