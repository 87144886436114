import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleTuChoiKyService, handleQuyetToanHSMService } from "./services";
import ModalLanhDaoQuyetToan from "./modal-lanh-dao-quyet-toan";

export default function LanhDaoQuyetToanModal({
  visible,
  closeModal,
  setFetchDataTable,
  detail,
}) {
  const { t } = useTranslation();
  const [loadingTuChoi, setLoadingTuChoi] = useState(false);
  const [loadingQuyetToanHSM, setLoadingQuyetToanHSM] = useState(false);

  //Handle Từ chối ký
  const handleTuChoiKy = () => {
    const hoSoId = { hoSoId: detail.id };
    handleTuChoiKyService(
      hoSoId,
      setLoadingTuChoi,
      closeModal,
      setFetchDataTable
    );
  };

  //Handle Quyết toán HSM
  const handleQuyetToanHSM = () => {
    const hoSoId = { id: detail.id };
    handleQuyetToanHSMService(
      hoSoId,
      setLoadingQuyetToanHSM,
      closeModal,
      setFetchDataTable
    );
  };

  return (
    <Fragment>
      <ModalLanhDaoQuyetToan
        loadingQuyetToanHSM={loadingQuyetToanHSM}
        visible={visible}
        closeModal={closeModal}
        handleTuChoiKy={handleTuChoiKy}
        loadingTuChoi={loadingTuChoi}
        handleQuyetToanHSM={handleQuyetToanHSM}
        t={t}
        detail={detail}
        setFetchDataTable={setFetchDataTable}
      />
    </Fragment>
  );
}
