import TrangChuDefault from "@modules/cap-dien/";
import DuyetHoSo from "@modules/cap-dien/ap-gia/duyet-ho-so";
import GiaoDichVien from "@modules/cap-dien/common-ho-so";
import DuyetHoSoDoiTruong from "@modules/cap-dien/doi-truong/duyet-ho-so";
import HoSoDaPhanCong from "@modules/cap-dien/giao-dich-vien/ho-so-da-phan-cong";
import QuanLyHoSoCoTroNgai from "@modules/cap-dien/giao-dich-vien/quan-ly-ho-so-co-tro-ngai";
import TiepNhanPhanCong from "@modules/cap-dien/giao-dich-vien/tiep-nhan-phan-cong";
import DuyetHoSoKiemDuyet from "@modules/cap-dien/kiem-duyet/duyet-ho-so";
import DuyetHoSoLanhDao from "@modules/cap-dien/lanh-dao/duyet-ho-so";
import DuyetQuyetToanLanhDao from "@modules/cap-dien/lanh-dao/duyet-quyet-toan";
import XacNhanNghiemThu from "@modules/cap-dien/nghiem-thu/nghiem-thu-ho-so";
import HoSoCoTroNgai from "@modules/cap-dien/nhan-vien-khao-sat/ho-so-co-tro-ngai";
import HoSoDangKhaoSat from "@modules/cap-dien/nhan-vien-khao-sat/ho-so-dang-khao-sat";
import HoSoDuocPhanCong from "@modules/cap-dien/nhan-vien-khao-sat/ho-so-duoc-phan-cong";
import LapPhuongAn from "@modules/cap-dien/nhan-vien-khao-sat/lap-phuong-an";
import DuyetHoSoPhongKinhDoanh from "@modules/cap-dien/phong-kinh-doanh/duyet-ho-so";
import LapQuyetToanCongTy from "@modules/cap-dien/quyet-toan/lap-quyet-toan-cong-ty";
import LapQuyetToanKhachHang from "@modules/cap-dien/quyet-toan/lap-quyet-toan-khach-hang";
import XacNhanQuyetToan from "@modules/cap-dien/quyet-toan/xac-nhan-quyet-toan";
import KhoiPhucTaiChinh from "@modules/cap-dien/tai-chinh/khoi-phuc-lai";
import XacNhanThuTien from "@modules/cap-dien/tai-chinh/xac-nhan-thu-tien";
import XacNhanThuTienNVKS from "@modules/cap-dien/tai-chinh/xac-nhan-thu-tien-nvks";
import XacNhanThiCong from "@modules/cap-dien/thi-cong/xac-nhan-thi-cong";
import DongBoThoiGian from "@modules/cap-dien/tien-ich/dong-bo-thoi-gian";
import DongBoYeuCauCmis from "@modules/cap-dien/tien-ich/dong-bo-yeu-cau-cmis";
import TaiAnhGiayToScan from "@modules/cap-dien/tien-ich/tai-anh-giay-to-scan";
import TrangChu from "@modules/cap-dien/trang-chu";
// import DanhSachVatTu from "@modules/cap-dien/vat-tu/danh-muc";
import BaoCao89Den160 from "@modules/cap-dien/bao-cao/bao-cao-cap-dien-89-160";
import BaoCaoChiTiet from "@modules/cap-dien/bao-cao/bao-cao-chi-tiet";
import BaoCaoChiTieuSoLuong from "@modules/cap-dien/bao-cao/bao-cao-chi-tieu-chat-luong";
import BaoCaoDSChamTienDo from "@modules/cap-dien/bao-cao/bao-cao-danh-sach-cham-tien-do";
import BaoCaoDSKH from "@modules/cap-dien/bao-cao/bao-cao-ds-kh";
import BaoCaoKeHoachTiepNhan from "@modules/cap-dien/bao-cao/bao-cao-ke-hoach-tiep-nhan";
import BaoCaoDVDien from "@modules/cap-dien/bao-cao/bao-cao-tiep-nhan-cac-dich-vu-dien";
import BaoCaoPhatTrienTheoLo from "@modules/cap-dien/bao-cao/bao-cao-tinh-hinh-phat-trien-theo-lo";
import BaoCaoCapDienTongHop from "@modules/cap-dien/bao-cao/bao-cao-tong-hop";
import BaoCaoTongHopSoLuongVT from "@modules/cap-dien/bao-cao/bao-cao-tong-hop-so-luong-vat-tu";
import BaoCaoTongHopSoPha from "@modules/cap-dien/bao-cao/bao-cao-tong-hop-so-pha";
import BaoCaoTTKH from "@modules/cap-dien/bao-cao/bao-cao-tong-hop-thong-tin-kh";
import ChiTietChiPhiQT from "@modules/cap-dien/bao-cao/chi-tiet-chi-phi-quyet-toan";
import ChiTietHTLD from "@modules/cap-dien/bao-cao/chi-tiet-theo-hinh-thuc-lap-dat";
import BaoCaoLogThaoTac from "@modules/cap-dien/bao-cao/log-thao-tac";
import TaoMoiKeHoachBanDien from "@modules/cap-dien/bao-cao/tao-moi-ke-hoach-tiep-nhan-ban-dien";
import TongHopChiPhiQT from "@modules/cap-dien/bao-cao/tong-hop-chi-phi-quyet-toan";
import TongHopHTLD from "@modules/cap-dien/bao-cao/tong-hop-theo-hinh-thuc-lap-dat";
import CauHinhHoSo from "@modules/cap-dien/ho-so-cau-hinh";
import DongBoThuTien from "@modules/cap-dien/tai-chinh/dong-bo-thu-tien";
import KhoiPhucThiCong from "@modules/cap-dien/thi-cong/khoi-phuc-thi-cong";
import ThoiGianLamViec from "@modules/cap-dien/thoi-gian-lam-viec";
import TraCuuHoSo from "@modules/cap-dien/tien-ich/tra-cuu-ho-so";
import XacNhanXuatVatTu from "@modules/cap-dien/vat-tu/xac-nhan-xuat-vat-tu";
import HSDangPhanCong from "@modules/cap-dien/thi-cong/ho-so-dang-treo-thao";
import LapBienBanLDDuongDay from "@modules/cap-dien/thi-cong/lap-bbld-duong-day";
import HSDaPhanCong from "@modules/cap-dien/thi-cong/ho-so-duoc-phan-cong";
import { cap_dien } from "@permissions/a_capdien";
import {
  MODULE_DICHVUDIEN,
  NAVIGATE_APGIAHOSO,
  NAVIGATE_BAOCAOBANDIEN,
  NAVIGATE_BAOCAOCAPDIEN89160,
  NAVIGATE_BAOCAOCHITIET,
  NAVIGATE_BAOCAOCHITIETCPQT,
  NAVIGATE_BAOCAOCHITIETHTLD,
  NAVIGATE_BAOCAODSCHAMTIENDO,
  NAVIGATE_BAOCAODSKH,
  NAVIGATE_BAOCAODVDIEN,
  // NAVIGATE_BAOCAOBANDIEN,
  // TITLE_BAOCAOBANDIEN,
  NAVIGATE_BAOCAOKEHOACHTIEPNHAN,
  NAVIGATE_BAOCAOLOGTHAOTAC,
  NAVIGATE_BAOCAOPHATTRIENTHEOLO,
  NAVIGATE_BAOCAOSOLUONGVATTU,
  NAVIGATE_BAOCAOSOPHA,
  NAVIGATE_BAOCAOTONGHOP,
  NAVIGATE_BAOCAOTONGHOPCPQT,
  NAVIGATE_BAOCAOTONGHOPHTLD,
  NAVIGATE_BAOCAOTONGHOPKH,
  NAVIGATE_CAUHINHHOSO,
  NAVIGATE_DICHVUDIEN,
  NAVIGATE_DICHVUDIEN_APGIA,
  NAVIGATE_DICHVUDIEN_BAOCAO,
  NAVIGATE_DICHVUDIEN_DOITRUONG,
  NAVIGATE_DICHVUDIEN_GIAODICHVIEN,
  NAVIGATE_DICHVUDIEN_KIEMDUYET,
  NAVIGATE_DICHVUDIEN_LANHDAO,
  NAVIGATE_DICHVUDIEN_NGHIEMTHU,
  NAVIGATE_DICHVUDIEN_NHANVIENKHAOSAT,
  NAVIGATE_DICHVUDIEN_PHONGKINHDOANH,
  NAVIGATE_DICHVUDIEN_QUYETTOAN,
  NAVIGATE_DICHVUDIEN_TAICHINH,
  NAVIGATE_DICHVUDIEN_THICONG,
  NAVIGATE_DICHVUDIEN_TIENICH,
  NAVIGATE_DONGBOTHOIGIAN,
  NAVIGATE_DONGBOTHUTIEN,
  NAVIGATE_DONGBOYEUCAUCMIS,
  NAVIGATE_DUYETHOSO,
  NAVIGATE_DUYETQUYETTOAN,
  NAVIGATE_HOSOCOTRONGAI,
  NAVIGATE_HOSODANGKHAOSAT,
  NAVIGATE_HOSODANGTREOTHAO,
  NAVIGATE_HOSODAPHANCONG,
  NAVIGATE_HOSODATRONGAI,
  NAVIGATE_HOSODUOCPHANCONG,
  // NAVIGATE_HOSO,
  // TITLE_HOSOFLOW,
  NAVIGATE_HOSOFLOW,
  NAVIGATE_KHOIPHUCTHICONG,
  NAVIGATE_LAPBBLDDUONGDAY,
  NAVIGATE_LAPPHUONGAN,
  NAVIGATE_LAPQUYETTOANCONGTY,
  NAVIGATE_LAPQUYETTOANKHACHHANG,
  NAVIGATE_NGHIEMTHUHOSO,
  NAVIGATE_TAIANHGIAYTOSCAN,
  NAVIGATE_THOIGIANLAMVIEC,
  NAVIGATE_TIEPNHANPHANCONG,
  NAVIGATE_TRACUUHOSO,
  NAVIGATE_VATTU,
  NAVIGATE_XACNHANQUYETTOAN,
  NAVIGATE_XACNHANTHICONG,
  // NAVIGATE_XACNHANTHICONG,
  NAVIGATE_XACNHANTHUTIEN,
  NAVIGATE_XACNHANTHUTIENNVKS,
  NAVIGATE_XACNHANXUATVATTU,
  // NAVIGATE_DANHSACHVATTU,
  TITLE_APGIA,
  TITLE_APGIAHOSO,
  // NAVIGATE_BAOCAO,
  TITLE_BAOCAO,
  TITLE_BAOCAOBANDIEN,
  TITLE_BAOCAOCAPDIEN89160,
  TITLE_BAOCAOCHITIET,
  TITLE_BAOCAOCHITIETCAPDIEN,
  TITLE_BAOCAOCHITIETCPQT,
  TITLE_BAOCAOCHITIETHTLD,
  TITLE_BAOCAODSCHAMTIENDO,
  TITLE_BAOCAODSKH,
  TITLE_BAOCAODVDIEN,
  TITLE_BAOCAOKEHOACHTIEPNHAN,
  TITLE_BAOCAOKHACHHANGPHIEN9,
  TITLE_BAOCAOLOGTHAOTAC,
  TITLE_BAOCAOPHATTRIENTHEOLO,
  TITLE_BAOCAOSOLUONGVATTU,
  TITLE_BAOCAOSOPHA,
  TITLE_BAOCAOTHEODOIPHATTRIENKHACHHANG,
  TITLE_BAOCAOTONGHOP,
  TITLE_BAOCAOTONGHOPCPQT,
  TITLE_BAOCAOTONGHOPHTLD,
  TITLE_BAOCAOTONGHOPKH,
  TITLE_CAUHINHHOSO,
  TITLE_DOITRUONG,
  TITLE_DONGBOTHOIGIAN,
  TITLE_DONGBOTHUTIEN,
  TITLE_DONGBOYEUCAUCMIS,
  TITLE_DUYETHOSO,
  TITLE_DUYETQUYETTOAN,
  TITLE_GIAODICHVIEN,
  TITLE_HOSO,
  TITLE_HOSODANGKHAOSAT,
  TITLE_HOSODANGTREOTHAO,
  TITLE_HOSODAPHANCONG,
  TITLE_HOSODUOCPHANCONG,
  TITLE_HOSOTRONGAI,
  TITLE_KHOIPHUCTHICONG,
  TITLE_KIEMDUYET,
  TITLE_LANHDAO,
  TITLE_LAPBBLDDUONGDAY,
  TITLE_LAPPHUONGAN,
  TITLE_LAPQUYETTOANCONGTY,
  TITLE_LAPQUYETTOANKHACHHANG,
  TITLE_NGHIEMTHU,
  TITLE_NGHIEMTHUHOSO,
  TITLE_NHANVIENKHAOSAT,
  TITLE_PHANCONGTIEPNHAN,
  TITLE_PHONGKINHDOANH,
  TITLE_QUYETTOAN,
  // TITLE_DANHSACHVATTU,
  TITLE_TAIANHGIAYTOSCAN,
  TITLE_TAICHINH,
  TITLE_THICONG,
  TITLE_THOIGIANLAMVIEC,
  TITLE_TIENICH,
  TITLE_TONGHOPTRANGWEBCAPDIEN,
  TITLE_TRACUUHOSO,
  TITLE_VATTU,
  TITLE_XACNHANQUYETTOAN,
  TITLE_XACNHANTHICONG,
  // TITLE_TRANGCHU,
  // TITLE_XACNHANTHICONG,
  TITLE_XACNHANTHUTIEN,
  TITLE_XACNHANTHUTIENNVKS,
  TITLE_XACNHANXUATVATTU,
} from "@utils/constants";

import BaoCaoChiTietCapDien from "@modules/cap-dien/bao-cao/bao-cao-cap-dien/bao-cao-chi-tiet-cap-dien";
import TongHopTrangWebCapDien from "@modules/cap-dien/bao-cao/bao-cao-cap-dien/tong-hop-trang-web-cap-dien";
import HoSoFlow from "@modules/cap-dien/ho-so/ho-so-flow";
import BCTDPhatTrienKhachHang from "@modules/cap-dien/bao-cao/bao-cao-theo-doi-phat-trien-khach-hang/bc-theo-doi-phat-trien-khach-hang";
import BaoCaoKHPhien9 from "@modules/cap-dien/bao-cao/bao-cao-khach-hang-phien-9/bao-cao-khach-hang-phien-9";
import XacNhanAnToanBAT from "@modules/cap-dien/ban-an-toan/xac-nhan-an-toan";
const routes = [
  {
    title: "Trang chủ",
    path: NAVIGATE_DICHVUDIEN,
    component: TrangChuDefault,
    privateRoute: true,
    subItems: [],
    permissionCode: MODULE_DICHVUDIEN,
  },
  {
    title: "Trang chủ",
    path: NAVIGATE_DICHVUDIEN + "/trang-chu",
    component: TrangChu,
    privateRoute: true,
    subItems: [],
    permissionCode: cap_dien.a0,
  },
  {
    title: TITLE_GIAODICHVIEN,
    path: `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_GIAODICHVIEN}`,
    component: GiaoDichVien,
    privateRoute: true,
    permissionCode: cap_dien.a1,
    subItems: [
      {
        subTitle: TITLE_PHANCONGTIEPNHAN,
        subPath: NAVIGATE_TIEPNHANPHANCONG,
        subComponent: TiepNhanPhanCong,
        permissionCode: cap_dien.a11,
      },
      {
        subTitle: TITLE_HOSODAPHANCONG,
        subPath: NAVIGATE_HOSODAPHANCONG,
        subComponent: HoSoDaPhanCong,
        permissionCode: cap_dien.a12,
      },
      {
        subTitle: TITLE_HOSOTRONGAI,
        subPath: NAVIGATE_HOSODATRONGAI,
        subComponent: QuanLyHoSoCoTroNgai,
        permissionCode: cap_dien.a13,
      },
    ],
  },
  {
    title: TITLE_APGIA,
    path: `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_APGIA}`,
    privateRoute: true,
    permissionCode: cap_dien.a2,
    subItems: [
      {
        subTitle: TITLE_APGIAHOSO,
        subPath: NAVIGATE_APGIAHOSO,
        subComponent: DuyetHoSo,
        permissionCode: cap_dien.a21,
      },
    ],
  },
  {
    title: TITLE_NHANVIENKHAOSAT,
    path: `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_NHANVIENKHAOSAT}`,
    privateRoute: true,
    permissionCode: cap_dien.a3,
    subItems: [
      {
        subTitle: TITLE_HOSODUOCPHANCONG,
        subPath: NAVIGATE_HOSODUOCPHANCONG,
        subComponent: HoSoDuocPhanCong,
        permissionCode: cap_dien.a31,
      },
      {
        subTitle: TITLE_HOSODANGKHAOSAT,
        subPath: NAVIGATE_HOSODANGKHAOSAT,
        subComponent: HoSoDangKhaoSat,
        permissionCode: cap_dien.a32,
      },
      {
        subTitle: TITLE_LAPPHUONGAN,
        subPath: NAVIGATE_LAPPHUONGAN,
        subComponent: LapPhuongAn,
        permissionCode: cap_dien.a33,
      },
      {
        subTitle: "Hồ sơ có trở ngại",
        subPath: NAVIGATE_HOSOCOTRONGAI,
        subComponent: HoSoCoTroNgai,
        permissionCode: cap_dien.a34,
      },
    ],
  },
  {
    title: TITLE_DOITRUONG,
    path: `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_DOITRUONG}`,
    privateRoute: true,
    permissionCode: cap_dien.a4,
    subItems: [
      {
        subTitle: TITLE_DUYETHOSO,
        subPath: NAVIGATE_DUYETHOSO,
        subComponent: DuyetHoSoDoiTruong,
        permissionCode: cap_dien.a41,
      },
    ],
  },
  {
    title: TITLE_KIEMDUYET,
    path: `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_KIEMDUYET}`,
    privateRoute: true,
    permissionCode: cap_dien.a5,
    subItems: [
      {
        subTitle: TITLE_DUYETHOSO,
        subPath: NAVIGATE_DUYETHOSO,
        subComponent: DuyetHoSoKiemDuyet,
        permissionCode: cap_dien.a51,
      },
    ],
  },
  {
    title: TITLE_PHONGKINHDOANH,
    path: `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_PHONGKINHDOANH}`,
    privateRoute: true,
    permissionCode: cap_dien.a6,
    subItems: [
      {
        subTitle: TITLE_DUYETHOSO,
        subPath: NAVIGATE_DUYETHOSO,
        permissionCode: cap_dien.a61,
        subComponent: DuyetHoSoPhongKinhDoanh,
      },
    ],
  },
  {
    title: TITLE_LANHDAO,
    path: `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_LANHDAO}`,
    privateRoute: true,
    permissionCode: cap_dien.a7,
    subItems: [
      {
        subTitle: TITLE_DUYETHOSO,
        subPath: NAVIGATE_DUYETHOSO,
        subComponent: DuyetHoSoLanhDao,
        permissionCode: cap_dien.a71,
      },
      {
        subTitle: TITLE_DUYETQUYETTOAN,
        subPath: NAVIGATE_DUYETQUYETTOAN,
        subComponent: DuyetQuyetToanLanhDao,
        permissionCode: cap_dien.a72,
      },
    ],
  },
  {
    title: TITLE_TAICHINH,
    path: `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_TAICHINH}`,
    privateRoute: true,
    permissionCode: cap_dien.a8,
    subItems: [
      {
        subTitle: TITLE_XACNHANTHUTIEN,
        subPath: NAVIGATE_XACNHANTHUTIEN,
        subComponent: XacNhanThuTien,
        permissionCode: cap_dien.a81,
      },
      {
        subTitle: TITLE_XACNHANTHUTIENNVKS,
        subPath: NAVIGATE_XACNHANTHUTIENNVKS,
        subComponent: XacNhanThuTienNVKS,
        permissionCode: cap_dien.a82,
      },
      {
        subTitle: TITLE_DONGBOTHUTIEN,
        subPath: NAVIGATE_DONGBOTHUTIEN,
        subComponent: DongBoThuTien,
        permissionCode: cap_dien.a83,
      },
      {
        subTitle: "Khôi phục lại tài chính",
        subPath: "/khoi-phuc",
        subComponent: KhoiPhucTaiChinh,
        permissionCode: cap_dien.a84,
      },
    ],
  },
  {
    title: TITLE_QUYETTOAN,
    path: `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_QUYETTOAN}`,
    privateRoute: true,
    permissionCode: cap_dien.a30,
    subItems: [
      {
        subTitle: TITLE_LAPQUYETTOANCONGTY,
        subPath: NAVIGATE_LAPQUYETTOANCONGTY,
        subComponent: LapQuyetToanCongTy,
        permissionCode: cap_dien.a301,
      },
      {
        subTitle: TITLE_LAPQUYETTOANKHACHHANG,
        subPath: NAVIGATE_LAPQUYETTOANKHACHHANG,
        subComponent: LapQuyetToanKhachHang,
        permissionCode: cap_dien.a302,
      },
      {
        subTitle: TITLE_XACNHANQUYETTOAN,
        subPath: NAVIGATE_XACNHANQUYETTOAN,
        subComponent: XacNhanQuyetToan,
        permissionCode: cap_dien.a303,
      },
    ],
  },
  {
    title: TITLE_BAOCAO,
    path: `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_BAOCAO}`,
    privateRoute: true,
    permissionCode: cap_dien.a50,
    subItems: [
      {
        subTitle: TITLE_BAOCAOTONGHOP,
        subPath: NAVIGATE_BAOCAOTONGHOP,
        subComponent: BaoCaoCapDienTongHop,
        permissionCode: cap_dien.a501,
      },
      {
        subTitle: TITLE_BAOCAOCHITIET,
        subPath: NAVIGATE_BAOCAOCHITIET,
        subComponent: BaoCaoChiTiet,
        permissionCode: cap_dien.a502,
      },
      {
        subTitle: TITLE_BAOCAODSCHAMTIENDO,
        subPath: NAVIGATE_BAOCAODSCHAMTIENDO,
        subComponent: BaoCaoDSChamTienDo,
        permissionCode: cap_dien.a503,
      },
      {
        subTitle: TITLE_BAOCAOSOPHA,
        subPath: NAVIGATE_BAOCAOSOPHA,
        subComponent: BaoCaoTongHopSoPha,
        permissionCode: cap_dien.a504,
      },
      {
        subTitle: TITLE_BAOCAODVDIEN,
        subPath: NAVIGATE_BAOCAODVDIEN,
        subComponent: BaoCaoDVDien,
        permissionCode: cap_dien.a505,
      },
      {
        subTitle: TITLE_BAOCAOBANDIEN,
        subPath: NAVIGATE_BAOCAOBANDIEN,
        subComponent: TaoMoiKeHoachBanDien,
        permissionCode: cap_dien.a506,
      },
      {
        subTitle: TITLE_BAOCAOKEHOACHTIEPNHAN,
        subPath: NAVIGATE_BAOCAOKEHOACHTIEPNHAN,
        subComponent: BaoCaoKeHoachTiepNhan,
        permissionCode: cap_dien.a507,
      },
      {
        subTitle: TITLE_BAOCAOCAPDIEN89160,
        subPath: NAVIGATE_BAOCAOCAPDIEN89160,
        subComponent: BaoCao89Den160,
        permissionCode: cap_dien.a508,
      },
      {
        subTitle: TITLE_BAOCAOTONGHOPHTLD,
        subPath: NAVIGATE_BAOCAOTONGHOPHTLD,
        subComponent: TongHopHTLD,
        permissionCode: cap_dien.a509,
      },
      {
        subTitle: TITLE_BAOCAOCHITIETHTLD,
        subPath: NAVIGATE_BAOCAOCHITIETHTLD,
        subComponent: ChiTietHTLD,
        permissionCode: cap_dien.a510,
      },
      {
        subTitle: TITLE_BAOCAOTONGHOPCPQT,
        subPath: NAVIGATE_BAOCAOTONGHOPCPQT,
        subComponent: TongHopChiPhiQT,
        permissionCode: cap_dien.a520,
      },
      {
        subTitle: TITLE_BAOCAOCHITIETCPQT,
        subPath: NAVIGATE_BAOCAOCHITIETCPQT,
        subComponent: ChiTietChiPhiQT,
        permissionCode: cap_dien.a530,
      },
      {
        subTitle: TITLE_BAOCAOTONGHOPKH,
        subPath: NAVIGATE_BAOCAOTONGHOPKH,
        subComponent: BaoCaoTTKH,
        permissionCode: cap_dien.a540,
      },
      {
        subTitle: TITLE_BAOCAODSKH,
        subPath: NAVIGATE_BAOCAODSKH,
        subComponent: BaoCaoDSKH,
        permissionCode: cap_dien.a550,
      },
      {
        subTitle: TITLE_BAOCAOPHATTRIENTHEOLO,
        subPath: NAVIGATE_BAOCAOPHATTRIENTHEOLO,
        subComponent: BaoCaoPhatTrienTheoLo,
        permissionCode: cap_dien.a560,
      },
      {
        subTitle: TITLE_BAOCAOLOGTHAOTAC,
        subPath: NAVIGATE_BAOCAOLOGTHAOTAC,
        subComponent: BaoCaoLogThaoTac,
        permissionCode: cap_dien.a570,
      },
      {
        subTitle: TITLE_BAOCAOSOLUONGVATTU,
        subPath: NAVIGATE_BAOCAOSOLUONGVATTU,
        subComponent: BaoCaoTongHopSoLuongVT,
        permissionCode: cap_dien.a580,
      },
      {
        subTitle: "Báo cáo chỉ tiêu chất lượng",
        subPath: "/chi-tieu-chat-luong",
        subComponent: BaoCaoChiTieuSoLuong,
        permissionCode: cap_dien.a590,
      },
      {
        subTitle: TITLE_TONGHOPTRANGWEBCAPDIEN,
        subPath: "/tong-hop-trang-web-cap-dien",
        subComponent: TongHopTrangWebCapDien,
        permissionCode: cap_dien.a591,
      },
      {
        subTitle: TITLE_BAOCAOCHITIETCAPDIEN,
        subPath: "/bao-cao-chi-tiet-cap-dien",
        subComponent: BaoCaoChiTietCapDien,
        permissionCode: cap_dien.a592,
      },
      {
        subTitle: TITLE_BAOCAOTHEODOIPHATTRIENKHACHHANG,
        subPath: "/bao-cao-theo-doi-phat-trien-khach-hang",
        subComponent: BCTDPhatTrienKhachHang,
        permissionCode: cap_dien.a593,
      },
      {
        subTitle: TITLE_BAOCAOKHACHHANGPHIEN9,
        subPath: "/bao-cao-khach-hang-phien-9",
        subComponent: BaoCaoKHPhien9,
        permissionCode: cap_dien.a594,
      },
    ],
  },
  {
    title: TITLE_TIENICH,
    path: `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_TIENICH}`,
    privateRoute: true,
    permissionCode: cap_dien.a40,
    subItems: [
      {
        subTitle: TITLE_TAIANHGIAYTOSCAN,
        subPath: NAVIGATE_TAIANHGIAYTOSCAN,
        subComponent: TaiAnhGiayToScan,
        permissionCode: cap_dien.a401,
      },
      {
        subTitle: TITLE_TRACUUHOSO,
        subPath: NAVIGATE_TRACUUHOSO,
        subComponent: TraCuuHoSo,
        permissionCode: cap_dien.a402,
      },
      {
        subTitle: TITLE_DONGBOTHOIGIAN,
        subPath: NAVIGATE_DONGBOTHOIGIAN,
        subComponent: DongBoThoiGian,
        permissionCode: cap_dien.a404,
      },
      {
        subTitle: TITLE_DONGBOYEUCAUCMIS,
        subPath: NAVIGATE_DONGBOYEUCAUCMIS,
        subComponent: DongBoYeuCauCmis,
        permissionCode: cap_dien.a403,
      },
    ],
  },
  {
    title: TITLE_THICONG,
    path: `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_THICONG}`,
    privateRoute: true,
    permissionCode: cap_dien.a10,
    subItems: [
      {
        subTitle: TITLE_XACNHANTHICONG,
        subPath: NAVIGATE_XACNHANTHICONG,
        subComponent: XacNhanThiCong,
        permissionCode: cap_dien.a101,
      },
      {
        subTitle: TITLE_KHOIPHUCTHICONG,
        subPath: NAVIGATE_KHOIPHUCTHICONG,
        subComponent: KhoiPhucThiCong,
        permissionCode: cap_dien.a102,
      },
      {
        subTitle: TITLE_LAPBBLDDUONGDAY,
        subPath: NAVIGATE_LAPBBLDDUONGDAY,
        subComponent: LapBienBanLDDuongDay,
        permissionCode: cap_dien.a103,
      },
      {
        subTitle: TITLE_HOSODUOCPHANCONG,
        subPath: NAVIGATE_HOSODUOCPHANCONG,
        subComponent: HSDaPhanCong,
        permissionCode: cap_dien.a104,
      },
      {
        subTitle: TITLE_HOSODANGTREOTHAO,
        subPath: NAVIGATE_HOSODANGTREOTHAO,
        subComponent: HSDangPhanCong,
        permissionCode: cap_dien.a105,
      },
    ],
  },
  {
    title: "Ban an toàn",
    path: `${NAVIGATE_DICHVUDIEN}/ban-an-toan`,
    privateRoute: true,
    permissionCode: cap_dien.a90,
    subItems: [
      {
        subTitle: "Xác nhận an toàn",
        subPath: "/xac-nhan-an-toan",
        subComponent: XacNhanAnToanBAT,
        permissionCode: cap_dien.a901,
      },
    ],
  },
  {
    title: TITLE_NGHIEMTHU,
    path: `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_NGHIEMTHU}`,
    privateRoute: true,
    permissionCode: cap_dien.a20,
    subItems: [
      {
        subTitle: TITLE_NGHIEMTHUHOSO,
        subPath: NAVIGATE_NGHIEMTHUHOSO,
        subComponent: XacNhanNghiemThu,
        permissionCode: cap_dien.a201,
      },
    ],
  },
  {
    title: TITLE_VATTU,
    path: `${NAVIGATE_DICHVUDIEN}${NAVIGATE_VATTU}`,
    privateRoute: true,
    permissionCode: cap_dien.a9,
    subItems: [
      {
        subTitle: TITLE_XACNHANXUATVATTU,
        subPath: NAVIGATE_XACNHANXUATVATTU,
        subComponent: XacNhanXuatVatTu,
        permissionCode: cap_dien.a91,
      },
      // {
      //   subTitle: TITLE_DANHSACHVATTU,
      //   subPath: NAVIGATE_DANHSACHVATTU,
      //   subComponent: DanhSachVatTu,
      // permissionCode: cap_dien.a142,
      // },
    ],
  },
  {
    title: TITLE_HOSO,
    path: `${NAVIGATE_DICHVUDIEN}${NAVIGATE_HOSOFLOW}`,
    privateRoute: true,
    permissionCode: cap_dien.a60,
    component: HoSoFlow,
    subItems: [
      // {
      //   subTitle: TITLE_HOSOFLOW,
      //   subPath: NAVIGATE_HOSOFLOW,
      //   subComponent: HoSoFlow,
      //   permissionCode: cap_dien.a601,
      // },
    ],
  },
  {
    title: TITLE_CAUHINHHOSO,
    path: `${NAVIGATE_DICHVUDIEN}${NAVIGATE_CAUHINHHOSO}`,
    privateRoute: true,
    component: CauHinhHoSo,
    permissionCode: cap_dien.a70,
    subItems: [],
  },
  {
    title: TITLE_THOIGIANLAMVIEC,
    path: `${NAVIGATE_DICHVUDIEN}${NAVIGATE_THOIGIANLAMVIEC}`,
    privateRoute: true,
    component: ThoiGianLamViec,
    permissionCode: cap_dien.a80,
    subItems: [],
  },
];

export default routes;
