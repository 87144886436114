import React from "react";
import ListMenu from "./list-menu";

function ListChucNang({
  row,
  setIsShowDetail,
  setDetail,
}) {
  return (
    <>
      <ListMenu
        row={row}
        setIsShowDetail={setIsShowDetail}
        setDetail={setDetail}
      />
    </>
  );
}

export default ListChucNang;
