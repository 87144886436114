import { Button, Tabs } from "antd";
import { Fragment } from "react";
import { Container } from "./css-styled";
import ChiTietHoSoTab from "./tabs/chi-tiet-ho-so";
import { closeModal } from "@state/system-config/reducer";
import NhatKiHoSoTab from "./tabs/nhat-ki-ho-so";
import TienTrinhTrongDichVuDienTab from "./tabs/tien-trinh-trong-dich-vu-dien";
import DanhSachAnhTabs from "../tai-anh-ho-so-khach-hang/tabs/tab-danh-sach-anh";
import NguoiThanTable from "./tabs/nguoi-than";
import ThongTinCongTo from "./tabs/chi-tiet-treo-thao";
import LoadingComponent from "@components/Loading";

const { TabPane } = Tabs;

export default function ChiTietHoSoModal(props) {
  const {
    indexTab,
    callback,
    dataDetail,
    loading,
    dataNhatKiHoSo,
    dataTienTrinh,
    dispatch,
    t,
    dataLoaiGT,
    firstLoaiGT,
    handleChangeType,
    dataImages,
    listId,
    dataTraLai,
    dataTroNgai,
    nguoiThan,
    thongTinCongTo,
    maLoaiYeuCau,
    recordRow,
    loadingDetail,
  } = props;
  return (
    <LoadingComponent loading={loadingDetail}>
      <Container className="modal-body">
        <Fragment>
          <Tabs defaultActiveKey={indexTab} onChange={callback}>
            <TabPane tab={"Thông tin chi tiết"} key="1">
              <ChiTietHoSoTab
                detailHoSo={dataDetail}
                loading={loading}
                dataTraLai={dataTraLai}
                dataTroNgai={dataTroNgai}
              />
            </TabPane>
            <TabPane tab={"Ảnh hồ sơ"} key="2">
              <DanhSachAnhTabs
                loading={loading}
                dataLoaiGT={dataLoaiGT}
                firstLoaiGT={firstLoaiGT}
                handleChangeType={handleChangeType}
                dataImages={dataImages}
                listId={listId}
              />
            </TabPane>
            <TabPane tab={"Nhật kí hồ sơ"} key="3">
              <NhatKiHoSoTab
                dataNhatKiHoSo={dataNhatKiHoSo}
                loading={loading}
              />
            </TabPane>
            <TabPane tab={"Tiến trình trong dịch vụ điện"} key="4">
              <TienTrinhTrongDichVuDienTab
                dataTienTrinh={dataTienTrinh}
                loading={loading}
              />
            </TabPane>
            <TabPane tab={"Người thân"} key="5">
              <NguoiThanTable dataSource={nguoiThan} />
            </TabPane>
            {thongTinCongTo === null ? null : (
              <TabPane tab={"Thông tin treo tháo"} key="6">
                <ThongTinCongTo
                  detail={thongTinCongTo}
                  loading={loading}
                  maLoaiYeuCau={maLoaiYeuCau}
                  recordRow={recordRow}
                />
              </TabPane>
            )}
          </Tabs>
        </Fragment>
      </Container>

      <div className="ant-modal-footer text-center">
        <Button type="default" onClick={() => dispatch(closeModal())}>
          {t("button.dong_lai")}
        </Button>
      </div>
    </LoadingComponent>
  );
}
