import { memo, useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  DatePicker,
  Row,
  Col,
  Checkbox,
} from "antd";
import { useTranslation } from "react-i18next";
import FormComponent from "@components/Form";
import { FORMAT_DATE, FORMAT_ENGLISH } from "@utils/constants";
import LoadingComponent from "@components/Loading";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import moment from "moment";
import { handleSubmitForm } from "./services";

const { TextArea } = Input;

function CauHinhDongBoModal({
  detaiDataTable,
  visible,
  closeModal,
  getListData,
}) {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const toDay = moment(moment(), FORMAT_DATE);
  const [tuNgay, setTuNgay] = useState(
    detaiDataTable && detaiDataTable.tuNgay ? detaiDataTable.tuNgay : toDay
  );
  const [denNgay, setDenNgay] = useState(
    detaiDataTable && detaiDataTable.denNgay ? detaiDataTable.denNgay : toDay
  );
  const [runJob, setRunJob] = useState(
    detaiDataTable.runJob ? detaiDataTable.runJob : false
  );
  const onFinish = (values) => {
    values.runJob = runJob;
    //submit data

    handleSubmitForm(
      values,
      detaiDataTable,
      setLoading,
      closeModal,
      getListData,
      form,
      tuNgay,
      denNgay
    );
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();
    // eslint-disable-next-line
  }, []);

  const handleChangeTuNgay = (date) => {
    let convert = "";
    if (date === null) convert = null;
    else convert = moment(date).format(FORMAT_ENGLISH);
    setTuNgay(convert);
  };

  const handleChangeDenNgay = (date) => {
    let convert = "";
    if (date === null) convert = null;
    else convert = moment(date).format(FORMAT_ENGLISH);
    setDenNgay(convert);
  };

  const handleChangeRunJob = (e) => {
    setRunJob(e.target.checked);
  };

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={
          detaiDataTable.id
            ? "Cập nhât cấu hình đồng bộ"
            : "Tạo cấu hình đồng bộ"
        }
        visible={visible}
        onCancel={closeModal}
        className="modal-cau-hinh"
        footer={null}
      >
        <FormComponent
          autoComplete="off"
          form={form}
          name="form-cau-hinh"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            ...detaiDataTable,
            tuNgay: moment(tuNgay, FORMAT_DATE),
            denNgay: moment(denNgay, FORMAT_DATE),
            gio: detaiDataTable && detaiDataTable.gio ? detaiDataTable.gio : 0,
            phut:
              detaiDataTable && detaiDataTable.phut ? detaiDataTable.phut : 0,
            giay:
              detaiDataTable && detaiDataTable.giay ? detaiDataTable.giay : 0,
          }}
          scrollToFirstError
        >
          <Form.Item
            label={t("cau_hinh.title.ten_cau_hinh")}
            name="tenCauHinh"
            className="required-field"
          >
            <Input
              ref={inputRef}
              placeholder="Nhập tên cấu hình"
              disabled={detaiDataTable.id ? true : false}
            />
          </Form.Item>
          <Row gutter={24}>
            <Col span={24} md={12}>
              <Form.Item
                label="Từ ngày"
                name="tuNgay"
                className="required-field"
              >
                <DatePicker
                  locale={locale}
                  format={FORMAT_DATE}
                  onChange={handleChangeTuNgay}
                  placeholder={FORMAT_DATE}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label="Đến ngày"
                name="denNgay"
                className="required-field"
              >
                <DatePicker
                  locale={locale}
                  format={FORMAT_DATE}
                  onChange={handleChangeDenNgay}
                  placeholder={FORMAT_DATE}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} md={8}>
              <Form.Item label="Giờ" name="gio" className="required-field">
                <Input type="number" min={0} max={23} placeholder="Nhập giờ" />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item label="Phút" name="phut" className="required-field">
                <Input type="number" min={0} max={59} placeholder="Nhập phút" />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item label="Giây" name="giay" className="required-field">
                <Input type="number" min={0} max={59} placeholder="Nhập giây" />
              </Form.Item>
            </Col>
            <Col span={24} md={19}>
              <Form.Item label="Route name" name="routeName">
                <Input placeholder="Nhập route name" />
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={5}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Item name="runJob" style={{ marginBottom: 0 }}>
                <Checkbox onChange={handleChangeRunJob} defaultChecked={runJob}>
                  Chạy tự động
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Mô tả" name="moTa">
            <TextArea rows={3} placeholder="Nhập mô tả" />
          </Form.Item>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-cau-hinh"
                loading={loading}
              >
                {detaiDataTable.id ? t("button.cap_nhat") : t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(CauHinhDongBoModal);
