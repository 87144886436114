import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
import { alertMessage422 } from "../service";

export const selectBCKHP9 = [
  {
    label: "1. Báo cáo khách hàng F9 thay đổi trong tháng",
    value: Endpoint.BCKHP9_BC_TTKH_P9_THAY_DOI_TRONG_THANG,
  },
  {
    label: "2. Báo cáo thông tin khách hàng F9 đang lắp công tơ điện tử",
    value: Endpoint.BCKHP9_BC_TTKH_P9_DANG_LAP_CONG_TO_DIEN_TU,
  },
  {
    label: "3. Báo cáo thông tin khách hàng F9 đang lắp công tơ khí",
    value: Endpoint.BCKHP9_BC_TTKH_P9_DANG_LAP_CONG_TO_CO_KHI,
  },
];

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
    })
  );
  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
