import Login from "@pages/LoginScreen/Login";
import HomePage from "@pages/HomePageScreen/HomePage";
import { NAVIGATE_DANGNHAP, NAVIGATE_TRANGCHU } from "@utils/constants";
const routes = [
  {
    title: "Đăng nhập",
    path: NAVIGATE_DANGNHAP,
    component: Login,
    privateRoute: false,
  },
  {
    title: "Trang chủ",
    path: NAVIGATE_TRANGCHU,
    component: HomePage,
    privateRoute: true,
  },
];

export default routes;
