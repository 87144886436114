/* eslint-disable react-hooks/exhaustive-deps */
import { FileSearchOutlined } from "@ant-design/icons";
import { METHOD_POST } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { nullToString, parseParams, removeAccents, stringToSlug } from "@utils/function";
import { authPostData, useCustomDebounce } from "@utils/request";
import { Form, Input, Select, Table, Tooltip } from "antd";
import moment from "moment";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { FillterKySoTaoFile } from "./list-bo-loc";
export default function KySoTaoFile() {
  const [dataDanhSachKhachHang, setDataDanhSachKhachHang] = useState([]);
  const [dataTable, setDataTable] = useState(dataDanhSachKhachHang);
  const location = useLocation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();
  const [colorTrangThai, setColorTrangThai] = useState("");
  const [colorTBLan2, setColorTBLan2] = useState("");
  const [doituongId, setDoituongId] = useState([]);
  const [urlDataKH, setUrlDataKH] = useState("");
  const [maKH, setmaKH] = useState("");
  const [tenKH, setTenKH] = useState("");
  const [soGCS, setSoGCS] = useState("");
  const [diaChi, setDiaChi] = useState("");
  const [tienNo, setTienNo] = useState("");
  const [trangThai, setTrangThai] = useState("Chưa tạo file");
  const [TBLan2, setTBLan2] = useState("");
  const [data, setData] = useState();
  const [filterConditions, setFilterConditions] = useState({
    ...parseParams(location.search),
    p_ky: 1,
    nam_ht: parseInt(moment(moment()._d).year()),
    nam_cu: parseInt(moment(moment()._d).subtract(1, "months").year().toString()),
    thang_ht: parseInt(`${moment(moment()._d).month() + 1}`),
    thang_cu: parseInt(`${moment(moment()._d).subtract(1, "months").month() + 1}`),
    p_thang: parseInt(`${moment(moment()._d).month() + 1}`),
    p_nam: parseInt(moment(moment()._d).year()),
    gio: 8,
    phut: 0,
  });

  const filterDebounce = useCustomDebounce(filterConditions);

  const listMaSo = useCustomDebounce(filterConditions?.p_ma_sogcs, 2000);

  const { Option } = Select;

  useEffect(() => {
    setDataTable(dataDanhSachKhachHang);
  }, [dataDanhSachKhachHang]);
  // mutil filter
  useEffect(() => {
    setDataTable(
      dataDanhSachKhachHang?.filter(
        (e) =>
          stringToSlug(removeAccents(nullToString(e?.mA_KHANG))).includes(stringToSlug(removeAccents(maKH))) &&
          stringToSlug(removeAccents(nullToString(e?.teN_KHANG))).includes(stringToSlug(removeAccents(tenKH))) &&
          stringToSlug(removeAccents(nullToString(e?.mA_SOGCS))).includes(stringToSlug(removeAccents(soGCS))) &&
          stringToSlug(removeAccents(nullToString(e?.duonG_PHO))).includes(stringToSlug(removeAccents(diaChi))) &&
          stringToSlug(removeAccents(nullToString(e?.tieN_NO))).includes(stringToSlug(removeAccents(tienNo))) &&
          e?.textTTInTaoFile.includes(trangThai) &&
          e?.texT_TRANG_THAI_THONG_BAO_L2.includes(TBLan2)
      )
    );
    if (TBLan2 === undefined && trangThai !== undefined) {
      setDataTable(
        dataDanhSachKhachHang?.filter(
          (e) =>
            stringToSlug(removeAccents(nullToString(e?.mA_KHANG))).includes(stringToSlug(removeAccents(maKH))) &&
            stringToSlug(removeAccents(nullToString(e?.teN_KHANG))).includes(stringToSlug(removeAccents(tenKH))) &&
            stringToSlug(removeAccents(nullToString(e?.mA_SOGCS))).includes(stringToSlug(removeAccents(soGCS))) &&
            stringToSlug(removeAccents(nullToString(e?.duonG_PHO))).includes(stringToSlug(removeAccents(diaChi))) &&
            stringToSlug(removeAccents(nullToString(e?.tieN_NO))).includes(stringToSlug(removeAccents(tienNo))) &&
            e?.textTTInTaoFile.includes(trangThai) &&
            e?.texT_TRANG_THAI_THONG_BAO_L2.includes(TBLan2)
        )
      );
      setTBLan2("");
    }
    if (trangThai === undefined && TBLan2 !== undefined) {
      setDataTable(
        dataDanhSachKhachHang?.filter(
          (e) =>
            stringToSlug(removeAccents(nullToString(e?.mA_KHANG))).includes(stringToSlug(removeAccents(maKH))) &&
            stringToSlug(removeAccents(nullToString(e?.teN_KHANG))).includes(stringToSlug(removeAccents(tenKH))) &&
            stringToSlug(removeAccents(nullToString(e?.mA_SOGCS))).includes(stringToSlug(removeAccents(soGCS))) &&
            stringToSlug(removeAccents(nullToString(e?.duonG_PHO))).includes(stringToSlug(removeAccents(diaChi))) &&
            stringToSlug(removeAccents(nullToString(e?.tieN_NO))).includes(stringToSlug(removeAccents(tienNo))) &&
            e?.textTTInTaoFile.includes(trangThai) &&
            e?.texT_TRANG_THAI_THONG_BAO_L2.includes(TBLan2)
        )
      );
      setTrangThai("");
    }

    if (TBLan2 === undefined && trangThai === undefined) {
      setDataTable(dataDanhSachKhachHang);
      setTrangThai("");
      setTBLan2("");
    }
  }, [maKH, tenKH, soGCS, diaChi, tienNo, trangThai, TBLan2, dataDanhSachKhachHang]);
  useEffect(() => {
    if (filterConditions?.p_ma_sogcs || filterConditions?.p_ma_sogcs?.length < 0) {
      setDataTable(null);
    }
  }, [filterConditions.p_ma_sogcs]);

  const handelCreateFile = (values) => {
    authPostData({
      url: `${Endpoint.TAO_BIEN_BAN_KY_SO_GUI_KH}`,
      method: METHOD_POST,
      payload: {
        doiTuongId: doituongId.toString(),
        ngayCatDien: values?.NgayGioCatDien?._d,
        hanThanhToan: values?.HanThanhToan?._d,
        gio: parseInt(filterConditions?.gio),
        phut: parseInt(filterConditions?.phut),
        data: filterConditions.Data,
        ky: values?.p_ky,
      },
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          getDataKH();
          setSelectedRowKeys([]);
          setFilterConditions((oldState) => ({
            ...oldState,
            Data: [],
            logFileKySoIds: "",
          }));
        }
      },
    });
  };

  const handelDeleteFile = () => {
    const idKySo = filterConditions.logFileKySoIds.filter(function (element) {
      return element !== undefined;
    });
    if (idKySo) {
      authPostData({
        url: `${Endpoint.HUY_BIEN_BAN_KY_SO_GUI_KH}`,
        method: METHOD_POST,
        payload: { logFileKySoIds: idKySo },
        setLoading,
        onSuccess: (res) => {
          if (res.statusCode === 200) {
            getDataKH();
            setSelectedRowKeys([]);
            setFilterConditions((oldState) => ({
              ...oldState,
              Data: [],
              logFileKySoIds: "",
            }));
          }
        },
      });
    }
  };

  const getDataKH = () => {
    if (
      urlDataKH &&
      filterDebounce.p_ma_sogcs &&
      filterDebounce.p_ma_sogcs.length > 0 &&
      filterDebounce.p_ky &&
      filterDebounce.p_nam
    ) {
      const queryString = {
        p_ky: filterDebounce.p_ky,
        nam_ht: filterDebounce.nam_ht,
        nam_cu: filterDebounce.nam_cu,
        thang_ht: filterDebounce.thang_ht,
        p_ma_sogcs: filterDebounce.p_ma_sogcs?.join(","),
        thang_cu: filterDebounce.thang_cu,
        p_thang: filterDebounce.p_thang,
        p_nam: filterDebounce.p_nam,
        doiTuongId: filterDebounce.doiTuongId,
        ma_to_1: filterDebounce.ma_to_1,
        id_hinhthuc: 5,
        chuc_nang: 1,
      };
      authPostData({
        url: `${urlDataKH}`,
        method: METHOD_POST,
        setLoading,
        payload: queryString,
        onSuccess: (res) => {
          if (res) {
            const item = res?.data.map((e, i) => {
              return { ...e, id: `${i + 1} ` };
            });
            setDataDanhSachKhachHang(
              item?.filter(function (element) {
                return element !== undefined;
              })
            );
          }
        },
      });
    }
  };

  useEffect(() => {
    getDataKH();
  }, [
    urlDataKH,
    filterDebounce.p_ky,
    filterDebounce.nam_ht,
    filterDebounce.nam_cu,
    filterDebounce.thang_ht,
    filterDebounce.p_ma_sogcs,
    filterDebounce.thang_cu,
    filterDebounce.p_thang,
    filterDebounce.p_nam,
    filterDebounce.doiTuongId,
    filterDebounce.ma_to_1,
  ]);

  useEffect(() => {
    onSelectChange();
  }, [dataDanhSachKhachHang]);

  useEffect(() => {
    setSelectedRowKeys([]);
    setFilterConditions((oldState) => ({
      ...oldState,
      mA_KHANG: [],
      danhSachKhachHang: [],
    }));
  }, [filterConditions?.p_ma_sogcs, doituongId, filterConditions?.ma_to_1]);
  const onSelectChange = (selected, record) => {
    if (record && selected) {
      const id = record?.map((item) => item.loG_FILE_KY_SO_ID);
      setSelectedRowKeys(selected);
      setFilterConditions((oldState) => ({
        ...oldState,
        mA_KHANG: selected,
        Data: record,
        logFileKySoIds: id,
      }));
    }
  };
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
    getCheckboxProps: (record) => ({
      name: record?.name,
    }),
  };

  const FilterByTenKH = (
    <div>
      <span>Tên khách hàng </span>
      <Input
        style={{ height: 30 }}
        onChange={(e) => {
          const currValue = e?.target.value;
          setTenKH(currValue);
        }}
      />
    </div>
  );
  const FilterByMaKH = (
    <div>
      <span>
        Mã khách hàng{" "}
        {filterConditions?.p_ma_sogcs?.length > 0 && dataTable?.length > 0 ? <span>({dataTable?.length})</span> : null}
      </span>
      <Input
        style={{ height: 30 }}
        onChange={(e) => {
          const currValue = e?.target.value;
          setmaKH(currValue);
        }}
      />
    </div>
  );
  const FilterBySOGCS = (
    <div>
      <span>Sổ GCS</span>
      <Input
        style={{ height: 30 }}
        onChange={(e) => {
          const currValue = e?.target.value;
          setSoGCS(currValue);
        }}
      />
    </div>
  );
  const FilterByDiaChi = (
    <div>
      <span>Địa chỉ</span>
      <Input
        style={{ height: 30 }}
        onChange={(e) => {
          const currValue = e?.target.value;
          setDiaChi(currValue);
        }}
      />
    </div>
  );
  const FilterByTienNo = (
    <div>
      <span>Tiền nợ</span>
      <Input
        style={{ height: 30 }}
        onChange={(e) => {
          const currValue = e?.target.value;
          setTienNo(currValue);
        }}
      />
    </div>
  );
  const FilterByTrangThai = (
    <div>
      <span>Trạng thái</span>
      <div className="FillterTrangThai">
        <Select
          defaultValue={"Chưa tạo file"}
          style={{ width: "100%", color: `${colorTrangThai}`, height: 30 }}
          allowClear="true"
          onChange={(e) => {
            const currValue = e;
            setTrangThai(currValue);
            if (e === "Chưa tạo file") {
              setColorTrangThai("green");
            }
            if (e === "Đã hủy xác nhận") {
              setColorTrangThai("red");
            }
            if (e === "Đã hủy ký số") {
              setColorTrangThai("red");
            }
            if (e === "Chưa xác nhận") {
              setColorTrangThai("green");
            }
            if (e === "Đã ký số") {
              setColorTrangThai("blue");
            }
            if (e === "Chưa ký số") {
              setColorTrangThai("green");
            }
          }}
        >
          <Option value="Chưa tạo file" style={{ color: "green" }}>
            Chưa tạo file
          </Option>
          <Option value="Chưa xác nhận" style={{ color: "green" }}>
            Chưa xác nhận
          </Option>
          <Option value="Chưa ký số" style={{ color: "green" }}>
            Chưa ký số
          </Option>

          <Option value="Đã hủy xác nhận" style={{ color: "red" }}>
            Đã hủy xác nhận
          </Option>
          <Option value="Đã hủy ký số" style={{ color: "red" }}>
            Đã hủy ký số
          </Option>
          <Option value="Đã ký số" style={{ color: "blue" }}>
            Đã ký số
          </Option>
        </Select>
      </div>
    </div>
  );
  const FilterByTBLan2 = (
    <div>
      <span>TB lần 2</span>
      <div className="FillterTrangThai">
        <Select
          allowClear="true"
          style={{ width: "100%", color: `${colorTBLan2}` }}
          onChange={(e) => {
            const currValue = e;
            setTBLan2(currValue);
            if (e === "Chưa nhắn") {
              setColorTBLan2("red");
            }
            if (e === "Đã nhắn") {
              setColorTBLan2("blue");
            }
          }}
        >
          <Option value="Chưa nhắn" style={{ color: "red" }}>
            Chưa nhắn
          </Option>
          <Option value="Đã nhắn" style={{ color: "blue" }}>
            Đã nhắn
          </Option>
        </Select>
      </div>
    </div>
  );
  const FilterByTacVu = (
    <div>
      <span>Tác vụ</span>
      <Input style={{ height: 30 }} />
    </div>
  );

  const columns = [
    {
      title: FilterByMaKH,
      dataIndex: "mA_KHANG",
      align: "center",
      width: 250,
    },
    {
      title: FilterByTenKH,
      dataIndex: "teN_KHANG",
      width: 200,
      key: "teN_KHANG",
    },
    {
      title: FilterBySOGCS,
      width: 150,
      align: "center",
      dataIndex: "mA_SOGCS",
    },
    {
      title: FilterByDiaChi,
      dataIndex: "duonG_PHO",
      width: 300,
    },
    {
      title: FilterByTienNo,
      align: "center",
      dataIndex: "tieN_NO",
      width: 100,
    },
    {
      title: FilterByTrangThai,
      dataIndex: "tranG_THAI_FILE",
      align: "center",
      width: 200,
      render: (text, record, index) => {
        const ChuaTaoFile = 1;
        const BiHuyTaoFile = 2;
        const DaTaoFile = 3;
        const BiHuyXacNhan = 4;
        const DaXacNhan = 5;
        const BiHuyKySo = 6;
        const DaKySo = 7;
        const DaGuiTinNhan = 8;
        return (
          <>
            <Form.Item name={`tranG_THAI_FILE-${record?.mA_KHANG}`}>
              {record?.tranG_THAI_FILE === ChuaTaoFile || record?.tranG_THAI_FILE === BiHuyTaoFile ? (
                <span key={index} style={{ color: "green" }}>
                  Chưa tạo file
                </span>
              ) : null}
              {record?.tranG_THAI_FILE === DaTaoFile ? (
                <span key={index} style={{ color: "green" }}>
                  Chưa xác nhận
                </span>
              ) : null}
              {record?.tranG_THAI_FILE === DaXacNhan ? (
                <span key={index} style={{ color: "green" }}>
                  Chưa ký số
                </span>
              ) : null}
              {record?.tranG_THAI_FILE === BiHuyKySo ? (
                <span key={index} style={{ color: "red" }}>
                  Đã hủy ký số
                </span>
              ) : null}
              {record?.tranG_THAI_FILE === BiHuyXacNhan ? (
                <span key={index} style={{ color: "red" }}>
                  Đã hủy xác nhận
                </span>
              ) : null}
              {record?.tranG_THAI_FILE === DaKySo || record?.tranG_THAI_FILE === DaGuiTinNhan ? (
                <span key={index} style={{ color: "blue" }}>
                  Đã ký số
                </span>
              ) : null}
            </Form.Item>
          </>
        );
      },
    },
    {
      title: FilterByTacVu,
      fixed: "right",
      width: 100,
      render: (record) => (
        <div className="action-table-column">
          <Tooltip title={"Xem"}>
            {record?.ddaN_FILEKY ? (
              <a href={`${record?.ddaN_FILEKY}`} rel="noreferrer" target="_blank">
                <FileSearchOutlined />
              </a>
            ) : null}
          </Tooltip>
        </div>
      ),
    },
  ];

  const columnsTBL2 = [
    {
      title: FilterByMaKH,
      dataIndex: "mA_KHANG",
      align: "center",
      width: 180,
    },
    {
      title: FilterByTenKH,
      dataIndex: "teN_KHANG",
      width: 200,
      key: "teN_KHANG",
    },
    {
      title: FilterBySOGCS,
      width: 150,
      align: "center",
      dataIndex: "mA_SOGCS",
    },
    {
      title: FilterByDiaChi,
      dataIndex: "duonG_PHO",
      width: 300,
    },
    {
      title: FilterByTienNo,
      align: "center",
      dataIndex: "tieN_NO",
      width: 100,
    },
    {
      title: FilterByTrangThai,
      dataIndex: "tranG_THAI_FILE",
      align: "center",
      width: 200,
      render: (text, record, index) => {
        const ChuaTaoFile = 1;
        const BiHuyTaoFile = 2;
        const DaTaoFile = 3;
        const BiHuyXacNhan = 4;
        const DaXacNhan = 5;
        const BiHuyKySo = 6;
        const DaKySo = 7;
        return (
          <>
            <Form.Item name={`tranG_THAI_FILE-${record?.mA_KHANG}`}>
              {record?.tranG_THAI_FILE === ChuaTaoFile || record?.tranG_THAI_FILE === BiHuyTaoFile ? (
                <span key={index} style={{ color: "green" }}>
                  Chưa tạo file
                </span>
              ) : null}
              {record?.tranG_THAI_FILE === DaTaoFile ? (
                <span key={index} style={{ color: "green" }}>
                  Chưa xác nhận
                </span>
              ) : null}
              {record?.tranG_THAI_FILE === DaXacNhan ? (
                <span key={index} style={{ color: "green" }}>
                  Chưa ký số
                </span>
              ) : null}
              {record?.tranG_THAI_FILE === BiHuyKySo ? (
                <span key={index} style={{ color: "red" }}>
                  Đã hủy ký số
                </span>
              ) : null}
              {record?.tranG_THAI_FILE === BiHuyXacNhan ? (
                <span key={index} style={{ color: "red" }}>
                  Đã hủy xác nhận
                </span>
              ) : null}
              {record?.tranG_THAI_FILE === DaKySo ? (
                <span key={index} style={{ color: "blue" }}>
                  Đã ký số
                </span>
              ) : null}
            </Form.Item>
          </>
        );
      },
    },
    {
      title: FilterByTBLan2,
      align: "center",
      width: 150,
      render: (text, record, index) => {
        return (
          <>
            <Form.Item name={`tranG_THAI_THONG_BAO_DIEN_LAN_2-${record?.mA_KHANG}`}>
              {record.texT_TRANG_THAI_THONG_BAO_L2 === "Chưa nhắn" ? (
                <span style={{ color: "red" }} key={index}>
                  {record.texT_TRANG_THAI_THONG_BAO_L2}
                </span>
              ) : null}
              {record.texT_TRANG_THAI_THONG_BAO_L2 === "Đã nhắn" ? (
                <span style={{ color: "blue" }} key={index}>
                  {record.texT_TRANG_THAI_THONG_BAO_L2}
                </span>
              ) : null}
            </Form.Item>
          </>
        );
      },
    },
    {
      title: FilterByTacVu,
      fixed: "right",
      width: 100,
      render: (record) => (
        <div className="action-table-column">
          <Tooltip title={"Xem"}>
            {record?.ddaN_FILEKY ? (
              <a href={`${record?.ddaN_FILEKY}`} rel="noreferrer" target="_blank">
                <FileSearchOutlined />
              </a>
            ) : null}
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <Container>
        <FillterKySoTaoFile
          form={form}
          setDoituongId={setDoituongId}
          filterConditions={filterConditions}
          doituongId={doituongId}
          setFilterConditions={setFilterConditions}
          setDataDanhSachKhachHang={setDataDanhSachKhachHang}
          handelCreateFile={handelCreateFile}
          setUrlDataKH={setUrlDataKH}
          urlDataKH={urlDataKH}
          setLoading={setLoading}
          handelDeleteFile={handelDeleteFile}
          setData={setData}
        />
        <Content>
          {urlDataKH && filterConditions.p_ma_sogcs && filterConditions.p_ma_sogcs.length > 0 ? (
            <>
              {doituongId === "e12382fc-5ebb-474d-a1a7-de277b424030" ? (
                <Table
                  sticky={true}
                  filterConditions={filterConditions}
                  dataSource={dataTable}
                  columns={columns}
                  pagination={false}
                  rowSelection={{
                    ...rowSelection,
                  }}
                  rowKey="id"
                  bordered
                  loading={loading}
                  scroll={{
                    x: true,
                    y: "70vh",
                  }}
                />
              ) : (
                <Table
                  sticky={true}
                  filterConditions={filterConditions}
                  dataSource={dataTable}
                  columns={columnsTBL2}
                  pagination={false}
                  rowSelection={{
                    ...rowSelection,
                  }}
                  rowKey="id"
                  bordered
                  loading={loading}
                  scroll={{
                    x: true,
                    y: "70vh",
                  }}
                />
              )}
            </>
          ) : (
            <Table
              sticky={true}
              filterConditions={filterDebounce}
              dataSource={null}
              columns={columns}
              pagination={false}
              rowSelection={{
                ...rowSelection,
              }}
              rowKey="id"
              bordered
              loading={loading}
              scroll={{
                x: true,
                y: "70vh",
              }}
            />
          )}
        </Content>
      </Container>
    </Fragment>
  );
}
const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
  .form-content {
    max-width: 100%;
    padding-left: 30px;
    justify-content: center;
    margin: auto;
  }
  .btn-file {
    display: flex;
    justify-content: space-around;
  }
  .ant-table-tbody > tr > td {
    padding: 8px !important;
  }
  .ant-form-item {
    margin: 0 !important;
  }
  .ant-col-24 {
    padding-bottom: 4px;
  }
  .FillterTrangThai {
    .ant-select-selector {
      height: 30px;
    }
  }
`;
const Content = styled.div`
  padding: 30px;
`;
