import styled from "styled-components";

export const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
  .title {
    margin: auto;
    display: flex;
    p {
      margin-left: 40px;
      font-size: 18px;
      margin-top: 5px;
      span {
        margin-left: 10px;
      }
    }
  }
  .bangke {
    margin: auto;
  }
`;