import React, { memo, useState } from "react";
import KySoModal from "./modal-ky-so";
import { handleKySo } from "./services";

function Index({
  url,
  params,
  setFetchDataTable,
  setSelectedRowKeys,
  visibleKySo,
  handleCloseModalKySo,
  content
}) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    handleKySo(
      setLoading,
      url,
      params,
      setFetchDataTable,
      setSelectedRowKeys,
      handleCloseModalKySo
    );
  };
  return (
    <KySoModal
      loading={loading}
      visible={visibleKySo}
      closeModal={handleCloseModalKySo}
      handleSubmit={handleSubmit}
      content={content}
    />
  );
}

export default memo(Index);
