import DanhMucDonVi from "@modules/tien-ich-chung/don-vi";
import DanhMucDiaChinh from "@modules/tien-ich-chung/dia-chinh";
import DanhMucLmei from "@modules/tien-ich-chung/lmei";

import DanhMucTram from "@modules/tien-ich-chung/tram";
import DanhMucLo from "@modules/tien-ich-chung/lo";
import DanhMucNganHang from "@modules/tien-ich-chung/ngan-hang";
import DanhMucDoi from "@modules/tien-ich-chung/doi";
import DanhMucChungLoaiCongTo from "@modules/tien-ich-chung/chung-loai-cong-to";
import DanhMucLyDoTreoThao from "@modules/tien-ich-chung/ly-do-treo-thao";
// import DanhMucVatTuLienSo from "@modules/tien-ich-chung/vat-tu-lien-so";
// import DanhMucChungLoaiCap from "@modules/tien-ich-chung/chung-loai-cap";
// import DanhMucGhiChiSo from "@modules/tien-ich-chung/so-ghi-chi-so";
// import DanhMucChungLoaiVatTu from "@modules/tien-ich-chung/chung-loai-vat-tu";
// import DanhMucLoaiTroNgai from "@modules/tien-ich-chung/loai-tro-ngai";
// import DanhMucChotTheoNgayHieuLuc from "@modules/tien-ich-chung/theo-ngay-hieu-luc";
// import DanhMucVungDonGia from "@modules/tien-ich-chung/vung-don-gia";

import DanhMucVatTu from "@modules/tien-ich-chung/vat-tu";

import DanhMucTyLeBanDien from "@modules/tien-ich-chung/ty-le-ban-dien";
import DanhMucUyQuyen from "@modules/tien-ich-chung/uy-quyen";
import DanhMucCauHinhChung from "@modules/tien-ich-chung/cau-hinh-chung";
import DanhMucTroNgai from "@modules/tien-ich-chung/tro-ngai";
import DanhMucLoaiYeuCau from "@modules/tien-ich-chung/loai-yeu-cau";
//import NoPageFound from "@pages/NoPageFound/NoPageFound";
import DanhMucEmail from "@modules/tien-ich-chung/email";
import DanhMucTrangThai from "@modules/tien-ich-chung/trang-thai";
import DanhMucCauHinhDongBo from "@modules/tien-ich-chung/cau-hinh-dong-bo";
import QuanLyChungThu from "@modules/tien-ich-chung/quan-ly-chung-thu";
import DanhMucNganhNghe from "@modules/tien-ich-chung/nganh-nghe";
import DanhMucNhomNganhNghe from "@modules/tien-ich-chung/nhom-nganh-nghe";
import DanhMucGiaNhomNganhNghe from "@modules/tien-ich-chung/gia-nhom-nganh-nghe";
import DanhMucThamChieuCapDienAp from "@modules/tien-ich-chung/tham-chieu-cap-dien-ap";
import DanhMucCapDienAp from "@modules/tien-ich-chung/cap-dien-ap";
import CapDienVersion from "@modules/tien-ich-chung/cap-dien-version";
import KiemTraThongTinHanhVi from "@modules/tien-ich-chung/kiem-tra-thong-tin-hanh-vi-nang-luc"
import TrangChu from "@modules/tien-ich-chung";
import {
  MODULE_TIENICHCHUNG,
  NAVIGATE_KIEMTRATHONGTINHANHVINANGLUC,
  NAVIGATE_TIENICHCHUNG_HINHTHUCLAPDATTHICONG,
  NAVIGATE_TIENICHCHUNG_LOAIDIEMDO,
  NAVIGATE_TIENICHCHUNG_LOAIGIAYTO,
  TITLE_HINHTHUCLAPDATTHICONG,
  TITLE_KIEMTRATHONGTINHANHVINANGLUC,
  TITLE_LOAIDIEMDO,
  TITLE_LOAIGIAYTO,
} from "@utils/constants";
import { tien_ich_chung } from "@permissions/m_tienichchung";
import DanhMucSoGhiChiSo from "@modules/tien-ich-chung/so-ghi-chi-so";
import HinhThucLapDat from "@modules/tien-ich-chung/hinh-thuc-lap-dat";
import HinhThucLapDatThiCong from "@modules/tien-ich-chung/hinh-thuc-thi-cong";
import LoaiDiemDo from "@modules/tien-ich-chung/loai-diem-do";
import LoaiHoSo from "@modules/tien-ich-chung/loai-ho-so";

import {
  NAVIGATE_TIENICHCHUNG,
  NAVIGATE_TIENICHCHUNG_CAUHINHCHUNG,
  NAVIGATE_TIENICHCHUNG_CAUHINHDONGBO,
  NAVIGATE_TIENICHCHUNG_DANHMUC,
  NAVIGATE_TIENICHCHUNG_DIACHINH,
  NAVIGATE_TIENICHCHUNG_DOI,
  NAVIGATE_TIENICHCHUNG_DONVI,
  NAVIGATE_TIENICHCHUNG_EMAIL,
  NAVIGATE_TIENICHCHUNG_HINHTHUCLAPDAT,
  NAVIGATE_TIENICHCHUNG_IMEI,
  NAVIGATE_TIENICHCHUNG_LO,
  NAVIGATE_TIENICHCHUNG_LOAIYEUCAU,
  NAVIGATE_TIENICHCHUNG_NGANHANG,
  NAVIGATE_TIENICHCHUNG_SOGHICHISO,
  NAVIGATE_TIENICHCHUNG_TILEBANDIEN,
  NAVIGATE_TIENICHCHUNG_TRAM,
  NAVIGATE_TIENICHCHUNG_TRANGTHAI,
  NAVIGATE_TIENICHCHUNG_TRONGAI,
  NAVIGATE_TIENICHCHUNG_UYQUYEN,
  NAVIGATE_TIENICHCHUNG_VATTU,
  NAVIGATE_TIENICHCHUNG_CHUNGLOAICONGTO,
  NAVIGATE_TIENICHCHUNG_LYDOTREOTHAO,
  NAVIGATE_TIENICHCHUNG_QLCHUNGTHU,
  NAVIGATE_TIENICHCHUNG_NGANHNGHE,
  NAVIGATE_TIENICHCHUNG_NHOMNGANHNGHE,
  NAVIGATE_TIENICHCHUNG_GIANHOMNGANHNGHE,
  NAVIGATE_TIENICHCHUNG_THAMCHIEUCAPDIENAP,
  NAVIGATE_TIENICHCHUNG_CAPDIENAP,
  NAVIGATE_CAPDIENVERSION,
  TITLE_CHUNGLOAICONGTO,
  TITLE_LYDOTREOTHAO,
  TITLE_DANHMUCCAUHINH,
  TITLE_DANHMUCCAUHINHDONGBO,
  TITLE_DANHMUCCHISO,
  TITLE_DANHMUCDIACHINH,
  TITLE_DANHMUCDOI,
  TITLE_DANHMUCDONVI,
  TITLE_DANHMUCEMAIL,
  TITLE_DANHMUCIMEI,
  TITLE_DANHMUCLO,
  TITLE_DANHMUCLOAIYEUCAU,
  TITLE_DANHMUCNGANHANG,
  TITLE_DANHMUCTILEBANDIEN,
  TITLE_DANHMUCTRAM,
  TITLE_DANHMUCTRANGTHAI,
  TITLE_DANHMUCTRONGAI,
  TITLE_DANHMUCUYQUYEN,
  TITLE_DANHMUCVATTU,
  TITLE_HINHTHUCLAPDAT,
  TITLE_QUANLYDANHMUC,
  TITLE_QL_CHUNG_THU,
  TITILE_DANHMUCNGANHNGHE,
  TITILE_DANHMUCNHOMNGANHNGHE,
  TITILE_DANHMUCGIANHOMNGANHNGHE,
  TITILE_DANHMUCTHAMCHIEUCAPDIENAP,
  TITILE_DANHMUCCAPDIENAP,
  // TITLE_TRANGCHU,
  TITLE_CAPDIENVERSION,
} from "@utils/constants";
const routes = [
  {
    title: "",
    path: NAVIGATE_TIENICHCHUNG,
    privateRoute: true,
    component: TrangChu,
    permissionCode: MODULE_TIENICHCHUNG,
    subItems: [],
  },
  {
    title: TITLE_QUANLYDANHMUC,
    path: `${NAVIGATE_TIENICHCHUNG}${NAVIGATE_TIENICHCHUNG_DANHMUC}`,
    privateRoute: true,
    permissionCode: tien_ich_chung.m1,
    subItems: [
      {
        subTitle: TITLE_DANHMUCVATTU,
        subPath: NAVIGATE_TIENICHCHUNG_VATTU,
        subComponent: DanhMucVatTu,
        permissionCode: tien_ich_chung.m11,
      },
      {
        subTitle: TITLE_DANHMUCDONVI,
        subPath: NAVIGATE_TIENICHCHUNG_DONVI,
        subComponent: DanhMucDonVi,
        permissionCode: tien_ich_chung.m12,
      },
      {
        subTitle: TITLE_DANHMUCDIACHINH,
        subPath: NAVIGATE_TIENICHCHUNG_DIACHINH,
        subComponent: DanhMucDiaChinh,
        permissionCode: tien_ich_chung.m13,
      },
      {
        subTitle: TITLE_DANHMUCIMEI,
        subPath: NAVIGATE_TIENICHCHUNG_IMEI,
        subComponent: DanhMucLmei,
        permissionCode: tien_ich_chung.m14,
      },

      {
        subTitle: TITLE_DANHMUCTRAM,
        subPath: NAVIGATE_TIENICHCHUNG_TRAM,
        subComponent: DanhMucTram,
        permissionCode: tien_ich_chung.m15,
      },
      {
        subTitle: TITLE_DANHMUCLO,
        subPath: NAVIGATE_TIENICHCHUNG_LO,
        subComponent: DanhMucLo,
        permissionCode: tien_ich_chung.m16,
      },
      {
        subTitle: TITLE_DANHMUCNGANHANG,
        subPath: NAVIGATE_TIENICHCHUNG_NGANHANG,
        subComponent: DanhMucNganHang,
        permissionCode: tien_ich_chung.m17,
      },
      {
        subTitle: TITLE_DANHMUCDOI,
        subPath: NAVIGATE_TIENICHCHUNG_DOI,
        subComponent: DanhMucDoi,
        permissionCode: tien_ich_chung.m18,
      },

      {
        subTitle: TITLE_DANHMUCTILEBANDIEN,
        subPath: NAVIGATE_TIENICHCHUNG_TILEBANDIEN,
        subComponent: DanhMucTyLeBanDien,
        permissionCode: tien_ich_chung.m19,
      },

      {
        subTitle: TITLE_DANHMUCUYQUYEN,
        subPath: NAVIGATE_TIENICHCHUNG_UYQUYEN,
        subComponent: DanhMucUyQuyen,
        permissionCode: tien_ich_chung.m110,
      },

      // {
      //   subTitle: TITLE_DANHMUCTINHTRANG,
      //   subPath: NAVIGATE_TIENICHCHUNG_TINHTRANG,
      //   subComponent: DanhMucTinhTrang,
      // },

      {
        subTitle: TITLE_DANHMUCTRANGTHAI,
        subPath: NAVIGATE_TIENICHCHUNG_TRANGTHAI,
        subComponent: DanhMucTrangThai,
        permissionCode: tien_ich_chung.m111,
      },
      {
        subTitle: TITLE_DANHMUCTRONGAI,
        subPath: NAVIGATE_TIENICHCHUNG_TRONGAI,
        subComponent: DanhMucTroNgai,
        permissionCode: tien_ich_chung.m112,
      },
      {
        subTitle: TITLE_DANHMUCLOAIYEUCAU,
        subPath: NAVIGATE_TIENICHCHUNG_LOAIYEUCAU,
        subComponent: DanhMucLoaiYeuCau,
        permissionCode: tien_ich_chung.m113,
      },

      {
        subTitle: TITLE_DANHMUCEMAIL,
        subPath: NAVIGATE_TIENICHCHUNG_EMAIL,
        subComponent: DanhMucEmail,
        permissionCode: tien_ich_chung.m114,
      },
      {
        subTitle: TITLE_DANHMUCCAUHINH,
        subPath: NAVIGATE_TIENICHCHUNG_CAUHINHCHUNG,
        subComponent: DanhMucCauHinhChung,
        permissionCode: tien_ich_chung.m115,
      },
      {
        subTitle: TITLE_DANHMUCCAUHINHDONGBO,
        subPath: NAVIGATE_TIENICHCHUNG_CAUHINHDONGBO,
        subComponent: DanhMucCauHinhDongBo,
        permissionCode: tien_ich_chung.m116,
      },
      {
        subTitle: TITLE_DANHMUCCHISO,
        subPath: NAVIGATE_TIENICHCHUNG_SOGHICHISO,
        subComponent: DanhMucSoGhiChiSo,
        permissionCode: tien_ich_chung.m117,
      },
      {
        subTitle: TITLE_HINHTHUCLAPDAT,
        subPath: NAVIGATE_TIENICHCHUNG_HINHTHUCLAPDAT,
        subComponent: HinhThucLapDat,
        permissionCode: tien_ich_chung.m118,
      },
      {
        subTitle: TITLE_HINHTHUCLAPDATTHICONG,
        subPath: NAVIGATE_TIENICHCHUNG_HINHTHUCLAPDATTHICONG,
        subComponent: HinhThucLapDatThiCong,
        permissionCode: tien_ich_chung.m119,
      },
      {
        subTitle: TITLE_LOAIDIEMDO,
        subPath: NAVIGATE_TIENICHCHUNG_LOAIDIEMDO,
        subComponent: LoaiDiemDo,
        permissionCode: tien_ich_chung.m120,
      },
      {
        subTitle: TITLE_LOAIGIAYTO,
        subPath: NAVIGATE_TIENICHCHUNG_LOAIGIAYTO,
        subComponent: LoaiHoSo,
        permissionCode: tien_ich_chung.m121,
      },
      {
        subTitle: TITLE_CHUNGLOAICONGTO,
        subPath: NAVIGATE_TIENICHCHUNG_CHUNGLOAICONGTO,
        subComponent: DanhMucChungLoaiCongTo,
        permissionCode: tien_ich_chung.m122,
      },
      {
        subTitle: TITLE_LYDOTREOTHAO,
        subPath: NAVIGATE_TIENICHCHUNG_LYDOTREOTHAO,
        subComponent: DanhMucLyDoTreoThao,
        permissionCode: tien_ich_chung.m123,
      },
      {
        subTitle: TITLE_QL_CHUNG_THU,
        subPath: NAVIGATE_TIENICHCHUNG_QLCHUNGTHU,
        subComponent: QuanLyChungThu,
        permissionCode: tien_ich_chung.m125,
      },
      {
        subTitle: TITILE_DANHMUCNGANHNGHE,
        subPath: NAVIGATE_TIENICHCHUNG_NGANHNGHE,
        subComponent: DanhMucNganhNghe,
        permissionCode: tien_ich_chung.m126,
      },
      {
        subTitle: TITILE_DANHMUCNHOMNGANHNGHE,
        subPath: NAVIGATE_TIENICHCHUNG_NHOMNGANHNGHE,
        subComponent: DanhMucNhomNganhNghe,
        permissionCode: tien_ich_chung.m127,
      },
      {
        subTitle: TITILE_DANHMUCGIANHOMNGANHNGHE,
        subPath: NAVIGATE_TIENICHCHUNG_GIANHOMNGANHNGHE,
        subComponent: DanhMucGiaNhomNganhNghe,
        permissionCode: tien_ich_chung.m128,
      },
      {
        subTitle: TITILE_DANHMUCTHAMCHIEUCAPDIENAP,
        subPath: NAVIGATE_TIENICHCHUNG_THAMCHIEUCAPDIENAP,
        subComponent: DanhMucThamChieuCapDienAp,
        permissionCode: tien_ich_chung.m130,
      },
      {
        subTitle: TITILE_DANHMUCCAPDIENAP,
        subPath: NAVIGATE_TIENICHCHUNG_CAPDIENAP,
        subComponent: DanhMucCapDienAp,
        permissionCode: tien_ich_chung.m129,
      },
    ],
  },
  {
    title: TITLE_CAPDIENVERSION,
    path: NAVIGATE_CAPDIENVERSION,
    privateRoute: true,
    component: CapDienVersion,
    permissionCode: tien_ich_chung.m2,
    subItems: [],
  },
  {
    title: TITLE_KIEMTRATHONGTINHANHVINANGLUC,
    path: NAVIGATE_KIEMTRATHONGTINHANHVINANGLUC,
    privateRoute: true,
    component: KiemTraThongTinHanhVi,
    permissionCode: tien_ich_chung.m3,
    subItems: [],
  },
];

export default routes;
