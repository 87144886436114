import { SearchOutlined } from "@ant-design/icons";
import FormComponent from "@components/Form";
import TableComponent from "@components/TableComponent";
import { getThangLamViec } from "@modules/giao-nhan-dien-nang/tong-hop-so-lieu/tinh-san-luong/services";
import { alertMessage, handlePagination } from "@utils/function";
import { useCustomDebounce } from "@utils/request";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Table,
} from "antd";
import moment from "moment";
import { Fragment, memo, useCallback, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import {
  ColCheck,
  ContainerForm,
  SpanText,
  ThangLaySoLieu,
  TitleItem,
} from "./css-styled";
import {
  callAPILayDoXa,
  callAPIUpdateThongTin,
  handleListData,
} from "./services";

function TableAnhXaDiemdo(props) {
  const [thang, setThang] = useState(1);
  const [nam, setNam] = useState(2017);
  const [term, setTerm] = useState("");
  const [selectedRowKey, setSelectedRowKey] = useState([1]);
  const [dataSource, setDataSource] = useState([]);
  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const debounceTerm = useCustomDebounce(term);
  const { columns, form } = props;

  const [isCGKG, setIsCGKG] = useState(detail?.kenhGiao === "N" ? true : false);
  const [isCNKG, setIsCNKG] = useState(detail?.kenhGiao === "G" ? true : false);
  const [isCGKN, setIsCGKN] = useState(detail?.kenhNhan === "N" ? true : false);
  const [isCNKN, setIsCNKN] = useState(detail?.kenhNhan === "G" ? true : false);

  const [isValueCGKG, setValueIsCGKG] = useState(false);
  const [isValueCNKG, setValueIsCNKG] = useState(false);
  const [isValueCGKN, setValueIsCGKN] = useState(false);
  const [isValueCNKN, setValueIsCNKN] = useState(false);

  const [isRowChanged, setIsRowChanged] = useState(false);
  const [dataDoxaAnhxa, setDataDoxaAnhxa] = useState([]);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: 1,
    pageSize: 15,
  });
  const [thangLamViec, setThangLamViec] = useState({});

  //pagination table
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  useEffect(() => {
    getThangLamViec(setThangLamViec, setLoading);
  }, []);
  //   console.log("dataSource", dataSource)
  // console.log(
  //   "merge",
  //   mergeTwoArrayObjectsEqualItem(dataSource, dataDoxaAnhxa)
  // );

  function mergeTwoArrayObjectsEqualItem(arr1, arr2) {
    const listSITECODE = arr2.map((el) => el.SITECODE);
    const merge = [];

    arr1.forEach((item) => {
      if (item.maDoXa === null || !listSITECODE.includes(item.maDoXa)) {
        // console.log("vao 1", item);
        merge.push({ ...item });
      } else {
        arr2.forEach((el) => {
          // console.log("vao 2", item, el);
          if (item.maDoXa.trim() === el.SITECODE.trim()) {
            merge.push({ ...item, ...el });
          }
          // if(item.maDoXa.trim() !== el.SITECODE.trim()) {
          //   // console.log("vao 1", item);
          //   merge.push({ ...item });
          // }
        });
      }
    });
    return merge;
  }

  //change check kênh
  const onChangeCheck = (e, field) => {
    if (field === "chieuGiaoKG") {
      if (e.target.checked) {
        setIsCNKG(true);
      } else {
        setIsCNKG(false);
      }
      setValueIsCGKG(e.target.checked);
    } else if (field === "chieuNhanKG") {
      if (e.target.checked) setIsCGKG(true);
      else {
        setIsCGKG(false);
      }
      setValueIsCNKG(e.target.checked);
    } else if (field === "chieuGiaoKN") {
      if (e.target.checked) setIsCNKN(true);
      else {
        setIsCNKN(false);
      }
      setValueIsCGKN(e.target.checked);
    } else {
      if (e.target.checked) setIsCGKN(true);
      else {
        setIsCGKN(false);
      }
      setValueIsCNKN(e.target.checked);
    }
  };

  const getListData = useCallback(() => {
    handleListData(
      debounceTerm,
      setLoading,
      setDataSource,
      setDetail,
      setIsCGKG,
      setIsCNKG,
      setIsCGKN,
      setIsCNKN,
      setValueIsCGKG,
      setValueIsCNKG,
      setValueIsCGKN,
      setValueIsCNKN,
      thang,
      nam,
      selectedRowKey
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceTerm, thang, nam]);

  const callLayDoXa = () => {
    const arrayMaDoXa = [];
    for (
      let i =
        filterConditions.pageIndex * filterConditions.pageSize -
        filterConditions.pageSize;
      i < filterConditions.pageIndex * filterConditions.pageSize;
      i++
    ) {
      arrayMaDoXa.push(dataSource[i]?.maDoXa);
    }

    const listMaDoXa = arrayMaDoXa.filter((item) => item !== null);

    const payload = {
      maDoXas: listMaDoXa,
      thang: thang,
      nam: nam,
    };
    callAPILayDoXa(setLoading, setDataDoxaAnhxa, payload);
  };

  useEffect(() => {
    setDataSource(mergeTwoArrayObjectsEqualItem(dataSource, dataDoxaAnhxa));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDoxaAnhxa]);

  useEffect(() => {
    getListData();
  }, [getListData]);

  const onChangeDate = (date, dateString) => {
    setThang(dateString.split("-")[0]);
    setNam(dateString.split("-")[1]);
  };

  useEffect(() => {
    if (isRowChanged) {
      setIsCGKG(detail?.kenhGiao === "N" ? true : false);
      setIsCNKG(detail?.kenhGiao === "G" ? true : false);
      setIsCGKN(detail?.kenhNhan === "N" ? true : false);
      setIsCNKN(detail?.kenhNhan === "G" ? true : false);

      setValueIsCGKG(detail?.kenhGiao === "G" ? true : false);
      setValueIsCNKG(detail?.kenhGiao === "N" ? true : false);
      setValueIsCGKN(detail?.kenhNhan === "G" ? true : false);
      setValueIsCNKN(detail?.kenhNhan === "N" ? true : false);
      setIsRowChanged(false);
    }
  }, [detail?.kenhGiao, detail?.kenhNhan, isRowChanged]);

  //submit cập nhật
  const onFinish = (values) => {
    const payload = {
      ...values,
      id: detail.id,
      kenhGiao: isCNKG ? "G" : isCGKG ? "N" : "X",
      kenhNhan: isCNKN ? "G" : isCGKN ? "N" : "X",
      thang: thang,
    };
    let isDup = false;
    if (
      payload.kenhGiao === payload.kenhNhan &&
      payload.kenhGiao !== "X" &&
      payload.kenhNhan !== "X"
    ) {
      alertMessage(
        "error",
        "Thông báo!",
        "Kênh giao và kênh nhận không được chọn cùng chiều"
      );
      isDup = true;
    } else {
      isDup = false;
    }
    if (!isDup) callAPIUpdateThongTin(payload, setLoadingForm, getListData);
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setDetail(selectedRows[0]);
    setSelectedRowKey(selectedRowKeys);
    setIsRowChanged(true);
  };

  const rowSelection = {
    type: "radio",
    selectedRowKeys: selectedRowKey,
    onChange: onSelectChange,
  };

  //fetch form
  useEffect(() => {
    form.setFieldsValue({
      maDiemDo: detail?.maDiemDo,
      thangLaySoLieu: detail?.thangLaySoLieu,
      maDoXa: detail?.maDoXa,
    });
  }, [detail, form, isCGKG, isCGKN]);

  const [valueDD, setValueDD] = useState(form.getFieldValue("maDiemDo"));
  //set value input search
  const changeTerm = (e) => {
    setTerm(e.target.value);
  };

  //change value điểm đo
  const handleChangeDD = (e) => {
    form.setFieldsValue({ maDoXa: e.target.value });
    setValueDD(e.target.value);
  };

  // const [ vt ] = useVT(() => ({ scroll: { y: 500 } }), []);

  const disabledDate = (current) => {
    // Can not select days after thangLamViec
    return (
      current &&
      current.startOf("month").valueOf() >
        moment(thangLamViec).endOf("month").valueOf()
    );
  };
  return (
    <Fragment>
      {/* <Container> */}
      <ContainerForm>
        <h2 className="font-bold">Thông tin điểm đo</h2>
        <FormComponent
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          form={form}
          name="form-action"
          layout="horizonal"
          onFinish={onFinish}
          initialValues={{
            ...detail,
            thangLaySoLieu: detail?.thangLaySoLieu,
            maDoXa: detail?.maDoXa,
          }}
        >
          <Row gutter={24}>
            <Col span={24} lg={8}>
              <h6
                className="fs-14px c-t-label"
                style={{ paddingTop: 10, paddingBottom: 6 }}
              >
                <span>Mã điểm đo:</span>{" "}
                <span className="fs-14px font-bold">{detail?.maDiemDo}</span>
              </h6>
            </Col>
            <Col span={24} lg={8}>
              <h6 className="fs-14px c-t-label">
                <span>Thông tin điểm đo:</span>{" "}
                <span className="fs-14px font-bold">
                  {detail?.thongTinDiemDo}
                </span>
              </h6>
            </Col>
            <Col span={24} lg={8}>
              <h6 className="fs-14px c-t-label">
                <span>Tên trạm:</span>{" "}
                <span className="fs-14px font-bold">{detail?.tenTram}</span>
              </h6>
            </Col>
            <Col span={24} lg={24}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  paddingBottom: 20,
                }}
              >
                <Form.Item name="maDoXa" label="Mã điểm đo ánh xạ (đo xa)">
                  <Input
                    style={{ width: "424px", marginLeft: "8%" }}
                    onChange={handleChangeDD}
                    defaultValue={detail?.maDoXa}
                  />
                </Form.Item>
              </div>
            </Col>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <ColCheck span={24} lg={8}>
                <label className="label" style={{ marginRight: "16px" }}>
                  Kênh giao
                </label>
                <div>
                  <Checkbox
                    onChange={(e) => onChangeCheck(e, "chieuGiaoKG")}
                    disabled={isCGKG}
                    checked={isValueCGKG}
                  >
                    Chiều giao
                  </Checkbox>
                  <Checkbox
                    onChange={(e) => onChangeCheck(e, "chieuNhanKG")}
                    disabled={isCNKG}
                    checked={isValueCNKG}
                  >
                    Chiều nhận
                  </Checkbox>
                </div>
              </ColCheck>
              <ColCheck span={24} lg={8}>
                <label className="label" style={{ marginRight: "12px" }}>
                  Kênh nhận
                </label>
                <div>
                  <Checkbox
                    onChange={(e) => onChangeCheck(e, "chieuGiaoKN")}
                    disabled={isCGKN}
                    checked={isValueCGKN}
                  >
                    Chiều giao
                  </Checkbox>
                  <Checkbox
                    onChange={(e) => onChangeCheck(e, "chieuNhanKN")}
                    disabled={isCNKN}
                    checked={isValueCNKN}
                  >
                    Chiều nhận
                  </Checkbox>
                </div>
              </ColCheck>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  form="form-action"
                  key="submit"
                  loading={loadingForm}
                >
                  Cập nhật
                </Button>
              </div>
            </div>
          </Row>
        </FormComponent>
      </ContainerForm>
      <ThangLaySoLieu>
        <Col span={11}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              style={{ minWidth: 294 }}
              name="thangLaySoLieu"
              label="Tháng lấy số liệu"
            ></Form.Item>
            <DatePicker
              disabledDate={disabledDate}
              onChange={onChangeDate}
              style={{ width: "100%", height: "32px" }}
              picker="month"
              format="MM-YYYY"
              defaultValue={moment(new Date("01/01/2017"))}
            />
          </div>
        </Col>
        <Button
          type="primary"
          className="btn-bg-yellow "
          key="click"
          onClick={callLayDoXa}
          style={{ marginLeft: 20 }}
          disabled={valueDD === "" || valueDD === null ? true : false}
        >
          Lấy đo xa
        </Button>
      </ThangLaySoLieu>
      <Row gutter={24} className=" pd-5px">
        <Col span={24} lg={6}>
          <TitleItem>Thông tin ánh xạ</TitleItem>
        </Col>
        <Col span={24} lg={10} style={{ display: "flex", paddingTop: 10 }}>
          <SpanText>
            Tổng:{" "}
            <SpanText className="textColorBlue fs-16px font-bold  mr-10px ml-10px ">
              {dataSource?.length}
            </SpanText>{" "}
            điểm đo
          </SpanText>
        </Col>
        <Col span={24} lg={8} style={{ padding: 10 }}>
          <Input
            placeholder="Tìm kiếm theo mã điểm đo, thông tin điểm đo"
            prefix={<SearchOutlined />}
            allowClear
            onChange={changeTerm}
            onPressEnter={() => changeTerm()}
          />
        </Col>
      </Row>
      <Scrollbars
        style={{ height: window.innerHeight > 660 ? "80vh" : "68vh" }}
        autoHide
      >
        <TableComponent
          pagination={filterConditions}
          loading={loading}
          rowSelection={rowSelection}
          bordered={true}
          columns={columns}
          dataSource={dataSource}
          onChange={onChangePagination}
          onChangePagination={onChangePagination}
          totalData={dataSource.lenght}
        />
      </Scrollbars>
    </Fragment>
  );
}

// const Container = styled.div`
//   height: calc(100vh - 100px);
//   overflow: hidden;
// `
export default memo(TableAnhXaDiemdo);
