const h1 = {
  h1: "h1",
  h1x: "h1x",
};

const h11 = {
  h11: "h11",
  h11x: "h11x",
};

const h12 = {
  h12: "h12",
  h12x: "h12x",
};

const h2 = {
  h2: "h2",
  h2x: "h2x",
};

const h21 = {
  h21: "h21",
  h21x: "h21x",
};

const h3 = {
  h3: "h3",
  h3x: "h3x",
};

const dktt = {
  h: "h",
};

export const dang_ky_thanh_toan = {
  ...dktt,
  ...h1,
  ...h11,
  ...h12,
  ...h2,
  ...h21,
  ...h3,
};
