import { Col, Row, Button, Table, Tag, Tooltip } from "antd";
import { Fragment, useEffect, useState } from "react";
import { Container, TableStyled } from "../css-styled";
import { closeModal } from "@state/system-config/reducer";
import { useDispatch } from "react-redux";
import { AP_GIA_TRANG_THAI_BK, AP_GIA_TRANG_THAI } from "@utils/constants";
import { handleDetailBK } from "../../services";
import { InfoCircleOutlined, FilePdfOutlined } from "@ant-design/icons";
import LoadingComponent from "@components/Loading";
import { openModal } from "@state/system-config/reducer";
import ChiTietDiemDo from "../../chi-tiet-diem-do";
export default function Index(props) {
  const dispatch = useDispatch();
  const [detailBK, setDetailBK] = useState([]);
  const [loading, setLoading] = useState(true);
  const { detail } = props;

  useEffect(() => {
    handleDetailBK(detail.id, setDetailBK, setLoading);
  }, [detail.id]);

  const detailDiemDo = (record) => {
    return dispatch(
      openModal({
        content: (
          <ChiTietDiemDo
            detailRecord={record}
          />
        ),
        size: "large",
        title: `Chi tiết điểm đo - ${record.maDiemDo}`
      })
    );
  };
  const detailDD = (record) => {
    return {
      onDoubleClick: () => {
        detailDiemDo(record)
      },
    };
  };
  const columnDM = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => detailDiemDo(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      fixed: "left"
    },
    {
      title: "Tên Khách hàng",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      key: "maKhachHang",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Số công tơ",
      dataIndex: "soCongTo",
      key: "soCongTo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Thay đổi định mức",
      dataIndex: "thayDoiDinhMuc",
      key: "thayDoiDinhMuc",
      render: (text, record) => (
        <div>
          {record.isThayDoi === true ? (<span>Có</span>)
            : (<span>Không</span>)}
        </div>
      ),
      width: "10%",
    },
    {
      title: "Ngày áp dụng mới",
      dataIndex: "ngayApDung",
      key: "ngayApDung",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
    },
    {
      title: "Định mức trước kiểm tra",
      dataIndex: "soHo",
      key: "soHo",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Định mức sau kiểm tra",
      dataIndex: "soHoMoi",
      key: "soHoMoi",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Thay đổi (+/-)",
      dataIndex: "thayDoi",
      key: "thayDoi",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text, record) => (
        <>
          {
            record.trangThai?.toString() === AP_GIA_TRANG_THAI.DA_LAY_TU_WEB ?
              <Tag style={{ display: "block", textAlign: "center" }} color={"#175bd1"}>{"Chờ phân công"}</Tag> :
              (
                record.trangThai?.toString() === AP_GIA_TRANG_THAI.DA_PHAN_CONG ?
                  <Tag style={{ display: "block", textAlign: "center" }} color={"#c91a34"}>{"Đã lấy về MTB"}</Tag> :
                  (
                    record.trangThai?.toString() === AP_GIA_TRANG_THAI.DANG_THUC_HIEN ?
                      <Tag style={{ display: "block", textAlign: "center" }} color={"#9516f0"}>{"Đang thực hiện"}</Tag> :
                      (
                        record.trangThai?.toString() === AP_GIA_TRANG_THAI.MTB_DA_GUI_DL ?
                          <Tag style={{ display: "block", textAlign: "center" }} color={"#e060a5"}>{"Chờ NV xác nhận"}</Tag> :
                          (
                            record.trangThai?.toString() === AP_GIA_TRANG_THAI.NVAG_DA_XAC_NHAN ?
                              <Tag style={{ display: "block", textAlign: "center" }} color={"#59e71f"}>{"Chờ bộ phận áp giá duyệt"}</Tag> :
                              (
                                record.trangThai?.toString() === AP_GIA_TRANG_THAI.BPAG_DA_XAC_NHAN ?
                                  <Tag style={{ display: "block", textAlign: "center" }} color={"#0aad98"}>{"Chờ PKD duyệt"}</Tag> :
                                  (
                                    record.trangThai?.toString() === AP_GIA_TRANG_THAI.PKD_DA_XAC_NHAN ?
                                      <Tag style={{ display: "block", textAlign: "center" }} color={"#9d41e3"}>{"Chờ lãnh đạo duyệt"}</Tag> :
                                      (
                                        record.trangThai?.toString() === AP_GIA_TRANG_THAI.LD_DA_XAC_NHAN ?
                                          <Tag style={{ display: "block", textAlign: "center" }} color={"#949d09"}>{"Lãnh đạo đã duyệt"}</Tag> :
                                          <Tag style={{ display: "block", textAlign: "center" }} color={"#26c0e9"}>{"Đã đồng bộ"}</Tag>
                                      )
                                  )
                              )
                          )
                      )
                  )
              )
          }
          {record.traLai ?
            <div
              className="table-text-center"
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: "#FF5247",
                cursor: "pointer",
              }}
            >
              <Tooltip title={record.lyDoTraLai}>
                <InfoCircleOutlined /> Bị trả lại
              </Tooltip>
            </div> : null
          }
        </>
      ),
      width: "10%",
    },
    {
      title: "KH xác nhận",
      dataIndex: "khachHangXacNhan",
      key: "khachHangXacNhan",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "Tác vụ",
      dataIndex: "",
      key: "tacVu",
      render: (text, record) => (
        <div className="table-text-center">
          {record.filePathBienBan ?
            <Tooltip title="Xem biên bản">
            <a
              href={record.filePathBienBan}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FilePdfOutlined
                style={{ fontSize: "22px" }}
              />
            </a>
          </Tooltip>
            : null
          }
        </div>
      ),
      width: "4%",
      fixed: "right"
    },
  ];
  const columnAG = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => detailDiemDo(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: "12%",
      fixed: "left"
    },
    {
      title: "Tên Khách hàng",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      key: "maKhachHang",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Số công tơ",
      dataIndex: "soCongTo",
      key: "soCongTo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Thay đổi áp giá",
      dataIndex: "thayDoiApGia",
      key: "thayDoiApGia",
      render: (text, record) => (
        <div>
          {record.isThayDoi === true ? (<span>Có</span>)
            : (<span>Không</span>)}
        </div>
      ),
      width: "10%",
    },
    {
      title: 'Trước khi kiểm tra',
      children: [
        {
          title: 'Cấp điện áp',
          dataIndex: "capDienAp",
          key: "capDienAp",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
        },
        {
          title: 'Mục đích sử dụng điện',
          dataIndex: "mucDichSuDungDien",
          key: "mucDichSuDungDien",
          render: (text) => <span>{text}</span>,
          width: "10%",
        },
        {
          title: "Tỷ lệ giá bán điện",
          dataIndex: "tiLeGiaBanDien",
          key: "tiLeGiaBanDien",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        }
      ],
    },
    {
      title: 'Sau khi kiểm tra',
      children: [
        {
          title: 'Cấp điện áp',
          dataIndex: "capDienAp",
          key: "capDienAp",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
        },
        {
          title: 'Mục đích sử dụng điện',
          dataIndex: "mucDichSuDungDien",
          key: "mucDichSuDungDien",
          render: (text) => <span>{text}</span>,
          width: "10%",
        },
        {
          title: "Tỷ lệ giá bán điện",
          dataIndex: "tiLeGiaBanDienSau",
          key: "tiLeGiaBanDienSau",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
        {
          title: "Ngày áp dụng",
          dataIndex: "ngayApDung",
          key: "ngayApDung",
          render: (text) => (
            <div className="table-text-center">
              <span>{text}</span>
            </div>
          ),
          width: "8%",
        },
      ],
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text, record) => (
        <>
          {
            record.trangThai?.toString() === AP_GIA_TRANG_THAI.DA_LAY_TU_WEB ?
              <Tag style={{ display: "block", textAlign: "center" }} color={"#175bd1"}>{"Chờ phân công"}</Tag> :
              (
                record.trangThai?.toString() === AP_GIA_TRANG_THAI.DA_PHAN_CONG ?
                  <Tag style={{ display: "block", textAlign: "center" }} color={"#c91a34"}>{"Đã lấy về MTB"}</Tag> :
                  (
                    record.trangThai?.toString() === AP_GIA_TRANG_THAI.DANG_THUC_HIEN ?
                      <Tag style={{ display: "block", textAlign: "center" }} color={"#9516f0"}>{"Đang thực hiện"}</Tag> :
                      (
                        record.trangThai?.toString() === AP_GIA_TRANG_THAI.MTB_DA_GUI_DL ?
                          <Tag style={{ display: "block", textAlign: "center" }} color={"#e060a5"}>{"Chờ NV xác nhận"}</Tag> :
                          (
                            record.trangThai?.toString() === AP_GIA_TRANG_THAI.NVAG_DA_XAC_NHAN ?
                              <Tag style={{ display: "block", textAlign: "center" }} color={"#59e71f"}>{"Chờ bộ phận áp giá duyệt"}</Tag> :
                              (
                                record.trangThai?.toString() === AP_GIA_TRANG_THAI.BPAG_DA_XAC_NHAN ?
                                  <Tag style={{ display: "block", textAlign: "center" }} color={"#0aad98"}>{"Chờ PKD duyệt"}</Tag> :
                                  (
                                    record.trangThai?.toString() === AP_GIA_TRANG_THAI.PKD_DA_XAC_NHAN ?
                                      <Tag style={{ display: "block", textAlign: "center" }} color={"#9d41e3"}>{"Chờ lãnh đạo duyệt"}</Tag> :
                                      (
                                        record.trangThai?.toString() === AP_GIA_TRANG_THAI.LD_DA_XAC_NHAN ?
                                          <Tag style={{ display: "block", textAlign: "center" }} color={"#949d09"}>{"Lãnh đạo đã duyệt"}</Tag> :
                                          <Tag style={{ display: "block", textAlign: "center" }} color={"#26c0e9"}>{"Đã đồng bộ"}</Tag>
                                      )
                                  )
                              )
                          )
                      )
                  )
              )
          }
          {record.biTraLai ?
            <div
              className="table-text-center"
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: "#FF5247",
                cursor: "pointer",
              }}
            >
              <Tooltip title={record.lyDoTraLai}>
                <InfoCircleOutlined /> Bị trả lại
              </Tooltip>
            </div> : null
          }
        </>
      ),
      width: "10%",
    },
    {
      title: "KH xác nhận",
      dataIndex: "khachHangXacNhan",
      key: "khachHangXacNhan",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "Tác vụ",
      dataIndex: "",
      key: "tacVu",
      render: (text, record) => (
        <div className="table-text-center">
          {record.filePathBienBan ?
            <Tooltip title="Xem biên bản">
            <a
              href={record.filePathBienBan}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FilePdfOutlined
                style={{ fontSize: "22px" }}
              />
            </a>
          </Tooltip>
            : null
          }
        </div>
      ),
      width: "4%",
      fixed: "right"
    },
  ];
  return (
    <LoadingComponent loading={loading}>
      <Fragment>
        <Container>
          <Col span={24}>
            <Row gutter={24}>
              <Col span={6}>
                <h6 className="fs-12px c-t-label">Loại bảng kê</h6>
                <h5 className="fs-14px font-bold">{detail?.loaiBangKe}</h5>
              </Col>
              <Col span={6}>
                <h6 className="fs-12px c-t-label">Trạng thái</h6>
                <h5 className="fs-14px font-bold">
                  {
                    detail.trangThai?.toString() === AP_GIA_TRANG_THAI_BK.CHO_NVAG_DUYET ? (
                      "Chờ NV áp giá duyệt"
                    ) : (
                      detail.trangThai?.toString() === AP_GIA_TRANG_THAI_BK.CHO_BPAG_DUYET ? (
                        "Chờ bộ phập áp giá duyệt"
                      ) : (
                        (
                          "Bộ phập áp giá đã duyệt"
                        )
                      )
                    )
                  }
                </h5>
              </Col>
              <Col span={6}>
                <h6 className="fs-12px c-t-label">Người tạo</h6>
                <h5 className="fs-14px font-bold">{detail?.tenNhanVien}</h5>
              </Col>
              <Col md={6}>
                <h6 className="fs-12px c-t-label">Ngày tạo</h6>
                <h5 className="fs-14px font-bold">{detail?.thoiGianTao}</h5>
              </Col>
              <Col span={24} md={24}>
                <h6 className="fs-12px c-t-label">Lý do trả lại</h6>
                <h5 className="fs-14px font-bold">{detail?.lyDoTraLai}</h5>
              </Col>
            </Row>
            <br />
            <TableStyled>
              <Table
                dataSource={detailBK}
                columns={detail?.loaiBangKe === "Kiểm tra áp giá" ? columnAG : columnDM}
                pagination={false}
                scroll={{ x: 2800 }}
                bordered={true}
                rowKey="id"
                onRow={detailDD}
              />
            </TableStyled>
          </Col>
        </Container>
        <div className="ant-modal-footer text-center">
          <Button type="default" onClick={() => dispatch(closeModal())}>
            Đóng lại
          </Button>
        </div>
      </Fragment>
    </LoadingComponent>

  );
}