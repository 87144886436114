import { Endpoint } from "@utils/endpoint";
import { authSelector } from "@state/auth";
import { Col, Form, Row, Select, Button } from "antd";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import Selection from "@components/Select";
import FormComponent from "@components/Form";
import { removeAccents } from "@utils/function";
const { Option } = Select;

export function ListFilterCauHinhKyBK(props) {
  const { handleSearch, clearFilter, filterConditions, dataDsSoGCS } = props;
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        DonViId: user.unitId.toString(),
      }}
    >
      <Row gutter={24} justify="space-between" align="middle">
        <Col span={24} md={6}>
          <Form.Item name="DonViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Selection
                url={Endpoint.GET_UNIT_LIST}
                form={form}
                formKey="DonViId"
                notClear={true}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.DonViId !== currentValues.DonViId) {
                form.setFieldsValue({
                  ToDoiId: undefined,
                });
              }
              return prevValues.DonViId !== currentValues.DonViId;
            }}
          >
            {({ getFieldValue }) => {
              if (getFieldValue("DonViId")) {
                return (
                  <Form.Item name="ToDoiId" label="Tổ đội">
                    <Selection
                      url={`${Endpoint.GET_TEAM_LIST}?donviId=${getFieldValue(
                        "DonViId"
                      )}`}
                      formKey="ToDoiId"
                      form={form}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="ToDoiId" label="Tổ đội">
                  <Selection
                    url={`${Endpoint.GET_TEAM_LIST}?donviId=${user.unitId}`}
                    formKey="ToDoiId"
                    form={form}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.ToDoiId !== currentValues.ToDoiId) {
                form.setFieldsValue({
                  UserId: undefined,
                });
              }
              return prevValues.ToDoiId !== currentValues.ToDoiId;
            }}
          >
            {({ getFieldValue }) => {
              if (getFieldValue("ToDoiId")) {
                return (
                  <Form.Item name="UserId" label="Nhân viên"
                    showSearch
                    filterOption={(input, option) => {
                      if (option && option.children) {
                        return (
                          removeAccents(option.children)
                            .toLowerCase()
                            .indexOf(removeAccents(input).toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    <Selection
                      url={`${Endpoint.NHAN_VIEN_THEO_DOI}?doiId=${getFieldValue(
                        "ToDoiId"
                      )}`}
                      formKey="UserId"
                      form={form}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="UserId" label="Nhân viên">
                  <Selection
                  // url={`${Endpoint.NHAN_VIEN_THEO_DOI}?doiId=${getFieldValue(
                  //   "ToDoiId"
                  // )}`}
                  // formKey="UserId"
                  // form={form}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="MaTenSo" label="Mã sổ">
            <Select
            showSearch
            filterOption={(input, option) => {
              if (option && option.children) {
                return (
                  removeAccents(option.children)
                    .toLowerCase()
                    .indexOf(removeAccents(input).toLowerCase()) >= 0
                );
              }
            }}
            allowClear
            >
              {dataDsSoGCS && dataDsSoGCS.length > 0
                ? (
                  dataDsSoGCS.map((item, index) => (
                    <Option key={index} value={item.value}>
                      {item.name}
                    </Option>
                  ))
                )
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col
          span={24}
          style={{ textAlign: "right" }}
        >
          <Button className="mr-24px" onClick={clear}>
            Bỏ lọc
          </Button>
          <Button type="primary" htmlType="submit" form="filter-form">
            Tìm kiếm
          </Button>
        </Col>
      </Row>
    </FormComponent>
  );
}
