import { Fragment, memo } from "react";
import { Table, ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import LoadingComponent from "@components/Loading";
import { ContainerTab } from "../css-styled";
import vn from "antd/es/locale-provider/vi_VN";

function Index({ dataTienTrinh, loading }) {
  const { t } = useTranslation();
  //khai bái columns

  const columns = [
    {
      title: t("duyet_ho_so.stt"),
      dataIndex: "stt",
      key: "stt",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: t("duyet_ho_so.noi_dung_xu_ly"),
      dataIndex: "noiDungXuLy",
      key: "noiDungXuLy",
      render: (text) => {
        const arrText = text.split("@@@");
        return arrText.map((item) => {
          return (
            <div className="table-text-left">
              <span>{item}</span>
            </div>
          );
        });
      },
      width: "30%",
    },
    {
      title: t("duyet_ho_so.bat_dau"),
      dataIndex: "ngayBatDau",
      key: "ngayBatDau",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: t("duyet_ho_so.ket_thuc"),
      dataIndex: "ngayKetThuc",
      key: "ngayKetThuc",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: t("duyet_ho_so.hien_tai"),
      dataIndex: "maCongViecHienTai",
      key: "maCongViecHienTai",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: t("duyet_ho_so.tiep_theo"),
      dataIndex: "maCongViecTiepTheo",
      key: "maCongViecTiepTheo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
  ];

  return (
    <Fragment>
      <LoadingComponent loading={loading}>
        <ConfigProvider locale={vn}>
          <ContainerTab>
            <Table
              dataSource={dataTienTrinh}
              columns={columns}
              pagination={false}
              scroll={{ y: 700, x: 800 }}
            />
          </ContainerTab>
        </ConfigProvider>
      </LoadingComponent>
    </Fragment>
  );
}

export default memo(Index);
