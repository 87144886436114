import { downloadFile } from "@utils/request";
import { Form } from "antd";
import { useCallback, useEffect, useState } from "react";
import FormBoLocXuatKho from "./form-bo-loc";

export default function ListFilterTaoPhieuXuatKho(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    tooltip,
    user,
    typeList,
    loaiBangKe,
    trangThai,
    firstTrangThai,
    checkTraLai,
    onChangeTrangThai,
    urlXuatExcel,
    permissionExcel,
  } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // hàm clear điều kiện search

  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);
  // hàm set điều kiện search default

  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);

  useEffect(() => {
    form.setFieldsValue({
      trangThaiId: firstTrangThai,
    });
  }, [firstTrangThai, form]);

  //call api xuất excel
  const handleExportExcel = useCallback((endpoint) => {
    if (endpoint !== undefined)
      downloadFile({
        endpoint,
        setLoading,
      });
  }, []);

  return (
    <FormBoLocXuatKho
      user={user}
      form={form}
      loading={loading}
      handleSearch={handleSearch}
      tooltip={tooltip}
      clear={clear}
      loaiBangKe={loaiBangKe}
      filterConditions={filterConditions}
      trangThai={trangThai}
      typeList={typeList}
      firstTrangThai={firstTrangThai}
      checkTraLai={checkTraLai}
      onChangeTrangThai={onChangeTrangThai}
      urlXuatExcel={urlXuatExcel}
      permissionExcel={permissionExcel}
      handleExportExcel={handleExportExcel}
    />
  );
}
