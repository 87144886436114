import FormComponent from "@components/Form";
import TableComponent from "@components/TableComponent";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import { handlePagination } from "@utils/function";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { exportData, handleDownload, selectSMSCovid4 } from "./service";

export default function Bieu4_ccDoiSoatSlTinNhanCV4(props) {
  const { screen, handleChange } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });

  //pagination table
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const onFinish = (values) => {
    exportData(values, setLoading, setData);
  };
  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      width: "5vw",
    },
    {
      title: "Mã đơn vị",
      dataIndex: "MA_DVIQLY",
      key: "MA_DVIQLY",
      width: "10vw",
    },
    {
      title: "Số lượng đã nhắn viettel",
      dataIndex: "SL_DANHAN_VIETTEL",
      key: "SL_DANHAN_VIETTEL",
      width: "10vw",
    },
    {
      title: "Đơn giá viettel",
      dataIndex: "DONGIA_VIETTEL",
      key: "DONGIA_VIETTEL",
      width: "10vw",
    },
    {
      title: "Tiền Viettel",
      dataIndex: "TIEN_VIETTEL",
      key: "TIEN_VIETTEL",
      width: "10vw",
    },
    {
      title: "Số lượng đã nhắn Vina",
      dataIndex: "SL_DANHAN_VINA",
      key: "SL_DANHAN_VINA",
      width: "10vw",
    },
    {
      title: "Đơn giá Vina",
      dataIndex: "TIEN_VINA",
      key: "TIEN_VINA",
      width: "10vw",
    },
    {
      title: "Tiền Vina",
      dataIndex: "TIEN_VINA",
      key: "TIEN_VINA",
      width: "10vw",
    },
    {
      title: "Số lượng đã nhắn Mobi",
      dataIndex: "SL_DANHAN_MOBI",
      key: "SL_DANHAN_MOBI",
      width: "10vw",
    },
    {
      title: "Đơn giá Mobi",
      dataIndex: "DONGIA_MOBI",
      key: "DONGIA_MOBI",
      width: "10vw",
    },
    {
      title: "Tiền Mobi",
      dataIndex: "TIEN_MOBI",
      key: "TIEN_MOBI",
      width: "10vw",
    },
    {
      title: "Số lượng đã nhắn VNMB",
      dataIndex: "time",
      key: "time",
      width: "10vw",
    },
    {
      title: "Đơn giá VNMB",
      dataIndex: "time",
      key: "time",
      width: "10vw",
    },
    {
      title: "Tiền VNMB",
      dataIndex: "time",
      key: "time",
      width: "10vw",
    },
    {
      title: "Số lượng đã nhắn GTEL",
      dataIndex: "time",
      key: "time",
      width: "10vw",
    },
    {
      title: "Đơn giá GTEL",
      dataIndex: "time",
      key: "time",
      width: "10vw",
    },
    {
      title: "Tiền GTEL",
      dataIndex: "time",
      key: "time",
      width: "10vw",
    },
    {
      title: "Số lượng đã nhắn ITEL",
      dataIndex: "SL_DANHAN_ITEL",
      key: "SL_DANHAN_ITEL",
      width: "10vw",
    },
    {
      title: "Đơn giá ITEL",
      dataIndex: "DONGIA_ITEL",
      key: "DONGIA_ITEL",
      width: "10vw",
    },
    {
      title: "Tiền ITEL",
      dataIndex: "TIEN_ITEL",
      key: "TIEN_ITEL",
      width: "10vw",
    },
    {
      title: "Tổng đã nhắn",
      dataIndex: "TONG_DANHAN",
      key: "TONG_DANHAN",
      width: "10vw",
    },
    {
      title: "Tổng tiền đã nhắn",
      dataIndex: "TONG",
      key: "TONG",
      width: "10vw",
    },
  ];

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Hiển thị báo cáo
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ reportType: screen }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="reportType"
                    label="Tên báo cáo"
                    labelAlign="left"
                  >
                    <Select
                      placeholder="Tất cả"
                      options={selectSMSCovid4}
                      formKey="reportType"
                      onChange={() => {
                        handleChange(form.getFieldValue("reportType"));
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end">
                <Button type="primary" htmlType="submit" className="mr-5px">
                  Tìm kiếm
                </Button>

                <Button
                  type="primary"
                  onClick={() => {
                    handleDownload(form.getFieldValue(), setLoading);
                  }}
                >
                  Xuất Excel
                </Button>
              </Row>
            </FormComponent>
            <Divider className="section-text-line" orientation="left"></Divider>
            <TableComponent
              header={"Kết quả hiển thị"}
              columns={columns}
              dataSource={data}
              pagination={filterConditions}
              onChangePagination={onChangePagination}
              scrollX={1300}
            />
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
