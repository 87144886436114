import DatePickerComponent from "@components/DatePicker";
import Filter from "../../../common/Filter";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_MONTH_ENGLISH,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData } from "@utils/request";
import { Button, Col, Form, Input, Row, Select } from "antd";
import moment from "moment";
import React, { Fragment, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import SelectMaSoGCS from "../common/SelectGetMaSoGCS";
import shouldUpdateMaso from "../common/should-update-ma-so";
// import { getListMaSoGCS } from "../../in-bang-ke-service";
export default function BKAnhSanLuongBT() {
  const [loading, setLoading] = useState(false);
  // const [listDataMaSoGCS, setListDataMaSoGCS] = useState([]);
  const location = useLocation();
  const [form] = Form.useForm();
  const { user } = useSelector(authSelector);

  const [listMaso, setListMaso] = useState([]);

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    KyGCS: 1,
    ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
    PhanTramBatThuong: 30,
    ...parseParams(location.search),
  });
  const [query, setQuery] = useState({
    pageIndex: 1,
    pageSize: 50,
  });
  const getListAnhSLBT = (value) => {
    authGetData({
      url: `${Endpoint.XUAT_PDF_ANH_CTSLBT}?${buildQueryString(
        parseParams(value)
      )}`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          window.open(res.data, "_blank");
        }
      },
    });
  };

  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        DonViId: values.DonViId,
        KyGCS: values.KyGCS,
        ThangNam: values.ThangNam,
        MaSoGCS: values.MaSoGCS,
      }));
      // getListAnhSLBT();

      const queryString = {
        ...values,
        MaSoGCS:
          values.MaSoGCS && values.MaSoGCS.length
            ? values.MaSoGCS.toString()
            : "",
        DonViId: values.DonViId,
        ThangNam: values.ThangNam === null ? undefined : values.ThangNam,
      };
      getListAnhSLBT(queryString);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [getListAnhSLBT]
  );

  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <Filter>
            <FormComponent
              form={form}
              initialValues={{
                KyGCS: "1",
                PhanTramBatThuong: "30",
                DonViId: user.unitId,
              }}
              name="filter-form"
              onFinish={handleSearch}
              layout="vertical"
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="DonViId" label="Đơn vị">
                    <Selection
                      url={Endpoint.GET_UNIT_LIST}
                      form={form}
                      formKey="DonViId"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.DonViId !== currentValues.DonViId
                    }
                  >
                    {({ getFieldValue }) => {
                      if (getFieldValue("DonViId")) {
                        return (
                          <Form.Item name="ToDoiId" label="Tổ/Đội">
                            <Selection
                              url={`${
                                Endpoint.AUTH_LIST_TO_DOI
                              }?DonViId=${getFieldValue("DonViId")}`}
                              formKey="ToDoiId"
                              form={form}
                              disabled={!getFieldValue("DonViId")}
                            />
                          </Form.Item>
                        );
                      }
                      return (
                        <Form.Item name="ToDoiId" label="Tổ/Đội">
                          <Select disabled />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={24} md={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => {
                      if (prevValues.DonViId !== currentValues.DonViId) {
                        form.setFieldsValue({
                          TramId: undefined,
                        });
                      }
                      return prevValues.DonViId !== currentValues.DonViId;
                    }}
                  >
                    {({ getFieldValue }) => {
                      if (getFieldValue("DonViId")) {
                        return (
                          <Form.Item name="TramId" label="Trạm">
                            <Selection
                              url={`${
                                Endpoint.LIST_TRAM_CT
                              }?donviId=${getFieldValue("DonViId")}`}
                              formKey="TramId"
                              form={form}
                            />
                          </Form.Item>
                        );
                      }
                      return (
                        <Form.Item name="TramId" label="Trạm">
                          <Selection
                            url={`${Endpoint.LIST_TRAM_CT}?donviId=${user.unitId}`}
                            formKey="TramId"
                            form={form}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={24} md={2}>
                  <Form.Item name="KyGCS" label="Kỳ GCS" labelAlign="right">
                    <Select>
                      <Select.Option value="1">1</Select.Option>
                      <Select.Option value="2">2</Select.Option>
                      <Select.Option value="3">3</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} md={4}>
                  <Form.Item
                    name="ThangNam"
                    label="Tháng /Năm"
                    labelAlign="right"
                  >
                    <DatePickerComponent
                      defaultValue={
                        filterConditions.ThangNam
                          ? moment(filterConditions.ThangNam)
                          : moment(moment(), FORMAT_MONTH_ENGLISH)
                      }
                      picker="month"
                      formKey="ThangNam"
                      format={"YYYY-MM"}
                      form={form}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="PhanTramBatThuong"
                    label="Phần trăm bất thường"
                    labelAlign="right"
                  >
                    <Input
                      addonBefore="+/-"
                      type="number"
                      addonAfter="%"
                      defaultValue={30}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="MaSoGCS"
                    label="Mã sổ"
                    shouldUpdate={(prevValues, currentValues) => {
                      shouldUpdateMaso({prevValues, currentValues, setQueryMaso: setQuery, form, setListMaso, fieldUpdate:"MaSoGCS"})
                    }}
                  >
                    <SelectMaSoGCS
                      query={query}
                      setQuery={setQuery}
                      url={Endpoint.LIST_MA_SO_BANG_KE_NO_TO_DOI}
                      formKey="MaSoGCS"
                      form={form}
                      loading
                      mode={"multiple"}
                      listMaso={listMaso}
                      setListMaso={setListMaso}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="left" gutter={2}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  In bảng kê
                </Button>
              </Row>
            </FormComponent>
          </Filter>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
  .ant-collapse.ant-collapse-icon-position-left {
    margin-top: 20px;
  }
`;
const ContentWrapper = styled.div``;
