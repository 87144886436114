import { memo, useRef, useState, useEffect } from "react";
import { Form, Input, Button, Modal, Row, Col } from "antd";
import FormComponent from "@components/Form";
import { useTranslation } from "react-i18next";
import LoadingComponent from "@components/Loading";
import { handleSubmitForm } from "./services";

function HinhThucThiCongModal({
  detailThiCong,
  visible,
  closeModal,
  getListThiCong,
}) {
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    handleSubmitForm(
      values,
      detailThiCong,
      setLoading,
      closeModal,
      getListThiCong,
      form
    );
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();

    // eslint-disable-next-line
  }, []);

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={
          detailThiCong.id
            ? "Cập nhật hình thức thi công"
            : "Tạo mới hình thức thi công"
        }
        visible={visible}
        onCancel={closeModal}
        className="modal"
        footer={null}
      >
        <FormComponent
          autoComplete="off"
          form={form}
          name="form-submit"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            ...detailThiCong,
          }}
          scrollToFirstError
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Mã hình thức thi công"
                name="maHinhThucTC"
                className="required-field"
              >
                <Input
                  ref={inputRef}
                  placeholder="Nhập mã hình thức thi công"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Tên hình thức thi công"
                name="tenHinhThucTC"
                className="required-field"
              >
                <Input placeholder="Nhập tên hình thức thi công" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-submit"
                loading={loading}
              >
                {detailThiCong.id ? t("button.cap_nhat") : t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(HinhThucThiCongModal);
