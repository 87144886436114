import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import ConfirmModal from "@modules/giao-nhan-dien-nang/tong-hop-so-lieu/tinh-san-luong/modal-confirm/modal-xacnhan/modal-confirm";
import { closeModal } from "@state/system-config/reducer";
import { LOAI_TIMKIEM_GNDN } from "@utils/constants";
import { numberWithNoSpaces, numberWithSpaces } from "@utils/function";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import NumericInput from "../NumericInput";
import {
  getDataBienBan,
  PostBienBan,
  postCongTo,
  postLayBienBanTuCMIS,
  selectTao,
  selectTaoKhiCoCT,
} from "../services";

export default function ModalBienBanTreoThao(props) {
  const { details, loading, setLoading, getListDD } = props;
  const [data, setData] = useState([]);
  const [dataCMIS, setDataCMIS] = useState([]);
  const [dataTreo, setDataTreo] = useState([]);
  const [valueThucHien, setValueThucHien] = useState();
  const dispatch = useDispatch();
  const [layBBTuCMIS, setLayBBTuCMIS] = useState(false);
  const [loadingComponent, setLoadingComponent] = useState(false);
  const [loadingBBCMIS, setLoadingBBCMIS] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [xacNhanDongBo, setXacNhanDongBo] = useState(false);
  const [loaiCongTo, setLoaiCongTo] = useState();
  const [form] = Form.useForm();
  const [dataTableTreo, setDataTableThao] = useState([]);
  const [arrayBoChiSoThao, setArrayBoChiSoThao] = useState([]);
  const [arrayChiSoTreo, setArrayChiSoTreo] = useState([]);


  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const handleTinhTong = (e, index) => {
    const chiSoChot = numberWithNoSpaces(
      form.getFieldValue(`chiSoChot-${index}`) || 0
    );
    const sanLuong = Number(
      numberWithNoSpaces(
        form.getFieldValue(`sanLuong-${index}`) === "-"
          ? 0
          : form.getFieldValue(`sanLuong-${index}`)
      ) || 0
    );
    const heSoNhan = data?.heSoNhan;

    let tong;
    if (layBBTuCMIS && xacNhanDongBo) {
      tong = Math.round(
        (chiSoChot - Number(arrayBoChiSoThao[index]?.chiSoRequest?.chiSoCu)) *
          heSoNhan +
          sanLuong
      );
    } else {
      
      tong = Math.round(
        (chiSoChot - Number(data?.gndnBoChiSos[index]?.chiSoRequest?.chiSoCu)) *
        heSoNhan +
        sanLuong
        );
    }

    if (!chiSoChot && !sanLuong) {
      form.setFieldsValue({
        [`tong-${index}`]: "",
      });
    } else {
      form.setFieldsValue({
        [`tong-${index}`]: numberWithSpaces(tong),
      });
    }
  };

  const onFinish = useCallback(
    (values) => {
      let validate = false;

      if (valueThucHien !== 1 && !xacNhanDongBo) {
        for (let i = 0; i < data?.boChiSosTreo?.length; i++) {
          if (
            numberWithNoSpaces(values[`chiSoChot-${i}`] ?? 0) <
              data?.boChiSosTreo[i].chiSoRequest.chiSoCu ||
            !values[`chiSoChot-${i}`]
          ) {
            form.setFields([
              {
                name: `chiSoChot-${i}`,
                errors: ["Nhập sai chỉ số"],
              },
            ]);
            validate = true;
          }
        }
      }
      if (valueThucHien !== 1 && xacNhanDongBo) {
        for (let i = 0; i < data?.gndnBoChiSos?.length; i++) {
          if (
            numberWithNoSpaces(values[`chiSoChot-${i}`] ?? 0) <
              data?.gndnBoChiSos[i].chiSoRequest.chiSoCu &&
            data?.gndnBoChiSos[i].chiSoRequest.chiSoCu !== 0
          ) {
            form.setFields([
              {
                name: `chiSoChot-${i}`,
                errors: ["Nhập sai chỉ số"],
              },
            ]);
            validate = true;
          }
        }
      }
      if (values?.thucHien === undefined) {
        form.setFields([
          {
            name: `thucHien`,
            errors: ["Thực hiện không được bỏ trống"],
          },
        ]);
      } else if (values?.heSoNhanTreo === undefined && valueThucHien === 1) {
        form.setFields([
          {
            name: `heSoNhanTreo`,
            errors: ["Hệ số nhân không được bỏ trống"],
          },
        ]);
      } else if (values?.heSoNhanTreo === undefined && valueThucHien === 3) {
        form.setFields([
          {
            name: `heSoNhanTreo`,
            errors: ["Hệ số nhân không được bỏ trống"],
          },
        ]);
      } else {
        if (valueThucHien === 1 && !validate) {
          const boChiSoRequests = arrayChiSoTreo?.map((e, index) => ({
            ...e,
            chiSoRequest: {
              chiSoTreo: numberWithNoSpaces(
                form.getFieldValue(`ChiSoTreo-${index}`)
              ),
              chiSoCu: null,
              chiSoChot: null,
              sanLuong: null,
            },
          }));

          PostBienBan(
            { ...values, boChiSoRequests },
            loaiCongTo,
            data,
            setLoadingComponent,
            handleCloseModal,
            details,
            valueThucHien,
            getListDD,
            form
          );
        }
        if (valueThucHien === 2 && !validate) {
          const boChiSoRequestsThao = data?.gndnBoChiSos?.map((e, index) => ({
            ...e,
            chiSoRequest: {
              chiSoChot: numberWithNoSpaces(
                form.getFieldValue(`chiSoChot-${index}`)
              ),
              chiSoCu: data?.gndnBoChiSos[index].chiSoRequest.chiSoCu,
              sanLuong:
                numberWithNoSpaces(form.getFieldValue(`sanLuong-${index}`)) ??
                0,
              chiSoTreo: null,
            },
          }));
          PostBienBan(
            {
              ...values,
              boChiSoRequestsThao,
              boChiSoRequests: [],
            },
            loaiCongTo,
            data,
            setLoadingComponent,
            handleCloseModal,
            details,
            valueThucHien,
            getListDD,
            form
          );
        }

        if (valueThucHien === 3 && !validate) {
          const boChiSoRequests = arrayChiSoTreo?.map((e, index) => ({
            ...e,
            chiSoRequest: {
              chiSoTreo: numberWithNoSpaces(
                form.getFieldValue(`ChiSoTreo-${index}`)
              ),
              chiSoCu: null,
              chiSoChot: null,
              sanLuong: null,
            },
          }));

          const boChiSoRequestsThao = data?.gndnBoChiSos?.map((e, index) => ({
            ...e,
            chiSoRequest: {
              chiSoChot: numberWithNoSpaces(
                form.getFieldValue(`chiSoChot-${index}`)
              ),
              sanLuong:
                numberWithNoSpaces(form.getFieldValue(`sanLuong-${index}`)) ??
                0,
              chiSoCu: data?.gndnBoChiSos[index].chiSoRequest.chiSoCu,
              chiSoTreo: null,
            },
          }));
          PostBienBan(
            { ...values, boChiSoRequests, boChiSoRequestsThao },
            loaiCongTo,
            data,
            setLoadingComponent,
            handleCloseModal,
            details,
            valueThucHien,
            getListDD,
            form
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      valueThucHien,
      dataTableTreo,
      data,
      loaiCongTo,
      arrayChiSoTreo,
      setLoadingComponent,
      handleCloseModal,
      details,
    ]
  );

  useEffect(() => {
    if (!layBBTuCMIS && !xacNhanDongBo) {
      for (let i = 0; i < arrayChiSoTreo.length; i++) {
        form.setFieldsValue({
          [`ChiSoTreo-${i}`]: "",
        });
      }
    }
  }, [form.getFieldValue("maCongToTreo"), layBBTuCMIS, xacNhanDongBo]);

  useEffect(() => {
    if (details?.thucHien) {
      getDataBienBan(
        props.details.maDiemDo,
        props.details.maCongTo,
        props.details.maBienBan,
        setData,
        setLoading
      );
    }
  }, [
    details?.maCongTo,
    details.thucHien,
    props.details.maBienBan,
    props.details.maCongTo,
    props.details.maDiemDo,
    setData,
    setLoading,
  ]);

  useEffect(() => {
    setDataTableThao(dataTreo?.bcs?.split(";"));
    setLoaiCongTo(dataTreo?.loaI_CTO);
  }, [dataTreo?.bcs, dataTreo?.loaI_CTO]);

  const objThao = {
    bcs: "string",
    chiSoRequest: {
      chiSoCu: null,
      chiSoChot: null,
      sanLuong: null,
      tong: null,
    },
  };
  const arrThao = [];

  useEffect(() => {
    const obj = {
      bcs: "string",
      chiSoRequest: {
        chiSoTreo: null,
      },
    };
    const arr = [];

    dataTableTreo?.forEach((i) => {
      arr.push({
        ...obj,
        bcs: i,
      });
    });

    setArrayChiSoTreo(arr);

    if (!layBBTuCMIS && !xacNhanDongBo) {
      data?.gndnBoChiSos?.forEach((item) => {
        arrThao.push({
          ...objThao,
          bcs: item.bcs,
          chiSoRequest: {
            chiSoCu: item.chiSoRequest.chiSoCu,
            chiSoChot: item.chiSoRequest.chiSoChot,
            sanLuong: item.chiSoRequest.sanLuong,
            tong: item.chiSoRequest.tong,
          },
        });
      });
      setArrayBoChiSoThao(arrThao);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTableTreo, data?.gndnBoChiSos]);

  const onEnter = (e) => {
    if (e.key === "Enter") {
      if (form.getFieldValue("maCongToTreo") === details.maCongToThao) {
        notification.error(
          {
            message: "Thông báo!",
            description: "Mã công tơ đã được gán cho điểm đo.",
          },
          500
        );
      } else {
        setLayBBTuCMIS(false);

        postCongTo(
          form.getFieldValue("maCongToTreo"),
          setDataTreo,
          setLoadingComponent,
          data,
          form
        );
      }
    }
  };

  useEffect(() => {
    if (details?.thucHien === 1 || details?.thucHien === 3) {
      setValueThucHien(3);
    } else {
      setValueThucHien(1);
    }
  }, [details?.thucHien]);

  const onChangeThucHien = (e) => {
    setValueThucHien(e);
  };

  const callApiBienBanTuCMIS = useCallback(() => {
    // form.getFieldValue("loaI_TIMKIEM")
    if (data === null) {
      notification.error(
        {
          message: "Thông báo!",
          description: "Không có mã công tơ!",
        },
        500
      );
    } else {
      const loaI_TIMKIEM = form.getFieldValue("loaI_TIMKIEM")
        ? LOAI_TIMKIEM_GNDN.TREO_THAO_DIEM_DO_DAU_NGUON
        : LOAI_TIMKIEM_GNDN.KHONG_PHAI_TREO_THAO_DIEM_DO_DAU_NGUON;
      postLayBienBanTuCMIS({
        maDiemDoNoiBo: props.details.maDiemDoNoiBo,
        setDataTreo: setDataCMIS,
        setLoading: setLoadingBBCMIS,
        congToTreo: form.getFieldValue("maCongToTreo")
          ? form.getFieldValue("maCongToTreo")
          : null,
        congToThao: form.getFieldValue("maCongTo")
          ? form.getFieldValue("maCongTo")
          : null,
        setLayBBTuCMIS: setLayBBTuCMIS,
        loaI_TIMKIEM: loaI_TIMKIEM,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLayBBTuCMIS]);

  useEffect(() => {
    if (xacNhanDongBo === true) {
      const newObj = {};
      for (const [key, value] of Object.entries(dataCMIS)) {
        if (
          key !== "capChinhXac" &&
          key !== "heSoNhan" &&
          key !== "loaiCongTo" &&
          key !== "maCongTo" &&
          key !== "soCuaSo" &&
          key !== "gndnBoChiSos"
        ) {
          newObj[key] = value;
        }

        if (key === "gndnBoChiSos") {
          if (value.length > 0) {
            value.forEach((el, idx) => {
              arrayBoChiSoThao.forEach((item, index) => {
                if (idx === index) {
                  arrThao.push({
                    ...objThao,
                    bcs: el.bcs,
                    chiSoRequest: {
                      chiSoCu: item.chiSoRequest.chiSoCu,
                      chiSoChot: el.chiSoRequest.chiSoChot,
                      sanLuong: item.chiSoRequest.sanLuong,
                      tong: Math.round(
                        numberWithNoSpaces(
                          el.chiSoRequest.chiSoChot - item.chiSoRequest.chiSoCu
                        ) *
                          data.heSoNhan +
                          numberWithNoSpaces(
                            item.chiSoRequest.sanLuong
                              ? item.chiSoRequest.sanLuong
                              : 0
                          )
                      ),
                    },
                  });
                }
              });
            });

            newObj[key] = arrThao;

            setArrayBoChiSoThao(arrThao);
          } else {
            newObj[key] = arrayBoChiSoThao;
          }
        }
      }
      setData((prev) => ({ ...prev, ...newObj }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCMIS, xacNhanDongBo]);

  useEffect(() => {
    if (layBBTuCMIS === true && xacNhanDongBo && dataCMIS) {
      form.setFieldsValue({
        ngayTreoThao: data?.ngayTreoThao,
        thongTinDiemDo: data?.thongTinDiemDo,
        soBienBan: data?.soBienBan,
        nguoiThucHien: data?.nhanVienTreoThao,
        maCongTo: data?.maCongTo,
        maCongToTreo: data?.maCongToTreo,
        heSoNhan: data?.heSoNhan,
        heSoNhanTreo: data?.heSoNhanTreo,
        capChinhXacThao: data?.capChinhXac,
        capChinhXacTreo: data?.capChinhXacTreo,
        loaiCTThao: data?.loaiCongTo,
        loaiCT: data?.loaiCongToTreo,
        soCuaSoThao: data?.soCuaSo,
        soCuaSoTreo: data?.soCuaSoTreo,
        boChiSoRequests: data?.boChiSosTreo,
      });

      const arr = [];
      for (let i = 0; i < data?.boChiSosTreo?.length; i++) {
        form.setFieldsValue({
          [`ChiSoTreo-${i}`]:
            numberWithSpaces(data?.boChiSosTreo[i]?.chiSoRequest?.chiSoCu) || 0,
        });

        arr.push({
          bcs: data?.boChiSosTreo[i]?.bcs,
        });
      }
      setArrayChiSoTreo(arr);

      for (let i = 0; i < arrayBoChiSoThao.length; i++) {
        form.setFieldsValue({
          [`chiSoCu-${i}`]: numberWithSpaces(
            arrayBoChiSoThao[i].chiSoRequest?.chiSoCu
          ),
          [`chiSoChot-${i}`]: numberWithSpaces(
            arrayBoChiSoThao[i].chiSoRequest?.chiSoChot
          ),
          [`sanLuong-${i}`]: numberWithSpaces(
            arrayBoChiSoThao[i].chiSoRequest?.sanLuong
          ),
          [`tong-${i}`]: numberWithSpaces(
            arrayBoChiSoThao[i].chiSoRequest?.tong
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //key down
  useEffect(() => {
    if (dataCMIS?.maCongTo || dataCMIS?.maCongToTreo) {
      if (
        !form.getFieldValue("maCongToTreo") &&
        !form.getFieldValue("maCongTo")
        ) {
        setXacNhanDongBo(true);
      } else if (
        (form.getFieldValue("maCongTo") &&
          dataCMIS?.maCongTo !== form.getFieldValue("maCongTo")) ||
        dataCMIS?.maCongToTreo !== form.getFieldValue("maCongToTreo")
      ) {
        setVisibleModal(true);
      }
    }
  }, [dataCMIS, form]);

  const onPressEnter = (e, name) => {
    e.preventDefault();
    document.getElementsByName(name)[0]?.focus();
  };
  const handleCloseModalConfirm = () => {
    setVisibleModal(false);
  };
  const handleConfirm = () => {
    setXacNhanDongBo(true);
    setVisibleModal(false);
  };
  const showModalConfirm = useMemo(
    () =>
      visibleModal && (
        <ConfirmModal
          visible={visibleModal}
          closeModal={handleCloseModalConfirm}
          content={`Mã công tơ hiện tại chưa trùng khớp với mã công tơ đồng bộ từ CMIS.
         Bạn chắc chắn muốn đồng bộ ?`}
          title={"Thông báo"}
          setLoading={setLoadingComponent}
          handleSubmit={handleConfirm}
          loading={loadingComponent}
        />
      ),
    [loadingComponent, setLoadingComponent, visibleModal]
  );

  const columnsThaoTH2_3 = [
    {
      title: "BCS",
      dataIndex: "bcs",
      align: "center",
      width: "20%",
      render: (text, record) => {
        return (
          <span style={{ margin: 0, textAlign: "center" }}>{record?.bcs}</span>
        );
      },
    },
    {
      title: "Chỉ số cũ",
      dataIndex: "chiSoCu",
      width: "20%",
      render: (text, record, index) => {
        return (
          <Form.Item name={`chiSoCu-${index}`}>
            <Input
              style={{ textAlign: "right" }}
              name={`chiSoCu-${index}`}
              disabled
              defaultValue={numberWithSpaces(record?.chiSoRequest?.chiSoCu)}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Chỉ số chốt",
      dataIndex: `chiSoChot`,
      width: "20%",
      render: (text, record, index) => {
        return (
          <Form.Item name={`chiSoChot-${index}`} required>
            <NumericInput
              name={`chiSoChot-${index}`}
              onPressEnter={(e) => onPressEnter(e, `chiSoChot-${index + 1}`)}
              onChange={(e) => handleTinhTong(e, index)}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "SL (+/-)",
      dataIndex: "sanLuong",
      width: "20%",
      render: (text, record, index) => {
        return (
          <Form.Item name={`sanLuong-${index}`}>
            <NumericInput
              name={`sanLuong-${index}`}
              onPressEnter={(e) => onPressEnter(e, `sanLuong-${index + 1}`)}
              onChange={(e) => handleTinhTong(e, index)}
              isNegativeValue
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Tổng",
      dataIndex: "tong",
      width: "20%",
      render: (text, record, index) => {
        return (
          <Form.Item name={`tong-${index}`}>
            <Input
              disabled
              style={{ textAlign: "right", color: "#000", fontSize: "14px" }}
            />
          </Form.Item>
        );
      },
    },
  ];
  const columnsThao = [
    {
      title: "BCS",
      dataIndex: "bcs",
      align: "center",
      width: "20%",
      render: (text, record) => {
        return (
          <span style={{ margin: 0, textAlign: "center" }}>{record?.bcs}</span>
        );
      },
    },
    {
      title: "Chỉ số cũ",
      dataIndex: "chiSoCu",
      width: "20%",
      render: (text, record, index) => {
        return (
          <Form.Item name={`chiSoCu-${index}`}>
            <Input
              style={{ textAlign: "right" }}
              name={`chiSoCu-${index}`}
              disabled
              defaultValue={numberWithSpaces(record?.chiSoRequest?.chiSoCu)}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Chỉ số chốt ",
      dataIndex: `chiSoChot`,
      width: "20%",
      render: (text, record, index) => {
        return (
          <Form.Item name={`chiSoChot-${index}`}>
            <NumericInput
              name={`chiSoChot-${index}`}
              onPressEnter={(e) => onPressEnter(e, `chiSoChot-${index + 1}`)}
              onChange={(e) => handleTinhTong(e, index)}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "SL (+/-)",
      dataIndex: "sanLuong",
      width: "20%",
      render: (text, record, index) => {
        return (
          <Form.Item name={`sanLuong-${index}`}>
            <NumericInput
              defaultValue={numberWithSpaces(text)}
              name={`sanLuong-${index}`}
              onPressEnter={(e) => onPressEnter(e, `sanLuong-${index + 1}`)}
              onChange={(e) => handleTinhTong(e, index)}
              isNegativeValue
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Tổng",
      dataIndex: "tong",
      width: "20%",
      render: (text, record, index) => {
        return (
          <Form.Item name={`tong-${index}`}>
            <Input
              disabled
              style={{ textAlign: "right", color: "#000", fontSize: "14px" }}
            />
          </Form.Item>
        );
      },
    },
  ];
  const columnsTreo = [
    {
      align: "center",
      title: "BCS",
      render: (text, record) => {
        return (
          <>
            <Form.Item>
              <span
                style={{ margin: 0, textAlign: "center", fontWeight: "bold" }}
              >
                {record}
              </span>
            </Form.Item>
          </>
        );
      },
    },
    {
      title: "Chỉ số treo",
      render: (text, record, index) => {
        return (
          <Form.Item name={`ChiSoTreo-${index}`}>
            <NumericInput
              name={`ChiSoTreo-${index}`}
              onPressEnter={(e) => onPressEnter(e, `ChiSoTreo-${index + 1}`)}
            />
          </Form.Item>
        );
      },
    },
  ];
  const columnsTreoTH3 = [
    // {
    //   title: "BCS",
    //   render: (text, record, index) => {
    //     return (
    //       <>
    //         <Form.Item name={`BCS-${index}`}>
    //           <Input
    //             onWheel={(e) => e.target.blur()}
    //             defaultValue={record?.bcs}
    //             className="flexCenter"
    //           />
    //         </Form.Item>
    //       </>
    //     );
    //   },
    // },
    {
      title: "BCS",
      dataIndex: "bcs",
      align: "center",
      render: (text, record) => {
        return (
          <span style={{ margin: 0, textAlign: "center", fontWeight: "bold" }}>
            {record?.bcs}
          </span>
        );
      },
    },
    {
      title: "Chỉ số treo",
      render: (text, record, index) => {
        return (
          <Form.Item name={`ChiSoTreo-${index}`}>
            <NumericInput
              name={`ChiSoTreo-${index}`}
              onPressEnter={(e) => onPressEnter(e, `ChiSoTreo-${index + 1}`)}
            />
          </Form.Item>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Container>
        <Spin spinning={loadingComponent}>
          <FormComponent
            layout="vertical"
            onFinish={onFinish}
            form={form}
            loading={loading}
            initialValues={{
              maDiemDo: details?.maDiemDo,
              ngayTreoThao: details?.ngayTreoThao,
              thongTinDiemDo: details?.thongTinDiemDo,
              maDiemDoNoiBo: details?.maDiemDoNoiBo,
              soBienBan: data?.soBienBan,
              nguoiThucHien: data?.nhanVienTreoThao,
              maCongTo:
                layBBTuCMIS === true ? data?.maCongTo : details?.maCongTo,
              heSoNhanThao: dataTreo?.heSoNhan || details?.heSoNhan,
              loaI_TIMKIEM: true,
            }}
          >
            <Divider orientation="left">1.Thông tin chung</Divider>
            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              gutter={24}
            >
              <Col span={5}>
                <Form.Item name="maDiemDo" label="Mã điểm đo">
                  <Select
                    onWheel={(e) => e.target.blur()}
                    disabled
                    type="text"
                    size="default"
                    formKey="maDiemDo"
                    form={form}
                    placeholder="Nhập mã điểm đo"
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="thongTinDiemDo"
                  label="Thông tin điểm đo"
                  required
                >
                  <Selection
                    disabled
                    formKey="thongTinDiemDo"
                    placeholder="Chọn Thông tin điểm đo"
                    form={form}
                  />
                </Form.Item>
              </Col>
              {details?.maDiemDoNoiBo?.length > 12 ? (
                <Col span={6}>
                  <Form.Item name="maDiemDoNoiBo" label="Mã điểm đo nội bộ">
                    <Input
                      onWheel={(e) => e.target.blur()}
                      disabled
                      type="text"
                      size="default"
                      formKey="maDiemDoNoiBo"
                      form={form}
                    />
                  </Form.Item>
                </Col>
              ) : (
                <Col span={6}></Col>
              )}
              {details?.maDiemDoNoiBo?.length > 12 ? (
                <Col span={6}>
                  <Form.Item name="btnlaytt">
                    <Button
                      loading={loadingBBCMIS}
                      style={{
                        marginTop: 30,
                        width: "100%",
                        background: "#44ffee",
                      }}
                      onClick={callApiBienBanTuCMIS}
                    >
                      Lấy BBTT gần nhất từ CMIS
                    </Button>
                  </Form.Item>
                </Col>
              ) : (
                <Col span={6}></Col>
              )}
            </Row>
            <Row
              gutter={24}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Col span={5}>
                <Form.Item name="ngayTreoThao" label="Ngày treo tháo ">
                  <DatePickerComponent
                    formKey="ngayTreoThao"
                    format={"YYYY-MM-DD"}
                    form={form}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="soBienBan" label="Số biên bản" required>
                  <Input
                    onWheel={(e) => e.target.blur()}
                    formKey="soBienBan"
                    type="text"
                    size="default"
                    form={form}
                    placeholder="Nhập số biên bản"
                  />
                </Form.Item>
              </Col>
              {details.thucHien === 1 || details.thucHien === 3 ? (
                <Col span={6}>
                  <Form.Item name="thucHien" label="Thực hiện" required>
                    <Select
                      options={selectTaoKhiCoCT}
                      formKey="thucHien"
                      form={form}
                      onChange={onChangeThucHien}
                      placeholder="Chọn thực hiện"
                    />
                  </Form.Item>
                </Col>
              ) : (
                <Col span={6}>
                  <Form.Item name="thucHien" label="Thực hiện" required>
                    <Select
                      options={selectTao}
                      formKey="thucHien"
                      form={form}
                      placeholder="Chọn thực hiện"
                      onChange={onChangeThucHien}
                    />
                  </Form.Item>
                </Col>
              )}

              <Col span={6}>
                <Form.Item
                  name="nguoiThucHien"
                  label="Người thực hiện"
                  required
                >
                  <Input
                    onWheel={(e) => e.target.blur()}
                    formKey="nguoiThucHien"
                    placeholder="Nhập người thực hiện "
                    form={form}
                  />
                </Form.Item>
              </Col>

              {details?.maDiemDoNoiBo?.length > 12 && (
                <>
                  <Col span={6}>
                    <Form.Item name="loaI_TIMKIEM" valuePropName="checked">
                      <Checkbox>Treo tháo điểm đo đầu nguồn</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={17} />
                </>
              )}
            </Row>
            <Content>
              {valueThucHien === 2 || valueThucHien === 3 ? (
                <>
                  <Divider orientation="left">2.Thông tin công tơ tháo</Divider>
                  <FlexForm>
                    <Row
                      style={{
                        width: "100%",
                        display: "flex",
                        paddingLeft: "2%",
                        marginLeft: 0,
                        height: 150,
                      }}
                      gutter={24}
                    >
                      <Col span={8}>
                        <Form.Item name="maCongTo" label="Mã công tơ">
                          <p style={{ fontWeight: 600 }}>
                            {data?.maCongTo || data?.maCongToTreo}
                          </p>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name="heSoNhanThao" label="Hệ số nhân">
                          <p style={{ fontWeight: 600 }}>
                            {data?.heSoNhan || data?.heSoNhanTreo}
                          </p>
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item
                          name="capChinhXacThao"
                          label="Cấp chính xác"
                          style={{ height: 100 }}
                        >
                          <p style={{ fontWeight: 600 }}>
                            {data?.capChinhXac || data?.capChinhXacTreo}
                          </p>
                        </Form.Item>
                      </Col>
                      <Col span={8} style={{ paddingLeft: 0 }}>
                        <Form.Item
                          style={{ paddingLeft: "5%" }}
                          name="loaiCTThao"
                          label="Loại Công tơ "
                        >
                          <span style={{ fontWeight: 600 }}>
                            {data?.loaiCongTo || data?.loaiCongToTreo}
                          </span>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name="soCuaSoThao" label="Số cửa sổ">
                          <p style={{ fontWeight: 600 }}>
                            {data?.soCuaSo || data?.soCuaSoTreo}
                          </p>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Col
                      span={12}
                      className="flex-table"
                      style={{ marginRight: "2%", fontWeight: 600 }}
                    >
                      {data?.boChiSosTreo?.length > 0 &&
                      layBBTuCMIS === false ? (
                        <Table
                          className="ThongTinCongToThao"
                          columns={columnsThaoTH2_3}
                          pagination={false}
                          bordered
                          form={form}
                          dataSource={data?.gndnBoChiSos || data?.boChiSosTreo}
                        ></Table>
                      ) : data?.boChiSosTreo?.length > 0 &&
                        layBBTuCMIS === true &&
                        xacNhanDongBo ? (
                        <Table
                          className="ThongTinCongToThao"
                          columns={columnsThaoTH2_3}
                          pagination={false}
                          bordered
                          form={form}
                          dataSource={data?.gndnBoChiSos}
                        ></Table>
                      ) : (
                        <Table
                          className="ThongTinCongToThao"
                          columns={columnsThao}
                          pagination={false}
                          bordered
                          form={form}
                          dataSource={data?.gndnBoChiSos}
                        ></Table>
                      )}
                    </Col>
                    {/* 2 */}
                  </FlexForm>
                </>
              ) : null}
              {/* 3 */}
              {valueThucHien === 1 ||
              valueThucHien === 3 ||
              valueThucHien === null ? (
                <>
                  <Divider orientation="left">3.Thông tin công tơ treo</Divider>

                  <FlexForm>
                    <Row
                      style={{
                        width: "100%",
                        display: "flex",
                        paddingLeft: "2%",
                        marginLeft: 0,
                        height: 200,
                      }}
                      gutter={24}
                    >
                      <Col span={7}>
                        <Form.Item name="maCongToTreo" label="Mã công tơ">
                          <Input
                            style={{ fontWeight: 600 }}
                            loading={loading}
                            onWheel={(e) => e.target.blur()}
                            formKey="maCongToTreo"
                            type="text"
                            size="default"
                            onPressEnter={onEnter}
                            form={form}
                            placeholder="Nhập mã công tơ"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name="heSoNhanTreo" label="Hệ số nhân">
                          <Input
                            style={{ fontWeight: 600 }}
                            onWheel={(e) => e.target.blur()}
                            formKey="heSoNhanTreo"
                            type="text"
                            size="default"
                            placeholder="Nhập hệ số nhân"
                            form={form}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item name="capChinhXacTreo" label="Cấp chính xác">
                          <p style={{ fontWeight: 600 }}>
                            {dataTreo?.capChinhXac || data?.capChinhXacTreo}
                          </p>
                        </Form.Item>
                      </Col>
                      <Col span={7} style={{ paddingLeft: 0 }}>
                        <Form.Item
                          style={{ paddingLeft: "5%" }}
                          name="loaiCT"
                          label="Loại Công tơ "
                        >
                          <span style={{ fontWeight: 600 }}>
                            {data?.loaiCongToTreo || dataTreo?.loaI_CTO}
                          </span>
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item name="soCuaSoTreo" label="Số cửa sổ">
                          <p style={{ fontWeight: 600 }}>
                            {dataTreo?.soCuaSo || data?.soCuaSoTreo}
                          </p>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Col
                      span={12}
                      className="flex-table"
                      style={{ marginRight: "2%" }}
                    >
                      {dataTableTreo &&
                      dataTableTreo?.length > 0 &&
                      layBBTuCMIS === false ? (
                        <Table
                          columns={columnsTreo}
                          pagination={false}
                          bordered
                          form={form}
                          dataSource={dataTableTreo}
                        ></Table>
                      ) : layBBTuCMIS === true && xacNhanDongBo ? (
                        <Table
                          columns={columnsTreoTH3}
                          pagination={false}
                          bordered
                          form={form}
                          dataSource={data?.boChiSosTreo}
                        ></Table>
                      ) : (
                        <Table
                          columns={columnsTreo}
                          pagination={false}
                          bordered
                          form={form}
                          dataSource={null}
                        ></Table>
                      )}
                    </Col>
                    {/* 2 */}
                  </FlexForm>
                </>
              ) : null}
            </Content>
            <ButtonStyle>
              <Row justify="center" gutter={12}>
                <Col span={4}>
                  <Button
                    type="block"
                    htmlType="submit"
                    size="large"
                    className="full"
                    onClick={handleCloseModal}
                  >
                    Đóng lại
                  </Button>
                </Col>
                <Col span={4}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="full"
                    loading={loadingComponent}
                  >
                    Cập nhật
                  </Button>
                </Col>
              </Row>
            </ButtonStyle>
            {showModalConfirm}
          </FormComponent>
        </Spin>
      </Container>
    </Fragment>
  );
}
const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
  .ant-table-tbody > tr > td {
    padding: 1px !important ;
  }
  .ant-form-item-has-error {
    padding-top: 0 !important;
  }
`;
const FlexForm = styled.div`
  display: flex;
  justify-content: space-around;
  .flex-left {
    display: flex;
    flex-wrap: wrap;
    flex: 3;
  }
`;
const ButtonStyle = styled.div`
  margin-top: 20px;
`;
const Content = styled.div`
  .ant-form-item-has-error {
    padding-top: 25px;
  }
  .ant-form-item {
    margin: 0 !important;
  }
`;
