import {
  FORMAT_DATE,
  FUNC_ID_DSYC_TREOTHAO,
  METHOD_POST,
  METHOD_PUT,
  STATUSCODE_200,
} from "@utils/constants";
import {
  authGetData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { alertMessage, getErrorMessage } from "@utils/function";
import { closeModal, openModal } from "@state/system-config/reducer";
import LapBienBanTreoThao from "@modules/treo-thao/common-ver1/lap-bien-ban-treo-thao";
import moment from "moment";

export const handleMultiNhanVien = (
  setLoading,
  setDataNhanVien,
  setDataTreoThao,
  setDataNiemPhong,
  detail
) => {
  const endpoints = [
    `${Endpoint.LIST_NHAN_VIEN_NGHIEM_THU}`,
    `${Endpoint.LIST_NHAN_VIEN_TREO_THAO_NGHIEM_THU}?HoSoId=${detail.id}`,
    `${Endpoint.LIST_NHAN_VIEN_NIEM_PHONG_NGHIEM_THU}?HoSoId=${detail.id}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDataNhanVien(res[0].data.data);
      setDataTreoThao(res[1].data.data);
      setDataNiemPhong(res[2].data.data);
    },
  });
};

export const handleDataLyDo = (setLoading, loaiBienBan, setDataLyDo) => {
  // const endpoints = [
  //   `${
  //     Endpoint.GET_SYNC_LIST_DETAIL_MOBILE
  //   }?loaiBienBan=${loaiBienBan.toString()}`,
  // ];
  const endpoints = [`${Endpoint.GET_SYNC_LIST_DETAIL_MOBILE}`];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDataLyDo(res[0].data.data);
    },
  });
};

export const handleSubmitPhanCong = (
  setLoading,
  detail,
  values,
  setFetchDataTable,
  form
) => {
  const data = {
    hoSoId: detail.id,
    nhanVienTreoThaoId: values.nhanVienTreoThaoId,
    nhanVienNiemPhongId: values.nhanVienNiemPhongId,
  };
  authPostData({
    url: "",
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};
const dataCTTreo = {
  maCongTo: null,
  soCongTo: null,
  loaiCongTo: null,
  nuocSX: null,
  bcs: null,
  dongDien: null,
  dienAp: null,
  ngayKiemDinh: null,
  seryTemKiemDinh: null,
  maTemKiemDinh: null,
  soChiKiemDinh: null,
  maChiHop: null,
  soVienChiHop: null,
  maCBOOC: null,
  soVienCBOOC: null,
  kimChiTai: null,
  soVienChiTai: null,
  thoiDiemLapTrinhCuoi: null,
  soLanLapTrinh: null,
  soPha: null,

  // vhCong: null,
  // lapTrinhLaiCongTo: false,
  // layBoChiSoNhan: false,
  // qrCode: null,
  // isThayDoiLoaiDiemDo: false,
  congToId: "00000000-0000-0000-0000-000000000000",
  // heSoNhanDoDem: null,
  // loaiDiemDo: null,

  // soCuaSo: null,

  // soPha: null,
  // soBieuGia: null,
  // chieuDoDem: null,
  // tySoBienDong: null,
  // tySoBienDienAp: null,
  // ngayKiemDinh: null,
  // thoiDiemLapTrinhCuoi: null,
  // maChiHop: null,
  // soVienChiHop: null,
  // maCBOOC: null,
  // soVienCBOOC: null,
  // soVienKiemDinh: null,
  // donViHienThi: null,
  // heSoNhanManHinh: null,
  // maChungLoai: null,
  boChiSos: [],
  // maTemKiemDinh: null,
  // seryTemKiemDinh: null,
  // soLanLapTrinh: null,
  // ngayLapTrinh: null,
  // maChiKiemDinh: null,
  // soChiKiemDinh: null,
  // kimChiTai: null,
  // soVienChiTai: null,
  // maDonViKiemDinh: null,
  // maNhanVienKiemDinh: null,
  // maNhanVienLapTrinh: null,
};
export const handleDetailYC = (
  setLoading,
  loading,
  dispatch,
  setFetchList,
  selectedRowKeys,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.GET_LIST_DETAIL_MOBILE,
    method: METHOD_PUT,
    payload: {
      ids: selectedRowKeys,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        dispatch(
          openModal({
            content: (
              <LapBienBanTreoThao
                loading={loading}
                setFetchList={setFetchList}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                funcId={FUNC_ID_DSYC_TREOTHAO.NVTREOTHAO_PHANCONG}
                detail={res.data[0]}
                defaultCTTreo={{ ...res.data[0].congToTreo, ...dataCTTreo }}
              />
            ),
            title: `Lập biên bản treo tháo`,
          })
        );
      }
    },
  });
};

export const callAPICancel = (
  setLoading,
  selectedRowKeys,
  setSelectedRowKeys,
  setFetchList
) => {
  authPostData({
    url: Endpoint.CANCEL_PHAN_CONG_NV_TREO_THAO,
    method: METHOD_PUT,
    setLoading,
    payload: {
      ids: selectedRowKeys,
    },
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setSelectedRowKeys([]);
        setFetchList(true);
      }
    },
  });
};

const convertDateMoment = (date, month, year, number) => {
  const convertDate = date + "/" + (month + 1) + "/" + (year + number);
  return convertDate;
};

export const callAPISearchCodeCT = (
  setLoading,
  setDetailCTTreo,
  setDataCMIS,
  maThietBi,
  detail,
  changeDD,
  setMaCongToTreo,
  form,
  soPhaHienTai,
  setIsSoNhan
) => {
  authGetData({
    url:
      Endpoint.GET_SYNC_LIST_THIET_BI_MOBILE +
      "?maThietBi=" +
      maThietBi +
      "&loaiThietBi=CTO",
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        const arrBCS = [];
        res.data.boChiSos.forEach((item) => {
          arrBCS.push(item.bcs);
        });
        let congTo2Chieu = form.getFieldValue("layBoChiSoNhan")
          ? form.getFieldValue("layBoChiSoNhan")
          : false;
        if (arrBCS.length > 0) {
          const arr = arrBCS.filter((el) => el.includes("N"));
          if (arr.length > 0) congTo2Chieu = true;
        }
        form.setFieldsValue({
          layBoChiSoNhan: congTo2Chieu,
        });
        setIsSoNhan(congTo2Chieu);

        let validate = true;
        if (moment(res.data.ngayKiemDinh) <= moment("31/12/2019")) {
          if (parseInt(soPhaHienTai) === 1) {
            const convertDate = convertDateMoment(
              moment(res.data.ngayKiemDinh).date(),
              moment(res.data.ngayKiemDinh).month(),
              moment(res.data.ngayKiemDinh).year(),
              5
            );
            if (moment(convertDate) <= moment()) {
              alertMessage(
                "error",
                "Thông báo!",
                "Vượt quá thời gian kiểm định."
              );
              validate = true;
            } else validate = false;
          } else {
            const convertDate = convertDateMoment(
              moment(res.data.ngayKiemDinh).date(),
              moment(res.data.ngayKiemDinh).month(),
              moment(res.data.ngayKiemDinh).year(),
              2
            );
            if (moment(convertDate) <= moment()) {
              alertMessage(
                "error",
                "Thông báo!",
                "Vượt quá thời gian kiểm định."
              );
              validate = true;
            } else validate = false;
          }
        } else {
          if (parseInt(soPhaHienTai) === 1) {
            if (
              changeDD === 1 ||
              changeDD === "1" ||
              changeDD === 2 ||
              changeDD === "2"
            ) {
              const convertDate = convertDateMoment(
                moment(res.data.ngayKiemDinh).date(),
                moment(res.data.ngayKiemDinh).month(),
                moment(res.data.ngayKiemDinh).year(),
                5
              );
              if (moment(convertDate) <= moment()) {
                alertMessage(
                  "error",
                  "Thông báo!",
                  "Vượt quá thời gian kiểm định."
                );
                validate = true;
              } else validate = false;
            } else {
              const convertDate = convertDateMoment(
                moment(res.data.ngayKiemDinh).date(),
                moment(res.data.ngayKiemDinh).month(),
                moment(res.data.ngayKiemDinh).year(),
                6
              );
              if (moment(convertDate) <= moment()) {
                alertMessage(
                  "error",
                  "Thông báo!",
                  "Vượt quá thời gian kiểm định."
                );
                validate = true;
              } else validate = false;
            }
          } else {
            if (
              changeDD === 1 ||
              changeDD === "1" ||
              changeDD === 2 ||
              changeDD === "2"
            ) {
              const convertDate = convertDateMoment(
                moment(res.data.ngayKiemDinh).date(),
                moment(res.data.ngayKiemDinh).month(),
                moment(res.data.ngayKiemDinh).year(),
                4
              );
              if (moment(convertDate) <= moment()) {
                alertMessage(
                  "error",
                  "Thông báo!",
                  "Vượt quá thời gian kiểm định."
                );
                validate = true;
              } else validate = false;
            } else {
              const convertDate = convertDateMoment(
                moment(res.data.ngayKiemDinh).date(),
                moment(res.data.ngayKiemDinh).month(),
                moment(res.data.ngayKiemDinh).year(),
                3
              );
              if (moment(convertDate) <= moment()) {
                alertMessage(
                  "error",
                  "Thông báo!",
                  "Vượt quá thời gian kiểm định."
                );
                validate = true;
              } else validate = false;
            }
          }
        }
        if (!validate) {
          if (parseInt(res.data.soPha) === parseInt(soPhaHienTai)) {
            if (changeDD === 2 || changeDD === "2") {
              if (
                (arrBCS.indexOf("KT") > -1 && arrBCS.indexOf("VC") > -1) ||
                (arrBCS.indexOf("BT") > -1 && arrBCS.indexOf("VC") > -1)
              ) {
                setDetailCTTreo(res.data);
                setMaCongToTreo(maThietBi);
                setDataCMIS(res.data);
                if (res.data.boChiSos.length > 0)
                  res.data.boChiSos.forEach((item, index) => {
                    form.setFieldsValue({
                      [`chiSoMoi-${index}`]: item.chiSoMoi
                        ? item.chiSoMoi
                        : "0",
                      [`ngayPmax-${index}`]: item.ngayPMax
                        ? moment(item.ngayPMax, FORMAT_DATE)
                        : moment(moment(), FORMAT_DATE),
                      [`pMax-${index}`]: item.pMax ? item.pMax : null,
                    });
                  });
              } else
                alertMessage(
                  "error",
                  "Thông báo!",
                  "Loại điểm đo 2 thì công tơ treo mới cần có biểu KT;VC hoặc BT;VC !"
                );
            } else if (changeDD === 1 || changeDD === "1") {
              if (arrBCS.indexOf("KT") > -1) {
                setDetailCTTreo(res.data);
                setMaCongToTreo(maThietBi);
                setDataCMIS(res.data);
                if (res.data.boChiSos.length > 0)
                  res.data.boChiSos.forEach((item, index) => {
                    form.setFieldsValue({
                      [`chiSoMoi-${index}`]: item.chiSoMoi
                        ? item.chiSoMoi
                        : "0",
                      [`ngayPmax-${index}`]: item.ngayPMax
                        ? moment(item.ngayPMax, FORMAT_DATE)
                        : moment(moment(), FORMAT_DATE),
                      [`pMax-${index}`]: item.pMax ? item.pMax : null,
                    });
                  });
              } else
                alertMessage(
                  "error",
                  "Thông báo!",
                  "Loại điểm đo 1 thì công tơ treo mới cần có biểu KT !"
                );
            } else {
              if (res.data.vhCong === "DT" || res.data.vhCong === "D1") {
                setDetailCTTreo(res.data);
                setMaCongToTreo(maThietBi);
                setDataCMIS(res.data);
                if (res.data.boChiSos.length > 0)
                  res.data.boChiSos.forEach((item, index) => {
                    form.setFieldsValue({
                      [`chiSoMoi-${index}`]: item.chiSoMoi
                        ? item.chiSoMoi
                        : "0",
                      [`ngayPmax-${index}`]: item.ngayPMax
                        ? moment(item.ngayPMax, FORMAT_DATE)
                        : moment(moment(), FORMAT_DATE),
                      [`pMax-${index}`]: item.pMax ? item.pMax : null,
                    });
                  });
                if (
                  arrBCS.indexOf("BT") === -1 &&
                  arrBCS.indexOf("CD") === -1 &&
                  arrBCS.indexOf("TD") === -1
                ) {
                  alertMessage(
                    "error",
                    "Thông báo!",
                    "Loại điểm đo 3/5/8 thì công tơ treo mới cần có biểu BT;CD;TD !"
                  );
                }
              } else
                alertMessage(
                  "error",
                  "Thông báo!",
                  "Điểm đo loại 3, 5, 8 chỉ treo công tơ điện tử !"
                );
            }
          } else {
            alertMessage(
              "error",
              "Thông báo!",
              "Số pha của công tơ mới phải bằng số pha của điểm đo"
            );
          }
        }
      } else
        alertMessage(
          "warning",
          "Thông báo!",
          "Không tìm thấy thông tin thiết bị !"
        );
      setMaCongToTreo(maThietBi);
    },
  });
};

export const callAPISearchBarCode = (
  setLoading,
  setDataDangTreo,
  maThietBi,
  type,
  dataDangTreo,
  setChangeTab3,
  viTriNhap
) => {
  const loaiThietBi = type === "TU" ? "TU" : type === "TI" ? "TI" : "CTO";
  authGetData({
    url:
      Endpoint.GET_SYNC_LIST_THIET_BI_MOBILE +
      "?maThietBi=" +
      maThietBi +
      "&loaiThietBi=" +
      loaiThietBi,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        let validate = true;
        if (res.data.ngayKiemDinh === null || res.data.ngayKiemDinh === "") {
          alertMessage(
            "warning",
            "Thông báo!",
            "Thiếu thông tin Ngày kiểm định của thiết bị !"
          );
        } else {
          if (moment(res.data.ngayKiemDinh) <= moment("31/12/2019")) {
            const convertDate = convertDateMoment(
              moment(res.data.ngayKiemDinh).date(),
              moment(res.data.ngayKiemDinh).month(),
              moment(res.data.ngayKiemDinh).year(),
              5
            );
            if (moment(convertDate) <= moment()) {
              alertMessage(
                "error",
                "Thông báo!",
                "Vượt quá thời gian kiểm định."
              );
              validate = true;
            } else validate = false;
          } else {
            const convertDate = convertDateMoment(
              moment(res.data.ngayKiemDinh).date(),
              moment(res.data.ngayKiemDinh).month(),
              moment(res.data.ngayKiemDinh).year(),
              5
            );
            if (moment(convertDate) <= moment()) {
              alertMessage(
                "error",
                "Thông báo!",
                "Vượt quá thời gian kiểm định."
              );
              validate = true;
            } else validate = false;
          }
        }

        if (!validate) {
          const tySoDau = dataDangTreo.tutIs[0].tySoDau
            ? dataDangTreo.tutIs[0].tySoDau
            : dataDangTreo.tutIs[1].tySoDau
            ? dataDangTreo.tutIs[1].tySoDau
            : dataDangTreo.tutIs[2].tySoDau
            ? dataDangTreo.tutIs[2].tySoDau
            : res.data.tySoDau;
          if (res.data.soPha === 1 || res.data.soPha === "1") {
            if (res.data.tySoDau !== tySoDau) {
              alertMessage(
                "warning",
                "Thông báo!",
                `Tỷ số đấu của ${loaiThietBi} vừa nhập phải giống Tỷ số đấu của ${loaiThietBi} đã nhập trước đó`
              );
            } else {
              dataDangTreo.tutIs[viTriNhap] = {
                thietBiId: "00000000-0000-0000-0000-000000000000",
                maThietBi: res.data.maCongTo,
                ngayKiemDinh: res.data.ngayKiemDinh,
                tySoDau: res.data.tySoDau,
                namSanXuat: res.data.namSanXuat,
                soPha: res.data.soPha,
                isChayHong: res.data.isChayHong,
                maTemKiemDinh: res.data.maTemKiemDinh,
                seryTemKiemDinh: res.data.seryTemKiemDinh,
                soLanLapTrinh: res.data.soLanLapTrinh,
                ngayLapTrinh: res.data.ngayLapTrinh,
                maChiKiemDinh: res.data.maChiKiemDinh,
                soChiKiemDinh: res.data.soChiKiemDinh,
                kimChiTai: res.data.kimChiTai,
                soVienChiTai: res.data.soVienChiTai,
                maDonViKiemDinh: res.data.maDonViKiemDinh,
                maNhanVienKiemDinh: res.data.maNhanVienKiemDinh,
                maNhanVienLapTrinh: res.data.maNhanVienLapTrinh,
              };
              setDataDangTreo(dataDangTreo);

              setChangeTab3(true);
            }
          } else {
            alertMessage(
              "warning",
              "Thông báo!",
              "Số pha phải bằng tổng số số pha cộng lại"
            );
          }
        }
      } else {
        alertMessage(
          "warning",
          "Thông báo!",
          "Không tìm thấy thông tin thiết bị !"
        );
      }
    },
  });
};

export const handleUpdateCTSYNC = (
  setLoading,
  payload,
  setFetchList,
  dispatch,
  setSelectedRowKeys
) => {
  let values = {
    ...payload,
    thongTinChung: {
      ...payload.thongTinChung,
      loaiDiemDo:
        payload?.congToTreo && payload?.congToTreo?.loaiDiemDo !== null
          ? Number(payload.congToTreo.loaiDiemDo)
          : payload.thongTinChung.loaiDiemDo,
    },
    congToTreo: null,
  };

  if (payload.congToTreo !== null) {
    values = {
      ...values,
      congToTreo: {
        ...payload.congToTreo,
        loaiDiemDo: Number(payload.congToTreo.loaiDiemDo),
      },
    };
  }
  authPostData({
    url: Endpoint.UPDATE_SYNC_DONG_BO_MOBILE,
    method: METHOD_POST,
    setLoading,
    payload: values,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
        dispatch(closeModal());
        setSelectedRowKeys([]);
      }
    },
  });
};
