import { Form } from "antd";

import { Endpoint } from "@utils/endpoint";
import { useState } from "react";
import { downloadFile } from "@utils/request";
import BaoCaoCapDienHaApLayout from "./layout";
import { alertMessage, formatSplitDate } from "@utils/function";
import { TYPE_BAOCAO_HA_AP } from "@utils/constants";
export default function Index(props) {
  const { donVi, changedDonViId, handleChangeUnit } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [loaiBaoCao] = useState("1");
  const [loaiBaoCaoExcel, setLoaiBaoCaoExce] = useState(1);
  const [value, setValue] = useState("1");

  const handleChangeValue = (value) => {
    setValue(value);
  };
  //export excel
  const onFinish = (values) => {
    if (values.loaiBaoCao === "1") {
      const data = {
        ...values,
        thangBaoCao: formatSplitDate(values.thangBaoCao, false),
        loaiBaoCao: parseInt(value),
      };
      let url = Endpoint.EXPORT_EXCEL_BAO_CAO_CHI_TIET_DIEN_HA_AP;
      if (loaiBaoCaoExcel === TYPE_BAOCAO_HA_AP.TRAM)
        url = Endpoint.EXPORT_EXCEL_BAO_CAO_TRAM_DIEN_HA_AP;
      else if (loaiBaoCaoExcel === TYPE_BAOCAO_HA_AP.MOI)
        url = Endpoint.EXPORT_EXCEL_BAO_CAO_CHI_PHI_CAP_MOI_DIEN_HA_AP;
      else if (loaiBaoCaoExcel === TYPE_BAOCAO_HA_AP.NANGCAP)
        url = Endpoint.EXPORT_EXCEL_BAO_CAO_CHI_PHI_NANG_CAP_DIEN_HA_AP;
      else if (loaiBaoCaoExcel === TYPE_BAOCAO_HA_AP.CHUYENDUNG)
        url = Endpoint.EXPORT_EXCEL_BAO_CAO_CHI_PHI_CHUYEN_DUNG_DIEN_HA_AP;
      const endpoint =
        url +
        "?donViId=" +
        data.donViId +
        "&thangBaoCao=" +
        data.thangBaoCao +
        "&loaiBaoCao=" +
        data.loaiBaoCao;

      downloadFile({
        endpoint,
        setLoading,
      });
    } else if (value === "4") {
      if (
        values.startDateBaoCao !== undefined &&
        values.startDateBaoCao !== null &&
        values.endDateBaoCao !== undefined &&
        values.endDateBaoCao !== null
      ) {
        if (values.startDateBaoCao <= values.endDateBaoCao) {
          const data = {
            ...values,
            loaiBaoCao: parseInt(value),
            startDateBaoCao: values.startDateBaoCao,
            endDateBaoCao: values.endDateBaoCao,
          };
          let url = Endpoint.EXPORT_EXCEL_BAO_CAO_CHI_TIET_DIEN_HA_AP;
          if (loaiBaoCaoExcel === TYPE_BAOCAO_HA_AP.TRAM)
            url = Endpoint.EXPORT_EXCEL_BAO_CAO_TRAM_DIEN_HA_AP;
          else if (loaiBaoCaoExcel === TYPE_BAOCAO_HA_AP.MOI)
            url = Endpoint.EXPORT_EXCEL_BAO_CAO_CHI_PHI_CAP_MOI_DIEN_HA_AP;
          else if (loaiBaoCaoExcel === TYPE_BAOCAO_HA_AP.NANGCAP)
            url = Endpoint.EXPORT_EXCEL_BAO_CAO_CHI_PHI_NANG_CAP_DIEN_HA_AP;
          else if (loaiBaoCaoExcel === TYPE_BAOCAO_HA_AP.CHUYENDUNG)
            url = Endpoint.EXPORT_EXCEL_BAO_CAO_CHI_PHI_CHUYEN_DUNG_DIEN_HA_AP;
          const endpoint =
            url +
            "?donViId=" +
            data.donViId +
            "&loaiBaoCao=" +
            data.loaiBaoCao +
            "&startDateBaoCao=" +
            data.startDateBaoCao +
            "&endDateBaoCao=" +
            data.endDateBaoCao;

          downloadFile({
            endpoint,
            setLoading,
          });
        } else {
          alertMessage(
            "error",
            "Thông báo",
            "Từ ngày báo cáo phải nhỏ hơn đến ngày báo cáo!"
          );
        }
      } else {
        alertMessage(
          "error",
          "Thông báo",
          "Vui lòng chọn giá trị ngày báo cáo!"
        );
      }
    }
  };

  const arrLoaiBaoCao = [
    {
      value: 1,
      name: "Báo cáo chi tiết",
    },
    {
      value: 2,
      name: "Báo cáo tổng hợp theo trạm",
    },
    {
      value: 3,
      name: "Báo cáo tổng hợp theo chi phí xây trạm TBA",
    },
    {
      value: 4,
      name: "Báo cáo tổng hợp theo chi phí nâng suất TBA",
    },
    {
      value: 5,
      name: "Báo cáo tổng hợp theo chi phí dùng nhờ Trạm chuyên dùng",
    },
  ];

  const handleChangeType = (e) => {
    setLoaiBaoCaoExce(e.target.value);
  };
  return (
    <BaoCaoCapDienHaApLayout
      loading={loading}
      form={form}
      onFinish={onFinish}
      handleChangeType={handleChangeType}
      loaiBaoCao={loaiBaoCao}
      handleChangeValue={handleChangeValue}
      value={value}
      donVi={donVi}
      changedDonViId={changedDonViId}
      handleChangeUnit={handleChangeUnit}
      loaiBaoCaoExcel={loaiBaoCaoExcel}
      arrLoaiBaoCao={arrLoaiBaoCao}
    />
  );
}
