import { ENUM_FUNCTION, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import {
  authGetData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";
import { Endpoint } from "@utils/endpoint";
export const handleListTroNgai = (setLoading, setDataTroNgai) => {
  authGetData({
    url: `${Endpoint.XU_LY_LIST_TRO_NGAI}?funcId=${ENUM_FUNCTION.KHOIPHUCTHICONG}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) setDataTroNgai(res.data);
    },
  });
};

// call api xác nhận khôi phục thi công

export const handleKhoiPhucThiCong = (
  t,
  setLoading,
  handleCloseModal,
  setFetchDataTable,
  hosoId
) => {
  const data = {
    hosoId,
  };
  authPostData({
    url: Endpoint.KHOI_PHUC_THI_CONG,
    setLoading,
    payload: data,
    method: METHOD_PUT,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModal();
      }
    },
  });
};

export const handleXemLaiPhuongAn = (
  detail,
  setDataPAXemLai,
  setVisibleXemLaiPhuongAnCapDien,
  visibleXemLaiPhuongAnCapDien
) => {
  const endpoints = [
    `${Endpoint.KS_GET_PHUONG_AN_CAP_DIEN}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    `${Endpoint.KS_TONG_CONG_SUAT}?HoSoId=${detail?.hoSoId ?? detail?.id}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading: () => false,
    onSuccess: (res) => {
      if (res[0]?.data !== null && res[1]?.data !== null) {
        setDataPAXemLai({ ...res[0].data.data, ...res[1].data.data });
        setVisibleXemLaiPhuongAnCapDien(!visibleXemLaiPhuongAnCapDien);
      }
    },
  });
};

export const handleLapBBLDDuongDay = (
  setLoading,
  loading,
  payload,
  dispatch,
  setFetchDataTable,
  detailHoSo,
  onComplete
) => {
  authPostData({
    url: Endpoint.GET_LAP_DAT_BIEN_BAN_DUONG_DAY,
    method: METHOD_PUT,
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        onComplete(res.data);
        // console.log(1);
        // return dispatch(
        //   openModal({
        //     content: (
        //       <LapBBLD
        //         detailHoSo={detailHoSo}
        //         detail={res.data[0]}
        //         loading={loading}
        //         setFetchList={setFetchDataTable}
        //         selectedRowKeys={detailHoSo.id}
        //         defaultCTTreo={{
        //           ...res.data[0].congToTreo,
        //           ...dataCTTreo,
        //         }}
        //       />
        //     ),
        //     title: "Lập biên bản lắp đặt đường dây",
        //     size: "large",
        //   })
        // );
      }
    },
  });
  // authPostData({
  //   url: Endpoint.GET_LAP_DAT_BIEN_BAN_DUONG_DAY + '?hoSoId=' + detailHoSo.id,
  //   method: METHOD_POST,
  //   payload,
  //   setLoading,
  //   onSuccess: (res) => {
  //     if (res.statusCode === STATUSCODE_200 && res.data) {
  //       return dispatch(
  //         openModal({
  //           content: (
  //             <LapBBLD
  //               setFetchDataTable={setFetchDataTable}
  //               detailHoSo={detailHoSo}
  //               detail={res.data}
  //             />
  //           ),
  //           title: "Lập biên bản lắp đặt đường dây",
  //           size: "large",
  //         })
  //       );
  //     }
  //   },
  // });
};
