import { Endpoint } from "@utils/endpoint";
import { authGetData, authGetMultiplesRequest } from "@utils/request";
import { buildQueryString, parseParams } from "@utils/function";
import ThongTinChiTietTabs from "../../common-ho-so/chi-tiet-ho-so";
import { closeModal, openModal } from "@state/system-config/reducer";

export const handleListBaoCao = (
  setLoading,
  setData,
  setTotalCount,
  filterConditions
) => {
  authGetData({
    url:
      Endpoint.GET_LIST_BAO_CAO_CHI_PHI_QUYET_TOAN +
      "?" +
      buildQueryString(parseParams(filterConditions)),
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setData(res.data);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};

export const handleListColumns = (setLoading, setDataColumns, dispatch) => {
  authGetData({
    url: Endpoint.GET_LIST_COLUMNS_BAO_CAO_CHI_PHI_QUYET_TOAN,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        const detailHoSo = (record) => {
          return dispatch(
            openModal({
              content: (
                <ThongTinChiTietTabs
                  detailHoSo={record}
                  closeModal={closeModal}
                />
              ),
              size: "large",
              title: `${
                record.maYeuCauKNCMIS !== null
                  ? record.maYeuCauKNCMIS + " - "
                  : ""
              }${record.tenKhachHang !== null ? record.tenKhachHang : ""}`,
            })
          );
        };

        const columns = [
          {
            title: "STT",
            dataIndex: "stt",
            key: "stt",
            hide: false,
            disabled: true,
            group: 1,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 50,
            fixed: "left",
          },
          {
            title: "Đơn vị",
            dataIndex: "maDonVi",
            key: "maDonVi",
            hide: false,
            group: 1,
            disabled: true,
            sorter: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 100,
            fixed: "left",
          },
          {
            title: "Tên khách hàng",
            dataIndex: "tenKhachHang",
            key: "tenKhachHang",
            hide: false,
            disabled: true,
            group: 1,
            sorter: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 150,
            fixed: "left",
          },
          {
            title: "Mã yêu cầu",
            dataIndex: "maYeuCauKNCMIS",
            key: "MaYeuCauKNCMIS",
            hide: false,
            disabled: true,
            group: 1,
            sorter: true,
            render: (text, record) => (
              <div
                className="table-text-left ant-btn-link cursor-pointer"
                onClick={() => detailHoSo(record)}
              >
                <span>{text}</span>
              </div>
            ),
            width: 120,
            fixed: "left",
          },
          {
            title: "Mã khách hàng",
            dataIndex: "maKhachHang",
            key: "maKhachHang",
            hide: true,
            disabled: false,
            group: 1,
            sorter: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 120,
          },
          {
            title: "Địa chỉ",
            dataIndex: "diaChi",
            key: "diaChi",
            hide: true,
            disabled: false,
            group: 1,
            sorter: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 250,
          },
          {
            title: "Điện thoại",
            dataIndex: "dienThoai",
            key: "dienThoai",
            hide: true,
            disabled: false,
            group: 1,
            sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 100,
          },
          {
            title: "Loại hợp đồng",
            dataIndex: "loaiHopDong",
            key: "loaiHopDong",
            hide: true,
            disabled: false,
            group: 1,
            sorter: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 150,
          },
          {
            title: "Hình thức lắp đặt",
            dataIndex: "hinhThucLapDat",
            key: "hinhThucLapDat",
            hide: true,
            disabled: false,
            group: 1,
            sorter: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 150,
          },
          {
            title: "Công suất tối đa",
            dataIndex: "congSuatToiDa",
            key: "congSuatToiDa",
            hide: true,
            disabled: false,
            group: 1,
            sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 120,
          },
          {
            title: "Số pha",
            dataIndex: "soPha",
            key: "soPha",
            hide: true,
            disabled: false,
            group: 1,
            sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 80,
          },
          {
            title: "Tổng chi phí thực hiện cấp mới",
            dataIndex: "tongChiPhiCapMoi",
            key: "tongChiPhiCapMoi",
            hide: true,
            disabled: false,
            group: 1,
            sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 130,
          },
          {
            title: "Tổng chi phí thực hiện cấp mới (Có VAT)",
            dataIndex: "tongChiPhiCapMoiVAT",
            key: "tongChiPhiCapMoiVAT",
            hide: true,
            disabled: false,
            group: 1,
            sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 130,
          },
          {
            title: "Trạng thái",
            dataIndex: "trangThai",
            key: "trangThai",
            hide: true,
            disabled: false,
            group: 1,
            sorter: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 150,
          },
          {
            title: "Ngày hoàn tất hồ sơ (CMIS)",
            dataIndex: "ngayHoanTatHoSoCmis",
            key: "ngayHoanTatHoSoCmis",
            hide: true,
            disabled: false,
            group: 1,
            sorter: true,
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            width: 150,
          },
          {
            title: "Tổng số ngày thực hiện",
            dataIndex: "tongSoNgayThucHien",
            key: "tongSoNgayThucHien",
            hide: true,
            disabled: false,
            group: 1,
            sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 120,
          },
          {
            title: "Tên công tơ",
            dataIndex: "tenCongToVTCT",
            key: "tenCongToVTCT",
            hide: true,
            disabled: false,
            group: 2,
            // sorter: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 200,
            parent: "Cáp trước công tơ",
          },
          {
            title: "Giá",
            dataIndex: "giaCongToVTCT",
            key: "giaCongToVTCT",
            hide: true,
            disabled: false,
            group: 2,
            // sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 80,
            parent: "Cáp trước công tơ",
          },
          {
            title: "Cáp trước công tơ",
            dataIndex: "tenCapTruocVTCT",
            key: "tenCapTruocVTCT",
            hide: true,
            disabled: false,
            group: 2,
            // sorter: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 200,
            parent: "Cáp trước công tơ",
          },
          {
            title: "Số lượng cáp",
            dataIndex: "soLuongVTCT",
            key: "soLuongVTCT",
            hide: true,
            disabled: false,
            group: 2,
            // sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 120,
            parent: "Cáp trước công tơ",
          },
          {
            title: "Giá",
            dataIndex: "giaDayCapVTCT",
            key: "giaDayCapVTCT",
            hide: true,
            disabled: false,
            group: 2,
            // sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 80,
            parent: "Cáp trước công tơ",
          },
          {
            title: "Vật tư phụ (liệt kê hết vào 1 ô)",
            dataIndex: "vatTuPhuVTCT",
            key: "vatTuPhuVTCT",
            hide: true,
            disabled: false,
            group: 2,
            // sorter: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 210,
            parent: "Cáp trước công tơ",
          },
          {
            title: "Giá",
            dataIndex: "giaVatTuPhuVTCT",
            key: "giaVatTuPhuVTCT",
            hide: true,
            disabled: false,
            group: 2,
            // sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 80,
            parent: "Cáp trước công tơ",
          },
          {
            title: "Nhân công",
            dataIndex: "nhanCongVTCT",
            key: "nhanCongVTCT",
            hide: true,
            disabled: false,
            group: 2,
            // sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 100,
            parent: "Cáp trước công tơ",
          },
          {
            title: "MTC",
            dataIndex: "mtcVTCT",
            key: "mtcVTCT",
            hide: true,
            disabled: false,
            group: 2,
            // sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 80,
            parent: "Cáp trước công tơ",
          },
          {
            title: "Chi phí khác",
            dataIndex: "chiPhiKhacVTCT",
            key: "chiPhiKhacVTCT",
            hide: true,
            disabled: false,
            group: 2,
            // sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 120,
            parent: "Cáp trước công tơ",
          },
          {
            title: "Tổng chi phí cáp trước",
            dataIndex: "tongChiPhiCapTruocVTCT",
            key: "tongChiPhiCapTruocVTCT",
            hide: true,
            disabled: false,
            group: 2,
            // sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 120,
            parent: "Cáp trước công tơ",
          },
          {
            title: "Tổng chi phí cáp trước (Có VAT)",
            dataIndex: "tongChiPhiCapTruocVATVTCT",
            key: "tongChiPhiCapTruocVATVTCT",
            hide: true,
            disabled: false,
            group: 2,
            // sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 120,
            parent: "Cáp trước công tơ",
          },

          // {
          //   title: "Tên công tơ",
          //   dataIndex: "tenCongToVTCS",
          //   key: "tenCongToVTCS",
          //   hide: true,
          //   disabled: false,
          //   group: 3,
          //   sorter: true,
          //   render: (text) => (
          //     <div className="table-text-left">
          //       <span>{text}</span>
          //     </div>
          //   ),
          //   width: 200,
          //   parent: "Cáp sau công tơ",
          // },
          // {
          //   title: "Giá",
          //   dataIndex: "giaCongToVTCS",
          //   key: "giaCongToVTCS",
          //   hide: true,
          //   disabled: false,
          //   group: 3,
          //   sorter: true,
          //   render: (text) => (
          //     <div className="table-text-right">
          //       <span>{text}</span>
          //     </div>
          //   ),
          //   width: 80,
          //   parent: "Cáp sau công tơ",
          // },
          {
            title: "Cáp sau công tơ",
            dataIndex: "tenCapTruocVTCS",
            key: "tenCapTruocVTCS",
            hide: true,
            disabled: false,
            group: 3,
            // sorter: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 200,
            parent: "Cáp sau công tơ",
          },
          {
            title: "Số lượng cáp",
            dataIndex: "soLuongVTCS",
            key: "soLuongVTCS",
            hide: true,
            disabled: false,
            group: 3,
            // sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 120,
            parent: "Cáp sau công tơ",
          },
          {
            title: "Giá",
            dataIndex: "giaDayCapVTCS",
            key: "giaDayCapVTCS",
            hide: true,
            disabled: false,
            group: 3,
            // sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 80,
            parent: "Cáp sau công tơ",
          },
          {
            title: "Vật tư phụ (liệt kê hết vào 1 ô)",
            dataIndex: "vatTuPhuVTCS",
            key: "vatTuPhuVTCS",
            hide: true,
            disabled: false,
            group: 3,
            // sorter: true,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: 210,
            parent: "Cáp sau công tơ",
          },
          {
            title: "Giá",
            dataIndex: "giaVatTuPhuVTCS",
            key: "giaVatTuPhuVTCS",
            hide: true,
            disabled: false,
            group: 3,
            // sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 80,
            parent: "Cáp sau công tơ",
          },
          {
            title: "Nhân công",
            dataIndex: "nhanCongVTCS",
            key: "nhanCongVTCS",
            hide: true,
            disabled: false,
            group: 3,
            // sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 100,
            parent: "Cáp sau công tơ",
          },
          {
            title: "MTC",
            dataIndex: "mtcVTCS",
            key: "mtcVTCS",
            hide: true,
            disabled: false,
            group: 3,
            // sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 80,
            parent: "Cáp sau công tơ",
          },
          {
            title: "Chi phí khác",
            dataIndex: "chiPhiKhacVTCS",
            key: "chiPhiKhacVTCS",
            hide: true,
            disabled: false,
            group: 3,
            // sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 120,
            parent: "Cáp sau công tơ",
          },
          {
            title: "Tổng chi phí cáp sau",
            dataIndex: "tongChiPhiCapTruocVTCS",
            key: "tongChiPhiCapTruocVTCS",
            hide: true,
            disabled: false,
            group: 3,
            // sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 120,
            parent: "Cáp sau công tơ",
          },
          {
            title: "Tổng chi phí cáp sau (Có VAT)",
            dataIndex: "tongChiPhiCapTruocVATVTCS",
            key: "tongChiPhiCapTruocVATVTCS",
            hide: true,
            disabled: false,
            group: 3,
            // sorter: true,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: 120,
            parent: "Cáp sau công tơ",
          },
        ];

        const newColumns = columns.filter((column) => {
          return !res.data.some((item) => {
            if (
              column.dataIndex ===
              item.key.replace(
                item.key.charAt(0),
                item.key.charAt(0).toLocaleLowerCase()
              )
            ) {
              column.hide = item.value;
              column.group = item.group;
            }
            return null;
          });
        });
        setDataColumns(newColumns);
      }
    },
  });
};

export const handleMultiBoLoc = (
  setLoading,
  setLoaiYeuCau,
  setMucDich,
  setSoPha,
  setHinhThucLapDat,
  setHinhThucNopTien,
  setDonVi
) => {
  const endpoints = [
    `${Endpoint.COMMON_LIST_LOAI_YEU_CAU}`,
    `${Endpoint.COMMON_LIST_MUC_DICH}`,
    `${Endpoint.COMMON_LIST_SO_PHA}`,
    `${Endpoint.COMMON_LIST_HINH_THUC_LAP_DAT}`,
    `${Endpoint.COMMON_LIST_HINH_THUC_TRA_TIEN}`,
    `${Endpoint.LIST_DON_VI}?OrderBy=maDonVi&pageSize=-1`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setLoaiYeuCau(res[0].data.data);
      setMucDich(res[1].data.data);
      setSoPha(res[2].data.data);
      setHinhThucLapDat(res[3].data.data);
      setHinhThucNopTien(res[4].data.data);
      setDonVi(res[5].data.data);
    },
  });
};
