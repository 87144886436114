import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { handleDetailHoSo, handleListLoaiGT } from "./services";
import PopupAnh from "./popup-anh";
import { INDEX_TAB2 } from "@utils/constants";
import { handleScroll } from "@state/system-config/reducer";
import { getIDHoSo } from "@state/cap-dien/tai-anh-scan/reducer";

function TaiAnhHoSoKhachHang({
  detail,
  closeModal,
  setFetchDataTable,
  isCheck,
  funcID,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [dataLoaiGT, setDataLoaiGT] = useState([]);
  const [firstData, setFistData] = useState("");
  const [indexTab, setIndexTab] = useState("2");
  const isCheckHide = isCheck === undefined || isCheck ? true : false;

  const callback = (key) => {
    if (key === INDEX_TAB2) {
      dispatch(
        handleScroll({
          scrollHeight: false,
        })
      );
    } else {
      dispatch(
        handleScroll({
          scrollHeight: true,
        })
      );
    }
    setIndexTab(key);
    // console.log(key);
  };

  useEffect(() => {
    handleDetailHoSo(detail, setLoading, setDataDetail);
    handleListLoaiGT(setLoading, setDataLoaiGT, setFistData);
    dispatch(getIDHoSo(detail.id));
  }, [detail, dispatch]);

  return (
    <PopupAnh
      callback={callback}
      t={t}
      dataDetail={dataDetail}
      loading={loading}
      dispatch={dispatch}
      closeModal={closeModal}
      detail={detail}
      setFetchDataTable={setFetchDataTable}
      setDataDetail={setDataDetail}
      dataLoaiGT={dataLoaiGT}
      firstData={firstData}
      indexTab={indexTab}
      setFistData={setFistData}
      isCheck={isCheckHide}
      funcID={funcID}
      // dataImage={dataImage}
      // idLoaiGT={idLoaiGT}
      // setDataImage={setDataImage}
    />
  );
}

export default memo(TaiAnhHoSoKhachHang);
