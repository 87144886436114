import styled from "styled-components";

const StyledAction = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    outline: none;
    border: none;
    padding: 0;
    background-color: transparent;
  }

`;

const StyleIconClose = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  .icon {
    font-size: 20px;
    cursor: pointer;
  }
`;

const StyleModal = styled.div`
  .ant-form-item-explain-error {
    margin: 5px 0 10px;
  }
`;

const StyleNote = styled.span`
  margin-left: 10px;
  opacity: 0.7;
  font-style: italic;
`;

export { StyledAction, StyleIconClose, StyleModal, StyleNote };
