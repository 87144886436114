import { Layout, Spin, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
// import Notification from "./Notification";
import UserProfile from "../UserProfile/UserProfile";
import SystemMenu from "./SystemMenu";
import { jsUcfirst } from "@utils/function";
import { BellOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Endpoint } from "@utils/endpoint";
import moment from "moment";
import {
  METHOD_POST,
  NAVIGATE_DICHVUDIEN,
  NAVIGATE_DICHVUDIEN_GIAODICHVIEN,
  NAVIGATE_TIEPNHANPHANCONG,
  STATUSCODE_200,
} from "@utils/constants";
import { authPostData } from "@utils/request";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import { cap_dien } from "@permissions/a_capdien";
import {
  handleCollapse,
  systemConfigSelector,
} from "@state/system-config/reducer";
const { Header } = Layout;

export default function HeaderComponent(props) {
  const { collapsed } = useSelector(systemConfigSelector);
  const { title, parentTitle = "", moduleCode, mobile } = props;
  const { token, user } = useSelector(authSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [hoSoSuccess, setHoSoSuccess] = useState(0);
  const [runTime, setRunTime] = useState(false);
  //function call api
  const callAPICMIS = useCallback(() => {
    const url = Endpoint.SYNC_TIEP_NHAN_YEU_CAU;
    const toDay = moment().format("DD-MM-YYYY");
    const data = {
      token: token,
      ngayDongBo: toDay,
      isChayNgam: true,
    };
    authPostData({
      url,
      method: METHOD_POST,
      payload: data,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200) {
          setHoSoSuccess(res.data.soHoSoDongBoThanhCong);
        }
      },
    });
  }, [token]);

  //fetch first call Api cmis
  useEffect(() => {
    if (moduleCode === "a") {
      callAPICMIS();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleCode]);

  //time out call api cmis
  useEffect(() => {
    if (
      (user?.tuDongDongBo && moduleCode === "a" && user?.isAdministrator) ||
      (user?.tuDongDongBo &&
        moduleCode === "a" &&
        user?.permission &&
        user?.permission.length &&
        user?.permission?.find((value) =>
          value.includes(cap_dien.a404_dong_bo_cmis)
        ))
    ) {
      let timer = null;
      if (!runTime) {
        timer = setTimeout(() => {
          callAPICMIS();
          setRunTime(true);
        }, user?.phutTuDongDongBo * 1000 * 60 ?? 60000);
      } else {
        timer = setTimeout(() => {
          callAPICMIS();
          setRunTime(false);
        }, user?.phutTuDongDongBo * 1000 * 60 ?? 60000);
      }
      return () => clearTimeout(timer);
    }
  }, [
    callAPICMIS,
    moduleCode,
    runTime,
    user,
    user?.phutTuDongDongBo,
    user?.tuDongDongBo,
  ]);

  const handleRedirectLink = useCallback(() => {
    setHoSoSuccess(0);
    if (
      user?.isAdministrator ||
      (user?.permission &&
        user?.permission.length &&
        user?.permission?.find((value) => value.includes(cap_dien.a11)))
    ) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_GIAODICHVIEN}${NAVIGATE_TIEPNHANPHANCONG}`
      );
    }
  }, [navigate, user?.isAdministrator, user?.permission]);

  //render noti
  const renderBadgeNoti = useCallback(() => {
    return (
      <Fragment>
        {moduleCode === "a" ? (
          <div className="action-item">
            <Spin tip={""} size="small" spinning={loading}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  cursor:
                    user?.isAdministrator ||
                    (user?.permission &&
                      user?.permission.length &&
                      user?.permission?.find((value) =>
                        value.includes(cap_dien.a11)
                      ))
                      ? "pointer"
                      : "unset",
                }}
                onClick={handleRedirectLink}
              >
                <Tooltip
                  title={`Đang có ${hoSoSuccess} hồ sơ đang chờ tiếp nhận.`}
                  placement="bottomLeft"
                >
                  <span className="ant-badge">
                    <Notication>
                      <BellOutlined />
                    </Notication>
                    <sup
                      data-show="true"
                      className="ant-scroll-number ant-badge-count ant-badge-count-sm"
                      title={hoSoSuccess}
                    >
                      <span
                        className="ant-scroll-number-only"
                        style={{ transition: "none 0s ease 0s" }}
                      >
                        <span className="ant-scroll-number-only-unit current">
                          {hoSoSuccess > 999 ? "999+" : hoSoSuccess}
                        </span>
                      </span>
                    </sup>
                  </span>
                </Tooltip>
              </div>
            </Spin>
          </div>
        ) : null}
      </Fragment>
    );
  }, [
    handleRedirectLink,
    hoSoSuccess,
    loading,
    moduleCode,
    user?.isAdministrator,
    user?.permission,
  ]);

  return (
    <HeaderStyled theme="light">
      <HeaderContentStyled
        style={{ justifyContent: mobile && !collapsed ? "end" : "" }}
      >
        {mobile ? (
          <MenuUnfoldOutlined
            style={{
              fontSize: 18,
              display: collapsed ? "block" : "none",
              transition: "smooth",
            }}
            onClick={() => dispatch(handleCollapse())}
          />
        ) : (
          <div className=" flex white-space-break">
            <div
              onClick={() => navigate("/trang-chu")}
              className="cursor-pointer page-name"
            >
              Trang chủ /{" "}
            </div>
            <div>{`${jsUcfirst()} ${title ? "/ " : ""}`}</div>
            {parentTitle && <div>{`${parentTitle} / `}</div>}
            <div>{title}</div>
          </div>
        )}
        <div className="action">
          {renderBadgeNoti()}
          <div className="action-item">
            <SystemMenu />
          </div>
          {/* <div className="action-item">
            <Notification />
          </div> */}
          <div className="action-item">
            <UserProfile />
          </div>
        </div>
      </HeaderContentStyled>
    </HeaderStyled>
  );
}

const HeaderStyled = styled(Header)`
  background: #405cab;
  height: 48px;
  line-height: 48px;
  box-shadow: inset 0px -1px 0px #d9d9d9;
  padding: 0px 15px;
  color: #fff;
`;

const HeaderContentStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .page-name {
    font-weight: bold;
    color: #fff;
    font-size: 16px;
  }
  .action {
    border-left: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 48px;
    padding-left: 15px;
    .action-item {
     min-width: 40px;
    }
  }
  .white-space-break{
    white-space: break-spaces;
  }
  .
`;

const Notication = styled.div`
  color: #ffffff;

  .anticon.anticon-bell svg {
    width: 20px;
    height: 20px;
  }
`;
