import { Modal } from "antd";
import { useEffect, useState } from "react";

import ThongTinThaoTabs from "./layout";

export default function Index(props) {
  const {
    detail,
    form,
    isResetIsThao,
    setIsResetIsThao,
    setIsResetIsTUTI,
    setChangeTab2,
  } = props;
  const [isThaoCongTo, setIsThaoCongTo] = useState(
    form.getFieldValue("isThaoCongTo")
      ? form.getFieldValue("isThaoCongTo")
      : detail?.isThaoCongTo
  );

  useEffect(() => {
    if (isResetIsThao) {
      form.setFieldsValue({
        isThaoCongTo: false,
      });
      setIsThaoCongTo(false);
      if (detail?.isThaoCongTo === isThaoCongTo) setChangeTab2(false);
      else setChangeTab2(true);
      setIsResetIsThao(false);
    }
  }, [
    detail?.isThaoCongTo,
    form,
    isResetIsThao,
    isThaoCongTo,
    setChangeTab2,
    setIsResetIsThao,
  ]);

  useEffect(() => {
    if (isThaoCongTo === detail?.isThaoCongTo) setChangeTab2(false);
    else setChangeTab2(true);
  }, [detail?.isThaoCongTo, form, isThaoCongTo, setChangeTab2]);

  //change tháo công tơ
  const handleChange = (e) => {
    // if (viTri === 1) {
    if (e.target.checked) {
      if (form.getFieldValue("isTreoThaoTUTI")) {
        Modal.confirm({
          centered: true,
          title: "Xác nhận",
          content: `Bắt buộc phải tháo tất cả TI/TU đang treo (nếu có) !. Hệ thống sẽ tự động xử lý bỏ chọn [Treo tháo TI/TU chốt chỉ số] nếu đang chọn !`,
          onOk() {
            setIsThaoCongTo(true);
            form.setFieldsValue({
              isThaoCongTo: true,
              isTreoThaoTUTI: false,
            });
            setIsResetIsTUTI(true);
            // else setChangeTab2(false);
          },
          onCancel() {
            setIsResetIsTUTI(false);
          },
          okText: "Đồng ý",
          cancelText: "Hủy",
        });
      } else {
        setIsThaoCongTo(e.target.checked);
        form.setFieldsValue({
          isThaoCongTo: e.target.checked,
        });
      }
    } else {
      form.setFieldsValue({
        isThaoCongTo: e.target.checked,
      });
      setIsThaoCongTo(e.target.checked);
    }
    // }
  };

  useEffect(() => {
    form.setFieldsValue({
      defaultCTThao: JSON.stringify(detail),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThongTinThaoTabs
      detail={detail}
      form={form}
      isThaoCongTo={isThaoCongTo}
      handleChange={handleChange}
    />
  );
}
