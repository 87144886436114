import FormComponent from "@components/Form";
import InputComponent from "@components/Input";
import Selection from "@components/Select";
import { BASE_API_URL, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { getErrorForm } from "@utils/function";
import { authGetData, authPostData } from "@utils/request";
import { Button, Col, Form, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";

export default function PopUpChangeUnit(props) {
  const [loading, setLoading] = useState(false);
  const [unitName, setUnitName] = useState();
  const { visible, close, idUser } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    if(idUser){
      authGetData({
        url: `${Endpoint.USER}/user-detail?id=${idUser}`,
        setLoading,
        onSuccess: (res) => {
          setUnitName(res.data.unitName);
        },
      });
    }
  }, [idUser]);
  const onFinish = (values) => {
    authPostData({
      url: `${BASE_API_URL}/user/change-unit`,
      method: "PUT",
      payload: {
        unitId: values.unitId,
        userId: idUser,
      },
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          form.resetFields();
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        } else {
          getErrorForm(res, form);
        }
      },
    });
  };

  return (
    <Modal
      width="700px"
      title="Chuyển đơn vị"
      visible={visible}
      footer={null}
      onCancel={() => close()}
    >
      <FormComponent onFinish={onFinish} form={form}>
        <Row gutter={8} labelCol={{ span: 24 }}>
          <Col span={24}>
            <Form.Item
              name="unitCurrent"
              label="Đơn vị hiện tại"
              labelCol={{ span: 24 }}
            >
              <InputComponent
                type="text"
                size="default"
                form={form}
                formKey="unitCurrent"
                disabled
                placeholder={unitName}
              />
            </Form.Item>
            <Col>
              <Form.Item
                name="unitId"
                label="Danh sách đơn vị"
                labelCol={{ span: 24 }}
              >
                <Selection
                  url={Endpoint.GET_UNIT_LIST}
                  formKey="unitId"
                  form={form}
                  placeholder="Chọn đơn vị"
                />
              </Form.Item>
            </Col>
          </Col>
        </Row>
        <Row gutter={16} justify="center">
          <Col span={4}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="full"
            >
              Cập nhật
            </Button>
          </Col>
        </Row>
      </FormComponent>
    </Modal>
  );
}
