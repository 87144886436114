import React, { useCallback } from "react";
import ButtonComponent from "@components/Button";
import { downloadFile } from "@utils/request";
import { authGetData } from "@utils/request";
import CheckPermission from "@components/CheckPermission";
import { ghi_chi_so } from "@permissions/d_gcs";

export const Export = (props) => {
  const { excelEndpoint, pdfEndpoint, setLoading } = props;
  const handleDownload = useCallback(
    (endpoint) => {
      if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
    },
    [setLoading]
  );

  const handleOpenPdf = useCallback(() => {
    if (pdfEndpoint !== undefined)
      authGetData({
        url: pdfEndpoint,
        setLoading,
        onSuccess: (res) => {
          if (res.statusCode === 200) {
            window.open(res.data, "_blank");
          }
        },
      });
  }, [pdfEndpoint, setLoading]);
  return (
    <>
      <CheckPermission permissioncode={ghi_chi_so.d5x}>
        {excelEndpoint && (
          <div className="btn-export-ex">
            <ButtonComponent
              size="default"
              type="primary"
              onClick={() => {
                handleDownload(excelEndpoint);
              }}
            >
              Xuất Excel
            </ButtonComponent>
          </div>
        )}
      </CheckPermission>
      <CheckPermission permissioncode={ghi_chi_so.d5p}>
        {pdfEndpoint && (
          <div className="btn-export">
            <ButtonComponent
              size="default"
              type="primary"
              onClick={handleOpenPdf}
            >
              Xuất PDF
            </ButtonComponent>
          </div>
        )}
      </CheckPermission>
    </>
  );
};
