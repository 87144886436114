import { authGetData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";

import { STATUSCODE_200, objModules } from "@utils/constants";

export const getPermissionList = (setLoading, setData, setDataGroup) => {
  authGetData({
    url: `${Endpoint.GET_PERMISSION_VIA_MODULE}`,
    setLoading,
    onSuccess: (res) => {
      setData(res.data);
      const arr = [];
      if (res.statusCode === STATUSCODE_200 && res.data.length) {
        Object.keys(objModules).map((key) => {
          const findModuleItems = res.data.filter((item) =>
            item.code.includes(key)
          );
          if (findModuleItems.length) {
            arr.push({
              moduleName: objModules[key],
              children: findModuleItems,
              moduleCode: key,
            });
          }
          return null;
        });
      }
      console.log(arr, "arr");
      setDataGroup(arr);
    },
  });
};
