import DanhSachPhieuVT from "../../common-ver1/phieu-vat-tu";
import { Fragment, useState } from "react";
import { treo_thao } from "@permissions/b_treothao";
import { Endpoint } from "@utils/endpoint";
import { ENUM_PHIEU_VAT_TU } from "@utils/constants";

export default function Index() {
  const [fetchList, setFetchList] = useState(false);

  return (
    <Fragment>
      <DanhSachPhieuVT
        url={Endpoint.LIST_PHIEU_VAT_TU}
        funcId={ENUM_PHIEU_VAT_TU.TCTKTRACUUPHIEUVT}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={false}
        permissionCodeXemPhieu={treo_thao.b92x}
        isNotLastColumn={true}
      />
    </Fragment>
  );
}
