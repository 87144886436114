import { useEffect, useState } from "react";
import { handleListImage } from "../../crm-services";
// import { handleListImage, handleRemoveImage } from "../../services";
import ListImage from "./list-image";
export default function Index({
  detail,
  remove,
  // loaiGiayToId,
  setDataImage,
  dataImage,
  setLoadingListAnh,
  loadingListAnh,
}) {
  // const [dataImage, setDataImage] = useState();
  // const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [, setCaption] = useState("");
  const [modalSrc, setModalSrc] = useState("");
  const [sectionIndex, setSectionIndex] = useState(null);
  const [imageIndex, setImageIndex] = useState(null);
  const [currentSectionLength, setCurrentSectionLength] = useState(null);
  const [hideButtonLeft, setHideButtonLeft] = useState(false);
  const [hideButtonRight, setHideButtonRight] = useState(false);
  const [note, setNote] = useState("");
  const showSliderImage = () => {
    if (sectionIndex <= 0 && imageIndex === 0) {
      setHideButtonLeft(true);
    } else setHideButtonLeft(false);
    if (
      dataImage[sectionIndex - 1] === dataImage.length - 1 &&
      imageIndex === dataImage.length - 1
    )
      setHideButtonRight(true);
    else setHideButtonRight(false);
    setShowModal(true);
  };

  const plusSlides = (n) => {
    const nextIndex = n;

    // const numberOfSections = dataDetail.length;

    if (nextIndex <= 0 && sectionIndex <= 1) {
      setHideButtonLeft(true);
    } else setHideButtonLeft(false);
    if (nextIndex === dataImage.length - 1) {
      setHideButtonRight(true);
    } else setHideButtonRight(false);
    let nextImage = nextIndex;
    let nextImageIndex = nextIndex;
    let nextSectionIndex = sectionIndex;
    let nextSectionLength = currentSectionLength;
    let note = dataImage[nextIndex]?.ghiChu;
    if (nextIndex >= sectionIndex) {
      nextImage = dataImage[nextIndex].pathImage;
      nextImageIndex = nextIndex;
      nextSectionIndex = sectionIndex;
      nextSectionLength = currentSectionLength;
      note = dataImage[nextIndex].ghiChu;
    } else {
      nextImage = dataImage[sectionIndex - 1].pathImage;
      nextImageIndex = 0;
      nextSectionIndex = sectionIndex - 1;
      nextSectionLength = dataImage[sectionIndex - 1].pathImage;
      note = dataImage[sectionIndex - 1].ghiChu;
    }

    setCaption(nextImage);
    setModalSrc(nextImage);
    setImageIndex(nextImageIndex);
    setSectionIndex(nextSectionIndex);
    setCurrentSectionLength(nextSectionLength);
    setNote(note);
  };

  useEffect(() => {
    handleListImage(detail, setLoadingListAnh, setDataImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ListImage
      loading={loadingListAnh}
      dataImage={dataImage}
      showSliderImage={showSliderImage}
      setCaption={setCaption}
      setModalSrc={setModalSrc}
      setImageIndex={setImageIndex}
      imageIndex={imageIndex}
      setSectionIndex={setSectionIndex}
      setCurrentSectionLength={setCurrentSectionLength}
      setHideButtonLeft={setHideButtonLeft}
      setHideButtonRight={setHideButtonRight}
      showModal={showModal}
      setShowModal={setShowModal}
      modalSrc={modalSrc}
      plusSlides={plusSlides}
      hideButtonLeft={hideButtonLeft}
      hideButtonRight={hideButtonRight}
      remove={remove}
      note={note}
      setNote={setNote}
      detail={detail}
      setDataImage={setDataImage}
      setLoading={setLoadingListAnh}
    />
  );
}
