import { handleScroll } from "@state/system-config/reducer";
import { INDEX_TAB2 } from "@utils/constants";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ChiTietYeuCauModal from "./modal-chi-tiet-yeu-cau";

function Index({ detailHoSo }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading] = useState(false);
  const [dataDetail] = useState([]);
  // const [, setDataAnhHoSo] = useState([]);
  const [dataTienTrinh] = useState([]);
  const [indexTab, setIndexTab] = useState("1");
  const [firstLoaiGT, setFirstLoaiGT] = useState("");

  const callback = (key) => {
    if (key === INDEX_TAB2) {
      dispatch(
        handleScroll({
          scrollHeight: false,
        })
      );
    } else {
      dispatch(
        handleScroll({
          scrollHeight: true,
        })
      );
    }
    setIndexTab(key);
  };

  const handleChangeType = (value) => {
    setFirstLoaiGT(value);
    scrollToTop(`data-${value}`);
  };

  const scrollToTop = (element) => {
    document
      .getElementById(element)
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <ChiTietYeuCauModal
      indexTab={indexTab}
      callback={callback}
      dataDetail={dataDetail}
      loading={loading}
      dataTienTrinh={dataTienTrinh}
      dispatch={dispatch}
      t={t}
      firstLoaiGT={firstLoaiGT}
      handleChangeType={handleChangeType}
      detailHoSo={detailHoSo}
    />
  );
}

export default memo(Index);
