/* eslint-disable no-unused-vars */
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import React, { memo, useState } from "react";
import { handleHuyChotSL, handleHuyTinhSL } from "../services";
import ConfirmModal from "./modal-confirm";

function Index({
  visible,
  handleCloseModal,
  content,
  title,
  setFetchList,
  user,
  thangLamViecNew,
  selectedRowKeys,
  thangLamViec,
  setFilterConditions,
  setFilterConditionsTCT,
  setIsDaChot,
  setLoading,
  loading,
  setSelectedRowKeys,
}) {
  // const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (user.isTongCongTy) {
      if (thangLamViecNew !== "") {
        const thang = thangLamViecNew.split("/")[0];
        const nam = thangLamViecNew.split("/")[1];
        const data = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViIds: selectedRowKeys,
        };
        const dataDD = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViIds:
            selectedRowKeys && selectedRowKeys.length > 0
              ? selectedRowKeys.toString()
              : "",
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
        };
        setFilterConditionsTCT(dataDD);
        handleHuyChotSL(
          setSelectedRowKeys,
          setFetchList,
          setLoading,
          data,
          handleCloseModal,
          setIsDaChot
        );
      } else {
        const thang = thangLamViec.split("-")[1];
        const nam = thangLamViec.split("-")[0];
        const data = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViIds: selectedRowKeys,
        };
        const dataDD = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViIds:
            selectedRowKeys && selectedRowKeys.length > 0
              ? selectedRowKeys.toString()
              : "",
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
        };
        setFilterConditionsTCT(dataDD);
        handleHuyChotSL(
          setSelectedRowKeys,
          setFetchList,
          setLoading,
          data,
          handleCloseModal,
          setIsDaChot
        );
      }
    } else {
      if (thangLamViecNew !== "") {
        const thang = thangLamViecNew.split("/")[0];
        const nam = thangLamViecNew.split("/")[1];
        const data = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViId: user.unitId,
        };
        const dataDD = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViIds: user.unitId,
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
        };
        setFilterConditions(dataDD);
        handleHuyTinhSL(setFetchList, setLoading, data, handleCloseModal);
      } else {
        const thang = thangLamViec.split("-")[1];
        const nam = thangLamViec.split("-")[0];
        const data = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViId: user.unitId,
        };
        const dataDD = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViIds: user.unitId,
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
        };
        setFilterConditions(dataDD);
        handleHuyTinhSL(setFetchList, setLoading, data, handleCloseModal);
      }
    }
  };

  return (
    <ConfirmModal
      loading={loading}
      visible={visible}
      closeModal={handleCloseModal}
      handleSubmit={handleSubmit}
      content={content}
      title={title}
    />
  );
}

export default memo(Index);
