import DanhSachYeuCau from "../../common-ver1/danh-sach-yeu-cau";
import { Fragment, useCallback, useState } from "react";
import { ENUM_DS_YEU_CAU, FUNC_ID_DSYC_TREOTHAO } from "@utils/constants";
import CheckPermission from "@components/CheckPermission";
import { treo_thao } from "@permissions/b_treothao";
import { Button } from "antd";
import UploadImage from "./modal";
export default function Index() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [fetchList, setFetchList] = useState(false);
  const [isUpload, setIsUploadFile] = useState(true);
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  //Hiển thị checkbox ở đầu mỗi bảng
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  //genExtra
  const genExtra = () => (
    <CheckPermission permissionCode={treo_thao.b33t}>
      <Button
        type="primary"
        className="mr-5px"
        onClick={() => handleOpenModal()}
        disabled={
          !selectedRowKeys.length || selectedRowKeys.length >= 2 || !isUpload
        }
      >
        Tải BBTT giấy
      </Button>
    </CheckPermission>
  );

  const [visible, setVisible] = useState(false);
  const handleOpenModal = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const handleCloseModal = () => {
    setVisible(false);
  };

  const renderUploadImage = useCallback(() => {
    return (
      visible && (
        <UploadImage
          visible={visible}
          closeModal={handleCloseModal}
          selectedRowKeys={selectedRowKeys}
          setFetchList={setFetchList}
          setIsUploadFile={setIsUploadFile}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      )
    );
  }, [selectedRowKeys, visible]);

  return (
    <Fragment>
      <DanhSachYeuCau
        funcId={FUNC_ID_DSYC_TREOTHAO.NVTREOTHAO_KHCHUAKYSO}
        typeList={ENUM_DS_YEU_CAU.NVTREOTHAO_KYSO}
        genExtra={genExtra}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        rowSelection={rowSelection}
        permissionCodeBB={treo_thao.b33bb}
      />
      {renderUploadImage()}
    </Fragment>
  );
}
