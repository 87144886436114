import { BASE_API_URL_TREO_THAO } from "@utils/constants";

export const APICommonTreoThao = {
  //danh sách yêu cầu
  DANH_SACH_YEU_CAU: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/danh-sach`,
  CHI_TIET_YEU_CAU: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/chi-tiet-yeu-cau`,
  LIST_ANH_HIEN_TRUONG_TREO_THAO: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/list-anh-hien-truong`,

  //api common cho select
  COMMON_LIST_TRAM_TREO_THAO: `${BASE_API_URL_TREO_THAO}/common/list-tram`,
  COMMON_LIST_TRANG_THAI_TREO_THAO: `${BASE_API_URL_TREO_THAO}/common/list-trang-thai`,
  COMMON_LIST_LOAI_TREO_THAO: `${BASE_API_URL_TREO_THAO}/common/list-loai-treo-thao`,
  COMMON_LIST_NGUOI_TAO: `${BASE_API_URL_TREO_THAO}/nguoi-tao/list`,
  //COMMON_LIST_DOI_TREO_THAO: `${BASE_API_URL_TREO_THAO}/doi/list`,
  COMMON_LIST_LOAI_PHIEUVT: `${BASE_API_URL_TREO_THAO}/common/list-loai-pvt`,
  COMMON_LIST_TRANGTHAI_PHIEUVT: `${BASE_API_URL_TREO_THAO}/common/list-trang-thai-pvt`,

  // cập nhật mã CL
  LIST_CHUNG_LOAI_CONG_TO_TREO_THAO: `${BASE_API_URL_TREO_THAO}/phan-cong/list-yc-update-chungloai`,
  UPDATE_MA_CHUNG_LOAI_CONG_TO_TREO_THAO: `${BASE_API_URL_TREO_THAO}/phan-cong/update-chung-loai-ct`,

  //phân công
  LIST_NHAN_VIEN_TREO_THAO_OR_NIEM_PHONG: `${BASE_API_URL_TREO_THAO}/phan-cong/list-nhan-vien`,
  PHAN_CONG_DU_LIEU: `${BASE_API_URL_TREO_THAO}/phan-cong/phan-cong`,
  LIST_DATA_LOG_PHAN_CONG: `${BASE_API_URL_TREO_THAO}/phan-cong/list-suggest-nvht`,

  //api xem phiếu
  VIEW_PHIEU: `${BASE_API_URL_TREO_THAO}/xem-phieu`,
  //Phiếu vật tư
  LIST_PHIEU_VAT_TU: `${BASE_API_URL_TREO_THAO}/phieu-vat-tu/danh-sach`,
  DELETE_PHIEU_VAT_TU: `${BASE_API_URL_TREO_THAO}/phieu-vat-tu`,
  IN_BB_PHIEU_VAT_TU: `${BASE_API_URL_TREO_THAO}/phieu-vat-tu/in-bb-phieu-vttt`,
  XAC_NHAN_PHIEU_XUAT_VTTT: `${BASE_API_URL_TREO_THAO}/phieu-vat-tu/xac-nhan-phieu-xuat-vttt`,
  XAC_NHAN_PHIEU_TRA_VTTT: `${BASE_API_URL_TREO_THAO}/phieu-vat-tu/xac-nhan-phieu-tra-vttt`,
  CHI_TIET_PHIEU_VAT_TU_DUYET_TRALAI: `${BASE_API_URL_TREO_THAO}/phieu-vat-tu/detail-pvt-xuat-tra`,
  LIST_LOAI_CONG_TO_TREO_THAO: `${BASE_API_URL_TREO_THAO}/phieu-vat-tu/list-loai-cong-to`,
  GET_TIEN_TRINH_PHIEU_VAT_TU: `${BASE_API_URL_TREO_THAO}/phieu-vat-tu/logs`,

  //Xác nhận an toàn
  XAC_NHAN_AN_TOAN: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/xac-nhan-an-toan`,
  GET_PHIEU_LENH_CONG_TAC: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/list-phieu-lenh-cong-tac`,

  //phiếu xuất vật tư
  LIST_VAT_TU_PHIEU_VAT_TU: `${BASE_API_URL_TREO_THAO}/phieu-vat-tu/list-vat-tu`,
  LIST_YEU_CAU_PHIEU_VAT_TU: `${BASE_API_URL_TREO_THAO}/phieu-vat-tu/list-yeu-cau`,
  DETAIL_YEU_CAU_PHIEU_XUAT_VAT_TU: `${BASE_API_URL_TREO_THAO}/phieu-vat-tu/detail-yeu-cau`,
  CREATE_PHIEU_VAT_TU: `${BASE_API_URL_TREO_THAO}/phieu-vat-tu/create-phieu-vat-tu`,
  UPDATE_PHIEU_VAT_TU: `${BASE_API_URL_TREO_THAO}/phieu-vat-tu/update-phieu-vat-tu`,
  TRALAI_DUYET_PHIEU_XUAT_VAT_TU: `${BASE_API_URL_TREO_THAO}/phieu-xuat-vat-tu/tra-lai-duyet`,
  REMOVE_PHIEU_XUAT_VAT_TU: `${BASE_API_URL_TREO_THAO}/phieu-xuat-vat-tu/delete`,
  CREATE_DANH_SACH_YEU_CAU_LIEN_QUAN: `${BASE_API_URL_TREO_THAO}/danh-sach-lien-quan/create`,
  REMOVE_DANH_SACH_YEU_CAU_LIEN_QUAN: `${BASE_API_URL_TREO_THAO}/danh-sach-lien-quan/delete`,
  SEARCH_YEU_CAU_PHIEU_VAT_TU: `${BASE_API_URL_TREO_THAO}/phieu-vat-tu/detail-list`,
  //phiếu trả vật tư
  REMOVE_PHIEU_TRA_VAT_TU: `${BASE_API_URL_TREO_THAO}/phieu-tra-vat-tu/delete`,

  //In biên bản
  IN_BIEN_BAN_TREO_THAO: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/in-bien-ban`,
  VIEW_LIST_BIEN_BAN_TREO_THAO: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/list-bien-ban-treo-thao`,

  //ký số YÊU CẦU
  KY_SO_GET_HASH: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/get-file-ky-so`,
  XAC_THUC_KY_SO: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/xac-thuc-ky-so`,
  //Ký số Phiếu Vật Tư
  KY_SO_GET_HASH_PHIEUVT: `${BASE_API_URL_TREO_THAO}/phieu-vat-tu/get-file-ky-so`,
  XAC_THUC_KY_SO_PHIEUVT: `${BASE_API_URL_TREO_THAO}/phieu-vat-tu/xac-thuc-ky-so`,

  //ky so hsm
  KY_SO_PHIEU_VAT_TU_HSM: `${BASE_API_URL_TREO_THAO}/phieu-vat-tu/ky-so-hsm`,
  KY_SO_YEU_CAU_HSM: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/ky-so-hsm`,

  //
  GET_LIST_DETAIL_MOBILE: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/list-details`,
  GET_SYNC_LIST_DETAIL_MOBILE: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/sync-ly-do-treo-thao`,
  GET_SYNC_LIST_THIET_BI_MOBILE: `${BASE_API_URL_TREO_THAO}/cong-to/get-thiet-bi`,
  UPDATE_SYNC_DONG_BO_MOBILE: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/mobile-sync`,
  GET_DATA_CHI_SO_TREO_THAo: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/data-chi-so-ct`,
  UPDATE_DATA_CHI_SO_TREO_THAo: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/update-chi-so-ct`,
};
