import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString } from "@utils/function";
import { authGetData } from "@utils/request";

export const getDanhSachCongToNoCap = (
  query,
  setLoading,
  setDataCustomerNoCap,
  setTotalCount
) => {
  let url = "";
  url = `${Endpoint.LIST_BIEN_BAN_CUSTOMER_NO_CAP}?${buildQueryString(query)}`;
  // key:
  //   (filterConditions.pageIndex + 1) * filterConditions.pageSize +
  //   (index + 1),
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataCustomerNoCap(res.data);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};
