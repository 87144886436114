import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Form, Input } from "antd";
import { callAPIXacNhanPhieu, handleChiTietPhieuDuyet } from "../services";
import LayoutPhieuXuat from "./layout";
import { useDispatch } from "react-redux";
import { closeModal } from "@state/system-config/reducer";

const EditableContext = React.createContext(null);

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} không được bỏ trống.`,
          },
        ]}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          type="number"
          min={0}
          placeholder="Nhập số lượng có thể xuất"
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default function Index(props) {
  const {
    setFetchList,
    selectRowKeys,
    typeLoaiPhieu,
    nguoiTao,
    permissionCodeKySo,
    maTrangThai,
  } = props;
  const [loading, setLoading] = useState(false);
  const [dataVT, setDataVT] = useState([]);
  const [defaultDataVT, setDefaultDataVT] = useState([]);
  const [form] = Form.useForm();
  const [isTraLai, setIsTraLai] = useState(false);
  const [defautInfo, setDefaultInfo] = useState([]);
  const dispatch = useDispatch();

  const defaultColumns = isTraLai
    ? [
        {
          title: "STT",
          dataIndex: "stt",
          key: "stt",
          render: (text, record, index) => (
            <div className="table-text-right">{index + 1}</div>
          ),
          width: "10%",
        },
        {
          title: "Chủng loại",
          dataIndex: "chungLoai",
          key: "chungLoai",
          render: (text) => <div className="table-text-center">{text}</div>,
          width: "15%",
        },
        {
          title: "Loại công tơ",
          dataIndex: "loaiCongTo",
          key: "loaiCongTo",
          render: (text) => <div className="table-text-left">{text}</div>,
          width: "20%",
        },
        {
          title: "Mô tả",
          dataIndex: "moTa",
          key: "moTa",
          render: (text) => <div className="table-text-left">{text}</div>,
          width: "22%",
        },
        {
          title: "Số lượng yêu cầu",
          dataIndex: "soLuongYeuCau",
          key: "soLuongYeuCau",
          render: (text) => <div className="table-text-right">{text}</div>,
          width: "10%",
        },
        {
          title: "Số lượng có thể xuất",
          dataIndex: "soLuongCoTheXuat",
          key: "soLuongCoTheXuat",
          render: (text, record, index) => (
            <div className="table-text-left">
              <Form.Item
                name={`soLuongCoTheXuat-${index + 1}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                <Input
                  type="number"
                  min={0}
                  onChange={(e) =>
                    handleChangeInput(e, record.soLuongYeuCau, index + 1)
                  }
                  style={{ textAlign: "right" }}
                />
              </Form.Item>
            </div>
          ),
          width: "15%",
          editable: true,
        },
        {
          title: "Chênh lệch",
          dataIndex: "chenhLech",
          key: "chenhLech",
          render: (text, record, index) => (
            <Form.Item
              name={`chenhLech-${index + 1}`}
              style={{ display: "flex", alignItems: "center", marginBottom: 0 }}
            >
              <Input
                style={{ textAlign: "right" }}
                value={form.getFieldValue(`chenhLech-${index + 1}`)}
                readOnly
              />
            </Form.Item>
          ),
          width: "8%",
        },
      ]
    : [
        {
          title: "STT",
          dataIndex: "stt",
          key: "stt",
          render: (text, record, index) => (
            <div className="table-text-right">{index + 1}</div>
          ),
          width: "10%",
        },
        {
          title: "Chủng loại",
          dataIndex: "chungLoai",
          key: "chungLoai",
          render: (text) => <div className="table-text-center">{text}</div>,
          width: "20%",
        },
        {
          title: "Loại công tơ",
          dataIndex: "loaiCongTo",
          key: "loaiCongTo",
          render: (text) => <div className="table-text-left">{text}</div>,
          width: "25%",
        },
        {
          title: "Mô tả",
          dataIndex: "moTa",
          key: "moTa",
          render: (text) => <div className="table-text-left">{text}</div>,
          width: "30%",
        },
        {
          title: "Số lượng yêu cầu",
          dataIndex: "soLuongYeuCau",
          key: "soLuongYeuCau",
          render: (text) => <div className="table-text-right">{text}</div>,
          width: "15%",
        },
      ];

  const handleChangeInput = (e, slYC, key) => {
    // eslint-disable-next-line array-callback-return
    dataVT.map((item, index) => {
      if (index + 1 === key) {
        item[`soLuongCoTheXuat-${key}`] = e.target.value;
        item[`chenhLech-${key}`] =
          e.target.value !== "" ? parseInt(slYC) - parseInt(e.target.value) : 0;
      }
    });
    setDataVT(dataVT);
    form.setFieldsValue({
      [`soLuongCoTheXuat-${key}`]: e.target.value,
      [`chenhLech-${key}`]:
        e.target.value !== "" ? parseInt(slYC) - parseInt(e.target.value) : 0,
    });
  };

  const handleSave = (row) => {
    const newData = [...dataVT];
    const index = newData.findIndex((item) => row.stt === item.stt);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataVT(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  //list data vt lien quan
  const getListDataVTLQ = useCallback(() => {
    handleChiTietPhieuDuyet(
      selectRowKeys,
      setLoading,
      setDataVT,
      setDefaultDataVT,
      setDefaultInfo,
      typeLoaiPhieu,
      form
    );
  }, [form, selectRowKeys, typeLoaiPhieu]);

  useEffect(() => {
    getListDataVTLQ();
  }, [getListDataVTLQ]);

  const findInArray = (value1, value2, arr) => {
    for (let i = 0; i < arr.length; i++) {
      if (parseInt(value1) !== parseInt(value2)) return false;
    }
    return true;
  };

  //submit dữ liệu
  const onFinishSubmit = (values) => {
    const vatTuXuats = [];
    let isValid = false;
    let countSl = 0;
    // eslint-disable-next-line array-callback-return
    dataVT.map((item, index) => {
      if (
        form.getFieldValue(`soLuongCoTheXuat-${index + 1}`) === "" ||
        form.getFieldValue(`soLuongCoTheXuat-${index + 1}`) === null
      ) {
        form.setFields([
          {
            name: [`soLuongCoTheXuat-${index + 1}`],
            errors: ["Không được bỏ trống"],
          },
        ]);
        return (isValid = true);
      }
      if (
        form.getFieldValue(`soLuongCoTheXuat-${index + 1}`) > item.soLuongYeuCau
      ) {
        form.setFields([
          {
            name: [`soLuongCoTheXuat-${index + 1}`],
            errors: ["Phải nhỏ hơn số lượng yêu cầu"],
          },
        ]);
        return (isValid = true);
      }

      if (isTraLai) {
        const find = findInArray(
          item.soLuongYeuCau,
          form.getFieldValue(`soLuongCoTheXuat-${index + 1}`),
          dataVT
        );
        if (find) countSl += 1;
      }
    });

    if (countSl === dataVT.length) {
      dataVT.map((item, index) => {
        form.setFields([
          {
            name: [`soLuongCoTheXuat-${index + 1}`],
            errors: ["Đã đủ vật tư nên không thể từ chối"],
          },
        ]);
        return (isValid = true);
      });
    }

    // eslint-disable-next-line array-callback-return
    dataVT.map((item, index) => {
      if (isTraLai) {
        vatTuXuats.push({
          chungLoaiId: item.chungLoaiId,
          chungLoai: item.chungLoai,
          soLuongYeuCau: item.soLuongYeuCau,
          soLuongCoTheXuat: form.getFieldValue(`soLuongCoTheXuat-${index + 1}`)
            ? parseFloat(form.getFieldValue(`soLuongCoTheXuat-${index + 1}`))
            : null,
        });
      } else {
        vatTuXuats.push({
          chungLoaiId: item.chungLoaiId,
          chungLoai: item.chungLoai,
          soLuongYeuCau: item.soLuongYeuCau,
        });
      }
    });

    let payload = {};
    if (isTraLai) {
      payload = {
        id: selectRowKeys,
        loaiPhieuVatTu: typeLoaiPhieu,
        lyDoTraLai: values.lyDoTraLai,
        vatTuXuats: vatTuXuats,
      };
    } else {
      payload = {
        id: selectRowKeys,
        vatTuXuats: vatTuXuats,
      };
    }
    if (!isValid) {
      // xác nhận trả lại/duyệt phiếu
      callAPIXacNhanPhieu(
        isTraLai,
        typeLoaiPhieu,
        payload,
        setLoading,
        setFetchList,
        form,
        dispatch,
        closeModal
      );
    }
  };

  //change trả lại
  const checkTraLai = (e) => {
    setIsTraLai(e.target.checked);
  };

  return (
    <LayoutPhieuXuat
      loading={loading}
      form={form}
      onFinishSubmit={onFinishSubmit}
      checkTraLai={checkTraLai}
      dataVT={dataVT}
      columns={columns}
      defautInfo={defautInfo}
      setFetchList={setFetchList}
      selectRowKeys={selectRowKeys}
      nguoiTao={nguoiTao}
      dispatch={dispatch}
      isTraLai={isTraLai}
      permissionCodeKySo={permissionCodeKySo}
      components={components}
      maTrangThai={maTrangThai}
      defaultDataVT={defaultDataVT}
    />
  );
}
