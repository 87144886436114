import React, { useCallback, useEffect, useState } from "react";
import { Form, Modal, Input, Switch, Row, Col } from "antd";

import { Endpoint } from "@utils/endpoint";
import Selection from "@components/Select";
import ButtonComponent from "@components/Button";
import styled from "styled-components";
import WarningIcon from "@assets/icon/WarningIcon";
function ModalEditMenuItem(props) {
  const { visible, close, moduleSelected, updateMenuItem, data, dataSource } =
    props;
  const [form] = Form.useForm();
  const [parentIsActive, setParentIsActive] = useState(null);

  useEffect(() => {
    if (data && dataSource) {
      const loop = (data, key, callback) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].permissionCode === key) {
            return callback(data[i].isActive);
          }
          if (data[i].children) {
            loop(data[i].children, key, callback);
          }
        }
      };
      let parentActive;
      loop(dataSource, data.parentKey, (status) => {
        parentActive = status;
      });
      setParentIsActive(parentActive);
    }
  }, [data, dataSource]);
  useEffect(() => {
    if (data && form) {
      form.setFieldsValue({
        isActive: data.isActive,
        menuName: data.name,
        pageId: data.pageId,
      });
    }
  }, [data, form]);
  const onFinish = useCallback(
    (values) => {
      updateMenuItem(values);
      close();
    },
    [updateMenuItem, close]
  );

  return (
    <Modal visible={visible} title="Chỉnh sửa" onCancel={close} footer={false}>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          name="menuName"
          label="Tên menu"
          rules={[
            {
              required: true,
              message: "Tên menu không được để trống",
            },
          ]}
        >
          <Input type="text" size="default" />
        </Form.Item>
        <Form.Item
          name="pageId"
          label="Chọn trang"
          rules={[
            {
              required: true,
              message: "Trang không được để trống",
            },
          ]}
        >
          <Selection
            url={`${Endpoint.GET_PAGE}?moduleId=${moduleSelected}`}
            formKey="pageId"
            form={form}
          />
        </Form.Item>

        <Form.Item name="isActive" label="Trạng thái" valuePropName="checked">
          <Switch
            checkedChildren="Hoạt động"
            unCheckedChildren="Khóa"
            disabled={parentIsActive === false}
          />
        </Form.Item>
        {parentIsActive === false && (
          <WarningWrapper>
            <div className="flex-center">
              <WarningIcon />
            </div>
            <span>
              Không thể kích hoạt trạng thái do menu cấp cao hơn chưa được kích
              hoạt
            </span>
          </WarningWrapper>
        )}
        <Row justify="center" gutter={8}>
          <Col span={12}>
            <ButtonComponent
              className="full"
              type="dash"
              size="large"
              onClick={close}
            >
              Đóng
            </ButtonComponent>
          </Col>
          <Col span={12}>
            <ButtonComponent
              type="primary"
              htmlType="submit"
              size="large"
              className="full"
            >
              Lưu
            </ButtonComponent>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

const WarningWrapper = styled.div`
  margin-bottom: 10px;
  background: #ff5e5e;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
  color: #fff;
  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > span {
    margin-left: 5px;
  }
`;

export default ModalEditMenuItem;
