import React, { Fragment } from "react";
import { Col, Row, Form, Typography } from "antd";
import { ENUM_MODE_PHUONGANCAPDIEN, TYPE_INPUT } from "@utils/constants";
import InputFormatter from "@components/InputFormatter";

export default function ThongTinChungPanels(props) {
  const { form, mode } = props;

  return (
    <Fragment>
      <Col span={24} md={6}>
        <span className="fs-16px font-bold">1. Thông tin chung</span>
      </Col>
      <Col span={24}>
        <Row gutter={24} className="mt-16px">
          <Col flex={"300px"} style={{ marginTop: 6 }}>
            <span className="fs-14px">
              Tổng công suất tác dụng tối đa cấp điện:
            </span>
          </Col>
          <Col flex={"auto"}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tongCongSuatTacDungToiDa !==
                  curValues.tongCongSuatTacDungToiDa
                }
              >
                {({ getFieldValue }) => {
                  const tongCongSuatTacDungToiDa =
                    getFieldValue("tongCongSuatTacDungToiDa") || undefined;
                  return tongCongSuatTacDungToiDa ? (
                    <Typography.Text className="ant-form-text" strong level={5}>
                      {`${tongCongSuatTacDungToiDa}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}{" "}
                      kW
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            ) : (
              <Form.Item name="tongCongSuatTacDungToiDa" form={form}>
                <InputFormatter
                  addonAfter="&nbsp;kW&nbsp;"
                  className="input-number-right"
                  type={TYPE_INPUT.DECIMAL}
                  decimals={2}
                  readOnly={true}
                />
              </Form.Item>
            )}
          </Col>
          <Col flex={"auto"}>
            <span className="fs-13px">
              (theo thực tế kiểm tra trước khi cấp điện).
            </span>
          </Col>
        </Row>
      </Col>

      <Col span={24} md={24}>
        <Row gutter={24}>
          <Col flex={"300px"} style={{ marginTop: 6 }}>
            <span className="fs-14px">
              Tổng công suất phản kháng tối đa cấp điện:
            </span>
          </Col>
          <Col flex={"auto"}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tongCongSuatPhanKhangToiDa !==
                  curValues.tongCongSuatPhanKhangToiDa
                }
              >
                {({ getFieldValue }) => {
                  const tongCongSuatPhanKhangToiDa =
                    getFieldValue("tongCongSuatPhanKhangToiDa") || undefined;
                  return tongCongSuatPhanKhangToiDa ? (
                    <Typography.Text className="ant-form-text" strong level={5}>
                      {tongCongSuatPhanKhangToiDa} kVAR
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            ) : (
              <Form.Item name="tongCongSuatPhanKhangToiDa">
                <InputFormatter
                  addonAfter="kVAr"
                  className="input-number-right"
                  disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                  type={TYPE_INPUT.INTEGER}
                />
              </Form.Item>
            )}
          </Col>
          <Col flex={"auto"}>
            <span className="fs-13px">
              (theo thực tế kiểm tra trước khi cấp điện).
            </span>
          </Col>
        </Row>
      </Col>
    </Fragment>
  );
}
