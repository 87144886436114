import { Checkbox, Col, Form, Row } from "antd";
import { Fragment } from "react";

export default function ThongTinTUTIDangTreo(props) {
  const {
    form,
    handleChange,
    isTU,
    isThao,
    data,
    isAll,
    handleCheckAll,
    renderTable,
  } = props;
  return (
    <Fragment>
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item
            name={isTU ? "isThaoTU" : "isThaoTI"}
            style={{ margin: 0 }}
          >
            <Checkbox
              onChange={(e) => handleChange(e, 1)}
              checked={isThao}
              disabled={!data.length}
              style={{ margin: 0 }}
            >
              {isTU ? "Tháo TU" : "Tháo TI"}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={3}>
          {form.getFieldValue("loaiBienBan") === "2" ? null : (
            <Form.Item name="checkAll">
              <Checkbox
                defaultChecked={isAll}
                checked={isAll}
                onChange={handleCheckAll}
                disabled={!data.length}
                style={{ margin: 0 }}
              >
                Cháy/Hỏng
              </Checkbox>
            </Form.Item>
          )}
        </Col>
        <Col span={24}>{renderTable()}</Col>
      </Row>
    </Fragment>
  );
}
