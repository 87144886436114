import FormComponent from "@components/Form";
import TableComponent from "@components/TableComponent";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  formatDateWithTimezone,
  parseParams,
} from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  Spin,
} from "antd";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { selectBCTongHop } from "./service";

export default function BieuThslCaiDatApp(props) {
  const { screen, handleChange } = props;
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [form] = Form.useForm();
  const [filterConditions, setFilterConditions] = useState({
    ...parseParams(location.search),
    Thang: formatDateWithTimezone(`${moment(moment()._d)}`).split("-")[1],
    Nam: formatDateWithTimezone(`${moment(moment()._d)}`).split("-")[0],
  });

  const onFinish = () => {
    const queryString = buildQueryString(
      parseParams({
        Thang: filterConditions?.Thang,
        Nam: filterConditions?.Nam,
      })
    );
    authGetData({
      url: `${Endpoint.BCTH_TONG_SO_KH_CAI_APP_THEO_HDH}?${queryString}`,
      setLoading,
      onSuccess: (res) => {
        if (res) {
          console.log(res);
          setDataTable(res.data);
        }
      },
    });
  };

  const onDowloadExcel = () => {
    const queryString = buildQueryString(
      parseParams({
        Thang: filterConditions.Thang,
        Nam: filterConditions.Nam,
      })
    );
    const endpoint = `${Endpoint.BCTH_TONG_SO_KH_CAI_APP_THEO_HDH_EXCEL}?${queryString}`;
    if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
  };

  const onChangeThangNam = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      Thang: formatDateWithTimezone(e?._d)?.split("-")[1],
      Nam: formatDateWithTimezone(e?._d)?.split("-")[0],
    }));
  };

  const columns = [
    {
      title: "Hệ điều hành Android",
      dataIndex: "android",
      key: "hdhandroid",
      align: "center",
    },
    {
      title: "Hệ điều hành IOS",
      dataIndex: "ios",
      align: "center",
      key: "hdhios",
    },
    {
      title: "Tổng",
      dataIndex: "tong",
      align: "center",
      key: "tong",
    },
    {
      title: "Tổng phát sinh trong tháng",
      align: "center",
      dataIndex: "pS_THANG",
      key: "tongphatsinhtrongthang",
    },
    {
      title: "Tổng số khách hàng cài Zalo",
      dataIndex: "tonG_ZALO",
      align: "center",
      key: "hdhandroid",
    },
    {
      title: "Tổng phát sinh Zalo trong tháng",
      dataIndex: "thanG_ZALO",
      align: "center",
      key: "tongphatsinhzalo",
    },
  ];

  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              initialValues={{ reportType: screen }}
              form={form}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="reportType"
                    label="Tên báo cáo"
                    labelAlign="left"
                  >
                    <Row>
                      <Col span={24} pull="8">
                        <Select
                          defaultValue={screen}
                          placeholder="Chọn báo cáo"
                          options={selectBCTongHop}
                          onChange={(e) => handleChange(e)}
                        ></Select>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="reportMonth"
                    label="Tháng năm"
                    labelAlign="left"
                  >
                    <Row>
                      <Col span={20} pull={4}>
                        <DatePicker
                          picker="month"
                          formKey="reportMonth"
                          form={form}
                          onChange={onChangeThangNam}
                          format="MM/YYYY"
                          defaultValue={moment()}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
                <Col span={4} push={1}>
                  <Form.Item>
                    <Button type="primary" htmlType="summit" className="full">
                      Xem báo cáo
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={onDowloadExcel}
                      className="full"
                    >
                      Xuất Excel
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </FormComponent>
            <Divider className="section-text-line" orientation="left"></Divider>
            {dataTable && dataTable.length > 0 ? (
              <TableComponent
                header={"Kết quả hiển thị"}
                columns={columns}
                dataSource={dataTable}
              />
            ) : (
              <TableComponent
                header={"Kết quả hiển thị"}
                columns={columns}
                dataSource={null}
              />
            )}
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
