import CheckStatus from "@components/CheckStatus";
import {
  LITMIT_TRANGTHAI,
  MATRIX_CAP_DIEN,
  SEQUENCE_TRANGTHAI,
} from "@utils/constants";
import { Menu } from "antd";
import ModalCapNhatHoSo from "../../common-ho-so/cap-nhat";
import TaiAnhHoSoKhachHang from "../../common-ho-so/tai-anh-ho-so-khach-hang";
import { cap_dien } from "@permissions/a_capdien";
import CheckPermission from "@components/CheckPermission";
import XemLaiBBLapDatComponent from "@modules/cap-dien/common-ho-so/xem-bien-ban-lap-dat";

const { SubMenu } = Menu;

export default function ListMenu(props) {
  const {
    t,
    dispatch,
    openModal,
    detail,
    firstDonVi,
    setFetchDataTable,
    closeModal,
    handleOpenModalPhanCong,
    handleOpenModalChuyenApGia,
    handleOpenModalChuyenDonViKhac,
    handleOpenModalInHoSoCMIS,
    handleOpenModalXemLaiPhuongAnCapDien,
    handleOpenModalQuyetToanKhachHang,
    handleOpenModalQuyetToanCongTy,
    handleOpenModalInBieuKhaiGia,
    inBienBanApGia,
    handleOpenInPhuTai,
  } = props;
  return (
    <Menu mode="vertical" style={{ width: "100%" }} selectedKeys={["0"]}>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.CAPNHAT}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.HOSODAPHANCONG[1]}
        permissionCode={cap_dien.a11_sua}
      >
        <Menu.Item
          eventKey="1"
          onClick={() =>
            dispatch(
              openModal({
                content: (
                  <ModalCapNhatHoSo
                    detail={detail}
                    // detailApGia={row}
                    // handleUpdate={handleUpdate}

                    firstDataDonVi={firstDonVi}
                    setFetchDataTable={setFetchDataTable}
                    closeModal={closeModal}
                  />
                ),
                size: "large",
                title: "Cập nhật hồ sơ đã phân công",
              })
            )
          }
        >
          {t("common_cap_dien.cap_nhat")}
        </Menu.Item>
      </CheckStatus>

      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.PHANCONG}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.HOSODAPHANCONG[1]}
        permissionCode={cap_dien.a11_phan_cong}
      >
        <Menu.Item eventKey="2" onClick={handleOpenModalPhanCong}>
          {t("common_cap_dien.phan_cong")}
        </Menu.Item>
      </CheckStatus>

      {/* <Menu.Item eventKey="3" onClick={handleOpenModalKhaoSatLai}>
      {t("giao_dich_vien.quan_ly_ho_so_co_tro_ngai.khao_sat_lai")}
    </Menu.Item> */}
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.XACNHANCHUYENAPGIA}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.HOSODAPHANCONG[1]}
        permissionCode={cap_dien.a11_xn_chuyen_ap_gia}
      >
        <Menu.Item eventKey="4" onClick={handleOpenModalChuyenApGia}>
          {t("common_cap_dien.xac_nhan_chuyen_ap_gia")}
        </Menu.Item>
      </CheckStatus>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.TAIANH}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.HOSODAPHANCONG[1]}
        permissionCode={cap_dien.a11_tai_anh_hskh}
      >
        <Menu.Item
          eventKey="5"
          onClick={() =>
            dispatch(
              openModal({
                content: (
                  <TaiAnhHoSoKhachHang
                    closeModal={closeModal}
                    detail={detail}
                  />
                ),
                size: "large",
              })
            )
          }
        >
          {" "}
          {t("common_cap_dien.tai_anh_ho_so_khach_hang")}
        </Menu.Item>
      </CheckStatus>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.CHUYENDONVI}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.HOSODAPHANCONG[1]}
        permissionCode={cap_dien.a11_chuyen_don_vi}
      >
        <Menu.Item eventKey="6" onClick={handleOpenModalChuyenDonViKhac}>
          {t("common_cap_dien.chuyen_don_vi_khac")}
        </Menu.Item>
      </CheckStatus>
      <CheckPermission permissionCode={cap_dien.a11_in_hs_cmis}>
        <Menu.Item eventKey="8" onClick={handleOpenModalInHoSoCMIS}>
          {" "}
          {t("common_cap_dien.in_ho_so_cmis")}
        </Menu.Item>
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a11_in_hs_web}>
        {Number(detail.maTrangThai) >= LITMIT_TRANGTHAI ? (
          <SubMenu eventKey="sub1" title={t("common_cap_dien.in_ho_so_web")}>
            <Menu.Item
              eventKey="9"
              onClick={handleOpenModalXemLaiPhuongAnCapDien}
            >
              {" "}
              {t("common_cap_dien.xem_lai_phuong_an_cap_dien")}
            </Menu.Item>
            <XemLaiBBLapDatComponent idHoSo={detail?.id ?? detail?.hoSoId} />
            <Menu.Item eventKey="20" onClick={handleOpenInPhuTai}>
              In biểu đồ phụ tải
            </Menu.Item>
            <Menu.Item
              eventKey="10"
              onClick={handleOpenModalQuyetToanKhachHang}
            >
              {" "}
              {t("common_cap_dien.quyet_toan_khach_hang")}
            </Menu.Item>
            <Menu.Item eventKey="11" onClick={handleOpenModalQuyetToanCongTy}>
              {" "}
              {t("common_cap_dien.quyet_toan_cong_ty")}
            </Menu.Item>
            <Menu.Item eventKey="12" onClick={handleOpenModalInBieuKhaiGia}>
              {" "}
              {t("common_cap_dien.in_bieu_khai_gia")}
            </Menu.Item>
            <Menu.Item eventKey="13" onClick={inBienBanApGia}>
              {" "}
              {t("common_cap_dien.in_bien_ban_ap_gia")}
            </Menu.Item>
            {/* <Menu.Item eventKey="12">
        {" "}
        {t("common_cap_dien.in_phieu_tiep_nhan_thong_tin_de_nghi_mua_dien")}
      </Menu.Item> */}
          </SubMenu>
        ) : null}
      </CheckPermission>
    </Menu>
  );
}
