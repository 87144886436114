import { Checkbox, Col, Form, Row } from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";

export default function Index(props) {
  const { form, detail, isTU, setDataTITreo } = props;

  const [data, setData] = useState(detail && detail.tutIs ? detail.tutIs : []);
  const [isThao, setIsThao] = useState(detail?.isThao);
  const [isAll, setIsAll] = useState(false);

  //get set form value
  const getSetForm = useCallback(
    (e) => {
      if (isTU)
        form.setFieldsValue({
          isThaoTU: e.target.checked,
        });
      else
        form.setFieldsValue({
          isThaoTI: e.target.checked,
        });
    },
    [form, isTU]
  );

  //check all cháy/hỏng
  const handleCheckAll = useCallback(
    (e) => {
      const arr = [...data];
      setIsAll(e.target.checked);
      if (e.target.checked) setIsThao(true);
      else setIsThao(false);
      getSetForm(e);
      for (let i = 0; i < data.length; i++) {
        arr[i].isChayHong = e.target.checked;

        if (isTU)
          form.setFieldsValue({
            [`chayHongTU-${i}`]: e.target.checked,
          });
        else
          form.setFieldsValue({
            [`chayHongTI-${i}`]: e.target.checked,
          });
      }
      setData(arr);
    },
    [data, form, getSetForm, isTU]
  );

  useEffect(() => {
    const newData = [];
    let checkAll = false;
    const arrBoolean = [];
    data.forEach((item) => {
      arrBoolean.push(item.isChayHong ? item.isChayHong : false);
      newData.push({
        ...item,
      });
    });
    const filterData = arrBoolean.filter((el) => el !== false);
    if (filterData.length === data.length && data.length > 0) checkAll = true;
    if (filterData.length > 0) {
      setIsThao(true);
      const e = {
        target: {
          checked: true,
        },
      };
      getSetForm(e);
    } else {
      const e = {
        target: {
          checked: false,
        },
      };
      getSetForm(e);
      setIsThao(false);
    }

    form.setFieldsValue({
      checkAll: checkAll,
    });
    setIsAll(checkAll);
    setData(newData);
    data.forEach((el, index) => {
      if (isTU)
        form.setFieldsValue({
          [`chayHongTU-${index}`]: el.isChayHong,
        });
      else
        form.setFieldsValue({
          [`chayHongTI-${index}`]: el.isChayHong,
        });
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //check cháy/hỏng
  const handleChecked = useCallback(
    (e, viTri) => {
      const arr = [...data];
      arr[viTri].isChayHong = e.target.checked;
      if (isTU) {
        form.setFieldsValue({
          [`chayHongTU-${viTri}`]: e.target.checked,
        });
      } else {
        form.setFieldsValue({
          [`chayHongTI-${viTri}`]: e.target.checked,
        });
      }
      const dataTrue = arr.filter((el) => el.isChayHong === true);
      if (dataTrue.length === arr.length) setIsAll(true);
      else setIsAll(false);
      if (dataTrue.length > 0) setIsThao(true);
      else setIsThao(false);
      getSetForm(e);
      setData(arr);
    },
    [data, form, getSetForm, isTU]
  );

  //change is tháo
  const handleChange = (e, viTri) => {
    if (viTri === 1) {
      setIsThao(e.target.checked);
      getSetForm(e);
    }
  };

  //render table
  const renderTable = useCallback(() => {
    return (
      <Row
        gutter={24}
        style={{
          borderRadius: "4px",
        }}
      >
        <Col span={5} style={{ marginBottom: "8px", paddingRight: 0 }}>
          <div
            style={{
              textAlign: "center",
              borderRight: "1px solid #fff",
              background: "#D0E0E3",
            }}
          >
            {isTU ? "Mã TU" : "Mã TI"}
          </div>
        </Col>
        <Col span={3} style={{ marginBottom: "8px", padding: 0 }}>
          <div
            style={{
              textAlign: "center",
              borderRight: "1px solid #fff",
              background: "#D0E0E3",
            }}
          >
            Cháy/Hỏng
          </div>
        </Col>
        <Col span={4} style={{ marginBottom: "8px", padding: 0 }}>
          <div
            style={{
              textAlign: "center",
              borderRight: "1px solid #fff",
              background: "#D0E0E3",
            }}
          >
            Số pha
          </div>
        </Col>
        <Col span={4} style={{ marginBottom: "8px", padding: 0 }}>
          <div
            style={{
              textAlign: "center",
              borderRight: "1px solid #fff",
              background: "#D0E0E3",
            }}
          >
            Tỷ số đấu
          </div>
        </Col>
        <Col span={4} style={{ marginBottom: "8px", padding: 0 }}>
          <div
            style={{
              textAlign: "center",
              borderRight: "1px solid #fff",
              background: "#D0E0E3",
            }}
          >
            Ngày kiểm định
          </div>
        </Col>
        <Col span={4} style={{ marginBottom: "8px", paddingLeft: 0 }}>
          <div
            style={{
              textAlign: "center",
              borderRight: "1px solid #fff",
              background: "#D0E0E3",
            }}
          >
            Tem kiểm định
          </div>
        </Col>
        {data && data.length > 0
          ? data.map((item, index) => (
              <Fragment key={index}>
                <Col span={5}>
                  <div style={{ padding: "8px" }}>{item.maThietBi}</div>
                </Col>
                <Col span={3}>
                  <div style={{ textAlign: "center", padding: "8px" }}>
                    <Form.Item
                      style={{ margin: 0 }}
                      name={
                        isTU ? `chayHongTU-${index}` : `chayHongTI-${index}`
                      }
                    >
                      <Checkbox
                        defaultChecked={item.isChayHong}
                        checked={item.isChayHong}
                        onChange={(e) => handleChecked(e, index)}
                        style={{ margin: 0 }}
                        disabled={
                          form.getFieldValue("loaiBienBan") === "2"
                            ? true
                            : false
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={4}>
                  <div style={{ textAlign: "right", padding: "8px" }}>
                    {item.soPha}
                  </div>
                </Col>
                <Col span={4}>
                  <div style={{ padding: "8px" }}>{item.tySoDau}</div>
                </Col>
                <Col span={4}>
                  <div style={{ textAlign: "center", padding: "8px" }}>
                    {item.ngayKiemDinh}
                  </div>
                </Col>
                <Col span={4}>
                  <div style={{ textAlign: "center", padding: "8px" }}>
                    {item.seryTemKiemDinh}
                  </div>
                </Col>
              </Fragment>
            ))
          : null}
      </Row>
    );
  }, [data, handleChecked, isTU, form]);

  useEffect(() => {
    if (setDataTITreo) setDataTITreo(data);
  }, [data, setDataTITreo]);
  return (
    <Fragment>
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item
            name={isTU ? "isThaoTU" : "isThaoTI"}
            style={{ margin: 0 }}
          >
            <Checkbox
              onChange={(e) => handleChange(e, 1)}
              checked={isThao}
              disabled={!data.length}
              style={{ margin: 0 }}
            >
              {isTU ? "Tháo TU" : "Tháo TI"}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={3}>
          {form.getFieldValue("loaiBienBan") === "2" ? null : (
            <Form.Item name="checkAll">
              <Checkbox
                defaultChecked={isAll}
                checked={isAll}
                onChange={handleCheckAll}
                disabled={!data.length}
                style={{ margin: 0 }}
              >
                Cháy/Hỏng
              </Checkbox>
            </Form.Item>
          )}
        </Col>
        <Col span={24}>{renderTable()}</Col>
      </Row>
    </Fragment>
  );
}
