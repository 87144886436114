import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { handleChuyenApGia } from "../../services";
import ApGiaModal from "./modal-ap-gia";

function Index({ visible, handleCloseModal, setFetchDataTable, detail }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = () => {
    handleChuyenApGia(
      t,
      setLoading,
      handleCloseModal,
      setFetchDataTable,
      detail.id
    );
  };

  return (
    <ApGiaModal
      t={t}
      loading={loading}
      visible={visible}
      handleCloseModal={handleCloseModal}
      handleSubmit={handleSubmit}
    />
  );
}

export default Index;
