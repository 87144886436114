import {
  alertMessage,
  buildQueryString,
  handlePagination,
  // handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { handleListBaoCao } from "./services";
import BaoCaoTongHopVTTable from "./table";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import { Table } from "antd";
import Text from "antd/lib/typography/Text";
import {
  ENUM_BUTTON_TYPE,
  ENUM_LOAIBAOCAO_VATTU,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import moment from "moment";
import { Endpoint } from "@utils/endpoint";
import { downloadFile } from "@utils/request";

const columnsKH = [
  {
    title: "STT",
    dataIndex: "stt",
    key: "stt",
    render: (text, record, index) => (
      <div className="table-text-right">
        <span>{index + 1}</span>
      </div>
    ),
    width: "8%",
  },
  {
    title: "Đơn vị",
    dataIndex: "maDonViOrDoi",
    key: "maDonViOrDoi",
    render: (text) => (
      <div className="table-text-left">
        <span>{text}</span>
      </div>
    ),
    // width: "20%",
    sorter: true,
  },
  {
    title: "Vật tư có sẵn",
    dataIndex: "",
    key: "",
    children: [
      {
        title: "Dây cáp",
        dataIndex: "vtcsDayCap",
        key: "vtcsDayCap",
        render: (text) => (
          <div className="table-text-right">
            <span>{text}</span>
          </div>
        ),
        width: "12%",
        sorter: true,
      },
      {
        title: "Công tơ",
        dataIndex: "vtcsCongTo",
        key: "vtcsCongTo",
        render: (text) => (
          <div className="table-text-right">
            <span>{text}</span>
          </div>
        ),
        width: "12%",
        sorter: true,
      },
      {
        title: "Hòm",
        dataIndex: "vtcsHom",
        key: "vtcsHom",
        render: (text) => (
          <div className="table-text-right">
            <span>{text}</span>
          </div>
        ),
        width: "12%",
        sorter: true,
      },
      {
        title: "Vật tư phụ",
        dataIndex: "vtcsVatTuPhu",
        key: "vtcsVatTuPhu",
        render: (text) => (
          <div className="table-text-right">
            <span>{text}</span>
          </div>
        ),
        width: "12%",
        sorter: true,
      },
    ],
  },
  {
    title: "Vật tư mua ngoài",
    dataIndex: "",
    key: "",
    children: [
      {
        title: "Dây cáp",
        dataIndex: "vtmnDayCap",
        key: "vtmnDayCap",
        render: (text) => (
          <div className="table-text-right">
            <span>{text}</span>
          </div>
        ),
        width: "12%",
        sorter: true,
      },
      {
        title: "Vật tư phụ",
        dataIndex: "vtmnVatTuPhu",
        key: "vtmnVatTuPhu",
        render: (text) => (
          <div className="table-text-right">
            <span>{text}</span>
          </div>
        ),
        width: "12%",
        sorter: true,
      },
    ],
  },
];

const columnCongTy = [
  {
    title: "STT",
    dataIndex: "stt",
    key: "stt",
    render: (text) => (
      <div className="table-text-center">
        <span>{text}</span>
      </div>
    ),
    width: "10%",
  },
  {
    title: "Đơn vị",
    dataIndex: "maDonViOrDoi",
    key: "maDonViOrDoi",
    render: (text) => (
      <div className="table-text-left">
        <span>{text}</span>
      </div>
    ),
    width: "30%",
    sorter: true,
  },
  {
    title: "Dây cáp",
    dataIndex: "vtcsDayCap",
    key: "vtcsDayCap",
    render: (text) => (
      <div className="table-text-right">
        <span>{text}</span>
      </div>
    ),
    width: "15%",
    sorter: true,
  },
  {
    title: "Công tơ",
    dataIndex: "vtcsCongTo",
    key: "vtcsCongTo",
    render: (text) => (
      <div className="table-text-right">
        <span>{text}</span>
      </div>
    ),
    width: "15%",
    sorter: true,
  },
  {
    title: "Hòm",
    dataIndex: "vtcsHom",
    key: "vtcsHom",
    render: (text) => (
      <div className="table-text-right">
        <span>{text}</span>
      </div>
    ),
    width: "15%",
    sorter: true,
  },
  {
    title: "Vật tư phụ",
    dataIndex: "vtcsVatTuPhu",
    key: "vtcsVatTuPhu",
    render: (text) => (
      <div className="table-text-right">
        <span>{text}</span>
      </div>
    ),
    width: "15%",
    sorter: true,
  },
];

export default function Index() {
  const { user } = useSelector(authSelector);
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [, setTotalCount] = useState(0);
  const [isCompany, setIsCompany] = useState(user.isTongCongTy);
  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);
  // const { user } = useSelector(authSelector);

  //filter
  const [filterConditions, setFilterConditions] = useState({
    // pageIndex: DEFAULT_PAGEINDEX,
    // pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    donViId: user.unitId,
    loaiBaoCao: ENUM_LOAIBAOCAO_VATTU.VATTUKH,
    ngayBatDau: moment(moment().startOf(MONTH_STRING)).format(FORMAT_ENGLISH),
    ngayKetThuc: moment().format(FORMAT_ENGLISH),
  });

  const [newColumns, setNewColumns] = useState([]);

  const getColumns = useCallback((loaiBaoCao) => {
    if (loaiBaoCao !== ENUM_LOAIBAOCAO_VATTU.VATTUKH)
      setNewColumns(columnCongTy);
    else setNewColumns(columnsKH);
  }, []);

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //change pagination
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  };

  //fetch báo cáo
  const getListBaoCao = useCallback(() => {
    handleListBaoCao(
      filterConditions,
      setLoading,
      setData,
      setTotalCount,
      setIsCompany
    );
  }, [filterConditions]);

  const [filterExcel, setFilterExcel] = useState(false);

  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type]);

  //search
  const handleSearch = useCallback(
    (values) => {
      if (values.ngayBatDau <= values.ngayKetThuc) {
        setFilterConditions(() => ({
          //...oldState,
          ...values,
          //donViId: values.donViId,
        }));
        getColumns(values.loaiBaoCao);
        handleAPI();
      } else {
        alertMessage("error", "Thông báo", "Khoảng ngày báo cáo không hợp lệ");
      }
    },
    [getColumns, handleAPI]
  );

  //clear search
  const clearFilter = useCallback(() => {
    setFilterConditions({
      // donViId: user.unitId,
      loaiBaoCao: ENUM_LOAIBAOCAO_VATTU.VATTUKH,
      ngayBatDau: moment(moment().startOf(MONTH_STRING)).format(FORMAT_ENGLISH),
      ngayKetThuc: moment().format(FORMAT_ENGLISH),
    });
    getColumns(ENUM_LOAIBAOCAO_VATTU.VATTUKH);
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  }, [getColumns]);

  useEffect(() => {
    if (filterExcel) {
      const endpoint = `${
        Endpoint.EXPORT_EXCEL_SO_LUONG_VAT_TU
      }?${buildQueryString(parseParams(filterConditions))}`;
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel]);

  useEffect(() => {
    if (type === ENUM_BUTTON_TYPE.TIM_KIEM) getListBaoCao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListBaoCao]);

  useEffect(() => {
    getColumns(ENUM_LOAIBAOCAO_VATTU.VATTUKH);
  }, [getColumns]);

  //footer total count table
  const countTotals = (pageData) => {
    let countVTCSDayCap = 0;
    let countVTCSCongTo = 0;
    let countVTCSHom = 0;
    let countVTCSVTP = 0;
    let countVTMNDayCap = 0;
    let countVTMNVTP = 0;
    pageData.forEach(
      ({
        vtcsDayCap,
        vtcsCongTo,
        vtcsHom,
        vtcsVatTuPhu,
        vtmnDayCap,
        vtmnVatTuPhu,
      }) => {
        countVTCSDayCap += parseFloat(vtcsDayCap);
        countVTCSCongTo += parseFloat(vtcsCongTo);
        countVTCSHom += parseFloat(vtcsHom);
        countVTCSVTP += parseFloat(vtcsVatTuPhu);
        countVTMNDayCap += parseFloat(vtmnDayCap);
        countVTMNVTP += parseFloat(vtmnVatTuPhu);
      }
    );
    return (
      <Table.Summary fixed>
        <Table.Summary.Row style={{ backgroundColor: "#FFEAEA" }}>
          <Table.Summary.Cell align="center" colSpan={2} index={0}>
            <Text className="font-bold">Tổng cộng</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={1}>
            <Text className="font-bold">{countVTCSDayCap}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={2}>
            <Text className="font-bold">{countVTCSCongTo}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={3}>
            <Text className="font-bold">{countVTCSHom}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={4}>
            <Text className="font-bold">{countVTCSVTP}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={5}>
            <Text className="font-bold">{countVTMNDayCap}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={6}>
            <Text className="font-bold">{countVTMNVTP}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <BaoCaoTongHopVTTable
      handleSearch={handleSearch}
      clearFilter={clearFilter}
      filterConditions={filterConditions}
      loading={loading}
      columns={newColumns}
      data={data}
      user={user}
      countTotals={countTotals}
      onChangePagination={onChangePagination}
      isCompany={isCompany}
      setType={setType}
    />
  );
}
