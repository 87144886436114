import { memo, useCallback, useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FORMAT_DATE, FORMAT_ENGLISH } from "@utils/constants";
import "moment/locale/vi";

import UITaoVatTu from "./popup-tao-vat-tu";
import { handleSubmitCreateData } from "../services";

function TaoVatTuModal({
  getListData,
  dataHinhThuc,
  dataLoaiChiPhi,
  dataChungLoai,
  dataLoaiYC,
  dataHinhThucNone,
  tableColums,
  nameFields,
  closeModal,
  countYC,
  dispatch,
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const toDay = moment().format(FORMAT_DATE);
  const [loading, setLoading] = useState(false);
  const [ngayBatDau, setNgayBatDau] = useState(toDay);
  const [ngayKetThuc, setNgayKetThuc] = useState(toDay);
  const [many, setMany] = useState(false);
  const [vatTuChinh, setVatTuChinh] = useState(false);
  const [keyData, setKeyData] = useState([1]);
  const [dataSource, setDataSource] = useState([
    {
      key: 1,
    },
  ]);
  const [count, setCount] = useState(1);
  const onFinish = (values) => {
    //call api tạo vật tư
    handleSubmitCreateData(
      values,
      setLoading,
      getListData,
      closeModal,
      form,
      vatTuChinh,
      many,
      ngayBatDau,
      ngayKetThuc,
      dataSource,
      dataLoaiYC,
      dataHinhThucNone,
      nameFields,
      countYC,
      dispatch,
      keyData
    );
  };

  //change ngày hiệu lực
  const handleChangeBatDau = (date) => {
    let convert = "";
    if (date === null) convert = null;
    else convert = moment(date).format(FORMAT_ENGLISH);
    setNgayBatDau(convert);
  };

  //change ngày hết hiệu lực
  const handleChangeKetThuc = (date) => {
    let convert = "";
    if (date === null) convert = null;
    else convert = moment(date).format(FORMAT_ENGLISH);
    setNgayKetThuc(convert);
  };

  //check nhiều hình thức
  const handleChangeMany = (e) => {
    setMany(e.target.checked);
  };

  //check vật tư chính
  const handleChangeVatTuChinh = (e) => {
    setVatTuChinh(e.target.checked);
  };

  //add row table
  const handleAdd = useCallback(() => {
    const countKey = count + 1;
    setCount(countKey);
    const newData = {
      key: countKey,
    };
    setDataSource((oldState) => [...oldState, newData]);
  }, [count]);

  //remove row table
  const handleRemove = useCallback(
    (key) => {
      const arr = [...dataSource];
      const keys = [];
      const currentColumns = arr.filter((item) => {
        return item.key !== key;
      });

      currentColumns.forEach((item) => {
        return keys.push(item.key);
      });
      setKeyData(keys);
      setDataSource(currentColumns);
    },
    [dataSource]
  );
  return (
    <UITaoVatTu
      loading={loading}
      onFinish={onFinish}
      ngayBatDau={ngayBatDau}
      ngayKetThuc={ngayKetThuc}
      vatTuChinh={vatTuChinh}
      form={form}
      handleChangeVatTuChinh={handleChangeVatTuChinh}
      handleChangeMany={handleChangeMany}
      handleChangeBatDau={handleChangeBatDau}
      handleChangeKetThuc={handleChangeKetThuc}
      many={many}
      // column={column}
      // columns={columns}
      dataSource={dataSource}
      handleAdd={handleAdd}
      t={t}
      dataLoaiChiPhi={dataLoaiChiPhi}
      dataChungLoai={dataChungLoai}
      dataHinhThuc={dataHinhThuc}
      tableColums={tableColums}
      nameFields={nameFields}
      handleRemove={handleRemove}
      setKeyData={setKeyData}
    />
  );
}

export default memo(TaoVatTuModal);
