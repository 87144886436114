import { Col, Form, Row, Select, Button } from "antd";
import DatePickerComponent from "@components/DatePicker";
import {
  ENUM_BUTTON_TYPE,
  FORMAT_DATE,
  FORMAT_ENGLISH,
  FORMAT_MONTH,
  MONTH_STRING,
  LOAIBAOCAO,
} from "@utils/constants";
import moment from "moment";
import FormComponent from "@components/Form";
import { cap_dien } from "@permissions/a_capdien";
import { removeAccents } from "@utils/function";
import CheckPermission from "@components/CheckPermission";

const { Option } = Select;

export default function FormBoLoc(props) {
  const {
    form,
    handleChangeUnit,
    handleSearch,
    user,
    filterConditions,
    donVi,
    clear,
    // getListBaoCao,
    setType,
    disabledChot,
    value,
    handleChangeValue,
  } = props;

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
      }}
      autoComplete="off"
    >
      <Row gutter={24}>
        <Col span={24} md={10}>
          <Form.Item name="donViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Select
                onChange={handleChangeUnit}
                showSearch
                filterOption={(input, option) => {
                  if (option && option.children) {
                    return (
                      removeAccents(option.children)
                        .toLowerCase()
                        .indexOf(removeAccents(input).toLowerCase()) >= 0
                    );
                  }
                }}
              >
                {donVi && donVi.length > 0
                  ? donVi.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                  : null}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={value === 4 ? 3 : 4}>
          <Form.Item name="loaiBaoCao" label="Loại báo cáo">
            <Select onChange={handleChangeValue}>
              {LOAIBAOCAO && LOAIBAOCAO.length > 0
                ? LOAIBAOCAO.filter(
                    (data) => data.value !== 2 && data.value !== 3
                  ).map((item, index) => (
                    <Option key={index} value={item.value}>
                      {item.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        {value === 1 ? (
          <Col span={24} md={4}>
            <Form.Item name="thangBaoCao" label="Tháng báo cáo">
              <DatePickerComponent
                defaultValue={
                  filterConditions.thangBaoCao
                    ? moment(filterConditions.thangBaoCao, FORMAT_ENGLISH)
                    : moment(moment(), FORMAT_MONTH)
                }
                format={FORMAT_ENGLISH}
                form={form}
                formKey={"thangBaoCao"}
                picker="month"
                notClear={true}
              />
            </Form.Item>
          </Col>
        ) : (
          <>
            <Col span={24} md={5}>
              <Form.Item name="startDateBaoCao" label="Từ ngày">
                <DatePickerComponent
                  defaultValue={
                    filterConditions.startDateBaoCao
                      ? moment(filterConditions.startDateBaoCao, FORMAT_ENGLISH)
                      : moment(moment().startOf(MONTH_STRING), FORMAT_DATE)
                  }
                  format={FORMAT_ENGLISH}
                  form={form}
                  formKey={"startDateBaoCao"}
                  // picker="date"
                  notClear={true}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={5}>
              <Form.Item name="endDateBaoCao" label="Đến ngày">
                <DatePickerComponent
                  defaultValue={
                    filterConditions.endDateBaoCao
                      ? moment(filterConditions.endDateBaoCao, FORMAT_ENGLISH)
                      : moment(moment(), FORMAT_DATE)
                  }
                  format={FORMAT_ENGLISH}
                  form={form}
                  formKey={"endDateBaoCao"}
                  // picker="date"
                  notClear={true}
                />
              </Form.Item>
            </Col>
          </>
        )}

        <Col
          span={24}
          md={24}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button className="mr-24px" onClick={clear}>
            Bỏ lọc
          </Button>
          <Button
            type="primary"
            className="mr-24px"
            htmlType="submit"
            form="filter-form"
            onClick={() => setType(ENUM_BUTTON_TYPE.TIM_KIEM)}
          >
            Tìm kiếm
          </Button>
          <CheckPermission permissionCode={cap_dien.a508_chot}>
            <Button
              type="primary"
              className="mr-24px"
              htmlType="submit"
              form="filter-form"
              onClick={() => setType(ENUM_BUTTON_TYPE.CHOT_DU_LIEU)}
              disabled={disabledChot}
            >
              Chốt dữ liệu
            </Button>
          </CheckPermission>

          {user.isTongCongTy ? (
            <CheckPermission permissionCode={cap_dien.a508_huy}>
              <Button
                type="primary"
                className="mr-24px"
                htmlType="submit"
                form="filter-form"
                onClick={() => setType(ENUM_BUTTON_TYPE.HUY_CHOT_DU_LIEU)}
                disabled={!disabledChot}
              >
                Hủy chốt dữ liệu
              </Button>
            </CheckPermission>
          ) : null}
        </Col>
      </Row>
    </FormComponent>
  );
}
