import {
  buildQueryString,
  getErrorMessage,
  parseParams,
} from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import {
  authDeleteData,
  authGetData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";
import {
  METHOD_POST,
  METHOD_PUT,
  STATUSCODE_200,
  TYPE_CHI_PHI,
} from "@utils/constants";

export const handleListBaoCao = (
  setLoading,
  filterConditions,
  setData,
  setTotalCount,
  setIsChot
) => {
  authGetData({
    url:
      Endpoint.GET_LIST_BAO_CAO_89_160 +
      "?" +
      buildQueryString(parseParams(filterConditions)),
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setData(res.data.data);
        setTotalCount(res.paging.totalCount);
        setIsChot(res.data.isChot);
      }
    },
  });
};

export const apiUpdateThongTinChung = (setLoading, data, getListBaoCao) => {
  authPostData({
    url: Endpoint.UPDATE_THONG_TIN_CHUNG_BAO_CAO_89_160,
    payload: data,
    method: METHOD_PUT,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListBaoCao();
      }
    },
  });
};

export const apiChotBaoCao = (setLoading, data, getListBaoCao) => {
  authPostData({
    url: Endpoint.CHOT_BAO_CAO_89_160,
    payload: data,
    method: METHOD_PUT,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListBaoCao();
      }
    },
  });
};
export const apiHuyChotBaoCao = (setLoading, data, getListBaoCao) => {
  authPostData({
    url: Endpoint.HUY_CHOT_BAO_CAO_89_160,
    payload: data,
    method: METHOD_PUT,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListBaoCao();
      }
    },
  });
};

export const handleListDataCN = (detailBaoCao, setLoading, setDataNgam) => {
  authGetData({
    url: `${Endpoint.GET_LIST_CHI_PHI_CAP_NGAM}?hoSoId=${detailBaoCao.hoSoId}&phanLoai=1`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) setDataNgam(res.data);
    },
  });
};

export const handleListDataDDK = (detailBaoCao, setLoading, setDataDDK) => {
  authGetData({
    url: `${Endpoint.GET_LIST_CHI_PHI_CAP_DDK}?hoSoId=${detailBaoCao.hoSoId}&phanLoai=2`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) setDataDDK(res.data);
    },
  });
};

export const handleListDataKhac = (detailBaoCao, setLoading, setDataKhac) => {
  authGetData({
    url: `${Endpoint.GET_LIST_CHI_PHI_CAP_KHAC}?hoSoId=${detailBaoCao.hoSoId}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) setDataKhac(res.data);
    },
  });
};

export const handleListData = (
  detailBaoCao,
  setLoading,
  setDataNgam,
  setDataDDK,
  setDataKhac
) => {
  const endpoints = [
    `${Endpoint.GET_LIST_CHI_PHI_CAP_NGAM}?hoSoId=${detailBaoCao.hoSoId}&phanLoai=1`,
    `${Endpoint.GET_LIST_CHI_PHI_CAP_DDK}?hoSoId=${detailBaoCao.hoSoId}&phanLoai=2`,
    `${Endpoint.GET_LIST_CHI_PHI_CAP_KHAC}?hoSoId=${detailBaoCao.hoSoId}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      if (res.data.length > 0 && res.data) {
        setDataNgam(res[0].data.data);
        setDataDDK(res[1].data.data);
        setDataKhac(res[2].data.data);
      }
    },
  });
};

export const handleCreateOrUpdate = (
  data,
  detail,
  setLoading,
  closeModal,
  form,
  getListData,
  typeChiPhi
) => {
  let createUrl = "";
  let updateUrl = "";

  if (typeChiPhi === TYPE_CHI_PHI.CAP_NGAM) {
    data.phanLoai = 1;
  } else if (typeChiPhi === TYPE_CHI_PHI.DDK) {
    data.phanLoai = 2;
  }

  if (detail.id) {
    data.id = detail.id;
    if (typeChiPhi === TYPE_CHI_PHI.CAP_NGAM) {
      updateUrl = Endpoint.UPDATE_CHI_PHI_CAP_NGAM;
    } else if (typeChiPhi === TYPE_CHI_PHI.DDK) {
      updateUrl = Endpoint.UPDATE_CHI_PHI_CAP_DDK;
    } else updateUrl = Endpoint.UPDATE_CHI_PHI_CAP_KHAC;
    authPostData({
      url: updateUrl,
      method: METHOD_PUT,
      payload: data,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          getListData();
          closeModal();
        } else getErrorMessage(res, form);
      },
    });
  } else {
    if (typeChiPhi === TYPE_CHI_PHI.CAP_NGAM) {
      createUrl = Endpoint.CREATE_CHI_PHI_CAP_NGAM;
    } else if (typeChiPhi === TYPE_CHI_PHI.DDK) {
      createUrl = Endpoint.CREATE_CHI_PHI_CAP_DDK;
    } else createUrl = Endpoint.CREATE_CHI_PHI_CAP_KHAC;

    authPostData({
      url: createUrl,
      method: METHOD_POST,
      payload: data,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          getListData();
          closeModal();
        } else getErrorMessage(res, form);
      },
    });
  }
};

export const handleRemoveData = (
  thangBaoCao,
  id,
  getListData,
  setLoading,
  type
) => {
  let deleteUrl = "";
  if (type === TYPE_CHI_PHI.CAP_NGAM) {
    deleteUrl = Endpoint.REMOVE_CHI_PHI_CAP_NGAM;
  } else if (type === TYPE_CHI_PHI.DDK) {
    deleteUrl = Endpoint.REMOVE_CHI_PHI_CAP_DDK;
  } else deleteUrl = Endpoint.REMOVE_CHI_PHI_CAP_KHAC;
  authDeleteData({
    url: deleteUrl + "?id=" + id + "&thangBaoCao=" + thangBaoCao,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) getListData();
    },
  });
};

export const handleMultiBoLoc = (setLoading, setDonVi) => {
  const endpoints = [`${Endpoint.GET_UNIT_LIST}`];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDonVi(res[0].data.data);
    },
  });
};

export const handleListVatTu = (setLoading, setDataVatTu, detailBaoCao) => {
  authGetData({
    url: `${Endpoint.LIST_VAT_TU_BAO_CAO}?hoSoId=` + detailBaoCao.hoSoId,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) setDataVatTu(res.data);
    },
  });
};

export const handleGetThongTinChung = (
  setLoading,
  detailBaoCao,
  setDetailThongTin
) => {
  authGetData({
    url:
      Endpoint.GET_THONG_TIN_CHUNG_BAO_CAO_89_160 +
      "?hoSoId=" +
      detailBaoCao.hoSoId,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) setDetailThongTin(res.data);
    },
  });
};

export const handleListChiPhi = (setLoading, type, setDataSource) => {
  let url = "";
  if (type === TYPE_CHI_PHI.CAP_NGAM) {
    url = Endpoint.GET_LIST_CHI_PHI_CAP_NGAM;
  } else if (type === TYPE_CHI_PHI.DDK) {
    url = Endpoint.GET_LIST_CHI_PHI_CAP_DDK;
  } else type = Endpoint.GET_LIST_CHI_PHI_CAP_KHAC;
  authGetData({
    url: url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) setDataSource(res.data);
    },
  });
};
