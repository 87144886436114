import { Pie, G2 } from "@ant-design/plots";
import { useEffect, useState, Fragment } from "react";
import { handleKTCTTrongThang } from "../services";
import LoadingComponent from "@components/Loading";
import { ContainerPie } from "../css-styled";

export default function TongCongToPie(props) {
  const { setTitle } = props;
  const [loading, setLoading] = useState(false);
  const [dataPie, setDataPie] = useState([]);
  useEffect(() => {
    handleKTCTTrongThang(setLoading, (values) => {
      setTitle(values?.title ?? "Biểu đồ");
      setDataPie([...values?.series?.data] ?? []);
    });
  }, [setTitle]);

  const G = G2.getEngine("canvas");
  const cfg = {
    appendPadding: 5,
    data: dataPie,
    angleField: "y",
    colorField: "name",
    radius: 0.75,
    legend: true,
    label: {
      type: "spider",
      labelHeight: 40,
      formatter: (data, mappingData) => {
        const group = new G.Group({});
        group.addShape({
          type: "circle",
          attrs: {
            x: 0,
            y: 0,
            width: 40,
            height: 50,
            r: 5,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: "text",
          attrs: {
            x: 10,
            y: 8,
            text: `${data.name}`,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: "text",
          attrs: {
            x: 0,
            y: 25,
            text: `${(data.percent * 100).toFixed(2)} %`,
            fill: "rgba(0, 0, 0, 0.65)",
            fontWeight: 700,
          },
        });
        return group;
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  const config = cfg;
  return (
    <Fragment>
      <LoadingComponent loading={loading}>
        <ContainerPie>
          {dataPie &&
            dataPie.length > 0 &&
            dataPie.map((item, index) => {
              return (
                <div className="container" key={index}>
                  <span className="value">{item?.y}</span>
                  <span className="title">{item?.name}</span>
                </div>
              );
            })}
          <div className="container">
            <span className="value">
              {dataPie.reduce(
                (accumulator, current) => accumulator + current.y,
                0
              )}
            </span>
            <span className="title">Tổng</span>
          </div>
        </ContainerPie>
        <Pie {...config} height={300} width={300} />
      </LoadingComponent>
    </Fragment>
  );
}
