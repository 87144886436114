import ModalDuLieu from "./modal";

export default function Index(props) {
  const { user, data, isThang, visible, closeModal, filterConditions } = props;

  const columnsData = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (_, record) => (
        <div
          className={
            record.id === null ? `table-text-center` : `table-text-right`
          }
        >
          <span>{record.id === null ? "Tổng cộng" : record.stt}</span>
        </div>
      ),
      width: "6%",
      onCell: (record, index) => ({
        colSpan: record.id === null && data.length - 1 === index ? 2 : 1,
      }),
    },
    {
      title: user.isTongCongTy ? "Đơn vị" : "Đội",
      dataIndex: isThang ? "maDonViHoacMaDoiThang" : "maDonViHoacMaDoiLK",
      key: isThang ? "maDonViHoacMaDoiThang" : "maDonViHoacMaDoiLK",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
      //sorter: true,
      onCell: (record, index) => ({
        colSpan: record.stt !== null && data.length - 1 === index ? 0 : 1,
      }),
    },
    {
      title: "Tổng số tiếp nhận",
      dataIndex: isThang ? "tongSoTiepNhanThang" : "tongSoTiepNhanLK",
      key: isThang ? "tongSoTiepNhanThang" : "tongSoTiepNhanLK",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      //sorter: true,
    },
    {
      title: "Cổng DVCQG",
      dataIndex: isThang ? "congDVCQGThang" : "congDVCQGLK",
      key: isThang ? "congDVCQGThang" : "congDVCQGLK",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
      //sorter: true,
    },
    {
      title: "TT.CSKH",
      children: [
        {
          title: "Web CSKH",
          dataIndex: isThang ? "webCSKHThang" : "webCSKHLK",
          key: isThang ? "webCSKHThang" : "webCSKHLK",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
          //sorter: true,
        },
        {
          title: "Email",
          dataIndex: isThang ? "emailThang" : "emailLK",
          key: isThang ? "emailThang" : "emailLK",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
          //sorter: true,
        },
        {
          title: "App CSKH",
          dataIndex: isThang ? "appCSKHThang" : "appCSKHLK",
          key: isThang ? "appCSKHThang" : "appCSKHLK",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
          //sorter: true,
        },
        {
          title: "Tổng đài 19001288",
          dataIndex: isThang ? "tongDaiThang" : "tongDaiLK",
          key: isThang ? "tongDaiThang" : "tongDaiLK",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
          //sorter: true,
        },
      ],
    },
    {
      title: "Phòng GD-ĐL",
      dataIndex: isThang ? "phongGDDLThang" : "phongGDDLLK",
      key: isThang ? "phongGDDLThang" : "phongGDDLLK",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "9%",
      //sorter: true,
    },
    {
      title: "Tỷ lệ tiếp nhận dịch vụ cấp điện qua TT.CSKH & DVCQG (%)",
      dataIndex: isThang ? "tiLeQuaCSKHVaDVCQGThang" : "tiLeQuaCSKHVaDVCQGLK",
      key: isThang ? "tiLeQuaCSKHVaDVCQGThang" : "tiLeQuaCSKHVaDVCQGLK",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}%</span>
        </div>
      ),
      width: "13%",
      //sorter: true,
    },
    {
      title: "Tỷ lệ tiếp nhận dịch vụ điện qua Cổng DVCQG (%)",
      dataIndex: isThang ? "tiLeQuaCongDVCQGThang" : "tiLeQuaCongDVCQGLK",
      key: isThang ? "tiLeQuaCongDVCQGThang" : "tiLeQuaCongDVCQGLK",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}%</span>
        </div>
      ),
      width: "13%",
      //sorter: true,
    },
  ];

  //footer total count table
  // const countTotals = (pageData) => {
  //   let countTongSoTiepNhan = 0;
  //   let countCongDVCQG = 0;
  //   let countWebCSKH = 0;
  //   let countEmail = 0;
  //   let countAppCSKH = 0;
  //   let countTongDai = 0;
  //   let countPhongGDDL = 0;
  //   // let countTyleTiepNhanDVDQuaCSKH = 0;
  //   // let countTyleTiepNhanDVDQuaDVCQG = 0;
  //   console.log(pageData);
  //   pageData.forEach((item) => {
  //     countTongSoTiepNhan += item.tongSoTiepNhanLK
  //       ? item.tongSoTiepNhanLK
  //       : item.tongSoTiepNhanThang;
  //     countCongDVCQG += item.congDVCQGLK
  //       ? item.congDVCQGLK
  //       : item.congDVCQGThang;
  //     countWebCSKH += item.webCSKHLK ? item.webCSKHLK : item.webCSKHThang;
  //     countEmail += item.emailLK ? item.emailLK : item.emailThang;
  //     countAppCSKH += item.appCSKHLK ? item.appCSKHLK : item.appCSKHThang;
  //     countTongDai += item.tongDaiLK ? item.tongDaiLK : item.tongDaiThang;
  //     countPhongGDDL += item.phongGDDLLK
  //       ? item.phongGDDLLK
  //       : item.phongGDDLThang;
  //   });

  //   console.log(countCongDVCQG);
  //   // pageData.forEach(
  //   //   (
  //   //     // item
  //   //     {
  //   //       tongSoTiepNhanLK,
  //   //       congDVCQGLK,
  //   //       webCSKHLK,
  //   //       emailLK,
  //   //       appCSKHLK,
  //   //       tongDaiLK,
  //   //       phongGDDLLK,
  //   //       // tiLeQuaCSKHVaDVCQG,
  //   //       // tiLeQuaCongDVCQG,
  //   //     }
  //   //   ) => {
  //   //     // console.log(item);
  //   //     // countTongSoTiepNhan += item.tongSoTiepNhanLK
  //   //     //   ? item.tongSoTiepNhanLK
  //   //     //   : item.tongSoTiepNhanThang;
  //   //     // countCongDVCQG += item.congDVCQGLK
  //   //     //   ? item.congDVCQGLK
  //   //     //   : item.congDVCQGLKThang;
  //   //     // countWebCSKH += item.webCSKHLK ? item.webCSKHLK : item.webCSKHThang;
  //   //     // countEmail += item.emailLK ? item.emailLK : item.emailThang;
  //   //     // countAppCSKH += item.appCSKHLK ? item.appCSKHLK : item.appCSKHThang;
  //   //     // countTongDai += item.tongDaiLK ? item.tongDaiLK : item.tongDaiThang;
  //   //     // countPhongGDDL += item.phongGDDLLK
  //   //     //   ? item.phongGDDLLK
  //   //     //   : item.phongGDDLThang;
  //   //     // countTyleTiepNhanDVDQuaCSKH += tiLeQuaCSKHVaDVCQG;
  //   //     // countTyleTiepNhanDVDQuaDVCQG += tiLeQuaCongDVCQG;
  //   //   }
  //   // );
  //   return (
  //     <Table.Summary fixed>
  //       <Table.Summary.Row style={{ backgroundColor: "#FFEAEA" }}>
  //         <Table.Summary.Cell align="center" colSpan={2} index={0}>
  //           <Text className="font-bold">Tổng cộng</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={1}>
  //           <Text className="font-bold">{countTongSoTiepNhan}</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={2}>
  //           <Text className="font-bold">{countCongDVCQG}</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={3}>
  //           <Text className="font-bold">{countWebCSKH}</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={4}>
  //           <Text className="font-bold">{countEmail}</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={5}>
  //           <Text className="font-bold">{countAppCSKH}</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={6}>
  //           <Text className="font-bold">{countTongDai}</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={7}>
  //           <Text className="font-bold">{countPhongGDDL}</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={8}>
  //           <Text className="font-bold">
  //             {countCongDVCQG +
  //               countWebCSKH +
  //               countEmail +
  //               countAppCSKH +
  //               countTongDai ===
  //             0
  //               ? 0
  //               : ((countCongDVCQG +
  //                   countWebCSKH +
  //                   countEmail +
  //                   countAppCSKH +
  //                   countTongDai) /
  //                   countTongSoTiepNhan) *
  //                 100}
  //             %
  //           </Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={9}>
  //           <Text className="font-bold">
  //             {countCongDVCQG === 0
  //               ? 0
  //               : ((countCongDVCQG / countTongSoTiepNhan) * 100).toFixed(2)}
  //             %
  //           </Text>
  //         </Table.Summary.Cell>
  //       </Table.Summary.Row>
  //     </Table.Summary>
  //   );
  // };

  //render note item for columns
  const noteColumns = [
    {
      title: "",
      // fixed: true,
    },
    {
      title: "(1)",
      // fixed: true,
    },
    {
      title: "(2) = (3+4+5+6+7+8)",
      // fixed: true,
      // last: true,
    },
    {
      title: "(3)",
    },
    {
      title: "(4)",
    },
    {
      title: "(5)",
    },
    {
      title: "(6)",
    },
    {
      title: "(7)",
    },
    {
      title: "(8)",
    },
    {
      title: "(9) = (3+4+5+6+7)/(2)",
    },
    {
      title: "(10) = (3)/(2)",
    },
  ];
  return (
    <ModalDuLieu
      visible={visible}
      closeModal={closeModal}
      isThang={isThang}
      data={data}
      columns={columnsData}
      // countTotals={countTotals}
      noteColumns={noteColumns}
      filterConditions={filterConditions}
    />
  );
}
