import { Table } from "antd";
import { useTranslation } from "react-i18next";

export default function ThacTacNguoiDung(props) {
  const { dataSource } = props;
  const { t } = useTranslation();

  const columns = [
    {
      title: t("duyet_ho_so.stt"),
      dataIndex: "stt",
      key: "stt",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "7%",
    },
    {
      title: t("duyet_ho_so.noi_dung_xu_ly"),
      dataIndex: "noiDung",
      key: "noiDung",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "30%",
    },
    {
      title: "Ngày tạo",
      dataIndex: "ngayTaoStr",
      key: "ngayTaoStr",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
    },
    {
      title: "Người giao",
      dataIndex: "nguoiGiao",
      key: "nguoiGiao",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "20%",
    },
    {
      title: "Người nhận",
      dataIndex: "nguoiNhan",
      key: "nguoiNhan",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "20%",
    },
    {
      title: "Mã trạng thái",
      dataIndex: "maTrangThai",
      key: "maTrangThai",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "11%",
    },
  ];

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      scroll={{ y: 700 }}
    />
  );
}
