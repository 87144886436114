import { authSelector } from "@state/auth";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BaoCaoDSKHTable from "./ui-bao-cao";
import { handleMultiBoLoc } from "./services";
import moment from "moment";
import {
  FORMAT_ENGLISH,
  MONTH_STRING,
  TYPE_CHANGE_DATE,
} from "@utils/constants";

export default function Index() {
  // const [, setSearchParams] = useSearchParams();
  // const location = useLocation();
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState([]);
  // const [totalCount, setTotalCount] = useState(0);
  const { user } = useSelector(authSelector);
  const [donVi, setDonVi] = useState([]);
  //const [maDonVi, setMaDonVi] = useState("");
  // //filter
  // const [filterConditions, setFilterConditions] = useState({
  //   pageIndex: DEFAULT_PAGEINDEX,
  //   pageSize: DEFAULT_PAGESIZE,
  //   ...parseParams(location.search),
  //   donViId: user.unitId,
  // });

  // useLayoutEffect(() => {
  //   setSearchParams(removeUndefinedAttribute(filterConditions));
  // }, [setSearchParams, filterConditions]);

  // //change pagination
  // const onChangePagination = (paging, filters, sorter) => {
  //   handlePagination(paging, sorter, setFilterConditions);
  // };

  // //fetch báo cáo
  // const getListBaoCao = useCallback(() => {
  //   handleListBaoCao(setLoading, setData, setTotalCount, filterConditions);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

  // //search
  // const handleSearch = useCallback((values) => {
  //   setFilterConditions((oldState) => ({
  //     ...oldState,
  //     pageIndex: DEFAULT_PAGEINDEX,
  //     ...values,
  //     donViId: values.donViId,
  //     tuNgay: formatSplitDate(values.tuNgay, true),
  //     denNgay: formatSplitDate(values.denNgay, true),
  //   }));
  // }, []);

  // //clear search
  // const clearFilter = useCallback(() => {
  //   setFilterConditions({
  //     pageIndex: DEFAULT_PAGEINDEX,
  //     pageSize: DEFAULT_PAGESIZE,
  //     donViId: user.unitId,
  //   });
  // }, [user.unitId]);

  // useEffect(() => {
  //   getListBaoCao();
  // }, [getListBaoCao]);

  const toDay = moment().format(FORMAT_ENGLISH);
  const [tuNgay, setTuNgay] = useState(toDay);
  const [denNgay, setDenNgay] = useState(toDay);
  const [thang, setThang] = useState(toDay);
  const [unit, setUnit] = useState(user.unitId);

  //change date
  const handleChangeNgay = (date, type) => {
    let convert = "";
    if (date === null) convert = undefined;
    else {
      if (type === TYPE_CHANGE_DATE.TUNGAY) {
        // convert = moment(date).startOf(MONTH_STRING).format(FORMAT_ENGLISH);
        convert = moment(date).format(FORMAT_ENGLISH);
        setTuNgay(convert);
      } else if (type === TYPE_CHANGE_DATE.DENNGAY) {
        //convert = moment(date).endOf(MONTH_STRING).format(FORMAT_ENGLISH);
        convert = moment(date).format(FORMAT_ENGLISH);
        setDenNgay(convert);
      } else {
        convert = moment(date).format(FORMAT_ENGLISH);
        setThang(convert);
      }
    }
  };

  //change unit
  const handleChangeUnit = (value) => {
    setUnit(value);
  };

  useEffect(() => {
    handleMultiBoLoc(setLoading, setDonVi);
  }, []);

  // useEffect(() => {
  //   if (donVi.length > 0) {
  //     const item = donVi.find((value) => value.id === unit);
  //     if (item !== null || item !== undefined) {
  //       setMaDonVi(item.maDonVi);
  //     }
  //   }
  // }, [donVi, unit]);

  return (
    <BaoCaoDSKHTable
      // handleSearch={handleSearch}
      // filterConditions={filterConditions}
      // clearFilter={clearFilter}
      // totalCount={totalCount}
      // loading={loading}
      // onChangePagination={onChangePagination}
      // data={data}
      user={user}
      donVi={donVi}
      loading={loading}
      handleChangeNgay={handleChangeNgay}
      handleChangeUnit={handleChangeUnit}
      tuNgay={tuNgay}
      denNgay={denNgay}
      thangKT={thang}
      unit={unit}
      //maDonVi={maDonVi}
    />
  );
}
