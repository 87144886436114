import React from "react";
import { Button, Col, Modal, Row } from "antd";

import LoadingComponent from "@components/Loading";
import { Container } from "./css-styled";
export default function Index(props) {
  const { loading, visible, closeModal, handleCancelHoSo, t } = props;
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={"Xác nhận hủy hồ sơ"}
        visible={visible}
        onCancel={closeModal}
        footer={null}
      >
        <Container>
          <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
            Hồ sơ bạn chọn sẽ bị Hủy. Bạn chắc chắn đồng ý?
          </h4>

          <Row gutter={24} justify="center" style={{ marginTop: "28px" }}>
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="danger"
                className="primary"
                onClick={handleCancelHoSo}
                key="confirm"
                loading={loading}
              >
                {t("button.dong_y")}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal>
    </LoadingComponent>
  );
}
