import LoadingComponent from "@components/Loading";
import { Table } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { handleListData } from "../services";
import { authSelector } from "@state/auth";

export default function KTTBNVTrongThang() {
  const { user } = useSelector(authSelector);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    handleListData(setLoading, setDataSource);
  }, []);
  const columns = [
    {
      title: "Đội",
      dataIndex: "doi",
      key: "doi",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: !user.isTongCongTy ? "25%" : "45%",
      sorter: false,
    },
    {
      title: "Nhân viên",
      dataIndex: "nhanVien",
      key: "nhanVien",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "20%",
      sorter: false,
    },
    {
      title: "Kiểm tra công tơ",
      children: [
        {
          title: "Đã kiểm tra",
          dataIndex: "daKiemTra",
          key: "daKiemTra",
          render: (text) => <div className="table-text-right">{text}</div>,
          width: "13%",
          sorter: false,
        },
        {
          title: "Đang kiểm tra",
          dataIndex: "dangKiemTra",
          key: "dangKiemTra",
          render: (text) => <div className="table-text-right">{text}</div>,
          width: "15%",
          sorter: false,
        },
      ],
    },
    !user.isTongCongTy
      ? {
          title: "Phúc tra ngoài hiện trường",
          children: [
            {
              title: "Đã phúc tra",
              dataIndex: "daPhucTra",
              key: "daPhucTra",
              render: (text) => <div className="table-text-right">{text}</div>,
              width: "13%",
              sorter: false,
            },
            {
              title: "Đang phúc tra",
              dataIndex: "dangPhucTra",
              key: "dangPhucTra",
              render: (text) => <div className="table-text-right">{text}</div>,
              width: "13%",
              sorter: false,
            },
          ],
        }
      : {},
  ];
  return (
    <Fragment>
      <LoadingComponent loading={loading}>
        <div className="table-fixed">
          <Table dataSource={dataSource} columns={columns} />
        </div>
      </LoadingComponent>
    </Fragment>
  );
}
