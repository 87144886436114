import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, authPutData } from "@utils/request";

export const getDanhSachDuyet = (
  setListLoading,
  setData,
  query,
  filterConditions,
  setTotal
) => {
  authGetData({
    url: `${Endpoint.CRMDD_DUYET_YEU_CAU}?${query}`,
    setLoading: setListLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              STT:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            };
          })
        );
        setTotal(res.paging.totalCount);
      }
    },
  });
};

export const putDuyetYeuCau = (
  payload,
  setLoading,
  setListLoading,
  location,
  filterConditions,
  setData,
  setTotal,
  setVisibleDuyet,
  setSelectedRowKeys
) => {
  authPutData({
    url: `${Endpoint.CRMDD_TRUONG_PHONG_XAC_NHAN}`,
    method: "PUT",
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        const query =
          Object.keys(parseParams(location.search)) > 0
            ? buildQueryString(parseParams(location.search))
            : buildQueryString(filterConditions);
        getDanhSachDuyet(
          setListLoading,
          setData,
          query,
          filterConditions,
          setTotal
        );
        setVisibleDuyet(false);
        setSelectedRowKeys([]);
      }
    },
  });
};

export const putTraLaiYeuCau = (
  payload,
  setLoading,
  setListLoading,
  location,
  filterConditions,
  setData,
  setTotal,
  setVisibleTra,
  setSelectedRowKeys
) => {
  authPutData({
    url: `${Endpoint.CRMDD_TRUONG_PHONG_XAC_NHAN_TRA_YEU_CAU}`,
    method: "PUT",
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        const query =
          Object.keys(parseParams(location.search)) > 0
            ? buildQueryString(parseParams(location.search))
            : buildQueryString(filterConditions);
        getDanhSachDuyet(
          setListLoading,
          setData,
          query,
          filterConditions,
          setTotal
        );
        setVisibleTra(false);
        setSelectedRowKeys([]);
      }
    },
  });
};

export const getLinhVucList = (
  setLoading,
  form_filter,
  setData,
  filterConditions,
  setTotal,
  location,
  setListLoading
) => {
  authGetData({
    url: `${Endpoint.CRM_LIST_LINH_VUC}`,
    setLoading,
    onSuccess: (res) => {
      const current_query = {
        ...filterConditions,
        ...(res.data.length === 1 && { LinhVuc: res.data[0].value }),
      };

      const updated_query = {
        ...current_query,
        pageSize: filterConditions.pageSize,
        pageIndex: filterConditions.pageIndex,
      };
      if (res.data.length === 1) {
        form_filter.setFieldsValue({
          LinhVuc: res.data[0].value || undefined,
        });
      }
      const query = buildQueryString(updated_query);

      getDanhSachDuyet(
        setListLoading,
        setData,
        query,
        filterConditions,
        setTotal
      );
    },
  });
};
