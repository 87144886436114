import { authGetData, authPostData } from "@utils/request";
import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";

export const handleXacNhan = (
  setLoading,
  payload,
  setFetchList,
  closeModal,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.XAC_NHAN_AN_TOAN,
    method: METHOD_PUT,
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        // getListData();
        setFetchList(true);
        closeModal();
        setSelectedRowKeys([]);
      }
    },
  });
};

export const handleListPhieuLenh = (params, setLoading, setData) => {
  authGetData({
    url: `${Endpoint.GET_PHIEU_LENH_CONG_TAC}?maSSONVTT=${params.maSSONVTT}&maSSONVNP=${params.maSSONVNP}&ngayTreoThao=${params.ngayTreoThao}&maDonVi=${params.maDonVi}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        // getListData();
        setData(res.data);
      }
    },
  });
};
