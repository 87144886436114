import { createSlice } from "@reduxjs/toolkit";
export const namespace = "lapPhuongAn";

const INITAL_STATE = {
  thongTinHoSo: {
    boPhanKiemTra: null,
    boPhanLap: null,
    hoSoId: null,
    isVatTuMuaNgoai: null,
    loaiCap: null,
    loaiDuToan: null,
    ngayLapKhaiGia: null,
  },
  listVatTuKhachHang: [],
  listVatTuCongTy: [],
  listVatTuTuHoi: [],
};

const slice = createSlice({
  name: namespace,
  initialState: INITAL_STATE,
  reducers: {
    setListVatTuKH: (state, action) => {
      return {
        ...state,
        listVatTuKhachHang: action.payload,
      };
    },
  },
});

export const reducer = slice.reducer;

export const { setListVatTuKH } = slice.actions;

export const duTruVatTuAnSelector = (state) => state[namespace];
