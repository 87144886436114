import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Style } from "./css-styled";
import Filter from "../../common/Filter";
import FilterThang from "./list-bo-loc";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_MONTH_ENGLISH,
} from "@utils/constants";
import moment from "moment";
import {
  buildQueryString,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import TableComponent from "@components/TableComponent";
import { Export } from "../export/Export";
import { Endpoint } from "@utils/endpoint";
import { getListTongHopAnhChupGCS } from "./services";
const TongHopAnhChupGCS = () => {
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const { user } = useSelector(authSelector);
  const [data, setData] = useState();
  const [total, setTotal] = useState();
  const location = useLocation();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
    DonViId: user.unitId,
    ...parseParams(location.search),
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);
  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      align: "center",
      width: "80px",
    },
    {
      title: "Đội GCS",
      dataIndex: "doi",
      align: "center",
      width: "250px",
    },
    {
      title: "Tổng số KH",
      dataIndex: "tongSoKH",
      align: "center",
    },
    {
      title: "Số lượng KH GCS theo các hình thức",
      children: [
        {
          title: "Đo xa",
          dataIndex: "doXa",
          align: "center",
        },
        {
          title: "Quét HHU Gelex",
          dataIndex: "hhugelex",
          align: "center",
        },
        {
          title: "Quét HHU Hữu Hồng",
          dataIndex: "hhu",
          align: "center",
        },
        {
          title: "Nhập tay HHU Gelex",
          // dataIndex: "hhu",
          align: "center",
        },
        {
          title: "Nhập tay HHU Hữu Hồng",
          // dataIndex: "hhu",
          align: "center",
        },
        {
          title: "Nhập trên MTB",
          dataIndex: "mtb",
          align: "center",
        },
        {
          title: "Tổng",
          dataIndex: "tong",
          align: "center",
        },
        {
          title: "SL KH phải chụp",
          dataIndex: "slkhPhaiChup",
          align: "center",
        },
        {
          title: "SL KH thiếu ảnh",
          dataIndex: "slkhThieuAnh",
          align: "center",
        },
        {
          title: "Tỷ lệ đủ ảnh",
          dataIndex: "tyLeDuAnh",
          align: "center",
        },
      ],
    },
    {
      title: "Số lượng KH lệch ngày GCS (với trường thời gian GCS)",
      children: [
        {
          title: "Trước > 2 ngày",
          dataIndex: "truocHon2Ngay",
          align: "center",
        },
        {
          title: "Trước 2 ngày",
          dataIndex: "truoc2Ngay",
          align: "center",
        },
        {
          title: "Trước 1 ngày",
          dataIndex: "truoc1Ngay",
          align: "center",
        },
        {
          title: "Đúng ngày GCS",
          dataIndex: "dungNgay",
          align: "center",
        },
        {
          title: "Sau 1 ngày",
          dataIndex: "sau1Ngay",
          align: "center",
        },
        {
          title: "Sau 2 ngày",
          dataIndex: "sau2Ngay",
          align: "center",
        },
        {
          title: "Sau > 2 ngày",
          dataIndex: "sauHon2Ngay",
          align: "center",
        },
        {
          title: "Tổng GCS không đúng ngày",
          dataIndex: "tongGCSKhongDungNgay",
          align: "center",
        },
        {
          title: "Tỷ lệ ghi đúng ngày",
          dataIndex: "tyLeGhiDungNgay",
          align: "center",
        },
      ],
    },
    {
      title: "Số lượng ghi có tọa độ",
      dataIndex: "soLuongCoGhiToaDo",
      align: "center",
    },
    {
      title: "Ghi chú",
      dataIndex: "",
      align: "center",
    },
  ];

  const queryString = buildQueryString(
    parseParams({
      ...filterConditions,
    })
  );
  const genExtra = useCallback(
    () => (
      <div className="export">
        <Export
          excelEndpoint={`${Endpoint.TONG_HOP_ANH_CHUP_EXCEL}?${queryString}`}
          pdfEndpoint={`${Endpoint.TONG_HOP_ANH_CHUP_PDF}?${queryString}`}
          setLoading={setLoadingBtn}
        />
      </div>
    ),
    [queryString]
  );

  const onChangePagination = useCallback((paging, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
      DonViId: user.unitId,
    });
  }, [user.unitId]);

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      ...values,
    }));
  }, []);

  const getLichGCS = useCallback(() => {
    getListTongHopAnhChupGCS(filterConditions, setLoading, setData, setTotal);
  }, [filterConditions]);

  useEffect(() => {
    getLichGCS();
  }, [getLichGCS]);

  return (
    <Style>
      <Filter>
        <FilterThang
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          loading={loading}
          handleSearch={handleSearch}
          loadingBtn={loadingBtn}
        />
      </Filter>

      <TableComponent
        header="Báo cáo tổng hợp ảnh chụp GCS"
        columns={columns}
        filterConditions={filterConditions}
        totalCount={total}
        loading={loading}
        scrollX={2300}
        dataSource={data}
        setLoading={setLoading}
        renderExtraAction={() => genExtra()}
        pagination={filterConditions}
        onChangePagination={onChangePagination}
      />
    </Style>
  );
};

export default TongHopAnhChupGCS;
