import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useEffect,
  useMemo
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Filter from "@components/Filter/Filter";
import { TableStyled, Container } from "./css-styled";
import { Tooltip, Tag, Modal, notification } from "antd";
import { authSelector } from "@state/auth/reducer";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE, ENUM_MUCDICH_SDD, AP_GIA_TRANG_THAI } from "@utils/constants";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import {
  handleListData,
  submitDBYeuCauCMIS
} from "./services";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilter } from "../../common/list-bo-loc";
import CheckPermission from "@components/CheckPermission";
import { ap_gia } from "@permissions/e_apgia";
import TableComponent from "@components/TableComponent";
import ButtonComponent from "@components/Button";
import { openModal } from "@state/system-config/reducer";
import ChiTietDiemDo from "../../common/chi-tiet-diem-do";
import { FilePdfOutlined, FileOutlined } from "@ant-design/icons";
// import moment from "moment";
import TraLaiModal from "./modal-tra-lai";

export default function Index() {
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);
  const [dataGuiDuLieuCMIS, setDataGuiDuLieuCMIS] = useState([]);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const location = useLocation();
  const [biTraLai, setBiTraLai] = useState(false);
  const [visibleTraLai, setVisibleTraLai] = useState(false);
  const [dataResponse, setDataResponse] = useState([]);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    ...parseParams(location.search),
    TrangThai: AP_GIA_TRANG_THAI.LD_DA_XAC_NHAN,
    // ThangKiemTra: moment().format(FORMAT_MONTH_ENGLISH),
    // LoaiKhachHang: ENUM_MUCDICH_SDD.SINHHOAT,
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);
  const onChangePagination = (paging, _filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setSelectedRowKeys([]);
  };
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
      BiTraLai: biTraLai ? biTraLai : null,
      Tram: values.Tram
        ? Array.isArray(values.Tram) && values.Tram.length > 0
          ? values.Tram.join()
          : values.Tram
        : [],
    }));
    setDataResponse([]);
  }, [biTraLai]);

  const clearFilter = useCallback(() => {
    setDataResponse([]);
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      TrangThai: AP_GIA_TRANG_THAI.LD_DA_XAC_NHAN,
      // ThangKiemTra: moment().format(FORMAT_MONTH_ENGLISH),
      // LoaiKhachHang: ENUM_MUCDICH_SDD.SINHHOAT,
    });
  }, [user.unitId]);
  //search list
  const getListData = useCallback(() => {
    handleListData(location,
      filterConditions,
      setLoading,
      setDataGuiDuLieuCMIS,
      setTotalCount,
      setSelectedRowKeys
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getListData();
  }, [getListData, user]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListData();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListData, setFetchDataTable]);

  const checkTraLai = (e) => {
    setBiTraLai(e.target.checked);
  };

  const detailDiemDo = (record) => {
    return dispatch(
      openModal({
        content: (
          <ChiTietDiemDo
            detailRecord={record}
          />
        ),
        size: "large",
        title: `Chi tiết điểm đo - ${record.maDiemDo}`
      })
    );
  };

  const detailDD = (record) => {
    return {
      onDoubleClick: () => {
        detailDiemDo(record)
      },
    };
  };

  const columns = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => detailDiemDo(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
      fixed: "left"
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      key: "maKhachHang",
      render: (text) => (
        <span>{text}</span>
      ),
      width: "10%",
      sorter: true,
      fixed: "left"
    },
    {
      title: "Tên KH",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => <span>{text}</span>,
      width: "12%",
      sorter: true,
    },
    {
      title: "Điện thoại",
      dataIndex: "soDienThoai",
      key: "soDienThoai",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: 'Trước kiểm tra',
      children: [
        {
          title: 'Ngày áp dụng',
          dataIndex: "ngayApDung",
          key: "ngayApDung",
          render: (text) => (
            <div className="table-text-center">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
        {
          title: 'Số hộ',
          dataIndex: "soHo",
          key: "soHo",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
        },
        {
          title: "Chuỗi giá",
          dataIndex: "chuoiGia",
          key: "chuoiGia",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        }
      ],
    },
    {
      title: 'Sau kiểm tra',
      children: [
        {
          title: 'Ngày áp dụng',
          dataIndex: "ngayApDungMoi",
          key: "ngayApDungMoi",
          render: (text) => (
            <div className="table-text-center">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
        {
          title: 'Số hộ',
          dataIndex: "soHoMoi",
          key: "soHoMoi",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
        },
        {
          title: "Chuỗi giá",
          dataIndex: "chuoiGiaMoi",
          key: "chuoiGiaMoi",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        }
      ],
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThaiPhanCong",
      key: "trangThaiPhanCong",
      render: (text, record) => (
        record.trangThai?.toString() === AP_GIA_TRANG_THAI.LD_DA_XAC_NHAN ?
          <Tag style={{ display: "block", textAlign: "center" }} color={"#26c0e9"}>{"Chờ đồng bộ về CMIS"}</Tag> :
          <Tag style={{ display: "block", textAlign: "center" }} color={"#faad14"}>{"Đã đồng bộ về CMIS"}</Tag>
      ),
      width: "8%",
    },
    // {
    //   title: "Tháng kiểm tra",
    //   dataIndex: "thangKiemTra",
    //   key: "thangKiemTra",
    //   render: (text) => (
    //     <div className="table-text-center">
    //       <span>{text}</span>
    //     </div>
    //   ),
    //   width: "7%",
    // },
    {
      title: "Loại khách hàng",
      dataIndex: "loaiKhachHang",
      key: "loaiKhachHang",
      render: (text, record) => (
        record.loaiKhachHang?.toString() === ENUM_MUCDICH_SDD.NGOAISINHHOAT ?
          <span>{"Ngoài sinh hoạt"}</span> :
          <span>{"Sinh hoạt"}</span>
      ),
      width: "8%",
    },
    {
      title: "Trạm",
      dataIndex: "tram",
      key: "tram",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: "Sổ GCS",
      dataIndex: "soGCS",
      key: "soGCS",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => <span>{text}</span>,
      width: "16%",
    },
    {
      title: "Mã bảng kê",
      dataIndex: "maBangKe",
      key: "maBangKe",
      render: (text) => <span>{text}</span>,
      width: "9%",
    },
    {
      title: "Tác vụ",
      dataIndex: "",
      key: "tacVu",
      render: (text, record) => (
        <div className="table-text-center">
          {record.pathFileBienBan ?
            <CheckPermission permissionCode={ap_gia.e33xb}>
              <Tooltip title="Xem biên bản">
                <a
                  href={record.pathFileBienBan}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FilePdfOutlined
                    style={{ fontSize: "22px" }}
                  />
                </a>
              </Tooltip>
            </CheckPermission>
            : null
          }
          {record.pathFileBienBanGiay ?
            <CheckPermission permissionCode={ap_gia.e33xb}>
              <Tooltip title="Xem BBAG giấy">
                <a
                  href={record.pathFileBienBanGiay}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FileOutlined
                    style={{ fontSize: "22px", marginLeft: '10px' }}
                  />
                </a>
              </Tooltip>
            </CheckPermission> : null}
        </div>
      ),
      width: "4%",
      fixed: "right"
    },
  ];

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: "3%",
    fixed: "left",
    getCheckboxProps: (record) => ({
      disabled:
        record.trangThai ===
        AP_GIA_TRANG_THAI.DA_DB_CMIS,
    }),
  };
  const handleDongBoCMIS = () => {
    setDataResponse([]);
    const tempData = dataGuiDuLieuCMIS.map( x => {
      x.loaiKT = x.loaiKhachHang === "0" && x.isChuyenApGia === false ? "0" : "1";
      return x
      })
    const listLoaiKH= tempData.filter(list => selectedRowKeys.includes(list.id)).map(e => e.loaiKT);
    if (
      listLoaiKH.every( (val, i, arr) => val === arr[0] )
    ) {
      Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: "Bạn muốn đồng bộ các yêu cầu đã chọn về CMIS ?",
      onOk() {
        submitDBYeuCauCMIS(
          listLoaiKH[0],
          setLoading,
          setFetchDataTable,
          selectedRowKeys,
          setSelectedRowKeys,
          setDataResponse
        )
      },
      onCancel() { },
      okText: "Đồng ý",
      okButtonProps: { type: "danger" },
      cancelText: "Hủy",
    });
    } else {
      notification.error({
        message: `Thông báo`,
        description: `Vui lòng chọn các yêu cầu có cùng loại kiểm tra!`,
      });
    }
    
  };

  const handleOpenModaTraLai = useCallback(() => {
    setVisibleTraLai(true);
  }, []);

  const handleCloseModalTraLai = () => {
    setVisibleTraLai(false);
    setLoading(false);
  };

  const showModalTraLai = useMemo(
    () =>
      visibleTraLai && (
        <TraLaiModal
          visible={visibleTraLai}
          handleCloseModalTraLai={handleCloseModalTraLai}
          setFetchDataTable={setFetchDataTable}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      ),
    [selectedRowKeys, visibleTraLai]
  );

  const genExtra = useCallback(
    () => (
      <div>
        <CheckPermission permissionCode={ap_gia.e33d}>
          <ButtonComponent
            type="primary"
            style={{ marginRight: "10px" }}
            onClick={() => handleDongBoCMIS()}
            disabled={selectedRowKeys.length === 0}
          >
            Đồng bộ về CMIS
          </ButtonComponent>
        </CheckPermission>
        <CheckPermission permissionCode={ap_gia.e33t}>
          <ButtonComponent
            type="primary"
            style={{ marginRight: "10px" }}
            onClick={() => handleOpenModaTraLai()}
            disabled={selectedRowKeys.length === 0}
          >
            Trả lại
          </ButtonComponent>
        </CheckPermission>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRowKeys]
  );
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          checkTraLai={checkTraLai}
          screen={"DB"}
        />
      </Filter>
      <Container>
        {
          dataResponse && dataResponse?.length > 0 &&
          <>
            <div style={{ color: "red", fontWeight: "bold ", marginLeft: 10 }}>Danh sách đồng bộ thất bại:</div>
            <div style={{ marginLeft: 20 }}>
              {dataResponse.map((item) => (
                <div >
                  <span>
                    <span style={{ fontWeight: "bold "}}>{item.maDiemDo}</span>: {item.noiDungLoi}
                  </span>
                </div>
              ))}
            </div>
          </>
        }
        <TableStyled>
          <TableComponent
            header={"Đồng bộ về CMIS"}
            handleSearch={handleSearch}
            dataSource={dataGuiDuLieuCMIS}
            columns={customColumn(columns, filterConditions)}
            totalData={totalCount}
            renderExtraAction={() => genExtra()}
            loading={loading}
            scrollX={3000}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            rowSelection={rowSelection}
            rowKey="id"
            onRow={detailDD}
          />
        </TableStyled>
      </Container>
      {showModalTraLai}
    </Fragment>
  );
}
