import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";

export const getListDonVi = (setLoading, setData) => {
  authGetData({
    url: `${Endpoint.GET_UNIT_LIST}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

//DASHBOARD_THANH_TOAN

// Don vi dang ky thanh toan
export const getListDonViDangKyThanhToan = (setLoading, setData, query) => {
  authGetData({
    url: `${Endpoint.DON_VI_DANG_KY_THANH_TOAN}?${query}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};
//GET_NGAN_HANG

export const getListNganHang = (setLoading, setData, query) => {
  authGetData({
    url: `${Endpoint.DANH_SACH_NGAN_HANG}?${query}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

//GET_LIST_TEAM
export const getListTeam = (setLoading, setData, donVi, form, setValueTeam) => {
  authGetData({
    url: `${Endpoint.GET_TEAM_LIST}?DonViId=${donVi}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
        form.setFieldsValue({
          DoiId: res.data[0].value,
        });
        setValueTeam(res.data[0].value);
      }
    },
  });
};
