import {
  DEBOUNCE_INPUT_SEARCH_DELAY,
  METHOD_DELETE,
  METHOD_GET,
  METHOD_POST,
  NAVIGATE_DANGNHAP,
  STATUSCODE_200,
  STATUSCODE_401,
  STATUSCODE_500,
  TOKEN_NAME,
} from "@utils/constants";
import { Modal, notification } from "antd";
import Axios from "axios";
import { useEffect, useState } from "react";
import { alertMessage, debounce } from "./function";
notification.config({
  maxCount: 1,
  duration: 2,
});
Axios.interceptors.response.use(
  (response) => {
    // do something with the response data

    if (response && response.data.statusCode === STATUSCODE_500) {
      notification.error({
        message: "Thông báo!",
        description: response.data.message,
      });
    }
    if (
      response &&
      response.data.statusCode === STATUSCODE_200 &&
      response.data.message
    ) {
      notification.success({
        message: "Thông báo!",
        description: response.data.message,
      });
    }
    return response;
  },
  (error) => {
    notification.config({
      maxCount: 1,
      duration: 4,
    });
    let mess = "";
    if (error?.response?.status === STATUSCODE_401) {
      window.location.href = NAVIGATE_DANGNHAP;
      localStorage.clear();
      return;
    }
    if (error && error.response) {
      mess = error.response.data.message;
      if (mess) {
        notification.error({
          message: "Thông báo!",
          description: (
            <p
              dangerouslySetInnerHTML={{
                __html: mess,
              }}
            />
          ),
        });
      }
    } else {
      notification.error({
        message: "Thông báo!",
        description: "Lỗi hệ thống",
        maxCount: 1,
      });
    }
    return error.response;
  }
);

async function defaultGet(endpoint) {
  return await Axios({
    method: METHOD_GET,
    url: endpoint,
  });
}

async function defaultPost(endpoint, method, payload) {
  const body = {};
  Object.keys(payload).forEach((key) => {
    body[key] = payload[key];

    if (
      payload[key] ||
      typeof payload[key] === "boolean" ||
      typeof payload[key] === "number"
    ) {
      body[key] = payload[key];
    }
    return null;
  });
  return await Axios({
    headers: {},
    method: method,
    url: endpoint,
    data: body,
  });
}

export async function authGet(endpoint) {
  const token = localStorage.getItem(TOKEN_NAME);
  return await Axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: METHOD_GET,
    url: endpoint,
  });
}

async function authDelete(endpoint) {
  const token = localStorage.getItem(TOKEN_NAME);
  return await Axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: METHOD_DELETE,
    url: endpoint,
  });
}

async function authPost(endpoint, method, payload) {
  const token = localStorage.getItem(TOKEN_NAME);
  const body = {};
  Object.keys(payload).forEach((key) => {
    if (
      payload[key] ||
      typeof payload[key] === "boolean" ||
      typeof payload[key] === "number"
    ) {
      body[key] = payload[key];
    }
    return {};
  });
  return await Axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: method,
    url: endpoint,
    data: body,
  });
}
export async function authPostFormData(endpoint, method, payload) {
  const token = localStorage.getItem(TOKEN_NAME);
  const body = {};

  Object.keys(payload).forEach((key) => {
    if (
      payload[key] ||
      typeof payload[key] === "boolean" ||
      typeof payload[key] === "number"
    ) {
      body[key] = payload[key];
    }
    return {};
  });
  const formData = new FormData();
  Object.keys(body).forEach((key) => {
    return formData.append(key, body[key]);
  });

  if (body.imageFile) {
    formData.append("file", body.imageFile);
  }
  return await Axios({
    headers: {
      Authorization: `Bearer ${token}`,
      ContentType: "multipart/form-data",
    },
    method: method,
    url: endpoint,
    data: formData,
  });
}

export async function authPostFileData({
  url,
  method,
  payload,
  setLoading,
  onSuccess,
}) {
  setLoading(true);
  try {
    const res = await authPostFormData(url, method, payload);
    if (res && res.data) {
      onSuccess(res.data);
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

export async function authPostData({
  url,
  method,
  payload,
  setLoading,
  onSuccess,
}) {
  setLoading(true);
  try {
    const res = await authPost(url, method, payload);
    if (res && res.data) {
      onSuccess(res.data);
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

export async function authGetData({ url, setLoading, onSuccess }) {
  setLoading(true);
  try {
    const res = await authGet(url);
    if (res && res.data) {
      onSuccess(res.data);
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

export async function startDelete({ url, setLoading, onSuccess }) {
  setLoading(true);
  try {
    const res = await authDelete(url);
    if (res && res.data) {
      onSuccess(res.data);
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}
export function authDeleteData({
  url,
  setLoading,
  onSuccess,
  content = "Bạn có chắc chắn muốn xóa !",
  title = "Xác nhận",
}) {
  Modal.confirm({
    centered: true,
    title,
    content,
    onOk() {
      startDelete({ url, setLoading, onSuccess });
    },
    onCancel() {},
    okText: "Đồng ý",
    okButtonProps: { type: "danger" },
    cancelText: "Hủy",
  });
}

export async function getData({ url, setLoading, onSuccess }) {
  setLoading(true);
  try {
    const res = await defaultGet(url);
    if (res && res.data) {
      onSuccess(res.data);
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

export async function postData({
  url,
  payload,
  method = METHOD_POST,
  setLoading,
  onSuccess,
}) {
  setLoading(true);
  try {
    const res = await defaultPost(url, method, payload);
    if (res && res.data) {
      onSuccess(res.data);
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

async function authPut(endpoint, method, body) {
  const token = localStorage.getItem(TOKEN_NAME);
  return await Axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: method,
    url: endpoint,
    data: body,
  });
}

export async function authPutData({
  url,
  method,
  payload,
  setLoading,
  onSuccess,
}) {
  setLoading(true);
  try {
    const res = await authPut(url, method, payload);
    if (res && res.data) {
      onSuccess(res.data);
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

export async function authGetMultiplesRequest({
  endpoints,
  setLoading,
  onSuccess,
}) {
  setLoading(true);
  try {
    await Axios.all(endpoints.map((endpoint) => authGet(endpoint))).then(
      (data) => {
        if (data.length) {
          onSuccess(data);
        }
      }
    );
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

export const debounceGetData = debounce(
  authGetData,
  DEBOUNCE_INPUT_SEARCH_DELAY
);

function getFileName(response) {
  let filename = "";
  const disposition = response.headers["content-disposition"];
  if (disposition && disposition.indexOf("filename") !== -1) {
    const filenameRegex = /UTF-8(.*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = decodeURIComponent(matches[1].replace(/['"]/g, ""));
    }
  }
  return filename;
}
export async function downloadFile({ endpoint, setLoading }) {
  setLoading(true);
  const token = localStorage.getItem(TOKEN_NAME);
  try {
    const res = await Axios({
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
      method: "GET",
      url: endpoint,
    });

    const fileName = getFileName(res);
    if (res && res.data && res.status === 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName ? fileName : "template.xlsx");
      document.body.appendChild(link);
      link.click();
    }
    if (res && res.data && res.status === 422) {
      return notification.error({
        message: `Hãy nhập đủ điều kiện tìm kiếm`,
      });
    } else if (fileName === "") {
      alertMessage(
        "error",
        "Thông báo",
        res.message ? res.message : "Dữ liệu không tìm thấy"
      );
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

export async function downloadFilePostData({
  endpoint,
  setLoading,
  query,
  errorMess,
}) {
  setLoading(true);
  const token = localStorage.getItem(TOKEN_NAME);
  try {
    const res = await Axios({
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
      method: "POST",
      url: endpoint,
      data: query,
    });
    const fileName = getFileName(res);
    if (res && res.data && res.status === 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName ? fileName : "template.xlsx");
      document.body.appendChild(link);
      link.click();
    }
    if (res && res.data && res.status === 400) {
      console.log(query);
      notification.error({
        message: "Thông báo",
        description: `${
          query?.tramIds?.length === 0 ? "Hãy chọn trạm" : errorMess
        }`,
      });
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

async function authPostImageFormData(endpoint, method, payload) {
  const token = localStorage.getItem(TOKEN_NAME);
  const formData = new FormData();
  if (payload) {
    payload.forEach((file) => {
      formData.append("fileExcel", file);
    });
  }
  return await Axios({
    headers: {
      Authorization: `Bearer ${token}`,
      ContentType: "multipart/form-data",
    },
    method: method,
    url: endpoint,
    data: formData,
  });
}

export async function authPostImageData({
  url,
  method,
  payload,
  setLoading,
  onSuccess,
}) {
  setLoading(true);
  try {
    const res = await authPostImageFormData(url, method, payload);
    if (res && res.data) {
      onSuccess(res.data);
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

export const useCustomDebounce = (value, time = 600) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), time);
    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return debouncedValue;
};
