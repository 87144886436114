import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { selectBcthsmsChuDong } from "../service";

export default function Bieu5(props) {
  const { screen, handleChange } = props;

  const [loading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // authPostData({
    //   url: ``,
    //   method: "POST",
    //   setLoading,
    //   payload: values,
    //   onSuccess: (res) => {
    //     console.log("res", res);
    //   },
    // });
    console.log("values", values);
  };

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Tin nhắn chủ động mo_mt
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              initialValues={{ reportName: screen }}
            >
              <Form.Item
                name="reportName"
                label="Tên báo cáo"
                labelAlign="left"
              >
                <Select
                  options={selectBcthsmsChuDong}
                  onChange={() => {
                    handleChange(form.getFieldValue("reportName"));
                  }}
                ></Select>
              </Form.Item>

              <Form.Item
                name="reportType"
                label="Loại báo cáo"
                labelAlign="left"
              >
                <Selection
                  url={Endpoint.GET_UNIT_LIST}
                  formKey="reportType"
                  form={form}
                />
              </Form.Item>

              <Form.Item
                name="reportStartDate"
                label="Ngày bắt đầu"
                labelAlign="left"
              >
                <DatePickerComponent
                  picker="Day"
                  formKey="reportStartDate"
                  form={form}
                />
              </Form.Item>

              <Form.Item
                name="reportEndDate"
                label="Ngày kết thúc"
                labelAlign="left"
              >
                <DatePickerComponent
                  picker="Day"
                  formKey="reportEndDate"
                  form={form}
                />
              </Form.Item>

              <Row>
                <Col span={4}></Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>

                  <Button
                    type="primary"
                    loading={loading}
                    className="ml-20px"
                    // onClick={() => {
                    //   handleOpenPdf(form.getFieldValue(), setLoading);
                    // }}
                  >
                    In bảng kê
                  </Button>
                </Col>
              </Row>
            </FormComponent>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
