import CheckPermission from "@components/CheckPermission";
import LoadingComponent from "@components/Loading";
import { treo_thao } from "@permissions/b_treothao";
import { authSelector } from "@state/auth";
import { ENUM_DS_YEU_CAU, FUNC_ID_DSYC_TREOTHAO } from "@utils/constants";
import { Button, Col, Form, Input, Modal, Row, Table } from "antd";
import { useCallback } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import DanhSachYeuCau from "../../common-ver1/danh-sach-yeu-cau";
import { dongBoYeuCauCMIS } from "./services";
import ModalNoti from "./modal";
import { callAPICancelYC } from "@modules/treo-thao/doi-truong/yc-da-xuat-vat-tu/services";

const { TextArea } = Input

export default function Index() {
  const { user } = useSelector(authSelector);
  const [fetchList, setFetchList] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [arrSelect, setArrSelectRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [donViId, setDonViId] = useState(user.unitId);
  const [isTongCongTy, setIsTongCongTy] = useState(
    user.isTongCongTy ? true : false
  );
  const [isSeach, setIsSearch] = useState(user.isTongCongTy ? false : true);

  //button nhận yêu cầu
  const genExtra = () => (
    <>
    <CheckPermission permissionCode={treo_thao.b12h}>
      <Button
        danger
        className="mr-5px"
        disabled={!selectedRowKeys.length}
        onClick={() => handleDeleteYC()}
        loading={loading}
      >
        Hủy yêu cầu
      </Button>
    </CheckPermission>
    <CheckPermission permissionCode={treo_thao.b12db}>
      <Button
        type="primary"
        onClick={handleDongBo}
        loading={loading}
        disabled={!selectedRowKeys.length || isTongCongTy || !isSeach}
      >
        Đồng bộ dữ liệu về CMIS
      </Button>
    </CheckPermission>
    </>
   
  );

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys, arrSelectRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    setArrSelectRowKeys(arrSelectRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  const [visible, setVisible] = useState(false);
  const [dataNoti, setDataNoti] = useState({});
  const handleCloseModal = () => {
    setVisible(false);
  };

  const showModalNoti = useCallback(() => {
    return (
      visible && (
        <ModalNoti
          visible={visible}
          closeModal={handleCloseModal}
          data={dataNoti}
        />
      )
    );
  }, [visible, dataNoti]);

  const handleDongBo = useCallback(() => {
    if (selectedRowKeys.length > 0) {
      const arrDDKError = arrSelect.filter((el) => el.ddkError === true);
      dongBoYeuCauCMIS(
        selectedRowKeys,
        setLoading,
        setFetchList,
        setSelectedRowKeys,
        donViId,
        setIsSearch,
        setVisible,
        setDataNoti,
        arrDDKError
      );
    }
  }, [arrSelect, selectedRowKeys, donViId]);

  const [visibleCancel, setVisibleCancel] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [dataErrors, setDataErrors] = useState([]);
  const [form] = Form.useForm()
  const handleDeleteYC = useCallback(() => {
    setVisibleCancel(true);
  }, []);

  const handleCloseModalCancel = () => {
    setVisibleCancel(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const renderModalCancel = useCallback(() => {
    form.resetFields();

    return (
      visibleCancel && (
        <Modal
          title="Hủy yêu cầu"
          visible={visibleCancel}
          onCancel={handleCloseModalCancel}
          className="modal-bpag-tra-lai"
          footer={null}
          width={"25%"}
        >
          <Form
            form={form}
            name="form-cancel"
            layout="vertical"
            onFinish={onFinish}
          >
            <Row gutter={24}>
              <Col span={24}>
                <h4>Bạn có chắc rằng muốn hủy yêu cầu này?</h4>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="lyDoHuy"
                  label="Lý do hủy"
                  rules={[
                    {
                      required: true,
                      message: "Lý do hủy không được để trống",
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea rows={3} />
                </Form.Item>
              </Col>
            </Row>
            <br />
            <Row gutter={24} justify="center">
              <Button
                key="back"
                onClick={handleCloseModalCancel}
                className="button-closed mr-24px"
              >
                Đóng lại
              </Button>
              <Button
                key="submit"
                type="primary"
                className="button-primary mr-24px"
                htmlType="submit"
                form="form-cancel"
              >
                Hủy
              </Button>
            </Row>
          </Form>
        </Modal>
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, visibleCancel]);

  const onFinish = (value) => {
    const payload = {
      ids: selectedRowKeys,
      lyDoHuy: value.lyDoHuy,
    };
    callAPICancelYC(
      payload,
      setLoading,
      setFetchList,
      setSelectedRowKeys,
      handleCloseModalCancel,
      setShowPopup,
      setDataErrors
    );
  };

  const renderPopup = useCallback(() => {
    return (
      showPopup && (
        <Modal
          title="Danh sách yêu cầu lỗi"
          visible={visibleCancel}
          onCancel={handleClosePopup}
          footer={null}
          width={"25%"}
        >
          <h3>
            Số yêu cầu thành công:
            <span
              className="total-success"
              style={{ color: "#52c41a", fontWeight: "bold" }}
            >
              {" "}
              {dataErrors?.soYeuCauSuccess}
            </span>
          </h3>
          <h3>
            Số yêu cầu thất bại:
            <span
              className="total-fail"
              style={{ color: "#ff8e8f", fontWeight: "bold" }}
            >
              {" "}
              {dataErrors?.soYeuCauError}
            </span>
          </h3>
          <Table
            scroll={{ y: 300 }}
            dataSource={dataErrors.yeuCauErrors}
            columns={[
              {
                title: "STT",
                dataIndex: "stt",
                key: "stt",
                width: "10%",
                // render: (text, record, index) => (
                //   <div className="table-text-center">{index + 1}</div>
                // ),
              },
              {
                title: "Mã điểm đo",
                dataIndex: "maDiemDo",
                key: "maDiemDo",
                width: "30%",
              },
              {
                title: "Ghi chú",
                dataIndex: "errorMessage",
                key: "errorMessage",
                width: "60%",
              },
            ]}
          />
          <div
            style={{
              textAlign: "center",
              paddingTop: "24px",
            }}
          >
            <Button
              key="back"
              type="default"
              onClick={() => handleClosePopup()}
            >
              Đóng lại
            </Button>
          </div>
        </Modal>
      )
    );
  }, [
    dataErrors?.soYeuCauError,
    dataErrors?.soYeuCauSuccess,
    dataErrors.yeuCauErrors,
    showPopup,
    visibleCancel,
  ]);


  return (
    <LoadingComponent loading={loading}>
      <DanhSachYeuCau
        funcId={FUNC_ID_DSYC_TREOTHAO.PKD_NGHIEMTHU}
        typeList={ENUM_DS_YEU_CAU.PKD_NGHIEMTHUYEUCAU}
        genExtra={genExtra}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        rowSelection={rowSelection}
        setSelectedRowKeys={setSelectedRowKeys}
        setDonViId={setDonViId}
        setIsTongCongTy={setIsTongCongTy}
        setIsSearch={setIsSearch}
        permissionCodeBB={treo_thao.b12bb}
      />
      {showModalNoti()}
      {renderModalCancel()}
      {renderPopup()}
    </LoadingComponent>
  );
}
