import FormComponent from "@components/Form";
import TableComponent from "@components/TableComponent";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_ENGLISH,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  formatDateWithTimezone,
  parseParams,
} from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  Spin,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { selectBCDOISOATEP } from "./service";

export default function Bieu1Thsl(props) {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [form] = Form.useForm();
  const [dataTable, setDataTable] = useState([]);
  const { screen, handleChange } = props;
  const [filterConditions, setFilterConditions] = useState({
    ...parseParams(location.search),
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
    DenNgay: moment().format(FORMAT_ENGLISH),
  });
  const onFinish = (values) => {
    console.log("values", values);
    const queryString = buildQueryString(
      parseParams({
        TuNgay: filterConditions.TuNgay,
        DenNgay: filterConditions.DenNgay,
      })
    );
    authGetData({
      url: `${Endpoint.BCBEP_TH_TIN_NHAN}?${queryString}`,
      setLoading,
      onSuccess: (res) => {
        if (res) {
          console.log(res);
          setDataTable(
            res.data.map((item, index) => {
              return {
                ...item,
                STT: index + 1,
              };
            })
          );
        }
      },
    });
  };

  const onDownloadExcel = () => {
    const queryString = buildQueryString(
      parseParams({
        TuNgay: filterConditions?.TuNgay,
        DenNgay: filterConditions?.DenNgay,
      })
    );
    const endpoint = `${Endpoint.BCBEP_TH_TIN_NHAN_EXCEL}?${queryString}`;
    if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
  };

  const columns = [
    {
      title: "STT",
      align: "center",
      width: 50,
      dataIndex: "STT",
      key: "STT",
    },
    {
      title: "Đơn vị",
      width: 150,
      dataIndex: "TEN_DVIQLY",
      key: "TEN_DVIQLY",
    },
    {
      width: 120,
      title: "Số lượng chưa nhắn",
      dataIndex: "SL_CHUANHAN",
      key: "SL_CHUANHAN",
      align: "center",
    },
    {
      title: "Tỉ lệ chưa nhắn",
      width: 70,
      dataIndex: "TYLE_CHUANHAN",
      align: "center",
      key: "TYLE_CHUANHAN",
    },
    {
      width: 70,
      title: "Số lượng đã nhắn",
      align: "center",
      dataIndex: "SL_DANHAN",
      key: "SL_DANHAN",
    },
    {
      width: 70,
      title: "Tỷ lệ đã nhắn",
      align: "center",
      dataIndex: "TYLE_NHAN",
      key: "SLTT",
    },
    {
      width: 70,
      title: "Số lượng lỗi",
      align: "center",
      dataIndex: "SL_LOI",
      key: "SL_LOI",
    },
    {
      width: 70,
      title: "Tỷ lệ lỗi",
      align: "center",
      dataIndex: "TYLE_LOI",
      key: "TYLE_LOI",
    },
    {
      width: 70,
      title: "Tổng số",
      align: "center",
      dataIndex: "TONG",
      key: "TONG",
    },
  ];

  const handleStartDate = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      TuNgay: formatDateWithTimezone(e?._d),
    }));
  };

  const handleEndDate = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      DenNgay: formatDateWithTimezone(e?._d),
    }));
  };

  return (
    <Container>
      <ContentWrapper>
        <Spin spinning={loading}>
          <FormComponent
            onFinish={onFinish}
            initialValues={{ reportType: screen }}
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
          >
            <Row>
              <Col span={22}>
                <Form.Item
                  name="reportType"
                  label="Tên báo cáo"
                  labelAlign="left"
                >
                  <Select
                    placeholder="Tất cả"
                    options={selectBCDOISOATEP}
                    onChange={(e) => handleChange(e)}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={8}>
                <Form.Item
                  name="TuNgay"
                  label="Ngày bắt đầu"
                  labelAlign="left"
                  labelCol={{ span: 11 }}
                  wrapperCol={{ span: 10 }}
                >
                  <DatePicker
                    picker="Day"
                    formKey="TuNgay"
                    defaultValue={
                      filterConditions.TuNgay
                        ? moment(filterConditions.TuNgay, FORMAT_ENGLISH)
                        : moment().startOf("month").format(FORMAT_ENGLISH)
                    }
                    form={form}
                    format="DD/MM/YYYY"
                    onChange={handleStartDate}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="DenNgay"
                  label="Ngày kết thức"
                  labelAlign="right"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 10 }}
                >
                  <DatePicker
                    picker="Day"
                    formKey="DenNgay"
                    defaultValue={
                      filterConditions.DenNgay
                        ? moment(filterConditions.DenNgay, FORMAT_ENGLISH)
                        : moment()
                    }
                    form={form}
                    format="DD/MM/YYYY"
                    onChange={handleEndDate}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ paddingLeft: 40 }}>
                {" "}
                <Button type="primary" htmlType="submit" className="mr-5px">
                  Xem báo cáo
                </Button>
                <Button type="primary" onClick={onDownloadExcel}>
                  Xuất Excel
                </Button>
              </Col>
            </Row>
            <Row justify="end"></Row>
          </FormComponent>
          <Divider className="section-text-line" orientation="left"></Divider>
          {dataTable && dataTable?.length > 0 ? (
            <TableComponent
              header={"Kết quả hiển thị"}
              columns={columns}
              dataSource={dataTable}
              scrollX={1200}
            />
          ) : (
            <TableComponent
              header={"Kết quả hiển thị"}
              columns={columns}
              dataSource={null}
              scrollX={1200}
            />
          )}
        </Spin>
      </ContentWrapper>
    </Container>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
