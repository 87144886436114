import React, { useEffect, useState } from "react";
import LoadingComponent from "@components/Loading";
import { Col, Collapse, Row } from "antd";
import FormComponent from "@components/Form";
import { LOAI_YEUCAU } from "@utils/constants";
import ThongTinChung from "./panels/thong-tin-chung";
import DanhSachVT from "./panels/danh-sach-vt";
import { xemPhieu } from "./services";
const { Panel } = Collapse;

export default function Index(props) {
  const { detail, typeTreoThao } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columnsTreoThao = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "10%",
    },

    {
      title: "Chủng loại",
      dataIndex: "chungLoai",
      key: "chungLoai",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "50%",
    },
    {
      title: "Loại công tơ",
      dataIndex: "loaiCongTo",
      key: "loaiCongTo",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "20%",
    },
    {
      title: "Mô tả",
      dataIndex: "moTa",
      key: "moTa",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "20%",
    },
    {
      title: "Số lượng xuất",
      dataIndex: "soLuongXuat",
      key: "soLuongXuat",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "20%",
    },
  ];

  const columnsCapDien = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "10%",
    },

    {
      title: "Mã vật tư",
      dataIndex: "maVatTu",
      key: "maVatTu",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "50%",
    },
    {
      title: "Tên vật tư",
      dataIndex: "tenVatTu",
      key: "tenVatTu",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "20%",
    },
    {
      title: "Số lượng",
      dataIndex: "soLuong",
      key: "soLuong",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "20%",
    },
    {
      title: "Đơn vị tính",
      dataIndex: "donViTinh",
      key: "donViTinh",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "20%",
    },
  ];

  useEffect(() => {
    xemPhieu(detail.id, setLoading, setData);
  }, [detail.id, setLoading]);

  return (
    <LoadingComponent loading={loading}>
      <FormComponent name="basic" layout="horizontal" autoComplete="off">
        <Row
          gutter={24}
          justify="center"
          style={{ marginTop: "24px", marginBottom: "24px" }}
        >
          <Col span={12} style={{ textAlign: "center" }}>
            <h5 className="fs-14px font-bold">
              TỔNG CÔNG TY ĐIỆN LỰC TP HÀ NỘI
            </h5>
            <h5 className="fs-14px font-bold">Công ty Điện lực Cầu GIấy </h5>
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            <h5 className="fs-14px font-bold">PHIẾU XUẤT VẬT TƯ</h5>
            <h5 className="fs-14px font-bold">Mã phiếu: PD01XK22041400001</h5>
          </Col>
        </Row>
        <Collapse defaultActiveKey={["1"]}>
          <Panel header="Thông tin chung" key="1">
            <ThongTinChung />
          </Panel>
          <Panel header="Danh sách vật tư" key="2">
            <DanhSachVT
              data={data}
              columns={
                typeTreoThao === LOAI_YEUCAU.TREOTHAO
                  ? columnsTreoThao
                  : columnsCapDien
              }
            />
          </Panel>
        </Collapse>
        <Row gutter={24} justify="center" style={{ marginTop: "48px" }}>
          <Col span={8} style={{ textAlign: "center" }}>
            <h5 className="fs-14px font-bold">ĐỘI TRƯỞNG </h5>
          </Col>
          <Col span={8} style={{ textAlign: "center" }}>
            <h5 className="fs-14px font-bold">PHÒNG VẬT TƯ </h5>
          </Col>
          <Col span={8} style={{ textAlign: "center" }}>
            <h5 className="fs-14px font-bold">LÃNH ĐẠO </h5>
          </Col>
        </Row>
      </FormComponent>
    </LoadingComponent>
  );
}
