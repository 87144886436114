import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";

export const getListDongBoCmis = (
  setDataDongBoCmis,
  setLoading,
  thang,
  nam
) => {
  authGetData({
    url: `${Endpoint.GNDN_LIST_DONGBOVECMIS}?thang=${thang}&nam=${nam}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataDongBoCmis(res.data);
      } else {
        setDataDongBoCmis([]);
      }
    },
  });
};

export const getListChiSoCmis = (
  setDataChiSoCmis,
  setLoading,
  thang,
  nam,
  donViId
) => {
  authGetData({
    url: `${Endpoint.GNDN_LIST_CHISOCMIS}?thang=${thang}&nam=${nam}&Id=${donViId}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataChiSoCmis(res.data);
      } else {
        setDataChiSoCmis([]);
      }
    },
  });
};

export const handleDongBoCmis = (
  setFetchList,
  setLoading,
  data,
  setSelectedRowKeys
) => {
  authPostData({
    url: `${Endpoint.GNDN_DONGBOVECMIS}`,
    setLoading,
    method: METHOD_POST,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
      }
      setSelectedRowKeys([]);
    },
  });
};
