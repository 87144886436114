import TrangChu from "@modules/cham-soc-khach-hang";
import KySoTaoFile from "@modules/cham-soc-khach-hang/kyso_hsm/ky-so-tao-file/ky-so-tao-file";
import HinhThucEmailZalo from "@modules/cham-soc-khach-hang/nhan-tin-email-zalo/hinh-thuc-email-zalo";
import HinhThucSMS from "@modules/cham-soc-khach-hang/nhan-tin-email-zalo/hinh-thuc-sms";

import NoiDungCuPhap from "@modules/cham-soc-khach-hang/tra-cuu-tien-ich/noi-dung-cu-phap";
import ThongTinDoiTuong from "@modules/cham-soc-khach-hang/tra-cuu-tien-ich/thong-tin-doi-tuong";
import ThongTinKhachHang from "@modules/cham-soc-khach-hang/tra-cuu-tien-ich/thong-tin-khach-hang";
import TimKiemTinNhan from "@modules/cham-soc-khach-hang/tra-cuu-tien-ich/tim-kiem-tin-nhan";

import KySoXacNhan from "@modules/cham-soc-khach-hang/kyso_hsm/ky-so-xac-nhan/ky-so-xac-nhan";
import KyFile from "@modules/cham-soc-khach-hang/kyso_hsm/ky-so/ky-file";

// baocao
// import BaoCaoDoiSoat from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/bc-doi-soat-sms/tien-dien-lan-1/bieu1-tn-tong-cong-ty";
import BaoCaoDoiSoatAppCSKH from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/bc-doi-soat-appcskh";
import BaoCaoDoiSoatDTXD from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/bc-doi-soat-dtxd";
import THTinhHinhGuiThongBaoChoKH from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/bc-doi-soat-email/tong-hop-tinh-hinh-gui-thong-bao";
import BaoCaoDoiSoatEP from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/bc-doi-soat-ep";
import B09THKhachHang from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/bc-doi-soat-zalo/b09-th-khach-hang";
import BaoCaoSanLuongTheoDonVi from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/bc-kiem-soat-sms/bc-sl-tn-theo-don-vi/bieu1A";
import BaoCaoTongHop from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/bc-tong-hop";
import DSKHHuyDichVuSMS from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/dskh-huy-dich-vu-sms";
import TinNhanChuyenDoiSo from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/tin-nhan-chuyen-doi-so";

import BrandName from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/bc-doi-soat-brand-name";
import Bieu1ThslTinNhanToanTongCty from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/bc-doi-soat-kh/bieu1-thsl-tin-nhan-toan-tong-cty";
import TabsPaneBaoCao from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/bc-doi-soat-sms";
import Bieu2ChiTietSLTinNhan from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/bc-doi-soat-sms-covid2/bieu2-chi-tiet-sl-tin-nhan";
import Bieu2ChiTietSLTinNhanCV4 from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/bc-doi-soat-sms-covid4/bieu2-chi-tiet-sl-tin-nhan";
import THSlFileKySo from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/bc-ky-so/tong-hop/thsl-file-ky-so";
// import Bieu1 from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/tin-nhan-mo-mt/bieu1";
import { cham_soc_khach_hang } from "@permissions/j_cskh";
import { MODULE_CHAMSOCKHACHHANG } from "@utils/constants";
import TinNhanMOMT from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/tin-nhan-mo-mt";
import BaoCaoCongTinNhanCSKH from "@modules/cham-soc-khach-hang/tong-hop-bao-cao-doi-soat/bc-cong-tin-nhan-cskh";

const mainPath = "/cham-soc-khach-hang";
const routes = [
  {
    title: "",
    path: mainPath,
    privateRoute: true,
    component: TrangChu,
    subItems: [],
    permissionCode: MODULE_CHAMSOCKHACHHANG,
  },
  // ky so hsm
  {
    title: "Ký số HSM",
    path: mainPath + "/ky-so-hsm",
    privateRoute: true,
    permissionCode: cham_soc_khach_hang.j1,
    subItems: [
      {
        subTitle: "Tạo file",
        subPath: "/ky-so-tao-file",
        subComponent: KySoTaoFile,
        permissionCode: cham_soc_khach_hang.j11,
      },
      {
        subTitle: "Xác nhận",
        subPath: "/ky-so-xac-nhan",
        subComponent: KySoXacNhan,
        permissionCode: cham_soc_khach_hang.j12,
      },
      {
        subTitle: "Ký số",
        subPath: "/ky-so",
        subComponent: KyFile,
        permissionCode: cham_soc_khach_hang.j13,
      },
    ],
  },
  // nhan tin cho khach hang
  {
    title: "Nhắn tin cho khách hàng",
    path: mainPath + "/nhan-tin-cho-khach-hang",
    privateRoute: true,
    permissionCode: cham_soc_khach_hang.j2,
    subItems: [
      {
        subTitle: "Hình thức Email, Zalo, App",
        subPath: "/hinh-thuc-email-zalo-app",
        subComponent: HinhThucEmailZalo,
        permissionCode: cham_soc_khach_hang.j21,
      },
      {
        subTitle: "Hình thức SMS",
        subPath: "/hinh-thuc-sms",
        subComponent: HinhThucSMS,
        permissionCode: cham_soc_khach_hang.j22,
      },
    ],
  },
  {
    title: "Tổng hợp báo cáo đối soát",
    path: mainPath + "/tong-hop-bao-cao-doi-soat",
    privateRoute: true,
    permissionCode: cham_soc_khach_hang.j3,
    subItems: [
      {
        subTitle: "Báo cáo kiểm soát SMS",
        subPath: "/bao-cao-kiem-soat-sms",
        subComponent: BaoCaoSanLuongTheoDonVi,
        permissionCode: cham_soc_khach_hang.j31,
      },
      {
        subTitle: "Tin nhắn MO-MT",
        subPath: "/tin-nhan-mo-mt",
        permissionCode: cham_soc_khach_hang.j32,
        subComponent: TinNhanMOMT,
      },
      {
        subTitle: "Báo cáo đối soát SMS",
        subPath: "/bao-cao-doi-soat-sms",
        subComponent: TabsPaneBaoCao,
        permissionCode: cham_soc_khach_hang.j33,
      },
      {
        subTitle: "Báo cáo đối soát Email",
        subPath: "/bao-cao-doi-soat-email",
        subComponent: THTinhHinhGuiThongBaoChoKH,
        permissionCode: cham_soc_khach_hang.j34,
      },
      {
        subTitle: "Báo cáo đối soát Zalo",
        subPath: "/bao-cao-doi-soat-zalo",
        permissionCode: cham_soc_khach_hang.j35,
        subComponent: B09THKhachHang,
      },
      {
        subTitle: "Báo cáo đối soát App CSHK",
        subPath: "/bao-cao-doi-soat-app-cskh",
        permissionCode: cham_soc_khach_hang.j36,
        subComponent: BaoCaoDoiSoatAppCSKH,
      },
      {
        subTitle: "Báo cáo tổng hợp",
        subPath: "/bao-cao-tong-hop",
        permissionCode: cham_soc_khach_hang.j37,
        subComponent: BaoCaoTongHop,
      },
      {
        subTitle: "Báo cáo đối soát EP",
        subPath: "/bao-cao-doi-soat-ep",
        permissionCode: cham_soc_khach_hang.j38,
        subComponent: BaoCaoDoiSoatEP,
      },
      {
        subTitle: "Báo cáo ký số",
        subPath: "/bao-cao-ky-so",
        permissionCode: cham_soc_khach_hang.j39,
        subComponent: THSlFileKySo,
      },
      {
        subTitle: "Đối soát SMS - Covid lần 2",
        subPath: "/doi-soat-sms-covid-lan-2",
        permissionCode: cham_soc_khach_hang.j310,
        subComponent: Bieu2ChiTietSLTinNhan,
      },
      {
        subTitle: "Đối soát Brandname",
        subPath: "/doi-soat-brandname",
        permissionCode: cham_soc_khach_hang.j320,
        subComponent: BrandName,
      },
      {
        subTitle: "Đối soát SMS - Covid lần 4",
        subPath: "/doi-soat-sms-covid-lan-4",
        permissionCode: cham_soc_khach_hang.j330,
        subComponent: Bieu2ChiTietSLTinNhanCV4,
      },
      {
        subTitle: "Danh sách khách hàng hủy dịch vụ SMS",
        subPath: "/danh-sach-khach-hang-huy-dich-vu-sms",
        permissionCode: cham_soc_khach_hang.j340,
        subComponent: DSKHHuyDichVuSMS,
      },
      {
        subTitle:
          "Báo cáo đối soát khách hàng được gửi thông báo TĐ qua App CSKH",
        subPath: "/bao-cao-doi-soat-khach-hang",
        permissionCode: cham_soc_khach_hang.j350,
        subComponent: Bieu1ThslTinNhanToanTongCty,
      },
      {
        subTitle: "Tin nhắn chuyển đổi số",
        subPath: "/tin-nhan-chuyen-doi-so",
        permissionCode: cham_soc_khach_hang.j360,
        subComponent: TinNhanChuyenDoiSo,
      },
      {
        subTitle: "Báo cáo đối soát ĐTXD",
        subPath: "/bao-cao-doi-soat-dtxd",
        permissionCode: cham_soc_khach_hang.j370,
        subComponent: BaoCaoDoiSoatDTXD,
      },
      {
        subTitle: "Báo cáo cổng tin nhắn cskh",
        subPath: "/bao-cao-cong-tin-nhan-cskh",
        permissionCode: cham_soc_khach_hang.j380,
        subComponent: BaoCaoCongTinNhanCSKH,
      },
    ],
  },
  // Tra cứu
  {
    title: "Tra cứu - Tiện ích",
    path: mainPath + "/tra-cuu-tien-ich",
    privateRoute: true,
    permissionCode: cham_soc_khach_hang.j4,
    subItems: [
      {
        subTitle: "Tra cứu thông tin khách hàng",
        subPath: "/tra-cuu-thong-tin-khach-hang",
        subComponent: ThongTinKhachHang,
        permissionCode: cham_soc_khach_hang.j41,
      },

      {
        subTitle: "Nội dung cú pháp",
        subPath: "/noi-dung-cu-phap",
        subComponent: NoiDungCuPhap,
        permissionCode: cham_soc_khach_hang.j42,
      },
      {
        subTitle: "Tìm kiếm tin nhắn",
        subPath: "/tim-kiem-tin-nhan",
        subComponent: TimKiemTinNhan,
        permissionCode: cham_soc_khach_hang.j43,
      },
      {
        subTitle: "Thông tin đối tượng",
        subPath: "/thong-tin-doi-tuong",
        subComponent: ThongTinDoiTuong,
        permissionCode: cham_soc_khach_hang.j45,
      },
    ],
  },
];
export default routes;
