import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
import moment from "moment";

export const selectTNCDS = [
  {
    label:
      "1. Tổng hợp số lượng KH đăng ký nhận thông báo qua Email, App EVNHANOI, Epoint, Zalo",
    value: Endpoint.TNCDS_THSL_KH_DANG_KY_NHAN_THONG_BAO_EMAIL_ZALO,
  },
  {
    label: "2. Chi tiết KH đăng ký nhận thông báo qua Email",
    value: Endpoint.TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_EMAIL,
  },
  {
    label: "3. Chi tiết KH đăng ký nhận thông báo qua App	",
    value: Endpoint.TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_APP,
  },
  {
    label: "4. Chi tiết KH đăng ký nhận thông báo qua Zalo",
    value: Endpoint.TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_ZALO,
  },
  {
    label: "5. Chi tiết KH đăng ký nhận thông báo qua Epoint",
    value: Endpoint.TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_EPOINT,
  },
  {
    label: "6. Chi tiết TB Tiền điện lần 1 gửi qua Email, App, Epoint, Zalo	",
    value: Endpoint.TNCDS_CT_TB_TIEN_DIEN_1_LAN_GUI_EMAIL_ZALO,
  },
  {
    label:
      "7. Chi tiết TB Tiền điện lần 2 gửi qua Email, Zalo qua cổng CSKH",
    value: Endpoint.TNCDS_CT_TB_TIEN_DIEN_2_GUIQUA_EMZA8055,
  },
  {
    label: "8. Tổng hợp số lượng tin nhắn qua các hình thức	",
    value: Endpoint.TNCDS_CT_TB_THSL_TIN_NHAN_QUA_CAC_HINH_THUC,
  },
  {
    label: "9. Tổng hợp số lượng KH đăng ký trung ID_ZALO	",
    value: Endpoint.TNCDS_CT_TB_THSL_KH_DANG_KY_ID_ZALO,
  },
  {
    label: "10. Tổng hợp số lượng KH sử dụng hệ sinh thái",
    value: Endpoint.TNCDS_CT_TB_THSL_DANG_KY_SU_DUNG_HST,
  },
  {
    label: "11. Tổng hợp số lượng KH nhận thông tin qua Zalo",
    value: Endpoint.TNCDS_CT_TB_THSL_NHAN_THONG_TIN_QUA_ZALO,
  },
  {
    label: "12. Chi tiết số lượng KH hủy nhận thông báo qua Hệ sinh thái",
    value: Endpoint.TNCDS_CT_TB_KH_HUY_TB_HST,
  },
  {
    label: "13. Chi tiết danh sách các khách hàng quản lý của Công ty Điện lực",
    value: Endpoint.TNCDS_CT_DS_KH_QUAN_LY_CUA_CTY_DIEN_LUC,
  },
];

export const exportData = (data, setLoading, setData) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      TenCuPhap: data.TenCuPhap,
      MaKhachHang: data.MaKhachHang,
    })
  );
  authGetData({
    url: `${data.reportType}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              STT: index + 1,
            };
          })
        );
      }
    },
  });
};

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      soLuongTrung: data.SoLuongTrung,
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      TuNgay: moment(data.TuNgay).format("YYYY-MM-DD"),
      DenNgay: moment(data.DenNgay).format("YYYY-MM-DD"),
      TenCuPhap: data.TenCuPhap,
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  console.log("data", data);
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      TuNgay: moment(data.TuNgay).format("YYYY-MM-DD"),
      DenNgay: moment(data.DenNgay).format("YYYY-MM-DD"),
      TenCuPhap: data.TenCuPhap,
    })
  );

  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
    },
  });
};
