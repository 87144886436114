import { Endpoint } from "@utils/endpoint";
import Screen1 from "../common-screen/screen1";
const BKDsCacKHCapNhatThayDoi = () => {
  const columns = [
    {
      title: "STT",
      dataIndex: "key",
      width: "70px",
      align: "center",
    },
    {
      title: "Đơn vị",
      dataIndex: "donVi",
      key: "donVi",
      width: 150,
    },
    {
      title: "Đội",
      dataIndex: "doi",
      key: "doi",
      width: 100,
    },
    {
      title: "Mã sổ GCS",
      dataIndex: "maSoGCS",
      width: 80,
    },
    {
      title: "Kỳ",
      dataIndex: "ky",
      align: "center",
      width: 30,
    },
    {
      title: "Ngày GCS",
      dataIndex: "ngayGCS",
      width: 80,
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      width: 100,
    },
  ];

  const options = [
    {
      value: "0",
      label: "Đã lấy lịch GCS từ CMIS",
    },
    {
      value: "1",
      label: "Đã lấy biểu từ CMIS",
    },
    {
      value: "2",
      label: "Đã phân công",
    },
    {
      value: "3",
      label: "Đang cập nhật MDMS",
    },
    {
      value: "4",
      label: "Kết thúc lấy MDMS, chuyển MTB, HHU",
    },
    {
      value: "5",
      label: "Đã cập nhật đủ MDMS",
    },
    {
      value: "6",
      label: "HHU đã lấy dữ liệu",
    },
    {
      value: "7",
      label: "HHU đã gửi dữ liệu",
    },
    {
      value: "8",
      label: "HHU đã gửi đủ dữ liệu",
    },
    {
      value: "10",
      label: "Chuyển lấy dữ liệu bằng MTB",
    },
    {
      value: "11",
      label: "Đã lấy số về MTB",
    },
    {
      value: "12",
      label: "MTB đã gửi dữ liệu",
    },
    {
      value: "19",
      label: "Hoàn tất đối soát tự động",
    },
    {
      value: "14",
      label: "Hoàn tất đối soát ảnh (Không đạt)",
    },
    {
      value: "9",
      label: "Xuất ghi lại bằng MTB",
    },
    {
      value: "17",
      label: "Xuất ghi lại bằng MDMS",
    },
    {
      value: "15",
      label: "Đang xác nhận các cấp",
    },
    {
      value: "16",
      label: "Hoàn thành xác nhận sổ",
    },
    {
      value: "18",
      label: "Hoàn thành đẩy về CMIS",
    },
  ];

  const endPoint = {
    LIST: Endpoint.LIST_SO_GCS_DV_DOI_QL,
    EXCEL: Endpoint.EXCEL_SO_GCS_DV_DOI_QL,
    PDF: Endpoint.PDF_SO_GCS_DV_DOI_QL,
    ...Endpoint,
  };

  return (
    <Screen1 columns={columns} endPoint={endPoint} isStatusRow={options} />
  );
};

export default BKDsCacKHCapNhatThayDoi;
