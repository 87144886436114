import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APIKyDuyet = {
  DOI_TRUONG_KY_DUYET: `${BASE_API_URL_CAP_DIEN}/ky-duyet/doi-truong-ky-duyet`,
  KIEM_DUYET_KY_DUYET: `${BASE_API_URL_CAP_DIEN}/ky-duyet/kiem-duyet-ky-duyet`,
  PHONG_KINH_DOANH_KY_DUYET: `${BASE_API_URL_CAP_DIEN}/ky-duyet/phong-kinh-doanh-ky-duyet`,
  PHONG_KINH_DOANH_XAC_THUC_CHU_KY: `${BASE_API_URL_CAP_DIEN}/ky-duyet/phong-kinh-doanh-xac-thuc-chu-ky`,
  LANH_DAO_KY_DUYET: `${BASE_API_URL_CAP_DIEN}/ky-duyet/lanh-dao-ky-duyet`,
  LANH_DAO_DUYET_QUYET_TOAN_HSM: `${BASE_API_URL_CAP_DIEN}/ky-duyet/lanh-dao-ky-quyet-toan-hsm`,
  LANH_DAO_DUYET_QUYET_TOAN_USB: `${BASE_API_URL_CAP_DIEN}/ky-duyet/lanh-dao-ky-quyet-toan-usb`,
  DIEU_HANH_KY_SO_DUYET_QUYET_TOAN_USB: `${BASE_API_URL_CAP_DIEN}/ky-duyet/lanh-dao-ky-quyet-toan-usb`,
  LANH_DAO_XAC_THUC_USB: `${BASE_API_URL_CAP_DIEN}/ky-duyet/lanh-dao-xac-thuc-ky-usb`,
  LANH_DAO_TU_CHOI_KY_QUYET_TOAN: `${BASE_API_URL_CAP_DIEN}/ky-duyet/lanh-dao-tu-choi-ky-quyet-toan`,
  GET_MESSAGE_LANH_DAO_KY: `${BASE_API_URL_CAP_DIEN}/ky-duyet/get-message-lanh-dao-ky`,
};
