import { buildQueryString, parseParams } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData} from "@utils/request";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";

export const handleListData = (
  setLoading,
  location,
  setDataDangTH,
  setTotalCount,
  filterConditions,
) => {
  let url = "";
  url = `${Endpoint.AG_LIST_YEU_CAU_DANG_THUC_HIEN}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDangTH(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataDangTH([]);
    },
  });
};

export const submitLamMoi = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.AG_LAM_MOI,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys.toString(),
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
      }
    },
  });
};