import { INDEX_TAB2 } from "@utils/constants";
import { Modal, Table } from "antd";

export default function ModalLog(props) {
  const { keyTab, visible, dataSource, columns, close } = props;
  return (
    <Modal
      title={`Log lỗi import excel ${
        keyTab === INDEX_TAB2 ? "Vật tư có sẵn" : "Vật tư mới"
      }`}
      width={"50%"}
      visible={visible}
      footer={null}
      onCancel={() => close()}
    >
      <Table
        scroll={{ y: 400 }}
        pagination={false}
        dataSource={dataSource}
        columns={columns}
      />
    </Modal>
  );
}
