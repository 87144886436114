import DatePicker from "@components/DatePicker";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import Selection from "@components/Select";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { parseParams } from "@utils/function";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import moment from "moment";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import BCSLTNTheoDonVi from "../bc-sl-tn-loi-theo-don-vi/bieu2";
import BCSLTinNhanTheoNgay from "../bc-sl-tn-theo-ngay/bieu1B";
import BieuChiTiet30Ngay from "../bieu-chi-tiet-30-ngay/bieuchitiet30ngay";
import BieuChiTiet from "../bieu-chi-tiet-theo-don-vi/bieuchitiet";
import BieuTongHop from "../bieu-tong-hop/bieutonghop";
import DSKHChuaGuiTin from "../ds-kh-chua-gui-tn/bieu3";
import { handleDownload, selectBaoCaoSMS } from "../services";
export default function BaoCaoSanLuongTheoDonVi() {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [screen, setScreen] = useState();
  const location = useLocation();
  const [form] = Form.useForm();
  const optionValue = [
    { label: "Biểu 1A: Báo cáo sản lượng tin nhắn theo đơn vị" },
  ];
  const [filterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
    DenNgay: moment().format(FORMAT_ENGLISH),
    ...parseParams(location.search),
  });

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const onFinish = (values) => {
    const dataRequest = {
      ...values,
      reportType: Endpoint.BC_SAN_LUONG_TIN_NHAN_THEO_DON_VI,
    };
    handleDownload(dataRequest, setLoading);
    // handleDownload(values, setLoading);
  };

  const handleChange = useCallback((values) => {
    setScreen(values);
  }, []);

  return (
    <Fragment>
      {screen === Endpoint.BC_SAN_LUONG_TIN_NHAN_THEO_DON_VI ? (
        <BaoCaoSanLuongTheoDonVi
          screen={screen}
          setScreen={setScreen}
          handleChange={handleChange}
        />
      ) : screen === Endpoint.BC_SAN_LUONG_THEO_NGAY ? (
        <BCSLTinNhanTheoNgay
          screen={screen}
          handleChange={handleChange}
          setScreen={setScreen}
        />
      ) : screen === Endpoint.BC_SAN_LUONG_TN_LOI_THEO_DV ? (
        <BCSLTNTheoDonVi screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.DANH_SACH_KH_CHUA_GUI_TIN_NHAN ? (
        <DSKHChuaGuiTin screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.DANH_SACH_KH_DKY_SAI_DV_HOAC_SAI_SDT_CT ? (
        <BieuChiTiet screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.DANH_SACH_KH_DKY_SAI_DV_HOAC_SAI_SDT_TH ? (
        <BieuTongHop screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.DANH_SACH_KH_30_NGAY_CHUA_CAP_NHAT ? (
        <BieuChiTiet30Ngay screen={screen} handleChange={handleChange} />
      ) : (
        <Container>
          <ContentWrapper>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              defaultValue={screen}
              initialValues={{
                reportType: screen,
                TuNgay: form.setFieldsValue(filterConditions.TuNgay)
                  ? form.setFieldsValue(filterConditions.TuNgay)
                  : moment().startOf(MONTH_STRING),
                PhanLoai: "0",
              }}
            >
              <Row gutter={12}>
                <Col span={24}>
                  <Form.Item
                    name="reportType"
                    label="Tên báo cáo"
                    labelAlign="left"
                  >
                    <Select
                      form={form}
                      defaultValue={optionValue}
                      options={selectBaoCaoSMS}
                      onChange={() =>
                        handleChange(form.getFieldValue("reportType"))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="PhanLoai"
                    label="Phân loại"
                    labelAlign="left"
                  >
                    <Select defaultValue="0">
                      <Option value="0">Thông báo Tiền điện lần 1(SMS)</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="MaDonVi" label="Đơn vị" labelAlign="left">
                    <Selection
                      url={Endpoint.GET_TEAM_LIST_CODE}
                      formKey="MaDonVi"
                      form={form}
                      placeholder={"Tất cả"}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item
                    name="TuNgay"
                    label="Ngày bắt đầu"
                    labelAlign="left"
                    labelCol={{ span: 12 }}
                  >
                    <div style={{ marginLeft: "3%" }}>
                      <DatePicker
                        formKey="TuNgay"
                        form={form}
                        allowClear={false}
                        format={FORMAT_ENGLISH}
                        defaultValue={
                          filterConditions.TuNgay
                            ? moment(filterConditions.TuNgay, FORMAT_ENGLISH)
                            : moment()
                        }
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="DenNgay"
                    label="Ngày kết thúc"
                    labelAlign="right"
                    labelCol={{ span: 12 }}
                  >
                    <DatePicker
                      formKey="DenNgay"
                      form={form}
                      allowClear={false}
                      format={FORMAT_ENGLISH}
                      defaultValue={
                        filterConditions.DenNgay
                          ? moment(filterConditions.DenNgay, FORMAT_ENGLISH)
                          : moment()
                      }
                    />
                  </Form.Item>
                </Col>
                <LoadingComponent loading={loading}>
                  <Col span={8}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Xuất Excel
                    </Button>
                  </Col>
                </LoadingComponent>
              </Row>
              <Row justify="end"></Row>
            </FormComponent>
            <Divider className="section-text-line" orientation="left"></Divider>
          </ContentWrapper>
        </Container>
      )}
    </Fragment>
  );
}

const Container = styled.div`
  margin-top: 30px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
