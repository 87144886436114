import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { authPostData } from "@utils/request";

export const handleKySo = (
  setLoading,
  url,
  params,
  setFetchList,
  setSelectedRowKeys,
  handleCloseModalKySo
) => {
  authPostData({
    url,
    setLoading,
    method: METHOD_PUT,
    payload: params,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
        setSelectedRowKeys([]);
        handleCloseModalKySo();
      }
    },
  });
};
