import { APIDuyetDoiTruong } from "@apiUrl/cap-dien/doi-truong/duyet-doi-truong";
import { APIGiaoDichVien } from "@apiUrl/cap-dien/giao-dich-vien/giao-dich-vien";
import { APIDongBoTiepNhanYeuCau } from "@apiUrl/cap-dien/dong-bo-tiep-nhan-yeu-cau/dong-bo";
import { APIPhanCong } from "@apiUrl/cap-dien/phan-cong/phan-cong";
import { APICommon } from "@apiUrl/cap-dien/common";
import { APIKhaoSat } from "@apiUrl/cap-dien/khao-sat";
import { APIKyDuyet } from "@apiUrl/cap-dien/ky-duyet/ky-duyet";
import { APIApGia } from "@apiUrl/cap-dien/ap-gia";
import { APIHieuChinh } from "@apiUrl/cap-dien/tien-ich/hieu-chinh-tien-do";
import { APINghiemThu } from "@apiUrl/cap-dien/nghiem-thu";
import { APIThiCong } from "@apiUrl/cap-dien/thi-cong";
import { APIVatTuCapDien } from "@apiUrl/cap-dien/vat-tu";
import { APIQuyetToan } from "@apiUrl/cap-dien/quyet-toan";
import { APITaiChinhCapDien } from "@apiUrl/cap-dien/tai-chinh";
import { APIHoSo } from "@apiUrl/cap-dien/ho-so";
import { APIBaoCaoCapDien } from "@apiUrl/cap-dien/bao-cao";
import { APILoaiGiayTo } from "@apiUrl/cap-dien/loai-giay-to";
import { APICauHinhHoSo } from "@apiUrl/cap-dien/ho-so-cau-hinh";
import { APIThoiGian } from "@apiUrl/cap-dien/thoi-gian-lam-viec";
import { APITrangChu } from "@apiUrl/cap-dien/trang-chu";
import { APIBanAnToan } from "@apiUrl/cap-dien/ban-an-toan";
import { APITienIch } from "@apiUrl/cap-dien/tien-ich/tai-giay-to";
export const CAPDIEN_Enpoint = {
  ...APITrangChu,
  // hồ sơ

  LIST_HO_SO: APIDuyetDoiTruong.LIST_HO_SO,
  CREATE_HO_SO: APIDuyetDoiTruong.CREATE_HO_SO,
  UPDATE_HO_SO: APIDuyetDoiTruong.UPDATE_HO_SO,
  REMOVE_HO_SO: APIDuyetDoiTruong.REMOVE_HO_SO,
  CANCEL_HO_SO: APIDuyetDoiTruong.CANCEL_HO_SO,
  DETAIL_HO_SO: APIDuyetDoiTruong.DETAIL_HO_SO,
  UPDATE_DETAIL_HO_SO: APIDuyetDoiTruong.UPDATE_DETAIL_HO_SO,
  TRA_LAI_HO_SO: APIDuyetDoiTruong.TRA_LAI_HO_SO,
  RE_NEW_HO_SO: APIDuyetDoiTruong.RE_NEW_HO_SO,
  KHAO_SAT_LAI: APIDuyetDoiTruong.KHAO_SAT_LAI,
  XU_LY_CAP_NHAT_TRO_NGAI: APIDuyetDoiTruong.XU_LY_CAP_NHAT_TRO_NGAI,
  XU_LY_LIST_TRO_NGAI: APIDuyetDoiTruong.XU_LY_LIST_TRO_NGAI,
  XU_LY_CHUYEN_DON_VI: APIDuyetDoiTruong.XU_LY_CHUYEN_DON_VI,
  XU_LY_XAC_NHAN_PHUONG_AN: APIDuyetDoiTruong.XU_LY_XAC_NHAN_PHUONG_AN,
  CHECK_TRA_HO_SO: APIDuyetDoiTruong.CHECK_TRA_HO_SO,
  DATA_THONG_TIN_KH_CMIS: APIDuyetDoiTruong.DATA_THONG_TIN_KH_CMIS,

  //Giao dich vien

  LIST_GIAO_DICH_VIEN: APIGiaoDichVien.LIST_GIAO_DICH_VIEN,
  CREATE_GIAO_DICH_VIEN: APIGiaoDichVien.CREATE_GIAO_DICH_VIEN,
  UPDATE_GIAO_DICH_VIEN: APIGiaoDichVien.UPDATE_GIAO_DICH_VIEN,
  REMOVE_GIAO_DICH_VIEN: APIGiaoDichVien.REMOVE_GIAO_DICH_VIEN,

  //đồng bộ tiếp nhận yêu cầu

  SYNC_TIEP_NHAN_YEU_CAU: APIDongBoTiepNhanYeuCau.SYNC_TIEP_NHAN_YEU_CAU,
  SYNC_TIEP_NHAN_YEU_CAU_THOIGIAN:
    APIDongBoTiepNhanYeuCau.SYNC_TIEP_NHAN_YEU_CAU_THOIGIAN,
  LIST_TIEP_NHAN_YEU_CAU: APIDongBoTiepNhanYeuCau.LIST_TIEP_NHAN_YEU_CAU,

  LIST_DONG_BO_THOI_GIAN: APIDongBoTiepNhanYeuCau.LIST_DONG_BO_THOI_GIAN,

  //phân công

  LIST_PHAN_CONG: APIPhanCong.LIST_PHAN_CONG,
  LIST_NGUOI_KHAO_SAT: APIPhanCong.LIST_NGUOI_KHAO_SAT,
  GET_PHAN_CONG: APIPhanCong.GET_PHAN_CONG,
  UPDATE_PHAN_CONG: APIPhanCong.UPDATE_PHAN_CONG,
  UPDATE_PHAN_CONG_LAI: APIPhanCong.UPDATE_PHAN_CONG_LAI,

  //common list cấp điện

  COMMON_LIST_SO_PHA: APICommon.COMMON_LIST_SO_PHA,
  COMMON_LIST_TINH_TRANG_SU_DUNG: APICommon.COMMON_LIST_TINH_TRANG_SU_DUNG,
  COMMON_HINH_THUC_GHI_CHI_SO: APICommon.COMMON_HINH_THUC_GHI_CHI_SO,
  COMMON_LIST_LOAI_HOP_DONG: APICommon.COMMON_LIST_LOAI_HOP_DONG,
  COMMON_LIST_HINH_THUC_TRA_TIEN: APICommon.COMMON_LIST_HINH_THUC_TRA_TIEN,
  COMMON_LIST_TINH_TRANG_THU: APICommon.COMMON_LIST_TINH_TRANG_THU,
  COMMON_LIST_GIOI_TINH: APICommon.COMMON_LIST_GIOI_TINH,
  COMMON_LIST_DON_VI_TINH: APICommon.COMMON_LIST_DON_VI_TINH,
  COMMON_LIST_HINH_THUC_LAP_DAT: APICommon.COMMON_LIST_HINH_THUC_LAP_DAT,
  COMMON_LIST_CHUNG_LOAI_VAT_TU: APICommon.COMMON_LIST_CHUNG_LOAI_VAT_TU,
  COMMON_LIST_LOAI_DU_TOAN: APICommon.COMMON_LIST_LOAI_DU_TOAN,
  COMMON_LIST_LOAI_CAP: APICommon.COMMON_LIST_LOAI_CAP,
  COMMON_LIST_CONG_TAC_VAT_TU: APICommon.COMMON_LIST_CONG_TAC_VAT_TU,
  COMMON_LIST_MUC_DICH: APICommon.COMMON_LIST_MUC_DICH,
  COMMON_LIST_TRANG_THAI: APICommon.COMMON_LIST_TRANG_THAI,
  COMMON_LIST_LOAI_YEU_CAU: APICommon.COMMON_LIST_LOAI_YEU_CAU,
  COMMON_LIST_LANH_DAO: APICommon.COMMON_LIST_LANH_DAO,
  COMMON_LIST_NGAN_HANG: APICommon.COMMON_LIST_NGAN_HANG,
  COMMON_LIST_OS: APICommon.COMMON_LIST_OS,
  COMMON_LIST_TINH_TRANG_YEU_CAU: APICommon.COMMON_LIST_TINH_TRANG_YEU_CAU,
  //khảo sát

  KS_GET_THONG_TIN_CO_BAN: APIKhaoSat.KS_GET_THONG_TIN_CO_BAN,
  KS_SAVE_THONG_TIN_CO_BAN: APIKhaoSat.KS_SAVE_THONG_TIN_CO_BAN,
  KS_DETAIL_CONG_SUAT: APIKhaoSat.KS_DETAIL_CONG_SUAT,
  KS_LIST_TY_LE_GIA_BAN_DIEN: APIKhaoSat.KS_LIST_TY_LE_GIA_BAN_DIEN,
  KS_GET_THIET_BI_DIEN: APIKhaoSat.KS_GET_THIET_BI_DIEN,
  KS_CREATE_THIET_BI_DIEN: APIKhaoSat.KS_CREATE_THIET_BI_DIEN,
  KS_UPDATE_THIET_BI_DIEN: APIKhaoSat.KS_UPDATE_THIET_BI_DIEN,
  KS_REMOVE_THIET_BI_DIEN: APIKhaoSat.KS_REMOVE_THIET_BI_DIEN,
  KS_GET_TY_LE_GIA_BAN_DIEN: APIKhaoSat.KS_GET_TY_LE_GIA_BAN_DIEN,
  KS_CREATE_TY_LE_GIA_BAN_DIEN: APIKhaoSat.KS_CREATE_TY_LE_GIA_BAN_DIEN,
  KS_UPDATE_TY_LE_GIA_BAN_DIEN: APIKhaoSat.KS_UPDATE_TY_LE_GIA_BAN_DIEN,
  KS_REMOVE_TY_LE_GIA_BAN_DIEN: APIKhaoSat.KS_REMOVE_TY_LE_GIA_BAN_DIEN,
  KS_GET_PHUONG_AN_CAP_DIEN: APIKhaoSat.KS_GET_PHUONG_AN_CAP_DIEN,
  KS_SAVE_PHUONG_AN_CAP_DIEN: APIKhaoSat.KS_SAVE_PHUONG_AN_CAP_DIEN,
  UPLOAD_ANH_HIEN_TRUONG: APIKhaoSat.UPLOAD_ANH_HIEN_TRUONG,
  LIST_ANH_HIEN_TRUONG: APIKhaoSat.LIST_ANH_HIEN_TRUONG,
  REMOVE_ANH_HIEN_TRUONG: APIKhaoSat.REMOVE_ANH_HIEN_TRUONG,
  SYNC_ANH_HIEN_TRUONG: APIKhaoSat.SYNC_ANH_HIEN_TRUONG,
  KS_LIST_VAT_TU_CONG_TY: APIKhaoSat.KS_LIST_VAT_TU_CONG_TY,
  KS_GET_VAT_TU_CONG_TY: APIKhaoSat.KS_GET_VAT_TU_CONG_TY,
  KS_DELETE_VAT_TU_CONG_TY: APIKhaoSat.KS_DELETE_VAT_TU_CONG_TY,
  KS_UPDATE_VAT_TU_CONG_TY: APIKhaoSat.KS_UPDATE_VAT_TU_CONG_TY,
  KS_CREATE_VAT_TU_CONG_TY: APIKhaoSat.KS_CREATE_VAT_TU_CONG_TY,
  KS_LIST_VAT_TU_CONG_TY_THU_HOI: APIKhaoSat.KS_LIST_VAT_TU_CONG_TY_THU_HOI,
  KS_GET_VAT_TU_CONG_TY_THU_HOI: APIKhaoSat.KS_GET_VAT_TU_CONG_TY_THU_HOI,
  KS_DELETE_VAT_TU_CONG_TY_THU_HOI: APIKhaoSat.KS_DELETE_VAT_TU_CONG_TY_THU_HOI,
  KS_UPDATE_VAT_TU_CONG_TY_THU_HOI: APIKhaoSat.KS_UPDATE_VAT_TU_CONG_TY_THU_HOI,
  KS_CREATE_VAT_TU_CONG_TY_THU_HOI: APIKhaoSat.KS_CREATE_VAT_TU_CONG_TY_THU_HOI,
  CHECK_SHOW_IMAGE: APIKhaoSat.CHECK_SHOW_IMAGE,

  KS_GET_THONG_TIN_HO_SO: APIKhaoSat.KS_GET_THONG_TIN_HO_SO,
  KS_SAVE_THONG_TIN_HO_SO: APIKhaoSat.KS_SAVE_THONG_TIN_HO_SO,

  KS_LIST_VAT_TU_KHACH_HANG: APIKhaoSat.KS_LIST_VAT_TU_KHACH_HANG,

  KS_CREATE_VAT_TU_KHACH_HANG_DU_TOAN_CO_SAN:
    APIKhaoSat.KS_CREATE_VAT_TU_KHACH_HANG_DU_TOAN_CO_SAN,
  KS_UPDATE_VAT_TU_KHACH_HANG_DU_TOAN_CO_SAN:
    APIKhaoSat.KS_UPDATE_VAT_TU_KHACH_HANG_DU_TOAN_CO_SAN,
  KS_DELETE_VAT_TU_KHACH_HANG_DU_TOAN_CO_SAN:
    APIKhaoSat.KS_DELETE_VAT_TU_KHACH_HANG_DU_TOAN_CO_SAN,
  KS_GET_VAT_TU_KHACH_HANG_DU_TOAN_CO_SAN:
    APIKhaoSat.KS_GET_VAT_TU_KHACH_HANG_DU_TOAN_CO_SAN,

  KS_CREATE_VAT_TU_KHACH_HANG_DU_TOAN_MUA_NGOAI:
    APIKhaoSat.KS_CREATE_VAT_TU_KHACH_HANG_DU_TOAN_MUA_NGOAI,
  KS_UPDATE_VAT_TU_KHACH_HANG_DU_TOAN_MUA_NGOAI:
    APIKhaoSat.KS_UPDATE_VAT_TU_KHACH_HANG_DU_TOAN_MUA_NGOAI,
  KS_DELETE_VAT_TU_KHACH_HANG_DU_TOAN_MUA_NGOAI:
    APIKhaoSat.KS_DELETE_VAT_TU_KHACH_HANG_DU_TOAN_MUA_NGOAI,
  KS_GET_VAT_TU_KHACH_HANG_DU_TOAN_MUA_NGOAI:
    APIKhaoSat.KS_GET_VAT_TU_KHACH_HANG_DU_TOAN_MUA_NGOAI,

  KS_CREATE_VAT_TU_KHACH_HANG_KHAI_GIA_CO_SAN:
    APIKhaoSat.KS_CREATE_VAT_TU_KHACH_HANG_KHAI_GIA_CO_SAN,
  KS_UPDATE_VAT_TU_KHACH_HANG_KHAI_GIA_CO_SAN:
    APIKhaoSat.KS_UPDATE_VAT_TU_KHACH_HANG_KHAI_GIA_CO_SAN,
  KS_DELETE_VAT_TU_KHACH_HANG_KHAI_GIA_CO_SAN:
    APIKhaoSat.KS_DELETE_VAT_TU_KHACH_HANG_KHAI_GIA_CO_SAN,
  KS_GET_VAT_TU_KHACH_HANG_KHAI_GIA_CO_SAN:
    APIKhaoSat.KS_GET_VAT_TU_KHACH_HANG_KHAI_GIA_CO_SAN,

  KS_CREATE_VAT_TU_KHACH_HANG_KHAI_GIA_MUA_NGOAI:
    APIKhaoSat.KS_CREATE_VAT_TU_KHACH_HANG_KHAI_GIA_MUA_NGOAI,
  KS_UPDATE_VAT_TU_KHACH_HANG_KHAI_GIA_MUA_NGOAI:
    APIKhaoSat.KS_UPDATE_VAT_TU_KHACH_HANG_KHAI_GIA_MUA_NGOAI,
  KS_DELETE_VAT_TU_KHACH_HANG_KHAI_GIA_MUA_NGOAI:
    APIKhaoSat.KS_DELETE_VAT_TU_KHACH_HANG_KHAI_GIA_MUA_NGOAI,
  KS_GET_VAT_TU_KHACH_HANG_KHAI_GIA_MUA_NGOAI:
    APIKhaoSat.KS_GET_VAT_TU_KHACH_HANG_KHAI_GIA_MUA_NGOAI,

  KS_GET_CHI_PHI_HINH_THUC_LAP_DAT: APIKhaoSat.KS_GET_CHI_PHI_HINH_THUC_LAP_DAT,
  KS_GET_CHI_PHI_LOAI_DU_TOAN: APIKhaoSat.KS_GET_CHI_PHI_LOAI_DU_TOAN,
  KS_IN_BIEN_BAN_KHAI_GIA_PDF: APIKhaoSat.KS_IN_BIEN_BAN_KHAI_GIA_PDF,
  KS_IN_BIEN_BAN_KHAI_GIA_EXCEL: APIKhaoSat.KS_IN_BIEN_BAN_KHAI_GIA_EXCEL,
  GET_NGAY_HEN_TIEP_THEO: APIKhaoSat.GET_NGAY_HEN_TIEP_THEO,
  KS_LIST_DAY_DAN_DIEN: APIKhaoSat.KS_LIST_DAY_DAN_DIEN,
  KS_TONG_CONG_SUAT: APIKhaoSat.KS_TONG_CONG_SUAT,
  KS_EXPORT_EXCEL_BIEU_DO_PT: APIKhaoSat.KS_EXPORT_EXCEL_BIEU_DO_PT,

  //ký duyệt

  DOI_TRUONG_KY_DUYET: APIKyDuyet.DOI_TRUONG_KY_DUYET,
  KIEM_DUYET_KY_DUYET: APIKyDuyet.KIEM_DUYET_KY_DUYET,
  PHONG_KINH_DOANH_KY_DUYET: APIKyDuyet.PHONG_KINH_DOANH_KY_DUYET,
  PHONG_KINH_DOANH_XAC_THUC_CHU_KY: APIKyDuyet.PHONG_KINH_DOANH_XAC_THUC_CHU_KY,
  LANH_DAO_KY_DUYET: APIKyDuyet.LANH_DAO_KY_DUYET,
  LANH_DAO_DUYET_QUYET_TOAN_HSM: APIKyDuyet.LANH_DAO_DUYET_QUYET_TOAN_HSM,
  LANH_DAO_DUYET_QUYET_TOAN_USB: APIKyDuyet.LANH_DAO_DUYET_QUYET_TOAN_USB,
  DIEU_HANH_KY_SO_DUYET_QUYET_TOAN_USB:
    APIKyDuyet.DIEU_HANH_KY_SO_DUYET_QUYET_TOAN_USB,
  LANH_DAO_TU_CHOI_KY_QUYET_TOAN: APIKyDuyet.LANH_DAO_TU_CHOI_KY_QUYET_TOAN,
  GET_MESSAGE_LANH_DAO_KY: APIKyDuyet.GET_MESSAGE_LANH_DAO_KY,
  LANH_DAO_XAC_THUC_USB: APIKyDuyet.LANH_DAO_XAC_THUC_USB,

  //áp giá

  XAC_NHAN_CHUYEN_AP_GIA: APIApGia.XAC_NHAN_CHUYEN_AP_GIA,
  AP_GIA_HO_SO: APIApGia.AP_GIA_HO_SO,
  AP_GIA_TI_LE_BAN_DIEN: APIApGia.AP_GIA_TI_LE_BAN_DIEN,
  GET_HO_SO_AP_GIA: APIApGia.GET_HO_SO_AP_GIA,
  IN_BIEN_BAN_AP_GIA: APIApGia.IN_BIEN_BAN_AP_GIA,
  LIST_TI_LE_GIA_BAN_DIEN_AP_GIA: APIApGia.LIST_TI_LE_GIA_BAN_DIEN_AP_GIA,
  TRA_LAI_AP_GIA_HO_SO: APIApGia.TRA_LAI_AP_GIA_HO_SO,

  //Tien ich
  UPDATE_SO_NGAY_XU_LY: APIHieuChinh.UPDATE_SO_NGAY_XU_LY,
  XUAT_EXCEL_TRACUU_HOSO: APIHieuChinh.XUAT_EXCEL_TRACUU_HOSO,
  DONG_BO_ANH_CMIS_TIEN_ICH: APITienIch.DONG_BO_ANH_CMIS_TIEN_ICH,

  //NGHIỆM THU
  LIST_NHAN_VIEN_NGHIEM_THU: APINghiemThu.LIST_NHAN_VIEN_NGHIEM_THU,
  LIST_NHAN_VIEN_TREO_THAO_NGHIEM_THU:
    APINghiemThu.LIST_NHAN_VIEN_TREO_THAO_NGHIEM_THU,
  LIST_NHAN_VIEN_NIEM_PHONG_NGHIEM_THU:
    APINghiemThu.LIST_NHAN_VIEN_NIEM_PHONG_NGHIEM_THU,
  XAC_NHAN_NGHIEM_THU: APINghiemThu.XAC_NHAN_NGHIEM_THU,
  NHAN_VIEN_TREO_THAO_VA_NIEM_PHONG:
    APINghiemThu.NHAN_VIEN_TREO_THAO_VA_NIEM_PHONG,
  XAC_NHAN_NGHIEM_THU_MOI: APINghiemThu.XAC_NHAN_NGHIEM_THU_MOI,

  // Quyết toán

  GET_LAP_BANG_QUYET_TOAN_KH: APIQuyetToan.GET_LAP_BANG_QUYET_TOAN_KH,
  UPDATE_VAT_TU_QUYET_TOAN_KH: APIQuyetToan.UPDATE_VAT_TU_QUYET_TOAN_KH,
  HOAN_THANH_QUYET_TOAN_KH: APIQuyetToan.HOAN_THANH_QUYET_TOAN_KH,
  XAC_NHAN_QUYET_TOAN: APIQuyetToan.XAC_NHAN_QUYET_TOAN,
  TRA_LAI_QUYET_TOAN: APIQuyetToan.TRA_LAI_QUYET_TOAN,
  TU_CHOI_QUYET_TOAN: APIQuyetToan.TU_CHOI_QUYET_TOAN,
  GET_LAP_BANG_QUYET_TOAN_CONGTY: APIQuyetToan.GET_LAP_BANG_QUYET_TOAN_CONGTY,
  HOAN_THANH_QUYET_TOAN_CONGTY: APIQuyetToan.HOAN_THANH_QUYET_TOAN_CONGTY,
  UPDATE_VAT_TU_QUYET_TOAN_CONGTY: APIQuyetToan.UPDATE_VAT_TU_QUYET_TOAN_CONGTY,
  IN_BIEN_BAN_CONG_TY: APIQuyetToan.IN_BIEN_BAN_CONG_TY,
  IN_BIEN_BAN_QUYET_TOAN_KH: APIQuyetToan.IN_BIEN_BAN_QUYET_TOAN_KH,
  IN_BIEN_BAN_CONG_TY_EXCEL: APIQuyetToan.IN_BIEN_BAN_CONG_TY_EXCEL,
  IN_BIEN_BAN_QUYET_TOAN_KH_EXCEL: APIQuyetToan.IN_BIEN_BAN_QUYET_TOAN_KH_EXCEL,

  //thi công- cấp điện
  KHOI_PHUC_THI_CONG: APIThiCong.KHOI_PHUC_THI_CONG,
  XAC_NHAN_THI_CONG: APIThiCong.XAC_NHAN_THI_CONG,
  GET_THONG_TIN_VAT_TU: APIThiCong.GET_THONG_TIN_VAT_TU,
  UPDATE_THONG_TIN_VAT_TU_THI_CONG: APIThiCong.UPDATE_THONG_TIN_VAT_TU_THI_CONG,
  GET_THONG_TIN_CONG_TO: APIThiCong.GET_THONG_TIN_CONG_TO,
  UPDATE_THONG_TIN_CONG_TO: APIThiCong.UPDATE_THONG_TIN_CONG_TO,
  CHECK_GHI_NHAN_THI_CONG: APIThiCong.CHECK_GHI_NHAN_THI_CONG,
  CHECK_XAC_NHAN_THI_CONG: APIThiCong.CHECK_XAC_NHAN_THI_CONG,
  GET_CONG_TO_CMIS: APIThiCong.GET_CONG_TO_CMIS,
  GET_DETAIL_CONG_TO_CMIS: APIThiCong.GET_DETAIL_CONG_TO_CMIS,
  GET_BIEN_BAN_LAP_DAT: APIThiCong.GET_BIEN_BAN_LAP_DAT,
  CREATE_OR_UPDATE_BIEN_BAN_LAP_DAT:
    APIThiCong.CREATE_OR_UPDATE_BIEN_BAN_LAP_DAT,
  GET_LINK_FILE_BBLD: APIThiCong.GET_LINK_FILE_BBLD,
  GET_FILE_FILE_BBLD: APIThiCong.GET_FILE_FILE_BBLD,
  GET_CT_THAO_CMIS: APIThiCong.GET_CT_THAO_CMIS,
  LIST_THIET_BI_CMIS: APIThiCong.LIST_THIET_BI_CMIS,
  GET_LAP_DAT_BIEN_BAN_DUONG_DAY: APIThiCong.GET_LAP_DAT_BIEN_BAN_DUONG_DAY,
  UPDATE_SYNC_DONG_BO_CAP_NHAT_BIEN_BAN_LAP_DAT:
    APIThiCong.UPDATE_SYNC_DONG_BO_CAP_NHAT_BIEN_BAN_LAP_DAT,
  XAC_NHAN_CHUYEN_THI_CONG: APIThiCong.XAC_NHAN_CHUYEN_THI_CONG,
  THI_CONG_TRA_LAI_HO_SO: APIThiCong.THI_CONG_TRA_LAI_HO_SO,

  //ban an toàn - xác nhận an toàn
  XAC_NHAN_AN_TOAN_CAP_DIEN: APIBanAnToan.XAC_NHAN_AN_TOAN_CAP_DIEN,
  GET_PHIEU_LENH_CONG_TAC_CAP_DIEN:
    APIBanAnToan.GET_PHIEU_LENH_CONG_TAC_CAP_DIEN,

  TRA_LAI_THI_CONG: APIBanAnToan.TRA_HS_CHO_THI_CONG,

  // vật tư - cấp điện
  XAC_NHAN_XUAT_VAT_TU: APIVatTuCapDien.XAC_NHAN_XUAT_VAT_TU,
  XUAT_EXCEL_VAT_TU: APIVatTuCapDien.XUAT_EXCEL_VAT_TU,

  //TÀI CHÍNH - CẤP ĐIỆN
  XAC_NHAN_TAI_CHINH: APITaiChinhCapDien.XAC_NHAN_TAI_CHINH,
  GET_TONG_CHI_PHI: APITaiChinhCapDien.GET_TONG_CHI_PHI,
  XAC_NHAN_THU_TIEN: APITaiChinhCapDien.XAC_NHAN_THU_TIEN,
  XAC_NHAN_THU_TIEN_TU_NVKS: APITaiChinhCapDien.XAC_NHAN_THU_TIEN_TU_NVKS,
  DONG_BO_THU_TIEN: APITaiChinhCapDien.DONG_BO_THU_TIEN,
  KHOI_PHUC_TAI_CHINH_CAP_DIEN: APITaiChinhCapDien.KHOI_PHUC_TAI_CHINH_CAP_DIEN,

  //HỒ SƠ
  GET_LIST_IN_BIEN_BAN_CMIS: APIHoSo.GET_LIST_IN_BIEN_BAN_CMIS,
  SEND_EMAIL_HO_SO_FLOW: APIHoSo.SEND_EMAIL_HO_SO_FLOW,
  GET_LIST_HO_SO_FLOW: APIHoSo.GET_LIST_HO_SO_FLOW,
  GET_FILE_TAI_LIEU_HO_SO: APIHoSo.GET_FILE_TAI_LIEU_HO_SO,
  GET_ANH_HIEN_TRUONG_HO_SO: APIHoSo.GET_ANH_HIEN_TRUONG_HS,

  //CẤU HÌNH HỒ SƠ
  LIST_CAU_HINH_HO_SO: APICauHinhHoSo.LIST_CAU_HINH_HO_SO,
  CREATE_CAU_HINH_HO_SO: APICauHinhHoSo.CREATE_CAU_HINH_HO_SO,
  UPDATE_CAU_HINH_HO_SO: APICauHinhHoSo.UPDATE_CAU_HINH_HO_SO,
  REMOVE_CAU_HINH_HO_SO: APICauHinhHoSo.REMOVE_CAU_HINH_HO_SO,
  LIST_THOI_GIAN_HIEN_LUC_CAU_HINH_HO_SO:
    APICauHinhHoSo.LIST_THOI_GIAN_HIEN_LUC_CAU_HINH_HO_SO,
  REMOVE_THOI_GIAN_HIEN_LUC_CAU_HINH_HO_SO:
    APICauHinhHoSo.REMOVE_THOI_GIAN_HIEN_LUC_CAU_HINH_HO_SO,

  //THỜI GIAN LÀM VIỆC
  LIST_THOI_GIAN_LAM_VIEC: APIThoiGian.LIST_THOI_GIAN_LAM_VIEC,
  CREATE_THOI_GIAN_LAM_VIEC: APIThoiGian.CREATE_THOI_GIAN_LAM_VIEC,
  UPDATE_THOI_GIAN_LAM_VIEC: APIThoiGian.UPDATE_THOI_GIAN_LAM_VIEC,
  REMOVE_THOI_GIAN_LAM_VIEC: APIThoiGian.REMOVE_THOI_GIAN_LAM_VIEC,

  //BÁO CÁO SỐ PHA
  GET_LIST_BAO_CAO_SO_PHA: APIBaoCaoCapDien.GET_LIST_BAO_CAO_SO_PHA,
  EXPORT_EXCEL_BAO_CAO_SO_PHA: APIBaoCaoCapDien.EXPORT_EXCEL_BAO_CAO_SO_PHA,
  HUY_CHOT_BAO_CAO: APIBaoCaoCapDien.HUY_CHOT_BAO_CAO,
  CHOT_BAO_CAO: APIBaoCaoCapDien.CHOT_BAO_CAO,

  //báo cáo tạo mới kế hoạch tiếp nhận
  GET_LIST_BAO_CAO_TAO_MOI_KE_HOACH:
    APIBaoCaoCapDien.GET_LIST_BAO_CAO_TAO_MOI_KE_HOACH,
  EXPORT_EXCEL_TAO_MOI_KE_HOACH: APIBaoCaoCapDien.EXPORT_EXCEL_TAO_MOI_KE_HOACH,
  CREATE_KE_HOACH_TIEP_NHAN: APIBaoCaoCapDien.CREATE_KE_HOACH_TIEP_NHAN,
  UPDATE_KE_HOACH_TIEP_NHAN: APIBaoCaoCapDien.CREATE_KE_HOACH_TIEP_NHAN,

  //báo cáo tổng hợp
  GET_LIST_BAO_CAO_TONG_HOP: APIBaoCaoCapDien.GET_LIST_BAO_CAO_TONG_HOP,
  CHOT_BAO_CAO_TONG_HOP: APIBaoCaoCapDien.CHOT_BAO_CAO_TONG_HOP,
  HUY_CHOT_BAO_CAO_TONG_HOP: APIBaoCaoCapDien.HUY_CHOT_BAO_CAO_TONG_HOP,
  GET_NEW_DATE_SO_LIEU_BAO_CAO_TONG_HOP:
    APIBaoCaoCapDien.GET_NEW_DATE_SO_LIEU_BAO_CAO_TONG_HOP,
  TONG_HOP_SO_LIEU_BAO_CAO_TONG_HOP:
    APIBaoCaoCapDien.TONG_HOP_SO_LIEU_BAO_CAO_TONG_HOP,
  XUAT_EXCEL_BAO_CAO_TONG_HOP: APIBaoCaoCapDien.XUAT_EXCEL_BAO_CAO_TONG_HOP,
  XUAT_EXCEL_BAO_CAO_TONG_HOP_CHI_TIET_CMIS:
    APIBaoCaoCapDien.XUAT_EXCEL_BAO_CAO_TONG_HOP_CHI_TIET_CMIS,

  //báo cáo chi tiết
  GET_LIST_BAO_CAO_CHI_TIET: APIBaoCaoCapDien.GET_LIST_BAO_CAO_CHI_TIET,
  GET_LIST_COLUMNS_BAO_CAO_CHI_TIET:
    APIBaoCaoCapDien.GET_LIST_COLUMNS_BAO_CAO_CHI_TIET,
  UPDATE_LIST_COLUMNS_BAO_CAO_CHI_TIET:
    APIBaoCaoCapDien.UPDATE_LIST_COLUMNS_BAO_CAO_CHI_TIET,
  EXPORT_EXCEL_BAO_CAO_CHI_TIET: APIBaoCaoCapDien.EXPORT_EXCEL_BAO_CAO_CHI_TIET,
  GET_LIST_LOAI_HO_SO_YEU_CAU_BAO_CAO:
    APIBaoCaoCapDien.GET_LIST_LOAI_HO_SO_YEU_CAU_BAO_CAO,

  //báo cáo 89-160
  CHOT_BAO_CAO_89_160: APIBaoCaoCapDien.CHOT_BAO_CAO_89_160,
  HUY_CHOT_BAO_CAO_89_160: APIBaoCaoCapDien.HUY_CHOT_BAO_CAO_89_160,
  GET_LIST_BAO_CAO_89_160: APIBaoCaoCapDien.GET_LIST_BAO_CAO_89_160,
  GET_LIST_CHI_PHI_CAP_NGAM: APIBaoCaoCapDien.GET_LIST_CHI_PHI_CAP_NGAM,
  GET_LIST_CHI_PHI_CAP_DDK: APIBaoCaoCapDien.GET_LIST_CHI_PHI_CAP_DDK,
  GET_LIST_CHI_PHI_CAP_KHAC: APIBaoCaoCapDien.GET_LIST_CHI_PHI_CAP_KHAC,
  CREATE_CHI_PHI_CAP_NGAM: APIBaoCaoCapDien.CREATE_CHI_PHI_CAP_NGAM,
  UPDATE_CHI_PHI_CAP_NGAM: APIBaoCaoCapDien.UPDATE_CHI_PHI_CAP_NGAM,
  REMOVE_CHI_PHI_CAP_NGAM: APIBaoCaoCapDien.REMOVE_CHI_PHI_CAP_NGAM,
  CREATE_CHI_PHI_CAP_DDK: APIBaoCaoCapDien.CREATE_CHI_PHI_CAP_DDK,
  UPDATE_CHI_PHI_CAP_DDK: APIBaoCaoCapDien.UPDATE_CHI_PHI_CAP_DDK,
  REMOVE_CHI_PHI_CAP_DDK: APIBaoCaoCapDien.REMOVE_CHI_PHI_CAP_DDK,
  CREATE_CHI_PHI_CAP_KHAC: APIBaoCaoCapDien.CREATE_CHI_PHI_CAP_KHAC,
  UPDATE_CHI_PHI_CAP_KHAC: APIBaoCaoCapDien.UPDATE_CHI_PHI_CAP_KHAC,
  REMOVE_CHI_PHI_CAP_KHAC: APIBaoCaoCapDien.REMOVE_CHI_PHI_CAP_KHAC,
  EXPORT_EXCEL_BAO_CAO_CHI_TIET_DIEN_HA_AP:
    APIBaoCaoCapDien.EXPORT_EXCEL_BAO_CAO_CHI_TIET_DIEN_HA_AP,
  EXPORT_EXCEL_BAO_CAO_TRAM_DIEN_HA_AP:
    APIBaoCaoCapDien.EXPORT_EXCEL_BAO_CAO_TRAM_DIEN_HA_AP,
  EXPORT_EXCEL_BAO_CAO_CHI_PHI_CAP_MOI_DIEN_HA_AP:
    APIBaoCaoCapDien.EXPORT_EXCEL_BAO_CAO_CHI_PHI_CAP_MOI_DIEN_HA_AP,
  EXPORT_EXCEL_BAO_CAO_CHI_PHI_NANG_CAP_DIEN_HA_AP:
    APIBaoCaoCapDien.EXPORT_EXCEL_BAO_CAO_CHI_PHI_NANG_CAP_DIEN_HA_AP,
  EXPORT_EXCEL_BAO_CAO_CHI_PHI_CHUYEN_DUNG_DIEN_HA_AP:
    APIBaoCaoCapDien.EXPORT_EXCEL_BAO_CAO_CHI_PHI_CHUYEN_DUNG_DIEN_HA_AP,
  GET_THONG_TIN_CHUNG_BAO_CAO_89_160:
    APIBaoCaoCapDien.GET_THONG_TIN_CHUNG_BAO_CAO_89_160,
  UPDATE_THONG_TIN_CHUNG_BAO_CAO_89_160:
    APIBaoCaoCapDien.UPDATE_THONG_TIN_CHUNG_BAO_CAO_89_160,

  //tổng hợp theo hình thức lắp đặt
  GET_LIST_BAO_CAO_TONG_HOP_HTLD:
    APIBaoCaoCapDien.GET_LIST_BAO_CAO_TONG_HOP_HTLD,
  EXPORT_EXCEL_BAO_CAO_TONG_HOP_HTLD:
    APIBaoCaoCapDien.EXPORT_EXCEL_BAO_CAO_TONG_HOP_HTLD,

  //log thao tác
  GET_LIST_LOG_THAO_TAC: APIBaoCaoCapDien.GET_LIST_LOG_THAO_TAC,
  EXPORT_EXCEL_LOG_THAO_TAC: APIBaoCaoCapDien.EXPORT_EXCEL_LOG_THAO_TAC,

  //số lượng vật tư
  GET_LIST_SO_LUONG_VAT_TU: APIBaoCaoCapDien.GET_LIST_SO_LUONG_VAT_TU,
  EXPORT_EXCEL_SO_LUONG_VAT_TU: APIBaoCaoCapDien.EXPORT_EXCEL_SO_LUONG_VAT_TU,
  LIST_DOI_SO_LUONG_VAT_TU: APIBaoCaoCapDien.LIST_DOI_SO_LUONG_VAT_TU,

  //loại giấy tờ
  LIST_LOAI_GIAY_TO: APILoaiGiayTo.LIST_LOAI_GIAY_TO,
  CREATE_LOAI_GIAY_TO: APILoaiGiayTo.CREATE_LOAI_GIAY_TO,
  UPDATE_LOAI_GIAY_TO: APILoaiGiayTo.UPDATE_LOAI_GIAY_TO,
  REMOVE_LOAI_GIAY_TO: APILoaiGiayTo.REMOVE_LOAI_GIAY_TO,

  //chậm tiến độ:
  GET_LISt_CHAM_TIEN_DO: APIBaoCaoCapDien.GET_LISt_CHAM_TIEN_DO,
  EXPORT_EXCEL_CHAM_TIEN_DO: APIBaoCaoCapDien.EXPORT_EXCEL_CHAM_TIEN_DO,
  GET_LIST_COLUMNS_BAO_CAO_CHAM_TIEN_DO:
    APIBaoCaoCapDien.GET_LIST_COLUMNS_BAO_CAO_CHAM_TIEN_DO,
  UPDATE_LIST_COLUMNS_BAO_CAO_CHAM_TIEN_DO:
    APIBaoCaoCapDien.UPDATE_LIST_COLUMNS_BAO_CAO_CHAM_TIEN_DO,
  LIST_NGAY_CHAM_TIEN_DO: APICommon.LIST_NGAY_CHAM_TIEN_DO,

  //Tong hop Chi tiết chi phí quyết toán
  GET_LIST_BAO_CAO_TONG_HOP_CHI_PHI_QUYET_TOAN:
    APIBaoCaoCapDien.GET_LIST_BAO_CAO_TONG_HOP_CHI_PHI_QUYET_TOAN,
  EXPORT_EXCEL_BAO_CAO_TONG_HOP_CHI_PHI_QUYET_TOAN:
    APIBaoCaoCapDien.EXPORT_EXCEL_BAO_CAO_TONG_HOP_CHI_PHI_QUYET_TOAN,

  //chi phí quyết toán
  GET_LIST_BAO_CAO_CHI_PHI_QUYET_TOAN:
    APIBaoCaoCapDien.GET_LIST_BAO_CAO_CHI_PHI_QUYET_TOAN,
  EXPORT_EXCEL_BAO_CAO_CHI_PHI_QUYET_TOAN:
    APIBaoCaoCapDien.EXPORT_EXCEL_BAO_CAO_CHI_PHI_QUYET_TOAN,
  GET_LIST_COLUMNS_BAO_CAO_CHI_PHI_QUYET_TOAN:
    APIBaoCaoCapDien.GET_LIST_COLUMNS_BAO_CAO_CHI_PHI_QUYET_TOAN,
  UPDATE_LIST_COLUMNS_BAO_CAO_CHI_PHI_QUYET_TOAN:
    APIBaoCaoCapDien.UPDATE_LIST_COLUMNS_BAO_CAO_CHI_PHI_QUYET_TOAN,

  //Báo cáo tổng hợp thông tin khách hàng
  GET_LIST_BAO_CAO_TONG_HOP_THONG_TIN_KH:
    APIBaoCaoCapDien.GET_LIST_BAO_CAO_TONG_HOP_THONG_TIN_KH,
  EXPORT_EXCEL_BAO_CAO_TONG_HOP_THONG_TIN_KH:
    APIBaoCaoCapDien.EXPORT_EXCEL_BAO_CAO_TONG_HOP_THONG_TIN_KH,

  //Báo cáo danh sách khách hàng
  GET_LIST_BAO_CAO_DANH_SACH_KH: APIBaoCaoCapDien.GET_LIST_BAO_CAO_DANH_SACH_KH,
  EXPORT_EXCEL_BAO_CAO_DANH_SACH_KH:
    APIBaoCaoCapDien.EXPORT_EXCEL_BAO_CAO_DANH_SACH_KH,
  EXPORT_EXCEL_BAO_CAO_DANH_SACH_KH_THEO_LO:
    APIBaoCaoCapDien.EXPORT_EXCEL_BAO_CAO_DANH_SACH_KH_THEO_LO,
  //Báo cáo tình hình phát triển theo lô
  GET_LIST_BAO_CAO_TINH_HINH_PHAT_TRIEN:
    APIBaoCaoCapDien.GET_LIST_BAO_CAO_TINH_HINH_PHAT_TRIEN,
  EXPORT_EXCEL_BAO_CAO_TINH_HINH_PHAT_TRIEN:
    APIBaoCaoCapDien.EXPORT_EXCEL_BAO_CAO_TINH_HINH_PHAT_TRIEN,
  EXPORT_EXCEL_BAO_CAO_TINH_HINH_PHAT_TRIEN_THEO_LO:
    APIBaoCaoCapDien.EXPORT_EXCEL_BAO_CAO_TINH_HINH_PHAT_TRIEN_THEO_LO,

  //Chi tiết theo hình thức lắp đặt
  GET_LIST_CHI_TIET_THEO_HTLD: APIBaoCaoCapDien.GET_LIST_CHI_TIET_THEO_HTLD,
  EXPORT_EXCEL_CHI_TIET_THEO_HTLD:
    APIBaoCaoCapDien.EXPORT_EXCEL_CHI_TIET_THEO_HTLD,
  GET_LIST_COLUMNS_BAO_CAO_CHI_TIET_HINH_THUC_LAP_DAT:
    APIBaoCaoCapDien.GET_LIST_COLUMNS_BAO_CAO_CHI_TIET_HINH_THUC_LAP_DAT,
  UPDATE_LIST_COLUMNS_BAO_CAO_CHI_TIET_HINH_THUC_LAP_DAT:
    APIBaoCaoCapDien.UPDATE_LIST_COLUMNS_BAO_CAO_CHI_TIET_HINH_THUC_LAP_DAT,

  //báo cáo dịch vụ điện
  GET_LIST_DICH_VU_DIEN: APIBaoCaoCapDien.GET_LIST_DICH_VU_DIEN,
  EXPORT_EXCEL_BAO_CAO_DICH_VU_DIEN:
    APIBaoCaoCapDien.EXPORT_EXCEL_BAO_CAO_DICH_VU_DIEN,

  //báo cáo chỉ tiêu chất lượng
  GET_LIST_CHI_TIEU_CHAT_LUONG: APIBaoCaoCapDien.GET_LIST_CHI_TIEU_CHAT_LUONG,
  EXPORT_EXCEL_BAO_CAO_CHI_TIEU_CHAT_LUONG:
    APIBaoCaoCapDien.EXPORT_EXCEL_BAO_CAO_CHI_TIEU_CHAT_LUONG,

  EXPORT_EXCEL_BAO_CAO_CHI_TIEU_CHAT_LUONG_NEW:
    APIBaoCaoCapDien.EXPORT_EXCEL_BAO_CAO_CHI_TIEU_CHAT_LUONG_NEW,

  IN_BIEU_DO_PHU_TAI_CAP_DIEN: APICommon.IN_BIEU_DO_PHU_TAI_CAP_DIEN,
};
