import { METHOD_POST, METHOD_PUT } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  getErrorForm,
  getErrorMessage,
  parseParams,
} from "@utils/function";
import { authGetData, authPostData, authPutData } from "@utils/request";

export const getDataDiemDo = (
  setDataTable,
  setLoading,
  setDataPage,
  payload
) => {
  const queryString = buildQueryString(parseParams(payload));
  authGetData({
    url: `${Endpoint?.GET_DANH_SACH_DIEM_DO}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      setDataTable(res.data);
      setDataPage(res.paging);
    },
  });
};

export const getDataBienBan = (
  maDiemDo,
  maCongTo,
  maBienBan,
  setData,
  setLoading,
  isEdit
) => {
  const queryString = buildQueryString(
    parseParams({
      maDiemDo: maDiemDo,
      maCongTo: maCongTo,
      maBienBan: maBienBan,
      isEdit,
    })
  );
  authGetData({
    url: `${Endpoint?.GET_CHECK_VA_GET_BIEN_BAN}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res) {
        setData(res.data);
      }
    },
  });
};

export const postCongTo = (loaiCongTo, setData, setLoading, data, form) => {
  authPostData({
    url: `${Endpoint.POST_CONG_TO}`,
    method: METHOD_POST,
    payload: { mA_TBI: loaiCongTo },
    setLoading,
    onSuccess: (res) => {
      setData(res.data);
      form.setFieldsValue({heSoNhanTreo: ""})
      for (let i = 0; i < data?.boChiSosTreo?.length; i++) {
        form.setFieldsValue({
          [`ChiSoTreo-${i}`]: "",
        });
      }
    },
  });
};

export const postCongToTH3 = (loaiCongTo, setDataTreo, setLoading) => {
  authPostData({
    url: `${Endpoint.POST_CONG_TO}`,
    method: METHOD_POST,
    payload: { mA_TBI: loaiCongTo },
    setLoading,
    onSuccess: (res) => {
      setDataTreo(res.data);
    },
  });
};

export const PostBienBan = (
  values,
  loaiCongTo,
  data,
  setLoading,
  handleCloseModal,
  details,
  valueThucHien,
  getListDD,
  form
) => {
  const loaiCongToT =
    data.loaiCongToTreo || values.loaiCT ||  values.loaiCongTo || loaiCongTo || data.loaiCongTo ;
  const soBienBan = values.soBienBan;
  const nguoiThucHien = values.nguoiThucHien;
  if (valueThucHien === 1) {
    authPostData({
      url: `${Endpoint.POST__TAO_BIEN_BAN}`,
      method: METHOD_POST,
      payload: {
        maDiemDo: values.maDiemDo,
        ngayTreoThao: values.ngayTreoThao,
        soBienBan: soBienBan,
        thucHien: values.thucHien,
        heSoNhan: values.heSoNhanTreo || data.heSoNhan,
        heSoNhanThao: values.heSoNhanThao || data.heSoNhan,
        nguoiThucHien: nguoiThucHien,
        maCongTo: values.maCongToTreo,
        loaiCongTo: loaiCongToT,
        boChiSoRequestsThao: values?.boChiSoRequestsThao,
        boChiSoRequests: values?.boChiSoRequests,
      },
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          getListDD();
          handleCloseModal();
        }
        if (res.statusCode === 422) {
          getErrorForm(res, form);
        } else {
          getErrorMessage(res, form);
        }
      },
    });
  }
  if (valueThucHien === 2) {
    authPostData({
      url: `${Endpoint.POST__TAO_BIEN_BAN}`,
      method: METHOD_POST,
      payload: {
        maDiemDo: values.maDiemDo,
        ngayTreoThao: values.ngayTreoThao,
        soBienBan: soBienBan,
        thucHien: values.thucHien,
        heSoNhan: values.heSoNhanTreo || data.heSoNhan,
        heSoNhanThao: values.heSoNhanThao || data.heSoNhan,
        nguoiThucHien: nguoiThucHien,
        maCongTo: values.maCongTo || values.maCongToThao,
        loaiCongTo: loaiCongToT,
        boChiSoRequestsThao: values?.boChiSoRequestsThao,
        boChiSoRequests: values?.boChiSoRequests,
      },
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          handleCloseModal();
          getListDD();
        }
        if (res.statusCode === 422) {
          getErrorForm(res, form);
        } else {
          getErrorMessage(res, form);
        }
      },
    });
  }
  if (valueThucHien === 3) {
    authPostData({
      url: `${Endpoint.POST__TAO_BIEN_BAN}`,
      method: METHOD_POST,
      payload: {
        maDiemDo: values.maDiemDo,
        ngayTreoThao: values.ngayTreoThao,
        soBienBan: soBienBan,
        thucHien: values.thucHien,
        heSoNhan: values.heSoNhanTreo || data.heSoNhan,
        heSoNhanThao: values.heSoNhanThao || data.heSoNhan,
        nguoiThucHien: nguoiThucHien,
        maCongTo: values.maCongToTreo,
        loaiCongTo: loaiCongToT,
        boChiSoRequestsThao: values?.boChiSoRequestsThao,
        boChiSoRequests: values?.boChiSoRequests,
      },
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          handleCloseModal();
          getListDD();
        }
        if (res.statusCode === 422) {
          getErrorForm(res, form);
        } else {
          getErrorMessage(res, form);
        }
      },
    });
  }
};

export const PutBienBan = (
  values,
  data,
  setLoading,
  handleCloseModal,
  getListDD,
  form
) => {
  authPutData({
    url: `${Endpoint.PUT_SUA_BIEN_BAN}`,
    method: METHOD_PUT,
    payload: {
      ...data,
      maDiemDo: values.maDiemDo,
      ngayTreoThao: values.ngayTreoThao,
      soBienBan: values.soBienBan,
      thucHien: values.thucHien,
      nguoiThucHien: values.nguoiThucHien,
      heSoNhan: values.heSoNhan || data.heSoNhan,
      heSoNhanTreo: values.heSoNhanTreo || data.heSoNhanTreo,
      maCongTo: values.maCongTo || data.maCongTo,
      maCongToTreo: values.maCongToTreo || data.maCongToTreo,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        handleCloseModal();
        getListDD();
      }
      if (res.statusCode === 422) {
        getErrorForm(res, form);
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const postLayBienBanTuCMIS = (
  {maDiemDoNoiBo,
  setDataTreo,
  setLoading,
  congToTreo,
  congToThao,
  setLayBBTuCMIS,
  loaI_TIMKIEM
}
) => {
  authPostData({
    url: `${Endpoint.POST_LAY_BIEN_BAN_TU_CMIS}`,
    method: METHOD_POST,
    payload: {
      mA_DVIQLY: maDiemDoNoiBo.slice(0, 6),
      mA_DDO: maDiemDoNoiBo,
      loaI_TIMKIEM: loaI_TIMKIEM,
      conG_TO_TREO: congToTreo ? congToTreo : null,
      conG_TO_THAO: congToThao ? congToThao : null,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setDataTreo(res.data);
        setLayBBTuCMIS(true);
      }
    },
  });
};

export const getDataDiemDefault = (setDataTable, setLoading) => {
  authGetData({
    url: `${Endpoint?.GET_DANH_SACH_DIEM_DO}`,
    setLoading,
    onSuccess: (res) => {
      setDataTable(res.data);
    },
  });
};

export const handleDelete =
  ((maCongTo, maDiemDo, getListDD, setLoading) => {
    authPostData({
      url: `${Endpoint.DELETE_BIEN_BAN}`,
      payload: { maDiemDo: maDiemDo, maCongTo: maCongTo },
      method: METHOD_POST,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          getListDD();
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },
  []);

export const selectSua = [
  {
    label: "2. Tháo thiết bị đo đếm",
    value: 2,
  },
  {
    label: "3. Treo tháo thiết bị đo đếm",
    value: 3,
  },
];
export const selectTao = [
  {
    label: "1.Treo mới thiết bị đo đếm",
    value: 1,
  },
];

export const selectTaoKhiCoCT = [
  {
    label: "2. Tháo thiết bị đo đếm",
    value: 2,
  },
  {
    label: "3. Treo tháo thiết bị đo đếm",
    value: 3,
  },
];
