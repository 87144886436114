import PhieuXuatVT from "@modules/treo-thao/common-ver1/phieu-vat-tu/index";
import { treo_thao } from "@permissions/b_treothao";
import { useCallback, useState } from "react";
import { Endpoint } from "@utils/endpoint";
import {
  ENUM_PHIEU_VAT_TU,
  // LOAI_PHIEU_TREOTHAO,
  // TYPE_PHIEUTRA_CHUCNANG,
} from "@utils/constants";
import { Button } from "antd";
import LoadingComponent from "@components/Loading";
// import {  useSelector } from "react-redux";
// import { openModal } from "@state/system-config/reducer";
// import { authSelector } from "@state/auth";
// import DuyetPhieuXuat from "../../common-ver1/thao-tac-phieu-xuat/duyet-phieu";
// import DuyetPhieuTra from "../../common-ver1/thao-tac-phieu-tra/duyet-phieu";
import ModalReturn from "../../common-ver1/modal-return-phieu-vt";
import CheckPermission from "@components/CheckPermission";
export default function Index() {
  // const dispatch = useDispatch();
  const [fetchList, setFetchList] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [arrSelectedRows, setArrSelectedRows] = useState([]);
  // const { user } = useSelector(authSelector);

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setArrSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  const renderButtonTL = () => (
    <CheckPermission permissionCode={treo_thao.b13tl}>
      <Button
        type="primary "
        className="mr-5px btn-bg-yellow"
        onClick={() => handleOpenModal()}
        disabled={!selectedRowKeys.length || selectedRowKeys.length >= 2}
      >
        Trả lại
      </Button>
    </CheckPermission>
  );

  const [loading] = useState(false);
  const [visible, setVisible] = useState(false);
  const handleOpenModal = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const handleCloseModal = () => {
    setVisible(false);
  };

  const showModalReturn = useCallback(() => {
    return (
      visible && (
        <ModalReturn
          setSelectedRowKeys={setSelectedRowKeys}
          visible={visible}
          closeModal={handleCloseModal}
          setFetchList={setFetchList}
          selectedRowKeys={selectedRowKeys}
          url={Endpoint.PKD_TRA_LAI_PHIEU_TREO_THAO}
        />
      )
    );
  }, [selectedRowKeys, visible]);

  // const renderPopupDuyetPhieu = (row, permissionCodeKySo) => {
  //   if (row.loaiPhieuNumber === LOAI_PHIEU_TREOTHAO.PHIEUXUAT) {
  //     dispatch(
  //       openModal({
  //         content: (
  //           <DuyetPhieuXuat
  //             selectRowKeys={row.id}
  //             typeLoaiPhieu={row.loaiPhieuNumber}
  //             nguoiTao={user.name}
  //             setFetchList={setFetchList}
  //             permissionCodeKySo={permissionCodeKySo}
  //             maTrangThai={row.maTrangThai}
  //           />
  //         ),
  //         size: "large",
  //         title: "Duyệt phiếu xuất vật tư",
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       openModal({
  //         content: (
  //           <DuyetPhieuTra
  //             selectRowKeys={row.id}
  //             typeLoaiPhieu={row.loaiPhieuNumber}
  //             type={TYPE_PHIEUTRA_CHUCNANG.TRALAI_DUYET}
  //             nguoiTao={user.name}
  //             setFetchList={setFetchList}
  //             maTrangThai={row.maTrangThai}
  //           />
  //         ),
  //         size: "large",
  //         title: "Duyệt phiếu trả vật tư",
  //       })
  //     );
  //   }
  // };
  //get payload cho xác nhận
  const handleGetPayloadXN = (row) => {
    const payload = {
      ids: row ? [row.id] : selectedRowKeys,
    };

    return payload;
  };
  return (
    <LoadingComponent loading={loading}>
      <PhieuXuatVT
        url={Endpoint.LIST_PHIEU_VAT_TU}
        funcId={ENUM_PHIEU_VAT_TU.PKDDUYETPHIEUVATTU}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        permissionCodeDuyet={treo_thao.b13tl}
        permissionCodeXemPhieu={treo_thao.b13v}
        urlRemove={Endpoint.REMOVE_PHIEU_TRA_VAT_TU}
        permissionCodeXacNhan={treo_thao.b13xn}
        permissionCodeKySo={treo_thao.b13k}
        urlKySo={Endpoint.KY_SO_PHIEU_VAT_TU_HSM}
        urlGetHash={Endpoint.KY_SO_GET_HASH_PHIEUVT}
        urlXN={Endpoint.PKD_XAC_NHAN_PHIEU_TREO_THAO}
        isRowSelect={true}
        isKySo
        rowSelection={rowSelection}
        selectedRowKeys={selectedRowKeys}
        arrSelectedRows={arrSelectedRows}
        payloadXN={handleGetPayloadXN}
        contentXN="Phiếu sẽ được chuyển tiếp cho Phòng vật tư !"
        titleXN="Phòng kinh doanh xác nhận/ký số"
        payloadKySo={{
          phieuIds: selectedRowKeys.toString(),
        }}
        // renderButtonXacNhan={renderButtonXacNhan}
        // renderPopupDuyetPhieu={renderPopupDuyetPhieu}
        setSelectedRowKeys={setSelectedRowKeys}
        renderButtonTL={renderButtonTL}
      />
      {showModalReturn()}
    </LoadingComponent>
  );
}
