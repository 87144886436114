import { authSelector } from "@state/auth";
import { Form } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { handleMultiBoLoc } from "./services";
import FormBoLoc from "./form-bo-loc";

export default function ListFilter(props) {
  const { handleSearch, clearFilter, filterConditions, setType } = props;
  const { user } = useSelector(authSelector);
  const [, setLoading] = useState(false);
  const [loaiYeuCau, setLoaiYeuCau] = useState([]);
  const [mucDich, setMucDich] = useState([]);
  const [soPha, setSoPha] = useState([]);
  const [hinhThucLapDat, setHinhThucLapDat] = useState([]);
  const [hinhThucNopTien, setHinhThucNopTien] = useState([]);
  const [donVi, setDonVi] = useState([]);
  const [tinhTrang, setTinhTrang] = useState([]);

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  useEffect(() => {
    handleMultiBoLoc(
      setLoading,
      setLoaiYeuCau,
      setSoPha,
      setHinhThucLapDat,
      setHinhThucNopTien,
      setTinhTrang,
      setMucDich,
      setDonVi
    );
  }, []);

  return (
    <FormBoLoc
      form={form}
      handleSearch={handleSearch}
      user={user}
      mucDich={mucDich}
      soPha={soPha}
      loaiYeuCau={loaiYeuCau}
      tinhTrang={tinhTrang}
      hinhThucLapDat={hinhThucLapDat}
      hinhThucNopTien={hinhThucNopTien}
      clear={clear}
      donVi={donVi}
      filterConditions={filterConditions}
      setType={setType}
    />
  );
}
