import { authSelector } from "@state/auth";
import { openModal } from "@state/system-config/reducer";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_DS_YEU_CAU,
  ENUM_LOAI_DS_YEU_CAU,
  FORMAT_DATE,
  FORMAT_ENGLISH,
  FUNC_ID_DSYC_TREOTHAO,
  MONTH_STRING,
} from "@utils/constants";
import { customColumn, handlePagination, parseParams } from "@utils/function";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  handleMultiDS,
  handleChiTiet,
  handleListData,
  handleGetDataBienBan,
} from "./services";
import ListYeuCauTable from "./table";
import DetailYeuCau from "../../common-ver1/chi-tiet-yeu-cau";
// import ButtonXemBB from "../../common-ver1/xem-bien-ban";
import ButtonXacNhan from "../../common-ver1/xac-nhan-ky-duyet";
import { Endpoint } from "@utils/endpoint";
import moment from "moment";
import { Tag, Tooltip } from "antd";
import CheckPermission from "@components/CheckPermission";
import { FilePdfOutlined, InfoCircleOutlined } from "@ant-design/icons";
import ViewBB from "../xem-bien-ban";

export default function Index(props) {
  const {
    funcId,
    typeList,
    genExtra,
    fetchList,
    setFetchList,
    isRowSelect,
    rowSelection,
    firstColumns,
    permissionCodeBB,
    urlBB,
    permissionCodeXN,
    urlXN,
    setIsTongCongTy,
    // dataDongBo,
    localFilter,
    setFilterUnit,
    setSelectedRowKeys,
    setDonViId,
    setTenDonVi,
    title,
    content,
    payloadKySo,
    selectedRowKeys,
    permissionCodeKySo,
    setDataUpdate,
    setIsUpdate,
    isKySo,
    setIsSearch,
    genExtraDongBo,
  } = props;

  const dispatch = useDispatch();

  //fill color trạng thái
  const fillColor = (maTrangThai) => {
    let color = "";
    switch (maTrangThai) {
      case -1:
        return (color = "#2D1B33");
      case 2:
        return (color = "#4E7AC7");
      case 3:
        return (color = "#DC4AD2");
      case 4:
        return (color = "#F77A52");
      case 5:
        return (color = "#027A06");
      case 6:
        return (color = "#0E1E8C");
      case 7:
        return (color = "#010340");
      case 8:
        return (color = "#BF0404");
      case 9:
        return (color = "#911146");
      case 10:
        return (color = "#FFAE2F");
      case 11:
        return (color = "#382513");
       
      default:
        return (color = "#04756F");
    }
  };

  //columns loại 1
  const columnsTable = [
    {
      title: "Tên KH - SĐT",
      dataIndex: "tenKHVaSDT",
      key: "tenKHVaSDT",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 250,
      sorter: true,
    },
    {
      title: "Địa chỉ dùng điện",
      dataIndex: "diaChiDungDien",
      key: "diaChiDungDien",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 350,
      sorter: true,
    },
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => getDetail(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: 200,
      sorter: true,
    },
    {
      title: "Loại treo tháo",
      dataIndex: "loaiTreoThao",
      key: "loaiTreoThao",
      render: (text, record) => (
        <div className="table-text-left">{record?.tenLoaiTreoThao}</div>
      ),
      width: 170,
      sorter: true,
    },
    {
      title: "Lý do treo tháo",
      dataIndex: "lyDoTreoThao",
      key: "lyDoTreoThao",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 200,
      sorter: true,
    },
    {
      title: "Ngày treo tháo",
      dataIndex: "ngayTreoThao",
      key: "ngayTreoThao",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: 120,
      sorter: true,
    },
    {
      title: "Đội",
      dataIndex: "doi",
      key: "doi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 200,
      sorter: true,
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text, record) => (
        <div>
          <div
            className="table-text-center"
            style={{
              backgroundColor: fillColor(record.maTrangThai),
              color: "#fff",
            }}
          >
            {text}
          </div>
          {funcId === FUNC_ID_DSYC_TREOTHAO.PKD_NGHIEMTHU &&
          record.isDongBo === false &&
          record.ketQuaDongBo !== null ? (
            <div
              className="table-text-center"
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: "#FF5247",
                cursor: "pointer",
              }}
            >
              <Tooltip title={record.ketQuaDongBo}>
                <InfoCircleOutlined /> Lỗi đồng bộ
              </Tooltip>
            </div>
          ) : null}
        </div>
      ),
      width: 200,
      sorter: true,
    },
    {
      title: "Chủng loại",
      dataIndex: "chungLoai",
      key: "chungLoai",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: 150,
      sorter: true,
    },

    {
      title: "Trạm",
      dataIndex: "tram",
      key: "tram",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 150,
      sorter: true,
    },
    {
      title: "Ngày KĐ",
      dataIndex: "hanKDCongToThao",
      key: "hanKDCongToThao",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: 120,
      sorter: true,
    },

    {
      title: "Mã công tơ tháo",
      dataIndex: "maCongToThao",
      key: "maCongToThao",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 150,
      sorter: true,
    },
    {
      title: "Số công tơ treo",
      dataIndex: "soCongToTreo",
      key: "soCongToTreo",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 150,
      sorter: true,
    },

    {
      title: "Mã sổ GCS",
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 150,
      sorter: true,
    },
    {
      title: "Số cột/hộp",
      dataIndex: "soCotHop",
      key: "soCotHop",
      render: (_, record) => (
        <div className="table-text-left">
          {record.soCot && record.soHop
            ? record.soCot + "/" + record.soHop
            : record.soCot
            ? record.soCot + "/"
            : record.soHop
            ? "/" + record.soHop
            : ""}
        </div>
      ),
      width: 120,
    },
  ];

  //columns loại 3
  const columnsNVAnToan = [
    {
      title: "Tên KH - SDT",
      dataIndex: "tenKHVaSDT",
      key: "tenKHSDT",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 250,
      sorter: true,
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 350,
      sorter: true,
    },
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => getDetail(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: 200,
      sorter: true,
    },
    {
      title: "Loại treo tháo",
      dataIndex: "loaiTreoThao",
      key: "loaiTreoThao",
      render: (text) => <div className="table-text-le">{text}</div>,
      width: 170,
      sorter: true,
    },
    {
      title: "Lý do treo tháo",
      dataIndex: "lyDoTreoThao",
      key: "lyDoTreoThao",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 200,
      sorter: true,
    },
    {
      title: "Ngày treo tháo",
      dataIndex: "ngayTreoThao",
      key: "ngayTreoThao",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: 120,
      sorter: true,
    },
    {
      title: "Đội",
      dataIndex: "doi",
      key: "doi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 200,
      sorter: true,
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text, record) => (
        <div
          className="table-text-center"
          style={{
            backgroundColor: fillColor(record.maTrangThai),
            color: "#fff",
          }}
        >
          {text}
        </div>
      ),
      width: 200,
      sorter: true,
    },
    {
      title: "Trạm",
      dataIndex: "tram",
      key: "tram",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 150,
      sorter: true,
    },

    {
      title: "Ngày KĐ",
      dataIndex: "hanKDCongToThao",
      key: "hanKDCongToThao",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: 120,
      sorter: true,
    },
    {
      title: "NV treo tháo",
      dataIndex: "nhanVienTreoThao",
      key: "nhanVienTreoThao",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 210,
      sorter: true,
    },
    {
      title: "NV niêm phong",
      dataIndex: "nhanVienNiemPhong",
      key: "nhanVienNiemPhong",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 210,
      sorter: true,
    },
    {
      title: "NV hỗ trợ",
      dataIndex: "nhanVienHoTros",
      key: "nhanVienHoTros",
      render: (tags) => {
        if (tags && tags.length) {
          return (
            <div>
              {tags.map((tag, index) => {
                if (tag !== " - " && tag !== "-")
                  return (
                    <Tag key={index} className="mg-2px">
                      {tag.toUpperCase()}
                    </Tag>
                  );
                else return null;
              })}
            </div>
          );
        }
        return null;
      },
      width: 210,
      sorter: true,
    },
  ];

  //column loại 1, danh sách Yêu cầu chờ phân công
  const columnsTableChoPhanCong = [
    {
      title: "Tên KH - SĐT",
      dataIndex: "tenKHVaSDT",
      key: "tenKHVaSDT",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 250,
      sorter: true,
    },
    {
      title: "Địa chỉ dùng điện",
      dataIndex: "diaChiDungDien",
      key: "diaChiDungDien",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 350,
      sorter: true,
    },
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => getDetail(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: 200,
      sorter: true,
    },
    {
      title: "Loại treo tháo",
      dataIndex: "loaiTreoThao",
      key: "loaiTreoThao",
      render: (text, record) => (
        <div className="table-text-left">{record?.tenLoaiTreoThao}</div>
      ),
      width: 170,
      sorter: true,
    },
    {
      title: "Lý do treo tháo",
      dataIndex: "lyDoTreoThao",
      key: "lyDoTreoThao",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 200,
      sorter: true,
    },
    {
      title: "Ngày treo tháo",
      dataIndex: "ngayTreoThao",
      key: "ngayTreoThao",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: 120,
      sorter: true,
    },
    {
      title: "Đội",
      dataIndex: "doi",
      key: "doi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 200,
      sorter: true,
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text, record) => (
        <div
          className="table-text-center"
          style={{
            backgroundColor: fillColor(record.maTrangThai),
            color: "#fff",
          }}
        >
          {text}
        </div>
      ),
      width: 200,
      sorter: true,
    },
    {
      title: "Chủng loại",
      dataIndex: "chungLoai",
      key: "chungLoai",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: 150,
      sorter: true,
    },
    {
      title: "Trạm",
      dataIndex: "tram",
      key: "tram",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 150,
      sorter: true,
    },
    {
      title: "Ngày KĐ",
      dataIndex: "hanKDCongToThao",
      key: "hanKDCongToThao",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: 120,
      sorter: true,
    },

    {
      title: "Mã công tơ tháo",
      dataIndex: "maCongToThao",
      key: "maCongToThao",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 150,
      sorter: true,
    },
    {
      title: "Số công tơ treo",
      dataIndex: "soCongToTreo",
      key: "soCongToTreo",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 150,
      sorter: true,
    },
    {
      title: "Mã sổ GCS",
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 150,
      sorter: true,
    },
    {
      title: "Mã ghi chữ",
      dataIndex: "maGhiChu",
      key: "maGhiChu",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 150,
      sorter: true,
    },
    {
      title: "Số cột/hộp",
      dataIndex: "soCotHop",
      key: "soCotHop",
      render: (_, record) => (
        <div className="table-text-left">
          {record.soCot && record.soHop
            ? record.soCot + "/" + record.soHop
            : record.soCot
            ? record.soCot + "/"
            : record.soHop
            ? "/" + record.soHop
            : ""}
        </div>
      ),
      width: 120,
    },
  ];

  //set state columns loại 1 hoặc loại 3
  const [columns, setColumns] = useState(
    typeList === ENUM_DS_YEU_CAU.NVANTOAN_XACNHANANTOAN
      ? columnsNVAnToan
      : typeList === ENUM_DS_YEU_CAU.DOITRUONG_YEUCAUDAXUATVATTU
      ? columnsTableChoPhanCong
      : columnsTable
  );

  const { user } = useSelector(authSelector);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState(
    localFilter && localFilter.ngayTaoTu && localFilter.ngayTaoDen
      ? {
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: user && user.pageSize ? user.pageSize : DEFAULT_PAGESIZE,
          donViId:
            localFilter && localFilter.donViId
              ? localFilter.donViId.toString()
              : user.unitId,
          funcId: funcId,
          ...parseParams(location.search),
          loaiTreoThao:
            localFilter && localFilter.loaiTreoThao
              ? localFilter.loaiTreoThao.toString()
              : null,
          ngayBatDauTT:
            localFilter && localFilter.ngayTaoTu
              ? localFilter.ngayTaoTu
              : moment(moment().startOf(MONTH_STRING), FORMAT_DATE),
          ngayKetThucTT:
            localFilter && localFilter.ngayTaoDen
              ? localFilter.ngayTaoTu
              : moment(moment(), FORMAT_DATE),
        }
      : {
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: user && user.pageSize ? user.pageSize : DEFAULT_PAGESIZE,
          donViId:
            localFilter && localFilter.donViId
              ? localFilter.donViId.toString()
              : user.unitId,
          funcId: funcId,
          ...parseParams(location.search),
          loaiTreoThao:
            localFilter && localFilter.loaiTreoThao
              ? localFilter.loaiTreoThao.toString()
              : null,
        }
  );

  const [donVi, setDonVi] = useState([]);
  const [tram, setTram] = useState([]);
  const [trangThai, setTrangThai] = useState([]);
  const [loaiTreoThao, setLoaiTreoThao] = useState([]);
  const [dataViewBB, setDataViewBB] = useState([]);
  const [visiblePopupViewBB, setVisiblePopupViewBB] = useState(false);
  const [visiblePDF, setVisiblePDF] = useState(false);
  const [fileData, setFileData] = useState("");

  //change show page size
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //click xem biên bản
  const handleClickViewBB = useCallback((id) => {
    const url = `${Endpoint.VIEW_LIST_BIEN_BAN_TREO_THAO}?YeuCauId=${id}`;
    handleGetDataBienBan(
      url,
      setLoading,
      setDataViewBB,
      setVisiblePopupViewBB,
      setVisiblePDF,
      setFileData
    );
  }, []);

  //đóng modal biên bản
  const handleCloseModalPopupViewBB = () => {
    setVisiblePopupViewBB(false);
    setVisiblePDF(false);
    setFileData("");
  };

  //render popup xem biên bản
  const renderPDFViewBB = useCallback(() => {
    return (
      <ViewBB
        dataSource={dataViewBB}
        visiblePopupViewBB={visiblePopupViewBB}
        visiblePDF={visiblePDF}
        fileData={fileData}
        closeModal={handleCloseModalPopupViewBB}
        setVisiblePDF={setVisiblePDF}
        setFileData={setFileData}
      />
    );
  }, [dataViewBB, fileData, visiblePDF, visiblePopupViewBB]);

  //xử lý thêm cột xem biên bản, xác nhận nếu có permissionCode
  useEffect(() => {
    if (
      permissionCodeBB &&
      typeList !== ENUM_DS_YEU_CAU.NVANTOAN_XACNHANANTOAN
    ) {
      columns.push({
        title: "Tác vụ",
        dataIndex: "",
        key: "",
        render: (_, record) => (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                textAlign: "center",
                display: "flex",
                marginRight: "5px",
              }}
            >
              {permissionCodeBB &&
              record?.isViewPDF &&
              typeList !== ENUM_DS_YEU_CAU.NVANTOAN_XACNHANANTOAN ? (
                <CheckPermission permissionCode={permissionCodeBB}>
                  <Tooltip title="Xem biên bản">
                    <div
                      style={{ color: "#FF2D00", fontSize: 20, marginLeft: 10 }}
                    >
                      <FilePdfOutlined
                        onClick={() => handleClickViewBB(record.id)}
                      />
                    </div>
                  </Tooltip>
                </CheckPermission>
              ) : // <ButtonXemBB
              //   url={`${Endpoint.VIEW_LIST_BIEN_BAN_TREO_THAO}?YeuCauId=${record.id}`}
              //   permissionCode={permissionCodeBB}
              // />
              null}
            </div>
            {permissionCodeXN &&
            typeList !== ENUM_DS_YEU_CAU.NVANTOAN_XACNHANANTOAN ? (
              <ButtonXacNhan
                url={urlXN}
                title={title}
                content={content}
                selectedRowKeys={[record.id]}
                setLoading={setLoading}
                setFetchList={setFetchList}
                permissionCodeKySo={permissionCodeKySo}
                isKySo={isKySo}
                isIcon={true}
                // urlKySo={Endpoint.XAC_THUC_KY_SO}
                urlKySo={Endpoint.KY_SO_YEU_CAU_HSM}
                payload={{ ids: [record.id].toString() }}
                urlGetHash={Endpoint.KY_SO_GET_HASH}
                payloadKySo={{
                  yeuCauIds: record.id,
                  // maTrangThai: record.maTrangThai,
                }}
                setSelectedRowKeys={setSelectedRowKeys}
                className="mr-5px"
                disabled={!selectedRowKeys?.length}
              />
            ) : null}
          </div>
        ),
        fixed: "right",
        width: 100,
      });
      setColumns(columns);
    }
    // else if (
    //   permissionCodeBB &&
    //   typeList !== ENUM_DS_YEU_CAU.NVANTOAN_XACNHANANTOAN
    // ) {
    //   columns.push({
    //     title: "",
    //     render: (_, record) => (
    //       <div style={{ textAlign: "center", display: "flex" }}>
    //         <ButtonXemBB
    //           url={`${Endpoint.IN_BIEN_BAN_TREO_THAO}?YeuCauId=${record.id}`}
    //           permissionCode={permissionCodeBB}
    //         />
    //       </div>
    //     ),
    //     fixed: "right",
    //     width: 60,
    //   });
    //   setColumns(columns);
    // }
  }, [
    columns,
    content,
    handleClickViewBB,
    isKySo,
    payloadKySo,
    permissionCodeBB,
    permissionCodeKySo,
    permissionCodeXN,
    selectedRowKeys,
    setFetchList,
    setSelectedRowKeys,
    title,
    typeList,
    urlBB,
    urlXN,
  ]);

  //get list data yêu cầu
  const getListData = useCallback(() => {
    handleListData(
      filterConditions,
      typeList,
      setLoading,
      setDataSource,
      setTotalCount
    );
    localStorage.removeItem("createFilterPhieu");
  }, [filterConditions, typeList]);

  useEffect(() => {
    getListData();
  }, [getListData]);

  useEffect(() => {
    //call api list if fetchList = true
    if (fetchList) {
      getListData();
      setFetchList(false);
    }
  }, [fetchList, getListData, setFetchList]);

  useEffect(() => {
    if (setDataUpdate && setIsUpdate) {
      setDataUpdate(dataSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, setDataUpdate]);

  //Lấy data để hiển thị cho bộ lọc
  useEffect(() => {
    //Nếu danh sách yêu cầu loại 1
    let type = ENUM_LOAI_DS_YEU_CAU.DANH_SACH_1;
    if (typeList === ENUM_DS_YEU_CAU.NVANTOAN_XACNHANANTOAN) {
      //Nếu danh sách yêu cầu loại 3
      type = ENUM_LOAI_DS_YEU_CAU.DANH_SACH_3;
    }
    handleMultiDS(
      setLoading,
      setDonVi,
      setTrangThai,
      setTram,
      setLoaiTreoThao,
      type,
      funcId
    );
  }, [funcId, typeList]);

  //tìm kiếm tổng công ty của select đơn vị
  const handleFindUnit = useCallback(
    (donViId) => {
      const unit = donVi.find((item) => item.id === donViId);
      if (unit !== undefined && setIsTongCongTy) {
        setIsTongCongTy(unit.isTongCongTy);
      }

      if (unit !== undefined && setFilterUnit) setFilterUnit(unit.tenDonVi);
      if (setTenDonVi) setTenDonVi(unit.tenDonVi);
      if (setIsSearch) {
        if (unit.isTongCongTy) {
          setIsSearch(false);
        } else setIsSearch(true);
      }

      //Set lại giá trị đơn vị khi thay chọn
      if (setDonViId) setDonViId(donViId);
    },
    [
      donVi,
      setDonViId,
      setFilterUnit,
      setIsSearch,
      setIsTongCongTy,
      setTenDonVi,
    ]
  );

  //tìm kiếm dữ liệu
  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        pageIndex: DEFAULT_PAGEINDEX,
        ...values,
        donViId: values.donViId,
        ngayBatDauTT: moment(values.ngayBatDauTT).format(FORMAT_ENGLISH),
        ngayKetThucTT: moment(values.ngayKetThucTT).format(FORMAT_ENGLISH),
      }));
      handleFindUnit(values.donViId);
      if (setDonViId) setDonViId(values.donViId);

      if (setSelectedRowKeys) setSelectedRowKeys([]);
    },
    [handleFindUnit, setDonViId, setSelectedRowKeys]
  );

  //clear bộ lọc
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
      funcId: funcId,
    });
    handleFindUnit(user.unitId);
    if (setSelectedRowKeys) setSelectedRowKeys([]);
  }, [funcId, handleFindUnit, setSelectedRowKeys, user.unitId]);

  //nếu có firstColumns thì thêm firstColumns vào columns
  useEffect(() => {
    let newColumns = [];
    if (firstColumns && Object.keys(firstColumns).length > 0) {
      columns.unshift(firstColumns);
      columns[0].width = 80;
      columns[0].fixed = "left";
    }
    newColumns = columns;
    setColumns(newColumns);
  }, [columns, firstColumns]);

  //chi tiết yêu cầu
  const getDetail = (record) => {
    //call api chi tiết
    handleChiTiet(record.id, setLoading, (detailYC) => {
      return dispatch(
        openModal({
          content: (
            <DetailYeuCau
              yeuCauId={record.id}
              detail={detailYC}
              loading={loading}
            />
          ),
          size: "large",
          title: `Chi tiết yêu cầu treo tháo`,
        })
      );
    });
  };

  //doubleClick
  const handleDoubleGetDetail = (record) => {
    return {
      onDoubleClick: () => {
        //call api chi tiết
    handleChiTiet(record.id, setLoading, (detailYC) => {
      return dispatch(
        openModal({
          content: (
            <DetailYeuCau
              yeuCauId={record.id}
              detail={detailYC}
              loading={loading}
            />
          ),
          size: "large",
          title: `Chi tiết yêu cầu treo tháo`,
        })
      );
    });
      },
    };
  }

  return (
    <Fragment>
      <ListYeuCauTable
        loading={loading}
        dataSource={dataSource}
        totalData={totalCount}
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        onChangePagination={onChangePagination}
        filterConditions={filterConditions}
        rowSelection={isRowSelect ? rowSelection : null}
        genExtra={genExtra}
        user={user}
        typeList={typeList}
        columns={customColumn(columns, filterConditions)}
        donVi={donVi}
        trangThai={trangThai}
        tram={tram}
        loaiTreoThao={loaiTreoThao}
        handleChange={handleFindUnit}
        genExtraDongBo={genExtraDongBo}
        onRow={handleDoubleGetDetail}
      />
      {renderPDFViewBB()}
    </Fragment>
  );
}
