import FormComponent from "@components/Form";
import { Col, Row, Form, Input, Button, Select } from "antd";
import { Fragment, useEffect } from "react";
const { TextArea } = Input;
const { Option } = Select;

export default function Index(props) {
  const { form, onFinish, typeDetail, loading, detailThongTin } = props;
  const arrHinhThuc = [
    {
      value: "QTCC",
      name: "Qua trạm công cộng",
    },
    {
      value: "NCST",
      name: "Nâng công suất trạm biến áp",
    },
    {
      value: "DTTBA",
      name: "Đầu tư trạm biến áp",
    },
    {
      value: "DNSTCD",
      name: "Dùng nhờ sau trạm chuyên dùng",
    },
    {
      value: "HTK",
      name: "Hình thức khác",
    },
  ];

  useEffect(() => {
    form.setFieldsValue(detailThongTin);
  }, [detailThongTin, form]);
  return (
    <FormComponent
      form={form}
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{
        ...detailThongTin,
      }}
    >
      <Row gutter={24}>
        <Col span={24} md={12}>
          <h6 className="fs-12px c-t-label">Mã hồ sơ</h6>
          <h5 className="fs-14px font-bold">{detailThongTin?.maHoSo}</h5>
        </Col>
        <Col span={24} md={12}>
          <h6 className="fs-12px c-t-label">Tên khách hàng</h6>
          <h5 className="fs-14px font-bold">{detailThongTin?.tenKhachHang}</h5>
        </Col>
        <Col span={24} md={12}>
          {typeDetail ? (
            <Fragment>
              <h6 className="fs-12px c-t-label">Số lượng MBA</h6>
              <h5 className="fs-14px font-bold">
                {detailThongTin?.soLuongMBA}
              </h5>
            </Fragment>
          ) : (
            <Form.Item name="soLuongMBA" label="Số lượng MBA">
              <Input type="number" min={0} />
            </Form.Item>
          )}
        </Col>
        <Col span={24} md={12}>
          {typeDetail ? (
            <Fragment>
              <h6 className="fs-12px c-t-label">Hình thức cấp điện</h6>
              <h5 className="fs-14px font-bold">
                {detailThongTin?.tenHinhThucCapDien}
              </h5>
            </Fragment>
          ) : (
            <Form.Item name="hinhThucCapDien" label="Hình thức cấp điện">
              <Select placeholder="Chọn hình thức cấp điện">
                {arrHinhThuc.map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Col>
        <Col span={24} md={12}>
          {typeDetail ? (
            <Fragment>
              <h6 className="fs-12px c-t-label">Công suất TBA</h6>
              <h5 className="fs-14px font-bold">
                {detailThongTin?.congSuatTBA}
              </h5>
            </Fragment>
          ) : (
            <Form.Item name="congSuatTBA" label="Công suất TBA">
              <Input type="number" min={0} />
            </Form.Item>
          )}
        </Col>
        <Col span={24} md={12}>
          {typeDetail ? (
            <Fragment>
              <h6 className="fs-12px c-t-label">Chi phí TBA</h6>
              <h5 className="fs-14px font-bold">{detailThongTin?.chiPhiTBA}</h5>
            </Fragment>
          ) : (
            <Form.Item name="chiPhiTBA" label="Chi phí TBA">
              <Input type="number" min={0} />
            </Form.Item>
          )}
        </Col>
        <Col span={24}>
          {typeDetail ? (
            <Fragment>
              <h6 className="fs-12px c-t-label">Ghi chú</h6>
              <h5 className="fs-14px font-bold">{detailThongTin?.ghiChu}</h5>
            </Fragment>
          ) : (
            <Form.Item name="ghiChu" label="Ghi chú">
              <TextArea rows={3} />
            </Form.Item>
          )}
        </Col>
      </Row>
      {typeDetail ? null : (
        <Row gutter={24} align="middle" justify="end">
          <Col span={24} md={24}>
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Cập nhật
            </Button>
          </Col>
        </Row>
      )}
    </FormComponent>
  );
}
