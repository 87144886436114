import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { authPostData } from "@utils/request";
import { alertMessage } from "@utils/function";
import { Endpoint } from "@utils/endpoint";

export const handeConfirmXacNhan = (
  detail,
  setLoading,
  setFetchDataTable,
  handleCloseModalXacNhan,
  t
) => {
  const data = {
    id: detail.id,
  };
  authPostData({
    url: "",
    setLoading,
    method: METHOD_POST,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModalXacNhan();
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const handleConfirmThuTien = (
  setLoading,
  values,
  detail,
  setFetchDataTable,
  handleCloseModalXacNhan,
  checked,
  content
) => {
  const data = {
    ...values,
    dangKyTriAn: checked,
    hoSoId: detail.id,
    thoiGianBatDauCoHieuLuc: content.ngayCoHieuLuc,
    thoiGianHetHieuLuc: content.ngayHetHieuLuc,
  };
  authPostData({
    url: Endpoint.XAC_NHAN_THU_TIEN_TU_NVKS,
    setLoading,
    method: METHOD_POST,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModalXacNhan();
      }
    },
  });
};
