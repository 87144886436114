// hình thức
export const NAME_HINHTHUC_EMAIL_ZALO =
  "Hình thức email, zalo, app";
export const NAME_HINHTHUC_SMS =
  "Hình thức sms";


// đối tượng id
export const NAME_TBdangSuDungDichVuTinNhan =
  "e12382fc-5ebb-474d-a1a7-de277b424035";
export const NAME_TB_COVID_19_DOT_2 = "e12382fc-5ebb-474d-a1a7-de277b424034";
export const NAME_TB_KH_CO_SL_TANG_30PT =
  "Thông báo khách hàng có sản lượng tăng từ 30% so với tháng trước";
export const NAME_TB_Ngung_Cung_Cap_Dien =
  "Thông báo ngừng cung cấp điện";
export const NAME_TB_TIEN_DIEN_LAN_2 = "Thông báo tiền điện lần 2";
export const NAME_TB_TIEN_DIEN_LAN_2_ZALO_MAIL_KHONG_THANHCONG =
  "Thông báo tiền điện lần 2 (nhắn tin zalo/mail không thành công)";
export const NAME_TB_XAC_NHAN_SL_DIEN_MT_SAU_3GIA =
  "Xác nhận sản lượng điện mặt trời - Sau 01/07/2019 - 3 biểu giá";
export const NAME_TB_XAC_NHAN_SL_DIEN_MT_SAU_1GIA =
  "Xác nhận sản lượng điện mặt trời - Sau 01/07/2019 - 1 biểu giá";
export const NAME_TB_XAC_NHAN_SL_DIEN_MT_TRUOC_3GIA =
  "Xác nhận sản lượng điện mặt trời - Trước 01/07/2019 - 3 biểu giá";
export const NAME_TB_XAC_NHAN_SL_DIEN_MT_TRUOC_1GIA =
  "Xác nhận sản lượng điện mặt trời - Trước 01/07/2019 - 1 biểu giá";
export const NAME_TB_KIEM_TRA_AP_GIA_BAN_DIEN =
  "Thông báo kiểm tra áp giá bán điện";
export const NAME_TB_KIEM_TRA_KH_2_DINH_MUC =
  "Thông báo kiểm tra khách hàng 2 định mức trở lên";
export const NAME_TB_THAY_DOI_LICH_GCS_TET =
  "Thông báo thay đổi lịch GCS - Tết nguyên đán";
export const NAME_TB_THAY_DOI_LICH_GCS_KH_GHEP_TONG_NHIEU_DIEM_DO =
  "Thông báo thay đổi lịch ghi chỉ số - KH ghép tổng nhiều điểm đo";
export const NAME_TB_THAY_DOI_LICH_GCS_KH_GCS_TU_2KY =
  "Thông báo thay đổi lịch ghi chỉ số - KH ghi chỉ số từ 2 kỳ trở lên";
export const NAME_TB_THAY_DOI_LICH_GCS_KH_1_DIEM_DO_VA_GCS_1_KY =
  "Thông báo thay đổi lịch ghi chỉ số - KH 1 điểm đo và ghi chỉ số 1 kỳ";
export const NAME_TB_CS_THAY_CT_DINHKY_THAO_COKHI_TREO_COKHI =
  "Thông báo chỉ số công tơ thay định kỳ - Tháo CT cơ khí treo CT cơ khí";
export const NAME_TB_CS_THAY_CT_DINHKY_THAO_COKHI_TREO_DIENTU =
  "Thông báo chỉ số công tơ thay định kỳ - Tháo CT cơ khí treo CT điện tử";
export const NAME_TB_CS_THAY_CT_DINHKY_THAO_DIENTU_TREO_DIENTU =
  "Thông báo chỉ số công tơ thay định kỳ - Tháo CT điện tử treo CT điện tử";
export const NAME_TB_KH_THAY_DINH_KY_CT =
  "Thông báo kế hoạch thay định kỳ công tơ";
export const NAME_TB_KH_CO_SL_TANG_50PT =
  "Thông báo khách hàng có sản lượng tăng từ 50% so với tháng trước";
export const NAME_TB_KH_SH_KHONG_SUDUNG_DIEN_6_THANG =
  "Thông báo KH SH không sử dụng điện 6 tháng liên tục";
