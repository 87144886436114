import { Fragment } from "react";
import TableComponent from "@components/TableComponent";
import { Container } from "./css-styled";

export default function ListYeuCauTable(props) {
  const {
    loading,
    dataSource,
    totalData,
    onChangePagination,
    filterConditions,
    rowSelection,
    columns,
    rowKey,
    onRow,
  } = props;
  return (
    <Fragment>
      <Container>
        <TableComponent
          header={"Danh sách điểm đo"}
          dataSource={dataSource}
          columns={columns}
          totalData={totalData}
          loading={loading}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
          scrollY={520}
          rowSelection={rowSelection ? rowSelection : null}
          rowKey={rowKey}
          onRow={onRow ? onRow : null}
        />
      </Container>
    </Fragment>
  );
}
