import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APINghiemThu = {
  LIST_NHAN_VIEN_NGHIEM_THU: `${BASE_API_URL_CAP_DIEN}/nghiem-thu/list-nhan-vien`,
  LIST_NHAN_VIEN_TREO_THAO_NGHIEM_THU: `${BASE_API_URL_CAP_DIEN}/nghiem-thu/list-nhan-vien-treo-thao`,
  LIST_NHAN_VIEN_NIEM_PHONG_NGHIEM_THU: `${BASE_API_URL_CAP_DIEN}/nghiem-thu/list-nhan-vien-niem-phong`,
  XAC_NHAN_NGHIEM_THU: `${BASE_API_URL_CAP_DIEN}/nghiem-thu/xac-nhan-nghiem-thu`,
  NHAN_VIEN_TREO_THAO_VA_NIEM_PHONG: `${BASE_API_URL_CAP_DIEN}/nghiem-thu/get-nvtt-nvnp`,
  XAC_NHAN_NGHIEM_THU_MOI: `${BASE_API_URL_CAP_DIEN}/nghiem-thu/xac-nhan-nghiem-thu-moi`,
};
