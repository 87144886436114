import {
  Col,
  Form,
  Row,
  Select,
  Button,
  Tooltip,
  Input,
  Checkbox,
  InputNumber,
} from "antd";
import DatePickerComponent from "../datepicker/DatePicker";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import FormComponent from "@components/Form";
import moment from "moment";
import {
  ENUM_HINHTHUC_KTCT,
  ENUM_KY_GCS_KTCT,
  FORMAT_DATE,
  FORMAT_ENGLISH,
  FORMAT_MONTH,
  TYPE_TABLE_CONGTO,
  ENUM_CHENHLECH_KTCT,
  ENUM_SOSANH_KTCT,
} from "@utils/constants";
import { Fragment } from "react";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";
import CheckPermission from "@components/CheckPermission";
import { buildQueryString, parseParams } from "@utils/function";

const { Option } = Select;

export default function FormBoLoc(props) {
  const {
    user,
    form,
    handleSearch,
    tooltip,
    clear,
    filterConditions,
    trangThai,
    typeList,
    checkTraLai,
    soPha,
    firstTrangThai,
    onChangeTrangThai,
    disableChenhLech,
    onChangeChenhLech,
    urlXuatExcel,
    permissionExcel,
    handleExportExcel,
    filterConditionsTraCuu,
    checkLocTheoThang,
    locTheoThang,
  } = props;
  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
      }}
      autoComplete="off"
    >
      <Row gutter={24}>
        {typeList !== TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA &&
        typeList !== TYPE_TABLE_CONGTO.DONGBO_CMIS &&
        typeList !== TYPE_TABLE_CONGTO.CHISO_EVN_HES ? (
          <Fragment>
            <Col span={24} md={7}>
              <Form.Item name="donViId" label="Đơn vị">
                {!user.isTongCongTy ? (
                  <Select defaultValue={user.unitId} disabled>
                    <Option value={user.unitId}>{user.unitName}</Option>
                  </Select>
                ) : (
                  <Selection
                    url={Endpoint.LIST_DONVI_KTCT}
                    form={form}
                    formKey="donViId"
                    notClear={true}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={24} md={7}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  if (prevValues.donViId !== currentValues.donViId) {
                    form.setFieldsValue({
                      doiId: undefined,
                    });
                  }
                  return prevValues.donViId !== currentValues.donViId;
                }}
              >
                {({ getFieldValue }) => {
                  if (getFieldValue("donViId")) {
                    return (
                      <Form.Item name="doiId" label="Đội">
                        <Selection
                          url={`${
                            Endpoint.LIST_DOI_KTCT
                          }?donviId=${getFieldValue("donViId")}`}
                          formKey="doiId"
                          form={form}
                        />
                      </Form.Item>
                    );
                  }
                  return (
                    <Form.Item name="doiId" label="Đội">
                      <Selection
                        url={`${Endpoint.LIST_DOI_KTCT}?donviId=${user.unitId}`}
                        formKey="doiId"
                        form={form}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={24} md={7}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  if (prevValues.donViId !== currentValues.donViId) {
                    form.setFieldsValue({
                      tramIds: undefined,
                    });
                  }
                  return prevValues.donViId !== currentValues.donViId;
                }}
              >
                {({ getFieldValue }) => {
                  if (getFieldValue("donViId")) {
                    return (
                      <Form.Item name="tramIds" label="Trạm">
                        <Selection
                          mode="multiple"
                          url={`${
                            Endpoint.LIST_TRAM_CT
                          }?donviId=${getFieldValue("donViId")}`}
                          formKey="tramIds"
                          form={form}
                        />
                      </Form.Item>
                    );
                  }
                  return (
                    <Form.Item name="tramIds" label="Trạm">
                      <Selection
                        mode="multiple"
                        url={`${Endpoint.LIST_TRAM_CT}?donviId=${user.unitId}`}
                        formKey="tramIds"
                        form={form}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={24} md={3}>
              <Form.Item name="soPha" label="Số pha">
                <Select placeholder="Tất cả" allowClear>
                  {soPha && soPha.length > 0
                    ? soPha.map((item, index) => {
                        if (item.value !== null) {
                          return (
                            <Option key={index} value={item.value.toString()}>
                              {item.name}
                            </Option>
                          );
                        }
                        return null;
                      })
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={7}>
              <Form.Item name="trangThaiId" label="Trạng thái">
                <Select
                  placeholder="Tất cả"
                  defaultValue={firstTrangThai}
                  allowClear
                >
                  {trangThai && trangThai.length > 0
                    ? trangThai.map((item, index) => {
                        if (item.value !== null) {
                          return (
                            <Option key={index} value={item.value.toString()}>
                              {item.name}
                            </Option>
                          );
                        }
                        return null;
                      })
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={4}>
              <Form.Item name="ngayBatDau" label="Ngày treo tháo">
                <DatePickerComponent
                  defaultValue={
                    filterConditions.ngayBatDau
                      ? moment(filterConditions.ngayBatDau, FORMAT_ENGLISH)
                      : moment(moment(), FORMAT_DATE)
                  }
                  format={FORMAT_ENGLISH}
                  form={form}
                  formKey={"ngayBatDau"}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={4}>
              <Form.Item name="ngayKetThuc" label="&nbsp;">
                <DatePickerComponent
                  defaultValue={
                    filterConditions.ngayKetThuc
                      ? moment(filterConditions.ngayKetThuc, FORMAT_ENGLISH)
                      : moment(moment(), FORMAT_DATE)
                  }
                  format={FORMAT_ENGLISH}
                  form={form}
                  formKey={"ngayKetThuc"}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={4}>
              <Form.Item name="maSoGCS" label="Mã sổ GCS">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={5}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  if (
                    prevValues.donViId !== currentValues.donViId ||
                    prevValues.doiId !== currentValues.doiId
                  ) {
                    form.setFieldsValue({
                      nhanVienKTCTId: undefined,
                    });
                  }
                  return prevValues.donViId !== currentValues.donViId;
                }}
              >
                {({ getFieldValue }) => {
                  if (user.isTongCongTy) {
                    const url =
                      form.getFieldValue("doiId") !== undefined
                        ? `${Endpoint.LIST_NV_KTCT}?donviId=${getFieldValue(
                            "donViId"
                          )}&doiId=${form.getFieldValue("doiId")}`
                        : `${Endpoint.LIST_NV_KTCT}?donviId=${getFieldValue(
                            "donViId"
                          )}`;
                    return (
                      <Form.Item name="nhanVienKTCTId" label="NV kiểm tra">
                        <Selection
                          url={url}
                          formKey="nhanVienKTCTId"
                          form={form}
                        />
                      </Form.Item>
                    );
                  } else if (!user.isTongCongTy) {
                    const url =
                      form.getFieldValue("doiId") !== undefined
                        ? `${Endpoint.LIST_NV_KTCT}?donviId=${getFieldValue(
                            "donViId"
                          )}&doiId=${form.getFieldValue("doiId")}`
                        : `${Endpoint.LIST_NV_KTCT}?donviId=${getFieldValue(
                            "donViId"
                          )}`;
                    return (
                      <Form.Item name="nhanVienKTCTId" label="NV kiểm tra">
                        <Selection
                          url={url}
                          formKey="nhanVienKTCTId"
                          form={form}
                        />
                      </Form.Item>
                    );
                  }
                }}
              </Form.Item>
            </Col>
            <Col span={24} md={16}>
              <Form.Item name="searchTerm" label="Từ khóa">
                <Input
                  prefix={<SearchOutlined />}
                  suffix={
                    <Tooltip title={tooltip}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Item name="biTraLai" style={{ margin: 0 }}>
                <Checkbox
                  onChange={checkTraLai}
                  defaultChecked={filterConditions.biTraLai ? true : false}
                >
                  Bị trả lại
                </Checkbox>
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Button className="mr-24px" onClick={clear}>
                Bỏ lọc
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                form="filter-form"
                className="mr-24px"
              >
                Tìm kiếm
              </Button>
              {urlXuatExcel ? (
                <CheckPermission permissionCode={permissionExcel}>
                  <Button
                    type="primary"
                    onClick={() =>
                      handleExportExcel(
                        `${urlXuatExcel}?${buildQueryString(
                          parseParams(filterConditions)
                        )}`
                      )
                    }
                  >
                    Xuất Excel
                  </Button>
                </CheckPermission>
              ) : null}
            </Col>
          </Fragment>
        ) : typeList === TYPE_TABLE_CONGTO.CHISO_EVN_HES ? (
          <Fragment>
            <Col span={24} md={7}>
              <Form.Item name="donViId" label="Đơn vị">
                {!user.isTongCongTy ? (
                  <Select defaultValue={user.unitId} disabled>
                    <Option value={user.unitId}>{user.unitName}</Option>
                  </Select>
                ) : (
                  <Selection
                    url={Endpoint.LIST_DONVI_KTCT}
                    form={form}
                    formKey="donViId"
                    notClear={true}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={24} md={7}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  if (prevValues.donViId !== currentValues.donViId) {
                    form.setFieldsValue({
                      doiId: undefined,
                    });
                  }
                  return prevValues.donViId !== currentValues.donViId;
                }}
              >
                {({ getFieldValue }) => {
                  if (getFieldValue("donViId")) {
                    return (
                      <Form.Item name="doiId" label="Đội">
                        <Selection
                          url={`${
                            Endpoint.LIST_DOI_KTCT
                          }?donviId=${getFieldValue("donViId")}`}
                          formKey="doiId"
                          form={form}
                        />
                      </Form.Item>
                    );
                  }
                  return (
                    <Form.Item name="doiId" label="Đội">
                      <Selection
                        url={`${Endpoint.LIST_DOI_KTCT}?donviId=${user.unitId}`}
                        formKey="doiId"
                        form={form}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={24} md={7}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  if (prevValues.donViId !== currentValues.donViId) {
                    form.setFieldsValue({
                      tramIds: undefined,
                    });
                  }
                  return prevValues.donViId !== currentValues.donViId;
                }}
              >
                {({ getFieldValue }) => {
                  if (getFieldValue("donViId")) {
                    return (
                      <Form.Item name="tramIds" label="Trạm">
                        <Selection
                          mode="multiple"
                          url={`${
                            Endpoint.LIST_TRAM_CT
                          }?donviId=${getFieldValue("donViId")}`}
                          formKey="tramIds"
                          form={form}
                        />
                      </Form.Item>
                    );
                  }
                  return (
                    <Form.Item name="tramIds" label="Trạm">
                      <Selection
                        mode="multiple"
                        url={`${Endpoint.LIST_TRAM_CT}?donviId=${user.unitId}`}
                        formKey="tramIds"
                        form={form}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={24} md={3}>
              <Form.Item name="kyGCS" label="Kỳ GCS">
                <Select placeholder={"Tất cả"} allowClear>
                  <Option value={ENUM_KY_GCS_KTCT.KY_1}>1</Option>
                  <Option value={ENUM_KY_GCS_KTCT.KY_2}>2</Option>
                  <Option value={ENUM_KY_GCS_KTCT.KY_3}>3</Option>
                  <Option value={ENUM_KY_GCS_KTCT.KY_4}>4</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={3}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Item name="isLocTheoThang" style={{ margin: 0 }}>
                <Checkbox
                  onChange={checkLocTheoThang}
                  // defaultChecked={filterConditions.biTraLai ? true : false}
                >
                  Lọc theo tháng
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24} md={4}>
              <Form.Item
                name="thangNamGCS"
                label={locTheoThang ? "Tháng năm GCS" : "Ngày GCS"}
              >
                <DatePickerComponent
                  defaultValue={
                    filterConditions.thangNamGCS
                      ? moment(filterConditions.thangNamGCS, FORMAT_ENGLISH)
                      : moment(moment(), FORMAT_MONTH)
                  }
                  format={FORMAT_ENGLISH}
                  form={form}
                  formKey={"thangNamGCS"}
                  picker={locTheoThang ? "month" : null}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={7}>
              <Form.Item name="trangThaiId" label="Trạng thái">
                <Select
                  placeholder="Tất cả"
                  defaultValue={firstTrangThai}
                  allowClear
                  onChange={onChangeTrangThai}
                >
                  {trangThai && trangThai.length > 0
                    ? trangThai.map((item, index) => {
                        if (item.value !== null) {
                          return (
                            <Option key={index} value={item.value.toString()}>
                              {item.name}
                            </Option>
                          );
                        }
                        return null;
                      })
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={6}>
              <Form.Item name="maSoGCS" label="Mã sổ GCS">
                <Input />
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={4}
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <Button className="mr-24px" onClick={clear}>
                Bỏ lọc
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                form="filter-form"
                className="mr-24px"
              >
                Tìm kiếm
              </Button>
            </Col>
          </Fragment>
        ) : (
          <Fragment>
            <Col span={24} md={7}>
              <Form.Item name="donViId" label="Đơn vị">
                {!user.isTongCongTy ? (
                  <Select defaultValue={user.unitId} disabled>
                    <Option value={user.unitId}>{user.unitName}</Option>
                  </Select>
                ) : (
                  <Selection
                    url={Endpoint.LIST_DONVI_KTCT}
                    form={form}
                    formKey="donViId"
                    notClear={true}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={24} md={7}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  if (prevValues.donViId !== currentValues.donViId) {
                    form.setFieldsValue({
                      doiId: undefined,
                    });
                  }
                  return prevValues.donViId !== currentValues.donViId;
                }}
              >
                {({ getFieldValue }) => {
                  if (getFieldValue("donViId")) {
                    return (
                      <Form.Item name="doiId" label="Đội">
                        <Selection
                          url={`${
                            Endpoint.LIST_DOI_KTCT
                          }?donviId=${getFieldValue("donViId")}`}
                          formKey="doiId"
                          form={form}
                        />
                      </Form.Item>
                    );
                  }
                  return (
                    <Form.Item name="doiId" label="Đội">
                      <Selection
                        url={`${Endpoint.LIST_DOI_KTCT}?donviId=${user.unitId}`}
                        formKey="doiId"
                        form={form}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={24} md={7}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  if (prevValues.donViId !== currentValues.donViId) {
                    form.setFieldsValue({
                      tramIds: undefined,
                    });
                  }
                  return prevValues.donViId !== currentValues.donViId;
                }}
              >
                {({ getFieldValue }) => {
                  if (getFieldValue("donViId")) {
                    return (
                      <Form.Item name="tramIds" label="Trạm">
                        <Selection
                          mode="multiple"
                          url={`${
                            Endpoint.LIST_TRAM_CT
                          }?donviId=${getFieldValue("donViId")}`}
                          formKey="tramIds"
                          form={form}
                        />
                      </Form.Item>
                    );
                  }
                  return (
                    <Form.Item name="tramIds" label="Trạm">
                      <Selection
                        mode="multiple"
                        url={`${Endpoint.LIST_TRAM_CT}?donviId=${user.unitId}`}
                        formKey="tramIds"
                        form={form}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={24} md={3}>
              <Form.Item name="kyGCS" label="Kỳ GCS">
                <Select placeholder={"Tất cả"} allowClear>
                  <Option value={ENUM_KY_GCS_KTCT.KY_1}>1</Option>
                  <Option value={ENUM_KY_GCS_KTCT.KY_2}>2</Option>
                  <Option value={ENUM_KY_GCS_KTCT.KY_3}>3</Option>
                  <Option value={ENUM_KY_GCS_KTCT.KY_4}>4</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={3}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Item name="isLocTheoThang" style={{ margin: 0 }}>
                <Checkbox
                  onChange={checkLocTheoThang}
                  // defaultChecked={filterConditions.biTraLai ? true : false}
                >
                  Lọc theo tháng
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24} md={4}>
              <Form.Item
                name="thangNamGCS"
                label={locTheoThang ? "Tháng năm GCS" : "Ngày GCS"}
              >
                <DatePickerComponent
                  defaultValue={
                    filterConditions.thangNamGCS
                      ? moment(filterConditions.thangNamGCS, FORMAT_ENGLISH)
                      : moment(moment(), FORMAT_MONTH)
                  }
                  format={FORMAT_ENGLISH}
                  form={form}
                  formKey={"thangNamGCS"}
                  picker={locTheoThang ? "month" : null}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={7}>
              <Form.Item name="trangThaiId" label="Trạng thái">
                <Select
                  placeholder="Tất cả"
                  defaultValue={firstTrangThai}
                  allowClear
                >
                  {trangThai && trangThai.length > 0
                    ? trangThai.map((item, index) => {
                        if (item.value !== null) {
                          return (
                            <Option key={index} value={item.value.toString()}>
                              {item.name}
                            </Option>
                          );
                        }
                        return null;
                      })
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={7}>
              <Form.Item name="maSoGCS" label="Mã sổ GCS">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={3}>
              <Form.Item name="hinhThuc" label="Hình thức">
                <Select placeholder="Tất cả" allowClear>
                  <Option value={ENUM_HINHTHUC_KTCT.MTB_STR}>MTB</Option>
                  <Option value={ENUM_HINHTHUC_KTCT.MDMS_STR}>MDMS</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={4}>
              <Form.Item name="loaiChenhLech" label="Loại chênh lệch">
                <Select
                  placeholder="Tất cả"
                  allowClear
                  onChange={onChangeChenhLech}
                >
                  <Option value={ENUM_CHENHLECH_KTCT.PT}>Chênh lệch %</Option>
                  <Option value={ENUM_CHENHLECH_KTCT.KWH}>
                    Chênh lệch Kwh
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={4}>
              <Form.Item name="loaiSoSanh" label="Loại so sánh">
                <Select placeholder="Tất cả" disabled={disableChenhLech}>
                  <Option value={ENUM_SOSANH_KTCT.BEHON_OR_BANG}>
                    {"Chênh lệch <="}
                  </Option>
                  <Option value={ENUM_SOSANH_KTCT.LONHON_OR_BANG}>
                    {"Chênh lệch >="}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={3}>
              <Form.Item name="chenhLech" label="Chênh lệch">
                <InputNumber min={0} disabled={disableChenhLech} />
              </Form.Item>
            </Col>
            <Col span={24} md={5}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  if (
                    prevValues.donViId !== currentValues.donViId ||
                    prevValues.doiId !== currentValues.doiId
                  ) {
                    form.setFieldsValue({
                      nhanVienKTCTId: undefined,
                    });
                  }
                  return prevValues.donViId !== currentValues.donViId;
                }}
              >
                {({ getFieldValue }) => {
                  if (user.isTongCongTy) {
                    const url =
                      form.getFieldValue("doiId") !== undefined
                        ? `${Endpoint.LIST_NV_KTCT}?donviId=${getFieldValue(
                            "donViId"
                          )}&doiId=${form.getFieldValue("doiId")}`
                        : `${Endpoint.LIST_NV_KTCT}?donviId=${getFieldValue(
                            "donViId"
                          )}`;
                    return (
                      <Form.Item name="nhanVienKTCTId" label="NV phúc tra">
                        <Selection
                          url={url}
                          formKey="nhanVienKTCTId"
                          form={form}
                        />
                      </Form.Item>
                    );
                  } else if (!user.isTongCongTy) {
                    const url =
                      form.getFieldValue("doiId") !== undefined
                        ? `${Endpoint.LIST_NV_KTCT}?donviId=${getFieldValue(
                            "donViId"
                          )}&doiId=${form.getFieldValue("doiId")}`
                        : `${Endpoint.LIST_NV_KTCT}?donviId=${getFieldValue(
                            "donViId"
                          )}`;
                    return (
                      <Form.Item name="nhanVienKTCTId" label="NV phúc tra">
                        <Selection
                          url={url}
                          formKey="nhanVienKTCTId"
                          form={form}
                        />
                      </Form.Item>
                    );
                  }
                }}
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={3}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Item name="biTraLai" style={{ margin: 0 }}>
                <Checkbox
                  onChange={checkTraLai}
                  defaultChecked={filterConditions.biTraLai ? true : false}
                >
                  Bị trả lại
                </Checkbox>
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={5}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Button className="mr-24px" onClick={clear}>
                Bỏ lọc
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                form="filter-form"
                className="mr-24px"
              >
                Tìm kiếm
              </Button>
              {urlXuatExcel ? (
                <CheckPermission permissionCode={permissionExcel}>
                  <Button
                    type="primary"
                    onClick={() =>
                      handleExportExcel(
                        `${urlXuatExcel}?${buildQueryString(
                          parseParams(filterConditionsTraCuu)
                        )}`
                      )
                    }
                  >
                    Xuất Excel
                  </Button>
                </CheckPermission>
              ) : null}
            </Col>
          </Fragment>
        )}
      </Row>
    </FormComponent>
  );
}
