import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams, removeAccents } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import { useCallback, useEffect, useState } from "react";
import { alertMessage422 } from "../service";

export const SelectionLoaiYeuCau = (props) => {
  const {
    url,
    placeholder = "Chọn loại yêu cầu ",
    form,
    formKey,
    disabled,
    mode,
    setValue,
    handleDeselect,
    notClear,
    defaultValue,
  } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(typeof url === "string" ? [] : url);

  const getData = useCallback(() => {
    authGetData({
      url,
      setLoading,
      onSuccess: (res) => {
        console.log(res.data);
        if (res.length > 0) setData(res);
        else setData(res.data);
      },
    });
  }, [url]);

  useEffect(() => {
    if (url && typeof url === "string") {
      getData();
    }
  }, [getData, url]);
  const handleChange = useCallback(
    (value) => {
      if (form) {
        form.setFieldsValue({
          [formKey]: value,
        });
      }
      if (setValue) {
        setValue(value);
      }
    },
    [form, formKey, setValue]
  );
  return (
    <Select
      defaultValue={defaultValue}
      value={form && form.getFieldValue(formKey)}
      placeholder={placeholder}
      showSearch
      mode={mode}
      allowClear={notClear ? false : true}
      loading={loading}
      onChange={handleChange}
      onDeselect={handleDeselect}
      disabled={disabled}
      style={{ width: "100%" }}
      filterOption={(input, option) => {
        if (option && option.children) {
          return (
            removeAccents(option.children)
              .toLowerCase()
              .indexOf(removeAccents(input).toLowerCase()) >= 0
          );
        }
      }}
    >
      {console.log(data)}
      {data && data.length
        ? data.map((item, idx) => (
            <Option
              key={idx}
              value={item.MA_YCAU ? item.MA_YCAU.toString() : item.MA_YCAU}
            >
              {item.TEN_YCAU}
            </Option>
          ))
        : undefined}
    </Select>
  );
};

export const selectBctdctdvkhCMIS3 = [
  {
    label:
      "1. Về việc tổng hợp số lượng 12 dịch vụ điện thực hiện theo phương thức điện tử",
    value: Endpoint.BCCTDVKH_THSL_12_DICH_VU_DIEN_LUC_THEO_PT_DIEN_TU,
  },
  {
    label:
      "2. Về việc giải quyết quá hạn dịch vụ điện trong quá trình thực hiện HĐMBĐ",
    value: Endpoint.BCCTDVKH_BC_GIAI_QUYET_QUA_HAN_CAC_DICH_VU_DIEN_HBMBĐ,
  },
  {
    label: "3. Về việc tổng hợp số lượng khách hàng cài App, Zalo",
    value: Endpoint.BCCTDVKH_TH_SO_KHACH_HANG_CAI_APP_ZALO,
  },
  {
    label: "4. Báo cáo theo dõi công tác dịch vụ khách hàng trên CMIS3",
    value: Endpoint.BCCTDVKH_BC_THEO_DOI_DVKH_CMIS3,
  },
  {
    label: "5. Tổng hợp tất cả dịch vụ điện tiếp nhận Web EVN Hà Nội",
    value: Endpoint.BCWEHN_TH_TAT_CA_DV_DIEN_TIEP_NHAN,
  },
];

export const selectBckdmoi = [
  {
    label:
      "1. Báo cáo về việc khách hàng lắp đặt và ký hợp đồng điện mặt trời mái nhà",
    value: Endpoint.BCCTDVKH_BC_KH_LAP_DAT_KY_HD_DIEN_MTAM,
  },
  {
    label:
      "2. Báo cáo về việc thanh toán tiền điện mặt trời mái nhà cho khách hàng",
    value: Endpoint.BCCTDVKH_BC_THANH_TOAN_TIEN_MTAM_CHO_KH,
  },
  // {
  //   label: "3. Báo cáo danh sách khách hàng điều chỉnh hóa đơn",
  //   value: Endpoint.BCCTDVKH_BC_DSKH_DIEU_CHINH_HOA_DON,
  // },
  {
    label: "3. Báo cáo tổng hợp khách hàng điều chỉnh hóa đơn",
    value: Endpoint.BCCTDVKH_BC_THKH_DIEU_CHINH_HOA_DON,
  },
];

export const selectBcdichvucong = [
  {
    label: "1. Báo cáo Tỷ lệ thanh toán dịch vụ điện không dùng tiền mặt",
    value: Endpoint.BCCTDVKH_TY_LE_TT_DVD_KHONG_DUNG_TIEN_MAT,
  },
  {
    label:
      "2. Báo cáo Tỷ lệ thanh toán dịch vụ điện không dùng tiền mặt chi tiết",
    value: Endpoint.BCCTDVKH_TY_LE_TT_DVD_KHONG_DUNG_TIEN_MAT_CT,
  },
];
export const selectBcdichvudientu = [
  {
    label: "1. M1-Báo cáo tiếp nhận đăng ký dịch vụ điện điện tử",
    value: Endpoint.BCCTDVKH_BC_TIEP_NHAN_DANG_KY_DICH_VU_DIEN_TU,
  },
  {
    label:
      "2. M2-Báo cáo giải quyết quá hạn các dịch vụ điện trong quá trình thực hiện HĐMBĐ",
    value: Endpoint.BCCTDVKH_BC_GIAI_QUYET_QUA_HAN_CAC_DICH_VU_DIEN,
  },
  {
    label: "3. M3-Báo cáo yêu cầu thực hiện theo phương thức điện tử",
    value: Endpoint.BCCTDVKH_BC_YEU_CAU_THUC_HIEN_THEO_PHUONG_THUC_DIEN_TU,
  },
  {
    label: "4. Báo cáo danh sách yêu cầu có số điện thoại nhiều hồ sơ",
    value: Endpoint.BCK_BC_DANH_SACH_YEU_CAU_SDT_NHIEU_HO_SO,
  },
  {
    label: "5. Báo cáo số lượng hợp đồng mua bán điện sinh hoạt ký điện tử",
    value: Endpoint.BCK_SL_HOP_DONG_MUA_BAN_DIEN_SHKDT,
  },
  {
    label: "6. Báo cáo chi tiết hợp đồng mua bán điện sinh hoạt ký điện tử",
    value: Endpoint.BCK_CHI_TIET_HDMBDSH_KY_DIEN_TU,
  },
];

export const selectHeSinhThai = [
  {
    label: "1. Báo cáo tổng hợp KH sử dụng hệ sinh thái",
    value: Endpoint.BCHST_BC_TONG_HOP,
  },
  {
    label: "2. Tổng hợp số lượng KH đăng ký nhận thông báo qua hệ sinh thái",
    value: Endpoint.BCHST_TONG_HOP_SLKH_DANG_KY,
  },
  {
    label: "3. Báo cáo chi tiết KH sử dụng hệ sinh thái",
    value: Endpoint.BCHST_CHI_TIET_HST_KH,
  },
];

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LoaiYeuCau: data.LoaiYeuCau,
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LoaiYeuCau: data.LoaiYeuCau,
    })
  );
  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};

export const handleTongHop = (data, setLoading) => {
  console.log(data);
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
    })
  );
  authGetData({
    url: `${Endpoint.BCHST_TH_KHACH_HANG_SU_DUNG_HST}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
