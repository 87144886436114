import { useEffect } from "react";
import { Form, Col, Input, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
// import Selection from "@components/Select";
export default function PanelsThongTinNganHang(props) {
  const { detail, form, dataNganHang } = props;
  const { t } = useTranslation();
  useEffect(() => {
    form.setFieldsValue({
      nganHang: detail.nganHangId,
      taiKhoanNganHang: detail.taiKhoan,
      maSoThue: detail.maSoThue,
    });
  }, [form, detail]);
  return (
    <Row gutter={24}>
      <Col span={24} md={24} lg={12}>
        <Form.Item label={t("cap_nhat_ho_so.ten_ngan_hang")} name="nganHang">
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {dataNganHang && dataNganHang.length
              ? dataNganHang.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.name}
                  </Select.Option>
                ))
              : null}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24} md={12} lg={6}>
        <Form.Item
          label={t("cap_nhat_ho_so.so_tai_khoan_ngan_hang")}
          name="taiKhoanNganHang"
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24} md={12} lg={6}>
        <Form.Item label={t("cap_nhat_ho_so.ma_so_thue")} name="maSoThue">
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
}
