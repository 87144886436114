import React from "react";
import { Row, Col, Collapse, Table, Button } from "antd";

import { CaretRightOutlined } from "@ant-design/icons";
import LoadingComponent from "@components/Loading";
const { Panel } = Collapse;

export default function Index(props) {
  const { genExtra, list, columns, t, dispatch, closeModal, loading, detail } =
    props;
  return (
    <LoadingComponent loading={loading}>
      <Collapse
        defaultActiveKey={["1", "2"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            style={{ fontSize: 18 }}
            rotate={isActive ? 90 : 0}
          />
        )}
      >
        {/* thông tin hồ sơ */}

        <Panel
          header={t("quyet_toan.lap_quyet_toan_cong_ty.thong_tin_chung")}
          collapsible={"header"}
          key="1"
        >
          <Row gutter={24}>
            <Col span={24} xs={12} md={12} lg={8}>
              <h6 className="fs-12px c-t-label">
                {t("quyet_toan.lap_quyet_toan_cong_ty.ten_khach_hang")}
              </h6>
              <h5 className="fs-14px font-bold">{detail.tenKhachHang}</h5>
            </Col>
            <Col span={24} xs={12} md={12} lg={8}>
              <h6 className="fs-12px c-t-label">
                {" "}
                {t("quyet_toan.lap_quyet_toan_cong_ty.ma_yckn")}
              </h6>
              <h5 className="fs-14px font-bold">{detail.maYeuCauKhieuNai}</h5>
            </Col>
            <Col span={24} xs={12} md={12} lg={8}>
              <h6 className="fs-12px c-t-label">
                {t("quyet_toan.lap_quyet_toan_cong_ty.dien_thoai")}
              </h6>
              <h5 className="fs-14px font-bold">{detail.dienThoai}</h5>
            </Col>

            <Col span={24} xs={12} md={12} lg={8}>
              <h6 className="fs-12px c-t-label">
                {t("quyet_toan.lap_quyet_toan_cong_ty.dia_chi_giao_dich")}
              </h6>
              <h5 className="fs-14px font-bold">{detail.diaChiGiaoDich}</h5>
            </Col>
            <Col span={24} xs={12} md={12} lg={8}>
              <h6 className="fs-12px c-t-label">
                {t("quyet_toan.lap_quyet_toan_cong_ty.muc_dich")}
              </h6>
              <h5 className="fs-14px font-bold">{detail.mucDichSinhHoat}</h5>
            </Col>
            <Col span={24} xs={12} md={12} lg={8}>
              <h6 className="fs-12px c-t-label">
                {t("quyet_toan.lap_quyet_toan_cong_ty.so_pha")}
              </h6>
              <h5 className="fs-14px font-bold">{detail.soPha}</h5>
            </Col>

            <Col span={24} xs={12} md={12} lg={8}>
              <h6 className="fs-12px c-t-label">
                {t("quyet_toan.lap_quyet_toan_cong_ty.dia_chi_su_dung_dien")}
              </h6>
              <h5 className="fs-14px font-bold">{detail.diaChiSuDungDien}</h5>
            </Col>
            <Col span={24} xs={12} md={12} lg={8}>
              <h6 className="fs-12px c-t-label">
                {t("quyet_toan.lap_quyet_toan_cong_ty.hinh_thuc_lap_dat")}
              </h6>
              <h5 className="fs-14px font-bold">{detail.hinhThucLapDat}</h5>
            </Col>
            <Col span={24} xs={12} md={12} lg={8}>
              <h6 className="fs-12px c-t-label">Loại cáp</h6>
              <h5 className="fs-14px font-bold">{detail.loaiCap}</h5>
            </Col>
          </Row>
        </Panel>

        {/* chi tiết các bước thực hiện */}

        <Panel
          header={
            <div style={{ position: "relative" }}>
              {t(
                "quyet_toan.lap_quyet_toan_cong_ty.danh_sach_vat_tu_quyet_toan_khach_hang"
              )}
            </div>
          }
          collapsible={"header"}
          extra={genExtra}
          key="2"
        >
          <Row gutter={24}>
            {/* <Col span={2}>
              <h6 className="fs-12px c-t-label color_module_m11">
                {t("quyet_toan.lap_quyet_toan_cong_ty.canh_bao")}
              </h6>
            </Col>
            <Col span={22}>
              <h5 className="fs-14px font-bold">
                <Checkbox>
                  {t(
                    "quyet_toan.lap_quyet_toan_cong_ty.don_gia_lay_tu_phuong_an_cap_dien"
                  )}
                </Checkbox>
              </h5>
            </Col> */}
            <Col span={24}>
              <Table
                scroll={{ x: 1500 }}
                dataSource={list}
                columns={columns}
                pagination={false}
              ></Table>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <div className="ant-modal-footer text-center">
        <Button type="default" onClick={() => dispatch(closeModal())}>
          {t("button.dong_lai")}
        </Button>
      </div>
    </LoadingComponent>
  );
}
