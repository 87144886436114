import styled from "styled-components";

export const Container = styled.div`
  padding: 0 36px;
`;
export const ContainerTable = styled.div`
  .table-component-panel .ant-collapse-content.ant-collapse-content-active {
    min-height: 250px;
  }
`;
