import { Fragment } from "react";
import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { customColumn } from "@utils/function";
import { ListFilter } from "./list-bo-loc";
import ListFilterDongBo from "../giao-dich-vien/tiep-nhan-phan-cong/list-bo-loc";
import ListFilterLapPhuongAn from "../nhan-vien-khao-sat/lap-phuong-an/list-bo-loc";
import ListFilterDongBoThoiGian from "../tien-ich/dong-bo-thoi-gian/list-bo-loc";
import { Container } from "./css-styled";
export default function DanhSachHoSo(props) {
  const {
    listFilter,
    handleSearch,
    clearFilter,
    filterConditions,
    funcID,
    t,
    renderExtraAction,
    dataHoSo,
    addColumns,
    totalCount,
    loading,
    onChangePagination,
    onRow,
    rowSelection,
    rowKey,
    checkCanhBao,
    isCanhBao,
    urlXuatExcel,
    permissionExcel,
    rowId,
  } = props;
  return (
    <Fragment>
      <Filter>
        {listFilter === 1 ? (
          <ListFilterDongBo
            handleSearch={handleSearch}
            clearFilter={clearFilter}
            filterConditions={filterConditions}
            funcID={funcID}
            checkCanhBao={checkCanhBao}
          />
        ) : listFilter === 2 ? (
          <ListFilterLapPhuongAn
            handleSearch={handleSearch}
            clearFilter={clearFilter}
            filterConditions={filterConditions}
            funcID={funcID}
            checkCanhBao={checkCanhBao}
          />
        ) : listFilter === 3 ? (
          <ListFilterDongBoThoiGian
            handleSearch={handleSearch}
            clearFilter={clearFilter}
            filterConditions={filterConditions}
            checkCanhBao={checkCanhBao}
          />
        ) : (
          <ListFilter
            handleSearch={handleSearch}
            clearFilter={clearFilter}
            filterConditions={filterConditions}
            funcID={funcID}
            checkCanhBao={checkCanhBao}
            isCanhBao={isCanhBao}
            urlXuatExcel={urlXuatExcel}
            permissionExcel={permissionExcel}
          />
        )}
      </Filter>
      <Container>
        <TableComponent
          header={t("ap_gia_ho_so.ho_so")}
          renderExtraAction={renderExtraAction}
          dataSource={dataHoSo}
          columns={customColumn(addColumns(), filterConditions)}
          totalData={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
          onRow={onRow ? onRow : null}
          scrollX={2500}
          scrollY={520}
          rowSelection={rowSelection ? rowSelection : null}
          rowKey={rowKey}
          rowId={rowId}
        />
      </Container>
    </Fragment>
  );
}
