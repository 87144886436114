import CheckPermission from "@components/CheckPermission";
import TableComponent from "@components/TableComponent";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useCustomDebounce } from "@utils/request";
import { Button, Spin } from "antd";
import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { ListFilter } from "./list-bo-loc";
import ModalThongTinTram from "./modal-danh-muc-tram";
import { handleListDataDanhMucTram, handleRemoveTram } from "./service";

export default function DanhMucTram() {
  const location = useLocation();
  const [visibleTram, setVisibleTram] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataTram, setDataTram] = useState([]);
  const [detailTram, setDetailTram] = useState({});
  const [, setSearchParams] = useSearchParams();

  //pagination table
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });
  const debounceSearch = useCustomDebounce(filterConditions);
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(debounceSearch));
  }, [setSearchParams, debounceSearch]);

  //fetch dữ liệu
  const getListDataDanhMucTram = useCallback(() => {
    handleListDataDanhMucTram(setLoading, location, setDataTram);
  }, [location]);

  useEffect(() => {
    getListDataDanhMucTram();
  }, [getListDataDanhMucTram]);

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDetailTram(row);
      else setDetailTram({});
      setVisibleTram(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleTram]
  );

  const handleCloseModal = () => {
    setVisibleTram(false);
    setLoading(false);
  };
  //xóa dữ liệu
  const handleDeleteTram = useCallback(
    (ids) => {
      handleRemoveTram(ids, setLoading, getListDataDanhMucTram);
    },
    [getListDataDanhMucTram]
  );

  const showModal = useMemo(
    () =>
      visibleTram && (
        <ModalThongTinTram
          detailDataTable={detailTram}
          visible={visibleTram}
          closeModal={handleCloseModal}
          getListDataDanhMucTram={getListDataDanhMucTram}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleTram, dataTram, getListDataDanhMucTram]
  );

  //permission button them moi
  const genExtra = () => (
    <div>
      <Button
        size="default"
        type="primary"
        className="mr-5px"
        onClick={() => handleOpenModal()}
      >
        Tạo mới
      </Button>
    </div>
  );

  //tìm kiếm dữ liệu
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      ...values,
    }));
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 100,
    },
    {
      title: "Mã trạm",
      dataIndex: "maTram",
      key: "maTram",
      width: 130,
      render: (text) => (
        <div className="table-text-left">
          <span> {text}</span>
        </div>
      ),
    },
    {
      title: "Thông tin mô tả",
      dataIndex: "moTa",
      key: "moTa",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: 250,
    },
    {
      title: "Mã cấp đa",
      dataIndex: "maCapDienAp",
      key: "maCapDienAp",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: 150,
    },
    {
      title: "Tính chất trạm",
      dataIndex: "tinhChat",
      key: "tinhChat",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: 250,
    },
    {
      title: "Tổng số điểm đo",
      dataIndex: "tongSoDiemDo",
      key: "tongSoDiemDo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: 200,
    },
    {
      title: "",
      dataIndex: "tacVu",
      key: "tacVu",
      width: 100,
      render: (text, record) => (
        <div className="action-table-column">
          <CheckPermission>
            <Button
              type="primary"
              style={{ marginRight: 8 }}
              onClick={() => handleOpenModal(record)}
            >
              Sửa
            </Button>
          </CheckPermission>
          <CheckPermission>
            <Button type="danger" onClick={() => handleDeleteTram(record.id)}>
              Xóa
            </Button>
          </CheckPermission>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <div style={{ padding: "10px 10px 0 10px" }}>
        <ListFilter
          filterConditions={filterConditions}
          tooltip="Tìm kiếm theo Mã trạm, Thông tin mô tả"
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          setFilterConditions={setFilterConditions}
          setLoading={setLoading}
        />
      </div>
      <Container>
        <Spin spinning={loading}>
          <TableComponent
            header="Danh sách trạm"
            columns={customColumn(columns, filterConditions)}
            dataSource={dataTram}
            renderExtraAction={() => genExtra()}
            scrollY={"62vh"}
            ListFilter={ListFilter}
            pagination={filterConditions}
            onChangePagination={onChangePagination}
          />
        </Spin>
      </Container>
      {showModal}
    </Fragment>
  );
}

const Container = styled.div`
  padding: 0px 10px;
`;
