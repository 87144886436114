import { BASE_API_URL_GHI_CHI_SO } from "@utils/constants";
export const APIBangKeGCS = {
  LIST_MA_SO: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/get-maso`,
  LIST_MA_SO_BANG_KE_NO_TO_DOI: `${BASE_API_URL_GHI_CHI_SO}/baocao/get-maso`,
  XUAT_PDF_BANG_KE_KSCS: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/pdf-kiem-soat-chi-so`,
  XUAT_EXCEL_BANG_KE_KSCS: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/kscs`,
  LIST_BIEN_BAN_KSCS: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke`,
  LIST_BIEN_BAN_SLBT: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/cong-to-slbt-paging`,
  XUAT_PDF_ANH_CTSLBT: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/bang-ke-cong-to-slbt-pdf`,
  XUAT_EXCEL_CONGTO_SLBT: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/xuat-excel-cong-to-slbt`,
  XUAT_PDF_CONGTO_SLBT: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/pdf-cong-to-slbt`,
  BANG_KE_KH_PHAI_GHI_PMAX: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/pmax`,
  XUAT_PDF_PMAX: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/pdf-pmax`,
  XUAT_EXCEL_PMAX: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/export-pmax`,
  PHUC_TRA_PAGING: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/phuc-tra-paging`,
  XUAT_PDF_PHUC_TRA_CHI_SO: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/pdf-phuc-tra-chi-so`,
  XUAT_EXCEL_PHUC_TRA_CHI_SO: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/excel-phuc-tra-chi-so`,
  XUAT_PDF_LICH_SU_PHUC_TRA: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/pdf-lich-su-phuc-tra`,
  XUAT_EXCEL_LICH_SU_PHUC_TRA: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/excel-lich-su-phuc-tra`,
  LIST_PHIEU_XAC_MINH: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/xac-minh-cong-to`,
  XUAT_PDF_PHIEU_XAC_MINH: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/pdf-xac-minh-cong-to`,
  XUAT_PDF_CUSTOMER_NO_CAP: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/pdf-customer-no-cap`,
  XUAT_EXCEL_CUSTOMER_NO_CAP: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/export-customer-no-cap`,
  LIST_BIEN_BAN_CUSTOMER_NO_CAP: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/customer-no-cap`,
  XUAT_PDF_TINH_TRANG_CTO: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/pdf-tinh-trang-cong-to`,
  XUAT_PDF_KHONG_DOI_SOAT: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/pdf-khong-doi-soat`,
  XUAT_PDF_DA_DOI_SOAT: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/pdf-doi-soat`,
  XUAT_PDF_DOI_SOAT_DAT: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/pdf-doi-soat-dat`,
  XUAT_PDF_DOI_SOAT_KHONG_DAT: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/pdf-doi-soat-khong-dat`,
  XUAT_PDF_CONG_TO_TT_BAT_THUONG: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/pdf-cong-to-tinh-trang-bat-thuong`,
  XUAT_PDF_THOI_GIAN_GCS: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/pdf-bang-ke-thoi-gian-chi-so`,
  THOI_GIAN_THUC_HIEN_GCS: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/thoi-gian-thuc-hien-gcs`,
  BANG_KE_CHI_SO: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/pdf-chi-so-bang-ke`,
  XEM_CHI_SO_BANG_KE: `${BASE_API_URL_GHI_CHI_SO}/gcsbangke/xem-chi-so-bang-ke`,
};
