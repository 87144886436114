import { handleChuyenApGia } from "@modules/cap-dien/ap-gia/duyet-ho-so/services";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ChuyenApGiaModal from "./ui-modal/chuyen-ap-gia";

function Index({ visible, handleCloseModal, setFetchDataTable, detail }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = () => {
    handleChuyenApGia(
      t,
      setLoading,
      handleCloseModal,
      setFetchDataTable,
      detail.id
    );
  };

  return (
    <ChuyenApGiaModal
      t={t}
      loading={loading}
      visible={visible}
      handleCloseModal={handleCloseModal}
      handleSubmit={handleSubmit}
    />
  );
}

export default Index;
