import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Checkbox, Spin, Collapse } from "antd";

import styled from "styled-components";
import lodash from "lodash";
import NoRecordFound from "@components/NoRecordFound";
import { getPermissionList } from "./services";
import { CaretRightOutlined } from "@ant-design/icons";
const { Panel } = Collapse;
export default function PermissionList(props) {
  const { permissionSelected, setPermissionSelected, isView } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataGroup, setDataGroup] = useState([]);
  useEffect(() => {
    getPermissionList(setLoading, setData, setDataGroup);
  }, []);

  const getAllChildrenItems = useCallback((item) => {
    let arr = [];
    if (item && item.childrenMenus && item.childrenMenus.length) {
      item.childrenMenus.map((child) => {
        if (child.permissions && child.permissions.length) {
          child.permissions.map((permission) => {
            return (arr = arr.concat(permission.code));
          });
        }
        if (child.childrenMenus && child.childrenMenus) {
          return (arr = arr.concat(getAllChildrenItems(child), [child.code]));
        }
        return null;
      });
    }
    if (item.permissions && item.permissions.length) {
      item.permissions.map((permission) => {
        return (arr = arr.concat(permission.code));
      });
    }
    return arr;
  }, []);

  const findParentPermission = useCallback(
    (permission, dataSource, currentValues) => {
      dataSource.map((item) => {
        if (item.id === permission.parentId) {
          const childrenItems = [...new Set(getAllChildrenItems(item))];
          const allChildrenItemsUnChecked = lodash.difference(
            childrenItems,
            currentValues
          );
          if (!allChildrenItemsUnChecked.length) {
            currentValues.push(item.code);
          } else {
            const findPermissionIndex = currentValues.findIndex(
              (value) => value === item.code
            );
            if (findPermissionIndex !== -1) {
              currentValues.splice(findPermissionIndex, 1);
            }
          }
          setPermissionSelected([...new Set(currentValues)]);
          if (item.parentId) {
            findParentPermission(item, data, permissionSelected);
          }
        } else {
          if (item.childrenMenus && item.childrenMenus.length) {
            findParentPermission(
              permission,
              item.childrenMenus,
              permissionSelected
            );
          }
        }
        return null;
      });
    },
    [data, getAllChildrenItems, permissionSelected, setPermissionSelected]
  );
  const onChange = useCallback(
    (event, item, parentItem) => {
      const { checked } = event.target;
      let currentValues = [...permissionSelected];
      if (checked) {
        const childrenItems = [...new Set(getAllChildrenItems(item))];
        currentValues = currentValues.concat([item.code], childrenItems);
        const findPermissionIndex = permissionSelected.findIndex(
          (value) => value === item.code
        );
        if (findPermissionIndex !== -1) {
          currentValues.splice(findPermissionIndex, 1);
        }
      } else {
        const findIndexCurrentPermissionUncheck = permissionSelected.findIndex(
          (value) => value === item.code
        );
        if (findIndexCurrentPermissionUncheck !== -1) {
          currentValues.splice(findIndexCurrentPermissionUncheck, 1);
        }
        const childrenItems = [...new Set(getAllChildrenItems(item))];
        childrenItems.map(
          (permission) =>
            (currentValues = currentValues.filter(
              (item) => item !== permission
            ))
        );
      }
      if (parentItem) {
        const childrenItems = [...new Set(getAllChildrenItems(parentItem))];
        const allChildrenItemsUnChecked = lodash.difference(
          childrenItems,
          currentValues
        );
        if (!allChildrenItemsUnChecked.length) {
          currentValues.push(parentItem.code);
        } else {
          const findPermissionIndex = currentValues.findIndex(
            (value) => value === parentItem.code
          );
          if (findPermissionIndex !== -1) {
            currentValues.splice(findPermissionIndex, 1);
          }
        }
        findParentPermission(parentItem, data, currentValues);
      }
      setPermissionSelected([...new Set(currentValues)]);
    },
    [
      permissionSelected,
      setPermissionSelected,
      getAllChildrenItems,
      findParentPermission,
      data,
    ]
  );
  const handleBoxChecked = useCallback(
    (item) => {
      return permissionSelected.includes(item.code);
    },
    [permissionSelected]
  );
  const renderChildrenPermission = useCallback(
    (item) => {
      if (item && item.childrenMenus && item.childrenMenus.length) {
        return item.childrenMenus.map((childMenu, childMenuIdx) => {
          return (
            <div key={childMenuIdx} className="ml-20px">
              <Row gutter={6}>
                <Col className="mab15" span={24}>
                  <Checkbox
                    onChange={(event) => onChange(event, childMenu, item)}
                    checked={handleBoxChecked(childMenu)}
                    disabled={isView}
                  >
                    {childMenu.name}
                  </Checkbox>
                </Col>
              </Row>
              {renderChildrenPermission(childMenu)}
            </div>
          );
        });
      }
      if (item.permissions && item.permissions.length) {
        return (
          <div className="ml-20px">
            <Row gutter={6}>
              {item.permissions.map((permission, perIdx) => {
                return (
                  <Col className="mab15" span={4} key={perIdx}>
                    <Checkbox
                      onChange={(event) => onChange(event, permission, item)}
                      checked={handleBoxChecked(permission)}
                      disabled={isView}
                    >
                      {permission.name}
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      }
    },
    [onChange, handleBoxChecked]
  );

  const handleCheckAllGroup = useCallback(
    (event, moduleCode) => {
      const { checked } = event.target;
      const findModuleItems = data.filter((item) =>
        item.code.includes(moduleCode)
      );
      if (checked) {
        let itemGroupChecked = [];
        if (findModuleItems.length) {
          findModuleItems.map((moduleItem) => {
            const arr = getAllChildrenItems(moduleItem);
            return (itemGroupChecked = itemGroupChecked.concat(
              [moduleItem.code],
              arr
            ));
          });
          setPermissionSelected([
            ...new Set([...permissionSelected, ...itemGroupChecked]),
          ]);
        }
      } else {
        if (findModuleItems.length) {
          let itemGroupUnChecked = [];
          findModuleItems.map((moduleItem) => {
            const arr = getAllChildrenItems(moduleItem);
            return (itemGroupUnChecked = itemGroupUnChecked.concat(
              [moduleItem.code],
              arr
            ));
          });
          const newArr = permissionSelected.filter(
            (element) => !itemGroupUnChecked.includes(element)
          );
          setPermissionSelected([...new Set(newArr)]);
        }
      }
    },
    [data, setPermissionSelected, permissionSelected, getAllChildrenItems]
  );

  const handleCheckAllGroupStatus = useCallback(
    (moduleCode) => {
      const findModuleItems = data.filter((item) =>
        item.code.includes(moduleCode)
      );
      if (findModuleItems.length) {
        let itemGroup = [];
        findModuleItems.map((moduleItem) => {
          const arr = getAllChildrenItems(moduleItem);
          return (itemGroup = itemGroup.concat([moduleItem.code], arr));
        });
        const groupCheckedAll = permissionSelected.filter(function (val) {
          return [...new Set(itemGroup)].indexOf(val) !== -1;
        });
        if (groupCheckedAll.length === [...new Set(itemGroup)].length) {
          return true;
        }
        return false;
      }
    },
    [data, getAllChildrenItems, permissionSelected]
  );
  const genExtra = useCallback(
    (group) => (
      <div>
        <Checkbox
          onChange={(event) => handleCheckAllGroup(event, group.moduleCode)}
          checked={handleCheckAllGroupStatus(group.moduleCode)}
          disabled={isView}
        >
          Tất cả
        </Checkbox>
      </div>
    ),
    [handleCheckAllGroup, handleCheckAllGroupStatus]
  );

  const renderContent = useCallback(() => {
    if (loading) {
      return <div className="text-center">Đang tải dữ liệu</div>;
    } else {
      if (dataGroup.length) {
        return (
          <div>
            {dataGroup.map((group, idx) => {
              return (
                <Collapse
                  key={idx}
                  defaultActiveKey={["1"]}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined
                      style={{ fontSize: 18 }}
                      rotate={isActive ? 90 : 0}
                    />
                  )}
                >
                  <Panel
                    header={group.moduleName}
                    extra={genExtra(group)}
                    key="1"
                    collapsible={"header"}
                  >
                    {group.children.map((item, itemIdx) => (
                      <div key={itemIdx}>
                        <Row gutter={6}>
                          <Col className="mab15" span={24}>
                            <Checkbox
                              onChange={(event) => onChange(event, item)}
                              checked={handleBoxChecked(item)}
                              disabled={isView}
                            >
                              <span className="font-bold">{item.name}</span>
                            </Checkbox>
                          </Col>
                        </Row>
                        {renderChildrenPermission(item)}
                      </div>
                    ))}
                  </Panel>
                </Collapse>
              );
            })}
          </div>
        );
      }
      return <NoRecordFound />;
    }
  }, [
    loading,
    dataGroup,
    genExtra,
    handleBoxChecked,
    onChange,
    renderChildrenPermission,
  ]);
  const handleCheckAll = useCallback(
    (event) => {
      const { checked } = event.target;
      if (checked) {
        let itemGroupChecked = [];
        if (data.length) {
          data.map((moduleItem) => {
            const arr = getAllChildrenItems(moduleItem);
            return (itemGroupChecked = itemGroupChecked.concat(
              [moduleItem.code],
              arr
            ));
          });
          setPermissionSelected([
            ...new Set([...permissionSelected, ...itemGroupChecked]),
          ]);
        }
      } else {
        setPermissionSelected([]);
      }
    },
    [data, getAllChildrenItems, permissionSelected, setPermissionSelected]
  );

  const handleCheckAllStatus = useCallback(() => {
    if (data.length) {
      let itemGroupChecked = [];
      data.map((moduleItem) => {
        const arr = getAllChildrenItems(moduleItem);
        return (itemGroupChecked = itemGroupChecked.concat(
          [moduleItem.code],
          arr
        ));
      });
      if ([...new Set(itemGroupChecked)].length === permissionSelected.length) {
        return true;
      }
      return false;
    }
    return false;
  }, [permissionSelected, data, getAllChildrenItems]);
  return (
    <Spin spinning={loading}>
      <PemissionContainer>
        <Checkbox
          onChange={(event) => handleCheckAll(event)}
          checked={handleCheckAllStatus()}
          disabled={isView}
        >
          Chọn tất cả
        </Checkbox>

        {renderContent()}
      </PemissionContainer>
    </Spin>
  );
}

const PemissionContainer = styled.div`
  padding: 20px;
  .ant-checkbox-disabled+span{
    color: unset
  }
`;
