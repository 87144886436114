import { APIBaoCao } from "../../api-url/kiem-tra-thiet-bi/bao-cao";
import { APICongTo } from "../../api-url/kiem-tra-thiet-bi/cong-to";
import { APIPhanCong } from "../../api-url/kiem-tra-thiet-bi/phan-cong";
import { APIBangKe } from "../../api-url/kiem-tra-thiet-bi/bang-ke";
import { APICommon } from "../../api-url/kiem-tra-thiet-bi/common";

export const KTTHIETBI_Endpoint = {
  // common

  LIST_TINHTRANG_BK: APICommon.LIST_TINHTRANG_BK,
  LIST_TINHTRANG_CT: APICommon.LIST_TINHTRANG_CT,
  LIST_TRAM_CT: APICommon.LIST_TRAM_CT,
  LIST_LOAI_BANGKE: APICommon.LIST_LOAI_BANGKE,
  LIST_NGUOI_THUC_HIEN: APICommon.LIST_NGUOI_THUC_HIEN,
  LIST_DONVI_KTCT: APICommon.LIST_DONVI_KTCT,
  LIST_DOI_KTCT: APICommon.LIST_DOI_KTCT,
  XUAT_EXCEL_DINHKY: APICommon.XUAT_EXCEL_DINHKY,
  XUAT_EXCEL_PHUCTRA: APICommon.XUAT_EXCEL_PHUCTRA,
  XUAT_EXCEL_BANGKE: APICommon.XUAT_EXCEL_BANGKE,

  // api báo cáo

  GET_BC_TONG_CONG_TO: APIBaoCao.BC_TONG_CONG_TO,
  BC_CT_BATTHUONG: APIBaoCao.BC_CT_BATTHUONG,
  BC_CT_KHONGDAT: APIBaoCao.BC_CT_KHONGDAT,
  BC_KTCT_TRONGTHANG: APIBaoCao.BC_KTCT_TRONGTHANG,
  BC_CT_DINHKY: APIBaoCao.BC_CT_DINHKY,
  BC_CT_PHUCTRA: APIBaoCao.BC_CT_PHUCTRA,
  EXCEL_BC_CT_BATTHUONG: APIBaoCao.EXCEL_BC_CT_BATTHUONG,
  EXCEL_BC_CT_DINHKY: APIBaoCao.EXCEL_BC_CT_DINHKY,
  EXCEL_BC_CT_PHUCTRA: APIBaoCao.EXCEL_BC_CT_PHUCTRA,
  PDF_BC_CT_BATTHUONG: APIBaoCao.PDF_BC_CT_BATTHUONG,
  PDF_BC_CT_DINHKY: APIBaoCao.PDF_BC_CT_DINHKY,
  PDF_BC_CT_PHUCTRA: APIBaoCao.PDF_BC_CT_PHUCTRA,
  BC_PT_NGOAIHIENTRUONG: APIBaoCao.BC_PT_NGOAIHIENTRUONG,
  BC_PT_TRONGTHANG: APIBaoCao.BC_PT_TRONGTHANG,
  BC_TRONGTHANG_THEO_NV: APIBaoCao.BC_TRONGTHANG_THEO_NV,
  BC_KT_THIETBI_HANGNGAY: APIBaoCao.BC_KT_THIETBI_HANGNGAY,

  // api công tơ

  GET_CONGTO: APICongTo.GET_CONGTO,
  LIST_CT_TAO_BK: APICongTo.LIST_CT_TAO_BK,
  DETAIL_CONGTO: APICongTo.DETAIL_CONGTO,
  BONGBO_CMIS_DK: APICongTo.BONGBO_CMIS_DK,
  BONGBO_CMIS_PT: APICongTo.BONGBO_CMIS_PT,
  DK_DONGBO_VE_WEB: APICongTo.DK_DONGBO_VE_WEB,
  PT_DONGBO_VE_WEB: APICongTo.PT_DONGBO_VE_WEB,
  DONGBO_LEN_CMIS: APICongTo.DONGBO_LEN_CMIS,
  CHI_SO_EVN_HES: APICongTo.CHI_SO_EVN_HES,
  CHUYEN_MTB_KTCT: APICongTo.CHUYEN_MTB_KTCT,

  //api phân công

  LIST_NVKT: APIPhanCong.LIST_NVKT,
  GET_PHANCONG: APIPhanCong.GET_PHANCONG,
  PKD_PHANCONG: APIPhanCong.PKD_PHANCONG,
  DOITRUONG_PHANCONG: APIPhanCong.DOITRUONG_PHANCONG,
  HUY_PHANCONG: APIPhanCong.HUY_PHANCONG,
  XOA_YEUCAU: APIPhanCong.XOA_YEUCAU,
  LIST_NV_KTCT: APIPhanCong.LIST_NV_KTCT,
  KTCT_LAM_MOI_YC: APIPhanCong.KTCT_LAM_MOI_YC,
  // bảng kê

  LIST_BANGKE: APIBangKe.LIST_BANGKE,
  GET_BANGKE: APIBangKe.GET_BANGKE,
  POST_BANGKE: APIBangKe.POST_BANGKE,
  PUT_BANGKE: APIBangKe.PUT_BANGKE,
  LIST_CT_BANGKE: APIBangKe.LIST_CT_BANGKE,
  TAO_BANG_KE: APIBangKe.TAO_BANG_KE,
  KIEM_TRA_LAI: APIBangKe.KIEM_TRA_LAI,
  PDF_BANGKE: APIBangKe.PDF_BANGKE,
  HUY_BANGKE: APIBangKe.HUY_BANGKE,
  XACNHAN_BANGKE: APIBangKe.XACNHAN_BANGKE,
  TRA_BANGKE: APIBangKe.TRA_BANGKE,
  KY_BANGKE: APIBangKe.KY_BANGKE,
  GET_HASH_KY_BANGKE: APIBangKe.GET_HASH_KY_BANGKE,
  LEAD_XACNHAN_BANGKE: APIBangKe.LEAD_XACNHAN_BANGKE,
  KYSO_HSM_KTCT: APIBangKe.KYSO_HSM_KTCT,
};
