import { METHOD_POST, STATUSCODE_200, METHOD_PUT } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { getErrorMessage } from "@utils/function";
import {
  authGetData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";
// call api tạo bảng kê

export const handleTaoBK = (
  data,
  setLoading,
  closeModal,
  form,
  handleLayDuLieu,
  onComplete
) => {
  authPostData({
    url: Endpoint.TAO_BANG_KE,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        closeModal();
        handleLayDuLieu();
        onComplete();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};
// call api kiểm tra lại

export const handleKTLai = (data, setLoading, closeModal, handleLayDuLieu) => {
  authPostData({
    url: Endpoint.KIEM_TRA_LAI,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        closeModal();
        handleLayDuLieu();
      } else {
        getErrorMessage(res);
      }
    },
  });
};
// call api list công tơ

export const handleListCTTao = (
  data,
  setLoading,
  setDataCongTo,
  setColumnsBK
) => {
  authGetData({
    // eslint-disable-next-line max-len
    url: `${Endpoint.LIST_CT_BANGKE}?LoaiBangKe=${data.loaiBangKe}&DonViId=${data.donViId}&DoiId=${data.doiId}&NguoiThucHienId=${data.nguoiThucHienId}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setColumnsBK(parseInt(data.loaiBangKe));
        setDataCongTo(res.data);
      } else {
        setDataCongTo([]);
      }
    },
  });
};
// call api multilist data dropdown

export const handleMultiDS = (setLoading, setLoaiBangKe) => {
  const endpoints = [`${Endpoint.LIST_LOAI_BANGKE}`];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setLoaiBangKe(res[0].data.data);
    },
  });
};
