import { authPostData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";

// Update data
export const handleUpdateData = (
  data,
  setLoading,
  closeModal,
  setFetchDataTable
) => {
  authPostData({
    url: Endpoint.KHAO_SAT_LAI,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        closeModal();
      }
    },
  });
};
