import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
} from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { handleCapNhatBanLe } from "./service";

export default function FormUpdateDichVuBanLe(props) {
  const { close } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();
  const { user } = useSelector(authSelector);

  const onFinish = (values) => {
    const payload = {
      ...values,
      ...(values.reportDate && { Nam: values.reportDate.split("-")[0] }),
      ...(values.reportDate && { Thang: values.reportDate.split("-")[1] }),
      useR_TAO: user.username,
    };
    handleCapNhatBanLe(
      payload,
      setLoading,
      Endpoint.BCDVBLDN_CAP_NHAT_CT_TCVHD_BAN_LE_DIEN_NANG,
      form,
      close
    );
  };

  return (
    <FormComponent
      layout="vertical"
      onFinish={onFinish}
      form={form}
      loading={loading}
      wrapperCol={{ span: 23 }}
    >
      <Row gutter={6}>
        <Col span={12}>
          <Form.Item name="reportDate" label="Tháng làm việc" labelAlign="left">
            <DatePickerComponent
              picker="month"
              formKey="reportDate"
              form={form}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="mA_DVIQLY" label="Đơn vị" labelAlign="left">
            <Selection
              url={Endpoint.GET_TEAM_LIST_CODE}
              formKey="mA_DVIQLY"
              form={form}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="text-area">
        <Col span={12}>
          <Form.Item
            name="xA_PHUONG_THITRAN"
            label="Xã/Phường/Thị Trấn"
            labelAlign="left"
          >
            <Input.TextArea
              size="small"
              formKey="xA_PHUONG_THITRAN"
              showCount
              maxLength={255}
              placeholder="Nhập địa chỉ"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={12}>
          <Form.Item name="tonG_DV" label="Tổng số dịch vụ" labelAlign="left">
            <InputNumber min={1} max={100} placeholder="Nhập tổng số dịch vụ" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="sL_THAMGIA_DV"
            label="Số lượng người tham gia dịch vụ"
            labelAlign="left"
          >
            <InputNumber
              min={1}
              max={100}
              placeholder="Nhập số lượng người tham gia dịch vụ"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={12}>
          <Form.Item
            name="sL_THIEN_DV"
            label="Số kh thực hiện dịch vụ"
            labelAlign="left"
          >
            <InputNumber
              min={1}
              max={100}
              placeholder="Nhập số KH thực hiện dịch vụ"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="sL_HDON_PHAITHU"
            label="Số lượng hóa đơn phải thu trong tháng"
            labelAlign="left"
          >
            <InputNumber
              min={1}
              max={100}
              placeholder="Nhập số lượng hóa đơn phải thu trong tháng"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={12}>
          <Form.Item
            name="sL_HDON_THU"
            label="Số lượng hóa đơn thu được trong tháng:"
            labelAlign="left"
          >
            <InputNumber placeholder="Nhập số lượng hóa đơn thu được trong tháng" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="dthuongphaM_GIAO"
            label="Điện thương phẩm giao tổ dịch vụ:"
            labelAlign="left"
          >
            <Input placeholder="Nhập điện thương phẩm giao tổ dịch vụ" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name="dthuongphaM_THU"
            label="Doanh thu giao tổ dịch vụ bán lẻ điện năng:"
            labelAlign="left"
          >
            <Input
              type="text"
              placeholder="Nhập doanh thu giao tổ dịch vụ bán lẻ điện năng"
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider className="text" orientation="left" plain={true}>
        Nội dung công việc thực hiện
      </Divider>
      <Row>
        <Col span={24}>
          <Form.Item name="check" labelAlign="left">
            <Radio.Group>
              <Radio value={0}>Ghi số công tơ điện</Radio>
              <Radio value={1}>Thu nộp tiền điện sinh hoạt</Radio>
              <Radio value={2}>Các công việc khác theo thỏa thuận</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Divider className="text" orientation="left" plain={true}>
        Tiền công dịch vụ chi trả
      </Divider>
      <div className="price_service">
        <Row gutter={6}>
          <Col span={12}>
            <Form.Item
              name="tiendV_QUYDINH"
              label="Theo định mức quy chế và hướng dẫn của Tổng công ty:"
              labelAlign="left"
            >
              <Input
                type="text"
                placeholder="Nhập theo định mức quy chế và hướng dẫn của Tổng công ty "
              ></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="tiendV_TRACVKHAC"
              label="Trả cho công việc khác theo thỏa thuận"
              labelAlign="left"
            >
              <Input
                type="text"
                placeholder="Nhập trả cho công việc khác theo thỏa thuận"
              ></Input>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <Divider className="text" orientation="left" plain={true}></Divider>
      <Row gutter={6}>
        <Col span={12}>
          <Form.Item
            name="luykE_TPHAM_GIAO"
            label="Lũy kế điện thương phẩm giao tố dịch vụ:"
            labelAlign="left"
          >
            <Input placeholder="Nhập lũy kế điện thương phẩm giao tố dịch vụ" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="luykE_DOANHTHU"
            label="Lũy kế doanh thu giao tố dịch vụ bán lẻ điện năng:"
            labelAlign="left"
          >
            <Input placeholder="Nhập lũy kế doanh thu giao tố dịch vụ bán lẻ điện năng" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={12}>
          <Form.Item
            name="luykE_DV"
            label="Lũy kế tiền công dịch vụ:"
            labelAlign="left"
          >
            <Input placeholder="Nhập lũy kế tiền công dịch vụ" />
          </Form.Item>
        </Col>
      </Row>
      <div className="btn-update-data">
        <Row gutter={16} justify="center">
          <Col span={4}>
            <Button
              className="full"
              type="primary"
              size="large"
              htmlType="submit"
              disabled={loading}
            >
              Cập nhật
            </Button>
          </Col>
        </Row>
      </div>
    </FormComponent>
  );
}
