import React, {
  Fragment,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  useContext,
  useEffect,
  useMemo
} from "react";
import Filter from "@components/Filter/Filter";
import { FormStyled } from "../common/css-styled";
import { Form, Input, Modal, Row, Select, notification } from "antd";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import {
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import {
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilter } from "../common/list-bo-loc";
import TableQuanLyChiSo from "../common/table";
import ChiTietCongTo from "../common/modal-chi-tiet-cong-to";
import DongBoCMIS from "./modal-lay-cto-thao-tu-cmis";
import CheckPermission from "@components/CheckPermission";
import { chi_so_cong_to } from "@permissions/i_chisocongto";
import ButtonComponent from "@components/Button";
import {
  handleListDataCapNhatChiSo,
  submitCapNhatChiSo,
  submitCapNhatMDMS,
  submitCapNhatMDMSAll,
  submitQuet,
  submitTrangThai,
  getCauHinhchiSoGanNhat,
  handleSoLuongDoiSoat,
  getNhanVienService,
  handleChiTietBienBanDB,
} from "./services";
import { getListDonViService, getBienBanWebService, } from "../common/services";
import "moment/locale/vi";
import moment from "moment";
const { Option } = Select;

export default function Index() {
  const EditableContext = React.createContext(null);
  const { user } = useSelector(authSelector);
  const [dataCapNhatChiSo, setDataCapNhatChiSo] = useState([]);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingBB, setLoadingBB] = useState(true);
  const [visibleChiTiet, setVisibleChiTiet] = useState(false);
  const [visibleDongBo, setVisibleDongBo] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [recordDetail, setRecordDetail] = useState();
  const [ngayBatDau, setNgayBatDau] = useState(moment().startOf(MONTH_STRING).format(FORMAT_ENGLISH));
  const [ngayKetThuc, setNgayKetThuc] = useState(moment().format(FORMAT_ENGLISH));
  const [rangeDate, setRangeDate] = useState([]);
  const [dataDonVi, setDataDonVi] = useState([]);
  const [dataBienBan, setDataBienban] = useState([]);
  const [dataNhanVien, setDataNhanVien] = useState([]);
  const [dataSLDoiSoat, setDataSLDoiSoat] = useState();
  const [bienBan, setBienban] = useState();
  const [cauHinhValue, setCauHinhValue] = useState();
  const location = useLocation();
  const [dataChiTietDB, setDataChiTietDB] = useState();
  const [isDB, setIsDB] = useState(false);
  const [term, setTerm] = useState("");

  const [filterConditions, setFilterConditions] = useState({
    DonViId: user.unitId,
    NgayBatDau: moment().startOf(MONTH_STRING).format(FORMAT_ENGLISH),
    NgayKetThuc: moment().format(FORMAT_ENGLISH),
    ...parseParams(location.search),
  });

  const [filterBB, setFilterBB] = useState({
    DonViId: user.unitId,
    NgayBatDau: moment().startOf(MONTH_STRING).format(FORMAT_ENGLISH),
    NgayKetThuc: moment().format(FORMAT_ENGLISH),
    IsDongBoLenCmis: "0"
  });

  useEffect(() => {
    if (parseParams(location.search) && Object.keys(parseParams(location.search)).length === 0) {
      setRangeDate([moment().startOf(MONTH_STRING), moment()]);
      setNgayBatDau(filterConditions.NgayBatDau);
      setNgayKetThuc(filterConditions.NgayKetThuc);
    } else if (parseParams(location.search).NgayBatDau === undefined) {
      setRangeDate([null, null]);
      setNgayBatDau();
      setNgayKetThuc();
    } else {
      console.log(parseParams(location.search).IsDongBoLenCmis);
      handleListDataCapNhatChiSo(
        setLoading,
        location,
        setDataCapNhatChiSo,
        filterConditions,
        cauHinhValue,
        term
      );
      handleSoLuongDoiSoat(
        setLoading,
        location,
        setDataSLDoiSoat,
        filterConditions,
      );
      if (parseParams(location.search).IsDongBoLenCmis === '3') {
        setIsDB(true);
      }
      (isDB || parseParams(location.search).IsDongBoLenCmis === '3') &&
      handleChiTietBienBanDB(
        setLoading,
        setDataChiTietDB,
        parseParams(location.search).MaBienBan ? parseParams(location.search).MaBienBan : bienBan
      );
      setRangeDate([moment(filterConditions.NgayBatDau), moment(filterConditions.NgayKetThuc)]);
      setNgayBatDau(filterConditions.NgayBatDau);
      setNgayKetThuc(filterConditions.NgayKetThuc);
      setBienban(filterConditions?.MaBienBan);
      setFilterBB((oldState) => ({
        ...oldState,
        NgayBatDau: filterConditions.NgayBatDau,
        NgayKetThuc: filterConditions.NgayKetThuc,
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCauHinhValue();
  }, []);

  useEffect(() => {
    getListNhanVien();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getListDonVi();
  }, []);

  const getCauHinhValue = () => {
    getCauHinhchiSoGanNhat(setLoading, setCauHinhValue);
  };

  const getListNhanVien = () => {
    getNhanVienService(setLoading, setDataNhanVien, user.unitId);
  };

  const getBienBan = useCallback(() => {
    getBienBanWebService(setLoadingBB, location, setDataBienban, filterBB);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBB]);

  useEffect(() => {
    getBienBan()
  }, [getBienBan]);

  const getListDonVi = () => {
    getListDonViService(setLoading, setDataDonVi);
  };

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const handleChangeRange = useCallback((values) => {
    setDataCapNhatChiSo([]);
    setDataSLDoiSoat();
    setDataChiTietDB();
    if (values !== null && moment(values[0]).isValid() && moment(values[1]).isValid()) {
      setNgayBatDau(moment(values[0]).format(FORMAT_ENGLISH));
      setNgayKetThuc(moment(values[1]).format(FORMAT_ENGLISH));
      setRangeDate(values);
      setFilterBB((oldState) => ({
        ...oldState,
        NgayBatDau: moment(values[0]).format(FORMAT_ENGLISH),
        NgayKetThuc: moment(values[1]).format(FORMAT_ENGLISH),
      }));
      setBienban();
    } else {
      setNgayBatDau();
      setNgayKetThuc();
      setRangeDate([]);
      setFilterBB((oldState) => ({
        ...oldState,
        NgayBatDau: null,
        NgayKetThuc: null,
      }));
      setBienban();
    }
  }, []);

  const handleChangeDonVi = useCallback((values) => {
    setDataCapNhatChiSo([]);
    setDataSLDoiSoat();
    setDataChiTietDB();
    setFilterBB((oldState) => ({
      ...oldState,
      DonViId: values,
      NgayBatDau: ngayBatDau,
      NgayKetThuc: ngayKetThuc,
    }));
    setBienban();
  }, [ngayBatDau, ngayKetThuc]);

  const handleChangeBienBan = useCallback((values) => {
    setDataCapNhatChiSo([]);
    setDataSLDoiSoat();
    setDataChiTietDB();
    setBienban(values);
    setIsDB(dataBienBan.find(({ maBienBan }) => maBienBan === values).isCmisSync);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataBienBan]);

  const handleSearch = useCallback((values) => {
    if (!bienBan) {
      notification.error({
        message: `Thông báo`,
        description: "Số biên bản không được để trống",
      });
    } else {
      setFilterConditions((oldState) => ({
        ...oldState,
        ...values,
        NgayBatDau: ngayBatDau,
        NgayKetThuc: ngayKetThuc,
        MaBienBan: bienBan
      }));
    }
  }, [ngayBatDau, ngayKetThuc, bienBan]);

  const getSoLuongDoiSoat = useCallback(() => {
    handleSoLuongDoiSoat(
      setLoading,
      location,
      setDataSLDoiSoat,
      filterConditions,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      DonViId: user.unitId,
      NgayBatDau: moment().startOf(MONTH_STRING).format(FORMAT_ENGLISH),
      NgayKetThuc: moment().format(FORMAT_ENGLISH),
    });
    setFilterBB((oldState) => ({
      ...oldState,
      DonViId: user.unitId,
      NgayBatDau: moment().startOf(MONTH_STRING).format(FORMAT_ENGLISH),
      NgayKetThuc: moment().format(FORMAT_ENGLISH),
    }));
    setRangeDate([moment().startOf(MONTH_STRING), moment()]);
    setDataCapNhatChiSo([]);
    setDataChiTietDB();
    setDataSLDoiSoat();
    setBienban();
  }, [user.unitId]);

  useEffect(() => {
    bienBan &&
      getSoLuongDoiSoat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSoLuongDoiSoat]);

  const getChiTietBBDB = useCallback(() => {
    bienBan && isDB &&
    handleChiTietBienBanDB(
        setLoading,
        setDataChiTietDB,
        bienBan
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  //search list
  const getListDataCapNhatChiSo = useCallback(() => {
    bienBan &&
      handleListDataCapNhatChiSo(
        setLoading,
        location,
        setDataCapNhatChiSo,
        filterConditions,
        cauHinhValue,
        term
      );
    setSelectedRowKeys([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term, cauHinhValue, location]);

  useEffect(() => {
    bienBan &&
      getListDataCapNhatChiSo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, getListDataCapNhatChiSo]);

  useEffect(() => {
    // isDB &&
    getChiTietBBDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChiTietBBDB]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getChiTietBBDB();
      getListDataCapNhatChiSo();
      getSoLuongDoiSoat();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataTableIfNeed, setFetchDataTable]);

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: "3%",
    fixed: "left",
    getCheckboxProps: (record) => ({
      style: { display: record.id === undefined ? "none" : "" },
      disabled: record.id === undefined || isDB
    }),

  };

  // eslint-disable-next-line no-unused-vars
  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [dataIndex, editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      dataIndex === "chiSoX5" ?
        form.setFieldsValue({
          [dataIndex]: record[dataIndex] === 0 ? null : record[dataIndex],
        }) :
        form.setFieldsValue({
          [dataIndex]: record[dataIndex] === "0" ? "" : record[dataIndex],
        })
    };

    function KeyCheck(e) {
      // eslint-disable-next-line no-restricted-globals
      const KeyID = (window.event) ? event.keyCode : e.keyCode;
      if (KeyID === 113)
        form.setFieldsValue({
          [dataIndex]: record.chiSoCMIS,
        });
    }
    const save = async () => {
      try {
        const values = await form.validateFields();
        form.setFieldsValue({
          [dataIndex]: record[dataIndex],
        });
        if ((record[dataIndex] !== null && record[dataIndex] !== Number(Object.values(values)[0])) ||
          (record[dataIndex] === null && Object.values(values)[0] !== null)) {
          submitCapNhatChiSo(setLoading, setFetchDataTable, record.idChiTiet, record.bcs, values);
        }
        setEditing(!editing);
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
        setEditing(!editing);
      }
    };
    const changeTrangThai = async () => {
      const values = await form.validateFields();
      try {
        form.setFieldsValue({
          [dataIndex]: record[dataIndex],
        });
        submitTrangThai(setLoading, setFetchDataTable, record.id, values)
        setEditing(!editing);
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
        setEditing(!editing);
      }
    };
    const lostFocus = async () => {
      setEditing(!editing);
    };

    let childNode = children;
    if (editable && dataIndex === "chiSoX5") {
      childNode = editing ? (
        <FormStyled>
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
            rules={[
              {
                pattern: new RegExp(/^[0-9]+$/),
                message: `${title} phải là số nguyên.`,
              },
            ]}
          >
            <Input ref={inputRef} onPressEnter={save} onBlur={save} onKeyUp={KeyCheck} />
          </Form.Item>
        </FormStyled>
      ) : (
        <div className="editable-cell-value-wrap" onClick={toggleEdit} >
          {children}
        </div>
      );
    }
    if (editable && dataIndex === "isSelected") {
      childNode = editing ? (
        <FormStyled>
          <Form.Item name={dataIndex} style={{ marginBottom: 0 }}>
            <Select onChange={changeTrangThai} ref={inputRef} onBlur={lostFocus}>
              <Option value="1">
                Đạt
              </Option>
              <Option value="2">
                Không Đạt
              </Option>
            </Select>
          </Form.Item>
        </FormStyled>
      ) : (
        <div className="editable-cell-value-wrap" onClick={toggleEdit}>
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  };

  const handleLayDoXa = () => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: "Bạn muốn lấy chỉ số đo xa lúc 0h ngày tháo của các điểm đo đã chọn?",
      onOk() {
        const listID = dataCapNhatChiSo.filter((el) => {
          return selectedRowKeys.some((f) => {
            return f === el.rowNumber;
          });
        });
        submitCapNhatMDMS(setLoading, setFetchDataTable, listID.map(s => s.id), setSelectedRowKeys);
      },
      onCancel() { },
      okText: "Đồng ý",
      okButtonProps: { type: "danger" },
      cancelText: "Hủy",
    });
  };
  const handleLayDoXaAll = () => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: "Bạn muốn lấy chỉ số đo xa lúc 0h ngày tháo của tất cả điểm đo ?",
      onOk() {
        if (!bienBan) {
          notification.error({
            message: `Thông báo`,
            description: "Số biên bản không được để trống",
          });
        } else {
          submitCapNhatMDMSAll(setLoading, setFetchDataTable, filterConditions);
        }
      },
      onCancel() { },
      okText: "Đồng ý",
      okButtonProps: { type: "danger" },
      cancelText: "Hủy",
    });
  };

  const handleSubmitQuet = (id, trangthai) => {
    submitQuet(setLoading, setFetchDataTable, id, trangthai);
  };

  //open popup chi tiết công tơ
  const handleOpenChiTietCongToModal = (id) => {
    setRecordDetail(dataCapNhatChiSo.filter(x => x.id === id)[0])
    setVisibleChiTiet(true);
  };
  //open popup đồng bộ cmis
  const handleOpenDongBoCMIS = () => {
    // if (dataCapNhatChiSo.find(element => element.isScanned === "1" && element.chiSoX5 === null) !== undefined) {
    //   notification.error({
    //     message: `Thông báo`,
    //     description: "Hãy nhập chỉ số X5 cho công tơ có tình trạng là đã quẹt",
    //   });
    // } else
     if (dataSLDoiSoat?.tongSoChuaXacDinh > 0) {
      notification.error({
        message: `Thông báo`,
        description: `Có ${dataSLDoiSoat?.tongSoChuaXacDinh} công tơ chưa xác định kết quả giao nhận Đạt /Không đạt. Vui lòng đánh giá kết quả giao nhận trước khi đồng bộ về CMIS`,
      });
    // } else if (dataCapNhatChiSo.filter(x => x.checkSN).length !== 0 ||
    // dataCapNhatChiSo.filter(x => x.checkSG).length !== 0) {
    //   Modal.confirm({
    //     centered: true,
    //     title: "Xác nhận",
    //     content: "Có công tơ có biểu tổng có giá trị khác tổng chỉ số các biểu chi tiết, bạn có muốn tiêp tục xử lí ?",
    //     onOk() {
    //       setVisibleDongBo(true);
    //     },
    //     onCancel() { },
    //     okText: "Đồng ý",
    //     okButtonProps: { type: "danger" },
    //     cancelText: "Hủy",
    //   });
    } else {
      // handleChiTietBienBanDB(setLoading, setDataChiTietDB, bienBan);
      setVisibleDongBo(true);
    }
  };

  //đóng popup chi tiết
  const handleCloseModal = () => {
    setVisibleChiTiet(false);
  };
  //đóng popup đồng bộ
  const handleCloseModalDongBo = () => {
    setVisibleDongBo(false);
  };
  //render popup chi tiết công tơ
  const showChiTietCongToModal = useMemo(
    () =>
      visibleChiTiet && (
        <ChiTietCongTo
          visible={visibleChiTiet}
          closeModal={handleCloseModal}
          recordDetail={recordDetail}
        />
      ),
    [visibleChiTiet, recordDetail]
  );
  //render popup đồng bộ cmis
  const showDongBoModal = useMemo(
    () =>
      visibleDongBo && (
        <DongBoCMIS
          visible={visibleDongBo}
          closeModal={handleCloseModalDongBo}
          loading={loading}
          handleCloseModalDongBo={handleCloseModalDongBo}
          setFetchDataTable={setFetchDataTable}
          dataNhanVien={dataNhanVien}
          filterConditions={filterConditions}
          setIsDB={setIsDB}
          
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataNhanVien, loading, visibleDongBo]
  );
  const detailCongTo = (record) => {
    return {
      onDoubleClick: () => {
        handleOpenChiTietCongToModal(record.idChiTiet ? record.idChiTiet : record.id)
      },
    };
  };
  // const changeTerm = (value) => {
  //   setTerm(value.target.value)
  // };
  const genExtra = useCallback(
    () => (
      <div>
        <CheckPermission permissionCode={chi_so_cong_to.i1dc}>
          <ButtonComponent
            type="primary"
            disabled={selectedRowKeys.length === 0 ? true : false}
            onClick={() => handleLayDoXa()}
            size="default"
            className="mr-5px"
            
          >
            Lấy đo xa các công tơ đã chọn
          </ButtonComponent>
        </CheckPermission>
        <CheckPermission permissionCode={chi_so_cong_to.i1dt}>
          <ButtonComponent
            type="primary"
            size="default"
            className="mr-5px"
            onClick={() => handleLayDoXaAll()}
            disabled={isDB}
          >
            Lấy đo xa tất cả
          </ButtonComponent>
        </CheckPermission>
        <CheckPermission permissionCode={chi_so_cong_to.i1db}>
          <ButtonComponent
            type="primary"
            size="default"
            className="mr-5px"
            onClick={() => handleOpenDongBoCMIS()}
            disabled={isDB}
          >
            Đồng bộ về CMIS
          </ButtonComponent>
        </CheckPermission>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRowKeys, dataCapNhatChiSo]
  );
  const SearchTerm = useCallback((value) => {
    const term = value.target.value;
    setTerm(term);
    if (term.trim().length > 0) {
      let listTerm = [];
      listTerm = dataCapNhatChiSo.filter(data =>
        data.maThietBi?.toUpperCase() === term.trim().toUpperCase());
      if (listTerm.length > 0) {
        listTerm[0].isScanned = true;
        isDB && submitQuet(setLoading, setFetchDataTable, listTerm[0].id, "0");
        setDataCapNhatChiSo(listTerm);
      } else {
        setDataCapNhatChiSo(listTerm);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCapNhatChiSo]);

  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          changeRange={handleChangeRange}
          rangeDate={rangeDate}
          dataDonVi={dataDonVi}
          handleChangeDonVi={handleChangeDonVi}
          dataBienBan={dataBienBan}
          handleChangeBienBan={handleChangeBienBan}
          bienBan={bienBan}
          loadingBB={loadingBB}
        />
      </Filter>
      {dataChiTietDB &&
      <>
      <div style={{ color: "#0dcb0d", fontWeight: "bold ", marginLeft: 20 }}> Chi tiết biên bản đã đồng bộ</div>
      <Row style={{ marginLeft: 20 }}>
      <span style={{ marginRight: "20px", marginTop: 5, fontWeight: "bold" }}>
          Người nhận: <span style={{ fontWeight: "normal" }}>
            {dataChiTietDB?.nguoiNhan }
          </span>
        </span>
        <span style={{ marginRight: "20px", marginTop: 5, fontWeight: "bold" }}>
          Kho nhận: <span style={{ fontWeight: "normal" }}>
          {dataChiTietDB?.maKho === "201" ? "Kho dữ liệu công tơ quay vòng chưa thực hiện P7.9" :
          "Kho dữ liệu công tơ quay vòng chưa thực hiện P8.9" }
          </span>
        </span>
        <span style={{ marginRight: "20px", marginTop: 5, fontWeight: "bold" }}>
          Ngày nhận: <span style={{ fontWeight: "normal" }}>
          {dataChiTietDB?.ngayNhan }
          </span>
        </span>
      </Row></>
      }
      
      <Row style={{ height: 20, marginTop: 10, marginLeft: 20 }}>
        <span style={{ marginRight: "20px", marginTop: 5, fontWeight: "bold" }}>
          Tổng: <span style={{ fontWeight: "bold" }}>
            {dataSLDoiSoat ? dataSLDoiSoat.tongToSoCongTo : 0}
          </span>
        </span>
        <span style={{ marginRight: "20px", marginTop: 5 }}>
          Đạt: <span style={{ color: "blue", fontWeight: "bold" }}>
            {dataSLDoiSoat ? dataSLDoiSoat.tongSoCongToDaDoiSoatDat : 0}
          </span>
        </span>
        <span style={{ marginRight: "20px", marginTop: 5, }}>
          Không đạt: <span style={{ color: "red", fontWeight: "bold" }}>
            {dataSLDoiSoat ? dataSLDoiSoat.tongSoCongToDaDoiSoatKhongDat : 0}
          </span>
        </span>
        <span style={{ marginRight: "20px", marginTop: 5, }}>
          Chưa xác định: <span style={{ color: "pink", fontWeight: "bold" }}>
            {dataSLDoiSoat ? dataSLDoiSoat.tongSoChuaXacDinh : 0}
          </span>
        </span>
        <Input
          placeholder="Tìm theo Mã công tơ"
          style={{ width: 200, marginRight: 10 }}
          // onChange={changeTerm}
          onPressEnter={SearchTerm}
        />
        <label className="mr-24px" style={{ marginTop: 5, fontWeight: "bold", color: "#2eb3df" }}>F2: Chỉ số X5 = Chỉ số tháo</label>
      </Row>
      {
        (dataCapNhatChiSo.filter(x => x.checkSN).length !== 0 ||
          dataCapNhatChiSo.filter(x => x.checkSG).length !== 0) &&
        <p style={{ fontWeight: "bold", color: "red", marginBottom: -20, marginTop: 20, marginLeft: 20 }}>
          Có công tơ bộ tổng có chỉ số khác tổng của các bộ chi tiết !
        </p>
      }
      <TableQuanLyChiSo
        header={"Kiểm tra công tơ tháo"}
        rowSelection={rowSelection}
        handleSearch={handleSearch}
        data={dataCapNhatChiSo}
        filterConditions={filterConditions}
        loading={loading}
        EditableRow={EditableRow}
        EditableCell={EditableCell}
        handleOpenChiTietCongToModal={handleOpenChiTietCongToModal}
        detail={detailCongTo}
        cauHinhValue={cauHinhValue}
        filter={true}
        handleSubmitQuet={handleSubmitQuet}
        renderExtraAction={() => genExtra()}
        isDB={isDB}
      />
      {showChiTietCongToModal}
      {showDongBoModal}
    </Fragment>
  );
}
