import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Form, Modal } from "antd";
import {
  FORMAT_ENGLISH,
  FORMAT_ENGLISH_TIME_FULL,
  TYPE_PHIEUTRA_CHUCNANG,
  TYPE_PHIEUXUAT_CHUCNANG,
} from "@utils/constants";
import TrashIcon from "@assets/icon/TrashIcon";
import {
  getDataMulti,
  getListdataDSYCLQ,
  getListDataLoaiCT,
  handleCreateOrUpdate,
  handleShowInfomationPhieu,
  handleVatTu,
  handleYeuCau,
} from "../services";
import LayoutPhieuTra from "./layout";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { alertMessage } from "@utils/function";
export default function Index(props) {
  const {
    data,
    type,
    setFetchList,
    selectRowKeys,
    typeLoaiPhieu,
    nguoiTao,
    typeTreoThao,
    doiId,
    filterConditions,
    funcId,
  } = props;
  const [loading, setLoading] = useState([]);
  const [dataTH, setDataTH] = useState([]);
  const [, setDataVTTL] = useState([
    {
      key: 1,
    },
  ]);
  const [dataYCVTTH, setDataYCVTTH] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [loaiCongTo, setLoaiCongTo] = useState([]);
  const [dataSelectDSYCLQ, setDataSelectDSYCLQ] = useState([]);
  const [lyDo, setLyDo] = useState("");
  const [isTraLai, setIsTraLai] = useState(false);
  const [yeuCauIds, setYeuCauIds] = useState(
    type === TYPE_PHIEUTRA_CHUCNANG.TAOMOI ? selectRowKeys : []
  );
  const navigation = useNavigate();
  const [form] = Form.useForm();

  const columnsTH = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text, record, index) => (
        <div className="table-text-right">{index + 1}</div>
      ),
      width: "10%",
    },
    {
      title: "Mã công tơ",
      dataIndex: "maCongTo",
      key: "maCongTo",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "30%",
    },
    {
      title: "Loại công tơ",
      dataIndex: "loaiCongTo",
      key: "loaiCongTo",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "40%",
    },
    {
      title: "Số lượng thu hồi",
      dataIndex: "soLuongThuHoi",
      key: "soLuongThuHoi",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "20%",
    },
  ];

  const columnsYCVTTH = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text, record, index) => (
        <div className="table-text-right">{index + 1}</div>
      ),
      width: "5%",
    },
    {
      title: "Mã công tơ tháo",
      dataIndex: "maCongTo",
      key: "maCongTo",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "12%",
    },
    {
      title: "Loại công tơ",
      dataIndex: "loaiCongTo",
      key: "loaiCongTo",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
    },
    {
      title: "Ngày treo tháo",
      dataIndex: "ngayTreoThao",
      key: "ngayTreoThao",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "10%",
    },
    {
      title: "Loại treo tháo",
      dataIndex: "loaiTreoThao",
      key: "loaiTreoThao",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Trạm",
      dataIndex: "tram",
      key: "tram",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "7%",
    },
    {
      title: "Đội",
      dataIndex: "doi",
      key: "doi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
    },
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "12%",
    },
    {
      title: "Tên KH - SĐT",
      dataIndex: "tenKH",
      key: "tenKH",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
    },
    {
      title: "Tác vụ",
      width: "5%",
      dataIndex: "tacVu",
      key: "tacVu",
      fixed: "right",
      render: (_, record) => {
        return (
          <Fragment>
            {dataYCVTTH.length === 1 ? null : (
              <div className="action-table-column">
                <div
                  className="action-icon"
                  onClick={() => handleDeleteData(record.id)}
                >
                  <TrashIcon />
                </div>
              </div>
            )}
          </Fragment>
        );
      },
    },
  ];

  const [tenLoaiTT, setTenLoaiTT] = useState(
    data && data.length > 0 ? data[0].tenLoaiTreoThao : null
  );
  const [tenDoi, setTenDoi] = useState(
    data && data.length > 0 ? data[0].doi : null
  );
  const [ngayTao] = useState(
    data && data.length > 0
      ? data[0].ngayTaoDate
      : moment().format(FORMAT_ENGLISH)
  );

  const [dataLoaiTT, setDataLoaiTT] = useState([]);
  const [dataDoi, setDataDoi] = useState([]);
  const [firstDoi, setFirstDoi] = useState("");
  const [firstLoaiTT, setFirstLoaiTT] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const [valueDoi, setValueDoi] = useState("");
  const handleListCT = useCallback((doiId) => {
    getListDataLoaiCT(setLoading, setLoaiCongTo, doiId, setDataSourceDSVTTL);
  }, []);
  const [dataSourceDSVTTL, setDataSourceDSVTTL] = useState([]);

  useEffect(() => {
    if (tenLoaiTT === null && tenDoi === null) {
      getDataMulti(
        setLoading,
        setDataLoaiTT,
        setFirstLoaiTT,
        setDataDoi,
        setFirstDoi,
        type,
        funcId,
        filterConditions.donViId
      );
    }
  }, [tenLoaiTT, tenDoi, type, funcId, filterConditions?.donViId]);

  const changeValueDoi = useCallback(
    (value) => {
      setIsChanged(true);
      if (firstDoi !== value) {
        if (
          dataSourceDSVTTL.length === 1 &&
          dataSourceDSVTTL[0][`loaiCongTo-1`] === ""
        ) {
          setValueDoi(value);
          setFirstDoi(value);
          setIsChanged(true);
          setDataSourceDSVTTL([]);
          setFirstLoaiTT(form.getFieldValue("loaiTreoThao"));
          form.setFieldsValue({
            [`soLuongTra-1`]: null,
          });
        } else {
          Modal.confirm({
            centered: true,
            title: "Xác nhận",
            content:
              "Dữ liệu sẽ mất khi bạn thay đổi Đội. Bạn chắc rằng muốn thay đổi Đội?",
            onOk() {
              setValueDoi(value);
              setFirstDoi(value);
              setIsChanged(true);
              setDataSourceDSVTTL([]);
              setFirstLoaiTT(form.getFieldValue("loaiTreoThao"));
              // eslint-disable-next-line array-callback-return
              dataSourceDSVTTL.map((item) => {
                form.setFieldsValue({
                  [`soLuongTra-${item.stt}`]: null,
                });
              });
            },
            onCancel() {
              setValueDoi(firstDoi);
              setIsChanged(false);
            },
            okText: "Đồng ý",
            okButtonProps: { type: "danger" },
            cancelText: "Hủy",
          });
        }
      }
    },
    [dataSourceDSVTTL, firstDoi, form]
  );

  useEffect(() => {
    if (doiId) handleListCT(doiId);
  }, [doiId, handleListCT]);

  useEffect(() => {
    if (firstDoi) handleListCT(firstDoi);
  }, [firstDoi, handleListCT]);

  const dispatch = useDispatch();
  //call api list vt thu hồi / danh sách yc có vật tư thu hồi
  const getListData = useCallback(() => {
    // console.log(tenLoaiTT);
    if (type === TYPE_PHIEUTRA_CHUCNANG.TAOMOI) {
      if (selectRowKeys.length > 0)
        handleShowInfomationPhieu(
          setLoading,
          setDataTH,
          setDataVTTL,
          setDataYCVTTH,
          selectRowKeys,
          typeLoaiPhieu
          // setKeyData
        );
    } else {
      handleYeuCau(
        setLoading,
        selectRowKeys,
        setDataYCVTTH,
        setTenLoaiTT,
        setLyDo,
        setIsTraLai,
        setTenDoi,
        setYeuCauIds
      );
      // handleShowYeuCauByPhieu(
      //   setLoading,
      //   setDataTH,
      //   // setDataVTTL,
      //   setDataSourceDSVTTL,
      //   setDataYCVTTH,
      //   selectRowKeys,
      //   setTenLoaiTT,
      //   // setNgayTao,
      //   setTenDoi,
      //   loaiCongTo,
      //   form,
      //   setLyDo,
      //   setIsTraLai,
      //   setYeuCauIds
      //   // setKeyData
      // );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getListData();
  }, [getListData]);

  const getListVatTu = useCallback(() => {
    if (type === TYPE_PHIEUTRA_CHUCNANG.THAYDOI) {
      handleVatTu(
        setLoading,
        selectRowKeys,
        loaiCongTo,
        form,
        setDataSourceDSVTTL,
        setDataTH
      );
    }
  }, [form, loaiCongTo, selectRowKeys, type]);

  useEffect(() => {
    getListVatTu();
  }, [getListVatTu]);

  useEffect(() => {
    if (selectRowKeys.length > 0 && yeuCauIds.length > 0)
      getListdataDSYCLQ(
        setLoading,
        setDataSelectDSYCLQ,
        typeLoaiPhieu,
        typeTreoThao,
        yeuCauIds,
        doiId
      );
  }, [typeLoaiPhieu, doiId, typeTreoThao, selectRowKeys, yeuCauIds]);

  useEffect(() => {
    if (!selectRowKeys.length) {
      const arr = [];
      arr.push({
        stt: 1,
        chungLoai: "",
        [`loaiCongTo-1`]: "",
        duLieuLoaiCongTo: loaiCongTo,
        moTa: "",
        soLuongTon: "",
        [`soLuongTra-1`]: "",
      });
      setDataSourceDSVTTL(arr);
    }
  }, [loaiCongTo, selectRowKeys]);

  const [valueSearch, setValueSearch] = useState(undefined);
  //search dữ liệu yêu cầu liên quan
  const onSearch = (value) => {
    // let disabled = false;

    setValueSearch(value);
    let disabled = false;
    if (value === "" || value === undefined || value === null) disabled = true;
    else disabled = false;
    setDisabled(disabled);
  };

  //xóa dữ liệu

  const handleDeleteData = useCallback(
    (id) => {
      const newData = dataYCVTTH.filter((item) => item.id === id);
      setDataSelectDSYCLQ(dataSelectDSYCLQ.concat(newData));

      //Tìm vị trí nếu có tồn tại trong mảng.
      const indexSearch = dataYCVTTH.findIndex((e) => e.id === id);
      //xóa phần tử trong mảng
      const newDataVTLQ = dataYCVTTH.filter((e) => e.id !== id);
      setDataYCVTTH(newDataVTLQ);

      const newDataVTTH = dataTH.filter(
        (e) => e.congToId !== dataYCVTTH[indexSearch]?.congToId
      );
      setDataTH(newDataVTTH);
    },
    [dataSelectDSYCLQ, dataTH, dataYCVTTH]
  );

  //TẠO YÊU CẦU LIÊN QUAN
  const handleCreateLQ = () => {
    let arr = [];
    // const newDataVT = [];
    dataSelectDSYCLQ.map((item) => {
      if ([valueSearch].includes(item.id)) arr = [...dataYCVTTH, item];
      return true;
    });

    setDataYCVTTH(arr);
    //Tìm vị trí nếu có tồn tại trong mảng.
    const indexSearch = dataSelectDSYCLQ.findIndex((e) => e.id === valueSearch);

    setDataTH(dataTH.concat([dataSelectDSYCLQ[indexSearch]]));

    //
    const newData = dataSelectDSYCLQ.filter((item) => item.id !== valueSearch);
    setDataSelectDSYCLQ(newData);
    // dataDSYCLQ.splice(dataDSYCLQ[indexSearch], 1);
    setValueSearch(undefined);
    setDisabled(true);
    // setDisabledCreate(true);
  };

  //submit dữ liệu
  const onFinishSubmit = (values) => {
    const toDay = moment().format(FORMAT_ENGLISH_TIME_FULL);
    const newDataTH = [];
    const newDataTL = [];
    let isValid = true;
    dataTH.map((item) => {
      if (item.congToId === undefined || item.congToId === null) {
        isValid = false;
        return alertMessage(
          "error",
          "Thông báo",
          "Loại công tơ không thể trống!"
        );
      } else {
        return newDataTH.push({
          congToId: item.congToId,
          soLuongThuHoi: item.soLuongThuHoi ? parseInt(item.soLuongThuHoi) : 0,
        });
      }
    });

    dataSourceDSVTTL.map((item) => {
      return newDataTL.push({
        chungLoaiId: item.chungLoaiId,
        chungLoai: item.chungLoai,
        soLuongTon: item.soLuongTon,
        soLuongTra: parseInt(item[`soLuongTra-${item.stt}`]),
      });
    });
    let valiVTTL = true;

    // eslint-disable-next-line array-callback-return
    dataSourceDSVTTL.map((item) => {
      if (
        item[`loaiCongTo-${item.stt}`] === "" ||
        item[`loaiCongTo-${item.stt}`] === null
      ) {
        form.setFields([
          {
            name: [`loaiCongTo-${item.stt}`],
            errors: ["Không được bỏ trống"],
          },
        ]);
        return (valiVTTL = false);
      } else if (
        item[`soLuongTra-${item.stt}`] === "" ||
        item[`soLuongTra-${item.stt}`] === null
      ) {
        form.setFields([
          {
            name: [`soLuongTra-${item.stt}`],
            errors: ["Không được bỏ trống"],
          },
        ]);
        return (valiVTTL = false);
      }

      if (item[`soLuongTra-${item.stt}`] === "0") {
        form.setFields([
          {
            name: [`soLuongTra-${item.stt}`],
            errors: ["Phải lớn hơn 0"],
          },
        ]);
        return (valiVTTL = false);
      }

      if (item[`soLuongTra-${item.stt}`] > item.soLuongTon) {
        form.setFields([
          {
            name: [`soLuongTra-${item.stt}`],
            errors: ["Số lượng trả phải nhỏ hơn số lượng tồn"],
          },
        ]);
        return (valiVTTL = false);
      }
    });

    const yeuCauIds = [];
    dataYCVTTH.map((item) => {
      yeuCauIds.push(item.id);
      return true;
    });
    let id = null;
    if (type === TYPE_PHIEUXUAT_CHUCNANG.THAYDOI) {
      id = selectRowKeys.toString();
    }

    const payload = {
      loaiPhieuVatTu: typeLoaiPhieu,
      loaiTreoThao:
        selectRowKeys.length > 0 ? typeTreoThao : values.loaiTreoThao,
      ngayTao: values.ngayTao
        ? moment(values.ngayTao).format(FORMAT_ENGLISH_TIME_FULL)
        : ngayTao
        ? moment(ngayTao).format(FORMAT_ENGLISH_TIME_FULL)
        : toDay,
      //Nếu phiếu trả không chọn yêu cầu, thì truyền giá trị của đội được chọn vào
      doiId: selectRowKeys.length > 0 ? doiId : values.doiId,
      vatTuThuHois: newDataTH,
      vatTuTraLais: newDataTL,
      yeuCaus: yeuCauIds,
      id,
    };

    if (valiVTTL && isValid)
      handleCreateOrUpdate(
        payload,
        setLoading,
        type,
        setFetchList,
        form,
        dispatch,
        navigation,
        filterConditions
      );
  };

  return (
    <Fragment>
      <LayoutPhieuTra
        loading={loading}
        form={form}
        onFinishSubmit={onFinishSubmit}
        type={type}
        dataTH={dataTH}
        columnsTH={columnsTH}
        // dataVTTL={dataVTTL}
        // defaultColumnsTL={defaultColumnsTL}
        dataSelectDSYCLQ={dataSelectDSYCLQ}
        // handleAdd={handleAdd}
        // components={components}
        onSearch={onSearch}
        dataYCVTTH={dataYCVTTH}
        columnsYCVTTH={columnsYCVTTH}
        nguoiTao={nguoiTao}
        handleCreateLQ={handleCreateLQ}
        dataDetail={data}
        valueSearch={valueSearch}
        tenLoaiTT={tenLoaiTT}
        tenDoi={tenDoi}
        ngayTao={ngayTao}
        disabled={disabled}
        // keyData={keyData}
        // handleDelete={handleDelete}
        // handleChangeValue={handleChangeValue}
        dataLoaiTT={dataLoaiTT}
        dataDoi={dataDoi}
        changeValueDoi={changeValueDoi}
        valueDoi={valueDoi}
        firstDoi={firstDoi}
        firstLoaiTT={firstLoaiTT}
        isChanged={isChanged}
        loaiCongTo={loaiCongTo}
        dataSourceDSVTTL={dataSourceDSVTTL}
        setDataSourceDSVTTL={setDataSourceDSVTTL}
        lyDo={lyDo}
        isTraLai={isTraLai}
        selectRowKeys={selectRowKeys}
      />
    </Fragment>
  );
}
