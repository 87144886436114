import { SearchOutlined } from "@ant-design/icons";
import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import { getListDonVi, getListKenh, getListLinhVuc, getLoaiYeuCau } from "@modules/tich-hop-CRM/common-ho-so/crm-services";
import { removeAccents } from "@utils/function";
import { Button, Col, Form, Input, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { exportExcel } from "./services";

export default function CRMSearchFilter(props) {
  const { handleSearch, clearFilter, filterConditions, form, listTrangThai } = props;
  const [loading, setLoading] = useState(false);
  const [loaiYeuCau, setLoaiYeuCau] = useState([]);
  const [listDonVi, setListDonVi] = useState([]);
  const [linhVucList, setLinhVucList] = useState([]);
  const [listKenh, setListKenh] = useState([]);
  const [donVi, setDonVi] = useState(filterConditions.DonViId);

  useEffect(() => {
    form.setFieldsValue({
      ...filterConditions,
      TuNgay: filterConditions.TuNgay ? moment(new Date(filterConditions.TuNgay)) : moment().subtract(7, "d"),
      DenNgay: filterConditions.DenNgay ? moment(new Date(filterConditions.DenNgay)) : moment(new Date()),
    });
  }, [form, filterConditions]);

  useEffect(() => {
    getLoaiYeuCau(setLoading, setLoaiYeuCau);
    getListDonVi(setLoading, setListDonVi);
    getListLinhVuc(setLoading, setLinhVucList);

    getListKenh(setLoading, setListKenh);
  }, []);

  const handleChangeDonVi = (value) => {
    setDonVi(value);
  };

  const clear = () => {
    form.resetFields();
    clearFilter();
    setDonVi(donVi);
  };

  const handleExportExcel = () => {
    exportExcel({ filterConditions});
  };

  return (
    <Styled>
      <div className="tich-hop-CRM-list-bo-loc">
        <FormComponent
          layout="vertical"
          form={form}
          onFinish={handleSearch}
          initialValues={{
            DonViId: donVi,
          }}
        >
          <Row gutter={[8, 16]}>
            <Col span={6}>
              <Form.Item name="DonViId" label="Đơn vị">
                <Select
                  onChange={handleChangeDonVi}
                  loading={loading}
                  showSearch
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return removeAccents(option.children).toLowerCase().indexOf(removeAccents(input).toLowerCase()) >= 0;
                    }
                  }}
                  disabled={listDonVi.length < 2}
                >
                  {listDonVi &&
                    listDonVi.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name="LoaiYeuCau" label="Loại yêu cầu">
                <Select
                  allowClear
                  showSearch
                  loading={loading}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return removeAccents(option.children).toLowerCase().indexOf(removeAccents(input).toLowerCase()) >= 0;
                    }
                  }}
                >
                  {loaiYeuCau &&
                    loaiYeuCau.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="LinhVuc" label="Lĩnh vực">
                <Select
                  placeholder="Tất cả"
                  allowClear
                  showSearch
                  loading={loading}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return removeAccents(option.children).toLowerCase().indexOf(removeAccents(input).toLowerCase()) >= 0;
                    }
                  }}
                  disabled={linhVucList && linhVucList.length < 2}
                >
                  {linhVucList &&
                    linhVucList.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name="TrangThai" label="Trạng thái">
                <Select
                  placeholder="Tất cả"
                  allowClear
                  // disabled={listTrangThai.length < 2}
                  showSearch
                  loading={loading}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return removeAccents(option.children).toLowerCase().indexOf(removeAccents(input).toLowerCase()) >= 0;
                    }
                  }}
                >
                  {listTrangThai &&
                    listTrangThai.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="KenhId" label="Kênh tiếp nhận">
                <Select
                  allowClear
                  showSearch
                  loading={loading}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return removeAccents(option.children).toLowerCase().indexOf(removeAccents(input).toLowerCase()) >= 0;
                    }
                  }}
                >
                  {listKenh &&
                    listKenh.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Form.Item name="TuNgay" label="Từ ngày">
                <DatePickerComponent
                  formKey="TuNgay"
                  form={form}
                  defaultValue={
                    filterConditions.TuNgay ? moment(new Date(filterConditions.TuNgay)) : moment().subtract(7, "d")
                  }
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="DenNgay" label="Đến ngày">
                <DatePickerComponent
                  formKey="DenNgay"
                  form={form}
                  defaultValue={filterConditions.DenNgay ? moment(new Date(filterConditions.DenNgay)) : moment()}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="TuKhoaTimKiem" label="Từ khóa tìm kiếm">
                <Input prefix={<SearchOutlined />} />
              </Form.Item>
            </Col>
            <Col span={4}></Col>
            <Col span={24} className="tich-hop-CRM-list-bo-loc-button">
              <Button onClick={clear}>Bỏ lọc</Button>
              <Button type="primary" htmlType="submit">
                Tìm kiếm
              </Button>
              <Button type="primary" onClick={handleExportExcel}>
                Xuất excel
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  .tich-hop-CRM-list-bo-loc {
    .tich-hop-CRM-list-bo-loc-button {
      display: flex;
      gap: 20px !important;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;
