import { authPostData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";

// Update data
export const handleDuyetHoSoService = (
  hoSoId,
  setLoading,
  closeModal,
  setFetchDataTable
) => {
  authPostData({
    url: Endpoint.LANH_DAO_KY_DUYET,
    method: METHOD_POST,
    payload: hoSoId,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        closeModal();
      }
    },
  });
};
