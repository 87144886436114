import { Endpoint } from "@utils/endpoint";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import {
  buildQueryString,
  getErrorMessage,
  parseParams,
} from "@utils/function";
import {
  authGetData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";

export const handleList = (
  filterConditions,
  setLoading,
  setDataSource,
  setTotalCount
) => {
  authGetData({
    url:
      Endpoint.GET_LIST_BAO_CAO_TAO_MOI_KE_HOACH +
      "?" +
      buildQueryString(parseParams(filterConditions)),
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataSource(res.data);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};

export const handleCreate = (
  setLoading,
  data,
  getListData,
  closeModal,
  form,
  parent
) => {
  authPostData({
    url: Endpoint.CREATE_KE_HOACH_TIEP_NHAN,
    payload: data,
    method: METHOD_POST,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        if (!parent) {
          getListData();
          closeModal();
        }
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleMultiBoLoc = (setLoading, setDonVi) => {
  const endpoints = [`${Endpoint.GET_UNIT_LIST}`];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDonVi(res[0].data.data);
    },
  });
};
