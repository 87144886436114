import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import {
  buildQueryString,
  parseParams,
} from "@utils/function";

// get list data bảng kê nhân viên cần xác nhận
export const handleListBKNVXacNhan = (
  setLoading,
  location,
  setListBKNVXacNhan,
  setTotalCount,
  filterConditions,
  setSelectedRowKeys
) => {
  let url = "";
  url = `${Endpoint.AG_NV_LIST_YEU}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      setSelectedRowKeys([]);
      if (res.data !== null) {
        setListBKNVXacNhan(res.data);
        setTotalCount(res.paging.totalCount);
      } else setListBKNVXacNhan([]);
    },
  });
};

export const submitNhanVienXacNhan = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys,
  handleCloseModalKy
) => {
  authPostData({
    url: Endpoint.AG_NV_XAC_NHAN,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys.toString(),
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
        handleCloseModalKy();
      }
    },
  });
};

export const submitKiemTraLai = (
  selectedRowKeys,
  setLoading,
  setFetchDataTable,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.AG_NV_KIEM_TRA_LAI,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys.toString(),
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([])
      }
    },
  });
};
