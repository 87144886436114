import Filter from "../../common/Filter";
import TableComponent from "@components/TableComponent";
import moment from "moment";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_MONTH_ENGLISH,
  STATUSCODE_200,
} from "@utils/constants";
import {
  buildQueryString,
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { authGetData } from "@utils/request";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
  memo,
} from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Export } from "../export/Export";
import Filterscreen1 from "./list-filter/filter-screen1";
import { authSelector } from "@state/auth";
import { useSelector } from "react-redux";

const Screen1 = (props) => {
  const { user } = useSelector(authSelector);
  const initFilterConditions = {
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
    DonViId: user.unitId,
  };
  const { columns, scrollX = 1400, endPoint, param, isStatusRow } = props;
  const [, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();

  const [filterConditions, setFilterConditions] = useState({
    ...initFilterConditions,
    ...parseParams(location.search),
  });

  const clearSearch = useCallback(() => {
    setFilterConditions(initFilterConditions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const getPageList = useCallback(() => {
    const build = param
      ? {
          ...parseParams(
            location?.search ? location.search : initFilterConditions
          ),
          ...param,
        }
      : {
          ...parseParams(
            location?.search ? location.search : initFilterConditions
          ),
        };
    authGetData({
      url: `${endPoint.LIST}?${buildQueryString(build)}`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          setDataSource(
            res.data.map((e, index) => ({
              ...e,
              key:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            }))
          );
          setTotalCount(res.paging.totalCount);
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    endPoint.LIST,
    filterConditions.pageIndex,
    filterConditions.pageSize,
    location.search,
  ]);

  useEffect(() => {
    getPageList();
  }, [getPageList]);

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const genExtra = useCallback(() => {
    const build = param
      ? {
          ...parseParams(
            location?.search ? location.search : initFilterConditions
          ),
          ...param,
        }
      : {
          ...parseParams(
            location?.search ? location.search : initFilterConditions
          ),
        };
    return (
      <div className="export">
        <Export
          excelEndpoint={`${endPoint.EXCEL}?${buildQueryString(build)}`}
          pdfEndpoint={`${endPoint.PDF}?${buildQueryString(build)}`}
          setLoading={setLoading}
        />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endPoint.EXCEL, endPoint.PDF, location.search]);

  return (
    <Fragment>
      <Container>
        <Filter>
          <Filterscreen1
            setFilterConditions={setFilterConditions}
            clearSearch={clearSearch}
            isStatusRow={isStatusRow}
            filterConditions={filterConditions}
            user={user}
          />
        </Filter>
        <TableComponent
          header={"Danh sách kết quả"}
          renderExtraAction={() => genExtra()}
          dataSource={dataSource}
          columns={customColumn(columns, filterConditions)}
          totalData={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
          scrollX={scrollX}
          rowKey="key"
          // summary={(pageData) => countTotals(pageData)}
        />
      </Container>
    </Fragment>
  );
};

const Container = styled.div`
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
  .ant-collapse.ant-collapse-icon-position-left {
    margin-top: 20px;
  }
`;

export default memo(Screen1);
