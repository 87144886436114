import { Tabs } from "antd";
import { Fragment } from "react";
import styled from "styled-components";
import IndexBieu1 from "./tabs/dich-vu-khac/bieu1";
import IndexTabTongHop from "./tabs/tong-hop/bieu1";
import BaoCaoDoiSoat from "./tien-dien-lan-1/bieu1-tn-tong-cong-ty";

export default function TabsPaneBaoCao() {
  const { TabPane } = Tabs;
  const onChangeTabs = (key) => {
    console.log("key", key);
  };
  return (
    <Fragment>
      <Container>
        <Tabs defaultActiveKey="1" onChange={onChangeTabs}>
          <TabPane tab="Tiền điện lần 1" key="1">
            <BaoCaoDoiSoat />
          </TabPane>
          <TabPane tab="Dịch vụ khác" key="2">
            <IndexBieu1 />
          </TabPane>
          <TabPane tab="Tổng hợp" key="3">
            <IndexTabTongHop />
          </TabPane>
        </Tabs>
      </Container>
    </Fragment>
  );
}
const Container = styled.div`
  padding: 0px 16px 0px 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
