import FormComponent from "@components/Form";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import React from "react";
import { Style } from "../css-styled";

function TraYeuCauModal(props) {
  const { visible, handleCloseModal, handleSubmit, loading } = props;

  const [form] = Form.useForm();

  return (
    // <LoadingComponent loading={loading}>
    <Modal
      title="Giao dịch viên trả lại yêu cầu."
      visible={visible}
      onCancel={handleCloseModal}
      footer={null}
    >
      <FormComponent form={form} onFinish={handleSubmit}>
        <Style>
          <div className="tra-lai-yeu-cau-modal">
            <Row gutter={24} justify="center" style={{ marginTop: "20px" }}>
              <Col span={20} style={{ textAlign: "right" }}>
                <Form.Item label="Lý do trả lại" required name="LyDoTraLai">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Button
                  key="back"
                  onClick={handleCloseModal}
                  className="button-closed"
                >
                  Đóng lại
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  key="submit"
                  type="primary"
                  className="button-primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Xác nhận
                </Button>
              </Col>
            </Row>
          </div>
        </Style>
      </FormComponent>
    </Modal>
    // </LoadingComponent>
  );
}

export default TraYeuCauModal;
