import { ENUM_DS_YEU_CAU, FUNC_ID_DSYC_TREOTHAO } from "@utils/constants";
import { Button, Col, Form, Input, Modal, Row, Table } from "antd";
import { Fragment, useCallback, useState } from "react";
import DanhSachYeuCau from "../../common-ver1/danh-sach-yeu-cau";
// import CapNhatMaCL from "../../common-ver1/cap-nhat-ma-cl-cong-to";
import { useDispatch, useSelector } from "react-redux";
// import { openModal } from "@state/system-config/reducer";
import CheckPermission from "@components/CheckPermission";
import { treo_thao } from "@permissions/b_treothao";
import PhanCongModal from "../../common-ver1/phan-cong";
import { authSelector } from "@state/auth";
import { alertMessage } from "@utils/function";
import { handleDetailYC } from "@modules/treo-thao/nhan-vien-treo-thao/yeu-cau-duoc-phan-cong/services";
import { callAPIRemoveYC } from "../yc-can-xuat-vat-tu/services";
import { callAPICancelYC } from "./services";

const { TextArea } = Input;

export default function Index() {
  const [fetchList, setFetchList] = useState(false);
  const { user } = useSelector(authSelector);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isTongCongTy, setIsTongCongTy] = useState(
    user.isTongCongTy ? true : false
  );
  const [filterUnit, setFilterUnit] = useState(user.unitName);
  const dispatch = useDispatch();
  const [arrSelectedRows, setArrSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [lyDoHuy, setLyDoHuy] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [dataErrors, setDataErrors] = useState([]);
  const [form] = Form.useForm();
  //button nhận yêu cầu
  const genExtra = () => (
    <Fragment>
      <CheckPermission permissionCode={treo_thao.b23xyc}>
        <Button
          danger
          className="mr-5px"
          disabled={!selectedRowKeys.length}
          onClick={() => handleDeleteYC()}
          loading={loading}
        >
          Hủy yêu cầu
        </Button>
      </CheckPermission>
      <CheckPermission permissionCode={treo_thao.b23tt}>
        <Button
          type="primary"
          className="mr-5px"
          onClick={() => handleOpenModalUpdateInfo(arrSelectedRows)}
          disabled={
            !selectedRowKeys.length ||
            selectedRowKeys.length >= 2 ||
            !arrSelectedRows.filter((el) => el.maTrangThai !== 1).length
          }
        >
          Lập BBTT
        </Button>
      </CheckPermission>
      <CheckPermission permissionCode={treo_thao.b23pc}>
        <Button
          type="primary"
          className="mr-5px"
          onClick={() => handleOpenModal()}
          disabled={isTongCongTy || !selectedRowKeys.length ? true : false}
        >
          Phân công
        </Button>
      </CheckPermission>
      {/* <CheckPermission permissionCode={treo_thao.b23s}>
        <Button
          type="primary"
          onClick={() => handleOpenModalUpdate()}
          disabled={!selectedRowKeys.length}
        >
          Cập nhật chủng loại
        </Button>
      </CheckPermission> */}
    </Fragment>
  );

  const findInArray = (value, arr) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== value) return false;
    }
    return true;
  };

  // const handleChangeLyDo = useCallback((e) => {
  //   setLyDoHuy(e.target.value);
  // }, []);

  const [visibleCancel, setVisibleCancel] = useState(false);
  const handleCloseModalCancel = () => {
    setVisibleCancel(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleDeleteYC = useCallback(() => {
    const arrTrangThai = [];
    arrSelectedRows.forEach((el) => {
      arrTrangThai.push(el.maTrangThai);
    });
    const isDuplicateTrangThai = findInArray(arrTrangThai[0], arrTrangThai);
    if (!isDuplicateTrangThai) {
      alertMessage(
        "warning",
        "Thông báo",
        "Chỉ cho phép hủy/xóa yêu cầu có cùng Trạng thái!"
      );
      setVisibleCancel(false);
    } else {
      if (arrTrangThai[0] === 1) {
        callAPIRemoveYC(
          selectedRowKeys,
          setLoading,
          setFetchList,
          setSelectedRowKeys
        );
        setVisibleCancel(false);
      } else {
        setVisibleCancel(true);
      }
    }
  }, [arrSelectedRows, selectedRowKeys]);

  const onFinish = (value) => {
    const payload = {
      ids: selectedRowKeys,
      lyDoHuy: value.lyDoHuy,
    };
    callAPICancelYC(
      payload,
      setLoading,
      setFetchList,
      setSelectedRowKeys,
      handleCloseModalCancel,
      setShowPopup,
      setDataErrors
    );
  };

  const renderModalCancel = useCallback(() => {
    form.resetFields();

    return (
      visibleCancel && (
        <Modal
          title="Hủy yêu cầu"
          visible={visibleCancel}
          onCancel={handleCloseModalCancel}
          className="modal-bpag-tra-lai"
          footer={null}
          width={"25%"}
        >
          <Form
            form={form}
            name="form-cancel"
            layout="vertical"
            onFinish={onFinish}
          >
            <Row gutter={24}>
              <Col span={24}>
                <h4>Bạn có chắc rằng muốn hủy yêu cầu này?</h4>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="lyDoHuy"
                  label="Lý do hủy"
                  rules={[
                    {
                      required: true,
                      message: "Lý do hủy không được để trống",
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea rows={3} />
                </Form.Item>
              </Col>
            </Row>
            <br />
            <Row gutter={24} justify="center">
              <Button
                key="back"
                onClick={handleCloseModalCancel}
                className="button-closed mr-24px"
              >
                Đóng lại
              </Button>
              <Button
                key="submit"
                type="primary"
                className="button-primary mr-24px"
                htmlType="submit"
                form="form-cancel"
              >
                Hủy
              </Button>
            </Row>
          </Form>
        </Modal>
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, visibleCancel]);

  const renderPopup = useCallback(() => {
    return (
      showPopup && (
        <Modal
          title="Danh sách yêu cầu lỗi"
          visible={visibleCancel}
          onCancel={handleClosePopup}
          footer={null}
          width={"25%"}
        >
          <h3>
            Số yêu cầu thành công:
            <span
              className="total-success"
              style={{ color: "#52c41a", fontWeight: "bold" }}
            >
              {" "}
              {dataErrors?.soYeuCauSuccess}
            </span>
          </h3>
          <h3>
            Số yêu cầu thất bại:
            <span
              className="total-fail"
              style={{ color: "#ff8e8f", fontWeight: "bold" }}
            >
              {" "}
              {dataErrors?.soYeuCauError}
            </span>
          </h3>
          <Table
            scroll={{ y: 300 }}
            dataSource={dataErrors.yeuCauErrors}
            columns={[
              {
                title: "STT",
                dataIndex: "stt",
                key: "stt",
                width: "10%",
                // render: (text, record, index) => (
                //   <div className="table-text-center">{index + 1}</div>
                // ),
              },
              {
                title: "Mã điểm đo",
                dataIndex: "maDiemDo",
                key: "maDiemDo",
                width: "30%",
              },
              {
                title: "Ghi chú",
                dataIndex: "errorMessage",
                key: "errorMessage",
                width: "60%",
              },
            ]}
          />
          <div
            style={{
              textAlign: "center",
              paddingTop: "24px",
            }}
          >
            <Button
              key="back"
              type="default"
              onClick={() => handleClosePopup()}
            >
              Đóng lại
            </Button>
          </div>
        </Modal>
      )
    );
  }, [
    dataErrors?.soYeuCauError,
    dataErrors?.soYeuCauSuccess,
    dataErrors.yeuCauErrors,
    showPopup,
    visibleCancel,
  ]);

  const handleOpenModalUpdateInfo = () => {
    handleDetailYC(
      setLoading,
      loading,
      dispatch,
      setFetchList,
      selectedRowKeys,
      setSelectedRowKeys
    );
  };

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    //Lấy giá trị key đã chọn
    setSelectedRowKeys(selectedRowKeys);
    // //Lấy giá trị các dòng được chọn
    setArrSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  // //show modal cập nhật CL
  // const handleOpenModalUpdate = () => {
  //   return dispatch(
  //     openModal({
  //       content: (
  //         <CapNhatMaCL
  //           setFetchList={setFetchList}
  //           selectedRowKeys={selectedRowKeys}
  //           setSelectedRowKeys={setSelectedRowKeys}
  //           funcId={FUNC_ID_DSYC_TREOTHAO.DOITRUONG_DAXUATVT}
  //         />
  //       ),
  //       size: "large",
  //       title: `Cập nhật chủng loại`,
  //     })
  //   );
  // };

  const handleOpenModal = useCallback(() => {
    let isDoi = false;
    const arrTT = [];
    const arrDoi = [];
    // eslint-disable-next-line array-callback-return
    arrSelectedRows.map((item) => {
      arrDoi.push(item.doiId);
      arrTT.push(item.maTrangThai);
      if (item.doiId === "" || item.doiId === undefined || item.doiId === null)
        isDoi = true;
    });

    const isDuplicate = findInArray(arrDoi[0], arrDoi);
    const isDupTT = findInArray(arrTT[0], arrTT);
    if (isDoi) {
      alertMessage(
        "warning",
        "Thông báo",
        "Có yêu cầu chưa xác định Đội. Hãy xác định Đội trước khi tạo phiếu xuất vật tư!"
      );
    } else if (!isDuplicate) {
      alertMessage(
        "warning",
        "Thông báo",
        "Chỉ cho phép phân công các yêu cầu có cùng tổ đội!"
      );
    } else if (!isDupTT) {
      alertMessage(
        "warning",
        "Thông báo",
        "Chỉ được phép phân công cho các yêu cầu có cùng trạng thái!"
      );
    } else if (arrTT[0] === 1) {
      Modal.confirm({
        centered: true,
        title: "Xác nhận",
        content:
          "Đang thực hiện phân công cho yêu cầu chưa tạo phiếu xuất vật tư, bạn có chắc chắn muốn tiếp tục?",
        onOk() {
          setVisible(!visible);
        },
        onCancel() {},
        okText: "Đồng ý",
        okButtonProps: { type: "primary" },
        cancelText: "Hủy",
      });
    } else {
      setVisible(!visible);
    }
  }, [arrSelectedRows, visible]);

  const handleCloseModal = () => {
    setVisible(false);
  };

  const showModal = useCallback(() => {
    return (
      visible && (
        <PhanCongModal
          visible={visible}
          closeModal={handleCloseModal}
          selectedRowKeys={selectedRowKeys}
          setFetchList={setFetchList}
          filterUnit={filterUnit}
          setSelectedRowKeys={setSelectedRowKeys}
          funcId={FUNC_ID_DSYC_TREOTHAO.DOITRUONG_DAXUATVT}
        />
      )
    );
  }, [filterUnit, selectedRowKeys, visible]);

  return (
    <Fragment>
      <DanhSachYeuCau
        funcId={FUNC_ID_DSYC_TREOTHAO.DOITRUONG_DAXUATVT}
        typeList={ENUM_DS_YEU_CAU.DOITRUONG_YEUCAUDAXUATVATTU}
        genExtra={genExtra}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        rowSelection={rowSelection}
        setIsTongCongTy={setIsTongCongTy}
        setFilterUnit={setFilterUnit}
        setSelectedRowKeys={setSelectedRowKeys}
      />
      {showModal()}
      {renderModalCancel()}
      {renderPopup()}
    </Fragment>
  );
}
