import {
  COLUMNS_TITLE,
  // DEFAULT_PAGEINDEX,
  // DEFAULT_PAGESIZE,
  FORMAT_MONTH_ENGLISH,
  NAVIGATE_BAOCAOTONGHOP,
  NAVIGATE_DICHVUDIEN,
  NAVIGATE_DICHVUDIEN_BAOCAO,
  INDEX_TAB2,
  TYPE_TRANGTHAIBAOCAO_TONGHOP,
  ENUM_BUTTON_TYPE,
} from "@utils/constants";
import {
  buildQueryString,
  formatSplitDate,
  stringToSlug,
} from "@utils/function";
import { Table, Tooltip, Typography } from "antd";
import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import BaoCaoTongHop from "./layout";
import { handleMultiBoLoc } from "../../services";
import { TableFoot } from "../../css-styled";

const { Text } = Typography;

export default function Index(props) {
  const {
    setKeyTabs,
    filterConditions,
    setFilterConditions,
    user,
    totalCount,
    loading,
    onChangePagination,
    data,
    setRedirect,
    isCompany,
    month,
    year,
    quy,
    getListBaoCao,
    setIsSubmit,
    type,
    setFilterExcel,
    setType,
  } = props;
  const [, setLoading] = useState(false);
  const [donVi, setDonVi] = useState([]);
  const [loaiYeuCau, setLoaiYeuCau] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [disabledDaChot, setDisabledDaChot] = useState(false);
  const [disabledHuyChot, setDisabledHuyChot] = useState(false);
  //columns
  const columns = [
    {
      title: isCompany ? "Đơn vị" : "Loại yêu cầu",
      dataIndex: "maDonViOrLoaiYeuCau",
      key: "maDonViOrLoaiYeuCau",
      width: isCompany ? 120 : 150,
      rowSpan: 2,
      render: (text) => <div className="table-text-left">{text}</div>,
      fixed: "left",
      sorter: true,
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      width: 150,
      rowSpan: 2,
      fixed: "left",
      render: (text) => (
        <div className="table-text-center">
          {stringToSlug(text) === TYPE_TRANGTHAIBAOCAO_TONGHOP.DACHOT ? (
            <Tooltip title="Số liệu của tất cả loại yêu cầu đã được chốt">
              <span style={{ color: "#23c16b" }}>{text}</span>
            </Tooltip>
          ) : stringToSlug(text) === TYPE_TRANGTHAIBAOCAO_TONGHOP.CHUACHOT ? (
            <Tooltip title="Chưa có loại yêu cầu nào được chốt số liệu">
              <span style={{ color: "#ff5247" }}>{text}</span>
            </Tooltip>
          ) : (
            <Tooltip title="Có loại yêu cầu đã chốt và loại yêu cầu chưa chốt">
              <span style={{ color: "#6e8cc3" }}>{text}</span>
            </Tooltip>
          )}
        </div>
      ),
      sorter: true,
    },
    {
      title: month,
      dataIndex: "",
      key: "",
      className: "month-columns",
      children: [
        {
          title: (
            <Tooltip
              placement="top"
              title="Là số yêu cầu đơn vị nhận trong tháng, không tính những bộ không có mã khiếu nại, không tính những bộ nâng công suất"
            >
              Số Y/C đã nhận
            </Tooltip>
          ),
          dataIndex: "soYeuCauNhanThang",
          key: "soYeuCauNhanThang",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Là số yêu cầu tồn từ giai đoạn trước tính đến thời điểm báo cáo (bao gồm cả những bộ tồn nhưng đã giải quyết trong tháng này), không tính những bộ không có mã khiếu nại, không tính những bộ nâng công suất"
            >
              Số Y/C còn tồn giai đoạn trước
            </Tooltip>
          ),
          dataIndex: "soYeuCauTonThang",
          key: "soYeuCauTonThang",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Những yêu cầu tồn, đã nhận nhưng chưa kết thúc tính đến thời điểm báo cáo"
            >
              Số đang giải quyết
            </Tooltip>
          ),
          dataIndex: "dangGiaiQuyetThang",
          key: "dangGiaiQuyetThang",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Những yêu cầu được hủy trong tháng, tính đến thời điểm báo cáo"
            >
              Số không đủ đ.kiện cấp điện
            </Tooltip>
          ),
          dataIndex: "khongDuCapYeuCauDienThang",
          key: "khongDuCapYeuCauDienThang",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip placement="top" title="Tổng số bộ đã giải quyết">
              Tổng số đã giải quyết
            </Tooltip>
          ),
          dataIndex: "tongDaGiaiQuyetThang",
          key: "tongDaGiaiQuyetThang",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="
          Là những bộ thuộc thành thị, hoàn thành đúng tiến độ"
            >
              Số đã giải quyết ≤ 3 ngày
            </Tooltip>
          ),
          dataIndex: "giaiQuyetNhoHon3Thang",
          key: "giaiQuyetNhoHon3Thang",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="
          Là những bộ thuộc thành thị, hoàn thành đúng tiến độ"
            >
              Số đã giải quyết trên 3 ngày
            </Tooltip>
          ),
          dataIndex: "giaiQuyetTren3Thang",
          key: "giaiQuyetTren3Thang",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Là những bộ thuộc nông thôn, hoàn thành đúng tiến độ"
            >
              Số đã giải quyết ≤ 5 ngày
            </Tooltip>
          ),
          dataIndex: "giaiQuyetNhoHon5Thang",
          key: "giaiQuyetNhoHon5Thang",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Là những bộ thuộc nông thôn, hoàn thành chậm tiến độ"
            >
              Số đã giải quyết trên 5 ngày
            </Tooltip>
          ),
          dataIndex: "giaiQuyetTren5Thang",
          key: "giaiQuyetTren5Thang",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Là những bộ thuộc nông thôn, hoàn thành đúng tiến độ"
            >
              Số đã giải quyết ≤ 7 ngày
            </Tooltip>
          ),
          dataIndex: "giaiQuyetNhoHon7Thang",
          key: "giaiQuyetNhoHon7Thang",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Là những bộ thuộc nông thôn, hoàn thành chậm tiến độ"
            >
              Số đã giải quyết trên 7 ngày
            </Tooltip>
          ),
          dataIndex: "giaiQuyetTren7Thang",
          key: "giaiQuyetTren7Thang",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
      ],
    },
    {
      title: quy,
      dataIndex: "",
      key: "",
      className: "quy-columns",
      children: [
        {
          title: (
            <Tooltip
              placement="top"
              title="Là số yêu cầu đơn vị nhận trong tháng, không tính những bộ không có mã khiếu nại, không tính những bộ nâng công suất"
            >
              Số Y/C đã nhận
            </Tooltip>
          ),
          dataIndex: "soYeuCauNhanQuy",
          key: "soYeuCauNhanQuy",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Là số yêu cầu tồn từ giai đoạn trước tính đến thời điểm báo cáo (bao gồm cả những bộ tồn nhưng đã giải quyết trong tháng này), không tính những bộ không có mã khiếu nại, không tính những bộ nâng công suất"
            >
              Số Y/C còn tồn giai đoạn trước
            </Tooltip>
          ),
          dataIndex: "soYeuCauTonQuy",
          key: "soYeuCauTonQuy",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Những yêu cầu tồn, đã nhận nhưng chưa kết thúc tính đến thời điểm báo cáo"
            >
              Số đang giải quyết
            </Tooltip>
          ),
          dataIndex: "dangGiaiQuyetQuy",
          key: "dangGiaiQuyetQuy",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Những yêu cầu được hủy trong tháng, tính đến thời điểm báo cáo"
            >
              Số không đủ đ.kiện cấp điện
            </Tooltip>
          ),
          dataIndex: "khongDuCapYeuCauDienQuy",
          key: "khongDuCapYeuCauDienQuy",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip placement="top" title="Tổng số bộ đã giải quyết">
              Tổng số đã giải quyết
            </Tooltip>
          ),
          dataIndex: "tongDaGiaiQuyetQuy",
          key: "tongDaGiaiQuyetQuy",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="
          Là những bộ thuộc thành thị, hoàn thành đúng tiến độ"
            >
              Số đã giải quyết ≤ 3 ngày
            </Tooltip>
          ),
          dataIndex: "giaiQuyetNhoHon3Quy",
          key: "giaiQuyetNhoHon3Quy",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="
          Là những bộ thuộc thành thị, hoàn thành đúng tiến độ"
            >
              Số đã giải quyết trên 3 ngày
            </Tooltip>
          ),
          dataIndex: "giaiQuyetTren3Quy",
          key: "giaiQuyetTren3Quy",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Là những bộ thuộc nông thôn, hoàn thành đúng tiến độ"
            >
              Số đã giải quyết ≤ 5 ngày
            </Tooltip>
          ),
          dataIndex: "giaiQuyetNhoHon5Quy",
          key: "giaiQuyetNhoHon5Quy",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Là những bộ thuộc nông thôn, hoàn thành chậm tiến độ"
            >
              Số đã giải quyết trên 5 ngày
            </Tooltip>
          ),
          dataIndex: "giaiQuyetTren5Quy",
          key: "giaiQuyetTren5Quy",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Là những bộ thuộc nông thôn, hoàn thành đúng tiến độ"
            >
              Số đã giải quyết ≤ 7 ngày
            </Tooltip>
          ),
          dataIndex: "giaiQuyetNhoHon7Quy",
          key: "giaiQuyetNhoHon7Quy",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Là những bộ thuộc nông thôn, hoàn thành chậm tiến độ"
            >
              Số đã giải quyết trên 7 ngày
            </Tooltip>
          ),
          dataIndex: "giaiQuyetTren7Quy",
          key: "giaiQuyetTren7Quy",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
      ],
    },
    {
      title: year,
      dataIndex: "",
      key: "",
      className: "year-columns",
      children: [
        {
          title: (
            <Tooltip
              placement="top"
              title="Là số yêu cầu đơn vị nhận trong tháng, không tính những bộ không có mã khiếu nại, không tính những bộ nâng công suất"
            >
              Số Y/C đã nhận
            </Tooltip>
          ),
          dataIndex: "soYeuCauNhanNam",
          key: "soYeuCauNhanNam",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Là số yêu cầu tồn từ giai đoạn trước tính đến thời điểm báo cáo (bao gồm cả những bộ tồn nhưng đã giải quyết trong tháng này), không tính những bộ không có mã khiếu nại, không tính những bộ nâng công suất"
            >
              Số Y/C còn tồn giai đoạn trước
            </Tooltip>
          ),
          dataIndex: "soYeuCauTonNam",
          key: "soYeuCauTonNam",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Những yêu cầu tồn, đã nhận nhưng chưa kết thúc tính đến thời điểm báo cáo"
            >
              Số đang giải quyết
            </Tooltip>
          ),
          dataIndex: "dangGiaiQuyetNam",
          key: "dangGiaiQuyetNam",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Những yêu cầu được hủy trong tháng, tính đến thời điểm báo cáo"
            >
              Số không đủ đ.kiện cấp điện
            </Tooltip>
          ),
          dataIndex: "khongDuCapYeuCauDienNam",
          key: "khongDuCapYeuCauDienNam",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip placement="top" title="Tổng số bộ đã giải quyết">
              Tổng số đã giải quyết
            </Tooltip>
          ),
          dataIndex: "tongDaGiaiQuyetNam",
          key: "tongDaGiaiQuyetNam",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="
          Là những bộ thuộc thành thị, hoàn thành đúng tiến độ"
            >
              Số đã giải quyết ≤ 3 ngày
            </Tooltip>
          ),
          dataIndex: "giaiQuyetNhoHon3Nam",
          key: "giaiQuyetNhoHon3Nam",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="
          Là những bộ thuộc thành thị, hoàn thành đúng tiến độ"
            >
              Số đã giải quyết trên 3 ngày
            </Tooltip>
          ),
          dataIndex: "giaiQuyetTren3Nam",
          key: "giaiQuyetTren3Nam",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Là những bộ thuộc nông thôn, hoàn thành đúng tiến độ"
            >
              Số đã giải quyết ≤ 5 ngày
            </Tooltip>
          ),
          dataIndex: "giaiQuyetNhoHon5Nam",
          key: "giaiQuyetNhoHon5Nam",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Là những bộ thuộc nông thôn, hoàn thành chậm tiến độ"
            >
              Số đã giải quyết trên 5 ngày
            </Tooltip>
          ),
          dataIndex: "giaiQuyetTren5Nam",
          key: "giaiQuyetTren5Nam",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Là những bộ thuộc nông thôn, hoàn thành đúng tiến độ"
            >
              Số đã giải quyết ≤ 7 ngày
            </Tooltip>
          ),
          dataIndex: "giaiQuyetNhoHon7Nam",
          key: "giaiQuyetNhoHon7Nam",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
        {
          title: (
            <Tooltip
              placement="top"
              title="Là những bộ thuộc nông thôn, hoàn thành chậm tiến độ"
            >
              Số đã giải quyết trên 7 ngày
            </Tooltip>
          ),
          dataIndex: "giaiQuyetTren7Nam",
          key: "giaiQuyetTren7Nam",
          width: 100,
          render: (text) => <div className="table-text-right">{text}</div>,
          sorter: true,
        },
      ],
    },
    {
      title: "Ngày tạo",
      dataIndex: "ngayTao",
      key: "ngayTao",
      width: 100,
      rowSpan: 2,
      render: (text) => <div className="table-text-center">{text}</div>,
      sorter: true,
    },
  ];

  //choice row table
  const onSelectChange = (selectedRowKeys) => {
    let chot = false;
    let huyChot = false;
    data.map((item) => {
      if (selectedRowKeys.includes(item.id)) {
        if (
          stringToSlug(item.trangThai) ===
            TYPE_TRANGTHAIBAOCAO_TONGHOP.DACHOT ||
          stringToSlug(item.trangThai) ===
            TYPE_TRANGTHAIBAOCAO_TONGHOP.CHOTMOTPHAN
        )
          return (chot = true);
        if (
          stringToSlug(item.trangThai) === TYPE_TRANGTHAIBAOCAO_TONGHOP.CHUACHOT
        )
          return (huyChot = true);
      }

      return true;
    });
    setDisabledDaChot(chot);
    setDisabledHuyChot(huyChot);

    setSelectedRowKeys(selectedRowKeys);
  };
  const [newColumns, setNewColums] = useState();

  //fetch title columns
  useEffect(() => {
    columns[2].title = month;
    columns[3].title = quy;
    columns[4].title = year;
    if (isCompany) delete columns[5];
    setNewColums(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompany, month, quy, year]);

  //footer total count table
  const countTotals = (pageData) => {
    let ycDaNhanThang = 0;
    let ycConTonThang = 0;
    let soDangGQThang = 0;
    let soKhongDuDKThang = 0;
    let tongDaGQThang = 0;
    let soBeBang3Thang = 0;
    let soLon3Thang = 0;
    let soBeBang5Thang = 0;
    let soLon5Thang = 0;
    let soBeBang7Thang = 0;
    let soLon7Thang = 0;

    let ycDaNhanQuy = 0;
    let ycConTonQuy = 0;
    let soDangGQQuy = 0;
    let soKhongDuDKQuy = 0;
    let tongDaGQQuy = 0;
    let soBeBang3Quy = 0;
    let soLon3Quy = 0;
    let soBeBang5Quy = 0;
    let soLon5Quy = 0;
    let soBeBang7Quy = 0;
    let soLon7Quy = 0;

    let ycDaNhanNam = 0;
    let ycConTonNam = 0;
    let soDangGQNam = 0;
    let soKhongDuDKNam = 0;
    let tongDaGQNam = 0;
    let soBeBang3Nam = 0;
    let soLon3Nam = 0;
    let soBeBang5Nam = 0;
    let soLon5Nam = 0;
    let soBeBang7Nam = 0;
    let soLon7Nam = 0;

    pageData.forEach(
      ({
        soYeuCauNhanThang,
        soYeuCauTonThang,
        dangGiaiQuyetThang,
        khongDuCapYeuCauDienThang,
        tongDaGiaiQuyetThang,
        giaiQuyetNhoHon3Thang,
        giaiQuyetTren3Thang,
        giaiQuyetNhoHon5Thang,
        giaiQuyetTren5Thang,
        giaiQuyetNhoHon7Thang,
        giaiQuyetTren7Thang,

        soYeuCauNhanQuy,
        soYeuCauTonQuy,
        dangGiaiQuyetQuy,
        khongDuCapYeuCauDienQuy,
        tongDaGiaiQuyetQuy,
        giaiQuyetNhoHon3Quy,
        giaiQuyetTren3Quy,
        giaiQuyetNhoHon5Quy,
        giaiQuyetTren5Quy,
        giaiQuyetNhoHon7Quy,
        giaiQuyetTren7Quy,

        soYeuCauNhanNam,
        soYeuCauTonNam,
        dangGiaiQuyetNam,
        khongDuCapYeuCauDienNam,
        tongDaGiaiQuyetNam,
        giaiQuyetNhoHon3Nam,
        giaiQuyetTren3Nam,
        giaiQuyetNhoHon5Nam,
        giaiQuyetTren5Nam,
        giaiQuyetNhoHon7Nam,
        giaiQuyetTren7Nam,
      }) => {
        ycDaNhanThang += soYeuCauNhanThang;
        ycConTonThang += soYeuCauTonThang;
        soDangGQThang += dangGiaiQuyetThang;
        soKhongDuDKThang += khongDuCapYeuCauDienThang;
        tongDaGQThang += tongDaGiaiQuyetThang;
        soBeBang3Thang += giaiQuyetNhoHon3Thang;
        soLon3Thang += giaiQuyetTren3Thang;
        soBeBang5Thang += giaiQuyetNhoHon5Thang;
        soLon5Thang += giaiQuyetTren5Thang;
        soBeBang7Thang += giaiQuyetNhoHon7Thang;
        soLon7Thang += giaiQuyetTren7Thang;

        ycDaNhanQuy += soYeuCauNhanQuy;
        ycConTonQuy += soYeuCauTonQuy;
        soDangGQQuy += dangGiaiQuyetQuy;
        soKhongDuDKQuy += khongDuCapYeuCauDienQuy;
        tongDaGQQuy += tongDaGiaiQuyetQuy;
        soBeBang3Quy += giaiQuyetNhoHon3Quy;
        soLon3Quy += giaiQuyetTren3Quy;
        soBeBang5Quy += giaiQuyetNhoHon5Quy;
        soLon5Quy += giaiQuyetTren5Quy;
        soBeBang7Quy += giaiQuyetNhoHon7Quy;
        soLon7Quy += giaiQuyetTren7Quy;

        ycDaNhanNam += soYeuCauNhanNam;
        ycConTonNam += soYeuCauTonNam;
        soDangGQNam += dangGiaiQuyetNam;
        soKhongDuDKNam += khongDuCapYeuCauDienNam;
        tongDaGQNam += tongDaGiaiQuyetNam;
        soBeBang3Nam += giaiQuyetNhoHon3Nam;
        soLon3Nam += giaiQuyetTren3Nam;
        soBeBang5Nam += giaiQuyetNhoHon5Nam;
        soLon5Nam += giaiQuyetTren5Nam;
        soBeBang7Nam += giaiQuyetNhoHon7Nam;
        soLon7Nam += giaiQuyetTren7Nam;
      }
    );
    return (
      <Table.Summary fixed>
        <TableFoot style={{ backgroundColor: "#FFEAEA" }}>
          <Table.Summary.Cell
            align="center"
            colSpan={3}
            index={0}
            style={{ zIndex: 9999 }}
          >
            <Text className="font-bold">Tổng cộng</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={1}>
            <Text className="font-bold">{ycDaNhanThang}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={2}>
            <Text className="font-bold">{ycConTonThang}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={3}>
            <Text className="font-bold">{soDangGQThang}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={4}>
            <Text className="font-bold">{soKhongDuDKThang}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={5}>
            <Text className="font-bold">{tongDaGQThang}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={6}>
            <Text className="font-bold">{soBeBang3Thang}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={7}>
            <Text className="font-bold">{soLon3Thang}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={8}>
            <Text className="font-bold">{soBeBang5Thang}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={9}>
            <Text className="font-bold">{soLon5Thang}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={10}>
            <Text className="font-bold">{soBeBang7Thang}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={11}>
            <Text className="font-bold">{soLon7Thang}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={12}>
            <Text className="font-bold">{ycDaNhanQuy}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={13}>
            <Text className="font-bold">{ycConTonQuy}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={14}>
            <Text className="font-bold">{soDangGQQuy}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={15}>
            <Text className="font-bold">{soKhongDuDKQuy}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={16}>
            <Text className="font-bold">{tongDaGQQuy}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={17}>
            <Text className="font-bold">{soBeBang3Quy}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={18}>
            <Text className="font-bold">{soLon3Quy}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={19}>
            <Text className="font-bold">{soBeBang5Quy}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={20}>
            <Text className="font-bold">{soLon5Quy}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={21}>
            <Text className="font-bold">{soBeBang7Quy}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={22}>
            <Text className="font-bold">{soLon7Quy}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={23}>
            <Text className="font-bold">{ycDaNhanNam}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={24}>
            <Text className="font-bold">{ycConTonNam}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={25}>
            <Text className="font-bold">{soDangGQNam}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={26}>
            <Text className="font-bold">{soKhongDuDKNam}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={27}>
            <Text className="font-bold">{tongDaGQNam}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={28}>
            <Text className="font-bold">{soBeBang3Nam}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={29}>
            <Text className="font-bold">{soLon3Nam}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={30}>
            <Text className="font-bold">{soBeBang5Nam}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={31}>
            <Text className="font-bold">{soLon5Nam}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={32}>
            <Text className="font-bold">{soBeBang7Nam}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={33}>
            <Text className="font-bold">{soLon7Nam}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={34}>
            <Text className="font-bold">{""}</Text>
          </Table.Summary.Cell>
        </TableFoot>
      </Table.Summary>
    );
  };
  const navigate = useNavigate();

  //redirect báo cáo chi tiết
  const handleBaoCaoChiTiet = useCallback(
    (loaiHoSo, values) => {
      setKeyTabs(INDEX_TAB2);
      const filters = {
        ...filterConditions,
        ngayBaoCao: values.ngayBaoCao
          ? moment(values.ngayBaoCao).format(FORMAT_MONTH_ENGLISH)
          : moment().format(FORMAT_MONTH_ENGLISH),
        loaiBaoCao: 1,
        loaiHoSo: loaiHoSo,
      };
      navigate(
        NAVIGATE_DICHVUDIEN +
          NAVIGATE_DICHVUDIEN_BAOCAO +
          NAVIGATE_BAOCAOTONGHOP +
          "?" +
          buildQueryString(filters)
      );
      setRedirect(true);
    },
    [filterConditions, navigate, setKeyTabs, setRedirect]
  );

  //thay đổi columns table if isTongCongTy
  const handleChangeColumns = useCallback(
    (isTongCongTy) => {
      const record = {
        title: "Ngày tạo",
        dataIndex: "ngayTao",
        key: "ngayTao",
        width: 100,
        rowSpan: 2,
        render: (text) => <div className="table-text-center">{text}</div>,
        sorter: true,
      };

      const columns = [...newColumns];
      if (!isTongCongTy) {
        columns[0].title = COLUMNS_TITLE.LOAIYEUCAU;
        columns[5] = record;
      } else {
        columns[0].title = COLUMNS_TITLE.DONVI;
        delete columns[5];
      }
      setNewColums(columns);
    },
    [newColumns]
  );

  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type, setFilterExcel]);

  //search
  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        // pageIndex: DEFAULT_PAGEINDEX,
        ...values,
        donViId: values.donViId,
        ngayBaoCao: formatSplitDate(values.ngayBaoCao, true),
      }));
      donVi.map((item) => {
        if ([values.donViId].includes(item.id))
          handleChangeColumns(item.isTongCongTy);
        return true;
      });
      setSelectedRowKeys([]);
      setDisabledDaChot(false);
      setDisabledHuyChot(false);
      setIsSubmit(true);
      handleAPI();
    },
    [donVi, handleChangeColumns, setFilterConditions, setIsSubmit, handleAPI]
  );

  //clear search
  const clearFilter = useCallback(() => {
    setFilterConditions({
      // pageIndex: DEFAULT_PAGEINDEX,
      // pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
    });

    handleChangeColumns(user.isTongCongTy);
    setSelectedRowKeys([]);
    setDisabledDaChot(false);
    setDisabledHuyChot(false);
    setIsSubmit(true);
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  }, [
    handleChangeColumns,
    setFilterConditions,
    setIsSubmit,
    user.isTongCongTy,
    user.unitId,
    setType,
  ]);

  useEffect(() => {
    handleMultiBoLoc(setLoading, setDonVi, setLoaiYeuCau);
  }, []);

  return (
    <BaoCaoTongHop
      handleSearch={handleSearch}
      clearFilter={clearFilter}
      filterConditions={filterConditions}
      handleBaoCaoChiTiet={handleBaoCaoChiTiet}
      totalCount={totalCount}
      loading={loading}
      onChangePagination={onChangePagination}
      columns={newColumns}
      data={data}
      countTotals={countTotals}
      donVi={donVi}
      rowSelection={{
        selectedRowKeys,
        onChange: onSelectChange,
      }}
      selectedRowKeys={selectedRowKeys}
      isCompany={isCompany}
      getListBaoCao={getListBaoCao}
      setSelectedRowKeys={setSelectedRowKeys}
      disabledDaChot={disabledDaChot}
      disabledHuyChot={disabledHuyChot}
      loaiYeuCau={loaiYeuCau}
      type={type}
      setFilterExcel={setFilterExcel}
      setType={setType}
    />
  );
}
