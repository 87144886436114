import React from "react";
import { Button, Modal, Row, Col, Form, Checkbox, Skeleton } from "antd";
import FormComponent from "@components/Form";
import { ContainerModal } from "../css-styled";
import InputFormatter from "@components/InputFormatter";
import { TYPE_INPUT } from "@utils/constants";
export default function XacNhanThuTienNVKSModal(props) {
  const {
    loading,
    visibleXacNhan,
    t,
    handleCloseModalXacNhan,
    form,
    onFinish,
    handleChange,
    content,
    loadingConfirm,
  } = props;

  return (
    <Modal
      title={"Xác nhận thu tiền"}
      visible={visibleXacNhan}
      onCancel={handleCloseModalXacNhan}
      footer={null}
    >
      <FormComponent
        autoComplete="off"
        form={form}
        name="form-nghiem-thu"
        initialValues={{}}
        layout="vertical"
        onFinish={onFinish}
      >
        <ContainerModal>
          <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
            Hồ sơ của bạn đang ở Phòng tài chính. Xác nhận sẽ chuyển tiếp cho
            Phòng vật tư !
          </h4>
          <Row gutter={24}>
            <Col span={24} className="mt-8px">
              <Form.Item label="Xác nhận tiền phải đóng" name="soTien">
                <InputFormatter
                  style={{
                    width: "100%",
                    backgroundColor: "#e2e2e2",
                    textAlign: "end",
                  }}
                  controls={false}
                  readOnly={true}
                  type={TYPE_INPUT.DECIMAL}
                />
              </Form.Item>
            </Col>
          </Row>
          {content?.noiDungChuongTrinhTriAn ? (
            <Row>
              <Col span={24} md={2}>
                <Form.Item name="checkMoney">
                  <Checkbox onChange={handleChange} />
                </Form.Item>
              </Col>
              <Col span={24} md={22} style={{ marginBottom: 20 }}>
                <Skeleton active title={false} loading={loading}>
                  <h4 className="fs-14px">
                    {content?.noiDungChuongTrinhTriAn}
                  </h4>
                </Skeleton>
              </Col>
            </Row>
          ) : null}
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button
                key="back"
                onClick={handleCloseModalXacNhan}
                className="button-closed"
              >
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-nghiem-thu"
                loading={loading || loadingConfirm}
              >
                {t("button.xac_nhan")}
              </Button>
            </Col>
          </Row>
        </ContainerModal>
      </FormComponent>
    </Modal>
  );
}
