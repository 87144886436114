import { Fragment, Suspense, useCallback, useMemo, useState } from "react";
import PDFModal from "@modules/cap-dien/common-ho-so/pdf-viewer";
import { handleGetFileBBLD } from "@modules/cap-dien/thi-cong/lap-bbld-duong-day/modals/popup-bien-ban/services";
import { alertMessage } from "@utils/function";
import { Menu } from "antd";

export default function XemLaiBBLapDatComponent({ idHoSo }) {
  const [visibleInBBLD, setVisibleInBBLD] = useState(false);
  const [loadingBBLD, setLoadingBBLD] = useState(false);

  const showModalInBienBanLapDat = useMemo(
    () =>
      visibleInBBLD && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visibleInBBLD}
            setVisible={setVisibleInBBLD}
            title="Xem trước in biên bản lắp đặt"
            getBienBan={(setUrl) => setUrlBBLapDat(setUrl)}
            loadingGetBienBan={loadingBBLD}
            isDownloadExcel={false}
            isReload={false}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visibleInBBLD]
  );

  const setUrlBBLapDat = useCallback(
    (setUrl) => {
      handleGetFileBBLD(idHoSo, setLoadingBBLD, (file) => {
        const blob = base64toBlob(file);
        const url = URL.createObjectURL(blob);
        setUrl(url);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idHoSo]
  );

  const base64toBlob = (data) => {
    try {
      const base64WithoutPrefix = data.substr(
        "data:application/pdf;base64,".length
      );
      const bytes = atob(base64WithoutPrefix);
      let length = bytes.length;
      const out = new Uint8Array(length);

      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      return new Blob([out], { type: "application/pdf" });
    } catch (error) {
      alertMessage("error", "Thông báo", "Định dạng file không hỗ trợ!");
    }
  };
  return (
    <Fragment>
      <Menu.Item eventKey="48" onClick={() => setVisibleInBBLD(true)}>
        Xem lại biên bản lắp đặt
      </Menu.Item>
      {showModalInBienBanLapDat}
    </Fragment>
  );
}
