import styled from "styled-components";
import { Tabs, Table } from "antd";

export const ContainerTable = styled.section`
  overflow: hidden;
  position: relative;
  overflow-x: auto;

  table {
    display: block;
  }

  thead,
  tbody {
    display: block;
  }

  tbody {
    height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  tr {
    /* display: table; */
    border-collapse: collapse;
    /* width: 75vw; */
    table-layout: fixed;
  }
`;

export const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;

  .month-columns {
    background: #fef2cb !important;
  }

  .year-columns {
    background: #e2efd9 !important;
  }
`;

export const TabsContainer = styled(Tabs)`
  .ant-tabs-nav-wrap {
    padding: 0 16px;
  }
`;

export const TableFoot = styled(Table.Summary.Row)`
  td:first-child {
    z-index: 9999;
  }
`;
