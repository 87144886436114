import { Collapse } from "antd";
import { Fragment, useState } from "react";
import ThongTinThao from "./thong-tin-thao";
import ThongTinTreo from "./thong-tin-treo";

const { Panel } = Collapse;

export default function Index(props) {
  const {
    detailCTTreo,
    detailCTThao,
    form,
    isResetIsThao,
    setIsResetIsThao,
    setIsResetIsTUTI,
    thongTinHienTai,
    defaultCTTreo,
    thongTinChung,
    setDataDetaiCTTreolUpdate,
    setChangeTab2,
    setChiSoMoiMax,
  } = props;
  const [maCongToTreo, setMaCongToTreo] = useState(detailCTTreo.maCongTo);
  const genExtraThao = () => (
    <div>
      Mã công tơ
      <span style={{ marginLeft: "12px", color: "#0000FF" }}>
        {detailCTThao.maCongTo}
      </span>
    </div>
  );

  const genExtraTreo = () => (
    <div>
      Mã công tơ
      <span style={{ marginLeft: "12px", color: "#0000FF" }}>
        {maCongToTreo}
      </span>
    </div>
  );

  return (
    <Fragment>
      <Collapse defaultActiveKey={["1"]} style={{ marginTop: "12px" }}>
        <Panel header="Thông tin công tơ THÁO" key="1" extra={genExtraThao()}>
          <ThongTinThao
            detail={detailCTThao}
            form={form}
            isResetIsThao={isResetIsThao}
            setIsResetIsThao={setIsResetIsThao}
            setIsResetIsTUTI={setIsResetIsTUTI}
            setChangeTab2={setChangeTab2}
          />
        </Panel>
        {
          // form.getFieldValue("isTreoThaoTUTI") ||
          form.getFieldValue("isThaoCongTo") ||
          form.getFieldValue("loaiBienBan") === 3 ||
          form.getFieldValue("loaiBienBan") === "3" ? null : (
            <Panel
              header="Thông tin công tơ TREO"
              key="2"
              extra={genExtraTreo()}
              collapsible={
                thongTinChung.isTreoThaoTUTI ||
                form.getFieldValue("isTreoThaoTUTI") ||
                form.getFieldValue("isThaoCongTo")
                  ? "disabled"
                  : "header"
              }
              forceRender={
                thongTinChung.isTreoThaoTUTI ||
                form.getFieldValue("isTreoThaoTUTI") ||
                form.getFieldValue("isThaoCongTo")
                  ? false
                  : true
              }
            >
              <ThongTinTreo
                detail={detailCTTreo}
                form={form}
                setMaCongToTreo={setMaCongToTreo}
                thongTinHienTai={thongTinHienTai}
                defaultCTTreo={defaultCTTreo}
                detailCTThao={detailCTThao}
                defaultLoaiDiemDo={thongTinChung?.loaiDiemDo?.toString()}
                setDataDetaiCTTreolUpdate={setDataDetaiCTTreolUpdate}
                setChangeTab2={setChangeTab2}
                setChiSoMoiMax={setChiSoMoiMax}
              />
            </Panel>
          )
        }
      </Collapse>
    </Fragment>
  );
}
