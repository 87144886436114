
import { Endpoint } from "@utils/endpoint";
import { authGetMultiplesRequest } from "@utils/request";
// call api list đơn vị

export const handleApiListDonVi = (setLoading, setDataDonVi, setFirstDonVi) => {
  const endpoints = [
    `${Endpoint.LIST_DON_VI_CHUYEN_DV + "?OrderBy=tenDonVi&PageSize=-1"}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      if (res[0].data.data !== null) {
        setDataDonVi(res[0].data.data);
        setFirstDonVi(res[0].data.data[0]);
      } else setDataDonVi([]);
    },
  });
};

// call api xem lại phương án cấp điện

export const handleXemLaiPACD = (
  detail,
  setDataPAXemLai,
  setVisibleXemLaiPACD,
  visibleXemLaiPACD
) => {
  const endpoints = [
    `${Endpoint.KS_GET_PHUONG_AN_CAP_DIEN}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    `${Endpoint.KS_TONG_CONG_SUAT}?HoSoId=${detail?.hoSoId ?? detail?.id}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading: () => false,
    onSuccess: (res) => {
      if (res[0]?.data !== null && res[1]?.data !== null) {
        setDataPAXemLai({ ...res[0].data.data, ...res[1].data.data });
        setVisibleXemLaiPACD(!visibleXemLaiPACD);
      }
    },
  });
};

