import { BASE_API_URL_GHI_CHI_SO } from "@utils/constants";

import BCKD from "./endpoints/endpoint_bckd";
import BCKSSMS from "./endpoints/endpoint_cskh";
import BaoCaoDoiSoatEmail from "./endpoints/endpoint_cskh";
import { CAPDIEN_Enpoint } from "./endpoints/endpoint_capdien";
import { DANHMUC_TIENICHCHUNG_Enpoint } from "./endpoints/endpoint_tienichchung";
import { HETHONG_Endpoint } from "./endpoints/endpoint_hethong";
import { GCS_Endpoint } from "./endpoints/endpoint_gcs";
import { APGIA_Endpoint } from "./endpoints/endpoint_apgia";
import { CRM_Endpoint } from "./endpoints/endpoint_crm";
import { TreoThao_Endpoint } from "./endpoints/endpoint_treothao";
import { CONGTOTHAO_Endpoint } from "./endpoints/endpoint_congtothao";
import { KTTHIETBI_Endpoint } from "./endpoints/endpoint_ktthietbi";
import { GNDN_Endpoint } from "./endpoints/endpoint_giaonhandiennang";
import { LOTRINH_Endpoint } from "./endpoints/endpoint_lotrinh";
import { THANH_TOAN_Endpoint } from "./endpoints/endpoint_thanhtoan";
import { TRUNGAP_Endpoint } from "./endpoints/endpoint_trungap";

export const Endpoint = {
  //hệ thống
  ...HETHONG_Endpoint,

  // api dashboard
  SO_DA_GHI_CHUA_GHI: `${BASE_API_URL_GHI_CHI_SO}/dashboard/sodaghichuaghi`,
  SO_GHI_CHAM: `${BASE_API_URL_GHI_CHI_SO}/dashboard/soghicham`,
  LICH_GCS: `${BASE_API_URL_GHI_CHI_SO}/dashboard/lichgcs`,
  LICH_GCS_THEO_TT: `${BASE_API_URL_GHI_CHI_SO}/dashboard/lichgcsbystatus`,
  LIST_BANG_THONG_KE: `${BASE_API_URL_GHI_CHI_SO}/dashboard/lay-danh-sach-bang-thong-ke`,

  // api bao cao gcs
  LICH_GCS_THEO_NAM: `${BASE_API_URL_GHI_CHI_SO}/baocao/lich-gcs`,
  LICH_GCS_EXCEL: `${BASE_API_URL_GHI_CHI_SO}/baocao/lich-gcs-excel`,
  LICH_GCS_PDF: `${BASE_API_URL_GHI_CHI_SO}/baocao/lich-gcs-pdf`,

  LICH_GCS_CHI_TIET: `${BASE_API_URL_GHI_CHI_SO}/baocao/lich-gcs-chi-tiet`,
  LICH_GCS_CHI_TIET_EXCEL: `${BASE_API_URL_GHI_CHI_SO}/baocao/lich-gcs-chi-tiet-excel`,
  LICH_GCS_CHI_TIET_PDF: `${BASE_API_URL_GHI_CHI_SO}/baocao/lich-gcs-chi-tiet-pdf`,

  // bao cao 2.1
  LICH_GCS_THEO_THOI_GIAN: `${BASE_API_URL_GHI_CHI_SO}/baocao/thoi-gian-gcs`,
  LICH_GCS_THEO_THOI_GIAN_EXCEL: `${BASE_API_URL_GHI_CHI_SO}/baocao/thoi-gian-gcs-excel`,
  LICH_GCS_THEO_THOI_GIAN_PDF: `${BASE_API_URL_GHI_CHI_SO}/baocao/thoi-gian-gcs-pdf`,
  // bao cao 2.2
  LICH_GCS_THEO_TG_CHITIET: `${BASE_API_URL_GHI_CHI_SO}/baocao/thoi-gian-gcs-chi-tiet`,
  LICH_GCS_THEO_TG_CHITIET_EXCEL: `${BASE_API_URL_GHI_CHI_SO}/baocao/thoi-gian-gcs-chi-tiet-excel`,
  LICH_GCS_THEO_TG_CHITIET_PDF: `${BASE_API_URL_GHI_CHI_SO}/baocao/thoi-gian-gcs-chi-tiet-pdf`,

  TOA_DO_KHACH_HANG: `${BASE_API_URL_GHI_CHI_SO}/baocao/toa-do-khach-hang`,
  TOA_DO_KHACH_HANG_EXCEL: `${BASE_API_URL_GHI_CHI_SO}/baocao/toa-do-khach-hang-excel`,
  TOA_DO_KHACH_HANG_PDF: `${BASE_API_URL_GHI_CHI_SO}/baocao/toa-do-khach-hang-pdf`,

  //danh mục
  ...DANHMUC_TIENICHCHUNG_Enpoint,

  // hồ sơ
  ...CAPDIEN_Enpoint,

  //BÁO CÁO KINH DOANH
  ...BCKD,
  // Cham Soc khahc hang
  ...BCKSSMS,
  ...BaoCaoDoiSoatEmail,
  // GHI CHỈ SỐ
  ...GCS_Endpoint,
  // Áp Giá
  ...APGIA_Endpoint,
  // CRM
  ...CRM_Endpoint,
  //treo tháo
  ...TreoThao_Endpoint,
  //ktcs công tơ tháo
  ...CONGTOTHAO_Endpoint,

  //ktthietbi đo đếm điện năng
  ...KTTHIETBI_Endpoint,

  //treo tháo thiết bị đo đếm
  ...TreoThao_Endpoint,
  // Giao nhận điện năng
  ...GNDN_Endpoint,

  // Lộ trình nhân viên
  ...LOTRINH_Endpoint,

  // Thanh toán
  ...THANH_TOAN_Endpoint,

  //Trung áp
  ...TRUNGAP_Endpoint,
};
