import { useCallback, useEffect, useLayoutEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Endpoint } from "@utils/endpoint";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  TABINDEX_CMIS,
} from "@utils/constants";
import {
  buildQueryString,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { handleListData } from "./services";
import Table from "./table";
import { Button } from "antd";
import { dongBoCMIS } from "../services";
import CheckPermission from "@components/CheckPermission";
import { tien_ich_chung } from "@permissions/m_tienichchung";
export default function Index() {
  const { t } = useTranslation();

  const [dataLo, setDataLo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu

  const getListLo = useCallback(() => {
    const endpoints = [
      `${Endpoint.LIST_LO}?${buildQueryString(parseParams(filterConditions))}`,
    ];
    handleListData(endpoints, setLoading, setDataLo, setTotalCount);
  }, [filterConditions]);

  useEffect(() => {
    getListLo();
    // eslint-disable-next-line
  }, [getListLo]);

  //thay đổi pagination table

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //đồng bộ

  //hiển thị các button thêm,...

  const genExtra = () => (
    <CheckPermission permissionCode={tien_ich_chung.m16cmis}>
      <Button
        size="small"
        type="primary"
        className="btn-bg-yellow"
        onClick={handleDongBoCMIS}
        loading={loading}
      >
        Đồng bộ CMIS
      </Button>
    </CheckPermission>
  );

  const handleDongBoCMIS = useCallback(() => {
    const url = Endpoint.DONG_BO_CMIS + `?tabIndex=${TABINDEX_CMIS.LO}`;
    dongBoCMIS(url, setLoading, getListLo);
  }, [getListLo]);

  //khai bái columns

  const columns = [
    {
      title: t("lo.ma_lo"),
      dataIndex: "maLo",
      key: "maLo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: t("lo.ten_lo"),
      dataIndex: "tenLo",
      key: "tenLo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "30%",
      sorter: true,
    },
    {
      title: t("lo.loai_lo"),
      dataIndex: "loaiLo",
      key: "loaiLo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
      sorter: true,
    },
    {
      title: t("lo.tinh_trang"),
      dataIndex: "tinhTrang",
      key: "tinhTrang",
      render: (isActive) => (
        <div className="table-text-center">
          <span className={isActive === 1 ? `active-color` : `unactive-color`}>
            {isActive === 1 ? "Hoạt động" : "Khóa"}
          </span>
        </div>
      ),
      width: "15%",
    },
    {
      title: t("lo.ngay_hieu_luc"),
      dataIndex: "ngayHieuLucString",
      key: "ngayHieuLucString",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
      sorter: true,
    },
    {
      title: t("lo.don_vi_quan_ly"),
      dataIndex: "donViQuanLy",
      key: "donViQuanLy",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
      sorter: true,
    },
  ];

  //tìm kiếm dữ liệu

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  return (
    <Table
      handleSearch={handleSearch}
      clearFilter={clearFilter}
      filterConditions={filterConditions}
      genExtra={genExtra}
      dataSource={dataLo}
      columns={columns}
      totalCount={totalCount}
      loading={loading}
      onChangePagination={onChangePagination}
      t={t}
    />
  );
}
