import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { handleTraLaiApGia } from "../../services";
import TraLaiApGiaModal from "./layout";

function Index({ visible, closeModal, setFetchDataTable, detail }) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values) => {
    const payload = {
      hoSoId: detail.id,
      lyDoTraLai: values.lyDoTraLai,
    };
    handleTraLaiApGia(setLoading, closeModal, setFetchDataTable, payload);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  return (
    <TraLaiApGiaModal
      loading={loading}
      visible={visible}
      closeModal={closeModal}
      handleSubmit={handleSubmit}
      form={form}
    />
  );
}

export default Index;
