import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { handleDownload, selectBCThueTro } from "./bc-chi-tiet-va-thue-tro/service";

export default function BaoCaoThueTro() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);
  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Báo cáo thuê trọ
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              initialValues={{
                reportType:
                  Endpoint.BCAGBD_KH_CHO_THUE_TRO_DA_AP_DINH_MUC_SINH_HOAT_BAC_THANG,
                unitId: user.user.unitCode,
              }}
              colon={false}
            >
              <Form.Item
                name="reportType"
                label="Loại báo cáo"
                labelAlign="left"
                required
              >
                <Select
                  formKey="reportType"
                  form={form}
                  options={selectBCThueTro}
                  placeholder="Tất cả"
                />
              </Form.Item>

              <Form.Item
                name="unitId"
                label="Tên đơn vị"
                labelAlign="left"
                required
              >
                <Selection
                  url={Endpoint.GET_TEAM_LIST_CODE}
                  formKey="unitId"
                  form={form}
                />
              </Form.Item>

              {/* Từ Ngày */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") !==
                    Endpoint.BCAGBD_KH_CHO_THUE_TRO_DA_AP_DINH_MUC_SINH_HOAT_BAC_THANG &&
                  getFieldValue("reportType") !==
                    Endpoint.BCAGBD_KH_CHO_THUE_TRO_DA_AP_DINH_MUC_SINH_HOAT_CUA_BAC_3 ? (
                    <Form.Item
                      name="TuNgay"
                      label="Từ ngày"
                      labelAlign="left"
                      required
                    >
                      <DatePickerComponent
                        formKey="TuNgay"
                        form={form}
                        picker="day"
                        format={"YYYY-MM-DD"}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              {/* Đến Ngày */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") !==
                    Endpoint.BCAGBD_KH_CHO_THUE_TRO_DA_AP_DINH_MUC_SINH_HOAT_BAC_THANG &&
                  getFieldValue("reportType") !==
                    Endpoint.BCAGBD_KH_CHO_THUE_TRO_DA_AP_DINH_MUC_SINH_HOAT_CUA_BAC_3 ? (
                    <Form.Item
                      name="DenNgay"
                      label="Đến ngày"
                      labelAlign="left"
                      required
                    >
                      <DatePickerComponent
                        picker="day"
                        formKey="DenNgay"
                        form={form}
                        format={"YYYY-MM-DD"}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              {/* Tháng năm*/}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") ===
                    Endpoint.BCAGBD_KH_CHO_THUE_TRO_DA_AP_DINH_MUC_SINH_HOAT_BAC_THANG ||
                  getFieldValue("reportType") ===
                    Endpoint.BCAGBD_KH_CHO_THUE_TRO_DA_AP_DINH_MUC_SINH_HOAT_CUA_BAC_3 ? (
                    <Form.Item
                      name="reportDate"
                      label="Tháng/Năm"
                      labelAlign="left"
                      required
                    >
                      <DatePickerComponent
                        picker="month"
                        formKey="reportDate"
                        form={form}
                        format={"YYYY-MM"}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              <Row>
                <Col span={4}></Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>

                  {/* <Button
                    type="primary"
                    loading={loading}
                    className="ml-20px"
                    htmlType="submit"
                  >
                    In báo cáo
                  </Button> */}
                </Col>
              </Row>
            </FormComponent>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
