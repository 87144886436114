import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData, authDeleteData } from "@utils/request";
import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import {
  buildQueryString,
  parseParams,
} from "@utils/function";

export const handleListDataDsSoGCS = (
  setLoading,
  setDataDsSoGCS,
) => {
  let url = "";
  url = Endpoint.LIST_MA_SO_BANG_KE_NO_TO_DOI;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDsSoGCS(res.data);
      } else setDataDsSoGCS([]);
    },
  });
};
// get list data cấu hình ký bảng kê
export const handleListDataCauHinhNV = (
  setLoading,
  location,
  setDataCauHinhNV,
  setTotalCount,
  filterConditions
) => {
  let url = "";
  url = `${Endpoint.GCS_CH_NV_GCS}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataCauHinhNV(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataCauHinhNV([]);
    },
  });
};

export const submitThemMoiCauHinhNV = (
  values,
  setLoading,
  handleCloseModal,
  setFetchDataTable,
) => {
  authPostData({
    url: Endpoint.GCS_CH_NV_GCS,
    method: METHOD_POST,
    payload: {
      ...values
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModal()
      }
    },
  });
};

export const submitUpdateCauHinhNV = (
  record, row, setLoading, setFetchDataTable, setEditingKey
) => {
  authPostData({
    url: Endpoint.GCS_CH_NV_GCS,
    method: METHOD_PUT,
    payload: {
      id: record.id,
      donViId: record.donViId,
      doiId: row.toDoiId,
      userId: row.tenNhanVien,
      maSoGCS: record.maSo

    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setEditingKey('')
      }
    },
  });
};

export const submitXoaCauHinhNV = (id, setLoading, setFetchDataTable) => {
  authDeleteData({
    url: `${Endpoint.GCS_CH_NV_GCS}/${id}`,
    setLoading,
    content: `Bạn chắc chắn muốn xóa ?`,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) setFetchDataTable(true);
    },
  });
};