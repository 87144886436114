import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { formatDateMonth, scrollToTop } from "@utils/function";
import moment from "moment";
import { useDispatch } from "react-redux";
import { closeModal } from "@state/system-config/reducer";
import { Container } from "./css-styled";
import { handleMultileListData, handleSubmitForm } from "./services";
import CapNhatModal from "./modal-cap-nhat";

function Index(props) {
  const { detail, setFetchDataTable } = props;
  const [loading, setLoading] = useState(true);
  const [dataDetail, setDataDetail] = useState([]);
  const [dataDiaChinh, setDataDiaChinh] = useState([]);
  const [dataGioiTinhKH, setDataGioiTinhKH] = useState([]);
  const [dataSoPha, setDataSoPha] = useState([]);
  const [dataMucDich, setDataMucDich] = useState([]);
  const [dataLoaiYeuCau, setDataLoaiYeuCau] = useState([]);
  const [dataLanhDao, setDataLanhDao] = useState([]);
  const [dataNganHang, setDataNganHang] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    const data = {
      ...values,
      hoSoId: detail.id,
      maLanhDaoDuyet: values.maLanhDaoDuyet,
      congSuatNamN: values.congSuatNamN ? values.congSuatNamN.toString() : null,
      congSuatNamNPlus1: values.congSuatNamNPlus1
        ? values.congSuatNamNPlus1.toString()
        : null,
      congSuatNamNPlus2: values.congSuatNamNPlus2
        ? values.congSuatNamNPlus2.toString()
        : null,
      congSuatNamNPlus3: values.congSuatNamNPlus3
        ? values.congSuatNamNPlus3.toString()
        : null,
      soLuongTrungBinhNamN: values.soLuongTrungBinhNamN
        ? values.soLuongTrungBinhNamN.toString()
        : null,
      soLuongTrungBinhNamNPlus1: values.soLuongTrungBinhNamNPlus1
        ? values.soLuongTrungBinhNamNPlus1.toString()
        : null,
      soLuongTrungBinhNamNPlus2: values.soLuongTrungBinhNamNPlus2
        ? values.soLuongTrungBinhNamNPlus2.toString()
        : null,
      soLuongTrungBinhNamNPlus3: values.soLuongTrungBinhNamNPlus3
        ? values.soLuongTrungBinhNamNPlus3.toString()
        : null,
      soHoDungChung: values.soHoDungChung
        ? values.soHoDungChung.toString()
        : null,
      maKhachHangDungChung: values.maKhachHangDungChung
        ? values.maKhachHangDungChung.toString()
        : null,
      maHopDongMB: values.maHopDongMB ? values.maHopDongMB.toString() : null,
      maLoaiYeuCau: values.maLoaiYeuCau,
      nganHangId: values.nganHang,
      flagDiaChiNgoai: values.flagDiaChiNgoai ? values.flagDiaChiNgoai : false,
      diaChiCapDienCmis: null,
      congSuatCmis: null,
      soPhaCmis: null,
      dienApCmis: null,
      lapQuaTiCmis: null,
      maTramCmis: null,
      tenTramCmis: null,
      mucDichSinhHoatCmis: null,
      viTriCongToCmis: null,
    };
    form.setFields([
      {
        name: ["tenNguoiYeuCau"],
        errors: false,
      },
    ]);
    form.setFields([
      {
        name: ["lyDoNangCongSuat"],
        errors: false,
      },
    ]);
    form.setFields([
      {
        name: ["gioiTinhKhachHang"],
        errors: false,
      },
    ]);
    form.setFields([
      {
        name: ["tenKhachHang"],
        errors: false,
      },
    ]);
    form.setFields([
      {
        name: ["gioiTinhNguoiDaiDien"],
        errors: false,
      },
    ]);
    form.setFields([
      {
        name: ["tenNguoiDaiDien"],
        errors: false,
      },
    ]);
    form.setFields([
      {
        name: ["ngayCap"],
        errors: false,
      },
    ]);

    const day = moment(values.ngayCap);
    const ngayCap =
      day.year() +
      "-" +
      formatDateMonth(day.month() + 1) +
      "-" +
      formatDateMonth(day.date());

    const dayUyQuyen = moment(values.ngayCapGiayUyQuyen);
    const ngayCapUyQuyen =
      dayUyQuyen.year() && dayUyQuyen.month() && dayUyQuyen.date()
        ? dayUyQuyen.year() +
          "-" +
          formatDateMonth(dayUyQuyen.month() + 1) +
          "-" +
          formatDateMonth(dayUyQuyen.date())
        : null;
    data.ngayCap = ngayCap;
    data.ngayCapGiayUyQuyen = ngayCapUyQuyen;

    handleSubmitForm(
      data,
      setLoading,
      closeModal,
      dispatch,
      setFetchDataTable,
      form
    );
  };
  useEffect(() => {
    handleMultileListData(
      setLoading,
      setDataDetail,
      setDataDiaChinh,
      setDataGioiTinhKH,
      setDataSoPha,
      setDataMucDich,
      setDataLoaiYeuCau,
      setDataLanhDao,
      setDataNganHang,
      detail.id
    );
    // eslint-disable-next-line
  }, []);

  const newDataLoaiYeuCau = dataLoaiYeuCau.map((item, index) => ({
    value: item.value,
    name: item.name,
    type: index + 1,
  }));

  const ref1 = useRef();

  useEffect(() => {
    scrollToTop(ref1);
  }, []);

  const render = useMemo(
    () => (
      <CapNhatModal
        loading={loading}
        ref1={ref1}
        form={form}
        dataDetail={dataDetail}
        handleSubmit={handleSubmit}
        dataDiaChinh={dataDiaChinh}
        dataGioiTinhKH={dataGioiTinhKH}
        dataLanhDao={dataLanhDao}
        dataSoPha={dataSoPha}
        dataMucDich={dataMucDich}
        newDataLoaiYeuCau={newDataLoaiYeuCau}
        dataNganHang={dataNganHang}
        t={t}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataDetail, dataDiaChinh, dataGioiTinhKH, form, loading, t]
  );
  return <Container>{render}</Container>;
}
export default memo(Index);
