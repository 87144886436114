import {
  authGetData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { STATUSCODE_200, ENUM_FUNCTION, METHOD_POST } from "@utils/constants";
import { formatYYYYMMDDString } from "@utils/function";
import moment from "moment";

export const handleXemLaiPhuongAn = (
  detail,
  setDataPAXemLai,
  setVisibleXemLaiPhuongAnCapDien,
  visibleXemLaiPhuongAnCapDien
) => {
  const endpoints = [
    `${Endpoint.KS_GET_PHUONG_AN_CAP_DIEN}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    `${Endpoint.KS_TONG_CONG_SUAT}?HoSoId=${detail?.hoSoId ?? detail?.id}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading: () => false,
    onSuccess: (res) => {
      if (res[0]?.data !== null && res[1]?.data !== null) {
        setDataPAXemLai({ ...res[0].data.data, ...res[1].data.data });
        setVisibleXemLaiPhuongAnCapDien(!visibleXemLaiPhuongAnCapDien);
      }
    },
  });
};

export const handleListTroNgai = (setLoading, setDataTroNgai) => {
  authGetData({
    url: `${Endpoint.XU_LY_LIST_TRO_NGAI}?funcId=${ENUM_FUNCTION.XACNHANXUATVATTU}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) setDataTroNgai(res.data);
    },
  });
};

export const submitXacNhan = (
  detail,
  ngay,
  setLoading,
  setFetchDataTable,
  handleCloseModal
) => {
  const data = {
    hoSoId: detail.id,
  };
  const convert = moment(ngay);
  const ngayXuatKho = formatYYYYMMDDString(
    convert.year(),
    convert.month(),
    convert.date()
  );
  data.ngayXuatKho = ngayXuatKho;
  authPostData({
    url: Endpoint.XAC_NHAN_XUAT_VAT_TU,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModal();
      }
    },
  });
};
