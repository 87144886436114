import { Endpoint } from "@utils/endpoint";
import { authGetData, downloadFile } from "@utils/request";
import { buildQueryString} from "@utils/function";

// get list data log lịch sử sổ
export const handleListLichSuSo = (
  lichGCSId,
  setLoading,
  setDataLichSuSo,
  setTotalCount
) => {
  let url = "";
  url = `${Endpoint.LICH_SU_SO_GCS}?${buildQueryString(lichGCSId)}`
    authGetData({
      url,
      setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataLichSuSo(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataLichSuSo([]);
    },
  });
};

export const downLoadLSSo = (id, setLoading) => {
  downloadFile({
    endpoint: `${Endpoint.GCS_XUAT_LS_SO}?LichGCSId=${id}`,
    setLoading,
  });
};