import { Endpoint } from "@utils/endpoint";
import { authGetData, downloadFile } from "@utils/request";

export const getListDonVi = (setLoading, setData) => {
  authGetData({
    url: `${Endpoint.GET_UNIT_LIST}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getListDoi = (setLoading, setData, donVi) => {
  authGetData({
    url: `${Endpoint.GET_TEAM_LIST}?DonViId=${donVi}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getNhanVien = (setLoading, setData, query) => {
  authGetData({
    url: `${Endpoint.LOTRINH_QUAN_LY_LO_TRINH}?${query}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};
export const getDanhSachNhanVien = (setLoading, setData, query) => {
  authGetData({
    url: `${Endpoint.LOTRINH_DANH_SACH_NHAN_VIEN}?${query}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};
export const getBaoCaoViTriNhanVien = (setLoading, query) => {
  authGetData({
    url: `${Endpoint.LOTRINH_BAO_CAO_VI_TRI_NHAN_VIEN}?${query}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
      }
    },
  });
};
export const handleDownload = (setLoading, query) => {
  const endpoint = `${Endpoint.LOTRINH_BAO_CAO_VI_TRI_NHAN_VIEN}?${query}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const getTrangThaiNhanVien = (setLoading, setData, query) => {
  authGetData({
    url: `${Endpoint.LOTRINH_TRANG_THAI_NHAN_VIEN}?${query}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};
