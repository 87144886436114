import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";

import LoadingComponent from "@components/Loading";
import moment from "moment";
import { ChiTietYeuCauStyle } from "../css-styled";
import { getChiTietYeuCau } from "../services";
export default function ChiTietYeuCauTab(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const { detailHoSo } = props;
  const { id } = detailHoSo;
  // lay danh sach chi tiet yeu cau
  useEffect(() => {
    getChiTietYeuCau(setLoading, id, setData);
  }, [id]);

  return (
    <LoadingComponent loading={loading}>
      {/* thông tin hồ sơ */}
      <ChiTietYeuCauStyle>
        <Row gutter={24}>
          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Mã khách hàng:</h5>
            <h5 className="fs-14px font-bold">{data?.maKhachHang || ""}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Tên khách hàng:</h5>
            <h5 className="fs-14px font-bold">{data?.tenKhachHang || ""}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Điện thoại khách hàng:</h5>
            <h5 className="fs-14px font-bold">
              {data?.dienThoaiKhachHang || ""}
            </h5>
          </Col>

          <Col className="field_wrap" span={14}>
            <h5 className="fs-14px c-t-label">Địa chỉ khách hàng:</h5>
            <h5 className="fs-14px font-bold">{data?.diaChiKhachHang || ""}</h5>
          </Col>

          <Col className="field_wrap" span={8} offset={2}>
            <h5 className="fs-14px c-t-label">Phiên GCS:</h5>
            <h5 className="fs-14px font-bold">{data?.phienGCS || ""}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Mã trạm CMIS:</h5>
            <h5
              className="fs-14px font-bold"
              style={{ color: `${data?.mau || ""}` }}
            >
              {data?.maTramCMIS || ""}
            </h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Tên trạm PP:</h5>
            <h5 className="fs-14px font-bold">{data?.tenTramPP || ""}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Mã trạm PP:</h5>
            <h5 className="fs-14px font-bold">{data?.maTramPP || ""}</h5>
          </Col>

          <Col className="field_wrap" span={24}>
            <h5 className="fs-14px c-t-label">Tổ/đội khách hàng:</h5>
            <h5 className="fs-14px font-bold">{data?.toDoiKhachHang || ""}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Mã tin:</h5>
            <h5 className="fs-14px font-bold">{data?.maYeuCau || ""}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Loại yêu cầu:</h5>
            <h5 className="fs-14px font-bold">{data?.loaiYeuCau || ""}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Trạng thái phiếu:</h5>
            <h5 className="fs-14px font-bold">{data?.trangThaiPhieu || ""}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Tên người yêu cầu:</h5>
            <h5 className="fs-14px font-bold">{data?.nguoiYeuCau || ""}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Điện thoại:</h5>
            <h5 className="fs-14px font-bold">
              {data?.dienThoaiNguoiYeuCau || ""}
            </h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Số lần gọi:</h5>
            <h5 className="fs-14px font-bold">{data?.soLanGoi || ""}</h5>
          </Col>

          <Col className="field_wrap" span={14}>
            <h5 className="fs-14px c-t-label">Địa chỉ người yêu cầu:</h5>
            <h5 className="fs-14px font-bold">
              {data?.diaChiNguoiYeuCau || ""}
            </h5>
          </Col>

          <Col className="field_wrap" span={8} offset={2}>
            <h5 className="fs-14px c-t-label">Đội:</h5>
            <h5 className="fs-14px font-bold">{data?.doi || ""}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Ngày yêu cầu:</h5>
            <h5 className="fs-14px font-bold">
              {data?.ngayYeuCau
                ? moment(data.ngayYeuCau).format("DD/MM/YYYY HH:mm")
                : ""}
            </h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Ngày tiếp nhận:</h5>
            <h5 className="fs-14px font-bold">
              {data?.ngayTiepNhan
                ? moment(data.ngayTiepNhan).format("DD/MM/YYYY HH:mm")
                : ""}
            </h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Kênh tiếp nhận:</h5>
            <h5 className="fs-14px font-bold">{data?.kenhTiepNhan || ""}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Người tạo:</h5>
            <h5 className="fs-14px font-bold">{data?.nguoiTao || ""}</h5>
          </Col>

          <Col className="field_wrap" span={16}>
            <h5 className="fs-14px c-t-label">Người tiếp nhận:</h5>
            <h5 className="fs-14px font-bold">{data?.nguoiTiepNhan || ""}</h5>
          </Col>
        </Row>
        {data?.linhVuc === "kythuat" && (
          <Row gutters={24}>
            <Col className="field_wrap" span={8}>
              <h5 className="fs-14px c-t-label">Giờ mất điện:</h5>
              <h5 className="fs-14px font-bold">
                {data?.ngayMatDien
                  ? moment(data.ngayMatDien).format("DD-MM-YYYY HH:mm")
                  : ""}
              </h5>
            </Col>

            <Col className="field_wrap" span={8}>
              <h5 className="fs-14px c-t-label">Ngày giờ có điện:</h5>
              <h5 className="fs-14px font-bold">
                {data?.ngayCoDien
                  ? moment(data.ngayCoDien).format("DD-MM-YYYY HH:mm")
                  : ""}
              </h5>
            </Col>

            <Col className="field_wrap" span={8}>
              <h5 className="fs-14px c-t-label">Mất điện cả khu vực:</h5>
              <h5 className="fs-14px font-bold">
                {data?.matDienCaKhuVuc || ""}
              </h5>
            </Col>

            <Col className="field_wrap" span={24}>
              <h5 className="fs-14px c-t-label">Nguyên nhân:</h5>
              <h5 className="fs-14px font-bold">{data?.nguyenNhan || ""}</h5>
            </Col>

            <Col className="field_wrap" span={8}>
              <h5 className="fs-14px c-t-label">Người xử lý:</h5>
              <h5 className="fs-14px font-bold">{data?.nguoiXuLy || ""}</h5>
            </Col>

            <Col className="field_wrap" span={8}>
              <h5 className="fs-14px c-t-label">Nhân viên sửa điện 1:</h5>
              <h5 className="fs-14px font-bold">
                {data?.nhanVienSuaDien1 || ""}
              </h5>
            </Col>

            <Col className="field_wrap" span={8}>
              <h5 className="fs-14px c-t-label">Nhân viên sửa điện 2:</h5>
              <h5 className="fs-14px font-bold">
                {data?.nhanVienSuaDien2 || ""}
              </h5>
            </Col>
          </Row>
        )}
        {data?.troNgai && (
          <Row gutter={24}>
            <Col className="field_wrap" span={24}>
              <h5 className="fs-14px c-t-label">Trở ngại:</h5>
              <h5 className="fs-14px font-bold">{data?.troNgai || ""}</h5>
            </Col>

            <Col className="field_wrap" span={24}>
              <h5 className="fs-14px c-t-label">Ngày hẹn:</h5>
              <h5 className="fs-14px font-bold">
                {data?.ngayHen ? moment(data.ngayHen).format("DD-MM-YYYY") : ""}
              </h5>
            </Col>
          </Row>
        )}
        <Row gutter={24}>
          <Col className="field_wrap" span={24}>
            <h5 className="fs-14px c-t-label">Nội dung yêu cầu:</h5>
            <h5 className="fs-14px font-bold">{data?.noiDungYeuCau || ""}</h5>
          </Col>

          <Col className="field_wrap" span={24}>
            <h5 className="fs-14px c-t-label">Kết quả xử lý:</h5>
            <h5 className="fs-14px font-bold">{data?.ketQuaXuLy || ""}</h5>
          </Col>
        </Row>
      </ChiTietYeuCauStyle>
    </LoadingComponent>
  );
}
