import { BASE_API_URL_GHI_CHI_SO } from "@utils/constants";

export const APICfgBangKe = {
  LIST_CAU_HINH_KBK: `${BASE_API_URL_GHI_CHI_SO}/cauhinhkybangke`,
  CREATE_CAU_HINH_KBK: `${BASE_API_URL_GHI_CHI_SO}/cauhinhkybangke`,
  UPDATE_CAU_HINH_KBK: `${BASE_API_URL_GHI_CHI_SO}/cauhinhkybangke`,
  REMOVE_CAU_HINH_KBK: `${BASE_API_URL_GHI_CHI_SO}/cauhinhkybangke`,
  LIST_LOAI_BK: `${BASE_API_URL_GHI_CHI_SO}/cauhinhkybangke/loai-bang-ke`,

  GCS_CH_NV_GCS: `${BASE_API_URL_GHI_CHI_SO}/nhanviengcs`,
  // GCS_CH_NV_GCS_CREATE: `${BASE_API_URL_GHI_CHI_SO}/nhanviengcs`,
  // GCS_CH_NV_GCS_LIST: `${BASE_API_URL_GHI_CHI_SO}/nhanviengcs`,
  // GCS_CH_NV_GCS_UPDATE: `${BASE_API_URL_GHI_CHI_SO}/nhanviengcs`,
  // GCS_CH_NV_GCS_DELETE: `${BASE_API_URL_GHI_CHI_SO}/nhanviengcs`,
};