import { EnvironmentFilled } from "@ant-design/icons";
import DatePickerComponent from "@components/DatePicker";
import Filter from "../../../common/Filter";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import ThongTinChiTietTabs from "../common-ho-so/index";
import { closeModal, openModal } from "@state/system-config/reducer";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth/reducer";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_MONTH_ENGLISH,
  FORMAT_YEAR,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { Button, Col, Form, Row, Select, Tooltip } from "antd";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Export } from "../../export/Export";
import { getThoiGianGhiChiSo } from "./services";
import SelectMaSoGCS from "../common/SelectGetMaSoGCS";
import shouldUpdateMaso from "../common/should-update-ma-so";

export default function BKThoiGianGhiChiSo() {
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const { user } = useSelector(authSelector);
  const [dataThoiGianGCS, setDataThoiGianGCS] = useState();
  const [totalCount, setTotalCount] = useState();
  const [isDoubleClick] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    KyGCS: 1,
    ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
    ...parseParams(location.search),
  });
  const [query, setQuery] = useState({
    pageIndex: 1,
    pageSize: 50
  });
  const [listMaso, setListMaso] = useState([]);

  const queryString = buildQueryString(
    parseParams({
      ...filterConditions,
      DonViId: filterConditions.DonViId,
      MaSoGCS: filterConditions.MaSoGCS,
      KyGCS: filterConditions.KyGCS,
      ThangNam:
        filterConditions.ThangNam === null
          ? undefined
          : filterConditions.ThangNam,
    })
  );

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    const query = {
      ...filterConditions,
    };
    getThoiGianGhiChiSo(setLoading, setDataThoiGianGCS, setTotalCount, query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      ...values,
    }));

    const queryString = {
      ...values,
      MaSoGCS:
        values.MaSoGCS && values.MaSoGCS.length
          ? values.MaSoGCS.toString()
          : "",
      // Type: "1",
    };

    getThoiGianGhiChiSo(setLoading, setDataThoiGianGCS, setTotalCount, queryString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };
  const handleChangeUnit = useCallback(() => {
    form.setFieldsValue({});
  }, [form]);

  const clear = useCallback(() => {
    form.resetFields();
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      KyGCS: 1,
      ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
    });
    const initQuery = {
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      KyGCS: 1,
      ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
    };

    getThoiGianGhiChiSo(setLoading, setDataThoiGianGCS, setTotalCount, initQuery);
    // form.setFieldsValue({ MaSoGCS: undefined });
  }, [form, user.unitId]);

  const genExtra = useCallback(
    () => (
      <div className="export">
        <Export
          pdfEndpoint={`${Endpoint.XUAT_PDF_THOI_GIAN_GCS}?${queryString}`}
          setLoading={setLoading}
        />
      </div>
    ),
    [queryString]
  );

  const detailThoiGian = (record) => {
    if (isDoubleClick)
      return {
        onDoubleClick: () => {
          dispatch(
            openModal({
              content: (
                <ThongTinChiTietTabs
                  detailThoiGian={record}
                  closeModal={closeModal}
                />
              ),
              size: "large",
              title: <>Danh sách mã trạm đơn vị</>,
            })
          );
        },
      };
  };

  const columns = [
    {
      title: "STT",
      key: "index",
      render: (a, b, index) => {
        return (
          (filterConditions.pageIndex - 1) * filterConditions.pageSize +
          (index + 1)
        );
      },
      width: 60,
      align: "center",
    },
    {
      title: "Đơn vị",
      dataIndex: "maDonVi",
      key: "maDonVi",
      width: 100,
    },
    {
      title: "Tên đội",
      dataIndex: "doiQLD",
      key: "doiQLD",
      width: 150,
    },
    {
      title: "Mã sổ GCS",
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      width: 150,
    },
    {
      title: "Mã trạm",
      dataIndex: "maTramList",
      key: "maTramList",
      width: 180,
      render: () => (
        <Tooltip title="Nháy đúp để xem chi tiết mã trạm" trigger="hover">
          <div className="poiter">
            <EnvironmentFilled />
            <span className="double-click-matram">Danh sách mã trạm</span>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Ngày GCS",
      dataIndex: "ngayGCS",
      key: "ngayGCS",
      width: 180,
    },
    {
      title: "Kỳ",
      dataIndex: "ky",
      key: "ky",
      width: 50,
      align: "center",
    },
    {
      title: "Tổng số KH",
      dataIndex: "tongSoKH",
      key: "tongSoKH",
      width: 100,
    },
    {
      title: "Tổng sản lượng",
      dataIndex: "tongSanLuong",
      key: "tongSanLuong",
      width: 100,
    },
    {
      title: "Loại sổ",
      dataIndex: "loaiSo",
      key: "loaiSo",
      width: 80,
    },
    {
      title: "Hình thức GCS",
      dataIndex: "hinhThucGCS",
      key: "hinhThucGCS",
      width: 100,
    },
    {
      title: "Thời gian nhận sổ từ CMIS",
      dataIndex: "thoiGianNhanSoTuCMIS",
      key: "thoiGianNhanSoTuCMIS",
      width: 180,
      render: (text, record) => {
        if (record.thoiGianNhanSoTuCMIS || record.thoiGianChuyenDuLieuCMIS)
          return <span>{text}</span>;
      },
    },
    {
      title: "Thời gian phân công sổ",
      dataIndex: "thoiGianPhanCongSo",
      key: "thoiGianPhanCongSo",
      width: 180,
    },
    {
      title: "Thời gian lấy dữ liệu MDMS",
      dataIndex: "thoiGianLayDuLieuMDMS",
      key: "thoiGianLayDuLieuMDMS",
      width: 180,
    },
    {
      title: "Thời gian lấy dữ liệu HHU",
      dataIndex: "thoiGianLayDuLieuHHU",
      key: "thoiGianLayDuLieuHHU",
      width: 180,
    },
    {
      title: "Thời gian lấy dữ liệu MTB",
      dataIndex: "thoiGianLayDuLieuMTB",
      key: "thoiGianLayDuLieuMTB",
      width: 180,
    },
    {
      title: "Thời gian chuyển dữ liệu về",
      dataIndex: "thoiGianChuyenDuLieuVe",
      key: "thoiGianChuyenDuLieuVe",
      width: 180,
    },
    {
      title: "Thời gian đối soát",
      dataIndex: "thoiGianDoiSoat",
      key: "thoiGianDoiSoat",
      width: 180,
    },
    {
      title: "Thời gian NV GCS ký",
      dataIndex: "thoiGianNVGCSKy",
      key: "thoiGianNVGCSKy",
      width: 150,
    },
    {
      title: "Thời gian đội trưởng ký",
      dataIndex: "thoiGianDoiTruongKy",
      key: "thoiGianDoiTruongKy",
      width: 150,
    },
    {
      title: "Thời gian chuyển dữ liệu CMIS",
      dataIndex: "thoiGianChuyenDuLieuCMIS",
      key: "thoiGianChuyenDuLieuCMIS",
      width: 150,
    },
    {
      title: "Thời gian thực hiện",
      dataIndex: "thoiGianThucHien",
      key: "thoiGianThucHien",
      width: 150,
    },
    {
      title: "Chênh lệch ngày GCS và ngày lấy dữ liệu lần cuối",
      dataIndex: "chenhLechNgayGCS",
      key: "chenhLechNgayGCS",
      width: 150,
    },
    {
      title: "Chênh lệch ngày xuất dữ liệu - ngày GCS",
      dataIndex: "chenhLechNgayXuatDuLieu",
      key: "chenhLechNgayXuatDuLieu",
      width: 150,
    },
    {
      title: "Chênh lệch ngày nhận dữ liệu - ngày GCS",
      dataIndex: "chenhLechNgayNhanDuLieu",
      key: "chenhLechNgayNhanDuLieu",
      width: 150,
    },
  ];

  return (
    <Fragment>
      <Container>
        <Filter>
          <FormComponent
            form={form}
            initialValues={{ KyGCS: "1", DonViId: user.unitId }}
            name="filter-form"
            onFinish={handleSearch}
            layout="vertical"
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item name="DonViId" label="Đơn vị">
                  <Selection
                    url={Endpoint.GET_UNIT_LIST}
                    form={form}
                    formKey="DonViId"
                    setValue={handleChangeUnit}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.DonViId !== currentValues.DonViId
                  }
                >
                  {({ getFieldValue }) => {
                    if (getFieldValue("DonViId")) {
                      return (
                        <Form.Item name="TodoiId" label="Tổ/Đội">
                          <Selection
                            url={`${
                              Endpoint.AUTH_LIST_TO_DOI
                            }?DonViId=${getFieldValue("DonViId")}`}
                            formKey="TodoiId"
                            form={form}
                            disabled={!getFieldValue("DonViId")}
                          />
                        </Form.Item>
                      );
                    }
                    return (
                      <Form.Item name="TodoiId" label="Tổ/Đội">
                        <Select disabled />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={24} md={6}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => {
                    if (prevValues.DonViId !== currentValues.DonViId) {
                      form.setFieldsValue({
                        TramId: undefined,
                      });
                    }
                    return prevValues.DonViId !== currentValues.DonViId;
                  }}
                >
                  {({ getFieldValue }) => {
                    if (getFieldValue("DonViId")) {
                      return (
                        <Form.Item name="TramId" label="Trạm">
                          <Selection
                            url={`${
                              Endpoint.LIST_TRAM_CT
                            }?donviId=${getFieldValue("DonViId")}`}
                            formKey="TramId"
                            form={form}
                          />
                        </Form.Item>
                      );
                    }
                    return (
                      <Form.Item name="TramId" label="Trạm">
                        <Selection
                          url={`${Endpoint.LIST_TRAM_CT}?donviId=${user.unitId}`}
                          formKey="TramId"
                          form={form}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={8} md={3}>
                <Form.Item name="KyGCS" label="Kỳ GCS" labelAlign="right">
                  <Select>
                    <Select.Option value="1">1</Select.Option>
                    <Select.Option value="2">2</Select.Option>
                    <Select.Option value="3">3</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} md={3}>
                <Form.Item
                  name="ThangNam"
                  label="Tháng /Năm"
                  labelAlign="right"
                >
                  <DatePickerComponent
                    defaultValue={
                      filterConditions.ThangNam
                        ? moment(filterConditions.ThangNam)
                        : moment(moment(), FORMAT_YEAR)
                    }
                    picker="month"
                    formKey="ThangNam"
                    format={"YYYY-MM"}
                    form={form}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
              <Form.Item
                    name="MaSoGCS"
                    label="Mã sổ"
                    shouldUpdate={(prevValues, currentValues) => {
                      shouldUpdateMaso({prevValues, currentValues, setQueryMaso: setQuery, form, setListMaso, fieldUpdate:"MaSoGCS"})
                    }}
                  >
                    <SelectMaSoGCS
                      query={query}
                      setQuery={setQuery}
                      url={Endpoint.LIST_MA_SO_BANG_KE_NO_TO_DOI}
                      formKey="MaSoGCS"
                      form={form}
                      loading
                      mode={"multiple"}
                      listMaso={listMaso}
                      setListMaso={setListMaso}
                    />
                  </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Button className="mr-24px" onClick={clear}>
                Bỏ lọc
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Tìm kiếm
              </Button>
            </Row>
          </FormComponent>
        </Filter>
        <TableComponent
          header={"Bảng kê thời gian gcs"}
          renderExtraAction={() => genExtra()}
          dataSource={dataThoiGianGCS}
          columns={customColumn(columns, filterConditions)}
          totalData={totalCount}
          onRow={detailThoiGian}
          loading={loading}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
          scrollX={2300}
        />
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
  .ant-collapse.ant-collapse-icon-position-left {
    margin-top: 20px;
  }
  .poiter {
    cursor: pointer;
  }
  .double-click-matram {
    margin-left: 7px;
    color: red;
  }
`;
