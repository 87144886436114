/* eslint-disable no-unused-vars */
import {  Col, Input, Modal, Row, Spin, Table } from "antd";
import {useCallback, useEffect, useState } from "react";
import styled from "styled-components";

export default function ModalDanhSachDiemDoChuaNhap(props) {
  const { visible, close, data, loading } = props;
 
  const [dataTable, setDaTaTable] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  
  useEffect(() => {
    setDaTaTable(data);
    setDataSource(data);
  }, [data]);
  const [paging, setPaging] = useState({
    current: 1,
    pageSize: 10,
  });


  const columns = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      with: "12%",
      render: (text) => <div style={{ textAlign: "center" }}>{text}</div>,
    },
    {
      title: "Tên trạm",
      dataIndex: "tenTram",
      key: "tenTram",
      width: "25%",
      render: (text) => <div style={{ textAlign: "left" }}>{text}</div>,
    },
    {
      title: "Tính chất điểm đo",
      dataIndex: "tinhChat",
      key: "tinhChat",
      width: "20%",
      render: (text) => <div style={{ textAlign: "left" }}>{text}</div>,
    },
    {
      title: "Thông tin điểm đo",
      dataIndex: "thongTinDiemDo",
      key: "thongTinDiemDo",
      width: "18%",
      render: (text) => <div style={{ textAlign: "left" }}>{text}</div>,
    },
    {
      title: "Đơn vị quản lý",
      dataIndex: "tenDonViQuanLy",
      key: "tenDonViQuanLy",
      width: "25%",
      render: (text) => <div style={{ textAlign: "left" }}>{text}</div>,
    },
  ];

    // event search
    const onPressEnter = useCallback(
      (event) => {
        const { keyCode, target } = event;
        const { value } = target;
        if (keyCode === 13) {
          const findItems = dataSource.filter(
            (item) =>
              item.maDiemDo.toLowerCase().includes(value.toLowerCase()) ||
              item.thongTinDiemDo.toLowerCase().includes(value.toLowerCase())
          );
          setPaging({
            current: 1,
            pageSize: 10,
          });
          setDaTaTable(findItems);
        }
      },
      [dataSource]
    );

  const onChangePagination = (paging) => {
    setPaging(paging);
  };
  return (
    <Modal
      title="Danh sách điểm đo chưa nhập chỉ số"
      visible={visible}
      onCancel={close}
      footer={null}
      width={"80vw"}
    >
      <Spin spinning={loading}>
        <Row>
        <Col span={12} style={{ marginBottom: 12 }}>
          <Input
            className="input"
            onKeyDown={onPressEnter}
            placeholder={"Tìm theo mã điểm đo, thông tin điểm đo"}
          />
        </Col>
          <Col span={24}>
            <TableStyled>
              <Table
                columns={columns}
                dataSource={dataTable}
                onChange={onChangePagination}
                pagination={{
                  total: dataTable.length,
                  current: paging.current,
                  pageSize: paging.pageSize,
                }}
                rowKey="maDiemDo"
              />
            </TableStyled>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}

const TableStyled = styled.div`

   .ant-table-tbody > tr > td {
     padding: 8px !important;
   }
  .ant-form-item-explain {
    display: none;
  }
  .ant-checkbox-disabled {
    display: none;
  }
`;
