import { Fragment, useCallback, useLayoutEffect, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Filter from "../../common/Filter";
import TableComponent from "@components/TableComponent";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE, ENUM_KY_GCS } from "@utils/constants";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilterTraCuu } from "./list-bo-loc";
import { Container, TableStyled } from "./css-styled";
import {
  handleListDataDsSoGCS
} from "./services";
import LichSuSoModal from "../../common/modal/lich-su-so/modal-lich-su-so";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import moment from "moment";
import "moment/locale/vi";
import { Form } from "antd";

export default function Index() {
  const { t } = useTranslation();
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [dataDsSoGCS, setDataDsSoGCS] = useState([]);
  const [, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [visibleLichSu, setVisibleLichSu] = useState(false);
  const [lichGCSId, setLichGCSId] = useState();
  const [tenSoGCS, setTenSoGCS] = useState();
  const [ky, setKy] = useState();
  const [thangNam, setThangNam] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    ...parseParams(location.search),
    Ky: ENUM_KY_GCS.KY_1,
    NgayGhi: moment().format("DD"),
    Thang: moment().format("MM"),
    Nam: moment().format("YYYY"),
    orderBy: "maSoGCS"
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //pagination table  
  const onChangePagination = (paging, _filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //tìm kiếm dữ liệu
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
      MaSoGCS: values.MaSoGCS === undefined ? values.MaSoGCS : values.MaSoGCS.trim(),
      TenSoGCS: values.TenSoGCS === undefined ? values.TenSoGCS : values.TenSoGCS.trim(),
      NgayGhi: (values.Ngay === undefined || values.Ngay === null) ? values.Ngay : values.Ngay.substring(8, 10),
      Thang: (values.Ngay === undefined || values.Ngay === null) ? values.Ngay : values.Ngay.substring(5, 7),
      Nam: (values.Ngay === undefined || values.Ngay === null) ? values.Ngay : values.Ngay.substring(0, 4),
    }));
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      Ky: ENUM_KY_GCS.KY_1,
      NgayGhi: moment().format("DD"),
      Thang: moment().format("MM"),
      Nam: moment().format("YYYY"),
      orderBy: "maSoGCS"
    });
  }, [user.unitId]);

  //fetch dữ liệu
  const getListDataDsSoGCS = useCallback(() => {
    handleListDataDsSoGCS(
      setLoading,
      location,
      setDataDsSoGCS,
      setTotalCount,
      filterConditions
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getListDataDsSoGCS();
  }, [getListDataDsSoGCS]);

  const columns = [
    {
      title: "",
      dataIndex: "stt",
      key: "stt",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "4%",
    },
    {
      title: t("tra_cuu.ma_so"),
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      render: (text, record) => (
        <div className="table-text-left">
          <span
            style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}
            onClick={() => handleOpenModal(record.id, record.tenSoGCS, record.ky, record.thangNam)}>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: t("tra_cuu.ten_so"),
      dataIndex: "tenSoGCS",
      key: "tenSoGCS",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
      sorter: true,
    },
    {
      title: t("tra_cuu.hinh_thuc"),
      dataIndex: "hinhThuc",
      key: "hinhThuc",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: t("tra_cuu.ky"),
      dataIndex: "ky",
      key: "ky",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "4%",
    },
    {
      title: t("tra_cuu.ngay_ghi"),
      dataIndex: "ngayGhi",
      key: "ngayGhi",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "6%",
    },
    {
      title: t("tra_cuu.thang_nam"),
      dataIndex: "thangNam",
      key: "thangNam",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: t("tra_cuu.slkh"),
      dataIndex: "slkh",
      key: "slkh",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "7%",
    },
    {
      title: t("tra_cuu.to_doi"),
      dataIndex: "tenToDoi",
      key: "tenToDoi",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: t("tra_cuu.nhan_vien_gcs"),
      dataIndex: "tenNhanVien",
      key: "tenNhanVien",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "13%",
    },
    {
      title: t("tra_cuu.trang_thai"),
      dataIndex: "tenTrangThai",
      key: "tenTrangThai",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
    },
  ];

  //open popup
  const handleOpenModal = (lichGCSId, tenSoGCS, ky, thangNam) => {
    setVisibleLichSu(true);
    setLichGCSId(lichGCSId);
    setTenSoGCS(tenSoGCS);
    setKy(ky);
    setThangNam(thangNam);
  };

  //đóng popup
  const handleCloseModal = () => {
    setVisibleLichSu(false);
    setLoading(false);
  };

  //render popup
  const showModal = useMemo(
    () =>
      visibleLichSu && (
        <LichSuSoModal
          visible={visibleLichSu}
          closeModal={handleCloseModal}
          lichGCSId={lichGCSId}
          tenSoGCS={tenSoGCS}
          ky={ky}
          thangNam={thangNam}
        />
      ),
    [visibleLichSu, lichGCSId, tenSoGCS, ky, thangNam]
  );

  return (
    <Fragment>
      <Filter>
        <ListFilterTraCuu
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
        />
      </Filter>
      <Container>
        <TableStyled>
          <TableComponent
            header={t("tra_cuu.danh_sach_so_ghi_chi_so")}
            dataSource={dataDsSoGCS}
            listFilter={<ListFilterTraCuu form={form} />}
            columns={customColumn(columns, filterConditions)}
            totalData={totalCount}
            loading={loading}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            scrollX={1700}
          />
        </TableStyled>
      </Container>
      {showModal}
    </Fragment>
  );
}
