import { buildQueryString, parseParams } from "@utils/function";

import { authGetMultiplesRequest } from "@utils/request";
import { Endpoint } from "@utils/endpoint";

export const handleListData = (
  filterConditions,
  setLoading,
  setDataTram,
  setTotalCount
) => {
  const endpoints = [
    `${Endpoint.LIST_TRAM}?${buildQueryString(parseParams(filterConditions))}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataTram(res[0].data.data);
        setTotalCount(res[0].data.paging.totalCount);
      } else setDataTram([]);
    },
  });
};
