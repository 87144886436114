import { Form } from "antd";
import { useCallback, useEffect } from "react";
import FormBoLocXuatKho from "./form-bo-loc";

export default function ListFilterTaoPhieuXuatKho(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    tooltip,
    user,
    handleChange,
    donVi,
    tram,
    loaiTreoThao,
    trangThai,
  } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  return (
    <FormBoLocXuatKho
      user={user}
      form={form}
      handleSearch={handleSearch}
      tooltip={tooltip}
      clear={clear}
      donVi={donVi}
      tram={tram}
      filterConditions={filterConditions}
      trangThai={trangThai}
      loaiTreoThao={loaiTreoThao}
      handleChange={handleChange}
    />
  );
}
