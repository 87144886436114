import React, { memo, useState } from "react";
import { Button,Modal, Row, Form, Col, Input } from "antd";
import LoadingComponent from "@components/Loading";
import { useTranslation } from "react-i18next";
import FormComponent from "@components/Form";
import { handleNhanVienTraBK } from "./services";
const { TextArea } = Input;

function NVTraBKModal({
  id,
  visible,
  setFetchDataTable,
  closeModal,
  lyDoTraLai
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    handleNhanVienTraBK(
      id,
      values,
      setLoading,
      setFetchDataTable,
      closeModal,
    );
  };

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={"Trả lại bảng kê"}
        visible={visible}
        onCancel={closeModal}
        footer={null}
        width="40%"
      >
        <FormComponent
          form={form}
          name="form-nv-ky-so"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
          }}
        >
          <Row gutter={24}>
            <h4 style={{marginLeft: 12}}><span style={{color: "red"}}>Thông tin bảng kê sẽ bị xóa.</span> Sau khi trả bảng kê, bạn có thể
            cập nhật kết quả đối soát và thực hiện Xuất ghi lại.</h4>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Lý do trả lại" name="lyDoTraLai" required>
                <TextArea rows={3} defaultValue={lyDoTraLai} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                  {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-nv-ky-so"
                loading={loading}
              >
                Đồng ý
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(NVTraBKModal);
