import { APIAuthCommon } from "@apiUrl/ghi-chi-so/auth-todoi";
import { APIBaoCaoGCS } from "@apiUrl/ghi-chi-so/bao-cao-ghi-chi-so";
import { APICfgBangKe } from "@apiUrl/ghi-chi-so/cau-hinh-bang-ke";
import { APICommonGCS } from "@apiUrl/ghi-chi-so/common";
import { APITraCuuTimKiem } from "@apiUrl/ghi-chi-so/danh-sach-so-gcs";
import { APIDieuHanhGCS } from "@apiUrl/ghi-chi-so/dieu-hanh-gcs";
import { APIBangKeGCS } from "@apiUrl/ghi-chi-so/in-bang-ke";
import { APIKyBangKe } from "@apiUrl/ghi-chi-so/ky-bang-ke";
export const GCS_Endpoint = {
  //Ghi chỉ số
  NHAN_VIEN_THEO_DON_VI: APICommonGCS.COMMON_LIST_NHAN_VIEN_THEO_DON_VI,
  NHAN_VIEN_THEO_DOI: APICommonGCS.COMMON_LIST_NHAN_VIEN_THEO_DOI,
  COMMON_LIST_TO_DOI: APICommonGCS.COMMON_LIST_TO_DOI,
  AUTH_LIST_TO_DOI: APIAuthCommon.AUTH_LIST_TO_DOI,
  LIST_SO_GCS: APICommonGCS.COMMON_LIST_SO_GCS,
  LIST_PHAN_CONG_GCS_DH: APIDieuHanhGCS.LIST_PHAN_CONG_DH,
  LIST_PHAN_CONG_GCS_DT: APIDieuHanhGCS.LIST_PHAN_CONG_DT,
  PHAN_CONG_GCS: APIDieuHanhGCS.PHAN_CONG,
  HUY_PHAN_CONG_GCS: APIDieuHanhGCS.HUY_PHAN_CONG,
  CHECK_PHAN_CONG: APIDieuHanhGCS.CHECK_PHAN_CONG,
  GCS_HUY_PC_NHIEU: APIDieuHanhGCS.GCS_HUY_PC_NHIEU,
  LIST_LAY_DU_LIEU_TU_CMIS: APIDieuHanhGCS.LIST_LAY_DU_LIEU_TU_CMIS,
  LIST_DAY_SO_VE_CMIS: APIDieuHanhGCS.LIST_DAY_SO_VE_CMIS,
  HUY_DAY_SO_VE_CMIS: APIDieuHanhGCS.HUY_DAY_SO_VE_CMIS,

  // Ghi chỉ số - in bảng kê
  LIST_MA_SO_BANG_KE: APIBangKeGCS.LIST_MA_SO,
  LIST_MA_SO_BANG_KE_NO_TO_DOI: APIBangKeGCS.LIST_MA_SO_BANG_KE_NO_TO_DOI,
  XUAT_BANG_KE: APIBangKeGCS.XUAT_BANG_KE,

  LIST_BIEN_BAN_KSCS: APIBangKeGCS.LIST_BIEN_BAN_KSCS,
  XUAT_PDF_BANG_KE_KSCS: APIBangKeGCS.XUAT_PDF_BANG_KE_KSCS,
  XUAT_EXCEL_BANG_KE_KSCS: APIBangKeGCS.XUAT_EXCEL_BANG_KE_KSCS,

  BANG_KE_CHI_SO: APIBangKeGCS.BANG_KE_CHI_SO,

  BANG_KE_KH_PHAI_GHI_PMAX: APIBangKeGCS.BANG_KE_KH_PHAI_GHI_PMAX,
  XUAT_PDF_PMAX: APIBangKeGCS.XUAT_PDF_PMAX,
  XUAT_EXCEL_CONGTO_SLBT: APIBangKeGCS.XUAT_EXCEL_CONGTO_SLBT,
  XUAT_PDF_CONGTO_SLBT: APIBangKeGCS.XUAT_PDF_CONGTO_SLBT,
  XUAT_EXCEL_PMAX: APIBangKeGCS.XUAT_EXCEL_PMAX,
  PHUC_TRA_PAGING: APIBangKeGCS.PHUC_TRA_PAGING,
  XUAT_PDF_ANH_CTSLBT: APIBangKeGCS.XUAT_PDF_ANH_CTSLBT,
  XUAT_PDF_PHUC_TRA_CHI_SO: APIBangKeGCS.XUAT_PDF_PHUC_TRA_CHI_SO,
  XUAT_EXCEL_PHUC_TRA_CHI_SO: APIBangKeGCS.XUAT_EXCEL_PHUC_TRA_CHI_SO,
  XUAT_PDF_LICH_SU_PHUC_TRA: APIBangKeGCS.XUAT_PDF_LICH_SU_PHUC_TRA,
  XUAT_EXCEL_LICH_SU_PHUC_TRA: APIBangKeGCS.XUAT_EXCEL_LICH_SU_PHUC_TRA,
  LIST_PHIEU_XAC_MINH: APIBangKeGCS.LIST_PHIEU_XAC_MINH,
  XUAT_PDF_PHIEU_XAC_MINH: APIBangKeGCS.XUAT_PDF_PHIEU_XAC_MINH,
  XUAT_PDF_TINH_TRANG_CTO: APIBangKeGCS.XUAT_PDF_TINH_TRANG_CTO,
  XUAT_PDF_CUSTOMER_NO_CAP: APIBangKeGCS.XUAT_PDF_CUSTOMER_NO_CAP,
  XUAT_EXCEL_CUSTOMER_NO_CAP: APIBangKeGCS.XUAT_EXCEL_CUSTOMER_NO_CAP,

  THOI_GIAN_THUC_HIEN_GCS: APIBangKeGCS.THOI_GIAN_THUC_HIEN_GCS,
  XUAT_PDF_THOI_GIAN_GCS: APIBangKeGCS.XUAT_PDF_THOI_GIAN_GCS,
  XEM_CHI_SO_BANG_KE: APIBangKeGCS.XEM_CHI_SO_BANG_KE,
  // bang ke san luong bat thuong
  LIST_BIEN_BAN_SLBT: APIBangKeGCS.LIST_BIEN_BAN_SLBT,
  LIST_BIEN_BAN_CUSTOMER_NO_CAP: APIBangKeGCS.LIST_BIEN_BAN_CUSTOMER_NO_CAP,
  XUAT_PDF_KHONG_DOI_SOAT: APIBangKeGCS.XUAT_PDF_KHONG_DOI_SOAT,
  XUAT_PDF_DA_DOI_SOAT: APIBangKeGCS.XUAT_PDF_DA_DOI_SOAT,
  XUAT_PDF_DOI_SOAT_DAT: APIBangKeGCS.XUAT_PDF_DOI_SOAT_DAT,
  XUAT_PDF_DOI_SOAT_KHONG_DAT: APIBangKeGCS.XUAT_PDF_DOI_SOAT_KHONG_DAT,
  XUAT_PDF_CONG_TO_TT_BAT_THUONG: APIBangKeGCS.XUAT_PDF_CONG_TO_TT_BAT_THUONG,

  // ghi chỉ số - báo cáo ghi chỉ số
  LIST_SO_GCS_DV_DOI_QL: APIBaoCaoGCS.LIST_SO_GCS_DV_DOI_QL,
  EXCEL_SO_GCS_DV_DOI_QL: APIBaoCaoGCS.EXCEL_SO_GCS_DV_DOI_QL,
  PDF_SO_GCS_DV_DOI_QL: APIBaoCaoGCS.PDF_SO_GCS_DV_DOI_QL,
  LIST_MA_SO_BAO_CAO_GCS: APIBaoCaoGCS.LIST_MA_SO_BAO_CAO_GCS,

  LIST_SO_GCS_X_NGAY: APIBaoCaoGCS.LIST_SO_GCS_X_NGAY,
  EXCEL_SO_GCS_X_NGAY: APIBaoCaoGCS.EXCEL_SO_GCS_X_NGAY,
  PDF_SO_GCS_X_NGAY: APIBaoCaoGCS.PDF_SO_GCS_X_NGAY,
  LIST_SO_GCS_XUAT_TRUOC_NGAY: APIBaoCaoGCS.LIST_SO_GCS_XUAT_TRUOC_NGAY,
  EXCEL_SO_GCS_XUAT_TRUOC_NGAY: APIBaoCaoGCS.EXCEL_SO_GCS_XUAT_TRUOC_NGAY,
  PDF_SO_GCS_XUAT_TRUOC_NGAY: APIBaoCaoGCS.PDF_SO_GCS_XUAT_TRUOC_NGAY,

  LIST_BKDS_KH_THAY_CS: APIBaoCaoGCS.LIST_BKDS_KH_THAY_CS,
  EXCEL_BKDS_KH_THAY_CS: APIBaoCaoGCS.EXCEL_BKDS_KH_THAY_CS,
  PDF_BKDS_KH_THAY_CS: APIBaoCaoGCS.PDF_BKDS_KH_THAY_CS,
  LIST_SO_CHUA_KY_SO: APIBaoCaoGCS.LIST_SO_CHUA_KY_SO,
  EXCEL_SO_CHUA_KY_SO: APIBaoCaoGCS.EXCEL_SO_CHUA_KY_SO,
  PDF_SO_CHUA_KY_SO: APIBaoCaoGCS.PDF_SO_CHUA_KY_SO,

  TONG_HOP_ANH_CHUP_LIST: APIBaoCaoGCS.TONG_HOP_ANH_CHUP_LIST,
  TONG_HOP_ANH_CHUP_EXCEL: APIBaoCaoGCS.TONG_HOP_ANH_CHUP_EXCEL,
  TONG_HOP_ANH_CHUP_PDF: APIBaoCaoGCS.TONG_HOP_ANH_CHUP_PDF,

  //  END
  LAY_DU_LIEU_TU_CMIS: APIDieuHanhGCS.LAY_DU_LIEU_TU_CMIS,
  LIST_CAP_NHAT_DU_LIEU_SO: APIDieuHanhGCS.LIST_CAP_NHAT_DU_LIEU_SO,
  LIST_DS_DOI_SOAT_DL: APIDieuHanhGCS.LIST_DS_DOI_SOAT_DL,
  TRA_SO_VE_CMIS: APIDieuHanhGCS.TRA_SO_VE_CMIS,
  CHECK_TRA_SO_VE_CMIS: APIDieuHanhGCS.CHECK_TRA_SO_VE_CMIS,
  LAY_CHI_SO_MDMS: APIDieuHanhGCS.LAY_CHI_SO_MDMS,
  DAY_SO_VE_CMIS: APIDieuHanhGCS.DAY_SO_VE_CMIS,
  CHUYEN_HHU: APIDieuHanhGCS.CHUYEN_HHU,
  CHUYEN_MTB: APIDieuHanhGCS.CHUYEN_MTB,
  XUAT_GHI_LAI_MTB: APIDieuHanhGCS.XUAT_GHI_LAI_MTB,
  TU_DONG_DS: APIDieuHanhGCS.TU_DONG_DS,
  HOAN_THANH_DS: APIDieuHanhGCS.HOAN_THANH_DS,
  LIST_SO_GCS_DOI_SOAT: APIDieuHanhGCS.LIST_SO_GCS_DOI_SOAT,
  LIST_SO_GCS_DOI_SOAT_DH: APIDieuHanhGCS.LIST_SO_GCS_DOI_SOAT_DH,
  LIST_SO_GCS_DOI_SOAT_DT: APIDieuHanhGCS.LIST_SO_GCS_DOI_SOAT_DT,
  CHECKBOX_DS: APIDieuHanhGCS.CHECKBOX_DS,
  CAP_NHAT_CHI_SO: APIDieuHanhGCS.CAP_NHAT_CHI_SO,
  GCS_NV_TAO_BANG_KE: APIDieuHanhGCS.GCS_NV_TAO_BANG_KE,

  //ký bảng kê
  LIST_NV_GCS_KBK: APIKyBangKe.LIST_NV_GCS_KBK,
  LIST_DOI_TRUONG_KBK: APIKyBangKe.LIST_DOI_TRUONG_KBK,
  LIST_NV_DIEU_HANH_KBK: APIKyBangKe.LIST_NV_DIEU_HANH_KBK,
  LIST_PHONG_KINH_DOANH_KBK: APIKyBangKe.LIST_PHONG_KINH_DOANH_KBK,
  NHAN_VIEN_XAC_NHAN: APIKyBangKe.NHAN_VIEN_XAC_NHAN,
  NHAN_VIEN_TRA_BK: APIKyBangKe.NHAN_VIEN_TRA_BK,
  DOI_TRUONG_KY_SO: APIKyBangKe.DOI_TRUONG_KY_SO,
  DIEU_HANH_KY_SO: APIKyBangKe.DIEU_HANH_KY_SO,
  PHONG_KD_KY_SO: APIKyBangKe.PHONG_KD_KY_SO,
  DOI_TRUONG_TRA_LAI_BK: APIKyBangKe.DOI_TRUONG_TRA_LAI_BK,
  DIEU_HANH_TRA_LAI_BK: APIKyBangKe.DIEU_HANH_TRA_LAI_BK,
  PHONG_KD_TRA_LAI_BK: APIKyBangKe.PHONG_KD_TRA_LAI_BK,
  TRA_LAI_BK_CHO_PKD: APIKyBangKe.TRA_LAI_BK_CHO_PKD,
  DOI_TRUONG_XAC_NHAN: APIKyBangKe.DOI_TRUONG_XAC_NHAN,
  DIEU_HANH_XAC_NHAN: APIKyBangKe.DIEU_HANH_XAC_NHAN,
  PHONG_KD_XAC_NHAN: APIKyBangKe.PHONG_KD_XAC_NHAN,
  //Cấu hình ký bảng kê
  LIST_CAU_HINH_KBK: APICfgBangKe.LIST_CAU_HINH_KBK,
  CREATE_CAU_HINH_KBK: APICfgBangKe.CREATE_CAU_HINH_KBK,
  UPDATE_CAU_HINH_KBK: APICfgBangKe.UPDATE_CAU_HINH_KBK,
  REMOVE_CAU_HINH_KBK: APICfgBangKe.REMOVE_CAU_HINH_KBK,
  LIST_LOAI_BK: APICfgBangKe.LIST_LOAI_BK,
  GCS_CH_NV_GCS: APICfgBangKe.GCS_CH_NV_GCS,

  //Tra cứu tìm kiếm
  DANH_SACH_SO_GCS: APITraCuuTimKiem.DANH_SACH_SO_GCS,
  LICH_SU_SO_GCS: APITraCuuTimKiem.LICH_SU_SO_GCS,
  GCS_XUAT_LS_SO: APITraCuuTimKiem.GCS_XUAT_LS_SO,

  // Ký số
  GCS_KY_SO: APIKyBangKe.KY_SO,

  //ky hsm
  GCS_PKD_KY_HSM: APIKyBangKe.GCS_PKD_KY_HSM,
  GCS_DT_KY_HSM: APIKyBangKe.GCS_DT_KY_HSM,
  GCS_DH_KY_HSM: APIKyBangKe.GCS_DH_KY_HSM,
};
