import { BASE_API_URL_BAO_CAO } from "@utils/constants";

export const APIBCCongto = {
  //Báo cáo tổng hợp sô lượng công tơ
  BCCT_TH_SOLUONG_CONGTO_TVQ_60NGAY: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/thslct-thao-ve-qua-60-ngay-chua-kdqv`,
  BCCT_TH_SOLUONG_CONGTO_TVQ_60NGAY_EXCEL: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/thslct-thao-ve-qua-60-ngay-chua-kdqv`,
  BCCT_CHITIET_CONG_TO_GUI_TRA_KHO_CAPTREN: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/chi-tiet-cong-to-gui-tra-lai-kho-cap-tren`,
  BCCT_CHITIET_CONG_TO_GUI_TRA_KHO_CAPTREN_EXCEL: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/chi-tiet-cong-to-gui-tra-lai-kho-cap-tren`,
  BCCT_CHI_TIET_CONG_TO_60NGAY: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/ctct-thao-ve-qua-60-ngay-ckdqv`,
  BCCT_CHI_TIET_CONG_TO_60NGAY_EXCEL: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/ctct-thao-ve-qua-60-ngay-ckdqv`,
  BCCT_CHI_TIET_CONG_TO_GUITRA_KHO_EXCEL: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/chi-tiet-cong-to-gui-tra-lai-kho-cap-tren`,
  BCCT_CHI_TIET_CONG_TO_TREN_NHAN_KIEM_DINH_EXCEL: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/chi-tiet-cong-to-tren-nhan-kiem-dinh`,
  BCCT_THSL_CONG_TO_TREN_CAP_TREN_BO_EXCEL: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/thsl-cong-to-cap-tren-phan-bo`,
  BCCT_THSL_CONG_TO_GUI_TRA_LAI_KHO_EXCEL: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/thsl-cong-to-gui-tra-lai-kho-cap-tren`,
  BCCT_THSL_CONG_TO_CAP_TREN_NHANKD_EXCEL: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/thsl-cong-to-cap-tren-nhan-kiem-dinh`,
  BCCT_THSL_CONG_TO_CAP_TREN_PHAN_BO_PDF: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/thsl-cong-to-cap-tren-phan-bo`,
  BCCT_CT_CONG_TO_TREN_CAP_TREN_BO: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/chi-tiet-cong-to-cap-tren-phan-bo`,
  BCCT_THSL_CONG_TO_KHONG_DUNG_6_THANG_TRO_LEN: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/tong-hop-so-luong-cong-to-khong-dung-6-thang-tro-len`,
  BCCT_CT_CONG_TO_K_DUNG_6_THANG_TRO_LEN: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/chi-tiet-cong-to-khong-dung-6-thang-tro-len`,
  BCCT_THSL_CONG_TO_CAP_TREN_THANH_LY: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/tong-hop-so-luong-cong-to-cap-tren-thanh-ly`,
  BCCT_CT_CONG_TO_CAP_TREN_THANH_LY: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/chi-tiet-cong-to-cap-tren-thanh-ly`,
  BCCT_CT_NHAP_KHO_CONG_TO: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/chi-tiet-nhap-kho-cong-to`,
  BCCT_BCTH_CONG_TO_CAP_TREN_MOI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/bao-cao-tong-hop-cong-to-cap-tren-moi`,
  BCCT_CT_CONG_TO_6_THANG_KHONG_LEN_CHI_SO_MOI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/chi-tiet-cong-to-6-thang-khong-len-chi-so-moi`,
  BCCT_CT_CONG_TO_GUI_KIEM_DINH_MOI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/chi-tiet-cong-to-gui-kiem-dinh-moi`,
  BCCT_CT_CONG_TO_PHAN_BO_MOI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/chi-tiet-cong-to-phan-bo-moi`,
  CT_KIEM_DINH_CONG_TO_MOI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/chi-tiet-kiem-dinh-cong-to-moi`,
  BCCT_CT_NHAP_KHO_SAU_KD_CONG_TO_MOI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/chi-tiet-nhap-kho-sau-kiem-dinh-cong-to-moi`,
  BCCT_TH_CONG_TAC_KD_CONG_TO_TU_TI_MOI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/tong-hop-cong-tac-kiem-dinh`,
  BCCT_TH_CONG_TO_DEN_HAN_THAY_DINH_KY_MOI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/tong-hop-cong-to-den-han-thay-dinh-ky-moi`,
  BCCT_THCT_PHAN_BO_MOI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/tong-hop-cong-to-phan-bo-moi`,
  BCCT_TH_THIET_BI_MOI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/tong-hop-thiet-bi-moi`,

  //Báo cáo Tổng hợp thiết bị đo đếm 2 cấp
  BCCT_SLTB_DD_TON_DUOI_LUOI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/sltb-do-dem-ton-duoi-luoi`,
  BCCT_SLTB_DD_TON_DUOI_LUOI_CKD: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/sltb-do-dem-ton-duoi-luoi-ckd`,
  BCCT_SLTB_DD_TON_CHUA_SU_DUNG: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/sltb-do-dem-ton-chua-su-dung`,
  BCCT_SLTB_DD_TON_QUA_60_NGAY: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/sltb-do-dem-ton-qua-60-ngay-csd`,
  BCCT_SLTB_DD_DANG_VAN_HANH_TL: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/sltb-do-dem-dang-van-hanh-ten-luoi`,
  BCCT_SLTB_DD_DANG_VAN_HANH_TL_DHKD: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/sltb-do-dem-dang-van-hanh-ten-luoi-dhkd`,
  BCCT_SLTB_DD_GUI_KD_QUAYVONG: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/sltb-do-dem-gui-kiem-dinh-quay-vong`,
  BCCT_SLTB_DD_NHAN_KD_QUAYVONG: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/stlb-do-dem-nhan-kiem-dinh-quay-vong`,
  BCCT_SLTB_DD_GUI_KD_LAI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/stlb-do-dem-gui-kiem-dinh-lai`,
  BCCT_SLTB_DD_NHAN_KD_LAI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/stlb-do-dem-nhan-kiem-dinh-lai`,
  BCCT_SLTB_DD_GUI_TRA: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/stlb-do-dem-gui-tra`,
  BCCT_SLTB_DD_HIEN_GUI_TRA: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/stlb-do-dem-hien-gui-tra`,
  BCCT_SLTB_DD_GUI_THANH_LY: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/stlb-do-dem-gui-thanh-ly`,
  BCCT_SLTB_DD_DA_THANH_LY: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/stlb-do-dem-da-thanh-ly`,
  BCCT_SLTB_DD_NHAN_GUI_THANH_LY: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/stlb-do-dem-nhan-gui-thanh-ly`,
  BCCT_SLTB_DD_PHAN_BO: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/stlb-do-dem-phan-bo`,
  BCCT_SLTB_DD_DA_NHAN_PHAN_BO: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/stlb-do-dem-da-nhan-phan-bo`,
  BCCT_SLCL_CONG_TO_TD_SAU_TBA_CC: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/so-luong-chung-loai-cong-to-tren-duoi-sau-tba-cong-cong`,
  BCCT_THTH_VAN_HANH_TBDD_TREN_LUOI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/thth-van-hanh-thiet-bi-do-dem`,
  BCCT_TBDD_DEN_HAN_KD: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/thtb-den-han-kiem-dinh`,
  BCCT_THTB_TON_KHO: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/thong-hop-thiet-bi-ton-kho`,
  BCCT_THTB_DUOI_LUOI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthtbd2c/thong-hop-thiet-bi-duoi-luoi`,

  // Báo cáo công tác quản lý thiết bị đo đếm
  BCCT_BAO_CAO_NANG_SUAT_SU_DUNG_CT_TT_TON_KHO: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/bcncsd-cong-to-trong-kho`,
  BCCT_DS_TINH_HINH_CONG_TO_TRONG_KHO_TON_KHO: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/danh-sach-tinh-hinh-cong-to-ton-kho`,
  BCCT_THEM_TINH_HINH_CONG_TO_TRONG_KHO_TON_KHO: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/them-tinh-hinh-cong-to-ton-kho`,
  BCCT_BAO_CAO_NHU_CAU_SU_DUNG_CT_TRONG_KHO_DUOI_LUOI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/bao-cao-nhu-cau-su-dung-cong-to-ton-kho-duoi-luoi`,
  BCCT_DS_TINH_HINH_CONG_TO_TRONG_KHO_DUOI_LUOI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/danh-sach-tinh-hinh-cong-to-ton-kho-duoi-luoi1`,
  BCCT_THEM_TINH_HINH_CONG_TO_TON_KHO_DUOI_LUOI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/them-tinh-hinh-cong-to-ton-kho-duoi-luoi`,
  BCCT_BAO_CAO_TONG_HOP_DO_DEM_CL: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/bcth-do-dem-theo-chung-loai`,
  BCCT_TH_CT_MHC_TL_PT_MOI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/thct-mat-hong-chay-tl-ptm`,
  BCCT_TH_TH_KD_CT: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/thth-kiem-dinh-cong-to`,
  BCCT_LOAI_CONG_TO: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/danh-muc-loai-cong-to`,

  // tổng hợp tình hình kiểm tra định kỳ công tơ
  BCCT_TH_TH_KT_DK_CT: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/thth-kiem-tra-dinh-ky-cong-to`,
  BCCT_DANH_SACH_KT_DK_CT: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/danh-sach-kiem-tra-dinh-ky-cong-to`,
  BCCT_THEM_KT_DK_CT: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/them-kiem-tra-dinh-ky-cong-to`,
  BCCT_SUA_KT_DK_CT: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/sua-kiem-tra-dinh-ky-cong-to`,

  //Tổng hợp tình hình phúc tra công tơ
  BCCT_TH_THPT_CT: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/thth-phuc-tra-cong-to`,
  BCCT_DANH_SACH_THPT_CT: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/danh-sach-tinh-hinh-phuc-tra-cong-to`,
  BCCT_THEM_THPT_CT: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/them-tinh-hinh-phuc-tra-cong-to`,
  BCCT_SUA_PHUC_TRA_CONG_TO: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/sua-tinh-hinh-phuc-tra-cong-to`,

  //Tổng hợp tình hình thay công tơ định kỳ
  BCCT_TH_TH_THAY_CT_DK: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/thth-thay-cong-to-dinh-ky`,
  BCCT_THTH_THAY_TUTI_DK: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcctqltbdd/thth-thay-tuti-dinh-ky`,

  //Kho thiết bị
  BCCT_DANH_MUC_KHO_THIET_BI: `${BASE_API_URL_BAO_CAO}/bckd/bcct/bcthslct/danh-muc-kho-thiet-bi`,
};
