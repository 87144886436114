import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import { Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useCallback, useState } from "react";
import styled from "styled-components";
import BieuBcDsYeuCauCoSDT from "./bieu-bc-ds-yeu-cau-co-sdt-nhieu-ho-so";
import BieuThslCaiDatApp from "./bieu-thsl-cai-dat-app";
import BieuThslSuDungApp from "./bieu-thsl-su-dung-app";
import Bieu17ThslTnCacHinhThuc from "./bieu17-thsl-tn-cac-hinh-thuc";
import { selectBCTongHop } from "./service";

export default function BaoCaoTongHop() {
  const [screen, setScreen] = useState(1);
  const { Option } = Select;
  const [loading] = useState(false);
  const [form] = Form.useForm();

  const handleChange = useCallback((values) => {
    setScreen(values);
  }, []);

  const onFinish = (values) => {
    console.log("values", values);
  };

  return (
    <Fragment>
      {screen === 4 ? (
        <BieuBcDsYeuCauCoSDT
          screen={screen}
          setScreen={setScreen}
          handleChange={handleChange}
        />
      ) : screen === 3 ? (
        <BieuThslCaiDatApp
          screen={screen}
          setScreen={setScreen}
          handleChange={handleChange}
        />
      ) : screen === 2 ? (
        <BieuThslSuDungApp
          screen={screen}
          setScreen={setScreen}
          handleChange={handleChange}
        />
      ) : screen === 1 ? (
        <Bieu17ThslTnCacHinhThuc
          screen={screen}
          setScreen={setScreen}
          handleChange={handleChange}
        />
      ) : (
        <Container>
          <ContentWrapper>
            <Spin spinning={loading}>
              <FormComponent
                onFinish={onFinish}
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="reportType"
                      label="Tên báo cáo"
                      labelAlign="left"
                    >
                      <Select
                        placeholder="Chọn báo cáo"
                        options={selectBCTongHop}
                        onChange={(e) => handleChange(e)}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="unitId"
                      label="Phân loại"
                      labelAlign="left"
                    >
                      <Select defaultValue={"0"}>
                        <Option value="0">
                          Thông báo TĐ lần 1 (SMS), Dịch vụ điện tử (SMS)
                        </Option>
                        <Option value="1">Thông báo TĐ lần 1 (Zalo)</Option>
                        <Option value="2">Thông báo TĐ lần 1 (App CSKH)</Option>
                        <Option value="3">
                          Tin nhắn dịch vụ khác qua Cổng thông báo CSKH
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item
                      name="reportStartDate"
                      label="Ngày bắt đầu"
                      labelAlign="left"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <DatePickerComponent
                        picker="Day"
                        formKey="reportStartDate"
                        form={form}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="reportEndDate"
                      label="Ngày kết thức"
                      labelAlign="right"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <DatePickerComponent
                        picker="Day"
                        formKey="reportEndDate"
                        form={form}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </FormComponent>
              <Divider
                className="section-text-line"
                orientation="left"
              ></Divider>
            </Spin>
          </ContentWrapper>
        </Container>
      )}
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
