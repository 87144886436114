import { APICONGTOTHAO } from "@apiUrl/cong-to-thao";

export const CONGTOTHAO_Endpoint = {
  //Cập nhật công tơ tháo
  LIST_CAP_NHAT_CONG_TO_THAO: APICONGTOTHAO.LIST_CAP_NHAT_CONG_TO_THAO,
  UPLOAD_EXCEL: APICONGTOTHAO.UPLOAD_EXCEL,
  CAP_NHAT_CONG_TO_THAO: APICONGTOTHAO.CAP_NHAT_CONG_TO_THAO,
  CAP_NHAT_MDMS: APICONGTOTHAO.CAP_NHAT_MDMS,
  CAP_NHAT_MDMS_ALL: APICONGTOTHAO.CAP_NHAT_MDMS_ALL,
  CTOTHAO_LAY_DU_LIEU_CMIS: APICONGTOTHAO.CTOTHAO_LAY_DU_LIEU_CMIS,
  DOWNLOAD_FILE_SAMPLE: APICONGTOTHAO.DOWNLOAD_FILE_SAMPLE,
  CTOTHAO_LIST_BIEN_BAN: APICONGTOTHAO.CTOTHAO_LIST_BIEN_BAN,
  CTOTHAO_LIST_BIEN_BAN_WEB:APICONGTOTHAO.CTOTHAO_LIST_BIEN_BAN_WEB,
  CTOTHAO_QUET: APICONGTOTHAO.CTOTHAO_QUET,
  CTOTHAO_UPDATE_TRANG_THAI: APICONGTOTHAO.CTOTHAO_UPDATE_TRANG_THAI,
  CTOTHAO_CAU_HINH_CSO_GAN_NHAT: APICONGTOTHAO.CTOTHAO_CAU_HINH_CSO_GAN_NHAT,
  CTOTHAO_DONG_BO_CMIS: APICONGTOTHAO.CTOTHAO_DONG_BO_CMIS,
  CTOTHAO_DONG_BO_VE_WEB: APICONGTOTHAO.CTOTHAO_DONG_BO_VE_WEB,
  CTOTHAO_DS_CONG_TO_DB: APICONGTOTHAO.CTOTHAO_DS_CONG_TO_DB,
  CTOTHAO_CHI_TIET_DONG_BO: APICONGTOTHAO.CTOTHAO_CHI_TIET_DONG_BO,

  //Đối soát chỉ số công tơ tháo
  SO_LUONG_DOI_SOAT: APICONGTOTHAO.SO_LUONG_DOI_SOAT,

  //Báo cáo
  LIST_BAO_CAO_CONG_TO_THAO: APICONGTOTHAO.LIST_BAO_CAO_CONG_TO_THAO,
  LIST_BAO_CAO_CONG_TO_THAO_PDF: APICONGTOTHAO.LIST_BAO_CAO_CONG_TO_THAO_PDF,
  LIST_BAO_CAO_CONG_TO_THAO_EXCEL: APICONGTOTHAO.LIST_BAO_CAO_CONG_TO_THAO_EXCEL,

  // Cấu hình chỉ số công tơ tháo
  LIST_CAU_HINH_CHI_SO: APICONGTOTHAO.LIST_CAU_HINH_CHI_SO,
  SAVE_CAU_HINH_CHI_SO: APICONGTOTHAO.SAVE_CAU_HINH_CHI_SO,
  UPDATE_CAU_HINH_CHI_SO: APICONGTOTHAO.UPDATE_CAU_HINH_CHI_SO,
  DELETE_CAU_HINH_CHI_SO: APICONGTOTHAO.DELETE_CAU_HINH_CHI_SO,
  CTOTHAO_NHAN_VIEN: APICONGTOTHAO.CTOTHAO_NHAN_VIEN
};