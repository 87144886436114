import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  STATUSCODE_200,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { authGetData } from "@utils/request";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { handleDownload, selectBaoCaoDoiSoatEmail } from "../services";

export default function DsKhDangKySuDungEmail(props) {
  const { screen, handleChange } = props;
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [dataEmail, setDataDanhSachKHEmail] = useState([]);
  const location = useLocation();
  const user = useSelector(authSelector);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    MaDonVi: user.user.unitCode,
    ...parseParams(location.search),
  });

  useEffect(() => {
    form.resetFields();
  }, [form]);

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const handleSearch = useCallback((values) => {
    const queryString = buildQueryString(
      parseParams({
        MaDonVi: values.MaDonVi,
      })
    );

    authGetData({
      url: `${Endpoint.DANH_SACH_KH_DANG_KY_EMAIL}?${buildQueryString(
        parseParams(queryString)
      )}`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          setDataDanhSachKHEmail(res.data);
        }
      },
    });
  }, []);

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      width: 50,
      align: "center",
      render: (a, b, index) => {
        return (
          (filterConditions.pageIndex - 1) * filterConditions.pageSize +
          (index + 1)
        );
      },
    },
    {
      title: "Đơn vị",
      dataIndex: "MA_DVIQLY",
      key: "MA_DVIQLY",
      align: "center",
      width: 100,
    },
    {
      title: "Mã số GCS",
      dataIndex: "SO_GCS",
      key: "SO_GCS",
      align: "center",
      width: 150,
    },
    {
      title: "Tổ",
      dataIndex: "MA_TO",
      key: "MA_TO",
      align: "center",
      width: 100,
    },
    {
      title: "Mã khách hàng",
      dataIndex: "MA_KHANG",
      key: "MA_KHANG",
      width: 100,
    },
    {
      title: "Tên khách hàng",
      dataIndex: "TEN_KH_LHE",
      key: "TEN_KH_LHE",
      width: 150,
    },
    {
      title: "Địa chỉ",
      dataIndex: "DCHI_KH_LHE",
      key: "DCHI_KH_LHE",
      width: 150,
    },
    {
      title: "Số điện thoại",
      dataIndex: "DTHOAI_DVU",
      key: "DTHOAI_DVU",
      width: 100,
    },
    {
      title: "Email",
      dataIndex: "EMAIL",
      key: "EMAIL",
      width: 100,
    },
  ];
  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <FormComponent
            onFinish={handleSearch}
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            initialValues={{
              reportType: screen,
            }}
          >
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="reportType"
                  label="Tên báo cáo"
                  labelAlign="left"
                >
                  <Select
                    formKey="reportType"
                    form={form}
                    options={selectBaoCaoDoiSoatEmail}
                    onChange={() =>
                      handleChange(form.getFieldValue("reportType"))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="MaDonVi" label="Đơn vị" labelAlign="left">
                  <Selection
                    url={Endpoint.GET_TEAM_LIST_CODE}
                    formKey="MaDonVi"
                    form={form}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Button type="primary" className="mr-5px" htmlType="submit">
                Xem báo cáo
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  handleDownload(form.getFieldValue(), setLoading);
                }}
              >
                Xuất Excel
              </Button>
            </Row>
          </FormComponent>
          <Divider className="section-text-line" orientation="left"></Divider>
        </ContentWrapper>
        <TableComponent
          header={"Danh sách khách hàng đăng ký sử dụng Email"}
          columns={customColumn(columns, filterConditions)}
          // dataSource={data}
          dataSource={dataEmail}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
          loading={loading}
          filterConditions={filterConditions}
          scrollX={1400}
        />
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  margin-top: 40px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
