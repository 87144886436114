import styled from "styled-components";
import { Row, Col } from "antd";

export const Container = styled.div`
  padding: 120px 0;
`;

export const ContainerInfo = styled(Row)`
  width: 60%;
  margin: 0 auto !important;
  background: #fafafa;
  padding: 40px;
`;

export const ContainerInfoCol = styled(Col)`
  text-align: center;
`;

export const ContainerH1 = styled.h1`
  font-size: 20px;
  font-weight: 600;
`;

export const ContainerInfoBody = styled.div`
  text-align: left;

  span {
    font-weight: bold;
  }
  .total {
  }
  .total-success {
    color: #52c41a;
  }

  .total-fail {
    color: #ff8e8f;
  }
`;
