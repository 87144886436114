import { BASE_API_URL_BAO_CAO } from "@utils/constants";
import { BASE_API_URL_CSKH } from "@utils/constants";

export const APIBCTinhHinhSMS = {
  BCTHSMS_CT_TIN_NHAN_THEO_CU_PHAP: `${BASE_API_URL_BAO_CAO}/bckd/bcthsms/momt/chi-tiet-tin-nhan-theo-cu-phap`,
  BCTHSMS_DS_TIN_NHAN_KHONG_THANH_CONG: `${BASE_API_URL_BAO_CAO}/bckd/bcthsms/momt/ds-tin-nhan-khong-thanh-cong`,
  BCTHSMS_DS_TIN_NHAN_MO_THAY_DOI_SO_DIEN_THOAI: `${BASE_API_URL_BAO_CAO}/bckd/bcthsms/momt/ds-tin-nhan-mo-thay-doi-so-dt`,
  BCTHSMS_DS_TIN_NHAN_THANH_CONG_THEO_NGAY: `${BASE_API_URL_BAO_CAO}/bckd/bcthsms/momt/ds-tin-nhan-thanh-cong-theo-ngay`,
  BCTHSMS_THSL_TIN_NHAN_THEO_TUNG_DICH_VU: `${BASE_API_URL_BAO_CAO}/bckd/bcthsms/momt/thsl-tin-nhan-theo-tung-dich-vu`,
  BCTHSMS_CT_TIN_NHAN_SAI_CU_PHAP: `${BASE_API_URL_BAO_CAO}/bckd/bcthsms/momt/chi-tiet-tin-nhan-sai-cu-phap`,
  BCTHSMS_CT_TIN_NHAN_8088: `${BASE_API_URL_CSKH}/baocao/chi-tiet-tin-nhan-8088`,
  BCTHSMS_DOI_SOAT_TIN_NHAN_8088: `${BASE_API_URL_CSKH}/baocao/doi-soat-tin-nhan-8088`,

  BCTHSMS_BC_THSL_ANH_GHI_CHI_SO: `${BASE_API_URL_BAO_CAO}/bckd/bcthsms/thslanhgcs/tong-hop-so-luong-anh-gcs`,
  BCTHSMS_BC_CHI_TIET_ANH_GHI_CHI_SO: `${BASE_API_URL_BAO_CAO}/bckd/bcthsms/thslanhgcs/chi-tiet-anh-gcs`,
  BCTHSMS_TIM_KIEM: `${BASE_API_URL_BAO_CAO}/bckd/bcthsms/timkiem/tim-kiem`,
};
