import EllipsisTooltip from "@components/EllipsisTooltip";
import { closeModal } from "@state/system-config/reducer";
import { formatDateWithTimezoneGB, numberWithSpaces } from "@utils/function";
import { Button, Col, Row, Table } from "antd";
import { Fragment, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getChiSoPT } from "../services";

const TableThayDoiPhuongThuc = (props) => {
  const { dataTable, setDataChiSoPTTable, detail, dataChiSoPTTable } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "",
      width: 50,
      align: "center",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Ngày hiệu lực",
      dataIndex: "ngayThayDoiCu",
      key: "ngayThayDoiCu",
      width: 130,
      render: (text) => {
        return (
          <div className="table-text-center">
            {text ? formatDateWithTimezoneGB(text) : null}
          </div>
        );
      },
    },
    {
      title: "Số biên bản",
      dataIndex: "soBienBanCu",
      key: "soBienBanCu",
      width: 150,
      align: "center",
      render: (text) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Lý do thay đổi",
      dataIndex: "lyDoThayDoiCu",
      key: "lyDoThayDoiCu",
      width: 200,
    },
    {
      title: "Đơn vị giao nhận",
      dataIndex: "donViGiaoNhan1Cu",
      key: "donViGiaoNhan1Cu",
      render: (text, record) => {
        return (
          <Fragment>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.donViGiaoNhan1Cu}
            >
              <div>{record.donViGiaoNhan1Cu}</div>
            </EllipsisTooltip>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.donViGiaoNhan2Cu}
            >
              <div
                style={{
                  display: record.donViGiaoNhan2Cu ? "block" : "none",
                  borderTop: "1px solid #f0f0f0",
                }}
              >
                {record.donViGiaoNhan2Cu}
              </div>
            </EllipsisTooltip>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.donViGiaoNhan3Cu}
            >
              <div
                style={{
                  display: record.donViGiaoNhan3Cu ? "block" : "none",
                  borderTop: "1px solid #f0f0f0",
                }}
              >
                {record.donViGiaoNhan3Cu}
              </div>
            </EllipsisTooltip>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.donViGiaoNhan4Cu}
            >
              <div
                style={{
                  display: record.donViGiaoNhan4Cu ? "block" : "none",
                  borderTop: "1px solid #f0f0f0",
                }}
              >
                {record.donViGiaoNhan4Cu}
              </div>
            </EllipsisTooltip>
          </Fragment>
        );
      },
    },
    {
      title: "Kênh nhận công tơ",
      dataIndex: "kenhNhan1Cu",
      key: "kenhNhan1Cu",
      render: (text, record) => {
        return (
          <Fragment>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.kenhNhan1Cu}
            >
              <div>{record.kenhNhan1Cu}</div>
            </EllipsisTooltip>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.kenhNhan2Cu}
            >
              <div
                style={{
                  display: record.kenhNhan2Cu ? "block" : "none",
                  borderTop: "1px solid #f0f0f0",
                }}
              >
                {record.kenhNhan2Cu}
              </div>
            </EllipsisTooltip>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.kenhNhan3Cu}
            >
              <div
                style={{
                  display: record.kenhNhan3Cu ? "block" : "none",
                  borderTop: "1px solid #f0f0f0",
                }}
              >
                {record.kenhNhan3Cu}
              </div>
            </EllipsisTooltip>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.kenhNhan4Cu}
            >
              <div
                style={{
                  display: record.kenhNhan4Cu ? "block" : "none",
                  borderTop: "1px solid #f0f0f0",
                }}
              >
                {record.kenhNhan4Cu}
              </div>
            </EllipsisTooltip>
          </Fragment>
        );
      },
    },
    {
      title: "Tính chất kênh nhận",
      dataIndex: "tinhChatKenhNhan",
      key: "tinhChatKenhNhan",
      render: (text, record) => {
        return (
          <Fragment>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.tinhChatKenhGiaoNhan1}
            >
              <div>{record.tinhChatKenhGiaoNhan1}</div>
            </EllipsisTooltip>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.tinhChatKenhGiaoNhan2}
            >
              <div
                style={{
                  display: record.tinhChatKenhGiaoNhan2 ? "block" : "none",
                  borderTop: "1px solid #f0f0f0",
                }}
              >
                {record.tinhChatKenhGiaoNhan2}
              </div>
            </EllipsisTooltip>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.tinhChatKenhNhan3Cu}
            >
              <div
                style={{
                  display: record.tinhChatKenhNhan3Cu ? "block" : "none",
                  borderTop: "1px solid #f0f0f0",
                }}
              >
                {record.tinhChatKenhNhan3Cu}
              </div>
            </EllipsisTooltip>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.tinhChatKenhNhan4Cu}
            >
              <div
                style={{
                  display: record.tinhChatKenhNhan4Cu ? "block" : "none",
                  borderTop: "1px solid #f0f0f0",
                }}
              >
                {record.tinhChatKenhNhan4Cu}
              </div>
            </EllipsisTooltip>
          </Fragment>
        );
      },
    },
    {
      title: "Kênh giao công tơ",
      dataIndex: "kenhGiao1Cu",
      key: "kenhGiao1Cu",
      render: (text, record) => {
        return (
          <Fragment>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.kenhGiao1Cu}
            >
              <div>{record.kenhGiao1Cu}</div>
            </EllipsisTooltip>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.kenhGiao2Cu}
            >
              <div
                style={{
                  display: record.kenhGiao2Cu ? "block" : "none",
                  borderTop: "1px solid #f0f0f0",
                }}
              >
                {record.kenhGiao2Cu}
              </div>
            </EllipsisTooltip>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.kenhGiao3Cu}
            >
              <div
                style={{
                  display: record.kenhGiao3Cu ? "block" : "none",
                  borderTop: "1px solid #f0f0f0",
                }}
              >
                {record.kenhGiao3Cu}
              </div>
            </EllipsisTooltip>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.kenhGiao4Cu}
            >
              <div
                style={{
                  display: record.kenhGiao4Cu ? "block" : "none",
                  borderTop: "1px solid #f0f0f0",
                }}
              >
                {record.kenhGiao4Cu}
              </div>
            </EllipsisTooltip>
          </Fragment>
        );
      },
    },
    {
      title: "Tính chất kênh giao",
      dataIndex: "tinhChatKenhGiao",
      key: "tinhChatKenhGiao",
      render: (text, record) => {
        return (
          <Fragment>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.tinhChatKenhGiaoNhan1}
            >
              <div>{record.tinhChatKenhGiaoNhan1}</div>
            </EllipsisTooltip>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.tinhChatKenhGiaoNhan2}
            >
              <div
                style={{
                  display: record.tinhChatKenhGiaoNhan2 ? "block" : "none",
                  borderTop: "1px solid #f0f0f0",
                }}
              >
                {record.tinhChatKenhGiaoNhan2}
              </div>
            </EllipsisTooltip>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.tinhChatKenhGiao3Cu}
            >
              <div
                style={{
                  display: record.tinhChatKenhGiao3Cu ? "block" : "none",
                  borderTop: "1px solid #f0f0f0",
                }}
              >
                {record.tinhChatKenhGiao3Cu}
              </div>
            </EllipsisTooltip>
            <EllipsisTooltip
              line="1"
              placement="topRight"
              title={record.tinhChatKenhGiao4Cu}
            >
              <div
                style={{
                  display: record.tinhChatKenhGiao4Cu ? "block" : "none",
                  borderTop: "1px solid #f0f0f0",
                }}
              >
                {record.tinhChatKenhGiao4Cu}
              </div>
            </EllipsisTooltip>
          </Fragment>
        );
      },
    },
  ];
  // columns phuong thuc
  const columns_phuongthuc = [
    {
      title: "Số công tơ",
      dataIndex: "soCongTo",
      width: "20%",
      align: "center",
      render: (text, row) => {
        <EllipsisTooltip line="1" placement="topRight" title={row.soCongTo}>
          <div>{row.soCongTo}</div>
        </EllipsisTooltip>;
        return <span>{row.soCongTo}</span>;
      },
    },
    {
      title: "Bộ số",
      dataIndex: "boSo",
      width: "10%",
      align: "center",
      render: (text, record) => {
        return <span>{record.boSo}</span>;
      },
    },

    {
      title: "Chỉ số đầu",
      dataIndex: "chiSoDau",
      width: "20%",
      align: "right",
      render: (text, record) => {
        return <span>{numberWithSpaces(record.chiSoDau)}</span>;
      },
    },
    {
      title: "Chỉ số cuối",
      dataIndex: "chiSoCuoi",
      width: "20%",
      align: "right",
      render: (text, record) => {
        return <span>{numberWithSpaces(record.chiSoCuoi)}</span>;
      },
    },
    {
      title: "Hệ số nhân",
      dataIndex: "hsNhan",
      width: "15%",
      align: "right",
      render: (text, record) => {
        return <span>{record.hsNhan}</span>;
      },
    },
    {
      title: "Sản lượng",
      dataIndex: "sanLuong",
      width: "15%",
      align: "right",
      render: (text, record) => {
        return <span>{numberWithSpaces(record.sanLuong)}</span>;
      },
    },
  ];
  return (
    <Fragment>
      <Content>
        <Table
          className="table-tttt"
          columns={columns}
          dataSource={dataTable}
          loading={loading}
          pagination={false}
          bordered
          scroll={{ x: 1600 }}
          onRow={(record) => ({
            onClick: () => {
              getChiSoPT(
                setLoading,
                setDataChiSoPTTable,
                detail.maDiemDo,
                record
              );
            },
          })}
        />
        <div
          style={{
            fontSize: 16,
            fontWeight: 600,
            margin: "15px 0px 5px 0px",
          }}
        >
          Chỉ số thay đổi phương thức
        </div>
        <Table
          columns={columns_phuongthuc}
          dataSource={dataChiSoPTTable}
          loading={loading}
          pagination={false}
          bordered
        />
        <ButtonStyled>
          <Row justify="center">
            <Col span={4}>
              <Button
                type="block"
                htmlType="submit"
                size="large"
                className="full"
                onClick={handleCloseModal}
              >
                Đóng lại
              </Button>
            </Col>
          </Row>
        </ButtonStyled>
      </Content>
    </Fragment>
  );
};

export default TableThayDoiPhuongThuc;
const ButtonStyled = styled.div`
  padding-top: 20px;
`;
const Content = styled.div`
  .ant-table-thead > tr > th {
    padding: 8px !important;
    font-size: 14px !important;
  }
  .ant-table-tbody > tr > td {
    padding: 8px !important;
    font-size: 14px !important;
  }
  .ant-table {
    font-size: 14px !important;
  }
  thead tr th {
    text-align: center !important;
  }
`;
