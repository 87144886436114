import { SearchOutlined } from "@ant-design/icons";
import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import { removeAccents } from "@utils/function";
import { Button, Col, Form, Input, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  getListDonVi,
  getListKenh,
  getListLinhVuc,
  getListTeam,
  getLoaiYeuCau,
} from "./crm-services";

export default function CRMFilter(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    form,
    listTrangThai,
    doiInput,
  } = props;
  const [loading, setLoading] = useState(false);
  const [loaiYeuCau, setLoaiYeuCau] = useState([]);
  const [listDonVi, setListDonVi] = useState([]);
  const [linhVucList, setLinhVucList] = useState([]);
  const [listKenh, setListKenh] = useState([]);
  const [listTeam, setListTeam] = useState([]);
  const [donVi, setDonVi] = useState(filterConditions.DonViId);
  const unitId = useSelector((state) => state.auth.user.unitId);

  useEffect(() => {
    form.setFieldsValue({
      ...filterConditions,
      TuNgay: filterConditions.TuNgay
        ? moment(new Date(filterConditions.TuNgay))
        : moment().subtract(7, "d"),
      DenNgay: filterConditions.DenNgay
        ? moment(new Date(filterConditions.DenNgay))
        : moment(new Date()),
    });
  }, [form, filterConditions]);

  useEffect(() => {
    form.setFieldsValue({
      ...(listTeam.length === 1 && { DoiId: listTeam[0].value }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listTeam]);

  useEffect(() => {
    getLoaiYeuCau(setLoading, setLoaiYeuCau);
    getListDonVi(setLoading, setListDonVi);
    getListLinhVuc(setLoading, setLinhVucList);

    getListKenh(setLoading, setListKenh);
  }, []);

  useEffect(() => {
    if (donVi) {
      getListTeam(setLoading, setListTeam, donVi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donVi]);

  const handleChangeDonVi = (value) => {
    setDonVi(value);
    form.setFieldsValue({
      DoiId: undefined,
    });
  };

  const clear = () => {
    form.resetFields();
    clearFilter();
    setDonVi(unitId);
  };

  return (
    <Styled>
      <div className="tich-hop-CRM-list-bo-loc">
        <FormComponent
          layout="vertical"
          form={form}
          onFinish={handleSearch}
          initialValues={{
            DonViId: filterConditions.DonViId,
            // ...(filterConditions.DoiId && { DoiId: filterConditions.DoiId }),
          }}
        >
          <Row gutter={[8, 16]}>
            <Col span={6}>
              <Form.Item name="DonViId" label="Đơn vị">
                <Select
                  onChange={handleChangeDonVi}
                  loading={loading}
                  showSearch
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                  disabled={listDonVi.length < 2}
                >
                  {listDonVi &&
                    listDonVi.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            {doiInput !== "null" && (
              <Col span={6}>
                <Form.Item name="DoiId" label="Tổ đội">
                  <Select
                    allowClear
                    showSearch
                    disabled={listTeam.length === 1}
                    loading={loading}
                    filterOption={(input, option) => {
                      if (option && option.children) {
                        return (
                          removeAccents(option.children)
                            .toLowerCase()
                            .indexOf(removeAccents(input).toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {listTeam &&
                      listTeam.map((item, id) => {
                        return (
                          <Select.Option key={id} value={item.value}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col span={6}>
              <Form.Item name="LoaiYeuCau" label="Loại yêu cầu">
                <Select
                  allowClear
                  showSearch
                  loading={loading}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {loaiYeuCau &&
                    loaiYeuCau.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="LinhVuc" label="Lĩnh vực">
                <Select
                  placeholder="Tất cả"
                  allowClear
                  showSearch
                  loading={loading}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                  disabled={linhVucList && linhVucList.length < 2}
                >
                  {linhVucList &&
                    linhVucList.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name="TrangThai" label="Trạng thái">
                <Select
                  placeholder="Tất cả"
                  allowClear
                  // disabled={listTrangThai.length < 2}
                  showSearch
                  loading={loading}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {listTrangThai &&
                    listTrangThai.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="KenhId" label="Kênh tiếp nhận">
                <Select
                  allowClear
                  showSearch
                  loading={loading}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {listKenh &&
                    listKenh.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Form.Item name="TuNgay" label="Từ ngày">
                <DatePickerComponent
                  // notClear
                  formKey="TuNgay"
                  form={form}
                  defaultValue={
                    filterConditions.TuNgay
                      ? moment(new Date(filterConditions.TuNgay))
                      : moment().subtract(7, "d")
                  }
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="DenNgay" label="Đến ngày">
                <DatePickerComponent
                  // notClear
                  formKey="DenNgay"
                  form={form}
                  defaultValue={
                    filterConditions.DenNgay
                      ? moment(new Date(filterConditions.DenNgay))
                      : moment()
                  }
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name="TuKhoaTimKiem" label="Từ khóa tìm kiếm">
                <Input prefix={<SearchOutlined />} />
              </Form.Item>
            </Col>
            <Col
              span={4}
              offset={20}
              className="tich-hop-CRM-list-bo-loc-button"
            >
              <Button onClick={clear}>Bỏ lọc</Button>
              <Button type="primary" htmlType="submit">
                Tìm kiếm
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  .tich-hop-CRM-list-bo-loc {
    .tich-hop-CRM-list-bo-loc-button {
      display: flex;
      gap: 20px !important;
      align-items: center;
      padding-right: 14px !important;
      justify-content: end;
    }
  }
`;
