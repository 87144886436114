import moment from "moment";
export const dateFilterCondition = (ojbStartDate, objEndDate) => {
  const objTodayDate = new Date(); // Ngày hiện tại
  const objFurtherDate = new Date(0); // Ngày xa nhất trong quá khứ

  // eslint-disable-next-line prefer-const
  let formatStartDate = `${moment(ojbStartDate).format("YYYY-MM-DD")}`;
  // eslint-disable-next-line prefer-const
  let formatEndDate = `${moment(objEndDate).format("YYYY-MM-DD")}`;

  const formatFurtherDate = `${moment(objFurtherDate).format("YYYY-MM-DD")}`;

  // Ngày yêu cầu (từ) > Ngày hiện tại?
  const isStartDateAfterTodayDate = moment(ojbStartDate).isAfter(
    moment(objTodayDate)
  );
  // Ngày yêu cầu (đến) > Ngày hiện tại?
  const isEndDateAfterTodayDate = moment(objEndDate).isAfter(
    moment(objTodayDate)
  );

  // Ngày yêu cầu(từ) không chọn?
  const isStartDateEmpty = moment(formatStartDate).isSame(
    moment(formatFurtherDate)
  );
  // Ngày yêu cầu(đến) không chọn?
  const isEndDateEmpty = moment(formatEndDate).isSame(
    moment(formatFurtherDate)
  );
  // Ngày yêu cầu(từ) > ngày yêu cầu (đến)
  const isStartDateAfterEndDate = moment(ojbStartDate).isAfter(
    moment(objEndDate)
  );
  return {
    isStartDateAfterTodayDate,
    isEndDateAfterTodayDate,
    isStartDateEmpty,
    isEndDateEmpty,
    isStartDateAfterEndDate,
  };
};

export const dateConditionLogic = (ojbStartDate, objEndDate) => {
  const objTodayDate = new Date(); // Ngày hiện tại
  const dateCondition = dateFilterCondition(ojbStartDate, objEndDate);

  // eslint-disable-next-line prefer-const
  let formatStartDate = `${moment(ojbStartDate).format("YYYY-MM-DD")}`;
  // eslint-disable-next-line prefer-const
  let formatEndDate = `${moment(objEndDate).format("YYYY-MM-DD")}`;

  if (dateCondition.isStartDateAfterEndDate) {
    formatStartDate = `${moment(objTodayDate)
      .subtract(7, "d")
      .format("YYYY-MM-DD")}`;

    formatEndDate = `${moment(objTodayDate).format("YYYY-MM-DD")}`;
  }

  // Không chọn ngày yêu cầu
  if (dateCondition.isStartDateEmpty && dateCondition.isEndDateEmpty) {
    formatStartDate = `${moment(objTodayDate)
      .subtract(7, "d")
      .format("YYYY-MM-DD")}`;

    formatEndDate = `${moment(objTodayDate).format("YYYY-MM-DD")}`;
  } else {
    // Chỉ chọn ngày yêu cầu (từ)
    if (dateCondition.isEndDateEmpty) {
      if (dateCondition.isStartDateAfterTodayDate) {
        formatStartDate = `${moment(objTodayDate)
          .subtract(7, "d")
          .format("YYYY-MM-DD")}`;

        formatEndDate = `${moment(objTodayDate).format("YYYY-MM-DD")}`;
      } else {
        formatStartDate = `${moment(ojbStartDate).get("year")}-${
          moment(ojbStartDate).get("month") + 1
        }-${moment(ojbStartDate).get("date")}`;

        formatEndDate = `${moment(objTodayDate).format("YYYY-MM-DD")}`;
      }
    }

    // Chỉ chọn ngày yêu cầu đến
    if (dateCondition.isStartDateEmpty) {
      formatStartDate = `${moment(objTodayDate)
        .subtract(7, "d")
        .format("YYYY-MM-DD")}`;

      formatEndDate = `${moment(objTodayDate).format("YYYY-MM-DD")}`;
    }
  }
  return { formatStartDate, formatEndDate };
};

// Tinh tong

export const tongSoLuongDonViDangKy = (listDonViDangKy) => {
  let tongSoLuongDonViDangKy = 0;
  listDonViDangKy.forEach((donViDangKy) => {
    tongSoLuongDonViDangKy += donViDangKy.soLuong;
  });

  return tongSoLuongDonViDangKy;
};
