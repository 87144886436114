import {
  authGetData,
  authPostData,
  authGetMultiplesRequest,
} from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { getErrorMessage } from "@utils/function";
import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
// Get list data

export const getListData = (
  endpoints,
  setLoading,
  setTenDonVi,
  setDataDoi,
  setFirstDataDoi,
  setDataPhanCong
) => {
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      if (res[0].data !== null) {
        setTenDonVi(res[0].data.data.tenDonVi);
      } else setTenDonVi("");
      if (res[1].data.data !== null) {
        setDataDoi(res[1].data.data);
        setFirstDataDoi(res[1].data.data[0].id);
      } else setDataDoi([]);
      if (res[2].data.data !== null) {
        setDataPhanCong(res[2].data.data);
      } else setDataPhanCong([]);
    },
  });
};
// Get list user

export const getListUserService = (doiId, setLoading, setNguoiKhaoSat) => {
  authGetData({
    url: `${Endpoint.LIST_NGUOI_KHAO_SAT}?doiId=${doiId}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setNguoiKhaoSat(res.data);
      }
    },
  });
};
//Submit

export const handleSubmitDataService = (
  url,
  setLoading,
  data,
  setFetchDataTable,
  handleCloseModal,
  form
) => {
  authPostData({
    url: url,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};
