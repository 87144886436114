import { Button, Table } from "antd";
import React, { useCallback, useEffect } from "react";
import TableColumns from "./columns";
export default function TableTest({
  loaiCongTo,
  dataSourceDSVTTL,
  setDataSourceDSVTTL,
  selectRowKeys,
  form,
}) {
  //xử lý select
  const handleSelection = useCallback(
    (value, record) => {
      if (value) {
        const newArr = [...dataSourceDSVTTL];
        let currentChungLoaiId;
        const findIndexItemSelected = dataSourceDSVTTL.findIndex(
          (i) => i.stt === record.stt
        );

        if (findIndexItemSelected > -1) {
          currentChungLoaiId = newArr[findIndexItemSelected].chungLoaiId;
          newArr[findIndexItemSelected].chungLoaiId = value;
        }
        for (let index = 0; index < newArr.length; index++) {
          if (
            newArr[index].duLieuLoaiCongTo.findIndex(
              (i) => i.chungLoaiId === value
            ) > -1
          ) {
            const newDataChungLoaiCongTo = [...newArr[index].duLieuLoaiCongTo];
            const findIndexDataLoaiCongTo = newDataChungLoaiCongTo.findIndex(
              (i) => i.chungLoaiId === value
            );
            const findItemDataLoaiCongToSelected = newDataChungLoaiCongTo.find(
              (i) => i.chungLoaiId === value
            );

            if (findIndexDataLoaiCongTo > -1) {
              // Tìm kiếm và xóa dữ liệu trong select loại công tơ, loại các option đã chọn
              newDataChungLoaiCongTo.splice(findIndexDataLoaiCongTo, 1);
              if (newArr[index].stt === record.stt) {
                // Dành cho bản ghi đã có chọn option thì vẫn giữ option đó chứ ko xóa khỏi select
                newDataChungLoaiCongTo.push(findItemDataLoaiCongToSelected);
              }
              newArr[index].duLieuLoaiCongTo = newDataChungLoaiCongTo;
            }
            // form.setFieldsValue({
            newArr[findIndexItemSelected].chungLoai =
              findItemDataLoaiCongToSelected.chungLoai;
            newArr[findIndexItemSelected][`loaiCongTo-${record.stt}`] =
              findItemDataLoaiCongToSelected.chungLoaiId;
            newArr[findIndexItemSelected].moTa =
              findItemDataLoaiCongToSelected.moTa;
            newArr[findIndexItemSelected].soLuongTon =
              findItemDataLoaiCongToSelected.soLuongTon;
          }
        }
        let itemLCTSelected;
        if (currentChungLoaiId) {
          // Dành cho bản ghi đã có chọn option1 sau đó lựa chọn option2  thì phải push lại option1 vào các select ở các bản ghi khác
          const dataChungLoaiCongTo = [...record.duLieuLoaiCongTo];
          itemLCTSelected = dataChungLoaiCongTo.find(
            (i) => i.chungLoaiId === currentChungLoaiId
          );
        }
        if (itemLCTSelected) {
          for (let index = 0; index < newArr.length; index++) {
            if (
              !newArr[index].duLieuLoaiCongTo.find(
                (i) => i.chungLoaiId === itemLCTSelected.chungLoaiId
              )
            ) {
              newArr[index].duLieuLoaiCongTo.push(itemLCTSelected);
            }
          }
        }
        setDataSourceDSVTTL(newArr);
      }
    },
    [dataSourceDSVTTL, setDataSourceDSVTTL]
  );

  //xóa dữ liệu
  const handleRemove = useCallback(
    (record) => {
      const newArr = [...dataSourceDSVTTL];
      const findIndex = newArr.findIndex((i) => i.stt === record.stt);
      if (findIndex > -1) {
        newArr.splice(findIndex, 1);
      }
      if (record.chungLoaiId) {
        const findItem = loaiCongTo.find(
          (i) => i.chungLoaiId === record.chungLoaiId
        );
        if (findItem) {
          newArr.forEach((i) => i.duLieuLoaiCongTo.push(findItem));
        }
      }
      setDataSourceDSVTTL(newArr);
    },
    [dataSourceDSVTTL, loaiCongTo, setDataSourceDSVTTL]
  );

  //thay đổi input
  const handleChangeInput = useCallback(
    (e, stt) => {
      const newArr = [...dataSourceDSVTTL];
      // newArr[index].soLuongTra = e.target.value;
      newArr.forEach((item) => {
        if (item.stt === stt)
          return (item[`soLuongTra-${stt}`] = e.target.value);
      });
      setDataSourceDSVTTL(newArr);
    },
    [dataSourceDSVTTL, setDataSourceDSVTTL]
  );

  //render table
  const renderTable = useCallback(() => {
    return (
      <Table
        bordered
        rowKey={(obj) => obj.stt}
        dataSource={dataSourceDSVTTL}
        columns={TableColumns({
          handleSelection,
          handleRemove,
          handleChangeInput,
          dataSourceDSVTTL,
          selectRowKeys,
        })}
        pagination={false}
        scroll={{ y: 300 }}
      />
    );
  }, [
    dataSourceDSVTTL,
    handleSelection,
    handleRemove,
    handleChangeInput,
    selectRowKeys,
  ]);

  //thêm dữ liệu
  const handleAdd = useCallback(() => {
    const newArr = [...dataSourceDSVTTL];
    const newArrDataLoaiCongTo = [...loaiCongTo];
    for (let index = 0; index < newArr.length; index++) {
      if (newArr[index].chungLoaiId) {
        const findIndexLCTSelected = newArrDataLoaiCongTo.findIndex(
          (i) => i.chungLoaiId === newArr[index].chungLoaiId
        );
        if (findIndexLCTSelected > -1) {
          newArrDataLoaiCongTo.splice(findIndexLCTSelected, 1);
        }
      }
    }

    newArr.push({
      stt: newArr[newArr.length - 1]?.stt
        ? newArr[newArr.length - 1]?.stt + 1
        : 1,
      chungLoai: "",
      chungLoaiId: "",
      [`loaiCongTo-${
        newArr[newArr.length - 1]?.stt ? newArr[newArr.length - 1]?.stt + 1 : 1
      }`]: "",
      duLieuLoaiCongTo: newArrDataLoaiCongTo,
      moTa: "",
      soLuongTon: "",
      [`soLuongTra-${
        newArr[newArr.length - 1]?.stt ? newArr[newArr.length - 1]?.stt + 1 : 1
      }`]: null,
    });

    form.setFieldsValue({
      [`soLuongTra-${
        newArr[newArr.length - 1]?.stt ? newArr[newArr.length - 1]?.stt : 1
      }`]: null,
    });

    setDataSourceDSVTTL(newArr);
  }, [dataSourceDSVTTL, loaiCongTo, form, setDataSourceDSVTTL]);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    dataSourceDSVTTL.map((item) => {
      form.setFieldsValue({
        [`loaiCongTo-${item.stt}`]: item[`loaiCongTo-${item.stt}`],
      });
    });
  });

  return (
    <div>
      <div style={{ textAlign: "right", marginBottom: "12px" }}>
        <Button type="primary" onClick={handleAdd}>
          Thêm dòng
        </Button>
      </div>
      {renderTable()}
    </div>
  );
}
