import TableComponent from "@components/TableComponent";
import styled from "styled-components";
export default function DataTable(props) {
  const {
    filterConditions,
    header,
    dataSource,
    columns,
    totalCount,
    loading,
  } = props;

  return (

      <Container>
        <TableComponent
          header={header}
          dataSource={dataSource}
          columns={columns}
          totalData={totalCount}
          loading={loading}
          pagination={filterConditions}
          scrollY={530}
        />
      </Container>
  );
}
const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
`;