import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { ENUM_MODE_PHUONGANCAPDIEN, ENUM_VATTU } from "@utils/constants";
import LoadingComponent from "@components/Loading";
import TrashIcon from "@assets/icon/TrashIcon";
import PencilIcon from "@assets/icon/PencilIcon";
import { handleListVatTuCongTy, deleteVatTuCongTy } from "../../../services";
import TableCongTy from "./table/table-cong-ty";
import VatTuCongTyModal from "./modals/modal-vat-tu-cong-ty";
export default function VatTuCongTyPanel({ detailLapPhuongAn, mode }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [detailVatTu, setDetailVatTu] = useState();
  const [visible, setVisible] = useState(false);

  const [dataCongTy, setDataCongTy] = useState([]);
  const [pagination, setPagination] = useState({});

  // const formatter = (value) =>
  //   value !== null ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;
  //khai báo columns

  const columns =
    mode !== ENUM_MODE_PHUONGANCAPDIEN.XEMLAI
      ? [
          {
            title: t("vat_tu.vat_tu_khach_hang.ten_vat_tu_thiet_bi"),
            dataIndex: "tenVatTu",
            key: "tenVatTu",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "30%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.so_luong"),
            dataIndex: "soLuong",
            key: "soLuong",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
          // {
          //   title: t("vat_tu.vat_tu_khach_hang.dg_vat_lieu"),
          //   dataIndex: "donGiaVatLieu",
          //   key: "donGiaVatLieu",
          //   render: (text) => (
          //     <div className="table-text-right">
          //       <span>{formatter(text)}</span>
          //     </div>
          //   ),
          //   width: "10%",
          // },
          // {
          //   title: t("vat_tu.vat_tu_khach_hang.dg_nhan_cong"),
          //   dataIndex: "donGiaNhanCong",
          //   key: "donGiaNhanCong",
          //   render: (text) => (
          //     <div className="table-text-right">
          //       <span>{formatter(text)}</span>
          //     </div>
          //   ),
          //   width: "10%",
          // },
          // {
          //   title: t("vat_tu.vat_tu_khach_hang.dg_mtc"),
          //   dataIndex: "donGiaMTC",
          //   key: "donGiaMTC",
          //   render: (text) => (
          //     <div className="table-text-right">
          //       <span>{formatter(text)}</span>
          //     </div>
          //   ),
          //   width: "10%",
          // },
          {
            title: t("vat_tu.vat_tu_khach_hang.don_vi_tinh"),
            dataIndex: "donViTinh",
            key: "donViTinh",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "5%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.chung_loai"),
            dataIndex: "chungLoai",
            key: "chungLoai",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "8%",
          },
          {
            title: t("tac_vu"),
            key: "action",
            width: "5%",
            render: (row) => (
              <div className="action-table-column">
                <div
                  className="action-icon"
                  onClick={() => handleOpenModal(row)}
                >
                  <PencilIcon />
                </div>
                <div
                  className="action-icon"
                  onClick={() => handleDeleteData(row.id)}
                >
                  <TrashIcon />
                </div>
              </div>
            ),
          },
        ]
      : [
          {
            title: t("vat_tu.vat_tu_khach_hang.ten_vat_tu_thiet_bi"),
            dataIndex: "tenVatTu",
            key: "tenVatTu",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "30%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.so_luong"),
            dataIndex: "soLuong",
            key: "soLuong",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
          // {
          //   title: t("vat_tu.vat_tu_khach_hang.dg_vat_lieu"),
          //   dataIndex: "donGiaVatLieu",
          //   key: "donGiaVatLieu",
          //   render: (text) => (
          //     <div className="table-text-right">
          //       <span>{formatter(text)}</span>
          //     </div>
          //   ),
          //   width: "10%",
          // },
          // {
          //   title: t("vat_tu.vat_tu_khach_hang.dg_nhan_cong"),
          //   dataIndex: "donGiaNhanCong",
          //   key: "donGiaNhanCong",
          //   render: (text) => (
          //     <div className="table-text-right">
          //       <span>{formatter(text)}</span>
          //     </div>
          //   ),
          //   width: "10%",
          // },
          // {
          //   title: t("vat_tu.vat_tu_khach_hang.dg_mtc"),
          //   dataIndex: "donGiaMTC",
          //   key: "donGiaMTC",
          //   render: (text) => (
          //     <div className="table-text-right">
          //       <span>{formatter(text)}</span>
          //     </div>
          //   ),
          //   width: "10%",
          // },
          {
            title: t("vat_tu.vat_tu_khach_hang.don_vi_tinh"),
            dataIndex: "donViTinh",
            key: "donViTinh",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "5%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.chung_loai"),
            dataIndex: "chungLoai",
            key: "chungLoai",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "8%",
          },
        ];

  const genExtra = () => (
    <div style={{ textAlign: "right", margin: "4px 0 16px 0" }}>
      {mode !== ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
        <Button
          size="small"
          type="primary"
          className="mr-5px"
          onClick={() => handleOpenModal({}, ENUM_VATTU.COSAN)}
        >
          <PlusOutlined /> Vật tư có sẵn
        </Button>
      ) : null}
    </div>
  );

  const getListData = (pagination) => {
    handleListVatTuCongTy(
      setLoading,
      setDataCongTy,
      t,
      detailLapPhuongAn,
      setPagination,
      pagination
    );
  };

  useEffect(() => {
    getListData({
      pageSize: -1,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailLapPhuongAn]);

  //xóa dữ liệu

  const handleDeleteData = useCallback(
    (id) => {
      deleteVatTuCongTy(
        id,
        setLoading,
        () =>
          getListData({
            pageSize: -1,
          }),
        t,
        detailLapPhuongAn
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //hiển thị popup, set dữ liệu chi tiết

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDetailVatTu(row);
      else setDetailVatTu({});
      setVisible(!visible);
    },
    [visible]
  );

  //đóng popup

  const handleCloseModal = () => {
    setVisible(false);
  };

  const showModal = useMemo(
    () =>
      visible && (
        <VatTuCongTyModal
          detailVatTu={detailVatTu}
          visible={visible}
          closeModal={handleCloseModal}
          detailLapPhuongAn={detailLapPhuongAn}
          refreshTable={() =>
            getListData({
              pageSize: -1,
            })
          }
        />
      ),
    // eslint-disable-next-line
    [visible]
  );

  return (
    <LoadingComponent loading={loading}>
      <TableCongTy
        genExtra={genExtra}
        columns={columns}
        dataCongTy={dataCongTy}
        tablePagination={pagination}
      />
      {showModal}
    </LoadingComponent>
  );
}
