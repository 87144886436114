import { BASE_API_URL_BAO_CAO } from "@utils/constants";

export const APIBCCongno = {
  //Tổng hợp thanh toán qua ngân hàng tổ chức trung gian thanh toán
  BCCN_BC_KHACHHANG_THUC_TRUY_NO_QUA_ECPAY: `${BASE_API_URL_BAO_CAO}/bckd/bccn/thttqnhtctgtt/bao-cao-khach-hang-thuc-truy`,
  BCCN_BC_CT_KHACHHANG_TRICH_NO_TU_DONG: `${BASE_API_URL_BAO_CAO}/bckd/bccn/thttqnhtctgtt/bao-cao-chi-tiet-khach-hang`,
  BCCN_BAO_CAO_GIAM_SAT_CONG_TAC_THU_TIEN_DIEN_CUA_NGUOI_THUE_NHA: `${BASE_API_URL_BAO_CAO}/bckd/bccn/thttqnhtctgtt/bao-cao-giam-sat-cong-tac`,
  BCCN_BC_SO_THU_DICH_VU_TIEN_DIEN_QUA_NH_TGTGTT: `${BASE_API_URL_BAO_CAO}/bckd/bccn/thttqnhtctgtt/bao-cao-so-thu-dich-vu-tien-di`,
  BCCN_BC_SO_THU_DICH_VU_TIEN_DIEN_QUA_NH_TGTGTT_DONVI: `${BASE_API_URL_BAO_CAO}/bckd/bccn/thttqnhtctgtt/bao-cao-so-thu-dich-vu-tien-dien`,
  BCCN_BC_SO_THU_DICH_VU_TIEN_DIEN_QUA_NH_TGTGTT_THEONGAY: `${BASE_API_URL_BAO_CAO}/bckd/bccn/thttqnhtctgtt/bao-cao-so-thu-dich-vu-tien-dien-theo-don-vi`,
  BCCN_BC_SO_THU_ECPAY_KENH_AUTO: `${BASE_API_URL_BAO_CAO}/bckd/bccn/thttqnhtctgtt/bao-cao-so-thu-theo-ngay-ecpay`,
  BCCN_BC_TH_SO_THU_THEO_CAC_PHUONG_THUC_VA_HINH_THUC_THU: `${BASE_API_URL_BAO_CAO}/bckd/bccn/thttqnhtctgtt/bao-cao-tong-so-thu-theo-cac-phuong-thuc-va-hinh-thuc-thu`,
  BCCN_BC_TH_THU_NOP_QUA_NH: `${BASE_API_URL_BAO_CAO}/bckd/bccn/thttqnhtctgtt/bao-cao-tinh-hinh-thu-nop-qua-ngan-hang`,
  BCCN_THU_HO_CAC_TCTG_VA_NGAN_HANG: `${BASE_API_URL_BAO_CAO}/bckd/bccn/bchtthcnvdtien/thu-ho-cua-cac-to-chuc-trung-gian-va-ngan-hang`,
  BCCN_TH_THANH_TOAN_24_48H: `${BASE_API_URL_BAO_CAO}/bckd/bccn/thttqnhtctgtt/qua-hinh-thuc-dien-tu-trong-vong-24-48h`,
  BCCN_DS_THU_TIEN_DIEN_CUA_NGUOI_THUE_NHA: `${BASE_API_URL_BAO_CAO}/bckd/bccn/thttqnhtctgtt/danh-sach-thong-tin-thu-tien-dien-cua-nguoi-thue-nha`,
  BCCN_THEM_THU_TIEN_DIEN_CUA_NGUOI_THUE_NHA: `${BASE_API_URL_BAO_CAO}/bckd/bccn/thttqnhtctgtt/them-danh-sach-thu-tien-dien-nguoi-thue-nha`,

  //Báo cáo chấm điểm dòng tiền
  BCCN_BC_CHAM_DIEM_DONG_TIEN: `${BASE_API_URL_BAO_CAO}/bckd/bccn/bccddt/bc-cham-diem-dong-tien`,

  //Biểu tỷ lệ thực hiện thu nộp tiền điện
  BCCN_BIEU_TY_LE_TH_THU_NOP_TIEN_DIEN: `${BASE_API_URL_BAO_CAO}/bckd/bccn/btlthtntd/btl-thuc-hien-thu-nop-tien-dien`,

  //Báo cáo quản lý dòng tiền
  BCCN_BC_TONG_HOP_DONG_TIEN: `${BASE_API_URL_BAO_CAO}/bckd/bccn/qldt/bc-tong-hop-dong-tien`,
  BCCN_BC_CHI_TIET_DONG_TIEN_THEO_NGAY_CUA_TUNG_DON_VI: `${BASE_API_URL_BAO_CAO}/bckd/bccn/qldt/bc-chi-tiet-dong-tien-theo-ngay-cua-tung-don-vi`,

  //Báo cáo hỗ trợ công nợ và dòng tiền
  BCCN_BIEU6_BANG_KE_THU_HO_CONG_TY_DIEN_LUC_KHAC: `${BASE_API_URL_BAO_CAO}/bckd/bccn/bchtthcnvdtien/bang-ke-hoa-don-thu-ho-cong-ty-dien-luc-khac`,
  BCCN_BIEU11_SO_THU_DICH_VU_TIEN_DIEN_NGAN_HANG_TO_CHUC_TGTT: `${BASE_API_URL_BAO_CAO}/bckd/bccn/bchtthcnvdtien/so-thu-dich-vu-tien-dien-qua-ngan-hang-to-chuc`,
  BCCN_BANG_KE_HOA_DON_DUOC_CONG_TY_DIEN_LUC_KHAC_THU_HO: `${BASE_API_URL_BAO_CAO}/bckd/bccn/bchtthcnvdtien/bang-ke-hoa-don-duoc-cong-ty-dien-luc-khac-thu-ho`,
  BCCN_TONG_HOP_HOA_DON_DUOC_CONG_TY_DIEN_LUC_KHAC_THU_HO: `${BASE_API_URL_BAO_CAO}/bckd/bccn/bchtthcnvdtien/tong-hop-hoa-don-duoc-cong-ty-dien-luc-khac-thu-ho`,
  BCCN_TONG_HOP_HOA_DON_THU_HO_CONG_TY_DIEN_LUC_KHAC: `${BASE_API_URL_BAO_CAO}/bckd/bccn/bchtthcnvdtien/tong-hop-hoa-don-thu-ho-cong-ty-dien-luc-khac`,
  BCCN_TONG_HOP_KHACH_HANG_THANH_TOAN_PHI_CAT_DIEN: `${BASE_API_URL_BAO_CAO}/bckd/bccn/bchtthcnvdtien/tong-hop-khach-hang-toan-phi-dong-cat-dien`,
  BCCN_TONG_HOP_KHACH_HANG_THANH_TOAN_TREN_WEB: `${BASE_API_URL_BAO_CAO}/bckd/bccn/bchtthcnvdtien/tong-hop-khach-hang-thanh-toan-tren-web`,
  BCCN_TONG_HOP_THU_HO_TONG_CONG_TY: `${BASE_API_URL_BAO_CAO}/bckd/bccn/bchtthcnvdtien/tong-hop-thu-ho-tong-cong-ty`,

  //Báo cáo dư nợ thủy nông
  BCCN_BC_DU_NO_THUY_NONG: `${BASE_API_URL_BAO_CAO}/bckd/bccn/bbcdntn/bieu-bao-cao-du-no-thuy-nong`,

  //Báo cáo dư nợ đèn đường
  BCCN_BC_DU_NO_DEN_DUONG: `${BASE_API_URL_BAO_CAO}/bckd/bccn/bcdndd/bao-cao-du-no-den-duong`,

  // Báo cáo Tổng hợp số tiền đã thu của khách dùng điện 13H
  BCCN_TH_SO_TIEN_DA_THU_CUA_KHACH_DUNG_DIEN_13G: `${BASE_API_URL_BAO_CAO}/bckd/bccn/thstdtckhdd/thst-da-thu-cua-khach-hang-dung-dien`,
  BCCN_TH_SO_TIEN_DA_THU_CUA_KHACH_DUNG_DIEN_13G_MOI: `${BASE_API_URL_BAO_CAO}/bckd/bccn/thstdtckhdd/thst-da-thu-cua-khach-hang-dung-dien-moi`,
  BCCN_DANH_SACH_CAP_NHAT_13H: `${BASE_API_URL_BAO_CAO}/bckd/bccn/bchtthcnvdtien/danh-sach-cap-nhap-13g`,
  BCCN_THEM_CAP_NHAT_13H: `${BASE_API_URL_BAO_CAO}/bckd/bccn/bchtthcnvdtien/them-cap-nhap-13g`,

  // Tổng hợp số tiền khách hàng còn nợ 13H
  BCCN_TH_SO_TIEN_KHACH_HANG_CON_NO_13H: `${BASE_API_URL_BAO_CAO}/bckd/bccn/thstkhcn/tong-so-tien-khach-hang-con-no`,

  //Báo cáo số thu thu theo hóa đơn
  BCCN_BC_SO_THU_THEO_HOA_DON: `${BASE_API_URL_BAO_CAO}/bckd/bccn/bctylesothutheohoadon/bc-ty-le-so-thu-theo-hoa-don`,

  //Báo cáo tỷ lệ số thu theo số tiền
  BCCN_BC_SO_THU_THEO_SO_TIEN: `${BASE_API_URL_BAO_CAO}/bckd/bccn/bctylesothutheohoadon/bc-ty-le-so-thu-theo-so-tien`,

  //Báo cáo tổng hợp và quản lí dòng tiền
  BCCN_BC_TONG_HOP_VA_QUAN_LI_DONG_TIEN: `${BASE_API_URL_BAO_CAO}/bckd/bccn/bctylesothutheohoadon/bc-tong-hop-ql-dong-tien`,

  //Báo cáo tổng hợp khách hàng nợ quá hạn
  BCCN_BC_TONG_HOP_KH_NO_QUA_HAN: `${BASE_API_URL_BAO_CAO}/bckd/bccn/bctylesothutheohoadon/bc-tong-hop-kh-no-qua-han`,

  //Báo cáo kiểm kê 0 giờ
  BCCN_BANG_KIEM_KE_CONG_NO_PHAI_TRA: `${BASE_API_URL_BAO_CAO}/bckd/bccn/baocaothkiemke0h/bang-kiem-ke-cong-no-phai-tra`,
  BCCN_BANG_KIEM_KE_CONG_NO_PHAI_THU: `${BASE_API_URL_BAO_CAO}/bckd/bccn/baocaothkiemke0h/bang-kiem-ke-cong-no-phai-thu`,
  BCCN_BC_SO_PHAI_THU_TRU_SO_PHAI_TRA: `${BASE_API_URL_BAO_CAO}/bckd/bccn/baocaothkiemke0h/bao-cao-so-thu-tru-so-phai-tra`,
};
