import { downloadFile } from "@utils/request";
import { Button } from "antd";
import { useState } from "react";

export default function XuatExcel(props) {
  const { endpoint, text, keyButton, disabled } = props;

  const [loading, setLoading] = useState(false);

  //call api xuất excel
  const handleDownloadExcel = (endpoint, setLoading) => {
    if (endpoint !== undefined)
      downloadFile({
        endpoint,
        setLoading,
      });
  };
  return (
    <Button
      disabled={disabled ? disabled : false}
      type="primary"
      className="mr-24px"
      key={keyButton ? keyButton : "export-excel"}
      onClick={() => handleDownloadExcel(endpoint, setLoading)}
      loading={loading}
    >
      {text ? text : "Xuất excel"}
    </Button>
  );
}
