const j1 = {
  j1: "j1",
  j1_v: "j1v",
};
const j11 = {
  j11: "j11",
  j11v: "j11v",
  j11t: "j11t",
  j11h: "j11h",
  j11x: "j11x",
};
const j12 = {
  j12: "j12",
  j12v: "j12v",
  j12t: "j12t",
  j12h: "j12h",
  j12x: "j12x",
};
const j13 = {
  j13: "j13",
  j13v: "j13v",
  j13t: "j13t",
  j13h: "j13h",
  j13x: "j13x",
};
const j2 = {
  j2: "j2",
  j2v: "j2v",
};
const j21 = {
  j21: "j21",
  j21v: "j21v",
  j21xe: "j21xe",
  j21g: "j21g",
};
const j22 = {
  j22: "j22",
  j22v: "j22v",
  j22xe: "j22xe",
  j22g: "j22g",
};
const j3 = {
  j3: "j3",
  j3v: "j3v",
};
const j31 = {
  j31: "j31",
  j31v: "j31v",
  j31a: "j31a",
  j31b: "j31b",
  j31c: "j31c",
  j31d: "j31d",
  j31e: "j31d",
  j31f: "j31f",
  j31g: "j31g",
};
const j32 = {
  j32: "j32",
  j32v: "j32v",
  j32a: "j32a",
  j32b: "j32b",
  j32c: "j32c",
  j32d: "j32d",
  j32e: "j32e",
  j32f: "j32f",
  j32g: "j32g",
};
const j33 = {
  j33: "j33",
  j33v: "j33v",
  j33a: "j33a",
  j33b: "j33b",
  j33c: "j33c",
  j33d: "j33d",
  j33e: "j33d",
  j33f: "j33f",
  j33g: "j33g",
  j33h: "j33h",
  j33j: "j33j",
  j33k: "j33k",
  j33l: "j33l",
  j33m: "m33j",
  j33n: "j33n",
  j33o: "o33j",
  j33p: "j33p",
};
const j34 = {
  j34: "j34",
  j34v: "j34v",
  j34a: "j34a",
  j34b: "j34b",
  j34c: "j34c",
  j34d: "j34d",
  j34e: "j34e",
  j34f: "j34f",
};
const j35 = {
  j35: "j35",
  j35v: "j35v",
  j35a: "j35a",
  j35b: "j35b",
  j35c: "j35c",
  j35d: "j35d",
  j35e: "j35d",
  j35f: "j35f",
  j35g: "j35g",
  j35h: "j35h",
  j35j: "j35j",
  j35k: "j35k",
  j35l: "j35l",
  j35m: "m35j",
  j35n: "j35n",
};
const j36 = {
  j36: "j36",
  j36v: "j36v",
  j36a: "j36a",
  j36b: "j36b",
  j36c: "j36c",
};
const j37 = {
  j37: "j37",
  j37v: "j37v",
  j37a: "j37a",
  j37b: "j37b",
  j37c: "j37c",
  j37d: "j37d",
};
const j38 = {
  j38: "j38",
  j38v: "j38v",
  j38a: "j38a",
  j38b: "j38b",
  j38c: "j38c",
  j38d: "j38d",
};
const j39 = {
  j39: "j39",
  j39v: "j39v",
  j39a: "j39a",
  j39b: "j39b",
};
const j310 = {
  j310: "j310",
  j310v: "j310v",
  j310a: "j310a",
  j310b: "j310b",
};
const j320 = {
  j320: "j320",
  j320v: "j320v",
  j320a: "j320a",
  j320b: "j320b",
  j320c: "j320c",
};
const j330 = {
  j330: "j330",
  j330v: "j330v",
  j330a: "j330a",
  j330b: "j330b",
};
const j340 = {
  j340: "j340",
  j340v: "j340v",
  j340a: "j340a",
};
const j350 = {
  j350: "j350",
  j350v: "j350v",
  j350a: "j350a",
  j350b: "j350b",
};
const j360 = {
  j360: "j360",
  j360v: "j360v",
  j360a: "j360a",
  j360b: "j360b",
  j360c: "j360c",
  j360d: "j360d",
  j360e: "j360e",
  j360f: "j360f",
};
const j370 = {
  j370: "j370",
  j370v: "j370v",
  j370a: "j370a",
};
const j380 = {
  j380: "j380",
  j380v: "j380v",
  j380a: "j380a",
};
const j4 = {
  j4: "j4",
  j4v: "j4v",
};
const j41 = {
  j41: "j41",
  j41v: "j41v",
};
const j42 = {
  j42: "j42",
  j42v: "j42v",
};
const j43 = {
  j43: "j43",
  j43v: "j43v",
};
const j44 = {
  j44: "j44",
  j44v: "j44v",
};
const j45 = {
  j45: "j45",
  j45v: "j45v",
};
const cskh = {
  j_cskh: "j",
};
export const cham_soc_khach_hang = {
  ...cskh,
  ...j1,
  ...j11,
  ...j12,
  ...j13,
  ...j2,
  ...j21,
  ...j22,
  ...j3,
  ...j31,
  ...j32,
  ...j33,
  ...j34,
  ...j35,
  ...j36,
  ...j37,
  ...j38,
  ...j39,
  ...j310,
  ...j320,
  ...j330,
  ...j340,
  ...j350,
  ...j360,
  ...j370,
  ...j380,
  ...j4,
  ...j41,
  ...j42,
  ...j43,
  ...j44,
  ...j45,
};
