import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Bar } from "@ant-design/plots";
import { authPostData } from "@utils/request";
import { Col, Divider, Row } from "antd";
import NoRecordFound from "@components/NoRecordFound";
import { Endpoint } from "@utils/endpoint";
const BKTheoDoi = () => {
  const config = useMemo(
    () => ({
      xField: "sales",
      yField: "type",
      meta: {
        type: {
          alias: "không dữ liệu",
        },
        sales: {
          alias: "dữ liệu",
        },
      },
      minBarWidth: 20,
      maxBarWidth: 20,
    }),
    []
  );
  const [dataLichGCS, setDataLichGCS] = useState(null);
  const [loading, setLoading] = useState();
  const getChartDasboardTrangThai = useCallback(() => {
    authPostData({
      url: `${Endpoint.LICH_GCS_THEO_TT}`,
      method: "POST",
      setLoading,
      payload: {},
      onSuccess: (res) => {
        const newData = res.data.xValues.map((e, i) => ({
          type: e,
          sales: res.data.series.data[i],
        }));
        setDataLichGCS(newData);
      },
    });
  }, []);

  useEffect(() => {
    getChartDasboardTrangThai();
  }, [getChartDasboardTrangThai]);
  const renderContent = useCallback(() => {
    if (!loading && !dataLichGCS) {
      return (
        <div className="text-center">
          <NoRecordFound />
        </div>
      );
    }
    if (dataLichGCS) {
      return <Bar {...config} data={dataLichGCS} />;
    }
  }, [dataLichGCS, loading, config]);
  return (
    <div className="chart-container-bao">
      <div className="container-bao">
        <Divider plain="true" className="section-text">
          <h2 className="chart">Bảng thống kê theo số sổ</h2>
        </Divider>
        <Row gutter={5} align="middle">
          <Col span={22}>{renderContent()}</Col>
        </Row>
      </div>
    </div>
  );
};
export default BKTheoDoi;
