import {
  Fragment,
  useState,
} from "react";
import CommonDoiSoat from "../../common/doi-soat";
import { ghi_chi_so } from "@permissions/d_gcs";
import "moment/locale/vi";

export default function Index() {
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);

  return (
    <Fragment>
      <CommonDoiSoat
        permission={ghi_chi_so.d32}
        setFetchDataTable={setFetchDataTable}
        fetchDataTableIfNeed={fetchDataTableIfNeed}
      />
    </Fragment>
  );
}
