const userManagementPage = {
  k2_user: "k2",
  k2_user_view: "k2v",
  k2_user_tao: "k2t",
  k2_user_sua: "k2s",
  k2_user_xoa: "k2x",
  k2_user_chuyendv: "k2c",
};
const rolePage = {
  k1_role: "k1",
  k1_view: "k1v",
  k1_tao: "k1t",
  k1_sua: "k1s",
  k1_xoa: "k1x",
};
const menuPage = {
  k3_menu: "k3",
  k3_view: "k3v",
  k3_tao: "k3t",
  k3_sua: "k3s",
  k3_xoa: "k3x",
};
const page = {
  k4_page: "k4",
  k4_view: "k4v",
  k4_tao: "k4t",
  k4_sua: "k4s",
  k4_xoa: "k4x",
};

const log = {
  k5_log: "k5",
  k51_log_error: "k51",
  k52_log_internal: "k52",
  k53_log_external: "k53",
};

export const permission = {
  ...userManagementPage,
  ...rolePage,
  ...menuPage,
  ...page,
  ...log,
};
