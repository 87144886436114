import React, { useMemo } from "react";
import { Document, Page, Font, Image } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import Regular from "@assets/fonts/Montserrat-Regular.ttf";
import Bold from "@assets/fonts/Montserrat-Bold.ttf";
import Medium from "@assets/fonts/Montserrat-Medium.ttf";
import Italic from "@assets/fonts/Montserrat-Italic.ttf";
// Register font
Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: Regular,
      fontStyle: "normal",
      fontWeight: "normal",
      format: "truetype",
    },
    {
      src: Medium,
      fontStyle: "normal",
      fontWeight: "medium",
      format: "truetype",
    },
    {
      src: Bold,
      fontStyle: "normal",
      fontWeight: "bold",
      format: "truetype",
    },
    {
      src: Italic,
      fontStyle: "italic",
      fontWeight: "normal",
      format: "truetype",
    }
  ],
});
export const LabelText = styled.Text`
  font-family: "Montserrat";
  font-size: 8pt;
  color: #000000;
  font-weight: 300;
`;
export const Table = styled.View`
  width: 100vw;
  display: flex;
  flex-direction: column;
`;
export const TableHeader = styled.View`
  width: 100vw;
  background: #e1e1e1;
  padding: 12pt;
  display: flex;
  flex-direction: row;
`;
export const TableHeaderCellLong = styled.Text`
  width: 35vw;
  color: #c11e29;
  font-family: "Montserrat";
  font-size: 12pt;
  font-weight: bold;
`;

export const TableHeaderCell = styled.Text`
  width: 15vw;
  color: #c11e29;
  font-family: "Montserrat";
  font-size: 12pt;
  font-weight: bold;
  text-align: right;
`;
export const TableRow = styled.View`
  width: 100vw;
  background: #fefefe;
  padding: 12pt;
  display: flex;
  border-bottom: 1pt solid #ccc;
  flex-direction: row;
`;
export const TableCellLong = styled.Text`
  width: 35vw;
  color: #232323;
  font-family: "Montserrat";
  font-size: 12pt;
`;

export const TableCell = styled.Text`
  width: 15vw;
  color: #232323;
  font-family: "Montserrat";
  font-size: 12pt;
  text-align: right;
`;

export const FlexHorizontal = styled.View`
  width: 100vw;
  display: flex;
  flex-direction: row;
`;

export const InvoiceHeader = styled.View`
  width: 100vw;
  display: flex;
  flex-direction: row;
  background: #c11e29;
  padding: 20pt;
  margin: 0;
`;
export const FlexVertical = styled.View`
  display: flex;
  flex-direction: column;
`;

export const DeclarationFooter = styled.View`
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  padding: 20pt;
  margin-top: 40pt;
`;
export const CompanyName = styled.Text`
  color: #232323;
  font-family: "Montserrat";
  font-size: 12pt;
  font-weight: bold;
`;
export const ReportTitle = styled.Text`
  color: #232323;
  font-family: "Montserrat";
  font-size: 13pt;
  font-weight: bold;
  flex-grow: 1;
  margin-top: 4pt;
`;

export const CompanyAddress = styled.Text`
  color: #232323;
  font-family: "Montserrat";
  font-size: 10pt;
  font-weight: normal;
`;
export const Customer = styled.Text`
  color: #232323;
  font-family: "Montserrat";
  font-size: 10pt;
  font-weight: bold;
`;
export const ValueText = styled.Text`
  color: #000000;
  font-family: "Montserrat";
  font-size: 8pt;
  font-weight: bold;
  margin-left: 10px;
`;

export const InvoiceTotalText = styled.Text`
  color: #fff;
  font-family: "Montserrat";
  font-size: 25pt;
  font-weight: bold;
  margin-top: 4pt;
`;

export const BilledToAddressText = styled.Text`
  width: 250pt;
  color: #fff;
  font-family: "Montserrat";
  font-size: 14pt;
  font-weight: bold;
  margin-top: 4pt;
`;

export const FinalLabelText = styled.Text`
  color: #000000;
  font-size: 8pt;
  margin-top: 12pt;
  font-family: "Montserrat";
  font-weight: bold;
`;
export const FinalValueText = styled.Text`
  color: #232323;
  font-size: 14pt;
  margin-left: 30pt;
  margin-top: 12pt;
  font-family: "Montserrat";
`;

export const DeclarationTitleText = styled.Text`
  font-family: "Montserrat";
  font-size: 10pt;
  color: #777;
  font-weight: bold;
`;

export const DeclarationContentText = styled.Text`
  font-family: "Montserrat";
  font-size: 12pt;
  color: #777;
`;

export const TextUnderline = styled.Text`
  color: #232323;
  font-family: "Montserrat";
  font-size: 10pt;
  font-weight: bold;
  border-bottom: 1px solid #000;
  display: inline-block;
  padding-bottom: 5px;
`;

export const TextItalic = styled.Text`
  font-family: "Montserrat";
  font-size: 8pt;
  color: #000000;
  font-weight: 300;
  font-style: italic;
`;

// Create Document Component
const BieuDoPhuTai = ({
  chartImage,
  tenDonVi,
  tenKhachHang,
  diaChiSuDungDien,
  ngayHienTai,
  congSuatSuDungTrungBinhNgay,
  congSuatSuDungLonNhatTrongNgay,
  congSuatSuDungNhoNhatTrongNgay,
  luongDienSuDungTrungBinhThang,
  congSuatLonNhatGioCaoDiemNgay,
  congSuatLonNhatGioCaoDiemToi,
}) => {
  
  const registerFont = () => {
    Font.register({
      family: "Montserrat",
      fonts: [
        {
          src: Regular,
          fontStyle: "normal",
          fontWeight: "normal",
          format: "truetype",
        },
        {
          src: Medium,
          fontStyle: "normal",
          fontWeight: "medium",
          format: "truetype",
        },
        {
          src: Bold,
          fontStyle: "normal",
          fontWeight: "bold",
          format: "truetype",
        },
        {
          src: Italic,
          fontStyle: "italic",
          fontWeight: "normal",
          format: "truetype",
        }
      ],
    });
  };
  useMemo(() => {
    registerFont();
  }, []);
  return (
    <Document>
      <Page
        size="A4"
        style={{
          margin: "0px",
          paddingBottom: "0pt",
          paddingTop: "0pt",
          paddingLeft: "0pt",
          paddingRight: "0pt",
          fontFamily: "Montserrat",
        }}
      >
        <FlexVertical style={{ width: "100vw" }}>
          <FlexHorizontal style={{ padding: "20pt" }}>
            <FlexVertical style={{ flexGrow: "1", alignItems: "center" }}>
              <CompanyName style={{ marginTop: "8pt" }}></CompanyName>
              <CompanyAddress>TỔNG CÔNG TY ĐIỆN LỰC TP HÀ NỘI</CompanyAddress>
              <TextUnderline style={{ marginTop: "8pt" }}>
                {tenDonVi || ""}
              </TextUnderline>
            </FlexVertical>
            <FlexVertical style={{ flexGrow: "1", alignItems: "center" }}>
              <CompanyName style={{ marginTop: "8pt" }}>
                CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM
              </CompanyName>
              <TextUnderline>Độc lập - Tự do - Hạnh phúc</TextUnderline>

              <CompanyAddress style={{ marginTop: "8pt" }}>
                Hà Nội, {ngayHienTai || ""}
              </CompanyAddress>
            </FlexVertical>
          </FlexHorizontal>

          <FlexVertical style={{ flexGrow: "1", alignItems: "center" }}>
            <ReportTitle>BẢN ĐĂNG KÝ BIỂU ĐỒ PHỤ TẢI</ReportTitle>
            <CompanyAddress>
              BIỂU ĐỒ PHỤ TẢI ĐIỂN HÌNH MỘT NGÀY ĐÊM
            </CompanyAddress>
          </FlexVertical>

          <FlexHorizontal
            style={{
              marginTop: "13pt",
              flexGrow: "1",
              alignItems: "start",
              marginLeft: 20,
            }}
          >
            <LabelText>Tên khách hàng: </LabelText>
            <ValueText>{tenKhachHang || ""}</ValueText>
          </FlexHorizontal>

          <FlexHorizontal
            style={{
              marginTop: "5pt",
              flexGrow: "1",
              alignItems: "start",
              marginLeft: 20,
            }}
          >
            <LabelText>Địa chỉ sử dụng điện: </LabelText>
            <ValueText>{diaChiSuDungDien || ""}</ValueText>
          </FlexHorizontal>

          <FlexVertical
            style={{
              flexGrow: "1",
              alignItems: "center",
              margin: 20,
            }}
          >
            <LabelText>
              (Thời gian từ ngày …….tháng……năm……. đến ngày …….tháng….. năm…….)
            </LabelText>
            <Image source={chartImage} />
          </FlexVertical>
          <FlexHorizontal style={{ padding: "20pt" }}>
            <FlexVertical style={{ flexGrow: "1", alignItems: "start" }}>
              <FinalLabelText>
                1. Công suất và sản lượng điện sử dụng:
              </FinalLabelText>
              <FlexHorizontal
                style={{
                  marginTop: "13pt",
                  flexGrow: "1",
                  alignItems: "start",
                }}
              >
                <LabelText>
                  {" "}
                  - Công suất sử dụng trung bình ngày (Ptb):{" "}
                </LabelText>
                <ValueText>{congSuatSuDungTrungBinhNgay || ""} kW</ValueText>
              </FlexHorizontal>
              <FlexHorizontal
                style={{
                  marginTop: "13pt",
                  flexGrow: "1",
                  alignItems: "start",
                }}
              >
                <LabelText>
                  {" "}
                  - Công suất sử dụng lớn nhất trong ngày (Pmax):{" "}
                </LabelText>
                <ValueText>{congSuatSuDungLonNhatTrongNgay || ""} kW</ValueText>
              </FlexHorizontal>

              <FlexHorizontal
                style={{
                  marginTop: "13pt",
                  flexGrow: "1",
                  alignItems: "start",
                }}
              >
                <LabelText>
                  {" "}
                  - Công suất sử dụng nhỏ nhất trong ngày (Pmin):{" "}
                </LabelText>
                <ValueText>{congSuatSuDungNhoNhatTrongNgay || ""} kW</ValueText>
              </FlexHorizontal>
              <FlexHorizontal
                style={{
                  marginTop: "13pt",
                  flexGrow: "1",
                  alignItems: "start",
                }}
              >
                <LabelText>
                  {" "}
                  - Sản lượng điện sử dụng trung bình một tháng (Atb) ≤:{" "}
                </LabelText>
                <ValueText>
                  {luongDienSuDungTrungBinhThang || ""} kWh/tháng
                </ValueText>
              </FlexHorizontal>
              <FlexVertical
                style={{
                  marginTop: "13pt",
                  flexGrow: "1",
                  alignItems: "start",
                }}
              >
                <LabelText>
                  {" "}
                  - Công suất sử dụng lớn nhất trong giờ cao điểm:{" "}
                </LabelText>
                <FlexHorizontal
                  style={{
                    marginTop: "13pt",
                    flexGrow: "1",
                    alignItems: "start",
                    marginLeft: "20pt",
                  }}
                >
                  <LabelText> + Từ 09h30 đến 11h30: </LabelText>
                  <ValueText>
                    {congSuatLonNhatGioCaoDiemNgay || ""} kW
                  </ValueText>
                </FlexHorizontal>
                <FlexHorizontal
                  style={{
                    marginTop: "13pt",
                    flexGrow: "1",
                    alignItems: "start",
                    marginLeft: "20pt",
                  }}
                >
                  <LabelText> + Từ 17h00 đến 20h00: </LabelText>
                  <ValueText>{congSuatLonNhatGioCaoDiemToi || ""} kW</ValueText>
                </FlexHorizontal>
              </FlexVertical>
            </FlexVertical>
            <FlexVertical style={{ flexGrow: "1", alignItems: "start" }}>
              <FinalLabelText>
                2. Chế độ sử dụng điện theo thời gian:
              </FinalLabelText>
              <FlexHorizontal
                style={{
                  marginTop: "13pt",
                  flexGrow: "1",
                  alignItems: "start",
                }}
              >
                <LabelText> - Giờ hành chính: </LabelText>
                <ValueText>...........</ValueText>
              </FlexHorizontal>
              <FlexVertical
                style={{
                  flexGrow: "1",
                  alignItems: "start",
                }}
              >
                <FlexHorizontal
                  style={{
                    marginTop: "13pt",
                    flexGrow: "1",
                    alignItems: "start",
                  }}
                >
                  <LabelText> - Theo ca: </LabelText>
                  <ValueText>...........Ca</ValueText>
                </FlexHorizontal>
                <FlexHorizontal
                  style={{
                    marginTop: "13pt",
                    flexGrow: "1",
                    alignItems: "start",
                    marginLeft: "20pt",
                  }}
                >
                  <LabelText> + Ca 1: </LabelText>
                  <ValueText>Từ ...........Đến...........</ValueText>
                </FlexHorizontal>
                <FlexHorizontal
                  style={{
                    marginTop: "13pt",
                    flexGrow: "1",
                    alignItems: "start",
                    marginLeft: "20pt",
                  }}
                >
                  <LabelText> + Ca 2: </LabelText>
                  <ValueText>Từ ...........Đến...........</ValueText>
                </FlexHorizontal>
                <FlexHorizontal
                  style={{
                    marginTop: "13pt",
                    flexGrow: "1",
                    alignItems: "start",
                    marginLeft: "20pt",
                  }}
                >
                  <LabelText> + Ca 3: </LabelText>
                  <ValueText>Từ ...........Đến...........</ValueText>
                </FlexHorizontal>
              </FlexVertical>
            </FlexVertical>
          </FlexHorizontal>

          <FlexHorizontal style={{ marginTop: "5pt", flexGrow: "1" }}>
            <FlexVertical
              style={{ flexGrow: "1", alignItems: "center" }}
            ></FlexVertical>
            <FlexVertical
              style={{ flexGrow: "1", alignItems: "center" }}
            ></FlexVertical>
            <FlexVertical style={{ flexGrow: "1", alignItems: "center" }}>
              <LabelText>
                ............., ngày..........tháng..........năm............
              </LabelText>
              <Customer style={{ marginTop: "8pt" }}>KHÁCH HÀNG</Customer>
              <CompanyName style={{ marginTop: "8pt" }}></CompanyName>
              <CompanyName style={{ marginTop: "8pt" }}></CompanyName>
              <CompanyName style={{ marginTop: "8pt" }}></CompanyName>
              <CompanyName style={{ marginTop: "8pt" }}></CompanyName>
              <CompanyName style={{ marginTop: "8pt" }}></CompanyName>
              <CompanyName style={{ marginTop: "8pt" }}></CompanyName>
              <CompanyName style={{ marginTop: "8pt" }}>
                {tenKhachHang.toUpperCase() || ""}
              </CompanyName>
              <CompanyName style={{ marginTop: "8pt" }}></CompanyName>
              <CompanyName style={{ marginTop: "8pt" }}></CompanyName>
            </FlexVertical>
          </FlexHorizontal>
        </FlexVertical>
      </Page>
    </Document>
  );
};

export default BieuDoPhuTai;
