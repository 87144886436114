import { memo, useRef, useState, useEffect } from "react";
import { Form, Input, Button, Modal, Row, Col, Checkbox } from "antd";
import FormComponent from "@components/Form";
import { useTranslation } from "react-i18next";
import LoadingComponent from "@components/Loading";
import { handleSubmitForm } from "./services";
import InputFormatter from "@components/InputFormatter";
import { TYPE_INPUT } from "@utils/constants";
const { TextArea } = Input;
function HinhThucLapDatModal({
  detailHinhThuc,
  visible,
  closeModal,
  getListHinhThuc,
}) {
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(detailHinhThuc.isActive);

  const onFinish = (values) => {
    const data = {
      ...values,
      isActive: isActive ? isActive : "false",
      maHinhThuc: values.maHinhThuc === 0 ? "0" : values.maHinhThuc,
    };
    handleSubmitForm(
      data,
      detailHinhThuc,
      setLoading,
      closeModal,
      getListHinhThuc,
      form
    );
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();
    //Set  mặc định Trạng Thái=true nếu cửa sổ đang là tạo mới
    if (detailHinhThuc && detailHinhThuc.id) {
      setIsActive(isActive);
    } else {
      setIsActive(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleChangeActive = (e) => {
    setIsActive(e.target.checked);
  };

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={
          detailHinhThuc.id
            ? "Cập nhật hình thức lắp đặt"
            : "Tạo mới hình thức lắp đặt"
        }
        visible={visible}
        onCancel={closeModal}
        className="modal"
        footer={null}
      >
        <FormComponent
          autoComplete="off"
          form={form}
          name="form-submit"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            ...detailHinhThuc,
            isActive: isActive ? isActive : false,
          }}
          scrollToFirstError
        >
          <Row gutter={24}>
            <Col span={16}>
              <Form.Item
                label="Mã hình thức"
                name="maHinhThuc"
                className="required-field"
              >
                <Input
                  ref={inputRef}
                  placeholder="Nhập mã hình thức"
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col span={8} style={{ display: "flex", alignItems: "center" }}>
              <Form.Item label="" name="isActive" style={{ marginBottom: "0" }}>
                <Checkbox onChange={handleChangeActive} checked={isActive}>
                  Hoạt động
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Tên hình thức lắp đặt"
                name="tenHinhThuc"
                className="required-field"
              >
                <Input placeholder="Nhập tên hình thức lắp đặt" />
              </Form.Item>
            </Col>
            <Col span={24} md={24}>
              <Form.Item
                label="Bên cung cấp cáp chính"
                name="benCungCapCapChinh"
                className="required-field"
              >
                <TextArea placeholder="Nhập bên cung cấp cáp chính" rows={3} />
              </Form.Item>
            </Col>
            <Col span={24} md={24}>
              <Form.Item
                label="Bên cung cấp phụ kiện"
                name="benCungCapPhuKien"
                className="required-field"
              >
                <TextArea placeholder="Nhập bên cung phụ kiện" rows={3} />
              </Form.Item>
            </Col>
            <Col span={24} md={24}>
              <Form.Item
                label="Tổng chi phí"
                name="tongChiPhi"
                className="required-field"
              >
                <InputFormatter min={0} type={TYPE_INPUT.DECIMAL} />
              </Form.Item>
            </Col>
            <Col span={24} md={24}>
              <Form.Item
                label="Bên cung cấp nhân công lắp đặt"
                name="benCungCapNhanCongLapDat"
                className="required-field"
              >
                <TextArea
                  placeholder="Nhập bên cung cấp nhân công lắp đặt"
                  rows={3}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-submit"
                loading={loading}
              >
                {detailHinhThuc.id ? t("button.cap_nhat") : t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(HinhThucLapDatModal);
