import React, { memo, useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  Select,
  Row,
  Col,
  DatePicker,
  // Checkbox,
} from "antd";
import { useTranslation } from "react-i18next";
import FormComponent from "@components/Form";
import TextArea from "antd/lib/input/TextArea";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import moment from "moment";
import { FORMAT_DATE, FORMAT_ENGLISH } from "@utils/constants";
import LoadingComponent from "@components/Loading";
import { handleSubmitForm } from "./services";

const { Option } = Select;

function DonViModal({
  detaiDataTable,
  visible,
  closeModal,
  dataNganHang,
  dataDiaChinh,
  getListData,
  dataApGiaUyQuyen,
  // isTongCongTy,
}) {
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const { t } = useTranslation();
  // const [isCompany, setIsCompany] = useState(detaiDataTable.isTongCongTy);
  const [diaChinh, setDiaChinh] = useState(detaiDataTable.quanId);
  const [loaiDonVi, setTypeUnit] = useState(detaiDataTable.loaiDonVi);
  const [vung, setVungDonGia] = useState(detaiDataTable.vungDonGia);
  const [bankId, setBank] = useState(detaiDataTable.nganHangId);
  const [dienThoai, setDienThoai] = useState(detaiDataTable.dienThoai);
  const [apGiaUyQuyen, setApGiaUyQuyen] = useState(detaiDataTable.uyQuyenAGId);
  const toDay = moment().format(FORMAT_ENGLISH);
  const [loading, setLoading] = useState(false);
  const [ngayQuyetDinh, setNgayQuyetDinh] = useState(
    detaiDataTable.ngayQuyetDinh === null
      ? undefined
      : detaiDataTable.ngayQuyetDinh
        ? detaiDataTable.ngayQuyetDinh
        : toDay
  );
  const onFinish = (values) => {
    handleSubmitForm(
      values,
      detaiDataTable,
      setLoading,
      closeModal,
      getListData,
      form,
      // isCompany,
      diaChinh,
      vung,
      bankId,
      loaiDonVi,
      dienThoai,
      ngayQuyetDinh,
      apGiaUyQuyen
    );
  };

  const handlChangeNgay = (date) => {
    let convert = "";
    if (date === null) convert = null;
    else convert = moment(date).format(FORMAT_ENGLISH);
    setNgayQuyetDinh(convert);
  };

  //check là tổng công ty

  // const handleChangeCompany = (e) => {
  //   setIsCompany(e.target.checked);
  // };

  const handleChangeDiaChinh = (value) => {
    setDiaChinh(value);
  };

  const handleChangeTypeUnit = (value) => {
    setTypeUnit(value);
  };

  const handleChangeVungDonGia = (value) => {
    setVungDonGia(value);
  };

  const handleChangeBank = (value) => {
    setBank(value);
  };

  const handleUyQuyenAG = (value) => {
    setApGiaUyQuyen(value);
  };

  const handleChangePhone = (e) => {
    setDienThoai(e.target.value);
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();
    // eslint-disable-next-line
  }, []);

  const handleSoThuTu = (e) => {
    if (e.target.value === "")
      form.setFieldsValue({
        soThuTu: 0,
      });
  };

  const loaiDonViArr = [
    {
      id: 1,
      tenLoaiDonVi: "Công ty điện lực",
    },
    {
      id: 2,
      tenLoaiDonVi: "Nhà máy",
    },
    {
      id: 3,
      tenLoaiDonVi: "Truyền tải",
    },
    {
      id: 4,
      tenLoaiDonVi: "Khác",
    },
  ];

  const vungDonGiaArr = [

    {
      id: 1,
      tenVung: "Vùng 1 KV1",
    },
    {
      id: 3,
      tenVung: "Vùng 1 KV2",
    },
    {
      id: 2,
      tenVung: "Vùng 2",
    },
  ];

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={
          detaiDataTable.id
            ? t("don_vi.cap_nhat_don_vi")
            : t("don_vi.tao_don_vi")
        }
        visible={visible}
        onCancel={closeModal}
        className="modal-don-vi"
        width={"75%"}
        style={{
          top: "8%",
          left: "4%",
        }}
        footer={null}
      >
        <FormComponent
          form={form}
          name="form-don-vi"
          onFinish={onFinish}
          initialValues={{
            ...detaiDataTable,
            ngayQuyetDinh:
              ngayQuyetDinh === undefined ? undefined : moment(ngayQuyetDinh),
            soThuTu: detaiDataTable.soThuTu ? detaiDataTable.soThuTu : 0,
          }}
          layout="vertical"
          scrollToFirstError
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={24} md={8}>
              <Form.Item
                label={t("don_vi.ma_don_vi")}
                name="maDonVi"
                className="required-field"
              >
                <Input
                  ref={inputRef}
                  readOnly={detaiDataTable.id ? true : false}
                  disabled={detaiDataTable.id ? true : false}
                  placeholder="Nhập mã đơn vị"
                />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item
                label={t("don_vi.ten_don_vi")}
                name="tenDonVi"
                className="required-field"
              >
                <Input placeholder="Nhập tên đơn vị" />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item
                label="Mã định danh"
                name="maDinhDanh"
                className="required-field"
              >
                <Input placeholder="Nhập mã định danh" />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item
                label={t("don_vi.quan_huyen")}
                name="quanId"
                className="required-field"
              >
                <Select
                  onChange={handleChangeDiaChinh}
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Chọn quận huyện"
                  defaultValue={diaChinh}
                >
                  {dataDiaChinh && dataDiaChinh.length > 0
                    ? dataDiaChinh.map((element, index) => (
                      <Option value={element.id} key={index}>
                        {element.tenDiaChinh}
                      </Option>
                    ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item
                label={t("don_vi.loai_don_vi")}
                name="loaiDonVi"
                className="required-field"
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Chọn loại đơn vị"
                  onChange={handleChangeTypeUnit}
                  defaultValue={loaiDonVi}
                >
                  {loaiDonViArr && loaiDonViArr.length > 0
                    ? loaiDonViArr.map((element, index) => (
                      <Option value={element.id} key={index}>
                        {element.tenLoaiDonVi}
                      </Option>
                    ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item label={t("don_vi.email")} name="email">
                <Input placeholder="Nhập email" />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item label={t("don_vi.dien_thoai")} name="dienThoai">
                <Input
                  type="number"
                  onChange={handleChangePhone}
                  placeholder="Nhập điện thoại"
                />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item label={t("don_vi.fax")} name="fax">
                <Input placeholder="Nhập fax" />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item label={t("don_vi.so_thu_tu")} name="soThuTu">
                <Input
                  type="number"
                  min={0}
                  onChange={handleSoThuTu}
                  placeholder="Nhập số thứ tư"
                />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item label={t("don_vi.vung_don_gia")} name="vungDonGia">
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Chọn vùng đơn giá"
                  onChange={handleChangeVungDonGia}
                  defaultValue={vung}
                >
                  {vungDonGiaArr && vungDonGiaArr.length > 0
                    ? vungDonGiaArr.map((element, index) => (
                      <Option value={element.id} key={index}>
                        {element.tenVung}
                      </Option>
                    ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item label={t("don_vi.ma_so_thue")} name="maSoThue">
                <Input placeholder="Nhập mã số thuế" />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item label={t("don_vi.tai_khoan")} name="taiKhoan">
                <Input placeholder="Nhập tài khoản" />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item label={t("don_vi.ngan_hang")} name="nganHangId">
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Chọn ngân hàng"
                  onChange={handleChangeBank}
                  defaultValue={bankId}
                >
                  {dataNganHang && dataNganHang.length > 0
                    ? dataNganHang.map((element, index) => (
                      <Option value={element.id} key={index}>
                        {element.maNganHang + " - " + element.tenNganHang}
                      </Option>
                    ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item label={t("don_vi.dai_dien")} name="daiDien">
                <Input placeholder="Nhập đại diện" />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item label={t("don_vi.chuc_vu")} name="chucVu">
                <Input placeholder="Nhập chức vụ" />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item label={t("don_vi.so_quyet_dinh")} name="soQuyetDinh">
                <Input placeholder="Nhập số quyết định" />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item
                label={t("don_vi.ngay_quyet_dinh")}
                name="ngayQuyetDinh"
              >
                <DatePicker
                  locale={locale}
                  format={FORMAT_DATE}
                  onChange={handlChangeNgay}
                  placeholder={FORMAT_DATE}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item label={t("don_vi.nguoi_uy_quyen")} name="nguoiUyQuyen">
                <Input placeholder="Nhập người ủy quyền" />
              </Form.Item>
            </Col>
            {
              detaiDataTable.id ?
                <Col span={24} md={8}>
                  <Form.Item label={"Ủy quyền Áp giá"} name="uyQuyenAGId">
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Chọn Ủy quyền áp giá"
                      onChange={handleUyQuyenAG}
                      defaultValue={apGiaUyQuyen}
                    >
                      {dataApGiaUyQuyen && dataApGiaUyQuyen.length > 0
                        ? dataApGiaUyQuyen.map((element, index) => (
                          <Option value={element.value} key={index}>
                            {element.name}
                          </Option>
                        ))
                        : null}
                    </Select>
                  </Form.Item>
                </Col> :
                null
            }

            {/* {isTongCongTy ? (
              <Col
                span={24}
                md={8}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Form.Item name="isTongCongTy" style={{ marginBottom: "0" }}>
                  <Checkbox checked={isCompany} onChange={handleChangeCompany}>
                    Tổng công ty
                  </Checkbox>
                </Form.Item>
              </Col>
            ) : null} */}
            <Col span={24} md={24}>
              <Form.Item label={t("don_vi.dia_chi")} name="diaChi">
                <TextArea rows={3} placeholder="Nhập địa chỉ" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-don-vi"
                loading={loading}
              >
                {detaiDataTable.id ? t("button.cap_nhat") : t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(DonViModal);
