import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";

export const handleUploadBBTTPDF = (
  setLoading,
  payload,
  setFetchList,
  closeModal,
  handleListImage,
  setIsUploadFile,
  setImageFiles,
  // setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.UPLOAD_PDF_BBTT_GIAY,
    method: METHOD_PUT,
    setLoading,
    payload,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
        setImageFiles([]);
        // closeModal();
        handleListImage();
        setIsUploadFile(true);
        // setSelectedRowKeys([]);
      } else setIsUploadFile(false);
    },
  });
};

export const handleGetListImage = (
  selectedRowKeys,
  setLoading,
  setDataImage,
  setTypeFile
) => {
  authGetData({
    url: Endpoint.GET_PDF_BBTT_GIAY + "?yeuCauId=" + selectedRowKeys.toString(),
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        if (res.data.indexOf(".pdf") > -1 || res.data.indexOf(".PDF") > -1) {
          setTypeFile("pdf");
        } else setTypeFile("image");
        setDataImage(res.data);
      }
    },
  });
};

export const handleXNBBTTPDF = (
  setLoading,
  payload,
  setFetchList,
  closeModal,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.XN_UPLOAD_PDF_BBTT_GIAY,
    method: METHOD_PUT,
    setLoading,
    payload,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
        closeModal();
        setSelectedRowKeys([]);
      }
    },
  });
};
