import { MIN_WIDTH } from "@utils/constants";
import { Modal, Button, Row, Checkbox, Divider } from "antd";
import React, { useCallback, useEffect, useState, Fragment } from "react";
import { CheckBoxGroupCol } from "../css-styled";

function HideDisplayColumns({
  visible,
  closeModal,
  arrColumns,
  setNewColumns,
  // setScrollX,
  textGroup1,
  textGroup2,
  textGroup3,
  textGroup4,
  setIsSubmit,
  titleHeader,
  // handleColumnsTable,
}) {
  const [defaultList, setDefaultList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [checked, setChecked] = useState([]);
  const [group1, setGroup1] = useState([]);
  const [group2, setGroup2] = useState([]);
  const [group3, setGroup3] = useState([]);
  const [group4, setGroup4] = useState([]);
  // const [count, setCount] = useState("max-content");

  //check column
  function onChange(list) {
    setChecked(list);
    setIndeterminate(!!list.length && list.length < defaultList.length);
    setIsCheckAll(list.length === defaultList.length);
  }

  //check all columns
  const onCheckAllChange = (e) => {
    if (e.target.checked) setChecked(checkAll);
    else setChecked([]);
    setIndeterminate(false);
    setIsCheckAll(e.target.checked);
  };

  useEffect(() => {
    const arrList = [];
    const checked = [];
    const checkAll = [];
    // eslint-disable-next-line
    arrColumns.map((item) => {
      if (!item.disabled) {
        arrList.push({
          label: item.title,
          value: item.key,
          group: item.group ? item.group : null,
        });
        if (!item.hide || item.disabled) checked.push(item.key);
        checkAll.push(item.key);
      }
    });
    const arrGroup1 = [];
    const arrGroup2 = [];
    const arrGroup3 = [];
    const arrGroup4 = [];
    arrList.forEach((item) => {
      switch (item.group) {
        case 1:
          return arrGroup1.push(item);
        case 2:
          return arrGroup2.push(item);
        case 3:
          return arrGroup3.push(item);
        case 4:
          return arrGroup4.push(item);
        default:
          return arrList;
      }
    });

    setCheckAll(checkAll);
    setChecked(checked);
    setDefaultList(arrList);
    setGroup1(arrGroup1);
    setGroup2(arrGroup2);
    setGroup3(arrGroup3);
    setGroup4(arrGroup4);
    if (checked.length === arrList.length) {
      setIndeterminate(false);
      setIsCheckAll(true);
    }
  }, [arrColumns]);

  function handleForm() {
    handleColumnsTable(checked);
    // if (checked.length >= 10) {
    //   setScrollX(count);
    // } else {
    //   setScrollX("max-content");
    // }
    setIsSubmit(true);
    closeModal();
  }

  //set new columns
  const handleColumnsTable = useCallback(
    (column) => {
      // let count = 0;
      arrColumns.map((item) => {
        // count += item.width;
        if (column.includes(item.key) || item.disabled)
          return (item.hide = false);
        else return (item.hide = true);
      });
      // setCount(count);

      setNewColumns(arrColumns);
      return arrColumns;
    },
    [arrColumns, setNewColumns]
  );

  //render group columns by name
  const renderGroupColumns = useCallback(() => {
    let minWidth = MIN_WIDTH.WIDTH_100_PHANTRAM;
    if (group1.length > 0 && group2.length > 0)
      minWidth = MIN_WIDTH.WIDTH_50_PHANTRAM;
    if (group1.length > 0 && group2.length > 0 && group3.length > 0)
      minWidth = MIN_WIDTH.WIDTH_33_PHANTRAM;
    if (
      group1.length > 0 &&
      group2.length > 0 &&
      group3.length > 0 &&
      group4.length > 0
    )
      minWidth = MIN_WIDTH.WIDTH_25_PHANTRAM;
    if (
      group1.length > 0 ||
      group2.length > 0 ||
      group3.length > 0 ||
      group4.length > 0
    ) {
      return (
        <Fragment>
          {group1.length > 0 ? (
            <div
              className="group-1"
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: minWidth,
              }}
            >
              <h3>{textGroup1}</h3>
              {group1.map((item, index) => (
                <Checkbox value={item.value} key={index}>
                  {item.label}
                </Checkbox>
              ))}
            </div>
          ) : null}
          {group2.length > 0 ? (
            <div
              className="group-2"
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: minWidth,
              }}
            >
              <h3>{textGroup2}</h3>
              {group2.map((item, index) => (
                <Checkbox value={item.value} key={index}>
                  {item.label}
                </Checkbox>
              ))}
            </div>
          ) : null}
          {group3.length > 0 ? (
            <div
              className="group-3"
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: minWidth,
              }}
            >
              <h3>{textGroup3}</h3>
              {group3.map((item, index) => (
                <Checkbox value={item.value} key={index}>
                  {item.label}
                </Checkbox>
              ))}
            </div>
          ) : null}
          {group4.length > 0 ? (
            <div
              className="group-4"
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: minWidth,
              }}
            >
              <h3>{textGroup4}</h3>
              {group4.map((item, index) => (
                <Checkbox value={item.value} key={index}>
                  {item.label}
                </Checkbox>
              ))}
            </div>
          ) : null}
        </Fragment>
      );
    } else {
      return (
        <div className="group-full">
          {defaultList.map((item, index) => (
            <Checkbox value={item.value} key={index}>
              {item.label}
            </Checkbox>
          ))}
        </div>
      );
    }
  }, [
    defaultList,
    group1,
    group2,
    group3,
    group4,
    textGroup1,
    textGroup2,
    textGroup3,
    textGroup4,
  ]);

  return (
    <Modal
      title={`${titleHeader ? titleHeader : "Cấu hình cột cho bảng"}`}
      visible={visible}
      onCancel={closeModal}
      className="modal-hide-show"
      footer={[
        <Button key="back" onClick={closeModal} className="button-closed">
          Đóng lại
        </Button>,
        <Button
          key="submit"
          type="primary"
          className="button-primary"
          htmlType="submit"
          form="form-hide"
          onClick={handleForm}
        >
          Cập nhật
        </Button>,
      ]}
    >
      <Row>
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={isCheckAll}
        >
          Hiển thị tất cả
        </Checkbox>
        <Divider />
        {
          <CheckBoxGroupCol>
            <Checkbox.Group
              // options={defaultList}
              style={{ display: "flex" }}
              value={checked}
              onChange={onChange}
            >
              {renderGroupColumns()}
              {/* {defaultList.map((item, index) => {
                if (item.group === 1) {
                  return (
                    <div className="group-1">
                      <Checkbox value={item.value} key={index}>
                        {item.label}
                      </Checkbox>
                    </div>
                  );
                } else if (item.group === 2)
                  return (
                    <div className="group-2">
                      <Checkbox value={item.value} key={index}>
                        {item.label}
                      </Checkbox>
                    </div>
                  );
                else
                  return (
                    <div className="group-3">
                      <Checkbox value={item.value} key={index}>
                        {item.label}
                      </Checkbox>
                    </div>
                  );
              })} */}
            </Checkbox.Group>
          </CheckBoxGroupCol>
        }
      </Row>
    </Modal>
  );
}

export default HideDisplayColumns;
