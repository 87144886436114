import { authSelector } from "@state/auth";
import { Col, Form, Row, Select, DatePicker, Button } from "antd";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { FORMAT_DATE } from "@utils/constants";
import FormComponent from "@components/Form";
const { Option } = Select;

const { RangePicker } = DatePicker;
// const { SHOW_CHILD } = TreeSelect;

export function ListFilter(props) {
  const { handleSearch,
    clearFilter,
    filterConditions,
    changeRange,
    rangeDate,
    handleChangeDonVi,
    dataDonVi,
    dataBienBan,
    handleChangeBienBan,
    bienBan,
    filterBB,
    loadingBB
  } =
    props;
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);

  useEffect(() => {
    form.setFieldsValue(filterBB);
  }, [form, filterBB]);

  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  // const tProps = {
  //   treeData: dataBienBan,
  //   showSearch: true,
  //   treeNodeFilterProp: 'title',
  //   value: bienBan,
  //   onChange: handleChangeBienBan,
  //   showCheckedStrategy: SHOW_CHILD,
  //   style: {
  //     width: '100%',
  //   },
  //   maxTagCount: 3,
  // };
  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        DonViId: user.unitId.toString(),
      }}
    >
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="DonViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Select onChange={handleChangeDonVi}>
                {dataDonVi && dataDonVi.length > 0
                  ? dataDonVi.map((item, index) => (
                    <Option key={index} value={item.value}>
                      {item.name}
                    </Option>
                  ))
                  : null}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <div style={{ paddingBottom: "8px" }}>
            <span>
              Ngày gửi kiểm định
            </span>
          </div>
          <RangePicker
            format={FORMAT_DATE}
            onChange={changeRange}
            value={rangeDate}
            allowClear={false}
            style={{ display: "flex" }}
          />
        </Col>
        <Col span={24} md={6}>
          {/* <Form.Item name="MaBienBan" label="Số biên bản"> */}
          <div style={{ paddingBottom: "8px" }}>
            <span>
              Số biên bản
            </span>
            <span style={{ color: "red" }}>*</span>
          </div>
          {dataBienBan && dataBienBan.length > 0 ?
            <Select style={{ display: "block" }} onChange={handleChangeBienBan} value={bienBan} loading={loadingBB}
              showSearch filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
              {
                dataBienBan.map((item, index) => (
                  <Option key={index} value={item.maBienBan}>
                    {item.maBienBan}
                  </Option>
                ))
              }
            </Select> :
            <Select style={{ display: "block" }}></Select>
          }
          {/* <TreeSelect {...tProps} loading={loadingBB} /> */}
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="IsSelected" label="Trạng thái">
            <Select placeholder={"Tất cả"} allowClear>
              <Option value={"1"}>Đạt</Option>
              <Option value={"2"}>Không đạt</Option>
            </Select>
          </Form.Item>
        </Col>

      </Row>
      <Row justify="end">
        <Button className="mr-24px" onClick={clear}>
          Bỏ lọc
        </Button>
        <Button type="primary" htmlType="submit" form="filter-form">
          Tìm kiếm
        </Button>
      </Row>
    </FormComponent>
  );
}
