import { authSelector } from "@state/auth";
import {
  buildQueryString,
  handlePagination,
  // handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { handleListBaoCao } from "./services";
import { Table } from "antd";
import Text from "antd/lib/typography/Text";
import BaoCaoTiepNhanDVTable from "./table";
import moment from "moment";
import {
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import { downloadFile } from "@utils/request";
import { Endpoint } from "@utils/endpoint";

export default function Index() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { user } = useSelector(authSelector);
  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);
  //filter
  const [filterConditions, setFilterConditions] = useState({
    ...parseParams(location.search),
    donViId: user.unitId,
    tinhTrangTiepNhan: "1",
    startDateBaoCao: moment(moment().startOf(MONTH_STRING)).format(
      FORMAT_ENGLISH
    ),
    endDateBaoCao: moment().format(FORMAT_ENGLISH),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //change pagination
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  };

  //fetch báo cáo
  const getListBaoCao = useCallback(() => {
    handleListBaoCao(filterConditions, setLoading, setData, setTotalCount);
  }, [filterConditions]);

  const [filterExcel, setFilterExcel] = useState(false);

  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type]);

  const [value, setValue] = useState("1");
  const handleChange = (value) => {
    setValue(value);
  };

  //search
  const handleSearch = useCallback(
    (values) => {
      setFilterConditions(() => ({
        // pageIndex: DEFAULT_PAGEINDEX,
        ...values,
        // donViId: values.donViId,
      }));
      handleAPI();
    },
    [handleAPI]
  );

  //clear
  const clearFilter = useCallback(() => {
    setFilterConditions({
      // pageIndex: DEFAULT_PAGEINDEX,
      // pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
      tinhTrangTiepNhan: "1",
      startDateBaoCao: moment(moment().startOf(MONTH_STRING)).format(
        FORMAT_ENGLISH
      ),
      endDateBaoCao: moment().format(FORMAT_ENGLISH),
    });
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  }, [user.unitId]);

  useEffect(() => {
    if (filterExcel) {
      const endpoint = `${
        Endpoint.EXPORT_EXCEL_BAO_CAO_DICH_VU_DIEN
      }?${buildQueryString(parseParams(filterConditions))}`;
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel]);

  useEffect(() => {
    if (type === ENUM_BUTTON_TYPE.TIM_KIEM) getListBaoCao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListBaoCao]);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "5%",
    },
    {
      title: user.isTongCongTy ? "Đơn vị" : "Đội",
      dataIndex: "maDonViHoacMaDoi",
      key: "maDonViHoacMaDoi",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Tổng số tiếp nhận",
      dataIndex: "tongSoTiepNhan",
      key: "tongSoTiepNhan",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Cổng DVCQG",
      dataIndex: "congDVCQG",
      key: "congDVCQG",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "7%",
      sorter: true,
    },
    {
      title: "TT.CSKH",
      children: [
        {
          title: "Web CSKH",
          dataIndex: "webCSKH",
          key: "webCSKH",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "8%",
          sorter: true,
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "8%",
          sorter: true,
        },
        {
          title: "App CSKH",
          dataIndex: "appCSKH",
          key: "appCSKH",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "8%",
          sorter: true,
        },
        {
          title: "Tổng đài 19001288",
          dataIndex: "tongDai",
          key: "tongDai",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "8%",
          sorter: true,
        },
      ],
    },
    {
      title: "Phòng GD-ĐL",
      dataIndex: "phongGDDL",
      key: "phongGDDL",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "6%",
      sorter: true,
    },
    {
      title: "Tỷ lệ tiếp nhận dịch vụ cấp điện qua TT.CSKH & DVCQG (%)",
      dataIndex: "tiLeQuaCSKHVaDVCQG",
      key: "tiLeQuaCSKHVaDVCQG",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "17%",
      sorter: true,
    },
    {
      title: "Tỷ lệ tiếp nhận dịch vụ điện qua Cổng DVCQG (%)",
      dataIndex: "tiLeQuaCongDVCQG",
      key: "tiLeQuaCongDVCQG",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "13%",
      sorter: true,
    },
  ];
  //footer total count table
  const countTotals = (pageData) => {
    let countTongSoTiepNhan = 0;
    let countCongDVCQG = 0;
    let countWebCSKH = 0;
    let countEmail = 0;
    let countAppCSKH = 0;
    let countTongDai = 0;
    let countPhongGDDL = 0;
    // let countTyleTiepNhanDVDQuaCSKH = 0;
    // let countTyleTiepNhanDVDQuaDVCQG = 0;

    pageData.forEach(
      ({
        tongSoTiepNhan,
        congDVCQG,
        webCSKH,
        email,
        appCSKH,
        tongDai,
        phongGDDL,
        // tiLeQuaCSKHVaDVCQG,
        // tiLeQuaCongDVCQG,
      }) => {
        countTongSoTiepNhan += tongSoTiepNhan;
        countCongDVCQG += congDVCQG;
        countWebCSKH += webCSKH;
        countEmail += email;
        countAppCSKH += appCSKH;
        countTongDai += tongDai;
        countPhongGDDL += phongGDDL;
        // countTyleTiepNhanDVDQuaCSKH += tiLeQuaCSKHVaDVCQG;
        // countTyleTiepNhanDVDQuaDVCQG += tiLeQuaCongDVCQG;
      }
    );
    return (
      <Table.Summary fixed>
        <Table.Summary.Row style={{ backgroundColor: "#FFEAEA" }}>
          <Table.Summary.Cell align="center" colSpan={2} index={0}>
            <Text className="font-bold">Tổng cộng</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={1}>
            <Text className="font-bold">
              {countCongDVCQG +
                countWebCSKH +
                countEmail +
                countAppCSKH +
                countTongDai +
                countPhongGDDL}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={2}>
            <Text className="font-bold">{countCongDVCQG}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={3}>
            <Text className="font-bold">{countWebCSKH}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={4}>
            <Text className="font-bold">{countEmail}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={5}>
            <Text className="font-bold">{countAppCSKH}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={6}>
            <Text className="font-bold">{countTongDai}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={7}>
            <Text className="font-bold">{countPhongGDDL}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={8}>
            <Text className="font-bold">
              {countCongDVCQG +
                countWebCSKH +
                countEmail +
                countAppCSKH +
                countTongDai ===
              0
                ? 0
                : (
                    ((countCongDVCQG +
                      countWebCSKH +
                      countEmail +
                      countAppCSKH +
                      countTongDai) /
                      countTongSoTiepNhan) *
                    100
                  ).toFixed(2)}
              %
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={9}>
            <Text className="font-bold">
              {countCongDVCQG === 0
                ? 0
                : ((countCongDVCQG / countTongSoTiepNhan) * 100).toFixed(2)}
              %
            </Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  //render note item for columns
  const noteColumns = [
    {
      title: "",
      // fixed: true,
    },
    {
      title: "(1)",
      // fixed: true,
    },
    {
      title: "(2) = (3+4+5+6+7+8)",
      // fixed: true,
      // last: true,
    },
    {
      title: "(3)",
    },
    {
      title: "(4)",
    },
    {
      title: "(5)",
    },
    {
      title: "(6)",
    },
    {
      title: "(7)",
    },
    {
      title: "(8)",
    },
    {
      title: "(9) = (3+4+5+6+7)/(2)",
    },
    {
      title: "(10) = (3)/(2)",
    },
  ];

  return (
    <BaoCaoTiepNhanDVTable
      handleSearch={handleSearch}
      clearFilter={clearFilter}
      filterConditions={filterConditions}
      totalCount={totalCount}
      loading={loading}
      columns={columns}
      data={data}
      countTotals={countTotals}
      noteColumns={noteColumns}
      onChangePagination={onChangePagination}
      header={"Báo cáo tiếp nhận các dịch vụ điện"}
      handleChange={handleChange}
      value={value}
      setType={setType}
    />
  );
}
