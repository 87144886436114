import { authPostData } from "@utils/request";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { alertMessage } from "@utils/function";
import { Endpoint } from "@utils/endpoint";

export const cancelHSService = (
  dataCancel,
  setLoading,
  setFetchDataTable,
  closeModal,
  t
) => {
  //call api hủy hồ sơ
  const data = {
    hoSoId: dataCancel.id,
  };
  authPostData({
    url: Endpoint.CANCEL_HO_SO + "?HoSoId=" + dataCancel.id,
    setLoading,
    method: METHOD_POST,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        // fetch data HoSo
        setFetchDataTable(true);
        closeModal();
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};
