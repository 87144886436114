import { Col, Row } from "antd";

export default function Index(props) {
  const { detail, soCot, maDiemDo } = props;
  return (
    <Row gutter={24}>
      <Col span={24} md={8}>
        <h6 className="fs-14px c-t-label">Mã Khách hàng</h6>
        <h5 className="fs-16px font-bold">{detail?.maKhachHang}</h5>
      </Col>
      <Col span={24} md={8}>
        <h6 className="fs-14px c-t-label">Tên Khách hàng</h6>
        <h5 className="fs-16px font-bold">{detail?.tenKhachHang}</h5>
      </Col>
      <Col span={24} md={8}>
        <h6 className="fs-14px c-t-label">Tên Người đại diện</h6>
        <h5 className="fs-16px font-bold">{detail?.tenNguoiDaiDien}</h5>
      </Col>
      <Col span={24} md={8}>
        <h6 className="fs-14px c-t-label">Địa chỉ dùng điện</h6>
        <h5 className="fs-16px font-bold">{detail?.diaChiDungDien}</h5>
      </Col>
      <Col span={24} md={8}>
        <h6 className="fs-14px c-t-label">Số điện thoại</h6>
        <h5 className="fs-16px font-bold">{detail?.soDienThoai}</h5>
      </Col>
      <Col span={24} md={8}>
        <h6 className="fs-14px c-t-label">Email</h6>
        <h5 className="fs-16px font-bold">{detail?.email}</h5>
      </Col>
      <Col span={24} md={8}>
        <h6 className="fs-14px c-t-label">Đội</h6>
        <h5 className="fs-16px font-bold">{detail?.tenDoi}</h5>
      </Col>
      <Col span={24} md={8}>
        <h6 className="fs-14px c-t-label">Trạm</h6>
        <h5 className="fs-16px font-bold">{detail?.tram}</h5>
      </Col>
      <Col span={24} md={8}>
        <h6 className="fs-14px c-t-label">Số GCS</h6>
        <h5 className="fs-16px font-bold">{detail?.maSoGCS}</h5>
      </Col>
      <Col span={24} md={8}>
        <h6 className="fs-14px c-t-label">Mã điểm đo</h6>
        <h5 className="fs-16px font-bold">{maDiemDo}</h5>
      </Col>
      <Col span={24} md={16}>
        <h6 className="fs-14px c-t-label">Số công tơ</h6>
        <h5 className="fs-16px font-bold">{detail?.soThietBi}</h5>
      </Col>
      {/* <Col span={24} md={8}>
        <h6 className="fs-14px c-t-label">Mã GC</h6>
        <h5 className="fs-16px font-bold">{detail?.maGC}</h5>
      </Col> */}
      <Col span={24} md={8}>
        <h6 className="fs-14px c-t-label">Số pha</h6>
        <h5 className="fs-16px font-bold">{detail?.soPha}</h5>
      </Col>
      <Col span={24} md={8}>
        <h6 className="fs-14px c-t-label">Số cột</h6>
        <h5 className="fs-16px font-bold" style={{ color: "blue" }}>
          {detail?.soCot ? detail?.soCot : soCot}
        </h5>
      </Col>
    </Row>
  );
}
