import { BASE_API_URL_TREO_THAO } from "@utils/constants";

export const APIPKDTreoThao = {
  DONG_BO_YEU_CAU_TU_CMIS: `${BASE_API_URL_TREO_THAO}/phong-kinh-doanh/dong-bo-tu-cmis`,
  DONG_BO_YEU_CAU_VE_CMIS: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/nghiem-thu`,
  TIEP_NHAN_YEU_CAU_TREO_THAO_CMIS: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/tiep-nhan`,
  UPDATE_TO_DOI_TREO_THAO: `${BASE_API_URL_TREO_THAO}/phan-cong/update-to-doi-yc`,
  UPDATE_LY_DO_TREO_THAO: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/update-ly-do`,
  GET_LY_DO_TREO_THAO: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/sync-ly-do-treo-thao`,
  REMOVE_YC_TREO_THAO: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao`,
  CANCEL_YC_TREO_THAO: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/huy`,
  PKD_XAC_NHAN_PHIEU_TREO_THAO: `${BASE_API_URL_TREO_THAO}/phong-kinh-doanh/xac-nhan-phieu`,
  PKD_TRA_LAI_PHIEU_TREO_THAO: `${BASE_API_URL_TREO_THAO}/phong-kinh-doanh/tra-phieu`,
};
