import { createSlice } from "@reduxjs/toolkit";
import { TOKEN_NAME, UNGDUNG_KINHDOANH } from "@utils/constants";
import { redirectSso } from "@utils/function";
export const namespace = "auth";

const INITAL_STATE = {
  isAuthenticated: false,
  isLoginSSO: false,
  moduleCode: null,
  moduleId: null,
  user: {},
  menuList: {
    data: [],
    loading: false,
  },
  logout: false,
  token: null,
};

const slice = createSlice({
  name: namespace,
  initialState: INITAL_STATE,
  reducers: {
    signInFinish: (state, action) => {
      localStorage.setItem(TOKEN_NAME, action.payload);
      return {
        ...state,
        isAuthenticated: true,
        logout: false,
        token: action.payload,
      };
    },
    signInSSO: (state) => {
      return {
        ...state,
        isLoginSSO: true,
      };
    },
    getUserProfile: (state) => {
      return {
        ...state,
      };
    },
    saveUser: (state, action) => {
      return {
        ...state,
        user: action.payload,
      };
    },
    signOut: (state, action) => {
      localStorage.clear();
      if (action?.payload?.isLoginSSO) {
        redirectSso({ type: "logout" });
      }
      return {
        ...INITAL_STATE,
        logout: true,
      };
    },
    handleSelectModule: (state, action) => {
      return {
        ...state,
        moduleCode: action.payload.moduleCode,
        moduleId: action.payload.moduleId,
        menuList: {
          ...state.menuList,
          loading: true,
        },
      };
    },
    getMenuList: (state) => {
      return {
        ...state,
        menuList: {
          ...state.menuList,
          loading: true,
        },
      };
    },
    saveMenuByModule: (state, action) => {
      return {
        ...state,
        menuList: {
          data: action.payload,
          loading: false,
        },
      };
    },
  },
});

export const reducer = slice.reducer;

export const {
  signOut,
  signInFinish,
  signInSSO,
  saveUser,
  getUserProfile,
  handleSelectModule,
  getMenuList,
  saveMenuByModule,
} = slice.actions;

export const authSelector = (state) => state[namespace];
