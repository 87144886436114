import { quanLyChiSoSelector } from "@state/giao-nhan-dien-nang/quan-ly-chi-so";
import { Col, Row, Spin, Alert } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getThangLamViec } from "./services";
import styled from "styled-components";
import { numberWithSpaces } from "@utils/function";
import { isEmpty } from "lodash";
export default function HeaderQuanLyChiSo() {
  const { san_luong } = useSelector(quanLyChiSoSelector);

  const { danhSachDiemDoCoChiSoNhapSai } = useSelector((state) => state.quanLyChiSo);
  const [loading, setLoading] = useState(false);

  const [thangLamViec, setThangLamViec] = useState({});
  useEffect(() => {
    getThangLamViec(setThangLamViec, setLoading);
  }, []);

  return (
    <Fragment>
      <Spin spinning={loading}>
        {!isEmpty(danhSachDiemDoCoChiSoNhapSai) && (
          <AlertStyled
            message="Có điểm đo nhập chỉ số không đúng. Chỉ số của BCS tổng khác với tổng
            chỉ số của các BCS bình thường, cao điểm, thấp điểm"
            type="error"
          />
        )}
        <Row gutter={24} justify="space-between" style={{ marginBottom: 10 }}>
          <Col span={4}>
            <div>
              Ngày ghi kỳ trước:{" "}
              <span style={{ fontWeight: 600 }}>
                {thangLamViec && Object.keys(thangLamViec).length > 0 ? `01/${thangLamViec.thang}/${thangLamViec.nam}` : ""}
              </span>
            </div>
          </Col>
          <Col span={4}>
            <div>
              Ngày ghi kỳ này:{" "}
              <span style={{ fontWeight: 600 }}>
                {thangLamViec && Object.keys(thangLamViec).length > 0 && thangLamViec.thang !== 12
                  ? `01/${thangLamViec.thang + 1}/${thangLamViec.nam}`
                  : `01/01/${thangLamViec.nam + 1}`}
              </span>
            </div>
          </Col>
          <Col span={5}>
            <div className="row">
              <span>Sản lượng (BT+CD+TD): </span>
              <span className="font-bold">{numberWithSpaces(san_luong.value)}</span>
            </div>
          </Col>
          <Col span={4}>
            <div className="row">
              <span>Tổng:</span>
              <span className="font-bold">{numberWithSpaces(san_luong.total)}</span>
            </div>
          </Col>
          <Col span={3}>
            <div className="row">
              <span>Chênh lệch:</span>
              <span className="font-bold text-red">{numberWithSpaces(san_luong.chenh_lech)}</span>
            </div>
          </Col>
        </Row>
      </Spin>
    </Fragment>
  );
}

const AlertStyled = styled(Alert)`
  margin-bottom: 10px;
  .ant-alert-message {
    color: red;
  }
`;
