import TableComponent from "@components/TableComponent";
import { Container } from "../css-styled";

export default function HoSoFlow(props) {
  const { data, totalCount, loading, columns } = props;

  return (
    <Container>
      <TableComponent
        header={"Hồ sơ FLOW"}
        renderExtraAction={false}
        dataSource={data}
        columns={columns}
        totalData={totalCount}
        loading={loading}
        pagination={false}
        scrollY={600}
      />
    </Container>
  );
}
