import { Form, notification } from "antd";
import { useState } from "react";
import ModalConfirm from "./modal";
import { handleSubmit } from "./services";

export default function Index(props) {
  const {
    detail,
    url,
    payload,
    closeModal,
    visible,
    setFetchList,
    content,
    title,
    isTL,
    loaiKy,
  } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleOk = (values) => {
    let params = payload;
    if (isTL) {
      params = {
        ...payload,
        lyDo: values.lyDoTraLai?.trim(),
        loaiKy,
      };

      if (
        values?.lyDoTraLai === undefined ||
        values?.lyDoTraLai.trim() === ""
      ) {
        notification.error(
          {
            message: "Thông báo!",
            description: "Không được phép để trống lý do trả lại.",
          },
          500
        );
      } else {
        handleSubmit(setLoading, url, params, setFetchList, closeModal);
      }
    } else {
      handleSubmit(setLoading, url, params, setFetchList, closeModal);
    }
  };

  return (
    <ModalConfirm
      title={title}
      visible={visible}
      handleOk={handleOk}
      content={content}
      closeModal={closeModal}
      loading={loading}
      detail={detail}
      isTL={isTL}
      form={form}
    />
  );
}
