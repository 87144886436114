const b1 = {
  b1: "b1",
};

const b11 = {
  b11: "b11",
  b11v: "b11v",
  b11db: "b11db",
  b11g: "b11g",
  // b11ld: "b11ld",
  b11x: "b11x",
};

const b12 = {
  b12: "b12",
  b12v: "b12v",
  b12db: "b12db",
  b12bb: "b12bb",
  b12h: "b12h"
};

const b13 = {
  b13: "b13",
  b13v: "b13v",
  b13xn: "b13xn",
  b13k: "b13k",
  b13tl: "b13tl",
};
const b2 = {
  b2: "b2",
};
const b21 = {
  b21: "b21",
  b21v: "b21v",
  b21t: "b21t",
  b21s: "b21s",
  b21ld: "b21ld",
  // b21db: "b21db",
  b21x: "b21x",
};
const b22 = {
  b22: "b22",
  b22v: "b22v",
  b22t: "b22t",
};
const b23 = {
  b23: "b23",
  b23x: "b23x",
  b23pc: "b23pc",
  b23s: "b23s",
  b23tt: "b23s",
  b23xyc: "b23xyc",
};
const b24 = {
  b24: "b24",
  b24v: "b24v",
  b24xn: "b24xn",
  b24bb: "b24bb",
};
const b25 = {
  b25: "b25",
  b25v: "b25v",
  b25t: "b25t",
  b25s: "b25s",
  b25x: "b25x",
  // b25db: "b25db",
  // b25xyc: "b25xyc",
};
const b26 = {
  b26: "b26",
  b26v: "b26v",
  b26t: "b26t",
  b26s: "b26s",
  b26x: "b26x",
};
const b27 = {
  b27: "b27",
  b27v: "b27v",
  b27k: "b27k",
  b27xn: "b27xn",
};

const b3 = {
  b3: "b3",
};
const b31 = {
  b31: "b31",
  b31v: "b31v",
  b31_lam_moi: "b31lm",
};
const b32 = {
  b32: "b32",
  b32v: "b32v",
  b32s: "b32s",
  b32tt: "b32tt",
  b32h: "b32h",
};
const b33 = {
  b33: "b33",
  b33x: "b33x",
  b33xn: "b33xn",
  b33t: "b33t",
  b33bb: 'b33bb'
};
const b34 = {
  b34: "b34",
  b34x: "b34x",
  b34k: "b34k",
  b34xn: "b34xn",
  b34bb: "b34bb",
  b34cn: "b34cn",
};
const b4 = {
  b4: "b4",
};
const b41 = {
  b41: "b41",
  b41v: "b41v",
};
const b42 = {
  b42: "b42",
  b42v: "b42v",
};
const b43 = {
  b43: "b43",
  b43v: "b43v",
};
const b5 = {
  b5: "b5",
};
const b51 = {
  b51: "b51",
  b51v: "b51v",
  b51xn: "b51xn",
  b51tl: "b51tl",
};

const b6 = {
  b6: "b6",
};
const b61 = {
  b61: "b61",
  b61v: "b61v",
  b61xn: "b61xn",
  b61k: "b61k",
  b61tl: "b61tl",
};

const b62 = {
  b62: "b62",
  b62v: "b61v",
  b62xn: "b62xn",
  b62k: "b62k",
  b62tl: "b62tl",
};

const b7 = {
  b7: "b7",
};
const b71 = {
  b71: "b71",
  b71v: "b71v",
  b71k: "b71k",
  b71xn: "b71xn",
};
const b72 = {
  b72: "b72",
  b72v: "b72v",
  b72k: "b72k",
  b72xn: "b72xn",
  b72tl: "b72tl",
};
const b8 = {
  b8: "b8",
};
const b81 = {
  b81: "b81",
  b81x: "b81x",
};
const b82 = {
  b82: "b82",
  b82x: "b82x",
};
const b9 = {
  b9: "b9",
};
const b91 = {
  b91: "b91",
  b91x: "b91x",
  b91bb: "b91bb",
};
const b92 = {
  b92: "b92",
  b92x: "b92x",
};

const b10 = {
  b10: "b10",
  b101v: "b101v",
  b101s: "b101s",
};
export const treo_thao = {
  ...b1,
  ...b11,
  ...b12,
  ...b13,

  ...b2,
  ...b21,
  ...b22,
  ...b23,
  ...b24,
  ...b25,
  ...b26,
  ...b27,

  ...b3,
  ...b31,
  ...b32,
  ...b33,
  ...b34,

  ...b4,
  ...b41,
  ...b42,
  ...b43,

  ...b5,
  ...b51,

  ...b6,
  ...b61,
  ...b62,

  ...b7,
  ...b71,
  ...b72,

  ...b8,
  ...b81,
  ...b82,

  ...b9,
  ...b91,
  ...b92,

  ...b10,
};
