import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { alertMessage } from "@utils/function";
import { authPostData } from "@utils/request";
import { Modal } from "antd";

export const dongBoYeuCauCMIS = (
  selectedRowKeys,
  setLoading,
  setFetchList,
  setSelectedRowKeys,
  donViId,
  setIsSearch,
  setVisible,
  setDataNoti,
  arrDDKError
) => {
  Modal.confirm({
    centered: true,
    title: "Xác nhận",
    content: (
      <div>
        {arrDDKError.length > 0 ? (
          <div>
            <p>
              Có điểm đo chưa nhập chỉ số định kỳ (DDK), bạn có muốn tiếp tục ?
            </p>
            <p>
              Danh sách mã điểm đo:
              <span style={{ fontWeight: "bold", marginLeft: "8px" }}>
                {arrDDKError.reduce((result, item) => {
                  return `${result}${item.maDiemDo} | `;
                }, "")}
              </span>
            </p>
          </div>
        ) : (
          <div>
            Dữ liệu treo tháo của các yêu cầu đã chọn sẽ được đồng bộ về CMIS,
            hoàn tất quá trình treo tháo !
          </div>
        )}
      </div>
    ),
    onOk() {
      callAPIDongBoCMIS(
        selectedRowKeys,
        setLoading,
        setFetchList,
        setSelectedRowKeys,
        donViId,
        setIsSearch,
        setVisible,
        setDataNoti
      );
    },
    onCancel() {},
    okText: "Đồng ý",
    okButtonProps: { type: "danger" },
    cancelText: "Hủy",
  });
};
const callAPIDongBoCMIS = (
  selectedRowKeys,
  setLoading,
  setFetchList,
  setSelectedRowKeys,
  donViId,
  setIsSearch,
  setVisible,
  setDataNoti
) => {
  authPostData({
    url: Endpoint.DONG_BO_YEU_CAU_VE_CMIS,
    payload: {
      ids: selectedRowKeys.toString(),
      donViId,
    },
    method: METHOD_POST,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchList(true);
        setSelectedRowKeys([]);
        setIsSearch(true);
        if (res.data.yeuCauErrors.length > 0) {
          const newData = [];
          res.data.yeuCauErrors.forEach((el, i) => {
            newData.push({
              ...el,
              stt: i + 1,
            });
          });
          setVisible(true);
          setDataNoti({
            ...res.data,
            yeuCauErrors: newData,
          });
        } else {
          setVisible(false);
          setDataNoti({});
        }

        if (
          res.data.soYeuCauSyncSuccess >= 0 &&
          res.data.soYeuCauSyncError === 0
        ) {
          alertMessage(
            "success",
            "Thông báo!",
            "Đồng bộ biên bản treo tháo về CMIS thành công !"
          );
        }
      }
    },
  });
};
