import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Table, Row, Col, Collapse, Tooltip } from "antd";
import { Fragment, useCallback, useState } from "react";
import PreviewImage from "./xem-anh";
const { Panel } = Collapse;

export default function Index(props) {
  const { detail } = props;
  return (
    <Collapse defaultActiveKey={["1", "2"]}>
      {detail?.congToThao?.maCongTo ? (
        <Panel header="Chỉ số tháo" key={"1"}>
          <Layout
            data={
              detail.congToThao && detail.congToThao.chiSoThaos
                ? detail.congToThao.chiSoThaos
                : []
            }
            maCongTo={
              detail.congToThao && detail.congToThao.maCongTo
                ? detail.congToThao.maCongTo
                : ""
            }
          />
        </Panel>
      ) : null}
      {detail?.congToTreo?.maCongTo ? (
        <Panel header="Chỉ số treo" key={"2"}>
          <Layout
            data={
              detail.congToTreo && detail.congToTreo.chiSoTreos
                ? detail.congToTreo.chiSoTreos
                : []
            }
            maCongTo={
              detail.congToTreo && detail.congToTreo.maCongTo
                ? detail.congToTreo.maCongTo
                : ""
            }
          />{" "}
        </Panel>
      ) : null}
    </Collapse>
  );
}

export function Layout(props) {
  const { data, maCongTo } = props;

  const columns = [
    {
      title: "BCS",
      key: "bcs",
      dataIndex: "bcs",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "14%",
    },
    {
      title: "CS cũ",
      key: "csCu",
      dataIndex: "csCu",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "14%",
    },
    {
      title: "CS mới",
      key: "csMoi",
      dataIndex: "csMoi",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "14%",
    },
    {
      title: "Sản lượng",
      key: "sanLuong",
      dataIndex: "sanLuong",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "13%",
    },
    {
      title: "Sản lượng trực tiếp",
      key: "sanLuongTrucTiep",
      dataIndex: "sanLuongTrucTiep",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "13%",
    },
    {
      title: "Ngày đầu kỳ",
      key: "ngayDauKy",
      dataIndex: "ngayDauKy",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "12%",
    },
    {
      title: "Ngày cuối kỳ",
      key: "ngayCuoiKy",
      dataIndex: "ngayCuoiKy",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "12%",
    },
    {
      title: "Ảnh",
      key: "filePath",
      align: "center",
      dataIndex: "filePath",
      render: (_, record) => (
        <Fragment>
          {record.filePath === null ||
          record.filePath === "" ||
          record.filePath === undefined ? (
            <Tooltip title="Không có ảnh">
              <EyeInvisibleOutlined style={{ color: "#595959" }} />
            </Tooltip>
          ) : (
            <Tooltip title="Xem ảnh">
              <EyeOutlined
                onClick={() => handleOpenPreviewImage(record)}
                style={{ color: "#0000FF" }}
              />
            </Tooltip>
          )}
        </Fragment>
      ),
      width: "8%",
    },
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [srcImg, setSrcImg] = useState("");
  const handleOpenPreviewImage = useCallback(
    (record) => {
      setIsOpen(!isOpen);
      setSrcImg(record?.filePath);
    },
    [isOpen]
  );

  const showPreviewImage = useCallback(() => {
    return (
      isOpen && (
        <PreviewImage srcImg={srcImg} isOpen={isOpen} setIsOpen={setIsOpen} />
      )
    );
  }, [isOpen, srcImg]);

  const renderTable = useCallback(() => {
    let newData = [...data];
    const arr = [];
    if (newData.length > 0) {
      newData.forEach((item, index) => {
        return arr.push({
          ...item,
          stt: index + 1,
        });
      });
      newData = arr;
    }
    return (
      <Table
        dataSource={newData}
        columns={columns}
        pagination={false}
        scroll={{ y: 300 }}
        rowKey={(obj) => obj.stt}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Fragment>
      <Row gutter={24}>
        <Col span={24} md={2}>
          <h6 className="fs-12px c-t-label">Mã công tơ</h6>
        </Col>
        <Col span={24} md={22}>
          <h5 className="fs-14px font-bold">{maCongTo}</h5>
        </Col>
        <Col span={24} md={24}>
          {renderTable()}
        </Col>
      </Row>
      {showPreviewImage()}
    </Fragment>
  );
}
