import { Collapse, Col, Table } from "antd";
import { Fragment, useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { TableStyled } from "../css-styled";
const { Panel } = Collapse;
export default function Index(props) {
  const { detail } = props;
  const [danhSachGiayTo, setDanhSachGiayTo] = useState([]);
  const [danhSachGiayToMoi, setDanhSachGiayToMoi] = useState([]);
  // const [selectedRowKey, setSelectedRowKey] = useState(null);

  const onSelectChangeHT = (selectedRowKey, selectedRows) => {
    setDanhSachGiayTo(selectedRows[0].danhSachGiayTo)
  };
  const rowSelectionHT = {
    // selectedRowKeys: selectedRowKey,
    onChange: onSelectChangeHT,
    type: "radio"
  };

  const onSelectChangeM = (selectedRowKey, selectedRows) => {
    setDanhSachGiayToMoi(selectedRows[0].danhSachGiayTo)
  };
  const rowSelectionM = {
    // selectedRowKeys: selectedRowKey,
    onChange: onSelectChangeM,
    type: "radio"
  };
  const columnTT = [
    {
      title: "Tên hộ",
      dataIndex: "tenHo",
      key: "tenHo",
      render: (text) => <span>{text}</span>,
      width: "30%",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => <span>{text}</span>,
      width: "70%",
    },
  ];
  const columnHS = [
    {
      title: "Giấy tờ",
      dataIndex: "tenLoaiHoSo",
      key: "tenLoaiHoSo",
      render: (text) => <span>{text}</span>,
      width: "20%",
    },
    {
      title: "Số giấy tờ",
      dataIndex: "soGiayTo",
      key: "soGiayTo",
      render: (text) => <span>{text}</span>,
      width: "10%",
    },
    {
      title: "Ngày hiệu lực",
      dataIndex: "ngayHieuLuc",
      key: "ngayHieuLuc",
      render: (text) => <span>{text}</span>,
      width: "15%",
    },
    {
      title: "Ngày hết hiệu lực",
      dataIndex: "ngayHetHieuLuc",
      key: "ngayHetHieuLuc",
      render: (text) => <span>{text}</span>,
      width: "15%",
    },
    {
      title: "Nơi cấp",
      dataIndex: "noiCap",
      key: "noiCap",
      render: (text) => <span>{text}</span>,
      width: "40%",
    },
  ]
  return (
    <Fragment>
      <Col span={24}>
        {/* <Row gutter={24}> */}
        <Collapse
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined
              style={{ fontSize: 18 }}
              rotate={isActive ? 90 : 0}
            />
          )}
        >
          <Panel
            header={
              <h3 className="font-bold" style={{ position: "relative" }}>
                Thông tin hiên tại
              </h3>
            }
            collapsible={"header"}
            key="1"
          >
            <TableStyled>
              <Table
                dataSource={detail?.danhSachHoDungChung}
                columns={columnTT}
                pagination={false}
                scroll={{ y: 700 }}
                bordered={true}
                rowSelection={rowSelectionHT}
                rowKey="rowNumber"
                // onRow={detailHoHT}
              />
            </TableStyled>
            <br/>
            <TableStyled>
              <Table
                dataSource={danhSachGiayTo}
                columns={columnHS}
                pagination={false}
                scroll={{ y: 700 }}
                // key="index"
                bordered={true}
              />
            </TableStyled>
          </Panel>
        </Collapse>
        <br />
        <Collapse
          defaultActiveKey={["2"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined
              style={{ fontSize: 18 }}
              rotate={isActive ? 90 : 0}
            />
          )}
        >
          <Panel
            header={
              <h3 className="font-bold" style={{ position: "relative" }}>
                Thông tin sau thay đổi định mức
              </h3>
            }
            collapsible={"header"}
            key="2"
          >
            <TableStyled>
              <Table
                dataSource={detail?.danhSachHoDungChungNew}
                columns={columnTT}
                pagination={false}
                scroll={{ y: 700 }}
                bordered={true}
                rowSelection={rowSelectionM}
                rowKey="rowNumber"
                // rowSelection={rowSelection}
                // key="index"
                // onRow={detailHoMoi}
              />
            </TableStyled>
            <br/>
            <TableStyled>
              <Table
                dataSource={danhSachGiayToMoi}
                columns={columnHS}
                pagination={false}
                scroll={{ y: 700 }}
                // key="index"
                bordered={true}
              />
            </TableStyled>
          </Panel>
        </Collapse>
        {/* </Row> */}
      </Col>
    </Fragment>
  );
}
