import { BASE_API_URL_KT_THIETBI } from "@utils/constants";

export const APIPhanCong = {
  GET_PHANCONG: `${BASE_API_URL_KT_THIETBI}/phancong/get-message`,
  LIST_NVKT: `${BASE_API_URL_KT_THIETBI}/phancong/list-nvktra`,
  PKD_PHANCONG: `${BASE_API_URL_KT_THIETBI}/phancong/phong-kinh-doanh`,
  DOITRUONG_PHANCONG: `${BASE_API_URL_KT_THIETBI}/phancong/doi-truong`,
  HUY_PHANCONG: `${BASE_API_URL_KT_THIETBI}/phancong/huy-phan-cong`,
  XOA_YEUCAU: `${BASE_API_URL_KT_THIETBI}/phancong/xoa-yeu-cau`,
  LIST_NV_KTCT: `${BASE_API_URL_KT_THIETBI}/phancong/list-nvktct-filldata`,
  KTCT_LAM_MOI_YC: `${BASE_API_URL_KT_THIETBI}/phancong/lam-moi-yeu-cau`,
};
