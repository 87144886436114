import { Collapse } from "antd";
import ThongTin from "./thong-tin";
const { Panel } = Collapse;

export default function Index(props) {
  const { detail, isThaoCongTo, isLapTrinhLaiCongTo } = props;
  return (
    <Collapse defaultActiveKey={["1", "2"]}>
      {detail.congToThao && detail.congToThao.maCongTo ? (
        <Panel header="Công tơ hiện tại" key="1">
          <ThongTin
            detail={detail.congToThao}
            isThaoCongTo={isThaoCongTo === null ? false : isThaoCongTo}
          />
        </Panel>
      ) : null}
      {detail.congToTreo && detail.congToTreo.maCongTo ? (
        <Panel header="Công tơ treo" key="2">
          <ThongTin
            detail={detail.congToTreo}
            isLapTrinhLaiCongTo={
              isLapTrinhLaiCongTo === null ? false : isLapTrinhLaiCongTo
            }
          />
        </Panel>
      ) : null}
    </Collapse>
  );
}
