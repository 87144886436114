import { Col, Table } from "antd";
import { Fragment } from "react";
import { TableStyled } from "../css-styled";
// const { Panel } = Collapse;
export default function Index(props) {
  const { detail } = props;

  const column = [
    {
      title: "STT",
      dataIndex: "rowNumber",
      key: "rowNumber",
      render: (text) =>
        <div className="table-text-right">
          <span>{text + 1}</span>
        </div>,
      width: "5%",
    },
    {
      title: "Ngày giờ",
      dataIndex: "ngayThucHien",
      key: "ngayThucHien",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Người dùng",
      dataIndex: "nguoiDung",
      key: "nguoiDung",
      render: (text) => <span>{text}</span>,
      width: "15%",
    },
    {
      title: "Bộ phận",
      dataIndex: "boPhan",
      key: "boPhan",
      render: (text) => <span>{text}</span>,
      width: "15%",
    },
    {
      title: "Nội dung",
      dataIndex: "noiDung",
      key: "noiDung",
      render: (text) => <span>{text}</span>,
      width: "40%",
    },
  ]
  return (
    <Fragment>
      <Col span={24}>
        <TableStyled>
          <Table
            dataSource={detail?.nhatKy}
            columns={column}
            bordered={true}
            rowKey="rowNumber"
            pagination={{
              total: detail?.nhatKy.length,
              showTotal: (total) => `Tổng ${total} bản ghi`,
              defaultpageSize: 20,
              defaultCurrent: 1,
              showSizeChanger: true,
            }}
          />
        </TableStyled>
      </Col>
    </Fragment>
  );
}
