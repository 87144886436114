import { authSelector } from "@state/auth";
import { Form } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { handleMultiBoLoc } from "./services";
import FormBoLoc from "./form-bo-loc";

export default function ListFilter(props) {
  const { handleSearch, clearFilter, filterConditions } = props;
  const { user } = useSelector(authSelector);
  const [, setLoading] = useState(false);
  const [taiKhoan, setTaiKhoan] = useState([]);
  const [donVi, setDonVi] = useState([]);

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  useEffect(() => {
    handleMultiBoLoc(setLoading, setDonVi, setTaiKhoan);
  }, []);

  useEffect(() => {
    const users = taiKhoan.find(
      (item) => item.id === filterConditions.nguoiThaoTac
    );
    if (users === undefined) {
      form.setFieldsValue({
        ...filterConditions,
        nguoiThaoTac: undefined,
      });
    } else {
      form.setFieldsValue({
        ...filterConditions,
        nguoiThaoTac: users.id,
      });
    }
  }, [filterConditions, form, taiKhoan, user.id]);

  return (
    <FormBoLoc
      form={form}
      handleSearch={handleSearch}
      user={user}
      donVi={donVi}
      filterConditions={filterConditions}
      clear={clear}
      taiKhoan={taiKhoan}
    />
  );
}
