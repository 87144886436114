import { getThangLamViec } from "@modules/giao-nhan-dien-nang/tong-hop-so-lieu/tinh-san-luong/services";
import React, { useEffect, useState } from "react";

export default function Thanglamviec() {
  const [loading, setLoading] = useState(false);
  const [thangLamViec, setThangLamViec] = useState("");

  useEffect(() => {
    getThangLamViec(setThangLamViec, setLoading);
  }, []);

  const [YYYY, MM, DD] = thangLamViec.split("-");
  return (
    <div>
      {" "}
      <p style={{ padding: "6px", fontWeight: "700", fontSize: "16px" }}>
        Tháng làm việc: {MM}/{YYYY}
      </p>{" "}
    </div>
  );
}
