import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authPutData, startDelete } from "@utils/request";
import { getDanhSachDiemDo } from "../../services";

export const handlerXoaDiemDo = (
  detail,
  filterConditions,
  setLoading,
  handleCloseModal,
  setDataDanhSach,
  dispatch,
  setLoadingDanhSach
) => {
  startDelete({
    // url: Endpoint.XOA_DIEM_DO + "?Id=" + dataRemove?.id,
    url: `${Endpoint.XOA_DIEM_DO}?Id=${detail?.id}`,
    setLoading: () => {},
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        getDanhSachDiemDo(
          filterConditions,
          setLoadingDanhSach,
          setDataDanhSach
        );
        handleCloseModal();
      }
    },
  });
};

export const handlerNgungDiemDo = (
  detail,
  filterConditions,
  setLoading,
  handleCloseModal,
  setDataDanhSach,
  dispatch,
  closeModal,
  setLoadingDanhSach,
  getDanhSachDiemDo
) => {
  authPutData({
    url: `${Endpoint.NGUNG_DIEM_DO}?Id=${detail?.id}`,
    method: METHOD_PUT,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        getDanhSachDiemDo(
          filterConditions,
          setLoadingDanhSach,
          setDataDanhSach
        );
        dispatch(closeModal());
      }
    },
  });
};
