import LoadingComponent from "@components/Loading";
import { Button, Modal } from "antd";

export default function ModalKyDuyet(props) {
  const {
    visible,
    handleXacNhan,
    handleKyHSM,
    content,
    closeModal,
    loading,
    title,
  } = props;
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={title}
        visible={visible}
        onCancel={closeModal}
        footer={[
          <div style={{ textAlign: "center" }} key="footer">
            <Button key="back" onClick={closeModal} className=" mr-24px">
              Đóng lại
            </Button>
            <Button
              key="ky-hsm"
              className="btn-bg-yellow mr-24px"
              loading={loading}
              onClick={handleKyHSM}
            >
              Ký HSM
            </Button>
            <Button
              key="xac-nhan"
              type="primary"
              loading={loading}
              onClick={handleXacNhan}
            >
              Xác nhận
            </Button>
          </div>,
        ]}
      >
        <div>{content}</div>
      </Modal>
    </LoadingComponent>
  );
}
