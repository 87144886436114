import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authPostData } from "@utils/request";

export const handleUploadBBAGPDF = (
  setLoading,
  payload,
  setFetchDataTable,
  closeModal,
) => {
  authPostData({
    url: Endpoint.AG_UPLOAD_BB_GIAY,
    method: METHOD_POST,
    setLoading,
    payload,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchDataTable(true);
        closeModal();
      }
    },
  });
};
