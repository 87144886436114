import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import ThongTinChung from "./thong-tin-chung";
import ChiPhiTable from "./chi-phi";
import { TYPE_CHI_PHI } from "@utils/constants";
import { ContainerPanel, TablePanel } from "../css-styled";

const { Panel } = Collapse;

export default function LayoutPanelsBaoCao(props) {
  const {
    loading,
    form,
    onFinishThongTinChung,
    typeDetail,
    genExtra,
    dataNgam,
    dataDDK,
    dataKhac,
    // detailBaoCao,
    detailThongTin,
    columnsDetail,
    renderColumns,
  } = props;

  return (
    <ContainerPanel>
      <Collapse
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            style={{ fontSize: 18 }}
            rotate={isActive ? 90 : 0}
          />
        )}
      >
        <Panel header={"Thông tin chung"} collapsible={"header"} key="1">
          <ThongTinChung
            form={form}
            onFinish={onFinishThongTinChung}
            typeDetail={typeDetail}
            loading={loading}
            detailThongTin={detailThongTin}
          />
        </Panel>

        <Panel header={"Danh sách chi phí"} collapsible={"header"} key="2">
          <TablePanel>
            <ChiPhiTable
              columns={
                typeDetail
                  ? columnsDetail
                  : renderColumns(TYPE_CHI_PHI.CAP_NGAM)
              }
              genExtra={genExtra}
              type={TYPE_CHI_PHI.CAP_NGAM}
              dataSource={dataNgam}
              loading={loading}
              typeDetail={typeDetail}
              header="Bảng chi phí cáp ngầm"
            />
          </TablePanel>
          <TablePanel>
            <ChiPhiTable
              columns={
                typeDetail ? columnsDetail : renderColumns(TYPE_CHI_PHI.DDK)
              }
              genExtra={genExtra}
              type={TYPE_CHI_PHI.DDK}
              dataSource={dataDDK}
              loading={loading}
              typeDetail={typeDetail}
              header="Bảng chi phí DDK"
            />
          </TablePanel>
          <TablePanel>
            <ChiPhiTable
              columns={
                typeDetail ? columnsDetail : renderColumns(TYPE_CHI_PHI.KHAC)
              }
              genExtra={genExtra}
              type={TYPE_CHI_PHI.KHAC}
              dataSource={dataKhac}
              loading={loading}
              typeDetail={typeDetail}
              header="Bảng chi phí khác"
            />
          </TablePanel>
        </Panel>
        {/* <Panel header={"Chi phí DDK"} collapsible={"header"} key="3">
          <TablePanel>
            <ChiPhiTable
              columns={
                typeDetail ? columnsDetail : renderColumns(TYPE_CHI_PHI.DDK)
              }
              genExtra={genExtra}
              type={TYPE_CHI_PHI.DDK}
              dataSource={dataDDK}
              loading={loading}
              typeDetail={typeDetail}
              header="Bảng chi phí DDK"
            />
          </TablePanel>
        </Panel>
        <Panel header={"Chi phí khác"} collapsible={"header"} key="4">
          <TablePanel>
            <ChiPhiTable
              columns={
                typeDetail ? columnsDetail : renderColumns(TYPE_CHI_PHI.KHAC)
              }
              genExtra={genExtra}
              type={TYPE_CHI_PHI.KHAC}
              dataSource={dataKhac}
              loading={loading}
              typeDetail={typeDetail}
              header="Bảng chi phí khác"
            />
          </TablePanel>
        </Panel> */}
      </Collapse>
    </ContainerPanel>
  );
}
