import { Col, Form, Row, Select, Button, Table } from "antd";
import { useEffect, useState } from "react";
import FormComponent from "@components/Form";
import { Fragment } from "react";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_SOPHA_KTCT,
  FORMAT_DATE,
  FORMAT_ENGLISH,
} from "@utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import moment from "moment";
import DatePickerComponent from "../../datepicker/DatePicker";
import { ContainerDK } from "../css-styled";
import { closeModal } from "@state/system-config/reducer";
import { handleDongBoCmis, handleDKDongBoVeWeb } from "../services";
import LoadingComponent from "@components/Loading";
import { handlePagination } from "@utils/function";
export default function Index({ typeDongBo }) {
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });
  const [call, setCall] = useState(false);
  const [dataSync, setDataSync] = useState({});
  //Hiển thị checkbox ở đầu mỗi bảng

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.isExistWebOrDuplicateKey === true,
    }),
  };
  // column table định kỳ

  const columnsTableKTDK = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 170,
      sorter: true,
    },
    {
      title: "Trạm",
      dataIndex: "tenTram",
      key: "tenTram",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
      sorter: true,
    },
    {
      title: "Tên khách hàng",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
      sorter: true,
    },
    {
      title: "Mã sổ GCS",
      dataIndex: "maSoGhiChiSo",
      key: "maSoGhiChiSo",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 170,
      sorter: true,
    },
    {
      title: "Mã công tơ",
      dataIndex: "maCongTo",
      key: "maCongTo",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 180,
      sorter: true,
    },
    {
      title: "Số pha",
      dataIndex: "soPha",
      key: "soPha",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 100,
      sorter: true,
    },
    {
      title: "Ngày treo tháo",
      dataIndex: "ngayKiemTra",
      key: "ngayKiemTra",
      render: (text) => (
        <div className="table-text-center">
          {text ? moment(text).format(FORMAT_DATE) : ""}
        </div>
      ),
      width: 150,
      sorter: true,
    },
    {
      title: "Đội",
      dataIndex: "tenDoi",
      key: "tenDoi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
      sorter: true,
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChiSuDung",
      key: "diaChiSuDung",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "20%",
      sorter: true,
    },
  ];
  // hàm phân trang

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setDataSync({});
  };
  // tìm kiếm dữ liệu

  const onFinish = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
    setCall(true);
    setDataSync({});
  };
  // call api đồng bộ từ cmis khi bấm tìm kiếm

  useEffect(() => {
    if (call)
      handleDongBoCmis(
        setLoading,
        setDataSource,
        typeDongBo,
        form,
        setTotalCount,
        filterConditions
      );
    // eslint-disable-next-line
  }, [filterConditions, call]);
  // call api đồng bộ về web

  const handleDBWEB = () => {
    const arr = selectedRowKeys
      .map((id) => {
        const dataFind = dataSource.find((x) => x.soThuTuTraVeTuCmis === id);
        if (
          dataFind &&
          Object.keys(dataFind) &&
          Object.keys(dataFind).length > 0
        ) {
          return dataFind;
        } else {
          return undefined;
        }
      })
      .filter(Boolean);
    handleDKDongBoVeWeb(arr, setLoading, setDataSync, setSelectedRowKeys);
  };

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={onFinish}
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
      }}
      autoComplete="off"
    >
      <LoadingComponent loading={loading}>
        <ContainerDK>
          <Row gutter={24}>
            <Fragment>
              <Col span={24} md={8}>
                <Form.Item name="donViId" label="Đơn vị">
                  {!user.isTongCongTy ? (
                    <Select defaultValue={user.unitId} disabled>
                      <Select.Option value={user.unitId}>
                        {user.unitName}
                      </Select.Option>
                    </Select>
                  ) : (
                    <Selection
                      url={Endpoint.LIST_DONVI_KTCT}
                      form={form}
                      formKey="donViId"
                      notClear={true}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={24} md={8}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => {
                    if (prevValues.donViId !== currentValues.donViId) {
                      form.setFieldsValue({
                        doiId: undefined,
                      });
                    }
                    return prevValues.donViId !== currentValues.donViId;
                  }}
                >
                  {({ getFieldValue }) => {
                    if (getFieldValue("donViId")) {
                      return (
                        <Form.Item name="doiId" label="Đội">
                          <Selection
                            url={`${
                              Endpoint.LIST_DOI_KTCT
                            }?donviId=${getFieldValue("donViId")}`}
                            formKey="doiId"
                            form={form}
                          />
                        </Form.Item>
                      );
                    }
                    return (
                      <Form.Item name="doiId" label="Đội">
                        <Selection
                          url={`${Endpoint.LIST_DOI_KTCT}?donviId=${user.unitId}`}
                          formKey="doiId"
                          form={form}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={24} md={8}>
                <Form.Item name="soPha" label="Số pha">
                  <Select
                    placeholder="Tất cả"
                    allowClear
                    defaultValue={ENUM_SOPHA_KTCT.TATCA}
                  >
                    <Select.Option value={ENUM_SOPHA_KTCT.TATCA}>
                      Tất cả
                    </Select.Option>
                    <Select.Option value={ENUM_SOPHA_KTCT.MOTPHA}>
                      1 pha
                    </Select.Option>
                    <Select.Option value={ENUM_SOPHA_KTCT.BAPHA_TRUCTIEP}>
                      3 pha trực tiếp
                    </Select.Option>
                    <Select.Option value={ENUM_SOPHA_KTCT.BAPHA_GIANTIEP}>
                      3 pha gián tiếp
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} md={4}>
                <Form.Item name="tuNgay" label="Ngày treo tháo">
                  <DatePickerComponent
                    defaultValue={moment(moment(), FORMAT_DATE)}
                    format={FORMAT_ENGLISH}
                    form={form}
                    formKey={"tuNgay"}
                    notClear={true}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={4}>
                <Form.Item name="denNgay" label="&nbsp;">
                  <DatePickerComponent
                    defaultValue={moment(moment(), FORMAT_DATE)}
                    format={FORMAT_ENGLISH}
                    form={form}
                    formKey={"denNgay"}
                    notClear={true}
                    disabledDate={(current) => {
                      return (
                        moment(form.getFieldValue("tuNgay")).add(-1, "days") >=
                          current ||
                        moment(form.getFieldValue("tuNgay")).add(1, "year") <=
                          current
                      );
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={8}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => {
                    if (prevValues.donViId !== currentValues.donViId) {
                      form.setFieldsValue({
                        tramId: undefined,
                      });
                    }
                    return prevValues.donViId !== currentValues.donViId;
                  }}
                >
                  {({ getFieldValue }) => {
                    if (getFieldValue("donViId")) {
                      return (
                        <Form.Item name="tramId" label="Trạm">
                          <Selection
                            url={`${
                              Endpoint.LIST_TRAM_CT
                            }?donviId=${getFieldValue("donViId")}`}
                            formKey="tramId"
                            form={form}
                          />
                        </Form.Item>
                      );
                    }
                    return (
                      <Form.Item name="tramId" label="Trạm">
                        <Selection
                          mode="multiple"
                          url={`${Endpoint.LIST_TRAM_CT}?donviId=${user.unitId}`}
                          formKey="tramId"
                          form={form}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col
                span={24}
                md={8}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  marginTop: 5,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  form="filter-form"
                  className="mr-24px"
                >
                  Lấy dữ liệu từ CMIS
                </Button>
              </Col>
              <Col
                span={24}
                md={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  marginBottom: 20,
                }}
              >
                <Button
                  type="primary"
                  className="mr-24px"
                  disabled={!selectedRowKeys.length}
                  onClick={handleDBWEB}
                >
                  Đồng bộ về Web
                </Button>
              </Col>
              <Col span={24} md={20}>
                {dataSync && Object.keys(dataSync).length > 0 ? (
                  <div style={{ marginBottom: 20 }}>
                    <div>
                      Số yêu cầu tiếp nhận:{"  "}
                      <span style={{ fontWeight: 600 }}>
                        {dataSync.soYeuCauTiepNhan}
                      </span>
                    </div>
                    <div>
                      Số yêu cầu đồng bộ thành công:{"  "}
                      <span
                        style={{ fontWeight: 600, color: "rgb(82, 196, 26)" }}
                      >
                        {dataSync.thanhCong}
                      </span>
                    </div>
                    <div>
                      Số yêu cầu đồng bộ thất bại:{"  "}
                      <span
                        style={{ fontWeight: 600, color: "rgb(255, 142, 143)" }}
                      >
                        {dataSync.thatBai}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Col>
              <Col span={24} md={24}>
                <Table
                  columns={columnsTableKTDK}
                  dataSource={dataSource}
                  scroll={{ x: 2200, y: 520 }}
                  rowSelection={rowSelection}
                  rowKey={"soThuTuTraVeTuCmis"}
                  pagination={{
                    total: totalCount,
                    showTotal: (total) => `Tổng ${total} bản ghi`,
                    defaultpageSize: DEFAULT_PAGESIZE,
                    defaultCurrent: DEFAULT_PAGEINDEX,
                    current: parseInt(filterConditions.pageIndex),
                    pageSize: parseInt(filterConditions.pageSize),
                    showSizeChanger: true,
                    pageSizeOptions: [10, 20, 50, 100, 200, 500],
                  }}
                  onChange={onChangePagination}
                ></Table>
              </Col>
            </Fragment>
          </Row>
          <div className="ant-modal-footer text-center">
            <Button type="default" onClick={() => dispatch(closeModal())}>
              Đóng lại
            </Button>
          </div>
        </ContainerDK>
      </LoadingComponent>
    </FormComponent>
  );
}
