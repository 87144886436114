import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import TableComponent from "@components/TableComponent";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { formatDateWithTimezoneGB, handlePagination } from "@utils/function";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { exportData, handleDownload } from "./service";

export default function DSKHHuyDichVuSMS() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });

  //pagination table
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const defaultValue = {
    label: "Danh sách khách hàng hủy dịch vụ SMS",
  };

  const onFinish = (values) => {
    const dataRequest = {
      ...values,
      reportType: Endpoint.DSKHHDVSMS_DS_KHACH_HANG_HUY_DICH_VU_SMS,
    };
    exportData(dataRequest, setLoading, setData);
  };

  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="reportType"
                    label="Tên báo cáo"
                    labelAlign="left"
                  >
                    <Select
                      formKey="reportType"
                      form={form}
                      defaultValue={defaultValue}
                    >
                      <Option>Danh sách khách hàng hủy dịch vụ SMS</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    name="TuNgay"
                    label="Ngày bắt đầu"
                    labelAlign="left"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <DatePickerComponent
                      picker="Day"
                      formKey="TuNgay"
                      defaultValue={moment().startOf("month")}
                      form={form}
                      format={"YYYY-MM-DD"}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="DenNgay"
                    label="Ngày kết thức"
                    labelAlign="right"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <DatePickerComponent
                      picker="Day"
                      formKey="DenNgay"
                      form={form}
                      format={"YYYY-MM-DD"}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end">
                <Button
                  type="primary"
                  onClick={() => {
                    handleDownload(
                      {
                        ...form.getFieldValue(),
                        reportType:
                          Endpoint.DSKHHDVSMS_DS_KHACH_HANG_HUY_DICH_VU_SMS,
                      },
                      setLoading
                    );
                  }}
                >
                  Xuất Excel
                </Button>
              </Row>
            </FormComponent>
            <Divider className="section-text-line" orientation="left"></Divider>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
