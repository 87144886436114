import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { useLocation, useSearchParams } from "react-router-dom";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import { tien_ich_chung } from "@permissions/m_tienichchung";
import CheckPermission from "@components/CheckPermission";
import GhiChiSoModal from "./modal-so-ghi-chi-so";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import {
  // deleteSGCSService,
  // getListDonViService,
  getListGCSService,
  getDongBoDataMaster
} from "./services";
import TableSGCS from "./table";
import { CheckCircleFilled } from "@ant-design/icons";

export default function Index() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [detailGhiChiSo, setDetailGhiChiSo] = useState({});
  const [loading, setLoading] = useState(true);
  const [dataSoGhiChiSo, setDataSoGhiChiSo] = useState([]);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [dataDonVi, setDataDonVi] = useState([]);
  const { user } = useSelector(authSelector);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu

  const getListGhiChiSo = useCallback(() => {
    getListGCSService(
      filterConditions,
      setLoading,
      setDataSoGhiChiSo,
      setTotalCount
    );
  }, [filterConditions]);

  useEffect(() => {
    getListGhiChiSo();
    // eslint-disable-next-line
  }, [getListGhiChiSo]);


  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const genExtra = () => (
    <div>
        <Button
          size="small"
          type="primary"
          className="btn-bg-yellow"
          onClick={() => getDongBoDataMaster(getListGhiChiSo, setLoading)}
        >
          Đồng bộ cmis
        </Button>
    </div>
  );

  //khai bái columns

  const columns = [
    {
      title: "Mã đơn vị quản lý",
      dataIndex: "maDonViQL",
      key: "maDonViQL",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
      sorter: true,
    },
    {
      title: "Mã sổ",
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Tên sổ",
      dataIndex: "tenSoGCS",
      key: "tenSoGCS",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "20%",
      sorter: true,
    },
    {
      title: "Loại số GCS",
      dataIndex: "loaiSoGCS",
      key: "loaiSoGCS",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Hình thức GCS",
      dataIndex: "hinhThucGCS",
      key: "hinhThucGCS",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Ngày ghi",
      dataIndex: "ngayGhi",
      key: "ngayGhi",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Sổ ký",
      dataIndex: "soKy",
      key: "soKy",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
      sorter: true,
    },
    {
      title: "Tình trạng",
      dataIndex: "tinhTrang",
      key: "tinhTrang",
      render: (actived) => (
        <div className="table-text-center">
          {actived ? (
            <CheckCircleFilled style={{ fontSize: "20px", color: "#03A4FF" }} />
          ) : null}
        </div>
      ),
      width: "10%",
      sorter: true,
    },

    {
      title: t("tac_vu"),
      key: "action",
      align: "center",
      // width: "10%",
      render: (row) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={tien_ich_chung.m117s}>
            <div className="action-icon" onClick={() => handleOpenModal(row)}>
              <PencilIcon />
            </div>
          </CheckPermission>
        </div>
      ),
    },
  ];

  //tìm kiếm dữ liệu

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  // //xóa dữ liệu

  // const handleDeleteData = useCallback(
  //   (ids) => {
  //     deleteSGCSService(ids, setLoading, getListGhiChiSo, t);
  //   },
  //   [getListGhiChiSo, t]
  // );

  // //hiển thị popup, set dữ liệu chi tiết

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDetailGhiChiSo(row);
      else setDetailGhiChiSo({});
      setVisible(!visible);
    },
    [visible]
  );
  // //đóng popup

  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
  };
  // //render popup

  const showModal = useMemo(
    () =>
      visible && (
        <GhiChiSoModal
          detailGhiChiSo={detailGhiChiSo}
          visible={visible}
          closeModal={handleCloseModal}
          getListGhiChiSo={getListGhiChiSo}
          dataDonVi={dataDonVi}
          user={user.unitName}
        />
      ),
    // eslint-disable-next-line
    [visible]
  );
  return (
    <Fragment>
      <TableSGCS
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        genExtra={genExtra}
        dataSoGhiChiSo={dataSoGhiChiSo}
        columns={columns}
        totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        t={t}
      />
      {showModal}
    </Fragment>
  );
}
