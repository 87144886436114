import ConfirmModal from "../modal-confirm";
export default function Index(props) {
  const {
    loading,
    visible,
    closeModal,
    onFinish,
    content,
    title,
    isKySo,
    urlKySo,
    urlGetHash,
    setFetchList,
    permissionCode,
    payloadKySo,
  } = props;

  return (
    <ConfirmModal
      loading={loading}
      visible={visible}
      closeModal={closeModal}
      onFinish={onFinish}
      title={title}
      content={content}
      textButton="Xác nhận"
      isKySo={isKySo}
      urlKySo={urlKySo}
      urlGetHash={urlGetHash}
      setFetchList={setFetchList}
      permissionCode={permissionCode}
      payloadKySo={payloadKySo}
    />
  );
}
