import { closeModal } from "@state/system-config/reducer";
import { formatDateWithTimezoneGB } from "@utils/function";
import { Button, Col, Row, Table } from "antd";
import { Fragment, useCallback } from "react";
import { useDispatch } from "react-redux";
import EllipsisTooltip from "@components/EllipsisTooltip";
import styled from "styled-components";
import { TableStyled } from "@modules/giao-nhan-dien-nang/quan-ly-chi-so/css-styled";

const TableThayDoiThongTInDiemDo = (props) => {
  const { loading, dataTable } = props;
  const dispatch = useDispatch();

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "",
      width: 50,
      align: "center",
      render: (a, b, idx) => {
        return idx + 1;
      },
    },
    {
      title: "Ngày hiệu lực",
      dataIndex: "ngayHieuLuc",
      key: "ngayHieuLuc",
      width: 130,
      align: "center",
      render: (text) => {
        return (
          <div className="table-text-center">
            {text ? formatDateWithTimezoneGB(text) : null}
          </div>
        );
      },
    },
    {
      title: "Số biên bản",
      dataIndex: "soBienBan",
      key: "soBienBan",
      width: 120,
      align: "center",
      render: (text) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Thông tin điểm đo",
      dataIndex: "thongTinDiemDo",
      key: "thongTinDiemDo",
      width: 200,
    },
    {
      title: "Đơn vị quản lý",
      dataIndex: "donViQuanLy",
      key: "donViQuanLy",
      width: 200,
    },
    {
      title: "Trạm",
      dataIndex: "tram",
      key: "tram",
      width: 200,
      render: (text) => {
        return (
          <EllipsisTooltip placement="topRight" title={text}>
            <div>{text}</div>
          </EllipsisTooltip>
        );
      },
    },
    {
      title: "Tính chất điểm đo",
      dataIndex: "tinhChatDiemDo",
      key: "tinhChatDiemDo",
      width: 170,
    },
    {
      title: "Cấp điện áp",
      dataIndex: "capDienAp",
      key: "capDienAp",
      width: 160,
    },
    {
      title: "Mã điểm đo nội bộ",
      dataIndex: "maDiemDoNoiBo",
      key: "maDiemDoNoiBo",
      width: 170,
    },
  ];
  return (
    <Fragment>
      <Container>
        <Row gutter={24}>
          <Col span={24}>
            <TableStyled>
              <Table
                className="table-tttt"
                columns={columns}
                dataSource={dataTable}
                loading={loading}
                pagination={false}
                scroll={{ x: 1600 }}
                bordered
              />
            </TableStyled>
            <ButtonStyled>
              <Row justify="center">
                <Col span={4}>
                  <Button
                    type="block"
                    htmlType="submit"
                    size="large"
                    className="full"
                    onClick={handleCloseModal}
                  >
                    Đóng lại
                  </Button>
                </Col>
              </Row>
            </ButtonStyled>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default TableThayDoiThongTInDiemDo;
const ButtonStyled = styled.div`
  padding-top: 40px;
`;
const Container = styled.div`
  .ant-table-tbody > tr > td {
    padding: 8px !important;
  }

  .table-text-center {
    text-align: center !important;
  }
`;
