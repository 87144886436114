import {
  Fragment,
  memo,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from "react";
import { downLoadBienBan, getPDFBienBanCongTy } from "./services";
import PDFModal from "@modules/cap-dien/common-ho-so/pdf-viewer";
import { useTranslation } from "react-i18next";
import { TYPE_BIEN_BAN } from "@utils/constants";

function Index({ detail, setVisibleQuyetToanCongTy, visibleQuyetToanCongTy }) {
  const { t } = useTranslation();
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const inBienBanCongTy = useMemo(
    () =>
      visibleQuyetToanCongTy && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visibleQuyetToanCongTy}
            setVisible={setVisibleQuyetToanCongTy}
            title={`${TYPE_BIEN_BAN.QUYETTOAN_CTY} ${detail?.maYCKNCmis} ${detail.tenKhachHang}`}
            getBienBan={(setUrl) => showModalPDF(setUrl)}
            downloadFileExcel={() =>
              downLoadBienBan(detail, setLoadingDownload)
            }
            setLoadingDownload={setLoadingDownload}
            loadingDownload={loadingDownload}
            loadingGetBienBan={loadingPDF}
            isDownloadExcel={true}
            isReload={true}
            type={TYPE_BIEN_BAN.QUYETTOAN_CTY}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visibleQuyetToanCongTy]
  );

  const showModalPDF = useCallback(
    (setUrl) => {
      getPDFBienBanCongTy(
        detail,
        (value) => {
          setUrl(value);
        },
        setLoadingPDF,
        t
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );

  return <Fragment>{inBienBanCongTy}</Fragment>;
}

export default memo(Index);
