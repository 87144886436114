import { Endpoint } from "@utils/endpoint";
import React from "react";
import Screen1 from "./screen/screen1";

export default function BKKhachHangPhaiGhiPMax() {
  const columns = [
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      align: "center",
      width: "150px",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "tenKhachHang",
      width: "200px",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      width: "200px",
    },
    {
      title: "Mã sổ GCS",
      dataIndex: "masoGCS",
      width: "150px",
    },
    {
      title: "Mã trạm",
      dataIndex: "maTram",
      width: "150px",
    },
    {
      title: "Mã GC",
      dataIndex: "maGC",
      width: "150px",
    },
    {
      title: "Số C.Tơ",
      width: "150px",
      dataIndex: "soCongTo",
      render: (text) => {
        return {
          children: <span>{text}</span>,
          props: {
            align: "end",
          },
        };
      },
    },
    {
      title: "BCS",
      dataIndex: "bcs",
      width: "70px",
      render: (text) => {
        return {
          children: <span>{text}</span>,
          props: {
            align: "end",
          },
        };
      },
    },
    {
      title: "Ký mua CSPK",
      dataIndex: "kymuaCSPK",
    },
    {
      title: "Sản lượng",
      width: "100px",
      dataIndex: "sanLuong",
      render: (text) => {
        return {
          children: <span>{text}</span>,
          props: {
            align: "end",
          },
        };
      },
    },
    {
      title: "P MAX",
      width: "100px",
      dataIndex: "pmax",
      render: (text) => {
        return {
          children: <span>{text}</span>,
          props: {
            align: "end",
          },
        };
      },
    },
    {
      title: "Ngày PMAX",
      width: "100px",
      dataIndex: "ngayPmax",
      render: (text) => {
        const bcdate = new Date(text);
        if (text)
          return {
            children: <span>{bcdate.toLocaleDateString("vi-VN")}</span>,
            props: {
              align: "end",
            },
          };
      },
    },
    {
      title: "Ngày GCS",
      width: "100px",
      dataIndex: "ngayGCS",
      render: (text) => {
        const bcdate = new Date(text);
        if (text)
          return {
            children: <span>{bcdate.toLocaleDateString("vi-VN")}</span>,
            props: {
              align: "end",
            },
          };
      },
    },
  ];
  const endPoint = {
    LIST: Endpoint.BANG_KE_KH_PHAI_GHI_PMAX,
    EXCEL: Endpoint.XUAT_EXCEL_PMAX,
    PDF: Endpoint.XUAT_PDF_PMAX,
    ...Endpoint,
  };
  return (
    <Screen1
      header={"Bảng kê khách hàng ghi PMAX"}
      columns={columns}
      endPoint={endPoint}
      scrollX={1700}
    />
  );
}
