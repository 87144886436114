import React, { Fragment, useMemo } from "react";
import { Select, Form, Input } from "antd";
import TrashIcon from "@assets/icon/TrashIcon";
import { removeAccents } from "@utils/function";
const { Option } = Select;
export default function TableColumns({
  handleSelection,
  handleRemove,
  handleChangeInput,
  selectRowKeys,
  dataSourceDSVTTL,
}) {
  const columns = useMemo(() => {
    return [
      {
        title: "STT",
        dataIndex: "stt",
        key: "stt",
        render: (text, record, index) => (
          <div className="table-text-right">{index + 1}</div>
        ),
        width: "6%",
      },
      {
        title: "Chủng loại",
        dataIndex: "chungLoai",
        key: "chungLoai",
        render: (text) => <div className="table-text-center">{text}</div>,
        width: "12%",
      },
      {
        title: "Loại công tơ",
        dataIndex: "loaiCongTo",
        key: "loaiCongTo",
        render: (text, record) => (
          <Form.Item
            name={`loaiCongTo-${record.stt}`}
            style={{ marginBottom: 0 }}
          >
            <Select
              placeholder="Chọn loại công tơ"
              onChange={(value) => handleSelection(value, record)}
              value={record.chungLoaiId || null}
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) => {
                if (option && option.children && option.label) {
                  return (
                    removeAccents(option.label)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0 ||
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {record.duLieuLoaiCongTo && record.duLieuLoaiCongTo.length > 0
                ? record.duLieuLoaiCongTo.map((item, index) => (
                    <Option
                      key={index}
                      value={item.chungLoaiId.toString()}
                      label={item.chungLoai}
                    >
                      {item.loaiCongTo}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        ),
        width: "25%",
      },
      {
        title: "Mô tả",
        dataIndex: "moTa",
        key: "moTa",
        render: (text) => <div className="table-text-left">{text}</div>,
        width: "25%",
      },
      {
        title: "Số lượng tồn",
        dataIndex: "soLuongTon",
        key: "soLuongTon",
        render: (text) => <div className="table-text-right">{text}</div>,
        width: "12%",
      },
      {
        title: "Số lượng trả",
        dataIndex: "soLuongTra",
        key: "soLuongTra",
        render: (text, record) => (
          <div className="table-text-right">
            <Form.Item
              name={`soLuongTra-${record.stt}`}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 0,
              }}
            >
              <Input
                type="number"
                min={0}
                onChange={(e) => handleChangeInput(e, record.stt)}
                style={{ textAlign: "right" }}
              />
            </Form.Item>
          </div>
        ),
        width: "15%",
      },
      {
        title: "Tác vụ",
        key: "tacVu",
        dataIndex: "tacVu",
        width: "7%",
        render: (_, record) => (
          <Fragment>
            {!selectRowKeys.length && dataSourceDSVTTL.length === 1 ? null : (
              <div className="action-table-column">
                <div
                  className="action-icon"
                  onClick={() => handleRemove(record)}
                >
                  <TrashIcon />
                </div>
              </div>
            )}
          </Fragment>
        ),
      },
    ];
  }, [
    dataSourceDSVTTL.length,
    handleChangeInput,
    handleRemove,
    handleSelection,
    selectRowKeys.length,
  ]);

  return columns;
}
