import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE, ENUM_FUNCTION, MATRIX_CAP_DIEN, NAVIGATE_APGIAHOSO, NAVIGATE_DICHVUDIEN, NAVIGATE_DICHVUDIEN_DOITRUONG, NAVIGATE_DICHVUDIEN_GIAODICHVIEN, NAVIGATE_DICHVUDIEN_KIEMDUYET, NAVIGATE_DICHVUDIEN_LANHDAO, NAVIGATE_DICHVUDIEN_NGHIEMTHU, NAVIGATE_DICHVUDIEN_NHANVIENKHAOSAT, NAVIGATE_DICHVUDIEN_PHONGKINHDOANH, NAVIGATE_DICHVUDIEN_QUYETTOAN, NAVIGATE_DICHVUDIEN_TAICHINH, NAVIGATE_DICHVUDIEN_THICONG, NAVIGATE_DUYETHOSO, NAVIGATE_DUYETQUYETTOAN, NAVIGATE_HOSOCOTRONGAI, NAVIGATE_HOSODANGKHAOSAT, NAVIGATE_HOSODANGTREOTHAO, NAVIGATE_HOSODAPHANCONG, NAVIGATE_HOSODATRONGAI, NAVIGATE_HOSODUOCPHANCONG, NAVIGATE_KHOIPHUCTHICONG, NAVIGATE_LAPBBLDDUONGDAY, NAVIGATE_LAPPHUONGAN, NAVIGATE_LAPQUYETTOANCONGTY, NAVIGATE_LAPQUYETTOANKHACHHANG, NAVIGATE_NGHIEMTHUHOSO, NAVIGATE_TIEPNHANPHANCONG, NAVIGATE_TREOTHAO_XACNHANANTOAN, NAVIGATE_VATTU, NAVIGATE_XACNHANQUYETTOAN, NAVIGATE_XACNHANTHICONG, NAVIGATE_XACNHANTHUTIEN, NAVIGATE_XACNHANTHUTIENNVKS, NAVIGATE_XACNHANXUATVATTU } from "@utils/constants";
import { checkPath } from "@utils/function";
import { Modal } from "antd";

export const redirectByMaTrangThai = (
  valueTrangThai,
  navigate,
  maYCKNCmis,
  user,
  params,
  menuList
) => {
  const value = Number(valueTrangThai) || null;
  let hasPermission = true;
  // eslint-disable-next-line max-len
  const uri = `?donViId=${user.unitId}&pageIndex=${DEFAULT_PAGEINDEX}&pageSize=${DEFAULT_PAGESIZE}&searchTerm=${maYCKNCmis}&maTrangThai=${params.maTrangThai}`;
  if (MATRIX_CAP_DIEN.TIEPNHANPHANCONG[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_GIAODICHVIEN}${NAVIGATE_TIEPNHANPHANCONG}`;

    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_GIAODICHVIEN}${NAVIGATE_TIEPNHANPHANCONG}${uri}&funcID=${ENUM_FUNCTION.TIEPNHAN}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.HOSODAPHANCONG[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_GIAODICHVIEN}${NAVIGATE_HOSODAPHANCONG}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_GIAODICHVIEN}${NAVIGATE_HOSODAPHANCONG}${uri}&funcID=${ENUM_FUNCTION.PHANCONG}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.QUANLYHSCOTRONGAI[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_GIAODICHVIEN}${NAVIGATE_HOSODATRONGAI}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_GIAODICHVIEN}${NAVIGATE_HOSODATRONGAI}${uri}&funcID=${ENUM_FUNCTION.TRONGAI}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.APGIAHOSO[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_GIAODICHVIEN}${NAVIGATE_APGIAHOSO}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_GIAODICHVIEN}${NAVIGATE_APGIAHOSO}${uri}&funcID=${ENUM_FUNCTION.APGIA}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.HSDADUOCPHANCONG[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_GIAODICHVIEN}${NAVIGATE_HOSODAPHANCONG}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_GIAODICHVIEN}${NAVIGATE_HOSODAPHANCONG}${uri}&funcID=${ENUM_FUNCTION.PHANCONG}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.HSDANGKHAOSAT[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_NHANVIENKHAOSAT}${NAVIGATE_HOSODANGKHAOSAT}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_NHANVIENKHAOSAT}${NAVIGATE_HOSODANGKHAOSAT}${uri}&funcID=${ENUM_FUNCTION.DANGKHAOSAT}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.LAPPHUONGAN[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_NHANVIENKHAOSAT}${NAVIGATE_LAPPHUONGAN}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_NHANVIENKHAOSAT}${NAVIGATE_LAPPHUONGAN}${uri}&funcID=${ENUM_FUNCTION.LAPPHUONGAN}`
      );
    } else {
      hasPermission = false;
    }
  }
  // if (MATRIX_CAP_DIEN.LAPPHUONGAN[0].includes(value)) {
  //   if (checkPermission(user, "")) {
  //     navigate(
  //       `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_NHANVIENKHAOSAT}${NAVIGATE_LAPPHUONGAN}${uri}`
  //     );
  //     return;
  //   } else {
  //     Modal.warning({
  //       title: "Thông báo",
  //       content: "Bạn không được phân quyền cho chức năng này",
  //     });
  //   }
  // }
  if (MATRIX_CAP_DIEN.HOSOCOTRONGAI[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_NHANVIENKHAOSAT}${NAVIGATE_HOSOCOTRONGAI}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_NHANVIENKHAOSAT}${NAVIGATE_HOSOCOTRONGAI}${uri}&funcID=${ENUM_FUNCTION.TRONGAIKHAOSAT}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.DOITRUONG_DUYETHS[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_DOITRUONG}${NAVIGATE_DUYETHOSO}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_DOITRUONG}${NAVIGATE_DUYETHOSO}${uri}&funcID=${ENUM_FUNCTION.DOITRUONG}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.KIEMDUYET_DUYETHS[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_KIEMDUYET}${NAVIGATE_DUYETHOSO}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_KIEMDUYET}${NAVIGATE_DUYETHOSO}${uri}&funcID=${ENUM_FUNCTION.KIEMDUYET}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.PKD_DUYETHS[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_PHONGKINHDOANH}${NAVIGATE_DUYETHOSO}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_PHONGKINHDOANH}${NAVIGATE_DUYETHOSO}${uri}&funcID=${ENUM_FUNCTION.KINHDOANH}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.LD_DUYETHS[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_LANHDAO}${NAVIGATE_DUYETHOSO}`;

    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_LANHDAO}${NAVIGATE_DUYETHOSO}${uri}&funcID=${ENUM_FUNCTION.LANHDAODUYETHOSO}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.LD_DUYETQUYETTOAN[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_LANHDAO}${NAVIGATE_DUYETQUYETTOAN}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_LANHDAO}${NAVIGATE_DUYETQUYETTOAN}${uri}&funcID=${ENUM_FUNCTION.LANHDAODUYETQUYETTOAN}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.XACNHANTHUTIEN[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_TAICHINH}${NAVIGATE_XACNHANTHUTIEN}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_TAICHINH}${NAVIGATE_XACNHANTHUTIEN}${uri}&funcID=${ENUM_FUNCTION.TAICHINH}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.XACNHANTHUTIENNVKS[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_TAICHINH}${NAVIGATE_XACNHANTHUTIENNVKS}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_TAICHINH}${NAVIGATE_XACNHANTHUTIENNVKS}${uri}&funcID=${ENUM_FUNCTION.XACNHANTHUTIENNVKS}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.KHOIPHUCTAICHINH[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_TAICHINH}/khoi-phuc`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_TAICHINH}/khoi-phuc${uri}&funcID=${ENUM_FUNCTION.KHOIPHUCTAICHINH}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.XACNHANXUATVATTU[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_VATTU}${NAVIGATE_XACNHANXUATVATTU}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_VATTU}${NAVIGATE_XACNHANXUATVATTU}${uri}&funcID=${ENUM_FUNCTION.XACNHANXUATVATTU}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.XACNHANTHICONG[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_THICONG}${NAVIGATE_LAPBBLDDUONGDAY}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_THICONG}${NAVIGATE_LAPBBLDDUONGDAY}${uri}&funcID=${ENUM_FUNCTION.XACNHANTHICONG}`
      );

    }    } else {
      hasPermission = false;
  }
  if (MATRIX_CAP_DIEN.KHOIPHUCTHICONG[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_THICONG}${NAVIGATE_KHOIPHUCTHICONG}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_THICONG}${NAVIGATE_KHOIPHUCTHICONG}${uri}&funcID=${ENUM_FUNCTION.KHOIPHUCTHICONG}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.NGHIEMTHUHOSO[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_NGHIEMTHU}${NAVIGATE_NGHIEMTHUHOSO}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_NGHIEMTHU}${NAVIGATE_NGHIEMTHUHOSO}${uri}&funcID=${ENUM_FUNCTION.XACNHANNGHIEMTHU}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.LAPQUYETTOANCTY[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_QUYETTOAN}${NAVIGATE_LAPQUYETTOANCONGTY}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_QUYETTOAN}${NAVIGATE_LAPQUYETTOANCONGTY}${uri}&funcID=${ENUM_FUNCTION.LAPQUYETTOANCONGTY}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.LAPQUYETTOANKH[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_QUYETTOAN}${NAVIGATE_LAPQUYETTOANKHACHHANG}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_QUYETTOAN}${NAVIGATE_LAPQUYETTOANKHACHHANG}${uri}&funcID=${ENUM_FUNCTION.LAPQUYETTOANKHACHHANG}`
      );
    } else {
      hasPermission = false;
    }
  }
  if (MATRIX_CAP_DIEN.XACNHANQUYETTOAN[0].includes(value)) {
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_QUYETTOAN}${NAVIGATE_XACNHANQUYETTOAN}`;
    if (checkPath(menuList, path) || user.isAdministrator) {
      navigate(
        `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_QUYETTOAN}${NAVIGATE_XACNHANQUYETTOAN}${uri}&funcID=${ENUM_FUNCTION.XACNHANQUYETTOAN}`
      );
    } else {
      hasPermission = false;
    }
  }
  if(MATRIX_CAP_DIEN.HSDANGTHICONG[0].includes(value)){
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_THICONG}${NAVIGATE_HOSODANGTREOTHAO}`;
    if(checkPath(menuList, path) || user.isAdministrator){
      navigate(`${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_THICONG}${NAVIGATE_HOSODANGTREOTHAO}${uri}&funcID=${ENUM_FUNCTION.HSDANGTREOTHAO}`)
    }else{
      hasPermission = false;
    }
  }
  if(MATRIX_CAP_DIEN.HSDAPHANCONG[0].includes(value)){
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_THICONG}${NAVIGATE_HOSODUOCPHANCONG}`
    if(checkPath(menuList, path) || user.isAdministrator){
      navigate(`${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_THICONG}${NAVIGATE_HOSODUOCPHANCONG}${uri}&funcID=${ENUM_FUNCTION.HSDUOCPHANCONG}`)
    }else{
      hasPermission = false;
    }
  }
  if(MATRIX_CAP_DIEN.XACNHANANTOAN[0].includes(value)){
    const path = `${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_THICONG}${NAVIGATE_HOSODUOCPHANCONG}`
    if(checkPath(menuList,path) || user.isAdministrator){
      navigate(`${NAVIGATE_DICHVUDIEN}${NAVIGATE_DICHVUDIEN_THICONG}${NAVIGATE_HOSODUOCPHANCONG}${uri}&funcID=${ENUM_FUNCTION.XACNHANANTOAN}`) 
    }else{
      hasPermission = false
    }
  }

  if (!hasPermission && !user.isAdministrator) {
    Modal.warning({
      title: "Thông báo",
      content: "Bạn không được phân quyền cho chức năng này",
    });
  }
  return;
};