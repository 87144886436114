import LoadingComponent from "@components/Loading";
import { Button, Col, Modal, Row } from "antd";

export default function KhoiPhucTaiChinhModal({
  loading,
  visible,
  handleCloseModal,
  handleSubmit,
}) {
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={"Khôi phục lại tài chính"}
        visible={visible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
          Hồ sơ sẽ chuyển sang bước tài chính. Bạn thực sự muốn điều đó ?
        </h4>
        <Row gutter={24} justify="center" style={{ marginTop: "48px" }}>
          <Col span={12} style={{ textAlign: "right" }}>
            <Button
              key="back"
              onClick={handleCloseModal}
              className="button-closed"
            >
              Đóng lại
            </Button>
          </Col>
          <Col span={12}>
            <Button
              key="submit"
              type="primary"
              className="button-primary"
              htmlType="submit"
              onClick={handleSubmit}
            >
              Đồng ý
            </Button>
          </Col>
        </Row>
      </Modal>
    </LoadingComponent>
  );
}
