import { BASE_API_URL_BAO_CAO } from "@utils/constants";

export const APIBCTonghoptonthat = {
  BCTHTT_TY_LE_DIEN_NANG_TRUYEN_TAI: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcthtt/tl-dien-nang-dung-de-ttvpp`,
  BCTHTT_TH_TON_THAT_DN_TRAM_CONG_CONG: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcthtt/ton-that-dien-nang-ctcc`,
  BCTHTT_BC_TON_THAT_LO_DUONG_DAY: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcthtt/bc-ton-that-lo-duong-day`,
  BCTHTT_BC_TON_THAT_TRUNG_AP_NGAY_1: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcthtt/ton-that-trung-ap-ghi-td-ngay-1`,
  BCTHTT_BC_TON_THAT_DUONG_DAY_NGAY_1: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcthtt/ton-that-duong-day-ghi-td-ngay-1`,
  BCTHTT_TH_CHI_TIEU_TON_THAT_DN_THEO_PHIEN_GHI_CHI_SO: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcthtt/chi-so-ton-that-dntpgcs`,
  BCTHTT_BC_SAN_LUONG_DIEN_NANG_DAU_NGUON_3_GIA: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcthtt/san-luong-dien-nang-dn-3-g`,
};
