import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import { Button, Checkbox, Col, Divider, Form, Row, Select, Spin } from "antd";
import { Option } from "antd/lib/mentions";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { handleDownload, selectBcThtt } from "./service";
export default function BaoCaoTongHopTonThat() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [luyKe3Gia, setLuyKe3Gia] = useState(false);

  const user = useSelector(authSelector);

  const onFinish = (values) => {
    const query = {
      ...values,
      ...( form.getFieldValue("reportType") ===
      Endpoint.BCTHTT_BC_SAN_LUONG_DIEN_NANG_DAU_NGUON_3_GIA && {luyKe: luyKe3Gia ? 1 : 0})
    }
    handleDownload(query, setLoading);
  };

  const onChangeLuyKe = (value) => {
    setLuyKe3Gia(value.target.checked);
  };

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Báo cáo tổng hợp tổn thất
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              initialValues={{
                reportType: Endpoint.BCTHTT_TH_CHI_TIEU_TON_THAT_DN_THEO_PHIEN_GHI_CHI_SO,
                unitId: user.user.unitCode,
                LuyKe: "0",
                LoaiBC: "0",
              }}
              colon={false}
            >
              <Form.Item
                name="reportType"
                label="Báo cáo"
                labelAlign="left"
                required
              >
                <Select placeholder="Tất cả" options={selectBcThtt}></Select>
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") !==
                  Endpoint.BCTHTT_BC_SAN_LUONG_DIEN_NANG_DAU_NGUON_3_GIA ? (
                    <Form.Item
                      name="unitId"
                      label="Tên đơn vị"
                      labelAlign="left"
                      required
                    >
                      <Selection
                        url={Endpoint.GET_TEAM_LIST_CODE}
                        formKey="unitId"
                        form={form}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              <Form.Item
                name="reportDate"
                label="Tháng báo cáo"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="month"
                  formKey="reportDate"
                  form={form}
                />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") ===
                    Endpoint.BCTHTT_TH_CHI_TIEU_TON_THAT_DN_THEO_PHIEN_GHI_CHI_SO ? (
                    <Form.Item
                      name="LuyKe"
                      label="Lũy kế"
                      labelAlign="left"
                      required
                    >
                      <Select placeholder="Chọn lũy kế" formKey="LuyKe">
                        <Option value="0">Báo cáo tháng</Option>
                        <Option value="1">Lũy kế đến tháng</Option>
                      </Select>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") ===
                  Endpoint.BCTHTT_BC_SAN_LUONG_DIEN_NANG_DAU_NGUON_3_GIA ? (
                    <Form.Item
                      name="luyKe"
                      label="Lũy kế"
                      labelAlign="left"
                      required
                    >
                      <Checkbox
                      onChange={onChangeLuyKe}
                      checked={luyKe3Gia}
                    >
                      Lũy kế
                    </Checkbox>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              <Row>
                <Col span={4}></Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>
                </Col>
              </Row>
            </FormComponent>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
