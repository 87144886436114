import React from "react";
import { Button, Col, Modal, Row, Select } from "antd";
import FormComponent from "@components/Form";
import { Option } from "antd/lib/mentions";
import { removeAccents } from "@utils/function";
import LoadingComponent from "@components/Loading";
import { ContainerItem } from "./css-style";

export default function Index(props) {
  const { visible, closeModal, dataLyDo, onFinish, form, loading } = props;
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={"Cập nhật lý do"}
        visible={visible}
        onCancel={closeModal}
        footer={null}
      >
        <FormComponent
          form={form}
          name="form-submit"
          onFinish={onFinish}
          layout="horizonal"
          initialValues={{}}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Row gutter={24}>
            <Col span={24} md={24}>
              <ContainerItem
                name="lyDoId"
                label="Lý do treo tháo"
                className="required-field"
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                  placeholder="Nhập lý do treo tháo"
                >
                  {dataLyDo.map((item, index) => (
                    <Option value={item.id} key={index}>
                      {`${item.maLyDo} - ${item.tenLyDo}`}
                    </Option>
                  ))}
                </Select>
              </ContainerItem>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                Đóng lại
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-submit"
                loading={loading}
              >
                Cập nhật lý do
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}
