import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";

export const getListDonVi = (setLoading, setData) => {
  authGetData({
    url: `${Endpoint.GET_TEAM_LIST_CODE}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};
