import React from "react";
import { Button, Col, Form, InputNumber, Modal, Row, Select } from "antd";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
const { Option } = Select;

export default function UIVatTuCongTy(props) {
  const {
    visible,
    closeModal,
    form,
    t,
    handleSubmitData,
    detailVatTu,
    handleChangeTenVatTu,
    loading,
    listVatTuTheoNgay,
  } = props;
  return (
    <Modal
      title="Vật tư có sẵn - Phần chi phí của Công ty"
      visible={visible}
      onCancel={closeModal}
      footer={null}
      width={800}
    >
      <FormComponent
        form={form}
        name="form-vat-tu-co-san"
        layout="vertical"
        onFinish={handleSubmitData}
        initialValues={{
          ...detailVatTu,
        }}
        autoComplete="off"
      >
        <LoadingComponent loading={loading}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label={t("vat_tu.vat_tu")}
                name="vatTuId"
                className="required-field"
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0 ||
                      option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  onChange={handleChangeTenVatTu}
                  // disabled={detailVatTu?.id}
                >
                  {listVatTuTheoNgay &&
                    listVatTuTheoNgay.map((item, index) => (
                      <Option
                        key={index}
                        value={item.vatTuId}
                        id={item.maVatTu}
                      >
                        {item.tenVaMaVatTu}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t("vat_tu.vat_tu_khach_hang.so_luong")}
                name="soLuong"
                className="required-field"
              >
                <InputNumber min={0}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button
                key="cancel"
                type="default"
                onClick={closeModal}
                className="button-closed"
              >
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                name="form-vat-tu-co-san"
                loading={loading}
              >
                {t("button.cap_nhat")}
              </Button>
            </Col>
          </Row>
        </LoadingComponent>
      </FormComponent>
    </Modal>
  );
}
