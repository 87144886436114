import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, authPutData } from "@utils/request";

export const listDanhSachTroNgai = (setListLoading, setData, query, filterConditions, setTotal) => {
  authGetData({
    url: `${Endpoint.CRMGDV_DANH_SACH_YEU_CAU_CO_TRO_NGAI}?${query}`,
    setLoading: setListLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        const data = res.data.map((item, index) => {
          return {
            ...item,
            STT: (filterConditions.pageIndex - 1) * filterConditions.pageSize + (index + 1),
          };
        });
        setData([...data]);
        setTotal(res.paging.totalCount);
      }
    },
  });
};

export const putPhanPhong = (
  payload,
  setLoading,
  setListLoading,
  filterConditions,
  setTotal,
  setVisibleDuyet,
  form_modal,
  setSelectedRowKeys,
  location,
  setData
) => {
  authPutData({
    url: `${Endpoint.CRMGDV_PHAN_PHONG}`,
    method: "PUT",
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        const query =
          Object.keys(parseParams(location.search)).length > 0
            ? buildQueryString(parseParams(location.search))
            : buildQueryString(filterConditions);
        listDanhSachTroNgai(setListLoading, setData, query, filterConditions, setTotal);
        setVisibleDuyet(false);
        form_modal.resetFields();
        setSelectedRowKeys([]);
      }
    },
  });
};

export const getLinhVucListTest = (
  setLoading,
  form_filter,
  setData,
  filterConditions,
  setTotal,
  location,
  setListLoading,
  status,
  setLinhVucList
) => {
  authGetData({
    url: `${Endpoint.CRM_LIST_LINH_VUC}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        //setLinhVucList(res.data);
        const current_query = {
          ...filterConditions,
          ...(res.data.length === 1 && { LinhVuc: res.data[0].value }),
        };
        if (res.data.length === 1) {
          form_filter.setFieldsValue({
            LinhVuc: res.data[0].value,
          });
        }

        // const updated_query = {
        //   ...current_query,
        //   pageSize: filterConditions.pageSize,
        //   pageIndex: filterConditions.pageIndex,
        // };

        const query = buildQueryString(current_query);
        listDanhSachTroNgai(setListLoading, setData, query, filterConditions, setTotal);
      }
    },
  });
};

export const getLinhVucList = (
  setLoading,
  form_filter,
  setData,
  filterConditions,
  setTotal,
  location,
  setListLoading,
  status,
  setLinhVucList
) => {
  authGetData({
    url: `${Endpoint.CRM_LIST_LINH_VUC}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setLinhVucList(res.data);

        const current_query = {
          ...filterConditions,
          ...(res.data.length === 1 && { LinhVuc: res.data[0].value }),
        };
        if (res.data.length === 1) {
          form_filter.setFieldsValue({
            LinhVuc: res.data[0].value,
          });
        }

        // const updated_query = {
        //   ...current_query,
        //   pageSize: filterConditions.pageSize,
        //   pageIndex: filterConditions.pageIndex,
        // };

        const query = buildQueryString(current_query);

        listDanhSachTroNgai(setListLoading, setData, query, filterConditions, setTotal);
      }
    },
  });
};

export const getChiTietYeuCau = (setLoading, id, setData) => {
  authGetData({
    url: `${Endpoint.CRM_CHI_TIET_YEU_CAU}?Id=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const putPhanCong = (
  payload,
  setLoading,
  setListLoading,
  filterConditions,
  setTotal,
  setVisiblePhanCong,
  form,
  location,
  setData,
  dispatch,
  closeModal,
  formFilter,
  setLinhVucList,
  setIsShowDetail
) => {
  authPutData({
    url: `${Endpoint.CRMGDV_PHAN_CONG_LAI}`,
    method: "PUT",
    payload,
    setLoading: setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        const status = 7;

        getLinhVucListTest(
          setLoading,
          formFilter,
          setData,
          filterConditions,
          setTotal,
          location,
          setListLoading,
          status,
          setLinhVucList
        );
        setIsShowDetail(false);
        setVisiblePhanCong(false);
        form.resetFields();
        dispatch(closeModal());
      }
    },
  });
};

export const getNhanVienHienTruong = (setLoading, setData, doiId) => {
  authGetData({
    url: `${Endpoint.CRM_DOI_TRUONG_PHAN_CONG_NGUOI_KHAO_SAT}?DoiId=${doiId}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getListDoi = (setLoading, setData, unitId) => {
  authGetData({
    url: `${Endpoint.GET_TEAM_LIST}?DonViId=${unitId}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getListNvGiamSat = (setLoading, setData, unitId) => {
  authGetData({
    url: `${Endpoint.CRM_LIST_NHAN_VIEN_GIAM_SAT}?DonViId=${unitId}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getListNvKinhDoanh = (setLoading, setData, unitId) => {
  authGetData({
    url: `${Endpoint.CRM_LIST_NHAN_VIEN_PHONG_KD}?DonViId=${unitId}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getListNvDieuDo = (setLoading, setData, unitId) => {
  authGetData({
    url: `${Endpoint.CRM_LIST_NV_DIEU_DO}?DonViId=${unitId}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

//CRMGDV_KET_QUA_XU_LY
export const getKetQuaXuLy = (setLoading, setData, id) => {
  authGetData({
    url: `${Endpoint.CRMGDV_KET_QUA_XU_LY}?Id=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

//CRMGDV_CAP_NHAT_KET_QUA_XU_LY
export const putCapNhatKetQua = (
  payload,
  setLoading,
  setListLoading,
  setData,
  location,
  filterConditions,
  setTotal,
  setVisibleCapNhat,
  dispatch,
  closeModal,
  formFilter,
  setLinhVucList,
  setIsShowDetail,
) => {
  authPutData({
    url: `${Endpoint.CRMGDV_CAP_NHAT_KET_QUA_XU_LY}`,
    method: "PUT",
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        const status = 7;

        getLinhVucListTest(
          setLoading,
          formFilter,
          setData,
          filterConditions,
          setTotal,
          location,
          setListLoading,
          status,
          setLinhVucList
        );

        setVisibleCapNhat(false);
        setIsShowDetail(false);
        // dispatch(closeModal());
      }
    },
  });
};

//CRMGDV_CAP_NHAT_YEU_CAU_SAI

export const putCapNhatYeuCauSai = (
  payload,
  setLoading,
  setListLoading,
  setData,
  location,
  filterConditions,
  setTotal,
  setVisibleYeuCauSai,
  dispatch,
  closeModal,
  formFilter,
  setLinhVucList,
  setIsShowDetail,
) => {
  authPutData({
    url: `${Endpoint.CRMGDV_CAP_NHAT_YEU_CAU_SAI}`,
    method: "PUT",
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        const status = 7;

        getLinhVucListTest(
          setLoading,
          formFilter,
          setData,
          filterConditions,
          setTotal,
          location,
          setListLoading,
          status,
          setLinhVucList
        );
        setIsShowDetail(false);
        setVisibleYeuCauSai(false);
        dispatch(closeModal());
      }
    },
  });
};
