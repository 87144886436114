import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { DANH_SACH_DON_VI_DIEN_LUC } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { handleDownload, handleTongHop, selectHeSinhThai } from "./service";

export default function BaoCaoKHSuDungHeSinhThaiMoi() {
  const [loading, setLoading] = useState(false);
  const [baocao, setBaocao] = useState(Endpoint.BCHST_BC_TONG_HOP);
  const user = useSelector(authSelector);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };

  const [unitKeyFilter, setUnitKeyFilter] = useState("");

  const handleChangeReportType = () => {
    setBaocao(form.getFieldValue("reportType"));
    setUnitKeyFilter(
      form.getFieldValue("reportType") === Endpoint.BCHST_CHI_TIET_HST_KH ? DANH_SACH_DON_VI_DIEN_LUC.TONG_CONG_TY.value : ""
    );
  };

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Báo cáo khách hàng sử dụng hệ sinh thái
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            {" "}
            <div className="flex">
              <FlexForm>
                <FormComponent
                  onFinish={onFinish}
                  form={form}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 14 }}
                  initialValues={{
                    reportType: Endpoint.BCHST_BC_TONG_HOP,
                    unitId: user.user.unitCode,
                  }}
                  colon={false}
                >
                  <Form.Item name="reportType" label="Loại báo cáo" labelAlign="left" required>
                    <Select
                      formKey="reportType"
                      placeholder="Tất cả"
                      options={selectHeSinhThai}
                      onChange={handleChangeReportType}
                    ></Select>
                  </Form.Item>
                  <Form.Item name="unitId" label="Đơn vị" labelAlign="left" required>
                    <Selection url={Endpoint.GET_TEAM_LIST_CODE} formKey="unitId" form={form} filterKey={unitKeyFilter} />
                  </Form.Item>
                  <Row>
                    <Col span={4}></Col>
                    <Col>
                      <Button type="primary" htmlType="submit">
                        Xuất báo cáo
                      </Button>
                    </Col>
                  </Row>
                </FormComponent>
              </FlexForm>
              <FlexButton>
                {baocao === Endpoint.BCHST_CHI_TIET_HST_KH ? (
                  <Button
                    type="primary"
                    loading={loading}
                    className="ml-20px"
                    onClick={() => handleTongHop(form.getFieldValue(), setLoading)}
                  >
                    Tổng hợp
                  </Button>
                ) : null}
              </FlexButton>
            </div>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
const FlexButton = styled.div`
  flex: 1;
`;
const FlexForm = styled.div`
  flex: 3;
`;
