/* eslint-disable react-hooks/exhaustive-deps */
import Filter from "../../../common/Filter";
import TableComponent from "@components/TableComponent";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  STATUSCODE_200,
} from "@utils/constants";
import {
  buildQueryString,
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { authGetData } from "@utils/request";
import { Spin } from "antd";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Export } from "../../export/Export";
import FilterScreen1 from "./list-filter/filter-screen1";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";

export default function Screen1(props) {
  const {
    endPoint,
    columns,
    header,
    uId,
    rowKey = "key",
    scrollX,
  } = props;

  const { user } = useSelector(authSelector);
  const [, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const location = useLocation();

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    donViId: user.unitId,
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [filterConditions]);

  const queryString = buildQueryString(
    parseParams({
      ...filterConditions,
      donViId: filterConditions.donViId,
      maSoGcs: filterConditions.maSoGcs,
      kyGCS: filterConditions.kyGCS,
      thangNam:
        filterConditions.thangNam || undefined,
    })
  );
  const getPageList = useCallback(() => {
    authGetData({
      url: `${endPoint.LIST}?${queryString}`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          if (rowKey === "id") {
            setDataSource(
              res.data.map((e) => ({
                ...e,
                children: e.children.map((e) => ({
                  ...e,
                  children: [
                    ...e.children,
                    {
                      tongSoDiemDo: e.tongSoDiemDo,
                      tongDienNang: e.tongDienNang,
                      tongDienNangTieuThu: e.tongDienNangTieuThu,
                      tongSanLuongThao: e.tongSanLuongThao,
                    },
                  ],
                })),
              }))
            );
          } else
            setDataSource(
              res.data.map((e, index) => ({
                ...e,
                key:
                  (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                  (index + 1),
              }))
            );
          setTotalCount(res.paging.totalCount);
        }
      },
    });
  }, [endPoint.LIST, filterConditions, rowKey, queryString]);

  useEffect(() => {
    getPageList();
  }, [getPageList]);

  const clearData = () => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
    });
    setSearchParams();
    setDataSource([]);
    setTotalCount(0);
  };

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };
  const genExtra = useCallback(
    () => (
      <div className="export">
        <Export
          excelEndpoint={
            endPoint.EXCEL
              ? `${endPoint.EXCEL}?${queryString}${uId ? `&UnitId=${uId}` : ""}`
              : null
          }
          pdfEndpoint={
            endPoint.PDF
              ? `${endPoint.PDF}?${queryString}${uId ? `&UnitId=${uId}` : ""}`
              : null
          }
          setLoading={setLoading}
        />
      </div>
    ),
    [endPoint, uId, queryString]
  );

  return (
    <Fragment>
      <Container>
        <Filter>
          <Spin spinning={loading}>
            <FilterScreen1
              filterConditions={filterConditions}
              endPoint={endPoint}
              setSearchParams={setSearchParams}
              clearData={clearData}
              setFilterConditions={setFilterConditions}
              loading={loading}
            />
          </Spin>
        </Filter>
        <TableComponent
          header={header}
          renderExtraAction={() => genExtra()}
          dataSource={dataSource}
          columns={customColumn(columns, filterConditions)}
          totalData={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
          scrollX={scrollX}
          rowKey={rowKey}
        />
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
  .ant-collapse.ant-collapse-icon-position-left {
    margin-top: 20px;
  }
`;
