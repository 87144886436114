import { Fragment, useCallback, useEffect, useState } from "react";
import { handleRemoveImage } from "../../services";
// import { handleListImage, handleRemoveImage } from "../../services";
import ListImage from "./list-image";

export default function Index({
  // detail,
  remove,
  // loaiGiayToId,
  dataImages,
  setFetchDataTable,
  listId,
  isSubmit,
  setIsSubmit,
  getListImages,
  // setDataImage,
  setReloadListImage,
  funcID,
}) {
  // const [dataImage, setDataImage] = useState(dataImages);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [, setCaption] = useState("");
  const [modalSrc, setModalSrc] = useState("");
  const [sectionIndex, setSectionIndex] = useState(null);
  const [imageIndex, setImageIndex] = useState(null);
  const [keyImage, setKeyImage] = useState(0);
  const [currentSectionLength, setCurrentSectionLength] = useState(null);
  const [hideButtonLeft, setHideButtonLeft] = useState(false);
  const [hideButtonRight, setHideButtonRight] = useState(false);
  // const [listId, setListId] = useState([]);
  const [note, setNote] = useState("");

  //
  const showSliderImage = useCallback(
    (index) => {
      if (sectionIndex <= 0 && imageIndex === 0) {
        setHideButtonLeft(true);
      } else setHideButtonLeft(false);
      if (
        dataImages[index][sectionIndex - 1] === dataImages[index].length - 1 &&
        imageIndex === dataImages[index].length - 1
      )
        setHideButtonRight(true);
      else setHideButtonRight(false);
      setShowModal(true);
    },
    [dataImages, imageIndex, sectionIndex]
  );

  const plusSlides = useCallback(
    (n, index) => {
      const nextIndex = n;

      // const numberOfSections = dataDetail.length;

      if (nextIndex <= 0 && sectionIndex <= 1) {
        setHideButtonLeft(true);
      } else setHideButtonLeft(false);
      if (nextIndex === dataImages[index].length - 1) {
        setHideButtonRight(true);
      } else setHideButtonRight(false);
      let nextImage = nextIndex;
      let nextImageIndex = nextIndex;
      let nextSectionIndex = sectionIndex;
      let nextSectionLength = currentSectionLength;
      let note = dataImages[index][nextIndex]?.ghiChu;
      if (nextIndex >= sectionIndex) {
        nextImage = dataImages[index][nextIndex].filePath;
        nextImageIndex = nextIndex;
        nextSectionIndex = sectionIndex;
        nextSectionLength = currentSectionLength;
        note = dataImages[index][nextIndex].ghiChu;
      } else {
        nextImage = dataImages[index][sectionIndex - 1].filePath;
        nextImageIndex = 0;
        nextSectionIndex = sectionIndex - 1;
        nextSectionLength = dataImages[index][sectionIndex - 1].filePath;
        note = dataImages[index][sectionIndex - 1].ghiChu;
      }

      setCaption(nextImage);
      setModalSrc(nextImage);
      setImageIndex(nextImageIndex);
      setSectionIndex(nextSectionIndex);
      setCurrentSectionLength(nextSectionLength);
      setNote(note);
      setKeyImage(index);
    },
    [currentSectionLength, dataImages, sectionIndex]
  );
  const removeImage = useCallback(
    (imageId) => {
      handleRemoveImage(
        setLoading,
        imageId,
        setReloadListImage,
        setFetchDataTable
      );
    },
    [setFetchDataTable, setReloadListImage]
  );

  // const getListImages = useCallback(() => {
  //   handleListImage(detail, setLoading, setDataImage, setListId);
  // }, [detail]);

  // useEffect(() => {
  //   getListImages();
  // }, [getListImages]);

  useEffect(() => {
    if (isSubmit) {
      getListImages();
      setIsSubmit(false);
    }
  }, [getListImages, isSubmit, setIsSubmit]);

  const renderListImage = useCallback(() => {
    return (
      <ListImage
        loading={loading}
        dataImage={dataImages}
        showSliderImage={showSliderImage}
        setCaption={setCaption}
        setModalSrc={setModalSrc}
        setImageIndex={setImageIndex}
        imageIndex={imageIndex}
        setSectionIndex={setSectionIndex}
        setCurrentSectionLength={setCurrentSectionLength}
        setHideButtonLeft={setHideButtonLeft}
        setHideButtonRight={setHideButtonRight}
        showModal={showModal}
        setShowModal={setShowModal}
        modalSrc={modalSrc}
        plusSlides={plusSlides}
        hideButtonLeft={hideButtonLeft}
        hideButtonRight={hideButtonRight}
        removeImage={removeImage}
        remove={remove}
        note={note}
        setNote={setNote}
        keyImage={keyImage}
        setKeyImage={setKeyImage}
        listId={listId}
        funcID={funcID}
      />
    );
  }, [
    dataImages,
    funcID,
    hideButtonLeft,
    hideButtonRight,
    imageIndex,
    keyImage,
    listId,
    loading,
    modalSrc,
    note,
    plusSlides,
    remove,
    removeImage,
    showModal,
    showSliderImage,
  ]);
  return <Fragment>{renderListImage()}</Fragment>;
}
