import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { handlePagination } from "@utils/function";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { exportData, handleDownload, selectBCWebEVN } from "./service";

export default function BaoCaoWebEVN() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);
  const [data, setData] = useState([]);
  const [baocao, setBaocao] = useState(
    Endpoint.BCWEHN_TK_NGUOI_DANG_NHAP_ONLINE_HE_THONG
  );
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });
  //pagination table
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };

  const columns = [
    {
      title: "Ngày",
      dataIndex: "NGAY",
      key: "NGAY",
      width: "30%",
      align: "center",
    },
    {
      title:
        "Số lượt khách hàng truy cập Website EVNHANOI nhưng chưa đăng nhập",
      dataIndex: "SO_LUONG_TRUY_CAP",
      key: "SO_LUONG_TRUY_CAP",
      width: "35%",
      align: "center",
    },
    {
      title: "Số lượt khách hàng đăng nhập Website/App EVNHANOI",
      dataIndex: "SO_LUONG_DANG_NHAP",
      key: "SO_LUONG_DANG_NHAP",
      width: "35%",
      align: "center",
    },
  ];

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Báo cáo Web EVN Hà Nội
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              initialValues={{
                reportType: Endpoint.BCWEHN_TH_VE_VIEC_DANH_GIA_MUC_DO_HAI_LONG_KH_EVN_HA_NOI,
                unitId: user.user.unitCode,
              }}
              colon={false}
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
            >
              <Form.Item
                name="reportType"
                label="Báo cáo"
                labelAlign="left"
                required
              >
                <Select
                  options={selectBCWebEVN}
                  onChange={() => setBaocao(form.getFieldValue("reportType"))}
                ></Select>
              </Form.Item>

              {/* Đơn vị */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") !==
                    Endpoint.BCWEHN_TK_NGUOI_DANG_NHAP_ONLINE_HE_THONG &&
                  getFieldValue("reportType") !==
                    Endpoint.BCWEHN_TH_17_DICH_VU_TIEP_NHAN &&
                  getFieldValue("reportType") !==
                    Endpoint.BCWEHN_TH_TAT_CA_DV_DIEN_TIEP_NHAN ? (
                    <Form.Item
                      name="unitId"
                      label="Đơn vị"
                      labelAlign="left"
                      required
                    >
                      <Selection
                        url={Endpoint.GET_TEAM_LIST_CODE}
                        formKey="unitId"
                        form={form}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              {/* Từ ngày */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") !==
                  Endpoint.TK_SO_LUONG_GIAO_DICH_DIEM_THU ? (
                    <Form.Item
                      name="TuNgay"
                      label="Từ ngày"
                      labelAlign="left"
                      required
                    >
                      <DatePickerComponent
                        picker="Day"
                        formKey="TuNgay"
                        form={form}
                        format={"YYYY-MM-DD"}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              {/* Đến ngày */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") !==
                  Endpoint.TK_SO_LUONG_GIAO_DICH_DIEM_THU ? (
                    <Form.Item
                      name="DenNgay"
                      label="Đến ngày"
                      labelAlign="left"
                      required
                    >
                      <DatePickerComponent
                        picker="Day"
                        formKey="DenNgay"
                        form={form}
                        format={"YYYY-MM-DD"}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              <Row>
                <Col span={4}></Col>
                <Col>
                  {baocao ===
                  Endpoint.BCWEHN_TK_NGUOI_DANG_NHAP_ONLINE_HE_THONG ? (
                    <Button
                      type="primary"
                      className="mr-20px"
                      onClick={() =>
                        exportData(form.getFieldValue(), setLoading, setData)
                      }
                    >
                      Hiển thị
                    </Button>
                  ) : null}

                  <Button type="primary" htmlType="submit" className="mr-20px">
                    Xuất báo cáo
                  </Button>
                  {/* <Button
                    type="primary"
                    loading={loading}
                    onClick={() => {
                      handleOpenPdf(form.getFieldValue(), setLoading);
                    }}
                  >
                    In báo cáo
                  </Button> */}
                </Col>
              </Row>

              {/* <Row style={{ margin: "1rem 0" }}>
                <Col span={4}>
                  <Typography.Text>Số Người online:</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text style={{ color: "red" }}>
                    Số Người online:
                  </Typography.Text>
                </Col> */}
              {/* </Row> */}
            </FormComponent>
            {baocao === Endpoint.BCWEHN_TK_NGUOI_DANG_NHAP_ONLINE_HE_THONG ? (
              <TableComponent
                header="Thông tin hiển thị"
                columns={columns}
                dataSource={data}
                pagination={filterConditions}
                onChangePagination={onChangePagination}
              />
            ) : null}
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
