import { closeModal } from "@state/system-config/reducer";
import { Button, Tabs, Modal, Tooltip } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { handleDetailDiemDo, handleDBAnhHT } from "../../services";
import HinhAnhHienTruong from "./tabpane/anh-hien-truong";
import ThongTinCongToChiTiet from "./tabpane/thong-tin-cong-to";
import HoDungChung from "./tabpane/ho-dung-chung";
import NhatKy from "./tabpane/nhat-ky";
import ChiTietGiaMoi from "./tabpane/chi-tiet-gia-moi";
import { ContainerBody } from "@modules/cap-dien/nhan-vien-khao-sat/css-styled";
import LoadingComponent from "@components/Loading";
import { InfoCircleOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;


export default function ChiTietCongToTabs({
  detailRecord
}) {
  const [detail, setDetail] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleDetailDiemDo(detailRecord.id, setDetail, setLoading);
  }, [detailRecord.id]);

  const dongBoAnhHT = () => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: `Bạn muốn đồng bộ ảnh hiện trường?`,
      onOk() {
        //call api
        handleDBAnhHT(
          detailRecord.id,
          setLoading,
          setDetail,
          handleDetailDiemDo
        );
      },
      onCancel() { },
      okText: "Đồng ý",
      cancelText: "Hủy",
    });
  };
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const onChange = (key) => { };

  return (
    <LoadingComponent loading={loading}>
      <Fragment>
        <ContainerBody className="modal-body">
          <Tabs onChange={onChange} type="card">
            <TabPane tab="Thông tin chung" key="1">
              <ThongTinCongToChiTiet
                detail={detail}
              />
            </TabPane>
            {detail?.loaiKhachHang === "0" && detail?.isChuyenApGia === false ?
              (<TabPane tab="Hộ dùng chung" key="2">
                <HoDungChung detail={detail} />
              </TabPane>) :
              (<TabPane tab="Chi tiết giá mới" key="2">
                <ChiTietGiaMoi detail={detail} />
              </TabPane>)
            }
            {detail?.syncAnhHienTruong && detail?.anhHienTruongs.length === 0 ?
              (<TabPane tab={<Tooltip title="Chưa đồng bộ ảnh hiện trường"><span> <InfoCircleOutlined style={{color: 'red'}} /> Hình ảnh hiện trường </span></Tooltip> } key="3" >
                <HinhAnhHienTruong dataAnh={detail?.anhHienTruongs} dongBoAnhHT={dongBoAnhHT} syncAnhHienTruong={detail?.syncAnhHienTruong} />
              </TabPane>) :
              (<TabPane tab="Hình ảnh hiện trường" >
                <HinhAnhHienTruong dataAnh={detail?.anhHienTruongs} dongBoAnhHT={dongBoAnhHT} syncAnhHienTruong={detail?.syncAnhHienTruong} />
              </TabPane>)
            }
            <TabPane tab="Nhật ký" key="4">
              <NhatKy detail={detail} />
            </TabPane>
          </Tabs>
        </ContainerBody>
        <div className="ant-modal-footer text-center">
          <Button type="default" onClick={() => dispatch(closeModal())}>
            Đóng lại
          </Button>
        </div>
      </Fragment>
    </LoadingComponent>

  );
}
