import TaiAnhHoSoTab from "@modules/tich-hop-CRM/common-ho-so/tai-anh-ho-so/index";
import { Tabs } from "antd";
import React from "react";
import { Style } from "../../../css-styled";
import ThongTinYeuCauTab from "./tabs/thong-tin-yeu-cau-tab";
function XacNhanHoanThanhModal({
  detail,
  closeModal,
  setData,
  setListLoading,
  filterConditions,
  setTotal,
  location,
}) {
  const { TabPane } = Tabs;
  return (
    <Style>
      <div className="tich-hop-CRM-gdv">
        <div style={{ padding: "15px" }}>
          <Tabs className="tabs-container">
            <TabPane tab="Thông tin yêu cầu" key="1">
              <ThongTinYeuCauTab
                detail={detail}
                setData={setData}
                closeModal={closeModal}
                setListLoading={setListLoading}
                filterConditions={filterConditions}
                setTotal={setTotal}
                location={location}
              />
            </TabPane>
            <TabPane tab="Tải ảnh hồ sơ" key="2">
              <TaiAnhHoSoTab detail={detail} closeModal={closeModal} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Style>
  );
}

export default XacNhanHoanThanhModal;
