import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  parseParams,
  alertMessage,
  getErrorMessage,
} from "@utils/function";
import { authDeleteData, authGetData, authPostData } from "@utils/request";

//get list data
export const handleListData = (
  setLoading,
  filterConditions,
  setDataHinhThuc,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.LIST_HINH_THUC_LAP_DAT}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataHinhThuc(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataHinhThuc([]);
    },
  });
};

//create data
const handleCreateData = (
  data,
  setLoading,
  closeModal,
  getListHinhThuc,
  form
) => {
  authPostData({
    url: Endpoint.CREATE_HINH_THUC_LAP_DAT,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListHinhThuc();
        closeModal();
      } else getErrorMessage(res, form);
    },
  });
};

//update data
const handleUpdateData = (
  data,
  setLoading,
  closeModal,
  getListHinhThuc,
  form
) => {
  authPostData({
    url: Endpoint.UPDATE_HINH_THUC_LAP_DAT,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListHinhThuc();
        closeModal();
      } else getErrorMessage(res, form);
    },
  });
};

//xử lý call api sửa/tạo

const handleUpdate = (data, setLoading, closeModal, getListHinhThuc, form) => {
  authPostData({
    url: Endpoint.ACTIVE_HINH_THUC_LAP_DAT,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListHinhThuc();
        closeModal();
      } else getErrorMessage(res, form);
    },
  });
};

//submit form modal Is Active
export const handleSubmitFormIsActive = (
  values,
  detaiDataTable,
  setLoading,
  closeModal,
  getListData,
  form
) => {
  const data = {
    ...values,
  };

  if (detaiDataTable.id) {
    data.id = detaiDataTable.id;
  }

  handleUpdate(data, setLoading, closeModal, getListData, form);
};

//remove data
export const handleRemoveData = (ids, setLoading, getListHinhThuc, t) => {
  authDeleteData({
    url: Endpoint.REMOVE_HINH_THUC_LAP_DAT + "?Ids=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) getListHinhThuc();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

//submit form modal Hinh thuc cai dat
export const handleSubmitForm = (
  values,
  detaiDataTable,
  setLoading,
  closeModal,
  getListData,
  form
) => {
  const data = {
    ...values,
  };

  if (detaiDataTable.id) {
    data.id = detaiDataTable.id;
  }

  handleCreateUpdate(data, setLoading, closeModal, getListData, form);
};

// create/update dữ liệu
const handleCreateUpdate = (
  data,
  setLoading,
  closeModal,
  getListData,
  form
) => {
  if (data.id) {
    //sửa dữ liệu

    handleUpdateData(data, setLoading, closeModal, getListData, form);
  } else {
    //tạo dữ liệu

    handleCreateData(data, setLoading, closeModal, getListData, form);
  }
};
