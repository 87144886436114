import FormComponent from "@components/Form";
import { Row } from "antd";
import { Container } from "../../css-styled";
import HoDungChungPanel from "../../panels/ho-dung-chung";

export default function HoDungChung(props) {
  // eslint-disable-next-line no-unused-vars
  const {
    detail
  } = props;
  return (
    // <LoadingComponent >
      <FormComponent
        autoComplete="off"
        name="form-chi-tiet-cong-to"
        initialValues={{}}
        layout="vertical"
        onValuesChange={false}
        scrollToFirstError
      >
        <Container>
          <Row gutter={24}>
            <HoDungChungPanel
              detail={detail}
              pagination={false}
            />
          </Row>
        </Container>
      </FormComponent>
    // </LoadingComponent>
  );
}
