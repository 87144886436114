import { API_TODOILOGIN } from "../../api-url/cham-soc-khach-hang/common/index";
import {
  APIDanhSachKH,
  APIDanhSachSo,
  APIKYSO,
  APINhanTinChoKH,
  APIBAOCAOKIEMSOAT,
  APIBAOCAODOISOATSMS,
  APIBAOCAODOISOATEMAIL,
  APIBaoCaoKySo,
  APIBAOCAODOISOATZALO,
  APIBaoCaoTH,
  APIBCDOISOATSMSCovid4,
  APIDSKHHDVSMS,
  APIBCDOISOATKHACHHANG,
  APITNCHUYENDOISO,
  APIBCDOISOATDTXD,
  APIBCDOISOATSMSCovid2,
  APIBAOCAO_DSBRANDNAME,
  APITIM_KIEM_TIN_NHAN,
  APIBAOCAO_EP,
  APITIM_KIEM_THONGTIN_KH,
  APIBaoCaoCongTinNhanCSKH,
} from "../../api-url/cham-soc-khach-hang/bao-cao-kiem-soat-sms";
// import { TienIchTraCuuYeuCau } from "@modules/tich-hop-CRM/tien-ich/tra-cuu-yeu-cau/tra-cuu-yeu-cau";

const Common = {
  TODOI_LOGIN: API_TODOILOGIN.TODOI_LOGIN,
};

const KYSO = {
  // api doi tuong
  LIST_DOI_TUONG_KY_SO: APIKYSO.LIST_DOI_TUONG_KY_SO,
  // tao bien ban thong bao
  TAO_BIEN_BAN_KY_SO_GUI_KH: APIKYSO.TAO_BIEN_BAN_KY_SO_GUI_KH,
  HUY_BIEN_BAN_KY_SO_GUI_KH: APIKYSO.HUY_BIEN_BAN_KY_SO_GUI_KH,
  XACNHAN_BIEN_BAN_KY_SO_GUI_KH: APIKYSO.XACNHAN_BIEN_BAN_KY_SO_GUI_KH,
  HUY_XACNHAN_BIEN_BAN_KY_SO_GUI_KH: APIKYSO.HUY_XACNHAN_BIEN_BAN_KY_SO_GUI_KH,
  KYSO_BIEN_BAN_KY_SO_GUI_KH: APIKYSO.KYSO_BIEN_BAN_KY_SO_GUI_KH,
  HUY_KYSO_BIEN_BAN_KY_SO_GUI_KH: APIKYSO.HUY_KYSO_BIEN_BAN_KY_SO_GUI_KH,
};

const NhanTinChoKH = {
  CSKH_NHAN_TIN: APINhanTinChoKH.CSKH_NHAN_TIN,
  CSKH_BAO_CAO_ZALO: APINhanTinChoKH.CSKH_BAO_CAO_ZALO,
  CSKH_BAO_CAO_APP: APINhanTinChoKH.CSKH_BAO_CAO_APP,
  CSKH_BAO_CAO_EMAIL: APINhanTinChoKH.CSKH_BAO_CAO_EMAIL,
  CSKH_DOITUONG_DROPDOWN: APINhanTinChoKH.CSKH_DOITUONG_DROPDOWN,
  CSKH_DOITUONG: APINhanTinChoKH.CSKH_DOITUONG,
  CSKH_NT_ZALO_EMAIL: APINhanTinChoKH.CSKH_NT_ZALO_EMAIL,
  CSKH_NT_SMS: APINhanTinChoKH.CSKH_NT_SMS,
  CSKH_XUAT_NT_SMS: APINhanTinChoKH.CSKH_XUAT_NT_SMS,
  CSKH_XUAT_NT_ZALO_EMAIL: APINhanTinChoKH.CSKH_XUAT_NT_ZALO_EMAIL,
  CSKH_SUA_CU_PHAP: APINhanTinChoKH.CSKH_SUA_CU_PHAP,
};

const GetDanhSachSo = {
  CSKH_DSS_SL_QUA_150: APIDanhSachSo.CSKH_DSS_SL_QUA_150,
  CSKH_DSS_SL_QUA_130: APIDanhSachSo.CSKH_DSS_SL_QUA_130,
  CSKH_DSS_TBTD_LAN_2: APIDanhSachSo.CSKH_DSS_TBTD_LAN_2,
  CSKH_DSS_TBTD_LAN_2_KTC: APIDanhSachSo.CSKH_DSS_TBTD_LAN_2_KTC,
  CSKH_DSS_TB_NGUNG_CAP_DIEN: APIDanhSachSo.CSKH_DSS_TB_NGUNG_CAP_DIEN,
  CSKH_DSS_TB_THAY_DK_CT: APIDanhSachSo.CSKH_DSS_TB_THAY_DK_CT,
  CSKH_DSS_TB_CHI_SO_THAY_DK_CT: APIDanhSachSo.CSKH_DSS_TB_CHI_SO_THAY_DK_CT,
  CSKH_DSS_TB_LICH_GHI_CS: APIDanhSachSo.CSKH_DSS_TB_LICH_GHI_CS,
  CSKH_DSS_TB_LICH_GHI_CS_TET: APIDanhSachSo.CSKH_DSS_TB_LICH_GHI_CS_TET,
  CSKH_DSS_TB_KH_2_DINH_MUC_TRO_LEN:
    APIDanhSachSo.CSKH_DSS_TB_KH_2_DINH_MUC_TRO_LEN,
  CSKH_DSS_TB_KIEM_TRA_AP_GIA_BAN_DIEN:
    APIDanhSachSo.CSKH_DSS_TB_KIEM_TRA_AP_GIA_BAN_DIEN,
  CSKH_DSS_TB_XACNHAN_SLDIEN_MT: APIDanhSachSo.CSKH_DSS_TB_XACNHAN_SLDIEN_MT,
  CSKH_DSS_KH_6_THANG_KHONG_DUNG: APIDanhSachSo.CSKH_DSS_KH_6_THANG_KHONG_DUNG,
};

const GetDanhSachKH = {
  CSKH_DSkh_SL_QUA_150: APIDanhSachKH.CSKH_DSkh_SL_QUA_150,
  CSKH_DSkh_SL_QUA_130: APIDanhSachKH.CSKH_DSkh_SL_QUA_130,
  CSKH_DSkh_TBTD_LAN_2: APIDanhSachKH.CSKH_DSkh_TBTD_LAN_2,
  CSKH_DSkh_TBTD_LAN_2_KTC: APIDanhSachKH.CSKH_DSkh_TBTD_LAN_2_KTC,
  CSKH_DSkh_TB_NGUNG_CAP_DIEN: APIDanhSachKH.CSKH_DSkh_TB_NGUNG_CAP_DIEN,
  CSKH_DSkh_TB_THAY_DK_CT: APIDanhSachKH.CSKH_DSkh_TB_THAY_DK_CT,
  CSKH_DSkh_TB_CHI_SO_THAY_DK_CT: APIDanhSachKH.CSKH_DSkh_TB_CHI_SO_THAY_DK_CT,
  CSKH_DSkh_TB_LICH_GHI_CS: APIDanhSachKH.CSKH_DSkh_TB_LICH_GHI_CS,
  CSKH_DSkh_TB_LICH_GHI_CS_TET: APIDanhSachKH.CSKH_DSkh_TB_LICH_GHI_CS_TET,
  CSKH_DSkh_TB_KH_2_DINH_MUC_TRO_LEN:
    APIDanhSachKH.CSKH_DSkh_TB_KH_2_DINH_MUC_TRO_LEN,
  CSKH_DSkh_TB_KIEM_TRA_AP_GIA_BAN_DIEN:
    APIDanhSachKH.CSKH_DSkh_TB_KIEM_TRA_AP_GIA_BAN_DIEN,
  CSKH_DSkh_TB_XACNHAN_SLDIEN_MT: APIDanhSachKH.CSKH_DSkh_TB_XACNHAN_SLDIEN_MT,
  CSKH_DSkh_KH_6_THANG_KHONG_DUNG: APIDanhSachKH.CSKH_DSkh_KH_6_THANG_KHONG_DUNG,
};
// bao cao kiem soat sms
const BaoCaoKiemSoatSMS = {
  BC_SAN_LUONG_TIN_NHAN_THEO_DON_VI:
    APIBAOCAOKIEMSOAT.BC_SAN_LUONG_TIN_NHAN_THEO_DON_VI,
  BC_SAN_LUONG_THEO_NGAY: APIBAOCAOKIEMSOAT.BC_SAN_LUONG_THEO_NGAY,
  BC_SAN_LUONG_TN_LOI_THEO_DV: APIBAOCAOKIEMSOAT.BC_SAN_LUONG_TN_LOI_THEO_DV,
  DANH_SACH_KH_CHUA_GUI_TIN_NHAN:
    APIBAOCAOKIEMSOAT.DANH_SACH_KH_CHUA_GUI_TIN_NHAN,
  DANH_SACH_KH_DKY_SAI_DV_HOAC_SAI_SDT_CT:
    APIBAOCAOKIEMSOAT.DANH_SACH_KH_DKY_SAI_DV_HOAC_SAI_SDT_CT,
  DANH_SACH_KH_DKY_SAI_DV_HOAC_SAI_SDT_TH:
    APIBAOCAOKIEMSOAT.DANH_SACH_KH_DKY_SAI_DV_HOAC_SAI_SDT_TH,
  DANH_SACH_KH_30_NGAY_CHUA_CAP_NHAT:
    APIBAOCAOKIEMSOAT.DANH_SACH_KH_30_NGAY_CHUA_CAP_NHAT,
  // EXCEL
  BC_SL_THEO_DV_EXCEL: APIBAOCAOKIEMSOAT.BC_SL_THEO_DV_EXCEL,
  BC_SL_THEO_NGAY_EXCEL: APIBAOCAOKIEMSOAT.BC_SL_THEO_NGAY_EXCEL,
  BC_LOI_DV_EXCEL: APIBAOCAOKIEMSOAT.BC_LOI_DV_EXCEL,
  BC_CHUA_GUI_TIN_NHAN_EXCEL: APIBAOCAOKIEMSOAT.BC_CHUA_GUI_TIN_NHAN_EXCEL,
  DS_CT_EXCEL: APIBAOCAOKIEMSOAT.DS_CT_EXCEL,
  DS_th_EXCEL: APIBAOCAOKIEMSOAT.DS_th_EXCEL,
  DS_30_NGAY_EXCEL: APIBAOCAOKIEMSOAT.DS_30_NGAY_EXCEL,
};
// baocaodoisoat smsm
const BaoCaoDoiSoatSMS = {
  B1_TH_TIN_NHAN_TOAN_CONG_TY: APIBAOCAODOISOATSMS.B1_TH_TIN_NHAN_TOAN_CONG_TY,
  B1_EXCEL: APIBAOCAODOISOATSMS.B1_EXCEL,
  B2_CT_SL_TN_THEO_CTY_DIEN_LUC:
    APIBAOCAODOISOATSMS.B2_CT_SL_TN_THEO_CTY_DIEN_LUC,
  B2_EXCEL: APIBAOCAODOISOATSMS.B2_EXCEL,
  DOITUONG_SMS_TIEN_DIEN_L1: APIBAOCAODOISOATSMS.DOITUONG_SMS_TIEN_DIEN_L1,
  DOITUONG_SMS_DICH_VU_KHAC: APIBAOCAODOISOATSMS.DOITUONG_SMS_DICH_VU_KHAC,
  B3_TH_TIN_NHAN_THEO_TUNG_NHA_MANG:
    APIBAOCAODOISOATSMS.B3_TH_TIN_NHAN_THEO_TUNG_NHA_MANG,
  B3_EXCEL: APIBAOCAODOISOATSMS.B3_EXCEL,

  B4_DOI_SOAT_SL_TIN_NHAN_THANH_CONG:
    APIBAOCAODOISOATSMS.B4_DOI_SOAT_SL_TIN_NHAN_THANH_CONG,
  B4_EXCEL: APIBAOCAODOISOATSMS.B4_EXCEL,

  B5_CHI_TIET_SL_TIN_NHAN_THANH_CONG:
    APIBAOCAODOISOATSMS.B5_CHI_TIET_SL_TIN_NHAN_THANH_CONG,
  B5_EXCEL: APIBAOCAODOISOATSMS.B5_EXCEL,
  B7_DS_TN_CHUA_GUI_KH: APIBAOCAODOISOATSMS.B7_DS_TN_CHUA_GUI_KH,
  B7_EXCEL: APIBAOCAODOISOATSMS.B7_EXCEL,
  B8_DOI_SOAT_THEO_NGAY: APIBAOCAODOISOATSMS.B8_DOI_SOAT_THEO_NGAY,
  B8_EXCEL: APIBAOCAODOISOATSMS.B8_EXCEL,
  B9_DOI_SOAT_NGAY_THEO_DON_VI:
    APIBAOCAODOISOATSMS.B9_DOI_SOAT_NGAY_THEO_DON_VI,
  B9_EXCEL: APIBAOCAODOISOATSMS.B9_EXCEL,
  // dvk tabpane
  B1_DVK_TH_TIN_NHAN_TOAN_CONG_TY:
    APIBAOCAODOISOATSMS.B1_DVK_TH_TIN_NHAN_TOAN_CONG_TY,
  B1_EXPORT_EXCEL: APIBAOCAODOISOATSMS.B1_EXPORT_EXCEL,
  B2_DVK_CT_SL_TN_THEO_CTY_DIEN_LUC:
    APIBAOCAODOISOATSMS.B2_DVK_CT_SL_TN_THEO_CTY_DIEN_LUC,
  B2_EXPORT_EXCEL: APIBAOCAODOISOATSMS.B2_EXPORT_EXCEL,
  B4_DVK_DOI_SOAT_SL_TIN_NHAN_THANH_CONG:
    APIBAOCAODOISOATSMS.B4_DVK_DOI_SOAT_SL_TIN_NHAN_THANH_CONG,
  B4_EXPORT_EXCEL: APIBAOCAODOISOATSMS.B4_EXPORT_EXCEL,
  // th tabpane
  B1_TH_TH_TIN_NHAN_TOAN_CONG_TY:
    APIBAOCAODOISOATSMS.B1_TH_TH_TIN_NHAN_TOAN_CONG_TY,
  B1_EXPORT_EXCEL_TH: APIBAOCAODOISOATSMS.B1_EXPORT_EXCEL_TH,
  B2_EXPORT_EXCEL_TH: APIBAOCAODOISOATSMS.B2_EXPORT_EXCEL_TH,
  B2_TH_CT_SL_TN_THEO_CTY_DIEN_LUC:
    APIBAOCAODOISOATSMS.B2_TH_CT_SL_TN_THEO_CTY_DIEN_LUC,
  B4_TH_DOI_SOAT: APIBAOCAODOISOATSMS.B4_TH_DOI_SOAT,
  B4_TH_DOI_SOAT_EXCEL: APIBAOCAODOISOATSMS.B4_TH_DOI_SOAT_EXCEL,
  B4_TH_DOI_SOAT_GOP_EP: APIBAOCAODOISOATSMS.B4_TH_DOI_SOAT_GOP_EP,
  B4_TH_DOI_SOAT_GOP_EP_EXCEL: APIBAOCAODOISOATSMS.B4_TH_DOI_SOAT_GOP_EP_EXCEL,
};
const BaoCaoDoiSoatEmail = {
  TH_GUI_TINH_HINH_THONG_BAO_EMAIL:
    APIBAOCAODOISOATEMAIL.TH_GUI_TINH_HINH_THONG_BAO_EMAIL,
  TH_GUI_TINH_HINH_THONG_BAO_EMAIL_EXCEL:
    APIBAOCAODOISOATEMAIL.TH_GUI_TINH_HINH_THONG_BAO_EMAIL_EXCEL,
  BC_TH_SL_THU_DIEN_TU_HOP_LE:
    APIBAOCAODOISOATEMAIL.BC_TH_SL_THU_DIEN_TU_HOP_LE,
  BC_CHI_TIET_SL_THU_DIEN_TU_HOP_LE:
    APIBAOCAODOISOATEMAIL.BC_CHI_TIET_SL_THU_DIEN_TU_HOP_LE,
  BC_TH_SL_THU_DIEN_TU_KHONG_HOP_LE:
    APIBAOCAODOISOATEMAIL.BC_TH_SL_THU_DIEN_TU_KHONG_HOP_LE,
  BC_CHI_TIET_SL_THU_DIEN_TU_KHONG_HOP_LE:
    APIBAOCAODOISOATEMAIL.BC_CHI_TIET_SL_THU_DIEN_TU_KHONG_HOP_LE,
  DANH_SACH_KH_DANG_KY_EMAIL: APIBAOCAODOISOATEMAIL.DANH_SACH_KH_DANG_KY_EMAIL,
};
const BaoCaoKySo = {
  TONG_SO_LUONG_KY_S0: APIBaoCaoKySo.TONG_SO_LUONG_KY_S0,
  TONG_SO_EXCEL: APIBaoCaoKySo.TONG_SO_EXCEL,

  CHI_TIET_SO_LUONG_KY_S0: APIBaoCaoKySo.CHI_TIET_SO_LUONG_KY_S0,
  CHI_TIET_EXCEL: APIBaoCaoKySo.CHI_TIET_EXCEL,
};
// bao cao doi soat zalo
const BaoCaoDoiSoatZalo = {
  TH_TINH_HINH_NT_TB_KH_ZALO: APIBAOCAODOISOATZALO.TH_TINH_HINH_NT_TB_KH_ZALO,
  TH_TINH_HINH_NT_TB_TIEN_DIEN_L1_ZALO:
    APIBAOCAODOISOATZALO.TH_TINH_HINH_NT_TB_TIEN_DIEN_L1_ZALO,
  CHI_TIET_TH_NT_TB_TIEN_DIEN_L1_ZALO:
    APIBAOCAODOISOATZALO.CHI_TIET_TH_NT_TB_TIEN_DIEN_L1_ZALO,
  DANH_SACH_KH_GUI_TN_THANH_CONG_TH:
    APIBAOCAODOISOATZALO.DANH_SACH_KH_GUI_TN_THANH_CONG_TH,
  DANH_SACH_KH_GUI_TN_THANH_CONG_CHI_TIET:
    APIBAOCAODOISOATZALO.DANH_SACH_KH_GUI_TN_THANH_CONG_CHI_TIET,
  DANH_SACH_KH_GUI_TN_KO_THANH_CONG_TH:
    APIBAOCAODOISOATZALO.DANH_SACH_KH_GUI_TN_KO_THANH_CONG_TH,
  DANH_SACH_KH_GUI_TN_KO_THANH_CONG_CHI_TIET:
    APIBAOCAODOISOATZALO.DANH_SACH_KH_GUI_TN_KO_THANH_CONG_CHI_TIET,
  BC_TH_TIN_NHAN: APIBAOCAODOISOATZALO.BC_TH_TIN_NHAN,
  BC_TH_TY_LE_NHAN_TIN: APIBAOCAODOISOATZALO.BC_TH_TY_LE_NHAN_TIN,
  DS_KH_DANG_KY_ZALO_TONG_HOP: APIBAOCAODOISOATZALO.DS_KH_DANG_KY_ZALO_TONG_HOP,
  DS_KH_DANG_KY_ZALO_CHI_TIET: APIBAOCAODOISOATZALO.DS_KH_DANG_KY_ZALO_CHI_TIET,

  BC_SL_KH_PHAT_TRIEN_MOI_EVNHANOI:
    APIBAOCAODOISOATZALO.BC_SL_KH_PHAT_TRIEN_MOI_EVNHANOI,
  BC_DS_KH_PHAT_TRIEN_MOI: APIBAOCAODOISOATZALO.BC_DS_KH_PHAT_TRIEN_MOI,
  // EXCEL
  B09_TB_EXCEL: APIBAOCAODOISOATZALO.B09_TB_EXCEL,
  B09_Th_EXCEL: APIBAOCAODOISOATZALO.B09_Th_EXCEL,
  B09_CT_EXCEL: APIBAOCAODOISOATZALO.B09_CT_EXCEL,
  DS_TC_TH_EXCEL: APIBAOCAODOISOATZALO.DS_TC_TH_EXCEL,
  DS_TC_CT_EXCEL: APIBAOCAODOISOATZALO.DS_TC_CT_EXCEL,
  DS_KO_TC_TH_EXCEL: APIBAOCAODOISOATZALO.DS_KO_TC_TH_EXCEL,
  DS_KO_TC_CT_EXCEL: APIBAOCAODOISOATZALO.DS_KO_TC_CT_EXCEL,
  BC_TH_TN_EXCEL: APIBAOCAODOISOATZALO.BC_TH_TN_EXCEL,
  BC_TH_TLTN_EXCEL: APIBAOCAODOISOATZALO.BC_TH_TLTN_EXCEL,
  BC_EVNHANOI_EXCEL: APIBAOCAODOISOATZALO.BC_EVNHANOI_EXCEL,
  BC_PTM_EXCEL: APIBAOCAODOISOATZALO.BC_PTM_EXCEL,
  DS_ZALO_CT_EXCEL: APIBAOCAODOISOATZALO.DS_ZALO_CT_EXCEL,
  DS_ZALO_TH_EXCEL: APIBAOCAODOISOATZALO.DS_ZALO_TH_EXCEL,
};

const BaoCaoTH = {
  BCTH_TONG_SO_LUONG_TN_QUA_CAC_HT:
    APIBaoCaoTH.BCTH_TONG_SO_LUONG_TN_QUA_CAC_HT,
  BCTH_TONG_SO_LUONG_TN_QUA_CAC_HT_EXCEL:
    APIBaoCaoTH.BCTH_TONG_SO_LUONG_TN_QUA_CAC_HT_EXCEL,
  BCTH_TONG_SO_LUONG_KH_SUDUNG_APP:
    APIBaoCaoTH.BCTH_TONG_SO_LUONG_KH_SUDUNG_APP,
  BCTH_TONG_SO_LUONG_KH_SUDUNG_APP_EXCEL:
    APIBaoCaoTH.BCTH_TONG_SO_LUONG_KH_SUDUNG_APP_EXCEL,
  BCTH_TONG_SO_KH_CAI_APP_THEO_HDH:
    APIBaoCaoTH.BCTH_TONG_SO_KH_CAI_APP_THEO_HDH,
  BCTH_TONG_SO_KH_CAI_APP_THEO_HDH_EXCEL:
    APIBaoCaoTH.BCTH_TONG_SO_KH_CAI_APP_THEO_HDH_EXCEL,
  BCTH_TONG_SO_LUONG_KH_YEU_CAU_CO_SDT:
    APIBaoCaoTH.BCTH_TONG_SO_LUONG_KH_YEU_CAU_CO_SDT,
  BCTH_TONG_SO_LUONG_KH_YEU_CAU_CO_SDT_EXCEL:
    APIBaoCaoTH.BCTH_TONG_SO_LUONG_KH_YEU_CAU_CO_SDT_EXCEL,
};

const BCDSSMSCOVID4 = {
  BCDOISOATSMSCOVID4_CHI_TIET_SO_LUONG_TIN_NHAN_THEO_TUNG_CONG_TY_DIEN_LUC:
    APIBCDOISOATSMSCovid4.BCDOISOATSMSCOVID4_CHI_TIET_SO_LUONG_TIN_NHAN_THEO_TUNG_CONG_TY_DIEN_LUC,

  BCDOISOATSMSCOVID4_DOI_SOAT_SL_TN_DA_THUC_HIEN_THANH_CONG:
    APIBCDOISOATSMSCovid4.BCDOISOATSMSCOVID4_DOI_SOAT_SL_TN_DA_THUC_HIEN_THANH_CONG,
};

const DSKHHDVSMS = {
  DSKHHDVSMS_DS_KHACH_HANG_HUY_DICH_VU_SMS:
    APIDSKHHDVSMS.DSKHHDVSMS_DS_KHACH_HANG_HUY_DICH_VU_SMS,
};

const BCDOISOATKH = {
  BCDSKH_THSL_TIN_NHAN_CUA_TOAN_TONG_CONG_TY:
    APIBCDOISOATKHACHHANG.BCDSKH_THSL_TIN_NHAN_CUA_TOAN_TONG_CONG_TY,
  BCDSKH_CTSL_TIN_NHAN_THEO_TUNG_CONG_TY_DIEN_LUC:
    APIBCDOISOATKHACHHANG.BCDSKH_CTSL_TIN_NHAN_THEO_TUNG_CONG_TY_DIEN_LUC,
};

const TNCDS = {
  TNCDS_THSL_KH_DANG_KY_NHAN_THONG_BAO_EMAIL_ZALO:
    APITNCHUYENDOISO.TNCDS_THSL_KH_DANG_KY_NHAN_THONG_BAO_EMAIL_ZALO,
  TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_EMAIL:
    APITNCHUYENDOISO.TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_EMAIL,
  TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_APP:
    APITNCHUYENDOISO.TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_APP,
  TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_EPOINT:
    APITNCHUYENDOISO.TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_EPOINT,
  TNCDS_CT_TB_TIEN_DIEN_1_LAN_GUI_EMAIL_ZALO:
    APITNCHUYENDOISO.TNCDS_CT_TB_TIEN_DIEN_1_LAN_GUI_EMAIL_ZALO,
  TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_ZALO:
    APITNCHUYENDOISO.TNCDS_CT_KH_DANG_KY_NHAN_THONG_BAO_QUA_ZALO,
  TNCDS_CT_TB_TIEN_DIEN_2_GUIQUA_EMZA8055:
    APITNCHUYENDOISO.TNCDS_CT_TB_TIEN_DIEN_2_GUIQUA_EMZA8055,
  TNCDS_CT_TB_THSL_TIN_NHAN_QUA_CAC_HINH_THUC:
    APITNCHUYENDOISO.TNCDS_CT_TB_THSL_TIN_NHAN_QUA_CAC_HINH_THUC,
  TNCDS_CT_TB_THSL_KH_DANG_KY_ID_ZALO:
    APITNCHUYENDOISO.TNCDS_CT_TB_THSL_KH_DANG_KY_ID_ZALO,
  TNCDS_CT_TB_THSL_DANG_KY_SU_DUNG_HST:
    APITNCHUYENDOISO.TNCDS_CT_TB_THSL_DANG_KY_SU_DUNG_HST,
  TNCDS_CT_TB_THSL_NHAN_THONG_TIN_QUA_ZALO:
    APITNCHUYENDOISO.TNCDS_CT_TB_THSL_NHAN_THONG_TIN_QUA_ZALO,
  TNCDS_CT_TB_KH_HUY_TB_HST: APITNCHUYENDOISO.TNCDS_CT_TB_KH_HUY_TB_HST,
  TNCDS_CT_DS_KH_QUAN_LY_CUA_CTY_DIEN_LUC:
    APITNCHUYENDOISO.TNCDS_CT_DS_KH_QUAN_LY_CUA_CTY_DIEN_LUC,
};

const BCDSDTXD = {
  BCDSDTXD_DOI_SOAT_SO_LUONG_TIN_NHAN_DA_THUC_HIEN_TC:
    APIBCDOISOATDTXD.BCDSDTXD_DOI_SOAT_SO_LUONG_TIN_NHAN_DA_THUC_HIEN_TC,
};

// Báo cáo cổng tin nhắn cskh
// Xuất excel
const BCCONGTINNHANCSKH_EXCEL = {
  BAOCAO_CONG_TINNHAN_CSKH_EXCEL:
  APIBaoCaoCongTinNhanCSKH.BC_CONG_TIN_NHAN_CSKH,
};

const BCDSSMSCOVID2 = {
  BCDOISOATSMSCOVID2_CHI_TIET_SO_LUONG_TIN_NHAN_THEO_TUNG_CONG_TY_DIEN_LUC:
    APIBCDOISOATSMSCovid2.BCDOISOATSMSCOVID2_CHI_TIET_SO_LUONG_TIN_NHAN_THEO_TUNG_CONG_TY_DIEN_LUC,

  BCDOISOATSMSCOVID2_DOI_SOAT_SL_TN_DA_THUC_HIEN_THANH_CONG:
    APIBCDOISOATSMSCovid2.BCDOISOATSMSCOVID2_DOI_SOAT_SL_TN_DA_THUC_HIEN_THANH_CONG,
};
const DSBRANDNAME = {
  BCBN_TONG_HOP_THEO_TOAN_DV: APIBAOCAO_DSBRANDNAME.BCBN_TONG_HOP_THEO_TOAN_DV,
  BCBN_TONG_HOP_THEO_TOAN_DV_EXCEL:
    APIBAOCAO_DSBRANDNAME.BCBN_TONG_HOP_THEO_TOAN_DV_EXCEL,
  BCBN_BIEU2_CHITIET: APIBAOCAO_DSBRANDNAME.BCBN_BIEU2_CHITIET,
  BCBN_BIEU2_CHITIET_EXCEL: APIBAOCAO_DSBRANDNAME.BCBN_BIEU2_CHITIET_EXCEL,
};
const DSBAOCAOEP = {
  BCBEP_TH_TIN_NHAN: APIBAOCAO_EP.BCBEP_TH_TIN_NHAN,
  BCBEP_TH_TIN_NHAN_EXCEL: APIBAOCAO_EP.BCBEP_TH_TIN_NHAN_EXCEL,
  BCBEP_CT_TIN_NHAN: APIBAOCAO_EP.BCBEP_CT_TIN_NHAN,
  BCBEP_CT_TIN_NHAN_EXCEL: APIBAOCAO_EP.BCBEP_CT_TIN_NHAN_EXCEL,
  BCBEP_TIN_NHAN_THTC: APIBAOCAO_EP.BCBEP_TIN_NHAN_THTC,
  BCBEP_TIN_NHAN_THTC_EXCEL: APIBAOCAO_EP.BCBEP_TIN_NHAN_THTC_EXCEL,
};

const TienIch = {
  TKTN_TN_SMS_TRACUU_TDLAN_1: APITIM_KIEM_TIN_NHAN.TKTN_TN_SMS_TRACUU_TDLAN_1,
  TKTN_TN_SMS_TRACUU_DICHVUKHAC_VA_DICHVU_DT:
    APITIM_KIEM_TIN_NHAN.TKTN_TN_SMS_TRACUU_DICHVUKHAC_VA_DICHVU_DT,
  TKTTKH_KH_SU_DUNG_APP: APITIM_KIEM_THONGTIN_KH.TKTTKH_KH_SU_DUNG_APP,
  TKTTKH_KH_SU_DUNG_APP_EXCEL:
    APITIM_KIEM_THONGTIN_KH.TKTTKH_KH_SU_DUNG_APP_EXCEL,
  TKTTKH_KH_SU_DUNG_EMAIL: APITIM_KIEM_THONGTIN_KH.TKTTKH_KH_SU_DUNG_EMAIL,
  TKTTKH_KH_SU_DUNG_EMAIL_EXCEL:
    APITIM_KIEM_THONGTIN_KH.TKTTKH_KH_SU_DUNG_EMAIL_EXCEL,
  TKTTKH_KH_SU_DUNG_ZALO: APITIM_KIEM_THONGTIN_KH.TKTTKH_KH_SU_DUNG_ZALO,
  TKTTKH_KH_SU_DUNG_ZALO_EXCEL:
    APITIM_KIEM_THONGTIN_KH.TKTTKH_KH_SU_DUNG_ZALO_EXCEL,
    TKTN_CONG_TIN_NHAN_CSKH_LOG_SMS: APITIM_KIEM_TIN_NHAN.TKTN_CONG_TIN_NHAN_CSKH_LOG_SMS,
    TKTN_CONG_TIN_NHAN_CSKH_LOG_EMAIL: APITIM_KIEM_TIN_NHAN.TKTN_CONG_TIN_NHAN_CSKH_LOG_EMAIL,
    TKTN_CONG_TIN_NHAN_CSKH_LOG_ZALO: APITIM_KIEM_TIN_NHAN.TKTN_CONG_TIN_NHAN_CSKH_LOG_ZALO,
};

const index = {
  ...KYSO,
  ...NhanTinChoKH,
  ...GetDanhSachSo,
  ...GetDanhSachKH,
  ...BaoCaoKiemSoatSMS,
  ...BaoCaoDoiSoatSMS,
  ...BaoCaoDoiSoatEmail,
  ...BaoCaoKySo,
  ...BaoCaoDoiSoatZalo,
  ...BaoCaoTH,
  ...BCDSSMSCOVID4,
  ...DSKHHDVSMS,
  ...BCDOISOATKH,
  ...TNCDS,
  ...Common,
  ...BCDSDTXD,
  ...BCDSSMSCOVID2,
  ...DSBRANDNAME,
  ...TienIch,
  ...DSBAOCAOEP,
  ...BCCONGTINNHANCSKH_EXCEL,
};

export default index;
