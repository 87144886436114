import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import {
  buildQueryString,
  // formatSplitDate,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { Button } from "antd";
import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import TaoMoiTiepNhanTable from "./table";
import TaoMoiKeHoachTiepNhanModal from "./modal";
import { handleList } from "./services";
import CheckPermission from "@components/CheckPermission";
import { cap_dien } from "@permissions/a_capdien";
import moment from "moment";
import { Endpoint } from "@utils/endpoint";
import { downloadFile } from "@utils/request";

export default function Index() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { user } = useSelector(authSelector);
  const [detail, setDetail] = useState({});
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);
  //filter
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    donViId: searchParams.get("donViId")
      ? searchParams.get("donViId")
      : user.unitId,
    ngayTiepNhan: moment(moment().startOf(MONTH_STRING)).format(FORMAT_ENGLISH),
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //change pagination
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  };

  //fetch list
  const getListData = useCallback(() => {
    handleList(filterConditions, setLoading, setDataSource, setTotalCount);
  }, [filterConditions]);

  const [filterExcel, setFilterExcel] = useState(false);

  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type]);

  const columns = [
    {
      title: "STT",
      key: "stt",
      dataIndex: "stt",
      render: (text, record, index) => (
        <div className="table-text-right">{index + 1}</div>
      ),
      width: "5%",
    },
    {
      title: "Đơn vị",
      key: "tenDonVi",
      dataIndex: "tenDonVi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
      sorter: true,
    },
    {
      title: "Tên khu vực phát triển mới khách hàng",
      key: "tenKhuVucPhatTrien",
      dataIndex: "tenKhuVucPhatTrien",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "20%",
      sorter: true,
    },
    {
      title: "Địa chỉ",
      key: "diaChi",
      dataIndex: "diaChi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "25%",
      sorter: true,
    },
    {
      title: "Kế hoạch tiếp nhận",
      key: "keHoachTiepNhan",
      dataIndex: "keHoachTiepNhan",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "15%",
      sorter: true,
    },
    {
      title: "Ghi chú",
      key: "ghiChu",
      dataIndex: "ghiChu",
      render: (text) => <div className="table-text-left">{text}</div>,
      // width: "25%",
      sorter: true,
    },
  ];

  //search
  const handleSearch = useCallback(
    (values) => {
      setFilterConditions(() => ({
        pageIndex: DEFAULT_PAGEINDEX,
        pageSize: DEFAULT_PAGESIZE,
        ...values,
        // donViId: values.donViId,
      }));
      handleAPI();
    },
    [handleAPI]
  );

  //clear
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
      ngayTiepNhan: moment(moment().startOf(MONTH_STRING)).format(
        FORMAT_ENGLISH
      ),
    });
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  }, [user.unitId]);

  useEffect(() => {
    if (filterExcel) {
      const endpoint = `${
        Endpoint.EXPORT_EXCEL_TAO_MOI_KE_HOACH
      }?${buildQueryString(parseParams(filterConditions))}`;
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel]);

  useEffect(() => {
    if (type === ENUM_BUTTON_TYPE.TIM_KIEM) getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListData]);

  const genExtra = () => (
    <div>
      <CheckPermission permissionCode={cap_dien.a507_them}>
        <Button
          size="small"
          type="primary"
          className="mr-5px"
          onClick={() => handleOpenModal()}
        >
          Thêm mới
        </Button>
      </CheckPermission>
    </div>
  );

  //hiển thị popup
  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDetail(row);
      else setDetail({});
      setVisible(!visible);
    },
    [visible]
  );

  //đóng popup

  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
  };

  const showModal = useMemo(
    () =>
      visible && (
        <TaoMoiKeHoachTiepNhanModal
          detail={detail}
          visible={visible}
          closeModal={handleCloseModal}
          getListData={getListData}
        />
      ),
    // eslint-disable-next-line
    [visible]
  );

  return (
    <Fragment>
      <TaoMoiTiepNhanTable
        columns={columns}
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        loading={loading}
        onChangePagination={onChangePagination}
        dataSource={dataSource}
        totalData={totalCount}
        genExtra={genExtra}
        setType={setType}
      />
      {showModal}
    </Fragment>
  );
}
