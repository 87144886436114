import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { getErrorMessage } from "@utils/function";
import { authDeleteData, authGetData, authPostData } from "@utils/request";

//Call api Get list Lý Do Treo Tháo
/**
 *
 * @param {boolean} setLoading
 * @param {array} setDataLyDoTreoThao
 * @param {int} loaiBienBan
 */
export const handleGetDataLyDoTreoThao = (
  setLoading,
  setDataLyDoTreoThao,
  loaiBienBan
) => {
  authGetData({
    url: `${Endpoint.GET_LY_DO_TREO_THAO}?LoaiBienBan=${loaiBienBan}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setDataLyDoTreoThao(res.data);
      } else {
        setDataLyDoTreoThao([]);
      }
    },
  });
};

/**
 *
 * @param {@boolean} setLoadingLyDo
 * @param {*} payload
 * @param {*} handleCloseModal
 * @param {*} setFetchList
 * @param {*} setSelectedRowKeys
 * @param {*} formLyDo
 */
export const handleUpdateLydo = (
  setLoading,
  payload,
  handleCloseModalLyDo,
  setFetchList,
  setSelectedRowKeys,
  formLyDo
) => {
  authPostData({
    url: Endpoint.UPDATE_LY_DO_TREO_THAO,
    method: METHOD_PUT,
    payload,
    setLoading: setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
        setSelectedRowKeys([]);
        handleCloseModalLyDo();
      } else {
        getErrorMessage(res, formLyDo);
      }
    },
  });
};

export const callAPIRemoveYC = (
  selectedRowKeys,
  setLoading,
  setFetchList,
  setSelectedRowKeys
) => {
  authDeleteData({
    url: Endpoint.REMOVE_YC_TREO_THAO + "?ids=" + selectedRowKeys,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchList(true);
        setSelectedRowKeys([]);
      }
    },
    content: "Bạn chắc rằng muốn xóa yêu cầu này?",
    title: "Xóa dữ liệu",
  });
};
