import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import {
  Button,
  Cascader,
  Checkbox,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  Spin,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import DatePickerComponent from "@components/DatePicker";
import {
  handleDownload,
  optionsCN,
  optionsKTB,
  SelectKhoThietBi,
  selectTHTBDD2C,
} from "./service";
import { Option } from "antd/lib/mentions";
import { SHOW_CHILD } from "rc-tree-select";

export default function BaoCaoTBDD2Cap() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);
  const [bienDong, setBienDong] = useState(false);
  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Báo cáo thiết bị đo đếm 2 cấp
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              initialValues={{
                reportType: Endpoint.BCCT_SLTB_DD_TON_DUOI_LUOI,
                SoNgay: 60,
                unitId: user.user.unitCode,
                KiemDinh: "ALL",
                BienDong: bienDong,
                KhoThietBi: ["ALL"],
                CongNghe: ["ALL"],
                ViTri: "B",
                LoaiCongTo: "ALL",
              }}
              colon={false}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
            >
              <Form.Item
                name="reportType"
                label="Báo cáo"
                labelAlign="left"
                required
              >
                <Select placeholder="Tất cả" options={selectTHTBDD2C}></Select>
              </Form.Item>
              <Form.Item
                name="unitId"
                label="Đơn vị"
                labelAlign="left"
                required
              >
                <Selection
                  url={Endpoint.GET_TEAM_LIST_CODE}
                  formKey="unitId"
                  form={form}
                />
              </Form.Item>
              {/* Từ Ngày */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  (getFieldValue("reportType") !==
                    Endpoint.BCCT_SLTB_DD_TON_DUOI_LUOI &&
                    getFieldValue("reportType") !==
                      Endpoint.BCCT_SLTB_DD_TON_DUOI_LUOI_CKD &&
                    getFieldValue("reportType") !==
                      Endpoint.BCCT_SLTB_DD_TON_CHUA_SU_DUNG &&
                    getFieldValue("reportType") !==
                      Endpoint.BCCT_SLTB_DD_TON_QUA_60_NGAY &&
                    getFieldValue("reportType") !==
                      Endpoint.BCCT_SLTB_DD_DANG_VAN_HANH_TL &&
                    getFieldValue("reportType") !==
                      Endpoint.BCCT_SLCL_CONG_TO_TD_SAU_TBA_CC &&
                    getFieldValue("reportType") !==
                      Endpoint.BCCT_THTH_VAN_HANH_TBDD_TREN_LUOI &&
                    getFieldValue("reportType") !==
                      Endpoint.BCCT_THTB_TON_KHO) ||
                  bienDong === true ? (
                    <Form.Item
                      name="TuNgay"
                      label="Từ ngày"
                      labelAlign="left"
                      required
                    >
                      <DatePickerComponent
                        formKey="TuNgay"
                        form={form}
                        picker="day"
                        format={"YYYY-MM-DD"}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              {/* Đến Ngày */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  (getFieldValue("reportType") !==
                    Endpoint.BCCT_SLCL_CONG_TO_TD_SAU_TBA_CC &&
                    getFieldValue("reportType") !==
                      Endpoint.BCCT_THTH_VAN_HANH_TBDD_TREN_LUOI &&
                    getFieldValue("reportType") !==
                      Endpoint.BCCT_THTB_TON_KHO) ||
                  bienDong === true ? (
                    <Form.Item
                      name="DenNgay"
                      label="Đến ngày"
                      labelAlign="left"
                      required
                    >
                      <DatePickerComponent
                        picker="day"
                        formKey="DenNgay"
                        form={form}
                        format={"YYYY-MM-DD"}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              {/* Số ngày */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") ===
                    Endpoint.BCCT_SLTB_DD_TON_DUOI_LUOI ||
                  getFieldValue("reportType") ===
                    Endpoint.BCCT_SLTB_DD_TON_DUOI_LUOI_CKD ||
                  getFieldValue("reportType") ===
                    Endpoint.BCCT_SLTB_DD_TON_CHUA_SU_DUNG ||
                  getFieldValue("reportType") ===
                    Endpoint.BCCT_SLTB_DD_TON_QUA_60_NGAY ? (
                    <Form.Item
                      name="SoNgay"
                      label="Số ngày"
                      labelAlign="left"
                      required
                    >
                      <InputNumber formKey="SoNgay" />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              {/* Kiểm định */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") ===
                    Endpoint.BCCT_SLTB_DD_TON_DUOI_LUOI ||
                  getFieldValue("reportType") ===
                    Endpoint.BCCT_SLTB_DD_TON_DUOI_LUOI_CKD ||
                  getFieldValue("reportType") ===
                    Endpoint.BCCT_SLTB_DD_TON_CHUA_SU_DUNG ||
                  getFieldValue("reportType") ===
                    Endpoint.BCCT_SLTB_DD_TON_QUA_60_NGAY ||
                  getFieldValue("reportType") ===
                    Endpoint.BCCT_SLTB_DD_DANG_VAN_HANH_TL ? (
                    <Form.Item
                      name="KiemDinh"
                      label="Kiểm định"
                      labelAlign="left"
                      required
                    >
                      <Select
                        placeholder="Chọn loại kiểm định"
                        formKey="KiemDinh"
                      >
                        <Option value="ALL">Tất cả</Option>
                        <Option value="1">Đạt</Option>
                        <Option value="2">Không đạt</Option>
                      </Select>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              {/* Kho thiết bị, Công nghệ */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") ===
                    Endpoint.BCCT_SLTB_DD_TON_DUOI_LUOI ||
                  getFieldValue("reportType") ===
                    Endpoint.BCCT_SLTB_DD_TON_DUOI_LUOI_CKD ||
                  getFieldValue("reportType") ===
                    Endpoint.BCCT_SLTB_DD_TON_CHUA_SU_DUNG ||
                  getFieldValue("reportType") ===
                    Endpoint.BCCT_SLTB_DD_TON_QUA_60_NGAY ? (
                    <Form.Item
                      name="KhoThietBi"
                      label="Kho thiết bị"
                      labelAlign="left"
                      required
                    >
                      <SelectKhoThietBi
                        url={Endpoint.BCCT_DANH_MUC_KHO_THIET_BI}
                        formKey="KhoThietBi"
                        form={form}
                      ></SelectKhoThietBi>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") ===
                    Endpoint.BCCT_THTH_VAN_HANH_TBDD_TREN_LUOI ||
                  getFieldValue("reportType") === Endpoint.BCCT_THTB_TON_KHO ||
                  getFieldValue("reportType") ===
                    Endpoint.BCCT_THTB_DUOI_LUOI ? (
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          name="KhoThietBi"
                          label="Kho thiết bị"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 8 }}
                          required
                        >
                          <Cascader
                            placeholder="Chọn Kho thiết bị"
                            options={optionsKTB}
                            multiple
                            maxTagCount="responsive"
                            showCheckedStrategy={SHOW_CHILD}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          name="CongNghe"
                          label="Công nghệ"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 12 }}
                          required
                        >
                          <Cascader
                            placeholder="Chọn công nghệ"
                            multiple
                            options={optionsCN}
                            maxTagCount="responsive"
                            showCheckedStrategy={SHOW_CHILD}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : null
                }
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") ===
                  Endpoint.BCCT_TBDD_DEN_HAN_KD ? (
                    /* Công nghệ ,vị trí */
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          name="CongNghe"
                          label="Công nghệ"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 12 }}
                          required
                        >
                          <Cascader
                            placeholder="Chọn công nghệ"
                            multiple
                            options={optionsCN}
                            maxTagCount="responsive"
                            showCheckedStrategy={SHOW_CHILD}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          name="ViTri"
                          label="Vị Trí"
                          labelAlign="left"
                          required
                        >
                          <Select placeholder="Tất cả">
                            <Option value="B">Trên Lưới</Option>
                            <Option value="E">Dưới lưới</Option>
                            <Option value="A">Trong Kho</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : null
                }
              </Form.Item>

              {/* Loại công tơ */}

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") ===
                  Endpoint.BCCT_SLTB_DD_DANG_VAN_HANH_TL_DHKD ? (
                    <Form.Item
                      name="LoaiCongTo"
                      label="Loại công tơ"
                      labelAlign="left"
                      required
                    >
                      <Select
                        placeholder="Chọn loại công tơ"
                        formKey="LoaiCongTo"
                      >
                        <Option value="ALL">Tất cả</Option>
                        <Option value="KH">Khách hàng</Option>
                        <Option value="DN">Đầu nguồn</Option>
                      </Select>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              {/* Ngày biến động */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") ===
                    Endpoint.BCCT_SLCL_CONG_TO_TD_SAU_TBA_CC ||
                  getFieldValue("reportType") ===
                    Endpoint.BCCT_THTH_VAN_HANH_TBDD_TREN_LUOI ||
                  getFieldValue("reportType") === Endpoint.BCCT_THTB_TON_KHO ? (
                    <Form.Item
                      name="BienDong"
                      label="Ngày biến động gần nhất"
                      labelAlign="left"
                      required
                      valuePropName="checked"
                    >
                      <Checkbox
                        formKey="BienDong"
                        value={bienDong}
                        onChange={(e) => setBienDong(e.target.checked)}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              <Row>
                <Col span={4}></Col>
                <Col span={15}>
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>

                  {/* <Button
                    type="primary"
                    loading={loading}
                    className="ml-20px"
                    onClick={() => {
                      handleOpenPdf(form.getFieldValue(), setLoading);
                    }}
                  >
                    In báo cáo
                  </Button> */}
                </Col>
              </Row>
            </FormComponent>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
