import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";

import LoadingComponent from "@components/Loading";
import { QUAN_LY_LOG } from "@utils/constants";
import { convertUtcToLocalTime } from "@utils/function";
import { ChiTietYeuCauStyle } from "./css-styled";
import { getChiTiet } from "./services";
export default function QuanLyLogDetail(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const { detailHoSo, activeKey, filterConditions } = props;
  const { id } = detailHoSo;
  // lay danh sach chi tiet yeu cau
  useEffect(() => {
    getChiTiet({setLoading, setData, activeKey, id, filterConditions});
  }, [activeKey, detailHoSo, filterConditions]);
  return (
    <LoadingComponent loading={loading}>
      {/* thông tin hồ sơ */}
      <ChiTietYeuCauStyle>
        {activeKey === QUAN_LY_LOG.HE_THONG_NGOAI && (
          <Row gutter={24}>
            <Col className="field_wrap" span={12}>
              <h5 className="fs-14px c-t-label">Thời gian bắt đầu:</h5>
              <h5 className="fs-14px font-bold">{convertUtcToLocalTime(data?.startTimeStamp, "YYYY-MM-DD HH:mm:ss")}</h5>
            </Col>
            <Col className="field_wrap" span={12}>
              <h5 className="fs-14px c-t-label">Thời gian kết thúc:</h5>
              <h5 className="fs-14px font-bold">{convertUtcToLocalTime(data?.finishTimeStamp, "YYYY-MM-DD HH:mm:ss")}</h5>
            </Col>
            <Col className="field_wrap" span={14}>
              <h5 className="fs-14px c-t-label">Url:</h5>
              <h5 className="fs-14px font-bold">{data?.path || ""}</h5>
            </Col>
            <Col className="field_wrap" span={4}>
              <h5 className="fs-14px c-t-label">Method:</h5>
              <h5 className="fs-14px font-bold">{data?.method || ""}</h5>
            </Col>
            <Col className="field_wrap" span={12}>
              <h5 className="fs-14px c-t-label">Request:</h5>
              <h5 className="fs-14px font-bold break-word break-word">{data?.requestBody || ""}</h5>
            </Col>
            <Col className="field_wrap" span={12}>
              <h5 className="fs-14px c-t-label">Response:</h5>
              <h5 className="fs-14px font-bold break-word break-word">{data?.responseBody || ""}</h5>
            </Col>
            <Col className="field_wrap" span={6}>
              <h5 className="fs-14px c-t-label">HttpCode:</h5>
              <h5 className="fs-14px font-bold break-word">{data?.statusCode || ""}</h5>
            </Col>
            <Col className="field_wrap" span={6}>
              <h5 className="fs-14px c-t-label">User name:</h5>
              <h5 className="fs-14px font-bold break-word">{data?.userName || ""}</h5>
            </Col>
            <Col className="field_wrap" span={12}>
              <h5 className="fs-14px c-t-label">Exception:</h5>
              <h5 className="fs-14px font-bold break-word break-word">{data?.exceptionMessage || ""}</h5>
            </Col>
            <Col className="field_wrap" span={12}>
              <h5 className="fs-14px c-t-label">Log url:</h5>
              <h5 className="fs-14px font-bold break-word break-word"><a href={data?.logUrl || ""} target="_blank" rel="noreferrer">{data?.logUrl || ""}</a></h5>
            </Col>
          </Row>
        )}
        {activeKey === QUAN_LY_LOG.NOI_BO && (
          <Row gutter={24}>
            <Col className="field_wrap" span={8}>
              <h5 className="fs-14px c-t-label">Thời gian:</h5>
              <h5 className="fs-14px font-bold">{convertUtcToLocalTime(data?.timeStamp, "YYYY-MM-DD HH:mm:ss")}</h5>
            </Col>
            <Col className="field_wrap" span={12}>
              <h5 className="fs-14px c-t-label">Path:</h5>
              <h5 className="fs-14px font-bold break-word">{data?.path || ""}</h5>
            </Col>
            <Col className="field_wrap" span={4}>
              <h5 className="fs-14px c-t-label">Method:</h5>
              <h5 className="fs-14px font-bold">{data?.method || ""}</h5>
            </Col>
            <Col className="field_wrap" span={12}>
              <h5 className="fs-14px c-t-label">Request body:</h5>
              <h5 className="fs-14px font-bold break-word">{data?.requestBody?.slice(0, 5000) || ""}</h5>
            </Col>
            <Col className="field_wrap" span={12}>
              <h5 className="fs-14px c-t-label">Response body:</h5>
              <h5 className="fs-14px font-bold break-word">{data?.responseBody?.slice(0, 5000) || ""}</h5>
            </Col>
            <Col className="field_wrap" span={8}>
              <h5 className="fs-14px c-t-label">Status code:</h5>
              <h5 className="fs-14px font-bold break-word">{data?.statusCode || ""}</h5>
            </Col>
            <Col className="field_wrap" span={8}>
              <h5 className="fs-14px c-t-label">User name:</h5>
              <h5 className="fs-14px font-bold break-word">{data?.userName || ""}</h5>
            </Col>
            <Col className="field_wrap" span={8}>
              <h5 className="fs-14px c-t-label">Os:</h5>
              <h5 className="fs-14px font-bold break-word">{data?.os || ""}</h5>
            </Col>
            <Col className="field_wrap" span={8}>
              <h5 className="fs-14px c-t-label">Appversion:</h5>
              <h5 className="fs-14px font-bold break-word">{data?.appVersion || ""}</h5>
            </Col>
            <Col className="field_wrap" span={8}>
              <h5 className="fs-14px c-t-label">Build number:</h5>
              <h5 className="fs-14px font-bold break-word">{data?.buildNumber || ""}</h5>
            </Col>
            <Col className="field_wrap" span={8}>
              <h5 className="fs-14px c-t-label">Log url:</h5>
              <h5 className="fs-14px"><a href={data?.logUrl || ""} target="_blank" rel="noreferrer">{data?.logUrl || ""}</a></h5>
            </Col>
          </Row>
        )}
        {activeKey === QUAN_LY_LOG.HE_THONG && (
          <Row gutter={24}>
            <Col className="field_wrap" span={8}>
              <h5 className="fs-14px c-t-label">Thời gian:</h5>
              <h5 className="fs-14px font-bold">{convertUtcToLocalTime(data?.timeStamp, "YYYY-MM-DD HH:mm:ss")}</h5>
            </Col>
            <Col className="field_wrap" span={16}>
              <h5 className="fs-14px c-t-label">Exception:</h5>
              <h5 className="fs-14px font-bold break-word">{data?.exception || ""}</h5>
            </Col>
            <Col className="field_wrap" span={8}>
              <h5 className="fs-14px c-t-label">Message:</h5>
              <h5 className="fs-14px font-bold">{data?.message || ""}</h5>
            </Col>
          </Row>
        )}
      </ChiTietYeuCauStyle>
    </LoadingComponent>
  );
}
