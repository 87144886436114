import React, { useEffect, useRef, useState } from "react";
import { Form } from "antd";
import {
  FORMAT_TIME,
  ENUM_MODE_PHUONGANCAPDIEN,
  MAX_INT,
  ERROR_MAX_INT,
} from "@utils/constants";
import moment from "moment";
import { isEqual } from "lodash";
import {
  getChiPhiByHTLD,
  getListDayDanDien,
  getTongCongSuat,
  handleGetPhuongAnCapDien,
  handleGetThongTinCoBan,
  handleMultiTabsThongTin,
  handlePhuongGiaoDich,
  handlePhuongXaSuDung,
  handleUpdatePhuongAnCapDien,
} from "../../../services";
import PhuongAnCapDienTabs from "./tabs-phuong-an-cap-dien";
import { scrollToTop } from "@utils/function";
import {
  notSavedPhuongAnCapDien,
  updateTongChiPhi,
  setLoadingTinhChiPhi,
  changeHinhThucLapDat,
} from "@state/cap-dien/nhan-vien-khao-sat/lap-phuong-an/reducer";
import { useDispatch } from "react-redux";

export default function Index(props) {
  const [form] = Form.useForm();
  const {
    detailLapPhuongAn,
    mode,
    dataPAXemLai,
    loading,
    setLoading,
    t,
    initPACD,
    setInitPACD,
    indexTab,
  } = props;
  const [listLo, setListLo] = useState([]);
  const [listTram, setListTram] = useState([]);
  const [listQuyen, setListQuyen] = useState([]);
  const [dataPhuongAn, setDataPhuongAn] = useState({});
  const [flag, setFlag] = useState(false);
  const [listHinhThucLapDat, setListHinhThucLapDat] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const elRef = useRef(null);
  const dispatch = useDispatch();
  const [loadingDayDanDien, setLoadingDDD] = useState(false);
  const [listDayDanDien, setListDayDanDien] = useState({
    content: "",
    duongDayDiens: [],
  });
  // eslint-disable-next-line no-unused-vars
  const [loadingSubmit, setLoadingSubmit] = useState(true);
  const [dataDiaChinh, setDataDiaChinh] = useState([]);
  const [dataSoPha, setDataSoPha] = useState([]);
  const [dataTinhTrangSuDung, setDataTinhTrangSuDung] = useState([]);
  const [dataHinhThucTraTien, setDataHinhThucTraTien] = useState([]);
  const [dataTinhTrangThu, setDataTinhTrangThu] = useState([]);
  const [dataHinhThucGhiChiSo, setDataHinhThucGhiChiSo] = useState([]);
  const [dataLoaiHopDong, setDataLoaiHopDong] = useState([]);
  const [dataGioiTinhKH, setDataGioiTinhKhachHang] = useState([]);
  const [dataLanhDao, setDataLanhDao] = useState([]);
  const [dataMucDich, setDataMucDich] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [dataThongTinCoBan, setDataThongTinCoBan] = useState({});
  const [dataXaPhuongSuDung, setDataXaPhuongSuDung] = useState([]);
  const [dataXaPhuongGiaoDich, setDataXaPhuongGiaoDich] = useState([]);
  const [isChangeSoDo, setChangeSoDo] = useState(false);

  const getDataPhuongAnCapDien = () => {
    handleGetPhuongAnCapDien(
      detailLapPhuongAn,
      dataPAXemLai,
      mode,
      setDataPhuongAn,
      setLoading,
      setListTram,
      setListLo,
      setListQuyen,
      setListHinhThucLapDat,
      setFlag
    );
  };

  // //thay đổi quận huyện call api xã phường

  const handleChangeDiaChinhSuDung = (value) => {
    handlePhuongXaSuDung(setLoading, value, setDataXaPhuongSuDung, form);
  };

  // //thay đổi quận huyện call api xã phường

  const handleChangeDiaChinhGiaoDich = (value) => {
    handlePhuongGiaoDich(setLoading, value, setDataXaPhuongGiaoDich, form);
  };
  useEffect(() => {
    if (dataThongTinCoBan?.quanHuyenGiaoDichId)
      handleChangeDiaChinhGiaoDich(dataThongTinCoBan.quanHuyenGiaoDichId);
    // eslint-disable-next-line
  }, [dataThongTinCoBan]);

  useEffect(() => {
    if (dataThongTinCoBan?.quanHuyenSuDungId)
      handleChangeDiaChinhSuDung(dataThongTinCoBan.quanHuyenSuDungId);
    // eslint-disable-next-line
  }, [dataThongTinCoBan]);

  useEffect(() => {
    handleGetThongTinCoBan(detailLapPhuongAn, setLoading, setDataThongTinCoBan);
    // eslint-disable-next-line
  }, [detailLapPhuongAn]);

  useEffect(() => {
    setChangeSoDo(false);
    if (mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI) {
      scrollToTop(elRef);
    }
    form.resetFields();
    getDataPhuongAnCapDien();
    setLoaded(true);
    // eslint-disable-next-line
  }, [detailLapPhuongAn]);

  useEffect(() => {
    if (initPACD && mode === ENUM_MODE_PHUONGANCAPDIEN.XEMTRENTAB) {
      form.setFieldsValue(initialValues);
      setInitPACD(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initPACD]);

  useEffect(() => {
    if (flag) {
      if (dataPhuongAn && Object.keys(dataPhuongAn).length > 0) {
        form.setFieldsValue({
          ...dataPhuongAn,
          ngayMucTaiCaoNhat: dataPhuongAn.ngayMucTaiCaoNhat
            ? moment(dataPhuongAn.ngayMucTaiCaoNhat)
            : "",
          _thoiGianThiCongTu: dataPhuongAn.thoiGianThiCongTu
            ? moment(dataPhuongAn.thoiGianThiCongTu)
            : "",
          _thoiGianThiCongDen: dataPhuongAn.thoiGianThiCongDen
            ? moment(dataPhuongAn.thoiGianThiCongDen)
            : "",
          _ngayThiCong: dataPhuongAn.thoiGianThiCongTu
            ? moment(dataPhuongAn.thoiGianThiCongTu)
            : "",
          soDoCapDien:
            dataPhuongAn.soDoCapDien &&
            Object.keys(dataPhuongAn.soDoCapDien).length &&
            dataPhuongAn.soDoCapDien.filePath
              ? {
                  fileName: dataPhuongAn.soDoCapDien.fileName,
                  base64Data: `${dataPhuongAn.soDoCapDien.filePath}`,
                }
              : null,
        });
        if (mode === ENUM_MODE_PHUONGANCAPDIEN.XEMTRENTAB) {
          setInitialValues(form.getFieldsValue());
        }
      }
      setLoaded(true);
      setFlag(false);
    }
  }, [dataPhuongAn, flag, form, mode]);

  const handleFieldChange = () => {
    if (mode === ENUM_MODE_PHUONGANCAPDIEN.XEMTRENTAB) {
      if (isEqual(initialValues, form.getFieldsValue())) {
        dispatch(notSavedPhuongAnCapDien(false));
      } else {
        dispatch(notSavedPhuongAnCapDien(true));
      }
    }
  };

  const handleSubmit = (values) => {
    if (!form.getFieldsError().filter(({ errors }) => errors.length).length) {
      if (
        (values.tongCongSuatPhanKhangToiDa &&
          values.tongCongSuatPhanKhangToiDa > MAX_INT) ||
        (values.congSuat2204 && values.congSuat2204 > MAX_INT) ||
        (values.congSuat0409 && values.congSuat0409 > MAX_INT) ||
        (values.congSuat0911 && values.congSuat0911 > MAX_INT) ||
        (values.congSuat1117 && values.congSuat1117 > MAX_INT) ||
        (values.congSuat1720 && values.congSuat1720 > MAX_INT) ||
        (values.congSuat2022 && values.congSuat2022 > MAX_INT)
      ) {
        const errors = [];

        if (values.tongCongSuatPhanKhangToiDa > MAX_INT) {
          errors.push({
            name: "tongCongSuatPhanKhangToiDa",
            errors: [ERROR_MAX_INT],
          });
        }
        if (values.congSuat2204 > MAX_INT) {
          errors.push({
            name: "congSuat2204",
            errors: [ERROR_MAX_INT],
          });
        }
        if (values.congSuat0409 > MAX_INT) {
          errors.push({
            name: "congSuat0409",
            errors: [ERROR_MAX_INT],
          });
        }
        if (values.congSuat0911 > MAX_INT) {
          errors.push({
            name: "congSuat0911",
            errors: [ERROR_MAX_INT],
          });
        }
        if (values.congSuat1117 > MAX_INT) {
          errors.push({
            name: "congSuat1117",
            errors: [ERROR_MAX_INT],
          });
        }
        if (values.congSuat1720 > MAX_INT) {
          errors.push({
            name: "congSuat1720",
            errors: [ERROR_MAX_INT],
          });
        }
        if (values.congSuat2022 > MAX_INT) {
          errors.push({
            name: "congSuat2022",
            errors: [ERROR_MAX_INT],
          });
        }

        form.setFields([...errors]);
        form.scrollToField(errors[0]?.name);
        return;
      }
      //call api
      handleUpdatePhuongAnCapDien(
        convertDataThoiGianThiCong,
        values,
        detailLapPhuongAn,
        setLoading,
        setInitialValues,
        (value) => {
          setChangeSoDo(false);
          dispatch(notSavedPhuongAnCapDien(value));
        },
        form,
        (data) => {
          if (data && data.hasOwnProperty("hinhThucLapDat")) {
            dispatch(changeHinhThucLapDat(true));
          } else {
            dispatch(changeHinhThucLapDat(false));
          }
          scrollToTop(elRef);
        }
      );
    }
  };

  const convertDataThoiGianThiCong = (values) => {
    const {
      _thoiGianThiCongDen,
      _thoiGianThiCongTu,
      _ngayThiCong,
      ngayMucTaiCaoNhat,
    } = values;

    const ngayThiCongRaw = moment(_ngayThiCong).format("YYYY-MM-DD");
    const tuRaw = moment(_thoiGianThiCongTu).format(FORMAT_TIME);
    const denRaw = moment(_thoiGianThiCongDen).format(FORMAT_TIME);

    delete values["_thoiGianThiCongTu"];
    delete values["_thoiGianThiCongDen"];
    delete values["_ngayThiCong"];

    if (!isChangeSoDo) {
      delete values["soDoCapDien"];
    }

    return {
      ...values,
      thoiGianThiCongTu: _thoiGianThiCongTu
        ? _ngayThiCong
          ? moment(`${ngayThiCongRaw} ${tuRaw}`).format("YYYY-MM-DDTHH:mm:ss")
          : moment(_thoiGianThiCongTu).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      thoiGianThiCongDen: _thoiGianThiCongDen
        ? _ngayThiCong
          ? moment(`${ngayThiCongRaw} ${denRaw}`).format("YYYY-MM-DDTHH:mm:ss")
          : moment(_thoiGianThiCongDen).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      ngayMucTaiCaoNhat: ngayMucTaiCaoNhat
        ? moment(ngayMucTaiCaoNhat).format()
        : null,
    };
  };

  useEffect(() => {
    const hinhThucLapDatId = form.getFieldValue("hinhThucLapDat");
    if (hinhThucLapDatId !== undefined || indexTab === "4") {
      getChiPhiByHTLD(
        hinhThucLapDatId,
        detailLapPhuongAn,
        (value) => {
          dispatch(updateTongChiPhi(value));
        },
        (loading) => dispatch(setLoadingTinhChiPhi(loading)),
        t
      );
      getListDayDanDien(
        detailLapPhuongAn,
        (values) => setListDayDanDien(values),
        setLoadingDDD
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue("hinhThucLapDat"), indexTab]);

  useEffect(() => {
    return () => dispatch(updateTongChiPhi(undefined));
  }, [dispatch]);

  useEffect(() => {
    if (indexTab === "4" && loaded) {
      getTongCongSuat(
        detailLapPhuongAn,
        () => false,
        (values) => {
          if (values && Object.keys(values)?.length > 0) {
            form.setFieldsValue({
              tongCongSuatTacDungToiDa: values.tongCongSuatTacDungToiDa,
            });
          }
        }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexTab]);

  useEffect(() => {
    handleMultiTabsThongTin(
      mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI
        ? detailLapPhuongAn.id
        : detailLapPhuongAn.hoSoId,
      setLoadingSubmit,
      setDataDiaChinh,
      setDataSoPha,
      setDataTinhTrangSuDung,
      setDataHinhThucGhiChiSo,
      setDataLoaiHopDong,
      setDataHinhThucTraTien,
      setDataTinhTrangThu,
      setDataGioiTinhKhachHang,
      setDataLanhDao,
      setDataMucDich,
      mode
    );
    // eslint-disable-next-line
  }, [detailLapPhuongAn]);

  return (
    <div ref={elRef}>
      <PhuongAnCapDienTabs
        loading={loading}
        form={form}
        handleSubmit={handleSubmit}
        handleFieldChange={handleFieldChange}
        mode={mode}
        listTram={listTram}
        listLo={listLo}
        listQuyen={listQuyen}
        listHinhThucLapDat={listHinhThucLapDat}
        dataPhuongAn={dataPhuongAn}
        loadingDayDanDien={loadingDayDanDien}
        listDayDanDien={listDayDanDien}
        detailLapPhuongAn={detailLapPhuongAn}
        setChangeSoDo={setChangeSoDo}
        dataSoPha={dataSoPha}
        dataTinhTrangSuDung={dataTinhTrangSuDung}
        dataHinhThucGhiChiSo={dataHinhThucGhiChiSo}
        dataLoaiHopDong={dataLoaiHopDong}
        dataGioiTinhKH={dataGioiTinhKH}
        dataLanhDao={dataLanhDao}
        dataTinhTrangThu={dataTinhTrangThu}
        dataMucDich={dataMucDich}
        dataDiaChinh={dataDiaChinh}
        dataHinhThucTraTien={dataHinhThucTraTien}
        dataThongTinCoBan={dataThongTinCoBan}
        dataXaPhuongGiaoDich={dataXaPhuongGiaoDich}
        dataXaPhuongSuDung={dataXaPhuongSuDung}
      />
    </div>
  );
}
