import { Endpoint } from "@utils/endpoint";
import { authPostData, authGetData, authDeleteData } from "@utils/request";
import {
  buildQueryString,
} from "@utils/function";
import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";

export const submitCauHinhCToThao = (
  setLoading,
  ngay,
  percent,
  getListDataCauHinh,
  handleCloseModal
) => {
  authPostData({
    url: Endpoint.SAVE_CAU_HINH_CHI_SO,
    method: METHOD_POST,
    payload: {
      ngayApDung: ngay,
      chenhLechSanLuong: percent,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        getListDataCauHinh()
        handleCloseModal()
      }
    },
  });
};
export const updateCauHinhCToThao = (
  setLoading,
  id,
  ngay,
  percent,
  getListDataCauHinh,
  handleCloseModal

) => {
  authPostData({
    url: Endpoint.UPDATE_CAU_HINH_CHI_SO,
    method: METHOD_PUT,
    payload: {
      id: id,
      ngayApDung: ngay,
      chenhLechSanLuong: percent,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        getListDataCauHinh()
        handleCloseModal()
      }
    },
  });
};

// get cấu hình chỉ số
export const getCauHinhchiSo = (
  setLoading,
  filterConditions,
  setDataCauHinh,
  setTotalCount
) => {
  let url = "";
  url = `${Endpoint.LIST_CAU_HINH_CHI_SO}?${buildQueryString(filterConditions)}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataCauHinh(res.data);
        setTotalCount(res.paging.totalCount)
      } else setDataCauHinh();
    },
  });
};
export const submitDeleteCauHinh = (
  id,
  setLoading,
  getListDataCauHinh,
) => {
  authDeleteData({
    url: Endpoint.DELETE_CAU_HINH_CHI_SO + id,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) getListDataCauHinh();
    },
  });
};