import LoadingComponent from "@components/Loading";
import { ConfigProvider, Table } from "antd";
import vn from "antd/es/locale-provider/vi_VN";
import moment from "moment";
import { Fragment, memo, useEffect, useState } from "react";
import { ContainerTab } from "../css-styled";
import { getLichSuThaoTac } from "../services";

function LichSuThaoTacTab({ detailHoSo }) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();

  const { id } = detailHoSo;

  // lay danh sach chi tiet yeu cau
  useEffect(() => {
    getLichSuThaoTac(setLoading, id, setData);
  }, [id]);

  // khai báo columns
  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      align: "center",
      width: "10%",
    },
    {
      title: "Công việc",
      dataIndex: "congViec",
      key: "congViec",
      align: "left",
      width: "30%",
    },
    {
      title: "Ngày thao tác",
      key: "ngayThaoTac",
      align: "center",
      render: (text) => <>{moment(text).format("DD/MM/YYYY")}</>,
      width: "15%",
    },
    {
      title: "Người thao tác",
      dataIndex: "nguoiThaoTac",
      key: "nguoiThaoTac",
      align: "left",
      width: "15%",
    },
    {
      title: "Nội dung",
      dataIndex: "noiDung",
      key: "noiDung",
      align: "left",
      width: "30%",
    },
  ];

  return (
    <Fragment>
      <LoadingComponent loading={loading}>
        <ConfigProvider locale={vn}>
          <ContainerTab>
            <Table
              dataSource={data}
              columns={columns}
              pagination={false}
              scroll={{ y: 700 }}
            />
          </ContainerTab>
        </ConfigProvider>
      </LoadingComponent>
    </Fragment>
  );
}

export default memo(LichSuThaoTacTab);
