import { Form, Input, Button, Modal, Row, Col, InputNumber } from "antd";
import FormComponent from "@components/Form";

export default function UIBangKeThietBi(props) {
  const {
    detailBangKeThietBi,
    t,
    visible,
    handleCloseModal,
    form,
    handleSubmit,
  } = props;
  return (
    <Modal
      title={
        detailBangKeThietBi.id
          ? t("lap_phuong_an.thiet_bi.cap_nhat_bang_ke_thiet_bi")
          : t("lap_phuong_an.thiet_bi.tao_bang_ke_thiet_bi")
      }
      visible={visible}
      onCancel={handleCloseModal}
      className="modal-thiet-bi"
      footer={null}
      width={700}
    >
      <FormComponent
        form={form}
        name="form-thiet-bi"
        onFinish={handleSubmit}
        initialValues={{
          ...detailBangKeThietBi,
          congSuat: detailBangKeThietBi.id
            ? detailBangKeThietBi.congSuatValue
            : detailBangKeThietBi.congSuat,
        }}
        layout="vertical"
        scrollToFirstError
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col span={24} md={24}>
            <Form.Item
              name="mucDichSuDung"
              className="required-field"
              label={t("lap_phuong_an.thiet_bi.muc_dich_su_dung")}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24} md={24}>
            <Form.Item
              label={t("lap_phuong_an.thiet_bi.ten_thiet_bi")}
              name="tenThietBi"
              className="required-field"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24} md={24}>
            <Form.Item
              label={t("lap_phuong_an.thiet_bi.dien_ap_su_dung")}
              name="dienApSuDung"
              className="required-field"
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col span={24} md={24}>
            <Form.Item
              label={t("lap_phuong_an.thiet_bi.cong_suat")}
              name="congSuat"
              className="required-field"
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label={t("lap_phuong_an.thiet_bi.so_luong")}
              name="soLuong"
              className="required-field"
            >
              <Input
                type="number"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item label={"Hệ số đồng thời"} name="heSoDongThoi">
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} justify="center">
          <Col span={12} style={{ textAlign: "right" }}>
            <Button
              key="back"
              onClick={handleCloseModal}
              className="button-closed"
            >
              {t("button.dong_lai")}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              key="submit"
              type="primary"
              className="button-primary"
              htmlType="submit"
              form="form-thiet-bi"
            >
              {detailBangKeThietBi.id
                ? t("button.cap_nhat")
                : t("button.tao_moi")}
            </Button>
          </Col>
        </Row>
      </FormComponent>
    </Modal>
  );
}
