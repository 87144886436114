import React, { memo, useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { handleReturnHoSo } from "./services";
import TraHoSoModal from "./modal-tra-ho-so";

function Index({
  detail,
  visible,
  closeModal,
  title,
  setFetchDataTable,
  contentMessage,
  typeTraLai
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  //submit
  const onFinish = (values) => {
    //submit dữ liệu
    handleReturnHoSo(
      values,
      detail,
      setLoading,
      setFetchDataTable,
      closeModal,
      form,
      typeTraLai
    );
  };

  return (
    <TraHoSoModal
      loading={loading}
      title={title}
      visible={visible}
      closeModal={closeModal}
      form={form}
      onFinish={onFinish}
      t={t}
      contentMessage={contentMessage}
      typeTraLai={typeTraLai}
    />
  );
}

export default memo(Index);
