import { BASE_API_URL_TREO_THAO } from "@utils/constants";
export const APILanhDaoTreoThao = {
  //api ký duyệt
  UPDATE_LANH_DAO_KY_DUYET_XAC_NHAN: `${BASE_API_URL_TREO_THAO}/lanh-dao/xac-nhan-yc`,
  UPDATE_LANH_DAO_KY_DUYET_KY_SO_USB: `${BASE_API_URL_TREO_THAO}/lanh-dao/ky-duyet/ky-so-usb`,
  UPDATE_LANH_DAO_KY_DUYET_HASH_KY_SO_USB: `${BASE_API_URL_TREO_THAO}/lanh-dao/ky-duyet/ky-so-get-hash`,
  //api Xem biên bản
  //GET_LANH_DAO_BIEN_BAN: `${BASE_API_URL_TREO_THAO}/lanh-dao/xem-bien-ban`,
  //api duyệt phiếu
  GET_LANH_DAO_DUYET_PHIEU_XEM_PHIEU: `${BASE_API_URL_TREO_THAO}/lanh-dao/duyet-phieu/xem-phieu`,
  UPDATE_LANH_DAO_DUYET_PHIEU_KY_SO_USB: `${BASE_API_URL_TREO_THAO}/lanh-dao/duyet-phieu/ky-so-usb`,
  UPDATE_LANH_DAO_DUYET_PHIEU_HASH_KY_SO_USB: `${BASE_API_URL_TREO_THAO}/lanh-dao/duyet-phieu/ky-so-get-hash`,
  LANH_DAO_XAC_NHAN_PHIEU_VT: `${BASE_API_URL_TREO_THAO}/lanh-dao/xac-nhan-phieu`,
  LANH_DAO_TRA_LAI_PHIEU_TREO_THAO: `${BASE_API_URL_TREO_THAO}/lanh-dao/tra-phieu`,
};
