import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authGetMultiplesRequest, authPostData } from "@utils/request";

export const handleMultiNhanVien = (
  setLoading,
  setDataTreoThao,
  setDataNiemPhong
) => {
  const endpoints = [``, ``];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDataTreoThao(res[0].data.data);
      setDataNiemPhong(res[1].data.data);
    },
  });
};
export const handleLamMoi = (
  setLoading,
  selectedRowKeys,
  setFetchList,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.LAM_MOI_YEU_CAU_TREO_THAO,
    setLoading,
    method: METHOD_PUT,
    payload: {
      yeuCauIds: selectedRowKeys.toString(),
    },
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
        setSelectedRowKeys([]);
      }
    },
  });
};
