import {
  ENUM_LOAI_DS_YEU_CAU,
  METHOD_POST,
  METHOD_PUT,
  STATUSCODE_200,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  authDeleteData,
  authPostData,
  authGetMultiplesRequest,
  authGetData,
} from "@utils/request";

export const handleListData = (
  url,
  funcId,
  filterConditions,
  setLoading,
  setDataSource,
  setTotalCount
) => {
  authPostData({
    url: `${url}/${funcId}`,
    method: METHOD_POST,
    payload: filterConditions,
    setLoading,
    onSuccess: (res) => {
      if (res.data && res.statusCode === STATUSCODE_200) {
        setDataSource(res.data);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};

export const getListMulti = (
  setLoading,
  setLoaiPhieu,
  setTrangThai,
  setDonVi,
  setLoaiTreoThao,
  funcId
) => {
  const endpoints = [
    `${Endpoint.COMMON_LIST_LOAI_PHIEUVT}`,
    `${Endpoint.COMMON_LIST_TRANGTHAI_PHIEUVT}?funcId=${funcId}`,
    `${Endpoint.COMMON_LIST_LOAI_TREO_THAO}?Type=${ENUM_LOAI_DS_YEU_CAU.DANH_SACH_3}`,
    `${Endpoint.GET_UNIT_LIST}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      if (res.length > 0) {
        setLoaiPhieu(res[0].data.data);
        setTrangThai(res[1].data.data);
        setLoaiTreoThao(res[2].data.data);
        setDonVi(res[3].data.data);
      }
    },
  });
};

export const handleRemoveData = (id, setLoading, setFetchList) => {
  const url = Endpoint.DELETE_PHIEU_VAT_TU + "?id=" + id;
  authDeleteData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) setFetchList(true);
    },
  });
};

export const handleXacNhanPhieu = (
  setLoading,
  payloadXN,
  urlXN,
  setFetchList
) => {
  authPostData({
    url: urlXN,
    method: METHOD_PUT,
    payload: payloadXN,
    setLoading,
    onSuccess: (res) => {
      if (res.data && res.statusCode === STATUSCODE_200) {
        setFetchList(true);
      }
    },
  });
};

export const handleGetTienTrinh = (id, setLoading, setDataTT, setIsShowTT) => {
  authGetData({
    url: Endpoint.GET_TIEN_TRINH_PHIEU_VAT_TU + "?id=" + id,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataTT(res.data);
        setIsShowTT(true);
      } else setIsShowTT(false);
    },
  });
};
