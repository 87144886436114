import {
  authGetData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { METHOD_PUT, STATUSCODE_200, METHOD_POST } from "@utils/constants";
import { alertMessage } from "@utils/function";
// get danh sách vật tư quyết toán khách hàng

export const handleListQuyetToanKh = (
  hoSoId,
  setLoading,
  setListQuyetToanKh,
  setDataQuyetToanKh
) => {
  authGetData({
    url: `${Endpoint.GET_LAP_BANG_QUYET_TOAN_KH}?HoSoId=${hoSoId}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataQuyetToanKh(res.data);
        setListQuyetToanKh(res.data.quyetToanVatTuKhachHangs);
      } else {
        setDataQuyetToanKh({});
        setListQuyetToanKh([]);
      }
    },
  });
};

export const handleOpenXemLaiPACDService = (
  url,
  setDataPAXemLai,
  setVisibleXemLaiPhuongAnCapDien,
  visibleXemLaiPhuongAnCapDien,
  detail
) => {
  const endpoints = [
    url,
    `${Endpoint.KS_TONG_CONG_SUAT}?HoSoId=${detail?.hoSoId ?? detail?.id}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading: () => false,
    onSuccess: (res) => {
      if (res[0]?.data !== null && res[1]?.data !== null) {
        setDataPAXemLai({ ...res[0].data.data, ...res[1].data.data });
        setVisibleXemLaiPhuongAnCapDien(!visibleXemLaiPhuongAnCapDien);
      }
    },
  });
};

// hoàn thành quyết toán khách hàng

export const handleHoanThanhQuyetToanKh = (
  hoSoId,
  setLoading,
  handleCloseModal,
  setFetchDataTable,
  setDisableButton
) => {
  authPostData({
    url: Endpoint.HOAN_THANH_QUYET_TOAN_KH,
    method: METHOD_POST,
    setLoading,
    payload: hoSoId,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModal();
        setDisableButton(true);
      } else {
        alertMessage("error", "Thông báo", res.message);
      }
    },
  });
};

// Trả lại quyết toán công ty

export const handleTraLaiQuyetToanCongTy = (
  hoSoId,
  setLoading,
  closeModal,
  setFetchDataTable
) => {
  authPostData({
    url: Endpoint.TRA_LAI_QUYET_TOAN,
    method: METHOD_POST,
    setLoading,
    payload: hoSoId,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        closeModal();
      } else {
        alertMessage("error", "Thông báo", res.message);
      }
    },
  });
};

//submit form

export const handleSubmitService = (
  values,
  detailLapQuyetToanCongTy,
  setLoading,
  handleCloseModal,
  getListDanhSach,
  t,
  detailHoSo
) => {
  const data = {
    ...values,
    hoSoId: detailHoSo.id,
    isVatTuKH: detailLapQuyetToanCongTy.isVatTuKH,
  };
  if (detailLapQuyetToanCongTy.id) {
    data.id = detailLapQuyetToanCongTy.id;
  }
  handleUpdate(data, setLoading, handleCloseModal, getListDanhSach, t);
};

//Update data

export const handleUpdate = (
  data,
  setLoading,
  getListDanhSach,
  handleCloseModal,
  t
) => {
  authPostData({
    url: `${Endpoint.UPDATE_VAT_TU_QUYET_TOAN_KH}`,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200 && res.data) {
        getListDanhSach();
        handleCloseModal();
      } else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};
