import { Fragment, useCallback, useLayoutEffect, useState, useEffect, useMemo } from "react";
import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { 
  DEFAULT_PAGEINDEX, 
  DEFAULT_PAGESIZE 
} from "@utils/constants";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilterTraCuu } from "./list-bo-loc";
import { Container, TableStyled } from "./css-styled";
import { 
  handleListDataThongKe
} from "./services";
import LichSuSoModal from "../common/modal/lich-su-so/modal-lich-su-so";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";

const ThongKeTable = () => {
  const { user } = useSelector(authSelector);
  const [dataThongKe, setDataThongKe] = useState([]);
  const [, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [visibleLichSu, setVisibleLichSu] = useState(false);
  const [lichGCSId, setLichGCSId] = useState();
  const [tenSoGCS, setTenSoGCS] = useState();
  const [ky, setKy] = useState();
  const [thangNam, setThangNam] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //pagination table  
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //tìm kiếm dữ liệu
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
      MaSoGCS: values.MaSoGCS === undefined ? values.MaSoGCS : values.MaSoGCS.trim(),
    }));
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
    });
  }, [user.unitId]);

  //fetch dữ liệu
  const getListDataThongKe = useCallback(() => {
    handleListDataThongKe(
      setLoading,
      location,
      setDataThongKe,
      setTotalCount,
      filterConditions,
    )
  }, [location, filterConditions]);

  useEffect(() => {
    getListDataThongKe();
  }, [getListDataThongKe]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListDataThongKe();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListDataThongKe, setFetchDataTable]);

  const columns = [
    {
      title: "Mã sổ",
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      render: (text, record) => (
        <div className="table-text-left">
          <span 
            style={{textDecoration: "underline", color: "blue", cursor: "pointer"}}
            onClick={()=> handleOpenModal(record.id, record.tenSoGCS, record.ky, record.thangNam)}>{text}</span>
        </div>
      ),
      width: "2%",
      sorter: true,
    },
    {
      title: "Tên sổ",
      dataIndex: "tenSoGCS",
      key: "tenSoGCS",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
      sorter: true,
    },
    {
      title: "Số lượng KH",
      dataIndex: "slkh",
      key: "slkh",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "5%",
    },
    {
      title: "Kỳ GCS",
      dataIndex: "ky",
      key: "ky",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "4%",
      sorter: true,
    },
    {
      title: "Tháng năm",
      dataIndex: "thangNam",
      key: "thangNam",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "4%",
    },
    {
      title: "Hình thức",
      dataIndex: "hinhThuc",
      key: "hinhThuc",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "4%",
    },
    {
      title: "Trạng thái",
      dataIndex: "tenTrangThai",
      key: "tenTrangThai",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
  ];

  //open popup
  const handleOpenModal = (lichGCSId, tenSoGCS, ky, thangNam) => {
    setVisibleLichSu(true);
    setLichGCSId(lichGCSId);
    setTenSoGCS(tenSoGCS);
    setKy(ky);
    setThangNam(thangNam);
  };

  //đóng popup
  const handleCloseModal = () => {
    setVisibleLichSu(false);
    setLoading(false);
  };

  //render popup
  const showModal = useMemo(
    () =>
      visibleLichSu && (
        <LichSuSoModal
          visible={visibleLichSu}
          closeModal={handleCloseModal}
          lichGCSId={lichGCSId}
          tenSoGCS={tenSoGCS}
          ky={ky}
          thangNam={thangNam}
        />
      ),
    [visibleLichSu, lichGCSId, tenSoGCS, ky, thangNam]
  );

  return (
    <Fragment>
      <Filter>
        <ListFilterTraCuu
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
        />
      </Filter>
      <Container>
        <TableStyled>
          <TableComponent
            header="Bảng thống kê"
            dataSource={dataThongKe}
            columns={customColumn(columns, filterConditions)}
            totalData={totalCount}
            loading={loading}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            scrollY={400}
          />
        </TableStyled>
      </Container>
      {showModal}
    </Fragment>
  );
}
export default ThongKeTable;

