import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import {
  alertMessage,
  buildQueryString,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import { handleListColumns, handleListData } from "./services";
import ChiTietHinhThucLapDat from "./table";
import moment from "moment";
import { downloadFile } from "@utils/request";
import { Endpoint } from "@utils/endpoint";

export default function Index() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector(authSelector);
  const [dataHinhThucLapDat, setDataHinhThucLapDat] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);
  //filter
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    donViId: user.unitId,
    tinhTrangTiepNhan: searchParams.get("tinhTrangTiepNhan")
      ? searchParams.get("tinhTrangTiepNhan")
      : "1",
    soPha: searchParams.get("soPha")
      ? searchParams.get("soPha")
      : user.isTongCongTy
      ? "1"
      : undefined,
    startDateBaoCao: moment(moment().startOf(MONTH_STRING)).format(
      FORMAT_ENGLISH
    ),
    endDateBaoCao: moment().format(FORMAT_ENGLISH),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //change pagination
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  };

  const getListHinhThucLapDat = useCallback(() => {
    handleListData(
      location,
      filterConditions,
      setLoading,
      setDataHinhThucLapDat,
      setTotalCount
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const [filterExcel, setFilterExcel] = useState(false);

  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type]);

  //render table con
  // const expandedRowRender = () => {
  //   const columns = [
  //     { title: "Tỷ số biến Ti", dataIndex: "", key: "", width: "5%" },
  //     { title: "Mục đích sử dụng", dataIndex: "", key: "", width: "5%" },
  //     { title: "Cáp ngầm SL", dataIndex: "", key: "", width: "5%" },
  //     { title: "Cáp ngầm", dataIndex: "", key: "", width: "5%" },
  //     { title: "DDK - (mét)", dataIndex: "", key: "", width: "3.33%" },
  //     { title: "DDK - Chi phí", dataIndex: "", key: "", width: "3.33%" },
  //     { title: "TBA - Công suất", dataIndex: "", key: "", width: "3.33%" },
  //     { title: "TBA - Chi phí", dataIndex: "", key: "", width: "6.67%" },
  //     { title: "Các loại chi phí", dataIndex: "", key: "", width: "6.67%" },
  //     { title: "Chi phí (VNĐ)", dataIndex: "", key: "", width: "6.67%" },
  //     { title: "Tổng chi phí", dataIndex: "", key: "", width: "5%" },
  //     { title: "Chiều dài cáp sau công", dataIndex: "", key: "", width: "5%" },
  //     {
  //       title: "Chủng loại cáp sau công",
  //       dataIndex: "",
  //       key: "",
  //       width: "5%",
  //     },
  //     {
  //       title: "Tổng chi phí (Trước thuế)",
  //       dataIndex: "",
  //       key: "",
  //       width: "8%",
  //     },
  //     { title: "Thời gian giả quyết", dataIndex: "", key: "", width: "8%" },
  //     { title: "Ghi chú", dataIndex: "", key: "", width: "14%" },
  //   ];
  //   return <Table columns={columns} dataSource={[]} pagination={false} />;
  // };

  //columns cha
  // const columns = [
  //   { title: "STT", dataIndex: "sTT", key: "sTT", width: "3%" },
  //   { title: "Đơn vị", dataIndex: "donVi", key: "donVi", width: "5%" },
  //   { title: "Đội", dataIndex: "doi", key: "doi", width: "5%" },
  //   {
  //     title: "Tên khách hàng",
  //     dataIndex: "tenKhachHang",
  //     key: "tenKhachHang",
  //     width: "10%",
  //   },
  //   {
  //     title: "Nguồn tiếp nhận",
  //     dataIndex: "nguonTiepNhan",
  //     key: "maYeuCau",
  //     width: "7%",
  //   },
  //   {
  //     title: "Loại hợp đồng",
  //     dataIndex: "loaiHopDongId",
  //     key: "loaiHopDongId",
  //     width: "10%",
  //   },
  //   {
  //     title: "Hình thức lắp đặt",
  //     dataIndex: "hinhThucLapDatId",
  //     key: "hinhThucLapDatId",
  //     width: "10%",
  //   },
  //   { title: "Số pha", dataIndex: "soPha", key: "soPha", width: "5%" },
  //   { title: "Chi phí", dataIndex: "chiPhi", key: "chiPhi", width: "5%" },
  //   {
  //     title: "Hình thức nộp tiền",
  //     dataIndex: "hinhThucNopTienId",
  //     key: "hinhThucNopTienId",
  //     width: "5%",
  //   },
  //   {
  //     title: "Công suất tối đa",
  //     dataIndex: "congSuatToiDa",
  //     key: "congSuatToiDa",
  //     width: "5%",
  //   },
  //   {
  //     title: "Có trở ngại",
  //     dataIndex: "troNgai",
  //     key: "troNgai",
  //     width: "5%",
  //   },
  //   { title: "Tổng số ngày thực hiện", dataIndex: "", key: "", width: "10%" },
  //   {
  //     title: "Tổng số ngày thực hiện (ngày TN- ngày NT)",
  //     dataIndex: "",
  //     key: "",
  //     width: "10%",
  //   },
  //   { title: "Tình trạng", dataIndex: "", key: "", width: "5%" },
  // ];

  //search

  //search
  const handleSearch = useCallback(
    (values) => {
      if (values.startDateBaoCao <= values.endDateBaoCao) {
        setFilterConditions(() => ({
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
          ...values,
          //donViId: values.donViId,
        }));
        handleAPI();
      } else {
        alertMessage("error", "Thông báo", "Khoảng ngày báo cáo không hợp lệ");
      }
    },
    [handleAPI]
  );

  //clear
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
      soPha: user.isTongCongTy ? "1" : undefined,
      tinhTrangTiepNhan: "1",
      startDateBaoCao: moment(moment().startOf(MONTH_STRING)).format(
        FORMAT_ENGLISH
      ),
      endDateBaoCao: moment().format(FORMAT_ENGLISH),
    });
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  }, [user]);

  useEffect(() => {
    if (filterExcel) {
      const endpoint = `${
        Endpoint.EXPORT_EXCEL_CHI_TIET_THEO_HTLD
      }?${buildQueryString(parseParams(filterConditions))}`;
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel]);

  useEffect(() => {
    if (type === ENUM_BUTTON_TYPE.TIM_KIEM) getListHinhThucLapDat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListHinhThucLapDat]);

  const [dataColumns, setDataColumns] = useState([]);
  //fetch columns
  const getListColumns = useCallback(() => {
    handleListColumns(setLoading, setDataColumns, dispatch);
  }, [dispatch]);

  useEffect(() => {
    getListColumns();
  }, [getListColumns]);
  const renderComponent = useCallback(() => {
    if (dataColumns.length > 0)
      return (
        <ChiTietHinhThucLapDat
          header={"Báo cáo chi tiết hình thức lắp đặt"}
          columns={dataColumns}
          dataSource={dataHinhThucLapDat}
          filterConditions={filterConditions}
          totalCount={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          getListColumns={getListColumns}
          clearFilter={clearFilter}
          handleSearch={handleSearch}
          setType={setType}
        />
      );
  }, [
    clearFilter,
    dataColumns,
    dataHinhThucLapDat,
    filterConditions,
    getListColumns,
    handleSearch,
    loading,
    totalCount,
  ]);

  return <Fragment>{renderComponent()}</Fragment>;
}
