import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";

export const getListDataB2 = (
  payload,
  setLoading,
  setDataB2,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.B2_CT_SL_TN_THEO_CTY_DIEN_LUC}?${buildQueryString(
      parseParams(payload)
    )}`,

    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataB2(
          res.data.map((item, index) => {
            return {
              ...item,
              STT: index + 1,
            };
          })
        );
        setTotalCount(res.data.paging);
      }
    },
  });
};
export const handleDownload = (data, filterConditions, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      ...filterConditions,
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};
