import { Menu } from "antd";
import TaiAnhHoSoKhachHang from "../../common-ho-so/tai-anh-ho-so-khach-hang";
import ModalCapNhatHoSo from "../../common-ho-so/cap-nhat";
import {
  LITMIT_TRANGTHAI,
  SEQUENCE_TRANGTHAI,
  MATRIX_CAP_DIEN,
} from "@utils/constants";
import CheckStatus from "@components/CheckStatus";
import { cap_dien } from "@permissions/a_capdien";
import CheckPermission from "@components/CheckPermission";
import XemLaiBBLapDatComponent from "@modules/cap-dien/common-ho-so/xem-bien-ban-lap-dat";

const { SubMenu } = Menu;

export default function ListMenu(props) {
  const {
    t,
    dispatch,
    openModal,
    detail,
    setFetchDataTable,
    closeModal,
    handleOpenModalPhanCong,
    handleOpenModalChuyenApGia,
    handleOpenModalChuyenDonViKhac,
    handleOpenModalInHoSoCMIS,
    handleOpenModalXemLaiPhuongAnCapDien,
    handleOpenModalQuyetToanKhachHang,
    handleOpenModalQuyetToanCongTy,
    handleOpenModalInBieuKhaiGia,
    inBienBanApGia,
    handleOpenXoaHS,
    handleOpenInPhuTai,
  } = props;
  return (
    <Menu mode="vertical" style={{ width: "100%" }} selectedKeys={["0"]}>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.CAPNHAT}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.TIEPNHANPHANCONG[1]}
        permissionCode={cap_dien.a12_sua}
      >
        <Menu.Item
          eventKey="1"
          onClick={() =>
            dispatch(
              openModal({
                content: (
                  <ModalCapNhatHoSo
                    detail={detail}
                    setFetchDataTable={setFetchDataTable}
                    closeModal={closeModal}
                  />
                ),
                size: "large",
                title: "Cập nhật thông tin hồ sơ",
              })
            )
          }
        >
          {t("common_cap_dien.cap_nhat")}
        </Menu.Item>
      </CheckStatus>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.PHANCONG}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.TIEPNHANPHANCONG[1]}
        permissionCode={cap_dien.a12_phan_cong}
      >
        <Menu.Item eventKey="2" onClick={handleOpenModalPhanCong}>
          {t("common_cap_dien.phan_cong")}
        </Menu.Item>
      </CheckStatus>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.XACNHANCHUYENAPGIA}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.TIEPNHANPHANCONG[1]}
        permissionCode={cap_dien.a12_xn_chuyen_ap_gia}
      >
        <Menu.Item eventKey="3" onClick={handleOpenModalChuyenApGia}>
          {t("common_cap_dien.xac_nhan_chuyen_ap_gia")}
        </Menu.Item>
      </CheckStatus>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.TAIANH}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.TIEPNHANPHANCONG[1]}
        permissionCode={cap_dien.a12_tai_anh_hskh}
      >
        <Menu.Item
          eventKey="4"
          onClick={() =>
            dispatch(
              openModal({
                content: (
                  <TaiAnhHoSoKhachHang
                    closeModal={closeModal}
                    detail={detail}
                    setFetchDataTable={setFetchDataTable}
                  />
                ),
                size: "large",
              })
            )
          }
        >
          {" "}
          {t("common_cap_dien.tai_anh_ho_so_khach_hang")}
        </Menu.Item>
      </CheckStatus>

      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.CHUYENDONVI}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.TIEPNHANPHANCONG[1]}
        permissionCode={cap_dien.a12_chuyen_don_vi}
      >
        <Menu.Item eventKey="5" onClick={handleOpenModalChuyenDonViKhac}>
          {t("common_cap_dien.chuyen_don_vi_khac")}
        </Menu.Item>
      </CheckStatus>
      <CheckPermission permissionCode={cap_dien.a12_in_hs_cmis}>
        <Menu.Item eventKey="7" onClick={handleOpenModalInHoSoCMIS}>
          {" "}
          {t("common_cap_dien.in_ho_so_cmis")}
        </Menu.Item>
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a12_in_hs_web}>
        {Number(detail.maTrangThai) >= LITMIT_TRANGTHAI ? (
          <SubMenu eventKey="sub1" title={t("common_cap_dien.in_ho_so_web")}>
            <Menu.Item key="8" onClick={handleOpenModalXemLaiPhuongAnCapDien}>
              {" "}
              {t("common_cap_dien.xem_lai_phuong_an_cap_dien")}
            </Menu.Item>
            <XemLaiBBLapDatComponent idHoSo={detail?.id ?? detail?.hoSoId} />
            <Menu.Item eventKey="20" onClick={handleOpenInPhuTai}>
              In biểu đồ phụ tải
            </Menu.Item>
            <Menu.Item key="9" onClick={handleOpenModalQuyetToanKhachHang}>
              {" "}
              {t("common_cap_dien.quyet_toan_khach_hang")}
            </Menu.Item>
            <Menu.Item key="10" onClick={handleOpenModalQuyetToanCongTy}>
              {" "}
              {t("common_cap_dien.quyet_toan_cong_ty")}
            </Menu.Item>
            <Menu.Item key="11" onClick={handleOpenModalInBieuKhaiGia}>
              {" "}
              {t("common_cap_dien.in_bieu_khai_gia")}
            </Menu.Item>
            <Menu.Item key="12" onClick={inBienBanApGia}>
              {" "}
              {t("common_cap_dien.in_bien_ban_ap_gia")}
            </Menu.Item>
          </SubMenu>
        ) : null}
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a11_xoa_hs}>
        <CheckStatus
          sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.XOA}
          maTrangThai={detail?.maTrangThai}
          matrix={MATRIX_CAP_DIEN.TIEPNHANPHANCONG[1]}
        >
          <Menu.Item eventKey="13" onClick={handleOpenXoaHS}>
            {"Xóa hồ sơ"}
          </Menu.Item>
        </CheckStatus>
      </CheckPermission>
    </Menu>
  );
}
