import DatePicker from "@components/DatePicker";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Form, InputNumber, Modal, Row } from "antd";
import { t } from "i18next";
import React, { memo, useState } from "react";
import { handleUpdateData } from "../service";

export function ModalCapNhatCongSuatDYCTGEVNHN(props) {
  const { visible, closeModal, getListData, detailDataTable } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  console.log(detailDataTable);

  const onFinish = (values) => {
    const url = Endpoint.BC_NCPT_SUA_CONG_SUAT_YEU_CAU;
    const [year, month, day] = values.ngaytietgiam.split("-");
    const ngaytietgiam = [day, month, year].join("/");
    const payload = {
      ...values,
      ngaytietgiam: ngaytietgiam,
      id: detailDataTable.ID,
    };
    handleUpdateData(payload, setLoading, closeModal, getListData, form, url);
  };

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title="Sửa thông tin"
        visible={visible}
        onCancel={closeModal}
        width={1200}
        footer={null}
      >
        <FormComponent
          onFinish={onFinish}
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          name="form-cong-suat"
          initialValues={{
            ...detailDataTable,
            ca1: detailDataTable.CA1,
            ca2: detailDataTable.CA2,
            ca3: detailDataTable.CA3,
            csuat_tietgiam: detailDataTable.CONGSUAT_TIETGIAM,
          }}
          colon={false}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                name="ngaytietgiam"
                label="Ngày tiết giảm"
                labelAlign="left"
                required
              >
                <DatePicker
                  picker="date"
                  formKey="ngaytietgiam"
                  form={form}
                  format={"YYYY-MM-DD"}
                  defaultValue={detailDataTable.NGAYTIETGIAM}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="csuat_tietgiam"
                label="Công suất tiết giảm"
                labelAlign="left"
                required
              >
                <InputNumber placeholder="Nhập công suất tiết giảm" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="ca1"
                label="Ca 1(22h-6h)"
                labelAlign="left"
                required
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="ca2"
                label="Ca 2 (6h-14h)"
                labelAlign="left"
                required
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="ca3"
                label="Ca 3 (14h-22h)"
                labelAlign="left"
                required
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>

          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-cong-suat"
                loading={loading}
              >
                Lưu
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(ModalCapNhatCongSuatDYCTGEVNHN);
