import ImageMultipleUpload from "@components/ImageMultipleUpload";
import { Button, Image, Modal } from "antd";
import imagePDF from "../../../../../assets/images/image-pdf.png";

export default function UploadImage(props) {
  const {
    visible,
    closeModal,
    handleUploadImage,
    loading,
    fileToDataUri,
    imageFiles,
    setImageFiles,
    isSubmit,
    isUpload,
    setIsUpload,
  } = props;

  function openPDF() {
    const pdfWindow = window.open("")
    pdfWindow.document.write(
      `<iframe width='100%' height='100%' src='${imageFiles[0].base64Data}'></iframe>`
    )
  }

  return (
    <Modal
      title="Upload BBAG giấy"
      visible={visible}
      onCancel={closeModal}
      footer={null}
      width={"50%"}
    >
      <ImageMultipleUpload
        setImageFiles={setImageFiles}
        imageFiles={imageFiles}
        multiple={false}
        fileToDataUri={fileToDataUri}
        isUpload={isUpload}
        isSubmit={isSubmit}
        setIsUpload={setIsUpload}
      >
        <div
          style={{
            width: "40%",
            height: 50,
            background: "#fafafa",
            border: "1px dashed #d9d9d9",
            textAlign: "center",
            lineHeight: "50px",
          }}
        >
          Chọn tệp
        </div>
      </ImageMultipleUpload>
      {imageFiles && imageFiles.length > 0 ? (
        <div>
          <h3
            style={{
              fontSize: "18px",
              color: "green",
              paddingTop: "12px",
            }}
          >
            {imageFiles[0].name}
          </h3>
          <div
            style={{
              width: "30%",
              height: "30%",
              paddingTop: "12px",
              margin: "0 auto",
            }}
          >
            {imageFiles[0].type === "application/pdf" ? (
              <Image
                src={imagePDF}
                width={"100%"}
                height={"100%"}
                preview={false}
                onClick={openPDF}
                style={{
                  cursor: "pointer"
                }}
              />
            ) : (
              <Image
                src={imageFiles[0].base64Data}
                width={"100%"}
                height={"100%"}
                preview={true}
              />
            )}
          </div>
        </div>
      ) : null}

      <div style={{ marginTop: "24px", textAlign: "center" }}>
        <Button
          type="default"
          className="mr-24px"
          onClick={closeModal}
        >
          Đóng lại
        </Button>
        <Button type="primary" onClick={handleUploadImage} loading={loading}>
          Tải BBAG
        </Button>
      </div>
    </Modal>
  );
}
