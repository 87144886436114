import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import {
  createVatTuCongTy,
  getListVatTuTheoNgayTiepNhan,
  updateVatTuCongTy,
} from "../../../../services";
import UIVatTuCongTy from "./ui-modal/vat-tu-cong-ty";

export default function VatTuCongTyModal({
  visible,
  detailVatTu,
  closeModal,
  detailLapPhuongAn,
  refreshTable,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [listVatTuTheoNgay, setListVatTu] = useState([]);

  useEffect(() => {
    getListVatTuTheoNgayTiepNhan(
      detailLapPhuongAn,
      setLoading,
      setListVatTu,
      t
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailLapPhuongAn]);

  const handleSubmitData = (values) => {
    const data = {
      ...values,
      hoSoId: detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id,
      id:
        detailVatTu && Object.keys(detailVatTu) && detailVatTu.id
          ? detailVatTu.id
          : null,
    };
    handleCreateUpdate(data);
  };

  //xử lý call api sửa/tạo

  const handleCreateUpdate = (data) => {
    if (data.id) {
      //sửa  vật tư

      updateVatTuCongTy(data, setLoading, refreshTable, closeModal, form);
    } else {
      //tạo  vật tư

      createVatTuCongTy(data, refreshTable, setLoading, closeModal, form);
    }
  };

  return (
    <UIVatTuCongTy
      visible={visible}
      closeModal={closeModal}
      form={form}
      t={t}
      handleSubmitData={handleSubmitData}
      detailVatTu={detailVatTu}
      loading={loading}
      listVatTuTheoNgay={listVatTuTheoNgay}
    />
  );
}
