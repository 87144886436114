import { THONG_TIN_CONG_TO } from "@utils/constants";
import React, { Fragment } from "react";
export default function CheckBCSCongTo(props) {
  const { sequenceCongTo, csCongTo, isKenhGiao } = props;
  let isDisable = true;
  if (
    csCongTo === undefined ||
    sequenceCongTo === undefined ||
    isKenhGiao === undefined
  )
    return null;

  const data = isKenhGiao
    ? THONG_TIN_CONG_TO.KENH_GIAO[csCongTo]
    : THONG_TIN_CONG_TO.KENH_NHAN[csCongTo];

  const final = data[`${csCongTo}`]; //[3,4]

  if (Array.isArray(sequenceCongTo) && Array.isArray(final)) {
    sequenceCongTo.forEach((value) => {
      if (final.includes(value)) {
        isDisable = false;
      }
    });
  }

  if (isDisable) return null;

  return <Fragment>{props.children}</Fragment>;
}
