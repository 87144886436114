import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
export const namespace = "quanTriNguoiDung";
const INITAL_STATE = {
  permissionAssignedUserList: {
    dataSource: [],
    loading: false,
    userId: null,
    totalData: 0,
    filterConditions: {
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    },
  },
  menuList: {
    data: [],
    loading: false,
  },
};

const slice = createSlice({
  name: namespace,
  initialState: INITAL_STATE,
  reducers: {
    getPermissionDataAssignedForUser: (state, action) => {
      const { filterConditions, userId } = action.payload;
      return {
        ...state,
        permissionAssignedUserList: {
          dataSource: [],
          loading: true,
          totalData: 0,
          filterConditions,
          userId,
        },
      };
    },
    savePermissionAssignedList: (state, action) => {
      return {
        ...state,
        permissionAssignedUserList: {
          ...state.permissionAssignedUserList,
          dataSource: action.payload.data,
          totalData: action.payload.paging.totalCount,
          loading: false,
        },
      };
    },
  },
});

export const reducer = slice.reducer;

export const { getPermissionDataAssignedForUser, savePermissionAssignedList } =
  slice.actions;

export const quanTriNguoiDungSelector = (state) => state[namespace];
