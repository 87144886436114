import TrashIcon from "@assets/icon/TrashIcon";
import { callAPISearchBarCode } from "@modules/treo-thao/nhan-vien-treo-thao/yeu-cau-duoc-phan-cong/services";
import { alertMessage } from "@utils/function";
import { Col, Form, Input, Row } from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import { handleGetCongToCMIS } from "@modules/cap-dien/thi-cong/xac-nhan-thi-cong/services";
import DSCongToModal from "../../modal-ds-cong-to";
import ThongTinTUTreoMoi from "./layout";

const { Search } = Input;

export default function Index(props) {
  const { form, detail, dataTreo, setCustomData, setChangeTab3, detailHoSo } =
    props;
  const [dataDangTreo, setDataTIDangTreo] = useState(detail);
  const [isChecked, setIsChecked] = useState(dataDangTreo?.nangHaCongSuat);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dataCongTo, setDataCongTo] = useState([]);
  const [typeThietBi, setTypeThietBi] = useState("");
  const [defaultTreoMoi] = useState({
    nangHaCongSuat: false,
    tutIs: [
      {
        thietBiId: null,
        maThietBi: null,
        ngayKiemDinh: null,
        tySoDau: null,
        namSanXuat: null,
        soPha: null,
        isChayHong: false,
        maTemKiemDinh: null,
        seryTemKiemDinh: null,
        soLanLapTrinh: null,
        ngayLapTrinh: null,
        maChiKiemDinh: null,
        soChiKiemDinh: null,
        kimChiTai: null,
        soVienChiTai: null,
        maDonViKiemDinh: null,
        maNhanVienKiemDinh: null,
        maNhanVienLapTrinh: null,
      },
      {
        thietBiId: null,
        maThietBi: null,
        ngayKiemDinh: null,
        tySoDau: null,
        namSanXuat: null,
        soPha: null,
        isChayHong: false,
        maTemKiemDinh: null,
        seryTemKiemDinh: null,
        soLanLapTrinh: null,
        ngayLapTrinh: null,
        maChiKiemDinh: null,
        soChiKiemDinh: null,
        kimChiTai: null,
        soVienChiTai: null,
        maDonViKiemDinh: null,
        maNhanVienKiemDinh: null,
        maNhanVienLapTrinh: null,
      },
      {
        thietBiId: null,
        maThietBi: null,
        ngayKiemDinh: null,
        tySoDau: null,
        namSanXuat: null,
        soPha: null,
        isChayHong: false,
        maTemKiemDinh: null,
        seryTemKiemDinh: null,
        soLanLapTrinh: null,
        ngayLapTrinh: null,
        maChiKiemDinh: null,
        soChiKiemDinh: null,
        kimChiTai: null,
        soVienChiTai: null,
        maDonViKiemDinh: null,
        maNhanVienKiemDinh: null,
        maNhanVienLapTrinh: null,
      },
    ],
  });

  //xóa
  const handleDeleteData = useCallback(
    (thietBiId, viTri) => {
      const arr = [...dataDangTreo.tutIs];
      arr[viTri] = {
        thietBiId: null,
        maThietBi: null,
        ngayKiemDinh: null,
        tySoDau: null,
        namSanXuat: null,
        soPha: null,
        isChayHong: false,
        maTemKiemDinh: null,
        seryTemKiemDinh: null,
        soLanLapTrinh: null,
        ngayLapTrinh: null,
        maChiKiemDinh: null,
        soChiKiemDinh: null,
        kimChiTai: null,
        soVienChiTai: null,
        maDonViKiemDinh: null,
        maNhanVienKiemDinh: null,
        maNhanVienLapTrinh: null,
      };
      setDataTIDangTreo({
        ...dataDangTreo,
        tutIs: arr,
      });
      form.setFieldsValue({
        [`maTU-${viTri}`]: null,
      });
    },
    [dataDangTreo, form]
  );

  useEffect(() => {
    if (!isChecked && detail && detail?.tutIs?.length === 0)
      setDataTIDangTreo(defaultTreoMoi);
  }, [defaultTreoMoi, detail, isChecked]);

  useEffect(() => {
    if (detail?.tutIs?.length === 0) setDataTIDangTreo(defaultTreoMoi);
  }, [defaultTreoMoi, detail]);

  useEffect(() => {
    dataDangTreo.tutIs.map((item, index) => {
      return form.setFieldsValue({
        [`maTU-${index}`]: item.maThietBi,
      });
    });
  }, [dataDangTreo, dataDangTreo.tutIs, form]);

  //
  const onChangeSearch = useCallback(
    (e, fieldName, viTri) => {
      if (e.target.value !== "") {
        form.setFields([
          {
            name: fieldName,
            errors: false,
          },
        ]);
      } else {
        if (viTri === 0) {
          if (
            form.getFieldValue(`maTU-${1}`) === "" &&
            form.getFieldValue(`maTU-${2}`) === "" &&
            e.target.value === ""
          ) {
            form.setFields([
              {
                name: fieldName,
                errors: false,
              },
              {
                name: `maTU-${1}`,
                errors: false,
              },
              {
                name: `maTU-${2}`,
                errors: false,
              },
            ]);
          } else {
            form.setFields([
              {
                name: fieldName,
                errors: ["Không được bỏ trống"],
              },
            ]);
          }
        } else if (viTri === 1) {
          if (
            form.getFieldValue(`maTU-${0}`) === "" &&
            form.getFieldValue(`maTU-${2}`) === "" &&
            e.target.value === ""
          ) {
            form.setFields([
              {
                name: fieldName,
                errors: false,
              },
              {
                name: `maTU-${0}`,
                errors: false,
              },
              {
                name: `maTU-${2}`,
                errors: false,
              },
            ]);
          } else {
            form.setFields([
              {
                name: fieldName,
                errors: ["Không được bỏ trống"],
              },
            ]);
          }
        } else {
          if (
            form.getFieldValue(`maTU-${0}`) === "" &&
            form.getFieldValue(`maTU-${1}`) === "" &&
            e.target.value === ""
          ) {
            form.setFields([
              {
                name: fieldName,
                errors: false,
              },
              {
                name: `maTU-${0}`,
                errors: false,
              },
              {
                name: `maTU-${1}`,
                errors: false,
              },
            ]);
          } else {
            form.setFields([
              {
                name: fieldName,
                errors: ["Không được bỏ trống"],
              },
            ]);
          }
        }
      }
    },
    [form]
  );

  //Hàm search call api công tơ
  const getListCongToCMIS = useCallback(
    (value, type) => {
      if (value !== "") {
        handleGetCongToCMIS(
          detailHoSo?.id,
          value,
          setLoading,
          setDataCongTo,
          setVisible,
          type,
          setTypeThietBi
        );
      }
    },
    [detailHoSo?.id]
  );

  const handleCloseModal = () => {
    setVisible(false);
  };

  //search công tơ
  const handleSearchBarCode = useCallback(
    (value, index) => {
      const type = "TU";
      const filterData = dataDangTreo.tutIs.filter(
        (el) => el.maThietBi === value
      );
      if (filterData.length > 0) {
        alertMessage(
          "error",
          "Thông báo!",
          `Mã TU đã nhập phải khác với Mã TU trong danh sách Treo mới`
        );
      } else {
        callAPISearchBarCode(
          setLoading,
          setDataTIDangTreo,
          value,
          type,
          dataDangTreo,
          setChangeTab3,
          index
        );
      }
    },
    [dataDangTreo, setChangeTab3]
  );

  // Kiểm tra điều kiện để setData tương ứng Treo or Tháo
  const renderDSCT = useCallback(() => {
    return (
      visible && (
        <DSCongToModal
          loading={loading}
          visible={visible}
          closeModal={handleCloseModal}
          data={dataCongTo}
          handleSearch={getListCongToCMIS}
          typeThietBi={typeThietBi}
          handleSearchBarCode={handleSearchBarCode}
        />
      )
    );
  }, [
    visible,
    loading,
    dataCongTo,
    getListCongToCMIS,
    typeThietBi,
    handleSearchBarCode,
  ]);

  //hiển thị bảng
  const renderTable = useCallback(() => {
    return (
      <Row gutter={24}>
        <Col span={6} style={{ marginBottom: "8px", paddingRight: 0 }}>
          <div
            style={{
              textAlign: "center",
              borderRight: "1px solid #fff",
              background: "#D0E0E3",
            }}
          >
            Mã TU
          </div>
        </Col>
        <Col span={4} style={{ marginBottom: "8px", padding: 0 }}>
          <div
            style={{
              textAlign: "center",
              borderRight: "1px solid #fff",
              background: "#D0E0E3",
            }}
          >
            Số pha
          </div>
        </Col>
        <Col span={4} style={{ marginBottom: "8px", padding: 0 }}>
          <div
            style={{
              textAlign: "center",
              borderRight: "1px solid #fff",
              background: "#D0E0E3",
            }}
          >
            Tỷ số đấu
          </div>
        </Col>
        <Col span={4} style={{ marginBottom: "8px", padding: 0 }}>
          <div
            style={{
              textAlign: "center",
              borderRight: "1px solid #fff",
              background: "#D0E0E3",
            }}
          >
            Ngày kiểm định
          </div>
        </Col>
        <Col span={4} style={{ marginBottom: "8px", padding: 0 }}>
          <div
            style={{
              textAlign: "center",
              borderRight: "1px solid #fff",
              background: "#D0E0E3",
            }}
          >
            Tem kiểm định
          </div>
        </Col>
        <Col span={2} style={{ marginBottom: "8px", paddingLeft: 0 }}>
          <div
            style={{
              textAlign: "center",
              borderRight: "1px solid #fff",
              background: "#D0E0E3",
            }}
          >
            Xóa
          </div>
        </Col>
        {dataDangTreo.tutIs && dataDangTreo.tutIs.length > 0
          ? dataDangTreo.tutIs.map((item, index) => (
              <Fragment key={index}>
                <Col span={6}>
                  <div style={{ padding: isChecked ? "8px" : 0 }}>
                    {isChecked ? (
                      item.maThietBi
                    ) : (
                      <Form.Item name={`maTU-${index}`}>
                        <Search
                          placeholder="Nhập mã TU"
                          allowClear={false}
                          enterButton="Tìm"
                          value={item.maThietBi}
                          onSearch={(value) =>
                            handleSearchBarCode(value, index)
                          }
                          onChange={(e) =>
                            onChangeSearch(e, `maTU-${index}`, index)
                          }
                          onKeyDown={(e) =>
                            e.keyCode === 13 ? e.preventDefault() : ""
                          }
                        />
                      </Form.Item>
                    )}
                  </div>
                </Col>
                <Col span={4}>
                  <div style={{ textAlign: "right", padding: "8px" }}>
                    {item.soPha}
                  </div>
                </Col>
                <Col span={4}>
                  <div style={{ padding: "8px" }}>{item.tySoDau}</div>
                </Col>
                <Col span={4}>
                  <div style={{ textAlign: "center", padding: "8px" }}>
                    {item.ngayKiemDinh}
                  </div>
                </Col>
                <Col span={4}>
                  <div style={{ textAlign: "center", padding: "8px" }}>
                    {item.seryTemKiemDinh}
                  </div>
                </Col>
                {isChecked || item.thietBiId === null ? null : (
                  <Col span={2}>
                    <div
                      style={{ textAlign: "center", padding: "8px" }}
                      className="action-icon"
                      onClick={() => handleDeleteData(item.thietBiId, index)}
                    >
                      <TrashIcon />
                    </div>
                  </Col>
                )}
              </Fragment>
            ))
          : null}
      </Row>
    );
  }, [
    dataDangTreo.tutIs,
    handleDeleteData,
    handleSearchBarCode,
    isChecked,
    onChangeSearch,
  ]);

  //check nâng hạ công suất
  const handleChange = (e) => {
    if (e.target.checked) {
      if (dataDangTreo && dataDangTreo?.tutIs?.length > 0) {
        const filterData = dataDangTreo.tutIs.filter(
          (el, index) => el.thietBiId === dataTreo.tutIs[index].thietBiId
        );
        if (filterData.length > 0) {
          alertMessage(
            "error",
            "Thông báo!",
            "Vui lòng xóa các TU đã nhập trước khi thực thay đổi công suất !"
          );
          setIsChecked(false);
          form.setFieldsValue({
            isNangHaTU: false,
          });
        } else {
          setIsChecked(true);
          form.setFieldsValue({
            isNangHaTU: true,
          });
          setDataTIDangTreo({
            tutIs: dataTreo.tutIs,
            nangHaCongSuat: true,
          });
        }
      } else {
        setDataTIDangTreo({
          tutIs: dataTreo.tutIs,
          nangHaCongSuat: true,
        });
        setIsChecked(true);
        form.setFieldsValue({
          isNangHaTU: true,
        });
      }
    } else {
      if (detail && detail?.tutIs.length > 0) {
        setDataTIDangTreo({
          tutIs: detail?.tutIs,
          nangHaCongSuat: false,
        });
      } else {
        setDataTIDangTreo({
          tutIs: defaultTreoMoi.tutIs,
          nangHaCongSuat: false,
        });
      }

      setIsChecked(false);
      form.setFieldsValue({
        isNangHaTU: false,
      });
    }
  };

  useEffect(() => {
    if (setCustomData) setCustomData(dataDangTreo);
  }, [setCustomData, dataDangTreo]);

  return (
    <ThongTinTUTreoMoi
      loading={loading}
      isChecked={isChecked}
      dataTreo={dataTreo}
      handleChange={handleChange}
      renderTable={renderTable}
      renderDSCT={renderDSCT}
    />
  );
}
