import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import XuatExcel from "../../bc-doi-soat-sms/tabs/xuat-excel";
import { handleDownload, selectBaoCaoSMS } from "../services";

export default function BieuChiTiet30Ngay(props) {
  const { screen, handleChange } = props;
  const [form] = Form.useForm();
  const [, setSearchParams] = useSearchParams();
  const user = useSelector(authSelector);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [filterConditions, setFilterConditions] = useState({
    MaDonVi: user.user.unitCode,
    ...parseParams(location.search),
  });

  useEffect(() => {
    form.resetFields();
  }, [form]);

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const handleSearch = useCallback((values) => {
    handleDownload(values, setLoading);
  }, []);

  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <FormComponent
            onFinish={handleSearch}
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            initialValues={{
              reportType: screen,
            }}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="reportType"
                  label="Tên báo cáo"
                  labelCol={{ span: 3 }}
                  labelAlign="left"
                >
                  <Select
                    form={form}
                    options={selectBaoCaoSMS}
                    onChange={() =>
                      handleChange(form.getFieldValue("reportType"))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={22}>
              <Col span={12}>
                <Form.Item
                  name="MaDonVi"
                  label="Đơn vị"
                  labelAlign="left"
                  labelCol={{ span: 6 }}
                >
                  <Selection
                    url={Endpoint.GET_TEAM_LIST_CODE}
                    formKey="MaDonVi"
                    form={form}
                  />
                </Form.Item>
              </Col>
              <Row justify="end">
                <Button type="primary" htmlType="submit">
                  Xuất Excel
                </Button>
              </Row>
            </Row>
          </FormComponent>
          <Divider className="section-text-line" orientation="left"></Divider>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  margin-top: 40px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
