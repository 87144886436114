import { quanLyChiSoSelector } from "@state/giao-nhan-dien-nang/quan-ly-chi-so";
import { cloneDeep } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { validateSanLuong } from "../validate";

const { ENUM_QL_CHI_SO_GNDN_LOAI_NHAP } = require("@utils/constants");
const { numberWithSpaces, numberWithNoSpaces } = require("@utils/function");
const { Input } = require("antd");
const { useState, useEffect } = require("react");
const { tinhToanSanLuongVaTongCong } = require("../calculate");

const NumericInput = (props) => {
  const {
    disablefield,
    digit,
    className = "",
    record = {},
    isNegativeValue,
    value = "",
    dataSource = [],
    setDataSource = () => {},
    name = "",
    style = "",
    id,
    dataSourceOriginal,
    setDataSourceInitial,
  } = props;

  const dispatch = useDispatch();
  const { danhSachDiemDoCoChiSoNhapSai } = useSelector(quanLyChiSoSelector);

  const [valueInput, setValueInput] = useState("");

  useEffect(() => {
    setValueInput(value);
  }, [value]);

  const classInput = className + " table-text-right table-text-transparent";

  const handleBlur = (event, record) => {
    if (disablefield) {
      return;
    }
    const dataSourceClone = cloneDeep(dataSource);
    const dataSourceOriginalClone = cloneDeep(dataSourceOriginal);
    const { value } = event.target;

    const index = dataSourceClone.findIndex((item) => item.id === record.id);
    const indexOriginal = dataSourceOriginalClone.findIndex((item) => item.id === record.id);

    dataSourceClone[index][name] = numberWithSpaces(value);
    dataSourceOriginalClone[indexOriginal][name] = numberWithSpaces(value);

    setDataSource([...dataSourceClone]);
    setDataSourceInitial([...dataSourceOriginalClone]);

    setValueInput(numberWithSpaces(value));
    handleValidate({ keyId: record.id, dataSourceClone, dataSourceOriginalClone });
  };

  const handleValidate = ({ keyId, dataSourceClone, dataSourceOriginalClone }) => {
    if (keyId) {
      tinhToanSanLuongVaTongCong({
        dataSource: dataSourceClone,
        dataSourceOriginal: dataSourceOriginalClone,
        keyId,
        setDataSource,
        setDataSourceInitial,
      });
      validateSanLuong({
        keyId,
        dataSourceOriginal: dataSourceOriginalClone,
        dispatch,
        danhSachDiemDoCoChiSoNhapSai,
      });
    }
  };

  const handleChange = (e) => {
    if (disablefield) return;
    const dataSourceClone = cloneDeep(dataSource);
    const dataSourceOriginalClone = cloneDeep(dataSourceOriginal);
    const inputValue = numberWithNoSpaces(e.target.value);
    let regex = /^(\d*)\.?(\d){0,1}$/;
    if (digit) {
      regex = /^[0-9]*\.?[0-9]*$/;
    }
    if (isNegativeValue) {
      regex = /^[-]?[0-9]*\.?[0-9]*$/;
    }
    if (
      (regex.test(+inputValue) || !inputValue || (isNegativeValue && inputValue === "-")) &&
      !(inputValue?.split(".")[1]?.length > 3)
    ) {
      setValueInput(inputValue);
      if (record.loaiNhap !== ENUM_QL_CHI_SO_GNDN_LOAI_NHAP.NHAP_TAY.value) {
        const index = dataSourceClone.findIndex((item) => item.id === record.id);
        const indexOriginal = dataSourceOriginalClone.findIndex((item) => item.id === record.id);

        dataSourceClone[index].loaiNhap = ENUM_QL_CHI_SO_GNDN_LOAI_NHAP.NHAP_TAY.value;
        dataSourceOriginalClone[indexOriginal].loaiNhap = ENUM_QL_CHI_SO_GNDN_LOAI_NHAP.NHAP_TAY.value;

        setDataSource([...dataSourceClone]);
        setDataSourceInitial([...dataSourceOriginalClone]);
      }
    }
  };

  const handleFocus = (e) => {
    if (disablefield) {
      return;
    }
    setValueInput(numberWithNoSpaces(e.target.value));
  };

  const handleFocusNextInput = (record, key) => {
    let next = null;
    for (let index = 0; index < dataSource.length; index++) {
      const element = dataSource[index];
      if (element.id === record.id) {
        const nextElement = dataSource[index + 1];
        if (nextElement) {
          next = document.getElementById(`${nextElement.id}-${key}`);
        }
      }
    }
    if (next) {
      next.focus();
    }
  };

  const copyChiSoDau = (event, record) => {
    if (event.code === "F2") {
      const dataSourceClone = cloneDeep(dataSource);
      const dataSourceOriginalClone = cloneDeep(dataSourceOriginal);
      const findDiemDo = dataSourceOriginalClone.filter((item) => item.diemDoId === record.diemDoId);
      if (findDiemDo.length) {
        for (let index = 0; index < findDiemDo.length; index++) {
          const element = findDiemDo[index];
          const chiSoDau = element.chiSoDau;
          
          const idx = dataSourceClone.findIndex((e) => e.id === element.id);
          const idxOriginal = dataSourceOriginalClone.findIndex((e) => e.id === element.id);

          dataSourceClone[idx].chiSoCuoi = numberWithSpaces(chiSoDau);
          dataSourceOriginalClone[idxOriginal].chiSoCuoi = numberWithSpaces(chiSoDau);

          tinhToanSanLuongVaTongCong({
            dataSource: dataSourceClone,
            keyId: element.id,
            setDataSource,
            dataSourceOriginal: dataSourceOriginalClone,
            setDataSourceInitial
          });
        }

        setDataSource([...dataSourceClone]);
        setDataSourceInitial([...dataSourceOriginalClone]);
      }
      // set loai nhap => loai nhap tay
        const listDiemDo = dataSourceOriginalClone.filter((item) => item.diemDoId === record.diemDoId);
        listDiemDo.forEach((element) => {
          const index = dataSourceClone.findIndex((item) => item.id === element.id);
          const idxOriginal = dataSourceOriginalClone.findIndex((item) => item.id === element.id);

          dataSourceClone[index].loaiNhap = ENUM_QL_CHI_SO_GNDN_LOAI_NHAP.NHAP_TAY.value;
          dataSourceOriginalClone[idxOriginal].loaiNhap = ENUM_QL_CHI_SO_GNDN_LOAI_NHAP.NHAP_TAY.value;
        });
        setDataSource([...dataSourceClone]);
        setDataSourceInitial([...dataSourceOriginalClone]);
    }
  };
  return (
    <Input
      // {...valueProps}
      className={classInput}
      onChange={handleChange}
      maxLength={25}
      value={valueInput}
      onFocus={handleFocus}
      onBlur={(e) => handleBlur(e, record)}
      style={style}
      onPressEnter={() => {
        handleFocusNextInput(record, "chiSoCuoi");
      }}
      onKeyDown={(e) => copyChiSoDau(e, record)}
      id={id}
    />
  );
};

export default NumericInput;
