import { Endpoint } from "@utils/endpoint";
import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { getErrorMessage } from "@utils/function";
import { authGetData, authPostData } from "@utils/request";

export const handleList = (setLoading, setData) => {
  authGetData({
    url: Endpoint.CAU_HINH_MAIL_TREO_THAO,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setData(res.data);
      }
    },
  });
};

export const handleListViTri = (setLoading, setDataViTri) => {
  authGetData({
    url: Endpoint.GET_VI_TRI_CONG_VIEC,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataViTri(res.data);
      }
    },
  });
};

export const handleUpdateData = (
  detail,
  checked,
  setLoading,
  values,
  form,
  getListHoSo,
  closeModal
) => {
  const payload = {
    ...values,
    id: detail.id,
    sendEmail: checked,
    doiTuongNhanMails: values.doiTuongNhanMails.toString(),
  };

  authPostData({
    url: Endpoint.UPDATe_CAU_HINH_MAIL_TREO_THAO,
    method: METHOD_PUT,
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        getListHoSo();
        closeModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};
