import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleDuyetHoSoService } from "./services";
import ModalKiemDuyet from "./modal-kiem-duyet";

function KiemDuyetModal({ visible, closeModal, setFetchDataTable, detail }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleDuyetHoSo = () => {
    const hoSoId = { id: detail.id };
    handleDuyetHoSoService(
      hoSoId,
      setLoading,
      closeModal,
      setFetchDataTable,
      t
    );
  };

  return (
    <ModalKiemDuyet
      loading={loading}
      visible={visible}
      closeModal={closeModal}
      handleDuyetHoSo={handleDuyetHoSo}
      t={t}
    ></ModalKiemDuyet>
  );
}

export default memo(KiemDuyetModal);
