import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleDuyetHoSoService, handleXacThucChuKyService } from "./services";
import ModalKinhDoanh from "./modal-kinh-doanh";

function KinhDoanhModal({ visible, closeModal, setFetchDataTable, detail }) {
  const { t } = useTranslation();
  const [loadingDuyet, setLoadingDuyet] = useState(false);
  const [loadingXacThu, setLoadingXacThuc] = useState(false);
  const handleDuyetHoSo = () => {
    const hoSoId = { id: detail.id };
    handleDuyetHoSoService(
      hoSoId,
      setLoadingDuyet,
      closeModal,
      setFetchDataTable,
      t
    );
  };

  const handleXacThucChuKy = () => {
    const hoSoId = { id: detail.id };
    handleXacThucChuKyService(
      hoSoId,
      setLoadingXacThuc,
      closeModal,
      setFetchDataTable,
      t
    );
  };
  return (
    <ModalKinhDoanh
      visible={visible}
      closeModal={closeModal}
      handleDuyetHoSo={handleDuyetHoSo}
      handleXacThucChuKy={handleXacThucChuKy}
      t={t}
      loadingDuyet={loadingDuyet}
      loadingXacThu={loadingXacThu}
    ></ModalKinhDoanh>
  );
}

export default memo(KinhDoanhModal);
