import styled from "styled-components";

export const Container = styled.div`
  padding: 0px 20px;
`;
export const TableStyled = styled.div`
  .ant-table-thead {
    .ant-table-cell {
      background: #d9d9d9;
      color: #1f1f1f;
      font-size: 13px;
    }
  };
  .ant-table-thead {
    .col-Cmis {
      background-color: #ffffff;
      color: #37e2d5 !important;
      font-weight: normal;
    };
    .col-Hes {
      background-color: #ffffff;
      color: red !important;
      font-weight: normal;
    }
    .hiddenBC {
      display: none;
    }
  }
`;
export const FormStyled = styled.div`
  .ant-form-item-explain-error {
    background: unset;
  }
`;

export const TableLSStyled = styled.div`
  .ant-table-thead {
    .ant-table-cell {
      background: #d9d9d9;
      color: #1f1f1f;
      font-size: 13px;
    }
  }
  .ant-table-tbody > tr > td,
  .ant-table-tbody > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding: 8px !important;
  }
`;

export const ContainerCMIS = styled.div`
  padding: 10px 36px;
`;
