import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ModalCapNhat13G from "./modal-cap-nhat-13g";
import {
  EndpointSTDTCKDD13G,
  handleDownload,
  handleListBaoCao,
} from "./service";
export default function BaoCaoTHSTDACuaKHDungDien13G() {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState();
  const [dataTable, setDataTable] = useState();
  const user = useSelector(authSelector);

  const onFinish = (values) => {
    handleDownload({ ...values, reportType: EndpointSTDTCKDD13G }, setLoading);
  };
  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <Fragment>
      {visible && (
        <ModalCapNhat13G
          visible={visible}
          onCancel={onCancel}
          dataTable={dataTable}
          setVisible={setVisible}
        />
      )}
      <Container>
        <Divider className="section-text" orientation="left">
          Báo cáo tổng hợp số tiền đã thu của khách hàng dùng điện 13g
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <div className="flex">
              <FlexForm>
                <FormComponent
                  onFinish={onFinish}
                  form={form}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 14 }}
                  colon={false}
                  initialValues={{
                    unitId: user.user.unitCode,
                    LoaiTongHop: "0",
                  }}
                >
                  <Form.Item
                    name="unitId"
                    label="Đơn vị"
                    labelAlign="left"
                    required
                  >
                    <Selection
                      url={Endpoint.GET_TEAM_LIST_CODE}
                      formKey="unitId"
                      form={form}
                    />
                  </Form.Item>

                  <Form.Item
                    name="reportDate"
                    label="Tháng báo cáo"
                    labelAlign="left"
                    required
                  >
                    <DatePickerComponent
                      picker="month"
                      formKey="reportDate"
                      form={form}
                    />
                  </Form.Item>

                  <Form.Item
                    name="LoaiTongHop"
                    label="Loại tổng hợp"
                    labelAlign="left"
                    required
                  >
                    <Select
                      placeholder="Chọn loại tổng hợp"
                      formKey="LoaiTongHop"
                    >
                      <Option value="0">Không tổng hợp lại</Option>
                      <Option value="1">Tổng hợp lại</Option>
                    </Select>
                  </Form.Item>

                  <Row>
                    <Col span={4}></Col>
                    <Col>
                      <Button type="primary" htmlType="submit">
                        Xuất báo cáo
                      </Button>
                      <Button
                        type="primary"
                        className="ml-20px"
                        onClick={() => {
                          handleDownload(
                            {
                              ...form.getFieldValue(),
                              reportType:
                                Endpoint.BCCN_TH_SO_TIEN_DA_THU_CUA_KHACH_DUNG_DIEN_13G_MOI,
                            },
                            setLoading
                          );
                        }}
                      >
                        Báo cáo mới
                      </Button>

                      {/* <Button
                        type="primary"
                        loading={loading}
                        className="ml-20px"
                        onClick={() => {
                          handleOpenPdf(
                            {
                              ...form.getFieldValue(),
                              reportType: EndpointSTDTCKDD13G,
                            },
                            setLoading
                          );
                        }}
                      >
                        In báo cáo
                      </Button> */}
                    </Col>
                  </Row>
                </FormComponent>
              </FlexForm>
              <FlexButton>
                <Button
                  type="primary"
                  onClick={() => {
                    handleListBaoCao(
                      Endpoint.BCCN_DANH_SACH_CAP_NHAT_13H,
                      {
                        MaDonVi: form.getFieldValue("unitId"),
                        User: user.user.username,
                      },
                      setLoading,
                      setDataTable,
                      setVisible
                    );
                  }}
                >
                  Cập nhật báo cáo
                </Button>
              </FlexButton>
            </div>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
const FlexButton = styled.div`
  flex: 1;
`;
const FlexForm = styled.div`
  flex: 3;
`;
