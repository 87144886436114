import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
import { alertMessage422 } from "@modules/bao-cao-kinh-doanh/service";

export const selectBCCTCD = [
  {
    label:
      "1. Báo cáo chi tiết tình hình cấp điện mới sinh hoạt khu vực TP, TT, TX theo tháng",
    value: Endpoint.BCCD_BC_TINH_HINH_CAP_DIEN_MOI_SHKV_THANH_PHO,
  },
  {
    label:
      "2. Báo cáo chi tiết tình hình cấp điện mới sinh hoạt khu vực nông thôn theo tháng",
    value: Endpoint.BCCD_BC_TINH_HINH_CAP_DIEN_MOI_SHKV_NONG_THON,
  },
  {
    label:
      "3. Báo cáo tình hình cấp điện mới cho khách hàng ngoài sinh hoạt theo tháng",
    value: Endpoint.BCCD_BC_TINH_HINH_CAP_DIEN_MOI_KH_NGOAI_SINH_HOAT,
  },
  {
    label:
      "4. Biểu 20 (1761) Báo cáo hoàn tất hồ sơ cấp điện hạ áp / Di dời / Nâng công suất",
    value: Endpoint.BCCD_HOAN_TAT_HSCD_HA_AP,
  },
  {
    label:
      "5. Biểu 21 (1761) Chi tiết báo cáo khách hàng cấp điện hạ áp trong báo cáo kinh doanh điện năng",
    value: Endpoint.BCCD_CT_KH_CAP_DIEN_HA_AP,
  },
  {
    label: "6. Báo cáo thời gian trung bình giải quyết cấp điện mới tại đơn vị",
    value: Endpoint.BCCD_THOI_GIAN_TB_GQCD,
  },
  {
    label: "7. Báo cáo tổng hợp hồ sơ có trở ngại",
    value: Endpoint.BCCD_TH_HS_CO_TRO_NGAI,
  },
];

export const EndpointTHTrangWebCapDien = Endpoint.BCCD_TH_TRANG_WEB_CAP_DIEN;

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      TuNgay: data.TuNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      DenNgay: data.DenNgay,
      MaDonVi: data.unitId,
    })
  );
  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
