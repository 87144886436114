import React, { Fragment } from "react";
import { Modal } from "antd";
import FormUpdateDichVuBanLe from "./Form-bc-dich-vu-ban-le";
import styled from "styled-components";
export default function ModalDichVuBanLeDienNang(props) {
  const { visible, close } = props;
  return (
    <Fragment>
      <Container>
        <Modal
          width="1200px"
          title="Cập nhật công tác tổ chức và hoạt động dịch vụ bán lẻ điện năng"
          visible={visible}
          footer={null}
          onCancel={() => close()}
        >
          <FormUpdateDichVuBanLe close={close} />
        </Modal>
      </Container>
    </Fragment>
  );
}
const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
  .btn-update-data {
    margin-bottom: 20px;
  }
`;
