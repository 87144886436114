import { giao_nhan_dien_nang } from "@permissions/c_gndn";
import { useState } from "react";
import LayoutBangKe from "../../common/table";

export default function Index() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [arrSelect, setArrSelect] = useState([]);
  const [fetchList, setFetchList] = useState(false);
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setArrSelect(selectedRows);
  };

  //Hiển thị checkbox ở đầu mỗi bảng
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
    getCheckboxProps: (record) => {
      return {
        disabled: record.maLoaiBangKe === 1,
        // Column configuration not to be checked
        // name: record.name,
      };
    },
  };
  return (
    <LayoutBangKe
      rowSelection={rowSelection}
      selectedRowKeys={selectedRowKeys}
      setSelectedRowKeys={setSelectedRowKeys}
      arrSelect={arrSelect}
      isHuy
      isTL={false}
      permissionCodeHuy={giao_nhan_dien_nang.c43h}
      fetchList={fetchList}
      setFetchList={setFetchList}
      headerTable={"Bảng kê - Nhân viên tạo (CTDL)"}
      loaiKy={0}
    />
  );
}
