/* eslint-disable react-hooks/exhaustive-deps */
import { buildQueryString, removeAccents } from "@utils/function";
import { authGetData } from "@utils/request";
import { Select } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
const { Option } = Select;
const SelectMaSoGCS = (props) => {
  const {
    query,
    setQuery,
    url,
    placeholder = "Tất cả",
    form,
    formKey,
    disabled,
    mode,
    handleDeselect,
    notClear,
    isCustom,
    valueKey,
    valueName,
    maxTagCount = 10,
    listMaso = [],
    setListMaso,
  } = props;
  const [loading, setLoading] = useState(false);

  const getData = () => {
    authGetData({
      url: `${url}?${buildQueryString(query)}`,
      setLoading,
      onSuccess: (res) => {
        if (query.pageIndex > 1) {
          setListMaso([...listMaso, ...res?.data]);
          return;
        }
        setListMaso([...res?.data]);
      },
    });
  };

  useEffect(() => {
    if (query.DonViId || query.donViId) {
      getData();
    }
  }, [url, query]);

  const debounceSearch = useCallback(
    debounce(({ queryParams }) => {
      setLoading(true);
      setListMaso([]);
      setQuery({
        ...queryParams,
        pageIndex: 1,
      });
    }, 1000),
    []
  );

  const onScroll = (event) => {
    const target = event.target;
    if (!loading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setLoading(true);
      target.scrollTo(0, target.scrollHeight);
      setQuery({
        ...query,
        pageIndex: query.pageIndex + 1,
      });
    }
  };

  const handleChange = (e) => {
    form.setFieldsValue({
      [formKey]: e,
    });
  };

  return (
    <>
      <Select
        maxTagCount={maxTagCount}
        value={form.getFieldValue(formKey)}
        placeholder={placeholder}
        showSearch
        mode={mode}
        allowClear={!notClear}
        loading={loading}
        onDeselect={handleDeselect}
        disabled={disabled}
        style={{ width: "100%" }}
        filterOption={(input, option) => {
          if (option && option.children) {
            return removeAccents(option.children).toLowerCase().indexOf(removeAccents(input).toLowerCase()) >= 0;
          }
        }}
        onSearch={(value) => {
          debounceSearch({ queryParams: { ...query, pageIndex: 1, ...(value && { searchTerm: value }) } });
        }}
        onBlur={() => {
          if (query.searchTerm) {
            setQuery({ ...query, searchTerm: "" });
          }
        }}
        onPopupScroll={onScroll}
        onChange={handleChange}
      >
        {listMaso?.map((item, idx) => (
          <Option key={idx} value={item.value ? item.value.toString() : isCustom && valueKey ? item[valueKey] : item.value}>
            {isCustom && valueName ? item[valueName] : item.name}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default SelectMaSoGCS;
