import { SearchOutlined } from "@ant-design/icons";
import FormComponent from "@components/Form";
import Thanglamviec from "@modules/giao-nhan-dien-nang/common/pdf-viewer/Thanglamviec";
import { Col, Form, Input, Row } from "antd";
import { useEffect } from "react";

export function FilterTreoThao(props) {
  const { onSearch, filterConditions, setFilterConditions } = props;
  const [form] = Form.useForm();
  useEffect(
    () => {
      if (filterConditions?.handleSearch) {
        onSearch();
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onChange = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      handleSearch: e.target.value,
      pageIndex: 1,
    }));
  };

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={onSearch}
      layout="vertical"
      autoComplete="off"
    >
      <Row>
        <Col span={12}>
          <Thanglamviec />
        </Col>
        <Col span={4}></Col>
        <Col span={8}>
          <Form.Item name="searchTerm">
            <Input
              onChange={onChange}
              prefix={<SearchOutlined />}
              placeholder="Tìm kiếm theo Mã điểm đo, Thông tin điểm đo, Mã công tơ"
            />
          </Form.Item>
        </Col>
      </Row>
    </FormComponent>
  );
}
