import styled from "styled-components";

export const Container = styled.div`
  padding: 0 8px;
`;

export const PopupSearch = styled.div`
  position: absolute;
  top: 31px;
  left: 0px;
  z-index: 9999;
  width: 93.5%;
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 2px;
  margin-left: 12px;
  height: 150px;
  overflow-y: auto;

  .item-search {
    padding: 5px 12px;
    cursor: pointer;
  }

  .item-search-not-value {
    padding: 5px 12px;
  }

  .item-search:hover,
  .item-search:focus {
    background: #f5f5f5;
  }
`;
