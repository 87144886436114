import CheckPermission from "@components/CheckPermission";
import XemLaiBBLapDatComponent from "@modules/cap-dien/common-ho-so/xem-bien-ban-lap-dat";
import { cap_dien } from "@permissions/a_capdien";
import { LITMIT_TRANGTHAI } from "@utils/constants";
import { Menu } from "antd";

const { SubMenu } = Menu;

export default function ListMenu(props) {
  const {
    t,
    detail,
    handleOpenModalInHoSoCMIS,
    handleOpenModalXemLaiPhuongAnCapDien,
    handleOpenModalQuyetToanKhachHang,
    handleOpenModalQuyetToanCongTy,
    handleOpenModalInBieuKhaiGia,
    inBienBanApGia,
    handleOpenInPhuTai,
  } = props;

  return (
    <Menu mode="vertical" style={{ width: "100%" }} selectedKeys={["0"]}>
      <CheckPermission permissionCode={cap_dien.a402_in_hs_cmis}>
        <Menu.Item eventKey="8" onClick={handleOpenModalInHoSoCMIS}>
          {" "}
          {t("common_cap_dien.in_ho_so_cmis")}
        </Menu.Item>
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a402_in_hs_web}>
        {Number(detail.maTrangThai) >= LITMIT_TRANGTHAI ? (
          <SubMenu eventKey="sub1" title={t("common_cap_dien.in_ho_so_web")}>
            <Menu.Item
              eventKey="9"
              onClick={handleOpenModalXemLaiPhuongAnCapDien}
            >
              {" "}
              {t("common_cap_dien.xem_lai_phuong_an_cap_dien")}
            </Menu.Item>
            <XemLaiBBLapDatComponent idHoSo={detail?.id ?? detail?.hoSoId} />
            <Menu.Item eventKey="20" onClick={handleOpenInPhuTai}>
              In biểu đồ phụ tải
            </Menu.Item>
            <Menu.Item
              eventKey="10"
              onClick={handleOpenModalQuyetToanKhachHang}
            >
              {" "}
              {t("common_cap_dien.quyet_toan_khach_hang")}
            </Menu.Item>
            <Menu.Item eventKey="11" onClick={handleOpenModalQuyetToanCongTy}>
              {" "}
              {t("common_cap_dien.quyet_toan_cong_ty")}
            </Menu.Item>
            <Menu.Item eventKey="12" onClick={handleOpenModalInBieuKhaiGia}>
              {" "}
              {t("common_cap_dien.in_bieu_khai_gia")}
            </Menu.Item>
            <Menu.Item eventKey="13" onClick={inBienBanApGia}>
              {" "}
              {t("common_cap_dien.in_bien_ban_ap_gia")}
            </Menu.Item>
          </SubMenu>
        ) : null}
      </CheckPermission>
    </Menu>
  );
}
