import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
import { alertMessage422 } from "../service";

export const selectBCGCS = [
  {
    label: "1. Giám sát ghi chỉ số theo ngày",
    value: Endpoint.BCGCS_GIAM_SAT_CHI_SO_THEO_NGAY,
  },
  {
    label: "2. Giám sát ghi chỉ số theo tháng",
    value: Endpoint.BCGCS_GIAM_SAT_CHI_SO_THEO_THANG,
  },
  {
    label: "3. Biểu 18(1761) - Danh sách chi tiết sổ ghi chỉ số",
    value: Endpoint.BCGCS_DS_CHI_TIET_SO_GHI_CHI_SO,
  },
  {
    label: "4. Biểu 19(1761) Danh sách các sổ ghi chỉ số được tạo",
    value: Endpoint.BCGCS_DS_CAC_SO_GHI_CHI_SO_DUOC_TAO,
  },
  {
    label: "5. Tổng hợp sổ ghi chỉ số đẩy từ file XML về CMIS",
    value: Endpoint.BCGCS_TH_SO_GCS_DAY_TU_XML_VE_CMIS,
  },
  {
    label: "6. Danh sách chi tiết sổ GCS xuất XML không qua Web ghi chỉ số",
    value: Endpoint.BCGCS_DS_GCS_XUAT_XML,
  },
];

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LoaiYeuCau: data.LoaiYeuCau,
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
    })
  );
  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
