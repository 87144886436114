import { useDispatch } from "react-redux";
import { openModal, closeModal } from "@state/system-config/reducer";
import ApGiaHoSoDetail from "./modals/detail-ap-gia";
import { useTranslation } from "react-i18next";
import { Fragment, Suspense, useCallback, useMemo, useState } from "react";
import ModalInHoSoCMIS from "../../common-ho-so/in-ho-so-CMIS";
import ModalXemLaiPhuongAnCapDien from "../../common-ho-so/xem-lai-phuong-an-cap-dien";
import ModalQuyetToanKhachHang from "../../common-ho-so/quyet-toan-khach-hang";
import ModalQuyetToanCongTy from "../../common-ho-so/quyet-toan-cong-ty";
import ModalApGia from "./modals/ap-gia";
import { STEP_XACNHAN_APGIA, TYPE_BIEN_BAN } from "@utils/constants";
import ListMenu from "./list-menu";
import { handleXemLaiPACD } from "./services";
import {
  downLoadBienBan,
  getPDFBienBanKhaiGia,
} from "../../nhan-vien-khao-sat/services";
import PDFModal from "@modules/cap-dien/common-ho-so/pdf-viewer";
import {
  inBienBan,
  inBieuDoPhuTai,
  downloadExcelBieuDoPT,
} from "../../common-ho-so/services";
import ReactPDF from "@react-pdf/renderer";
import BieuDoPhuTai from "@modules/cap-dien/common-ho-so/pdf-viewer/bieu-do-phu-tai";
import generateChart from "@modules/cap-dien/common-ho-so/pdf-viewer/charts/generate-chart";
import { alertMessage } from "@utils/function";
import ModalTraLaiAG from "./modals/tra-lai";

export default function ListChucNang(props) {
  const { detail, setFetchDataTable, setIsDoubleClick } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [visibleCMIS, setVisibleCMIS] = useState(false);
  const [visibleXemLaiPACD, setVisibleXemLaiPACD] = useState(false);
  const [visibleQuyetToanKH, setVisibleQuyetToanKH] = useState(false);
  const [visibleQuyetToanCT, setVisibleQuyetToanCT] = useState(false);
  const [visibleInBieuKG, setVisibleInBieuKG] = useState(false);
  const [visibleApGia, setVisibleApGia] = useState(false);
  const [visibleApGiaHS, setVisibleApGiaHS] = useState(false);
  const [dataPAXemLai, setDataPAXemLai] = useState({});
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [visibleTLAG, setVisibleTLAG] = useState(false);
  /* Handle Open Modal */

  const handleOpenInHoSoCMIS = () => {
    setVisibleCMIS(!visibleCMIS);
    setIsDoubleClick(false);
  };
  const handleOpenXemLaiPACD = () => {
    handleXemLaiPACD(
      detail,
      setDataPAXemLai,
      setVisibleXemLaiPACD,
      visibleXemLaiPACD
    );
    setIsDoubleClick(false);
  };
  const handleOpenQuyetToanKH = () => {
    setVisibleQuyetToanKH(!visibleQuyetToanKH);
    setIsDoubleClick(false);
  };
  const handleOpenQuyetToanCT = () => {
    setVisibleQuyetToanCT(!visibleQuyetToanCT);
    setIsDoubleClick(false);
  };
  const handleOpenInBieuKG = () => {
    setVisibleInBieuKG(!visibleInBieuKG);
    setIsDoubleClick(false);
  };

  const handleTraLaiApGia = () => {
    setVisibleTLAG(!visibleInBieuKG);
    setIsDoubleClick(false);
  };
  /* Handle Close Modal */

  const handleCloseInHoSoCMIS = () => {
    setVisibleCMIS(false);
    setIsDoubleClick(true);
  };
  const handleCloseXemLaiPACD = () => {
    setVisibleXemLaiPACD(false);
    setIsDoubleClick(true);
  };

  const handleCloseApGia = () => {
    setVisibleApGia(false);
    setIsDoubleClick(true);
  };

  const handleCloseApGiaHS = () => {
    setVisibleApGiaHS(false);
    setIsDoubleClick(true);
  };

  const handleCloseTraLaiApGia = () => {
    setVisibleTLAG(false);
    setIsDoubleClick(true);
  };

  /* Modal */
  //modal in hồ sơ cmis

  const showModalInHoSoCMIS = useMemo(
    () =>
      visibleCMIS && (
        <ModalInHoSoCMIS
          visible={visibleCMIS}
          detail={detail}
          handleCloseModal={handleCloseInHoSoCMIS}
          setFetchDataTable={setFetchDataTable}
        />
      ),
    // eslint-disable-next-line
    [visibleCMIS, detail]
  );
  //modal xem lại phương án cấp điện

  const showModalXemLaiPhuongAnCapDien = useMemo(
    () => {
      return (
        visibleXemLaiPACD && (
          <ModalXemLaiPhuongAnCapDien
            visible={visibleXemLaiPACD}
            handleCloseModal={handleCloseXemLaiPACD}
            setFetchDataTable={setFetchDataTable}
            detail={detail}
            dataPAXemLai={dataPAXemLai}
          />
        )
      );
    },
    // eslint-disable-next-line
    [visibleXemLaiPACD]
  );
  //modal quyết toán khách hàng

  const showModalQuyettoanKhachHang = useMemo(
    () =>
      visibleQuyetToanKH && (
        <ModalQuyetToanKhachHang
          visible={visibleQuyetToanKH}
          detail={detail}
          setVisibleQuyetToanKhachHang={setVisibleQuyetToanKH}
          visibleQuyetToanKhachHang={visibleQuyetToanKH}
        />
      ),
    // eslint-disable-next-line
    [visibleQuyetToanKH]
  );
  //modal quyết toán công ty

  const showModalQuyetToanCongTy = useMemo(
    () =>
      visibleQuyetToanCT && (
        <ModalQuyetToanCongTy
          visible={visibleQuyetToanCT}
          detail={detail}
          setVisibleQuyetToanCongTy={setVisibleQuyetToanCT}
          visibleQuyetToanCongTy={visibleQuyetToanCT}
        />
      ),
    // eslint-disable-next-line
    [visibleQuyetToanCT]
  );

  //modal trả lại áp giá

  const showModalTraLaiAG = useMemo(
    () =>
      visibleTLAG && (
        <ModalTraLaiAG
          visible={visibleTLAG}
          detail={detail}
          closeModal={handleCloseTraLaiApGia}
          setFetchDataTable={setFetchDataTable}
        />
      ),
    // eslint-disable-next-line
    [visibleTLAG]
  );

  //modal xác nhận áp giá

  const showModalApGia = useMemo(
    () =>
      visibleApGia && (
        <ModalApGia
          visible={visibleApGia}
          handleCloseModal={handleCloseApGia}
          setStateApGiaHS={setVisibleApGiaHS}
          setStateApGia={setVisibleApGia}
          setFetchDataTable={setFetchDataTable}
          detail={detail}
          closeModal={closeModal}
          step={STEP_XACNHAN_APGIA.APGIA}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFetchDataTable, visibleApGia]
  );

  const showModalApGiaHS = useMemo(
    () =>
      visibleApGiaHS && (
        <ApGiaHoSoDetail
          visible={visibleApGiaHS}
          handleCloseModal={handleCloseApGiaHS}
          setStateApGiaHS={setVisibleApGiaHS}
          setStateApGia={setVisibleApGia}
          setFetchDataTable={setFetchDataTable}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFetchDataTable, visibleApGiaHS]
  );

  const [, setLoading] = useState(false);
  const [loadingBBApGia, setLoadingBBApGia] = useState(false);
  const [visibleInBBApGia, setVisibleInBBApGia] = useState(false);
  const inBienBanApGia = () => {
    setVisibleInBBApGia(!visibleInBBApGia);
    setIsDoubleClick(false);
  };
  const showModalInBienBanApGia = useMemo(
    () =>
      visibleInBBApGia && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visibleInBBApGia}
            setVisible={setVisibleInBBApGia}
            title={`${TYPE_BIEN_BAN.AP_GIA} ${detail?.maYCKNCmis} ${detail.tenKhachHang}`}
            getBienBan={(setUrl) => showModalBBApGia(setUrl)}
            loadingGetBienBan={loadingBBApGia}
            isDownloadExcel={false}
            isReload={false}
            type={TYPE_BIEN_BAN.AP_GIA}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visibleInBBApGia]
  );

  const showModalBBApGia = useCallback(
    (setUrl) => {
      inBienBan(
        detail,
        setLoadingBBApGia,
        (value) => {
          setUrl(value);
        },
        setLoadingPDF,
        t
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );

  //BIỂU ĐỒ PHỤ TẢI
  const [loadingPhuTai, setLoadingPhuTai] = useState(false);
  const [loadingDownloadPT, setLoadinDownloadPT] = useState(false);
  const [visiblePhuTai, setVisiblePhuTai] = useState(false);
  const handleOpenInPhuTai = () => {
    setVisiblePhuTai(!visiblePhuTai);
    setIsDoubleClick(false);
  };

  //show popup
  const showModalInBieuDoPhuTai = useMemo(
    () =>
      visiblePhuTai && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visiblePhuTai}
            setVisible={setVisiblePhuTai}
            title={`In biểu đồ phụ tải`}
            getBienBan={(setUrl) => callAPIBieuDoPhuTai(setUrl)}
            loadingGetBienBan={loadingPhuTai}
            isDownloadExcel={true}
            isReload={false}
            downloadFileExcel={() =>
              downloadExcelBieuDoPT(detail, setLoadinDownloadPT)
            }
            loadingDownload={loadingDownloadPT}
            // type={TYPE_BIEN_BAN.AP_GIA}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visiblePhuTai]
  );

  //call api
  const callAPIBieuDoPhuTai = useCallback(
    (setUrl) => {
      inBieuDoPhuTai(
        detail,
        setLoadingPhuTai,
        async (values) => {
          if (values === "error-1") {
            alertMessage(
              "error",
              t("modal.thong_bao"),
              "Tổng công suất lắp đặt phải > 40"
            );
            setVisiblePhuTai(false);
            return;
          }
          if (values && Object.values(values).length > 0) {
            const labels = values?.duLieuBieuDoCongSuat?.xValues || [];
            const datasets = [
              {
                label: "Công suất",
                fill: false,
                data: values?.duLieuBieuDoCongSuat?.yValues?.data || [], // Add data to the chart
              },
            ];
            const title = values?.duLieuBieuDoCongSuat?.title || "";
            const tilteY = values?.duLieuBieuDoCongSuat?.yInfo?.tilteY || "";

            const chartImage = await generateChart(
              datasets,
              labels,
              title,
              tilteY
            );

            const blob = await ReactPDF.pdf(
              <BieuDoPhuTai
                chartImage={chartImage}
                tenDonVi={values.tenDonVi}
                tenKhachHang={values.tenKhachHang}
                diaChiSuDungDien={values.diaChiSuDungDien}
                ngayHienTai={values.ngayHienTai}
                congSuatSuDungTrungBinhNgay={values.congSuatSuDungTrungBinhNgay}
                congSuatSuDungLonNhatTrongNgay={
                  values.congSuatSuDungLonNhatTrongNgay
                }
                congSuatSuDungNhoNhatTrongNgay={
                  values.congSuatSuDungNhoNhatTrongNgay
                }
                luongDienSuDungTrungBinhThang={
                  values.luongDienSuDungTrungBinhThang
                }
                congSuatLonNhatGioCaoDiemNgay={
                  values.congSuatLonNhatGioCaoDiemNgay
                }
                congSuatLonNhatGioCaoDiemToi={
                  values.congSuatLonNhatGioCaoDiemToi
                }
              />
            ).toBlob();
            const url = URL.createObjectURL(blob);
            setUrl(url);
          } else {
            setUrl("error");
          }
        },
        setLoadingPDF,
        t
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );
  //END BIỂU ĐỒ PHỤ TẢI

  const showModalPdfKhaiGia = useMemo(
    () =>
      visibleInBieuKG && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visibleInBieuKG}
            setVisible={setVisibleInBieuKG}
            title={`${TYPE_BIEN_BAN.KHAI_GIA} ${detail?.maYCKNCmis} ${detail.tenKhachHang}`}
            getBienBan={(setUrl) => showModalPDF(setUrl)}
            downloadFileExcel={() =>
              downLoadBienBan(detail, setLoadingDownload)
            }
            setLoadingDownload={setLoadingDownload}
            loadingDownload={loadingDownload}
            loadingGetBienBan={loadingPDF}
            isDownloadExcel={true}
            isReload={true}
            type={TYPE_BIEN_BAN.KHAI_GIA}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visibleInBieuKG]
  );

  const showModalPDF = useCallback(
    (setUrl) => {
      getPDFBienBanKhaiGia(
        detail,
        (value) => {
          setUrl(value);
        },
        setLoadingPDF,
        t
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );

  return (
    <Fragment>
      <ListMenu
        t={t}
        dispatch={dispatch}
        openModal={openModal}
        ApGiaHoSoDetail={ApGiaHoSoDetail}
        detail={detail}
        closeModal={closeModal}
        setFetchDataTable={setFetchDataTable}
        handleOpenInHoSoCMIS={handleOpenInHoSoCMIS}
        inBienBanApGia={inBienBanApGia}
        handleOpenXemLaiPACD={handleOpenXemLaiPACD}
        handleOpenQuyetToanKH={handleOpenQuyetToanKH}
        handleOpenQuyetToanCT={handleOpenQuyetToanCT}
        handleOpenInBieuKG={handleOpenInBieuKG}
        setLoading={setLoading}
        handleOpenInPhuTai={handleOpenInPhuTai}
        handleTraLaiApGia={handleTraLaiApGia}
      />
      {showModalInHoSoCMIS}
      {showModalXemLaiPhuongAnCapDien}
      {showModalQuyettoanKhachHang}
      {showModalQuyetToanCongTy}
      {showModalPdfKhaiGia}
      {showModalApGia}
      {showModalApGiaHS}
      {showModalInBienBanApGia}
      {showModalInBieuDoPhuTai}
      {showModalTraLaiAG}
    </Fragment>
  );
}
