import { memo, useRef, useState, useEffect } from "react";
import { Form, Input, Button, Modal, Row, Col } from "antd";
import FormComponent from "@components/Form";
import { useTranslation } from "react-i18next";
import LoadingComponent from "@components/Loading";
import { handleSubmitForm } from "./services";
const { TextArea } = Input;

function LoaiDiemDoModal({ detailDiemDo, visible, closeModal, getListDiemDo }) {
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    handleSubmitForm(
      values,
      detailDiemDo,
      setLoading,
      closeModal,
      getListDiemDo,
      form
    );
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();

    // eslint-disable-next-line
  }, []);

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={
          detailDiemDo.id ? "Cập nhật loại điểm đo" : "Tạo mới loại điểm đo"
        }
        visible={visible}
        onCancel={closeModal}
        className="modal"
        footer={null}
      >
        <FormComponent
        autoComplete="off"
          form={form}
          name="form-submit"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            ...detailDiemDo,
            loaiDDO: detailDiemDo.loaiDDO ? detailDiemDo.loaiDDO : 1,
          }}
          scrollToFirstError
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Loại điểm đo"
                name="loaiDDO"
                className="required-field"
              >
                <Input
                  ref={inputRef}
                  placeholder="Nhập loại điểm đo"
                  type="number"
                  min={1}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Loại BCS"
                name="loaiBCS"
                className="required-field"
              >
                <Input placeholder="Nhập loại BCS" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Thời gian bán điện"
                name="tGianBDien"
                className="required-field"
              >
                <Input placeholder="Nhập thời gian bán điện" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Số thứ tự bộ chỉ số" name="sttBoChiSo">
                <Input
                  placeholder="Nhập số thứ tự bộ chỉ số"
                  type="number"
                  max={Number.MAX_SAFE_INTEGER}
                  min={Number.MIN_SAFE_INTEGER}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Mã chức năng" name="maChucNang">
                <Input
                  placeholder="Nhập mã chức năng"
                  type="number"
                  max={Number.MAX_SAFE_INTEGER}
                  min={Number.MIN_SAFE_INTEGER}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Mô tả" name="moTa">
                <TextArea placeholder="Nhập mô tả" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-submit"
                loading={loading}
              >
                {detailDiemDo.id ? t("button.cap_nhat") : t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(LoaiDiemDoModal);
