import FormComponent from "@components/Form";
import { FORMAT_DATE } from "@utils/constants";
import { Button, Col, Collapse, DatePicker, Form, Input, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { Fragment, useCallback, useEffect } from "react";

const { Panel } = Collapse;

export default function LayoutModal({
  closeModal,
  form,
  handleSubmitData,
  detail,
  dataTime,
  t,
  inputRef,
  renderPagination,
  isAdd,
  handleAddThongTin,
  handleDeleteThongTin,
  handleChangeBatDau,
  handleChangeKetThuc,
  loading,
  handleBack,
}) {
  useEffect(() => {
    if (dataTime && Object.keys(dataTime).length > 0) {
      form.setFieldsValue({
        giaTri: dataTime.giaTri,
        ngayHieuLuc:
          dataTime.ngayHieuLuc === undefined
            ? undefined
            : moment(dataTime.ngayHieuLuc),
        ngayHetHieuLuc:
          dataTime.ngayHetHieuLuc === undefined
            ? undefined
            : moment(dataTime.ngayHetHieuLuc),
      });
    }
  }, [dataTime, form]);

  const renderForm = useCallback(() => {
    return (
      <FormComponent
        form={form}
        name="form-cau-hinh"
        layout="vertical"
        onFinish={handleSubmitData}
        initialValues={{
          ...detail,
          ...dataTime,
          // ngayHieuLuc: moment(ngayBatDau, FORMAT_DATE),
          // ngayHetHieuLuc: ngayKetThuc ? moment(ngayKetThuc, FORMAT_DATE) : null,
          // giaTri: dataTime.giaTri,
        }}
        scrollToFirstError
        autoComplete="off"
      >
        <Form.Item label={t("cau_hinh.title.ten_cau_hinh")} name="tenCauHinh">
          <Input
            ref={detail.id ? null : inputRef}
            placeholder="Nhập tên cấu hình"
            disabled={detail.id ? true : false}
          />
        </Form.Item>
        {/* <Form.Item
            label={t("cau_hinh.title.gia_tri")}
            name="giaTri"
            className="required-field"
          >
            <Input
              placeholder="Nhập giá trị"
              ref={detail.id ? inputRef : null}
            />
          </Form.Item> */}
        <Form.Item label={"Đơn vị tính"} name="donViTinh">
          <Input placeholder="Nhập đơn vị tính" className="required-field" />
        </Form.Item>
        <Form.Item label={t("cau_hinh.title.ghi_chu")} name="ghiChu">
          <TextArea rows={3} placeholder="Nhập ghi chú" />
        </Form.Item>

        <Row gutter={24} style={{ marginBottom: "36px", marginTop: "12px" }}>
          <Col span={24} md={18}>
            {renderPagination()}
          </Col>
          <Col span={24} md={6} style={{ textAlign: "rgiht" }}>
            <div
              className="action-table-column"
              style={{ justifyContent: "end" }}
            >
              <div className="action-icon">
                {isAdd ? (
                  <Button type="default" onClick={handleBack}>
                    Quay lại
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={handleAddThongTin}
                    disabled={isAdd}
                  >
                    Thêm thông tin đơn giá
                  </Button>
                )}
              </div>

              <div className="action-icon">
                <Button
                  disabled={Object.keys(dataTime).length === 0 || isAdd}
                  type="danger"
                  onClick={() => handleDeleteThongTin(dataTime?.id)}
                >
                  Xóa
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Collapse defaultActiveKey={["1"]}>
          <Panel header="Thông tin đơn giá" key="1">
            <Row gutter={24}>
              <Col span={24} md={8}>
                <Form.Item
                  label="Ngày hiệu lực"
                  name="ngayHieuLuc"
                  className="required-field"
                >
                  <DatePicker
                    format={FORMAT_DATE}
                    onChange={handleChangeBatDau}
                    placeholder={FORMAT_DATE}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={8}>
                <Form.Item label="Ngày hết hiệu lực" name="ngayHetHieuLuc">
                  <DatePicker
                    format={FORMAT_DATE}
                    onChange={handleChangeKetThuc}
                    placeholder={FORMAT_DATE}
                    allowClear={true}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={8}>
                <Form.Item
                  label={t("cau_hinh.title.gia_tri")}
                  name="giaTri"
                  className="required-field"
                >
                  <Input
                    placeholder="Nhập giá trị"
                    ref={detail.id ? inputRef : null}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <Row gutter={24} justify="center" style={{ marginTop: "24px" }}>
          <Col span={12} style={{ textAlign: "right" }}>
            <Button key="back" onClick={closeModal} className="button-closed">
              {t("button.dong_lai")}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              key="submit"
              type="primary"
              className="button-primary"
              htmlType="submit"
              form="form-cau-hinh"
              loading={loading}
            >
              {detail.id ? t("button.cap_nhat") : t("button.tao_moi")}
            </Button>
          </Col>
        </Row>
      </FormComponent>
    );
  }, [
    closeModal,
    dataTime,
    detail,
    form,
    handleAddThongTin,
    handleChangeBatDau,
    handleChangeKetThuc,
    handleDeleteThongTin,
    handleSubmitData,
    inputRef,
    isAdd,
    loading,
    renderPagination,
    t,
    handleBack,
  ]);
  return <Fragment>{renderForm()} </Fragment>;
}
