import React, { Fragment } from "react";
import { Tabs } from "antd";
import ThongTinVatTuTabs from "../../tabs/ghi-nhan-ket-qua/tabs-thong-tin-vat-tu";
import ThongTinCongToTabs from "../../tabs/ghi-nhan-ket-qua/tabs-thong-tin-cong-to";
import XacNhanThiCongTabs from "../../tabs/ghi-nhan-ket-qua/tabs-xac-nhan-thi-cong";
import { ContainerBody } from "../../css-styled";
import { xacNhanThiCongSelector } from "@state/cap-dien/thi-cong/xac-nhan-thi-cong";
import { useSelector } from "react-redux";
const { TabPane } = Tabs;
export default function GhiNhanKetQuaModal(props) {
  const {
    selectedTab,
    callback,
    closeModal,
    t,
    dispatch,
    handleSubmitCongTo,
    setFetchDataTable,
    detail,
    loadingSubmit,
    initCongTo,
    setInitCongTo,
    initVatTu,
    setInitVatTu,
    textError,
    scroll,
    setScroll,
  } = props;
  const { thongTin, congTo, thiCong } = useSelector(xacNhanThiCongSelector);
  return (
    <Fragment>
      <ContainerBody className="modal-body">
        <Tabs
          defaultActiveKey={selectedTab}
          activeKey={selectedTab}
          onChange={callback}
        >
          <TabPane tab={`Thông tin vật tư ${thongTin ? " *" : ""}`} key="1">
            <ThongTinVatTuTabs
              t={t}
              dispatch={dispatch}
              closeModal={closeModal}
              selectedTab={selectedTab}
              detail={detail}
              setFetchDataTable={setFetchDataTable}
              initVatTu={initVatTu}
              setInitVatTu={setInitVatTu}
              scroll={scroll}
              setScroll={setScroll}
            />
          </TabPane>
          <TabPane tab={`Thông tin công tơ ${congTo ? " *" : ""}`} key="2">
            <ThongTinCongToTabs
              detail={detail}
              selectedTab={selectedTab}
              handleSubmitCongTo={handleSubmitCongTo}
              t={t}
              dispatch={dispatch}
              closeModal={closeModal}
              loadingSubmit={loadingSubmit}
              initCongTo={initCongTo}
              setInitCongTo={setInitCongTo}
            />
          </TabPane>
          <TabPane tab={`Xác nhận thi công ${thiCong ? " *" : ""}`} key="3">
            <XacNhanThiCongTabs
              selectedTab={selectedTab}
              t={t}
              dispatch={dispatch}
              closeModal={closeModal}
              setFetchDataTable={setFetchDataTable}
              detail={detail}
              textError={textError}
            />
          </TabPane>
        </Tabs>
      </ContainerBody>
    </Fragment>
  );
}
