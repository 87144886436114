import React, { Fragment, useState } from "react";
import {
  Col,
  Input,
  Row,
  Form,
  Select,
  DatePicker,
  Space,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import {
  FORMAT_DATE,
  ENUM_MODE_PHUONGANCAPDIEN,
  TYPE_INPUT,
} from "@utils/constants";
import moment from "moment";
import InputFormatter from "@components/InputFormatter";

export default function TramCapDienPanels(props) {
  const { listTram, listLo, listQuyen, mode } = props;
  const [format] = useState(FORMAT_DATE);
  const { t } = useTranslation();
  return (
    <Fragment>
      <Col span={24}>
        <span className="fs-16px font-bold">2. Trạm cấp điện</span>
        <Row gutter={24} className="mt-16px">
          <Col span={6}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Form.Item
                label="Trạm"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tramId !== curValues.tramId
                }
              >
                {({ getFieldValue }) => {
                  const tramId = getFieldValue("tramId") || undefined;
                  return tramId ? (
                    <Typography.Text className="ant-form-text" strong>
                      {(listTram &&
                        listTram.find((x) => x.value === tramId)?.name) ||
                        ""}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            ) : (
              <Form.Item label="Trạm" name="tramId">
                <Select
                  onChange={() => false}
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                >
                  {listTram &&
                    listTram.map((item, index) => (
                      <Select.Option key={index} value={item.value}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col span={6}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Form.Item
                label="Mã lộ"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.loId !== curValues.loId
                }
              >
                {({ getFieldValue }) => {
                  const loId = getFieldValue("loId") || undefined;
                  return loId ? (
                    <Typography.Text className="ant-form-text" strong>
                      {(listLo && listLo.find((x) => x.value === loId)?.name) ||
                        ""}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            ) : (
              <Form.Item label={t("lo.ma_lo")} name="loId">
                <Select
                  onChange={() => false}
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                >
                  {listLo &&
                    listLo.map((item, index) => (
                      <Select.Option key={index} value={item.value}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col span={6}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Form.Item
                label="Mã quyển"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.quyenId !== curValues.quyenId
                }
              >
                {({ getFieldValue }) => {
                  const quyenId = getFieldValue("quyenId") || undefined;
                  return quyenId ? (
                    <Typography.Text className="ant-form-text" strong>
                      {(listQuyen &&
                        listQuyen.find((x) => x.name === quyenId)?.name) ||
                        ""}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            ) : (
              <Form.Item label="Mã quyển" name="quyenId">
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (
                      (option &&
                        option?.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0) ||
                      (option &&
                        option?.id?.toLowerCase().indexOf(input.toLowerCase()) >=
                          0)
                    );
                  }}
                  disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                >
                  <Select.Option key={-1} value={null} id={null}>
                    Chọn quyển
                  </Select.Option>
                  {listQuyen &&
                    listQuyen.map((item, index) => (
                      <Select.Option
                        key={index}
                        value={item.name}
                        id={item.tenGCS}
                      >
                        {`${item.name} - ${item.tenGCS}`}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col span={6}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Form.Item
                label="Ngày mức tải cao nhất TBA"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.ngayMucTaiCaoNhat !== curValues.ngayMucTaiCaoNhat
                }
              >
                {({ getFieldValue }) => {
                  const ngayMucTaiCaoNhat =
                    getFieldValue("ngayMucTaiCaoNhat") || undefined;
                  return ngayMucTaiCaoNhat ? (
                    <Typography.Text className="ant-form-text" strong>
                      {`${moment(ngayMucTaiCaoNhat).format(FORMAT_DATE)}`}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            ) : (
              <Form.Item
                label="Ngày mức tải cao nhất TBA"
                name="ngayMucTaiCaoNhat"
              >
                <DatePicker
                  locale={locale}
                  format={format}
                  disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Form.Item
                label="Dung lượng MBA"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.dungLuongMBA !== curValues.dungLuongMBA
                }
              >
                {({ getFieldValue }) => {
                  const dungLuongMBA =
                    getFieldValue("dungLuongMBA") || undefined;
                  return dungLuongMBA ? (
                    <Typography.Text className="ant-form-text" strong>
                      {dungLuongMBA} kVA
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            ) : (
              <Form.Item label="Dung lượng MBA" name="dungLuongMBA">
                <InputFormatter
                  addonAfter="kVA"
                  className="input-number-right"
                  disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                  type={TYPE_INPUT.INTEGER}
                />
              </Form.Item>
            )}
          </Col>
          <Col span={6}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Form.Item
                label="Điện thế"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.dienThe !== curValues.dienThe
                }
              >
                {({ getFieldValue }) => {
                  const dienThe = getFieldValue("dienThe") || undefined;
                  return dienThe ? (
                    <Typography.Text className="ant-form-text" strong>
                      {dienThe} kV
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            ) : (
              <Form.Item label="Điện thế" name="dienThe">
                {/* <InputFormatter
                  addonAfter="kV"
                  className="input-number-right"
                  disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                  type={TYPE_INPUT.INTEGER}
                /> */}
                <Input
                  className="input-right"
                  suffix="kV"
                  disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                />
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Form.Item
                label="Điểm đấu điện"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.diemDauDien !== curValues.diemDauDien
                }
              >
                {({ getFieldValue }) => {
                  const diemDauDien = getFieldValue("diemDauDien") || undefined;
                  return diemDauDien ? (
                    <Typography.Text className="ant-form-text" strong>
                      {diemDauDien}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            ) : (
              <Form.Item label="Điểm đấu điện" name="diemDauDien">
                <Input disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI} />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Col>

      {/* start time */}
      <Col span={24}>
        <Row gutter={24}>
          <Col span={8}>
            <Row gutter={24}>
              <Col span={24}>
                <Space direction="horizontal">
                  <div className="fs-14px" style={{ marginBottom: 22 }}>
                    Từ 22h00 - 04h00:{" "}
                  </div>
                  {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.congSuat2204 !== curValues.congSuat2204
                      }
                    >
                      {({ getFieldValue }) => {
                        const congSuat2204 =
                          getFieldValue("congSuat2204") || undefined;
                        return congSuat2204 ? (
                          <Typography.Text className="ant-form-text" strong>
                            {congSuat2204} kW
                          </Typography.Text>
                        ) : null;
                      }}
                    </Form.Item>
                  ) : (
                    <Form.Item name="congSuat2204">
                      <InputFormatter
                        addonAfter="kW"
                        className="input-number-right"
                        disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                        type={TYPE_INPUT.INTEGER}
                      />
                    </Form.Item>
                  )}
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={24}>
              <Col span={24}>
                <Space direction="horizontal">
                  <div className="fs-14px" style={{ marginBottom: 22 }}>
                    Từ 04h00 - 09h30:{" "}
                  </div>
                  {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.congSuat0409 !== curValues.congSuat0409
                      }
                    >
                      {({ getFieldValue }) => {
                        const congSuat0409 =
                          getFieldValue("congSuat0409") || undefined;
                        return congSuat0409 ? (
                          <Typography.Text className="ant-form-text" strong>
                            {congSuat0409} kW
                          </Typography.Text>
                        ) : null;
                      }}
                    </Form.Item>
                  ) : (
                    <Form.Item name="congSuat0409">
                      <InputFormatter
                        addonAfter="kW"
                        className="input-number-right"
                        disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                        type={TYPE_INPUT.INTEGER}
                      />
                    </Form.Item>
                  )}
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={24}>
              <Col span={24}>
                <Space direction="horizontal">
                  <div className="fs-14px" style={{ marginBottom: 22 }}>
                    Từ 09h30 - 11h30:{" "}
                  </div>
                  {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.congSuat0911 !== curValues.congSuat0911
                      }
                    >
                      {({ getFieldValue }) => {
                        const congSuat0911 =
                          getFieldValue("congSuat0911") || undefined;
                        return congSuat0911 ? (
                          <Typography.Text className="ant-form-text" strong>
                            {congSuat0911} kW
                          </Typography.Text>
                        ) : null;
                      }}
                    </Form.Item>
                  ) : (
                    <Form.Item name="congSuat0911">
                      <InputFormatter
                        addonAfter="kW"
                        className="input-number-right"
                        disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                        type={TYPE_INPUT.INTEGER}
                      />
                    </Form.Item>
                  )}
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={24}>
          <Col span={8}>
            <Row gutter={24}>
              <Col span={24}>
                <Space direction="horizontal">
                  <div className="fs-14px" style={{ marginBottom: 22 }}>
                    Từ 11h30 - 17h00:{" "}
                  </div>
                  {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.congSuat1117 !== curValues.congSuat1117
                      }
                    >
                      {({ getFieldValue }) => {
                        const congSuat1117 =
                          getFieldValue("congSuat1117") || undefined;
                        return congSuat1117 ? (
                          <Typography.Text className="ant-form-text" strong>
                            {congSuat1117} kW
                          </Typography.Text>
                        ) : null;
                      }}
                    </Form.Item>
                  ) : (
                    <Form.Item name="congSuat1117">
                      <InputFormatter
                        addonAfter="kW"
                        className="input-number-right"
                        disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                        type={TYPE_INPUT.INTEGER}
                      />
                    </Form.Item>
                  )}
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={24}>
              <Col span={24}>
                <Space direction="horizontal">
                  <div className="fs-14px" style={{ marginBottom: 22 }}>
                    Từ 17h00 - 20h00:{" "}
                  </div>
                  {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.congSuat1720 !== curValues.congSuat1720
                      }
                    >
                      {({ getFieldValue }) => {
                        const congSuat1720 =
                          getFieldValue("congSuat1720") || undefined;
                        return congSuat1720 ? (
                          <Typography.Text className="ant-form-text" strong>
                            {congSuat1720} kW
                          </Typography.Text>
                        ) : null;
                      }}
                    </Form.Item>
                  ) : (
                    <Form.Item name="congSuat1720">
                      <InputFormatter
                        addonAfter="kW"
                        className="input-number-right"
                        disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                        type={TYPE_INPUT.INTEGER}
                      />
                    </Form.Item>
                  )}
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={24}>
              <Col span={24}>
                <Space direction="horizontal">
                  <div className="fs-14px" style={{ marginBottom: 22 }}>
                    Từ 20h00 - 22h00:{" "}
                  </div>
                  {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.congSuat2022 !== curValues.congSuat2022
                      }
                    >
                      {({ getFieldValue }) => {
                        const congSuat2022 =
                          getFieldValue("congSuat2022") || undefined;
                        return congSuat2022 ? (
                          <Typography.Text className="ant-form-text" strong>
                            {congSuat2022} kW
                          </Typography.Text>
                        ) : null;
                      }}
                    </Form.Item>
                  ) : (
                    <Form.Item name="congSuat2022">
                      <InputFormatter
                        addonAfter="kW"
                        className="input-number-right"
                        disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                        type={TYPE_INPUT.INTEGER}
                      />
                    </Form.Item>
                  )}
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      {/* end time */}
      {/* end tram cap dien */}
    </Fragment>
  );
}
