import LoadingComponent from "@components/Loading";
import { Container } from "./css-styled";
import FormVatTu from "./form/vat-tu";
import FormThoiGian from "./form/thoi-gian";

export default function Index(props) {
  const {
    detailTableData,
    loading,
    onFinishVatTu,
    onFinishVatTuThoiGian,
    ngayBatDau,
    ngayKetThuc,
    vatTuChinh,
    form1,
    form2,
    handleChangeVatTuChinh,
    handleChangeMany,
    handleChangeBatDau,
    handleChangeKetThuc,
    many,
    column,
    columns,
    dataSource,
    handleAdd,
    // closeModal,
    handleAddThongTin,
    addThongTin,
    data,
    totalCount,
    handleBackForm,
    dataTable,
    handleAddForUpdateData,
    t,
    dataLoaiChiPhi,
    dataChungLoai,
    handleChangePage,
    dataHinhThuc,
    tableColums,
    nameFields,
    handleRemove,
    handleRemoveThoiGian,
    toDay,
    setKeyData,
    pageDefault,
    countAdd,
  } = props;

  return (
    <LoadingComponent loading={loading}>
      <Container>
        <FormVatTu
          form1={form1}
          detailTableData={detailTableData}
          vatTuChinh={vatTuChinh}
          onFinishVatTu={onFinishVatTu}
          dataChungLoai={dataChungLoai}
          handleChangeVatTuChinh={handleChangeVatTuChinh}
          handleChangeMany={handleChangeMany}
          many={many}
          dataLoaiChiPhi={dataLoaiChiPhi}
          loading={loading}
          t={t}
        />
        <FormThoiGian
          handleAddThongTin={handleAddThongTin}
          addThongTin={addThongTin}
          form2={form2}
          data={data}
          ngayBatDau={ngayBatDau}
          ngayKetThuc={ngayKetThuc}
          onFinishVatTuThoiGian={onFinishVatTuThoiGian}
          handleChangeBatDau={handleChangeBatDau}
          handleChangeKetThuc={handleChangeKetThuc}
          many={many}
          column={column}
          columns={columns}
          dataTable={dataTable}
          dataSource={dataSource}
          handleAddForUpdateData={handleAddForUpdateData}
          handleAdd={handleAdd}
          handleBackForm={handleBackForm}
          loading={loading}
          t={t}
          handleChangePage={handleChangePage}
          totalCount={totalCount}
          dataHinhThuc={dataHinhThuc}
          tableColums={tableColums}
          nameFields={nameFields}
          handleRemove={handleRemove}
          handleRemoveThoiGian={handleRemoveThoiGian}
          toDay={toDay}
          setKeyData={setKeyData}
          pageDefault={pageDefault}
          countAdd={countAdd}
        />
      </Container>
    </LoadingComponent>
  );
}
