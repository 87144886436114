import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleDeleteHoSo } from "./services";
import DeleteHoSoModal from "./modal-xoa-ho-so";
function Index(props) {
  const { visible, handleCloseModal, dataRemove, setFetchDataTable } = props;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  //xóa dữ liệu

  const onFinish = () => {
    //call api xóa dữ liệu

    handleDeleteHoSo(
      dataRemove,
      setLoading,
      setFetchDataTable,
      handleCloseModal
    );
  };

  return (
    <DeleteHoSoModal
      loading={loading}
      visible={visible}
      handleCloseModal={handleCloseModal}
      onFinish={onFinish}
      t={t}
    />
  );
}
export default memo(Index);
