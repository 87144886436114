import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";
export const getDanhSachNhanVien = (
  setListLoading,
  setData,
  query,
) => {
  authGetData({
    url: `${Endpoint}?${query}`,
    setLoading: setListLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};
export const getListDonVi = (
  setListLoading,
  setData,
  query,
) => {
  authGetData({
    url: `${Endpoint}?${query}`,
    setLoading: setListLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data)
      }
    },
  });
};
export const getListNhanVien = (
  setListLoading,
  setData,
  query,
) => {
  authGetData({
    url: `${Endpoint}?${query}`,
    setLoading: setListLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data)
      }
    },
  });
};
