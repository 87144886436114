import ChartJsImage from "chartjs-to-image";

export default function generateChart(datasets, labels, title, tilteY, titleX) {
  const myChart = new ChartJsImage();
  myChart.setConfig({
    type: "line",
    data: {
      labels,
      datasets,
    },
    options: {
      title: {
        display: true,
        text: title,
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "T (giờ)",
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: false,
            },
            scaleLabel: {
              display: true,
              labelString: tilteY,
            },
          },
        ],
      },
    },
  });

  myChart.setWidth(1000).setHeight(300).setBackgroundColor("transparent");
  return myChart.toDataUrl();
}
