import React, { useState } from "react";
import XacNhanThiCongModal from "./modal-xac-nhan-thi-cong";
import { handleXacNhanThiCong } from "../../../services";

export default function Index({
  detail,
  visible,
  closeModal,
  handleCloseModalXacNhanThiCong,
  setFetchDataTable,
  checked,
  dispatch,
  t,
}) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    const hoSoId = detail.id;
    handleXacNhanThiCong(
      setFetchDataTable,
      setLoading,
      hoSoId,
      checked,
      closeModal,
      dispatch
    );
  };

  return (
    <XacNhanThiCongModal
      loading={loading}
      visible={visible}
      t={t}
      handleSubmit={handleSubmit}
      handleCloseModalXacNhanThiCong={handleCloseModalXacNhanThiCong}
    />
  );
}
