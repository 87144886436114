import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { TABLE_COLUMNS_THEO_LOAI_YEU_CAU } from "@modules/tich-hop-CRM/common-ho-so/constant";
import { dateConditionLogic } from "@modules/tich-hop-CRM/common-ho-so/functions";
import CRMFilter from "@modules/tich-hop-CRM/common-ho-so/list-bo-loc";
import { closeModal, openModal } from "@state/system-config/reducer";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { Button, Col, Form, notification, Row } from "antd";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import ThongTinChiTietTabs from "../../common-ho-so/chi-tiet-yeu-cau/index";
import { Style } from "../../css-styled";
import PhanCongKinhDoanhModal from "./modal/phan-cong-kinh-doanh-modal";
import PhanCongKyThuatModal from "./modal/phan-cong-ky-thuat-modal";
import { getLinhVucList, putPhanCong } from "./services";
function YeuCauTiepNhan() {
  const [form] = Form.useForm();
  const [form_modal] = Form.useForm();
  const [visibleDuyet, setVisibleDuyet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const donViId = useSelector((state) => state.auth.user.unitId);
  const [isDoubleClick] = useState(true);
  const dispatch = useDispatch();
  const [listLoading, setListLoading] = useState(false);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DenNgay: `${moment().format("YYYY-MM-DD")}`,
    TuNgay: `${moment().subtract(7, "d").format("YYYY-MM-DD")}`,
    DonViId: donViId,
    ...parseParams(location.search),
  });

  const listTrangThai = [
    {
      name: "Đã tiếp nhận",
      value: "4b41e789-b6f7-42ab-9beb-2c8f65d6463c",
    },
  ];

  const [dataIds, setDataIds] = useState([]);
  const [linhVucList, setLinhVucList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [linhVuc, setLinhVuc] = useState([]);

  const columns = TABLE_COLUMNS_THEO_LOAI_YEU_CAU.LOAI_1;
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setLinhVuc(
        selectedRows.map((item) => {
          return item.linhVuc;
        })
      );
      const idArray = selectedRows.map((item) => {
        return item.id;
      });
      setDataIds(idArray);
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
    selectedRowKeys,
  };

  const handleCloseModalDuyet = useCallback(() => {
    setVisibleDuyet(false);
  }, [setVisibleDuyet]);
  const handleConfirmDuyet = () => {
    if ([...new Set(linhVuc)].length > 1) {
      notification.error({
        message: "Thông báo!",
        description: "Lĩnh vực không giống nhau, vui lòng chọn lại.",
      });
    } else if (
      linhVucList.length > 1 &&
      [...new Set(linhVuc)].includes("kythuat")
    ) {
      notification.error({
        message: "Thông báo!",
        description: "Không được phép phân công cho kỹ thuật.",
      });
    } else {
      setVisibleDuyet(true);
    }
  };

  const handleSubmitDuyet = (value) => {
    const payload = {
      id: dataIds,
      userId: value.nvHienTruong || value.nvDieuDo1 || value.nvKinhDoanh,
      ...(value.ghichu && { ghiChu: value.ghichu }),
      ...(value.doi && { doiId: value.doi }),
      ...(value.nvDieuDo2 && { userId2: value.nvDieuDo2 }),
      ...(value.nguoiGiamSat && { nvGiamSat: value.nguoiGiamSat }),
    };
    putPhanCong(
      payload,
      setLoading,
      setListLoading,
      filterConditions,
      setTotal,
      setVisibleDuyet,
      form_modal,
      setSelectedRowKeys,
      location,
      setData,
      setDataIds,
      setLinhVuc
    );
  };

  const handleSearch = useCallback(
    (values) => {
      const ojbStartDate = new Date(values.TuNgay);
      const objEndDate = new Date(values.DenNgay);
      const dateCondition = dateConditionLogic(ojbStartDate, objEndDate);

      setLinhVuc([]);
      setSelectedRowKeys([]);

      const payload = {
        TuNgay: dateCondition.formatStartDate,
        DenNgay: dateCondition.formatEndDate,
        ...(values.KenhId && { KenhId: values.KenhId }),
        ...(values.LoaiYeuCau && { LoaiYeuCau: values.LoaiYeuCau }),
        ...(values.DoiId && { DoiId: values.DoiId }),
        ...(values.DonViId && { DonViId: values.DonViId }),
        ...(values.TuKhoaTimKiem && { TuKhoaTimKiem: values.TuKhoaTimKiem }),
        ...(values.LinhVuc && { LinhVuc: values.LinhVuc }),
        ...(values.TrangThai && { TrangThai: values.TrangThai }),
        pageIndex: DEFAULT_PAGEINDEX,
        pageSize: DEFAULT_PAGESIZE,
      };
      setFilterConditions(payload);
    },

    []
  );

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const showPhanCongModal = useMemo(() => {
    if (visibleDuyet) {
      if (linhVuc[0] === "kinhdoanh") {
        return (
          <PhanCongKinhDoanhModal
            handleCloseModal={handleCloseModalDuyet}
            visible={visibleDuyet}
            handleSubmit={handleSubmitDuyet}
            dataIds={dataIds}
            form={form_modal}
            loading={loading}
          />
        );
      } else {
        return (
          <PhanCongKyThuatModal
            handleCloseModal={handleCloseModalDuyet}
            visible={visibleDuyet}
            handleSubmit={handleSubmitDuyet}
            dataIds={dataIds}
            form={form_modal}
            loading={loading}
          />
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleDuyet, linhVuc, loading]);

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);
  useEffect(() => {
    getLinhVucList(
      setLoading,
      setData,
      filterConditions,
      setTotal,
      location,
      setListLoading,
      setLinhVucList,
      form
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions]);

  const detailHoSo = (record) => {
    if (isDoubleClick)
      return {
        onDoubleClick: () => {
          dispatch(
            openModal({
              content: (
                <ThongTinChiTietTabs
                  detailHoSo={record}
                  closeModal={closeModal}
                />
              ),
              size: "large",
              title: <>Chi tiết yêu cầu</>,
            })
          );
        },
      };
  };

  const clearFilter = () => {
    const payload = {
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DenNgay: `${moment().format("YYYY-MM-DD")}`,
      TuNgay: `${moment().subtract(7, "d").format("YYYY-MM-DD")}`,
      DonViId: donViId,
      LinhVuc: linhVucList[0].value,
    };
    setFilterConditions(payload);
    setSelectedRowKeys([]);
    setLinhVuc([]);

    form.setFieldsValue({
      LinhVuc: linhVucList[0].value,
    });
  };

  return (
    <Style>
      <div className="tich-hop-CRM-gdv">
        <Filter>
          <CRMFilter
            handleSearch={handleSearch}
            filterConditions={filterConditions}
            form={form}
            clearFilter={clearFilter}
            listTrangThai={listTrangThai}
          />
        </Filter>
        <div className="tich-hop-CRM-gdv-content">
          <Row gutter={24}>
            <Col span={5} offset={19}>
              <div className="yeu-cau-da-phan-cong-button">
                <Button
                  type="primary"
                  onClick={handleConfirmDuyet}
                  disabled={[...new Set(linhVuc)].length === 0}
                >
                  Phân công
                </Button>
              </div>
            </Col>
          </Row>
          <TableComponent
            loading={listLoading}
            dataSource={data}
            columns={columns}
            scrollX={1600}
            expandable={false}
            header="Danh sách"
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            rowKey="key"
            totalData={total}
            pagination={filterConditions}
            onChangePagination={onChangePagination}
            onRow={detailHoSo}
          />

          {showPhanCongModal}
        </div>
      </div>
    </Style>
  );
}

export default YeuCauTiepNhan;
