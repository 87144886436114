import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData, authDeleteData } from "@utils/request";
import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import {
  buildQueryString,
  getErrorForm,
  getErrorMessage,
  parseParams,
} from "@utils/function";
import { alertMessage } from "@utils/function";

export const handleSubmitForm = (
  values,
  setLoading,
  closeModal,
  getListDataDanhMucTram,
  detailDataTable,
  form
) => {
  const data = {
    ...values,
  };

  if (detailDataTable.id) {
    data.id = detailDataTable.id;
  }

  handleCreateUpdate(
    data,
    setLoading,
    closeModal,
    getListDataDanhMucTram,
    form
  );
};

// get list data danh muc Tram
export const handleListDataDanhMucTram = (
  setLoading,
  location,
  setDataTram,
  setTotalCount,
  filterConditions
) => {
  authGetData({
    url: `${Endpoint.GNDN_DANH_MUC_TRAM}?${buildQueryString(
      Object.keys(parseParams(location.search)).length > 0
        ? parseParams(location.search)
        : filterConditions
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataTram(
          res.data.map((item, index) => {
            return {
              ...item,
              stt: index + 1,
            };
          })
        );

        setTotalCount(res.paging.totalCount);
      } else setDataTram([]);
    },
  });
};

export const GetDataDanhMucTram = (setDanhMucTram, setLoading) => {
  authGetData({
    url: `${Endpoint.GNDN_DS_CAP_DA}?${buildQueryString()}`,
    setLoading,
    onSuccess: (res) => {
      setDanhMucTram(res.data);
    },
  });
};

export const submitThemMoiDanhMucTram = (
  bangKe,
  ghiChu,
  check,
  t,
  setLoading,
  setFetchDataTable,
  unit
) => {
  authPostData({
    url: Endpoint.GNDN_DANH_MUC_TRAM,
    method: METHOD_POST,
    payload: {
      donViId: unit,
      loaiBangKeId: bangKe,
      ghiChu: ghiChu,
      kySo: check,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

//remove data DVGN
export const handleRemoveTram = (
  ids,
  setLoading,
  getListDataDanhMucTram,
  t
) => {
  authDeleteData({
    url: Endpoint.GNDN_DANH_MUC_TRAM + "?id=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data)
        getListDataDanhMucTram();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

// create data DVGN
export const handleCreateTram = (
  data,
  setLoading,
  getListDataDanhMucTram,
  closeModal,
  form
) => {
  authPostData({
    url: Endpoint.GNDN_DANH_MUC_TRAM,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        closeModal();
        getListDataDanhMucTram();
      }
      if (res.statusCode === 422) {
        getErrorForm(res, form);
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

//update data DVGN
const handleUpdateTram = (
  data,
  setLoading,
  closeModal,
  getListDataDanhMucTram,
  form
) => {
  authPostData({
    url: Endpoint.GNDN_DANH_MUC_TRAM,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListDataDanhMucTram();
        closeModal();
      }
      if (res.statusCode === 422) {
        getErrorForm(res, form);
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

// create/update dữ liệu
const handleCreateUpdate = (
  data,
  setLoading,
  closeModal,
  getListDataDanhMucDVGiaoNhan,
  form
) => {
  if (data.id) {
    handleUpdateTram(
      data,
      setLoading,
      closeModal,
      getListDataDanhMucDVGiaoNhan,
      form
    );
  } else {
    //tạo dữ liệu
    handleCreateTram(
      data,
      setLoading,
      closeModal,
      getListDataDanhMucDVGiaoNhan,
      form
    );
  }
};
