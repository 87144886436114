import { Table, Col, Row, ConfigProvider } from "antd";
import { Fragment } from "react";
import { Column } from "@ant-design/plots";
import vn from "antd/es/locale-provider/vi_VN";
import {
  ContainerFormDangKy,
  TextMiddleFormDangKy,
  VerticalTextFormDangKy,
} from "@modules/cap-dien/nhan-vien-khao-sat/css-styled";
import BieuDoCongSuatChart from "../../../chart/line-bieu-do-cong-suat";

export default function FormDaDangKy(props) {
  const { t, data, config, columns, dataTable } = props;
  return (
    <Fragment>
      <Row gutter={24} className="mt-16px">
        <Col span={24} md={24}>
          <span className="fs-16px font-bold">
            2. {t("lap_phuong_an.thiet_bi.bieu_do_dang_ky")}
          </span>
        </Col>
      </Row>
      <Row gutter={24} className="mt-16px">
        <Col span={24} md={24}>
          <ContainerFormDangKy>
            <TextMiddleFormDangKy>
              <span className="fs-14px font-bold">
                {data?.duLieuBieuDoCongSuat?.tilte ?? ""}
              </span>
            </TextMiddleFormDangKy>
            <VerticalTextFormDangKy>
              <span className="font-bold">
                {data?.duLieuBieuDoCongSuat?.yInfo?.tilteY}
              </span>
            </VerticalTextFormDangKy>

            {/* <Column {...config} /> */}
            <BieuDoCongSuatChart config={config}/>
            <TextMiddleFormDangKy>
              <span className="font-bold">
                {data?.duLieuBieuDoCongSuat?.yValues?.titleX}
              </span>
            </TextMiddleFormDangKy>
          </ContainerFormDangKy>
        </Col>
        <Col span={24} md={24} style={{ paddingTop: 50 }}>
          <ConfigProvider locale={vn}>
            <div className="table-fixed">
              <Table
                columns={columns}
                dataSource={dataTable}
                pagination={false}
                rowKey="id"
                bordered={true}
              />
            </div>
          </ConfigProvider>
        </Col>
      </Row>
    </Fragment>
  );
}
