import { Form, Input, Button, Modal, Row, Col, DatePicker } from "antd";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import moment from "moment";
import { FORMAT_MONTH, MONTH_STRING } from "@utils/constants";
import { useCallback } from "react";
import { ContainerForm } from "../css-styled";

const { TextArea } = Input;

export default function UITaoMoi(props) {
  const {
    loading,
    visible,
    closeModal,
    form,
    onFinish,
    detail,
    handlChangeNgay,
    ngay,
    inputRef,
    parent,
  } = props;
  const renderForm = useCallback(() => {
    return (
      <FormComponent
        autoComplete="off"
        form={form}
        name="form-ke-hoach"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          ...detail,
          keHoachTiepNhan: ngay === undefined ? undefined : moment(ngay),
        }}
        scrollToFirstError
      >
        <Form.Item
          label="Tên khu vực phát triển mới khách hàng"
          name="tenKhuVuc"
          className="required-field"
        >
          <Input ref={inputRef} />
        </Form.Item>
        <Form.Item label="Địa chỉ" name="diaChi" className="required-field">
          <Input />
        </Form.Item>
        <Form.Item
          label="Kế hoạch tiếp nhận"
          name="keHoachTiepNhan"
          className="required-field"
        >
          <DatePicker
            locale={locale}
            allowClear={false}
            format={FORMAT_MONTH}
            onChange={handlChangeNgay}
            placeholder={FORMAT_MONTH}
            picker={MONTH_STRING}
          />
        </Form.Item>
        <Form.Item label="Ghi chú" name="ghiChu">
          <TextArea rows={3} />
        </Form.Item>
        <Row gutter={24} justify="center">
          {parent ? null : (
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                Đóng lại
              </Button>
            </Col>
          )}

          <Col
            span={parent ? 24 : 12}
            style={{ textAlign: parent ? "center" : "left" }}
          >
            <Button
              key="submit"
              type="primary"
              className="button-primary"
              htmlType="submit"
              form="form-ke-hoach"
              loading={loading}
            >
              Thêm mới
            </Button>
          </Col>
        </Row>
      </FormComponent>
    );
  }, [
    closeModal,
    detail,
    form,
    handlChangeNgay,
    inputRef,
    loading,
    ngay,
    onFinish,
    parent,
  ]);
  return (
    <LoadingComponent loading={loading}>
      {parent ? (
        <ContainerForm>{renderForm()}</ContainerForm>
      ) : (
        <Modal
          title="Kế hoạch tiếp nhận"
          visible={visible}
          onCancel={closeModal}
          className="modal"
          footer={null}
        >
          {renderForm()}
          {/* <FormComponent
          autoComplete="off"
          form={form}
          name="form-ke-hoach"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            ...detail,
            keHoachTiepNhan: ngay === undefined ? undefined : moment(ngay),
          }}
          scrollToFirstError
        >
          <Form.Item
            label="Tên khu vực phát triển mới khách hàng"
            name="tenKhuVuc"
            className="required-field"
          >
            <Input ref={inputRef} />
          </Form.Item>
          <Form.Item label="Địa chỉ" name="diaChi" className="required-field">
            <Input />
          </Form.Item>
          <Form.Item
            label="Kế hoạch tiếp nhân"
            name="keHoachTiepNhan"
            className="required-field"
          >
            <DatePicker
              locale={locale}
              allowClear={false}
              format={FORMAT_MONTH}
              onChange={handlChangeNgay}
              placeholder={FORMAT_MONTH}
              picker={MONTH_STRING}
            />
          </Form.Item>
          <Form.Item label="Ghi chú" name="ghiChu">
            <TextArea rows={3} />
          </Form.Item>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                Đóng lại
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-ke-hoach"
                loading={loading}
              >
                Cập nhật
              </Button>
            </Col>
          </Row>
        </FormComponent> */}
        </Modal>
      )}
    </LoadingComponent>
  );
}
