import CheckPermission from "@components/CheckPermission";
import { downloadFile } from "@utils/request";
import { Button } from "antd";
import { useCallback, useState } from "react";

export default function Index(props) {
  const { endpoint, permissionCode, text, keyButton } = props;

  const [loading, setLoading] = useState(false);

  //call api xuất excel
  const handleDownloadExcel = useCallback((endpoint) => {
    if (endpoint !== undefined)
      downloadFile({
        endpoint,
        setLoading,
      });
  }, []);

  return (
    <CheckPermission permissionCode={permissionCode}>
      <Button
        type="primary"
        className="mr-24px"
        key={keyButton ? keyButton : "export-excel"}
        onClick={() => handleDownloadExcel(endpoint)}
        loading={loading}
      >
        {text ? text : "Xuất excel"}
      </Button>
    </CheckPermission>
  );
}
