import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authPostData, authGetData } from "@utils/request";
import { isEmpty } from "lodash";

export const KIEMTRA_CHUYEN_THANG_LAMVIEC = 1;
export const HUY_CHUYEN_THANG_LAMVIEC = 2;

export const handleChuyenThangLamViec = (
  setFetchThangLamViec,
  setLoading,
  handleCloseModal,
) => {
  authPostData({
    url: `${Endpoint.CHUYEN_THANG_LAMVIEC}`,
    setLoading,
    method: METHOD_POST,
    payload: {},
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        handleCloseModal();
        setFetchThangLamViec(true);
      }
    },
  });
};
export const handlerKiemTraThangLamViec = (
  setKiemTraThangLamViec,
  setLoading,
  handleCloseModal,
  setDisplayMess,
  setFetchThangLamViec,
) => {
  authPostData({
    url: `${Endpoint.KIEMTRA_CHUYEN_THANG_LAMVIEC}`,
    setLoading,
    method: METHOD_POST,
    payload: {},
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setKiemTraThangLamViec(
          res.data.danhSachDonVis.map((item, idx) => {
            return {
              ...item,
              stt: idx + 1,
            };
          })
        );
        if (isEmpty(res.data.danhSachDonVis)) {
          handleChuyenThangLamViec(
            setFetchThangLamViec,
            setLoading,
            handleCloseModal
          );
          setDisplayMess(true);
        }

        if (!isEmpty(res.data.danhSachDonVis)) {
          setDisplayMess(false);
        }
        handleCloseModal();
      }
    },
  });
};
export const handlerKiemTraHuy = (
  setKiemTraHuy,
  setLoading,
  handleCloseModal,
  setDisplayMessHuy,
  setFetchThangLamViec,
) => {
  authPostData({
    url: `${Endpoint.KIEMTRA_HUY_CHUYEN_THANG_LAMVIEC}`,
    setLoading,
    method: METHOD_POST,
    payload: {},
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setKiemTraHuy(
          res.data.danhSachDonVis.map((item, idx) => {
            return {
              ...item,
              stt: idx + 1,
            };
          })
        );
        if (isEmpty(res.data.danhSachDonVis)) {
          handleHuyChuyenThangLamViec(
            setFetchThangLamViec,
            setLoading,
            handleCloseModal
          );
          setDisplayMessHuy(true);
        }

        if (!isEmpty(res.data.danhSachDonVis)) {
          setDisplayMessHuy(false);
        }
        handleCloseModal();
      }
    },
  });
};
export const handleHuyChuyenThangLamViec = (
  setFetchThangLamViec,
  setLoading,
  handleCloseModal,
) => {
  authPostData({
    url: `${Endpoint.HUY_CHUYEN_THANG_LAMVIEC}`,
    setLoading,
    method: METHOD_POST,
    payload: {},
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        handleCloseModal();
        setFetchThangLamViec(true);
      }
    },
  });
};

export const getThangLamViec = (setThangLamViec, setLoading) => {
  authGetData({
    url: `${Endpoint.GET_THANGLAMVIEC}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setThangLamViec(res.data);
      }
    },
  });
};
