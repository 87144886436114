import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import Table from "./table";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  TABINDEX_CMIS,
} from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import {
  handleListData,
  handleListNguoiDuocUyQuyen,
  handleRemoveData,
} from "./services";
import UyQuyenModal from "./modal-uy-quyen";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import { useLocation, useSearchParams } from "react-router-dom";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import { tien_ich_chung } from "@permissions/m_tienichchung";
import CheckPermission from "@components/CheckPermission";
import { Endpoint } from "@utils/endpoint";
import { dongBoCMIS } from "../services";

export default function Index() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [detailUyQuyen, setDetailUyQuyen] = useState({});
  const [loading, setLoading] = useState(true);
  const [dataUyQuyen, setDataUyQuyen] = useState([]);
  const [dataNguoiDuocUyQuyen, setDataNguoiDuocUyQuyen] = useState([]);
  const { user } = useSelector(authSelector);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });
  //khai bái columns

  const columns = [
    {
      title: t("uy_quyen.title.ma_don_vi"),
      dataIndex: "maDoViQuanLy",
      key: "maDoViQuanLy",
      width: "10%",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: t("uy_quyen.ten_nguoi_duoc_uy_quyen"),
      dataIndex: "tenNguoiDuocUyQuyen",
      key: "tenNguoiDuocUyQuyen",
      width: "15%",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: t("uy_quyen.username_nguoi_duoc_uy_quyen"),
      dataIndex: "tenDangNhapNguoiDuocUyQuyen",
      key: "tenDangNhapNguoiDuocUyQuyen",
      width: "15%",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: t("uy_quyen.title.so_quyet_dinh"),
      dataIndex: "soQuyetDinh",
      key: "soQuyetDinh",
      width: "10%",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: t("uy_quyen.title.ngay_quyet_dinh"),
      dataIndex: "ngayQuyetDinh",
      key: "ngayQuyetDinh",
      width: "10%",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Ngày hiệu lực",
      dataIndex: "ngayHieuLuc",
      key: "ngayHieuLuc",
      width: "10%",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: t("uy_quyen.title.ngay_het_han"),
      dataIndex: "ngayHetHan",
      key: "ngayHetHan",
      width: "10%",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: t("tac_vu"),
      key: "action",
      align: "center",
      render: (row) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={tien_ich_chung.m110s}>
            <div className="action-icon" onClick={() => handleOpenModal(row)}>
              <PencilIcon />
            </div>
          </CheckPermission>
          <CheckPermission permissionCode={tien_ich_chung.m110x}>
            <div
              className="action-icon"
              onClick={() => handleDeleteData(row.id)}
            >
              <TrashIcon />
            </div>
          </CheckPermission>
        </div>
      ),
    },
  ];

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);
  //fetch dữ liệu

  const getListData = useCallback(() => {
    handleListData(setLoading, filterConditions, setDataUyQuyen, setTotalCount);
  }, [filterConditions]);

  useEffect(() => {
    getListData();
    // eslint-disable-next-line
  }, [getListData]);

  //thay đổi pagination table

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };
  // Hiển thị  các buttom tạo mới, ...
  const genExtra = () => (
    <CheckPermission permissionCode={tien_ich_chung.m110cmis}>
      <Button
        size="small"
        type="primary"
        className="btn-bg-yellow"
        onClick={handleDongBoCMIS}
        loading={loading}
      >
        Đồng bộ CMIS
      </Button>
    </CheckPermission>
  );

  const handleDongBoCMIS = useCallback(() => {
    const url = Endpoint.DONG_BO_CMIS + `?tabIndex=${TABINDEX_CMIS.UY_QUYEN}`;
    dongBoCMIS(url, setLoading, getListData);
  }, [getListData]);

  //tìm kiếm dữ liệu
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);
  //xóa dữ liệu

  const handleDeleteData = useCallback(
    (ids) => {
      handleRemoveData(ids, setLoading, getListData, t);
    },
    [getListData, t]
  );
  //hiển thị popup, set dữ liệu chi tiết

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDetailUyQuyen(row);
      else setDetailUyQuyen({});
      setVisible(!visible);
    },
    [visible]
  );
  //đóng popup

  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
  };
  //render popup

  const showModal = useMemo(
    () =>
      visible && (
        <UyQuyenModal
          detaiDataTable={detailUyQuyen}
          visible={visible}
          closeModal={handleCloseModal}
          dataNguoiDuocUyQuyen={dataNguoiDuocUyQuyen}
          unitUser={user.unitName}
          getListData={getListData}
        />
      ),
    // eslint-disable-next-line
    [visible]
  );

  useEffect(() => {
    handleListNguoiDuocUyQuyen(setLoading, setDataNguoiDuocUyQuyen);
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Table
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        genExtra={genExtra}
        dataSource={dataUyQuyen}
        columns={columns}
        totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        t={t}
      />
      {showModal}
    </Fragment>
  );
}
