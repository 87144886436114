import { createSlice } from "@reduxjs/toolkit";
export const namespace = "tiepNhanPhanCongLoaiYC";

const INITAL_STATE = {
  loaiYeuCau: null,
};

const slice = createSlice({
  name: namespace,
  initialState: INITAL_STATE,
  reducers: {
    saveLoaiYeuCauDichVu: (state, action) => {
      return {
        ...state,
        loaiYeuCau: action.payload,
      };
    },
  },
});

export const reducer = slice.reducer;

export const { saveLoaiYeuCauDichVu } = slice.actions;

export const tiepNhanPhanCongSelector = (state) => state[namespace];
