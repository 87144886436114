import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { alertMessage } from "@utils/function";
import { Endpoint } from "@utils/endpoint";

import { authPostData, startDelete } from "@utils/request";

// call api xác nhận bảng kê

export const handleXacNhanBangKe = (
  selectedRowKeys,
  setLoading,
  setFetchDataTable,
  handleCloseModalXN,
  setSelectedRowKeys
) => {
  const data = {
    bangKeIds: selectedRowKeys.toString(),
  };
  authPostData({
    url: Endpoint.XACNHAN_BANGKE,
    setLoading,
    method: METHOD_PUT,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModalXN();
        setSelectedRowKeys([]);
      } else {
        alertMessage("error", "Thông báo", res.message);
      }
    },
  });
};
// call api xóa yêu cầu

export const handleXoaYeuCau = (
  selectedRowKeys,
  setLoading,
  setFetchDataTable,
  handleCloseModal,
  setSelectedRowKeys
) => {
  const congToIds = selectedRowKeys.toString();
  startDelete({
    url: `${Endpoint.XOA_YEUCAU}?congToIds=${congToIds}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModal();
        setSelectedRowKeys([]);
      } else {
        alertMessage("error", "Thông báo", res.message);
      }
    },
  });
};
// call api đồng bộ công tơ lên cmis

export const handleDongBoLenCmis = (
  selectedRowKeys,
  setLoading,
  setFetchDataTable,
  handleCloseModalXN,
  setSelectedRowKeys
) => {
  const data = {
    congToIds: selectedRowKeys.toString(),
  };
  authPostData({
    url: Endpoint.DONGBO_LEN_CMIS,
    setLoading,
    method: METHOD_PUT,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModalXN();
        setSelectedRowKeys([]);
      } else {
        alertMessage("error", "Thông báo", res.message);
      }
    },
  });
};

// call api xác nhận bảng kê

export const handleLeadXNBangKe = (
  selectedRowKeys,
  setLoading,
  setFetchDataTable,
  handleCloseModalXN,
  setSelectedRowKeys,
  maLoaiDanhSach
) => {
  const data = {
    maLoaiDanhSach: maLoaiDanhSach,
    bangKeIds: selectedRowKeys.toString(),
  };
  authPostData({
    url: Endpoint.LEAD_XACNHAN_BANGKE,
    setLoading,
    method: METHOD_PUT,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModalXN();
        setSelectedRowKeys([]);
      } else {
        alertMessage("error", "Thông báo", res.message);
      }
    },
  });
};

// call api cập nhật chỉ số EVN HES

export const handleEVNHES = (
  selectedRowKeys,
  setLoading,
  setFetchDataTable,
  handleCloseModalXN,
  setSelectedRowKeys,
  ngayPhucTra,
  setDisableMTB,
  setDataMesEVNHES
) => {
  const data = {
    ngayPhucTra: ngayPhucTra,
    congToIds: selectedRowKeys.toString(),
  };
  authPostData({
    url: Endpoint.CHI_SO_EVN_HES,
    setLoading,
    method: METHOD_PUT,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setDataMesEVNHES(res.data);
        setFetchDataTable(true);
        handleCloseModalXN();
        setSelectedRowKeys([]);
        setDisableMTB(false);
      } else {
        alertMessage("error", "Thông báo", res.message);
      }
    },
  });
};

// call api chuyển MTB

export const handleMTB = (
  selectedRowKeys,
  setLoading,
  setFetchDataTable,
  handleCloseModalXN,
  setSelectedRowKeys,
  setDisableMTB
) => {
  const data = {
    congToIds: selectedRowKeys.toString(),
  };
  authPostData({
    url: Endpoint.CHUYEN_MTB_KTCT,
    setLoading,
    method: METHOD_PUT,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModalXN();
        setSelectedRowKeys([]);
        setDisableMTB(true);
      } else {
        alertMessage("error", "Thông báo", res.message);
      }
    },
  });
};
