import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useEffect
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Filter from "@components/Filter/Filter";
import { TableStyled, Container } from "./css-styled";
import { Tooltip, Tag, Modal } from "antd";
import { authSelector } from "@state/auth/reducer";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE, AP_GIA_KTGS, ENUM_MUCDICH_SDD } from "@utils/constants";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import {
  handleListData, submitChuyenKTGS
} from "./services";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilter } from "../../common/list-bo-loc";
import CheckPermission from "@components/CheckPermission";
import { ap_gia } from "@permissions/e_apgia";
import TableComponent from "@components/TableComponent";
import ButtonComponent from "@components/Button";
import { openModal } from "@state/system-config/reducer";
import ChiTietDiemDo from "../../common/chi-tiet-diem-do";
import { FilePdfOutlined, FileOutlined } from "@ant-design/icons";
// import moment from "moment";

export default function Index() {
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);
  const [dataKTGS, setDataKTGS] = useState([]);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const location = useLocation();
  // const [biTraLai, setBiTraLai] = useState(false);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    TrangThai: AP_GIA_KTGS.CHUA_CHUYEN_KTGS,
    // ThangKiemTra: moment().format(FORMAT_MONTH_ENGLISH),
    // LoaiKhachHang: ENUM_MUCDICH_SDD.SINHHOAT,
    ...parseParams(location.search),
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const onChangePagination = (paging, _filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setSelectedRowKeys([]);
  };
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
      Tram: values.Tram
        ? Array.isArray(values.Tram) && values.Tram.length > 0
          ? values.Tram.join()
          : values.Tram
        : [],
      // BiTraLai: biTraLai ? biTraLai : null
    }));
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      TrangThai: AP_GIA_KTGS.CHUA_CHUYEN_KTGS,
      // LoaiKhachHang: ENUM_MUCDICH_SDD.SINHHOAT,
      // ThangKiemTra: moment().format(FORMAT_MONTH_ENGLISH),
    });
  }, [user.unitId]);
  //search list
  const getListData = useCallback(() => {
    handleListData(location,
      filterConditions,
      setLoading,
      setDataKTGS,
      setTotalCount,
      setSelectedRowKeys
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getListData();
  }, [getListData, user]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListData();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListData, setFetchDataTable]);

  // const checkTraLai = (e) => {
  //   setBiTraLai(e.target.checked);
  // };

  const detailDiemDo = (record) => {
    console.log(record);
    return dispatch(
      openModal({
        content: (
          <ChiTietDiemDo
            detailRecord={record}
          />
        ),
        size: "large",
        title: `Chi tiết điểm đo - ${record.maDiemDo}`
      })
    );
  };

  const detailDD = (record) => {
    return {
      onDoubleClick: () => {
        detailDiemDo(record)
      },
    };
  };

  const columns = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => detailDiemDo(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
      fixed: "left"
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      key: "maKhachHang",
      render: (text) => (
        <span>{text}</span>
      ),
      width: "10%",
      sorter: true,
      fixed: "left"
    },
    {
      title: "Tên KH",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => <span>{text}</span>,
      width: "12%",
      sorter: true,
    },
    {
      title: "Điện thoại",
      dataIndex: "soDienThoai",
      key: "soDienThoai",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: 'Trước kiểm tra',
      children: [
        {
          title: 'Ngày áp dụng',
          dataIndex: "ngayApDung",
          key: "ngayApDung",
          render: (text) => (
            <div className="table-text-center">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
        {
          title: 'Số hộ',
          dataIndex: "soHo",
          key: "soHo",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
        },
        {
          title: "Chuỗi giá",
          dataIndex: "chuoiGia",
          key: "chuoiGia",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        }
      ],
    },
    {
      title: 'Sau kiểm tra',
      children: [
        {
          title: 'Ngày áp dụng',
          dataIndex: "ngayApDungMoi",
          key: "ngayApDungMoi",
          render: (text) => (
            <div className="table-text-center">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
        {
          title: 'Số hộ',
          dataIndex: "soHoMoi",
          key: "soHoMoi",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
        },
        {
          title: "Chuỗi giá",
          dataIndex: "chuoiGiaMoi",
          key: "chuoiGiaMoi",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        }
      ],
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text, record) => (
        record.trangThai === AP_GIA_KTGS.DA_CHUYEN_KTGS ?
          <Tag style={{ display: "block", textAlign: "center" }} color={"#26c0e9"}>{"Đã chuyển KTGS"}</Tag> :
          <Tag style={{ display: "block", textAlign: "center" }} color={"#faad14"}>{"Chưa chuyển KTGS"}</Tag>
      ),
      width: "8%",
    },
    // {
    //   title: "Tháng kiểm tra",
    //   dataIndex: "thangKiemTra",
    //   key: "thangKiemTra",
    //   render: (text) => (
    //     <div className="table-text-center">
    //       <span>{text}</span>
    //     </div>
    //   ),
    //   width: "7%",
    // },
    {
      title: "Loại khách hàng",
      dataIndex: "loaiKhachHang",
      key: "loaiKhachHang",
      render: (text, record) => (
        record.loaiKhachHang?.toString() === ENUM_MUCDICH_SDD.NGOAISINHHOAT ?
          <span>{"Ngoài sinh hoạt"}</span> :
          <span>{"Sinh hoạt"}</span>
      ),
      width: "8%",
    },
    {
      title: "Trạm",
      dataIndex: "tram",
      key: "tram",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: "Sổ GCS",
      dataIndex: "soGCS",
      key: "soGCS",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => <span>{text}</span>,
      width: "16%",
    },
    {
      title: "Mã bảng kê",
      dataIndex: "maBangKe",
      key: "maBangKe",
      render: (text) => <span>{text}</span>,
      width: "9%",
    },
    {
      title: "Tác vụ",
      dataIndex: "",
      key: "tacVu",
      render: (text, record) => (
        <div className="table-text-center">
          {record.pathFileBienBan ?
            <CheckPermission permissionCode={ap_gia.e24x}>
              <Tooltip title="Xem biên bản">
                <a
                  href={record.pathFileBienBan}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FilePdfOutlined
                    style={{ fontSize: "22px" }}
                  />
                </a>
              </Tooltip>
            </CheckPermission>
            : null
          }
          {record.pathFileBienBanGiay ?
            <CheckPermission permissionCode={ap_gia.e24x}>
              <Tooltip title="Xem BBAG giấy">
                <a
                  href={record.pathFileBienBanGiay}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FileOutlined
                    style={{ fontSize: "22px", marginLeft: '10px' }}
                  />
                </a>
              </Tooltip>
            </CheckPermission> : null}
        </div>
      ),
      width: "4%",
      fixed: "right"
    },
  ];

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: "3%",
    fixed: "left",
    getCheckboxProps: (record) => ({
      disabled:
        record.trangThai === AP_GIA_KTGS.DA_CHUYEN_KTGS,
    }),
  };

  const handleChuyenKTGS = () => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: "Bạn muốn đồng bộ các yêu cầu đã chọn về Web?",
      onOk() {
        //call api
        submitChuyenKTGS(setLoading, setFetchDataTable, selectedRowKeys, setSelectedRowKeys);
      },
      onCancel() { },
      okText: "Đồng ý",
      cancelText: "Hủy",
    });
  };


  const genExtra = useCallback(
    () => (
      <div>
        <CheckPermission permissionCode={ap_gia.e24c}>
          <ButtonComponent
            type="primary"
            style={{ marginRight: "10px" }}
            onClick={() => handleChuyenKTGS()}
            disabled={selectedRowKeys.length === 0}
          >
            Chuyển KTGS
          </ButtonComponent>
        </CheckPermission>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRowKeys]
  );
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          // checkTraLai={checkTraLai}
          screen="KTGS"
        />
      </Filter>
      <Container>
        <TableStyled>
          <TableComponent
            header={"Chuyển kiểm tra giám sát"}
            handleSearch={handleSearch}
            dataSource={dataKTGS}
            columns={customColumn(columns, filterConditions)}
            totalData={totalCount}
            renderExtraAction={() => genExtra()}
            loading={loading}
            scrollX={3000}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            rowSelection={rowSelection}
            rowKey="id"
            onRow={detailDD}
          />
        </TableStyled>
      </Container>
    </Fragment>
  );
}
