import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  authGetData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";
import TaiAnhHoSoKhachHang from "../../common-ho-so/tai-anh-ho-so-khach-hang";
// call api thông tin hồ sơ

export const handleDetailHoSoApGia = (setLoading, setDetailApGia, hoSoId) => {
  authGetData({
    url: Endpoint.GET_HO_SO_AP_GIA + "?hoSoId=" + hoSoId,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDetailApGia(res.data);
      } else {
        setDetailApGia({});
      }
    },
  });
};
// call api áp giá hồ sơ

export const handleApGiaHoSo = (
  setLoadingSubmit,
  setFetchDataTable,
  dispatch,
  closeModal,
  hoSoId
) => {
  authPostData({
    url: Endpoint.AP_GIA_HO_SO,
    method: METHOD_POST,
    payload: { hoSoId: hoSoId },
    setLoading: setLoadingSubmit,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        dispatch(closeModal());
      }
    },
  });
};
// call api xác nhận chuyển áp giá

export const handleChuyenApGia = (
  t,
  setLoading,
  handleCloseModal,
  setFetchDataTable,
  hoSoId
) => {
  authPostData({
    url: Endpoint.XAC_NHAN_CHUYEN_AP_GIA,
    setLoading,
    payload: { hoSoId: hoSoId },
    method: METHOD_POST,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModal();
      }
    },
  });
};

//trả lại áp giá
export const handleTraLaiApGia = (
  setLoading,
  closeModal,
  setFetchDataTable,
  payload
) => {
  authPostData({
    url: Endpoint.TRA_LAI_AP_GIA_HO_SO,
    setLoading,
    payload,
    method: METHOD_PUT,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        closeModal();
      }
    },
  });
};
// call api xem lại phương án cấp điện

export const handleXemLaiPACD = (
  detail,
  setDataPAXemLai,
  setVisibleXemLaiPACD,
  visibleXemLaiPACD
) => {
  const endpoints = [
    `${Endpoint.KS_GET_PHUONG_AN_CAP_DIEN}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    `${Endpoint.KS_TONG_CONG_SUAT}?HoSoId=${detail?.hoSoId ?? detail?.id}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading: () => false,
    onSuccess: (res) => {
      if (res[0]?.data !== null && res[1]?.data !== null) {
        setDataPAXemLai({ ...res[0].data.data, ...res[1].data.data });
        setVisibleXemLaiPACD(!visibleXemLaiPACD);
      }
    },
  });
};

export const checkPermissionViewImage = (
  detail,
  setLoading,
  dispatch,
  openModal,
  closeModal
) => {
  authPostData({
    url: Endpoint.CHECK_SHOW_IMAGE,
    payload: {
      hoSoId: detail.id,
    },
    method: METHOD_POST,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        // setIsCheck(res.data);
        dispatch(
          openModal({
            content: (
              <TaiAnhHoSoKhachHang
                closeModal={closeModal}
                detail={detail}
                isCheck={res.data}
              />
            ),
            size: "large",
          })
        );
      }
    },
  });
};
