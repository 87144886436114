import { STATUSCODE_200 } from "@utils/constants";
import { authGetData, downloadFile } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { alertMessage422 } from "@modules/bao-cao-kinh-doanh/service";

export const selectNganHang = [
  { label: "Tất cả", value: "-1" },
  { label: "N01 - AnBinh Bank", value: "N01" },
  { label: "N02 - BIDV", value: "N02" },
  { label: "N03 - Agribank", value: "N03" },
  { label: "N04 - VIB", value: "N04" },
  { label: "N05 - HD Bank", value: "N05" },
  { label: "N06 - Vietcombank", value: "N06" },
  { label: "N07 - LienVietBank", value: "N07" },
  { label: "N08 - Techcombank", value: "N08" },
  { label: "N09 - SACOMBANK", value: "N09" },
  { label: "N10 - VIETINBANK", value: "N10" },
  { label: "N11 - SCBank", value: "N11" },
  { label: "N12 - MBBank", value: "N12" },
  { label: "N13 - MariTimeBank", value: "N13" },
  { label: "N14 - SHB", value: "N14" },
  { label: "N15 - ACB", value: "N15" },
  { label: "N16 - Eximbank", value: "N16" },
  { label: "N17 - OCBank", value: "N17" },
  { label: "N18 - VPBank", value: "N18" },
  { label: "T01 - ECPAY", value: "T01" },
  { label: "T02 - Payhoo", value: "T02" },
  { label: "T03 - BANKPLUS", value: "T03" },
  { label: "T04 - MoMo", value: "T04" },
  { label: "T05 - VTCPay", value: "T05" },
  { label: "T06 - VNPAY", value: "T06" },
  { label: "T07 - ZALO PAY", value: "T07" },
  { label: "T08 - VIMO", value: "T08" },
  { label: "T09 - Ngan Luong", value: "T09" },
  { label: "T10 - VNPTPAY", value: "T10" },
  { label: "T11 - PayTech", value: "T11" },
  { label: "T12 - BuuDienPos", value: "T12" },
];

export const selectTHTT = [
  {
    label: "1. Báo cáo tổng hợp sổ thu theo các phương thức và hình thức thu",
    value: Endpoint.BCCN_BC_TH_SO_THU_THEO_CAC_PHUONG_THUC_VA_HINH_THUC_THU,
  },
  {
    label: "2. Báo cáo tình hình thu nộp qua ngân hàng",
    value: Endpoint.BCCN_BC_TH_THU_NOP_QUA_NH,
  },
  {
    label: "3. Báo cáo số thu dịch vụ tiền điện qua ngân hàng/TCTGTT",
    value: Endpoint.BCCN_BC_SO_THU_DICH_VU_TIEN_DIEN_QUA_NH_TGTGTT,
  },
  {
    label: "4. Báo cáo chi tiết khách hàng trích nợ tự động",
    value: Endpoint.BCCN_BC_CT_KHACHHANG_TRICH_NO_TU_DONG,
  },
  {
    label: "5. Báo cáo số thu dịch vụ tiền điện qua ngân hàng/TCTGTT theo ngày",
    value: Endpoint.BCCN_BC_SO_THU_DICH_VU_TIEN_DIEN_QUA_NH_TGTGTT_THEONGAY,
  },
  {
    label:
      "6. Báo cáo số thu dịch vụ tiền điện qua ngân hàng/TCTGTT theo đơn vị",
    value: Endpoint.BCCN_BC_SO_THU_DICH_VU_TIEN_DIEN_QUA_NH_TGTGTT_DONVI,
  },
  {
    label:
      "7. Tổng hợp khách hàng thanh toán qua hình thức điện tử trong vòng 24h/48h kể từ thời điểm phát hành hóa đơn",
    value: Endpoint.BCCN_TH_THANH_TOAN_24_48H,
  },
   {
    label: "8. Tổng hợp thu CSKH của các đơn vị",
    value: Endpoint.BCCTDVKH_TH_THU_CSKH_CUA_CAC_DON_VI,
  },
  {
    label: "9. Biểu 10 (1761) Thu hộ của các tổ chức trung gian và ngân hàng",
    value: Endpoint.BCCN_THU_HO_CAC_TCTG_VA_NGAN_HANG,
  },
  {
    label:
      "10. Biểu 11 (1761) Sổ thu dịch vụ tiền điện qua ngân hàng/Tổ chức TGTT",
    value: Endpoint.BCCN_BIEU11_SO_THU_DICH_VU_TIEN_DIEN_NGAN_HANG_TO_CHUC_TGTT,
  },
  {
    label: "11. Biểu 13 (1761) Tổng hợp khách hàng thanh toán phí đóng cắt điện",
    value: Endpoint.BCCN_TONG_HOP_KHACH_HANG_THANH_TOAN_PHI_CAT_DIEN,
  },
];

export const exportData = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LuyKe: data.LuyKe,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      LoaiBC: data.LoaiBC,
      Bank: data.BankId,

      LoaiKhachHang: data.LoaiKhachHang,
    })
  );
  authGetData({
    url: `${data.reportType}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LuyKe: data.LuyKe,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      LoaiBC: data.LoaiBC,
      Bank: data.BankId,

      LoaiKhachHang: data.LoaiKhachHang,
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LuyKe: data.LuyKe,
      LoaiBC: data.LoaiBC,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      Bank: data.BankId,

      LoaiKhachHang: data.LoaiKhachHang,
    })
  );

  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
