import { Bar } from "@ant-design/plots";
import { TongHopTrangThaiContainer } from "../css-styled";
export default function Index(props) {
  const { config, titleP3 } = props;
  return (
    <TongHopTrangThaiContainer>
      <h2>III. Tổng hợp yêu cầu cấp điện theo trạng thái</h2>
      <div className="container">
        <div className="box">
          <h3>{titleP3}</h3>
          <Bar {...config} />
        </div>
      </div>
    </TongHopTrangThaiContainer>
  );
}
