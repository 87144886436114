import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { tien_ich_chung } from "@permissions/m_tienichchung";
import CheckPermission from "@components/CheckPermission";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import TyLeBanDienModal from "./modal-ty-le-ban-dien";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import Table from "./table";
import { handleListData, handleRemoveData } from "./services";

export default function Index() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [detailTyLeBanDien, setDetailTyLeBanDien] = useState({});
  const { user } = useSelector(authSelector);
  const [loading, setLoading] = useState(true);
  const [dataGiaBanDien, setDataGiaBanDien] = useState([]);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });
  //khai bái columns

  const columns = [
    {
      title: "Mã mục đích",
      dataIndex: "maMucDich",
      key: "maMucDich",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
      sorter: true,
    },
    {
      title: t("ti_le_ban_dien.muc_dich_su_dung"),
      dataIndex: "mucDichSuDung",
      key: "mucDichSuDung",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "25%",
      sorter: true,
    },
    {
      title: t("ti_le_ban_dien.gia_ban_binh_thuong"),
      dataIndex: "gioBinhThuong",
      key: "gioBinhThuong",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
      sorter: true,
    },
    {
      title: t("ti_le_ban_dien.gia_ban_cao_diem"),
      dataIndex: "gioCaoDiem",
      key: "gioCaoDiem",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
      sorter: true,
    },
    {
      title: t("ti_le_ban_dien.gia_ban_thap_diem"),
      dataIndex: "gioThapDiem",
      key: "gioThapDiem",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: t("ti_le_ban_dien.gia_ban_kt"),
      dataIndex: "gioKT",
      key: "gioKT",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },

    {
      title: t("tac_vu"),
      key: "action",
      align: "center",
      render: (row) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={tien_ich_chung.m19s}>
            <div className="action-icon" onClick={() => handleOpenModal(row)}>
              <PencilIcon />
            </div>
          </CheckPermission>
          <CheckPermission permissionCode={tien_ich_chung.m19x}>
            <div
              className="action-icon"
              onClick={() => handleDeleteData(row.id)}
            >
              <TrashIcon />
            </div>
          </CheckPermission>
        </div>
      ),
    },
  ];

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);
  //fetch dữ liệu

  const getListData = useCallback(() => {
    handleListData(
      setLoading,
      filterConditions,
      setDataGiaBanDien,
      setTotalCount
    );
  }, [filterConditions]);

  useEffect(() => {
    getListData();
    // eslint-disable-next-line
  }, [getListData]);
  //thay đổi pagination table

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };
  // Hiển thị  các buttom tạo mới, ...

  const genExtra = () => (
    <div>
      <CheckPermission permissionCode={tien_ich_chung.m19t}>
        <Button
          size="small"
          type="primary"
          className="mr-5px"
          onClick={() => handleOpenModal()}
        >
          {t("button.tao_moi")}
        </Button>
      </CheckPermission>
    </div>
  );
  //xóa dữ liệu

  const handleDeleteData = useCallback(
    (ids) => {
      handleRemoveData(ids, setLoading, getListData, t);
    },
    [getListData, t]
  );
  //tìm kiếm dữ liệu

  const handleSearch = useCallback((values) => {
    setFilterConditions(() => ({
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);
  //hiển thị popup, set dữ liệu chi tiết

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDetailTyLeBanDien(row);
      else setDetailTyLeBanDien({});
      setVisible(!visible);
    },
    [visible]
  );
  //đóng popup

  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
  };
  //render popup

  const showModal = useMemo(
    () =>
      visible && (
        <TyLeBanDienModal
          detaiDataTable={detailTyLeBanDien}
          visible={visible}
          unitUser={user.unitName}
          closeModal={handleCloseModal}
          getListData={getListData}
        />
      ),
    // eslint-disable-next-line
    [visible]
  );

  return (
    <Fragment>
      <Table
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        genExtra={genExtra}
        dataSource={dataGiaBanDien}
        columns={columns}
        totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        t={t}
      />
      {showModal}
    </Fragment>
  );
}
