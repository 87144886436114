import React, { Fragment } from "react";
import { authSelector } from "@state/auth/reducer";
import { useSelector } from "react-redux";
export default function CheckPermission(props) {
  const { user } = useSelector(authSelector);
  const { permissionCode } = props;
  if (
    !user.isAdministrator &&
    permissionCode &&
    user &&
    user.permission &&
    user.permission.length &&
    !user.permission.find((value) => value.includes(permissionCode))
  )
    return null;

  return <Fragment>{props.children}</Fragment>;
}
