import {
  buildQueryString,
  formatSplitDate,
  handlePagination,
  // handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { handleListBaoCao } from "./services";
import BaoCaoChatLuongTable from "./table";
import { ENUM_BUTTON_TYPE, FORMAT_MONTH_ENGLISH } from "@utils/constants";
import moment from "moment";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import { downloadFile } from "@utils/request";

export default function Index() {
  const { user } = useSelector(authSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const toDay = moment().format(FORMAT_MONTH_ENGLISH);
  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);
  //filter
  const [filterConditions, setFilterConditions] = useState({
    // pageIndex: DEFAULT_PAGEINDEX,
    // pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    donViId: searchParams.get("donViId")
      ? searchParams.get("donViId")
      : user.unitId,
    ngayBaoCao: searchParams.get("ngayBaoCao")
      ? searchParams.get("ngayBaoCao")
      : formatSplitDate(toDay, true),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //change pagination
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  };

  //fetch báo cáo
  const getListBaoCao = useCallback(() => {
    handleListBaoCao(filterConditions, setLoading, setData, setTotalCount);
  }, [filterConditions]);

  const [filterExcel, setFilterExcel] = useState(false);
  const [filterExcelChiTiet, setFilterExcelChiTiet] = useState(false);

  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL_CHI_TIET) {
      setFilterExcelChiTiet(true);
    } else {
      setFilterExcel(false);
      setFilterExcelChiTiet(false);
    }
  }, [type]);
  //search
  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        // pageIndex: DEFAULT_PAGEINDEX,
        ...values,
        donViId: values.donViId,
        ngayBaoCao: formatSplitDate(values.ngayBaoCao, true),
      }));
      handleAPI();
    },
    [handleAPI]
  );

  //clear search
  const clearFilter = useCallback(() => {
    setFilterConditions({
      // donViId: undefined,
      ngayBaoCao: formatSplitDate(toDay, true),
      // pageIndex: DEFAULT_PAGEINDEX,
      // pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
      // thangBaoCao: moment(moment(), FORMAT_MONTH),
    });
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  }, [toDay, user.unitId]);

  useEffect(() => {
    if (filterExcel) {
      const endpoint = `${
        Endpoint.EXPORT_EXCEL_BAO_CAO_CHI_TIEU_CHAT_LUONG
      }?${buildQueryString(parseParams(filterConditions))}`;
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    } else if (filterExcelChiTiet) {
      const endpoint = `${
        Endpoint.EXPORT_EXCEL_BAO_CAO_CHI_TIEU_CHAT_LUONG_NEW
      }?${buildQueryString(parseParams(filterConditions))}`;
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcelChiTiet(false);
    }
  }, [filterConditions, filterExcel, filterExcelChiTiet]);

  useEffect(() => {
    if (type === ENUM_BUTTON_TYPE.TIM_KIEM) getListBaoCao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListBaoCao]);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "3.5%",
    },
    {
      title: "Chỉ tiêu",
      dataIndex: "chiTieu",
      key: "chiTieu",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "14.5%",
      //sorter: true,
    },
    {
      title: "Đơn vị",
      dataIndex: "donViTinh",
      key: "donViTinh",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "5%",
      //sorter: true,
    },
    {
      title: "Tháng báo cáo",
      children: [
        {
          title: "Số lượng (vụ/lần/KH)",
          dataIndex: "soLuongThang",
          key: "soLuongThang",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "7%",
          //sorter: true,
        },
        {
          title: "So với cùng kỳ",
          dataIndex: "soVoiCungKyThang",
          key: "soVoiCungKyThang",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
          //sorter: true,
        },
        {
          title: "Tỉ trọng",
          dataIndex: "tyTrongThang",
          key: "tyTrongThang",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
          //sorter: true,
        },
        {
          title: "Tổng thời gian t.hiện",
          dataIndex: "tongThoiGianThucHienThang",
          key: "tongThoiGianThucHienThang",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "7%",
          //sorter: true,
        },
        {
          title: "Thời gian TB",
          dataIndex: "thoiGianTrungBinhThang",
          key: "thoiGianTrungBinhThang",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "7%",
          //sorter: true,
        },
        {
          title: "So cùng kỳ",
          dataIndex: "soVoiCungKyTBThang",
          key: "soVoiCungKyTBThang",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
          //sorter: true,
        },
      ],
    },
    {
      title: "Lũy thừa đến tháng báo cáo",
      children: [
        {
          title: "Số lượng (vụ/lần/KH)",
          dataIndex: "soLuongLK",
          key: "soLuongLK",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
          //sorter: true,
        },
        {
          title: "So với cùng kỳ",
          dataIndex: "soLuongCungKyLK",
          key: "soLuongCungKyLK",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
          //sorter: true,
        },
        {
          title: "Tỉ trọng",
          dataIndex: "tyTrongLK",
          key: "tyTrongLK",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
          //sorter: true,
        },
        {
          title: "Tổng thời gian t.hiện",
          dataIndex: "tongThoiGianThucHienLK",
          key: "tongThoiGianThucHienLK",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "7%",
          //sorter: true,
        },
        {
          title: "Thời gian TB",
          dataIndex: "thoiGianTrungBinhLK",
          key: "thoiGianTrungBinhLK",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "7%",
          //sorter: true,
        },
        {
          title: "So cùng kỳ",
          dataIndex: "soVoiCungKyTBLK",
          key: "soVoiCungKyTBLK",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
          //sorter: true,
        },
      ],
    },
  ];

  //render note item for columns
  const noteColumns = [
    {
      title: "1",
      // fixed: true,
    },
    {
      title: "2",
      // fixed: true,
    },
    {
      title: "3",
      // fixed: true,
      // last: true,
    },
    {
      title: "4",
    },
    {
      title: "5",
    },
    {
      title: "6",
    },
    {
      title: "7",
    },
    {
      title: "8=7/4",
    },
    {
      title: "9",
    },
    {
      title: "10",
    },
    {
      title: "11",
    },
    {
      title: "12",
    },
    {
      title: "13",
    },
    {
      title: "14=13/10",
    },
    {
      title: "15",
    },
  ];

  return (
    <BaoCaoChatLuongTable
      handleSearch={handleSearch}
      clearFilter={clearFilter}
      filterConditions={filterConditions}
      totalCount={totalCount}
      loading={loading}
      columns={columns}
      data={data}
      // countTotals={countTotals}
      noteColumns={noteColumns}
      onChangePagination={onChangePagination}
      header={"Báo cáo chỉ tiêu chất lượng"}
      setType={setType}
    />
  );
}
