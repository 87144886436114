import React, {
  // Fragment,
  useCallback,
  useEffect,
  // useMemo,
  useState,
} from "react";
import { Button, Col, Form, Modal, Row, Select, Tabs } from "antd";
import TaiAnhMoiTab from "./tabs/tab-tai-anh";
import ListImageRemoveTab from "./tabs/tab-list-delete-image";
import { ContainerBody, TabsListContainer } from "./css-styled";
import FormComponent from "@components/Form";
import {
  handleListImage,
  // handleSyncListImage,
  handleUploadImage,
} from "./services";
import { ENUM_FUNCTION, INDEX_TAB2 } from "@utils/constants";
import { removeAccents } from "@utils/function";
import { useSelector } from "react-redux";
import { taiGiayToScanSelector } from "@state/cap-dien/tai-anh-scan";
import {
  callAPIImageCMIS,
  saveDongBoImageScan,
} from "@state/cap-dien/tai-anh-scan/reducer";
import { handleDongBoAnhCMIS } from "@modules/cap-dien/tien-ich/tai-anh-giay-to-scan/services";
import LoadingComponent from "@components/Loading";

const { TabPane } = Tabs;
const { Option } = Select;

export default function PopupAnh(props) {
  const {
    callback,
    t,
    // loading,
    dispatch,
    closeModal,
    detail,
    setFetchDataTable,
    setDataDetail,
    dataLoaiGT,
    firstData,
    indexTab,
    setFistData,
    // dataImage,
    // idLoaiGT,
    // setDataImage,
    isCheck,
    dataDetail,
    funcID,
  } = props;

  const [form] = Form.useForm();
  const [loadingSubmit, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [dataImage, setDataImage] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isReloadListImage, setReloadListImage] = useState(false);
  const [listId, setListId] = useState([]);
  const [loadingSync, setLoadingSync] = useState(false);
  const { dongBoImage } = useSelector(taiGiayToScanSelector);
  const [dongBoTay, setDongBoTay] = useState(false);
  const handleSubmit = (values) => {
    handleUploadImage(
      values,
      imageFiles,
      detail,
      setLoading,
      setDataDetail,
      setFetchDataTable,
      setImageFiles,
      firstData,
      setDataImage,
      setIsSubmit,
      dispatch,
      funcID
    );
  };

  useEffect(() => {
    form.setFieldsValue({
      loaiGiayToId: firstData,
    });
  }, [firstData, form]);

  const handleChangeType = (value) => {
    setFistData(value);
    scrollToTop(`data-${value}`);
  };

  const scrollToTop = (element) => {
    document
      .getElementById(element)
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const getListImages = useCallback(() => {
    handleListImage(detail, setLoading, setDataImage, setListId);
  }, [detail]);

  useEffect(() => {
    getListImages();
  }, [getListImages]);

  useEffect(() => {
    if (dongBoTay) {
      getListImages();
      setDongBoTay(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dongBoTay]);

  useEffect(() => {
    if (isReloadListImage) {
      handleListImage(detail, setLoading, setDataImage, setListId);
      setReloadListImage(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReloadListImage]);

  const handleClosePopup = () => {
    if (dongBoImage === false) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: "Xác nhận?",
        content:
          "Tồn tại ảnh/tài liệu chưa đồng bộ về CMIS, hệ thống sẽ tự động đồng bộ sau khi đóng màn hình !",
        onOk() {
          dispatch(callAPIImageCMIS(true));
          dispatch(saveDongBoImageScan(true));
          dispatch(closeModal());
        },
        onCancel() {},
        okText: "Đồng ý",
        okButtonProps: { type: "primary" },
        cancelText: "Hủy",
      });
    } else {
      dispatch(closeModal());
    }
  };

  // const operations =
  //   dataDetail.enableSync && indexTab === INDEX_TAB2 ? (
  //     <Button
  //       type="primary"
  //       style={{ position: "absolute", right: "450px", top: "5px" }}
  //       onClick={() =>
  //         handleSyncListImage(
  //           setLoadingSync,
  //           detail,
  //           setDataDetail,
  //           setReloadListImage
  //         )
  //       }
  //       loading={loadingSync}
  //     >
  //       Đồng bộ ảnh CMIS
  //     </Button>
  //   ) : null;

  const handleDongBoAnh = () => {
    const payload = {
      hoSoId: detail.id,
    };
    handleDongBoAnhCMIS(
      payload,
      setLoading,
      setFetchDataTable,
      dispatch,
      setDongBoTay
    );
    dispatch(saveDongBoImageScan(true));
    dispatch(callAPIImageCMIS(false));
  };

  return (
    <LoadingComponent loading={loadingSubmit}>
      <ContainerBody className="modal-body">
        <FormComponent
          form={form}
          name="form-tai-anh"
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            loaiGiayToId: firstData,
          }}
          autoComplete="off"
        >
          <Row
            gutter={24}
            style={{
              // position: "fixed",
              // top: "96px",
              // borderBottom: "1px solid #f0f0f0",
              background: "#FFFFFF",
              zIndex: 99999,
              width: "100%",
            }}
          >
            <Col span={24} md={6} style={{ marginTop: "12px" }}>
              <Form.Item>
                <span>
                  {t("giao_dich_vien.tiep_nhan_phan_cong.chon_loai_giay_to")}
                </span>
              </Form.Item>
            </Col>
            <Col
              span={24}
              xs={indexTab === INDEX_TAB2 ? 24 : 18}
              md={12}
              style={{ marginTop: "12px" }}
            >
              <Form.Item name="loaiGiayToId">
                <Select
                  onChange={handleChangeType}
                  showSearch
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {dataLoaiGT && dataLoaiGT.length
                    ? dataLoaiGT.map((item, index) => (
                        <Option value={item.id} key={index}>
                          {item.tenLoaiHoSo}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            {indexTab === INDEX_TAB2 ? null : (
              <Col span={24} xs={6} md={6} style={{ marginTop: "12px" }}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    form="form-tai-anh"
                    loading={loadingSubmit}
                  >
                    Tải ảnh
                  </Button>
                </Form.Item>
              </Col>
            )}
            <Col span={24} md={24}>
              {!isCheck ? (
                // <Tabs defaultActiveKey={indexTab} onChange={callback}>
                //   <TabPane
                //     tab={t("giao_dich_vien.tiep_nhan_phan_cong.tai_anh_moi")}
                //     key="1"
                //   >
                <TaiAnhMoiTab
                  detail={detail}
                  dataLoaiGT={dataLoaiGT}
                  firstData={firstData}
                  loading={loadingSubmit}
                  setImageFiles={setImageFiles}
                  imageFiles={imageFiles}
                  isSubmit={isSubmit}
                />
              ) : (
                //   </TabPane>
                // </Tabs>
                <TabsListContainer
                  defaultActiveKey={indexTab}
                  onChange={callback}
                  id="tabs-list-image"
                  // tabBarExtraContent={operations}
                >
                  <TabPane
                    tab={t("giao_dich_vien.tiep_nhan_phan_cong.danh_sach_anh")}
                    key="2"
                  >
                    <ListImageRemoveTab
                      detail={detail}
                      remove={true}
                      loaiGiayToId={firstData}
                      dataImages={dataImage}
                      indexTab={indexTab}
                      setFetchDataTable={setFetchDataTable}
                      isSubmit={isSubmit}
                      setIsSubmit={setIsSubmit}
                      listId={listId}
                      getListImages={getListImages}
                      setDataImage={setDataImage}
                      setReloadListImage={setReloadListImage}
                      funcID={funcID}
                    />
                  </TabPane>

                  <TabPane
                    tab={t("giao_dich_vien.tiep_nhan_phan_cong.tai_anh_moi")}
                    key="1"
                  >
                    <TaiAnhMoiTab
                      detail={detail}
                      dataLoaiGT={dataLoaiGT}
                      firstData={firstData}
                      loading={loadingSubmit}
                      setImageFiles={setImageFiles}
                      imageFiles={imageFiles}
                      isSubmit={isSubmit}
                    />
                  </TabPane>
                </TabsListContainer>
              )}{" "}
            </Col>{" "}
          </Row>
        </FormComponent>
      </ContainerBody>

      <div className="ant-modal-footer text-center">
        <Button type="default" onClick={handleClosePopup}>
          {t("button.dong_lai")}
        </Button>
        {funcID === ENUM_FUNCTION.TRACUUHOSO ? (
          <Button
            type="primary"
            className="btn-bg-yellow"
            style={{
              marginRight: "24px",
            }}
            onClick={handleDongBoAnh}
            loading={loadingSubmit}
          >
            Đồng bộ ảnh
          </Button>
        ) : null}
      </div>
    </LoadingComponent>
  );
}
