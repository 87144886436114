import LoadingComponent from "@components/Loading";
import { Col, Image, Row, Select, Tooltip } from "antd";
import { useCallback, useRef, useState } from "react";
import { ContainerChoice } from "../css-styled";
import { removeAccents } from "@utils/function";
import noImage from "@assets/images/no-image.png";
import { scrollToTop } from "@utils/function";
import { StyleButton } from "../css-styled";
import { UpOutlined } from "@ant-design/icons";
import ViewImage from "../view-image";

const { Option } = Select;

export default function ListImage(props) {
  const {
    loading,
    dataImages,
    showSliderImage,
    setCaption,
    setModalSrc,
    setImageIndex,
    imageIndex,
    setSectionIndex,
    setCurrentSectionLength,
    setHideButtonLeft,
    setHideButtonRight,
    showModal,
    setShowModal,
    modalSrc,
    plusSlides,
    hideButtonLeft,
    hideButtonRight,
    handleChangeType,
    dataLoaiGT,
    type,
    // note,
    setNote,
    keyImage,
    setKeyImage,
    listId,
    firstLoaiGT,
  } = props;
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [disabledZoomIn, setDisabledZoomIn] = useState(false);
  const [disabledZoomOut, setDisabledZoomOut] = useState(true);
  const [transform, setTransform] = useState(`scale3d(1,1,1) rotate(0deg)`);

  const renderListImages = useCallback(() => {
    return (
      <div style={{ marginTop: "24px" }}>
        {dataImages && dataImages.length > 0 ? (
          // eslint-disable-next-line array-callback-return
          dataImages.map((item, index) => {
            if (item.length > 0) {
              return (
                <Row
                  gutter={24}
                  style={{ margin: 0 }}
                  id={`data-${listId[index]?.loaiGiayToId}`}
                  key={index}
                >
                  <Col span={24} style={{ marginTop: "12px" }}>
                    <h2>
                      Loại hồ sơ: <span>{listId[index]?.tenLoaiGiayTo}</span>
                    </h2>
                  </Col>
                  {item.map((pic, key) => [
                    <Col
                      span={24}
                      md={8}
                      key={key}
                      style={{
                        textAlign: "center",
                        marginTop: "12px",
                        marginBottom: pic.ghiChu ? "20px" : "0",
                      }}
                    >
                      <Image
                        preview={false}
                        src={pic.filePath}
                        alt={""}
                        width={"100%"}
                        height={"300px"}
                        onError={(e) => {
                          e.currentTarget.src = noImage;
                        }}
                        style={{ objectFit: "cover", cursor: "pointer" }}
                        onClick={() => {
                          showSliderImage(index);
                          setCaption(pic);
                          setModalSrc(pic.filePath);
                          setImageIndex(key);
                          setKeyImage(index);
                          setSectionIndex(key);
                          setNote(pic.ghiChu);
                          setCurrentSectionLength(dataImages[index].length);
                          if (key === 0) setHideButtonLeft(true);
                          else setHideButtonLeft(false);
                          if (dataImages[index].length === key + 1)
                            setHideButtonRight(true);
                          else setHideButtonRight(false);
                          setRotate(0);
                          setScale(1);
                          setTransform(`scale3d(1,1,1) rotate(0deg)`);
                        }}
                      />

                      <div
                        style={{
                          border: "1px solid #f0f0f0",
                          borderRadius: "0px 0px 8px 8px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <span>{pic.ghiChu ? pic.ghiChu : ""}</span>
                      </div>
                    </Col>,
                  ])}
                </Row>
              );
            }
          })
        ) : (
          <div style={{ minHeight: "300px" }}></div>
        )}
      </div>
    );
  }, [
    dataImages,
    listId,
    setCaption,
    setCurrentSectionLength,
    setHideButtonLeft,
    setHideButtonRight,
    setImageIndex,
    setKeyImage,
    setModalSrc,
    setNote,
    setSectionIndex,
    showSliderImage,
  ]);
  const ref = useRef();

  //Change css when lick rotage
  const handleChange = (string) => {
    let x = scale;
    let y = rotate;
    let zoomIn = disabledZoomIn;
    let zoomOut = disabledZoomOut;
    if (string === "zoomIn") {
      x += 1;
    } else if (string === "zoomOut") {
      x -= 1;
    } else if (string === "rotateLeft") {
      y -= 90;
    } else {
      y += 90;
    }

    if (x > 5) {
      x = 5;
      zoomIn = true;
    } else if (x <= 1) {
      x = 1;
      zoomOut = true;
    } else {
      zoomIn = false;
      zoomOut = false;
    }

    setScale(x);
    setRotate(y);
    setTransform(`scale3d(${x},${x},1) rotate(${y}deg)`);
    setDisabledZoomIn(zoomIn);
    setDisabledZoomOut(zoomOut);
  };

  return (
    <LoadingComponent loading={loading}>
      {!type ? (
        <ContainerChoice ref={ref}>
          <span className="text">Chọn loại hồ sơ giấy tờ: </span>
          <Select
            placeholder="Chọn loại hồ sơ giấy tờ"
            onChange={handleChangeType}
            defaultValue={
              dataImages && dataImages.length > 0 ? firstLoaiGT : undefined
            }
            className="choice"
            showSearch
            filterOption={(input, option) => {
              if (option && option.children) {
                return (
                  removeAccents(option.children)
                    .toLowerCase()
                    .indexOf(removeAccents(input).toLowerCase()) >= 0
                );
              }
            }}
            disabled={dataImages && dataImages.length > 0 ? false : true}
          >
            {dataImages && dataImages.length > 0
              ? dataLoaiGT && dataLoaiGT.length
                ? dataLoaiGT.map((item, index) => (
                    <Option value={item.id} key={index}>
                      {item.tenLoaiHoSo}
                    </Option>
                  ))
                : null
              : null}
          </Select>
        </ContainerChoice>
      ) : null}

      {renderListImages()}
      <StyleButton onClick={() => scrollToTop(ref)}>
        <Tooltip title="Lên đầu trang">
          <UpOutlined />
        </Tooltip>
      </StyleButton>
      <ViewImage
        showModal={showModal}
        setShowModal={setShowModal}
        handleChange={handleChange}
        plusSlides={plusSlides}
        imageIndex={imageIndex}
        keyImage={keyImage}
        hideButtonLeft={hideButtonLeft}
        hideButtonRight={hideButtonRight}
        modalSrc={modalSrc}
        transform={transform}
        isSilder
        disabledZoomIn={disabledZoomIn}
        disabledZoomOut={disabledZoomOut}
      />
    </LoadingComponent>
  );
}
