import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  TYPE_PHANCONG,
  TYPE_TABLE_CONGTO,
  ENUM_SOPHA,
  TYPE_MODAL,
  ENUM_DS_CONGTO,
  FORMAT_ENGLISH,
  FORMAT_DATE,
} from "@utils/constants";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { handleListData, handleMultiDS } from "./services";
import DSCongToTable from "./table";
import CheckPermission from "@components/CheckPermission";
import { kt_tb_do_dem } from "@permissions/n_kttbdodem";
import { Button, Form, Tag, Tooltip } from "antd";
import PhanCongModal from "../modal-phan-cong";
import LamMoiModal from "../modal-lam-moi";
import ModalDinhKyCMIS from "../modal-dong-bo-cmis/modal-dinh-ky";
import ModalPhucTraCMIS from "../modal-dong-bo-cmis/modal-phuc-tra";
import { closeModal, openModal } from "@state/system-config/reducer";
import ChiTietCongTo from "../chi-tiet-cong-to";
import { handleGetMessage } from "../modal-phan-cong/services";
import HuyPCModal from "../modal-huy-phan-cong";
import { InfoCircleOutlined } from "@ant-design/icons";
import XoaModal from "../modal-xac-nhan-bang-ke";
import EVNHESModal from "../modal-xac-nhan-bang-ke";
import MTBModal from "../modal-xac-nhan-bang-ke";
import moment from "moment";
import DongBoCmisModal from "../modal-xac-nhan-bang-ke";
import DatePickerComponent from "@components/DatePicker";
import { Grid } from "antd";
const { useBreakpoint } = Grid;

export default function Index(props) {
  const {
    typeList,
    typeDS,
    fetchList,
    setFetchList,
    isRowSelect,
    rowSelection,
    firstColumns,
    header,
    selectedRowKeys,
    columnsTB,
    isGenExtra,
    typePhanCong,
    setSelectedRowKeys,
    setIsDisableAction,
    isHuyPhanCong,
    urlXuatExcel,
    permissionExcel,
  } = props;
  const { user } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingPC, setLoadingPC] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [, setSearchParams] = useSearchParams();
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const location = useLocation();
  const [firstTrangThai, setFirstTrangThai] = useState("");
  const [filterConditions, setFilterConditions] = useState({});
  const [filterConditionsTraCuu, setFilterConditionsTraCuu] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    donViId: user.unitId,
    ...parseParams(location.search),
    ngayBatDau:
      typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY
        ? moment().startOf("month").format(FORMAT_ENGLISH)
        : null,
    ngayKetThuc:
      typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY
        ? moment().endOf("month").format(FORMAT_ENGLISH)
        : null,
    thangNamGCS:
      typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA ||
      typeList === TYPE_TABLE_CONGTO.CHISO_EVN_HES ||
      typeList === TYPE_TABLE_CONGTO.DONGBO_CMIS
        ? moment(moment()).format(FORMAT_ENGLISH)
        : null,
    maLoaiDanhSach: typeDS,
  });

  const [visiblePhanCong, setVisiblePhanCong] = useState(false);
  const [biTraLai, setBiTraLai] = useState(false);
  const [locTheoThang, setLocTheoThang] = useState(false);
  const [dataDoi, setDataDoi] = useState([]);
  // eslint-disable-next-line
  const [loaiBangKe, setLoaiBangKe] = useState([]);
  const [trangThai, setTrangThai] = useState([]);
  const [soPha, setSoPha] = useState([]);
  const [visibleXoa, setVisibleXoa] = useState(false);
  const [visibleHuyPC, setVisibleHuyPC] = useState(false);
  const [visibleDBCmis, setVisibleDBCmis] = useState(false);
  const [visibleEVNHES, setVisibleEVNHES] = useState(false);
  const [visibleMTB, setVisibleMTB] = useState(false);
  const [id, setId] = useState("");
  const [formHes] = Form.useForm();
  const [check, setCheck] = useState(false);
  const [disableMTB, setDisableMTB] = useState(true);
  const [visibleLamMoi, setVisibleLamMoi] = useState(false);
  const [dataMesEVNHES, setDataMesEVNHES] = useState({});
  const screens = useBreakpoint();
  const mobile = screens.xs && !screens.md;

  // column table

  const columnsTableKTDK = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => detailCongTo(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: 170,
      sorter: true,
    },
    {
      title: "Trạm",
      dataIndex: "tenTram",
      key: "tenTram",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
      sorter: true,
    },
    {
      title: "Tên khách hàng",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
      sorter: true,
    },
    {
      title: "Mã sổ GCS",
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
      sorter: true,
    },
    {
      title: "Mã công tơ",
      dataIndex: "maCongTo",
      key: "maCongTo",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 180,
      sorter: true,
    },
    {
      title: "Số pha",
      dataIndex: "soPha",
      key: "soPha",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 100,
      sorter: true,
    },
    {
      title: "Ngày treo tháo",
      dataIndex: "ngayKiemDinh",
      key: "ngayKiemDinh",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: 150,
      sorter: true,
    },
    {
      title: "Đội",
      dataIndex: "tenDoi",
      key: "tenDoi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
      sorter: true,
    },
    {
      title: "Trạng thái",
      dataIndex: "tenTrangThai",
      key: "tenTrangThai",
      render: (text, record) => (
        <div className="table-text-center">
          <Tag color={record.maMau}>{text}</Tag>
          {record.isTraDoiTruong ? (
            <div
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: "#FF5247",
              }}
            >
              <InfoCircleOutlined /> Bị trả lại từ NV
            </div>
          ) : record.biTraLai ? (
            <div
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: "#FF5247",
                cursor: "pointer",
              }}
            >
              <Tooltip title={record.lyDoTraLai}>
                <InfoCircleOutlined /> Bị trả lại
              </Tooltip>
            </div>
          ) : null}
        </div>
      ),
      width: 200,
      sorter: true,
    },
    {
      title: "NV kiểm tra",
      dataIndex: "tenNhanVien",
      key: "tenNhanVien",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
      sorter: true,
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
      sorter: true,
    },
    typePhanCong !== TYPE_PHANCONG.NHANVIEN &&
    typeDS !== ENUM_DS_CONGTO.DINHKY_TRACUU &&
    typeDS !== ENUM_DS_CONGTO.PHUCTRA_TRACUU &&
    typeDS !== ENUM_DS_CONGTO.PHUCTRA_CMIS_PKD
      ? {
          title: "Tác vụ",
          key: "action",
          align: "center",
          width: 120,
          render: (row) => (
            <Fragment>
              {row.isHuy ? (
                <CheckPermission
                  permissionCode={
                    typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY &&
                    typePhanCong === TYPE_PHANCONG.PHONGKINHDOANH
                      ? kt_tb_do_dem.n21h
                      : typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY &&
                        typePhanCong === TYPE_PHANCONG.DOITRUONG
                      ? kt_tb_do_dem.n31h
                      : null
                  }
                >
                  <div
                    className="ant-btn-link cursor-pointer"
                    style={{ textAlign: "center" }}
                    onClick={() => handleOpenModalHuy(row.congToId)}
                  >
                    <Button danger size="small">
                      Hủy phân công
                    </Button>
                  </div>
                </CheckPermission>
              ) : null}
            </Fragment>
          ),
        }
      : {},
  ];

  const columnsTableKTPT = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text, record) => (
        <div
          className="ant-btn-link cursor-pointer table-text-left"
          onClick={() => detailCongTo(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: 170,
      sorter: true,
    },
    {
      title: "Mã sổ GCS",
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
      sorter: true,
    },
    {
      title: "Mã công tơ",
      dataIndex: "maCongTo",
      key: "maCongTo",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 180,
      sorter: true,
    },
    {
      title: "Tháng năm GCS",
      dataIndex: "thangNamGCS",
      key: "thangNamGCS",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: 120,
      sorter: true,
    },
    {
      title: "Kỳ GCS",
      dataIndex: "kyGCS",
      key: "kyGCS",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 70,
      sorter: true,
    },
    {
      title: "Chênh lệch %",
      dataIndex: "chenhLechPhanTram",
      key: "chenhLechPhanTram",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 100,
      sorter: true,
    },
    {
      title: "Chênh lệch Kwh",
      dataIndex: "chenhLechKwh",
      key: "chenhLechKwh",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 130,
      sorter: true,
    },
    {
      title: "Trạng thái",
      dataIndex: "tenTrangThai",
      key: "tenTrangThai",
      render: (text, record) => (
        <div className="table-text-center">
          <Tag color={record.maMau}>{text}</Tag>
          {record.isTraDoiTruong ? (
            <div
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: "#FF5247",
              }}
            >
              <InfoCircleOutlined /> Bị trả lại từ NV
            </div>
          ) : record.biTraLai ? (
            <div
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: "#FF5247",
                cursor: "pointer",
              }}
            >
              <Tooltip title={record.lyDoTraLai}>
                <InfoCircleOutlined /> Bị trả lại
              </Tooltip>
            </div>
          ) : null}
        </div>
      ),
      width: 200,
      sorter: true,
    },
    {
      title: "Hình thức",
      dataIndex: "hinhThuc",
      key: "hinhThuc",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 100,
      sorter: true,
    },
    {
      title: "Trạm",
      dataIndex: "tenTram",
      key: "tenTram",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "13%",
      sorter: true,
    },
    {
      title: "Tên khách hàng",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
      sorter: true,
    },
    {
      title: "Đội",
      dataIndex: "tenDoi",
      key: "tenDoi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
      sorter: true,
    },
    {
      title: "NV phúc tra",
      dataIndex: "tenNhanVien",
      key: "tenNhanVien",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
      sorter: true,
    },
    {
      title: "NV GCS",
      dataIndex: "tenNhanVienGCS",
      key: "tenNhanVienGCS",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
      sorter: true,
    },
    {
      title: "Ngày GCS",
      dataIndex: "ngayGCS",
      key: "ngayGCS",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: 120,
      sorter: true,
    },
    {
      title: "Ngày phúc tra",
      dataIndex: "ngayPhucTra",
      key: "ngayPhucTra",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: 120,
      sorter: true,
    },
    typePhanCong !== TYPE_PHANCONG.NHANVIEN &&
    typeDS !== ENUM_DS_CONGTO.DINHKY_TRACUU &&
    typeDS !== ENUM_DS_CONGTO.PHUCTRA_TRACUU
      ? {
          title: "Tác vụ",
          key: "action",
          align: "center",
          width: 120,
          render: (row) => (
            <Fragment>
              {row.isHuy ? (
                <CheckPermission
                  permissionCode={
                    typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA &&
                    typePhanCong === TYPE_PHANCONG.PHONGKINHDOANH
                      ? kt_tb_do_dem.n22h
                      : typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA &&
                        typePhanCong === TYPE_PHANCONG.DOITRUONG
                      ? kt_tb_do_dem.n32h
                      : null
                  }
                >
                  <div
                    className="ant-btn-link cursor-pointer"
                    style={{ textAlign: "center" }}
                    onClick={() => handleOpenModalHuy(row.congToId)}
                  >
                    <Button danger size="small">
                      Hủy phân công
                    </Button>
                  </div>
                </CheckPermission>
              ) : null}
            </Fragment>
          ),
        }
      : {},
  ];
  // check column định kỳ và phúc tra

  const [columns, setColumns] = useState(
    typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA ||
      typeList === TYPE_TABLE_CONGTO.CHISO_EVN_HES
      ? columnsTableKTPT
      : columnsTableKTDK
  );
  // các hàm đóng modal

  const handleCloseModalPC = () => {
    setVisiblePhanCong(false);
  };
  const handleCloseModalXoa = () => {
    setVisibleXoa(false);
  };
  const handleCloseModalHuyPC = () => {
    setVisibleHuyPC(false);
  };
  const handleCloseModalDBCmis = () => {
    setVisibleDBCmis(false);
  };
  const handleCloseModalEVNHES = () => {
    setVisibleEVNHES(false);
  };
  const handleCloseModalMTB = () => {
    setVisibleMTB(false);
  };
  const handleCloseModalLamMoi = () => {
    setVisibleLamMoi(false);
  };
  // các hàm mở modal

  const handleOpenModalPC = () => {
    handleGetMessage(
      selectedRowKeys,
      setLoadingPC,
      setDataDoi,
      setVisiblePhanCong,
      visiblePhanCong
    );
  };
  const handleOpenModalHuy = (id) => {
    setId(id);
    setVisibleHuyPC(!visibleHuyPC);
  };

  const handleOpenModalEVNHES = () => {
    setVisibleEVNHES(!visibleEVNHES);
  };

  const handleOpenModalMTB = () => {
    setVisibleMTB(!visibleMTB);
    setDataMesEVNHES({});
  };

  const handleOpenModalLamMoi = () => {
    setVisibleLamMoi(!visibleLamMoi);
  };

  const handleOpenModalXoa = () => {
    setVisibleXoa(!visibleXoa);
  };

  const handleOpenModalDB = () => {
    setVisibleDBCmis(!visibleDBCmis);
  };
  // Hiển thị  các buttom

  const genExtra = () => (
    <Fragment>
      <div style={{ display: "flex", flexDirection: mobile ? "column" : "" }}>
        {typePhanCong === TYPE_PHANCONG.NHANVIEN &&
        typeDS !== ENUM_DS_CONGTO.DINHKY_NVKT &&
        typeDS !== ENUM_DS_CONGTO.PHUCTRA_NVKT ? (
          <div
            style={{ display: "flex", flexDirection: mobile ? "column" : "" }}
          >
            <CheckPermission permissionCode={kt_tb_do_dem.n48cn}>
              <Form.Item
                name="ngayPhucTra"
                label="Ngày phúc tra"
                style={{ marginBottom: 0, marginRight: mobile ? 0 : 24 }}
              >
                <DatePickerComponent
                  defaultValue={
                    filterConditions.ngayBatDau
                      ? moment(filterConditions.ngayBatDau, FORMAT_ENGLISH)
                      : moment(moment(), FORMAT_DATE)
                  }
                  format={FORMAT_ENGLISH}
                  form={formHes}
                  formKey={"ngayPhucTra"}
                  disabledDate={(current) =>
                    current.isAfter(moment().subtract(0, "day"))
                  }
                  notClear
                />
              </Form.Item>
              <Button
                type="primary"
                className={mobile ? "mt-8px" : "mr-24px"}
                onClick={() => handleOpenModalEVNHES()}
                disabled={!selectedRowKeys.length}
              >
                Cập nhật chỉ số EVN HES
              </Button>
            </CheckPermission>
            <CheckPermission permissionCode={kt_tb_do_dem.n48c}>
              <Button
                type="primary"
                className={mobile ? "mt-8px" : "mr-5px"}
                onClick={() => handleOpenModalMTB()}
                disabled={!selectedRowKeys.length}
              >
                Chuyển MTB
              </Button>
            </CheckPermission>
          </div>
        ) : typeList === TYPE_TABLE_CONGTO.DONGBO_CMIS ? (
          <CheckPermission permissionCode={kt_tb_do_dem.n24db}>
            <Button
              type="primary"
              className={mobile ? "mt-8px" : "mr-5px"}
              onClick={() => handleOpenModalDB()}
              disabled={!selectedRowKeys.length}
            >
              Đồng bộ về CMIS
            </Button>
          </CheckPermission>
        ) : typePhanCong === TYPE_PHANCONG.DOITRUONG &&
          typeList === TYPE_TABLE_CONGTO.CHISO_EVN_HES ? (
          <div
            style={{ display: "flex", flexDirection: mobile ? "column" : "" }}
          >
            <CheckPermission permissionCode={kt_tb_do_dem.n34cn}>
              <Form.Item
                name="ngayPhucTra"
                label="Ngày phúc tra"
                style={{ marginBottom: 0, marginRight: mobile ? 0 : 24 }}
              >
                <DatePickerComponent
                  defaultValue={
                    filterConditions.ngayBatDau
                      ? moment(filterConditions.ngayBatDau, FORMAT_ENGLISH)
                      : moment(moment(), FORMAT_DATE)
                  }
                  format={FORMAT_ENGLISH}
                  form={formHes}
                  formKey={"ngayPhucTra"}
                  disabledDate={(current) =>
                    current.isAfter(moment().subtract(0, "day"))
                  }
                  notClear
                />
              </Form.Item>
              <Button
                type="primary"
                className={mobile ? "mt-8px" : "mr-24px"}
                onClick={() => handleOpenModalEVNHES()}
                disabled={!selectedRowKeys.length}
              >
                Cập nhật chỉ số EVN HES
              </Button>
            </CheckPermission>
            <CheckPermission permissionCode={kt_tb_do_dem.n34c}>
              <Button
                type="primary"
                className={mobile ? "mt-8px" : "mr-5px"}
                onClick={() => handleOpenModalMTB()}
                disabled={disableMTB}
              >
                Chuyển MTB
              </Button>
            </CheckPermission>
          </div>
        ) : typeDS === ENUM_DS_CONGTO.DINHKY_NVKT ||
          typeDS === ENUM_DS_CONGTO.PHUCTRA_NVKT ? (
          <CheckPermission
            permissionCode={
              typeDS === ENUM_DS_CONGTO.DINHKY_NVKT
                ? kt_tb_do_dem.n41lm
                : typeDS === ENUM_DS_CONGTO.PHUCTRA_NVKT
                ? kt_tb_do_dem.n43lm
                : null
            }
          >
            <Button
              type="primary"
              className={mobile ? "mt-8px" : "mr-5px"}
              onClick={() => handleOpenModalLamMoi()}
              disabled={!selectedRowKeys.length}
            >
              Làm mới
            </Button>
          </CheckPermission>
        ) : (
          <>
            <CheckPermission
              permissionCode={
                typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY &&
                typePhanCong === TYPE_PHANCONG.PHONGKINHDOANH
                  ? kt_tb_do_dem.n21g
                  : typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY &&
                    typePhanCong === TYPE_PHANCONG.DOITRUONG
                  ? kt_tb_do_dem.n31g
                  : typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA &&
                    typePhanCong === TYPE_PHANCONG.PHONGKINHDOANH
                  ? kt_tb_do_dem.n22g
                  : typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA &&
                    typePhanCong === TYPE_PHANCONG.DOITRUONG
                  ? kt_tb_do_dem.n32g
                  : null
              }
            >
              <Button
                type="primary"
                className={mobile ? "mt-8px" : "mr-5px"}
                onClick={() => showModalCMIS()}
              >
                Lấy danh sách từ CMIS
              </Button>
            </CheckPermission>
            <CheckPermission
              permissionCode={
                typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY &&
                typePhanCong === TYPE_PHANCONG.PHONGKINHDOANH
                  ? kt_tb_do_dem.n21i
                  : typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY &&
                    typePhanCong === TYPE_PHANCONG.DOITRUONG
                  ? kt_tb_do_dem.n31i
                  : typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA &&
                    typePhanCong === TYPE_PHANCONG.PHONGKINHDOANH
                  ? kt_tb_do_dem.n22i
                  : typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA &&
                    typePhanCong === TYPE_PHANCONG.DOITRUONG
                  ? kt_tb_do_dem.n32i
                  : null
              }
            >
              {/* <Button
              type="primary"
              className="mr-5px"
              onClick={() => handleOpenModalFile()}
            >
              Import file
            </Button> */}
            </CheckPermission>
            <CheckPermission
              permissionCode={
                typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY &&
                typePhanCong === TYPE_PHANCONG.PHONGKINHDOANH
                  ? kt_tb_do_dem.n21p
                  : typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY &&
                    typePhanCong === TYPE_PHANCONG.DOITRUONG
                  ? kt_tb_do_dem.n31p
                  : typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA &&
                    typePhanCong === TYPE_PHANCONG.PHONGKINHDOANH
                  ? kt_tb_do_dem.n22p
                  : typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA &&
                    typePhanCong === TYPE_PHANCONG.DOITRUONG
                  ? kt_tb_do_dem.n32p
                  : null
              }
            >
              <Button
                type="primary"
                className={mobile ? "mt-8px" : "mr-5px"}
                onClick={() => handleOpenModalPC()}
                loading={loadingPC}
                disabled={!selectedRowKeys.length}
              >
                Phân công
              </Button>
            </CheckPermission>
            {isHuyPhanCong ? (
              <CheckPermission
                permissionCode={
                  typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY &&
                  typePhanCong === TYPE_PHANCONG.PHONGKINHDOANH
                    ? kt_tb_do_dem.n21h
                    : typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY &&
                      typePhanCong === TYPE_PHANCONG.DOITRUONG
                    ? kt_tb_do_dem.n31h
                    : null
                }
              >
                <Button
                  type="primary"
                  onClick={() => handleOpenModalHuy()}
                  className={mobile ? "mt-8px" : "mr-5px btn-bg-yellow"}
                  disabled={!selectedRowKeys.length}
                >
                  Hủy phân công
                </Button>
              </CheckPermission>
            ) : null}
            {typeDS === ENUM_DS_CONGTO.DINHKY_PKD ||
            typeDS === ENUM_DS_CONGTO.DINHKY_DT ? (
              <CheckPermission
                permissionCode={
                  typeDS === ENUM_DS_CONGTO.DINHKY_PKD
                    ? kt_tb_do_dem.n21d
                    : typeDS === ENUM_DS_CONGTO.DINHKY_DT
                    ? kt_tb_do_dem.n31d
                    : null
                }
              >
                <Button
                  danger
                  type="primary"
                  className={mobile ? "mt-8px" : "mr-5px btn-bg-yellow"}
                  onClick={() => handleOpenModalXoa()}
                  disabled={!selectedRowKeys.length}
                >
                  Xóa
                </Button>
              </CheckPermission>
            ) : null}
          </>
        )}
      </div>
    </Fragment>
  );
  //render popup Làm mới

  const showModalLamMoi = useMemo(
    () =>
      visibleLamMoi && (
        <LamMoiModal
          visible={visibleLamMoi}
          handleCloseModal={handleCloseModalLamMoi}
          selectedRowKeys={selectedRowKeys}
          setFetchDataTable={setFetchDataTable}
          setVisibleLamMoi={setVisibleLamMoi}
          setSelectedRowKeys={setSelectedRowKeys}
          isPhucTra={typeDS === ENUM_DS_CONGTO.PHUCTRA_NVKT ? true : false}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleLamMoi]
  );

  //render popup Phân công

  const showModalPC = useMemo(
    () =>
      visiblePhanCong && (
        <PhanCongModal
          visiblePhanCong={visiblePhanCong}
          handleCloseModal={handleCloseModalPC}
          selectedRowKeys={selectedRowKeys}
          setFetchList={setFetchList}
          detail={dataSource}
          setFetchDataTable={setFetchDataTable}
          typePhanCong={typePhanCong}
          dataDoi={dataDoi}
          setVisiblePhanCong={setVisiblePhanCong}
          setSelectedRowKeys={setSelectedRowKeys}
          typeList={typeList}
          typeDS={typeDS}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visiblePhanCong]
  );
  //render popup đồng bộ cmis

  const showModalCMIS = () => {
    return dispatch(
      openModal({
        content:
          typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY ? (
            <ModalDinhKyCMIS typeDongBo={typeList} />
          ) : typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA ? (
            <ModalPhucTraCMIS typeDongBo={typeList} />
          ) : null,
        size: "large",
        title:
          typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY
            ? "ĐỒNG BỘ YÊU CẦU KIỂM TRA ĐỊNH KỲ TỪ CMIS"
            : "ĐỒNG BỘ YÊU CẦU PHÚC TRA TỪ CMIS",
      })
    );
  };
  //render popup hủy phân công

  const showModalHuyPC = useMemo(
    () =>
      visibleHuyPC && (
        <HuyPCModal
          visible={visibleHuyPC}
          handleCloseModal={handleCloseModalHuyPC}
          setVisibleHuyPC={setVisibleHuyPC}
          id={id || selectedRowKeys?.toString()}
          setFetchDataTable={setFetchDataTable}
          typeHuy={typePhanCong === TYPE_PHANCONG.PHONGKINHDOANH ? true : false}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleHuyPC]
  );

  //render popup xóa

  const showModalXoa = useMemo(
    () =>
      visibleXoa && (
        <XoaModal
          visibleXacNhan={visibleXoa}
          handleCloseModalXN={handleCloseModalXoa}
          setFetchDataTable={setFetchDataTable}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          title={"Xóa yêu cầu kiểm tra công tơ"}
          content={"Bạn chắc chắn muốn xóa yêu cầu kiểm tra thiết bị đã chọn?"}
          type={TYPE_MODAL.XOA}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleXoa]
  );
  //render popup cập nhật chỉ số EVN HES

  const showModalEVNHES = useMemo(
    () =>
      visibleEVNHES && (
        <EVNHESModal
          visibleXacNhan={visibleEVNHES}
          handleCloseModalXN={handleCloseModalEVNHES}
          setFetchDataTable={setFetchDataTable}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          title={"Cập nhật chỉ số EVN HES"}
          content={
            "Bạn đồng ý cập nhật chỉ số EVN HES của các công tơ đã chọn ?"
          }
          type={TYPE_MODAL.EVNHES}
          values={formHes.getFieldValue("ngayPhucTra")}
          setDisableMTB={setDisableMTB}
          setDataMesEVNHES={setDataMesEVNHES}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleEVNHES]
  );
  //render popup cập nhật chỉ số EVN HES

  const showModalMTB = useMemo(
    () =>
      visibleMTB && (
        <MTBModal
          visibleXacNhan={visibleMTB}
          handleCloseModalXN={handleCloseModalMTB}
          setFetchDataTable={setFetchDataTable}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          title={"Cập nhật chỉ số EVN HES"}
          content={
            "Bạn đồng ý chuyển lấy dữ liệu bằng MTB cho các công tơ đã chọn?"
          }
          type={TYPE_MODAL.MTB}
          setDisableMTB={setDisableMTB}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleMTB]
  );
  //render modal xác nhận

  const showModalDBCmis = useMemo(
    () =>
      visibleDBCmis && (
        <DongBoCmisModal
          visibleXacNhan={visibleDBCmis}
          handleCloseModalXN={handleCloseModalDBCmis}
          setFetchDataTable={setFetchDataTable}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          title={"Đồng bộ về CMIS"}
          content={"Bạn có muốn đồng bộ các yêu cầu đã chọn về CMIS?"}
          type={TYPE_MODAL.DB}
        />
      ),
    // eslint-disable-next-line
    [visibleDBCmis]
  );
  // modal chi tiết công tơ

  const detailCongTo = (record) => {
    return dispatch(
      openModal({
        content: (
          <ChiTietCongTo
            typeSoPha={record.soPha}
            typeList={typeList}
            detailCongTo={record}
            closeModal={closeModal}
          />
        ),
        size: "large",
        title:
          typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY &&
          record.soPha === ENUM_SOPHA.MOT_PHA
            ? "Chi tiết công tơ 1 pha"
            : typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY &&
              record.soPha === ENUM_SOPHA.BA_PHA
            ? "Chi tiết công tơ 3 pha"
            : typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA
            ? "Chi tiết công tơ phúc tra"
            : null,
      })
    );
  };

  const detailDouble = (record) => {
    return {
      onDoubleClick: () => {
        dispatch(
          openModal({
            content: (
              <ChiTietCongTo
                typeSoPha={record.soPha}
                typeList={typeList}
                detailCongTo={record}
                closeModal={closeModal}
              />
            ),
            size: "large",
            title:
              typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY &&
              record.soPha === ENUM_SOPHA.MOT_PHA
                ? "Chi tiết công tơ 1 pha"
                : typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY &&
                  record.soPha === ENUM_SOPHA.BA_PHA
                ? "Chi tiết công tơ 3 pha"
                : typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA
                ? "Chi tiết công tơ phúc tra"
                : null,
          })
        );
      },
    };
  };
  //get multilist data dropdown

  useEffect(() => {
    handleMultiDS(
      setLoading,
      setSoPha,
      setTrangThai,
      setLoaiBangKe,
      typeDS,
      setFirstTrangThai
    );
  }, [typeDS]);
  //change show page size

  useLayoutEffect(() => {
    setSearchParams(
      removeUndefinedAttribute(
        typeDS === ENUM_DS_CONGTO.DINHKY_TRACUU ||
          typeDS === ENUM_DS_CONGTO.PHUCTRA_TRACUU
          ? filterConditionsTraCuu
          : filterConditions
      )
    );
  }, [setSearchParams, filterConditions, filterConditionsTraCuu, typeDS]);

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(
      paging,
      sorter,
      typeDS === ENUM_DS_CONGTO.DINHKY_TRACUU ||
        typeDS === ENUM_DS_CONGTO.PHUCTRA_TRACUU
        ? setFilterConditionsTraCuu
        : setFilterConditions
    );
  };
  //get list data

  const getListData = useCallback(() => {
    handleListData(
      typeList,
      typeDS === ENUM_DS_CONGTO.DINHKY_TRACUU ||
        typeDS === ENUM_DS_CONGTO.PHUCTRA_TRACUU
        ? filterConditionsTraCuu
        : filterConditions,
      setLoading,
      setDataSource,
      setTotalCount,
      location
    );
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (firstTrangThai !== "")
      setFilterConditions({
        pageIndex: DEFAULT_PAGEINDEX,
        pageSize: DEFAULT_PAGESIZE,
        donViId: user.unitId,
        ...parseParams(location.search),
        ngayBatDau:
          typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY
            ? moment().startOf("month").format(FORMAT_ENGLISH)
            : null,
        ngayKetThuc:
          typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY
            ? moment().endOf("month").format(FORMAT_ENGLISH)
            : null,
        thangNamGCS:
          typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA ||
          typeList === TYPE_TABLE_CONGTO.CHISO_EVN_HES ||
          typeList === TYPE_TABLE_CONGTO.DONGBO_CMIS
            ? moment(moment()).format(FORMAT_ENGLISH)
            : null,
        trangThaiId: firstTrangThai,
        maLoaiDanhSach: typeDS,
      });
    // eslint-disable-next-line
  }, [firstTrangThai]);

  useEffect(() => {
    if (filterConditions && Object.keys(filterConditions).length > 0) {
      getListData();
    }
    // eslint-disable-next-line
  }, [getListData]);

  useEffect(() => {
    if (
      typeDS === ENUM_DS_CONGTO.DINHKY_TRACUU ||
      typeDS === ENUM_DS_CONGTO.PHUCTRA_TRACUU
    ) {
      getListData();
    }
    // eslint-disable-next-line
  }, [getListData]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListData();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
    // eslint-disable-next-line
  }, [fetchDataTableIfNeed]);

  useEffect(() => {
    //call api list if fetchList = true
    if (fetchList) {
      getListData();
      setFetchList(false);
    }
  }, [fetchList, getListData, setFetchList]);
  //hàm tìm kiếm dữ liệu

  const handleSearch = useCallback(
    (values) => {
      if (
        typeDS === ENUM_DS_CONGTO.DINHKY_TRACUU ||
        typeDS === ENUM_DS_CONGTO.PHUCTRA_TRACUU
      ) {
        setFilterConditionsTraCuu((oldState) => ({
          ...oldState,
          pageIndex: DEFAULT_PAGEINDEX,
          ...values,
          donViId: values.donViId,
          biTraLai: biTraLai,
          isLocTheoThang: locTheoThang,
          tramIds: values.tramIds
            ? Array.isArray(values.tramIds) && values.tramIds.length > 0
              ? values.tramIds.join()
              : values.tramIds
            : [],
          thangNamGCS:
            typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA
              ? values.thangNamGCS
              : null,
          kyGCS: values.kyGCS,
          hinhThuc: values.hinhThuc,
          maLoaiDanhSach: typeDS,
        }));
      } else {
        setFilterConditions((oldState) => ({
          ...oldState,
          pageIndex: DEFAULT_PAGEINDEX,
          ...values,
          donViId: values.donViId,
          biTraLai: biTraLai,
          isLocTheoThang: locTheoThang,
          tramIds: values.tramIds
            ? Array.isArray(values.tramIds) && values.tramIds.length > 0
              ? values.tramIds.join()
              : values.tramIds
            : [],
          thangNamGCS:
            typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA ||
            typeList === TYPE_TABLE_CONGTO.CHISO_EVN_HES ||
            typeList === TYPE_TABLE_CONGTO.DONGBO_CMIS
              ? values.thangNamGCS
              : null,
          kyGCS: values.kyGCS,
          hinhThuc: values.hinhThuc,
          maLoaiDanhSach: typeDS,
        }));
        setSelectedRowKeys([]);
        setIsDisableAction(check);
        setDataMesEVNHES({});
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [biTraLai, typeDS, typeList, check, locTheoThang]
  );
  //clear bộ lọc tìm kiếm

  const clearFilter = useCallback(() => {
    if (
      typeDS === ENUM_DS_CONGTO.DINHKY_TRACUU ||
      typeDS === ENUM_DS_CONGTO.PHUCTRA_TRACUU
    ) {
      setFilterConditionsTraCuu({
        pageIndex: DEFAULT_PAGEINDEX,
        pageSize: DEFAULT_PAGESIZE,
        donViId: user.unitId,
        ngayBatDau:
          typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY
            ? moment().startOf("month").format(FORMAT_ENGLISH)
            : null,
        ngayKetThuc:
          typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY
            ? moment().endOf("month").format(FORMAT_ENGLISH)
            : null,
        thangNamGCS:
          typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA
            ? moment(moment()).format(FORMAT_ENGLISH)
            : null,
        maLoaiDanhSach: typeDS,
      });
    } else {
      setFilterConditions({
        pageIndex: DEFAULT_PAGEINDEX,
        pageSize: DEFAULT_PAGESIZE,
        donViId: user.unitId,
        ngayBatDau:
          typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY
            ? moment().startOf("month").format(FORMAT_ENGLISH)
            : null,
        ngayKetThuc:
          typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY
            ? moment().endOf("month").format(FORMAT_ENGLISH)
            : null,
        thangNamGCS:
          typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA ||
          typeList === TYPE_TABLE_CONGTO.CHISO_EVN_HES ||
          typeList === TYPE_TABLE_CONGTO.DONGBO_CMIS
            ? moment(moment()).format(FORMAT_ENGLISH)
            : null,
        trangThaiId: firstTrangThai,
        maLoaiDanhSach: typeDS,
      });
      setSelectedRowKeys([]);
    }
    setLocTheoThang(false);
    setBiTraLai(false);
    setDataMesEVNHES({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstTrangThai, typeDS, typeList, user.unitId]);
  //change checkbox

  const checkTraLai = (e) => {
    setBiTraLai(e.target.checked);
  };

  const checkLocTheoThang = (e) => {
    setLocTheoThang(e.target.checked);
  };

  useEffect(() => {
    let newColumns = [];
    if (firstColumns && Object.keys(firstColumns).length > 0) {
      columns.unshift(firstColumns);
      columns[0].width = 80;
      columns[0].fixed = "left";
    }
    newColumns = columns;
    setColumns(newColumns);
  }, [columns, firstColumns]);

  const onChangeTrangThai = (values) => {
    if (values === undefined) {
      setCheck(false);
    } else {
      trangThai &&
        trangThai.length > 0 &&
        // eslint-disable-next-line array-callback-return
        trangThai.map((item) => {
          if (item.value === values) {
            setCheck(item.isDisableAction);
          }
        });
    }
  };

  return (
    <Fragment>
      <DSCongToTable
        loading={loading}
        dataSource={dataSource}
        totalData={totalCount}
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        onChangePagination={onChangePagination}
        filterConditions={
          typeDS === ENUM_DS_CONGTO.DINHKY_TRACUU ||
          typeDS === ENUM_DS_CONGTO.PHUCTRA_TRACUU
            ? filterConditionsTraCuu
            : filterConditions
        }
        rowSelection={isRowSelect ? rowSelection : null}
        genExtra={isGenExtra ? genExtra : null}
        user={user}
        typeList={typeList}
        columns={
          columnsTB ? columnsTB : customColumn(columns, filterConditions)
        }
        header={header}
        rowKey={"congToId"}
        checkTraLai={checkTraLai}
        trangThai={trangThai}
        soPha={soPha}
        typeDS={typeDS}
        firstTrangThai={firstTrangThai}
        onRow={detailDouble}
        onChangeTrangThai={onChangeTrangThai}
        urlXuatExcel={urlXuatExcel}
        permissionExcel={permissionExcel}
        filterConditionsTraCuu={filterConditionsTraCuu}
        checkLocTheoThang={checkLocTheoThang}
        locTheoThang={locTheoThang}
        dataMesEVNHES={dataMesEVNHES}
      />
      {showModalPC}
      {showModalHuyPC}
      {showModalXoa}
      {showModalDBCmis}
      {showModalEVNHES}
      {showModalMTB}
      {showModalLamMoi}
    </Fragment>
  );
}
