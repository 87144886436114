import { Table } from "antd";

export default function Index(props) {
  const { data, columns } = props;
  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={false}
      scroll={{ y: 300 }}
    />
  );
}
