import { getUserProfile } from "@state/auth/reducer";
import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authPostData, authPostFileData } from "@utils/request";
import { Modal } from "antd";

export const handleUploadImage = (
  setLoading,
  file,
  closeModal,
  dispatch,
  handleKySo
) => {
  authPostFileData({
    url: Endpoint.UPLOAD_CHU_KY,
    method: METHOD_PUT,
    payload: {
      file,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        dispatch(getUserProfile());
        closeModal();
        Modal.confirm({
          centered: true,
          title: "Xác nhận",
          content:
            "Bạn đã tạo chữ ký thành công. Bạn có muốn chuyển sang bước Ký quyết toán USB?",
          onOk() {
            handleKySo();
          },
          onCancel() {},
          okText: "Đồng ý",
          okButtonProps: { type: "primary" },
          cancelText: "Hủy",
        });
      }
    },
  });
};

export const handleKyUSB = (url, signalItems, setLoading, callback) => {
  authPostData({
    url,
    method: METHOD_PUT,
    payload: { listHopDong: signalItems },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        if (callback) callback();
      } else {
      }
    },
  });
};

export const handleKyUSBCapDien = (url, signalItems, setLoading, callback) => {
  authPostData({
    url,
    method: METHOD_POST,
    payload: {
      files: signalItems,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        if (callback) callback();
      } else {
      }
    },
  });
};

export const handleGetHash = (url, payload, tokenCert, callback) => {
  console.log("payload:", payload);
  authPostData({
    url,
    method: METHOD_PUT,
    payload: {
      ...payload,
      cert: tokenCert,
    },
    setLoading: () => {},
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        callback(res.data);
      }
    },
  });
};

export const handleGetHashTreoThao = (url, payload, tokenCert, callback) => {
  console.log("TreoThao - payload: ", { ...payload, cert: tokenCert });
  authPostData({
    url,
    payload: {
      ...payload,
      cert: tokenCert,
    },
    method: METHOD_PUT,
    setLoading: () => {},
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        if (payload.yeuCauIds !== null && payload.yeuCauIds !== undefined)
          callback(res.data.yeuCauKySos);
        else callback(res.data.phieuKySos);
      }
    },
  });
};

export const handleXacThucKySo = (
  url,
  signalItems,
  payload,
  setLoading,
  callback
) => {
  console.log("signalItems 1111111111111: ", signalItems);
  authPostData({
    url,
    method: METHOD_POST,
    payload: { files: signalItems, maTrangThai: payload.maTrangThai },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        if (callback) callback();
      } else {
      }
    },
  });
};

export const handleGetHashKTCT = (url, payload, tokenCert, callback) => {
  authPostData({
    url,
    payload: {
      ...payload,
      cert: tokenCert,
    },
    method: METHOD_PUT,
    setLoading: () => {},
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        callback(res.data.dataKySos);
      }
    },
  });
};

export const handleXacThucKySoKTCT = (
  url,
  signalItems,
  setLoading,
  callback,
  payload
) => {
  authPostData({
    url,
    method: METHOD_PUT,
    payload: { files: signalItems, maLoaiDanhSach: payload.maLoaiDanhSach },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        if (callback) callback();
      } else {
      }
    },
  });
};
