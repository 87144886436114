import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";
import {
  buildQueryString,
  parseParams, 
  alertMessage
} from "@utils/function";
import { STATUSCODE_200 } from "@utils/constants";

export const getListDonViService = (setLoading, setDataDonVi) => {
  authGetData({
    url: `${Endpoint.GET_UNIT_LIST}?pageSize=-1`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDonVi(res.data);
      } else setDataDonVi([]);
    },
  });
};
export const handleListDataTram = (setLoading, setDataNhomTram, id) => {
  authGetData({
    url: `${Endpoint.LIST_TRAM_CT}?donviId=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        res.data.forEach(function (row, i) {
          row.maTram = res.data[i].name.substr(0, res.data[i].name.indexOf('-')).trim();
        });
        setDataNhomTram(res.data);
      }
      else setDataNhomTram(res.data);
    },
  });
};
// get list data bảng kê nhân viên cần tạo
export const handleListBKNVCanTao = (
  setLoading,
  location,
  setListBKNVCanTao,
  setTotalCount,
  filterConditions,
) => {
  let url = "";
  url = `${Endpoint.AG_LIST_YEU_CAU_MTB_DA_GUI_DL}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
    authGetData({
      url,
      setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setListBKNVCanTao(res.data);
        setTotalCount(res.paging.totalCount);
      } else setListBKNVCanTao([]);
    },
  });
};

export const submitTaoBangKe = (
  selectedRowKeys,
  setLoading,
  setFetchDataTable,
  t,
  setSelectedRowKeys
) => {
  authGetData({
    url: Endpoint.AG_TAO_BANG_KE + "?Ids=" + selectedRowKeys,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};
