import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  MONTH_STRING,
} from "@utils/constants";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { exportData, handleDownload, selectBCDSKH } from "./service";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";

export default function Bieu2ChiTietslTinNhanTheoTungCty(props) {
  const { screen, handleChange } = props;
  const [loading, setLoading] = useState(false);
  const user = useSelector(authSelector);
  const [form] = Form.useForm();

  const [filterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    MaDonVi: user.user.unitCode,
  });

  const onFinish = (values) => {
    exportData(values, setLoading);
  };

  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              initialValues={{
                reportType: screen,
                TuNgay: form.setFieldsValue(filterConditions.TuNgay)
                  ? form.setFieldsValue(filterConditions.TuNgay)
                  : moment().startOf(MONTH_STRING),
              }}
            >
              <Form.Item
                name="reportType"
                label="Tên báo cáo"
                labelAlign="left"
              >
                <Select
                  placeholder="Tất cả"
                  options={selectBCDSKH}
                  formKey="reportType"
                  onChange={() => {
                    handleChange(form.getFieldValue("reportType"));
                  }}
                />
              </Form.Item>
              <Row gutter={20}>
                <Col span={24}>
                  <Form.Item
                    name="reportDate"
                    label="Tháng năm"
                    labelAlign="left"
                  >
                    <DatePickerComponent
                      picker="month"
                      formKey="reportDate"
                      form={form}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end">
                <Button
                  type="primary"
                  onClick={() => {
                    handleDownload(form.getFieldValue(), setLoading);
                  }}
                >
                  Xuất Excel
                </Button>
              </Row>
            </FormComponent>
            <Divider className="section-text-line" orientation="left"></Divider>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
