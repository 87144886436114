import { Button, Col, Modal, Row, Table } from "antd";

export default function Index(props) {
  const { dataSource, visible, setVisible, typeLog } = props;
  const columns =
    typeLog === 1
      ? [
          {
            title: "Tên trở ngại",
            key: "tenTroNgai",
            dataIndex: "tenTroNgai",
            render: (text) => <div className="table-text-left">{text}</div>,
            width: "35%",
          },
          {
            title: "Tính chất",
            key: "tinhChat",
            dataIndex: "tinhChat",
            render: (text) => <div className="table-text-right">{text}</div>,
            width: "15%",
          },
          {
            title: "Ghi chú",
            key: "ghiChu",
            dataIndex: "ghiChu",
            render: (text) => <div className="table-text-left">{text}</div>,
            width: "35%",
          },
          {
            title: "Ngày",
            key: "ngayStr",
            dataIndex: "ngayStr",
            render: (text) => <div className="table-text-center">{text}</div>,
            width: "15%",
          },
        ]
      : [
          {
            title: "Lý do trả hồ sơ",
            key: "lyDoTraHS",
            dataIndex: "lyDoTraHS",
            render: (text) => <div className="table-text-left">{text}</div>,
            width: "40%",
          },
          {
            title: "Người trả",
            key: "nguoiTraHS",
            dataIndex: "nguoiTraHS",
            render: (text) => <div className="table-text-left">{text}</div>,
            width: "40%",
          },
          {
            title: "Ngày",
            key: "ngayTraHSStr",
            dataIndex: "ngayTraHSStr",
            render: (text) => <div className="table-text-center">{text}</div>,
            width: "20%",
          },
        ];
  return (
    <Modal
      title={typeLog === 1 ? "Xem Log trở ngại" : "Xem Log trả lại hồ sơ"}
      visible={visible}
      onCancel={() => setVisible(false)}
      className="modal-cau-hinh"
      footer={null}
      width={"70%"}
    >
      <Row gutter={24} justify="center">
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={{ y: 500, x: 600 }}
          />
        </Col>
        <Col span={24} style={{ textAlign: "center", marginTop: "24px" }}>
          <Button
            key="back"
            onClick={() => setVisible(false)}
            className="button-closed"
          >
            Đóng lại
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
