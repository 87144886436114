import React, { useCallback } from "react";
import { Input } from "antd";
const InputComponent = (props) => {
  const {
    form,
    setValue,
    formKey,
    suffix,
    prefix,
    type,
    size,
    disabled,
    placeholder,
  } = props;
  const trimSpace = useCallback(
    (event) => {
      const { value } = event.target;
      if (form) {
        form.setFieldsValue({
          [formKey]: value.trim(),
        });
      }
    },
    [form, formKey]
  );
  const onChange = useCallback(
    (event) => {
      const { value } = event.target;
      if (form) {
        form.setFieldsValue({
          [formKey]: value,
        });
      }
      if (setValue) {
        setValue(value.trim());
      }
    },
    [form, formKey, setValue]
  );

  return (
    <Input
      disabled={disabled}
      value={form && form.getFieldValue(formKey)}
      onChange={onChange}
      suffix={suffix}
      prefix={prefix}
      size={size}
      type={type}
      onBlur={trimSpace}
      placeholder={placeholder}
    />
  );
};

export default InputComponent;
