import LoadingComponent from "@components/Loading";
import { quanLyChiSoSelector } from "@state/giao-nhan-dien-nang/quan-ly-chi-so/";
import { clear, selectTram } from "@state/giao-nhan-dien-nang/quan-ly-chi-so/reducer";
import { Menu, Tooltip } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { getDataTram } from "./service";

export default function DanhSachTram() {
  const { tramIdSelected, loadingGetDiemDo } = useSelector(quanLyChiSoSelector);
  const dispatch = useDispatch();
  const [listTram, setListTram] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getDataTram({
      setListTram,
      setLoading,
      setTramIDSelected: (id) => {},
    });
  }, []);

  const handleSelectTram = useCallback((value) => {
    dispatch(clear());
    dispatch(selectTram(value));
  }, []);
  return (
    <LoadingComponent loading={loading}>
      <div
        style={{
          fontSize: 16,
          padding: "12px 5px 5px 5px",
          fontWeight: 600,
          borderRight: "1px solid #e9e5e5",
          textAlign: "center",
          cursor: "pointer",
          color: !tramIdSelected && "#1890ff",
        }}
        onClick={() => handleSelectTram("")}
      >
        Danh sách trạm (
        {listTram.reduce(function (sum, current) {
          return sum + current.soLuongDiemDo;
        }, 0)}
        &nbsp;điểm&nbsp;đo)
      </div>
      <Scrollbars style={{ height: "calc(100vh - 250px)" }}>
        <Menu selectedKeys={[tramIdSelected]} disabled={loadingGetDiemDo}>
          {listTram?.length > 0 &&
            listTram?.map((item) => {
              let str = `${item?.moTaTram}(${item?.soLuongDiemDo})`;
              if (str.length > 30) {
                str = `${str.slice(0, 30)}...`;
              }
              return (
                <Menu.Item
                  onClick={() => handleSelectTram(item?.tramId)}
                  style={{
                    height: 35,
                    marginBottom: 0,
                    marginTop: 0,
                  }}
                  key={item?.tramId}
                >
                  <Tooltip placement="right" title={`${item?.moTaTram} (${item?.soLuongDiemDo})`}>
                    <div className="trun-cate">{str}</div>
                  </Tooltip>
                </Menu.Item>
              );
            })}
        </Menu>
      </Scrollbars>
    </LoadingComponent>
  );
}
