import { Col, Form, Row } from "antd";
import styled from "styled-components";

export const ContainerItem = styled(Form.Item)`
  .ant-form-item-label {
    text-align: left;
  }
`;

export const ContainerInfo = styled(Row)`
  width: 60%;
  margin: 0 auto !important;
  background: #fafafa;
  padding: 40px;
`;

export const ContainerInfoCol = styled(Col)`
  text-align: center;
`;

export const ContainerH1 = styled.h1`
  font-size: 20px;
  font-weight: 600;
  text-align: left;
`;

export const ContainerInfoBody = styled.div`
  text-align: left;

  span {
    font-weight: bold;
  }
  .total {
  }
  .total-success {
    color: #52c41a;
  }

  .total-fail {
    color: #ff8e8f;
  }
`;

export const ContainerButton = styled.div`
  text-align: center;
  padding-top: 30px;
`;
