import { memo, useEffect, useRef, useState } from "react";
import { Form, Input, Button, Modal, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { handleSubmitForm } from "./services";
const { TextArea } = Input;

function TyLeBanDienModal({
  detaiDataTable,
  visible,
  // unitUser,
  closeModal,
  getListData,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleSubmitData = (values) => {
    handleSubmitForm(
      values,
      detaiDataTable,
      setLoading,
      closeModal,
      getListData,
      form
    );
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();
    // eslint-disable-next-line
  }, []);

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={
          detaiDataTable.id
            ? t("ti_le_ban_dien.cap_nhat_ti_le_ban_dien")
            : t("ti_le_ban_dien.tao_ti_le_ban_dien")
        }
        visible={visible}
        onCancel={closeModal}
        className="modal-ty-le-ban-dien"
        footer={null}
      >
        <FormComponent
          autoComplete="off"
          form={form}
          name="form-ty-le-ban-dien"
          layout="vertical"
          onFinish={handleSubmitData}
          initialValues={{ ...detaiDataTable }}
          scrollToFirstError
        >
          {/* <Row gutter={24}>
            <Col span={8}>
              <Form.Item>
                <span>{t("ti_le_ban_dien.don_vi_quan_ly")}</span>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item>
                <span className="font-bold">{unitUser}</span>
              </Form.Item>
            </Col>
          </Row> */}
          <Row gutter={24}>
            <Col span={24} md={24}>
              <Form.Item
                label="Mã mục đích"
                name="maMucDich"
                className="required-field"
              >
                <Input ref={inputRef} placeholder="Nhập mã mục đích" />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t("ti_le_ban_dien.gia_ban_binh_thuong")}
                name="gioBinhThuong"
                className="required-field"
              >
                <Input
                  ref={inputRef}
                  placeholder="Nhập giá bán khi bình thường"
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t("ti_le_ban_dien.gia_ban_cao_diem")}
                name="gioCaoDiem"
                className="required-field"
              >
                <Input placeholder="Nhập giá bán khi cao điểm" />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t("ti_le_ban_dien.gia_ban_thap_diem")}
                name="gioThapDiem"
                className="required-field"
              >
                <Input placeholder="Nhập giá bán khi thấp điểm" />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label={t("ti_le_ban_dien.gia_ban_kt")} name="gioKT">
                <Input placeholder="Nhập giá bán khi kết thúc" />
              </Form.Item>
            </Col>
            <Col span={24} md={24}>
              <Form.Item
                label={t("ti_le_ban_dien.muc_dich_su_dung")}
                name="mucDichSuDung"
                className="required-field"
              >
                <TextArea rows={3} placeholder="Nhập mục đích sử dụng" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-ty-le-ban-dien"
                loading={loading}
              >
                {detaiDataTable.id ? t("button.cap_nhat") : t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(TyLeBanDienModal);
