import ImportModal from "./modal";

export default function Index(props) {
  const { visible,
    handleCloseImport,
    dataNV,
    setFetchDataTable,
    setDataResponse,
    dataApGiaUyQuyen,
    dataApGiaUyQuyenMacDinh } = props;
  return <ImportModal
    visible={visible}
    handleCloseImport={handleCloseImport}
    dataNV={dataNV}
    setFetchDataTable={setFetchDataTable}
    setDataResponse={setDataResponse}
    dataApGiaUyQuyen={dataApGiaUyQuyen}
    dataApGiaUyQuyenMacDinh={dataApGiaUyQuyenMacDinh}
  />;
}
