import { APIGDV_CRM } from "../../api-url/crm/giao-dich-vien/index";
import { API_COMMON_LIST_CRM } from "../../api-url/crm/common/index";
import { API_DOI_TRUONG_CRM } from "../../api-url/crm/doi-truong/index";
import { APINVP_CRM } from "../../api-url/crm/nhan-vien-phong/index";
import { APINVHT_CRM } from "../../api-url/crm/nhan-vien-hien-truong/index";
import {
  API_LANH_DAO_CRM,
  API_NV_PHONG_KD_CRM,
  API_TRUONG_PHONG_KD_CRM,
} from "../../api-url/crm";
import { API_KINH_DOANH } from "../../api-url/crm/kinh-doanh/index";
import { API_DIEU_DO_CRM } from "@apiUrl/crm/dieu-do";
import { API_NV_GIAM_SAT_CRM } from "../../api-url/crm/giam-sat/index";
import { API_DONG_BO_CRM } from "../../api-url/crm/dong-bo-crm";
import { API_TRA_CUU_TIM_KIEM_CRM } from "../../api-url/crm/tra-cuu-tiem-kiem/index";
export const CRM_Endpoint = {
  // GDV
  CRMGDV_DANH_SACH_YEU_CAU: APIGDV_CRM.CRMGDV_DANH_SACH_YEU_CAU,
  CRMGDV_DANH_SACH_YEU_CAU_CO_TRO_NGAI:
    APIGDV_CRM.CRMGDV_DANH_SACH_YEU_CAU_CO_TRO_NGAI,
  CRMGDV_TRA_LAI_YEU_CAU: APIGDV_CRM.CRMGDV_TRA_LAI_YEU_CAU,
  CRMGDV_PHAN_CONG: APIGDV_CRM.CRMGDV_PHAN_CONG,
  CRMGDV_DANH_SACH_YEU_CAU_DA_PHAN_CONG:
    APIGDV_CRM.CRMGDV_DANH_SACH_YEU_CAU_DA_PHAN_CONG,
  CRMGDV_DANH_SACH_DUYET_YEU_CAU: APIGDV_CRM.CRMGDV_DANH_SACH_DUYET_YEU_CAU,
  CRMGDV_XAC_NHAN: APIGDV_CRM.CRMGDV_XAC_NHAN,
  CRMGDV_PHAN_PHONG: APIGDV_CRM.CRMGDV_PHAN_PHONG,
  CRMGDV_KY_SO: APIGDV_CRM.CRMGDV_KY_SO,
  CRMGDV_DANH_SACH_TRUONG_CA_DUYET_YEU_CAU:
    APIGDV_CRM.CRMGDV_DANH_SACH_TRUONG_CA_DUYET_YEU_CAU,
  CRMGDV_PHAN_CONG_LAI: APIGDV_CRM.CRMGDV_PHAN_CONG_LAI,
  CRMGDV_KET_QUA_XU_LY: APIGDV_CRM.CRMGDV_KET_QUA_XU_LY,
  CRMGDV_CAP_NHAT_KET_QUA_XU_LY: APIGDV_CRM.CRMGDV_CAP_NHAT_KET_QUA_XU_LY,
  CRMGDV_CAP_NHAT_YEU_CAU_SAI: APIGDV_CRM.CRMGDV_CAP_NHAT_YEU_CAU_SAI,
  CRMGDV_KY_HSM: APIGDV_CRM.CRMGDV_KY_HSM,
  // COMMON
  CRM_LIST_NHAN_VIEN_PHONG_KD: API_COMMON_LIST_CRM.CRM_LIST_NHAN_VIEN_PHONG_KD,
  CRM_LIST_DANH_SACH_DOI_TRUONG:
    API_COMMON_LIST_CRM.CRM_LIST_DANH_SACH_DOI_TRUONG,
  CRM_LIST_DANH_SACH_KENH: API_COMMON_LIST_CRM.CRM_LIST_DANH_SACH_KENH,
  CRM_LIST_LOAI_DICH_VU: API_COMMON_LIST_CRM.CRM_LIST_LOAI_DICH_VU,
  CRM_LIST_TRANG_THAI_YEU_CAU: API_COMMON_LIST_CRM.CRM_LIST_TRANG_THAI_YEU_CAU,
  CRM_LIST_LINH_VUC: API_COMMON_LIST_CRM.CRM_LIST_LINH_VUC,
  CRM_LIST_NV_DIEU_DO: API_COMMON_LIST_CRM.CRM_LIST_NV_DIEU_DO,
  CRM_TAI_ANH: API_COMMON_LIST_CRM.CRM_TAI_ANH,
  CRM_LOAI_YEU_CAU: API_COMMON_LIST_CRM.CRM_LOAI_YEU_CAU,
  CRM_LIST_NHAN_VIEN_GIAM_SAT: API_COMMON_LIST_CRM.CRM_LIST_NHAN_VIEN_GIAM_SAT,
  CRM_LIST_PHONG_THUC_HIEN: API_COMMON_LIST_CRM.CRM_LIST_PHONG_THUC_HIEN,
  CRM_UPDATE_HOP_DONG_KY_SO: API_COMMON_LIST_CRM.CRM_UPDATE_HOP_DONG_KY_SO,
  CRM_CHI_TIET_YEU_CAU: API_COMMON_LIST_CRM.CRM_CHI_TIET_YEU_CAU,
  CRM_LICH_SU_THAO_TAC: API_COMMON_LIST_CRM.CRM_LICH_SU_THAO_TAC,
  // DOITRUONG
  CRM_DOI_TRUONG_PHAN_CONG_DOI: API_DOI_TRUONG_CRM.CRM_DOI_TRUONG_PHAN_CONG_DOI,
  CRM_DOI_TRUONG_PHAN_CONG_NGUOI_KHAO_SAT:
    API_DOI_TRUONG_CRM.CRM_DOI_TRUONG_PHAN_CONG_NGUOI_KHAO_SAT,
  CRM_DOI_TRUONG_DANH_SACH_YEU_CAU:
    API_DOI_TRUONG_CRM.CRM_DOI_TRUONG_DANH_SACH_YEU_CAU,
  CRM_DOI_TRUONG_PHAN_CONG: API_DOI_TRUONG_CRM.CRM_DOI_TRUONG_PHAN_CONG,
  CRM_DOI_TRUONG_DANH_SACH_DUYET_YEU_CAU:
    API_DOI_TRUONG_CRM.CRM_DOI_TRUONG_DANH_SACH_DUYET_YEU_CAU,
  CRM_DOI_TRUONG_DUYET: API_DOI_TRUONG_CRM.CRM_DOI_TRUONG_DUYET,
  CRM_DOI_TRUONG_TRA_YEU_CAU: API_DOI_TRUONG_CRM.CRM_DOI_TRUONG_TRA_YEU_CAU,
  CRM_DOI_TRUONG_XAC_NHAN: API_DOI_TRUONG_CRM.CRM_DOI_TRUONG_XAC_NHAN,
  CRM_DOI_TRUONG_KY_SO: API_DOI_TRUONG_CRM.CRM_DOI_TRUONG_KY_SO,
  CRM_DOI_TRUONG_KY_HSM: API_DOI_TRUONG_CRM.CRM_DOI_TRUONG_KY_HSM,
  // NHAN VIEN PHONG
  CRMNVP_DANH_SACH_YEU_CAU: APINVP_CRM.CRMNVP_DANH_SACH_YEU_CAU,

  // NHAN VIEN HIEN TRUONG
  CRMNVHT_DANH_SACH_YEU_CAU_DUOC_PHAN_CONG:
    APINVHT_CRM.CRMNVHT_DANH_SACH_YEU_CAU_DUOC_PHAN_CONG,
  CRMNVHT_DANH_SACH_YEU_CAU_DANG_THUC_HIEN:
    APINVHT_CRM.CRMNVHT_DANH_SACH_YEU_CAU_DANG_THUC_HIEN,
  CRMNVHT_XAC_NHAN_YEU_CAU: APINVHT_CRM.CRMNVHT_XAC_NHAN_YEU_CAU,
  CRMNVHT_YEU_CAU_CHO_DUYET: APINVHT_CRM.CRMNVHT_YEU_CAU_CHO_DUYET,
  CRMNVHT_YEU_CAU_CO_TRO_NGAI: APINVHT_CRM.CRMNVHT_YEU_CAU_CO_TRO_NGAI,
  CRMNVHT_DANH_SACH_YEU_CAU: APINVHT_CRM.CRMNVHT_DANH_SACH_YEU_CAU,
  CRMNVHT_DANH_SACH_XAC_NHAN_YEU_CAU:
    APINVHT_CRM.CRMNVHT_DANH_SACH_XAC_NHAN_YEU_CAU,
  CRMNVHT_DANH_SACH_TRO_NGAI: APINVHT_CRM.CRMNVHT_DANH_SACH_TRO_NGAI,
  CRMNVHT_CAP_NHAT_TRO_NGAI: APINVHT_CRM.CRMNVHT_CAP_NHAT_TRO_NGAI,

  // Yêu cầu
  CRM_LANH_DAO_LIST_YEU_CAU: API_LANH_DAO_CRM.YEU_CAU_LANH_DAO,
  CRM_TRUONG_PHONG_KD_LIST_YEU_CAU:
    API_TRUONG_PHONG_KD_CRM.YEU_CAU_TRUONG_PHONG_KD,
  YEU_CAU_NV_PHONG_KD: API_NV_PHONG_KD_CRM.YEU_CAU_NV_PHONG_KD,

  // kinh doanh
  CRMKD_DANH_SACH_YEU_CAU_DANG_THUC_HIEN:
    API_KINH_DOANH.CRMKD_DANH_SACH_YEU_CAU_DANG_THUC_HIEN,
  CRMKD_XAC_NHAN: API_KINH_DOANH.CRMKD_XAC_NHAN,
  CRMKD_DUYET_YEU_CAU_DOI: API_KINH_DOANH.CRMKD_DUYET_YEU_CAU_DOI,
  CRMKD_DUYET_YEU_CAU_PKD: API_KINH_DOANH.CRMKD_DUYET_YEU_CAU_PKD,
  CRM_TRUONG_PKD_XAC_NHAN: API_KINH_DOANH.CRM_TRUONG_PKD_XAC_NHAN,
  CRM_TRUONG_PKD_KY_SO: API_KINH_DOANH.CRM_TRUONG_PKD_KY_SO,
  CRM_TRUONG_PKD_TRA_LAI: API_KINH_DOANH.CRM_TRUONG_PKD_TRA_LAI,
  CRM_TRUONG_PKD_KY_HSM: API_KINH_DOANH.CRM_TRUONG_PKD_KY_HSM,
  // điều độ
  CRMDD_DUYET_YEU_CAU: API_DIEU_DO_CRM.DUYET_YEU_CAU,
  // CRMDD_KY_SO: API_DIEU_DO_CRM.KY_SO_YEU_CAU,
  CRMDD_TRUONG_PHONG_XAC_NHAN: API_DIEU_DO_CRM.TRUONG_PHONG_XAC_NHAN_YEU_CAU,
  CRMDD_TRUONG_PHONG_XAC_NHAN_TRA_YEU_CAU:
    API_DIEU_DO_CRM.TRUONG_PHONG_XAC_NHAN_TRA_YEU_CAU,

  // lãnh đạo
  CRM_LANH_DAO_KY_SO: API_LANH_DAO_CRM.KY_SO_YEU_CAU,
  CRM_LANH_DAO_XAC_NHAN: API_LANH_DAO_CRM.XAC_NHAN_YEU_CAU,
  CRM_LANH_DAO_TRA_YEU_CAU: API_LANH_DAO_CRM.XAC_NHAN_TRA_YEU_CAU,
  KY_HSM_LANH_DAO: API_LANH_DAO_CRM.KY_HSM_LANH_DAO,
  // NV GIÁM SÁT
  NVGS_DANH_SACH_YEU_CAU: API_NV_GIAM_SAT_CRM.DANH_SACH_YEU_CAU,

  // ĐỒNG BỘ WEB/CRM
  CRM_DONG_BO_WEB_CRM: API_DONG_BO_CRM.DONG_BO_WEB_CRM,
  CRM_DONG_BO_CMIS_NGUYEN_NHAN_MD: API_DONG_BO_CRM.DONG_BO_CMIS_NGUYEN_NHAN_MD,

  // TRA CUU TIM KIEM
  CRM_DANH_SACH_YEU_CAU_TRA_CUU:
    API_TRA_CUU_TIM_KIEM_CRM.CRM_DANH_SACH_YEU_CAU_TRA_CUU,

  CRM_EXPORT_DANH_SACH_YEU_CAU_TRA_CUU:
  API_TRA_CUU_TIM_KIEM_CRM.CRM_EXPORT_DANH_SACH_YEU_CAU_TRA_CUU,
};
