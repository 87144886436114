import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Col, Divider, Row } from "antd";
import { Column } from "@ant-design/plots";
import { authPostData } from "@utils/request";
import NoRecordFound from "@components/NoRecordFound";
import { Endpoint } from "@utils/endpoint";
const ThongKeLich = () => {
  const [dataLich, setDataLich] = useState();
  const [loading, setLoading] = useState(null);
  const getChartDasboardLich = useCallback(() => {
    authPostData({
      url: `${Endpoint.LICH_GCS}`,
      method: "POST",
      setLoading,
      payload: {},
      onSuccess: (res) => {
        // map 2 data voi nhau
        const newData = res.data.xValues.map((e, i) => ({
          type: e,
          sales: res.data.series.data[i],
        }));
        setDataLich(newData);
      },
    });
  }, []);
  useEffect(() => {
    getChartDasboardLich();
  }, [getChartDasboardLich]);
  const config = useMemo(
    () => ({
      xField: "type",
      yField: "sales",
      label: {
        position: "middle",
        style: {
          fill: "#FFFFFF",
          opacity: 0.6,
        },
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      meta: {
        type: {
          alias: "dữ liệu",
        },
        sales: {
          alias: "dữ liệu",
        },
      },
    }),
    []
  );
  const renderContent = useCallback(() => {
    if (!loading && !dataLich) {
      return (
        <div className="text-center">
          <NoRecordFound />
        </div>
      );
    }
    if (dataLich) {
      return <Column {...config} data={dataLich} />;
    }
  }, [dataLich, loading, config]);
  return (
    <div className="chart-container-lich">
      <div className="container-lich">
        <Divider plain="true" className="section-text">
          <h2 className="chart">Biểu đồ lịch GCS trong tháng</h2>
        </Divider>
        <Row gutter={5}>
          <Col span={24}>{renderContent()}</Col>
        </Row>
      </div>
    </div>
  );
};
export default ThongKeLich;
