import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Divider, Form, Row, Spin } from "antd";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { handleDownload, handleOpenPdf } from "./service";

export default function TKThongTinLienHeLQDenKhCuaCTDL(props) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);
  const onFinish = (values) => {
    handleDownload(
      {
        ...values,
        reportType: Endpoint.BCTK_TKTT_LIEN_HE_LIEN_QUAN_DEN_KH_CUA_CTDL,
      },
      setLoading
    );
  };
  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Thống kê thông tin liên hệ liên quan đến khách hàng của công ty điện
          lực
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              initialValues={{
                unitId: user.user.unitCode,
              }}
              colon={false}
            >
              <Form.Item
                name="unitId"
                label="Đơn vị"
                labelAlign="left"
                required
              >
                <Selection
                  url={Endpoint.GET_TEAM_LIST_CODE}
                  formKey="unitId"
                  form={form}
                />
              </Form.Item>

              <Form.Item
                name="TuNgay"
                label="Từ ngày"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="Day"
                  formKey="TuNgay"
                  form={form}
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>
              <Form.Item
                name="DenNgay"
                label="Đến ngày"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="Day"
                  formKey="DenNgay"
                  form={form}
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>

              <Row>
                <Col span={4}></Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>
                </Col>
                <Col>
                  {/* <Button
                    type="primary"
                    loading={loading}
                    className="ml-20px"
                    onClick={() => {
                      handleOpenPdf(
                        {
                          ...form.getFieldValue(),
                          reportType:
                            Endpoint.BCTK_TKTT_LIEN_HE_LIEN_QUAN_DEN_KH_CUA_CTDL,
                        },
                        setLoading
                      );
                    }}
                  >
                    In báo cáo
                  </Button> */}
                </Col>
              </Row>
            </FormComponent>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
