import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import TableComponent from "@components/TableComponent";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { downloadFile } from "@utils/request";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { selectTabTH } from "../../../services";
import { getDataB4_Gop_Ep } from "./services";
export default function Bieu4CCGopEp(props) {
  const { screen, handleChange } = props;
  const [form] = Form.useForm();
  const [dataTHEP, setDataB4_TH_EP] = useState([]);
  const [loading, setLoading] = useState();
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
    DenNgay: moment().format(FORMAT_ENGLISH),
    ...parseParams(location.search),
  });
  // layout effect
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);

  const [filterExcel, setFilterExcel] = useState(false);
  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type]);

  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        TuNgay: values.TuNgay,
        DenNgay: values.DenNgay,
      }));
      const payload = {
        TuNgay: values.TuNgay,
        DenNgay: values.DenNgay,
      };
      handleAPI();
      getDataB4_Gop_Ep(payload, setLoading, setDataB4_TH_EP);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleAPI]
  );

  // const clearFilter = useCallback(() => {
  //   setFilterConditions({
  //     pageIndex: DEFAULT_PAGEINDEX,
  //     pageSize: DEFAULT_PAGESIZE,
  //     TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
  //     DenNgay: moment().format(FORMAT_ENGLISH),
  //   });
  // }, []);

  // const clear = useCallback(() => {
  //   form.resetFields();
  //   clearFilter();
  // }, [clearFilter, form]);
  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      width: 70,
      align: "center",
      render: (a, b, index) => {
        return (
          (filterConditions.pageIndex - 1) * (filterConditions.pageSize + 1) +
          (index + 1)
        );
      },
    },
    {
      title: "Mã đơn vị",
      dataIndex: "MA_DVIQLY",
      width: 100,
    },
    {
      title: "Số lượng đã nhắn viettel",
      dataIndex: "SL_DANHAN_VIETTEL",
      width: 150,
    },
    {
      title: "Đơn giá viettel",
      dataIndex: "DONGIA_VIETTEL",
      width: 100,
    },
    {
      title: "Tiền Viettel",
      dataIndex: "TIEN_VIETTEL",
      width: 100,
    },
    {
      title: "Số lượng đã nhắn Vina",
      dataIndex: "SL_DANHAN_VINA",
      width: 150,
    },
    {
      title: "Đơn giá Vina",
      dataIndex: "DONGIA_VINA",
      width: 100,
    },
    {
      title: "Tiền Vina",
      dataIndex: "TIEN_VINA",
      width: 100,
    },
    {
      title: "Số lượng đã nhắn Mobi",
      dataIndex: "SL_DANHAN_MOBI",
      width: 150,
    },
    {
      title: "Đơn giá Mobi",
      dataIndex: "DONGIA_MOBI",
      width: 100,
    },
    {
      title: "Tiền Mobi",
      dataIndex: "TIEN_MOBI",
      width: 100,
    },
    {
      title: "Số lượng đã nhắn VNMB",
      dataIndex: "SL_DANHAN_HT",
      key: "SL_DANHAN_HT",
      width: 100,
    },
    {
      title: "Đơn giá VNMB",
      dataIndex: "DONGIA_HT",
      key: "DONGIA_HT",
      width: 100,
    },
    {
      title: "Tiền VNMB",
      dataIndex: "TIEN_HT",
      width: 100,
    },
    {
      title: "Số lượng đã nhắn GTEL",
      dataIndex: "SL_DANHAN_BL",
      width: 150,
    },
    {
      title: "Đơn giá GTEL",
      dataIndex: "DONGIA_BL",
      width: 100,
    },
    {
      title: "Tiền GTEL",
      dataIndex: "TIEN_BL",
      width: 100,
    },
    {
      title: "Số lượng đã nhắn ITEL",
      dataIndex: "SL_DANHAN_ITEL",
      width: 150,
    },
    {
      title: "Đơn giá ITEL",
      dataIndex: "DONGIA_ITEL",
      width: 100,
    },
    {
      title: "Tiền ITEL",
      dataIndex: "TIEN_ITEL",
      width: 100,
    },
    {
      title: "Tổng đã nhắn",
      dataIndex: "TONG_DANHAN",
      width: 100,
    },
    {
      title: "Tổng tiền đã nhắn",
      dataIndex: "TONG",
      width: 100,
    },
  ];

  useEffect(() => {
    if (filterExcel) {
      const endpoint =
        Endpoint.B4_TH_DOI_SOAT_GOP_EP_EXCEL +
        "?" +
        buildQueryString(parseParams(filterConditions));
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel]);
  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <FormComponent
            onFinish={handleSearch}
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            initialValues={{
              reportType: screen,
            }}
          >
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="reportType"
                  label="Tên báo cáo"
                  labelCol={{ span: 3 }}
                  labelAlign="left"
                >
                  <Select
                    formKey="reportType"
                    form={form}
                    options={selectTabTH}
                    onChange={() =>
                      handleChange(form.getFieldValue("reportType"))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="TuNgay"
                  label="Ngày bắt đầu"
                  labelAlign="left"
                  labelCol={{ span: 9 }}
                >
                  <div style={{ marginLeft: "3%" }}>
                    <DatePickerComponent
                      formKey="TuNgay"
                      form={form}
                      labelAlign="left"
                      format={FORMAT_ENGLISH}
                      defaultValue={
                        filterConditions.TuNgay
                          ? moment(filterConditions.TuNgay, FORMAT_ENGLISH)
                          : moment()
                      }
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="DenNgay"
                  label="Ngày kết thúc"
                  labelAlign="right"
                  labelCol={{ span: 11 }}
                >
                  <DatePickerComponent
                    formKey="DenNgay"
                    form={form}
                    labelAlign="left"
                    format={FORMAT_ENGLISH}
                    defaultValue={
                      filterConditions.DenNgay
                        ? moment(filterConditions.DenNgay, FORMAT_ENGLISH)
                        : moment()
                    }
                  />
                </Form.Item>
              </Col>
              <Row justify="end">
                <Button
                  type="primary"
                  className="mr-5px"
                  htmlType="submit"
                  onClick={() => setType(ENUM_BUTTON_TYPE.TIM_KIEM)}
                >
                  Xem báo cáo
                </Button>
                <Button
                  type="primary"
                  className="mr-5px"
                  htmlType="submit"
                  onClick={() => setType(ENUM_BUTTON_TYPE.XUAT_EXCEL)}
                >
                  Xuất excel
                </Button>
                {/* <XuatExcel
                  endpoint={
                    Endpoint.B4_TH_DOI_SOAT_GOP_EP_EXCEL +
                    "?" +
                    buildQueryString(parseParams(filterConditions))
                  }
                  text={"Xuất Excel"}
                  key={"xuat-excel"}
                /> */}
              </Row>
            </Row>
            <Divider className="section-text-line" orientation="left"></Divider>
          </FormComponent>
          <TableComponent
            header={
              "Biểu 4_CC: Đối soát số lượng tin nhắn đã thực hiện thành công (Đã gộp EP)"
            }
            columns={columns}
            dataSource={dataTHEP}
            loading={loading}
            scrollX={1400}
          />
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 10px 16px;
`;
