import FormComponent from "@components/Form";
import { removeAccents } from "@utils/function";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getListDonVi, getNhanVienThuTien } from "./services";

export default function ListBoLoc(props) {
  const { handleSearch, clearFilter, filterConditions, form } = props;
  const unitId = useSelector((state) => state.auth.user.unitId);
  const [loading, setLoading] = useState(false);
  const [listDonVi, setListDonVi] = useState([]);
  const [listNv, setListNv] = useState([]);
  const [donVi, setDonVi] = useState(filterConditions.DonViId);
  const listTrangThai = [
    {
      value: 0,
      name: "Chưa thu",
    },
    {
      value: 1,
      name: "Đã thu",
    },
    {
      value: 2,
      name: "Đã đồng bộ",
    },
  ];
  useEffect(() => {
    form.setFieldsValue({
      ...filterConditions,
      ...(filterConditions.TrangThaiWeb !== undefined && {
        TrangThaiWeb: Number(filterConditions.TrangThaiWeb),
      }),
      TuNgay: filterConditions.TuNgay
        ? moment(new Date(filterConditions.TuNgay))
        : undefined,
      DenNgay: filterConditions.DenNgay
        ? moment(new Date(filterConditions.DenNgay))
        : undefined,
    });
  }, [filterConditions, form]);

  useEffect(() => {
    getListDonVi(setLoading, setListDonVi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (donVi) {
      getNhanVienThuTien(setLoading, setListNv, donVi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donVi]);
  const handleChangeDonVi = (value) => {
    setDonVi(value);
    form.setFieldsValue({
      NVThuNo: undefined,
    });
  };

  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [clearFilter, form]);
  return (
    <Styled>
      <div className="thanh-toan-bo-loc">
        <FormComponent
          layout="vertical"
          form={form}
          onFinish={handleSearch}
          initialValues={{
            DonViId: unitId,
          }}
        >
          <Row gutter={[8, 16]}>
            <Col span={6}>
              <Form.Item name="DonViId" label="Đơn vị">
                <Select
                  onChange={handleChangeDonVi}
                  loading={loading}
                  showSearch
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                  disabled={listDonVi.length < 2}
                >
                  {listDonVi &&
                    listDonVi.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="SoGCS" label="Sổ GCS">
                <Input />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name="MaKhachHang" label="Mã khách hàng">
                <Input />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name="NVThuNo" label="NV thu nợ">
                <Select
                  allowClear
                  showSearch
                  loading={loading}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {listNv &&
                    listNv.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.valueId}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Form.Item name="TuNgay" label="Từ ngày">
                <DatePicker format={"DD/MM/YYYY"} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="DenNgay" label="Đến ngày">
                <DatePicker format={"DD/MM/YYYY"} />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name="TrangThaiWeb" label="Trạng thái">
                <Select
                  placeholder="Tất cả"
                  allowClear
                  showSearch
                  loading={loading}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {listTrangThai.map((item, id) => {
                    return (
                      <Select.Option key={id} value={item.value}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6} offset={6} className="thanh-toan-bo-loc-button">
              <Button onClick={clear}>Bỏ lọc</Button>
              <Button type="primary" htmlType="submit">
                Tìm kiếm
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  .thanh-toan-bo-loc {
    .thanh-toan-bo-loc-button {
      display: flex;
      gap: 10px !important;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;
