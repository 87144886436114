import { memo, useEffect, useRef, useState } from "react";
import { Form } from "antd";
import UITaoMoi from "./modal";
import { FORMAT_ENGLISH } from "@utils/constants";
import moment from "moment";
import { formatYYYYMMDDString } from "@utils/function";
import { handleCreate } from "../services";

function Index({ detail, visible, closeModal, getListData, parent }) {
  const inputRef = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const toDay = moment().format(FORMAT_ENGLISH);
  const [ngay, setNgay] = useState(toDay);
  const onFinish = (values) => {
    const data = {
      ...values,
    };

    const convert = moment(ngay);
    data.keHoachTiepNhan = formatYYYYMMDDString(
      convert.year(),
      convert.month(),
      convert.date()
    );

    handleCreate(setLoading, data, getListData, closeModal, form, parent);
    // console.log(data);
    //submit data
    // handleSubmitForm(
    //   values,
    //   detaiDataTable,
    //   setLoading,
    //   closeModal,
    //   getListData,
    //   form,
    //   tuNgay,
    //   denNgay
    // );
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();
    // eslint-disable-next-line
  }, []);

  const handlChangeNgay = (date) => {
    let convert = "";
    if (date === null) convert = null;
    else convert = moment(date).format(FORMAT_ENGLISH);
    setNgay(convert);
  };

  return (
    <UITaoMoi
      loading={loading}
      visible={visible}
      closeModal={closeModal}
      form={form}
      onFinish={onFinish}
      detail={detail}
      handlChangeNgay={handlChangeNgay}
      ngay={ngay}
      inputRef={inputRef}
      parent={parent}
    />
  );
}

export default memo(Index);
