import DatePickerComponent from "@components/DatePicker";
import Filter from "@components/Filter/Filter";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { downloadFile } from "@utils/request";
import { Button, Col, Divider, Form, Row, Select, Tooltip } from "antd";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { selectTabDVK } from "../../../services";
import XuatExcel from "../../xuat-excel";
import { getListDataB2_DVK } from "./services";
export default function IndexBieu2(props) {
  const { screen, handleChange } = props;
  const [loading, setLoading] = useState();
  const [data, setDataB2] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [, setSearchParams] = useSearchParams();
  const user = useSelector(authSelector);
  const location = useLocation();
  const [form] = Form.useForm();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    MaDonVi: user.user.unitCode,
    TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
    DenNgay: moment().format(FORMAT_ENGLISH),
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const [filterExcel, setFilterExcel] = useState(false);
  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type]);

  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        MaDonVi: values.MaDonVi,
        doiTuong: values.doiTuong,
        TuNgay: values.TuNgay,
        DenNgay: values.DenNgay,
      }));
      const payload = {
        MaDonVi: values.MaDonVi,
        doiTuong: values.doiTuong,
        TuNgay: values.TuNgay,
        DenNgay: values.DenNgay,
      };
      handleAPI();
      getListDataB2_DVK(payload, setLoading, setDataB2, setTotalCount);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleAPI]
  );

  // const clearFilter = useCallback(() => {
  //   setFilterConditions({
  //     pageIndex: DEFAULT_PAGEINDEX,
  //     pageSize: DEFAULT_PAGESIZE,
  //     MaDonVi: user.user.unitCode,
  //     TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
  //     DenNgay: moment().format(FORMAT_ENGLISH),
  //   });
  // }, [user.user.unitCode]);

  // const clear = useCallback(() => {
  //   form.resetFields();
  //   clearFilter();
  // }, [clearFilter, form]);

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      width: 70,
      align: "center",
      render: (a, b, index) => {
        return (
          (filterConditions.pageIndex - 1) * (filterConditions.pageSize + 1) +
          (index + 1)
        );
      },
    },
    {
      title: "ID SMS",
      dataIndex: "IDSMS",
      key: "IDSMS",
      width: 150,
    },
    {
      title: "Mã khách hàng",
      dataIndex: "MA_KHANG",
      width: 150,
      key: "MA_KHANG",
    },
    {
      title: "Điện thoại",
      dataIndex: "DTHOAI",
      width: 150,
      key: "DTHOAI",
    },
    {
      title: "Nội dung",
      dataIndex: "NOI_DUNG",
      width: 150,
      key: "NOI_DUNG",
      render: (text) => (
        <Tooltip title={text} align="center">
          <Button>Nội dung cú pháp</Button>
        </Tooltip>
      ),
    },
    {
      title: "Thời gian thực hiện",
      dataIndex: "TGIAN_TH",
      width: 150,
      key: "TGIAN_TH",
      render: (TGIAN_TH) => {
        return <span>{TGIAN_TH}</span>;
      },
    },
    {
      title: "Tình trạng",
      dataIndex: "TINHTRANG",
      width: 150,
      key: "TINHTRANG",
    },
    {
      title: "Nhà mạng",
      dataIndex: "NHA_MANG",
      width: 150,
      key: "NHA_MANG",
    },
  ];

  const fakeTableTitle =
    "Biểu 2: Chi tiết số lượng tin nhắn theo từng Công ty Điện Lực";
  useEffect(() => {
    if (filterExcel) {
      const endpoint =
        Endpoint.B2_EXPORT_EXCEL +
        "?" +
        buildQueryString(parseParams(filterConditions));
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel]);
  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <FormComponent
            onFinish={handleSearch}
            form={form}
            initialValues={{
              reportType: screen,
              doiTuong: "e12382fc-5ebb-474d-a1a7-de277b424007",
            }}
          >
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="reportType"
                  label="Tên báo cáo"
                  labelAlign="left"
                  labelCol={{ span: 3 }}
                >
                  <Select
                    formKey="reportType"
                    form={form}
                    options={selectTabDVK}
                    onChange={() =>
                      handleChange(form.getFieldValue("reportType"))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="MaDonVi"
                  label="Đơn vị"
                  labelAlign="left"
                  labelCol={{ span: 3 }}
                >
                  <Selection
                    url={Endpoint.GET_TEAM_LIST_CODE}
                    formKey="MaDonVi"
                    form={form}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="doiTuong"
                  label="Nội dung"
                  labelAlign="left"
                  labelCol={{ span: 3 }}
                >
                  <Selection
                    url={Endpoint.CSKH_BAO_CAO_EMAIL}
                    formKey="doiTuong"
                    form={form}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="TuNgay"
                  label="Ngày bắt đầu"
                  labelAlign="left"
                  labelCol={{ span: 9 }}
                >
                  <div style={{ marginLeft: "3%" }}>
                    <DatePickerComponent
                      formKey="TuNgay"
                      form={form}
                      format={FORMAT_ENGLISH}
                      defaultValue={
                        filterConditions.TuNgay
                          ? moment(filterConditions.TuNgay, FORMAT_ENGLISH)
                          : moment()
                      }
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="DenNgay"
                  label="Ngày kết thúc"
                  labelAlign="left"
                  labelCol={{ span: 11 }}
                >
                  <DatePickerComponent
                    formKey="DenNgay"
                    form={form}
                    format={FORMAT_ENGLISH}
                    defaultValue={
                      filterConditions.DenNgay
                        ? moment(filterConditions.DenNgay, FORMAT_ENGLISH)
                        : moment()
                    }
                  />
                </Form.Item>
              </Col>
              <Row justify="end">
                {/* <Button type="dasher" className="mr-5px" onClick={clear}>
                  Bỏ lọc
                </Button> */}
                <Button
                  type="primary"
                  className="mr-5px"
                  htmlType="submit"
                  onClick={() => setType(ENUM_BUTTON_TYPE.TIM_KIEM)}
                >
                  Xem báo cáo
                </Button>
                <Button
                  type="primary"
                  className="mr-5px"
                  htmlType="submit"
                  onClick={() => setType(ENUM_BUTTON_TYPE.XUAT_EXCEL)}
                >
                  Xuất excel
                </Button>
                {/* <XuatExcel
                  endpoint={
                    Endpoint.B2_EXPORT_EXCEL +
                    "?" +
                    buildQueryString(parseParams(filterConditions))
                  }
                  text={"Xuất Excel"}
                  key={"xuat-excel"}
                /> */}
              </Row>
            </Row>
          </FormComponent>
          <Divider className="section-text-line" orientation="left"></Divider>
          <TableComponent
            header={fakeTableTitle}
            columns={columns}
            dataSource={data}
            totalCount={totalCount}
            loading={loading}
            pagination={filterConditions}
            onChangePagination={onChangePagination}
            scrollX={1400}
            filterConditions={filterConditions}
          />
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 10px 16px;
`;
