import styled from "styled-components";

export const ContainerStyled = styled.div`
  .ant-form-item {
    margin: 0 !important;
  }

  .ant-table-tbody > tr > td {
    margin: 0 !important;
  }
  .ant-table-tbody > tr > td {
    border-right: 1px solid #f0f0f0;
  }
  padding-bottom: 10px;
`;
export const TableStyled = styled.div`
  .text-13{
    font-size: 13px;
  }
  .error-quantity {
    .quantity {
      border-color: #ff4d4f !important;
    }
  }
  .error-last-stat {
    .last-stat {
      border-color: #ff4d4f !important;
    }
  }
  .ant-form-item-explain {
    display: none;
  }
  .ant-checkbox-disabled {
    display: none;
  }
  .ant-table-tbody > tr > td {
    padding: 4px !important;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: transparent !important;
  }
  .row-color-table .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: #ffd646 !important;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding: 8px;
  }
  .ant-pagination-options{
    display: none;
  }
`;
export const ContentTB = styled.div`
  border: 1px solid #e9e5e5;
  border-radius: 4px;
`;

export const ContainerTB = styled.div`
  display: flex;
  padding: 10px;
`;

export const Page = styled.div`
  padding: 10px 20px 0;
`;
export const InfoPage = styled.div`
  display: block;
`;
export const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const TitlePage = styled.div`
  display: flex;
  width: 100%;
`;
export const Date = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding-bottom: 14px;
`;
export const Container = styled.div`
  display: flex;
  border-bottom: 1px solid #e9e5e5;
  padding: 0px 15px;
`;

export const ContainerConfirm = styled.div`
  padding: 0 8px;
`;
