import Filter from "../../common/Filter";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_MONTH_ENGLISH,
  STATUSCODE_200,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { authGetData } from "@utils/request";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Export } from "../export/Export";
import FilterBKDsCacKHCapNhatThayDoi from "./filter";

export default function BKDsCacKHCapNhatThayDoi() {
  const [, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const { user } = useSelector(authSelector);
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const format = { year: "numeric", month: "numeric", day: "numeric" };
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
    ...parseParams(location.search),
  });
  const queryString = buildQueryString(
    parseParams({
      DonViId: user.unitId,
      ThangNam: filterConditions.ThangNam,
      MaSoGCS: filterConditions.MaSoGCS,
    })
  );
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const clearSearch = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  const getDataList = useCallback(() => {
    authGetData({
      url: `${Endpoint.LIST_BKDS_KH_THAY_CS}?${buildQueryString(
        parseParams(filterConditions)
      )}`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          setDataSource(res.data);
          setTotalCount(res.paging.totalCount);
        }
      },
    });
  }, [filterConditions]);

  useEffect(() => {
    getDataList();
  }, [getDataList]);

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const genExtra = useCallback(
    () => (
      <div className="export">
        <div className="export">
          <Export
            excelEndpoint={`${Endpoint.EXCEL_BKDS_KH_THAY_CS}?${queryString}`}
            pdfEndpoint={`${Endpoint.PDF_BKDS_KH_THAY_CS}?${queryString}`}
            setLoading={setLoading}
          />
        </div>
      </div>
    ),
    [queryString]
  );

  const columns = [
    {
      title: "STT",
      dataIndex: "key",
      width: 70,
      align: "center",
      render: (a, b, index) => {
        return (
          (filterConditions.pageIndex - 1) * filterConditions.pageSize +
          (index + 1)
        );
      },
    },
    {
      title: "Đơn vị",
      dataIndex: "donVi",
      width: "300px",
    },
    {
      title: "Đội",
      width: "150px",
      dataIndex: "doi",
    },
    {
      title: "Mã sổ GCS",
      width: "100px",
      dataIndex: "maSoGCS",
    },
    {
      title: "Mã điểm đo",
      width: "150px",
      dataIndex: "maDiemDo",
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      width: "150px",
    },
    {
      title: "Mã công tơ",
      width: "200px",
      dataIndex: "maCongTo",
    },
    {
      title: "Kỳ",
      dataIndex: "ky",
      width: "70px",
      align: "center",
    },
    {
      title: "BCS",
      dataIndex: "bcs",
      align: "center",
    },
    {
      title: "CS trước khi sửa",
      dataIndex: "csTruocKhiSua",
      render: (text) => {
        return {
          children: <span>{text}</span>,
          props: {
            align: "end",
          },
        };
      },
    },
    {
      title: "CS sau khi sửa",
      dataIndex: "csSauKhiSua",
      render: (text) => {
        return {
          children: <span>{text}</span>,
          props: {
            width: "100px",
            align: "end",
          },
        };
      },
    },
    {
      title: "Thời gian ghi CS trước",
      width: "100px",
      dataIndex: "thoiGianGhiCSTruoc",
      key: "thoiGianGhiCSTruoc",
      render: (thoiGianGhiCSTruoc) => {
        const bcdate = new Date(thoiGianGhiCSTruoc);
        if (thoiGianGhiCSTruoc)
          return {
            children: (
              <span>{bcdate.toLocaleDateString(undefined, format)}</span>
            ),
            props: {
              align: "end",
            },
          };
      },
    },
    {
      title: "Thời gian ghi CS sau",
      dataIndex: "thoiGianGhiCSSau",
      width: "100px",
      key: "thoiGianGhiCSSau",
      render: (thoiGianGhiCSSau) => {
        const bcdate = new Date(thoiGianGhiCSSau);
        if (thoiGianGhiCSSau)
          return {
            children: (
              <span>{bcdate.toLocaleDateString(undefined, format)}</span>
            ),
            props: { align: "end" },
          };
      },
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      width: "200px",
    },
  ];
  return (
    <Fragment>
      <Container>
        <Filter>
          <FilterBKDsCacKHCapNhatThayDoi
            setFilterConditions={setFilterConditions}
            clearSearch={clearSearch}
            filterConditions={filterConditions}
          />
        </Filter>
        <TableComponent
          header={"Danh sách kết quả"}
          renderExtraAction={() => genExtra()}
          dataSource={dataSource}
          columns={columns}
          totalData={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
          scrollX={1900}
          scrollY={1900}
          rowKey="key"
        />
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
  .ant-collapse.ant-collapse-icon-position-left {
    margin-top: 20px;
  }
`;
