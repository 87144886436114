import { memo, useState } from "react";
import { Form, Button, Modal, Row, Col, TimePicker } from "antd";
import { useTranslation } from "react-i18next";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { handleSubmitForm } from "./services";
import { FORMAT_TIME_FULL } from "@utils/constants";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import moment from "moment";

function CauHinhHoSoModal({ detail, visible, closeModal, getListData }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const handleSubmitData = (values) => {
    const data = {
      ...values,
    };
    data.thoiGianBatDau = moment(values.thoiGianBatDau).format(
      FORMAT_TIME_FULL
    );
    data.thoiGianKetThuc = moment(values.thoiGianKetThuc).format(
      FORMAT_TIME_FULL
    );
    data.thoiGianNghiTruaBatDau = moment(values.thoiGianNghiTruaBatDau).format(
      FORMAT_TIME_FULL
    );
    data.thoiGianNghiTruaKetThuc = moment(
      values.thoiGianNghiTruaKetThuc
    ).format(FORMAT_TIME_FULL);

    handleSubmitForm(data, detail, setLoading, closeModal, getListData, form);
  };

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={
          detail.id
            ? "Cập nhật thời gian làm việc"
            : "Tạo mới thời gian làm việc"
        }
        visible={visible}
        onCancel={closeModal}
        className="modal-form"
        footer={null}
      >
        <FormComponent
          form={form}
          name="form-thoi-gian"
          layout="vertical"
          onFinish={handleSubmitData}
          initialValues={{
            ...detail,
            thoiGianBatDau: moment(detail.thoiGianBatDau, FORMAT_TIME_FULL),
            thoiGianKetThuc: moment(detail.thoiGianKetThuc, FORMAT_TIME_FULL),
            thoiGianNghiTruaBatDau: moment(
              detail.thoiGianNghiTruaBatDau,
              FORMAT_TIME_FULL
            ),
            thoiGianNghiTruaKetThuc: moment(
              detail.thoiGianNghiTruaKetThuc,
              FORMAT_TIME_FULL
            ),
          }}
          scrollToFirstError
          autoComplete="off"
        >
          <Form.Item
            label="Thời gian bắt đầu"
            name="thoiGianBatDau"
            className="required-field"
          >
            <TimePicker
              format={FORMAT_TIME_FULL}
              locale={{
                ...locale,
                lang: {
                  ...locale.lang,
                  ok: "Chọn",
                },
              }}
              allowClear={false}
            />
          </Form.Item>
          <Form.Item
            label="Thời gian kết thúc"
            name="thoiGianKetThuc"
            className="required-field"
          >
            <TimePicker
              format={FORMAT_TIME_FULL}
              locale={{
                ...locale,
                lang: {
                  ...locale.lang,
                  ok: "Chọn",
                },
              }}
              allowClear={false}
            />
          </Form.Item>
          <Form.Item
            label="Thời gian nghỉ trưa bắt đầu"
            name="thoiGianNghiTruaBatDau"
            className="required-field"
          >
            <TimePicker
              format={FORMAT_TIME_FULL}
              locale={{
                ...locale,
                lang: {
                  ...locale.lang,
                  ok: "Chọn",
                },
              }}
              allowClear={false}
            />
          </Form.Item>
          <Form.Item
            label="Thời gian nghỉ trưa kết thúc"
            name="thoiGianNghiTruaKetThuc"
            className="required-field"
          >
            <TimePicker
              format={FORMAT_TIME_FULL}
              locale={{
                ...locale,
                lang: {
                  ...locale.lang,
                  ok: "Chọn",
                },
              }}
              allowClear={false}
            />
          </Form.Item>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-thoi-gian"
                loading={loading}
              >
                {detail.id ? t("button.cap_nhat") : t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(CauHinhHoSoModal);
