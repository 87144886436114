import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";
// api báo cáo kiểm tra công tơ trong tháng

export const handleKTCTTrongThang = (setLoading, onComplete) => {
  authGetData({
    url: Endpoint.BC_KTCT_TRONGTHANG,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        onComplete(res.data);
      } else {
        onComplete({});
      }
    },
  });
};
// api báo cáo phúc tra ngoài hiện trường trong tháng

export const handlePTTrongThang = (setLoading, onComplete) => {
  authGetData({
    url: Endpoint.BC_PT_NGOAIHIENTRUONG,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        onComplete(res.data);
      } else {
        onComplete({});
      }
    },
  });
};
// api báo cáo phúc tra trong tháng

export const handlePhucTra = (setLoading, onComplete) => {
  authGetData({
    url: Endpoint.BC_PT_TRONGTHANG,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        onComplete(res.data);
      } else {
        onComplete({});
      }
    },
  });
};
//  BIểu kiểm tra công tơ, phúc tra ngoài hiện trường hàng ngày

export const handleKTTBHangNgay = (setLoading, setData, onComplete) => {
  function flatten(arr) {
    return arr.reduce(function (flat, toFlatten) {
      return flat.concat(
        Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
      );
    }, []);
  }
  authGetData({
    url: Endpoint.BC_KT_THIETBI_HANGNGAY,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        onComplete(res.data);
        const dataRaw = res.data.xValues.map((rs, index) => {
          return res.data.series.map((rsSerie) => {
            return {
              name: rsSerie.name,
              yValues: rsSerie.yValues[index],
              xValues: rs,
            };
          });
        });
        setData(flatten(dataRaw));
      } else {
        onComplete([]);
      }
    },
  });
};

export const handleListData = (setLoading, setDataSource) => {
  authGetData({
    url: Endpoint.BC_TRONGTHANG_THEO_NV,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataSource(res.data);
      }
    },
  });
};

export const handleGetCTKhongDat = (setLoading, setData, onComplete) => {
  authGetData({
    url: Endpoint.BC_CT_KHONGDAT,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        onComplete(res.data);
        const dataRaw = [];
        for (let i = 0; i < res.data.series.length; i++) {
          dataRaw.push({
            [`yValues`]: null,
            [`xValues`]: null,
          });
        }
        if (res.data.series) {
          if (res.data.series.length > 0 && res.data.xValues.length > 0) {
            dataRaw.forEach((item, i) => {
              item[`yValues`] = Number(res.data.series[i].yValues);
              item[`xValues`] = res.data.xValues[i];
            });
          }
        }
        setData(dataRaw);
      } else {
        onComplete({});
      }
    },
  });
};
export const handleGetNgayKiemTra = (setLoading, setData, onComplete) => {
  function flatten(arr) {
    return arr.reduce(function (flat, toFlatten) {
      return flat.concat(
        Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
      );
    }, []);
  }
  authGetData({
    url: Endpoint.BC_NGAY_KIEM_TRA,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        onComplete(res.data);
        const dataRaw = res.data.xValues.map((rs, index) => {
          return res.data.series.map((rsSerie) => {
            return {
              name: rsSerie.name,
              yValues: rsSerie.yValues[index],
              xValues: rs,
            };
          });
        });
        setData(flatten(dataRaw));
      } else {
        onComplete([]);
      }
    },
  });
};
