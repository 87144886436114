import React, { useCallback, useState } from "react";
import { Row, Col, Collapse, Tooltip, Grid } from "antd";

import { CaretRightOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import LoadingComponent from "@components/Loading";
import moment from "moment";
import { FORMAT_DATE } from "@utils/constants";
import { ExTraStepDetail } from "../css-styled";
import XemLog from "../modals";

const { Panel } = Collapse;
const { useBreakpoint } = Grid;

export default function ChiTietHoSoTabs(props) {
  const { detailHoSo, loading, dataTraLai, dataTroNgai } = props;
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [typeLog, setTypeLog] = useState(1);
  const screens = useBreakpoint();
  const mobile = screens.xs;
  const handleOpenModalLog = useCallback(
    (typeLog) => {
      setVisible(!visible);
      setTypeLog(typeLog);
    },
    [visible]
  );

  const showModalLog = useCallback(() => {
    return (
      visible && (
        <XemLog
          visible={visible}
          setVisible={setVisible}
          dataSource={typeLog === 1 ? dataTroNgai : dataTraLai}
          typeLog={typeLog}
        />
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  return (
    <LoadingComponent loading={loading}>
      {/* thông tin hồ sơ */}
      <Row gutter={24} style={{ marginTop: "12px" }}>
        <Col span={24} md={8}>
          <h6 className="fs-12px c-t-label">{t("duyet_ho_so.khach_hang")}</h6>
          <h5 className="fs-14px font-bold">{detailHoSo.tenKhachHang}</h5>
        </Col>
        <Col span={12} md={8}>
          <h6 className="fs-12px c-t-label">{t("duyet_ho_so.dai_dien")}</h6>
          <h5 className="fs-14px font-bold">{detailHoSo.tenNguoiDaiDien}</h5>
        </Col>
        <Col span={12} md={8}>
          <h6 className="fs-12px c-t-label">{t("duyet_ho_so.chuc_vu")}</h6>
          <h5 className="fs-14px font-bold">{detailHoSo.chucVuNguoiDaiDien}</h5>
        </Col>

        <Col span={12} md={8}>
          <h6 className="fs-12px c-t-label">{"Mã yêu cầu"}</h6>
          <h5 className="fs-14px font-bold">{detailHoSo.maYCKNCmis}</h5>
        </Col>
        <Col span={12} md={8}>
          <h6 className="fs-12px c-t-label">
            {t("duyet_ho_so.muc_dich_su_dung")}
          </h6>
          <h5 className="fs-14px font-bold">
            {detailHoSo.mucDichSuDung ?? ""}
          </h5>
        </Col>
        <Col span={12} md={8}>
          <h6 className="fs-12px c-t-label">{t("duyet_ho_so.trang_thai")}</h6>
          <h5
            className="fs-14px font-bold"
            style={{ color: `${detailHoSo.mau}` }}
          >
            {detailHoSo.tenTrangThai}
          </h5>
        </Col>

        <Col span={12} md={8}>
          <h6 className="fs-12px c-t-label">
            {t("duyet_ho_so.dien_thoai_di_dong")}
          </h6>
          <h5 className="fs-14px font-bold">{detailHoSo.dienThoai}</h5>
        </Col>
        <Col span={12} md={8}>
          <h6 className="fs-12px c-t-label">{t("duyet_ho_so.email")}</h6>
          <h5 className="fs-14px font-bold">{detailHoSo.email}</h5>
        </Col>
        <Col span={12} md={8}>
          <h6 className="fs-12px c-t-label">
            {t("duyet_ho_so.tai_khoan_ngan_hang")}
          </h6>
          <h5 className="fs-14px font-bold">{detailHoSo.nganHang}</h5>
        </Col>

        <Col span={12} md={8}>
          <h6 className="fs-12px c-t-label">
            {t("duyet_ho_so.chung_minh_thu")}
          </h6>
          <h5 className="fs-14px font-bold">{detailHoSo.cmnd}</h5>
        </Col>
        <Col span={12} md={8}>
          <h6 className="fs-12px c-t-label">{t("duyet_ho_so.ngay_cap")}</h6>
          <h5 className="fs-14px font-bold">
            {detailHoSo.ngayCap
              ? moment(detailHoSo.ngayCap).format(FORMAT_DATE)
              : ""}
          </h5>
        </Col>
        <Col span={12} md={8}>
          <h6 className="fs-12px c-t-label">{t("duyet_ho_so.noi_cap")}</h6>
          <h5 className="fs-14px font-bold">{detailHoSo.noiCap}</h5>
        </Col>

        <Col span={24} md={8}>
          <h6 className="fs-12px c-t-label">
            {t("duyet_ho_so.dia_chi_su_dung_dien")}
          </h6>
          <h5 className="fs-14px font-bold">{detailHoSo.diaChiCapDien}</h5>
        </Col>
        <Col span={24} md={8}>
          <h6 className="fs-12px c-t-label">
            {t("duyet_ho_so.dia_chi_dai_dien")}
          </h6>
          <h5 className="fs-14px font-bold">{detailHoSo.diaChiGiaoDich}</h5>
        </Col>
        <Col span={12} md={8}>
          <h6 className="fs-12px c-t-label">Đội</h6>
          <h5 className="fs-14px font-bold">{detailHoSo?.thongTinDoi}</h5>
        </Col>
        <Col span={12} md={8}>
          <h6 className="fs-12px c-t-label">
            <span style={{ marginRight: "8px" }}>
              {t("duyet_ho_so.ten_tro_ngai")}
            </span>
            {dataTroNgai && dataTroNgai.length > 0 && (
              <Tooltip title="Xem log trở ngại">
                <InfoCircleOutlined
                  style={{ color: "#FF5247", cursor: "pointer" }}
                  onClick={() => handleOpenModalLog(1)}
                />
              </Tooltip>
            )}
          </h6>
          <h5 className="fs-14px font-bold">
            {detailHoSo.tenTroNgai
              ? `${detailHoSo.tenTroNgai}  
                ${
                  detailHoSo.tinhChatKhacPhuc
                    ? `- Tính chất khắc phục: ${detailHoSo.tinhChatKhacPhuc}`
                    : ""
                }`
              : null}
          </h5>
        </Col>
        <Col span={12} md={8}>
          <h6 className="fs-12px c-t-label">
            {t("duyet_ho_so.ghi_chu_tro_ngai")}
          </h6>
          <h5 className="fs-14px font-bold">{detailHoSo.ghiChuTroNgai}</h5>
        </Col>
        <Col span={12} md={8}>
          <h6 className="fs-12px c-t-label">
            {t("duyet_ho_so.ma_dich_vu_cong")}
          </h6>
          <h5 className="fs-14px font-bold">{detailHoSo.techId}</h5>
        </Col>
        <Col span={12}>
          <h6 className="fs-12px c-t-label">
            <span style={{ marginRight: "8px" }}>
              {t("duyet_ho_so.ly_do_tra_lai_ho_so")}
            </span>
            {dataTraLai && dataTraLai.length > 0 && (
              <Tooltip title="Xem log trả lại">
                <InfoCircleOutlined
                  style={{ color: "#FF5247", cursor: "pointer" }}
                  onClick={() => handleOpenModalLog(2)}
                />
              </Tooltip>
            )}
          </h6>
          <h5 className="fs-14px font-bold" style={{ color: "#FF5247" }}>
            {detailHoSo.noiDungTraHoSo}
          </h5>
        </Col>
      </Row>
      <Collapse
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            style={{ fontSize: 18 }}
            rotate={isActive ? 90 : 0}
          />
        )}
      >
        {/* <Panel
              header={
                <h3 className="font-bold">
                  {t("common_cap_dien.thong_tin_ho_so")}
                </h3>
              }
              collapsible={"header"}
              key="1"
            > */}
        {/* </Panel> */}

        {/* chi tiết các bước thực hiện */}

        <Panel
          header={
            <h3 className="font-bold" style={{ position: "relative" }}>
              {t("common_cap_dien.cac_buoc_thuc_hien")}
            </h3>
          }
          collapsible={"header"}
          extra={
            <ExTraStepDetail
              style={{
                transform: mobile
                  ? "translate(-50%, 0%)"
                  : "translate(-50%, -50%)",
              }}
            >
              <h3>
                {t("common_cap_dien.tong_so_ngay_thuc_hien")}
                <span style={{ color: "#279BEF" }}>
                  {detailHoSo.snlvandtsn}
                </span>
              </h3>
            </ExTraStepDetail>
          }
          key="2"
        >
          <Row gutter={24}>
            <Col span={12} md={8}>
              <h6 className="fs-12px c-t-label">
                {t("duyet_ho_so.ngay_dang_ky")}
              </h6>
              <h5 className="fs-14px font-bold">{detailHoSo.ngayDangKy}</h5>
            </Col>
            <Col span={12} md={8}>
              <h6 className="fs-12px c-t-label">
                {t("duyet_ho_so.ngay_giao_thiet_ke")}
              </h6>
              <h5 className="fs-14px font-bold">
                {detailHoSo.ngayGiaoThietKe}
              </h5>
            </Col>
            <Col span={12} md={8}>
              <h6 className="fs-12px c-t-label">
                {t("duyet_ho_so.ngay_thong_bao_thanh_toan")}
              </h6>
              <h5 className="fs-14px font-bold">
                {detailHoSo.ngayThongBaoThanhToan}
              </h5>
            </Col>

            <Col span={12} md={8}>
              <h6 className="fs-12px c-t-label">
                {t("duyet_ho_so.ngay_hoan_thanh_phuong_an")}
              </h6>
              <h5 className="fs-14px font-bold">
                {detailHoSo.ngayHoanThanhPhuongAn}
              </h5>
            </Col>
            <Col span={12} md={8}>
              <h6 className="fs-12px c-t-label">
                {t("duyet_ho_so.ngay_duyet_phuong_an")}
              </h6>
              <h5 className="fs-14px font-bold">
                {detailHoSo.ngayDuyetPhuongAn}
              </h5>
            </Col>
            <Col span={12} md={8}>
              <h6 className="fs-12px c-t-label">
                {t("duyet_ho_so.ngay_chuyen_kinh_doanh")}
              </h6>
              <h5 className="fs-14px font-bold">
                {detailHoSo.ngayChuyenKinhDoanh}
              </h5>
            </Col>

            <Col span={12} md={8}>
              <h6 className="fs-12px c-t-label">
                {t("duyet_ho_so.ngay_chuyen_tai_chinh")}
              </h6>
              <h5 className="fs-14px font-bold">
                {detailHoSo.ngayChuyenTaiChinh}
              </h5>
            </Col>
            <Col span={12} md={8}>
              <h6 className="fs-12px c-t-label">
                {t("duyet_ho_so.ngay_nop_tien")}
              </h6>
              <h5 className="fs-14px font-bold">{detailHoSo.ngayNopTien}</h5>
            </Col>
            <Col span={12} md={8}>
              <h6 className="fs-12px c-t-label">
                {t("duyet_ho_so.ngay_thi_cong_lap_dat")}
              </h6>
              <h5 className="fs-14px font-bold">
                {detailHoSo.ngayThiCongLapDat}
              </h5>
            </Col>

            <Col span={12} md={8}>
              <h6 className="fs-12px c-t-label">
                {t("duyet_ho_so.ngay_dong_dien")}
              </h6>
              <h5 className="fs-14px font-bold">{detailHoSo.ngayDongDien}</h5>
            </Col>
            <Col span={12} md={8}>
              <h6 className="fs-12px c-t-label">
                {t("duyet_ho_so.ngay_ket_thuc")}
              </h6>
              <h5 className="fs-14px font-bold">{detailHoSo.ngayKetThuc}</h5>
            </Col>
            <Col span={12} md={8}>
              <h6 className="fs-12px c-t-label">
                {t("duyet_ho_so.ngay_khao_sat_tro_ngai")}
              </h6>
              <h5 className="fs-14px font-bold">
                {detailHoSo.ngayKhaoSatTroNgai}
              </h5>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      {showModalLog()}
    </LoadingComponent>
  );
}
