import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { METHOD_PUT } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";
import { Button, Col, Form, Input, notification, Row, Spin } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
export default function NoiDungCuPhap() {
  const [loading, setLoading] = useState(false);
  const [valueDoiTuong, setValueDoiTuong] = useState("");
  const [data, setData] = useState();
  const [form] = Form.useForm();

  const [item, setItem] = useState();
  useEffect(() => {
    authGetData({
      url: `${Endpoint.CSKH_DOITUONG}`,
      setLoading,
      onSuccess: (res) => {
        if (res) {
          setData(res.data);
        }
      },
    });
  }, []);

  useEffect(() => {
    if (valueDoiTuong) {
      data.map((item) => {
        if (valueDoiTuong === item.id) {
          setItem(item);
        }
      });
    }
    if (item) {
      form.setFieldsValue({
        [`cuPhapSMS`]: item?.cuPhapSMS,
        [`cuPhapZalo`]: item?.cuPhapZalo,
        [`cuPhapEmail`]: item?.cuPhapEmail,
      });
    }
  }, [valueDoiTuong, data, item, form]);

  const handleChange = (e) => {
    setValueDoiTuong(e);
  };

  const onFinish = (values) => {
    authPostData({
      url: `${Endpoint.CSKH_SUA_CU_PHAP}`,
      method: METHOD_PUT,
      setLoading,
      payload: values,
      onSuccess: (res) => {
        if (res.statusCode === 422) {
          notification.error({
            message: `${res.data.id}`,
          });
        }
      },
    });
  };

  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              colon={false}
            >
              <Row>
                <Col span={24}>
                  <Form.Item name="id" label="Đối tượng" labelAlign="left">
                    <Selection
                      url={Endpoint.CSKH_DOITUONG_DROPDOWN}
                      setValue={handleChange}
                      formKey="id"
                      placeholder="Chọn đối tượng"
                      form={form}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="cuPhapSMS" label="SMS" labelAlign="left">
                    <Input.TextArea
                      type="textarea"
                      formKey="cuPhapSMS"
                      style={{ height: "50px" }}
                      rows={2}
                    ></Input.TextArea>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="cuPhapEmail" label="Email" labelAlign="left">
                    <Input.TextArea
                      style={{ height: "300px" }}
                      rows={2}
                      formKey="cuPhapEmail"
                    ></Input.TextArea>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="cuPhapZalo" label="Zalo" labelAlign="left">
                    <Input.TextArea
                      style={{ height: "300px" }}
                      type="textarea"
                      formKey="cuPhapZalo"
                      rows={2}
                    ></Input.TextArea>
                  </Form.Item>
                </Col>
              </Row>
              <ButtonSave>
                <Col span={6}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="full">
                      Lưu
                    </Button>
                  </Form.Item>
                </Col>
              </ButtonSave>
            </FormComponent>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;

const ButtonSave = styled.div`
  padding-left: 45%;
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
