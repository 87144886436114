import { authGetData, authPutData, downloadFile } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { alertMessage, buildQueryString, parseParams } from "@utils/function";
import { STATUSCODE_200 } from "@utils/constants";

export const getDoiTruongPhanCongDoi = (setLoading, setData) => {
  authGetData({
    url: `${Endpoint.CRM_DOI_TRUONG_PHAN_CONG_DOI}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getPhanCongNguoiKhaoSat = (setLoading, setData, id) => {
  authGetData({
    url: `${Endpoint.CRM_DOI_TRUONG_PHAN_CONG_NGUOI_KHAO_SAT}?DoiId=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getPDFBienBanKH = (detail, onComplete, setLoading, t) => {
  authGetData({
    url: `${Endpoint.IN_BIEN_BAN_QUYET_TOAN_KH}?HoSoId=${detail?.hoSoId ?? detail?.id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        onComplete(res.data);
      } else {
        onComplete("error");
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};
export const getExcelBienBanKH = (detail, onComplete, setLoading, t) => {
  authGetData({
    url: `${Endpoint.IN_BIEN_BAN_QUYET_TOAN_KH_EXCEL}?HoSoId=${detail?.hoSoId ?? detail?.id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        onComplete(res.data);
      } else {
        onComplete(0);
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const downLoadBienBan = (detail, setLoading) => {
  downloadFile({
    endpoint: `${Endpoint.IN_BIEN_BAN_QUYET_TOAN_KH_EXCEL}?HoSoId=${detail?.hoSoId ?? detail?.id}`,
    setLoading,
  });
};

export const getDanhSachDuyetYeuCau = (setListLoading, setData, query, filterConditions, setTotal) => {
  authGetData({
    url: `${Endpoint.CRM_DOI_TRUONG_DANH_SACH_DUYET_YEU_CAU}?${query}`,
    setLoading: setListLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              STT: (filterConditions.pageIndex - 1) * filterConditions.pageSize + (index + 1),
            };
          })
        );
        setTotal(res.paging.totalCount);
      }
    },
  });
};

export const putTraYeuCau = (
  payload,
  setLoading,
  setListLoading,
  location,
  filterConditions,
  setData,
  setTotal,
  setVisibleTra,
  setSelectedRowKeys
) => {
  authPutData({
    url: `${Endpoint.CRM_DOI_TRUONG_TRA_YEU_CAU}`,
    method: "PUT",
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        const query =
          Object.keys(parseParams(location.search)) > 0
            ? buildQueryString(parseParams(location.search))
            : buildQueryString(filterConditions);
        getDanhSachDuyetYeuCau(setListLoading, setData, query, filterConditions, setTotal);
        setVisibleTra(false);
        setSelectedRowKeys([]);
      }
    },
  });
};

export const putDuyetYeuCau = (
  payload,
  setLoading,
  setListLoading,
  location,
  filterConditions,
  setData,
  setTotal,
  setVisibleDuyet,
  setSelectedRowKeys
) => {
  authPutData({
    url: `${Endpoint.CRM_DOI_TRUONG_XAC_NHAN}`,
    method: "PUT",
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        const query =
          Object.keys(parseParams(location.search)).length > 0
            ? buildQueryString(parseParams(location.search))
            : buildQueryString(filterConditions);
        getDanhSachDuyetYeuCau(setListLoading, setData, query, filterConditions, setTotal);
        setVisibleDuyet(false);
        setSelectedRowKeys([]);
      }
    },
  });
};

export const putKyHSM = ({ payload, setLoadingKyHSM, onSuccess }) => {
  authPutData({
    url: `${Endpoint.CRM_DOI_TRUONG_KY_HSM}`,
    method: "PUT",
    payload,
    setLoading: setLoadingKyHSM,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        onSuccess();
      }
    },
  });
};
