import { Fragment } from "react";
import TableComponent from "@components/TableComponent";
import Filter from "@components/Filter/Filter";

import { customColumn } from "@utils/function";
import { ListFilter } from "../list-bo-loc";
import { Container } from "./css-styled";

export default function Index(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    genExtra,
    dataSource,
    columns,
    totalCount,
    loading,
    onChangePagination,
    skip,
  } = props;

  return (
    <Fragment>
      {skip ? null : (
        <Filter>
          <ListFilter
            tooltip={"Tìm kiếm theo mã hình thức, tên hình thức thi công"}
            handleSearch={handleSearch}
            clearFilter={clearFilter}
            filterConditions={filterConditions}
          />
        </Filter>
      )}
      <Container>
        <TableComponent
          header={"Danh mục hình thức thi công"}
          renderExtraAction={() => genExtra()}
          dataSource={dataSource}
          columns={customColumn(columns, filterConditions)}
          totalData={totalCount}
          loading={loading}
          onChangePagination={skip === "false" ? false : onChangePagination}
          pagination={skip === "false" ? false : filterConditions}
          scrollY={530}
        />
      </Container>
    </Fragment>
  );
}
