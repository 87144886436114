import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString } from "@utils/function";
import { Button, Col, Form, Row, Select } from "antd";
import { values } from "lodash";
import moment from "moment";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { ButtonFilter } from "./css-styled";
export function FillerTheoToaDo(props) {
  const { clearFilter, loading, handleSearch } = props;
  const [form] = Form.useForm();
  const { user } = useSelector(authSelector);

  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  const handleChangeUnit = useCallback(() => {
    form.setFieldsValue({});
  }, [form]);

  return (
    <FormComponent
      form={form}
      initialValues={{
        DonViId: user.unitId,
      }}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
    >
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item name="DonViId" label="Đơn vị">
            <Selection
              url={Endpoint.GET_UNIT_LIST}
              form={form}
              formKey="DonViId"
              setValue={handleChangeUnit}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.DonViId !== currentValues.DonViId
            }
          >
            {({ getFieldValue }) => {
              if (getFieldValue("DonViId")) {
                return (
                  <Form.Item name="ToDoiId" label="Tổ/Đội">
                    <Selection
                      url={`${
                        Endpoint.AUTH_LIST_TO_DOI
                      }?DonViId=${getFieldValue("DonViId")}`}
                      formKey="ToDoiId"
                      form={form}
                      disabled={!getFieldValue("DonViId")}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="ToDoiId" label="Tổ/Đội">
                  <Select disabled />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="ThangNam" label="Tháng" labelAlign="right">
            <DatePickerComponent
              defaultValue={moment()}
              picker="month"
              formKey="ThangNam"
              format={"YYYY-MM"}
              form={form}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={16} md={16}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.DonViId !== currentValues.DonViId ||
              prevValues.ToDoiId !== currentValues.ToDoiId ||
              prevValues.ThangNam !== currentValues.ThangNam
            }
          >
            {({ getFieldValue }) => {
              return (
                <Form.Item name="MaSo" label="Mã sổ">
                  <Selection
                    url={`${Endpoint.LIST_MA_SO_BANG_KE_NO_TO_DOI}?${buildQueryString(
                      {
                        DonViId: getFieldValue("DonViId"),
                        ThangNam: getFieldValue("ThangNam"),
                        ToDoiId: getFieldValue("ToDoiId"),
                      }
                    )}`}
                    formKey="MaSo"
                    form={form}
                    loading
                    mode={"multiple"}
                    values={values}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <ButtonFilter>
          <Col span={3} md={2}>
            <Form.Item>
              <Button className="mr-24px" onClick={clear}>
                Bỏ lọc
              </Button>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Tìm kiếm
            </Button>
          </Col>
        </ButtonFilter>
      </Row>
    </FormComponent>
  );
}
