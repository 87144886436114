import React, { Fragment, useCallback, useEffect, useState } from "react";
import { authGetData, authPostData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { Row, Col, Form, notification } from "antd";
import ButtonComponent from "@components/Button";
import Selection from "@components/Select";
import { INDEX_TAB2 } from "@utils/constants";

import {
  quanTriNguoiDungSelector,
  getPermissionDataAssignedForUser,
} from "@state/quan-tri-he-thong/quan-ly-nguoi-dung/reducer";
import { useSelector, useDispatch } from "react-redux";
import FormComponent from "@components/Form";
import { STATUSCODE_200 } from "@utils/constants";
export default function FunctionDecentralizationForm({ user, activeKey }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { permissionAssignedUserList } = useSelector(quanTriNguoiDungSelector);
  const [loading, setLoading] = useState(false);

  const getRoleListByModule = useCallback(() => {
    if (user && user.id && activeKey === INDEX_TAB2) {
      authGetData({
        url: `${Endpoint.USER}/get-role-by-user-dropdown?userId=${user.id}`,
        setLoading,
        onSuccess: (res) => {
          if (res.data && res.data.length) {
            form.setFieldsValue({
              roleId: res.data.map((item) => item.value),
            });
          }
        },
      });
    } else {
    }
  }, [user, activeKey, form]);
  useEffect(() => {
    getRoleListByModule();
  }, [getRoleListByModule]);

  const onFinish = useCallback(
    (values) => {
      authPostData({
        url: `${Endpoint.USER}/add-user-roles`,
        method: "POST",
        payload: {
          userId: user.id,
          ...values,
        },
        setLoading,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            if (permissionAssignedUserList.userId) {
              dispatch(
                getPermissionDataAssignedForUser({
                  filterConditions: permissionAssignedUserList.filterConditions,
                  userId: permissionAssignedUserList.userId,
                })
              );
            }
          } else {
            const entityError = Object.keys(res.data);
            if (entityError.length) {
              entityError.map((err) => {
                return notification.error({
                  message: "Thông báo!",
                  description: res.data[err],
                });
              });
            }
          }
        },
      });
    },
    [user, dispatch, permissionAssignedUserList]
  );

  return (
    <Fragment>
      <FormComponent onFinish={onFinish} form={form} autoComplete="off">
        <Row className="mb-15px" gutter={6}>
          <Col span={21}>
            <Form.Item name="roleId" label="Chọn quyền">
              <Selection
                url={Endpoint.GET_ROLES}
                mode="multiple"
                form={form}
                formKey="roleId"
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <ButtonComponent
              type="primary"
              className="full"
              disabled={loading}
              htmlType="submit"
            >
              Lưu
            </ButtonComponent>
          </Col>
        </Row>
      </FormComponent>
    </Fragment>
  );
}
