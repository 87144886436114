import { ENUM_SOSANH_KTCT } from "@utils/constants";
import { downloadFile } from "@utils/request";
import { Form } from "antd";
import { useCallback, useEffect, useState } from "react";
import FormBoLocXuatKho from "./form-bo-loc";

export default function ListFilterDinhKy(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    tooltip,
    user,
    typeList,
    checkTraLai,
    biTraLai,
    trangThai,
    soPha,
    firstTrangThai,
    onChangeTrangThai,
    urlXuatExcel,
    permissionExcel,
    filterConditionsTraCuu,
    checkLocTheoThang,
    locTheoThang
  } = props;
  const [form] = Form.useForm();
  const [disableChenhLech, setDisableChenhaLech] = useState(true);
  const [loading, setLoading] = useState(false);

  // hàm clear bộ lọc

  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);
  // set data vào form

  useEffect(() => {
    form.setFieldsValue({
      trangThaiId: firstTrangThai,
      // loaiSoSanh: ENUM_SOSANH_KTCT.LONHON_OR_BANG,
      // chenhLech: "0",
    });
  }, [firstTrangThai, form]);
  // hiển thị điều kiện tìm kiếm khi copy pass link web vào trình duyệt mới

  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);

  useEffect(() => {
    let object = {};
    if (
      !!filterConditions.tramIds &&
      !Array.isArray(filterConditions.tramIds)
    ) {
      object = {
        ...filterConditions,
        tramIds: filterConditions.tramIds.split(","),
      };
    } else {
      if (Array.isArray(filterConditions.tramIds)) {
        object = {
          ...filterConditions,
          tramIds: filterConditions.tramIds,
        };
      } else {
        object = {
          ...filterConditions,
        };
      }
    }
    form.setFieldsValue(object);
  }, [form, filterConditions]);

  // set disable

  const onChangeChenhLech = (value) => {
    if (value === undefined) {
      setDisableChenhaLech(true);
      form.setFieldsValue({
        loaiSoSanh: null,
        chenhLech: null,
      });
    } else {
      setDisableChenhaLech(false);
      form.setFieldsValue({
        loaiSoSanh: ENUM_SOSANH_KTCT.LONHON_OR_BANG,
        chenhLech: "0",
      });
    }
  };

  //call api xuất excel
  const handleExportExcel = useCallback((endpoint) => {
    if (endpoint !== undefined)
      downloadFile({
        endpoint,
        setLoading,
      });
  }, []);
  const cleanKeyNullOfObject = (obj) => {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  };

  return (
    <FormBoLocXuatKho
      user={user}
      form={form}
      loading={loading}
      handleSearch={handleSearch}
      tooltip={tooltip}
      clear={clear}
      filterConditions={filterConditions}
      trangThai={trangThai}
      typeList={typeList}
      checkTraLai={checkTraLai}
      biTraLai={biTraLai}
      soPha={soPha}
      firstTrangThai={firstTrangThai}
      onChangeTrangThai={onChangeTrangThai}
      disableChenhLech={disableChenhLech}
      onChangeChenhLech={onChangeChenhLech}
      handleExportExcel={handleExportExcel}
      urlXuatExcel={urlXuatExcel}
      permissionExcel={permissionExcel}
      filterConditionsTraCuu={cleanKeyNullOfObject(filterConditionsTraCuu)}
      checkLocTheoThang={checkLocTheoThang}
      locTheoThang={locTheoThang}
    />
  );
}
