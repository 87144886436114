import { createSlice } from "@reduxjs/toolkit";
export const namespace = "xacNhanThiCong";

const INITAL_STATE = {
  thongTin: false,
  congTo: false,
  thiCong: false,
  canShowDialogLeavingPage : false,
};

const slice = createSlice({
  name: namespace,
  initialState: INITAL_STATE,
  reducers: {
    notSavedThongTinThiCong: (state, action) => {
      return {
        ...state,
        thongTin: action.payload,
      };
    },
    notSavedCongTo: (state, action) => {
      return {
        ...state,
        congTo: action.payload,
      };
    },
    notSavedThiCong: (state, action) => {
      return {
        ...state,
        thiCong: action.payload,
      };
    },
    setCanShowDialogLeavingTC: (state, action) => {
      return {
        ...state,
        canShowDialogLeavingPage: action.payload,
      };
    },
  },
});

export const reducer = slice.reducer;

export const {
  notSavedThongTinThiCong,
  notSavedCongTo,
  notSavedThiCong,
  setCanShowDialogLeavingTC,
} = slice.actions;

export const xacNhanThiCongSelector = (state) => state[namespace];
