import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Divider, Form, InputNumber, Row, Select, Spin } from "antd";
import { Option } from "antd/lib/mentions";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { handleDownload, selectBcslct } from "./service";
export default function BaoCaoTHSLCongTo() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);

  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Báo cáo tổng hợp số lượng công tơ
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              colon={false}
              initialValues={{
                reportType: Endpoint.BCCT_TH_SOLUONG_CONGTO_TVQ_60NGAY_EXCEL,
                SoNgay: 60,
                unitId: user.user.unitCode,
                LoaiCongTo: "ALL",
                SoPha: "0",
                TinhTrang: "0",
                SoThang: 6,
              }}
            >
              {/* Tên báo cáo */}
              <Form.Item name="reportType" label="Báo cáo" labelAlign="left" required>
                <Select
                  placeholder="Tất cả"
                  options={selectBcslct}
                  onChange={() => form.setFieldsValue({ LoaiCongTo: "ALL" })}
                ></Select>
              </Form.Item>
              {/* Đơn vị */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") !== Endpoint.BCCT_BCTH_CONG_TO_CAP_TREN_MOI &&
                  getFieldValue("reportType") !== Endpoint.BCCT_TH_CONG_TAC_KD_CONG_TO_TU_TI_MOI &&
                  getFieldValue("reportType") !== Endpoint.BCCT_TH_CONG_TO_DEN_HAN_THAY_DINH_KY_MOI &&
                  getFieldValue("reportType") !== Endpoint.BCCT_THCT_PHAN_BO_MOI &&
                  getFieldValue("reportType") !== Endpoint.BCCT_TH_THIET_BI_MOI ? (
                    <Form.Item name="unitId" label="Đơn vị" labelAlign="left" required>
                      <Selection url={Endpoint.GET_TEAM_LIST_CODE} formKey="unitId" form={form} />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              {/* Từ Ngày */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") !== Endpoint.BCCT_THSL_CONG_TO_KHONG_DUNG_6_THANG_TRO_LEN &&
                  getFieldValue("reportType") !== Endpoint.BCCT_TH_THIET_BI_MOI ? (
                    <Form.Item name="TuNgay" label="Từ ngày" labelAlign="left" required>
                      <DatePickerComponent formKey="TuNgay" form={form} picker="day" format={"YYYY-MM-DD"} />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              {/* Đến Ngày */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") !== Endpoint.BCCT_CT_CONG_TO_K_DUNG_6_THANG_TRO_LEN &&
                  getFieldValue("reportType") !== Endpoint.BCCT_CT_CONG_TO_6_THANG_KHONG_LEN_CHI_SO_MOI ? (
                    <Form.Item name="DenNgay" label="Đến ngày" labelAlign="left" required>
                      <DatePickerComponent picker="day" formKey="DenNgay" form={form} format={"YYYY-MM-DD"} />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              {/* Loại công tơ */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") === Endpoint.BCCT_CHI_TIET_CONG_TO_60NGAY_EXCEL ||
                  getFieldValue("reportType") === Endpoint.BCCT_CHI_TIET_CONG_TO_GUITRA_KHO_EXCEL ||
                  getFieldValue("reportType") === Endpoint.BCCT_CHI_TIET_CONG_TO_TREN_NHAN_KIEM_DINH_EXCEL ||
                  getFieldValue("reportType") === Endpoint.BCCT_CT_CONG_TO_TREN_CAP_TREN_BO ||
                  getFieldValue("reportType") === Endpoint.BCCT_CT_CONG_TO_K_DUNG_6_THANG_TRO_LEN ||
                  getFieldValue("reportType") === Endpoint.BCCT_CT_CONG_TO_CAP_TREN_THANH_LY ||
                  getFieldValue("reportType") === Endpoint.BCCT_CT_NHAP_KHO_CONG_TO ||
                  getFieldValue("reportType") === Endpoint.BCCT_CT_CONG_TO_6_THANG_KHONG_LEN_CHI_SO_MOI ||
                  getFieldValue("reportType") === Endpoint.BCCT_CT_CONG_TO_PHAN_BO_MOI ||
                  getFieldValue("reportType") === Endpoint.CT_KIEM_DINH_CONG_TO_MOI ||
                  getFieldValue("reportType") === Endpoint.BCCT_CT_CONG_TO_GUI_KIEM_DINH_MOI ||
                  getFieldValue("reportType") === Endpoint.BCCT_CT_NHAP_KHO_SAU_KD_CONG_TO_MOI ? (
                    <Form.Item name="LoaiCongTo" label="Loại công tơ" labelAlign="left" required>
                      <Select placeholder="Tất cả" formKey="LoaiCongTo">
                        <Option value="ALL">Tất cả</Option>
                        <Option value="HC">Hữu công</Option>
                        <Option value="VC">Vô công</Option>
                        <Option value="DT">Điện tử</Option>
                      </Select>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              {/* Loại công tơ */}

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") === Endpoint.BCCT_TH_CONG_TO_DEN_HAN_THAY_DINH_KY_MOI ||
                  getFieldValue("reportType") === Endpoint.BCCT_THCT_PHAN_BO_MOI ? (
                    <Form.Item name="LoaiCongTo" label="Loại công tơ" labelAlign="left" required>
                      <Select placeholder="Chọn loại công tơ" formKey="LoaiCongTo">
                        <Option value="ALL">Tất cả</Option>
                        <Option value="KH">Khách hàng</Option>
                        <Option value="DN">Đầu nguồn</Option>
                      </Select>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              {/* Số pha */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") === Endpoint.BCCT_CHI_TIET_CONG_TO_60NGAY_EXCEL ||
                  getFieldValue("reportType") === Endpoint.BCCT_CHI_TIET_CONG_TO_GUITRA_KHO_EXCEL ||
                  getFieldValue("reportType") === Endpoint.BCCT_CHI_TIET_CONG_TO_TREN_NHAN_KIEM_DINH_EXCEL ||
                  getFieldValue("reportType") === Endpoint.BCCT_CT_CONG_TO_TREN_CAP_TREN_BO ||
                  getFieldValue("reportType") === Endpoint.BCCT_CT_CONG_TO_K_DUNG_6_THANG_TRO_LEN ||
                  getFieldValue("reportType") === Endpoint.BCCT_CT_CONG_TO_CAP_TREN_THANH_LY ||
                  getFieldValue("reportType") === Endpoint.BCCT_CT_NHAP_KHO_CONG_TO ||
                  getFieldValue("reportType") === Endpoint.BCCT_CT_CONG_TO_6_THANG_KHONG_LEN_CHI_SO_MOI ||
                  getFieldValue("reportType") === Endpoint.BCCT_CT_CONG_TO_GUI_KIEM_DINH_MOI ||
                  getFieldValue("reportType") === Endpoint.BCCT_CT_CONG_TO_PHAN_BO_MOI ||
                  getFieldValue("reportType") === Endpoint.CT_KIEM_DINH_CONG_TO_MOI ||
                  getFieldValue("reportType") === Endpoint.BCCT_CT_NHAP_KHO_SAU_KD_CONG_TO_MOI ||
                  getFieldValue("reportType") === Endpoint.BCCT_TH_CONG_TAC_KD_CONG_TO_TU_TI_MOI ? (
                    <Form.Item name="SoPha" label="Số pha" labelAlign="left" required>
                      <Select placeholder="Chọn số pha" formKey="SoPha">
                        <Option value="0">Tất cả</Option>
                        <Option value="1">1 pha</Option>
                        <Option value="3">3 pha</Option>
                      </Select>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") === Endpoint.BCCT_TH_SOLUONG_CONGTO_TVQ_60NGAY_EXCEL ||
                  getFieldValue("reportType") === Endpoint.BCCT_CHI_TIET_CONG_TO_60NGAY_EXCEL ? (
                    <Form.Item name="SoNgay" label="Số ngày" labelAlign="left" required>
                      <InputNumber formKey="SoNgay" />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              {/* Tình Trạng */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") === Endpoint.BCCT_THSL_CONG_TO_TREN_CAP_TREN_BO_EXCEL ||
                  getFieldValue("reportType") === Endpoint.BCCT_CT_CONG_TO_TREN_CAP_TREN_BO ? (
                    <Form.Item name="TinhTrang" label="Tình trạng" labelAlign="left" required>
                      <Select placeholder="Chọn tình trạng" formKey="TinhTrang">
                        <Option value="0">Tất cả</Option>
                        <Option value="1">Kiểm định mới</Option>
                        <Option value="2">kiểm định quay vòng</Option>
                      </Select>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              {/* Số Tháng */}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") === Endpoint.BCCT_CT_CONG_TO_6_THANG_KHONG_LEN_CHI_SO_MOI ||
                  getFieldValue("reportType") === Endpoint.BCCT_THSL_CONG_TO_KHONG_DUNG_6_THANG_TRO_LEN ? (
                    <Form.Item name="SoThang" label="Số tháng" labelAlign="left" required>
                      <InputNumber placeholder="Chọn số tháng" />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              <Row>
                <Col span={4}></Col>
                <Col span={15}>
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>
                  {/* <Button
                    type="primary"
                    loading={loading}
                    className="ml-20px"

                    onClick={() => {
                      handleOpenPdf(form.getFieldValue(), setLoading);
                    }}
                  >
                    In báo cáo
                  </Button> */}
                </Col>
              </Row>
            </FormComponent>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
