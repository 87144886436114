import { Button } from "antd";
import { useState, useMemo, Fragment, useCallback, useEffect } from "react";
import HideDisplayColumns from "./modal";
import HideShowColumnsTable from "./table";
import { handleUpdate } from "../services";

export default function Index(props) {
  const {
    header,
    columns,
    dataSource,
    // totalCount,
    loading,
    onChangePagination,
    disableGetQueryParams,
    totalData,
    rowSelection,
    onRow,
    pagination,
    rowKey,
    expandable,
    summary,
    setScrollX,
    textGroup1,
    textGroup2,
    textGroup3,
    type,
    getListColumns,
    titleHeader,
    lengthFixed,
  } = props;

  const [newColumns, setNewColumns] = useState(columns);
  const [visible, setVisible] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [, setLoading] = useState(false);

  //xử lý phần ẩn/hiện cột
  const showHideDisplayColumns = () => {
    setVisible(true);
  };

  const closeHideDisplayColumns = () => {
    setVisible(false);
  };

  const genExtra = useCallback(
    () => (
      <div>
        <Button
          size="small"
          type="primary"
          className="mr-5px"
          onClick={showHideDisplayColumns}
        >
          Cấu hình bảng
        </Button>
      </div>
    ),
    []
  );

  const modalHideDisplay = useMemo(
    () =>
      visible && (
        <HideDisplayColumns
          visible={visible}
          closeModal={closeHideDisplayColumns}
          arrColumns={newColumns}
          setNewColumns={setNewColumns}
          setScrollX={setScrollX}
          textGroup1={textGroup1}
          textGroup2={textGroup2}
          textGroup3={textGroup3}
          getListColumns={getListColumns}
          setIsSubmit={setIsSubmit}
          titleHeader={titleHeader}
        />
      ),
    [
      visible,
      newColumns,
      setScrollX,
      textGroup1,
      textGroup2,
      textGroup3,
      getListColumns,
      titleHeader,
    ]
  );

  useEffect(() => {
    if (isSubmit) setIsSubmit(false);
  }, [isSubmit]);

  const fixNewColumns = useCallback(
    (newColumns) => {
      const arrColumns = [];
      let titleGroup2 = {};
      let titleGroup3 = {};
      const childrenGroup2 = [];
      const childrenGroup3 = [];
      newColumns.map((item) => {
        if (!item.hide) {
          if (item.parent !== undefined && item.parent !== "") {
            if (item.group === 2) {
              titleGroup2 = {
                title: item.parent,
                dataIndex: "group2",
                key: "group2",
              };
              childrenGroup2.push(item);
            }
            if (item.group === 3) {
              titleGroup3 = {
                title: item.parent,
                dataIndex: "group3",
                key: "group3",
              };
              childrenGroup3.push(item);
            }
          } else {
            return arrColumns.push(item);
          }
        }

        return true;
      });
      const group2 = {
        ...titleGroup2,
        children: childrenGroup2,
      };

      const group3 = {
        ...titleGroup3,
        children: childrenGroup3,
      };
      if (group2.children.length > 0) arrColumns.push(group2);
      if (group3.children.length > 0) arrColumns.push(group3);

      const columnsUpdate = {};
      newColumns.map((item) => {
        return (columnsUpdate[item.dataIndex] = item.hide);
      });
      if (isSubmit) {
        handleUpdate(columnsUpdate, setLoading, getListColumns, type);
        setIsSubmit(false);
      }
      return arrColumns;
    },
    [getListColumns, isSubmit, type]
  );

  const renderTable = useCallback(() => {
    const arrColumns = fixNewColumns(newColumns);
    const checked = [];
    let countWidth = 0;
    arrColumns.map((item) => {
      if (item.children && item.children.length > 0) {
        item.children.map((el) => {
          if (!el.hide) {
            return checked.push(el);
          }
          return true;
        });
      } else {
        if (!item.hide) {
          checked.push(item);
        }
      }
      return true;
    });

    checked.forEach(({ width }) => {
      if (width === undefined) width = 150;
      countWidth += width;
    });

    if (lengthFixed === checked.length) {
      checked.forEach((item) => {
        if (item.fixed) return (item.fixed = null);
      });
    }
    // else {
    //   // for (let i = 0; i < lengthFixed; i++) {
    //   //   console.log(checked);
    //   //   // if (checked && checked[i].fixed !== undefined)
    //   //     // checked[i].fixed = "left";
    //   // }
    // }
    return (
      <HideShowColumnsTable
        header={header}
        columns={arrColumns}
        dataSource={dataSource}
        // totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        genExtra={genExtra}
        disableGetQueryParams={disableGetQueryParams}
        totalData={totalData}
        rowSelection={rowSelection}
        onRow={onRow}
        pagination={pagination}
        scrollX={checked.length <= 10 ? countWidth + 700 : countWidth + 1500}
        rowKey={rowKey}
        expandable={expandable}
        summary={summary}
      />
    );
  }, [
    dataSource,
    disableGetQueryParams,
    expandable,
    fixNewColumns,
    genExtra,
    header,
    lengthFixed,
    loading,
    newColumns,
    onChangePagination,
    onRow,
    pagination,
    rowKey,
    rowSelection,
    summary,
    totalData,
  ]);
  return (
    <Fragment>
      {renderTable()}
      {modalHideDisplay}
    </Fragment>
  );
}
