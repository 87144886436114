// import KySoComponent from "@components/KySo";
// import { Endpoint } from "@utils/endpoint";
import { Button, Col, Modal, Row } from "antd";
import React from "react";

function DuyetYeuCauModal(props) {
  const {
    visible,
    handleCloseModal,
    handleSubmit,
    // dataIds,
    // getListData,
    loading,
    // loadingKySo,
    // setLoadingKySo,
    handleKyHSM,
    loadingKyHSM,
  } = props;

  return (
    // <LoadingComponent loading={loading}>
    <Modal
      title="YÊU CẦU ĐANG Ở LÃNH ĐẠO."
      visible={visible}
      onCancel={handleCloseModal}
      footer={null}
      maskClosable={false}
    >
      <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
        Yêu cầu sẽ được Lãnh đạo ký số/xác nhận.
      </h4>
      <Row gutter={24} justify="center" style={{ marginTop: "20px" }}>
        <Col span={6} style={{ textAlign: "center" }}>
          <Button
            key="back"
            onClick={handleCloseModal}
            className="button-closed"
          >
            Đóng lại
          </Button>
        </Col>
        <Col span={6} style={{ textAlign: "center" }}>
          <Button
            key="submit"
            type="primary"
            className="button-primary"
            htmlType="submit"
            onClick={handleSubmit}
            style={{ backgroundColor: "green" }}
            loading={loading}
            disabled={loadingKyHSM}
          >
            Xác nhận
          </Button>
        </Col>
        <Col span={6} style={{ textAlign: "center" }}>
          {/* <KySoComponent
            url={Endpoint.CRM_UPDATE_HOP_DONG_KY_SO}
            urlGetHash={Endpoint.CRM_LANH_DAO_KY_SO}
            payload={{
              id: dataIds,
            }}
            // permissionCode={ghi_chi_so.d33ky}
            disabled={!dataIds.length || loading}
            // setFetchDataTable={setFetchDataTable}
            reload={getListData}
            setLoading={setLoadingKySo}
            loading={loadingKySo}
          /> */}
          <Button
            onClick={handleKyHSM}
            loading={loadingKyHSM}
            type="danger"
            disabled={loading}
          >
            Ký HSM
          </Button>
        </Col>
      </Row>
    </Modal>
    // </LoadingComponent>
  );
}

export default DuyetYeuCauModal;
