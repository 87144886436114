import { SearchOutlined } from "@ant-design/icons";
import FormComponent from "@components/Form";
import { buildQueryString, removeAccents } from "@utils/function";
import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import styled from "styled-components";
import {
  exportDanhSachYeuCauDiemDo,
  getDanhSachYeuCauDiemDo,
  getListDoi,
  getListDonVi,
  getListTram,
  getSoGCS,
} from "./quan-ly-diem-do-va-tram-services";

export default function QuanLyDiemDoVaTramFilter(props) {
  const { filterMapConditions, setDanhSachDiemDo } = props;
  const hinhThucHienThi = [
    {
      name: "Tất cả",
      value: "0",
    },
    {
      name: "Trạm",
      value: "1",
    },
    {
      name: "Điểm đo",
      value: "2",
    },
  ];
  const [, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [listDonVi, setListDonVi] = useState([]);
  const [listDoi, setListDoi] = useState([]);
  const [danhSachTram, setDanhSachTram] = useState([]);
  const [listSoGCS, setListSoGCS] = useState([]);
  const [donVi, setDonVi] = useState(filterMapConditions.DonViId);
  const [maTram, setMaTram] = useState(filterMapConditions.TramId);
  const [doiId, setDoiId] = useState(filterMapConditions.DoiId);
  const [soGCS, setSoGCS] = useState(filterMapConditions.SoGCS);

  const [tuKhoa, setTuKhoa] = useState(filterMapConditions.TuKhoa);
  const [hienThi, setHienThi] = useState(
    filterMapConditions.HienThi
      ? filterMapConditions.HienThi
      : hinhThucHienThi[0].value
  );

  useEffect(() => {
    form.setFieldsValue({
      ...filterMapConditions,
    });
  }, [form, filterMapConditions]);

  useEffect(() => {
    getListDonVi(setLoading, setListDonVi);
  }, []);

  useEffect(() => {
    getListTram(setLoading, setDanhSachTram, donVi);
  }, [donVi, setDanhSachTram]);

  useEffect(() => {
    const payload = {
      DonViId: donVi,
      TramId: maTram,
    };

    const query = buildQueryString(payload);
    getSoGCS(setLoading, setListSoGCS, query);
  }, [donVi, maTram]);

  // Get ds doi
  useEffect(() => {
    getListDoi(setLoading, setListDoi, donVi);
  }, [donVi]);

  useEffect(() => {
    setDanhSachDiemDo([]);
    const payload = {
      ...(donVi && { DonViId: donVi }),
      ...(doiId && { DoiId: doiId }),
      ...(maTram && { TramId: maTram }),
      ...(soGCS && { SoGCS: soGCS }),
      ...(tuKhoa && { TuKhoa: tuKhoa }),
      HienThi: hienThi,
    };
    setSearchParams(payload);
    const query = buildQueryString(payload);
    getDanhSachYeuCauDiemDo(setLoading, setDanhSachDiemDo, query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donVi, doiId, soGCS, maTram, tuKhoa, hienThi]);

  const handleChangeDonViId = (value) => {
    setDonVi(value);
    setDoiId();
    setMaTram();
    setSoGCS();
    form.setFieldsValue({
      DoiId: undefined,
      TramId: undefined,
      SoGCS: undefined,
    });
  };
  const handleChangeDoiId = (value) => {
    setDoiId(value);
  };
  const handleChangeMaTram = (value) => {
    setMaTram(value);
    setSoGCS();
    form.setFieldsValue({
      SoGCS: undefined,
    });
  };
  const handleChangeSoGCS = (value) => {
    setSoGCS(value);
  };
  const handleChangeTuKhoa = (e) => {
    setTuKhoa(e.target.value);
  };
  const handleChangeHienThi = (value) => {
    setHienThi(value);
  };

  const handleExport = () => {
    const payload = {
      ...(donVi && { DonViId: donVi }),
      ...(doiId && { DoiId: doiId }),
      ...(maTram && { TramId: maTram }),
      ...(soGCS && { SoGCS: soGCS }),
      ...(tuKhoa && { TuKhoa: tuKhoa }),
      HienThi: hienThi,
    };
    const query = buildQueryString(payload);
    exportDanhSachYeuCauDiemDo(query)
  }

  return (
    <Styled>
      <Row>
        <FormComponent
          style={{ width: "100%" }}
          layout="vertical"
          form={form}
          initialValues={{
            DonViId: donVi,
            TramId: maTram,
            DoiId: doiId,
            SoGCS: soGCS,
            TuKhoa: tuKhoa,
            HienThi: hienThi,
          }}
        >
          <Row gutter={[32, 4]}>
            <Col span={7}>
              <Form.Item name="DonViId" label="">
                <Select
                  showSearch
                  onChange={handleChangeDonViId}
                  loading={loading}
                  placeholder="Đơn vị"
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                  disabled={listDonVi.length < 2}
                >
                  {listDonVi &&
                    listDonVi.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item name="TramId" label="">
                <Select
                  allowClear
                  showSearch
                  loading={loading}
                  placeholder="Trạm"
                  onChange={handleChangeMaTram}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {danhSachTram &&
                    danhSachTram.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="DoiId" label="">
                <Select
                  onChange={handleChangeDoiId}
                  allowClear
                  showSearch
                  loading={loading}
                  placeholder="Tổ đội"
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                  disabled={listDoi.length === 1}
                >
                  {listDoi &&
                    listDoi.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="SoGCS" label="">
                <Select
                  allowClear
                  showSearch
                  loading={loading}
                  placeholder="Sổ GCS"
                  onChange={handleChangeSoGCS}
                  disabled={donVi && maTram ? false : true}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {listSoGCS &&
                    listSoGCS.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={7}>
              <Form.Item name="TuKhoa" label="">
                <Input
                  prefix={<SearchOutlined />}
                  onPressEnter={handleChangeTuKhoa}
                  placeholder="Tìm kiếm mã điểm đo"
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="HienThi" label="">
                <Select
                  onChange={handleChangeHienThi}
                  loading={loading}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {hinhThucHienThi &&
                    hinhThucHienThi.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Button type="primary" onClick={handleExport}>Xuất excel</Button>
            </Col>
          </Row>
        </FormComponent>
      </Row>
    </Styled>
  );
}

const Styled = styled.div`
  width: 100%;
`;
