import React, { useState, useEffect } from "react";
import UICongTo from "./ui-panels/cong-to";

export default function CongToPanels(props) {
  const {
    form,
    listHinhThucLapDat,
    mode,
    dataPhuongAn,
    listDayDanDien,
    loadingDayDanDien,
  } = props;
  const [isShowInputField, setShowInputField] = useState(false);
  const [viTriLapDat, setViTriLapDat] = useState(null);

  useEffect(() => {
    setShowInputField(form.getFieldValue("isLapThem"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue("isLapThem")]);

  useEffect(() => {
    setViTriLapDat(form.getFieldValue("viTriCongTo"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue("viTriCongTo")]);

  const columns = [
    {
      title: "Dây dẫn điện",
      dataIndex: "dayDanDien",
      key: "dayDanDien",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "20%",
    },
    {
      title: "Tên vật tư",
      dataIndex: "tenVatTu",
      key: "tenVatTy",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "40%",
    },
    {
      title: "Chiều dài (mét)",
      dataIndex: "chieuDai",
      key: "chieuDai",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "20%",
    },
    {
      title: "Ghi chú",
      dataIndex: "ghiChu",
      key: "ghiChu",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "20%",
    },
  ];

  return (
    <UICongTo
      form={form}
      mode={mode}
      setViTriLapDat={setViTriLapDat}
      viTriLapDat={viTriLapDat}
      columns={columns}
      data={listDayDanDien?.duongDayDiens}
      listHinhThucLapDat={listHinhThucLapDat}
      setShowInputField={setShowInputField}
      isShowInputField={isShowInputField}
      dataPhuongAn={dataPhuongAn}
      content={listDayDanDien?.content}
      loadingDayDanDien={loadingDayDanDien}
    />
  );
}
