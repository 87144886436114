import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";
import { buildQueryString, parseParams } from "@utils/function";
import { STATUSCODE_200 } from "@utils/constants";

// get list data chỉ số
export const handleListDataChiSo = (
  setLoading,
  location,
  setDataChiSo,
  setTotalCount,
  filterConditions
) => {
  let url = "";
  url = `${Endpoint.XEM_CHI_SO_BANG_KE}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataChiSo(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataChiSo([]);
    },
  });
};

export const submitOpenPdf = (id, setLoading) => {
  authGetData({
    url: `${Endpoint.BANG_KE_CHI_SO}/${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        window.open(res.data, "_blank");
      }
    },
  });
};
