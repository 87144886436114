import { ENUM_DIACHINH, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { getErrorMessage } from "@utils/function";
import {
  authGetData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";
// list datas

export const handleMultileListData = (
  setLoading,
  setDataDetail,
  setDataDiaChinh,
  setDataGioiTinhKH,
  setDataSoPha,
  setDataMucDich,
  setDataLoaiYeuCau,
  setDataLanhDao,
  setDataNganHang,
  hoSoId
) => {
  const endpoints = [
    `${Endpoint.UPDATE_DETAIL_HO_SO + "?HoSoId=" + hoSoId}`,
    `${Endpoint.LIST_DIA_CHINH}?CapDonVi=${ENUM_DIACHINH.CAPHAI}&PageSize=-1`,
    `${Endpoint.COMMON_LIST_GIOI_TINH}`,
    `${Endpoint.COMMON_LIST_SO_PHA}`,
    `${Endpoint.COMMON_LIST_MUC_DICH}`,
    `${Endpoint.COMMON_LIST_LOAI_YEU_CAU}`,
    `${Endpoint.COMMON_LIST_LANH_DAO + "?HoSoId=" + hoSoId}`,
    `${Endpoint.COMMON_LIST_NGAN_HANG}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDataDetail(res[0].data.data[0]);
      setDataDiaChinh(res[1].data.data);
      setDataGioiTinhKH(res[2].data.data);
      setDataSoPha(res[3].data.data);
      setDataMucDich(res[4].data.data);
      setDataLoaiYeuCau(res[5].data.data);
      setDataLanhDao(res[6].data.data);
      setDataNganHang(res[7].data.data);
    },
  });
};
// list datas xã phường

export const handleMultileListXaPhuong = (
  quanHuyenId,
  setLoading,
  setDataXaPhuong
) => {
  const endpoints = [`${Endpoint.LIST_PHUONG_XA}?QuanHuyenId=${quanHuyenId}`];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDataXaPhuong(res[0].data.data);
    },
  });
};
// list datas xã phường GD

export const handleMultileListXaPhuongGD = (
  quanHuyenGiaoDichId,
  setLoading,
  setDataXaPhuongGiaoDich
) => {
  const endpoints = [
    `${Endpoint.LIST_PHUONG_XA}?QuanHuyenId=${quanHuyenGiaoDichId}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDataXaPhuongGiaoDich(res[0].data.data);
    },
  });
};
// list data quận huyện

export const handleDataDiaChinh = (
  value,
  setLoading,
  setDataXaPhuong,
  setDisabledXaPhuong,
  form
) => {
  authGetData({
    url: `${Endpoint.LIST_PHUONG_XA}?QuanHuyenId=${value}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        const valuesXP = form.getFieldValue();
        form.setFieldsValue({
          ...valuesXP,
          xaPhuong: null,
        });
        setDataXaPhuong(res.data);
        setDisabledXaPhuong(false);
      } else setDataXaPhuong([]);
    },
  });
};
// list data quận huyện giao dịch

export const handleDataDiaChinhGiaoDich = (
  value,
  setLoading,
  setDataXaPhuongGiaoDich,
  setDisabledXaPhuongGiaoDich,
  form
) => {
  authGetData({
    url: `${Endpoint.LIST_PHUONG_XA}?QuanHuyenId=${value}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        const valuesXPGD = form.getFieldValue();
        form.setFieldsValue({
          ...valuesXPGD,
          xaPhuongGiaoDich: null,
        });
        setDataXaPhuongGiaoDich(res.data);
        setDisabledXaPhuongGiaoDich(false);
      } else {
        setDataXaPhuongGiaoDich([]);
      }
    },
  });
};
// call api update khi submit

export const handleSubmitForm = (
  values,
  setLoading,
  closeModal,
  dispatch,
  setFetchDataTable,
  form
) => {
  const data = {
    ...values,
  };
  authPostData({
    url: Endpoint.UPDATE_HO_SO,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchDataTable(true);
        dispatch(closeModal());
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};
// call api get thông tin khách hàng lấy từ cmis

export const handleDataThongTinKHCMIS = (
  value,
  setLoading,
  setDataDiemDoHienTai
) => {
  authGetData({
    url: `${Endpoint.DATA_THONG_TIN_KH_CMIS}?MaKhachHang=${value}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDiemDoHienTai(res.data);
      } else setDataDiemDoHienTai({});
    },
  });
};
