import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_DATE,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { authGetData } from "@utils/request";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { handleDownload, selectBaoCaoDoiSoatEmail } from "../services";

export default function BcThSlThuDienTu(props) {
  const { screen, handleChange } = props;
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { user } = useSelector(authSelector);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
    DenNgay: moment().format(FORMAT_ENGLISH),
    ...parseParams(location.search),
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
      DenNgay: moment().format(FORMAT_ENGLISH),
    });
  }, [user.unitId]);

  const handleSearch = useCallback((values) => {
    const queryString = buildQueryString(
      parseParams({
        DonViId: values.DonViId,
        DoiTuongId: values.DoiTuongId,
        TuNgay: values.TuNgay,
        DenNgay: values.DenNgay,
      })
    );
    authGetData({
      url: `${Endpoint.BC_TH_SL_THU_DIEN_TU_HOP_LE}?${buildQueryString(
        parseParams(queryString)
      )}`,
      setLoading,
      onSuccess: (res) => {
        if (res && res.data) {
          setData(res.data);
          setTotalCount(res.paging.totalCount);
        }
      },
    });
  }, []);
  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      width: 70,
    },
    {
      title: "Đơn vị",
      dataIndex: "maDVi",
      key: "maDVi",
    },
    {
      title: "Số lượng",
      dataIndex: "soLuong",
      key: "soLuong",
    },
  ];
  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <FormComponent
            onFinish={handleSearch}
            layout="horizontal"
            clearFilter={clearFilter}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            form={form}
            initialValues={{
              reportType: screen,
              TuNgay: form.setFieldsValue(filterConditions.TuNgay)
                ? form.setFieldsValue(filterConditions.TuNgay)
                : moment().startOf(MONTH_STRING),
            }}
          >
            <Form.Item name="reportType" label="Tên báo cáo" labelAlign="left">
              <Select
                formKey="reportType"
                form={form}
                options={selectBaoCaoDoiSoatEmail}
                onChange={() => handleChange(form.getFieldValue("reportType"))}
              />
            </Form.Item>
            <Form.Item name="DonViId" label="Đơn vị" labelAlign="left">
              <Selection
                url={Endpoint.GET_UNIT_LIST}
                formKey="DonViId"
                form={form}
              />
            </Form.Item>
            <Form.Item name="DoiTuongId" label="Nội dung" labelAlign="left">
              <Selection
                url={Endpoint.CSKH_BAO_CAO_EMAIL}
                formKey="DoiTuongId"
                form={form}
                placeholder="Tất cả"
              />
            </Form.Item>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  name="TuNgay"
                  label="Ngày bắt đầu"
                  labelAlign="left"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                >
                  <DatePickerComponent
                    defaultValue={
                      filterConditions.TuNgay
                        ? moment(filterConditions.TuNgay, FORMAT_ENGLISH)
                        : moment().startOf(FORMAT_DATE)
                    }
                    formKey="TuNgay"
                    form={form}
                    format={FORMAT_ENGLISH}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="DenNgay"
                  label="Ngày kết thúc"
                  labelAlign="right"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                >
                  <DatePickerComponent
                    defaultValue={
                      filterConditions.DenNgay
                        ? moment(filterConditions.DenNgay, FORMAT_ENGLISH)
                        : moment().startOf(FORMAT_DATE)
                    }
                    formKey="DenNgay"
                    form={form}
                    format={FORMAT_ENGLISH}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Button type="primary" className="mr-5px" htmlType="submit">
                Xem báo cáo
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  handleDownload(form.getFieldValue(), setLoading);
                }}
              >
                Xuất Excel
              </Button>
            </Row>
          </FormComponent>
          <Divider className="section-text-line" orientation="left"></Divider>
        </ContentWrapper>
        <TableComponent
          header={
            "Báo cáo tổng hợp số lượng thư điện tử hợp lệ đã gửi theo từng đơn vị"
          }
          columns={columns}
          dataSource={data}
          totalCount={totalCount}
          loading={loading}
        />
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  margin-top: 40px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
