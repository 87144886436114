import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import LoadingComponent from "@components/Loading";
import {
  Table,
  Row,
  Col,
  Collapse,
  Tooltip,
  Tabs,
  Form,
  Input,
  Button,
  Modal,
} from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import ThongTinChung from "./thong-tin-chung";
import PreviewImage from "./images";
import FormComponent from "@components/Form";
import { closeModal } from "@state/system-config/reducer";
import { useDispatch } from "react-redux";
import { handleUpdateChiSo } from "@modules/treo-thao/nhan-vien-treo-thao/ky-duyet/services";

const { Panel } = Collapse;

export default function Index(props) {
  const { data, loading, setFetchList } = props;
  const [form] = Form.useForm();
  const [validate, setValidate] = useState(false);
  const dispatch = useDispatch();
  const [load, setLoading] = useState(false);
  const [bcsThao, setBCSThao] = useState([]);
  const [bcsTreo, setBCSTreo] = useState([]);

  const onFinish = () => {
    const arrBCSThao = [];
    let totalSGThao = 0;
    let totalSNThao = 0;
    let totalSGTreo = 0;
    let totalSNTreo = 0;
    if (data.congToThao && data.congToThao.boChiSos.length > 0) {
      data.congToThao.boChiSos.forEach((item) => {
        if (item.bcs === "BT" || item.bcs === "CD" || item.bcs === "TD")
          totalSGThao += item.chiSoMoi ? parseInt(item.chiSoMoi) : 0;
        if (item.bcs === "BN" || item.bcs === "CN" || item.bcs === "TN")
          totalSNThao += item.chiSoMoi ? parseInt(item.chiSoMoi) : 0;
        return arrBCSThao.push({
          bcs: item.bcs,
          chiSoCu: form.getFieldValue(`chiSoCuThao-${item.bcs}`)
            ? form.getFieldValue(`chiSoCuThao-${item.bcs}`)
            : item.chiSoCu,
          chiSoMoi: form.getFieldValue(`chiSoMoiThao-${item.bcs}`)
            ? form.getFieldValue(`chiSoMoiThao-${item.bcs}`)
            : item.chiSoCu,
          image: item.image,
          ngayPMax: item.ngayPMax,
          pMax: item.pMax,
          sanLuongMoi: form.getFieldValue(`sanLuongMoiThao-${item.bcs}`)
            ? form.getFieldValue(`sanLuongMoiThao-${item.bcs}`)
            : item.chiSoCu,
          thuTu: item.thuTu,
        });
      });
    }

    const arrBCSTreo = [];
    if (data.congToTreo && data.congToTreo.boChiSos.length > 0) {
      data.congToTreo.boChiSos.forEach((item) => {
        if (item.bcs === "BT" || item.bcs === "CD" || item.bcs === "TD")
          totalSGTreo += item.chiSoMoi ? parseInt(item.chiSoMoi) : 0;
        if (item.bcs === "BN" || item.bcs === "CN" || item.bcs === "TN")
          totalSNTreo += item.chiSoMoi ? parseInt(item.chiSoMoi) : 0;
        return arrBCSTreo.push({
          bcs: item.bcs,
          chiSoCu: item.chiSoCu,
          chiSoMoi: form.getFieldValue(`chiSoMoiTreo-${item.bcs}`)
            ? form.getFieldValue(`chiSoMoiTreo-${item.bcs}`)
            : item.chiSoMoi,
          image: item.image,
          ngayPMax: item.ngayPMax,
          pMax: item.pMax,
          sanLuongMoi: "0",
          thuTu: item.thuTu,
        });
      });
    }
    const payload = {
      ...data,
      congToThao: {
        ...data.congToThao,
        boChiSos: arrBCSThao,
      },
      congToTreo:
        data.congToTreo === null
          ? null
          : {
              ...data.congToTreo,
              boChiSos: arrBCSTreo,
            },
    };
    if (!validate) {
      if (bcsThao.includes("SG") || bcsThao.includes("SN")) {
        if (
          form.getFieldValue(`chiSoMoiThao-SG`) < totalSGThao - 3 ||
          form.getFieldValue(`chiSoMoiThao-SG`) > totalSGThao + 3 ||
          form.getFieldValue(`chiSoMoiThao-SN`) < totalSNThao - 3 ||
          form.getFieldValue(`chiSoMoiThao-SN`) > totalSNThao + 3
        ) {
          Modal.confirm({
            centered: true,
            title: "Xác nhận",
            content: `Tổng chỉ số SG/SN của công tơ THÁO đang chênh lệch ± 3 so với tổng BCS (BT/BN + CD/CN + TD/TN)`,
            onOk() {
              handleUpdateChiSo(setLoading, payload, setFetchList, dispatch);
            },
            onCancel() {},
            okText: "Đồng ý",
            okButtonProps: { type: "danger" },
            cancelText: "Hủy",
          });
        } else handleUpdateChiSo(setLoading, payload, setFetchList, dispatch);
      } else if (bcsTreo.includes("SG") || bcsTreo.includes("SN")) {
        if (
          form.getFieldValue(`chiSoMoiTreo-SG`) < totalSGTreo - 3 ||
          form.getFieldValue(`chiSoMoiTreo-SG`) > totalSGTreo + 3 ||
          form.getFieldValue(`chiSoMoiTreo-SN`) < totalSNTreo - 3 ||
          form.getFieldValue(`chiSoMoiTreo-SN`) > totalSNTreo + 3
        ) {
          Modal.confirm({
            centered: true,
            title: "Xác nhận",
            content: `Tổng chỉ số SG/SN của công tơ TREO đang chênh lệch ± 3 so với tổng BCS (BT/BN + CD/CN + TD/TN)`,
            onOk() {
              handleUpdateChiSo(setLoading, payload, setFetchList, dispatch);
            },
            onCancel() {},
            okText: "Đồng ý",
            okButtonProps: { type: "danger" },
            cancelText: "Hủy",
          });
        } else handleUpdateChiSo(setLoading, payload, setFetchList, dispatch);
      } else handleUpdateChiSo(setLoading, payload, setFetchList, dispatch);
    }
  };

  useEffect(() => {
    const arrBCSThao = [];
    const arrBCSTreo = [];
    if (data.congToThao && data.congToThao.boChiSos.length > 0) {
      data.congToThao.boChiSos.forEach((item) => {
        arrBCSThao.push(item.bcs);
      });
    }

    if (data.congToTreo && data.congToTreo.boChiSos.length > 0) {
      data.congToTreo.boChiSos.forEach((item) => {
        arrBCSTreo.push(item.bcs);
      });
    }
    setBCSThao(arrBCSThao);
    setBCSTreo(arrBCSTreo);
  }, [data.congToThao, data.congToTreo]);
  return (
    <LoadingComponent loading={loading || load}>
      <Collapse defaultActiveKey={["1"]}>
        <Panel header="Thông tin chung" key={"1"}>
          <ThongTinChung
            detail={{
              maGC: data.maGC,
              maTrangThai: data.maTrangThai,
              soCongTo: data.soCongTo,
              soCot: data.soCot,
              tenKH: data.tenKH,
              tenTram: data.tenTram,
            }}
          />
        </Panel>
      </Collapse>
      <FormComponent
        form={form}
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Tabs defaultActiveKey="1" type="card" style={{ padding: "8px" }}>
          <Tabs.TabPane tab="Công tơ tháo" key="1">
            <Layout
              soCuaSo={
                data.congToThao && data.congToThao.soCuaSo
                  ? data.congToThao.soCuaSo
                  : 1
              }
              hsn={
                data.congToThao && data.congToThao?.heSoNhan
                  ? data.congToThao.heSoNhan
                  : 1
              }
              form={form}
              data={
                data.congToThao && data.congToThao.boChiSos
                  ? data.congToThao.boChiSos
                  : []
              }
              maCongTo={
                data.congToThao && data.congToThao.maCongTo
                  ? data.congToThao.maCongTo
                  : ""
              }
              setValidate={setValidate}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Công tơ treo" key="2">
            <Layout
              hsn={
                data.congToTreo && data.congToTreo?.heSoNhan
                  ? data.congToTreo.heSoNhan
                  : 1
              }
              isTreo
              form={form}
              data={
                data.congToTreo && data.congToTreo.boChiSos
                  ? data.congToTreo.boChiSos
                  : []
              }
              maCongTo={
                data.congToTreo && data.congToTreo.maCongTo
                  ? data.congToTreo.maCongTo
                  : ""
              }
              soCuaSo={
                data.congToTreo && data.congToTreo.soCuaSo
                  ? data.congToThao.soCuaSo
                  : 1
              }
              setValidate={setValidate}
            />
          </Tabs.TabPane>
        </Tabs>
        <Row
          gutter={24}
          align="center"
          style={{ marginTop: "48px", textAlign: "center" }}
          justify="center"
        >
          <Col span={24}>
            <Button
              type="default"
              className="mr-24px"
              onClick={() => dispatch(closeModal())}
            >
              Đóng lại
            </Button>
            <Button type="primary" htmlType="submit">
              Cập nhật
            </Button>
          </Col>
        </Row>
      </FormComponent>
    </LoadingComponent>
  );
}

function Layout(props) {
  const { data, maCongTo, form, isTreo, hsn, soCuaSo, setValidate } = props;

  const handleNumber = (e) => {
    const value = e;

    if (value.match(/^[+-]?\d+(\.\d+)?$/)) return value;
  };

  const handleChangeInput = useCallback(
    (e, fieldName, record) => {
      let validate = false;
      const max = soCuaSo;
      let valueText = "9";
      for (let i = 1; i < max; i++) {
        valueText += "9";
      }
      const chiSo = e.target.value === "" ? 0 : e.target.value;

      if (e.target.value === "" || parseInt(e.target.value) < 0) {
        if (isTreo) {
          form.setFields([
            {
              name: [`chiSoMoiTreo-${record.bcs}`],
              errors: ["Chỉ số treo phải lớn hơn hoặc bằng 0"],
            },
          ]);
          validate = true;
        } else {
          if (fieldName === "chiSoMoi") {
            form.setFields([
              {
                name: [`chiSoMoiThao-${record.bcs}`],
                errors: ["Chỉ số mới phải lớn hơn hoặc bằng 0"],
              },
            ]);
          } else {
            form.setFields([
              {
                name: [`chiSoCuThao-${record.bcs}`],
                errors: ["Chỉ số cũ phải lớn hơn hoặc bằng 0"],
              },
            ]);
          }
          validate = true;
        }
      } else if (parseInt(e.target.value) > parseInt(valueText)) {
        if (isTreo) {
          form.setFields([
            {
              name: [`chiSoMoiTreo-${record.bcs}`],
              errors: [`Chỉ số treo phải bé hơn hoặc bằng ${valueText}`],
            },
          ]);
          validate = true;
        } else {
          if (fieldName === "chiSoMoi") {
            form.setFields([
              {
                name: [`chiSoMoiThao-${record.bcs}`],
                errors: [`Chỉ số mới phải bé hơn hoặc bằng ${valueText}`],
              },
            ]);
          } else {
            form.setFields([
              {
                name: [`chiSoCuThao-${record.bcs}`],
                errors: ["Chỉ số cũ phải lớn hơn hoặc bằng 0"],
              },
            ]);
          }
          validate = true;
        }
      } else if (handleNumber(e.target.value) === undefined) {
        if (isTreo) {
          form.setFields([
            {
              name: [`chiSoMoiTreo-${record.bcs}`],
              errors: [`Nhập số nguyên hoặc số thập phân`],
            },
          ]);
        } else {
          form.setFields([
            {
              name: [`chiSoMoiThao-${record.bcs}`],
              errors: [`Nhập số nguyên hoặc số thập phân`],
            },
          ]);
        }
        validate = true;
      } else {
        if (isTreo) {
          form.setFields([
            {
              name: [`chiSoMoiTreo-${record.bcs}`],
              errors: false,
            },
          ]);
        } else {
          if (fieldName === "chiSoMoi") {
            form.setFields([
              {
                name: [`chiSoMoiThao-${record.bcs}`],
                errors: false,
              },
            ]);
          } else {
            form.setFields([
              {
                name: [`chiSoCuThao-${record.bcs}`],
                errors: false,
              },
            ]);
          }
        }
        validate = false;
      }
      setValidate(validate);

      let formChiSoMoi = form.getFieldValue(`chiSoMoiThao-${record.bcs}`)
        ? form.getFieldValue(`chiSoMoiThao-${record.bcs}`)
        : 0;

      let formChiSoCu = form.getFieldValue(`chiSoCuThao-${record.bcs}`)
        ? form.getFieldValue(`chiSoCuThao-${record.bcs}`)
        : 0;

      if (isTreo) {
        formChiSoMoi = form.getFieldValue(`chiSoMoiTreo-${record.bcs}`)
          ? form.getFieldValue(`chiSoMoiTreo-${record.bcs}`)
          : 0;
        formChiSoCu = form.getFieldValue(`chiSoCuTreo-${record.bcs}`)
          ? form.getFieldValue(`chiSoCuTreo-${record.bcs}`)
          : 0;

        if (fieldName === "chiSoMoi") {
          form.setFieldsValue({
            [`sanLuongMoiTreo-${record.bcs}`]: (
              (parseFloat(chiSo) - parseFloat(formChiSoCu)) *
              hsn
            ).toFixed(0),
          });
        } else {
          form.setFieldsValue({
            [`sanLuongMoiTreo-${record.bcs}`]: (
              (parseFloat(formChiSoMoi) - parseFloat(chiSo)) *
              hsn
            ).toFixed(0),
          });
        }
      } else {
        if (fieldName === "chiSoMoi") {
          if (handleNumber(e.target.value) === undefined) {
            form.setFieldsValue({
              [`sanLuongMoiThao-${record.bcs}`]: "0",
            });
          } else
            form.setFieldsValue({
              [`sanLuongMoiThao-${record.bcs}`]: (
                (parseFloat(chiSo) - parseFloat(formChiSoCu)) *
                hsn
              ).toFixed(0),
            });
        } else {
          form.setFieldsValue({
            [`sanLuongMoiThao-${record.bcs}`]: (
              (parseFloat(formChiSoMoi) - parseFloat(chiSo)) *
              hsn
            ).toFixed(0),
          });
        }
      }
    },
    [form, hsn, isTreo, setValidate, soCuaSo]
  );

  useEffect(() => {
    if (isTreo && data.length > 0) {
      data.map((item) => {
        return form.setFieldsValue({
          bcs: item.bcs,
          [`chiSoCuTreo-${item.bcs}`]: item.chiSoCu,
          [`chiSoMoiTreo-${item.bcs}`]: item.chiSoMoi,
          image: item.image,
          ngayPMax: item.ngayPMax,
          pMax: item.pMax,
          [`sanLuongMoiTreo-${item.bcs}`]: item.sanLuongMoi,
          thuTu: item.thuTu,
        });
      });
    } else {
      if (data.length > 0) {
        data.map((item) => {
          return form.setFieldsValue({
            bcs: item.bcs,
            [`chiSoCuThao-${item.bcs}`]: item.chiSoCu,
            [`chiSoMoiThao-${item.bcs}`]: item.chiSoMoi,
            image: item.image,
            ngayPMax: item.ngayPMax,
            pMax: item.pMax,
            [`sanLuongMoiThao-${item.bcs}`]: item.sanLuongMoi,
            thuTu: item.thuTu,
          });
        });
      }
    }
  }, [data, form, isTreo]);
  const columnsTreo = [
    {
      title: "Bộ chỉ số",
      key: "bcs",
      dataIndex: "bcs",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "35%",
    },
    // {
    //   title: "CS cũ",
    //   key: "chiSoCu",
    //   dataIndex: "chiSoCu",
    //   render: (text, record, index) => (
    //     <Form.Item
    //       name={isTreo ? `chiSoCuTreo-${index}` : `chiSoCuThao-${index}`}
    //     >
    //       <Input
    //         placeholder="Nhập chỉ số cũ"
    //         onChange={(e) => handleChangeInput(e, "chiSoCu", index)}
    //         defaultValue={record.chiSoCu}
    //         type="number"
    //         min={0}
    //       />
    //     </Form.Item>
    //   ),
    //   // <div className="table-text-right">{text}</div>,
    //   width: "20%",
    // },
    {
      title: "Chỉ số treo",
      key: "chiSoMoi",
      dataIndex: "chiSoMoi",
      render: (text, record) => (
        <Form.Item name={`chiSoMoiTreo-${record.bcs}`} style={{ margin: 0 }}>
          <Input
            placeholder="Nhập chỉ số treo"
            onChange={(e) => handleChangeInput(e, "chiSoMoi", record)}
            defaultValue={record.chiSoMoi}
            // type="number"
            // min={0}
            style={{ textAlign: "right" }}
          />
        </Form.Item>
      ),
      // <div className="table-text-right">{text}</div>,
      width: "35%",
    },
    // {
    //   title: "Sản lượng",
    //   key: "sanLuongMoi",
    //   dataIndex: "sanLuongMoi",
    //   render: (text, record, index) => (
    //     <Form.Item
    //       name={
    //         isTreo ? `sanLuongMoiTreo-${index}` : `sanLuongMoiThao-${index}`
    //       }
    //     >
    //       <Input
    //         placeholder="Nhập sản lượng mới"
    //         defaultValue={record.sanLuongMoi}
    //         readOnly
    //       />
    //     </Form.Item>
    //   ),
    //   // <div className="table-text-right">{text}</div>,
    //   width: "20%",
    // },
    {
      title: "Ảnh",
      key: "filePath",
      align: "center",
      dataIndex: "filePath",
      render: (_, record) => (
        <Fragment>
          {record.image.filePath === null ||
          record.image.filePath === "" ||
          record.image.filePath === undefined ? (
            <Tooltip title="Không có ảnh">
              <EyeInvisibleOutlined style={{ color: "#595959" }} />
            </Tooltip>
          ) : (
            <Tooltip title="Xem ảnh">
              <EyeOutlined
                onClick={() => handleOpenPreviewImage(record)}
                style={{ color: "#0000FF" }}
              />
            </Tooltip>
          )}
        </Fragment>
      ),
      width: "30%",
    },
  ];

  const columnsThao = [
    {
      title: "Bộ chỉ số",
      key: "bcs",
      dataIndex: "bcs",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "20%",
    },
    {
      title: "Chỉ số cũ",
      key: "chiSoCu",
      dataIndex: "chiSoCu",
      render: (text) => (
        <div className="table-text-right">{text}</div>
        // <Form.Item name={`chiSoCuThao-${index}`}>
        //   <Input
        //     placeholder="Nhập chỉ số cũ"
        //     onChange={(e) => handleChangeInput(e, "chiSoCu", index)}
        //     defaultValue={record.chiSoCu}
        //     type="number"
        //     min={0}
        //     style={{ textAlign: "right" }}
        //   />
        // </Form.Item>
      ),
      // <div className="table-text-right">{text}</div>,
      width: "20%",
    },
    {
      title: "Chỉ số mới",
      key: "chiSoMoi",
      dataIndex: "chiSoMoi",
      render: (text, record) => (
        <Form.Item name={`chiSoMoiThao-${record.bcs}`} style={{ margin: 0 }}>
          <Input
            placeholder="Nhập chỉ số mới"
            onChange={(e) => handleChangeInput(e, "chiSoMoi", record)}
            defaultValue={record.chiSoMoi}
            // type="number"
            // min={0}
            style={{ textAlign: "right" }}
          />
        </Form.Item>
      ),
      // <div className="table-text-right">{text}</div>,
      width: "20%",
    },
    {
      title: "Sản lượng",
      key: "sanLuongMoi",
      dataIndex: "sanLuongMoi",
      render: (text, record) => (
        <Form.Item name={`sanLuongMoiThao-${record.bcs}`} style={{ margin: 0 }}>
          <Input
            placeholder="Nhập sản lượng mới"
            defaultValue={record.sanLuongMoi}
            readOnly
            style={{ textAlign: "right" }}
          />
        </Form.Item>
      ),
      // <div className="table-text-right">{text}</div>,
      width: "20%",
    },
    {
      title: "Ảnh",
      key: "filePath",
      align: "center",
      dataIndex: "filePath",
      render: (_, record) => (
        <Fragment>
          {record.image && record.image.filePath !== null ? (
            <Tooltip title="Xem ảnh">
              <EyeOutlined
                onClick={() => handleOpenPreviewImage(record)}
                style={{ color: "#0000FF" }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Không có ảnh">
              <EyeInvisibleOutlined style={{ color: "#595959" }} />
            </Tooltip>
          )}
        </Fragment>
      ),
      width: "20%",
    },
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [srcImg, setSrcImg] = useState("");
  const handleOpenPreviewImage = useCallback(
    (record) => {
      if (record.image && record.image.filePath) {
        setIsOpen(!isOpen);
        setSrcImg(record?.image.filePath);
      }
    },
    [isOpen]
  );

  const showPreviewImage = useCallback(() => {
    return (
      isOpen && (
        <PreviewImage srcImg={srcImg} isOpen={isOpen} setIsOpen={setIsOpen} />
      )
    );
  }, [isOpen, srcImg]);

  const renderTable = useCallback(() => {
    let newData = [...data];
    const arr = [];
    if (newData.length > 0) {
      newData.forEach((item, index) => {
        return arr.push({
          ...item,
          stt: index + 1,
        });
      });
      newData = arr;
    }
    return (
      <Table
        dataSource={newData}
        columns={isTreo ? columnsTreo : columnsThao}
        pagination={false}
        scroll={{ y: 300 }}
        rowKey={(obj) => obj.stt}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Fragment>
      <Row gutter={24} style={{ paddingTop: "12px" }}>
        <Col span={24} md={4} style={{ paddingBottom: "12px" }}>
          <h6 className="fs-14px c-t-label">Mã công tơ</h6>
        </Col>
        <Col span={24} md={20}>
          <h5 className="fs-16px font-bold">{maCongTo}</h5>
        </Col>
        <Col span={24} md={24}>
          {renderTable()}
        </Col>
      </Row>
      {showPreviewImage()}
    </Fragment>
  );
}
