import { authSelector } from "@state/auth";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { useLocation, useSearchParams } from "react-router-dom";
// import { handleListBaoCao } from "./services";
// import { Table, Form } from "antd";
// import Text from "antd/lib/typography/Text";
import BaoCaoPhatTrienTheoLoTable from "./ui-bao-cao";
import { handleMultiBoLoc } from "./services";
import moment from "moment";
import {
  FORMAT_ENGLISH,
  MONTH_STRING,
  TYPE_CHANGE_DATE,
} from "@utils/constants";

export default function Index() {
  // const [, setSearchParams] = useSearchParams();
  // const [form] = Form.useForm();
  // const location = useLocation();
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState([]);
  // const [totalCount, setTotalCount] = useState(0);
  const { user } = useSelector(authSelector);
  const [donVi, setDonVi] = useState([]);
  // const [maDonVi, setMaDonVi] = useState("");
  // //filter
  // const [filterConditions, setFilterConditions] = useState({
  //   // pageIndex: DEFAULT_PAGEINDEX,
  //   // pageSize: DEFAULT_PAGESIZE,
  //   ...parseParams(location.search),
  //   donViId: user.unitId,
  // });

  // useLayoutEffect(() => {
  //   setSearchParams(removeUndefinedAttribute(filterConditions));
  // }, [setSearchParams, filterConditions]);

  // //change pagination
  // const onChangePagination = (paging, filters, sorter) => {
  //   handlePagination(paging, sorter, setFilterConditions);
  // };

  // //fetch báo cáo
  // const getListBaoCao = useCallback(() => {
  //   handleListBaoCao(setLoading, setData, setTotalCount, filterConditions);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

  // //search
  // const handleSearch = useCallback((values) => {
  //   setFilterConditions((oldState) => ({
  //     ...oldState,
  //     // pageIndex: DEFAULT_PAGEINDEX,
  //     ...values,
  //     donViId: values.donViId,
  //     tuNgay: formatSplitDate(values.tuNgay, true),
  //     denNgay: formatSplitDate(values.denNgay, true),
  //   }));
  // }, []);

  // //clear search
  // const clearFilter = useCallback(() => {
  //   setFilterConditions({
  //     // pageIndex: DEFAULT_PAGEINDEX,
  //     // pageSize: DEFAULT_PAGESIZE,
  //     donViId: user.unitId,
  //   });
  // }, [user.unitId]);

  // useEffect(() => {
  //   getListBaoCao();
  // }, [getListBaoCao]);

  // const columns = [
  //   {
  //     title: "STT",
  //     dataIndex: "stt",
  //     key: "stt",
  //     render: (text) => (
  //       <div className="table-text-center">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: "6%",
  //   },
  //   {
  //     title: "Đơn vị",
  //     dataIndex: "maDonViQuanLy",
  //     key: "maDonViQuanLy",
  //     render: (text) => (
  //       <div className="table-text-left">
  //         <span>{text}</span>
  //       </div>
  //     ),
  //     width: "18%",
  //   },
  //   {
  //     title: `Thông tin đã phát triển khách hàng từ tháng  ${
  //       filterConditions.tuNgay ? filterConditions.tuNgay.split("-")[1] : "..."
  //     } năm ${
  //       filterConditions.tuNgay ? filterConditions.tuNgay.split("-")[0] : "..."
  //     } đến tháng ${
  //       filterConditions.denNgay
  //         ? filterConditions.denNgay.split("-")[1]
  //         : "..."
  //     } Năm ${
  //       filterConditions.denNgay
  //         ? filterConditions.denNgay.split("-")[0]
  //         : "..."
  //     }`,
  //     children: [
  //       {
  //         title: "Số lượng khách hàng tại file dữ liệu",
  //         dataIndex: "soLuongKhachHangFile",
  //         key: "soLuongKhachHangFile",
  //         render: (text) => (
  //           <div className="table-text-right">
  //             <span>{text}</span>
  //           </div>
  //         ),
  //       },
  //       {
  //         title: "Số lượng sổ ghi chỉ số phục vụ phát triển mới",
  //         dataIndex: "soLuongSoGCSFile",
  //         key: "soLuongSoGCSFile",
  //         render: (text) => (
  //           <div className="table-text-right">
  //             <span>{text}</span>
  //           </div>
  //         ),
  //       },
  //       {
  //         title: "Số lượng khu vực phát triển mới khách hàng",
  //         dataIndex: "soLuongKhuVucPhatTrienFile",
  //         key: "soLuongKhuVucPhatTrienFile",
  //         render: (text) => (
  //           <div className="table-text-right">
  //             <span>{text}</span>
  //           </div>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     title: "Số liệu đã thực hiện trên chương trình",
  //     children: [
  //       {
  //         title: "Ngày thực hiện",
  //         dataIndex: "ngayTao",
  //         key: "ngayTao",
  //         render: (text) => (
  //           <div className="table-text-right">
  //             <span>{text}</span>
  //           </div>
  //         ),
  //       },
  //       {
  //         title: "Số lượng sổ ghi chỉ số đã thực hiện",
  //         dataIndex: "soLuongSoGCSDaThucHien",
  //         key: "soLuongSoGCSDaThucHien",
  //         render: (text) => (
  //           <div className="table-text-right">
  //             <span>{text}</span>
  //           </div>
  //         ),
  //       },
  //       {
  //         title: "Số lượng khách hàng đã phát triển trong CMIS",
  //         dataIndex: "soLuongKhachHangDaPhatTrien",
  //         key: "soLuongKhachHangDaPhatTrien",
  //         render: (text) => (
  //           <div className="table-text-right">
  //             <span>{text}</span>
  //           </div>
  //         ),
  //       },
  //       {
  //         title: "Số lượng khách hàng lỗi không đẩy được vào CMIS",
  //         dataIndex: "soLuongKhachHangLoi",
  //         key: "soLuongKhachHangLoi",
  //         render: (text) => (
  //           <div className="table-text-right">
  //             <span>{text}</span>
  //           </div>
  //         ),
  //       },
  //     ],
  //   },
  // ];

  // // const handleTuThang = useCallback(
  // //   (value) => {
  // //     setTuThang(value);
  // //   },
  // //   // eslint-disable-next-line
  // //   [tuThang]
  // // );

  // // const handleDenThang = useCallback(
  // //   (value) => {
  // //     setDenThang(value);
  // //   },
  // //   // eslint-disable-next-line
  // //   [denThang]
  // // );

  // //footer total count table
  // const countTotals = (pageData) => {
  //   let countFile = 0;
  //   let countPhatTrienMoi = 0;
  //   let countKhucVucPhatTrien = 0;
  //   const countNgayThucHien = 0;
  //   let countGCS = 0;
  //   let countPhatTrienCMIS = 0;
  //   // let countKhongCMIS = 0;
  //   pageData.forEach(
  //     ({
  //       soLuongKhachHangFile,
  //       soLuongSoGCSFile,
  //       soLuongKhuVucPhatTrienFile,
  //       // ngayTao,
  //       soLuongSoGCSDaThucHien,
  //       soLuongKhachHangDaPhatTrien,
  //       // soLuongKhachHangLoi,
  //       // sLKHKhongPhatTrienCMIS,
  //     }) => {
  //       countFile += soLuongKhachHangFile;
  //       countPhatTrienMoi += soLuongSoGCSFile;
  //       countKhucVucPhatTrien += soLuongKhuVucPhatTrienFile;
  //       // countNgayThucHien += ngayThucHien;
  //       countGCS += soLuongSoGCSDaThucHien;
  //       countPhatTrienCMIS += soLuongKhachHangDaPhatTrien;
  //       // countKhongCMIS += sLKHKhongPhatTrienCMIS;
  //     }
  //   );
  //   return (
  //     <Table.Summary fixed>
  //       <Table.Summary.Row style={{ backgroundColor: "#FFEAEA" }}>
  //         <Table.Summary.Cell align="center" colSpan={2} index={0}>
  //           <Text className="font-bold">Tổng cộng</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={1}>
  //           <Text className="font-bold">{countFile}</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={2}>
  //           <Text className="font-bold">{countPhatTrienMoi}</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={3}>
  //           <Text className="font-bold">{countKhucVucPhatTrien}</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={4}>
  //           <Text className="font-bold">{countNgayThucHien}</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={5}>
  //           <Text className="font-bold">{countGCS}</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={6}>
  //           <Text className="font-bold">{countPhatTrienCMIS}</Text>
  //         </Table.Summary.Cell>
  //         <Table.Summary.Cell align="right" index={7}>
  //           <Text className="font-bold">{countFile - countPhatTrienCMIS}</Text>
  //         </Table.Summary.Cell>
  //       </Table.Summary.Row>
  //     </Table.Summary>
  //   );
  // };

  // const noteColumns = [
  //   {
  //     title: "",
  //     // fixed: true,
  //   },
  //   {
  //     title: "",
  //     // fixed: true,
  //   },
  //   {
  //     title: "(1)",
  //     // fixed: true,
  //     // last: true,
  //   },
  //   {
  //     title: "(2)",
  //   },
  //   {
  //     title: "(3)",
  //   },
  //   {
  //     title: "(4)",
  //   },
  //   {
  //     title: "(5)",
  //   },
  //   {
  //     title: "(6)",
  //   },
  //   {
  //     title: "(7) = (1-6)",
  //   },
  // ];

  const toDay = moment().format(FORMAT_ENGLISH);
  const [tuNgay, setTuNgay] = useState(toDay);
  const [denNgay, setDenNgay] = useState(toDay);
  const [thang, setThang] = useState(toDay);
  const [unit, setUnit] = useState(user.unitId);

  //change date
  const handleChangeNgay = (date, type) => {
    let convert = "";
    if (date === null) convert = undefined;
    else {
      if (type === TYPE_CHANGE_DATE.TUNGAY) {
        // convert = moment(date).startOf(MONTH_STRING).format(FORMAT_ENGLISH);
        convert = moment(date).format(FORMAT_ENGLISH);
        setTuNgay(convert);
      } else if (type === TYPE_CHANGE_DATE.DENNGAY) {
        // convert = moment(date).endOf(MONTH_STRING).format(FORMAT_ENGLISH);
        convert = moment(date).format(FORMAT_ENGLISH);
        setDenNgay(convert);
      } else {
        convert = moment(date).format(FORMAT_ENGLISH);
        setThang(convert);
      }
    }
  };

  //change unit
  const handleChangeUnit = (value) => {
    setUnit(value);
  };

  useEffect(() => {
    handleMultiBoLoc(setLoading, setDonVi);
  }, []);

  // useEffect(() => {
  //   if (donVi.length > 0) {
  //     const item = donVi.find((value) => value.id === unit);
  //     if (item !== null || item !== undefined) {
  //       setMaDonVi(item.maDonVi);
  //     }
  //   }
  // }, [donVi, unit]);

  return (
    <BaoCaoPhatTrienTheoLoTable
      // form={form}
      // handleSearch={handleSearch}
      // filterConditions={filterConditions}
      // clearFilter={clearFilter}
      // totalCount={totalCount}
      // loading={loading}
      // onChangePagination={onChangePagination}
      // columns={columns}
      // noteColumns={noteColumns}
      // data={data}
      // countTotals={countTotals}
      user={user}
      donVi={donVi}
      loading={loading}
      handleChangeNgay={handleChangeNgay}
      handleChangeUnit={handleChangeUnit}
      tuNgay={tuNgay}
      denNgay={denNgay}
      thang={thang}
      unit={unit}
      //maDonVi={maDonVi}
    />
  );
}
