import React, { useState, useCallback } from "react";
import { authPostFileData, authGetData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Input,
  Form,
  Button,
  Radio,
  Checkbox,
  Select,
  Spin,
  Switch,
} from "antd";
import FormComponent from "@components/Form";
import InputComponent from "@components/Input";
import Selection from "@components/Select";
import styled from "styled-components";
import { getErrorForm } from "@utils/function";
import { optionPageSize, STATUSCODE_200 } from "@utils/constants";
import ImageUpload from "@components/ImageUpload";
import DatePickerComponent from "@components/DatePicker";
import moment from "moment";
const initialValues = {
  userName: undefined,
  unitId: undefined,
  name: undefined,
  cmisCode: undefined,
  positionId: undefined,
  gender: undefined,
  jobPosition: [],
  email: undefined,
  phoneNumber: undefined,
  password: undefined,
  confirmPassword: undefined,
  giayToTuyThan: undefined,
  ngayCap: null,
  noiCap: undefined
};

const { Option } = Select;

export default function CreateUserForm(props) {
  const { getUserList, setUserInfo } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [imageFile, setImageFile] = useState(null);
  // const [signature, setSignature] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ssoChecked, setSSOChecked] = useState(false);
  const [resultCheckSSO, setResultCheckSSO] = useState(false);
  const [useDefaultPassword, setUseDefaultPassword] = useState(false);
  const onFinish = useCallback(
    (values) => {
      authPostFileData({
        url: `${Endpoint.USER}`,
        method: "POST",
        setLoading,
        payload: {
          ...values,
          defaultPassword: useDefaultPassword,
          file: imageFile,
          sso: ssoChecked,
          // chuKy: signature,
        },
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            setUserInfo({
              ...res.data,
              ngayCap: res.data.ngayCap ? moment(res.data.ngayCap).format('DD/MM/YYYY') : res.data.ngayCap,
              jobPosition: res.data.jobPosition
                ? res.data.jobPosition.split(",")
                : [],
            });
            getUserList();
          } else {
            getErrorForm(res, form);
          }
        },
      });
    },
    [
      form,
      getUserList,
      imageFile,
      setUserInfo,
      // signature,
      ssoChecked,
      useDefaultPassword,
    ]
  );
  const checkSSO = useCallback(() => {
    const userName = form.getFieldValue("userName");
    if (userName) {
      authGetData({
        url: `${Endpoint.CHECK_SSO}?userName=${userName}`,
        setLoading,
        onSuccess: (res) => {
          if (res.data !== null) {
            form.setFieldsValue(res.data);
            setResultCheckSSO(true);
          }
        },
      });
    }
  }, [form]);
  const handleCheckSSO = useCallback(
    (event) => {
      const { checked } = event.target;
      setSSOChecked(checked);
      setUseDefaultPassword(checked);
      form.setFieldsValue({
        password: undefined,
        confirmPassword: undefined,
      });
    },
    [form]
  );
  const handleCheckUseDefaultPassword = useCallback(
    (event) => {
      const { checked } = event.target;
      setUseDefaultPassword(checked);
      form.setFieldsValue({
        password: undefined,
        confirmPassword: undefined,
      });
    },
    [form]
  );
  const handleChangeUnit = useCallback(() => {
    form.setFieldsValue({
      teamId: undefined,
    });
  }, [form]);
  const handleChangeUserName = useCallback(
    (value) => {
      if (ssoChecked && resultCheckSSO) {
        form.setFieldsValue({ ...initialValues, userName: value });
        setResultCheckSSO(false);
      }
    },
    [form, ssoChecked, resultCheckSSO]
  );

  return (
    <Spin spinning={loading}>
      <FormComponent
        layout="vertical"
        onFinish={onFinish}
        form={form}
        initialValues={{
          initialValues,
          pageSize: 20,
        }}
      >
        <Row>
          <Col span={3}>
            <Form.Item name="avatar" label="Ảnh đại diện">
              <ImageUpload setImageFile={setImageFile} />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item name="chuky" label="Chữ ký">
              <ImageUpload setImageFile={setSignature} />
            </Form.Item>
          </Col> */}
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="userName" label="Tên đăng nhập" required>
              <InputComponent
                type="text"
                size="default"
                setValue={handleChangeUserName}
                form={form}
                formKey="userName"
                placeholder="Nhập tên đăng nhập"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <SSOContainer>
              <div className="sso-checkbox">
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.userName !== currentValues.userName
                  }
                >
                  {({ getFieldValue }) => {
                    return (
                      <Checkbox
                        onChange={handleCheckSSO}
                        checked={ssoChecked}
                        disabled={!getFieldValue("userName") || resultCheckSSO}
                      />
                    );
                  }}
                </Form.Item>

                <span>SSO</span>
              </div>

              <Button
                type="danger"
                size="small"
                disabled={!ssoChecked || resultCheckSSO}
                onClick={checkSSO}
              >
                Kiểm tra
              </Button>
            </SSOContainer>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="unitId" label="Đơn vị" required>
              <Selection
                url={Endpoint.GET_UNIT_LIST}
                formKey="unitId"
                form={form}
                setValue={handleChangeUnit}
                placeholder="Chọn đơn vị"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.unitId !== currentValues.unitId
              }
            >
              {({ getFieldValue }) => {
                if (getFieldValue("unitId")) {
                  return (
                    <Form.Item name="teamId" label="Tổ/Đội">
                      <Selection
                        url={`${Endpoint.GET_TEAM_LIST}?donviId=${getFieldValue(
                          "unitId"
                        )}`}
                        formKey="teamId"
                        form={form}
                        disabled={!getFieldValue("unitId")}
                        placeholder="Nhập Tổ/Đội"
                      />
                    </Form.Item>
                  );
                }
                return (
                  <Form.Item name="teamId" label="Tổ/Đội">
                    <Select disabled />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="name" label="Tên đầy đủ" required>
              <InputComponent
                type="text"
                size="default"
                disabled={ssoChecked}
                form={form}
                formKey="name"
                placeholder="Nhập tên đầy đủ"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="cmisCode" label="Mã nhân viên (CMIS)">
              <Input placeholder="Mã nhân viên" formKey="cmisCode"/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="positionId" label="Chức vụ" required>
              <Selection
                url={Endpoint.GET_POSITION_LIST}
                placeholder="Chọn chức vụ"
                formKey="positionId"
                form={form}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="gender" label="Giới tính">
              <Radio.Group disabled={ssoChecked}>
                <Radio value={1}>Nam</Radio>
                <Radio value={2}>Nữ</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item name="jobPosition" label="Vị trí công việc">
              <Selection
                url={Endpoint.GET_VI_TRI_CONG_VIEC}
                formKey="jobPosition"
                form={form}
                placeholder="Chọn vị trí công việc"
                mode="multiple"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="email" label="Email">
              <InputComponent
                type="text"
                size="default"
                disabled={ssoChecked}
                form={form}
                formKey="email"
                placeholder="Thêm Email"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="phoneNumber" label="Điện thoại">
              <InputComponent
                type="text"
                size="default"
                disabled={ssoChecked}
                form={form}
                formKey="phoneNumber"
                placeholder="Số điện thoại"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="giayToTuyThan" label="Số CMND/CCCD/Hộ chiếu">
              <InputComponent type="text" size="default" form={form} formKey="giayToTuyThan" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="ngayCap" label="Ngày cấp">
              <DatePickerComponent
                defaultValue={null}
                format={"YYYY-MM-DD"}
                form={form}
                formKey={"ngayCap"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="noiCap" label="Nơi cấp">
              <InputComponent type="text" size="default" form={form} formKey="noiCap" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="default_password" label="Mật khẩu mặc định">
              <Checkbox
                onChange={handleCheckUseDefaultPassword}
                checked={useDefaultPassword}
                disabled={ssoChecked}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="password" label="Mật khẩu" required>
              <Input.Password
                size="default"
                disabled={useDefaultPassword}
                autoComplete="new-password"
                placeholder="Nhập mật khẩu"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="confirmPassword"
              label="Xác nhận mật khẩu"
              required
            >
              <Input.Password
                size="default"
                disabled={useDefaultPassword}
                placeholder="Xác nhận mật khẩu"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name="pageSize"
              label="Cấu hình hiển thị số trang của bảng"
            >
              <Select allowClear={false}>
                {optionPageSize.map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Trạng thái" valuePropName="checked">
              <Switch
                defaultChecked={true}
                disabled={true}
                checkedChildren="Hoạt động"
                unCheckedChildren="Khóa"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} justify="center">
          <Col span={4}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="full"
              disabled={ssoChecked && !resultCheckSSO}
            >
              {t("Tạo")}
            </Button>
          </Col>
        </Row>
      </FormComponent>
    </Spin>
  );
}

const SSOContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  .sso-checkbox {
    margin-right: 20px;
    > span {
      margin-left: 10px;
    }
  }
`;
