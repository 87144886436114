import { STATUSCODE_200 } from "@utils/constants";
import { authGetData } from "@utils/request";

export const handleXemBienBan = (url, setLoading, onComplete) => {
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        onComplete(res.data);
      } else {
        onComplete("error");
      }
    },
  });
};

export const handleGetData = (url, setLoading, setData) => {
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setData(res.data);
      }
    },
  });
};
