import FormComponent from "@components/Form";
import { buildQueryString, removeAccents } from "@utils/function";
import { Button, Col, DatePicker, Form, Row, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  getDanhSachNhanVien,
  getListDoi,
  getListDonVi,
  handleDownload,
} from "./quan-ly-lo-trinh-services";
import moment from "moment";
import CheckPermission from "@components/CheckPermission";
import { lo_trinh_nhan_vien } from "@permissions/g_lotrinhnhanvien";

export default function QuanLyLoTrinhFilter(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    form,
    donVi,
    setDonVi,
    doiId,
    setDoiId,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = props;
  const unitId = useSelector((state) => state.auth.user.unitId);
  const teamId = useSelector((state) => state.auth.user.teamId);
  const permissionCode = lo_trinh_nhan_vien.g12bc;

  const [loading, setLoading] = useState(false);
  const [listDonVi, setListDonVi] = useState([]);
  const [listDoi, setListDoi] = useState([]);
  const [danhSachNhanVien, setDanhSachNhanVien] = useState([]);

  // Get danh sach don vi
  useEffect(() => {
    getListDonVi(setLoading, setListDonVi);
  }, []);

  // Get ds doi
  useEffect(() => {
    getListDoi(setLoading, setListDoi, donVi);
  }, [donVi]);

  useEffect(() => {
    const payload = {
      DonViId: donVi,
      DoiId: doiId,
    };

    const query = buildQueryString(payload);
    getDanhSachNhanVien(setLoading, setDanhSachNhanVien, query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doiId, donVi]);

  useEffect(() => {
    form.setFieldsValue({
      ...filterConditions,
      TuNgay: moment(new Date(filterConditions.TuNgay)),
      DenNgay: moment(new Date(filterConditions.DenNgay)),
    });
  }, [form, filterConditions]);

  const handleChangeDonViId = (value) => {
    setDonVi(value);
    form.setFieldsValue({
      DoiId: undefined,
      NhanVien: undefined,
    });
  };

  const handleChangeDoiId = (value) => {
    setDoiId(value);
    form.setFieldsValue({
      NhanVien: undefined,
    });
  };

  const handleChangeStartDate = (date) => {
    setStartDate(`${date.format("YYYY-MM-DD")}`);
  };
  const handleChangeEndDate = (date) => {
    setEndDate(`${date.format("YYYY-MM-DD")}`);
  };

  const clear = useCallback(() => {
    form.resetFields();
    form.setFieldsValue({
      TuNgay: moment().startOf("month"),
      DenNgay: moment().endOf("month"),
      DoiId: teamId ? teamId : undefined,
    });
    setDonVi(unitId);
    setDoiId(teamId);
    setDanhSachNhanVien([]);

    setStartDate(`${moment().startOf("month").format("YYYY-MM-DD")}`);
    setEndDate(`${moment().endOf("month").format("YYYY-MM-DD")}`);

    clearFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFilter, form]);

  const baoCaoViTriNhanVien = () => {
    const payload = {
      DonViId: form.getFieldValue("DonViId"),
      DoiId: form.getFieldValue("DoiId"),
      NhanVien: form.getFieldValue("NhanVien"),
      TuNgay: startDate,
      DenNgay: endDate,
    };

    const query = buildQueryString(payload);
    handleDownload(setLoading, query);
  };

  return (
    <Styled>
      <div className="quan-ly-lo-trinh-list-bo-loc">
        <FormComponent
          layout="vertical"
          form={form}
          onFinish={handleSearch}
          initialValues={{
            DonViId: unitId,
            DoiId: doiId ? doiId : undefined,
          }}
        >
          <Row gutter={[8, 4]}>
            <Col span={6}>
              <Form.Item name="DonViId" label="Đơn vị">
                <Select
                  onChange={handleChangeDonViId}
                  loading={loading}
                  showSearch
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                  disabled={listDonVi.length < 2}
                >
                  {listDonVi &&
                    listDonVi.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name="DoiId" label="Tổ đội">
                <Select
                  onChange={handleChangeDoiId}
                  allowClear
                  showSearch
                  loading={loading}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                  disabled={listDoi.length === 1}
                >
                  {listDoi &&
                    listDoi.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="NhanVien" label="Nhân viên">
                <Select
                  maxTagCount={1}
                  mode="multiple"
                  allowClear
                  loading={loading}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {danhSachNhanVien &&
                    danhSachNhanVien.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Form.Item name="TuNgay" label="Từ ngày">
                <DatePicker
                  onChange={handleChangeStartDate}
                  allowClear={false}
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="DenNgay" label="Đến ngày">
                <DatePicker
                  onChange={handleChangeEndDate}
                  allowClear={false}
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>

            <Col span={6} className="quan-ly-lo-trinh-list-bo-loc-button">
              <Button onClick={clear}>Bỏ lọc</Button>
              <Button type="primary" htmlType="submit">
                Tìm kiếm
              </Button>

              <CheckPermission permissionCode={permissionCode}>
                <Button type="primary" onClick={baoCaoViTriNhanVien}>
                  Báo cáo vị trí nhân viên
                </Button>
              </CheckPermission>
            </Col>
          </Row>
        </FormComponent>
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  .quan-ly-lo-trinh-list-bo-loc {
    .quan-ly-lo-trinh-list-bo-loc-button {
      display: flex;
      gap: 20px;
      align-items: center;
      padding-top: 5px;
    }
  }
`;
