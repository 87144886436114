import React, {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useEffect
} from "react";
import Filter from "@components/Filter/Filter";
import { Button, Row, Col, Form, Select, notification } from "antd";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE, FORMAT_ENGLISH, MONTH_STRING } from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
  customColumn
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilter } from "./list-bo-loc";
import CheckPermission from "@components/CheckPermission";
import { chi_so_cong_to } from "@permissions/i_chisocongto";
import { downLoadBaoCao, handleListDataBaoCao, submitOpenPdf, } from "./services";
import { getListDonViService, getBienBanWebService } from "../common/services";
import "moment/locale/vi";
import moment from "moment";
import { TableStyled, Container } from "./css-styled";
import TableComponent from "@components/TableComponent";
const { Option } = Select;

export default function Index() {
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [dataBaoCao, setDataBaoCao] = useState([]);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingBB, setLoadingBB] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const [ngayBatDau, setNgayBatDau] = useState(moment().startOf(MONTH_STRING).format(FORMAT_ENGLISH));
  const [ngayKetThuc, setNgayKetThuc] = useState(moment().format(FORMAT_ENGLISH));
  const [rangeDate, setRangeDate] = useState([]);
  const [tenTT, setTenTT] = useState();
  const [dataDonVi, setDataDonVi] = useState([]);
  const [dataBienBan, setDataBienban] = useState([]);
  const [bienBan, setBienban] = useState();
  const location = useLocation();

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    NgayBatDau: moment().startOf(MONTH_STRING).format(FORMAT_ENGLISH),
    NgayKetThuc: moment().format(FORMAT_ENGLISH),
    ...parseParams(location.search),
  });

  const [filterBB, setFilterBB] = useState({
    DonViId: user.unitId,
    NgayBatDau: moment().startOf(MONTH_STRING).format(FORMAT_ENGLISH),
    NgayKetThuc: moment().format(FORMAT_ENGLISH),
    IsDongBoLenCmis: "1"
  });

  useEffect(() => {
    if (parseParams(location.search) && Object.keys(parseParams(location.search)).length === 0) {
      setRangeDate([moment().startOf(MONTH_STRING), moment()]);
      setNgayBatDau(filterConditions.NgayBatDau);
      setNgayKetThuc(filterConditions.NgayKetThuc);
    } else if (parseParams(location.search).NgayBatDau === undefined) {
      setRangeDate([null, null]);
      setNgayBatDau();
      setNgayKetThuc();
    } else {
      setRangeDate([moment(filterConditions.NgayBatDau), moment(filterConditions.NgayKetThuc)]);
      setNgayBatDau(filterConditions.NgayBatDau);
      setNgayKetThuc(filterConditions.NgayKetThuc);
      setFilterBB((oldState) => ({
        ...oldState,
        NgayBatDau: filterConditions.NgayBatDau,
        NgayKetThuc: filterConditions.NgayKetThuc,
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    getListDonVi();
  }, []);

  const getBienBan = useCallback(() => {
    getBienBanWebService(setLoadingBB, location, setDataBienban, filterBB);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBB]);

  useEffect(() => {
    getBienBan()
  }, [getBienBan]);

  const getListDonVi = () => {
    getListDonViService(setLoading, setDataDonVi);
  };

  const handleChangeDonVi = useCallback((values) => {
    setFilterBB((oldState) => ({
      ...oldState,
      DonViId: values,
      NgayBatDau: ngayBatDau,
      NgayKetThuc: ngayKetThuc,
    }));
    setBienban();
  }, [ngayBatDau, ngayKetThuc]);

  const handleChangeBienBan = useCallback((values) => {
    setBienban(values);
  }, []);

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const handleChangeRange = useCallback((values) => {
    if (values !== null && moment(values[0]).isValid() && moment(values[1]).isValid()) {
      setNgayBatDau(moment(values[0]).format(FORMAT_ENGLISH));
      setNgayKetThuc(moment(values[1]).format(FORMAT_ENGLISH));
      setRangeDate(values);
      setFilterBB((oldState) => ({
        ...oldState,
        NgayBatDau: moment(values[0]).format(FORMAT_ENGLISH),
        NgayKetThuc: moment(values[1]).format(FORMAT_ENGLISH),
      }));
      setBienban();
    } else {
      setNgayBatDau();
      setNgayKetThuc();
      setRangeDate([]);
      setFilterBB((oldState) => ({
        ...oldState,
        NgayBatDau: null,
        NgayKetThuc: null,
      }));
      setBienban();
    }
  }, []);

  const handleSearch = useCallback((values) => {
    if (!bienBan) {
      notification.error({
        message: `Thông báo`,
        description: "Số biên bản không được để trống",
      });
    } else {
      setFilterConditions((oldState) => ({
        ...oldState,
        ...values,
        NgayBatDau: ngayBatDau,
        NgayKetThuc: ngayKetThuc,
        MaBienBan: bienBan
      }));
    }

  }, [ngayBatDau, ngayKetThuc, bienBan]);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      DonViId: user.unitId,
      NgayBatDau: moment().startOf(MONTH_STRING).format(FORMAT_ENGLISH),
      NgayKetThuc: moment().format(FORMAT_ENGLISH),
    });
    setFilterBB((oldState) => ({
      ...oldState,
      DonViId: user.unitId,
      NgayBatDau: moment().startOf(MONTH_STRING).format(FORMAT_ENGLISH),
      NgayKetThuc: moment().format(FORMAT_ENGLISH),
    }));
    setRangeDate([moment().startOf(MONTH_STRING), moment()]);
    setDataBaoCao([]);
    setBienban();
  }, [user.unitId]);

  // search list
  const getListDataBaoCao = useCallback(() => {
    handleListDataBaoCao(
      setLoading,
      location,
      setDataBaoCao,
      setTotalCount,
      filterConditions,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    bienBan &&
      getListDataBaoCao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListDataBaoCao, user]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListDataBaoCao();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListDataBaoCao, setFetchDataTable]);

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      render: (a, b, index) => {
        return (
          (filterConditions.pageIndex - 1) * filterConditions.pageSize +
          (index + 1)
        );
      },
      className: "table-text-right",
      width: "3%",
      fixed: "left"
    },
    {
      title: "Mã công tơ",
      dataIndex: "maThietBi",
      key: "maThietBi",
      render: (text) => <span>{text}</span>,
      width: "6%",
      fixed: "left"
    },
    {
      title: "Số công tơ",
      dataIndex: "soThietBi",
      key: "soThietBi",
      render: (text) => <span>{text}</span>,
      width: "5%",
    },
    {
      title: "Số pha",
      dataIndex: "soPha",
      key: "soPha",
      render: (text) => <span>{text}</span>,
      width: "3%",
    },
    {
      title: "TI",
      dataIndex: "ti",
      key: "ti",
      render: (text) => <span>{text}</span>,
      width: "3%",
    },
    {
      title: "TU",
      dataIndex: "tu",
      key: "tu",
      render: (text) => <span>{text}</span>,
      width: "3%",
    },
    {
      title: "HSN",
      dataIndex: "heSoNhan",
      key: "heSoNhan",
      className: "table-text-right",
      render: (text) => <span>{text}</span>,
      width: "3%",
    },
    {
      title: "Tổng P giao",
      dataIndex: "tongPGiao",
      key: "tongPGiao",
      className: "table-text-right",
      render: (text, record) => (
        record.chiSoHES.filter(boCS => boCS.bcs === "KT").length === 0 ?
          record.chiSoHES.filter(boCS => boCS.bcs === "SG")[0]?.chiSoX5 :
          record.chiSoHES.filter(boCS => boCS.bcs === "KT")[0]?.chiSoX5
      ),
      width: "5%",
    },
    {
      title: "Tổng Q giao",
      dataIndex: "tongQgiao",
      key: "tongQgiao",
      className: "table-text-right",
      render: (text, record) => (
        record.chiSoHES.filter(boCS => boCS.bcs === "VC")[0]?.chiSoX5
      ),
      width: "5%",
    },
    {
      title: "Biểu 1",
      dataIndex: "",
      key: "",
      children: [
        {
          title: "Giao",
          dataIndex: "giao",
          key: "giao",
          className: "table-text-right",
          render: (text, record) => (
            record.chiSoHES.filter(boCS => boCS.bcs === "BT")[0]?.chiSoX5
          ),
          width: "3%",
        },
        {
          title: "Nhận",
          dataIndex: "nhan",
          key: "nhan",
          className: "table-text-right",
          render: (text, record) => (
            record.chiSoHES.filter(boCS => boCS.bcs === "BN")[0]?.chiSoX5
          ),
          width: "3%",
        },
      ],
    },
    {
      title: "Biểu 2",
      dataIndex: "",
      key: "",
      children: [
        {
          title: "Giao",
          dataIndex: "giao",
          key: "giao",
          className: "table-text-right",
          render: (text, record) => (
            record.chiSoHES.filter(boCS => boCS.bcs === "CD")[0]?.chiSoX5
          ),
          width: "3%",
        },
        {
          title: "Nhận",
          dataIndex: "nhan",
          key: "nhan",
          className: "table-text-right",
          render: (text, record) => (
            record.chiSoHES.filter(boCS => boCS.bcs === "CN")[0]?.chiSoX5
          ),
          width: "3%",
        },
      ],
    },
    {
      title: "Biểu 3",
      dataIndex: "",
      key: "",
      children: [
        {
          title: "Giao",
          dataIndex: "giao",
          key: "giao",
          className: "table-text-right",
          render: (text, record) => (
            record.chiSoHES.filter(boCS => boCS.bcs === "TD")[0]?.chiSoX5
          ),
          width: "3%",
        },
        {
          title: "Nhận",
          dataIndex: "nhan",
          key: "nhan",
          className: "table-text-right",
          render: (text, record) => (
            record.chiSoHES.filter(boCS => boCS.bcs === "TN")[0]?.chiSoX5
          ),
          width: "3%",
        },
      ],
    },
    {
      title: "Tổng P nhận",
      dataIndex: "tongPNhan",
      key: "tongPNhan",
      className: "table-text-right",
      render: (text, record) => (
        record.chiSoHES.filter(boCS => boCS.bcs === "SN").length === 0 ?
          record.chiSoHES.filter(boCS => boCS.bcs === "KN")[0]?.chiSoX5 :
          record.chiSoHES.filter(boCS => boCS.bcs === "SN")[0]?.chiSoX5
      ),
      width: "5%",
    },
    {
      title: "Tổng Q nhận",
      dataIndex: "tongQNhan",
      key: "tongQNhan",
      className: "table-text-right",
      render: (text, record) => (
        record.chiSoHES.filter(boCS => boCS.bcs === "VN")[0]?.chiSoX5
      ),
      width: "5%",
    },
    {
      title: "Trạng thái",
      dataIndex: "ketQuaGiaoNhan",
      key: "ketQuaGiaoNhan",
      className: "table-text-center",
      render: (text, record) => (
        record.ketQuaGiaoNhan === "Không đạt" ?
          <div style={{ fontWeight: "bold", color: "red" }} className="table-text-center">{record.ketQuaGiaoNhan}</div> :
          record.isSelected.toString() === "Đạt" ?
            <div style={{ fontWeight: "bold", color: "#1915eb" }} className="table-text-center">{record.ketQuaGiaoNhan}</div> :
            <div style={{ fontWeight: "bold"}} className="table-text-center">{record.ketQuaGiaoNhan}</div>
      ),
      width: "5%",
    },
    {
      title: "Tem CQ",
      dataIndex: "temCQ",
      key: "temCQ",
      render: (text, record) => (
        record.chiSoHES[0]?.temCQ
      ),
      width: "3%",
    },
    {
      title: "Chì niêm phong",
      dataIndex: "chiNiemPhong",
      key: "chiNiemPhong",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "4%",
    },
    {
      title: "Ghi chú",
      dataIndex: "ghiChu",
      key: "ghiChu",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      // width: "3%",
    },
  ];

  const handleOpenPDF = useCallback(() => {
    if (!bienBan) {
      notification.error({
        message: `Thông báo`,
        description: "Số biên bản không được để trống",
      });
    } else
    if (!tenTT) {
      notification.error({
        message: `Thông báo`,
        description: "Tên trung tâm không được để trống",
      });
    } else {
      submitOpenPdf(setLoading, location, filterConditions, tenTT);
    }

  }, [bienBan, tenTT, location, filterConditions]);

  const handleDownloadBaoCao = useCallback(() => {
    if (!bienBan) {
      notification.error({
        message: `Thông báo`,
        description: "Số biên bản không được để trống",
      });
    } else
    if (!tenTT) {
      notification.error({
        message: `Thông báo`,
        description: "Tên trung tâm không được để trống",
      });
    } else {
      downLoadBaoCao(setLoading, location, filterConditions, tenTT);
    }
  }, [bienBan, tenTT, location, filterConditions]);

  const handleChangeTT = useCallback((values) => {
    console.log(values);
    setTenTT(values)
  }, []);
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          changeRange={handleChangeRange}
          rangeDate={rangeDate}
          dataDonVi={dataDonVi}
          handleChangeDonVi={handleChangeDonVi}
          dataBienBan={dataBienBan}
          handleChangeBienBan={handleChangeBienBan}
          bienBan={bienBan}
          loadingBB={loadingBB}
        />
      </Filter>
      <Container>
        <Row style={{ marginBottom: 2 }}>
          <span style={{ marginTop: 6, marginRight: 10, marginLeft: 10 }}>
            Tên trung tâm
          </span>
          <Col span={24} md={8}>
            <Select style={{ width: 300 }} onChange={handleChangeTT} allowClear>
              <Option value="1">Trung tâm Sửa chữa Hiệu chỉnh</Option>
              <Option value="2">Trung tâm Kiểm định Hiệu chuẩn</Option>
            </Select>
          </Col>
        </Row>
        <CheckPermission permissionCode={chi_so_cong_to.i3xp}>
          <Button
            className="btn-xp"
            type="primary"
            style={{ marginRight: 10, float: "right", marginTop: -34 }}
            onClick={() => handleOpenPDF()}
          >
            Xuất PDF
          </Button>
        </CheckPermission>
        <CheckPermission permissionCode={chi_so_cong_to.i3xe}>
          <Button
            className="btn-xe"
            type="primary"
            style={{ marginRight: 115, float: "right", marginTop: -34 }}
            onClick={() => handleDownloadBaoCao()}
          >
            Xuất Excel
          </Button>
        </CheckPermission>
        <TableStyled>
          <TableComponent
            header={"Báo cáo nhập quay vòng công tơ điện tử"}
            dataSource={dataBaoCao}
            listFilter={<ListFilter form={form} />}
            columns={customColumn(columns, filterConditions)}
            totalData={totalCount}
            loading={loading}
            scrollX={2500}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
          />
        </TableStyled>
      </Container>
    </Fragment>
  );
}
