import React from "react";
import { Button, Modal, Row, Col, Form, Input, DatePicker, Select } from "antd";
import FormComponent from "@components/Form";
import moment from "moment";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import { FORMAT_DATE } from "@utils/constants";
import LoadingComponent from "@components/Loading";
import { ContainerTroNgai } from "../../../css-styled";
import { removeAccents } from "@utils/function";

const { Option } = Select;
const { TextArea } = Input;

export default function UITroNgaiKhaoSat(props) {
  const {
    loadingSubmit,
    visible,
    closeModal,
    form,
    handleSubmit,
    ngay,
    handleChangeLoaiTroNgai,
    dataTroNgai,
    loadingTenTroNgai,
    data,
    handleChangeNgay,
    disable,
    loading,
    t,
    title,
    text,
  } = props;

  // useEffect(() => {
  //   form.setFieldsValue({
  //     ngayHen: moment(ngay),
  //   });
  // }, [form, ngay]);

  return (
    <LoadingComponent loading={loadingSubmit}>
      <Modal
        title={title ? title : "Cập nhật trở ngại khảo sát"}
        visible={visible}
        onCancel={closeModal}
        footer={null}
      >
        <FormComponent
          form={form}
          name="form-tro-ngai-khao-sat"
          onFinish={handleSubmit}
          initialValues={{
            ngayHen: moment(ngay, FORMAT_DATE),
          }}
          layout="vertical"
          autoComplete="off"
        >
          <ContainerTroNgai>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label="Loại trở ngại"
                  name="loaiTroNgaiId"
                  className="required-field"
                >
                  <Select
                    onChange={handleChangeLoaiTroNgai}
                    showSearch
                    filterOption={(input, option) => {
                      if (option && option.children) {
                        return (
                          removeAccents(option.children)
                            .toLowerCase()
                            .indexOf(removeAccents(input).toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {dataTroNgai && dataTroNgai.length > 0
                      ? dataTroNgai.map((item, index) => (
                          <Option key={index} value={item.id}>
                            {item.tenTroNgai}
                          </Option>
                        ))
                      : null}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Trở ngại"
                  name="troNgaiId"
                  className="required-field"
                >
                  <Select loading={loadingTenTroNgai}>
                    {data && data.length > 0
                      ? data.map((item, index) => (
                          <Option key={index} value={item.id}>
                            {item.tenTroNgai}
                          </Option>
                        ))
                      : null}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={text ? text : "Ngày hẹn tiếp theo"}
                  name="ngayHen"
                  // className="required-field"
                >
                  <DatePicker
                    locale={locale}
                    format={FORMAT_DATE}
                    onChange={handleChangeNgay}
                    placeholder={FORMAT_DATE}
                    allowClear={false}
                    disabled={disable}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Ghi chú" name="ghiChu">
                  <TextArea rows={3} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} justify="center">
              <Col span={12} style={{ textAlign: "right" }}>
                <Button
                  key="back"
                  onClick={closeModal}
                  className="button-closed"
                >
                  {t("button.dong_lai")}
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  loading={loading}
                  key="submit"
                  htmlType="submit"
                  form="form-tro-ngai-khao-sat"
                >
                  {t("button.xac_nhan")}
                </Button>
              </Col>
            </Row>
          </ContainerTroNgai>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}
