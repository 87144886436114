import { Col, Form, Row, Select, Button } from "antd";
import DatePickerComponent from "@components/DatePicker";
import {
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
  FORMAT_MONTH,
} from "@utils/constants";
import moment from "moment";
import FormComponent from "@components/Form";
import { cap_dien } from "@permissions/a_capdien";
import { removeAccents } from "@utils/function";
import CheckPermission from "@components/CheckPermission";

const { Option } = Select;

export default function FormBoLoc(props) {
  const { form, handleSearch, user, donVi, filterConditions, clear, setType } =
    props;

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        donViId: filterConditions.donViId
          ? filterConditions.donViId
          : user.unitId.toString(),
      }}
      autoComplete="off"
    >
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="donViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Select
                showSearch
                filterOption={(input, option) => {
                  if (option && option.children) {
                    return (
                      removeAccents(option.children)
                        .toLowerCase()
                        .indexOf(removeAccents(input).toLowerCase()) >= 0
                    );
                  }
                }}
              >
                {donVi && donVi.length > 0
                  ? donVi.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                  : null}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="ngayTiepNhan" label="Tháng báo cáo">
            <DatePickerComponent
              defaultValue={
                filterConditions.ngayTiepNhan
                  ? moment(filterConditions.ngayTiepNhan, FORMAT_ENGLISH)
                  : moment(moment(), FORMAT_MONTH)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"ngayTiepNhan"}
              picker="month"
              notClear={true}
            />
          </Form.Item>
        </Col>
        <Col
          span={24}
          md={14}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button className="mr-24px" onClick={clear}>
            Bỏ lọc
          </Button>
          <Button
            type="primary"
            className="mr-24px"
            htmlType="submit"
            form="filter-form"
            onClick={() => setType(ENUM_BUTTON_TYPE.TIM_KIEM)}
          >
            Tìm kiếm
          </Button>
          <CheckPermission permissionCode={cap_dien.a507_xuat}>
            <Button
              type="primary"
              className="mr-24px"
              htmlType="submit"
              form="filter-form"
              onClick={() => setType(ENUM_BUTTON_TYPE.XUAT_EXCEL)}
            >
              Xuất excel
            </Button>
          </CheckPermission>
        </Col>
      </Row>
    </FormComponent>
  );
}
