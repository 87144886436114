import DanhSachYeuCau from "../../common-ver1/danh-sach-yeu-cau";
import { Fragment, useCallback, useState } from "react";
import { treo_thao } from "@permissions/b_treothao";
import { Endpoint } from "@utils/endpoint";
import {
  ENUM_DS_YEU_CAU,
  // ENUM_KYSO,
  FUNC_ID_DSYC_TREOTHAO,
  TYPE_PHIEU_YEUCAU,
} from "@utils/constants";
import { Button } from "antd";
import ModalConfirm from "@modules/treo-thao/common-ver1/confirm-xac-nhan";
// import KySoComponent from "@components/KySo";
// import ButtonKySoHSM from "@modules/treo-thao/common-ver1/ky-so-hsm";

export default function Index() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [fetchList, setFetchList] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [, setCodeStatus] = useState(0);
  const [arrSelectedRows, setArrSelectedRows] = useState([]);
  const [typeButton, setTypeButton] = useState("xac-nhan");
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setCodeStatus(selectedRows[0]?.maTrangThai);
    setArrSelectedRows(selectedRows);
  };
  //Hiển thị checkbox ở đầu mỗi bảng
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  const [visibled, setVisibled] = useState(false);
  const handleOpenXN = useCallback(
    (typeClick) => {
      setVisibled(!visibled);
      setTypeButton(typeClick);
    },
    [visibled]
  );

  const handleCloseXN = () => {
    setVisibled(false);
  };

  const showPopupXNPhieu = useCallback(() => {
    return (
      visibled && (
        <ModalConfirm
          visible={visibled}
          closeModal={handleCloseXN}
          payload={
            typeButton === "xac-nhan"
              ? {
                  ids: selectedRowKeys.toString(),
                }
              : { yeuCauIds: selectedRowKeys.toString() }
          }
          url={
            typeButton === "xac-nhan"
              ? Endpoint.UPDATE_LANH_DAO_KY_DUYET_XAC_NHAN
              : Endpoint.KY_SO_YEU_CAU_HSM
          }
          setFetchList={setFetchList}
          content={"Yêu cầu sẽ được chuyển tiếp cho Phòng kinh doanh !"}
          title="Lãnh đạo xác nhận yêu cầu treo tháo"
          dataSource={arrSelectedRows}
          type={TYPE_PHIEU_YEUCAU.YEUCAU}
          setSelectedRowKeys={setSelectedRowKeys}
          typeClick={typeButton}
        />
      )
    );
  }, [arrSelectedRows, selectedRowKeys, typeButton, visibled]);

  const genExtra = () => (
    <Fragment>
      <Button
        type="primary"
        className="mr-5px btn-bg-yellow"
        onClick={() => handleOpenXN("xac-nhan")}
        disabled={!selectedRowKeys.length}
      >
        Xác nhận
      </Button>

      {/* <KySoComponent
        url={Endpoint.XAC_THUC_KY_SO}
        urlGetHash={Endpoint.KY_SO_GET_HASH}
        payload={{
          yeuCauIds: selectedRowKeys.toString(),
          maTrangThai: codeStatus,
        }}
        permissionCode={treo_thao.b34k}
        disabled={!selectedRowKeys.length}
        setFetchDataTable={setFetchList}
        text={"Ký số USB"}
        setLoading={setLoading}
        loading={loading}
        bgColor={"#faad14"}
        typeKySo={ENUM_KYSO.TREOTHAO}
      /> */}
      <Button
        type="primary"
        onClick={() => handleOpenXN("ky-so")}
        disabled={!selectedRowKeys.length}
      >
        Ký số
      </Button>
      {/* <ButtonKySoHSM
        url={Endpoint.KY_SO_YEU_CAU_HSM}
        params={{
          yeuCauIds: selectedRowKeys.toString(),
        }}
        setFetchList={setFetchList}
        disabled={!selectedRowKeys.length}
      /> */}

      {/* <ButtonXacNhan
        title={"Lãnh đạo xác nhận yêu cầu"}
        content={"Lãnh đạo duyệt biên bản treo tháo."}
        selectedRowKeys={selectedRowKeys}
        setLoading={setLoading}
        setFetchList={setFetchList}
        permissionCodeKySo={treo_thao.b71k}
        isKySo={true}
        isIcon={false}
        url={Endpoint.UPDATE_LANH_DAO_KY_DUYET_XAC_NHAN}
        urlKySo={Endpoint.XAC_THUC_KY_SO}
        payload={{ ids: selectedRowKeys.toString() }}
        urlGetHash={Endpoint.KY_SO_GET_HASH}
        payloadKySo={{ yeuCauIds: selectedRowKeys.toString() }}
        className="mr-5px"
        disabled={!selectedRowKeys.length}
        setSelectedRowKeys={setSelectedRowKeys}
      /> */}
    </Fragment>
  );

  return (
    <Fragment>
      <DanhSachYeuCau
        funcId={FUNC_ID_DSYC_TREOTHAO.LANHDAO_DUYETYEUCAU}
        typeList={ENUM_DS_YEU_CAU.LANHDAO_KYDUYETYEUCAU}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        genExtra={genExtra}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        rowSelection={rowSelection}
        permissionCodeBB={treo_thao.b71v}
        title={"Lãnh đạo xác nhận yêu cầu"}
        content={
          "Yêu cầu treo tháo đang ở bước Lãnh đạo. Sau khi xác nhận/ký số, yêu cầu sẽ được chuyển sang bước tiếp theo"
        }
        payloadKySo={{
          yeuCauIds: selectedRowKeys.toString(),
          // maTrangThai: codeStatus,
        }}
        permissionCodeKySo={treo_thao.b71k}
        urlXN={Endpoint.UPDATE_LANH_DAO_KY_DUYET_XAC_NHAN}
        permissionCodeXN={treo_thao.b71xn}
        isKySo={true}
      />
      {showPopupXNPhieu()}
    </Fragment>
  );
}
