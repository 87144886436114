import { closeModal } from "@state/system-config/reducer";
import { Button, Tabs } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { handleDetailCongTo } from "../../danh-sach-cong-to/services";
import HinhAnhHienTruong from "./tabpane/anh-hien-truong";
import ThongTinCongToChiTiet from "./tabpane/thong-tin-cong-to";
import { Form } from "antd";
import { ContainerBody } from "@modules/cap-dien/nhan-vien-khao-sat/css-styled";

const { TabPane } = Tabs;

export default function ChiTietCongToTabs({
  typeSoPha,
  typeList,
  detailCongTo,
}) {
  const [loading, setLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [dataAnh, setDataAnh] = useState(false);
  const [form] = Form.useForm();
  const [dataTable, setDataTable] = useState([]);

  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const onChange = (key) => {};
  const columnsPT = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "5%",
      sorter: false,
    },
    {
      title: "Loại BCS",
      dataIndex: "loaiBCS",
      key: "loaiBCS",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "15%",
      sorter: false,
    },
    {
      title: "Chỉ số ghi",
      dataIndex: "chiSoGhi",
      key: "chiSoGhi",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "15%",
      sorter: false,
    },
    {
      title: "Chỉ số phúc tra",
      dataIndex: "chiSoPhucTra",
      key: "chiSoPhucTra",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "15%",
      sorter: false,
    },
    {
      title: "Hệ số nhân",
      dataIndex: "heSoNhan",
      key: "heSoNhan",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "15%",
      sorter: false,
    },
    {
      title: "Chênh lệch",
      dataIndex: "chenhLech",
      key: "chenhLech",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "15%",
      sorter: false,
    },
  ];

  useEffect(() => {
    handleDetailCongTo(
      detailCongTo.congToId,
      setLoading,
      setDataDetail,
      setDataAnh,
      setDataTable
    );
  }, [detailCongTo]);

  useEffect(() => {
    if (dataDetail && Object.keys(dataDetail).length > 0) {
      form.setFieldsValue({
        ...dataDetail,
      });
    }
    // eslint-disable-next-line
  }, [dataDetail]);
  return (
    <Fragment>
      <ContainerBody className="modal-body">
        <Tabs onChange={onChange} type="card">
          <TabPane tab="Thông tin công tơ" key="1">
            <ThongTinCongToChiTiet
              form={form}
              loading={loading}
              typeSoPha={typeSoPha}
              typeList={typeList}
              columnsPT={columnsPT}
              dataTable={dataTable}
              dataDetail={dataDetail}
            />
          </TabPane>
          <TabPane tab="Hình ảnh hiện trường" key="2">
            <HinhAnhHienTruong
              dataAnh={dataAnh}
              dataDetail={dataDetail}
              dataChiSo={dataTable}
            />
          </TabPane>
        </Tabs>
      </ContainerBody>
      <div className="ant-modal-footer text-center">
        <Button type="default" onClick={() => dispatch(closeModal())}>
          Đóng lại
        </Button>
      </div>
    </Fragment>
  );
}
