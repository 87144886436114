import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData } from "@utils/request";

export const getListDataB1_TH = (payload, setLoading, setDataB1TH) => {
  authGetData({
    url: `${Endpoint.B1_TH_TH_TIN_NHAN_TOAN_CONG_TY}?${buildQueryString(
      parseParams(payload)
    )}`,

    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataB1TH(res.data);
      }
    },
  });
};
