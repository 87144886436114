import { authGetData, downloadFile } from "@utils/request";
import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { alertMessage } from "@utils/function";

// in biên bản quyết toán khách hàng

export const getPDFBienBanKH = (detail, onComplete, setLoading, t) => {
  authGetData({
    url: `${Endpoint.IN_BIEN_BAN_QUYET_TOAN_KH}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        onComplete(res.data);
      } else {
        onComplete("error");
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};
export const getExcelBienBanKH = (detail, onComplete, setLoading, t) => {
  authGetData({
    url: `${Endpoint.IN_BIEN_BAN_QUYET_TOAN_KH_EXCEL}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        onComplete(res.data);
      } else {
        onComplete(0);
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const downLoadBienBan = (detail, setLoading) => {
  downloadFile({
    endpoint: `${Endpoint.IN_BIEN_BAN_QUYET_TOAN_KH_EXCEL}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    setLoading,
  });
};
