import { Tabs } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  padding: 16px 24px;
`;
export const ContainerTab = styled.div`
  padding: 0px 36px;
`;

export const ExTraStepDetail = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
`;
export const ButtonClose = styled.div`
  margin-top: 32px;
  text-align: center;
`;
export const TabContainer = styled.div`
  margin-top: 20px;
  .ant-tabs-nav {
    background: #fafafa;
    font-weight: bold;
    padding: 0 16px;
  }

  .ant-tabs-content-holder {
    padding: 16px;
  }

  .ant-collapse-content-box {
    padding: 16px !important;
  }
`;

export const TabsDetailContainer = styled(Tabs)`
  padding: 10px;
`;

export const ContainerCheckBox = styled.div`
  margin-bottom: 15px;
`;
