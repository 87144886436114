import React, { } from "react";
import styled from "styled-components";
import { Collapse } from "antd";
import { FilterOutlined } from "@ant-design/icons";

const { Panel } = Collapse;
export default function Filter(props) {

  return (
    <CollapseContainer
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <FilterText className={isActive ? "active" : ""}>
          <span style={{ fontSize: "14px" }}>Bộ lọc</span>
          <FilterOutlined style={{ fontSize: 16 }} />
        </FilterText>
      )}
      defaultActiveKey={['1']}
    >
      <Panel key={'1'} >{props.children}</Panel>
    </CollapseContainer>
    // color: #279bef;
  );
}
const CollapseContainer = styled(Collapse)`
  width: 100%;
  background: #fff;
  border: none;
  border-radius: unset;
  .ant-collapse-header {
    min-height: 28px;
  }
  .active {
    color: #405cab;
  }
  .ant-collapse-content-box {
    padding: 15px 30px;
  }
  .filter-action {
    display: flex;
    justify-content: end;
    align-items: end;
  }
`;
const FilterText = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  > span {
    margin-right: 10px;
  }
`;
