import React from "react";
import ContentQuanLyChiSo from "./content";
import { Page } from "./css-styled";
import HeaderQuanLyChiSo from "./header";
export default function Index() {
  return (
    <Page>
      <HeaderQuanLyChiSo />
      <ContentQuanLyChiSo />
    </Page>
  );
}
