import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import TableComponent from "@components/TableComponent";

import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_DATE,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { authGetData } from "@utils/request";
import { Button, Col, Divider, Form, Row, Select, Table } from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import BcChiTietSlThuDienTu from "../bc-chi-tiet-sl-thu-dien-tu-hop-le";
import BcChiTietSlThuDienTuKhongHopLe from "../bc-chi-tiet-sl-thu-dien-tu-khong-hop-le/bc-chi-tiet-sl-thu-dien-tu-khong-hop-le";
import BcThSlThuDienTuKhongHopLe from "../bc-tong-hop-sl-thu-dien-tu-khong-hop-le/bc-tong-hop-sl-thu-dien-tu-khong-hop-le";
import BcThSlThuDienTu from "../bc-tong-hop-sl-thu-hop-le";
import DsKhDangKySuDungEmail from "../ds-kh-dang-ky-su-dung-email";
import { handleDownload, selectBaoCaoDoiSoatEmail } from "../services";

export default function THTinhHinhGuiThongBaoChoKH() {
  const [screen, setScreen] = useState();
  const [loading, setLoading] = useState(false);
  const [dataB09, setDataB09] = useState([]);
  const [form] = Form.useForm();
  const [totalCount, setTotalCount] = useState(1);
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [query, setQuery] = useState();
  const handleChange = useCallback((values) => {
    setScreen(values);
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const optionValue = [
    {
      label:
        "B09 - Tổng hợp tình hình gửi thông báo cho khách hàng hình thức Email",
    },
  ];
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
    DenNgay: moment().format(FORMAT_ENGLISH),
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  console.log("query", query);

  const handleSearch = useCallback((values) => {
    const queryString = buildQueryString(
      parseParams({
        TuNgay: values.TuNgay,
        DenNgay: values.DenNgay,
      })
    );
    setQuery(queryString);
    authGetData({
      url: `${Endpoint.TH_GUI_TINH_HINH_THONG_BAO_EMAIL}?${queryString}`,
      setLoading,
      onSuccess: (res) => {
        if (res && res.data) {
          setDataB09(mergeSimilarRows(res.data.map((item, index) => ({STT: index + 1, ...item}))));
          setTotalCount(res.paging.totalCount);
        }
      },
    });
  }, []);

  const handleXuatExcel = useCallback(() => {
    const payload = {
      TuNgay: form.getFieldValue("TuNgay"),
      DenNgay: form.getFieldValue("DenNgay"),
      reportType: form.getFieldValue("reportType"),
    };
    handleDownload(payload, setLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mergeSimilarRows = (rowsData = []) => {
    const uniqueUserIdsPerPage = new Set();
    let pageNo = 0;
    const mergedData = rowsData.map((rowData, index) => {
      const updatedRowsData = { ...rowData };
      if (index !== 0 && index % DEFAULT_PAGESIZE === 0) {
        uniqueUserIdsPerPage.clear();
        pageNo += 1;
      }
      if (uniqueUserIdsPerPage.has(rowData.donvi)) {
        updatedRowsData.rowSpan = 0;
      } else {
        const occurCount = rowsData
          .slice(pageNo * DEFAULT_PAGESIZE, (pageNo + 1) * DEFAULT_PAGESIZE)
          .filter((data) => data.donvi === rowData.donvi).length;
        updatedRowsData.rowSpan = Math.min(DEFAULT_PAGESIZE, occurCount);
        uniqueUserIdsPerPage.add(rowData.donvi);
      }
      return updatedRowsData;
    });
    return mergedData;
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      width: 50,
    },
    {
      title: "Đơn vị",
      dataIndex: "donvi",
      key: "donvi",
      width: 100,
      colSpan: 1,
      render: (value, row) => {
        const obj = {
          children: value,
          props: {
            colSpan: 1,
            rowSpan: row.rowSpan
          }
        };
        return obj;
      }
    },
    {
      title: "Loại tin nhắn",
      dataIndex: "loaitinnhan",
      key: "loaitinnhan",
      width: 250,
    },
    {
      title: "Số lượng gửi nhà cung cấp thành công",
      dataIndex: "slgncctc",
      key: "slgncctc",
      width: 100,
    },
    {
      title: "Số lượng lỗi",
      dataIndex: "slloi",
      key: "slloi",
      width: 100,
    },
  ];
  const countTotals = (pageData) => {
    let countslgncctc = 0;
    let countslloi = 0;
    pageData.forEach(({ slgncctc, slloi }) => {
      countslgncctc += parseInt(slgncctc);
      countslloi += parseInt(slloi);
    });

    return (
      <Table.Summary.Row style={{ backgroundColor: "#ccccb3" }} colSpan={2}>
        <Table.Summary.Cell
          align="center"
          index={0}
          colSpan={2}
          className="summary"
        >
          <Text className="font-bold">Tổng cộng</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold summary"
          align="center"
          index={2}
        ></Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold summary"
          align="center"
          index={2}
        >
          {countslgncctc}
        </Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold summary"
          align="center"
          index={2}
        >
          {countslloi}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };
  return (
    <Fragment>
      {screen === Endpoint.TH_GUI_TINH_HINH_THONG_BAO_EMAIL ? (
        <THTinhHinhGuiThongBaoChoKH />
      ) : screen === Endpoint.BC_TH_SL_THU_DIEN_TU_HOP_LE ? (
        <BcThSlThuDienTu screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.BC_CHI_TIET_SL_THU_DIEN_TU_HOP_LE ? (
        <BcChiTietSlThuDienTu screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.BC_TH_SL_THU_DIEN_TU_KHONG_HOP_LE ? (
        <BcThSlThuDienTuKhongHopLe
          screen={screen}
          handleChange={handleChange}
        />
      ) : screen === Endpoint.BC_CHI_TIET_SL_THU_DIEN_TU_KHONG_HOP_LE ? (
        <BcChiTietSlThuDienTuKhongHopLe
          screen={screen}
          handleChange={handleChange}
        />
      ) : screen === Endpoint.DANH_SACH_KH_DANG_KY_EMAIL ? (
        <DsKhDangKySuDungEmail screen={screen} handleChange={handleChange} />
      ) : (
        <Container>
          <ContentWrapper>
            <FormComponent
              onFinish={handleSearch}
              form={form}
              layout="horizontal"
              initialValues={{
                // reportType: screen,
                reportType: Endpoint.TH_GUI_TINH_HINH_THONG_BAO_EMAIL,
                TuNgay: form.setFieldsValue(filterConditions.TuNgay)
                  ? form.setFieldsValue(filterConditions.TuNgay)
                  : moment().startOf(MONTH_STRING),
              }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="reportType"
                    label="Tên báo cáo"
                    labelCol={{ span: 3 }}
                    labelAlign="left"
                  >
                    <Select
                      form={form}
                      formKey="reportType"
                      defaultValue={optionValue}
                      options={selectBaoCaoDoiSoatEmail}
                      onChange={() =>
                        handleChange(form.getFieldValue("reportType"))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={7}>
                  <Form.Item
                    name="TuNgay"
                    label="Ngày bắt đầu"
                    labelAlign="left"
                    labelCol={{ span: 11 }}
                  >
                    <DatePickerComponent
                      defaultValue={
                        filterConditions.TuNgay
                          ? moment(filterConditions.TuNgay)
                          : moment().startOf(FORMAT_DATE)
                      }
                      formKey="TuNgay"
                      format={FORMAT_ENGLISH}
                      form={form}
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    name="DenNgay"
                    label="Ngày kết thúc"
                    labelAlign="left"
                    labelCol={{ span: 11 }}
                  >
                    <DatePickerComponent
                      defaultValue={
                        filterConditions.DenNgay
                          ? moment(filterConditions.DenNgay)
                          : moment()
                      }
                      formKey="DenNgay"
                      format={FORMAT_ENGLISH}
                      form={form}
                    />
                  </Form.Item>
                </Col>
                <Row justify="end">
                  <Button type="primary" className="mr-5px" htmlType="submit">
                    Xem báo cáo
                  </Button>
                  <Button
                    type="primary"
                    // onClick={() => {
                    //   handleDownload(form.getFieldValue(), setLoading);
                    // }}
                    onClick={handleXuatExcel}
                  >
                    Xuất Excel
                  </Button>
                </Row>
              </Row>
            </FormComponent>
            <Divider className="section-text-line" orientation="left"></Divider>
            <TableComponent
              header={
                "B09 - Tổng hợp tình hình gửi thông báo cho khách hàng hình thức Email"
              }
              columns={columns}
              dataSource={dataB09}
              filterConditions={filterConditions}
              loading={loading}
              totalCount={totalCount}
              onChangePagination={onChangePagination}
              pagination={filterConditions}
              summary={(pageData) => countTotals(pageData)}
            />
          </ContentWrapper>
        </Container>
      )}
    </Fragment>
  );
}

const Container = styled.div`
  margin-top: 40px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0 16px;
`;
