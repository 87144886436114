import React from "react";
import { Button, Modal, Row, Col, Form } from "antd";
import FormComponent from "@components/Form";
import { Container } from "../css-styled";
import LoadingComponent from "@components/Loading";

// const { Option } = Select;

export default function UIXacNhanNghiemThu(props) {
  const {
    t,
    visibleXacNhan,
    handleCloseModalXacNhan,
    form,
    dataTreoThao,
    dataNiemPhong,
    onFinishNghiemThu,
    loading,
  } = props;

  return (
    <Modal
      title={t("nghiem_thu.xac_nhan_nghiem_thu")}
      visible={visibleXacNhan}
      onCancel={handleCloseModalXacNhan}
      footer={null}
    >
      <div>Xác nhận sẽ chuyển tiếp cho bộ phận Quyết toán !</div>
      <FormComponent
        form={form}
        name="form-nghiem-thu"
        onFinish={onFinishNghiemThu}
        initialValues={{}}
        layout="vertical"
        autoComplete="off"
      >
        <LoadingComponent loading={loading}>
          <Container>
            <Row gutter={24}>
              <Col span={24}>
                <h4 className="fs-14px">
                  {t("nghiem_thu.thuc_hien_nghiem_thu_dong_bo")}
                </h4>
              </Col>
              <Col span={8}>
                <Form.Item style={{ margin: "10px 0px 5px" }}>
                  <span>{t("nghiem_thu.nhan_vien_treo_thao")}:</span>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  style={{ margin: "10px 0px 5px" }}
                  name="nhanVienTreoThaoId"
                >
                  <span className="font-bold">{dataTreoThao?.tenNhanVien}</span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  <span>{t("nghiem_thu.nhan_vien_niem_phong")}:</span>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item name="nhanVienNiemPhongId">
                  <span className="font-bold">
                    {dataNiemPhong?.tenNhanVien}
                  </span>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} justify="center">
              <Col span={12} style={{ textAlign: "right" }}>
                <Button
                  key="back"
                  onClick={handleCloseModalXacNhan}
                  className="button-closed"
                >
                  {t("button.dong_lai")}
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  key="submit"
                  type="primary"
                  className="button-primary"
                  htmlType="submit"
                  form="form-nghiem-thu"
                >
                  {t("button.xac_nhan")}
                </Button>
              </Col>
            </Row>
          </Container>
        </LoadingComponent>
      </FormComponent>
    </Modal>
  );
}
