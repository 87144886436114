import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import Selection from "@components/Select";
import ConfirmModal from "@modules/giao-nhan-dien-nang/tong-hop-so-lieu/tinh-san-luong/modal-confirm/modal-xacnhan/modal-confirm";
import { closeModal } from "@state/system-config/reducer";
import { LOAI_TIMKIEM_GNDN } from "@utils/constants";
import { numberWithNoSpaces, numberWithSpaces } from "@utils/function";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import NumericInput from "../NumericInput";
import {
  getDataBienBan,
  postCongTo,
  postCongToTH3,
  postLayBienBanTuCMIS,
  PutBienBan,
  selectSua,
  selectTao,
} from "../services";

export default function ModalSuaBienBanTreoThao(props) {
  const { details, loading, setLoading, getListDD } = props;
  const [data, setData] = useState([]);
  const [dataTreo, setDataTreo] = useState([]);
  const [valueThucHien, setValueThucHien] = useState();
  const dispatch = useDispatch();
  const [loadingComponent, setLoadingComponent] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [arrayBoChiSoRequest, setArrayBoChiSoRequest] = useState([]);
  const [loadingBBCMIS, setLoadingBBCMIS] = useState(false);
  const [layBBTuCMIS, setLayBBTuCMIS] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [dataCMIS, setDataCMIS] = useState([]);
  const [xacNhanDongBo, setXacNhanDongBo] = useState(false);
  const [changeTTCT, setChangeTTCT] = useState(false);
  const [form] = Form.useForm();
  const [dataTableTreo, setDataTableTreo] = useState([]);
  const isEdit = true;
  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);


  const handleTinhTong = (e, index) => {
    const chiSoChot = numberWithNoSpaces(
      form.getFieldValue(`chiSoChot-${index}`) || 0
    );

    const sanLuong = Number(
      numberWithNoSpaces(
        form.getFieldValue(`sanLuong-${index}`) === "-"
          ? 0
          : form.getFieldValue(`sanLuong-${index}`)
      ) || 0
    );
    const heSoNhan = data?.heSoNhan;
    const chiSoCu = numberWithNoSpaces(
      form.getFieldValue(`chiSoCu-${index}`) ?? 0
    );
    const tong = Math.round((chiSoChot - chiSoCu) * heSoNhan + sanLuong);

    if (!chiSoChot && !sanLuong) {
      form.setFieldsValue({
        [`tong-${index}`]: "",
      });
    } else {
      form.setFieldsValue({
        [`tong-${index}`]: numberWithSpaces(tong),
      });
    }
  };

  const onFinish = useCallback(
    (values) => {
      let validate = false;
      if (valueThucHien !== 1) {
        for (let i = 0; i < data?.gndnBoChiSos.length; i++) {
          if (
            numberWithNoSpaces(values[`chiSoChot-${i}` ?? 0]) <
              data?.gndnBoChiSos[i].chiSoRequest.chiSoCu ||
            !values[`chiSoChot-${i}`]
          ) {
            form.setFields([
              {
                name: `chiSoChot-${i}`,
                errors: ["Nhập sai chỉ số"],
              },
            ]);
            validate = true;
          }
        }
      }

      if (!values?.thucHien) {
        form.setFields([
          {
            name: `thucHien`,
            errors: ["Thực hiện không được bỏ trống"],
          },
        ]);
      } else if (!values.heSoNhanTreo && valueThucHien === 1) {
        form.setFields([
          {
            name: `heSoNhanTreo`,
            errors: ["Hệ số nhân không được bỏ trống"],
          },
        ]);
      } else if (!values.heSoNhanTreo && valueThucHien === 3) {
        form.setFields([
          {
            name: `heSoNhanTreo`,
            errors: ["Hệ số nhân không được bỏ trống"],
          },
        ]);
      } else {
        if (valueThucHien === 1) {
          const boChiSoRequests = data?.gndnBoChiSos?.map((e, index) => ({
            ...e,
            chiSoRequest: {
              chiSoTreo: numberWithNoSpaces(values[`ChiSoTreo-${index}`]),
            },
          }));
          const payload = {
            ...data,
            boChiSoRequests,
            heSoNhan: values.heSoNhanTreo,
          };

          PutBienBan(
            values,
            payload,
            setLoadingContent,
            handleCloseModal,
            getListDD,
            form
          );
        } else if (valueThucHien === 2 && !validate) {
          const boChiSoRequestsThao = data?.gndnBoChiSos?.map((e, index) => ({
            ...e,
            chiSoRequest: {
              chiSoChot: numberWithNoSpaces(values[`chiSoChot-${index}`]),
              chiSoCu: data?.gndnBoChiSos[index].chiSoRequest.chiSoCu,
              sanLuong: numberWithNoSpaces(values[`sanLuong-${index}`]) || 0,
              chiSoTreo: null,
            },
          }));

          const payload = {
            ...data,
            boChiSoRequestsThao,
            maCongToTreo: data.maCongToTreo || data.maCongTo,
          };

          PutBienBan(
            values,
            payload,
            setLoadingContent,
            handleCloseModal,
            getListDD,
            form
          );
        } else if (valueThucHien === 3 && !validate) {
          const boChiSoRequestsThao = data?.gndnBoChiSos?.map((e, index) => ({
            ...e,
            chiSoRequest: {
              chiSoChot: numberWithNoSpaces(values[`chiSoChot-${index}`]),
              chiSoCu: data?.gndnBoChiSos[index].chiSoRequest.chiSoCu,
              sanLuong: numberWithNoSpaces(values[`sanLuong-${index}`]) || 0,
              chiSoTreo: null,
            },
          }));

          const boChiSoRequests = arrayBoChiSoRequest?.map((e, index) => ({
            ...e,
            chiSoRequest: {
              chiSoTreo: numberWithNoSpaces(values[`ChiSoTreo-${index}`]),
            },
          }));

          const payload = {
            ...data,
            boChiSoRequestsThao,
            boChiSoRequests,
            heSoNhan: values.heSoNhanTreo,
          };

          PutBienBan(
            values,
            payload,
            setLoadingContent,
            handleCloseModal,
            getListDD,
            form
          );
        }
      }
    },

    [
      data,
      form,
      handleCloseModal,
      setLoadingContent,
      getListDD,
      valueThucHien,
      arrayBoChiSoRequest,
    ]
  );

  useEffect(() => {
    getDataBienBan(
      props.details?.maDiemDo,
      props.details?.maCongTo
        ? props.details?.maCongTo
        : props.details?.maCongToThao,
      props.details?.maBienBan
        ? props.details?.maBienBan
        : props.details?.maBienBanThao,
      setData,
      setLoading,
      isEdit
    );
  }, [
    props.details?.maBienBan,
    props.details?.maBienBanThao,
    props.details?.maCongTo,
    props.details?.maCongToThao,
    props.details?.maDiemDo,
    setData,
    setLoading,
  ]);

  useEffect(() => {
    setValueThucHien(data?.thucHien);
  }, [data?.thucHien]);

  useEffect(() => {
    setDataTableTreo(dataTreo?.bcs?.split(";"));
  }, [dataTreo?.bcs]);

  // useEffect(() => {
  //   if (props.details?.maCongTo && props.details?.thucHien === 1) {
  //     postCongToTH3(
  //       props.details?.maCongTo
  //         ? props.details?.maCongTo
  //         : props.details?.maCongToThao,
  //       setDataTreo,
  //       setLoading
  //     );
  //   }
  // }, [
  //   props.details?.maCongTo,
  //   props.details?.maCongToThao,
  //   props.details?.thucHien,
  //   setLoading,
  // ]);

  useEffect(() => {
    setDataTreo(data)
    const obj = {
      bcs: "string",
      chiSoRequest: {
        chiSoTreo: null,
      },
    };
    if (data?.thucHien === 1 && changeTTCT === false) {
      const arr = [];
      data?.boChiSosTreo?.forEach((i) => {
        arr.push({
          bcs: i?.bcs,
          chiSoRequest: {
            chiSoTreo: i?.chiSoRequest?.chiSoCu,
          },
        });
      });
      setArrayBoChiSoRequest(arr);
    } else if (valueThucHien === 3 && changeTTCT === false) {
      const arr = [];
      data?.boChiSosTreo?.forEach((i) => {
        arr.push({
          bcs: i?.bcs,
          chiSoRequest: {
            chiSoTreo: i?.chiSoRequest?.chiSoCu,
          },
        });
      });
      setArrayBoChiSoRequest(arr);
    } else if (valueThucHien === 3 && changeTTCT === true) {
      const arr = [];
      dataTableTreo?.forEach((i) => {
        arr.push({
          ...obj,
          bcs: i,
        });
      });
      setArrayBoChiSoRequest(arr);
    }
  }, [
    changeTTCT,
    data?.boChiSosTreo,
    data?.gndnBoChiSos,
    data?.thucHien,
    dataTableTreo,
    valueThucHien,
  ]);

  useEffect(() => {
    setDataTableTreo(dataTreo?.bcs?.split(";"));
  }, [dataTreo?.bcs, dataTreo?.loaI_CTO]);

  useEffect(() => {
    const obj = {
      bcs: "string",
      chiSoRequest: {
        chiSoTreo: 0,
      },
    };
    if (changeTTCT === true) {
      const arr = [];
      dataTableTreo?.forEach((i) => {
        arr.push({
          ...obj,
          bcs: i,
        });
      });
      setArrayBoChiSoRequest(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeTTCT]);

  //key down

  const onPressEnter = (e, name) => {
    e.preventDefault();
    document.getElementsByName(name)[0]?.focus();
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      setLayBBTuCMIS(false);
      postCongTo(
        form.getFieldValue("maCongToTreo"),
        setDataTreo,
        setLoadingComponent,
        data,
        form
      );
      setChangeTTCT(true);
      data?.gndnBoChiSos?.forEach((item, index) => {
        form.setFieldsValue({
          [`ChiSoTreo-${index}`]: "",
        });
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      maDiemDo: data?.maDiemDo,
      ngayTreoThao: data?.ngayTreoThao,
      thongTinDiemDo: data?.thongTinDiemDo,
      soBienBan: data?.soBienBan,
      nguoiThucHien: data?.nhanVienTreoThao,
      maCongTo: data?.maCongTo,
      maCongToTreo: data?.thucHien === 1 ? data?.maCongTo : data?.maCongToTreo,
      heSoNhan: data?.heSoNhan,
      heSoNhanTreo: data?.thucHien === 1 ? data?.heSoNhan : data?.heSoNhanTreo,
      capChinhXacThao: data?.capChinhXac,
      capChinhXacTreo: data?.capChinhXacTreo,
      loaiCTThao: data?.loaiCongTo,
      loaiCT: data?.loaiCongToTreo,
      soCuaSoThao: data?.soCuaSo,
      soCuaSoTreo: data?.soCuaSoTreo,
      thucHien: data?.thucHien,
    });

    for (let i = 0; i < data?.boChiSosTreo?.length; i++) {
      form.setFieldsValue({
        [`ChiSoTreo-${i}`]: numberWithSpaces(
          data?.boChiSosTreo[i]?.chiSoRequest?.chiSoCu
        ),
        [`BCS-${i}`]: 
          data?.boChiSosTreo[i]?.bcs
        ,
      });
    }
    for (let i = 0; i < data?.gndnBoChiSos?.length; i++) {
      form.setFieldsValue({
        [`sanLuong-${i}`]:
          numberWithSpaces(data?.gndnBoChiSos[i]?.chiSoRequest?.sanLuong) || 0,
      });
      form.setFieldsValue({
        [`tong-${i}`]: numberWithSpaces(
          data?.gndnBoChiSos[i]?.chiSoRequest?.tong
        ),
      });
      form.setFieldsValue({
        [`chiSoChot-${i}`]: numberWithSpaces(
          data?.gndnBoChiSos[i]?.chiSoRequest?.chiSoChot
        ),
      });
      form.setFieldsValue({
        [`chiSoCu-${i}`]: numberWithSpaces(
          data?.gndnBoChiSos[i]?.chiSoRequest?.chiSoCu
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const onChangeThucHien = (e) => {
    setValueThucHien(e);
  };
  const callApiBienBanTuCMIS = () => {
    const loaI_TIMKIEM = form.getFieldValue("loaI_TIMKIEM")
      ? LOAI_TIMKIEM_GNDN.TREO_THAO_DIEM_DO_DAU_NGUON
      : LOAI_TIMKIEM_GNDN.KHONG_PHAI_TREO_THAO_DIEM_DO_DAU_NGUON;
    postLayBienBanTuCMIS({
      maDiemDoNoiBo: props.details?.maDiemDoNoiBo,
      setDataTreo: setDataCMIS,
      setLoading: setLoadingBBCMIS,
      congToTreo: form.getFieldValue("maCongToTreo")
        ? form.getFieldValue("maCongToTreo")
        : null,
      congToThao: form.getFieldValue("maCongTo")
        ? form.getFieldValue("maCongTo")
        : null,
      setLayBBTuCMIS: setLayBBTuCMIS,
      loaI_TIMKIEM: loaI_TIMKIEM,
    });
  };

  useEffect(() => {
    if (xacNhanDongBo === true) {
      const newObj = {};
      for (const [key, value] of Object.entries(dataCMIS)) {
        if (
          key === "ngayTreoThao" ||
          key === "soBienBan" ||
          key === "maCongToTreo" ||
          key === "loaiCongToTreo" ||
          key === "heSoNhanTreo" ||
          key === "capChinhXacTreo" ||
          key === "soCuaSoTreo" ||
          key === "boChiSosTreo"
        ) {
          newObj[key] = value;
        }
      }
      setData((prev) => ({ ...prev, ...newObj }));
    }
  }, [dataCMIS, xacNhanDongBo]);

  const handleCloseModalConfirm = () => {
    setVisibleModal(false);
  };
  const handleConfirm = () => {
    setXacNhanDongBo(true);
    setVisibleModal(false);
  };

  //key down
  useEffect(() => {
    if (dataCMIS?.maCongTo && dataCMIS?.maCongToTreo) {
      if (
        !form.getFieldValue("maCongToTreo") &&
        !form.getFieldValue("maCongTo")
      ) {
        setXacNhanDongBo(true);
      } else if (
        (form.getFieldValue("maCongTo") &&
          dataCMIS?.maCongTo !== form.getFieldValue("maCongTo")) ||
        (form.getFieldValue("maCongToTreo") &&
          dataCMIS?.maCongToTreo !== form.getFieldValue("maCongToTreo"))
      ) {
        setVisibleModal(true);
      }
    }
  }, [dataCMIS, form]);

  const showModalConfirm = useMemo(
    () =>
      visibleModal && (
        <ConfirmModal
          visible={visibleModal}
          closeModal={handleCloseModalConfirm}
          content={`Mã công tơ hiện tại chưa trùng khớp với mã công tơ đồng bộ từ CMIS.
         Bạn chắc chắn muốn đồng bộ ?`}
          title={"Thông báo"}
          setLoading={setLoadingContent}
          handleSubmit={handleConfirm}
          loading={loadingContent}
        />
      ),
    [loadingContent, setLoadingContent, visibleModal]
  );

  const columnsThao = [
    {
      title: "BCS",
      dataIndex: "bcs",
      align: "center",
      width: "20%",
      render: (text, record) => {
        return (
          <span style={{ margin: 0, textAlign: "center" }}>{record?.bcs}</span>
        );
      },
    },
    {
      title: "Chỉ số cũ ",
      dataIndex: "chiSoCu",
      width: "20%",
      render: (text, record, index) => {
        return (
          <Form.Item name={`chiSoCu-${index}`}>
            <NumericInput name={`chiSoCu-${index}`} disabled />
          </Form.Item>
        );
      },
    },
    {
      title: "Chỉ số chốt",
      dataIndex: `chiSoChot`,
      width: "20%",
      render: (text, record, index) => {
        return (
          <Form.Item name={`chiSoChot-${index}`}>
            <NumericInput
              name={`chiSoChotThao-${index}`}
              onPressEnter={(e) =>
                onPressEnter(e, `chiSoChotThao-${index + 1}`)
              }
              onChange={(e) => handleTinhTong(e, index)}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "SL (+/-)",
      dataIndex: "sanLuong",
      width: "20%",
      render: (text, record, index) => {
        return (
          <Form.Item name={`sanLuong-${index}`}>
            <NumericInput
              defaultValue={numberWithSpaces(text)}
              name={`sanLuongThao-${index}`}
              onPressEnter={(e) => onPressEnter(e, `sanLuongThao-${index + 1}`)}
              onChange={(e) => handleTinhTong(e, index)}
              isNegativeValue
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Tổng",
      dataIndex: "tong",
      width: "20%",
      render: (text, record, index) => {
        return (
          <Form.Item name={`tong-${index}`}>
            <Input
              disabled
              style={{ textAlign: "right", color: "#000", fontSize: "14px" }}
            />
          </Form.Item>
        );
      },
    },
  ];
  const columnsTreo = [
    {
      align: "center",
      title: "BCS",
      render: (text, record) => {
        return (
          <>
            <Form.Item>
              <span
                style={{
                  margin: 0,
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {record?.bcs}
              </span>
            </Form.Item>
          </>
        );
      },
    },
    {
      title: "Chỉ số treo",
      align: "center",
      render: (text, record, index) => {
        return (
          <Form.Item name={`ChiSoTreo-${index}`}>
            <NumericInput
              defaultValue={numberWithSpaces(text)}
              name={`ChiSoTreo-${index}`}
              onPressEnter={(e) => onPressEnter(e, `ChiSoTreo-${index + 1}`)}
            />
          </Form.Item>
        );
      },
    },
  ];

  const columnsTreoTH3 = [
    {
      title: "BCS",
      dataIndex: "bcs",
      align: "center",
      render: (text, record) => {
        return (
          <span style={{ margin: 0, textAlign: "center", fontWeight: "bold" }}>
            {record?.bcs}
          </span>
        );
      },
    },
    {
      title: "Chỉ số treo",
      render: (text, record, index) => {
        return (
          <Form.Item name={`ChiSoTreo-${index}`}>
            <NumericInput
              name={`ChiSoTreo-${index}`}
              onPressEnter={(e) => onPressEnter(e, `ChiSoTreo-${index + 1}`)}
            />
          </Form.Item>
        );
      },
    },
  ];
  return (
    <Fragment>
      <Container>
        {showModalConfirm}
        <LoadingComponent loading={loadingContent}>
          <FormComponent
            layout="vertical"
            onFinish={onFinish}
            form={form}
            loading={loading}
            initialValues={{
              maDiemDo: data?.maDiemDo,
              ngayTreoThao: data?.ngayTreoThao,
              thongTinDiemDo: data?.thongTinDiemDo,
              maDiemDoNoiBo: details?.maDiemDoNoiBo,
              soBienBan: data?.soBienBan,
              nguoiThucHien: data?.nhanVienTreoThao,
              maCongToThao: data?.maCongTo,
              maCongToTreo: data?.maCongToTreo,
              heSoNhanThao: data?.heSoNhan,
              heSoNhanTreo: data?.heSoNhanTreo,
              loaiCTThao: data?.loaiCT,
              loaiCTTreo: data?.loaiCTTreo,
              loaiCT: details?.loaiCT,
              maCongTo: data?.maCongTo,
              heSoNhan: details?.heSoNhan || data?.heSoNhan,
              soCuaSo: data?.soCuaSo,
              soCuaSoTreo: data?.soCuaSoTreo,
              loaI_TIMKIEM: true,
            }}
          >
            <Divider orientation="left">1.Thông tin chung</Divider>
            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              gutter={24}
            >
              <Col span={5}>
                <Form.Item name="maDiemDo" label="Mã điểm đo">
                  <Input
                    onWheel={(e) => e.target.blur()}
                    disabled
                    type="text"
                    size="default"
                    formKey="maDiemDo"
                    form={form}
                    placeholder="Nhập mã điểm đo"
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="thongTinDiemDo"
                  label="Thông tin điểm đo"
                  required
                >
                  <Selection
                    disabled
                    formKey="thongTinDiemDo"
                    placeholder="Chọn Thông tin điểm đo"
                    form={form}
                  />
                </Form.Item>
              </Col>
              {details?.maDiemDoNoiBo?.length > 12 ? (
                <Col span={6}>
                  <Form.Item name="maDiemDoNoiBo" label="Mã điểm đo nội bộ">
                    <Input
                      onWheel={(e) => e.target.blur()}
                      disabled
                      type="text"
                      size="default"
                      formKey="maDiemDoNoiBo"
                      form={form}
                    />
                  </Form.Item>
                </Col>
              ) : (
                <Col span={6}></Col>
              )}
              {details?.maDiemDoNoiBo?.length > 12 ? (
                <Col span={6}>
                  <Form.Item name="btnlaytt">
                    <Button
                      loading={loadingBBCMIS}
                      style={{
                        marginTop: 30,
                        width: "100%",
                        background: "#44ffee",
                      }}
                      onClick={callApiBienBanTuCMIS}
                    >
                      Lấy BBTT gần nhất từ CMIS
                    </Button>
                  </Form.Item>
                </Col>
              ) : (
                <Col span={6}></Col>
              )}
            </Row>
            <Row
              gutter={24}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Col span={5}>
                <Form.Item name="ngayTreoThao" label="Ngày treo tháo ">
                  <DatePickerComponent
                    formKey="ngayTreoThao"
                    format={"YYYY-MM-DD"}
                    form={form}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="soBienBan" label="Số biên bản" required>
                  <Input
                    onWheel={(e) => e.target.blur()}
                    formKey="soBienBan"
                    type="text"
                    size="default"
                    form={form}
                    placeholder="Nhập số biên bản"
                  />
                </Form.Item>
              </Col>
              {data?.loaiCongTo && data?.thucHien === 1 ? (
                <Col span={6}>
                  <Form.Item name="thucHien" label="Thực hiện" required>
                    <Select
                      options={selectTao}
                      formKey="thucHien"
                      form={form}
                      onChange={onChangeThucHien}
                      placeholder="Chọn thực hiện"
                      disabled
                    />
                  </Form.Item>
                </Col>
              ) : (
                <Col span={6}>
                  <Form.Item name="thucHien" label="Thực hiện" required>
                    <Select
                      options={selectSua}
                      formKey="thucHien"
                      form={form}
                      onChange={onChangeThucHien}
                      placeholder="Chọn thực hiện"
                      disabled
                    />
                  </Form.Item>
                </Col>
              )}

              <Col span={6}>
                <Form.Item
                  name="nguoiThucHien"
                  label="Người thực hiện"
                  required
                >
                  <Input
                    onWheel={(e) => e.target.blur()}
                    formKey="nguoiThucHien"
                    placeholder="Chọn Thông tin điểm đo"
                    form={form}
                  />
                </Form.Item>
              </Col>

              {details?.maDiemDoNoiBo?.length > 12 && (
                <>
                  <Col span={6}>
                    <Form.Item name="loaI_TIMKIEM" valuePropName="checked">
                      <Checkbox>Treo tháo điểm đo đầu nguồn</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={17} />
                </>
              )}
            </Row>
            <Content>
              {valueThucHien === 2 || valueThucHien === 3 ? (
                <>
                  <Divider orientation="left">2.Thông tin công tơ tháo</Divider>
                  <FlexForm>
                    <Row
                      style={{
                        width: "100%",
                        display: "flex",
                        paddingLeft: "3%",
                        height: 150,
                        marginLeft: 0,
                      }}
                      gutter={24}
                    >
                      <Col span={8}>
                        <Form.Item name="maCongToThao" label="Mã công tơ">
                          <p style={{ fontWeight: 600 }}>{data?.maCongTo}</p>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name="heSoNhanThao" label="Hệ số nhân">
                          <p style={{ fontWeight: 600 }}>{data?.heSoNhan}</p>
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item
                          name="capChinhXacThao"
                          label="Cấp chính xác"
                          style={{ height: 100 }}
                        >
                          <p style={{ fontWeight: 600 }}>{data?.capChinhXac}</p>
                        </Form.Item>
                      </Col>
                      <Col span={8} style={{ paddingLeft: 0 }}>
                        <Form.Item
                          style={{ paddingLeft: "5%" }}
                          name="loaiCTThao"
                          label="Loại Công tơ "
                        >
                          <span style={{ fontWeight: 600 }}>
                            {data?.loaiCongTo}
                          </span>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name="soCuaSoThao" label="Số cửa sổ">
                          <p style={{ fontWeight: 600 }}>{data?.soCuaSo}</p>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Col
                      span={12}
                      className="flex-table"
                      style={{ marginRight: "2%" }}
                    >
                      {data?.gndnBoChiSos && data?.gndnBoChiSos.length > 0 ? (
                        <Table
                          columns={columnsThao}
                          pagination={false}
                          bordered
                          form={form}
                          dataSource={data?.gndnBoChiSos}
                        ></Table>
                      ) : (
                        <Table
                          columns={columnsThao}
                          pagination={false}
                          bordered
                          form={form}
                          dataSource={null}
                        ></Table>
                      )}
                    </Col>
                    {/* 2 */}
                  </FlexForm>
                </>
              ) : null}
              {/* 3 */}
              {valueThucHien === 1 ? (
                <>
                  <Divider orientation="left">3.Thông tin công tơ treo</Divider>
                  <Spin spinning={loadingComponent}>
                    <FlexForm>
                      <Row
                        style={{
                          width: "100%",
                          display: "flex",
                          paddingLeft: "3%",
                          height: 250,
                          marginLeft: 0,
                        }}
                        gutter={24}
                      >
                        <Col span={8}>
                          <Form.Item name="maCongToTreo" label="Mã công tơ">
                            <Input
                              style={{ fontWeight: 600 }}
                              onWheel={(e) => e.target.blur()}
                              formKey="maCongToTreo"
                              type="text"
                              size="default"
                              onKeyDown={onEnter}
                              form={form}
                              placeholder="Nhập mã công tơ"
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item name="heSoNhanTreo" label="Hệ số nhân">
                            <Input
                              style={{ fontWeight: 600 }}
                              onWheel={(e) => e.target.blur()}
                              formKey="heSoNhanTreo"
                              type="text"
                              size="default"
                              form={form}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item name="capChinhXac" label="Cấp chính xác">
                            <p style={{ fontWeight: 600 }}>
                              {data?.capChinhXac}
                            </p>
                          </Form.Item>
                        </Col>
                        <Col span={8} style={{ paddingLeft: 0 }}>
                          <Form.Item
                            style={{ paddingLeft: "5%" }}
                            name="loaiCT"
                            label="Loại Công tơ "
                          >
                            <span>{data?.loaiCongTo}</span>
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item name="soCuaSo" label="Số cửa sổ">
                            <p>{data?.soCuaSo}</p>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Col
                        span={12}
                        className="flex-table"
                        style={{ marginRight: "2%" }}
                      >
                         {data?.boChiSosTreo &&
                        data?.boChiSosTreo?.length > 0 ? (
                          <Table
                            columns={columnsTreo}
                            pagination={false}
                            bordered
                            form={form}
                            dataSource={data?.boChiSosTreo}
                          ></Table>
                        ) : dataTableTreo !== undefined ? (
                          <Table
                            columns={columnsTreoTH3}
                            pagination={false}
                            bordered
                            form={form}
                            dataSource={dataTableTreo}
                          ></Table>
                        ) : dataTableTreo === undefined ? (
                          <Table
                            columns={columnsTreoTH3}
                            pagination={false}
                            bordered
                            form={form}
                            dataSource={null}
                          ></Table>
                        ) : (
                          <Table
                            columns={columnsTreo}
                            pagination={false}
                            bordered
                            form={form}
                            dataSource={null}
                          ></Table>
                        )}
                      </Col>
                    </FlexForm>
                  </Spin>
                </>
              ) : null}
              {valueThucHien === 3 && changeTTCT === false ? (
                <>
                  <Divider orientation="left">3.Thông tin công tơ treo</Divider>
                  <Spin spinning={loadingComponent}>
                    <FlexForm>
                      <Row
                        style={{
                          width: "100%",
                          display: "flex",
                          paddingLeft: "3%",
                          marginLeft: 0,
                          height: 250,
                        }}
                        gutter={24}
                      >
                        <Col span={8}>
                          <Form.Item name="maCongToTreo" label="Mã công tơ">
                            <Input
                              style={{ fontWeight: 600 }}
                              onWheel={(e) => e.target.blur()}
                              formKey="maCongToTreo"
                              type="text"
                              size="default"
                              onKeyDown={onEnter}
                              form={form}
                              placeholder="Nhập mã công tơ"
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item name="heSoNhanTreo" label="Hệ số nhân">
                            <Input
                              onWheel={(e) => e.target.blur()}
                              style={{ fontWeight: 600 }}
                              formKey="heSoNhanTreo"
                              type="text"
                              size="default"
                              form={form}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item
                            name="capChinhXacTreo"
                            label="Cấp chính xác"
                          >
                            <p style={{ fontWeight: 600 }}>
                              {data?.capChinhXacTreo}
                            </p>
                          </Form.Item>
                        </Col>
                        <Col span={8} style={{ paddingLeft: 0 }}>
                          <Form.Item
                            style={{ paddingLeft: "5%" }}
                            name="loaiCTTreo"
                            label="Loại Công tơ "
                          >
                            <span style={{ fontWeight: 600 }}>
                              {data?.loaiCongToTreo}
                            </span>
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item name="soCuaSoTreo" label="Số cửa sổ">
                            <p style={{ fontWeight: 600 }}>
                              {data?.soCuaSoTreo}
                            </p>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Col
                        span={12}
                        className="flex-table"
                        style={{ marginRight: "2%" }}
                      >
                        {data?.boChiSosTreo &&
                        data?.boChiSosTreo?.length > 0 ? (
                          <Table
                            columns={columnsTreo}
                            pagination={false}
                            bordered
                            form={form}
                            dataSource={data?.boChiSosTreo}
                          ></Table>
                        ) : (
                          <Table
                            columns={columnsTreo}
                            pagination={false}
                            bordered
                            form={form}
                            dataSource={null}
                          ></Table>
                        )}
                      </Col>
                      {/* 2 */}
                    </FlexForm>
                  </Spin>
                </>
              ) : null}
              {valueThucHien === 3 && changeTTCT === true ? (
                <>
                  <Divider orientation="left">3.Thông tin công tơ treo</Divider>
                  <Spin spinning={loadingComponent}>
                    <FlexForm>
                      <Row
                        style={{
                          width: "100%",
                          display: "flex",
                          paddingLeft: "3%",
                          marginLeft: 0,
                          height: 250,
                        }}
                        gutter={24}
                      >
                        <Col span={8}>
                          <Form.Item name="maCongToTreo" label="Mã công tơ">
                            <Input
                              style={{ fontWeight: 600 }}
                              onWheel={(e) => e.target.blur()}
                              formKey="maCongToTreo"
                              type="text"
                              size="default"
                              onKeyDown={onEnter}
                              form={form}
                              placeholder="Nhập mã công tơ"
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="heSoNhanTreo"
                            label="Hệ số nhân treo"
                          >
                            <Input
                              onWheel={(e) => e.target.blur()}
                              style={{ fontWeight: 600 }}
                              formKey="heSoNhanTreo"
                              type="text"
                              size="default"
                              form={form}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item
                            name="capChinhXacTreo"
                            label="Cấp chính xác"
                          >
                            <p style={{ fontWeight: 600 }}>
                              {dataTreo?.capChinhXac}
                            </p>
                          </Form.Item>
                        </Col>
                        <Col span={8} style={{ paddingLeft: 0 }}>
                          <Form.Item
                            style={{ paddingLeft: "5%" }}
                            name="loaiCTTreo"
                            label="Loại Công tơ "
                          >
                            <span style={{ fontWeight: 600 }}>
                              {dataTreo?.loaI_CTO}
                            </span>
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item name="soCuaSoTreo" label="Số cửa sổ">
                            <p style={{ fontWeight: 600 }}>
                              {dataTreo?.soCuaSo}
                            </p>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Col
                        span={12}
                        className="flex-table"
                        style={{ marginRight: "2%" }}
                      >
                        {dataTableTreo &&
                        dataTableTreo?.length > 0 &&
                        layBBTuCMIS === false ? (
                          <Table
                            columns={columnsTreoTH3}
                            pagination={false}
                            bordered
                            form={form}
                            dataSource={dataTableTreo}
                          ></Table>
                        ) : layBBTuCMIS === true ? (
                          <Table
                            columns={columnsTreoTH3}
                            pagination={false}
                            bordered
                            form={form}
                            dataSource={data?.boChiSosTreo}
                          ></Table>
                        ) : (
                          <Table
                            columns={columnsTreo}
                            pagination={false}
                            bordered
                            form={form}
                            dataSource={null}
                          ></Table>
                        )}
                      </Col>
                      {/* 2 */}
                    </FlexForm>
                  </Spin>
                </>
              ) : null}
            </Content>
            <ButtonStyle>
              <Row justify="center" gutter={12}>
                <Col span={4}>
                  <Button
                    type="block"
                    // htmlType="submit"
                    size="large"
                    className="full"
                    onClick={handleCloseModal}
                  >
                    Đóng lại
                  </Button>
                </Col>
                <Col span={4}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="full"
                    loading={loadingContent}
                  >
                    Cập nhật
                  </Button>
                </Col>
              </Row>
            </ButtonStyle>
          </FormComponent>
        </LoadingComponent>
      </Container>
    </Fragment>
  );
}
const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
  .ant-table-tbody > tr > td {
    padding: 1px !important ;
  }
  .ant-form-item-has-error {
    padding-top: 0 !important;
  }
`;

const Content = styled.div`
  .ant-form-item-has-error {
    padding-top: 25px;
  }
  .ant-form-item {
    margin: 0 !important;
  }
`;
const FlexForm = styled.div`
  display: flex;
  justify-content: space-around;
  .flex-left {
    display: flex;
    flex-wrap: wrap;
    flex: 4;
  }
`;
const ButtonStyle = styled.div`
  margin-top: 20px;
`;
