import { FORMAT_ENGLISH, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData } from "@utils/request";
import { Col, Row } from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import TabThongTinDiemDo from "../tabs/tabContainer-tt-diem-do";
import ContentEditPhuongThuc from "./content-edit-phuong-thuc";
import { getLayPhuongThucManSua, getListSuaPhuongThuc } from "./services";
export default function ModalSuaPhuongThuc(props) {
  const {
    detail,
    setLoading,
    closeModal,
    form,
    setLoadingDanhSach,
    setDataDanhSach,
    getDanhSachDiemDo,
  } = props;
  const [dataTTPhuongThuc, setDataTTPhuongThuc] = useState();
  const [dataPhuongThucManSuaLeft, setDataPhuongThucManSuaLeft] = useState([]);
  const [dataPhuongThucManSuaRight, setDataPhuongThucManSuaRight] = useState(
    []
  );
  const [dataChisoTableSua, setDataChiSoTableSua] = useState([]);
  const [dataChisoTableRightSua, setDataChiSoTableRightSua] = useState([]);
  useEffect(() => {
    authGetData({
      url: `${Endpoint.LAY_THONG_TIN_DIEM_DO}?DiemDoId=${detail.id}`,
      setLoading: () => {},
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          setDataTTPhuongThuc(res.data);
        }
      },
    });
  }, [detail.id, setLoading]);

  // lấy phương thức màn sửa - thông tin phương thức giao nhận
  const getListDataPhuongThuc = useCallback(() => {
    getLayPhuongThucManSua(
      setDataPhuongThucManSuaLeft,
      setDataPhuongThucManSuaRight,
      setLoading,
      closeModal,
      form,
      detail
    );
  }, [closeModal, detail, form, setLoading]);

  useEffect(() => {
    getListDataPhuongThuc();
  }, [getListDataPhuongThuc]);

  // lấy data màn chốt chỉ số thay đổi màn sửa
  const getListChiSoSua = useCallback(() => {
    if (Object.keys(dataPhuongThucManSuaRight).length > 0) {
      const query = buildQueryString(
        parseParams({
          id: detail.id,
          ngayCuoiKyCu: moment(dataPhuongThucManSuaLeft?.ngayThayDoiCu).format(
            FORMAT_ENGLISH
          ),
          ngayCuoiKyHienTai: moment(
            dataPhuongThucManSuaRight?.ngayThayDoiCu
          ).format(FORMAT_ENGLISH),
        })
      );
      getListSuaPhuongThuc(
        setDataChiSoTableSua,
        setDataChiSoTableRightSua,
        setLoading,
        form,
        query,
        detail
      );
    }
  }, [
    dataPhuongThucManSuaLeft,
    dataPhuongThucManSuaRight,
    detail,
    form,
    setLoading,
  ]);

  useEffect(() => {
    getListChiSoSua();
  }, [getListChiSoSua]);

  return (
    <Fragment>
      <Container>
        <Row>
          <Col span={24}>
            <TabThongTinDiemDo
              dataTTPhuongThuc={dataTTPhuongThuc}
              detail={detail}
            />
          </Col>
        </Row>
        <ContentEditPhuongThuc
          dataPhuongThucManSuaLeft={dataPhuongThucManSuaLeft}
          dataPhuongThucManSuaRight={dataPhuongThucManSuaRight}
          detail={detail}
          dataChisoTableSua={dataChisoTableSua}
          dataChisoTableRightSua={dataChisoTableRightSua}
          setLoadingDanhSach={setLoadingDanhSach}
          setDataDanhSach={setDataDanhSach}
          getDanhSachDiemDo={getDanhSachDiemDo}
        />
      </Container>
    </Fragment>
  );
}
const Container = styled.div`
  margin-top: 12px;
`;
