import React, {
  memo,
  useEffect,
  useState,
  useCallback,
  Fragment,
  Suspense,
  useMemo,
} from "react";
import { Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import LapBienBanLapDatModal from "./modal-lap-bien-ban-lap-dat";
import {
  handleGetCongToCMIS,
  handleDetailBienBanLapDat,
  handleSubmitForm,
  handleGetFileBBLD,
  handleGetDataLyDoTreoThao,
  handleGetCTThaoCmis,
} from "../../services";
import { alertMessage } from "@utils/function";
import PDFModal from "@modules/cap-dien/common-ho-so/pdf-viewer";
import DSCongToModal from "@modules/cap-dien/thi-cong/modal-ds-cong-to";
import {
  CONG_TO_TREO,
  CONG_TO_THAO,
  CMIS_TREOTHAO_THICONG,
  FORMAT_YEAR,
  FORMAT_ENGLISH,
  TYPE_THIET_BI,
} from "@utils/constants";
import DatePicker from "@components/DatePicker";
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import debounce from "lodash/debounce";

function Index({
  // detailDuyetHoSo, handleUpdate,
  detail,
  closeModal,
  setFetchDataTable,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [listCongToCMIS, setListCongToCMIS] = useState([]);
  const [dataDetailBBLD, setDataDetailBBLD] = useState([]);
  const [isShowCongToThao, setIsShowCongToThao] = useState(false);
  const [isShowCongToTreo, setIsShowCongToTreo] = useState(false);
  const [isBieuGia, setIsBieuGia] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataLyDoTreoThao, setDataLyDoTreoThao] = useState([]);
  const [visible, setVisible] = useState(false);
  const [typeTreoThao, setTypeTreoThao] = useState(CONG_TO_TREO);
  const [detailCongToThaoCmis, setDetailCongToThaoCmis] = useState({});
  const [displaySearch, setDisplaySearch] = useState(false);
  const [isShowMayBienDong, setIsShowMayBienDong] = useState(false);

  //Data TI
  const [dataTI, setDataTI] = useState([]);
  const [isShowTI, setShowTI] = useState(false);

  //DataTU
  const [dataTU, setDataTU] = useState([]);
  const [isShowTU, setShowTU] = useState(false);

  //Data TI tháo
  const [dataTIThao, setDataTIThao] = useState([]);
  const [isShowTIThao, setShowTIThao] = useState(false);

  //DataTU tháo
  const [dataTUThao, setDataTUThao] = useState([]);
  const [isShowTUThao, setShowTUThao] = useState(false);

  // status active row in TU or TI
  const [rowActive, setRowActive] = useState(null);

  const [typeThietBi, setTypeThietBi] = useState("");
  //Fetch data Biên bản lắp đặt
  const fetchData = useCallback(() => {
    //call api
    handleDetailBienBanLapDat(
      detail.id,
      setLoading,
      setDataDetailBBLD,
      setIsShowCongToThao,
      setIsBieuGia,
      setDataTI,
      setDataTU,
      setShowTI,
      setShowTU,
      setDataTIThao,
      setShowTIThao,
      setDataTUThao,
      setShowTUThao
    );

    //call api
    handleGetDataLyDoTreoThao(setLoading, setDataLyDoTreoThao);
  }, [detail.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  //onChangeTreo
  const onChangeTreo = (value) => {
    if (value !== "" && value !== undefined) {
      //Nếu chọn công tơ treo thì giá trị của tháo sẽ null.
      //Đặt lại giá trị để hiển thị modal tương ứng ở hàm renderDSCT()
      setTypeTreoThao(CONG_TO_TREO);
    }
  };

  //onChangeThao
  const onChangeThao = (value) => {
    if (value !== "" && value !== undefined) {
      setTypeTreoThao(CONG_TO_THAO);
    }
  };

  //Hàm lấy công tơ
  const getListCongToCMIS = useCallback(
    (value, type) => {
      if (value !== "") {
        handleGetCongToCMIS(
          detail.id,
          value,
          setLoading,
          setListCongToCMIS,
          setVisible,
          type,
          setTypeThietBi
        );
      }
    },
    [detail.id]
  );

  //Search All
  const handleSearchCMIS = useCallback(
    (value, type) => {
      //Call API Thao
      // if (type === CONG_TO_THAO) {
      //   getListCongToCMIS(value, TYPE_THIET_BI.CT);
      //   setTypeTreoThao(CONG_TO_THAO);
      // }
      // //Call API Treo
      // if (type === CONG_TO_TREO) {
      //   getListCongToCMIS(value, TYPE_THIET_BI.CT);
      //   setTypeTreoThao(CONG_TO_TREO);
      // }
      getListCongToCMIS(value, type);
    },
    [getListCongToCMIS]
  );

  const handleCloseModal = () => {
    setVisible(false);
  };

  // Kiểm tra điều kiện để setData tương ứng Treo or Tháo
  const renderDSCT = useCallback(() => {
    return (
      visible && (
        <DSCongToModal
          loading={loading}
          setLoading={setLoading}
          visible={visible}
          closeModal={handleCloseModal}
          data={listCongToCMIS}
          typeTreoThao={typeTreoThao}
          handleSearch={handleSearchCMIS}
          dataDetailBBLD={dataDetailBBLD}
          setDataDetailBBLD={setDataDetailBBLD}
          typeThietBi={typeThietBi}
          rowActive={rowActive}
          form={form}
          isShowCongToThao={isShowCongToThao}
        />
      )
    );
  }, [
    form,
    visible,
    loading,
    listCongToCMIS,
    typeTreoThao,
    handleSearchCMIS,
    dataDetailBBLD,
    typeThietBi,
    rowActive,
    isShowCongToThao,
  ]);

  const [loadingBBLD, setLoadingBBLD] = useState(false);
  const [visibleInBBLD, setVisibleInBBLD] = useState(false);

  const showModalInBienBanLapDat = useMemo(
    () =>
      visibleInBBLD && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visibleInBBLD}
            setVisible={setVisibleInBBLD}
            title="Xem trước in biên bản lắp đặt"
            getBienBan={(setUrl) => showModalBBApGia(setUrl)}
            loadingGetBienBan={loadingBBLD}
            isDownloadExcel={false}
            isReload={false}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visibleInBBLD]
  );

  const base64toBlob = (data) => {
    try {
      const base64WithoutPrefix = data.substr(
        "data:application/pdf;base64,".length
      );
      const bytes = atob(base64WithoutPrefix);
      let length = bytes.length;
      const out = new Uint8Array(length);

      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      return new Blob([out], { type: "application/pdf" });
    } catch (error) {
      alertMessage(
        "error",
        t("modal.thong_bao"),
        "Định dạng file không hỗ trợ!"
      );
    }
  };

  const showModalBBApGia = useCallback(
    (setUrl) => {
      handleGetFileBBLD(detail.id, setLoadingBBLD, (file) => {
        const blob = base64toBlob(file);
        const url = URL.createObjectURL(blob);
        setUrl(url);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );

  // Checkbox Nhiều Biểu Giá
  const handleChange = (e) => {
    setIsBieuGia(e.target.checked);
  };
  //Checkbox Máy Biến Dòng
  const handleChangeMayBienDong = (e) => {
    //setIsShowMayBienDong(dataMayBienDong ? false : true);
    setIsShowMayBienDong(e.target.checked);
  };

  const handleChangeTI = (e) => {
    setShowTI(e.target.checked);
  };

  const handleChangeTU = (e) => {
    setShowTU(e.target.checked);
  };

  const onChangeTextNo = (e, type, index) => {
    e.preventDefault();
    const value = e.target.value;
    if (value) {
      setRowActive(index);
      handleGetCongToCMIS(
        detail.id,
        value,
        setLoading,
        setListCongToCMIS,
        setVisible,
        type,
        setTypeThietBi,
        (values) => {
          // on complete
          if (values && Object.keys(values).length > 0) {
            // todo:
          }
        }
      );
    }
  };

  const columnsTi = [
    {
      title: "Số No",
      dataIndex: "soThietBi ",
      key: "soThietBi ",
      render: (text, record, index) => (
        <Form.Item name={`soThietBi-${index}-ti`}>
          {/* <div className="table-text-left"> */}
          <Input
            value={text}
            onKeyDown={(e) =>
              e.keyCode === 13 ? onChangeTextNo(e, TYPE_THIET_BI.TI, index) : ""
            }
            // onChange={debounce(
            //   (e) => onChangeTextNo(e, TYPE_THIET_BI.TI, index),
            //   400
            // )}
            placeholder={"Nhấn enter để tìm kiếm"}
          />
          {/* </div> */}
        </Form.Item>
      ),
      width: "12%",
    },
    {
      title: "Năm sản xuất",
      dataIndex: "namSanXuat ",
      key: "namSanXuat ",
      render: (text, record, index) => (
        <Form.Item name={`namSanXuat-${index}-ti`}>
          <DatePicker
            picker="year"
            formKey={`namSanXuat-${index}-ti`}
            // format={FORMAT_YEAR}
            form={form}
            notClear={true}
            inputReadOnly={true}
          />
        </Form.Item>
      ),
      width: "10%",
    },
    {
      title: "Ngày kiểm định",
      dataIndex: "ngayKiemDinh ",
      key: "ngayKiemDinh ",
      render: (text, record, index) => (
        <Form.Item name={`ngayKiemDinh-${index}-ti`}>
          <DatePicker
            formKey={`ngayKiemDinh-${index}-ti`}
            form={form}
            // format={"MM/DD/YYYY"}
            notClear={true}
            inputReadOnly={true}
          />
        </Form.Item>
      ),
      width: "10%",
    },
    {
      title: "Loại",
      dataIndex: "loai ",
      key: "loai ",
      render: (text, record, index) => (
        <Form.Item name={`loai-${index}-ti`}>
          <Input
            value={text}
            readOnly={true}
            style={{
              backgroundColor: "#e2e2e2",
            }}
          />
        </Form.Item>
      ),
      width: "20%",
    },
    {
      title: "Tỷ số biến",
      dataIndex: "tySoBien ",
      key: "tySoBien ",
      render: (text, record, index) => (
        <Form.Item name={`tySoBien-${index}-ti`}>
          <Input
            value={text}
            readOnly={true}
            style={{
              backgroundColor: "#e2e2e2",
            }}
          />
        </Form.Item>
      ),
      width: "15%",
    },
    {
      title: "Chì hộp / Số viên",
      dataIndex: "chiHopVien ",
      key: "chiHopVien ",
      render: (text, record, index) => (
        <Form.Item name={`chiHopVien-${index}-ti`}>
          <Input
            value={text}
            style={{
              backgroundColor: "#e2e2e2",
            }}
            readOnly={true}
          />
        </Form.Item>
      ),
      width: "15%",
    },
    {
      title: "Mã (Tem kiểm định) / Số viên",
      dataIndex: "temKDVien",
      key: "temKDVien ",
      render: (text, record, index) => (
        <Form.Item name={`temKDVien-${index}-ti`}>
          <Input
            value={text}
            readOnly={true}
            style={{
              backgroundColor: "#e2e2e2",
            }}
          />
        </Form.Item>
      ),
      width: "15%",
    },
  ];

  const columnsTu = [
    {
      title: "Số No",
      dataIndex: "soThietBi ",
      key: "soThietBi ",
      render: (text, record, index) => (
        <Form.Item name={`soThietBi-${index}-tu`}>
          {/* <div className="table-text-left"> */}
          <Input
            value={text}
            onKeyDown={(e) =>
              e.keyCode === 13 ? onChangeTextNo(e, TYPE_THIET_BI.TU, index) : ""
            }
            // onChange={debounce(
            //   (e) => onChangeTextNo(e, TYPE_THIET_BI.TU, index),
            //   400
            // )}
            placeholder={"Nhấn enter để tìm kiếm"}
          />
          {/* </div> */}
        </Form.Item>
      ),
      width: "12%",
    },
    {
      title: "Năm sản xuất",
      dataIndex: "namSanXuat ",
      key: "namSanXuat ",
      render: (text, record, index) => (
        <Form.Item name={`namSanXuat-${index}-tu`}>
          <DatePicker
            picker="year"
            formKey={`namSanXuat-${index}-tu`}
            // format={FORMAT_YEAR}
            form={form}
            notClear={true}
            inputReadOnly={true}
          />
        </Form.Item>
      ),
      width: "10%",
    },
    {
      title: "Ngày kiểm định",
      dataIndex: "ngayKiemDinh ",
      key: "ngayKiemDinh ",
      render: (text, record, index) => (
        <Form.Item name={`ngayKiemDinh-${index}-tu`}>
          <DatePicker
            formKey={`ngayKiemDinh-${index}-tu`}
            form={form}
            // format={"MM/DD/YYYY"}
            notClear={true}
            inputReadOnly={true}
          />
        </Form.Item>
      ),
      width: "10%",
    },
    {
      title: "Loại",
      dataIndex: "loai ",
      key: "loai ",
      render: (text, record, index) => (
        <Form.Item name={`loai-${index}-tu`}>
          <Input
            value={text}
            style={{
              backgroundColor: "#e2e2e2",
            }}
            readOnly={true}
          />
        </Form.Item>
      ),
      width: "20%",
    },
    {
      title: "Tỷ số biến",
      dataIndex: "tySoBien ",
      key: "tySoBien ",
      render: (text, record, index) => (
        <Form.Item name={`tySoBien-${index}-tu`}>
          <Input
            value={text}
            style={{
              backgroundColor: "#e2e2e2",
            }}
            inputReadOnly={true}
          />
        </Form.Item>
      ),
      width: "15%",
    },
    {
      title: "Chì hộp / Số viên",
      dataIndex: "chiHopVien ",
      key: "chiHopVien ",
      render: (text, record, index) => (
        <Form.Item name={`chiHopVien-${index}-tu`}>
          <Input
            value={text}
            style={{
              backgroundColor: "#e2e2e2",
            }}
            inputReadOnly={true}
          />
        </Form.Item>
      ),
      width: "15%",
    },
    {
      title: "Mã (Tem kiểm định) / Số viên",
      dataIndex: "temKDVien",
      key: "temKDVien ",
      render: (text, record, index) => (
        <Form.Item name={`temKDVien-${index}-tu`}>
          <Input
            value={text}
            style={{
              backgroundColor: "#e2e2e2",
            }}
            inputReadOnly={true}
          />
        </Form.Item>
      ),
      width: "15%",
    },
  ];

  const columnsTiThao = [
    {
      title: "Số No",
      dataIndex: "soThietBi",
      key: "soThietB",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "12%",
    },
    {
      title: "Năm sản xuất",
      dataIndex: "namSanXuat",
      key: "namSanXuat",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "10%",
    },
    {
      title: "Ngày kiểm định",
      dataIndex: "ngayKiemDinh",
      key: "ngayKiemDinh",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "10%",
    },
    {
      title: "Loại",
      dataIndex: "loai",
      key: "loai",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "20%",
    },
    {
      title: "Tỷ số biến",
      dataIndex: "tySoBien",
      key: "tySoBien",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "15%",
    },
    {
      title: "Chì hộp / Số viên",
      dataIndex: "chiHopVien",
      key: "chiHopVien",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "15%",
    },
    {
      title: "Mã (Tem kiểm định) / Số viên",
      dataIndex: "temKDVien",
      key: "temKDVien",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "15%",
    },
  ];

  const columnsTuThao = [
    {
      title: "Số No",
      dataIndex: "soThietBi",
      key: "soThietBi",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "12%",
    },
    {
      title: "Năm sản xuất",
      dataIndex: "namSanXuat",
      key: "namSanXuat",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "10%",
    },
    {
      title: "Ngày kiểm định",
      dataIndex: "ngayKiemDinh",
      key: "ngayKiemDinh",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "10%",
    },
    {
      title: "Loại",
      dataIndex: "loai",
      key: "loai",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "20%",
    },
    {
      title: "Tỷ số biến",
      dataIndex: "tySoBien",
      key: "tySoBien",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "15%",
    },
    {
      title: "Chì hộp / Số viên",
      dataIndex: "chiHopVien",
      key: "chiHopVien",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "15%",
    },
    {
      title: "Mã (Tem kiểm định) / Số viên",
      dataIndex: "temKDVien",
      key: "temKDVien",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "15%",
    },
  ];

  //Submit Update data
  const onFinish = (values) => {
    const dataTITemp = [];
    if (isShowTI) {
      for (let i = 0; i < 3; i++) {
        dataTITemp.push({
          soThietBi: values[`soThietBi-${i}-ti`] ?? "",
          namSanXuat:
            values[`namSanXuat-${i}-ti`] !== null
              ? moment(values[`namSanXuat-${i}-ti`]).format(FORMAT_YEAR)
              : "",
          ngayKiemDinh: values[`ngayKiemDinh-${i}-ti`]
            ? moment(values[`ngayKiemDinh-${i}-ti`]).format(FORMAT_ENGLISH)
            : "",
          loai: values[`loai-${i}-ti`] ?? "",
          tySoBien: values[`tySoBien-${i}-ti`] ?? "",
          chiHopVien: values[`chiHopVien-${i}-ti`] ?? "",
          temKDVien: values[`temKDVien-${i}-ti`] ?? "",
        });
        delete values[`soThietBi-${i}-ti`];
        delete values[`namSanXuat-${i}-ti`];
        delete values[`ngayKiemDinh-${i}-ti`];
        delete values[`loai-${i}-ti`];
        delete values[`tySoBien-${i}-ti`];
        delete values[`chiHopVien-${i}-ti`];
        delete values[`temKDVien-${i}-ti`];
      }
    }

    const dataTUTemp = [];
    if (isShowTU) {
      for (let i = 0; i < 3; i++) {
        dataTUTemp.push({
          soThietBi: values[`soThietBi-${i}-tu`] ?? "",
          namSanXuat:
            values[`namSanXuat-${i}-tu`] !== null
              ? moment(values[`namSanXuat-${i}-tu`]).format(FORMAT_YEAR)
              : "",
          ngayKiemDinh: values[`ngayKiemDinh-${i}-tu`]
            ? moment(values[`ngayKiemDinh-${i}-tu`]).format(FORMAT_ENGLISH)
            : "",
          loai: values[`loai-${i}-tu`] ?? "",
          tySoBien: values[`tySoBien-${i}-tu`] ?? "",
          chiHopVien: values[`chiHopVien-${i}-tu`] ?? "",
          temKDVien: values[`temKDVien-${i}-tu`] ?? "",
        });
        delete values[`soThietBi-${i}-tu`];
        delete values[`namSanXuat-${i}-tu`];
        delete values[`ngayKiemDinh-${i}-tu`];
        delete values[`loai-${i}-tu`];
        delete values[`tySoBien-${i}-tu`];
        delete values[`chiHopVien-${i}-tu`];
        delete values[`temKDVien-${i}-tu`];
      }
    }
    const dataTUTempThao = [];
    if (dataTUThao) {
      for (let i = 0; i < dataTUThao.length; i++) {
        dataTUTempThao.push({
          soThietBi: dataTUThao[i].soThietBi ?? null,
          ngayKiemDinh: dataTUThao[i].ngayKiemDinh
            ? moment(dataTUThao[i].ngayKiemDinh).format(FORMAT_ENGLISH)
            : null,
          namSanXuat: dataTUThao[i].namSanXuat
            ? moment(dataTUThao[i].namSanXuat).format(FORMAT_YEAR)
            : null,
          loai: dataTUThao[i].loai ?? null,
          tySoBien: dataTUThao[i].tySoBien ?? null,
          chiHopVien: dataTUThao[i].chiHopVien ?? null,
          temKDVien: dataTUThao[i].temKDVien ?? null,
        });
      }
    }
    const dataTITempThao = [];
    if (dataTIThao) {
      for (let i = 0; i < dataTIThao.length; i++) {
        dataTITempThao.push({
          soThietBi: dataTIThao[i].soThietBi ?? null,
          ngayKiemDinh: dataTIThao[i].ngayKiemDinh
            ? moment(dataTIThao[i].ngayKiemDinh).format(FORMAT_ENGLISH)
            : null,
          namSanXuat: dataTIThao[i].namSanXuat
            ? moment(dataTIThao[i].namSanXuat).format(FORMAT_YEAR)
            : null,
          loai: dataTIThao[i].loai ?? null,
          tySoBien: dataTIThao[i].tySoBien ?? null,
          chiHopVien: dataTIThao[i].chiHopVien ?? null,
          temKDVien: dataTIThao[i].temKDVien ?? null,
        });
      }
    }

    const data = {
      hoSoId: detail.id,
      tenNhanVien: values.tenNhanVien,
      lyDoTreoThaoId: values.lyDoTreoThaoId,
      nhieuBieuGia: isBieuGia,
      soBienBan: values.soBienBan,
      congToTreo: {
        soCongTo:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.soCongTo
            ? detailCongToThaoCmis.soCongTo
            : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.soCongTo
            ? dataDetailBBLD.congToTreo.soCongTo
            : null,
        // soBienBan:
        //   typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
        //   Object.keys(detailCongToThaoCmis).length > 0 &&
        //   detailCongToThaoCmis.soBienBan
        //     ? detailCongToThaoCmis.soBienBan
        //     : values.soBienBanTreo
        //     ? values.soBienBanTreo
        //     : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.soBienBan
        //     ? dataDetailBBLD.congToTreo.soBienBan
        //     : null,
        maSoChiHom:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.maSoChiHom
            ? detailCongToThaoCmis.maSoChiHom
            : values.maSoChiHomTreo
            ? values.maSoChiHomTreo
            : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.maSoChiHom
            ? dataDetailBBLD.congToTreo.maSoChiHom
            : null,
        soVienChiHom:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.soVienChiHom
            ? detailCongToThaoCmis.soVienChiHom
            : values.soVienChiHomTreo
            ? values.soVienChiHomTreo
            : dataDetailBBLD.congToTreo &&
              dataDetailBBLD.congToTreo.soVienChiHom
            ? dataDetailBBLD.congToTreo.soVienChiHom
            : null,
        kimNiemChiSo:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.kimNiemChiSo
            ? detailCongToThaoCmis.kimNiemChiSo
            : values.kimNiemChiSoTreo
            ? values.kimNiemChiSoTreo
            : dataDetailBBLD.congToTreo &&
              dataDetailBBLD.congToTreo.kimNiemChiSo
            ? dataDetailBBLD.congToTreo.kimNiemChiSo
            : null,
        ccx:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.ccx
            ? detailCongToThaoCmis.ccx
            : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.ccx
            ? dataDetailBBLD.congToTreo.ccx
            : null,
        soTemCongQuang:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.soTemCongQuang
            ? detailCongToThaoCmis.soTemCongQuang
            : dataDetailBBLD.congToTreo &&
              dataDetailBBLD.congToTreo.soTemCongQuang
            ? dataDetailBBLD.congToTreo.soTemCongQuang
            : null,
        soLanCanhBao:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.soLanCanhBao
            ? detailCongToThaoCmis.soLanCanhBao
            : dataDetailBBLD.congToTreo &&
              dataDetailBBLD.congToTreo.soLanCanhBao
            ? dataDetailBBLD.congToTreo.soLanCanhBao
            : 0,
        tinhTrangChiNiemPhong:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.tinhTrangChiNiemPhong
            ? detailCongToThaoCmis.tinhTrangChiNiemPhong
            : values.tinhTrangNiemPhongTreo
            ? values.tinhTrangNiemPhongTreo
            : dataDetailBBLD.congToTreo &&
              dataDetailBBLD.congToTreo.tinhTrangChiNiemPhong
            ? dataDetailBBLD.congToTreo.tinhTrangChiNiemPhong
            : null,
        heSoNhanHeThong:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.heSoNhanHeThong
            ? detailCongToThaoCmis.heSoNhanHeThong
            : values.heSoNhanHeThongTreo
            ? values.heSoNhanHeThongTreo
            : dataDetailBBLD.congToTreo &&
              dataDetailBBLD.congToTreo.heSoNhanHeThong
            ? dataDetailBBLD.congToTreo.heSoNhanHeThong
            : null,
        maSoChiCongQuang:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.maSoChiCongQuang
            ? detailCongToThaoCmis.maSoChiCongQuang
            : dataDetailBBLD.congToTreo &&
              dataDetailBBLD.congToTreo.maSoChiCongQuang
            ? dataDetailBBLD.congToTreo.maSoChiCongQuang
            : null,
        soVienChiCongQuang:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.soVienChiCongQuang
            ? detailCongToThaoCmis.soVienChiCongQuang
            : dataDetailBBLD.congToTreo &&
              dataDetailBBLD.congToTreo.soVienChiCongQuang
            ? dataDetailBBLD.congToTreo.soVienChiCongQuang
            : null,
        loaiCongTo:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.loaiCongTo
            ? detailCongToThaoCmis.loaiCongTo
            : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.loaiCongTo
            ? dataDetailBBLD.congToTreo.loaiCongTo
            : null,
        maCongTo:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.maCongTo
            ? detailCongToThaoCmis.maCongTo
            : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.maCongTo
            ? dataDetailBBLD.congToTreo.maCongTo
            : null,
        maNuoc:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.maNuoc
            ? detailCongToThaoCmis.maNuoc
            : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.maNuoc
            ? dataDetailBBLD.congToTreo.maNuoc
            : null,
        namSanXuat:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.namSanXuat
            ? detailCongToThaoCmis.namSanXuat
            : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.namSanXuat
            ? dataDetailBBLD.congToTreo.namSanXuat
            : 0,
        dongDien:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.dongDien
            ? detailCongToThaoCmis.dongDien
            : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.dongDien
            ? dataDetailBBLD.congToTreo.dongDien
            : null,
        dienAp:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.dienAp
            ? detailCongToThaoCmis.dienAp
            : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.dienAp
            ? dataDetailBBLD.congToTreo.dienAp
            : null,
        hangSoK:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.hangSoK
            ? detailCongToThaoCmis.hangSoK
            : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.hangSoK
            ? dataDetailBBLD.congToTreo.hangSoK
            : null,
        maSoChiKiemDinh:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.maSoChiKiemDinh
            ? detailCongToThaoCmis.maSoChiKiemDinh
            : dataDetailBBLD.congToTreo &&
              dataDetailBBLD.congToTreo.maSoChiKiemDinh
            ? dataDetailBBLD.congToTreo.maSoChiKiemDinh
            : null,
        soVienChiKiemDinh:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.soVienChiKiemDinh
            ? detailCongToThaoCmis.soVienChiKiemDinh
            : dataDetailBBLD.congToTreo &&
              dataDetailBBLD.congToTreo.soVienChiKiemDinh
            ? dataDetailBBLD.congToTreo.soVienChiKiemDinh
            : null,
        maSoTemKiemDinh:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.maSoTemKiemDinh
            ? detailCongToThaoCmis.maSoTemKiemDinh
            : dataDetailBBLD.congToTreo &&
              dataDetailBBLD.congToTreo.maSoTemKiemDinh
            ? dataDetailBBLD.congToTreo.maSoTemKiemDinh
            : null,
        ngayKiemDinh:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.ngayKiemDinh
            ? detailCongToThaoCmis.ngayKiemDinh
            : dataDetailBBLD.congToTreo &&
              dataDetailBBLD.congToTreo.ngayKiemDinh
            ? dataDetailBBLD.congToTreo.ngayKiemDinh
            : null,
        maBDong:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.maBDong
            ? detailCongToThaoCmis.maBDong
            : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.maBDong
            ? dataDetailBBLD.congToTreo.maBDong
            : null,
        bcs:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.bcs
            ? detailCongToThaoCmis.bcs
            : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.bcs
            ? dataDetailBBLD.congToTreo.bcs
            : null,
        heSoNhan:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.heSoNhan
            ? detailCongToThaoCmis.heSoNhan
            : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.heSoNhan
            ? dataDetailBBLD.congToTreo.heSoNhan
            : null,
        serialTemKiemDinh:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.serialTemKiemDinh
            ? detailCongToThaoCmis.serialTemKiemDinh
            : dataDetailBBLD.congToTreo &&
              dataDetailBBLD.congToTreo.serialTemKiemDinh
            ? dataDetailBBLD.congToTreo.serialTemKiemDinh
            : null,
        soPha:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.soPha
            ? detailCongToThaoCmis.soPha
            : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.soPha
            ? dataDetailBBLD.congToTreo.soPha
            : 0,
        soLanSuDung:
          typeCallAPI === CMIS_TREOTHAO_THICONG.CO_CONGTOTREO &&
          Object.keys(detailCongToThaoCmis).length > 0 &&
          detailCongToThaoCmis.soLanSuDung
            ? detailCongToThaoCmis.soLanSuDung
            : dataDetailBBLD.congToTreo && dataDetailBBLD.congToTreo.soLanSuDung
            ? dataDetailBBLD.congToTreo.soLanSuDung
            : 0,
        dataTi: dataTITemp,
        dataTu: dataTUTemp,
      },
      //nếu là hồ sơ Cấp mới ->  Chỉ nhập thông tin công tơ TREO
      //nếu là hồ sơ Nâng cấp/ Di dời ->  Nhập thông tin công tơ TREO và THÁO
      //Dùng field coCongToThao để kiểm tra hồ sơ thuộc loại nào -> (true: Nâng cấp/Di dời, false: Cấp mới)

      congToThao:
        Object.keys(detailCongToThaoCmis).length > 0
          ? {
              soCongTo: detailCongToThaoCmis.soCongTo,
              // soBienBan: detailCongToThaoCmis.soBienBan,
              maSoChiHom: detailCongToThaoCmis.maSoChiHom,
              soVienChiHom: detailCongToThaoCmis.soVienChiHom,
              kimNiemChiSo: detailCongToThaoCmis.kimNiemChiSo,
              ccx: detailCongToThaoCmis.ccx,
              soTemCongQuang: detailCongToThaoCmis.soTemCongQuang,
              soLanCanhBao: detailCongToThaoCmis.soLanCanhBao,
              tinhTrangChiNiemPhong: values.tinhTrangNiemPhongThao
                ? values.tinhTrangNiemPhongThao
                : detailCongToThaoCmis.tinhTrangChiNiemPhong,
              heSoNhanHeThong: values.heSoNhanHeThongThao
                ? values.heSoNhanHeThongThao
                : detailCongToThaoCmis.heSoNhanHeThong,
              maSoChiCongQuang: detailCongToThaoCmis.maSoChiCongQuang,
              soVienChiCongQuang: detailCongToThaoCmis.soVienChiCongQuang,
              loaiCongTo: detailCongToThaoCmis.loaiCongTo,
              maCongTo: detailCongToThaoCmis.maCongTo,
              maNuoc: detailCongToThaoCmis.maNuoc,
              namSanXuat: detailCongToThaoCmis.namSanXuat,
              dongDien: detailCongToThaoCmis.dongDien,
              dienAp: detailCongToThaoCmis.dienAp,
              hangSoK: detailCongToThaoCmis.hangSoK,
              maSoChiKiemDinh: detailCongToThaoCmis.maSoChiKiemDinh,
              soVienChiKiemDinh: detailCongToThaoCmis.soVienChiKiemDinh,
              maSoTemKiemDinh: detailCongToThaoCmis.maSoTemKiemDinh,
              ngayKiemDinh: detailCongToThaoCmis.ngayKiemDinh,
              maBDong: detailCongToThaoCmis.maBDong,
              bcs: detailCongToThaoCmis.bcs,
              heSoNhan: detailCongToThaoCmis.heSoNhan,
              serialTemKiemDinh: detailCongToThaoCmis.serialTemKiemDinh,
              soPha: detailCongToThaoCmis.soPha,
              soLanSuDung: detailCongToThaoCmis.soLanSuDung,
              dataTi: dataTITempThao,
              dataTu: dataTUTempThao,
            }
          : null,
    };

    if (
      dataDetailBBLD.coCongToThao === true &&
      dataDetailBBLD.thucHienTreoThao === false &&
      dataDetailBBLD.hoSoId
    )
      delete data.congToThao;
    handleSubmitForm(
      data,
      setLoading,
      setFetchDataTable,
      form,
      closeModal,
      dispatch,
      isShowTI,
      isShowTU
    );
  };
  const [typeCallAPI, setTypeCallAPI] = useState(
    CMIS_TREOTHAO_THICONG.CO_CONGTOTHAO
  );
  useEffect(() => {
    if (
      dataDetailBBLD.coCongToThao === true &&
      dataDetailBBLD.thucHienTreoThao === true &&
      dataDetailBBLD.hoSoId
    ) {
      setDisplaySearch(true);
    } else setDisplaySearch(false);
    if (
      dataDetailBBLD.coCongToThao === true &&
      dataDetailBBLD.thucHienTreoThao === true &&
      dataDetailBBLD.hoSoId &&
      dataDetailBBLD.congToThao === null
    ) {
      handleGetCTThaoCmis(
        dataDetailBBLD.hoSoId,
        setLoading,
        setDetailCongToThaoCmis,
        setDataTIThao,
        setDataTUThao,
        setShowTIThao,
        setShowTUThao
      );
      // setDisplaySearch(true);
      setTypeCallAPI(CMIS_TREOTHAO_THICONG.CO_CONGTOTHAO);
    } else {
      // setDisplaySearch(false);
      setDetailCongToThaoCmis(dataDetailBBLD.congToThao ?? {});
    }
    if (
      dataDetailBBLD.coCongToThao === true &&
      dataDetailBBLD.thucHienTreoThao === false &&
      dataDetailBBLD.hoSoId
    ) {
      setIsShowCongToThao(false);
    }

    if (
      dataDetailBBLD.coCongToThao === true &&
      dataDetailBBLD.thucHienTreoThao === false &&
      dataDetailBBLD.hoSoId &&
      dataDetailBBLD.congToTreo === null
    ) {
      setIsShowCongToThao(false);
      handleGetCTThaoCmis(
        dataDetailBBLD.hoSoId,
        setLoading,
        setDetailCongToThaoCmis
      );
      setTypeCallAPI(CMIS_TREOTHAO_THICONG.CO_CONGTOTREO);
      setIsShowCongToTreo(false);
    } else setIsShowCongToTreo(true);
  }, [dataDetailBBLD, detail.tenLoaiYeuCau]);

  return (
    <Fragment>
      <LapBienBanLapDatModal
        dataLyDoTreoThao={dataLyDoTreoThao}
        form={form}
        handleSearch={handleSearchCMIS}
        dispatch={dispatch}
        t={t}
        onChangeTreo={onChangeTreo}
        onChangeThao={onChangeThao}
        closeModal={closeModal}
        onFinish={onFinish}
        loading={loading}
        dataDetailBBLD={dataDetailBBLD}
        isShowCongToThao={isShowCongToThao}
        isBieuGia={isBieuGia}
        handleChange={handleChange}
        setVisibleInBBLD={setVisibleInBBLD}
        displaySearch={displaySearch}
        detailCongToThaoCmis={detailCongToThaoCmis}
        typeCallAPI={typeCallAPI}
        isShowCongToTreo={isShowCongToTreo}
        columnsTi={columnsTi}
        columnsTu={columnsTu}
        columnsTiThao={columnsTiThao}
        columnsTuThao={columnsTuThao}
        isShowMayBienDong={isShowMayBienDong}
        dataTI={dataTI}
        dataTU={dataTU}
        dataTIThao={dataTIThao}
        dataTUThao={dataTUThao}
        handleChangeMayBienDong={handleChangeMayBienDong}
        isShowTI={isShowTI}
        isShowTU={isShowTU}
        isShowTIThao={isShowTIThao}
        isShowTUThao={isShowTUThao}
        handleChangeTI={handleChangeTI}
        handleChangeTU={handleChangeTU}
      />
      {showModalInBienBanLapDat}
      {renderDSCT()}
    </Fragment>
  );
}

export default memo(Index);
