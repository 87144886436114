import { Endpoint } from "@utils/endpoint";
import { authSelector } from "@state/auth";
import { Col, Form, Input, Row, Select, Button } from "antd";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import Selection from "@components/Select";
import FormComponent from "@components/Form";
import { ENUM_GCSTRANGTHAISO } from "@utils/constants";
const { Option } = Select;

export function ListFilterTraCuu(props) {
  const { handleSearch, clearFilter, filterConditions } = props;
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        DonViId: user.unitId.toString(),
      }}
    >
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="DonViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Selection
                url={Endpoint.GET_UNIT_LIST}
                form={form}
                formKey="DonViId"
              />
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="MaSoGCS" label="Mã sổ">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="MaTrangThai" label="Trạng thái">
            <Select placeholder="Tất cả" allowClear>
              <Option value={ENUM_GCSTRANGTHAISO.DA_LAY_LICH_GCS_TU_CMIS.toString()}>Đã lấy lịch GCS từ CMIS</Option>
              <Option value={ENUM_GCSTRANGTHAISO.DA_LAY_BIEU_TU_CMIS.toString()}>Đã lấy biểu từ CMIS</Option>
              <Option value={ENUM_GCSTRANGTHAISO.DA_PHAN_CONG.toString()}>Đã phân công</Option>
              <Option value={ENUM_GCSTRANGTHAISO.DANG_CAP_NHAT_MDMS.toString()}>Đang cập nhật MDMS</Option>
              <Option value={ENUM_GCSTRANGTHAISO.KET_THUC_MDMS_CHUYEN_MTB_HHU.toString()}>Kết thúc MDMS, chuyển MTB, HHU</Option>
              <Option value={ENUM_GCSTRANGTHAISO.DA_CAP_NHAT_DU_MDMS.toString()}>Đã cập nhật đủ MDMS</Option>
              <Option value={ENUM_GCSTRANGTHAISO.HHU_DA_LAY_DU_LIEU.toString()}>HHU đã lấy dữ liệu</Option>
              <Option value={ENUM_GCSTRANGTHAISO.HHU_DA_GUI_DU_DL.toString()}>HHU đã gửi đủ dữ liệu</Option>
              <Option value={ENUM_GCSTRANGTHAISO.XUAT_GHI_LAI_BANG_MTB.toString()}>Xuất ghi lại bằng MTB</Option>
              <Option value={ENUM_GCSTRANGTHAISO.CHUYEN_LAY_DL_BANG_MTB.toString()}>Chuyển lấy dữ liệu bằng MTB</Option>
              <Option value={ENUM_GCSTRANGTHAISO.DA_LAY_SO_VE_MTB.toString()}>Đã lấy sổ về MTB</Option>
              <Option value={ENUM_GCSTRANGTHAISO.MTB_DA_GUI_DU_LIEU.toString()}>MTB đã gửi dữ liệu</Option>
              <Option value={ENUM_GCSTRANGTHAISO.HOAN_TAT_DOI_SOAT_ANH_KHONG_DAT.toString()}>Hoàn tất đối soát ảnh (Không đạt)</Option>
              <Option value={ENUM_GCSTRANGTHAISO.DANG_XAC_NHAN_CAC_CAP.toString()}>Đang xác nhận các cấp</Option>
              <Option value={ENUM_GCSTRANGTHAISO.HOAN_THANH_XAC_NHAN_SO.toString()}>Hoàn thành xác nhận sổ</Option>
              <Option value={ENUM_GCSTRANGTHAISO.XUAT_GHI_LAI_BANG_MDMS.toString()}>Xuất ghi lại bằng MDMS</Option>
              <Option value={ENUM_GCSTRANGTHAISO.HOAN_THANH_DAY_VE_CMIS.toString()}>Hoàn thành đẩy sổ về CMIS</Option>
              <Option value={ENUM_GCSTRANGTHAISO.HOAN_TAT_DS_TU_DONG.toString()}>Hoàn tất đối soát tự động</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Button className="mr-24px" onClick={clear}>
          Bỏ lọc
        </Button>
        <Button type="primary" htmlType="submit" form="filter-form">
          Tìm kiếm
        </Button>
      </Row>
    </FormComponent>
  );
}
