import { Col, Row } from "antd";
import styled from "styled-components";

export const TableInfo = styled.table`
  width: 100%;
  margin-bottom: 20px;

  tr td {
    width: 50%;
    border: 1px solid;
    padding: 4px;
  }

  // tr:first-child,
  // tr:nth-child(2) {
  //   background-color: #fff2cc;
  // }

  tr td:first-child {
    border-right: unset;
  }

  tr td:not(:first-child) {
    border-left: unset;
    text-align: center;
  }

  // tr:nth-child(2n) td {
  //   border-top: unset;
  // }

  tr td span:last-child {
    margin-left: 32px;
  }
`;

export const ColCustomCheck = styled(Col)`
  display: flex;

  .ant-form-item .ant-row.ant-form-item-row {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .ant-form-item .ant-row.ant-form-item-row .ant-col.ant-form-item-control {
    width: auto;
  }
  .ant-form-item {
    margin: 0;
    flex-direction: row-reverse;
    align-items: center;
  }

  .ant-form-item-label {
    padding: 0;
  }

  .ant-form-item-label label {
    margin-left: 8px;
  }
`;

export const RowTable = styled(Row)`
  margin: 0;
  border: 1px solid #f5f5f5;
  border-bottom: unset;
  border-radius: 4px;
  margin-bottom: 12px;
  margin-top: 12px;
  width: 58%;
  margin: 0 auto;
  margin-left: 21% !important;

  .ant-form-item-explain-error {
    background: unset;
  }
  // .ant-col {
  //   padding: 8px;
  //   border-bottom: 1px solid #f5f5f5;
  //   cursor: pointer;
  // }
`;
