import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { customColumn } from "@utils/function";
import { Fragment } from "react";
import { ContainerTable } from "../../css-styled";
import ListFilter from "../../list-bo-loc";

export default function BaoCaoCapDien89Den160(props) {
  const {
    loading,
    onChangePagination,
    detailData,
    handleSearch,
    clearFilter,
    filterConditions,
    columns,
    dataSource,
    totalCount,
    handleChangeUnit,
    donVi,
    // rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,
    getListBaoCao,
    setType,
    disabledChot,
    value,
    handleChangeValue,
  } = props;
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          handleChangeUnit={handleChangeUnit}
          donVi={donVi}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          getListBaoCao={getListBaoCao}
          setType={setType}
          disabledChot={disabledChot}
          value={value}
          handleChangeValue={handleChangeValue}
        />
      </Filter>
      <ContainerTable>
        <TableComponent
          header={"Báo cáo cấp điện 89 đến 160 kVA"}
          columns={customColumn(columns, filterConditions)}
          pagination={filterConditions}
          // expandable={{ expandedRowRender, defaultExpandAllRows: true }}
          dataSource={dataSource}
          scrollX={3470}
          filterConditions={filterConditions}
          totalCount={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          onRow={detailData}
          // rowSelection={rowSelection}
        />
      </ContainerTable>
    </Fragment>
  );
}
