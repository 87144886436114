import React from "react";
import FormPhanCong from "./form";

export default function PhanCongModal(props) {
  const {
    visible,
    closeModal,
    form,
    dataTreoThao,
    dataNiemPhong,
    onFinish,
    loading,
    filterUnit,
    dataNVLog,
    disabledDate,
    // valueCot1Row1,
    // valueCot1Row2,
    // valueCot1Row3,
    // valueCot2Row1,
    // valueCot2Row2,
    // valueCot2Row3,

    // setValueCot1Row1,
    // setValueCot1Row2,
    // setValueCot1Row3,
    // setValueCot2Row1,
    // setValueCot2Row2,
    // setValueCot2Row3,
    // isDisCot1Row1,
    // isDisCot1Row2,
    // isDisCot1Row3,
    // isDisCot2Row1,
    // isDisCot2Row2,
    // isDisCot2Row3,
    // setIsDisCot1Row1,
    // setIsDisCot1Row2,
    // setIsDisCot1Row3,
    // setIsDisCot2Row1,
    // setIsDisCot2Row2,
    // setIsDisCot2Row3,
    // handleCloseAllPopup,
  } = props;
  // const [dataSearch, setDataSearch] = useState([]);
  // const [isDisCot1Row1, setIsDisCot1Row1] = useState(false);
  // const [isDisCot1Row2, setIsDisCot1Row2] = useState(false);
  // const [isDisCot1Row3, setIsDisCot1Row3] = useState(false);
  // const [isDisCot2Row1, setIsDisCot2Row1] = useState(false);
  // const [isDisCot2Row2, setIsDisCot2Row2] = useState(false);
  // const [isDisCot2Row3, setIsDisCot2Row3] = useState(false);

  // //get list search
  // const handleGetListSearch = useCallback(
  //   (column, value) => {
  //     let arr = [];
  //     const arr1 = [];

  //     dataNVLog.forEach((item) => {
  //       if (column === "cot1") {
  //         if (
  //           item.tenNhanVien
  //             .toLocaleLowerCase()
  //             .indexOf(value.toLocaleLowerCase()) > -1
  //         ) {
  //           arr1.push(item);
  //         }
  //       } else {
  //         if (
  //           item.maNhanVien
  //             .toLocaleLowerCase()
  //             .indexOf(value.toLocaleLowerCase()) > -1
  //         ) {
  //           arr1.push(item);
  //         }
  //       }
  //     });
  //     arr = arr1;
  //     setDataSearch(arr);
  //   },
  //   [dataNVLog]
  // );

  // //set close/open popup and value in input
  // const handleSwitchValue = useCallback(
  //   (value, column, row, event, value2) => {
  //     switch (column) {
  //       case "cot1":
  //         if (row === 1) {
  //           if (event !== "onClick") {
  //             setIsDisCot1Row1(true);
  //             setIsDisCot1Row2(false);
  //             setIsDisCot1Row3(false);
  //             setIsDisCot2Row1(false);
  //             setIsDisCot2Row2(false);
  //             setIsDisCot2Row3(false);
  //           }
  //           if (event !== "focus") {
  //             setValueCot1Row1(value);
  //             if (value2) setValueCot2Row1(value2);
  //           }
  //         } else if (row === 2) {
  //           if (event !== "onClick") {
  //             setIsDisCot1Row1(false);
  //             setIsDisCot1Row2(true);
  //             setIsDisCot1Row3(false);
  //             setIsDisCot2Row1(false);
  //             setIsDisCot2Row2(false);
  //             setIsDisCot2Row3(false);
  //           }
  //           if (event !== "focus") {
  //             setValueCot1Row2(value);
  //             if (value2) setValueCot2Row2(value2);
  //           }

  //           // if (event !== "focus") setValueCot1Row2(value);
  //         } else {
  //           if (event !== "onClick") {
  //             setIsDisCot1Row1(false);
  //             setIsDisCot1Row2(false);
  //             setIsDisCot1Row3(true);
  //             setIsDisCot2Row1(false);
  //             setIsDisCot2Row2(false);
  //             setIsDisCot2Row3(false);
  //           }
  //           if (event !== "focus") {
  //             setValueCot1Row3(value);
  //             if (value2) setValueCot2Row3(value2);
  //           }
  //           // if (event !== "focus") setValueCot1Row3(value);
  //         }
  //         break;

  //       default:
  //         if (row === 1) {
  //           if (event !== "onClick") {
  //             setIsDisCot1Row1(false);
  //             setIsDisCot1Row2(false);
  //             setIsDisCot1Row3(false);
  //             setIsDisCot2Row1(true);
  //             setIsDisCot2Row2(false);
  //             setIsDisCot2Row3(false);
  //           }
  //           if (event !== "focus") {
  //             setValueCot2Row1(value);
  //             if (value2) setValueCot1Row1(value2);
  //           }
  //           // if (event !== "focus") setValueCot2Row1(value);
  //         } else if (row === 2) {
  //           if (event !== "onClick") {
  //             setIsDisCot1Row1(false);
  //             setIsDisCot1Row2(false);
  //             setIsDisCot1Row3(false);
  //             setIsDisCot2Row1(false);
  //             setIsDisCot2Row2(true);
  //             setIsDisCot2Row3(false);
  //           }
  //           if (event !== "focus") {
  //             setValueCot2Row2(value);
  //             if (value2) setValueCot1Row2(value2);
  //           }
  //           // if (event !== "focus") setValueCot2Row2(value);
  //         } else {
  //           if (event !== "onClick") {
  //             setIsDisCot1Row1(false);
  //             setIsDisCot1Row2(false);
  //             setIsDisCot1Row3(false);
  //             setIsDisCot2Row1(false);
  //             setIsDisCot2Row2(false);
  //             setIsDisCot2Row3(true);
  //           }
  //           if (event !== "focus") {
  //             setValueCot2Row3(value);
  //             if (value2) setValueCot1Row3(value2);
  //           }
  //           // if (event !== "focus") setValueCot2Row3(value);
  //         }
  //     }
  //   },
  //   [
  //     setIsDisCot1Row1,
  //     setIsDisCot1Row2,
  //     setIsDisCot1Row3,
  //     setIsDisCot2Row1,
  //     setIsDisCot2Row2,
  //     setIsDisCot2Row3,
  //     setValueCot1Row1,
  //     setValueCot1Row2,
  //     setValueCot1Row3,
  //     setValueCot2Row1,
  //     setValueCot2Row2,
  //     setValueCot2Row3,
  //   ]
  // );

  // //change input value
  // const handleChangeInput = useCallback(
  //   (e, column, row) => {
  //     handleSwitchValue(e.target.value, column, row, "onChange");
  //     handleGetListSearch(column, e.target.value);
  //   },
  //   [handleGetListSearch, handleSwitchValue]
  // );

  // //click value in popup
  // const handleClick = useCallback(
  //   (value, column, row, event, value2) => {
  //     handleSwitchValue(value, column, row, event, value2);

  //     handleCloseAllPopup();
  //     setDataSearch(dataNVLog);
  //   },
  //   [dataNVLog, handleCloseAllPopup, handleSwitchValue]
  // );

  // //focus input close/open popup
  // const onFocusInput = (column, row, value) => {
  //   handleSwitchValue(value, column, row, "focus");
  //   handleGetListSearch(column, value);
  // };

  // //render popup search
  // const renderPopupSearch = useCallback(
  //   (isDisplay, clickItem, column, row) => {
  //     return (
  //       <PopupSearch
  //         style={{
  //           display: isDisplay ? "block" : "none",
  //         }}
  //       >
  //         <div style={{ position: "relative" }}>
  //           <div style={{ position: "absolute", right: "8px", top: "4px" }}>
  //             <span
  //               class="ant-input-suffix"
  //               onClick={() => handleCloseAllPopup()}
  //             >
  //               <span
  //                 role="button"
  //                 aria-label="close-circle"
  //                 tabIndex="-1"
  //                 class="anticon anticon-close-circle ant-input-clear-icon"
  //               >
  //                 <Tooltip title="Đóng bảng dữ liệu gợi ý">
  //                   <svg
  //                     viewBox="64 64 896 896"
  //                     focusable="false"
  //                     data-icon="close-circle"
  //                     width="1em"
  //                     height="1em"
  //                     fill="currentColor"
  //                     aria-hidden="true"
  //                   >
  //                     <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm1
  //65.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0
  //1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
  //                   </svg>
  //                 </Tooltip>
  //               </span>
  //             </span>
  //           </div>
  //           {dataSearch.map((item, index) => (
  //             <div
  //               className="item-search"
  //               key={index}
  //               onClick={() =>
  //                 handleClick(
  //                   clickItem === "hoTen" ? item.tenNhanVien : item.maNhanVien,
  //                   column,
  //                   row,
  //                   "onClick",
  //                   clickItem === "hoTen" ? item.maNhanVien : item.tenNhanVien
  //                 )
  //               }
  //             >
  //               {clickItem === "hoTen" ? item.tenNhanVien : item.maNhanVien}
  //             </div>
  //           ))}
  //         </div>
  //       </PopupSearch>
  //     );
  //   },
  //   [dataSearch, handleClick, handleCloseAllPopup]
  // );

  return (
    <FormPhanCong
      visible={visible}
      closeModal={closeModal}
      form={form}
      onFinish={onFinish}
      filterUnit={filterUnit}
      loading={loading}
      dataTreoThao={dataTreoThao}
      dataNiemPhong={dataNiemPhong}
      // valueCot1Row1={valueCot1Row1}
      // valueCot1Row2={valueCot1Row2}
      // valueCot1Row3={valueCot1Row3}
      // valueCot2Row1={valueCot2Row1}
      // valueCot2Row2={valueCot2Row2}
      // valueCot2Row3={valueCot2Row3}
      // isDisCot1Row1={isDisCot1Row1}
      // isDisCot1Row2={isDisCot1Row2}
      // isDisCot1Row3={isDisCot1Row3}
      // isDisCot2Row1={isDisCot2Row1}
      // isDisCot2Row2={isDisCot2Row2}
      // isDisCot2Row3={isDisCot2Row3}
      // handleChangeInput={handleChangeInput}
      // onFocusInput={onFocusInput}
      // renderPopupSearch={renderPopupSearch}
      // handleCloseAllPopup={handleCloseAllPopup}
      dataNVLog={dataNVLog}
      disabledDate={disabledDate}
    />
  );
}
