import LoadingComponent from "@components/Loading";
import TableComponent from "@components/TableComponent";
import { useCustomDebounce } from "@utils/request";
import { Table } from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { FilterTab2 } from "../common/list-bo-loc-tabs/list-bo-loc-tab2";
import { getDanhSachDiemDoDVK } from "../services";

const TableDiemDoTab2 = (props) => {
  const { columns, dataSoLuong, dataDanhSach } = props;
  const [dataDVK, setDataDVK] = useState([]);
  const [loading, setLoading] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [indexPage, setIndexPage] = useState();
  const [resultDVK, setResultDVK] = useState([]);
  const [filterConditions, setFilterConditions] = useState("");

  const debouncePageSize = useCustomDebounce(pageSize);
  const debounceSearch = useCustomDebounce(filterConditions);
  const handleSearch = useCallback(
    (resultDVK) => {
      setResultDVK(resultDVK);
      getDanhSachDiemDoDVK(
        debounceSearch,
        setLoading,
        setDataDVK,
        setIndexPage,
        pageSize
      );
    },
    [debounceSearch, pageSize]
  );

  useEffect(() => {
    if (debounceSearch === "") {
      handleSearch();
    } else {
      getDanhSachDiemDoDVK(debounceSearch, setLoading, setDataDVK);
    }
  }, [debounceSearch, handleSearch]);

  useEffect(() => {
    getDanhSachDiemDoDVK(
      debounceSearch,
      setLoading,
      setDataDVK,
      setIndexPage,
      pageSize
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);
  return (
    <Fragment>
      <FilterTab2
        handleSearch={handleSearch}
        resultDVK={resultDVK}
        setFilterConditions={setFilterConditions}
        dataSoLuong={dataSoLuong}
        dataDanhSach={dataDanhSach}
      ></FilterTab2>
      <LoadingComponent loading={loading}>
        <div
          className="loading-scrollbar"
          style={{ paddingTop: 10, width: "100%" }}
        >
          <Scrollbars
            style={{ height: "70vh" }}
            autoHide
            onScrollFrame={(e) => {
              if (e.top * 100 > 99 && pageSize < indexPage.totalCount) {
                setPageSize(debouncePageSize + 20);
              }
            }}
          >
            <div className="f" style={{ width: 1800 }}>
              <TableComponent
                dataSource={dataDVK}
                columns={columns}
                totalCount={indexPage}
                rowKey="id"
                scroll={{ x: 600 }}
                loading={loading}
                pagination={false}
                bordered
              />
            </div>
          </Scrollbars>
        </div>
      </LoadingComponent>
    </Fragment>
  );
};
export default TableDiemDoTab2;
