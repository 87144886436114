import { createSlice } from "@reduxjs/toolkit";
export const namespace = "yeuCauDuocPhanCong";

const INITAL_STATE = {
  saveData: false,
  canShowDialogLeavingPage: false,
};

const slice = createSlice({
  name: namespace,
  initialState: INITAL_STATE,
  reducers: {
    getSaveDataPopup: (state, action) => {
      return {
        ...state,
        saveData: action.payload,
      };
    },
    setCanShowDialogLeaving: (state, action) => {
      return {
        ...state,
        canShowDialogLeavingPage: action.payload,
      };
    },
  },
});

export const reducer = slice.reducer;

export const { getSaveDataPopup, setCanShowDialogLeaving } = slice.actions;

export const yeuCauDuocPhanCongSelector = (state) => state[namespace];
