import FormComponent from "@components/Form";
import { removeAccents } from "@utils/function";
import { Button, Col, Form, Input, Row, Select } from "antd";

const Boloc = ({ listLoaiTaiLieu = [], form, handleSearch = () => {}, clearFilter = () => {} }) => {

  return (
    <FormComponent
      layout="vertical"
      form={form}
      onFinish={handleSearch}
      initialValues={{
        loaiTaiLieuId: "",
        tenTaiLieu: "",
      }}
    >
      <Row gutter={[8, 16]} className="align-items">
        <Col span={6}>
          <Form.Item name="loaiTaiLieuId" label="Loại tài liệu">
            <Select
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return removeAccents(option.children).toLowerCase().indexOf(removeAccents(input).toLowerCase()) >= 0;
                }
              }}
              allowClear
            >
              {listLoaiTaiLieu &&
                listLoaiTaiLieu.map((item, id) => {
                  return (
                    <Select.Option key={id} value={item.value}>
                      {item.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item name="tenTaiLieu" label="Tìm theo tên tài liệu">
            <Input allowClear/>
          </Form.Item>
        </Col>
        <Col span={6} offset={1}>
          <Button onClick={clearFilter} className="mr-10px">
            Bỏ lọc
          </Button>
          <Button type="primary" htmlType="submit">
            Tìm kiếm
          </Button>
        </Col>
      </Row>
    </FormComponent>
  );
};
export default Boloc;
