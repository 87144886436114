import { all, fork, call, takeLatest, put } from "redux-saga/effects";
import { STATUSCODE_200, TOKEN_NAME } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authGet } from "@utils/request";
import {
  signInFinish,
  saveUser,
  signOut,
  getUserProfile,
  getMenuList,
  saveMenuByModule,
} from "./reducer";
import { saveDongBoImageScan } from "@state/cap-dien/tai-anh-scan/reducer";
function* checkAuthenticated() {
  yield fork(() => watchGetProfile());
}

function* watchGetProfile() {
  const token = localStorage.getItem(TOKEN_NAME);
  if (token) {
    const res = yield call(authGet, Endpoint.GET_PROFILE);
    if (res && res.data.statusCode === STATUSCODE_200) {
      yield put(saveUser(res.data.data));
      yield put(saveDongBoImageScan(true));
    } else {
      yield put(signOut());
    }
  } else {
    yield put(signOut());
  }
}

function* watchGetLeftMenuByModule({ payload }) {
  const res = yield call(
    authGet,
    `${Endpoint.MENU}/display?moduleId=${payload.moduleId}`
  );
  if (res && res.data.statusCode === STATUSCODE_200) {
    yield put(saveMenuByModule(res.data.data));
  }
}

export function* rootSagas() {
  yield all([
    fork(() => checkAuthenticated()),
    takeLatest(getUserProfile, watchGetProfile),
    takeLatest(signInFinish, watchGetProfile),
    takeLatest(getMenuList, watchGetLeftMenuByModule),
  ]);
}
