import FormComponent from "@components/Form";
import {
  ENUM_DS_YEU_CAU,
  FORMAT_DATE,
  FORMAT_ENGLISH,
  FUNC_ID_DSYC_TREOTHAO,
  MONTH_STRING,
} from "@utils/constants";
import { Button, Col, Form, Input, Row } from "antd";
import { useCallback, useState } from "react";
import DanhSachYeuCau from "../../common-ver1/danh-sach-yeu-cau";
import DatePickerComponent from "@components/DatePicker";
import moment from "moment";
import {
  handleGanDoi,
  // handleGetDataLyDoTreoThao,
  handleGetDoi,
  tiepNhanYeuCauCMIS,
  // handleUpdateLydo,
  callAPIRemoveYC,
} from "./services";
import LoadingComponent from "@components/Loading";
import CheckPermission from "@components/CheckPermission";
import { treo_thao } from "@permissions/b_treothao";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import { useEffect } from "react";
import ModalGanDoi from "./modal-gan-doi";
import ModalNoti from "./modal-noti-cmis";
// import ModalLyDo from "./modal-ly-do";
// import { alertMessage } from "@utils/function";

export default function Index() {
  const [fetchList, setFetchList] = useState(false);
  const { user } = useSelector(authSelector);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  //const [loadingLyDo, setLoadingLyDo] = useState(false);
  const [isTongCongTy, setIsTongCongTy] = useState(
    user.isTongCongTy ? true : false
  );
  const [donViId, setDonViId] = useState(user.unitId);
  const [tenDonVi, setTenDonVi] = useState(user.unitName);
  const [dataDoi, setDataDoi] = useState([]);
  //const [dataLyDo, setDataLyDo] = useState([]);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  // const [formlyDo] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [visibled, setVisibled] = useState(false);
  // const [visibledLyDo, setVisibledLyDo] = useState(false);
  const [dataNoti, setDataNoti] = useState({});
  //const [disabledLyDo, setDisabledLyDo] = useState(true);
  // const [loaiTreoThao, setLoaiTreoThao] = useState();
  //CALL API ĐỒNG BỘ
  const handleCMIS = (values) => {
    const payload = {
      ...values,
    };
    tiepNhanYeuCauCMIS(
      payload,
      setLoading,
      setFetchList,
      setVisibled,
      setDataNoti
    );
  };
  //
  const getDataDoi = useCallback(() => {
    handleGetDoi(setLoading, setDataDoi, donViId);
  }, [donViId]);

  useEffect(() => {
    getDataDoi();
  }, [getDataDoi]);

  //button nhận yêu cầu
  const genExtra = () => (
    <div style={{ display: "flex" }}>
      <CheckPermission permissionCode={treo_thao.b11g}>
        <Button
          type="primary"
          className="mr-24px"
          disabled={isTongCongTy || !selectedRowKeys.length}
          onClick={() => handleOpenModal()}
          style={{ width: "100%" }}
          loading={loading2}
        >
          Gán đội
        </Button>
      </CheckPermission>

      <CheckPermission permissionCode={treo_thao.b11x}>
        <Button
          danger
          disabled={!selectedRowKeys.length}
          onClick={() => handleDeleteYC()}
          style={{ width: "100%" }}
          loading={loading}
        >
          Xóa yêu cầu
        </Button>
      </CheckPermission>

      {/* <Popover
        content={contentAction}
        placement="bottom"
        title="Thao tác"
        trigger="hover"
      >
        <Button className="btn-bg-yellow">Thao tác</Button>
      </Popover> */}
    </div>
  );

  const handleDeleteYC = () => {
    callAPIRemoveYC(
      selectedRowKeys,
      setLoading,
      setFetchList,
      setSelectedRowKeys
    );
  };

  //Hiển thị checkbox ở đầu mỗi bảng
  /**
   *
   * @param {Array} selectedRowKeys
   * @param {Array} selectedRows
   */
  const onSelectChange = (selectedRowKeys) => {
    // if (selectedRows.length > 0) {
    //   let disabled = false;
    //   let distinct = selectedRows[0].loaiTreoThao;
    //   if (selectedRows && selectedRows.length > 1) {
    //     const newArr = [];
    //     for (let i = 0; i < selectedRows.length; i++) {
    //       newArr.push(selectedRows[i].loaiTreoThao);
    //     }
    //     distinct = newArr[0];
    //     for (let j = 0; j < newArr.length; j++) {
    //       if (distinct !== newArr[j]) {
    //         disabled = true;
    //       }
    //     }
    //   }
    //   //Kiểm tra nếu disable là false, thì mới gán giá trị loaiTreoThao
    //   // if (!disabled) {
    //   //   setLoaiTreoThao(distinct);
    //   // }
    //   //setDisabledLyDo(disabled);
    // }
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  const handleOpenModal = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  // const handleOpenModalLyDo = useCallback(() => {
  //   if (disabledLyDo) {
  //     alertMessage(
  //       "warning",
  //       "Thông báo!",
  //       "Không thể cập nhật lý do khi khác loại treo tháo!"
  //     );
  //   } else {
  //     setVisibledLyDo(!visibledLyDo);
  //   }
  // }, [visibledLyDo, disabledLyDo]);

  const handleCloseModal = () => {
    setVisible(false);
  };

  const handleCloseModalNoti = () => {
    setVisibled(false);
  };

  // const handleCloseModalLyDo = () => {
  //   setVisibledLyDo(false);
  // };

  const showModal = useCallback(() => {
    return (
      visible && (
        <ModalGanDoi
          visible={visible}
          closeModal={handleCloseModal}
          dataDoi={dataDoi}
          tenDonVi={tenDonVi}
          onFinish={onFinish}
          form={form2}
          loading={loading2}
        />
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDoi, visible, tenDonVi]);

  const showModalNoti = useCallback(() => {
    return (
      visibled && (
        <ModalNoti
          visible={visibled}
          closeModal={handleCloseModalNoti}
          data={dataNoti}
        />
      )
    );
  }, [visibled, dataNoti]);

  // const showModalLyDo = useCallback(() => {
  //   return (
  //     visibledLyDo && (
  //       <ModalLyDo
  //         visible={visibledLyDo}
  //         closeModal={handleCloseModalLyDo}
  //         dataLyDo={dataLyDo}
  //         tenDonVi={tenDonVi}
  //         onFinish={onFinishLyDo}
  //         form={formlyDo}
  //         loading={loadingLyDo}
  //       />
  //     )
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dataLyDo, visibledLyDo, tenDonVi]);

  const onFinish = (values) => {
    const payload = {
      ids: selectedRowKeys,
      ...values,
    };
    handleGanDoi(
      setLoading2,
      payload,
      handleCloseModal,
      setFetchList,
      setSelectedRowKeys,
      form2
    );
  };
  /**
   *
   * @param {*} values
   */
  // const onFinishLyDo = (values) => {
  //   if (selectedRowKeys.length > 0 && values.lyDoId !== undefined) {
  //     const payload = {
  //       ids: selectedRowKeys.toString(),
  //       ...values,
  //     };
  //     handleUpdateLydo(
  //       setLoadingLyDo,
  //       payload,
  //       handleCloseModalLyDo,
  //       setFetchList,
  //       setSelectedRowKeys,
  //       formlyDo
  //     );
  //   } else {
  //     alertMessage("warning", "Thông báo!", "Lý do không được để trống!");
  //   }
  // };

  const genExtraDongBo = () => (
    <CheckPermission permissionCode={treo_thao.b11db}>
      <FormComponent
        form={form}
        name="dong-bo-yc"
        onFinish={handleCMIS}
        layout="horizonal"
        initialValues={{}}
        autoComplete="off"
      >
        <Row gutter={24} style={{ alignItems: "baseline", height: "32px" }}>
          <Col span={24} md={5}>
            <Form.Item name="maDiemDo" style={{ margin: 0 }}>
              <Input
                placeholder="Nhập mã điểm đo"
                allowClear
                // onChange={handleChangeMaDD}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={5}>
            <Form.Item name="maSoGCS" style={{ margin: 0 }}>
              <Input
                placeholder="Nhập sổ GCS"
                allowClear
                // onChange={handleChangeMaKH}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={5}>
            <Form.Item name="tuNgay" style={{ margin: 0 }}>
              <DatePickerComponent
                defaultValue={moment(
                  moment().startOf(MONTH_STRING),
                  FORMAT_DATE
                )}
                format={FORMAT_ENGLISH}
                form={form}
                formKey={"tuNgay"}
                notClear={true}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={5}>
            <Form.Item name="denNgay" style={{ margin: 0 }}>
              <DatePickerComponent
                defaultValue={moment(moment(), FORMAT_DATE)}
                format={FORMAT_ENGLISH}
                form={form}
                formKey={"denNgay"}
                notClear={true}
              />
            </Form.Item>
          </Col>
          <Col
            span={24}
            md={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              form="dong-bo-yc"
              disabled={isTongCongTy}
              style={{
                width: "100%",
                // marginRight: "12px",
              }}
            >
              Đồng bộ CMIS
            </Button>
          </Col>
        </Row>
      </FormComponent>
    </CheckPermission>
  );

  return (
    <LoadingComponent loading={loading}>
      <DanhSachYeuCau
        funcId={FUNC_ID_DSYC_TREOTHAO.PKD_TIEPNHAN}
        typeList={ENUM_DS_YEU_CAU.PKD_TIEPNHANYEUCAU}
        genExtra={genExtra}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        setIsTongCongTy={setIsTongCongTy}
        setDonViId={setDonViId}
        // dataDongBo={dataDongBo}
        rowSelection={rowSelection}
        setTenDonVi={setTenDonVi}
        setSelectedRowKeys={setSelectedRowKeys}
        genExtraDongBo={genExtraDongBo}
      />
      {showModal()}
      {showModalNoti()}
      {/* {showModalLyDo()} */}
    </LoadingComponent>
  );
}
