import { CheckCircleFilled } from "@ant-design/icons";
import CheckPermission from "@components/CheckPermission";
import TableComponent from "@components/TableComponent";
import Thanglamviec from "@modules/giao-nhan-dien-nang/common/pdf-viewer/Thanglamviec";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import { handlePagination, parseParams } from "@utils/function";
import { Button, Spin } from "antd";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import ModalThongTinDonViGiaoNhan from "./modal-thong-tin-don-vi-giao-nhan";
import { handleListDataDanhMucDVGiaoNhan, handleRemoveDVGN } from "./service";

export default function DanhMucDonViGiaoNhan() {
  const [visibleDonViGiaoNhan, setVisibleDonViGiaoNhan] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [dataDVGiaoNhan, setDataDVGiaoNhan] = useState([]);
  const [detailDVGN, setDetailDVGN] = useState({});

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  //fetch dữ liệu
  const getListDataDanhMucDVGiaoNhan = useCallback(() => {
    handleListDataDanhMucDVGiaoNhan(setLoading, location, setDataDVGiaoNhan);
  }, [location]);

  useEffect(() => {
    getListDataDanhMucDVGiaoNhan();
  }, [getListDataDanhMucDVGiaoNhan]);

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDetailDVGN(row);
      else setDetailDVGN({});
      setVisibleDonViGiaoNhan(true);
    },
    [visibleDonViGiaoNhan]
  );

  const handleCloseModal = () => {
    setVisibleDonViGiaoNhan(false);
    setLoading(false);
  };

  const showModal = useMemo(
    () =>
      visibleDonViGiaoNhan && (
        <ModalThongTinDonViGiaoNhan
          detailDataTable={detailDVGN}
          visible={visibleDonViGiaoNhan}
          closeModal={handleCloseModal}
          getListDataDanhMucDVGiaoNhan={getListDataDanhMucDVGiaoNhan}
        />
      ),
    [visibleDonViGiaoNhan, dataDVGiaoNhan, getListDataDanhMucDVGiaoNhan]
  );

  //permission button them moi
  const genExtra = () => (
    <div>
      <Button
        size="default"
        type="primary"
        className="mr-5px"
        onClick={() => handleOpenModal()}
      >
        Tạo mới
      </Button>
    </div>
  );

  const columns = [
    {
      title: "Đơn vị giao nhận",
      dataIndex: "maDonVi",
      key: "maDonVi",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "20%",
    },
    {
      title: "",
      dataIndex: "laDongBo",
      key: "laDongBo",
      render: (text) => (
        <div className="table-text-center">
          {text ? (
            true
          ) : (
            <CheckCircleFilled style={{ fontSize: "20px", color: "#03A4FF" }} />
          )}
        </div>
      ),
      width: "5%",
    },
    {
      title: "Tên đơn vị",
      dataIndex: "tenDonVi",
      key: "tenDonVi",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "30%",
    },
    {
      title: "Trạng Thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text) => (
        <div className="table-text-left">
          <span>{text === 1 && "Đang hoạt động"}</span>
          <span>{text === 0 && "Ngừng hoạt động"}</span>
        </div>
      ),
      width: "30%",
    },
    {
      title: "",
      dataIndex: "",
      key: "tacVu",
      render: (text, record) => (
        <div className="action-table-column">
          {record.laDongBo ? (
            true
          ) : (
            <>
              {" "}
              <CheckPermission>
                <Button
                  type="primary"
                  style={{ marginRight: 8 }}
                  onClick={() => handleOpenModal(record)}
                >
                  Sửa
                </Button>
              </CheckPermission>
              <CheckPermission>
                <Button
                  type="danger"
                  onClick={() => handleDeleteDVGN(record.id)}
                >
                  Xóa
                </Button>
              </CheckPermission>
            </>
          )}
        </div>
      ),
      // width: "25%",
    },
  ];

  //xóa dữ liệu
  const handleDeleteDVGN = useCallback(
    (ids) => {
      handleRemoveDVGN(ids, setLoading, getListDataDanhMucDVGiaoNhan);
    },
    [getListDataDanhMucDVGiaoNhan]
  );

  return (
    <Fragment>
      <Container>
        <div style={{ padding: "10px 10px 0 10px" }}>
          <Thanglamviec />
        </div>
        <Spin spinning={loading}>
          <TableComponent
            header="Danh sách đơn vị giao nhận"
            columns={columns}
            dataSource={dataDVGiaoNhan}
            renderExtraAction={() => genExtra()}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            scrollY={"62vh"}
          />
        </Spin>
      </Container>
      {showModal}
    </Fragment>
  );
}

const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
`;
