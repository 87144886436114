import {
  Fragment,
  memo,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import InHoSoCMISModal from "./modal-in-ho-so-CMIS";
import { handleAnhHienTruong, handleFileTaiLieu, handleList } from "./services";
import PDFModal from "@modules/cap-dien/common-ho-so/pdf-viewer";
import { alertMessage, FILE_TYPES, getFileType } from "@utils/function";

function InHoSoCMIS({ visible, handleCloseModal, detail }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [visiblePDF, setVisiblePDF] = useState(false);
  const [fileData, setFileData] = useState(false);
  const [visibleImage, setVisibleImage] = useState(false);
  const [listImage, setListImage] = useState([]);
  const [title, setTitle] = useState("biên bản CMIS");

  const getListData = useCallback((hoSoId) => {
    handleList(hoSoId, setLoading, setData);
  }, []);

  const base64toBlob = useCallback((data, hasPrefix) => {
    try {
      const base64WithoutPrefix = hasPrefix
        ? data.substr("data:application/pdf;base64,".length)
        : data;
      const bytes = atob(base64WithoutPrefix);
      let length = bytes.length;
      const out = new Uint8Array(length);

      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      return new Blob([out], { type: "application/pdf" });
    } catch (error) {
      alertMessage(
        "error",
        t("modal.thong_bao"),
        "Định dạng file không hỗ trợ!"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (detail?.id) {
      getListData(detail?.id);
    }
  }, [detail?.id, getListData]);

  //khai bái columns

  const columns = [
    {
      title: t("ap_gia_ho_so.stt"),
      dataIndex: "stt",
      key: "stt",
      fixed: "left",
      render: (item, record, index) => (
        <div style={{ textAlign: "center" }}>
          <span>{index + 1}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: "Tên loại hồ sơ",
      dataIndex: "tenLoaiHoSo",
      key: "tenLoaiHoSo",
      width: "82%",
    },
    {
      title: t("tac_vu"),
      key: "action",
      align: "center",
      fixed: "right",
      width: "10%",
      render: (row) => (
        <div style={{ textAlign: "center", cursor: "pointer" }}>
          <div
            style={{ color: "#03A4FF", fontSize: 14 }}
            onClick={() => showViewPDF(row)}
          >
            Xem
          </div>
        </div>
      ),
    },
  ];
  const showViewPDF = useCallback(
    (row) => {
      if (row !== undefined) {
        if (
          row.isPdf !== null &&
          row.hoSoId !== null &&
          row.maLoaiHoSo !== null
        ) {
          if (row.isPdf) {
            // get tai lieu
            handleFileTaiLieu(
              row.hoSoId,
              setLoading,
              (data) => {
                if (data && Object.keys(data) && Object.keys(data).length > 0) {
                let hasPrefix = false;
                const fileType = getFileType(data?.fileData);
                if (fileType === FILE_TYPES.BASE64) {
                  hasPrefix = true;
                } else {
                  hasPrefix = false;
                }
                const blob = base64toBlob(data?.fileData, hasPrefix);
                const url = URL.createObjectURL(blob);
                setFileData(url);
                setVisiblePDF(true);
                setTitle(data?.tenLoaiHoSo ?? "biên bản CMIS");
                } else {
                  alertMessage(
                    "error",
                    "Thông báo",
                    `${row.tenLoaiHoSo} không có dữ liệu!`
                  );
                }
              },
              row.maLoaiHoSo
            );
          } else {
            //get list image
            handleAnhHienTruong(
              row.hoSoId,
              setLoading,
              (arrayBienBan) => {
                if (Array.isArray(arrayBienBan) && arrayBienBan.length > 0) {
                  setListImage(arrayBienBan);
                  setVisiblePDF(false);
                  setVisibleImage(true);
                  setTitle("");
                } else {
                  alertMessage(
                    "error",
                    "Thông báo",
                    `${row.tenLoaiHoSo} không có hình ảnh!`
                  );
                }
              },
              row.maLoaiHoSo
            );
          }
        } else {
          alertMessage(
            "error",
            "Thông báo",
            `${row.tenLoaiHoSo} không có dữ liệu!`
          );
        }
      }
    },
    [base64toBlob]
  );

  const showModalInBienBan = useMemo(
    () =>
      visiblePDF && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visiblePDF}
            setVisible={setVisiblePDF}
            title={title}
            getBienBan={(setUrl) => setUrl(fileData)}
            loadingGetBienBan={false}
            isDownloadExcel={false}
            isReload={false}
            type={title}
          />
        </Suspense>
      ),
    [visiblePDF, title, fileData]
  );

  return (
    <Fragment>
      <InHoSoCMISModal
        t={t}
        visible={visible}
        handleCloseModal={handleCloseModal}
        form={form}
        loading={loading}
        data={data}
        columns={columns}
        setVisibleImage={setVisibleImage}
        visibleImage={visibleImage}
        listImage={listImage}
      />
      {showModalInBienBan}
    </Fragment>
  );
}

export default memo(InHoSoCMIS);
