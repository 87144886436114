import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { downloadFile } from "@utils/request";

export const selectBaoCaoSMS = [
  {
    label: "Biểu 1: Tổng hợp tin nhắn toàn Tổng công ty",
    value: Endpoint.B1_TH_TIN_NHAN_TOAN_CONG_TY,
  },
  {
    label: "Biểu 2: Chi tiết số lượng tin nhắn theo từng Công ty Điện Lực",
    value: Endpoint.B2_CT_SL_TN_THEO_CTY_DIEN_LUC,
  },
  {
    label: "Biểu 3: Tổng hợp số lượng tin nhắn đã thực hiện theo từng nhà mạng",
    value: Endpoint.B3_TH_TIN_NHAN_THEO_TUNG_NHA_MANG,
  },
  {
    label: "Biểu 4_CC: Đối soát số lượng tin nhắn đã thực hiện thành công",
    value: Endpoint.B4_DOI_SOAT_SL_TIN_NHAN_THANH_CONG,
  },
  {
    label:
      "Biểu 5: Chi tiết số lượng tin nhắn thành công theo từng công ty Điện lực",
    value: Endpoint.B5_CHI_TIET_SL_TIN_NHAN_THANH_CONG,
  },
  {
    label: "Biểu 7: Danh sách tin nhắn chưa gửi cho khách hàng",
    value: Endpoint.B7_DS_TN_CHUA_GUI_KH,
  },
  {
    label: "Biểu 8: Đối soát theo ngày",
    value: Endpoint.B8_DOI_SOAT_THEO_NGAY,
  },
  {
    label: "Biểu 9: Đối soát ngày theo đơn vị",
    value: Endpoint.B9_DOI_SOAT_NGAY_THEO_DON_VI,
  },
];
export const selectTabDVK = [
  {
    label: "Biểu 1: Tổng hợp tin nhắn toàn Tổng công ty",
    value: Endpoint.B1_DVK_TH_TIN_NHAN_TOAN_CONG_TY,
  },
  {
    label: "Biểu 2: Chi tiết số lượng tin nhắn theo từng Công ty Điện Lực",
    value: Endpoint.B2_DVK_CT_SL_TN_THEO_CTY_DIEN_LUC,
  },

  {
    label: "Biểu 4_CC: Đối soát số lượng tin nhắn đã thực hiện thành công",
    value: Endpoint.B4_DVK_DOI_SOAT_SL_TIN_NHAN_THANH_CONG,
  },
];
export const selectTabTH = [
  {
    label: "Biểu 1: Tổng hợp tin nhắn toàn Tổng công ty",
    value: Endpoint.B1_TH_TH_TIN_NHAN_TOAN_CONG_TY,
  },
  {
    label: "Biểu 2: Chi tiết số lượng tin nhắn theo từng Công ty Điện Lực",
    value: Endpoint.B2_TH_CT_SL_TN_THEO_CTY_DIEN_LUC,
  },
  {
    label: "Biểu 4_CC: Đối soát số lượng tin nhắn đã thực hiện thành công",
    value: Endpoint.B4_TH_DOI_SOAT,
  },
  {
    label:
      "Biểu 4_CC: Đối soát số lượng tin nhắn đã thực hiện thành công (Đã gộp EP)",
    value: Endpoint.B4_TH_DOI_SOAT_GOP_EP,
  },
];
export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.MaDonVi,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};
