/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { Fragment, useState, useEffect, memo, useRef } from "react";
import { Select, Checkbox, Form, Col, Input, Row, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import {
  ONG,
  CO_QUAN,
  FORMAT_DATE,
  TYPE_LOAIYEUCAU_HOSO,
  BA,
  MUC_DICH,
  ENUM_SOPHA,
  ENUM_MUCDICHSINHHOAT,
} from "@utils/constants";
import styled from "styled-components";
import locale from "antd/es/date-picker/locale/vi_VN";
import moment from "moment";
import {
  handleDataDiaChinh,
  handleDataDiaChinhGiaoDich,
  handleDataThongTinKHCMIS,
  handleMultileListXaPhuong,
  handleMultileListXaPhuongGD,
} from "../services";
import { useDispatch, useSelector } from "react-redux";
import {
  tiepNhanPhanCongSelector,
  saveLoaiYeuCauDichVu,
} from "@state/cap-dien/giao-dich-vien/tiep-nhan-phan-cong/reducer";
const { Search } = Input;
const { Option } = Select;

function PanelsThongTinChung(props) {
  const { loaiYeuCau } = useSelector(tiepNhanPhanCongSelector);
  const { detail, form, dataDiaChinh, dataGioiTinhKH, dataLanhDao } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [displayNguoiDaiDien, setDisplayNguoiDaiDien] = useState(
    parseInt(detail.gioiTinhKhachHang) === 3 ? false : true
  );
  const [displayQuanHuyen, setDisplayQuanHuyen] = useState(
    detail.flagDiaChiNgoai ? true : false
  );
  const dispatch = useDispatch();
  const [displayLanhDao, setDisplayLanhDao] = useState();
  const [dataXaPhuong, setDataXaPhuong] = useState([]);
  const [dataXaPhuongGiaoDich, setDataXaPhuongGiaoDich] = useState([]);

  const [disabledXaPhuong, setDisabledXaPhuong] = useState(
    detail.xaPhuong ? true : false
  );
  const [disabledXaPhuongGiaoDich, setDisabledXaPhuongGiaoDich] = useState(
    detail.xaPhuongGiaoDich ? true : false
  );
  const [defaultValueTenCoQuan, setDefaultValueTenCoQuan] = useState(
    detail.tenCoQuan ? detail.tenCoQuan : ONG
  );
  const inputRef = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const [dataDiemDoHienTai, setDataDiemDoHienTai] = useState({});
  // handle change giới tính cơ quan

  const handleCoQuan = (value) => {
    setDefaultValueTenCoQuan(value);
    if (value === CO_QUAN) {
      setDisplayNguoiDaiDien(false);
      const values = form.getFieldValue();
      form.setFieldsValue({
        ...values,
        gioiTinhNguoiDaiDien: detail.gioiTinhNguoiDaiDien,
        tenNguoiDaiDien: detail.tenNguoiDaiDien,
        chucVuNguoiDaiDien: detail.chucVuNguoiDaiDien,
      });
    } else {
      setDisplayNguoiDaiDien(true);
      const values = form.getFieldValue();
      form.setFieldsValue({
        ...values,
        gioiTinhNguoiDaiDien: detail.gioiTinhNguoiDaiDien
          ? ONG
          : detail.gioiTinhNguoiDaiDien,
        tenNguoiDaiDien: null,
        chucVuNguoiDaiDien: null,
      });
    }
  };
  // handle display quận huyện

  const handleDisplayQuanHuyen = (e) => {
    if (e.target.checked) {
      form.setFieldsValue({
        quanHuyenGiaoDich: null,
        xaPhuongGiaoDich: null,
      });
    } else {
      form.setFieldsValue({
        quanHuyenGiaoDich: detail.quanHuyenGiaoDich,
        xaPhuongGiaoDich: detail.xaPhuongGiaoDich,
      });
    }

    form.setFieldsValue({
      flagDiaChiNgoai: e.target.checked,
    });

    setDisplayQuanHuyen(!displayQuanHuyen);
  };
  // thay đổi quận huyện call api xã phường

  const handleChangeDiaChinh = (value) => {
    if (value !== null) {
      handleDataDiaChinh(
        value,
        setLoading,
        setDataXaPhuong,
        setDisabledXaPhuong,
        form
      );
    } else {
      setDisabledXaPhuong(true);
      const valuesXaPhuong = form.getFieldValue();
      form.setFieldsValue({
        ...valuesXaPhuong,
        xaPhuong: null,
      });
    }
  };
  // thay đổi quận huyện call api xã phường

  const handleChangeDiaChinhGiaoDich = (value) => {
    if (value !== null) {
      handleDataDiaChinhGiaoDich(
        value,
        setLoading,
        setDataXaPhuongGiaoDich,
        setDisabledXaPhuongGiaoDich,
        form
      );
    } else {
      setDisabledXaPhuongGiaoDich(true);
      const valuesXaPhuongGiaoDich = form.getFieldValue();
      form.setFieldsValue({
        ...valuesXaPhuongGiaoDich,
        xaPhuongGiaoDich: null,
      });
    }
  };
  // fetch list xã phường theo id của quận huyện

  useEffect(() => {
    if (detail.quanHuyen)
      handleMultileListXaPhuong(detail.quanHuyen, setLoading, setDataXaPhuong);
    else setLoading(false);
  }, [detail.quanHuyen]);

  useEffect(() => {
    if (detail.quanHuyenGiaoDich)
      handleMultileListXaPhuongGD(
        detail.quanHuyenGiaoDich,
        setLoading,
        setDataXaPhuongGiaoDich
      );
    else setLoading(false);
  }, [detail.quanHuyenGiaoDich]);

  useEffect(() => {
    form.setFieldsValue({
      maKhachHang: detail.maKhachHang,
      maHoSo: detail.maHoSo,
      tenCoQuan: detail.tenCoQuan,
      coQuanCap: detail.coQuanCap,
      tenKhachHang: detail.tenKH,
      tenNguoiYeuCau: detail.tenNguoiYeuCau,
      nguoiDaiDien: detail.nguoiDaiDien,
      tenNguoiDaiDien: detail.tenNDD,
      chucVuNguoiDaiDien: detail.chucVu,
      cmnd: detail.cmt,
      ngayCap: detail.ngayCap ? moment(detail.ngayCap) : moment(),
      noiCap: detail.noiCap,
      soGiayUyQuyen: detail.soGiayUyQuyen,
      ngayCapGiayUyQuyen: detail.ngayCapGiayUyQuyen
        ? moment(detail.ngayCapGiayUyQuyen)
        : "",
      coQuanCapGiayUyQuyen: detail.coQuanCapGiayUyQuyen,
      diaChiGiaoDich: detail.diaChiGiaoDich,
      quanHuyenGiaoDich: detail.quanHuyenGiaoDich,
      xaPhuongGiaoDich: detail.xaPhuongGiaoDich,
      quanHuyen: detail.quanHuyen,
      xaPhuong: detail.xaPhuong,
      diaChiCapDien: detail.diaChiSuDung,
      dienThoai: detail.sdt,
      dienThoaiKhac: detail.dienThoaiKhac,
      email: detail.email,
      dienThoaiDichVu: detail.dienThoaiDichVu,
      emailDichVu: detail.emailDichVu,
      maLanhDaoDuyet: detail.maLanhDaoDuyet ? detail.maLanhDaoDuyet : null,
      gioiTinhNguoiDaiDien:
        detail.gioiTinhNguoiDaiDien === ONG ||
        detail.gioiTinhNguoiDaiDien === BA
          ? detail.gioiTinhNguoiDaiDien
          : ONG,
      gioiTinhKhachHang:
        detail.gioiTinhKhachHang === ONG ||
        detail.gioiTinhKhachHang === BA ||
        detail.gioiTinhKhachHang === CO_QUAN
          ? detail.gioiTinhKhachHang
          : ONG,

      flagDiaChiNgoai: detail.flagDiaChiNgoai,
      flagLanhDaoKy: detail.flagLanhDaoKy,
      maLoaiYeuCau: detail.maLoaiYeuCau,
    });
  }, [form, detail]);

  useEffect(() => {
    if (form.getFieldValue("gioiTinhKhachHang") === CO_QUAN) {
      setDisplayNguoiDaiDien(false);
    } else {
      setDisplayNguoiDaiDien(true);
    }
    // eslint-disable-next-line
  }, [form.getFieldValue("gioiTinhKhachHang")]);

  useEffect(() => {
    if (form.getFieldValue("maLoaiYeuCau") === TYPE_LOAIYEUCAU_HOSO.CAPDIEN) {
      dispatch(saveLoaiYeuCauDichVu(TYPE_LOAIYEUCAU_HOSO.CAPDIEN_SO));
    } else {
      dispatch(saveLoaiYeuCauDichVu(null));
    }
    // eslint-disable-next-line
  }, [form.getFieldValue("maLoaiYeuCau")]);

  useEffect(() => {
    if (detail.flagDiaChiNgoai || detail.flagDiaChiNgoai === null) {
      form.setFieldsValue({
        quanHuyenGiaoDich: null,
        xaPhuongGiaoDich: null,
      });
      setDisplayQuanHuyen(true);
    } else {
      setDisplayQuanHuyen(false);
      form.setFieldsValue({
        quanHuyenGiaoDich: detail.quanHuyenGiaoDich,
        xaPhuongGiaoDich: detail.xaPhuongGiaoDich,
      });
    }
  }, [
    detail.flagDiaChiNgoai,
    detail.quanHuyenGiaoDich,
    detail.xaPhuongGiaoDich,
    form,
  ]);
  // Ẩn/hiện dropdown danh sách lãnh đạo

  useEffect(() => {
    if (detail.flagLanhDaoKy || detail.flagLanhDaoKy === null) {
      setDisplayLanhDao(true);
    } else {
      setDisplayLanhDao(false);
    }
  }, [detail.flagLanhDaoKy]);
  // call api get thông tin hồ sơ khi enter

  const onPressEnter = (value) => {
    if (
      value.target.value !== "" &&
      loaiYeuCau !== TYPE_LOAIYEUCAU_HOSO.CAPDIEN_SO
    ) {
      handleDataThongTinKHCMIS(
        value.target.value,
        setLoading,
        setDataDiemDoHienTai
      );
    }
  };

  const onSearchThongTinKHCMIS = (value) => {
    if (value !== "" && loaiYeuCau !== TYPE_LOAIYEUCAU_HOSO.CAPDIEN_SO) {
      handleDataThongTinKHCMIS(value, setLoading, setDataDiemDoHienTai);
    }
  };
  useEffect(() => {
    inputRef.current.focus();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (
  //     detail.maKhachHang &&
  //     loaiYeuCau !== TYPE_LOAIYEUCAU_HOSO.CAPDIEN_SO &&
  //     detail.maLoaiYeuCau !== TYPE_LOAIYEUCAU_HOSO.CAPDIEN
  //   )
  //     handleDataThongTinKHCMIS(
  //       detail.maKhachHang,
  //       setLoading,
  //       setDataDiemDoHienTai
  //     );
  //   // eslint-disable-next-line
  // }, [detail.maKhachHang]);

  // set thông tin khách hàng về điểm đo hiện tại

  useEffect(() => {
    if (
      dataDiemDoHienTai &&
      Object.keys(dataDiemDoHienTai).length > 0 &&
      loaiYeuCau !== TYPE_LOAIYEUCAU_HOSO.CAPDIEN_SO
    ) {
      form.setFieldsValue({
        diaChiCapDienCmis: dataDiemDoHienTai.diaChiCapDien,
        congSuatCmis: dataDiemDoHienTai.congSuat,
        soPhaCmis: dataDiemDoHienTai.soPha,
        dienApCmis: dataDiemDoHienTai.dienAp,
        lapQuaTiCmis: dataDiemDoHienTai.chiSoTi,
        maTramCmis: dataDiemDoHienTai.maTram,
        tenTramCmis:
          dataDiemDoHienTai.maTram && dataDiemDoHienTai.tenTram
            ? dataDiemDoHienTai.maTram + " - " + dataDiemDoHienTai.tenTram
            : dataDiemDoHienTai.maTram
            ? dataDiemDoHienTai.maTram
            : dataDiemDoHienTai.tenTram
            ? dataDiemDoHienTai.tenTram
            : null,
        mucDichSinhHoatCmis:
          dataDiemDoHienTai.mucDichSinhHoat === MUC_DICH.SINHHOAT_NUM
            ? MUC_DICH.SINHHOAT
            : dataDiemDoHienTai.mucDichSinhHoat === MUC_DICH.NGOAISINHHOAT_NUM
            ? MUC_DICH.NGOAISINHHOAT
            : null,
        viTriCongToCmis: dataDiemDoHienTai.viTriCongTo,
        cmnd: dataDiemDoHienTai.cmt ? dataDiemDoHienTai.cmt : detail.cmt,
        tenKhachHang: dataDiemDoHienTai.tenKhachHang
          ? dataDiemDoHienTai.tenKhachHang
          : detail.tenKH,
        ngayCap: dataDiemDoHienTai.ngayCapCMT
          ? moment(dataDiemDoHienTai.ngayCapCMT)
          : detail.ngayCap
          ? moment(detail.ngayCap)
          : moment(),
        noiCap: dataDiemDoHienTai.noiCapCMT
          ? dataDiemDoHienTai.noiCapCMT
          : detail.noiCap,
        email: dataDiemDoHienTai.email ? dataDiemDoHienTai.email : detail.email,
        emailDichVu: dataDiemDoHienTai.email
          ? dataDiemDoHienTai.email
          : detail.emailDichVu,
        dienThoai: dataDiemDoHienTai.dienThoai
          ? dataDiemDoHienTai.dienThoai
          : detail.sdt,
        dienThoaiDichVu: dataDiemDoHienTai.dienThoai
          ? dataDiemDoHienTai.dienThoai
          : detail.dienThoaiDichVu,
        dienThoaiKhac: dataDiemDoHienTai.dienThoai
          ? dataDiemDoHienTai.dienThoai
          : detail.dienThoaiKhac,
        mucDichSinhHoat:
          dataDiemDoHienTai.mucDichSinhHoat === MUC_DICH.SINHHOAT_NUM ||
          dataDiemDoHienTai.mucDichSinhHoat === MUC_DICH.NGOAISINHHOAT_NUM
            ? dataDiemDoHienTai.mucDichSinhHoat.toString()
            : detail.mucDichSinhHoat === ENUM_MUCDICHSINHHOAT.SINHHOAT
            ? ENUM_MUCDICHSINHHOAT.SINHHOAT
            : ENUM_MUCDICHSINHHOAT.NGOAISINHHOAT,
        taiKhoanNganHang: dataDiemDoHienTai.taiKhoanNganHang
          ? dataDiemDoHienTai.taiKhoanNganHang
          : detail.taiKhoan,
        diaChiCapDien: dataDiemDoHienTai.diaChiCapDien
          ? dataDiemDoHienTai.diaChiCapDien
          : detail.diaChiSuDung,
        diaChiGiaoDich: dataDiemDoHienTai.diaChiLienHe
          ? dataDiemDoHienTai.diaChiLienHe
          : detail.diaChiGiaoDich,
        soPha:
          dataDiemDoHienTai.soPha === ENUM_SOPHA.MOT_PHA ||
          dataDiemDoHienTai.soPha === ENUM_SOPHA.BA_PHA
            ? dataDiemDoHienTai.soPha
            : detail.soPha === ENUM_SOPHA.MOT_PHA ||
              detail.soPha === ENUM_SOPHA.BA_PHA
            ? detail.soPha
            : null,
      });
    } else {
      form.setFieldsValue({
        diaChiCapDienCmis: null,
        congSuatCmis: null,
        soPhaCmis: null,
        dienApCmis: null,
        lapQuaTiCmis: null,
        maTramCmis: null,
        tenTramCmis: null,
        mucDichSinhHoatCmis: null,
        viTriCongToCmis: null,
      });
    }
  }, [form, dataDiemDoHienTai]);

  const handleChangeInput = (e) => {
    const val = e.target.value.trim();
    if (val.includes(" ")) {
      form.setFields([
        {
          name: "cmnd",
          errors: ["Vui lòng không nhập khoảng trắng"],
        },
      ]);
    } else if (val.length > 13) {
      form.setFields([
        {
          name: "cmnd",
          errors: ["Vui lòng không nhập quá 13 ký tự"],
        },
      ]);
    } else {
      form.setFields([
        {
          name: "cmnd",
          errors: false,
        },
      ]);
      form.setFieldsValue({
        cmnd: val.trim(),
      });
    }
  };

  return (
    <Fragment>
      <Row gutter={24}>
        <Col span={24} md={12} lg={6}>
          <Form.Item
            label={t("cap_nhat_ho_so.ma_khach_hang")}
            name="maKhachHang"
          >
            <Search
              disabled={
                (detail.maLoaiYeuCau === TYPE_LOAIYEUCAU_HOSO.CAPDIEN &&
                  loaiYeuCau === TYPE_LOAIYEUCAU_HOSO.CAPDIEN_SO) ||
                loaiYeuCau === TYPE_LOAIYEUCAU_HOSO.CAPDIEN_SO
              }
              placeholder="Nhập mã khách hàng"
              onSearch={onSearchThongTinKHCMIS}
              onPressEnter={onPressEnter}
              onKeyDown={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={18}>
          <Form.Item
            label={t("cap_nhat_ho_so.ten_nguoi_yeu_cau")}
            name="tenNguoiYeuCau"
            className="required-field"
          >
            <Input ref={inputRef} />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={6}>
          <Form.Item
            label={t("cap_nhat_ho_so.ten_khach_hang")}
            name="gioiTinhKhachHang"
            className="required-field"
          >
            <Select
              disabled={
                loaiYeuCau === TYPE_LOAIYEUCAU_HOSO.CAPDIEN_SO ? false : true
              }
              onChange={handleCoQuan}
              defaultValue={defaultValueTenCoQuan}
              loading={loading}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {dataGioiTinhKH && dataGioiTinhKH.length
                ? dataGioiTinhKH.map((item, index) => {
                    return (
                      <Select.Option
                        key={index}
                        value={item.value ? item.value.toString() : item.value}
                      >
                        {item.name}
                      </Select.Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={12}>
          <Form.Item label={" "} name="tenKhachHang" className="required-field">
            <Input
              placeholder={t("cap_nhat_ho_so.ten_khach_hang")}
              disabled={
                loaiYeuCau === TYPE_LOAIYEUCAU_HOSO.CAPDIEN_SO ? false : true
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={24} lg={6}>
          <Form.Item label={t("cap_nhat_ho_so.co_quan_cap")} name="coQuanCap">
            <Input
              disabled={
                loaiYeuCau === TYPE_LOAIYEUCAU_HOSO.CAPDIEN_SO ? false : true
              }
            />
          </Form.Item>
        </Col>

        <Col span={24} md={12} lg={6}>
          <Form.Item
            label={t("cap_nhat_ho_so.nguoi_dai_dien")}
            name="gioiTinhNguoiDaiDien"
            className="required-field"
            // style={{ display: displayNguoiDaiDien ? "none" : "block" }}
          >
            <Select
              loading={loading}
              disabled={
                loaiYeuCau === TYPE_LOAIYEUCAU_HOSO.CAPDIEN_SO ? false : true
              }
            >
              {dataGioiTinhKH && dataGioiTinhKH.length > 0
                ? dataGioiTinhKH
                    .filter((data) => !data.name.includes("Cơ quan"))
                    .map((item, index) => (
                      <Option key={index} value={item.value.toString()}>
                        {item.name}
                      </Option>
                    ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={12}>
          <Form.Item
            label={" "}
            name="tenNguoiDaiDien"
            className="required-field"
            // style={{ display: displayNguoiDaiDien ? "none" : "block" }}
          >
            <Input
              placeholder={t("cap_nhat_ho_so.ten_nguoi_dai_dien")}
              disabled={
                loaiYeuCau === TYPE_LOAIYEUCAU_HOSO.CAPDIEN_SO ? false : true
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={24} lg={6}>
          <Form.Item
            label={t("cap_nhat_ho_so.chuc_vu")}
            name="chucVuNguoiDaiDien"
            // style={{ display: displayNguoiDaiDien ? "none" : "block" }}
          >
            <Input
              disabled={
                loaiYeuCau === TYPE_LOAIYEUCAU_HOSO.CAPDIEN_SO ? false : true
              }
            />
          </Form.Item>
        </Col>

        <Col span={24} md={12} lg={6}>
          <Form.Item
            label={t("cap_nhat_ho_so.chung_minh_thu_ho_chieu")}
            name="cmnd"
          >
            <Input
              disabled={
                loaiYeuCau === TYPE_LOAIYEUCAU_HOSO.CAPDIEN_SO ? false : true
              }
              onChange={handleChangeInput}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={6}>
          <Form.Item
            label={t("cap_nhat_ho_so.ngay_cap")}
            name="ngayCap"
            className="required-field"
          >
            <DatePicker
              locale={locale}
              format={FORMAT_DATE}
              placeholder={FORMAT_DATE}
              allowClear={false}
              disabled={
                loaiYeuCau === TYPE_LOAIYEUCAU_HOSO.CAPDIEN_SO ? false : true
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={24} lg={12}>
          <Form.Item label={t("cap_nhat_ho_so.noi_cap")} name="noiCap">
            <Input
              disabled={
                loaiYeuCau === TYPE_LOAIYEUCAU_HOSO.CAPDIEN_SO ? false : true
              }
            />
          </Form.Item>
        </Col>

        <Col span={24} md={12} lg={6}>
          <Form.Item
            label={t("cap_nhat_ho_so.so_giay_uy_quyen")}
            name="soGiayUyQuyen"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={6}>
          <Form.Item
            label={t("cap_nhat_ho_so.ngay_cap_giay_uy_quyen")}
            name="ngayCapGiayUyQuyen"
          >
            <DatePicker
              locale={locale}
              format={FORMAT_DATE}
              allowClear={true}
              placeholder={FORMAT_DATE}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={24} lg={12}>
          <Form.Item
            label={t("cap_nhat_ho_so.ten_don_vi_cap")}
            name="coQuanCapGiayUyQuyen"
          >
            <Input />
          </Form.Item>
        </Col>
        <CheckBoxDiaChi span={24} md={24}>
          <Form.Item
            label={t("cap_nhat_ho_so.dia_chi_giao_dich")}
            name="flagDiaChiNgoai"
          >
            <Checkbox
              checked={displayQuanHuyen}
              // checked={displayNguoiDaiDien}
              onChange={handleDisplayQuanHuyen}
            >
              {t("cap_nhat_ho_so.tich_chon_neu_khong_su_dung_quan_huyen")}
            </Checkbox>
          </Form.Item>
        </CheckBoxDiaChi>

        <Col span={24} md={12} lg={6}>
          <Form.Item
            name="quanHuyenGiaoDich"
            label={t("cap_nhat_ho_so.quan_huyen")}
            className="required-field"
            style={{ display: displayQuanHuyen ? "none" : "block" }}
          >
            <Select
              disabled={displayQuanHuyen}
              onChange={handleChangeDiaChinhGiaoDich}
              loading={loading}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>--Chọn--</Option>
              {dataDiaChinh && dataDiaChinh.length > 0
                ? dataDiaChinh.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.tenDiaChinh}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={6}>
          <Form.Item
            name="xaPhuongGiaoDich"
            label={t("cap_nhat_ho_so.phuong_xa")}
            className="required-field"
            style={{ display: displayQuanHuyen ? "none" : "block" }}
          >
            <Select
              disabled={disabledXaPhuongGiaoDich || displayQuanHuyen}
              loading={loading}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>--Chọn--</Option>
              {dataXaPhuongGiaoDich && dataXaPhuongGiaoDich.length > 0
                ? dataXaPhuongGiaoDich.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.tenDiaChinh}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={24}>
          <Form.Item
            label={t("cap_nhat_ho_so.dia_chi")}
            name="diaChiGiaoDich"
            className="required-field"
          >
            <Input />
          </Form.Item>
        </Col>

        <TextBottom span={24} md={24}>
          {t("cap_nhat_ho_so.dia_chi_su_dung_dien")}
        </TextBottom>
        <Col span={24} md={12} lg={6}>
          <Form.Item
            name="quanHuyen"
            label={t("cap_nhat_ho_so.quan_huyen")}
            className="required-field"
          >
            <Select
              onChange={handleChangeDiaChinh}
              loading={loading}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>--Chọn--</Option>
              {dataDiaChinh && dataDiaChinh.length > 0
                ? dataDiaChinh.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.tenDiaChinh}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={6}>
          <Form.Item
            name="xaPhuong"
            label={t("cap_nhat_ho_so.phuong_xa")}
            className="required-field"
          >
            <Select
              disabled={disabledXaPhuong}
              loading={loading}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>--Chọn--</Option>
              {dataXaPhuong && dataXaPhuong.length > 0
                ? dataXaPhuong.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.tenDiaChinh}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={24}>
          <Form.Item
            label={t("cap_nhat_ho_so.dia_chi")}
            name="diaChiCapDien"
            className="required-field"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={6}>
          <Form.Item
            label={t("cap_nhat_ho_so.so_dien_thoai_di_dong")}
            name="dienThoai"
            className="required-field"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={12}>
          <Form.Item
            label={t("cap_nhat_ho_so.so_dien_thoai_khac")}
            name="dienThoaiKhac"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={6}>
          <Form.Item label={t("cap_nhat_ho_so.email")} name="email">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={6}>
          <Form.Item
            label={t("cap_nhat_ho_so.sdt_dung_cho_dich_vu_nhan_tin")}
            name="dienThoaiDichVu"
            className="required-field"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={12}>
          <Form.Item
            label={t("cap_nhat_ho_so.email_su_dung_nhan_cac_dich_vu_thong_bao")}
            name="emailDichVu"
            // className="required-field"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={6}>
          <Form.Item
            label={t("cap_nhat_ho_so.lanh_dao_ky")}
            name="maLanhDaoDuyet"
            className="required-field"
            style={{ display: displayLanhDao ? "block" : "none" }}
          >
            <Select
              //disabled={displayLanhDao}
              loading={loading}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>--Chọn lãnh đạo ký--</Option>
              {dataLanhDao && dataLanhDao.length > 0
                ? dataLanhDao.map((item, index) => (
                    <Option key={index} value={item.value}>
                      {item.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={6}>
          <Form.Item name="maLoaiYeuCau" style={{ display: "none" }}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={6}>
          <Form.Item name="flagLanhDaoKy" style={{ display: "none" }}>
            <Checkbox />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={6}>
          <Form.Item name="maHoSo" style={{ display: "none" }}>
            <Checkbox />
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  );
}
const CheckBoxDiaChi = styled(Col)`
  .ant-row.ant-form-item {
    flex-direction: row !important;
  }
  .ant-col.ant-form-item-label {
    display: flex;
    padding: 0 20px 0 0;
  }
  }
`;
const TextBottom = styled(Col)`
  padding-bottom: 8px;
`;

export default memo(PanelsThongTinChung);
