import { Endpoint } from "@utils/endpoint";
import { authGetData, downloadFile } from "@utils/request";
import {
  buildQueryString,
  parseParams
} from "@utils/function";
import { STATUSCODE_200 } from "@utils/constants";


// get list data báo cáo công tơ tháo
export const handleListDataBaoCao = (
  setLoading,
  location,
  setDataBaoCao,
  setTotalCount,
  filterConditions
) => {
  let url = "";
  url = `${Endpoint.LIST_BAO_CAO_CONG_TO_THAO}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
    authGetData({
      url,
      setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataBaoCao(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataBaoCao([]);
    },
  });
};

export const submitOpenPdf = (
  setLoading, 
  location, 
  filterConditions,
  trungTam
) => {
  authGetData({
    url: `${Endpoint.LIST_BAO_CAO_CONG_TO_THAO_PDF}?${buildQueryString(
      Object.keys(parseParams(location.search)).length > 0
        ? parseParams(location.search)
        : filterConditions
    )}&TrungTam=${trungTam}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        window.open(res.data, "_blank");
      }
    },
  });
};

export const downLoadBaoCao = (
  setLoading, 
  location, 
  filterConditions,
  trungTam
) => {
  downloadFile({
    endpoint: `${Endpoint.LIST_BAO_CAO_CONG_TO_THAO_EXCEL}?${buildQueryString(
      Object.keys(parseParams(location.search)).length > 0
        ? parseParams(location.search)
        : filterConditions
    )}&TrungTam=${trungTam}`,
    setLoading,
  });
};