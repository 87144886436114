import classnames from "classnames";
import React from "react";
import { PaginationStyle } from "./style";
import { DOTS, usePagination } from "./usePagination";
const PaginationCustom = (props) => {
  const { onPageChange, totalPages, siblingCount = 1, currentPage, className } = props;

  const paginationRange = usePagination({
    currentPage,
    totalPages,
    siblingCount,
  });

  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange?.length - 1];
  return (
    <PaginationStyle>
      <ul className={classnames("pagination-container", { [className]: className })}>
        <li
          className={classnames("pagination-item", {
            disabled: currentPage === 1,
          })}
          onClick={onPrevious}
        >
          <div className="arrow left" />
        </li>
        {paginationRange.map((pageNumber, index) => {
          // <Fragment key={index}></Fragment>
          if (pageNumber === DOTS) {
            return <li className="pagination-item dots" key={index}>&#8230;</li>;
          }

          return (
            <li
              className={classnames("pagination-item", {
                selected: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber)}
              key={index}
            >
              <a>{pageNumber}</a>
            </li>
          );
        })}
        <li
          className={classnames("pagination-item", {
            disabled: currentPage === lastPage,
          })}
          onClick={onNext}
        >
          {/* <a> */}
          <div className="arrow right" />
          {/* </a> */}
        </li>
      </ul>
    </PaginationStyle>
  );
};

export default PaginationCustom;
