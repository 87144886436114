import { BASE_API_URL_BAO_CAO } from "@utils/constants";

export const APIBCCapDien = {
  BCCD_TH_TRANG_WEB_CAP_DIEN: `${BASE_API_URL_BAO_CAO}/bckd/bccd/thtwebcd/tong-hop-trang-web-cap-dien`,
  BCCD_BC_TINH_HINH_CAP_DIEN_MOI_SHKV_THANH_PHO: `${BASE_API_URL_BAO_CAO}/bckd/bccd/bcctcd/bao-cao-ctth-cap-dien-moi-shkv-theo-thang`,
  BCCD_BC_TINH_HINH_CAP_DIEN_MOI_SHKV_NONG_THON: `${BASE_API_URL_BAO_CAO}/bckd/bccd/bcctcd/chi-tiet-tinh-hinh-cap-dien-moi-shkv-nong-thon-theo-thang`,
  BCCD_BC_TINH_HINH_CAP_DIEN_MOI_KH_NGOAI_SINH_HOAT: `${BASE_API_URL_BAO_CAO}/bckd/bccd/bcctcd/chi-tiet-tinh-hinh-cap-dien-moi-cho-khnsh-theo-thang`,
  BCCD_HOAN_TAT_HSCD_HA_AP: `${BASE_API_URL_BAO_CAO}/bckd/bccd/bcctcd/hoan-tat-hscd-ha-ap-ddncs`,
  BCCD_CT_KH_CAP_DIEN_HA_AP: `${BASE_API_URL_BAO_CAO}/bckd/bccd/bcctcd/chi-tiet-cac-khcdha-trong-vckddn`,
  BCCD_THOI_GIAN_TB_GQCD: `${BASE_API_URL_BAO_CAO}/bckd/bccd/bcctcd/tgtb-giai-quyet-cd-moi-tai-dv`,
  BCCD_TH_HS_CO_TRO_NGAI: `${BASE_API_URL_BAO_CAO}/bckd/bccd/bcctcd/th-ho-so-co-tro-ngai`,
};
