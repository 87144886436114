import { BASE_API_URL_DANH_MUC } from "@utils/constants";

export const APIToDoi = {
  LIST_DOI: `${BASE_API_URL_DANH_MUC}/doi/list`,
  SYNC_DOI: `${BASE_API_URL_DANH_MUC}/doi/sync`,
  CREATE_DOI: `${BASE_API_URL_DANH_MUC}/doi/create`,
  UPDATE_DOI: `${BASE_API_URL_DANH_MUC}/doi/update`,
  REMOVE_DOI: `${BASE_API_URL_DANH_MUC}/doi/delete`,
  GET_DOI: `${BASE_API_URL_DANH_MUC}/doi/get`,
};
