import FormComponent from "@components/Form";
import { Button, Col, DatePicker, Form, Input, Row, Spin, Modal } from "antd";
import React, { useState } from "react";
import { Container, ContentWrapper } from "./css-styled";
import { FORMAT_ENGLISH, FORMAT_DATE } from "@utils/constants";
import { submitCauHinhCToThao, updateCauHinhCToThao } from "./services";
import moment from "moment";


export default function CauHinhCToThao(props) {
  const {
    visible,
    closeModal,
    getCauHinh,
    cauHinhChiSo,
    handleCloseModal
  } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [disable, setDisable] = useState(false);
  const [percent, setPercent] = useState(
    cauHinhChiSo ? cauHinhChiSo.chenhLechSanLuong : "80"
  );
  const [ngay, setNgay] = useState(
    cauHinhChiSo ?
      moment(moment(cauHinhChiSo.ngayApDung, "DD-MM-YYYY")).format(FORMAT_ENGLISH) :
      moment().add(1, 'days').format(FORMAT_ENGLISH)
  );

  const onChangePercent = (e) => {
    const patt = /^\d+\.{0,1}\d{0,1}$/;
    if (!e.target.value) {
      setPercent(0);
    } else
      if (patt.test(e.target.value)) {
        setPercent((Number(e.target.value) > 100 ? 100 : e.target.value));
      }
  }

  const changeNgay = (value) => {
    setNgay(value.format(FORMAT_ENGLISH))
    if (moment() > value) {
      setDisable(true)
    } else {
      setDisable(false)
    }
  };
  const onFinish = () => {
    cauHinhChiSo ?
      updateCauHinhCToThao(
        setLoading,
        cauHinhChiSo.id,
        ngay,
        percent,
        getCauHinh,
        handleCloseModal
      ) :
      submitCauHinhCToThao(
        setLoading,
        ngay,
        percent,
        getCauHinh,
        handleCloseModal
      );
  }
  return (
    <Modal
      title="Cấu hình"
      visible={visible}
      onCancel={closeModal}
      className="modal-dong-bo"
      footer={null}
    >
      <Container>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              form={form}
              name="form-cau-hinh"
              onFinish={onFinish}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <span>Ngày áp dụng:</span>
                </Col>
                <Col span={14}>
                  <Form.Item
                    name="ngayApDung"
                    label=""
                    labelAlign="left"
                  >
                    <DatePicker
                      defaultValue={cauHinhChiSo ? moment(moment(cauHinhChiSo.ngayApDung, "DD-MM-YYYY")) : moment().add(1, 'days')}
                      format={FORMAT_DATE}
                      onChange={changeNgay}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <span>Chênh lệch sản lượng tháo CMIS với sản lượng đo xa lấy lúc 0 ngày tháo hoặc là sản lượng nhập từ thông tin mặt công tơ tháo:</span>
                </Col>
                <Col span={6}>
                </Col>
                <Col span={14} style={{ marginTop: 10}}>
                  <Input
                    value={percent}
                    defaultValue={cauHinhChiSo ? cauHinhChiSo.chenhLechSanLuong : "80"}
                    placeholder="Nhập số <100"
                    onChange={onChangePercent}>
                  </Input>
                </Col>
                <Col span={4} style={{ paddingTop: 18, paddingLeft: 0}}>
                  <span>%</span>
                </Col>
              </Row>
              <Row justify="center" style={{ marginTop: 10 }}>
                {/* <Col span={4}></Col> */}
                <Button
                  key="submit"
                  type="primary"
                  className="button-primary"
                  htmlType="submit"
                  form="form-cau-hinh"
                  disabled={disable}
                >
                  {cauHinhChiSo ? "Cập nhật" : "Lưu"}
                  {/* Cập nhật */}
                </Button>
              </Row>
            </FormComponent>
            {/* <Divider className="section-text-line" orientation="left"></Divider> */}
          </Spin>
        </ContentWrapper>
      </Container>
    </Modal>
  );
}
