import { Button, Col, Modal, Row } from "antd";
import React from "react";

function DuyetYeuCauModal(props) {
  const { visible, handleCloseModal, handleSubmit, loading } = props;
  return (
    // <LoadingComponent loading={loading}>
    <Modal
      title="YÊU CẦU ĐANG Ở LÃNH ĐẠO."
      visible={visible}
      onCancel={handleCloseModal}
      footer={null}
    >
      <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
        <p style={{ marginBottom: "5px" }}>
          Yêu cầu sẽ được Phòng điều độ ký số/xác nhận.
        </p>
        <p>Sau đó yêu cầu sẽ được chuyển sang bước tiếp theo.</p>
      </h4>
      <Row gutter={24} justify="center" style={{ marginTop: "20px" }}>
        <Col span={8} style={{ textAlign: "right" }}>
          <Button
            key="back"
            onClick={handleCloseModal}
            className="button-closed"
          >
            Đóng lại
          </Button>
        </Col>

        <Col span={8} style={{ textAlign: "center" }}>
          <Button
            key="submit"
            type="primary"
            className="button-primary"
            htmlType="submit"
            onClick={handleSubmit}
            style={{ backgroundColor: "green" }}
            loading={loading}
          >
            Xác nhận
          </Button>
        </Col>
        {/* <Col span={8} style={{ textAlign: "left" }}>
          <KySoComponent
            url={Endpoint.CRM_UPDATE_HOP_DONG_KY_SO}
            urlGetHash={Endpoint.CRM_LANH_DAO_KY_SO}
            payload={{
              id: dataIds,
            }}
            // permissionCode={ghi_chi_so.d33ky}
            disabled={!dataIds.length}
            // setFetchDataTable={setFetchDataTable}
            reload={getListData}
            setLoading={setLoading}
            loading={loading}
          />
        </Col> */}
      </Row>
    </Modal>
    // </LoadingComponent>
  );
}

export default DuyetYeuCauModal;
