import { Table } from "antd";

export default function Index(props) {
  const { dataTH, columnsTH } = props;
  return (
    <Table
      dataSource={dataTH}
      columns={columnsTH}
      pagination={false}
      scroll={{ y: 300 }}
      rowKey={(obj) => obj.stt}
    />
  );
}
