import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import React from "react";
import styled from "styled-components";
const BoLocLog = ({ handleSearch, form, clearFilter, listModule }) => {

  return (
    <Style>
      <Form layout="vertical" form={form} onFinish={handleSearch}>
        {
          <Row gutter={[8, 16]}>
             <Col span={6}>
              <Form.Item name="moduleCode" label="Module">
                <Select options={listModule}></Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="fromDate" label="From Date">
                <DatePicker showTime />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="toDate" label="To Date">
                <DatePicker showTime />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="searchTerm" label="Từ khóa tìm kiếm" >
                <Input prefix={<SearchOutlined />} allowClear/>
              </Form.Item>
            </Col>
            <Col span={4} offset={1} className="filter-log-button">
              <Button onClick={() => clearFilter()}>Bỏ lọc</Button>
              <Button type="primary" htmlType="submit">
                Tìm kiếm
              </Button>
            </Col>
          </Row>
        }
      </Form>
    </Style>
  );
};

export default BoLocLog;

const Style = styled.div`
  .filter-log-button {
    display: flex;
    gap: 20px !important;
    align-items: center;
    justify-content: end;
  }
`;
