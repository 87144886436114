import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_MONTH_ENGLISH,
} from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { Table } from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { getTongHopThoiGian } from "./services";
import BaoCaoTongHopTheoThoiGian from "./table";
export default function TimeGCS() {
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const { user } = useSelector(authSelector);
  const [dataTheoThoiGian, setdataTheoThoiGian] = useState();
  const [totalCount, setTotalCount] = useState();
  const location = useLocation();

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
    DonViId: user.unitId,
    ...parseParams(location.search),
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const getTongHop = useCallback(() => {
    getTongHopThoiGian(
      filterConditions,
      setLoading,
      setdataTheoThoiGian,
      setTotalCount
    );
  }, [filterConditions]);

  useEffect(() => {
    getTongHop();
  }, [getTongHop]);

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      ...values,
    }));
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
      DonViId: user.unitId,
    });
  }, [user.unitId]);
  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const columns = [
    {
      title: "STT",
      key: "index",
      align: "center",
      render: (a, b, index) => {
        return (
          (filterConditions.pageIndex - 1) * filterConditions.pageSize +
          (index + 1)
        );
      },
      width: 70,
      fixed: "left",
    },
    {
      title: "Đơn vị",
      dataIndex: "maDonVi",
      key: "maDonVi",
      width: 150,
      align: "center",
      fixed: "left",
    },
    {
      title: "Tổng sổ GCS",
      dataIndex: "tongSoGCS",
      key: "tongSoGCS",
      width: 100,
      align: "center",
    },
    {
      title: "Chênh lệch ngày nhận dữ liệu",
      dataIndex: "chenlechngaynhandulieu",
      key: "chenlechngaynhandulieu",
      children: [
        {
          title: "Cùng ngày GCS",
          dataIndex: "nhanCungNgayGCS",
          key: "cungNgayGCS",
          width: 100,
          align: "center",
        },
        {
          title: "Tỷ lệ nhận dữ liệu quá thời gian quy định",
          dataIndex: "tyLeNhanDuLieu",
          key: "tyLeNhanDuLieu",
          width: 200,
          align: "center",
        },
      ],
    },
    {
      title: "Chênh lệch ngày  xuất dữ liệu",
      dataIndex: "tyLeXuatDuLieu",
      key: "tyLeXuatDuLieu",
      children: [
        {
          title: "Trước -5 ngày",
          dataIndex: "truocAm5Ngay",
          key: "truocAm5Ngay",
          width: 100,
          align: "center",
        },
        {
          title: "Trước -4 ngày",
          dataIndex: "truocAm4Ngay",
          key: "truocAm4Ngay",
          width: 100,
          align: "center",
        },
        {
          title: "Trước -3 ngày",
          dataIndex: "truocAm3Ngay",
          key: "truocAm3Ngay",
          width: 100,
          align: "center",
        },
        {
          title: "Trước -2 ngày",
          dataIndex: "truocAm2Ngay",
          key: "truocAm2Ngay",
          width: 100,
          align: "center",
        },
        {
          title: "Trước -1 ngày",
          dataIndex: "truocAm1Ngay",
          key: "truocAm1Ngay",
          width: 100,
          align: "center",
        },
        {
          title: "Cùng ngày GCS",
          dataIndex: "xuatCungNgayGCS",
          key: "cungNgayGCS",
          width: 100,
          align: "center",
        },
        {
          title: "Trước 1 ngày",
          dataIndex: "truoc1Ngay",
          key: "truoc1Ngay",
          width: 100,
          align: "center",
        },
        {
          title: "Trước 2 ngày",
          dataIndex: "truoc2Ngay",
          key: "truoc2Ngay",
          width: 100,
          align: "center",
        },
        {
          title: "Trước 5 ngày",
          dataIndex: "truoc5Ngay",
          key: "truoc5Ngay",
          width: 100,
          align: "center",
        },
        {
          title: "Trước 6 ngày",
          dataIndex: "truoc6Ngay",
          key: "truoc6Ngay",
          width: 100,
          align: "center",
        },
        {
          title: "Tỷ lệ xuất dữ liệu trước thời gian quy định ",
          dataIndex: "tyLeXuatDuLieuTruocQuyDinh",
          key: "tyLeXuatDuLieuTruocQuyDinh",
          width: 100,
          align: "center",
        },
      ],
    },
  ];
  const countTotals = (pageData) => {
    let counttongSoGCS = 0;
    let countnhanCungNgayGCS = 0;
    let counttyLeNhanDuLieu = 0;
    let counttruocAm5Ngay = 0;
    let counttruocAm4Ngay = 0;
    let counttruocAm3Ngay = 0;
    let counttruocAm2Ngay = 0;
    let counttruocAm1Ngay = 0;
    let countxuatCungNgayGCS = 0;
    let counttruoc1Ngay = 0;
    let counttruoc2Ngay = 0;
    let counttruoc5Ngay = 0;
    let counttruoc6Ngay = 0;
    let counttyLeXuatDuLieuTruocQuyDinh = 0;
    pageData.forEach(
      ({
        tongSoGCS,
        nhanCungNgayGCS,
        tyLeNhanDuLieu,
        truocAm5Ngay,
        truocAm4Ngay,
        truocAm3Ngay,
        truocAm2Ngay,
        truocAm1Ngay,
        xuatCungNgayGCS,
        truoc1Ngay,
        truoc2Ngay,
        truoc5Ngay,
        truoc6Ngay,
        tyLeXuatDuLieuTruocQuyDinh,
      }) => {
        counttongSoGCS += parseInt(tongSoGCS);
        countnhanCungNgayGCS += parseInt(nhanCungNgayGCS);
        counttyLeNhanDuLieu += parseInt(tyLeNhanDuLieu);
        counttruocAm5Ngay += parseInt(truocAm5Ngay);
        counttruocAm4Ngay += parseInt(truocAm4Ngay);
        counttruocAm3Ngay += parseInt(truocAm3Ngay);
        counttruocAm2Ngay += parseInt(truocAm2Ngay);
        counttruocAm1Ngay += parseInt(truocAm1Ngay);
        countxuatCungNgayGCS += parseInt(xuatCungNgayGCS);
        counttruoc1Ngay += parseInt(truoc1Ngay);
        counttruoc2Ngay += parseInt(truoc2Ngay);
        counttruoc5Ngay += parseInt(truoc5Ngay);
        counttruoc6Ngay += parseInt(truoc6Ngay);
        counttyLeXuatDuLieuTruocQuyDinh += parseInt(tyLeXuatDuLieuTruocQuyDinh);
      }
    );
    return (
      <Table.Summary.Row style={{ backgroundColor: "#ccccb3" }} colSpan={2}>
        <Table.Summary.Cell
          className="summary"
          align="center"
          index={0}
          colSpan={2}
        >
          <Text className="font-bold">Tổng cộng</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell
          className="font-bold summary"
          align="center"
          index={1}
        >
          <Text>{counttongSoGCS}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text>{countnhanCungNgayGCS}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={3}>
          <Text>{counttyLeNhanDuLieu}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={4}>
          <Text>{counttruocAm5Ngay}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={5}>
          <Text>{counttruocAm4Ngay}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={6}>
          <Text>{counttruocAm3Ngay}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={7}>
          <Text>{counttruocAm2Ngay}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={8}>
          <Text>{counttruocAm1Ngay}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={9}>
          <Text>{countxuatCungNgayGCS}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={10}>
          <Text>{counttruoc1Ngay}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={11}>
          <Text>{counttruoc2Ngay}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={12}>
          <Text>{counttruoc5Ngay}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={13}>
          <Text>{counttruoc6Ngay}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={14}>
          <Text>{counttyLeXuatDuLieuTruocQuyDinh}</Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };
  return (
    <BaoCaoTongHopTheoThoiGian
      columns={columns}
      handleSearch={handleSearch}
      filterConditions={filterConditions}
      loading={loading}
      setLoading={setLoading}
      onChangePagination={onChangePagination}
      data={dataTheoThoiGian}
      totalCount={totalCount}
      countTotals={countTotals}
      scrollX={1400}
      clearFilter={clearFilter}
    />
  );
}
