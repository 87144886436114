import PaginationCustom from "@components/pagination-custom";
import { quanLyChiSoSelector } from "@state/giao-nhan-dien-nang/quan-ly-chi-so";
import {
  saveBoChiSo,
  selectDiemDo,
  startGetDanhSachDiemDo,
  updateDanhSachDiemDoCoChiSoNhapSai,
} from "@state/giao-nhan-dien-nang/quan-ly-chi-so/reducer";
import { BCS_GNDN, ENUM_QL_CHI_SO_GNDN_LOAI_NHAP } from "@utils/constants";
import { numberWithNoSpaces } from "@utils/function";
import { Checkbox, Table } from "antd";
import { cloneDeep, isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useVT } from "virtualizedtableforantd4";
import { TableStyled } from "../css-styled";
import { fixedNumber } from "./calculate";
import DanhSachDiemDoColumn from "./danh-sach-diem-do-column";
import DanhSachDiemDoHeader from "./danh-sach-diem-do-header";
import { getListDiemDo } from "./service";
import { setClassErrorChiSoCuoi, setClassErrorSanLuong } from "./validate";

export default function DanhSachDiemDo() {
  const { tramIdSelected } = useSelector(quanLyChiSoSelector);
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceOriginal, setDataSourceInitial] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({ current: 1 });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [rowSelected, setRowSelected] = useState({});

  const [filter, setFilter] = useState({ keyWord: "", loaiNhap: ENUM_QL_CHI_SO_GNDN_LOAI_NHAP.ALL.value });

  useEffect(() => {
    dispatch(startGetDanhSachDiemDo(loading));
  }, [loading]);

  useEffect(() => {
    setFilter({ keyWord: "", loaiNhap: ENUM_QL_CHI_SO_GNDN_LOAI_NHAP.ALL.value });
    setPageInfo({ current: 1 });
    handleGetDiemDo();
    dispatch(updateDanhSachDiemDoCoChiSoNhapSai([]));
  }, [tramIdSelected]);

  const [vt] = useVT(() => ({ scroll: { y: "calc(100vh - 360px)" } }), []);

  const handleGetDiemDo = () => {
    if (tramIdSelected) {
      const queryString = {
        id: tramIdSelected,
      };
      getListDiemDo({
        setDataSource,
        setLoading,
        queryString,
        setDataSourceInitial,
      });
      return;
    }
    if (isEmpty(tramIdSelected)) {
      const queryString = {
        pageIndex: 1,
      };
      getListDiemDo({
        setDataSource,
        setLoading,
        queryString,
        setDataSourceInitial,
        setPageInfo,
      });
    }
  };

  const onSelect = useCallback(
    (record, selected) => {
      if (selected) {
        const arr = [...selectedRowKeys];
        arr.push(record.id);
        setSelectedRowKeys(arr);
      } else {
        const arr = [...selectedRowKeys];
        const newArr = arr.filter((item) => item !== record.id);
        setSelectedRowKeys(newArr);
      }
    },
    [selectedRowKeys]
  );
  const onSelectAll = useCallback(
    (event) => {
      const { checked } = event.target;
      if (checked) {
        const arrIds = [];
        for (let index = 0; index < dataSource.length; index++) {
          const element = dataSource[index];
          if (element.boSo === BCS_GNDN.BT) {
            arrIds.push(element.id);
          }
        }
        setSelectedRowKeys(arrIds);
      } else {
        setSelectedRowKeys([]);
      }
    },
    [dataSource]
  );

  const headerCheckbox = useCallback(() => {
    const arrIds = [];
    for (let index = 0; index < dataSource.length; index++) {
      const element = dataSource[index];
      if (element.boSo === BCS_GNDN.BT) {
        arrIds.push(element.id);
      }
    }
    return (
      <Checkbox
        checked={selectedRowKeys.length > 0 && selectedRowKeys.length === arrIds.length}
        indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < arrIds.length}
        onChange={onSelectAll}
      />
    );
  }, [dataSource, onSelectAll, selectedRowKeys.length]);

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelect,
    fixed: true,
    getCheckboxProps: (record) => {
      return {
        disabled: !record.isRecordFisrt,
      };
    },
    preserveSelectedRowKeys: true,
    columnTitle: headerCheckbox,
  };

  const handleDetailDiemDo = (id) => {
    dispatch(selectDiemDo(id));
  };

  const handleClickRow = (record) => {
    setRowSelected(record);
    // Lấy chỉ số cho header quản lý chỉ số
    if (record.boSo !== BCS_GNDN.VC && record.boSo !== BCS_GNDN.VN) {

      let valueSanLuong = 0;
      let tongSanLuong = 0;
      for (let index = 0; index < dataSourceOriginal.length; index++) {
        const element = dataSourceOriginal[index];
        const idx = dataSourceOriginal.findIndex((e) => e.id === element.id);
        const sanLuong = +numberWithNoSpaces(dataSourceOriginal[idx].sanLuong);
        if (record.diemDoId === element.diemDoId) {
          if (
            record.boSo === BCS_GNDN.BT ||
            record.boSo === BCS_GNDN.CD ||
            record.boSo === BCS_GNDN.TD ||
            record.boSo === BCS_GNDN.SG
          ) {
            if (element.boSo === BCS_GNDN.BT || element.boSo === BCS_GNDN.CD || element.boSo === BCS_GNDN.TD) {
              valueSanLuong = valueSanLuong + sanLuong;
            }
            if (element.boSo === BCS_GNDN.SG) {
              tongSanLuong = sanLuong;
            }
          }
          if (
            record.boSo === BCS_GNDN.BN ||
            record.boSo === BCS_GNDN.CN ||
            record.boSo === BCS_GNDN.TN ||
            record.boSo === BCS_GNDN.SN
          ) {
            if (element.boSo === BCS_GNDN.BN || element.boSo === BCS_GNDN.CN || element.boSo === BCS_GNDN.TN) {
              valueSanLuong = valueSanLuong + sanLuong;
            }
            if (element.boSo === BCS_GNDN.SN) {
              tongSanLuong = sanLuong;
            }
          }
        }
      }
      dispatch(
        saveBoChiSo({
          value: fixedNumber(valueSanLuong || 0),
          total: fixedNumber(tongSanLuong || 0),
          chenh_lech: fixedNumber((tongSanLuong - valueSanLuong) || 0),
        })
      );
    }
  };

  const getRowClassName = (record) => {
    let className = "cursor-pointer";
    const index = dataSource.findIndex((e) => e.id === record.id);
    const loaiNhap = dataSource[index].loaiNhap;
    const classErrorSanLuong = setClassErrorSanLuong({ dataSource, record, index });
    const classErrorChiSoCuoi = setClassErrorChiSoCuoi({ dataSource, index });
    className = className + classErrorSanLuong + classErrorChiSoCuoi;
    if (rowSelected.id === record.id) {
      className = className + " table-row-active";
    }

    if (loaiNhap === ENUM_QL_CHI_SO_GNDN_LOAI_NHAP.LAY_DO_XA.value) {
      if (record.isRecordFisrt) {
        className = className + " table-row-yellow";
      } else {
        className = className + " table-row-green-bold";
      }
    } else if (loaiNhap === ENUM_QL_CHI_SO_GNDN_LOAI_NHAP.NHAP_TAY.value) {
      if (record.isRecordFisrt) {
        className = className + " table-row-green";
      } else {
        className = className + " table-row-white";
      }
    } else {
      className = className + " table-row-white";
    }

    return className;
  };

  useEffect(() => {
    if(!loading){
      startSearch();
    }
  }, [filter.loaiNhap, loading]);

  const getDataByLoaiNhap = () => {
    let dataClone = [];

    const dataSourceOriginalClone = cloneDeep(dataSourceOriginal);

    switch (filter.loaiNhap) {
      case ENUM_QL_CHI_SO_GNDN_LOAI_NHAP.ALL.value:
        dataClone = [...dataSourceOriginalClone];
        break;
      case ENUM_QL_CHI_SO_GNDN_LOAI_NHAP.NHAP_TAY.value:
        dataClone = dataSourceOriginalClone.filter((item) => item.loaiNhap === ENUM_QL_CHI_SO_GNDN_LOAI_NHAP.NHAP_TAY.value);
        break;
      case ENUM_QL_CHI_SO_GNDN_LOAI_NHAP.LAY_DO_XA.value:
        dataClone = dataSourceOriginalClone.filter((item) => item.loaiNhap === ENUM_QL_CHI_SO_GNDN_LOAI_NHAP.LAY_DO_XA.value);
        break;
      default:
        dataClone = dataSourceOriginalClone.filter((item) => item.loaiNhap === ENUM_QL_CHI_SO_GNDN_LOAI_NHAP.CHUA_NHAP.value);
      // code block
    }
    return dataClone;
  };

  const startSearch = () => {
    const dataClone = getDataByLoaiNhap();
    const findItems = dataClone.filter(
      (item) =>
        item.maDiemDo.toLowerCase().includes(filter.keyWord.toLowerCase()) ||
        item.tenDiemDo.toLowerCase().includes(filter.keyWord.toLowerCase())
    );

    const arr = [];
    findItems.forEach((item) => {
      const isCheck = arr.some((e) => e?.diemDoId === item?.diemDoId);
      if (isCheck) {
        arr.push(item);
      } else {
        arr.push({
          ...item,
          isRecordFisrt: true,
        });
      }
    });
    setDataSource([...arr]);
  };

  const handleKeyDownSearchTerm = (event) => {
    const { keyCode } = event;
    if (keyCode === 13) {
      // press Enter
      startSearch();
    }
  };

  const handlePageChange = (page) => {
    if (page !== page.current) {
      setPageInfo({ ...pageInfo, current: page });
      const queryString = {
        pageIndex: page,
      };
      getListDiemDo({
        setDataSource,
        setLoading,
        queryString,
        setDataSourceInitial,
      });
    }
  };

  return (
    <div style={{ padding: 10 }}>
      <DanhSachDiemDoHeader
        totalData={dataSource.length}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        handleKeyDownSearchTerm={handleKeyDownSearchTerm}
        disableActionBtn={loading}
        dataSource={dataSource}
        tramId={tramIdSelected}
        refresh={handleGetDiemDo}
        filter={filter}
        startSearch={startSearch}
        setFilter={setFilter}
        currentPage={pageInfo.current}
        setLoadingList={setLoading}
        setDataSource={setDataSource}
        dataSourceOriginal={dataSourceOriginal}
        setDataSourceInitial={setDataSourceInitial}
      />
      <TableStyled>
        <div style={{ paddingTop: 10, width: "100%" }}>
          <Table
            className={"row-color-table table-expandedRowKeys-all"}
            pagination={false}
            loading={loading}
            rowSelection={rowSelection}
            scroll={{ y: "calc(100vh - 250px)", x: 2000 }}
            columns={DanhSachDiemDoColumn({
              handleDetailDiemDo,
              dataSource,
              setDataSource,
              dataSourceOriginal,
              setDataSourceInitial
            })}
            bordered={true}
            dataSource={dataSource}
            components={vt}
            rowKey="id"
            rowClassName={(record) => getRowClassName(record)}
            onRow={(record) => {
              return {
                onClick: (event) => {
                  handleClickRow(record, event);
                },
              };
            }}
          />
          {!tramIdSelected && (
            <PaginationCustom
              onPageChange={(e) => {
                handlePageChange(e);
              }}
              totalPages={pageInfo.totalPages || 1}
              currentPage={pageInfo.current}
            />
          )}
        </div>
      </TableStyled>
    </div>
  );
}