import styled from "styled-components";

export const Container = styled.div`
  margin-top: 12px;
  padding: 150px;

  @media screen and (max-width: 1200px) {
    padding: 72px;
  }

  .ant-picker {
    width: 100%;
  }
`;

export const FullCol = styled.div`
  .ant-select.ant-select-single.ant-select-show-arrow,
  .ant-picker {
    width: 100%;
  }
`;
