import { BASE_API_URL_CRM } from "@utils/constants";

export const APINVHT_CRM = {
  CRMNVHT_DANH_SACH_YEU_CAU_DUOC_PHAN_CONG: `${BASE_API_URL_CRM}/yeucau/yeu-cau-duoc-phan-cong-nvht`,
  CRMNVHT_DANH_SACH_YEU_CAU_DANG_THUC_HIEN: `${BASE_API_URL_CRM}/yeucau/yeu-cau-dang-thuc-hien-nvht`,
  CRMNVHT_XAC_NHAN_YEU_CAU: `${BASE_API_URL_CRM}/xacnhan/nvht`,
  CRMNVHT_YEU_CAU_CHO_DUYET: `${BASE_API_URL_CRM}/yeucau/yeu-cau-cho-duyet-nvht`,
  CRMNVHT_YEU_CAU_CO_TRO_NGAI: `${BASE_API_URL_CRM}/yeucau/yeu-cau-co-tro-ngai-nvht`,
  CRMNVHT_DANH_SACH_XAC_NHAN_YEU_CAU: `${BASE_API_URL_CRM}/yeucau/xac-nhan-yeu-cau-nvht`,
  CRMNVHT_DANH_SACH_TRO_NGAI: `${BASE_API_URL_CRM}/common/tro-ngai`,
  CRMNVHT_CAP_NHAT_TRO_NGAI: `${BASE_API_URL_CRM}/nvhientruong/cap-nhat-tro-ngai`,
  CRMNVHT_KHAO_SAT_LAI: `${BASE_API_URL_CRM}/nvhientruong/khao-sat-lai`,
};
