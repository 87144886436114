import styled from "styled-components";
export const Style = styled.div`
  .nguoi-giam-sat {
    .ant-form-item-label {
      flex-basis: 26.2%;
      text-align: left;
    }
  }
  .nguoi-giam-sat-kd {
    .ant-form-item-label {
      flex-basis: 34.7%;
      text-align: left;
    }
  }
  .ant-form {
    position: relative;
  }
  .ant-radio-group .ant-radio-wrapper:first-child {
    position: absolute;
    top: 27px;
  }
  .ant-radio-group .ant-radio-wrapper:last-child {
    position: absolute;
    top: 137px;
  }
`;
