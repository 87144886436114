import PencilIcon from "@assets/icon/PencilIcon";
import { Fragment, useCallback, useEffect, useState } from "react";
import { handleList, handleListViTri } from "./services";
import TableCauHinh from "./table";
import CauHinhModal from "./modal";
import { Tag } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import CheckPermission from "@components/CheckPermission";
import { treo_thao } from "@permissions/b_treothao";

export default function Index() {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState({});
  const [dataViTri, setDataViTri] = useState([]);
  //fetch dữ liệu

  const getData = useCallback(() => {
    handleList(setLoading, setData);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    handleListViTri(setLoading, setDataViTri);
  }, []);

  const columns = [
    {
      title: "Tên tiến trình",
      key: "tenFlow",
      dataIndex: "tenFlow",
      width: "35%",
    },
    {
      title: "Gửi Mail",
      key: "sendMail",
      dataIndex: "sendMail",
      width: "20%",
      render: (actived) => (
        <div className="table-text-center">
          {actived ? (
            <CheckCircleFilled style={{ fontSize: "20px", color: "#03A4FF" }} />
          ) : null}
        </div>
      ),
    },
    {
      title: "Đối tượng nhận Mail",
      key: "doiTuongNhanMail",
      dataIndex: "doiTuongNhanMail",
      width: "35%",
      render: (arr) => {
        return (
          <div className="table-text-center">
            {arr?.map((item, index) => {
              return (
                <Tag color="#108ee9" key={index}>
                  {item.maViTriCongViec}
                </Tag>
              );
            })}
          </div>
        );
      },
    },
    {
      title: "Tác vụ",
      key: "action",
      // width: "10%",
      fixed: "right",
      render: (row) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={treo_thao.b101s}>
            <div className="action-icon" onClick={() => handleOpenModal(row)}>
              <PencilIcon />
            </div>
          </CheckPermission>
        </div>
      ),
    },
  ];

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) {
        let newData = {};
        const detailDoiTuong = [];
        if (row.doiTuongNhanMail.length > 0) {
          row.doiTuongNhanMail.map((item) => {
            return detailDoiTuong.push(item.viTriCongViecId);
          });
        }

        newData = {
          ...row,
          doiTuongNhanMail: detailDoiTuong,
        };
        setDetail(newData);
      } else setDetail({});
      setVisible(!visible);
    },
    [visible]
  );

  //đóng popup

  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
  };

  //render popup

  const showModal = useCallback(
    () =>
      visible && (
        <CauHinhModal
          detail={detail}
          visible={visible}
          closeModal={handleCloseModal}
          getListHoSo={getData}
          dataViTri={dataViTri}
        />
      ),
    [dataViTri, detail, getData, visible]
  );

  return (
    <Fragment>
      <TableCauHinh data={data} loading={loading} columns={columns} />
      {showModal()}
    </Fragment>
  );
}
