import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";

import { Button } from "antd";
import { tien_ich_chung } from "@permissions/m_tienichchung";
import CheckPermission from "@components/CheckPermission";
import HinhThucLapDatModal from "./modal-hinh-thuc-thi-cong";
import { useTranslation } from "react-i18next";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import { handleListData, handleRemoveData } from "./services";
import Table from "./table";

export default function Index(props) {
  const { skip } = props;
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [detailThiCong, setDetailThiCong] = useState({});
  const [dataThiCong, setDataThiCong] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu

  const getListThiCong = useCallback(() => {
    handleListData(
      setLoading,
      filterConditions,
      setDataThiCong,
      setTotalCount,
      skip
    );
  }, [filterConditions, skip]);

  useEffect(() => {
    getListThiCong();
    // eslint-disable-next-line
  }, [getListThiCong]);

  //thay đổi pagination table

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //hiển thị các button thêm,...

  const genExtra = () => (
    <div>
      <CheckPermission
        permissionCode={
          skip === "false" ? tien_ich_chung.m11taotc : tien_ich_chung.m119t
        }
      >
        <Button
          size="small"
          type="primary"
          className="mr-5px"
          onClick={() => handleOpenModal()}
        >
          {t("button.tao_moi")}
        </Button>
      </CheckPermission>
    </div>
  );

  //khai bái columns

  const columns = [
    {
      title: "Mã hình thức thi công",
      dataIndex: "maHinhThucTC",
      key: "maHinhThuc",
      sorter: skip === "false" ? false : true,
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "30%",
    },
    {
      title: "Tên hình thức thi công",
      dataIndex: "tenHinhThucTC",
      key: "tenHinhThucTC",
      sorter: skip === "false" ? false : true,
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "60%",
    },
    {
      title: t("tac_vu"),
      key: "action",
      fixed: "right",
      render: (row) => (
        <div className="action-table-column">
          <CheckPermission
            permissionCode={
              skip === "false" ? tien_ich_chung.m11suatc : tien_ich_chung.m119s
            }
          >
            <div className="action-icon" onClick={() => handleOpenModal(row)}>
              <PencilIcon />
            </div>
          </CheckPermission>
          <CheckPermission
            permissionCode={
              skip === "false" ? tien_ich_chung.m11xoatc : tien_ich_chung.m119x
            }
          >
            <div
              className="action-icon"
              onClick={() => handleDeleteData(row.id)}
            >
              <TrashIcon />
            </div>
          </CheckPermission>
        </div>
      ),
    },
  ];

  //tìm kiếm dữ liệu

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  //xóa dữ liệu
  const handleDeleteData = useCallback(
    (ids) => {
      handleRemoveData(ids, setLoading, getListThiCong, t);
    },
    [getListThiCong, t]
  );

  //hiển thị popup, set dữ liệu chi tiết

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDetailThiCong(row);
      else setDetailThiCong({});
      setVisible(!visible);
    },
    [visible]
  );

  //đóng popup

  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
  };

  //render popup

  const showModal = useMemo(
    () =>
      visible && (
        <HinhThucLapDatModal
          detailThiCong={detailThiCong}
          visible={visible}
          closeModal={handleCloseModal}
          getListThiCong={getListThiCong}
        />
      ),
    // eslint-disable-next-line
    [visible]
  );

  return (
    <Fragment>
      <Table
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        genExtra={genExtra}
        dataSource={dataThiCong}
        columns={columns}
        totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        t={t}
        skip={skip}
      />
      {showModal}
    </Fragment>
  );
}
