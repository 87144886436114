import ButtonComponent from "@components/Button";
import CheckPermission from "@components/CheckPermission";
import Selection from "@components/Select";
import { cham_soc_khach_hang } from "@permissions/j_cskh";
import { FORMAT_MONTH, METHOD_GET } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData } from "@utils/request";
import { Col, DatePicker, Form, Row, Select, Tabs } from "antd";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import SelectionSo from "../../common/SelectSo";


export function FilterXacNhan(props) {
  const {
    setFilterConditions,
    setLoading,
    setDoituongId,
    doituongId,
    setData,
    setUrlDataKH,
    form,
    urlDataKH,
    handleKySo,
    filterConditions,
    handeleHuyKySo,
  } = props;
  const { TabPane } = Tabs;
  const [urlMaSo, setUrlMaSo] = useState("");
  const [isDisable, setIsDisable] = useState(false);

  const options = [
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3",
      value: 3,
    },
  ];
  useEffect(() => {
    authGetData({
      url: `${Endpoint.CSKH_DOITUONG}`,
      method: METHOD_GET,
      setLoading,
      onSuccess: (res) => {
        setData(res);
      },
    });
  }, [setLoading, setData]);
  const handleChangeToDoi = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      ma_to_1: values,
      p_ma_sogcs: [],
    }));
    form.setFieldsValue({"DanhSachSo": undefined})
    setIsDisable(false)
  };
  const handleChangeKy = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      p_ky: values,
    }));
  };
  const handleChangeDoiTuong = (values) => {
    setDoituongId(values);
    setFilterConditions((oldState) => ({
      ...oldState,
      doiTuongId: values,
      p_ma_sogcs: [],
    }));
    form.setFieldsValue({"DanhSachSo": undefined})
    setIsDisable(false)
  };
  const hanldeHanThangNam = (dates, dateStrings) => {
    const dateCu = moment(dates._d).subtract(1, "months");
    setFilterConditions((state) => ({
      ...state,
      nam_ht: parseInt(dateStrings.split("/")[1]),
      nam_cu: parseInt(dateCu.year().toString()),
      thang_ht: parseInt(dateStrings.split("/")[0]),
      thang_cu: parseInt(`${dateCu.month() + 1}`),
      p_nam: parseInt(dateStrings.split("/")[1]),
      p_thang: parseInt(dateStrings.split("/")[0]),
    }));
  };
  useEffect(() => {
    const maSoValue = {
      TBNGUNGCCD: "e12382fc-5ebb-474d-a1a7-de277b424031",
      TBTIENDIENL2: "e12382fc-5ebb-474d-a1a7-de277b424030",
    };
    const queryMaSo = buildQueryString(
      parseParams({
        doiTuongId: filterConditions?.doiTuongId,
        id_hinhthuc: 5,
        ma_to_1: filterConditions?.ma_to_1,
        nam_cu: filterConditions?.nam_cu,
        nam_ht: filterConditions?.nam_ht,
        p_ky: filterConditions?.p_ky,
        p_nam: filterConditions?.p_nam,
        p_thang: filterConditions?.p_thang,
        thang_cu: filterConditions?.thang_cu,
        thang_ht: filterConditions?.thang_ht,
        Loai_cto: filterConditions?.Loai_cto,
        p_TrangThaiID: filterConditions?.p_TrangThaiID,
      })
    );
    if (doituongId === maSoValue.TBNGUNGCCD && filterConditions.ma_to_1) {
      setUrlMaSo(`${Endpoint.CSKH_DSS_TB_NGUNG_CAP_DIEN}?${queryMaSo}`);
      setUrlDataKH(`${Endpoint.CSKH_DSkh_TB_NGUNG_CAP_DIEN}`);
    }
    if (doituongId === maSoValue.TBTIENDIENL2 && filterConditions.ma_to_1) {
      setUrlMaSo(`${Endpoint.CSKH_DSS_TBTD_LAN_2}?${queryMaSo}`);
      setUrlDataKH(`${Endpoint.CSKH_DSkh_TBTD_LAN_2}`);
    }
  }, [doituongId, urlDataKH, filterConditions, setUrlDataKH]);
  const handleNgayDSSO = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      p_ma_sogcs: values,
    }));
  };

  return (
    <>
      <Fragment>
        <Form
          form={form}
          layout="vertical"
          className="form-content"
          onFinish={handleKySo}
        >
          <Row gutter={24}>
            <div style={{ paddingBottom: 20, width: "100%", display: "flex" }}>
              <Col span={12}>
                <Form.Item
                  name="DoiTuongId"
                  label="Đối tượng"
                  labelAlign="left"
                  rules={[
                    {
                      required: true,
                      message: "Đối tượng sổ không được để trống",
                    },
                  ]}
                >
                  <Selection
                    url={Endpoint.LIST_DOI_TUONG_KY_SO}
                    formKey="DoiTuongId"
                    placeholder="Chọn đối tượng"
                    form={form}
                    setValue={handleChangeDoiTuong}
                  />
                </Form.Item>
              </Col>

              <Col span={5}>
                <Form.Item name="ky" label="Kỳ" labelAlign="left">
                  <Select
                    options={options}
                    defaultValue={1}
                    onChange={handleChangeKy}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="ThangNam"
                  label="Tháng/Năm"
                  labelAlign="left"
                  rules={[
                    {
                      required: true,
                      message: "Tháng năm không được để trống",
                    },
                  ]}
                >
                  {form.setFieldsValue({
                    [`ThangNam`]: moment(),
                  })}
                  <DatePicker
                    picker="month"
                    formKey="ThangNam"
                    onChange={hanldeHanThangNam}
                    format={FORMAT_MONTH}
                    defaultValue={moment()}
                    form={form}
                  />
                </Form.Item>
              </Col>
            </div>
          </Row>
          <Row gutter={20}>
            <Col span={5}>
              <Form.Item
                name="ToDoiId"
                label="Tổ đội"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Tổ đội không được để trống",
                  },
                ]}
              >
                <Selection
                  url={Endpoint.TODOI_LOGIN}
                  placeholder="Chọn tổ đội"
                  formKey="ToDoiId"
                  setValue={handleChangeToDoi}
                  form={form}
                />
              </Form.Item>
            </Col>
            <Col span={13}>
              <Form.Item
                name="DanhSachSo"
                label="Danh sách sổ"
                rules={[
                  {
                    required: true,
                    message: "Danh sách sổ không được để trống",
                  },
                ]}
              >
                {doituongId &&
                doituongId.length > 0 &&
                filterConditions?.ma_to_1 ? (
                  <SelectionSo
                    url={urlMaSo}
                    formKey="DanhSachSo"
                    placeholder="Chọn danh sách sổ"
                    form={form}
                    loading
                    mode={"multiple"}
                    setValue={handleNgayDSSO}
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : (
                  <Select disabled />
                )}
              </Form.Item>
            </Col>
            <Col span={2} style={{ paddingTop: "30px", marginRight: "15px" }}>
              <CheckPermission permissionCode={cham_soc_khach_hang.j13h}>
                <ButtonComponent
                  type="danger"
                  onClick={handeleHuyKySo}
                  size="default"
                >
                  Hủy ký số
                </ButtonComponent>
              </CheckPermission>
            </Col>
            <Col
              span={3}
              style={{ paddingTop: "30px" }}
            >
              <CheckPermission permissionCode={cham_soc_khach_hang.j13t}>
                <ButtonComponent
                  type="primary"
                  htmlType="submit"
                  size="default"
                >
                  Ký số
                </ButtonComponent>
              </CheckPermission>
            </Col>
          </Row>
        </Form>
      </Fragment>
    </>
  );
}
