import {
  Form,
  Button,
  Row,
  Col,
  DatePicker,
  Collapse,
  ConfigProvider,
  Pagination,
  Input,
} from "antd";
import FormComponent from "@components/Form";
import moment from "moment";
import { FORMAT_DATE } from "@utils/constants";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import vn from "antd/es/locale-provider/vi_VN";
import { useEffect, useMemo } from "react";
import Columns from "../../columns-don-gia";

const { Panel } = Collapse;

export default function FormThoiGian(props) {
  const {
    handleAddThongTin,
    addThongTin,
    form2,
    data,
    ngayBatDau,
    ngayKetThuc,
    onFinishVatTuThoiGian,
    handleChangeBatDau,
    handleChangeKetThuc,
    many,
    dataSource,
    handleAddForUpdateData,
    handleAdd,
    handleBackForm,
    loading,
    t,
    handleChangePage,
    totalCount,
    dataHinhThuc,
    tableColums,
    nameFields,
    handleRemove,
    dataTable,
    handleRemoveThoiGian,
    setKeyData,
    pageDefault,
    countAdd,
  } = props;

  useEffect(() => {
    form2.setFieldsValue({
      donGiaMayThiCong: data?.donGiaMayThiCong ? data?.donGiaMayThiCong : 0,
      donGiaNhanCongNoiBo: data?.donGiaNhanCongNoiBo
        ? data?.donGiaNhanCongNoiBo
        : 0,
      donGiaVatTu: data?.donGiaVatTu ? data?.donGiaVatTu : 0,
      ngayHieuLuc: addThongTin
        ? undefined
        : data?.ngayHieuLuc
        ? moment(data?.ngayHieuLuc, FORMAT_DATE)
        : moment(ngayBatDau, FORMAT_DATE),
      ngayHetHieuLuc:
        addThongTin ||
        data?.ngayHetHieuLuc === null ||
        data?.ngayHetHieuLuc === undefined
          ? undefined
          : data?.ngayHetHieuLuc
          ? moment(data?.ngayHetHieuLuc, FORMAT_DATE)
          : moment(ngayKetThuc, FORMAT_DATE),
    });
    if (!addThongTin) {
      // eslint-disable-next-line array-callback-return
      dataTable.map((item) => {
        form2.setFieldsValue({
          [`hinhThucThiCong-${item.key}`]:
            item[`hinhThucThiCongId-${item.key}`],
          [`dgtg1-${item.key}`]: item[`dgtg1-${item.key}`],
          [`dgtg2-${item.key}`]: item[`dgtg2-${item.key}`],
          [`dgtg3-${item.key}`]: item[`dgtg3-${item.key}`],
          [`dgttc1-${item.key}`]: item[`dgttc1-${item.key}`],
          [`dgttc2-${item.key}`]: item[`dgttc2-${item.key}`],
          [`dgttc3-${item.key}`]: item[`dgttc3-${item.key}`],
          [`dgttcvt1-${item.key}`]: item[`dgttcvt1-${item.key}`],
          [`dgttcvt2-${item.key}`]: item[`dgttcvt2-${item.key}`],
          [`dgttcvt3-${item.key}`]: item[`dgttcvt3-${item.key}`],
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addThongTin, data, dataTable, form2]);

  const renderPagination = useMemo(
    () =>
      totalCount &&
      !addThongTin && (
        <ConfigProvider locale={vn}>
          <Pagination
            total={totalCount}
            defaultPageSize={1}
            onChange={handleChangePage}
            current={pageDefault}
          />
        </ConfigProvider>
      ),
    [addThongTin, handleChangePage, pageDefault, totalCount]
  );

  return (
    <Collapse defaultActiveKey={["1"]}>
      <Panel header="Thông tin đơn giá" key="1">
        <Row gutter={24} style={{ marginBottom: "36px" }}>
          <Col span={24} md={18}>
            {renderPagination}
          </Col>
          <Col span={24} md={6} style={{ textAlign: "rgiht" }}>
            <div
              className="action-table-column"
              style={{ justifyContent: "end" }}
            >
              <div className="action-icon">
                <Button
                  type="primary"
                  onClick={handleAddThongTin}
                  disabled={addThongTin}
                >
                  Thêm thông tin đơn giá
                </Button>
              </div>

              <div className="action-icon">
                <Button
                  disabled={addThongTin}
                  type="danger"
                  onClick={() => handleRemoveThoiGian(data.vatTuThoiGianId)}
                >
                  Xóa
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <FormComponent
          autoComplete="off"
          form={form2}
          key={2}
          name="form-don-gia"
          initialValues={{
            ...data,
            donGiaVatTu: data?.donGiaVatTu ? data?.donGiaVatTu : 0,
            donGiaMayThiCong: data?.donGiaMayThiCong
              ? data?.donGiaMayThiCong
              : 0,
            donGiaNhanCongNoiBo: data?.donGiaNhanCongNoiBo
              ? data?.donGiaNhanCongNoiBo
              : 0,
            ngayHieuLuc:
              data?.ngayHieuLuc !== null && data?.ngayHieuLuc !== undefined
                ? moment(data?.ngayHieuLuc, FORMAT_DATE)
                : moment(ngayBatDau, FORMAT_DATE),
            ngayHetHieuLuc:
              data?.ngayHetHieuLuc !== null &&
              data?.ngayHetHieuLuc !== undefined
                ? moment(data?.ngayHetHieuLuc, FORMAT_DATE)
                : moment(ngayKetThuc, FORMAT_DATE),
          }}
          onFinish={onFinishVatTuThoiGian}
          layout="vertical"
          scrollToFirstError
        >
          <Row gutter={24}>
            <Col span={24} md={12}>
              <Form.Item
                label="Ngày hiệu lực"
                name="ngayHieuLuc"
                className="required-field"
              >
                <DatePicker
                  locale={locale}
                  format={FORMAT_DATE}
                  onChange={handleChangeBatDau}
                  placeholder={FORMAT_DATE}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label="Ngày hết hiệu lực" name="ngayHetHieuLuc">
                <DatePicker
                  locale={locale}
                  format={FORMAT_DATE}
                  onChange={handleChangeKetThuc}
                  placeholder={FORMAT_DATE}
                  allowClear={true}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item
                label="Đơn giá vật liệu"
                name="donGiaVatTu"
                className="required-field"
              >
                <Input
                  style={{ textAlign: "right" }}
                  placeholder="Nhập đơn giá vật liệu"
                  type="number"
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item
                label="Đơn giá máy thi công"
                name="donGiaMayThiCong"
                className="required-field"
              >
                <Input
                  style={{ textAlign: "right" }}
                  placeholder="Nhập đơn giá máy thi công"
                  type="number"
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item
                label="Đơn giá nhân công quyết toán (Nội bộ)"
                name="donGiaNhanCongNoiBo"
                className="required-field"
              >
                <Input
                  style={{ textAlign: "right" }}
                  placeholder="Nhập đơn giá nhân công quyết toán (Nội bộ)"
                  type="number"
                  min={0}
                />
              </Form.Item>
            </Col>

            <Columns
              dataSource={addThongTin ? dataSource : dataTable}
              handleAdd={addThongTin ? handleAddForUpdateData : handleAdd}
              isHinhThuc={many}
              dataHinhThuc={dataHinhThuc}
              tableColums={tableColums}
              nameFields={nameFields}
              handleRemove={handleRemove}
              setKeyData={setKeyData}
              countAdd={countAdd}
            />
          </Row>
          <Row gutter={24} justify="center" style={{ marginTop: "12px" }}>
            <Col span={24} style={{ textAlign: "right", marginBottom: "12px" }}>
              {addThongTin ? (
                <Button onClick={handleBackForm} style={{ marginRight: "8px" }}>
                  Quay lại
                </Button>
              ) : null}
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                loading={loading}
              >
                {t("button.cap_nhat")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Panel>
    </Collapse>
  );
}
