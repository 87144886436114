import styled from "styled-components";

export const Style = styled.div`
  .phan-cong-modal {
    .ant-form-item-label > label:after {
      display: none;
    }
    .ant-form-item-label > label {
      margin-right: 14px;
    }
  }
`;
