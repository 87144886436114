import "moment/locale/vi";
import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { authPostData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";

export const submitDongBoService = (
  detail,
  setLoading,
  setFetchDataTable,
  closeModal,
  getListHoSo,
  isMultiple,
  setSelectedRowKeys
) => {
  let ids = {};
  //TH nhận list id
  if (isMultiple) {
    ids = {
      ids: detail.toString(),
    };
  } else {
    ids = {
      ids: detail.hoSoId.toString(),
    };
  }
  authPostData({
    url: Endpoint.SYNC_TIEP_NHAN_YEU_CAU_THOIGIAN,
    payload: ids,
    method: METHOD_PUT,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchDataTable(true);
        closeModal();
        getListHoSo();
        if (isMultiple) {
          setSelectedRowKeys([]);
        }

        if (setSelectedRowKeys) {
          setSelectedRowKeys([]);
        }
      }
    },
  });
};
