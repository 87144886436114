import { Col, Form, Row, Button, Select } from "antd";
import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import moment from "moment";
import {
  ENUM_BUTTON_TYPE,
  FORMAT_MONTH,
  FORMAT_MONTH_ENGLISH,
} from "@utils/constants";
import CheckPermission from "@components/CheckPermission";
import { cap_dien } from "@permissions/a_capdien";

const { Option } = Select;

export default function FormBoLoc(props) {
  const {
    form,
    handleSearch,
    loaiYeuCau,
    filterConditions,
    clear,
    hinhThucLapDat,
    mucDich,
    setType,
  } = props;
  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      autoComplete="off"
      initialValues={{}}
    >
      <Row gutter={24}>
        <Col span={24} md={4}>
          <Form.Item name="loaiYeuCauId" label="Loại tổng hợp">
            <Select placeholder="Tất cả" allowClear>
              {loaiYeuCau && loaiYeuCau.length > 0
                ? loaiYeuCau.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="hinhThucLapDatId" label="Hình thức lắp đặt">
            <Select placeholder="Tất cả" allowClear>
              {hinhThucLapDat && hinhThucLapDat.length > 0
                ? hinhThucLapDat.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="loaiHopDong" label="Loại hợp đồng">
            <Select placeholder="Tất cả" allowClear>
              {mucDich && mucDich.length > 0
                ? mucDich.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="ngayBaoCao" label="Tháng báo cáo">
            <DatePickerComponent
              defaultValue={
                filterConditions.ngayBaoCao
                  ? moment(filterConditions.ngayBaoCao, FORMAT_MONTH)
                  : moment(moment(), FORMAT_MONTH)
              }
              format={FORMAT_MONTH_ENGLISH}
              form={form}
              formKey={"ngayBaoCao"}
              picker="month"
              notClear={true}
            />
          </Form.Item>
        </Col>

        <Col
          span={24}
          md={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button className="mr-24px" onClick={clear}>
            Bỏ lọc
          </Button>
          <Button
            type="primary"
            className="mr-24px"
            htmlType="submit"
            form="filter-form"
            onClick={() => setType(ENUM_BUTTON_TYPE.TIM_KIEM)}
          >
            Tìm kiếm
          </Button>
          <CheckPermission permissionCode={cap_dien.a504_xuat}>
            <Button
              type="primary"
              className="mr-24px"
              htmlType="submit"
              form="filter-form"
              onClick={() => setType(ENUM_BUTTON_TYPE.XUAT_EXCEL)}
            >
              Xuất excel
            </Button>
          </CheckPermission>
        </Col>
      </Row>
    </FormComponent>
  );
}
