import { BASE_API_URL_GNDN } from "@utils/constants";

export const APIQLChiSo = {
  QLCS_GET_DANH_SACH_TRAM: `${BASE_API_URL_GNDN}/quanlychiso/danh-sach-tram`,
  QLCS_GET_DANH_SACH_DIEMDO: `${BASE_API_URL_GNDN}/quanlychiso/danh-sach-diem-do`,
  QLCS_GET_DETAIL_DIEMDO: `${BASE_API_URL_GNDN}/quanlychiso/thong-tin-diem-do`,
  QLCS_LAY_DOXA: `${BASE_API_URL_GNDN}/quanlychiso/lay-do-xa`,
  GET_THANGLAMVIEC: `${BASE_API_URL_GNDN}/diemdo/lay-thang-lam-viec`,
  QLCS_HUY_CHISO: `${BASE_API_URL_GNDN}/quanlychiso/huy-chi-so`,
  QLCS_CAPNHAT_CHISO: `${BASE_API_URL_GNDN}/quanlychiso/cap-nhat-chi-so`,
  QLCS_DIEM_DO_CHUA_NHAP_CHI_SO: `${BASE_API_URL_GNDN}/quanlychiso/diem-do-chua-nhap-chi-so`,
};
