import { customColumn, headerWithSummary } from "@utils/function";
import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";

import { Fragment } from "react";
import { Container } from "./css-styled";
import ListFilter from "./list-bo-loc.jsx";

export default function BaoCaoTongHopTable(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    totalCount,
    loading,
    scrollX,
    scrollY,
    columns,
    data,
    // countTotals,
    onChangePagination,
    vat,
    setType,
  } = props;
  //render note item for columns
  const noteColumns = [
    { title: "1", fixed: true },
    { title: "2", fixed: true },
    { title: "3", fixed: true, last: true },
    { title: "4" },
    { title: "5" },
    { title: "6" },
    { title: "7" },
    { title: "8=(4-7)" },
    { title: `9=8*${vat}` },
    { title: "10" },
    { title: "11" },
    { title: "12" },
    { title: "13" },
    { title: "14=(10-13)" },
    { title: `15=14*${vat}` },
    { title: "16=(9+15)" },
  ];
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          setType={setType}
        />
      </Filter>
      <Container>
        <TableComponent
          bordered
          header={"Tổng hợp chi phí quyết toán"}
          totalData={totalCount}
          loading={loading}
          pagination={false}
          scrollX={scrollX}
          scrollY={scrollY}
          columns={customColumn(columns, filterConditions)}
          dataSource={data}
          onChangePagination={onChangePagination}
          // summary={(pageData) => countTotals(pageData)}
          components={{
            header: {
              wrapper: (props) => headerWithSummary(props, noteColumns),
            },
          }}
        />
      </Container>
    </Fragment>
  );
}
