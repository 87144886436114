import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import UIVatTuDuToan from "./ui-modal/vat-tu-du-toan";
import {
  createVatTuKHDT,
  getListLoaiChiPhi,
  getListVatTuHinhThucThiCong,
  getVatTuKHDT,
  updateVatTuKHDT,
} from "../../../../services";
import { ENUM_VATTU } from "@utils/constants";

export default function VatTuKhachHangDuToanModal({
  visible,
  detailVatTu,
  closeModal,
  typeVatTu,
  detailLapPhuongAn,
  refreshTable,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [listVatTuTheoNgay, setListVatTu] = useState([]);
  const [listHTTC, setListHTTC] = useState([]);
  const [listChiPhi, setListChiPhi] = useState([]);
  // const { tongChiPhi } = useSelector(lapPhuongAnSelector);
  // const dispatch = useDispatch();

  // const toNumber = (str) => +str;\

  useEffect(() => {
    if (typeVatTu === ENUM_VATTU.COSAN) {
      getListLoaiChiPhi(setLoading, setListChiPhi, t);
      getListVatTuHinhThucThiCong(
        detailLapPhuongAn,
        setLoading,
        setListHTTC,
        setListVatTu,
        t
      );
    } else {
      getListLoaiChiPhi(setLoading, setListChiPhi, t);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailLapPhuongAn]);

  useEffect(() => {
    if (detailVatTu && Object.keys(detailVatTu).length > 0 && detailVatTu.id) {
      if (
        Array.isArray(listVatTuTheoNgay) &&
        listVatTuTheoNgay.length > 0 &&
        typeVatTu === ENUM_VATTU.COSAN
      ) {
        getVatTuKHDT(
          detailVatTu.id,
          (values) => {
            const vatTuDetail = listVatTuTheoNgay.find(
              (vt) => vt.vatTuId === values.vatTuId
            );
            if (Array.isArray(vatTuDetail?.donGiaHieuLuc?.hinhThucThiCongs)) {
              setListHTTC(vatTuDetail?.donGiaHieuLuc?.hinhThucThiCongs);
            }
            form.setFieldsValue({
              ...values,
            });
          },
          setLoading,
          t,
          typeVatTu
        );
      } else {
        if (typeVatTu === ENUM_VATTU.MUANGOAI) {
          getVatTuKHDT(
            detailVatTu.id,
            (values) => {
              const vatTuDetail = listVatTuTheoNgay.find(
                (vt) => vt.vatTuId === values.vatTuId
              );
              if (Array.isArray(vatTuDetail?.donGiaHieuLuc?.hinhThucThiCongs)) {
                setListHTTC(vatTuDetail?.donGiaHieuLuc?.hinhThucThiCongs);
              }
              form.setFieldsValue({
                ...values,
              });
            },
            setLoading,
            t,
            typeVatTu
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailVatTu, typeVatTu, listVatTuTheoNgay]);

  const handleSubmitData = (values) => {
    const data = {
      ...values,
      hoSoId: detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id,
      id:
        detailVatTu && Object.keys(detailVatTu) && detailVatTu.id
          ? detailVatTu.id
          : null,
      soLuong: Number(values.soLuong),
    };
    handleCreateUpdate(data);
  };

  //xử lý call api sửa/tạo

  const handleCreateUpdate = (data) => {
    // const url = "";

    if (data.id) {
      //sửa  vật tư

      updateVatTuKHDT(
        data,
        setLoading,
        refreshTable,
        closeModal,
        form,
        typeVatTu,
        // (value) => {
        //   if (value && tongChiPhi) {
        //     const newTongChiPhi =
        //       toNumber(tongChiPhi) -
        //       toNumber(value.donGiaCu) +
        //       toNumber(value.donGiaMoi);
        //     dispatch(updateTongChiPhi(newTongChiPhi));
        //   }
        // }
        () => false
      );
    } else {
      //tạo  vật tư

      createVatTuKHDT(
        data,
        refreshTable,
        setLoading,
        closeModal,
        form,
        typeVatTu,
        // (value) => {
        //   if (value && tongChiPhi) {
        //     const newTongChiPhi =
        //       toNumber(tongChiPhi) -
        //       toNumber(value.donGiaCu) +
        //       toNumber(value.donGiaMoi);
        //     dispatch(updateTongChiPhi(newTongChiPhi));
        //   }
        // }
        () => false
      );
    }
  };

  const handleVatTuChange = (values) => {
    const vatTuDetail = listVatTuTheoNgay.find((vt) => vt.vatTuId === values);

    if (Array.isArray(vatTuDetail?.donGiaHieuLuc?.hinhThucThiCongs)) {
      setListHTTC(vatTuDetail?.donGiaHieuLuc?.hinhThucThiCongs);
      form.resetFields(["hinhThucThiCongId"]);
    }
  };

  return (
    <UIVatTuDuToan
      visible={visible}
      closeModal={closeModal}
      form={form}
      t={t}
      handleSubmitData={handleSubmitData}
      detailVatTu={detailVatTu}
      loading={loading}
      typeVatTu={typeVatTu}
      listVatTuTheoNgay={listVatTuTheoNgay}
      listHTTC={listHTTC}
      listChiPhi={listChiPhi}
      handleVatTuChange={handleVatTuChange}
    />
  );
}
