import { MenuOutlined } from "@ant-design/icons";
import { Button, Dropdown, Spin, Table } from "antd";
import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import ListChucNang from "./list-chuc-nang";
import { FilterTreoThao } from "./list-filter";
import DanhSachDiemDoTabs from "../cap-nhat-thong-tin-diem-do/tabs/danh-sach-diem-do";
import { getDataDiemDo } from "./services";
import { useSelector } from "react-redux";
import { systemConfigSelector } from "@state/system-config/reducer";
import { useCustomDebounce } from "@utils/request";
import EllipsisTooltip from "@components/EllipsisTooltip";
import Scrollbars from "react-custom-scrollbars-2";
import TableComponent from "@components/TableComponent";

export default function TreoThaoThietBi() {
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: 1,
    pageSize: 30,
  });
  const [loading, setLoading] = useState(false);
  const [dataPage, setDataPage] = useState();
  const [data, setData] = useState([]);
  const modalData = useSelector(systemConfigSelector);
  const [values, setValues] = useState([]);
  const debounceSearch = useCustomDebounce(filterConditions);
  const getListDD = (values) => {
    setValues(values);
    handleSearch();
  };

  const handleSearch = () => {
    if (filterConditions.pageIndex) {
      const payload = {
        pageIndex: filterConditions.pageIndex,
        pageSize: filterConditions.pageSize,
        SearchTerm: debounceSearch?.handleSearch,
      };
      getDataDiemDo(setData, setLoading, setDataPage, payload);
    }
  };

  useEffect(() => {
    const payload = {
      pageIndex: filterConditions.pageIndex,
      pageSize: filterConditions.pageSize,
      SearchTerm: debounceSearch?.handleSearch,
    };
    getDataDiemDo(setData, setLoading, setDataPage, payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch]);

  const columns = [
    {
      title: "",
      dataIndex: "",
      width: 46,
      fixed: "left",
      render: (row) => (
        <div style={{ textAlign: "center" }}>
          <Dropdown
            overlay={
              <div
                className="list-menu-function"
                style={{
                  boxShadow:
                    " 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
                }}
              >
                <ListChucNang
                  detail={row}
                  loading={loading}
                  setLoading={setLoading}
                  values={values}
                  setDataTable={setData}
                  getListDD={getListDD}
                  columns={columns}
                ></ListChucNang>
              </div>
            }
            trigger={["click"]}
          >
            <Button type="primary" icon={<MenuOutlined />}></Button>
          </Dropdown>
        </div>
      ),
    },
    {
      title: "Thông tin điểm đo",
      key: "thongTinDiemDo",
      width: 200,
      align: "center",
      render: (record) => (
        <EllipsisTooltip
          line="1"
          placement="topRight"
          title={record.thongTinDiemDo}
        >
          {record.thongTinDiemDo}
        </EllipsisTooltip>
      ),
    },
    {
      title: "Mã điểm đo",
      key: "maDiemDo",
      align: "center",
      render: (record) => (
        <EllipsisTooltip line="1" placement="topRight" title={record.maDiemDo}>
          {record.maDiemDo}
        </EllipsisTooltip>
      ),
      width: 150,
    },
    {
      title: "Tính chất",
      key: "tinhChat",
      align: "center",
      width: 150,
      render: (record) => (
        <EllipsisTooltip line="1" placement="topRight" title={record.tinhChat}>
          {record.tinhChat}
        </EllipsisTooltip>
      ),
    },
    {
      title: "Mã công tơ",
      key: "maCongTo",
      align: "center",
      width: 200,
      render: (record) => (
        <>
          {record.thucHien === null || record.thucHien === 2
            ? null
            : record.maCongTo}
        </>
      ),
    },
    {
      title: "Ngày treo tháo",
      key: "ngayTreoThao",
      align: "center",
      width: 150,
      render: (record) => (
        <>
          {record.thucHien === null || record.thucHien === 2
            ? null
            : record.ngayTreoThao}
        </>
      ),
    },
    {
      title: "Mã biên bản",
      width: 150,
      align: "center",
      key: "maBienBan",
      render: (record) => (
        <>
          {record.thucHien === null || record.thucHien === 2
            ? null
            : record.maBienBan}
        </>
      ),
    },
    {
      title: "Giao nhận",
      key: "giaoNhan",
      algin: "center",
      width: 200,
      render: (record) => (
        <EllipsisTooltip line="1" placement="topRight" title={record.giaoNhan}>
          {record.giaoNhan}
        </EllipsisTooltip>
      ),
    },

    {
      title: "Cấp điện áp",
      width: 200,
      align: "center",
      key: "capDienAp",
      render: (record) => (
        <EllipsisTooltip line="1" placement="topRight" title={record.capDienAp}>
          {record.capDienAp}
        </EllipsisTooltip>
      ),
    },
    {
      title: "Trạm",
      width: 200,
      align: "center",
      key: "tram",
      render: (record) => (
        <EllipsisTooltip line="1" placement="topRight" title={record.tram}>
          {record.tram}
        </EllipsisTooltip>
      ),
    },
    {
      title: "Đơn vị quản lý",
      align: "center",
      width: 200,
      key: "donViQuanLy",
      render: (record) => (
        <EllipsisTooltip
          line="1"
          placement="topRight"
          title={record.donViQuanLy}
        >
          {record.donViQuanLy}
        </EllipsisTooltip>
      ),
    },
  ];

  return (
    <Fragment>
      <Container>
        <Spin spinning={loading}>
          <Search>
            <FilterTreoThao
              modalData={modalData}
              onSearch={getListDD}
              filterConditions={filterConditions}
              setDataTable={setData}
              values={values}
              setFilterConditions={setFilterConditions}
            ></FilterTreoThao>
          </Search>

          <DanhSachDiemDoTabs dataTable={dataPage} />

          <div
            className="loading-scrollbar"
            style={{ paddingTop: 10, width: "100%" }}
          >
            <Scrollbars
              style={{ height: window.innerHeight > 660 ? "80vh" : "68vh" }}
              autoHide
              onScrollFrame={(e) => {
                if (
                  e.top * 100 > 99 &&
                  filterConditions.pageSize < dataPage.totalCount
                ) {
                  setFilterConditions(() => ({
                    ...filterConditions,
                    pageSize: debounceSearch.pageSize + 30,
                  }));
                }
              }}
            >
              {window.innerWidth > 1366 ? (
                <TableComponent
                  sticky={true}
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  bordered
                  scroll={{ x: true }}
                />
              ) : (
                <div className="f" style={{ width: 1800 }}>
                  <TableComponent
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    bordered
                    scroll={{ x: 500 }}
                  />
                </div>
              )}
            </Scrollbars>
          </div>
        </Spin>
      </Container>
    </Fragment>
  );
}
const Container = styled.div`
  padding: 5px 20px;
  .ant-table-tbody > tr > td {
    padding: 8px !important;
  }
  .ant-table {
    font-size: 14px !important;
  }
`;
const Search = styled.div`
  padding: 20px 0 0 20px;
`;
