import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData, authDeleteData } from "@utils/request";
import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import {
  buildQueryString,
  parseParams
} from "@utils/function";

// get list data bảng kê nhân viên cần xác nhận
export const handleListBKNVXacNhan = (
  setLoading,
  location,
  setListBKNVXacNhan,
  setTotalCount,
  filterConditions,
  setSelectedRowKeys
) => {
  let url = "";
  url = `${Endpoint.AG_NV_LIST_XAC_NHAN_BK}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
    authGetData({
      url,
      setLoading,
    onSuccess: (res) => {
      setSelectedRowKeys([]);
      if (res.data !== null) {
        setListBKNVXacNhan(res.data);
        setTotalCount(res.paging.totalCount);
      } else setListBKNVXacNhan([]);
    },
  });
};

export const submitNhanVienXacNhan = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.AG_NV_XAC_NHAN_BK,
    method: METHOD_PUT,
    payload: {
      ids: selectedRowKeys,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
      }
    },
  });
};

export const submitHuyBK = (setLoading, setFetchDataTable, id) => {
  authDeleteData({
    url: `${Endpoint.AG_NV_HUY_BK}/${id}`,
    setLoading,
    content: `Bạn chắc chắn muốn hủy bảng kê ?`,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) setFetchDataTable(true);
    },
  });
};
  