import { BASE_API_URL_CRM } from "@utils/constants";

export const API_DOI_TRUONG_CRM = {
  CRM_DOI_TRUONG_PHAN_CONG_DOI: `${BASE_API_URL_CRM}/doitruong/doi`,
  CRM_DOI_TRUONG_PHAN_CONG_NGUOI_KHAO_SAT: `${BASE_API_URL_CRM}/doitruong/nguoi-khao-sat`,
  CRM_DOI_TRUONG_DANH_SACH_YEU_CAU: `${BASE_API_URL_CRM}/yeucau/yeu-cau-doi-truong`,
  CRM_DOI_TRUONG_PHAN_CONG: `${BASE_API_URL_CRM}/doitruong/phan-cong`,
  CRM_DOI_TRUONG_DANH_SACH_DUYET_YEU_CAU: `${BASE_API_URL_CRM}/yeucau/duyet-yeu-cau-doi-truong`,
  CRM_DOI_TRUONG_DUYET: `${BASE_API_URL_CRM}/doitruong/duyet`,
  CRM_DOI_TRUONG_TRA_YEU_CAU: `${BASE_API_URL_CRM}/tralai/doi-truong`,
  CRM_DOI_TRUONG_XAC_NHAN: `${BASE_API_URL_CRM}/xacnhan/doi-truong`,
  CRM_DOI_TRUONG_KY_SO: `${BASE_API_URL_CRM}/kyso/doi-truong`,
  CRM_DOI_TRUONG_KY_HSM: `${BASE_API_URL_CRM}/kyhsm/doi-truong`,
};
