import { Col, Form, Row, Select, Button, Table, InputNumber } from "antd";
import { useEffect, useState } from "react";
import FormComponent from "@components/Form";
import { Fragment } from "react";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import { ContainerDK } from "../css-styled";
import { closeModal } from "@state/system-config/reducer";
import LoadingComponent from "@components/Loading";
import { handleDongBoCmis, handlePTDongBoVeWeb } from "../services";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_KY_GCS_KTCT,
  FORMAT_ENGLISH,
  FORMAT_MONTH,
  OPERATORS,
} from "@utils/constants";
import { handlePagination } from "@utils/function";
import DatePickerComponent from "../../datepicker/DatePicker";
import moment from "moment";
const { Option } = Select;
export default function Index({ typeDongBo }) {
  const { user } = useSelector(authSelector);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });
  const [call, setCall] = useState(false);
  const [dataSync, setDataSync] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.isExistWebOrDuplicateKey === true,
    }),
  };

  // column table phúc tra

  const columnsTableKTPT = [
    // {
    //   title: "STT",
    //   dataIndex: "stt",
    //   key: "index",
    //   render: (item, record, index) => (
    //     <div className="table-text-center">
    //       {filterConditions && filterConditions.pageIndex > 1
    //         ? filterConditions.pageSize * (filterConditions.pageIndex - 1) +
    //           (index + 1)
    //         : index + 1}
    //     </div>
    //   ),
    //   width: 70,
    //   sorter: false,
    // },
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 170,
      sorter: true,
    },
    {
      title: "Mã sổ GCS",
      dataIndex: "maSoGhiChiSo",
      key: "maSoGhiChiSo",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 150,
      sorter: true,
    },
    {
      title: "Mã công tơ",
      dataIndex: "maCongTo",
      key: "maCongTo",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 180,
      sorter: true,
    },
    {
      title: "Ngày GCS",
      dataIndex: "thangNamGCS",
      key: "thangNamGCS",
      render: (text, record) => (
        <div className="table-text-center">
          {record.ngayGCS ? record.ngayGCS + "/" : ""}
          {record.thangGCS ? record.thangGCS + "/" : ""}
          {record.namGCS ? record.namGCS : ""}
        </div>
      ),
      width: 150,
      sorter: false,
    },
    {
      title: "Kỳ GCS",
      dataIndex: "kyGCS",
      key: "kyGCS",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 100,
      sorter: true,
    },
    {
      title: "Sản lượng kỳ này",
      dataIndex: "sanLuongKyNay",
      key: "sanLuongKyNay",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 150,
      sorter: true,
    },
    {
      title: "Sản lượng kỳ trước",
      dataIndex: "sanLuongKyTruoc",
      key: "sanLuongKyTruoc",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 150,
      sorter: true,
    },
    {
      title: "Chênh lệch Kwh",
      dataIndex: "chenLechKwh",
      key: "chenLechKwh",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 150,
      sorter: true,
    },
    {
      title: "Chênh lệch %",
      dataIndex: "chenhLechPhanTram",
      key: "chenhLechPhanTram",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 150,
      sorter: true,
    },
    {
      title: "Trạm",
      dataIndex: "tenTram",
      key: "tenTram",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "20%",
      sorter: true,
    },
    {
      title: "Tên khách hàng",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
      sorter: true,
    },
  ];
  // tìm kiếm dữ liệu theo điều kiện search

  const onFinish = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
      ngay: values.thangNamGCS
        ? values.thangNamGCS.split("-")[2].toString()
        : null,
      thang: values.thangNamGCS
        ? values.thangNamGCS.split("-")[1].toString()
        : null,
      nam: values.thangNamGCS
        ? values.thangNamGCS.split("-", 1).toString()
        : null,
      thangNamGCS: null,
      kyGCS: null,
    }));
    setCall(true);
    setDataSync({});
  };
  // call api đồng bộ từ cmis

  useEffect(() => {
    form.setFieldsValue({
      ky: ENUM_KY_GCS_KTCT.KY_1,
      isLonHonBang : true,
      sanLuong : 0
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (call)
      handleDongBoCmis(
        setLoading,
        setDataSource,
        typeDongBo,
        form,
        setTotalCount,
        filterConditions
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions, call]);
  // hàm phân trang

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setDataSync({});
  };
  // call api đồng bộ về web

  const handleDBWEB = () => {
    const arr = selectedRowKeys
      .map((id) => {
        const dataFind = dataSource.find((x) => x.soThuTuTraVeTuCmis === id);
        if (
          dataFind &&
          Object.keys(dataFind) &&
          Object.keys(dataFind).length > 0
        ) {
          return dataFind;
        } else {
          return undefined;
        }
      })
      .filter(Boolean);
    handlePTDongBoVeWeb(arr, setLoading, setDataSync, setSelectedRowKeys);
  };

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={onFinish}
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
      }}
      autoComplete="off"
    >
      <LoadingComponent loading={loading}>
        <ContainerDK>
          <Row gutter={24}>
            <Fragment>
              <Col span={24} md={5}>
                <Form.Item name="donViId" label="Đơn vị">
                  {!user.isTongCongTy ? (
                    <Select defaultValue={user.unitId} disabled>
                      <Select.Option value={user.unitId}>
                        {user.unitName}
                      </Select.Option>
                    </Select>
                  ) : (
                    <Selection
                      url={Endpoint.LIST_DONVI_KTCT}
                      form={form}
                      formKey="donViId"
                      notClear={true}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={24} md={4}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => {
                    if (prevValues.donViId !== currentValues.donViId) {
                      form.setFieldsValue({
                        doiId: undefined,
                      });
                    }
                    return prevValues.donViId !== currentValues.donViId;
                  }}
                >
                  {({ getFieldValue }) => {
                    if (getFieldValue("donViId")) {
                      return (
                        <Form.Item name="doiId" label="Đội">
                          <Selection
                            url={`${
                              Endpoint.LIST_DOI_KTCT
                            }?donviId=${getFieldValue("donViId")}`}
                            formKey="doiId"
                            form={form}
                          />
                        </Form.Item>
                      );
                    }
                    return (
                      <Form.Item name="doiId" label="Đội">
                        <Selection
                          url={`${Endpoint.LIST_DOI_KTCT}?donviId=${user.unitId}`}
                          formKey="doiId"
                          form={form}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={24} md={4}>
                <Form.Item name="thangNamGCS" label="Ngày GCS">
                  <DatePickerComponent
                    defaultValue={
                      filterConditions.thangNamGCS
                        ? moment(filterConditions.thangNamGCS, FORMAT_ENGLISH)
                        : moment(moment(), FORMAT_MONTH)
                    }
                    format={FORMAT_ENGLISH}
                    form={form}
                    formKey={"thangNamGCS"}
                    // picker="month"
                    notClear={true}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={2}>
                <Form.Item name="ky" label="Kỳ GCS">
                  <Select defaultValue={ENUM_KY_GCS_KTCT.KY_1}>
                    <Option value={ENUM_KY_GCS_KTCT.KY_1}>1</Option>
                    <Option value={ENUM_KY_GCS_KTCT.KY_2}>2</Option>
                    <Option value={ENUM_KY_GCS_KTCT.KY_3}>3</Option>
                    <Option value={ENUM_KY_GCS_KTCT.KY_4}>4</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} md={3}>
                <Form.Item name="chenhLechPhanTram" label="Chênh lệch %">
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col span={24} md={2}>
                <Form.Item name="chenhLechKwh" label="Chênh lệch Kwh">
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col span={24} md={4}>
                <Row>
                  <Col span={8}>
                    <Form.Item name="isLonHonBang" label="Sản lượng">
                      <Select defaultValue={true}>
                        <Option value={true}>
                          {OPERATORS.GREATER_OR_EQUAL}
                        </Option>
                        <Option value={false}>
                          {OPERATORS.LESS_OR_EQUAL}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item name="sanLuong" label=" ">
                      <InputNumber />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={24} md={20}>
                <div style={{ color: "red" }}>
                  Các công tơ có trị tuyệt đối của chênh lệch %, trị tuyệt đối
                  của chênh lệch Kwh lớn hơn giá trị đã nhập. Trường hợp không
                  nhập giá trị thì không dùng làm điều kiện lọc
                </div>
                <div style={{ color: "red" }}>
                  Bắt buộc phải nhập 1 trong 2 trường Chênh lệch % hoặc Chênh
                  lệch Kwh.
                </div>
              </Col>
              <Col
                span={24}
                md={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 50,
                }}
              >
                <Button
                  style={{ marginTop: 5 }}
                  type="primary"
                  htmlType="submit"
                  form="filter-form"
                >
                  Lấy dữ liệu từ CMIS
                </Button>
              </Col>
              <Col
                span={24}
                md={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  marginBottom: 20,
                }}
              >
                <Button
                  type="primary"
                  className="mr-24px"
                  onClick={() => handleDBWEB()}
                  disabled={!selectedRowKeys.length}
                >
                  Đồng bộ về Web
                </Button>
              </Col>
              <Col span={24} md={20}>
                {dataSync && Object.keys(dataSync).length > 0 ? (
                  <div style={{ marginBottom: 20 }}>
                    <div>
                      Số yêu cầu tiếp nhận:{"  "}
                      <span style={{ fontWeight: 600 }}>
                        {dataSync.soYeuCauTiepNhan}
                      </span>
                    </div>
                    <div>
                      Số yêu cầu đồng bộ thành công:{"  "}
                      <span
                        style={{ fontWeight: 600, color: "rgb(82, 196, 26)" }}
                      >
                        {dataSync.thanhCong}
                      </span>
                    </div>
                    <div>
                      Số yêu cầu đồng bộ thất bại:{"  "}
                      <span
                        style={{ fontWeight: 600, color: "rgb(255, 142, 143)" }}
                      >
                        {dataSync.thatBai}
                      </span>
                    </div>
                  </div>
                ) : null}
              </Col>
              <Col span={24} md={24}>
                <Table
                  columns={columnsTableKTPT}
                  dataSource={dataSource}
                  rowSelection={rowSelection}
                  rowKey={"soThuTuTraVeTuCmis"}
                  scroll={{ x: 2400, y: 520 }}
                  pagination={{
                    total: totalCount,
                    showTotal: (total) => `Tổng ${total} bản ghi`,
                    defaultpageSize: DEFAULT_PAGESIZE,
                    defaultCurrent: DEFAULT_PAGEINDEX,
                    current: parseInt(filterConditions.pageIndex),
                    pageSize: parseInt(filterConditions.pageSize),
                    showSizeChanger: true,
                    pageSizeOptions: [10, 20, 50, 100, 200, 500],
                  }}
                  onChange={onChangePagination}
                ></Table>
              </Col>
            </Fragment>
          </Row>
          <div className="ant-modal-footer text-center">
            <Button type="default" onClick={() => dispatch(closeModal())}>
              Đóng lại
            </Button>
          </div>
        </ContainerDK>
      </LoadingComponent>
    </FormComponent>
  );
}
