import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { DANH_SACH_DON_VI_DIEN_LUC } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import { Option } from "antd/lib/mentions";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ModalUpdateThuTienDien from "./bc-chi-tiet-tong-hop-thanh-toan-qua-ngan-hang/modal-update-thu-tien-dien";
import { handleDownload, selectNganHang, selectTHTT } from "./bc-chi-tiet-tong-hop-thanh-toan-qua-ngan-hang/service";
import { handleListBaoCao } from "./service";

export default function TongHopTTQNganHangTCTGThanhToan() {
  const [visibleUpdate, setVisibleUpdate] = useState();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);
  const [baocao, setBaocao] = useState();
  const [dataTable, setDataTable] = useState();

  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };

  const [unitKeyFilter, setUnitKeyFilter] = useState("");

  const handleChangeReportType = () => {
    setBaocao(form.getFieldValue("reportType"));
    setUnitKeyFilter(
      form.getFieldValue("reportType") === Endpoint.BCCN_BC_CT_KHACHHANG_TRICH_NO_TU_DONG
        ? DANH_SACH_DON_VI_DIEN_LUC.TONG_CONG_TY.value
        : ""
    );
  };

  const handleShowFiledTuNgayOrDenNgay = (reportType) => {
    const listReportTypeShow = [
      Endpoint.BCCN_BC_TH_THU_NOP_QUA_NH,
      Endpoint.BCCN_BC_SO_THU_DICH_VU_TIEN_DIEN_QUA_NH_TGTGTT,
      Endpoint.BCCN_BC_CT_KHACHHANG_TRICH_NO_TU_DONG,
      Endpoint.BCCN_BC_SO_THU_DICH_VU_TIEN_DIEN_QUA_NH_TGTGTT_THEONGAY,
      Endpoint.BCCN_BC_SO_THU_DICH_VU_TIEN_DIEN_QUA_NH_TGTGTT_DONVI,
      Endpoint.BCCN_BC_KHACHHANG_THUC_TRUY_NO_QUA_ECPAY,
      Endpoint.BCCN_BC_SO_THU_ECPAY_KENH_AUTO,
      Endpoint.BCCTDVKH_TH_THU_CSKH_CUA_CAC_DON_VI,
      Endpoint.BCCN_THU_HO_CAC_TCTG_VA_NGAN_HANG,
      Endpoint.BCCN_TONG_HOP_KHACH_HANG_THANH_TOAN_PHI_CAT_DIEN,
    ];
    return listReportTypeShow.some((item) => item === reportType);
  };
  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Tổng hợp thanh toán qua ngân hàng
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <div className="flex">
              <FlexForm>
                <FormComponent
                  onFinish={onFinish}
                  form={form}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 14 }}
                  initialValues={{
                    reportType: Endpoint.BCCN_BC_TH_SO_THU_THEO_CAC_PHUONG_THUC_VA_HINH_THUC_THU,
                    unitId: user.user.unitCode,
                    LoaiKhachHang: "-1",
                    BankId: "-1",
                  }}
                  colon={false}
                >
                  <Form.Item name="reportType" label="Loại báo cáo" labelAlign="left" required>
                    <Select
                      options={selectTHTT}
                      placeholder="Tất cả"
                      formKey="reportType"
                      form={form}
                      onChange={handleChangeReportType}
                    />
                  </Form.Item>
                  <Form.Item name="unitId" label="Đơn vị" labelAlign="left" required>
                    <Selection url={Endpoint.GET_TEAM_LIST_CODE} formKey="unitId" form={form} filterKey={unitKeyFilter} />
                  </Form.Item>

                  {/* Tháng báo cáo */}
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("reportType") !== Endpoint.BCCN_BC_KHACHHANG_THUC_TRUY_NO_QUA_ECPAY &&
                      getFieldValue("reportType") !== Endpoint.BCCN_BC_SO_THU_ECPAY_KENH_AUTO &&
                      getFieldValue("reportType") !== Endpoint.BCCTDVKH_TH_THU_CSKH_CUA_CAC_DON_VI &&
                      getFieldValue("reportType") !== Endpoint.BCCN_THU_HO_CAC_TCTG_VA_NGAN_HANG &&
                      getFieldValue("reportType") !== Endpoint.BCCN_TONG_HOP_KHACH_HANG_THANH_TOAN_PHI_CAT_DIEN ? (
                        <Form.Item name="reportDate" label="Tháng báo cáo" labelAlign="left" required>
                          <DatePickerComponent picker="month" formKey="reportDate" form={form} format={"YYYY-MM"} />
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>

                  {/* Loại khách hàng */}
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("reportType") === Endpoint.BCCN_BC_TH_SO_THU_THEO_CAC_PHUONG_THUC_VA_HINH_THUC_THU ||
                      getFieldValue("reportType") === Endpoint.BCCN_BC_CT_KHACHHANG_TRICH_NO_TU_DONG ||
                      getFieldValue("reportType") === Endpoint.BCCN_BC_SO_THU_DICH_VU_TIEN_DIEN_QUA_NH_TGTGTT_DONVI ||
                      getFieldValue("reportType") === Endpoint.BCCN_THU_HO_CAC_TCTG_VA_NGAN_HANG ||
                      getFieldValue("reportType") === Endpoint.BCCN_BIEU11_SO_THU_DICH_VU_TIEN_DIEN_NGAN_HANG_TO_CHUC_TGTT ? (
                        <Form.Item name="LoaiKhachHang" label="Loại khách hàng" labelAlign="left" required>
                          <Select placeholder="Chọn loại khách hàng" formKey="LoaiKhachHang">
                            <Option value="-1">Tất cả</Option>
                            <Option value="0">Sinh hoạt</Option>
                            <Option value="1">Ngoài sinh hoạt</Option>
                          </Select>
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>

                  {/* Từ Ngày */}
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
                  >
                    {({ getFieldValue }) =>
                      handleShowFiledTuNgayOrDenNgay(getFieldValue("reportType")) ? (
                        <Form.Item name="TuNgay" label="Từ ngày" labelAlign="left" required>
                          <DatePickerComponent formKey="TuNgay" form={form} picker="day" format={"YYYY-MM-DD"} />
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>
                  {/* Đến Ngày */}
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
                  >
                    {({ getFieldValue }) =>
                      handleShowFiledTuNgayOrDenNgay(getFieldValue("reportType")) ? (
                        <Form.Item name="DenNgay" label="Đến ngày" labelAlign="left" required>
                          <DatePickerComponent picker="day" formKey="DenNgay" form={form} format={"YYYY-MM-DD"} />
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>

                  {/* Ngân hàng  */}
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("reportType") === Endpoint.BCCN_BC_SO_THU_DICH_VU_TIEN_DIEN_QUA_NH_TGTGTT_THEONGAY ||
                      getFieldValue("reportType") === Endpoint.BCCN_BC_SO_THU_DICH_VU_TIEN_DIEN_QUA_NH_TGTGTT_DONVI ||
                      getFieldValue("reportType") === Endpoint.BCCN_TH_THANH_TOAN_24_48H ||
                      getFieldValue("reportType") === Endpoint.BCCN_BIEU11_SO_THU_DICH_VU_TIEN_DIEN_NGAN_HANG_TO_CHUC_TGTT ? (
                        <Form.Item name="BankId" label="Ngân hàng" labelAlign="left" required>
                          <Select options={selectNganHang} formKey="BankId" placeholder="Chọn ngân hàng" />
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>
                  <Row>
                    <Col span={4}></Col>
                    <Col span={15}>
                      <Button type="primary" htmlType="submit">
                        Xuất báo cáo
                      </Button>
                    </Col>
                  </Row>
                </FormComponent>
              </FlexForm>
              {baocao === Endpoint.BCCN_BAO_CAO_GIAM_SAT_CONG_TAC_THU_TIEN_DIEN_CUA_NGUOI_THUE_NHA ? (
                <FlexButton>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() =>
                      handleListBaoCao(
                        Endpoint.BCCN_DS_THU_TIEN_DIEN_CUA_NGUOI_THUE_NHA,
                        { MaDonVi: form.getFieldValue("reportType") },
                        setLoading,
                        setDataTable,
                        setVisibleUpdate
                      )
                    }
                  >
                    Cập nhật báo cáo
                  </Button>
                </FlexButton>
              ) : null}
            </div>
          </Spin>
        </ContentWrapper>
        {visibleUpdate && (
          <ModalUpdateThuTienDien
            visible={visibleUpdate}
            setVisible={setVisibleUpdate}
            dataTable={dataTable}
            setLoading={setLoading}
          />
        )}
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
const FlexForm = styled.div`
  flex: 4;
`;
const FlexButton = styled.div`
  flex: 1;
`;
