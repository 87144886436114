import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authPostData, authGetMultiplesRequest } from "@utils/request";

//call api update
export const handleUpdate = (
  setLoading,
  payload,
  setFetchList,
  closeModal,
  dispatch,
  setIsUpdate,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.UPDATE_MA_CHUNG_LOAI_CONG_TO_TREO_THAO,
    method: METHOD_PUT,
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
        // setSelectedRowKeys([]);
        dispatch(closeModal());
        if (setIsUpdate) setIsUpdate(true);
        else {
          setSelectedRowKeys([]);
        }
      }
    },
  });
};

//multi data ở bộ lọc
export const handleMultiDS = (
  selectedRowKeys,
  setLoading,
  setChungLoai,
  setDataSource
) => {
  const endpoints = [
    `${Endpoint.LIST_CHUNG_LOAI_CONG_TO_TREO_THAO}?Ids=${selectedRowKeys}`,
    `${Endpoint.LIST_CHUNG_LOAI_CONG_TO}?PageSize=-1`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDataSource(res[0].data.data);
      setChungLoai(res[1].data.data);
    },
  });
};
