import { giao_nhan_dien_nang } from "@permissions/c_gndn";
import { useState } from "react";
import LayoutBangKe from "../../common/table";

export default function Index() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [arrSelect, setArrSelect] = useState([]);
  const [fetchList, setFetchList] = useState(false);
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setArrSelect(selectedRows);
  };

  //Hiển thị checkbox ở đầu mỗi bảng
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };
  return (
    <LayoutBangKe
      rowSelection={rowSelection}
      selectedRowKeys={selectedRowKeys}
      setSelectedRowKeys={setSelectedRowKeys}
      arrSelect={arrSelect}
      isHuy={false}
      isTL
      permissionCodeKy={giao_nhan_dien_nang.c42k}
      permissionCodeHuy={giao_nhan_dien_nang.c42h}
      fetchList={fetchList}
      setFetchList={setFetchList}
      headerTable={"Ký bảng kê - Lãnh đạo (CTDL)"}
      loaiKy={2}
    />
  );
}
