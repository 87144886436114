import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Style } from "../css-styled";
function PhanCongKyThuatModal(props) {
  const { visible, handleCloseModal, handleSubmit, form, loading } = props;
  const unitId = useSelector((state) => state.auth.user.unitId);
  const teamId = useSelector((state) => state.auth.user.teamId);
  // const [doiId, setDoiId] = useState();

  useEffect(() => {
    form.setFieldsValue({
      doi: teamId,
    });
  }, [form, teamId]);

  return (
    <Modal
      title="PHÂN CÔNG."
      visible={visible}
      onCancel={handleCloseModal}
      footer={null}
      maskClosable={false}
    >
      <Style>
        <div className="phan-cong-modal">
          <Form
            form={form}
            onFinish={handleSubmit}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            requiredMark
            initialValues={{
              doi: teamId,
            }}
          >
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="doi"
                  required
                  label="Đội"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng chọn đội!",
                    },
                  ]}
                >
                  <Selection
                    disabled={teamId}
                    placeholder="Chọn đội"
                    formKey="doi"
                    form={form}
                    url={`${Endpoint.GET_TEAM_LIST}?DonViId=${unitId}`}
                    // setValue={setDoiId}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="nvHienTruong"
                  required
                  label="Nhân viên hiện trường"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng chọn nv hiện trường!",
                    },
                  ]}
                >
                  <Selection
                    placeholder="Chọn NV hiện trường của đội"
                    formKey="nvHienTruong"
                    form={form}
                    url={`${Endpoint.CRM_DOI_TRUONG_PHAN_CONG_NGUOI_KHAO_SAT}?DoiId=${teamId}`}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="nguoiGiamSat"
                  label="Người giám sát"
                  className="nguoi-giam-sat"
                >
                  <Selection
                    placeholder="Chọn NV hỗ trợ, giám sát"
                    formKey="nguoiGiamSat"
                    form={form}
                    url={`${Endpoint.CRM_LIST_NHAN_VIEN_GIAM_SAT}?DonViId=${unitId}`}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Ghi chú" name="ghichu">
                  <Input.TextArea row={6} placeholder />
                </Form.Item>
              </Col>

              <Col span={12} style={{ textAlign: "right" }}>
                <Button
                  key="back"
                  onClick={handleCloseModal}
                  className="button-closed"
                >
                  Đóng lại
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  key="submit"
                  type="primary"
                  className="button-primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Phân công
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Style>
    </Modal>
  );
}

export default PhanCongKyThuatModal;
