import React from "react";
import { Button, Modal, Table } from "antd";
import { ContainerButton, ContainerH1 } from "./css-style";

export default function Index(props) {
  const { visible, closeModal, data } = props;
  return (
    <Modal
      title={"Thông báo đồng bộ yêu cầu về CMIS"}
      visible={visible}
      onCancel={closeModal}
      footer={null}
      width={"60%"}
    >
      <ContainerH1>Thông tin đồng bộ yêu cầu về CMIS</ContainerH1>
      <h3>
        Số yêu cầu thành công:
        <span
          className="total-success"
          style={{ color: "#52c41a", fontWeight: "bold" }}
        >
          {" "}
          {data?.soYeuCauSyncSuccess}
        </span>
      </h3>
      <h3>
        Số yêu cầu thất bại:
        <span
          className="total-fail"
          style={{ color: "#ff8e8f", fontWeight: "bold" }}
        >
          {" "}
          {data?.soYeuCauSyncError}
        </span>
      </h3>
      <Table
        scroll={{ y: 300 }}
        dataSource={data.yeuCauErrors}
        columns={[
          {
            title: "STT",
            dataIndex: "stt",
            key: "stt",
            width: "10%",
            // render: (text, record, index) => (
            //   <div className="table-text-center">{index + 1}</div>
            // ),
          },
          {
            title: "Biên bản",
            dataIndex: "bienBanId",
            key: "bienBanId",
            width: "30%",
          },
          {
            title: "Ghi chú",
            dataIndex: "errorMessage",
            key: "errorMessage",
            width: "60%",
          },
        ]}
      />
      <ContainerButton>
        <Button key="back" type="default" onClick={() => closeModal()}>
          Đóng lại
        </Button>
      </ContainerButton>
    </Modal>
  );
}
