import { BASE_API_URL_GHI_CHI_SO } from "@utils/constants";

export const APIKyBangKe = {
  LIST_NV_GCS_KBK: `${BASE_API_URL_GHI_CHI_SO}/kybangkechitiet/nhan-vien-ghi-chi-so`,
  LIST_DOI_TRUONG_KBK: `${BASE_API_URL_GHI_CHI_SO}/kybangkechitiet/doi-truong`,
  LIST_NV_DIEU_HANH_KBK: `${BASE_API_URL_GHI_CHI_SO}/kybangkechitiet/nhan-vien-dieu-hanh`,
  LIST_PHONG_KINH_DOANH_KBK: `${BASE_API_URL_GHI_CHI_SO}/kybangkechitiet/phong-kinh-doanh`,
  NHAN_VIEN_XAC_NHAN: `${BASE_API_URL_GHI_CHI_SO}/kybangkechitiet/nhan-vien-xac-nhan`,
  NHAN_VIEN_TRA_BK: `${BASE_API_URL_GHI_CHI_SO}/kybangkechitiet/nhan-vien-tra-lai`,
  DOI_TRUONG_KY_SO: `${BASE_API_URL_GHI_CHI_SO}/kybangkechitiet/doi-truong-ky-so`,
  DIEU_HANH_KY_SO: `${BASE_API_URL_GHI_CHI_SO}/kybangkechitiet/dieu-hanh-ky-so`,
  PHONG_KD_KY_SO: `${BASE_API_URL_GHI_CHI_SO}/kybangkechitiet/phong-kinh-doanh-ky-so`,
  DOI_TRUONG_TRA_LAI_BK: `${BASE_API_URL_GHI_CHI_SO}/kybangkechitiet/doi-truong-tra-lai`,
  DIEU_HANH_TRA_LAI_BK: `${BASE_API_URL_GHI_CHI_SO}/kybangkechitiet/dieu-hanh-tra-lai`,
  PHONG_KD_TRA_LAI_BK: `${BASE_API_URL_GHI_CHI_SO}/kybangkechitiet/phong-kinh-doanh-tra-lai`,
  TRA_LAI_BK_CHO_PKD: `${BASE_API_URL_GHI_CHI_SO}/kybangkechitiet/tra-lai-cho-phong-kinh-doanh`,
  DOI_TRUONG_XAC_NHAN: `${BASE_API_URL_GHI_CHI_SO}/kybangkechitiet/doi-truong-xac-nhan`,
  DIEU_HANH_XAC_NHAN: `${BASE_API_URL_GHI_CHI_SO}/kybangkechitiet/nhan-vien-dieu-hanh-xac-nhan`,
  PHONG_KD_XAC_NHAN: `${BASE_API_URL_GHI_CHI_SO}/kybangkechitiet/phong-kinh-doanh-xac-nhan`,
  KY_SO: `${BASE_API_URL_GHI_CHI_SO}/kybangkechitiet/update-hop-dong-da-ky`,

  //ky hsm
  GCS_PKD_KY_HSM: `${BASE_API_URL_GHI_CHI_SO}/kyhsm/phong-kinh-doanh`,
  GCS_DT_KY_HSM: `${BASE_API_URL_GHI_CHI_SO}/kyhsm/doi-truong`,
  GCS_DH_KY_HSM: `${BASE_API_URL_GHI_CHI_SO}/kyhsm/dieu-hanh`,
};
