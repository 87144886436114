import React, { useState, useRef, useEffect } from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";

export default function ImageUpload(props) {
  const { setImageFile, currentImage, isTreoThao } = props;
  const [imageUrl, setImageUrl] = useState(currentImage);
  const inputRef = useRef(null);
  const uploadAvatar = (event) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];
      event.target.value = null;
      setImageUrl(URL.createObjectURL(img));
      setImageFile(img);
    }
  };
  useEffect(() => {
    setImageUrl(currentImage);
  }, [currentImage]);

  const handleClick = () => {
    inputRef.current.click();
  };
  return (
    <AvatarUploadContainerStyled>
      <input
        className="input-upload"
        type="file"
        name="myImage"
        onChange={uploadAvatar}
        ref={inputRef}
      />
      <div
        className="avatar-preview"
        onClick={handleClick}
        style={{ height: isTreoThao ? "330px" : "100%", width: "100%" }}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            width={isTreoThao ? "100%" : 100}
            height={isTreoThao ? "100%" : 100}
          />
        ) : (
          <div className="no-image">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        )}
      </div>
      <div className="remove-button">
        <Button
          type="danger"
          size="small"
          onClick={() => {
            setImageUrl(null);
            setImageFile(null);
          }}
        >
          Xóa
        </Button>
      </div>
    </AvatarUploadContainerStyled>
  );
}

const AvatarUploadContainerStyled = styled.div`
  display: flex;
  align-items: end;
  .avatar-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
    .no-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100px;
      height: 100px;
    }
  }
  .input-upload {
    display: none !important;
  }
  .remove-button {
    margin-left: 10px;
  }
`;
