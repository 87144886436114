import React, { useCallback, useState, useEffect, Fragment } from "react";
import { Input, Popover } from "antd";
import styled from "styled-components";
import { AppstoreOutlined } from "@ant-design/icons";
import { Row, Col, Spin } from "antd";
import { authGetData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import SystemItem from "@components/SystemItem";
import { authSelector, handleSelectModule } from "@state/auth/reducer";
import { useDispatch, useSelector } from "react-redux";
import { closeFilters } from "@state/cap-dien/system-filter/reducer";
import { MODULE_CODECAPDIEN } from "@utils/constants";
export default function SystemMenu() {
  const { moduleCode, user } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState([]);
  const [dataModule, setDataModule] = useState([]);
  useEffect(() => {
    authGetData({
      url: `${Endpoint.GET_MODULE_LIST}`,
      setLoading,
      onSuccess: (res) => {
        if (res && res.data) {
          res.data.map((item) => {
            if (item.moduleCode === moduleCode) {
              localStorage.setItem(
                "title-menu",
                item.shortName.toLocaleUpperCase()
              );
              dispatch(
                handleSelectModule({
                  moduleId: item.id,
                  moduleCode,
                })
              );
              if (item.moduleCode !== MODULE_CODECAPDIEN) {
                dispatch(closeFilters());
              }
            }
            return null;
          });

          setModules(res.data);
          setDataModule(res.data);
        }
      },
    });
  }, [dispatch, moduleCode]);

  const handleChange = useCallback(
    (e) => {
      let arr = [];
      const arr1 = [];
      const arr2 = [];
      // eslint-disable-next-line array-callback-return
      modules.map((item) => {
        if (
          item.shortName.toLowerCase().indexOf(e.target.value.toLowerCase()) >=
          0
        ) {
          arr1.push(item);
        } else arr2.push(item);
      });
      if (arr1.length > 0) arr = arr1;
      else arr = arr2;
      setDataModule(arr);
    },
    [modules]
  );

  const handleClickPopover = () => {};
  const renderContent = useCallback(() => {
    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingBottom: "20px",
          }}
        >
          <div style={{ width: "51%" }}></div>
          <div style={{ width: "49%" }}>
            <Input
              onChange={handleChange}
              allowClear
              placeholder="Tìm kiếm Modules"
            />
          </div>
        </div>
        <MenuItemsWrapper>
          <Spin spinning={loading}>
            <Row gutter={8}>
              {dataModule.length &&
                dataModule.map((item, idx) => {
                  if (item.shortName) {
                    if (
                      (user.permission &&
                        user.permission.length &&
                        user.permission.find(
                          (i) => i.charAt(0) === item.moduleCode
                        )) ||
                      user.isAdministrator
                    ) {
                      return (
                        <Col span={12} key={idx}>
                          <SystemItem item={item} />
                        </Col>
                      );
                    }
                    return null;
                  }
                  return null;
                })}
            </Row>
          </Spin>
        </MenuItemsWrapper>
      </Fragment>
    );
  }, [handleChange, loading, dataModule, user]);

  return (
    <Popover
      onClick={handleClickPopover}
      content={renderContent()}
      title={<div className="font-bold fs-16px">Chuyển hệ thống</div>}
      trigger="click"
      placement="bottomRight"
    >
      <SystemMenuWrapper>
        <AppstoreOutlined style={{ fontSize: 20 }} />
      </SystemMenuWrapper>
    </Popover>
  );
}

const SystemMenuWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;

const MenuItemsWrapper = styled.div`
  width: 500px;
`;
