import { alertMessage422 } from "@modules/bao-cao-kinh-doanh/service";
import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";

export const selectBCCNVDT = [
  {
    label: "1. Biểu 6 (1761) Bảng kê hóa đơn thu hộ Công ty điện lực khác",
    value: Endpoint.BCCN_BIEU6_BANG_KE_THU_HO_CONG_TY_DIEN_LUC_KHAC,
  },
  {
    label:
      "2. Biểu 7 (1761) Tổng hợp hóa đơn được công ty điện lực khác thu hộ",
    value: Endpoint.BCCN_TONG_HOP_HOA_DON_DUOC_CONG_TY_DIEN_LUC_KHAC_THU_HO,
  },
  {
    label: "3. Biểu 8 (1761) Bảng kê hóa đơn được công ty điện lực khác thu hộ",
    value: Endpoint.BCCN_BANG_KE_HOA_DON_DUOC_CONG_TY_DIEN_LUC_KHAC_THU_HO,
  },
  {
    label: "4. Biểu 9 (1761) Tổng hợp thu hộ tổng công ty",
    value: Endpoint.BCCN_TONG_HOP_THU_HO_TONG_CONG_TY,
  },
  {
    label: "5. Biểu 12 (1761) Tổng hợp khách hàng thanh toán trên web",
    value: Endpoint.BCCN_TONG_HOP_KHACH_HANG_THANH_TOAN_TREN_WEB,
  },
  {
    label:
      "6. Biểu 15 (1761) Tổng hợp hóa đơn thu hộ các công ty điện lực khác",
    value: Endpoint.BCCN_TONG_HOP_HOA_DON_THU_HO_CONG_TY_DIEN_LUC_KHAC,
  },
];

export const exportData = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      ...{ TuNgay: data.TuNgay },
      ...{ DenNgay: data.DenNgay },
    })
  );
  authGetData({
    url: `${data.reportType}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...data,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
    })
  );

  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
