import { Col, Input, Form, Row } from "antd";

export default function FormTheoMucDich(props) {
  const { form, t } = props;
  return (
    <Form
      name="form-cs-su-dung-dien"
      layout="vertical"
      form={form}
      onFinish={() => false}
    >
      <Row gutter={24}>
        <Col span={24} md={24}>
          <span className="fs-16px font-bold">
            1. {t("lap_phuong_an.thiet_bi.cs_su_dung_theo_muc_dich")}
          </span>
        </Col>
      </Row>
      <Row gutter={24} className="mt-16px">
        <Col span={24} md={6}>
          <Form.Item
            label={t("lap_phuong_an.thiet_bi.cs_lap_dat")}
            name="tongCongSuatLapDat"
          >
            <Input
              suffix="kW"
              readOnly={true}
              className="input-right"
              style={{
                backgroundColor: "#e2e2e2",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            label={t("lap_phuong_an.thiet_bi.cs_su_dung_cao")}
            name="congSuatSuDungCaoNhat"
          >
            <Input
              suffix="kW"
              readOnly={true}
              className="input-right"
              style={{
                backgroundColor: "#e2e2e2",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            label={t("lap_phuong_an.thiet_bi.cap_dien_ap_su_dung")}
            name="capDienApSuDung"
          >
            <Input
              suffix="V"
              readOnly={true}
              className="input-right"
              style={{
                backgroundColor: "#e2e2e2",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            label={t("lap_phuong_an.thiet_bi.cs_su_dung_trung_binh")}
            name="congSuatSuDungTrungBinh"
          >
            <Input
              suffix="kW"
              readOnly={true}
              className="input-right"
              style={{
                backgroundColor: "#e2e2e2",
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
