import { authSelector, handleSelectModule } from "@state/auth/reducer";
import {
  lapPhuongAnSelector,
  notSavedPhuongAnCapDien,
  notSavedThongTinCoBan,
  rangeOfChange,
  setCanShowDialogLeavingPACD,
} from "@state/cap-dien/nhan-vien-khao-sat/lap-phuong-an/reducer";
import {
  callAPIImageCMIS,
  saveDongBoImageScan,
  taiGiayToScanSelector,
} from "@state/cap-dien/tai-anh-scan/reducer";
import { xacNhanThiCongSelector } from "@state/cap-dien/thi-cong/xac-nhan-thi-cong";
import {
  notSavedPhanQuyenDiemDo,
  phanQuyenDiemDoSelector,
  setCanShowDialogLeavingTT,
} from "@state/giao-nhan-dien-nang/reducer";
import { closeModal } from "@state/system-config/reducer";
import { yeuCauDuocPhanCongSelector } from "@state/treo-thao/nhan-vien-treo-thao";
import {
  getSaveDataPopup,
  setCanShowDialogLeaving,
} from "@state/treo-thao/nhan-vien-treo-thao/reducer";
import {
  MODULE_APGIA,
  MODULE_BAOCAO,
  MODULE_CHAMSOCKHACHHANG,
  MODULE_CHISOCONGTO,
  MODULE_DICHVUDIEN,
  MODULE_DODEM,
  MODULE_GHICHISO,
  MODULE_HETHONG,
  MODULE_KIEMTRACONGTO,
  MODULE_LOTRINH,
  MODULE_THANHTOAN,
  MODULE_TICHHOPCRM,
  MODULE_TIENICHCHUNG,
  MODULE_TREOTHAOTHIETBI,
  MODULE_TRUNG_AP,
  NAVIGATE_BAOCAO,
  NAVIGATE_CHAMSOCKHACHHANG,
  NAVIGATE_CHISOCONGTO,
  NAVIGATE_DICHVUDIEN,
  NAVIGATE_DODEM,
  NAVIGATE_GHICHISO,
  NAVIGATE_HETHONG,
  NAVIGATE_KTCT,
  NAVIGATE_KTDINHMUC_APGIA,
  NAVIGATE_LOTRINH,
  NAVIGATE_THANHTOAN,
  NAVIGATE_TICHHOPCRM,
  NAVIGATE_TIENICHCHUNG,
  NAVIGATE_TRANGCHU,
  NAVIGATE_TREOTHAO,
  NAVIGATE_TRUNGAP,
} from "@utils/constants";
import { imagesHomePageIcon, stringToSlug } from "@utils/function";
import { Modal } from "antd";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ModalWarningChange from "./Layout/ModalWarningChange";

const SystemItem = (props) => {
  const { shortName, moduleCode, id } = props.item;
  const { user } = useSelector(authSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { phuongAnCapDien, thongTinCoBan } = useSelector(lapPhuongAnSelector);
  const { thongTin, congTo, thiCong } = useSelector(xacNhanThiCongSelector);
  const { saveData } = useSelector(yeuCauDuocPhanCongSelector);
  const { phanQuyenDiemDo } = useSelector(phanQuyenDiemDoSelector);
  const { dongBoImage } = useSelector(taiGiayToScanSelector);
  const renderIcon = useCallback(() => {
    if (moduleCode) {
      const findIcon = imagesHomePageIcon.find(
        (item) => item.name === stringToSlug(moduleCode)
      );
      if (findIcon) {
        return findIcon.path;
      }
      return "";
    }
  }, [moduleCode]);
  const handleRedirect = useCallback(
    (code) => {
      switch (code) {
        case MODULE_DICHVUDIEN:
          return user.isAdministrator || user.permission.includes("a0")
            ? navigate(NAVIGATE_DICHVUDIEN + "/trang-chu")
            : navigate(NAVIGATE_DICHVUDIEN);
        case MODULE_TREOTHAOTHIETBI:
          return navigate(NAVIGATE_TREOTHAO);
        case MODULE_DODEM:
          return navigate(NAVIGATE_DODEM);
        case MODULE_GHICHISO:
          return navigate(NAVIGATE_GHICHISO);
        case MODULE_APGIA:
          return navigate(NAVIGATE_KTDINHMUC_APGIA);
        case MODULE_BAOCAO:
          return navigate(NAVIGATE_BAOCAO);
        case MODULE_LOTRINH:
          return navigate(NAVIGATE_LOTRINH);
        case MODULE_THANHTOAN:
          return navigate(NAVIGATE_THANHTOAN);
        case MODULE_CHISOCONGTO:
          return navigate(NAVIGATE_CHISOCONGTO);
        case MODULE_CHAMSOCKHACHHANG:
          return navigate(NAVIGATE_CHAMSOCKHACHHANG);
        case MODULE_HETHONG:
          return navigate(NAVIGATE_HETHONG);
        case MODULE_TIENICHCHUNG:
          return navigate(NAVIGATE_TIENICHCHUNG);
        case MODULE_TICHHOPCRM:
          return navigate(NAVIGATE_TICHHOPCRM);
        case MODULE_KIEMTRACONGTO:
          return navigate(NAVIGATE_KTCT);
        case MODULE_TRUNG_AP:
          return navigate(NAVIGATE_TRUNGAP);
        default:
          return navigate(NAVIGATE_TRANGCHU);
      }
    },
    [navigate, user]
  );

  const selectSystem = useCallback(() => {
    if (dongBoImage === false) {
      Modal.confirm({
        centered: true,
        width: 600,
        title: "Xác nhận?",
        content:
          "Tồn tại ảnh/tài liệu chưa đồng bộ về CMIS, hệ thống sẽ tự động đồng bộ sau khi đóng màn hình !",
        onOk() {
          dispatch(callAPIImageCMIS(true));
          dispatch(saveDongBoImageScan(true));
          dispatch(closeModal());
          localStorage.setItem("title-menu", shortName.toLocaleUpperCase());
          handleRedirect(moduleCode);
          dispatch(
            handleSelectModule({
              moduleCode: moduleCode,
              moduleId: id,
            })
          );
        },
        onCancel() {},
        okText: "Đồng ý",
        okButtonProps: { type: "primary" },
        cancelText: "Hủy",
      });
    } else {
      //block navigate if in screen Lap phuong an
      if (
        !phuongAnCapDien &&
        !thongTinCoBan &&
        !thongTin &&
        !congTo &&
        !thiCong &&
        !saveData
      ) {
        dispatch(closeModal());
        localStorage.setItem("title-menu", shortName.toLocaleUpperCase());
        handleRedirect(moduleCode);
        dispatch(
          handleSelectModule({
            moduleCode: moduleCode,
            moduleId: id,
          })
        );
      } else {
        ModalWarningChange(
          () => {
            // dispatch(rangeOfChange(false));
            dispatch(rangeOfChange(false));
            if (phuongAnCapDien) dispatch(notSavedPhuongAnCapDien(false));
            if (thongTinCoBan) dispatch(notSavedThongTinCoBan(false));
            dispatch(setCanShowDialogLeavingPACD(false));

            if (phanQuyenDiemDo) dispatch(notSavedPhanQuyenDiemDo(false));
            dispatch(setCanShowDialogLeavingTT(false));

            if (saveData) dispatch(getSaveDataPopup(false));
            dispatch(setCanShowDialogLeaving(false));
            localStorage.setItem("title-menu", shortName.toLocaleUpperCase());
            handleRedirect(moduleCode);
            dispatch(
              handleSelectModule({
                moduleCode: moduleCode,
                moduleId: id,
              })
            );
          },
          () => {}
        );
      }
    }
  }, [
    dongBoImage,
    dispatch,
    phuongAnCapDien,
    thongTinCoBan,
    thongTin,
    congTo,
    thiCong,
    saveData,
    shortName,
    handleRedirect,
    moduleCode,
    id,
    phanQuyenDiemDo,
  ]);

  const renderItem = useCallback(() => {
    return (
      <Container onClick={() => selectSystem()}>
        <img src={renderIcon()} alt="icon" />
        <div className="title">
          <div className={`color_module_${moduleCode}`}>{shortName}</div>
        </div>
      </Container>
    );
  }, [moduleCode, shortName, renderIcon, selectSystem]);
  return <div>{renderItem()}</div>;
};
const Container = styled.div`
  display: flex;
  padding: 15px;
  align-items: center;
  background: #fdfdfd;
  box-shadow: 0px 8px 20px -8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-bottom: 8px;
  :hover {
    cursor: pointer;
    background: #b3bdf1;
  }
  .title {
    margin-left: 10px;
    > div {
      font-weight: bold;
      font-size: 16px;
    }
  }
`;

export default SystemItem;
