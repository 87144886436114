import { Button, Modal, Table } from "antd";

export default function TiepNhanModal(props) {
  const { visible, closeModal, columns, data } = props;
  return (
    <Modal
      title="Chi tiết kết quả giải quyết yêu cầu cấp điện"
      visible={visible}
      width={"50%"}
      // style={{ top: "1%" }}
      onCancel={closeModal}
      className="modal"
      footer={[
        <div style={{ textAlign: "center" }} key="foll">
          <Button key="cancel" onClick={closeModal}>
            Đóng lại
          </Button>
        </div>,
      ]}
    >
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey={(obj) => obj.tenYeuCau}
        scroll={{ y: 400 }}
      />
    </Modal>
  );
}
