import LoadingComponent from "@components/Loading";
import TableComponent from "@components/TableComponent";
import { systemConfigSelector } from "@state/system-config";
import { useCustomDebounce } from "@utils/request";
import { Table } from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import { FilterTab3 } from "../common/list-bo-loc-tabs/list-bo-loc-tab3";
import { getDanhSachDataNgungSD } from "../services";

const TableDiemDoTab3 = (props) => {
  const { columns, dataSoLuong, dataDanhSach } = props;
  const [loading, setLoading] = useState(false);
  const [resultStop, setResultStop] = useState();
  const [dataUseStop, setDataUseStop] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [indexPage, setIndexPage] = useState();
  const modalData = useSelector(systemConfigSelector);
  const [filterConditions, setFilterConditions] = useState("");

  const debouncePageSize = useCustomDebounce(pageSize);
  const debounceSearch = useCustomDebounce(filterConditions);
  const handleSearch = useCallback(
    (resultStop) => {
      setResultStop(resultStop);
      getDanhSachDataNgungSD(
        debounceSearch,
        setLoading,
        setDataUseStop,
        setIndexPage,
        pageSize
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalData]
  );
  useEffect(() => {
    if (debounceSearch === "") {
      handleSearch();
    } else {
      getDanhSachDataNgungSD(debounceSearch, setLoading, setDataUseStop);
    }
  }, [debounceSearch, handleSearch]);
  useEffect(() => {
    getDanhSachDataNgungSD(
      debounceSearch,
      setLoading,
      setDataUseStop,
      setIndexPage,
      pageSize
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);
  return (
    <Fragment>
      <FilterTab3
        handleSearch={handleSearch}
        resultStop={resultStop}
        setFilterConditions={setFilterConditions}
        dataSoLuong={dataSoLuong}
        dataDanhSach={dataDanhSach}
      ></FilterTab3>
      <LoadingComponent loading={loading}>
        <div
          className="loading-scrollbar"
          style={{ paddingTop: 10, width: "100%" }}
        >
          <Scrollbars
            style={{ height: "70vh" }}
            autoHide
            onScrollFrame={(e) => {
              if (e.top * 100 > 99 && pageSize < indexPage.totalCount) {
                setPageSize(debouncePageSize + 20);
              }
            }}
          >
            <div className="f" style={{ width: 1800 }}>
              <TableComponent
                columns={columns}
                dataSource={dataUseStop}
                totalCount={indexPage}
                rowKey="id"
                scroll={{ x: 600 }}
                pagination={false}
                loading={loading}
                bordered
              />
            </div>
          </Scrollbars>
        </div>
      </LoadingComponent>
    </Fragment>
  );
};
export default TableDiemDoTab3;
