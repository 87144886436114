/* eslint-disable no-unused-vars */
import React, { memo, useState } from "react";
import ConfirmModal from "./modal-confirm";

function Index({
  visible,
  handleCloseModal,
  content,
  title,
  handleSubmit
}) {
  const [loading, setLoading] = useState(false);

  return (
    <ConfirmModal
      loading={loading}
      visible={visible}
      closeModal={handleCloseModal}
      handleSubmit={handleSubmit}
      content={content}
      title={title}
    />
  );
}

export default memo(Index);
