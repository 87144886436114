import React from "react";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
export default function LoadingComponent(props) {
  const { loading, children } = props;
  const { t } = useTranslation();
  return (
    <SpinStyled tip={t("dang_tai")} size="large" spinning={loading}>
      {children}
    </SpinStyled>
  );
}

const SpinStyled = styled(Spin)`
  .ant-spin-text {
    font-size: 16px;
  }
`;
