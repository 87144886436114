import TiepNhanModal from "./modal";

export default function Index(props) {
  const { visible, closeModal, columns, data } = props;
  return (
    <TiepNhanModal
      visible={visible}
      closeModal={closeModal}
      columns={columns}
      data={data}
    />
  );
}
