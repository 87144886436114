import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APITaiChinhCapDien = {
  XAC_NHAN_TAI_CHINH: `${BASE_API_URL_CAP_DIEN}/ho-so/xuat-vat-tu`,
  XAC_NHAN_THU_TIEN: `${BASE_API_URL_CAP_DIEN}/tai-chinh/xac-nhan-thu-tien`,
  XAC_NHAN_THU_TIEN_TU_NVKS: `${BASE_API_URL_CAP_DIEN}/tai-chinh/xac-nhan-thu-tien-tu-nvks`,
  GET_TONG_CHI_PHI: `${BASE_API_URL_CAP_DIEN}/tai-chinh/tinh-tong-chi-phi`,
  DONG_BO_THU_TIEN: `${BASE_API_URL_CAP_DIEN}/tai-chinh/dong-bo-thu-tien`,
  KHOI_PHUC_TAI_CHINH_CAP_DIEN: `${BASE_API_URL_CAP_DIEN}/tai-chinh/khoi-phuc-tai-chinh`,
};
