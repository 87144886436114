import React from "react";
import { Dropdown, Menu, Space } from "antd";
import styled from "styled-components";

const nhanVienStatusStyle = (nhanvien) => {
  return nhanvien.trangThai === "Online"
    ? {
        display: "inline-block",
        border: "2px solid green",
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        marginLeft: "5px",
      }
    : {
        display: "inline-block",
        border: "2px solid red",
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        marginLeft: "5px",
      };
};
export const XacDinhNhanVienOnline = ({ listNhanVien }) => {
  const listNhanVienOnline = listNhanVien?.filter(
    (nhanVien) => nhanVien.trangThai === "Online"
  );
  const listNhanVienOffline = listNhanVien?.filter(
    (nhanVien) => nhanVien.trangThai === "Offline"
  );

  const menu = (
    <Menu mode="inline" style={{ width: "100%" }}>
      {listNhanVien?.length &&
        listNhanVien.map((nhanvien, id) => (
          <Menu.Item key={id} eventKey={`${id + 1}`}>
            <div className="row diem-do">
              <span>{nhanvien.tenNhanVien}</span>
              <div style={nhanVienStatusStyle(nhanvien)}></div>
            </div>
          </Menu.Item>
        ))}
    </Menu>
  );
  const menuNhanVienOnline = (
    <Menu mode="inline" style={{ width: "100%" }}>
      {listNhanVienOnline?.length &&
        listNhanVienOnline.map((nhanvienOnline, id) => (
          <Menu.Item key={id} eventKey={`${id + 1}`}>
            <div className="row diem-do">
              <span>{nhanvienOnline.tenNhanVien}</span>
              <div style={nhanVienStatusStyle(nhanvienOnline)}></div>
            </div>
          </Menu.Item>
        ))}
    </Menu>
  );
  const menuNhanVienOffline = (
    <Menu mode="inline" style={{ width: "100%" }}>
      {listNhanVienOffline?.length &&
        listNhanVienOffline.map((nhanvienOffline, id) => (
          <Menu.Item key={id} eventKey={`${id + 1}`}>
            <div className="row diem-do">
              <span>{nhanvienOffline.tenNhanVien}</span>
              <div style={nhanVienStatusStyle(nhanvienOffline)}></div>
            </div>
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Styled>
      {/* Tất cả nhân viên */}
      <Dropdown
        disabled={listNhanVien?.length === 0 ? true : false}
        overlay={menu}
        placement="bottomLeft"
        // arrow
        overlayStyle={
          listNhanVien?.length < 15
            ? { height: "auto", overflow: "auto" }
            : { height: "450px", overflow: "auto" }
        }
      >
        <Space align="center">
          <span>Tất cả</span>
        </Space>
      </Dropdown>

      {/* Nhân viên on */}
      <Dropdown
        disabled={listNhanVienOnline?.length === 0 ? true : false}
        overlay={menuNhanVienOnline}
        overlayStyle={
          listNhanVienOnline?.length < 15
            ? { height: "auto", overflow: "auto" }
            : { height: "450px", overflow: "auto" }
        }
        placement="bottomLeft"
      >
        <Space align="center">
          <span style={{ color: "green" }}>
            {listNhanVienOnline?.length ? listNhanVienOnline?.length : "0"}{" "}
            ONLINE
          </span>
        </Space>
      </Dropdown>

      {/* Nhân viên off */}
      <Dropdown
        disabled={listNhanVienOffline?.length === 0 ? true : false}
        overlay={menuNhanVienOffline}
        overlayStyle={
          listNhanVienOffline?.length < 15
            ? { height: "auto", overflow: "auto" }
            : { height: "450px", overflow: "auto" }
        }
        placement="bottomRight"
      >
        <Space align="center">
          <span style={{ color: "red" }}>
            {listNhanVienOffline?.length ? listNhanVienOffline?.length : "0"}{" "}
            OFFLINE
          </span>
        </Space>
      </Dropdown>
    </Styled>
  );
};

export const Styled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
`;
