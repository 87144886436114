import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  formatDateWithTimezone,
  parseParams,
  removeAccents,
} from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import { useCallback, useEffect, useState } from "react";
import { alertMessage422 } from "../service";

export const selectTPPT = [
  {
    label: "Nông lâm nghiệp thủy sản",
    value: 1,
  },
  {
    label: "Công nghiệp xây dựng",
    value: 2,
  },
  {
    label: "Thương nghiệp khách sạn nhà hàng",
    value: 3,
  },
  {
    label: "Quản lý tiêu dùng",
    value: 4,
  },
  {
    label: "Hoạt động khác",
    value: 5,
  },
];

export const selectBCHTDX = [
  {
    label: "1. Tổng hợp điểm đo mất kết nối",
    value: Endpoint.BCHTDX_TONG_HOP_DD_MAT_KET_NOI,
  },
  {
    label:
      "2. Danh sách điểm đo thanh lý trạm và điểm đo thay thế tại công ty điện lực",
    value: Endpoint.BCHTDX_DS_DD_THANH_LY_VA_DD_THAY_THE,
  },
  {
    label: "3. Danh sách các điểm đo có dữ liệu phục vụ lập hóa đơn",
    value: Endpoint.BCHTDX_DS_CAC_DIEM_DO_CO_DL_PHUC_VU_LAP_HOA_DON,
  },
  {
    label:
      "4. Danh sách số lượng khách hàng lớn đã lắp đo xa theo 5 thành phần phụ tải",
    value: Endpoint.BCHTDX_SL_KH_LON_DA_LAP_DO_XA,
  },
  {
    label: "5. Báo cáo số lượng, chủng loại công tơ theo trạm",
    value: Endpoint.BCHTDX_BCSL_CHUNG_LOAI_CONG_TO_THEO_TRAM,
  },
  {
    label:
      "6. Báo cáo số lượng, chủng loại công tơ đến hạn thay định kỳ theo trạm",
    value: Endpoint.BCHTDX_BCSL_CHUNG_LOAI_CONG_TO_DEN_HAN_THAY_DINH_KY,
  },
];

export const SelectionDanhSachMaTram = (props) => {
  const {
    url,
    placeholder = "Chọn mã trạm",
    form,
    formKey,
    disabled,
    mode,
    setValue,
    handleDeselect,
    notClear,
    defaultValue,
  } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(typeof url === "string" ? [] : url);
  const getData = useCallback(() => {
    authGetData({
      url,
      setLoading,
      onSuccess: (res) => {
        if (res.length > 0) setData(res);
        else setData(res.data);
      },
    });
  }, [url]);

  useEffect(() => {
    if (url && typeof url === "string") {
      getData();
    }
  }, [getData, url]);
  const handleChange = useCallback(
    (value) => {
      if (form) {
        form.setFieldsValue({
          [formKey]: value,
        });
      }
      if (setValue) {
        setValue(value);
      }
    },
    [form, formKey, setValue]
  );
  return (
    <Select
      defaultValue={defaultValue}
      value={form && form.getFieldValue(formKey)}
      placeholder={placeholder}
      showSearch
      mode={mode}
      allowClear={notClear ? false : true}
      loading={loading}
      onChange={handleChange}
      onDeselect={handleDeselect}
      disabled={disabled}
      style={{ width: "100%" }}
      filterOption={(input, option) => {
        if (option && option.children) {
          return (
            removeAccents(option.children)
              .toLowerCase()
              .indexOf(removeAccents(input).toLowerCase()) >= 0
          );
        }
      }}
    >
      {data && data.length
        ? data.map((item, idx) => (
            <Option
              key={idx}
              value={item.MA_TRAM ? item.MA_TRAM.toString() : item.MA_TRAM}
            >
              {item.TEN_TRAM}
            </Option>
          ))
        : undefined}
    </Select>
  );
};

export const exportData = (data, setLoading) => {
  const thoiGian = formatDateWithTimezone(data.ThoiGian);
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      LoaiCongTo: data.LoaiCongTo,
      KiemDinh: data.KiemDinh,
      KhoThietBi: data.KhoThietBi,
      SoPha: data.SoPha,
      TinhTrang: data.TinhTrang,
      SoNgay: data.SoNgay,
      LoaiKhachHang: data.LoaiKhachHang,
      LuyKe: data.LuyKe,
      ThanhPhanPhuTai: data.ThanhPhanPhuTai,
      NhaCungCap: data.NhaCungCap,
      ThoiGian: thoiGian,
      MaTram: data.MaTram,
    })
  );
  authGetData({
    url: `${data.reportType}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};

export const handleDownload = (data, setLoading) => {
  const thoiGian = formatDateWithTimezone(data.ThoiGian);
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      LoaiCongTo: data.LoaiCongTo,
      KiemDinh: data.KiemDinh,
      KhoThietBi: data.KhoThietBi,
      SoPha: data.SoPha,
      TinhTrang: data.TinhTrang,
      SoNgay: data.SoNgay,
      LoaiKhachHang: data.LoaiKhachHang,
      LuyKe: data.LuyKe,
      ThanhPhanPhuTai: data.ThanhPhanPhuTai,
      NhaCungCap: data.NhaCungCap,
      ThoiGian: thoiGian,
      MaTram: data.MaTram,
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const thoiGian = formatDateWithTimezone(data.ThoiGian);
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      LoaiCongTo: data.LoaiCongTo,
      KiemDinh: data.KiemDinh,
      KhoThietBi: data.KhoThietBi,
      SoPha: data.SoPha,
      TinhTrang: data.TinhTrang,
      SoNgay: data.SoNgay,
      LoaiKhachHang: data.LoaiKhachHang,
      LuyKe: data.LuyKe,
      ThanhPhanPhuTai: data.ThanhPhanPhuTai,
      NhaCungCap: data.NhaCungCap,
      ThoiGian: thoiGian,
      MaTram: data.MaTram,
    })
  );
  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
