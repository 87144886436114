import { Table } from "antd";

export default function NguoiThanTable(props) {
  const { dataSource } = props;
  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: 100,
    },
    {
      title: "Tên",
      dataIndex: "ten",
      key: "ten",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: 250,
    },
    {
      title: "Mối quan hệ",
      dataIndex: "moiQuanHe",
      key: "moiQuanHe",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: 200,
    },

    {
      title: "Số điện thoại",
      dataIndex: "soDienThoai",
      key: "soDienThoai",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: 150,
    },
    {
      title: "SĐT EPoint",
      dataIndex: "sdtEpoint",
      key: "sdtEpoint",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: 150,
    },
    {
      title: "SĐT Zalo",
      dataIndex: "sdtZalo",
      key: "sdtZalo",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: 150,
    },
    {
      title: "SĐT Viber",
      dataIndex: "sdtViber",
      key: "sdtViber",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: 200,
    },
    {
      title: "Facebook",
      dataIndex: "facebook",
      key: "facebook",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: 200,
    },
    {
      title: "Khác",
      dataIndex: "khac",
      key: "khac",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: 150,
    },
    {
      title: "Ghi chú",
      dataIndex: "ghiChu",
      key: "ghiChu",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: 250,
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      scroll={{ y: 500, x: 2200 }}
    />
  );
}
