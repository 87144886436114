import { Endpoint } from "@utils/endpoint";
import { authGetData, authGetMultiplesRequest } from "@utils/request";
import { buildQueryString } from "@utils/function";

export const handleListBaoCao = (
  filterConditions,
  setLoading,
  setData,
  setTotalCount,
  setIsCompany
) => {
  authGetData({
    url: `${Endpoint.GET_LIST_SO_LUONG_VAT_TU}?${buildQueryString(
      filterConditions
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setData(res.data.datas);
        setIsCompany(res.data.isTongCongTy);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};

export const handleMultiBoLoc = (
  setLoading,
  setLoaiYeuCau,
  setSoPha,
  setDonVi
) => {
  const endpoints = [
    `${Endpoint.COMMON_LIST_LOAI_YEU_CAU}`,
    `${Endpoint.COMMON_LIST_SO_PHA}`,
    `${Endpoint.LIST_DON_VI}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setLoaiYeuCau(res[0].data.data);
      setSoPha(res[1].data.data);
      setDonVi(res[2].data.data);
    },
  });
};
