import React, { Fragment } from "react";
import styled from "styled-components";

export default function DanhSachDiemDoTabs(props) {
  const { dataSoLuong, dataTable } = props;
  return (
    <Fragment>
      <Infomations>
        <div
          style={{ padding: "4px 0 4px 20px" }}
          className="infomation-diem-do"
        >
          <span>Danh sách điểm đo:</span>
          <span
            className="info-middle"
            style={{ color: "red", margin: "0 10px 0 10px" }}
          >
            {dataSoLuong}
            {dataTable && dataTable?.totalCount > 0 ? (
              <span>{dataTable?.totalCount}</span>
            ) : null}
          </span>
          điểm đo
        </div>
      </Infomations>
    </Fragment>
  );
}
const Infomations = styled.div`
  .infomation-diem-do {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .info-middle {
    font-size: 16px;
  }
`;
