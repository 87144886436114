import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useEffect,
  useMemo
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Filter from "@components/Filter/Filter";
import { TableStyled, Container } from "./css-styled";
import { Tag, Form, Tooltip } from "antd";
import { authSelector } from "@state/auth/reducer";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import {
  handleListData,
  submitPKDXacNhan,
  getDataCauHinh,
  submitPKDKyNhayBB
} from "./services";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilter } from "../../common/list-bo-loc";
import CheckPermission from "@components/CheckPermission";
import { ap_gia } from "@permissions/e_apgia";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE, ENUM_MUCDICH_SDD, AP_GIA_TRANG_THAI } from "@utils/constants";
import KyDuyetModal from "./modal-ky-duyet";
import TraLaiModal from "./modal-tra-lai";
import TableComponent from "@components/TableComponent";
import ButtonComponent from "@components/Button";
import { openModal } from "@state/system-config/reducer";
import ChiTietDiemDo from "../../common/chi-tiet-diem-do";
// import moment from "moment";
import { InfoCircleOutlined, FilePdfOutlined, FileOutlined } from "@ant-design/icons";
// import ThemChuKy from "@components/KySo/modal";

export default function Index() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { user } = useSelector(authSelector);
  const [dataYCPKD, setDataYCPKD] = useState([]);
  const [dataCauHinh, setDataCauHinh] = useState();
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [visibleKy, setVisibleKy] = useState(false);
  const [visibleTraLai, setVisibleTraLai] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const location = useLocation();
  const [biTraLai, setBiTraLai] = useState(false);
  // const [visible, setVisible] = useState(false);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    // ThangKiemTra: moment().format(FORMAT_MONTH_ENGLISH),
    // LoaiKhachHang: ENUM_MUCDICH_SDD.SINHHOAT,
    ...parseParams(location.search),
    TrangThai: AP_GIA_TRANG_THAI.BPAG_DA_XAC_NHAN,
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const onChangePagination = (paging, _filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setSelectedRowKeys([]);
  };
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
      BiTraLai: biTraLai ? biTraLai : null,
      Tram: values.Tram
        ? Array.isArray(values.Tram) && values.Tram.length > 0
          ? values.Tram.join()
          : values.Tram
        : [],
    }));
  }, [biTraLai]);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      TrangThai: AP_GIA_TRANG_THAI.BPAG_DA_XAC_NHAN,
      // ThangKiemTra: moment().format(FORMAT_MONTH_ENGLISH),
      // LoaiKhachHang: ENUM_MUCDICH_SDD.SINHHOAT,
    });
  }, [user.unitId]);
  //search list
  const getListData = useCallback(() => {
    handleListData(location,
      filterConditions,
      setLoading,
      setDataYCPKD,
      setTotalCount,
      setSelectedRowKeys
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getListData();
  }, [getListData, user]);

  useEffect(() => {
    getDataCauHinh(setLoading, setDataCauHinh);
  }, []);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListData();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListData, setFetchDataTable]);

  const checkTraLai = (e) => {
    setBiTraLai(e.target.checked);
  };

  const detailDiemDo = (record) => {
    return dispatch(
      openModal({
        content: (
          <ChiTietDiemDo
            detailRecord={record}
          />
        ),
        size: "large",
        title: `Chi tiết điểm đo - ${record.maDiemDo}`
      })
    );
  };

  const detailDD = (record) => {
    return {
      onDoubleClick: () => {
        detailDiemDo(record)
      },
    };
  };

  const columns = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => detailDiemDo(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
      fixed: "left"
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      key: "maKhachHang",
      render: (text) => (
        <span>{text}</span>
      ),
      width: "10%",
      sorter: true,
      fixed: "left"
    },
    {
      title: "Tên KH",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => <span>{text}</span>,
      width: "12%",
      sorter: true,
    },
    {
      title: "Điện thoại",
      dataIndex: "soDienThoai",
      key: "soDienThoai",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: 'Trước kiểm tra',
      children: [
        {
          title: 'Ngày áp dụng',
          dataIndex: "ngayApDung",
          key: "ngayApDung",
          render: (text) => (
            <div className="table-text-center">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
        {
          title: 'Số hộ',
          dataIndex: "soHo",
          key: "soHo",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
        },
        {
          title: "Chuỗi giá",
          dataIndex: "chuoiGia",
          key: "chuoiGia",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        }
      ],
    },
    {
      title: 'Sau kiểm tra',
      children: [
        {
          title: 'Ngày áp dụng',
          dataIndex: "ngayApDungMoi",
          key: "ngayApDungMoi",
          render: (text) => (
            <div className="table-text-center">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
        {
          title: 'Số hộ',
          dataIndex: "soHoMoi",
          key: "soHoMoi",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
        },
        {
          title: "Chuỗi giá",
          dataIndex: "chuoiGiaMoi",
          key: "chuoiGiaMoi",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        }
      ],
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text, record) => (
        <>
          {
            record.trangThai?.toString() === AP_GIA_TRANG_THAI.BPAG_DA_XAC_NHAN ?
              <Tag style={{ display: "block", textAlign: "center" }} color={"#26c0e9"}>{"Chưa ký"}</Tag> :
              <Tag style={{ display: "block", textAlign: "center" }} color={"#faad14"}>{"Đã ký"}</Tag>
          }
          {record.biTraLai ?
            <div
              className="table-text-center"
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: "#FF5247",
                cursor: "pointer",
              }}
            >
              <Tooltip title={record.lyDoTraLai}>
                <InfoCircleOutlined /> Bị trả lại
              </Tooltip>
            </div> : null
          }

        </>
      ),
      width: "8%",
    },
    // {
    //   title: "Tháng kiểm tra",
    //   dataIndex: "thangKiemTra",
    //   key: "thangKiemTra",
    //   render: (text) => (
    //     <div className="table-text-center">
    //       <span>{text}</span>
    //     </div>
    //   ),
    //   width: "7%",
    // },
    {
      title: "Loại khách hàng",
      dataIndex: "loaiKhachHang",
      key: "loaiKhachHang",
      render: (text, record) => (
        record.loaiKhachHang?.toString() === ENUM_MUCDICH_SDD.NGOAISINHHOAT ?
          <span>{"Ngoài sinh hoạt"}</span> :
          <span>{"Sinh hoạt"}</span>
      ),
      width: "8%",
    },
    {
      title: "Trạm",
      dataIndex: "tram",
      key: "tram",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: "Sổ GCS",
      dataIndex: "soGCS",
      key: "soGCS",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => <span>{text}</span>,
      width: "16%",
    },
    {
      title: "Mã bảng kê",
      dataIndex: "maBangKe",
      key: "maBangKe",
      render: (text) => <span>{text}</span>,
      width: "9%",
    },
    {
      title: "Tác vụ",
      dataIndex: "",
      key: "tacVu",
      render: (text, record) => (
        <div className="table-text-center">
          {record.pathFileBienBan ?
            <CheckPermission permissionCode={ap_gia.e32xb}>
              <Tooltip title="Xem biên bản">
                <a
                  href={record.pathFileBienBan}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FilePdfOutlined
                    style={{ fontSize: "22px" }}
                  />
                </a>
              </Tooltip>
            </CheckPermission>
            : null
          }
          {record.pathFileBienBanGiay ?
            <CheckPermission permissionCode={ap_gia.e32xb}>
              <Tooltip title="Xem BBAG giấy">
                <a
                  href={record.pathFileBienBanGiay}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FileOutlined
                    style={{ fontSize: "22px", marginLeft: '10px' }}
                  />
                </a>
              </Tooltip>
            </CheckPermission> : null}
        </div>
      ),
      width: "4%",
      fixed: "right"
    },
  ];

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: "3%",
    fixed: "left",
    getCheckboxProps: (record) => ({
      disabled:
        record.trangThai?.toString() === AP_GIA_TRANG_THAI.PKD_DA_XAC_NHAN
    }),
  };
  // open modal ky duyet
  const handleOpenModalKy = useCallback(() => {
    setVisibleKy(true)
  }, []);
  // open modal tra lai
  const handleOpenModaTraLai = useCallback(() => {
    setVisibleTraLai(true)
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const kySo = () => {
    submitPKDKyNhayBB(
      setLoading,
      setFetchDataTable,
      selectedRowKeys,
      setSelectedRowKeys,
      handleCloseModalKy
    );
  };
  // const kySo = () => {
  //   submitPKDKySo(
  //     setLoading,
  //     setFetchDataTable,
  //     selectedRowKeys,
  //     setSelectedRowKeys,
  //     handleCloseModalKy
  //   );
  // };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFinish = () => {
    submitPKDXacNhan(
      setLoading,
      setFetchDataTable,
      selectedRowKeys,
      setSelectedRowKeys,
      handleCloseModalKy
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const openModalKySo = () => {
    kySo();
  };

  // const handleCloseModal = () => {
  //   setVisible(false);
  // };
  //popup tạo chữ ký
  // const showModalChuKy = useMemo(() => {
  //   return (
  //     visible && (
  //       <ThemChuKy
  //         visible={visible}
  //         closeModal={handleCloseModal}
  //         handleKySo={kySo}
  //       />
  //     )
  //   );
  // }, [kySo, visible]);



  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCloseModalKy = () => {
    setVisibleKy(false);
    setLoading(false);
  };

  const handleCloseModalTraLai = () => {
    setVisibleTraLai(false);
    setLoading(false);
  };
  const showModalKy = useMemo(
    () =>
      visibleKy && (
        <KyDuyetModal
          form={form}
          visible={visibleKy}
          onFinish={onFinish}
          handleCloseModalKy={handleCloseModalKy}
          openModalKySo={openModalKySo}
          loading={loading}
          dataCauHinh={dataCauHinh}
        />
      ),
    [dataCauHinh, form, loading, onFinish, openModalKySo, visibleKy]
  );

  const showModalTraLai = useMemo(
    () =>
      visibleTraLai && (
        <TraLaiModal
          form={form}
          visible={visibleTraLai}
          handleCloseModalTraLai={handleCloseModalTraLai}
          setFetchDataTable={setFetchDataTable}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      ),
    [form, selectedRowKeys, visibleTraLai]
  );

  const genExtra = useCallback(
    () => (
      <div>
        <CheckPermission permissionCode={ap_gia.e32k}>
          <ButtonComponent
            type="primary"
            style={{ marginRight: "10px" }}
            onClick={() => handleOpenModalKy()}
            disabled={selectedRowKeys.length === 0}
          >
            Ký duyệt
          </ButtonComponent>
        </CheckPermission>
        <CheckPermission permissionCode={ap_gia.e32t}>
          <ButtonComponent
            type="primary"
            style={{ marginRight: "10px" }}
            onClick={() => handleOpenModaTraLai()}
            disabled={selectedRowKeys.length === 0}
          >
            Trả lại
          </ButtonComponent>
        </CheckPermission>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRowKeys]
  );
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          checkTraLai={checkTraLai}
          screen={"PKDD"}
        />
      </Filter>
      <Container>
        <TableStyled>
          <TableComponent
            header={"Duyệt yêu cầu"}
            handleSearch={handleSearch}
            dataSource={dataYCPKD}
            columns={customColumn(columns, filterConditions)}
            totalData={totalCount}
            renderExtraAction={() => genExtra()}
            loading={loading}
            scrollX={3000}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            rowSelection={rowSelection}
            rowKey="id"
            onRow={detailDD}
          />
        </TableStyled>
      </Container>
      {showModalKy}
      {showModalTraLai}
    </Fragment>
  );
}
