import {
  ENUM_LOAI_PHIEU,
  ENUM_PHIEU_VAT_TU,
  NAVIGATE_TREOTHAO,
  NAVIGATE_TREOTHAO_DOITRUONG,
  NAVIGATE_TREOTHAO_YCCANTRAVT,
  TYPE_PHIEUTRA_CHUCNANG,
} from "@utils/constants";
import { Fragment, useState, useEffect } from "react";
import PhieuXuatVTTable from "../../common-ver1/phieu-vat-tu";
import { treo_thao } from "@permissions/b_treothao";
import { Endpoint } from "@utils/endpoint";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "@state/system-config/reducer";
import { authSelector } from "@state/auth";
import TaoSuaPhieuTra from "../../common-ver1/thao-tac-phieu-tra/tao-sua-phieu";
import CheckPermission from "@components/CheckPermission";
import { alertMessage } from "@utils/function";

export default function Index() {
  const localFilter = localStorage.getItem("createFilterPhieu");
  const [fetchList, setFetchList] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(authSelector);
  const [filterRedirect, setFilterRedirect] = useState({});
  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    if (localStorage.getItem("isRedirect")) setIsOpen(true);
  }, []);

  //redirect tới tạo phiếu
  const handleClickRedirect = (permissionCode) => {
    if (
      permissionCode &&
      user &&
      user.permission &&
      user.permission.length &&
      !user.permission.find((value) => value.includes(permissionCode))
    ) {
      alertMessage(
        "error",
        "Thông báo!",
        "Bạn không có quyền tạo phiếu trả vật tư!"
      );
    } else {
      localStorage.setItem("createFilterPhieu", JSON.stringify(filterRedirect));
      navigate(
        NAVIGATE_TREOTHAO +
          NAVIGATE_TREOTHAO_DOITRUONG +
          NAVIGATE_TREOTHAO_YCCANTRAVT
      );
    }
  };

  //show popup tạo
  const renderButtonTao = () => (
    <CheckPermission permissionCode={treo_thao.b26t}>
      <Button
        type="primary"
        className="mr-5px"
        id="create-phieu"
        onClick={() => handleClickRedirect(treo_thao.b22t)}
      >
        Tạo phiếu
      </Button>
    </CheckPermission>
  );

  //show popup sửa
  const renderPopupSuaPhieu = (row) => {
    return (
      <CheckPermission permissionCode={treo_thao.b26s}>
        {dispatch(
          openModal({
            content: (
              <TaoSuaPhieuTra
                data={[row]}
                type={TYPE_PHIEUTRA_CHUCNANG.THAYDOI}
                selectRowKeys={[row.id]}
                typeLoaiPhieu={ENUM_LOAI_PHIEU.PHIEU_TRAVT}
                typeTreoThao={row.loaiTreoThao}
                typeYeuCau={row.loaiYeuCau}
                dispatch={dispatch}
                nguoiTao={row.nguoiTao}
                doiId={row.doiId}
                setFetchList={setFetchList}
              />
            ),
            size: "large",
            title: "Sửa phiếu trả vật tư",
          })
        )}
      </CheckPermission>
    );
  };

  return (
    <Fragment>
      <PhieuXuatVTTable
        url={Endpoint.LIST_PHIEU_VAT_TU}
        funcId={ENUM_PHIEU_VAT_TU.DTPHIEUTRAVT}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={false}
        rowSelection={rowSelection}
        selectedRowKeys={selectedRowKeys}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        permissionCodeTao={treo_thao.b26t}
        permissionCodeSua={treo_thao.b26s}
        permissionCodeXoa={treo_thao.b26x}
        renderButtonTao={renderButtonTao}
        typeTao={TYPE_PHIEUTRA_CHUCNANG.TAOMOI}
        typeSua={TYPE_PHIEUTRA_CHUCNANG.THAYDOI}
        typeDuyet={TYPE_PHIEUTRA_CHUCNANG.TRALAI_DUYET}
        permissionCodeXemPhieu={treo_thao.b26v}
        urlRemove={Endpoint.REMOVE_PHIEU_TRA_VAT_TU}
        setFilterRedirect={setFilterRedirect}
        renderPopupSuaPhieu={renderPopupSuaPhieu}
        setSelectedRowKeys={setSelectedRowKeys}
        localFilter={localFilter ? JSON.parse(localFilter) : null}
      />
    </Fragment>
  );
}
