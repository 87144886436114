import { Col, Divider } from "antd";
import { Fragment } from "react";

export default function ThongTinChung(props) {
  const { detail, formThao, form } = props;
  return (
    <Fragment
    // gutter={24}
    // style={{
    //   borderTop: "1px solid #000",
    //   borderBottom: "1px solid #000",
    //   paddingTop: "8px",
    // }}
    >
      <Divider style={{ marginTop: 0 }} />
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Mã công tơ</h6>
        <h5 className="fs-16px font-bold" style={{ color: "#0000FF" }}>
          {detail?.maCongTo}
        </h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Số công tơ</h6>
        <h5 className="fs-16px font-bold" style={{ color: "#0000FF" }}>
          {detail?.soCongTo}
        </h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Loại công tơ</h6>
        <h5 className="fs-16px font-bold" style={{ color: "#0000FF" }}>
          {detail?.loaiCongTo}
        </h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Nước SX</h6>
        <h5 className="fs-16px font-bold">{detail?.nuocSX}</h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">BCS</h6>
        <h5 className="fs-16px font-bold">{detail?.bcs}</h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Số pha</h6>
        <h5 className="fs-16px font-bold">{detail?.soPha}</h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Dòng điện</h6>
        <h5 className="fs-16px font-bold">{detail?.dongDien}</h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Điện áp</h6>
        <h5 className="fs-16px font-bold">{detail?.dienAp}</h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Ngày kiểm định</h6>
        <h5 className="fs-16px font-bold">{detail?.ngayKiemDinh}</h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Tem kiểm định</h6>
        <h5 className="fs-16px font-bold">{detail?.seryTemKiemDinh}</h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Mã tem kiểm định</h6>
        <h5 className="fs-16px font-bold">{detail?.maTemKiemDinh}</h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Số viên chì kiểm định</h6>
        <h5 className="fs-16px font-bold">{detail?.soChiKiemDinh}</h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Mã số chì hộp</h6>
        <h5 className="fs-16px font-bold">
          {!formThao && form.getFieldValue("maChiHop")
            ? form.getFieldValue("maChiHop")
            : detail?.maChiHop}
        </h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Số viên chì hộp</h6>
        <h5 className="fs-16px font-bold">
          {!formThao && form.getFieldValue("soVienChiHop")
            ? form.getFieldValue("soVienChiHop")
            : detail?.soVienChiHop}
        </h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Mã chì booc</h6>
        <h5 className="fs-16px font-bold">
          {!formThao && form.getFieldValue("maChiBOOC")
            ? form.getFieldValue("maChiBOOC")
            : detail?.maCBOOC}
        </h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Số viên chì booc</h6>
        <h5 className="fs-16px font-bold">
          {!formThao && form.getFieldValue("soVienChiBOOC")
            ? form.getFieldValue("soVienChiBOOC")
            : detail?.soVienCBOOC}
        </h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Mã kim chì tai</h6>
        <h5 className="fs-16px font-bold">{detail?.kimChiTai}</h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Số viên chì tai</h6>
        <h5 className="fs-16px font-bold">{detail?.soVienChiTai}</h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Ngày lập trình cuối cùng</h6>
        <h5 className="fs-16px font-bold">{detail?.thoiDiemLapTrinhCuoi}</h5>
      </Col>
      <Col span={24} md={6}>
        <h6 className="fs-14px c-t-label">Số lần lập trình</h6>
        <h5 className="fs-16px font-bold">{detail?.soLanLapTrinh}</h5>
      </Col>

      <Divider style={{ marginTop: 0 }} />
    </Fragment>
  );
}
