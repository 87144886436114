import { Col, Form, Row, Select, Button, DatePicker } from "antd";
import FormComponent from "@components/Form";
import { removeAccents } from "@utils/function";
import moment from "moment";

const { Option } = Select;

export default function FormBoLoc(props) {
  const {
    user,
    form,
    handleSearch,
    clear,
    donVi,
    loaiBangKe,
    handleChangeThangLV,
    locale,
  } = props;
  return (
    <Form
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      
      autoComplete="off"
    >
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="donViId" label="Đơn vị">
            <Select
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
              placeholder="Tất cả"
              // onChange={(value) => handleChange(value)}
              disabled={!user.isTongCongTy ? true : false}
            >
              {donVi && donVi.length > 0
                ? donVi.map((item, index) => (
                    <Option
                      key={index}
                      value={!user.isTongCongTy ? user.unitId : item.id}
                    >
                      {!user.isTongCongTy ? user.unitName : item.tenDonVi}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="loaiBangKeId" label="Loại bảng kê">
            <Select allowClear placeholder="Tất cả">
              {loaiBangKe && loaiBangKe.length > 0
                ? loaiBangKe.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.id}>
                          {item.tenLoaiBangKe}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={3}>
          <Form.Item name="thangBaoCao" label="Tháng">
            <DatePicker
              format="MM/YYYY"
              picker={"month"}
              locale={locale}
              form={form}
              allowClear={false}
              onChange={handleChangeThangLV}
              disabledDate={(current) => {
                const customDate = moment().format("YYYY-MM-DD");
                return current && current > moment(customDate, "YYYY-MM-DD");
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="trangThaiKy" label="Trạng thái">
            <Select
              allowClear
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
              placeholder="Tất cả"
            >
              {/* <Option key={null}>Tất cả</Option> */}
              <Option key="1">Chưa ký duyệt</Option>
              <Option key="2">Đã ký duyệt</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col
          span={24}
          md={5}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            marginTop: 5,
          }}
        >
          <Button className="mr-24px" onClick={clear}>
            Bỏ lọc
          </Button>

          <Button type="primary" htmlType="submit" form="filter-form">
            Tìm kiếm
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
