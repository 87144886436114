import LoadingComponent from "@components/Loading";
import { Modal, Table } from "antd";

export default function ViewPDFTreoThao(props) {
  const { loading, visible, closeModal, dataSource, columns } = props;
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title="Danh sách biên bản treo tháo"
        visible={visible}
        onCancel={closeModal}
        className="modal"
        footer={null}
        width={"60%"}
      >
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          scroll={{ y: 500 }}
          bordered={true}
          rowKey={(obj) => obj.stt + "-" + obj.tenBienBan}
        />
      </Modal>
    </LoadingComponent>
  );
}
