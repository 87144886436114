import { authSelector } from "@state/auth";
import { Form } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { handleMultiBoLoc } from "../services";
import FormBoLoc from "./form-bo-loc";

export default function ListFilter(props) {
  const { handleSearch, clearFilter, filterConditions, tooltip, checkCanhBao } =
    props;
  const { user } = useSelector(authSelector);
  const [loading, setLoading] = useState(false);
  const [loaiYeuCau, setLoaiYeuCau] = useState([]);
  const [mucDich, setMucDich] = useState([]);
  const [soPha, setSoPha] = useState([]);
  const [form] = Form.useForm();
  useEffect(() => {
    let object = {};
    if (
      !!filterConditions.maTrangThai &&
      !Array.isArray(filterConditions.maTrangThai)
    ) {
      object = {
        ...filterConditions,
        maTrangThai: filterConditions.maTrangThai.split(",").map(Number),
      };
    } else {
      if (Array.isArray(filterConditions.maTrangThai)) {
        object = {
          ...filterConditions,
          maTrangThai: filterConditions.maTrangThai,
        };
      } else {
        object = {
          ...filterConditions,
        };
      }
    }
    form.setFieldsValue(object);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  useEffect(() => {
    handleMultiBoLoc(setLoading, setLoaiYeuCau, setMucDich, setSoPha);
    // eslint-disable-next-line
  }, []);

  return (
    <FormBoLoc
      form={form}
      handleSearch={handleSearch}
      user={user}
      loading={loading}
      loaiYeuCau={loaiYeuCau}
      mucDich={mucDich}
      soPha={soPha}
      tooltip={tooltip}
      clear={clear}
      filterConditions={filterConditions}
      checkCanhBao={checkCanhBao}
    />
  );
}
