import { Endpoint } from "@utils/endpoint";
import { authSelector } from "@state/auth";
import { Col, Form, Row, Select, Button } from "antd";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import Selection from "@components/Select";
import FormComponent from "@components/Form";
const { Option } = Select;

export function ListFilterCauHinhKyBK(props) {
  const { handleSearch, clearFilter, filterConditions, loaiBK } = props;
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        DonViId: user.unitId.toString(),
      }}
    >
      <Row gutter={24} justify="space-between" align="middle">
        <Col span={24} md={6}>
          <Form.Item name="DonViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Selection
                url={Endpoint.GET_UNIT_LIST}
                form={form}
                formKey="DonViId"
                notClear={true}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="LoaiBangKeId" label="Loại bảng kê">
            <Select placeholder="Tất cả" allowClear>
              {loaiBK && loaiBK.length > 0
                ? loaiBK.map((item, index) => {
                  if (item.id !== null) {
                    return (
                      <Option key={index} value={item.id}>
                        {item.tenLoaiBangKe}
                      </Option>
                    );
                  }
                  return null;
                })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col
          span={24}
          md={12}
          style={{ textAlign: "right", paddingTop: "8px" }}
        >
          <Button className="mr-24px" onClick={clear}>
            Bỏ lọc
          </Button>
          <Button type="primary" htmlType="submit" form="filter-form">
            Tìm kiếm
          </Button>
        </Col>
      </Row>
    </FormComponent>
  );
}
