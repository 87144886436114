import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import ListBoLoc from "./list-bo-loc";
import { Style } from "@modules/dang-ky-thanh-toan/css-styled";
import { Button, Col, Form, Row } from "antd";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import moment from "moment";
import {
  buildQueryString,
  formatTime,
  handlePagination,
  parseParams,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getDanhSachNoDong, postDongBo } from "./services";
import DongBoCMISModal from "./modal/dong-bo-cmis-modal";
function DanhSachHoaDonDongCatDien() {
  const [form] = Form.useForm();
  const location = useLocation();
  const donViId = useSelector((state) => state.auth.user.unitId);
  const [, setSearchParams] = useSearchParams();
  // Table state
  const [listLoading, setListLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: donViId,
    ...parseParams(location.search),
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataIds, setDataIds] = useState([]);

  //Modal Dong Bo CMIS
  const [visibleCMIS, setVisibleCMIS] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  // Search by filter
  const handleSearch = useCallback(
    (values) => {

      const payload = {
        TuNgay: formatTime(values.TuNgay, "YYYY-MM-DD"),
        DenNgay: formatTime(values.DenNgay, "YYYY-MM-DD"),
        ...(values.SoGCS && { SoGCS: values.SoGCS }),
        ...(values.MaKhachHang && { MaKhachHang: values.MaKhachHang }),
        ...(values.DonViId && { DonViId: values.DonViId }),
        ...(values.NVThuNo && { NVThuNo: values.NVThuNo }),
        ...(values.TrangThaiWeb !== undefined && {
          TrangThaiWeb: Number(values.TrangThaiWeb),
        }),
        pageIndex: DEFAULT_PAGEINDEX,
        pageSize: DEFAULT_PAGESIZE,
      };

      setFilterConditions(payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterConditions]
  );

  const removeUndefinedAttribute = (obj) => {
    const params = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== undefined) {
        params[key] = obj[key];
      }
      return {};
    });
    return params;
  };

  // default call api list

  useEffect(() => {
    const current_query = filterConditions;

    const updated_query = {
      ...current_query,
      pageSize: filterConditions.pageSize,
      pageIndex: filterConditions.pageIndex,
    };

    const query = buildQueryString(updated_query);
    getDanhSachNoDong(
      setListLoading,
      setData,
      query,
      filterConditions,
      setTotal
    );
    setSelectedRowKeys([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions]);

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  // Search by default condition
  const clearFilter = useCallback(() => {
    const payload = {
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DenNgay: undefined,
      TuNgay: undefined,
      DonViId: donViId,
    };
    setSelectedRowKeys([]);
    setFilterConditions(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // onChange PageSize and PageIdex
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  // fake data table and columns

  const columns = [
    {
      title: "STT",
      width: "80px",
      align: "center",
      dataIndex: "STT",
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      width: "120px",
      align: "left",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "tenKhachHang",
      width: "100px",
      align: "left",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      width: "150px",
      align: "left",
    },
    {
      title: "Số GCS",
      dataIndex: "soGCS",
      width: "100px",
      align: "left",
    },
    {
      title: "Tổng hóa đơn",
      dataIndex: "phiDichVu",
      width: "100px",
      align: "right",
    },
    {
      title: "Tổ đội",
      dataIndex: "toDoi",
      width: "100px",
      align: "left",
    },
    {
      title: "Nhân viên thu",
      dataIndex: "nhanVienThuNo",
      width: "100px",
      align: "left",
    },
    {
      title: "Ngày thu",
      dataIndex: "ngayThuNo",
      render: (value) => (
        <>{value ? moment(value).format("DD/MM/YYYY") : ""}</>
      ),
      width: "100px",
      align: "center",
    },
    {
      title: "Ngày cắt điện",
      dataIndex: "ngayCatDien",
      render: (value) => (
        <>{value ? moment(value).format("DD/MM/YYYY") : ""}</>
      ),
      width: "120px",
      align: "center",
    },
    {
      title: "Ngày yêu cầu",
      dataIndex: "ngayYeuCau",
      render: (value) => (
        <>{value ? moment(value).format("DD/MM/YYYY") : ""}</>
      ),
      width: "120px",
      align: "center",
    },
    {
      title: "Mã hóa đơn",
      dataIndex: "idHoaDon",
      width: "100px",
      align: "center",
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      width: "110px",
      align: "left",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      const idArray = selectedRows.map((item) => {
        return item.id;
      });
      setDataIds(idArray);
    },
    getCheckboxProps: (record) => ({
      name: record.name,
      disabled: record.trangThai === "Đã đồng bộ",
    }),
    selectedRowKeys,
  };

  // Handle modal Dong bo CMIS
  const handleOpenModal = () => {
    setVisibleCMIS(true);
  };

  const handleCloseModal = () => {
    setVisibleCMIS(false);
  };

  const handleSubmitModal = useCallback(() => {
    const payload = {
      ListId: dataIds,
    };
    postDongBo(
      payload,
      setLoadingModal,
      setListLoading,
      setData,
      filterConditions,
      location,
      setTotal,
      setVisibleCMIS,
      setSelectedRowKeys,
      setDataIds
    );
  }, [dataIds, filterConditions, location]);

  const showModalDongBo = useMemo(() => {
    if (visibleCMIS) {
      return (
        <DongBoCMISModal
          visible={visibleCMIS}
          handleCloseModal={handleCloseModal}
          handleSubmit={handleSubmitModal}
          loading={loadingModal}
        />
      );
    }
  }, [handleSubmitModal, loadingModal, visibleCMIS]);

  return (
    <Style>
      <div className="dang-ky-thanh-toan">
        <Filter>
          <ListBoLoc
            form={form}
            filterConditions={filterConditions}
            handleSearch={handleSearch}
            clearFilter={clearFilter}
          />
        </Filter>

        <div className="dang-ky-thanh-toan-content">
          <Row gutter={24}>
            <Col span={5} offset={19}>
              <div className="dang-ky-thanh-toan-content-button">
                <Button
                  type="primary"
                  onClick={() => handleOpenModal()}
                  disabled={selectedRowKeys.length < 1}
                >
                  Đồng bộ về CMIS
                </Button>
              </div>
            </Col>
          </Row>
          <TableComponent
            loading={listLoading}
            dataSource={data}
            columns={columns}
            expandable={false}
            header="Danh sách"
            rowKey="key"
            totalData={total}
            pagination={filterConditions}
            onChangePagination={onChangePagination}
            // onRow={detailHoSo}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            scrollX={1800}
          />
        </div>
      </div>
      {showModalDongBo}
    </Style>
  );
}

export default DanhSachHoaDonDongCatDien;
