import { BASE_API_URL_DANH_MUC } from "@utils/constants";

export const APILoaiDiemDo = {
  LIST_LOAI_DIEM_DO: `${BASE_API_URL_DANH_MUC}/loai-diem-do/list`,
  SYNC_LOAI_DIEM_DO: `${BASE_API_URL_DANH_MUC}/loai-diem-do/sync`,
  CREATE_LOAI_DIEM_DO: `${BASE_API_URL_DANH_MUC}/loai-diem-do/create`,
  UPDATE_LOAI_DIEM_DO: `${BASE_API_URL_DANH_MUC}/loai-diem-do/update`,
  REMOVE_LOAI_DIEM_DO: `${BASE_API_URL_DANH_MUC}/loai-diem-do/delete`,
  GET_LOAI_DIEM_DO: `${BASE_API_URL_DANH_MUC}/loai-diem-do/get`,
};
