import { Pie } from "@ant-design/plots";
import { Button, Col, Row } from "antd";
import { Fragment } from "react";
import { ContainerThongKe, TitleContainer } from "../css-styled";

export default function Index(props) {
  const {
    configChart,
    dataYCThang,
    dataYCQuy,
    dataYCNam,
    configPie,
    handleOpenModalChiTietTab2,
    titleThang,
    titleNam,
    titleQuy,
    width,
  } = props;

  return (
    <Fragment>
      <TitleContainer>
        <h2>II. Thống kê kết quả giải quyết yêu cầu cấp điện</h2>
        <Button type="link" onClick={handleOpenModalChiTietTab2}>
          Xem dữ liệu chi tiết
        </Button>
      </TitleContainer>
      <ContainerThongKe>
        <Row gutter={24}>
          <Col
            span={24}
            xl={12}
            style={{ paddingRight: width && width >= 1200 ? "24px" : "12px" }}
          >
            <div className="box">
              <h3>{titleThang}</h3>
              <Pie
                {...configChart(
                  configPie,
                  dataYCThang,
                  ["#5B9BD5", "#ED7D31", "#A5A5A5", "#FFC000", "#4472C4"],
                  "right",
                  window.innerWidth <= 1550 ? -72 : -120,
                  80
                )}
              />
            </div>
          </Col>
          <Col
            span={24}
            xl={12}
            style={{
              paddingLeft: width && width < 1200 ? "12px" : "24px",
              paddingTop: width && width < 1200 ? "24px" : 0,
            }}
          >
            <div className="box">
              <h3>{titleQuy}</h3>
              <Pie
                {...configChart(
                  configPie,
                  dataYCQuy,
                  ["#5B9BD5", "#ED7D31", "#A5A5A5", "#FFC000", "#4472C4"],
                  "right",
                  window.innerWidth <= 1550 ? -72 : -120,
                  80
                )}
              />
            </div>
          </Col>
          <Col span={24} className="row3">
            <div
              className="box"
              style={{
                width: width && width < 1200 ? "100%" : "50%",
              }}
            >
              <h3>{titleNam}</h3>
              <Pie
                {...configChart(
                  configPie,
                  dataYCNam,
                  ["#5B9BD5", "#ED7D31", "#A5A5A5", "#FFC000", "#4472C4"],
                  "right",
                  window.innerWidth <= 1550 ? -72 : -120,
                  80
                )}
              />
            </div>
          </Col>
          {/* <ButtonChiTiet>
            <Button type="link" onClick={handleOpenModalChiTietTab2}>
              Xem dữ liệu chi tiết
            </Button>
          </ButtonChiTiet> */}
        </Row>
      </ContainerThongKe>
    </Fragment>
  );
}
