import React from "react";
import { Container } from "./css-styled";
import { Button, Modal, Row } from "antd";
import LoadingComponent from "@components/Loading";
import RenderContent from "./render-content";
export default function ModalConfirm(props) {
  const {
    title,
    visible,
    closeModal,
    onSuccess,
    loading,
    content,
    dataSource,
    columns,
  } = props;
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={title}
        visible={visible}
        onCancel={closeModal}
        footer={null}
        width={"50%"}
      >
        {/* Truyền content */}
        <Container>
          <RenderContent
            content={content}
            dataSource={dataSource}
            columns={columns}
          />
        </Container>
        <Row gutter={24} justify="center" style={{ marginTop: "48px" }}>
          <Button
            key="back"
            onClick={closeModal}
            className="button-closed"
            style={{ marginRight: "24px" }}
          >
            Đóng lại
          </Button>

          <Button
            type="primary"
            key="submit"
            loading={loading}
            onClick={onSuccess}
          >
            {"Đồng ý"}
          </Button>
        </Row>
      </Modal>
    </LoadingComponent>
  );
}
