import { authSelector } from "@state/auth";
import { Button, notification } from "antd";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import CheckPermission from "../CheckPermission";
import ThemChuKy from "./modal";
import {
  handleKyUSB,
  handleGetHash,
  handleKyUSBCapDien,
  handleGetHashTreoThao,
  handleXacThucKySo,
  handleGetHashKTCT,
  handleXacThucKySoKTCT,
} from "./services";
import { ENUM_KYSO } from "@utils/constants";

export default function Index(props) {
  const {
    url,
    payload,
    permissionCode,
    disabled,
    text,
    setFetchDataTable,
    urlGetHash,
    reload,
    loading,
    setLoading,
    typeKySo,
    widthBtn,
  } = props;
  const { user } = useSelector(authSelector);
  const [visible, setVisible] = useState(false);

  // const [loading, setLoading] = useState(false);
  //callback after handle ký số
  const callback = useCallback(() => {
    if (setFetchDataTable) setFetchDataTable(true);
    if (reload) reload();
  }, [reload, setFetchDataTable]);

  const handleKySo = useCallback(() => {
    setLoading(true);
    // eslint-disable-next-line no-undef
    const Sign = new SignHubExt();

    Sign.initialize(
      { urlGetHash, payload },
      typeKySo === ENUM_KYSO.TREOTHAO
        ? handleGetHashTreoThao
        : typeKySo === ENUM_KYSO.KTCONGTO
        ? handleGetHashKTCT
        : handleGetHash,
      setLoading,
      (signalItems) => {
        if (signalItems) {
          if (typeKySo === ENUM_KYSO.KTCONGTO)
            handleXacThucKySoKTCT(
              url,
              signalItems,
              setLoading,
              callback,
              payload
            );
          else if (typeKySo === ENUM_KYSO.TREOTHAO)
            handleXacThucKySo(url, signalItems, payload, setLoading, callback);
          else if (typeKySo === ENUM_KYSO.CAPDIEN)
            handleKyUSBCapDien(url, signalItems, setLoading, callback);
          else handleKyUSB(url, signalItems, setLoading, callback);
        } else {
          notification.error({
            message: `Thông báo`,
            description: "Thông tin chứng thư số không hợp lệ!",
          });
          setLoading(false);
        }
      }
    );
  }, [setLoading, urlGetHash, payload, typeKySo, url, callback]);

  //Handle Quyết toán USB
  const handleQuyetToanUSB = useCallback(() => {
    handleKySo();
  }, [handleKySo]);

  //xử lý show modal
  const openModalKySo = () => {
    if (user.chuKy === null || user.chuKy === "null") {
      setVisible(!visible);
    } else {
      handleQuyetToanUSB();
    }
  };

  //close modal
  const handleCloseModal = () => {
    setVisible(false);
  };

  //popup tạo chữ ký
  const showModalChuKy = useCallback(() => {
    return (
      visible && (
        <ThemChuKy
          visible={visible}
          closeModal={handleCloseModal}
          handleKySo={handleKySo}
        />
      )
    );
  }, [handleKySo, visible]);
  return (
    <CheckPermission permissionCode={permissionCode}>
      <Button
        type="primary"
        onClick={openModalKySo}
        disabled={disabled}
        loading={loading}
        style={{ width: widthBtn }}
      >
        {text ? text : "Ký số"}
      </Button>

      {showModalChuKy()}
    </CheckPermission>
  );
}
