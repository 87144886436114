import { authPostData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { alertMessage } from "@utils/function";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";

// Update data
export const handleDuyetHoSoService = (
  hoSoId,
  setLoading,
  closeModal,
  setFetchDataTable,
  t
) => {
  authPostData({
    url: Endpoint.KIEM_DUYET_KY_DUYET,
    method: METHOD_POST,
    payload: hoSoId,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        closeModal();
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};
