import styled from "styled-components";
export const CheckBoxGroupCol = styled.div`
  width: 100%;
  .ant-checkbox-wrapper {
    margin-left: 0px !important;
  }

  .group-full {
    .ant-checkbox-wrapper {
      width: 32%;
    }
  }
`;
