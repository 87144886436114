import { cap_dien } from "@permissions/a_capdien";
import CheckStatus from "@components/CheckStatus";
import {
  LITMIT_TRANGTHAI,
  MATRIX_CAP_DIEN,
  SEQUENCE_TRANGTHAI,
} from "@utils/constants";
import { Menu } from "antd";
import TaiAnhHoSoKhachHang from "../../common-ho-so/tai-anh-ho-so-khach-hang";
import CheckPermission from "@components/CheckPermission";

const { SubMenu } = Menu;

export default function ListMenu(props) {
  const {
    dispatch,
    openModal,
    closeModal,
    detail,
    getDetail,
    handleOpenModalChuyenDT,
    handleOpenModalTroNgaiKS,
    t,
    handleOpenModalInHoSoCMIS,
    handleOpenModalXemLaiPhuongAnCapDien,
    handleOpenModalQuyetToanKhachHang,
    handleOpenModalQuyetToanCongTy,
    handleOpenModalInBieuKhaiGia,
    inBienBanApGia,
    handleOpenInPhuTai,
    handleOpenModalChuyenAG,
  } = props;

  return (
    <Menu mode="vertical" style={{ width: "100%" }} selectedKeys={["0"]}>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.TAIANH}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.LAPPHUONGAN[1]}
        permissionCode={cap_dien.a33_tai_anh_hskh}
      >
        <Menu.Item
          eventKey="20"
          onClick={() =>
            dispatch(
              openModal({
                content: (
                  <TaiAnhHoSoKhachHang
                    closeModal={closeModal}
                    detail={detail}
                  />
                ),
                size: "large",
              })
            )
          }
        >
          {t("common_cap_dien.tai_anh_ho_so_khach_hang")}
        </Menu.Item>
      </CheckStatus>
      {detail.isDuocPhepChuyenApGia ? (
        <CheckPermission permissionCode={cap_dien.a33_chuyen_ap_gia}>
          <Menu.Item eventKey="88" onClick={handleOpenModalChuyenAG}>
            Xác nhận chuyển áp giá
          </Menu.Item>
        </CheckPermission>
      ) : null}
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.LAPPHUONGAN}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.LAPPHUONGAN[1]}
        permissionCode={cap_dien.a33_lap_phuong_an}
      >
        <Menu.Item eventKey="1" onClick={getDetail}>
          {t("lap_phuong_an.lap_phuong_an")}
        </Menu.Item>
      </CheckStatus>
      <CheckPermission permissionCode={cap_dien.a33_xn_chuyen_doi_truong}>
        <Menu.Item eventKey="10" onClick={handleOpenModalChuyenDT}>
          {" "}
          {t("lap_phuong_an.xac_nhan_chuyen_doi_truong")}
        </Menu.Item>
      </CheckPermission>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.TRONGAI}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.LAPPHUONGAN[1]}
        permissionCode={cap_dien.a33_tro_ngai_khao_sat}
      >
        <Menu.Item eventKey="12" onClick={handleOpenModalTroNgaiKS}>
          {" "}
          {t("lap_phuong_an.tro_ngai_khao_sat")}
        </Menu.Item>
      </CheckStatus>

      <CheckPermission permissionCode={cap_dien.a33_in_hs_cmis}>
        <Menu.Item eventKey="2" onClick={handleOpenModalInHoSoCMIS}>
          {" "}
          {t("common_cap_dien.in_ho_so_cmis")}
        </Menu.Item>
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a33_in_hs_web}>
        {Number(detail.maTrangThai) >= LITMIT_TRANGTHAI ? (
          <SubMenu eventKey="sub1" title={t("common_cap_dien.in_ho_so_web")}>
            <Menu.Item
              eventKey="3"
              onClick={handleOpenModalXemLaiPhuongAnCapDien}
            >
              {" "}
              {t("common_cap_dien.xem_lai_phuong_an_cap_dien")}
            </Menu.Item>
            <Menu.Item eventKey="20" onClick={handleOpenInPhuTai}>
              In biểu đồ phụ tải
            </Menu.Item>
            <Menu.Item eventKey="4" onClick={handleOpenModalQuyetToanKhachHang}>
              {" "}
              {t("common_cap_dien.quyet_toan_khach_hang")}
            </Menu.Item>
            <Menu.Item eventKey="5" onClick={handleOpenModalQuyetToanCongTy}>
              {" "}
              {t("common_cap_dien.quyet_toan_cong_ty")}
            </Menu.Item>
            <Menu.Item eventKey="6" onClick={handleOpenModalInBieuKhaiGia}>
              {" "}
              {t("common_cap_dien.in_bieu_khai_gia")}
            </Menu.Item>
            <Menu.Item eventKey="7" onClick={inBienBanApGia}>
              {" "}
              {t("common_cap_dien.in_bien_ban_ap_gia")}
            </Menu.Item>
          </SubMenu>
        ) : null}
      </CheckPermission>
    </Menu>
  );
}
