import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { getErrorForm } from "@utils/function";
import { authPostData } from "@utils/request";
import { Modal } from "antd";

export const savePage = (values, setLoading, getPageList, form, close) => {
  authPostData({
    url: `${Endpoint.PAGE}`,
    method: "POST",
    setLoading,
    payload: {
      ...values,
      trangThai: 1,
    },
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getPageList();
        form.resetFields();
        console.log("form.resetFields", form.resetFields);
        if (close) close();
      } else {
        getErrorForm(res, form);
      }
    },
  });
};

export const removePage = ({
  values,
  setLoading,
  getPageList,
  dataSource,
  setFilterConditions,
}) => {
  authPostData({
    url: `${Endpoint.PAGE}`,
    method: "DELETE",
    setLoading,
    payload: {
      ...values,
    },
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        if (res.data?.id) {
          handleDeletePage({
            values: { id: res.data?.id, isDeleteWithMenu: true },
            setLoading,
            getPageList,
            content: "Trang đang được gán cho menu, bạn có tiếp tục xóa?",
          });
        } else {
          if (dataSource && dataSource.length === 1) {
            setFilterConditions((oldState) => ({
              ...oldState,
              pageIndex: 1,
            }));
            getPageList();
          } else {
            getPageList();
          }
        }
      }
    },
  });
};

export function handleDeletePage({
  values,
  setLoading,
  getPageList,
  content = "Bạn có chắc chắn muốn xóa !",
  dataSource,
  setFilterConditions,
}) {
  Modal.confirm({
    centered: true,
    title: "Xác nhận",
    content,
    onOk() {
      removePage({
        values,
        setLoading,
        getPageList,
        dataSource,
        setFilterConditions,
      });
    },
    onCancel() {},
    okText: "Đồng ý",
    okButtonProps: { type: "danger" },
    cancelText: "Hủy",
  });
}
