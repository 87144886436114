import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, authPutData } from "@utils/request";
export const getDanhSachDuyet = (
  setListLoading,
  setData,
  query,
  filterConditions,
  setTotal
) => {
  authGetData({
    url: `${Endpoint.CRM_LANH_DAO_LIST_YEU_CAU}?${query}`,
    setLoading: setListLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              STT:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            };
          })
        );
        setTotal(res.paging.totalCount);
      }
    },
  });
};

export const putDuyetYeuCau = (
  payload,
  setLoading,
  setListLoading,
  location,
  filterConditions,
  setData,
  setTotal,
  setVisibleDuyet,
  setSelectedRowKeys
) => {
  authPutData({
    url: `${Endpoint.CRM_LANH_DAO_XAC_NHAN}`,
    method: "PUT",
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        const query =
          Object.keys(parseParams(location.search)) > 0
            ? buildQueryString(parseParams(location.search))
            : buildQueryString(filterConditions);
        getDanhSachDuyet(
          setListLoading,
          setData,
          query,
          filterConditions,
          setTotal
        );
        setVisibleDuyet(false);
        setSelectedRowKeys([]);
      }
    },
  });
};

export const putTraLaiYeuCau = (
  payload,
  setLoading,
  setListLoading,
  location,
  filterConditions,
  setData,
  setTotal,
  setVisibleTra,
  setSelectedRowKeys
) => {
  authPutData({
    url: `${Endpoint.CRM_LANH_DAO_TRA_YEU_CAU}`,
    method: "PUT",
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        const query =
          Object.keys(parseParams(location.search)) > 0
            ? buildQueryString(parseParams(location.search))
            : buildQueryString(filterConditions);
        getDanhSachDuyet(
          setListLoading,
          setData,
          query,
          filterConditions,
          setTotal
        );
        setVisibleTra(false);
        setSelectedRowKeys([]);
      }
    },
  });
};

export const getLinhVucList = (
  setLoading,
  form_filter,
  setData,
  filterConditions,
  setTotal,
  location,
  setListLoading
) => {
  authGetData({
    url: `${Endpoint.CRM_LIST_LINH_VUC}`,
    setLoading,
    onSuccess: (res) => {
      form_filter.setFieldsValue({
        LinhVuc: res.data[0].value || undefined,
      });
      const query =
        Object.keys(parseParams(location.search)) > 0
          ? buildQueryString(parseParams(location.search))
          : buildQueryString({
              linhVuc: res.data[0].value,
              ...filterConditions,
            });
      getDanhSachDuyet(
        setListLoading,
        setData,
        query,
        filterConditions,
        setTotal
      );
    },
  });
};

export const putKyHSM = (
  payload,
  setLoadingKyHSM,
  setListLoading,
  location,
  filterConditions,
  setData,
  setTotal,
  setVisibleDuyet,
  setSelectedRowKeys,
  setDataIds
) => {
  authPutData({
    url: `${Endpoint.KY_HSM_LANH_DAO}`,
    method: "PUT",
    payload,
    setLoading: setLoadingKyHSM,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        const query =
          Object.keys(parseParams(location.search)).length > 0
            ? buildQueryString(parseParams(location.search))
            : buildQueryString(filterConditions);
        getDanhSachDuyet(
          setListLoading,
          setData,
          query,
          filterConditions,
          setTotal
        );
        setVisibleDuyet(false);
        setSelectedRowKeys([]);
        setDataIds([]);
      }
    },
  });
};
