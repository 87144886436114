import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { getErrorMessage } from "@utils/function";
import { authPostData } from "@utils/request";
// hàm call api hủy phân công

export const handleHuyPhanCong = (
  data,
  setLoading,
  setFetchDataTable,
  setVisibleHuyPC,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.HUY_PHANCONG,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setVisibleHuyPC(false);
        setSelectedRowKeys([]);
      } else {
        getErrorMessage(res);
      }
    },
  });
};
