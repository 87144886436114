import { useEffect, useState } from "react";
import { handleGetCongSuat } from "../../../services";
import CongSuatThietBiTabs from "./tabs-cong-suat-thiet-bi-dien";
import { lapPhuongAnSelector } from "@state/cap-dien/nhan-vien-khao-sat/lap-phuong-an/reducer";
import { useDispatch, useSelector } from "react-redux";
import { changedHeSoDongThoi } from "@state/cap-dien/nhan-vien-khao-sat/lap-phuong-an/reducer";

export default function Index(props) {
  const { detailLapPhuongAn, indexTab, mode } = props;
  const [fetchCSTBIfNeed, setFetchDataCSTB] = useState(false);
  const [dataCongSuatThietBi, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { isChangeHeSoDongThoi } = useSelector(lapPhuongAnSelector);
  const dispatch = useDispatch();

  const getCongSuatThietBi = () => {
    handleGetCongSuat(detailLapPhuongAn, setLoading, setData);
  };

  useEffect(() => {
    getCongSuatThietBi();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (indexTab === "2" && isChangeHeSoDongThoi) {
      getCongSuatThietBi();
      dispatch(changedHeSoDongThoi(false));
    }
    // eslint-disable-next-line
  }, [indexTab]);

  useEffect(() => {
    if (fetchCSTBIfNeed) {
      getCongSuatThietBi();
      setFetchDataCSTB(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCSTBIfNeed]);

  return (
    <CongSuatThietBiTabs
      loading={loading}
      dataCongSuatThietBi={dataCongSuatThietBi}
      detailLapPhuongAn={detailLapPhuongAn}
      setLoading={setLoading}
      setFetchDataCSTB={setFetchDataCSTB}
      mode={mode}
    />
  );
}
