import { ConfigProvider, Layout } from "antd";
import styled from "styled-components";
import Header from "./Header";
import Sider from "./Sider";
import ModalComponent from "./ModalComponent";
import { Scrollbars } from "react-custom-scrollbars";
import vn from "antd/es/locale-provider/vi_VN";
import { systemConfigSelector } from "@state/system-config/reducer";
import { useSelector } from "react-redux";
import { Grid } from "antd";

const { useBreakpoint } = Grid;
const { Content } = Layout;

export default function LayoutComponent(props) {
  const { title, parentTitle, children, moduleCode } = props;
  const { collapsed } = useSelector(systemConfigSelector);
  const screens = useBreakpoint();
  const mobile = screens.xs && !screens.md;
  return (
    <ConfigProvider locale={vn}>
      <LayoutStyled>
        {mobile && collapsed ? null : <Sider mobile={mobile} />}
        <Layout>
          <Header
            title={title}
            parentTitle={parentTitle}
            moduleCode={moduleCode}
            mobile={mobile}
          />
          <Scrollbars autoHide className="scroll-bars">
            <Content>
              <ModalComponent>{children}</ModalComponent>
            </Content>
          </Scrollbars>
        </Layout>
      </LayoutStyled>
    </ConfigProvider>
  );
}

const LayoutStyled = styled(Layout)`
  height: 100vh;
  .scroll-bars {
    background: #fff;
  }
`;
