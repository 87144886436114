/* eslint-disable react-hooks/exhaustive-deps */
import { METHOD_POST } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  nullToString,
  parseParams,
  removeAccents,
  stringToSlug
} from "@utils/function";
import {
  authPostData,
  downloadFilePostData,
  useCustomDebounce
} from "@utils/request";
import {
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Spin,
  Table
} from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  NAME_HINHTHUC_SMS,
  NAME_TB_CS_THAY_CT_DINHKY_THAO_COKHI_TREO_COKHI,
  NAME_TB_CS_THAY_CT_DINHKY_THAO_COKHI_TREO_DIENTU,
  NAME_TB_CS_THAY_CT_DINHKY_THAO_DIENTU_TREO_DIENTU,
  NAME_TB_KH_CO_SL_TANG_30PT,
  NAME_TB_KH_CO_SL_TANG_50PT,
  NAME_TB_KH_SH_KHONG_SUDUNG_DIEN_6_THANG,
  NAME_TB_KH_THAY_DINH_KY_CT,
  NAME_TB_KIEM_TRA_AP_GIA_BAN_DIEN,
  NAME_TB_KIEM_TRA_KH_2_DINH_MUC,
  NAME_TB_Ngung_Cung_Cap_Dien,
  NAME_TB_THAY_DOI_LICH_GCS_KH_1_DIEM_DO_VA_GCS_1_KY,
  NAME_TB_THAY_DOI_LICH_GCS_KH_GCS_TU_2KY,
  NAME_TB_THAY_DOI_LICH_GCS_KH_GHEP_TONG_NHIEU_DIEM_DO,
  NAME_TB_THAY_DOI_LICH_GCS_TET,
  NAME_TB_TIEN_DIEN_LAN_2,
  NAME_TB_TIEN_DIEN_LAN_2_ZALO_MAIL_KHONG_THANHCONG,
  NAME_TB_XAC_NHAN_SL_DIEN_MT_SAU_1GIA,
  NAME_TB_XAC_NHAN_SL_DIEN_MT_SAU_3GIA,
  NAME_TB_XAC_NHAN_SL_DIEN_MT_TRUOC_1GIA,
  NAME_TB_XAC_NHAN_SL_DIEN_MT_TRUOC_3GIA
} from "./constant";
import { FillterTinNhan } from "./list-bo-loc";

const danhSachDoiTuong = [
  NAME_TB_CS_THAY_CT_DINHKY_THAO_COKHI_TREO_COKHI,
  NAME_TB_CS_THAY_CT_DINHKY_THAO_COKHI_TREO_DIENTU,
  NAME_TB_CS_THAY_CT_DINHKY_THAO_DIENTU_TREO_DIENTU,
  NAME_TB_KH_CO_SL_TANG_30PT,
  NAME_TB_KH_CO_SL_TANG_50PT,
  NAME_TB_KH_SH_KHONG_SUDUNG_DIEN_6_THANG,
  NAME_TB_KH_THAY_DINH_KY_CT,
  NAME_TB_KIEM_TRA_AP_GIA_BAN_DIEN,
  NAME_TB_KIEM_TRA_KH_2_DINH_MUC,
  NAME_TB_Ngung_Cung_Cap_Dien,
  NAME_TB_THAY_DOI_LICH_GCS_KH_1_DIEM_DO_VA_GCS_1_KY,
  NAME_TB_THAY_DOI_LICH_GCS_KH_GCS_TU_2KY,
  NAME_TB_THAY_DOI_LICH_GCS_KH_GHEP_TONG_NHIEU_DIEM_DO,
  NAME_TB_THAY_DOI_LICH_GCS_TET,
  NAME_TB_TIEN_DIEN_LAN_2,
  NAME_TB_TIEN_DIEN_LAN_2_ZALO_MAIL_KHONG_THANHCONG,
  NAME_TB_XAC_NHAN_SL_DIEN_MT_SAU_1GIA,
  NAME_TB_XAC_NHAN_SL_DIEN_MT_SAU_3GIA,
  NAME_TB_XAC_NHAN_SL_DIEN_MT_TRUOC_1GIA,
  NAME_TB_XAC_NHAN_SL_DIEN_MT_TRUOC_3GIA,
];

export default function HinhThucEmailZalo() {
  const [dataDanhSachKhachHang, setDataDanhSachKhachHang] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [id, setId] = useState();
  const [confirm, setConfirm] = useState();
  const [timeWarning, setTimeWarning] = useState();
  const [visible, setVisible] = useState(false);
  const [valueSDT, setValueSDT] = useState("");
  const [maKH, setmaKH] = useState("");
  const [tenKH, setTenKH] = useState("");
  const [soGCS, setSoGCS] = useState("");
  const [diaChi, setDiaChi] = useState("");
  const [trangThai, setTrangThai] = useState("");
  const [colorTrangThai, setColorTrangThai] = useState("");
  const location = useLocation();
  const [data, setData] = useState();
  const [dataTable, setDataTable] = useState(dataDanhSachKhachHang);
  const [filterConditions, setFilterConditions] = useState({
    ...parseParams(location.search),
    id_hinhthuc: 1,
    p_ky: 1,
    thang_ht: parseInt(`${moment(moment()._d).month() + 1}`),
    nam_cu: parseInt(
      moment(moment()._d).subtract(1, "months").year().toString()
    ),
    nam_ht: parseInt(moment(moment()._d).year()),
    thang_cu: parseInt(
      `${moment(moment()._d).subtract(1, "months").month() + 1}`
    ),
    p_thang: parseInt(`${moment(moment()._d).month() + 1}`),
    p_nam: parseInt(moment(moment()._d).year()),
    ngaY_THAY_CT: new Date(moment(moment()._d)).toLocaleString().split(",")[1],
    ngaY_KIEM_TRA: new Date(moment(moment()._d)).toLocaleString().split(",")[1],
    Gio: 8,
    Phut: 0,
  });
  const [dataDoiTuong, setDataDoiTuong] = useState([]);

  const { Option } = Select;
  const errorMess = "Cần có ít nhất 1 khách hàng được chọn";

  useEffect(() => {
    const today = new Date();
    const time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    if (
      time?.split(":")[0]?.toString() >= 19 ||
      time?.split(":")[0]?.toString() <= 7
    ) {
      setTimeWarning(true);
    } else {
      setTimeWarning(false);
    }
  }, []);

  useEffect(() => {
    setSelectedRowKeys([]);
    setFilterConditions((oldState) => ({
      ...oldState,
      mA_KHANG: [],
      danhSachKhachHang: [],
    }));
  }, [filterConditions?.maso, id, filterConditions?.ma_to_1]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isDisable, setIsDisable] = useState(false);

  const onSelectChange = (selected, record) => {
    setSelectedRowKeys(selected);
    setFilterConditions((oldState) => ({
      ...oldState,
      mA_KHANG: selected,
      danhSachKhachHang: record,
    }));
  };
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };
  useEffect(() => {
    setDataTable(dataDanhSachKhachHang);
  }, [dataDanhSachKhachHang]);

  useEffect(() => {
    if (filterConditions?.maso || filterConditions?.maso?.length < 0) {
      setDataTable(null);
    }
  }, [filterConditions?.maso]);
  const filterDebounce = useCustomDebounce(filterConditions);

  const listMaSo = useCustomDebounce(filterConditions?.maso, 2000);

  const queryMaSo = buildQueryString(
    parseParams({
      doiTuongId: filterConditions?.doiTuongId,
      id_hinhthuc: 1,
      ma_to_1: filterConditions?.ma_to_1,
      nam_cu: filterConditions?.nam_cu,
      nam_ht: filterConditions?.nam_ht,
      p_ky: filterConditions?.p_ky,
      p_nam: filterConditions?.p_nam,
      p_thang: filterConditions?.p_thang,
      thang_cu: filterConditions?.thang_cu,
      thang_ht: filterConditions?.thang_ht,
      Loai_cto: filterConditions?.Loai_cto,
      p_TrangThaiID: filterConditions?.p_TrangThaiID,
    })
  );
  const handleConfirm = useCallback(() => {
    setConfirm(true);
  }, []);

  useEffect(() => {
    if (timeWarning === false) {
      setConfirm(true);
    }
    if (timeWarning === true) {
      setConfirm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions.danhSachKhachHang]);

  const postData = () => {
    if (confirm === true) {
      authPostData({
        url: `${Endpoint.CSKH_NT_SMS}`,
        method: METHOD_POST,
        payload: { ...filterDebounce },
        setLoading,
        onSuccess: () => {
          getAllData();
        },
      });
    }
  };

  useEffect(() => {
    if (
      confirm === true &&
      filterDebounce.danhSachKhachHang &&
      filterDebounce.danhSachKhachHang.length > 0
    ) {
      setVisible(false);
      postData();
    }
  }, [confirm]);

  useEffect(() => {
    if (filterConditions?.maso && filterConditions?.maso.length > 0) {
      handleGetData();
    }
  }, [listMaSo])

  const handleGetData = () => {
    const queryString = {
      p_ky: filterConditions.p_ky,
      nam_ht: filterConditions.p_nam,
      nam_cu: filterConditions.nam_cu,
      thang_ht: filterConditions.thang_ht,
      p_ma_sogcs: filterConditions.maso?.join(","),
      thang_cu: filterConditions.thang_cu,
      p_thang: filterConditions.p_thang,
      p_nam: filterConditions.p_nam,
      doiTuongId: filterConditions.doiTuongId,
      ma_to_1: filterConditions.ma_to_1,
      p_Loai_KH: filterConditions.p_Loai,
      id_hinhthuc: 1,
    };
    if (
      id === NAME_TB_KH_CO_SL_TANG_50PT &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0 &&
      filterConditions?.ma_to_1
    ) {
      authPostData({
        url: `${Endpoint.CSKH_DSkh_SL_QUA_150}`,
        method: METHOD_POST,
        setLoading,
        payload: queryString,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }
    if (
      id === NAME_TB_KH_CO_SL_TANG_30PT &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      authPostData({
        url: `${Endpoint.CSKH_DSkh_SL_QUA_130}`,
        method: METHOD_POST,
        setLoading,
        payload: queryString,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }
    if (
      id === NAME_TB_TIEN_DIEN_LAN_2 &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      authPostData({
        url: `${Endpoint.CSKH_DSkh_TBTD_LAN_2}`,
        method: METHOD_POST,
        setLoading,
        payload: queryString,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }

    //Chờ api
    if (
      id === NAME_TB_TIEN_DIEN_LAN_2_ZALO_MAIL_KHONG_THANHCONG &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      authPostData({
        url: `${Endpoint.CSKH_DSkh_TBTD_LAN_2_KTC}`,
        method: METHOD_POST,
        setLoading,
        payload: queryString,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }
    if (
      id === NAME_TB_Ngung_Cung_Cap_Dien &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      authPostData({
        url: `${Endpoint.CSKH_DSkh_TB_NGUNG_CAP_DIEN}`,
        method: METHOD_POST,
        setLoading,
        payload: queryString,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }
    if (
      id === NAME_TB_KH_THAY_DINH_KY_CT &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      const treoCoKhi = 1;
      const query = {
        p_ma_sogcs: filterConditions?.maso?.join(","),
        doiTuongId: filterConditions?.doiTuongId,
        p_thang: filterConditions.p_thang,
        p_nam: filterConditions.p_nam,
        ma_to_1: filterConditions?.ma_to_1,
        p_loai_tt: treoCoKhi,
        p_TrangThaiID: filterConditions?.p_TrangThaiID,
        id_hinhthuc: 1,
      };
      authPostData({
        url: `${Endpoint.CSKH_DSkh_TB_THAY_DK_CT}`,
        method: METHOD_POST,
        setLoading,
        payload: query,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }
    if (
      id === NAME_TB_CS_THAY_CT_DINHKY_THAO_COKHI_TREO_DIENTU &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      const thaoCoKhiTreoDT = 2;
      const query = {
        p_ma_sogcs: filterConditions?.maso?.join(","),
        doiTuongId: filterConditions?.doiTuongId,
        p_thang: filterConditions.p_thang,
        p_nam: filterConditions.p_nam,
        ma_to_1: filterConditions?.ma_to_1,
        p_loai_tt: thaoCoKhiTreoDT,
        p_TrangThaiID: filterConditions?.p_TrangThaiID,
        id_hinhthuc: 1,
      };
      authPostData({
        url: `${Endpoint.CSKH_DSkh_TB_CHI_SO_THAY_DK_CT}`,
        method: METHOD_POST,
        setLoading,
        payload: query,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }
    if (
      id === NAME_TB_CS_THAY_CT_DINHKY_THAO_DIENTU_TREO_DIENTU &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      const TreoDT = 3;
      const query = {
        p_ma_sogcs: filterConditions?.maso?.join(","),
        doiTuongId: filterConditions?.doiTuongId,
        p_thang: filterConditions.p_thang,
        p_nam: filterConditions.p_nam,
        ma_to_1: filterConditions?.ma_to_1,
        p_loai_tt: TreoDT,
        p_TrangThaiID: filterConditions?.p_TrangThaiID,
        id_hinhthuc: 1,
      };
      authPostData({
        url: `${Endpoint.CSKH_DSkh_TB_CHI_SO_THAY_DK_CT}`,
        method: METHOD_POST,
        setLoading,
        payload: query,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }
    if (
      id === NAME_TB_CS_THAY_CT_DINHKY_THAO_COKHI_TREO_COKHI &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      const TreoCKthayCK = 1;
      const query = {
        p_ma_sogcs: filterConditions?.maso?.join(","),
        doiTuongId: filterConditions?.doiTuongId,
        p_thang: filterConditions.p_thang,
        p_nam: filterConditions.p_nam,
        ma_to_1: filterConditions?.ma_to_1,
        p_loai_tt: TreoCKthayCK,
        p_TrangThaiID: filterConditions?.p_TrangThaiID,
        id_hinhthuc: 1,
      };
      authPostData({
        url: `${Endpoint.CSKH_DSkh_TB_CHI_SO_THAY_DK_CT}`,
        method: METHOD_POST,
        setLoading,
        payload: query,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }
    if (
      id === NAME_TB_THAY_DOI_LICH_GCS_KH_GHEP_TONG_NHIEU_DIEM_DO &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      const KHnhieuDD = 3;
      const query = {
        p_ma_sogcs: filterConditions?.maso?.join(","),
        doiTuongId: filterConditions?.doiTuongId,
        ma_to_1: filterConditions?.ma_to_1,
        nam_ht: filterConditions?.nam_ht,
        nam_cu: filterConditions?.nam_cu,
        thang_ht: filterConditions?.thang_ht,
        thang_cu: filterConditions?.thang_cu,
        Loai_cto: filterConditions?.Loai_cto,
        p_TrangThaiID: filterConditions?.p_TrangThaiID,
        id_hinhthuc: 1,
        p_Loai_KH: KHnhieuDD,
      };
      authPostData({
        url: `${Endpoint.CSKH_DSkh_TB_LICH_GHI_CS}`,
        method: METHOD_POST,
        setLoading,
        payload: query,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }
    if (
      id === NAME_TB_THAY_DOI_LICH_GCS_KH_GCS_TU_2KY &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      const KHKY2 = 2;
      const query = {
        p_ma_sogcs: filterConditions?.maso?.join(","),
        doiTuongId: filterConditions?.doiTuongId,
        ma_to_1: filterConditions?.ma_to_1,
        nam_ht: filterConditions?.nam_ht,
        nam_cu: filterConditions?.nam_cu,
        thang_ht: filterConditions?.thang_ht,
        thang_cu: filterConditions?.thang_cu,
        Loai_cto: filterConditions?.Loai_cto,
        p_TrangThaiID: filterConditions?.p_TrangThaiID,
        id_hinhthuc: 1,
        p_Loai_KH: KHKY2,
      };
      authPostData({
        url: `${Endpoint.CSKH_DSkh_TB_LICH_GHI_CS}`,
        method: METHOD_POST,
        setLoading,
        payload: query,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }
    if (
      id === NAME_TB_THAY_DOI_LICH_GCS_KH_1_DIEM_DO_VA_GCS_1_KY &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      const KHKY1 = 1;
      const query = {
        p_ma_sogcs: filterConditions?.maso?.join(","),
        doiTuongId: filterConditions?.doiTuongId,
        ma_to_1: filterConditions?.ma_to_1,
        nam_ht: filterConditions?.nam_ht,
        nam_cu: filterConditions?.nam_cu,
        thang_ht: filterConditions?.thang_ht,
        thang_cu: filterConditions?.thang_cu,
        Loai_cto: filterConditions?.Loai_cto,
        p_TrangThaiID: filterConditions?.p_TrangThaiID,
        id_hinhthuc: 1,
        p_Loai_KH: KHKY1,
      };
      authPostData({
        url: `${Endpoint.CSKH_DSkh_TB_LICH_GHI_CS}`,
        method: METHOD_POST,
        setLoading,
        payload: query,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }
    if (
      id === NAME_TB_THAY_DOI_LICH_GCS_TET &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      const query = {
        p_ma_sogcs: filterConditions?.maso?.join(","),
        ma_to_1: filterConditions?.ma_to_1,
        nam_ht: filterConditions?.nam_ht,
        nam_cu: filterConditions?.nam_cu,
        thang_ht: filterConditions?.thang_ht,
        thang_cu: filterConditions?.thang_cu,
        p_TrangThaiID: filterConditions?.p_TrangThaiID,
        id_hinhthuc: 1,
        p_Loai_KH: 1,
      };
      authPostData({
        url: `${Endpoint.CSKH_DSkh_TB_LICH_GHI_CS_TET}`,
        method: METHOD_POST,
        setLoading,
        payload: query,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }
    if (
      id === NAME_TB_KIEM_TRA_KH_2_DINH_MUC &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      authPostData({
        url: `${Endpoint.CSKH_DSkh_TB_KH_2_DINH_MUC_TRO_LEN}`,
        method: METHOD_POST,
        setLoading,
        payload: queryString,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }
    if (
      id === NAME_TB_KIEM_TRA_AP_GIA_BAN_DIEN &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      authPostData({
        url: `${Endpoint.CSKH_DSkh_TB_KIEM_TRA_AP_GIA_BAN_DIEN}`,
        method: METHOD_POST,
        setLoading,
        payload: queryString,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }
    if (
      id === NAME_TB_XAC_NHAN_SL_DIEN_MT_TRUOC_1GIA &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      const Truoc = 1;
      const motGia = 1;
      const query = {
        p_ma_sogcs: filterConditions?.maso?.join(","),
        doiTuongId: filterConditions?.doiTuongId,
        p_thang: filterConditions.p_thang,
        p_nam: filterConditions.p_nam,
        ma_to_1: filterConditions?.ma_to_1,
        id_hinhthuc: 1,
        loai_kh: Truoc,
        Loai_cto: motGia,
      };
      authPostData({
        url: `${Endpoint.CSKH_DSkh_TB_XACNHAN_SLDIEN_MT}`,
        method: METHOD_POST,
        setLoading,
        payload: query,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }
    if (
      id === NAME_TB_XAC_NHAN_SL_DIEN_MT_TRUOC_3GIA &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      const Truoc = 1;
      const BaGia = 3;
      const query = {
        p_ma_sogcs: filterConditions?.maso?.join(","),
        doiTuongId: filterConditions?.doiTuongId,
        p_thang: filterConditions.p_thang,
        p_nam: filterConditions.p_nam,
        ma_to_1: filterConditions?.ma_to_1,
        loai_kh: Truoc,
        id_hinhthuc: 1,
        Loai_cto: BaGia,
      };
      authPostData({
        url: `${Endpoint.CSKH_DSkh_TB_XACNHAN_SLDIEN_MT}`,
        method: METHOD_POST,
        setLoading,
        payload: query,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }
    if (
      id === NAME_TB_XAC_NHAN_SL_DIEN_MT_SAU_1GIA &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      const Sau = 2;
      const MotGia = 1;
      const query = {
        p_ma_sogcs: filterConditions?.maso?.join(","),
        doiTuongId: filterConditions?.doiTuongId,
        p_thang: filterConditions.p_thang,
        p_nam: filterConditions.p_nam,
        ma_to_1: filterConditions?.ma_to_1,
        loai_kh: Sau,
        id_hinhthuc: 1,
        Loai_cto: MotGia,
      };
      authPostData({
        url: `${Endpoint.CSKH_DSkh_TB_XACNHAN_SLDIEN_MT}`,
        method: METHOD_POST,
        setLoading,
        payload: query,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }
    if (
      id === NAME_TB_XAC_NHAN_SL_DIEN_MT_SAU_3GIA &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      const Sau = 2;
      const BaGia = 3;
      const query = {
        p_ma_sogcs: filterConditions?.maso.join(","),
        doiTuongId: filterConditions?.doiTuongId,
        p_thang: filterConditions.p_thang,
        p_nam: filterConditions.p_nam,
        ma_to_1: filterConditions?.ma_to_1,
        loai_kh: Sau,
        id_hinhthuc: 1,
        Loai_cto: BaGia,
      };
      authPostData({
        url: `${Endpoint.CSKH_DSkh_TB_XACNHAN_SLDIEN_MT}`,
        method: METHOD_POST,
        setLoading,
        payload: query,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }
    if (
      id === NAME_TB_KH_SH_KHONG_SUDUNG_DIEN_6_THANG &&
      filterConditions?.maso &&
      filterConditions?.maso.length > 0
    ) {
      const query = {
        p_ma_sogcs: filterConditions?.maso?.join(","),
        doiTuongId: filterConditions?.doiTuongId,
        ma_to_1: filterConditions?.ma_to_1,
        id_hinhthuc: 1,
      };
      authPostData({
        url: `${Endpoint.CSKH_DSkh_KH_6_THANG_KHONG_DUNG}`,
        method: METHOD_POST,
        setLoading,
        payload: query,
        onSuccess: (res) => {
          if (res) {
            setData(
              res?.data.map((e) => ({
                ...e,
                id: e?.mA_KHANG,
              }))
            );
            setSelectedRowKeys([]);
          }
        },
      });
    }

    if (id && !danhSachDoiTuong.includes(id)) {
      notification.error({
        message: `Đối tượng thêm mới cần được phát triển thêm `,
      });
    }
  }

  const getAllData = useCallback(() => {
    handleGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    // setLoading,
    filterConditions.nam_ht,
    filterConditions.nam_cu,
    filterConditions.thang_ht,
    filterConditions.thang_cu,
    filterConditions.p_thang,
    filterConditions.p_nam,
    filterConditions.doiTuongId,
    filterConditions.ma_to_1,
    filterConditions?.Loai_cto,
    filterConditions?.p_TrangThaiID,
  ]);

  useEffect(() => {
    if (filterConditions?.maso && filterConditions?.maso.length > 0) {
      getAllData();
    }
  }, [
    getAllData,
  ]);

  const getData = useCallback(() => {
    const item = data?.map((e) => {
      if (e.isDisplayInNhanTinSMS === true) {
        return e;
      }
    });
    setDataDanhSachKhachHang(
      item?.filter(function (element) {
        return element !== undefined;
      })
    );
  }, [data]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleChange = (values) => {
    setId(values);
    setFilterConditions((oldState) => ({
      ...oldState,
      // doiTuongId: values,
      doiTuongId: dataDoiTuong.find((item) => item.name === values).value,
      maso: []
    }));
    form.setFieldsValue({"danhsachso": undefined})
    setIsDisable(false)
  };
  const handleChangeToDoi = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      ma_to_1: values,
      maso: []
    }));
    form.setFieldsValue({"danhsachso": undefined})
    setIsDisable(false)
  };
  const handleChangeMaSo = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      maso: values,
    }));
  };
  const onChangeTrangThai = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      p_TrangThaiID: values,
    }));
  };
  const onChangeHinhThuc = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      id_hinhthuc: values,
    }));
  };

  const onFinish = (values) => {
    if (timeWarning === true) {
      openWarning();
    }
    if (timeWarning === false) {
      postData();
    }
  };

  // mutil filter
  useEffect(() => {
    setDataTable(
      dataDanhSachKhachHang?.filter(
        (e) =>
          stringToSlug(removeAccents(nullToString(e?.mA_KHANG))).includes(
            stringToSlug(removeAccents(maKH))
          ) &&
          stringToSlug(removeAccents(nullToString(e?.teN_KHANG))).includes(
            stringToSlug(removeAccents(tenKH))
          ) &&
          stringToSlug(removeAccents(nullToString(e?.mA_SOGCS))).includes(
            stringToSlug(removeAccents(soGCS))
          ) &&
          stringToSlug(removeAccents(nullToString(e?.duonG_PHO))).includes(
            stringToSlug(removeAccents(diaChi))
          ) &&
          e?.texT_TRANG_THAI_SMS.includes(trangThai) &&
          stringToSlug(removeAccents(nullToString(e?.dthoai))).includes(
            stringToSlug(removeAccents(valueSDT))
          )
      )
    );
    if (trangThai === undefined) {
      setDataTable(dataDanhSachKhachHang);
      setTrangThai("");
    }
  }, [maKH, tenKH, soGCS, diaChi, trangThai, valueSDT]);

  const FilterByTenKH = (
    <div>
      <span>Tên khách hàng </span>
      <Input
        style={{ height: 30 }}
        onChange={(e) => {
          const currValue = e?.target.value;
          setTenKH(currValue);
        }}
      />
    </div>
  );
  const FilterByMaKH = (
    <div>
      <span>
        Mã khách hàng{" "}
        {filterConditions?.maso?.length > 0 && dataTable?.length > 0 ? (
          <span>({dataTable?.length})</span>
        ) : null}
      </span>
      <Input
        style={{ height: 30 }}
        onChange={(e) => {
          const currValue = e?.target.value;
          setmaKH(currValue);
        }}
      />
    </div>
  );
  const FilterBySOGCS = (
    <div>
      <span>Sổ GCS</span>
      <Input
        style={{ height: 30 }}
        onChange={(e) => {
          const currValue = e?.target.value;
          setSoGCS(currValue);
        }}
      />
    </div>
  );
  const FilterByDiaChi = (
    <div>
      <span>Địa chỉ</span>
      <Input
        style={{ height: 30 }}
        onChange={(e) => {
          const currValue = e?.target.value;
          setDiaChi(currValue);
        }}
      />
    </div>
  );
  const FilterBySDT = (
    <div>
      <span>Điện thoại</span>
      <Input
        value={valueSDT}
        style={{ height: 30 }}
        onChange={(e) => {
          const currValue = e.target.value;
          setValueSDT(currValue);
        }}
      />
    </div>
  );
  const FilterByTrangThai = (
    <div>
      <span>Trạng thái</span>
      <div className="FillterTrangThai">
        <Select
          allowClear="true"
          style={{ width: "100%", color: `${colorTrangThai}` }}
          onChange={(e) => {
            const currValue = e;
            setTrangThai(currValue);
            if (e === "Chưa nhắn") {
              setColorTrangThai("green");
            }
            if (e === "Đã nhắn") {
              setColorTrangThai("blue");
            }
            if (e === undefined) {
              setDataTable(dataDanhSachKhachHang);
            }
          }}
        >
          <Option value="Chưa nhắn" style={{ color: "green" }}>
            Chưa nhắn
          </Option>
          <Option value="Đã nhắn" style={{ color: "blue" }}>
            Đã nhắn
          </Option>
        </Select>
      </div>
    </div>
  );
  const onCancel = () => {
    setVisible(false);
  };
  const columns = [
    {
      title: FilterByMaKH,
      dataIndex: "makhachhang",
      key: "makhachhang",
      width: 200,
      render: (text, record, index) => {
        return (
          <>
            <Form.Item name={`mA_KHANG-${record?.mA_KHANG}`}>
              <span key={index}>{record?.mA_KHANG}</span>
            </Form.Item>
          </>
        );
      },
    },
    {
      title: FilterByTenKH,
      dataIndex: "tenkhachhang",
      key: "tenkhachhang",
      width: 300,
      render: (text, record, index) => {
        return (
          <>
            <Form.Item name={`mA_KHANG-${record?.mA_KHANG}`}>
              <span key={index}>{record?.teN_KHANG}</span>
            </Form.Item>
          </>
        );
      },
    },
    {
      title: FilterBySOGCS,
      dataIndex: "sogcs",
      key: "sogcs",
      width: 120,
      render: (text, record, index) => {
        return (
          <>
            <Form.Item name={`mA_SOGCS-${record?.mA_SOGCS}`}>
              <span key={index}>{record?.mA_SOGCS}</span>
            </Form.Item>
          </>
        );
      },
    },
    {
      title: "Tiền điện",
      dataIndex: "tieN_NO",
      key: "tieN_NO",
      align: "center",
      width: 250,
    },
    {
      title: FilterByDiaChi,
      dataIndex: "duonG_PHO",
      key: "diachi",
      width: 400,
    },
    {
      title: FilterBySDT,
      dataIndex: "dthoai",
      width: 200,
      align: "center",
      key: "dthoai",
    },
    {
      title: FilterByTrangThai,
      dataIndex: "texT_TRANG_THAI_SMS",
      key: "texT_TRANG_THAI_SMS",
      align: "center",
      width: 130,
      render: (text, record, index) => {
        return (
          <>
            <Form.Item name={`tranG_THAI_FILE-${record?.mA_KHANG}`}>
              {record?.texT_TRANG_THAI_SMS === "Chưa nhắn" ? (
                <span key={index} style={{ color: "green" }}>
                  Chưa nhắn
                </span>
              ) : null}
              {record?.texT_TRANG_THAI_SMS === "Đã nhắn" ? (
                <span key={index} style={{ color: "blue" }}>
                  Đã nhắn
                </span>
              ) : null}
            </Form.Item>
          </>
        );
      },
    },
  ];
  const handleOpenPdf = (data, setLoading) => {
    const endpoint = `${Endpoint.CSKH_XUAT_NT_SMS}`;
    const query = {
      p_ma_sogcs: filterDebounce?.maso,
      doiTuongId: filterDebounce?.doiTuongId,
      p_nam: filterDebounce?.nam_ht,
      p_thang: filterDebounce?.thang_ht,
      ma_to_1: filterDebounce?.ma_to_1,
      nam_ht: filterDebounce?.nam_ht,
      nam_cu: filterDebounce?.nam_cu,
      thang_ht: filterDebounce?.thang_ht,
      thang_cu: filterDebounce?.thang_cu,
      Loai_cto: filterDebounce?.Loai_cto,
      p_TrangThaiID: filterDebounce?.p_TrangThaiID,
      id_hinhthuc: 1,
      danhSachKhachHang: filterDebounce?.danhSachKhachHang,
      ngaY_KIEM_TRA: filterDebounce?.ngaY_KIEM_TRA,
    };
    if (isEmpty(selectedRowKeys)) {
      notification.error(
        {
          message: "Thông báo!",
          description: "Hãy chọn khách hàng.",
        },
        500
      );
    } else {
      if (endpoint !== undefined)
        downloadFilePostData({ endpoint, setLoading, query, errorMess });
      setFilterConditions((oldState) => ({
        ...oldState,
        danhSachKhachHang: [],
      }));
    }
  };

  const onChangeKy = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      p_ky: values,
    }));
  };
  const onChange = useCallback((dates, dateStrings) => {
    const dateCu = moment(dates._d).subtract(1, "months");
    setFilterConditions((state) => ({
      ...state,
      nam_ht: parseInt(dateStrings.split("/")[1]),
      nam_cu: parseInt(dateCu.year().toString()),
      thang_ht: parseInt(dateStrings.split("/")[0]),
      thang_cu: parseInt(`${dateCu.month() + 1}`),
      p_nam: parseInt(dateStrings.split("/")[1]),
      p_thang: parseInt(dateStrings.split("/")[0]),
    }));
  }, []);
  const onChangeNgayKiemTra = useCallback((dates, dateStrings) => {
    setFilterConditions((state) => ({
      ...state,
      ngaY_KIEM_TRA: dateStrings,
    }));
  }, []);
  const onChangeNgayThayCT = useCallback((dates, dateStrings) => {
    setFilterConditions((state) => ({
      ...state,
      ngaY_THAY_CT: dateStrings,
    }));
  }, []);
  const openWarning = () => {
    if (
      filterConditions?.danhSachKhachHang &&
      filterConditions?.danhSachKhachHang.length > 0
    ) {
      setVisible(true);
    }
  };

  const handleNgayCatDien = (values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      NgayCatDien: values,
    }));
  };
  const handleChangeGio = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      Gio: parseInt(e.target.value),
    }));
  };
  const handleChangePhut = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      Phut: parseInt(e.target.value),
    }));
  };
  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <Spin spinning={loading}>
            <Form
              layout="vertical"
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <FillterTinNhan
                data={dataDoiTuong}
                setData={setDataDoiTuong}
                filterConditions={filterDebounce}
                setFilterConditions={setFilterConditions}
                handleChange={handleChange}
                form={form}
                handleChangeToDoi={handleChangeToDoi}
                onChange={onChange}
                onChangeHinhThuc={onChangeHinhThuc}
                onChangeTrangThai={onChangeTrangThai}
                onChangeNgayKiemTra={onChangeNgayKiemTra}
                onChangeNgayThayCT={onChangeNgayThayCT}
                id={id}
                onChangeKy={onChangeKy}
                setId={setId}
                handleChangeMaSo={handleChangeMaSo}
                queryMaSo={queryMaSo}
                handleOpenPdf={handleOpenPdf}
                setLoading={setLoading}
                openWarning={openWarning}
                handleNgayCatDien={handleNgayCatDien}
                handleChangeGio={handleChangeGio}
                handleChangePhut={handleChangePhut}
                hinhThucNhanTin={NAME_HINHTHUC_SMS}
                isDisable={isDisable} 
                setIsDisable={setIsDisable}
              />
              <Divider className="section-text" orientation="left"></Divider>
              {danhSachDoiTuong.includes(id) &&
              filterConditions.maso &&
              filterConditions.maso.length > 0 ? (
                <Table
                  sticky={true}
                  filterConditions={filterConditions}
                  dataSource={dataTable}
                  columns={columns}
                  pagination={false}
                  rowSelection={rowSelection}
                  rowKey="id"
                  bordered
                  loading={loading}
                  scroll={{
                    x: true,
                    y: "70vh",
                  }}
                />
              ) : (
                <Table
                  sticky={true}
                  filterConditions={filterConditions}
                  dataSource={null}
                  columns={columns}
                  pagination={false}
                  rowSelection={rowSelection}
                  rowKey="id"
                  bordered
                  loading={loading}
                  scroll={{
                    x: true,
                    y: "70vh",
                  }}
                />
              )}
              <Modal
                visible={visible}
                title="Xác nhận gửi tin nhắn"
                onCancel={onCancel}
                width={"60%"}
                onOk={handleConfirm}
                okText="Xác nhận"
              >
                <div style={{ color: "red" }}>
                  Hiện tại không trong thời gian được hỗ trợ xác nhận gửi ?
                </div>
              </Modal>
            </Form>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
  .ant-form-item {
    margin: 0 !important;
  }
  .ant-col-24 {
    padding-bottom: 4px;
  }
  .FillterTrangThai {
    .ant-select-selector {
      height: 30px;
    }
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
