import React, { useEffect, useState, useCallback } from "react";
import { Select } from "antd";
import { authGetData } from "@utils/request";
import { removeAccents } from "@utils/function";
import { isEmpty } from "lodash";
const { Option } = Select;
const SelectionSo = (props) => {
  const { url, placeholder = "Tất cả", form, formKey, mode, setValue, isDisable, setIsDisable } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(typeof url === "string" ? [] : url);
  const [visibleSelect, setVisibleSelect] = useState(false);

  const [valueSelect, setValueSelect] = useState(form.getFieldValue(formKey));

  useEffect(() => {
    setValueSelect(form.getFieldValue(formKey));
  }, [form.getFieldValue(formKey)]);

  const getData = useCallback(() => {
    authGetData({
      url,
      setLoading,
      onSuccess: (res) => {
        if (res.length > 0) setData(res);
        else setData(res.data);
      },
    });
  }, [url]);

  useEffect(() => {
    if (url && typeof url === "string") {
      getData();
    }
  }, [getData, url]);
  const handleChange = (value) => {
    if (value.includes("all")) {
      const listSoNotFull = data.filter((item) => !item.isFull);
      const listSoNotFullIds = listSoNotFull.map((item) => item.value);
      visibleSelect ? setValueSelect(listSoNotFullIds) : setValue(listSoNotFullIds);
      form.setFieldsValue({
        [formKey]: listSoNotFullIds,
      });
      setIsDisable(true);
    } else {
      visibleSelect ? setValueSelect(value) : setValue(value);
      form.setFieldsValue({
        [formKey]: value,
      });
      setIsDisable(false);
    }
  };

  const handleBlur = () => {
    setValue(valueSelect);
  };

  return (
    <Select
      onDropdownVisibleChange={(open) => setVisibleSelect(open)}
      maxTagCount={mode === "multiple" || "tags" ? "responsive" : null}
      value={valueSelect}
      placeholder={placeholder}
      showSearch
      disabled={loading}
      allowClear
      mode={mode}
      loading={loading}
      onChange={handleChange}
      onBlur={handleBlur}
      style={{ width: "100%" }}
      filterOption={(input, option) => {
        if (option && option.children && option.children?.props?.children) {
          return removeAccents(option.children.props.children).toLowerCase().indexOf(removeAccents(input).toLowerCase()) >= 0;
        }
        return removeAccents(option.children).toLowerCase().indexOf(removeAccents(input).toLowerCase()) >= 0;
      }}
    >
      {data && !isEmpty(data) ? (
        <Option key="all" value="all">
          Tất cả
        </Option>
      ) : null}

      {data && !isEmpty(data)
        ? data.map((item, idx) => (
            <Option key={idx} value={item.value.toString()} disabled={isDisable}>
              <span style={{ color: item.isFull ? "#2596be" : "black" }}>{item.name}</span>
            </Option>
          ))
        : undefined}
    </Select>
  );
};

export default SelectionSo;
