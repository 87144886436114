import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  overflow: hidden;
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
  .ant-picker {
    width: 50%;
  }
  .ant-select {
    width: 50%;
  }
`;

export const ContentWrapper = styled.div`
  padding: 0px 16px;
  height: 100%;
  overflow: hidden;
`;

export const TableStyled = styled.div`
  height: calc(100vh - 270px);
  margin-top: 10px;
  .ant-table-wrapper {
    height: 100%;
    .ant-spin-nested-loading {
      height: 100%;
      .ant-spin-container {
        height: 100%;
        .ant-table.ant-table-fixed-header {
          height: 100%;
          .ant-table-container {
            height: 100%;
            .ant-table-thead {
              .ant-table-cell {
                background: #d9d9d9;
                color: #1f1f1f;
                font-size: 13px;
              }
            }
            .ant-table-body {
              max-height: calc(100% - 58px) !important;
            }
          }
        }
      }
    }
  }
`;
