import { FORMAT_DATE, FORMAT_ENGLISH } from "@utils/constants";
import { alertMessage, removeAccents } from "@utils/function";
import {
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  DatePicker,
} from "antd";
import { Fragment, useEffect, useState } from "react";
import { CustomRow } from "./css-styled";
import moment from "moment";

const { Option } = Select;

const arrLoaiBienBan = [
  {
    value: 0,
    name: "Treo mới",
  },
  {
    value: 1,
    name: "Thay thế định kỳ ",
  },
  {
    value: 2,
    name: "Thay thế cháy hỏng",
  },
  {
    value: 3,
    name: "Tháo thu hồi",
  },
  // {
  //   value: 4,
  //   name: "Nâng công suất, Di dời",
  // },
];

const arrViTriTreo = [
  {
    value: 2,
    name: "Trong nhà",
  },
  {
    value: 1,
    name: "Trên tường ngoài nhà ",
  },
  {
    value: 0,
    name: "Tại cột số",
  },
];

export default function Index(props) {
  const {
    detail,
    dataLyDo,
    getDataLyDo,
    form,
    // detailCTTreo,
    detailCTThao,
    setIsResetIsThao,
    isResetIsTUTI,
    setIsResetIsTUTI,
    // defaultCTTreo,
    setSoCot,
    dataDetaiCTTreolUpdate,
    setChangeTab1,
    doingChange,
    setNgay,
    ngay,
  } = props;
  const [isCheck1, setIsCheck1] = useState(
    detail?.isTreoThaoTUTI ? detail?.isTreoThaoTUTI : false
  );
  const [isCheck2, setIsCheck2] = useState(
    detail?.isDoXa ? detail?.isDoXa : false
  );
  const [isRequired, setIsRequired] = useState(false);
  const [valueLoaiBienBan, setValueLoaiBB] = useState(
    detail && detail.loaiBienBan ? detail.loaiBienBan.toString() : null
  );
  const [oldValueLoaiBB, setOldValueLoaiBB] = useState(
    detail && detail.loaiBienBan ? detail.loaiBienBan.toString() : null
  );

  useEffect(() => {
    if (isResetIsTUTI) {
      form.setFieldsValue({
        isTreoThaoTUTI: false,
      });
      if (detail?.isTreoThaoTUTI === isCheck1) setChangeTab1(false);
      else setChangeTab1(true);
      setIsCheck1(false);
      setIsResetIsTUTI(false);
    }
  }, [
    detail?.isTreoThaoTUTI,
    form,
    isCheck1,
    isResetIsTUTI,
    setChangeTab1,
    setIsResetIsTUTI,
  ]);

  useEffect(() => {
    if (isCheck1 === detail?.isTreoThaoTUTI && doingChange === false)
      setChangeTab1(false);
    else setChangeTab1(true);
  }, [detail?.isTreoThaoTUTI, doingChange, isCheck1, setChangeTab1]);
  // const [dataCTTreo, setDataCTTreo] = useState(detailCTTreo);

  //change input
  const onChange = (e, viTri) => {
    if (viTri === 1) {
      if (e.target.checked) {
        if (
          dataDetaiCTTreolUpdate.maCongTo !== "" &&
          dataDetaiCTTreolUpdate.maCongTo !== null &&
          detailCTThao &&
          !form.getFieldValue("isThaoCongTo")
        ) {
          Modal.warning({
            centered: true,
            title: "Xác nhận",
            content:
              "Thông tin công tơ treo mới đã được đăng ký, bạn hãy xóa thông tin công tơ treo mới trước khi chọn [Treo tháo TU/TI chốt chỉ số?",
            onOk() {
              // setIsCheck1(false);
              // form.setFieldsValue({
              //   isTreoThaoTUTI: false,
              //   isThaoCongTo: form.getFieldValue("isThaoCongTo"),
              // });
              // setDataCTTreo({
              //   ...dataCTTreo,
              // });
            },
            okText: "Đồng ý",
            okButtonProps: { type: "primary" },
          });
        } else if (
          (detailCTThao && detailCTThao.isThaoCongTo) ||
          (detailCTThao && form.getFieldValue("isThaoCongTo"))
        ) {
          Modal.confirm({
            centered: true,
            title: "Xác nhận",
            content:
              "Chọn [Treo tháo TU/TI chốt chỉ số] thì không được phép chọn [Chỉ tháo công tơ]. Hệ thống sẽ tự động xử lý không chọn [Chỉ tháo công tơ]?",
            onOk() {
              form.setFieldsValue({
                isTreoThaoTUTI: true,
                isThaoCongTo: false,
              });
              if (detail?.isTreoThaoTUTI === e.target.checked) {
                setChangeTab1(true);
              }

              setIsResetIsThao(true);
              setIsCheck1(true);
            },
            onCancel() {
              form.setFieldsValue({
                isTreoThaoTUTI: false,
                isThaoCongTo: form.getFieldValue("isThaoCongTo"),
              });
              setIsResetIsThao(false);
              setIsCheck1(false);
            },
            okText: "Đồng ý",
            okButtonProps: { type: "primary" },
            cancelText: "Hủy",
          });
        } else {
          form.setFieldsValue({
            isTreoThaoTUTI: true,
          });
          setIsCheck1(true);
        }
      } else {
        form.setFieldsValue({
          isTreoThaoTUTI: false,
        });
        setIsCheck1(false);
      }
    } else {
      setIsCheck2(e.target.checked);
      form.setFieldsValue({
        isDoXa: e.target.checked,
      });
    }
  };

  //change vị trí treo
  const handleChangeViTriTreo = (value) => {
    if (value === "0") {
      setIsRequired(true);
    } else {
      setIsRequired(false);
      form.setFields([
        {
          name: ["soCot"],
          errors: false,
        },
      ]);
    }
  };

  //set form field
  useEffect(() => {
    form.setFieldsValue({
      loaiBienBan: detail?.loaiBienBan.toString(),
      lyDoTreoThaoId: detail?.lyDoTreoThaoId,
      viTriTreoThao: detail?.viTriTreoThao
        ? detail?.viTriTreoThao.toString()
        : "0",
      soCot: detail?.soCot,
      soHop: detail?.soHop,
      hsn: detail?.hsn,
      maChiHop: detail?.maChiHop,
      isDoXa: detail?.isDoXa,
      isTreoThaoTUTI: detail?.isTreoThaoTUTI,
      gioDoXa: detail?.gioDoXa,
      phutDoXa: detail?.phutDoXa,
      hesid: detail?.hesid,
      gatewayID: detail?.gatewayID,
      maChiBOOC: detail?.maChiBOOC ? detail?.maChiBOOC : null,
      soVienChiBOOC: detail?.soVienChiBOOC ? detail?.soVienChiBOOC : null,
      soVienChiHop: detail?.soVienChiHop ? detail?.soVienChiHop : null,
    });
  }, [detail, form]);
  useEffect(() => {
    if (
      form.getFieldValue("viTriTreoThao") === 0 ||
      form.getFieldValue("viTriTreoThao") === "0"
    )
      setIsRequired(true);
    else setIsRequired(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Thay đổi loại biên bản
  const handleChangeLoaiBB = (value) => {
    let validate = false;
    if (
      detailCTThao === null ||
      (detailCTThao && detailCTThao.maCongTo === null)
    ) {
      if (value === "0" || value === 0) {
        validate = false;
        setValueLoaiBB(value);
        setOldValueLoaiBB(value);
        form.setFieldsValue({
          loaiBienBan: value,
        });
      } else {
        alertMessage(
          "error",
          "Thông báo!",
          "Không có công tơ đang treo. Chỉ cho phép chọn loại biên bản [Treo mới]"
        );
        validate = true;
        setValueLoaiBB(oldValueLoaiBB);
        setOldValueLoaiBB(oldValueLoaiBB);
        form.setFieldsValue({
          loaiBienBan: oldValueLoaiBB,
        });
      }
    } else if (value === "3" || value === 3) {
      if (
        (dataDetaiCTTreolUpdate.maCongTo !== null &&
          !form.getFieldValue("isTreoThaoTUTI")) ||
        form.getFieldValue("isNangHaTI") ||
        form.getFieldValue("isNangHaTU")
      ) {
        alertMessage(
          "error",
          "Thông báo!",
          "Thông tin thiết bị (Công tơ/TU/TI) treo mới đã đăng ký, bạn hãy xóa các thông tin này trước khi thay đổi loại biên bàn là [Tháo thu hồi]"
        );
        validate = true;
        setValueLoaiBB(oldValueLoaiBB);
        setOldValueLoaiBB(oldValueLoaiBB);
        form.setFieldsValue({
          loaiBienBan: oldValueLoaiBB,
        });
      } else {
        validate = false;
        setValueLoaiBB(value);
        setOldValueLoaiBB(value);
        form.setFieldsValue({
          loaiBienBan: value,
        });
      }
    }
    if (!validate) {
      form.setFieldsValue({
        lyDoTreoThaoId: null,
      });
      getDataLyDo(value);
    }

    // if (value === "3") {
    //   if (dataDetaiCTTreolUpdate.maCongTo === null) {
    //     alertMessage(
    //       "error",
    //       "Thông báo",
    //       'Đang'
    //     );
    //   }
    // }
  };

  const handleChangeDate = (date) => {
    let convert = "";
    if (date === null) convert = null;
    else convert = moment(date).format(FORMAT_ENGLISH);
    setNgay(convert);
  };

  return (
    <Fragment>
      <Row gutter={24} style={{ padding: "8px" }}>
        <Col span={24} md={8}>
          <Form.Item
            label="Loại biên bản"
            name="loaiBienBan"
            rules={[{ required: true, message: "Không được bỏ trống" }]}
          >
            <Select
              placeholder="Chọn loại biên bản"
              defaultValue={detail.loaiBienBan.toString()}
              value={valueLoaiBienBan}
              onChange={handleChangeLoaiBB}
              disabled={
                detail?.loaiBienBan === null ||
                detail?.loaiBienBan === undefined
              }
            >
              {arrLoaiBienBan.map((item, index) => (
                <Option key={index} value={item.value.toString()}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={8}>
          <Form.Item
            label="Lý do treo tháo"
            name="lyDoTreoThaoId"
            rules={[{ required: true, message: "Không được bỏ trống" }]}
          >
            <Select
              placeholder="Nhập lý do treo tháo"
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
              showSearch
            >
              {dataLyDo && dataLyDo.length > 0
                ? dataLyDo.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.maLyDo + " - " + item.tenLyDo}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={8}>
          <Form.Item
            label="Vị trí treo"
            name="viTriTreoThao"
            rules={[{ required: true, message: "Không được bỏ trống" }]}
          >
            <Select
              placeholder="Chọn vị trí treo"
              onChange={handleChangeViTriTreo}
            >
              {arrViTriTreo.map((item, index) => (
                <Option key={index} value={item.value.toString()}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            label="Số cột"
            name="soCot"
            rules={[
              { required: isRequired, message: "Không được bỏ trống" },
              () => ({
                validator(_, value) {
                  if (parseInt(value) < 0) {
                    return Promise.reject(
                      `Số cột nhập phải lớn hơn hoặc bằng 0`
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              placeholder="Nhập số cột"
              onChange={(e) => setSoCot(e.target.value)}
              disabled={!isRequired}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            label="Số hộp"
            name="soHop"
            rules={[
              () => ({
                validator(_, value) {
                  if (parseInt(value) < 0) {
                    return Promise.reject(
                      `Số hộp nhập phải lớn hơn hoặc bằng 0`
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Nhập số hộp" type="number" />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            label="HSN điểm đo"
            name="hsn"
            rules={[
              { required: true, message: "Không được bỏ trống" },
              () => ({
                validator(_, value) {
                  const re = /^[0-9\b]+$/;
                  if (re.test(value)) {
                    if (parseInt(value) <= 0) {
                      return Promise.reject(`Hệ số nhân nhập phải lớn hơn 0`);
                    }
                  } else if (value.indexOf(".")) {
                    if (parseFloat(value) <= 0) {
                      return Promise.reject(`Hệ số nhân nhập phải lớn hơn 0`);
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Nhập HSN điểm đo" />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item label="Ngày treo tháo" name="ngayTreoThaoTB">
            <DatePicker
              defaultValue={moment(ngay, FORMAT_DATE)}
              format={FORMAT_DATE}
              onChange={handleChangeDate}
              allowClear={false}
            />
          </Form.Item>
        </Col>
        <CustomRow span={24} md={6}>
          <Form.Item label="Chì hộp/Số viên" name="maChiHop">
            <Input placeholder="Nhập mã chì hộp" />
          </Form.Item>
        </CustomRow>
        <Col
          span={24}
          md={6}
          style={{
            alignItems: "center",
            display: "flex",
            marginTop: "2px",
          }}
        >
          <Form.Item
            label=""
            name="soVienChiHop"
            style={{ margin: 0, width: "100%" }}
            rules={[
              () => ({
                validator(_, value) {
                  if (parseInt(value) < 0) {
                    return Promise.reject(
                      `Số viên chì hộp nhập phải lớn hơn hoặc bằng 0`
                    );
                  } else if (parseInt(value) >= 100) {
                    return Promise.reject(
                      `Số viên chì hộp nhập phải bé hơn 100`
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Nhập số viên chì hộp" type="number" />
          </Form.Item>
        </Col>
        <CustomRow span={24} md={6} className="after-icon">
          <Form.Item label="Chì booc/Số viên" name="maChiBOOC">
            <Input placeholder="Nhập mã chì BOOC" />
          </Form.Item>
        </CustomRow>

        <Col
          span={24}
          md={6}
          style={{
            alignItems: "center",
            display: "flex",
            marginTop: "2px",
          }}
        >
          <Form.Item
            label=""
            name="soVienChiBOOC"
            style={{ margin: 0, width: "100%" }}
            rules={[
              () => ({
                validator(_, value) {
                  if (parseInt(value) < 0) {
                    return Promise.reject(
                      `Số viên chì BOOC nhập phải lớn hơn hoặc bằng 0`
                    );
                  } else if (parseInt(value) >= 100) {
                    return Promise.reject(
                      `Số viên chì BOOC nhập phải bé hơn 100`
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Nhập số viên chì BOOC" type="number" />
          </Form.Item>
        </Col>
        <Col
          span={24}
          md={12}
          style={{
            alignItems: "center",
            display: "flex",
            marginTop: "2px",
            marginBottom: "16px",
          }}
        >
          <Form.Item label="" name="isDoXa" style={{ margin: 0 }}>
            <Checkbox onChange={(e) => onChange(e, 2)} checked={isCheck2}>
              Thông tin đo xa
            </Checkbox>
          </Form.Item>
        </Col>
        <Col
          span={24}
          md={12}
          style={{
            alignItems: "center",
            display: "flex",
            marginTop: "2px",
            marginBottom: "16px",
          }}
        >
          <Form.Item label="" name="isTreoThaoTUTI" style={{ margin: 0 }}>
            <Checkbox onChange={(e) => onChange(e, 1)} checked={isCheck1}>
              Treo tháo TU/TI chốt chỉ số
            </Checkbox>
          </Form.Item>
        </Col>

        <Col span={24} md={6}>
          <Form.Item label="Giờ" name="gioDoXa">
            <Input
              type="number"
              min={0}
              max={23}
              disabled={!isCheck2}
              placeholder="Nhập giờ"
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item label="Phút" name="phutDoXa">
            <Input
              type="number"
              min={0}
              max={59}
              disabled={!isCheck2}
              placeholder="Nhập phút"
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item label="HESID" name="hesid">
            <Input disabled={!isCheck2} placeholder="Nhập HESID" />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item label="Gateway ID" name="gatewayID">
            <Input disabled={!isCheck2} placeholder="Nhập Gateway ID" />
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  );
}
