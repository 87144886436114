import React, { useState } from "react";
import { Button, Col, Modal, Row } from "antd";
import LoadingComponent from "@components/Loading";
import { Container } from "./css-styled";
import KySoComponent from "@components/KySo";
import { Endpoint } from "@utils/endpoint";
import { cap_dien } from "@permissions/a_capdien";
import { ENUM_KYSO } from "@utils/constants";

export default function Index(props) {
  const {
    loadingQuyetToanHSM,
    visible,
    closeModal,
    handleTuChoiKy,
    loadingTuChoi,
    handleQuyetToanHSM,
    t,
    detail,
    setFetchDataTable,
  } = props;
  const [loading, setLoading] = useState(false);
  return (
    <LoadingComponent loading={loadingQuyetToanHSM || loading || loadingTuChoi}>
      <Modal
        title={"Lãnh đạo ký duyệt"}
        visible={visible}
        onCancel={closeModal}
        footer={null}
        width={650}
      >
        <Container>
          <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
            Hồ sơ của bạn đã hoàn tất quyết toán. Xác nhận Ký quyết toán sẽ hoàn
            tất quá trình xử lý hồ sơ ! Từ chối ký sẽ trả lại hồ sơ để thực liện
            lại việc Quyết toán !
          </h4>
          <Row gutter={24} style={{ marginTop: "48px", textAlign: "center" }}>
            {/* <Col span={24} md={12}>
            <Button key="back" onClick={closeModal} className="button-closed">
              {t("button.dong_lai")}
            </Button>
          </Col> */}
            <Col
              span={24}
              xs={12}
              sm={7}
              md={7}
              lg={7}
              style={{ marginBottom: "5px" }}
            >
              <KySoComponent
                url={Endpoint.LANH_DAO_XAC_THUC_USB}
                urlGetHash={Endpoint.LANH_DAO_DUYET_QUYET_TOAN_USB}
                payload={{ hoSoId: detail?.id }}
                permissionCode={cap_dien.a72k}
                disabled={false}
                setFetchDataTable={setFetchDataTable}
                text={t("button.ky_quyet_toan_usb")}
                setLoading={setLoading}
                loading={loading}
                typeKySo={ENUM_KYSO.CAPDIEN}
                widthBtn={"100%"}
              />
              {/* <KySoComponent
                url={Endpoint.LANH_DAO_DUYET_QUYET_TOAN_USB}
                payload={{ hoSoId: detail?.id }}
                permissionCode={cap_dien.a72k}
                disabled={false}
                text={t("button.ky_quyet_toan_usb")}
                setFetchDataTable={setFetchDataTable}
              /> */}
            </Col>
            <Col
              span={24}
              xs={12}
              sm={7}
              md={7}
              lg={7}
              style={{ marginBottom: "5px" }}
            >
              <Button
                type="primary"
                onClick={handleQuyetToanHSM}
                loading={loadingQuyetToanHSM}
                key="hsm"
                style={{ width: "100%" }}
              >
                {t("button.ky_quyet_toan_hsm")}
              </Button>
            </Col>{" "}
            <Col
              span={24}
              xs={12}
              sm={6}
              md={6}
              lg={6}
              style={{ marginBottom: "5px" }}
            >
              <Button
                type="primary"
                className="btn-bg-yellow"
                onClick={handleTuChoiKy}
                loading={loadingTuChoi}
                style={{ width: "100%" }}
              >
                {t("button.tu_choi_ky")}
              </Button>
            </Col>{" "}
            <Col
              span={24}
              xs={12}
              sm={4}
              md={4}
              lg={4}
              style={{ marginBottom: "5px" }}
            >
              <Button
                type="default"
                className=""
                onClick={closeModal}
                style={{ width: "100%" }}
              >
                Đóng lại
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal>
    </LoadingComponent>
  );
}
