import LoadingComponent from "@components/Loading";
import { Modal, Button, notification } from "antd";
import { memo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SignaturePad from "react-signature-canvas";
import { handleUploadImage } from "../services";

function Index(props) {
  const { visible, closeModal, handleKySo } = props;
  const [urImage, setUrImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const sigCanvas = useRef({});
  const dispatch = useDispatch();
  const clear = () => {
    sigCanvas.current.clear();
    setUrImage(null);
  };

  const save = () => {
    if (!sigCanvas.current.isEmpty()) {
      const url = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      setUrImage(url);
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          handleUpload(file);
        });
    } else {
      notification.error({
        message: `Thông báo`,
        description: "Vui lòng vẽ chữ ký trước khi lưu",
        duration: 3,
      });
      setUrImage(null);
    }
  };

  const handleUpload = (file) => {
    handleUploadImage(setLoading, file, closeModal, dispatch, handleKySo);
  };

  return (
    <LoadingComponent loading={false}>
      <Modal
        title="Tạo chữ ký"
        visible={visible}
        onCancel={closeModal}
        className="modal"
        footer={null}
      >
        <div style={{ textAlign: "center" }}>
          <SignaturePad
            ref={sigCanvas}
            penColor="black"
            // dotSize={0.1}
            // velocityFilterWeight={1}
            // throttle={1}
            canvasProps={{
              className: "signatureCanvas",
              backgroundColor: "#ffffff",
              width: 150,
              height: 80,
            }}
          />
          <div style={{ marginTop: "24px" }}>
            <Button type="default" className="mr-24px" onClick={closeModal}>
              Đóng lại
            </Button>
            <Button
              type="default"
              className="mr-24px btn-bg-yellow"
              onClick={clear}
            >
              Reset chữ ký
            </Button>
            <Button type="primary" onClick={save} loading={loading}>
              Lưu chữ ký
            </Button>
          </div>
        </div>

        {urImage && urImage !== null ? (
          <img
            src={urImage}
            alt="Sign"
            style={{
              display: "block",
              width: "150px",
              minHeight: "50px",
              border: "1px solid #000",
            }}
          />
        ) : null}
      </Modal>
    </LoadingComponent>
  );
}
export default memo(Index);
