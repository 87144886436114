import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
import { alertMessage422 } from "../service";

export const selectBCTT = [
  {
    label:
      "1. Tổng hợp thông tin các điểm đo khách hàng chuyển dùng theo phiên ghi chỉ số",
    value: Endpoint.BCTT_TH_CAC_DIEM_DO_KHCD_THEO_PHIEN_GCS,
  },
  {
    label:
      "2. Tổng hợp thông tin các điểm đo đầu nguồn trạm biến áp công cộng theo phiên ghi chỉ số",
    value: Endpoint.BCTT_TT_CAC_DIEM_DO_DAU_NGUON_TBACC_THEO_PHIEN_GCS,
  },
  {
    label: "3. Báo cáo thương phẩm",
    value: Endpoint.BCTT_BC_THUONG_PHAM,
  },
];

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LoaiYeuCau: data.LoaiYeuCau,
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
    })
  );
  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
