import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useEffect,
  useMemo,
} from "react";
import Filter from "@components/Filter/Filter";
import { TableStyled, Container } from "./css-styled";
import { Form, Modal, Tag, Tooltip, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  // FORMAT_MONTH_ENGLISH,
  ENUM_MUCDICH_SDD,
  AP_GIA_TRANG_THAI,
} from "@utils/constants";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilter } from "../../common/list-bo-loc";
import CheckPermission from "@components/CheckPermission";
import { ap_gia } from "@permissions/e_apgia";
import TableComponent from "@components/TableComponent";
import {
  handleListBKNVXacNhan,
  submitNhanVienXacNhan,
  submitKiemTraLai,
} from "./services";
import { openModal } from "@state/system-config/reducer";
import ChiTietDiemDo from "../../common/chi-tiet-diem-do";
import ButtonComponent from "@components/Button";
import KyDuyetModal from "./modal-ky-duyet";
// import moment from "moment";
import { InfoCircleOutlined, FilePdfOutlined, FileOutlined } from "@ant-design/icons";

export default function Index() {
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [listBKNVXacNhan, setListBKNVXacNhan] = useState([]);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [biTraLai, setBiTraLai] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const location = useLocation();
  const [visibleKy, setVisibleKy] = useState(false);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    // ThangKiemTra: moment().format(FORMAT_MONTH_ENGLISH),
    // LoaiKhachHang: ENUM_MUCDICH_SDD.SINHHOAT,
    ...parseParams(location.search),
    TrangThai: AP_GIA_TRANG_THAI.MTB_DA_GUI_DL,
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu
  const getListBKNVXacNhan = useCallback(() => {
    handleListBKNVXacNhan(
      setLoading,
      location,
      setListBKNVXacNhan,
      setTotalCount,
      filterConditions,
      setSelectedRowKeys
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getListBKNVXacNhan();
  }, [getListBKNVXacNhan]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListBKNVXacNhan();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListBKNVXacNhan, setFetchDataTable]);

  const onChangePagination = (paging, _filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setSelectedRowKeys([]);
  };

  //tìm kiếm dữ liệu
  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        pageIndex: DEFAULT_PAGEINDEX,
        ...values,
        BiTraLai: biTraLai ? biTraLai : null,
        Tram: values.Tram
          ? Array.isArray(values.Tram) && values.Tram.length > 0
            ? values.Tram.join()
            : values.Tram
          : [],
      }));
    },
    [biTraLai]
  );

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      TrangThai: AP_GIA_TRANG_THAI.MTB_DA_GUI_DL,
      // ThangKiemTra: moment().format(FORMAT_MONTH_ENGLISH),
      // LoaiKhachHang: ENUM_MUCDICH_SDD.SINHHOAT,
    });
  }, [user.unitId]);

  const detailDiemDo = (record) => {
    return dispatch(
      openModal({
        content: <ChiTietDiemDo detailRecord={record} />,
        size: "large",
        title: `Chi tiết điểm đo - ${record.maDiemDo}`,
      })
    );
  };

  const detailDD = (record) => {
    return {
      onDoubleClick: () => {
        detailDiemDo(record);
      },
    };
  };

  const checkTraLai = (e) => {
    setBiTraLai(e.target.checked);
  };

  const columns = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => detailDiemDo(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
      fixed: "left",
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      key: "maKhachHang",
      render: (text) => <span>{text}</span>,
      width: "10%",
      sorter: true,
      fixed: "left",
    },
    {
      title: "Tên KH",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => <span>{text}</span>,
      width: "12%",
      sorter: true,
    },
    {
      title: "Điện thoại",
      dataIndex: "soDienThoai",
      key: "soDienThoai",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: "Trước kiểm tra",
      children: [
        {
          title: "Ngày áp dụng",
          dataIndex: "ngayApDung",
          key: "ngayApDung",
          render: (text) => (
            <div className="table-text-center">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
        {
          title: "Số hộ",
          dataIndex: "soHo",
          key: "soHo",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
        },
        {
          title: "Chuỗi giá",
          dataIndex: "chuoiGia",
          key: "chuoiGia",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
      ],
    },
    {
      title: "Sau kiểm tra",
      children: [
        {
          title: "Ngày áp dụng",
          dataIndex: "ngayApDungMoi",
          key: "ngayApDungMoi",
          render: (text) => (
            <div className="table-text-center">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
        {
          title: "Số hộ",
          dataIndex: "soHoMoi",
          key: "soHoMoi",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
        },
        {
          title: "Chuỗi giá",
          dataIndex: "chuoiGiaMoi",
          key: "chuoiGiaMoi",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
      ],
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text, record) => (
        <>
          {record.trangThai?.toString() === AP_GIA_TRANG_THAI.MTB_DA_GUI_DL ? (
            <Tag
              style={{ display: "block", textAlign: "center" }}
              color={"#26c0e9"}
            >
              {"Chưa xác nhận"}
            </Tag>
          ) : (
            <Tag
              style={{ display: "block", textAlign: "center" }}
              color={"#faad14"}
            >
              {"Đã xác nhận"}
            </Tag>
          )}
          {record.biTraLai ? (
            <div
              className="table-text-center"
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: "#FF5247",
                cursor: "pointer",
              }}
            >
              <Tooltip
                overlayStyle={{ whiteSpace: "pre-line" }}
                title={`Lý do: ${record.lyDoTraLai}
                Thời gian: ${record.thoiGianTraLai}`}
              >
                <InfoCircleOutlined /> Bị trả lại
              </Tooltip>
            </div>
          ) : null}
        </>
      ),
      width: "8%",
    },
    // {
    //   title: "Tháng kiểm tra",
    //   dataIndex: "thangKiemTra",
    //   key: "thangKiemTra",
    //   render: (text) => (
    //     <div className="table-text-center">
    //       <span>{text}</span>
    //     </div>
    //   ),
    //   width: "7%",
    // },
    {
      title: "Loại khách hàng",
      dataIndex: "loaiKhachHang",
      key: "loaiKhachHang",
      render: (text, record) =>
        record.loaiKhachHang?.toString() === ENUM_MUCDICH_SDD.NGOAISINHHOAT ? (
          <span>{"Ngoài sinh hoạt"}</span>
        ) : (
          <span>{"Sinh hoạt"}</span>
        ),
      width: "8%",
    },
    {
      title: "Trạm",
      dataIndex: "tram",
      key: "tram",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: "Sổ GCS",
      dataIndex: "soGCS",
      key: "soGCS",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => <span>{text}</span>,
      width: "16%",
    },
    {
      title: "Mã bảng kê",
      dataIndex: "maBangKe",
      key: "maBangKe",
      render: (text) => <span>{text}</span>,
      width: "9%",
    },
    {
      title: "Tác vụ",
      dataIndex: "",
      key: "tacVu",
      render: (text, record) => (
        <div className="table-text-center">
          {record.pathFileBienBan ? (
            <CheckPermission permissionCode={ap_gia.e14x}>
              <Tooltip title="Xem biên bản">
                <a
                  href={record.pathFileBienBan}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FilePdfOutlined
                    style={{ fontSize: "22px" }}
                  />
                </a>
              </Tooltip>
            </CheckPermission>
          ) : null}
          {record.pathFileBienBanGiay ?
            <CheckPermission permissionCode={ap_gia.e14x}>
              <Tooltip title="Xem BBAG giấy">
                <a
                  href={record.pathFileBienBanGiay}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FileOutlined
                    style={{ fontSize: "22px", marginLeft: '10px' }}
                  />
                </a>
              </Tooltip>
            </CheckPermission> : null}
        </div>
      ),
      width: "4%",
      fixed: "right"
    },
  ];

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: "3%",
    fixed: "left",
    getCheckboxProps: (record) => ({
      disabled:
        record.trangThai?.toString() === AP_GIA_TRANG_THAI.NVAG_DA_XAC_NHAN,
    }),
  };
  // open modal ky duyet
  const handleOpenModalKy = useCallback(() => {
    setVisibleKy(true);
  }, []);

  const handleCloseModalKy = () => {
    setVisibleKy(false);
    setLoading(false);
  };

  const handleKiemTraLai = () => {
    const filteredArray = listBKNVXacNhan.filter(function (itm) {
      return selectedRowKeys.indexOf(itm.id) > -1 && (itm.maBangKe === null || itm.maBangKe === undefined);
    });
    if (
      filteredArray.length < selectedRowKeys.length && filteredArray.length !== 0
    ) {
      Modal.confirm({
        centered: true,
        title: "Xác nhận",
        content: `Có yêu cầu đã tạo được tạo bảng kê được chọn. Cần thực hiện hủy bảng kê rồi thực hiện kiểm tra lại.
        Ấn Tiếp tục để xử lý kiểm tra lại cho các yêu cầu chưa tạo bảng kê`,
        onOk() {
          //call api
          submitKiemTraLai(
            filteredArray.map(item => item.id),
            setLoading,
            setFetchDataTable,
            setSelectedRowKeys
          );
        },
        onCancel() { },
        okText: "Tiếp tục",
        cancelText: "Hủy",
      });
    } else if (filteredArray.length === 0) {
      notification.error({
        message: `Thông báo`,
        description:
          "Vui lòng chọn yêu cầu chưa tạo bảng kê!",
      });
    } else {
      Modal.confirm({
        centered: true,
        title: "Xác nhận",
        content: `Bạn chắc chắn thực hiện kiểm tra áp giá lại các yêu cầu đã chọn?`,
        onOk() {
          //call api
          submitKiemTraLai(
            selectedRowKeys,
            setLoading,
            setFetchDataTable,
            setSelectedRowKeys
          );
        },
        onCancel() { },
        okText: "Đồng ý",
        cancelText: "Hủy",
      });
    }
  };

  const genExtra = useCallback(
    () => (
      <div>
        <CheckPermission permissionCode={ap_gia.e14n}>
          <ButtonComponent
            type="primary"
            style={{ marginRight: "10px" }}
            onClick={() => handleOpenModalKy()}
            disabled={selectedRowKeys.length === 0}
          >
            Xác nhận
          </ButtonComponent>
        </CheckPermission>
        <CheckPermission permissionCode={ap_gia.e14k}>
          <ButtonComponent
            type="primary"
            style={{ marginRight: "10px" }}
            onClick={() => handleKiemTraLai()}
            disabled={selectedRowKeys.length === 0}
          >
            Khảo sát lại
          </ButtonComponent>
        </CheckPermission>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRowKeys]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFinish = () => {
    submitNhanVienXacNhan(
      setLoading,
      setFetchDataTable,
      selectedRowKeys,
      setSelectedRowKeys,
      handleCloseModalKy
    );
  };

  const showModalKy = useMemo(
    () =>
      visibleKy && (
        <KyDuyetModal
          form={form}
          visible={visibleKy}
          onFinish={onFinish}
          closeModal={handleCloseModalKy}
          setFetchDataTable={setFetchDataTable}
        />
      ),
    [form, onFinish, visibleKy]
  );
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          screen={"NVDuyet"}
          checkTraLai={checkTraLai}
        />
      </Filter>
      <Container>
        <TableStyled>
          <TableComponent
            header={"Duyệt yêu cầu"}
            dataSource={listBKNVXacNhan}
            listFilter={<ListFilter form={form} />}
            columns={customColumn(columns, filterConditions)}
            renderExtraAction={() => genExtra()}
            totalData={totalCount}
            loading={loading}
            scrollX={3000}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            rowSelection={rowSelection}
            rowKey="id"
            onRow={detailDD}
          />
        </TableStyled>
      </Container>
      {showModalKy}
    </Fragment>
  );
}
