import { buildQueryString, parseParams } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authGetMultiplesRequest } from "@utils/request";
import ThongTinChiTietTabs from "../../common-ho-so/chi-tiet-ho-so";
import { closeModal, openModal } from "@state/system-config/reducer";

//Tìm kiếm data Chi tiết hình thức lắp đặt
export const handleListData = (
  location,
  filterConditions,
  setLoading,
  setDataHinhThucLapDat,
  setTotalCount
) => {
  const url = `${Endpoint.GET_LIST_CHI_TIET_THEO_HTLD}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataHinhThucLapDat(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataHinhThucLapDat([]);
    },
  });
};

export const handleMultiBoLoc = (
  setLoading,
  setLoaiYeuCau,
  setSoPha,
  setHinhThucLapDat,
  setHinhThucNopTien,
  setTinhTrang,
  setMucDich,
  setDonVi
) => {
  const endpoints = [
    `${Endpoint.COMMON_LIST_LOAI_YEU_CAU}`,
    `${Endpoint.COMMON_LIST_MUC_DICH}`,
    `${Endpoint.COMMON_LIST_SO_PHA}`,
    `${Endpoint.COMMON_LIST_HINH_THUC_LAP_DAT}`,
    `${Endpoint.COMMON_LIST_HINH_THUC_TRA_TIEN}`,
    `${Endpoint.LIST_DON_VI}?OrderBy=maDonVi&pageSize=-1`,
    `${Endpoint.COMMON_LIST_TINH_TRANG_YEU_CAU}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setLoaiYeuCau(res[0].data.data);
      setMucDich(res[1].data.data);
      setSoPha(res[2].data.data);
      setHinhThucLapDat(res[3].data.data);
      setHinhThucNopTien(res[4].data.data);
      setDonVi(res[5].data.data);
      setTinhTrang(res[6].data.data);
    },
  });
};

export const handleListColumns = (setLoading, setDataColumns, dispatch) => {
  authGetData({
    url: Endpoint.GET_LIST_COLUMNS_BAO_CAO_CHI_TIET_HINH_THUC_LAP_DAT,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        const detailHoSo = (record) => {
          return dispatch(
            openModal({
              content: (
                <ThongTinChiTietTabs
                  detailHoSo={record}
                  closeModal={closeModal}
                />
              ),
              size: "large",
              title: `${
                record.maYeuCau !== null ? record.maYeuCau + " - " : ""
              }${record.tenKhachHang !== null ? record.tenKhachHang : ""}`,
            })
          );
        };

        const columns = [
          {
            title: "STT",
            dataIndex: "stt",
            key: "stt",
            hide: false,
            disabled: true,
            width: 50,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            fixed: "left",
          },
          {
            title: "Mã đơn vị",
            dataIndex: "donVi",
            key: "donVi",
            hide: false,
            disabled: true,
            width: 100,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            fixed: "left",
            sorter: true,
          },
          {
            title: "Đội Quản lý",
            dataIndex: "maDoi",
            key: "maDoi",
            hide: false,
            disabled: true,
            width: 110,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            fixed: "left",
            sorter: true,
          },
          {
            title: "Tên KH",
            dataIndex: "tenKhachHang",
            key: "tenKhachHang",
            hide: false,
            disabled: true,
            width: 160,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            fixed: "left",
            sorter: true,
          },
          {
            title: "Mã yêu cầu",
            dataIndex: "maYeuCau",
            key: "maYeuCau",
            hide: true,
            disabled: false,
            width: 150,
            render: (text, record) => (
              <div
                className="table-text-left ant-btn-link cursor-pointer"
                onClick={() => detailHoSo(record)}
              >
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 1,
            fixed: "left",
          },
          {
            title: "Mã khách hàng",
            dataIndex: "maKhachHang",
            key: "maKhachHang",
            hide: true,
            disabled: false,
            width: 150,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 1,
          },
          {
            title: "Địa chỉ",
            dataIndex: "diaChi",
            key: "diaChi",
            hide: true,
            disabled: false,
            width: 250,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 1,
          },
          {
            title: "Điện thoại",
            dataIndex: "dienThoai",
            key: "dienThoai",
            hide: true,
            disabled: false,
            width: 100,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 1,
          },
          {
            title: "Nguồn tiếp nhận",
            dataIndex: "nguonTiepNhan",
            key: "nguonTiepNhan",
            hide: true,
            disabled: false,
            width: 120,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 1,
          },
          {
            title: "Loại hợp đồng",
            dataIndex: "loaiHopDong",
            key: "loaiHopDong",
            hide: true,
            disabled: false,
            width: 120,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 1,
          },
          {
            title: "Hình thức lắp đặt",
            dataIndex: "hinhThucLapDat",
            key: "hinhThucLapDat",
            hide: true,
            disabled: false,
            width: 120,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 1,
          },

          {
            title: "Chi phí",
            dataIndex: "chiPhi",
            key: "chiPhi",
            hide: true,
            disabled: false,
            width: 120,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 2,
          },
          {
            title: "Hình thức nộp tiền",
            dataIndex: "hinhThucNopTien",
            key: "hinhThucNopTien",
            hide: true,
            disabled: false,
            width: 120,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 2,
          },
          {
            title: "Công suất tối đa",
            dataIndex: "congSuatToiDa",
            key: "congSuatToiDa",
            hide: true,
            disabled: false,
            width: 120,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 2,
          },
          {
            title: "Số pha",
            dataIndex: "soPha",
            key: "soPha",
            hide: true,
            disabled: false,
            width: 80,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 2,
          },
          {
            title: "Cáp chính",
            dataIndex: "capChinh",
            key: "capChinh",
            hide: true,
            disabled: false,
            width: 120,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            // sorter: true,
            group: 2,
          },
          {
            title: "Số lượng cáp",
            dataIndex: "soLuongCap",
            key: "soLuongCap",
            hide: true,
            disabled: false,
            width: 100,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            // sorter: true,
            group: 2,
          },

          {
            title: "Hồ sơ có trở ngại",
            dataIndex: "hoSoCoTroNgai",
            key: "hoSoCoTroNgai",
            hide: true,
            disabled: false,
            width: 120,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 2,
          },

          {
            title: "Tên trở ngại",
            dataIndex: "tenTroNgais",
            key: "tenTroNgais",
            render: (row) => {
              return row.map((item) => {
                if (item === null || item === "null" || item === "")
                  return null;
                else return <div>{item};</div>;
              });
            },
            hide: true,
            disabled: false,
            // sorter: true,
            width: 250,
            group: 2,
          },
          {
            title: "Ghi chú trở ngại",
            dataIndex: "ghiChuTroNgais",
            key: "ghiChuTroNgais",
            render: (row) => {
              return row.map((item) => {
                if (item === null || item === "null" || item === "")
                  return null;
                else return <div>{item};</div>;
              });
            },
            hide: true,
            disabled: false,
            // sorter: true,
            width: 250,
            group: 2,
          },

          {
            title: "Ngày đăng ký",
            dataIndex: "ngayDangKy",
            key: "ngayDangKy",
            hide: true,
            disabled: false,
            width: 120,
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 2,
          },
          {
            title: "Ngày hoàn thành PACĐ",
            dataIndex: "ngayHoanThanhPACD",
            key: "ngayHoanThanhPACD",
            hide: true,
            disabled: false,
            width: 120,
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 2,
          },
          {
            title: "Ngày Lãnh đạo duyệt PACĐ",
            dataIndex: "ngayLanhDaoDuyetPACD",
            key: "ngayLanhDaoDuyetPACD",
            hide: true,
            disabled: false,
            width: 120,
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 3,
          },
          {
            title: "Ngày nộp tiền",
            dataIndex: "ngayNopTien",
            key: "ngayNopTien",
            hide: true,
            disabled: false,
            width: 120,
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 3,
          },
          {
            title: "Ngày thi công lắp đặt",
            dataIndex: "ngayThiCongLapDat",
            key: "ngayThiCongLapDat",
            hide: true,
            disabled: false,
            width: 120,
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 3,
          },
          {
            title: "Ngày nghiệm thu đóng điện",
            dataIndex: "ngayNghiemThuDongDien",
            key: "ngayNghiemThuDongDien",
            hide: true,
            disabled: false,
            width: 120,
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 3,
          },
          {
            title: "Ngày hoàn tất hồ sơ (CMIS)",
            dataIndex: "ngayHoanTatHoSo",
            key: "ngayHoanTatHoSo",
            hide: true,
            disabled: false,
            width: 120,
            render: (text) => (
              <div className="table-text-center">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 3,
          },
          {
            title: "Tổng số ngày thực hiện",
            dataIndex: "tongSoNgayThucHien",
            key: "tongSoNgayThucHien",
            hide: true,
            disabled: false,
            width: 120,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 3,
          },
          {
            title: "Tổng số ngày thực hiện (ngày TN- ngày NT)",
            dataIndex: "tongSoNgayThucHienTN",
            key: "tongSoNgayThucHienTN",
            hide: true,
            disabled: false,
            width: 150,
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            // sorter: true,
            group: 3,
          },
          {
            title: "Tình trạng",
            dataIndex: "tinhTrang",
            key: "tinhTrang",
            hide: true,
            disabled: false,
            width: 150,
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            sorter: true,
            group: 3,
          },
        ];

        const newColumns = columns.filter((column) => {
          return !res.data.some((item) => {
            if (
              column.dataIndex ===
              item.key.replace(
                item.key.charAt(0),
                item.key.charAt(0).toLocaleLowerCase()
              )
            ) {
              column.hide = item.value;
              column.group = item.group;
            }
            return null;
          });
        });
        setDataColumns(newColumns);
      }
    },
  });
};
