import { Fragment, useEffect } from "react";
import { Row, Col, Form, Checkbox, Input, DatePicker } from "antd";
import moment from "moment";
import { FORMAT_DATE, TYPE_PHIEUTRA_CHUCNANG } from "@utils/constants";
import { disabledDateFuture } from "@utils/function";

const { TextArea } = Input;

export default function Index(props) {
  const {
    form,
    type,
    checkTraLai,
    nguoiTao,
    tenLoaiTT,
    tenDoi,
    isTraLai,
    defaultInfo,
  } = props;
  useEffect(() => {
    form.setFieldsValue({
      loaiTreoThao: defaultInfo?.loaiTreoThao,
      doiId: defaultInfo?.doi,
      ngayTao: moment(defaultInfo?.ngayTao),
    });
  }, [defaultInfo, form]);

  return (
    <Fragment>
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="loaiTreoThao" label="Loại treo tháo">
            <Input defaultValue={tenLoaiTT} disabled />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="doiId" label="Đội">
            <Input defaultValue={tenDoi} disabled />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="ngayTao" label="Ngày tạo">
            <DatePicker
              format={FORMAT_DATE}
              form={form}
              notClear={false}
              disabledDate={disabledDateFuture}
              allowClear={false}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="nguoiTao" label="Người tạo">
            <Input defaultValue={nguoiTao} disabled={true} />
          </Form.Item>
        </Col>
        {type === TYPE_PHIEUTRA_CHUCNANG.TRALAI_DUYET ? (
          <Fragment>
            <Col
              span={24}
              md={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Item name="isTraLai" style={{ margin: 0 }}>
                <Checkbox onChange={checkTraLai} checked={isTraLai}>
                  Trả lại phiếu
                </Checkbox>
              </Form.Item>
            </Col>
            {isTraLai ? (
              <Col span={24} md={18}>
                <Form.Item name="lyDoTraLai" className="requird-field">
                  <TextArea rows={3} />
                </Form.Item>
              </Col>
            ) : null}
          </Fragment>
        ) : null}
      </Row>
    </Fragment>
  );
}
