import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { alertMessage } from "@utils/function";
import { startDelete } from "@utils/request";

export const handleDeleteHoSo = (
  dataRemove,
  setLoading,
  setFetchDataTable,
  handleCloseModal,
  t
) => {
  startDelete({
    url: Endpoint.REMOVE_HO_SO + "?HoSoId=" + dataRemove?.id,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        // fetch data HoSo

        setFetchDataTable(true);
        handleCloseModal();
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};
