import { REACT_APP_MAP_API_KEY } from "@utils/constants";
import { Spin } from "antd";
import React, { useEffect, useRef } from "react";
import NhanVienIcon from "@modules/lo-trinh-nhan-vien/assets/nhan-vien-icon.png";
import AvatarDefault from "@modules/lo-trinh-nhan-vien/assets/default-avatar.png";
import moment from "moment";
import _ from "lodash";
import styled from "styled-components";

function checkIsAfter(date1, date2) {
  return moment(date1).isAfter(date2) ? 1 : -1;
}

function MapContentComponent({ loTrinhNhanVien }) {
  const ref = useRef();
  useEffect(() => {
    const map = new window.mapboxgl.Map({
      container: ref.current,
      center: [105.842392, 21.025755],
      zoom: 9.5,
    });
    new window.ekmapplf.VectorBaseMap(
      "OSM:Bright",
      REACT_APP_MAP_API_KEY
    ).addTo(map);

    loTrinhNhanVien.forEach((nhanVien) => {
      // Nhân viên
      if (nhanVien.length === 1) {
        nhanVien.forEach((loTrinhNV) => {
          const el = document.createElement("div");
          const icon = NhanVienIcon;
          const color = "blue";

          el.className = "cursor-pointer";
          el.innerHTML = `<div style="color: ${color};"class="font-bold"><img src=${icon} width=15 height=15 alt="icon-substation"><div>${loTrinhNV.nhanVien}</div></div>`;

          const popup = new window.mapboxgl.Popup({ offset: 2 })
            .setHTML(
              `
          <div>
          <div style='text-align: left'>
          
           <div><img src=${
             loTrinhNV?.anhDaiDien === null
               ? AvatarDefault
               : loTrinhNV.anhDaiDien
           } width=150 alt="hinh anh nhan vien"></div>
           
            <p style="color: blue; font-weight: bold">
              Tên nhân viên: ${
                loTrinhNV?.nhanVien !== null ? loTrinhNV.nhanVien : ""
              }
            </p>
          </div>
           
          <div style='text-align: left'>
          <p>Đơn vị quản lý: ${
            loTrinhNV?.donVi !== null ? loTrinhNV.donVi : ""
          }</p>
          <p>Đội quản lý: ${
            loTrinhNV?.toDoi !== null ? loTrinhNV.toDoi : ""
          }</p>
        <p>Ghi chú lộ trình: ${
          loTrinhNV?.thongTinGhiChu !== null ? loTrinhNV.thongTinGhiChu : ""
        } </p>
        <p>Ngày giờ ghi lộ trình: ${moment(loTrinhNV.thoiGian).format(
          "YYYY-MM-DD HH:mm:ss"
        )}</p>
            <p>
              Tên trạm: ${loTrinhNV?.tenTram !== null ? loTrinhNV.tenTram : ""} 
            </p>
            <p>Mã điểm đo: ${
              loTrinhNV?.maDiemDo !== null ? loTrinhNV.maDiemDo : ""
            }</p>
            <p>
             Mã công tơ: ${
               loTrinhNV?.maCongTo !== null ? loTrinhNV.maCongTo : ""
             }
            </p>
            <p>
              Mã khách hàng: ${loTrinhNV?.maKH !== null ? loTrinhNV.maKH : ""}
            </p>
            <p>Tên khách hàng: ${
              loTrinhNV?.tenKH !== null ? loTrinhNV?.tenKH : ""
            }</p>
            <p>Địa chỉ sử dụng điện: ${
              loTrinhNV?.diaChiSuDungDien !== null
                ? loTrinhNV.diaChiSuDungDien
                : ""
            }</p>
            <p> Mã sổ GCS: ${
              loTrinhNV?.soKhachHang !== null ? loTrinhNV.soKhachHang : ""
            } </p>
          </div>
            
          </div>
        `
            )
            .setMaxWidth("500px");

          if (
            loTrinhNV.longitudeNhanVien === null &&
            loTrinhNV.latitudeNhanVien === null
          ) {
            // eslint-disable-next-line array-callback-return
            return;
          }
          return new window.mapboxgl.Marker(el)
            .setLngLat([
              parseFloat(
                loTrinhNV.longitudeNhanVien === null
                  ? 0
                  : loTrinhNV.longitudeNhanVien
              ),
              parseFloat(
                loTrinhNV.latitudeNhanVien === null
                  ? 0
                  : loTrinhNV.latitudeNhanVien
              ),
            ])
            .setPopup(popup)
            .addTo(map);
        });
      } else {
        const thongTinNhanVien = document.createElement("div");
        const node = document.createElement("div");
        const tenNhanVien = [];
        const donViQuanLi = [];
        const doiQuanLi = [];
        const ghiChu = [];
        const ngayGioGhiLoTrinh = [];
        const tenTram = [];
        const maDiemDo = [];
        const maCongTo = [];
        const maKhachHang = [];
        const tenKhachHang = [];
        const diaChiSuDungDien = [];
        const maSoGCS = [];
        const textnode = [];
        const imgnode = [];
        const imgWrapper = [];
        const infoWrapper = [];

        nhanVien.sort((a, b) => checkIsAfter(b.thoiGian, a.thoiGian));

        nhanVien.map((loTrinhNV, index) => {
          imgnode[index] = document.createElement("img");
          imgnode[index].src = `${
            loTrinhNV?.anhDaiDien === null
              ? AvatarDefault
              : loTrinhNV.anhDaiDien
          }`;

          tenNhanVien[index] = document.createElement("div");
          tenNhanVien[index].innerHTML =  `Tên nhân viên: 
          ${loTrinhNV?.nhanVien !== null ? loTrinhNV.nhanVien : ""}`
          tenNhanVien[index].style.color = "blue";
          tenNhanVien[index].style.fontWeight = "bold";

          donViQuanLi[index] = document.createElement("div");
          donViQuanLi[index].innerHTML =  `Đơn vị quản lý: ${
            loTrinhNV?.donVi !== null ? loTrinhNV.donVi : ""
          }`
          doiQuanLi[index] = document.createElement("div");
          doiQuanLi[index].innerHTML =  `Đội quản lý: ${loTrinhNV?.toDoi !== null ? loTrinhNV.toDoi : ""}`
          ghiChu[index] = document.createElement("div");
          ghiChu[index].innerHTML =  `Ghi chú lộ trình: ${
            loTrinhNV?.thongTinGhiChu !== null
              ? loTrinhNV.thongTinGhiChu
              : ""
          }`
          ngayGioGhiLoTrinh[index] = document.createElement("div");
          ngayGioGhiLoTrinh[index].innerHTML =  `Ngày giờ ghi lộ trình: ${moment(loTrinhNV.thoiGian).format(
            "YYYY-MM-DD HH:mm:ss"
          )}`

          tenTram[index] = document.createElement("div");
          tenTram[index].innerHTML =  `Tên trạm: ${loTrinhNV?.tenTram !== null ? loTrinhNV.tenTram : ""}`
          maDiemDo[index] = document.createElement("div");
          maDiemDo[index].innerHTML =  ` Mã điểm đo: ${
            loTrinhNV?.maDiemDo !== null ? loTrinhNV.maDiemDo : ""
          }`
          maCongTo[index] = document.createElement("div");
          maCongTo[index].innerHTML =  `
          Mã công tơ: ${
            loTrinhNV?.maCongTo !== null ? loTrinhNV.maCongTo : ""
          }`
          maKhachHang[index] = document.createElement("div");
          maKhachHang[index].innerHTML =  `Mã khách hàng: ${loTrinhNV?.maKH !== null ? loTrinhNV.maKH : ""}`
          tenKhachHang[index] = document.createElement("div");
          tenKhachHang[index].innerHTML =  ` Tên khách hàng: ${
            loTrinhNV?.tenKH !== null ? loTrinhNV?.tenKH : ""
          }`
          diaChiSuDungDien[index] = document.createElement("div");
          diaChiSuDungDien[index].innerHTML =  ` Địa chỉ sử dụng điện: ${
            loTrinhNV?.diaChiSuDungDien !== null
              ? loTrinhNV.diaChiSuDungDien
              : ""
          }`
          maSoGCS[index] = document.createElement("div");
          maSoGCS[index].innerHTML =  ` Mã sổ GCS: ${
            loTrinhNV?.soKhachHang !== null ? loTrinhNV.soKhachHang : ""
          }`


                    textnode[index] = document.createElement("div");
                    textnode[index].style.textAlign = "left"
                    textnode[index].style.padding = "10px"
                    textnode[index].style.borderBottom = "1px solid gray"

                    imgWrapper[index] = document.createElement("div");
                    infoWrapper[index] = document.createElement("div");

      
          
          textnode[index].appendChild(imgnode[index]);
          textnode[index].appendChild(tenNhanVien[index]);
          textnode[index].appendChild(donViQuanLi[index]);
          textnode[index].appendChild(doiQuanLi[index]);
          textnode[index].appendChild(ghiChu[index]);
          textnode[index].appendChild(ngayGioGhiLoTrinh[index]);
          textnode[index].appendChild(tenTram[index]);
          textnode[index].appendChild(maDiemDo[index]);
          textnode[index].appendChild(maCongTo[index]);
          textnode[index].appendChild(maKhachHang[index]);
          textnode[index].appendChild(tenKhachHang[index]);
          textnode[index].appendChild(maSoGCS[index]);
          textnode[index].appendChild(diaChiSuDungDien[index]);
          node.append(textnode[index])
        });
        thongTinNhanVien.appendChild(node);
       thongTinNhanVien.style.maxHeight = '450px'
       thongTinNhanVien.style.overflow = "scroll"
        const el = document.createElement("div");
        const icon = NhanVienIcon;
        const color = "green";

        el.className = "cursor-pointer";
        el.innerHTML = `<div style="color: ${color};"class="font-bold"><img src=${icon} width=15 height=15 alt="icon-substation"><div>${nhanVien[0]?.nhanVien}</div></div>`;

        const popup = new window.mapboxgl.Popup({ offset: 2 })
          .setDOMContent(thongTinNhanVien)
          .setMaxWidth("500px");
        popup.addClassName("hello");
        return new window.mapboxgl.Marker(el)
          .setLngLat([
            parseFloat(
              nhanVien[0].longitudeNhanVien === null
                ? 0
                : nhanVien[0].longitudeNhanVien
            ),
            parseFloat(
              nhanVien[0].latitudeNhanVien === null
                ? 0
                : nhanVien[0].latitudeNhanVien
            ),
          ])
          .setPopup(popup)
          .addTo(map);
      }
    });
  });
  return (
    <MapContent
      ref={ref}
      style={{ width: "100%", height: "100%" }}
    ></MapContent>
  );
}

function MapComponent({ loTrinhNhanVien, loading }) {
  const multiGroupBy = (array, group, ...restGroups) => {
    if (!group) {
      return array;
    }
    const currGrouping = _.groupBy(array, group);
    if (!restGroups.length) {
      return currGrouping;
    }
    return _.transform(
      currGrouping,
      (result, value, key) => {
        result[key] = multiGroupBy(value, ...restGroups);
      },
      {}
    );
  };
  const newData = multiGroupBy(
    loTrinhNhanVien,
    "latitudeNhanVien",
    "longitudeNhanVien"
  );
  const newData2 = [];
  const newData3 = [];

  for (const [key, value] of Object.entries(newData)) {
    newData2.push(value);
  }
  newData2.forEach((item) => {
    for (const [key, value] of Object.entries(item)) {
      newData3.push(value);
    }
  });

  return (
    <div style={{ width: "100%", height: "100%", padding: 10 }}>
      {loading ? <Spin /> : <MapContentComponent loTrinhNhanVien={newData3} />}
    </div>
  );
}

export default MapComponent;

const MapContent = styled.div``;
