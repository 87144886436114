import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Form, InputNumber, Modal, Row } from "antd";
import moment from "moment";
import React, { memo, useState } from "react";
import { handleSuaCongTo } from "../../service";

export function ModalSuaTHKiemTraDKCongTo(props) {
  const { visible, closeModal, getListData, detailTable } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    const url = Endpoint.BCCT_SUA_KT_DK_CT;
    const data = {
      iD_K: detailTable.ID_K,
      ma_dviqly: values.MA_DVIQLY,
      ...(values.reportDate && { nam: values.reportDate.split("-")[0] }),
      ...(values.reportDate && { thang: values.reportDate.split("-")[1] }),
      tong_cto: values.TONG_CTO,
      tong_cto_ktra: values.TONG_CTO_KTRA,
    };
    handleSuaCongTo(data, setLoading, closeModal, getListData, url);
  };

  console.log(detailTable);

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title="Sửa thông tin"
        visible={visible}
        onCancel={closeModal}
        width={1100}
        footer={null}
      >
        <FormComponent
          onFinish={onFinish}
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          initialValues={{
            ...detailTable,
          }}
          colon={false}
        >
          <Form.Item name="MA_DVIQLY" label="Đơn vị" labelAlign="left" required>
            <Selection
              url={Endpoint.GET_TEAM_LIST_CODE}
              formKey="MA_DVIQLY"
              form={form}
            />
          </Form.Item>

          <Form.Item
            name="reportDate"
            label="Tháng/ năm"
            labelAlign="left"
            required
          >
            <DatePickerComponent
              picker="month"
              formKey="reportDate"
              form={form}
              format={"YYYY-MM"}
              defaultValue={
                detailTable.THANG && detailTable.NAM
                  ? `${detailTable.NAM}-${detailTable.THANG}`
                  : null
              }
            />
          </Form.Item>

          <Form.Item
            name="TONG_CTO_KTRA"
            label="Tổng số công tơ đã kiểm tra"
            labelAlign="left"
            required
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            name="TONG_CTO"
            label="Tổng số công tơ theo kế hoạch"
            labelAlign="left"
            required
          >
            <InputNumber />
          </Form.Item>

          <Row>
            <Col span={23}>
              {" "}
              <Button type="primary" htmlType="submit">
                Cập nhật
              </Button>
            </Col>
            <Col span={1}>
              {" "}
              <Button onClick={closeModal}>Hủy</Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(ModalSuaTHKiemTraDKCongTo);
