import TrangChu from "@modules/ap-gia";
import { ap_gia } from "@permissions/e_apgia";
import NhanHSTuCMIS from "@modules/ap-gia/pkd-doi-kt-sd-dien/nhan-yeu-cau-tu-cmis";
import PKDDuyetYC from "@modules/ap-gia/pkd-doi-kt-sd-dien/duyet-yeu-cau";
import DBVeCMIS from "@modules/ap-gia/pkd-doi-kt-sd-dien/dong-bo-ve-cmis";
import TiepNhanPhanCong from "@modules/ap-gia/pkd-doi-kt-sd-dien/phan-cong";
import LanhDaoDuyetYC from "@modules/ap-gia/lanh-dao/duyet-yeu-cau";
import LapBK from "@modules/ap-gia/nhan-vien-kiem-tra/lap-bang-ke";
import XacNhanBK from "@modules/ap-gia/nhan-vien-kiem-tra/xac-nhan-bang-ke";
import DuyetYC from "@modules/ap-gia/nhan-vien-kiem-tra/duyet-yeu-cau";
import PhanCongCongViec from "@modules/ap-gia/bo-phan-ap-gia/phan-cong";
import BPAGDuyetYC from "@modules/ap-gia/bo-phan-ap-gia/duyet-yeu-cau";
import BPAGDuyetBK from "@modules/ap-gia/bo-phan-ap-gia/duyet-bang-ke";
import BPAGChuyenKTGS from "@modules/ap-gia/bo-phan-ap-gia/chuyen-kt-gs";
import YCDuocPhanCong from "@modules/ap-gia/nhan-vien-kiem-tra/yc-duoc-phan-cong";
import YCDangThucHien from "@modules/ap-gia/nhan-vien-kiem-tra/yc-dang-thuc-hien";
import YCKhachHangChuaKy from "@modules/ap-gia/nhan-vien-kiem-tra/yc-khach-hang-chua-ky";
import TraCuuBK from "@modules/ap-gia/tra-cuu-tim-kiem/tra-cuu-bang-ke";
import TraCuuYC from "@modules/ap-gia/tra-cuu-tim-kiem/tra-cuu-yeu-cau";
import CauHinh from "@modules/ap-gia/cau-hinh/cau-hinh-ky-nhay";
import {
  NAVIGATE_KTDINHMUC_APGIA,
  NAVIGATE_APGIA_PKD,
  NAVIGATE_APGIA_LANHDAO,
  NAVIGATE_APGIA_NHANVIENAPGIA,
  NAVIGATE_APGIA_TRACUU,

  NAVIGATE_PKD_LAYCMIS,
  NAVIGATE_PKD_DUYETYC,
  NAVIGATE_PKD_TN_PC,
  TITLE_PKD_TIEPNHAN_PHANCONG,
  TITLE_PKD_DONGBOCMIS,
  NAVIGATE_LD_DUYETYC,
  NAVIGATE_YC_PHANCONG,
  NAVIGATE_YC_DANGTHUCHIEN,
  NAVIGATE_YC_CHUAKY,
  NAVIGATE_NV_DUYETYEUCAU,
  NAVIGATE_TRACUU_YC,
  NAVIGATE_TRACUU_BK,
  NAVIGATE_NV_LAPBANGKE,
  NAVIGATE_NV_XACNHANBANGKE,
  NAVIGATE_APGIA_BOPHANAPGIA,
  NAVIGATE_BP_PHANCONG,
  NAVIGATE_BP_DUYETYC,
  NAVIGATE_BP_DUYETBK,
  NAVIGATE_BP_CHUYENKTGS,
  NAVIGATE_APGIA_CAUHINH,
  NAVIGATE_APGIA_CAUHINH_KYNHAY,

  TITLE_PKD_APGIA,
  TITLE_PKD_LAYCMIS,
  TITLE_PKD_DUYETYC,
  NAVIGATE_PKD_DONGBOCMIS,
  TITLE_LANHDAO_APGIA ,
  TITLE_AG_CAU_HINH,
  TITLE_CAUHINH_KYNHAY,
  TITLE_LD_DUYETYC,
  TITLE_NV_AP_GIA,
  TITLE_YC_DUOCPHANCONG,
  TITLE_YC_DANGTHUCHIEN,
  TITLE_YC_CHUAKY,
  TITLE_NV_DUYETYEUCAU,
  TITLE_TRACUU_TIMKIEM,
  TITLE_TRACUU_BK,
  TITLE_TRACUU_YC,
  TITLE_NV_LAPBANGKE,
  TITLE_NV_XACNHANBANGKE,
  TITLE_BP_APGIA,
  TITLE_BP_PHANCONG,
  TITLE_BP_DUYETYC,
  TITLE_BP_DUYETBK,
  TITLE_BP_CHUYENKTGS
  // TITLE_TRANGCHU,
} from "@utils/constants";
import { MODULE_APGIA } from "@utils/constants";

const routes = [
  {
    title: '',
    path: NAVIGATE_KTDINHMUC_APGIA,
    component: TrangChu,
    privateRoute: true,
    permissionCode: MODULE_APGIA,
    subItems: [],
  },
  {
    title: TITLE_NV_AP_GIA,
    path: `${NAVIGATE_KTDINHMUC_APGIA}${NAVIGATE_APGIA_NHANVIENAPGIA}`,
    component: TrangChu,
    privateRoute: true,
    permissionCode: ap_gia.e1,
    subItems: [
      {
        subTitle: TITLE_YC_DUOCPHANCONG,
        subPath: NAVIGATE_YC_PHANCONG,
        subComponent: YCDuocPhanCong,
        permissionCode: ap_gia.e11,
      },
      {
        subTitle: TITLE_YC_DANGTHUCHIEN,
        subPath: NAVIGATE_YC_DANGTHUCHIEN,
        subComponent: YCDangThucHien,
        permissionCode: ap_gia.e12,
      },
      {
        subTitle: TITLE_YC_CHUAKY,
        subPath: NAVIGATE_YC_CHUAKY,
        subComponent: YCKhachHangChuaKy,
        permissionCode: ap_gia.e13,
      },
      {
        subTitle: TITLE_NV_DUYETYEUCAU,
        subPath: NAVIGATE_NV_DUYETYEUCAU,
        subComponent: DuyetYC,
        permissionCode: ap_gia.e14,
      },
      {
        subTitle: TITLE_NV_LAPBANGKE,
        subPath: NAVIGATE_NV_LAPBANGKE,
        subComponent: LapBK,
        permissionCode: ap_gia.e15,
      },
      {
        subTitle: TITLE_NV_XACNHANBANGKE,
        subPath: NAVIGATE_NV_XACNHANBANGKE,
        subComponent: XacNhanBK,
        permissionCode: ap_gia.e16,
      },
    ],
  },
  {
    title: TITLE_BP_APGIA,
    path: `${NAVIGATE_KTDINHMUC_APGIA}${NAVIGATE_APGIA_BOPHANAPGIA}`,
    component: TrangChu,
    privateRoute: true,
    permissionCode: ap_gia.e2,
    subItems: [
      {
        subTitle: TITLE_BP_PHANCONG,
        subPath: NAVIGATE_BP_PHANCONG,
        subComponent: PhanCongCongViec,
        permissionCode: ap_gia.e21,
      },
      {
        subTitle: TITLE_BP_DUYETYC,
        subPath: NAVIGATE_BP_DUYETYC,
        subComponent: BPAGDuyetYC,
        permissionCode: ap_gia.e22,
      },
      {
        subTitle: TITLE_BP_DUYETBK,
        subPath: NAVIGATE_BP_DUYETBK,
        subComponent: BPAGDuyetBK,
        permissionCode: ap_gia.e23,
      },
      {
        subTitle: TITLE_BP_CHUYENKTGS,
        subPath: NAVIGATE_BP_CHUYENKTGS,
        subComponent: BPAGChuyenKTGS,
        permissionCode: ap_gia.e24,
      },
    ],
  },
  {
    title: TITLE_PKD_APGIA,
    path: `${NAVIGATE_KTDINHMUC_APGIA}${NAVIGATE_APGIA_PKD}`,
    component: TrangChu,
    privateRoute: true,
    permissionCode: ap_gia.e3,
    subItems: [
      {
        subTitle: TITLE_PKD_LAYCMIS,
        subPath: NAVIGATE_PKD_LAYCMIS,
        subComponent: NhanHSTuCMIS,
        permissionCode: ap_gia.e31,
      },
      {
        subTitle: TITLE_PKD_DUYETYC,
        subPath: NAVIGATE_PKD_DUYETYC,
        subComponent: PKDDuyetYC,
        permissionCode: ap_gia.e32,
      },
      {
        subTitle: TITLE_PKD_DONGBOCMIS,
        subPath: NAVIGATE_PKD_DONGBOCMIS,
        subComponent: DBVeCMIS,
        permissionCode: ap_gia.e33,
      },
      {
        subTitle: TITLE_PKD_TIEPNHAN_PHANCONG,
        subPath: NAVIGATE_PKD_TN_PC,
        subComponent: TiepNhanPhanCong,
        permissionCode: ap_gia.e34,
      }
    ],
  },
  {
    title: TITLE_LANHDAO_APGIA,
    path: `${NAVIGATE_KTDINHMUC_APGIA}${NAVIGATE_APGIA_LANHDAO}`,
    component: TrangChu,
    privateRoute: true,
    permissionCode: ap_gia.e4,
    subItems: [
      {
        subTitle: TITLE_LD_DUYETYC,
        subPath: NAVIGATE_LD_DUYETYC,
        subComponent: LanhDaoDuyetYC,
        permissionCode: ap_gia.e41,
      },
    ],
  },
  {
    title: TITLE_TRACUU_TIMKIEM,
    path: `${NAVIGATE_KTDINHMUC_APGIA}${NAVIGATE_APGIA_TRACUU}`,
    component: TrangChu,
    privateRoute: true,
    permissionCode: ap_gia.e5,
    subItems: [
      {
        subTitle: TITLE_TRACUU_YC,
        subPath: NAVIGATE_TRACUU_YC,
        subComponent: TraCuuYC,
        permissionCode: ap_gia.e51,
      },
      {
        subTitle: TITLE_TRACUU_BK,
        subPath: NAVIGATE_TRACUU_BK,
        subComponent: TraCuuBK,
        permissionCode: ap_gia.e52,
      },
    ],
  },
  {
    title: TITLE_AG_CAU_HINH,
    path: `${NAVIGATE_KTDINHMUC_APGIA}${NAVIGATE_APGIA_CAUHINH}`,
    component: TrangChu,
    privateRoute: true,
    permissionCode: ap_gia.e6,
    subItems: [
      {
        subTitle: TITLE_CAUHINH_KYNHAY,
        subPath: NAVIGATE_APGIA_CAUHINH_KYNHAY,
        subComponent: CauHinh,
        permissionCode: ap_gia.e61,
      },
    ],
  },
];

export default routes;
