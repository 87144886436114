import { Col, Form, Row, Select, Button, Tooltip, Input } from "antd";
import DatePickerComponent from "@components/DatePicker";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import FormComponent from "@components/Form";
import moment from "moment";
import {
  ENUM_KY_GCS_KTCT,
  FORMAT_ENGLISH,
  FORMAT_MONTH,
} from "@utils/constants";
import { Fragment } from "react";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";

const { Option } = Select;

export default function FormBoLoc(props) {
  const { user, form, handleSearch, tooltip, clear, filterConditions } = props;
  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
      }}
      autoComplete="off"
    >
      <Row gutter={24}>
        <Fragment>
          <Col span={24} md={8}>
            <Form.Item name="donViId" label="Đơn vị">
              {!user.isTongCongTy ? (
                <Select defaultValue={user.unitId} disabled>
                  <Option value={user.unitId}>{user.unitName}</Option>
                </Select>
              ) : (
                <Selection
                  url={Endpoint.LIST_DONVI_KTCT}
                  form={form}
                  formKey="donViId"
                  notClear={true}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={5}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                if (prevValues.donViId !== currentValues.donViId) {
                  form.setFieldsValue({
                    doiId: undefined,
                  });
                }
                return prevValues.donViId !== currentValues.donViId;
              }}
            >
              {({ getFieldValue }) => {
                if (getFieldValue("donViId")) {
                  return (
                    <Form.Item name="doiId" label="Đội">
                      <Selection
                        url={`${Endpoint.LIST_DOI_KTCT}?donviId=${getFieldValue(
                          "donViId"
                        )}`}
                        formKey="doiId"
                        form={form}
                      />
                    </Form.Item>
                  );
                }
                return (
                  <Form.Item name="doiId" label="Đội">
                    <Selection
                      url={`${Endpoint.LIST_DOI_KTCT}?donviId=${user.unitId}`}
                      formKey="doiId"
                      form={form}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={24} md={4}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                if (prevValues.donViId !== currentValues.donViId) {
                  form.setFieldsValue({
                    tramId: undefined,
                  });
                }
                return prevValues.donViId !== currentValues.donViId;
              }}
            >
              {({ getFieldValue }) => {
                if (getFieldValue("donViId")) {
                  return (
                    <Form.Item name="tramId" label="Trạm">
                      <Selection
                        mode="multiple"
                        url={`${Endpoint.LIST_TRAM_CT}?donviId=${getFieldValue(
                          "donViId"
                        )}`}
                        formKey="tramId"
                        form={form}
                      />
                    </Form.Item>
                  );
                }
                return (
                  <Form.Item name="tramId" label="Trạm">
                    <Selection
                      mode="multiple"
                      url={`${Endpoint.LIST_TRAM_CT}?donviId=${user.unitId}`}
                      formKey="tramId"
                      form={form}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={24} md={3}>
            <Form.Item name="thangNamGCS" label="Tháng năm GCS">
              <DatePickerComponent
                defaultValue={
                  filterConditions.thangNamGCS
                    ? moment(filterConditions.thangNamGCS, FORMAT_ENGLISH)
                    : moment(moment(), FORMAT_MONTH)
                }
                format={FORMAT_ENGLISH}
                form={form}
                formKey={"thangNamGCS"}
                picker="month"
                notClear={true}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={4}>
            <Form.Item name="kyGCS" label="Kỳ GCS">
              <Select placeholder={"Tất cả"} allowClear>
                <Option value={ENUM_KY_GCS_KTCT.KY_1}>1</Option>
                <Option value={ENUM_KY_GCS_KTCT.KY_2}>2</Option>
                <Option value={ENUM_KY_GCS_KTCT.KY_3}>3</Option>
                <Option value={ENUM_KY_GCS_KTCT.KY_4}>4</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={4}>
            <Form.Item name="maSoGCS" label="Mã sổ GCS">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24} md={8}>
            <Form.Item name="searchTerm" label="Từ khóa">
              <Input
                prefix={<SearchOutlined />}
                suffix={
                  <Tooltip title={tooltip}>
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Col>
          <Col
            span={24}
            md={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button className="mr-24px" onClick={clear}>
              Bỏ lọc
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              form="filter-form"
              className="mr-24px"
            >
              Tìm kiếm
            </Button>
          </Col>
        </Fragment>
      </Row>
    </FormComponent>
  );
}
