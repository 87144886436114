import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authDeleteData, authGetData, authPostData, authPostFormData } from "@utils/request";

// LIST DON VI
export const getListLoaiTaiLieu = (setLoading, setData) => {
  authGetData({
    url: `${Endpoint.DANH_SACH_LOAI_TAI_LIEU}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

//DANH_SACH_NHAN_VIEN_THU_TIEN
export const getListTaiLieu = ({setLoading, query, onSuccess = () => {}}) => {
  authGetData({
    url: `${Endpoint.TAI_LIEU_HO_TRO_KHACH_HANG}?${query}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        onSuccess(res);
      }
    },
  });
};

//DANH_SACH_NO_DONG_CAT_DIEN

export const createOrUpdateDocument = async ({onSuccess, setLoading, payload, method}) => {
  setLoading(true);
  try {
    const res = await authPostFormData(`${Endpoint.TAI_LIEU_HO_TRO_KHACH_HANG}`, method, payload);
    if (res && res.data) {
      onSuccess(res.data);
    }
  } catch (err) {
  } finally {
    setLoading(false);
  }
}

export const deleteDoccument = ({id, onSuccess}) => {
  authDeleteData({
    url: `${Endpoint.TAI_LIEU_HO_TRO_KHACH_HANG}/${id}`,
    setLoading: () => {},
    content: `Bạn chắc chắn muốn hủy tài liệu ?`,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) onSuccess();
    },
  });
};
