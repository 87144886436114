import styled from "styled-components";

export const Container = styled.div`
  padding: 16px 24px;
`;
export const ExTraStepDetail = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
`;

export const TableStyled = styled.div`
  .ant-table-thead {
    .ant-table-cell {
      background: #d9d9d9;
      color: #1f1f1f;
      font-size: 13px;
    }
  }
  .ant-table-tbody > tr > td,
  .ant-table-tbody > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding: 8px !important;
  }
`;