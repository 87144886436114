import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APIQuyetToan = {
  GET_LAP_BANG_QUYET_TOAN_KH: `${BASE_API_URL_CAP_DIEN}/quyet-toan/lap-bang-quyet-toan-khach-hang`,
  UPDATE_VAT_TU_QUYET_TOAN_KH: `${BASE_API_URL_CAP_DIEN}/quyet-toan/update-vat-tu-quyet-toan-khach-hang`,
  HOAN_THANH_QUYET_TOAN_KH: `${BASE_API_URL_CAP_DIEN}/quyet-toan/hoan-thanh-quyet-toan-khach-hang`,
  XAC_NHAN_QUYET_TOAN: `${BASE_API_URL_CAP_DIEN}/quyet-toan/xac-nhan-quyet-toan`,
  TRA_LAI_QUYET_TOAN: `${BASE_API_URL_CAP_DIEN}/quyet-toan/tra-lai-quyet-toan`,
  TU_CHOI_QUYET_TOAN: `${BASE_API_URL_CAP_DIEN}/quyet-toan/tu-choi-quyet-toan`,
  GET_LAP_BANG_QUYET_TOAN_CONGTY: `${BASE_API_URL_CAP_DIEN}/quyet-toan-du-toan/get-thong-tin-quyet-toan`,
  HOAN_THANH_QUYET_TOAN_CONGTY: `${BASE_API_URL_CAP_DIEN}/quyet-toan-du-toan/hoan-thanh-quyet-toan`,
  UPDATE_VAT_TU_QUYET_TOAN_CONGTY: `${BASE_API_URL_CAP_DIEN}/quyet-toan-du-toan/update-thong-tin-vat-tu`,
  IN_BIEN_BAN_CONG_TY: `${BASE_API_URL_CAP_DIEN}/quyet-toan-du-toan/in-bien-ban-quyet-toan`,
  IN_BIEN_BAN_QUYET_TOAN_KH: `${BASE_API_URL_CAP_DIEN}/quyet-toan/in-bien-ban-quyet-toan-khach-hang`,
  IN_BIEN_BAN_CONG_TY_EXCEL: `${BASE_API_URL_CAP_DIEN}/quyet-toan-du-toan/export-excel-quyet-toan-cong-ty`,
  IN_BIEN_BAN_QUYET_TOAN_KH_EXCEL: `${BASE_API_URL_CAP_DIEN}/quyet-toan/export-excel-bien-ban-quyet-toan-khach-hang`,
};
