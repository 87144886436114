import { Button, Checkbox, Form } from "antd";
import { isEqual } from "lodash";
import { Fragment, useEffect, useState, useCallback, useMemo } from "react";
import {
  ContainerBox,
  ContainerWarning,
  ContainerXacNhanThiCong,
} from "../../css-styled";
import { xacNhanThiCongSelector } from "@state/cap-dien/thi-cong/xac-nhan-thi-cong";
import { useSelector } from "react-redux";
import {
  notSavedCongTo,
  notSavedThongTinThiCong,
  notSavedThiCong,
} from "@state/cap-dien/thi-cong/xac-nhan-thi-cong/reducer";
import ModalWarningChange from "@components/Layout/ModalWarningChange";
import ModalXacNhanThiCong from "../../modals/ghi-nhan-ket-qua/xac-nhan-thi-cong";
export default function XacNhanThiCongTabs(props) {
  const { t, dispatch, closeModal, setFetchDataTable, detail, textError } =
    props;
  const [form] = Form.useForm();
  const [checked, setChecked] = useState(false);
  const [initialValues] = useState(false);
  const [visibleXacNhanThiCong, setVisibleXacNhanThiCong] = useState(false);
  const { thongTin, congTo, thiCong } = useSelector(xacNhanThiCongSelector);
  const handleChange = (e) => {
    setChecked(e.target.checked);
  };
  const handleOpenModalXacNhanThiCong = () => {
    setVisibleXacNhanThiCong(!visibleXacNhanThiCong);
  };
  const handleCloseModalXacNhanThiCong = () => {
    setVisibleXacNhanThiCong(false);
  };
  useEffect(() => {
    if (isEqual(initialValues, checked)) {
      dispatch(notSavedThiCong(false));
    } else {
      dispatch(notSavedThiCong(true));
    }
  }, [checked, dispatch, form, initialValues]);

  useEffect(() => {
    if (!thiCong) setChecked(false);
  }, [thiCong]);

  const onClose = useCallback(() => {
    if (thongTin || congTo || thiCong) {
      ModalWarningChange(
        () => {
          if (thongTin) dispatch(notSavedThongTinThiCong(false));
          if (congTo) dispatch(notSavedCongTo(false));
          if (thiCong) dispatch(notSavedThiCong(false));
          dispatch(closeModal());
        },
        () => false
      );
    } else {
      dispatch(closeModal());
    }
  }, [closeModal, congTo, dispatch, thiCong, thongTin]);

  //modal xác nhận thi công

  const showModalXacNhanThiCong = useMemo(
    () =>
      visibleXacNhanThiCong && (
        <ModalXacNhanThiCong
          detail={detail}
          visible={visibleXacNhanThiCong}
          dispatch={dispatch}
          checked={checked}
          closeModal={closeModal}
          setFetchDataTable={setFetchDataTable}
          handleCloseModalXacNhanThiCong={() =>
            handleCloseModalXacNhanThiCong()
          }
          t={t}
        />
      ),
    // eslint-disable-next-line
    [visibleXacNhanThiCong]
  );

  return (
    <Fragment>
      <ContainerWarning>
        <span>{textError}</span>
      </ContainerWarning>
      <ContainerXacNhanThiCong>
        <h3 className="fs-16px font-bold">Lưu ý:</h3>
        <h4 className="fs-14px " style={{ color: "#03A4FF" }}>
          Cập nhật thông tin hồ sơ trước khi Xác nhận, Xin cảm ơn!
        </h4>
        <h4 className="fs-14px ">Đồng bộ CMIS với những bộ cấp mới</h4>
        <h4 className="fs-14px ">
          Những bộ nâng công suất sẽ không đồng bộ CMIS
        </h4>
        <ContainerBox>
          <h4>
            Hồ sơ sẽ được xác nhận kết quả thi công và chuyển đến bộ phận nghiệm
            thu
          </h4>
          <Checkbox onChange={handleChange}>
            Kiểm tra điều kiện dự thảo hợp đồng trước khi xác nhận
          </Checkbox>
        </ContainerBox>
        <div className="ant-modal-footer text-center">
          <Button type="default" onClick={onClose}>
            {t("button.dong_lai")}
          </Button>
          <Button
            key="submit"
            type="primary"
            className="button-primary"
            onClick={() => handleOpenModalXacNhanThiCong()}
            disabled={textError ? true : false}
          >
            Xác nhận thi công
          </Button>
        </div>
      </ContainerXacNhanThiCong>
      {showModalXacNhanThiCong}
    </Fragment>
  );
}
