import { EyeOutlined } from "@ant-design/icons";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import FormComponent from "@components/Form";
import TableComponent from "@components/TableComponent";
import { Style } from "@modules/dang-ky-thanh-toan/css-styled";
import { closeModal, openModal } from "@state/system-config/reducer";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  buildQueryString,
  handlePagination,
  parseParams,
  removeAccents,
  removeUndefinedAttribute,
} from "@utils/function";
import { Button, Divider, Form, Input, Select, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import SuaThongTinKhuyenMaiModal from "./modals/sua-thong-tin-khuyen-mai-modal";
import ThemMoiThongTinKhuyenMaiModal from "./modals/them-moi-thong-tin-km-modal";
import {
  getDanhSachThongTinKhuyenMai,
  getListNganHang,
  xoaThongTinKhuyenMai,
} from "./services";
function ThongTinKhuyenMai() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();

  const [, setSearchParams] = useSearchParams();
  const [loadingListNganHang, setLoadingListNganHang] = useState(false);
  const [listNganHang, setListNganHang] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [, setLoadingDelete] = useState(false);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  const columns = [
    {
      title: "STT",
      width: "80px",
      align: "center",
      dataIndex: "STT",
    },
    {
      title: "Mã khuyến mãi",
      dataIndex: "maKhuyenMai",
      width: "100px",
      align: "left",
    },
    {
      title: "Tên khuyến mãi",
      dataIndex: "tenKhuyenMai",
      width: "100px",
      align: "left",
    },
    {
      title: "Ngân hàng",
      dataIndex: "tenNganHang",
      width: "100px",
      align: "left",
    },
    {
      title: "Hạn khuyến mãi",
      width: "160px",
      align: "center",
      render: (_, record) => {
        if (record.tuNgay && record.denNgay) {
          return (
            <>
              {moment(record.tuNgay).format("DD/MM/YYYY")} -
              {moment(record.denNgay).format("DD/MM/YYYY")}
            </>
          );
        }
      },
    },
    {
      title: "Mô tả",
      dataIndex: "noiDung",
      width: "130px",
      align: "left",
      render: (_, record) => {
        if (record.noiDung) {
          return (
            <>
              {record.noiDung.length > 100
                ? `${record.noiDung.substring(0, 100)}...`
                : record.noiDung}
            </>
          );
        }
      },
      className: "noi-dung-km",
    },
    {
      title: "Tác vụ",
      width: "100px",
      align: "center",
      render: (_, record) => (
        <div className="thanh-toan-khuyen-mai-tac-vu-item">
          <Tooltip title="Xem">
            <Button onClick={() => handleShowKhuyenMai(record.id)}>
              <EyeOutlined style={{ cursor: "pointer" }} />
            </Button>
          </Tooltip>
          <Tooltip title="Sửa">
            <Button onClick={() => handleEdit(record.id)}>
              <PencilIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Xóa">
            <Button
              onClick={() =>
                xoaThongTinKhuyenMai(
                  record.id,
                  setLoadingDelete,
                  setListLoading,
                  setData,
                  filterConditions,
                  setTotal,
                  location
                )
              }
            >
              <TrashIcon />
            </Button>
          </Tooltip>
        </div>
      ),
      className: "thanh-toan-khuyen-mai-tac-vu",
    },
  ];

  const handleShowKhuyenMai = (id) => {
    dispatch(
      openModal({
        title: "Chi tiết thông tin khuyến mãi",
        content: (
          <SuaThongTinKhuyenMaiModal
            closeModal={closeModal}
            id={id}
            listNganHang={listNganHang}
            disable={true}
            dispatch={dispatch}
          />
        ),
        size: "large",
      })
    );
  };

  const handleEdit = (id) => {
    dispatch(
      openModal({
        title: "Sửa thông tin khuyến mãi",
        content: (
          <SuaThongTinKhuyenMaiModal
            closeModal={closeModal}
            id={id}
            listNganHang={listNganHang}
            dispatch={dispatch}
            location={location}
            filterConditions={filterConditions}
            setListLoading={setListLoading}
            setData={setData}
            setTotal={setTotal}
          />
        ),
        size: "large",
      })
    );
  };

  // filter coupon
  const handleSearch = (values) => {
    const payload = {
      NganHang: values?.NganHang,
      SearchTerm: values?.SearchTerm,
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    };
    setFilterConditions(payload);
    // const query = buildQueryString(payload);
    // setSearchParams(query);
    // getDanhSachThongTinKhuyenMai(
    //   setListLoading,
    //   setData,
    //   query,
    //   filterConditions,
    //   setTotal
    // );
  };

  useEffect(() => {
    getListNganHang(setLoadingListNganHang, setListNganHang);
  }, []);

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  useEffect(() => {
    const current_query = filterConditions;
    // Object.keys(parseParams(location.search)).length > 0
    //   ? parseParams(location.search)
    //   : filterConditions;
    const updated_query = {
      ...current_query,
      pageSize: filterConditions.pageSize,
      pageIndex: filterConditions.pageIndex,
    };
    const query = buildQueryString(updated_query);

    getDanhSachThongTinKhuyenMai(
      setListLoading,
      setData,
      query,
      filterConditions,
      setTotal
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions]);

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  useEffect(() => {
    form.setFieldsValue({
      ...(filterConditions.NganHang && { NganHang: filterConditions.NganHang }),
    });
  }, [filterConditions.NganHang, form]);

  return (
    <Style>
      <div className="dang-ky-thanh-toan">
        <Divider orientation="left" style={{ fontSize: "20px" }}>
          Danh sách thông tin khuyến mãi
        </Divider>
        <div className="dang-ky-thanh-toan-tt-khuyen-mai">
          <FormComponent form={form} onFinish={handleSearch}>
            <Form.Item
              name="NganHang"
              label="Ngân hàng"
              className="dang-ky-thanh-toan-tt-khuyen-mai-ngan-hang"
            >
              <Select
                allowClear
                showSearch
                maxTagTextLength={5}
                placeholder="--Chọn ngân hàng--"
                loading={loadingListNganHang}
                filterOption={(input, option) => {
                  if (option && option.children) {
                    return (
                      removeAccents(option.children)
                        .toLowerCase()
                        .indexOf(removeAccents(input).toLowerCase()) >= 0
                    );
                  }
                }}
              >
                {listNganHang &&
                  listNganHang.map((item, id) => {
                    return (
                      <Select.Option
                        key={id}
                        value={item.value}
                        disabled={item.value === null}
                      >
                        {item.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item name="SearchTerm">
              <Input placeholder="Tìm kiếm mã khuyến mãi hoặc tên khuyến mãi" />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Tìm kiếm
            </Button>
          </FormComponent>
          <Button
            type="primary"
            onClick={() =>
              dispatch(
                openModal({
                  title: "Thêm mới thông tin khuyến mãi",
                  content: (
                    <ThemMoiThongTinKhuyenMaiModal
                      closeModal={closeModal}
                      listNganHang={listNganHang}
                      setListLoading={setListLoading}
                      setData={setData}
                      setTotal={setTotal}
                      filterConditions={filterConditions}
                      location={location}
                      total={total}
                      callCode={true}
                    />
                  ),
                  size: "large",
                })
              )
            }
          >
            Thêm mới
          </Button>
        </div>
        <div className="dang-ky-thanh-toan-content">
          <TableComponent
            loading={listLoading}
            dataSource={data}
            columns={columns}
            expandable={false}
            header="Danh sách"
            rowKey="key"
            totalData={total}
            pagination={filterConditions}
            onChangePagination={onChangePagination}
            // onRow={detailHoSo}
          />
        </div>
      </div>
      {/* {showDeleteModal} */}
    </Style>
  );
}

export default ThongTinKhuyenMai;
