import { authGetData } from "@utils/request";
import Filter from "../../common/Filter";
import { Endpoint } from "@utils/endpoint";
import React, { useCallback, useState } from "react";
import FilterScreen1 from "./screen/list-filter/filter-screen1";
import { STATUSCODE_200 } from "@utils/constants";
import { buildQueryString, parseParams } from "@utils/function";

export default function BKTinhTrangCongTo() {
  const [loading, setLoading] = useState(false);

  const handleOpenPdf = useCallback(
    (values) => {
      const queryString = buildQueryString(
        parseParams({
          donViId: values.donViId,
          toDoiId: values.toDoiId,
          tramId: values.tramId,
          maSoGCS: values.maSoGCS,
          kyGCS: values.kyGCS,
          thangNam: values.thangNam || undefined,
        })
      );
      authGetData({
        url: `${Endpoint.XUAT_PDF_TINH_TRANG_CTO}?${queryString}`,
        setLoading,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            window.open(res.data, "_blank");
          }
        },
      });
    },
    [ setLoading]
  );

  return (
    <Filter>
      <FilterScreen1
        endPoint={{ ...Endpoint }}
        loading={loading}
        customHandle={handleOpenPdf}
      />
    </Filter>
  );
}
