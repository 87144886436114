import { Endpoint } from "@utils/endpoint";

export const selectBaoCaoKySo = [
  {
    label: "Tổng hợp số lượng file ký số",
    value: Endpoint.TONG_SO_LUONG_KY_S0,
  },
  {
    label: "Chi tiết số lượng file ký số",
    value: Endpoint.CHI_TIET_SO_LUONG_KY_S0,
  },
];
export const selecPhanLoai = [
  {
    label: "Thông báo TĐ lần 2",
    value: "e12382fc-5ebb-474d-a1a7-de277b424030",
  },
  {
    label: "Thông báo ngừng cung cấp điện",
    value: "e12382fc-5ebb-474d-a1a7-de277b424031",
  },
];
