import React from "react";
import { Button, Modal, Row, Col, Form, Input } from "antd";
import FormComponent from "@components/Form";
import { Container } from "../css-styled";

const { TextArea } = Input;

export default function UITraHSXacNhan(props) {
  const { t, visibleTraDoi, handleCloseModalTraDoi, form, onFinish } = props;
  return (
    <Modal
      title={"Trả hồ sơ đội thi công"}
      visible={visibleTraDoi}
      onCancel={handleCloseModalTraDoi}
      footer={null}
    >
      <FormComponent
        form={form}
        name="form-tra-doi"
        onFinish={onFinish}
        initialValues={{}}
        layout="vertical"
        autoComplete="off"
      >
        <Container>
          <p>Hồ sơ sẽ được trả về cho Đội thi công !</p>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label={t("nghiem_thu.ly_do_tra_lai")}
                name="noiDungTraHoSo"
                className="required-field"
              >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button
                key="back"
                onClick={handleCloseModalTraDoi}
                className="button-closed"
              >
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-tra-doi"
              >
                {t("button.xac_nhan")}
              </Button>
            </Col>
          </Row>
        </Container>
      </FormComponent>
    </Modal>
  );
}
