import { SearchOutlined } from "@ant-design/icons";
import FormComponent from "@components/Form";
import { removeAccents } from "@utils/function";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getListDonVi, getListNganHang, getListTeam } from "./services";

export default function ListBoLoc(props) {
  const { handleSearch, clearFilter, filterConditions, form } = props;
  const unitId = useSelector((state) => state.auth.user.unitId);
  const [loading, setLoading] = useState(false);
  const [listDonVi, setListDonVi] = useState([]);
  const [donVi, setDonVi] = useState(filterConditions.DonViId);
  const [listTeam, setListTeam] = useState([]);
  const [listNganHang, setListNganHang] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      TuNgay: filterConditions.TuNgay
        ? moment(new Date(filterConditions.TuNgay))
        : moment().subtract(7, "d"),
      DenNgay: filterConditions.DenNgay
        ? moment(new Date(filterConditions.DenNgay))
        : moment(new Date()),
    });
  }, [form, filterConditions]);

  useEffect(() => {
    getListDonVi(setLoading, setListDonVi);
    getListNganHang(setLoading, setListNganHang);
  }, []);

  useEffect(() => {
    if (donVi) {
      getListTeam(setLoading, setListTeam, donVi);
      form.setFieldsValue({
        DoiId: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donVi]);

  const handleChangeDonVi = (value) => {
    setDonVi(value);
  };

  useEffect(() => {
    if (listTeam && listTeam.length === 1) {
      form.setFieldsValue({
        DoiId: listTeam[0].value,
      });
    }
  }, [form, listTeam]);

  const clear = () => {
    form.resetFields();
    clearFilter();
    setDonVi(unitId);
  };
  useEffect(() => {
    form.setFieldsValue({
      ...(filterConditions.DoiId && { DoiId: filterConditions.DoiId }),
      ...(filterConditions.MaNganHang && {
        NganHang: filterConditions.MaNganHang,
      }),
      ...(filterConditions.SearchTerm && {
        TuKhoaTimKiem: filterConditions.SearchTerm,
      }),
      DonViId: filterConditions.DonViId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions]);

  return (
    <Styled>
      <div className="thanh-toan-bo-loc">
        <FormComponent
          layout="vertical"
          form={form}
          onFinish={handleSearch}
          initialValues={{
            DonViId: filterConditions.DonViId,
            DoiId: filterConditions.DoiId,
          }}
        >
          <Row gutter={[8, 16]}>
            <Col span={6}>
              <Form.Item name="DonViId" label="Đơn vị">
                <Select
                  onChange={handleChangeDonVi}
                  loading={loading}
                  showSearch
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                  disabled={listDonVi.length < 2}
                >
                  {listDonVi &&
                    listDonVi.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="DoiId" label="Tổ đội">
                <Select
                  allowClear
                  showSearch
                  placeholder="--Chọn đội--"
                  disabled={listTeam.length === 1}
                  loading={loading}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {listTeam &&
                    listTeam.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="NganHang" label="Ngân hàng">
                <Select
                  allowClear
                  showSearch
                  placeholder="--Chọn ngân hàng--"
                  loading={loading}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {listNganHang &&
                    listNganHang.map((item, id) => {
                      return (
                        <Select.Option
                          key={id}
                          value={item.value}
                          disabled={item.value === null}
                        >
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Form.Item name="TuNgay" label="Từ ngày">
                <DatePicker
                  // notClear
                  format={"DD/MM/YYYY"}
                  // defaultValue={
                  //   filterConditions.TuNgay
                  //     ? moment(new Date(filterConditions.TuNgay))
                  //     : moment().subtract(7, "d")
                  // }
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="DenNgay" label="Đến ngày">
                <DatePicker
                  format={"DD/MM/YYYY"}

                  // notClear
                  // formKey="DenNgay"
                  // form={form}
                  // defaultValue={
                  //   filterConditions.DenNgay
                  //     ? moment(new Date(filterConditions.DenNgay))
                  //     : moment()
                  // }
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name="TuKhoaTimKiem" label="Từ khóa tìm kiếm">
                <Input prefix={<SearchOutlined />} />
              </Form.Item>
            </Col>
            <Col span={6} offset={12} className="thanh-toan-bo-loc-button">
              <Button onClick={clear}>Bỏ lọc</Button>
              <Button type="primary" htmlType="submit">
                Tìm kiếm
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  .thanh-toan-bo-loc {
    .thanh-toan-bo-loc-button {
      display: flex;
      gap: 10px !important;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;
