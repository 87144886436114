import FormComponent from "@components/Form";
import { Button, Col, Form, Modal, Row } from "antd";
import React from "react";

function DongBoCMISModal(props) {
  const { visible, handleCloseModal, handleSubmit, loading } = props;

  const [form] = Form.useForm();

  return (
    // <LoadingComponent loading={loading}>
    <Modal
      title="Đồng bộ về CMIS."
      visible={visible}
      onCancel={handleCloseModal}
      footer={null}
    >
      <FormComponent form={form} onFinish={handleSubmit}>
        <Row gutter={24} justify="center" style={{ marginTop: "20px" }}>
          <Col span={20} >
            <h2 style={{ textAlign: "center" }}>Bạn có chắc chắn muốn đồng bộ về CMIS?</h2>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Button
              key="back"
              onClick={handleCloseModal}
              className="button-closed"
            >
              Đóng lại
            </Button>
          </Col>
          <Col span={12}>
            <Button
              key="submit"
              type="primary"
              className="button-primary"
              htmlType="submit"
              loading={loading}
            >
              Xác nhận
            </Button>
          </Col>
        </Row>
      </FormComponent>
    </Modal >
    // </LoadingComponent>
  );
}

export default DongBoCMISModal;
