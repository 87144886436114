const g1 = {
  g1: "g1",
  g1x: "g1x",
};

const g11 = {
  g11: "g11",
  g11x: "g11x",
};

const g12 = {
  g12: "g12",
  g12x: "g12x",
  g12bc: "g12bc",
};

const lotrinhnhanvien = {
  g: "g",
};

export const lo_trinh_nhan_vien = {
  ...lotrinhnhanvien,
  ...g1,
  ...g11,
  ...g12,
};
