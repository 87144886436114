import { Table, ConfigProvider } from "antd";
import LoadingComponent from "@components/Loading";
import vn from "antd/es/locale-provider/vi_VN";
import { Container } from "../../../css-styled";
export default function TyLeGiaBanDienTabs(props) {
  const { loading, genExtra, dataGiaBanDien, columns } = props;

  return (
    <LoadingComponent loading={loading}>
      {genExtra}
      <Container>
        <ConfigProvider locale={vn}>
          <Table
            dataSource={dataGiaBanDien}
            columns={columns}
            pagination={false}
            scroll={{ y: "500px", x: "1600px" }}
          />
        </ConfigProvider>
      </Container>
    </LoadingComponent>
  );
}
