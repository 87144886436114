import { Button, Form, Modal } from "antd";
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleDataLyDo } from "@modules/treo-thao/nhan-vien-treo-thao/yeu-cau-duoc-phan-cong/services";
import {
  FORMAT_DATE,
  FORMAT_DATE_MONTH,
  FORMAT_ENGLISH,
} from "@utils/constants";
import moment from "moment";
import { isEqual } from "lodash";
import { yeuCauDuocPhanCongSelector } from "@state/treo-thao/nhan-vien-treo-thao";
import {
  getSaveDataPopup,
  setCanShowDialogLeaving,
} from "@state/treo-thao/nhan-vien-treo-thao/reducer";
import {
  handleMultiList,
  handleXacNhanChuyenTC,
  handleUpdateCTSYNC,
  handleGetFileBBLD,
} from "./services";
import { alertMessage, scrollToTop } from "@utils/function";
import PDFModal from "@modules/cap-dien/common-ho-so/pdf-viewer";
import LapBBLDDuongDay from "./layout";
// const detail = {
//   id: "7ed11b41-9493-44d6-9475-a9c550d16b8a",
//   thongTinHienTai: {
//     maKhachHang: null,
//     tenKhachHang: "X1 - test 1",
//     tramId: "1a8dc1b0-1184-4e20-b10f-b5b46cea4c98",
//     tram: "Trạm E1.08",
//     maTram: "108",
//     maThietBi: null,
//     soThietBi: null,
//     maSoGCS: "PD0120421",
//     maGC: null,
//     soCot: null,
//     vhCong: null,
//     loaiCongTo: null,
//     soPha: 3,
//     diaChiDungDien: "Hàng Buồm, Hoàn Kiếm",
//     tenDoi: null,
//     tenNguoiDaiDien: null,
//     soDienThoai: "0964365561",
//     email: "Trangnguyen281097@gmail.com",
//   },
//   thongTinChung: {
//     loaiBienBan: 4,
//     lyDoTreoThaoId: "e88712dc-1d05-4726-906c-fd554bd94cf3",
//     lyDoTreoThao: "Treo mới TG 3 pha ĐT",
//     viTriTreoThao: 1,
//     viTriKhac: null,
//     soCot: null,
//     soHop: "4",
//     maChiHop: "test 1",
//     soVienChiHop: "2",
//     maChiBOOC: "test 2",
//     soVienChiBOOC: "4",
//     hsn: null,
//     isTreoThaoTUTI: false,
//     isDoXa: true,
//     gioDoXa: "23",
//     phutDoXa: "59",
//     hesid: "test hes",
//     gatewayID: "test gate",
//     soPha: 3,
//     nhanVienTreoThaoId: "fd7c5e0d-3366-4902-9196-fce2507cfff6",
//     nhanVienTreoThao: "Minh VH",
//     nhanVienNiemPhongId: "718ff5ac-c480-4493-84e8-1e4c2c5f17b1",
//     nhanVienNiemPhong: "NV Niêm phong 01",
//     maDiemDo: "892338",
//     soBienBan: "102200001",
//     loaiDiemDo: 5,
//   },
//   congToTreo: {
//     vhCong: "DT",
//     lapTrinhLaiCongTo: false,
//     layBoChiSoNhan: false,
//     qrCode: null,
//     isThayDoiLoaiDiemDo: true,
//     congToId: "3fb01fa8-4450-40c8-a56b-8967d665f273",
//     heSoNhanDoDem: 1,
//     loaiDiemDo: 5,
//     maCongTo: "588202121055141",
//     soCongTo: "21055141",
//     soCuaSo: 8,
//     loaiCongTo: "HHM-38GT 3x5(10)A, 3x57,7/100-240/415V, CCX0,5S",
//     soPha: 3,
//     soBieuGia: 3,
//     chieuDoDem: 2,
//     tySoBienDong: null,
//     tySoBienDienAp: null,
//     ngayKiemDinh: "16/07/2021",
//     thoiDiemLapTrinhCuoi: null,
//     maChiHop: "test 1",
//     soVienChiHop: 2,
//     maCBOOC: "test 2",
//     soVienCBOOC: "4",
//     soVienKiemDinh: null,
//     donViHienThi: "Kwh",
//     heSoNhanManHinh: "1",
//     maChungLoai: "588",
//     nuocSX: "VIE",
//     dienAp: "57,7/100-240/415",
//     dongDien: "5(10)",
//     bcs: "BT;CD;TD;SG;VC;BN;CN;TN;SN;VN",
//     boChiSos: [
//       {
//         bcs: "BT",
//         chiSoCu: "1122",
//         chiSoMoi: "1122",
//         pMax: "2",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 2,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },
//       {
//         bcs: "CD",
//         chiSoCu: "333",
//         chiSoMoi: "333",
//         pMax: "3",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 3,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },
//       {
//         bcs: "TD",
//         chiSoCu: "0",
//         chiSoMoi: "0",
//         pMax: "0",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 4,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },
//       {
//         bcs: "SG",
//         chiSoCu: "0",
//         chiSoMoi: "0",
//         pMax: "0",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 5,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },
//       {
//         bcs: "VC",
//         chiSoCu: "0",
//         chiSoMoi: "0",
//         pMax: "0",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 6,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },

//       {
//         bcs: "BN",
//         chiSoCu: "0",
//         chiSoMoi: "0",
//         pMax: "0",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 8,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },
//       {
//         bcs: "CN",
//         chiSoCu: "0",
//         chiSoMoi: "0",
//         pMax: "0",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 9,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },
//       {
//         bcs: "TN",
//         chiSoCu: "0",
//         chiSoMoi: "0",
//         pMax: "0",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 10,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },
//       {
//         bcs: "SN",
//         chiSoCu: "0",
//         chiSoMoi: "0",
//         pMax: "0",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 11,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },
//       {
//         bcs: "VN",
//         chiSoCu: "0",
//         chiSoMoi: "0",
//         pMax: "0",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 12,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },
//     ],
//     maTemKiemDinh: "-",
//     seryTemKiemDinh: "-",
//     soLanLapTrinh: 9,
//     ngayLapTrinh: "2021-08-27T00:00:00",
//     maChiKiemDinh: "VN/N309-HHM/07-24",
//     soChiKiemDinh: "2",
//     kimChiTai: "VN/N309-HHM/07-24",
//     soVienChiTai: 2,
//     maDonViKiemDinh: "15",
//     maNhanVienKiemDinh: "HHM",
//     maNhanVienLapTrinh: "19145",
//   },
//   congToThao: {
//     isThaoCongTo: false,
//     tinhTrangCongTo: null,
//     congToId: "00000000-0000-0000-0000-000000000000",
//     heSoNhanDoDem: null,
//     loaiDiemDo: null,
//     maCongTo: null,
//     soCongTo: null,
//     soCuaSo: 0,
//     loaiCongTo: null,
//     soPha: null,
//     soBieuGia: null,
//     chieuDoDem: null,
//     tySoBienDong: null,
//     tySoBienDienAp: null,
//     ngayKiemDinh: null,
//     thoiDiemLapTrinhCuoi: null,
//     maChiHop: null,
//     soVienChiHop: null,
//     maCBOOC: null,
//     soVienCBOOC: null,
//     soVienKiemDinh: null,
//     donViHienThi: null,
//     heSoNhanManHinh: null,
//     maChungLoai: null,
//     nuocSX: null,
//     dienAp: null,
//     dongDien: null,
//     bcs: null,
//     boChiSos: [],
//     maTemKiemDinh: null,
//     seryTemKiemDinh: null,
//     soLanLapTrinh: null,
//     ngayLapTrinh: null,
//     maChiKiemDinh: null,
//     soChiKiemDinh: null,
//     kimChiTai: null,
//     soVienChiTai: null,
//     maDonViKiemDinh: null,
//     maNhanVienKiemDinh: null,
//     maNhanVienLapTrinh: null,
//   },
//   tuDangTreo: {
//     isThao: false,
//     tutIs: [],
//   },
//   tiDangTreo: {
//     isThao: false,
//     tutIs: [],
//   },
//   tuTreoMoi: {
//     nangHaCongSuat: false,
//     tutIs: [],
//   },
//   tiTreoMoi: {
//     nangHaCongSuat: false,
//     tutIs: [],
//   },
//   vatTus: {
//     vatTuBenBans: [],
//     vatTuBenMuas: [],
//     vatTuThuHois: [],
//   },
//   isUpdated: true,
// };

export default function Index(props) {
  const {
    detail,
    loading,
    setFetchList,
    selectedRowKeys,
    defaultCTTreo,
    detailHoSo,
  } = props;
  const [dataLapDat, setDataLapDat] = useState(detail);
  const [isDelCT, setIsDelCT] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [keyTab, setKeyTab] = useState("1");
  const [load, setLoading] = useState(false);
  const [dataLyDo, setDataLyDo] = useState([]);
  const [isResetIsThao, setIsResetIsThao] = useState(false);
  const [isResetIsTUTI, setIsResetIsTUTI] = useState(false);
  const [, setIsChangedTIMoi] = useState(false);
  const [, setIsChangedTUMoi] = useState(false);
  const [customTINew, setCustomTINew] = useState(dataLapDat?.tiTreoMoi);
  const [customTUNew, setCustomTUNew] = useState(dataLapDat?.tuTreoMoi);
  const [dataDetaiCTTreolUpdate, setDataDetaiCTTreolUpdate] = useState(
    dataLapDat?.congToTreo
  );
  const [soCot, setSoCot] = useState(dataLapDat?.thongTinHienTai?.soCot);
  const { saveData } = useSelector(yeuCauDuocPhanCongSelector);
  const [changeTab1, setChangeTab1] = useState(false);
  const [doingChange, setDoingChange] = useState(false);
  const [changeTab2, setChangeTab2] = useState(false);
  const [changeTab3, setChangeTab3] = useState(false);
  const [dataNVNiemPhong, setDataNVNiemPhong] = useState([]);
  const [dataNVTreoThao, setDataNVTreoThao] = useState([]);
  const [type, setType] = useState("cap-nhat");
  const [visibleInBBLD, setVisibleInBBLD] = useState(false);
  const [loadingBBLD, setLoadingBBLD] = useState(false);
  const [disbledXN, setDisabledXN] = useState(
    !dataLapDat.isUpdated ? true : false
  );
  const [dataSBB, setDataSoBB] = useState(dataLapDat?.thongTinChung?.soBienBan);
  const [countKey, setCountKey] = useState(0);
  const [chiSoMoiMax, setChiSoMoiMax] = useState(false);
  const toDay = moment();
  const [ngay, setNgay] = useState(
    detail.thongTinChung?.ngayTreoThao
      ? moment(detail.thongTinChung?.ngayTreoThao)
      : toDay
  );

  useEffect(() => {
    handleMultiList(
      setLoading,
      setDataNVNiemPhong,
      setDataNVTreoThao,
      detailHoSo
    );
  }, [detailHoSo]);

  //set key tab
  function callback(key) {
    // if (key !== "1") {
    if (
      form.getFieldValue("lyDoTreoThaoId") === null ||
      form.getFieldValue("lyDoTreoThaoId") === "" ||
      form.getFieldValue("nhanVienNiemPhongId") === null ||
      form.getFieldValue("nhanVienNiemPhongId") === "" ||
      form.getFieldValue("nhanVienTreoThaoId") === null ||
      form.getFieldValue("nhanVienTreoThaoId") === ""
    ) {
      alertMessage(
        "error",
        "Thông báo!",
        "Vui lòng nhập dữ liệu đầy đủ ở Thông tin chung"
      );
    } else {
      const count = countKey + 1;
      setCountKey(count);
      setKeyTab(key);
    }
  }

  //get  data lý do
  const getDataLyDo = useCallback((loaiBienBan) => {
    handleDataLyDo(setLoading, loaiBienBan, setDataLyDo);
  }, []);

  //fetch data lý do
  useEffect(() => {
    getDataLyDo(
      dataLapDat?.thongTinChung.loaiBienBan !== null
        ? dataLapDat?.thongTinChung.loaiBienBan
        : "0"
    );
  }, [dataLapDat?.thongTinChung.loaiBienBan, getDataLyDo]);

  //click type button submit
  const handleTypeClick = (e, type) => {
    setType(type);
  };

  //modal confirm check bộ chỉ số KG/KN
  const showModalConfirmBCS = (content, payload) => {
    return Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content,
      onOk() {
        handleUpdateCTSYNC(
          setLoading,
          payload,
          setFetchList,
          setDisabledXN,
          setDataSoBB,
          detailHoSo,
          setDataLapDat,
          dispatch,
          loading,
          setChangeTab1,
          setChangeTab2,
          setChangeTab3
        );
      },
      onCancel() {},
      okText: "Đồng ý",
      okButtonProps: { type: "danger" },
      cancelText: "Hủy",
    });
  };

  //call api sau khi check
  const callAPISubmit = (arrCTTreo, payload) => {
    if (arrCTTreo.length > 0) {
      let arrTreoThao = [...dataDetaiCTTreolUpdate.boChiSos];
      if (countKey > 0) arrTreoThao = arrCTTreo;
      if (
        !form.getFieldValue("isThaoCongTo") ||
        !form.getFieldValue("isTreoThaoTUTI")
      ) {
        let totalSG = 0;
        let totalSN = 0;
        let valueCSMoiKG = 0;
        let valueCSMoiKN = 0;
        const filterArrKG = arrTreoThao.filter((el) => el.bcs === "SG");
        const filterArrKN = arrTreoThao.filter((el) => el.bcs === "SN");
        arrTreoThao.forEach((item) => {
          if (item.bcs === "BT" || item.bcs === "CD" || item.bcs === "TD")
            totalSG += item.chiSoMoi ? parseInt(item.chiSoMoi) : 0;
          if (item.bcs === "BN" || item.bcs === "CN" || item.bcs === "TN")
            totalSN += item.chiSoMoi ? parseInt(item.chiSoMoi) : 0;
          if (item.bcs === "SG") valueCSMoiKG = item.chiSoMoi;
          if (item.bcs === "SN") valueCSMoiKN = item.chiSoMoi;
        });
        if (filterArrKG.length > 0 && filterArrKN.length > 0) {
          if (
            (parseInt(valueCSMoiKG) < totalSG - 3 ||
              parseInt(valueCSMoiKG) > totalSG + 3) &&
            (parseInt(valueCSMoiKN) < totalSN - 3 ||
              parseInt(valueCSMoiKN) > totalSN + 3)
          ) {
            showModalConfirmBCS(
              `Tổng chỉ số 2 kênh giao và nhận SG/SN đang chênh lệch ± 3 so với tổng BCS (BT/BN + CD/CN + TD/TN)`,
              payload
            );
          } else if (
            parseInt(valueCSMoiKG) < totalSG - 3 ||
            parseInt(valueCSMoiKG) > totalSG + 3
          ) {
            showModalConfirmBCS(
              `Tổng chỉ số SG của công tơ TREO đang chênh lệch ± 3 so với tổng BCS (BT + CD + TD)`,
              payload
            );
          } else if (
            parseInt(valueCSMoiKN) < totalSN - 3 ||
            parseInt(valueCSMoiKN) > totalSN + 3
          ) {
            showModalConfirmBCS(
              `Tổng chỉ số SN của công tơ TREO đang chênh lệch ± 3 so với tổng BCS (BN + CN + TN)`,
              payload
            );
          } else {
            handleUpdateCTSYNC(
              setLoading,
              payload,
              setFetchList,
              setDisabledXN,
              setDataSoBB,
              detailHoSo,
              setDataLapDat,
              dispatch,
              loading,
              setChangeTab1,
              setChangeTab2,
              setChangeTab3
            );
          }
        } else if (filterArrKG.length > 0) {
          if (
            parseInt(valueCSMoiKG) < totalSG - 3 ||
            parseInt(valueCSMoiKG) > totalSG + 3
          ) {
            showModalConfirmBCS(
              `Tổng chỉ số SG của công tơ TREO đang chênh lệch ± 3 so với tổng BCS (BT + CD + TD)`,
              payload
            );
          } else {
            handleUpdateCTSYNC(
              setLoading,
              payload,
              setFetchList,
              setDisabledXN,
              setDataSoBB,
              detailHoSo,
              setDataLapDat,
              dispatch,
              loading,
              setChangeTab1,
              setChangeTab2,
              setChangeTab3
            );
          }
        } else {
          if (
            parseInt(valueCSMoiKN) < totalSN - 3 ||
            parseInt(valueCSMoiKN) > totalSN + 3
          ) {
            showModalConfirmBCS(
              `Tổng chỉ số SN của công tơ TREO đang chênh lệch ± 3 so với tổng BCS (BN + CN + TN)`,
              payload
            );
          } else {
            handleUpdateCTSYNC(
              setLoading,
              payload,
              setFetchList,
              setDisabledXN,
              setDataSoBB,
              detailHoSo,
              setDataLapDat,
              dispatch,
              loading,
              setChangeTab1,
              setChangeTab2,
              setChangeTab3
            );
          }
        }
      }
    } else {
      handleUpdateCTSYNC(
        setLoading,
        payload,
        setFetchList,
        setDisabledXN,
        setDataSoBB,
        detailHoSo,
        setDataLapDat,
        dispatch,
        loading,
        setChangeTab1,
        setChangeTab2,
        setChangeTab3
      );
    }
  };

  //submit form
  const onFinish = (values) => {
    if (type === "cap-nhat") {
      let validate = false;
      const dataCTThao = form.getFieldValue("defaultCTThao")
        ? JSON.parse(form.getFieldValue("defaultCTThao"))
        : dataLapDat.congToThao;
      const arrTiDangTreo = [];
      if (dataLapDat.tiDangTreo.tutIs.length > 0) {
        dataLapDat.tiDangTreo.tutIs.map((item, index) => {
          return arrTiDangTreo.push({
            ...item,
            isChayHong: form.getFieldValue(`chayHongTI-${index}`),
          });
        });
      }

      const arrTuDangTreo = [];
      if (dataLapDat.tuDangTreo.tutIs.length > 0) {
        dataLapDat.tuDangTreo.tutIs.map((item, index) => {
          return arrTuDangTreo.push({
            ...item,
            isChayHong: form.getFieldValue(`chayHongTU-${index}`),
          });
        });
      }

      const arrCTTreo = [];
      if (dataDetaiCTTreolUpdate.boChiSos.length > 0) {
        dataDetaiCTTreolUpdate.boChiSos.forEach((item, index) => {
          const date =
            item.ngayPMax !== null
              ? item.ngayPMax
              : moment(moment()).format(FORMAT_DATE);
          const chiSoMoi = item.chiSoMoi !== null ? item.chiSoMoi : "0";
          const pMax = item.pMax !== null ? item.pMax : "0";
          arrCTTreo.push({
            ...item,
            chiSoMoi:
              countKey > 0 && form.getFieldValue(`chiSoMoi-${index}`)
                ? form.getFieldValue(`chiSoMoi-${index}`)
                : chiSoMoi,
            pMax:
              countKey > 0 && form.getFieldValue(`pMax-${index}`)
                ? form.getFieldValue(`pMax-${index}`)
                : pMax,
            ngayPMax:
              countKey > 0 && form.getFieldValue(`ngayPMax-${index}`)
                ? form.getFieldValue(`ngayPMax-${index}`)
                : date,
          });
        });
      }
      const thietBiTI1 =
        customTINew?.tutIs[0]?.thietBiId !== null ||
        customTINew?.tutIs[0]?.thietBiId !== ""
          ? customTINew?.tutIs[0]?.thietBiId
          : null;
      const thietBiTI2 =
        customTINew?.tutIs[1]?.thietBiId !== null ||
        customTINew?.tutIs[1]?.thietBiId !== ""
          ? customTINew?.tutIs[1]?.thietBiId
          : null;
      const thietBiTI3 =
        customTINew?.tutIs[2]?.thietBiId !== null ||
        customTINew?.tutIs[2]?.thietBiId !== ""
          ? customTINew?.tutIs[2]?.thietBiId
          : null;

      const thietBiTU1 =
        customTUNew?.tutIs[0]?.thietBiId !== null ||
        customTUNew?.tutIs[0]?.thietBiId !== ""
          ? customTUNew?.tutIs[0]?.thietBiId
          : null;
      const thietBiTU2 =
        customTUNew?.tutIs[1]?.thietBiId !== null ||
        customTUNew?.tutIs[1]?.thietBiId !== ""
          ? customTUNew?.tutIs[1]?.thietBiId
          : null;
      const thietBiTU3 =
        customTUNew?.tutIs[2]?.thietBiId !== null ||
        customTUNew?.tutIs[2]?.thietBiId !== ""
          ? customTUNew?.tutIs[2]?.thietBiId
          : null;
      if (
        dataLapDat.thongTinChung.soPha === 3 ||
        dataLapDat.thongTinChung.soPha === "3"
      ) {
        if (
          thietBiTI1 === null &&
          thietBiTI2 === null &&
          thietBiTI3 === null &&
          thietBiTU1 === null &&
          thietBiTU2 === null &&
          thietBiTU3 === null
        ) {
          validate = false;
          form.setFields([
            {
              name: [`maTI-0`],
              errors: false,
            },
            {
              name: [`maTI-1`],
              errors: false,
            },
            {
              name: [`maTI-2`],
              errors: false,
            },
            {
              name: [`maTU-0`],
              errors: false,
            },
            {
              name: [`maTU-1`],
              errors: false,
            },
            {
              name: [`maTU-2`],
              errors: false,
            },
          ]);
        } else if (
          (thietBiTI1 === null && thietBiTI2 !== null) ||
          (thietBiTI1 === null && thietBiTI3 !== null) ||
          (thietBiTI2 === null && thietBiTI1 !== null) ||
          (thietBiTI2 === null && thietBiTI3 !== null) ||
          (thietBiTI3 === null && thietBiTI1 !== null) ||
          (thietBiTI3 === null && thietBiTI2 !== null)
        ) {
          alertMessage(
            "error",
            "Thông báo!",
            "Vui lòng nhập đầy đủ thông tin TI Treo mới"
          );
          validate = true;
        } else if (
          (thietBiTU1 === null && thietBiTU2 !== null) ||
          (thietBiTU1 === null && thietBiTU3 !== null) ||
          (thietBiTU2 === null && thietBiTU1 !== null) ||
          (thietBiTU2 === null && thietBiTU3 !== null) ||
          (thietBiTU3 === null && thietBiTU1 !== null) ||
          (thietBiTU3 === null && thietBiTU2 !== null)
        ) {
          alertMessage(
            "error",
            "Thông báo!",
            "Vui lòng nhập đầy đủ thông tin TU Treo mới"
          );

          validate = true;
        }
      } else validate = false;
      const payload = {
        id: selectedRowKeys.toString(),
        thongTinHienTai: {
          ...dataLapDat.thongTinHienTai,
        },
        thongTinChung: {
          ...dataLapDat.thongTinChung,
          loaiBienBan:
            detail?.thongTinChung.loaiBienBan === "4" ||
            detail?.thongTinChung.loaiBienBan === 4
              ? parseInt(detail?.thongTinChung.loaiBienBan)
              : parseInt(values.loaiBienBan),
          // loaiBienBan: parseInt(values.loaiBienBan),
          lyDoTreoThaoId: values.lyDoTreoThaoId,
          // viTriTreoThao: parseInt(values.viTriTreoThao),
          // soCot: values.soCot ? values.soCot : null,
          soHop: values.soHop ? values.soHop : null,
          hsn: values.hsn ? values.hsn : null,
          maChiHop: values.maChiHop ? values.maChiHop : null,
          maChiBOOC: values.maChiBOOC ? values.maChiBOOC : null,
          soVienChiBOOC: values.soVienChiBOOC ? values.soVienChiBOOC : null,
          soVienChiHop: values.soVienChiHop ? values.soVienChiHop : null,
          isDoXa: form.getFieldValue("isDoXa"),
          isTreoThaoTUTI: form.getFieldValue("isTreoThaoTUTI"),
          gioDoXa: values.gioDoXa ? values.gioDoXa : null,
          phutDoXa: values.phutDoXa ? values.phutDoXa : null,
          hesid: values.hesid ? values.hesid : null,
          gatewayID: values.gatewayID ? values.gatewayID : null,
          nhanVienTreoThaoId: values.nhanVienTreoThaoId
            ? values.nhanVienTreoThaoId
            : null,
          nhanVienNiemPhongId: values.nhanVienNiemPhongId
            ? values.nhanVienNiemPhongId
            : null,
          ngayTreoThao: moment(ngay).format(FORMAT_ENGLISH),
        },
        congToThao: {
          ...dataCTThao,
          isThaoCongTo: form.getFieldValue("isThaoCongTo"),
        },
        congToTreo:
          form.getFieldValue("isThaoCongTo") ||
          (dataDetaiCTTreolUpdate.boChiSos.length === 0 &&
            detail?.isCapDien &&
            detailHoSo.maLoaiYeuCau !== "CDHA_D")
            ? null
            : form.getFieldValue("isTreoThaoTUTI")
            ? {
                ...dataCTThao,
                congToId: "00000000-0000-0000-0000-000000000000",
                isThayDoiLoaiDiemDo: false,
                // loaiDiemDo: null,
                // countKey > 0 && form.getFieldValue("loaiDiemDo")
                //   ? parseInt(form.getFieldValue("loaiDiemDo"))
                //   : detail?.thongTinChung?.loaiDiemDo === null
                //   ? 1
                //   : detail?.thongTinChung?.loaiDiemDo,
                lapTrinhLaiCongTo: false,
                layBoChiSoNhan: false,
              }
            : {
                ...dataDetaiCTTreolUpdate,
                boChiSos: arrCTTreo,
                isThayDoiLoaiDiemDo: form.getFieldValue("isThayDoiLoaiDiemDo"),
                loaiDiemDo:
                  countKey > 0 && form.getFieldValue("loaiDiemDo")
                    ? parseInt(form.getFieldValue("loaiDiemDo"))
                    : detail?.thongTinChung?.loaiDiemDo === null
                    ? 1
                    : detail?.thongTinChung?.loaiDiemDo,
                lapTrinhLaiCongTo: form.getFieldValue("lapTrinhLaiCongTo"),
                layBoChiSoNhan: form.getFieldValue("layBoChiSoNhan"),
                soPha: parseInt(dataDetaiCTTreolUpdate.soPha),
              },
        tuDangTreo: {
          ...dataLapDat.tuDangTreo,
          isThao: form.getFieldValue("isThaoTU")
            ? form.getFieldValue("isThaoTU")
            : dataLapDat.tuDangTreo.isThao,
          tutIs: arrTuDangTreo,
        },
        tiDangTreo: {
          ...dataLapDat.tiDangTreo,
          isThao: form.getFieldValue("isThaoTI")
            ? form.getFieldValue("isThaoTI")
            : dataLapDat.tiDangTreo.isThao,
          tutIs: arrTiDangTreo,
        },
        tuTreoMoi: {
          ...customTUNew,
          tutIs:
            customTUNew?.tutIs[0]?.thietBiId === null &&
            customTUNew?.tutIs[1]?.thietBiId === null &&
            customTUNew?.tutIs[2]?.thietBiId === null
              ? []
              : customTUNew.tutIs,
        },
        tiTreoMoi: {
          ...customTINew,
          tutIs:
            customTINew?.tutIs[0]?.thietBiId === null &&
            customTINew?.tutIs[1]?.thietBiId === null &&
            customTINew?.tutIs[2]?.thietBiId === null
              ? []
              : customTINew.tutIs,
        },
        isWeb: true,
      };
      if (!validate) {
        if (!chiSoMoiMax) {
          if (
            dataLapDat.thongTinChung.loaiBienBan === "4" ||
            dataLapDat.thongTinChung.loaiBienBan === 4
            // (dataLapDat.thongTinChung.loaiBienBan === 5 &&
            //   !form.getFieldValue("isThaoCongTo")) ||
            // (dataLapDat.thongTinChung.loaiBienBan === "5" &&
            //   !form.getFieldValue("isThaoCongTo"))
          ) {
            // if (
            //   dataDetaiCTTreolUpdate.maCongTo &&
            //   dataDetaiCTTreolUpdate.soCongTo
            // ) {
            //   callAPISubmit(arrCTTreo, payload);
            // } else {
            //   alertMessage(
            //     "error",
            //     "Thông báo!",
            //     "Vui lòng nhập thông tin công tơ Treo mới!"
            //   );
            // }
            callAPISubmit(arrCTTreo, payload);
          } else {
            callAPISubmit(arrCTTreo, payload);
          }
        }
      }
    } else {
      const payload = {
        hoSoId: detailHoSo.id,
      };
      //call api Xác nhận chuyển an toàn
      handleXacNhanChuyenTC(setLoading, payload, setFetchList, dispatch);
    }
  };
  //change value form
  const handleChangeValueForm = useCallback(
    (changedValues, allValues) => {
      //xử lý cho tab 1
      const objectFieldTab1 = {
        ...dataLapDat.thongTinChung,
        loaiBienBan: parseInt(allValues.loaiBienBan),
        lyDoTreoThaoId: allValues.lyDoTreoThaoId,
        viTriTreoThao: allValues.viTriTreoThao,
        soCot: allValues.soCot ? allValues.soCot : null,
        soHop: allValues.soHop ? allValues.soHop : null,
        hsn: allValues.hsn ? allValues.hsn : null,
        maChiHop: allValues.maChiHop ? allValues.maChiHop : null,
        maChiBOOC: allValues.maChiBOOC ? allValues.maChiBOOC : null,
        soVienChiBOOC: allValues.soVienChiBOOC ? allValues.soVienChiBOOC : null,
        soVienChiHop: allValues.soVienChiHop ? allValues.soVienChiHop : null,
        isDoXa: form.getFieldValue("isDoXa"),
        isTreoThaoTUTI: form.getFieldValue("isTreoThaoTUTI"),
        gioDoXa: allValues.gioDoXa ? allValues.gioDoXa : null,
        phutDoXa: allValues.phutDoXa ? allValues.phutDoXa : null,
        hesid: allValues.hesid ? allValues.hesid : null,
        gatewayID: allValues.gatewayID ? allValues.gatewayID : null,
        nhanVienNiemPhongId: allValues.nhanVienNiemPhongId
          ? allValues.nhanVienNiemPhongId
          : null,
        nhanVienTreoThaoId: allValues.nhanVienTreoThaoId
          ? allValues.nhanVienTreoThaoId
          : null,
      };
      if (isEqual(objectFieldTab1, dataLapDat.thongTinChung)) {
        setChangeTab1(false);
        setDoingChange(false);
        // dispatch(getSaveDataPopup(false));
      } else {
        setDoingChange(true);
        setChangeTab1(true);
        // dispatch(getSaveDataPopup(true));
      }

      //xử lý cho tab 2
      if (
        form.getFieldValue("isThaoCongTo") ===
          dataLapDat.congToThao.isThaoCongTo &&
        form.getFieldValue("isThayDoiLoaiDiemDo") ===
          dataLapDat.congToTreo.isThayDoiLoaiDiemDo &&
        form.getFieldValue("lapTrinhLaiCongTo") ===
          dataLapDat.congToTreo.lapTrinhLaiCongTo &&
        form.getFieldValue("layBoChiSoNhan") ===
          dataLapDat.congToTreo.layBoChiSoNhan
      ) {
        setChangeTab2(false);
      } else {
        setChangeTab2(true);
      }

      //xử lý tab 3
      const arrTuDangTreo = [];
      if (dataLapDat.tuDangTreo.tutIs.length > 0) {
        dataLapDat.tuDangTreo.tutIs.map((item, index) => {
          return arrTuDangTreo.push({
            ...item,
            isChayHong: form.getFieldValue(`chayHongTU-${index}`),
          });
        });
      }
      const tuDangTreo = {
        ...dataLapDat.tuDangTreo,
        isThao: form.getFieldValue("isThaoTU")
          ? form.getFieldValue("isThaoTU")
          : dataLapDat.tuDangTreo.isThao,
        tutIs: arrTuDangTreo,
      };

      const arrTiDangTreo = [];
      if (dataLapDat.tiDangTreo.tutIs.length > 0) {
        dataLapDat.tiDangTreo.tutIs.map((item, index) => {
          return arrTiDangTreo.push({
            ...item,
            isChayHong: form.getFieldValue(`chayHongTI-${index}`),
          });
        });
      }

      const tiDangTreo = {
        ...dataLapDat.tiDangTreo,
        isThao: form.getFieldValue("isThaoTI")
          ? form.getFieldValue("isThaoTI")
          : dataLapDat.tiDangTreo.isThao,
        tutIs: arrTiDangTreo,
      };
      const isNangHaTi = form.getFieldValue("isNangHaTI")
        ? form.getFieldValue("isNangHaTI")
        : false;
      const isNangHaTu = form.getFieldValue("isNangHaTU")
        ? form.getFieldValue("isNangHaTU")
        : false;
      if (
        isEqual(tuDangTreo, dataLapDat.tuDangTreo) &&
        isEqual(tiDangTreo, dataLapDat.tiDangTreo) &&
        isNangHaTi === dataLapDat.tiTreoMoi.nangHaCongSuat &&
        isNangHaTu === dataLapDat.tuTreoMoi.nangHaCongSuat
      ) {
        setChangeTab3(false);
      } else {
        setChangeTab3(true);
      }
    },
    [
      dataLapDat.congToThao.isThaoCongTo,
      dataLapDat.congToTreo.isThayDoiLoaiDiemDo,
      dataLapDat.congToTreo.lapTrinhLaiCongTo,
      dataLapDat.congToTreo.layBoChiSoNhan,
      dataLapDat.thongTinChung,
      dataLapDat.tiDangTreo,
      dataLapDat.tiTreoMoi.nangHaCongSuat,
      dataLapDat.tuDangTreo,
      dataLapDat.tuTreoMoi.nangHaCongSuat,
      form,
    ]
  );

  useEffect(() => {
    if (changeTab1 || changeTab2 || changeTab3)
      dispatch(getSaveDataPopup(true));
    else dispatch(getSaveDataPopup(false));
  }, [changeTab1, changeTab2, changeTab3, dispatch]);

  useEffect(() => {
    if (saveData) {
      dispatch(setCanShowDialogLeaving(true));
    } else {
      dispatch(setCanShowDialogLeaving(false));
    }
  }, [dispatch, saveData]);

  const showModalInBienBanLapDat = useMemo(
    () =>
      visibleInBBLD && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visibleInBBLD}
            setVisible={setVisibleInBBLD}
            title="Xem trước in biên bản lắp đặt"
            getBienBan={(setUrl) => showModalBBApGia(setUrl)}
            loadingGetBienBan={loadingBBLD}
            isDownloadExcel={false}
            isReload={false}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visibleInBBLD]
  );

  const base64toBlob = (data) => {
    try {
      const base64WithoutPrefix = data.substr(
        "data:application/pdf;base64,".length
      );
      const bytes = atob(base64WithoutPrefix);
      let length = bytes.length;
      const out = new Uint8Array(length);

      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      return new Blob([out], { type: "application/pdf" });
    } catch (error) {
      alertMessage("error", "Thông báo", "Định dạng file không hỗ trợ!");
    }
  };

  const showModalBBApGia = useCallback(
    (setUrl) => {
      handleGetFileBBLD(dataLapDat?.id, setLoadingBBLD, (file) => {
        const blob = base64toBlob(file);
        const url = URL.createObjectURL(blob);
        setUrl(url);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataLapDat]
  );

  const genExtra = () => (
    <div>
      <Button
        onClick={() => setVisibleInBBLD(true)}
        type="primary"
        className="a-to-button"
      >
        Biên bản lắp đặt
      </Button>
    </div>
  );
  const ref1 = useRef(null);
  useEffect(() => {
    scrollToTop(ref1);
  }, []);
  return (
    <div ref={ref1}>
      <LapBBLDDuongDay
        loading={loading}
        load={load}
        detail={dataLapDat}
        genExtra={genExtra}
        soCot={soCot}
        form={form}
        handleChangeValueForm={handleChangeValueForm}
        onFinish={onFinish}
        keyTab={keyTab}
        callback={callback}
        changeTab1={changeTab1}
        changeTab2={changeTab2}
        changeTab3={changeTab3}
        dataLyDo={dataLyDo}
        getDataLyDo={getDataLyDo}
        setIsResetIsTUTI={setIsResetIsTUTI}
        setIsResetIsThao={setIsResetIsThao}
        isResetIsTUTI={isResetIsTUTI}
        defaultCTTreo={defaultCTTreo}
        setSoCot={setSoCot}
        dataDetaiCTTreolUpdate={dataDetaiCTTreolUpdate}
        setChangeTab1={setChangeTab1}
        setChangeTab2={setChangeTab2}
        setChangeTab3={setChangeTab3}
        doingChange={doingChange}
        dataNVNiemPhong={dataNVNiemPhong}
        dataNVTreoThao={dataNVTreoThao}
        isResetIsThao={isResetIsThao}
        setDataDetaiCTTreolUpdate={setDataDetaiCTTreolUpdate}
        detailHoSo={detailHoSo}
        setCustomTUNew={setCustomTUNew}
        setIsChangedTUMoi={setIsChangedTUMoi}
        setIsChangedTIMoi={setIsChangedTIMoi}
        setCustomTINew={setCustomTINew}
        showModalInBienBanLapDat={showModalInBienBanLapDat}
        handleTypeClick={handleTypeClick}
        setIsDelCT={setIsDelCT}
        isDelCT={isDelCT}
        disbledXN={disbledXN}
        dataSBB={dataSBB}
        setChiSoMoiMax={setChiSoMoiMax}
        setNgay={setNgay}
        ngay={ngay}
      />
    </div>
  );
}
