import { Button, Collapse, Form, Modal, Row, Tabs, Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "@components/Loading";
import {
  handleDataLyDo,
  handleUpdateCTSYNC,
} from "@modules/treo-thao/nhan-vien-treo-thao/yeu-cau-duoc-phan-cong/services";
import ThongTinHientai from "@modules/treo-thao/common-ver1/lap-bien-ban-treo-thao/thong-tin-hien-tai";
import ThongTinChung from "@modules/treo-thao/common-ver1/lap-bien-ban-treo-thao/thong-tin-chung";
import Congto from "@modules/treo-thao/common-ver1/lap-bien-ban-treo-thao/cong-to";
import Tuti from "@modules/treo-thao/common-ver1/lap-bien-ban-treo-thao/tu-ti";
import {
  FORMAT_DATE,
  FORMAT_DATE_MONTH,
  FORMAT_ENGLISH,
} from "@utils/constants";
import moment from "moment";
import { debounce, isEqual } from "lodash";
import { yeuCauDuocPhanCongSelector } from "@state/treo-thao/nhan-vien-treo-thao";
// import { useNavigatingAway } from "@hooks/useNavigatingAway";
import {
  getSaveDataPopup,
  setCanShowDialogLeaving,
} from "@state/treo-thao/nhan-vien-treo-thao/reducer";
import { alertMessage } from "@utils/function";
// import ModalWarningChange from "@components/Layout/ModalWarningChange";
const { Panel } = Collapse;

// const detail = {
//   id: "7ed11b41-9493-44d6-9475-a9c550d16b8a",
//   thongTinHienTai: {
//     maKhachHang: null,
//     tenKhachHang: "X1 - test 1",
//     tramId: "1a8dc1b0-1184-4e20-b10f-b5b46cea4c98",
//     tram: "Trạm E1.08",
//     maTram: "108",
//     maThietBi: null,
//     soThietBi: null,
//     maSoGCS: "PD0120421",
//     maGC: null,
//     soCot: null,
//     vhCong: null,
//     loaiCongTo: null,
//     soPha: 3,
//     diaChiDungDien: "Hàng Buồm, Hoàn Kiếm",
//     tenDoi: null,
//     tenNguoiDaiDien: null,
//     soDienThoai: "0964365561",
//     email: "Trangnguyen281097@gmail.com",
//   },
//   thongTinChung: {
//     loaiBienBan: 4,
//     lyDoTreoThaoId: "e88712dc-1d05-4726-906c-fd554bd94cf3",
//     lyDoTreoThao: "Treo mới TG 3 pha ĐT",
//     viTriTreoThao: 1,
//     viTriKhac: null,
//     soCot: null,
//     soHop: "4",
//     maChiHop: "test 1",
//     soVienChiHop: "2",
//     maChiBOOC: "test 2",
//     soVienChiBOOC: "4",
//     hsn: null,
//     isTreoThaoTUTI: false,
//     isDoXa: true,
//     gioDoXa: "23",
//     phutDoXa: "59",
//     hesid: "test hes",
//     gatewayID: "test gate",
//     soPha: 3,
//     nhanVienTreoThaoId: "fd7c5e0d-3366-4902-9196-fce2507cfff6",
//     nhanVienTreoThao: "Minh VH",
//     nhanVienNiemPhongId: "718ff5ac-c480-4493-84e8-1e4c2c5f17b1",
//     nhanVienNiemPhong: "NV Niêm phong 01",
//     maDiemDo: "892338",
//     soBienBan: "102200001",
//     loaiDiemDo: 5,
//   },
//   congToTreo: {
//     vhCong: "DT",
//     lapTrinhLaiCongTo: false,
//     layBoChiSoNhan: false,
//     qrCode: null,
//     isThayDoiLoaiDiemDo: true,
//     congToId: "3fb01fa8-4450-40c8-a56b-8967d665f273",
//     heSoNhanDoDem: 1,
//     loaiDiemDo: 5,
//     maCongTo: "588202121055141",
//     soCongTo: "21055141",
//     soCuaSo: 8,
//     loaiCongTo: "HHM-38GT 3x5(10)A, 3x57,7/100-240/415V, CCX0,5S",
//     soPha: 3,
//     soBieuGia: 3,
//     chieuDoDem: 2,
//     tySoBienDong: null,
//     tySoBienDienAp: null,
//     ngayKiemDinh: "16/07/2021",
//     thoiDiemLapTrinhCuoi: null,
//     maChiHop: "test 1",
//     soVienChiHop: 2,
//     maCBOOC: "test 2",
//     soVienCBOOC: "4",
//     soVienKiemDinh: null,
//     donViHienThi: "Kwh",
//     heSoNhanManHinh: "1",
//     maChungLoai: "588",
//     nuocSX: "VIE",
//     dienAp: "57,7/100-240/415",
//     dongDien: "5(10)",
//     bcs: "BT;CD;TD;SG;VC;BN;CN;TN;SN;VN",
//     boChiSos: [
//       {
//         bcs: "BT",
//         chiSoCu: "1122",
//         chiSoMoi: "1122",
//         pMax: "2",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 2,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },
//       {
//         bcs: "CD",
//         chiSoCu: "333",
//         chiSoMoi: "333",
//         pMax: "3",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 3,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },
//       {
//         bcs: "TD",
//         chiSoCu: "0",
//         chiSoMoi: "0",
//         pMax: "0",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 4,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },
//       {
//         bcs: "SG",
//         chiSoCu: "0",
//         chiSoMoi: "0",
//         pMax: "0",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 5,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },
//       {
//         bcs: "VC",
//         chiSoCu: "0",
//         chiSoMoi: "0",
//         pMax: "0",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 6,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },

//       {
//         bcs: "BN",
//         chiSoCu: "0",
//         chiSoMoi: "0",
//         pMax: "0",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 8,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },
//       {
//         bcs: "CN",
//         chiSoCu: "0",
//         chiSoMoi: "0",
//         pMax: "0",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 9,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },
//       {
//         bcs: "TN",
//         chiSoCu: "0",
//         chiSoMoi: "0",
//         pMax: "0",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 10,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },
//       {
//         bcs: "SN",
//         chiSoCu: "0",
//         chiSoMoi: "0",
//         pMax: "0",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 11,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },
//       {
//         bcs: "VN",
//         chiSoCu: "0",
//         chiSoMoi: "0",
//         pMax: "0",
//         ngayPMax: "11/10/2022",
//         sanLuongMoi: "0",
//         thuTu: 12,
//         image: {
//           fileName: null,
//           filePath: null,
//           ghiChu: null,
//           base64Data: null,
//         },
//       },
//     ],
//     maTemKiemDinh: "-",
//     seryTemKiemDinh: "-",
//     soLanLapTrinh: 9,
//     ngayLapTrinh: "2021-08-27T00:00:00",
//     maChiKiemDinh: "VN/N309-HHM/07-24",
//     soChiKiemDinh: "2",
//     kimChiTai: "VN/N309-HHM/07-24",
//     soVienChiTai: 2,
//     maDonViKiemDinh: "15",
//     maNhanVienKiemDinh: "HHM",
//     maNhanVienLapTrinh: "19145",
//   },
//   congToThao: {
//     isThaoCongTo: false,
//     tinhTrangCongTo: null,
//     congToId: "00000000-0000-0000-0000-000000000000",
//     heSoNhanDoDem: null,
//     loaiDiemDo: null,
//     maCongTo: null,
//     soCongTo: null,
//     soCuaSo: 0,
//     loaiCongTo: null,
//     soPha: null,
//     soBieuGia: null,
//     chieuDoDem: null,
//     tySoBienDong: null,
//     tySoBienDienAp: null,
//     ngayKiemDinh: null,
//     thoiDiemLapTrinhCuoi: null,
//     maChiHop: null,
//     soVienChiHop: null,
//     maCBOOC: null,
//     soVienCBOOC: null,
//     soVienKiemDinh: null,
//     donViHienThi: null,
//     heSoNhanManHinh: null,
//     maChungLoai: null,
//     nuocSX: null,
//     dienAp: null,
//     dongDien: null,
//     bcs: null,
//     boChiSos: [],
//     maTemKiemDinh: null,
//     seryTemKiemDinh: null,
//     soLanLapTrinh: null,
//     ngayLapTrinh: null,
//     maChiKiemDinh: null,
//     soChiKiemDinh: null,
//     kimChiTai: null,
//     soVienChiTai: null,
//     maDonViKiemDinh: null,
//     maNhanVienKiemDinh: null,
//     maNhanVienLapTrinh: null,
//   },
//   tuDangTreo: {
//     isThao: false,
//     tutIs: [],
//   },
//   tiDangTreo: {
//     isThao: false,
//     tutIs: [],
//   },
//   tuTreoMoi: {
//     nangHaCongSuat: false,
//     tutIs: [],
//   },
//   tiTreoMoi: {
//     nangHaCongSuat: false,
//     tutIs: [],
//   },
//   vatTus: {
//     vatTuBenBans: [],
//     vatTuBenMuas: [],
//     vatTuThuHois: [],
//   },
//   isUpdated: true,
// };

export default function Index(props) {
  const {
    detail,
    loading,
    setFetchList,
    selectedRowKeys,
    defaultCTTreo,
    setSelectedRowKeys,
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [keyTab, setKeyTab] = useState("1");
  const [load, setLoading] = useState(false);
  const [dataLyDo, setDataLyDo] = useState([]);
  const [isResetIsThao, setIsResetIsThao] = useState(false);
  const [isResetIsTUTI, setIsResetIsTUTI] = useState(false);
  const [, setIsChangedTIMoi] = useState(false);
  const [, setIsChangedTUMoi] = useState(false);
  const [customTINew, setCustomTINew] = useState(detail?.tiTreoMoi);
  const [customTUNew, setCustomTUNew] = useState(detail?.tuTreoMoi);
  const [dataDetaiCTTreolUpdate, setDataDetaiCTTreolUpdate] = useState(
    detail?.congToTreo
  );
  const [soCot, setSoCot] = useState(detail?.thongTinHienTai?.soCot);
  const { saveData } = useSelector(yeuCauDuocPhanCongSelector);
  const [changeTab1, setChangeTab1] = useState(false);
  const [doingChange, setDoingChange] = useState(false);
  const [changeTab2, setChangeTab2] = useState(false);
  const [changeTab3, setChangeTab3] = useState(false);
  const [countKey, setCountKey] = useState(0);
  const [chiSoMoiMax, setChiSoMoiMax] = useState(false);
  const toDay = moment();
  const [ngay, setNgay] = useState(
    detail.thongTinChung?.ngayTreoThao
      ? moment(detail.thongTinChung?.ngayTreoThao)
      : toDay
  );
  //set key tab
  function callback(key) {
    if (key === "2" || key === 2) {
      const count = countKey + 1;
      setCountKey(count);
    }
    setKeyTab(key);
  }

  //get  data lý do
  const getDataLyDo = useCallback((loaiBienBan) => {
    handleDataLyDo(setLoading, loaiBienBan, setDataLyDo);
  }, []);
  //fetch data lý do
  useEffect(() => {
    getDataLyDo(
      detail?.thongTinChung.loaiBienBan ? detail?.thongTinChung.loaiBienBan : 0
    );
  }, [detail?.thongTinChung.loaiBienBan, getDataLyDo]);

  //submit form
  const onFinish = (values) => {
    let validate = false;
    const dataCTThao = form.getFieldValue("defaultCTThao")
      ? JSON.parse(form.getFieldValue("defaultCTThao"))
      : detail.congToThao;
    const arrTiDangTreo = [];
    if (detail.tiDangTreo.tutIs.length > 0) {
      detail.tiDangTreo.tutIs.map((item, index) => {
        return arrTiDangTreo.push({
          ...item,
          isChayHong: form.getFieldValue(`chayHongTI-${index}`),
        });
      });
    }

    const arrTuDangTreo = [];
    if (detail.tuDangTreo.tutIs.length > 0) {
      detail.tuDangTreo.tutIs.map((item, index) => {
        return arrTuDangTreo.push({
          ...item,
          isChayHong: form.getFieldValue(`chayHongTU-${index}`),
        });
      });
    }

    const arrCTTreo = [];
    if (dataDetaiCTTreolUpdate.boChiSos.length > 0) {
      dataDetaiCTTreolUpdate.boChiSos.forEach((item, index) => {
        const date =
          item.ngayPMax !== null
            ? item.ngayPMax
            : moment(moment()).format(FORMAT_DATE);
        const chiSoMoi = item.chiSoMoi !== null ? item.chiSoMoi : "0";
        const pMax = item.pMax !== null ? item.pMax : "0";
        arrCTTreo.push({
          ...item,
          chiSoMoi:
            countKey > 0 && form.getFieldValue(`chiSoMoi-${index}`)
              ? form.getFieldValue(`chiSoMoi-${index}`)
              : chiSoMoi,
          pMax:
            countKey > 0 && form.getFieldValue(`pMax-${index}`)
              ? form.getFieldValue(`pMax-${index}`)
              : pMax,
          ngayPMax:
            countKey > 0 && form.getFieldValue(`ngayPMax-${index}`)
              ? form.getFieldValue(`ngayPMax-${index}`)
              : date,
        });
      });
    }

    const thietBiTI1 =
      customTINew?.tutIs[0]?.thietBiId !== null ||
      customTINew?.tutIs[0]?.thietBiId !== ""
        ? customTINew?.tutIs[0]?.thietBiId
        : null;
    const thietBiTI2 =
      customTINew?.tutIs[1]?.thietBiId !== null ||
      customTINew?.tutIs[1]?.thietBiId !== ""
        ? customTINew?.tutIs[1]?.thietBiId
        : null;
    const thietBiTI3 =
      customTINew?.tutIs[2]?.thietBiId !== null ||
      customTINew?.tutIs[2]?.thietBiId !== ""
        ? customTINew?.tutIs[2]?.thietBiId
        : null;

    const thietBiTU1 =
      customTUNew?.tutIs[0]?.thietBiId !== null ||
      customTUNew?.tutIs[0]?.thietBiId !== ""
        ? customTUNew?.tutIs[0]?.thietBiId
        : null;
    const thietBiTU2 =
      customTUNew?.tutIs[1]?.thietBiId !== null ||
      customTUNew?.tutIs[1]?.thietBiId !== ""
        ? customTUNew?.tutIs[1]?.thietBiId
        : null;
    const thietBiTU3 =
      customTUNew?.tutIs[2]?.thietBiId !== null ||
      customTUNew?.tutIs[2]?.thietBiId !== ""
        ? customTUNew?.tutIs[2]?.thietBiId
        : null;

    if (
      detail?.thongTinChung.soPha === 3 ||
      detail?.thongTinChung.soPha === "3"
    ) {
      if (
        thietBiTI1 === null &&
        thietBiTI2 === null &&
        thietBiTI3 === null &&
        thietBiTU1 === null &&
        thietBiTU2 === null &&
        thietBiTU3 === null
      ) {
        validate = false;
        form.setFields([
          {
            name: [`maTI-0`],
            errors: false,
          },
          {
            name: [`maTI-1`],
            errors: false,
          },
          {
            name: [`maTI-2`],
            errors: false,
          },
          {
            name: [`maTU-0`],
            errors: false,
          },
          {
            name: [`maTU-1`],
            errors: false,
          },
          {
            name: [`maTU-2`],
            errors: false,
          },
        ]);
      } else if (
        (thietBiTI1 === null && thietBiTI2 !== null) ||
        (thietBiTI1 === null && thietBiTI3 !== null) ||
        (thietBiTI2 === null && thietBiTI1 !== null) ||
        (thietBiTI2 === null && thietBiTI3 !== null) ||
        (thietBiTI3 === null && thietBiTI1 !== null) ||
        (thietBiTI3 === null && thietBiTI2 !== null)
      ) {
        alertMessage(
          "error",
          "Thông báo!",
          "Vui lòng nhập đầy đủ thông tin TI Treo mới"
        );
        validate = true;
      } else if (
        (thietBiTU1 === null && thietBiTU2 !== null) ||
        (thietBiTU1 === null && thietBiTU3 !== null) ||
        (thietBiTU2 === null && thietBiTU1 !== null) ||
        (thietBiTU2 === null && thietBiTU3 !== null) ||
        (thietBiTU3 === null && thietBiTU1 !== null) ||
        (thietBiTU3 === null && thietBiTU2 !== null)
      ) {
        alertMessage(
          "error",
          "Thông báo!",
          "Vui lòng nhập đầy đủ thông tin TU Treo mới"
        );

        validate = true;
      }
    } else validate = false;

    const payload = {
      id: selectedRowKeys.toString(),
      thongTinHienTai: {
        ...detail.thongTinHienTai,
      },
      thongTinChung: {
        ...detail.thongTinChung,
        loaiBienBan: parseInt(values.loaiBienBan),
        lyDoTreoThaoId: values.lyDoTreoThaoId,
        viTriTreoThao: parseInt(values.viTriTreoThao),
        soCot: values.soCot ? values.soCot : null,
        soHop: values.soHop ? values.soHop : null,
        hsn: values.hsn ? values.hsn : null,
        maChiHop: values.maChiHop ? values.maChiHop : null,
        maChiBOOC: values.maChiBOOC ? values.maChiBOOC : null,
        soVienChiBOOC: values.soVienChiBOOC ? values.soVienChiBOOC : null,
        soVienChiHop: values.soVienChiHop ? values.soVienChiHop : null,
        isDoXa: form.getFieldValue("isDoXa")
          ? form.getFieldValue("isDoXa")
          : detail.congToThao.isThaoCongTo,
        isTreoThaoTUTI: form.getFieldValue("isTreoThaoTUTI")
          ? form.getFieldValue("isTreoThaoTUTI")
          : detail.congToThao.isTreoThaoTUTI,
        gioDoXa: values.gioDoXa ? values.gioDoXa : null,
        phutDoXa: values.phutDoXa ? values.phutDoXa : null,
        hesid: values.hesid ? values.hesid : null,
        gatewayID: values.gatewayID ? values.gatewayID : null,
        ngayTreoThao: moment(ngay).format(FORMAT_ENGLISH),
      },
      congToThao: {
        ...dataCTThao,
        isThaoCongTo: form.getFieldValue("isThaoCongTo"),
      },
      congToTreo:
        form.getFieldValue("isThaoCongTo") ||
        values.loaiBienBan === "3" ||
        values.loaiBienBan === 3 ||
        dataDetaiCTTreolUpdate.boChiSos.length === 0
          ? null
          : form.getFieldValue("isTreoThaoTUTI")
          ? {
              ...dataCTThao,
              congToId: "00000000-0000-0000-0000-000000000000",
              isThayDoiLoaiDiemDo: false,
              loaiDiemDo: null,
              // countKey > 0 && form.getFieldValue("loaiDiemDo")
              //   ? parseInt(form.getFieldValue("loaiDiemDo"))
              //   : detail?.thongTinChung?.loaiDiemDo === null
              //   ? 1
              //   : detail?.thongTinChung?.loaiDiemDo,
              lapTrinhLaiCongTo: false,
              layBoChiSoNhan: false,
            }
          : {
              ...dataDetaiCTTreolUpdate,
              boChiSos: arrCTTreo,
              isThayDoiLoaiDiemDo: form.getFieldValue("isThayDoiLoaiDiemDo"),
              loaiDiemDo:
                countKey > 0 && form.getFieldValue("loaiDiemDo")
                  ? parseInt(form.getFieldValue("loaiDiemDo"))
                  : detail?.thongTinChung?.loaiDiemDo === null
                  ? 1
                  : detail?.thongTinChung?.loaiDiemDo,
              lapTrinhLaiCongTo: form.getFieldValue("lapTrinhLaiCongTo"),
              layBoChiSoNhan: form.getFieldValue("layBoChiSoNhan"),
            },
      tuDangTreo: {
        ...detail.tuDangTreo,
        isThao: form.getFieldValue("isThaoTU")
          ? form.getFieldValue("isThaoTU")
          : detail.tuDangTreo.isThao,
        tutIs: arrTuDangTreo,
      },
      tiDangTreo: {
        ...detail.tiDangTreo,
        isThao: form.getFieldValue("isThaoTI")
          ? form.getFieldValue("isThaoTI")
          : detail.tiDangTreo.isThao,
        tutIs: arrTiDangTreo,
      },
      tuTreoMoi: {
        ...customTUNew,
        tutIs:
          customTUNew?.tutIs[0]?.thietBiId === null &&
          customTUNew?.tutIs[1]?.thietBiId === null &&
          customTUNew?.tutIs[2]?.thietBiId === null
            ? []
            : customTUNew.tutIs,
      },
      tiTreoMoi: {
        ...customTINew,
        tutIs:
          customTINew?.tutIs[0]?.thietBiId === null &&
          customTINew?.tutIs[1]?.thietBiId === null &&
          customTINew?.tutIs[2]?.thietBiId === null
            ? []
            : customTINew.tutIs,
      },
      isWeb: true,
    };
    if (!validate) {
      if (arrCTTreo.length > 0) {
        let arrTreoThao = [...dataDetaiCTTreolUpdate.boChiSos];
        if (countKey > 0) arrTreoThao = arrCTTreo;
        if (!chiSoMoiMax) {
          if (
            !form.getFieldValue("isThaoCongTo") ||
            values.loaiBienBan !== "3" ||
            values.loaiBienBan !== 3 ||
            !form.getFieldValue("isTreoThaoTUTI")
          ) {
            let totalSG = 0;
            let totalSN = 0;
            let valueCSMoiKG = 0;
            let valueCSMoiKN = 0;
            const filterArrKG = arrTreoThao.filter((el) => el.bcs === "SG");
            const filterArrKN = arrTreoThao.filter((el) => el.bcs === "SN");
            arrTreoThao.forEach((item) => {
              if (item.bcs === "BT" || item.bcs === "CD" || item.bcs === "TD")
                totalSG += item.chiSoMoi ? parseInt(item.chiSoMoi) : 0;
              if (item.bcs === "BN" || item.bcs === "CN" || item.bcs === "TN")
                totalSN += item.chiSoMoi ? parseInt(item.chiSoMoi) : 0;
              if (item.bcs === "SG") valueCSMoiKG = item.chiSoMoi;
              if (item.bcs === "SN") valueCSMoiKN = item.chiSoMoi;
            });
            if (filterArrKG.length > 0 && filterArrKN.length > 0) {
              if (
                (parseInt(valueCSMoiKG) < totalSG - 3 ||
                  parseInt(valueCSMoiKG) > totalSG + 3) &&
                (parseInt(valueCSMoiKN) < totalSN - 3 ||
                  parseInt(valueCSMoiKN) > totalSN + 3)
              ) {
                Modal.confirm({
                  centered: true,
                  title: "Xác nhận",
                  content: `Tổng chỉ số 2 kênh giao và nhận SG/SN đang chênh lệch ± 3 so với tổng BCS (BT/BN + CD/CN + TD/TN)`,
                  onOk() {
                    handleUpdateCTSYNC(
                      setLoading,
                      payload,
                      setFetchList,
                      dispatch,
                      setSelectedRowKeys
                    );
                  },
                  onCancel() {},
                  okText: "Đồng ý",
                  okButtonProps: { type: "danger" },
                  cancelText: "Hủy",
                });
              } else if (
                parseInt(valueCSMoiKG) < totalSG - 3 ||
                parseInt(valueCSMoiKG) > totalSG + 3
              ) {
                Modal.confirm({
                  centered: true,
                  title: "Xác nhận",
                  content: `Tổng chỉ số SG của công tơ TREO đang chênh lệch ± 3 so với tổng BCS (BT + CD + TD)`,
                  onOk() {
                    handleUpdateCTSYNC(
                      setLoading,
                      payload,
                      setFetchList,
                      dispatch,
                      setSelectedRowKeys
                    );
                  },
                  onCancel() {
                    validate = true;
                  },
                  okText: "Đồng ý",
                  okButtonProps: { type: "danger" },
                  cancelText: "Hủy",
                });
              } else if (
                parseInt(valueCSMoiKN) < totalSN - 3 ||
                parseInt(valueCSMoiKN) > totalSN + 3
              ) {
                Modal.confirm({
                  centered: true,
                  title: "Xác nhận",
                  content: `Tổng chỉ số SN của công tơ TREO đang chênh lệch ± 3 so với tổng BCS (BN + CN + TN)`,
                  onOk() {
                    handleUpdateCTSYNC(
                      setLoading,
                      payload,
                      setFetchList,
                      dispatch,
                      setSelectedRowKeys
                    );
                  },
                  onCancel() {},
                  okText: "Đồng ý",
                  okButtonProps: { type: "danger" },
                  cancelText: "Hủy",
                });
              } else {
                handleUpdateCTSYNC(
                  setLoading,
                  payload,
                  setFetchList,
                  dispatch,
                  setSelectedRowKeys
                );
              }
            } else if (filterArrKG.length > 0) {
              if (
                parseInt(valueCSMoiKG) < totalSG - 3 ||
                parseInt(valueCSMoiKG) > totalSG + 3
              ) {
                Modal.confirm({
                  centered: true,
                  title: "Xác nhận",
                  content: `Tổng chỉ số SG của công tơ TREO đang chênh lệch ± 3 so với tổng BCS (BT + CD + TD)`,
                  onOk() {
                    handleUpdateCTSYNC(
                      setLoading,
                      payload,
                      setFetchList,
                      dispatch,
                      setSelectedRowKeys
                    );
                  },
                  onCancel() {},
                  okText: "Đồng ý",
                  okButtonProps: { type: "danger" },
                  cancelText: "Hủy",
                });
              } else {
                handleUpdateCTSYNC(
                  setLoading,
                  payload,
                  setFetchList,
                  dispatch,
                  setSelectedRowKeys
                );
              }
            } else {
              if (
                parseInt(valueCSMoiKN) < totalSN - 3 ||
                parseInt(valueCSMoiKN) > totalSN + 3
              ) {
                Modal.confirm({
                  centered: true,
                  title: "Xác nhận",
                  content: `Tổng chỉ số SN của công tơ TREO đang chênh lệch ± 3 so với tổng BCS (BN + CN + TN)`,
                  onOk() {
                    handleUpdateCTSYNC(
                      setLoading,
                      payload,
                      setFetchList,
                      dispatch,
                      setSelectedRowKeys
                    );
                  },
                  onCancel() {},
                  okText: "Đồng ý",
                  okButtonProps: { type: "danger" },
                  cancelText: "Hủy",
                });
              } else {
                handleUpdateCTSYNC(
                  setLoading,
                  payload,
                  setFetchList,
                  dispatch,
                  setSelectedRowKeys
                );
              }
            }
          } else {
            handleUpdateCTSYNC(
              setLoading,
              payload,
              setFetchList,
              dispatch,
              setSelectedRowKeys
            );
          }
        }
      } else {
        handleUpdateCTSYNC(
          setLoading,
          payload,
          setFetchList,
          dispatch,
          setSelectedRowKeys
        );
      }
    }
  };

  //change value form
  const handleChangeValueForm = useCallback(
    (changedValues, allValues) => {
      //xử lý cho tab 1
      const objectFieldTab1 = {
        ...detail.thongTinChung,
        loaiBienBan: parseInt(allValues.loaiBienBan),
        lyDoTreoThaoId: allValues.lyDoTreoThaoId,
        viTriTreoThao: parseInt(allValues.viTriTreoThao),
        soCot: allValues.soCot ? allValues.soCot : null,
        soHop: allValues.soHop ? allValues.soHop : null,
        hsn: allValues.hsn ? allValues.hsn : null,
        maChiHop: allValues.maChiHop ? allValues.maChiHop : null,
        maChiBOOC: allValues.maChiBOOC ? allValues.maChiBOOC : null,
        soVienChiBOOC: allValues.soVienChiBOOC ? allValues.soVienChiBOOC : null,
        soVienChiHop: allValues.soVienChiHop ? allValues.soVienChiHop : null,
        isDoXa: form.getFieldValue("isDoXa"),
        isTreoThaoTUTI: form.getFieldValue("isTreoThaoTUTI"),
        gioDoXa: allValues.gioDoXa ? allValues.gioDoXa : null,
        phutDoXa: allValues.phutDoXa ? allValues.phutDoXa : null,
        hesid: allValues.hesid ? allValues.hesid : null,
        gatewayID: allValues.gatewayID ? allValues.gatewayID : null,
        ngayTreoThao: allValues.ngayTreoThao ? allValues.ngayTreoThao : toDay,
      };
      if (isEqual(objectFieldTab1, detail.thongTinChung)) {
        setChangeTab1(false);
        setDoingChange(false);
        // dispatch(getSaveDataPopup(false));
      } else {
        setDoingChange(true);
        setChangeTab1(true);
        // dispatch(getSaveDataPopup(true));
      }

      //xử lý cho tab 2
      if (
        form.getFieldValue("isThaoCongTo") === detail.congToThao.isThaoCongTo &&
        form.getFieldValue("isThayDoiLoaiDiemDo") ===
          detail.congToTreo.isThayDoiLoaiDiemDo &&
        form.getFieldValue("lapTrinhLaiCongTo") ===
          detail.congToTreo.lapTrinhLaiCongTo &&
        form.getFieldValue("layBoChiSoNhan") ===
          detail.congToTreo.layBoChiSoNhan
      ) {
        setChangeTab2(false);
      } else {
        setChangeTab2(true);
      }

      //xử lý tab 3
      const arrTuDangTreo = [];
      if (detail.tuDangTreo.tutIs.length > 0) {
        detail.tuDangTreo.tutIs.map((item, index) => {
          return arrTuDangTreo.push({
            ...item,
            isChayHong: form.getFieldValue(`chayHongTU-${index}`),
          });
        });
      }
      const tuDangTreo = {
        ...detail.tuDangTreo,
        isThao: form.getFieldValue("isThaoTU")
          ? form.getFieldValue("isThaoTU")
          : detail.tuDangTreo.isThao,
        tutIs: arrTuDangTreo,
      };

      const arrTiDangTreo = [];
      if (detail.tiDangTreo.tutIs.length > 0) {
        detail.tiDangTreo.tutIs.map((item, index) => {
          return arrTiDangTreo.push({
            ...item,
            isChayHong: form.getFieldValue(`chayHongTI-${index}`),
          });
        });
      }

      const tiDangTreo = {
        ...detail.tiDangTreo,
        isThao: form.getFieldValue("isThaoTI")
          ? form.getFieldValue("isThaoTI")
          : detail.tiDangTreo.isThao,
        tutIs: arrTiDangTreo,
      };
      const isNangHaTi = form.getFieldValue("isNangHaTI")
        ? form.getFieldValue("isNangHaTI")
        : false;
      const isNangHaTu = form.getFieldValue("isNangHaTU")
        ? form.getFieldValue("isNangHaTU")
        : false;
      if (
        isEqual(tuDangTreo, detail.tuDangTreo) &&
        isEqual(tiDangTreo, detail.tiDangTreo) &&
        isNangHaTi === detail.tiTreoMoi.nangHaCongSuat &&
        isNangHaTu === detail.tuTreoMoi.nangHaCongSuat
      ) {
        setChangeTab3(false);
      } else {
        setChangeTab3(true);
      }
    },
    [
      detail.congToThao.isThaoCongTo,
      detail.congToTreo.isThayDoiLoaiDiemDo,
      detail.congToTreo.lapTrinhLaiCongTo,
      detail.congToTreo.layBoChiSoNhan,
      detail.thongTinChung,
      detail.tiDangTreo,
      detail.tiTreoMoi.nangHaCongSuat,
      detail.tuDangTreo,
      detail.tuTreoMoi.nangHaCongSuat,
      form,
      toDay,
    ]
  );
  useEffect(() => {
    if (changeTab1 || changeTab2 || changeTab3)
      dispatch(getSaveDataPopup(true));
    else dispatch(getSaveDataPopup(false));
  }, [changeTab1, changeTab2, changeTab3, dispatch]);

  useEffect(() => {
    if (saveData) {
      dispatch(setCanShowDialogLeaving(true));
    } else {
      dispatch(setCanShowDialogLeaving(false));
    }
  }, [dispatch, saveData]);

  // useEffect(() => {
  //   if (showDialogLeavingPage) {
  //     ModalWarningChange(
  //       () => {
  //         confirmNavigation();
  //         // dispatch(rangeOfChange(false));
  //         if (saveData) dispatch(getSaveDataPopup(false));
  //       },
  //       () => {
  //         cancelNavigation();
  //       }
  //     );
  //   }
  // }, [
  //   cancelNavigation,
  //   confirmNavigation,
  //   dispatch,
  //   saveData,
  //   showDialogLeavingPage,
  // ]);

  return (
    <LoadingComponent loading={loading || load}>
      <Collapse defaultActiveKey={["1"]}>
        <Panel header="Thông tin công tơ hiện tại" key="1">
          <ThongTinHientai
            detail={detail?.thongTinHienTai}
            soCot={soCot}
            maDiemDo={detail?.thongTinChung?.maDiemDo}
          />
        </Panel>
      </Collapse>
      <Form
        form={form}
        name="lap-bb"
        onValuesChange={debounce(handleChangeValueForm, 200)}
        // labelAlign="left"
        onFinish={onFinish}
        initialValues={{
          ...detail,
          ...detail.thongTinChung,
          ...detail.congToThao,
          ...detail.congToTreo,
          ...detail.tuDangTreo,
          ...detail.tiDangTreo,
        }}
        layout="vertical"
        autoComplete="off"
      >
        <Tabs
          activeKey={keyTab}
          defaultActiveKey="1"
          type="card"
          style={{ padding: "8px" }}
          onChange={callback}
        >
          <Tabs.TabPane
            tab={
              <div>
                <span style={{ color: changeTab1 ? "#ff4d4f" : "#1892FF" }}>
                  Thông tin chung
                </span>
              </div>
            }
            key="1"
          >
            <ThongTinChung
              detail={detail?.thongTinChung}
              dataLyDo={dataLyDo}
              getDataLyDo={getDataLyDo}
              form={form}
              detailCTTreo={detail?.congToTreo}
              detailCTThao={detail?.congToThao}
              setIsResetIsThao={setIsResetIsThao}
              isResetIsTUTI={isResetIsTUTI}
              setIsResetIsTUTI={setIsResetIsTUTI}
              defaultCTTreo={defaultCTTreo}
              setSoCot={setSoCot}
              dataDetaiCTTreolUpdate={dataDetaiCTTreolUpdate}
              setChangeTab1={setChangeTab1}
              doingChange={doingChange}
              setNgay={setNgay}
              ngay={ngay}
            />
            {/* </Row> */}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <div>
                <span style={{ color: changeTab2 ? "#ff4d4f" : "#1892FF" }}>
                  Công tơ
                </span>
              </div>
            }
            key="2"
          >
            <Congto
              detailCTThao={detail?.congToThao}
              detailCTTreo={detail?.congToTreo}
              thongTinHT={detail?.thongTinHienTai}
              form={form}
              thongTinChung={detail?.thongTinChung}
              thongTinHienTai={detail?.thongTinHienTai}
              isResetIsThao={isResetIsThao}
              setIsResetIsThao={setIsResetIsThao}
              setIsResetIsTUTI={setIsResetIsTUTI}
              defaultCTTreo={defaultCTTreo}
              setDataDetaiCTTreolUpdate={setDataDetaiCTTreolUpdate}
              setChangeTab2={setChangeTab2}
              setChiSoMoiMax={setChiSoMoiMax}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <div>
                <Tooltip
                  title={
                    detail?.thongTinChung.soPha === 1
                      ? "TI/TU chỉ áp dụng cho công tơ 3 pha !"
                      : ""
                  }
                >
                  <span
                    style={{
                      color: changeTab3
                        ? "#ff4d4f"
                        : detail?.thongTinChung.soPha === 1
                        ? ""
                        : "#1892FF",
                    }}
                  >
                    TU / TI
                  </span>
                </Tooltip>
              </div>
            }
            key="3"
            disabled={detail?.thongTinChung.soPha === 1}
          >
            <Tuti
              detailTIDangTreo={detail.tiDangTreo}
              detailTITreoMoi={detail.tiTreoMoi}
              detailTUDangTreo={detail?.tuDangTreo}
              detailTUTreoMoi={detail?.tuTreoMoi}
              setIsChangedTUMoi={setIsChangedTUMoi}
              setIsChangedTIMoi={setIsChangedTIMoi}
              form={form}
              soPha={detail?.thongTinChung.soPha}
              setCustomTINew={setCustomTINew}
              setCustomTUNew={setCustomTUNew}
              setChangeTab3={setChangeTab3}
            />
          </Tabs.TabPane>
        </Tabs>
        <Row gutter={24} align="center" style={{ marginTop: "56px" }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "200px", height: "52px", fontSize: "16px" }}
          >
            Cập nhật
          </Button>
        </Row>
      </Form>
    </LoadingComponent>
  );
}
