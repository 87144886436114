import CheckStatus from "@components/CheckStatus";
import CheckPermission from "@components/CheckPermission";
import { cap_dien } from "@permissions/a_capdien";
import {
  LITMIT_TRANGTHAI,
  MATRIX_CAP_DIEN,
  SEQUENCE_TRANGTHAI,
} from "@utils/constants";
import { Menu } from "antd";
import XemLaiBBLapDatComponent from "@modules/cap-dien/common-ho-so/xem-bien-ban-lap-dat";
const { SubMenu } = Menu;

export default function ListMenu(props) {
  const {
    handleOpenModalXacNhanNghiemThu,
    handleOpenModalTroNgaiKS,
    detail,
    handleOpenModalInHoSoCMIS,
    t,
    handleOpenModalXemLaiPhuongAnCapDien,
    handleOpenModalQuyetToanKhachHang,
    handleOpenModalQuyetToanCongTy,
    handleOpenModalInBieuKhaiGia,
    inBienBanApGia,
    handleOpenInPhuTai,
  } = props;
  return (
    <Menu mode="vertical" style={{ width: "100%" }} selectedKeys={["0"]}>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.XACNHANTHUTIEN}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.XACNHANTHUTIEN[1]}
        permissionCode={cap_dien.a81_xn_thu_tien}
      >
        <Menu.Item eventKey="1" onClick={handleOpenModalXacNhanNghiemThu}>
          Xác nhận thu tiền
        </Menu.Item>
      </CheckStatus>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.TRONGAI}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.XACNHANTHUTIEN[1]}
        permissionCode={cap_dien.a81_cap_nhat_tro_ngai}
      >
        <Menu.Item eventKey="2" onClick={handleOpenModalTroNgaiKS}>
          Cập nhật trở ngại
        </Menu.Item>
      </CheckStatus>
      <CheckPermission permissionCode={cap_dien.a81_in_hs_cmis}>
        <Menu.Item eventKey="4" onClick={handleOpenModalInHoSoCMIS}>
          In hồ sơ CMIS
        </Menu.Item>
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a81_in_hs_web}>
        {Number(detail.maTrangThai) >= LITMIT_TRANGTHAI ? (
          <SubMenu eventKey="sub1" title={t("common_cap_dien.in_ho_so_web")}>
            <Menu.Item
              eventKey="9"
              onClick={handleOpenModalXemLaiPhuongAnCapDien}
            >
              {" "}
              {t("common_cap_dien.xem_lai_phuong_an_cap_dien")}
            </Menu.Item>
            <XemLaiBBLapDatComponent idHoSo={detail?.id ?? detail?.hoSoId} />
            <Menu.Item eventKey="20" onClick={handleOpenInPhuTai}>
              In biểu đồ phụ tải
            </Menu.Item>
            <Menu.Item
              eventKey="10"
              onClick={handleOpenModalQuyetToanKhachHang}
            >
              {" "}
              {t("common_cap_dien.quyet_toan_khach_hang")}
            </Menu.Item>
            <Menu.Item eventKey="11" onClick={handleOpenModalQuyetToanCongTy}>
              {" "}
              {t("common_cap_dien.quyet_toan_cong_ty")}
            </Menu.Item>
            <Menu.Item eventKey="12" onClick={handleOpenModalInBieuKhaiGia}>
              {" "}
              {t("common_cap_dien.in_bieu_khai_gia")}
            </Menu.Item>
            <Menu.Item eventKey="13" onClick={inBienBanApGia}>
              {" "}
              {t("common_cap_dien.in_bien_ban_ap_gia")}
            </Menu.Item>
            {/* <Menu.Item eventKey="12">
        {" "}
        {t("common_cap_dien.in_phieu_tiep_nhan_thong_tin_de_nghi_mua_dien")}
      </Menu.Item> */}
          </SubMenu>
        ) : null}
      </CheckPermission>
    </Menu>
  );
}
