import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { parseParams, removeUndefinedAttribute } from "@utils/function";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import moment from "moment";
import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { handleDownload, selectBaoCaoSMS } from "../services";

export default function DSKHChuaGuiTin(props) {
  const { screen, handleChange } = props;
  const { Option } = Select;
  const [form] = Form.useForm();
  const user = useSelector(authSelector);
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [filterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    MaDonVi: user.user.unitCode,
    TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
    DenNgay: moment().format(FORMAT_ENGLISH),
    ...parseParams(location.search),
  });

  useEffect(() => {
    form.resetFields();
  }, [form]);

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };
  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <FormComponent
            onFinish={onFinish}
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            initialValues={{
              reportType: screen,
              TuNgay: form.setFieldsValue(filterConditions.TuNgay)
                ? form.setFieldsValue(filterConditions.TuNgay)
                : moment().startOf(MONTH_STRING),
              PhanLoai: "0",
            }}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="reportType"
                  label="Tên báo cáo"
                  labelAlign="left"
                >
                  <Select
                    form={form}
                    options={selectBaoCaoSMS}
                    onChange={() =>
                      handleChange(form.getFieldValue("reportType"))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="PhanLoai" label="Phân loại" labelAlign="left">
                  <Select placeholder="Tất cả" defaultValue={"0"}>
                    <Option value="0"> Thông báo Tiền điện lần 1</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="MaDonVi" label="Đơn vị" labelAlign="left">
                  <Selection
                    url={Endpoint.GET_TEAM_LIST_CODE}
                    formKey="MaDonVi"
                    form={form}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={8}>
                <Form.Item
                  name="TuNgay"
                  label="Ngày bắt đầu"
                  labelCol={{ span: 12 }}
                  labelAlign="left"
                >
                  <div style={{ marginLeft: "3%" }}>
                    <DatePickerComponent
                      formKey="TuNgay"
                      form={form}
                      format={FORMAT_ENGLISH}
                      defaultValue={
                        filterConditions.TuNgay
                          ? moment(filterConditions.TuNgay, FORMAT_ENGLISH)
                          : moment()
                      }
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="DenNgay"
                  label="Ngày kết thúc"
                  labelAlign="right"
                  labelCol={{ span: 12 }}
                >
                  <DatePickerComponent
                    formKey="DenNgay"
                    form={form}
                    format={FORMAT_ENGLISH}
                    defaultValue={
                      filterConditions.DenNgay
                        ? moment(filterConditions.DenNgay, FORMAT_ENGLISH)
                        : moment()
                    }
                  />
                </Form.Item>
              </Col>
              <Row justify="end">
                {/* <XuatExcel
                  endpoint={
                    Endpoint.BC_CHUA_GUI_TIN_NHAN_EXCEL +
                    "?" +
                    buildQueryString(parseParams(filterConditions))
                  }
                  text={"Xuất Excel"}
                  key={"xuat-excel"}
                /> */}
                <Button type="primary" htmlType="submit" loading={loading}>
                  Xuất báo cáo
                </Button>
              </Row>
            </Row>
          </FormComponent>
          <Divider className="section-text-line" orientation="left"></Divider>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  margin-top: 40px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
