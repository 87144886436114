import { Table } from "antd";
import { Container } from "./css-style";
export default function Index(props) {
  const { dataVT, columns } = props;
  return (
    <Container>
      <Table
        bordered
        dataSource={dataVT}
        columns={columns}
        pagination={false}
        rowKey={(obj) => obj.stt}
      />
    </Container>
  );
}
