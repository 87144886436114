import EllipsisTooltip from "@components/EllipsisTooltip";
import { numberWithSpaces } from "@utils/function";
import { Form } from "antd";
import React from "react";
import { Container } from "./css-styled";
import TableAnhXaDiemdo from "./table";
export default function AnhXaTTDiemDo() {
  const [form] = Form.useForm();

  function financial(x, nth = 2) {
    if (x) {
      return Number.parseFloat(x).toFixed(nth);
    }

    return null;
  }

  function changeToZero(x) {
    const numberArray = String(x).split(".");
    if (x == 0) {
      return 0;
    } else {
      if (numberArray[1] === "0") {
        return numberArray[0];
      } else {
        return x;
      }
    }
  }

  const columns = [
    {
      title: "STT",
      key: "stt",
      dataIndex: "stt",
      width: 50,
      align: "center",
    },
    {
      title: "Mã điểm đo",
      key: "maDiemDo",
      dataIndex: "maDiemDo",
      render: (text) => (
        <EllipsisTooltip line="1" placement="topRight" title={text}>
          {text}
        </EllipsisTooltip>
      ),
      align: "center",
      width: 100,
    },
    {
      title: "Thông tin điểm đo ",
      key: "thongTinDiemDo",
      dataIndex: "thongTinDiemDo",
      render: (text) => (
        <EllipsisTooltip line="1" placement="topRight" title={text}>
          {text}
        </EllipsisTooltip>
      ),
      align: "center",
      width: 200,
    },
    {
      title: "HSN",
      key: "hsn",
      dataIndex: "hsn",
      align: "center",
      width: 50,
    },
    {
      title: "Tên trạm",
      key: "tenTram",
      dataIndex: "tenTram",
      render: (text) => (
        <EllipsisTooltip line="1" placement="topRight" title={text}>
          {text}
        </EllipsisTooltip>
      ),
      align: "center",
      width: 200,
    },
    {
      title: "Mã đo xa",
      key: "maDoXa",
      dataIndex: "maDoXa",
      align: "center",
      render: (text) => (
        <EllipsisTooltip line="1" placement="topRight" title={text}>
          {text}
        </EllipsisTooltip>
      ),
      width: 200,
    },
    {
      title: "CSDK BTG",
      key: "csdkbtg",
      dataIndex: "csdkbtg",
      align: "right",
      render: (text, record) => <div>{numberWithSpaces(record?.csdkbtg)}</div>,
      width: 100,
    },
    {
      title: "CSDK BTN",
      key: "csdkbtn",
      dataIndex: "csdkbtn",
      align: "right",
      render: (text, record) => <div>{numberWithSpaces(record?.csdkbtn)}</div>,
      width: 100,
    },
    {
      title: "CSDK BTG đo xa",
      key: "csdkbtgDoXa",
      dataIndex: "csdkbtgDoXa",
      align: "right",
      render: (text, record) => (
        <div>
          {numberWithSpaces(changeToZero(financial(record?.BTG / 1000, 1)))}
        </div>
      ),
      width: 100,
    },
    {
      title: "CSDK BTN đo xa",
      key: "csdkbtnDoXa",
      dataIndex: "csdkbtnDoXa",
      align: "right",
      render: (text, record) => (
        <div>
          {numberWithSpaces(changeToZero(financial(record?.BTN / 1000, 1)))}
        </div>
      ),
      width: 100,
    },
    {
      title: "Lệch giao",
      key: "lechGiao",
      dataIndex: "lechGiao",
      align: "right",
      render: (text, record) => (
        <div>
          {record?.hsn === 1000
            ? record?.BTG - record?.csdkbtg
              ? numberWithSpaces(
                  changeToZero(
                    financial(record?.BTG / 1000 - record?.csdkbtg, 1)
                  )
                )
              : null
            : record?.BTG - record?.csdkbtg
            ? numberWithSpaces(
                changeToZero(financial(record?.BTG - record?.csdkbtg, 1))
              )
            : null}
        </div>
      ),
      width: 100,
    },
    {
      title: "Lệch nhận",
      key: "lechNhan",
      dataIndex: "lechNhan",
      align: "right",
      render: (text, record) => (
        <div>
          {record?.hsn === 1000
            ? record?.BTN - record?.csdkbtn
              ? numberWithSpaces(
                  changeToZero(
                    financial(record?.BTN / 1000 - record?.csdkbtn, 1)
                  )
                )
              : null
            : record?.BTN - record?.csdkbtn
            ? numberWithSpaces(
                changeToZero(financial(record?.BTN - record?.csdkbtn, 1))
              )
            : null}
        </div>
      ),
      width: 100,
    },
  ];
  return (
    <Container>
      <TableAnhXaDiemdo
        columns={columns}
        header={"Nhập chỉ số định kỳ"}
        form={form}
      />
    </Container>
  );
}
