import React, { useCallback, useEffect, useState } from "react";
import { authGetData, authPostFileData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { Row, Col, Form, Radio, Checkbox, Select, Spin, DatePicker } from "antd";
import InputComponent from "@components/Input";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import styled from "styled-components";
import { getErrorForm } from "@utils/function";
import { optionPageSize, STATUSCODE_200 } from "@utils/constants";
import ButtonComponent from "@components/Button";
import ImageUpload from "@components/ImageUpload";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import { getUserProfile } from "@state/auth/reducer";
import { useDispatch } from "react-redux";
import { FORMAT_DATE } from "@utils/constants";
import moment from "moment";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";

const { Option } = Select;

export default function EditFormUserAccount() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);
  const [ssoChecked] = useState(false);
  // const [signature, setSignature] = useState(null);
  const [avatar, setAvatar] = useState(null);
  // const [signatureURL, setSignatureURL] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const getUserDetail = useCallback(() => {
    authGetData({
      url: `${Endpoint.USER}/user-detail?id=${user.id}`,
      setLoading,
      onSuccess: (res) => {
        if (res.data.avatar) {
          setAvatar(res.data.avatar);
        }
        form.setFieldsValue(res.data);
      },
    });
  }, [form, user]);
  useEffect(() => {
    getUserDetail();
  }, [getUserDetail]);
  useEffect(() => {
    if (user.avatar) {
      setAvatar(user.avatar);
    }
    // if (user.chuKy) {
    //   setSignatureURL(user.chuKy);
    // }
    form.setFieldsValue(user);
  }, [user, form]);

  const [ngayCap, setNgayCap] = useState(
    user && user.ngayCap
      ?  moment(user.ngayCap, FORMAT_DATE).format('YYYY-MM-DD')
      : null
  );

  const handleChangeNgayCap = (date) => {
    let convert = "";
    if (date === null) convert = null;
    else convert = moment(date).format('YYYY-MM-DD');
    setNgayCap(convert);
  };

  const onFinish = useCallback(
    (values) => {
      authPostFileData({
        url: `${Endpoint.UPDATE_PROFILE}`,
        method: "PUT",
        payload: {
          ...values,
          id: user.id,
          // chuKy: signature,
          file: imageFile,
          isRemoveAvatar: !imageFile ? true : false,
          ngayCap: ngayCap,
        },
        setLoading,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            dispatch(getUserProfile());
          } else {
            getErrorForm(res, form);
          }
        },
      });
    },
    [user.id, imageFile, ngayCap, dispatch, form]
    // [form, user, imageFile, dispatch, signature]
  );
  return (
    <Spin spinning={loading}>
      <FormComponent
        layout="vertical"
        onFinish={onFinish}
        form={form}
        autoComplete="off"
      >
        <Row>
          <Col span={4}>
            <Form.Item label="Ảnh đại diện">
              <ImageUpload setImageFile={setImageFile} currentImage={avatar} />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item name="chuky" label="Chữ ký">
              <ImageUpload
                setImageFile={setSignature}
                currentImage={signatureURL}
              />
            </Form.Item>
          </Col> */}
        </Row>

        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="userName" label="Tên đăng nhập ">
              <InputComponent
                type="text"
                size="default"
                form={form}
                formKey="userName"
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <SSOContainer>
              <div className="sso-checkbox">
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.userName !== currentValues.userName
                  }
                >
                  {() => {
                    return <Checkbox checked={ssoChecked} disabled />;
                  }}
                </Form.Item>

                <span>SSO</span>
              </div>
            </SSOContainer>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="unitId" label="Đơn vị">
              <Selection
                url={Endpoint.GET_UNIT_LIST}
                formKey="unitId"
                form={form}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                if (prevValues.unitId !== currentValues.unitId) {
                  form.setFieldsValue({
                    teamId: undefined,
                  });
                }
                return prevValues.unitId !== currentValues.unitId;
              }}
            >
              {({ getFieldValue }) => {
                if (getFieldValue("unitId")) {
                  return (
                    <Form.Item name="teamName" label="Tổ/Đội">
                      <Selection
                        url={`${Endpoint.GET_TEAM_LIST}?donviId=${getFieldValue(
                          "unitId"
                        )}`}
                        formKey="teamName"
                        form={form}
                        disabled={getFieldValue("unitId")}
                      />
                    </Form.Item>
                  );
                }
                return (
                  <Form.Item name="teamName" label="Tổ/Đội">
                    <Select disabled />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="name" label="Tên đầy đủ">
              <InputComponent
                type="text"
                size="default"
                form={form}
                formKey="name"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="cmisCode" label="Mã nhân viên (CMIS)">
              <InputComponent
                type="text"
                size="default"
                form={form}
                formKey="cmisCode"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="positionId" label="Chức vụ">
              <Selection
                url={Endpoint.GET_POSITION_LIST}
                formKey="positionId"
                form={form}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="gender" label="Giới tính">
              <Radio.Group>
                <Radio value={1}>Nam</Radio>
                <Radio value={2}>Nữ</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24} md={12}>
            <Form.Item name="jobPosition" label="Vị trí công việc">
              <Selection
                url={Endpoint.GET_VI_TRI_CONG_VIEC}
                formKey="jobPosition"
                form={form}
                mode="multiple"
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="pageSize"
              label="Cấu hình hiển thị số trang của bảng"
            >
              <Select allowClear={false}>
                {optionPageSize.map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="email" label="Email">
              <InputComponent
                type="text"
                size="default"
                form={form}
                formKey="email"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="phoneNumber" label="Điện thoại">
              <InputComponent
                type="text"
                size="default"
                form={form}
                formKey="phoneNumber"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="giayToTuyThan" label="Số CMND/CCCD/Hộ chiếu">
              <InputComponent type="text" size="default" form={form} formKey="giayToTuyThan" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Ngày cấp"
              name=""
            >
              <DatePicker
                locale={locale}
                defaultValue={user.ngayCap ? moment(user.ngayCap, FORMAT_DATE) : null}
                format={FORMAT_DATE}
                onChange={(value) => handleChangeNgayCap(value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="noiCap" label="Nơi cấp">
              <InputComponent type="text" size="default" form={form} formKey="noiCap" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} justify="center">
          <Col span={4}>
            <ButtonComponent
              type="primary"
              htmlType="submit"
              size="large"
              className="full"
              disabled={loading}
            >
              Cập nhật
            </ButtonComponent>
          </Col>
        </Row>
      </FormComponent>
    </Spin>
  );
}

const SSOContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  .sso-checkbox {
    margin-right: 20px;
    > span {
      margin-left: 10px;
    }
  }
`;
