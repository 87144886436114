import {
  METHOD_POST,
  METHOD_PUT,
  STATUSCODE_200,
  TYPE_TRA_HO_SO,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { getErrorMessage } from "@utils/function";
import { authPostData, authPutData } from "@utils/request";

export const handleReturnHoSo = (
  values,
  detail,
  setLoading,
  setFetchDataTable,
  closeModal,
  form,
  typeTraLai
) => {
  const data = {
    ...values,
    hoSoId: detail.id,
  };
  if (typeTraLai === TYPE_TRA_HO_SO.THI_CONG) {
    authPutData({
      url: Endpoint.THI_CONG_TRA_LAI_HO_SO,
      method: METHOD_PUT,
      payload: data,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          setFetchDataTable(true);
          closeModal();
        } else {
          getErrorMessage(res, form);
        }
      },
    });
  } else {
    authPostData({
      url: Endpoint.TRA_LAI_HO_SO,
      method: METHOD_POST,
      payload: data,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          setFetchDataTable(true);
          closeModal();
        } else {
          getErrorMessage(res, form);
        }
      },
    });
  }
};
