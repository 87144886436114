import FormComponent from "@components/Form";
import React, { useState } from "react";
import { Endpoint } from "@utils/endpoint";
import Selection from "@components/Select";
import DatePickerComponent from "@components/DatePicker";

import {
  InputNumber,
  Divider,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Spin,
  Table,
} from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import { handleCapNhatBaoCao } from "./service";

export default function ModalCapNhat13G(props) {
  const [loading, setLoading] = useState(false);
  const { visible, setVisible, onCancel, dataTable } = props;
  const [form] = Form.useForm();
  const { user } = useSelector(authSelector);
  const onFinish = (values) => {
    const payload = {
      ...values,
      ...(values.reportDate && { Nam: values.reportDate.split("-")[0] }),
      ...(values.reportDate && { Thang: values.reportDate.split("-")[1] }),
      useR_TAO: user.username,
    };
    handleCapNhatBaoCao(
      payload,
      setLoading,
      Endpoint.BCCN_THEM_CAP_NHAT_13H,
      form
    );
  };

  const columns = [
    {
      title: "Tháng",
      dataIndex: "THANG",
      key: "THANG",
      width: "5%",
      align: "center",
    },
    {
      title: "Năm",
      dataIndex: "NAM",
      key: "NAM",
      width: "5%",
      align: "center",
    },
    {
      title: "Thành phần",
      dataIndex: "THANH_PHAN",
      key: "THANH_PHAN",
      width: "5%",
    },
    {
      title: "Tiền điện đã thu trong tháng",
      dataIndex: "TienDienDaThu",
      key: "TienDienDaThu",
      width: "15%",

      children: [
        {
          title: "Tổng số",
          dataIndex: "TONGSO",
          key: "TONGSO",
          width: "5%",
          align: "center",
        },
        {
          title: "Trong đó khách hàng trả trước",
          dataIndex: "TTIEN_TRATRUOC",
          key: "TTIEN_TRATRUOC",
          width: "10%",
          align: "center",
        },
      ],
    },
    {
      title: "Phân tích số tiền đã thu",
      dataIndex: "THANH_PHAN",
      key: "THANH_PHAN",
      width: "50%",
      children: [
        {
          title: "Nộp tiền mặt vào ngân hàng",
          dataIndex: "TIENMAT_NGANHANG",
          key: "TIENMAT_NGANHANG",
          width: "10%",
          align: "center",
        },
        {
          title: "Nộp tiền mặt vào quỹ",
          dataIndex: "TIENMAT_QUY",
          key: "TIENMAT_QUY",
          width: "10%",
          align: "center",
        },
        {
          title: "Thu qua ngân hàng và TCTG",
          dataIndex: "TM_DIEMTHUTG",
          key: "TM_DIEMTHUTG",
          width: "25%",
          children: [
            {
              title: "Tổng ",
              dataIndex: "TONGSO",
              key: "TONGSO",
              width: "5%",
              align: "center",
            },
            {
              title: "Thu TM tại điểm thu của TCTG",
              dataIndex: "TM_DIEMTHUTG",
              key: "TM_DIEMTHUTG",
              width: "10%",
              align: "center",
            },
            {
              title: "Thu qua CK, UNC, UNT, ATM, Ví ĐT",
              dataIndex: "THU_HTHUCKHAC",
              key: "THU_HTHUCKHAC",
              width: "10%",
              align: "center",
            },
          ],
        },
        {
          title: "Tiền điện bù trừ",
          dataIndex: "TIENDIEN_BUTRU",
          key: "TIENDIEN_BUTRU",
          width: "7%",
          align: "center",
        },
        {
          title: "Chuyển trả khách hàng",
          dataIndex: "CHUYENTRA_KH",
          key: "CHUYENTRA_KH",
          width: "7%",
          align: "center",
        },
      ],
    },

    {
      title: "Thu hộ",
      dataIndex: "THUHO",
      key: "THUHO",
      width: "5%",
      align: "center",
    },
    {
      title: "Được thu hộ",
      dataIndex: "DUOC_THUHO",
      key: "DUOC_THUHO",
      width: "5%",
      align: "center",
    },
  ];

  return (
    <Modal
      visible={visible}
      title="Cập nhật số tiền đã thu của khách hàng dùng điện 13g"
      onCancel={onCancel}
      width={"100%"}
      style={{ height: "500px" }}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Container>
          <FormComponent
            onFinish={onFinish}
            form={form}
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 13 }}
          >
            <Row>
              <Col span={8}>
                <Form.Item
                  name="reportDate"
                  label="Tháng làm việc"
                  labelAlign="left"
                >
                  <DatePickerComponent
                    picker="month"
                    formKey="reportDate"
                    form={form}
                    format={"YYYY-MM"}
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
              <Col span={8}>
                <Form.Item name="mA_DVIQLY" label="Đơn vị" labelAlign="left">
                  <Selection
                    url={Endpoint.GET_TEAM_LIST_CODE}
                    formKey="mA_DVIQLY"
                    form={form}
                  />
                </Form.Item>
              </Col>
            </Row>
            <SubContainer>
              <Divider className="section-text" orientation="left">
                Tiền điện
              </Divider>
              <Row>
                <Col span={8}>
                  <Form.Item
                    name="ttieN_TRATRUOC"
                    label="Tiền trả trước"
                    labelAlign="left"
                  >
                    <InputNumber placeholder="Nhập tiền trả trước" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="tienmaT_NGANHANG"
                    label="Tiền mặt nộp ngân hàng"
                    labelAlign="left"
                  >
                    <InputNumber placeholder="Nhập tiền mặt nộp ngân hàng" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="tienmaT_QUY"
                    label="Tiền mặt nộp quỹ"
                    labelAlign="left"
                  >
                    <InputNumber placeholder="Nhập tiền mặt nộp quỹ" />
                  </Form.Item>
                </Col>
              </Row>
              <SubContainer>
                <Divider className="section-text" orientation="left">
                  Thu ngân và TCTG
                </Divider>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="tM_DIEMTHUTG"
                      label="Thu tiền mặt và TCTG"
                      labelAlign="left"
                    >
                      <InputNumber placeholder="Nhập thu tiền mặt và TCTG" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="thU_HTHUCKHAC"
                      label="Thu qua CK, UNC, UNT, ATM, Ví ĐT"
                      labelAlign="left"
                    >
                      <InputNumber placeholder="Nhập thu qua CK, UNC, UNT, ATM, Ví ĐT" />
                    </Form.Item>
                  </Col>
                </Row>
              </SubContainer>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="tiendieN_BUTRU"
                    label="Tiền điện bù trừ"
                    labelAlign="left"
                  >
                    <InputNumber placeholder="Nhập tiền điện bù trừ" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="chuyentrA_KH"
                    label="Chuyển trả khách hàng"
                    labelAlign="left"
                  >
                    <InputNumber placeholder="Nhập chuyển trả khách hàng" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name="thuho" label="Thu hộ" labelAlign="left">
                    <InputNumber placeholder="Nhập thu hộ" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="duoC_THUHO"
                    label="Được thu hộ"
                    labelAlign="left"
                  >
                    <InputNumber placeholder="Nhập được thu hộ" />
                  </Form.Item>
                </Col>
              </Row>
            </SubContainer>
            <SubContainer>
              <Divider className="section-text" orientation="left">
                Tiền điện năng phản kháng
              </Divider>
              <Row>
                <Col span={8}>
                  <Form.Item
                    name="ttieN_TRATRUOCCSPK"
                    label="Tiền trả trước"
                    labelAlign="left"
                  >
                    <InputNumber placeholder="Nhập tiền trả trước" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="tienmaT_NGANHANGCSPK"
                    label="Tiền mặt nộp ngân hàng"
                    labelAlign="left"
                  >
                    <InputNumber placeholder="Nhập tiền mặt nộp ngân hàng" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="tienmaT_QUYCSPK"
                    label="Tiền mặt nộp quỹ"
                    labelAlign="left"
                  >
                    <InputNumber placeholder="Nhập tiền mặt nộp quỹ" />
                  </Form.Item>
                </Col>
              </Row>
              <SubContainer>
                <Divider className="section-text" orientation="left">
                  Thu ngân và TCTG
                </Divider>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="tM_DIEMTHUTGCSPK"
                      label="Thu tiền mặt và TCTG"
                      labelAlign="left"
                    >
                      <InputNumber placeholder="Nhập thu tiền mặt và TCTG" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="thU_HTHUCKHACCSPK"
                      label="Thu qua CK, UNC, UNT,ATM, Ví ĐT"
                      labelAlign="left"
                    >
                      <InputNumber placeholder="Nhập thu qua CK, UNC, UNT,ATM, Ví ĐT" />
                    </Form.Item>
                  </Col>
                </Row>
              </SubContainer>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="tiendieN_BUTRUCSPK"
                    label="Tiền điện bù trừ"
                    labelAlign="left"
                  >
                    <InputNumber placeholder="Nhập tiền điện bù trừ" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="chuyentrA_KHCSPK"
                    label="Chuyển trả khách hàng"
                    labelAlign="left"
                  >
                    <InputNumber placeholder="Nhập chuyển trả khách hàng" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name="thuhocspk" label="Thu hộ" labelAlign="left">
                    <InputNumber placeholder="Nhập thu hộ" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="duoC_THUHOCSPK"
                    label="Được thu hộ"
                    labelAlign="left"
                  >
                    <InputNumber placeholder="Nhập được thu hộ" />
                  </Form.Item>
                </Col>
              </Row>
            </SubContainer>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Cập nhật
              </Button>
            </Form.Item>

            <Table
              columns={columns}
              dataSource={dataTable}
              scroll={{ y: 420 }}
              pagination={false}
            />
          </FormComponent>
        </Container>
      </Spin>
    </Modal>
  );
}

const Container = styled.div`
  .section-text {
    font-size: 14px;
    font-weight: bold;
  }
`;

const SubContainer = styled.div`
  border: 1px solid #ccc !important;
  padding: 0px 20px 20px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
`;
