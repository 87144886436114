import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData } from "@utils/request";

export const getDanhSachYeuCauDaPhanCong = (
  setLoading,
  setData,
  query,
  filterConditions,
  setTotal
) => {
  authGetData({
    url: `${Endpoint.CRMGDV_DANH_SACH_YEU_CAU_DA_PHAN_CONG}?${query}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              STT:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            };
          })
        );
        setTotal(res.paging.totalCount);
      }
    },
  });
};

export const getLinhVucList = (
  setLoading,
  form_filter,
  setData,
  filterConditions,
  setTotal,
  location,
  setListLoading,
  setLinhVucList
) => {
  authGetData({
    url: `${Endpoint.CRM_LIST_LINH_VUC}`,
    setLoading,
    onSuccess: (res) => {
      setLinhVucList(res.data);
      const current_query = {
        ...filterConditions,
        ...(res.data.length === 1 && { LinhVuc: res.data[0].value }),
      };

      if (res.data.length === 1) {
        form_filter.setFieldsValue({
          LinhVuc: res.data[0].value || undefined,
        });
      }
      const updated_query = {
        ...current_query,
        pageSize: filterConditions.pageSize,
        pageIndex: filterConditions.pageIndex,
      };

      const query = buildQueryString(updated_query);

      // setSearchParams(query);
      getDanhSachYeuCauDaPhanCong(
        setListLoading,
        setData,
        query,
        filterConditions,
        setTotal
      );
    },
  });
};
