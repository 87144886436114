import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString } from "@utils/function";
import { authGetData } from "@utils/request";

export const getThoiGianGhiChiSo = (
  setLoading,
  setDataThoiGianGCS,
  setTotalCount,
  query
) => {
  authGetData({
    url: `${Endpoint.THOI_GIAN_THUC_HIEN_GCS}?${buildQueryString(query)}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataThoiGianGCS(res.data);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};
