import { authSelector } from "@state/auth";
import { Col, Form, Input, Row, Select, Tooltip, Button } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import moment from "moment";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";
import { ENUM_LOAI_KIEM_TRA } from "@utils/constants";
import {
  handleListDataTram,
  getListDonViService
} from "./services";
import { removeAccents } from "@utils/function";
import FormComponent from "@components/Form";
// import DatePickerComponent from "@components/DatePicker";
const { Option } = Select;

export function ListFilter(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    tooltip
  } = props;
  const { user } = useSelector(authSelector);
  const [loading, setLoading] = useState(true);
  const [dataDonVi, setDataDonVi] = useState([]);
  const [dataNhomTram, setDataNhomTram] = useState([]);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  useEffect(() => {
    getListDonViService(setLoading, setDataDonVi);
  }, []);

  useEffect(() => {
    handleListDataTram(setLoading, setDataNhomTram, user.unitId);
  }, [user.unitId]);

  const handleChangeDonVi = useCallback((values) => {
    handleListDataTram(setLoading, setDataNhomTram, values);
  }, []);
  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        DonViId: user.unitId.toString(),
        // TenNhanVien: user.id
      }}
    >
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="DonViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Select onChange={handleChangeDonVi} showSearch 
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
              >
                {dataDonVi && dataDonVi.length > 0
                  ? dataDonVi.map((item, index) => (
                    <Option key={index} value={item.value}>
                      {item.name}
                    </Option>
                  ))
                  : null}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.DonViId !== currentValues.DonViId) {
                form.setFieldsValue({
                  DoiId: undefined,
                });
              }
              return prevValues.DonViId !== currentValues.DonViId;
            }}
          >
            {({ getFieldValue }) => {
              if (getFieldValue("DonViId")) {
                return (
                  <Form.Item name="DoiId" label="Tổ đội">
                    <Selection
                      url={`${Endpoint.GET_TEAM_LIST}?donviId=${getFieldValue(
                        "DonViId"
                      )}`}
                      formKey="DoiId"
                      form={form}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="DoiId" label="Tổ đội">
                  <Selection
                    url={`${Endpoint.GET_TEAM_LIST}?donviId=${user.unitId}`}
                    formKey="DoiId"
                    form={form}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="Tram" label="Trạm"
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.DonViId !== currentValues.DonViId) {
                form.setFieldsValue({
                  Tram: undefined,
                });
              }
              return prevValues.DonViId !== currentValues.DonViId;
            }}
          >
            <Select placeholder={"Tất cả"} allowClear loading={loading}
              showSearch mode="multiple"
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {dataNhomTram && dataNhomTram.length > 0
                ? dataNhomTram.map((item, index) => {
                  if (item.maTram !== null) {
                    return (
                      <Option key={index} value={item.maTram}>
                        {item.name}
                      </Option>
                    );
                  }
                  return null;
                })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="SoGCS" label="Sổ GCS">
            <Input
              suffix={
                <Tooltip title={tooltip}>
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="LoaiKiemTra" label="Loại kiểm tra">
            <Select placeholder={"Tất cả"} defaultValue={ENUM_LOAI_KIEM_TRA.DINHMUC}>
              <Option value={ENUM_LOAI_KIEM_TRA.DINHMUC}>Định mức</Option>
              <Option value={ENUM_LOAI_KIEM_TRA.APGIA}>Áp giá</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          {/* <Form.Item name="TenNhanVien" label="Nhân viên lập bảng kê">
            <Select defaultValue={user.id} disabled>
              <Option value={user.id}>{user.name}</Option>
            </Select>
          </Form.Item> */}
          {
            !user.isTongCongTy ? (
              <Form.Item name="TenNhanVien" label="Nhân viên lập bảng kê">
                <Select defaultValue={user.id} disabled>
                  <Option value={user.id}>{user.name}</Option>
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  if (prevValues.DonViId !== currentValues.DonViId) {
                    form.setFieldsValue({
                      UserId: undefined,
                    });
                  }
                  return prevValues.DonViId !== currentValues.DonViId;
                }}
              >
                {({ getFieldValue }) => {
                  if (getFieldValue("DonViId")) {
                    return (
                      <Form.Item name="TenNhanVien" label="Nhân viên lập bảng kê">
                        <Selection
                          url={`${Endpoint.AG_LIST_NV_PHAN_CONG
                            }?DonViId=${getFieldValue("DonViId")}`}
                          formKey="TenNhanVien"
                          form={form}
                        />
                      </Form.Item>
                    );
                  }
                  return (
                    <Form.Item name="TenNhanVien" label="Nhân viên lập bảng kê">
                      <Selection
                        url={`${Endpoint.AG_LIST_NV_PHAN_CONG}?DonViId=${user.unitId}`}
                        formKey="TenNhanVien"
                        form={form}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            )
          }
        </Col>
        {/* <Col span={24} md={3}>
          <Form.Item name="ThangKiemTra" label="Tháng kiểm tra">
            <DatePickerComponent
              defaultValue={
                filterConditions.ThangKiemTra
                  ? moment(filterConditions.ThangKiemTra, FORMAT_MONTH_ENGLISH)
                  : moment(moment(), FORMAT_MONTH)
              }
              format={FORMAT_MONTH_ENGLISH}
              form={form}
              formKey={"ThangKiemTra"}
              picker="month"
              allowClear={true}
            />
          </Form.Item>
        </Col> */}
      </Row>
      <Row justify="end">
        <Button className="mr-24px" onClick={clear}>
          Bỏ lọc
        </Button>
        <Button type="primary" htmlType="submit" form="filter-form">
          Tìm kiếm
        </Button>
      </Row>
    </FormComponent>
  );
}
