import { Fragment, useState } from "react";
import Table from "../../common/danh-sach-bao-cao";
import { TYPE_TABLE_BAOCAO } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";

export default function Index() {
  const [fetchList, setFetchList] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <Fragment>
      <Table
        url={Endpoint.BC_CT_BATTHUONG}
        typeList={TYPE_TABLE_BAOCAO.BATTHUONG}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        header={"Báo cáo công tơ bất thường"}
        rowSelection={rowSelection}
      />
    </Fragment>
  );
}
