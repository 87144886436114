import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useEffect,
  useMemo
} from "react";
import { useTranslation } from "react-i18next";
import Filter from "../../common/Filter";
import CheckPermission from "@components/CheckPermission";
import { ghi_chi_so } from "@permissions/d_gcs";
import { TableStyled, Container } from "./css-styled";
import { Tag, Form, Input, Button, Row, Col, Modal } from "antd";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { handleListData, submitTraSoCMIS, submitTraLaiBK, submitHuyTraSoCMIS } from "./services";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilterDaySoGCS } from "./list-bo-loc";
import { ENUM_GCSTRANGTHAISO, ENUM_KY_GCS } from "@utils/constants";
import LichSuSoModal from "../../common/modal/lich-su-so/modal-lich-su-so";
import TableComponent from "@components/TableComponent";
import moment from "moment";
import { getListLoaiBK } from "../../cau-hinh/cau-hinh-ky-bang-ke/services";

export default function Index() {
  const { t } = useTranslation();
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [dataDaySoCmis, setDataDaySoCmis] = useState([]);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const [lichGCSId, setLichGCSId] = useState();
  const [tenSoGCS, setTenSoGCS] = useState();
  const [ky, setKy] = useState();
  const [loaiBK, setLoaiBK] = useState([]);
  const [thangNam, setThangNam] = useState();
  const [visibleLichSu, setVisibleLichSu] = useState(false);
  const [tempDataDaySoCmis, setTempDataDaySoCmis] = useState([]);
  const [tempTotalCount, setTempTotalCount] = useState(0);
  const [term, setTerm] = useState("");
  const [widthTacVu, setWidthTacVu] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    ...parseParams(location.search),
    Ky: ENUM_KY_GCS.KY_1,
    MaTrangThai: ENUM_GCSTRANGTHAISO.HOAN_THANH_XAC_NHAN_SO,
    NgayGhi: moment().format("DD"),
    Thang: moment().format("MM"),
    Nam: moment().format("YYYY"),
    orderBy: "maSoGCS"
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setSelectedRowKeys([]);
  };

  const changeTerm = (value) => {
    setTerm(value.target.value)
  };

  useEffect(() => {
    getListLoaiBK(setLoading, setLoaiBK);
  }, []);

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
      MaSo: values.MaSo === undefined ? values.MaSo : values.MaSo.trim(),
      TenSo: values.TenSo === undefined ? values.TenSo : values.TenSo.trim(),
      NgayGhi: (values.Ngay === undefined || values.Ngay === null) ? values.Ngay : values.Ngay.substring(8, 10),
      Thang: (values.Ngay === undefined || values.Ngay === null) ? values.Ngay : values.Ngay.substring(5, 7),
      Nam: (values.Ngay === undefined || values.Ngay === null) ? values.Ngay : values.Ngay.substring(0, 4),
    }));
  }, []);

  const SearchTerm = useCallback(() => {
    if (term.trim().length > 0) {
      let listTerm = [];
      listTerm = tempDataDaySoCmis.filter(data =>
        data.maSoGCS.toUpperCase().indexOf(term.trim().toUpperCase()) > -1 ||
        data.tenSoGCS.toUpperCase().indexOf(term.trim().toUpperCase()) > -1);
      setDataDaySoCmis(listTerm)
      setTotalCount(listTerm.length)
    } else {
      setDataDaySoCmis(tempDataDaySoCmis);
      setTotalCount(tempTotalCount)
    }
  }, [tempDataDaySoCmis, tempTotalCount, term]);

  // Trả sổ
  const handleTraLaiBK = useCallback(
    (id) => {
      Modal.confirm({
        centered: true,
        title: "Xác nhận",
        content:
          `Bạn chắc chắn trả bảng kê về Phòng kinh doanh ?`,
        onOk() {
          //call api
          submitTraLaiBK(id, setLoading, setFetchDataTable, t);
        },
        onCancel() { },
        okText: "Đồng ý",
        cancelText: "Hủy",
      });
    },
    [t]
  );
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      Ky: ENUM_KY_GCS.KY_1,
      MaTrangThai: ENUM_GCSTRANGTHAISO.HOAN_THANH_XAC_NHAN_SO,
      NgayGhi: moment().format("DD"),
      Thang: moment().format("MM"),
      Nam: moment().format("YYYY"),
      orderBy: "maSoGCS"
    });
  }, [user.unitId]);

  const getListData = useCallback(() => {
    handleListData(
      location,
      filterConditions,
      setLoading,
      setDataDaySoCmis,
      setTempDataDaySoCmis,
      setTotalCount,
      setTempTotalCount,
      setWidthTacVu
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListData();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListData, setFetchDataTable]);

  useEffect(() => {
    getListData();
  }, [getListData]);

  const columns = [
    {
      title: t("phan_cong.ma_so"),
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      render: (text, record) => (
        <div className="table-text-left">
          <span
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
            onClick={() => handleOpenLichSuSoModal(record.id, record.tenSoGCS, record.ky, record.thangNam)}
          >
            {text}
          </span>
        </div>
      ),
      width: "5%",
      sorter: true,
      fixed: "left"
    },
    {
      title: t("phan_cong.ten_so"),
      dataIndex: "tenSoGCS",
      key: "tenSoGCS",
      render: (text) => <span>{text}</span>,
      width: "10%",
      sorter: true,
    },
    {
      title: t("phan_cong.hinh_thuc_gcs"),
      dataIndex: "hinhThuc",
      key: "hinhThuc",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "6%",
    },
    {
      title: t("phan_cong.ky_gcs"),
      dataIndex: "ky",
      key: "ky",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "5%",
      sorter: true,
    },
    {
      title: t("phan_cong.ngay_ghi"),
      dataIndex: "ngayGhi",
      key: "ngayGhi",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "5%",
    },
    {
      title: t("phan_cong.thang_nam"),
      dataIndex: "thangNam",
      key: "thangNam",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "5%",
    },
    {
      title: t("phan_cong.to_doi"),
      dataIndex: "tenToDoi",
      key: "tenToDoi",
      render: (text) => <span>{text}</span>,
      width: "8%",
    },
    {
      title: t("phan_cong.nhan_vien_gcs"),
      dataIndex: "tenNhanVien",
      key: "tenNhanVien",
      render: (text) => <span>{text}</span>,
      width: "7%",
    },
    {
      title: t("phan_cong.trang_thai"),
      dataIndex: "trangThai",
      key: "trangThai",
      className: "text-center",
      render: (text, record) => (
        record.maTrangThai === ENUM_GCSTRANGTHAISO.HOAN_THANH_XAC_NHAN_SO.toString() ?
          <Tag style={{ display: "block", textAlign: "center" }} color={"#26c0e9"}>{"Chưa đẩy về CMIS"}</Tag> :
          <Tag style={{ display: "block", textAlign: "center" }} color={"#faad14"}>{"Đã đẩy về CMIS"}</Tag>
      ),
      width: "7%",
    },
    {
      title: t("phan_cong.tac_vu"),
      dataIndex: "tacVu",
      key: "tacVu",
      className: "text-center",
      render: (text, record) => (
        <div>
          {record.danhSachBangKe?.map((e) => (
            loaiBK.find(x => x.id === e.loaiBangKeId).maLoaiBangKe === "BKCS" ?
              <Button style={{ marginRight: "10px", backgroundColor: "orange" }}>
                <a href={e.filePDF} target='_blank' rel='noopener noreferrer'>
                  Xem bảng kê BKCS </a>
              </Button> :
              loaiBK.find(x => x.id === e.loaiBangKeId).maLoaiBangKe === "SLBT" ?
                <Button style={{ marginRight: "10px"}}>
                  <a href={e.filePDF} target='_blank' rel='noopener noreferrer'>
                    Xem bảng kê SLBT </a>
                </Button> :
                <Button style={{ marginRight: "10px" }}>
                  <a href={e.filePDF} target='_blank' rel='noopener noreferrer'>
                    Xem bảng kê TTBT </a>
                </Button>
          ))}
          {record.danhSachBangKe?.map((e) => (
            loaiBK.find(x => x.id === e.loaiBangKeId).maLoaiBangKe === "BKCS" ?
              <CheckPermission permissionCode={ghi_chi_so.d14tl}>
                <Button
                  hidden={
                    record.maTrangThai ===
                    ENUM_GCSTRANGTHAISO.HOAN_THANH_DAY_VE_CMIS.toString()
                  }
                  type="primary"
                  onClick={() => handleTraLaiBK(e.id)}
                >
                  Trả lại bảng kê
                </Button>
              </CheckPermission> : null
          ))}
        </div>
      ),
      width: widthTacVu === 0 ? "5%" : widthTacVu === 1 ? "15%" : widthTacVu === 2 ? "20%" : "27%",
    },
  ];

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: "2%",
    fixed: "left",
    dataIndex: "",
    // getCheckboxProps: (record) => ({
    //   disabled: record.maTrangThai === ENUM_GCSTRANGTHAISO.HOAN_THANH_DAY_VE_CMIS.toString(),
    // }),
  };


  // Trả sổ CMIS
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDaySoVeCMIS = () => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content:
        `Bạn chắc chắn muốn đẩy sổ GCS đã chọn về CMIS?`,
      onOk() {
        //call api
        submitTraSoCMIS(
          t,
          setLoading,
          setFetchDataTable,
          selectedRowKeys,
          setSelectedRowKeys
        );
      },
      onCancel() { },
      okText: "Đồng ý",
      cancelText: "Hủy",
    });
  };

  const handleHuyDaySoVeCMIS = () => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content:
        `Bạn chắc chắn muốn hủy đẩy sổ GCS đã chọn về CMIS?`,
      onOk() {
        //call api
        submitHuyTraSoCMIS(
          t,
          setLoading,
          setFetchDataTable,
          selectedRowKeys,
          setSelectedRowKeys
        );
      },
      onCancel() { },
      okText: "Đồng ý",
      cancelText: "Hủy",
    });
  };
  //open popup lịch sử sổ
  const handleOpenLichSuSoModal = (lichGCSId, tenSoGCS, ky, thangNam) => {
    setVisibleLichSu(true);
    setLichGCSId(lichGCSId);
    setTenSoGCS(tenSoGCS);
    setKy(ky);
    setThangNam(thangNam);
  };
  //đóng popup
  const handleCloseModal = () => {
    // setVisible(false);
    setLoading(false);
    setVisibleLichSu(false);
  };
  //render popup lịch sử sổ
  const showLichSuSoModal = useMemo(
    () =>
      visibleLichSu && (
        <LichSuSoModal
          visible={visibleLichSu}
          closeModal={handleCloseModal}
          lichGCSId={lichGCSId}
          tenSoGCS={tenSoGCS}
          ky={ky}
          thangNam={thangNam}
        />
      ),
    [visibleLichSu, lichGCSId, tenSoGCS, ky, thangNam]
  );
  return (
    <Fragment>
      <Filter>
        <ListFilterDaySoGCS
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
        />
      </Filter>
      <Container>
        <Row style={{ marginBottom: "-33px", marginLeft: "10px" }}>
          <span style={{ marginTop: "6px" }}>Danh sách sổ GCS: </span>
          <Col span={24} md={8}>
            <Input
              placeholder="Nhập mã sổ, tên sổ"
              style={{ marginLeft: 20, marginBottom: 35, width: 300 }}
              onChange={changeTerm}
              onPressEnter={() => SearchTerm()}
            />
          </Col>
        </Row>
        <CheckPermission permissionCode={ghi_chi_so.d14ds}>
          <Button
            type="primary"
            onClick={() => handleDaySoVeCMIS()}
            disabled={selectedRowKeys.length === 0 ? true : false}
            style={{ float: "right", marginTop: "-34px", marginRight: "10px" }}
            loading={loading}
          >
            Đẩy sổ về CMIS
          </Button>
          <Button
            type="primary"
            onClick={() => handleHuyDaySoVeCMIS()}
            disabled={selectedRowKeys.length === 0 ? true : false}
            style={{ float: "right", marginTop: "-34px", marginRight: "150px" }}
            loading={loading}
          >
            Hủy đẩy sổ về CMIS
          </Button>
        </CheckPermission>
        <TableStyled>
          <TableComponent
            header={"Đẩy sổ về CMIS"}
            dataSource={dataDaySoCmis}
            columns={customColumn(columns, filterConditions)}
            listFilter={<ListFilterDaySoGCS form={form} />}
            totalData={totalCount}
            loading={loading}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            scrollX={2200}
            rowSelection={rowSelection}
            rowKey="id"
            isNoResize
          />
        </TableStyled>
      </Container>
      {showLichSuSoModal}
    </Fragment>
  );
}