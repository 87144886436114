import { memo, useEffect, useRef, useState } from "react";
import { Form, Input, Button, Modal, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import { handleSubmitForm } from "./services";

const { TextArea } = Input;

function DoiModal({ detaiDataTable, visible, closeModal, getListData }) {
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  //submit dữ liệu

  const onFinish = (values) => {
    handleSubmitForm(
      values,
      detaiDataTable,
      setLoading,
      closeModal,
      getListData,
      form
    );
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();
    // eslint-disable-next-line
  }, []);

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={detaiDataTable.id ? t("doi.cap_nhat_doi") : t("doi.tao_doi")}
        visible={visible}
        onCancel={closeModal}
        className="modal-doi"
        footer={null}
      >
        <FormComponent
          form={form}
          name="form-doi"
          onFinish={onFinish}
          initialValues={{
            ...detaiDataTable,
          }}
          layout="vertical"
          scrollToFirstError
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item>
                <span>{t("uy_quyen.title.ma_don_vi_quan_ly")}</span>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item>
                <span className="font-bold">{user.unitName}</span>
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t("doi.ma_doi")}
                name="maDoi"
                className="required-field"
              >
                <Input
                  readOnly={detaiDataTable.id ? true : false}
                  ref={detaiDataTable.id ? undefined : inputRef}
                  placeholder="Nhập mã đội"
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t("doi.ten_doi")}
                name="tenDoi"
                className="required-field"
              >
                <Input
                  ref={detaiDataTable.id ? inputRef : undefined}
                  placeholder="Nhập tên đội"
                />
              </Form.Item>
            </Col>
            <Col span={24} md={24}>
              <Form.Item label={t("doi.ghi_chu")} name="ghiChu">
                <TextArea rows={3} placeholder="Nhập ghi chú" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-doi"
                loading={loading}
              >
                {detaiDataTable.id ? t("button.cap_nhat") : t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(DoiModal);
