import {
  INDEX_TAB2,
  METHOD_POST,
  METHOD_PUT,
  STATUSCODE_200,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  parseParams,
  alertMessage,
  getErrorMessage,
  formatYYYYMMDDString,
  renderErrorFields,
} from "@utils/function";
import {
  authDeleteData,
  authGetData,
  authPostData,
  authPostFileData,
} from "@utils/request";
import moment from "moment";

export const handleListData = (
  setLoading,
  filterConditions,
  setDataVatTu,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.LIST_VAT_TU}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataVatTu(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataVatTu([]);
    },
  });
};

export const getListThiCong = (setLoading, setDataHinhThuc, skip) => {
  authGetData({
    url: `${Endpoint.LIST_HINH_THUC_THI_CONG}?pageSize=-1&skipNone=${skip}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataHinhThuc(res.data);
      } else setDataHinhThuc([]);
    },
  });
};

export const handleRemoveData = (ids, setLoading, getListData, t) => {
  authDeleteData({
    url: Endpoint.REMOVE_VAT_TU + "?Ids=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) getListData();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

export const handleListLoaiYC = (setLoading, setDataLoaiYC) => {
  authGetData({
    url: `${Endpoint.COMMON_LIST_LOAI_YEU_CAU}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataLoaiYC(res.data);
      } else setDataLoaiYC([]);
    },
  });
};

export const handleSubmitCreateData = (
  values,
  setLoading,
  getListData,
  closeModal,
  form,
  vatTuChinh,
  many,
  ngayBatDau,
  ngayKetThuc,
  dataSource,
  dataLoaiYC,
  dataHinhThucNone,
  nameFields,
  countYC,
  dispatch,
  keyData
) => {
  const data = {
    ...values,
    isVatTuChinh: vatTuChinh,
    chungLoai: parseInt(values.chungLoai),
    loaiHinhThucThiCong: many,
  };
  //validate form
  const dataFields = [];
  const arrHinhThuc = [];
  for (let k = 0; k <= keyData.length; k++) {
    for (let j = 0; j < nameFields.length; j++) {
      dataFields.push({
        values: values[nameFields[j] + "-" + keyData[k]],
        name: nameFields[j] + "-" + keyData[k],
      });
    }
    arrHinhThuc.push({
      values: values[`hinhThucThiCong-` + keyData[k]],
      name: `hinhThucThiCong-` + keyData[k],
    });
  }

  arrHinhThuc.forEach((item) => {
    return renderErrorFields(form, item.values, item.name, false);
  });
  dataFields.forEach((item) => {
    return renderErrorFields(form, item.values, item.name, true);
  });

  // init data vật tư thời gian hiệu lực
  const vatTuThoiGianHieuLuc = {
    hinhThucThiCongDonGia: [],
  };

  vatTuThoiGianHieuLuc.donGiaVatTu = parseFloat(values.donGiaVatTu);
  vatTuThoiGianHieuLuc.donGiaMayThiCong = parseFloat(values.donGiaMayThiCong);
  vatTuThoiGianHieuLuc.donGiaNhanCongNoiBo = parseFloat(
    values.donGiaNhanCongNoiBo
  );
  for (let i = 0; i < keyData.length; i++) {
    vatTuThoiGianHieuLuc.hinhThucThiCongDonGia.push({
      hinhThucThiCongId: !many
        ? dataHinhThucNone[0].id
        : values[`hinhThucThiCong-${keyData[i]}`],
      loaiDonGiaLoaiYeuCauDonGia: [
        {
          loaiDonGia: 0,
          loaiYeuCauDonGia: [
            {
              loaiYeuCauId:
                dataLoaiYC && dataLoaiYC.length > 0
                  ? dataLoaiYC[1].value
                  : null,
              donGia: values[`dgtg1-${keyData[i]}`],
            },
            {
              loaiYeuCauId:
                dataLoaiYC && dataLoaiYC.length > 0
                  ? dataLoaiYC[3].value
                  : null,
              donGia: values[`dgtg2-${keyData[i]}`],
            },
            {
              loaiYeuCauId:
                dataLoaiYC && dataLoaiYC.length > 0
                  ? dataLoaiYC[2].value
                  : null,
              donGia: values[`dgtg3-${keyData[i]}`],
            },
          ],
        },
        {
          loaiDonGia: 1,
          loaiYeuCauDonGia: [
            {
              loaiYeuCauId:
                dataLoaiYC && dataLoaiYC.length > 0
                  ? dataLoaiYC[1].value
                  : null,
              donGia: values[`dgttc1-${keyData[i]}`],
            },
            {
              loaiYeuCauId:
                dataLoaiYC && dataLoaiYC.length > 0
                  ? dataLoaiYC[3].value
                  : null,
              donGia: values[`dgttc2-${keyData[i]}`],
            },
            {
              loaiYeuCauId:
                dataLoaiYC && dataLoaiYC.length > 0
                  ? dataLoaiYC[2].value
                  : null,
              donGia: values[`dgttc3-${keyData[i]}`],
            },
          ],
        },
        {
          loaiDonGia: 2,
          loaiYeuCauDonGia: [
            {
              loaiYeuCauId:
                dataLoaiYC && dataLoaiYC.length > 0
                  ? dataLoaiYC[1].value
                  : null,
              donGia: values[`dgttcvt1-${keyData[i]}`],
            },
            {
              loaiYeuCauId:
                dataLoaiYC && dataLoaiYC.length > 0
                  ? dataLoaiYC[3].value
                  : null,
              donGia: values[`dgttcvt2-${keyData[i]}`],
            },
            {
              loaiYeuCauId:
                dataLoaiYC && dataLoaiYC.length > 0
                  ? dataLoaiYC[2].value
                  : null,
              donGia: values[`dgttcvt3-${keyData[i]}`],
            },
          ],
        },
      ],
    });

    delete data[`dgtg1-${keyData[i]}`];
    delete data[`dgtg2-${keyData[i]}`];
    delete data[`dgtg3-${keyData[i]}`];
    delete data[`dgttc1-${keyData[i]}`];
    delete data[`dgttc2-${keyData[i]}`];
    delete data[`dgttc3-${keyData[i]}`];
    delete data[`dgttcvt1-${keyData[i]}`];
    delete data[`dgttcvt2-${keyData[i]}`];
    delete data[`dgttcvt3-${keyData[i]}`];
    delete data[`hinhThucThiCong-${keyData[i]}`];
  }

  const from = moment(values.ngayHieuLuc ? values.ngayHieuLuc : ngayBatDau);

  vatTuThoiGianHieuLuc.ngayHieuLuc = formatYYYYMMDDString(
    from.year(),
    from.month(),
    from.date()
  );

  const to = moment(values.ngayHetHieuLuc);
  if (moment(to).isValid()) {
    vatTuThoiGianHieuLuc.ngayHetHieuLuc = formatYYYYMMDDString(
      to.year(),
      to.month(),
      to.date()
    );
  } else vatTuThoiGianHieuLuc.ngayHetHieuLuc = null;
  delete data.donGiaMayThiCong;
  delete data.donGiaNhanCongNoiBo;
  delete data.donGiaVatTu;
  delete data.ngayHieuLuc;
  delete data.ngayHetHieuLuc;

  data.vatTuThoiGianHieuLuc = vatTuThoiGianHieuLuc;
  handleCreate(data, setLoading, getListData, closeModal, form, dispatch);
};

const handleCreate = (
  data,
  setLoading,
  getListData,
  closeModal,
  form,
  dispatch
) => {
  authPostData({
    url: Endpoint.CREATE_VAT_TU,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        getListData();
        dispatch(closeModal());
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleSubmitUpdateDataVatTu = (
  values,
  setLoading,
  getListData,
  closeModal,
  form,
  vatTuChinh,
  many
) => {
  const data = {
    ...values,
    isVatTuChinh: vatTuChinh,
    chungLoai: values.chungLoai.toString(),
    loaiHinhThucThiCong: many,
  };

  handleUpdateVatTu(data, setLoading, getListData, closeModal, form);
};

const handleUpdateVatTu = (data, setLoading, getListData, closeModal, form) => {
  authPostData({
    url: Endpoint.UPDATE_THONG_TIN_VAT_TU,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        getListData();
        closeModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

const flat = (res, key, val, pre = "") => {
  const prefix = [pre, key].filter((v) => v).join(".");
  return typeof val === "object"
    ? Object.keys(val).reduce(
        (prev, curr) => flat(prev, curr, val[curr], prefix),
        res
      )
    : Object.assign(res, { [prefix]: val });
};
function flatObject(input) {
  return Object.keys(input).reduce(
    (prev, curr) => flat(prev, curr, input[curr]),
    {}
  );
}
const flatInArray = (values) => {
  return Object.assign(
    {},
    ...(function _flatten(o) {
      return [].concat(
        ...Object.keys(o).map((k) =>
          typeof o[k] === "object" ? _flatten(o[k]) : { [k]: o[k] }
        )
      );
    })(values)
  );
};

export const handleListThoiGian = (
  detailTableData,
  setLoading,
  setData,
  setTotalCount,
  pageIndex,
  setDataTable,
  setCountData
) => {
  authGetData({
    url: `${Endpoint.LIST_VAT_TU_THOI_GIAN}?pageIndex=${pageIndex}&pageSize=1&vatTuId=${detailTableData?.vatTuId}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setData(res.data);
        const newArray = res.data[0].hinhThucThiCongDonGias.map(
          (data, index) => {
            const hinhThucThiCongId = data.hinhThucThiCongId;
            const donGiaTronGois = flatInArray(
              data.donGiaTronGois.map((rs, indexDonGiaTG) => {
                return { [`dgtg${indexDonGiaTG + 1}-${index}`]: rs };
              })
            );
            const donGiaTuTucCaps = flatInArray(
              data.donGiaTuTucCaps.map((rs, indexDonGiaTC) => {
                return { [`dgttc${indexDonGiaTC + 1}-${index}`]: rs };
              })
            );
            const donGiaTuTucCapVatTus = flatInArray(
              data.donGiaTuTucCapVatTus.map((rs, indexDonGiaVT) => {
                return { [`dgttcvt${indexDonGiaVT + 1}-${index}`]: rs };
              })
            );
            return flatObject({
              [`hinhThucThiCongId-${index}`]: hinhThucThiCongId,
              ...donGiaTronGois,
              ...donGiaTuTucCaps,
              ...donGiaTuTucCapVatTus,
              key: index,
            });
          }
        );
        setDataTable(newArray);
        setCountData(newArray.length);
        setTotalCount(res.paging.totalCount);
      } else setData([]);
    },
  });
};

export const handleSubmitUpdateDataVatTuThoiGian = (
  values,
  dataUpdate,
  setLoading,
  getListData,
  form,
  vatTuChinh,
  many,
  ngayBatDau,
  ngayKetThuc,
  dataLoaiYC,
  dataHinhThucNone,
  nameFields,
  countYC,
  idVatTuThoiGian,
  addThongTin,
  keyData,
  getListVatTuThoiGian,
  handleBackForm
) => {
  const data = {
    ...values,
    isThemThongTin: addThongTin,
  };
  //validate form
  const dataFields = [];
  const arrHinhThuc = [];
  for (let k = 0; k <= keyData.length; k++) {
    for (let j = 0; j < nameFields.length; j++) {
      dataFields.push({
        values: values[nameFields[j] + "-" + keyData[k]],
        name: nameFields[j] + "-" + keyData[k],
      });
    }
    arrHinhThuc.push({
      values: values[`hinhThucThiCong-` + keyData[k]],
      name: `hinhThucThiCong-` + keyData[k],
    });
  }

  arrHinhThuc.forEach((item) => {
    return renderErrorFields(form, item.values, item.name, false);
  });
  dataFields.forEach((item) => {
    return renderErrorFields(form, item.values, item.name, true);
  });

  // init data vật tư thời gian hiệu lực
  const vatTuThoiGianHieuLuc = {
    hinhThucThiCongDonGia: [],
  };
  vatTuThoiGianHieuLuc.id = idVatTuThoiGian;
  vatTuThoiGianHieuLuc.donGiaVatTu = parseFloat(values.donGiaVatTu);
  vatTuThoiGianHieuLuc.donGiaMayThiCong = parseFloat(values.donGiaMayThiCong);
  vatTuThoiGianHieuLuc.donGiaNhanCongNoiBo = parseFloat(
    values.donGiaNhanCongNoiBo
  );
  for (let i = 0; i < keyData.length; i++) {
    vatTuThoiGianHieuLuc.hinhThucThiCongDonGia.push({
      hinhThucThiCongId: !many
        ? dataHinhThucNone[0].id
        : values[`hinhThucThiCong-${keyData[i]}`],
      loaiDonGiaLoaiYeuCauDonGia: [
        {
          loaiDonGia: 0,
          loaiYeuCauDonGia: [
            {
              loaiYeuCauId:
                dataLoaiYC && dataLoaiYC.length > 0
                  ? dataLoaiYC[1].value
                  : null,
              donGia: values[`dgtg1-${keyData[i]}`],
            },
            {
              loaiYeuCauId:
                dataLoaiYC && dataLoaiYC.length > 0
                  ? dataLoaiYC[3].value
                  : null,
              donGia: values[`dgtg2-${keyData[i]}`],
            },
            {
              loaiYeuCauId:
                dataLoaiYC && dataLoaiYC.length > 0
                  ? dataLoaiYC[2].value
                  : null,
              donGia: values[`dgtg3-${keyData[i]}`],
            },
          ],
        },
        {
          loaiDonGia: 1,
          loaiYeuCauDonGia: [
            {
              loaiYeuCauId:
                dataLoaiYC && dataLoaiYC.length > 0
                  ? dataLoaiYC[1].value
                  : null,
              donGia: values[`dgttc1-${keyData[i]}`],
            },
            {
              loaiYeuCauId:
                dataLoaiYC && dataLoaiYC.length > 0
                  ? dataLoaiYC[3].value
                  : null,
              donGia: values[`dgttc2-${keyData[i]}`],
            },
            {
              loaiYeuCauId:
                dataLoaiYC && dataLoaiYC.length > 0
                  ? dataLoaiYC[2].value
                  : null,
              donGia: values[`dgttc3-${keyData[i]}`],
            },
          ],
        },
        {
          loaiDonGia: 2,
          loaiYeuCauDonGia: [
            {
              loaiYeuCauId:
                dataLoaiYC && dataLoaiYC.length > 0
                  ? dataLoaiYC[1].value
                  : null,
              donGia: values[`dgttcvt1-${keyData[i]}`],
            },
            {
              loaiYeuCauId:
                dataLoaiYC && dataLoaiYC.length > 0
                  ? dataLoaiYC[3].value
                  : null,
              donGia: values[`dgttcvt2-${keyData[i]}`],
            },
            {
              loaiYeuCauId:
                dataLoaiYC && dataLoaiYC.length > 0
                  ? dataLoaiYC[2].value
                  : null,
              donGia: values[`dgttcvt3-${keyData[i]}`],
            },
          ],
        },
      ],
    });

    delete data[`dgtg1-${keyData[i]}`];
    delete data[`dgtg2-${keyData[i]}`];
    delete data[`dgtg3-${keyData[i]}`];
    delete data[`dgttc1-${keyData[i]}`];
    delete data[`dgttc2-${keyData[i]}`];
    delete data[`dgttc3-${keyData[i]}`];
    delete data[`dgttcvt1-${keyData[i]}`];
    delete data[`dgttcvt2-${keyData[i]}`];
    delete data[`dgttcvt3-${keyData[i]}`];
    delete data[`hinhThucThiCong-${keyData[i]}`];
  }

  if (
    values.ngayHieuLuc === undefined ||
    values.ngayHieuLuc === "" ||
    values.ngayHieuLuc === null
  ) {
    vatTuThoiGianHieuLuc.ngayHieuLuc = null;
  } else {
    const from = moment(values.ngayHieuLuc);
    vatTuThoiGianHieuLuc.ngayHieuLuc = formatYYYYMMDDString(
      from.year(),
      from.month(),
      from.date()
    );
  }

  if (
    values.ngayHetHieuLuc === undefined ||
    values.ngayHetHieuLuc === "" ||
    values.ngayHetHieuLuc === null
  )
    vatTuThoiGianHieuLuc.ngayHetHieuLuc = null;
  else {
    const to = moment(values.ngayHetHieuLuc);
    vatTuThoiGianHieuLuc.ngayHetHieuLuc = formatYYYYMMDDString(
      to.year(),
      to.month(),
      to.date()
    );
  }

  delete data.donGiaMayThiCong;
  delete data.donGiaNhanCongNoiBo;
  delete data.donGiaVatTu;
  delete data.ngayHieuLuc;
  delete data.ngayHetHieuLuc;
  data.vatTuThoiGianHieuLuc = vatTuThoiGianHieuLuc;

  handleUpdateVatTuThoiGian(
    data,
    setLoading,
    getListData,
    form,
    getListVatTuThoiGian,
    handleBackForm
  );
};

const handleUpdateVatTuThoiGian = (
  data,
  setLoading,
  getListData,
  form,
  getListVatTuThoiGian,
  handleBackForm
) => {
  authPostData({
    url: Endpoint.UPDATE_VAT_TU_THOI_GIAN,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        getListData();
        getListVatTuThoiGian();
        handleBackForm();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleListLoaiChiPhi = (setLoading, setDataLoaiChiPhi) => {
  authGetData({
    url: `${Endpoint.LIST_LOAI_CHI_PHI}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataLoaiChiPhi(res.data);
      } else setDataLoaiChiPhi([]);
    },
  });
};

export const handleListTableColumns = (setLoading, setTable) => {
  authGetData({
    url: `${Endpoint.GET_LIST_CAU_HINH_TABLE}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setTable(res.data);
      } else setTable([]);
    },
  });
};

export const handleDelete = (ids, setLoading, t, getListVatTuThoiGian) => {
  authDeleteData({
    url: Endpoint.DELETE_VAT_TU_THOI_GIAN + "?Ids=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListVatTuThoiGian();
      }
    },
  });
};

export const callAPIImportFile = ({
  url,
  method,
  setLoading,
  payload,
  setFileExOld,
  setFileExNew,
  keyTab,
  setDataLog,
  setMessErr,
  setMessErr2,
  getListData,
}) => {
  authPostFileData({
    url,
    method,
    setLoading,
    payload,

    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        if (keyTab === INDEX_TAB2) setFileExOld({});
        else setFileExNew({});
        alertMessage(
          "success",
          "Thông báo!",
          `Import file excel thành công. Số vật tư lưu thành công ${res.data.countRecordSuccess}`
        );
        getListData();
      } else {
        setDataLog(res.data.errors);
        if (keyTab === INDEX_TAB2) {
          setMessErr2(res.data.message);
        } else setMessErr(res.data.message);
      }
    },
  });
};
