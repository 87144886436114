import { Fragment } from "react";
import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { Container } from "./css-styled";
import ListFilter from "./list-bo-loc";

export default function ListYeuCauTable(props) {
  const {
    typeList,
    loading,
    dataSource,
    totalData,
    handleSearch,
    clearFilter,
    onChangePagination,
    filterConditions,
    rowSelection,
    genExtra,
    user,
    columns,
    header,
    rowKey,
    checkTraLai,
    biTraLai,
    trangThai,
    soPha,
    firstTrangThai,
    typeDS,
    onRow,
    onChangeTrangThai,
    urlXuatExcel,
    permissionExcel,
    filterConditionsTraCuu,
    checkLocTheoThang,
    locTheoThang,
    dataMesEVNHES,
  } = props;
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          tooltip={"Tìm kiếm theo mã điểm đo, tên KH, Mã công tơ"}
          user={user}
          typeList={typeList}
          checkTraLai={checkTraLai}
          biTraLai={biTraLai}
          trangThai={trangThai}
          soPha={soPha}
          firstTrangThai={firstTrangThai}
          typeDS={typeDS}
          onChangeTrangThai={onChangeTrangThai}
          urlXuatExcel={urlXuatExcel}
          permissionExcel={permissionExcel}
          filterConditionsTraCuu={filterConditionsTraCuu}
          checkLocTheoThang={checkLocTheoThang}
          locTheoThang={locTheoThang}
        />
      </Filter>
      <Container>
        <Fragment>
          {dataMesEVNHES && Object.keys(dataMesEVNHES).length > 0 ? (
            <div style={{ paddingLeft: 10 }}>
              <div>
                Tổng số công tơ:
                {"  "}
                <span style={{ fontWeight: 600 }}>{dataMesEVNHES.total}</span>
              </div>
              <div>
                Số công tơ có các biểu đều lấy đủ chỉ số:{"  "}
                <span style={{ fontWeight: 600, color: "rgb(82, 196, 26)" }}>
                  {dataMesEVNHES.soDuChiSo}
                </span>
              </div>
              <div>
                Số công tơ kết thúc lấy MDMS, chuyển MTB:{"  "}
                <span style={{ fontWeight: 600, color: "rgb(255, 142, 143)" }}>
                  {dataMesEVNHES.soKhongDuDieuKien}
                </span>
                , trong đó{" "}
                <span style={{ fontWeight: 600, color: "rgb(255, 142, 143)" }}>
                  {dataMesEVNHES.soGiaTriNhoHon}
                </span>{" "}
                công tơ có giá trị ghi nhỏ hơn giá trị cũ.
              </div>
            </div>
          ) : null}
        </Fragment>
        <TableComponent
          header={header}
          renderExtraAction={genExtra ? () => genExtra() : null}
          dataSource={dataSource}
          columns={columns}
          totalData={totalData}
          loading={loading}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
          scrollX={2400}
          scrollY={520}
          rowSelection={rowSelection ? rowSelection : null}
          rowKey={rowKey}
          onRow={onRow ? onRow : null}
          pageSizeOptionKTCT={
            totalData > 500
              ? [10, 20, 50, 100, 200, 500, totalData]
              : [10, 20, 50, 100, 200, 500]
          }
        />
      </Container>
    </Fragment>
  );
}
