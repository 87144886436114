import { Col, Row, Checkbox } from "antd";
import { ContainerCheckBox } from "../../../../css-styled";
export default function Index(props) {
  const { detail, isThaoCongTo, isLapTrinhLaiCongTo } = props;
  return (
    <Row gutter={24}>
      <Col span={24} md={24}>
        <ContainerCheckBox>
          <Checkbox
            defaultChecked={
              isLapTrinhLaiCongTo === undefined
                ? isThaoCongTo
                : isLapTrinhLaiCongTo
            }
            disabled={true}
            className="fs-14px font-bold"
          >
            {isLapTrinhLaiCongTo === undefined
              ? "Tháo công tơ"
              : "Lập trình lại công tơ"}
          </Checkbox>
        </ContainerCheckBox>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Mã công tơ</h6>
        <h5 className="fs-14px font-bold">{detail?.maCongTo}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Số công tơ</h6>
        <h5 className="fs-14px font-bold">{detail?.soCongTo}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Loại công tơ</h6>
        <h5 className="fs-14px font-bold">{detail?.loaiCongTo}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Nước SX</h6>
        <h5 className="fs-14px font-bold">{detail?.nuocSX}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">BCS</h6>
        <h5 className="fs-14px font-bold">{detail?.bcs}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Số pha</h6>
        <h5 className="fs-14px font-bold">{detail?.soPha}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Dòng điện</h6>
        <h5 className="fs-14px font-bold">{detail?.dongDien}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Điện áp</h6>
        <h5 className="fs-14px font-bold">{detail?.dienAp}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Ngày kiểm định</h6>
        <h5 className="fs-14px font-bold">{detail?.ngayKiemDinh}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Tem kiểm định</h6>
        <h5 className="fs-14px font-bold">{detail?.temKiemDinh}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Mã tem kiểm định</h6>
        <h5 className="fs-14px font-bold">{detail?.maTemKiemDinh}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Số viên chì kiểm định</h6>
        <h5 className="fs-14px font-bold">{detail?.soVienTemKD}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Mã số chì hộp</h6>
        <h5 className="fs-14px font-bold">{detail?.maChiQHopDauDay}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Số viên chì hộp</h6>
        <h5 className="fs-14px font-bold">{detail?.soVienChiHop}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Mã kìm chì tai</h6>
        <h5 className="fs-14px font-bold">{detail?.maKimChiTai}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Số viên chì tai</h6>
        <h5 className="fs-14px font-bold">{detail?.soVienChiTai}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Ngày lập trình cuối cùng</h6>
        <h5 className="fs-14px font-bold">{detail?.ngayLapTrinhCuoi}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Số lần lập trình</h6>
        <h5 className="fs-14px font-bold">{detail?.soLanLapTrinh}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Mã chì booc</h6>
        <h5 className="fs-14px font-bold">{detail?.maChiBooc}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Số viên chì booc</h6>
        <h5 className="fs-14px font-bold">{detail?.soVienChiBooc}</h5>
      </Col>
    </Row>
  );
}
