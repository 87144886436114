import { memo, useState } from "react";
import "moment/locale/vi";
import XacNhanXuatVatTuModal from "./modal-xac-nhan-xuat-vat-tu";
import { FORMAT_ENGLISH } from "@utils/constants";
import moment from "moment";
import { submitXacNhan } from "../services";

function Index({ visible, handleCloseModal, setFetchDataTable, detail, t }) {
  const toDay = moment().format(FORMAT_ENGLISH);
  const [ngay, setNgay] = useState(toDay);
  const [loading, setLoading] = useState(false);

  //Handle submit

  const onSubmitXacNhan = () => {
    submitXacNhan(
      detail,
      ngay,
      setLoading,
      setFetchDataTable,
      handleCloseModal
    );
  };

  const handleChangeDate = (date) => {
    let convert = "";
    if (date === null) convert = undefined;
    else convert = moment(date).format(FORMAT_ENGLISH);
    setNgay(convert);
  };

  return (
    <XacNhanXuatVatTuModal
      t={t}
      visible={visible}
      handleCloseModal={handleCloseModal}
      onSubmitXacNhan={onSubmitXacNhan}
      handleChangeDate={handleChangeDate}
      loading={loading}
    />
  );
}

export default memo(Index);
