import BKAnhGCSCongTo from "@modules/ghi-chi-so/bangke/bangkechitiet/bk-anh-cs-cong-to";
import BKAnhSanLuongBT from "@modules/ghi-chi-so/bangke/bangkechitiet/bk-anhslbt/bk-anh-slbt";
import BkChiSo from "@modules/ghi-chi-so/bangke/bangkechitiet/bk-chiso";
import BKCongToDaDoiSoat from "@modules/ghi-chi-so/bangke/bangkechitiet/bk-cong-to-da-doi-soat";
import BKSanLuongCongTo from "@modules/ghi-chi-so/bangke/bangkechitiet/bk-cong-to-co-san-luong-bt";
import DSCongToTinhTrangBatThuong from "@modules/ghi-chi-so/bangke/bangkechitiet/bk-ds-cong-to-tinh-trang-bt";
import BKDSDoiSoatDat from "@modules/ghi-chi-so/bangke/bangkechitiet/bk-ds-doi-soat-dat";
import DSCongToDoiSoatKhongDat from "@modules/ghi-chi-so/bangke/bangkechitiet/bk-ds-doi-soat-khong-dat";
import BKKhachHangPhaiGhiPMax from "@modules/ghi-chi-so/bangke/bangkechitiet/bk-kh-ghi-pmax";
import BKKhongChupAnh from "@modules/ghi-chi-so/bangke/bangkechitiet/ds-cong-to-khong-chup-anh";
import BKKhachHangKhongDoiSoat from "@modules/ghi-chi-so/bangke/bangkechitiet/bk-kh-khong-doi-soat";
// import bang ke
import BKKiemSoatCS from "@modules/ghi-chi-so/bangke/bangkechitiet/bk-kiem-soat-cs";
import BKLichSuPhucTraChiSo from "@modules/ghi-chi-so/bangke/bangkechitiet/bk-lich-su-phuc-tra-chi-so";
import BKPhucTra from "@modules/ghi-chi-so/bangke/bangkechitiet/bk-phuc-tra";
import BKThoiGianGhiChiSo from "@modules/ghi-chi-so/bangke/bangkechitiet/bk-thoi-gian-ghi-chi-so";
import BKTinhTrangCongTo from "@modules/ghi-chi-so/bangke/bangkechitiet/bk-tinh-trang-cong-to";
import PhieuXacMinhChiSoCongTo from "@modules/ghi-chi-so/bangke/bangkechitiet/bk-xac-minh-chi-so-cong-to";
// import baocao
import THGSLichGCS from "@modules/ghi-chi-so/baocao/bao-cao-giam-sat-theo-nam-quy-ngay-thang/th-lich-gcs-theo-nam";
import GCSTheoNgay from "@modules/ghi-chi-so/baocao/bao-cao-giam-sat-theo-nam-quy-ngay-thang/th-lich-gcs-theo-ngay";
import GCSTheoQuy from "@modules/ghi-chi-so/baocao/bao-cao-giam-sat-theo-nam-quy-ngay-thang/th-lich-gcs-theo-quy";
import GCSTheoThang from "@modules/ghi-chi-so/baocao/bao-cao-giam-sat-theo-nam-quy-ngay-thang/th-lich-gcs-theo-thang";
import CTGCS from "@modules/ghi-chi-so/baocao/bao-cao-tt-xuat-dl-theo-nam-thang-ngay-quy/chi-tiet-thoi-gian";
import TimeGCS from "@modules/ghi-chi-so/baocao/bao-cao-tt-xuat-dl-theo-nam-thang-ngay-quy/tong-hop-thoi-gian";
import BKDsCacKHCapNhatThayDoi from "@modules/ghi-chi-so/baocao/bk-ds-cac-kh-cap-nhat-cs-thay-doi-cs";
import DsSoCapNhatSlSoVoiNgayGCSXNgay from "@modules/ghi-chi-so/baocao/ds-cac-so-cap-nhat-sl-so-voi-ngay-gcs-x-ngay";
import DsSoChuaKySo from "@modules/ghi-chi-so/baocao/ds-cac-so-chua-ky-so";
import DsCacSoGCSTheoDV from "@modules/ghi-chi-so/baocao/ds-cac-so-gcs-theo-tung-don-vi-ct-den-doiql";
import DsSoXuatdltruocXngay from "@modules/ghi-chi-so/baocao/ds-so-xuat-dl-truoc-x-ngay";
import DanhSachToaDoKhachHang from "@modules/ghi-chi-so/baocao/ds-toa-do-khach-hang/ds-toa-do-khach-hang";
// bao cao nhanh
import BaoCaoNhanh from "@modules/ghi-chi-so/baocaonhanh";
import CauHinhKyBangKe from "@modules/ghi-chi-so/cau-hinh/cau-hinh-ky-bang-ke";
import CauHinhNhanVien from "@modules/ghi-chi-so/cau-hinh/cau-hinh-nhan-vien";
import DaySoVeCMIS from "@modules/ghi-chi-so/dieu-hanh-ghi-chi-so/day-so-ve-cmis";
import DieuHanhDoiSoat from "@modules/ghi-chi-so/dieu-hanh-ghi-chi-so/doi-soat-anh-chi-so";
import DieuHanhKyBK from "@modules/ghi-chi-so/dieu-hanh-ghi-chi-so/ky-bang-ke";
import NhanSoTuCMIS from "@modules/ghi-chi-so/dieu-hanh-ghi-chi-so/nhan-so-tu-cmis";
import PhanCongGCS from "@modules/ghi-chi-so/dieu-hanh-ghi-chi-so/phan-cong-ghi-chi-so";
import DoiTruongDoiSoat from "@modules/ghi-chi-so/doi-truong/doi-soat-anh-chi-so";
import DoiTruongKBK from "@modules/ghi-chi-so/doi-truong/ky-bang-ke";
import DoiTruongPhanCong from "@modules/ghi-chi-so/doi-truong/phan-cong-gcs";
import CapNhatChiSoEVNHES from "@modules/ghi-chi-so/nhan-vien-gcs/cap-nhat-chi-so-tu-evn-hes";
import DoisoatAnhChiSo from "@modules/ghi-chi-so/nhan-vien-gcs/doi-soat-anh-chi-so";
import NVKyBangKe from "@modules/ghi-chi-so/nhan-vien-gcs/ky-bang-ke";
import PhongKDKBK from "@modules/ghi-chi-so/phong-kinh-doanh/ky-bang-ke";
import DanhSachSoGCS from "@modules/ghi-chi-so/tra-cuu-tim-kiem/danh-sach-so-gcs";
import TongHopAnhChupGCS from "@modules/ghi-chi-so/baocao/tong-hop-anh-chup-gcs/index";
import { ghi_chi_so } from "@permissions/d_gcs";
import {
  MODULE_GHICHISO,
  NAVIGATE_CAPNHATCHISOEVNHES,
  NAVIGATE_CAUHINHBANGKE,
  NAVIGATE_DAYSOVECMIS,
  NAVIGATE_DIEUHANHDOISOAT,
  NAVIGATE_DOISOATANHCHISO,
  NAVIGATE_GHICHISO,
  NAVIGATE_GHICHISO_BAOCAO,
  NAVIGATE_GHICHISO_CAUHINH,
  NAVIGATE_GHICHISO_DIEUHANHGCS,
  NAVIGATE_GHICHISO_DOITRUONGGCS,
  NAVIGATE_GHICHISO_INBANGKE,
  NAVIGATE_GHICHISO_NHANVIENGCS,
  NAVIGATE_GHICHISO_PHONGKINHDOANHGCS,
  NAVIGATE_GHICHISO_TRACUUTIMKIEM,
  NAVIGATE_KYBANGKE,
  NAVIGATE_NHANSOTUCMIS,
  NAVIGATE_PHANCONGSOGCS,
  NAVIGATE_CAUHINHNHANVIENGCSMACDINH,
  TITLE_BAOCAO,
  TITLE_CAPNHATCHISOEVNHES,
  TITLE_CAUHINH,
  TITLE_CAUHINHBANGKE,
  TITLE_DAYSOVECMIS,
  TITLE_DIEUHANHGCS,
  TITLE_DOISOATANHCHISO,
  TITLE_DOITRUONGGCS,
  TITLE_INBANGKE,
  TITLE_KYBANGKE,
  TITLE_NHANSOTUCMIS,
  TITLE_NHANVIENGCS,
  TITLE_PHANCONGSOGCS,
  TITLE_PHONGKINHDOANHGCS,
  TITLE_TRACUUTIMKIEM,
  TITLE_CAUHINHNVGCS,
} from "@utils/constants";
const mainPath = "/ghi-chi-so";
const routes = [
  {
    title: "Trang Chủ",
    path: mainPath,
    privateRoute: true,
    component: BaoCaoNhanh,
    permissionCode: MODULE_GHICHISO,
  },
  {
    title: TITLE_DIEUHANHGCS,
    path: `${NAVIGATE_GHICHISO}${NAVIGATE_GHICHISO_DIEUHANHGCS}`,
    privateRoute: true,
    permissionCode: ghi_chi_so.d1,
    subItems: [
      {
        subTitle: TITLE_NHANSOTUCMIS,
        subPath: NAVIGATE_NHANSOTUCMIS,
        subComponent: NhanSoTuCMIS,
        permissionCode: ghi_chi_so.d11,
      },
      {
        subTitle: TITLE_PHANCONGSOGCS,
        subPath: NAVIGATE_PHANCONGSOGCS,
        subComponent: PhanCongGCS,
        permissionCode: ghi_chi_so.d12,
      },
      {
        subTitle: TITLE_KYBANGKE,
        subPath: NAVIGATE_KYBANGKE,
        subComponent: DieuHanhKyBK,
        permissionCode: ghi_chi_so.d13,
      },
      {
        subTitle: TITLE_DAYSOVECMIS,
        subPath: NAVIGATE_DAYSOVECMIS,
        subComponent: DaySoVeCMIS,
        permissionCode: ghi_chi_so.d14,
      },
      {
        subTitle: TITLE_DOISOATANHCHISO,
        subPath: NAVIGATE_DIEUHANHDOISOAT,
        subComponent: DieuHanhDoiSoat,
        permissionCode: ghi_chi_so.d15,
      },
    ],
  },
  {
    title: TITLE_NHANVIENGCS,
    path: `${NAVIGATE_GHICHISO}${NAVIGATE_GHICHISO_NHANVIENGCS}`,
    privateRoute: true,
    permissionCode: ghi_chi_so.d2,
    subItems: [
      {
        subTitle: TITLE_CAPNHATCHISOEVNHES,
        subPath: NAVIGATE_CAPNHATCHISOEVNHES,
        subComponent: CapNhatChiSoEVNHES,
        permissionCode: ghi_chi_so.d21,
      },
      {
        subTitle: TITLE_DOISOATANHCHISO,
        subPath: NAVIGATE_DOISOATANHCHISO,
        subComponent: DoisoatAnhChiSo,
        permissionCode: ghi_chi_so.d22,
      },
      {
        subTitle: TITLE_KYBANGKE,
        subPath: NAVIGATE_KYBANGKE,
        subComponent: NVKyBangKe,
        permissionCode: ghi_chi_so.d23,
      },
    ],
  },
  {
    title: TITLE_DOITRUONGGCS,
    path: `${NAVIGATE_GHICHISO}${NAVIGATE_GHICHISO_DOITRUONGGCS}`,
    privateRoute: true,
    permissionCode: ghi_chi_so.d3,
    subItems: [
      {
        subTitle: TITLE_PHANCONGSOGCS,
        subPath: NAVIGATE_PHANCONGSOGCS,
        subComponent: DoiTruongPhanCong,
        permissionCode: ghi_chi_so.d31,
      },
      {
        subTitle: TITLE_DOISOATANHCHISO,
        subPath: NAVIGATE_DOISOATANHCHISO,
        subComponent: DoiTruongDoiSoat,
        permissionCode: ghi_chi_so.d32,
      },
      {
        subTitle: TITLE_KYBANGKE,
        subPath: NAVIGATE_KYBANGKE,
        subComponent: DoiTruongKBK,
        permissionCode: ghi_chi_so.d33,
      },
    ],
  },
  {
    title: TITLE_PHONGKINHDOANHGCS,
    path: `${NAVIGATE_GHICHISO}${NAVIGATE_GHICHISO_PHONGKINHDOANHGCS}`,
    privateRoute: true,
    permissionCode: ghi_chi_so.d4,
    subItems: [
      {
        subTitle: TITLE_KYBANGKE,
        subPath: NAVIGATE_KYBANGKE,
        subComponent: PhongKDKBK,
        permissionCode: ghi_chi_so.d41,
      },
    ],
  },
  // bang ke
  {
    title: TITLE_INBANGKE,
    path: `${NAVIGATE_GHICHISO}${NAVIGATE_GHICHISO_INBANGKE}`,
    privateRoute: true,
    permissionCode: ghi_chi_so.d5,
    subItems: [
      {
        subTitle: "Bảng kê kiểm soát chỉ số",
        subPath: "/bang-ke-kiem-soat-chi-so",
        subComponent: BKKiemSoatCS,
        permissionCode: ghi_chi_so.d5,
      },
      {
        subTitle: "Bảng kê công tơ có sản lượng bất thường",
        subPath: "/bang-ke-cong-to-co-san-luong-bat-thuong",
        subComponent: BKSanLuongCongTo,
        permissionCode: ghi_chi_so.d5,
      },
      {
        subTitle: "Bảng kê chỉ số",
        subPath: "/bang-ke-chi-so",
        subComponent: BkChiSo,
        permissionCode: ghi_chi_so.d5,
      },
      {
        subTitle: "Bảng kê ảnh sản lượng bất thường",
        subPath: "/bang-ke-anh-gcs",
        subComponent: BKAnhSanLuongBT,
        permissionCode: ghi_chi_so.d5,
      },
      {
        subTitle: "Bảng kê khách hàng ghi PMAX",
        subPath: "/bang-ke-khach-hang-ghi-pmax",
        subComponent: BKKhachHangPhaiGhiPMax,
        permissionCode: ghi_chi_so.d5,
      },
      {
        subTitle: "Phúc tra chỉ số",
        subPath: "/bang-ke-phuc-tra",
        subComponent: BKPhucTra,
        permissionCode: ghi_chi_so.d5,
      },
      {
        subTitle: "Bảng kê lịch sử phúc tra chỉ số",
        subPath: "/bang-ke-lich-su-phuc-tra-chi-so",
        subComponent: BKLichSuPhucTraChiSo,
        permissionCode: ghi_chi_so.d5,
      },
      {
        subTitle: "Phiếu xác minh chỉ số công tơ",
        subPath: "/phieu-xac-minh-chi-so-cong-to",
        subComponent: PhieuXacMinhChiSoCongTo,
        permissionCode: ghi_chi_so.d5,
      },
      {
        subTitle: "Bảng kê khách hàng không chụp ảnh",
        subPath: "/bang-ke-khach-hang-khong-chup-anh",
        subComponent: BKKhongChupAnh,
        permissionCode: ghi_chi_so.d5,
      },
      {
        subTitle: "Danh sách công tơ không đối soát",
        subPath: "/danh-sach-cong-to-khong-doi-soat",
        subComponent: BKKhachHangKhongDoiSoat,
        permissionCode: ghi_chi_so.d5,
      },

      {
        subTitle: "Bảng kê công tơ đã đối soát",
        subPath: "/bang-ke-cong-to-da-doi-soat",
        subComponent: BKCongToDaDoiSoat,
        permissionCode: ghi_chi_so.d5,
      },

      {
        subTitle: "Bảng kê đối soát đạt",
        subPath: "/bang-ke-doi-soat-dat",
        subComponent: BKDSDoiSoatDat,
        permissionCode: ghi_chi_so.d5,
      },
      {
        subTitle: "Danh sách công tơ đối soát không đạt",
        subPath: "/danh-sach-cong-to-doi-soat-khong-dat",
        subComponent: DSCongToDoiSoatKhongDat,
        permissionCode: ghi_chi_so.d5,
      },
      {
        subTitle: "Phiếu xác minh chỉ số công tơ",
        subPath: "/phieu-xac-minh-chi-so-cong-to",
        subComponent: PhieuXacMinhChiSoCongTo,
        permissionCode: ghi_chi_so.d5,
      },
      {
        subTitle: "Bảng kê khách hàng không chụp ảnh",
        subPath: "/bang-ke-khach-hang-khong-chup-anh",
        subComponent: BKKhongChupAnh,
        permissionCode: ghi_chi_so.d5,
      },

      {
        subTitle: "Bảng kê công tơ có tình trạng bất thường",
        subPath: "/bang-ke-cong-to-co-tinh-trang-bat-thuong",
        subComponent: DSCongToTinhTrangBatThuong,
        permissionCode: ghi_chi_so.d5,
      },
      {
        subTitle: "Bảng kê ảnh chỉ số công tơ",
        subPath: "/bang-ke-anh-chi-so-cong-to",
        subComponent: BKAnhGCSCongTo,
        permissionCode: ghi_chi_so.d5,
      },
      {
        subTitle: "Bảng kê thời gian ghi chỉ số",
        subPath: "/bang-ke-thoi-gian-ghi-chi-so",
        subComponent: BKThoiGianGhiChiSo,
        permissionCode: ghi_chi_so.d5,
      },
      {
        subTitle: "Bảng kê tình trạng công tơ",
        subPath: "/bang-ke-tinh-trang-cong-to",
        subComponent: BKTinhTrangCongTo,
        permissionCode: ghi_chi_so.d5,
      },
    ],
  },
  // báo cáo
  {
    title: TITLE_BAOCAO,
    path: `${NAVIGATE_GHICHISO}${NAVIGATE_GHICHISO_BAOCAO}`,
    privateRoute: true,
    permissionCode: ghi_chi_so.d6,
    subItems: [
      {
        subTitle: "Tổng hợp ghi chỉ số theo năm",
        subPath: "/tong-hop-ghi-chi-so-theo-nam",
        subComponent: THGSLichGCS,
        permissionCode: ghi_chi_so.d6,
      },
      {
        subTitle: "Tổng hợp Giám sát lịch GCS hàng quý",
        subPath: "/bao-cao-gcs-theo-quy",
        subComponent: GCSTheoQuy,
        permissionCode: ghi_chi_so.d6,
      },
      {
        subTitle: "Chi tiết giám sát GCS theo tháng",
        subPath: "/bao-cao-gcs-theo-thang",
        subComponent: GCSTheoThang,
        permissionCode: ghi_chi_so.d6,
      },
      {
        subTitle: "Chi tiết Giám sát lịch GCS hàng ngày",
        subPath: "/bao-cao-gcs-theo-ngay",
        subComponent: GCSTheoNgay,
        permissionCode: ghi_chi_so.d6,
      },
      {
        subTitle: "Tổng hợp thời gian thực hiện sổ GCS",
        subPath: "/tong-hop-thoi-gian-thuc-hien-so-gcs",
        subComponent: TimeGCS,
        permissionCode: ghi_chi_so.d6,
      },
      {
        subTitle: "Chi tiết thực hiện thời gian GCS",
        subPath: "/chi-tiet-thuc-hien-thoi-gian-gcs",
        subComponent: CTGCS,
        permissionCode: ghi_chi_so.d6,
      },
      {
        subTitle:
          "Danh sách các sổ GCS theo từng đơn vị, chi tiết đến đội quản lý	",
        subPath: "/ds-so-cac-so-gcs-theo-tung-don-vi-chi-tiet-den-doi-quan-ly",
        subComponent: DsCacSoGCSTheoDV,
        permissionCode: ghi_chi_so.d6,
      },
      {
        subTitle: "DS sổ xuất dữ liệu trước x ngày",
        subPath: "/ds-so-xuat-du-lieu-truoc-x-ngay",
        subComponent: DsSoXuatdltruocXngay,
        permissionCode: ghi_chi_so.d6,
      },
      {
        subTitle:
          "Danh sách các sổ cập nhật số liệu so với ngày ghi chỉ số x ngày",
        subPath: "/ds-cac-so-cap-nhat-so-lieu-so-voi-ngay-gcs-x-ngay",
        subComponent: DsSoCapNhatSlSoVoiNgayGCSXNgay,
        permissionCode: ghi_chi_so.d6,
      },
      {
        subTitle: "Danh sách tọa độ khách hàng theo từng sổ, đội, đơn vị",
        subPath: "/ds-toa-do-khach-hang-theo-so-doi-don-vi",
        subComponent: DanhSachToaDoKhachHang,
        permissionCode: ghi_chi_so.d6,
      },
      {
        subTitle:
          "Bảng kê danh sách các khách hàng cập nhật chỉ số thay đổi chỉ số (chỉ số cũ, chỉ số đã sửa)",
        subPath: "/bk-danh-sach-kh-cap-nhat-cs-thay-doi-cs",
        subComponent: BKDsCacKHCapNhatThayDoi,
        permissionCode: ghi_chi_so.d6,
      },
      {
        subTitle: "Danh sách các sổ chưa thực hiện ký số, ký số chưa đầy đủ",
        subPath: "/ds-cac-so-chua-ky-so",
        subComponent: DsSoChuaKySo,
        permissionCode: ghi_chi_so.d6,
      },
      {
        subTitle: "Tổng hợp ảnh chụp GCS",
        subPath: "/tong-hop-anh-chup-gcs",
        subComponent: TongHopAnhChupGCS,
        permissionCode: ghi_chi_so.d613,
      },
    ],
  },

  {
    title: TITLE_CAUHINH,
    path: `${NAVIGATE_GHICHISO}${NAVIGATE_GHICHISO_CAUHINH}`,
    privateRoute: true,
    permissionCode: ghi_chi_so.d7,
    subItems: [
      {
        subTitle: TITLE_CAUHINHNVGCS,
        subPath: NAVIGATE_CAUHINHNHANVIENGCSMACDINH,
        subComponent: CauHinhNhanVien,
        permissionCode: ghi_chi_so.d71,
      },
      {
        subTitle: TITLE_CAUHINHBANGKE,
        subPath: NAVIGATE_CAUHINHBANGKE,
        subComponent: CauHinhKyBangKe,
        permissionCode: ghi_chi_so.d72,
      },
    ],
  },
  {
    title: TITLE_TRACUUTIMKIEM,
    path: `${NAVIGATE_GHICHISO}${NAVIGATE_GHICHISO_TRACUUTIMKIEM}`,
    privateRoute: true,
    permissionCode: ghi_chi_so.d8,
    subItems: [
      {
        subTitle: "Danh sách sổ GCS",
        subPath: "/danh-sach-so-gcs",
        subComponent: DanhSachSoGCS,
        permissionCode: ghi_chi_so.d81,
      },
    ],
  },
];

export default routes;
