/* eslint-disable no-unused-vars */
import React, { memo, useState } from "react";
import XacNhanBangKeModal from "./modal-xac-nhan-bang-ke";
import {
  handleDongBoLenCmis,
  handleXacNhanBangKe,
  handleXoaYeuCau,
  handleLeadXNBangKe,
  handleEVNHES,
  handleMTB,
} from "./services";
import { TYPE_MODAL } from "@utils/constants";
function Index({
  visibleXacNhan,
  handleCloseModalXN,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys,
  title,
  content,
  type,
  typeDS,
  values,
  setDisableMTB,
  setDataMesEVNHES,
}) {
  const [loading, setLoading] = useState(false);
  // call api xác nhận bảng kê hoặc xóa yêu cầu tùy thuộc vào type

  const handleSubmit = () => {
    if (type === TYPE_MODAL.XN) {
      handleXacNhanBangKe(
        selectedRowKeys,
        setLoading,
        setFetchDataTable,
        handleCloseModalXN,
        setSelectedRowKeys
      );
    } else if (type === TYPE_MODAL.XOA) {
      handleXoaYeuCau(
        selectedRowKeys,
        setLoading,
        setFetchDataTable,
        handleCloseModalXN,
        setSelectedRowKeys
      );
    } else if (type === TYPE_MODAL.DB) {
      handleDongBoLenCmis(
        selectedRowKeys,
        setLoading,
        setFetchDataTable,
        handleCloseModalXN,
        setSelectedRowKeys
      );
    } else if (type === TYPE_MODAL.LEADXN) {
      handleLeadXNBangKe(
        selectedRowKeys,
        setLoading,
        setFetchDataTable,
        handleCloseModalXN,
        setSelectedRowKeys,
        typeDS
      );
    } else if (type === TYPE_MODAL.EVNHES) {
      handleEVNHES(
        selectedRowKeys,
        setLoading,
        setFetchDataTable,
        handleCloseModalXN,
        setSelectedRowKeys,
        values,
        setDisableMTB,
        setDataMesEVNHES
      );
    } else if (type === TYPE_MODAL.MTB) {
      handleMTB(
        selectedRowKeys,
        setLoading,
        setFetchDataTable,
        handleCloseModalXN,
        setSelectedRowKeys,
        setDisableMTB
      );
    }
  };
  return (
    <XacNhanBangKeModal
      loading={loading}
      visible={visibleXacNhan}
      closeModal={handleCloseModalXN}
      handleSubmit={handleSubmit}
      title={title}
      content={content}
    />
  );
}

export default memo(Index);
