import { Endpoint } from "@utils/endpoint";
import { authGetData, authGetMultiplesRequest } from "@utils/request";
import { buildQueryString, parseParams } from "@utils/function";

export const handleListBaoCao = (
  setLoading,
  setData,
  setTotalCount,
  filterConditions,
  setVAT
) => {
  authGetData({
    url:
      Endpoint.GET_LIST_BAO_CAO_TONG_HOP_CHI_PHI_QUYET_TOAN +
      "?" +
      buildQueryString(parseParams(filterConditions)),
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setData(res.data.data);
        setVAT(res.data.vat);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};

export const handleMultiBoLoc = (
  setLoading,
  setLoaiYeuCau,
  setMucDich,
  setSoPha,
  setHinhThucLapDat,
  setHinhThucNopTien,
  setDonVi
) => {
  const endpoints = [
    `${Endpoint.COMMON_LIST_LOAI_YEU_CAU}`,
    `${Endpoint.COMMON_LIST_MUC_DICH}`,
    `${Endpoint.COMMON_LIST_SO_PHA}`,
    `${Endpoint.COMMON_LIST_HINH_THUC_LAP_DAT}`,
    `${Endpoint.COMMON_LIST_HINH_THUC_TRA_TIEN}`,
    `${Endpoint.GET_UNIT_LIST}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setLoaiYeuCau(res[0].data.data);
      setMucDich(res[1].data.data);
      setSoPha(res[2].data.data);
      setHinhThucLapDat(res[3].data.data);
      setHinhThucNopTien(res[4].data.data);
      setDonVi(res[5].data.data);
    },
  });
};
