import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import { Button, Col, Divider, Form, Row, Spin } from "antd";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import {
  EndpointTHTrangWebCapDien,
  handleDownload,
  handleOpenPdf,
} from "./service";

export default function TongHopTrangWebCapDien() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    handleDownload(
      { ...values, reportType: EndpointTHTrangWebCapDien },
      setLoading
    );
  };

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Tổng hợp trang web cấp điện
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              colon={false}
            >
              <Form.Item
                name="reportDate"
                label="Tháng/Năm"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="month"
                  formKey="reportDate"
                  form={form}
                  format={"YYYY-MM"}
                />
              </Form.Item>

              <Row>
                <Col span={4}></Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>

                  {/* <Button
                    type="primary"
                    loading={loading}
                    className="ml-20px"
                    onClick={() => {
                      handleOpenPdf(
                        {
                          ...form.getFieldValue(),
                          reportType: EndpointTHTrangWebCapDien,
                        },
                        setLoading
                      );
                    }}
                  >
                    In báo cáo
                  </Button> */}
                </Col>
              </Row>
            </FormComponent>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
