import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { handlePagination } from "@utils/function";
import { Button, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useCallback, useState } from "react";
import styled from "styled-components";
import Bieu4_ccDoiSoatSlTinNhanCV4 from "./bieu4_cc-doi-soat-sl-tin-nhan";
import { exportData, handleDownload, selectSMSCovid4 } from "./service";

export default function Bieu2ChiTietSLTinNhanCV4() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [screen, setScreen] = useState();
  const [data, setData] = useState([]);

  const optionValue = [
    {
      label: "Biểu 2: Chi tiết số lượng tin nhắn theo từng công ty điện lực",
    },
  ];

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });

  const onFinish = (values) => {
    const dataRequest = {
      ...values,
      reportType:
        Endpoint.BCDOISOATSMSCOVID4_CHI_TIET_SO_LUONG_TIN_NHAN_THEO_TUNG_CONG_TY_DIEN_LUC,
    };
    exportData(dataRequest, setLoading, setData);
  };

  const handleChange = useCallback((values) => {
    setScreen(values);
  }, []);

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      width: 80,
    },
    {
      title: "ID SMS",
      dataIndex: "IDSMS",
      key: "IDSMS",
      width: 100,
    },
    {
      title: "Mã khách hàng",
      dataIndex: "MA_KHANG",
      key: "MA_KHANG",
      width: 100,
    },
    {
      title: "Số điện thoại",
      dataIndex: "DTHOAI",
      key: "DTHOAI",
      width: 100,
    },
    {
      title: "Nội dung",
      dataIndex: "NOI_DUNG",
      key: "NOI_DUNG",
      width: 100,
    },
    {
      title: "Thời gian thực hiện",
      dataIndex: "TGIAN_TH",
      key: "TGIAN_TH",
      width: 100,
    },
    {
      title: "Tình trạng",
      dataIndex: "TINHTRANG",
      key: "TINHTRANG",
      width: 100,
    },
    {
      title: "Nhà mạng",
      dataIndex: "NHA_MANG",
      key: "NHA_MANG",
      width: 100,
    },
    {
      title: "Loại tin nhắn",
      dataIndex: "LOAI_TNHAN",
      key: "LOAI_TNHAN",
      width: 100,
    },
    {
      title: "SL tin nhắn",
      dataIndex: "SL_SMS",
      key: "SL_SMS",
      width: 100,
    },
  ];

  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
            >
              <Form.Item
                label="Tên báo cáo"
                labelAlign="left"
                name="reportType"
              >
                <Select
                  formKey="reportType"
                  defaultValue={optionValue}
                  placeholder="Tất cả"
                  options={selectSMSCovid4}
                  onChange={() => {
                    handleChange(form.getFieldValue("reportType"));
                    console.log(form.getFieldValue("reportType"));
                  }}
                />
              </Form.Item>

              <Form.Item name="unitId" label="Đơn vị" labelAlign="left">
                <Selection
                  url={Endpoint.GET_TEAM_LIST_CODE}
                  formKey="unitId"
                  form={form}
                />
              </Form.Item>

              <Row justify="end">
                <Button
                  type="primary"
                  onClick={() => {
                    handleDownload(
                      {
                        ...form.getFieldValue(),
                        reportType:
                          Endpoint.BCDOISOATSMSCOVID4_CHI_TIET_SO_LUONG_TIN_NHAN_THEO_TUNG_CONG_TY_DIEN_LUC,
                      },
                      setLoading
                    );
                  }}
                >
                  Xuất Excel
                </Button>
              </Row>
            </FormComponent>
            <Divider className="section-text-line" orientation="left"></Divider>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
