import { MenuOutlined } from "@ant-design/icons";
import EllipsisTooltip from "@components/EllipsisTooltip";
import LoadingComponent from "@components/Loading";
import TableComponent from "@components/TableComponent";
import Thanglamviec from "@modules/giao-nhan-dien-nang/common/pdf-viewer/Thanglamviec";
import ListChucNang from "@modules/giao-nhan-dien-nang/quan-ly-diem-do/cap-nhat-thong-tin-diem-do/list-chuc-nang";
import { closeModal, openModal } from "@state/system-config/reducer";
import { useCustomDebounce } from "@utils/request";
import { Button, Col, Dropdown, Row, Table, Tabs } from "antd";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { FillterCapNhat } from "./common/list-bo-loc-tabs/list-bo-loc";
import ModalKhaiBaoDiemDo from "./modal/modal-khai-bao-diem-do";
import { getDanhSachDiemDo, getSoLuongDiemDo } from "./services";
import TableDiemDoTab2 from "./table/tab2-don-vi-khac";
import TableDiemDoTab3 from "./table/tab3-diem-do-ngung-su-dung";

const { TabPane } = Tabs;

export default function CNThongTinDiemDo(props) {
  const { dataDVK } = props;
  const [dataSoLuong, setDataSoLuong] = useState("");
  const [dataDanhSach, setDataDanhSach] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [indexPage, setIndexPage] = useState();
  const [filterConditions, setFilterConditions] = useState("");
  const getListSoLuong = useCallback(() => {
    getSoLuongDiemDo(filterConditions, setLoading, setDataSoLuong);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getListSoLuong();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncePageSize = useCustomDebounce(pageSize);
  const debounceSearch = useCustomDebounce(filterConditions);
  const handleSearch = useCallback(
    (result) => {
      setResult(result);
      getDanhSachDiemDo(
        debounceSearch,
        setLoading,
        setDataDanhSach,
        setIndexPage,
        pageSize
      );
    },
    [debounceSearch, pageSize]
  );
  useEffect(() => {
    if (debounceSearch === "") {
      handleSearch();
    } else {
      getDanhSachDiemDo(debounceSearch, setLoading, setDataDanhSach);
    }
  }, [debounceSearch, handleSearch]);
  useEffect(() => {
    getDanhSachDiemDo(
      debounceSearch,
      setLoading,
      setDataDanhSach,
      setIndexPage,
      pageSize
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);

  const dispatch = useDispatch();
  const getColumn = ({ key }) => {
    return [
      {
        title: "",
        dataIndex: "",
        width: 60,
        fixed: "left",
        render: (row) => (
          <div style={{ textAlign: "center" }}>
            {key === "1" && (
              <Dropdown
                overlay={
                  <div
                    className="list-menu-function"
                    style={{
                      boxShadow:
                        " 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
                    }}
                  >
                    <ListChucNang
                      detail={row}
                      result={result}
                      getDanhSachDiemDo={getDanhSachDiemDo}
                      setDataDanhSach={setDataDanhSach}
                      setLoadingDanhSach={setLoading}
                      setFilterConditions={setFilterConditions}
                      filterConditions={filterConditions}
                      isSuaPhuongThuc={row.isSuaPhuongThuc}
                      setIndexPage={setIndexPage}
                      indexPage={indexPage}
                    ></ListChucNang>
                  </div>
                }
                trigger={["click"]}
              >
                <Button type="primary" icon={<MenuOutlined />}></Button>
              </Dropdown>
            )}
          </div>
        ),
      },
      {
        title: "Thông tin điểm đo ",
        dataIndex: "thongTinDiemDo",
        key: "thongTinDiemDo",
        width: 180,
        render: (text, record, index) => {
          const rowKeys = Object.keys(record);
          if (rowKeys.find((e) => e === "thongTinDiemDo") !== undefined) {
            return (
              <div key={index}>
                <EllipsisTooltip line={"1"} title={text}>
                  {text}
                </EllipsisTooltip>
              </div>
            );
          } else {
            return <div>{text}</div>;
          }
        },
      },
      {
        title: "Mã điểm đo",
        dataIndex: "maDiemDo",
        key: "maDiemDo",
        width: 120,
      },
      {
        title: "Tính chất",
        dataIndex: "tinhChat",
        key: "tinhChat",
        width: 180,
        render: (text, record, index) => {
          const rowKeys = Object.keys(record);
          if (rowKeys.find((e) => e === "tinhChat") !== undefined) {
            return (
              <div key={index}>
                <EllipsisTooltip line={"1"} title={text}>
                  {text}
                </EllipsisTooltip>
              </div>
            );
          } else {
            return <div>{text}</div>;
          }
        },
      },

      {
        title: "Mã công tơ",
        dataIndex: "maCongTo",
        key: "maCongTo",
        width: 150,
        render: (text, record, index) => {
          const rowKeys = Object.keys(record);
          if (rowKeys.find((e) => e === "maCongTo") !== undefined) {
            return (
              <div key={index}>
                <EllipsisTooltip line={"1"} title={text}>
                  {text}
                </EllipsisTooltip>
              </div>
            );
          } else {
            return <div>{text}</div>;
          }
        },
      },
      {
        title: "Ngày treo tháo",
        dataIndex: "ngayTreoThao",
        width: 150,
        key: "ngayTreoThao",
      },
      {
        title: "Giao nhận",
        dataIndex: "giaoNhan",
        width: 250,
        key: "giaoNhan",
      },
      {
        title: "Cấp điện áp",
        dataIndex: "capDienAp",
        key: "capDienAp",
        width: 120,
      },
      {
        title: "Trạm",
        dataIndex: "tram",
        key: "tram",
        width: 180,
        render: (text, record, index) => {
          const rowKeys = Object.keys(record);
          if (rowKeys.find((e) => e === "tram") !== undefined) {
            return (
              <div key={index}>
                <EllipsisTooltip line={"1"} title={text}>
                  {text}
                </EllipsisTooltip>
              </div>
            );
          } else {
            return <div>{text}</div>;
          }
        },
      },
      {
        title: "Đơn vị quản lý",
        dataIndex: "donViQuanLy",
        width: 250,
        key: "donViQuanLy",
      },
    ];
  };

  const actionKhaiBaoDiemDo = (
    <Button
      type="primary"
      danger
      htmlType="submit"
      size="default"
      className="mr-24px"
      onClick={() =>
        dispatch(
          openModal({
            title: "Khai báo điểm đo",
            content: (
              <ModalKhaiBaoDiemDo
                closeModal={closeModal}
                loading={loading}
                setDataDanhSach={setDataDanhSach}
                getDanhSachDiemDo={getDanhSachDiemDo}
                setLoadingDanhSach={setLoading}
              />
            ),
            size: "small",
          })
        )
      }
    >
      Khai báo điểm đo
    </Button>
  );
  return (
    <Fragment>
      <Container>
        <Row gutter={24}>
          <Col span={24}>
            <Tabs
              className="tabs-container"
              tabBarExtraContent={actionKhaiBaoDiemDo}
              defaultActiveKey="1"
            >
              <TabPane
                className="tabspan-no-margin"
                tab={`Thuộc đơn vị quản lý ${
                  dataSoLuong?.thuocDonViQuanLy || 0
                }`}
                key="1"
              >
                <FillterCapNhat
                  handleSearch={handleSearch}
                  result={result}
                  setFilterConditions={setFilterConditions}
                  dataSoLuong={dataSoLuong}
                  dataDanhSach={dataDanhSach}
                  filterConditions={filterConditions}
                />

                <LoadingComponent loading={loading}>
                  <div
                    className="loading-scrollbar"
                    style={{ paddingTop: 10, width: "100%" }}
                  >
                    <Scrollbars
                      style={{ height: "70vh" }}
                      autoHide
                      onScrollFrame={(e) => {
                        if (
                          e.top * 100 > 99 &&
                          pageSize < indexPage.totalCount
                        ) {
                          setPageSize(debouncePageSize + 20);
                        }
                      }}
                    >
                      <div className="f" style={{ width: 1800 }}>
                        <TableComponent
                          columns={getColumn({ key: "1" })}
                          dataSource={dataDanhSach}
                          totalCount={indexPage}
                          rowKey="id"
                          scroll={{ x: 600 }}
                          pagination={false}
                          bordered
                          sticky={true}
                        />
                      </div>
                    </Scrollbars>
                  </div>
                </LoadingComponent>
              </TabPane>
              <TabPane
                className="tabspan-no-margin"
                tab={`Đơn vị quản lý khác ${
                  dataSoLuong?.donViQuangLyKhac || 0
                }`}
                key="2"
              >
                <TableDiemDoTab2
                  columns={getColumn({ key: "2" })}
                  loading={loading}
                  dataSoLuong={dataSoLuong}
                  dataDanhSach={dataDanhSach}
                  dataSource={dataDVK}
                  scroll={{ x: 600 }}
                  bordered
                  pagination={false}
                />
              </TabPane>
              <TabPane
                className="tabspan-no-margin"
                tab={`Điểm đo ngưng sử dụng ${
                  dataSoLuong?.diemDoNgungSuDung || 0
                }`}
                key="3"
              >
                <TableDiemDoTab3
                  columns={getColumn({ key: "3" })}
                  dataSoLuong={dataSoLuong}
                  dataDanhSach={dataDanhSach}
                  rowKey="id"
                  scroll={{ x: 600 }}
                  loading={loading}
                  pagination={false}
                  bordered
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
const Container = styled.div`
  padding: 10px 20px 10px 20px;
  .loading-scrollbar > div {
    height: 75vh !important;
  }
`;
