import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";

// data hồ sơ

export const handleDataHoSo = (
  hoSoId,
  setLoading,
  setDataDetail,
  setDataAnhHoSo,
  setDataNhatKiHoSo,
  setDataTienTrinh,
  setDataTroNgai,
  setDataTraLai,
  setNguoiThan,
  setThongTinCongTo
) => {
  authGetData({
    url: Endpoint.DETAIL_HO_SO + "?HoSoId=" + hoSoId + "&IsWeb=true",
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDetail(res.data[0].thongTinChiTiet);
        setDataAnhHoSo(res.data[0].anhHoSo);
        setDataNhatKiHoSo(res.data[0].nhatKyHoSo);
        setDataTienTrinh(res.data[0].tienTrinhDichVuDien);
        setDataTroNgai(res.data[0].troNgaiHoSos);
        setDataTraLai(res.data[0].traLaiHoSos);
        setNguoiThan(res.data[0].nguoiThans);
        if (res.data[0].yeuCauTreoThao !== null) {
          setThongTinCongTo(res.data[0].yeuCauTreoThao);
        }
      }
    },
  });
};

export const handleListLoaiGT = (
  hoSoId,
  setLoading,
  setDataLoaiGT,
  setFirstLoaiGT
) => {
  authGetData({
    url: Endpoint.LIST_LOAI_GIAY_TO + "?hoSoId=" + hoSoId + "&pageSize=-1",
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataLoaiGT(res.data);
        setFirstLoaiGT(res.data[0].id);
      } else setDataLoaiGT([]);
    },
  });
};

export const handleListImage = (
  detailHoSo,
  setLoading,
  setDataImages,
  setListId
) => {
  const hoSoId = detailHoSo.id ? detailHoSo.id : detailHoSo.hoSoId;
  authGetData({
    url: Endpoint.LIST_ANH_HIEN_TRUONG + "?hoSoId=" + hoSoId,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null && res.data.length > 0) {
        const arr = [];
        const arrId = [];
        res.data.forEach((item, index) => {
          arr.push(res.data[index].images);
          arrId.push({
            loaiGiayToId: res.data[index].loaiGiayToId,
            tenLoaiGiayTo: res.data[index].tenLoaiGiayTo,
          });
        });
        setDataImages(arr);
        setListId(arrId);
      }
    },
  });
};
