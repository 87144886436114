import { METHOD_POST, METHOD_PUT, STATUSCODE_200, STATUSCODE_400 } from "@utils/constants";
import { authGetMultiplesRequest, authPostData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { getErrorMessage } from "@utils/function";

export const handleMultiNhanVien = (
  setLoading,
  setDataTreoThao,
  setDataNiemPhong,
  detail
) => {
  const endpoints = [
    `${Endpoint.NHAN_VIEN_TREO_THAO_VA_NIEM_PHONG}?HoSoId=${detail.id}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDataTreoThao(res[0].data.data.nhanVienTreoThao);
      setDataNiemPhong(res[0].data.data.nhanVienNiemPhong);
    },
  });
};

export const handleSubmitXacNhan = (
  setLoading,
  detail,
  setFetchDataTable,
  form,
  setVisibleXacNhanNghiemThu
  // dataTreoThao,
  // dataNiemPhong
) => {
  const data = {
    hoSoId: detail.id,
    // nhanVienTreoThaoId: dataTreoThao?.id,
    // nhanVienNiemPhongId: dataNiemPhong?.id,
  };
  authPostData({
    url: Endpoint.XAC_NHAN_NGHIEM_THU_MOI,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (
        res.statusCode === STATUSCODE_200 ||
        res.statusCode === STATUSCODE_400
      ) {
        setFetchDataTable(true);
        setVisibleXacNhanNghiemThu(false);
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleXemLaiPhuongAn = (
  detail,
  setDataPAXemLai,
  setVisibleXemLaiPhuongAnCapDien,
  visibleXemLaiPhuongAnCapDien
) => {
  const endpoints = [
    `${Endpoint.KS_GET_PHUONG_AN_CAP_DIEN}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    `${Endpoint.KS_TONG_CONG_SUAT}?HoSoId=${detail?.hoSoId ?? detail?.id}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading: () => false,
    onSuccess: (res) => {
      if (res[0]?.data !== null && res[1]?.data !== null) {
        setDataPAXemLai({ ...res[0].data.data, ...res[1].data.data });
        setVisibleXemLaiPhuongAnCapDien(!visibleXemLaiPhuongAnCapDien);
      }
    },
  });
};

export const handleReturnHoSo = (
  values,
  detail,
  setLoading,
  setFetchDataTable,
  closeModal,
  form
) => {
  const data = {
    ...values,
    hoSoId: detail.id,
  };
  authPostData({
    url: Endpoint.TRA_LAI_HO_SO,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchDataTable(true);
        closeModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};
