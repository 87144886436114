import PhieuXuatVT from "@modules/treo-thao/common-ver1/phieu-vat-tu/index";
import { treo_thao } from "@permissions/b_treothao";
import { useState } from "react";
import { Endpoint } from "@utils/endpoint";
import {
  ENUM_PHIEU_VAT_TU,
  LOAI_PHIEU_TREOTHAO,
  TYPE_PHIEUTRA_CHUCNANG,
} from "@utils/constants";
import { Button } from "antd";
import { handleXacNhanKyDuyet } from "./services";
import LoadingComponent from "@components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "@state/system-config/reducer";
import { authSelector } from "@state/auth";
import DuyetPhieuXuat from "../../common-ver1/thao-tac-phieu-xuat/duyet-phieu";
import DuyetPhieuTra from "../../common-ver1/thao-tac-phieu-tra/duyet-phieu";
export default function Index() {
  const dispatch = useDispatch();
  const [fetchList, setFetchList] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [arrSelectedRows, setArrSelectedRows] = useState([]);
  const { user } = useSelector(authSelector);

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setArrSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  const renderButtonXacNhan = () => {
    <Button
      size="small"
      type="primary"
      className="mr-5px"
      id="create-phieu"
      onClick={() => handleXacNhan()}
    >
      Tạo phiếu
    </Button>;
  };

  const [loading, setLoading] = useState(false);
  const handleXacNhan = () => {
    const url = Endpoint.PVT_XAC_NHAN_XUAT_TRA_VT;
    const vatTuXuats = [];
    arrSelectedRows.map((item) => {
      return vatTuXuats.push({
        chungLoaiId: item.chungLoaiId,
        chungLoai: item.chungLoai,
        soLuongYeuCau: item.soLuongYeuCau,
        soLuongCoTheXuat: parseFloat(item.soLuongCoTheXuat),
      });
    });

    const data = {
      phieuVatTuId: null,
      vatTuXuats: vatTuXuats,
    };
    handleXacNhanKyDuyet(url, data, setLoading, setFetchList);
  };

  const renderPopupDuyetPhieu = (row, permissionCodeKySo) => {
    if (row.loaiPhieuNumber === LOAI_PHIEU_TREOTHAO.PHIEUXUAT) {
      dispatch(
        openModal({
          content: (
            <DuyetPhieuXuat
              selectRowKeys={row.id}
              typeLoaiPhieu={row.loaiPhieuNumber}
              nguoiTao={user.name}
              setFetchList={setFetchList}
              permissionCodeKySo={permissionCodeKySo}
              maTrangThai={row.maTrangThai}
            />
          ),
          size: "large",
          title: "Duyệt phiếu xuất vật tư",
        })
      );
    } else {
      dispatch(
        openModal({
          content: (
            <DuyetPhieuTra
              selectRowKeys={row.id}
              typeLoaiPhieu={row.loaiPhieuNumber}
              type={TYPE_PHIEUTRA_CHUCNANG.TRALAI_DUYET}
              nguoiTao={user.name}
              setFetchList={setFetchList}
              maTrangThai={row.maTrangThai}
            />
          ),
          size: "large",
          title: "Duyệt phiếu trả vật tư",
        })
      );
    }
  };

  return (
    <LoadingComponent loading={loading}>
      <PhieuXuatVT
        url={Endpoint.LIST_PHIEU_VAT_TU}
        funcId={ENUM_PHIEU_VAT_TU.PVTDUYETPHIEUVT}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        permissionCodeDuyet={treo_thao.b61tl}
        permissionCodeXemPhieu={treo_thao.b61v}
        urlRemove={Endpoint.REMOVE_PHIEU_TRA_VAT_TU}
        permissionCodeXacNhan={treo_thao.b61xn}
        permissionCodeKySo={treo_thao.b61k}
        urlKySo={Endpoint.XAC_THUC_KY_SO_PHIEUVT}
        urlGetHash={Endpoint.KY_SO_GET_HASH_PHIEUVT}
        urlXN={Endpoint.PVT_XAC_NHAN}
        isRowSelect={false}
        rowSelection={rowSelection}
        selectedRowKeys={selectedRowKeys}
        arrSelectedRows={arrSelectedRows}
        renderButtonXacNhan={renderButtonXacNhan}
        renderPopupDuyetPhieu={renderPopupDuyetPhieu}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    </LoadingComponent>
  );
}
