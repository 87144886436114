import styled from "styled-components";
export const Style = styled.div`
  padding: 20px;
  background-color: rgb(229, 229, 229);
  .bieu-do-thong-ke-thanh-toan {
    border-radius: 4px;
    background-color: #fff;
    padding: 10px;
    h2 {
      font-weight: 600;
    }
    .ant-row {
      .ant-col {
        display: flex;
        align-items: center;
        span:last-child {
          margin-left: 20px;
          font-size: 24px;
          font-weight: 600;
        }
      }
    }
  }
  .bieu-do-theo-don-vi-quan-ly {
    background-color: #fff;
    padding: 10px;
    border-radius: 4px;
    // height: 100%;
    h2 {
      font-weight: 600;
    }
    .bieu-do-theo-don-vi-quan-ly-bar-container {
      margin-bottom: 40px;
      .bieu-do-theo-don-vi-quan-ly-bar-container-header {
        display: flex;
        justify-content: space-between;
      }
      .bieu-do-theo-don-vi-quan-ly-bar {
        height: 10px;
        background-color: rgba(1, 152, 117, 0.3);
        border-radius: 10px;
        div {
          height: 10px;
          background-color: rgba(1, 152, 117);
          border-radius: 10px;
          width: 50%;
        }
      }
    }
  }
`;
