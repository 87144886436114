import {
  buildQueryString,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
  alertMessage,
} from "@utils/function";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { handleListBaoCao } from "./services";
import BaoCaoHanCongToBiMat from "./table";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import { Fragment } from "react";
import { Endpoint } from "@utils/endpoint";
import { downloadFile } from "@utils/request";
import moment from "moment";

export default function Index() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { user } = useSelector(authSelector);
  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);

  //filter
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    donViId: user.unitId,
    tuNgay: searchParams.get("tuNgay")
      ? searchParams.get("tuNgay")
      : moment().startOf(MONTH_STRING).format(FORMAT_ENGLISH),
    denNgay: searchParams.get("denNgay")
      ? searchParams.get("denNgay")
      : moment().format(FORMAT_ENGLISH),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //change pagination
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  };

  //fetch báo cáo
  const getListBaoCao = useCallback(() => {
    handleListBaoCao(filterConditions, setLoading, setData, setTotalCount);
  }, [filterConditions]);
  const [filterExcel, setFilterExcel] = useState(false);

  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type]);

  // //search
  // const handleSearch = useCallback(
  //   (values) => {
  //     setFilterConditions(() => ({
  //       ...values,
  //     }));
  //     // setFilter(() => ({
  //     //   ...values,
  //     // }));
  //     handleAPI();
  //   },
  //   [handleAPI]
  // );

  const handleSearch = useCallback(
    (values) => {
      if (values.tuNgay <= values.denNgay) {
        setFilterConditions((oldState) => ({
          ...oldState,
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
          ...values,
        }));
        handleAPI();
      } else {
        alertMessage("error", "Thông báo", "Khoảng ngày báo cáo không hợp lệ");
      }
    },
    [handleAPI]
  );

  useEffect(() => {
    if (filterExcel) {
      const endpoint = `${Endpoint.EXPORT_EXCEL_CT_BI_MAT}?${buildQueryString(
        parseParams(filterConditions)
      )}`;
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel]);

  //clear search
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
    });
    // setFilter({
    //   pageIndex: DEFAULT_PAGEINDEX,
    //   pageSize: DEFAULT_PAGESIZE,
    //   donViId: user.unitId,
    // });
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  }, [user.unitId]);

  useEffect(() => {
    if (type === ENUM_BUTTON_TYPE.TIM_KIEM) getListBaoCao();
  }, [getListBaoCao, type]);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "5%",
    },
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
      sorter: true,
    },
    {
      title: "Mã công tơ",
      dataIndex: "maCongTo",
      key: "maCongTo",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Số pha",
      dataIndex: "soPha",
      key: "soPha",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "6%",
      sorter: true,
    },
    {
      title: "Chủng loại",
      dataIndex: "chungLoai",
      key: "chungLoai",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "7%",
      sorter: true,
    },
    {
      title: "Ngày treo tháo",
      dataIndex: "ngayTreoThao",
      key: "ngayTreoThao",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Loại treo tháo",
      dataIndex: "tenLoaiTreoThao",
      key: "tenLoaiTreoThao",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Đội",
      dataIndex: "tenDoi",
      key: "maTram",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "11%",
      sorter: true,
    },
    {
      title: "Trạm",
      dataIndex: "maTram",
      key: "maTram",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "7%",
      sorter: true,
    },
    {
      title: "Mã sổ GCS",
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "7%",
      sorter: true,
    },
    {
      title: "Tên KH & SDT",
      dataIndex: "tenKHVaSDT",
      key: "tenKHVaSDT",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
      sorter: true,
    },

    {
      title: "Địa chỉ sử dụng điện",
      dataIndex: "diaChiDungDien",
      key: "diaChiDungDien",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
      sorter: true,
    },
  ];

  return (
    <Fragment>
      <BaoCaoHanCongToBiMat
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        loading={loading}
        columns={columns}
        totalCount={totalCount}
        data={data}
        onChangePagination={onChangePagination}
        setType={setType}
      />
    </Fragment>
  );
}
