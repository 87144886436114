import { SearchOutlined } from "@ant-design/icons";
import FormComponent from "@components/Form";
import Thanglamviec from "@modules/giao-nhan-dien-nang/common/pdf-viewer/Thanglamviec";
import { Col, Form, Input, Row } from "antd";
import { useEffect } from "react";

export function ListFilter(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    tooltip,
    setFilterConditions,
  } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const onChange = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      search: e.target.value,
    }));
  };
  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      autoComplete="off"
    >
      <div style={{ paddingLeft: 20 }}>
        <Row justify="space-between" align="middle">
          <Col span={8}>
            <Thanglamviec />
          </Col>
          <Col span={4}></Col>

          <Col span={8}>
            <Form.Item name="search">
              <Input
                onChange={onChange}
                prefix={<SearchOutlined />}
                placeholder="Tìm kiếm theo mã trạm và thông tin mô tả"
              />
            </Form.Item>
          </Col>

          <Col span={24} md={6} style={{ textAlign: "right" }}></Col>
        </Row>
      </div>
    </FormComponent>
  );
}
