import React from "react";
import { Button, Modal, Tooltip } from "antd";
import {
  ContainerButton,
  ContainerH1,
  ContainerInfo,
  ContainerInfoBody,
  ContainerInfoCol,
} from "./css-style";
import { InfoCircleOutlined } from "@ant-design/icons";

export default function Index(props) {
  const { visible, closeModal, data } = props;
  return (
    <Modal
      title={"Thông báo đồng bộ yêu cầu từ CMIS"}
      visible={visible}
      onCancel={closeModal}
      footer={null}
    >
      <ContainerInfo gutter={24} align="middle" justify="center">
        <ContainerInfoCol span={24} md={24}>
          <ContainerH1>Thông tin đồng bộ yêu cầu từ CMIS</ContainerH1>
          <ContainerInfoBody>
            <h3>
              Tổng số yêu cầu tiếp nhận:
              <span className="total"> {data?.soYeuCauTiepNhan ?? 0}</span>
              <span style={{ marginLeft: "12px" }}>
                <Tooltip title="Các điểm đo có cùng ngày treo tháo đã tồn tại ở Web hoặc không thuộc loại điểm đo KH sẽ không được đồng bộ về !">
                  <InfoCircleOutlined style={{ color: "#279bef" }} />
                </Tooltip>
              </span>
            </h3>
            <h3>
              Số yêu cầu thành công:
              <span className="total-success"> {data?.thanhCong ?? 0}</span>
            </h3>
            <h3>
              Số yêu cầu thất bại:
              <span className="total-fail"> {data?.thatBai ?? 0}</span>
            </h3>
          </ContainerInfoBody>
        </ContainerInfoCol>
      </ContainerInfo>
      <ContainerButton>
        <Button key="back" type="default" onClick={() => closeModal()}>
          Đóng lại
        </Button>
      </ContainerButton>
    </Modal>
  );
}
