import { METHOD_POST } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authPostData } from "@utils/request";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Spin,
} from "antd";
import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";

function ModalThongTinDoiTuong(props) {
  const [loading, setLoading] = useState(false);
  const [checkedValues, setCheckedValues] = useState(false);
  const [form] = Form.useForm();
  const { visible, onCancel, getData, setVisible } = props;
  const onSubmit = (values) => {
    const doiTuong = checkedValues.reduce((a, v) => {
      return {
        ...a,
        [v]: true,
      };
    }, {});
    authPostData({
      url: `${Endpoint.CSKH_DOITUONG}`,
      method: METHOD_POST,
      payload: {
        NoiDung: values?.ten?.trim(),
        stt: parseInt(values?.stt?.toString().trim()),
        phanloai: values?.phanloai?.trim(),
        ...doiTuong,
      },
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          getData();
          setVisible(false);
          form.resetFields();
        }
      },
    });
  };
  const onChange = (e) => {
    setCheckedValues(e);
  };

  useEffect(() => {
    if (visible === false) {
      form.resetFields();
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title="Thêm mới thông tin đối tượng"
      onCancel={onCancel}
      width={"70%"}
      footer={null}
      centered
    >
      <Spin spinning={loading}>
        <Form
          onFinish={onSubmit}
          form={form}
          labelCol={{ span: 6 }}
          colon={false}
          wrapperCol={{ span: 18 }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="ten"
                label="Nội dung"
                labelAlign="left"
                rules={[
                  { required: true, message: "Nội dung không được để trống" },
                ]}
              >
                <Input formKey="ten" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="phanloai"
                label="Phân loại"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Phân loại không được để trống",
                  },
                ]}
              >
                <Input formKey="phanloai" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="stt"
                label="Thứ tự hiển thị"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Thứ tự hiển thị không được để trống",
                  },
                ]}
              >
                <InputNumber formKey="stt" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} offset={6}>
              <Form.Item name="doituong">
                <Checkbox.Group onChange={onChange}>
                  <div style={{ display: "inline-block", width: "100%" }}>
                    <Checkbox value="isDoiTuongNhanTinWeb">
                      Đối tượng nhắn tin ở web
                    </Checkbox>
                  </div>
                  <div style={{ display: "inline-block", width: "100%" }}>
                    <Checkbox value="isBaoCaoDoiSoatSMSLan1">
                      Báo cáo đối soát SMS - Tiền điện lần 1
                    </Checkbox>
                  </div>
                  <div style={{ display: "inline-block", width: "100%" }}>
                    <Checkbox value="isBaoCaoDoiSoatEmail">
                      Báo cáo đối soát Email
                    </Checkbox>
                  </div>
                  <div style={{ display: "inline-block", width: "100%" }}>
                    <Checkbox value="isBaoCaoDoiSoatAppCSKH">
                      Báo cáo đối soát App CSKH
                    </Checkbox>
                  </div>
                  <div style={{ display: "inline-block", width: "100%" }}>
                    <Checkbox value="isDoiTuongCuPhap">
                      Đối tượng cú pháp
                    </Checkbox>
                  </div>
                  <div style={{ display: "inline-block", width: "100%" }}>
                    <Checkbox value="isBaoCaoDoiSoatSMSDichVuKhac">
                      Báo cáo đối soát SMS - Dịch vụ khác
                    </Checkbox>
                  </div>

                  <div style={{ display: "inline-block", width: "100%" }}>
                    <Checkbox value="isBaoCaoDoiSoatZalo">
                      Báo cáo đối soát Zalo
                    </Checkbox>
                  </div>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Row span={20}>
              <Item>
                <Button style={{ width: 200 }} onClick={onCancel}>
                  Hủy
                </Button>
                <Button
                  style={{ width: 200, marginLeft: 40 }}
                  type="primary"
                  htmlType="submit"
                >
                  Lưu
                </Button>
              </Item>
            </Row>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}
export default memo(ModalThongTinDoiTuong);

const Item = styled.div`
  width: 500px;
  margin-left: 33%;
`;
