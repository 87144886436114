import React, { useEffect, useState, useCallback } from "react";
import { authGetData, authPostFileData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { Row, Col, Form, Radio, Checkbox, Select, Spin, Switch, Modal, Input, Button, DatePicker } from "antd";
import FormComponent from "@components/Form";
import styled from "styled-components";
import InputComponent from "@components/Input";
import Selection from "@components/Select";
import { getErrorForm } from "@utils/function";
import { optionPageSize, STATUSCODE_200 } from "@utils/constants";
import ButtonComponent from "@components/Button";
import ImageUpload from "@components/ImageUpload";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import { getUserProfile } from "@state/auth/reducer";
import { FORMAT_DATE } from "@utils/constants";
import moment from "moment";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";

const { Option } = Select;
export default function EditUserForm({ userInfo, getUserList, setActiveKey, setUserInfo }) {
  const [form] = Form.useForm();
  const [avatar, setAvatar] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [ssoChecked, setSSOChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resultCheckSSO, setResultCheckSSO] = useState(false);
  const { user } = useSelector(authSelector);
  const [ngayCap, setNgayCap] = useState(
    userInfo && userInfo.ngayCap
      ?  moment(userInfo.ngayCap, FORMAT_DATE).format('YYYY-MM-DD')
      : null
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (userInfo.avatar) {
      setAvatar(userInfo.avatar);
    }

    form.setFieldsValue(userInfo);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const handleCheckSSO = useCallback(
    (event) => {
      const { checked } = event.target;
      setSSOChecked(checked);
      form.setFieldsValue({
        password: undefined,
        confirmPassword: undefined,
      });
    },
    [form]
  );

  const checkSSO = () => {
    const userName = form.getFieldValue("userName");
    if (userName) {
      authGetData({
        url: `${Endpoint.CHECK_SSO}?userName=${userName}`,
        setLoading,
        onSuccess: (res) => {
          if (res.data) {
            form.setFieldsValue(res.data);
            setResultCheckSSO(true);
          }
        },
      });
    }
  };

  const handleChangeNgayCap = (date) => {
    let convert = "";
    if (date === null) convert = null;
    else convert = moment(date).format('YYYY-MM-DD');
    setNgayCap(convert);
  };

  const onFinish = (values) => {
    authPostFileData({
      url: `${Endpoint.USER}`,
      method: "PUT",
      setLoading,
      payload: {
        ...values,
        id: userInfo.id,
        file: imageFile,
        isRemoveAvatar: !imageFile ? true : false,
        status: values.isActived ? 1 : 2,
        sso: !!userInfo.sso || !!resultCheckSSO,
        ngayCap: ngayCap,
      },
      onSuccess: (res) => {
        getUserList();
        if (res.statusCode === STATUSCODE_200 && userInfo) {
          if (res.data.isUpdateTodoi) {
            setUserInfo({
              ...userInfo,
              ...values,
            });
            Modal.confirm({
              centered: true,
              title: "Xác nhận",
              content:
                "Thông tin Tổ/Đội của người dùng thay đổi, bạn hãy kiểm tra lại thông tin phân quyền dữ liệu của người dùng",
              onOk() {
                setActiveKey("3");
              },
              onCancel() { },
              okText: "Đồng ý",
              cancelText: "Hủy",
            });
            if (userInfo.id === user.id) {
              dispatch(getUserProfile());
            }
          }else{
            setUserInfo({
              ...userInfo,
              sso: !!userInfo.sso || !!resultCheckSSO,
            });
          }
        } else {
          getErrorForm(res, form);
        }
      },
    });
  };

  const handleDisableCapNhat = () => {
    if ((ssoChecked && !resultCheckSSO) || loading) {
      return true;
    }
    return false;
  };

  return (
    <Spin spinning={loading}>
      <FormComponent
        layout="vertical"
        onFinish={onFinish}
        form={form}
        autoComplete="off"
        initialValues={{ pageSize: userInfo.pageSize ? userInfo.pageSize : 20}}
      >
        <Row>
          <Col span={3}>
            <Form.Item name="avatar" label="Ảnh đại diện">
              <ImageUpload setImageFile={setImageFile} currentImage={avatar} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="userName" label="Tên đăng nhập" required>
              <InputComponent type="text" size="default" form={form} formKey="userName" disabled={userInfo.sso} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <SSOContainer>
              <div className="sso-checkbox">
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.userName !== currentValues.userName}
                >
                  {({ getFieldValue }) => {
                    return (
                      <Checkbox
                        onChange={handleCheckSSO}
                        checked={userInfo.sso || ssoChecked}
                        disabled={!getFieldValue("userName") || resultCheckSSO || userInfo.sso}
                      />
                    );
                  }}
                </Form.Item>

                <span>SSO</span>
              </div>

              <Button type="danger" size="small" disabled={userInfo.sso || resultCheckSSO || !ssoChecked} onClick={checkSSO}>
                Kiểm tra
              </Button>
            </SSOContainer>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="unitId" label="Đơn vị" required>
              <Selection url={Endpoint.GET_UNIT_LIST} formKey="unitId" form={form} disabled={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.unitId !== currentValues.unitId}>
              {({ getFieldValue }) => {
                if (getFieldValue("unitId")) {
                  return (
                    <Form.Item name="teamId" label="Tổ/Đội">
                      <Selection
                        url={`${Endpoint.GET_TEAM_LIST}?donviId=${getFieldValue("unitId")}`}
                        formKey="teamId"
                        form={form}
                        disabled={!getFieldValue("unitId")}
                      />
                    </Form.Item>
                  );
                }
                return (
                  <Form.Item name="teamId" label="Tổ/Đội">
                    <Select disabled />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="name" label="Tên đầy đủ" required>
              <InputComponent type="text" size="default" form={form} formKey="name" disabled={userInfo.sso || ssoChecked} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="cmisCode" label="Mã nhân viên (CMIS)">
              <Input placeholder="Mã nhân viên" formKey="cmisCode" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="positionId" label="Chức vụ" required>
              <Selection url={Endpoint.GET_POSITION_LIST} formKey="positionId" form={form} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="gender" label="Giới tính">
              <Radio.Group disabled={userInfo.sso || ssoChecked}>
                <Radio value={1}>Nam</Radio>
                <Radio value={2}>Nữ</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item name="jobPosition" label="Vị trí công việc">
              <Selection url={Endpoint.GET_VI_TRI_CONG_VIEC} formKey="jobPosition" form={form} mode="multiple" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="email" label="Email">
              <InputComponent type="text" size="default" form={form} formKey="email" disabled={userInfo.sso || ssoChecked} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="phoneNumber" label="Điện thoại">
              <InputComponent
                type="text"
                size="default"
                form={form}
                formKey="phoneNumber"
                disabled={userInfo.sso || ssoChecked}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="giayToTuyThan" label="Số CMND/CCCD/Hộ chiếu">
              <InputComponent type="text" size="default" form={form} formKey="giayToTuyThan" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Ngày cấp"
              name=""
            >
              <DatePicker
                locale={locale}
                defaultValue={userInfo.ngayCap ? moment(userInfo.ngayCap, FORMAT_DATE) : null}
                format={FORMAT_DATE}
                onChange={(value) => handleChangeNgayCap(value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="noiCap" label="Nơi cấp">
              <InputComponent type="text" size="default" form={form} formKey="noiCap" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="pageSize" label="Cấu hình hiển thị số trang của bảng">
              <Select allowClear={false}>
                {optionPageSize.map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="isActived" label="Trạng thái" valuePropName="checked">
              <Switch checkedChildren="Hoạt động" unCheckedChildren="Khóa" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} justify="center">
          <Col span={4}>
            <ButtonComponent type="primary" htmlType="submit" size="large" className="full" disabled={handleDisableCapNhat()}>
              Cập nhật
            </ButtonComponent>
          </Col>
        </Row>
      </FormComponent>
    </Spin>
  );
}

const SSOContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  .sso-checkbox {
    margin-right: 20px;
    > span {
      margin-left: 10px;
    }
  }
`;
