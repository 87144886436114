import TrangChu from "@modules/giao-nhan-dien-nang";
import BaoCaoChiTietDienNangTuDung from "@modules/giao-nhan-dien-nang/bao-cao/bao-cao-chi-tiet-dien-nang-tu-dung";
import BaoCaoSanLuongDauNguon3Gia from "@modules/giao-nhan-dien-nang/bao-cao/bao-cao-san-luong-dau-nguon-3-gia";
import BaoCaoChiTietSanLuong from "@modules/giao-nhan-dien-nang/bao-cao/bao-cao-chi-tiet-san-luong";
import BaoCaoDiemDoTreoThaoTrongThang from "@modules/giao-nhan-dien-nang/bao-cao/bao-cao-diem-do-treo-thang-trong-thang";
import BaoCaoSaiPhamSo110 from "@modules/giao-nhan-dien-nang/bao-cao/bao-cao-sai-so-tram-110";
import BienBanQuyetToanDNPhanKhang from "@modules/giao-nhan-dien-nang/bao-cao/bien-ban-quyet-toan-dien-nang-phan-khang";
import CTChiSoCongToDoDemDienNangGN from "@modules/giao-nhan-dien-nang/bao-cao/chi-tiet-chi-so-cong-to-do-dem-dien-nang-giao-nhan";
import ChiTietDienNangGiaoNhan from "@modules/giao-nhan-dien-nang/bao-cao/chi-tiet-dien-nang-giao-nhan";
import ChiTietDienNangTheoTram from "@modules/giao-nhan-dien-nang/bao-cao/chi-tiet-dien-nang-theo-tram";
import TongHopDienNangGiaoNhan from "@modules/giao-nhan-dien-nang/bao-cao/tong-hop-dien-nang-giao-nhan";
import PhongKinhDoanhCTDL from "@modules/giao-nhan-dien-nang/bang-ke/bang-ke-ctdt/phong-kinh-doanh";
import LanhDaoCTDL from "@modules/giao-nhan-dien-nang/bang-ke/bang-ke-ctdt/lanh-dao";
import BanPhongKinhDoanhTCT from "@modules/giao-nhan-dien-nang/bang-ke/bang-ke-tct/ban-kinh-doanh";
import LanhDaoTCT from "@modules/giao-nhan-dien-nang/bang-ke/bang-ke-tct/lanh-dao";
import NhanVienCTDL from "@modules/giao-nhan-dien-nang/bang-ke/bang-ke-ctdt/nhan-vien-tao";
import DanhMucDonViGiaoNhan from "@modules/giao-nhan-dien-nang/danh-muc/danh-muc-don-vi-giao-nhan/danh-muc-don-vi-giao-nhan";
import DanhMucTram from "@modules/giao-nhan-dien-nang/danh-muc/danh-muc-tram/danh-muc-tram";
import NhapChiSoDinhKy from "@modules/giao-nhan-dien-nang/quan-ly-chi-so";
import AnhXaTTDiemDo from "@modules/giao-nhan-dien-nang/quan-ly-diem-do/anh-xa-thong-tin-diem-do";
import CNThongTinDiemDo from "@modules/giao-nhan-dien-nang/quan-ly-diem-do/cap-nhat-thong-tin-diem-do/Index";
import PQDonViDiemDo from "@modules/giao-nhan-dien-nang/quan-ly-diem-do/phan-quyen-don-vi-diem-do/phan-quyen-don-vi-diem-do";
import TreoThaoThietBi from "@modules/giao-nhan-dien-nang/quan-ly-diem-do/treo-thao-thiet-bi";
import BienBanQuyetToanDNGiaoNhan from "@modules/giao-nhan-dien-nang/bao-cao/bien-ban-quyet-toan-dien-nang-giao-nhan";
import TinhSanLuong from "@modules/giao-nhan-dien-nang/tong-hop-so-lieu/tinh-san-luong";
import TongHopSanLuong from "@modules/giao-nhan-dien-nang/tong-hop-so-lieu/tong-hop-san-luong";
import DSDonViDaXacNhan from "@modules/giao-nhan-dien-nang/tong-hop-so-lieu/ds-don-vi-da-xac-nhan";
import DayDuLieuVeCMIS from "@modules/giao-nhan-dien-nang/tong-hop-so-lieu/day-du-lieu-ve-cmis";
import ChuyenThangLamViec from "@modules/giao-nhan-dien-nang/tong-hop-so-lieu/chuyen-thang-lam-viec";
import { giao_nhan_dien_nang } from "@permissions/c_gndn";
import { MODULE_DODEM } from "@utils/constants";
const mainPath = "/giao-nhan-dien-nang";
const routes = [
  {
    title: "",
    path: mainPath,
    privateRoute: true,
    component: TrangChu,
    subItems: [],
    permissionCode: MODULE_DODEM,
  },
  {
    title: "Danh mục",
    path: mainPath + "/danh-muc",
    privateRoute: true,
    permissionCode: giao_nhan_dien_nang.c3,
    subItems: [
      {
        subTitle: "Danh mục đơn vị giao nhận",
        subPath: "/danh-muc-don-vi-giao-nhan",
        subComponent: DanhMucDonViGiaoNhan,
        permissionCode: giao_nhan_dien_nang.c32,
      },
      {
        subTitle: "Danh mục trạm",
        subPath: "/danh-muc-tram",
        subComponent: DanhMucTram,
        permissionCode: giao_nhan_dien_nang.c31,
      },
    ],
  },
  {
    title: "Quản lý chỉ số",
    path: mainPath + "/quan-ly-chi-so",
    privateRoute: true,
    permissionCode: giao_nhan_dien_nang.c1,
    subItems: [
      {
        subTitle: "Nhập chỉ số định kỳ",
        subPath: "/nhap-chi-so-dinh-ky",
        subComponent: NhapChiSoDinhKy,
        permissionCode: giao_nhan_dien_nang.c11,
      },
    ],
  },
  {
    title: "Quản lý điểm đo",
    path: mainPath + "/quan-ly-diem-do",
    privateRoute: true,
    permissionCode: giao_nhan_dien_nang.c2,
    subItems: [
      {
        subTitle: "Cập nhật thông tin điểm đo",
        subPath: "/cap-nhat-thong-tin-diem-do",
        subComponent: CNThongTinDiemDo,
        permissionCode: giao_nhan_dien_nang.c21,
      },
      {
        subTitle: "Ánh xạ thông tin điểm đo",
        subPath: "/anh-xa-thong-tin-diem-do",
        subComponent: AnhXaTTDiemDo,
        permissionCode: giao_nhan_dien_nang.c22,
      },
      {
        subTitle: "Treo tháo thiết bị",
        subPath: "/treo-thao-thiet-bi",
        subComponent: TreoThaoThietBi,
        permissionCode: giao_nhan_dien_nang.c24,
      },
      {
        subTitle: "Phân quyền đơn vị điểm đo",
        subPath: "/phan-quyen-don-vi-diem-do",
        subComponent: PQDonViDiemDo,
        permissionCode: giao_nhan_dien_nang.c23,
      },
    ],
  },

  {
    title: "Bảng kê (CTDT)",
    path: mainPath + "/bang-ke-ctdl",
    privateRoute: true,
    permissionCode: giao_nhan_dien_nang.c4,
    subItems: [
      {
        subTitle: "Nhân viên kinh doanh",
        subPath: "/nhan-vien-kinh-doanh",
        subComponent: NhanVienCTDL,
        permissionCode: giao_nhan_dien_nang.c43,
      },
      {
        subTitle: "Phòng kinh doanh",
        subPath: "/phong-kinh-doanh",
        subComponent: PhongKinhDoanhCTDL,
        permissionCode: giao_nhan_dien_nang.c41,
      },
      {
        subTitle: "Lãnh đạo",
        subPath: "/lanh-dao",
        subComponent: LanhDaoCTDL,
        permissionCode: giao_nhan_dien_nang.c42,
      },
    ],
  },
  {
    title: "Bảng kê (TCT)",
    path: mainPath + "/bang-ke-tct",
    privateRoute: true,
    permissionCode: giao_nhan_dien_nang.c5,
    subItems: [
      {
        subTitle: "Ban kinh doanh",
        subPath: "/ban-kinh-doanh",
        subComponent: BanPhongKinhDoanhTCT,
        permissionCode: giao_nhan_dien_nang.c51,
      },
      {
        subTitle: "Lãnh đạo",
        subPath: "/lanh-dao",
        permissionCode: giao_nhan_dien_nang.c52,
        subComponent: LanhDaoTCT,
      },
    ],
  },
  {
    title: "Báo cáo",
    path: mainPath + "/bao-cao",
    privateRoute: true,
    permissionCode: giao_nhan_dien_nang.c6,
    subItems: [
      {
        subTitle: "Chi tiết điện năng giao nhận",
        subPath: "/chi-tiet-dien-nang-giao-nhan",
        subComponent: ChiTietDienNangGiaoNhan,
        permissionCode: giao_nhan_dien_nang.c61,
      },
      {
        subTitle: "Tổng hợp điện năng giao nhận ",
        subPath: "/tong-hop-dien-nang-giao-nhan",
        subComponent: TongHopDienNangGiaoNhan,
        permissionCode: giao_nhan_dien_nang.c62,
      },
      {
        subTitle: "Chi tiết chỉ số công tơ đo đếm điện năng giao nhận",
        subPath: "/chi-tiet-chi-so-cong-to-do-dem-dien-nang-giao-nhan",
        subComponent: CTChiSoCongToDoDemDienNangGN,
        permissionCode: giao_nhan_dien_nang.c63,
      },
      {
        subTitle: "Biên bản quyết toán điện năng phản kháng",
        subPath: "/bien-ban-quyet-toan-dien-nang-phan-khang",
        subComponent: BienBanQuyetToanDNPhanKhang,
        permissionCode: giao_nhan_dien_nang.c64,
      },
      {
        subTitle: "Báo cáo sai số trạm 110",
        subPath: "/bao-cao-sai-so-tram-110",
        subComponent: BaoCaoSaiPhamSo110,
        permissionCode: giao_nhan_dien_nang.c65,
      },
      {
        subTitle: "Chi tiết điện năng theo trạm",
        subPath: "/chi-tiet-dien-nang-theo-tram",
        subComponent: ChiTietDienNangTheoTram,
        permissionCode: giao_nhan_dien_nang.c66,
      },
      {
        subTitle: "Báo cáo chi tiêt sản lượng",
        subPath: "/bao-cao-chi-tiet-san-luong",
        subComponent: BaoCaoChiTietSanLuong,
        permissionCode: giao_nhan_dien_nang.c67,
      },
      {
        subTitle: "Báo cáo điểm đo treo tháo trong tháng",
        subPath: "/bao-cao-diem-do-treo-thao-trong-thang",
        subComponent: BaoCaoDiemDoTreoThaoTrongThang,
        permissionCode: giao_nhan_dien_nang.c68,
      },
      {
        subTitle: "Báo cáo sản lượng đầu nguồn 3 giá",
        subPath: "/bao-cao-san-luong-dau-nguon-3-gia",
        subComponent: BaoCaoSanLuongDauNguon3Gia,
        permissionCode: giao_nhan_dien_nang.c69,
      },

      {
        subTitle: "Báo cáo chi tiết điện năng tự dùng",
        subPath: "/bao-cao-chi-tiet-dien-nang-tu-dung",
        subComponent: BaoCaoChiTietDienNangTuDung,
        permissionCode: giao_nhan_dien_nang.c610,
      },
      {
        subTitle: "Biên bản quyết toán điện năng giao nhận",
        subPath: "/bien-ban-quyet-toan-dien-nang-giao-nhan",
        subComponent: BienBanQuyetToanDNGiaoNhan,
        permissionCode: giao_nhan_dien_nang.c611,
      },
    ],
  },
  {
    title: "Tổng hợp số liệu",
    path: mainPath + "/tong-hop-so-lieu",
    privateRoute: true,
    permissionCode: giao_nhan_dien_nang.c7,
    subItems: [
      {
        subTitle: "Tính sản lượng",
        subPath: "/tinh-san-luong",
        subComponent: TinhSanLuong,
        permissionCode: giao_nhan_dien_nang.c71,
      },
      {
        subTitle: "Tổng hợp sản lượng",
        subPath: "/tong-hop-san-luong",
        permissionCode: giao_nhan_dien_nang.c72,
        subComponent: TongHopSanLuong,
      },
      {
        subTitle: "Danh sách đơn vị đã xác nhận",
        subPath: "/ds-don-vi-da-xac-nhan",
        permissionCode: giao_nhan_dien_nang.c73,
        subComponent: DSDonViDaXacNhan,
      },
      {
        subTitle: "Đẩy dữ liệu về CMIS",
        subPath: "/day-du-lieu-ve-cmis",
        permissionCode: giao_nhan_dien_nang.c74,
        subComponent: DayDuLieuVeCMIS,
      },
      {
        subTitle: "Chuyển tháng làm việc",
        subPath: "/chuyen-thang-lam-viec",
        permissionCode: giao_nhan_dien_nang.c75,
        subComponent: ChuyenThangLamViec,
      },
    ],
  },
];
export default routes;
