/* eslint-disable no-unused-vars */
import { Table, Button, ConfigProvider } from "antd";
import vn from "antd/es/locale-provider/vi_VN";
// import { Scrollbars } from "react-custom-scrollbars";
import { TableWrapper } from "@modules/cap-dien/nhan-vien-khao-sat/css-styled";
import Filter from "@components/Filter/Filter";
import ListFilter from "./list-bo-loc";
import React, { Fragment } from "react";
import { Container } from "./css-styled";
import TableComponent from "@components/TableComponent";

function FormSuDung(props) {
  const {
    countTotals,
    dataTable,
    handleLayDuLieu,
    handleKiemTraLai,
    handleTaoBangKe,
    columns,
    filterConditions,
    user,
    loaiBangKe,
    form,
    nguoiThucHien,
    loading,
    rowSelection,
    rowKey,
  } = props;
  const scroll = {
    width: "100%",
    height: 340,
  };
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleLayDuLieu={handleLayDuLieu}
          filterConditions={filterConditions}
          user={user}
          handleKiemTraLai={handleKiemTraLai}
          handleTaoBangKe={handleTaoBangKe}
          loaiBangKe={loaiBangKe}
          form={form}
          nguoiThucHien={nguoiThucHien}
        />
      </Filter>
      <Container>
        <TableComponent
          header={"Lập bảng kê"}
          bordered
          columns={columns}
          summary={countTotals}
          dataSource={dataTable}
          pagination={false}
          scrollX={2000}
          scrollY={520}
          rowClassName={(record, index) =>
            record.isBiHuyBangKe ? "table-row-red" : ""
          }
          loading={loading}
          rowSelection={rowSelection}
          rowKey={rowKey}
        />
      </Container>
    </Fragment>
  );
}
export default React.memo(FormSuDung);
