import { buildQueryString, parseParams } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { alertMessage } from "@utils/function";

export const handleListData = (
  location,
  filterConditions,
  setLoading,
  setDataCapNhatEVNHES,
  setTempDataCapNhatEVNHES,
  setTotalCount,
  setTempTotalCount
) => {
  let url = "";
  url = `${Endpoint.LIST_CAP_NHAT_DU_LIEU_SO}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataCapNhatEVNHES(res.data);
        setTempDataCapNhatEVNHES(res.data);
        setTotalCount(res.paging.totalCount);
        setTempTotalCount(res.paging.totalCount);
      } else setDataCapNhatEVNHES([]);
    },
  });
};
export const submitCapNhatMDMS = (
  t,
  setLoading,
  setFilterConditions,
  selectedRowKeys,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.LAY_CHI_SO_MDMS,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys.toString(),
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFilterConditions((oldState) => ({
          ...oldState,
          // pageIndex: DEFAULT_PAGEINDEX,
          MaTrangThai: null
        }));
        setSelectedRowKeys([]);
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const submitchuyenHHU = (
  t,
  setLoading,
  setFilterConditions,
  selectedRowKeys,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.CHUYEN_HHU,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys.toString(),
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFilterConditions((oldState) => ({
          ...oldState,
          // pageIndex: DEFAULT_PAGEINDEX,
          MaTrangThai: null
        }));
        setSelectedRowKeys([]);
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};
export const submitchuyenMTB = (
  t,
  setLoading,
  setFilterConditions,
  selectedRowKeys,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.CHUYEN_MTB,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys.toString(),
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFilterConditions((oldState) => ({
          ...oldState,
          // pageIndex: DEFAULT_PAGEINDEX,
          MaTrangThai: null
        }));
        setSelectedRowKeys([]);
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};