import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
} from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import { callAPISearchCodeCT } from "@modules/treo-thao/nhan-vien-treo-thao/yeu-cau-duoc-phan-cong/services";
import LoadingComponent from "@components/Loading";
import { alertMessage } from "@utils/function";
import { ColCustomCheck, RowTable } from "./css-styled";
import moment from "moment";
import { FORMAT_DATE } from "@utils/constants";
import ThongTinChung from "../thong-tin-chung";

const { Option } = Select;
const { Search } = Input;

const arrayLoaiDiemDo = [
  {
    value: 1,
    name: "Công tơ cơ HC/điện tử đo đếm KT",
  },
  {
    value: 2,
    name: "Công tơ cơ HC/điện tử đo đếm KT+VC",
  },
  {
    value: 3,
    name: "Công tơ điện tử bán 3 giá",
  },
  {
    value: 5,
    name: "Công tơ điện tử 3 giá bán 1 giá",
  },
  {
    value: 8,
    name: "Công tơ điện tử 3 giá, có giá KT",
  },
];

export default function Index(props) {
  const {
    detail,
    form,
    thongTinHienTai,
    setMaCongToTreo,
    defaultCTTreo,
    detailCTThao,
    setDataDetaiCTTreolUpdate,
    defaultLoaiDiemDo,
    setChangeTab2,
    setChiSoMoiMax,
  } = props;
  const [detailCTTreo, setDetailCTTreo] = useState(detail);

  const [isChanged, setIsChanged] = useState(detailCTTreo?.isThayDoiLoaiDiemDo);
  const [isLT, setIsLT] = useState(detailCTTreo?.lapTrinhLaiCongTo);
  const [isSoNhan, setIsSoNhan] = useState(detailCTTreo?.layBoChiSoNhan);
  const [viTri, setViTri] = useState(0);
  const [dataCMIS, setDataCMIS] = useState({});
  const [changeDD, setChangeDD] = useState(defaultLoaiDiemDo);
  const [loading, setLoading] = useState(false);
  //click row
  const handleClickViewCamera = useCallback(
    (url, index) => {
      setViTri(index !== undefined ? index : viTri);
      form.setFieldsValue({
        ngayPMaxThao: detailCTTreo.boChiSos[index].ngayPMax,
        pMaxThao: detailCTTreo.boChiSos[index].pMax,
        chiSoMoiThao: detailCTTreo.boChiSos[index].chiSoMoi,
      });
    },
    [detailCTTreo.boChiSos, form, viTri]
  );

  //xóa công tơ
  const handleDeleteCongTo = () => {
    setDetailCTTreo(defaultCTTreo);
    setIsLT(false);
    setIsSoNhan(false);
    form.setFieldsValue({
      isThayDoiLoaiDiemDo: false,
      lapTrinhLaiCongTo: false,
      layBoChiSoNhan: false,
    });
    setMaCongToTreo("");
  };

  //change checkbox
  const handleChanged = (e, viTri) => {
    if (viTri === 1) {
      form.setFieldsValue({
        isThayDoiLoaiDiemDo: e.target.checked,
      });
      setIsChanged(e.target.checked);
    } else if (viTri === 2) {
      if (e.target.checked) {
        if (detailCTTreo) {
          Modal.confirm({
            centered: true,
            title: "Xác nhận",
            content:
              "Bạn đã nhập thông tin công tơ treo khác với công tơ tháo. Bạn có đồng ý xóa công tơ treo hiện tại và công tơ tháo sẽ là công tơ treo",
            onOk() {
              setDetailCTTreo({
                ...detailCTThao,
                vhCong: detailCTTreo.vhCong,
              });
              setIsLT(e.target.checked);
              form.setFieldsValue({
                lapTrinhLaiCongTo: true,
              });
              setMaCongToTreo(detailCTThao.maCongTo);
              detailCTThao.boChiSos.forEach((item, index) => {
                form.setFieldsValue({
                  [`chiSoMoi-${index}`]: item.chiSoMoi,
                  [`ngayPmax-${index}`]:
                    item.ngayPMax === null || item.ngayPMax === undefined
                      ? moment(moment(), FORMAT_DATE)
                      : moment(item.ngayPMax, FORMAT_DATE),
                  [`pMax-${index}`]: item.pMax,
                });
              });
              setDataCMIS(defaultCTTreo);
            },
            onCancel() {
              setIsLT(false);
              form.setFieldsValue({
                lapTrinhLaiCongTo: false,
              });
            },
            okText: "Đồng ý",
            okButtonProps: { type: "primary" },
            cancelText: "Hủy",
          });
        } else {
          // setDetailCTTreo({
          //   ...dataThao,
          //   vhCong: detailCTTreo.vhCong,
          // });
          // setDataBCSTreo(dataThao.boChiSos);
          // setIsLT(true);
          // form.setFieldsValue({
          //   lapTrinhLaiCongTo: true,
          // });
        }
      } else {
        Modal.confirm({
          centered: true,
          title: "Xác nhận",
          content:
            "Bạn muốn thực hiện hủy chọn [Lập trình lại công tơ], thông tin công tơ treo sẽ bị xóa.",
          onOk() {
            setDetailCTTreo(defaultCTTreo);
            setIsLT(false);
            form.setFieldsValue({
              lapTrinhLaiCongTo: false,
            });
            detailCTThao.boChiSos.forEach((item, index) => {
              form.setFieldsValue({
                [`chiSoMoi-${index}`]: "0",
                [`ngayPmax-${index}`]: moment(moment(), FORMAT_DATE),
                [`pMax-${index}`]: null,
              });
            });
            setMaCongToTreo("");
            setDataCMIS(defaultCTTreo);
          },
          onCancel() {
            form.setFieldsValue({
              lapTrinhLaiCongTo: true,
            });
            setIsLT(true);
          },
          okText: "Đồng ý",
          okButtonProps: { type: "primary" },
          cancelText: "Hủy",
        });
      }
    } else {
      setIsSoNhan(e.target.checked);
      form.setFieldsValue({
        layBoChiSoNhan: e.target.checked,
      });
      const newData = [];
      let arr = [];
      if (e.target.checked) {
        for (let i = 0; i < detailCTTreo.boChiSos.length; i++) {
          if (detailCTTreo.boChiSos[i].bcs[1] !== "N") {
            const filter = detailCTTreo.boChiSos.filter(
              (el) => el.bcs === detailCTTreo.boChiSos[i].bcs[0] + "N"
            );
            if (filter.length === 0) {
              newData.push({
                bcs: detailCTTreo.boChiSos[i].bcs[0] + "N",
                chiSoCu: detailCTTreo.boChiSos[i].chiSoCu,
                chiSoMoi: detailCTTreo.boChiSos[i].chiSoMoi,
                image: detailCTTreo.boChiSos[i].image,
                ngayPMax: detailCTTreo.boChiSos[i].ngayPMax,
                pMax: detailCTTreo.boChiSos[i].pMax,
                sanLuongMoi: detailCTTreo.boChiSos[i].sanLuongMoi,
                thuTu: 0,
              });
            }
          }
        }
        arr = detailCTTreo.boChiSos.concat(newData);
        setDetailCTTreo({
          ...detailCTTreo,
          boChiSos: arr,
        });
      } else {
        arr = detailCTTreo.boChiSos.filter(function (value, index, arr) {
          return value.bcs.indexOf("N") === -1;
        });
      }
      arr.forEach((item, index) => {
        form.setFieldsValue({
          [`chiSoMoi-${index}`]: item.chiSoMoi,
          [`ngayPmax-${index}`]:
            item.ngayPMax === null || item.ngayPMax === undefined
              ? moment(moment(), FORMAT_DATE)
              : moment(item.ngayPMax, FORMAT_DATE),
          [`pMax-${index}`]: item.pMax,
        });
      });
      setDetailCTTreo({
        ...detailCTTreo,
        boChiSos: arr,
      });
    }
  };

  useEffect(() => {
    if (
      detail.lapTrinhLaiCongTo === isLT &&
      detail.isThayDoiLoaiDiemDo === isChanged &&
      detail.layBoChiSoNhan === isSoNhan
    )
      setChangeTab2(false);
    else setChangeTab2(true);
  }, [
    detail.isThayDoiLoaiDiemDo,
    detail.lapTrinhLaiCongTo,
    detail.layBoChiSoNhan,
    isChanged,
    isLT,
    isSoNhan,
    setChangeTab2,
  ]);

  //change điểm đo
  const handleChangeLDD = (value) => {
    if (detailCTTreo.boChiSos.length > 0) {
      const arrBCS = [];
      detailCTTreo.boChiSos.forEach((item) => {
        arrBCS.push(item.bcs);
      });
      if (value === 1 || value === "1") {
        if (arrBCS.indexOf("KT") > -1) {
          setChangeDD(value);
        } else
          alertMessage(
            "error",
            "Thông báo",
            "Loại điểm đo 1 thì công tơ treo mới cần có biểu KT !"
          );
      } else if (value === 2 || value === "2") {
        if (arrBCS.indexOf("KT") > -1 && arrBCS.indexOf("VC") > -1) {
          setChangeDD(value);
        } else
          alertMessage(
            "error",
            "Thông báo",
            "Loại điểm đo 2 thì công tơ treo mới cần có biểu KT;VC hoặc BT;VC !"
          );
      } else if (
        value === 3 ||
        value === "3" ||
        value === 5 ||
        value === "5" ||
        value === 8 ||
        value === "8"
      ) {
        if (detailCTTreo.vhCong === "DT" || detailCTTreo.vhCong === "D1") {
          setChangeDD(value);
          if (
            arrBCS.indexOf("BT") === -1 &&
            arrBCS.indexOf("CD") === -1 &&
            arrBCS.indexOf("TD") === -1
          ) {
            alertMessage(
              "error",
              "Thông báo!",
              "Loại điểm đo 3/5/8 thì công tơ treo mới cần có biểu BT;CD;TD !"
            );
          }
        } else
          alertMessage(
            "error",
            "Thông báo",
            "Điểm đo loại 3, 5, 8 chỉ treo công tơ điện từ !"
          );
      } else setChangeDD(value);
    } else setChangeDD(value);
  };

  //search mã công tơ
  const handleSearchBarCode = (value) => {
    if (value === detailCTTreo?.maCongTo) {
      alertMessage(
        "error",
        "Thông báo",
        "Vui lòng xóa thông tin công tơ treo mới trước khi tìm kiếm công tơ khác"
      );
    } else
      callAPISearchCodeCT(
        setLoading,
        setDetailCTTreo,
        setDataCMIS,
        value,
        detailCTTreo,
        changeDD,
        setMaCongToTreo,
        form,
        thongTinHienTai?.soPha,
        setIsSoNhan
      );
  };

  const handleNumber = (e) => {
    const value = e;

    if (value.match(/^[+-]?\d+(\.\d+)?$/)) return value;
  };

  //change input value
  const handleChangeInput = useCallback(
    (e, field) => {
      const max = detailCTTreo.soCuaSo;
      let valueText = "9";
      for (let i = 1; i < max; i++) {
        valueText += "9";
      }

      if (field === "chiSoMoi") {
        if (handleNumber(e.target.value) === undefined) {
          form.setFields([
            {
              name: [`chiSoMoi-${viTri}`],
              errors: [`Nhập số nguyên hoặc số thực`],
            },
          ]);
          setChiSoMoiMax(true);
        } else {
          if (parseInt(e.target.value) < 0 || e.target.value === "") {
            form.setFields([
              {
                name: [`chiSoMoi-${viTri}`],
                errors: [`Không được nhập bé hơn 0`],
              },
            ]);
            setChiSoMoiMax(true);
          } else if (
            e.target.value.length >= 2 &&
            e.target.value[0] === "0" &&
            e.target.value[1] >= "0"
          ) {
            form.setFields([
              {
                name: [`chiSoMoi-${viTri}`],
                errors: [`Nhập sai dữ liệu`],
              },
            ]);
            setChiSoMoiMax(true);
          } else if (parseInt(e.target.value) > parseInt(valueText)) {
            form.setFields([
              {
                name: [`chiSoMoi-${viTri}`],
                errors: [`Chỉ số treo phải bé hơn hoặc bằng ${valueText}`],
              },
            ]);
            setChiSoMoiMax(true);
          } else {
            form.setFields([
              {
                name: [`chiSoMoi-${viTri}`],
                errors: false,
              },
            ]);

            form.setFieldsValue({
              [`${"chiSoMoi"}-${viTri}`]: e.target.value,
            });
            setChiSoMoiMax(false);
          }
        }
      } else {
        form.setFieldsValue({
          [`${field}-${viTri}`]: e.target.value,
        });
      }
    },
    [detailCTTreo.soCuaSo, form, setChiSoMoiMax, viTri]
  );

  //change date
  const handleOnChangeDate = useCallback(
    (date, dateString) => {
      form.setFieldsValue({
        [`ngayPMax-${viTri}`]: dateString,
      });
    },
    [form, viTri]
  );

  useEffect(() => {
    form.setFieldsValue({
      isThayDoiLoaiDiemDo: detailCTTreo.isThayDoiLoaiDiemDo,
      layBoChiSoNhan: detailCTTreo.layBoChiSoNhan,
      lapTrinhLaiCongTo: detailCTTreo.lapTrinhLaiCongTo,
      loaiDiemDo: changeDD,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (setDataDetaiCTTreolUpdate) setDataDetaiCTTreolUpdate(detailCTTreo);
  }, [detailCTTreo, setDataDetaiCTTreolUpdate]);

  useEffect(() => {
    if (detailCTTreo && detailCTTreo.boChiSos.length > 0) {
      detailCTTreo.boChiSos.forEach((item, index) => {
        let convert = moment();
        if (item.ngayPMax) {
          convert = moment(
            item.ngayPMax.split("/")[1] +
              "/" +
              item.ngayPMax.split("/")[0] +
              "/" +
              item.ngayPMax.split("/")[2]
          );
        }
        form.setFieldsValue({
          [`pMax-${index}`]: item.pMax,
          [`chiSoMoi-${index}`]: item.chiSoMoi,
          [`ngayPmax-${index}`]: convert,
        });
      }, []);
    }
  }, [detailCTTreo, form]);
  //render table
  const renderTable = useCallback(() => {
    if (detailCTTreo?.boChiSos?.length > 0)
      return (
        <RowTable gutter={24}>
          {detailCTTreo.boChiSos.map((item, index) => (
            <Fragment key={index}>
              <Col
                span={4}
                style={{
                  padding: "8px",
                  borderBottom: "1px solid #f5f5f5",
                  cursor: "pointer",
                  fontWeight: "bold",
                  backgroundColor: viTri === index ? "#FFF2CC" : "#fff",
                }}
                onClick={() =>
                  handleClickViewCamera(
                    item.image && item.image.filePath,
                    index
                  )
                }
              >
                <div style={{ marginBottom: "16px" }}>BCS</div>
                <div style={{ paddingTop: "4px" }}>Ngày Pmax</div>
              </Col>
              <Col
                span={6}
                style={{
                  padding: "8px",
                  borderBottom: "1px solid #f5f5f5",
                  cursor: "pointer",
                  fontWeight: "bold",
                  backgroundColor: viTri === index ? "#FFF2CC" : "#fff",
                }}
                onClick={() =>
                  handleClickViewCamera(
                    item.image && item.image.filePath,
                    index
                  )
                }
              >
                <div style={{ marginBottom: "16px", color: "#0018FF" }}>
                  {item.bcs}
                </div>
                <Form.Item name={`ngayPmax-${index}`} style={{ margin: 0 }}>
                  <DatePicker
                    format={FORMAT_DATE}
                    onChange={handleOnChangeDate}
                    readOnly={viTri !== index}
                  />
                </Form.Item>
              </Col>
              <Col
                span={4}
                style={{
                  padding: "8px",
                  borderBottom: "1px solid #f5f5f5",
                  cursor: "pointer",
                  fontWeight: "bold",
                  backgroundColor: viTri === index ? "#FFF2CC" : "#fff",
                }}
              ></Col>
              <Col
                span={4}
                style={{
                  padding: "8px",
                  borderBottom: "1px solid #f5f5f5",
                  cursor: "pointer",
                  fontWeight: "bold",
                  backgroundColor: viTri === index ? "#FFF2CC" : "#fff",
                }}
                onClick={() =>
                  handleClickViewCamera(
                    item.image && item.image.filePath,
                    index
                  )
                }
              >
                <div style={{ marginBottom: "16px" }}>Chỉ số treo</div>
                <div style={{ paddingTop: "4px" }}>Pmax</div>
              </Col>
              <Col
                span={6}
                style={{
                  padding: "8px",
                  borderBottom: "1px solid #f5f5f5",
                  cursor: "pointer",
                  fontWeight: "bold",
                  backgroundColor: viTri === index ? "#FFF2CC" : "#fff",
                }}
                onClick={() =>
                  handleClickViewCamera(
                    item.image && item.image.filePath,
                    index
                  )
                }
              >
                <div style={{ marginBottom: "8px" }}>
                  <Form.Item name={`chiSoMoi-${index}`} style={{ margin: 0 }}>
                    <Input
                      placeholder="Nhập chỉ số mới"
                      // defaultValue={item.chiSoMoi}
                      onChange={(e) => handleChangeInput(e, "chiSoMoi")}
                      readOnly={viTri !== index}
                      style={{ color: "#0018FF" }}
                    />
                  </Form.Item>
                </div>
                <Form.Item name={`pMax-${index}`} style={{ margin: 0 }}>
                  <Input
                    placeholder="Nhập Pmax"
                    type="number"
                    // defaultValue={detailCTTreo.boChiSos[0]?.pMax}
                    onChange={(e) => handleChangeInput(e, "pMax")}
                    readOnly={viTri !== index}
                  />
                </Form.Item>
              </Col>
            </Fragment>
          ))}
        </RowTable>
      );
  }, [
    detailCTTreo.boChiSos,
    handleChangeInput,
    handleClickViewCamera,
    handleOnChangeDate,
    viTri,
  ]);

  return (
    <LoadingComponent loading={loading}>
      <Row gutter={24}>
        <Col span={24}>
          <Row gutter={24}>
            <Col
              span={8}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "12px",
              }}
            >
              <h6 className="fs-14px c-t-label">
                Loại công tơ hiện tại{" "}
                <span
                  className="fs-16px font-bold ml-24px"
                  style={{ color: "#0000FF" }}
                >
                  {thongTinHienTai?.loaiCongTo}
                </span>
              </h6>
            </Col>

            <Col
              span={4}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "12px",
              }}
            >
              <h6 className="fs-14px c-t-label">
                Số pha{" "}
                <span
                  className="fs-16px font-bold ml-24px"
                  style={{ color: "#0000FF" }}
                >
                  {thongTinHienTai?.soPha}
                </span>
              </h6>
            </Col>
            <Col
              span={4}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "12px",
              }}
            >
              <h6 className="fs-14px c-t-label">
                VH Công{" "}
                <span
                  className="fs-16px font-bold ml-24px"
                  style={{ color: "#0000FF" }}
                >
                  {thongTinHienTai?.vhCong}
                </span>
              </h6>
            </Col>
            <Col
              span={8}
              style={{
                textAlign: "right",
                marginBottom: "12px",
              }}
            >
              <Button
                danger
                onClick={handleDeleteCongTo}
                style={{ width: "50%", margin: "0 auto" }}
                disabled={!detailCTTreo?.maCongTo}
              >
                Xóa công tơ
              </Button>
            </Col>
            <ThongTinChung detail={detailCTTreo} formThao={false} form={form} />
          </Row>

          <Row gutter={24}>
            <ColCustomCheck span={5}>
              <Form.Item
                label="Thay đổi lại điểm đo"
                name="isThayDoiLoaiDiemDo"
              >
                <Checkbox
                  value={isChanged}
                  onChange={(e) => handleChanged(e, 1)}
                />
              </Form.Item>
            </ColCustomCheck>
            <Col span={23} md={8}>
              <Form.Item name="loaiDiemDo" style={{ width: "100%", margin: 0 }}>
                <Select
                  value={changeDD}
                  placeholder="Chọn loại điểm đo"
                  disabled={!isChanged}
                  onChange={handleChangeLDD}
                >
                  {arrayLoaiDiemDo.map((item, index) => (
                    <Option key={index} value={item.value.toString()}>
                      {`${item.value} - ${item.name}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={11}>
              <Form.Item name="qrCode" style={{ margin: 0 }}>
                <Search
                  disabled={isLT}
                  placeholder="Nhập mã công tơ"
                  allowClear={false}
                  enterButton="Tìm"
                  onSearch={handleSearchBarCode}
                  onKeyDown={(e) =>
                    e.keyCode === 13 ? e.preventDefault() : ""
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12} md={5}>
              <Form.Item
                name="lapTrinhLaiCongTo"
                style={{ marginTop: "12px", marginBottom: "12px" }}
              >
                <Checkbox checked={isLT} onChange={(e) => handleChanged(e, 2)}>
                  <span>Lập trình lại công tơ</span>
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={12} md={4}>
              <Form.Item
                name="layBoChiSoNhan"
                style={{ marginTop: "12px", marginBottom: "12px" }}
              >
                <Checkbox
                  checked={isSoNhan}
                  onChange={(e) => handleChanged(e, 3)}
                  disabled={!detailCTTreo?.boChiSos?.length}
                >
                  <span>Công tơ 2 chiều</span>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          {renderTable()}
        </Col>
      </Row>
    </LoadingComponent>
  );
}
