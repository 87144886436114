import { buildQueryString, parseParams } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";
import { METHOD_POST, STATUSCODE_200, STATUSCODE_400 } from "@utils/constants";
import { alertMessage } from "@utils/function";
import { Modal, notification } from "antd";
notification.config({
  maxCount: 1,
  duration: 8,
});

export const handleListData = (
  location,
  filterConditions,
  setLoading,
  setDataLayDuLieuCMIS,
  setTempDataLayDuLieuCMIS,
  setTotalCount,
  setTempTotalCount
) => {
  let url = "";
  url = `${Endpoint.LIST_LAY_DU_LIEU_TU_CMIS}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataLayDuLieuCMIS(
          res.data.map((item, index) => ({
            ...item,
            uniqueId: index,
          }))
        );
        setTempDataLayDuLieuCMIS(
          res.data.map((item, index) => ({
            ...item,
            uniqueId: index,
          }))
        );
        setTotalCount(res.paging.totalCount);
        setTempTotalCount(res.paging.totalCount);
      } else setDataLayDuLieuCMIS([]);
    },
  });
};
export const submitLayDuLieuCMIS = (
  t,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys,
  dataLayDuLieuCMIS
) => {
  const dataPut = [];

  dataLayDuLieuCMIS?.forEach((item) => {
    const isCheck = selectedRowKeys.some((e) => e === item.uniqueId);
    if (isCheck) {
      dataPut.push(item);
    }
  });

  authPostData({
    url: Endpoint.LAY_DU_LIEU_TU_CMIS,
    method: METHOD_POST,
    payload: {
      nhanSoRequests: dataPut.map((item) => {
        return {
          lichGCSId: item.lichGCSId || 0,
          maSoGCS: item.maSoGCS,
          maDonVi: item.maDonVi,
          ky: item.ky,
          thang: item.thang,
          nam: item.nam,
          ngayGhi: item.ngayGhi,
        };
      }),
    },
    setLoading: () => {},
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
        notification.success({
          message: "Thông báo!",
          description:
            res.message.length > 1000
              ? res.message.slice(0, 1000).concat("...")
              : res.message,
        });
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};
export const handleTraSo = (
  ids,
  getListData,
  t,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.TRA_SO_VE_CMIS,
    method: METHOD_POST,
    payload: {
      ids: ids,
    },
    setLoading: () => {},
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListData();
        setSelectedRowKeys([]);
      } else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

export const handleCheckTraSo = (
  id,
  getListData,
  t,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.CHECK_TRA_SO_VE_CMIS,
    method: METHOD_POST,
    payload: {
      id: id,
    },
    setLoading: () => {},
    onSuccess: (res) => {
      if (
        res.statusCode === STATUSCODE_200 ||
        res.statusCode === STATUSCODE_400
      ) {
        notification.success({
          style: { display: "none" },
        });
        Modal.confirm({
          centered: true,
          title: "Xác nhận",
          content: res.message,
          onOk() {
            handleTraSo([id], getListData, t, setSelectedRowKeys);
          },
          onCancel() {},
          okText: "Đồng ý",
          cancelText: "Hủy",
        });
      } else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};