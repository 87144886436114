import BaoCaoNhanh from "@modules/kiem-tra-cong-to/bao-cao-nhanh";
import CongViecDinhKy from "@modules/kiem-tra-cong-to/phong-kinh-doanh/cong-viec-dinh-ky";
import CongViecPhucTra from "@modules/kiem-tra-cong-to/phong-kinh-doanh/cong-viec-phuc-tra";
import KyDuyetBanKe from "@modules/kiem-tra-cong-to/phong-kinh-doanh/ky-duyet-ban-ke";
import DongBoCMIS from "@modules/kiem-tra-cong-to/phong-kinh-doanh/dong-bo-ve-cmis";

import CongViecDinhKyDT from "@modules/kiem-tra-cong-to/doi-truong/cong-viec-dinh-ky";
import CongViecPhucTraDT from "@modules/kiem-tra-cong-to/doi-truong/cong-viec-phuc-tra";
import KyDuyetBanKeDT from "@modules/kiem-tra-cong-to/doi-truong/ky-duyet-ban-ke";
import ChiSoEVNHESDT from "@modules/kiem-tra-cong-to/doi-truong/cap-nhat-chi-so-evn-hes";

import CongViecDinhKyNV from "@modules/kiem-tra-cong-to/nhan-vien/cong-viec-dinh-ky";
import CongViecPhucTraNV from "@modules/kiem-tra-cong-to/nhan-vien/cong-viec-phuc-tra";
import CongViecDKDaKTNV from "@modules/kiem-tra-cong-to/nhan-vien/lap-bang-ke";
import CongViecPTDaKTNV from "@modules/kiem-tra-cong-to/nhan-vien/cong-viec-phuc-tra-da-kiem-tra";
import BangKeKHChuaKy from "@modules/kiem-tra-cong-to/nhan-vien/bang-ke-cho-ky";
import DanhSachBangKe from "@modules/kiem-tra-cong-to/nhan-vien/danh-sach-bang-ke";
import ChiSoEVNHES from "@modules/kiem-tra-cong-to/nhan-vien/cap-nhat-chi-so-evn-hes";

import CongViecDinhKyTC from "@modules/kiem-tra-cong-to/tra-cuu/cong-viec-dinh-ky";
import CongViecPhucTraTC from "@modules/kiem-tra-cong-to/tra-cuu/cong-viec-phuc-tra";
import DanhSachBangKeTC from "@modules/kiem-tra-cong-to/tra-cuu/danh-sach-bang-ke";

import BCCongToBatThuong from "@modules/kiem-tra-cong-to/bao-cao/cong-to-bat-thuong";
import BCCongToDKDaKiemTra from "@modules/kiem-tra-cong-to/bao-cao/cong-to-dk-da-kiem-tra";
import BCCongToPTDaKiemTra from "@modules/kiem-tra-cong-to/bao-cao/cong-to-pt-da-kiem-tra";

import KyDuyetBanKeBPPT from "@modules/kiem-tra-cong-to/bo-phan-phuc-tra";
import { kt_tb_do_dem } from "@permissions/n_kttbdodem";
import {
  NAVIGATE_KTCT,
  TITLE_TRANGCHU,
  MODULE_KIEMTRACONGTO,
  TITLE_KTCT_PKD,
  NAVIGATE_KTCT_PHONGKINHDOANH,
  TITLE_KTCT_CVDINHKY,
  NAVIGATE_KTCT_CONGVIECDINHKY,
  TITLE_KTCT_CVPHUCTRA,
  NAVIGATE_KTCT_CONGVIECPHUCTRA,
  TITLE_KTCT_KYDUYETBANKE,
  NAVIGATE_KTCT_KYDUYETBANKE,
  TITLE_KTCT_DOITRUONG,
  NAVIGATE_KTCT_DOITRUONG,
  TITLE_KTCT_NHANVIEN,
  NAVIGATE_KTCT_NHANVIEN,
  TITLE_KTCT_CVPTDAKT,
  NAVIGATE_KTCT_PT_CONGVIECDADKKT,
  TITLE_KTCT_LAPBANGKE,
  NAVIGATE_KTCT_DK_CONGVIECDADKKT,
  TITLE_KTCT_BAOCAO,
  NAVIGATE_KTCT_BAOCAO,
  TITLE_KTCT_BCCTBATTHUONG,
  NATVIGATE_KTCT_BAOCAOCTBATTHUONG,
  TITLE_KTCT_BCCTDKDAKT,
  NATVIGATE_KTCT_BAOCAOCTDKDAKT,
  TITLE_KTCT_BCCTPTDAKT,
  NATVIGATE_KTCT_BAOCAOCTPTDAKT,
  TITLE_KTCT_TRACUUTIMKIEM,
  NAVIGATE_KTCT_TRACUUTIMKIEM,
  TITLE_KTCT_BPPT,
  NAVIGATE_KTCT_BPPHUCTRA,
  TITLE_KTCT_DSBANGKE,
  NAVIGATE_KTCT_DSBANGKE,
  TITLE_KTCT_BANGKECHOKY,
  NAVIGATE_KTCT_BANGKECHOKY,
  TITLE_KTCT_DONGBOCMIS,
  NAVIGATE_KTCT_DONGBOCMIS,
  TITLE_KTCT_CHISOEVNHES,
  NAVIGATE_KTCT_CHISOEVNHES,
} from "@utils/constants";

const routes = [
  {
    title: TITLE_TRANGCHU,
    path: NAVIGATE_KTCT,
    component: BaoCaoNhanh,
    privateRoute: true,
    subItems: [],
    permissionCode: MODULE_KIEMTRACONGTO,
  },
  {
    title: TITLE_KTCT_PKD,
    path: NAVIGATE_KTCT + NAVIGATE_KTCT_PHONGKINHDOANH,
    privateRoute: true,
    permissionCode: kt_tb_do_dem.n2,
    subItems: [
      {
        subTitle: TITLE_KTCT_CVDINHKY,
        subPath: NAVIGATE_KTCT_CONGVIECDINHKY,
        subComponent: CongViecDinhKy,
        permissionCode: kt_tb_do_dem.n21,
      },
      {
        subTitle: TITLE_KTCT_CVPHUCTRA,
        subPath: NAVIGATE_KTCT_CONGVIECPHUCTRA,
        subComponent: CongViecPhucTra,
        permissionCode: kt_tb_do_dem.n22,
      },
      {
        subTitle: TITLE_KTCT_KYDUYETBANKE,
        subPath: NAVIGATE_KTCT_KYDUYETBANKE,
        subComponent: KyDuyetBanKe,
        permissionCode: kt_tb_do_dem.n23,
      },
      {
        subTitle: TITLE_KTCT_DONGBOCMIS,
        subPath: NAVIGATE_KTCT_DONGBOCMIS,
        subComponent: DongBoCMIS,
        permissionCode: kt_tb_do_dem.n24,
      },
    ],
  },
  {
    title: TITLE_KTCT_BPPT,
    path: NAVIGATE_KTCT + NAVIGATE_KTCT_BPPHUCTRA,
    privateRoute: true,
    permissionCode: kt_tb_do_dem.n7,
    subItems: [
      {
        subTitle: TITLE_KTCT_KYDUYETBANKE,
        subPath: NAVIGATE_KTCT_KYDUYETBANKE,
        subComponent: KyDuyetBanKeBPPT,
        permissionCode: kt_tb_do_dem.n71,
      },
    ],
  },
  {
    title: TITLE_KTCT_DOITRUONG,
    path: NAVIGATE_KTCT + NAVIGATE_KTCT_DOITRUONG,
    privateRoute: true,
    permissionCode: kt_tb_do_dem.n3,
    subItems: [
      {
        subTitle: TITLE_KTCT_CVDINHKY,
        subPath: NAVIGATE_KTCT_CONGVIECDINHKY,
        subComponent: CongViecDinhKyDT,
        permissionCode: kt_tb_do_dem.n31,
      },
      {
        subTitle: TITLE_KTCT_CVPHUCTRA,
        subPath: NAVIGATE_KTCT_CONGVIECPHUCTRA,
        subComponent: CongViecPhucTraDT,
        permissionCode: kt_tb_do_dem.n32,
      },
      {
        subTitle: TITLE_KTCT_KYDUYETBANKE,
        subPath: NAVIGATE_KTCT_KYDUYETBANKE,
        subComponent: KyDuyetBanKeDT,
        permissionCode: kt_tb_do_dem.n33,
      },
      {
        subTitle: TITLE_KTCT_CHISOEVNHES,
        subPath: NAVIGATE_KTCT_CHISOEVNHES,
        subComponent: ChiSoEVNHESDT,
        permissionCode: kt_tb_do_dem.n34,
      },
    ],
  },
  {
    title: TITLE_KTCT_NHANVIEN,
    path: NAVIGATE_KTCT + NAVIGATE_KTCT_NHANVIEN,
    privateRoute: true,
    permissionCode: kt_tb_do_dem.n4,
    subItems: [
      {
        subTitle: TITLE_KTCT_CVDINHKY,
        subPath: NAVIGATE_KTCT_CONGVIECDINHKY,
        subComponent: CongViecDinhKyNV,
        permissionCode: kt_tb_do_dem.n41,
      },

      {
        subTitle: TITLE_KTCT_LAPBANGKE,
        subPath: NAVIGATE_KTCT_DK_CONGVIECDADKKT,
        subComponent: CongViecDKDaKTNV,
        permissionCode: kt_tb_do_dem.n47,
      },
      {
        subTitle: TITLE_KTCT_BANGKECHOKY,
        subPath: NAVIGATE_KTCT_BANGKECHOKY,
        subComponent: BangKeKHChuaKy,
        permissionCode: kt_tb_do_dem.n46,
      },
      {
        subTitle: TITLE_KTCT_CVPHUCTRA,
        subPath: NAVIGATE_KTCT_CONGVIECPHUCTRA,
        subComponent: CongViecPhucTraNV,
        permissionCode: kt_tb_do_dem.n43,
      },
      {
        subTitle: TITLE_KTCT_CHISOEVNHES,
        subPath: NAVIGATE_KTCT_CHISOEVNHES,
        subComponent: ChiSoEVNHES,
        permissionCode: kt_tb_do_dem.n48,
      },
      {
        subTitle: TITLE_KTCT_CVPTDAKT,
        subPath: NAVIGATE_KTCT_PT_CONGVIECDADKKT,
        subComponent: CongViecPTDaKTNV,
        permissionCode: kt_tb_do_dem.n44,
      },
      {
        subTitle: TITLE_KTCT_DSBANGKE,
        subPath: NAVIGATE_KTCT_DSBANGKE,
        subComponent: DanhSachBangKe,
        permissionCode: kt_tb_do_dem.n45,
      },
    ],
  },
  {
    title: TITLE_KTCT_BAOCAO,
    path: NAVIGATE_KTCT + NAVIGATE_KTCT_BAOCAO,
    privateRoute: true,
    permissionCode: kt_tb_do_dem.n5,
    subItems: [
      {
        subTitle: TITLE_KTCT_BCCTBATTHUONG,
        subPath: NATVIGATE_KTCT_BAOCAOCTBATTHUONG,
        subComponent: BCCongToBatThuong,
        permissionCode: kt_tb_do_dem.n51,
      },
      {
        subTitle: TITLE_KTCT_BCCTDKDAKT,
        subPath: NATVIGATE_KTCT_BAOCAOCTDKDAKT,
        subComponent: BCCongToDKDaKiemTra,
        permissionCode: kt_tb_do_dem.n52,
      },
      {
        subTitle: TITLE_KTCT_BCCTPTDAKT,
        subPath: NATVIGATE_KTCT_BAOCAOCTPTDAKT,
        subComponent: BCCongToPTDaKiemTra,
        permissionCode: kt_tb_do_dem.n53,
      },
    ],
  },
  {
    title: TITLE_KTCT_TRACUUTIMKIEM,
    path: NAVIGATE_KTCT + NAVIGATE_KTCT_TRACUUTIMKIEM,
    privateRoute: true,
    permissionCode: kt_tb_do_dem.n6,
    subItems: [
      {
        subTitle: TITLE_KTCT_CVDINHKY,
        subPath: NAVIGATE_KTCT_CONGVIECDINHKY,
        subComponent: CongViecDinhKyTC,
        permissionCode: kt_tb_do_dem.n61,
      },
      {
        subTitle: TITLE_KTCT_CVPHUCTRA,
        subPath: NAVIGATE_KTCT_CONGVIECPHUCTRA,
        subComponent: CongViecPhucTraTC,
        permissionCode: kt_tb_do_dem.n62,
      },
      {
        subTitle: TITLE_KTCT_DSBANGKE,
        subPath: NAVIGATE_KTCT_DSBANGKE,
        subComponent: DanhSachBangKeTC,
        permissionCode: kt_tb_do_dem.n63,
      },
    ],
  },
];

export default routes;
