import { memo, useEffect, useRef, useState } from "react";
import { Form } from "antd";
import UIChiPhi from "./modal";
import { handleCreateOrUpdate } from "../services";

function ChiPhiModal({
  detail,
  visible,
  closeModal,
  typeChiPhi,
  getListData,
  detailBaoCao,
  thangBaoCao,
  dataVatTu,
}) {
  const inputRef = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    const data = {
      ...values,
      hoSoId: detailBaoCao.hoSoId,
      donViId: detailBaoCao.donViId,
      thangBaoCao: thangBaoCao,
      soLuong: values.soLuong ? parseInt(values.soLuong) : 0,
      chiPhi: values.chiPhi ? parseInt(values.chiPhi) : 0,
    };
    handleCreateOrUpdate(
      data,
      detail,
      setLoading,
      closeModal,
      form,
      getListData,
      typeChiPhi
    );
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();
    // eslint-disable-next-line
  }, []);

  return (
    <UIChiPhi
      loading={loading}
      typeChiPhi={typeChiPhi}
      visible={visible}
      closeModal={closeModal}
      form={form}
      onFinish={onFinish}
      dataVatTu={dataVatTu}
      detail={detail}
      inputRef={inputRef}
    />
  );
}

export default memo(ChiPhiModal);
