import { Col, Collapse, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { getDataDetailDiemDo } from "./service";
import styled from "styled-components";
import { quanLyChiSoSelector } from "@state/giao-nhan-dien-nang/quan-ly-chi-so";
import { useSelector } from "react-redux";

const { Panel } = Collapse;
export default function ThongTinDiemDo() {
  const { diemDoIdSelected } = useSelector(quanLyChiSoSelector);
  const [loading, setLoading] = useState(false);
  const [detailDiemDo, setDetailDiemDo] = useState(null);
  useEffect(() => {
    if (diemDoIdSelected) {
      getDataDetailDiemDo({
        setDetailDiemDo,
        setLoading,
        id: diemDoIdSelected,
      });
    } else {
      setDetailDiemDo(null);
    }
  }, [diemDoIdSelected]);
  return (
    <CollapseStyled defaultActiveKey={["1"]} expandIconPosition="right">
      <Panel header="Thông tin điểm đo" key="1">
        <Spin spinning={loading}>
          <Row gutter={24}>
            <Col span={6}>
              <div>
                Mã điểm đo:{" "}
                <span style={{ fontWeight: 600 }}>
                  {detailDiemDo?.maDiemDo}
                </span>
              </div>
              <div>
                Cấp điện áp:{" "}
                <span style={{ fontWeight: 600 }}>
                  {detailDiemDo?.capDienAp}
                </span>
              </div>
            </Col>
            <Col span={11}>
              <div>
                Thông tin điểm đo:{" "}
                <span style={{ fontWeight: 600 }}>
                  <> </>
                  {detailDiemDo?.thongTinDiemDo}
                </span>
              </div>
              <div>
                Mã trạm:{" "}
                <span style={{ fontWeight: 600 }}>{detailDiemDo?.maTram}</span>
              </div>
            </Col>
            <Col span={7}>
              <div>
                Số công tơ:{" "}
                <span style={{ fontWeight: 600 }}>
                  {detailDiemDo?.soCongTo}
                </span>
              </div>
              <div>
                Tính chất điểm đo:{" "}
                <span style={{ fontWeight: 600 }}>
                  {detailDiemDo?.tinhChatDiemDo}
                </span>
              </div>
            </Col>
          </Row>
        </Spin>
      </Panel>
    </CollapseStyled>
  );
}

const CollapseStyled = styled(Collapse)`
  border: unset !important;
  .ant-collapse-header {
    padding: 6px 24px !important;
  }
  .ant-collapse-content-box {
    padding: 6px 24px !important;
  }
`;
