import React, { memo, useEffect, useState } from "react";
import { Form } from "antd";
import PhanCongModal from "./modal-phan-cong";
import { handleDTPhanCong, handlePKDPhanCong } from "./services";
import { ENUM_HINHTHUC_KTCT, TYPE_PHANCONG } from "@utils/constants";

function Index({
  visiblePhanCong,
  handleCloseModal,
  selectedRowKeys,
  setFetchList,
  setFetchDataTable,
  typePhanCong,
  dataDoi,
  setVisiblePhanCong,
  setSelectedRowKeys,
  typeList,
  typeDS,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      hinhThucGCS: ENUM_HINHTHUC_KTCT.MTB,
    });
  }, [form]);
  // call api phân công

  const onFinish = (values) => {
    if (typePhanCong === TYPE_PHANCONG.DOITRUONG) {
      handleDTPhanCong(
        values,
        selectedRowKeys,
        setLoading,
        setFetchDataTable,
        form,
        setVisiblePhanCong,
        setSelectedRowKeys,
        typeList
      );
    } else if (typePhanCong === TYPE_PHANCONG.PHONGKINHDOANH) {
      handlePKDPhanCong(
        values,
        selectedRowKeys,
        setLoading,
        setFetchDataTable,
        form,
        setVisiblePhanCong,
        setSelectedRowKeys,
        typeList
      );
    }
  };

  return (
    <PhanCongModal
      loading={loading}
      visiblePhanCong={visiblePhanCong}
      handleCloseModal={handleCloseModal}
      selectedRowKeys={selectedRowKeys}
      setFetchList={setFetchList}
      onFinish={onFinish}
      form={form}
      dataDoi={dataDoi}
      typePhanCong={typePhanCong}
      typeDS={typeDS}
    />
  );
}

export default memo(Index);
