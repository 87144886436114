import { BASE_API_URL_BAO_CAO } from "@utils/constants";

export const APIBCCTKD = {
  BCCTKD_GIA_KH_LAP_DAT_MAT_TROI_AP_MAI: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcctkd/gia-kh-lap-dat-mat-troi-ap-mai`,

  BCCTKD_QLSD_KIM_CHI_THEO_MA_KIM_LUY_KE: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcctkd/qlsd-kim-chi-theo-ma-kim-luy-ke`,

  BCCTKD_QLSD_KIM_CHI_THEO_MA_KIM: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcctkd/qlsd-kim-chi-theo-ma-kim`,

  BCCTKD_CT_QL_SU_DUNG_KIM_CHI_NIEM_PHONG_TONG_HOP_LUY_KE: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcctkd/cong-tac-quan-ly-su-dung-kim-chi-niem-phong-tong-hop-luy-ke`,

  BCCTKD_CT_QL_KIM_CHI_NIEM_PHONG_TONG_HOP: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcctkd/qlsd-kim-chi-theo-ma-kim-tong-hop`,

  BCCTKD_DSKH_LAP_DAT_MAT_TROI_AP_MAI: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcctkd/dskh-lap-dat-mat-troi-ap-mai`,

  BCCTKD_CHI_TIET_KH_LAP_DAT_MAT_TROI_AP_MAI: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcctkd/gia-kh-lap-dat-mat-troi-ap-mai`,

  BCCTKD_DS_SU_DUNG_KIM_CHI_NIEM_PHONG: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcctkd/list-su-dung-kim-chi-niem-phong`,


  BCCTKD_THEM_SU_DUNG_KIM_CHI_NIEM_PHONG: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcctkd/them-su-dung-kim-chi-niem-phong`,

  BCCTKD_XOA_SU_DUNG_KIM_CHI_NIEM_PHONG: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcctkd/xoa-su-dung-kim-chi-niem-phong`,

  BCCTKD_DS_DOI: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcctkd/bckd-dm-doi`,

  BCCTKD_DS_NHAN_VIEN: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcctkd/danh-sach-nhan-vien`,
};
