import { ENUM_SOPHA, TYPE_TABLE_CONGTO } from "@utils/constants";
import { Col, Form, Row, Typography } from "antd";
import { Fragment } from "react";

export default function ThongTinChungPanel({ typeSoPha, typeList }) {
  return (
    <Fragment>
      {typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA ||
      typeList === TYPE_TABLE_CONGTO.CHISO_EVN_HES ||
      typeList === TYPE_TABLE_CONGTO.DONGBO_CMIS ? (
        <Col span={24}>
          <span className="fs-16px font-bold">Thông tin chung</span>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="Mã điểm đo"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.maDiemDo !== curValues.maDiemDo
                }
              >
                {({ getFieldValue }) => {
                  const maDiemDo = getFieldValue("maDiemDo") || undefined;
                  return maDiemDo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {maDiemDo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Đội"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tenDoi !== curValues.tenDoi
                }
              >
                {({ getFieldValue }) => {
                  const tenDoi = getFieldValue("tenDoi") || undefined;
                  return tenDoi ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tenDoi}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Sổ GCS"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.maSoGhiChiSo !== curValues.maSoGhiChiSo
                }
              >
                {({ getFieldValue }) => {
                  const maSoGhiChiSo =
                    getFieldValue("maSoGhiChiSo") || undefined;
                  return maSoGhiChiSo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {maSoGhiChiSo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Trạm"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tenTram !== curValues.tenTram
                }
              >
                {({ getFieldValue }) => {
                  const tenTram = getFieldValue("tenTram") || undefined;
                  return tenTram ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tenTram}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="Tên khách hàng"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tenKhachHang !== curValues.tenKhachHang
                }
              >
                {({ getFieldValue }) => {
                  const tenKhachHang =
                    getFieldValue("tenKhachHang") || undefined;
                  return tenKhachHang ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tenKhachHang}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Điện thoại"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.soDienThoai !== curValues.soDienThoai
                }
              >
                {({ getFieldValue }) => {
                  const soDienThoai = getFieldValue("soDienThoai") || undefined;
                  return soDienThoai ? (
                    <Typography.Text className="ant-form-text" strong>
                      {soDienThoai}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Người kiểm tra"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tenNhanVien !== curValues.tenNhanVien
                }
              >
                {({ getFieldValue }) => {
                  const tenNhanVien = getFieldValue("tenNhanVien") || undefined;
                  return tenNhanVien ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tenNhanVien}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Địa chỉ dùng điện"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.diaChiDungDien !== curValues.diaChiDungDien
                }
              >
                {({ getFieldValue }) => {
                  const diaChiDungDien =
                    getFieldValue("diaChiDungDien") || undefined;
                  return diaChiDungDien ? (
                    <Typography.Text className="ant-form-text" strong>
                      {diaChiDungDien}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      ) : typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY &&
        typeSoPha === ENUM_SOPHA.MOT_PHA ? (
        <Col span={24}>
          <span className="fs-16px font-bold">Thông tin chung</span>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="Mã điểm đo"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.maDiemDo !== curValues.maDiemDo
                }
              >
                {({ getFieldValue }) => {
                  const maDiemDo = getFieldValue("maDiemDo") || undefined;
                  return maDiemDo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {maDiemDo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Đội"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tenDoi !== curValues.tenDoi
                }
              >
                {({ getFieldValue }) => {
                  const tenDoi = getFieldValue("tenDoi") || undefined;
                  return tenDoi ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tenDoi}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Sổ GCS"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.maSoGhiChiSo !== curValues.maSoGhiChiSo
                }
              >
                {({ getFieldValue }) => {
                  const maSoGhiChiSo =
                    getFieldValue("maSoGhiChiSo") || undefined;
                  return maSoGhiChiSo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {maSoGhiChiSo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Trạm"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tenTram !== curValues.tenTram
                }
              >
                {({ getFieldValue }) => {
                  const tenTram = getFieldValue("tenTram") || undefined;
                  return tenTram ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tenTram}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="Tên khách hàng"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tenKhachHang !== curValues.tenKhachHang
                }
              >
                {({ getFieldValue }) => {
                  const tenKhachHang =
                    getFieldValue("tenKhachHang") || undefined;
                  return tenKhachHang ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tenKhachHang}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Điện thoại"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.soDienThoai !== curValues.soDienThoai
                }
              >
                {({ getFieldValue }) => {
                  const soDienThoai = getFieldValue("soDienThoai") || undefined;
                  return soDienThoai ? (
                    <Typography.Text className="ant-form-text" strong>
                      {soDienThoai}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Người kiểm tra"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tenNhanVien !== curValues.tenNhanVien
                }
              >
                {({ getFieldValue }) => {
                  const tenNhanVien = getFieldValue("tenNhanVien") || undefined;
                  return tenNhanVien ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tenNhanVien}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Hạn kiểm định"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.hanKiemDinh !== curValues.hanKiemDinh
                }
              >
                {({ getFieldValue }) => {
                  const hanKiemDinh = getFieldValue("hanKiemDinh") || undefined;
                  return hanKiemDinh ? (
                    <Typography.Text className="ant-form-text" strong>
                      {hanKiemDinh}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Địa chỉ dùng điện"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.diaChiDungDien !== curValues.diaChiDungDien
                }
              >
                {({ getFieldValue }) => {
                  const diaChiDungDien =
                    getFieldValue("diaChiDungDien") || undefined;
                  return diaChiDungDien ? (
                    <Typography.Text className="ant-form-text" strong>
                      {diaChiDungDien}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      ) : typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY &&
        typeSoPha === ENUM_SOPHA.BA_PHA ? (
        <Col span={24}>
          <span className="fs-16px font-bold">Thông tin chung</span>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="Mã điểm đo"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.maDiemDo !== curValues.maDiemDo
                }
              >
                {({ getFieldValue }) => {
                  const maDiemDo = getFieldValue("maDiemDo") || undefined;
                  return maDiemDo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {maDiemDo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Đội"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tenDoi !== curValues.tenDoi
                }
              >
                {({ getFieldValue }) => {
                  const tenDoi = getFieldValue("tenDoi") || undefined;
                  return tenDoi ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tenDoi}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Sổ GCS"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.maSoGhiChiSo !== curValues.maSoGhiChiSo
                }
              >
                {({ getFieldValue }) => {
                  const maSoGhiChiSo =
                    getFieldValue("maSoGhiChiSo") || undefined;
                  return maSoGhiChiSo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {maSoGhiChiSo}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Trạm"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tenTram !== curValues.tenTram
                }
              >
                {({ getFieldValue }) => {
                  const tenTram = getFieldValue("tenTram") || undefined;
                  return tenTram ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tenTram}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="Tên khách hàng"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tenKhachHang !== curValues.tenKhachHang
                }
              >
                {({ getFieldValue }) => {
                  const tenKhachHang =
                    getFieldValue("tenKhachHang") || undefined;
                  return tenKhachHang ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tenKhachHang}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Điện thoại"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.soDienThoai !== curValues.soDienThoai
                }
              >
                {({ getFieldValue }) => {
                  const soDienThoai = getFieldValue("soDienThoai") || undefined;
                  return soDienThoai ? (
                    <Typography.Text className="ant-form-text" strong>
                      {soDienThoai}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Người kiểm tra"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tenNhanVien !== curValues.tenNhanVien
                }
              >
                {({ getFieldValue }) => {
                  const tenNhanVien = getFieldValue("tenNhanVien") || undefined;
                  return tenNhanVien ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tenNhanVien}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Hạn kiểm định"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.hanKiemDinh !== curValues.hanKiemDinh
                }
              >
                {({ getFieldValue }) => {
                  const hanKiemDinh = getFieldValue("hanKiemDinh") || undefined;
                  return hanKiemDinh ? (
                    <Typography.Text className="ant-form-text" strong>
                      {hanKiemDinh}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Địa chỉ dùng điện"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.diaChiDungDien !== curValues.diaChiDungDien
                }
              >
                {({ getFieldValue }) => {
                  const diaChiDungDien =
                    getFieldValue("diaChiDungDien") || undefined;
                  return diaChiDungDien ? (
                    <Typography.Text className="ant-form-text" strong>
                      {diaChiDungDien}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      ) : null}
    </Fragment>
  );
}
