/* eslint-disable react-hooks/exhaustive-deps */
import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth/reducer";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE, ENUM_KY_GCS, FORMAT_MONTH_ENGLISH, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, customColumn, handlePagination, parseParams, removeUndefinedAttribute } from "@utils/function";
import { authGetData } from "@utils/request";
import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import moment from "moment";
import React, { Fragment, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Filter from "../../common/Filter";
import { Export } from "../export/Export";
import SelectMaSoGCS from "./common/SelectGetMaSoGCS";
import shouldUpdateMaso from "./common/should-update-ma-so";

export default function BKSanLuongCongTo() {
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const location = useLocation(false);
  const { user } = useSelector(authSelector);
  const [dataTableSL, setDataTableSL] = useState();
  const [totalCount, setTotalCount] = useState();
  const [form] = Form.useForm();

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    KyGCS: 1,
    ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
    PhanTramBatThuong: 30,
    ...parseParams(location.search),
  });
  const [query, setQuery] = useState({
    pageIndex: 1,
    pageSize: 50,
  });

  const [listMaso, setListMaso] = useState([]);

  const queryString = buildQueryString(
    parseParams({
      ...filterConditions,
      DonViId: filterConditions.DonViId,
      MaSoGCS: filterConditions.MaSoGCS,
      KyGCS: filterConditions.KyGCS,
      ThangNam: filterConditions.ThangNam === null ? undefined : filterConditions.ThangNam,
      PhanTramBatThuong: filterConditions.PhanTramBatThuong,
    })
  );

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
    form.setFieldsValue({
      ...filterConditions,
    });
  }, [filterConditions, form]);

  const getCongToSLBatThuong = useCallback(() => {
    authGetData({
      url: `${Endpoint.LIST_BIEN_BAN_SLBT}?${queryString}`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          setDataTableSL(
            res.data.map((e, index) => ({
              ...e,
              key: (filterConditions.pageIndex - 1) * filterConditions.pageSize + (index + 1),
            }))
          );
          setTotalCount(res.paging.totalCount);
        }
      },
    });
  }, [filterConditions, queryString]);

  useEffect(() => {
    getCongToSLBatThuong();
  }, [getCongToSLBatThuong]);

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      ...values,
    }));
  }, []);

  const clear = useCallback(() => {
    form.resetFields();
  }, [form]);

  const genExtra = useCallback(
    () => (
      <div className="export">
        <Export
          excelEndpoint={`${Endpoint.XUAT_EXCEL_CONGTO_SLBT}?${queryString}`}
          pdfEndpoint={`${Endpoint.XUAT_PDF_CONGTO_SLBT}?${queryString}`}
          setLoading={setLoading}
        />
      </div>
    ),
    [queryString]
  );

  const columns = [
    {
      title: "STT",
      key: "index",
      render: (a, b, index) => {
        return (filterConditions.pageIndex - 1) * filterConditions.pageSize + (index + 1);
      },
      width: 70,
      align: "center",
    },
    {
      title: "Mã Trạm",
      dataIndex: "maTram",
      key: "maTram",
      width: 100,
      align: "center",
    },
    {
      title: "Mã KH",
      dataIndex: "maKhachHang",
      key: "maKhachHang",
      width: 100,
      align: "center",
    },
    {
      title: "Mã GC",
      dataIndex: "maGhiChu",
      key: "maGhiChu",
      width: 100,
      align: "center",
    },
    {
      title: "Mã sổ GCS",
      dataIndex: "maGCS",
      key: "maGCS",
      width: 150,
      align: "center",
    },
    {
      title: "Tên KH",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      width: 100,
      align: "center",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      width: 100,
      align: "center",
    },
    {
      title: "Loại BCS",
      dataIndex: "loaiBoChiSo",
      key: "loaiBoChiSo",
      width: 100,
      align: "center",
    },
    {
      title: "Số hộ",
      dataIndex: "soHo",
      key: "soHo",
      align: "center",
      width: 80,
    },
    {
      title: "Số C.Tơ",
      dataIndex: "soCongTo",
      key: "soCongTo",
      width: 160,
      align: "center",
    },
    {
      title: "Tình trạng",
      dataIndex: "trangThai",
      key: "trangThai",
      width: 100,
      align: "center",
    },
    {
      title: "CS Đ.Kỳ",
      dataIndex: "chiSoDauKy",
      key: "chiSoDauKy",
      width: 100,
      align: "center",
    },
    {
      title: "CS C.Kỳ",
      dataIndex: "chiSoMoi",
      key: "chiSoMoi",
      width: 100,
      align: "center",
    },
    {
      title: "SL tháo",
      dataIndex: "slThao",
      key: "slThao",
      width: 100,
      align: "center",
    },
    {
      title: "HSN",
      dataIndex: "heSoNhan",
      key: "heSoNhan",
      align: "center",
      width: 70,
    },
    {
      title: "ĐNTT",
      dataIndex: "dienNangTieuThu",
      key: "dienNangTieuThu",
      width: 90,
      align: "center",
    },
    {
      title: "ĐNTT K.Trước",
      dataIndex: "dienNangTieuThuKyTruoc",
      key: "dienNangTieuThuKyTruoc",
      width: 100,
      align: "center",
    },
    {
      title: "Chênh Lệch(kwh)",
      dataIndex: "chenhLechKwh",
      key: "chenhLechKwh",
      width: 100,
      align: "center",
    },
    {
      title: "Chênh Lệch(%)",
      dataIndex: "chenhLech",
      key: "chenhLech",
      width: 100,
      align: "center",
    },
    {
      title: "Số cột",
      dataIndex: "soCot",
      key: "soCot",
      width: 90,
      align: "center",
    },
    {
      title: "Ghi Chú",
      dataIndex: "ghiChu ",
      key: "ghiChu ",
      width: 100,
      align: "center",
    },
  ];
  return (
    <Fragment>
      <Container>
        <Spin spinning={loading}>
          <Filter>
            <FormComponent
              form={form}
              initialValues={{
                KyGCS: "1",
                DonViId: user.unitId,
                PhanTramBatThuong: "30",
              }}
              name="filter-form"
              onFinish={handleSearch}
              layout="vertical"
            >
              <Row gutter={24}>
                <Col span={24} md={6}>
                  <Form.Item name="DonViId" label="Đơn vị">
                    <Selection url={Endpoint.GET_UNIT_LIST} form={form} formKey="DonViId" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.DonViId !== currentValues.DonViId}
                  >
                    {({ getFieldValue }) => {
                      if (getFieldValue("DonViId")) {
                        return (
                          <Form.Item name="ToDoiId" label="Tổ/Đội">
                            <Selection
                              url={`${Endpoint.AUTH_LIST_TO_DOI}?DonViId=${getFieldValue("DonViId")}`}
                              formKey="ToDoiId"
                              form={form}
                            />
                          </Form.Item>
                        );
                      }
                      return (
                        <Form.Item name="ToDoiId" label="Tổ/Đội">
                          <Select disabled />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={24} md={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => {
                      if (prevValues.DonViId !== currentValues.DonViId) {
                        form.setFieldsValue({
                          TramId: undefined,
                        });
                      }
                      return prevValues.DonViId !== currentValues.DonViId;
                    }}
                  >
                    {({ getFieldValue }) => {
                      if (getFieldValue("DonViId")) {
                        return (
                          <Form.Item name="TramId" label="Trạm">
                            <Selection
                              url={`${Endpoint.LIST_TRAM_CT}?donviId=${getFieldValue("DonViId")}`}
                              formKey="TramId"
                              form={form}
                            />
                          </Form.Item>
                        );
                      }
                      return (
                        <Form.Item name="TramId" label="Trạm">
                          <Selection url={`${Endpoint.LIST_TRAM_CT}?donviId=${user.unitId}`} formKey="TramId" form={form} />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={24} md={3}>
                  <Form.Item name="KyGCS" label="Kỳ GCS" labelAlign="right">
                    <Select>
                      <Select.Option value={ENUM_KY_GCS.KY_1}>1</Select.Option>
                      <Select.Option value={ENUM_KY_GCS.KY_2}>2</Select.Option>
                      <Select.Option value={ENUM_KY_GCS.KY_3}>3</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} md={3}>
                  <Form.Item name="ThangNam" label="Tháng/Năm" labelAlign="right">
                    <DatePickerComponent picker="month" formKey="ThangNam" format={"YYYY-MM"} form={form} />
                  </Form.Item>
                </Col>
                <Col span={24} md={4}>
                  <Form.Item name="PhanTramBatThuong" label="Phần trăm bất thường" labelAlign="right">
                    <Input addonBefore="+/-" type="number" addonAfter="%" defaultValue={30} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} md={24}>
                  <Form.Item
                    name="MaSoGCS"
                    label="Mã sổ"
                    shouldUpdate={(prevValues, currentValues) => {
                      shouldUpdateMaso({
                        prevValues,
                        currentValues,
                        setQueryMaso: setQuery,
                        form,
                        setListMaso,
                        fieldUpdate: "MaSoGCS",
                      });
                    }}
                  >
                    <SelectMaSoGCS
                      query={query}
                      setQuery={setQuery}
                      url={Endpoint.LIST_MA_SO_BANG_KE_NO_TO_DOI}
                      formKey="MaSoGCS"
                      form={form}
                      loading
                      mode={"multiple"}
                      listMaso={listMaso}
                      setListMaso={setListMaso}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end">
                <Button className="mr-24px" onClick={clear}>
                  Bỏ lọc
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Tìm kiếm
                </Button>
              </Row>
            </FormComponent>
          </Filter>
          <TableComponent
            header={"Bảng kê công tơ có sản lượng bất thường"}
            renderExtraAction={() => genExtra()}
            dataSource={dataTableSL}
            columns={customColumn(columns, filterConditions)}
            totalData={totalCount}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            scrollX={1400}
          />
        </Spin>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
  .ant-collapse.ant-collapse-icon-position-left {
    margin-top: 20px;
  }
`;
