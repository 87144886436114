import { Col } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  padding: 20px 10px;
`;
export const FieldSetContainer = styled.fieldset`
  margin-top: 16px;
  legend {
    width: 120px;
    color: #000000;
    font-size: 14px;
    margin-bottom: 8px;
    border: unset;
  }
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 2px;
  border-style: groove;
  border-color: threedface;
`;

export const LabelColStyled = styled(Col)`
  .ant-form-item-label {
    display: flex;
    align-items: center;
  }
  .ant-form-item-control input {
    width: 100%;
  }
`;

export const ColStyled = styled(Col)`
  @media screen and (min-width: 1025px) {
    .ant-form-item-label {
      margin-top: 10px;
    }

    .ant-form-item-control {
      margin-top: 12px;
    }
  }
`;
