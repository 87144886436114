import { Fragment } from "react";
import ListMenu from "./list-menu";
export default function ListChucNang(props) {
  const { detail, loading, setLoading, values, setDataTable, getListDD } =
    props;

  return (
    <Fragment>
      <ListMenu
        details={detail}
        loading={loading}
        setLoading={setLoading}
        values={values}
        setDataTable={setDataTable}
        getListDD={getListDD}
      />
    </Fragment>
  );
}
