import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Bieu1 from "./bieu1";
import Bieu2 from "./bieu2";
import Bieu3 from "./bieu3";
import Bieu4 from "./bieu4";
import Bieu5 from "./bieu5";
import Bieu6 from "./bieu6";
import Bieu7 from "./bieu7";
import Bieu8 from "./bieu8";
import { selectBCMOMT } from "./service";
export default function TinNhanMOMT() {
  const { Option } = Select;
  const [loading] = useState(false);
  const [form] = Form.useForm();
  const [screen, setScreen] = useState();

  const onFinish = (values) => {
    // authPostData({
    //   url: ``,
    //   method: "POST",
    //   setLoading,
    //   payload: values,
    //   onSuccess: (res) => {
    //     console.log("res", res);
    //   },
    // });
    console.log("values", values);
  };

  useEffect(() => {
    setScreen(selectBCMOMT[0].value);
  }, []);

  const handleChange = useCallback((values) => {
    setScreen(values);
  }, []);

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
    },
    {
      title: "Đơn vị",
      dataIndex: "unitId",
      key: "unitId",
    },
    {
      title: "SL tin nhắn ENVHN gửi nhà CCDV",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "SL tin nhắn nhà CCDV nhận từ EVNHN",
      dataIndex: "smsCount",
      key: "smsCount",
    },
    {
      title: "SL tin nhắn nhà CCDV đẩy sang nhà mạng",
      dataIndex: "syntax",
      key: "syntax",
    },
    {
      title: "SL nhắn nhà mạng gửi thành công",
      dataIndex: "thoigianguimo",
      key: "thoigianguimo",
    },
    {
      title: "SL tin nhắn nhà mạng gửi lỗi",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "SL nhắn nhà mạng gửi lỗi đã xử lý",
      dataIndex: "success",
      key: "success",
    },
  ];

  return (
    <Fragment>
      {screen === Endpoint.BCTHSMS_THSL_TIN_NHAN_THEO_TUNG_DICH_VU ? (
        <Bieu1 screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.BCTHSMS_CT_TIN_NHAN_THEO_CU_PHAP ? (
        <Bieu2 screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.BCTHSMS_DS_TIN_NHAN_THANH_CONG_THEO_NGAY ? (
        <Bieu3 screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.BCTHSMS_DS_TIN_NHAN_MO_THAY_DOI_SO_DIEN_THOAI ? (
        <Bieu4 screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.BCTHSMS_DS_TIN_NHAN_KHONG_THANH_CONG ? (
        <Bieu5 screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.BCTHSMS_CT_TIN_NHAN_SAI_CU_PHAP ? (
        <Bieu6 screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.BCTHSMS_DOI_SOAT_TIN_NHAN_8088 ? (
        <Bieu7 screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.BCTHSMS_CT_TIN_NHAN_8088 ? (
        <Bieu8 screen={screen} handleChange={handleChange} />
      ) : (
        <Container>
          <Divider className="section-text" orientation="left">
            Hiển thị báo cáo
          </Divider>
          <ContentWrapper>
            <Spin spinning={loading}>
              <FormComponent
                onFinish={onFinish}
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                initialValues={{ reportType: screen }}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="reportType"
                      label="Tên báo cáo"
                      labelAlign="left"
                    >
                      <Select
                        placeholder="Tất cả"
                        options={selectBCMOMT}
                        formKey="reportType"
                        form={form}
                        onChange={() =>
                          handleChange(form.getFieldValue("reportType"))
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="phanloai"
                      label="Phân loại"
                      labelAlign="left"
                    >
                      <Select defaultValue="all">
                        <Option value="all"> Tất cả</Option>
                        <Option value="0"> Thông báo Tiền điện lần 1</Option>
                        <Option value="1">Tin nhắn SMS Dịch vụ khác</Option>
                        <Option value="2">Dịch vụ điện tử</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name="unitId" label="Đơn vị" labelAlign="left">
                      <Selection
                        url={Endpoint.GET_UNIT_LIST}
                        formKey="unitId"
                        form={form}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item
                      name="reportStartDate"
                      label="Ngày bắt đầu"
                      labelAlign="left"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <DatePickerComponent
                        picker="Day"
                        formKey="reportStartDate"
                        form={form}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="reportEndDate"
                      label="Ngày kết thức"
                      labelAlign="right"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <DatePickerComponent
                        picker="Day"
                        formKey="reportEndDate"
                        form={form}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="end">
                  <Button type="primary" className="mr-5px">
                    Xem báo cáo
                  </Button>
                  <Button type="primary">Xuất Excel</Button>
                </Row>
              </FormComponent>
              <Divider
                className="section-text-line"
                orientation="left"
              ></Divider>
              <TableComponent
                header={"Kết quả hiển thị"}
                columns={columns}
                dataSource={null}
              />
            </Spin>
          </ContentWrapper>
        </Container>
      )}
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
