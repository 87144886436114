import FormComponent from "@components/Form";
import PanelsThongTinChung from "./panels/panels-thong-tin-chung";
import PanelsThongTinNganHang from "./panels/panels-thong-tin-ngan-hang";
import PanelsThongTinVeDichVu from "./panels/panels-thong-tin-ve-dich-vu-dien";
import PanelsThongTinveDiemDoHienTai from "./panels/panels-thong-tin-ve-diem-do-hien-tai";
import LoadingComponent from "@components/Loading";
import { TYPE_LOAIYEUCAU_HOSO } from "@utils/constants";
import { Button, Col, Collapse, Row } from "antd";
const { Panel } = Collapse;

export default function CapNhatModal(props) {
  const {
    loading,
    ref1,
    form,
    dataDetail,
    handleSubmit,
    dataDiaChinh,
    dataGioiTinhKH,
    dataLanhDao,
    dataSoPha,
    dataMucDich,
    newDataLoaiYeuCau,
    dataNganHang,
    t,
  } = props;
  return (
    <LoadingComponent loading={loading}>
      <div ref={ref1}>
        <FormComponent
          form={form}
          name="form-thong-tin-chung"
          layout="vertical"
          initialValues={{
            ...dataDetail,
          }}
          id="form-cap-nhat"
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Collapse defaultActiveKey={["1", "2", "3", "4"]}>
            <Panel
              header={t("cap_nhat_ho_so.thong_tin_chung")}
              key="1"
              className="Title"
            >
              <PanelsThongTinChung
                form={form}
                detail={dataDetail}
                dataDiaChinh={dataDiaChinh}
                dataGioiTinhKH={dataGioiTinhKH}
                dataLanhDao={dataLanhDao}
              />
            </Panel>
            <Panel
              header={t("cap_nhat_ho_so.thong_tin_ve_diem_do_hien_tai")}
              key="2"
            >
              <PanelsThongTinveDiemDoHienTai
                detail={dataDetail}
                form={form}
                dataDiaChinh={dataDiaChinh}
              />
            </Panel>

            <Panel
              header={
                dataDetail.maLoaiYeuCau === TYPE_LOAIYEUCAU_HOSO.NANGCONGSUAT
                  ? " THÔNG TIN VỀ YÊU CẦU THAY ĐỔI CÔNG SUẤT"
                  : dataDetail.maLoaiYeuCau === TYPE_LOAIYEUCAU_HOSO.DIDOI
                  ? " THÔNG TIN VỀ YÊU CẦU DI DỜI"
                  : t("cap_nhat_ho_so.thong_tin_ve_dich_vu_dien")
              }
              key="3"
            >
              <PanelsThongTinVeDichVu
                detail={dataDetail}
                form={form}
                dataSoPha={dataSoPha}
                dataMucDich={dataMucDich}
                dataLoaiYeuCau={newDataLoaiYeuCau}
              />
            </Panel>
            <Panel header={t("cap_nhat_ho_so.thong_tin_khac")} key="4">
              <PanelsThongTinNganHang
                detail={dataDetail}
                form={form}
                dataNganHang={dataNganHang}
              />
            </Panel>
          </Collapse>
          <Row>
            <Col span={24} className="btn_submit">
              <Button type="primary" htmlType="submit" loading={loading}>
                {t("button.cap_nhat")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </div>
    </LoadingComponent>
  );
}
