import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData } from "@utils/request";

// call api list ngành nghề
export const handleListDataNN = (
  setLoading,
  filterConditions,
  setDataNN,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.COMMON_DM_LIST_NGANH_NGHE}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataNN(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataNN([]);
    },
  });
};
