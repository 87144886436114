import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useEffect
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import Filter from "../../common/Filter";
import TableComponent from "@components/TableComponent";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilterCauHinhKyBK } from "./list-bo-loc";
import { Container } from "./css-styled";
import { handleListDataCauHinhKBK, submitThemMoiCauHinhKBK, submitXoaCauHinhKBK, submitUpdateCauHinhKBK, getListLoaiBK } from "./services";
import CheckPermission from "@components/CheckPermission";
import { ghi_chi_so } from "@permissions/d_gcs";
import { Form, Row, Col, Input, Checkbox, Button, Collapse, Select } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
const { Panel } = Collapse;
const { Option } = Select;


export default function Index() {
  const { t } = useTranslation();
  const { user } = useSelector(authSelector)
  const [form] = Form.useForm();
  const [dataCauHinhKBK, setDataCauHinhKBK] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ghiChu, setGhiChu] = useState();
  const [check, setCheck] = useState(false);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [bangKe, setBangKe] = useState();
  const [loaiBK, setLoaiBK] = useState([]);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [editingKey, setEditingKey] = useState('');
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    DonViId: user.unitId,
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu
  const getListDataCauHinhKBK = useCallback(() => {
    handleListDataCauHinhKBK(
      setLoading,
      location,
      setDataCauHinhKBK,
      setTotalCount,
      filterConditions
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getListLoaiBK(setLoading, setLoaiBK);
  }, []);

  useEffect(() => {
    getListDataCauHinhKBK();
  }, [getListDataCauHinhKBK]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListDataCauHinhKBK();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListDataCauHinhKBK, setFetchDataTable]);
  //pagination table
  const onChangePagination = (paging, _filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //tìm kiếm dữ liệu
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
    });
  }, [user.unitId]);

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ghiChu: '',
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (id) => {
    const row = await form.validateFields();
    submitUpdateCauHinhKBK(id, row, setLoading, t, setFetchDataTable, setEditingKey)
  };

  const EditableCell = ({
    editing,
    dataIndex,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
          >
            <Input />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  const columns = [
    {
      title: t("cau_hinh_kbk.don_vi"),
      dataIndex: "tenDonVi",
      key: "tenDonVi",
      render: (text) => (
        <div>
          <span>{text}</span>
        </div>
      ),
      width: "20%",
      sorter: true,
    },
    {
      title: t("cau_hinh_kbk.ma_bang_ke"),
      dataIndex: "maBangKe",
      key: "maBangKe",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
      sorter: true,
    },
    {
      title: t("cau_hinh_kbk.ten_bang_ke"),
      dataIndex: "tenBangKe",
      key: "tenBangKe",
      render: (text) => (
        <span>{text}</span>
      ),
      width: "15%",
      sorter: true,
    },
    {
      title: t("cau_hinh_kbk.ghi_chu"),
      dataIndex: "ghiChu",
      key: "ghiChu",
      render: (text) => <span>{text}</span>,
      width: "30%",
      editable: true,
    },
    {
      title: t("cau_hinh_kbk.tac_vu"),
      dataIndex: "",
      key: "tacVu",
      className: "text-center",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <CheckPermission permissionCode={ghi_chi_so.d72lu}>
              <Button type="primary" onClick={() => save(record.id)}
                style={{
                  marginRight: 10,
                }}
              >
                Lưu
              </Button>
            </CheckPermission>
            <Button onClick={cancel}>Bỏ qua</Button>
          </span>
        ) : (
          <span>
            <CheckPermission permissionCode={ghi_chi_so.d72s}>
              <Button type="primary" disabled={editingKey !== ''} onClick={() => edit(record)}
                style={{
                  marginRight: 8,
                }}>
                Sửa
              </Button>
            </CheckPermission>
            <CheckPermission permissionCode={ghi_chi_so.d72x}>
              <Button
                type="danger"
                onClick={() => handleXoaCauHinhKBK(record.id)}
              >
                Xóa
              </Button>
            </CheckPermission>
          </span>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        key: col.key,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const handleXoaCauHinhKBK = useCallback(
    (id) => {
      submitXoaCauHinhKBK(id, setLoading, setFetchDataTable, t);
    },
    [t]
  );

  const changeBK = (value) => {
    setBangKe(value)
  };
  const changeGhiChu = (e) => {
    setGhiChu(e.target.value)
  };

  const changeCheckbox = (e) => {
    setCheck(e.target.checked)
  };

  const handleThem = useCallback(() => {
    submitThemMoiCauHinhKBK(bangKe, ghiChu, check, t, setLoading, setFetchDataTable, user.unitId)
  }, [bangKe, check, ghiChu, t, user.unitId]);
  return (
    <Fragment>
      <Filter>
        <ListFilterCauHinhKyBK
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          loaiBK={loaiBK}
        />
      </Filter>

      {loaiBK && loaiBK.length > 0 ?
        <Container style={{ paddingLeft: "20px", paddingRight: "20px" }}>
          <Collapse
            defaultActiveKey={["1"]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined
                style={{ fontSize: 18 }}
                rotate={isActive ? 90 : 0}
              />
            )}
            style={{ marginBottom: 15 }}
          >
            <Panel
              header={"Đăng ký ký bảng kê"}
              collapsible={"header"}
              key="1"
            >
              <Row gutter={24} justify="space-between" align="middle">
                <Col span={24} md={8}>
                  <Form.Item label="Loại bảng kê" style={{ margin: 0 }}>
                    <Select onChange={changeBK} allowClear>
                      {(loaiBK && loaiBK.length > 0)
                        ? loaiBK.map((item, index) => {
                          if (item.id !== null) {
                            return (
                              <Option key={index} value={item.id}>
                                {item.tenLoaiBangKe}
                              </Option>
                            );
                          }
                          return null;
                        })
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} md={10}>
                  <Form.Item label="Ghi chú" style={{ margin: 0 }}>
                    <Input onChange={changeGhiChu}></Input>
                  </Form.Item>
                </Col>
                <Col span={24} md={3}>
                  <Checkbox onChange={changeCheckbox}>Ký số</Checkbox>
                </Col>
                <Col span={24} md={3}>
                  <CheckPermission permissionCode={ghi_chi_so.d72tm}>
                    <Button type="primary" onClick={() => handleThem()} form="filter-form" disabled={bangKe === undefined}>
                      Thêm
                    </Button>
                  </CheckPermission>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Container>
        : <Container></Container>
      }
      <Container>
        <Form form={form} component={false}>
          <TableComponent
            header={t("cau_hinh_kbk.cau_hinh_kbk")}
            dataSource={dataCauHinhKBK}
            columns={customColumn(mergedColumns, filterConditions)}
            totalData={totalCount}
            loading={loading}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
          />
        </Form>
      </Container>
    </Fragment>
  );
}
