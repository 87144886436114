import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_ENGLISH,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  formatDateWithTimezone,
  parseParams,
} from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  Spin,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { selectBCDOISOATEP } from "./service";

export default function Bieu2ChiTietSL(props) {
  const [loading, setLoading] = useState(false);
  const [Donvi, setDonvi] = useState();
  const location = useLocation();
  const [form] = Form.useForm();
  const [dataTable, setDataTable] = useState([]);
  const { screen, handleChange } = props;
  const [filterConditions, setFilterConditions] = useState({
    ...parseParams(location.search),
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
    DenNgay: moment().format(FORMAT_ENGLISH),
  });
  const onFinish = (values) => {
    console.log("values", values);
    const queryString = buildQueryString(
      parseParams({
        maDonVi: values?.unitId,
        TuNgay: filterConditions.TuNgay,
        DenNgay: filterConditions.DenNgay,
      })
    );
    authGetData({
      url: `${Endpoint.BCBEP_CT_TIN_NHAN}?${queryString}`,
      setLoading,
      onSuccess: (res) => {
        if (res) {
          console.log(res);
          setDataTable(res.data);
        }
      },
    });
  };

  const handleToDoi = (e) => {
    setDonvi(e);
  };

  const onDownloadExcel = () => {
    const queryString = buildQueryString(
      parseParams({
        maDonVi: Donvi,
        TuNgay: filterConditions?.TuNgay,
        DenNgay: filterConditions?.DenNgay,
      })
    );
    const endpoint = `${Endpoint.BCBEP_CT_TIN_NHAN_EXCEL}?${queryString}`;
    if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
  };

  const columns = [
    {
      title: "STT",
      align: "center",
      width: 50,
      dataIndex: "RN",
      key: "RN",
      render: (text, record, index) => {
        return (
          <>
            <span key={index}>{index + 1}</span>
          </>
        );
      },
    },
    {
      title: "ID SMS",
      align: "center",
      width: 150,
      dataIndex: "IDSMS",
      key: "IDSMS",
    },
    {
      title: "Mã khách hàng",
      align: "center",
      width: 150,
      dataIndex: "MA_KHANG",
      key: "MA_KHANG",
    },
    {
      title: "Số điện thoại",
      align: "center",
      width: 150,
      dataIndex: "DTHOAI",
      key: "DTHOAI",
    },
    {
      title: "Nội dung",
      width: 150,
      align: "center",
      dataIndex: "NOI_DUNG",
      key: "NOI_DUNG",
    },
    {
      title: "Thời gian thực hiện",
      width: 150,
      dataIndex: "TGIAN_TH",
      align: "center",
      key: "TGIAN_TH",
    },
    {
      title: "Tình trạng",
      width: 150,
      dataIndex: "TINHTRANG",
      key: "TINHTRANG",
      align: "center",
    },
    {
      title: "Nhà mạng",
      width: 150,
      dataIndex: "NHA_MANG",
      key: "NHA_MANG",
      align: "center",
    },
    {
      title: "Loại tin nhắn",
      width: 150,
      dataIndex: "LOAI_TNHAN",
      key: "LOAI_TNHAN",
      align: "center",
    },
    {
      title: "Số lượng tin nhắn",
      width: 150,
      dataIndex: "SL_SMS",
      key: "SL_SMS",
      align: "center",
    },
  ];

  const handleStartDate = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      TuNgay: formatDateWithTimezone(e?._d),
    }));
  };

  const handleEndDate = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      DenNgay: formatDateWithTimezone(e?._d),
    }));
  };

  return (
    <Container>
      <ContentWrapper>
        <Spin spinning={loading}>
          <FormComponent
            onFinish={onFinish}
            initialValues={{ reportType: screen }}
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
          >
            <Row>
              <Col span={22}>
                <Form.Item
                  name="reportType"
                  label="Tên báo cáo"
                  labelAlign="left"
                >
                  <Select
                    placeholder="Tất cả"
                    options={selectBCDOISOATEP}
                    onChange={(e) => handleChange(e)}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            <Row></Row>
            <Col span={22}>
              <Form.Item name="unitId" label="Đơn vị" labelAlign="left">
                <Selection
                  url={Endpoint.GET_TEAM_LIST_CODE}
                  placeholder="Tất cả"
                  formKey="unitId"
                  form={form}
                  setValue={handleToDoi}
                />
              </Form.Item>
            </Col>
            <Row>
              <Col span={8}>
                <Form.Item
                  name="TuNgay"
                  label="Ngày bắt đầu"
                  labelAlign="left"
                  labelCol={{ span: 11 }}
                  wrapperCol={{ span: 10 }}
                >
                  <DatePicker
                    picker="Day"
                    formKey="TuNgay"
                    defaultValue={
                      filterConditions.TuNgay
                        ? moment(filterConditions.TuNgay, FORMAT_ENGLISH)
                        : moment().startOf("month").format(FORMAT_ENGLISH)
                    }
                    form={form}
                    format="DD/MM/YYYY"
                    onChange={handleStartDate}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="DenNgay"
                  label="Ngày kết thức"
                  labelAlign="right"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 10 }}
                >
                  <DatePicker
                    picker="Day"
                    formKey="DenNgay"
                    defaultValue={
                      filterConditions.DenNgay
                        ? moment(filterConditions.DenNgay, FORMAT_ENGLISH)
                        : moment()
                    }
                    form={form}
                    format="DD/MM/YYYY"
                    onChange={handleEndDate}
                  />
                </Form.Item>
              </Col>

              <Col span={8} style={{ paddingLeft: 40 }}>
                {" "}
                <Button type="primary" htmlType="submit" className="mr-5px">
                  Xem báo cáo
                </Button>
                <Button type="primary" onClick={onDownloadExcel}>
                  Xuất Excel
                </Button>
              </Col>
            </Row>
            <Row justify="end"></Row>
          </FormComponent>
          <Divider className="section-text-line" orientation="left"></Divider>
          {dataTable && dataTable?.length > 0 ? (
            <TableComponent
              header={"Kết quả hiển thị"}
              columns={columns}
              dataSource={dataTable}
              scrollX={1200}
            />
          ) : (
            <TableComponent
              header={"Kết quả hiển thị"}
              columns={columns}
              dataSource={null}
              scrollX={1200}
            />
          )}
        </Spin>
      </ContentWrapper>
    </Container>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
