import { Fragment, useState } from "react";
import DanhSachHoSo from "../../common-ho-so";
import { Button, Dropdown, Form } from "antd";
import { MenuOutlined, MoreOutlined } from "@ant-design/icons";
import ListChucNang from "./list-chuc-nang";
import { getParamsUrlHoSo } from "@utils/function";
import { ENUM_FUNCTION } from "@utils/constants";
import ThongTinChiTietTabs from "../../common-ho-so/chi-tiet-ho-so";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "@state/system-config/reducer";
import { ListFilter } from "../../common-ho-so/list-bo-loc";
import { Grid } from "antd";
const { useBreakpoint } = Grid;

export default function Index() {
  const dispatch = useDispatch();
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [isDoubleClick, setIsDoubleClick] = useState(true);
  const [form] = Form.useForm();
  const [rowId, setRowId] = useState(null);
  const screens = useBreakpoint();
  const mobile = screens.xs && !screens.md;

  const firstColumns = {
    title: "",
    key: "",
    dataIndex: "",
    width: mobile ? "1.5%" : "3%",
    render: (row) => (
      <div
        style={{ textAlign: "center" }}
        onClick={() => setRowId(row?.id ?? row?.hoSoId)}
      >
        <Dropdown
          overlay={
            <div
              className="list-function"
              style={{
                boxShadow:
                  " 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
              }}
            >
              <ListChucNang
                detail={row}
                setFetchDataTable={setFetchDataTable}
                setIsDoubleClick={setIsDoubleClick}
              />
            </div>
          }
          trigger={["click"]}
        >
          {mobile ? (
            <MoreOutlined />
          ) : (
            <Button type="primary" icon={<MenuOutlined />}></Button>
          )}
        </Dropdown>
      </div>
    ),
  };

  const detailHoSo = (record) => {
    if (isDoubleClick)
      return {
        onDoubleClick: () => {
          dispatch(
            openModal({
              content: (
                <ThongTinChiTietTabs
                  detailHoSo={record}
                  closeModal={closeModal}
                />
              ),
              size: "large",
              title: `${
                record.maYCKNCmis !== null ? record.maYCKNCmis + " - " : ""
              }${record.tenKhachHang !== null ? record.tenKhachHang : ""}`,
            })
          );
        },
      };
  };

  return (
    <Fragment>
      <DanhSachHoSo
        firstColumns={firstColumns}
        onRow={detailHoSo}
        paramsUrl={getParamsUrlHoSo(ENUM_FUNCTION.LAPQUYETTOANCONGTY)}
        funcID={ENUM_FUNCTION.LAPQUYETTOANCONGTY}
        listFilter={<ListFilter form={form} />}
        setFetchDataTable={setFetchDataTable}
        fetchDataTableIfNeed={fetchDataTableIfNeed}
        rowId={rowId}
      />
    </Fragment>
  );
}
