import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, authGetMultiplesRequest } from "@utils/request";
import { ENUM_DS_BANGKE } from "@utils/constants";
// call api get list data

export const handleListData = (
  filterConditions,
  setLoading,
  setDataSource,
  setTotalCount,
  location
) => {
  let url = "";
  url = `${Endpoint.LIST_BANGKE}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataSource(res.data);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};
// call api get multilist dropdown

export const handleMultiDS = (
  setLoading,
  setTrangThai,
  setLoaiBangKe,
  typeDS,
  setFirstTrangThai
) => {
  const endpoints = [
    `${Endpoint.LIST_TINHTRANG_BK}?MaLoaiDanhSach=${typeDS}`,
    `${Endpoint.LIST_LOAI_BANGKE}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setTrangThai(res[0].data.data);
      if (typeDS === ENUM_DS_BANGKE.TRACUU) {
        setFirstTrangThai("");
      } else {
        setFirstTrangThai(res[0].data.data[0].value);
      }
      setLoaiBangKe(res[1].data.data);
    },
  });
};
// call api in pdf

export function inPDF(bangKeId, onComplete, setLoading) {
  authGetData({
    url: Endpoint.PDF_BANGKE + "?Id=" + bangKeId,
    setLoading: setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        onComplete(res.data);
      } else {
        onComplete("error");
      }
    },
  });
}
