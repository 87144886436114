import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useEffect
} from "react";
import { useTranslation } from "react-i18next";
import Filter from "../../common/Filter";
import { TableStyled, Container } from "./css-styled";
import { Tag, Form, Input, Button, Row, Col, Modal } from "antd";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilterCapNhatChiSo } from "./list-bo-loc";
import TableComponent from "@components/TableComponent";
import { handleListData, submitCapNhatMDMS, submitchuyenHHU, submitchuyenMTB } from "./services";
import CheckPermission from "@components/CheckPermission";
import { ghi_chi_so } from "@permissions/d_gcs";
import moment from "moment";
import { ENUM_GCSTRANGTHAISO, ENUM_KY_GCS, DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";

export default function Index() {
  const { t } = useTranslation();
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [dataCapNhatEVNHES, setDataCapNhatEVNHES] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const [tempDataCapNhatEVNHES, setTempDataCapNhatEVNHES] = useState([]);
  const [tempTotalCount, setTempTotalCount] = useState(0);
  const [term, setTerm] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    ...parseParams(location.search),
    Ky: ENUM_KY_GCS.KY_1,
    MaTrangThai: ENUM_GCSTRANGTHAISO.DA_PHAN_CONG,
    NgayGhi: moment().format("DD"),
    Thang: moment().format("MM"),
    Nam: moment().format("YYYY"),
    orderBy: "maSoGCS"
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setSelectedRowKeys([]);
  };

  const changeTerm = (value) => {
    setTerm(value.target.value)
  };

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
      MaSo: values.MaSo === undefined ? values.MaSo : values.MaSo.trim(),
      TenSo: values.TenSo === undefined ? values.TenSo : values.TenSo.trim(),
      NgayGhi: (values.Ngay === undefined || values.Ngay === null) ? values.Ngay : values?.Ngay?.substring(8, 10),
      Thang: (values.Ngay === undefined || values.Ngay === null) ? values.Ngay : values?.Ngay?.substring(5, 7),
      Nam: (values.Ngay === undefined || values.Ngay === null) ? values.Ngay : values?.Ngay?.substring(0, 4),
    }));
  }, []);

  const SearchTerm = useCallback(() => {
    if (term.trim().length > 0) {
      let listTerm = [];
      listTerm = tempDataCapNhatEVNHES.filter(data =>
        data.maSoGCS.toUpperCase().indexOf(term.trim().toUpperCase()) > -1 ||
        data.tenSoGCS.toUpperCase().indexOf(term.trim().toUpperCase()) > -1);
      setDataCapNhatEVNHES(listTerm)
      setTotalCount(listTerm.length)
    } else {
      setDataCapNhatEVNHES(tempDataCapNhatEVNHES);
      setTotalCount(tempTotalCount)
    }
  }, [tempDataCapNhatEVNHES, tempTotalCount, term]);

  const getListData = useCallback(() => {
    handleListData(
      location,
      filterConditions,
      setLoading,
      setDataCapNhatEVNHES,
      setTempDataCapNhatEVNHES,
      setTotalCount,
      setTempTotalCount
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      Ky: ENUM_KY_GCS.KY_1,
      MaTrangThai: ENUM_GCSTRANGTHAISO.DA_PHAN_CONG,
      NgayGhi: moment().format("DD"),
      Thang: moment().format("MM"),
      Nam: moment().format("YYYY"),
      orderBy: "maSoGCS"
    });
  }, [user.unitId]);

  useEffect(() => {
    getListData();
  }, [getListData, user.unitId]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListData();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListData, setFetchDataTable]);
  const columns = [
    {
      title: t("phan_cong.ma_so"),
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      render: (text) => <span>{text}</span>,
      width: "8%",
      sorter: true,
      fixed: "left"
    },
    {
      title: t("phan_cong.ten_so"),
      dataIndex: "tenSoGCS",
      key: "tenSoGCS",
      render: (text) => <span>{text}</span>,
      width: "15%",
      sorter: true,
    },
    {
      title: t("phan_cong.tinh_trang"),
      dataIndex: "tinhTrang",
      key: "tinhTrang",
      render: (text, record) => (
        record.maTrangThai === ENUM_GCSTRANGTHAISO.DA_CAP_NHAT_DU_MDMS.toString() ||
          record.maTrangThai === ENUM_GCSTRANGTHAISO.HHU_DA_GUI_DU_DL.toString() ||
          record.maTrangThai === ENUM_GCSTRANGTHAISO.MTB_DA_GUI_DU_LIEU.toString() ||
          record.maTrangThai === ENUM_GCSTRANGTHAISO.DANG_XAC_NHAN_CAC_CAP.toString() ?
          <Tag style={{ display: "block", textAlign: "center" }} color={"#26c0e9"}>Đủ chỉ số {record.soLuongChiSoDaDo === null
            || record.soLuongChiSoDaDo === undefined
            ? 0 :
            record.soLuongChiSoDaDo}/{record.soLuongChiSo}</Tag> :
          <Tag style={{ display: "block", textAlign: "center" }} color={"#faad14"}>Đã lấy được {record.soLuongChiSoDaDo === null
            || record.soLuongChiSoDaDo === undefined
            ? 0 :
            record.soLuongChiSoDaDo}/{record.soLuongChiSo}</Tag>
      ),
      width: "9%",
    },
    {
      title: t("phan_cong.trang_thai"),
      dataIndex: "tenTrangThai",
      key: "tenTrangThai",
      className: "text-center",
      render: (text, record) => (
        record.maTrangThai === ENUM_GCSTRANGTHAISO.DA_PHAN_CONG.toString() ?
          <Tag color={"#175bd1"}>Chưa thực hiện</Tag> :
          record.maTrangThai === ENUM_GCSTRANGTHAISO.DANG_CAP_NHAT_MDMS.toString() ||
            // record.maTrangThai === ENUM_GCSTRANGTHAISO.XUAT_GHI_LAI_BANG_MTB.toString() ||
            record.maTrangThai === ENUM_GCSTRANGTHAISO.XUAT_GHI_LAI_BANG_MDMS.toString() ?
            <Tag color={"#c91a34"}>Đang thực hiện</Tag> :
            record.maTrangThai === ENUM_GCSTRANGTHAISO.KET_THUC_MDMS_CHUYEN_MTB_HHU.toString() ?
              <Tag color={"#9516f0"}>Chờ chuyển HHU/MTB</Tag> :
              record.maTrangThai === ENUM_GCSTRANGTHAISO.HHU_DA_LAY_DU_LIEU.toString() ?
                <Tag color={"#e060a5"}>Đã chuyển HHU</Tag> :
                record.maTrangThai === ENUM_GCSTRANGTHAISO.CHUYEN_LAY_DL_BANG_MTB.toString() ||
                record.maTrangThai === ENUM_GCSTRANGTHAISO.XUAT_GHI_LAI_BANG_MTB.toString() ||
                  record.maTrangThai === ENUM_GCSTRANGTHAISO.DA_LAY_SO_VE_MTB.toString() ?
                  <Tag color={"#59e71f"}>Đã chuyển MTB</Tag> :
                  <Tag color={"#0aad98"}>Đã đủ chỉ số</Tag>
      ),
      width: "11%",
    },
    {
      title: t("phan_cong.hinh_thuc_gcs"),
      dataIndex: "hinhThuc",
      key: "hinhThuc",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "9%",
    },
    {
      title: t("phan_cong.ky_gcs"),
      dataIndex: "ky",
      key: "ky",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
      sorter: true,
    },
    {
      title: t("phan_cong.ngay_ghi"),
      dataIndex: "ngayGhi",
      key: "ngayGhi",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: t("phan_cong.thang_nam"),
      dataIndex: "thangNam",
      key: "thangNam",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: t("phan_cong.to_doi"),
      dataIndex: "tenToDoi",
      key: "tenToDoi",
      render: (text) => <span>{text}</span>,
      width: "12%",
    },
    {
      title: t("phan_cong.nhan_vien_gcs"),
      dataIndex: "tenNhanVien",
      key: "tenNhanVien",
      render: (text) => <span>{text}</span>,
    },
  ];

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: "2%",
    fixed: "left",
    getCheckboxProps: (record) => ({
      disabled: record.maTrangThai !== ENUM_GCSTRANGTHAISO.DA_PHAN_CONG.toString()
        && record.maTrangThai !== ENUM_GCSTRANGTHAISO.KET_THUC_MDMS_CHUYEN_MTB_HHU.toString()
        && record.maTrangThai !== ENUM_GCSTRANGTHAISO.HHU_DA_LAY_DU_LIEU.toString()
        && record.maTrangThai !== ENUM_GCSTRANGTHAISO.CHUYEN_LAY_DL_BANG_MTB.toString()
        && record.maTrangThai !== ENUM_GCSTRANGTHAISO.DA_LAY_SO_VE_MTB.toString()
        && record.maTrangThai !== ENUM_GCSTRANGTHAISO.DANG_CAP_NHAT_MDMS.toString()
        && record.maTrangThai !== ENUM_GCSTRANGTHAISO.XUAT_GHI_LAI_BANG_MDMS.toString()
        // && record.maTrangThai !== ENUM_GCSTRANGTHAISO.XUAT_GHI_LAI_BANG_MTB.toString()
    }),
  };

  // Cập nhật chỉ số MDMS
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCapNhatMDMS = () => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: `Thực hiện lấy dữ liệu đo xa cho các sổ GCS có trạng thái [Chưa thực hiện]. 
              Bạn đồng ý lấy dữ liệu đo xa của các sổ GCS đã chọn ?`,
      onOk() {
        //call api
        submitCapNhatMDMS(t, setLoading, setFilterConditions, selectedRowKeys, setSelectedRowKeys);
      },
      onCancel() { },
      okText: "Đồng ý",
      cancelText: "Hủy",
    });
  };

  // Chuyển HHU
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChuyenHHU = () => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: `Bạn đồng ý chuyển lấy dữ liệu bằng HHU cho các sổ GCS đã chọn ?`,
      onOk() {
        //call api
        submitchuyenHHU(t, setLoading, setFilterConditions, selectedRowKeys, setSelectedRowKeys);
      },
      onCancel() { },
      okText: "Đồng ý",
      cancelText: "Hủy",
    });
  };
  // Chuyển HHU
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChuyenMTB = () => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: `Bạn đồng ý chuyển lấy dữ liệu bằng MTB cho các sổ GCS đã chọn?`,
      onOk() {
        //call api
        submitchuyenMTB(t, setLoading, setFilterConditions, selectedRowKeys, setSelectedRowKeys);
      },
      onCancel() { },
      okText: "Đồng ý",
      cancelText: "Hủy",
    });
  };

  return (
    <Fragment>
      <Filter>
        <ListFilterCapNhatChiSo
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
        />
      </Filter>
      <Container>
        <Row style={{ marginBottom: "-33px", marginLeft: "10px" }}>
          <span style={{ marginTop: "6px" }}>Danh sách sổ GCS: </span>
          <Col span={24} md={8}>
            <Input
              placeholder="Nhập mã sổ, tên sổ"
              style={{ marginLeft: 20, marginBottom: 35, width: 300 }}
              onChange={changeTerm}
              onPressEnter={() => SearchTerm()}
            />
          </Col>
          <Col style={{ marginLeft: "auto" }}>
            <CheckPermission permissionCode={ghi_chi_so.d21u}>
              <Button
                type="primary"
                style={{ marginRight: 15 }}
                disabled={selectedRowKeys.length === 0 ? true : false}
                onClick={() => handleCapNhatMDMS()}
                loading={loading}
              >
                Cập nhật chỉ số đo xa
              </Button>
            </CheckPermission>
            <CheckPermission permissionCode={ghi_chi_so.d21ch}>
              <Button
                type="primary"
                style={{ marginRight: 15 }}
                disabled={selectedRowKeys.length === 0 ? true : false}
                onClick={() => handleChuyenHHU()}
                loading={loading}
              >
                Chuyển HHU
              </Button>
            </CheckPermission>
            <CheckPermission permissionCode={ghi_chi_so.d21cu}>
              <Button
                type="primary"
                style={{ marginRight: 10 }}
                disabled={selectedRowKeys.length === 0 ? true : false}
                onClick={() => handleChuyenMTB()}
                loading={loading}
              >
                Chuyển MTB
              </Button>
            </CheckPermission>
          </Col>
        </Row>
        <TableStyled>
          <TableComponent
            header={"Cập nhập chỉ số từ EVN-HES"}
            dataSource={dataCapNhatEVNHES}
            handleSearch={handleSearch}
            columns={customColumn(columns, filterConditions)}
            listFilter={<ListFilterCapNhatChiSo form={form} />}
            totalData={totalCount}
            loading={loading}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            scrollX={1700}
            rowSelection={rowSelection}
            rowKey="id"
          />
        </TableStyled>
      </Container>
    </Fragment>
  );
}