import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { TABLE_THOA_KIEM_TRA_DIEU_KIEN } from "@modules/trung-ap/common/constant";
import FilterCommon from "@modules/trung-ap/common/filter";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  buildQueryString,
  handlePagination,
  parseParams,
  // removeUndefinedAttribute,
} from "@utils/function";
import { Form } from "antd";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { getDanhSachKiemTra } from "./services";
import TienDoThucHien from "@modules/trung-ap/common/tien-do-thuc-hien/modal-tien-do-thuc-hien";
import { closeModal, openModal } from "@state/system-config/reducer";

export default function KiemTraDieuKienDongDien() {
  // initialState for calling data
  const location = useLocation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const maDonVi = useSelector((state) => state.auth.user.unitCode);
  const initialState = useMemo(() => {
    return {
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      denNgay: `${moment().format("YYYY-MM-DD")}`,
      tuNgay: `${moment().subtract(7, "d").format("YYYY-MM-DD")}`,
      maDonVi: maDonVi,
      status: -1,
    };
    // eslint-disable-next-line no-use-before-define
  }, [maDonVi]);

  //Table state
  const [loading, setLoading] = useState(false);
  const [isDoubleClick] = useState(true);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [, setSearchParams] = useSearchParams();
  const [filterConditions, setFilterConditions] = useState({
    ...initialState,
    ...parseParams(location.search),
  });

  const listTrangThai = [
    {
      name: "Chưa tiếp nhận",
      value: 0,
    },
    {
      name: "Tiếp nhận",
      value: 1,
    },
    {
      name: "Phân công khảo sát",
      value: 2,
    },
    {
      name: "Kết quả khảo sát",
      value: 3,
    },
    {
      name: "Biên bản kiểm tra",
      value: 4,
    },
    {
      name: "Dự thảo hợp đồng",
      value: 5,
    },
    {
      name: "Phân công treo tháo",
      value: 6,
    },
    {
      name: "Kết quả treo tháo",
      value: 7,
    },
    {
      name: "Biên bản treo tháo",
      value: 8,
    },
    {
      name: "Biên bản nghiệm thu",
      value: 9,
    },
    {
      name: "Hoàn thành",
      value: 10,
    },
    {
      name: "Bị trả lại",
      value: 13,
    },
  ];

  const removeUndefinedAttribute = (obj) => {
    const params = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== undefined) {
        params[key] = obj[key];
      }
      return {};
    });
    return params;
  };

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const handleSearch = useCallback((values) => {
    const payload = {
      ...values,
      tuNgay: moment(values.tuNgay).format("YYYY-MM-DD"),
      denNgay: moment(values.denNgay).format("YYYY-MM-DD"),
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      status: values.status === undefined ? -1 : values.status,
    };

    setFilterConditions(payload);
  }, []);
  const clearFilter = useCallback(() => {
    const payload = {
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      denNgay: `${moment().format("YYYY-MM-DD")}`,
      tuNgay: `${moment().subtract(7, "d").format("YYYY-MM-DD")}`,
      maDonVi: maDonVi,
      status: -1,
    };
    setFilterConditions(payload);
  }, [maDonVi]);

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const getData = useCallback(() => {
    const current_query = filterConditions;

    const updated_query = {
      ...current_query,
      pageSize: filterConditions.pageSize,
      pageIndex: filterConditions.pageIndex,
    };
    const query = buildQueryString(updated_query);

    getDanhSachKiemTra(setLoading, setData, query, filterConditions, setTotal);
  }, [filterConditions]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions]);

  const detailHoSo = (record) => {
    if (isDoubleClick)
      return {
        onDoubleClick: () => {
          dispatch(
            openModal({
              content: (
                <TienDoThucHien detailHoSo={record} closeModal={closeModal} />
              ),
              size: "large",
              title: <>Chi tiết yêu cầu</>,
            })
          );
        },
      };
  };

  return (
    <Style>
      <div className="thoa-thuan-dau-noi">
        <Filter>
          <FilterCommon
            form={form}
            listTrangThai={listTrangThai}
            handleSearch={handleSearch}
            clearFilter={clearFilter}
            filterConditions={filterConditions}
            maYeuCau={true}
          />
        </Filter>
        <div className="thoa-thuan-dau-noi-content">
          <TableComponent
            header={"Kết quả hiển thị"}
            columns={TABLE_THOA_KIEM_TRA_DIEU_KIEN}
            dataSource={data}
            pagination={filterConditions}
            onChangePagination={onChangePagination}
            loading={loading}
            totalData={total}
            rowKey="key"
            onRow={detailHoSo}
          />
        </div>
      </div>
    </Style>
  );
}

const Style = styled.div`
  .thoa-thuan-dau-noi-content {
    padding: 20px;
    .ant-table-row:hover {
      cursor: pointer;
    }
  }

  .ant-table-thead th.ant-table-cell {
    text-align: center !important;
  }
`;
