import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData } from "@utils/request";

export const getDataB9_DoiSoat = (
  filterConditions,
  setLoading,
  setDataB9,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.B9_DOI_SOAT_NGAY_THEO_DON_VI}?${buildQueryString(
      parseParams(filterConditions)
    )}`,

    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataB9(res.data);
        setTotalCount(res.data.paging);
      }
    },
  });
};
