import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import DonViModal from "./modal-don-vi";
import {} from "@utils/request";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import { useSelector } from "react-redux";
import { tien_ich_chung } from "@permissions/m_tienichchung";
import CheckPermission from "@components/CheckPermission";
import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  TABINDEX_CMIS,
} from "@utils/constants";
import {
  handleListData,
  handleMultileListData,
  handleRemoveData,
  handleListApGiaUyQuyen
} from "./services";
import Table from "./table";
import EllipsisTooltip from "@components/EllipsisTooltip";
import { Endpoint } from "@utils/endpoint";
import { dongBoCMIS } from "../services";

export default function Index() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [detailDonVi, setDetailDonVi] = useState({});
  const [loading, setLoading] = useState(true);
  const [dataDonVi, setDataDonVi] = useState([]);
  const [dataNganHang, setDataNganHang] = useState([]);
  const [dataApGiaUyQuyen, setDataApGiaUyQuyen] = useState([]);
  const [dataDiaChinh, setDataDiaChinh] = useState([]);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const { user } = useSelector(authSelector);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu

  const getListData = useCallback(() => {
    handleListData(setLoading, filterConditions, setDataDonVi, setTotalCount);
  }, [filterConditions]);

  useEffect(() => {
    getListData();
    // eslint-disable-next-line
  }, [getListData]);

  //thay đổi pagination table

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };
  //hiển thị các button thêm,...

  const genExtra = () => (
    <div>
      <CheckPermission permissionCode={tien_ich_chung.m12t}>
        {user.isTongCongTy ? (
          <Button
            size="small"
            type="primary"
            className="mr-5px"
            onClick={() => handleOpenModal()}
          >
            {t("button.tao_moi")}
          </Button>
        ) : null}
      </CheckPermission>
      <CheckPermission permissionCode={tien_ich_chung.m12cmis}>
        <Button
          size="small"
          type="primary"
          className="btn-bg-yellow"
          onClick={handleDongBoCMIS}
          loading={loading}
        >
          Đồng bộ CMIS
        </Button>
      </CheckPermission>
    </div>
  );

  const handleDongBoCMIS = useCallback(() => {
    const url = Endpoint.DONG_BO_CMIS + `?tabIndex=${TABINDEX_CMIS.DVI_QLY}`;
    dongBoCMIS(url, setLoading, getListData);
  }, [getListData]);

  // const handleSync = () => {
  //   alertMessage("warning", t("modal.thong_bao"), t("message.dang_phat_trien"));
  // };
  //khai bái columns

  const columns = [
    {
      title: t("don_vi.ma_don_vi"),
      dataIndex: "maDonVi",
      key: "maDonVi",
      width: "10%",
      sorter: true,
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: t("don_vi.ten_don_vi"),
      dataIndex: "tenDonVi",
      key: "tenDonVi",
      width: "20%",
      sorter: true,
      render: (text) => <EllipsisTooltip title={text}>{text}</EllipsisTooltip>,
    },
    {
      title: "Mã định danh",
      dataIndex: "maDinhDanh",
      key: "maDinhDanh",
      width: "10%",
      sorter: true,
      render: (text) => <EllipsisTooltip title={text}>{text}</EllipsisTooltip>,
    },
    {
      title: t("don_vi.dia_chi"),
      dataIndex: "diaChi",
      key: "diaChi",
      width: "30%",
      sorter: true,
      render: (text) => <EllipsisTooltip title={text}>{text}</EllipsisTooltip>,
    },
    {
      title: t("don_vi.ten_loai_don_vi"),
      dataIndex: "tenLoaiDonVi",
      key: "loaiDonVi",
      width: "10%",
      sorter: true,
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: t("don_vi.dien_thoai"),
      dataIndex: "dienThoai",
      key: "dienThoai",
      sorter: true,
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },

    {
      title: t("tac_vu"),
      key: "action",
      // width: "10%",
      render: (row) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={tien_ich_chung.m12s}>
            <div className="action-icon" onClick={() => handleOpenModal(row)}>
              <PencilIcon />
            </div>
          </CheckPermission>
          <CheckPermission permissionCode={tien_ich_chung.m12x}>
            <div
              className="action-icon"
              onClick={() => handleDeleteData(row.id)}
            >
              <TrashIcon />
            </div>
          </CheckPermission>
        </div>
      ),
    },
  ];

  //xóa dữ liệu

  const handleDeleteData = useCallback(
    (ids) => {
      handleRemoveData(ids, setLoading, getListData, t);
    },
    [getListData, t]
  );

  //tìm kiếm dữ liệu

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  //hiển thị popup, set dữ liệu chi tiết

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) {
        handleListApGiaUyQuyen(setLoading, row.id, setDataApGiaUyQuyen);
        setDetailDonVi(row);
      }
      else {
        setDetailDonVi({});
      }
      setVisible(!visible);
    },
    [visible]
  );
  //đóng popup

  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
  };
  //render popup

  const showModal = useMemo(
    () =>
      visible && (
        <DonViModal
          detaiDataTable={detailDonVi}
          visible={visible}
          closeModal={handleCloseModal}
          dataNganHang={dataNganHang}
          dataDiaChinh={dataDiaChinh}
          getListData={getListData}
          isTongCongTy={user.isTongCongTy}
          dataApGiaUyQuyen={dataApGiaUyQuyen}
        />
      ),
    // eslint-disable-next-line
    [visible, dataApGiaUyQuyen]
  );

  useEffect(() => {
    handleMultileListData(setLoading, setDataNganHang, setDataDiaChinh);
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Table
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        genExtra={genExtra}
        dataSource={dataDonVi}
        columns={columns}
        totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        t={t}
      />
      {showModal}
    </Fragment>
  );
}
