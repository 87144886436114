import { Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import UploadImage from "./layout";
import {
  handleGetListImage,
  handleUploadBBTTPDF,
  handleXNBBTTPDF,
} from "./services";

export default function Index(props) {
  const {
    visible,
    closeModal,
    selectedRowKeys,
    setFetchList,
    setIsUploadFile,
    setSelectedRowKeys,
  } = props;
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const [isSubmit] = useState(false);
  const [dataImage, setDataImage] = useState("");
  const [typeFile, setTypeFile] = useState("");
  //upload image
  const handleUploadImage = () => {
    const payload = {
      yeuCauId: selectedRowKeys.toString(),
      fileName: imageFiles[0].name,
      base64Data: imageFiles[0].base64Data.split(";base64,")[1],
    };
    handleUploadBBTTPDF(
      setLoading,
      payload,
      setFetchList,
      closeModal,
      handleListImage,
      setIsUploadFile,
      setImageFiles,
      // setSelectedRowKeys
    );
  };
  const fileToDataUri = (image) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { type, name, size } = image;
      reader.addEventListener("load", () => {
        res({
          base64Data: reader.result,
          name,
          type,
          size,
          upload: true,
        });
      });
      reader.readAsDataURL(image);
    });
  };

  const handleListImage = useCallback(() => {
    handleGetListImage(selectedRowKeys, setLoading, setDataImage, setTypeFile);
  }, [selectedRowKeys]);

  useEffect(() => {
    handleListImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = () => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content:
        "Sau khi xác nhận KH đã ký sẽ không thể thay đổi thông tin biên bản hoặc thay đổi BB giấy đã upload, bạn có chắc chắn muốn tiếp tục?",
      onOk() {
        const payload = {
          yeuCauId: selectedRowKeys.toString(),
        };
        handleXNBBTTPDF(
          setLoading,
          payload,
          setFetchList,
          closeModal,
          setSelectedRowKeys
        );
      },
      onCancel() {},
      okText: "Đồng ý",
      okButtonProps: { type: "primary" },
      cancelText: "Hủy",
    });
  };

  return (
    <UploadImage
      visible={visible}
      closeModal={closeModal}
      handleUploadImage={handleUploadImage}
      loading={loading}
      fileToDataUri={fileToDataUri}
      imageFiles={imageFiles}
      setImageFiles={setImageFiles}
      isSubmit={isSubmit}
      isUpload={isUpload}
      setIsUpload={setIsUpload}
      dataImage={dataImage}
      typeFile={typeFile}
      handleConfirm={handleConfirm}
    />
  );
}
