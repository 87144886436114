import {
  closeFilters,
  filterCapDien,
  openFilters,
} from "@state/cap-dien/system-filter/reducer";
import { KEY_PANEL0, KEY_PANEL1 } from "@utils/constants";
import { Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const { Panel } = Collapse;

export default function Filter(props) {
  const dispatch = useDispatch();
  const { openFilter } = useSelector(filterCapDien);
  const [defaultKey, setDefaultKey] = useState([]);

  const callback = (key) => {
    if (key.length === 0) dispatch(closeFilters());
    else dispatch(openFilters());
  };

  useEffect(() => {
    if (openFilter) setDefaultKey([KEY_PANEL1]);
    else setDefaultKey([KEY_PANEL0]);
  }, [openFilter]);

  return (
    <CollapseContainer
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <FilterText className={isActive ? "active" : ""}>
          <span style={{ fontSize: "14px" }}>Xem toàn bộ kết quả xử lý</span>
        </FilterText>
      )}
      onChange={callback}
      defaultActiveKey={defaultKey}
      activeKey={defaultKey}
    >
      <Panel key={KEY_PANEL1}>{props.children}</Panel>
    </CollapseContainer>
  );
}
const CollapseContainer = styled(Collapse)`
  width: 100%;
  background: #fff;
  border: none;
  border-radius: unset;
  .ant-collapse-header {
    min-height: 28px;
  }
  .active {
    color: #279bef;
  }
  .ant-collapse-content-box {
    padding: 15px 30px;
  }
  .filter-action {
    display: flex;
    justify-content: end;
    align-items: end;
  }
`;
const FilterText = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  > span {
    margin-right: 10px;
  }
`;
