// import KySoComponent from "@components/KySo";
// import { Endpoint } from "@utils/endpoint";
import { Button, Col, Modal, Row } from "antd";
import React from "react";
import DataTable from "./DataTable";

function HoSoNhanSuModal(props) {
  const { visible, handleCloseModal, data, loading, filterConditions } = props;

  const columnsDaoTaoDaiHan = [
    {
      title: "Trường đào tạo",
      dataIndex: "truongDaoTao",
      key: "truongDaoTao",
      width: "15%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Ngành học",
      dataIndex: "nganhHoc",
      key: "nganhHoc",
      width: "15%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Trình độ",
      dataIndex: "trinhDo",
      key: "trinhDo",
      width: "15%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Năm tốt nghiệp",
      dataIndex: "namTotNghiep",
      key: "namTotNghiep",
      width: "15%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Hình thức đào tạo",
      dataIndex: "hinhThucDaoTao",
      key: "hinhThucDaoTao",
      width: "15%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Loại văn bằng",
      dataIndex: "loaiVanBang",
      key: "loaiVanBang",
      width: "15%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "là CMC",
      dataIndex: "laCMC",
      key: "laCMC",
      width: "15%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
  ];
  const columnsBoiDuongNganHan = [
    {
      title: "Thời gian",
      dataIndex: "thoiGian",
      key: "thoiGian",
      width: "15%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Nơi đào tạo",
      dataIndex: "noiDaoTao",
      key: "noiDaoTao",
      width: "15%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Nội dung đào tạo",
      dataIndex: "noiDungDaoTao",
      key: "noiDungDaoTao",
      width: "15%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Xếp loại",
      dataIndex: "xepLoai",
      key: "xepLoai",
      width: "15%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Ngày CC hết hạn",
      dataIndex: "ngayCCHetHan",
      key: "ngayCCHetHan",
      width: "15%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Số giờ ĐT",
      dataIndex: "soGioDT",
      key: "soGioDT",
      width: "15%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Số QĐ",
      dataIndex: "soQD",
      key: "soQD",
      width: "15%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Ngày ký",
      dataIndex: "ngayKy",
      key: "ngayKy",
      width: "15%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
  ];

  return (
    // <LoadingComponent loading={loading}>
    <Modal
      title="Hồ sơ nhân sự"
      visible={visible}
      onCancel={handleCloseModal}
      footer={null}
      maskClosable={false}
    >
      <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
        Yêu cầu sẽ được Lãnh đạo ký số/xác nhận.
      </h4>

      <Row>
        <DataTable
          filterConditions={filterConditions}
          header={"Quá trình đào tạo dài hạn"}
          dataSource={data}
          columns={columnsDaoTaoDaiHan}
          totalCount
          loading={loading}
        />
      </Row>
      <Row>
        <DataTable
          filterConditions={filterConditions}
          header={"Quá trình bồi dưỡng ngắn hạn"}
          dataSource={data}
          columns={columnsBoiDuongNganHan}
          totalCount
          loading={loading}
        />
      </Row>

      <Row gutter={24} justify="center" style={{ marginTop: "20px" }}>
        <Col span={6} style={{ textAlign: "center" }}>
          <Button
            key="back"
            onClick={handleCloseModal}
            className="button-closed"
          >
            Đóng lại
          </Button>
        </Col>
      </Row>
    </Modal>
    // </LoadingComponent>
  );
}

export default HoSoNhanSuModal;
