import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APIBaoCaoCapDien = {
  //BÁO CÁO SỐ PHA
  GET_LIST_BAO_CAO_SO_PHA: `${BASE_API_URL_CAP_DIEN}/bao-cao/bao-cao-tong-hop-so-pha`,
  EXPORT_EXCEL_BAO_CAO_SO_PHA: `${BASE_API_URL_CAP_DIEN}/bao-cao/export-excel-bao-cao-tong-hop-so-pha`,

  //common báo cáo
  HUY_CHOT_BAO_CAO: `${BASE_API_URL_CAP_DIEN}/bao-cao/huy-chot-bao-cao`,
  CHOT_BAO_CAO: `${BASE_API_URL_CAP_DIEN}/bao-cao/chot-bao-cao`,

  //TẠO MỚI KẾ HOẠCH TIẾP NHẬN BÁN ĐIỆN
  GET_LIST_BAO_CAO_TAO_MOI_KE_HOACH: `${BASE_API_URL_CAP_DIEN}/bao-cao/bao-cao-ke-hoach-tiep-nhan`,
  EXPORT_EXCEL_TAO_MOI_KE_HOACH: `${BASE_API_URL_CAP_DIEN}/bao-cao/export-excel-bao-cao-ke-hoach-tiep-nhan`,
  CREATE_KE_HOACH_TIEP_NHAN: `${BASE_API_URL_CAP_DIEN}/bao-cao/create-ke-hoach-tiep-nhan`,
  UPDATE_KE_HOACH_TIEP_NHAN: `${BASE_API_URL_CAP_DIEN}/bao-cao/update-ke-hoach-tiep-nhan`,

  //báo cáo tổng hợp
  GET_LIST_BAO_CAO_TONG_HOP: `${BASE_API_URL_CAP_DIEN}/bao-cao/get-bcth-kq-yccd`,
  CHOT_BAO_CAO_TONG_HOP: `${BASE_API_URL_CAP_DIEN}/bao-cao/chot-so-lieu-bcth-yccd`,
  HUY_CHOT_BAO_CAO_TONG_HOP: `${BASE_API_URL_CAP_DIEN}/bao-cao/huy-chot-so-lieu-bcth-yccd`,
  GET_NEW_DATE_SO_LIEU_BAO_CAO_TONG_HOP: `${BASE_API_URL_CAP_DIEN}/bao-cao/get-ngay-gio-bcth-moi-nhat`,
  TONG_HOP_SO_LIEU_BAO_CAO_TONG_HOP: `${BASE_API_URL_CAP_DIEN}/bao-cao/tong-hop-so-lieu-bcth-kq-yccd`,
  XUAT_EXCEL_BAO_CAO_TONG_HOP: `${BASE_API_URL_CAP_DIEN}/bao-cao/export-excel-bcth-kq-yccd`,
  XUAT_EXCEL_BAO_CAO_TONG_HOP_CHI_TIET_CMIS: `${BASE_API_URL_CAP_DIEN}/bao-cao/export-excel-bao-cao-chi-tiet-2-cmis`,

  //BÁO CÁO CHI TIẾT
  GET_LIST_BAO_CAO_CHI_TIET: `${BASE_API_URL_CAP_DIEN}/bao-cao/get-bc-chitiet-kq-cd`,
  GET_LIST_COLUMNS_BAO_CAO_CHI_TIET: `${BASE_API_URL_CAP_DIEN}/bao-cao/get-column-bcct-kq-cd`,
  UPDATE_LIST_COLUMNS_BAO_CAO_CHI_TIET: `${BASE_API_URL_CAP_DIEN}/bao-cao/update-column-bcct-kq-cd`,
  EXPORT_EXCEL_BAO_CAO_CHI_TIET: `${BASE_API_URL_CAP_DIEN}/bao-cao/export-excel-bc-chitiet-kq-cd`,
  GET_LIST_LOAI_HO_SO_YEU_CAU_BAO_CAO: `${BASE_API_URL_CAP_DIEN}/bao-cao/get-loai-hs-ycbc`,

  //BÁO CÁO 89-160
  CHOT_BAO_CAO_89_160: `${BASE_API_URL_CAP_DIEN}/bao-cao/cap-dien-ha-ap/chot-du-lieu`,
  HUY_CHOT_BAO_CAO_89_160: `${BASE_API_URL_CAP_DIEN}/bao-cao/cap-dien-ha-ap/huy-du-lieu`,
  GET_LIST_BAO_CAO_89_160: `${BASE_API_URL_CAP_DIEN}/bao-cao/cap-dien-ha-ap/list`,
  CREATE_CHI_PHI_CAP_NGAM: `${BASE_API_URL_CAP_DIEN}/bao-cao/chi-phi-cap/create`,
  UPDATE_CHI_PHI_CAP_NGAM: `${BASE_API_URL_CAP_DIEN}/bao-cao/chi-phi-cap/update`,
  REMOVE_CHI_PHI_CAP_NGAM: `${BASE_API_URL_CAP_DIEN}/bao-cao/chi-phi-cap/delete`,
  CREATE_CHI_PHI_CAP_DDK: `${BASE_API_URL_CAP_DIEN}/bao-cao/chi-phi-cap/create`,
  UPDATE_CHI_PHI_CAP_DDK: `${BASE_API_URL_CAP_DIEN}/bao-cao/chi-phi-cap/update`,
  REMOVE_CHI_PHI_CAP_DDK: `${BASE_API_URL_CAP_DIEN}/bao-cao/chi-phi-cap/delete`,
  CREATE_CHI_PHI_CAP_KHAC: `${BASE_API_URL_CAP_DIEN}/bao-cao/chi-phi-khac/create`,
  UPDATE_CHI_PHI_CAP_KHAC: `${BASE_API_URL_CAP_DIEN}/bao-cao/chi-phi-khac/update`,
  REMOVE_CHI_PHI_CAP_KHAC: `${BASE_API_URL_CAP_DIEN}/bao-cao/chi-phi-khac/delete`,
  GET_LIST_CHI_PHI_CAP_NGAM: `${BASE_API_URL_CAP_DIEN}/bao-cao/chi-phi-cap/list`,
  GET_LIST_CHI_PHI_CAP_DDK: `${BASE_API_URL_CAP_DIEN}/bao-cao/chi-phi-cap/list`,
  GET_LIST_CHI_PHI_CAP_KHAC: `${BASE_API_URL_CAP_DIEN}/bao-cao/chi-phi-khac/list`,
  EXPORT_EXCEL_BAO_CAO_CHI_TIET_DIEN_HA_AP: `${BASE_API_URL_CAP_DIEN}/bao-cao/cap-dien-ha-ap/export-chi-tiet`,
  EXPORT_EXCEL_BAO_CAO_TRAM_DIEN_HA_AP: `${BASE_API_URL_CAP_DIEN}/bao-cao/cap-dien-ha-ap/export-theo-tram`,
  EXPORT_EXCEL_BAO_CAO_CHI_PHI_CAP_MOI_DIEN_HA_AP: `${BASE_API_URL_CAP_DIEN}/bao-cao/cap-dien-ha-ap/export-chi-phi-cap-moi`,
  EXPORT_EXCEL_BAO_CAO_CHI_PHI_NANG_CAP_DIEN_HA_AP: `${BASE_API_URL_CAP_DIEN}/bao-cao/cap-dien-ha-ap/export-chi-phi-nang-cap`,
  EXPORT_EXCEL_BAO_CAO_CHI_PHI_CHUYEN_DUNG_DIEN_HA_AP: `${BASE_API_URL_CAP_DIEN}/bao-cao/cap-dien-ha-ap/export-chi-phi-tram-chuyen-dung`,
  GET_THONG_TIN_CHUNG_BAO_CAO_89_160: `${BASE_API_URL_CAP_DIEN}/bao-cao/thong-tin-chung/get`,
  UPDATE_THONG_TIN_CHUNG_BAO_CAO_89_160: `${BASE_API_URL_CAP_DIEN}/bao-cao/thong-tin-chung/update`,

  //tổng hợp theo hình thức lắp đặt
  GET_LIST_BAO_CAO_TONG_HOP_HTLD: `${BASE_API_URL_CAP_DIEN}/bao-cao/bao-cao-tong-hop-theo-hinh-thuc-lap-dat`,
  EXPORT_EXCEL_BAO_CAO_TONG_HOP_HTLD: `${BASE_API_URL_CAP_DIEN}/bao-cao/export-excel-bao-cao-tong-hop-theo-hinh-thuc-lap-dat`,

  //LOG THAO TÁC
  GET_LIST_LOG_THAO_TAC: `${BASE_API_URL_CAP_DIEN}/bao-cao/bao-cao-hsthaotac`,
  EXPORT_EXCEL_LOG_THAO_TAC: `${BASE_API_URL_CAP_DIEN}/bao-cao/export-excel-bao-cao-hsthaotac`,

  //chậm tiến độ
  GET_LISt_CHAM_TIEN_DO: `${BASE_API_URL_CAP_DIEN}/bao-cao/bao-cao-ds-cham-td`,
  EXPORT_EXCEL_CHAM_TIEN_DO: `${BASE_API_URL_CAP_DIEN}/bao-cao/export-excel-bc-dsctd`,
  GET_LIST_COLUMNS_BAO_CAO_CHAM_TIEN_DO: `${BASE_API_URL_CAP_DIEN}/bao-cao/get-column-bc-dsctd`,
  UPDATE_LIST_COLUMNS_BAO_CAO_CHAM_TIEN_DO: `${BASE_API_URL_CAP_DIEN}/bao-cao/update-column-bc-dsctd`,

  //Chi tiết theo hình thức lắp đặt
  GET_LIST_CHI_TIET_THEO_HTLD: `${BASE_API_URL_CAP_DIEN}/bao-cao/bao-cao-chi-tiet-theo-hinh-thuc-lap-dat`,
  EXPORT_EXCEL_CHI_TIET_THEO_HTLD: `${BASE_API_URL_CAP_DIEN}/bao-cao/export-excel-bao-cao-chi-tiet-theo-hinh-thuc-lap-dat`,
  GET_LIST_COLUMNS_BAO_CAO_CHI_TIET_HINH_THUC_LAP_DAT: `${BASE_API_URL_CAP_DIEN}/bao-cao/get-column-bao-cao-chi-tiet-hinh-thuc-lap-dat`,
  UPDATE_LIST_COLUMNS_BAO_CAO_CHI_TIET_HINH_THUC_LAP_DAT: `${BASE_API_URL_CAP_DIEN}/bao-cao/update-column-bao-cao-chi-tiet-hinh-thuc-lap-dat`,

  //Tổng hợp chi phí quyết toán
  GET_LIST_BAO_CAO_TONG_HOP_CHI_PHI_QUYET_TOAN: `${BASE_API_URL_CAP_DIEN}/bao-cao/bao-cao-tong-hop-chi-phi-quyet-toan`,
  EXPORT_EXCEL_BAO_CAO_TONG_HOP_CHI_PHI_QUYET_TOAN: `${BASE_API_URL_CAP_DIEN}/bao-cao/export-excel-bao-cao-tong-hop-chi-phi-quyet-toan`,

  //Chi tiết chi phí quyết toán
  GET_LIST_BAO_CAO_CHI_PHI_QUYET_TOAN: `${BASE_API_URL_CAP_DIEN}/bao-cao/bao-cao-chi-tiet-chi-phi-quyet-toan`,
  GET_LIST_COLUMNS_BAO_CAO_CHI_PHI_QUYET_TOAN: `${BASE_API_URL_CAP_DIEN}/bao-cao/get-column-bao-cao-chi-tiet-chi-phi-quyet-toan`,
  EXPORT_EXCEL_BAO_CAO_CHI_PHI_QUYET_TOAN: `${BASE_API_URL_CAP_DIEN}/bao-cao/export-excel-bao-cao-chi-tiet-chi-phi-quyet-toan`,
  UPDATE_LIST_COLUMNS_BAO_CAO_CHI_PHI_QUYET_TOAN: `${BASE_API_URL_CAP_DIEN}/bao-cao/update-column-bao-cao-chi-tiet-chi-phi-quyet-toan`,

  //Báo cáo tổng hợp thông tin khách hàng
  GET_LIST_BAO_CAO_TONG_HOP_THONG_TIN_KH: `${BASE_API_URL_CAP_DIEN}/bao-cao/bao-cao-tong-hop-thong-tin-khach-hang`,
  EXPORT_EXCEL_BAO_CAO_TONG_HOP_THONG_TIN_KH: `${BASE_API_URL_CAP_DIEN}/bao-cao/export-excel-bao-cao-tong-hop-khach-hang-phat-trien-moi`,

  //Báo cáo danh sách khách hàng
  GET_LIST_BAO_CAO_DANH_SACH_KH: `${BASE_API_URL_CAP_DIEN}/bao-cao/bao-cao-ds-khach-hang-phat-trien-theo-lo`,
  EXPORT_EXCEL_BAO_CAO_DANH_SACH_KH: `${BASE_API_URL_CAP_DIEN}/bao-cao/export-excel-bao-cao-ds-khach-hang-phat-trien-theo-lo`,
  EXPORT_EXCEL_BAO_CAO_DANH_SACH_KH_THEO_LO: `http://10.9.125.18:8031/KeHoachTiepNhan/ExportTongHopThongTinKhachHangPhatTrienTheoLo`,

  //Báo cáo tình hình phát triển theo lô
  GET_LIST_BAO_CAO_TINH_HINH_PHAT_TRIEN: `${BASE_API_URL_CAP_DIEN}/bao-cao/bao-cao-tinh-hinh-phat-trien-theo-lo`,
  EXPORT_EXCEL_BAO_CAO_TINH_HINH_PHAT_TRIEN: `${BASE_API_URL_CAP_DIEN}/bao-cao/export-excel-bao-cao-tinh-hinh-phat-trien-theo-lo`,
  EXPORT_EXCEL_BAO_CAO_TINH_HINH_PHAT_TRIEN_THEO_LO: `http://10.9.125.18:8031/KeHoachTiepNhan/ExportTinhHinhPhatTrienTheoLo`,
  //báo cáo dv điện
  GET_LIST_DICH_VU_DIEN: `${BASE_API_URL_CAP_DIEN}/bao-cao/bao-cao-tiep-nhan-cac-dich-vu`,
  EXPORT_EXCEL_BAO_CAO_DICH_VU_DIEN: `${BASE_API_URL_CAP_DIEN}/bao-cao/export-excel-bao-cao-tiep-nhan-cac-dich-vu`,

  //báo cáo chỉ tiêu chất lượng
  GET_LIST_CHI_TIEU_CHAT_LUONG: `${BASE_API_URL_CAP_DIEN}/bao-cao/bao-cao-ctcl`,
  EXPORT_EXCEL_BAO_CAO_CHI_TIEU_CHAT_LUONG: `${BASE_API_URL_CAP_DIEN}/bao-cao/export-excel-bao-cao-ctcl`,
  EXPORT_EXCEL_BAO_CAO_CHI_TIEU_CHAT_LUONG_NEW: `${BASE_API_URL_CAP_DIEN}/bao-cao/export-excel-bao-cao-chi-tiet-ctcl`,
  //BÁO CÁO COMMON

  //SỐ LƯỢNG VẬT TƯ
  GET_LIST_SO_LUONG_VAT_TU: `${BASE_API_URL_CAP_DIEN}/bao-cao/get-bcth-slvt`,
  LIST_DOI_SO_LUONG_VAT_TU: `${BASE_API_URL_CAP_DIEN}/bao-cao/list-doi-bcth-slvt`,
  EXPORT_EXCEL_SO_LUONG_VAT_TU: `${BASE_API_URL_CAP_DIEN}/bao-cao/export-excel-bcth-slvt`,
};
