import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Grid } from "antd";
import ModalSuaLapQuyetToanCongTy from "./modal-sua-quyet-toan-cong-ty";
import { useTranslation } from "react-i18next";
import PencilIcon from "@assets/icon/PencilIcon";
import ModalLapQuyetToan from "./ui-modal/modal-lap-quyet-toan-cong-ty";
import ModalQuyetToanCongTy from "../../common-ho-so/quyet-toan-cong-ty";
import { handleListQuyetToanCongTy } from "./services";
import ModalHoanThanhQuyetToan from "./ui-modal/modal-hoan-thanh-quyet-toan";
const { useBreakpoint } = Grid;

export default function LapBangQuyetToanCongTy({
  closeModal,
  dispatch,
  detail,
  setFetchDataTable,
}) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [detailLapQuyetToanCongTy, setDetailLapQuyetToanCongTy] = useState({});
  const [loading, setLoading] = useState(false);
  const [visibleHoanThanh, setVisibleHoanThanh] = useState(false);
  const [listQuyetToanCongTy, setListQuyetToanCongTy] = useState([]);
  const [dataQuyetToanCongTy, setDataQuyetToanCongTy] = useState({});
  const [visibleQuyetToanCongTy, setVisibleQuyetToanCongTy] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const screens = useBreakpoint();
  const mobile = (screens.xs || screens.sm) && !screens.md;
  // Hiển thị  các buttom tạo mới, ...

  const genExtra = () => (
    <div style={{ display: "flex", flexDirection: mobile ? "column" : "row" }}>
      <Button
        size="small"
        type="primary"
        className="mr-5px"
        onClick={handleOpenQuyetToanCongTy}
        style={{ marginBottom: mobile ? "5px" : "0px" }}
      >
        {t("quyet_toan.lap_quyet_toan_cong_ty.bien_ban_quyet_toan")}
      </Button>
      <Button
        size="small"
        type="primary"
        className="mr-5px"
        style={{ backgroundColor: "#23c16b" }}
        loading={loading}
        onClick={openModalHoanThanh}
        disabled={disableButton}
      >
        {t("quyet_toan.lap_quyet_toan_cong_ty.hoan_thanh_quyet_toan")}
      </Button>
    </div>
  );
  //modal quyết toán công ty

  const showModalQuyetToanCongTy = useMemo(
    () =>
      visibleQuyetToanCongTy && (
        <ModalQuyetToanCongTy
          visible={visibleQuyetToanCongTy}
          detail={detail}
          setVisibleQuyetToanCongTy={setVisibleQuyetToanCongTy}
          visibleQuyetToanCongTy={visibleQuyetToanCongTy}
        />
      ),
    // eslint-disable-next-line
    [visibleQuyetToanCongTy]
  );
  const handleOpenQuyetToanCongTy = () => {
    setVisibleQuyetToanCongTy(!visibleQuyetToanCongTy);
  };

  const getListDanhSach = () => {
    handleListQuyetToanCongTy(
      detail.id,
      setLoading,
      setListQuyetToanCongTy,
      setDataQuyetToanCongTy
    );
  };

  useEffect(() => {
    getListDanhSach();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: t("ap_gia_ho_so.stt"),
      dataIndex: "id",
      key: "id",
      render: (item, record, index) => (
        <div className="table-text-center">
          <span>{index + 1}</span>
        </div>
      ),
      width: "5%",
    },
    {
      title: "Tên vật tư, thiết bị",
      dataIndex: "tenVatTuThietBi",
      key: "tenVatTuThietBi",
      width: "15%",
    },
    {
      title: t("quyet_toan.lap_quyet_toan_cong_ty.don_vi_tinh"),
      dataIndex: "donViTinh",
      key: "donViTinh",
      width: "10%",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Số lượng thực tế",
      dataIndex: "soLuongThucTe",
      key: "soLuongThucTe",
      width: "10%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: t("quyet_toan.lap_quyet_toan_cong_ty.he_so_dieu_chinh"),
      dataIndex: "heSoDieuChinh",
      key: "heSoDieuChinh",
      width: "21%",
      children: [
        {
          title: t("quyet_toan.lap_quyet_toan_cong_ty.k1nc"),
          dataIndex: "k1NC",
          key: "k1NC",
          width: "7%",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
        },
        {
          title: t("quyet_toan.lap_quyet_toan_cong_ty.k2nc"),
          dataIndex: "k2NC",
          key: "k2NC",
          width: "7%",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
        },
        {
          title: t("quyet_toan.lap_quyet_toan_cong_ty.kmtc"),
          dataIndex: "mtc",
          key: "mtc",
          width: "7%",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
        },
      ],
    },
    {
      title: t("quyet_toan.lap_quyet_toan_cong_ty.don_gia_quyet_toan"),
      dataIndex: "donGiaQuyetToan",
      key: "donGiaQuyetToan",
      width: "30%",
      children: [
        {
          title: t("quyet_toan.lap_quyet_toan_cong_ty.vat_lieu"),
          dataIndex: "donGiaVatTu",
          key: "donGiaVatTu",
          width: "10%",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
        },
        {
          title: t("quyet_toan.lap_quyet_toan_cong_ty.nhan_cong"),
          dataIndex: "donGiaNhanCong",
          key: "donGiaNhanCong",
          width: "10%",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
        },
        {
          title: t("quyet_toan.lap_quyet_toan_cong_ty.mtc"),
          dataIndex: "donGiaMTC",
          key: "donGiaMTC",
          width: "10%",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
        },
      ],
    },
    {
      title: t("tac_vu"),
      key: "action",
      align: "center",
      width: "15%",
      render: (row) => (
        <div
          style={
            disableButton
              ? { pointerEvents: "none", opacity: "0.4", textAlign: "center" }
              : { textAlign: "center" }
          }
        >
          <div className="action-icon" onClick={() => handleOpenModal(row)}>
            <PencilIcon />
          </div>
        </div>
      ),
    },
  ];
  //đóng popup

  const handleCloseModal = () => {
    setVisible(false);
  };
  //hiển thị popup, set dữ liệu chi tiết

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDetailLapQuyetToanCongTy(row);
      else setDetailLapQuyetToanCongTy({});
      setVisible(!visible);
    },
    [visible]
  );

  //render popup

  const showModal = useMemo(
    () =>
      visible && (
        <ModalSuaLapQuyetToanCongTy
          detailLapQuyetToanCongTy={detailLapQuyetToanCongTy}
          visible={visible}
          handleCloseModal={handleCloseModal}
          getListDanhSach={getListDanhSach}
          detailHoSo={detail}
        />
      ),
    // eslint-disable-next-line
    [visible]
  );
  //render popup hoàn thành

  const openModalHoanThanh = () => {
    setVisibleHoanThanh(!visibleHoanThanh);
  };

  const handleCloseModalHoanThanh = () => {
    setVisibleHoanThanh(false);
  };

  const showModalHoanThanhQuyetToan = useMemo(
    () =>
      visibleHoanThanh && (
        <ModalHoanThanhQuyetToan
          detail={detail}
          visible={visibleHoanThanh}
          handleCloseModal={handleCloseModalHoanThanh}
          setFetchDataTable={setFetchDataTable}
          setDisableButton={setDisableButton}
        />
      ),
    // eslint-disable-next-line
    [visibleHoanThanh]
  );
  return (
    <Fragment>
      <ModalLapQuyetToan
        genExtra={genExtra()}
        list={listQuyetToanCongTy}
        columns={columns}
        t={t}
        dispatch={dispatch}
        closeModal={closeModal}
        loading={loading}
        detail={dataQuyetToanCongTy}
      ></ModalLapQuyetToan>
      {showModal}
      {showModalHoanThanhQuyetToan}
      {showModalQuyetToanCongTy}
    </Fragment>
  );
}
