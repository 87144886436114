import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  getErrorMessage,
  parseParams,
  removeAccents,
} from "@utils/function";
import { authGetData, authPostData, downloadFile } from "@utils/request";

import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { alertMessage422 } from "../service";

export const optionsKTB = [
  { label: "Tất cả", value: "ALL" },
  { label: "Kho thiết bị mới tại Công ty điện lực", value: "5" },
  { label: "Kho thiết bị dưới lưới chờ gửi kiểm định quay vòng", value: "6" },
  { label: "Kho công tơ cơ khí chờ chỉ đạo sử dụng", value: "7" },
  {
    label: "Kho thiết bị thành phần quay vòng tại công ty điện lực",
    value: "8",
  },
  { label: "Kho thiết bị đã kiểm định không đạt chờ thanh lý", value: "9" },
];

export const optionsCN = [
  { label: "Tất cả", value: "ALL" },
  { label: "Elster RS232", value: "Elster RS232" },
  { label: "PLC", value: "PLC" },
  { label: "RF", value: "RF" },
  { label: "RS485", value: "RS485" },
  { label: "Khác", value: "KHAC" },
];

export const SelectKhoThietBi = (props) => {
  const {
    url,
    placeholder = "Chọn kho thiết bị",
    form,
    formKey,
    disabled,
    mode,
    setValue,
    handleDeselect,
    notClear,
    defaultValue,
  } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(typeof url === "string" ? [] : url);

  const getData = useCallback(() => {
    authGetData({
      url,
      setLoading,
      onSuccess: (res) => {
        if (res.length > 0) setData(res);
        else setData(res.data);
      },
    });
  }, [url]);

  useEffect(() => {
    if (url && typeof url === "string") {
      getData();
    }
  }, [getData, url]);
  const handleChange = useCallback(
    (value) => {
      if (form) {
        form.setFieldsValue({
          [formKey]: value,
        });
      }
      if (setValue) {
        setValue(value);
      }
    },
    [form, formKey, setValue]
  );
  return (
    <Select
      defaultValue={defaultValue}
      maxTagCount={mode === "multiple" || "tags" ? "responsive" : null}
      value={form && form.getFieldValue(formKey)}
      placeholder={placeholder}
      showSearch
      mode={mode}
      allowClear={notClear ? false : true}
      loading={loading}
      onChange={handleChange}
      onDeselect={handleDeselect}
      disabled={disabled}
      style={{ width: "100%" }}
      filterOption={(input, option) => {
        if (option && option.children) {
          return (
            removeAccents(option.children)
              .toLowerCase()
              .indexOf(removeAccents(input).toLowerCase()) >= 0
          );
        }
      }}
    >
      {data?.map((item, idx) => (
        <Option
          key={idx}
          value={item.MA_KHO ? item.MA_KHO.toString() : item.MA_KHO}
        >
          {item.MO_TA}
        </Option>
      ))}
    </Select>
  );
};

export const SelectLoaiCongTo = (props) => {
  const {
    url,
    placeholder = "Chọn loại công tơ",
    form,
    formKey,
    disabled,
    mode,
    setValue,
    handleDeselect,
    notClear,
    defaultValue,
  } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(typeof url === "string" ? [] : url);

  const getData = useCallback(() => {
    authGetData({
      url,
      setLoading,
      onSuccess: (res) => {
        if (res.length > 0) setData(res);
        else setData(res.data);
      },
    });
  }, [url]);

  useEffect(() => {
    if (url && typeof url === "string") {
      getData();
    }
  }, [getData, url]);
  const handleChange = useCallback(
    (value) => {
      if (form) {
        form.setFieldsValue({
          [formKey]: value,
        });
      }
      if (setValue) {
        setValue(value);
      }
    },
    [form, formKey, setValue]
  );
  return (
    <Select
      defaultValue={defaultValue}
      maxTagCount={mode === "multiple" || "tags" ? "responsive" : null}
      value={form && form.getFieldValue(formKey)}
      placeholder={placeholder}
      showSearch
      mode={mode}
      allowClear={notClear ? false : true}
      loading={loading}
      onChange={handleChange}
      onDeselect={handleDeselect}
      disabled={disabled}
      style={{ width: "100%" }}
      filterOption={(input, option) => {
        if (option && option.children) {
          return (
            removeAccents(option.children)
              .toLowerCase()
              .indexOf(removeAccents(input).toLowerCase()) >= 0
          );
        }
      }}
    >
      {data.map((item, idx) => (
        <Option
          key={idx}
          value={item.MA_CLOAI ? item.MA_CLOAI.toString() : item.MA_CLOAI}
        >
          {item.MOTA}
        </Option>
      ))}
    </Select>
  );
};

export const optionsLoaiCongTo = [
  {
    label: "Công tơ 1 pha hữu công - CV130m 3(9)A-230V",
    value: 1,
  },
  {
    label: "Công tơ điện tử 3 pha 1 giá đa truyền thông - DTS27 5(100)A-220V",
    value: 2,
  },
  {
    label: "Công tơ điện tử 3 pha 3 giá đa truyền thông - DTS27 3x5(100)A-220V",
    value: 3,
  },
  {
    label: "Công tơ điện tử 3 pha 3 giá đa truyền thông - DTS27 5(6)A-57.5/415",
    value: 4,
  },
  {
    label:
      "Công tơ điện tử 3 pha 3 giá tích hợp module PLC - VSE3T10 10A-230V-PLC",
    value: 5,
  },
  {
    label: "Công tơ điện tử 1 pha-OHEBA06DC2-5(60)-230",
    value: 6,
  },
  {
    label: "Công tơ điện tử 3 pha trực tiếp 1 biểu giá - OMWH-34 3x20(120)A",
    value: 7,
  },
];

export const selectBcslct = [
  {
    label:
      "1. Tổng hợp số lượng công tơ tháo về quá 60 ngày chưa kiểm định quay vòng",
    value: Endpoint.BCCT_TH_SOLUONG_CONGTO_TVQ_60NGAY_EXCEL,
  },
  {
    label:
      "2. Chi tiết công tơ tháo về quá 60 ngày chưa gửi kiểm định quay vòng",
    value: Endpoint.BCCT_CHI_TIET_CONG_TO_60NGAY_EXCEL,
  },
  {
    label: "3. Tổng hợp số lượng công tơ gửi trả kho cấp trên",
    value: Endpoint.BCCT_THSL_CONG_TO_GUI_TRA_LAI_KHO_EXCEL,
  },
  {
    label: "4. Chi tiết công tơ gửi trả kho cấp trên",
    value: Endpoint.BCCT_CHI_TIET_CONG_TO_GUITRA_KHO_EXCEL,
  },
  {
    label: "5. Tổng hợp số lượng công tơ cấp trên nhận kiểm định",
    value: Endpoint.BCCT_THSL_CONG_TO_CAP_TREN_NHANKD_EXCEL,
  },
  {
    label: "6. Chi tiết công tơ cấp trên nhận kiểm định",
    value: Endpoint.BCCT_CHI_TIET_CONG_TO_TREN_NHAN_KIEM_DINH_EXCEL,
  },
  {
    label: "7. Tổng hợp số lượng công tơ cấp trên phân bổ",
    value: Endpoint.BCCT_THSL_CONG_TO_TREN_CAP_TREN_BO_EXCEL,
  },
  {
    label: "8. Chi tiết công tơ cấp trên phân bổ",
    value: Endpoint.BCCT_CT_CONG_TO_TREN_CAP_TREN_BO,
  },
  {
    label: "9. Tổng hợp số lượng công tơ không dùng 6 tháng trở lên",
    value: Endpoint.BCCT_THSL_CONG_TO_KHONG_DUNG_6_THANG_TRO_LEN,
  },
  {
    label: "10. Tổng hợp số lượng công tơ cấp trên thanh lý",
    value: Endpoint.BCCT_THSL_CONG_TO_CAP_TREN_THANH_LY,
  },
  {
    label: "11. Chi tiết công tơ cấp trên thanh lý",
    value: Endpoint.BCCT_CT_CONG_TO_CAP_TREN_THANH_LY,
  },
  {
    label: "12. Chi tiết nhập kho công tơ",
    value: Endpoint.BCCT_CT_NHAP_KHO_CONG_TO,
  },
  {
    label: "13. Báo cáo tổng hợp công tơ cấp trên",
    value: Endpoint.BCCT_BCTH_CONG_TO_CAP_TREN_MOI,
  },
  {
    label: "14. Chi tiết công tơ 6 tháng không lên chỉ số",
    value: Endpoint.BCCT_CT_CONG_TO_6_THANG_KHONG_LEN_CHI_SO_MOI,
  },
  {
    label: "15. Chi tiết công tơ gửi kiểm định",
    value: Endpoint.BCCT_CT_CONG_TO_GUI_KIEM_DINH_MOI,
  },
  {
    label: "16. Chi tiết công tơ cấp dưới nhận phân bổ",
    value: Endpoint.BCCT_CT_CONG_TO_PHAN_BO_MOI,
  },
  {
    label: "17. Chi tiết kiểm định công tơ",
    value: Endpoint.CT_KIEM_DINH_CONG_TO_MOI,
  },
  {
    label: "18. Chi tiết nhập kho sau kiểm định công tơ",
    value: Endpoint.BCCT_CT_NHAP_KHO_SAU_KD_CONG_TO_MOI,
  },
  {
    label: "29. Tổng hợp công tác kiểm định(công tơ,TU,TI)",
    value: Endpoint.BCCT_TH_CONG_TAC_KD_CONG_TO_TU_TI_MOI,
  },
  {
    label: "20. Tổng hợp công tơ đến hạn thay định kỳ",
    value: Endpoint.BCCT_TH_CONG_TO_DEN_HAN_THAY_DINH_KY_MOI,
  },
  {
    label: "21. Tổng hợp công tơ phân bổ mới",
    value: Endpoint.BCCT_THCT_PHAN_BO_MOI,
  },
  {
    label: "22. Tổng hợp thiết bị",
    value: Endpoint.BCCT_TH_THIET_BI_MOI,
  },
];

export const selectTHTBDD2C = [
  {
    label: "1. Số lượng thiết bị đo đếm tồn dưới lưới",
    value: Endpoint.BCCT_SLTB_DD_TON_DUOI_LUOI,
  },
  {
    label: "2. Số lượng thiết bị đo đếm tồn dưới lưới chưa gửi kiểm định",
    value: Endpoint.BCCT_SLTB_DD_TON_DUOI_LUOI_CKD,
  },
  {
    label: "3. Số lượng thiết bị đo đếm tồn kho chưa sử dụng",
    value: Endpoint.BCCT_SLTB_DD_TON_CHUA_SU_DUNG,
  },
  {
    label: "4. Số lượng thiết bị đo đếm tồn quá 60 ngày chưa sử dụng",
    value: Endpoint.BCCT_SLTB_DD_TON_QUA_60_NGAY,
  },
  {
    label: "5. Số lượng thiết bị đo đếm đang vận hành trên lưới",
    value: Endpoint.BCCT_SLTB_DD_DANG_VAN_HANH_TL,
  },
  {
    label:
      "6. Số lượng thiết bị đo đếm đang vận hành trên lưới đến hạn kiểm định",
    value: Endpoint.BCCT_SLTB_DD_DANG_VAN_HANH_TL_DHKD,
  },
  {
    label: "7. Số lượng thiết bị đo đếm gửi kiểm định quay vòng",
    value: Endpoint.BCCT_SLTB_DD_GUI_KD_QUAYVONG,
  },
  {
    label: "8. Số lượng thiết bị đo đếm nhận kiểm định quay vòng",
    value: Endpoint.BCCT_SLTB_DD_NHAN_KD_QUAYVONG,
  },
  {
    label: "9. Số lượng thiết bị đo đếm gửi kiểm định lại",
    value: Endpoint.BCCT_SLTB_DD_GUI_KD_LAI,
  },
  {
    label: "10. Số lượng thiết bị đo đếm nhận kiểm định lại",
    value: Endpoint.BCCT_SLTB_DD_NHAN_KD_LAI,
  },
  {
    label: "11. Số lượng thiết bị đo đếm gửi trả",
    value: Endpoint.BCCT_SLTB_DD_GUI_TRA,
  },
  {
    label: "12. Số lượng thiết bị đo đếm nhận gửi trả",
    value: Endpoint.BCCT_SLTB_DD_HIEN_GUI_TRA,
  },
  {
    label: "13. Số lượng thiết bị đo đếm gửi thanh lý",
    value: Endpoint.BCCT_SLTB_DD_GUI_THANH_LY,
  },
  {
    label: "14. Số lượng thiết bị đo đếm nhận gửi thanh lý",
    value: Endpoint.BCCT_SLTB_DD_NHAN_GUI_THANH_LY,
  },
  {
    label: "15. Số lượng thiết bị đo đếm đã thanh lý",
    value: Endpoint.BCCT_SLTB_DD_DA_THANH_LY,
  },
  {
    label: "16. Số lượng thiết bị đo đếm phân bố",
    value: Endpoint.BCCT_SLTB_DD_PHAN_BO,
  },
  {
    label: "17. Số lượng thiết bị đo đếm đã nhận phân bố ",
    value: Endpoint.BCCT_SLTB_DD_DA_NHAN_PHAN_BO,
  },
  {
    label: "18. Số lượng chủng loại công tơ trên lưới sau TBA công cộng",
    value: Endpoint.BCCT_SLCL_CONG_TO_TD_SAU_TBA_CC,
  },
  {
    label: "19. Tổng hợp tình hình vận hành thiết bị đo đếm (trên lưới)",
    value: Endpoint.BCCT_THTH_VAN_HANH_TBDD_TREN_LUOI,
  },
  {
    label: "20. Tổng hợp thiết bị đến hạn kiểm định",
    value: Endpoint.BCCT_TBDD_DEN_HAN_KD,
  },
  {
    label: "21. Tổng hợp thiết bị tồn kho",
    value: Endpoint.BCCT_THTB_TON_KHO,
  },
  {
    label: "22. Tổng hợp thiết bị dưới lưới",
    value: Endpoint.BCCT_THTB_DUOI_LUOI,
  },
];

export const selectBcCtTbDd = [
  {
    label: "1. Tổng hợp tình hình thay công tơ định kỳ",
    value: Endpoint.BCCT_TH_TH_THAY_CT_DK,
  },
  {
    label: "2. Tổng hợp tình hình thay TU/TI định kỳ",
    value: Endpoint.BCCT_THTH_THAY_TUTI_DK,
  },
  {
    label: "3. Tổng hợp tình hình kiểm tra định kỳ công tơ",
    value: Endpoint.BCCT_TH_TH_KT_DK_CT,
  },
  {
    label: "4. Tổng hợp tình hình phúc tra công tơ",
    value: Endpoint.BCCT_TH_THPT_CT,
  },
  {
    label: "5. Tổng hợp công tơ mất hỏng cháy thanh lý phát triển mới",
    value: Endpoint.BCCT_TH_CT_MHC_TL_PT_MOI,
  },
  {
    label: "6. Tổng hợp tình hình kiểm định công tơ",
    value: Endpoint.BCCT_TH_TH_KD_CT,
  },
  {
    label: "7. Báo cáo nhu cầu công tơ dưới lưới/trong kho",
    value: Endpoint.BCK_NHU_CAU_CONG_TO_DUOI_LUOI_TRONG_KHO,
  },
  {
    label: "8. Báo cáo tổng hợp đo đếm theo chủng loại",
    value: Endpoint.BCCT_BAO_CAO_TONG_HOP_DO_DEM_CL,
  },
];

export const exportData = (data, setLoading, setDataTable, openModal) => {
  const queryString = buildQueryString(
    parseParams({
      ...data,
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      Nam: data.Nam || moment().get("year"),
      BienDong: data.BienDong,
      SoThang: data.SoThang,
      ViTri: data.ViTri,
    })
  );
  authGetData({
    url: `${data.reportType}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataTable(res.data);
        openModal();
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      ...data,
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      SoThang: data.SoThang,
      BienDong: data.BienDong,
      ViTri: data.ViTri,
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      LoaiCongTo: data.LoaiCongTo,
      KiemDinh: data.KiemDinh,
      KhoThietBi: data.KhoThietBi,
      CongNghe: data.CongNghe,
      SoPha: data.SoPha,
      TinhTrang: data.TinhTrang,
      SoNgay: data.SoNgay,
      LoaiKhachHang: data.LoaiKhachHang,
      LoaiBienDong: data.LoaiBienDong,
      LuyKe: data.LuyKe,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      SoThang: data.SoThang,
      BienDong: data.BienDong,
      ViTri: data.ViTri,
    })
  );
  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};

export const handleListBaoCao = (
  url,
  filterConditions,
  setLoading,
  setDataTable,
  openModal
) => {
  const queryString = buildQueryString(parseParams(filterConditions));
  authGetData({
    url: `${url}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataTable(res.data);
        openModal();
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};

// create data tinh hinh cong to ton kho duoi luoi
export const handleCreateCongToTonKhoDL = (
  data,
  setLoading,
  getListData,
  url,
  form
) => {
  authPostData({
    url: url,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        form.resetFields();
        getListData();
      } else {
        getErrorMessage(res);
      }
    },
  });
};

//Cập nhật tình hình kiểm tra định kỳ công tơ, phúc tra công tơ
export const handleCreateCongTo = (
  data,
  setLoading,
  getListData,
  url,
  form
) => {
  authPostData({
    url: url,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        form.resetFields();
        getListData();
      } else {
        getErrorMessage(res);
      }
    },
  });
};

//Sửa tình hình kiểm tra định kỳ công tơ, phúc tra công tơ
export const handleSuaCongTo = (
  data,
  setLoading,
  closeModal,
  getListData,
  url
) => {
  authPostData({
    url: url,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListData();
        closeModal();
      } else {
        getErrorMessage(res);
      }
    },
  });
};
