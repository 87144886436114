import { cap_dien } from "@permissions/a_capdien";
import CheckStatus from "@components/CheckStatus";
import { MATRIX_CAP_DIEN, SEQUENCE_TRANGTHAI } from "@utils/constants";
import { Menu } from "antd";
export default function ListMenu(props) {
  const { handleOpenModal, detail } = props;
  return (
    <Menu mode="vertical" style={{ width: "100%" }} selectedKeys={["0"]}>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.LAMMOIHS}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.HSDANGKHAOSAT[1]}
        permissionCode={cap_dien.a32_lam_moi_hs}
      >
        <Menu.Item eventKey="1" onClick={handleOpenModal}>
          Làm mới hồ sơ
        </Menu.Item>
      </CheckStatus>
    </Menu>
  );
}
