import { APITram } from "@apiUrl/danh-muc/tram";
import { APICauHinhChung } from "@apiUrl/danh-muc/cau-hinh-chung";
import { APIUyQuyen } from "@apiUrl/danh-muc/uy-quyen";
import { APITiLeBanDien } from "@apiUrl/danh-muc/ti-le-ban-dien";
import { APILo } from "@apiUrl/danh-muc/lo";
import { APIToDoi } from "@apiUrl/danh-muc/doi";
import { APIImei } from "@apiUrl/danh-muc/imei";
import { APINganHang } from "@apiUrl/danh-muc/ngan-hang";
import { APITheoNgayHieuLuc } from "@apiUrl/danh-muc/theo-ngay-hieu-luc";
import { APITroNgai } from "@apiUrl/danh-muc/loai-tro-ngai";
import { APIDiaChinh } from "@apiUrl/danh-muc/dia-chinh";
import { APISoGhiChiSo } from "@apiUrl/danh-muc/so-ghi-chi-so";
import { APIMucTroNgai } from "@apiUrl/danh-muc/muc-tro-ngai";
import { APIVatTuLienSo } from "@apiUrl/danh-muc/vat-tu-lien-so";
import { APiTinhTrang } from "@apiUrl/danh-muc/tinh-trang";
import { APICongTo } from "@apiUrl/danh-muc/cong-to";
import { APILoaiYeuCau } from "@apiUrl/danh-muc/loai-yeu-cau";
import { APIVungDonGia } from "@apiUrl/danh-muc/vung-don-gia";
import { APIEmail } from "@apiUrl/danh-muc/email";
import { APIChungLoaiVatTu } from "@apiUrl/danh-muc/chung-loai-vat-tu";
import { APIVatTu } from "@apiUrl/danh-muc/vat-tu";
import { APIDonVi } from "@apiUrl/danh-muc/don-vi";
import { APITrangThai } from "@apiUrl/danh-muc/trang-thai";
import { APICauHinhDongBo } from "@apiUrl/danh-muc/cau-hinh-dong-bo";
import { APICommonDanhMuc } from "@apiUrl/danh-muc/common";
import { APIHinhThucLapDat } from "@apiUrl/danh-muc/hinh-thuc-lap-dat";
import { APIHinhThucThiCong } from "@apiUrl/danh-muc/hinh-thuc-thi-cong";
import { APILoaiDiemDo } from "@apiUrl/danh-muc/loai-diem-do";
import { APILyDoTreoThao } from "@apiUrl/danh-muc/ly-do-treo-thao";
import { APIChungLoaiCongTo } from "@apiUrl/danh-muc/chung-loai-cong-to";
import { APIQLChungThu } from "@apiUrl/danh-muc/quan-ly-chung-thu";
import { APICapDienVersion } from "@apiUrl/danh-muc/cap-dien-vesion";
export const DANHMUC_TIENICHCHUNG_Enpoint = {
  //trạm

  LIST_TRAM: APITram.LIST_TRAM,
  SYNC_TRAM: APITram.SYNC_TRAM,
  GET_TRAM: APITram.GET_TRAM,
  CREATE_TRAM: APITram.CREATE_TRAM,
  UPDATE_TRAM: APITram.UPDATE_TRAM,
  REMOVE_TRAM: APITram.REMOVE_TRAM,
  SEARCH_TRAM: APITram.SEARCH_TRAM,

  // Cấp điện version

  LIST_CAP_DIEN_VERSION: APICapDienVersion.LIST_CAP_DIEN_VERSION,
  CREATE_CAP_DIEN_VERSION: APICapDienVersion.CREATE_CAP_DIEN_VERSION,
  UPDATE_CAP_DIEN_VERSION: APICapDienVersion.UPDATE_CAP_DIEN_VERSION,
  REMOVE_CAP_DIEN_VERSION: APICapDienVersion.REMOVE_CAP_DIEN_VERSION,

  // Danh mục cấu hình chung

  LIST_CAU_HINH_CHUNG: APICauHinhChung.LIST_CAU_HINH_CHUNG,
  CREATE_CAU_HINH_CHUNG: APICauHinhChung.CREATE_CAU_HINH_CHUNG,
  UPDATE_CAU_HINH_CHUNG: APICauHinhChung.UPDATE_CAU_HINH_CHUNG,
  REMOVE_CAU_HINH_CHUNG: APICauHinhChung.REMOVE_CAU_HINH_CHUNG,

  // Danh mục cấu hình chung

  LIST_CAU_HINH_DONG_BO: APICauHinhDongBo.LIST_CAU_HINH_DONG_BO,
  CREATE_CAU_HINH_DONG_BO: APICauHinhDongBo.CREATE_CAU_HINH_DONG_BO,
  UPDATE_CAU_HINH_DONG_BO: APICauHinhDongBo.UPDATE_CAU_HINH_DONG_BO,
  REMOVE_CAU_HINH_DONG_BO: APICauHinhDongBo.REMOVE_CAU_HINH_DONG_BO,

  //tỉ lệ giá bán điện

  LIST_GIA_BAN_DIEN: APITiLeBanDien.LIST_GIA_BAN_DIEN,
  SYNC_GIA_BAN_DIEN: APITiLeBanDien.SYNC_GIA_BAN_DIEN,
  GET_GIA_BAN_DIEN: APITiLeBanDien.GET_GIA_BAN_DIEN,
  CREATE_GIA_BAN_DIEN: APITiLeBanDien.CREATE_GIA_BAN_DIEN,
  UPDATE_GIA_BAN_DIEN: APITiLeBanDien.UPDATE_GIA_BAN_DIEN,
  REMOVE_GIA_BAN_DIEN: APITiLeBanDien.REMOVE_GIA_BAN_DIEN,

  //lộ

  LIST_LO: APILo.LIST_LO,
  SYNC_LO: APILo.SYNC_LO,
  GET_LO: APILo.GET_LO,
  CREATE_LO: APILo.CREATE_LO,
  UPDATE_LO: APILo.UPDATE_LO,
  REMOVE_LO: APILo.REMOVE_LO,

  //tổ đội

  LIST_DOI: APIToDoi.LIST_DOI,
  SYNC_DOI: APIToDoi.SYNC_DOI,
  GET_DOI: APIToDoi.GET_DOI,
  CREATE_DOI: APIToDoi.CREATE_DOI,
  UPDATE_DOI: APIToDoi.UPDATE_DOI,
  REMOVE_DOI: APIToDoi.REMOVE_DOI,

  //imei

  LIST_IMEI: APIImei.LIST_IMEI,
  SYNC_IMEI: APIImei.SYNC_IMEI,
  GET_IMEI: APIImei.GET_IMEI,
  CREATE_IMEI: APIImei.CREATE_IMEI,
  UPDATE_IMEI: APIImei.UPDATE_IMEI,
  REMOVE_IMEI: APIImei.REMOVE_IMEI,
  LIST_NGUOI_DUNG: APIImei.LIST_NGUOI_DUNG,

  //Ngan hang

  LIST_NGAN_HANG: APINganHang.LIST_NGAN_HANG,
  SYNC_NGAN_HANG: APINganHang.SYNC_NGAN_HANG,
  GET_NGAN_HANG: APINganHang.GET_NGAN_HANG,
  CREATE_NGAN_HANG: APINganHang.CREATE_NGAN_HANG,
  UPDATE_NGAN_HANG: APINganHang.UPDATE_NGAN_HANG,
  REMOVE_NGAN_HANG: APINganHang.REMOVE_NGAN_HANG,

  // Ủy quyền

  LIST_UY_QUYEN: APIUyQuyen.LIST_UY_QUYEN,
  CREATE_UY_QUYEN: APIUyQuyen.CREATE_UY_QUYEN,
  UPDATE_UY_QUYEN: APIUyQuyen.UPDATE_UY_QUYEN,
  REMOVE_UY_QUYEN: APIUyQuyen.REMOVE_UY_QUYEN,
  LIST_NGUOI_DUOC_UY_QUYEN: APIUyQuyen.LIST_NGUOI_DUOC_UY_QUYEN,

  // Theo ngày hiệu lực

  LIST_THEO_NGAY_HIEU_LUC: APITheoNgayHieuLuc.LIST_THEO_NGAY_HIEU_LUC,
  CREATE_THEO_NGAY_HIEU_LUC: APITheoNgayHieuLuc.CREATE_THEO_NGAY_HIEU_LUC,
  UPDATE_THEO_NGAY_HIEU_LUC: APITheoNgayHieuLuc.UPDATE_THEO_NGAY_HIEU_LUC,
  REMOVE_THEO_NGAY_HIEU_LUC: APITheoNgayHieuLuc.REMOVE_THEO_NGAY_HIEU_LUC,

  // trở ngại

  LIST_TRO_NGAI: APITroNgai.LIST_TRO_NGAI,
  CREATE_TRO_NGAI: APITroNgai.CREATE_TRO_NGAI,
  UPDATE_TRO_NGAI: APITroNgai.UPDATE_TRO_NGAI,
  REMOVE_TRO_NGAI: APITroNgai.REMOVE_TRO_NGAI,

  // địa chính

  LIST_DIA_CHINH: APIDiaChinh.LIST_DIA_CHINH,
  LIST_PHUONG_XA: APIDiaChinh.LIST_PHUONG_XA,
  CREATE_DIA_CHINH: APIDiaChinh.CREATE_DIA_CHINH,
  UPDATE_DIA_CHINH: APIDiaChinh.UPDATE_DIA_CHINH,
  REMOVE_DIA_CHINH: APIDiaChinh.REMOVE_DIA_CHINH,
  SYNC_DIA_CHINH: APIDiaChinh.SYNC_DIA_CHINH,
  // số ghi chỉ số

  LIST_SO_GHI_CHI_SO: APISoGhiChiSo.LIST_SO_GHI_CHI_SO,
  SYNC_SO_GHI_CHI_SO: APISoGhiChiSo.SYNC_SO_GHI_CHI_SO,
  GET_SO_GHI_CHI_SO: APISoGhiChiSo.GET_SO_GHI_CHI_SO,
  CREATE_SO_GHI_CHI_SO: APISoGhiChiSo.CREATE_SO_GHI_CHI_SO,
  UPDATE_SO_GHI_CHI_SO: APISoGhiChiSo.UPDATE_SO_GHI_CHI_SO,
  REMOVE_SO_GHI_CHI_SO: APISoGhiChiSo.REMOVE_SO_GHI_CHI_SO,

  //lắp đặt

  LIST_HINH_THUC_LAP_DAT: APIHinhThucLapDat.LIST_HINH_THUC_LAP_DAT,
  SYNC_HINH_THUC_LAP_DAT: APIHinhThucLapDat.SYNC_HINH_THUC_LAP_DAT,
  GET_HINH_THUC_LAP_DAT: APIHinhThucLapDat.GET_HINH_THUC_LAP_DAT,
  CREATE_HINH_THUC_LAP_DAT: APIHinhThucLapDat.CREATE_HINH_THUC_LAP_DAT,
  UPDATE_HINH_THUC_LAP_DAT: APIHinhThucLapDat.UPDATE_HINH_THUC_LAP_DAT,
  REMOVE_HINH_THUC_LAP_DAT: APIHinhThucLapDat.REMOVE_HINH_THUC_LAP_DAT,
  ACTIVE_HINH_THUC_LAP_DAT: APIHinhThucLapDat.ACTIVE_HINH_THUC_LAP_DAT,

  // thi công
  LIST_HINH_THUC_THI_CONG: APIHinhThucThiCong.LIST_HINH_THUC_THI_CONG,
  SYNC_HINH_THUC_THI_CONG: APIHinhThucThiCong.SYNC_HINH_THUC_THI_CONG,
  GET_HINH_THUC_THI_CONG: APIHinhThucThiCong.GET_HINH_THUC_THI_CONG,
  CREATE_HINH_THUC_THI_CONG: APIHinhThucThiCong.CREATE_HINH_THUC_THI_CONG,
  UPDATE_HINH_THUC_THI_CONG: APIHinhThucThiCong.UPDATE_HINH_THUC_THI_CONG,
  REMOVE_HINH_THUC_THI_CONG: APIHinhThucThiCong.REMOVE_HINH_THUC_THI_CONG,

  // vật tư liên sở

  LIST_VAT_TU_LIEN_SO: APIVatTuLienSo.LIST_VAT_TU_LIEN_SO,
  SYNC_VAT_TU_LIEN_SO: APIVatTuLienSo.SYNC_VAT_TU_LIEN_SO,

  //     //Tình trạng

  LIST_TINH_TRANG: APiTinhTrang.LIST_TINH_TRANG,
  SYNC_TINH_TRANG: APiTinhTrang.SYNC_TINH_TRANG,
  GET_TINH_TRANG: APiTinhTrang.GET_TINH_TRANG,
  CREATE_TINH_TRANG: APiTinhTrang.CREATE_TINH_TRANG,
  UPDATE_TINH_TRANG: APiTinhTrang.UPDATE_TINH_TRANG,
  REMOVE_TINH_TRANG: APiTinhTrang.REMOVE_TINH_TRANG,

  // công tơ

  LIST_CONG_TO: APICongTo.LIST_CONG_TO,
  SYNC_CONG_TO: APICongTo.SYNC_CONG_TO,

  //trở ngại

  LIST_MUC_TRO_NGAI: APIMucTroNgai.LIST_TRO_NGAI,
  SYNC_MUC_TRO_NGAI: APIMucTroNgai.SYNC_TRO_NGAI,

  //yêu cầu

  LIST_LOAI_YEU_CAU: APILoaiYeuCau.LIST_LOAI_YEU_CAU,
  SYNC_LOAI_YEU_CAU: APILoaiYeuCau.SYNC_LOAI_YEU_CAU,
  GET_LOAI_YEU_CAU: APILoaiYeuCau.GET_LOAI_YEU_CAU,
  CREATE_LOAI_YEU_CAU: APILoaiYeuCau.CREATE_LOAI_YEU_CAU,
  UPDATE_LOAI_YEU_CAU: APILoaiYeuCau.UPDATE_LOAI_YEU_CAU,
  REMOVE_LOAI_YEU_CAU: APILoaiYeuCau.REMOVE_LOAI_YEU_CAU,

  //chủng loại vật tư

  LIST_CHUNG_LOAI_VAT_TU: APIChungLoaiVatTu.LIST_CHUNG_LOAI_VAT_TU,
  SYNC_CHUNG_LOAI_VAT_TU: APIChungLoaiVatTu.SYNC_CHUNG_LOAI_VAT_TU,
  GET_CHUNG_LOAI_VAT_TU: APIChungLoaiVatTu.GET_CHUNG_LOAI_VAT_TU,
  CREATE_CHUNG_LOAI_VAT_TU: APIChungLoaiVatTu.CREATE_CHUNG_LOAI_VAT_TU,
  UPDATE_CHUNG_LOAI_VAT_TU: APIChungLoaiVatTu.UPDATE_CHUNG_LOAI_VAT_TU,
  REMOVE_CHUNG_LOAI_VAT_TU: APIChungLoaiVatTu.REMOVE_CHUNG_LOAI_VAT_TU,

  //vật tư

  LIST_VAT_TU: APIVatTu.LIST_VAT_TU,
  SYNC_VAT_TU: APIVatTu.SYNC_VAT_TU,
  GET_VAT_TU: APIVatTu.GET_VAT_TU,
  CREATE_VAT_TU: APIVatTu.CREATE_VAT_TU,
  UPDATE_VAT_TU: APIVatTu.UPDATE_VAT_TU,
  REMOVE_VAT_TU: APIVatTu.REMOVE_VAT_TU,
  LIST_VAT_TU_THOI_GIAN: APIVatTu.LIST_VAT_TU_THOI_GIAN,
  UPDATE_VAT_TU_THOI_GIAN: APIVatTu.UPDATE_VAT_TU_THOI_GIAN,
  LIST_LOAI_CHI_PHI: APIVatTu.LIST_LOAI_CHI_PHI,
  GET_LIST_CAU_HINH_TABLE: APIVatTu.GET_LIST_CAU_HINH_TABLE,
  UPDATE_THONG_TIN_VAT_TU: APIVatTu.UPDATE_THONG_TIN_VAT_TU,
  DELETE_VAT_TU_THOI_GIAN: APIVatTu.DELETE_VAT_TU_THOI_GIAN,
  LIST_VAT_TU_THEO_NGAY_TIEP_NHAN: APIVatTu.LIST_VAT_TU_THEO_NGAY_TIEP_NHAN,
  GET_DON_GIA_NHAN_CONG: APIVatTu.GET_DON_GIA_NHAN_CONG,
  LIST_VAT_TU_BAO_CAO: APIVatTu.LIST_VAT_TU_BAO_CAO,
  IMPORT_FILE_VAT_TU_MOI: APIVatTu.IMPORT_FILE_VAT_TU_MOI,
  IMPORT_FILE_VAT_TU_CO_SAN: APIVatTu.IMPORT_FILE_VAT_TU_CO_SAN,

  //Email

  LIST_EMAIL: APIEmail.LIST_EMAIL,
  SYNC_EMAIL: APIEmail.SYNC_EMAIL,
  GET_EMAIL: APIEmail.GET_EMAIL,
  CREATE_EMAIL: APIEmail.CREATE_EMAIL,
  UPDATE_EMAIL: APIEmail.UPDATE_EMAIL,
  REMOVE_EMAIL: APIEmail.REMOVE_EMAIL,

  //Vùng đơn giá

  LIST_VUNG_DON_GIA: APIVungDonGia.LIST_VUNG_DON_GIA,
  SYNC_VUNG_DON_GIA: APIVungDonGia.SYNC_VUNG_DON_GIA,
  GET_VUNG_DON_GIA: APIVungDonGia.GET_VUNG_DON_GIA,
  CREATE_VUNG_DON_GIA: APIVungDonGia.CREATE_VUNG_DON_GIA,
  UPDATE_VUNG_DON_GIA: APIVungDonGia.UPDATE_VUNG_DON_GIA,
  REMOVE_VUNG_DON_GIA: APIVungDonGia.REMOVE_VUNG_DON_GIA,

  //đơn vị

  LIST_DON_VI: APIDonVi.LIST_DON_VI,
  LIST_DON_VI_CHUYEN_DV: APIDonVi.LIST_DON_VI_CHUYEN_DV,
  SYNC_DON_VI: APIDonVi.SYNC_DON_VI,
  GET_DON_VI: APIDonVi.GET_DON_VI,
  CREATE_DON_VI: APIDonVi.CREATE_DON_VI,
  UPDATE_DON_VI: APIDonVi.UPDATE_DON_VI,
  REMOVE_DON_VI: APIDonVi.REMOVE_DON_VI,

  //loại điểm đo

  LIST_LOAI_DIEM_DO: APILoaiDiemDo.LIST_LOAI_DIEM_DO,
  SYNC_LOAI_DIEM_DO: APILoaiDiemDo.SYNC_LOAI_DIEM_DO,
  GET_LOAI_DIEM_DO: APILoaiDiemDo.GET_LOAI_DIEM_DO,
  CREATE_LOAI_DIEM_DO: APILoaiDiemDo.CREATE_LOAI_DIEM_DO,
  UPDATE_LOAI_DIEM_DO: APILoaiDiemDo.UPDATE_LOAI_DIEM_DO,
  REMOVE_LOAI_DIEM_DO: APILoaiDiemDo.REMOVE_LOAI_DIEM_DO,

  //Lý do treo tháo

  LIST_LY_DO_TREO_THAO: APILyDoTreoThao.LIST_LY_DO_TREO_THAO,
  CREATE_LY_DO_TREO_THAO: APILyDoTreoThao.CREATE_LY_DO_TREO_THAO,
  UPDATE_LY_DO_TREO_THAO: APILyDoTreoThao.UPDATE_LY_DO_TREO_THAO,
  REMOVE_LY_DO_TREO_THAO: APILyDoTreoThao.REMOVE_LY_DO_TREO_THAO,

  //Chủng loại công tơ

  LIST_CHUNG_LOAI_CONG_TO: APIChungLoaiCongTo.LIST_CHUNG_LOAI_CONG_TO,
  GET_CHUNG_LOAI_CONG_TO: APIChungLoaiCongTo.GET_CHUNG_LOAI_CONG_TO,
  CREATE_CHUNG_LOAI_CONG_TO: APIChungLoaiCongTo.CREATE_CHUNG_LOAI_CONG_TO,
  UPDATE_CHUNG_LOAI_CONG_TO: APIChungLoaiCongTo.UPDATE_CHUNG_LOAI_CONG_TO,
  REMOVE_CHUNG_LOAI_CONG_TO: APIChungLoaiCongTo.REMOVE_CHUNG_LOAI_CONG_TO,

  //trạng thái

  LIST_TRANG_THAI: APITrangThai.LIST_TRANG_THAI,
  CREATE_TRANG_THAI: APITrangThai.CREATE_TRANG_THAI,
  UPDATE_TRANG_THAI: APITrangThai.UPDATE_TRANG_THAI,
  REMOVE_TRANG_THAI: APITrangThai.REMOVE_TRANG_THAI,

  //Common Danh Muc

  COMMON_LIST_TRO_NGAI: APICommonDanhMuc.LIST_TRO_NGAI,
  COMMON_LIST_LO: APICommonDanhMuc.LIST_LO,
  COMMON_LIST_TRAM: APICommonDanhMuc.LIST_TRAM,
  COMMON_LIST_QUYEN: APICommonDanhMuc.LIST_QUYEN,
  COMMON_DM_LIST_HINH_THUC_LAP_DAT: APICommonDanhMuc.LIST_HINH_THUC_LAP_DAT,
  COMMON_DM_LIST_LY_DO_TREO_THAO: APICommonDanhMuc.LIST_LY_DO_TREO_THAO,
  COMMON_DM_LIST_NGANH_NGHE: APICommonDanhMuc.LIST_NGANH_NGHE,
  COMMON_DM_LIST_NHOM_NGANH_NGHE: APICommonDanhMuc.LIST_NHOM_NGANH_NGHE,
  COMMON_DM_LIST_GIA_NHOM_NGANH_NGHE: APICommonDanhMuc.LIST_GIA_NHOM_NGANH_NGHE,
  COMMON_DM_LIST_CAP_DIEN_AP: APICommonDanhMuc.LIST_CAP_DIEN_AP,
  COMMON_DM_LIST_THAM_CHIEU_CAP_DA: APICommonDanhMuc.LIST_THAM_CHIEU_CAP_DA,
  COMMON_DM_LIST_AP_GIA_UY_QUYEN: APICommonDanhMuc.LIST_AP_GIA_UY_QUYEN,
  COMMON_DM_LIST_AP_GIA_UY_QUYEN_MAC_DINH: APICommonDanhMuc.LIST_AP_GIA_UY_QUYEN_MAC_DINH,
  DONG_BO_CMIS: APICommonDanhMuc.DONG_BO_CMIS,

  //Quản lý chứng thư
  LIST_CHUNG_THU: APIQLChungThu.LIST_CHUNG_THU,
  CREATE_CHUNG_THU: APIQLChungThu.CREATE_CHUNG_THU,
  UPDATE_CHUNG_THU: APIQLChungThu.UPDATE_CHUNG_THU,
  REMOVE_CHUNG_THU: APIQLChungThu.REMOVE_CHUNG_THU,
};
