import { Pie } from "@ant-design/plots";
import { Button, Col } from "antd";
import { Fragment } from "react";
import { ContainerTiepNhan } from "../css-styled";

export default function Index(props) {
  const {
    configChart,
    configPie,
    dataBDThang,
    dataBDLK,
    handleOpenModal,
    titleLKP1,
    titleThangP1,
    width,
  } = props;

  return (
    <Fragment>
      <ContainerTiepNhan gutter={24}>
        <Col span={24}>
          <h2 className="title">I. Thống kê yêu cầu theo Nguồn tiếp nhận</h2>
        </Col>
        <Col span={24} lg={12}>
          <div className="container">
            <div className="title-chart">
              {width && width <= 1199 ? null : <h3>Tháng hiện tại</h3>}
              <Button type="link" onClick={() => handleOpenModal(true)}>
                Xem dữ liệu tháng hiện tại
              </Button>
            </div>

            <div className="box">
              <div className="box-container">
                <h3>{titleThangP1}</h3>
                <Pie
                  {...configChart(
                    configPie,
                    dataBDThang,
                    [
                      "#5B9BD5",
                      "#ED7D31",
                      "#A5A5A5",
                      "#FFD96A",
                      "#74ff6b",
                      "#14e2c7",
                    ],
                    "bottom",
                    0,
                    0
                  )}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col span={24} lg={12}>
          <div className="container">
            <div className="title-chart">
              {width && width <= 1199 ? null : <h3>Lũy kế hiện tại</h3>}
              <Button type="link" onClick={() => handleOpenModal(false)}>
                Xem dữ liệu lũy kế đến tháng hiện tại
              </Button>
            </div>

            <div className="box">
              <div className="box-container">
                <h3>{titleLKP1}</h3>
                <Pie
                  {...configChart(
                    configPie,
                    dataBDLK,
                    [
                      "#5B9BD5",
                      "#ED7D31",
                      "#A5A5A5",
                      "#FFD96A",
                      "#74ff6b",
                      "#14e2c7",
                    ],
                    "bottom",
                    0,
                    0
                  )}
                />
              </div>
            </div>
          </div>
        </Col>
      </ContainerTiepNhan>
    </Fragment>
  );
}
