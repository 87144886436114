import { kt_tb_do_dem } from "@permissions/n_kttbdodem";
import { ENUM_DS_BANGKE } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { Fragment, useState } from "react";
import Table from "../../common/ky-duyet-bang-ke";

export default function Index() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [fetchList, setFetchList] = useState(false);

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Fragment>
      <Table
        typeDS={ENUM_DS_BANGKE.TRACUU}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={false}
        rowSelection={rowSelection}
        selectedRowKeys={selectedRowKeys}
        header={"Danh sách bảng kê"}
        urlXuatExcel={Endpoint.XUAT_EXCEL_BANGKE}
        permissionExcel={kt_tb_do_dem.n63ex}
      />
    </Fragment>
  );
}
