import { Fragment, useCallback, useState } from "react";
import PhieuXuatVTTable from "../../common-ver1/phieu-vat-tu";
import { treo_thao } from "@permissions/b_treothao";
import { useDispatch } from "react-redux";
import { openModal } from "@state/system-config/reducer";
import XemPhieu from "../../common-ver1/xem-phieu";
import { ENUM_PHIEU_VAT_TU, LOAI_YEUCAU } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import ModalReturn from "@modules/treo-thao/common-ver1/modal-return-phieu-vt";
import { Button } from "antd";
import CheckPermission from "@components/CheckPermission";

export default function Index() {
  const [fetchList, setFetchList] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [arrSelectedRows, setArrSelectedRows] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setArrSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  //show popup xem phiếu
  const renderPopup = (detail) => {
    return dispatch(
      openModal({
        content: (
          <XemPhieu detail={detail} typeTreoThao={LOAI_YEUCAU.TREOTHAO} />
        ),
        size: "large",
        title: "Xem phiếu",
      })
    );
  };

  const handleGetPayloadXN = (row) => {
    const payload = {
      ids: row ? [row.id] : selectedRowKeys,
    };

    return payload;
  };

  const renderButtonTL = () => (
    <CheckPermission permissionCode={treo_thao.b72tl}>
      <Button
        type="primary "
        className="mr-5px btn-bg-yellow"
        onClick={() => handleOpenModal()}
        disabled={
          !selectedRowKeys.length ||
          selectedRowKeys.length >= 2 ||
          arrSelectedRows[0].loaiPhieuNumber === 2 ||
          arrSelectedRows[0].loaiPhieuNumber === "2"
        }
      >
        Trả lại
      </Button>
    </CheckPermission>
  );

  const [visible, setVisible] = useState(false);
  const handleOpenModal = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const handleCloseModal = () => {
    setVisible(false);
  };

  const showModalReturn = useCallback(() => {
    return (
      visible && (
        <ModalReturn
          setSelectedRowKeys={setSelectedRowKeys}
          visible={visible}
          closeModal={handleCloseModal}
          setFetchList={setFetchList}
          selectedRowKeys={selectedRowKeys}
          url={Endpoint.LANH_DAO_TRA_LAI_PHIEU_TREO_THAO}
        />
      )
    );
  }, [selectedRowKeys, visible]);

  return (
    <Fragment>
      <PhieuXuatVTTable
        url={Endpoint.LIST_PHIEU_VAT_TU}
        funcId={ENUM_PHIEU_VAT_TU.LDDUYETPHIEUVT}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        rowSelection={rowSelection}
        selectedRowKeys={selectedRowKeys}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        renderPopup={renderPopup}
        permissionCodeXacNhan={treo_thao.b72xn}
        permissionCodeKySo={treo_thao.b72k}
        permissionCodeXemPhieu={treo_thao.b72v}
        urlXN={Endpoint.LANH_DAO_XAC_NHAN_PHIEU_VT}
        titleXN="Lãnh đạo xác nhận/ký số"
        contentXN={
          <Fragment>
            <div>Phiếu Xuất vật tư sẽ được chuyển tiếp cho Phòng vật tư !</div>
            <div>
              Phiếu Trả vật tư sau khi Lãnh đạo ký duyệt thành công sẽ hoàn tất
              việc trả vật tư !
            </div>
          </Fragment>
        }
        payloadXN={handleGetPayloadXN}
        isIcon={false}
        isKySo={true}
        // urlKySo={Endpoint.XAC_THUC_KY_SO_PHIEUVT}
        urlKySo={Endpoint.KY_SO_PHIEU_VAT_TU_HSM}
        urlGetHash={Endpoint.KY_SO_GET_HASH_PHIEUVT}
        payloadKySo={{
          phieuIds: selectedRowKeys.toString(),
          // maTrangThai: arrSelectedRows[0]?.maTrangThai,
        }}
        setSelectedRowKeys={setSelectedRowKeys}
        arrSelectedRows={arrSelectedRows}
        renderButtonTL={renderButtonTL}
      />
      {showModalReturn()}
    </Fragment>
  );
}
