import React, { useState } from "react";
import "moment/locale/vi";
import { submitDongBoService } from "./services";
import ModalDongBoThoiGian from "./modal-dong-bo-thoi-gian";

export default function Index({
  detail,
  visible,
  closeModal,
  setFetchDataTable,
  getListHoSo,
  isMultiple,
  setSelectedRowKeys,
}) {
  const [loading, setLoading] = useState(false);

  // gọi api khi submit

  const handleSubmit = () => {
    submitDongBoService(
      detail,
      setLoading,
      setFetchDataTable,
      closeModal,
      getListHoSo,
      isMultiple,
      setSelectedRowKeys
    );
  };
  return (
    <ModalDongBoThoiGian
      visible={visible}
      closeModal={closeModal}
      handleSubmit={handleSubmit}
      loading={loading}
    />
  );
}
