import DanhSachYeuCau from "../../common-ver1/danh-sach-yeu-cau";
import { Fragment, useCallback, useState } from "react";
import { treo_thao } from "@permissions/b_treothao";
import { Endpoint } from "@utils/endpoint";
import {
  ENUM_DS_YEU_CAU,
  // ENUM_KYSO,
  FUNC_ID_DSYC_TREOTHAO,
  TYPE_PHIEU_YEUCAU,
} from "@utils/constants";
import ModalConfirm from "@modules/treo-thao/common-ver1/confirm-xac-nhan";
import { Button } from "antd";
// import KySoComponent from "@components/KySo";
// import ButtonKySoHSM from "@modules/treo-thao/common-ver1/ky-so-hsm";
import CheckPermission from "@components/CheckPermission";
// import { openModal } from "@state/system-config/reducer";
import { useDispatch } from "react-redux";
// import CapNhatThongTinChiSo from "@modules/treo-thao/common-ver1/cap-nhat-thong-tin-chi-so";
import { handleGetDataChiSo } from "./services";

export default function Index() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [arrSelectedRows, setArrSelectedRows] = useState([]);
  const [fetchList, setFetchList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setCodeStatus] = useState(0);
  const dispatch = useDispatch();
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setCodeStatus(selectedRows[0]?.maTrangThai);
    setArrSelectedRows(selectedRows);
  };
  //Hiển thị checkbox ở đầu mỗi bảng
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  const [visibled, setVisibled] = useState(false);
  const handleOpenXN = useCallback(() => {
    setVisibled(!visibled);
  }, [visibled]);

  const handleCloseXN = () => {
    setVisibled(false);
  };

  const showPopupXNPhieu = useCallback(() => {
    return (
      visibled && (
        <ModalConfirm
          visible={visibled}
          closeModal={handleCloseXN}
          payload={{
            ids: selectedRowKeys.toString(),
          }}
          url={Endpoint.XAC_NHAN_KY_DUYET_YEU_CAU_NV_TT_NP}
          setFetchList={setFetchList}
          content={
            "Yêu cầu đang ở bước chờ Nhân viên treo tháo và Nhân viên niêm phong ký duyệt. Sau khi cả 2 nhân viên thực hiện ký duyệt thành công, yêu cầu sẽ được chuyển tiếp cho Đội trưởng !"
          }
          title="Nhân viên treo tháo xác nhận yêu cầu treo tháo"
          dataSource={arrSelectedRows}
          type={TYPE_PHIEU_YEUCAU.YEUCAU}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      )
    );
  }, [arrSelectedRows, selectedRowKeys, visibled]);

  const [visible, setVisible] = useState(false);
  const handleOpenKySoHSM = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const handleCloseKySoHSM = () => {
    setVisible(false);
  };

  const showPopupKySo = useCallback(() => {
    return (
      visible && (
        <ModalConfirm
          visible={visible}
          closeModal={handleCloseKySoHSM}
          payload={{
            yeuCauIds: selectedRowKeys.toString(),
          }}
          isKySo
          url={Endpoint.KY_SO_YEU_CAU_HSM}
          setFetchList={setFetchList}
          content={
            "Yêu cầu đang ở bước chờ Nhân viên treo tháo và Nhân viên niêm phong ký duyệt. Sau khi cả 2 nhân viên thực hiện ký duyệt thành công, yêu cầu sẽ được chuyển tiếp cho Đội trưởng !"
          }
          title="Nhân viên treo tháo ký HSM yêu cầu treo tháo"
          dataSource={arrSelectedRows}
          type={TYPE_PHIEU_YEUCAU.YEUCAU}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      )
    );
  }, [arrSelectedRows, selectedRowKeys, visible]);

  const genExtra = () => (
    <Fragment>
      <CheckPermission permissionCode={treo_thao.b34cn}>
        <Button
          type="primary"
          className="mr-5px "
          onClick={() => handleOpenUpdateTTCS()}
          disabled={!selectedRowKeys.length || selectedRowKeys.length >= 2}
        >
          Cập nhật chỉ số công tơ
        </Button>
      </CheckPermission>
      <Button
        type="primary"
        className="mr-5px btn-bg-yellow"
        onClick={() => handleOpenXN()}
        disabled={!selectedRowKeys.length}
      >
        Xác nhận
      </Button>

      {/* <KySoComponent
        url={Endpoint.XAC_THUC_KY_SO}
        urlGetHash={Endpoint.KY_SO_GET_HASH}
        payload={{
          yeuCauIds: selectedRowKeys.toString(),
          maTrangThai: codeStatus,
        }}
        permissionCode={treo_thao.b34k}
        disabled={!selectedRowKeys.length}
        setFetchDataTable={setFetchList}
        text={"Ký số USB"}
        setLoading={setLoading}
        loading={loading}
        bgColor={"#faad14"}
        typeKySo={ENUM_KYSO.TREOTHAO}
      /> */}
      <Button
        type="primary"
        onClick={() => handleOpenKySoHSM()}
        disabled={!selectedRowKeys.length}
      >
        Ký số
      </Button>
      {/* <ButtonKySoHSM
        url={Endpoint.KY_SO_YEU_CAU_HSM}
        params={{
          yeuCauIds: selectedRowKeys.toString(),
          // maTrangThai: codeStatus,
        }}
        setFetchList={setFetchList}
        disabled={!selectedRowKeys.length}
        arrSelectedRows={arrSelectedRows}
        // type={}
        setSelectedRowKeys={setSelectedRowKeys}
        content={
          "Yêu cầu treo tháo đang ở bước Nhân viên treo tháo. Sau khi xác nhận/ký số, yêu cầu sẽ được chuyển sang bước tiếp theo"
        }
        title={"Nhân viên treo tháo ký HSM yêu cầu treo tháo"}
      /> */}
    </Fragment>
  );

  const handleOpenUpdateTTCS = () => {
    handleGetDataChiSo(
      setLoading,
      loading,
      selectedRowKeys,
      dispatch,
      setFetchList
    );
    // dispatch(
    //   openModal({
    //     content: <CapNhatThongTinChiSo />,
    //     title: "Cập nhật thông tin chỉ số",
    //   })
    // );
  };

  return (
    <Fragment>
      <DanhSachYeuCau
        funcId={FUNC_ID_DSYC_TREOTHAO.NVTREOTHAO_KYDUYETYEUCAU}
        typeList={ENUM_DS_YEU_CAU.NVTTKyDuyetYeuCau}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        genExtra={genExtra}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        rowSelection={rowSelection}
        permissionCodeBB={treo_thao.b34bb}
        title={"Nhân viên treo tháo/niêm phong xác nhận yêu cầu"}
        content={`Yêu cầu đang ở bước chờ Nhân viên treo tháo và Nhân viên niêm phong ký duyệt. Sau khi cả 2 nhân viên thực hiện ký duyệt thành công, yêu cầu sẽ được chuyển tiếp cho Đội trưởng !`}
        payloadKySo={{
          yeuCauIds: selectedRowKeys.toString(),
          // maTrangThai: codeStatus,
        }}
        permissionCodeKySo={treo_thao.b34k}
        urlXN={Endpoint.XAC_NHAN_KY_DUYET_YEU_CAU_NV_TT_NP}
        permissionCodeXN={treo_thao.b34xn}
        isKySo={true}
      />
      {showPopupXNPhieu()}
      {showPopupKySo()}
    </Fragment>
  );
}
