import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useEffect
} from "react";
import Filter from "@components/Filter/Filter";
import { TableStyled, Container } from "./css-styled";
import { Form, Tooltip, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE, ENUM_MUCDICH_SDD } from "@utils/constants";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import {
  handleListData
} from "./services";
import UploadImage from "./modal";
// import moment from "moment";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilter } from "../../common/list-bo-loc";
import TableComponent from "@components/TableComponent";
import ChiTietDiemDo from "../../common/chi-tiet-diem-do";
import { openModal } from "@state/system-config/reducer";
import CheckPermission from "@components/CheckPermission";
import { ap_gia } from "@permissions/e_apgia";
import { FilePdfOutlined, UploadOutlined, FileOutlined } from "@ant-design/icons";

export default function Index() {
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [dataPhanCong, setDataPhanCong] = useState([]);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [biTraLai, setBiTraLai] = useState(false);

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    // LoaiKhachHang: ENUM_MUCDICH_SDD.SINHHOAT,
    // ThangKiemTra: moment().format(FORMAT_MONTH_ENGLISH),
    ...parseParams(location.search),
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const onChangePagination = (paging, _filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
      BiTraLai: biTraLai ? biTraLai : null,
      Tram: values.Tram
        ? Array.isArray(values.Tram) && values.Tram.length > 0
          ? values.Tram.join()
          : values.Tram
        : [],
    }));
  }, [biTraLai]);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      // LoaiKhachHang: ENUM_MUCDICH_SDD.SINHHOAT,
      // ThangKiemTra: moment().format(FORMAT_MONTH_ENGLISH),
    });
  }, [user.unitId]);

  //search list
  const getListData = useCallback(() => {
    handleListData(
      setLoading,
      location,
      setDataPhanCong,
      setTotalCount,
      filterConditions
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getListData();
  }, [getListData]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListData();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListData, setFetchDataTable]);

  const checkTraLai = (e) => {
    setBiTraLai(e.target.checked);
  };

  const columns = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => detailDiemDo(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
      fixed: "left"
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      key: "maKhachHang",
      render: (text) => (
        <div
          className="table-text-left"
        >
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
      fixed: "left"
    },
    {
      title: "Tên KH",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => <span>{text}</span>,
      width: "12%",
      sorter: true,
    },
    {
      title: "Điện thoại",
      dataIndex: "soDienThoai",
      key: "soDienThoai",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: 'Trước kiểm tra',
      children: [
        {
          title: 'Ngày áp dụng',
          dataIndex: "ngayApDung",
          key: "ngayApDung",
          render: (text) => (
            <div className="table-text-center">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
        {
          title: 'Số hộ',
          dataIndex: "soHo",
          key: "soHo",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
        },
        {
          title: "Chuỗi giá",
          dataIndex: "chuoiGia",
          key: "chuoiGia",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        }
      ],
    },
    {
      title: 'Sau kiểm tra',
      children: [
        {
          title: 'Ngày áp dụng',
          dataIndex: "ngayApDungMoi",
          key: "ngayApDungMoi",
          render: (text) => (
            <div className="table-text-center">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
        {
          title: 'Số hộ',
          dataIndex: "soHoMoi",
          key: "soHoMoi",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
        },
        {
          title: "Chuỗi giá",
          dataIndex: "chuoiGiaMoi",
          key: "chuoiGiaMoi",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        }
      ],
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThaiYeuCau",
      key: "trangThaiYeuCau",
      render: () => (
        <Tag style={{ display: "block", textAlign: "center" }} color={"#26c0e9"}>Chờ NV áp giá duyệt</Tag>
      ),
      width: "8%",
    },
    // {
    //   title: "Tháng kiểm tra",
    //   dataIndex: "thangKiemTra",
    //   key: "thangKiemTra",
    //   render: (text) => (
    //     <div className="table-text-center">
    //       <span>{text}</span>
    //     </div>
    //   ),
    //   width: "7%",
    // },
    {
      title: "Loại khách hàng",
      dataIndex: "loaiKhachHang",
      key: "loaiKhachHang",
      render: (text, record) => (
        record.loaiKhachHang?.toString() === ENUM_MUCDICH_SDD.NGOAISINHHOAT ?
          <span>{"Ngoài sinh hoạt"}</span> :
          <span>{"Sinh hoạt"}</span>
      ),
      width: "8%",
    },
    {
      title: "Trạm",
      dataIndex: "tram",
      key: "tram",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: "Sổ GCS",
      dataIndex: "soGCS",
      key: "soGCS",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => <span>{text}</span>,
      width: "16%",
    },
    {
      title: "Mã bảng kê",
      dataIndex: "maBangKe",
      key: "maBangKe",
      render: (text) => <span>{text}</span>,
      width: "9%",
    },
    {
      title: "Tác vụ",
      dataIndex: "",
      key: "tacVu",
      render: (text, record) => (
        <div className="table-text-center">
          {record.pathFileBienBan ?
            <CheckPermission permissionCode={ap_gia.e13x}>
              <Tooltip title="Xem biên bản">
                <a
                  href={record.pathFileBienBan}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FilePdfOutlined
                    style={{ fontSize: "22px" }}
                  />
                </a>
              </Tooltip>
            </CheckPermission>
            : null
          }
          {record.pathFileBienBanGiay ?
            <CheckPermission permissionCode={ap_gia.e13t}>
              <Tooltip title="Xem BBAG giấy">
                <a
                  href={record.pathFileBienBanGiay}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FileOutlined
                    style={{ fontSize: "22px", marginLeft: '10px' }}
                  />
                </a>
              </Tooltip>
            </CheckPermission> : null}
          <CheckPermission permissionCode={ap_gia.e13t}>
            <Tooltip title="Upload BBAG giấy">
              <UploadOutlined
                style={{ fontSize: "22px", marginLeft: '10px', color: "#52c41a" }}
                onClick={() => handleOpenModal(record.id)}
              />
            </Tooltip>
          </CheckPermission>
        </div>
      ),
      width: "6%",
      fixed: "right"
    },

  ];
  const detailDiemDo = (record) => {
    return dispatch(
      openModal({
        content: (
          <ChiTietDiemDo
            detailRecord={record}
          />
        ),
        size: "large",
        title: `Chi tiết điểm đo - ${record.maDiemDo}`
      })
    );
  };

  const detailDD = (record) => {
    return {
      onDoubleClick: () => {
        detailDiemDo(record)
      },
    };
  };

  const [visible, setVisible] = useState(false);
  const [idUpload, setIdUpload] = useState();
  const [pathBBG, setPathBBG] = useState();
  const handleOpenModal = useCallback((id, path) => {
    setVisible(!visible);
    setIdUpload(id);
    setPathBBG(path)
  }, [visible]);

  const handleCloseModal = () => {
    setVisible(false);
  };

  const renderUploadImage = useCallback(() => {
    return (
      visible && (
        <UploadImage
          visible={visible}
          closeModal={handleCloseModal}
          idUpload={idUpload}
          pathBBG={pathBBG}
          setFetchDataTable={setFetchDataTable}
        />
      )
    );
  }, [visible, idUpload, pathBBG]);

  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          screen={"NVKHCK"}
          checkTraLai={checkTraLai}
        />
      </Filter>
      <Container>
        <TableStyled>
          <TableComponent
            header={"Yêu cầu khách hàng chưa ký"}
            dataSource={dataPhanCong}
            listFilter={<ListFilter form={form} />}
            columns={customColumn(columns, filterConditions)}
            totalData={totalCount}
            loading={loading}
            scrollX={3000}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            onRow={detailDD}
          />
        </TableStyled>
      </Container>
      {renderUploadImage()}
    </Fragment>
  );
}
