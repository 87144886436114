import { buildQueryString, parseParams } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import {
  authGetData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";
import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";

export const handleListBaoCao = (
  setLoading,
  filterConditions,
  setData,
  setTotalCount,
  setIsCompany,
  setMonth,
  setYear,
  setQuy
) => {
  authGetData({
    url:
      Endpoint.GET_LIST_BAO_CAO_TONG_HOP +
      "?" +
      buildQueryString(parseParams(filterConditions)),
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setData(res.data.datas);
        setIsCompany(res.data.isTongCongTy);
        setMonth(res.data.thang);
        setYear(res.data.nam);
        setQuy(res.data.quy);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};

export const handleGetNewDate = (ngayBaoCao, setLoading, setData) => {
  authGetData({
    url:
      Endpoint.GET_NEW_DATE_SO_LIEU_BAO_CAO_TONG_HOP +
      "?ngayBaoCao=" +
      ngayBaoCao,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setData(res.data);
      }
    },
  });
};

export const handleSubmitTongHop = (ngayBaoCao, setLoading, getNewDate) => {
  authPostData({
    url: Endpoint.TONG_HOP_SO_LIEU_BAO_CAO_TONG_HOP,
    method: METHOD_PUT,
    payload: { ngayBaoCao },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getNewDate(ngayBaoCao);
      }
    },
  });
};

export const handleMultiBoLoc = (setLoading, setDonVi, setLoaiYeuCau) => {
  const endpoints = [
    `${Endpoint.LIST_DON_VI}?pageSize=-1`,
    `${Endpoint.COMMON_LIST_LOAI_YEU_CAU}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDonVi(res[0].data.data);
      setLoaiYeuCau(res[1].data.data);
    },
  });
};
