import { memo, useState, useRef, useEffect, useCallback } from "react";
import { Endpoint } from "@utils/endpoint";
import { authSelector } from "@state/auth";
import { useSelector } from "react-redux";
import { Form, Input, Button, Modal, Row, Col, Select, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { authGetData } from "@utils/request";
import { handleSubmitForm } from "./services";
import moment from "moment";
import "moment/locale/vi";
import { FORMAT_DATE } from "@utils/constants";
import locale from "antd/es/date-picker/locale/vi_VN";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { removeAccents } from "@utils/function";

const { Option } = Select;

function QLChungThuModal({ detailDataTable, visible, closeModal, getListDataChungThu }) {
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingListNhanVien, setLoadingListNhanVien] = useState(false);
  const [listNhanVien, setListNhanVien] = useState([]);
  const [, setMaNV] = useState([]);
  const [vaiTroNV, setVaiTroNV] = useState([]);
  const [tenNV, setTenNV] = useState([]);
  const toDay = moment().format(FORMAT_DATE);
  const [imageUrl, setImageUrl] = useState(null);
  const [ngayBatDau, setNgayBatDau] = useState(
    detailDataTable && detailDataTable.ngayBatDau ? detailDataTable.ngayBatDau : toDay
  );
  const [ngayKetThuc, setNgayKetThuc] = useState(
    detailDataTable && detailDataTable.ngayKetThuc ? detailDataTable.ngayKetThuc : toDay
  );
  const inputRef = useRef(null);
  const onFinish = (values) => {
    handleSubmitForm(
      values,
      detailDataTable,
      setLoading,
      closeModal,
      getListDataChungThu,
      form,
      listNhanVien.find((x) => x.id === values.userId)?.unitId,
      ngayBatDau,
      imageUrl === null ? null : imageUrl.substring(imageUrl.indexOf(",") + 1).replace(/=+$/, "")
    );
  };

  useEffect(() => {
    detailDataTable.chuKy &&
      toDataURL(detailDataTable.chuKy, function (dataUrl) {
        setImageUrl(dataUrl);
      });
  }, [detailDataTable]);

  //thay đổi ngày hiệu lực
  const handleChangeNgayBatDau = (date) => {
    let convert = "";
    if (date === null) convert = null;
    else convert = moment(date).format(FORMAT_DATE);
    setNgayBatDau(convert);
  };

  //thay đổi ngày hết hiệu lực
  const handleChangeNgayKetThuc = (date) => {
    let convert = "";
    if (date === null) convert = null;
    else convert = moment(date).format(FORMAT_DATE);
    setNgayKetThuc(convert);
  };

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    event.target.value = null;
    const base64 = await convertBase64(file);
    setImageUrl(base64);
  };
  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  function toDataURL(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }
  const handleClick = () => {
    inputRef.current.click();
  };

  const handleRemove = useCallback(() => {
    setImageUrl(null);
  }, []);

  useEffect(() => {
    authGetData({
      url: `${Endpoint.USER}?pageSize=10000`,
      setLoading: setLoadingListNhanVien,
      onSuccess: (res) => {
        setListNhanVien(res.data);
      },
    });
  }, [user]);

  const handleChangeMaNV = useCallback(
    (value) => {
      setMaNV(value);
      setTenNV(listNhanVien.filter((obj) => obj.id.includes(value))[0].name);
      setVaiTroNV(listNhanVien.filter((obj) => obj.id.includes(value))[0].positionName);
    },
    [listNhanVien]
  );

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={detailDataTable.id ? t("ql_chung_thu.sua") : t("ql_chung_thu.them_moi")}
        visible={visible}
        onCancel={closeModal}
        className="modal-chung-thu"
        footer={null}
        width="50%"
      >
        <FormComponent
          form={form}
          name="form-chung-thu"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            ...detailDataTable,
            ngayBatDau: moment(ngayBatDau, FORMAT_DATE),
            ngayKetThuc: moment(ngayKetThuc, FORMAT_DATE),
          }}
        >
          <Row gutter={24}>
            <Col span={24} md={12}>
              {detailDataTable.id ? (
                <>
                  <Form.Item name="tenDangNhap" label="Tên tài khoản" required>
                    <Input disabled/>
                  </Form.Item>
                  <Form.Item name="userId" hidden/>
                </>
              ) : (
                <Form.Item name="userId" label="Tên tài khoản" required>
                  <Select
                    placeholder={"-chọn-"}
                    loading={loadingListNhanVien}
                    onChange={handleChangeMaNV}
                    showSearch
                    filterOption={(input, option) => {
                      if (option && option.children) {
                        return removeAccents(option.children).toLowerCase().indexOf(removeAccents(input).toLowerCase()) >= 0;
                      }
                    }}
                  >
                    {listNhanVien && listNhanVien.length > 0
                      ? listNhanVien.map((item, index) => {
                          if (item.id !== null) {
                            return (
                              <Option key={index} value={item.id}>
                                {item.userName || item.name}
                              </Option>
                            );
                          }
                          return null;
                        })
                      : null}
                  </Select>
                </Form.Item>
              )}
            </Col>
            <Col span={24} md={12}>
              <Form.Item label="Tên nhân viên" name="tenNhanVien">
                <Input disabled placeholder={tenNV}></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} md={12}>
              <Form.Item label="Số Serial" name="soSerial" required>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label="Vai trò" name="vaiTro">
                <Input disabled placeholder={vaiTroNV}></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} md={12}>
              <Form.Item label="Ngày hiệu lực" name="ngayBatDau">
                <DatePicker locale={locale} format={FORMAT_DATE} onChange={handleChangeNgayBatDau} allowClear={false} />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label="Ngày hết hiệu lực" name="ngayKetThuc">
                <DatePicker locale={locale} format={FORMAT_DATE} onChange={handleChangeNgayKetThuc} allowClear={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="chuKy" label="Chữ ký">
                <AvatarUploadContainerStyled>
                  <input className="input-upload" type="file" name="myImage" onChange={handleFileRead} ref={inputRef} />
                  <div className="avatar-preview" onClick={handleClick} style={{ width: 100, height: 100 }}>
                    {imageUrl ? (
                      <img src={imageUrl} alt="avatar" width={100} height={100} />
                    ) : (
                      <div className="no-image">
                        <PlusOutlined style={{ display: "block" }} />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    )}
                  </div>
                  <div className="remove-button">
                    <Button type="danger" size="small" onClick={() => handleRemove()}>
                      Xóa
                    </Button>
                  </div>
                </AvatarUploadContainerStyled>
                {/* <ImageUpload setImageFile={setSignature} /> */}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-chung-thu"
                loading={loading}
              >
                Lưu lại
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}
const AvatarUploadContainerStyled = styled.div`
  display: flex;
  align-items: end;
  .avatar-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
    margin: auto;
    .no-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100px;
      height: 100px;
    }
  }
  .input-upload {
    display: none !important;
  }
  .remove-button {
    margin-left: 10px;
  }
`;
export default memo(QLChungThuModal);
