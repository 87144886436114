import React from "react";
import LoadingComponent from "@components/Loading";
// import { Container } from "./css-styled";
import { Button, Col, Form, Modal, Row, Select } from "antd";
import { removeAccents } from "@utils/function";

const { Option } = Select;

export default function ModalXNAnToan(props) {
  const {
    visible,
    closeModal,
    onFinish,
    loading,
    dataSelect,
    onChangedSelect,
    selected,
    nvnp,
    nvtt,
  } = props;
  return (
    <Modal
      title="Xác nhận an toàn"
      visible={visible}
      onCancel={closeModal}
      footer={null}
    >
      <LoadingComponent loading={loading}>
        {/* <Container> */}
        <div>
          Hồ sơ của bạn đang ở Ban an toàn. Xác nhận sẽ chuyển tiếp cho Đội thi
          công !
        </div>
        <Row gutter={24} style={{ paddingTop: "24px" }}>
          <Col span={24}>
            <Form.Item label="Phiếu lệnh công tác" name="phieuLenhCongTacId">
              <Select
                placeholder="Chọn phiếu lệnh công tác"
                onChange={onChangedSelect}
                allowClear
                showSearch
                filterOption={(input, option) => {
                  if (option && option.children) {
                    return (
                      removeAccents(option.children)
                        .toLowerCase()
                        .indexOf(removeAccents(input).toLowerCase()) >= 0
                    );
                  }
                }}
              >
                {dataSelect.map((item, index) => (
                  <Option key={index} value={item?.stt}>
                    {`${item.hienThi}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {Object.keys(selected).length > 0 && (
            <React.Fragment>
              <Col
                span={24}
                style={{
                  // maxWidth: "300px",
                  margin: "0 auto",
                  fontSize: "16px",
                  // marginLeft: "135px",
                }}
              >
                <Row gutter={24}>
                  <Col span={8}>{nvtt}:</Col>
                  <Col span={6}>{selected.maSSONVTT}</Col>
                  <Col span={6}>{selected.bacAnToanNVTT}</Col>
                  <Col span={8}>{nvnp}:</Col>
                  <Col span={6}>{selected.maSSONVNP}</Col>
                  <Col span={6}>{selected.bacAnToanNVNP}</Col>
                </Row>
                {/* <div>
                  <span>{nvtt}:</span>
                  <span style={{ marginLeft: "32px" }}>
                    {selected.maSSONVTT}
                  </span>
                  <span style={{ marginLeft: "32px" }}>
                    {selected.bacAnToanNVTT}
                  </span>
                </div>
                <div>
                  <span>{nvnp}:</span>
                  <span style={{ marginLeft: "32px" }}>
                    {selected.maSSONVNP}
                  </span>
                  <span style={{ marginLeft: "32px" }}>
                    {selected.bacAnToanNVNP}
                  </span>
                </div> */}
              </Col>
            </React.Fragment>
          )}
        </Row>
        {/* </Container> */}
        <Row gutter={24} justify="center" style={{ marginTop: "48px" }}>
          <Col span={24} md={12} style={{ textAlign: "right" }}>
            <Button key="back" onClick={closeModal} className="button-closed">
              Đóng lại
            </Button>
          </Col>
          <Col span={24} md={12} style={{ textAlign: "left" }}>
            <Button
              type="primary"
              key="submit"
              loading={loading}
              onClick={onFinish}
            >
              {"Đồng ý"}
            </Button>
          </Col>
        </Row>
      </LoadingComponent>
    </Modal>
  );
}
