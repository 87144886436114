const e1 = {
  e1: "e1",
  e1v: "e1v",
};
const e11 = {
  e11: "e11",
  e11v: "e11v",
};
const e12 = {
  e12: "e12",
  e12v: "e12v",
  e12l: "e12l",
};
const e13 = {
  e13: "e13",
  e13v: "e13v",
  e13x: "e13x",
  e13t: "e13t",
};
const e14 = {
  e14: "e14",
  e14v: "e14v",
  e14n: "e14n",
  e14k: "e14k",
  e14x: "e14x",
};
const e15 = {
  e15: "e15",
  e15v: "e15v",
  e15l: "e15l",
  e15x: "e15x",
};
const e16 = {
  e16: "e16",
  e16v: "e16v",
  e16k: "e16k",
  e16x: "e16x",
  e16h: "e16h",
  e16xb: "e16xb",
};
const e2 = {
  e2: "e2",
  e2v: "e2v",
};
const e21 = {
  e21: "e21",
  e21v: "e21v",
  e21p: "e21p",
  e21h: "e21h",
};
const e22 = {
  e22: "e22",
  e22v: "e22v",
  e22k: "e22k",
  e22x: "e22x",
  e22t: "e22t",
  e22xb: "e22xb",
};
const e23 = {
  e23: "e23",
  e23v: "e23v",
  e23k: "e23k",
  e23x: "e23x",
  e23t: "e23t",
  e23xb: "e23xb",
};
const e24 = {
  e24: "e24",
  e24v: "e24v",
  e24x: "e24x",
  e24c: "e24c",
};
const e3 = {
  e3: "e3",
  e3v: "e3v",
};
const e31 = {
  e31: "e31",
  e31v: "e31v",
  e31d: "e31d",
};
const e32 = {
  e32: "e32",
  e32v: "e32v",
  e32k: "e32k",
  e32x: "e32x",
  e32t: "e32t",
  e32xb: "e32xb",
};
const e33 = {
  e33: "e33",
  e33v: "e33v",
  e33d: "e33d",
  e33xb: "e33xb",
  e33t: "e33t",
};
const e34 = {
  e33: "e34",
  e34v: "e34v",
  e34p: "e34p",
  e34hp: "e34hp",
  e34hy: "e34hy",
};
const e4 = {
  e4: "e4",
  e4v: "e4v",
};
const e41 = {
  e41: "e41",
  e41v: "e41v",
  e41k: "e41k",
  e41x: "e41x",
  e41t: "e41t",
  e41xb: "e41xb",
};
const e5 = {
  e5: "e5",
  e5v: "e5v",
};
const e51 = {
  e51: "e51",
  e51v: "e51v",
};
const e52 = {
  e52: "e52",
  e52v: "e52v",
  e52xb: "e52xb",
};
const e6 = {
  e6: "e6",
  e6v: "e6v",
};
const e61 = {
  e61: "e61",
  e61v: "e61v",
  e61t: "e61t",
  e61s: "e61s",
  e61x: "e61x",
};
const ag = {
  e: "e",
};

export const ap_gia = {
  ...ag,
  ...e1,
  ...e11,
  ...e12,
  ...e13,
  ...e14,
  ...e15,
  ...e16,
  ...e2,
  ...e21,
  ...e22,
  ...e23,
  ...e24,
  ...e3,
  ...e31,
  ...e32,
  ...e33,
  ...e34,
  ...e4,
  ...e41,
  ...e5,
  ...e51,
  ...e52,
  ...e6,
  ...e61
};
