import { Modal } from "antd";
import styled from "styled-components";

export const ContainerModal = styled(Modal)`
  width: 100% !important;
  .ant-modal-body {
  height: calc(100vh - 75px);
    overflow-x: auto;
    padding: 0;
  }
  canvas {
    padding: 8px;
  }
`;