import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  getErrorMessage,
  parseParams,
} from "@utils/function";
import { authDeleteData, authGetData, authPostData } from "@utils/request";

export const handleListData = (
  filterConditions,
  setLoading,
  setData,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.LIST_THOI_GIAN_LAM_VIEC}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setData(res.data);
        setTotalCount(res.paging.totalCount);
      } else setData([]);
    },
  });
};

const handleCreateData = (data, setLoading, closeModal, getListData, form) => {
  authPostData({
    url: Endpoint.CREATE_THOI_GIAN_LAM_VIEC,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        closeModal();
        getListData();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

const handleUpdateData = (data, setLoading, closeModal, getListData, form) => {
  authPostData({
    url: Endpoint.UPDATE_THOI_GIAN_LAM_VIEC,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListData();
        closeModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleRemoveData = (ids, setLoading, getListData) => {
  authDeleteData({
    url: Endpoint.REMOVE_THOI_GIAN_LAM_VIEC + "?Ids=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) getListData();
    },
  });
};

export const handleSubmitForm = (
  data,
  detail,
  setLoading,
  closeModal,
  getListData,
  form
) => {
  if (detail.id) {
    data.id = detail.id;
  }

  handleCreateUpdate(data, setLoading, closeModal, getListData, form);
};

const handleCreateUpdate = (
  data,
  setLoading,
  closeModal,
  getListData,
  form
) => {
  if (data.id) {
    //sửa dữ liệu

    handleUpdateData(data, setLoading, closeModal, getListData, form);
  } else {
    //tạo dữ liệu

    handleCreateData(data, setLoading, closeModal, getListData, form);
  }
};
