import { createSlice } from "@reduxjs/toolkit";
export const namespace = "phanQuyenDiemDo";

const INITAL_STATE = {
  canShowDialogLeavingPage: false,
  // inTab: false,
  phanQuyenDiemDo: false,

};

const slice = createSlice({
  name: namespace,
  initialState: INITAL_STATE,
  reducers: {
    // notChangeComponent: (state, action) => {
    //   return {
    //     ...state,
    //     canShowDialogLeavingPage: action.payload,
    //   };
    // },
    notSavedPhanQuyenDiemDo: (state, action) => {
      return {
        ...state,
        phanQuyenDiemDo: action.payload,
      };
    },
    // rangeOfChange: (state, action) => {
    //   return {
    //     ...state,
    //     inTab: action.payload,
    //   };
    // },
    setCanShowDialogLeavingTT: (state, action) => {
      return {
        ...state,
        canShowDialogLeavingPage: action.payload,
      };
    },
  },
});

export const reducer = slice.reducer;

export const { notSavedPhanQuyenDiemDo, setCanShowDialogLeavingTT } = slice.actions;

export const phanQuyenDiemDoSelector = (state) => state[namespace];
