import { BASE_API_URL_DANH_MUC } from "@utils/constants";

export const APIVatTu = {
  LIST_VAT_TU: `${BASE_API_URL_DANH_MUC}/vat-tu/list-vat-tu`,
  SYNC_VAT_TU: `${BASE_API_URL_DANH_MUC}/vat-tu/sync`,
  CREATE_VAT_TU: `${BASE_API_URL_DANH_MUC}/vat-tu/create`,
  UPDATE_VAT_TU: `${BASE_API_URL_DANH_MUC}/vat-tu/update`,
  REMOVE_VAT_TU: `${BASE_API_URL_DANH_MUC}/vat-tu/delete`,
  GET_VAT_TU: `${BASE_API_URL_DANH_MUC}/vat-tu/get`,
  LIST_VAT_TU_THOI_GIAN: `${BASE_API_URL_DANH_MUC}/vat-tu/list-vat-tu-thoi-gian`,
  UPDATE_VAT_TU_THOI_GIAN: `${BASE_API_URL_DANH_MUC}/vat-tu/update`,
  LIST_LOAI_CHI_PHI: `${BASE_API_URL_DANH_MUC}/vat-tu/list-loai-chi-phi`,
  GET_LIST_CAU_HINH_TABLE: `${BASE_API_URL_DANH_MUC}/vat-tu/get-cau-hinh-table`,
  UPDATE_THONG_TIN_VAT_TU: `${BASE_API_URL_DANH_MUC}/vat-tu/update-thong-tin`,
  DELETE_VAT_TU_THOI_GIAN: `${BASE_API_URL_DANH_MUC}/vat-tu/delete-vat-tu-thoi-gian`,
  LIST_VAT_TU_THEO_NGAY_TIEP_NHAN: `${BASE_API_URL_DANH_MUC}/vat-tu/list-theo-ngay-tiep-nhan`,
  GET_DON_GIA_NHAN_CONG: `${BASE_API_URL_DANH_MUC}/vat-tu/get-don-gia-nhan-cong`,
  LIST_VAT_TU_BAO_CAO: `${BASE_API_URL_DANH_MUC}/vat-tu/list-vat-tu-bao-cao`,
  IMPORT_FILE_VAT_TU_MOI: `${BASE_API_URL_DANH_MUC}/vat-tu/import-vat-tu-moi`,
  IMPORT_FILE_VAT_TU_CO_SAN: `${BASE_API_URL_DANH_MUC}/vat-tu/import-vat-tu-cu`,
};
