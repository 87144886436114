import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APIApGia = {
  XAC_NHAN_CHUYEN_AP_GIA: `${BASE_API_URL_CAP_DIEN}/ap-gia-ho-so/xac-nhan-chuyen-ap-gia`,
  AP_GIA_HO_SO: `${BASE_API_URL_CAP_DIEN}/ap-gia-ho-so/ap-gia-ho-so`,
  AP_GIA_TI_LE_BAN_DIEN: `${BASE_API_URL_CAP_DIEN}/ap-gia-ho-so/list-ho-so-ti-le-gia-ban-dien`,
  GET_HO_SO_AP_GIA: `${BASE_API_URL_CAP_DIEN}/ap-gia-ho-so/get-ho-so-ap-gia`,
  IN_BIEN_BAN_AP_GIA: `${BASE_API_URL_CAP_DIEN}/ap-gia-ho-so/in-bien-ban-ap-gia`,
  LIST_TI_LE_GIA_BAN_DIEN_AP_GIA: `${BASE_API_URL_CAP_DIEN}/ap-gia-ho-so/list-ti-le-ban-dien-ap-gia`,
  TRA_LAI_AP_GIA_HO_SO: `${BASE_API_URL_CAP_DIEN}/ap-gia-ho-so/tra-lai-ap-gia`,
};
