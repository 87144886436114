import { BASE_API_URL_BAO_CAO } from "@utils/constants";

export const APIBCNgienCuuPhuTai = {
  BC_NCPT_THEM_CONG_SUAT_YEU_CAU: `${BASE_API_URL_BAO_CAO}/bckd/nghiencuuphutai/nghiencuuphutai/them-cong-suat-yeu-cau-se-tiet-giam-cua-evn-hn`,
  BC_NCPT_SUA_CONG_SUAT_YEU_CAU: `${BASE_API_URL_BAO_CAO}/bckd/nghiencuuphutai/nghiencuuphutai/sua-cong-suat-yeu-cau-se-tiet-giam-cua-evn-hn`,
  BC_NCPT_CONG_SUAT_YEU_CAU: `${BASE_API_URL_BAO_CAO}/bckd/nghiencuuphutai/nghiencuuphutai/cong-suat-yeu-cau-se-tiet-giam-cua-evn-hn`,
  BC_NCPT_DANH_SACH_NGAY_DUOC_TINH_DE_DUA_RA_BIEU_DO: `${BASE_API_URL_BAO_CAO}/bckd/nghiencuuphutai/nghiencuuphutai/danh-sach-ngay-duoc-tinh-de-dua-ra-bieu-do-co-so`,
  BC_NCPT_DANH_SACH_LOAI_NGAY: `${BASE_API_URL_BAO_CAO}/bckd/nghiencuuphutai/nghiencuuphutai/danh-sach-loai-ngay`,
  BC_NCPT_DANH_SACH_KHU_CONG_NGHIEP: `${BASE_API_URL_BAO_CAO}/bckd/nghiencuuphutai/nghiencuuphutai/danh-sach-khu-cong-nghiep`,
  BC_NCPT_DANH_SACH_DIEM_DO_DRMS: `${BASE_API_URL_BAO_CAO}/bckd/nghiencuuphutai/nghiencuuphutai/danh-sach-diem-do-drms`,
  BC_NCPT_THEM_NGAY_DUOC_TINH_DE_DUA_RA_BIEU_DO_CO_SO: `${BASE_API_URL_BAO_CAO}/bckd/nghiencuuphutai/nghiencuuphutai/them-ngay-duoc-tinh-de-dua-ra-bieu-do-co-so`,
  BC_NCPT_CHI_TIET_NGAY_DUOC_TINH_DE_DUA_RA_BIEU_DO_CO_SO: `${BASE_API_URL_BAO_CAO}/bckd/nghiencuuphutai/nghiencuuphutai/chi-tiet-ngay-duoc-tinh-de-dua-ra-bieu-do-co-so`,
  BC_NCPT_SUA_NGAY_DUOC_TINH_DE_DUA_RA_BIEU_DO_SO: `${BASE_API_URL_BAO_CAO}/bckd/nghiencuuphutai/nghiencuuphutai/sua-ngay-duoc-tinh-de-dua-ra-bieu-do-so`,
  BC_NCPT_THEM_DIEM_DO_DRMS: `${BASE_API_URL_BAO_CAO}/bckd/nghiencuuphutai/nghiencuuphutai/them-diem-do-drms`,
  BC_NCPT_CHI_TIET_THEM_DIEM_DO_DRMS: `${BASE_API_URL_BAO_CAO}/bckd/nghiencuuphutai/nghiencuuphutai/danh-sach-diem-do-drms`,
};
