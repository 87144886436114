import React from "react";
import { Button, Modal, Row, Col, Form } from "antd";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { Container } from "./css-styled";
import TextArea from "antd/lib/input/TextArea";
export default function HuyBangKeModal(props) {
  const { loading, visibleHuy, handleCloseModalhuy, onFinish, form } = props;
  return (
    <Modal
      title={"Hủy bảng kê"}
      visible={visibleHuy}
      onCancel={handleCloseModalhuy}
      footer={null}
    >
      <LoadingComponent loading={loading}>
        <FormComponent
          form={form}
          name="form-huy-bang-ke"
          onFinish={onFinish}
          initialValues={{}}
          layout="vertical"
          autoComplete="off"
        >
          <Container>
            <Row gutter={24}>
              <Col span={24} style={{ marginBottom: "15px" }}>
                <span>
                  Bảng kê sẽ được trả về lập bảng kê ở bộ phận tiếp nhận !
                </span>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Lý do hủy"
                  name="lyDoHuy"
                  className="required-field"
                >
                  <TextArea rows={3} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} justify="center">
              <Col span={12} style={{ textAlign: "right" }}>
                <Button
                  key="back"
                  onClick={handleCloseModalhuy}
                  className="button-closed"
                >
                  {"Đóng lại"}
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  key="submit"
                  type="primary"
                  className="button-primary"
                  htmlType="submit"
                  form="form-huy-bang-ke"
                >
                  {"Đồng ý"}
                </Button>
              </Col>
            </Row>
          </Container>
        </FormComponent>
      </LoadingComponent>
    </Modal>
  );
}
