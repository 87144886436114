import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { TABLE_COLUMNS_THEO_LOAI_YEU_CAU } from "@modules/tich-hop-CRM/common-ho-so/constant";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";

import CRMFilter from "@modules/tich-hop-CRM/common-ho-so/list-bo-loc";

import { MenuOutlined } from "@ant-design/icons";
import ThongTinChiTietTabs from "@modules/tich-hop-CRM/common-ho-so/chi-tiet-yeu-cau/index";
import { dateConditionLogic } from "@modules/tich-hop-CRM/common-ho-so/functions";
import { closeModal, openModal } from "@state/system-config/reducer";
import { Button, Dropdown, Form } from "antd";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { Style } from "./css-styled";
import ListChucNang from "./list-chuc-nang";
import { getLinhVucList } from "./services";
import CapNhatYeuCauModal from "./modal/cap-nhat-yeu-cau-modal";
function YeuCauCoTroNgai() {
  const location = useLocation();
  const [, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const donViId = useSelector((state) => state.auth.user.unitId);
  const [form] = Form.useForm();
  const [isDoubleClick] = useState(true);
  const [listLoading, setListLoading] = useState(false);
  const [linhVucList, setLinhVucList] = useState([]);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [detail, setDetail] = useState({});

  const dispatch = useDispatch();

  const listTrangThai = [
    {
      name: "Có trở ngại, trả GDV",
      value: "7d861b83-9e05-442a-9846-328826897b52",
    },
  ];

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DenNgay: `${moment().format("YYYY-MM-DD")}`,
    TuNgay: `${moment().subtract(7, "d").format("YYYY-MM-DD")}`,
    DonViId: donViId,
    MaTrangThai: 7,

    ...parseParams(location.search),
  });

  const [, setSearchParams] = useSearchParams();

  const columns = useMemo(() => {
    return [
      {
        title: "",
        dataIndex: "",
        width: 60,
        render: (row) => (
          <div style={{ textAlign: "center" }}>
            <Dropdown
              overlay={
                <div
                  className="list-menu-function"
                  style={{
                    boxShadow:
                      " 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
                  }}
                >
                  <ListChucNang
                    row={row}
                    setIsShowDetail={setIsShowDetail}
                    setDetail={setDetail}
                  ></ListChucNang>
                </div>
              }
              trigger={["click"]}
            >
              <Button type="primary" icon={<MenuOutlined />}></Button>
            </Dropdown>
          </div>
        ),
      },
      ...TABLE_COLUMNS_THEO_LOAI_YEU_CAU.LOAI_4,
    ];
  }, [data]);

  const handleSearch = useCallback(
    (values) => {
      const ojbStartDate = new Date(values.TuNgay);
      const objEndDate = new Date(values.DenNgay);
      const dateCondition = dateConditionLogic(ojbStartDate, objEndDate);

      const payload = {
        TuNgay: dateCondition.formatStartDate,
        DenNgay: dateCondition.formatEndDate,
        MaTrangThai: 7,
        ...(values.KenhId && { KenhId: values.KenhId }),
        ...(values.DichVuId && { DichVuId: values.DichVuId }),
        ...(values.DoiId && { DoiId: values.DoiId }),
        ...(values.DonViId && { DonViId: values.DonViId }),
        ...(values.TuKhoaTimKiem && { TuKhoaTimKiem: values.TuKhoaTimKiem }),
        ...(values.LinhVuc && { LinhVuc: values.LinhVuc }),
        ...(values.TrangThai && { TrangThai: values.TrangThai }),
        ...(values.LoaiYeuCau && { LoaiYeuCau: values.LoaiYeuCau }),
        pageIndex: DEFAULT_PAGEINDEX,
        pageSize: DEFAULT_PAGESIZE,
      };

      setFilterConditions(payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterConditions, setSearchParams]
  );

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const handleGetLinhVucList = () => {
    const status = 7;
    getLinhVucList(
      setLoading,
      form,
      setData,
      filterConditions,
      setTotal,
      location,
      setListLoading,
      status,
      setLinhVucList
    );
  };

  useEffect(() => {
    handleGetLinhVucList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions]);

  const clearFilter = useCallback(() => {
    const payload = {
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DenNgay: `${moment().format("YYYY-MM-DD")}`,
      TuNgay: `${moment().subtract(7, "d").format("YYYY-MM-DD")}`,
      DonViId: donViId,
      LinhVuc: linhVucList[0].value,
      MaTrangThai: 7,
    };

    setFilterConditions(payload);

    form.setFieldsValue({
      LinhVuc: linhVucList[0].value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };
  const detailHoSo = (record) => {
    if (isDoubleClick)
      return {
        onDoubleClick: () => {
          dispatch(
            openModal({
              content: (
                <ThongTinChiTietTabs
                  detailHoSo={record}
                  closeModal={closeModal}
                />
              ),
              size: "large",
              title: <>Chi tiết yêu cầu</>,
            })
          );
        },
      };
  };

  return (
    <Style>
      {isShowDetail ? (
        <CapNhatYeuCauModal
          closeModal={closeModal}
          detail={detail}
          linhVucList={linhVucList}
          setListLoading={setListLoading}
          filterConditions={filterConditions}
          setTotal={setTotal}
          location={location}
          setData={setData}
          formFilter={form}
          setLinhVucList={setLinhVucList}
          setIsShowDetail={setIsShowDetail}
        />
      ) : (
        <>
          <Filter>
            <CRMFilter
              handleSearch={handleSearch}
              filterConditions={filterConditions}
              form={form}
              clearFilter={clearFilter}
              listTrangThai={listTrangThai}
            />
          </Filter>
          <div className="yeu-cau-tra-ve-doi-table">
            <TableComponent
              dataSource={data}
              columns={columns}
              scrollX={1600}
              expandable={false}
              header="Danh sách"
              rowKey="key"
              totalData={total}
              pagination={filterConditions}
              onChangePagination={onChangePagination}
              loading={listLoading}
              onRow={detailHoSo}
            />
          </div>
        </>
      )}
    </Style>
  );
}

export default YeuCauCoTroNgai;
