/* eslint-disable react-hooks/exhaustive-deps */
import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { FORMAT_MONTH_ENGLISH } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Form, Row, Select } from "antd";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SelectMaSoGCS from "../../common/SelectGetMaSoGCS";
import shouldUpdateMaso from "../../common/should-update-ma-so";

const FilterScreen1 = (props) => {
  const { clearData, filterConditions, endPoint, loading, setFilterConditions, customHandle } = props;
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [queryMaso, setQueryMaso] = useState({
    pageIndex: 1,
    pageSize: 50,
  });

  const [listMaso, setListMaso] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      ...filterConditions,
      maSoGCS: filterConditions?.maSoGCS || [],
    });
  }, [filterConditions]);

  const clear = () => {
    form.resetFields();
    form.setFieldsValue({ maSoGCS: undefined });
    clearData();
  };

  const handleSearch = (values) => {
    if (customHandle) customHandle(values);
    else
      setFilterConditions((oldState) => ({
        ...oldState,
        ...values,
      }));
  };
  const handleChangeUnit = () => {
    form.setFieldsValue({
      toDoiId: undefined,
    });
  };
  return (
    <FormComponent
      form={form}
      initialValues={{
        kyGcs: "1",
        donViId: user.unitId,
      }}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
    >
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="donViId" label="Đơn vị">
            <Selection url={endPoint.GET_UNIT_LIST} form={form} formKey="donViId" setValue={handleChangeUnit} />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.donViId !== currentValues.donViId}>
            {({ getFieldValue }) => {
              if (getFieldValue("donViId")) {
                return (
                  <Form.Item name="toDoiId" label="Tổ/Đội">
                    <Selection
                      url={`${endPoint.AUTH_LIST_TO_DOI}?donViId=${getFieldValue("donViId")}`}
                      formKey="toDoiId"
                      form={form}
                      disabled={!getFieldValue("donViId")}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="toDoiId" label="Tổ/Đội">
                  <Select disabled />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.donViId !== currentValues.donViId) {
                form.setFieldsValue({
                  tramId: undefined,
                });
              }
              return prevValues.donViId !== currentValues.donViId;
            }}
          >
            {({ getFieldValue }) => {
              if (getFieldValue("donViId")) {
                return (
                  <Form.Item name="tramId" label="Trạm">
                    <Selection
                      url={`${endPoint.LIST_TRAM_CT}?donviId=${getFieldValue("donViId")}`}
                      formKey="tramId"
                      form={form}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="tramId" label="Trạm">
                  <Selection url={`${endPoint.LIST_TRAM_CT}?donviId=${user.unitId}`} formKey="tramId" form={form} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24} md={3}>
          <Form.Item name="kyGcs" label="Kỳ GCS" labelAlign="right">
            <Select>
              <Select.Option value="1">1</Select.Option>
              <Select.Option value="2">2</Select.Option>
              <Select.Option value="3">3</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={3}>
          <Form.Item name="thangNam" label="Tháng /Năm" labelAlign="right">
            <DatePickerComponent picker="month" formKey="thangNam" format={FORMAT_MONTH_ENGLISH} form={form} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} md={24}>
          <Form.Item
            name="maSoGCS"
            label="Mã sổ"
            shouldUpdate={(prevValues, currentValues) => {
              shouldUpdateMaso({prevValues, currentValues, setQueryMaso, form, setListMaso, fieldUpdate:"maSoGCS", isFilterScreen: true})
            }}
          >
            <SelectMaSoGCS
              query={queryMaso}
              setQuery={setQueryMaso}
              url={Endpoint.LIST_MA_SO_BANG_KE_NO_TO_DOI}
              formKey="maSoGCS"
              form={form}
              mode={"multiple"}
              maxTagCount={10}
              listMaso={listMaso}
              setListMaso={setListMaso}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        {!customHandle && (
          <Button className="mr-24px" onClick={clear}>
            Bỏ lọc
          </Button>
        )}
        <Button type="primary" htmlType="submit" loading={loading}>
          {customHandle ? "In bảng kê" : "Tìm kiếm"}
        </Button>
      </Row>
    </FormComponent>
  );
};
export default FilterScreen1;
