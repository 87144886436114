import { useState, useEffect } from "react";
import { Form, Button, Modal, Row, Col, Select } from "antd";
// import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { removeAccents } from "@utils/function";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";

const { Option } = Select;

export default function PhanCongModal(props) {
  const {
    visible,
    closeModal,
    onFinish,
    loadingTenNV,
    dataApGiaUyQuyen,
    dataApGiaUyQuyenMacDinh,
  } = props;
  const [loading,] = useState(false);
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [form]);

  return (
    <Modal
      title="Phân Công Áp Giá"
      visible={visible}
      onCancel={closeModal}
      className="modal-phan-cong"
      footer={null}
    >
      <LoadingComponent loading={loadingTenNV}>
        <Form
          form={form}
          name="form-phan-cong"
          layout="horizontal"
          onFinish={onFinish}
          initialValues={{
            uyQuyenId: dataApGiaUyQuyenMacDinh && dataApGiaUyQuyenMacDinh.value,
          }}
        >
          <Row gutter={24}>
            <Col span={6}>
              <span>Đơn vị:</span>
            </Col>
            <Col span={18}>
              <Form.Item name="donViId" label="">
                <span>{user.unitName}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <span>Đội:</span>
            </Col>
            <Col span={18}>
              <Form.Item name="doiId" label=""
                rules={[
                  {
                    required: true,
                    message: `Đội không được bỏ trống.`,
                  },
                ]}
              >
                <Selection
                  url={`${Endpoint.GET_TEAM_LIST}?donviId=${user.unitId}`}
                  formKey="doiId"
                  form={form}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <span>Nhân viên kiểm tra:</span>
            </Col>
            <Col span={24} md={18}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  if (prevValues.doiId !== currentValues.doiId) {
                    form.setFieldsValue({
                      userId: undefined,
                    });
                  }
                  return prevValues.doiId !== currentValues.doiId;
                }}
                rules={[
                  {
                    required: true,
                    message: `Nhân viên kiểm tra không được bỏ trống.`,
                  },
                ]}
              >
                {({ getFieldValue }) => {
                  if (getFieldValue("doiId")) {
                    return (
                      <Form.Item name="userId" label=""
                        rules={[
                          {
                            required: true,
                            message: `Nhân viên kiểm tra không được bỏ trống.`,
                          },
                        ]}
                      >
                        <Selection
                          url={`${Endpoint.AG_LIST_NV_PHAN_CONG}?donViId=${user.unitId}&doiId=${getFieldValue(
                            "doiId"
                          )}`}
                          formKey="userId"
                          form={form}
                        />
                      </Form.Item>
                    );
                  }
                  return (
                    <Form.Item name="userId" label=""
                      rules={[
                        {
                          required: true,
                          message: `Nhân viên kiểm tra không được bỏ trống.`,
                        },
                      ]}
                    >
                      <Selection
                        // url={`${Endpoint.AG_LIST_NV_PHAN_CONG}?donviId=${user.unitId}&doiId=${user.teamId}`}
                        formKey="userId"
                        form={form}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            {/* <Col span={18}>
              <Form.Item
                name="userId" label=""
                rules={[
                  {
                    required: true,
                    message: `Nhân viên áp giá không được bỏ trống.`,
                  },
                ]}
              >
                <Selection
                  url={`${Endpoint.AG_LIST_NV_PHAN_CONG}?donViId=${user.unitId}&doiId=${user.teamId}`}
                  formKey="userId"
                  form={form}
                />
              </Form.Item>
            </Col> */}
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <span>Ủy quyền áp giá:</span>
            </Col>
            <Col span={18}>
              <Form.Item name="uyQuyenId" label=""
                rules={[
                  {
                    required: true,
                    message: `Ủy quyền áp giá không được bỏ trống.`,
                  },
                ]}>
                <Select showSearch filterOption={(input, option) => {
                  if (option && option.children) {
                    return (
                      removeAccents(option.children)
                        .toLowerCase()
                        .indexOf(removeAccents(input).toLowerCase()) >= 0
                    );
                  }
                }}
                  defaultValue={dataApGiaUyQuyenMacDinh && dataApGiaUyQuyenMacDinh.value}
                >
                  {dataApGiaUyQuyen && dataApGiaUyQuyen.length > 0
                    ? dataApGiaUyQuyen.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                Đóng lại
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-phan-cong"
                loading={loading}
              >
                Phân Công
              </Button>
            </Col>
          </Row>
        </Form>
      </LoadingComponent>
    </Modal>
  );
}
