import React, { useState } from 'react'
import ListAnh from '@modules/tich-hop-CRM/common-ho-so/tai-anh-ho-so/list-anh/index'
import { Divider } from 'antd';
function DanhSachAnh({ detailHoSo }) {

  const [dataImage, setDataImage] = useState();
  const [loadingListAnh, setLoadingListAnh] = useState(false);
  return (
    <>
      <Divider orientation="left" style={{ fontSize: '20px', fontWeight: '550' }}>Danh sách ảnh đã tải</Divider>

      <ListAnh detail={detailHoSo}
        loadingListAnh={loadingListAnh}
        setLoadingListAnh={setLoadingListAnh}
        setDataImage={setDataImage}
        dataImage={dataImage}
      />
    </>
  )
}

export default DanhSachAnh