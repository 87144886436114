import { BASE_API_URL_KT_THIETBI } from "@utils/constants";

export const APICongTo = {
  GET_CONGTO: `${BASE_API_URL_KT_THIETBI}/congto/list`,
  LIST_CT_TAO_BK: `${BASE_API_URL_KT_THIETBI}/congto/list-cong-to-tao-bang-ke`,
  DETAIL_CONGTO: `${BASE_API_URL_KT_THIETBI}/congto`,
  BONGBO_CMIS_DK: `${BASE_API_URL_KT_THIETBI}/congto/get-yeu-cau-dinh-ky-cmis`,
  BONGBO_CMIS_PT: `${BASE_API_URL_KT_THIETBI}/congto/get-yeu-cau-phuc-tra-cmis`,
  DK_DONGBO_VE_WEB: `${BASE_API_URL_KT_THIETBI}/congto/dong-bo-yeu-cau-dinh-ky-cmis`,
  PT_DONGBO_VE_WEB: `${BASE_API_URL_KT_THIETBI}/congto/dong-bo-yeu-cau-phuc-tra-cmis`,
  DONGBO_LEN_CMIS: `${BASE_API_URL_KT_THIETBI}/congto/dong-bo-cong-to-len-cmis`,
  CHI_SO_EVN_HES: `${BASE_API_URL_KT_THIETBI}/congto/update-chi-so-evn-hes`,
  CHUYEN_MTB_KTCT: `${BASE_API_URL_KT_THIETBI}/congto/chuyen-hinh-thuc-gcs-mtb`,
};
