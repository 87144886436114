import {
  buildQueryString,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
  alertMessage,
} from "@utils/function";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { handleListBaoCao, handleListYC } from "./services";
import BaoCaoHanKTCTTable from "./table";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_BUTTON_TYPE,
} from "@utils/constants";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import YeuCauModal from "./modals";
import { Fragment } from "react";
import { Endpoint } from "@utils/endpoint";
import { downloadFile } from "@utils/request";
import lodash from "lodash";

export default function Index() {
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { user } = useSelector(authSelector);
  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);

  //filter
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    donViId: user.unitId,
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //change pagination
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  };

  //fetch báo cáo
  const getListBaoCao = useCallback(() => {
    handleListBaoCao(filterConditions, setLoading, setData, setTotalCount);
  }, [filterConditions]);
  const [filterExcel, setFilterExcel] = useState(false);

  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type]);

  // //search
  // const handleSearch = useCallback(
  //   (values) => {
  //     setFilterConditions(() => ({
  //       ...values,
  //     }));
  //     // setFilter(() => ({
  //     //   ...values,
  //     // }));
  //     handleAPI();
  //   },
  //   [handleAPI]
  // );

  const handleSearch = useCallback(
    (values) => {
      if (values.tuNgay <= values.denNgay) {
        setFilterConditions((oldState) => ({
          ...oldState,
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
          ...values,
        }));
        handleAPI();
      } else {
        alertMessage("error", "Thông báo", "Khoảng ngày báo cáo không hợp lệ");
      }
    },
    [handleAPI]
  );

  useEffect(() => {
    if (filterExcel) {
      const endpoint = `${
        Endpoint.EXPORT_EXCEL_HAN_KIEM_DINH_CT
      }?${buildQueryString(parseParams(filterConditions))}`;
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel]);

  //clear search
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
    });
    // setFilter({
    //   pageIndex: DEFAULT_PAGEINDEX,
    //   pageSize: DEFAULT_PAGESIZE,
    //   donViId: user.unitId,
    // });
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  }, [user.unitId]);

  useEffect(() => {
    if (type === ENUM_BUTTON_TYPE.TIM_KIEM) getListBaoCao();
  }, [getListBaoCao, type]);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "5%",
    },
    {
      title: "Trạm",
      dataIndex: "tenTram",
      key: "tenTram",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "22%",
      sorter: true,
    },
    {
      title: "Tổng số công tơ",
      children: [
        {
          title: "Công tơ 1p",
          dataIndex: "soCongTo1Pha",
          key: "soCongTo1Pha",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
          sorter: true,
        },
        {
          title: "Công tơ 3p",
          dataIndex: "soCongTo3Pha",
          key: "soCongTo3Pha",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
          sorter: true,
        },
      ],
    },
    {
      title: "Tổng số lượng công tơ đến hạn kiểm định",
      children: [
        {
          title: "Công tơ 1p",
          dataIndex: "soCongTo1PhaDenHan",
          key: "soCongTo1PhaDenHan",
          render: (text, record) => (
            <div
              className="table-text-right ant-btn-link cursor-pointer"
              onClick={() => getDetail(record, 1)}
            >
              <span>{text}</span>
            </div>
          ),
          width: "14%",
          sorter: true,
        },
        {
          title: "Công tơ 3p",
          dataIndex: "soCongTo3PhaDenHan",
          key: "soCongTo3PhaDenHan",
          render: (text, record) => (
            <div
              className="table-text-right ant-btn-link cursor-pointer"
              onClick={() => getDetail(record, 3)}
            >
              <span>{text}</span>
            </div>
          ),
          width: "14%",
          sorter: true,
        },
      ],
    },
    {
      title: "Tỷ lệ % tổng số công tơ đến hạn/tổng số công tơ",
      dataIndex: "tyLeDenHan",
      key: "tyLeDenHan",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      // width: "20%",
      sorter: true,
    },
  ];

  const [dataYC, setDataYC] = useState([]);
  const [totalCountYC, setTotalCountYC] = useState(0);
  const [isShowYC, setIsShowYC] = useState(false);

  const getDetail = useCallback(
    (record, soPha) => {
      const obj = lodash.cloneDeep(filterConditions);
      if (
        filterConditions.hasOwnProperty("pageSize") ||
        filterConditions.hasOwnProperty("pageIndex")
      ) {
        if (filterConditions.hasOwnProperty("pageSize")) {
          delete obj["pageSize"];
        }
        if (filterConditions.hasOwnProperty("pageIndex")) {
          delete obj["pageIndex"];
        }
      }
      handleListYC(
        record,
        soPha,
        obj,
        setLoading,
        setDataYC,
        setTotalCountYC,
        setIsShowYC
      );
    },
    [filterConditions]
  );

  const showModalYC = useCallback(() => {
    return (
      isShowYC && (
        <YeuCauModal
          onChangePagination={onChangePagination}
          visible={isShowYC}
          setIsShowYC={setIsShowYC}
          dataSource={dataYC}
          totalCountYC={totalCountYC}
        />
      )
    );
  }, [isShowYC, dataYC, totalCountYC]);

  return (
    <Fragment>
      <BaoCaoHanKTCTTable
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        loading={loading}
        columns={columns}
        totalCount={totalCount}
        data={data}
        onChangePagination={onChangePagination}
        setType={setType}
      />
      {showModalYC()}
    </Fragment>
  );
}
