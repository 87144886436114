import { Endpoint } from "@utils/endpoint";
import { Col, Form, Input, Row, Select, Tooltip, Button, Checkbox } from "antd";
import DatePickerComponent from "@components/DatePicker";
import { FORMAT_ENGLISH, MONTH_STRING } from "@utils/constants";
import moment from "moment";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import Selection from "@components/Select";
import FormComponent from "@components/Form";

const { Option } = Select;

export default function FormBoLoc(props) {
  const {
    form,
    handleSearch,
    user,
    loading,
    loaiYeuCau,
    mucDich,
    soPha,
    tooltip,
    clear,
    filterConditions,
    checkCanhBao,
  } = props;
  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
      }}
      autoComplete="off"
    >
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="donViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Selection
                url={Endpoint.GET_UNIT_LIST}
                form={form}
                formKey="donViId"
              />
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.donViId !== currentValues.donViId) {
                form.setFieldsValue({
                  doiId: undefined,
                });
              }
              return prevValues.donViId !== currentValues.donViId;
            }}
          >
            {({ getFieldValue }) => {
              if (getFieldValue("donViId")) {
                return (
                  <Form.Item name="doiId" label="Đội">
                    <Selection
                      url={`${Endpoint.GET_TEAM_LIST}?donviId=${getFieldValue(
                        "donViId"
                      )}`}
                      formKey="doiId"
                      form={form}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="doiId" label="Đội">
                  <Selection
                    url={`${Endpoint.GET_TEAM_LIST}?donviId=${user.unitId}`}
                    formKey="doiId"
                    form={form}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={24} md={6}>
          <Form.Item name="loaiYeuCauId" label="Loại yêu cầu">
            <Select placeholder="Tất cả" loading={loading} allowClear>
              {loaiYeuCau && loaiYeuCau.length > 0
                ? loaiYeuCau.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="mucDich" label="Mục đích">
            <Select placeholder="Tất cả" loading={loading} allowClear>
              {mucDich && mucDich.length > 0
                ? mucDich.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={3}>
          <Form.Item name="soPha" label="Số pha">
            <Select placeholder="Tất cả" loading={loading} allowClear>
              {soPha && soPha.length > 0
                ? soPha.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="startDate" label="Từ ngày">
            <DatePickerComponent
              defaultValue={
                filterConditions.startDate
                  ? moment(filterConditions.startDate, FORMAT_ENGLISH)
                  : moment().startOf(MONTH_STRING)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"startDate"}
              notClear={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="endDate" label="Đến ngày">
            <DatePickerComponent
              defaultValue={
                filterConditions.endDate
                  ? moment(filterConditions.endDate, FORMAT_ENGLISH)
                  : moment()
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"endDate"}
              notClear={true}
            />
          </Form.Item>
        </Col>

        <Col span={24} md={6}>
          <Form.Item name="searchTerm" label="Từ khóa tìm kiếm">
            <Input
              prefix={<SearchOutlined />}
              suffix={
                <Tooltip title={tooltip}>
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={3} style={{ display: "flex", alignItems: "center" }}>
          <Form.Item name="isCanhBao" style={{ margin: 0 }}>
            <Checkbox
              onChange={checkCanhBao}
              defaultChecked={filterConditions.isCanhBao ? true : false}
            >
              Cảnh báo
            </Checkbox>
          </Form.Item>
        </Col>
        <Col
          span={24}
          md={4}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button className="mr-24px" onClick={clear}>
            Bỏ lọc
          </Button>
          <Button type="primary" htmlType="submit" form="filter-form">
            Tìm kiếm
          </Button>
        </Col>
      </Row>
    </FormComponent>
  );
}
