import React, { Fragment, useState } from "react";
import { Col, Button, Image, Upload, Form } from "antd";
import { ENUM_MODE_PHUONGANCAPDIEN } from "@utils/constants";
import { ContainerBodySoDoCapDien } from "@modules/cap-dien/nhan-vien-khao-sat/css-styled";
import noImage from "@assets/images/no-image.png";
import { LoadingOutlined } from "@ant-design/icons";
import { PictureCardContainer } from "../../../../css-styled";
export default function UISoDoCapDien(props) {
  const { handleChange, mode, preview, loading, deleteHandler } = props;
  const [visible, setVisible] = useState(false);
  const handlePreview = () => {
    setVisible(true);
  };
  return (
    <Fragment>
      <Col span={24} md={20}>
        <span className="fs-16px font-bold">5. Sơ đồ cấp điện</span>
      </Col>
      <Col span={24} md={4}>
        {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMTRENTAB ? (
          <Upload
            showUploadList={false}
            onChange={handleChange}
            beforeUpload={() => false}
            disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
            maxCount={1}
            accept=".jpg, .jpeg, .png"
          >
            <Button type="primary" loading={loading}>
              {loading ? <LoadingOutlined /> : null}
              Tải sơ đồ
            </Button>
          </Upload>
        ) : null}
      </Col>
      <Col span={24} md={24} style={{ marginTop: "16px" }}>
        <ContainerBodySoDoCapDien>
          <Form.Item name="soDoCapDien">
            {/* <Image
              preview={preview ? true : false}
              src={preview ? preview : noImage}
              width={700}
              height={500}
              style={{ objectFit: "cover" }}
            /> */}
            <span className="ant-upload-picture-card-wrapper">
              <div className="ant-upload-list ant-upload-list-picture-card">
                <PictureCardContainer>
                  <span>
                    <div className="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card">
                      <div className="ant-upload-list-item-info">
                        <span>
                          <img
                            alt="avatar"
                            style={{
                              width: "700px",
                              height: "500px",
                              objectFit: "cover",
                              border: "black",
                            }}
                            src={preview ? preview : noImage}
                            className="ant-upload-list-item-image"
                          ></img>
                          <span className="ant-upload-list-item-name ant-upload-list-item-name-icon-count-1"></span>
                        </span>
                      </div>
                      {preview ? (
                        <span
                          className="ant-upload-list-item-actions"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <button
                            title="Xem sơ đồ"
                            type="button"
                            className="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn"
                            onClick={handlePreview}
                          >
                            <span
                              role="img"
                              aria-label="eye"
                              className="anticon anticon-eye"
                            >
                              <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="eye"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path>
                              </svg>
                            </span>
                          </button>
                          {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMTRENTAB ? (
                            <button
                              title="Xóa sơ đồ"
                              type="button"
                              className="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn"
                              onClick={deleteHandler}
                            >
                              <span
                                role="img"
                                aria-label="delete"
                                tabIndex="-1"
                                className="anticon anticon-delete"
                              >
                                <svg
                                  viewBox="140 64 896 896"
                                  focusable="false"
                                  data-icon="delete"
                                  width="1em"
                                  height="1em"
                                  fill="#f81c1c"
                                  aria-hidden="true"
                                >
                                  <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                                </svg>
                              </span>
                            </button>
                          ) : null}
                        </span>
                      ) : null}
                    </div>
                  </span>
                </PictureCardContainer>
              </div>
            </span>
          </Form.Item>
        </ContainerBodySoDoCapDien>
        <Image
          width={200}
          style={{
            display: "none",
            position: "absolute",
            visibility: "hidden",
            left: "-9999px",
          }}
          src={preview}
          preview={{
            visible,
            src: preview,
            onVisibleChange: (value) => {
              setVisible(value);
            },
          }}
        />
      </Col>
    </Fragment>
  );
}
