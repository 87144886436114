import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import Table from "./table";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  TABINDEX_CMIS,
} from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { handleListData } from "./services";
import EllipsisTooltip from "@components/EllipsisTooltip";
import { Endpoint } from "@utils/endpoint";
import { dongBoCMIS } from "../services";
import { Button } from "antd";
import CheckPermission from "@components/CheckPermission";
import { tien_ich_chung } from "@permissions/m_tienichchung";

export default function Index() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [dataTroNgai, setDataTroNgai] = useState([]);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });
  //khai bái columns

  const columns = [
    {
      title: t("tro_ngai.ma_tro_ngai"),
      dataIndex: "maTroNgai",
      key: "maTroNgai",
      render: (text) => <span>{text}</span>,
      width: "10%",
      sorter: true,
    },
    {
      title: t("tro_ngai.ten_tro_ngai"),
      dataIndex: "tenTroNgai",
      key: "tenTroNgai",
      render: (text) => <span>{text}</span>,
      width: "20%",
      sorter: true,
    },
    {
      title: t("tro_ngai.khac_phuc"),
      dataIndex: "tinhChatKhacPhuc",
      key: "tinhChatKhacPhuc",
      render: (text) => <span>{text}</span>,
      width: "20%",
      sorter: true,
    },
    {
      title: t("tro_ngai.nguyen_nhan"),
      dataIndex: "nguyenNhan",
      key: "nguyenNhan",
      render: (text) => <EllipsisTooltip title={text}>{text}</EllipsisTooltip>,
      width: "25%",
      sorter: true,
    },
    {
      title: t("tro_ngai.ma_cong_Viec"),
      dataIndex: "maCongViec",
      key: "maCongViec",
      render: (text) => <span>{text}</span>,
      width: "10%",
      sorter: true,
    },
    {
      title: t("tro_ngai.ngay_hieu_luc"),
      dataIndex: "ngayHieuLuc",
      key: "ngayHieuLuc",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
  ];

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);
  //fetch dữ liệu

  const getListTroNgai = useCallback(() => {
    handleListData(setLoading, filterConditions, setDataTroNgai, setTotalCount);
  }, [filterConditions]);

  useEffect(() => {
    getListTroNgai();
    // eslint-disable-next-line
  }, [getListTroNgai]);
  //thay đổi pagination table

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const genExtra = () => (
    <CheckPermission permissionCode={tien_ich_chung.m112cmis}>
      <Button
        size="small"
        type="primary"
        className="btn-bg-yellow"
        onClick={handleDongBoCMIS}
        loading={loading}
      >
        Đồng bộ CMIS
      </Button>
    </CheckPermission>
  );

  const handleDongBoCMIS = useCallback(() => {
    const url = Endpoint.DONG_BO_CMIS + `?tabIndex=${TABINDEX_CMIS.TRO_NGAI}`;
    dongBoCMIS(url, setLoading, getListTroNgai);
  }, [getListTroNgai]);
  //tìm kiếm dữ liệu

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  return (
    <Fragment>
      <Table
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        genExtra={genExtra}
        dataSource={dataTroNgai}
        columns={columns}
        totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        t={t}
      />
    </Fragment>
  );
}
