import { Endpoint } from "@utils/endpoint";
import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { getErrorMessage } from "@utils/function";
import { authGetData, authPostData } from "@utils/request";

export const handleListData = (setLoading, setData, setTotalCount) => {
  authGetData({
    url: Endpoint.GET_LIST_HO_SO_FLOW,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setData(res.data);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};

export const handleListViTri = (setLoading, setDataViTri) => {
  authGetData({
    url: Endpoint.GET_VI_TRI_CONG_VIEC,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataViTri(res.data);
      } else {
        setDataViTri([]);
      }
    },
  });
};

export const handleUpdateData = (
  detail,
  checked,
  setLoading,
  values,
  form,
  getListHoSo,
  closeModal
) => {
  const data = {
    ...values,
    sendEmail: checked,
    doiTuongNhanMails: values.doiTuongNhanMails.toString(),
  };
  data.id = detail.id;
  authPostData({
    url: Endpoint.SEND_EMAIL_HO_SO_FLOW,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        getListHoSo();
        closeModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};
