import { TYPE_TABLE_CONGTO } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, authGetMultiplesRequest } from "@utils/request";
import { ENUM_DS_CONGTO } from "@utils/constants";
// call api list data

export const handleListData = (
  typeList,
  filterConditions,
  setLoading,
  setDataSource,
  setTotalCount,
  location
) => {
  let url = "";
  if (typeList === TYPE_TABLE_CONGTO.KIEMTRADINHKY) {
    url = `${Endpoint.GET_CONGTO}?${buildQueryString(
      Object.keys(parseParams(location.search)).length > 0
        ? parseParams(location.search)
        : filterConditions
    )}`;
  } else if (
    typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA ||
    typeList === TYPE_TABLE_CONGTO.DONGBO_CMIS ||
    typeList === TYPE_TABLE_CONGTO.CHISO_EVN_HES
  ) {
    url = `${Endpoint.GET_CONGTO}?${buildQueryString(
      Object.keys(parseParams(location.search)).length > 0
        ? parseParams(location.search)
        : filterConditions
    )}`;
  }
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataSource(res.data);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};
// call api multilist data

export const handleMultiDS = (
  setLoading,
  setSoPha,
  setTrangThai,
  setLoaiBangKe,
  typeDS,
  setFirstTrangThai
) => {
  const endpoints = [
    `${Endpoint.COMMON_LIST_SO_PHA}`,
    `${Endpoint.LIST_TINHTRANG_CT}?MaLoaiDanhSach=${
      typeDS === ENUM_DS_CONGTO.CHISO_EVN_HES_DT
        ? ENUM_DS_CONGTO.CHISO_EVN_HES
        : typeDS
    }`,
    // `${Endpoint.LIST_TRAM_CT}`,
    `${Endpoint.LIST_LOAI_BANGKE}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setSoPha(res[0].data.data);
      setTrangThai(res[1].data.data);
      if (
        typeDS === ENUM_DS_CONGTO.DINHKY_TRACUU ||
        typeDS === ENUM_DS_CONGTO.PHUCTRA_TRACUU
      ) {
        setFirstTrangThai("");
      } else {
        setFirstTrangThai(res[1].data.data[0].value);
      }
      // setTram(res[2].data.data);
      setLoaiBangKe(res[2].data.data);
    },
  });
};
// call api chi tiết hồ sơ

export const handleDetailCongTo = (
  congToId,
  setLoading,
  setDataDetail,
  setDataAnh,
  dataTable
) => {
  authGetData({
    url: `${Endpoint.DETAIL_CONGTO}?id=${congToId}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDetail(res.data);
        setDataAnh(res.data.hinhAnhHienTruongs);
        dataTable(res.data.dataChiSo);
      } else {
        setDataAnh([]);
        dataTable([]);
        setDataDetail({});
      }
    },
  });
};
