import { createSlice } from "@reduxjs/toolkit";
export const namespace = "filterCapDien";

const INITAL_STATE = {
  openFilter: false,
};

const slice = createSlice({
  name: namespace,
  initialState: INITAL_STATE,
  reducers: {
    openFilters: (state) => {
      return {
        ...state,
        openFilter: !state.openFilter,
      };
    },
    closeFilters: (state) => {
      return {
        ...state,
        openFilter: false,
      };
    },
  },
});

export const reducer = slice.reducer;

export const { openFilters, closeFilters } = slice.actions;

export const filterCapDien = (state) => state[namespace];
