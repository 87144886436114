import CheckPermission from "@components/CheckPermission";
import XemLaiBBLapDatComponent from "@modules/cap-dien/common-ho-so/xem-bien-ban-lap-dat";
import { cap_dien } from "@permissions/a_capdien";
import { Menu } from "antd";

const { SubMenu } = Menu;

export default function ListMenu(props) {
  const {
    handleOpenDuyetHoSo,
    handleOpenModalInHoSoCMIS,
    handleOpenModalXemLaiPhuongAnCapDien,
    handleOpenModalQuyetToanKhachHang,
    handleOpenModalQuyetToanCongTy,
    handleOpenModalInBieuKhaiGia,
    inBienBanApGia,
    handleOpenInPhuTai,
    detail,
  } = props;
  return (
    <Menu mode="vertical" style={{ width: "100%" }} selectedKeys={["0"]}>
      <CheckPermission permissionCode={cap_dien.a72_ky_duyet_tc}>
        <Menu.Item eventKey="1" onClick={handleOpenDuyetHoSo}>
          Ký duyệt/Từ chối quyết toán
        </Menu.Item>
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a72_in_hs_cmis}>
        <Menu.Item eventKey="4" onClick={handleOpenModalInHoSoCMIS}>
          In hồ sơ CMIS
        </Menu.Item>
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a72_in_hs_web}>
        <SubMenu eventKey="inHoSoWeb" title="In hồ sơ Web">
          <Menu.Item
            eventKey="5"
            onClick={handleOpenModalXemLaiPhuongAnCapDien}
          >
            Xem lại phương án cấp điện
          </Menu.Item>
          <XemLaiBBLapDatComponent idHoSo={detail?.id ?? detail?.hoSoId} />
          <Menu.Item eventKey="20" onClick={handleOpenInPhuTai}>
            In biểu đồ phụ tải
          </Menu.Item>
          <Menu.Item eventKey="6" onClick={handleOpenModalQuyetToanKhachHang}>
            Quyết toán khách hàng
          </Menu.Item>
          <Menu.Item eventKey="7" onClick={handleOpenModalQuyetToanCongTy}>
            Quyết toán công ty
          </Menu.Item>
          <Menu.Item eventKey="8" onClick={handleOpenModalInBieuKhaiGia}>
            In biểu khai giá
          </Menu.Item>
          <Menu.Item eventKey="9" onClick={inBienBanApGia}>
            In biên bản áp giá
          </Menu.Item>
          {/* <Menu.Item eventKey="10">
          In phiếu tiếp nhận thông tin đề nghị mua điện
        </Menu.Item> */}
        </SubMenu>
      </CheckPermission>
    </Menu>
  );
}
