import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  TABINDEX_CMIS,
} from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { handleListData } from "./services";
import Table from "./table";
import { Endpoint } from "@utils/endpoint";
import { dongBoCMIS } from "../services";
import { Button } from "antd";
import CheckPermission from "@components/CheckPermission";
import { tien_ich_chung } from "@permissions/m_tienichchung";

export default function Index() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [dataDiaChinh, setDataDiaChinh] = useState([]);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const getListData = useCallback(() => {
    handleListData(
      setLoading,
      filterConditions,
      setDataDiaChinh,
      setTotalCount
    );
  }, [filterConditions]);

  useEffect(() => {
    getListData();
    // eslint-disable-next-line
  }, [getListData]);

  //thay đổi pagination table

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //hiển thị các button thêm,...

  const genExtra = () => (
    <CheckPermission permissionCode={tien_ich_chung.m13cmis}>
      <Button
        size="small"
        type="primary"
        className="btn-bg-yellow"
        onClick={handleDongBoCMIS}
        loading={loading}
      >
        Đồng bộ CMIS
      </Button>
    </CheckPermission>
  );

  const handleDongBoCMIS = useCallback(() => {
    const url =
      Endpoint.DONG_BO_CMIS + `?tabIndex=${TABINDEX_CMIS.DVI_DIACHINH}`;
    dongBoCMIS(url, setLoading, getListData);
  }, [getListData]);

  //khai bái columns

  const columns = [
    {
      title: t("dia_chinh.ma_dia_chinh"),
      dataIndex: "maDiaChinh",
      key: "maDiaChinh",
      sorter: true,
      width: "15%",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: t("dia_chinh.ten_dia_chinh"),
      dataIndex: "tenDiaChinh",
      key: "tenDiaChinh",
      width: "40%",
      sorter: true,
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: t("dia_chinh.khu_vuc"),
      dataIndex: "khuVuc",
      key: "khuVuc",
      width: "25%",
      sorter: true,
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: t("dia_chinh.ma_cap_tren"),
      dataIndex: "maDiaChinhCapTren",
      key: "maDiaChinhCapTren",
      // width: "12%",
      sorter: true,
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
    },
  ];

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  return (
    <Fragment>
      <Table
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        genExtra={genExtra}
        dataSource={dataDiaChinh}
        columns={columns}
        totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        t={t}
      />
    </Fragment>
  );
}
