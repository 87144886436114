import Screen1 from "../common-screen/screen1";
import { Endpoint } from "@utils/endpoint";
const DsSoCapNhatSlSoVoiNgayGCSXNgay = () => {
  const columns = [
    {
      title: "STT",
      dataIndex: "key",
      width: "70px",
      align: "center",
    },
    {
      title: "Đơn vị",
      dataIndex: "donVi",
      width: "400px",
    },
    {
      title: "Đội",
      dataIndex: "doi",
      width: "350px",
    },
    {
      title: "Mã sổ GCS",
      dataIndex: "maSoGCS",
    },
    {
      title: "Kỳ",
      dataIndex: "ky",
      width: "70px",
      align: "center",
    },
    {
      title: "Ngày GCS",
      dataIndex: "ngayGCS",
      render: (ngayGCS) => {
        if (ngayGCS)
          return {
            children: <span>{ngayGCS}</span>,
            props: {
              align: "end",
            },
          };
      },
    },
    {
      title: "Ngày thực tế GCS",
      dataIndex: "ngayThucTe",
      render: (ngayThucTe) => {
        if (ngayThucTe)
          return {
            children: <span>{ngayThucTe}</span>,
            props: {
              align: "end",
            },
          };
      },
    },
    {
      title: "Chênh lệch",
      dataIndex: "chenhLech",
      render: (text) => {
        return {
          children: <span>{text}</span>,
          props: {
            align: "end",
          },
        };
      },
    },
  ];
  const endPoint = {
    LIST: Endpoint.LIST_SO_GCS_X_NGAY,
    EXCEL: Endpoint.EXCEL_SO_GCS_X_NGAY,
    PDF: Endpoint.PDF_SO_GCS_X_NGAY,
    ...Endpoint,
  };
  const param = {
    IsComplete: true,
  };
  return <Screen1 columns={columns} endPoint={endPoint} param={param} />;
};

export default DsSoCapNhatSlSoVoiNgayGCSXNgay;
