import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, authPostData } from "@utils/request";

// LIST DON VI
export const getListDonVi = (setLoading, setData) => {
  authGetData({
    url: `${Endpoint.GET_UNIT_LIST}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

//DANH_SACH_NHAN_VIEN_THU_TIEN
export const getNhanVienThuTien = (setLoading, setData, donVi) => {
  authGetData({
    url: `${Endpoint.DANH_SACH_NHAN_VIEN_THU_TIEN}?Id=${donVi}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

//DANH_SACH_NO_DONG_CAT_DIEN
export const getDanhSachNoDong = (
  setListLoading,
  setData,
  query,
  filterConditions,
  setTotal
) => {
  authGetData({
    url: `${Endpoint.DANH_SACH_NO_DONG_CAT_DIEN}?${query}`,
    setLoading: setListLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              STT:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            };
          })
        );
        setTotal(res.paging.totalCount);
      }
    },
  });
};

//DANH_SACH_NO_DONG_CAT_DIEN

export const postDongBo = (
  payload,
  setLoading,
  setListLoading,
  setData,
  filterConditions,
  location,
  setTotal,
  setVisibleCMIS,
  setSelectedRowKeys,
  setDataIds
) => {
  authPostData({
    url: `${Endpoint.DONG_BO_NO_DONG_CAT_DIEN}`,
    method: "POST",
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        const query =
          Object.keys(parseParams(location.search)).length > 0
            ? buildQueryString(parseParams(location.search))
            : buildQueryString(filterConditions);
        getDanhSachNoDong(
          setListLoading,
          setData,
          query,
          filterConditions,
          setTotal
        );
        setVisibleCMIS(false);
        setSelectedRowKeys([]);
        setDataIds([]);
      }
    },
  });
};
