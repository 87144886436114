import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE, listPhanLoaiDuoiLuoiTRongKho } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Divider, Form, Input, Row, Select, Spin } from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ModalCapNhatThKiemTraDinhKy from "./modal-bc-chi-tiet/md-cap-nhat-th-kiem-tra-dinh-ky";
import ModalCapNhatThCongToTonDuoiLuoi from "./modal-bc-chi-tiet/md-cap-nhat-tinh-hinh-cong-to-duoi-luoi";
import ModalCapNhatThCongToTonKho from "./modal-bc-chi-tiet/md-cap-nhat-tinh-hinh-cong-to-ton-kho";
import ModalCapNhatThPhucTraCongTo from "./modal-bc-chi-tiet/md-cap-nhat-tinh-hinh-phuc-tra-cong-to";
import { exportData, handleDownload, handleListBaoCao, selectBcCtTbDd } from "./service";
import { handlePagination } from "@utils/function";

export default function BaoCaoCTQLTBDoDem() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);
  const [visibleKTDinhKy, setVisibleKTDinhKy] = useState(false);
  const [visibleTHPhucTra, setVisibleTHPhucTra] = useState(false);
  const [visibleThCongToTonKho, setVisibleThCongToTonKho] = useState(false);
  const [visibleCongToDuoiLuoi, setVisibleCongToDuoiLuoi] = useState(false);

  const [dataDuoiLuoiTrongKho, setDataDuoiLuoiTrongKho] = useState([]);

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });

  //filter conditions
  const [filterConditionsKTDinhKy, setFilterConditionsKTDinhKy] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });

  const [filterConditionsTHPhucTra, setFilterConditionsTHPhucTra] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });
  const [filterConditionsCongToTonKho, setFilterConditionsCongToTonKho] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });

  const [filterConditionsCongToDuoiLuoi, setFilterConditionsCongToDuoiLuoi] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });

  const [dataTable, setDataTable] = useState();
  const [baocao, setBaocao] = useState(Endpoint.BCCT_TH_TH_THAY_CT_DK);

  // Open modal
  const openModalKTDinhKy = useCallback(() => {
    setVisibleKTDinhKy(true);
  }, []);

  const onCancelKTDinhKy = useCallback(() => {
    setVisibleKTDinhKy(false);
  }, []);

  const openModalTHPhucTra = useCallback(() => {
    setVisibleTHPhucTra(true);
  }, []);

  const onCancelTHPhucTra = useCallback(() => {
    setVisibleTHPhucTra(false);
  }, []);

  const openModalThCongToTonKho = useCallback(() => {
    setVisibleThCongToTonKho(true);
  }, []);

  const onCancelThCongToTonKho = useCallback(() => {
    setVisibleThCongToTonKho(false);
  }, []);

  const openModalCongToDuoiLuoi = useCallback(() => {
    setVisibleCongToDuoiLuoi(true);
  }, []);

  const onCancelCongToDuoiLuoi = useCallback(() => {
    setVisibleCongToDuoiLuoi(false);
  }, []);

  //search Báo cáo kiểm tra định kỳ công tơ
  const handleGetUpdateKTDinhKy = (data) => {
    setFilterConditionsKTDinhKy((oldState) => ({
      ...oldState,
      MaDonVi: data.unitId,
      LuyKe: data.LuyKe,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
    }));
  };

  //search Báo cáo tổng hợp phúc tra công tơ
  const handleGetUpdateTHPhucTra = (data) => {
    setFilterConditionsTHPhucTra((oldState) => ({
      ...oldState,
      MaDonVi: data.unitId,
      LuyKe: data.LuyKe,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
    }));
  };

  //search Báo cáo nhu cầu sử dụng công tơ tồn kho
  const handleGetUpdateCongToTonKho = (data) => {
    setFilterConditionsCongToTonKho((oldState) => ({
      ...oldState,
      MaDonVi: data.unitId,
      Nam: moment().get("year"),
    }));
  };

  //search Báo cáo nhu cầu sử dụng công tơ dưới lưới
  const handleGetUpdateCongToDuoiLuoi = (data) => {
    setFilterConditionsCongToDuoiLuoi((oldState) => ({
      ...oldState,
      MaDonVi: data.unitId,
      Nam: moment().get("year"),
    }));
  };

  //fetch báo cáo kiểm tra định kỳ
  const getListBaoCaoKTDinhKy = useCallback(() => {
    const url = Endpoint.BCCT_DANH_SACH_KT_DK_CT;
    if (filterConditionsKTDinhKy.Nam) {
      handleListBaoCao(url, filterConditionsKTDinhKy, setLoading, setDataTable, openModalKTDinhKy);
    }
  }, [filterConditionsKTDinhKy, openModalKTDinhKy]);

  //fetch báo cáo tình hình phúc tra công tơ
  const getListBaoCaoTHPhucTra = useCallback(() => {
    const url = Endpoint.BCCT_DANH_SACH_THPT_CT;
    if (filterConditionsTHPhucTra.Nam) {
      handleListBaoCao(url, filterConditionsTHPhucTra, setLoading, setDataTable, openModalTHPhucTra);
    }
  }, [filterConditionsTHPhucTra, openModalTHPhucTra]);

  //fetch báo cáo nhu cầu sử dụng công tơ trong kho tồn kho
  const getListBaoCaoCongToTonKho = useCallback(() => {
    const url = Endpoint.BCCT_DS_TINH_HINH_CONG_TO_TRONG_KHO_TON_KHO;
    if (filterConditionsCongToTonKho.Nam) {
      handleListBaoCao(url, filterConditionsCongToTonKho, setLoading, setDataTable, openModalThCongToTonKho);
    }
  }, [filterConditionsCongToTonKho, openModalThCongToTonKho]);

  //fetch báo cáo nhu cầu sử dụng công tơ trong kho tồn kho
  const getListBaoCaoCongToDuoiLuoi = useCallback(() => {
    const url = Endpoint.BCCT_DS_TINH_HINH_CONG_TO_TRONG_KHO_DUOI_LUOI;
    if (filterConditionsCongToDuoiLuoi.Nam) {
      handleListBaoCao(url, filterConditionsCongToDuoiLuoi, setLoading, setDataTable, openModalCongToDuoiLuoi);
    }
  }, [filterConditionsCongToDuoiLuoi, openModalCongToDuoiLuoi]);
  //GetListBaoCao

  useEffect(() => {
    getListBaoCaoTHPhucTra();
  }, [getListBaoCaoTHPhucTra]);

  useEffect(() => {
    getListBaoCaoKTDinhKy();
  }, [getListBaoCaoKTDinhKy]);

  useEffect(() => {
    getListBaoCaoCongToTonKho();
  }, [getListBaoCaoCongToTonKho]);

  useEffect(() => {
    getListBaoCaoCongToDuoiLuoi();
  }, [getListBaoCaoCongToDuoiLuoi]);

  //Xuất báo cáo
  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };

  const renderViewThangNam = () => {
    const listValueShouldRender = [
      Endpoint.BCCT_TH_TH_KT_DK_CT,
      Endpoint.BCCT_TH_THPT_CT,
      Endpoint.BCK_NHU_CAU_CONG_TO_DUOI_LUOI_TRONG_KHO,
    ];
    if (listValueShouldRender.includes(form.getFieldValue("reportType"))) {
      return (
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}>
          <Form.Item name="reportDate" label="Tháng năm" labelAlign="left" required>
            <DatePickerComponent picker="month" formKey="reportDate" form={form} format={"YYYY-MM"} />
          </Form.Item>
        </Form.Item>
      );
    }
    return <></>;
  };

  const renderViewPhanLoai = () => {
    const listValueShouldRender = [Endpoint.BCK_NHU_CAU_CONG_TO_DUOI_LUOI_TRONG_KHO];
    if (listValueShouldRender.includes(form.getFieldValue("reportType"))) {
      return (
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}>
          <Form.Item name="phanLoai" label="Phân loại" labelAlign="left" required>
            <Select placeholder="Tất cả">
              {listPhanLoaiDuoiLuoiTRongKho.map((item) => {
                return (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form.Item>
      );
    }
    return <></>;
  };

  const columns = [
    {
      title: "Mã chủng loại",
      dataIndex: "MA_CLOAI",
      key: "MA_CLOAI",
      width: "10%",
      align: "center",
    },
    {
      title: "Loại công tơ",
      dataIndex: "LOAI_CTO",
      key: "LOAI_CTO",
      width: "10%",
      align: "center",
    },
    {
      title: "Mô tả",
      dataIndex: "MO_TA",
      key: "MO_TA",
      width: "20%",
      align: "center",
    },
    {
      title: "Số lượng tồn kho",
      dataIndex: "SOLUONG_TON",
      key: "SOLUONG_TON",
      width: "10%",
      align: "center",
      render: (text, record) => {
        return (
          <Form.Item name={`soluongtonkho-${record.stt}`}>
            <Input className="flexCenter" defaultValue={text} min={0} type={"number"} />
          </Form.Item>
        );
      },
    },
    {
      title: "Nhu cầu sử dụng",
      dataIndex: "SOLUONG_NCAU",
      key: "SOLUONG_NCAU",
      // width: "10%",
      align: "center",
      render: (text, record) => {
        return (
          <Form.Item name={`$soluongnhucau-${record.stt}`}>
            <Input type={"number"} className="flexCenter" defaultValue={text} min={0} />
          </Form.Item>
        );
      },
    },
  ];

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  return (
    <Fragment>
      <Container>
        <Spin spinning={loading}>
          <Divider className="section-text" orientation="left">
            Báo cáo công tác quản lí thiết bị đo đếm
          </Divider>
          <ContentWrapper>
            <div className="flex">
              <FlexForm>
                <FormComponent
                  onFinish={onFinish}
                  form={form}
                  initialValues={{
                    reportType: Endpoint.BCCT_TH_TH_THAY_CT_DK,
                    unitId: user.user.unitCode,
                    LoaiKhachHang: "ALL",
                    LuyKe: "0",
                    LoaiBienDong: "TRONGKHO",
                  }}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 18 }}
                  colon={false}
                >
                  <Form.Item name="reportType" label="Báo cáo" labelAlign="left" required>
                    <Select
                      placeholder="Tất cả"
                      options={selectBcCtTbDd}
                      onChange={() => setBaocao(form.getFieldValue("reportType"))}
                    ></Select>
                  </Form.Item>
                  {/* Loại báo cáo */}
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("reportType") !== Endpoint.BCCT_TH_TH_KD_CT &&
                      getFieldValue("reportType") !== Endpoint.BCCT_BAO_CAO_TONG_HOP_DO_DEM_CL ? (
                        <Form.Item name="unitId" label="Đơn vị" labelAlign="left" required>
                          <Selection url={Endpoint.GET_TEAM_LIST_CODE} formKey="unitId" form={form} />
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>

                  {/* Phân loại */}
                  {renderViewPhanLoai()}
                  {/* Loại khách hàng */}
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("reportType") === Endpoint.BCCT_TH_TH_THAY_CT_DK ||
                      getFieldValue("reportType") === Endpoint.BCCT_TH_CT_MHC_TL_PT_MOI ? (
                        <Form.Item name="LoaiKhachHang" label="Loại khách hàng" labelAlign="left" required>
                          <Select placeholder="Chọn loại khách hàng" formKey="LoaiKhachHang">
                            <Option value="ALL">Tất cả</Option>
                            <Option value="DN">Khách hàng đầu nguồn</Option>
                            <Option value="KH">Khách hàng</Option>
                          </Select>
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>
                  {/* Lũy kế */}
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("reportType") === Endpoint.BCCT_TH_TH_KT_DK_CT ||
                      getFieldValue("reportType") === Endpoint.BCCT_TH_THPT_CT ? (
                        <Form.Item name="LuyKe" label="Lũy kế" labelAlign="left" required>
                          <Select placeholder="Chọn lũy kế" formKey="LuyKe">
                            <Option value="0">Tháng</Option>
                            <Option value="1">Năm</Option>
                          </Select>
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>
                  {/* Từ Ngày */}
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("reportType") === Endpoint.BCCT_TH_TH_THAY_CT_DK ||
                      getFieldValue("reportType") === Endpoint.BCCT_THTH_THAY_TUTI_DK ||
                      getFieldValue("reportType") === Endpoint.BCCT_TH_CT_MHC_TL_PT_MOI ||
                      getFieldValue("reportType") === Endpoint.BCCT_TH_TH_KD_CT ? (
                        <Form.Item name="TuNgay" label="Từ ngày" labelAlign="left" required>
                          <DatePickerComponent formKey="TuNgay" form={form} picker="day" format={"YYYY-MM-DD"} />
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>
                  {/* Đến Ngày */}
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("reportType") === Endpoint.BCCT_TH_TH_THAY_CT_DK ||
                      getFieldValue("reportType") === Endpoint.BCCT_THTH_THAY_TUTI_DK ||
                      getFieldValue("reportType") === Endpoint.BCCT_TH_CT_MHC_TL_PT_MOI ||
                      getFieldValue("reportType") === Endpoint.BCCT_TH_TH_KD_CT ? (
                        <Form.Item name="DenNgay" label="Đến ngày" labelAlign="left" required>
                          <DatePickerComponent picker="day" formKey="DenNgay" form={form} format={"YYYY-MM-DD"} />
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>

                  {/* Loại biến động */}
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("reportType") === Endpoint.BCCT_BAO_CAO_TONG_HOP_DO_DEM_CL ? (
                        <Form.Item name="LoaiBienDong" label="Loại biến động" labelAlign="left" required>
                          <Select formKey="LoaiBienDong" form={form} placeholder="Chọn loại biến động">
                            <Option value="TRONGKHO">Trong Kho</Option>
                            <Option value="TRENLUOI">Trên lưới</Option>
                            <Option value="DUOILUOI">Dưới lưới</Option>
                          </Select>
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>
                  {/* Tháng năm */}
                  {renderViewThangNam()}
                  <Row>
                    <Col span={4}></Col>
                    <Col span={18}>
                      <div className="flex">
                        {baocao === Endpoint.BCK_NHU_CAU_CONG_TO_DUOI_LUOI_TRONG_KHO && (
                          <>
                            <Button
                              type="primary"
                              loading={loading}
                              className="mr-20px"
                              // onClick={() => handleUpdate()}
                              // disabled={disabledUpdate}
                            >
                              Cập nhật
                            </Button>
                            <Button
                              type="primary"
                              loading={loading}
                              className="mr-20px"
                              onClick={() => {
                                exportData(form.getFieldValue(), setLoading, setDataDuoiLuoiTrongKho);
                                // setDisabledUpdate(false);
                              }}
                            >
                              Xem bảng kê
                            </Button>
                          </>
                        )}
                        <Button type="primary" htmlType="submit">
                          Xuất báo cáo
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </FormComponent>
              </FlexForm>
              <FlexButton>
                {/* Button cập nhật kiểm tra định kỳ công tơ*/}
                {baocao === Endpoint.BCCT_TH_TH_KT_DK_CT ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      handleGetUpdateKTDinhKy(form.getFieldsValue());
                    }}
                  >
                    Cập nhật báo cáo
                  </Button>
                ) : null}

                {/* Button tình hình phúc tra công tơ*/}
                {baocao === Endpoint.BCCT_TH_THPT_CT ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      handleGetUpdateTHPhucTra(form.getFieldsValue());
                    }}
                  >
                    Cập nhật báo cáo
                  </Button>
                ) : null}

                {/* Button Báo cáo nhu cầu sử dụng công tơ tồn kho*/}
                {baocao === Endpoint.BCCT_BAO_CAO_NANG_SUAT_SU_DUNG_CT_TT_TON_KHO ? (
                  <Button type="primary" onClick={() => handleGetUpdateCongToTonKho(form.getFieldsValue())}>
                    Cập nhật báo cáo
                  </Button>
                ) : null}

                {/* Button Báo cáo nhu cầu sử dụng công tơ dưới lưới*/}
                {baocao === Endpoint.BCCT_BAO_CAO_NHU_CAU_SU_DUNG_CT_TRONG_KHO_DUOI_LUOI ? (
                  <Button type="primary" onClick={() => handleGetUpdateCongToDuoiLuoi(form.getFieldsValue())}>
                    Cập nhật báo cáo
                  </Button>
                ) : null}
              </FlexButton>
            </div>
            {baocao === Endpoint.BCK_NHU_CAU_CONG_TO_DUOI_LUOI_TRONG_KHO ? (
              <TableComponent
                header="Thông tin hiển thị"
                columns={columns}
                dataSource={dataDuoiLuoiTrongKho}
                pagination={filterConditions}
                onChangePagination={onChangePagination}
                bordered
              />
            ) : null}
          </ContentWrapper>

          {/* Modal Cập nhật kiểm tra định kỳ công tơ */}
          {visibleKTDinhKy && (
            <ModalCapNhatThKiemTraDinhKy
              visible={visibleKTDinhKy}
              onCancel={onCancelKTDinhKy}
              dataTable={dataTable}
              filterConditions={filterConditionsKTDinhKy}
              setFilterConditions={setFilterConditionsKTDinhKy}
              getListData={getListBaoCaoKTDinhKy}
              loading={loading}
              setLoading={setLoading}
            />
          )}

          {/* Modal tình hình phúc tra công tơ */}
          {visibleTHPhucTra && (
            <ModalCapNhatThPhucTraCongTo
              visible={visibleTHPhucTra}
              onCancel={onCancelTHPhucTra}
              dataTable={dataTable}
              filterConditions={filterConditionsTHPhucTra}
              setFilterConditions={setFilterConditionsTHPhucTra}
              getListData={getListBaoCaoTHPhucTra}
              loading={loading}
              setLoading={setLoading}
            />
          )}

          {/* Modal nhu cầu sử dụng công tơ tồn kho*/}
          {visibleThCongToTonKho && (
            <ModalCapNhatThCongToTonKho
              visible={visibleThCongToTonKho}
              onCancel={onCancelThCongToTonKho}
              dataTable={dataTable}
              filterConditions={filterConditionsCongToTonKho}
              setFilterConditions={setFilterConditionsCongToTonKho}
              getListData={getListBaoCaoCongToTonKho}
              loading={loading}
              setLoading={setLoading}
            />
          )}

          {/* Modal nhu cầu sử dụng công tơ dưới lưới */}
          {visibleCongToDuoiLuoi && (
            <ModalCapNhatThCongToTonDuoiLuoi
              visible={visibleCongToDuoiLuoi}
              onCancel={onCancelCongToDuoiLuoi}
              dataTable={dataTable}
              filterConditions={filterConditionsCongToDuoiLuoi}
              setFilterConditions={setFilterConditionsCongToDuoiLuoi}
              getListData={getListBaoCaoCongToDuoiLuoi}
              loading={loading}
              setLoading={setLoading}
            />
          )}
        </Spin>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
const FlexForm = styled.div`
  flex: 3;
`;
const FlexButton = styled.div`
  flex: 1;
`;
