import React, { useState } from "react";
import { Button, Col, Modal, Row } from "antd";
import LoadingComponent from "@components/Loading";
import { Container } from "../../common-ho-so/modal-duyet-ho-so/lanh-dao-quyet-toan/css-styled";
import { useTranslation } from "react-i18next";
import { handleTuChoiQuyetToan, handleXacNhanQuyetToan } from "./services";

export default function Index(props) {
  const { closeModal, setFetchDataTable, detail, visible } = props;
  const { t } = useTranslation();
  const [loadingTuChoi, setLoadingTuChoi] = useState(false);
  const [loadingCapNhat, setLoadingCapNhat] = useState(false);
  //Handle Từ chối quyết toán

  const handleTuChoi = () => {
    const hoSoId = { hoSoId: detail.id };
    handleTuChoiQuyetToan(
      hoSoId,
      setLoadingTuChoi,
      closeModal,
      setFetchDataTable
    );
  };

  //Handle xác nhận quyết toán

  const handleCapNhat = () => {
    const hoSoId = { hoSoId: detail.id };
    handleXacNhanQuyetToan(
      hoSoId,
      setLoadingCapNhat,
      closeModal,
      setFetchDataTable
    );
  };

  return (
    <LoadingComponent loading={loadingCapNhat || loadingTuChoi}>
      <Modal
        title={"Hồ sơ đang ở bộ phận quyết toán"}
        visible={visible}
        onCancel={closeModal}
        footer={null}
      >
        <Container>
          <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
            Hồ sơ của bạn đã hoàn tất lập quyết toán công ty và quyết toán khách
            hàng. Xác nhận sẽ chuyển tiếp cho Lãnh đạo ! Từ chối sẽ trả lại để
            thực hiện lại việc lập quyết toán khách hàng !
          </h4>
          <Row gutter={24} style={{ marginTop: "48px", textAlign: "center" }}>
            <Col span={3} md={3}></Col>
            <Col span={6} md={6}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={6} md={6}>
              <Button
                type="primary"
                onClick={handleTuChoi}
                loading={loadingTuChoi}
                key="tuChoi"
              >
                {"Từ chối"}
              </Button>
            </Col>
            <Col span={6} md={6}>
              <Button
                type="primary"
                onClick={handleCapNhat}
                loading={loadingCapNhat}
                key="capNhat"
              >
                {"Cập nhật"}
              </Button>
            </Col>
            <Col span={3} md={3}></Col>
          </Row>
        </Container>
      </Modal>
    </LoadingComponent>
  );
}
