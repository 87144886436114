import common from "./common.json";

import tram from "./danh-muc/tram.json";
import nganHang from "./danh-muc/ngan-hang.json";
import diaChinh from "./danh-muc/dia-chinh.json";
import vatTuLienSo from "./danh-muc/vat-tu-lien-so.json";
import chungLoaiCongTo from "./danh-muc/chung-loai-cong-to.json";
import theoNgayHieuLuc from "./danh-muc/theo-ngay-hieu-luc.json";
import uyQuyen from "./danh-muc/uy-quyen";
import tyLeBanDien from "./danh-muc/ti-le-ban-dien";
import loaiTroNgai from "./danh-muc/loai-tro-ngai";
import cauHinhChung from "./danh-muc/cau-hinh-chung";
import chungLoaiVatTu from "./danh-muc/chung-loai-vat-tu";
import vatTu from "./danh-muc/vat-tu";
import lo from "./danh-muc/lo";
import donVi from "./danh-muc/don-vi";
import doi from "./danh-muc/doi";
import imei from "./danh-muc/imei";
import soGhiChiSo from "./danh-muc/so-ghi-chi-so";
import troNgai from "./danh-muc/tro-ngai";
import tinhTrang from "./danh-muc/tinh-trang";
import loaiYeuCau from "./danh-muc/loai-yeu-cau";
import vungDonGia from "./danh-muc/vung-don-gia";
import email from "./danh-muc/email";
import trangThai from "./danh-muc/trang-thai";
import duyetHoSo from "./cap-dien/duyet-ho-so";
import tienIch from "./cap-dien/tien-ich";
import lapPhuongAn from "./cap-dien/lap-phuong-an";
import capNhatHoSo from "./cap-dien/cap-nhat-ho-so";
import giaoDichVien from "./cap-dien/giao-dich-vien";
import commonCapDien from "./cap-dien/common-cap-dien";
import quyetToan from "./cap-dien/quyet-toan";
import apGiaHoSo from "./cap-dien/ap-gia-ho-so";
import vatTuCapDien from "./cap-dien/vat-tu";
import nghiemThu from "./cap-dien/nghiem-thu";
import nhanVienGCSMacDinh from "./ghi-chi-so/nhan-vien-gcs-mac-dinh";
import cauHinhKyBangKe from "./ghi-chi-so/cau-hinh-ky-bang-ke";
import qlChungThuGCS from "./ghi-chi-so/quan-ly-chung-thu";
import phanCongGCS from "./ghi-chi-so/phan-cong-gcs";
import commonKBK from "./ghi-chi-so/common-ky-bang-ke";
import traCuuTimKiem from "./ghi-chi-so/tra-cuu-tim-kiem";
import doiSoat from "./ghi-chi-so/doi-soat-anh-du-lieu";
import thiCong from "./cap-dien/thi-cong";

const vn = {
  ...common,
  ...tram,
  ...nganHang,
  ...diaChinh,
  ...vatTuLienSo,
  ...chungLoaiCongTo,
  ...chungLoaiCongTo,
  ...vatTuLienSo,
  ...theoNgayHieuLuc,
  ...uyQuyen,
  ...tyLeBanDien,
  ...loaiTroNgai,
  ...apGiaHoSo,
  ...cauHinhChung,
  ...chungLoaiVatTu,
  ...vatTu,
  ...apGiaHoSo,
  ...lo,
  ...donVi,
  ...doi,
  ...imei,
  ...soGhiChiSo,
  ...tinhTrang,
  ...troNgai,
  ...loaiYeuCau,
  ...vungDonGia,
  ...email,
  ...duyetHoSo,
  ...tienIch,
  ...lapPhuongAn,
  ...capNhatHoSo,
  ...giaoDichVien,
  ...commonCapDien,
  ...quyetToan,
  ...vatTuCapDien,
  ...trangThai,
  ...nghiemThu,
  ...nhanVienGCSMacDinh,
  ...cauHinhKyBangKe,
  ...qlChungThuGCS,
  ...phanCongGCS,
  ...commonKBK,
  ...traCuuTimKiem,
  ...doiSoat,
  ...thiCong,
};

export { vn };
