import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import {
  authGetData,
  authPostData,
  authPostFileData,
  startDelete,
} from "@utils/request";
import { getDanhSachYeuCau } from "../services";

export const TaiAnhHoSo = (
  setLoading,
  file,
  setImageFile,
  detail,
  setDataImage,
  setIsSubmit,
  setLoadingListAnh
) => {
  authPostFileData({
    url: `${Endpoint.CRM_TAI_ANH}`,
    payload: {
      file,
      yeuCauId: detail.id,
    },
    method: METHOD_POST,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setImageFile([]);
        setIsSubmit(true);
        handleListImage(detail, setLoadingListAnh, setDataImage);
      } else if (res.statusCode === 422) {
      }
    },
  });
};

// export const deleteImage = (id, setLoading, detail, setDataImage) => {
//   startDelete({
//     url: `${Endpoint.CRM_TAI_ANH}/${id}`,
//     setLoading,
//     onSuccess: (res) => {
//       if (res.statusCode === 200) {
//         console.log("success");
//         handleListImage(detail, setLoading, setDataImage)
//       }
//     },
//   });
// };

export const handleListImage = (detail, setLoading, setDataImage) => {
  authGetData({
    url: `${Endpoint.CRM_TAI_ANH}?YeuCauId=${detail.id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setDataImage(res.data);
      }
    },
  });
};

export const handleDeleteImage = (
  id,
  setLoading,
  detail,
  setDataImage,
  setVisibleDelete
) => {
  startDelete({
    url: `${Endpoint.CRM_TAI_ANH}/${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        handleListImage(detail, setLoading, setDataImage);
        setVisibleDelete(false);
      }
    },
  });
};

export const XacNhanKinhDoanh = (
  setLoading,
  payload,
  setData,
  closeModal,
  dispatch,
  setListLoading,
  filterConditions,
  setTotal,
  location
) => {
  authPostData({
    url: `${Endpoint.CRMKD_XAC_NHAN}`,
    method: "POST",
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        dispatch(closeModal());
        const query =
          Object.keys(parseParams(location.search)).length > 0
            ? buildQueryString(parseParams(location.search))
            : buildQueryString(filterConditions);

        getDanhSachYeuCau(
          setListLoading,
          setData,
          query,
          filterConditions,
          setTotal
        );
      }
    },
  });
};

export const getChiTietYeuCau = (setLoading, id, setData) => {
  authGetData({
    url: `${Endpoint.CRM_CHI_TIET_YEU_CAU}?Id=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};
