import { BASE_API_URL_BAO_CAO } from "@utils/constants";

export const APIBCkinhdoanh = {
  BCKD_TH_BAN_DIEN_CUA_CAC_DON_VI: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bckd/tong-hop-ban-dien`,
  BCKD_TH_CAC_CHI_TIEU_CHAT_LUONG_DV_KH: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bckd/chi-tieu-chat-luong-dvkh`,
  BCKD_TH_CAC_CHI_TIEU_KD: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bckd/chi-tieu-kinh-doanh`,
  BCKD_BAN_DIEN_THEO_NGANH_NGHE: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bckd/ban-dien-theo-nganh-nghe`,
  BCKD_BAN_DIEN_THEO_DOI_TUONG_GIA: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bckd/ban-dien-theo-doi-tuong-gia`,
  BCKD_BAN_DIEN_THEO_PHU_TAI: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bckd/ban-dien-theo-phu-tai`,
  BCKD_SAN_LUONG_TIET_KIEM_DIEN: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bckd/san-luong-tiet-kiem-dien`,
  BCKD_SO_DU_VA_TAI_KHOAN_THU: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bckd/so-du-va-tai-khoan-thu`,
  BCKD_TH_BAN_DIEN: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bckd/tong-hop-ban-dien`,
  BCKD_TONG_HOP_DIEN_NANG_GIAO_NHAN_DAU_NGUON: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bckd/tong-hop-dien-dien-nang-giao-nhan-dau-nguon`,
  BCKD_SO_CONG_TO_CAC_DON_VI: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bckd/so-cong-to-cac-don-vi`,
  BCKD_SO_HOP_DONG_MUA_BAN_DIEN: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bckd/so-hop-dong-mua-ban-dien`,
  BCKD_TI_LE_DIEN_NANG_TRUYEN_TAI: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bckd/ty-le-dien-nang-truyen-tai`,
  BCKD_DANH_SACH_NHAN_VIEN: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcctkd/danh-sach-nhan-vien`,

  // Báo cáo tổng hợp hóa đơn theo giai đoạn
  BC_TH_HOA_DON_TGD: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcthhdtgd/bao-cao-tong-hop-hoa-don-theo-giai-doan`,
  //Báo cáo tổng hợp hóa đơn theo kì
  BC_TH_HD_HOA_DON_TK: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcthhdtk/bao-cao-tong-hop-hoa-don-theo-ky`,
  // Báo cáo tin nhắn zalo
  BCKD_BC_TN_ZALO: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bctnzalo/bao-cao-tin-nhan-zalo`,
  //Báo cáo phân hệ hóa đơn
  BCKD_BC_KHNC_PHU_TAI: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcphhd/bao-cao-khach-hang-nghien-cuu-phu-tai`,
  BCKD_TK_KH_CO_SL_TU_1_3: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcphhd/tkkh-co-san-luong-tang-tu-1-3-lan`,
  BCKD_CT_KH_CO_SL_TU_1_3: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/bcphhd/ctkh-co-san-luong-tang-tu-1-3-lan`,
  //Danh sách khách hàng cảnh báo hành chính nhân sự
  BCKD_DSKH_CANH_BAO_AP_GIA_HANH_CHINH_NHAN_SU: `${BASE_API_URL_BAO_CAO}/bckd/bcqtkd/dskhcbag/bao-ap-gia-hanh-chinh-su-nghiep`,
};
