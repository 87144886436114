import { Collapse } from "antd";
import VatTuCongTy from "../../panels/panel-du-tru-vat-tru-cap-dien/panel-vat-tu-cong-ty";
import VatTuKhachHang from "../../panels/panel-du-tru-vat-tru-cap-dien/panel-vat-tu-khach-hang";
import VatTuThuHoi from "../../panels/panel-du-tru-vat-tru-cap-dien/panel-vat-tu-thu-hoi";
import { PanelWrapper } from "../../../css-styled";

const { Panel } = Collapse;

export default function DuTruVatTuCapDienTabs(props) {
  const { dataChungLoaiVatTu, indexTab, detailLapPhuongAn, mode } = props;
  return (
    <Collapse defaultActiveKey={["1", "2", "3"]}>
      <Panel
        header={
          <h3 className="font-bold" style={{ position: "relative" }}>
            1. Phần chi phí của công ty
          </h3>
        }
        key="1"
      >
        <VatTuCongTy
          dataChungLoaiVatTu={dataChungLoaiVatTu}
          indexTab={indexTab}
          detailLapPhuongAn={detailLapPhuongAn}
          mode={mode}
        />
      </Panel>
      <PanelWrapper
        header={
          <h3 className="font-bold" style={{ position: "relative" }}>
            2. Phần chi phí của khách hàng
          </h3>
        }
        key="2"
      >
        <VatTuKhachHang
          dataChungLoaiVatTu={dataChungLoaiVatTu}
          indexTab={indexTab}
          detailLapPhuongAn={detailLapPhuongAn}
          mode={mode}
        />
      </PanelWrapper>

      <Panel
        header={
          <h3 className="font-bold" style={{ position: "relative" }}>
            3. Dự trù vật tư thu hồi của Công ty sau khi cấp điện
          </h3>
        }
        key="3"
      >
        <VatTuThuHoi
          dataChungLoaiVatTu={dataChungLoaiVatTu}
          indexTab={indexTab}
          detailLapPhuongAn={detailLapPhuongAn}
          mode={mode}
        />
      </Panel>
    </Collapse>
  );
}
