import React, { memo, useState } from "react";
import { handlerKiemTraHuy, handlerKiemTraThangLamViec, HUY_CHUYEN_THANG_LAMVIEC, KIEMTRA_CHUYEN_THANG_LAMVIEC } from "../service";
import ConfirmModal from "./modal-confirm";
function Index({
  visible,
  handleCloseModal,
  content,
  title,
  setKiemTraThangLamViec,
  setDisplayMess,
  setDisplayMessHuy,
  setKiemTraHuy,
  setFetchThangLamViec,
  type,
}) {
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    if (type === KIEMTRA_CHUYEN_THANG_LAMVIEC) {
      handlerKiemTraThangLamViec(
        setKiemTraThangLamViec,
        setLoading,
        handleCloseModal,
        setDisplayMess,
        setFetchThangLamViec,
      );
    }
    if (type === HUY_CHUYEN_THANG_LAMVIEC) {
      handlerKiemTraHuy(
        setKiemTraHuy,
        setLoading,
        handleCloseModal,
        setDisplayMessHuy,
        setFetchThangLamViec,
      );
    }
  };

  return (
    <ConfirmModal
      loading={loading}
      visible={visible}
      closeModal={handleCloseModal}
      handleSubmit={handleSubmit}
      content={content}
      title={title}
    />
  );
}

export default memo(Index);
