import { useEffect, useState } from "react";
import { Select, Form, Col, Input, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  TYPE_LOAIYEUCAU_HOSO,
  ENUM_MUCDICHSINHHOAT,
  ENUM_SOPHA,
} from "@utils/constants";
import { saveLoaiYeuCauDichVu } from "@state/cap-dien/giao-dich-vien/tiep-nhan-phan-cong/reducer";
import { useDispatch } from "react-redux";

export default function PanelsThongTinVeDichVu(props) {
  const { detail, form, dataSoPha, dataMucDich, dataLoaiYeuCau } = props;
  const { t } = useTranslation();
  const [displayNangCongSuat, setDisplayNangCongSuat] = useState(
    detail.maLoaiYeuCau === TYPE_LOAIYEUCAU_HOSO.DIDOI ? false : true
  );

  const [titleLoaiYC, setTilteLoaiYC] = useState("Lý do đề nghị cấp điện");
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({
      lyDoNangCongSuat: detail.lyDoNangCongSuat,
      loaiYeuCauId: detail.loaiYeuCauDichVu,
      mucDichSinhHoat:
        detail.mucDichSinhHoat === ENUM_MUCDICHSINHHOAT.SINHHOAT
          ? ENUM_MUCDICHSINHHOAT.SINHHOAT
          : ENUM_MUCDICHSINHHOAT.NGOAISINHHOAT,
      soPha:
        detail.soPha === ENUM_SOPHA.MOT_PHA ||
        detail.soPha === ENUM_SOPHA.BA_PHA
          ? detail.soPha
          : null,
      congSuatNamN: detail.congSuatNamN,
      congSuatNamNPlus1: detail.congSuatNamNPlus1,
      congSuatNamNPlus2: detail.congSuatNamNPlus2,
      congSuatNamNPlus3: detail.congSuatNamNPlus3,
      soLuongTrungBinhNamN: detail.soLuongTrungBinhNamN,
      soLuongTrungBinhNamNPlus1: detail.soLuongTrungBinhNamNPlus1,
      soLuongTrungBinhNamNPlus2: detail.soLuongTrungBinhNamNPlus2,
      soLuongTrungBinhNamNPlus3: detail.soLuongTrungBinhNamNPlus3,
      soHoDungChung: detail.soHoDungChung,
      tenChuHoDungChung: detail.tenChuHoDungChung,
      maHopDongMB: detail.maHopDongMB,
      maKhachHangDungChung: detail.maKhachHangDungChung,
    });
  }, [form, detail]);

  useEffect(() => {
    if (detail.maLoaiYeuCau === TYPE_LOAIYEUCAU_HOSO.DIDOI) {
      setTilteLoaiYC("Lý do đề nghị di dời");
      setDisplayNangCongSuat(false);
    } else if (detail.maLoaiYeuCau === TYPE_LOAIYEUCAU_HOSO.NANGCONGSUAT) {
      setTilteLoaiYC("Lý do đề nghị nâng công suất điện");
      setDisplayNangCongSuat(false);
    } else {
      setTilteLoaiYC(t("Lý do đề nghị cấp điện"));
      setDisplayNangCongSuat(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail.maLoaiYeuCau]);

  // useEffect(() => {
  //   if (
  //     form.getFieldValue("maLoaiYeuCau") ===
  //       TYPE_LOAIYEUCAU_HOSO.NANGCONGSUAT ||
  //     form.getFieldValue("maLoaiYeuCau") === TYPE_LOAIYEUCAU_HOSO.DIDOI
  //   ) {
  //     setDisplayNangCongSuat(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [form.getFieldValue("maLoaiYeuCau")]);
  //Hiển thị hoặc ẩn input lyDoNangCongSuat

  const handleLoaiYeuCau = (value) => {
    const loaiYeuCau = dataLoaiYeuCau.find((item) => item.value === value);

    if (
      loaiYeuCau.type === TYPE_LOAIYEUCAU_HOSO.NANGCONGSUAT_SO ||
      loaiYeuCau.type === TYPE_LOAIYEUCAU_HOSO.DIDOI_SO
    ) {
      setDisplayNangCongSuat(false);
    } else {
      setDisplayNangCongSuat(true);
    }
    if (loaiYeuCau.type === TYPE_LOAIYEUCAU_HOSO.CAPDIEN_SO) {
      const cd = form.getFieldValue();
      form.setFieldsValue({
        ...cd,
        maLoaiYeuCau: TYPE_LOAIYEUCAU_HOSO.CAPDIEN,
      });
      setTilteLoaiYC(t("Lý do đề nghị cấp điện"));
    } else if (loaiYeuCau.type === TYPE_LOAIYEUCAU_HOSO.DIDOI_SO) {
      const dd = form.getFieldValue();
      form.setFieldsValue({
        ...dd,
        maLoaiYeuCau: TYPE_LOAIYEUCAU_HOSO.DIDOI,
      });
      setTilteLoaiYC("Lý do đề nghị di dời");
    } else if (loaiYeuCau.type === TYPE_LOAIYEUCAU_HOSO.NANGCONGSUAT_SO) {
      const ncs = form.getFieldValue();
      form.setFieldsValue({
        ...ncs,
        maLoaiYeuCau: TYPE_LOAIYEUCAU_HOSO.NANGCONGSUAT,
      });
      setTilteLoaiYC("Lý do đề nghị nâng công suất điện");
    }
    dispatch(saveLoaiYeuCauDichVu(loaiYeuCau.type));
  };

  return (
    <Row gutter={24}>
      <Col span={24} md={24}>
        <Form.Item
          label={titleLoaiYC}
          name="lyDoNangCongSuat"
          className="required-field"
        >
          <Input disabled={displayNangCongSuat} />
        </Form.Item>
      </Col>
      <Col span={24} md={8} lg={6}>
        <Form.Item
          label={t("cap_nhat_ho_so.loai_yeu_cau_dich_vu")}
          name="loaiYeuCauId"
        >
          <Select onChange={handleLoaiYeuCau}>
            {dataLoaiYeuCau && dataLoaiYeuCau.length
              ? dataLoaiYeuCau.map((item, index) => {
                  if (item.value !== null) {
                    return (
                      <Select.Option key={index} value={item.value.toString()}>
                        {item.name}
                      </Select.Option>
                    );
                  }
                  return null;
                })
              : null}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24} md={8} lg={6}>
        <Form.Item
          label={t("cap_nhat_ho_so.muc_dich_sinh_hoat")}
          name="mucDichSinhHoat"
        >
          <Select>
            {dataMucDich && dataMucDich.length
              ? dataMucDich.map((item, index) => {
                  if (item.value !== null) {
                    return (
                      <Select.Option key={index} value={item.value.toString()}>
                        {item.name}
                      </Select.Option>
                    );
                  }
                  return null;
                })
              : null}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24} md={8} lg={6}>
        <Form.Item label={t("cap_nhat_ho_so.so_pha")} name="soPha">
          <Select>
            {dataSoPha && dataSoPha.length
              ? dataSoPha.map((item, index) => {
                  if (item.value !== 0) {
                    return (
                      <Select.Option key={index} value={item.value}>
                        {item.name}
                      </Select.Option>
                    );
                  }
                  return null;
                })
              : null}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24} md={0} lg={6}></Col>

      <Col span={24} md={8} lg={6}>
        <Form.Item
          label={`${t("cap_nhat_ho_so.cong_suat_nam")}${"(n)"}${t(
            "cap_nhat_ho_so.kW"
          )}`}
          name="congSuatNamN"
          className="required-field"
        >
          <Input type="text" />
        </Form.Item>
      </Col>
      <Col span={24} md={8} lg={6}>
        <Form.Item
          label={`${t("cap_nhat_ho_so.cong_suat_nam")}${"(n+1)"}${t(
            "cap_nhat_ho_so.kW"
          )}`}
          name="congSuatNamNPlus1"
        >
          <Input />
        </Form.Item>
      </Col>

      <Col span={24} md={8} lg={6}>
        <Form.Item
          label={`${t("cap_nhat_ho_so.cong_suat_nam")}${"(n+2)"}${t(
            "cap_nhat_ho_so.kW"
          )}`}
          name="congSuatNamNPlus2"
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24} md={8} lg={6}>
        <Form.Item
          label={`${t("cap_nhat_ho_so.cong_suat_nam")}${"(n+3)"}${t(
            "cap_nhat_ho_so.kW"
          )}`}
          name="congSuatNamNPlus3"
        >
          <Input />
        </Form.Item>
      </Col>

      <Col span={24} md={8} lg={6}>
        <Form.Item
          label={`${t("cap_nhat_ho_so.san_luong_trung_binh_nam")}${"(n)"}${t(
            "cap_nhat_ho_so.kW"
          )}`}
          name="soLuongTrungBinhNamN"
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24} md={8} lg={6}>
        <Form.Item
          label={`${t("cap_nhat_ho_so.san_luong_trung_binh_nam")}${"(n+1)"}${t(
            "cap_nhat_ho_so.kW"
          )}`}
          name="soLuongTrungBinhNamNPlus1"
        >
          <Input />
        </Form.Item>
      </Col>

      <Col span={24} md={8} lg={6}>
        <Form.Item
          label={`${t("cap_nhat_ho_so.san_luong_trung_binh_nam")}${"(n+2)"}${t(
            "cap_nhat_ho_so.kW"
          )}`}
          name="soLuongTrungBinhNamNPlus2"
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24} md={8} lg={6}>
        <Form.Item
          label={`${t("cap_nhat_ho_so.san_luong_trung_binh_nam")}${"(n+3)"}${t(
            "cap_nhat_ho_so.kW"
          )}`}
          name="soLuongTrungBinhNamNPlus3"
        >
          <Input />
        </Form.Item>
      </Col>

      <Col span={24} md={8} lg={6}>
        <Form.Item
          label={t("cap_nhat_ho_so.so_ho_dung_chung")}
          name="soHoDungChung"
        >
          <Input />
        </Form.Item>
      </Col>

      <Col span={24} md={8} lg={6}>
        <Form.Item
          label={t("cap_nhat_ho_so.ten_chu_ho_dung_chung")}
          name="tenChuHoDungChung"
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24} md={8} lg={6}>
        <Form.Item label={t("cap_nhat_ho_so.so_HĐMB")} name="maHopDongMB">
          <Input />
        </Form.Item>
      </Col>
      <Col span={24} md={8} lg={6}>
        <Form.Item
          label={t("cap_nhat_ho_so.ma_khach_hang_dung_chung")}
          name="maKhachHangDungChung"
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
}
