import FormComponent from "@components/Form";
import { Row } from "antd";
import { Container } from "../../css-styled";
import ThongTinChungPanel from "../../panels/thong-tin-chung";

export default function ThongTinCongToChiTiet(props) {
  // eslint-disable-next-line no-unused-vars
  const {
    detail
  } = props;
  return (
    <FormComponent
      autoComplete="off"
      name="form-chi-tiet-cong-to"
      initialValues={{}}
      layout="vertical"
      onValuesChange={false}
      scrollToFirstError
    >
      <Container>
        <Row gutter={24}>
          <ThongTinChungPanel
            detail={detail}
            pagination={false}
          />
        </Row>
      </Container>
    </FormComponent>
  );
}
