import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { cancelHSService } from "./services";
import ModalHuyHoSo from "./modal-huy-ho-so";

export default function HuyHoSoModal(props) {
  const { visible, closeModal, dataCancel, setFetchDataTable } = props;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  //xóa dữ liệu

  const handleCancelHoSo = () => {
    cancelHSService(dataCancel, setLoading, setFetchDataTable, closeModal, t);
  };

  return (
    <ModalHuyHoSo
      loading={loading}
      visible={visible}
      closeModal={closeModal}
      handleCancelHoSo={handleCancelHoSo}
      t={t}
    />
  );
}
