export const selectBCDOISOATEP = [
  {
    label: "Biểu 1: Tổng hợp số lượng tin nhắn của toàn Tổng công ty",
    value: 1,
  },
  {
    label: "Biểu 2: Chi tiết số lượng tin nhắn theo từng Công ty Điện Lực",
    value: 2,
  },
  {
    label: "Biểu 4_CC: Đối soát số lượng tin nhắn đã thực hiện thành công",
    value: 4,
  },
];
