import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE, FORMAT_DATE } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, formatDateWithTimezone, handlePagination, parseParams } from "@utils/function";
import { authGetData } from "@utils/request";
import { Button, Col, DatePicker, Divider, Form, Input, Row, Select, Spin } from "antd";
import moment from "moment";

import {
  HINH_THUC_EMAIL,
  HINH_THUC_SMS,
  HINH_THUC_ZALO,
  PHAN_LOAI_CONG_TIN_NHAN_CSKH,
  PHAN_LOAI_DICH_VU_DIEN_TU,
  PHAN_LOAI_TIN_NHAN_SMS_DICH_VU_KHAC,
  PHAN_LOAI_TIN_NHAN_SMS_TIEN_DIEN_LAN_1,
} from "./constants";

import React, { Fragment, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
export default function TimKiemTinNhan() {
  const { Option } = Select;

  const location = useLocation();
  const unitId = useSelector((state) => state.auth.user.unitId);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [filterConditions, setFilterConditions] = useState({
    ...parseParams(location.search),
    DonViId: unitId,
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    PhanLoaiTimKiem: PHAN_LOAI_TIN_NHAN_SMS_TIEN_DIEN_LAN_1,
    HinhThuc: HINH_THUC_SMS,
    NgayBatDau: formatDateWithTimezone(`${moment(moment()?._d)}`),
    NgayKetThuc: formatDateWithTimezone(`${moment(moment()?._d)}`),
  });
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };
  const onChangeDV = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      DonViId: e,
    }));
  };
  const onChangePhanLoai = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      PhanLoaiTimKiem: e,
    }));
  };
  const onChangeHinhThuc = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      HinhThuc: e,
    }));
  };
  const onChangeMaKH = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      MaDinhDanh: e.target.value,
    }));
  };
  const onChangeNgayBatDau = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      NgayBatDau: e ? moment(e).format("YYYY-MM-DD") : undefined,
    }));
  };
  const onChangeNgayKetThuc = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      NgayKetThuc: e ? moment(e).format("YYYY-MM-DD") : undefined,
    }));
  };

  const onFinish = () => {
    const query = buildQueryString(
      parseParams({
        DonViId: filterConditions?.DonViId,
        DoiTuongId: filterConditions?.DoiTuongId,
        HinhThuc: filterConditions?.HinhThuc,
        MaDinhDanh: filterConditions?.MaDinhDanh,
        DThoai: filterConditions?.DThoai,
        NgayBatDau: filterConditions?.NgayBatDau,
        NgayKetThuc: filterConditions?.NgayKetThuc,
        PhanLoaiTimKiem: filterConditions?.PhanLoaiTimKiem,
      })
    );
    if (filterConditions.PhanLoaiTimKiem === PHAN_LOAI_TIN_NHAN_SMS_TIEN_DIEN_LAN_1) {
      authGetData({
        url: `${Endpoint.TKTN_TN_SMS_TRACUU_TDLAN_1}?${query}`,
        method: "GET",
        setLoading,
        onSuccess: (res) => {
          if (res && res.statusCode === 200) {
            setData(res.data);
          }
        },
      });
    } else if (filterConditions.PhanLoaiTimKiem === PHAN_LOAI_TIN_NHAN_SMS_DICH_VU_KHAC || filterConditions.PhanLoaiTimKiem === PHAN_LOAI_DICH_VU_DIEN_TU) {
      authGetData({
        url: `${Endpoint.TKTN_TN_SMS_TRACUU_DICHVUKHAC_VA_DICHVU_DT}?${query}`,
        method: "GET",
        setLoading,
        onSuccess: (res) => {
          if (res && res.statusCode === 200) {
            setData(res.data);
          }
        },
      });
    } else {
      const query = buildQueryString(
        parseParams({
          DonViId: filterConditions?.DonViId,
          DoiTuongId: filterConditions?.DoiTuongId,
          HinhThuc: filterConditions?.HinhThuc,
          MaDinhDanh: filterConditions?.MaDinhDanh,
          DThoai: filterConditions?.DThoai,
          NgayBatDau: filterConditions?.NgayBatDau,
          NgayKetThuc: filterConditions?.NgayKetThuc,
        })
      );
      if (filterConditions.HinhThuc === HINH_THUC_SMS) {
        authGetData({
          url: `${Endpoint.TKTN_CONG_TIN_NHAN_CSKH_LOG_SMS}?${query}`,
          method: "GET",
          setLoading,
          onSuccess: (res) => {
            if (res && res.statusCode === 200) {
              setData(res.data);
            }
          },
        });
      } else if (filterConditions.HinhThuc === HINH_THUC_EMAIL) {
        authGetData({
          url: `${Endpoint.TKTN_CONG_TIN_NHAN_CSKH_LOG_EMAIL}?${query}`,
          method: "GET",
          setLoading,
          onSuccess: (res) => {
            if (res && res.statusCode === 200) {
              setData(res.data);
            }
          },
        });
      } else {
        authGetData({
          url: `${Endpoint.TKTN_CONG_TIN_NHAN_CSKH_LOG_ZALO}?${query}`,
          method: "GET",
          setLoading,
          onSuccess: (res) => {
            if (res && res.statusCode === 200) {
              setData(res.data);
            }
          },
        });
      }
    }
  };
  const clearFilter = useCallback(() => {
    setFilterConditions({
      DonViId: unitId,
      PhanLoaiTimKiem: PHAN_LOAI_TIN_NHAN_SMS_TIEN_DIEN_LAN_1,
      HinhThuc: HINH_THUC_SMS,
      NgayBatDau: formatDateWithTimezone(`${moment(moment()._d)}`),
      NgayKetThuc: formatDateWithTimezone(`${moment(moment()._d)}`),
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });

    setData([]);

    form.setFieldsValue({
      [`DonViId`]: unitId,
    });
    form.setFieldsValue({
      [`PhanLoaiTimKiem`]: PHAN_LOAI_TIN_NHAN_SMS_TIEN_DIEN_LAN_1,
    });
    form.setFieldsValue({
      [`HinhThuc`]: HINH_THUC_SMS,
    });
    form.setFieldsValue({
      [`MaDinhDanh`]: "",
    });
    form.setFieldsValue({
      [`DThoai`]: "",
    });
    form.setFieldsValue({
      [`NgayBatDau`]: moment(),
    });
    form.setFieldsValue({
      [`NgayKetThuc`]: moment(),
    });
  }, []);
  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      width: 100,
      align: "center",
      key: "STT",
      render: (text, record, index) => {
        form.setFieldsValue({
          [`STT-${index + 1}`]: `${index + 1}`,
        });
        return (
          <>
            <Form.Item name={`STT-${index + 1}`}>
              <span key={index}>{`${index + 1}`}</span>
            </Form.Item>
          </>
        );
      },
    },
    {
      title: "IDSMS",
      dataIndex: "IDSMS",
      key: "IDSMS",
      align: "center",
      width: 250,
      render: (text, record, index) => {
        form.setFieldsValue({
          [`idsms-${index + 1}`]: record?.idsms,
        });
        return (
          <>
            <Form.Item name={`idsms-${record?.idsms}`}>
              <span key={index}>{record?.idsms}</span>
            </Form.Item>
          </>
        );
      },
    },
    {
      title: "Mã khách hàng",
      width: 250,
      align: "center",
      dataIndex: "makhachhang",
      key: "makhachhang",
      render: (text, record, index) => {
        form.setFieldsValue({
          [`mA_KHANG-${index + 1}`]: record?.mA_KHANG,
        });
        return (
          <>
            <Form.Item name={`mA_KHANG-${record?.mA_KHANG}`}>
              <span key={index}>{record?.mA_KHANG}</span>
            </Form.Item>
          </>
        );
      },
    },

    {
      title: "Điện thoại",
      width: 250,
      align: "center",
      dataIndex: "dthoai",
      key: "dthoai",
      render: (text, record, index) => {
        form.setFieldsValue({
          [`dthoai-${index + 1}`]: record?.dthoai,
        });
        return (
          <>
            <Form.Item name={`dthoai-${index + 1}`}>
              <span key={index}>{record?.dthoai || record?.thongtin}</span>
            </Form.Item>
          </>
        );
      },
    },
    {
      title: "Trạng thái",
      width: 250,
      dataIndex: "trangthai",
      key: "trangthai",
      render: (text, record, index) => {
        form.setFieldsValue({
          [`tranG_THAI-${index + 1}`]: record?.tranG_THAI,
        });
        return (
          <>
            <Form.Item name={`tranG_THAI-${index + 1}`}>
              <span key={index}>{record?.tranG_THAI}</span>
            </Form.Item>
          </>
        );
      },
    },
    {
      title: "Nội dung",
      width: 250,
      dataIndex: "noidung",
      key: "noidung",
      render: (text, record, index) => {
        form.setFieldsValue({
          [`noI_DUNG-${index + 1}`]: record?.noI_DUNG,
        });
        return (
          <>
            <Form.Item name={`noI_DUNG-${index + 1}`}>
              <span key={index}>{record?.noI_DUNG}</span>
            </Form.Item>
          </>
        );
      },
    },
    {
      title: "Thời gian thực hiện",
      dataIndex: "thoigian",
      width: 250,
      align: "center",
      key: "thoigian",
      render: (text, record, index) => {
        form.setFieldsValue({
          [`tgianN_TH-${index + 1}`]: record?.tgianN_TH,
        });
        return (
          <>
            <Form.Item name={`tgianN_TH-${index + 1}`}>
              <span key={index}>{record?.tgiaN_TH || record?.tgian}</span>
            </Form.Item>
          </>
        );
      },
    },
  ];
  const columnsCongTinNhanCSKH = [
    {
      title: "STT",
      dataIndex: "STT",
      width: 100,
      align: "center",
      key: "STT",
      render: (text, record, index) => {
        form.setFieldsValue({
          [`STT-${index + 1}`]: `${index + 1}`,
        });
        return (
          <>
            <Form.Item name={`STT-${index + 1}`}>
              <span key={index}>{`${index + 1}`}</span>
            </Form.Item>
          </>
        );
      },
    },
    {
      title: "Mã khách hàng",
      width: 250,
      align: "center",
      dataIndex: "maKhachHang",
      key: "maKhachHang",
    },

    {
      title: "Điện thoại",
      width: 250,
      align: "center",
      dataIndex: "dienThoai",
      key: "dienThoai",
    },
    {
      title: "Trạng thái",
      width: 250,
      dataIndex: "trangThai",
      key: "trangThai",
    },
    {
      title: "Nội dung",
      width: 250,
      dataIndex: "noiDung",
      key: "noiDung",
    },
    {
      title: "Thời gian thực hiện",
      dataIndex: "thoiGianThucHien",
      width: 250,
      align: "center",
      key: "thoiGianThucHien",
      render: (text, record, index) => <span>{moment(record.thoiGianThucHien).format("DD/MM/YYYY")}</span>,
    },
  ];
  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <Spin spinning={loading}>
            <Form
              layout="vertical"
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ DonViId: unitId, NgayBatDau: moment(), NgayKetThuc: moment() }}
            >
              <Row gutter={10}>
                <Col span={8}>
                  <Form.Item
                    name="DonViId"
                    label="Đơn vị"
                    labelAlign="left"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Đơn vị không được để trống",
                      },
                    ]}
                  >
                    <Selection
                      url={Endpoint.GET_UNIT_LIST}
                      formKey="DonViId"
                      form={form}
                      placeholder="Chọn đơn vị"
                      setValue={onChangeDV}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="PhanLoaiTimKiem" label="Phân loại" labelAlign="left">
                    <Select defaultValue={PHAN_LOAI_TIN_NHAN_SMS_TIEN_DIEN_LAN_1} onChange={onChangePhanLoai}>
                      <Option value={PHAN_LOAI_TIN_NHAN_SMS_TIEN_DIEN_LAN_1}>Tin nhắn SMS Tiền điện lần 1</Option>
                      <Option value={PHAN_LOAI_TIN_NHAN_SMS_DICH_VU_KHAC}>Tin nhắn SMS Dịch vụ khác</Option>
                      <Option value={PHAN_LOAI_DICH_VU_DIEN_TU}>Dịch vụ điện tử</Option>
                      <Option value={PHAN_LOAI_CONG_TIN_NHAN_CSKH}>Cổng tin nhắn CSKH</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="HinhThuc" label="Hình thức" labelAlign="left">
                    <Select defaultValue={HINH_THUC_SMS} onChange={onChangeHinhThuc}>
                      <Option value={HINH_THUC_SMS}>SMS</Option>
                      <Option value={HINH_THUC_EMAIL}>Email</Option>
                      <Option value={HINH_THUC_ZALO}>Zalo</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={6}>
                  <Form.Item
                    name="MaDinhDanh"
                    label="Mã khách hàng / Số điện thoại"
                    labelAlign="left"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Mã khách hàng / số điện thoại không được để trống",
                      },
                    ]}
                  >
                    <Input onChange={onChangeMaKH} placeholder="Nhập mã khách hàng / số điện thoại" />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name="NgayBatDau" label="Ngày bắt đầu" labelAlign="left">
                    <DatePicker formKey="NgayBatDau" format={FORMAT_DATE} form={form} onChange={onChangeNgayBatDau} allowClear={false}/>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name="NgayKetThuc" label="Ngày kết thúc" labelAlign="left">
                    <DatePicker formKey="NgayKetThuc" format={FORMAT_DATE} form={form} onChange={onChangeNgayKetThuc} allowClear={false}/>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="&nbsp;">
                    <Button onClick={clearFilter} className="full">
                      Bỏ lọc
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="&nbsp;">
                    <Button type="primary" htmlType="submit" className="full">
                      Tìm kiếm
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <div className="btn-export-baocao">
                <Row gutter={16}></Row>
              </div>
            </Form>
            <Divider className="section-text" orientation="left"></Divider>
            {data && data?.length > 0 && filterConditions.PhanLoaiTimKiem !== PHAN_LOAI_CONG_TIN_NHAN_CSKH ? (
              <TableComponent
                header={"Kết quả hiển thị"}
                columns={columns}
                onChangePagination={onChangePagination}
                pagination={filterConditions}
                dataSource={data}
                scrollX={1200}
              />
            ) : (
              <TableComponent
                header={"Kết quả hiển thị"}
                columns={columnsCongTinNhanCSKH}
                onChangePagination={onChangePagination}
                pagination={filterConditions}
                dataSource={data}
                scrollX={1200}
              />
            )}
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
  .ant-form-item {
    margin: 0 !important;
  }
  .ant-col-24 {
    padding-bottom: 4px;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
