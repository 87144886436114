import { closeModal } from "@state/system-config/reducer";
import {
  LOAI_PHIEU_TREOTHAO,
  METHOD_POST,
  METHOD_PUT,
  NAVIGATE_TREOTHAO,
  NAVIGATE_TREOTHAO_DOITRUONG,
  NAVIGATE_TREOTHAO_PHIEUDAXUATVT,
  STATUSCODE_200,
  TYPE_PHIEUXUAT_CHUCNANG,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { getErrorMessage } from "@utils/function";
import {
  authGetData,
  authDeleteData,
  // authGetMultiplesRequest,
  authPostData,
  authGetMultiplesRequest,
} from "@utils/request";

export const handleListVT = (setLoading, setDataVT, selectRowKeys) => {
  authGetData({
    url: `${
      Endpoint.LIST_VAT_TU_PHIEU_XUAT_VAT_TU
    }?Id=${selectRowKeys.toString()}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataVT(res.data);
      }
    },
  });
};

function deduplicate(arr, field, count) {
  const isExist = (arr, x) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][field] === x[field]) {
        arr[i][count] += 1;
        return true;
      }
    }
    return false;
  };

  const ans = [];
  arr.forEach((element) => {
    if (!isExist(ans, element)) ans.push(element);
  });
  return ans;
}

export const handleShowInfomationPhieu = (
  setLoading,
  setDataVT,
  setDataVTLQ,
  selectRowKeys,
  loaiPhieuVatTu
) => {
  const payload = {
    loaiPhieuVatTu: loaiPhieuVatTu,
    yeuCauIds: selectRowKeys,
  };

  authPostData({
    url: Endpoint.DETAIL_YEU_CAU_PHIEU_XUAT_VAT_TU,
    method: METHOD_POST,
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        let dataVT = [];
        const dataVTLQ = [];
        dataVT = deduplicate(res.data, "chungLoai", "soLuongYeuCau");
        res.data.map((item, index) => {
          dataVTLQ.push({
            stt: index,
            chungLoai: item.chungLoai,
            loaiCongTo: item.loaiCongTo,
            ngayTreoThao: item.ngayTreoThao,
            loaiTreoThao: item.loaiTreoThao,
            tram: item.tram,
            doi: item.doi,
            maDiemDo: item.maDiemDo,
            tenKH: item.tenKH,
            id: item.id,
            moTa: item.moTa,
          });
          return true;
        });
        setDataVT(dataVT);
        setDataVTLQ(dataVTLQ);
      }
    },
  });
};

export const handleShowYeuCauByPhieu = (
  setLoading,
  setDataVT,
  setDataVTLQ,
  id,
  setTenLoaiTT,
  // setNgayTao,
  setTenDoi,
  setLyDo,
  setIsTraLai,
  setNguoiSuaPhieu,
  setYeuCauIds
) => {
  //Call api sửa phiếu.
  const endpoints = [
    `${Endpoint.LIST_YEU_CAU_PHIEU_VAT_TU}?Id=${id}`,
    `${Endpoint.LIST_VAT_TU_PHIEU_VAT_TU}?Id=${id}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      if (res.length > 0) {
        setDataVTLQ(res[0].data.data.yeuCaus);
        const yeuCauIds = [];
        if (res[0].data.data && res[0].data.data.yeuCaus.length > 0) {
          res[0].data.data.yeuCaus.map((item) => {
            return yeuCauIds.push(item.id);
          });
        }
        setYeuCauIds(yeuCauIds);
        const data = [];
        if (res[1].data.data.vatTuXuats.length > 0) {
          // eslint-disable-next-line array-callback-return
          res[1].data.data.vatTuXuats.map((item, index) => {
            data.push({
              ...item,
              stt: index + 1,
            });
          });
        }
        setDataVT(data);
        setTenLoaiTT(res[0].data.data.thongTinChung.tenLoaiTreoThao);
        setTenDoi(res[0].data.data.thongTinChung.doi);
        setLyDo(res[0].data.data.thongTinChung.lyDoTraLai);
        setIsTraLai(res[0].data.data.thongTinChung.isTraLai);
        setNguoiSuaPhieu(res[0].data.data.thongTinChung.nguoiTao);
      }
    },
  });
};

export const getListdataDSYCLQ = (
  setLoading,
  setDataDSYCLQ,
  loaiPhieuVatTu,
  loaiTreoThao,
  doiId,
  yeuCauIds
) => {
  const payload = {
    loaiPhieuVatTu: loaiPhieuVatTu,
    loaiTreoThao: loaiTreoThao,
    doiId: doiId,
    yeuCauIds: yeuCauIds,
  };
  authPostData({
    url: `${Endpoint.SEARCH_YEU_CAU_PHIEU_VAT_TU}`,
    method: METHOD_POST,
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataDSYCLQ(res.data);
      }
    },
  });
};

export const handleRemoveVTLQ = (setLoading, id, getListData, setFetchList) => {
  authDeleteData({
    url: Endpoint.REMOVE_DANH_SACH_YEU_CAU_LIEN_QUAN + "?id=" + id,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        getListData();
        setFetchList(true);
      }
    },
  });
};

export const handleCreateYeuCau = (
  payload,
  setLoading,
  getListData,
  setFetchList,
  form
) => {
  authPostData({
    url: Endpoint.CREATE_DANH_SACH_YEU_CAU_LIEN_QUAN,
    payload,
    method: METHOD_POST,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListData();
        setFetchList(true);
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleCreateOrUpdate = (
  payload,
  setLoading,
  type,
  setFetchList,
  form,
  dispatch,
  //closeModal,
  navigate,
  filterConditions
) => {
  let url = "";
  let method = METHOD_POST;
  if (type === TYPE_PHIEUXUAT_CHUCNANG.TAOMOI) {
    url = Endpoint.CREATE_PHIEU_VAT_TU;
  } else if (type === TYPE_PHIEUXUAT_CHUCNANG.TRALAI_DUYET) {
    url = Endpoint.TRALAI_DUYET_PHIEU_XUAT_VAT_TU;
  } else {
    url = Endpoint.UPDATE_PHIEU_VAT_TU;
    method = METHOD_PUT;
  }
  authPostData({
    url,
    payload,
    method,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        if (type === TYPE_PHIEUXUAT_CHUCNANG.THAYDOI) {
          setFetchList(true);
        }

        dispatch(closeModal());
        if (type === TYPE_PHIEUXUAT_CHUCNANG.TAOMOI) {
          navigate(
            NAVIGATE_TREOTHAO +
              NAVIGATE_TREOTHAO_DOITRUONG +
              NAVIGATE_TREOTHAO_PHIEUDAXUATVT
          );
          localStorage.setItem(
            "createFilterPhieu",
            JSON.stringify(filterConditions)
          );
        }
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const callAPIXacNhanPhieu = (
  isTraLai,
  typeLoaiPhieu,
  payload,
  setLoading,
  setFetchList,
  form,
  dispatch,
  closeModal
) => {
  let url = Endpoint.PVT_XAC_NHAN_XUAT_TRA_VT;
  if (typeLoaiPhieu === LOAI_PHIEU_TREOTHAO.PHIEUTRA) {
    url = Endpoint.PVT_XAC_NHAN_PHIEU_TRA_VT;
  }
  if (isTraLai) {
    url = Endpoint.PVT_TU_CHOI_VAT_TU;
  }

  authPostData({
    url,
    payload,
    method: METHOD_PUT,
    setLoading,
    onSuccess: (res) => {
      if (res.data && res.statusCode === STATUSCODE_200) {
        setFetchList(true);
        dispatch(closeModal());
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleTuChoiPhieu = (
  payload,
  setLoading,
  setFetchList,
  form,
  dispatch,
  closeModal
) => {
  authPostData({
    url: Endpoint.PVT_TU_CHOI_VAT_TU,
    payload,
    method: METHOD_PUT,
    setLoading,
    onSuccess: (res) => {
      if (res.data && res.statusCode === STATUSCODE_200) {
        setFetchList(true);
        dispatch(closeModal());
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleChiTietPhieuDuyet = (
  selectRowKeys,
  setLoading,
  setDataVT,
  setDefaultDataVT,
  setDefaultInfo,
  typeLoaiPhieu,
  form
) => {
  const url =
    Endpoint.CHI_TIET_PHIEU_VAT_TU_DUYET_TRALAI +
    "?phieuVatTuId=" +
    selectRowKeys +
    "&loaiPhieu=" +
    typeLoaiPhieu;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data && res.statusCode === STATUSCODE_200) {
        setDefaultDataVT(res.data.vatTuXuats);
        const newData = [];
        if (res.data.vatTuXuats.length > 0)
          // eslint-disable-next-line array-callback-return
          res.data.vatTuXuats.map((item, index) => {
            newData.push({
              chungLoai: item.chungLoai,
              chungLoaiId: item.chungLoaiId,

              loaiCongTo: item.loaiCongTo,
              moTa: item.moTa,
              [`soLuongCoTheXuat-${index + 1}`]: item.soLuongCoTheXuat,
              soLuongYeuCau: item.soLuongYeuCau,
              stt: item.stt,
              vatTuId: item.vatTuId,
              [`chenhLech-${index + 1}`]:
                item.soLuongCoTheXuat === null
                  ? item.soLuongYeuCau
                  : item.soLuongYeuCau - item.soLuongCoTheXuat,
            });
            form.setFieldsValue({
              [`soLuongCoTheXuat-${index + 1}`]: item.soLuongCoTheXuat,
              [`chenhLech-${index + 1}`]:
                item.soLuongCoTheXuat === null
                  ? item.soLuongYeuCau
                  : item.soLuongYeuCau - item.soLuongCoTheXuat,
            });
          });
        setDataVT(newData);
        res.data.ngayTao = res.data.ngayTao.split("/").reverse().join("-");
        setDefaultInfo(res.data);
      }
    },
  });
};
