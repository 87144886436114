import { Endpoint } from "@utils/endpoint";

export const listOptionBCKDKHmoi = [
    {
      label: "1. Tổng hợp lắp đặt điện mặt trời mái nhà",
      value: Endpoint.BCCTKD_DSKH_LAP_DAT_MAT_TROI_AP_MAI,
    },
    {
      label: "2. Tổng hợp sản lượng mua bán điện mặt trời mái nhà",
      value: Endpoint.BCCTKD_CHI_TIET_KH_LAP_DAT_MAT_TROI_AP_MAI,
    },
  ];

  export const listOptionKiemChi = [
    {
      label: "1. Công tác quản lý sử dụng kìm chì niêm phong tổng hợp",
      value: Endpoint.BCCTKD_CT_QL_KIM_CHI_NIEM_PHONG_TONG_HOP,
    },
    {
      label:
        "2. Công tác quản lý sử dụng kìm chì niêm phong chi tiết theo mã kìm",
      value: Endpoint.BCCTKD_QLSD_KIM_CHI_THEO_MA_KIM,
    },
  ];
