import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import ButtonComponent from "@components/Button";
import CheckPermission from "@components/CheckPermission";
import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { permission } from "@permissions/k_qtht";
import { openModal } from "@state/system-config/reducer";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { authGetData } from "@utils/request";
import { Tooltip } from "antd";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { ListFilter } from "./list-bo-loc";
import ModalCreateOrEditPage from "./modal-create-or-edit-page";
import { handleDeletePage } from "./services";

export default function QuanLyTrang() {
  const [, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const getPageList = useCallback(() => {
    authGetData({
      url: `${Endpoint.PAGE}?${buildQueryString(parseParams(location.search))}`,
      setLoading,
      onSuccess: (res) => {
        setDataSource(res.data);
        setTotalCount(res.paging.totalCount);
      },
    });
  }, [location.search]);

  useEffect(() => {
    getPageList();
  }, [getPageList]);

  const genExtra = useCallback(
    () => (
      <CheckPermission permissionCode={permission.k4_tao}>
        <ButtonComponent
          size="small"
          type="primary"
          onClick={() =>
            dispatch(
              openModal({
                title: "Thêm mới trang",
                content: (
                  <ModalCreateOrEditPage
                    dispatch={dispatch}
                    getPageList={getPageList}
                  />
                ),
                size: "large",
              })
            )
          }
        >
          Tạo mới
        </ButtonComponent>
      </CheckPermission>
    ),
    [dispatch, getPageList]
  );

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const handleOpenModalEditPage = useCallback(
    (page) => {
      dispatch(
        openModal({
          title: "Thay đổi trang",
          content: (
            <ModalCreateOrEditPage
              dispatch={dispatch}
              page={page}
              getPageList={getPageList}
            />
          ),
          size: "large",
        })
      );
    },
    [dispatch, getPageList]
  );
  const onDeletePage = useCallback(
    (pageId) => {
      handleDeletePage({
        values: { id: pageId },
        setLoading,
        getPageList,
        dataSource,
        setFilterConditions,
      });
    },
    [getPageList, dataSource]
  );

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      ...values,
    }));
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  const columns = [
    {
      title: "",
      key: "index",
      render: (a, b, index) => {
        return (
          (filterConditions.pageIndex - 1) * filterConditions.pageSize +
          (index + 1)
        );
      },
      width: "50px",
    },
    {
      title: "Module",
      dataIndex: "moduleName",
      sorter: true,
    },
    {
      title: "Tên trang",
      dataIndex: "pageName",
      sorter: true,
    },
    {
      title: "Đường dẫn",
      dataIndex: "url",
      sorter: true,
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      className: "text-center",
      render: (trangThai) => (
        <span className={trangThai ? `active-color` : `unactive-color`}>
          {trangThai ? "Hoạt động" : "Không hoạt động"}
        </span>
      ),
      sorter: true,
    },
    {
      title: "Tác vụ",
      width: 100,
      fixed: "right",
      render: (row) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={permission.k4_sua}>
            <Tooltip title={"Sửa"}>
              <ButtonComponent
                type="link"
                onClick={() => handleOpenModalEditPage(row)}
                className="pd-5px"
              >
                <PencilIcon />
              </ButtonComponent>
            </Tooltip>
          </CheckPermission>
          <CheckPermission permissionCode={permission.k4_xoa}>
            <Tooltip title={"Xóa"}>
              <ButtonComponent
                type="link"
                onClick={() => onDeletePage(row.id)}
                className="pd-5px"
              >
                <TrashIcon />
              </ButtonComponent>
            </Tooltip>
          </CheckPermission>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
        />
      </Filter>
      <Container>
        <TableComponent
          header={"Danh sách trang"}
          renderExtraAction={() => genExtra()}
          dataSource={dataSource}
          columns={customColumn(columns, filterConditions)}
          totalData={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
          scrollX={1500}
          scrollY={600}
        />
      </Container>
    </Fragment>
  );
}
const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
`;
