import TrangChu from "@modules/ktcs-cong-to-thao";
import CapNhatChiSo from "@modules/ktcs-cong-to-thao/cap-nhat-chi-so";
import LayCToThao from "@modules/ktcs-cong-to-thao/lay-dl-tu-cmis";
import CauHinhCToThao from "@modules/ktcs-cong-to-thao/cau-hinh";
import BaoCaoCToThao from "@modules/ktcs-cong-to-thao/bao-cao";
import {
  NAVIGATE_CHISOCONGTO,
  NAVIGATE_CAPNHATCHISO_CTOTHAO,
  NAVIGATE_BAOCAO_CTOTHAO,
  NAVIGATE_CAUHINH_CTOTHAO,
  NAVIGATE_LAYDLCMIS_CTOTHAO,
  TITLE_CAPNHATCHISO_CTOTHAO,
  TITLE_BAOCAO_CTOTHAO,
  TITLE_CAUHINH_CTOTHAO,
  TITLE_LAYDLCMIS_CTOTHAO
} from "@utils/constants";
import { MODULE_CHISOCONGTO } from "@utils/constants";
import { chi_so_cong_to } from "@permissions/i_chisocongto";

const routes = [
  {
    title: '',
    path: NAVIGATE_CHISOCONGTO,
    component: TrangChu,
    privateRoute: true,
    permissionCode: MODULE_CHISOCONGTO,
    subItems: [],
  },
  {
    title: TITLE_LAYDLCMIS_CTOTHAO,
    path: NAVIGATE_LAYDLCMIS_CTOTHAO,
    component: LayCToThao,
    privateRoute: true,
    permissionCode: chi_so_cong_to.i1,
  },
  {
    title: TITLE_CAPNHATCHISO_CTOTHAO,
    path: NAVIGATE_CAPNHATCHISO_CTOTHAO,
    component: CapNhatChiSo,
    privateRoute: true,
    permissionCode: chi_so_cong_to.i1,
  },
  {
    title: TITLE_BAOCAO_CTOTHAO,
    path: NAVIGATE_BAOCAO_CTOTHAO,
    component: BaoCaoCToThao,
    privateRoute: true,
    permissionCode: chi_so_cong_to.i3,
  },
  {
    title: TITLE_CAUHINH_CTOTHAO,
    path: NAVIGATE_CAUHINH_CTOTHAO,
    component: CauHinhCToThao,
    privateRoute: true,
    permissionCode: chi_so_cong_to.i4,
  },
];

export default routes;
