import styled from "styled-components";

export const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
  button.ant-btn-primary {
    float: right;
    margin-top: -34px;
    margin-right: 120px;
  };
  button.action-button {
    margin-right: unset;
    margin-top: unset;
    &:hover {
      color: #fff;
    }
  };
  .action-table-column {
    justify-content: space-between;
  }
  button.btn-confirm {
    margin-right: 10px;
    background-color: #B4EEB4;
    border-color: #B4EEB4;
  };
  button.btn-confirm[disabled] {
    color: rgba(0,0,0,.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  };
`;
export const TableStyled = styled.div`
  .ant-table-thead {
    .ant-table-cell {
    background: #d9d9d9;
    color: #1f1f1f;
    font-size: 13px;
    }
  }
`;