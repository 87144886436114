import YeuCauModal from "./modal";

export default function Index(props) {
  const {
    loading,
    visible,
    setIsShowYC,
    dataSource,
    filter,
    onChangePagination,
  } = props;

  const columns = [
    {
      title: "Hạn KĐ của công tơ tháo",
      dataIndex: "hanKDCongToThao",
      key: "hanKDCongToThao",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "5%",
      sorter: true,
      fixed: "left",
    },
    {
      title: "Ngày treo tháo",
      dataIndex: "ngayTreoThao",
      key: "ngayTreoThao",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "5%",
      sorter: true,
      fixed: "left",
    },
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text) => (
        <div
          className="table-text-left "
          // onClick={() => getDetail(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: "7%",
      sorter: true,
      fixed: "left",
    },
    {
      title: "Loại treo tháo",
      dataIndex: "loaiTreoThao",
      key: "loaiTreoThao",
      render: (text, record) => (
        <div className="table-text-left">{record?.tenLoaiTreoThao}</div>
      ),
      width: "6%",
      sorter: true,
      fixed: "left",
    },
    {
      title: "Đội",
      dataIndex: "doi",
      key: "doi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "7%",
      sorter: true,
    },
    {
      title: "Chủng loại",
      dataIndex: "chungLoai",
      key: "chungLoai",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "6%",
      sorter: true,
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "9%",
      sorter: true,
    },
    {
      title: "Trạm",
      dataIndex: "tram",
      key: "tram",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "6%",
      sorter: true,
    },

    {
      title: "Tên KH - SĐT",
      dataIndex: "tenKHVaSDT",
      key: "tenKHVaSDT",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "11%",
      sorter: true,
    },
    {
      title: "Mã công tơ tháo",
      dataIndex: "maCongToThao",
      key: "maCongToThao",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "7%",
      sorter: true,
    },
    {
      title: "Số công tơ treo",
      dataIndex: "soCongToTreo",
      key: "soCongToTreo",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "7%",
      sorter: true,
    },

    {
      title: "Mã sổ GCS",
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "6%",
      sorter: true,
    },
    {
      title: "Số cột/hộp",
      dataIndex: "soCotHop",
      key: "soCotHop",
      render: (_, record) => (
        <div className="table-text-right">
          {record.soCot && record.soHop
            ? record.soCot + "/" + record.soHop
            : record.soCot
            ? record.soCot + "/"
            : record.soHop
            ? "/" + record.soHop
            : ""}
        </div>
      ),
      width: "6%",
    },

    {
      title: "Địa chỉ dùng điện",
      dataIndex: "diaChiDungDien",
      key: "diaChiDungDien",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "12%",
      sorter: true,
    },
  ];

  return (
    <YeuCauModal
      loading={loading}
      visible={visible}
      setIsShowYC={setIsShowYC}
      columns={columns}
      dataSource={dataSource}
      filter={filter}
      onChangePagination={onChangePagination}
    />
  );
}
