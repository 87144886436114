import { Fragment } from "react";
import ListFilter from "./list-bo-loc.jsx";
import { customColumn } from "@utils/function";
import { TYPE_HIDE_SHOW_COLUMNS } from "@utils/constants";
import Filter from "@components/Filter/Filter";
import TableHideColumns from "../common/show-hide-columns";

export default function ChiTietHinhThucLapDat(props) {
  const {
    columns,
    dataSource,
    filterConditions,
    totalCount,
    loading,
    onChangePagination,
    header,
    handleSearch,
    clearFilter,
    getListColumns,
    setType,
  } = props;
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          setType={setType}
        />
      </Filter>
      <TableHideColumns
        header={header}
        columns={customColumn(columns, filterConditions)}
        // expandable={{ expandedRowRender, defaultExpandAllRows: true }}
        dataSource={dataSource}
        filterConditions={filterConditions}
        pagination={filterConditions}
        totalData={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        getListColumns={getListColumns}
        type={TYPE_HIDE_SHOW_COLUMNS.CHITIETHINHTHUCLAPDAT}
        titleHeader={"Cấu hình cột cho bảng chi tiết theo hình thức lắp đặt"}
      />
    </Fragment>
  );
}
