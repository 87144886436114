import { Fragment } from "react";
import { Col, Row } from "antd";
import styled from "styled-components";

const TabThongTinDiemDo = ({ dataThongTin, dataTTPhuongThuc }) => {
  return (
    <Fragment>
      <Container>
        <div
          strong
          style={{
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          Thông tin điểm đo
        </div>
        <Row
          gutter={24}
          style={{
            height: 58,
            width: "100%",
          }}
        >
          <Col span={8} style={{ marginLeft: 20, paddingTop: 10 }}>
            <p className="text-blur" style={{ marginBottom: 5 }}>
              Mã điểm đo:
              <span style={{ fontWeight: 600, paddingLeft: 5 }}>
                {dataThongTin?.maDiemDo || dataTTPhuongThuc?.maDiemDo}
              </span>
            </p>
            <p className="text-blur">
              Cấp điện áp:
              <span style={{ fontWeight: 600, paddingLeft: 5 }}>
                {dataThongTin?.maCapDienAp || dataTTPhuongThuc?.maCapDienAp}
              </span>
            </p>
          </Col>
          <Col span={8} style={{ paddingTop: 10 }}>
            <p
              className="text-blur"
              style={{ marginBottom: 5, width: "500px" }}
            >
              Thông tin điểm đo:
              <span style={{ fontWeight: 600 }}>
                <> </>
                {dataThongTin?.thongTinDiemDo ||
                  dataTTPhuongThuc?.thongTinDiemDo}
              </span>
            </p>
            <p className="text-blur">
              Tính chất điểm đo:
              <span style={{ fontWeight: 600 }}>
                <> </>
                {dataThongTin?.maTinhChatDiemDo ||
                  dataTTPhuongThuc?.maTinhChatDiemDo}
              </span>
            </p>
          </Col>
          <Col span={7}>
            <p style={{ padding: 12 }}>
              <> </>
            </p>
            <p className="text-blur">
              Mã trạm:
              <span style={{ fontWeight: 600 }}>
                <> </>
                {dataThongTin?.maTram || dataTTPhuongThuc?.maTram}
              </span>
            </p>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default TabThongTinDiemDo;
const Container = styled.div`
  padding: 0px;
`;
// const TitlePage = styled.div`
//   display: flex;
//   width: 100%;
//   }
//   .fs20 {
//     color:gray;
//     opacity:80%;
//   }
// `;
// const Content = styled.div`
//   display: flex;
//   width: 100%;
//   justify-content: space-between;
//   }
// `;
// const InfoPage = styled.div`
//   display: block;
//   }
// `;
