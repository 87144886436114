import {
  useCallback,
  useEffect,
  useState,
  Fragment,
  useMemo,
  Suspense,
} from "react";

import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import { handlePagination, parseParams } from "@utils/function";
import { useLocation } from "react-router-dom";
import {
  CheckCircleTwoTone,
  FileTextOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import PhieuVatTuTable from "./table";
import {
  handleGetTienTrinh,
  handleListData,
  handleRemoveData,
} from "./services";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_KYSO,
  ENUM_LOAI_PHIEU,
  ENUM_PHIEU_VAT_TU,
  FORMAT_DATE,
  FORMAT_ENGLISH,
  LOAI_YEUCAU,
  MONTH_STRING,
  TYPE_PHIEU_YEUCAU,
} from "@utils/constants";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import { Button, Modal, Tooltip } from "antd";
import CheckPermission from "@components/CheckPermission";
import ButtonXacNhan from "../../common-ver1/xac-nhan-ky-duyet";
import { Endpoint } from "@utils/endpoint";
import { handleXemBienBan } from "../xem-bien-ban/services";
import KySoComponent from "@components/KySo";
import PDFModal from "../pdf-viewer";
import moment from "moment";
import ModalConfirm from "../confirm-xac-nhan";
import ThacTacNguoiDung from "../thao-tac-tien-trinh";
import ButtonKySoHSM from "../ky-so-hsm";

export default function Index(props) {
  const {
    url,
    funcId,
    fetchList,
    setFetchList,
    isRowSelect,
    rowSelection,
    selectedRowKeys,
    permissionCodeSua,
    permissionCodeXoa,
    permissionCodeXacNhan,
    permissionCodeDuyet,
    permissionCodeKySo,
    renderButtonTao,
    urlXN,
    payloadXN,
    contentXN,
    titleXN,
    isKySo,
    urlKySo,
    urlGetHash,
    payloadKySo,
    // isNotLastColumn,
    setFilterRedirect,
    setSelectedRowKeys,
    renderPopupSuaPhieu,
    renderPopupDuyetPhieu,
    localFilter,
    arrSelectedRows,
    renderButtonTL,
  } = props;

  const { user } = useSelector(authSelector);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState(
    localFilter && localFilter.ngayBatDauTT && localFilter.ngayKetThucTT
      ? {
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: user && user.pageSize ? user.pageSize : DEFAULT_PAGESIZE,
          ...parseParams(location.search),
          donViId:
            localFilter && localFilter.donViId
              ? localFilter.donViId
              : user.unitId,
          loaiTreoThao:
            localFilter && localFilter.loaiTreoThao
              ? localFilter.loaiTreoThao.toString()
              : null,
          loaiPhieu:
            localFilter && localFilter.loaiPhieu
              ? localFilter.loaiPhieu
              : funcId === ENUM_PHIEU_VAT_TU.DTPHIEUXUATVT
              ? ENUM_LOAI_PHIEU.PHIEU_XUATVT
              : funcId === ENUM_PHIEU_VAT_TU.DTPHIEUTRAVT
              ? ENUM_LOAI_PHIEU.PHIEU_TRAVT
              : null,
          ngayTaoTu:
            localFilter && localFilter.ngayBatDauTT
              ? localFilter.ngayBatDauTT
              : moment(moment().startOf(MONTH_STRING), FORMAT_DATE),
          ngayTaoDen:
            localFilter && localFilter.ngayKetThucTT
              ? localFilter.ngayKetThucTT
              : moment(moment(), FORMAT_DATE),
        }
      : {
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: user && user.pageSize ? user.pageSize : DEFAULT_PAGESIZE,
          ...parseParams(location.search),
          donViId:
            localFilter && localFilter.donViId
              ? localFilter.donViId
              : user.unitId,
          loaiTreoThao:
            localFilter && localFilter.loaiTreoThao
              ? localFilter.loaiTreoThao.toString()
              : null,
          loaiPhieu:
            localFilter && localFilter.loaiPhieu
              ? localFilter.loaiPhieu
              : funcId === ENUM_PHIEU_VAT_TU.DTPHIEUXUATVT
              ? ENUM_LOAI_PHIEU.PHIEU_XUATVT
              : funcId === ENUM_PHIEU_VAT_TU.DTPHIEUTRAVT
              ? ENUM_LOAI_PHIEU.PHIEU_TRAVT
              : null,
        }
  );

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //get list data phiếu
  const getListData = useCallback(() => {
    handleListData(
      url,
      funcId,
      filterConditions,
      setLoading,
      setDataSource,
      setTotalCount
    );
    if (setFilterRedirect) setFilterRedirect(filterConditions);
    localStorage.removeItem("createFilterPhieu");
  }, [filterConditions, funcId, setFilterRedirect, url]);

  useEffect(() => {
    getListData();
  }, [getListData]);

  useEffect(() => {
    //call api list if fetchList = true
    if (fetchList) {
      getListData();
      setFetchList(false);
    }
  }, [fetchList, getListData, setFetchList]);

  const [visibled, setVisibled] = useState(false);
  const [typeButton, setTypeButton] = useState("xac-nhan");
  const handleOpenXN = useCallback(
    (typeClick) => {
      setVisibled(!visibled);
      setTypeButton(typeClick);
    },
    [visibled]
  );

  const handleCloseXN = () => {
    setVisibled(false);
  };

  const showPopupXNPhieu = useCallback(() => {
    return (
      visibled && (
        <ModalConfirm
          visible={visibled}
          closeModal={handleCloseXN}
          payload={typeButton === "ky-so" ? payloadKySo : payloadXN()}
          url={typeButton === "ky-so" ? urlKySo : urlXN}
          setFetchList={setFetchList}
          title={titleXN}
          content={contentXN}
          dataSource={arrSelectedRows}
          type={TYPE_PHIEU_YEUCAU.PHIEU}
          setSelectedRowKeys={setSelectedRowKeys}
          typeClick={typeButton}
        />
      )
    );
  }, [
    visibled,
    typeButton,
    payloadKySo,
    payloadXN,
    urlKySo,
    urlXN,
    setFetchList,
    titleXN,
    contentXN,
    arrSelectedRows,
    setSelectedRowKeys,
  ]);

  // Hiển thị  các buttom tạo mới, ...theo điều kiện
  const genExtra = () => (
    <div>
      {renderButtonTL ? renderButtonTL() : null}
      {renderButtonTao ? renderButtonTao() : null}
      {permissionCodeXacNhan && funcId !== ENUM_PHIEU_VAT_TU.PVTDUYETPHIEUVT ? (
        <Button
          type="primary"
          className="mr-5px btn-bg-yellow"
          onClick={() => handleOpenXN("xac-nhan")}
          disabled={!selectedRowKeys.length}
        >
          Xác nhận
        </Button>
      ) : null}
      {permissionCodeKySo && funcId !== ENUM_PHIEU_VAT_TU.PVTDUYETPHIEUVT ? (
        // <KySoComponent
        //   url={urlKySo}
        //   urlGetHash={urlGetHash}
        //   payload={payloadKySo}
        //   permissionCode={permissionCodeKySo}
        //   disabled={!selectedRowKeys.length}
        //   setFetchDataTable={setFetchList}
        //   text={"Ký số USB"}
        //   setLoading={setLoading}
        //   loading={loading}
        //   bgColor={"#faad14"}
        //   typeKySo={ENUM_KYSO.TREOTHAO}
        // />
        <Button
          type="primary"
          onClick={() => handleOpenXN("ky-so")}
          disabled={!selectedRowKeys.length}
        >
          Ký số
        </Button>
      ) : // <ButtonKySoHSM
      //   url={urlKySo}
      //   params={payloadKySo}
      //   setFetchList={setFetchList}

      // />
      null}
    </div>
  );

  const fillColor = (maTrangThai) => {
    let color = "";
    switch (maTrangThai) {
      case 2:
        return (color = "#4E7AC7");
      case 3:
        return (color = "#FFAE2F");
      case 4:
        return (color = "#911146");
      default:
        return (color = "#04756F");
    }
  };

  //tạo column
  const column = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "6%",
      sorter: false,
      fixed: "left",
    },
    {
      title: "Mã phiếu",
      dataIndex: "maPhieu",
      key: "maPhieu",
      render: (text, record) => (
        <Fragment>
          {!record.isCreatedPdfFile ? (
            <div className="table-text-center">{text}</div>
          ) : (
            <Tooltip title="Xem phiếu">
              <div
                onClick={() => {
                  handleOpenPDF(record);
                }}
                style={{
                  cursor: "pointer",
                  background: "transparent",
                  borderColor: "transparent",
                  boxShadow: "none",
                  color: "#1890ff",
                  textAlign: "center",
                }}
              >
                <span>{text}</span>
              </div>
            </Tooltip>
          )}
        </Fragment>
      ),

      width: "15%",
      sorter: true,
      fixed: "left",
    },
    {
      title: "Đội",
      dataIndex: "doi",
      key: "doi",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Loại treo tháo",
      dataIndex: "tenLoaiTreoThao",
      key: "tenLoaiTreoThao",
      render: (text) => <span>{text}</span>,
      width: "12%",
      sorter: true,
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text, record) => (
        <Fragment>
          {record?.isTraLai ? (
            <div className="table-text-center">
              <div
                style={{
                  backgroundColor: fillColor(record.maTrangThai),
                  color: "#fff",
                }}
              >
                <span>{text}</span>
              </div>
              <div
                style={{
                  fontSize: "12px",
                  marginTop: "4px",
                  color: "#FF5247",
                  cursor: "pointer",
                }}
              >
                <Tooltip title={record.lyDoTraLai}>
                  <InfoCircleOutlined /> Bị trả lại
                </Tooltip>
              </div>
            </div>
          ) : (
            <div
              className="table-text-center"
              style={{
                backgroundColor: fillColor(record.maTrangThai),
                color: "#fff",
              }}
            >
              <span>{text}</span>
            </div>
          )}
        </Fragment>
      ),
      width: "15%",
      sorter: true,
    },
    {
      title: "Người tạo",
      dataIndex: "nguoiTao",
      key: "nguoiTao",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
      sorter: true,
    },
    {
      title: "Ngày tạo",
      dataIndex: "ngayTao",
      key: "ngayTao",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Loại phiếu",
      dataIndex: "loaiPhieu",
      key: "loaiPhieu",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Tác vụ",
      key: "action",
      align: "center",
      // width: "6%",
      fixed: "right",
      render: (row) => (
        <div className="action-table-column">
          {permissionCodeSua && renderPopupSuaPhieu ? (
            <CheckPermission
              permissionCode={permissionCodeSua}
              style={{
                display:
                  funcId === ENUM_PHIEU_VAT_TU.DTPHIEUXUATVT &&
                  row.loaiYeuCau === LOAI_YEUCAU.CAPDIEN
                    ? "none"
                    : "block",
              }}
            >
              <div
                className="action-icon"
                onClick={() => renderPopupSuaPhieu(row)}
              >
                <Tooltip title="Sửa">
                  <PencilIcon />
                </Tooltip>
              </div>
            </CheckPermission>
          ) : null}
          {permissionCodeXoa ? (
            <CheckPermission
              permissionCode={permissionCodeXoa}
              style={{
                display:
                  funcId === ENUM_PHIEU_VAT_TU.DTPHIEUXUATVT &&
                  row.loaiYeuCau === LOAI_YEUCAU.CAPDIEN
                    ? "none"
                    : "block",
              }}
            >
              <div
                className="action-icon"
                onClick={() => handleDeleteData(row.id)}
              >
                <Tooltip title="Xóa">
                  <TrashIcon />
                </Tooltip>
              </div>
            </CheckPermission>
          ) : null}
          {permissionCodeDuyet && renderPopupDuyetPhieu ? (
            <CheckPermission permissionCode={permissionCodeDuyet}>
              <div
                className="action-icon"
                onClick={() => renderPopupDuyetPhieu(row, permissionCodeKySo)}
              >
                <Tooltip title="Duyệt">
                  <CheckCircleTwoTone
                    twoToneColor="#52c41a"
                    style={{ fontSize: "18px" }}
                  />
                </Tooltip>
              </div>
            </CheckPermission>
          ) : null}
          {(permissionCodeXacNhan &&
            funcId !== ENUM_PHIEU_VAT_TU.PVTDUYETPHIEUVT) ||
          (permissionCodeKySo &&
            funcId !== ENUM_PHIEU_VAT_TU.PVTDUYETPHIEUVT) ? (
            <ButtonXacNhan
              // url={urlXNNotMany}
              url={urlXN}
              permissionCode={permissionCodeXacNhan}
              content={contentXN}
              title={titleXN}
              setFetchList={setFetchList}
              payload={payloadXN(row)}
              isIcon={true}
              isKySo={isKySo}
              urlKySo={urlKySo}
              urlGetHash={urlGetHash}
              permissionCodeKySo={permissionCodeKySo}
              payloadKySo={{
                phieuIds: row.id,
                // maTrangThai: row.maTrangThai,
              }}
              disabled={!selectedRowKeys?.length ? true : false}
              text={"Duyệt phiếu"}
            />
          ) : null}
          <Tooltip title="Xem thao tác người dùng">
            <div
              className="action-icon"
              onClick={() => handleShowTienTrinh(row.id)}
            >
              <FileTextOutlined style={{ fontSize: "18px" }} />
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];

  const [isShowTT, setIsShowTT] = useState(false);
  const [dataTT, setDataTT] = useState([]);

  const handleShowTienTrinh = useCallback((id) => {
    handleGetTienTrinh(id, setLoading, setDataTT, setIsShowTT);
  }, []);

  const handleCloseTienTrinh = () => {
    setIsShowTT(false);
  };

  const showModalTT = useCallback(() => {
    return (
      isShowTT && (
        <Modal
          title="Thao tác người dùng"
          visible={isShowTT}
          onCancel={handleCloseTienTrinh}
          footer={null}
          width={"75%"}
        >
          <ThacTacNguoiDung dataSource={dataTT} />
          <div style={{ textAlign: "center", marginTop: "24px" }}>
            <Button
              key="back"
              onClick={handleCloseTienTrinh}
              className="button-closed"
            >
              Đóng lại
            </Button>
          </div>
        </Modal>
      )
    );
  }, [dataTT, isShowTT]);

  const [urlPDF, setUrlPDF] = useState("");
  //open pdf phiếu in xuất/trả
  const handleOpenPDF = (record) => {
    const url = `${Endpoint.IN_BB_PHIEU_VAT_TU}?phieuVatTuId=${record.id}&loaiPhieu=${record.loaiPhieuNumber}`;
    setVisible(!visible);
    setUrlPDF(url);
  };

  const [visible, setVisible] = useState(false);

  //gọi api
  const callAPIXemBienBan = useCallback(
    (setUrl) => {
      handleXemBienBan(urlPDF, setLoading, (value) => {
        setUrl(value);
      });
    },
    [urlPDF]
  );

  //show in biên bản
  const showModalInBienBan = useMemo(
    () =>
      visible && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visible}
            setVisible={setVisible}
            title={`Xem biên bản`}
            getBienBan={(setUrl) => callAPIXemBienBan(setUrl)}
            loadingGetBienBan={loading}
            isDownloadExcel={false}
            isReload={false}
          />
        </Suspense>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visible]
  );

  // const [columns, setColumns] = useState(column);

  //tìm kiếm dữ liệu
  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        pageIndex: DEFAULT_PAGEINDEX,
        ...values,
        ngayTaoTu: moment(values.ngayTaoTu).format(FORMAT_ENGLISH),
        ngayTaoDen: moment(values.ngayTaoDen).format(FORMAT_ENGLISH),
        donViId: values.donViId,
      }));
      if (setSelectedRowKeys) setSelectedRowKeys([]);
    },
    [setSelectedRowKeys]
  );

  //clear bộ lọc
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
      loaiPhieu:
        funcId === ENUM_PHIEU_VAT_TU.DTPHIEUXUATVT
          ? ENUM_LOAI_PHIEU.PHIEU_XUATVT
          : funcId === ENUM_PHIEU_VAT_TU.DTPHIEUTRAVT
          ? ENUM_LOAI_PHIEU.PHIEU_TRAVT
          : null,
    });
    if (setSelectedRowKeys) setSelectedRowKeys([]);
  }, [funcId, setSelectedRowKeys, user.unitId]);

  // useEffect(() => {
  //   if (isNotLastColumn) columns.pop();
  //   setColumns(columns);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  //xóa phiếu
  const handleDeleteData = useCallback(
    (id) => {
      handleRemoveData(id, setLoading, setFetchList);
    },
    [setFetchList]
  );
  return (
    <Fragment>
      <PhieuVatTuTable
        funcId={funcId}
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        dataSource={dataSource}
        columns={column}
        genExtra={genExtra}
        isRowSelect={isRowSelect}
        rowSelection={rowSelection}
      />
      {showModalInBienBan}
      {showPopupXNPhieu()}
      {showModalTT()}
    </Fragment>
  );
}
