import DanhSachAnhTabs from "./tab-danh-sach-anh";
import { useCallback, useEffect, useState } from "react";
import { handleDataImage } from "../../services";

export default function Index(props) {
  const { yeuCauId, dataLoaiGT, firstDataValue, setDataLoaiGT } = props;
  const [loading, setLoading] = useState(false);
  const [dataImages, setDataImages] = useState([]);
  const [listId, setListId] = useState([]);
  const [firstLoaiGT, setFirstLoaiGT] = useState(firstDataValue);
  const [ids, setIds] = useState([]);

  const getListImages = useCallback(() => {
    handleDataImage(yeuCauId, setLoading, setDataImages, setListId, setIds);
  }, [yeuCauId]);

  useEffect(() => {
    getListImages();
  }, [getListImages]);

  const handleChangeType = (value) => {
    setFirstLoaiGT(value);
    scrollToTop(`data-${value}`);
  };

  const scrollToTop = (element) => {
    document
      .getElementById(element)
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEffect(() => {
    if (ids.length > 0) {
      const data = [];
      dataLoaiGT.map((item) => {
        if (ids.includes(item.id)) {
          return data.push(item);
        }
        return null;
      });
      setDataLoaiGT(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids]);

  return (
    <DanhSachAnhTabs
      loading={loading}
      dataLoaiGT={dataLoaiGT}
      firstLoaiGT={firstLoaiGT}
      handleChangeType={handleChangeType}
      dataImages={dataImages}
      listId={listId}
    />
  );
}
