import React, { useState } from "react";
import { handleKhoiPhucTaiChinh } from "../services";
import KhoiPhucTaiChinhModal from "./layout";

function Index({ visible, handleCloseModal, setFetchDataTable, detail }) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    const hosoId = detail.id;
    handleKhoiPhucTaiChinh(
      setLoading,
      handleCloseModal,
      setFetchDataTable,
      hosoId
    );
  };

  return (
    <KhoiPhucTaiChinhModal
      loading={loading}
      visible={visible}
      handleCloseModal={handleCloseModal}
      handleSubmit={handleSubmit}
    />
  );
}

export default Index;
