import TrangChu from "@modules/quan-tri-he-thong";
import QuanLyLogExternal from "@modules/quan-tri-he-thong/quan-ly-log/quan-ly-log-he-thong-ngoai";
import QuanLyLogError from "@modules/quan-tri-he-thong/quan-ly-log/quan-ly-log-he-thong";
import QuanLyLogInternal from "@modules/quan-tri-he-thong/quan-ly-log/quan-ly-log-noi-bo";
import QuanLyMenu from "@modules/quan-tri-he-thong/quan-ly-menu/quan-ly-menu";
import QuanLyNguoiDung from "@modules/quan-tri-he-thong/quan-ly-nguoi-dung/quan-ly-nguoi-dung";
import QuanLyPhanQuyen from "@modules/quan-tri-he-thong/quan-ly-phan-quyen/quan-ly-phan-quyen";
import QuanLyTrang from "@modules/quan-tri-he-thong/quan-ly-trang/quan-ly-trang";
import { permission } from "@permissions/k_qtht";
import { MODULE_HETHONG } from "@utils/constants";
const mainPath = "/quan-tri-he-thong";
const routes = [
  {
    title: "Trang chủ",
    path: mainPath,
    privateRoute: true,
    component: TrangChu,
    subItems: [],
    permissionCode: MODULE_HETHONG,
  },
  {
    title: "Quản lý người dùng",
    path: mainPath + "/nguoi-dung",
    component: QuanLyNguoiDung,
    privateRoute: true,
    subItems: [],
    permissionCode: permission.k2_user,
  },
  {
    title: "Quản lý phân quyền",
    path: mainPath + "/phan-quyen",
    component: QuanLyPhanQuyen,
    privateRoute: true,
    subItems: [],
    permissionCode: permission.k1_role,
  },
  {
    title: "Quản lý menu",
    path: mainPath + "/menu",
    component: QuanLyMenu,
    privateRoute: true,
    subItems: [],
    permissionCode: permission.k3_menu,
  },
  {
    title: "Quản lý trang",
    path: mainPath + "/danh-sach-trang",
    component: QuanLyTrang,
    privateRoute: true,
    subItems: [],
    permissionCode: permission.k4_page,
  },
  {
    title: "Quản lý log",
    path: mainPath + "/log",
    privateRoute: true,
    permissionCode: permission.k5_log,
    subItems: [
      {
        subTitle: "Quản lý log lỗi hệ thống",
        subPath: "/loi-he-thong",
        subComponent: QuanLyLogError,
        permissionCode: permission.k51_log_error,
      },
      {
        subTitle: "Quản lý log nội bộ",
        subPath: "/noi-bo",
        subComponent: QuanLyLogInternal,
        permissionCode: permission.k52_log_internal,
      },
      {
        subTitle: "Quản lý log hệ thống ngoài",
        subPath: "/he-thong-ngoai",
        subComponent: QuanLyLogExternal,
        permissionCode: permission.k53_log_external,
      },
    ],
  },
];

export default routes;
