import { BASE_API_URL_GNDN } from "@utils/constants";

export const APITongHopSL = {
  LIST_SANLUONG_TCT: `${BASE_API_URL_GNDN}/sanluong/danh-sach-san-luong-tct`,
  LIST_SANLUONG_DONVI: `${BASE_API_URL_GNDN}/sanluong/danh-sach-san-luong-donvi`,
  LIST_SANLUONG_CHITIET: `${BASE_API_URL_GNDN}/sanluong/danh-sach-san-luong-chitiet`,
  TINH_SANLUONG_GNDN: `${BASE_API_URL_GNDN}/sanluong/tinh-san-luong`,
  HUY_SANLUONG_GNDN: `${BASE_API_URL_GNDN}/sanluong/huy-san-luong`,
  XACNHAN_SANLUONG_GNDN: `${BASE_API_URL_GNDN}/sanluong/xac-nhan-san-luong`,
  CHOT_SANLUONG_GNDN: `${BASE_API_URL_GNDN}/sanluong/chot-san-luong`,
  HUY_CHOT_SANLUONG_GNDN: `${BASE_API_URL_GNDN}/sanluong/huy-chot-san-luong`,
  CHUYEN_THANG_LAMVIEC: `${BASE_API_URL_GNDN}/sanluong/chuyen-thang-lam-viec`,
  KIEMTRA_CHUYEN_THANG_LAMVIEC: `${BASE_API_URL_GNDN}/sanluong/kiem-tra-chuyen-thang-lam-viec`,
  KIEMTRA_HUY_CHUYEN_THANG_LAMVIEC: `${BASE_API_URL_GNDN}/sanluong/kiem-tra-huy-chuyen-thang-lam-viec`,
  HUY_CHUYEN_THANG_LAMVIEC: `${BASE_API_URL_GNDN}/sanluong/huy-chuyen-thang-lam-viec`,
  KTDK_HUY_SANLUONG: `${BASE_API_URL_GNDN}/sanluong/kiemtra-dieu-kien-huy-san-luong`,
  KTDK_HUYCHOT_SANLUONG: `${BASE_API_URL_GNDN}/sanluong/kiemtra-dieu-kien-huy-chot-san-luong`,
  KTDK_XACNHAN_SANLUONG: `${BASE_API_URL_GNDN}/sanluong/kiemtra-dieu-kien-xac-nhan-san-luong`,
  GNDN_DONGBOVECMIS: `${BASE_API_URL_GNDN}/sanluong/dong-bo-ve-cmis`,
  GNDN_LIST_DONGBOVECMIS: `${BASE_API_URL_GNDN}/sanluong/danh-sach-dong-bo-ve-cmis`,
  GNDN_LIST_CHISOCMIS: `${BASE_API_URL_GNDN}/sanluong/chi-so-dong-bo-ve-cmis`,
  GNDN_DD_CHUA_NHAP_CS: `${BASE_API_URL_GNDN}/sanluong/diem-do-chua-nhap-cs`,
};
