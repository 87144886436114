const m11 = {
  m11: "m11",
  m11view: "m11v",
  m11tao: "m11t",
  m11sua: "m11s",
  m11xoa: "m11x",
  m11viewtc: "m11vtc",
  m11taotc: "m11ttc",
  m11suatc: "m11stc",
  m11xoatc: "m11xtc",
  m11cmis: "m11cmis",
  m11import: "m11import",
};
const m12 = {
  m12: "m12t",
  m12v: "m12v",
  m12t: "m12t",
  m12s: "m12s",
  m12x: "m12x",
  m12cmis: "m12cmis",
};
const m13 = {
  m13: "m13x",
  m13cmis: "m13cmis",
};
const m14 = {
  m14: "m14",
  m14t: "m14t",
  m14v: "m14v",
  m14s: "m14s",
  m14x: "m14x",
};
const m15 = {
  m15: "m15v",
  m15cmis: "m15cmis",
};
const m16 = {
  m16: "m16v",
  m16cmis: "m16cmis",
};
const m17 = {
  m17: "m17v",
  m17cmis: "m17cmis",
};
const m18 = {
  m18: "m18",
  m18t: "m18t",
  m18v: "m18v",
  m18s: "m18s",
  m18x: "m18x",
  m18cmis: "m18cmis",
};
const m19 = {
  m19: "m19",
  m19t: "m19t",
  m19v: "m19v",
  m19s: "m19s",
  m19x: "m19x",
};
const m110 = {
  m110: "m110",
  m110t: "m110t",
  m110v: "m110v",
  m110s: "m110s",
  m110x: "m110x",
  m110cmis: "m110cmis",
};
const m111 = {
  m111: "m111",
  m111t: "m111t",
  m111s: "m111s",
};
const m112 = {
  m112: "m112",
  m112v: "m112v",
  m112cmis: "m112cmis",
};
const m113 = {
  m113: "m113",
  m113t: "m113t",
  m113v: "m113v",
  m113s: "m113s",
  m113x: "m113x",
};
const m114 = {
  m114: "m114",
  m114t: "m114t",
  m114v: "m114v",
  m114s: "m114s",
  m114x: "m114x",
};
const m115 = {
  m115: "m115",
  m115t: "m115t",
  m115v: "m115v",
  m115s: "m115s",
  m115x: "m115x",
};
const m116 = {
  m116: "m116",
  m116t: "m116t",
  m116v: "m116v",
  m116s: "m116s",
  m116x: "m116x",
};
const m117 = {
  m117: "m117",
  m117t: "m117t",
  m117v: "m117v",
  m117s: "m117s",
  m117x: "m117x",
};
const m118 = {
  m118: "m118",
  m118t: "m118t",
  m118v: "m118v",
  m118s: "m118s",
  m118x: "m118x",
};
const m119 = {
  m119: "m119",
  m119t: "m119t",
  m119v: "m119v",
  m119s: "m119s",
  m119x: "m119x",
};
const m120 = {
  m120: "m120",
  m120t: "m120t",
  m120v: "m120v",
  m120s: "m120s",
  m120x: "m120x",
  m120cmis: "m120cmis",
};
const m121 = {
  m121: "m121",
  m121t: "m121t",
  m121v: "m121v",
  m121s: "m121s",
  m121x: "m121x",
  m121cmis: "m121cmis",
};
const m122 = {
  m122: "m122",
  m122t: "m122t",
  m122v: "m122v",
  m122s: "m122s",
  m122x: "m122x",
  m122cmis: "m122cmis",
};
const m123 = {
  m123: "m123",
  m123t: "m123t",
  m123v: "m123v",
  m123s: "m123s",
  m123x: "m123x",
  m123cmis: "m123cmis",
};
const m124 = {
  m124: "m124",
  m124t: "m124t",
  m124v: "m124v",
  m124s: "m124s",
  m124x: "m124x",
};
const m125 = {
  m125: "m125",
  m125v: "m125v",
  m125tm: "m125tm",
  m125s: "m125s",
  m125x: "m125x",
};
const m126 = {
  m126: "m126",
  m126v: "m126v",
  m126cmis: "m126cmis",
};
const m127 = {
  m127: "m127",
  m127v: "m127v",
  m127cmis: "m127cmis",
};
const m128 = {
  m128: "m128",
  m128v: "m128v",
  m128cmis: "m128cmis",
};
const m129 = {
  m129: "m129",
  m129v: "m129v",
  m129cmis: "m129cmis",
};
const m130 = {
  m130: "m130",
  m130v: "m130v",
  m130cmis: "m130cmis",
};

const cap_dien_version = {
  m2: "m2",
  m2t: "m2t",
  m2v: "m2v",
  m2s: "m2s",
  m2x: "m2x",
};
const kiem_tra_thong_tin_hanh_vi_nang_luc = {
  m2: "m3",
  m2t: "m3t",
  m2v: "m3v",
  m2s: "m3s",
  m2x: "m3x",
};

const danh_muc = {
  m1: "m1",
};
export const tien_ich_chung = {
  ...danh_muc,
  ...cap_dien_version,
  ...kiem_tra_thong_tin_hanh_vi_nang_luc,
  ...m11,
  ...m12,
  ...m13,
  ...m14,
  ...m15,
  ...m16,
  ...m17,
  ...m18,
  ...m19,
  ...m110,
  ...m111,
  ...m112,
  ...m113,
  ...m114,
  ...m115,
  ...m116,
  ...m117,
  ...m118,
  ...m119,
  ...m120,
  ...m121,
  ...m122,
  ...m123,
  ...m124,
  ...m125,
  ...m126,
  ...m127,
  ...m128,
  ...m129,
  ...m130,
};
