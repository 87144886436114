import React, { Fragment, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import FormComponent from "@components/Form";
import { ENUM_VATTU, TYPE_INPUT } from "@utils/constants";
import LoadingComponent from "@components/Loading";
import InputFormatter from "@components/InputFormatter";
import { getDonGiaNhanCong } from "@modules/cap-dien/nhan-vien-khao-sat/services";

const { Option } = Select;

const UICoSan = ({
  dataChungLoaiVatTu,
  handleChangeTenVatTu,
  closeModal,
  loading,
  t,
  listVatTu,
  listHTTC,
  listChiPhi,
  // detailVatTu,
  flagVatTuPhu,
  handleChangeHTTC,
}) => {
  return (
    <Fragment>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            label="Tên vật tư"
            name="vatTuId"
            className="required-field"
          >
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                  option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
              onChange={handleChangeTenVatTu}
              // disabled={detailVatTu?.id}
            >
              {listVatTu &&
                listVatTu.map((item, index) => (
                  <Option key={index} value={item.vatTuId} id={item.maVatTu}>
                    {item.tenVaMaVatTu}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="Hình thức thi công"
            name="hinhThucThiCongId"
            className="required-field"
          >
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleChangeHTTC}
            >
              {listHTTC &&
                listHTTC.map((item, index) => (
                  <Option key={index} value={item.id ?? item.hinhThucThiCongId}>
                    {item.tenHinhThucTC ?? item.tenHinhThucThiCong}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={20}>
          <Form.Item
            label={t("vat_tu.vat_tu_khach_hang.so_luong")}
            name="soLuong"
            className="required-field"
          >
            <InputNumber min={0} />
          </Form.Item>
        </Col>
        <Col span={4} style={{ paddingTop: 30 }}>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.donViTinh !== curValues.donViTinh
            }
          >
            {({ getFieldValue }) => {
              const donViTinh = getFieldValue("donViTinh") || undefined;
              return donViTinh ? (
                <Typography.Text className="ant-form-text" strong>
                  {"(  " + donViTinh + "  )"}
                </Typography.Text>
              ) : null;
            }}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t("vat_tu.chung_loai")}
            name="chungLoai"
            className="required-field"
          >
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled
            >
              {flagVatTuPhu !== 1 && flagVatTuPhu !== undefined ? (
                <Option key={flagVatTuPhu} value={flagVatTuPhu}>
                  Vật tư phụ
                </Option>
              ) : (
                <Fragment>
                  {dataChungLoaiVatTu && dataChungLoaiVatTu.length > 0
                    ? dataChungLoaiVatTu.map((item, index) => (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      ))
                    : null}
                </Fragment>
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Định mức:"
            name="loaiChiPhiGianTiepId"
            // className="required-field"
          >
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              // disabled
            >
              {listChiPhi &&
                listChiPhi.map((item, index) => (
                  <Option key={index} value={item.loaiChiPhiId}>
                    {item.tenLoaiChiPhi}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col
          span={12}
          style={{ display: "flex", alignItems: "center", marginTop: 10 }}
        >
          <Form.Item
            label=""
            name="isVatTuPhuTinhThue"
            style={{ marginBottom: 10 }}
            valuePropName="checked"
          >
            <Checkbox>Vật tư phụ tính thuế</Checkbox>
          </Form.Item>
        </Col>
        <Col span={24} md={4} style={{ marginTop: 35 }}>
          <span className="fs-14px">Đơn giá</span>
        </Col>
        <Col span={24} md={20}>
          <Row gutter={24}>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Vật tư" name="donGiaVatTu">
                <InputFormatter
                  type={TYPE_INPUT.DECIMAL}
                  readOnly={true}
                  style={{
                    backgroundColor: "#e2e2e2",
                  }}
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Nhân công" name="donGiaNhanCong">
                <InputFormatter
                  type={TYPE_INPUT.DECIMAL}
                  readOnly={true}
                  style={{
                    backgroundColor: "#e2e2e2",
                  }}
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Máy thi công" name="donGiaMTC">
                <InputFormatter
                  type={TYPE_INPUT.DECIMAL}
                  readOnly={true}
                  style={{
                    backgroundColor: "#e2e2e2",
                  }}
                  min={0}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24} md={4} style={{ marginTop: 35 }}>
          <span className="fs-14px">Hệ số điều chỉnh</span>
        </Col>
        <Col span={24} md={20}>
          <Row gutter={24}>
            <Col className="gutter-row" span={8}>
              <Form.Item label="k1nc" name="k1nc">
                <InputNumber controls={false} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="k2nc" name="k2nc">
                <InputNumber controls={false} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="kmtc" name="kmtc">
                <InputNumber controls={false} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={24} justify="center">
        <Col span={12} style={{ textAlign: "right" }}>
          <Button
            key="cancel"
            type="default"
            onClick={closeModal}
            className="button-closed"
          >
            {t("button.dong_lai")}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            key="submit"
            type="primary"
            className="button-primary"
            htmlType="submit"
            name="form-vat-tu-co-san"
            loading={loading}
          >
            {t("button.cap_nhat")}
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

const UIMuaNgoai = ({
  dataChungLoaiVatTu,
  closeModal,
  loading,
  t,
  listChiPhi,
}) => {
  return (
    <Fragment>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            label="Tên vật tư"
            name="tenVatTu"
            className="required-field"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t("vat_tu.vat_tu_khach_hang.so_luong")}
            name="soLuong"
            className="required-field"
          >
            <InputNumber min={0} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Đơn vị tính"
            name="donViTinh"
            className="required-field"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t("vat_tu.chung_loai")}
            name="chungLoai"
            className="required-field"
          >
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {dataChungLoaiVatTu && dataChungLoaiVatTu.length > 0
                ? dataChungLoaiVatTu.map((item, index) => (
                    <Option key={index} value={item.value}>
                      {item.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Định mức:"
            name="loaiChiPhiGianTiepId"
            // className="required-field"
          >
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listChiPhi &&
                listChiPhi.map((item, index) => (
                  <Option key={index} value={item.loaiChiPhiId}>
                    {item.tenLoaiChiPhi}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col
          span={12}
          style={{ display: "flex", alignItems: "center", marginTop: 10 }}
        >
          <Form.Item
            label=""
            name="isVatTuPhuTinhThue"
            style={{ marginBottom: 10 }}
            valuePropName="checked"
          >
            <Checkbox>Vật tư phụ tính thuế</Checkbox>
          </Form.Item>
        </Col>
        <Col span={24} md={4} style={{ marginTop: 35 }}>
          <span className="fs-14px">Đơn giá</span>
        </Col>
        <Col span={24} md={20}>
          <Row gutter={24}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Vật tư"
                name="donGiaVatTu"
                className="required-field"
              >
                <InputFormatter type={TYPE_INPUT.VND} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Nhân công"
                name="donGiaNhanCong"
                className="required-field"
              >
                <InputFormatter type={TYPE_INPUT.VND} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Máy thi công"
                name="donGiaMTC"
                className="required-field"
              >
                <InputFormatter type={TYPE_INPUT.VND} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24} md={4} style={{ marginTop: 35 }}>
          <span className="fs-14px">Hệ số điều chỉnh</span>
        </Col>
        <Col span={24} md={20}>
          <Row gutter={24}>
            <Col className="gutter-row" span={8}>
              <Form.Item label="k1nc" name="k1nc">
                <InputNumber />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="k2nc" name="k2nc">
                <InputNumber />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="kmtc" name="kmtc">
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={24} justify="center">
        <Col span={12} style={{ textAlign: "right" }}>
          <Button
            key="cancel"
            type="default"
            onClick={closeModal}
            className="button-closed"
          >
            {t("button.dong_lai")}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            key="submit"
            type="primary"
            className="button-primary"
            htmlType="submit"
            name="form-vat-tu-co-san"
            loading={loading}
          >
            {t("button.cap_nhat")}
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default function UIVatTuKhaiGia(props) {
  const {
    visible,
    closeModal,
    form,
    t,
    handleSubmitData,
    detailVatTu,
    handleChangeTenVatTu,
    dataChungLoaiVatTu,
    loading,
    typeVatTu,
    listVatTu,
    listHTTC,
    listChiPhi,
    loadingCP,
    loadingCL,
    flagVatTuPhu,
    handleChangeHTTC,
    detailLapPhuongAn,
    vatTuDetailChange,
  } = props;

  useEffect(() => {
    if (
      // !(detailVatTu && Object.keys(detailVatTu).length > 0 && detailVatTu.id) &&
      typeVatTu === ENUM_VATTU.COSAN
    ) {
      if (
        !(detailVatTu && Object.keys(detailVatTu).length > 0 && detailVatTu.id)
      ) {
        if (Array.isArray(listHTTC) && listHTTC.length > 0) {
          form.setFieldsValue({
            hinhThucThiCongId: `${
              listHTTC[0].id ?? listHTTC[0].hinhThucThiCongId
            }`,
          });
          getDonGiaNhanCong(
            detailLapPhuongAn,
            vatTuDetailChange?.donGiaHieuLuc?.vatTuThoiGianHieuLucId,
            form.getFieldValue("hinhThucThiCongId"),
            undefined,
            (value) => {
              form.setFieldsValue({ donGiaNhanCong: value });
            },
            () => false,
            t
          );
        }
      } else {
        if (Array.isArray(listHTTC) && listHTTC.length > 0) {
          const hinhThucThiCongId = form.getFieldValue("hinhThucThiCongId");
          if (
            !hinhThucThiCongId &&
            vatTuDetailChange &&
            Object.keys(vatTuDetailChange).length > 0
          ) {
            form.setFieldsValue({
              hinhThucThiCongId: `${
                listHTTC[0].id ?? listHTTC[0].hinhThucThiCongId
              }`,
            });
            getDonGiaNhanCong(
              detailLapPhuongAn,
              vatTuDetailChange?.donGiaHieuLuc?.vatTuThoiGianHieuLucId,
              form.getFieldValue("hinhThucThiCongId"),
              undefined,
              (value) => {
                form.setFieldsValue({ donGiaNhanCong: value });
              },
              () => false,
              t
            );
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailVatTu, form, listHTTC, typeVatTu]);

  return (
    <Modal
      title={
        typeVatTu === ENUM_VATTU.COSAN
          ? "Vật tư có sẵn - Phần chi phí của Khách hàng"
          : "Vật tư mua ngoài - Phần chi phí của Khách hàng"
      }
      visible={visible}
      onCancel={closeModal}
      footer={null}
      width={800}
      style={{ top: 10 }}
    >
      <LoadingComponent loading={loading || loadingCP || loadingCL}>
        <FormComponent
          form={form}
          name="form-vat-tu-co-san"
          layout="vertical"
          onFinish={handleSubmitData}
          initialValues={{
            // ...detailVatTu,
            hinhThucThiCongId: detailVatTu.hinhThucThiCongId
              ? detailVatTu.hinhThucThiCongId
              : null,
            chungLoai: detailVatTu.chungLoai ? detailVatTu.chungLoai : null,
            donViTinh: detailVatTu.id ? detailVatTu.donViTinh : "",
          }}
          autoComplete="off"
        >
          {typeVatTu === ENUM_VATTU.COSAN ? (
            <UICoSan
              dataChungLoaiVatTu={dataChungLoaiVatTu}
              handleChangeTenVatTu={handleChangeTenVatTu}
              closeModal={closeModal}
              loading={loading}
              t={t}
              listVatTu={listVatTu}
              listHTTC={listHTTC}
              listChiPhi={listChiPhi}
              detailVatTu={detailVatTu}
              flagVatTuPhu={flagVatTuPhu}
              handleChangeHTTC={handleChangeHTTC}
            />
          ) : (
            <UIMuaNgoai
              dataChungLoaiVatTu={dataChungLoaiVatTu}
              handleChangeTenVatTu={handleChangeTenVatTu}
              closeModal={closeModal}
              loading={loading}
              t={t}
              listChiPhi={listChiPhi}
            />
          )}
        </FormComponent>
      </LoadingComponent>
    </Modal>
  );
}
