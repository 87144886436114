import { BASE_API_URL_DANH_MUC } from "@utils/constants";

export const APIImei = {
  LIST_IMEI: `${BASE_API_URL_DANH_MUC}/imei/list`,
  SYNC_IMEI: `${BASE_API_URL_DANH_MUC}/imei/sync`,
  CREATE_IMEI: `${BASE_API_URL_DANH_MUC}/imei/create`,
  UPDATE_IMEI: `${BASE_API_URL_DANH_MUC}/imei/update`,
  REMOVE_IMEI: `${BASE_API_URL_DANH_MUC}/imei/delete`,
  GET_IMEI: `${BASE_API_URL_DANH_MUC}/imei/get`,
  LIST_NGUOI_DUNG: `${BASE_API_URL_DANH_MUC}/imei/list-nguoi-dung`,
};
