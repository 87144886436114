import TrashIcon from "@assets/icon/TrashIcon";
import LoadingComponent from "@components/Loading";
import { callAPISearchBarCode } from "@modules/treo-thao/nhan-vien-treo-thao/yeu-cau-duoc-phan-cong/services";
import { alertMessage } from "@utils/function";
import { Checkbox, Col, Form, Input, Row } from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";

const { Search } = Input;

export default function Index(props) {
  const { form, detail, dataTreo, setCustomData, setChangeTab3 } = props;
  const [dataDangTreo, setDataTIDangTreo] = useState(detail);
  const [isChecked, setIsChecked] = useState(dataDangTreo?.nangHaCongSuat);
  const [loading, setLoading] = useState(false);

  const [defaultTreoMoi] = useState({
    nangHaCongSuat: false,
    tutIs: [
      {
        thietBiId: null,
        maThietBi: null,
        ngayKiemDinh: null,
        tySoDau: null,
        namSanXuat: null,
        soPha: null,
        isChayHong: false,
        maTemKiemDinh: null,
        seryTemKiemDinh: null,
        soLanLapTrinh: null,
        ngayLapTrinh: null,
        maChiKiemDinh: null,
        soChiKiemDinh: null,
        kimChiTai: null,
        soVienChiTai: null,
        maDonViKiemDinh: null,
        maNhanVienKiemDinh: null,
        maNhanVienLapTrinh: null,
      },
      {
        thietBiId: null,
        maThietBi: null,
        ngayKiemDinh: null,
        tySoDau: null,
        namSanXuat: null,
        soPha: null,
        isChayHong: false,
        maTemKiemDinh: null,
        seryTemKiemDinh: null,
        soLanLapTrinh: null,
        ngayLapTrinh: null,
        maChiKiemDinh: null,
        soChiKiemDinh: null,
        kimChiTai: null,
        soVienChiTai: null,
        maDonViKiemDinh: null,
        maNhanVienKiemDinh: null,
        maNhanVienLapTrinh: null,
      },
      {
        thietBiId: null,
        maThietBi: null,
        ngayKiemDinh: null,
        tySoDau: null,
        namSanXuat: null,
        soPha: null,
        isChayHong: false,
        maTemKiemDinh: null,
        seryTemKiemDinh: null,
        soLanLapTrinh: null,
        ngayLapTrinh: null,
        maChiKiemDinh: null,
        soChiKiemDinh: null,
        kimChiTai: null,
        soVienChiTai: null,
        maDonViKiemDinh: null,
        maNhanVienKiemDinh: null,
        maNhanVienLapTrinh: null,
      },
    ],
  });

  //xóa
  const handleDeleteData = useCallback(
    (thietBiId, viTri) => {
      const arr = [...dataDangTreo.tutIs];
      arr[viTri] = {
        thietBiId: null,
        maThietBi: null,
        ngayKiemDinh: null,
        tySoDau: null,
        namSanXuat: null,
        soPha: null,
        isChayHong: false,
        maTemKiemDinh: null,
        seryTemKiemDinh: null,
        soLanLapTrinh: null,
        ngayLapTrinh: null,
        maChiKiemDinh: null,
        soChiKiemDinh: null,
        kimChiTai: null,
        soVienChiTai: null,
        maDonViKiemDinh: null,
        maNhanVienKiemDinh: null,
        maNhanVienLapTrinh: null,
      };
      setDataTIDangTreo({
        ...dataDangTreo,
        tutIs: arr,
      });
      form.setFieldsValue({
        [`maTU-${viTri}`]: null,
      });
    },
    [dataDangTreo, form]
  );

  useEffect(() => {
    if (detail && detail.tutIs.length > 0) {
      dataDangTreo.tutIs.map((item, index) => {
        return form.setFieldsValue({
          [`maTU-${index}`]: item.maThietBi,
        });
      });
    }
  }, [dataDangTreo.tutIs, detail, form]);

  useEffect(() => {
    if (!isChecked && detail && detail?.tutIs?.length === 0)
      setDataTIDangTreo(defaultTreoMoi);
  }, [defaultTreoMoi, detail, isChecked]);

  //search công tơ
  const handleSearchBarCode = useCallback(
    (value, index) => {
      const type = "TU";
      const filterData = dataDangTreo.tutIs.filter(
        (el) => el.maThietBi === value
      );
      if (filterData.length > 0) {
        alertMessage(
          "error",
          "Thông báo!",
          "Vui lòng xóa các TU đã nhập trước khi thực thay đổi công suất !"
        );
      } else {
        callAPISearchBarCode(
          setLoading,
          setDataTIDangTreo,
          value,
          type,
          dataDangTreo,
          setChangeTab3,
          index
        );
      }
    },
    [dataDangTreo, setChangeTab3]
  );

  //hiển thị bảng
  const renderTable = useCallback(() => {
    return (
      <Row gutter={24}>
        <Col span={6} style={{ marginBottom: "8px", paddingRight: 0 }}>
          <div
            style={{
              textAlign: "center",
              borderRight: "1px solid #fff",
              background: "#D0E0E3",
            }}
          >
            Mã TU
          </div>
        </Col>
        <Col span={4} style={{ marginBottom: "8px", padding: 0 }}>
          <div
            style={{
              textAlign: "center",
              borderRight: "1px solid #fff",
              background: "#D0E0E3",
            }}
          >
            Số pha
          </div>
        </Col>
        <Col span={4} style={{ marginBottom: "8px", padding: 0 }}>
          <div
            style={{
              textAlign: "center",
              borderRight: "1px solid #fff",
              background: "#D0E0E3",
            }}
          >
            Tỷ số đấu
          </div>
        </Col>
        <Col span={4} style={{ marginBottom: "8px", padding: 0 }}>
          <div
            style={{
              textAlign: "center",
              borderRight: "1px solid #fff",
              background: "#D0E0E3",
            }}
          >
            Ngày kiểm định
          </div>
        </Col>
        <Col span={4} style={{ marginBottom: "8px", padding: 0 }}>
          <div
            style={{
              textAlign: "center",
              borderRight: "1px solid #fff",
              background: "#D0E0E3",
            }}
          >
            Tem kiểm định
          </div>
        </Col>
        <Col span={2} style={{ marginBottom: "8px", paddingLeft: 0 }}>
          <div
            style={{
              textAlign: "center",
              borderRight: "1px solid #fff",
              background: "#D0E0E3",
            }}
          >
            Xóa
          </div>
        </Col>
        {dataDangTreo.tutIs && dataDangTreo.tutIs.length > 0
          ? dataDangTreo.tutIs.map((item, index) => (
              <Fragment key={index}>
                <Col span={6}>
                  <div style={{ padding: isChecked ? "8px" : 0 }}>
                    {isChecked ? (
                      item.maThietBi
                    ) : (
                      <Form.Item name={`maTU-${index}`}>
                        <Search
                          placeholder="Nhập mã công tơ"
                          allowClear={false}
                          enterButton="Tìm"
                          onSearch={(value) =>
                            handleSearchBarCode(value, index)
                          }
                          onKeyDown={(e) =>
                            e.keyCode === 13 ? e.preventDefault() : ""
                          }
                        />
                      </Form.Item>
                    )}
                  </div>
                </Col>
                <Col span={4}>
                  <div style={{ textAlign: "right", padding: "8px" }}>
                    {item.soPha}
                  </div>
                </Col>
                <Col span={4}>
                  <div style={{ padding: "8px" }}>{item.tySoDau}</div>
                </Col>
                <Col span={4}>
                  <div style={{ textAlign: "center", padding: "8px" }}>
                    {item.ngayKiemDinh}
                  </div>
                </Col>
                <Col span={4}>
                  <div style={{ textAlign: "center", padding: "8px" }}>
                    {item.maTemKiemDinh}
                  </div>
                </Col>
                {isChecked || item.thietBiId === null ? null : (
                  <Col span={2}>
                    <div
                      style={{ textAlign: "center", padding: "8px" }}
                      className="action-icon"
                      onClick={() => handleDeleteData(item.thietBiId, index)}
                    >
                      <TrashIcon />
                    </div>
                  </Col>
                )}
              </Fragment>
            ))
          : null}
      </Row>
    );
  }, [dataDangTreo.tutIs, handleDeleteData, handleSearchBarCode, isChecked]);

  //check nâng hạ công suất
  const handleChange = (e) => {
    if (e.target.checked) {
      if (detail && detail?.tutIs?.length > 0) {
        const filterData = detail.tutIs.filter(
          (el, index) => el.thietBiId === dataTreo.tutIs[index].thietBiId
        );
        if (filterData.length > 0) {
          alertMessage(
            "error",
            "Thông báo!",
            `Mã TU đã nhập phải khác với Mã TU trong danh sách Treo mới`
          );
        }
        setIsChecked(false);
        const e = {
          target: { checked: false },
        };
        getSetForm(e);
      } else {
        setDataTIDangTreo({
          tutIs: dataTreo.tutIs,
          nangHaCongSuat: true,
        });
        setIsChecked(true);
        const e = {
          target: { checked: true },
        };
        getSetForm(e);
      }
    } else {
      setDataTIDangTreo({
        tutIs: [],
        nangHaCongSuat: false,
      });
      setIsChecked(false);
      const e = {
        target: { checked: false },
      };
      getSetForm(e);
    }
  };

  //get set form value
  const getSetForm = (e) => {
    form.setFieldsValue({
      isNangHaTU: e.target.checked,
    });
  };

  useEffect(() => {
    if (setCustomData) setCustomData(dataDangTreo);
  }, [setCustomData, dataDangTreo]);

  return (
    <LoadingComponent loading={loading}>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name={"isNangHaTU"} style={{ margin: 0 }}>
            <Checkbox
              checked={isChecked}
              onChange={handleChange}
              disabled={dataTreo && !dataTreo?.tutIs?.length}
            >
              Nâng hạ công suất
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={24}>{renderTable()}</Col>
      </Row>
    </LoadingComponent>
  );
}
