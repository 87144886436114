import { RetweetOutlined } from "@ant-design/icons";
import TrashIcon from "@assets/icon/TrashIcon";
import ButtonComponent from "@components/Button";
import CheckPermission from "@components/CheckPermission";
import { alertMessage, parseParams, removeUndefinedAttribute } from "@utils/function";
import { Button, Form, Tooltip } from "antd";
import locale from "antd/es/date-picker/locale/vi_VN";
import { Fragment, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import BangKeTable from "./layout";
import { handleListData, handleMultiBoLoc } from "./services";
import ConfirmModal from "../modal-confirm";
import KyBangKeModal from "../modal-ky-duyet";
import { Endpoint } from "@utils/endpoint";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import moment from "moment";
import { getThangLamViec } from "@modules/giao-nhan-dien-nang/tong-hop-so-lieu/tinh-san-luong/services";

export default function Index(props) {
  const {
    rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,
    arrSelect,
    permissionCodeKy,
    permissionCodeTL,
    permissionCodeHuy,
    fetchList,
    setFetchList,
    type,
    headerTable,
    loaiKy,
    isNotSelect,
  } = props;

  const location = useLocation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const { user } = useSelector(authSelector);
  const [idDonViTCT, setIdDonViTCT] = useState("");
  const [filterConditions, setFilterConditions] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [thangLamViec, setThangLamViec] = useState("");
  const [thangLamViecNew, setThangLamViecNew] = useState("");

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  useEffect(() => {
    if (thangLamViec !== "") {
      setFilterConditions({
        ...parseParams(location.search),
        donViId: !user.isTongCongTy ? user.unitId : idDonViTCT,
        thang: thangLamViec.split("-")[1],
        nam: thangLamViec.split("-")[0],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thangLamViec]);

  useEffect(() => {
    if (thangLamViec !== "") {
      setThangLamViecNew("");
      form.setFieldsValue({
        thangBaoCao: moment(thangLamViec),
      });
    }
  }, [form, thangLamViec]);

  const handleChangeThangLV = (value, dateString) => {
    setThangLamViecNew(dateString);
  };

  //get list data
  const getListData = useCallback(() => {
    handleListData(filterConditions, setLoading, setDataSource, setTotalCount);
  }, [filterConditions]);

  useEffect(() => {
    if (thangLamViec !== "") {
      getListData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListData]);

  useEffect(() => {
    //call api list if fetchList = true
    if (fetchList) {
      getListData();
      setFetchList(false);
    }
  }, [fetchList, getListData, setFetchList]);

  const [donVi, setDonVi] = useState([]);
  const [loaiBangKe, setLoaiBangKe] = useState([]);

  useEffect(() => {
    handleMultiBoLoc(setLoading, setDonVi, setLoaiBangKe, setIdDonViTCT);
  }, []);

  //tìm kiếm dữ liệu

  const handleSearch = useCallback(
    (values) => {
      setFilterConditions({
        donViId: values.donViId,
        loaiBangKeId: values.loaiBangKeId,
        trangThaiKy: values.trangThaiKy,
        thang: thangLamViecNew !== "" ? thangLamViecNew.split("/")[0] : thangLamViec.split("-")[1],
        nam: thangLamViecNew !== "" ? thangLamViecNew.split("/")[1] : thangLamViec.split("-")[0],
      });
    },
    [thangLamViec, thangLamViecNew]
  );

  //clear search
  const clearFilter = useCallback(() => {
    setFilterConditions({
      donViId: !user.isTongCongTy ? user.unitId : idDonViTCT,
      thang: thangLamViec.split("-")[1],
      nam: thangLamViec.split("-")[0],
    });
    if (thangLamViec !== "") {
      setThangLamViecNew("");
      form.setFieldsValue({
        thangBaoCao: moment(thangLamViec),
      });
    }
  }, [form, idDonViTCT, thangLamViec, user.isTongCongTy, user.unitId]);

  const handleCheckDisableBtnHuy = (row) => {
    if (row?.tinhTrangKy === "Đã ký duyệt") {
      return !(row.choPhepHuy);
    }
    return thangLamViecNew && thangLamViecNew !== moment(new Date(thangLamViec)).format("MM/YYYY");
  };

  //KHAI BÁO COLUMNS
  const columns = [
    {
      title: "Loại bảng kê",
      dataIndex: "loaiBangKe",
      key: "loaiBangKe",
      width: "15%",
      render: (text, record) => (
        <div
          onClick={() => {
            handleOpenPDF(record);
          }}
          style={{
            cursor: "pointer",
            background: "transparent",
            borderColor: "transparent",
            boxShadow: "none",
            color: "#1890ff",
            textAlign: "left",
          }}
        >
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Đơn vị giao nhận",
      dataIndex: "donViGiaoNhan",
      key: "donViGiaoNhan",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "20%",
    },
    {
      title: "Ngày ký 1",
      dataIndex: "ngayKi1",
      key: "ngayKi1",
      render: (text) => (
        <div className="table-text-left">
          <span>{text ? moment(text).format("DD/MM/YYYY HH:mm") : ""}</span>
        </div>
      ),
      width: "11%",
    },
    {
      title: "Ngày ký 2",
      dataIndex: "ngayKi2",
      key: "ngayKi2",
      render: (text) => (
        <div className="table-text-left">
          <span>{text ? moment(text).format("DD/MM/YYYY HH:mm") : ""}</span>
        </div>
      ),
      width: "11%",
    },
    {
      title: "Ngày ký 3",
      dataIndex: "ngayKi3",
      key: "ngayKi3",
      render: (text) => (
        <div className="table-text-left">
          <span>{text ? moment(text).format("DD/MM/YYYY HH:mm") : ""}</span>
        </div>
      ),
      width: "11%",
    },
    {
      title: "Ngày ký 4",
      dataIndex: "ngayKi4",
      key: "ngayKi4",
      render: (text) => (
        <div className="table-text-left">
          <span>{text ? moment(text).format("DD/MM/YYYY HH:mm") : ""}</span>
        </div>
      ),
      width: "11%",
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThaiKy",
      key: "trangThaiKy",
      width: "15%",
      align: "center",
      render: (text, record) => (
        <div
          className="table-text-center"
          style={record?.tinhTrangKy === "Chưa ký duyệt" ? { background: "#F9CB9C" } : { background: "#00FFFF" }}
        >
          <span
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>{record?.tinhTrangKy}</span>
            {record?.biTraLai ? (
              <Tooltip title={record?.lyDoTraLai}>
                <span style={{ color: "#FF0084" }}>Bị trả lại</span>
              </Tooltip>
            ) : (
              ""
            )}
          </span>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: "6%",
      render: (row) => (
        <div className="action-table-column">
          {loaiKy === 0 ? (
            <CheckPermission permissionCode={permissionCodeHuy}>
              <Tooltip title={"Hủy"}>
                <ButtonComponent
                  type="link"
                  onClick={() => handleOpenModalHuy(row)}
                  disabled={handleCheckDisableBtnHuy(row)}
                  className="pd-5px"
                >
                  <TrashIcon />
                </ButtonComponent>
              </Tooltip>
            </CheckPermission>
          ) : null}
          {(findMaLoaiBangKe(row) === 2 && loaiKy === 3) ||
          (findMaLoaiBangKe(row) === 2 && loaiKy === 4) ||
          loaiKy !== 0 && (
            <CheckPermission permissionCode={permissionCodeTL}>
              <Tooltip title={"Trả lại"}>
                <ButtonComponent
                  type="link"
                  onClick={() => handleOpenModalTL(row)}
                  disabled={handleCheckDisableBtnHuy()}
                  className="pd-5px"
                >
                  <RetweetOutlined />
                </ButtonComponent>
              </Tooltip>
            </CheckPermission>
          )}
        </div>
      ),
    },
  ];

  //call api biên bản
  const handleOpenPDF = (record) => {
    if (record.fileUrl !== "" || record.fileUrl !== null) window.open(record.fileUrl, "_blank");
    else alertMessage("error", "Thông báo!", "Không có dữ liệu");
  };

  //find mã loại bảng kê
  const findMaLoaiBangKe = useCallback(
    (record) => {
      const obj = loaiBangKe.find((el) => el?.id === record?.loaiBangKeId);
      if (obj?.maLoaiBangKe) return obj?.maLoaiBangKe;
      else return null;
    },
    [loaiBangKe]
  );

  //find mã loại bảng kê
  const findMaLoaiBaoCao = (selectedRow) => {
    const listMaLoaiBangKe = selectedRow?.map((item) => item.maLoaiBangKe);
    return listMaLoaiBangKe;
  };

  const [visibleHuy, setVisibleHuy] = useState(false);
  const [visibleTL, setVisibleTL] = useState(false);
  const [visibleKy, setVisibleKy] = useState(false);
  const [detailRecord, setDetailRecord] = useState({});

  //set MODAL HUY
  const handleOpenModalHuy = useCallback(
    (record) => {
      setVisibleHuy(!visibleHuy);
      setDetailRecord(record);
    },
    [visibleHuy]
  );
  //close modal huy
  const handleCloseModalHuy = () => {
    setVisibleHuy(false);
  };
  //show ui modal
  const showModalHuy = useCallback(() => {
    return (
      visibleHuy && (
        <ConfirmModal
          url={Endpoint.HUY_BANG_KE}
          payload={{
            tinhTrangBangKeId: detailRecord?.id,
          }}
          detail={detailRecord}
          closeModal={handleCloseModalHuy}
          visible={visibleHuy}
          setFetchList={setFetchList}
          content={
            findMaLoaiBangKe(detailRecord) === 1 ? (
              <span>
                Bạn chắc chắn muốn hủy <span style={{ color: "#0000FF" }}>Biên bản quyết toán điện năng giao nhận</span> này?
              </span>
            ) : (
              <span>
                Bạn chắc chắn muốn hủy <span style={{ color: "#0000FF" }}>Báo cáo chi tiết điện năng giao nhận</span> này?
              </span>
            )
          }
          title={"Hủy bảng kê"}
          isTL={false}
        />
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleHuy]);

  //set modal tl
  const handleOpenModalTL = (record) => {
    setVisibleTL(true);
    setDetailRecord(record);
  };

  //close modal tl
  const handleCloseModalTL = () => {
    setVisibleTL(false);
  };

  //show modal ui
  const showModalTL = useCallback(() => {
    return (
      visibleTL && (
        <ConfirmModal
          url={findMaLoaiBangKe(detailRecord) === 1 ? Endpoint.TRA_LAI_BANG_KE_BIEN_BAN : Endpoint.TRA_LAI_BANG_KE_BAO_CAO}
          payload={{
            tinhTrangBangKeId: detailRecord?.id,
          }}
          detail={detailRecord}
          closeModal={handleCloseModalTL}
          visible={visibleTL}
          setFetchList={setFetchList}
          content={
            findMaLoaiBangKe(detailRecord) === 1 ? (
              <span>
                <span style={{ color: "#0000FF" }}>Biên bản quyết toán điện năng giao nhận</span>{" "}
                {loaiKy === 4
                  ? "sẽ được trả cho Phòng kinh doanh(Tổng công ty)!"
                  : loaiKy === 3
                  ? "sẽ được trả cho Lãnh đạo(Đơn vị)!"
                  : loaiKy === 2
                  ? "sẽ được trả cho Phòng kinh doanh(Đơn vị)!"
                  : loaiKy === 1
                  ? "sẽ được trả cho NV tạo BB!"
                  : ""}
              </span>
            ) : (
              <p>
                <span style={{ color: "#0000FF" }}>Báo cáo chi tiết điện năng giao nhận</span>{" "}
                {loaiKy === 4
                  ? "sẽ được trả cho Phòng kinh doanh(Tổng công ty)!"
                  : loaiKy === 3
                  ? "sẽ được trả cho Lãnh đạo(Đơn vị)!"
                  : loaiKy === 2
                  ? "sẽ được trả cho Phòng kinh doanh(Đơn vị)!"
                  : loaiKy === 1
                  ? "sẽ được trả cho Phòng NV tạo BB!"
                  : ""}
              </p>
            )
          }
          title={"Trả lại bảng kê"}
          isTL
          loaiKy={loaiKy}
        />
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleTL]);

  //set modal tl
  const handleOpenModalKy = useCallback(() => {
    setVisibleKy(!visibleKy);
  }, [visibleKy]);

  //close modal tl
  const handleCloseModalKy = () => {
    setVisibleKy(false);
  };

  //show text content modal ký
  const showContentKy = useCallback(() => {
    let text = <span></span>;
    if (findMaLoaiBaoCao(arrSelect).includes(1) && findMaLoaiBaoCao(arrSelect).includes(2)) {
      text = (
        <span>
          {loaiKy === 4 ? "Bạn chắc chắc ký duyệt" : ""}{" "}
          <span style={{ color: "#0000FF" }}>Báo cáo chi tiết điện năng giao nhận</span> và{" "}
          <span style={{ color: "#0000FF" }}>Biên bản quyết toán điện năng giao nhận</span>{" "}
          {loaiKy === 3
            ? "sẽ được chuyển cho Lãnh đạo(Tổng công ty)!"
            : loaiKy === 1
            ? "sẽ được chuyển cho Lãnh đạo(Đơn vị)!"
            : loaiKy === 2
            ? "sẽ được chuyển cho Phòng kinh doanh(Tổng công ty)!"
            : ""}
        </span>
      );
    } else if (findMaLoaiBaoCao(arrSelect).includes(1)) {
      text = (
        <span>
          {loaiKy === 4 ? "Bạn chắc chắc ký duyệt" : ""}{" "}
          <span style={{ color: "#0000FF" }}>Biên bản quyết toán điện năng giao nhận</span>{" "}
          {loaiKy === 3
            ? "sẽ được chuyển cho Lãnh đạo(Tổng công ty)!"
            : loaiKy === 1
            ? "sẽ được chuyển cho Lãnh đạo(Đơn vị)!"
            : loaiKy === 2
            ? "sẽ được chuyển cho Phòng kinh doanh(Tổng công ty)!"
            : ""}
        </span>
      );
    } else if (findMaLoaiBaoCao(arrSelect).includes(2)) {
      text = (
        <span>
          {loaiKy === 4 ? "Bạn chắc chắc ký duyệt" : ""}{" "}
          <span style={{ color: "#0000FF" }}>Báo cáo chi tiết điện năng giao nhận</span>{" "}
          {loaiKy === 3
            ? "sẽ được chuyển cho Lãnh đạo(Tổng công ty)!"
            : loaiKy === 1
            ? "sẽ được chuyển cho Lãnh đạo(Đơn vị)!"
            : loaiKy === 2
            ? "sẽ được chuyển cho Phòng kinh doanh(Tổng công ty)!"
            : "!"}
        </span>
      );
    }
    // }
    return text;
  }, [arrSelect, loaiKy]);

  //show modal ui

  const checkKyHSMBangKe = (listData, arrSelect) => {
    const BienBan = listData.filter((item) => item.maLoaiBangKe === 1);
    const BaoCao = listData.filter((item) => item.maLoaiBangKe === 2);

    if (arrSelect.includes(1) && arrSelect.includes(2)) {
      return [
        { ListBienBan: BienBan, url: Endpoint.KY_HSM_BANG_KE_BIEN_BAN },
        { ListBaoCao: BaoCao, url: Endpoint.KY_HSM_BANG_KE_BAO_CAO },
      ];
    }
    if (arrSelect.includes(1)) {
      return [{ ListBienBan: BienBan, url: Endpoint.KY_HSM_BANG_KE_BIEN_BAN }];
    }
    if (arrSelect.includes(2)) {
      return [{ ListBaoCao: BaoCao, url: Endpoint.KY_HSM_BANG_KE_BAO_CAO }];
    }
  };
  const checkXNBangKe = (listData, arrSelect) => {
    const BienBan = listData.filter((item) => item.maLoaiBangKe === 1);
    const BaoCao = listData.filter((item) => item.maLoaiBangKe === 2);

    if (arrSelect.includes(1) && arrSelect.includes(2)) {
      return [
        { ListBienBan: BienBan, url: Endpoint.XAC_NHAN_BANG_KE_BIEN_BAN },
        { ListBaoCao: BaoCao, url: Endpoint.XAC_NHAN_BANG_KE_BAO_CAO },
      ];
    }
    if (arrSelect.includes(1)) {
      return [{ ListBienBan: BienBan, url: Endpoint.XAC_NHAN_BANG_KE_BIEN_BAN }];
    }
    if (arrSelect.includes(2)) {
      return [{ ListBaoCao: BaoCao, url: Endpoint.XAC_NHAN_BANG_KE_BAO_CAO }];
    }
  };

  const showModalKy = useCallback(() => {
    return (
      visibleKy && (
        <KyBangKeModal
          urlXN={checkXNBangKe(arrSelect, findMaLoaiBaoCao(arrSelect))}
          urlHSM={checkKyHSMBangKe(arrSelect, findMaLoaiBaoCao(arrSelect))}
          closeModal={handleCloseModalKy}
          visible={visibleKy}
          setFetchList={setFetchList}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          type={type}
          title={"Ký duyệt bảng kê"}
          content={showContentKy()}
          loaiKy={loaiKy}
        />
      )
    );
  }, [arrSelect, loaiKy, selectedRowKeys, setFetchList, setSelectedRowKeys, showContentKy, type, visibleKy]);

  //render button ký duyệt
  const genExtra = () => {
    return (
      <Fragment>
        <CheckPermission permissionCode={permissionCodeKy}>
          <Button
            type="primary"
            onClick={handleOpenModalKy}
            disabled={
              !selectedRowKeys.length ||
              (thangLamViecNew && thangLamViecNew !== moment(new Date(thangLamViec)).format("MM/YYYY"))
            }
          >
            Ký duyệt
          </Button>
        </CheckPermission>
      </Fragment>
    );
  };

  useEffect(() => {
    getThangLamViec(setThangLamViec, setLoading);
  }, []);

  return (
    <Fragment>
      <BangKeTable
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        genExtra={genExtra}
        dataSource={dataSource}
        columns={columns}
        totalCount={totalCount}
        loading={loading}
        rowSelection={rowSelection}
        headerTable={headerTable}
        donVi={donVi}
        loaiBangKe={loaiBangKe}
        isNotSelect={isNotSelect}
        handleChangeThangLV={handleChangeThangLV}
        locale={locale}
        form={form}
      />
      {showModalHuy()}
      {showModalTL()}
      {showModalKy()}
    </Fragment>
  );
}
