import { all, call, takeLatest, put } from "redux-saga/effects";
import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authGet } from "@utils/request";
import {
  getPermissionDataAssignedForUser,
  savePermissionAssignedList,
} from "./reducer";
import { buildQueryString } from "@utils/function";
function* watchGetPermissionDataAssignedForUser({ payload }) {
  const { filterConditions, userId } = payload;
  const res = yield call(
    authGet,
    `${Endpoint.USER}/get-role-by-user?userId=${userId}&${buildQueryString(
      filterConditions
    )}`
  );
  if (res && res.data.statusCode === STATUSCODE_200) {
    yield put(savePermissionAssignedList(res.data));
  } else {
    yield put(savePermissionAssignedList([]));
  }
}

export function* rootSagas() {
  yield all([
    takeLatest(
      getPermissionDataAssignedForUser,
      watchGetPermissionDataAssignedForUser
    ),
  ]);
}
