import React, { useState } from "react";
import { Button, Col, Modal, Row } from "antd";
import LoadingComponent from "@components/Loading";
import { Container } from "../../../common-ho-so/modal-duyet-ho-so/lanh-dao-quyet-toan/css-styled";
import { useTranslation } from "react-i18next";
import { handleTraLaiQuyetToanCongTy } from "../services";

export default function Index(props) {
  const { closeModal, setFetchDataTable, detail, visible } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  //Handle trả lại quyết toán công ty

  const handleTraLai = () => {
    const hoSoId = { hoSoId: detail.id };
    handleTraLaiQuyetToanCongTy(
      hoSoId,
      setLoading,
      closeModal,
      setFetchDataTable
    );
  };

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={"Hồ sơ đang ở bộ phận quyết toán"}
        visible={visible}
        onCancel={closeModal}
        footer={null}
      >
        <Container>
          <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
            {/* Hồ sơ đang ở bước lập quyết toán Khách hàng ! */}
            Hồ sơ của bạn đang ở bước lập quyết toán Khách hàng. Trả lại sẽ thực
            hiện lại việc lập quyết toán công ty !
          </h4>
          <Row gutter={24} style={{ marginTop: "48px", textAlign: "center" }}>
            <Col span={4} md={4}></Col>
            <Col span={5} md={5}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={11} md={11}>
              <Button
                type="primary"
                onClick={handleTraLai}
                loading={loading}
                key="traLai"
              >
                {"Trả lại quyết toán công ty"}
              </Button>
            </Col>
            <Col span={4} md={4}></Col>
          </Row>
        </Container>
      </Modal>
    </LoadingComponent>
  );
}
