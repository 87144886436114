import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalLanhDaoKyDuyet from "./modal-lanh-dao-ky-duyet";
import { handleDuyetHoSoService } from "./services";

function LanhDaoKyDuyetModal({
  visible,
  closeModal,
  setFetchDataTable,
  detail,
  dataMessage,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleDuyetHoSo = () => {
    const hoSoId = { id: detail.id };
    handleDuyetHoSoService(hoSoId, setLoading, closeModal, setFetchDataTable);
  };

  return (
    <ModalLanhDaoKyDuyet
      dataMessage={dataMessage}
      loading={loading}
      visible={visible}
      closeModal={closeModal}
      handleDuyetHoSo={handleDuyetHoSo}
      t={t}
    ></ModalLanhDaoKyDuyet>
  );
}

export default memo(LanhDaoKyDuyetModal);
