import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData, authDeleteData } from "@utils/request";
import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import {
  buildQueryString,
  parseParams,
} from "@utils/function";

export const getListDonViService = (setLoading, setDataDonVi) => {
  authGetData({
    url: `${Endpoint.GET_UNIT_LIST}?pageSize=-1`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDonVi(res.data);
      } else setDataDonVi([]);
    },
  });
};

// get list data cấu hình ký bảng kê
export const handleListDataCauHinh = (
  setLoading,
  location,
  setDataCauHinh,
  setTotalCount,
  filterConditions
) => {
  let url = "";
    url =  `${Endpoint.AG_KY_NHAY_LIST}?${buildQueryString(
      Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
    )}`;
    authGetData({
      url,
      setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataCauHinh(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataCauHinh([]);
    },
  });
};

export const getListLoaiBK = (
  setLoading,
  setLoaiBK
) => {
  let url = "";
    url =  Endpoint.LIST_LOAI_BK
    authGetData({
      url,
      setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setLoaiBK(res.data);
      } else setLoaiBK([]);
    },
  });
};

export const submitThemMoiCauHinh = (
  viTriCongViec, setLoading, setFetchDataTable, unit
) => {
  authPostData({
    url: Endpoint.AG_KY_NHAY_CREATE,
    method: METHOD_POST,
    payload: {
      donViId: unit,
      viTriCongViec: viTriCongViec,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
      }
    },
  });
};

export const submitUpdateCauHinh = (
  id, viTriCongViec, setLoading, setFetchDataTable, setEditingKey
) => {
  authPostData({
    url: Endpoint.AG_KY_NHAY_UPDATE,
    method: METHOD_PUT,
    payload: {
      id: id,
      viTriCongViec: viTriCongViec.viTriCongViec,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setEditingKey('')
      }
    },
  });
};

export const submitXoaCauHinh = (id,  setLoading, setFetchDataTable) => {
  authDeleteData({
    url: `${Endpoint.AG_KY_NHAY_DELETE}/${id}`,
    setLoading,
    content: `Bạn chắc chắn muốn xóa ?`,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) setFetchDataTable(true);
    },
  });
};