import React, { useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { handleReturnHoSo } from "../services";
import UITraHSXacNhan from "./ui-modal/tra-ho-so";

export default function XacNhanTraHSModal({
  visibleTraDoi,
  handleCloseModalTraDoi,
  setFetchDataTable,
  detail,
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [, setLoading] = useState(false);
  //submit
  const onFinish = (values) => {
    //submit dữ liệu
    handleReturnHoSo(
      values,
      detail,
      setLoading,
      setFetchDataTable,
      handleCloseModalTraDoi,
      form
    );
  };

  return (
    <UITraHSXacNhan
      t={t}
      visibleTraDoi={visibleTraDoi}
      handleCloseModalTraDoi={handleCloseModalTraDoi}
      form={form}
      onFinish={onFinish}
    />
  );
}
