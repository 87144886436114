import LoadingComponent from "@components/Loading";
import { ENUM_AHT } from "@utils/constants";
import { Col, Row } from "antd";
import { Container } from "../../css-styled";
import AnhHienTruong from "../../panels/anh-hien-truong";
export default function HinhAnhHienTruong(props) {
  const { dataAnh, dataDetail, dataChiSo } = props;

  return (
    <LoadingComponent loading={false}>
      <Container>
        {dataAnh && dataAnh.length > 0 ? (
          <Row
            gutter={24}
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            <Col
              span={12}
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <AnhHienTruong
                dataAnh={dataAnh}
                dataDetail={dataDetail}
                dataChiSo={dataChiSo}
              />
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <AnhHienTruong
                dataAnh={dataAnh}
                dataDetail={dataDetail}
                type={ENUM_AHT.AHT}
              />
            </Col>
          </Row>
        ) : (
          <Row
            gutter={24}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div style={{ fontSize: 18, textAlign: "center", fontWeight: 600 }}>
              Không có hình ảnh hiện trường!
            </div>
          </Row>
        )}
      </Container>
    </LoadingComponent>
  );
}
