import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { authGetMultiplesRequest, authPostData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";

// call api xác nhận khôi phục thi công

export const handleKhoiPhucTaiChinh = (
  setLoading,
  handleCloseModal,
  setFetchDataTable,
  hosoId
) => {
  const data = {
    hosoId,
  };
  authPostData({
    url: Endpoint.KHOI_PHUC_TAI_CHINH_CAP_DIEN,
    setLoading,
    payload: data,
    method: METHOD_PUT,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModal();
      }
    },
  });
};

export const handleXemLaiPhuongAn = (
  detail,
  setDataPAXemLai,
  setVisibleXemLaiPhuongAnCapDien,
  visibleXemLaiPhuongAnCapDien
) => {
  const endpoints = [
    `${Endpoint.KS_GET_PHUONG_AN_CAP_DIEN}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    `${Endpoint.KS_TONG_CONG_SUAT}?HoSoId=${detail?.hoSoId ?? detail?.id}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading: () => false,
    onSuccess: (res) => {
      if (res[0]?.data !== null && res[1]?.data !== null) {
        setDataPAXemLai({ ...res[0].data.data, ...res[1].data.data });
        setVisibleXemLaiPhuongAnCapDien(!visibleXemLaiPhuongAnCapDien);
      }
    },
  });
};
