import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APIKhaoSat = {
  KS_GET_THONG_TIN_CO_BAN: `${BASE_API_URL_CAP_DIEN}/khao-sat/thong-tin-co-ban/get`,
  KS_SAVE_THONG_TIN_CO_BAN: `${BASE_API_URL_CAP_DIEN}/khao-sat/thong-tin-co-ban/save`,
  KS_LIST_TY_LE_GIA_BAN_DIEN: `${BASE_API_URL_CAP_DIEN}/khao-sat/ti-le-gia-ban-dien/list`,
  KS_DETAIL_CONG_SUAT: `${BASE_API_URL_CAP_DIEN}/khao-sat/cong-suat/get`,
  KS_GET_THIET_BI_DIEN: `${BASE_API_URL_CAP_DIEN}/khao-sat/thiet-bi-dien/get`,
  KS_UPDATE_THIET_BI_DIEN: `${BASE_API_URL_CAP_DIEN}/khao-sat/thiet-bi-dien/update`,
  KS_CREATE_THIET_BI_DIEN: `${BASE_API_URL_CAP_DIEN}/khao-sat/thiet-bi-dien/create`,
  KS_REMOVE_THIET_BI_DIEN: `${BASE_API_URL_CAP_DIEN}/khao-sat/thiet-bi-dien/delete`,
  KS_GET_TY_LE_GIA_BAN_DIEN: `${BASE_API_URL_CAP_DIEN}/khao-sat/ti-le-gia-ban-dien/get`,
  KS_UPDATE_TY_LE_GIA_BAN_DIEN: `${BASE_API_URL_CAP_DIEN}/khao-sat/ti-le-gia-ban-dien/update`,
  KS_CREATE_TY_LE_GIA_BAN_DIEN: `${BASE_API_URL_CAP_DIEN}/khao-sat/ti-le-gia-ban-dien/create`,
  KS_REMOVE_TY_LE_GIA_BAN_DIEN: `${BASE_API_URL_CAP_DIEN}/khao-sat/ti-le-gia-ban-dien/delete`,
  KS_GET_PHUONG_AN_CAP_DIEN: `${BASE_API_URL_CAP_DIEN}/khao-sat/phuong-an-cap-dien/get`,
  KS_SAVE_PHUONG_AN_CAP_DIEN: `${BASE_API_URL_CAP_DIEN}/khao-sat/phuong-an-cap-dien/save`,
  UPLOAD_ANH_HIEN_TRUONG: `${BASE_API_URL_CAP_DIEN}/khao-sat/upload-anh-hien-truong`,
  LIST_ANH_HIEN_TRUONG: `${BASE_API_URL_CAP_DIEN}/khao-sat/list-anh-hien-truong`,
  REMOVE_ANH_HIEN_TRUONG: `${BASE_API_URL_CAP_DIEN}/khao-sat/delete-anh-hien-truong`,
  SYNC_ANH_HIEN_TRUONG : `${BASE_API_URL_CAP_DIEN}/khao-sat/sync-anh-hien-truong`,
  CHECK_SHOW_IMAGE: `${BASE_API_URL_CAP_DIEN}/khao-sat/check-show-image`,

  KS_LIST_VAT_TU_CONG_TY: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-cong-ty/list`,
  KS_GET_VAT_TU_CONG_TY: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-cong-ty/get`,
  KS_DELETE_VAT_TU_CONG_TY: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-cong-ty/delete`,
  KS_UPDATE_VAT_TU_CONG_TY: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-cong-ty/update`,
  KS_CREATE_VAT_TU_CONG_TY: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-cong-ty/create`,

  KS_LIST_VAT_TU_CONG_TY_THU_HOI: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-cong-ty-thu-hoi/list`,
  KS_GET_VAT_TU_CONG_TY_THU_HOI: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-cong-ty-thu-hoi/get`,
  KS_DELETE_VAT_TU_CONG_TY_THU_HOI: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-cong-ty-thu-hoi/delete`,
  KS_UPDATE_VAT_TU_CONG_TY_THU_HOI: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-cong-ty-thu-hoi/update`,
  KS_CREATE_VAT_TU_CONG_TY_THU_HOI: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-cong-ty-thu-hoi/create`,

  KS_GET_THONG_TIN_HO_SO: `${BASE_API_URL_CAP_DIEN}/khao-sat/thong-tin-ho-so/get`,
  KS_SAVE_THONG_TIN_HO_SO: `${BASE_API_URL_CAP_DIEN}/khao-sat/thong-tin-ho-so/save`,

  KS_LIST_VAT_TU_KHACH_HANG: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-khach-hang/list`,

  KS_CREATE_VAT_TU_KHACH_HANG_DU_TOAN_CO_SAN: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-khach-hang-du-toan-co-san/create`,
  KS_UPDATE_VAT_TU_KHACH_HANG_DU_TOAN_CO_SAN: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-khach-hang-du-toan-co-san/update`,
  KS_DELETE_VAT_TU_KHACH_HANG_DU_TOAN_CO_SAN: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-khach-hang-du-toan-co-san/delete`,
  KS_GET_VAT_TU_KHACH_HANG_DU_TOAN_CO_SAN: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-khach-hang-du-toan-co-san/get`,

  KS_CREATE_VAT_TU_KHACH_HANG_DU_TOAN_MUA_NGOAI: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-khach-hang-du-toan-mua-ngoai/create`,
  KS_UPDATE_VAT_TU_KHACH_HANG_DU_TOAN_MUA_NGOAI: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-khach-hang-du-toan-mua-ngoai/update`,
  KS_DELETE_VAT_TU_KHACH_HANG_DU_TOAN_MUA_NGOAI: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-khach-hang-du-toan-mua-ngoai/delete`,
  KS_GET_VAT_TU_KHACH_HANG_DU_TOAN_MUA_NGOAI: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-khach-hang-du-toan-mua-ngoai/get`,

  KS_CREATE_VAT_TU_KHACH_HANG_KHAI_GIA_CO_SAN: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-khach-hang-khai-gia-co-san/create`,
  KS_UPDATE_VAT_TU_KHACH_HANG_KHAI_GIA_CO_SAN: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-khach-hang-khai-gia-co-san/update`,
  KS_DELETE_VAT_TU_KHACH_HANG_KHAI_GIA_CO_SAN: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-khach-hang-khai-gia-co-san/delete`,
  KS_GET_VAT_TU_KHACH_HANG_KHAI_GIA_CO_SAN: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-khach-hang-khai-gia-co-san/get`,

  KS_CREATE_VAT_TU_KHACH_HANG_KHAI_GIA_MUA_NGOAI: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-khach-hang-khai-gia-mua-ngoai/create`,
  KS_UPDATE_VAT_TU_KHACH_HANG_KHAI_GIA_MUA_NGOAI: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-khach-hang-khai-gia-mua-ngoai/update`,
  KS_DELETE_VAT_TU_KHACH_HANG_KHAI_GIA_MUA_NGOAI: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-khach-hang-khai-gia-mua-ngoai/delete`,
  KS_GET_VAT_TU_KHACH_HANG_KHAI_GIA_MUA_NGOAI: `${BASE_API_URL_CAP_DIEN}/khao-sat/vat-tu-khach-hang-khai-gia-mua-ngoai/get`,

  KS_GET_CHI_PHI_HINH_THUC_LAP_DAT: `${BASE_API_URL_CAP_DIEN}/khao-sat/chi-phi-hinh-thuc-lap-dat`,
  KS_GET_CHI_PHI_LOAI_DU_TOAN: `${BASE_API_URL_CAP_DIEN}/khao-sat/chi-phi-loai-du-toan`,

  KS_IN_BIEN_BAN_KHAI_GIA_EXCEL: `${BASE_API_URL_CAP_DIEN}/khao-sat/in-bien-ban-excel`,
  KS_IN_BIEN_BAN_KHAI_GIA_PDF: `${BASE_API_URL_CAP_DIEN}/khao-sat/in-bien-ban-pdf`,

  GET_NGAY_HEN_TIEP_THEO: `${BASE_API_URL_CAP_DIEN}/xu-ly-ho-so/get-ngay-hen-thi-cong`,
  KS_LIST_DAY_DAN_DIEN : `${BASE_API_URL_CAP_DIEN}/khao-sat/list-day-dan-dien`,
  KS_TONG_CONG_SUAT: `${BASE_API_URL_CAP_DIEN}/khao-sat/tong-cong-suat`,
  KS_EXPORT_EXCEL_BIEU_DO_PT: `${BASE_API_URL_CAP_DIEN}/khao-sat/export-excel-bieu-do-phu-tai`,
};
