import styled from "styled-components";

export const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
  .table-component-panel .ant-collapse-content.ant-collapse-content-active {
    min-height: 500px;
  }
`;

export const ContainerForm = styled.div`
  margin-top: 2px;
  padding: 0px 30px;
`;

export const ContainerConfirm = styled.div`
  padding: 0 8px;
`;
