import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import { Select, Button, Col, Divider, Form, Row, Spin } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { handleDownload, handleOpenPdf, selectBCTHE } from "./service";

export default function BaoCaoEmailCSKH() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [screen] = useState(selectBCTHE[0].value);
  const user = useSelector(authSelector);
  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Báo cáo email CSKH
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              initialValues={{ reportType: screen, unitId: user.user.unitCode }}
              colon={false}
            >
              <Form.Item
                name="reportType"
                label="Báo cáo"
                labelAlign="left"
                required
              >
                <Select
                  options={selectBCTHE}
                  formKey="reportType"
                  form={form}
                  placeholder="Tất cả"
                />
              </Form.Item>

              <Form.Item
                name="unitId"
                label="Tên đơn vị"
                labelAlign="left"
                required
              >
                <Selection
                  url={Endpoint.GET_TEAM_LIST_CODE}
                  formKey="unitId"
                  form={form}
                />
              </Form.Item>

              <Form.Item
                name="TuNgay"
                label="Ngày bắt đầu"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="date"
                  formKey="TuNgay"
                  form={form}
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>

              <Form.Item
                name="DenNgay"
                label="Ngày kết thúc"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="date"
                  formKey="DenNgay"
                  form={form}
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>

              <Row>
                <Col span={4}></Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>

                  {/* <Button
                    type="primary"
                    loading={loading}
                    className="ml-20px"
                    onClick={() => {
                      handleOpenPdf(form.getFieldValue(), setLoading);
                    }}
                  >
                    In báo cáo
                  </Button> */}
                </Col>
              </Row>
            </FormComponent>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
