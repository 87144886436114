import React, { useState } from "react";
import { authPostData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import FormComponent from "@components/Form";
import ButtonComponent from "@components/Button";
import { Row, Col, Input, Form, Button, Spin } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { STATUSCODE_200 } from "@utils/constants";
import { getErrorForm } from "@utils/function";
export default function ChangePasswordForm({ userId }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    authPostData({
      url: `${Endpoint.USER_CHANGE_PASSWORD}`,
      method: "PUT",
      payload: {
        ...values,
        id: userId,
      },
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          form.resetFields();
        } else {
          getErrorForm(res, form);
        }
      },
    });
  };
  return (
    <Spin spinning={loading}>
      <Row justify="center">
        <Col span={12}>
          <FormComponent
            form={form}
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item name="newPassWord" label="Mật khẩu mới">
              <Input.Password
                size="large"
                prefix={<LockOutlined style={{ color: "#279BEF" }} />}
              />
            </Form.Item>
            <Form.Item name="confirmNewPassWord" label="Xác nhận mật khẩu mới">
              <Input.Password
                size="large"
                prefix={<LockOutlined style={{ color: "#279BEF" }} />}
              />
            </Form.Item>
            <ButtonComponent
              type="primary"
              htmlType="submit"
              size="large"
              className="full"
              disabled={loading}
            >
              Cập nhật
            </ButtonComponent>
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              className="full"
              disabled={loading}
            ></Button>
          </FormComponent>
        </Col>
      </Row>
    </Spin>
  );
}
