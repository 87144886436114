import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData } from "@utils/request";

export const getSoLuongDiemDo = (
  filterConditions,
  setLoading,
  setDataSoLuong
) => {
  authGetData({
    url: `${Endpoint.SO_LUONG_DIEM_DO}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataSoLuong(res.data);
      }
    },
  });
};

export const getDanhSachDiemDo = (
  result,
  setLoading,
  setDataDanhSach,
  setIndexPage,
  pageSize
) => {
  const queryString = buildQueryString(
    parseParams({
      SearchTerm: result,
      PageSize: pageSize,
    })
  );
  authGetData({
    url: `${Endpoint.DANH_SACH_DIEM_DO}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataDanhSach(res.data);
        setIndexPage(res.paging);
      }
    },
  });
};

export const getDanhSachDiemDoDVK = (
  resultDVK,
  setLoading,
  setDataDVK,
  setIndexPage,
  pageSize
) => {
  const queryString = buildQueryString(
    parseParams({
      SearchTerm: resultDVK,
      PageSize: pageSize,
    })
  );
  authGetData({
    url: `${Endpoint.DANH_SACH_DIEM_DO_DVK}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      setDataDVK(res.data);
      setIndexPage(res.paging);
    },
  });
};

export const getDanhSachDataNgungSD = (
  resultStop,
  setLoading,
  setDataUseStop,
  setIndexPage,
  pageSize
) => {
  const queryString = buildQueryString(
    parseParams({
      SearchTerm: resultStop,
      pageSize: pageSize,
    })
  );
  authGetData({
    url: `${Endpoint.DANH_SACH_DIEM_DO_KHONG_HD}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      setDataUseStop(res.data);
      setIndexPage(res.paging);
    },
  });
};
