/* eslint-disable no-unused-vars */
import FormComponent from "@components/Form";
import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  TRANGTHAI_SANLUONG_DV,
  TRANGTHAI_SANLUONG_TCT,
} from "@utils/constants";
import { handlePagination, numberWithSpaces } from "@utils/function";
import { Alert, Button, Col, DatePicker, Form, Row, Table, Tag } from "antd";
import locale from "antd/es/date-picker/locale/vi_VN";
import moment from "moment";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ContainerForm } from "./css-styled";
import ConfirmModal from "./modal-confirm";
import XacNhanModal from "./modal-confirm/modal-xacnhan";
import {
  getListDiemDo,
  getListDiemDoChuaNhapCS,
  getListSLDonVi,
  getListSLTCT,
  getThangLamViec,
  handleChotSL,
  handleKTDKHuyChotSL,
  handleKTDKHuyTinhSL,
  handleKTDKXacNhan,
  handleTinhSL,
} from "./services";
import TableDiemDo from "./table";

import EllipsisTooltip from "@components/EllipsisTooltip";
import ModalDanhSachDiemDoChuaNhap from "../modal/modal-danh-sach-don-vi-chua-nhap";

const CHOT_SANLUONG = 2
export default function Index() {
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [dataDonViTong, setDataDonViTong] = useState([]);
  const [dataDonVi, setDataDonVi] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [fetchListDV, setFetchListDV] = useState(false);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    donViIds: user.unitId,
  });
  const [filterConditionsTCT, setFilterConditionsTCT] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [thangLamViec, setThangLamViec] = useState("");
  const [dateMM, setdateMM] = useState("");
  const [dateYYYY, setdateYYYY] = useState("");
  const [thangLamViecNew, setThangLamViecNew] = useState("");
  const [fetchList, setFetchList] = useState(false);
  const [dataDiemDo, setDataDiemDo] = useState([]);
  const [dataDVGiaoNhan, setDataDVGiaoNhan] = useState([]);
  const [fetchListDiemDo, setFetchListDiemDo] = useState(false);
  const [trangThaiDonVi, setTrangThaiDonVi] = useState(false);
  const [messKTDK, setMessKTDK] = useState("");
  const [visibleModalKTDK, setVisibleModalKTDK] = useState(false);
  const [visibleXacNhan, setVisibleXacNhan] = useState(false);
  const arrDaChot = new Set([]);
  const [isDaChot, setIsDaChot] = useState(true);
  const [isDateNhoHon, setIsDateNhoHon] = useState(false);
  const [checkTaoBangKe, setCheckTaoBangKe] = useState("");
  const [noidungPopUp, setNoidungPopUp] = useState("");
  const [isDateChotNhoHon, setIsDateChotNhoHon] = useState(false);

  const [visibleDiemDoChuaNhap, setVisibleDiemDoChuaNhap] = useState(false);
  const [dataTable, setDaTaTable] = useState([]);

  const columnsTableDonVi = [
    {
      title: "Đơn vị",
      dataIndex: "donViGiaoNhan",
      key: "donViGiaoNhan",
      with: "25%",
      render: (text, record) => <div className="table-text-left">{text}</div>,
    },
    {
      title: "Trạng thái của đơn vị",
      dataIndex: "trangThaiDonVi",
      key: "trangThaiDonVi",
      width: "20%",
      render: (text) => (
        <div className="table-text-center">
          {text ? (
            <Tag
              color={
                text === TRANGTHAI_SANLUONG_DV.CHUA_TINH_NUM
                  ? "rgb(55 188 188)"
                  : text === TRANGTHAI_SANLUONG_DV.DA_TINH_NUM
                  ? "#d5a6bd"
                  : text === TRANGTHAI_SANLUONG_DV.DA_XACNHAN_NUM
                  ? "rgb(63 172 63)"
                  : ""
              }
            >
              {text === TRANGTHAI_SANLUONG_DV.CHUA_TINH_NUM
                ? TRANGTHAI_SANLUONG_DV.CHUA_TINH
                : text === TRANGTHAI_SANLUONG_DV.DA_TINH_NUM
                ? TRANGTHAI_SANLUONG_DV.DA_TINH
                : text === TRANGTHAI_SANLUONG_DV.DA_XACNHAN_NUM
                ? TRANGTHAI_SANLUONG_DV.DA_XACNHAN
                : ""}
            </Tag>
          ) : null}
        </div>
      ),
    },
    {
      title: "Ngày đơn vị xác nhận",
      dataIndex: "ngayDonViXacNhan",
      key: "ngayDonViXacNhan",
      width: "20%",
      render: (text) => (
        <div
          style={{ color: isDateChotNhoHon === true ? "red" : "black"}}
          className="table-text-center"
        >
          {text ? moment(text).format("DD/MM/YYYY HH:mm") : ""}
        </div>
      ),
    },

    {
      title: "Trạng thái của TCT",
      dataIndex: "trangThaiTongCongTy",
      key: "trangThaiTongCongTy",
      width: "20%",
      render: (text) => (
        <div className="table-text-center">
          {text ? (
            <Tag
              color={
                text === TRANGTHAI_SANLUONG_TCT.CHUA_CHOT_NUM
                  ? "rgb(55 188 188)"
                  : text === TRANGTHAI_SANLUONG_TCT.DA_CHOT_NUM
                  ? "#d5a6bd"
                  : ""
              }
            >
              {text === TRANGTHAI_SANLUONG_TCT.CHUA_CHOT_NUM
                ? TRANGTHAI_SANLUONG_TCT.CHUA_CHOT
                : text === TRANGTHAI_SANLUONG_TCT.DA_CHOT_NUM
                ? TRANGTHAI_SANLUONG_TCT.DA_CHOT
                : ""}
            </Tag>
          ) : null}
        </div>
      ),
    },
    {
      title: "Ngày TCT chốt",
      dataIndex: "ngayTCTChot",
      key: "ngayTCTChot",
      width: "15%",
      render: (text) => (
        <div className="table-text-center">
          {" "}
          {text ? moment(text).format("DD/MM/YYYY HH:mm") : ""}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (dataDonVi[0]?.trangThaiDonVi === 2) {
      setTrangThaiDonVi(true);
    }
    if (dataDonVi[0]?.trangThaiDonVi === 1) {
      setTrangThaiDonVi(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDonVi[0]?.trangThaiDonVi]);
  useEffect(() => {
    const ngayNew = new Date(moment(dataDonVi[0]?.ngayTCTChot));
    const ngayOld = new Date(moment(dataDonVi[0]?.ngayDonViXacNhan));
    if (
      dataDonVi[0]?.ngayTCTChot !== null &&
      dataDonVi[0]?.ngayDonViXacNhan !== null &&
      ngayNew > ngayOld
    ) {
      setIsDateChotNhoHon(true);
    } else {
      setIsDateChotNhoHon(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDonVi[0]?.ngayDonViXacNhan, dataDonVi[0]?.ngayTCTChot]);

  const columnsTableDonViGN = [
    user.isTongCongTy
      ? {}
      : {
          title: "STT",
          dataIndex: "stt",
          key: "index",
          render: (item, record, index) => (
            <div className="table-text-center">{index + 1}</div>
          ),
          width: 70,
          sorter: false,
        },
    {
      title: "Đơn vị",
      dataIndex: "donViGiaoNhan",
      key: "donViGiaoNhan",
      with: "25%",
      render: (text, record) => <div className="table-text-left">{text}</div>,
    },
    {
      title: "Trạng thái của đơn vị",
      dataIndex: "trangThaiDonVi",
      key: "trangThaiDonVi",
      width: "20%",
      render: (text) => (
        <div className="table-text-center">
          {text ? (
            <Tag
              color={
                text === TRANGTHAI_SANLUONG_DV.CHUA_TINH_NUM
                  ? "rgb(55 188 188)"
                  : text === TRANGTHAI_SANLUONG_DV.DA_TINH_NUM
                  ? "#d5a6bd"
                  : text === TRANGTHAI_SANLUONG_DV.DA_XACNHAN_NUM
                  ? "rgb(63 172 63)"
                  : ""
              }
            >
              {text === TRANGTHAI_SANLUONG_DV.CHUA_TINH_NUM
                ? TRANGTHAI_SANLUONG_DV.CHUA_TINH
                : text === TRANGTHAI_SANLUONG_DV.DA_TINH_NUM
                ? TRANGTHAI_SANLUONG_DV.DA_TINH
                : text === TRANGTHAI_SANLUONG_DV.DA_XACNHAN_NUM
                ? TRANGTHAI_SANLUONG_DV.DA_XACNHAN
                : ""}
            </Tag>
          ) : null}
        </div>
      ),
    },
    {
      title: "Ngày đơn vị xác nhận",
      dataIndex: "ngayDonViXacNhan",
      key: "ngayDonViXacNhan",
      width: "20%",
      render: (text) => (
        <div
          style={{ color: isDateChotNhoHon === true ? "red"  : "black" }}
          className="table-text-center"
        >
          {text ? moment(text).format("DD/MM/YYYY HH:mm") : ""}
        </div>
      ),
    },
    {
      title: "Trạng thái của TCT",
      dataIndex: "trangThaiTongCongTy",
      key: "trangThaiTongCongTy",
      width: "20%",
      render: (text) => (
        <div className="table-text-center">
          {text ? (
            <Tag
              color={
                text === TRANGTHAI_SANLUONG_TCT.CHUA_CHOT_NUM
                  ? "rgb(55 188 188)"
                  : text === TRANGTHAI_SANLUONG_TCT.DA_CHOT_NUM
                  ? "#d5a6bd"
                  : ""
              }
            >
              {text === TRANGTHAI_SANLUONG_TCT.CHUA_CHOT_NUM
                ? TRANGTHAI_SANLUONG_TCT.CHUA_CHOT
                : text === TRANGTHAI_SANLUONG_TCT.DA_CHOT_NUM
                ? TRANGTHAI_SANLUONG_TCT.DA_CHOT
                : ""}
            </Tag>
          ) : null}
        </div>
      ),
    },
    {
      title: "Ngày TCT chốt",
      dataIndex: "ngayTCTChot",
      key: "ngayTCTChot",
      width: "15%",
      render: (text) => (
        <div
          className="table-text-center"
        >
          {text ? moment(text).format("DD/MM/YYYY HH:mm") : ""}
        </div>
      ),
    },
  ];

  const columnsTableDiemDo = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      with: "10%",
      render: (text) => (
        <div
          className="table-text-left"
          style={{ fontSize: window.innerWidth > 1366 ? "16px" : "14px" }}
        >
          <EllipsisTooltip title={text}>{text}</EllipsisTooltip>
        </div>
      ),
    },
    {
      title: "Mô tả điểm đo",
      dataIndex: "motaDiemDo",
      key: "motaDiemDo",
      width: "11%",
      render: (text) => (
        <div
          className="table-text-left"
          style={{ fontSize: window.innerWidth > 1366 ? "16px" : "14px" }}
        >
          <EllipsisTooltip title={text}>{text}</EllipsisTooltip>
        </div>
      ),
    },
    {
      title: "Chú thích",
      dataIndex: "chuThich",
      key: "chuThich",
      width: "12%",
      render: (text) => (
        <div
          className="table-text-left"
          style={{ fontSize: window.innerWidth > 1366 ? "16px" : "14px" }}
        >
          <EllipsisTooltip title={text}>{text}</EllipsisTooltip>
        </div>
      ),
    },
    {
      title: "Bình thường",
      dataIndex: "binhThuong",
      key: "binhThuong",
      width: "11%",
      render: (text) => (
        <div
          className="table-text-right"
          style={{ fontSize: window.innerWidth > 1366 ? "16px" : "14px" }}
        >
          {numberWithSpaces(text)}
        </div>
      ),
    },
    {
      title: "Cao điểm",
      dataIndex: "caoDiem",
      key: "caoDiem",
      width: "11%",
      render: (text) => (
        <div
          className="table-text-right"
          style={{ fontSize: window.innerWidth > 1366 ? "16px" : "14px" }}
        >
          {numberWithSpaces(text)}
        </div>
      ),
    },
    {
      title: "Thấp điểm",
      dataIndex: "thapDiem",
      key: "thapDiem",
      width: "11%",
      render: (text) => (
        <div
          className="table-text-right"
          style={{ fontSize: window.innerWidth > 1366 ? "16px" : "14px" }}
        >
          {numberWithSpaces(text)}
        </div>
      ),
    },
    {
      title: "Vô công",
      dataIndex: "voCong",
      key: "voCong",
      width: "11%",
      render: (text) => (
        <div
          className="table-text-right"
          style={{ fontSize: window.innerWidth > 1366 ? "16px" : "14px" }}
        >
          {numberWithSpaces(text)}
        </div>
      ),
    },
    {
      title: "Tổng",
      dataIndex: "tong",
      key: "tong",
      width: "11%",
      render: (text) => (
        <div
          className="table-text-right"
          style={{ fontSize: window.innerWidth > 1366 ? "16px" : "14px" }}
        >
          {numberWithSpaces(text)}
        </div>
      ),
    },
    {
      title: "Đơn vị giao nhận",
      dataIndex: "donViGiaoNhan",
      key: "donViGiaoNhan",
      render: (text) => (
        <div
          className="table-text-left"
          style={{ fontSize: window.innerWidth > 1366 ? "16px" : "14px" }}
        >
          <EllipsisTooltip title={text}>{text}</EllipsisTooltip>
        </div>
      ),
    },
  ];

  // hàm phân trang

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(
      paging,
      sorter,
      user.isTongCongTy ? setFilterConditionsTCT : setFilterConditions
    );
  };

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    if (selectedRowKeys && selectedRowKeys.length > 0) {
      dataDonViTong.find((item) => {
        selectedRowKeys.map((e) => {
          if (item.donViId === e) {
            if (item.trangThaiTongCongTy === 1) {
              return arrDaChot.add(1);
            }
            arrDaChot.add(2);
          }
          return null;
        });
        return null;
      });
    }
    arrDaChot.delete();
    if (selectedRowKeys.length > 0 && arrDaChot.has(1)) {
      setIsDaChot(true);
    } else if (selectedRowKeys.length > 0 && !arrDaChot.has(1)) {
      setIsDaChot(false);
    } else if (selectedRowKeys.length <= 0) {
      setIsDaChot(true);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  // gọi api list đơn vị khi thay đổi tháng

  const handleChangeThangLV = (value, dateString) => {
    const ngayNew = new Date(moment(value));
    const ngayOld = new Date(`${thangLamViec} "00:00:00 GMT+0700"`);
    if (ngayNew >= ngayOld) {
      setIsDateNhoHon(false);
    } else {
      setIsDateNhoHon(true);
    }
    setThangLamViecNew(dateString);
    const thang = dateString.split("/")[0];
    const nam = dateString.split("/")[1];
    setdateYYYY(nam);
    setdateMM(thang);
    if (user.isTongCongTy) {
      setSelectedRowKeys([]);
      getListSLTCT(setDataDonViTong, setFetchListDV, setLoading, thang, nam);
    } else {
      const dataDD = {
        thang: thang,
        nam: nam,
        donViIds: user.unitId,
        pageIndex: DEFAULT_PAGEINDEX,
        pageSize: DEFAULT_PAGESIZE,
      };
      getListDiemDo(setDataDiemDo, setLoading, setTotalCount, dataDD);
      getListSLDonVi(
        setDataDonVi,
        setDataDVGiaoNhan,
        setFetchList,
        setLoading,
        thang,
        nam,
        user.unitId
      );
    }
  };

  const handleTinhSanLuong = () => {
    if (user.isTongCongTy) {
      if (thangLamViecNew !== "") {
        const thang = thangLamViecNew.split("/")[0];
        const nam = thangLamViecNew.split("/")[1];
        const data = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViIds: selectedRowKeys,
        };
        const dataDD = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViIds:
            selectedRowKeys && selectedRowKeys.length > 0
              ? selectedRowKeys.toString()
              : "",
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
        };
        handleChotSL(
          setFetchListDiemDo,
          setLoadingButton,
          data,
          setSelectedRowKeys,
          setIsDaChot
        );
        setFilterConditionsTCT(dataDD);
      } else {
        const thang = thangLamViec.split("-")[1];
        const nam = thangLamViec.split("-")[0];
        const data = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViIds: selectedRowKeys,
        };
        const dataDD = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViIds:
            selectedRowKeys && selectedRowKeys.length > 0
              ? selectedRowKeys.toString()
              : "",
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
        };
        handleChotSL(
          setFetchListDiemDo,
          setLoadingButton,
          data,
          setSelectedRowKeys,
          setIsDaChot
        );
        setFilterConditionsTCT(dataDD);
      }
    } else {
      if (thangLamViecNew !== "") {
        const thang = thangLamViecNew.split("/")[0];
        const nam = thangLamViecNew.split("/")[1];
        const data = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViId: user.unitId,
        };
        const dataDD = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViIds: user.unitId,
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
        };
        handleTinhSL(setFetchListDiemDo, setLoadingButton, data);
        setFilterConditions(dataDD);
      } else {
        const thang = thangLamViec.split("-")[1];
        const nam = thangLamViec.split("-")[0];
        const data = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViId: user.unitId,
        };
        const dataDD = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViIds: user.unitId,
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
        };
        handleTinhSL(setFetchListDiemDo, setLoadingButton, data);
        setFilterConditions(dataDD);
      }
    }
  };

  const handleHuyTinhSanLuong = () => {
    if (user.isTongCongTy) {
      if (thangLamViecNew !== "") {
        const thang = thangLamViecNew.split("/")[0];
        const nam = thangLamViecNew.split("/")[1];
        const data = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViIds: selectedRowKeys,
        };
        const dataDD = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViIds:
            selectedRowKeys && selectedRowKeys.length > 0
              ? selectedRowKeys.toString()
              : "",
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
        };
        setFilterConditionsTCT(dataDD);
        handleKTDKHuyChotSL(
          setFetchListDiemDo,
          setLoading,
          data,
          setMessKTDK,
          setVisibleModalKTDK,
          setSelectedRowKeys,
          setIsDaChot
        );
      } else {
        const thang = thangLamViec.split("-")[1];
        const nam = thangLamViec.split("-")[0];
        const data = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViIds: selectedRowKeys,
        };
        const dataDD = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViIds:
            selectedRowKeys && selectedRowKeys.length > 0
              ? selectedRowKeys.toString()
              : "",
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
        };
        setFilterConditionsTCT(dataDD);
        handleKTDKHuyChotSL(
          setFetchListDiemDo,
          setLoading,
          data,
          setMessKTDK,
          setVisibleModalKTDK,
          setSelectedRowKeys,
          setIsDaChot
        );
      }
    } else {
      if (thangLamViecNew !== "") {
        const thang = thangLamViecNew.split("/")[0];
        const nam = thangLamViecNew.split("/")[1];
        const data = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViId: user.unitId,
        };
        const dataDD = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViIds: user.unitId,
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
        };
        setFilterConditions(dataDD);
        handleKTDKHuyTinhSL(
          setFetchListDiemDo,
          setLoading,
          data,
          setMessKTDK,
          setVisibleModalKTDK
        );
      } else {
        const thang = thangLamViec.split("-")[1];
        const nam = thangLamViec.split("-")[0];
        const data = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViId: user.unitId,
        };
        const dataDD = {
          thang: parseInt(thang),
          nam: parseInt(nam),
          donViIds: user.unitId,
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
        };
        setFilterConditions(dataDD);
        handleKTDKHuyTinhSL(
          setFetchListDiemDo,
          setLoading,
          data,
          setMessKTDK,
          setVisibleModalKTDK
        );
      }
    }
  };

  useEffect(() => {
    getThangLamViec(setThangLamViec, setLoading, setdateYYYY, setdateMM);
  }, []);

  useEffect(() => {
    if (thangLamViec !== "") {
      setThangLamViecNew("");
      form.setFieldsValue({
        thangNam: moment(thangLamViec),
      });
    }
  }, [form, thangLamViec]);
  // call api list đơn vị của table

  useEffect(() => {
    if (thangLamViec !== "") {
      const thang = thangLamViec?.split("-")[1];
      const nam = thangLamViec?.split("-")[0];
      if (user.isTongCongTy) {
        getListSLTCT(setDataDonViTong, setFetchListDV, setLoading, thang, nam);
      } else {
        getListSLDonVi(
          setDataDonVi,
          setDataDVGiaoNhan,
          setFetchList,
          setLoading,
          thang,
          nam,
          user.unitId
          // setDisableMessError
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thangLamViec]);

  useEffect(() => {
    if (thangLamViec !== "") {
      const thang = thangLamViec?.split("-")[1];
      const nam = thangLamViec?.split("-")[0];
      setFilterConditions({
        ...filterConditions,
        thang: thang,
        nam: nam,
      });
      setFetchListDiemDo(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thangLamViec]);

  useEffect(() => {
    if (fetchListDiemDo && !user.isTongCongTy) {
      getListDiemDo(setDataDiemDo, setLoading, setTotalCount, filterConditions);
      getListSLDonVi(
        setDataDonVi,
        setDataDVGiaoNhan,
        setFetchList,
        setLoading,
        filterConditions.thang,
        filterConditions.nam,
        user.unitId
        // setDisableMessError
      );
      setFetchListDiemDo(!fetchListDiemDo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchListDiemDo, filterConditions, user.isTongCongTy]);

  useEffect(() => {
    if (
      fetchListDiemDo &&
      filterConditionsTCT &&
      Object.keys(filterConditionsTCT).length > 2 &&
      user.isTongCongTy
    ) {
      getListSLTCT(
        setDataDonViTong,
        setFetchListDV,
        setLoading,
        filterConditionsTCT.thang,
        filterConditionsTCT.nam
      );
      getListDiemDo(
        setDataDiemDo,
        setLoading,
        setTotalCount,
        filterConditionsTCT
      );
      setFetchListDiemDo(!fetchListDiemDo);
    }
  }, [fetchListDiemDo, filterConditionsTCT, user.isTongCongTy]);

  useEffect(() => {
    if (filterConditions.thang && filterConditions.nam) {
      const query = {
        Thang: filterConditions.thang,
        Nam: filterConditions.nam,
        ...(selectedRowKeys && { donViIds: selectedRowKeys }),
      };
      getListDiemDoChuaNhapCS(setDaTaTable, setLoading, query);
    }
  }, [filterConditions.thang, filterConditions.nam, selectedRowKeys]);

  const handleCloseModalConfirm = () => {
    setVisibleModalKTDK(false);
  };
  const handleOpenModalXacNhan = () => {
    const data = {
      thang: dateMM,
      nam: dateYYYY,
      donViId: user?.unitId,
    };
    handleKTDKXacNhan(data, setLoading, setCheckTaoBangKe, setNoidungPopUp);
    setTimeout(() => {
      setVisibleXacNhan(!visibleXacNhan);
    }, 1000);
  };

  const handleCloseModalXacNhan = () => {
    setVisibleXacNhan(false);
  };

  const showModalConfirm = useMemo(
    () =>
      visibleModalKTDK && (
        <ConfirmModal
          visible={visibleModalKTDK}
          handleCloseModal={handleCloseModalConfirm}
          content={messKTDK}
          title={"Thông báo"}
          setFetchList={setFetchListDiemDo}
          user={user}
          thangLamViecNew={thangLamViecNew}
          selectedRowKeys={selectedRowKeys}
          thangLamViec={thangLamViec}
          setFilterConditionsTCT={setFilterConditionsTCT}
          setFilterConditions={setFilterConditions}
          setIsDaChot={setIsDaChot}
          setLoading={setLoading}
          loading={loading}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      ),
    [
      loading,
      messKTDK,
      selectedRowKeys,
      thangLamViec,
      thangLamViecNew,
      user,
      visibleModalKTDK,
    ]
  );
  const showModalXacNhan = useMemo(
    () =>
      visibleXacNhan && (
        <XacNhanModal
          visible={visibleXacNhan}
          handleCloseModal={handleCloseModalXacNhan}
          setVisible={setVisibleXacNhan}
          setCheckTaoBangKe={setCheckTaoBangKe}
          content={
            checkTaoBangKe === false
              ? `Dữ liệu sản lượng giao nhận của tháng làm việc ${
                  thangLamViecNew !== ""
                    ? `
        ${thangLamViecNew.split("/")[0]}/${thangLamViecNew.split("/")[1]}`
                    : `
        ${thangLamViec.split("-")[1]}/${thangLamViec.split("-")[0]}`
                } đã kiểm tra xong ?
        Ấn đồng ý, sẽ thực hiện xác nhận sản lượng và tạo bảng kê.
        `
              : `${noidungPopUp} `
          }
          title={"Xác nhận sản lượng và tạo bảng kê"}
          setFetchListDiemDo={setFetchListDiemDo}
          thangLamViec={thangLamViec}
          thangLamViecNew={thangLamViecNew}
          user={user}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleXacNhan]
  );

  // Check diem do chua nhap chi so
  const handleOpenModalDiemDoChuaNhap = () => {
    if (dataTable.length > 0) {
      setVisibleDiemDoChuaNhap(true);
    } 
    // else {
    //   handleTinhSanLuong();
    // }
    handleTinhSanLuong()
  };

  const handleCloseModalDiemDoChuaNhap = () => {
    setVisibleDiemDoChuaNhap(false);
  };

  const showModalDiemDoChuaNhap = useMemo(
    () =>
      visibleDiemDoChuaNhap && (
        <ModalDanhSachDiemDoChuaNhap
          visible={visibleDiemDoChuaNhap}
          close={handleCloseModalDiemDoChuaNhap}
          loading={loading}
          setLoading={setLoading}
          data={dataTable}
        />
      ),
    [visibleDiemDoChuaNhap, loading, dataTable]
  );
  return (
    <Fragment>
      <ContainerForm>
        {isDateChotNhoHon === true ? (
          <Alert
            style={{ margin: " 10px 0" }}
            message=" Ngày chốt sản lượng của TCT sau ngày tạo bảng kê, hãy kiểm tra lại
           thông tin bảng kê !"
            type="error"
          />
        ) : null}
        <FormComponent
          form={form}
          name="filter-form"
          layout="vertical"
          autoComplete="off"
          style={{ paddingTop: 10 }}
        >
          <Row gutter={24}>
            <Col span={6} md={6} style={{ height: 70 }}>
              <Form.Item name="thangNam" label="Tháng Năm" style={{}}>
                <DatePicker
                  format="MM/YYYY"
                  picker={"month"}
                  locale={locale}
                  form={form}
                  allowClear={false}
                  onChange={handleChangeThangLV}
                  disabledDate={(current) => {
                    const customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current > moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={3} style={{ marginLeft: 20, textAlign: "right" }}>
              {user.isTongCongTy ? (
                <Button
                  disabled={
                    user.isTongCongTy
                      ? !selectedRowKeys.length || isDateNhoHon
                      : (dataDonVi &&
                          dataDonVi.length > 0 &&
                          dataDonVi[0].trangThaiTongCongTy ===
                            TRANGTHAI_SANLUONG_TCT.DA_CHOT_NUM) ||
                        isDateNhoHon
                  }
                  type="primary"
                  key="back"
                  className="button-closed"
                  // onClick={handleTinhSanLuong}
                  onClick={handleOpenModalDiemDoChuaNhap}
                  style={{ marginTop: 30 }}
                  loading={loadingButton}
                >
                  {user.isTongCongTy ? "Chốt sản lượng" : "Tính sản lượng"}
                </Button>
              ) : (
                <Button
                  disabled={
                    isDateNhoHon || dataDonVi[0]?.trangThaiTongCongTy === 2
                  }
                  type="primary"
                  key="back"
                  className="button-closed"
                  onClick={handleOpenModalDiemDoChuaNhap}
                  style={{ marginTop: 30 }}
                  loading={loadingButton}

                >
                  {user.isTongCongTy ? "Chốt sản lượng" : "Tính sản lượng"}
                </Button>
              )}
            </Col>
            <Col span={3} style={{ marginLeft: 20, textAlign: "right" }}>
              {trangThaiDonVi === true && user.isTongCongTy === false ? (
                <Button
                  disabled={isDateNhoHon}
                  type="primary"
                  className="btn-bg-yellow"
                  key="confirm"
                  onClick={handleHuyTinhSanLuong}
                  style={{ marginTop: 30 }}
                >
                  {user.isTongCongTy
                    ? "Hủy chốt sản lượng"
                    : "Hủy tính sản lượng"}
                </Button>
              ) : trangThaiDonVi === true && user.isTongCongTy === true ? (
                <Button
                  disabled
                  type="primary"
                  className="btn-bg-yellow"
                  key="confirm"
                  onClick={handleHuyTinhSanLuong}
                  style={{ marginTop: 30 }}
                >
                  {user.isTongCongTy
                    ? "Hủy chốt sản lượng"
                    : "Hủy tính sản lượng"}
                </Button>
              ) : (
                <Button
                  disabled={
                    user.isTongCongTy
                      ? !selectedRowKeys.length || isDateNhoHon || isDaChot
                      : (dataDonVi &&
                          dataDonVi.length > 0 &&
                          dataDonVi[0].trangThaiTongCongTy ===
                            TRANGTHAI_SANLUONG_TCT.DA_CHOT_NUM) ||
                        isDateNhoHon ||
                        isDateNhoHon ||
                        dataDonVi[0]?.trangThaiDonVi === 1
                  }
                  type="primary"
                  className="btn-bg-yellow"
                  key="confirm"
                  onClick={handleHuyTinhSanLuong}
                  style={{ marginTop: 30 }}
                >
                  {user.isTongCongTy
                    ? "Hủy chốt sản lượng"
                    : "Hủy tính sản lượng"}
                </Button>
              )}
            </Col>
            <Col
              span={6}
              style={{ textAlign: "right", marginTop: 30, marginLeft: 25 }}
            >
              <Button
                type="primary"
                key="back"
                className="button-closed"
                style={{
                  display: user.isTongCongTy ? "none" : "block",
                }}
                onClick={handleOpenModalXacNhan}
                disabled={isDateNhoHon || (dataDonVi[0]?.trangThaiDonVi === 1 && dataDonVi[0].trangThaiTongCongTy === 1)}
              >
                Xác nhận sản lượng và tạo bảng kê
              </Button>
            </Col>
            <Col span={24}>
              <Table
                columns={columnsTableDonVi}
                dataSource={user.isTongCongTy ? dataDonViTong : dataDonVi}
                scroll={{ y: 200 }}
                rowKey={"donViId"}
                rowSelection={user.isTongCongTy ? rowSelection : false}
                pagination={false}
                loading={loading}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      if(record.trangThaiTongCongTy === CHOT_SANLUONG) {
                         setFetchListDiemDo(true);
                        setFilterConditionsTCT(prev => ({
                        ...prev,
                        donViIds: record.donViId,
                        thang: record.thang,
                        nam: record.nam
                      }))
                      }
                      else {
                        setDataDiemDo([])
                      }
                    }, // click row
                  };
                }}
              />
            </Col>
            <Col
              span={24}
              style={{
                marginTop: 30,
                display: user.isTongCongTy ? "none" : "block",
              }}
            >
              Đơn vị quản lý điểm đo có giao nhận với {user.unitName}
            </Col>
            <Col
              span={24}
              style={{ display: user.isTongCongTy ? "none" : "block" }}
            >
              <Table
                columns={columnsTableDonViGN}
                dataSource={dataDVGiaoNhan}
                scroll={{ y: 200 }}
                pagination={false}
              />
            </Col>
          </Row>
        </FormComponent>
      </ContainerForm>
      <TableDiemDo
        columns={columnsTableDiemDo}
        filterConditions={
          user.isTongCongTy ? filterConditionsTCT : filterConditions
        }
        onChangePagination={onChangePagination}
        totalData={totalCount}
        dataSource={dataDiemDo}
        loading={loading}
      />
      {showModalConfirm}
      {showModalXacNhan}
      {showModalDiemDoChuaNhap}
    </Fragment>
  );
}
