import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { customColumn } from "@utils/function";
import { Fragment, useCallback } from "react";
import { Container } from "./css-styled";
import ListFilter from "./list-bo-loc.jsx";

export default function BaoCaoTongHopVTTable(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    onChangePagination,
    loading,
    columns,
    data,
    countTotals,
    user,
    isCompany,
    setType,
  } = props;

  const callBackTable = useCallback(() => {
    if (columns.length > 0) {
      if (!isCompany) columns[1].title = "Tổ đội";
      else columns[1].title = "Đơn vị";
      return (
        <TableComponent
          header={"Báo cáo tổng hợp số lượng vật tư"}
          // totalData={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          // pagination={filterConditions}
          scrollY={500}
          columns={customColumn(columns, filterConditions)}
          dataSource={data}
          summary={(pageData) => countTotals(pageData)}
        />
      );
    }
  }, [
    columns,
    countTotals,
    data,
    filterConditions,
    isCompany,
    loading,
    onChangePagination,
  ]);
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          loading={loading}
          user={user}
          setType={setType}
        />
      </Filter>
      <Container>{callBackTable()}</Container>
    </Fragment>
  );
}
