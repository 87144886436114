import { BASE_API_URL_BAO_CAO } from "@utils/constants";

export const APIBCGhiChiSo = {
  BCGCS_GIAM_SAT_CHI_SO_THEO_NGAY: `${BASE_API_URL_BAO_CAO}/bckd/bcgcs/bcgsgcs/giam-sat-chi-so-theo-ngay`,
  BCGCS_GIAM_SAT_CHI_SO_THEO_THANG: `${BASE_API_URL_BAO_CAO}/bckd/bcgcs/bcgsgcs/giam-sat-chi-so-theo-thang`,
  BCGCS_DS_CHI_TIET_SO_GHI_CHI_SO: `${BASE_API_URL_BAO_CAO}/bckd/bcgcs/bcgsgcs/chi-tiet-so-gcs`,
  BCGCS_DS_CAC_SO_GHI_CHI_SO_DUOC_TAO: `${BASE_API_URL_BAO_CAO}/bckd/bcgcs/bcgsgcs/cac-so-gcs-duoc-tao`,
  BCGCS_TH_SO_GCS_DAY_TU_XML_VE_CMIS: `${BASE_API_URL_BAO_CAO}/bckd/bcgcs/bcgsgcs/so-gcs-day-tu-file-xml-ve-cmis`,
  BCGCS_DS_GCS_XUAT_XML: `${BASE_API_URL_BAO_CAO}/bckd/bcgcs/bcgsgcs/chi-tiet-so-gcs-xuat-cmlkqwgcs`,
};
