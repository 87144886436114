/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import BackgroundImage from "@assets/images/login-background.png";
import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import SuccessForm from "./SuccessForm";
import { useNavigate } from "react-router-dom";
import { authSelector, handleSelectModule } from "@state/auth/reducer";
import { useSelector, useDispatch } from "react-redux";
import routeCapDien from "@routes/route_cap_dien";
import routeQuanTriHeThong from "@routes/route_quan_tri_he_thong";
import routeGhiChiSo from "@routes/route_ghi_chi_so";
import routeTienIchChung from "@routes/route_tien_ich_chung";
import routeBaoCaoKinhDoanh from "@routes/route_bao_cao_kinh_doanh";
import routeChamSocKhachHang from "@routes/route_cham_soc_khach_hang";
import routeKiemTraCongTo from "@routes/route_kttb_do_diem";
import routeTreoThao from "@routes/route_treo_thao";
import routeApGia from "@routes/route_ap_gia";
import routeDangKyThanhToan from "@routes/route_dang_ky_thanh_toan";
import routeTrungAp from "@routes/route_trung_ap";
import routeTichHopCRM from "@routes/route_tich_hop_CRM";
import routeGiaoNhanDienNang from "@routes/route_giao_nhan_dien_nang";
import routeCongToThao from "@routes/route_ktcs_cong_to_thao";
import routeLoTrinhNhanVien from "@routes/route_lo_trinh_nhan_vien";
import { STEP_TWO, STEP_THREE, NAVIGATE_TRANGCHU, NAVIGATE_DEFAULT_PAGE } from "@utils/constants";
import { isEmpty } from "lodash";
const dataRouters = [
  ...routeQuanTriHeThong,
  ...routeCapDien,
  ...routeTienIchChung,
  ...routeBaoCaoKinhDoanh,
  ...routeChamSocKhachHang,
  ...routeGhiChiSo,
  ...routeKiemTraCongTo,
  // Fix bug login
  ...routeTreoThao,
  ...routeApGia,
  ...routeDangKyThanhToan,
  ...routeTrungAp,
  ...routeTichHopCRM,
  ...routeGiaoNhanDienNang,
  ...routeCongToThao,
  ...routeLoTrinhNhanVien,
];
export default function Login() {
  const [step, setStep] = useState(1);
  const { isAuthenticated, user } = useSelector(authSelector);
  const previousAccessLink = localStorage.getItem("previousAccessLink");
  const previousSearchQuery = localStorage.getItem("previousSearchQuery");
  const token = localStorage.getItem("ex1-auth-token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuthenticated && token) {
      if (previousAccessLink && previousAccessLink !== NAVIGATE_TRANGCHU) {
        let routerFind = {};
        const loop = (data, parentPath) => {
          for (let i = 0; i < data.length; i++) {
            if (data[i].path === previousAccessLink || parentPath + data[i].subPath === previousAccessLink) {
              routerFind = data[i];
              return;
            }
            if (!isEmpty(data[i].subItems)) {
              loop(data[i].subItems, parentPath + data[i].path);
            }
          }
        };

        loop(dataRouters, "")

        if (!isEmpty(routerFind)) {
          dispatch(
            handleSelectModule({
              moduleCode: routerFind.permissionCode?.charAt(0),
            })
          );
          navigate(`${previousAccessLink}${previousSearchQuery}`);
          return;
        };
        navigate(NAVIGATE_TRANGCHU);
      } else {
        navigate(NAVIGATE_TRANGCHU);
      }
    }
  }, [previousAccessLink, isAuthenticated, user, previousSearchQuery]);

  const renderStep = useCallback(() => {
    switch (step) {
      case STEP_TWO:
        return <ForgotPasswordForm setStep={setStep} />;
      case STEP_THREE:
        return <SuccessForm setStep={setStep} />;
      default:
        return <LoginForm setStep={setStep} />;
    }
  }, [step]);

  return (
    <Container
      style={{
        backgroundImage: `url(${BackgroundImage})`,
      }}
    >
      <LoginFormContainer className="full">
        <div className="form-wrapper">{renderStep()}</div>
      </LoginFormContainer>
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
  padding: 0px 90px;
  position: relative;
  background-size: cover;

  @media screen and (max-width: 576px) {
    background-size: 200% 100%;
  }
`;
const LoginFormContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;

  .form-wrapper {
    background: #ffffff;
    box-shadow: 0px 12px 22px -6px rgba(0, 21, 71, 0.2), 0px 14px 64px -4px rgba(91, 92, 96, 0.2);
    border-radius: 8px;
    padding: 30px 40px;

    @media screen and (min-width: 768px) {
      min-width: 445px;
      min-height: 560px;
    }

    @media screen and (max-width: 767px) {
      min-width: 300px;
      min-height: 500px;
    }
  }
  @media screen and (max-width: 1024px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
  }
`;
