import { Menu } from "antd";
import { openModal } from "@state/system-config/reducer";
import LapBangQuyetToanKhachHang from "./modal-lap-quyet-toan-khach-hang";
import {
  LITMIT_TRANGTHAI,
  MATRIX_CAP_DIEN,
  SEQUENCE_TRANGTHAI,
} from "@utils/constants";
import CheckStatus from "@components/CheckStatus";
import { cap_dien } from "@permissions/a_capdien";
import CheckPermission from "@components/CheckPermission";
import XemLaiBBLapDatComponent from "@modules/cap-dien/common-ho-so/xem-bien-ban-lap-dat";
const { SubMenu } = Menu;

export default function Index(props) {
  const {
    closeModal,
    handleOpenModalTraLaiQuyetToan,
    handleOpenModalInHoSoCMIS,
    handleOpenModalXemLaiPhuongAnCapDien,
    handleOpenModalQuyetToanKhachHang,
    handleOpenModalQuyetToanCongTy,
    handleOpenModalInBieuKhaiGia,
    inBienBanApGia,
    dispatch,
    t,
    detail,
    setFetchDataTable,
    handleOpenInPhuTai,
  } = props;

  return (
    <Menu mode="vertical" style={{ width: "100%" }} selectedKeys={["0"]}>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.LAPQUYETTOANKH}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.LAPQUYETTOANKH[1]}
        permissionCode={cap_dien.a302_quyet_toan_kh}
      >
        <Menu.Item
          eventKey="1"
          onClick={() =>
            dispatch(
              openModal({
                content: (
                  <LapBangQuyetToanKhachHang
                    closeModal={closeModal}
                    dispatch={dispatch}
                    detail={detail}
                    setFetchDataTable={setFetchDataTable}
                  />
                ),
                size: "large",
                title: "Quyết toán khách hàng",
              })
            )
          }
        >
          Quyết toán khách hàng
        </Menu.Item>
      </CheckStatus>

      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.TRALAIQUYETTOANNOIBO}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.LAPQUYETTOANKH[1]}
        permissionCode={cap_dien.a302_tra_qt_cty}
      >
        <Menu.Item eventKey="2" onClick={handleOpenModalTraLaiQuyetToan}>
          {" "}
          Trả lại quyết toán công ty
        </Menu.Item>
      </CheckStatus>
      <CheckPermission permissionCode={cap_dien.a302_in_hs_cmis}>
        <Menu.Item eventKey="6" onClick={handleOpenModalInHoSoCMIS}>
          {" "}
          {t("common_cap_dien.in_ho_so_cmis")}
        </Menu.Item>
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a302_in_hs_web}>
        {Number(detail.maTrangThai) >= LITMIT_TRANGTHAI ? (
          <SubMenu eventKey="sub1" title={t("common_cap_dien.in_ho_so_web")}>
            <Menu.Item
              eventKey="7"
              onClick={handleOpenModalXemLaiPhuongAnCapDien}
            >
              {" "}
              {t("common_cap_dien.xem_lai_phuong_an_cap_dien")}
            </Menu.Item>
            <XemLaiBBLapDatComponent idHoSo={detail?.id ?? detail?.hoSoId} />
            <Menu.Item eventKey="20" onClick={handleOpenInPhuTai}>
              In biểu đồ phụ tải
            </Menu.Item>
            <Menu.Item eventKey="8" onClick={handleOpenModalQuyetToanKhachHang}>
              {" "}
              {t("common_cap_dien.quyet_toan_khach_hang")}
            </Menu.Item>
            <Menu.Item eventKey="9" onClick={handleOpenModalQuyetToanCongTy}>
              {" "}
              {t("common_cap_dien.quyet_toan_cong_ty")}
            </Menu.Item>
            <Menu.Item eventKey="10" onClick={handleOpenModalInBieuKhaiGia}>
              {" "}
              {t("common_cap_dien.in_bieu_khai_gia")}
            </Menu.Item>
            <Menu.Item eventKey="11" onClick={inBienBanApGia}>
              {" "}
              {t("common_cap_dien.in_bien_ban_ap_gia")}
            </Menu.Item>
          </SubMenu>
        ) : null}
      </CheckPermission>
    </Menu>
  );
}
