import React, { useEffect, useRef } from "react";
import { Button, Col, Row, Select } from "antd";

import LoadingComponent from "@components/Loading";
import FormComponent from "@components/Form";
import { Container, ContainerTable } from "./css-styled";
import TableComponent from "@components/TableComponent";
import { useDispatch } from "react-redux";
import { closeModal } from "@state/system-config/reducer";
import { scrollToTop } from "@utils/function";

const { Option } = Select;

export default function CapNhatCLCongToModal(props) {
  const {
    loading,
    chungLoai,
    handleSubmit,
    form,
    columns,
    dataSource,
    filterAllValue,
    handleSelect,
    choiceValue,
  } = props;

  const dispatch = useDispatch();
  const ref1 = useRef();

  useEffect(() => {
    scrollToTop(ref1);
  }, []);

  return (
    <LoadingComponent loading={loading}>
      <div ref={ref1}>
        <Container>
          <Row gutter={24} style={{ paddingTop: "24px" }}>
            <Col span={24} md={8}>
              <p>Chủng loại mặc định</p>
            </Col>
            <Col span={16} md={12}>
              <Select
                loading={loading}
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={handleSelect}
                style={{ width: "100%" }}
                placeholder="Chọn chủng loại mặc định"
              >
                {chungLoai && chungLoai.length > 0
                  ? chungLoai.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {`${item.maChungLoai} - ${item.loaiCongTo}`}
                      </Option>
                    ))
                  : null}
              </Select>
            </Col>
            <Col span={8} md={4}>
              <Button
                onClick={filterAllValue}
                type="primary"
                disabled={
                  choiceValue === "" ||
                  choiceValue === undefined ||
                  choiceValue === null
                    ? true
                    : false
                }
              >
                Áp dụng cho tất cả yêu cầu
              </Button>
            </Col>
          </Row>
        </Container>
        <FormComponent
          form={form}
          name="form-get"
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{}}
          autoComplete="off"
        >
          <ContainerTable>
            <TableComponent
              dataSource={dataSource}
              columns={columns}
              scrollY={300}
              pagination={false}
              header={"Danh sách yêu cầu cần cập nhật chủng loại"}
              rowKey="yeuCauId"
            />
          </ContainerTable>

          <Row gutter={24} justify="center" style={{ marginTop: "24px" }}>
            <Col span={12} style={{ textAlign: "right" }}>
              <Button
                key="back"
                onClick={() => dispatch(closeModal())}
                className="button-closed"
              >
                Đóng lại
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-get"
              >
                Cập nhật
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </div>
    </LoadingComponent>
  );
}
