import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";

export const handleDataImage = (
  yeuCauId,
  setLoading,
  setDataImages,
  setListId,
  setIds
) => {
  authGetData({
    url: Endpoint.LIST_ANH_HIEN_TRUONG_TREO_THAO + "?yeuCauId=" + yeuCauId,
    setLoading,
    onSuccess: (res) => {
      if (
        res.data !== null &&
        res.data.length > 0 &&
        res.statusCode === STATUSCODE_200
      ) {
        const arr = [];
        const arrId = [];
        const ids = [];
        // eslint-disable-next-line array-callback-return
        res.data.map((item, index) => {
          arr.push(res.data[index].images);
          ids.push(res.data[index].loaiGiayToId);
          arrId.push({
            loaiGiayToId: res.data[index].loaiGiayToId,
            tenLoaiGiayTo: res.data[index].tenLoaiGiayTo,
          });
        });
        setDataImages(arr);
        setListId(arrId);
        setIds(ids);
      }
    },
  });
};

export const handleListLoaiGT = (setLoading, setDataLoaiGT, setFistData) => {
  authGetData({
    url: Endpoint.LIST_LOAI_GIAY_TO + "?pageSize=-1",
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataLoaiGT(res.data);
        setFistData(res.data[0].id);
      } else setDataLoaiGT([]);
    },
  });
};
