import React from "react";
import { Button, Modal, Row, Col } from "antd";
import "moment/locale/vi";
import LoadingComponent from "@components/Loading";
import { Container } from "../modal-duyet-ho-so/doi-truong/css-styled";

export default function Index(props) {
  const { visible, closeModal, handleSubmit, loading } = props;
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={"Đồng bộ thời gian"}
        visible={visible}
        onCancel={closeModal}
        footer={null}
      >
        <Container>
          <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
            Bạn có muốn đồng bộ thời gian?
          </h4>
          <Row gutter={24} justify="center" style={{ marginTop: "40px" }}>
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                Đóng lại
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                className="btn-bg-yellow"
                onClick={() => handleSubmit()}
                loading={loading}
              >
                Đồng bộ
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal>
    </LoadingComponent>
  );
}
