import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { authGetData } from "@utils/request";
import { Button, Col, Divider, Form, Row, Select, Tooltip } from "antd";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { handleDownload, selectBaoCaoDoiSoatEmail } from "../services";

export default function BcChiTietSlThuDienTuKhongHopLe(props) {
  const { screen, handleChange } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [filterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
    DenNgay: moment().format(FORMAT_ENGLISH),
    ...parseParams(location.search),
  });

  useEffect(() => {
    form.resetFields();
  }, [form]);

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const handleSearch = useCallback((values) => {
    const queryString = buildQueryString(
      parseParams({
        DonViId: values.DonViId,
        DoiTuongId: values.DoiTuongId,
        TuNgay: values.TuNgay,
        DenNgay: values.DenNgay,
      })
    );
    authGetData({
      url: `${
        Endpoint.BC_CHI_TIET_SL_THU_DIEN_TU_KHONG_HOP_LE
      }?${buildQueryString(parseParams(queryString))}`,
      setLoading,
      onSuccess: (res) => {
        if (res && res.data) {
          setData(res.data);
          setTotalCount(res.paging.totalCount);
        }
      },
    });
  }, []);

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      align: "center",
      width: 60,
      render: (a, b, index) => {
        return (
          (filterConditions.pageIndex - 1) * filterConditions.pageSize +
          (index + 1)
        );
      },
    },
    {
      title: "Đơn vị",
      dataIndex: "maDVi",
      key: "maDVi",
      width: 150,
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKH",
      key: "maKH",
      width: 100,
    },
    {
      title: "Cú pháp",
      dataIndex: "cuPhap",
      key: "cuPhap",
      width: 150,
      align: "center",
      render: (text) => (
        <Tooltip title={text}>
          <Button>Nội dung cú pháp</Button>
        </Tooltip>
      ),
    },
    {
      title: "Số GCS",
      dataIndex: "soGCS",
      key: "soGCS",
      width: 100,
    },
    {
      title: "Tên số GCS",
      dataIndex: "tenSoGCS",
      key: "tenSoGCS",
      width: 160,
    },
  ];
  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <FormComponent
            onFinish={handleSearch}
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            initialValues={{
              reportType: screen,
              TuNgay: form.setFieldsValue(filterConditions.TuNgay)
                ? form.setFieldsValue(filterConditions.TuNgay)
                : moment().startOf(MONTH_STRING),
            }}
          >
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="reportType"
                  label="Tên báo cáo"
                  labelAlign="left"
                >
                  <Select
                    formKey="reportType"
                    form={form}
                    options={selectBaoCaoDoiSoatEmail}
                    onChange={() =>
                      handleChange(form.getFieldValue("reportType"))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="DonViId" label="Đơn vị" labelAlign="left">
                  <Selection
                    url={Endpoint.GET_UNIT_LIST}
                    formKey="DonViId"
                    form={form}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="DoiTuongId" label="Nội dung" labelAlign="left">
                  <Selection
                    url={Endpoint.CSKH_BAO_CAO_EMAIL}
                    formKey="DoiTuongId"
                    form={form}
                    placeholder="Tất cả"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="TuNgay"
                  label="Ngày bắt đầu"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  labelAlign="left"
                >
                  <DatePickerComponent
                    formKey="TuNgay"
                    form={form}
                    format={FORMAT_ENGLISH}
                    defaultValue={
                      filterConditions.TuNgay
                        ? moment(filterConditions.TuNgay, FORMAT_ENGLISH)
                        : moment()
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="DenNgay"
                  label="Ngày kết thức"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  labelAlign="left"
                >
                  <DatePickerComponent
                    formKey="DenNgay"
                    form={form}
                    format={FORMAT_ENGLISH}
                    defaultValue={
                      filterConditions.DenNgay
                        ? moment(filterConditions.DenNgay, FORMAT_ENGLISH)
                        : moment()
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Button type="primary" className="mr-5px" htmlType="submit">
                Xem báo cáo
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  handleDownload(form.getFieldValue(), setLoading);
                }}
              >
                Xuất Excel
              </Button>
            </Row>
          </FormComponent>
          <Divider className="section-text-line" orientation="left"></Divider>
          <TableComponent
            header={
              "Báo cáo chi tiết số lượng thư điện tử hợp lệ đã gửi theo từng đơn vị"
            }
            columns={columns}
            filterConditions={filterConditions}
            dataSource={data}
            totalCount={totalCount}
            loading={loading}
          />
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  margin-top: 40px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
