import { authDeleteData, authGetData, authPostData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import {
  alertMessage,
  buildQueryString,
  getErrorMessage,
  parseParams,
} from "@utils/function";
import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { t } from "i18next";

export const getListGiayToService = (
  filterConditions,
  setLoading,
  setDataGiayTo,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.LIST_LOAI_GIAY_TO}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataGiayTo(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataGiayTo([]);
    },
  });
};

export const deleteGiayToService = (ids, setLoading, getListGiayTo) => {
  authDeleteData({
    url: Endpoint.REMOVE_LOAI_GIAY_TO + "?Ids=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) getListGiayTo();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

export const createOrUpdateGiayTo = (
  data,
  setLoading,
  getListGiayTo,
  closeModal,
  form
) => {
  if (data.id) {
    authPostData({
      url: Endpoint.UPDATE_LOAI_GIAY_TO,
      method: METHOD_PUT,
      payload: data,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          getListGiayTo();
          closeModal();
        } else getErrorMessage(res, form);
      },
    });
  } else {
    authPostData({
      url: Endpoint.CREATE_LOAI_GIAY_TO,
      method: METHOD_POST,
      payload: data,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          getListGiayTo();
          closeModal();
        } else getErrorMessage(res, form);
      },
    });
  }
};
