import React from "react";
import styled from "styled-components";
import { Result } from "antd";

import { useLocation } from "react-router-dom";
const DefaultPage = () => {
  const { state } = useLocation();
  return (
    <Container>
      <div>
        <Result
          status="403"
          title={`TRANG ${
            state.namePage ? state.namePage.toUpperCase() : ""
          } HIỆN TẠI ĐANG PHÁT TRIỂN`}
        />
        ,
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: 50%;
  }
}
`;

export default DefaultPage;
