import { updateDanhSachDiemDoCoChiSoNhapSai } from "@state/giao-nhan-dien-nang/quan-ly-chi-so/reducer";
import { BCS_GNDN, ENUM_QL_CHI_SO_GNDN_LOAI_NHAP } from "@utils/constants";
import {
  roundingNumberAfterDecimal,
  isNumber,
  numberWithNoSpaces,
  numberWithSpaces,
  checkIsIndex,
} from "@utils/function";

export function tinhToanSanLuongVaTongCong({
  dataSource,
  keyId,
  setDataSource = () => {},
  isNoUpdate,
  dataSourceOriginal,
  setDataSourceInitial = () => {},
}) {
  const idx = dataSource.findIndex((e) => e.id === keyId);
  const idxOriginal = dataSourceOriginal.findIndex((e) => e.id === keyId);
  const getValue = (name) => {
    let value = null;
    if (checkIsIndex({ index: idx })) {
      value = dataSource[idx][name];
    }
    if (isNumber(value)) {
      value = parseFloat(numberWithNoSpaces(value));
    }
    return value;
  };

  const chiSoCuoi = getValue("chiSoCuoi");
  const sanLuongTrucTiep = getValue("sanLuongTrucTiep");
  let sanLuong;
  let tongCong;
  const sanLuongThao = getValue("sanLuongThao") || 0;
  const chiSoDau = getValue("chiSoDau");
  const heSoNhan = getValue("heSoNhan");

  if (isNumber(chiSoCuoi)) {

    const roundIndex = fixedNumber(chiSoCuoi - chiSoDau);
    sanLuong = fixedNumber(roundIndex * heSoNhan);

  } else {
    sanLuong = null;
  }

  if (isNumber(sanLuong) || isNumber(sanLuongTrucTiep)) {
    tongCong = fixedNumber((sanLuongTrucTiep || 0) + (sanLuong || 0) + (sanLuongThao || 0));
  }

  if (checkIsIndex({ index: idx })) {
    dataSource[idx].sanLuong = numberWithSpaces(sanLuong) || sanLuong;
    dataSource[idx].tongCong = numberWithSpaces(tongCong) || tongCong;
  }

  if (checkIsIndex({ index: idxOriginal })) {
    dataSourceOriginal[idxOriginal].sanLuong = numberWithSpaces(sanLuong) || sanLuong;
    dataSourceOriginal[idxOriginal].tongCong = numberWithSpaces(tongCong) || tongCong;
  }

  if (!isNoUpdate) {
    setDataSource([...dataSource]);
    setDataSourceInitial([...dataSourceOriginal]);
  }
}

const getValue = (value) => {
  return roundingNumberAfterDecimal({ number: value });
};

const calculateForm = ({ record, chiSoCuoi, index, dataSource, dataSourceOriginal, indexOriginal, setDataSourceInitial }) => {
  const heSoNhan = +numberWithNoSpaces(record?.heSoNhan);
  const valueChiSoCuoi = heSoNhan >= 1000 ? getValue(chiSoCuoi / heSoNhan) : getValue(chiSoCuoi);

  if (checkIsIndex({ index: index })) {
    dataSource[index].chiSoCuoi = numberWithSpaces(valueChiSoCuoi);
  }
  if (checkIsIndex({ index: indexOriginal })) {
    dataSourceOriginal[indexOriginal].chiSoCuoi = numberWithSpaces(valueChiSoCuoi);
  }

  const listDiemDo = dataSourceOriginal.filter((item) => item.diemDoId === record.diemDoId);

  // Xét tất cả các điểm đo có cùng diemDoId => Lay Do xa
  listDiemDo.forEach((element) => {
    const idx = dataSourceOriginal.findIndex((item) => item.id === element.id);
    const idxOriginal = dataSourceOriginal.findIndex((item) => item.id === element.id);

    if (checkIsIndex({ index: idx })) {
      dataSource[idx].loaiNhap = ENUM_QL_CHI_SO_GNDN_LOAI_NHAP.LAY_DO_XA.value;
    }
    if (checkIsIndex({ index: idxOriginal })) {
      dataSourceOriginal[idxOriginal].loaiNhap = ENUM_QL_CHI_SO_GNDN_LOAI_NHAP.LAY_DO_XA.value;
    }
  });

  tinhToanSanLuongVaTongCong({ dataSource, keyId: record.id, isNoUpdate: true, dataSourceOriginal, setDataSourceInitial });
};

export function calculateLayDoXa({ dataSource, res, dispatch, setDataSource, dataSourceOriginal, setDataSourceInitial }) {
  if (res.data && res.data.length) {
    const elements = res.data;
    let arrChiSoNhapSai = [];
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];

      const diemDos = dataSourceOriginal.filter((item) => item.maDoXa === element.SITECODE);

      const totalBG = element.BTG + element.CDG + element.TDG;
      const totalBN = element.BTN + element.CDN + element.TDN;

      const boChiSoId = diemDos[0].boChiSoId;
      if (totalBG === element.SGG && totalBN === element.SGN) {
        arrChiSoNhapSai = arrChiSoNhapSai.filter((e) => e !== boChiSoId);
      } else {
        arrChiSoNhapSai = [...new Set([...arrChiSoNhapSai, boChiSoId])];
      }

      for (let index = 0; index < diemDos.length; index++) {
        const diemDo = diemDos[index];
        const idx = dataSource.findIndex((e) => e.id === diemDo.id);
        const indexOriginal = dataSourceOriginal.findIndex((e) => e.id === diemDo.id);
        switch (diemDo.boSo) {
          // Cập nhật BCS bên giao
          case BCS_GNDN.BT:
            calculateForm({
              record: diemDo,
              chiSoCuoi: element.BTG,
              index: idx,
              indexOriginal,
              dataSource,
              dataSourceOriginal,
              setDataSourceInitial,
            });
            break;
          case BCS_GNDN.CD:
            calculateForm({
              record: diemDo,
              chiSoCuoi: element.CDG,
              index: idx,
              indexOriginal,
              dataSource,
              dataSourceOriginal,
              setDataSourceInitial,
            });
            break;
          case BCS_GNDN.TD:
            calculateForm({
              record: diemDo,
              chiSoCuoi: element.TDG,
              index: idx,
              indexOriginal,
              dataSource,
              dataSourceOriginal,
              setDataSourceInitial,
            });
            break;
          case BCS_GNDN.SG:
            calculateForm({
              record: diemDo,
              chiSoCuoi: element.SGG,
              index: idx,
              indexOriginal,
              dataSource,
              dataSourceOriginal,
              setDataSourceInitial,
            });
            break;
          case BCS_GNDN.VC:
            calculateForm({
              record: diemDo,
              chiSoCuoi: element.VCG,
              index: idx,
              indexOriginal,
              dataSource,
              dataSourceOriginal,
              setDataSourceInitial,
            });
            break;
          // Cập nhật BCS bên nhận
          case BCS_GNDN.BN:
            calculateForm({
              record: diemDo,
              chiSoCuoi: element.BTN,
              index: idx,
              indexOriginal,
              dataSource,
              dataSourceOriginal,
              setDataSourceInitial,
            });
            break;
          case BCS_GNDN.CN:
            calculateForm({
              record: diemDo,
              chiSoCuoi: element.CDN,
              index: idx,
              indexOriginal,
              dataSource,
              dataSourceOriginal,
              setDataSourceInitial,
            });
            break;
          case BCS_GNDN.TN:
            calculateForm({
              record: diemDo,
              chiSoCuoi: element.TDN,
              index: idx,
              indexOriginal,
              dataSource,
              dataSourceOriginal,
              setDataSourceInitial,
            });
            break;
          case BCS_GNDN.SN:
            calculateForm({
              record: diemDo,
              chiSoCuoi: element.SGN,
              index: idx,
              indexOriginal,
              dataSource,
              dataSourceOriginal,
              setDataSourceInitial,
            });
            break;
          case BCS_GNDN.VN:
            calculateForm({
              record: diemDo,
              chiSoCuoi: element.VCN,
              index: idx,
              indexOriginal,
              dataSource,
              dataSourceOriginal,
              setDataSourceInitial,
            });
            break;
          default:
          // code block
        }
      }
    }
    setDataSource([...dataSource]);
    setDataSourceInitial([...dataSourceOriginal]);
    dispatch(updateDanhSachDiemDoCoChiSoNhapSai([...arrChiSoNhapSai]));
  }
  // alertMessage(res);
}

export function getChiSoCuoi({ record }) {
  if (record.loaiNhap === ENUM_QL_CHI_SO_GNDN_LOAI_NHAP.LAY_DO_XA.value) {
    return roundingNumberAfterDecimal({ number: record.chiSoCuoi });
  }
  return fixedNumber(record.chiSoCuoi);
}

export function fixedNumber(number) {
  if (!number && number !== 0) {
    return number;
  }
  return +number?.toFixed(3)?.toString();
}
