import { Endpoint } from "@utils/endpoint";
import { authGetData, authGetMultiplesRequest } from "@utils/request";
import { buildQueryString } from "@utils/function";
import { STATUSCODE_200 } from "@utils/constants";

export const handleListBaoCao = (
  filterConditions,
  setLoading,
  setData,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.LIST_BAOCAO_HAN_KIEM_DINH_CT}?${buildQueryString(
      filterConditions
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data && res.statusCode === STATUSCODE_200) {
        setData(res.data);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};

export const handleListYC = (
  record,
  soPha,
  obj,
  setLoading,
  setDataYC,
  setTotalCountYC,
  setIsShowYC
) => {
  authGetData({
    url: `${Endpoint.LIST_YEU_CAU_BAO_CAO_HAN_KIEM_DINH_CT}?${buildQueryString(
      obj
    )}&tramId=${record.tramId}&soPha=${soPha}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data && res.statusCode === STATUSCODE_200) {
        setDataYC(res.data);
        setTotalCountYC(res.paging.totalCount);
        setIsShowYC(true);
      } else {
        setIsShowYC(false);
      }
    },
  });
};

export const handleMultiBoLoc = (setLoading, setLoaiTT, setDonVi) => {
  const endpoints = [
    `${Endpoint.COMMON_LIST_LOAI_TREO_THAO}?type=1&funcId=3`,
    // `${Endpoint.COMMON_LIST_TRAM_TREO_THAO}`,
    `${Endpoint.LIST_DON_VI}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setLoaiTT(res[0].data.data);
      setDonVi(res[1].data.data);
    },
  });
};
