import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";
import {
  buildQueryString,
  parseParams
} from "@utils/function";

// get list data Danh sách sổ GCS
export const handleListDataDsSoGCS = (
  setLoading,
  location,
  setDataDsSoGCS,
  setTotalCount,
  filterConditions
) => {
  let url = "";
  url = `${Endpoint.DANH_SACH_SO_GCS}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
    authGetData({
      url,
      setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDsSoGCS(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataDsSoGCS([]);
    },
  });
};