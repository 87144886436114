import React, { Fragment, useCallback } from "react";
import {
  Col,
  Input,
  Row,
  Form,
  Radio,
  Table,
  Select,
  Checkbox,
  Space,
  Typography,
  ConfigProvider,
  Skeleton,
} from "antd";
import vn from "antd/es/locale-provider/vi_VN";
import { ENUM_MODE_PHUONGANCAPDIEN, TYPE_INPUT } from "@utils/constants";
import { TextContent } from "@modules/cap-dien/nhan-vien-khao-sat/css-styled";
import InputFormatter from "@components/InputFormatter";
import { useSelector } from "react-redux";
import { lapPhuongAnSelector } from "@state/cap-dien/nhan-vien-khao-sat/lap-phuong-an";
import LoadingComponent from "@components/Loading";
import { useState } from "react";

const Text = Typography.Text;

export default function UICongTo(props) {
  const {
    mode,
    setViTriLapDat,
    viTriLapDat,
    columns,
    data,
    listHinhThucLapDat,
    setShowInputField,
    isShowInputField,
    content,
    loadingDayDanDien,
    form,
  } = props;

  const [length, setLength] = useState("");

  const { tongChiPhi, tongChiPhiChuaVAT, loadingTinhChiPhi } =
    useSelector(lapPhuongAnSelector);

  const formatter = (value) =>
    value !== null && value !== undefined
      ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : null;
  const ucs2ToBinaryString = (str) => {
    if (str === "") return str;
    const escstr = encodeURIComponent(str);
    const binstr = escstr.replace(/%([0-9A-F]{2})/gi, function (match, hex) {
      const i = parseInt(hex, 16);
      return String.fromCharCode(i);
    });
    return binstr;
  };

  const cotSo = useCallback(
    (event) => {
      const { value } = event.target;
      if (form) {
        if (ucs2ToBinaryString(value).length >= 20) {
          const limit = 20;
          const size = ucs2ToBinaryString(value).length - limit;
          form.setFieldsValue({
            cotSo: value.slice(0, value.length - size),
          });
          setLength(value.slice(0, value.length - size));
        } else {
          form.setFieldsValue({
            cotSo: value,
          });
          setLength(value);
        }
      }
    },
    [form]
  );

  const dataMerge =
    data &&
    Array.isArray(data) &&
    data.map((rs, index) => ({ ...rs, id: index }));
  return (
    <Fragment>
      <Col span={24}>
        <span className="fs-16px font-bold">3. Công tơ</span>
        <Row gutter={24} className="mt-16px">
          <Col span={24} md={24}>
            <span className="fs-14px">Thông tin đo đếm điện</span>
          </Col>
          <Col span={24} md={8}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.doDemA !== curValues.doDemA
                }
              >
                {({ getFieldValue }) => {
                  const doDemA = getFieldValue("doDemA") || undefined;
                  return doDemA ? (
                    <Typography.Text className="ant-form-text" strong>
                      {doDemA} A
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            ) : (
              <Form.Item name="doDemA">
                <Input
                  className="input-right"
                  suffix="A"
                  disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                />
              </Form.Item>
            )}
          </Col>
          <Col span={24} md={8}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.doDemV !== curValues.doDemV
                }
              >
                {({ getFieldValue }) => {
                  const doDemV = getFieldValue("doDemV") || undefined;
                  return doDemV ? (
                    <Typography.Text className="ant-form-text" strong>
                      {doDemV} V
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            ) : (
              <Form.Item name="doDemV">
                <Input
                  className="input-right"
                  suffix="V"
                  disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                />
              </Form.Item>
            )}
          </Col>
          <Col span={24} md={8}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.doDemTI !== curValues.doDemTI
                }
              >
                {({ getFieldValue }) => {
                  const doDemTI = getFieldValue("doDemTI") || undefined;
                  return doDemTI ? (
                    <Typography.Text className="ant-form-text" strong>
                      {doDemTI} Ti
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            ) : (
              <Form.Item name="doDemTI">
                <Input
                  className="input-right"
                  suffix="Ti"
                  disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} flex={"200px"} style={{ marginTop: 6 }}>
            <Text className="fs-14px">Tình trạng hòm công tơ: </Text>
          </Col>
          <Col span={24} flex={"auto"}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.tinhTrangCongTo !== curValues.tinhTrangCongTo
                }
              >
                {({ getFieldValue }) => {
                  const tinhTrangCongTo =
                    getFieldValue("tinhTrangCongTo") || undefined;
                  return tinhTrangCongTo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {tinhTrangCongTo === "LM"
                        ? "Lắp mới"
                        : tinhTrangCongTo === "DCS"
                        ? "Có sẵn"
                        : tinhTrangCongTo === "LOAI_CTO_DDC"
                        ? "Thay Thế"
                        : null}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            ) : (
              <Form.Item name="tinhTrangCongTo">
                <Radio.Group
                  style={{ width: "100%" }}
                  disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                >
                  <Row gutter={24}>
                    <Col span={24} md={5}>
                      <Radio value="LM">Lắp mới</Radio>
                    </Col>
                    <Col span={24} md={5}>
                      <Radio value="DCS">Có Sẵn</Radio>
                    </Col>
                    <Col md={5}>
                      <Radio value="LOAI_CTO_DDC">Thay Thế</Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} flex={"200px"} style={{ marginTop: 6 }}>
            <Text className="fs-14px">Vị trí lắp đặt công tơ:</Text>
          </Col>
          <Col span={24} flex={"auto"}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.viTriCongTo !== curValues.viTriCongTo
                }
              >
                {({ getFieldValue }) => {
                  const viTriCongTo = getFieldValue("viTriCongTo") || undefined;
                  const cotSo = getFieldValue("cotSo") || undefined;
                  const viTriKhac = getFieldValue("viTriKhac") || undefined;
                  return viTriCongTo ? (
                    <Typography.Text className="ant-form-text" strong>
                      {viTriCongTo === 0
                        ? "Trong nhà"
                        : viTriCongTo === 1
                        ? "Trên tường ngoài nhà"
                        : viTriCongTo === 2
                        ? `Tại cột số - ${cotSo}`
                        : viTriCongTo === 3
                        ? `Vị trí khác - ${viTriKhac}`
                        : null}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            ) : (
              <Form.Item name="viTriCongTo">
                <Radio.Group
                  onChange={(e) => {
                    setViTriLapDat(e.target.value);
                  }}
                  style={{ width: "100%" }}
                  disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                >
                  <Row gutter={24}>
                    <Col span={24} md={5}>
                      <Radio value={0}>Trong nhà</Radio>
                    </Col>
                    <Col span={24} md={5}>
                      <Radio value={1}>Trên tường ngoài nhà</Radio>
                    </Col>
                    <Col span={24} md={3} style={{ paddingRight: 0 }}>
                      <Radio value={2}>Tại cột số</Radio>
                    </Col>

                    <Col span={24} md={11} style={{ height: 50 }}>
                      {viTriLapDat === 2 ? (
                        <Form.Item name={"cotSo"}>
                          <Input
                            placeholder="Cột số"
                            suffix={`${
                              ucs2ToBinaryString(length).length
                            }/20 kí tự`}
                            value={form.getFieldValue("cotSo") || ""}
                            style={{ marginTop: 0 }}
                            disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                            onChange={cotSo}
                            // onChange={(e) => {
                            //   if (
                            //     ucs2ToBinaryString(e.target.value).length <= 50
                            //   ) {
                            //     form.setFieldsValue({
                            //       [`cotSo`]: e.target.value,
                            //     });
                            //   } else {
                            //   }
                            // }}
                          />
                        </Form.Item>
                      ) : null}
                    </Col>
                  </Row>
                  {/* <Row gutter={24} style={{ height: 50 }}>
                    <Col span={24} md={4}>
                      <Radio value={3}>Vị trí khác</Radio>
                    </Col>
                    <Col span={24} md={20}>
                      {viTriLapDat === 3 ? (
                        <Form.Item name="viTriKhac">
                          <Input
                            placeholder="Vị trí khác"
                            disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                          />
                        </Form.Item>
                      ) : null}
                    </Col>
                  </Row> */}
                </Radio.Group>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <LoadingComponent loading={loadingDayDanDien}>
          <ConfigProvider locale={vn}>
            <div className="table-fixed">
              <Table
                columns={columns}
                dataSource={dataMerge}
                pagination={false}
                style={{ padding: 5 }}
                rowKey={(object) => object.id ?? object.vatTuId}
                bordered={true}
              />
            </div>
          </ConfigProvider>
        </LoadingComponent>

        <TextContent>{content}</TextContent>

        <Row gutter={24} className="mt-16px">
          <Col flex={"150px"} className="mt-16px">
            <span className="fs-14px">Hình thức lắp đặt</span>
          </Col>
          <Col flex={"500px"} style={{ paddingTop: 12 }}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.hinhThucLapDat !== curValues.hinhThucLapDat
                }
              >
                {({ getFieldValue }) => {
                  const hinhThucLapDat =
                    getFieldValue("hinhThucLapDat") || undefined;
                  return hinhThucLapDat ? (
                    <Typography.Text className="ant-form-text" strong>
                      {listHinhThucLapDat &&
                        listHinhThucLapDat.find(
                          (x) => x.value === hinhThucLapDat
                        )?.name}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            ) : (
              <Form.Item name="hinhThucLapDat">
                <Select
                  style={{ width: "100%" }}
                  disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                >
                  {listHinhThucLapDat &&
                    listHinhThucLapDat.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            )}
          </Col>
          {/* <Col span={12}>
            <Form.Item name="isVAT" valuePropName="checked">
              <Checkbox disabled={true}>VAT</Checkbox>
            </Form.Item>
          </Col> */}
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <div style={{ height: 30 }}>
              {loadingTinhChiPhi ? (
                <Skeleton
                  style={{ padding: 0 }}
                  loading={loadingTinhChiPhi}
                  active
                  paragraph={{ rows: 2 }}
                  title={false}
                />
              ) : (
                <Space direction="horizontal">
                  <div className="fs-14px">
                    Tổng tiền chi phí lắp đặt dây sau công tơ của khách hàng là:
                  </div>
                  <div>
                    <InputFormatter
                      style={{
                        width: "100%",
                        backgroundColor: "#e2e2e2",
                        textAlign: "end",
                      }}
                      controls={false}
                      readOnly={true}
                      type={TYPE_INPUT.DECIMAL}
                      className="input-number-right"
                      value={formatter(tongChiPhi) || 0}
                    />
                  </div>

                  <div>Đồng</div>
                  {tongChiPhiChuaVAT !== null &&
                  tongChiPhiChuaVAT !== undefined ? (
                    <div className="fs-12px" style={{ fontStyle: "italic" }}>
                      (Chưa VAT: {formatter(tongChiPhiChuaVAT) || 0} đồng)
                    </div>
                  ) : null}
                </Space>
              )}
            </div>
          </Col>
        </Row>
        <Row gutter={24} className="mt-16px">
          <Col span={24}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.isDatCoc !== curValues.isDatCoc
                }
              >
                {({ getFieldValue }) => {
                  const isDatCoc = getFieldValue("isDatCoc") || undefined;
                  return (
                    <Fragment>
                      <Typography.Text className="ant-form-text">
                        {"Cần nộp tiền đặt cọc: "}
                      </Typography.Text>
                      <Typography.Text className="ant-form-text" strong>
                        {`${isDatCoc ? " Có" : " Không"}`}
                      </Typography.Text>
                    </Fragment>
                  );
                }}
              </Form.Item>
            ) : null}
          </Col>

          <Col span={24}>
            {/* {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.isTreoThao !== curValues.isTreoThao
                }
              >
                {({ getFieldValue }) => {
                  const isTreoThao = getFieldValue("isTreoThao") || undefined;
                  return isTreoThao ? (
                    <Typography.Text className="ant-form-text" strong>
                      {"Có thực hiện treo tháo"}
                    </Typography.Text>
                  ) : null;
                }}
              </Form.Item>
            ) : (
              <Form.Item name="isTreoThao" valuePropName="checked">
                <Checkbox disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}>
                  Thực hiện treo tháo
                </Checkbox>
              </Form.Item>
            )} */}
          </Col>
          <Col flex={1}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Space direction="horizontal">
                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.isLapThem !== curValues.isLapThem
                  }
                >
                  {({ getFieldValue }) => {
                    const isLapThem = getFieldValue("isLapThem") || undefined;
                    return isLapThem ? (
                      <Typography.Text className="ant-form-text">
                        {"Có lắp thêm Cột/cáp ngầm/máy biến dòng"}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.dienGiaiLapThem !== curValues.dienGiaiLapThem
                  }
                >
                  {({ getFieldValue }) => {
                    const dienGiaiLapThem =
                      getFieldValue("dienGiaiLapThem") || undefined;
                    return dienGiaiLapThem ? (
                      <Typography.Text className="ant-form-text" strong>
                        : {dienGiaiLapThem}
                      </Typography.Text>
                    ) : null;
                  }}
                </Form.Item>
              </Space>
            ) : (
              <Form.Item name="isLapThem" valuePropName="checked">
                <Checkbox
                  onChange={(e) => setShowInputField(e.target.checked)}
                  disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                >
                  <Space direction="horizontal">
                    Lắp thêm Cột/cáp ngầm/máy biến dòng
                  </Space>
                </Checkbox>
              </Form.Item>
            )}
          </Col>
          <Col flex={6}>
            {mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? null : ( // </Form.Item> //   }} //     ) : null; //       </Typography.Text> //         : {dienGiaiLapThem} //       <Typography.Text className="ant-form-text" strong> //     return dienGiaiLapThem ? ( //       getFieldValue("dienGiaiLapThem") || undefined; //     const dienGiaiLapThem = //   {({ getFieldValue }) => { // > //   } //     prevValues.dienGiaiLapThem !== curValues.dienGiaiLapThem //   shouldUpdate={(prevValues, curValues) => // <Form.Item
              <Fragment>
                {isShowInputField ? (
                  <Form.Item name="dienGiaiLapThem">
                    <Input
                      placeholder="Diễn giải lý do xây dựng lắp thêm…."
                      disabled={mode === ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                    />
                  </Form.Item>
                ) : null}
              </Fragment>
            )}
          </Col>
        </Row>
      </Col>
    </Fragment>
  );
}
