import { Col } from "antd";
import { ContainerTab4 } from "../css-styled";

export default function Index(props) {
  const { data } = props;
  return (
    <ContainerTab4 gutter={24}>
      <Col span={24}>
        <h2 className="title">IV. Yêu cầu chậm tiến độ</h2>
      </Col>
      {data && data.length > 0
        ? data.map((item, index) => (
            <Col span={24} md={24} lg={8} key={index}>
              <div className="container">
                <div className={`box box${index + 1}`}>
                  <h3>{item.title}</h3>
                  <h1 className="font-bold">{item?.value}</h1>
                </div>
              </div>
            </Col>
          ))
        : null}
      {/* <Col span={24} md={8}>
        <div className="container">
          <div className="box box1">
            <h3>Tháng 12/2022</h3>
            <h1 className="font-bold">{data?.thang}</h1>
          </div>
        </div>
      </Col>
      <Col span={24} md={8}>
        <div className="container">
          <div className="box box2">
            <h3>Quý 4/2022</h3>
            <h1 className="font-bold">{data?.quy}</h1>
          </div>
        </div>
      </Col>
      <Col span={24} md={8}>
        <div className="container">
          <div className="box box3">
            <h3>Năm 2022</h3>
            <h1 className="font-bold">{data?.nam}</h1>
          </div>
        </div>
      </Col> */}
    </ContainerTab4>
  );
}
