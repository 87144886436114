import { removeAccents } from "@utils/function";
import { Button, Col, Form, Input, Modal, Radio, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getListDoi,
  getListNvKinhDoanh,
  getNhanVienHienTruong,
} from "../services";
import { Style } from "./css-styled";
function PhanCongKinhDoanhModal(props) {
  const { visible, handleCloseModal, handleSubmit, form, loading } = props;

  const unitId = useSelector((state) => state.auth.user.unitId);

  const [value, setValue] = useState("1");
  const [doiId, setDoiId] = useState();
  const [loadingNv, setLoadingNv] = useState(false);
  const [loadingDoi, setLoadingDoi] = useState(false);

  const [loadingNvKinhDoanh, setLoadingNvKinhDoanh] = useState(false);
  const [dataNv, setDataNv] = useState([]);
  const [dataDoi, setDataDoi] = useState([]);
  const [dataNvKinhDoanh, setDataNvKinhDoanh] = useState([]);

  const handleChange = (value) => {
    setDoiId(value);
  };

  const onChange = (e) => {
    setValue(e.target.value);
    setDataNv([]);
    setDoiId(null);
    form.resetFields();
  };
  const handleClose = () => {
    handleCloseModal();
  };

  useEffect(() => {
    getListDoi(setLoadingDoi, setDataDoi, unitId);
    // getListNvGiamSat(setLoadingNvGiamSat, setDataNvGiamSat, unitId);
    getListNvKinhDoanh(setLoadingNvKinhDoanh, setDataNvKinhDoanh, unitId);
  }, [unitId]);

  useEffect(() => {
    if (doiId) {
      getNhanVienHienTruong(setLoadingNv, setDataNv, doiId);
    }
  }, [doiId]);

  return (
    <Modal
      title="PHÂN CÔNG."
      visible={visible}
      onCancel={handleClose}
      footer={null}
    >
      <Style>
        <Form
          form={form}
          // layout="vertical"
          onFinish={handleSubmit}
          requiredMark={true}
        >
          <Radio.Group
            style={{ width: "100%" }}
            onChange={onChange}
            value={value}
          >
            <Radio value="1">Phân về đội</Radio>
            <Radio value="2">NV kinh doanh</Radio>
          </Radio.Group>
          <Row gutter={24}>
            <Col span={16} offset={8}>
              <Form.Item
                name="doi"
                rules={[
                  {
                    required: value === "1" && true,
                    message: "Vui lòng chọn đội!",
                  },
                ]}
              >
                <Select
                  placeholder="Chọn đội"
                  allowClear
                  loading={loadingDoi}
                  disabled={value !== "1"}
                  showSearch
                  onChange={handleChange}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {dataDoi &&
                    dataDoi.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={16} offset={8}>
              <Form.Item name="nvHienTruong">
                <Select
                  placeholder="Chọn NV hiện trường của đội"
                  allowClear
                  disabled={value !== "1"}
                  showSearch
                  loading={loadingNv}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {dataNv &&
                    dataNv.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            {/* <Col span={24}>
              <Form.Item
                name="nguoiGiamSat"
                label="Người giám sát"
                className="nguoi-giam-sat-kd"
              >
                <Select
                  placeholder="Chọn NV hỗ trợ, giám sát"
                  allowClear
                  disabled={value !== "1"}
                  showSearch
                  loading={loadingNvGiamSat}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {dataNvGiamSat &&
                    dataNvGiamSat.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col> */}
            <Col span={16} offset={8}>
              <Form.Item
                name="nvKinhDoanh"
                rules={[
                  {
                    required: value === "2" && true,
                    message: "Vui lòng chọn nv kinh doanh!",
                  },
                ]}
              >
                <Select
                  placeholder="Chọn NV hỗ trợ, giám sát"
                  allowClear
                  disabled={value !== "2"}
                  showSearch
                  loading={loadingNvKinhDoanh}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {dataNvKinhDoanh &&
                    dataNvKinhDoanh.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Ghi chú" name="ghichu">
                <Input.TextArea row={6} placeholder />
              </Form.Item>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <Button
                key="back"
                onClick={handleClose}
                className="button-closed"
                htmlType="button"
              >
                Đóng lại
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                loading={loading}
              >
                Phân công
              </Button>
            </Col>
          </Row>
        </Form>
      </Style>
    </Modal>
  );
}

export default PhanCongKinhDoanhModal;
