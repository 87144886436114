import { useState, useEffect } from "react";
import { Form } from "antd";
import ModalReturn from "./modal";
import { handleReturn } from "./services";

export default function Index(props) {
  const {
    visible,
    closeModal,
    selectedRowKeys,
    setSelectedRowKeys,
    setFetchList,
    url,
  } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);
  const onFinish = (value) => {
    const payload = {
      id: selectedRowKeys.toString(),
      lyDoTraLai: value.lyDoTraLai,
    };
    handleReturn(
      url,
      payload,
      setLoading,
      setFetchList,
      setSelectedRowKeys,
      closeModal
    );
  };
  return (
    <ModalReturn
      visible={visible}
      closeModal={closeModal}
      loading={loading}
      form={form}
      onFinish={onFinish}
    />
  );
}
