export const BASE_API_URL = process.env.REACT_APP_API_URL;
export const BASE_API_URL_CAP_DIEN = process.env.REACT_APP_API_URL_CAP_DIEN;
export const BASE_API_URL_DANH_MUC = process.env.REACT_APP_API_URL_DANH_MUC;
export const BASE_API_URL_GHI_CHI_SO = process.env.REACT_APP_API_URL_GHI_CHI_SO;
export const BASE_API_URL_BAO_CAO = process.env.REACT_APP_API_URL_BAO_CAO;
export const BASE_API_URL_CSKH = process.env.REACT_APP_API_URL_CSKH;
export const BASE_API_URL_GNDN =
  process.env.REACT_APP_API_URL_GIAO_NHAN_DIEN_NANG;
export const BASE_API_URL_APGIA = process.env.REACT_APP_API_URL_AP_GIA;
export const BASE_API_URL_CRM = process.env.REACT_APP_API_URL_CRM;
export const BASE_API_URL_THANH_TOAN = process.env.REACT_APP_API_URL_THANH_TOAN;
export const BASE_API_URL_CONG_TO_THAO =
  process.env.REACT_APP_API_URL_CONG_TO_THAO;
export const BASE_API_URL_KT_THIETBI =
  process.env.REACT_APP_API_URL_KT_THIET_BI;
export const BASE_API_URL_TREO_THAO = process.env.REACT_APP_API_URL_TREO_THAO;
export const BASE_API_URL_LO_TRINH_NHAN_VIEN =
  process.env.REACT_APP_API_URL_LO_TRINH_NHAN_VIEN;

export const BASE_API_URL_TRUNG_AP = process.env.REACT_APP_API_URL_TRUNG_AP;
export const REACT_APP_MAP_API_KEY = process.env.REACT_APP_MAP_API_KEY;

export const TOKEN_NAME = "ex1-auth-token";
export const DEBOUNCE_INPUT_SEARCH_DELAY = 1000;

export const WIDTH_LEFT_MENU = 270;

export const DEFAULT_PAGEINDEX = 1;
export const DEFAULT_PAGESIZE = 20;
export const PAGESIZE_5 = 5;

export const FORMAT_DATE = "DD/MM/YYYY";
export const FORMAT_DATE_MONTH = "MM/DD/YYYY";
export const FORMAT_ENGLISH = "YYYY-MM-DD";
export const FORMAT_ENGLISH_TIME_FULL = "YYYY-MM-DDTHH:mm:ss";
export const FORMAT_MONTH = "MM/YYYY";
export const FORMAT_MONTH_ENGLISH = "YYYY-MM";
export const FORMAT_YEAR = "YYYY";
export const FORMAT_QUANTER = "YYYY-\\QQ";
export const MONTH_STRING = "month";
export const FORMAT_TIME = "HH:mm";
export const FORMAT_TIME_FULL = "HH:mm:ss";
export const INDEX_TAB1 = "1";
export const INDEX_TAB2 = "2";
export const INDEX_TAB3 = "3";
export const KEY_PANEL0 = "0";
export const KEY_PANEL1 = "1";
export const KEY_PANEL2 = "2";
export const KEY_PANEL3 = "3";
export const KEY_PANEL4 = "4";

export const CONG_TO_TREO = "B";
export const CONG_TO_THAO = "E";
export const MODULE_CODECAPDIEN = "a";

// Trạng thái tài khoản

const ACTIVE = 1;
const UNACTIVE = 2;
export const ITEM_STATUS = [
  { value: ACTIVE, name: "Hoạt động" },
  { value: UNACTIVE, name: "Khóa" },
];

//MODULE

export const MODULE_DICHVUDIEN = "a";
export const MODULE_TREOTHAOTHIETBI = "b";
export const MODULE_DODEM = "c";
export const MODULE_GHICHISO = "d";
export const MODULE_APGIA = "e";
export const MODULE_BAOCAO = "f";
export const MODULE_LOTRINH = "g";
export const MODULE_THANHTOAN = "h";
export const MODULE_CHISOCONGTO = "i";
export const MODULE_CHAMSOCKHACHHANG = "j";
export const MODULE_HETHONG = "k";
export const MODULE_TIENICHCHUNG = "m";
export const MODULE_KIEMTRACONGTO = "n";
export const MODULE_TICHHOPCRM = "o";
export const MODULE_TRUNG_AP = "l";

export const objModules = {
  [MODULE_DICHVUDIEN]: "Quản lý dịch vụ điện",
  [MODULE_TREOTHAOTHIETBI]: "Treo tháo thiết bị đo đếm",
  [MODULE_DODEM]: "Đo đếm và GNDN",
  [MODULE_GHICHISO]: "Ghi chỉ số",
  [MODULE_APGIA]: "Kiểm tra định mức Áp giá bán điện",
  [MODULE_BAOCAO]: "Báo cáo kinh doanh",
  [MODULE_LOTRINH]: "Quản lý lộ trình nhân viên",
  [MODULE_THANHTOAN]: "Thay đổi, đăng ký thanh toán",
  [MODULE_CHISOCONGTO]: "Kiểm tra chỉ số công tơ tháo",
  [MODULE_CHAMSOCKHACHHANG]: "Chăm sóc khách hàng",
  [MODULE_HETHONG]: "Quản trị hệ thống",
  [MODULE_TIENICHCHUNG]: "Tiện ích chung",
  [MODULE_TICHHOPCRM]: "Tích hợp CRM",
  [MODULE_KIEMTRACONGTO]: "Kiểm tra thiết bị đo đếm điện năng",
  [MODULE_TRUNG_AP]: "Trung áp",
};

//navigate parent

export const NAVIGATE_DICHVUDIEN = "/dich-vu-dien";
export const NAVIGATE_TREOTHAOTHIETBI = "/thiet-bi-do-dem";
export const NAVIGATE_DODEM = "/giao-nhan-dien-nang";
export const NAVIGATE_GHICHISO = "/ghi-chi-so";
export const NAVIGATE_KTDINHMUC_APGIA = "/kiem-tra-dinh-muc-ap-gia-ban-dien";
export const NAVIGATE_CAPDIENTRUNGAP = "/cap-dien-trung-ap";

export const NAVIGATE_BAOCAOTONGHOP = "/bao-cao-tong-hop";
export const NAVIGATE_BAOCAOCHITIET = "/bao-cao-chi-tiet";
export const NAVIGATE_BAOCAODSCHAMTIENDO = "/bao-cao-ds-cham-tien-do";
export const NAVIGATE_BAOCAOSOPHA = "/bao-cao-so-pha";
export const NAVIGATE_BAOCAODVDIEN = "/bao-cao-dv-dien";
export const NAVIGATE_BAOCAOBANDIEN = "/bao-cao-ban-dien";
export const NAVIGATE_BAOCAOKEHOACHTIEPNHAN = "/bao-cao-ke-hoach-tiep-nhan";
export const NAVIGATE_BAOCAOCAPDIEN89160 = "/bao-cao-89-160kVa";
export const NAVIGATE_BAOCAOTONGHOPHTLD = "/tong-hop-hinh-thuc-lap-dat";
export const NAVIGATE_BAOCAOCHITIETHTLD = "/chi-tiet-hinh-thuc-lap-dat";
export const NAVIGATE_BAOCAOTONGHOPCPQT = "/tong-hop-chi-phi-quyet-toan";
export const NAVIGATE_BAOCAOCHITIETCPQT = "/chi-tiet-chi-phi-quyet-toan";
export const NAVIGATE_BAOCAOTONGHOPKH = "/bao-cao-thong-tin-khach-hang";
export const NAVIGATE_BAOCAODSKH = "/bao-cao-danh-sach-khach-hang";
export const NAVIGATE_BAOCAOPHATTRIENTHEOLO = "/tinh-hinh-phat-trien-theo-lo";
export const NAVIGATE_BAOCAOLOGTHAOTAC = "/log-thao-tac";
export const NAVIGATE_BAOCAO = "/bao-cao-kinh-doanh";

export const NAVIGATE_LOTRINH = "/quan-ly-lo-trinh";
export const NAVIGATE_THANHTOAN = "/dang-ky-thanh-toan";
export const NAVIGATE_CHISOCONGTO = "/kiem-tra-chi-so-cong-to-thao";
export const NAVIGATE_CHAMSOCKHACHHANG = "/cham-soc-khach-hang";
export const NAVIGATE_HETHONG = "/quan-tri-he-thong";
export const NAVIGATE_GIAONHANDIENNANG = "/giao-nhan-dien-nang";
export const NAVIGATE_TIENICHCHUNG = "/tien-ich-chung";
export const NAVIGATE_TRUNGAP = "/trung-ap";
export const NAVIGATE_TRANGCHU = "/trang-chu";
export const NAVIGATE_DANGNHAP = "/dang-nhap";
export const NAVIGATE_DEFAULT_PAGE = "/default-page";

//cấp điện

export const NAVIGATE_DICHVUDIEN_GIAODICHVIEN = "/giao-dich-vien";
export const NAVIGATE_DICHVUDIEN_APGIA = "/ap-gia";
export const NAVIGATE_DICHVUDIEN_NHANVIENKHAOSAT = "/nhan-vien-khao-sat";
export const NAVIGATE_DICHVUDIEN_DOITRUONG = "/doi-truong";
export const NAVIGATE_DICHVUDIEN_KIEMDUYET = "/kiem-duyet";
export const NAVIGATE_DICHVUDIEN_PHONGKINHDOANH = "/phong-kinh-doanh";
export const NAVIGATE_DICHVUDIEN_LANHDAO = "/lanh-dao";
export const NAVIGATE_DICHVUDIEN_TAICHINH = "/tai-chinh";
export const NAVIGATE_DICHVUDIEN_TIENICH = "/tien-ich";
export const NAVIGATE_DICHVUDIEN_THICONG = "/thi-cong";
export const NAVIGATE_DICHVUDIEN_NGHIEMTHU = "/nghiem-thu";
export const NAVIGATE_DICHVUDIEN_QUYETTOAN = "/quyet-toan";
export const NAVIGATE_TIEPNHANPHANCONG = "/tiep-nhan-phan-cong";
export const NAVIGATE_HOSODAPHANCONG = "/ho-so-da-phan-cong";
export const NAVIGATE_HOSODANGTREOTHAO = "/ho-so-dang-treo-thao";
export const NAVIGATE_HOSODATRONGAI = "/quan-ly-ho-so-co-tro-ngai";
export const NAVIGATE_HOSOCOTRONGAI = "/ho-so-co-tro-ngai";
export const NAVIGATE_APGIAHOSO = "/ap-gia-ho-so";
export const NAVIGATE_LAPPHUONGAN = "/lap-phuong-an";
export const NAVIGATE_HOSODUOCPHANCONG = "/ho-so-duoc-phan-cong";
export const NAVIGATE_LAPBBLDDUONGDAY = "/lap-bbld-duong-day";
export const NAVIGATE_KHOIPHUCTHICONG = "/khoi-phuc-thi-cong";
export const NAVIGATE_DUYETHOSO = "/duyet-ho-so";
export const NAVIGATE_DUYETQUYETTOAN = "/duyet-quyet-toan";
export const NAVIGATE_XACNHANTHUTIEN = "/xac-nhan-thu-tien";
export const NAVIGATE_XACNHANTHUTIENNVKS = "/xac-nhan-thu-tien-nvks";
export const NAVIGATE_DONGBOTHUTIEN = "/dong-bo-thu-tien";
export const NAVIGATE_DONGBOTHOIGIAN = "/dong-bo-thoi-gian";
export const NAVIGATE_HIEUCHINHTIENDO = "/hieu-chinh-tien-do";
export const NAVIGATE_XACNHANTHICONG = "/xac-nhan-thi-cong";
export const NAVIGATE_NGHIEMTHUHOSO = "/nghiem-thu-ho-so";
export const NAVIGATE_LAPQUYETTOANCONGTY = "/lap-quyet-toan-cong-ty";
export const NAVIGATE_LAPQUYETTOANKHACHHANG = "/lap-quyet-toan-khach-hang";
export const NAVIGATE_XACNHANQUYETTOAN = "/xac-nhan-quyet-toan";
export const NAVIGATE_DICHVUDIEN_BAOCAO = "/bao-cao";
export const NAVIGATE_VATTU = "/vat-tu";
export const NAVIGATE_HOSO = "/ho-so";
export const NAVIGATE_DANHSACHVATTU = "/danh-sach-vat-tu";
export const NAVIGATE_HOSODANGKHAOSAT = "/ho-so-dang-khao-sat";
export const NAVIGATE_TRACUUHOSO = "/tra-cuu-ho-so";
export const NAVIGATE_XACNHANXUATVATTU = "/xac-nhan-xuat-vat-tu";
export const NAVIGATE_HOSOFLOW = "/cau-hinh-gui-mail";
export const NAVIGATE_CAUHINHHOSO = "/cau-hinh-ho-so";
export const NAVIGATE_THOIGIANLAMVIEC = "/thoi-gian-lam-viec";
export const NAVIGATE_TAIANHGIAYTOSCAN = "/tai-anh-giay-to-scan";
export const NAVIGATE_DONGBOYEUCAUCMIS = "/dong-bo-yeu-cau-cmis";
export const NAVIGATE_BAOCAOSOLUONGVATTU = "/tong-hop-sl-vat-tu";

export const TITLE_XACNHANXUATVATTU = "Xác nhận xuất vật tư";
export const TITLE_HOSOFLOW = "Hồ sơ FLOW";
export const TITLE_TRACUUHOSO = "Tra cứu hồ sơ";
export const TITLE_GIAODICHVIEN = "Giao dịch viên";
export const TITLE_PHANCONGTIEPNHAN = "Tiếp nhận - Phân công";
export const TITLE_HOSODAPHANCONG = "Hồ sơ đã phân công";
export const TITLE_HOSOTRONGAI = "Quản lý hồ sơ có trở ngại";
export const TITLE_APGIA = "Áp giá";
export const TITLE_APGIAHOSO = "Áp giá hồ sơ";
export const TITLE_NHANVIENKHAOSAT = "Nhân viên khảo sát";
export const TITLE_LAPPHUONGAN = "Lập phương án";
export const TITLE_HOSODUOCPHANCONG = "Hồ sơ được phân công";
export const TITLE_HOSODANGTREOTHAO = "Hồ sơ đang treo tháo";
export const TITLE_LAPBBLDDUONGDAY = "Lập biên bản lắp đặt đường dây";
export const TITLE_HOSODANGKHAOSAT = "Hồ sơ đang khảo sát";
export const TITLE_KHOIPHUCTHICONG = "Khôi phục thi công";
export const TITLE_DOITRUONG = "Đội trưởng";
export const TITLE_DUYETQUYETTOAN = "Duyệt quyết toán";
export const TITLE_KIEMDUYET = "Kiểm duyệt";
export const TITLE_PHONGKINHDOANH = "Phòng kinh doanh";
export const TITLE_LANHDAO = "Lãnh đạo";
export const TITLE_TAICHINH = "Tài chính";
export const TITLE_XACNHANTHUTIEN = "Xác nhận thu tiền";
export const TITLE_XACNHANTHUTIENNVKS = "Xác nhận thu tiền từ NVKS";
export const TITLE_DONGBOTHUTIEN = "Đồng bộ thu tiền";
export const TITLE_TIENICH = "Tiện ích";
export const TITLE_DONGBOTHOIGIAN = "Đồng bộ thời gian";
export const TITLE_THICONG = "Thi công";
export const TITLE_XACNHANTHICONG = "Xác nhận thi công";
export const TITLE_NGHIEMTHU = "Nghiệm thu";
export const TITLE_NGHIEMTHUHOSO = "Nghiệm thu hồ sơ";
export const TITLE_QUYETTOAN = "Quyết toán";
export const TITLE_LAPQUYETTOANCONGTY = "Lập quyết toán công ty";
export const TITLE_LAPQUYETTOANKHACHHANG = "Lập quyết toán khách hàng";
export const TITLE_XACNHANQUYETTOAN = "Xác nhận quyết toán";
export const TITLE_VATTU = "Vật tư";
export const TITLE_HOSO = "Cấu hình gửi mail";
export const TITLE_CAUHINHHOSO = "Cấu hình hồ sơ";
export const TITLE_THOIGIANLAMVIEC = "Thời gian làm việc";
export const TITLE_DANHSACHVATTU = "Danh sách vật tư";
export const TITLE_HIEUCHINHTIENDO = "Hiệu chỉnh tiến độ";
export const TITLE_BAOCAO = "Báo cáo";
export const TITLE_BAOCAOTONGHOP = "Báo cáo tổng hợp";
export const TITLE_BAOCAOCHITIET = "Báo cáo chi tiết";
export const TITLE_BAOCAODSCHAMTIENDO = "Báo cáo danh sách chậm tiến độ";
export const TITLE_BAOCAOSOPHA = "Báo cáo tổng hợp số pha";
export const TITLE_BAOCAODVDIEN = "Báo cáo tiếp nhận các dịch vụ điện";
export const TITLE_BAOCAOBANDIEN = "Tạo mới kế hoạch tiếp nhận bán điện";
export const TITLE_BAOCAOKEHOACHTIEPNHAN = "Báo cáo kế hoạch tiếp nhận";
export const TITLE_BAOCAOCAPDIEN89160 = "Báo cáo cấp điện 89 đến 160 kVA";
export const TITLE_BAOCAOTONGHOPHTLD = "Tổng hợp theo hình thức lắp đặt";
export const TITLE_BAOCAOCHITIETHTLD = "Chi tiết theo hình thức lắp đặt";
export const TITLE_BAOCAOTONGHOPCPQT = "Tổng hợp chi phí quyết toán";
export const TITLE_BAOCAOCHITIETCPQT = "Chi tiết chi phí quyết toán";
export const TITLE_BAOCAOTONGHOPKH = "Báo cáo tổng hợp thông tin khách hàng";
export const TITLE_BAOCAODSKH = "Báo cáo danh sách khách hàng";
export const TITLE_BAOCAOPHATTRIENTHEOLO =
  "Báo cáo tình hình phát triển theo lô";
export const TITLE_TONGHOPTRANGWEBCAPDIEN = "Tổng hợp trang web cấp điện";
export const TITLE_BAOCAOCHITIETCAPDIEN = "Báo cáo chi tiết cấp điện";
export const TITLE_BAOCAOTHEODOIPHATTRIENKHACHHANG =
  "Báo cáo theo dõi phát triển khách hàng";
export const TITLE_BAOCAOKHACHHANGPHIEN9 = "Báo cáo khách hàng phiên 9";
export const TITLE_BAOCAOLOGTHAOTAC = "Log thao tác";
export const TITLE_BAOCAOSOLUONGVATTU = "Báo cáo tổng hợp số lượng vật tư";
export const TITLE_TAIANHGIAYTOSCAN = "Tải ảnh giấy tờ scan";
export const TITLE_DONGBOYEUCAUCMIS = "Đồng bộ yêu cầu CMIS";

//NAVIGATION Treo Thao
export const NAVIGATE_TREOTHAO = "/treo-thao";
export const NAVIGATE_TREOTHAO_PHONGKD = "/pkd";
export const NAVIGATE_TREOTHAO_TIEPNHANYC = "/tiep-nhan-yeu-cau";
export const NAVIGATE_TREOTHAO_NGHIEMTHUYC = "/nghiem-thu-yeu-cau";
// treo thao - doi truong
export const NAVIGATE_TREOTHAO_DOITRUONG = "/doi-truong";
export const NAVIGATE_TREOTHAO_PHIEUDAXUATVT = "/phieu-xuat-vt";
export const NAVIGATE_TREOTHAO_DTDUYETPHIEUVT = "/duyet-phieu-vt";
export const NAVIGATE_TREOTHAO_PHIEUCHOTRAVT = "/phieu-tra-vt";
export const NAVIGATE_TREOTHAO_YCCANTRAVT = "/yc-can-tra-vt";
export const NAVIGATE_TREOTHAO_YCDAXUATVT = "/yc-da-xuat-vt";
export const NAVIGATE_TREOTHAO_YCCANXUATVT = "/yc-can-xuat-vt";
export const NAVIGATE_TREOTHAO_DTKYDUYETYC = "/ky-duyet";

// treo thao - nhân viên treo tháo
export const NAVIGATE_TREOTHAO_NVTREOTHAO = "/nhan-vien-treo-thao";
export const NAVIGATE_TREOTHAO_YCDUOCPHANCONG = "/yeu-cau-duoc-phan-cong";
export const NAVIGATE_TREOTHAO_YCDANGTREOTHAO = "/yeu-cau-dang-treo-thao";
export const NAVIGATE_TREOTHAO_YCKHCHUAKYSO = "/yc-kh-chua-ky-so";
export const NAVIGATE_TREOTHAO_NVKYDUYETYC = "/ky-duyet";

//Treo Thao - Thi Công
export const NAVIGATE_TREOTHAO_THICONG = "/thi-cong";
export const NAVIGATE_TREOTHAO_KETQUATC = "/ghi-nhan-kq-tc";
export const NAVIGATE_TREOTHAO_TRONGAI = "/cap-nhat-tro-ngai";
export const NAVIGATE_TREOTHAO_KHOIPHUCTC = "/khoi-phuc";

// treo thao - nv-antoan
export const NAVIGATE_TREOTHAO_NVANTOAN = "/nhan-vien-an-toan";
export const NAVIGATE_TREOTHAO_XACNHANANTOAN = "/xac-nhan-an-toan";

// treo thao - pvt
export const NAVIGATE_TREOTHAO_PHONGVATTU = "/phong-vat-tu";
export const NAVIGATE_TREOTHAO_DUYETPHIEUTRAVT = "/duyet-phieu-tra-vat-tu";
export const NAVIGATE_TREOTHAO_DUYETPHIEUXUATVT = "/duyet-phieu-xuat-vat-tu";
export const NAVIGATE_TREOTHAO_DUYETPHIEUVT = "/duyet-phieu-vt";

// treo thao - ld
export const NAVIGATE_TREOTHAO_LANHDAO = "/lanh-dao";
export const NAVIGATE_TREOTHAO_LDDUYETPHIEUTRAVT = "/duyet-phieu-tra-vat-tu";
export const NAVIGATE_TREOTHAO_LDDUYETPHIEUXUATVT = "/duyet-phieu-xuat-vat-tu";
export const NAVIGATE_TREOTHAO_LDKYDUYETYC = "/ky-duyet";
export const NAVIGATE_TREOTHAO_LDDUYETPHIEUVT = "/duyet-phieu-vt";

export const NAVIGATE_TREOTHAO_BAOCAO = "/bao-cao";
export const NAVIGATE_TREOTHAO_TIMKIEMTRACUU = "/tim-kiem-tra-cuu";
export const NAVIGATE_TREOTHAO_TRACUU = "/tra-cuu-yc";
export const NAVIGATE_TREOTHAO_TRACUUVT = "/tra-cuu-phieu-vt";

// Title Treo Thao Common
export const TITLE_TREOTHAO_DUYETPHIEUVT = "Duyệt phiếu vật tư";
// export const TITLE_TREOTHAO_DUYETYEUCAU = "Duyệt yêu cầu";
export const TITLE_TREOTHAO_XEMPHIEU = "Xem phiếu";
export const TITLE_TREOTHAO_KYSO = "Ký số";
export const TITLE_TREOTHAO_XACNHAN = "Xác nhận";
export const TITLE_TREOTHAO_XEMYC = "Xem yêu cầu";
//Start Treo Thao - Phòng Kinh Doanh
export const TITLE_TREOTHAO_PHONGKD = "Phòng kinh doanh";
export const TITLE_TREOTHAO_TIEPNHANYC = "Tiếp nhận yêu cầu";
export const TITLE_TREOTHAO_NGHIEMTHUYC = "Đồng bộ dữ liệu treo tháo về CMIS";
//End Treo Thao - Phòng Kinh Doanh
//export const TITLE_TREOTHAO_DONGBOCMIS = "Đồng bộ CMIS";
//Start Treo Thao - Nhân Viên Treo Tháo
export const TITLE_TREOTHAO_NVTREOTHAO = "Nhân viên treo tháo";
export const TITLE_TREOTHAO_YCDUOCPHANCONG = "Yêu cầu được phân công";
export const TITLE_TREOTHAO_YCDANGTREOTHAO = "Yêu cầu đang treo tháo";
export const TITLE_TREOTHAO_YCKHCHUAKYSO = "Yêu cầu khách hàng chưa ký số";
export const TITLE_TREOTHAO_NVKYDUYETYC = "Ký duyệt yêu cầu";
//End Treo Thao - Nhân Viên Treo Tháo

// Start Treo Thao - TITLE Đội Trưởng
export const TITLE_TREOTHAO_DOITRUONG = "Đội trưởng";
export const TITLE_TREOTHAO_PHIEUDAXUATVT = "Phiếu đề nghị xuất kho";
export const TITLE_TREOTHAO_YCCANTRAVT = " Yêu cầu cần trả vật tư";
export const TITLE_TREOTHAO_PHIEUCHOTRAVT = " Phiếu trả vật tư";
export const TITLE_TREOTHAO_YCDAXUATVT = "Yêu cầu chờ phân công";
export const TITLE_TREOTHAO_YCCANXUATVT = "Chờ tạo phiếu đề nghị xuất kho";
export const TITLE_TREOTHAO_DTKYDUYETYC = "Ký duyệt yêu cầu";
//End Treo Thao - TITLE Đội Trưởng

//Start Treo Thao - Title Nhân Viên An Toàn
export const TITLE_TREOTHAO_NVANTOAN = "Nhân viên an toàn";
export const TITLE_TREOTHAO_XACNHANANTOAN = "Xác nhận an toàn";
//End Treo Thao - Title Nhân Viên An Toàn

//Start Treo Thao - Title Thi Công
export const TITLE_TREOTHAO_THICONG = "Thi Công";
export const TITLE_TREOTHAO_KETQUATC = "Ghi nhận kết quả thi công";
export const TITLE_TREOTHAO_TRONGAI = "Cập nhật trở ngại";
export const TITLE_TREOTHAO_KHOIPHUCTC = "Khôi phục thi công";
//End Treo Thao - Title Thi Công

//Start Treo Thao - Title Phòng Vật Tư
export const TITLE_TREOTHAO_PHONGVATTU = "Phòng vật tư";
export const TITLE_TREOTHAO_DUYETPHIEUTRAVT = "Duyệt phiếu vật tư";
export const TITLE_TREOTHAO_DUYETPHIEUXUATVT = "Duyệt phiếu xuất vật tư";
//End Treo Thao - Title Phòng Vật Tư

//Start Treo Thao - Title Lãnh Đạo
export const TITLE_TREOTHAO_LANHDAO = "Lãnh đạo";
export const TITLE_TREOTHAO_LDDUYETPHIEUTRAVT = "Duyệt phiếu vật tư";
export const TITLE_TREOTHAO_LDDUYETPHIEUXUATVT = "Duyệt phiếu xuất vật tư";
export const TITLE_TREOTHAO_LDKYDUYETYC = "Ký duyệt yêu cầu";
export const TITLE_TREOTHAO_LDDUYETPHIEUVT = "Duyệt phiếu vật tư";
//End Treo Thao - Title Lãnh Đạo

//Start Treo Thao - Title Báo Cáo
export const TITLE_TREOTHAO_BAOCAO = "Báo cáo";
//End Treo Thao - Title Báo Cáo

//Start Treo Thao - Title Tìm Kiếm Tra Cứu
export const TITLE_TREOTHAO_TIMKIEMTRACUU = "Tìm kiếm tra cứu";
export const TITLE_TREOTHAO_TRACUU = "Tra cứu yêu cầu";
export const TITLE_TREOTHAO_TRACUUVT = "Tra cứu phiếu vật tư";
export const TITLE_TREOTHAO_XEMBB = "Xem biên bản";
//End Treo Thao - Title Tìm Kiếm Tra Cứu

// cấp điện giới tính

export const ONG = "1";
export const BA = "2";
export const CO_QUAN = "3";

//end cấp điện

export const ENUM_BUTTON_TYPE = {
  TIM_KIEM: 1,
  XUAT_EXCEL: 2,
  XUAT_EXCEL_CHI_TIET: 3,
  CHOT_DU_LIEU: 4,
  HUY_CHOT_DU_LIEU: 5,
};
//Start Hình thức trả tiền
export const HINH_THUC_KHAC = 4;
//End Hình thức trả tiền

//tiện ích chung

export const NAVIGATE_TIENICHCHUNG_DANHMUC = "/danh-muc";
export const NAVIGATE_TIENICHCHUNG_VATTU = "/vat-tu";
export const NAVIGATE_TIENICHCHUNG_CHUNGLOAIVATTU = "/chung-loai-vat-tu";
export const NAVIGATE_TIENICHCHUNG_DONVI = "/don-vi";
export const NAVIGATE_TIENICHCHUNG_DIACHINH = "/dia-chinh";
export const NAVIGATE_TIENICHCHUNG_IMEI = "/imei";
export const NAVIGATE_TIENICHCHUNG_TRAM = "/tram";
export const NAVIGATE_TIENICHCHUNG_LO = "/lo";
export const NAVIGATE_TIENICHCHUNG_NGANHANG = "/ngan-hang";
export const NAVIGATE_TIENICHCHUNG_DOI = "/doi";
export const NAVIGATE_TIENICHCHUNG_TILEBANDIEN = "/ti-le-ban-dien";
export const NAVIGATE_TIENICHCHUNG_UYQUYEN = "/uy-quyen";
export const NAVIGATE_TIENICHCHUNG_TINHTRANG = "/tinh-trang";
export const NAVIGATE_TIENICHCHUNG_TRANGTHAI = "/trang-thai";
export const NAVIGATE_TIENICHCHUNG_TRONGAI = "/tro-ngai";
export const NAVIGATE_TIENICHCHUNG_LOAIYEUCAU = "/loai-yeu-cau";
export const NAVIGATE_TIENICHCHUNG_EMAIL = "/email";
export const NAVIGATE_TIENICHCHUNG_CAUHINHCHUNG = "/cau-hinh-chung";
export const NAVIGATE_TIENICHCHUNG_CAUHINHDONGBO = "/cau-hinh-dong-bo";
export const NAVIGATE_TIENICHCHUNG_SOGHICHISO = "/so-ghi-chi-so";
export const NAVIGATE_TIENICHCHUNG_HINHTHUCLAPDAT = "/hinh-thuc-lap-dat";
export const NAVIGATE_TIENICHCHUNG_HINHTHUCLAPDATTHICONG =
  "/hinh-thuc-thi-cong";
export const NAVIGATE_TIENICHCHUNG_LOAIDIEMDO = "/loai-diem-do";
export const NAVIGATE_TIENICHCHUNG_LOAIGIAYTO = "/loai-ho-so";
export const NAVIGATE_TIENICHCHUNG_CHUNGLOAICONGTO = "/chung-loai-cong-to";
export const NAVIGATE_TIENICHCHUNG_LYDOTREOTHAO = "/ly-do-treo-thao";
export const NAVIGATE_TIENICHCHUNG_QLCHUNGTHU = "/quan-ly-chung-thu";
export const NAVIGATE_TIENICHCHUNG_NGANHNGHE = "/nganh-nghe";
export const NAVIGATE_TIENICHCHUNG_NHOMNGANHNGHE = "/nhom-nganh-nghe";
export const NAVIGATE_TIENICHCHUNG_GIANHOMNGANHNGHE = "/gia-nhom-nganh-nghe";
export const NAVIGATE_TIENICHCHUNG_THAMCHIEUCAPDIENAP =
  "/tham-chieu-cap-dien-ap";
export const NAVIGATE_TIENICHCHUNG_CAPDIENAP = "/cap-dien-ap";
export const NAVIGATE_CAPDIENVERSION = "/tien-ich-chung/cap-dien-version";
export const NAVIGATE_KIEMTRATHONGTINHANHVINANGLUC =
  "/tien-ich-chung/kiem-tra-thong-tin-hanh-vi-nang-luc";

export const TITLE_CHUNGLOAICONGTO = "Chủng loại công tơ";
export const TITLE_LYDOTREOTHAO = "Lý do treo tháo";
export const TITLE_QUANLYDANHMUC = "Quản lý danh mục";
export const TITLE_DANHMUCVATTU = "Vật tư";
export const TITLE_DANHMUCCHUNGLOAIVATTU = "Chủng loại vật tư";
export const TITLE_DANHMUCDONVI = "Đơn vị";
export const TITLE_DANHMUCDIACHINH = "Địa chính";
export const TITLE_DANHMUCIMEI = "IMEI";
export const TITLE_DANHMUCTRAM = "Trạm";
export const TITLE_DANHMUCLO = "Lộ";
export const TITLE_DANHMUCNGANHANG = "Ngân hàng";
export const TITLE_DANHMUCDOI = "Đội";
export const TITLE_DANHMUCTILEBANDIEN = "Tỉ lệ giá bán điện";
export const TITLE_DANHMUCUYQUYEN = "Ủy quyền";
export const TITLE_DANHMUCTINHTRANG = "Tình trạng";
export const TITLE_DANHMUCTRONGAI = "Trở ngại";
export const TITLE_DANHMUCLOAIYEUCAU = "Loại yêu cầu";
export const TITLE_DANHMUCEMAIL = "Email";
export const TITLE_DANHMUCCAUHINH = "Cấu hình chung";
export const TITLE_DANHMUCCAUHINHDONGBO = "Cấu hình đồng bộ";
export const TITLE_DANHMUCTRANGTHAI = "Trạng thái";
export const TITLE_DANHMUCCHISO = "Số ghi chỉ số";
export const TITLE_HINHTHUCLAPDAT = "Hình thức lắp đặt";
export const TITLE_HINHTHUCLAPDATTHICONG = "Hình thức thi công";
export const TITLE_LOAIDIEMDO = "Loại điểm đo";
export const TITLE_LOAIGIAYTO = "Loại hồ sơ";
export const TITLE_QL_CHUNG_THU = "Quản lý chứng thư";
export const TITILE_DANHMUCNGANHNGHE = "Ngành nghề";
export const TITILE_DANHMUCNHOMNGANHNGHE = "Nhóm ngành nghề";
export const TITILE_DANHMUCGIANHOMNGANHNGHE = "Giá nhóm ngành nghề";
export const TITILE_DANHMUCTHAMCHIEUCAPDIENAP = "Tham chiếu cấp điện áp";
export const TITILE_DANHMUCCAPDIENAP = "Cấp điện áp";
export const TITLE_CAPDIENVERSION = "Cấp điện Version";
export const TITLE_KIEMTRATHONGTINHANHVINANGLUC =
  "Kiểm tra thông tin hành vi, năng lực";
//end

//tích hợp crm
export const NAVIGATE_TICHHOPCRM = "/tich-hop-crm";

//ghi chi so
export const NAVIGATE_GHICHISO_BAOCAONHANH = "/bao-cao-nhanh";
export const NAVIGATE_GHICHISO_DIEUHANHGCS = "/dieu-hanh-ghi-chi-so";
export const NAVIGATE_GHICHISO_NHANVIENGCS = "/nhan-vien-ghi-chi-so";
export const NAVIGATE_GHICHISO_DOITRUONGGCS = "/doi-truong-ghi-chi-so";
export const NAVIGATE_GHICHISO_PHONGKINHDOANHGCS =
  "/phong-kinh-doanh-ghi-chi-so";
export const NAVIGATE_GHICHISO_INBANGKE = "/in-bang-ke";
export const NAVIGATE_GHICHISO_BAOCAO = "/bao-cao-ghi-chi-so";
export const NAVIGATE_GHICHISO_DANHMUCHETHONG = "/danh-muc-he-thong";
export const NAVIGATE_GHICHISO_CAUHINH = "/cau-hinh-ghi-chi-so";
export const NAVIGATE_GHICHISO_QUANTRIHETHONG = "/quan-tri-he-thong-gcs";
export const NAVIGATE_GHICHISO_TRACUUTIMKIEM = "/tra-cuu-tim-kiem";
export const NAVIGATE_DIEUHANHDOISOAT = "/doi-soat-anh-chi-so";
export const NAVIGATE_NHANSOTUCMIS = "/nhan-so-tu-cmis";
export const NAVIGATE_PHANCONGSOGCS = "/phan-cong-so-gcs";
export const NAVIGATE_KYBANGKE = "/ky-bang-ke";
export const NAVIGATE_DAYSOVECMIS = "/day-so-ve-cmis";
export const NAVIGATE_CAPNHATCHISOEVNHES = "/cap-nhat-chi-so-evn-hes";
export const NAVIGATE_DOISOATANHCHISO = "/doi-soat-anh-chi-so";
export const NAVIGATE_BKKIEMSOATCHISO = "/bang-ke-kiem-soat-chi-so";
export const NAVIGATE_BKCONGTOSLBT = "/bang-ke-cong-to-slbt";
export const NAVIGATE_XEMCHISOBKDANGPDF = "/xem-chi-so-bang-ke-pdf";
export const NAVIGATE_BKSANLUONGBATTHUONG = "/bang-ke-san-luong-bat-thuong";
export const NAVIGATE_BKPHUCTRACHISO = "/bang-ke-phuc-tra-chi-so";
export const NAVIGATE_BKLICHSUPHUCTRACHISO = "/bang-ke-lich-su-phuc-tra-chi-so";
export const NAVIGATE_PHIEUXACMINHCHISOCONGTO =
  "/phieu-xac-minh-chi-so-cong-to";
export const NAVIGATE_BKDANHSACHKHKHONGCHUPANH =
  "/bang-ke-khach-hang-khong-chup-anh";
export const NAVIGATE_BKDANHSACHKHKHONGDUOCDOISOATANH =
  "/bang-ke-khach-hang-khong-doi-soat-anh";
export const NAVIGATE_BKDANHSACHKHDADOISOAT = "/bang-ke-khach-da-doi-soat";
export const NAVIGATE_BKKHDOISOATDAT = "/bang-ke-khach-doi-soat-dat";
export const NAVIGATE_BKKHDOISOATKHONGDAT = "/bang-ke-khach-doi-soat-khong-dat";
export const NAVIGATE_BAOCAODSSOGCSTHEOTHANG = "/bao-cao-so-gcs-theo-thang";
export const NAVIGATE_TONGHOPGIAMSATLICHGCSTHEOTHANG = "/lich-gcs-theo-thang";
export const NAVIGATE_TONGHOPGIAMSATLICHGCSTHEONAM = "/lich-gcs-theo-nam";
export const NAVIGATE_BAOCAOGIAMSATLICHGCSTHEOSOSS2THANG =
  "/lich-gcs-theo-2-thang";
export const NAVIGATE_TONGHOPTHOIGIANTHUCHIENGCS = "/thoi-gian-thuc-hien-gcs";
export const NAVIGATE_TONGHOPANHCHUPGCS = "/anh-chup-gcs";
export const NAVIGATE_BAOCAOCHITIETANHCHUPGCS = "/chi-tiet-anh-chup-gcs";
export const NAVIGATE_BAOCAOQUANLYKYSOBANGKE = "/quan-ly-ky-so-bang-ke";
export const NAVIGATE_DANHMUCDOIGCS = "/danh-muc-doi";
export const NAVIGATE_PHANQUYENUSERIMEI = "/phan-quyen-user-imei";
export const NAVIGATE_QUANLYSOGCS = "/quan-ly-so-gcs";
export const NAVIGATE_CAUHINHDOI = "/cau-hinh-doi";
export const NAVIGATE_CAUHINHNHANVIENGCSMACDINH = "/nhan-vien-ghi-chi-so";
export const NAVIGATE_CAUHINHBANGKE = "/cau-hinh-bang-ke";
export const NAVIGATE_BOPHANKY = "/bo-phan-ky";
export const NAVIGATE_QUANLYCHUNGTHU = "/quan-ly-chung-thu";
export const NAVIGATE_CAUHINHTHAMSOHETHONG = "/cau-hinh-tham-so-he-thong";
export const NAVIGATE_QUANTRIQUYEN = "/quan-tri-quyen";
export const NAVIGATE_QUANTRIMENU = "/quan-tri-menu";
export const NAVIGATE_QUANTRITRANG = "/quan-tri-trang";
export const NAVIGATE_QUANTRINGUOIDUNG = "/quan-tri-nguoi-dung";
export const NAVIGATE_QUANTRIDONVI = "/quan-tri-don-vi";

export const TITLE_BAOCAONHANH = "Báo cáo nhanh";
export const TITLE_DIEUHANHGCS = "Điều hành GCS";
export const TITLE_NHANVIENGCS = "Nhân viên GCS";
export const TITLE_DOITRUONGGCS = "Đội trưởng";
export const TITLE_PHONGKINHDOANHGCS = "Phòng kinh doanh";
export const TITLE_INBANGKE = "In bảng kê";
export const TITLE_BAOCAOGCS = "Báo cáo";
export const TITLE_DANHMUCHETHONG = "Danh mục hệ thống";
export const TITLE_CAUHINH = "Cấu hình";
export const TITLE_QUANTRIHETHONG = "Quản trị hệ thống";
export const TITLE_TRACUUTIMKIEM = "Tra cứu - Tìm kiếm";

export const TITLE_NHANSOTUCMIS = "Nhận sổ từ CMIS";
export const TITLE_PHANCONGSOGCS = "Phân công sổ ghi chỉ số";
export const TITLE_KYBANGKE = "Ký bảng kê";
export const TITLE_DAYSOVECMIS = "Đẩy sổ về CMIS";
export const TITLE_CAPNHATCHISOEVNHES = "Cập nhập chỉ số từ EVN-HES";
export const TITLE_DOISOATANHCHISO = "Đối soát ảnh chỉ số";
export const TITLE_XEMCHISOBKDANGPDF = "Xem chỉ số bảng kê dạng pdf";
export const TITLE_BKSANLUONGBATTHUONG = "Bảng kê sản lượng bất thường";
export const TITLE_BKPHUCTRACHISO = "Bảng kê phúc tra chỉ số";
export const TITLE_BKLICHSUPHUCTRACHISO = "Bảng kê lịch sử phúc tra chỉ số";
export const TITLE_PHIEUXACMINHCHISOCONGTO = "Phiếu xác minh chỉ số công tơ";
export const TITLE_BKDANHSACHKHKHONGCHUPANH =
  "Bảng kê danh sách KH không chụp ảnh";
export const TITLE_BKDANHSACHKHKHONGDUOCDOISOATANH =
  "Bảng kê danh sách KH không được đối soát ảnh";
export const TITLE_BKDANHSACHKHDADOISOAT =
  "Bảng kê danh sách khách hàng đã đối soát";
export const TITLE_BKKHDOISOATDAT = "Bảng kê KH đối soát đạt";
export const TITLE_BKKHDOISOATKHONGDAT = "Bảng kê KH đối soát không đạt";
export const TITLE_BAOCAODSSOGCSTHEOTHANG =
  "Báo cáo danh sách sổ ghi chỉ số theo tháng";
export const TITLE_TONGHOPGIAMSATLICHGCSTHEOTHANG =
  "Tổng hợp giám sát lịch ghi chỉ số theo tháng";
export const TITLE_TONGHOPGIAMSATLICHGCSTHEONAM =
  "Tổng hợp giám sát lịch ghi chỉ số theo năm";
export const TITLE_BAOCAOGIAMSATLICHGCSTHEOSOSS2THANG =
  "Báo cáo giám sát lịch ghi chỉ số theo số so sánh 2 tháng";
export const TITLE_TONGHOPTHOIGIANTHUCHIENGCS =
  "Tổng hợp thời gian thực hiện sổ GCS";
export const TITLE_TONGHOPANHCHUPGCS = "Tổng hợp ảnh chụp ghi chỉ số";
export const TITLE_BAOCAOCHITIETANHCHUPGCS =
  "Báo cáo chi tiết ảnh chụp ghi chỉ số";
export const TITLE_BAOCAOQUANLYKYSOBANGKE = "Báo cáo quản lý ký số các bảng kê";
export const TITLE_DANHMUCDOIGCS = "Danh mục đội";
export const TITLE_PHANQUYENUSERIMEI = "Phân quyền User-IMEI";
export const TITLE_QUANLYSOGCS = "Quản lý sổ GCS";
export const TITLE_CAUHINHDOI = "Cấu hình đội";
export const TITLE_CAUHINHNHANVIENGCSMACDINH =
  "Cấu hình nhân viên ghi chỉ số mặc định";
export const TITLE_CAUHINHBANGKE = "Cấu hình bảng kê";
export const TITLE_CAUHINHNVGCS = "Cấu hình NV GCS mặc định";
export const TITLE_BOPHANKY = "Bộ phận ký";
export const TITLE_QUANLYCHUNGTHU = "Quản lý chứng thư";
export const TITLE_CAUHINHTHAMSOHETHONG = "Cấu hình tham số hệ thống";
export const TITLE_QUANTRIQUYEN = "Quản trị quyền";
export const TITLE_QUANTRIMENU = "Quản trị menu";
export const TITLE_QUANTRITRANG = "Quản trị trang";
export const TITLE_QUANTRINGUOIDUNG = "Quản trị người dùng";
export const TITLE_QUANTRIDONVI = "Quản trị đơn vị";

//end ghi chi so

//ap gia

export const NAVIGATE_APGIA_NHANVIENAPGIA = "/nhan-vien-ap-gia";
export const NAVIGATE_APGIA_BOPHANAPGIA = "/bo-phan-ap-gia";
export const NAVIGATE_APGIA_PKD = "/phong-kinh-doanh";
export const NAVIGATE_APGIA_LANHDAO = "/lanh-dao";
export const NAVIGATE_APGIA_TRACUU = "/tra-cuu-tim-kiem";
export const NAVIGATE_APGIA_CAUHINH = "/cau-hinh";

export const NAVIGATE_YC_PHANCONG = "/yeu-cau-duoc-phan-cong";
export const NAVIGATE_YC_DANGTHUCHIEN = "/yeu-cau-dang-thuc-hien";
export const NAVIGATE_YC_CHUAKY = "/yeu-cau-chua-ky";
export const NAVIGATE_NV_DUYETYEUCAU = "/duyet-yeu-cau";
export const NAVIGATE_NV_LAPBANGKE = "/lap-bang-ke";
export const NAVIGATE_NV_XACNHANBANGKE = "/xac-nhan-bang-ke";

export const NAVIGATE_BP_PHANCONG = "/phan-cong";
export const NAVIGATE_BP_DUYETYC = "/duyet-yeu-cau";
export const NAVIGATE_BP_DUYETBK = "/duyet-bang-ke";
export const NAVIGATE_BP_CHUYENKTGS = "/chuyen-kiem-tra-giam-sat";

export const NAVIGATE_PKD_LAYCMIS = "/lay-yeu-cau-tu-cmis";
export const NAVIGATE_PKD_DUYETYC = "/duyet-yeu-cau";
export const NAVIGATE_PKD_DONGBOCMIS = "/dong-bo-ve-cmis";
export const NAVIGATE_PKD_TN_PC = "/tiep-nhan-phan-cong";

export const NAVIGATE_LD_DUYETYC = "/duyet-yeu-cau";

export const NAVIGATE_TRACUU_YC = "/tra-cuu-yeu-cau";
export const NAVIGATE_TRACUU_BK = "/tra-cuu-bang-ke";

export const NAVIGATE_APGIA_CAUHINH_KYNHAY = "/cau-hinh-ky-nhay";

export const TITLE_NV_AP_GIA = "Nhân viên áp giá";
export const TITLE_YC_DUOCPHANCONG = "Yêu cầu được phân công";
export const TITLE_YC_DANGTHUCHIEN = "Yêu cầu đang thực hiện";
export const TITLE_YC_CHUAKY = "Yêu cầu khách hàng chưa ký";
export const TITLE_NV_DUYETYEUCAU = "Duyệt yêu cầu";
export const TITLE_NV_LAPBANGKE = "Lập bảng kê";
export const TITLE_NV_XACNHANBANGKE = "Xác nhận bảng kê";

export const TITLE_BP_APGIA = "Bộ phận áp giá";
export const TITLE_BP_PHANCONG = "Phân công";
export const TITLE_BP_DUYETYC = "Duyệt yêu cầu";
export const TITLE_BP_DUYETBK = "Duyệt bảng kê";
export const TITLE_BP_CHUYENKTGS = "Chuyển bộ phận kiểm tra giám sát";

export const TITLE_PKD_APGIA = "Phòng kinh doanh";
export const TITLE_PKD_LAYCMIS = "Lấy yêu cầu từ CMIS";
export const TITLE_PKD_DUYETYC = "Duyệt yêu cầu";
export const TITLE_PKD_DONGBOCMIS = "Đồng bộ về CMIS";
export const TITLE_PKD_TIEPNHAN_PHANCONG = "Tiếp nhận - Phân công";

export const TITLE_LANHDAO_APGIA = "Lãnh đạo";
export const TITLE_LD_DUYETYC = "Duyệt yêu cầu";

export const TITLE_TRACUU_TIMKIEM = "Tra cứu - Tìm kiếm";
export const TITLE_TRACUU_YC = "Tra cứu yêu cầu";
export const TITLE_TRACUU_BK = "Tra cứu bảng kê";

export const TITLE_AG_CAU_HINH = "Cấu hình";
export const TITLE_CAUHINH_KYNHAY = "Cấu hình ký nháy";

// end ap gia

// KTCS cong to thao
export const NAVIGATE_LAYDLCMIS_CTOTHAO =
  "/kiem-tra-chi-so-cong-to-thao/lay-cong-to-thao-tu-cmis";
export const NAVIGATE_CAPNHATCHISO_CTOTHAO =
  "/kiem-tra-chi-so-cong-to-thao/cap-nhat-chi-so";
export const NAVIGATE_BAOCAO_CTOTHAO = "/kiem-tra-chi-so-cong-to-thao/bao-cao";
export const NAVIGATE_CAUHINH_CTOTHAO =
  "/kiem-tra-chi-so-cong-to-thao/cau-hinh";
export const TITLE_LAYDLCMIS_CTOTHAO = "Lấy công tơ tháo từ CMIS";
export const TITLE_CAPNHATCHISO_CTOTHAO = "Cập nhật chỉ số công tơ tháo";
export const TITLE_BAOCAO_CTOTHAO = "Báo cáo";
export const TITLE_CAUHINH_CTOTHAO = "Cấu hình";
//end KTCS cong to thao

// KT THIET BI DO DIEM
export const NAVIGATE_KTCT = "/kiem-tra-cong-to";
export const TITILE_KTCT_BAOCAONHANH = "Báo cáo nhanh";

export const NAVIGATE_KTCT_PHONGKINHDOANH = "/phong-kinh-doanh";
export const NAVIGATE_KTCT_CONGVIECPHUCTRA = "/cv-phuc-tra";
export const NAVIGATE_KTCT_KYDUYETBANKE = "/ky-duyet-bang-ke";
export const NAVIGATE_KTCT_CONGVIECDINHKY = "/cv-dinh-ky";
export const NAVIGATE_KTCT_DONGBOCMIS = "/pt-day-ve-cmis";

export const NAVIGATE_KTCT_BPPHUCTRA = "/bo-phan-phuc-tra";

export const NAVIGATE_KTCT_DOITRUONG = "/doi-truong";

export const NAVIGATE_KTCT_NHANVIEN = "/nhan-vien";
export const NAVIGATE_KTCT_DK_CONGVIECDADKKT = "/lap-bang-ke";
export const NAVIGATE_KTCT_PT_CONGVIECDADKKT = "/cv-pt-da-kiem-tra";
export const NAVIGATE_KTCT_DSBANGKE = "/ds-bang-ke";
export const NAVIGATE_KTCT_BANGKECHOKY = "/bang-ke-cho-ky";
export const NAVIGATE_KTCT_CHISOEVNHES = "/chi-so-evn-hes";

export const NAVIGATE_KTCT_BAOCAO = "/bao-cao";
export const NATVIGATE_KTCT_BAOCAOCTBATTHUONG = "/bc-ct-bat-thuong";
export const NATVIGATE_KTCT_BAOCAOCTDKDAKT = "/bc-ct-ct-dk-da-kiem-tra";
export const NATVIGATE_KTCT_BAOCAOCTPTDAKT = "/bc-ct-ct-pt-da-kiem-tra";

export const NAVIGATE_KTCT_TRACUUTIMKIEM = "/tra-cuu-tim-kiem";

export const TITLE_KTCT_CVDINHKY = "Công việc định kỳ";
export const TITLE_KTCT_CVPHUCTRA = "Công việc phúc tra";
export const TITLE_KTCT_KYDUYETBANKE = "Ký duyệt bảng kê";
export const TITLE_KTCT_DONGBOCMIS = "Đồng bộ về CMIS";
export const TITLE_KTCT_CVPTDAKT = "Công việc phúc tra đã kiểm tra";
export const TITLE_KTCT_LAPBANGKE = "Lập bảng kê";
export const TITLE_KTCT_DSBANGKE = "Danh sách bảng kê";
export const TITLE_KTCT_BCCTBATTHUONG = "Báo cáo công tơ bất thường";
export const TITLE_KTCT_BANGKECHOKY = "Bảng kê chờ KH ký";
export const TITLE_KTCT_CHISOEVNHES = "Cập nhật chỉ số EVN HES";

export const TITLE_KTCT_BCCTDKDAKT =
  "Báo cáo chi tiết công tơ định kỳ đã kiểm tra";
export const TITLE_KTCT_BCCTPTDAKT =
  "Báo cáo chi tiết công tơ phúc tra đã kiểm tra";
export const TITLE_KTCT_TRACUUTIMKIEM = "Tra cứu tìm kiếm";
export const TITLE_KTCT_BAOCAO = "Báo cáo";
export const TITLE_KTCT_NHANVIEN = "Nhân viên";
export const TITLE_KTCT_DOITRUONG = "Đội trưởng";
export const TITLE_KTCT_PKD = "Phòng kinh doanh";
export const TITLE_KTCT_BPPT = "Bộ phận phúc tra";

//end KTCT

export const TITLE_TRANGCHU = "Trang chủ";
export const TITLE_DUYETHOSO = "Duyệt hồ sơ";

export const STEP_ONE = 1;
export const STEP_TWO = 2;
export const STEP_THREE = 3;
export const STEP_FOUR = 4;

export const STATUSCODE_200 = 200;
export const STATUSCODE_500 = 500;
export const STATUSCODE_400 = 400;
export const STATUSCODE_401 = 401;
export const METHOD_GET = "GET";
export const METHOD_POST = "POST";
export const METHOD_PUT = "PUT";
export const METHOD_DELETE = "DELETE";

export const ID_DOES_NOT_EXIST = "00000000-0000-0000-0000-000000000000";

// SSO
export const UNGDUNG_KINHDOANH = "UNGDUNG_KINHDOANH";

export const ENUM_FUNCTION = {
  TIEPNHAN: 0,
  PHANCONG: 1,
  TRONGAI: 2,
  APGIA: 3,
  DUOCPHANCONG: 4,
  DANGKHAOSAT: 5,
  LAPPHUONGAN: 6,
  TRONGAIKHAOSAT: 7,
  DOITRUONG: 8,
  KIEMDUYET: 9,
  KINHDOANH: 10,
  LANHDAODUYETHOSO: 11,
  LANHDAODUYETQUYETTOAN: 12,
  TAICHINH: 13,
  XACNHANXUATVATTU: 14,
  DANHMUCVATTU: 15,
  XACNHANTHICONG: 16,
  KHOIPHUCTHICONG: 17,
  XACNHANNGHIEMTHU: 18,
  LAPQUYETTOANCONGTY: 19,
  LAPQUYETTOANKHACHHANG: 20,
  XACNHANQUYETTOAN: 21,
  TRACUUHOSO: 22,
  XACNHANTHUTIENNVKS: 23,
  KHOIPHUCTAICHINH: 24,
  HSDUOCPHANCONG: 25,
  HSDANGTREOTHAO: 26,
  XACNHANANTOAN: 27,
};

export const ENUM_PHANCONG = {
  PHANCONG: 0,
  PHANCONGLAI: 1,
};
export const ENUM_VATTU = {
  MUANGOAI: 0,
  COSAN: 1,
};
export const ENUM_DIACHINH = {
  CAPMOT: 1,
  CAPHAI: 2,
};
export const ENUM_API = {
  HOSO: 1,
  DONGBOTHOIGIAN: 2,
};

export const STEP_XACNHAN_APGIA = {
  TIEPNHANPHANCONG: 1,
  APGIA: 2,
};

export const LOAI_TIMKIEM_GNDN = {
  TREO_THAO_DIEM_DO_DAU_NGUON: 1,
  KHONG_PHAI_TREO_THAO_DIEM_DO_DAU_NGUON: 0,
};

export const TYPE_LOAIYEUCAU_HOSO = {
  CAPDIEN: "CDHA_D",
  DIDOI: "DDOI_D",
  NANGCONGSUAT: "TCCS_D",
  CAPDIEN_SO: 2,
  DIDOI_SO: 3,
  NANGCONGSUAT_SO: 4,
};
export const ENUM_MODE_PHUONGANCAPDIEN = {
  XEMLAI: 1,
  XEMTRENTAB: 2,
};

export const LIST_TILEGIABANDIEN = {
  APGIA: 1,
  BANDIEN: 2,
};

export const HIDE_SELECT_APGIA = true;

export const TYPE_INPUT = {
  VND: "VND",
  DECIMAL: "DECIMAL",
  TWO_DECIMAL: "TWO_DECIMAL",
  PERCENT: "%",
  WATTAGE: "KWH",
  INTEGER: "INTEGER",
};

export const ENUM_MUCDICHSINHHOAT = {
  SINHHOAT: "0",
  NGOAISINHHOAT: "1",
};

export const ENUM_SOPHA = {
  NULL: 0,
  MOT_PHA: 1,
  BA_PHA: 3,
};

export const ENUM_TINHTRANGSUDUNG = {
  CHUA_CO_DIEN: 1,
  DUNG_CHUNG_CONG_TO: 2,
};

export const ENUM_HINHTHUCTRATIEN = {
  TIEN_MAT: 1,
  QUA_NGAN_HANG: 2,
  EVN_HANOI: 3,
  HINHTHUCKHAC: 4,
};

export const ENUM_HINHTHUCGCS = {
  TRUC_TIEP: 1,
  HHU: 2,
  TU_XA: 3,
};

export const ENUM_LOAIHOPDONG = {
  SINHHOAT: 0,
  NGOAISINHHOAT: 1,
};

export const ENUM_TINHTRANGTHU = {
  DU_LIEU2020: 0,
  DA_THU: 1,
  CHUA_THU: 2,
  KHONG_PHAI_THU: 3,
  THU_NVKS: 4,
};
export const ENUM_TRANGTHAIPHANCONG = {
  CHUA_PHAN_CONG: 1,
  DA_PHAN_CONG: 2,
  CHUA_PHAN_CONG_TEXT: "Chưa phân công",
  DA_PHAN_CONG_TEXT: "Đã phân công",
};
export const ENUM_GCSTRANGTHAISO = {
  DA_LAY_LICH_GCS_TU_CMIS: 0, // chưa lấy
  DA_LAY_BIEU_TU_CMIS: 1, // đã lấy / chưa phan công
  DA_PHAN_CONG: 2, // đã phân công / chưa thực hiện
  DANG_CAP_NHAT_MDMS: 3, // đang thực hiện
  KET_THUC_MDMS_CHUYEN_MTB_HHU: 4, // chờ chuyển HHU/MTB
  DA_CAP_NHAT_DU_MDMS: 5, // đã đủ chỉ số
  HHU_DA_LAY_DU_LIEU: 6, // đã chuyển HHU
  HHU_DA_GUI_DU_DL: 8, // đã đủ chỉ số
  XUAT_GHI_LAI_BANG_MTB: 9, // đang thực hiện
  CHUYEN_LAY_DL_BANG_MTB: 10, // đang thực hiện
  DA_LAY_SO_VE_MTB: 11, // đang thực hiện
  MTB_DA_GUI_DU_LIEU: 12, // đã đủ chỉ số
  HOAN_TAT_DOI_SOAT_ANH_KHONG_DAT: 14, // đã hoàn thành đối soát
  DANG_XAC_NHAN_CAC_CAP: 15, // đã hoàn thành đối soát
  HOAN_THANH_XAC_NHAN_SO: 16, // chưa đẩy về cmis
  XUAT_GHI_LAI_BANG_MDMS: 17, // đang thực hiện
  HOAN_THANH_DAY_VE_CMIS: 18, // đã đẩy về cmis
  HOAN_TAT_DS_TU_DONG: 19, // đã đối soát tự động
};
export const ENUM_QL_CHI_SO_GNDN_LOAI_NHAP = {
  LAY_DO_XA: { value: 0, title: "Lấy đo xa" }, // Loại nhập đo xa
  NHAP_TAY: { value: 1, title: "Nhập tay" }, // Loại nhập tay
  CHUA_NHAP: { value: null, title: "Chưa nhập" }, // Chưa nhập
  ALL: { value: "All", title: "Tất cả" },
};
export const ENUM_HINHTHUCGCSCMIS = {
  HHU: "HHU",
  MDMS: "MDMS",
  MTB: "MTB",
  FORM: "FORM",
};
export const ENUM_KY_GCS = {
  KY_1: 1,
  KY_2: 2,
  KY_3: 3,
};
export const ENUM_DS_INBK = {
  KH_KHONG_CHUP_ANH: 1,
  KH_KHONG_DUOC_DS: 2,
  KH_DA_DS: 3,
  KH_DS_DAT: 4,
  KH_DS_KHONG_DAT: 5,
};

export const ENUM_TRANGTHAI_KBK = {
  DA_KY: 1,
  CHUA_KY: 2,
};

export const ENUM_TRANGTHAI_NVKBK = {
  CHUA_KY: 0,
  DA_KY: 1,
};

export const ENUM_TRANGTHAI_DTKBK = {
  CHUA_KY: 1,
  DA_KY: 2,
};

export const ENUM_TRANGTHAI_DHKBK = {
  CHUA_KY: 2,
  DA_KY: 3,
};

export const ENUM_TRANGTHAI_PKDKBK = {
  CHUA_KY: 3,
  DA_KY: 4,
};

export const ENUM_LOAI_BANG_KE = {
  BKCS: "BKCS",
  SLBT: "SLBT",
  TTBT: "TTBT",
};

export const ENUM_LOC_CONG_TO = {
  CONG_TO_DAT: "CHECK",
  CONG_TO_KHONG_DAT: "UNCHECK",
  CONG_TO_CHUA_DOI_SOAT: "CHUA_DOI_SOAT",
  CONG_TO_CO_ANH: "CONG_TO_CO_ANH",
  DU_CHI_SO: "DU_CHI_SO",
  CHUA_DU_CHI_SO: "CHUA_DU_CHI_SO",
};

export const ENUM_DUTRU = {
  DU_TOAN: 0,
  KHAI_GIA: 1,
};

export const QUAN_LY_LOG = {
  HE_THONG: 1,
  NOI_BO: 2,
  HE_THONG_NGOAI: 3,
};

export const MATRIX_CAP_DIEN = {
  TIEPNHANPHANCONG: [
    [0, 1],
    [
      [0, 1], //Cap nhat
      [1], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [0, 1], //Phân công
      [1], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [0, 1], //Chuyển đơn vị
      [0, 1], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  HOSODAPHANCONG: [
    [2],
    [
      [2], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [2], //Phân công
      [2], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [2], //Chuyển đơn vị
      [2], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  QUANLYHSCOTRONGAI: [
    [-3, 4, -8, -9, -10],
    [
      [], //Cap nhat
      [], //Xoa
      [-3], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [-8, -9, -10], //Phân công lại
      [4], //Khảo sát lại
      [-10], //Chuyển đơn vị
      [], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  APGIAHOSO: [
    [],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  HSDADUOCPHANCONG: [
    [2],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  HSDANGKHAOSAT: [
    [3],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [3], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  LAPPHUONGAN: [
    [5],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [5], //Tải ảnh
      [5], //Lập phương án
      [5], //Trở ngại
      [5], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  HOSOCOTRONGAI: [
    [4],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [4], //Khảo sát lại
      [], //Chuyển đơn vị
      [], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  DOITRUONG_DUYETHS: [
    [7],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [7], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [7], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  KIEMDUYET_DUYETHS: [
    [8],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [8], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [], //Ký duyệt hồ sơ
      [8], //Xác nhận hồ sơ
      [8], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  PKD_DUYETHS: [
    [9],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [9], //Ký duyệt hồ sơ
      [9], //Xác nhận hồ sơ
      [9], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  LD_DUYETHS: [
    [10],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [], //Ký duyệt hồ sơ
      [10], //Xác nhận hồ sơ
      [10], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  LD_DUYETQUYETTOAN: [
    [21],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [21], //Ký quyết toán Usb
      [21], //Ký quyết toán hsm
      [21], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  XACNHANTHUTIEN: [
    [12],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [], //Tải ảnh
      [], //Lập phương án
      [12], //Trở ngại
      [], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [12], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  XACNHANTHUTIENNVKS: [
    [],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [999], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [999], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  KHOIPHUCTAICHINH: [
    [-6],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [-6], //Khôi phục tài chính
    ],
  ],
  XACNHANXUATVATTU: [
    [13, 14],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [13, 14], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  XACNHANTHICONG: [
    [15],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [], //Tải ảnh
      [], //Lập phương án
      [15], //Trở ngại
      [], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [15], //Xác nhận thi công
      [15], //Lập BB 1 giá
      [15], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  KHOIPHUCTHICONG: [
    [-5],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [-5], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  NGHIEMTHUHOSO: [
    [17],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [17], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [17], //Xác nhận nghiệm thu
      [17], //Trả đội thi công
      [17], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  LAPQUYETTOANCTY: [
    [18],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [18], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  LAPQUYETTOANKH: [
    [19],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [19], //Lập quyết toán khách hàng
      [19], //Trả lại quyết toán nội bộ
      [], //Xác nhận quyết toán
      [], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  XACNHANQUYETTOAN: [
    [20],
    [
      [], //Cap nhat
      [], //Xoa
      [], //Huy
      [], //Làm mới hồ sơ
      [], //Phân công
      [], //Xác nhận chuyển áp giá
      [], //Phân công lại
      [], //Khảo sát lại
      [], //Chuyển đơn vị
      [], //Tải ảnh
      [], //Lập phương án
      [], //Trở ngại
      [], //Ký duyệt hồ sơ
      [], //Xác nhận hồ sơ
      [], //Trả lại hồ sơ
      [], //Ký quyết toán Usb
      [], //Ký quyết toán hsm
      [], //Từ chối quyết toán
      [], //Xác nhận thu tiền
      [], //Xác nhận xuất vật tư
      [], //Lập quyết toán cty
      [], //Lập quyết toán khách hàng
      [], //Trả lại quyết toán nội bộ
      [20], //Xác nhận quyết toán
      [20], //Từ chối quyết toán
      [], //Xác nhận thi công
      [], //Lập BB 1 giá
      [], //Lập BB nhiều giá
      [], //Khôi phục thi công
      [], //Xác nhận nghiệm thu
      [], //Trả đội thi công
      [], //Xác nhận thu tiền từ NVKS
      [], //Khôi phục tài chính
    ],
  ],
  //HSDAPHANCONG
  HSDAPHANCONG: [
    [25],
    [
      [25], //Cap nhat
      [25], //Xoa
      [25], //Huy
      [25], //Làm mới hồ sơ
      [25], //Phân công
      [25], //Xác nhận chuyển áp giá
      [25], //Phân công lại
      [25], //Khảo sát lại
      [25], //Chuyển đơn vị
      [25], //Tải ảnh
      [25], //Lập phương án
      [25], //Trở ngại
      [25], //Ký duyệt hồ sơ
      [25], //Xác nhận hồ sơ
      [25], //Trả lại hồ sơ
      [25], //Ký quyết toán Usb
      [25], //Ký quyết toán hsm
      [25], //Từ chối quyết toán
      [25], //Xác nhận thu tiền
      [25], //Xác nhận xuất vật tư
      [25], //Lập quyết toán cty
      [25], //Lập quyết toán khách hàng
      [25], //Trả lại quyết toán nội bộ
      [25], //Xác nhận quyết toán
      [25], //Từ chối quyết toán
      [25], //Xác nhận thi công
      [25], //Lập BB 1 giá
      [25], //Lập BB nhiều giá
      [25], //Khôi phục thi công
      [25], //Xác nhận nghiệm thu
      [25], //Trả đội thi công
      [25], //Xác nhận thu tiền từ NVKS
      [25], //Khôi phục tài chính
    ],
  ],
  HSDANGTHICONG: [
    [26],
    [
      [26], //Cap nhat
      [26], //Xoa
      [26], //Huy
      [26], //Làm mới hồ sơ
      [26], //Phân công
      [26], //Xác nhận chuyển áp giá
      [26], //Phân công lại
      [26], //Khảo sát lại
      [26], //Chuyển đơn vị
      [26], //Tải ảnh
      [26], //Lập phương án
      [26], //Trở ngại
      [26], //Ký duyệt hồ sơ
      [26], //Xác nhận hồ sơ
      [26], //Trả lại hồ sơ
      [26], //Ký quyết toán Usb
      [26], //Ký quyết toán hsm
      [26], //Từ chối quyết toán
      [26], //Xác nhận thu tiền
      [26], //Xác nhận xuất vật tư
      [26], //Lập quyết toán cty
      [26], //Lập quyết toán khách hàng
      [26], //Trả lại quyết toán nội bộ
      [26], //Xác nhận quyết toán
      [26], //Từ chối quyết toán
      [26], //Xác nhận thi công
      [26], //Lập BB 1 giá
      [26], //Lập BB nhiều giá
      [26], //Khôi phục thi công
      [26], //Xác nhận nghiệm thu
      [26], //Trả đội thi công
      [26], //Xác nhận thu tiền từ NVKS
      [26], //Khôi phục tài chính
    ],
  ],

  //XACNHANANTOAN
  XACNHANANTOAN: [
    [24],
    [
      [24], //Cap nhat
      [24], //Xoa
      [24], //Huy
      [24], //Làm mới hồ sơ
      [24], //Phân công
      [24], //Xác nhận chuyển áp giá
      [24], //Phân công lại
      [24], //Khảo sát lại
      [24], //Chuyển đơn vị
      [24], //Tải ảnh
      [24], //Lập phương án
      [24], //Trở ngại
      [24], //Ký duyệt hồ sơ
      [24], //Xác nhận hồ sơ
      [24], //Trả lại hồ sơ
      [24], //Ký quyết toán Usb
      [24], //Ký quyết toán hsm
      [24], //Từ chối quyết toán
      [24], //Xác nhận thu tiền
      [24], //Xác nhận xuất vật tư
      [24], //Lập quyết toán cty
      [24], //Lập quyết toán khách hàng
      [24], //Trả lại quyết toán nội bộ
      [24], //Xác nhận quyết toán
      [24], //Từ chối quyết toán
      [24], //Xác nhận thi công
      [24], //Lập BB 1 giá
      [24], //Lập BB nhiều giá
      [24], //Khôi phục thi công
      [24], //Xác nhận nghiệm thu
      [24], //Trả đội thi công
      [24], //Xác nhận thu tiền từ NVKS
      [24], //Khôi phục tài chính
    ],
  ],
};

export const TABINDEX_CMIS = {
  LOAI_CTO: 1,
  LOAI_DDO: 2,
  LOAI_HSO: 3,
  LY_DO: 4,
  NGAN_HANG: 5,
  TRO_NGAI: 6,
  DVI_DIACHINH: 7,
  DVI_QLY: 8,
  TO: 9,
  TRAM: 10,
  VAT_TU: 11,
  LO: 12,
  UY_QUYEN: 17,
  NHOM_NGANH_NGHE: 19,
  NGANH_NGHE: 20,
  GIA_NHOM_NGANH_NGHE: 21,
  CAP_DIEN_AP: 22,
  THAM_CHIEU_CAP_DIEN_AP: 23,
};

export const BOCHISO = {
  KT: "KT",
  KTVS: "KT;VC",
  KTKN: "KT;KN",
  BN: "BN",
  CN: "CN",
  TN: "TN",
};

export const LITMIT_TRANGTHAI = 5;

export const SEQUENCE_TRANGTHAI = {
  CAPNHAT: 0,
  XOA: 1,
  HUY: 2,
  LAMMOIHS: 3,
  PHANCONG: 4,
  XACNHANCHUYENAPGIA: 5,
  PHANCONGLAI: 6,
  KHAOSATLAI: 7,
  CHUYENDONVI: 8,
  TAIANH: 9,
  LAPPHUONGAN: 10,
  TRONGAI: 11,
  KYDUYETHS: 12,
  XACNHANHS: 13,
  TRALAIHS: 14,
  KYQUYETTOANUSB: 15,
  KYQUYETTOANHSM: 16,
  TUCHOIQUYETTOAN: 17,
  XACNHANTHUTIEN: 18,
  XACNHANXUATVATTU: 19,
  LAPQUYETTOANCTY: 20,
  LAPQUYETTOANKH: 21,
  TRALAIQUYETTOANNOIBO: 22,
  XACNHANQUYETTOAN: 23,
  _TUCHOIQUYETTOAN: 24,
  XACNHANTHICONG: 25,
  LAPBB1GIA: 26,
  LAPBBNHIEUGIA: 27,
  KHOIPHUCTHICONG: 28,
  XACNHANNGHIEMTHU: 29,
  TRADOITHICONG: 30,
  XACNHANTHUTIENTUNVKS: 31,
  KHOIPHUCTAICHINH: 32,
};

export const MAX_INT = 2147483648;
export const ERROR_MAX_INT = "Không được phép nhập quá 10 kí tự";
export const OS = {
  IOS: "ios",
  ANDROID: "android",
  IOS_NUM: 1,
  ANDROID_NUM: 2,
};

export const SHOW_UPLOAD = [0, 1, 2, 5, 8, 17];
export const SHOW_UPLOAD_SCAN = [12, 13, 14, 15, 24, 26, 17, 25, 18, 19,20,21,22];
export const MUC_DICH = {
  SINHHOAT: "Sinh hoạt",
  NGOAISINHHOAT: "Ngoài sinh hoạt",
  SINHHOAT_NUM: 0,
  NGOAISINHHOAT_NUM: 1,
};

export const ENUM_QUY = {
  QUY1: "1",
  QUY2: "4",
  QUY3: "7",
  QUY4: "10",
};

export const CAP_BAC_KY = {
  TO_TRUONG: 2,
  NV_DIEU_HANH: 3,
  PHONG_KINH_DOANH: 4,
};

export const TYPE_CHI_PHI = {
  CAP_NGAM: 1,
  DDK: 2,
  KHAC: 3,
};

//table
export const KHONGCODULIEU_TABLE = {
  emptyText: "Trống",
};

export const LOAI_CHI_PHI = {
  CP_CHUNG_DM: "ĐM22/2020",
  CP_CHUNG_TT: "TT10/2019",
  CP_CHUNG_DM_4970: "ĐM4970",
};

export const LOAI_BAO_CAO = {
  THANG: "Tháng",
  QUY: "Quý",
  NAM: "Năm",
};

export const COLUMNS_TITLE = {
  DONVI: "Đơn vị",
  LOAIYEUCAU: "Loại yêu cầu",
};

export const MIN_WIDTH = {
  WIDTH_100_PHANTRAM: "100%",
  WIDTH_50_PHANTRAM: "50%",
  WIDTH_33_PHANTRAM: "33.33%",
  WIDTH_25_PHANTRAM: "25%",
};

export const TYPEPOPUP_TRONGAI = {
  TYPE_NVKS: 1,
  TYPE_TAICHINH: 2,
  TYPE_THICONG: 3,
};

export const TYPE_DONGBO = {
  THUTIEN: 1,
  THOIGIAN: 2,
};

export const SPECIAL_CHARACTERS = [
  "`",
  "!",
  "@",
  "#",
  "$",
  "%",
  "^",
  "&",
  "*",
  "(",
  ")",
  "_",
  "+",
  "-",
  "=",
  "[",
  "]",
  "{",
  "}",
  ";",
  "'",
  ":",
  '"',
  "|",
  ",",
  "<",
  ">",
  "/",
  "?",
  "\\",
  "~",
];

export const TYPE_HIDE_SHOW_COLUMNS = {
  CHITIETCHIPHIQUYETTOAN: 1,
  BAOCAOCHITIET: 2,
  CHITIETHINHTHUCLAPDAT: 3,
  CHAMTIENDO: 4,
};

export const CMIS_TREOTHAO_THICONG = {
  CO_CONGTOTHAO: 1,
  CO_CONGTOTREO: 2,
};

export const BCS_CONG_TO = {
  KT: {
    sequence: 0,
    name: "KT",
  },
  KTVC: {
    sequence: 1,
    name: "KT;VC",
  },
  KTKN: {
    sequence: 2,
    name: "KT;KN",
  },
  BN: {
    sequence: 3,
    name: "BN",
  },
  CN: {
    sequence: 3,
    name: "CN",
  },
  TN: {
    sequence: 3,
    name: "TN",
  },
  OTHER: {
    sequence: 4,
    name: "unknow",
  },
};

export const CS_CONG_TO = {
  CS_BINHTHUONG: 0,
  CS_CAODIEM: 1,
  CS_THAPDIEM: 2,
  CS_P: 3,
  CS_Q: 4,
};

export const THONG_TIN_CONG_TO = {
  KENH_GIAO: [
    { [CS_CONG_TO.CS_BINHTHUONG]: [3, 4] },
    { [CS_CONG_TO.CS_CAODIEM]: [3, 4] },
    { [CS_CONG_TO.CS_THAPDIEM]: [3, 4] },
    { [CS_CONG_TO.CS_P]: [0, 1, 2, 3, 4] },
    { [CS_CONG_TO.CS_Q]: [1, 3, 4] },
  ],
  KENH_NHAN: [
    { [CS_CONG_TO.CS_BINHTHUONG]: [3] },
    { [CS_CONG_TO.CS_CAODIEM]: [3] },
    { [CS_CONG_TO.CS_THAPDIEM]: [3] },
    { [CS_CONG_TO.CS_P]: [2, 3] },
    { [CS_CONG_TO.CS_Q]: [3] },
  ],
};

//
export const LOAIBAOCAO = [
  {
    value: 1,
    name: "Tháng",
  },
  {
    value: 2,
    name: "Quý",
  },
  {
    value: 3,
    name: "Năm",
  },
  {
    value: 4,
    name: "Khác",
  },
];

export const LOAICHAMTIENDO = [
  {
    value: null,
    name: "Tất cả",
  },
  {
    value: 1,
    name: "3 ngày",
  },
  {
    value: 2,
    name: "5 ngày",
  },
  {
    value: 3,
    name: "7 ngày",
  },
];

export const TYPE_CHANGE_DATE = {
  TUNGAY: 1,
  DENNGAY: 2,
  THANGKIEMTRA: 3,
};

export const TYPE_BAOCAO_HA_AP = {
  CHITIET: 1,
  TRAM: 2,
  MOI: 3,
  NANGCAP: 4,
  CHUYENDUNG: 5,
};
export const TYPE_BIEN_BAN = {
  QUYETTOAN_KH: "Biên bản quyết toán khách hàng",
  QUYETTOAN_CTY: "Biên bản quyết toán công ty",
  KHAI_GIA: "Biên bản khai giá",
  AP_GIA: "Biên bản áp giá",
};

export const TYPE_TRANGTHAIBAOCAO_TONGHOP = {
  DACHOT: "da_chot",
  CHUACHOT: "chua_chot",
  CHOTMOTPHAN: "chot_1_phan",
};

//treo tháo

export const TYPE_XACNHAN_GUIPHIEU = {
  PHIEUXUATKHO: 1,
  PHIEUTHUHOI: 2,
  PHIEUTRAVATTU: 3,
};

export const TYPE_PHIEU = {
  THUHOIVATTU: 1,
  TRAVATTU: 2,
  XUATKHO: 3,
};

// áp giá

export const ENUM_KY_NHAY = {
  ALL: 0,
  PAG: 1,
  PKD: 2,
};

export const ENUM_MUCDICH_SDD = {
  SINHHOAT: "0",
  NGOAISINHHOAT: "1",
};

export const ENUM_LOAI_KIEM_TRA = {
  DINHMUC: "0",
  APGIA: "1",
};

export const ENUM_TRANGTHAI_YC_CMIS = {
  CHUA_LAY: 1,
  DA_LAY: 2,
  DA_PHAN_CONG: 3
};

export const ENUM_TRANGTHAI_BKAG_NV = {
  CHUA_KY: 0,
  DA_KY: 1,
};

export const ENUM_TRANGTHAI_BKAG_DT = {
  CHUA_KY: 1,
  DA_KY: 2,
};

export const ENUM_TRANGTHAI_BIEN_BAN = {
  CHUA_KY: 0,
  DA_KY: 1,
};

export const ENUM_TRANGTHAI_GUI_CMIS = {
  DA_GUI: 2,
  CHUA_GUI: 1,
};

export const AP_GIA_TRANG_THAI = {
  CHUA_LAY: "0",
  DA_LAY_TU_WEB: "1",
  DA_PHAN_CONG: "2",
  DANG_THUC_HIEN: "3",
  MTB_DA_GUI_DL: "4",
  NVAG_DA_XAC_NHAN: "5",
  BPAG_DA_XAC_NHAN: "6",
  PKD_DA_XAC_NHAN: "7",
  LD_DA_XAC_NHAN: "8",
  DA_DB_CMIS: "9",
  PKD_DA_PHAN_CONG: "10",
};

export const AP_GIA_TRANG_THAI_BK = {
  CHO_NVAG_DUYET: "0",
  CHO_BPAG_DUYET: "1",
  BPAG_DA_DUYET: "2",
};

export const AP_GIA_KTGS = {
  DA_CHUYEN_KTGS: "1",
  CHUA_CHUYEN_KTGS: "0",
};

export const AP_GIA_DB_WEB = {
  DA_DB: "1",
  CHUA_DB: "0",
};

export const ENUM_TRANGTHAI_HS_PHANCONG = {
  DA_PHAN_CONG: 1,
  DA_LAY_VE_MTB: 2,
};

export const ENUM_MA_TRANGTHAI_HS_PHANCONG = {
  DA_PHAN_CONG: 3,
  DA_LAY_VE_MTB: 4,
};

// Kiểm tra chỉ số công tơ tháo

export const ENUM_TRANGTHAI_DOISOAT_CSCTT = {
  DAT: "CHECK",
  KHONG_DAT: "UNCHECK",
  DU_CHI_SO: "DU_CHI_SO",
  CHUA_DU_CHI_SO: "CHUA_DU_CHI_SO",
};

export const TYPE_PHIEU_VATTU = {
  THUHOI: "1",
  TRA: "2",
  XUAT: "3",
};

export const ENUM_LOAIBAOCAO_VATTU = {
  VATTUKH: "1",
  VATTUCTY: "2",
};

//treo tháo
export const ENUM_DS_YEU_CAU = {
  PKD_TIEPNHANYEUCAU: 1,
  PKD_NGHIEMTHUYEUCAU: 2,
  DOITRUONG_YEUCAUCANXUATVATTU: 3,
  DOITRUONG_YEUCAUCANTRAVATTU: 4,
  DOITRUONG_YEUCAUDAXUATVATTU: 5,
  DOITRUONG_KYDUYETYEUCAU: 6,
  NVTREOTHAO_YCDANGTREOTHAO: 7,
  NVTREOTHAO_YCPHANCONG: 8,
  NVTREOTHAO_KYSO: 9,
  NVTREOTHAO_KYDUYETYEUCAU: 10,
  LANHDAO_KYDUYETYEUCAU: 11,
  TIMKIEMTRACUU: 12,
  NVANTOAN_XACNHANANTOAN: 13,
  TIMKIEMTRACUU_PHIEUVT: 14,
};
//Function Phiếu vật tư
export const ENUM_PHIEU_VAT_TU = {
  //Phòng kinh doanh
  PKDDUYETPHIEUVATTU: 6,
  //Đội trưởng
  DTPHIEUXUATVT: 0, //Phiếu xuất vật tư
  DTPHIEUTRAVT: 1, //Phiếu trả vật tư
  DTDUYETPHIEUVT: 2, //Duyệt phiếu vật tư

  //Phòng vật tư
  PVTDUYETPHIEUVT: 3, //Duyệt phiếu vật tư
  PVTXACNHANXUATVT: 7, //xác nhận phiếu vật tư
  //Lãnh đạo
  LDDUYETPHIEUVT: 4, //Duyệt phiếu vật tư

  //Tra cứu - Tìm kiếm
  TCTKTRACUUPHIEUVT: 5, //Tra cứu phiếu vật tư
};

//Enum Loại Danh Sách Yêu Cầu
export const ENUM_LOAI_DS_YEU_CAU = {
  DANH_SACH_1: 1,
  DANH_SACH_3: 3,
};
// loại treo tháo
export const DANHSACH_LOAITREOTHAO = [
  { value: 1, name: "Định kỳ" },
  { value: 2, name: "Tháo thu hồi" },
  { value: 3, name: "Thay thế cháy" },
  { value: 4, name: "Lắp mới" },
  { value: 5, name: "Nâng công suất" },
  { value: 6, name: "Di dời" },
];
//Enum Loại Phiếu
export const ENUM_LOAI_PHIEU = {
  PHIEU_XUATVT: 1,
  PHIEU_TRAVT: 2,
};

//Enum
export const ENUM_KYSO = {
  GCS: 1,
  CAPDIEN: 2,
  TREOTHAO: 3,
  KTCONGTO: 4,
};

export const TYPE_XUAT_TRA = {
  PHIEU_XUAT: 1,
  PHIEU_TRA: 2,
  CAP_DIEN: 3,
};

export const TYPE_PHIEUXUAT_CHUCNANG = {
  TAOMOI: 1,
  THAYDOI: 2,
  TRALAI_DUYET: 3,
};

export const TYPE_PHIEUTRA_CHUCNANG = {
  TAOMOI: 1,
  THAYDOI: 2,
  TRALAI_DUYET: 3,
};

export const TYPE_FUNC_PHIEU = {
  TAOMOI: 1,
  THAYDOI: 2,
  DUYET: 3,
  XOA: 4,
};

export const TYPE_PHIEU_CAPDIEN = {
  TAOMOI: 1,
  THAYDOI: 2,
  TRALAI_DUYET: 3,
};

export const LOAI_PHIEU_TREOTHAO = {
  PHIEUXUAT: 1,
  PHIEUTRA: 2,
};
//Loại Yêu Cầu bao gồm: Cấp điện or Treo Tháo
export const LOAI_YEUCAU = {
  CAPDIEN: 2,
  TREOTHAO: 1,
};

export const FUNC_ID_DSYC_TREOTHAO = {
  //Phòng kinh doanh
  PKD_TIEPNHAN: 0, //Tiếp nhận yêu cầu
  PKD_NGHIEMTHU: 1, //Nghiệm thu yêu cầu

  //Đội trưởng
  DOITRUONG_CANXUATVT: 2, //Yêu cầu cần xuất vật tư
  DOITRUONG_CANTRAVT: 3, //Yêu cầu cần trả vật tư
  DOITRUONG_DAXUATVT: 4, //Yêu cầu đã xuất vật tư
  DOITRUONG_DUYETYEUCAU: 5, //Ký duyệt yêu cầu

  //Nhân viên treo tháo
  NVTREOTHAO_DANGTREOTHAO: 6, //Yêu cầu đang treo tháo
  NVTREOTHAO_PHANCONG: 7, //Yêu cầu được phân công
  NVTREOTHAO_KHCHUAKYSO: 8, //Yêu cầu khách hàng chưa ký số
  NVTREOTHAO_KYDUYETYEUCAU: 9, //Ký duyệt yêu cầu

  //Lãnh đạo
  LANHDAO_DUYETYEUCAU: 10, //Ký duyệt yêu cầu
  TRACUU_YEUCAU: 11, //Tra cứu yêu cầu
};
//end treo tháo

// module kiểm tra công tơ

export const BO_CHI_SO_KTCT = {
  KT: "KT",
  KT_VC: "KT;VC",
  KT_KN: "KT;KN",
  BN: "BN",
  CN: "CN",
  TN: "TN",
};

export const BCS_KTCT = [
  { name: "chiSoKT", value: 1 },
  { name: "chiSoBT", value: 2 },
  { name: "chiSoCD", value: 3 },
  { name: "chiSoTD", value: 4 },
  { name: "chiSoSG", value: 5 },
  { name: "chiSoVC", value: 6 },
  { name: "chiSoKN", value: 7 },
  { name: "chiSoBN", value: 8 },
  { name: "chiSoCN", value: 9 },
  { name: "chiSoTN", value: 10 },
  { name: "chiSoSN", value: 11 },
  { name: "chiSoVN", value: 12 },
];
export const ENUM_AHT = {
  AHT: 1,
  BCS: 12,
};
export const ENUM_DS_CONGTO = {
  DINHKY_PKD: 1,
  PHUCTRA_PKD: 2,
  PHUCTRA_CMIS_PKD: 3,
  DINHKY_DT: 4,
  PHUCTRA_DT: 5,
  DINHKY_NVKT: 6,
  PHUCTRA_NVKT: 7,
  DINHKY_TRACUU: 8,
  PHUCTRA_TRACUU: 9,
  CHISO_EVN_HES: 10,
  CHISO_EVN_HES_DT: 11,
};

export const ENUM_DS_BANGKE = {
  KYDUYET_PKD: 1,
  PHUCTRA_BPPT: 2,
  KYDUYET_DT: 3,
  CHOKHKY_PKD_CMIS: 4,
  CHOXN_NVKT: 5,
  TRACUU: 6,
};

export const TYPE_TABLE_CONGTO = {
  KIEMTRADINHKY: 1,
  KIEMTRAPHUCTRA: 2,
  KIEMTRABATTHUONG: 3,
  DONGBO_CMIS: 4,
  CHISO_EVN_HES: 5,
};

export const ENUM_DBKTGS = {
  DA_DB: "1",
  CHUA_DB: "2",
};

export const TYPE_PHANCONG = {
  PHONGKINHDOANH: 1,
  DOITRUONG: 2,
  NHANVIEN: 3,
};

export const TYPE_TABLE_BAOCAO = {
  BATTHUONG: 1,
  PHUCTRADAKIEMTRA: 2,
  DINHKYDAKIEMTRA: 3,
};

export const TYPE_TABLE_BANGKE = {
  MOTPHA: 0,
  BAPHA: 1,
  PHUCTRA: 2,
  MOTPHA_STR: "Định kỳ 1 pha",
  BAPHA_STR: "Định kỳ 3 pha",
  PHUCTRA_STR: "Phúc tra chỉ số",
};

export const ENUM_SOPHA_KTCT = {
  TATCA: -1,
  MOTPHA: 1,
  BAPHA_TRUCTIEP: 3,
  BAPHA_GIANTIEP: 4,
};

export const ENUM_HINHTHUC_KTCT = {
  MTB: 1,
  MDMS: 2,
  HHU: 3,
  MTB_STR: "1",
  MDMS_STR: "2",
  HHU_STR: "3",
};

export const ENUM_CHENHLECH_KTCT = {
  PT: "1",
  KWH: "2",
};

export const ENUM_SOSANH_KTCT = {
  BEHON_OR_BANG: "1",
  LONHON_OR_BANG: "2",
};

export const ENUM_KY_GCS_KTCT = {
  KY_1: 1,
  KY_2: 2,
  KY_3: 3,
  KY_4: 4,
};
export const TYPE_MODAL = {
  XOA: 1,
  XN: 2,
  DB: 3,
  LEADXN: 4,
  EVNHES: 5,
  MTB: 6,
};
//

export const CAU_HINH_SL = {
  MOT_THANG: "0",
  BA_THANG: "1",
};

export const CHENH_LECH_SL = {
  PHAN_TRAM: "0",
  KWH: "1",
};

export const SO_BCS = {
  SAU_BCS: 6,
  MUOI_BCS: 10,
};

export const QUANTITY_TYPE = {
  NUMBER_ONE: 1,
};

// các loại modal ký duyệt
export const KY_DUYET_MODAL_TYPE = {
  PHONG_KD: 1,
  PHONG_KD_OTHERS: 2,
  PHONG_DIEU_DO: 3,
  LANH_DAO: 4,
  GDV: 5,
  CAPTAIN: 6,
  CAPTAIN_OTHERS: 7,
};

// các loại modal trả lại
export const TRA_YEU_CAU_MODAL_TYPE = {
  PHONG_DIEU_DO: 1,
  LANH_DAO: 2,
};

export const TYPE_NHAN_VIEN = {
  TREO_THAO: 1,
  NIEM_PHONG: 2,
};

export const TYPE_THIET_BI = {
  TI: "TI",
  TU: "TU",
  CT: "CTO",
};

export const TYPE_PHIEU_YEUCAU = {
  PHIEU: 1,
  YEUCAU: 2,
};

// module Giao nhận điện năng

export const TYPE_INPDF_GNDN = {
  CHITIET_DNGN: 1,
  TONGHOP_DNGN: 2,
  CHITIET_CS_CTD_DNGN: 3,
  BBQT_DN_PHANKHANG: 4,
  SAISO_TRAM110: 5,
  CHITIET_DN_THEOTRAM: 6,
  CHITIET_SANLUONG: 7,
  DIEMDO_BIENDONG_THANG: 8,
  SANLUONG_DAUNGUON_3GIA: 9,
  CHITIET_DN_TUDUNG: 10,
  BBQT_DIENNANG_GIAONHAN: 11,
};

export const ENUM_LUYKE_GNDN = {
  LUYKE: 1,
  LUYKE_QUY: 2,
  NAM: 3,
};

export const TRANGTHAI_SANLUONG_DV = {
  CHUA_TINH: "Chưa tính sản lượng",
  DA_TINH: "Đã tính sản lượng",
  DA_XACNHAN: "Đã xác nhận",
  CHUA_TINH_NUM: 1,
  DA_TINH_NUM: 2,
  DA_XACNHAN_NUM: 3,
};

export const TRANGTHAI_SANLUONG_TCT = {
  CHUA_CHOT: "Chưa  chốt sản lượng",
  DA_CHOT: "Chốt sản lượng",
  CHUA_CHOT_NUM: 1,
  DA_CHOT_NUM: 2,
};

export const BCS_GNDN = {
  BT: "BT",
  TD: "TD",
  CD: "CD",
  SG: "SG",
  VC: "VC",
  BN: "BN",
  TN: "TN",
  CN: "CN",
  SN: "SN",
  VN: "VN",
  KT: "KT",
  KN: "KN",
};

export const optionPageSize = [
  {
    id: 1,
    value: 10,
  },
  {
    id: 2,
    value: 20,
  },
  {
    id: 3,
    value: 50,
  },
  {
    id: 4,
    value: 100,
  },
  {
    id: 5,
    value: 200,
  },
];

export const listPhanLoaiDuoiLuoiTRongKho = [
  { value: "A", label: "Trong Kho" },
  { value: "E", label: "Dưới lưới" },
];

export const TYPE_TRA_HO_SO = {
  THI_CONG: 1,
};

export const DANH_SACH_DON_VI_DIEN_LUC = {
  TONG_CONG_TY: {value: "PD", name: "TỔNG CÔNG TY ĐIỆN LỰC TP HÀ NỘI"}
};

export const OPTION_CHENH_LECH_BAO_CAO_PHAN_HE_HOA_DON = {
  DUOI_1_3: {value: "1", label: "Từ 1,3 lần đến dưới 1,5 lần"},
  DUOI_2: {value: "2", label: "Từ 1,5 lần đến dưới 2 lần"},
  TREN_2: {value: "3", label: "Trên 2 lần"},
  TREN_1_3: {value: "4", label: "Trên 1,3 lần"}
};

export const LIST_TIME_OF_DAY = [
  {value: "0", label: "0"},
  {value: "1", label: "1"},
  {value: "2", label: "2"},
  {value: "3", label: "3"},
  {value: "4", label: "4"},
  {value: "5", label: "6"},
  {value: "7", label: "7"},
  {value: "8", label: "8"},
  {value: "9", label: "9"},
  {value: "10", label: "10"},
  {value: "11", label: "11"},
  {value: "12", label: "12"},
  {value: "13", label: "13"},
  {value: "14", label: "14"},
  {value: "15", label: "15"},
  {value: "16", label: "16"},
  {value: "17", label: "17"},
  {value: "18", label: "18"},
  {value: "19", label: "19"},
  {value: "20", label: "20"},
  {value: "21", label: "21"},
  {value: "22", label: "22"},
  {value: "23", label: "23"},
  {value: "24", label: "24"}
];

export const LIST_TYPE_CUSTOMER = {
  ALL: {value: "-1", label: "Tất cả"},
  LIVING: {value: "0", label: "Sinh hoạt"},
  OUT_SIDE_LIFE:  {value: "1", label: "Ngoài sinh hoạt"},
}

export const OPERATORS = {
  GREATER_OR_EQUAL : '>=',
  LESS_OR_EQUAL : "<="
}