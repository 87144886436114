import React from "react";
import { Modal, Tabs } from "antd";
import EditFormUserAccount from "./edit-form-user-account";
import ChangePassWordFormAccount from "./change-password-account";
export default function ModalEditUserAccount(props) {
  const { close, visible, user } = props;
  const { TabPane } = Tabs;

  return (
    <Modal
      width="700px"
      title="Thông tin tài khoản"
      visible={visible}
      footer={null}
      onCancel={() => close()}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Thông tin người dùng" key="1">
          <EditFormUserAccount user={user} />
        </TabPane>
        <TabPane tab="Thay đổi mật khẩu" key="4">
          <ChangePassWordFormAccount userId={user} />
        </TabPane>
      </Tabs>
    </Modal>
  );
}
