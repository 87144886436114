import React from "react";

function TrashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#FF4D4F"
        d="M5.607 1.677h-.179a.18.18 0 00.179-.179v.179h6.785v-.179c0 .098.08.179.18.179h-.18v1.607H14V1.498A1.43 1.43 0 0012.57.069H5.428A1.43 1.43 0 004 1.498v1.786h1.607V1.677zm11.25 1.607H1.143a.714.714 0 00-.715.714v.714c0 .098.08.179.179.179h1.348l.551 11.674a1.43 1.43 0 001.427 1.361h10.134c.763 0 1.39-.598 1.426-1.361l.551-11.674h1.348a.18.18 0 00.18-.179v-.714a.714.714 0 00-.715-.714zm-2.962 13.035h-9.79l-.54-11.428h10.87l-.54 11.428z"
      ></path>
    </svg>
  );
}

export default TrashIcon;
