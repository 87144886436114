import { useState, useEffect, useLayoutEffect, useCallback } from "react";

import { useTranslation } from "react-i18next";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  TABINDEX_CMIS,
} from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { getListNganHangService } from "./services";
import NganHangTable from "./table";
import EllipsisTooltip from "@components/EllipsisTooltip";
import { Button } from "antd";
import { Endpoint } from "@utils/endpoint";
import { dongBoCMIS } from "../services";
import CheckPermission from "@components/CheckPermission";
import { tien_ich_chung } from "@permissions/m_tienichchung";

export default function Index() {
  const { t } = useTranslation();
  const [dataNganHang, setDataNganHang] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu

  const getListNganHang = useCallback(() => {
    getListNganHangService(
      filterConditions,
      setLoading,
      setDataNganHang,
      setTotalCount
    );
  }, [filterConditions]);

  useEffect(() => {
    getListNganHang();
    // eslint-disable-next-line
  }, [getListNganHang]);

  //thay đổi pagination table

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const genExtra = () => (
    <CheckPermission permissionCode={tien_ich_chung.m17cmis}>
      <Button
        size="small"
        type="primary"
        className="btn-bg-yellow"
        onClick={handleDongBoCMIS}
        loading={loading}
      >
        Đồng bộ CMIS
      </Button>
    </CheckPermission>
  );

  const handleDongBoCMIS = useCallback(() => {
    const url = Endpoint.DONG_BO_CMIS + `?tabIndex=${TABINDEX_CMIS.NGAN_HANG}`;
    dongBoCMIS(url, setLoading, getListNganHang);
  }, [getListNganHang]);

  //khai báo columns

  const columns = [
    {
      title: t("ngan_hang.ma_ngan_hang"),
      dataIndex: "maNganHang",
      key: "maNganHang",
      render: (text) => <span>{text}</span>,
      width: "10%",
      sorter: true,
    },
    {
      title: t("ngan_hang.ten_ngan_hang"),
      dataIndex: "tenNganHang",
      key: "tenNganHang",
      render: (text) => <EllipsisTooltip title={text}>{text}</EllipsisTooltip>,
      width: "40%",
      sorter: true,
    },
    {
      title: t("ngan_hang.dia_chi"),
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => <EllipsisTooltip title={text}>{text}</EllipsisTooltip>,
      // width: "50%",
      sorter: true,
    },
  ];

  //tìm kiếm dữ liệu

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  return (
    <NganHangTable
      handleSearch={handleSearch}
      clearFilter={clearFilter}
      filterConditions={filterConditions}
      genExtra={genExtra}
      t={t}
      dataNganHang={dataNganHang}
      columns={columns}
      totalCount={totalCount}
      loading={loading}
      onChangePagination={onChangePagination}
    />
  );
}
