import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { removeAccents } from "@utils/function";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { Option } from "antd/lib/mentions";
import { t } from "i18next";
import { memo, useEffect, useState } from "react";
import { GetDataDanhMucTram, handleSubmitForm } from "./service";

function ModalThongTinTram(props) {
  const { visible, closeModal, getListDataDanhMucTram, detailDataTable } =
    props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [danhMucTram, setDanhMucTram] = useState(false);

  const listTinhChat = [
    { name: "Điện MTMN từ trụ sở đơn vị khác thuộc TCT", value: "ĐMT_TSXTCT" },
    { name: "Điện MTMN trụ sở CTDL", value: "ĐMT_TSX" },
    { name: "Điện MTMN của khách hàng", value: "ÐMT_KH" },
    { name: "Điện MTMN Tổng Cty", value: "ÐMT_TCT" },
    { name: "Khách hàng lẻ", value: "KH" },
  ];
  const onFinish = (values) => {
    handleSubmitForm(
      values,
      setLoading,
      closeModal,
      getListDataDanhMucTram,
      detailDataTable,
      form
    );
  };
  useEffect(() => {
    GetDataDanhMucTram(setDanhMucTram, setLoading);
  }, []);
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={detailDataTable.id ? "Sửa danh mục Trạm" : "Thông tin trạm"}
        visible={visible}
        onCancel={closeModal}
        width={800}
        footer={null}
      >
        <FormComponent
          onFinish={onFinish}
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          name="form-thong-tin-tram"
          initialValues={{
            ...detailDataTable,
            maCapDienAp: detailDataTable.capDienApId,
            tinhChat: detailDataTable.maTinhChat,
          }}
        >
          <Form.Item
            name="maTram"
            label="Mã trạm"
            labelAlign="left"
            required
            colon={false}
          >
            <Input placeholder="Nhập mã trạm" />
          </Form.Item>
          <Form.Item
            name="moTa"
            label="Thông tin mô tả"
            labelAlign="left"
            required
            colon={false}
          >
            <Input placeholder="Nhập thông tin mô tả" />
          </Form.Item>
          <Form.Item
            name="maCapDienAp"
            label="Mã cấp đa"
            labelAlign="left"
            required
            colon={false}
          >
            <Select
              formKey="maCapDienAp"
              placeholder="Chọn mã cấp đa"
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {danhMucTram && danhMucTram?.length > 0
                ? danhMucTram?.map((item, idx) => (
                    <Option
                      key={idx}
                      value={item.id ? item.id.toString() : item.id}
                    >
                      {item?.moTa}
                    </Option>
                  ))
                : undefined}
            </Select>
          </Form.Item>

          <Form.Item
            name="tinhChat"
            label="Tính chất"
            labelAlign="left"
            colon={false}
            style={{ paddingLeft: "10px" }}
          >
            <Select
              style={{ marginLeft: "-8px", width: "101%" }}
              loading={loading}
              allowClear
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {listTinhChat &&
                listTinhChat.map((item, id) => {
                  return (
                    <Select.Option key={id} value={item.value}>
                      {item.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-thong-tin-tram"
                loading={loading}
              >
                Cập nhật
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(ModalThongTinTram);
