import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { downloadFile } from "@utils/request";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { selectTabDVK } from "../../../services";
import XuatExcel from "../../xuat-excel";
import IndexBieu2 from "../bieu2";
import IndexBieu4 from "../bieu4";
import { getListDataB1_DVK } from "./services";
export default function IndexBieu1() {
  const [loading, setLoading] = useState();
  const [screen, handleChange] = useState();
  const [form] = Form.useForm();
  const [data, setDataB1] = useState([]);
  const { Option } = Select;
  const user = useSelector(authSelector);
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const optionValue = [
    { label: "Biểu 1: Tổng hợp tin nhắn toàn Tổng công ty" },
  ];
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    MaDonVi: user.user.unitCode,
    TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
    DenNgay: moment().format(FORMAT_ENGLISH),
    ...parseParams(location.search),
  });
  // layout effect
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);

  const [filterExcel, setFilterExcel] = useState(false);
  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type]);

  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        MaDonVi: values.MaDonVi,
        phanLoai: values.PhanLoai,
        TuNgay: values.TuNgay,
        DenNgay: values.DenNgay,
      }));
      const payload = {
        MaDonVi: values.MaDonVi,
        phanLoai: values.PhanLoai,
        TuNgay: values.TuNgay,
        DenNgay: values.DenNgay,
      };
      handleAPI();
      getListDataB1_DVK(payload, setLoading, setDataB1);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleAPI]
  );
  // const clearFilter = useCallback(() => {
  //   setFilterConditions({
  //     pageIndex: DEFAULT_PAGEINDEX,
  //     pageSize: DEFAULT_PAGESIZE,
  //     MaDonVi: user.user.unitCode,
  //     TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
  //     DenNgay: moment().format(FORMAT_ENGLISH),
  //   });
  // }, [user.user.unitCode]);

  // const clear = useCallback(() => {
  //   form.resetFields();
  //   clearFilter();
  // }, [form, clearFilter]);

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      width: 70,
      align: "center",
      render: (a, b, idx) => {
        return (
          (filterConditions.pageIndex - 1) * (filterConditions.pageSize + 1) +
          (idx + 1)
        );
      },
    },
    {
      title: "Đơn vị",
      dataIndex: "MA_DVIQLY",
      key: "MA_DVIQLY",
      width: 150,
    },
    {
      title: "Loại tin nhắn",
      dataIndex: "LOAI_TNHAN",
      key: "LOAI_TNHAN",
      width: 150,
    },
    {
      title: "Số lượng chưa nhắn",
      dataIndex: "SL_CHUANHAN",
      width: 150,
      key: "SL_CHUANHAN",
    },
    {
      title: "Tỷ lệ chưa nhắn",
      dataIndex: "TYLE_CHUANHAN",
      width: 150,
      key: "TYLE_CHUANHAN",
    },
    {
      title: "Số lượng đã nhắn",
      dataIndex: "SL_DANHAN",
      width: 150,
      key: "SL_DANHAN",
    },
    {
      title: "Tỷ lệ đã nhắn",
      dataIndex: "TYLE_NHAN",
      key: "TYLE_NHAN",
      width: 150,
    },
    {
      title: "Số lượng lỗi",
      dataIndex: "SL_LOI",
      width: 150,
      key: "SL_LOI",
    },
    {
      title: "Tỷ lệ lỗi",
      dataIndex: "TYLE_LOI",
      width: 150,
      key: "TYLE_LOI",
    },
    {
      title: "Tổng",
      dataIndex: "TONG",
      width: 150,
      key: "TONG",
    },
  ];
  useEffect(() => {
    if (filterExcel) {
      const endpoint =
        Endpoint.B1_EXPORT_EXCEL +
        "?" +
        buildQueryString(parseParams(filterConditions));
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel]);
  return (
    <Fragment>
      {screen === Endpoint.B1_DVK_TH_TIN_NHAN_TOAN_CONG_TY ? (
        <IndexBieu1 />
      ) : screen === Endpoint.B2_DVK_CT_SL_TN_THEO_CTY_DIEN_LUC ? (
        <IndexBieu2 screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.B4_DVK_DOI_SOAT_SL_TIN_NHAN_THANH_CONG ? (
        <IndexBieu4 screen={screen} handleChange={handleChange} />
      ) : (
        <Container>
          <ContentWrapper>
            <FormComponent
              onFinish={handleSearch}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              initialValues={{
                reportType: screen,
                PhanLoai: "0",
              }}
            >
              <Row gutter={12}>
                <Col span={24}>
                  <Form.Item
                    name="reportType"
                    label="Tên báo cáo"
                    labelCol={{ span: 3 }}
                    labelAlign="left"
                  >
                    <Select
                      formKey="reportType"
                      form={form}
                      defaultValue={optionValue}
                      options={selectTabDVK}
                      onChange={() =>
                        handleChange(form.getFieldValue("reportType"))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="MaDonVi"
                    label="Đơn vị"
                    labelAlign="left"
                    labelCol={{ span: 3 }}
                  >
                    <Selection
                      url={Endpoint.GET_TEAM_LIST_CODE}
                      formKey="MaDonVi"
                      form={form}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="PhanLoai"
                    labelCol={{ span: 3 }}
                    label="Phân loại"
                    labelAlign="left"
                  >
                    <Select>
                      <Option value="0">Dịch vụ khác</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    name="TuNgay"
                    label="Ngày bắt đầu"
                    labelAlign="left"
                    labelCol={{ span: 9 }}
                  >
                    <div style={{ marginLeft: "3%" }}>
                      <DatePickerComponent
                        formKey="TuNgay"
                        form={form}
                        labelAlign="left"
                        format={FORMAT_ENGLISH}
                        defaultValue={
                          filterConditions.TuNgay
                            ? moment(filterConditions.TuNgay, FORMAT_ENGLISH)
                            : moment()
                        }
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    name="DenNgay"
                    label="Ngày kết thúc"
                    labelAlign="right"
                    labelCol={{ span: 11 }}
                  >
                    <DatePickerComponent
                      formKey="DenNgay"
                      form={form}
                      labelAlign="left"
                      format={FORMAT_ENGLISH}
                      defaultValue={
                        filterConditions.DenNgay
                          ? moment(filterConditions.DenNgay, FORMAT_ENGLISH)
                          : moment()
                      }
                    />
                  </Form.Item>
                </Col>
                <Row justify="end">
                  {/* <Button type="dasher" className="mr-5px" onClick={clear}>
                    Bỏ lọc
                  </Button> */}
                  <Button
                    type="primary"
                    className="mr-5px"
                    htmlType="submit"
                    onClick={() => setType(ENUM_BUTTON_TYPE.TIM_KIEM)}
                  >
                    Xem báo cáo
                  </Button>
                  <Button
                    type="primary"
                    className="mr-5px"
                    htmlType="submit"
                    onClick={() => setType(ENUM_BUTTON_TYPE.XUAT_EXCEL)}
                  >
                    Xuất excel
                  </Button>
                  {/* <XuatExcel
                    endpoint={
                      Endpoint.B1_EXPORT_EXCEL +
                      "?" +
                      buildQueryString(parseParams(filterConditions))
                    }
                    text={"Xuất Excel"}
                    key={"xuat-excel"}
                  /> */}
                </Row>
              </Row>
              <Divider
                className="section-text-line"
                orientation="left"
              ></Divider>
            </FormComponent>
            <TableComponent
              header={"Biểu 1- B09 - Tổng hợp tin nhắn toàn Tổng công ty"}
              columns={columns}
              dataSource={data}
              loading={loading}
              scrollX={1400}
            />
          </ContentWrapper>
        </Container>
      )}
    </Fragment>
  );
}

const Container = styled.div`
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 10px 16px;
`;
