import LoadingComponent from "@components/Loading";
import { Button, Col, Image, Modal, Row, Select } from "antd";
import { Fragment, Suspense, useCallback, useMemo, useState } from "react";
import imagePDF from "../../../../../../assets/images/image-pdf.png";
import { ContainerChoice } from "../../css-styled";
import PDFModal from "@modules/cap-dien/common-ho-so/pdf-viewer";
import { alertMessage, removeAccents } from "@utils/function";
import { useTranslation } from "react-i18next";
import noImage from "@assets/images/no-image.png";

const { Option } = Select;

export default function ListImage(props) {
  const {
    loading,
    dataImages,
    showSliderImage,
    setCaption,
    setModalSrc,
    setImageIndex,
    imageIndex,
    setSectionIndex,
    setCurrentSectionLength,
    setHideButtonLeft,
    setHideButtonRight,
    showModal,
    setShowModal,
    modalSrc,
    plusSlides,
    hideButtonLeft,
    hideButtonRight,
    handleChangeType,
    dataLoaiGT,
    firstLoaiGT,
    type,
    note,
    setNote,
    keyImage,
    setKeyImage,
    listId,
  } = props;
  const [visiblePDF, setVisiblePDF] = useState(false);
  const [base64, setBase64] = useState("");
  const { t } = useTranslation();

  const showModalPdfKhaiGia = useMemo(
    () =>
      visiblePDF && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visiblePDF}
            setVisible={setVisiblePDF}
            title="Xem trước file PDF"
            getBienBan={(setUrl) => showModalPDF(setUrl)}
            loadingGetBienBan={false}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visiblePDF]
  );

  const base64toBlob = (data) => {
    try {
      const base64WithoutPrefix = data.substr(
        "data:application/pdf;base64,".length
      );
      const bytes = atob(base64WithoutPrefix);
      let length = bytes.length;
      const out = new Uint8Array(length);

      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      return new Blob([out], { type: "application/pdf" });
    } catch (error) {
      alertMessage(
        "error",
        t("modal.thong_bao"),
        "Định dạng file không hỗ trợ!"
      );
    }
  };

  const showModalPDF = useCallback(
    (setUrl) => {
      const blob = base64toBlob(base64);
      const url = URL.createObjectURL(blob);
      setUrl(url);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [base64]
  );

  const renderListImages = useMemo(() => {
    return (
      <div style={{ marginTop: "24px" }}>
        {dataImages && dataImages.length > 0 ? (
          // eslint-disable-next-line array-callback-return
          dataImages.map((item, index) => {
            if (item.length > 0) {
              return (
                <Row
                  gutter={24}
                  style={{ margin: 0 }}
                  id={`data-${listId[index]?.loaiGiayToId}`}
                >
                  <Col span={24} style={{ marginTop: "12px" }}>
                    <h2>
                      Loại hồ sơ: <span>{listId[index]?.tenLoaiGiayTo}</span>
                    </h2>
                  </Col>
                  {item.map((pic, key) => [
                    <Col
                      span={24}
                      md={4}
                      key={key}
                      style={{
                        textAlign: "center",
                        marginTop: "12px",
                        marginBottom: pic.ghiChu ? "20px" : "0",
                      }}
                    >
                      <div
                        className="remove-image"
                        style={{
                          position: "relative",
                          cursor: "pointer",
                          height: "200px",
                          width: "100%",
                          border: "1px solid #f0f0f0",
                          boxShadow: "0px 8px 20px -8px rgb(0 0 0 / 20%)",
                        }}
                      >
                        {/* {remove ? (
                            <Button
                              type="danger"
                              size="small"
                              onClick={() => removeImage(pic.imageId)}
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                zIndex: 9999,
                              }}
                            >
                              X
                            </Button>
                          ) : null} */}

                        {pic.fileName.indexOf(".pdf") === -1 ? (
                          <Fragment>
                            <Image
                              preview={false}
                              src={pic.filePath}
                              alt={""}
                              width={"100%"}
                              height={"100%"}
                              onError={(e) => {
                                e.currentTarget.src = noImage;
                              }}
                              style={{ objectFit: "cover" }}
                              onClick={() => {
                                showSliderImage(index);
                                setCaption(pic);
                                setModalSrc(pic.filePath);
                                setImageIndex(key);
                                setKeyImage(index);
                                setSectionIndex(key);
                                setNote(pic.ghiChu);
                                setCurrentSectionLength(
                                  dataImages[index].length
                                );
                                if (key === 0) setHideButtonLeft(true);
                                else setHideButtonLeft(false);
                                if (dataImages[index].length === key + 1)
                                  setHideButtonRight(true);
                                else setHideButtonRight(false);
                              }}
                            />

                            <div
                              style={{
                                border: "1px solid #f0f0f0",
                                borderRadius: "0px 0px 8px 8px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <span>{pic.ghiChu ? pic.ghiChu : ""}</span>
                            </div>
                          </Fragment>
                        ) : (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <Fragment>
                            <Image
                              preview={false}
                              src={imagePDF}
                              alt={""}
                              width={"100%"}
                              height={"100%"}
                              style={{ objectFit: "cover" }}
                              onClick={() => {
                                window.open(pic.filePath, "_blank");
                                // setBase64(pic.filePath);
                                // setVisiblePDF(true);
                                // setNote(pic.ghiChu);
                              }}
                            />

                            <div
                              style={{
                                border: "1px solid #f0f0f0",
                                borderRadius: "0px 0px 8px 8px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <span>{pic.ghiChu ? pic.ghiChu : ""}</span>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </Col>,
                  ])}
                </Row>
              );
            }
          })
        ) : (
          // ? dataImage.map((pic, key) => [
          //     <Col
          //       span={24}
          //       md={8}
          //       key={key}
          //       style={{
          //         textAlign: "center",
          //         marginTop: "24px",
          //         marginBottom: pic.ghiChu ? "20px" : "0",
          //       }}
          //     >
          //       <div
          //         className="remove-image"
          //         style={{
          //           position: "relative",
          //           cursor: "pointer",
          //           height: "350px",
          //           width: "100%",
          //           border: "1px solid #f0f0f0",
          //           boxShadow: "0px 8px 20px -8px rgb(0 0 0 / 20%)",
          //         }}
          //       >
          //         {/* {remove ? (
          //           <Button
          //             type="danger"
          //             size="small"
          //             onClick={() => removeImage(pic.imageId)}
          //             style={{
          //               position: "absolute",
          //               top: 0,
          //               right: 0,
          //               zIndex: 9999,
          //             }}
          //           >
          //             X
          //           </Button>
          //         ) : null} */}

          //         {pic.fileName.indexOf(".pdf") === -1 ? (
          //           <Fragment>
          //             <Image
          //               preview={false}
          //               src={pic.filePath}
          //               alt={""}
          //               width={"100%"}
          //               height={"350px"}
          //               onError={(e) => {
          //                 e.currentTarget.src = noImage;
          //               }}
          //               style={{ objectFit: "cover" }}
          //               onClick={() => {
          //                 showSliderImage();
          //                 setCaption(pic);
          //                 setModalSrc(pic.filePath);
          //                 setImageIndex(key);
          //                 setSectionIndex(key);
          //                 setNote(pic.ghiChu);
          //                 setCurrentSectionLength(dataImage.length);
          //                 if (key === 0) setHideButtonLeft(true);
          //                 else setHideButtonLeft(false);
          //                 if (dataImage.length === key + 1)
          //                   setHideButtonRight(true);
          //                 else setHideButtonRight(false);
          //               }}
          //             />
          //             {pic.ghiChu ? (
          //               <div
          //                 style={{
          //                   border: "1px solid #f0f0f0",
          //                   borderRadius: "0px 0px 8px 8px",
          //                   whiteSpace: "nowrap",
          //                   overflow: "hidden",
          //                   textOverflow: "ellipsis",
          //                   padding: "4px",
          //                 }}
          //               >
          //                 {pic.ghiChu}
          //               </div>
          //             ) : null}
          //           </Fragment>
          //         ) : (
          //           // eslint-disable-next-line jsx-a11y/anchor-is-valid
          //           <Fragment>
          //             <Image
          //               preview={false}
          //               src={imagePDF}
          //               alt={""}
          //               width={"100%"}
          //               height={"350px"}
          //               style={{ objectFit: "cover" }}
          //               onClick={() => {
          //                 setBase64(pic.filePath);
          //                 setVisiblePDF(true);
          //                 setNote(pic.ghiChu);
          //               }}
          //             />
          //             {pic.ghiChu ? (
          //               <div
          //                 style={{
          //                   border: "1px solid #f0f0f0",
          //                   borderRadius: "0px 0px 8px 8px",
          //                   whiteSpace: "nowrap",
          //                   overflow: "hidden",
          //                   textOverflow: "ellipsis",
          //                   padding: "4px",
          //                 }}
          //               >
          //                 {pic.ghiChu}
          //               </div>
          //             ) : null}
          //           </Fragment>
          //         )}
          //       </div>
          //     </Col>,
          //   ])
          <div style={{ minHeight: "300px" }}></div>
        )}

        {/* </ScrollContentComponent> */}
      </div>
    );
  }, [
    dataImages,
    listId,
    setCaption,
    setCurrentSectionLength,
    setHideButtonLeft,
    setHideButtonRight,
    setImageIndex,
    setKeyImage,
    setModalSrc,
    setNote,
    setSectionIndex,
    showSliderImage,
  ]);

  return (
    <LoadingComponent loading={loading}>
      {!type ? (
        <ContainerChoice>
          <span className="text">Chọn loại hồ sơ giấy tờ: </span>
          <Select
            placeholder="Chọn loại hồ sơ giấy tờ"
            onChange={handleChangeType}
            defaultValue={firstLoaiGT}
            className="choice"
            showSearch
            filterOption={(input, option) => {
              if (option && option.children) {
                return (
                  removeAccents(option.children)
                    .toLowerCase()
                    .indexOf(removeAccents(input).toLowerCase()) >= 0
                );
              }
            }}
          >
            {dataLoaiGT && dataLoaiGT.length
              ? dataLoaiGT.map((item, index) => (
                  <Option value={item.id} key={index}>
                    {item.tenLoaiHoSo}
                  </Option>
                ))
              : null}
          </Select>
        </ContainerChoice>
      ) : null}

      {renderListImages}
      {showModalPdfKhaiGia}
      <Modal
        visible={showModal}
        width={800}
        onCancel={() => false}
        onOk={() => false}
        footer={
          note ? (
            <div
              style={{
                // border: "1px solid #f0f0f0",
                // borderRadius: "0px 0px 8px 8px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                padding: "4px",
                textAlign: "center",
              }}
            >
              {note}
            </div>
          ) : (
            []
          )
        }
        className="modal-image"
      >
        {/* <div style={{ textAlign: "right" }}> */}
        <Button
          className="close"
          onClick={() => setShowModal(false)}
          style={{
            fontSize: "24px",
            border: "unset",
            position: "absolute",
            top: "0",
            right: "10px",
          }}
        >
          &times;
        </Button>
        {/* </div> */}

        <div
          className="mySlides"
          style={{
            display: showModal ? "block" : "none",
            marginTop: "20px",
            height: "450px",
          }}
        >
          <Image
            className="modal-content"
            src={modalSrc?.indexOf(".pdf") > -1 ? imagePDF : modalSrc}
            alt=""
            height={"100%"}
            width={"100%"}
            style={{ objectFit: "contain", padding: 0 }}
          />
          <Button
            className="prev"
            onClick={() => {
              plusSlides(imageIndex - 1, keyImage);
            }}
            style={{
              position: "absolute",
              top: "50%",
              left: "-10%",
              transform: "translate(50%, -50%)",
            }}
            disabled={hideButtonLeft ? true : false}
          >
            &#10094;
          </Button>
          <Button
            className="next"
            onClick={() => {
              plusSlides(imageIndex + 1, keyImage);
            }}
            style={{
              position: "absolute",
              top: "50%",
              right: "-10%",
              transform: "translate(-50%, -50%)",
            }}
            disabled={hideButtonRight ? true : false}
          >
            &#10095;
          </Button>
        </div>
      </Modal>
    </LoadingComponent>
  );
}
