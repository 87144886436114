import { Endpoint } from "@utils/endpoint";
import {
  METHOD_PUT,
  STATUSCODE_200,
  TYPE_HIDE_SHOW_COLUMNS,
} from "@utils/constants";
import { authPostData } from "@utils/request";

export const apiChotBaoCao = (
  endpoint,
  setLoading,
  payload,
  getListBaoCao,
  setSelectedRowKeys,
  normal
) => {
  authPostData({
    url: endpoint,
    payload,
    setLoading,
    method: METHOD_PUT,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        if (!normal) setSelectedRowKeys([]);
        getListBaoCao();
      }
    },
  });
};

export const apiHuyChotBaoCao = (
  endpoint,
  setLoading,
  payload,
  getListBaoCao,
  setSelectedRowKeys,
  normal
) => {
  authPostData({
    url: endpoint,
    payload,
    setLoading,
    method: METHOD_PUT,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        if (!normal) setSelectedRowKeys([]);
        getListBaoCao();
      }
    },
  });
};

export const handleUpdate = (
  arrColumnsUpdate,
  setLoading,
  getListColumns,
  type,
  closeModal
) => {
  let url = Endpoint.UPDATE_LIST_COLUMNS_BAO_CAO_CHI_PHI_QUYET_TOAN;
  if (type === TYPE_HIDE_SHOW_COLUMNS.BAOCAOCHITIET) {
    url = Endpoint.UPDATE_LIST_COLUMNS_BAO_CAO_CHI_TIET;
  } else if (type === TYPE_HIDE_SHOW_COLUMNS.CHITIETHINHTHUCLAPDAT) {
    url = Endpoint.UPDATE_LIST_COLUMNS_BAO_CAO_CHI_TIET_HINH_THUC_LAP_DAT;
  } else if (type === TYPE_HIDE_SHOW_COLUMNS.CHAMTIENDO) {
    url = Endpoint.UPDATE_LIST_COLUMNS_BAO_CAO_CHAM_TIEN_DO;
  }
  authPostData({
    url: url,
    method: METHOD_PUT,
    payload: arrColumnsUpdate,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListColumns();
        closeModal();
      }
    },
  });
};
