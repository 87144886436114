import React, { Fragment, useState, useCallback } from "react";
import { Divider, Spin, Form, Row, Col, Button, Select } from "antd";
import styled from "styled-components";
import { Endpoint } from "@utils/endpoint";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import DatePickerComponent from "@components/DatePicker";
import ModalDichVuBanLeDienNang from "./modal-dich-vu-ban-le";
import { handleDownload, handleOpenPdf } from "./service";
import { Option } from "antd/lib/mentions";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";

export default function BCDichVuBanLeDienNang() {
  const [visibleUpdateBanLe, setVisibleUpdateBanLe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);
  const onFinish = (values) => {
    console.log(values);
    handleDownload(values, setLoading);
  };
  const handleClick = () => {
    alert("Giao diện dang phát triển vui lòng thử lại sau");
  };
  const handleModalDichVuBanLeDienNang = useCallback(() => {
    setVisibleUpdateBanLe(!visibleUpdateBanLe);
  }, [visibleUpdateBanLe]);
  return (
    <Fragment>
      {visibleUpdateBanLe && (
        <ModalDichVuBanLeDienNang
          visible={visibleUpdateBanLe}
          close={handleModalDichVuBanLeDienNang}
        />
      )}
      <Container>
        <Divider className="section-text" orientation="left">
          Báo cáo dịch vụ bán lẻ điện năng
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <div className="flex">
              <FlexForm>
                <FormComponent
                  onFinish={onFinish}
                  form={form}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 18 }}
                  initialValues={{
                    reportType:
                      Endpoint.BCDVBLDN_BC_CONG_TAC_TO_CHUC_VA_HOAT_DONG_BAN_LE_DIEN_NANG,
                    unitId: user.user.unitCode,
                  }}
                  colon={false}
                >
                  <Form.Item
                    name="reportType"
                    label="Báo cáo"
                    labelAlign="left"
                    required
                  >
                    <Select placeholder="lựa chọn" formKey="reportType">
                      <Option
                        value={
                          Endpoint.BCDVBLDN_BC_CONG_TAC_TO_CHUC_VA_HOAT_DONG_BAN_LE_DIEN_NANG
                        }
                      >
                        1. Báo cáo công tác tổ chức và hoạt động dịch vụ bán lẻ
                        điện năng
                      </Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="unitId"
                    label="Đơn vị"
                    labelAlign="left"
                    required
                  >
                    <Selection
                      url={Endpoint.GET_TEAM_LIST_CODE}
                      formKey="unitId"
                      form={form}
                    />
                  </Form.Item>

                  <Form.Item
                    name="reportDate"
                    label="Tháng báo cáo"
                    labelAlign="left"
                    required
                  >
                    <DatePickerComponent
                      picker="month"
                      formKey="reportDate"
                      form={form}
                      format={"YYYY-MM"}
                    />
                  </Form.Item>

                  <Row>
                    <Col span={4}></Col>
                    <Col>
                      <Button type="primary" htmlType="submit">
                        Xuất báo cáo
                      </Button>

                      {/* <Button
                        type="primary"
                        loading={loading}
                        className="ml-20px"
                        onClick={() => {
                          handleOpenPdf(form.getFieldValue(), setLoading);
                        }}
                      >
                        In báo cáo
                      </Button> */}
                    </Col>
                  </Row>
                </FormComponent>
              </FlexForm>
              <FlexButton>
                <Button
                  htmlType="submit"
                  onClick={() => setVisibleUpdateBanLe(true)}
                >
                  Cập nhật bán lẻ
                </Button>
                <Button
                  htmlType="submit"
                  onClick={handleClick}
                  className="ml-20px"
                >
                  Cập nhật bán buôn
                </Button>
              </FlexButton>
            </div>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;

const FlexForm = styled.div`
  flex: 3;
`;
const FlexButton = styled.div`
  flex: 1;
  display: flex;
`;
