import { Fragment } from "react";
import ViewPDFTreoThao from "./layout";
export default function Index(props) {
  const { dataSource, visible, closeModal, loading, showViewPDF } = props;

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (item, record, index) => (
        <div style={{ textAlign: "center" }}>
          <span>{index + 1}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Tên biên bản",
      dataIndex: "tenBienBan",
      key: "tenBienBan",
      width: "55%",
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "ngayCapNhat",
      key: "ngayCapNhat",
      width: "25%",
      render: (text) => (
        <div style={{ textAlign: "center" }}>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Tác vụ",
      key: "action",
      align: "center",
      width: "10%",
      render: (row) => (
        <div style={{ textAlign: "center", cursor: "pointer" }}>
          <div
            style={{ color: "#03A4FF", fontSize: 14 }}
            onClick={() => showViewPDF(row)}
          >
            Xem
          </div>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <ViewPDFTreoThao
        loading={loading}
        visible={visible}
        closeModal={closeModal}
        dataSource={dataSource}
        columns={columns}
      />
    </Fragment>
  );
}
