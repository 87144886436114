import ImageMultipleUpload from "@components/ImageMultipleUpload";
import { Button, Image, Modal, Tabs, Tooltip } from "antd";
import { useState } from "react";
import imagePDF from "../../../../../assets/images/image-pdf.png";
import ListImageBBGiay from "./list-image";

export default function UploadImage(props) {
  const {
    visible,
    closeModal,
    handleUploadImage,
    loading,
    fileToDataUri,
    imageFiles,
    setImageFiles,
    isSubmit,
    isUpload,
    setIsUpload,
    dataImage,
    typeFile,
    handleConfirm,
  } = props;
  const [keyTab, setKeyTab] = useState("1");
  const callback = (key) => {
    setKeyTab(key);
  };

  return (
    <Modal
      title="Tải BBTT giấy"
      visible={visible}
      onCancel={closeModal}
      footer={null}
      width={"60%"}
    >
      <Tabs type="card" defaultActiveKey="1" onChange={callback}>
        <Tabs.TabPane key={"1"} tab="Tải ảnh / PDF">
          <ImageMultipleUpload
            setImageFiles={setImageFiles}
            imageFiles={imageFiles}
            // remove={removeImage}
            multiple={false}
            fileToDataUri={fileToDataUri}
            isUpload={isUpload}
            isSubmit={isSubmit}
            setIsUpload={setIsUpload}
            // messErr="Chỉ được phép tải PDF !"
            // accept=".pdf"
            // acceptMess="Có thể kéo & thả để tải tệp (*.pdf)"
          >
            <div
              style={{
                width: "40%",
                height: 50,
                background: "#fafafa",
                border: "1px dashed #d9d9d9",
                textAlign: "center",
                lineHeight: "50px",
              }}
            >
              Chọn tệp
            </div>
          </ImageMultipleUpload>
          {imageFiles && imageFiles.length > 0 ? (
            <div>
              <h3
                style={{
                  fontSize: "18px",
                  color: "green",
                  paddingTop: "12px",
                }}
              >
                {imageFiles[0].name}
              </h3>
              <div
                style={{
                  width: "50%",
                  height: "250px",
                  paddingTop: "12px",
                  margin: "0 auto",
                }}
              >
                {imageFiles[0].type === "application/pdf" ? (
                  <Image
                    src={imagePDF}
                    width={"100%"}
                    height={"100%"}
                    preview={false}
                  />
                ) : (
                  <Image
                    src={imageFiles[0].base64Data}
                    width={"100%"}
                    height={"100%"}
                    preview={false}
                  />
                )}
              </div>
            </div>
          ) : null}
        </Tabs.TabPane>
        <Tabs.TabPane
          key={"2"}
          tab={
            dataImage === null || dataImage === "" ? (
              <Tooltip title="Không có dữ liệu ảnh / PDF">
                <div>Danh sách ảnh / PDF</div>
              </Tooltip>
            ) : (
              <div>Danh sách ảnh / PDF</div>
            )
          }
          disabled={dataImage === null || dataImage === "" ? true : false}
        >
          <ListImageBBGiay
            loading={loading}
            src={dataImage}
            typeFile={typeFile}
            handleConfirm={handleConfirm}
          />
        </Tabs.TabPane>
      </Tabs>

      <div style={{ marginTop: "24px", textAlign: "center" }}>
        <Button
          type="default"
          className={`mr-24px`}
          onClick={closeModal}
        >
          Đóng lại
        </Button>
        {keyTab === "2" ? (
          <Button type="primary" onClick={handleConfirm} loading={loading}>
            Xác nhận KH đã ký
          </Button>
        ) : (
          <Button type="primary" onClick={handleUploadImage} loading={loading}>
            Tải BBTT
          </Button>
        )}
      </div>
    </Modal>
  );
}
