import React, { useState } from "react";
import { Button, Col, Modal, Row } from "antd";
import LoadingComponent from "@components/Loading";
import { Container } from "../../../common-ho-so/modal-duyet-ho-so/lanh-dao-quyet-toan/css-styled";
import { useTranslation } from "react-i18next";
import { handleHoanThanhQuyetToanCongTy } from "../services";

export default function Index(props) {
  const {
    handleCloseModal,
    setFetchDataTable,
    detail,
    visible,
    setDisableButton,
  } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  //Handle hoàn thành quyết toán

  const handleHoanThanh = () => {
    const hoSoId = { hoSoId: detail.id };
    handleHoanThanhQuyetToanCongTy(
      hoSoId,
      setLoading,
      handleCloseModal,
      setFetchDataTable,
      setDisableButton
    );
  };

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={"Hoàn thành quyết toán công ty"}
        visible={visible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Container>
          <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
            Hoàn thành lập quyết toán công ty, hồ sơ sẽ được chuyển tới bước
            quyết toán khách hàng.
          </h4>
          <Row gutter={24} style={{ marginTop: "48px", textAlign: "center" }}>
            <Col span={5} md={5}></Col>
            <Col span={7} md={7}>
              <Button
                key="back"
                onClick={handleCloseModal}
                className="button-closed"
              >
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={7} md={7}>
              <Button
                type="primary"
                onClick={handleHoanThanh}
                loading={loading}
                key="dongY"
              >
                {"Đồng ý"}
              </Button>
            </Col>
            <Col span={5} md={5}></Col>
          </Row>
        </Container>
      </Modal>
    </LoadingComponent>
  );
}
