import styled from "styled-components";

export const Container = styled.div`
  padding: 0px 36px;
`;
export const ContainerButonKySo = styled.div`
  > button {
    background-color: #faad14;
    border-color: #faad14;
  }
  > button:hover {
    background-color: #faad14;
    border-color: #faad14;
  }
`;
