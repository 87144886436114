import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_MONTH_ENGLISH,
} from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { Image } from "antd";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import moment from "moment";
import { useLocation, useSearchParams } from "react-router-dom";
import { getToaDoKhachHang } from "./services";
import BaoCaoTheoToaDo from "./table";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";

export default function DanhSachToaDoKhachHang() {
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const user = useSelector(authSelector);
  const [dataTheoToaDo, setDataTheoToaDo] = useState();
  const [totalCount, setTotalCount] = useState();
  const location = useLocation();

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
    ...parseParams(location.search),
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const getLichToaDo = useCallback(() => {
    getToaDoKhachHang(
      filterConditions,
      setLoading,
      setDataTheoToaDo,
      setTotalCount
    );
  }, [filterConditions]);

  useEffect(() => {
    getLichToaDo();
  }, [getLichToaDo]);

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      ...values,
    }));
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      ThangNam: moment().format(FORMAT_MONTH_ENGLISH),
    });
  }, [user.unitId]);

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const columns = [
    {
      title: "STT",
      key: "index",
      align: "center",
      render: (a, b, index) => {
        return (
          (filterConditions.pageIndex - 1) * filterConditions.pageSize +
          (index + 1)
        );
      },
      width: 70,
    },
    {
      title: "Mã sổ GCS",
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      align: "center",
      width: 120,
    },
    {
      title: "Mã KH",
      dataIndex: "maKH",
      key: "maKH",
      align: "center",
      width: 150,
    },
    {
      title: "Tên KH",
      dataIndex: "tenKH",
      key: "tenKH",
      align: "center",
      width: 200,
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      align: "center",
      width: 200,
    },
    {
      title: "Kỳ",
      dataIndex: "ky",
      key: "ky",
      align: "center",
      width: 70,
    },
    {
      title: "BCS",
      dataIndex: "bcs",
      key: "bcs",
      align: "center",
      width: 100,
    },
    {
      title: "Mã công tơ",
      dataIndex: "maCongTo",
      key: "maCongTo",
      align: "center",
      width: 100,
    },
    {
      title: "Số công tơ",
      dataIndex: "soCongTo",
      key: "soCongTo",
      align: "center",
      width: 100,
    },
    {
      title: "CSĐK",
      dataIndex: "csdk",
      key: "csdk",
      align: "center",
      width: 100,
    },
    {
      title: "CSCK",
      dataIndex: "csck",
      key: "csck",
      align: "center",
      width: 100,
    },
    {
      title: "ĐNTT",
      dataIndex: "dntt",
      key: "dntt",
      align: "center",
      width: 100,
    },
    {
      title: "Hình thức GCS",
      dataIndex: "hinhThucGCS",
      key: "hinhThucGCS",
      align: "center",
      width: 100,
    },
    {
      title: "Ngày GCS",
      dataIndex: "ngayGCS",
      key: "ngayGCS",
      align: "center",
      width: 100,
    },
    {
      title: "Thời gian GCS",
      dataIndex: "thoiGianGCS",
      key: "thoiGianGCS",
      align: "center",
      width: 100,
    },
    {
      title: "Chênh lệch",
      dataIndex: "chenhLech",
      key: "chenhLech",
      align: "center",
      width: 100,
    },
    {
      title: "Tọa độ X",
      dataIndex: "toaDoX",
      key: "toaDoX",
      align: "center",
      width: 100,
    },
    {
      title: "Tọa độ Y",
      dataIndex: "toaDoY",
      key: "toaDoY",
      align: "center",
      width: 100,
    },
    {
      title: "Ảnh chụp CS",
      dataIndex: "anhChupChiSo",
      key: "anhChupChiSo",
      align: "center",
      width: 280,
      render: (text, record) =>
        record.anhChupChiSo ? (
          record.anhChupChiSo
        ) : (
          <Image
            preview={false}
            src={record.anhChupChiSo}
            alt={"image"}
            width={"100%"}
            height={"200px"}
            style={{ objectFit: "cover" }}
          />
        ),
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
      align: "center",
      width: 100,
    },
  ];
  return (
    <BaoCaoTheoToaDo
      columns={columns}
      handleSearch={handleSearch}
      filterConditions={filterConditions}
      loading={loading}
      setLoading={setLoading}
      onChangePagination={onChangePagination}
      data={dataTheoToaDo}
      totalCount={totalCount}
      scrollX={2000}
      scrollY={500}
      clearFilter={clearFilter}
    />
  );
}
