import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleChuyenDoiTruong } from "./services";
import ChuyenDoiTruongModal from "./modal-chuyen-doi-truong";
function Index({ visible, closeModal, setFetchDataTable, detail }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // handle submit chuyển đội trưởng

  const handleSubmit = () => {
    handleChuyenDoiTruong(detail.id, setLoading, setFetchDataTable, closeModal);
  };
  return (
    <ChuyenDoiTruongModal
      loading={loading}
      visible={visible}
      closeModal={closeModal}
      t={t}
      handleSubmit={handleSubmit}
    />
  );
}

export default memo(Index);
