import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useEffect,
  useMemo
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import Filter from "../../common/Filter";
import { Input, Row, Button, Select, Col, Checkbox, Modal, Tag, Image, notification } from "antd";
import { Container } from "../../common/doi-soat/css-styled";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import moment from "moment";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilterDS } from "./list-bo-loc";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_KY_GCS, ENUM_DS_INBK,
  ENUM_GCSTRANGTHAISO,
  // ENUM_HINHTHUCGCSCMIS
} from "@utils/constants";
import {
  handleListData,
  handleListSoGCSDH,
  handleListSoGCSDT,
  handleListSoGCS,
  submitXuatGhiLaiMDMS,
  submitXuatGhiLaiMTB,
  submitHoanThanhDS,
  submitTuDongDS,
  submitChangeCheckbox,
  submitInBK,
  getListDonViService,
  submitChuyenHHU
} from "./services";
import ViewImage from "../../common/doi-soat/view-image";
import ThemChiSo from "../modal/modal-them-chi-so";
import TableComponent from "@components/TableComponent";
import CheckPermission from "@components/CheckPermission";
import { ghi_chi_so } from "@permissions/d_gcs";
import "moment/locale/vi";
import { isEmpty } from "lodash";
const { Option } = Select;

export default function Index(props) {
  const {
    permission,
    fetchDataTableIfNeed,
    setFetchDataTable,
  } = props;
  const { t } = useTranslation();
  const { user } = useSelector(authSelector);
  const [dataDoiSoat, setDataDoiSoat] = useState([]);
  const [dataSoGCS, setDataSoGCS] = useState([]);
  const [dataDonVi, setDataDonVi] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setLoadingSoGCS] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const [tempDataDoiSoat, setTempDataDoiSoat] = useState([]);
  const [tempTotalCount, setTempTotalCount] = useState(0);
  const [visibleViewImage, setVisibleViewImage] = useState(false);
  const [visibleCapNhatchiSo, setVisibleCapNhatchiSo] = useState(false);
  const [chiSoMoi, setChiSoMoi] = useState();
  const [anhGCS, setAnhGCS] = useState();
  const [idThemCS, setIdThemCS] = useState();
  const location = useLocation();
  const [checked, setChecked] = useState();
  const [disableChecked, setDisableChecked] = useState();
  const [bangKe, setBangKe] = useState(1);
  const [daDS, setDaDS] = useState(0);
  const [dsDat, setDSDat] = useState(0);
  const [dsKhongDat, setDSKhongDat] = useState(0);
  const [total, setTotal] = useState(0);
  const [tempDaDS, setTempDaDS] = useState(0);
  const [tempDsDat, setTempDSDat] = useState(0);
  const [tempDsKhongDat, setTempDSKhongDat] = useState(0);
  const [tempTotal, setTempTotal] = useState(0);
  const [soGCS, setSoGCS] = useState();
  const [thang, setThang] = useState(moment().format("MM"));
  const [nam, setNam] = useState(moment().format("YYYY"));
  const [term, setTerm] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);
  const [image, setImage] = useState("");
  const [listSelect, setListSelect] = useState("")
  const [allIds, setAllIds] = useState("")
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    Ky: ENUM_KY_GCS.KY_1,
    DonViId: user.unitId,
    Thang: moment().format("MM"),
    Nam: moment().format("YYYY"),
    TrangThai: [ENUM_GCSTRANGTHAISO.MTB_DA_GUI_DU_LIEU].toString(),
    lichGCSs: undefined
  });
  const [filterSoGCS, setFilterSoGCS] = useState({
    Ky: ENUM_KY_GCS.KY_1,
    DonViId: user.unitId,
    Thang: moment().format("MM"),
    Nam: moment().format("YYYY"),
    TrangThai: [ENUM_GCSTRANGTHAISO.MTB_DA_GUI_DU_LIEU].toString(),
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: 1000,
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const changeTerm = (value) => {
    setTerm(value.target.value)
  };
  //tìm kiếm dữ liệu
  const handleSearch = useCallback((values) => {
    if (!soGCS || isEmpty(soGCS)) {
      notification.error({
        message: `Thông báo`,
        description: "Sổ GCS không được để trống",
      });
    } else {
      setFilterConditions((oldState) => ({
        ...oldState,
        pageIndex: DEFAULT_PAGEINDEX,
        ...values,
        lichGCSs: soGCS,
        BiTraLai: checked,
        Thang: thang,
        Nam: nam,
      }));
    }
  }, [checked, nam, soGCS, thang]);

  const SearchTerm = useCallback(() => {
    if (term.trim().length > 0) {
      let listTerm = [];
      listTerm = tempDataDoiSoat.filter(data =>
        data.tenKhachHang?.toUpperCase().indexOf(term.trim().toUpperCase()) > -1 ||
        data.maTram?.toUpperCase().indexOf(term.trim().toUpperCase()) > -1 ||
        data.soCongTo?.toUpperCase().indexOf(term.trim().toUpperCase()) > -1);
      setDataDoiSoat(listTerm)
      setTotalCount(listTerm.length)
      let dsoatDat = 0;
      let dsoatKhongDat = 0;
      dsoatDat = listTerm === undefined ? 0 : listTerm.filter(data => data.checkDoiSoatStr === "CHECK").length;
      dsoatKhongDat = listTerm === undefined ? 0 : listTerm.filter(data => data.checkDoiSoatStr === "UNCHECK").length;
      setDaDS(dsoatDat + dsoatKhongDat);
      setDSDat(dsoatDat);
      setDSKhongDat(dsoatKhongDat);
      setTotal(listTerm.length);
    } else {
      setDataDoiSoat(tempDataDoiSoat);
      setTotalCount(tempTotalCount);
      setDaDS(tempDaDS);
      setDSDat(tempDsDat);
      setDSKhongDat(tempDsKhongDat);
      setTotal(tempTotal);
    }
  }, [tempDaDS, tempDataDoiSoat, tempDsDat, tempDsKhongDat, tempTotal, tempTotalCount, term]);
  const handleChangeTrangThai = useCallback((values) => {
    if ( values === ENUM_GCSTRANGTHAISO.DANG_XAC_NHAN_CAC_CAP.toString() ) {
      setChecked(true);
      setDisableChecked(true);
      setFilterSoGCS((oldState) => ({
        ...oldState,
        TrangThai: values,
        Thang: thang,
        Nam: nam,
        BiTraLai: true
      }));
    } else {
      setChecked(false);
      setDisableChecked(false);
      setFilterSoGCS((oldState) => ({
        ...oldState,
        TrangThai: values,
        Thang: thang,
        Nam: nam,
        BiTraLai: null
      }));
    }
    setFilterSoGCS((oldState) => ({
      ...oldState,
      TrangThai: values,
      Thang: thang,
      Nam: nam,
    }));
    setSelectedValues([]);
    setDataDoiSoat([]);
    setTotalCount(0);
    setSoGCS([]);
    setDaDS(0);
    setDSDat(0);
    setDSKhongDat(0);
    setTotal(0);
  }, [nam, thang]);

  const handleChangeDonVi = useCallback((values) => {
    setFilterSoGCS((oldState) => ({
      ...oldState,
      DonViId: values,
      Thang: thang,
      Nam: nam,
    }));
    setSelectedValues([]);
    setDataDoiSoat([]);
    setTotalCount(0);
    setSoGCS([]);
    setDaDS(0);
    setDSDat(0);
    setDSKhongDat(0);
    setTotal(0);
  }, [nam, thang]);

  const handleChangeKyGCS = useCallback((values) => {
    setFilterSoGCS((oldState) => ({
      ...oldState,
      Ky: values,
      Thang: thang,
      Nam: nam,
    }));
    setSelectedValues([]);
    setDataDoiSoat([]);
    setTotalCount(0);
    setSoGCS([]);
    setDaDS(0);
    setDSDat(0);
    setDSKhongDat(0);
    setTotal(0);
  }, [nam, thang]);

  const handleChangeThangNam = useCallback((values) => {
    let tempThang = "";
    let tempNam = "";
    if (moment(values).isValid()) {
      tempThang = moment(values).format("MM-YYYY").substring(0, 2);
      tempNam = moment(values).format("MM-YYYY").slice(-4);
      setThang(tempThang);
      setNam(tempNam);
    } else {
      setThang();
      setNam();
    }
    setFilterSoGCS((oldState) => ({
      ...oldState,
      Thang: tempThang,
      Nam: tempNam,
    }));
    setSelectedValues([]);
    setDataDoiSoat([]);
    setTotalCount(0);
    setSoGCS([]);
    setDaDS(0);
    setDSDat(0);
    setDSKhongDat(0);
    setTotal(0);
  }, []);

  const handleChangeCheckBox = useCallback((e) => {
    setChecked(e.target.checked);
    setFilterSoGCS((oldState) => ({
      ...oldState,
      BiTraLai: e.target.checked === true ? true : null,
      Thang: thang,
      Nam: nam,
    }));
    setSelectedValues([]);
    setDataDoiSoat([]);
    setTotalCount(0);
    setSoGCS([]);
    setDaDS(0);
    setDSDat(0);
    setDSKhongDat(0);
    setTotal(0);
  }, [nam, thang]);

  const handleChangeSoGCS = useCallback((values) => {
    setSelectedValues(values)
    setSoGCS(values)
    setDataDoiSoat([]);
    setTotalCount(0);
    if (values.length === 0) {
      setDaDS(0);
      setDSDat(0);
      setDSKhongDat(0);
      setTotal(0);
    }
  }, []);
  const handleSelect = useCallback(() => {
    setSelectedValues(allIds);
    setSoGCS(allIds);
    setDataDoiSoat([]);
    setTotalCount(0);
  }, [allIds]);
  const handleUnselect = useCallback(() => {
    setSelectedValues([])
    setSoGCS([])
    setDataDoiSoat([])
    setTotalCount(0);
    setDaDS(0);
    setDSDat(0);
    setDSKhongDat(0);
    setTotal(0);
  }, []);

  //fetch dữ liệu
  const getListData = useCallback(() => {
    handleListData(t,
      setLoading,
      filterConditions,
      location,
      setDataDoiSoat,
      setTempDataDoiSoat,
      setTotalCount,
      setTempTotalCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getListSoGCS = useCallback(() => {
    permission === ghi_chi_so.d15 ?
      (
        handleListSoGCSDH(
          setLoadingSoGCS,
          filterSoGCS,
          setDataSoGCS,
          soGCS,
          setDaDS,
          setDSDat,
          setDSKhongDat,
          setTotal,
          setTempDaDS,
          setTempDSDat,
          setTempDSKhongDat,
          setTempTotal,
          setAllIds,
          setListSelect)
      ) : permission === ghi_chi_so.d22
        ? (
          handleListSoGCS(
            setLoadingSoGCS,
            filterSoGCS,
            setDataSoGCS,
            soGCS,
            setDaDS,
            setDSDat,
            setDSKhongDat,
            setTotal,
            setTempDaDS,
            setTempDSDat,
            setTempDSKhongDat,
            setTempTotal,
            setAllIds,
            setListSelect)
        ) :
        (
          handleListSoGCSDT(
            setLoadingSoGCS,
            filterSoGCS,
            setDataSoGCS,
            soGCS,
            setDaDS,
            setDSDat,
            setDSKhongDat,
            setTotal,
            setTempDaDS,
            setTempDSDat,
            setTempDSKhongDat,
            setTempTotal,
            setAllIds,
            setListSelect)
        )
  }, [filterSoGCS, permission, soGCS]);
  const getListDonVi = () => {
    getListDonViService(setLoading, setDataDonVi);
  };

  useEffect(() => {
    getListDonVi();
  }, []);

  useEffect(() => {
    getListData();
  }, [getListData]);

  useEffect(() => {
    getListSoGCS();
  }, [getListSoGCS]);

  //pagination table
  const onChangePagination = (paging, _filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };
  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListData();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListData, setFetchDataTable]);

  const handleSubmitCheckboxDat = useCallback((id, trangthai) => {
    submitChangeCheckbox(t,
      setLoading,
      id,
      trangthai === "CHECK" ? "UNCHECK" : "CHECK",
      setFetchDataTable,
      getListSoGCS)
  }, [getListSoGCS, setFetchDataTable, t]);

  // Chang checkbox Khong Dat
  const handleSubmitCheckboxKhongDat = useCallback((id, trangthai) => {
    submitChangeCheckbox(t,
      setLoading,
      id,
      trangthai === "UNCHECK" ? "CHECK" : "UNCHECK",
      setFetchDataTable,
      getListSoGCS)
  }, [getListSoGCS, setFetchDataTable, t]);

  // Xuất ghi lại bằng MDMS
  const handleXuatGhiLaiMDMS = () => {
    if (soGCS === undefined || soGCS.length === 0) {
      notification.error({
        message: `Thông báo`,
        description: "Sổ GCS không được để trống",
      });
    } else {
      Modal.confirm({
        centered: true,
        title: "Xác nhận",
        content: `Bạn đồng ý thực hiện xuất ghi lại bằng đo xa đối với các biểu có trạng thái [Không đạt] ?`,
        onOk() {
          //call api
          submitXuatGhiLaiMDMS(t, setLoading, setFetchDataTable, soGCS, setFilterConditions, getListSoGCS, setSoGCS, setSelectedValues);
        },
        onCancel() { },
        okText: "Đồng ý",
        cancelText: "Hủy",
      });
    }

  };

  // Xuất ghi lại bằng MTB
  const handleXuatGhiLaiMTB = () => {
    if (soGCS === undefined || soGCS.length === 0) {
      notification.error({
        message: `Thông báo`,
        description: "Sổ GCS không được để trống",
      });
    } else {
      Modal.confirm({
        centered: true,
        title: "Xác nhận",
        content: `Bạn đồng ý thực hiện xuất ghi lại bằng MTB đối với các biểu có trạng thái [Không đạt] ?`,
        onOk() {
          //call api
          submitXuatGhiLaiMTB(t, setLoading, setFetchDataTable, soGCS, setFilterConditions, getListSoGCS, setSoGCS, setSelectedValues);
        },
        onCancel() { },
        okText: "Đồng ý",
        cancelText: "Hủy",
      });
    }
  };

    // Xuất ghi lại bằng MTB
    const handleChuyenHHU = () => {
      if (soGCS === undefined || soGCS.length === 0) {
        notification.error({
          message: `Thông báo`,
          description: "Sổ GCS không được để trống",
        });
      } else {
        Modal.confirm({
          centered: true,
          title: "Xác nhận",
          content: `Bạn đồng ý thực hiện chuyển HHU cho các sổ đã chọn ?`,
          onOk() {
            //call api
            submitChuyenHHU(t, setLoading, setFetchDataTable, soGCS, setFilterConditions, getListSoGCS, setSoGCS, setSelectedValues);
          },
          onCancel() { },
          okText: "Đồng ý",
          cancelText: "Hủy",
        });
      }
    };
  // Hoàn thành đối soát
  const handleHoanThanhDS = () => {
    if (soGCS === undefined || soGCS.length === 0) {
      notification.error({
        message: `Thông báo`,
        description: "Sổ GCS không được để trống",
      });
    } else {
      submitHoanThanhDS(t, setLoading, setFetchDataTable, soGCS.toString());
    }
  };
  // Tự động đối soát
  const handleTuDongDS = useCallback(() => {
    if (!soGCS || isEmpty(soGCS)) {
      notification.error({
        message: `Thông báo`,
        description: "Sổ GCS không được để trống",
      });
    } else {
      submitTuDongDS(
        t,
        setLoading,
        soGCS.toString(),
        setFetchDataTable,
        // getListSoGCS,
        onSuccessTuDongDs
      );
    }
  }, [getListSoGCS, setFetchDataTable, soGCS, t]);

  const onSuccessTuDongDs = () => {
    setFilterConditions({...filterConditions, lichGCSs: null});
    setSelectedValues([]);
  }

  const handleChangeBK = useCallback((values) => {
    setBangKe(values)
  }, []);

  // Call API In Bang Ke
  const handleInBangKe = useCallback(() => {
    if (soGCS === undefined || soGCS.length === 0) {
      notification.error({
        message: `Thông báo`,
        description: "Sổ GCS không được để trống",
      });
    } else {
      const listMaSo = [];
      for (let i = 0; i < dataSoGCS.length; i++) {
        for (let j = 0; j < soGCS.length; j++) {
          if (soGCS[j] === dataSoGCS[i].id) {
            listMaSo.push(dataSoGCS[i].maSoGCS)
          }
        }
      }
      submitInBK(bangKe, setLoading, listMaSo, filterSoGCS)
    }
  }, [bangKe, dataSoGCS, filterSoGCS, soGCS]);

  const columns = [
    {
      title: t("doi_soat.thong_tin"),
      dataIndex: "thongTin",
      key: "thongTin",
      render: (_text, record) => (
        <div className="">
          <Row>
            <span>Tên Khách Hàng:
              <span style={{ marginLeft: "10px", fontWeight: "bold" }}>{record.tenKhachHang}</span>
            </span>
          </Row>
          <Row>
            <span>Địa chỉ: <span style={{ marginLeft: "10px" }}>{record.diaChi}</span></span>
          </Row>
          <Row gutter={24}>
            <Col md={8}>
              <span>Tình trạng mới: </span>
            </Col>
            <Col md={6}>
              <span>{record.trangThaiMoi !== "Bình thường" ? <span style={{color: "red", fontWeight: "bold", backgroundColor: "yellow"}}>{record.trangThaiMoi}</span> : "Bình thường"}</span>
            </Col>
            <Col md={5} >
              {record.nvgcsTralai === true ?
                <Tag style={{ textAlign: "center" }} color={"#faad14"}>{"Bị trả lại"}</Tag> :
                ""}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={8}>
              <span>Loại bộ chỉ số: </span>
            </Col>
            <Col md={6}>
              <span>{record.loaiBCS}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={8}>
              <span>Mã trạm: </span>
            </Col>
            <Col md={6}>
              <span>{record.maTram}</span>
            </Col>
            <Col md={5}>
              <span>Số công tơ: </span>
            </Col>
            <Col md={5}>
              <span style={{ color: "blue", fontWeight: "bold" }}>{record.soCongTo}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={8}>
              <span>Mã khách hàng: </span>
            </Col>
            <Col md={16}>
              <span>{record.maKhachHang}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={8}>
              <span>Chỉ số cũ: </span>
            </Col>
            <Col md={6}>
              <span style={{ color: "blue", fontWeight: "bold" }}>{record.chiSoCu}</span>
            </Col>
            <Col md={5}>
              <span>Chỉ số mới: </span>
            </Col>
            <Col md={5}>
              <span style={{ color: "blue", fontWeight: "bold" }}>{record.chiSoMoi}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={8}>
              <span>Sản lượng mới: </span>
            </Col>
            <Col md={6} >
              <span style={{ color: "blue", fontWeight: "bold" }}>{record.sanLuongMoi}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={8}>
              <span>ĐNTT tháng liền kề: </span>
            </Col>
            <Col md={16} >
              <span style={{ color: "blue", fontWeight: "bold" }}>{record.dnttThangLienKe}</span>
            </Col>
          </Row>
        </div>
      ),
      width: "50%",
    },
    {
      title: t("doi_soat.hinh_anh"),
      dataIndex: "hinhAnh",
      key: "hinhAnh",
      render: (_text, record) => (
        record.anhGCS === undefined ? record.anhGCS :
          <Image
            preview={false}
            src={record.anhGCS}
            alt={"image"}
            width={"100%"}
            height={"200px"}
            style={{ objectFit: "cover" }}
            onClick={() => handleOpenImage(record.anhGCS)}
          />
      ),
      width: "20%",
    },
    {
      title: t("doi_soat.dat"),
      dataIndex: "dat",
      key: "dat",
      render: (_text, record) => (
        <div className="table-text-center">
          <Checkbox checked={record.checkDoiSoatStr === "CHECK"}
            onClick={() => handleSubmitCheckboxDat(record.id, record.checkDoiSoatStr)}
          ></Checkbox>
        </div>
      ),
      width: "7%",
    },
    {
      title: t("doi_soat.khong_dat"),
      dataIndex: "khongDat",
      key: "khongDat",
      render: (_text, record) => (
        <div className="table-text-center">
          <Checkbox checked={record.checkDoiSoatStr === "UNCHECK"}
            onClick={() => handleSubmitCheckboxKhongDat(record.id, record.checkDoiSoatStr)}></Checkbox>
        </div>
      ),
      width: "13%",
    },
    {
      title: "Tác vụ",
      dataIndex: "",
      key: "",
      render: (_text, record) => (
        <Button
          hidden={record.maTrangThai === ENUM_GCSTRANGTHAISO.HOAN_TAT_DS_TU_DONG.toString() || 
            record.maTrangThai === ENUM_GCSTRANGTHAISO.DANG_XAC_NHAN_CAC_CAP.toString() 
          }
          type="primary"
          style={{ display: "block", margin: "auto" }}
          onClick={() => handleNhapChiSo(record.chiSoMoi, record.anhGCS, record.id)}
        >
          Nhập chỉ số
        </Button>
      ),
    },
  ];
  const handleNhapChiSo = (csMoi, anh, id) => {
    setVisibleCapNhatchiSo(true);
    setAnhGCS(anh);
    setChiSoMoi(csMoi);
    setIdThemCS(id)
  }
  const handleOpenImage = (srcImage) => {
    setVisibleViewImage(true)
    setImage(srcImage)
  };
  const handleCloseImage = () => {
    setVisibleViewImage(false)
    setImage()
  };
  const handleCloseThemChiSo = () => {
    setVisibleCapNhatchiSo(false)
    setImage()
  };

  const showImage = useMemo(
    () =>
      visibleViewImage && (
        <ViewImage
          showModal={visibleViewImage}
          setShowModal={setVisibleViewImage}
          src={image}
          closeImage={handleCloseImage}
        />
      ),
    [image, visibleViewImage]
  );

  const showCapNhatChiSo = useMemo(
    () =>
      visibleCapNhatchiSo && (
        <ThemChiSo
          showModal={visibleCapNhatchiSo}
          setShowModal={setVisibleCapNhatchiSo}
          closeThemChiSo={handleCloseThemChiSo}
          chiSoMoi={chiSoMoi}
          anhGCS={anhGCS}
          id={idThemCS}
          setFetchDataTable={setFetchDataTable}
        />
      ),
    [anhGCS, chiSoMoi, idThemCS, setFetchDataTable, visibleCapNhatchiSo]
  );

  return (
    <Fragment>
      <Filter>
        <ListFilterDS
          handleSearch={handleSearch}
          filterConditions={filterConditions}
          filterSoGCS={filterSoGCS}
          dataSoGCS={dataSoGCS}
          dataDonVi={dataDonVi}
          handleChangeDonVi={handleChangeDonVi}
          handleChangeSoGCS={handleChangeSoGCS}
          handleChangeTrangThai={handleChangeTrangThai}
          handleChangeKyGCS={handleChangeKyGCS}
          handleChangeThangNam={handleChangeThangNam}
          handleChangeCheckBox={handleChangeCheckBox}
          handleHoanThanhDS={handleHoanThanhDS}
          handleTuDongDS={handleTuDongDS}
          checked={checked}
          permission={permission}
          handleSelect={handleSelect}
          handleUnselect={handleUnselect}
          selectedValues={selectedValues}
          listSelect={listSelect}
          disableChecked={disableChecked}
        />
      </Filter>
      <Container>
        <Row>
          <div className="title mt-16px" style={{ marginLeft: "-30px" }}>
            <p style={{ fontSize: 16 }}>
              Tổng KH đã đối soát: <span style={{ color: "blue" }}>{daDS}/{total}</span>
            </p>
            <p style={{ fontSize: 16, marginLeft: 10 }}>
              Tổng KH đối soát đạt:<span style={{ color: "pink" }}>{dsDat}</span>
            </p>
            <p style={{ fontSize: 16, marginLeft: 10 }}>
              Tổng KH đối soát không đạt:<span style={{ color: "red" }}>{dsKhongDat}</span>
            </p>
          </div>
          <CheckPermission
            permissionCode={
              permission === ghi_chi_so.d15 ? ghi_chi_so.d15xg :
                (permission === ghi_chi_so.d22 ? ghi_chi_so.d22xu : ghi_chi_so.d32xg)}>
            <Button type="primary" style={{ float: "right", marginRight: "10px" }} onClick={() => handleChuyenHHU()} loading={loading}>
              Chuyển HHU
            </Button>
          </CheckPermission>
          <CheckPermission
            permissionCode={
              permission === ghi_chi_so.d15 ? ghi_chi_so.d15xg :
                (permission === ghi_chi_so.d22 ? ghi_chi_so.d22xu : ghi_chi_so.d32xg)}>
            <Button type="primary" style={{ float: "right", marginRight: "10px" }} onClick={() => handleXuatGhiLaiMDMS()} loading={loading}>
              Xuất ghi lại bằng đo xa
            </Button>
          </CheckPermission>
          <CheckPermission
            permissionCode={
              permission === ghi_chi_so.d15 ? ghi_chi_so.d15xg :
                (permission === ghi_chi_so.d22 ? ghi_chi_so.d22xu : ghi_chi_so.d32xg)}>
            <Button type="primary" style={{ float: "right", marginRight: "10px" }} onClick={() => handleXuatGhiLaiMTB()} loading={loading}>
              Xuất ghi lại bằng MTB
            </Button>
          </CheckPermission>
        </Row>
        <CheckPermission
          permissionCode={
            permission === ghi_chi_so.d15 ? ghi_chi_so.d15p :
              (permission === ghi_chi_so.d22 ? ghi_chi_so.d22p : ghi_chi_so.d32p)}>
          <Row gutter={24} style={{ marginLeft: "-3px" }}>
            <Col style={{ paddingTop: "5px" }}>
              <span>Bảng kê:</span></Col>
            <Col md={8}>
              <Select defaultValue={ENUM_DS_INBK.KH_KHONG_CHUP_ANH} style={{ width: "100%" }} allowClear={true} onChange={handleChangeBK}>
                <Option value={ENUM_DS_INBK.KH_KHONG_CHUP_ANH}>
                  01. Danh sách khách hàng không chụp ảnh
                </Option>
                <Option value={ENUM_DS_INBK.KH_KHONG_DUOC_DS}>
                  02. Danh sách khách hàng không được đối soát
                </Option>
                <Option value={ENUM_DS_INBK.KH_DA_DS}>03. Danh sách khách hàng đã đối soát</Option>
                <Option value={ENUM_DS_INBK.KH_DS_DAT}>04. Danh sách khách hàng đối soát đạt</Option>
                <Option value={ENUM_DS_INBK.KH_DS_KHONG_DAT}>
                  05. Danh sách khách hàng đối soát không đạt
                </Option>
              </Select>
            </Col>
            <Col span={24} md={8}>
              <Button disabled={bangKe === undefined} className="mr-24px" type="primary" onClick={() => handleInBangKe()} loading={loading}>
                In bảng kê
              </Button>
            </Col>
          </Row>
        </CheckPermission>
        <Row justify="end">
          <Input
            placeholder="Tìm kiếm Tên khách hàng, Số công tơ, Mã trạm"
            style={{ width: 350, marginRight: 10 }}
            onChange={changeTerm}
            onPressEnter={() => SearchTerm()}
          />
        </Row>
        <TableComponent
          header={t("doi_soat.doi_soat_du_lieu")}
          dataSource={dataDoiSoat}
          columns={customColumn(columns, filterConditions)}
          totalData={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          setFetchDataTable={setFetchDataTable}
          pagination={filterConditions}
        />
      </Container>
      {showImage}
      {showCapNhatChiSo}
    </Fragment>
  );
}
