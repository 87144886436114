import { BASE_API_URL_TREO_THAO } from "@utils/constants";

export const APINVTreoThao = {
  //api ký duyệt
  UPDATE_NV_TREOTHAO_KY_DUYET_XAC_NHAN: `${BASE_API_URL_TREO_THAO}/nhan-vien-treo-thao/ky-duyet/xac-nhan`,
  UPDATE_NV_TREOTHAO_KY_DUYET_KY_SO_USB: `${BASE_API_URL_TREO_THAO}/nhan-vien-treo-thao/ky-duyet/ky-so-usb`,
  UPDATE_NV_TREOTHAO_KY_DUYET_HASH_KY_SO_USB: `${BASE_API_URL_TREO_THAO}/nhan-vien-treo-thao/ky-duyet/ky-so-get-hash`,
  XAC_NHAN_KY_DUYET_YEU_CAU_NV_TT_NP: `${BASE_API_URL_TREO_THAO}/nhan-vien-treo-thao/nvttnp-xac-nhan-yeu-cau`,

  LAM_MOI_YEU_CAU_TREO_THAO: `${BASE_API_URL_TREO_THAO}/yeu-cau-treo-thao/lam-moi-yeu-cau`,

  UPLOAD_PDF_BBTT_GIAY: `${BASE_API_URL_TREO_THAO}/nhan-vien-treo-thao/upload-bbtt-giay`,
  GET_PDF_BBTT_GIAY: `${BASE_API_URL_TREO_THAO}/nhan-vien-treo-thao/bbtt-giay`,
  XN_UPLOAD_PDF_BBTT_GIAY: `${BASE_API_URL_TREO_THAO}/nhan-vien-treo-thao/xac-nhan-upload-bbtt-giay`,
  CANCEL_PHAN_CONG_NV_TREO_THAO: `${BASE_API_URL_TREO_THAO}/nhan-vien-treo-thao/huy-phan-cong`,

  //api Xem biên bản
  //GET_NV_TREOTHAO_BIEN_BAN: `${BASE_API_URL_TREO_THAO}/nhan-vien-treo-thao/xem-bien-ban`,
  //api duyệt phiếu
};
