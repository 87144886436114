import styled from "styled-components";
export const Style = styled.div`
  .phan-phong-modal {
    .ant-form-item-label > label:after {
      display: none;
    }
    .ant-form-item-label > label {
      margin-right: 14px;
    }
  }
  .yeu-cau-tra-ve-doi-table {
    padding: 20px 10px;
    .ant-select {
      margin-left: 10px;
    }
  }
  .yeu-cau-cho-huy-table {
    padding: 20px 10px;
  }
  .yeu-cau-cho-chuyen-phong-table {
    padding: 20px 10px;
  }
  .yeu-cau-cho-chuyen-phong-table {
    .ant-select {
      margin-left: 10px;
    }
    .yeu-cau-cho-chuyen-phong-table-button {
      margin-right: 10px;
    }
  }
  .yeu-cau-cho-huy-table {
    .ant-select {
      margin-left: 10px;
    }
    .yeu-cau-cho-huy-table-button {
      margin-right: 10px;
    }
  }
  .ant-row.ant-form-item {
    padding: 0 10px;
  }
`;

export const ChiTietYeuCauStyle = styled.div`
  padding: 10px 40px;
  .modal-header {
    height: 48px;
    background: #279bef;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    .close-icon {
      :hover {
        cursor: pointer;
      }
    }
    margin-bottom: 20px;
  }
  .field_wrap {
    display: flex;

    h5.c-t-label {
      flex: 0 0 150px;
    }
  }
  .ant-select {
    width: 100%;
  }
  .ant-btn:active {
    color: #000;
    border-color: #000;
  }
  .ant-btn:focus {
    color: #000;
    border-color: #000;
  }
  .ant-btn:hover {
    color: #000;
    border-color: #000;
  }
`;
