import { Endpoint } from "@utils/endpoint";
import { authSelector } from "@state/auth";
import { Col, Form, Input, Row, Select, Button, Checkbox } from "antd";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import DatePickerComponent from "@components/DatePicker";
import Selection from "@components/Select";
import FormComponent from "@components/Form";
import moment from "moment"
import { 
  ENUM_KY_GCS, 
  ENUM_TRANGTHAI_DTKBK
} from "@utils/constants";
const { Option } = Select;

export function ListFilterKyBangKe(props) {
  const { 
    handleSearch, 
    clearFilter, 
    filterConditions,
    checked,
    handleChangeCheckBox,
    loaiBK,
    firstLoaiBK
  } = props;
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        DonViId: user.unitId.toString(),
      }}
    >
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="DonViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Selection
                url={Endpoint.GET_UNIT_LIST}
                form={form}
                formKey="DonViId"
              />
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.DonViId !== currentValues.DonViId) {
                form.setFieldsValue({
                  ToDoiId: undefined,
                });
              }
              return prevValues.DonViId !== currentValues.DonViId;
            }}
          >
            {({ getFieldValue }) => {
              if (getFieldValue("DonViId")) {
                return (
                  <Form.Item name="ToDoiId" label="Tổ đội">
                    <Selection
                      url={`${Endpoint.GET_TEAM_LIST}?donviId=${getFieldValue(
                        "DonViId"
                      )}`}
                      formKey="ToDoiId"
                      form={form}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="ToDoiId" label="Tổ đội">
                  <Selection
                    url={`${Endpoint.GET_TEAM_LIST}?donviId=${user.unitId}`}
                    formKey="ToDoiId"
                    form={form}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item name="MaSo" label="Mã sổ">
            <Input/>
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item name="TenSo" label="Tên sổ">
            <Input/>
          </Form.Item>
        </Col>
        <Col span={24} md={4} style={{marginTop: 34}}>
          <Checkbox onChange={handleChangeCheckBox} checked={checked}>Bị trả lại</Checkbox>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24} md={4}>
          <Form.Item name="Ky" label="Kỳ GCS">
            <Select defaultValue={ENUM_KY_GCS.KY_1} placeholder={"Tất cả"} allowClear>
              <Option value={ENUM_KY_GCS.KY_1}>1</Option>
              <Option value={ENUM_KY_GCS.KY_2}>2</Option>
              <Option value={ENUM_KY_GCS.KY_3}>3</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="NgayGhi" label="Ngày ghi">
            <DatePickerComponent
              placeholder={"Chọn ngày ghi"}
              defaultValue={moment()}
              format={"YYYY-MM-DD"}
              form={form}
              formKey={"NgayGhi"}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="TrangThai" label="Trạng Thái">
            <Select defaultValue={ENUM_TRANGTHAI_DTKBK.CHUA_KY.toString()} placeholder={"Tất cả"} allowClear>
              <Option value={ENUM_TRANGTHAI_DTKBK.CHUA_KY.toString()}>Chưa ký</Option>
              <Option value={ENUM_TRANGTHAI_DTKBK.DA_KY.toString()}>Đã ký</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="LoaiBangKeId" label="Loại bảng kê"  defaultValue={firstLoaiBK}>
            <Select placeholder={"Tất cả"} allowClear>
              {loaiBK && loaiBK.length > 0
              ? loaiBK.map((item, index) => {
                if (item.id !== null) {
                  return (
                    <Option key={index} value={item.id}>
                      {item.tenLoaiBangKe}
                    </Option>
                  );
                }
                return null;
              })
              : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Button className="mr-24px" onClick={clear}>
          Bỏ lọc
        </Button>
        <Button type="primary" htmlType="submit" form="filter-form">
          Tìm kiếm
        </Button>
      </Row>
    </FormComponent>
  );
}