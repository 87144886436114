import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { EndpointHDHoaDonTK, handleDownload, handleOpenPdf } from "./service";

const selectLoaiKhachHang = [
  {
    label: "Tất cả",
    value: 2,
  },
  {
    label: "Sinh hoạt",
    value: 1,
  },
  {
    label: "Ngoài sinh hoạt",
    value: 0,
  },
];

export default function BaoCaoTHHDTheoKy() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    handleDownload({ ...values, reportType: EndpointHDHoaDonTK }, setLoading);
    console.log(values.TuNgay);
  };

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Báo cáo tổng hợp hóa đơn theo kỳ
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              colon={false}
              initialValues={{
                LoaiKhachHang: 2,
              }}
            >
              <Form.Item
                name="LoaiKhachHang"
                label="Loại khách hàng"
                labelAlign="left"
                required
              >
                <Select
                  placeholder="Chọn loại khách hàng"
                  options={selectLoaiKhachHang}
                ></Select>
              </Form.Item>

              <Form.Item
                name="TuNgay"
                label="Đến ngày"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="Day"
                  formKey="TuNgay"
                  form={form}
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>

              <Row>
                <Col span={4}></Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>

                  {/* <Button
                    type="primary"
                    className="ml-20px"
                    loading={loading}
                    onClick={() => {
                      handleOpenPdf(
                        {
                          ...form.getFieldValue(),
                          reportType: EndpointHDHoaDonTK,
                        },
                        setLoading
                      );
                    }}
                  >
                    In báo cáo
                  </Button> */}
                </Col>
              </Row>
            </FormComponent>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
