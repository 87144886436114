import { authGetData } from "@utils/request";
import { APICommonTreoThao } from "@apiUrl/treo-thao/common";
import { STATUSCODE_200 } from "@utils/constants";

export const xemPhieu = (id, setLoading, setData) => {
  authGetData({
    url: `${APICommonTreoThao.VIEW_PHIEU}?hoSoId=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setData(res.data);
      }
    },
  });
};
