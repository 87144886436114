import styled from "styled-components";

export const TableAction = styled.div`
  .action-icon {
    display: none !important;
  }
`;

export const TablePanel = styled.div`
  .table-component-panel .ant-collapse-content.ant-collapse-content-active {
    min-height: 400px;
  }
`;

export const ContainerPanel = styled.div`
  padding: 12px;
`;

export const FullCol = styled.div`
  .ant-select.ant-select-single.ant-select-show-arrow,
  .ant-picker {
    width: 100%;
  }
`;

export const ContainerTable = styled.div`
  .ant-table-thead > tr > th {
    padding: 2px;
  }
`;
