import { Form, Input, Button, Modal, Row, Col, Select, Checkbox } from "antd";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";

const { Option } = Select;
const { TextArea } = Input;

export default function UITiLeBanDien(props) {
  const {
    loading,
    detailLeBanDien,
    visible,
    handleCloseModal,
    form,
    handleSubmitData,
    idBanDien,
    t,
    donViTinh,
    handleChangeDonViTinh,
    handleChangeMucDich,
    dataTiLe,
    handleChangeActive,
    isKhongTheoTG,
    // readOnly,
    getMucDich,
    getTiLe,
    getGioTD,
    getGioBT,
    getGioCD,
    getGioKT,
    inputRef,
  } = props;
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={
          detailLeBanDien.id
            ? t("lap_phuong_an.ty_le_ban_dien.cap_nhat_ti_le_gia_ban_dien")
            : t("lap_phuong_an.ty_le_ban_dien.ti_le_gia_ban_dien")
        }
        visible={visible}
        onCancel={handleCloseModal}
        className="modal-ty-le-ban-dien"
        footer={null}
      >
        <FormComponent
          form={form}
          name="form-ty-le-ban-dien"
          layout="vertical"
          onFinish={handleSubmitData}
          initialValues={{
            ...detailLeBanDien,
            tiLeGiaBanDienId: idBanDien,
            mucDichSuDung: detailLeBanDien.mucDichSuDung,
            isPhanTram: donViTinh,
            tiLePhanTram: detailLeBanDien.tiLePhanTram,
          }}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={24} md={24}>
              <Form.Item
                label={t("lap_phuong_an.muc_dich_su_dung_dien")}
                name="tiLeGiaBanDienId"
                // className="required-field"
              >
                <Select onChange={handleChangeMucDich}>
                  <Option value={null} key={null}>
                    -- Khác --
                  </Option>
                  {dataTiLe && dataTiLe.length > 0
                    ? dataTiLe.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.mucDichSuDung}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={24}>
              <Form.Item
                label="Diễn giải mục đích sử dụng điện"
                name="mucDichSuDung"
                className="required-field"
              >
                <TextArea
                  rows={3}
                  // readOnly={readOnly}
                  defaultValue={detailLeBanDien.mucDichSuDung}
                  onChange={(e) => getMucDich(e)}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label="Tỉ lệ"
                name="tiLePhanTram"
                className="required-field"
              >
                <Input
                  placeholder="Nhập tỉ lệ"
                  onChange={(e) => getTiLe(e)}
                  type="number"
                />
              </Form.Item>
            </Col>

            <Col span={24} md={12}>
              <Form.Item
                label={t("ap_gia_ho_so.ti_le_%_hoac_kwh")}
                name="isPhanTram"
                className="required-field"
              >
                <Select onChange={handleChangeDonViTinh}>
                  <Option value={0}>kWh</Option>
                  <Option value={1}>%</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={24} className="mb-15px">
              <Form.Item
                label=""
                name="isKhongTheoTG"
                style={{ marginBottom: "0" }}
              >
                <Checkbox onChange={handleChangeActive} checked={isKhongTheoTG}>
                  Không theo thời gian
                </Checkbox>
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={24}
              style={{ display: isKhongTheoTG ? "block" : "none" }}
            >
              <Form.Item
                // label={"Nhập giá bán điện không theo thời gian"}
                name="gioKT"
                className="required-field"
              >
                <Input
                  placeholder="Nhập giá bán điện không theo thời gian"
                  onChange={(e) => getGioKT(e)}
                />
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={24}
              style={{ display: isKhongTheoTG ? "none" : "block" }}
            >
              <Form.Item
                label={t("ap_gia_ho_so.gio_binh_thuong")}
                name="gioBinhThuong"
                className="required-field"
              >
                <Input
                  ref={inputRef}
                  placeholder="Nhập giá bán điện giờ bình thường"
                  onChange={(e) => getGioBT(e)}
                />
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={24}
              style={{ display: isKhongTheoTG ? "none" : "block" }}
            >
              <Form.Item
                label={t("ap_gia_ho_so.gio_cao_diem")}
                name="gioCaoDiem"
                className="required-field"
              >
                <Input
                  placeholder="Nhập giá bán điện giờ cao điểm"
                  onChange={(e) => getGioCD(e)}
                />
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={24}
              style={{ display: isKhongTheoTG ? "none" : "block" }}
            >
              <Form.Item
                label={t("ap_gia_ho_so.gio_thap_diem")}
                name="gioThapDiem"
                className="required-field"
              >
                <Input
                  placeholder="Nhập giá bán điện giờ thấp điểm"
                  onChange={(e) => getGioTD(e)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button
                key="back"
                onClick={handleCloseModal}
                className="button-closed"
              >
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-ty-le-ban-dien"
                loading={loading}
              >
                {detailLeBanDien.id
                  ? t("button.cap_nhat")
                  : t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}
