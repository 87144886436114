import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData, authDeleteData } from "@utils/request";
import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import {
  buildQueryString,
  parseParams,
  alertMessage
} from "@utils/function";

// get list data cấu hình ký bảng kê
export const handleListDataCauHinhKBK = (
  setLoading,
  location,
  setDataCauHinhKBK,
  setTotalCount,
  filterConditions
) => {
  let url = "";
    url =  `${Endpoint.LIST_CAU_HINH_KBK}?${buildQueryString(
      Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
    )}`;
    authGetData({
      url,
      setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataCauHinhKBK(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataCauHinhKBK([]);
    },
  });
};

export const getListLoaiBK = (
  setLoading,
  setLoaiBK,
  setFirstLoaiBK
) => {
  let url = "";
    url =  Endpoint.LIST_LOAI_BK
    authGetData({
      url,
      setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setLoaiBK(res.data);
        setFirstLoaiBK(res.data.find(x => x.maLoaiBangKe === 'BKCS').id)
      } else 
      {
        setFirstLoaiBK("");
        setLoaiBK([]);
      }
    },
  });
};

export const submitThemMoiCauHinhKBK = (
  bangKe, ghiChu, check, t, setLoading, setFetchDataTable, unit
) => {
  authPostData({
    url: Endpoint.CREATE_CAU_HINH_KBK,
    method: METHOD_POST,
    payload: {
      donViId: unit,
      loaiBangKeId: bangKe,
      ghiChu: ghiChu,
      kySo: check
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const submitUpdateCauHinhKBK = (
  id, ghiChu, setLoading, t, setFetchDataTable, setEditingKey
) => {
  authPostData({
    url: Endpoint.UPDATE_CAU_HINH_KBK,
    method: METHOD_PUT,
    payload: {
      id: id,
      ghiChu: ghiChu.ghiChu,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setEditingKey('')
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const submitXoaCauHinhKBK = (id,  setLoading, setFetchDataTable, t) => {
  authDeleteData({
    url: `${Endpoint.REMOVE_CAU_HINH_KBK}/${id}`,
    setLoading,
    content: `Bạn chắc chắn muốn xóa ?`,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) setFetchDataTable(true);
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};