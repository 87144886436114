import React, { Fragment } from "react";
import { Button, Col, Modal, Row, Input } from "antd";
import LoadingComponent from "@components/Loading";
import Text from "antd/lib/typography/Text";
import {
  ButtonSearchResult,
  Container,
  HeaderSearchResult,
  ItemSearchResult,
} from "../xac-nhan-thi-cong/css-styled";

const { Search } = Input;

export default function DSCongToModal(props) {
  const {
    loading,
    visible,
    closeModal,
    data,
    handleSearch,
    handleChoice,
    typeThietBi
  } = props;
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={"Danh sách công tơ"}
        visible={visible}
        onCancel={closeModal}
        footer={null}
        width={"65%"}
      >
        <Row gutter={24} style={{ marginBottom: "24px" }}>
          <Col span={24} md={12}>
            <Search
              placeholder="Nhập số công tơ"
              allowClear={true}
              enterButton="Tìm"
              onSearch={(value) => handleSearch(value, typeThietBi)}
              onKeyDown={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
            />
          </Col>
          <Col span={24} md={12}></Col>
        </Row>
        {/* <Container> */}
        <Row gutter={24} style={{ maxHeight: "500px", overflow: "auto" }}>
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <Fragment key={index}>
                <Col span={24} md={24}>
                  <HeaderSearchResult>
                    {item.soBienBan}
                    <span>{item.soCongTo}</span>
                  </HeaderSearchResult>
                </Col>
                <Col span={24} md={24}>
                  <Container>
                    <ItemSearchResult>
                      Mã công tơ
                      <Text type="danger">{item.maCongTo}</Text>
                    </ItemSearchResult>
                    <ItemSearchResult>
                      Mã chủng loại
                      <Text>{item.maChungLoai}</Text>
                    </ItemSearchResult>
                    <ItemSearchResult>
                      Loại công tơ
                      <Text>{item.loaiCongTo}</Text>
                    </ItemSearchResult>
                    <ButtonSearchResult>
                      <Button
                        type="primary"
                        className="primary"
                        onClick={() => handleChoice(item)}
                        key="choice"
                        style={{ minWidth: "150px" }}
                      >
                        Chọn
                      </Button>
                    </ButtonSearchResult>
                  </Container>
                </Col>
                <div style={{ margin : 10}} />
              </Fragment>
            ))
          ) : (
            <div
              style={{
                color: "red",
                fontSize: "20px",
                textAlign: "center",
                width: "100%",
              }}
            >
              Không tìm thấy dữ liệu
            </div>
          )}
        </Row>
        {/* </Container> */}
      </Modal>
    </LoadingComponent>
  );
}
