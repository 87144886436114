import { authSelector } from "@state/auth";
import { Form } from "antd";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import FormBoLoc from "./form-bo-loc";

export default function ListFilter(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    donVi,
    // handleDownload,
    handleChangeUnit,
    selectedRowKeys,
    setSelectedRowKeys,
    getListBaoCao,
    setType,
    disabledChot,
    value,
    handleChangeValue,
  } = props;
  const { user } = useSelector(authSelector);

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  return (
    <FormBoLoc
      form={form}
      handleChangeUnit={handleChangeUnit}
      handleSearch={handleSearch}
      user={user}
      filterConditions={filterConditions}
      donVi={donVi}
      clear={clear}
      selectedRowKeys={selectedRowKeys}
      setSelectedRowKeys={setSelectedRowKeys}
      getListBaoCao={getListBaoCao}
      setType={setType}
      disabledChot={disabledChot}
      value={value}
      handleChangeValue={handleChangeValue}
    />
  );
}
