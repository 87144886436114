import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, authPostFileData, startDelete } from "@utils/request";

export const getDataYeuCau = ({
  location,
  endpoint,
  filterConditions,
  setLoading,
  setDataYeuCau,
  setTotalCount,
  hasMessage,
}) => {
  const queryParamsString = buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  );

  authGetData({
    url: hasMessage
      ? endpoint + "?" + queryParamsString + `&HasMessage=${hasMessage}`
      : endpoint + "?" + queryParamsString,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setDataYeuCau(res.data);
        setTotalCount(res.paging.totalCount);
      } else {
        setDataYeuCau([]);
      }
    },
  });
};

export const getLoaiYeuCau = (setLoading, setData) => {
  authGetData({
    url: `${Endpoint.CRM_LOAI_YEU_CAU}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getListDonVi = (setLoading, setData) => {
  authGetData({
    url: `${Endpoint.GET_UNIT_LIST}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getListLinhVuc = (setLoading, setData) => {
  authGetData({
    url: `${Endpoint.CRM_LIST_LINH_VUC}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

// Bo loc trang thai
// export const getTrangThaiYeuCau = (setLoading, setData) => {
//   authGetData({
//     url: `${Endpoint.CRM_LIST_TRANG_THAI_YEU_CAU}`,
//     setLoading,
//     onSuccess: (res) => {
//       if (res.statusCode === 200) {
//         setData(res.data);
//       }
//     },
//   });
// };

export const getListKenh = (setLoading, setData) => {
  authGetData({
    url: `${Endpoint.CRM_LIST_DANH_SACH_KENH}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getListTeam = (setLoading, setData, donVi) => {
  authGetData({
    url: `${Endpoint.GET_TEAM_LIST}?DonViId=${donVi}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const handleListImage = (detail, setLoading, setDataImage) => {
  authGetData({
    url: `${Endpoint.CRM_TAI_ANH}?YeuCauId=${detail.id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setDataImage(res.data);
      }
    },
  });
};

export const handleDeleteImage = (
  id,
  setLoading,
  detail,
  setDataImage,
  setVisibleDelete
) => {
  startDelete({
    url: `${Endpoint.CRM_TAI_ANH}/${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        handleListImage(detail, setLoading, setDataImage);
        setVisibleDelete(false);
      }
    },
  });
};

export const TaiAnhHoSo = (
  setLoading,
  file,
  setImageFile,
  detail,
  setDataImage,
  setIsSubmit,
  setLoadingListAnh
) => {
  authPostFileData({
    url: `${Endpoint.CRM_TAI_ANH}`,
    payload: {
      file,
      yeuCauId: detail.id,
    },
    method: METHOD_POST,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setImageFile([]);
        setIsSubmit(true);
        handleListImage(detail, setLoadingListAnh, setDataImage);
      } else if (res.statusCode === 422) {
      }
    },
  });
};
