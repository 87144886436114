import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Tabs } from "antd";
import ThongTinCoBanTabs from "./tabs/tab-thong-tin-co-ban";
import CongSuatThietBiTabs from "./tabs/tab-cong-suat-thiet-bi-dien";
import TyLeGiaBanDienTabs from "./tabs/tab-ty-le-gia-ban-dien";
import PhuongAnCapDienTabs from "./tabs/tab-phuong-an-cap-dien";
import DuTruVatTuCapDien from "./tabs/tab-du-tru-vat-tu-cap-dien";
import { ENUM_MODE_PHUONGANCAPDIEN } from "@utils/constants";
import { ContainerBody } from "../css-styled";
import { scrollToTop } from "@utils/function";
import { lapPhuongAnSelector } from "@state/cap-dien/nhan-vien-khao-sat/lap-phuong-an";
import { useSelector } from "react-redux";
import ModalWarningChange from "@components/Layout/ModalWarningChange";
import {
  notSavedPhuongAnCapDien,
  notSavedThongTinCoBan,
  rangeOfChange,
} from "@state/cap-dien/nhan-vien-khao-sat/lap-phuong-an/reducer";

const { TabPane } = Tabs;

export default function FormDetailThongTin(props) {
  const {
    indexTab,
    callback,
    detailLapPhuongAn,
    setNotSaved,
    t,
    notSaved,
    initTTCB,
    setInitTTCB,
    setFetchDataTable,
    initPACD,
    setInitPACD,
    dispatch,
    closeModal,
    formName,
  } = props;
  const ref1 = useRef();
  const [loading, setLoading] = useState(false);
  const { phuongAnCapDien, thongTinCoBan } = useSelector(lapPhuongAnSelector);

  useEffect(() => {
    scrollToTop(ref1);
  }, []);

  const onClose = useCallback(() => {
    if (phuongAnCapDien || thongTinCoBan) {
      ModalWarningChange(
        () => {
          dispatch(rangeOfChange(false));
          if (phuongAnCapDien) dispatch(notSavedPhuongAnCapDien(false));
          if (thongTinCoBan) dispatch(notSavedThongTinCoBan(false));
          dispatch(closeModal());
        },
        () => false // do nothing
      );
    } else {
      dispatch(closeModal());
    }
  }, [closeModal, dispatch, phuongAnCapDien, thongTinCoBan]);

  return (
    <Fragment>
      <div ref={ref1}>
        <ContainerBody className="modal-body">
          <Fragment>
            <Tabs
              defaultActiveKey={indexTab}
              onChange={callback}
              activeKey={indexTab}
            >
              <TabPane
                tab={
                  t("lap_phuong_an.thong_tin_co_ban.thong_tin_co_ban") +
                  (thongTinCoBan ? " *" : "")
                }
                key="1"
              >
                <ThongTinCoBanTabs
                  detailHoSo={detailLapPhuongAn}
                  setNotSaved={setNotSaved}
                  initTTCB={initTTCB}
                  setInitTTCB={setInitTTCB}
                  setFetchDataTable={setFetchDataTable}
                  notSaved={notSaved}
                  indexTab={indexTab}
                />
              </TabPane>
              <TabPane tab={t("lap_phuong_an.cong_suat_thiet_bi")} key="2">
                <CongSuatThietBiTabs
                  detailLapPhuongAn={detailLapPhuongAn}
                  indexTab={indexTab}
                />
              </TabPane>
              <TabPane tab={t("lap_phuong_an.ty_le_gia_ban_dien")} key="3">
                <TyLeGiaBanDienTabs
                  detaiHoSo={detailLapPhuongAn}
                  indexTab={indexTab}
                />
              </TabPane>
              <TabPane
                tab={
                  t("lap_phuong_an.phuong_an_cap_dien") +
                  (phuongAnCapDien ? " *" : "")
                }
                key="4"
              >
                <PhuongAnCapDienTabs
                  detailLapPhuongAn={detailLapPhuongAn}
                  setNotSaved={setNotSaved}
                  initPACD={initPACD}
                  setInitPACD={setInitPACD}
                  mode={ENUM_MODE_PHUONGANCAPDIEN.XEMTRENTAB}
                  indexTab={indexTab}
                  setLoading={setLoading}
                  loading={loading}
                  t={t}
                />
              </TabPane>
              {/* <TabPane tab={t("lap_phuong_an.vat_tu_cong_ty")} key="5">
              <VatTuCongTyTabs />
            </TabPane>
            <TabPane tab={t("lap_phuong_an.vat_tu_khach_hang")} key="6">
              <VatTuKhachHangTabs />
            </TabPane> */}
              <TabPane tab={"Dự trù vật tư cấp điện"} key="5">
                <DuTruVatTuCapDien
                  detailLapPhuongAn={detailLapPhuongAn}
                  indexTab={indexTab}
                />
              </TabPane>
            </Tabs>
          </Fragment>
        </ContainerBody>

        <div className="ant-modal-footer text-center">
          <Button type="default" onClick={onClose}>
            {t("button.dong_lai")}
          </Button>
          {indexTab === "1" || indexTab === "4" ? (
            <Button
              key="submit"
              type="primary"
              className="button-primary"
              htmlType="submit"
              form={formName}
              loading={loading}
            >
              {t("button.cap_nhat")}
            </Button>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
}
