import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import TableComponent from "@components/TableComponent";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_ENGLISH,
} from "@utils/constants";
import { handlePagination, parseParams } from "@utils/function";
import { Button, Col, Form, Row, Select, Spin } from "antd";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { exportData, handleDownload, selectBCMOMT } from "./service";

export default function Bieu8(props) {
  const { screen, handleChange } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const location = useLocation();
  const [data, setData] = useState();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
    DenNgay: moment().format(FORMAT_ENGLISH),
    ...parseParams(location.search),
  });
  const onFinish = (values) => {
    exportData(values, setLoading, setData);
  };
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      width: "70px",
    },
    {
      title: "Nội dung tin nhắn MO",
      dataIndex: "MSGBODY",
      key: "MSGBODY",
    },
    {
      title: "Ngày gửi",
      dataIndex: "NGAY_GUI",
      key: "NGAY_GUI",
    },
    {
      title: "Tình Trạng trả lời tin nhắn MT",
      dataIndex: "TINH_TRANG",
      key: "TINH_TRANG",
    },
    {
      title: "Nguyên nhân",
      dataIndex: "NGUYEN_NHAN",
      key: "NGUYEN_NHAN",
    },
  ];

  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              initialValues={{ reportType: screen }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="reportType"
                    label="Tên báo cáo"
                    labelAlign="left"
                  >
                    <Select
                      options={selectBCMOMT}
                      formKey="reportType"
                      form={form}
                      onChange={() =>
                        handleChange(form.getFieldValue("reportType"))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    name="TuNgay"
                    label="Ngày bắt đầu"
                    labelAlign="left"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <DatePickerComponent
                      formKey="TuNgay"
                      form={form}
                      labelAlign="left"
                      format={FORMAT_ENGLISH}
                      defaultValue={
                        filterConditions.TuNgay
                          ? moment(filterConditions.TuNgay, FORMAT_ENGLISH)
                          : moment()
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="DenNgay"
                    label="Ngày kết thúc"
                    labelAlign="right"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <DatePickerComponent
                      formKey="DenNgay"
                      form={form}
                      labelAlign="left"
                      format={FORMAT_ENGLISH}
                      defaultValue={
                        filterConditions.DenNgay
                          ? moment(filterConditions.DenNgay, FORMAT_ENGLISH)
                          : moment()
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end">
                <Button type="primary" htmlType="submit" className="mr-5px">
                  Xem báo cáo
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    handleDownload(form.getFieldValue(), setLoading);
                  }}
                >
                  Xuất Excel
                </Button>
              </Row>
            </FormComponent>
            <TableComponent
              header={"Kết quả hiển thị"}
              columns={columns}
              dataSource={data}
              scrollX={1500}
              pagination={filterConditions}
              onChangePagination={onChangePagination}
            />
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
