import DatePicker from "@components/DatePicker";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import {
  FORMAT_ENGLISH,
  FORMAT_YEAR,
  METHOD_GET,
  STATUSCODE_200,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { alertMessage, removeAccents } from "@utils/function";
import { authGetData } from "@utils/request";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
} from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { handlerSubmitDiemDo, TaoMaDiemDo } from "./services";

export default function ModalKhaiBaoDiemDo(props) {
  const {
    filterConditions,
    setDataDanhSach,
    closeModal,
    setLoadingDanhSach,
    detail,
    getDanhSachDiemDo,
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;
  const [data, setData] = useState([]);
  const [visibleCheckBox, setVisibleCheckBox] = useState(false);
  const [urlTinhChatDD, setUrlTinhChatDD] = useState([]);
  const [urlCapDienAp, setUrlCapDienAp] = useState([]);
  const [urlHTTT, setUrlHTTT] = useState([]);
  const [urlDvgn, setUrlDvgn] = useState([]);
  const [loading, setLoading] = useState(false);
  const [valueDD, setValueDD] = useState();
  const [urlTram, setUrlTram] = useState([]);
  const [taoMa, setTaoMa] = useState([]);

  // get giá trị mặc định trong input
  useEffect(() => {
    form.setFieldsValue({
      ...(urlCapDienAp.length > 0 && { capDienApId: urlCapDienAp[0].id }),
      ...(urlHTTT.length > 0 && { hinhThucThanhToanId: urlHTTT[0].id }),
      ...(urlTinhChatDD.length > 0 && {
        tinhChatDiemDoId: urlTinhChatDD[0].id,
      }),
    });
    if (urlTinhChatDD.length > 0) {
      setValueDD(urlTinhChatDD[0].id);
    }
  }, [data, form, urlCapDienAp, urlDvgn, urlHTTT, urlTinhChatDD]);

  // api tính chất điểm đo
  useEffect(() => {
    authGetData({
      url: `${Endpoint.GET_SELECTION_HT_DIEM_DO}`,
      method: METHOD_GET,
      setLoading,
      onSuccess: (res) => {
        setUrlTinhChatDD(res.data);
      },
    });
  }, [setLoading]);

  // capi cấp điện áp
  useEffect(() => {
    authGetData({
      url: `${Endpoint.GET_SELECTION_CAP_DIEN_AP}`,
      method: METHOD_GET,
      setLoading,
      onSuccess: (res) => {
        setUrlCapDienAp(res.data);
      },
    });
  }, [setLoading]);

  // get api hình thức thanh toán
  useEffect(() => {
    authGetData({
      url: `${Endpoint.GET_SELECTION_HINH_THUC_TT}`,
      method: METHOD_GET,
      setLoading,
      onSuccess: (res) => {
        setUrlHTTT(res.data);
      },
    });
  }, [setLoading]);

  //  get api gdown vị giao nhận
  useEffect(() => {
    authGetData({
      url: `${Endpoint.GET_SELECTION_DVGN}`,
      method: METHOD_GET,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          setUrlDvgn(res.data);
        }
      },
    });
  }, [setLoading]);

  // get api trạm
  useEffect(() => {
    if (valueDD) {
      authGetData({
        url: `${Endpoint.GET_SELECTION_TRAM}?TinhChatDiemDoId=${valueDD}`,
        method: METHOD_GET,
        setLoading,
        onSuccess: (res) => {
          if (res && valueDD !== undefined) {
            setUrlTram(res.data);
          }
        },
      });
    }
  }, [setLoading, valueDD]);

  // fetch data tạo mã ngày vào input
  useEffect(() => {
    if (taoMa) {
      form.setFieldsValue({
        ...taoMa,
        maDiemDo: taoMa.maDiemDo,
        ngayHieuLuc: taoMa.ngayHieuLuc,
        ngayThayDoi: taoMa.ngayHieuLuc,
        ngayTao: taoMa.ngayHieuLuc,
      });
    }
  }, [taoMa, form]);

  // fetch api mã điểm đo tự sinh
  const getTaoMa = useCallback(() => {
    TaoMaDiemDo(setTaoMa, setLoading, form, closeModal);
  }, [closeModal, form, setLoading]);

  useEffect(() => {
    getTaoMa();
  }, [getTaoMa]);

  // state kenh nhan/kenh giao
  const [isKGCG, setIsKGCG] = useState(detail?.kenhGiao === "G" ? true : false);
  const [isKGCN, setIsKGCN] = useState(detail?.kenhGiao === "N" ? true : false);
  const [isKNCG, setIsKNCG] = useState(detail?.kenhNhan === "G" ? true : false);
  const [isKNCN, setIsKNCN] = useState(detail?.kenhNhan === "N" ? true : false);
  const [isValueCGKG, setValueIsCGKG] = useState(
    detail?.kenhGiao === "N" ? true : false
  );
  const [isValueCNKG, setValueIsCNKG] = useState(
    detail?.kenhGiao === "G" ? true : false
  );
  const [isValueCGKN, setValueIsCGKN] = useState(
    detail?.kenhNhan === "N" ? true : false
  );
  const [isValueCNKN, setValueIsCNKN] = useState(
    detail?.kenhNhan === "G" ? true : false
  );

  const [isChange, setIsChange] = useState(false);

  const onChangeCheck = (e, field) => {
    if (field === "chieuGiaoKG") {
      if (e.target.checked) {
        setIsKGCN(true);
      } else {
        setIsKGCN(false);
      }
      setValueIsCGKG(e.target.checked);
    } else if (field === "chieuNhanKG") {
      if (e.target.checked) setIsKGCG(true);
      else {
        setIsKGCG(false);
      }
      setValueIsCNKG(e.target.checked);
    } else if (field === "chieuGiaoKN") {
      if (e.target.checked) setIsKNCN(true);
      else {
        setIsKNCN(false);
      }
      setValueIsCGKN(e.target.checked);
    } else {
      if (e.target.checked) setIsKNCG(true);
      else {
        setIsKNCG(false);
      }
      setValueIsCNKN(e.target.checked);
    }
  };

  useEffect(() => {
    if (isChange) {
      setIsKGCG(detail?.kenhGiao === "G" ? true : false);
      setIsKGCN(detail?.kenhGiao === "N" ? true : false);
      setIsKNCG(detail?.kenhNhan === "G" ? true : false);
      setIsKNCN(detail?.kenhNhan === "N" ? true : false);

      setValueIsCGKG(detail?.kenhGiao === "G" ? true : false);
      setValueIsCNKG(detail?.kenhGiao === "N" ? true : false);
      setValueIsCGKN(detail?.kenhNhan === "G" ? true : false);
      setValueIsCNKN(detail?.kenhNhan === "N" ? true : false);
      setIsChange(false);
    }
  }, [detail?.kenhGiao, detail?.kenhNhan, form, isChange]);

  const handleChangeCheckBox = () => {
    setVisibleCheckBox(!visibleCheckBox);
  };

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [closeModal, dispatch]);

  const onFinish = (values) => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: `Cập nhật thông tin khởi tạo cho điểm đo mới ?`,
      onOk() {
        const payload = {
          ...values,
          kenhGiao: isKGCN ? "N" : isKGCG ? "G" : null,
          kenhNhan: isKNCN ? "N" : isKNCG ? "G" : null,
        };
        let isDup = false;
        if (
          payload.kenhGiao === payload.kenhNhan &&
          payload.kenhGiao !== null &&
          payload.kenhNhan !== null
        ) {
          alertMessage(
            "error",
            "Thông báo!",
            "Kênh giao và kênh nhận không được chọn cùng chiều"
          );
          isDup = true;
        } else {
          isDup = false;
        }
        if (!isDup)
          //call api
          handlerSubmitDiemDo(
            payload,
            setData,
            values,
            closeModal,
            form,
            filterConditions,
            setLoading,
            setDataDanhSach,
            setLoadingDanhSach,
            getDanhSachDiemDo,
            dispatch
          );
      },
      onCancel() {},
      okText: "Đồng ý",
      cancelText: "Hủy",
    });
  };

  // check khi chon tinh chat diem do moi hien thi tram
  const handlerChangTram = (value) => {
    setValueDD(value);
  };

  return (
    <Container>
      <LoadingComponent loading={loading}>
        <FormComponent layout="vertical" form={form} onFinish={onFinish}>
          <Divider orientation="left">1. Thông tin điểm đo</Divider>
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item name="maDiemDo" label="Mã điểm đo ">
                <Input
                  style={{ border: "1px solid #d9d9d9" }}
                  type="text"
                  size="default"
                  form={form}
                  placeholder="Nhập mã điểm đo"
                  disabled
                  defaultValue={taoMa.maDiemDo}
                >
                  {taoMa.MaDiemDo}
                </Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="ngayHieuLuc" label="Ngày hiệu lực " required>
                <DatePicker
                  defaultValue={moment()
                    .startOf("month")
                    .format(FORMAT_ENGLISH)}
                  formKey="ngayHieuLuc"
                  format={FORMAT_ENGLISH}
                  form={form}
                  inputReadOnly={true}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="maDiemDoNoiBo" label="Mã điểm đo nội bộ">
                <Input
                  type="text"
                  size="default"
                  placeholder="Nhập mã điểm đo nội bộ"
                  formKey="maDiemDoNoiBo"
                  form={form}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="soBienBan" label="Số biên bản " required>
                <Input
                  type="text"
                  formKey="soBienBan"
                  size="default"
                  placeholder=" Nhập số biên bản"
                ></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="capDienApId" label="Cấp điện áp " required>
                <Select
                  formKey="capDienApId"
                  placeholder="Chọn cấp điện áp"
                  form={form}
                >
                  {urlCapDienAp &&
                    urlCapDienAp.length &&
                    urlCapDienAp.map((item, idx) => (
                      <Option
                        key={idx}
                        value={item.id ? item.id.toString() : item.id}
                      >
                        {item.maCapDienAp} - {item.moTa}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="hinhThucThanhToanId"
                label="Hình thức thanh toán"
              >
                <Select
                  formKey="hinhThucThanhToanId"
                  placeholder="Hình thức thanh toán"
                  defaultValue={
                    urlHTTT.length > 0 && { hinhThucThanhToanId: urlHTTT[0].id }
                  }
                >
                  {urlHTTT &&
                    urlHTTT.length &&
                    urlHTTT.map((item, idx) => (
                      <Option
                        key={idx}
                        // value={`${item.maHinhThuc} -  ${item.noiDung}`}
                        value={item.id ? item.id.toString() : item.id}
                      >
                        {item.maHinhThuc} - {item.noiDung}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                name="tinhChatDiemDoId"
                label="Tính chất điểm đo"
                required
              >
                <Select
                  placeholder="Chọn tính chất điểm đo"
                  form={form}
                  onChange={handlerChangTram}
                  loading={loading}
                >
                  {urlTinhChatDD &&
                    urlTinhChatDD.length &&
                    urlTinhChatDD.map((item, idx) => (
                      <Option
                        key={idx}
                        value={item.id ? item.id.toString() : item.id}
                      >
                        {item.maTinhChatDiemDo} - {item.moTa}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="tramId" label="Trạm" required>
                <Select
                  // selectiName="moTa"
                  formKey="tramId"
                  placeholder="Chọn trạm"
                  form={form}
                  // onChange={onChangeSelecTram}
                  showSearch
                  optionFilterProp="children"
                  // url={urlTram}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {urlTram &&
                    urlTram.length &&
                    urlTram.map((item, idx) => (
                      <Option
                        key={idx}
                        value={item.id ? item.id.toString() : item.id}
                      >
                        {item?.moTa}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="thongTinDiemDo"
                label="Thông tin điểm đo"
                required
              >
                <Input
                  type="text"
                  formKey="thongTinDiemDo"
                  size="default"
                  placeholder=" Nhập thông tin điểm đo"
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">2.Ánh xạ điểm đo(đo xa)</Divider>
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item name="maDiemDoDoXa" label="Mã điểm đo ánh xạ (đo xa)">
                <Input
                  type="text"
                  formKey="maDiemDoDoXa"
                  size="default"
                  placeholder=" Nhập mã điểm đo ánh xạ (đo xa)"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="ngayTao" label="Tháng lấy số liệu">
                <DatePicker
                  defaultValue={
                    filterConditions
                      ? moment(filterConditions)
                      : moment(moment(), FORMAT_YEAR)
                  }
                  picker="month"
                  formKey="ngayTao"
                  format={"YYYY-MM"}
                  form={form}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item name="kenhGiao" label="Kênh giao">
                <Checkbox
                  onChange={(e) => onChangeCheck(e, "chieuGiaoKG")}
                  disabled={isKGCG}
                  checked={isValueCGKG}
                >
                  Chiều giao
                </Checkbox>
                <Checkbox
                  onChange={(e) => onChangeCheck(e, "chieuNhanKG")}
                  disabled={isKGCN}
                  checked={isValueCNKG}
                >
                  Chiều nhận
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="kenhNhan" label="Kênh nhận">
                <Checkbox
                  onChange={(e) => onChangeCheck(e, "chieuGiaoKN")}
                  disabled={isKNCG}
                  checked={isValueCGKN}
                >
                  Chiều giao
                </Checkbox>
                <Checkbox
                  onChange={(e) => onChangeCheck(e, "chieuNhanKN")}
                  disabled={isKNCN}
                  checked={isValueCNKN}
                >
                  Chiều nhận
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">3.Thông tin phương thức</Divider>
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item name="ngayThayDoi" label="Ngày thay đổi" required>
                <DatePicker
                  defaultValue={moment()
                    .startOf("month")
                    .format(FORMAT_ENGLISH)}
                  formKey="ngayThayDoi"
                  format={FORMAT_ENGLISH}
                  form={form}
                  inputReadOnly={true}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="soBienBan_PT" label="Số biên bản" required>
                <Input
                  type="text"
                  formKey="soBienBan_PT"
                  size="default"
                  placeholder=" Nhập số biên bản"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="lyDoThayDoi" label="Lý do thay đổi" required>
                <Input
                  type="text"
                  formKey="lyDoThayDoi"
                  size="default"
                  placeholder=" Nhập lý do thay đổi"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item name="checkbox">
                <Checkbox
                  onChange={handleChangeCheckBox}
                  visibleCheckBox={visibleCheckBox}
                >
                  Giao nhận tay ba
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                name="donViQuanLyGiaoNhanId1"
                label="Đơn vị giao nhận"
                required
              >
                <Select
                  formKey="donViQuanLyGiaoNhanId1"
                  placeholder="Chọn đơn vị giao nhận"
                  form={form}
                  showSearch
                  optionFilterProp="children"
                  // url={urlTram}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(
                          option?.children &&
                            option?.children[0] &&
                            option?.children[1] &&
                            option?.children[2]
                        )
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {urlDvgn &&
                    urlDvgn.length &&
                    urlDvgn.map((item, idx) => (
                      <Option
                        key={idx}
                        value={item.id ? item.id.toString() : item.id}
                      >
                        {item.maDonVi} - {item.tenDonVi}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              {visibleCheckBox && (
                <Form.Item
                  name="donViQuanLyGiaoNhanId2"
                  label="Đơn vị giao nhận"
                  required
                >
                  <Select
                    formKey="donViQuanLyGiaoNhanId2"
                    placeholder="Chọn đơn vị giao nhận"
                    form={form}
                    showSearch
                    filterOption={(input, option) => {
                      if (option && option.children) {
                        return (
                          removeAccents(
                            option?.children &&
                              option?.children[0] &&
                              option?.children[1] &&
                              option?.children[2]
                          )
                            .toLowerCase()
                            .indexOf(removeAccents(input).toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {urlDvgn &&
                      urlDvgn.length &&
                      urlDvgn.map((item, idx) => (
                        <Option
                          key={idx}
                          value={item.id ? item.id.toString() : item.id}
                        >
                          {item.maDonVi} - {item.tenDonVi}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
            <Col span={8}>
              <Form.Item name="kenhNhan_PT1" label="Kênh nhận công tơ" required>
                <Select
                  formKey="kenhNhan_PT1"
                  placeholder="Chọn kênh nhận công tơ"
                  form={form}
                  allowClear
                >
                  <Option value="N">1. Chiều nhận điện từ đơn vị khác</Option>
                  <Option value="G">2. Chiều giao điện từ đơn vị khác</Option>
                  <Option value="X">3. Không có giao nhận</Option>
                </Select>
              </Form.Item>
              {visibleCheckBox && (
                <Form.Item
                  name="kenhNhan_PT2"
                  label="Kênh nhận công tơ"
                  required
                >
                  <Select
                    formKey="kenhNhan_PT2"
                    placeholder="Chọn kênh nhận công tơ"
                    form={form}
                    allowClear
                  >
                    <Option value="N">1. Chiều nhận điện từ đơn vị khác</Option>
                    <Option value="G">2. Chiều giao điện từ đơn vị khác</Option>
                    <Option value="X">3. Không có giao nhận</Option>
                  </Select>
                </Form.Item>
              )}
            </Col>
            <Col span={8}>
              <Form.Item name="kenhGiao_PT1" label="Kênh giao công tơ" required>
                <Select
                  formKey="kenhGiao_PT1"
                  placeholder="Chọn kênh giao công tơ"
                  form={form}
                  allowClear
                >
                  <Option value="N">1. Chiều nhận điện từ đơn vị khác</Option>
                  <Option value="G">2. Chiều giao điện từ đơn vị khác</Option>
                  <Option value="X">3. Không có giao nhận</Option>
                </Select>
              </Form.Item>
              {visibleCheckBox && (
                <Form.Item
                  name="kenhGiao_PT2"
                  label="Kênh giao công tơ"
                  required
                >
                  <Select
                    formKey="kenhGiao_PT2"
                    placeholder="Chọn kênh giao công tơ"
                    form={form}
                    allowClear
                  >
                    <Option value="N">1. Chiều nhận điện từ đơn vị khác</Option>
                    <Option value="G">2. Chiều giao điện từ đơn vị khác</Option>
                    <Option value="X">3. Không có giao nhận</Option>
                  </Select>
                </Form.Item>
              )}
            </Col>
          </Row>

          <Row justify="center" gutter={12}>
            <Col span={4}>
              <Button
                type="block"
                htmlType="submit"
                size="large"
                className="full"
                onClick={handleCloseModal}
              >
                Đóng lại
              </Button>
            </Col>
            <Col span={4}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="full"
                loading={loading}
              >
                Cập nhật
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </LoadingComponent>
    </Container>
  );
}
const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
`;
