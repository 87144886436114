import { BCS_KTCT, ENUM_AHT } from "@utils/constants";
import { Image } from "antd";
import Text from "antd/lib/typography/Text";
import { Fragment, useState, useEffect } from "react";

export default function AnhHienTruong({
  dataAnh,
  dataDetail,
  type,
  dataChiSo,
}) {
  const [visible, setVisible] = useState(false);
  const [indexImage, setIndexImage] = useState(null);

  useEffect(() => {
    if (indexImage !== null) {
      setVisible(true);
    }
  }, [indexImage]);

  useEffect(() => {
    if (!visible) {
      setIndexImage(null);
    }
  }, [visible]);

  if (type !== ENUM_AHT.AHT) {
    return (
      <Fragment>
        {dataAnh &&
          dataAnh.length > 0 &&
          dataAnh.map((item, index) => {
            if (item.loaiHinhAnh <= ENUM_AHT.BCS)
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 15,
                  }}
                  key={index}
                >
                  <div
                    style={{
                      marginLeft: 10,
                      fontSize: 17,
                      fontWeight: 600,
                    }}
                  >
                    <Text style={{ marginRight: 30 }}>
                      {item.tenLoaiHinhAnh}
                    </Text>
                    {dataDetail &&
                    Object.keys(dataDetail).length > 0 &&
                    dataDetail.loaiYeuCauCongTo === 1 ? (
                      <Fragment>
                        {BCS_KTCT.map((rank, i) => {
                          return (
                            <Text key={i} style={{ fontWeight: 600 }}>
                              {rank.value === item.loaiHinhAnh &&
                              dataDetail &&
                              Object.keys(dataDetail).length > 0
                                ? dataDetail[rank.name]
                                : ""}
                            </Text>
                          );
                        })}
                      </Fragment>
                    ) : dataDetail &&
                      Object.keys(dataDetail).length > 0 &&
                      dataDetail.loaiYeuCauCongTo === 2 ? (
                      <Fragment>
                        {dataChiSo &&
                          dataChiSo.length > 0 &&
                          dataChiSo.map((rank, i) => {
                            return (
                              <Text key={i} style={{ fontWeight: 600 }}>
                                {item.tenLoaiHinhAnh &&
                                item.tenLoaiHinhAnh.includes(rank.loaiBCS)
                                  ? rank.chiSoPhucTra
                                  : ""}
                              </Text>
                            );
                          })}
                      </Fragment>
                    ) : null}
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {item.loaiHinhAnh &&
                      item.hinhAnhs &&
                      item.hinhAnhs.map((item, j) => {
                        return (
                          <div
                            style={{
                              margin: "10px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Image
                              width={220}
                              height={350}
                              key={j}
                              src={item.duongDanFile}
                            />

                            <Text style={{ width: 220 }}>{item.ghiChu}</Text>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            return null;
          })}
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {dataAnh &&
          dataAnh.length > 0 &&
          dataAnh.map((item) => {
            if (item.loaiHinhAnh > ENUM_AHT.BCS)
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 15,
                  }}
                >
                  <div
                    style={{
                      marginLeft: 10,
                      fontSize: 17,
                      fontWeight: 600,
                    }}
                  >
                    <Text style={{ marginRight: 30 }}>
                      {item.tenLoaiHinhAnh}
                    </Text>
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {item.loaiHinhAnh &&
                      item.hinhAnhs &&
                      item.hinhAnhs.map((item, index) => {
                        return (
                          <div
                            style={{
                              margin: "10px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Image
                              width={220}
                              height={350}
                              key={index}
                              src={item.duongDanFile}
                              onClick={() => setIndexImage(index)}
                              preview={{ visible: false }}
                            />

                            <Text style={{ width: 220 }}>{item.ghiChu}</Text>
                          </div>
                        );
                      })}
                    <div style={{ display: "none" }}>
                      <Image.PreviewGroup
                        preview={{
                          visible,
                          onVisibleChange: (vis) => setVisible(vis),
                          current: indexImage,
                        }}
                      >
                        {item.loaiHinhAnh &&
                          item.hinhAnhs &&
                          item.hinhAnhs.map((item, index) => {
                            return (
                              <Image key={index} src={item.duongDanFile} />
                            );
                          })}
                      </Image.PreviewGroup>
                    </div>
                  </div>
                </div>
              );
            return null;
          })}
      </Fragment>
    );
  }
}
