import { Endpoint } from "@utils/endpoint";
import Screen1 from "../common-screen/screen1";
const DsSoXuatdltruocXngay = () => {
  const columns = [
    {
      title: "STT",
      dataIndex: "key",
      width: "70px",
      align: "center",
    },
    {
      title: "Đơn vị",
      dataIndex: "donVi",
      key: "donVi",
      width: "400px",
    },
    {
      title: "Đội",
      dataIndex: "doi",
      width: "350px",
    },
    {
      title: "Mã sổ GCS",
      dataIndex: "maSoGCS",
    },
    {
      title: "Kỳ",
      dataIndex: "ky",
      width: "70px",
      align: "center",
    },
    {
      title: "Ngày GCS",
      dataIndex: "ngayGCS",
      render: (ngayGCS) => {
        if (ngayGCS)
          return {
            children: <span>{ngayGCS}</span>,
            props: {
              align: "end",
            },
          };
      },
    },
    {
      title: "Ngày nhận sổ GCS",
      dataIndex: "ngayNhanSoGCS",
      render: (ngayNhanSoGCS) => {
        if (ngayNhanSoGCS)
          return {
            children: <span>{ngayNhanSoGCS}</span>,
            props: {
              align: "end",
            },
          };
      },
    },
    {
      title: "Chênh lệch",
      dataIndex: "chenhLech",
      render: (text) => {
        return {
          children: <span>{text}</span>,
          props: {
            align: "end",
          },
        };
      },
    },
  ];
  // const countTotals = (pageData) => {
  //   pageData.forEach(({}) => {});
  //   return (
  //     <Table.Summary.Row style={{ backgroundColor: "#b8b894" }}>
  //       <Table.Summary.Cell
  //         align="center"
  //         index={0}
  //         colSpan={3}
  //       ></Table.Summary.Cell>
  //       <Table.Summary.Cell
  //         className="font-bold"
  //         align="center"
  //         index={1}
  //       ></Table.Summary.Cell>
  //       <Table.Summary.Cell
  //         className="font-bold"
  //         align="center"
  //         index={1}
  //       ></Table.Summary.Cell>
  //     </Table.Summary.Row>
  //   );
  // };
  const endPoint = {
    LIST: Endpoint.LIST_SO_GCS_XUAT_TRUOC_NGAY,
    EXCEL: Endpoint.EXCEL_SO_GCS_XUAT_TRUOC_NGAY,
    PDF: Endpoint.PDF_SO_GCS_XUAT_TRUOC_NGAY,
    ...Endpoint,
  };

  return <Screen1 columns={columns} endPoint={endPoint} />;
};

export default DsSoXuatdltruocXngay;
