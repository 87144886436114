import CheckPermission from "@components/CheckPermission";
import { cap_dien } from "@permissions/a_capdien";
import CheckStatus from "@components/CheckStatus";
import {
  LITMIT_TRANGTHAI,
  MATRIX_CAP_DIEN,
  SEQUENCE_TRANGTHAI,
} from "@utils/constants";
import { Menu } from "antd";
import XemLaiBBLapDatComponent from "@modules/cap-dien/common-ho-so/xem-bien-ban-lap-dat";
const { SubMenu } = Menu;

export default function ListMenu(props) {
  const {
    t,
    handleOpenModalPhanCong,
    handleOpenModalKhaoSatLai,
    handleOpenModalChuyenDonViKhac,
    handleOpenModalHuyHoSo,
    handleOpenModalInHoSoCMIS,
    handleOpenModalXemLaiPhuongAnCapDien,
    handleOpenModalQuyetToanKhachHang,
    handleOpenModalQuyetToanCongTy,
    handleOpenModalInBieuKhaiGia,
    inBienBanApGia,
    detail,
    handleOpenInPhuTai,
  } = props;
  return (
    <Menu mode="vertical" style={{ width: "100%" }} selectedKeys={["0"]}>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.PHANCONGLAI}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.QUANLYHSCOTRONGAI[1]}
        permissionCode={cap_dien.a13_phan_cong_lai}
      >
        <Menu.Item eventKey="2" onClick={handleOpenModalPhanCong}>
          {t("giao_dich_vien.quan_ly_ho_so_co_tro_ngai.phan_cong_lai")}
        </Menu.Item>
      </CheckStatus>

      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.KHAOSATLAI}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.QUANLYHSCOTRONGAI[1]}
        permissionCode={cap_dien.a13_khao_sat_lai}
      >
        <Menu.Item eventKey="3" onClick={handleOpenModalKhaoSatLai}>
          {t("giao_dich_vien.quan_ly_ho_so_co_tro_ngai.khao_sat_lai")}
        </Menu.Item>
      </CheckStatus>

      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.CHUYENDONVI}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.QUANLYHSCOTRONGAI[1]}
        permissionCode={cap_dien.a13_chuyen_don_vi}
      >
        <Menu.Item eventKey="5" onClick={handleOpenModalChuyenDonViKhac}>
          {t("common_cap_dien.chuyen_don_vi_khac")}
        </Menu.Item>
      </CheckStatus>

      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.HUY}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.QUANLYHSCOTRONGAI[1]}
        permissionCode={cap_dien.a13_huy}
      >
        <Menu.Item eventKey="6" onClick={handleOpenModalHuyHoSo}>
          {t("giao_dich_vien.quan_ly_ho_so_co_tro_ngai.xac_nhan_huy_ho_so")}
        </Menu.Item>
      </CheckStatus>
      <CheckPermission permissionCode={cap_dien.a13_in_hs_cmis}>
        <Menu.Item eventKey="7" onClick={handleOpenModalInHoSoCMIS}>
          {" "}
          {t("common_cap_dien.in_ho_so_cmis")}
        </Menu.Item>
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a13_in_hs_web}>
        {Number(detail.maTrangThai) >= LITMIT_TRANGTHAI ? (
          <SubMenu eventKey="sub1" title={t("common_cap_dien.in_ho_so_web")}>
            <Menu.Item
              eventKey="8"
              onClick={handleOpenModalXemLaiPhuongAnCapDien}
            >
              {" "}
              {t("common_cap_dien.xem_lai_phuong_an_cap_dien")}
            </Menu.Item>
            <XemLaiBBLapDatComponent idHoSo={detail?.id ?? detail?.hoSoId} />
            <Menu.Item eventKey="20" onClick={handleOpenInPhuTai}>
              In biểu đồ phụ tải
            </Menu.Item>
            <Menu.Item eventKey="9" onClick={handleOpenModalQuyetToanKhachHang}>
              {" "}
              {t("common_cap_dien.quyet_toan_khach_hang")}
            </Menu.Item>
            <Menu.Item eventKey="10" onClick={handleOpenModalQuyetToanCongTy}>
              {" "}
              {t("common_cap_dien.quyet_toan_cong_ty")}
            </Menu.Item>
            <Menu.Item eventKey="11" onClick={handleOpenModalInBieuKhaiGia}>
              {" "}
              {t("common_cap_dien.in_bieu_khai_gia")}
            </Menu.Item>
            <Menu.Item eventKey="12" onClick={inBienBanApGia}>
              {" "}
              {t("common_cap_dien.in_bien_ban_ap_gia")}
            </Menu.Item>
            {/* <Menu.Item eventKey="12">
        {" "}
        {t("common_cap_dien.in_phieu_tiep_nhan_thong_tin_de_nghi_mua_dien")}
      </Menu.Item> */}
          </SubMenu>
        ) : null}
      </CheckPermission>
    </Menu>
  );
}
