import CheckPermission from "@components/CheckPermission";
import { getThangLamViec } from "@modules/giao-nhan-dien-nang/tong-hop-so-lieu/tinh-san-luong/services";
import { giao_nhan_dien_nang } from "@permissions/c_gndn";
import { authSelector } from "@state/auth";
import { ENUM_LUYKE_GNDN, TYPE_INPDF_GNDN } from "@utils/constants";
import { removeAccents } from "@utils/function";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  handleExportExcel,
  handleViewPDF,
  useSelectAllOption,
} from "../content/functions";
import XacNhanModal from "../modal-xacnhan/index";
import { Container, ContentWrapper, TableStyled } from "./css-styled";
import {
  getListSLDonVi,
  handleKTDKXacNhan,
  handleMultiData,
  handleXacNhanSL,
} from "./service";

export default function TongHopDienNangGiaoNhan(props) {
  const {
    typeInPDF,
    disableLuyKe,
    disableLuyKeQuy,
    disableLuyKeNam,
    disableTram,
    allDVGiaoNhan,
    checkTableAll,
    validate,
  } = props;
  const { user } = useSelector(authSelector);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [dataTable, setDataTable] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [listTram, setListTram] = useState([]);
  const [luyKe, setLuyKe] = useState(false);
  const [luyKeQuy, setLuyKeQuy] = useState(false);
  const [luyKeNam, setLuyKeNam] = useState(false);
  const [thangLamViec, setThangLamViec] = useState({});
  const [filterConditions, setFilterConditions] = useState({});
  const [dataDonVi, setDataDonVi] = useState([]);
  const [checkTaoBangKe, setCheckTaoBangKe] = useState("");
  const [noidungPopUp, setNoidungPopUp] = useState("");
  const [visibleXacNhan, setVisibleXacNhan] = useState(false);

  const reportDate = localStorage.getItem("reportDate");

  useEffect(() => {
    getThangLamViec(setThangLamViec, setLoading);
  }, []);
  useEffect(() => {
    setFilterConditions({
      ...filterConditions,
      donViId: user.unitId,
      thang: `${moment(thangLamViec).format("MM")}`,
      nam: `${moment(thangLamViec).format("YYYY")}`,
    });
    if (thangLamViec) {
      form.setFieldsValue({
        reportDate: reportDate
          ? moment(new Date(reportDate))
          : moment(new Date(thangLamViec)),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, thangLamViec]);

  const onChangeLuyKe = (value) => {
    setLuyKe(value.target.checked);
    setLuyKeQuy(false);
    setLuyKeNam(false);
  };
  const onChangeLuyKeQuy = (value) => {
    setLuyKeQuy(value.target.checked);
    setLuyKe(false);
    setLuyKeNam(false);
  };
  const onChangeLuyKeNam = (value) => {
    setLuyKeQuy(false);
    setLuyKe(false);
    setLuyKeNam(value.target.checked);
  };
  const handleChangeThangLamViec = (dateString) => {
    localStorage.setItem("reportDate", dateString);
  };

  const columns = [
    {
      title: "Mã đơn vị",
      dataIndex: "maDonVi",
      key: "maDonVi",
      width: "30%",
    },
    {
      title: "Tên đơn vị",
      dataIndex: "tenDonVi",
      key: "tenDonVi",
      width: "65%",
    },
  ];

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const arrCheckAll =
    dataTable && dataTable.length > 0 && dataTable.map((obj) => obj.id);

  const checkAllDonVi = dataTable
    ?.filter((obj) => obj.maDonVi !== "PD")
    .map((item) => item.id);

  const rowSelection = {
    selectedRowKeys: checkTableAll ? arrCheckAll : selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: () => {
      return {
        disabled: checkTableAll,
      };
    },
  };

  // Xac nhan san luong
  // Bat dau
  useEffect(
    () => {
      if (Object.keys(thangLamViec).length !== 0 && !user.isTongCongTy) {
        const thang = `${moment(thangLamViec).format("MM")}`;
        const nam = `${moment(thangLamViec).format("YYYY")}`;
        getListSLDonVi(
          setDataDonVi,
          setLoading,
          thang,
          nam,
          user.unitId
          // setDisableMessError
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [thangLamViec]
  );

  const handleOpenModalXacNhan = () => {
    const data = {
      thang: `${moment(thangLamViec).format("MM")}`,
      nam: `${moment(thangLamViec).format("YYYY")}`,
      donViId: user?.unitId,
    };
    handleKTDKXacNhan(data, setLoading, setCheckTaoBangKe, setNoidungPopUp);
    setTimeout(() => {
      setVisibleXacNhan(!visibleXacNhan);
    }, 700);
  };

  const handleSubmit = () => {
    handleXacNhanSL(
      filterConditions.donViId,
      filterConditions.thang,
      filterConditions.nam,
      setLoading,
      setVisibleXacNhan
    );
  };
  const handleCloseModalXacNhan = () => {
    setVisibleXacNhan(false);
  };
  const showModalXacNhan = useMemo(
    () =>
      visibleXacNhan && (
        <XacNhanModal
          visible={visibleXacNhan}
          handleCloseModal={handleCloseModalXacNhan}
          setVisible={setVisibleXacNhan}
          content={
            checkTaoBangKe === false
              ? `Dữ liệu sản lượng giao nhận của tháng làm việc ${
                  thangLamViec !== ""
                    ? `
          ${moment(thangLamViec).format("MM")}/${moment(thangLamViec).format(
                        "YYYY"
                      )}`
                    : `
          ${moment(thangLamViec).format("MM")}/${moment(thangLamViec).format(
                        "YYYY"
                      )}`
                } đã kiểm tra xong ?
          Ấn đồng ý, sẽ thực hiện xác nhận sản lượng và tạo bảng kê.
          `
              : `${noidungPopUp} `
          }
          title={"Xác nhận sản lượng và tạo bảng kê"}
          handleSubmit={handleSubmit}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleXacNhan]
  );
  // call api list trạm và list đơn vị của table

  useEffect(() => {
    handleMultiData(setLoading, setListTram, setDataTable);
  }, [typeInPDF]);

  const optionsTram = listTram.map((item) => {
    return {
      label: item.moTa,
      value: item.id,
    };
  });

  const [getValueFromEvent] = useSelectAllOption(optionsTram);

  const disabledDate = (current) => {
    // Can not select days after thangLamViec
    return (
      current &&
      current.startOf("month").valueOf() >
        moment(thangLamViec).endOf("month").valueOf()
    );
  };

  return (
    <Container>
      <Spin spinning={loading}>
        <ContentWrapper>
          <Form form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}>
            {user.isTongCongTy ? (
              <Row gutter={24}>
                <Col span={24} md={24}>
                  <Form.Item
                    label="Đơn vị"
                    labelAlign="left"
                    style={{ marginBottom: 10 }}
                  >
                    <Text strong>{user.unitName}</Text>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="reportDate"
                    label="Tháng/Năm"
                    labelAlign="left"
                    style={{ marginBottom: 10 }}
                  >
                    <DatePicker
                      disabledDate={disabledDate}
                      onChange={handleChangeThangLamViec}
                      picker="month"
                      format={"MM-YYYY"}
                    />
                  </Form.Item>
                </Col>
                <Col
                  md={4}
                  style={{
                    display: disableLuyKe ? "none" : "block",
                  }}
                ></Col>
                <Col
                  span={24}
                  md={20}
                  style={{
                    display: disableLuyKe ? "none" : "block",
                  }}
                >
                  <Form.Item style={{ marginBottom: 10 }}>
                    <Checkbox
                      onChange={onChangeLuyKe}
                      value={ENUM_LUYKE_GNDN.LUYKE}
                      checked={luyKe}
                      style={{
                        display: disableLuyKe ? "none" : "inline-flex",
                      }}
                    >
                      Lũy kế
                    </Checkbox>
                    <Checkbox
                      onChange={onChangeLuyKeQuy}
                      checked={luyKeQuy}
                      value={ENUM_LUYKE_GNDN.LUYKE_QUY}
                      style={{
                        display: disableLuyKeQuy ? "none" : "inline-flex",
                      }}
                    >
                      Lũy kế quý
                    </Checkbox>
                    <Checkbox
                      onChange={onChangeLuyKeNam}
                      checked={luyKeNam}
                      value={ENUM_LUYKE_GNDN.NAM}
                      style={{
                        display: disableLuyKeNam ? "none" : "inline-flex",
                      }}
                    >
                      Lũy kế năm
                    </Checkbox>
                  </Form.Item>
                </Col>
                {/* <Col
                  span={24}
                  md={24}
                  style={{ display: disableTram ? "none" : "block" }}
                >
                  <Form.Item
                    getValueFromEvent={getValueFromEvent}
                    label="Chọn trạm"
                    name="tramIds"
                    labelAlign="left"
                    style={{ marginBottom: 10 }}
                  >
                    <Select
                      placeholder="Chọn trạm"
                      maxTagCount={2}
                      allowClear
                      mode="multiple"
                      options={optionsWithAllOption}
                    />
                  </Form.Item>
                </Col> */}
                <Col
                  span={24}
                  md={24}
                  style={{ display: disableTram ? "none" : "block" }}
                >
                  <Form.Item
                    getValueFromEvent={getValueFromEvent}
                    label="Chọn trạm"
                    name="tramIds"
                    labelAlign="left"
                    style={{ marginBottom: 10 }}
                  >
                    <Select
                      defaultValue={["All"]}
                      placeholder="Chọn trạm"
                      maxTagCount={2}
                      allowClear
                      mode="multiple"
                      showSearch
                      filterOption={(input, option) => {
                        if (option && option.children) {
                          return (
                            removeAccents(option.children)
                              .toLowerCase()
                              .indexOf(removeAccents(input).toLowerCase()) >= 0
                          );
                        }
                      }}
                    >
                      <Select.Option key="All" value="All">
                        Tất cả
                      </Select.Option>
                      {listTram &&
                        listTram.map((item, id) => {
                          return (
                            <Select.Option key={id} value={item.id}>
                              {item.moTa}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                {!allDVGiaoNhan ? (
                  <Col span={24} md={4} style={{ marginTop: 8 }}>
                    Chọn CTĐL
                  </Col>
                ) : (
                  <Col span={24} md={4} style={{ marginTop: 8 }}></Col>
                )}
                <Col
                  span={24}
                  md={20}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <CheckPermission
                    permissionCode={
                      typeInPDF === TYPE_INPDF_GNDN.CHITIET_DNGN
                        ? giao_nhan_dien_nang.c61i
                        : typeInPDF === TYPE_INPDF_GNDN.TONGHOP_DNGN
                        ? giao_nhan_dien_nang.c62i
                        : typeInPDF === TYPE_INPDF_GNDN.CHITIET_CS_CTD_DNGN
                        ? giao_nhan_dien_nang.c63i
                        : typeInPDF === TYPE_INPDF_GNDN.BBQT_DN_PHANKHANG
                        ? giao_nhan_dien_nang.c64i
                        : typeInPDF === TYPE_INPDF_GNDN.SAISO_TRAM110
                        ? giao_nhan_dien_nang.c65i
                        : typeInPDF === TYPE_INPDF_GNDN.CHITIET_DN_THEOTRAM
                        ? giao_nhan_dien_nang.c66i
                        : typeInPDF === TYPE_INPDF_GNDN.CHITIET_SANLUONG
                        ? giao_nhan_dien_nang.c67i
                        : typeInPDF === TYPE_INPDF_GNDN.DIEMDO_BIENDONG_THANG
                        ? giao_nhan_dien_nang.c68i
                        : typeInPDF === TYPE_INPDF_GNDN.SANLUONG_DAUNGUON_3GIA
                        ? giao_nhan_dien_nang.c69i
                        : typeInPDF === TYPE_INPDF_GNDN.CHITIET_DN_TUDUNG
                        ? giao_nhan_dien_nang.c610i
                        : typeInPDF === TYPE_INPDF_GNDN.BBQT_DIENNANG_GIAONHAN
                        ? giao_nhan_dien_nang.c611i
                        : null
                    }
                  >
                    <Button
                      type="primary"
                      onClick={() =>
                        handleViewPDF(
                          validate,
                          checkTableAll,
                          setFilterConditions,
                          filterConditions,
                          form,
                          luyKe,
                          luyKeQuy,
                          luyKeNam,
                          selectedRowKeys,
                          checkAllDonVi,
                          setLoading,
                          typeInPDF
                        )
                      }
                    >
                      In báo cáo
                    </Button>
                  </CheckPermission>
                  <CheckPermission
                    permissionCode={
                      typeInPDF === TYPE_INPDF_GNDN.CHITIET_DNGN
                        ? giao_nhan_dien_nang.c61e
                        : typeInPDF === TYPE_INPDF_GNDN.TONGHOP_DNGN
                        ? giao_nhan_dien_nang.c62e
                        : typeInPDF === TYPE_INPDF_GNDN.CHITIET_CS_CTD_DNGN
                        ? giao_nhan_dien_nang.c63e
                        : typeInPDF === TYPE_INPDF_GNDN.BBQT_DN_PHANKHANG
                        ? giao_nhan_dien_nang.c64e
                        : typeInPDF === TYPE_INPDF_GNDN.SAISO_TRAM110
                        ? giao_nhan_dien_nang.c65e
                        : typeInPDF === TYPE_INPDF_GNDN.CHITIET_DN_THEOTRAM
                        ? giao_nhan_dien_nang.c66e
                        : typeInPDF === TYPE_INPDF_GNDN.CHITIET_SANLUONG
                        ? giao_nhan_dien_nang.c67e
                        : typeInPDF === TYPE_INPDF_GNDN.DIEMDO_BIENDONG_THANG
                        ? giao_nhan_dien_nang.c68e
                        : typeInPDF === TYPE_INPDF_GNDN.SANLUONG_DAUNGUON_3GIA
                        ? giao_nhan_dien_nang.c69e
                        : typeInPDF === TYPE_INPDF_GNDN.CHITIET_DN_TUDUNG
                        ? giao_nhan_dien_nang.c610e
                        : typeInPDF === TYPE_INPDF_GNDN.BBQT_DIENNANG_GIAONHAN
                        ? giao_nhan_dien_nang.c611e
                        : null
                    }
                  >
                    <Button
                      type="primary"
                      className="ml-20px"
                      onClick={() =>
                        handleExportExcel(
                          validate,
                          checkTableAll,
                          setFilterConditions,
                          filterConditions,
                          form,
                          luyKe,
                          luyKeQuy,
                          luyKeNam,
                          selectedRowKeys,
                          checkAllDonVi,
                          setLoading,
                          typeInPDF
                        )
                      }
                    >
                      Xuất excel
                    </Button>
                  </CheckPermission>
                </Col>
              </Row>
            ) : (
              <Row gutter={24}>
                <Col span={24} md={24}>
                  <Form.Item label="Đơn vị" labelAlign="left">
                    <Text strong>{user.unitName}</Text>
                  </Form.Item>
                </Col>
                <Col span={24} md={24}>
                  <Form.Item
                    name="reportDate"
                    label="Tháng/Năm"
                    labelAlign="left"
                  >
                    <DatePicker
                      onChange={handleChangeThangLamViec}
                      picker="month"
                      format={"MM-YYYY"}
                    />
                  </Form.Item>
                </Col>
                {!allDVGiaoNhan ? (
                  <Col span={24} md={4} style={{ marginTop: 8 }}>
                    Chọn CTĐL
                  </Col>
                ) : (
                  <Col span={24} md={4} style={{ marginTop: 8 }}></Col>
                )}
                <Col
                  span={24}
                  md={20}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <CheckPermission
                    permissionCode={
                      typeInPDF === TYPE_INPDF_GNDN.CHITIET_DNGN
                        ? giao_nhan_dien_nang.c61i
                        : typeInPDF === TYPE_INPDF_GNDN.CHITIET_CS_CTD_DNGN
                        ? giao_nhan_dien_nang.c63i
                        : typeInPDF === TYPE_INPDF_GNDN.BBQT_DIENNANG_GIAONHAN
                        ? giao_nhan_dien_nang.c611i
                        : null
                    }
                  >
                    <Button
                      type="primary"
                      onClick={() =>
                        handleViewPDF(
                          validate,
                          checkTableAll,
                          setFilterConditions,
                          filterConditions,
                          form,
                          luyKe,
                          luyKeQuy,
                          luyKeNam,
                          selectedRowKeys,
                          checkAllDonVi,
                          setLoading,
                          typeInPDF
                        )
                      }
                    >
                      In báo cáo
                    </Button>
                  </CheckPermission>
                  <CheckPermission
                    permissionCode={
                      typeInPDF === TYPE_INPDF_GNDN.CHITIET_DNGN
                        ? giao_nhan_dien_nang.c61e
                        : typeInPDF === TYPE_INPDF_GNDN.CHITIET_CS_CTD_DNGN
                        ? giao_nhan_dien_nang.c63e
                        : typeInPDF === TYPE_INPDF_GNDN.BBQT_DIENNANG_GIAONHAN
                        ? giao_nhan_dien_nang.c611e
                        : null
                    }
                  >
                    <Button
                      type="primary"
                      className="ml-20px"
                      onClick={() =>
                        handleExportExcel(
                          validate,
                          checkTableAll,
                          setFilterConditions,
                          filterConditions,
                          form,
                          luyKe,
                          luyKeQuy,
                          luyKeNam,
                          selectedRowKeys,
                          checkAllDonVi,
                          setLoading,
                          typeInPDF
                        )
                      }
                    >
                      Xuất excel
                    </Button>
                  </CheckPermission>

                  {/* Day nha */}
                  <Button
                    type="primary"
                    key="back"
                    style={{
                      display: !allDVGiaoNhan ? "none" : "block",
                      marginLeft: 20,
                    }}
                    onClick={handleOpenModalXacNhan}
                    disabled={dataDonVi[0]?.trangThaiDonVi === 1}
                  >
                    Xác nhận sản lượng và tạo bảng kê
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
          {/* <CheckPermission> */}
          <TableStyled>
            {!allDVGiaoNhan ? (
              <Table
                columns={columns}
                dataSource={dataTable}
                scroll={{ y: 360 }}
                rowKey={"id"}
                rowSelection={rowSelection}
                pagination={false}
              />
            ) : null}
          </TableStyled>
          {/* </CheckPermission> */}
          {showModalXacNhan}
        </ContentWrapper>
      </Spin>
    </Container>
  );
}
