import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import {
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  Spin,
} from "antd";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectKKOG } from "./bc-chi-tiet-kiem-ke-0-gio/service";
import { handleDownload } from "./service";

export default function BaoCaoTHKiemKe0Gio() {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);
  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Báo cáo tổng hợp kiểm kê 0g
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              initialValues={{
                reportType: Endpoint.BCCN_BANG_KIEM_KE_CONG_NO_PHAI_TRA,
                unitId: user.user.unitCode,
                LoaiKhachHang: "-1",
                SoNgay: 5,
              }}
              colon={false}
            >
              <Form.Item
                name="reportType"
                label="Loại báo cáo"
                labelAlign="left"
                required
              >
                <Select
                  options={selectKKOG}
                  placeholder="Tất cả"
                  formKey="reportType"
                />
              </Form.Item>
              <Form.Item
                name="unitId"
                label="Đơn vị"
                labelAlign="left"
                required
              >
                <Selection
                  url={Endpoint.GET_TEAM_LIST_CODE}
                  formKey="unitId"
                  form={form}
                />
              </Form.Item>
              <Form.Item
                name="LoaiKhachHang"
                label="Loại khách hàng"
                labelAlign="left"
                required
              >
                <Select
                  placeholder="Chọn loại khách hàng"
                  formKey="LoaiKhachHang"
                >
                  <Option value="-1">Tất cả</Option>
                  <Option value="0">Sinh hoạt</Option>
                  <Option value="1">Ngoài sinh hoạt</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="reportDate"
                label="Tháng báo cáo"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="month"
                  formKey="reportDate"
                  form={form}
                  format={"YYYY-MM"}
                />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.reportType !== currentValues.reportType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") ===
                    Endpoint.BCCN_BANG_KIEM_KE_CONG_NO_PHAI_THU ||
                  getFieldValue("reportType") ===
                    Endpoint.BCCN_BC_SO_PHAI_THU_TRU_SO_PHAI_TRA ? (
                    <Form.Item
                      name="SoNgay"
                      label="Số ngày quá hạn"
                      labelAlign="left"
                      required
                    >
                      <InputNumber
                        min={1}
                        max={100}
                        placeholder="Nhập số ngày quá hạn"
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              <Row>
                <Col span={4}> </Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>
                </Col>
                <Col span={8}>
                  {/* <Button
                    type="primary"
                    loading={loading}
                    className="ml-20px"
                    onClick={() => {
                      handleOpenPdf(form.getFieldValue(), setLoading);
                    }}
                  >
                    In báo cáo
                  </Button> */}
                </Col>
              </Row>
            </FormComponent>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
