import { authSelector } from "@state/auth";
import { Col, Form, Input, Row, Select, Tooltip, Button } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  FORMAT_DATE,
  FORMAT_ENGLISH,
  MONTH_STRING,
  ENUM_MUCDICH_SDD,
} from "@utils/constants";
import FormComponent from "@components/Form";
import DatePickerComponent from "@components/DatePicker";
import { handleListDataTram } from "./services";
import { removeAccents } from "@utils/function";
const { Option } = Select;

export function ListFilter(props) {
  const { handleSearch, clearFilter, filterConditions, tooltip } = props;
  const { user } = useSelector(authSelector);
  const [dataNhomTram, setDataNhomTram] = useState([]);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  useEffect(() => {
    handleListDataTram(setLoading, setDataNhomTram, user.unitId);
  }, [user.unitId]);

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      // initialValues={{
      //   DonViId: user.unitId.toString(),
      // }}
    >
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="LoaiKhachHang" label="Loại khách hàng">
            <Select
              placeholder={"Tất cả"}
              defaultValue={ENUM_MUCDICH_SDD.SINHHOAT}
              allowClear
            >
              <Option value={ENUM_MUCDICH_SDD.SINHHOAT}>Sinh hoạt</Option>
              <Option value={ENUM_MUCDICH_SDD.NGOAISINHHOAT}>
                Ngoài sinh hoạt
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="MaTram" label="Mã trạm">
            <Select
              placeholder={"Tất cả"}
              allowClear
              loading={loading}
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {dataNhomTram && dataNhomTram.length > 0
                ? dataNhomTram.map((item, index) => {
                    if (item.maTram !== null) {
                      return (
                        <Option key={index} value={item.maTram}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="MaDiemDo" label="Mã điểm đo">
            <Input suffix={<Tooltip title={tooltip}></Tooltip>} />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="MaKhachHang" label="Mã khách hàng">
            <Input suffix={<Tooltip title={tooltip}></Tooltip>} />
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="TuNgay" label="Ngày áp giá">
            <DatePickerComponent
              defaultValue={
                filterConditions.ngayBatDauTT
                  ? moment(filterConditions.ngayBatDauTT, FORMAT_ENGLISH)
                  : moment(moment().startOf(MONTH_STRING), FORMAT_DATE)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"TuNgay"}
              notClear={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="DenNgay" label="Đến ngày">
            <DatePickerComponent
              defaultValue={
                filterConditions.ngayKetThucTT
                  ? moment(filterConditions.ngayKetThucTT, FORMAT_ENGLISH)
                  : moment(moment(), FORMAT_DATE)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"DenNgay"}
              notClear={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="SoGCS" label="Sổ GCS">
            <Input suffix={<Tooltip title={tooltip}></Tooltip>} />
          </Form.Item>
        </Col>
        {/* <Col span={24} md={4}>
          <Form.Item name="TrangThai" label="Trạng thái">
            <Select placeholder={"Tất cả"} allowClear>
              <Option value={ENUM_TRANGTHAI_YC_CMIS.CHUA_LAY}>Chưa đồng bộ</Option>
              <Option value={ENUM_TRANGTHAI_YC_CMIS.DA_LAY}>
                Đã đồng bộ (Chưa phân công)
              </Option>
              <Option value={ENUM_TRANGTHAI_YC_CMIS.DA_PHAN_CONG}>
                Đã phân công
              </Option>
            </Select>
          </Form.Item>
        </Col> */}
      </Row>
      <Row justify="end">
        <Button className="mr-24px" onClick={clear}>
          Bỏ lọc
        </Button>
        <Button type="primary" htmlType="submit" form="filter-form">
          Tìm kiếm
        </Button>
      </Row>
    </FormComponent>
  );
}
