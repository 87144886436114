import { CONG_TO_TREO, ENUM_FUNCTION, METHOD_POST } from "@utils/constants";
import { authGetData, authPostData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { alertMessage, validReg, validReg1 } from "@utils/function";
import moment from "moment";
import "moment/locale/vi";
import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { formatYYYYMMDDString, getErrorMessage } from "@utils/function";
import ModalGhiNhanKetQua from "./modals/ghi-nhan-ket-qua";

export const handleDetailBienBan = (setLoading, setDataDetail, hoSoId) => {
  authGetData({
    url: Endpoint.DETAIL_HO_SO + "?HoSoId=" + hoSoId,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) setDataDetail(res.data);
      else setDataDetail([]);
    },
  });
};

export const handleListTroNgai = (setLoading, setDataTroNgai) => {
  authGetData({
    url: `${Endpoint.XU_LY_LIST_TRO_NGAI}?funcId=${ENUM_FUNCTION.XACNHANTHICONG}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) setDataTroNgai(res.data);
      else setDataTroNgai([]);
    },
  });
};

export const handleSubmitTroNgaiThiCong = (
  values,
  ngay,
  disable,
  detail,
  funcID,
  setLoadingSubmit,
  setFetchDataTable,
  closeModal,
  form
) => {
  const data = {
    ...values,
  };
  const ngayHen = moment(values.ngayHen ? values.ngayHen : ngay);

  data.ngayHen = formatYYYYMMDDString(
    ngayHen.year(),
    ngayHen.month(),
    ngayHen.date()
  );

  if (disable) {
    delete data.ngayHen;
  }

  data.hoSoId = detail.id;
  data.funcId = funcID;

  authPostData({
    url: Endpoint.XU_LY_CAP_NHAT_TRO_NGAI,
    method: METHOD_PUT,
    payload: data,
    setLoading: setLoadingSubmit,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        closeModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const changeTroNgai = (
  funcID,
  value,
  setLoadingTenTroNgai,
  setData,
  form
) => {
  authGetData({
    url: `${Endpoint.XU_LY_LIST_TRO_NGAI}?funcId=${funcID}&loaiTroNgaiId=${value}`,
    setLoading: setLoadingTenTroNgai,
    onSuccess: (res) => {
      if (res.data !== null) {
        setData(res.data);
        form.resetFields(["troNgaiId"]);
      } else setData(res.data);
    },
  });
};

export const handleXacNhanThiCong = (
  setFetchDataTable,
  setLoading,
  hoSoId,
  checked,
  closeModal,
  dispatch
) => {
  authPostData({
    url: Endpoint.XAC_NHAN_THI_CONG,
    setLoading,
    payload: {
      hoSoId,
      isKiemTraDuThao: checked,
    },
    method: METHOD_PUT,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        dispatch(closeModal());
      }
    },
  });
};

export const handleDetailThongTin = (
  detail,
  setLoading,
  setDataBB,
  setDataBM,
  setDataTH
) => {
  authGetData({
    url: Endpoint.GET_THONG_TIN_VAT_TU + "?hoSoId=" + detail.id,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        const vatTuBenBans = [];
        const vatTuBenMuas = [];
        const vatTuThuHois = [];
        res.data.vatTuBenBans.map((item, index) => {
          return vatTuBenBans.push({
            chungLoaiVatTu: item.chungLoaiVatTu,
            donViTinh: item.donViTinh,
            [`duToanBBId-${index}`]: item.id,
            [`isVatTuNgoaiBB-${index}`]: item.isVatTuNgoai,
            soLuongDuToan: item.soLuongDuToan,
            stt: item.stt,
            [`thucTeBB-${index}`]: item.thucTe,
          });
        });

        res.data.vatTuBenMuas.map((item, index) => {
          return vatTuBenMuas.push({
            chungLoaiVatTu: item.chungLoaiVatTu,
            donViTinh: item.donViTinh,
            [`duToanBMId-${index}`]: item.id,
            [`isVatTuNgoaiBM-${index}`]: item.isVatTuNgoai,
            soLuongDuToan: item.soLuongDuToan,
            stt: item.stt,
            [`thucTeBM-${index}`]: item.thucTe,
          });
        });

        res.data.vatTuThuHois.map((item, index) => {
          return vatTuThuHois.push({
            chungLoaiVatTu: item.chungLoaiVatTu,
            donViTinh: item.donViTinh,
            [`duToanTHId-${index}`]: item.id,
            [`isVatTuNgoaiTH-${index}`]: item.isVatTuNgoai,
            soLuongDuToan: item.soLuongDuToan,
            stt: item.stt,
            [`thucTeTH-${index}`]: item.thucTe,
          });
        });
        setDataBB(vatTuBenBans);
        setDataBM(vatTuBenMuas);
        setDataTH(vatTuThuHois);
      } else {
        setDataBB([]);
        setDataBM([]);
        setDataTH([]);
      }
    },
  });
};

export const submitThongTinVT = (
  detail,
  setLoading,
  dataBB,
  dataBM,
  dataTH,
  setFetchDataTable,
  values,
  setScroll,
  form,
  setNotSaved
) => {
  const data = {
    ...values,
    hoSoId: detail.id,
  };
  const request = [];
  const validBB = [];
  const validBM = [];
  const validTH = [];

  for (let i = 0; i < dataBB.length; i++) {
    request.push({
      id: data[`duToanBBId-${i}`],
      thucTe: data[`thucTeBB-${i}`],
      isVatTuNgoai: data[`isVatTuNgoaiBB-${i}`],
    });
    validBB.push({
      name: `thucTeBB-${i}`,
      value: data[`thucTeBB-${i}`],
    });

    delete data[`duToanBBId-${i}`];
    delete data[`thucTeBB-${i}`];
    delete data[`chechLechBB-${i}`];
    delete data[`isVatTuNgoaiBB-${i}`];
  }

  for (let j = 0; j < dataBM.length; j++) {
    request.push({
      id: data[`duToanBMId-${j}`],
      thucTe: data[`thucTeBM-${j}`],
      isVatTuNgoai: data[`isVatTuNgoaiBM-${j}`],
    });
    validBM.push({
      name: [`thucTeBM-${j}`],
      value: data[`thucTeBM-${j}`],
    });

    delete data[`duToanBMId-${j}`];
    delete data[`thucTeBM-${j}`];
    delete data[`chechLechBM-${j}`];
    delete data[`isVatTuNgoaiBM-${j}`];
  }

  for (let k = 0; k < dataTH.length; k++) {
    request.push({
      id: data[`duToanTHId-${k}`],
      thucTe: data[`thucTeTH-${k}`],
      isVatTuNgoai: data[`isVatTuNgoaiTH-${k}`],
    });
    validTH.push({
      name: [`thucTeTH-${k}`],
      value: data[`thucTeTH-${k}`],
    });

    delete data[`duToanTHId-${k}`];
    delete data[`thucTeTH-${k}`];
    delete data[`chechLechTH-${k}`];
    delete data[`isVatTuNgoaiTH-${k}`];
  }

  validBB.forEach((item) => {
    return validReg(15, 3, item.value, form, item.name);
  });

  validBM.forEach((item) => {
    return validReg(15, 3, item.value, form, item.name);
  });

  validTH.forEach((item) => {
    return validReg(15, 3, item.value, form, item.name);
  });
  data.thongTinVatTus = request;
  authPostData({
    url: Endpoint.UPDATE_THONG_TIN_VAT_TU_THI_CONG,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setScroll(true);
        setNotSaved(false);
      }
    },
  });
};

export const handleDetailCongTo = (
  detail,
  setLoading,
  setDataCongTo,
  setIsThao
) => {
  authGetData({
    url: Endpoint.GET_THONG_TIN_CONG_TO + "?hoSoId=" + detail.id,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setIsThao(res.data.coCongToThao);
        let dataThao = {};
        let dataTreo = {};
        let data = {};
        const {
          boChiSo,
          chiSoBinhThuong,
          chiSoCaoDiem,
          chiSoP,
          chiSoQ,
          chiSoThapDiem,
          kenhNhanChiSoP,
          kenhNhanChiSoQ,
          kenhNhanChiSoCaoDiem,
          kenhNhanChiSoThapDiem,
          kenhNhanChiSoBinhThuong,
        } = res.data.congToTreo;

        dataTreo = {
          congToTreoBoChiSo: boChiSo,
          congToTreoChiSoBinhThuong: chiSoBinhThuong,
          congToTreoChiSoCaoDiem: chiSoCaoDiem,
          congToTreoChiSoP: chiSoP,
          congToTreoChiSoQ: chiSoQ,
          congToTreoChiSoThapDiem: chiSoThapDiem,
          coCongToThao: res.data.coCongToThao,
          congToTreoKenhNhanChiSoBinhThuong: kenhNhanChiSoBinhThuong,
          congToTreoKenhNhanChiSoCaoDiem: kenhNhanChiSoCaoDiem,
          congToTreoKenhNhanChiSoP: kenhNhanChiSoP,
          congToTreoKenhNhanChiSoQ: kenhNhanChiSoQ,
          congToTreoKenhNhanChiSoThapDiem: kenhNhanChiSoThapDiem,
        };
        if (
          res.data.congToThao !== null &&
          res.data.congToThao !== undefined &&
          res.data.coCongToThao
        ) {
          const {
            boChiSo,
            chiSoBinhThuong,
            chiSoCaoDiem,
            chiSoP,
            chiSoQ,
            chiSoThapDiem,
            kenhNhanChiSoP,
            kenhNhanChiSoQ,
            kenhNhanChiSoCaoDiem,
            kenhNhanChiSoThapDiem,
            kenhNhanChiSoBinhThuong,
          } = res.data.congToThao;
          dataThao = {
            congToThaoBoChiSo: boChiSo,
            congToThaoChiSoBinhThuong: chiSoBinhThuong,
            congToThaoChiSoCaoDiem: chiSoCaoDiem,
            congToThaoChiSoP: chiSoP,
            congToThaoChiSoQ: chiSoQ,
            congToThaoChiSoThapDiem: chiSoThapDiem,
            congToThaoKenhNhanChiSoBinhThuong: kenhNhanChiSoBinhThuong,
            congToThaoKenhNhanChiSoCaoDiem: kenhNhanChiSoCaoDiem,
            congToThaoKenhNhanChiSoP: kenhNhanChiSoP,
            congToThaoKenhNhanChiSoQ: kenhNhanChiSoQ,
            congToThaoKenhNhanChiSoThapDiem: kenhNhanChiSoThapDiem,
          };
        }
        data = {
          ...dataTreo,
          ...dataThao,
        };

        setDataCongTo(data);
      } else {
        setDataCongTo([]);
      }
    },
  });
};

export const submitThongTinCT = (
  detail,
  setLoading,
  values,
  setFetchDataTable,
  setNotSaved
) => {
  const data = {
    ...values,
    hoSoId: detail.id,
    congToTreo: {
      chiSoP: parseInt(values.congToTreoChiSoP),
      chiSoQ: parseInt(values.congToTreoChiSoQ),
      chiSoCaoDiem: parseInt(values.congToTreoChiSoCaoDiem),
      chiSoBinhThuong: parseInt(values.congToTreoChiSoBinhThuong),
      chiSoThapDiem: parseInt(values.congToTreoChiSoThapDiem),
      kenhNhanChiSoP: parseInt(values.congToTreoKenhNhanChiSoP),
      kenhNhanChiSoQ: parseInt(values.congToTreoKenhNhanChiSoQ),
      kenhNhanChiSoCaoDiem: parseInt(values.congToTreoKenhNhanChiSoCaoDiem),
      kenhNhanChiSoBinhThuong: parseInt(
        values.congToTreoKenhNhanChiSoBinhThuong
      ),
      kenhNhanChiSoThapDiem: parseInt(values.congToTreoKenhNhanChiSoThapDiem),
    },
  };
  if (
    values.congToThaoChiSoBinhThuong !== undefined ||
    values.congToThaoChiSoCaoDiem !== undefined ||
    values.congToThaoChiSoThapDiem !== undefined ||
    values.congToThaoChiSoP !== undefined ||
    values.congToThaoChiSoQ !== undefined ||
    values.congToThaoKenhNhanChiSoP !== undefined ||
    values.congToThaoKenhNhanChiSoQ !== undefined ||
    values.congToThaoKenhNhanChiSoCaoDiem !== undefined ||
    values.congToThaoKenhNhanChiSoBinhThuong !== undefined ||
    values.congToThaoKenhNhanChiSoThapDiem !== undefined
  ) {
    data.congToThao = {
      chiSoP: parseInt(values.congToThaoChiSoP),
      chiSoQ: parseInt(values.congToThaoChiSoQ),
      chiSoCaoDiem: parseInt(values.congToThaoChiSoCaoDiem),
      chiSoBinhThuong: parseInt(values.congToThaoChiSoBinhThuong),
      chiSoThapDiem: parseInt(values.congToThaoChiSoThapDiem),
      kenhNhanChiSoP: parseInt(values.congToThaoKenhNhanChiSoP),
      kenhNhanChiSoQ: parseInt(values.congToThaoKenhNhanChiSoQ),
      kenhNhanChiSoCaoDiem: parseInt(values.congToThaoKenhNhanChiSoCaoDiem),
      kenhNhanChiSoBinhThuong: parseInt(
        values.congToThaoKenhNhanChiSoBinhThuong
      ),
      kenhNhanChiSoThapDiem: parseInt(values.congToThaoKenhNhanChiSoThapDiem),
    };

    delete data.congToThaoChiSoP;
    delete data.congToThaoChiSoQ;
    delete data.congToThaoChiSoCaoDiem;
    delete data.congToThaoChiSoBinhThuong;
    delete data.congToThaoChiSoThapDiem;
    delete data.congToThaoKenhNhanChiSoP;
    delete data.congToThaoKenhNhanChiSoQ;
    delete data.congToThaoKenhNhanChiSoCaoDiem;
    delete data.congToThaoKenhNhanChiSoBinhThuong;
    delete data.congToThaoKenhNhanChiSoThapDiem;
  }
  delete data.congToTreoChiSoP;
  delete data.congToTreoChiSoQ;
  delete data.congToTreoChiSoCaoDiem;
  delete data.congToTreoChiSoBinhThuong;
  delete data.congToTreoChiSoThapDiem;
  delete data.congToTreoKenhNhanChiSoP;
  delete data.congToTreoKenhNhanChiSoQ;
  delete data.congToTreoKenhNhanChiSoCaoDiem;
  delete data.congToTreoKenhNhanChiSoBinhThuong;
  delete data.congToTreoKenhNhanChiSoThapDiem;

  authPostData({
    url: Endpoint.UPDATE_THONG_TIN_CONG_TO,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setNotSaved(false);
      }
    },
  });
};

export const handeCheckXacNhan = (
  detail,
  setLoading,
  setSelectedTab,
  setTextError,
  key
) => {
  authGetData({
    url: Endpoint.CHECK_XAC_NHAN_THI_CONG + "?hoSoId=" + detail.id,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setSelectedTab(key);
        setTextError("");
      } else {
        setTextError(res.message);
      }
    },
  });
};

//Call api Get list Lý Do Treo Tháo
export const handleGetDataLyDoTreoThao = (setLoading, setDataLyDoTreoThao) => {
  authGetData({
    url: Endpoint.COMMON_DM_LIST_LY_DO_TREO_THAO,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setDataLyDoTreoThao(res.data);
      } else {
        setDataLyDoTreoThao([]);
      }
    },
  });
};

//Lấy công tơ  TREO từ CMIS

export const handleGetCongToTreoCMIS = (
  value,
  setLoading,
  setListCongToCMIS,
  setVisible
) => {
  authGetData({
    url: `${Endpoint.GET_CONG_TO_CMIS}?SoCongTo=${value}&MaBDong=${CONG_TO_TREO}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setListCongToCMIS(res.data);
        setVisible(true);
      }
    },
  });
};
//Lấy công tơ tháo của khách hàng từ cmis

export const handleGetCTThaoCmis = (
  hoSoId,
  setLoading,
  setDetailCongToThaoCmis,
  setDataTIThao,
  setDataTUThao,
  setShowTIThao,
  setShowTUThao
) => {
  authGetData({
    url: `${Endpoint.GET_CT_THAO_CMIS}?HoSoId=${hoSoId}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDetailCongToThaoCmis(res.data);
        if (res.data.dataTi.length > 0) {
          setShowTIThao(true);
          setDataTIThao(res.data.dataTi);
        }
        if (res.data.dataTu.length > 0) {
          setShowTUThao(true);
          setDataTUThao(res.data.dataTu);
        }
      } else {
        alertMessage("warning", "Không tìm thấy thông tin thiết bị!");
      }
    },
  });
};

//Lấy công tơ Treo từ CMIS

export const handleGetCongToCMIS = (
  id,
  value,
  setLoading,
  setListCongToCMIS,
  setVisible,
  type,
  setTypeThietBi,
  onComplete
) => {
  authGetData({
    url: `${Endpoint.LIST_THIET_BI_CMIS}?HoSoId=${id}&SoThietBi=${value}&LoaiThietBi=${type}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setListCongToCMIS(res.data);
        setTypeThietBi(type);
        setVisible(true);
        onComplete(res.data);
      } else {
        setListCongToCMIS([]);
        setTypeThietBi(type);
        setVisible(true);
        onComplete([]);
      }
    },
  });
};

//Lấy chi tiết công tơ từ CMIS
export const handleGetDetailCongToCMIS = (
  maCongTo,
  setLoading,
  dataDetailBBLD,
  setDataDetailBBLD,
  closeModal
) => {
  authGetData({
    url: `${Endpoint.GET_DETAIL_CONG_TO_CMIS}?MaCongTo=${maCongTo}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        const data = { ...dataDetailBBLD, congToTreo: { ...res.data } };
        setDataDetailBBLD(data);
        closeModal();
      }
    },
  });
};

//Get thông tin chi tiết Biên bản lắp đặt TREO/THÁO
export const handleDetailBienBanLapDat = (
  hoSoId,
  setLoading,
  setDetailBienBanLapDat,
  setIsShowCongToThao,
  setIsBieuGia,
  setDataTI,
  setDataTU,
  setShowTI,
  setShowTU,
  setDataTIThao,
  setShowTIThao,
  setDataTUThao,
  setShowTUThao
) => {
  authGetData({
    url: Endpoint.GET_BIEN_BAN_LAP_DAT + "?HoSoId=" + hoSoId,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setIsShowCongToThao(res.data.coCongToThao);
        setIsBieuGia(res.data.nhieuBieuGia);
        setDetailBienBanLapDat(res.data);
        const newArrTi = [];
        const newArrTu = [];
        const newArrTiThao = [];
        const newArrTuThao = [];
        for (let i = 0; i < 3; i++) {
          newArrTi.push({
            [`soThietBi-${i}-ti`]: null,
            [`namSanXuat-${i}-ti`]: null,
            [`ngayKiemDinh-${i}-ti`]: null,
            [`loai-${i}-ti`]: null,
            [`tySoBien-${i}-ti`]: null,
            [`chiHopVien-${i}-ti`]: null,
            [`temKDVien-${i}-ti`]: null,
          });
        }
        for (let i = 0; i < 3; i++) {
          newArrTu.push({
            [`soThietBi-${i}-tu`]: null,
            [`namSanXuat-${i}-tu`]: null,
            [`ngayKiemDinh-${i}-tu`]: null,
            [`loai-${i}-tu`]: null,
            [`tySoBien-${i}-tu`]: null,
            [`chiHopVien-${i}-tu`]: null,
            [`temKDVien-${i}-tu`]: null,
          });
        }
        if (res.data.congToTreo) {
          // set data ti
          if (res.data.congToTreo.dataTi.length > 0) {
            newArrTi.forEach((item, i) => {
              item[`soThietBi-${i}-ti`] =
                res.data.congToTreo.dataTi[i].soThietBi;
              item[`namSanXuat-${i}-ti`] =
                res.data.congToTreo.dataTi[i].namSanXuat;
              item[`ngayKiemDinh-${i}-ti`] =
                res.data.congToTreo.dataTi[i].ngayKiemDinh;
              item[`loai-${i}-ti`] = res.data.congToTreo.dataTi[i].loai;
              item[`tySoBien-${i}-ti`] = res.data.congToTreo.dataTi[i].tySoBien;
              item[`chiHopVien-${i}-ti`] =
                res.data.congToTreo.dataTi[i].chiHopVien;
              item[`temKDVien-${i}-ti`] =
                res.data.congToTreo.dataTi[i].temKDVien;
            });
            setShowTI(true);
          }

          //setData tu
          if (res.data.congToTreo.dataTu.length > 0) {
            newArrTu.forEach((item, i) => {
              item[`soThietBi-${i}-tu`] =
                res.data.congToTreo.dataTu[i].soThietBi;
              item[`namSanXuat-${i}-tu`] =
                res.data.congToTreo.dataTu[i].namSanXuat;
              item[`ngayKiemDinh-${i}-tu`] =
                res.data.congToTreo.dataTu[i].ngayKiemDinh;
              item[`loai-${i}-tu`] = res.data.congToTreo.dataTu[i].loai;
              item[`tySoBien-${i}-tu`] = res.data.congToTreo.dataTu[i].tySoBien;
              item[`chiHopVien-${i}-tu`] =
                res.data.congToTreo.dataTu[i].chiHopVien;
              item[`temKDVien-${i}-tu`] =
                res.data.congToTreo.dataTu[i].temKDVien;
            });
            setShowTU(true);
          }
        }
        setDataTI(newArrTi);
        setDataTU(newArrTu);
        for (let i = 0; i < res.data.congToThao.dataTi.length; i++) {
          newArrTiThao.push({
            [`soThietBi-${i}-ti`]: null,
            [`namSanXuat-${i}-ti`]: null,
            [`ngayKiemDinh-${i}-ti`]: null,
            [`loai-${i}-ti`]: null,
            [`tySoBien-${i}-ti`]: null,
            [`chiHopVien-${i}-ti`]: null,
            [`temKDVien-${i}-ti`]: null,
          });
        }
        for (let i = 0; i < res.data.congToThao.dataTu.length; i++) {
          newArrTuThao.push({
            [`soThietBi-${i}-tu`]: null,
            [`namSanXuat-${i}-tu`]: null,
            [`ngayKiemDinh-${i}-tu`]: null,
            [`loai-${i}-tu`]: null,
            [`tySoBien-${i}-tu`]: null,
            [`chiHopVien-${i}-tu`]: null,
            [`temKDVien-${i}-tu`]: null,
          });
        }
        if (res.data.congToThao) {
          // set data ti
          if (res.data.congToThao.dataTi.length > 0) {
            newArrTiThao.forEach((item, i) => {
              item[`soThietBi`] = res.data.congToThao.dataTi[i].soThietBi;
              item[`namSanXuat`] = res.data.congToThao.dataTi[i].namSanXuat;
              item[`ngayKiemDinh`] = res.data.congToThao.dataTi[i].ngayKiemDinh;
              item[`loai`] = res.data.congToThao.dataTi[i].loai;
              item[`tySoBien`] = res.data.congToThao.dataTi[i].tySoBien;
              item[`chiHopVien`] = res.data.congToThao.dataTi[i].chiHopVien;
              item[`temKDVien`] = res.data.congToThao.dataTi[i].temKDVien;
            });
            setShowTIThao(true);
          }

          //setData tu
          if (res.data.congToThao.dataTu.length > 0) {
            newArrTuThao.forEach((item, i) => {
              item[`soThietBi`] = res.data.congToThao.dataTu[i].soThietBi;
              item[`namSanXuat`] = res.data.congToThao.dataTu[i].namSanXuat;
              item[`ngayKiemDinh`] = res.data.congToThao.dataTu[i].ngayKiemDinh;
              item[`loai`] = res.data.congToThao.dataTu[i].loai;
              item[`tySoBien`] = res.data.congToThao.dataTu[i].tySoBien;
              item[`chiHopVien`] = res.data.congToThao.dataTu[i].chiHopVien;
              item[`temKDVien`] = res.data.congToThao.dataTu[i].temKDVien;
            });
            setShowTUThao(true);
          }
        }
        setDataTIThao(newArrTiThao);
        setDataTUThao(newArrTuThao);
      } else {
        setDetailBienBanLapDat({});
      }
    },
  });
};

//Cập nhật thông tin Biên bản lắp đặt TREO/THAO
export const handleCreateOrUpdateBBLD = (
  data,
  setLoading,
  setFetchDataTable,
  form,
  closeModal,
  dispatch
) => {
  authPostData({
    url: Endpoint.CREATE_OR_UPDATE_BIEN_BAN_LAP_DAT + "?HoSoId=" + data.hoSoId,
    method: METHOD_POST,
    payload: {
      ...data,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchDataTable(true);
        dispatch(closeModal());
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleSubmitForm = (
  values,
  setLoading,
  setFetchDataTable,
  form,
  closeModal,
  dispatch,
  isShowTI,
  isShowTU
) => {
  const data = {
    ...values,
  };

  let validThao = false;
  const validTreo = validReg1(
    15,
    3,
    data.congToTreo.heSoNhanHeThong,
    form,
    "heSoNhanHeThongTreo"
  );

  if (data.congToThao && data.congToThao.heSoNhanHeThongThao) {
    validThao = validReg1(
      15,
      3,
      data.congToThao.heSoNhanHeThong,
      form,
      "heSoNhanHeThongThao"
    );
  }
  // if (data.congToTreo && !data.congToTreo.soCongTo) {
  //   alertMessage(
  //     "warning",
  //     "Thông báo!",
  //     "Số công tơ treo không được bỏ trống!"
  //   );
  // }
  let validMBD = true;
  if (isShowTI) {
    // eslint-disable-next-line array-callback-return
    data.congToTreo.dataTi.map((item, i) => {
      // eslint-disable-next-line array-callback-return
      if (
        item["soThietBi"] === undefined ||
        item["soThietBi"] === "" ||
        item["soThietBi"] === null
      ) {
        form.setFields([
          {
            name: [`soThietBi-${i}-ti`],
            errors:
              item.soThietBi === "" ||
              item.soThietBi === null ||
              item.soThietBi === undefined
                ? [`Không được bỏ trống`]
                : false,
          },
        ]);
        return (validMBD = false);
      }
    });
  }

  if (isShowTU) {
    // eslint-disable-next-line array-callback-return
    data.congToTreo.dataTu.map((item, i) => {
      // eslint-disable-next-line array-callback-return
      if (
        item["soThietBi"] === undefined ||
        item["soThietBi"] === "" ||
        item["soThietBi"] === null
      ) {
        form.setFields([
          {
            name: [`soThietBi-${i}-tu`],
            errors:
              item.soThietBi === "" ||
              item.soThietBi === null ||
              item.soThietBi === undefined
                ? [`Không được bỏ trống`]
                : false,
          },
        ]);
        return (validMBD = false);
      }
    });
  }

  //Check
  if (validTreo && validThao && validMBD) {
    handleCreateOrUpdateBBLD(
      data,
      setLoading,
      setFetchDataTable,
      form,
      closeModal,
      dispatch
    );
  } else if (validTreo && validMBD) {
    handleCreateOrUpdateBBLD(
      data,
      setLoading,
      setFetchDataTable,
      form,
      closeModal,
      dispatch
    );
  }
};

//Lấy link file Biên bản lắp đặt
export const handleGetFileBBLD = (hoSoId, setLoading, onComplete) => {
  authGetData({
    url: Endpoint.GET_LINK_FILE_BBLD + "?HoSoId=" + hoSoId,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        onComplete(res.data);
      } else {
        onComplete("error");
      }
    },
  });
};

export const handleCheckXacNhan = (
  detail,
  setLoading,
  dispatch,
  openModal,
  closeModal,
  setFetchDataTable
) => {
  authGetData({
    url: Endpoint.CHECK_GHI_NHAN_THI_CONG + "?hoSoId=" + detail.id,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        dispatch(
          openModal({
            content: (
              <ModalGhiNhanKetQua
                // detailApGia={row}
                // handleUpdate={handleUpdate}
                detail={detail}
                closeModal={closeModal}
                setFetchDataTable={setFetchDataTable}
              />
            ),
            title: "Ghi nhận kết quả thi công",
            size: "large",
          })
        );
      }
    },
  });
};
