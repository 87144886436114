import React, { memo, useEffect, useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import ModalXacNhan from "./modal-xac-nhan-thu-tien";
import { handleConfirmThuTien } from "../services";
import { tinhTongChiPhi } from "../../xac-nhan-thu-tien/services";

function Index({
  visibleXacNhan,
  handleCloseModalXacNhan,
  detail,
  setFetchDataTable,
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState({});
  const [checked, setChecked] = useState(false);
  const [loadingConfirm , setLoadingConfirm] = useState(false)

  //submit

  const onFinish = (values) => {
    //call api
    handleConfirmThuTien(
      setLoadingConfirm,
      values,
      detail,
      setFetchDataTable,
      handleCloseModalXacNhan,
      checked,
      content
    );
  };

  useEffect(() => {
    if (detail && Object.keys(detail).length > 0 && detail.id) {
      tinhTongChiPhi(detail, setLoading, (values) => {
        if (values) {
          setContent(values);
          form.setFieldsValue({ soTien: values.tongTien });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <ModalXacNhan
      loading={loading}
      t={t}
      visibleXacNhan={visibleXacNhan}
      handleCloseModalXacNhan={handleCloseModalXacNhan}
      form={form}
      onFinish={onFinish}
      handleChange={handleChange}
      content={content}
      checked={checked}
      setChecked={setChecked}
      loadingConfirm={loadingConfirm}
    />
  );
}
export default memo(Index);
