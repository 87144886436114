import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
// import BcDsYeuCauCoSDTNhieuHoSo from "./bc-cong-tac-dich-vu-dien-tu/bc-danh-sach-yeu-cau-co-so-dien-thoai-nhieu-ho-so";
// import DanhGiaMucDoHaiLongKH from "./bc-cong-tac-dich-vu-dien-tu/th-ve-viec-danh-gia-muc-do-hai-long-khach-hang";
// import THSLDVTiepNhanDienTu from "./bc-cong-tac-dich-vu-dien-tu/th-sl-dich-vu-tiep-nhan-dien-tu";

import { handleDownload, handleOpenPdf, selectBcdichvudientu } from "./service";

export default function BaoCaoDichVuDienTu() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [screen] = useState(selectBcdichvudientu[0].value);

  const user = useSelector(authSelector);
  const onFinish = (values) => {
    handleDownload(values, setLoading);
  };

  const renderViewThangNam = () => {
    const listValueShouldRender = [Endpoint.BCK_BC_DANH_SACH_YEU_CAU_SDT_NHIEU_HO_SO];
    if (listValueShouldRender.includes(form.getFieldValue("reportType"))) {
      return (
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}>
          <Form.Item name="reportDate" label="Tháng / Năm" labelAlign="left" required>
            <DatePickerComponent picker="month" formKey="reportDate" form={form} />
          </Form.Item>
        </Form.Item>
      );
    }
    return <></>;
  };

  const renderViewDonvi = () => {
    const listValueShouldRender = [
      Endpoint.BCCTDVKH_BC_TIEP_NHAN_DANG_KY_DICH_VU_DIEN_TU,
      Endpoint.BCCTDVKH_BC_GIAI_QUYET_QUA_HAN_CAC_DICH_VU_DIEN,
      Endpoint.BCCTDVKH_BC_YEU_CAU_THUC_HIEN_THEO_PHUONG_THUC_DIEN_TU,
      Endpoint.BCK_CHI_TIET_HDMBDSH_KY_DIEN_TU,
    ];
    if (listValueShouldRender.includes(form.getFieldValue("reportType"))) {
      return (
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}>
          <Form.Item name="unitId" label="Đơn vị" labelAlign="left" required>
            <Selection url={Endpoint.GET_TEAM_LIST_CODE} formKey="unitId" form={form} />
          </Form.Item>
        </Form.Item>
      );
    }
    return <></>;
  };

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Báo cáo dịch vụ điện tử
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <Form
              onFinish={onFinish}
              form={form}
              initialValues={{ reportType: screen, unitId: user.user.unitCode }}
              colon={false}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
            >
              <Form.Item name="reportType" label="Loại báo cáo" labelAlign="left" required>
                <Select placeholder="Tất cả" formKey="reportType" options={selectBcdichvudientu}></Select>
              </Form.Item>
           
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {/* {({ getFieldValue }) =>
                  getFieldValue("reportType") === Endpoint.BCCTDVKH_BC_TIEP_NHAN_DANG_KY_DICH_VU_DIEN_TU ||
                  getFieldValue("reportType") === Endpoint.BCCTDVKH_BC_GIAI_QUYET_QUA_HAN_CAC_DICH_VU_DIEN ||
                  getFieldValue("reportType") === Endpoint.BCCTDVKH_BC_YEU_CAU_THUC_HIEN_THEO_PHUONG_THUC_DIEN_TU ||
                  getFieldValue("reportType") === Endpoint.BCK_CHI_TIET_HDMBDSH_KY_DIEN_TU ||
                  getFieldValue("reportType") === Endpoint.BCCTDVKH_BC_YEU_CAU_THUC_HIEN_THEO_PHUONG_THUC_DIEN_TU */}
                  {({ getFieldValue }) =>
                  getFieldValue("reportType") !== Endpoint.BCK_BC_DANH_SACH_YEU_CAU_SDT_NHIEU_HO_SO
                   ? (
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
                    >
                      <Form.Item name="unitId" label="Đơn vị" labelAlign="left" required>
                        <Selection url={Endpoint.GET_TEAM_LIST_CODE} formKey="unitId" form={form} />
                      </Form.Item>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") === Endpoint.BCK_BC_DANH_SACH_YEU_CAU_SDT_NHIEU_HO_SO ? (
                    <Form.Item name="reportDate" label="Tháng / Năm" labelAlign="left" required>
                      <DatePickerComponent picker="month" formKey="reportDate" form={form} />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") !== Endpoint.BCK_BC_DANH_SACH_YEU_CAU_SDT_NHIEU_HO_SO ? (
                    <Form.Item name="TuNgay" label="Từ ngày" labelAlign="left" required>
                      <DatePickerComponent picker="Day" formKey="TuNgay" form={form} format={"YYYY-MM-DD"} />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.reportType !== currentValues.reportType}
              >
                {({ getFieldValue }) =>
                  getFieldValue("reportType") !== Endpoint.BCK_BC_DANH_SACH_YEU_CAU_SDT_NHIEU_HO_SO ? (
                    <Form.Item name="DenNgay" label="Đến ngày" labelAlign="left" required>
                      <DatePickerComponent picker="Day" formKey="DenNgay" form={form} format={"YYYY-MM-DD"} />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              <Row>
                <Col span={4}></Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Xuất báo cáo
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </ContentWrapper>
      </Container>
      {/* )} */}
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
