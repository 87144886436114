import {
  METHOD_POST,
  STATUSCODE_200,
  TYPE_TABLE_CONGTO,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { getErrorMessage } from "@utils/function";
import { authPostData } from "@utils/request";
export const handlePKDPhanCong = (
  values,
  listCongToId,
  setLoading,
  setFetchDataTable,
  form,
  setVisiblePhanCong,
  setSelectedRowKeys,
  typeList
) => {
  const data = {
    ...values,
    congToIds: listCongToId,
    isPhucTra: typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA ? true : false,
  };
  authPostData({
    url: Endpoint.PKD_PHANCONG,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setVisiblePhanCong(false);
        setSelectedRowKeys([]);
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleDTPhanCong = (
  values,
  listCongToId,
  setLoading,
  setFetchDataTable,
  form,
  setVisiblePhanCong,
  setSelectedRowKeys,
  typeList
) => {
  const data = {
    ...values,
    congToIds: listCongToId,
    isPhucTra: typeList === TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA ? true : false,
  };
  authPostData({
    url: Endpoint.DOITRUONG_PHANCONG,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setVisiblePhanCong(false);
        setSelectedRowKeys([]);
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleGetMessage = (
  listCongToId,
  setLoading,
  setDataDoi,
  setVisiblePhanCong,
  visiblePhanCong
) => {
  const data = {
    congToIds: listCongToId,
  };
  authPostData({
    url: Endpoint.GET_PHANCONG,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataDoi(res.data.listDoi);
        setVisiblePhanCong(!visiblePhanCong);
      } else {
        setDataDoi([]);
        setVisiblePhanCong(false);
      }
    },
  });
};
