import { Col, Row } from "antd";

export default function Index(props) {
  const { detail } = props;
  return (
    <Row gutter={24} style={{ padding: "16px" }}>
      <Col span={24} md={8}>
        <h6 className="fs-14px c-t-label">Tên Khách hàng</h6>
        <h5 className="fs-16px font-bold">{detail?.tenKH}</h5>
      </Col>
      <Col span={24} md={8}>
        <h6 className="fs-14px c-t-label">Tên Trạm</h6>
        <h5 className="fs-16px font-bold">{detail?.tenTram}</h5>
      </Col>
      <Col span={24} md={8}>
        <h6 className="fs-12px c-t-label">Mã GC</h6>
        <h5 className="fs-14px font-bold">{detail?.maGC}</h5>
      </Col>
      <Col span={24} md={16}>
        <h6 className="fs-12px c-t-label">Số công tơ</h6>
        <h5 className="fs-14px font-bold">{detail?.soCongTo}</h5>
      </Col>
      <Col span={24} md={8}>
        <h6 className="fs-12px c-t-label">Số cột</h6>
        <h5 className="fs-14px font-bold">{detail?.soCot}</h5>
      </Col>
    </Row>
  );
}
