import React, { memo, useState } from "react";
import { Form } from "antd";
import HuyBangKe from "./modal-huy-bang-ke";
import { handleHuyBangKe } from "./services";

function Index({ visibleHuy, handleCloseModalhuy, setFetchDataTable, id }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // hàm call api hủy bảng kê

  const onFinish = (values) => {
    handleHuyBangKe(
      id,
      setLoading,
      setFetchDataTable,
      handleCloseModalhuy,
      values,
      form
    );
  };

  return (
    <HuyBangKe
      loading={loading}
      visibleHuy={visibleHuy}
      handleCloseModalhuy={handleCloseModalhuy}
      setFetchDataTable={setFetchDataTable}
      onFinish={onFinish}
      form={form}
    />
  );
}

export default memo(Index);
