import { authSelector } from "@state/auth";
import {
  formatSplitDate,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import LogThaoTacTable from "./table";
import { handleList } from "./services";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_ENGLISH,
  FORMAT_MONTH,
  MONTH_STRING,
} from "@utils/constants";
import moment from "moment";

export default function Index() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { user } = useSelector(authSelector);

  //filter
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    donViId: searchParams.get("donViId")
      ? searchParams.get("donViId")
      : user.unitId,
    nguoiThaoTac: searchParams.get("nguoiThaoTac")
      ? searchParams.get("nguoiThaoTac")
      : user.id,
    tuNgay: moment().startOf(MONTH_STRING).format(FORMAT_ENGLISH),
    denNgay: moment(moment(), FORMAT_MONTH).format(FORMAT_ENGLISH),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //change pagination
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //fetch list
  const getListData = useCallback(() => {
    handleList(filterConditions, setLoading, setDataSource, setTotalCount);
  }, [filterConditions]);

  useEffect(() => {
    getListData();
  }, [getListData]);

  const columns = [
    {
      title: "Mã yêu cầu",
      key: "maYCKN",
      dataIndex: "maYCKN",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Khách hàng",
      key: "khachHang",
      dataIndex: "khachHang",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "17%",
    },
    {
      title: "Loại yêu cầu",
      key: "loaiYeuCau",
      dataIndex: "loaiYeuCau",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Ngày thao tác",
      key: "ngayThaoTac",
      dataIndex: "ngayThaoTac",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "10%",
    },
    {
      title: "Người thao tác",
      key: "nguoiThaoTac",
      dataIndex: "nguoiThaoTac",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
    },
    {
      title: "Bộ phận",
      key: "boPhan",
      dataIndex: "boPhan",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
    },
    {
      title: "Nội dung",
      key: "noiDung",
      dataIndex: "noiDung",
      render: (text) => <div className="table-text-left">{text}</div>,
      // width: "23%",
    },
  ];

  //search
  const handleSearch = useCallback((values) => {
    setFilterConditions(() => ({
      // ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      ...values,
      // tuNgay: formatSplitDate(values.tuNgay, true),
      // denNgay: formatSplitDate(values.denNgay, true),
    }));
  }, []);

  //clear
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
      nguoiThaoTac: user.id,
    });
  }, [user]);

  return (
    <LogThaoTacTable
      columns={columns}
      handleSearch={handleSearch}
      clearFilter={clearFilter}
      filterConditions={filterConditions}
      loading={loading}
      onChangePagination={onChangePagination}
      dataSource={dataSource}
      totalCount={totalCount}
    />
  );
}
