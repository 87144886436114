import { createStore, applyMiddleware, compose } from "redux";
import { rootReducer } from "./reducer";
import { rootSaga } from "./sagas";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["quanLyChiSo"],
};

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__()
  : (x) => x;

const bindMiddleware = (middleware) => {
  return compose(applyMiddleware(...middleware), devTools);
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, bindMiddleware([sagaMiddleware]));
export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
