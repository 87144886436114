export const selectBCBrandName = [
  {
    label: "Biểu 1: Tổng hợp theo toàn đơn vị",
    value: 1,
  },
  {
    label: "Biểu 2: Chi tiết",
    value: 2,
  },
];
