import { BASE_API_URL } from "@utils/constants";

export const HETHONG_Endpoint = {
  LOGIN: `${BASE_API_URL}/auth/login`,
  LOGIN_SSO: `${BASE_API_URL}/auth/sso-login`,
  ROOM: `${BASE_API_URL}/user/room`,
  DOI: `${BASE_API_URL}/doi/create`,
  GET_PROFILE: `${BASE_API_URL}/user/profile`,
  GET_MODULE_LIST: `${BASE_API_URL}/module`,
  ROLE: `${BASE_API_URL}/role`,
  GET_ROLE_DETAIL: `${BASE_API_URL}/role/get-role-detail`,
  USER: `${BASE_API_URL}/user`,
  FORGOT_PASSWORD: `${BASE_API_URL}/user/forgot-password`,
  CHECK_OTP: `${BASE_API_URL}/user/check-otp`,
  USER_CHANGE_PASSWORD: `${BASE_API_URL}/user/change-password`,
  PROFILE_CHANGE_PASSWORD: `${BASE_API_URL}/user/change-mypassword`,
  RESET_PASSWORD: `${BASE_API_URL}/user/reset-password`,
  UPDATE_PROFILE: `${BASE_API_URL}/user/profile-web`,
  GET_PERMISSION_VIA_MODULE: `${BASE_API_URL}/role/get-permission-default`,
  CHECK_SSO: `${BASE_API_URL}/user/check-user-sso`,
  GET_CMIS_USERS: `${BASE_API_URL}/user/get-user-cmis`,
  PAGE: `${BASE_API_URL}/page`,
  // user claim
  USER_CLAIM: `${BASE_API_URL}/userclaim`,
  GET_LIST_TEAM_USER_CLAIM: `${BASE_API_URL}/userclaim/get-list-team-userclaim`,
  //common
  LIST_SELECT_MODULE: `${BASE_API_URL}/common/modules`,
  GET_UNIT_LIST: `${BASE_API_URL}/common/donvi`,
  GET_TEAM_LIST: `${BASE_API_URL}/common/todoi`,
  UPLOAD_CHU_KY: `${BASE_API_URL}/user/chu-ky`,
  GET_TEAM_LIST_CODE: `${BASE_API_URL}/common/madonvi`,

  GET_POSITION_LIST: `${BASE_API_URL}/common/positions`,
  GET_VI_TRI_CONG_VIEC: `${BASE_API_URL}/common/vitricongviec`,
  GET_ROLES: `${BASE_API_URL}/common/list-roles`,
  GET_PAGE: `${BASE_API_URL}/common/page`,
  DONG_BO_MASTER_TO_CMIS: `${BASE_API_URL}/common/sync-cmis-data-seperate`,

  // Menu
  MENU: `${BASE_API_URL}/menu`,

  // Log
  LOG_INTERNAL_LIST: `${BASE_API_URL}/log/internal`,
  LOG_INTERNAL_DETAIL: `${BASE_API_URL}/log/internal-detail`,
  LOG_EXTERNAL_LIST: `${BASE_API_URL}/log/external`,
  LOG_EXTERNAL_DETAIL: `${BASE_API_URL}/log/external-detail`,
  LOG_ERROR: `${BASE_API_URL}/log/error`,
  LOG_ERROR_DETAIL: `${BASE_API_URL}/log/error-detail`,
  GET_LIST_MODULE: `${BASE_API_URL}/module`,
};
