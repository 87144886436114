import LoadingComponent from "@components/Loading";
import TableComponent from "@components/TableComponent";
import { Button, Col, Modal, Row } from "antd";
export default function YeuCauModal(props) {
  const {
    loading,
    visible,
    setIsShowYC,
    columns,
    dataSource,
    filter,
    onChangePagination,
  } = props;
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={"Danh sách tiếp nhận yêu cầu"}
        visible={visible}
        onCancel={() => setIsShowYC(false)}
        footer={null}
        width={"85%"}
        style={{ top: "1%" }}
      >
        {/* Truyền content */}
        <TableComponent
          header="Danh sách tiếp nhận yêu cầu"
          columns={columns}
          dataSource={dataSource}
          pagination={filter}
          onChangePagination={onChangePagination}
          scrollX={2800}
          scrollY={600}
        />
        <Row
          gutter={24}
          justify="center"
          style={{ marginTop: "24px", textAlign: "center" }}
        >
          <Col span={24}>
            <Button
              key="back"
              onClick={() => setIsShowYC(false)}
              className="button-closed"
            >
              Đóng lại
            </Button>
          </Col>
        </Row>
      </Modal>
    </LoadingComponent>
  );
}
