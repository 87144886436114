import styled from "styled-components";

export const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
  tr:last-child > td {
    background-color: rgb(255, 234, 234) !important;
    font-weight: bold;
  }
`;
