import { authSelector } from "@state/auth";
import { Tabs } from "antd";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import TongHopSoLieuTab from "./tabs/tong-hop-so-lieu";
import BaoCaoTongHopTabs from "./tabs/bao-cao-tong-hop";
import BaoCaoChiTietTabs from "./tabs/bao-cao-chi-tiet";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  // DEFAULT_PAGEINDEX,
  // DEFAULT_PAGESIZE,
  INDEX_TAB2,
  INDEX_TAB1,
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  MONTH_STRING,
  FORMAT_ENGLISH,
  ENUM_BUTTON_TYPE,
  // FORMAT_MONTH_ENGLISH,
} from "@utils/constants";
import {
  buildQueryString,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { handleListBaoCao } from "./services";
import { TabsContainer } from "./css-styled";
import moment from "moment";
import { Endpoint } from "@utils/endpoint";
import { downloadFile } from "@utils/request";
// import moment from "moment";

const { TabPane } = Tabs;

export default function Index() {
  const { user } = useSelector(authSelector);
  const [loading, setLoading] = useState(false);
  const [keyTabs, setKeyTabs] = useState(INDEX_TAB1);
  const location = useLocation();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [redirect, setRedirect] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isCompany, setIsCompany] = useState(user.unitId);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [quy, setQuy] = useState("");
  // const [callFirst, setCallFirst] = useState(false);
  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);
  const [filterExcel, setFilterExcel] = useState(false);
  //filter
  const [filterConditions, setFilterConditions] = useState({
    // pageIndex: DEFAULT_PAGEINDEX,
    // pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    donViId: searchParams.get("donViId")
      ? searchParams.get("donViId")
      : user.unitId,
    ngayBaoCao: moment(moment().startOf(MONTH_STRING)).format(FORMAT_ENGLISH),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //change pagination
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  };

  //fetch báo cáo
  const getListBaoCao = useCallback(() => {
    handleListBaoCao(
      setLoading,
      filterConditions,
      setData,
      setTotalCount,
      setIsCompany,
      setMonth,
      setYear,
      setQuy
    );
  }, [filterConditions]);

  useEffect(() => {
    if (filterExcel) {
      const endpoint = `${
        Endpoint.XUAT_EXCEL_BAO_CAO_TONG_HOP
      }?${buildQueryString(parseParams(filterConditions))}`;
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel]);

  useEffect(() => {
    if (type === ENUM_BUTTON_TYPE.TIM_KIEM) getListBaoCao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListBaoCao]);

  const callback = (key) => {
    if (key === INDEX_TAB2) {
      // if (filterConditions?.ngayBaoCao === undefined) setCallFirst(true);
      // else setCallFirst(false);
      setRedirect(true);
    } else setRedirect(false);

    setKeyTabs(key);
  };

  return (
    <TabsContainer
      activeKey={keyTabs}
      onChange={callback}
      className="tabs-container"
    >
      <TabPane tab="Báo cáo tổng hợp" key="1">
        <BaoCaoTongHopTabs
          setKeyTabs={setKeyTabs}
          filterConditions={filterConditions}
          setFilterConditions={setFilterConditions}
          user={user}
          totalCount={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          data={data}
          setRedirect={setRedirect}
          isCompany={isCompany}
          month={month}
          year={year}
          quy={quy}
          getListBaoCao={getListBaoCao}
          setIsSubmit={setIsSubmit}
          type={type}
          setType={setType}
          setFilterExcel={setFilterExcel}
        />
      </TabPane>
      <TabPane tab="Báo cáo chi tiết" key="2">
        <BaoCaoChiTietTabs
          redirect={redirect}
          setRedirect={setRedirect}
          setIsSubmit={setIsSubmit}
          isSubmit={isSubmit}
          newFilter={{
            ...filterConditions,
            pageIndex: DEFAULT_PAGEINDEX,
            pageSize: DEFAULT_PAGESIZE,
            loaiHoSo: "1",
          }}
          keyTabs={keyTabs}
          // callFirst={callFirst}
          // setCallFirst={setCallFirst}
        />
      </TabPane>
      <TabPane
        tab={
          <div style={{ display: "flex" }}>
            <div
              style={{ color: "red", fontWeight: "bold", marginRight: "8px" }}
            >
              Tổng hợp số liệu
            </div>
            <div style={{ color: "blue" }}>
              ← Cần tổng hợp số liệu để xem dữ liệu báo cáo mới nhất
            </div>
          </div>
        }
        key="3"
      >
        <TongHopSoLieuTab user={user} />
      </TabPane>
    </TabsContainer>
  );
}
