import { memo, useRef, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  Row,
  Col,
  Checkbox,
  InputNumber,
} from "antd";
import FormComponent from "@components/Form";
import { useTranslation } from "react-i18next";
import LoadingComponent from "@components/Loading";
import { createOrUpdateLyDoTreoThao } from "./services";

const { TextArea } = Input;

function LoaiLyDoTreoThaoModal({
  detailLyDoTreoThao,
  visible,
  closeModal,
  getListLyDoTreoThao,
}) {
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(detailLyDoTreoThao.trangThai);
  const handleSubmit = (values) => {
    const data = {
      ...values,
      trangThai: isActive ? isActive : "false",
    };
    if (detailLyDoTreoThao.id) {
      data.id = detailLyDoTreoThao.id;
    }
    handleCreateUpdate(data, form);
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();
    if (detailLyDoTreoThao && detailLyDoTreoThao.id) {
      setIsActive(isActive);
    } else {
      setIsActive(true);
    }

    // eslint-disable-next-line
  }, []);

  //xử lý call api sửa/tạo

  const handleCreateUpdate = (data, form) => {
    createOrUpdateLyDoTreoThao(
      data,
      setLoading,
      getListLyDoTreoThao,
      closeModal,
      form
    );
  };

  //Thay đổi field TrangThai
  const handleChangeActive = (e) => {
    setIsActive(e.target.checked);
  };

  return (
    <Modal
      title={
        detailLyDoTreoThao.id
          ? "Cập nhật lý do treo tháo"
          : "Tạo mới lý do treo tháo"
      }
      visible={visible}
      onCancel={closeModal}
      className="modal"
      footer={null}
    >
      <LoadingComponent loading={loading}>
        <FormComponent
          autoComplete="off"
          form={form}
          name="form-submit"
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            ...detailLyDoTreoThao,
            trangThai: isActive ? isActive : false,
          }}
          scrollToFirstError
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Mã lý do"
                name="maLyDo"
                className="required-field"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Nhóm" name="nhom" className="required-field">
                <InputNumber type="number" controls={false} />
              </Form.Item>
            </Col>
            <Col span={6} style={{ display: "flex", alignItems: "center" }}>
              <Form.Item
                label=""
                name="trangThai"
                style={{ marginBottom: "0" }}
              >
                <Checkbox onChange={handleChangeActive} checked={isActive}>
                  Hoạt động
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Tên lý do"
                name="tenLyDo"
                className="required-field"
              >
                <Input placeholder="Nhập tên lý do treo tháo" ref={inputRef} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Nguyên nhân" name="nguyenNhan">
                <TextArea
                  placeholder="Nhập nguyên nhân cho lý do treo tháo"
                  row={3}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-submit"
                loading={loading}
              >
                {detailLyDoTreoThao.id
                  ? t("button.cap_nhat")
                  : t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </LoadingComponent>
    </Modal>
  );
}

export default memo(LoaiLyDoTreoThaoModal);
