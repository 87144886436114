import { METHOD_POST, STATUSCODE_200, TYPE_INPDF_GNDN } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authGetMultiplesRequest, authPostData, downloadFilePostData } from "@utils/request";

// call api in biên bảng pdf

export function inPDF(setLoading, onComplete, typeInPDF, payload) {
  // Bao cao 1
  typeInPDF === TYPE_INPDF_GNDN.CHITIET_DNGN
    ? authPostData({
        url: Endpoint.PDF_CHITIET_DNGN,
        setLoading: setLoading,
        method: METHOD_POST,
        payload: payload,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            onComplete(res.data);
          } else {
            onComplete("error");
          }
        },
      })
    : // Bao cao 2
    typeInPDF === TYPE_INPDF_GNDN.TONGHOP_DNGN
    ? authPostData({
        url: Endpoint.PDF_TONGHOP_DNGN,
        setLoading: setLoading,
        method: METHOD_POST,
        payload: payload,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            onComplete(res.data);
          } else {
            onComplete("error");
          }
        },
      })
    : // Bao cao 3
    typeInPDF === TYPE_INPDF_GNDN.CHITIET_CS_CTD_DNGN
    ? authPostData({
        url: Endpoint.PDF_CHITIET_CSCT_DNGN,
        setLoading: setLoading,
        method: METHOD_POST,
        payload: payload,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            onComplete(res.data);
          } else {
            onComplete("error");
          }
        },
      })
    : // Bao cao 5
    typeInPDF === TYPE_INPDF_GNDN.BBQT_DN_PHANKHANG
    ? authPostData({
        url: `${Endpoint.PDF_BB_QTDN_PHANKHANG}`,
        setLoading: setLoading,
        method: METHOD_POST,
        payload: payload,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            onComplete(res.data);
          } else {
            onComplete("error");
          }
        },
      })
    : // Bao cao 6
    typeInPDF === TYPE_INPDF_GNDN.SAISO_TRAM110
    ? authPostData({
        url: `${Endpoint.PDF_SAISO_TRAM110}`,
        setLoading: setLoading,
        method: METHOD_POST,
        payload: payload,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            onComplete(res.data);
          } else {
            onComplete("error");
          }
        },
      })
    : // Bao cao 7
    typeInPDF === TYPE_INPDF_GNDN.CHITIET_DN_THEOTRAM
    ? authPostData({
        url: `${Endpoint.PDF_CTDN_THEOTRAM}`,
        setLoading: setLoading,
        method: METHOD_POST,
        payload: payload,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            onComplete(res.data);
          } else {
            onComplete("error");
          }
        },
      })
    : // Bao cao 8
    typeInPDF === TYPE_INPDF_GNDN.CHITIET_SANLUONG
    ? authPostData({
        url: Endpoint.PDF_CHITIET_SANLUONG,
        setLoading: setLoading,
        method: METHOD_POST,
        payload: payload,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            onComplete(res.data);
          } else {
            onComplete("error");
          }
        },
      })
    : // Bao cao 9
    typeInPDF === TYPE_INPDF_GNDN.DIEMDO_BIENDONG_THANG
    ? authPostData({
        url: `${Endpoint.PDF_DDTT_TRONGTHANG}`,
        setLoading: setLoading,
        method: METHOD_POST,
        payload: payload,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            onComplete(res.data);
          } else {
            onComplete("error");
          }
        },
      })
    : // Bao cao 10
    typeInPDF === TYPE_INPDF_GNDN.SANLUONG_DAUNGUON_3GIA
    ? authPostData({
        url: `${Endpoint.PDF_SL_DAUNGUON_BAGIA}`,
        setLoading: setLoading,
        method: METHOD_POST,
        payload: payload,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            onComplete(res.data);
          } else {
            onComplete("error");
          }
        },
      })
    : // Bao cao 11
    typeInPDF === TYPE_INPDF_GNDN.CHITIET_DN_TUDUNG
    ? authPostData({
        url: `${Endpoint.PDF_CHITIET_DN_TUDUNG}`,
        setLoading: setLoading,
        method: METHOD_POST,
        payload: payload,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            onComplete(res.data);
          } else {
            onComplete("error");
          }
        },
      })
    : // Bao cao 12
    typeInPDF === TYPE_INPDF_GNDN.BBQT_DIENNANG_GIAONHAN
    ? authPostData({
        url: `${Endpoint.PDF_BB_QTDN_GIAONHAN}`,
        setLoading: setLoading,
        method: METHOD_POST,
        payload: payload,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            onComplete(res.data);
          } else {
            onComplete("error");
          }
        },
      })
    : // Bao cao 9
      authPostData({
        url: `${Endpoint.PDF_DDTT_TRONGTHANG}`,
        setLoading: setLoading,
        method: METHOD_POST,
        payload: payload,
        onSuccess: (res) => {
          if (res.statusCode === STATUSCODE_200 && res.data) {
            onComplete(res.data);
          } else {
            onComplete("error");
          }
        },
      });
}

export function inExcel(setLoading, typeInPDF, payload) {
       // Excel 1
       typeInPDF === TYPE_INPDF_GNDN.CHITIET_DNGN
       ? downloadFilePostData({
           endpoint: Endpoint.EXCEL_CHITIET_DNGN,
           setLoading: setLoading,
           query: payload,
           errorMess: "Không có dữ liệu",
         })
       : // Excel 2
       typeInPDF === TYPE_INPDF_GNDN.TONGHOP_DNGN
       ? downloadFilePostData({
           endpoint: Endpoint.EXCEL_TONGHOP_DNGN,
           setLoading: setLoading,
           query: payload,
           errorMess: "Không có dữ liệu",
         })
       : // Excel 3
       typeInPDF === TYPE_INPDF_GNDN.CHITIET_CS_CTD_DNGN
       ? downloadFilePostData({
           endpoint: Endpoint.EXCEL_CHITIET_CSCT_DNGN,
           setLoading: setLoading,
           query: payload,
           errorMess: "Không có dữ liệu",
         })
       : // Excel 5
       typeInPDF === TYPE_INPDF_GNDN.BBQT_DN_PHANKHANG
       ? downloadFilePostData({
           endpoint: `${Endpoint.EXCEL_BB_QTDN_PHANKHANG}`,
           setLoading: setLoading,
           query: payload,
           errorMess: "Không có dữ liệu",
         })
       : // Excel 6
       typeInPDF === TYPE_INPDF_GNDN.SAISO_TRAM110
       ? downloadFilePostData({
           endpoint: `${Endpoint.EXCEL_SAISO_TRAM110}`,
           setLoading: setLoading,
           query: payload,
           errorMess: "Không có dữ liệu",
         }) // Excel 7
       : typeInPDF === TYPE_INPDF_GNDN.CHITIET_DN_THEOTRAM
       ? downloadFilePostData({
           endpoint: `${Endpoint.EXCEL_CTDN_THEOTRAM}`,
           setLoading: setLoading,
           query: payload,
           errorMess: "Không có dữ liệu",
         })
       : // Excel 8
       typeInPDF === TYPE_INPDF_GNDN.CHITIET_SANLUONG
       ? downloadFilePostData({
           setLoading: setLoading,
           endpoint: Endpoint.EXCEL_CHITIET_SANLUONG,
           query: payload,
           errorMess: "Không có dữ liệu",
         })
       : // Excel 9
       typeInPDF === TYPE_INPDF_GNDN.DIEMDO_BIENDONG_THANG
       ? downloadFilePostData({
           endpoint: `${Endpoint.EXCEL_DDTT_TRONGTHANG}`,
           setLoading: setLoading,
           query: payload,
           errorMess: "Không có dữ liệu",
         })
       : // Excel 10
       typeInPDF === TYPE_INPDF_GNDN.SANLUONG_DAUNGUON_3GIA
       ? downloadFilePostData({
           endpoint: `${Endpoint.EXCEL_SL_DAUNGUON_BAGIA}`,
           setLoading: setLoading,
           query: payload,
           errorMess: "Không có dữ liệu",
         })
       : // Excel 11
       typeInPDF === TYPE_INPDF_GNDN.CHITIET_DN_TUDUNG
       ? downloadFilePostData({
           endpoint: `${Endpoint.EXCEL_CHITIET_DN_TUDUNG}`,
           setLoading: setLoading,
           query: payload,
           errorMess: "Không có dữ liệu",
         })
       : // Excel 12
       typeInPDF === TYPE_INPDF_GNDN.BBQT_DIENNANG_GIAONHAN
       ? downloadFilePostData({
           endpoint: `${Endpoint.EXCEL_BB_QTDN_GIAONHAN}`,
           setLoading: setLoading,
           query: payload,
           errorMess: "Không có dữ liệu",
         })
       : // Excel 9
         downloadFilePostData({
           endpoint: `${Endpoint.EXCEL_DDTT_TRONGTHANG}`,
           setLoading: setLoading,
           query: payload,
           errorMess: "Không có dữ liệu",
         });
}
// call api multilist data

export const handleMultiData = (setLoading, setListTram, setDataTable) => {
  const endpoints = [
    `${Endpoint.GNDN_DANH_MUC_TRAM}`,
    `${Endpoint.GNDN_DANH_MUC_DON_VI_GIAO_NHAN}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setListTram(res[0].data.data);
      setDataTable(
        res[1].data.data.filter(
          (e) =>
            e.maDonVi !== "HT" &&
            e.maDonVi !== "HY" &&
            e.maDonVi !== "LT" &&
            e.maDonVi !== "PDBC" &&
            e.maDonVi !== "PDHD" &&
            e.maDonVi !== "PDTC" &&
            e.maDonVi !== "VP" &&
            e.laDongBo === true
        )
      );
    },
  });
};

export const getListSLDonVi = (
  setDataDonVi,
  setLoading,
  thang,
  nam,
  donViId
  // setDisableMessError
) => {
  authGetData({
    url: `${Endpoint.LIST_SANLUONG_DONVI}?donViId=${donViId}&Thang=${thang}&Nam=${nam}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataDonVi([res.data]);
      }
    },
  });
};
export const handleXacNhanSL = (donViId, thang, nam, setLoading, setVisibleXacNhan) => {
  const payload = {
    donViId: donViId,
    thang: thang,
    nam: nam,
  };
  authPostData({
    url: `${Endpoint.XACNHAN_SANLUONG_GNDN}`,
    setLoading: setLoading,
    method: METHOD_POST,
    payload: payload,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setVisibleXacNhan(false);
      }
    },
  });
};
export const handleKTDKXacNhan = (
  data,
  setLoading,
  setCheckTaoBangKe,
  setNoidungPopUp
) => {
  authPostData({
    url: `${Endpoint.KTDK_XACNHAN_SANLUONG}`,
    setLoading,
    method: METHOD_POST,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setCheckTaoBangKe(res.data.hienThiPopUp);
        setNoidungPopUp(res.data.noidungPopUp);
      }
    },
  });
};