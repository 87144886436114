import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authDeleteData, authGetData } from "@utils/request";
import { authPostFileData } from "../../common/request";
//GET_NGAN_HANG

export const getListNganHang = (setLoading, setData) => {
  authGetData({
    url: `${Endpoint.DANH_SACH_NGAN_HANG_THANH_TOAN}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

//THONG_TIN_KHUYEN_MAI_THANH_TOAN

export const getDanhSachThongTinKhuyenMai = (
  setListLoading,
  setData,
  query,
  filterConditions,
  setTotal
) => {
  authGetData({
    url: `${Endpoint.THONG_TIN_KHUYEN_MAI_THANH_TOAN}?${query}`,
    setLoading: setListLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              STT:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            };
          })
        );
        setTotal(res.paging.totalCount);
      }
    },
  });
};

//TAO_THONG_TIN_KHUYEN_MAI_THANH_TOAN

export const taoThongTinKhuyenMai = (
  payload,
  setLoading,
  setImageFile,
  setListLoading,
  setData,
  location,
  filterConditions,
  setTotal,
  dispatch,
  closeModal
) => {
  authPostFileData({
    url: `${Endpoint.TAO_THONG_TIN_KHUYEN_MAI_THANH_TOAN}`,
    payload,
    method: METHOD_POST,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setImageFile([]);
        const current_query =
          Object.keys(parseParams(location.search)).length > 0
            ? parseParams(location.search)
            : filterConditions;
        const updated_query = {
          ...current_query,
          pageSize: filterConditions.pageSize,
          pageIndex: filterConditions.pageIndex,
        };
        const query = buildQueryString(updated_query);
        getDanhSachThongTinKhuyenMai(
          setListLoading,
          setData,
          query,
          filterConditions,
          setTotal
        );
        dispatch(closeModal());
      } else if (res.statusCode === 422) {
      }
    },
  });
};

//SUA_THONG_TIN_KHUYEN_MAI_THANH_TOAN

export const suaThongTinKhuyenMai = (
  payload,
  setLoading,
  setImageFile,
  setListLoading,
  setData,
  location,
  filterConditions,
  setTotal,
  dispatch,
  closeModal,
  id
) => {
  authPostFileData({
    url: `${Endpoint.SUA_THONG_TIN_KHUYEN_MAI_THANH_TOAN}`,
    payload: { ...payload, Id: id },
    method: METHOD_POST,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setImageFile([]);
        const current_query =
          Object.keys(parseParams(location.search)).length > 0
            ? parseParams(location.search)
            : filterConditions;

        const updated_query = {
          ...current_query,
          pageSize: filterConditions.pageSize,
          pageIndex: filterConditions.pageIndex,
        };
        const query = buildQueryString(updated_query);

        getDanhSachThongTinKhuyenMai(
          setListLoading,
          setData,
          query,
          filterConditions,
          setTotal
        );
        dispatch(closeModal());
      } else if (res.statusCode === 422) {
      }
    },
  });
};

//XOA_THONG_TIN_KHUYEN_MAI_THANH_TOAN

export const xoaThongTinKhuyenMai = (
  id,
  setLoading,
  setListLoading,
  setData,
  filterConditions,
  setTotal,
  location
) => {
  authDeleteData({
    url: `${Endpoint.XOA_THONG_TIN_KHUYEN_MAI_THANH_TOAN}?Id=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        const current_query =
          Object.keys(parseParams(location.search)).length > 0
            ? parseParams(location.search)
            : filterConditions;
        const updated_query = {
          ...current_query,
          pageSize: filterConditions.pageSize,
          pageIndex: filterConditions.pageIndex,
        };
        const query = buildQueryString(updated_query);
        getDanhSachThongTinKhuyenMai(
          setListLoading,
          setData,
          query,
          filterConditions,
          setTotal
        );
      }
    },
  });
};

// CHI_TIET_KHUYEN_MAI_THANH_TOAN

export const getChiTietKhuyenMai = (id, setLoading, setDataItem) => {
  authGetData({
    url: `${Endpoint.CHI_TIET_KHUYEN_MAI_THANH_TOAN}?Id=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setDataItem(res.data);
      }
    },
  });
};

//CODE_KHUYEN_MAI_THANH_TOAN

export const getCodeKhuyenMai = (setLoading, setData) => {
  authGetData({
    url: `${Endpoint.CODE_KHUYEN_MAI_THANH_TOAN}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};
