import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";
import { alertMessage422 } from "../service";

export const selectTenCuPhap = [
  { label: "Ghi chỉ số", value: "GCS" },
  { label: "Tiền điện", value: "TD" },
  { label: "Thanh toán", value: "TT" },
  { label: "Dịch vụ", value: "DV" },
  { label: "Đổi số SMS", value: "SMS" },
  { label: "Cấp điện", value: "CD" },
  { label: "Đăng ký", value: "DK" },
  { label: "Hủy đăng ký", value: "HUY" },
];

export const selectBcthsms = [
  {
    label: "1. Biểu 1: Tổng hợp số lượng tin nhắn theo từng dịch vụ",
    value: Endpoint.BCTHSMS_THSL_TIN_NHAN_THEO_TUNG_DICH_VU,
  },
  {
    label: "2. Biểu 2: Chi tiết tin nhắn theo cú pháp",
    value: Endpoint.BCTHSMS_CT_TIN_NHAN_THEO_CU_PHAP,
  },
  {
    label: "3. Biểu 3: Danh sách tin nhắn thành công theo ngày",
    value: Endpoint.BCTHSMS_DS_TIN_NHAN_THANH_CONG_THEO_NGAY,
  },
  {
    label: "4. Biểu 4: Danh sách tin nhắn Mo thay đổi số điện thoại",
    value: Endpoint.BCTHSMS_DS_TIN_NHAN_MO_THAY_DOI_SO_DIEN_THOAI,
  },
  {
    label: "5. Biểu 5: Danh sách tin nhắn không thành công",
    value: Endpoint.BCTHSMS_DS_TIN_NHAN_KHONG_THANH_CONG,
  },
  {
    label: "6. Biểu 6: Chi tiết tin nhắn sai cú pháp",
    value: Endpoint.BCTHSMS_CT_TIN_NHAN_SAI_CU_PHAP,
  },
];

export const selectBcthsmsChuDong = [
  {
    label: "1. Biểu 1: Tổng hợp số lượng tin nhắn của toàn công ty",
    value: 1,
  },
  {
    label: "2. Biểu 2: Chi tiết số lượng tin nhắn theo từng Công ty Điện lực",
    value: 2,
  },
  {
    label:
      "3. Biểu 3: Tổng hợp số lượng tin nhắn đã thực hiện theo từng nhà mạng",
    value: 3,
  },
  {
    label:
      "4. Biểu 4: Đối soát số lượng tin nhắn đã thực hiện thành công tin nhắn theo từng nhà mạng",
    value: 4,
  },
  {
    label:
      "5. Biểu 5: Chi tiết số lượng tin nhắn thành công theo từng công ty Điện lực",
    value: 5,
  },
  {
    label: "6. Biểu 6: Danh sách tin nhắn chưa gửi cho khách hàng",
    value: 6,
  },
  {
    label: "7. Biểu 7: Đối soát ngày theo đơn vị",
    value: 7,
  },
  {
    label:
      "8. Biểu 8: Danh sách tin nhắn thành công có thời gian chậm hơn yêu cầu 19 tiếng",
    value: 8,
  },
  {
    label:
      "9. Biểu 9: Danh sách tin nhắn thành công có thời gian chậm hơn yêu cầu 24 tiếng",
    value: 9,
  },
];

export const selectBcthslagcs = [
  {
    label: "1. Báo cáo ảnh ghi chỉ số",
    value: Endpoint.BCTHSMS_BC_THSL_ANH_GHI_CHI_SO,
  },
  {
    label: "2. Báo cáo chi tiết ảnh ghi chỉ số",
    value: Endpoint.BCTHSMS_BC_CHI_TIET_ANH_GHI_CHI_SO,
  },
];

export const exportData = (data, setLoading, setData) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      TenCuPhap: data.TenCuPhap,
      MaKhachHang: data.MaKhachHang,
    })
  );
  authGetData({
    url: `${data.reportType}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              STT: index + 1,
            };
          })
        );
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      TenCuPhap: data.TenCuPhap,
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      TenCuPhap: data.TenCuPhap,
    })
  );

  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
