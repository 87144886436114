import { BASE_API_URL_BAO_CAO } from "@utils/constants";

export const APIBCKhac = {
  BCK_CHI_TIET_HDMBDSH_KY_DIEN_TU: `${BASE_API_URL_BAO_CAO}/bckd/bckhac/bcnhucaucongto/chi-tiet-hdmbdsh-ky-dien-tu`,
  BCK_SL_HOP_DONG_MUA_BAN_DIEN_SHKDT: `${BASE_API_URL_BAO_CAO}/bckd/bckhac/bcnhucaucongto/sl-hop-dong-mua-ban-dien-shkdt`,
  BCK_NHU_CAU_CONG_TO_DUOI_LUOI_TRONG_KHO: `${BASE_API_URL_BAO_CAO}/bckd/bckhac/bcnhucaucongto/nhu-cau-cong-to-duoi-luoi-trong-kho`,
  BCK_BC_DANH_SACH_YEU_CAU_SDT_NHIEU_HO_SO: `${BASE_API_URL_BAO_CAO}/bckd/bckhac/bcnhucaucongto/bao-cao-danh-sach-yeu-cau-sdt-nhieu-ho-so`,
  TK_TONG_HOP_SO_LUONG_KH_LAY_MK: `${BASE_API_URL_BAO_CAO}/bckd/webevn/bcwebevn/tkth-so-luong-kh-lay-lai-mk`,
  TK_CHI_TIET_SO_LUONG_KH_LAY_MK: `${BASE_API_URL_BAO_CAO}/bckd/webevn/bcwebevn/tk-chi-tiet-so-luong-kh-lay-lai-mk`,
  TONG_HOP_DANH_GIA_KHAO_SAT: `${BASE_API_URL_BAO_CAO}/bckd/webevn/bcwebevn/thkq-danh-gia-kscl-trang-web-evn-hanoi-cua-kh`,
  CHI_TIET_DANH_GIA_KHAO_SAT: `${BASE_API_URL_BAO_CAO}/bckd/webevn/bcwebevn/ctkq-danh-gia-kscl-trang-web-evn-hanoi-cua-kh`,
  TK_SO_LUONG_GIAO_DICH_DIEM_THU: `${BASE_API_URL_BAO_CAO}/bckd/webevn/bcwebevn/tksl-phong-giao-dich-sldt`,
  BCK_SUA_CONG_TO_TRONG_KHO_DUOI_LUOI: `${BASE_API_URL_BAO_CAO}/bckd/bckhac/bcnhucaucongto/sua-cong-to-duoi-luoi-trong-kho`,
};
