import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  parseParams,
  alertMessage,
  getErrorMessage,
} from "@utils/function";
import { authDeleteData, authGetData, authPostData } from "@utils/request";

//List data Ime
export const handleListData = (
  setLoading,
  filterConditions,
  setDataDiemDo,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.LIST_LOAI_DIEM_DO}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDiemDo(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataDiemDo([]);
    },
  });
};

const handleCreateData = (
  data,
  setLoading,
  closeModal,
  getListDiemDo,
  form
) => {
  authPostData({
    url: Endpoint.CREATE_LOAI_DIEM_DO,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListDiemDo();
        closeModal();
      } else getErrorMessage(res, form);
    },
  });
};

const handleUpdateData = (
  data,
  setLoading,
  closeModal,
  getListDiemDo,
  form
) => {
  authPostData({
    url: Endpoint.UPDATE_LOAI_DIEM_DO,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListDiemDo();
        closeModal();
      } else getErrorMessage(res, form);
    },
  });
};

//Remove data
export const handleRemoveData = (ids, setLoading, getListDiemDo, t) => {
  authDeleteData({
    url: Endpoint.REMOVE_LOAI_DIEM_DO + "?Ids=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) getListDiemDo();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

export const handleSubmitForm = (
  values,
  detaiDataTable,
  setLoading,
  closeModal,
  getListData,
  form
) => {
  const data = {
    ...values,
  };

  if (detaiDataTable.id) {
    data.id = detaiDataTable.id;
  }
  // return data;
  handleCreateUpdate(data, setLoading, closeModal, getListData, form);
};

const handleCreateUpdate = (
  data,
  setLoading,
  closeModal,
  getListData,
  form
) => {
  if (data.id) {
    //sửa dữ liệu

    handleUpdateData(data, setLoading, closeModal, getListData, form);
  } else {
    //tạo dữ liệu

    handleCreateData(data, setLoading, closeModal, getListData, form);
  }
};
