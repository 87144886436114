import LoadingComponent from "@components/Loading";
import { Image } from "antd";
import noImage from "@assets/images/no-image.png";
import imagePDF from "../../../../../assets/images/image-pdf.png";
export default function ListImageBBGiay(props) {
  const { loading, src, typeFile } = props;
  return (
    <LoadingComponent loading={loading}>
      <div
        style={{
          height: "300px",
        }}
      >
        <Image
          src={typeFile === "image" ? src : imagePDF}
          width={"100%"}
          height="100%"
          onError={(e) => {
            e.currentTarget.src = noImage;
          }}
          preview={typeFile === "image" ? true : false}
          onClick={() =>
            typeFile === "pdf" ? window.open(src, "_blank") : false
          }
        />
      </div>
    </LoadingComponent>
  );
}
