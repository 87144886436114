import { memo, useEffect, useRef, useState } from "react";
import { Form, Input, Button, Modal, DatePicker, Row, Col, Select } from "antd";
import { useTranslation } from "react-i18next";
import FormComponent from "@components/Form";
import moment from "moment";
import { formatDateMonth } from "@utils/function";
import { FORMAT_DATE } from "@utils/constants";
import LoadingComponent from "@components/Loading";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import { handleSubmitForm } from "./services";

const { Option } = Select;

function UyQuyenModal({
  detaiDataTable,
  visible,
  closeModal,
  dataNguoiDuocUyQuyen,
  unitUser,
  getListData,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const toDay = moment().format(FORMAT_DATE);
  const [userId, setUserId] = useState(
    detaiDataTable.nguoiDuocUyQuyenId !== undefined
      ? detaiDataTable.nguoiDuocUyQuyenId
      : null
  );
  const [ngayQuyetDinh, setNgayQuyetDinh] = useState(
    detaiDataTable && detaiDataTable.ngayQuyetDinh
      ? detaiDataTable.ngayQuyetDinh
      : toDay
  );
  const [ngayHetHan, setNgayHetHan] = useState(
    detaiDataTable && detaiDataTable.ngayHetHan
      ? detaiDataTable.ngayHetHan
      : null
  );
  const [ngayHieuLuc, setNgayHieuLuc] = useState(
    detaiDataTable && detaiDataTable.ngayHieuLuc
      ? detaiDataTable.ngayHieuLuc
      : toDay
  );
  const [form] = Form.useForm();
  // handle submit

  const handleSubmitData = (values) => {
    const data = {
      nguoiDuocUyQuyenId: values.nguoiDuocUyQuyenId
    };
    // const dateQuyetDinh = moment(
    //   values.ngayQuyetDinh ? values.ngayQuyetDinh : ngayQuyetDinh
    // );
    // const convertNgayQuyetDinh =
    //   dateQuyetDinh.year() +
    //   "-" +
    //   formatDateMonth(dateQuyetDinh.month() + 1) +
    //   "-" +
    //   formatDateMonth(dateQuyetDinh.date());
    // data.ngayQuyetDinh = convertNgayQuyetDinh;

    // const dateHetHan = moment(
    //   values.ngayHetHan ? values.ngayHetHan : ngayHetHan
    // );
    // const convertNgayHetHan =
    //   values.ngayHetHan === null
    //     ? null
    //     : dateHetHan.year() +
    //       "-" +
    //       formatDateMonth(dateHetHan.month() + 1) +
    //       "-" +
    //       formatDateMonth(dateHetHan.date());
    // data.ngayHetHan = convertNgayHetHan;

    // const dateHieuLuc = moment(
    //   values.ngayHieuLuc ? values.ngayHieuLuc : ngayHieuLuc
    // );
    // const convertNgayHieuLuc =
    //   dateHieuLuc.year() +
    //   "-" +
    //   formatDateMonth(dateHieuLuc.month() + 1) +
    //   "-" +
    //   formatDateMonth(dateHieuLuc.date());
    // data.ngayHieuLuc = convertNgayHieuLuc;
    handleSubmitForm(
      data,
      detaiDataTable,
      setLoading,
      closeModal,
      getListData,
      form
    );
  };
  //thay đổi ngày quyết định

  const handleChangeQuyetDinh = (date) => {
    let convert = "";
    if (date === null) convert = null;
    else convert = moment(date).format("YYYY-MM-DD");
    setNgayQuyetDinh(convert);
  };
  //thay đổi ngày hết hạn

  const handleChangeHetHan = (date) => {
    let convert = "";
    if (date === null) convert = null;
    else convert = moment(date).format("YYYY-MM-DD");
    setNgayHetHan(convert);
  };

  //thay đổi ngày hiệu lực

  const handleChangeHieuLuc = (date) => {
    let convert = "";
    if (date === null) convert = null;
    else convert = moment(date).format("YYYY-MM-DD");
    setNgayHieuLuc(convert);
  };
  //thay đổi người dùng

  const handleChangeUser = (value) => {
    setUserId(value);
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();
    // eslint-disable-next-line
  }, []);

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={
          detaiDataTable.id
            ? t("uy_quyen.cap_nhat_uy_quyen")
            : t("uy_quyen.tao_uy_quyen")
        }
        visible={visible}
        onCancel={closeModal}
        className="modal-uy-quyen"
        footer={null}
      >
        <FormComponent
          autoComplete="off"
          form={form}
          name="form-uy-quyen"
          layout="vertical"
          onFinish={handleSubmitData}
          initialValues={{
            ...detaiDataTable,
            ngayQuyetDinh: moment(ngayQuyetDinh, FORMAT_DATE),
            ngayHetHan: ngayHetHan ? moment(ngayHetHan, FORMAT_DATE) : null,
            ngayHieuLuc: moment(ngayHieuLuc, FORMAT_DATE),
          }}
          scrollToFirstError
        >
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item>
                <span>{t("uy_quyen.title.ma_don_vi_quan_ly")}</span>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item>
                <span className="font-bold">{unitUser}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label={t("uy_quyen.title.ten_nguoi_uy_quyen")}
                name="tenNguoiUyQuyen"
              >
                <Input ref={inputRef} disabled placeholder="Nhập tên người ủy quyền" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t("uy_quyen.title.username_nguoi_duoc_uy_quyen")}
                name="nguoiDuocUyQuyenId"
                className="required-field"
              >
                <Select
                  onChange={handleChangeUser}
                  defaultValue={userId}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={null}>--Chọn người được ủy quyền--</Option>
                  {dataNguoiDuocUyQuyen && dataNguoiDuocUyQuyen.length > 0
                    ? dataNguoiDuocUyQuyen.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.tenHienThi}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={t("uy_quyen.title.so_quyet_dinh")}
            name="soQuyetDinh"
          >
            <Input disabled placeholder="Nhập số quyết định" />
          </Form.Item>
          <Row gutter={24}>
            <Col span={24} md={8}>
              <Form.Item
                label="Ngày ủy quyền"
                name="ngayQuyetDinh"
              >
                <DatePicker
                disabled
                  locale={locale}
                  format={FORMAT_DATE}
                  onChange={handleChangeQuyetDinh}
                  placeholder={FORMAT_DATE}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item
                label="Ngày hiệu lực"
                name="ngayHieuLuc"
              >
                <DatePicker
                disabled
                  locale={locale}
                  format={FORMAT_DATE}
                  onChange={handleChangeHieuLuc}
                  placeholder={FORMAT_DATE}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item
                label="Ngày hết hạn"
                name="ngayHetHan"
                // className="required-field"
              >
                <DatePicker
                disabled
                  locale={locale}
                  format={FORMAT_DATE}
                  onChange={handleChangeHetHan}
                  placeholder={FORMAT_DATE}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-uy-quyen"
                loading={loading}
              >
                {detaiDataTable.id ? t("button.cap_nhat") : t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(UyQuyenModal);
