// import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import React from "react";
// import { useSelector } from "react-redux";
import Screen1 from "./screen/screen1";

export default function BKPhucTra() {
  // const { user } = useSelector(authSelector);

  const columns = [
    {
      title: "STT",
      dataIndex: "key",
      width: "70px",
      align: "center",
    },
    {
      title: "Họ và tên khách hàng",
      dataIndex: "tenKhachHang",
      width: "150px",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      width: "150px",
    },
    {
      title: "Mã GC",
      dataIndex: "maGCS",
    },
    {
      title: "Số công tơ /Chủng loại công tơ (1pha 3pha)",
      dataIndex: "soCongTo",
      width: "200px",
      render: (text) => {
        return {
          children: <span>{text}</span>,
          props: {
            align: "end",
          },
        };
      },
    },
    {
      title: "Đã ghi chỉ số",
      dataIndex: "daghi",
      children: [
        {
          title: "Ngày ghi",
          dataIndex: "ngayGhi",
          render: (text) => {
            return {
              children: <span>{text}</span>,
              props: {
                align: "end",
              },
            };
          },
        },
        {
          title: "Chỉ số",
          dataIndex: "chiSoMoi",
          render: (text) => {
            return {
              children: <span>{text}</span>,
              props: {
                align: "end",
              },
            };
          },
        },
      ],
    },
    {
      title: "Thực hiện phúc tra",
      dataIndex: "thuchien",
      children: [
        {
          title: "Ngày phúc tra",
          dataIndex: "ngayPhucTra",
          render: (text) => {
            return {
              children: <span>{text}</span>,
              props: {
                align: "end",
              },
            };
          },
        },
        {
          title: "Chỉ số phúc tra",
          dataIndex: "chiSoPhucTra",
          render: (text) => {
            return {
              children: <span>{text}</span>,
              props: {
                align: "end",
              },
            };
          },
        },
        {
          title: "Tính trạng bên ngoài hòm (hòm, cáp, mã chì, hòm đánh đai)",
          dataIndex: "tinhTrangBenNgoaiHom",
        },
        {
          title: "Tình trạng hoạt động (bình thường, kẹt chết...)",
          dataIndex: "tinhTrangHoatDong",
          align: "center",
        },
      ],
    },
    {
      title: "Kết luận (Kiến nghị)",
      dataIndex: "ketLuan",
    },
  ];
  const endPoint = {
    LIST: Endpoint.PHUC_TRA_PAGING,
    EXCEL: Endpoint.XUAT_EXCEL_PHUC_TRA_CHI_SO,
    PDF: Endpoint.XUAT_PDF_PHUC_TRA_CHI_SO,
    ...Endpoint,
  };
  return (
    <Screen1
      header={"Bảng kê phúc tra chỉ số"}
      columns={columns}
      endPoint={endPoint}
      // uId={user.unitId}
      scrollX={1400}
    />
  );
}
