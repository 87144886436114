import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_YEAR,
} from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { Table } from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { getLichGCSTheoNam } from "./services";
import BaoCaoGCSTheoNam from "./table";
export default function THGSLichGCS() {
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const { user } = useSelector(authSelector);
  const [dataTheoNam, setDataTheoNam] = useState();
  const [totalCount, setTotalCount] = useState();
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    Nam: moment().format(FORMAT_YEAR),
    DonViId: user.unitId,
    ...parseParams(location.search),
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      ...values,
    }));
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      Nam: moment().format(FORMAT_YEAR),
      DonViId: user.unitId,
    });
  }, [user.unitId]);

  const getLichGCS = useCallback(() => {
    getLichGCSTheoNam(
      filterConditions,
      setLoading,
      setDataTheoNam,
      setTotalCount
    );
  }, [filterConditions]);

  useEffect(() => {
    getLichGCS();
  }, [getLichGCS]);

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const columns = [
    {
      title: "STT",
      key: "index",
      render: (a, b, index) => {
        return (
          (filterConditions.pageIndex - 1) * filterConditions.pageSize +
          (index + 1)
        );
      },
      width: 70,
      style: "backgroundColor:red",
      align: "center",
      fixed: "left",
    },
    {
      title: "Đơn vị",
      dataIndex: "maDonVi",
      key: "maDonVi",
      fixed: "left",
      width: 150,
    },
    {
      title: "Tháng 1",
      dataIndex: "thang",
      key: "thang",

      children: [
        {
          title: "Tổng (SL sổ GCS)",
          dataIndex: "tongSoGCSthang1",
          key: "tongSoGCSthang1",
          width: 150,
          align: "center",
        },
        {
          title: "Tổng (SL sổ GCS lệch ngày ghi)",
          dataIndex: "tongSoLechNgaythang1",
          key: "tongSoLechNgaythang1",
          align: "center",
          width: 150,
        },
      ],
    },
    {
      title: "Tháng 2",
      dataIndex: "thang",
      key: "thang",
      children: [
        {
          title: "Tổng (SL sổ GCS)",
          dataIndex: "tongSoGCSthang2",
          key: "tongSoGCSthang2",
          align: "center",
          width: 150,
        },
        {
          title: "Tổng (SL sổ GCS lệch ngày ghi)",
          dataIndex: "tongSoLechNgaythang2",
          key: "tongSoLechNgaythang2",
          align: "center",
          width: 150,
        },
      ],
    },
    // t3
    {
      title: "Tháng 3",
      dataIndex: "thang",
      key: "thang",
      children: [
        {
          title: "Tổng (SL sổ GCS)",
          dataIndex: "tongSoGCSthang3",
          key: "tongSoGCSthang3",
          align: "center",
          width: 150,
        },
        {
          title: "Tổng (SL sổ GCS lệch ngày ghi)",
          dataIndex: "tongSoLechNgaythang3",
          key: "tongSoLechNgaythang3",
          align: "center",
          width: 150,
        },
      ],
    },
    // t4
    {
      title: "Tháng 4",
      dataIndex: "thang",
      key: "thang",
      children: [
        {
          title: "Tổng (SL sổ GCS)",
          dataIndex: "tongSoGCSthang4",
          key: "tongSoGCSthang4",
          align: "center",
          width: 150,
        },
        {
          title: "Tổng (SL sổ GCS lệch ngày ghi)",
          dataIndex: "tongSoLechNgaythang4",
          key: "tongSoLechNgaythang4",
          align: "center",
          width: 150,
        },
      ],
    },
    // t5
    {
      title: "Tháng 5",
      dataIndex: "thang",
      key: "thang",
      children: [
        {
          title: "Tổng (SL sổ GCS)",
          dataIndex: "tongSoGCSthang5",
          key: "tongSoGCSthang5",
          align: "center",
          width: 150,
        },
        {
          title: "Tổng (SL sổ GCS lệch ngày ghi)",
          dataIndex: "tongSoLechNgaythang5",
          key: "tongSoLechNgaythang5",
          align: "center",
          width: 150,
        },
      ],
    },
    {
      title: "Tháng 6",
      dataIndex: "thang",
      key: "thang",
      children: [
        {
          title: "Tổng (SL sổ GCS)",
          dataIndex: "tongSoGCSthang6",
          key: "tongSoGCSthang6",
          align: "center",
          width: 150,
        },
        {
          title: "Tổng (SL sổ GCS lệch ngày ghi)",
          dataIndex: "tongSoLechNgaythang6",
          key: "tongSoLechNgaythang6",
          align: "center",
          width: 150,
        },
      ],
    },
    {
      title: "Tháng 7",
      dataIndex: "thang",
      key: "thang",
      children: [
        {
          title: "Tổng (SL sổ GCS)",
          dataIndex: "tongSoGCSthang7",
          key: "tongSoGCSthang7",
          align: "center",
          width: 150,
        },
        {
          title: "Tổng (SL sổ GCS lệch ngày ghi)",
          dataIndex: "tongSoLechNgaythang7",
          key: "tongSoLechNgaythang7",
          align: "center",
          width: 150,
        },
      ],
    },
    {
      title: "Tháng 8",
      dataIndex: "thang",
      key: "thang",
      children: [
        {
          title: "Tổng (SL sổ GCS)",
          dataIndex: "tongSoGCSthang8",
          key: "tongSoGCSthang8",
          align: "center",
          width: 150,
        },
        {
          title: "Tổng (SL sổ GCS lệch ngày ghi)",
          dataIndex: "tongSoLechNgaythang8",
          key: "tongSoLechNgaythang8",
          align: "center",
          width: 150,
        },
      ],
    },
    {
      title: "Tháng 9",
      dataIndex: "thang",
      key: "thang",
      children: [
        {
          title: "Tổng (SL sổ GCS)",
          dataIndex: "tongSoGCSthang9",
          key: "tongSoGCSthang9",
          align: "center",
          width: 150,
        },
        {
          title: "Tổng (SL sổ GCS lệch ngày ghi)",
          dataIndex: "tongSoLechNgaythang9",
          key: "tongSoLechNgaythang9",
          align: "center",
          width: 150,
        },
      ],
    },
    {
      title: "Tháng 10",
      dataIndex: "thang",
      key: "thang",
      children: [
        {
          title: "Tổng (SL sổ GCS)",
          dataIndex: "tongSoGCSthang10",
          key: "tongSoGCSthang10",
          align: "center",
          width: 150,
        },
        {
          title: "Tổng (SL sổ GCS lệch ngày ghi)",
          dataIndex: "tongSoLechNgaythang10",
          key: "tongSoLechNgaythang10",
          align: "center",
          width: 150,
        },
      ],
    },
    {
      title: "Tháng 11",
      dataIndex: "thang",
      key: "thang",
      children: [
        {
          title: "Tổng (SL sổ GCS)",
          dataIndex: "tongSoGCSthang11",
          key: "tongSoGCSthang11",
          align: "center",
          width: 150,
        },
        {
          title: "Tổng (SL sổ GCS lệch ngày ghi)",
          dataIndex: "tongSoLechNgaythang11",
          key: "tongSoLechNgaythang11",
          align: "center",
          width: 150,
        },
      ],
    },
    {
      title: "Tháng 12",
      dataIndex: "thang",
      key: "thang",
      children: [
        {
          title: "Tổng (SL sổ GCS)",
          dataIndex: "tongSoGCSthang12",
          key: "tongSoGCSthang12",
          align: "center",
          width: 150,
        },
        {
          title: "Tổng (SL sổ GCS lệch ngày ghi)",
          dataIndex: "tongSoLechNgaythang12",
          key: "tongSoLechNgaythang12",
          align: "center",
          width: 150,
        },
      ],
    },
  ];
  const countTotals = (pageData) => {
    let countTongSoGCST1 = 0;
    let countTongLechSoGCST1 = 0;
    let countTongSoGCST2 = 0;
    let countTongLechSoGCST2 = 0;
    let countTongSoGCST3 = 0;
    let countTongLechSoGCST3 = 0;
    let countTongSoGCST4 = 0;
    let countTongLechSoGCST4 = 0;
    let countTongSoGCST5 = 0;
    let countTongLechSoGCST5 = 0;
    let countTongSoGCST6 = 0;
    let countTongLechSoGCST6 = 0;
    let countTongSoGCST7 = 0;
    let countTongLechSoGCST7 = 0;
    let countTongSoGCST8 = 0;
    let countTongLechSoGCST8 = 0;
    let countTongSoGCST9 = 0;
    let countTongLechSoGCST9 = 0;
    let countTongSoGCST10 = 0;
    let countTongLechSoGCST10 = 0;
    let countTongSoGCST11 = 0;
    let countTongLechSoGCST11 = 0;
    let countTongSoGCST12 = 0;
    let countTongLechSoGCST12 = 0;
    pageData.forEach(
      ({
        tongSoGCSthang1,
        tongSoLechNgaythang1,
        tongSoGCSthang2,
        tongSoLechNgaythang2,
        tongSoGCSthang3,
        tongSoLechNgaythang3,
        tongSoGCSthang4,
        tongSoLechNgaythang4,
        tongSoGCSthang5,
        tongSoLechNgaythang5,
        tongSoGCSthang6,
        tongSoLechNgaythang6,
        tongSoGCSthang7,
        tongSoLechNgaythang7,
        tongSoGCSthang8,
        tongSoLechNgaythang8,
        tongSoGCSthang9,
        tongSoLechNgaythang9,
        tongSoGCSthang10,
        tongSoLechNgaythang10,
        tongSoGCSthang11,
        tongSoLechNgaythang11,
        tongSoGCSthang12,
        tongSoLechNgaythang12,
      }) => {
        countTongSoGCST1 += parseInt(tongSoGCSthang1);
        countTongLechSoGCST1 += parseInt(tongSoLechNgaythang1);
        countTongSoGCST2 += parseInt(tongSoGCSthang2);
        countTongLechSoGCST2 += parseInt(tongSoLechNgaythang2);
        countTongSoGCST3 += parseInt(tongSoGCSthang3);
        countTongLechSoGCST3 += parseInt(tongSoLechNgaythang3);
        countTongSoGCST4 += parseInt(tongSoGCSthang4);
        countTongLechSoGCST4 += parseInt(tongSoLechNgaythang4);
        countTongSoGCST5 += parseInt(tongSoGCSthang5);
        countTongLechSoGCST5 += parseInt(tongSoLechNgaythang5);
        countTongSoGCST6 += parseInt(tongSoGCSthang6);
        countTongLechSoGCST6 += parseInt(tongSoLechNgaythang6);
        countTongSoGCST7 += parseInt(tongSoGCSthang7);
        countTongLechSoGCST7 += parseInt(tongSoLechNgaythang7);
        countTongSoGCST8 += parseInt(tongSoGCSthang8);
        countTongLechSoGCST8 += parseInt(tongSoLechNgaythang8);
        countTongSoGCST9 += parseInt(tongSoGCSthang9);
        countTongLechSoGCST9 += parseInt(tongSoLechNgaythang9);
        countTongSoGCST10 += parseInt(tongSoGCSthang10);
        countTongLechSoGCST10 += parseInt(tongSoLechNgaythang10);
        countTongSoGCST11 += parseInt(tongSoGCSthang11);
        countTongLechSoGCST11 += parseInt(tongSoLechNgaythang11);
        countTongSoGCST12 += parseInt(tongSoGCSthang12);
        countTongLechSoGCST12 += parseInt(tongSoLechNgaythang12);
      }
    );
    return (
      <Table.Summary.Row style={{ backgroundColor: "#ccccb3" }} colSpan={2}>
        <Table.Summary.Cell
          className="summary"
          align="center"
          index={0}
          colSpan={2}
        >
          <Text className="font-bold">Tổng cộng</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongSoGCST1}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongLechSoGCST1}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongSoGCST2}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongLechSoGCST2}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongSoGCST3}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongLechSoGCST3}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongSoGCST4}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongLechSoGCST4}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongSoGCST5}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongLechSoGCST5}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongSoGCST6}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongLechSoGCST6}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongSoGCST7}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongLechSoGCST7}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongSoGCST8}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongLechSoGCST8}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongSoGCST9}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongLechSoGCST9}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongSoGCST10}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongLechSoGCST10}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongSoGCST11}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongLechSoGCST11}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongSoGCST12}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text> {countTongLechSoGCST12}</Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };
  return (
    <BaoCaoGCSTheoNam
      columns={columns}
      handleSearch={handleSearch}
      filterConditions={filterConditions}
      loading={loading}
      setLoading={setLoading}
      onChangePagination={onChangePagination}
      data={dataTheoNam}
      totalCount={totalCount}
      countTotals={countTotals}
      scrollX={1400}
      clearFilter={clearFilter}
    />
  );
}
