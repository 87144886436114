import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { customColumn } from "@utils/function";
import { Fragment } from "react";
import ListFilter from "./list-bo-loc";

export default function LogThaoTacTable(props) {
  const {
    columns,
    handleSearch,
    clearFilter,
    filterConditions,
    loading,
    onChangePagination,
    dataSource,
    totalCount,
  } = props;
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
        />
      </Filter>
      <TableComponent
        header={"Log thao tác"}
        columns={customColumn(columns, filterConditions)}
        dataSource={dataSource}
        filterConditions={filterConditions}
        totalData={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        pagination={filterConditions}
      />
    </Fragment>
  );
}
