import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData } from "@utils/request";

export const getLichGCSTheoNgay = (
  filterConditions,
  setLoading,
  setdataTheoNgay,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.LICH_GCS_CHI_TIET}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setdataTheoNgay(res.data);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};
