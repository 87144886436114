import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { authSelector } from "@state/auth";
import { LIST_TIME_OF_DAY } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { handleCreate, SelectionDanhSachDoi, SelectionDanhSachNhanVien } from "./service";

const { TextArea } = Input;

export function ModalTaoMoi(props) {
  const { visible, closeModal, getListData, maDonVi } = props;
  const [form] = Form.useForm();
  const [fieldNumber, setFieldNumber] = useState({soluong_bienban: ""})
  const [loading, setLoading] = useState(false);
  const user = useSelector(authSelector);

  const onFinish = (values) => {
    const payload = {
      ...values,
      pma_dviqly: maDonVi,
      nguoi_nhap: user.user.id,
      pngay_giaonhan: moment(values.pngay_giaonhan).format("DD/MM/YYYY"),
      ...fieldNumber,
    };
    const url = Endpoint.BCCTKD_THEM_SU_DUNG_KIM_CHI_NIEM_PHONG;
    handleCreate(payload, setLoading, getListData, closeModal, url, form);
  };

  const onChangeTypeNumber = ({ filedName, value }) => {
    setFieldNumber({...fieldNumber, [filedName]: value});
    form.setFieldsValue({ [filedName]: value });
  };
  const onBlurTypeNumber = ({ filedName, value }) => {
    const valueUpdate = +value <= 0 ? 1 : Math.trunc(+value);
    setFieldNumber({...fieldNumber, [filedName]: valueUpdate});
    form.setFieldsValue({ [filedName]: valueUpdate });
  };

  return (
    <LoadingComponent loading={loading}>
      <Modal title="Tạo mới" visible={visible} onCancel={closeModal} width={1120} footer={null}>
        <FormComponent
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
          initialValues={{ mucdichkepchi: "1" }}
          colon={false}
          onFinish={onFinish}
        >
          <Style>
            <Row>
              <Col span={12}>
                <Form.Item name="pma_to" label="Đội " labelAlign="left" required>
                  <SelectionDanhSachDoi formKey="pma_to" form={form} url={`${Endpoint.BCCTKD_DS_DOI}?MaDonVi=${maDonVi}`} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="pMA_LOAI" label="Mục đích kẹp chì" labelAlign="left" required>
                  <Select formKey="pMA_LOAI" placeholder="Chọn mục đích kẹp chì">
                    <Option value="1">Cấp mới</Option>
                    <Option value="2">Định kỳ</Option>
                    <Option value="3">Cháy hỏng</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item name="pma_nv_sd" label="Nhân viên sử dụng " labelAlign="left" required>
                  <SelectionDanhSachNhanVien
                    formKey="pma_nv_sd"
                    form={form}
                    url={`${Endpoint.BCKD_DANH_SACH_NHAN_VIEN}?MaDonVi=${maDonVi}`}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="pngay_giaonhan" label="Ngày giao nhận" labelAlign="left" required>
                  <DatePicker format={"DD/MM/YYYY"} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item name="pma_nv_gnhan" label="Nhân viên giao nhận" labelAlign="left" required>
                  <SelectionDanhSachNhanVien
                    formKey="pma_nv_gnhan"
                    form={form}
                    url={`${Endpoint.BCKD_DANH_SACH_NHAN_VIEN}?MaDonVi=${maDonVi}`}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="gio_giao" label="Giờ giao" labelAlign="left" required>
                  <Select options={LIST_TIME_OF_DAY} placeholder="Giờ giao" showSearch />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item name="pma_kim" label="Mã kìm" labelAlign="left" required>
                  <Input placeholder="Nhập mã kìm" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="gio_tra" label="Giờ trả" labelAlign="left" required>
                  <Select options={LIST_TIME_OF_DAY} placeholder="Giờ trả" showSearch />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Form.Item name="capmoi_vien" label="Cấp mới (Viên)" labelAlign="left" required>
                  <Input placeholder="Nhập cấp mới (Viên)" />
                </Form.Item>{" "}
              </Col>
              <Col span={12}>
                <Form.Item name="capmoi_day" label="Cấp mới (Dây)" labelAlign="left" required>
                  <Input placeholder="Nhập cấp mới dây" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item name="hong_vien" label="Kẹp hỏng (Viên)" labelAlign="left" required>
                  <Input placeholder="Nhập kẹp hỏng (Viên)" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="hong_day" label="Kẹp hỏng (Dây)" labelAlign="left" required>
                  <Input placeholder="Nhập cấp mới (Dây)" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item name="thuhoi_vien" label="Thu hồi (Viên)" labelAlign="left" required>
                  <Input placeholder="Nhập thu hồi viên (Viên)" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="thuhoi_day" label="Thu hồi (Dây)" labelAlign="left" required>
                  <Input placeholder="Nhập thu hồi (Dây)" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item name="noi_dung" label="Nội dung" labelAlign="left" required>
                  <TextArea placeholder="Nội dung" maxLength={500} className="text-area" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="giai_trinh" label="Giải trình" labelAlign="left" required>
                  <TextArea placeholder="Giải trình" maxLength={500} className="text-area" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="Số lượng biên bản" labelAlign="left" required>
                  <Input
                    placeholder="Số lượng biên bản"
                    type="number"
                    onChange={(e) => onChangeTypeNumber({ filedName: "soluong_bienban", value: e.target.value })}
                    onBlur={(e) => onBlurTypeNumber({ filedName: "soluong_bienban", value: e.target.value })}
                    value={fieldNumber.soluong_bienban}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={20}></Col>
              <Col span={4}>
                <Button onClick={() => closeModal()} className="mr-5px">
                  Hủy
                </Button>
                <Button type="primary" htmlType="submit">
                  Lưu
                </Button>
              </Col>
            </Row>
          </Style>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(ModalTaoMoi);

const Style = styled.div`
  .text-area {
    height: 150px;
  }
`;
