import { API_QUAN_LY_DV_THANH_TOAN } from "../../api-url/thanh-toan/quan-ly-dich-vu-than-toan/index";
import { API_QUAN_LY_KHUYEN_MAI_THANH_TOAN } from "../../api-url/thanh-toan/quan-ly-khuyen-mai/index";
import { API_COMMON_THANH_TOAN } from "../../api-url/thanh-toan/common/index";
import { API_NO_DONG_CAT_DIEN_THANH_TOAN } from "../../api-url/thanh-toan/no-dong-cat-dien/index";
import { API_HO_TRO_KHACH_HANG } from "../../api-url/thanh-toan/thong-tin-ho-tro-khach-hang";
export const THANH_TOAN_Endpoint = {
  // COMMON
  DANH_SACH_NGAN_HANG_THANH_TOAN:
    API_COMMON_THANH_TOAN.DANH_SACH_NGAN_HANG_THANH_TOAN,
  DANH_SACH_NHAN_VIEN_THU_TIEN:
    API_COMMON_THANH_TOAN.DANH_SACH_NHAN_VIEN_THU_TIEN,
  // QUAN LY DICH VU THANH TOAN
  DANH_SACH_DICH_VU_THANH_TOAN:
    API_QUAN_LY_DV_THANH_TOAN.DANH_SACH_DICH_VU_THANH_TOAN,
  // DON VI DANG KI THANH TOAN
  DON_VI_DANG_KY_THANH_TOAN: API_QUAN_LY_DV_THANH_TOAN.DASHBOARD_DON_VI_DANG_KY,

  // DANH SACH NGAN HANG
  DANH_SACH_NGAN_HANG: API_QUAN_LY_DV_THANH_TOAN.DASHBOARD_DICH_VU_THANH_TOAN,
  // QUAN LY KHUYEN MAI THANH TOAN
  THONG_TIN_KHUYEN_MAI_THANH_TOAN:
    API_QUAN_LY_KHUYEN_MAI_THANH_TOAN.THONG_TIN_KHUYEN_MAI_THANH_TOAN,
  SUA_THONG_TIN_KHUYEN_MAI_THANH_TOAN:
    API_QUAN_LY_KHUYEN_MAI_THANH_TOAN.SUA_THONG_TIN_KHUYEN_MAI_THANH_TOAN,
  TAO_THONG_TIN_KHUYEN_MAI_THANH_TOAN:
    API_QUAN_LY_KHUYEN_MAI_THANH_TOAN.TAO_THONG_TIN_KHUYEN_MAI_THANH_TOAN,
  XOA_THONG_TIN_KHUYEN_MAI_THANH_TOAN:
    API_QUAN_LY_KHUYEN_MAI_THANH_TOAN.XOA_THONG_TIN_KHUYEN_MAI_THANH_TOAN,
  CHI_TIET_KHUYEN_MAI_THANH_TOAN:
    API_QUAN_LY_KHUYEN_MAI_THANH_TOAN.CHI_TIET_KHUYEN_MAI_THANH_TOAN,
  CODE_KHUYEN_MAI_THANH_TOAN:
    API_QUAN_LY_KHUYEN_MAI_THANH_TOAN.CODE_KHUYEN_MAI_THANH_TOAN,

  // NO DONG CAT DIEN THANH TOAN
  DANH_SACH_NO_DONG_CAT_DIEN:
    API_NO_DONG_CAT_DIEN_THANH_TOAN.DANH_SACH_NO_DONG_CAT_DIEN,
  DONG_BO_NO_DONG_CAT_DIEN:
    API_NO_DONG_CAT_DIEN_THANH_TOAN.DONG_BO_NO_DONG_CAT_DIEN,

  // THONG TIN HO TRO KHACH HANG
  DANH_SACH_LOAI_TAI_LIEU: API_COMMON_THANH_TOAN.DANH_SACH_LOAI_TAI_LIEU,
  TAI_LIEU_HO_TRO_KHACH_HANG: API_HO_TRO_KHACH_HANG.TAI_LIEU
};
