import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";

export const selectBaoCaoDoiSoatEmail = [
  {
    label:
      "B09 - Tổng hợp tình hình gửi thông báo cho khách hàng hình thức Email",
    value: Endpoint.TH_GUI_TINH_HINH_THONG_BAO_EMAIL,
  },
  {
    label:
      "Báo cáo tổng hợp số lượng thư điện tử hợp lệ đã gửi theo từng đơn vị",
    value: Endpoint.BC_TH_SL_THU_DIEN_TU_HOP_LE,
  },
  {
    label:
      "Báo cáo chi tiết số lượng thư điện tử hợp lệ đã gửi theo từng đơn vị",
    value: Endpoint.BC_CHI_TIET_SL_THU_DIEN_TU_HOP_LE,
  },
  {
    label:
      "Báo cáo tổng hợp số lượng thư điện tử không hợp lệ đã gửi theo từng đơn vị",
    value: Endpoint.BC_TH_SL_THU_DIEN_TU_KHONG_HOP_LE,
  },
  {
    label:
      "Báo cáo chi tiết số lượng thư điện tử không hợp lệ đã gửi theo từng đơn vị",
    value: Endpoint.BC_CHI_TIET_SL_THU_DIEN_TU_KHONG_HOP_LE,
  },
  {
    label: "Danh sách khách hàng đăng ký sử dụng Email",
    value: Endpoint.DANH_SACH_KH_DANG_KY_EMAIL,
  },
];
export const handleDownload = (payload, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      ...(payload.MaDonVi && { MaDonVi: payload.MaDonVi }),
      ...(payload.DonViId && { DonViId: payload.DonViId }),
      ...(payload.DoiTuongId && { DoiTuongId: payload.DoiTuongId }),
      ...(payload.TuNgay && { TuNgay: payload.TuNgay }),
      ...(payload.DenNgay && { DenNgay: payload.DenNgay }),
    })
  );
  // authGetData({
  //   url: `${Endpoint.TH_GUI_TINH_HINH_THONG_BAO_EMAIL_EXCEL}?${queryString}`,
  //   setLoading,
  //   onSuccess: (res) => {
  //     if (res && res.data) {
  //     }
  //   },
  // });
  const endpoint = `${payload.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const getListDataB1 = (filterConditions, setLoading, setDataB1) => {
  authGetData({
    url: `${Endpoint.B1_TH_TIN_NHAN_TOAN_CONG_TY}?${buildQueryString(
      parseParams(filterConditions)
    )}`,

    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataB1(
          res.data.map((item, index) => {
            return {
              ...item,
              STT: index + 1,
            };
          })
        );
      }
    },
  });
};
