import FormComponent from "@components/Form";
import InputComponent from "@components/Input";
import Selection from "@components/Select";
import { closeModal } from "@state/system-config/reducer";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, Form, Row, Spin } from "antd";
import React, { useCallback, useState } from "react";
import { savePage } from "./services";

const initialValues = {
  moduleId: undefined,
  pageName: undefined,
  url: undefined,
  trangThai: 1,
};
export default function CreatePageForm(props) {
  const { getPageList, dispatch } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const saveAndAddMore = useCallback(() => {
    form.validateFields().then((values) => {
      savePage(values, setLoading, getPageList, form);
    });
  }, [getPageList, form]);

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const onSave = useCallback(
    (values) => {
      savePage(values, setLoading, getPageList, form, handleCloseModal);
    },
    [getPageList, handleCloseModal, form]
  );

  return (
    <Spin spinning={loading}>
      <FormComponent
        onFinish={onSave}
        form={form}
        colon={false}
        initialValues={initialValues}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="moduleId"
              label="Module"
              labelAlign="left"
              required
            >
              <Selection
                url={`${Endpoint.PAGE}/modules`}
                form={form}
                formKey="moduleId"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              name="pageName"
              label="Tên trang"
              required
              labelAlign="left"
            >
              <InputComponent
                type="text"
                size="default"
                form={form}
                formKey="pageName"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item name="url" label="Đường dẫn" required labelAlign="left">
              <InputComponent
                type="text"
                size="default"
                form={form}
                formKey="url"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} justify="center">
          <Col span={4}>
            <Form.Item>
              <Button className="full" onClick={() => dispatch(closeModal())}>
                Đóng lại
              </Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="full">
                Lưu
              </Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Button
                type="primary"
                className="full"
                onClick={() => saveAndAddMore(form.getFieldsValue())}
              >
                Lưu và tiếp tục
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </FormComponent>
    </Spin>
  );
}
