// import { DeleteOutlined } from "@ant-design/icons";
import { TYPE_TABLE_BANGKE } from "@utils/constants";
import { Button, Form } from "antd";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";

import TableDSCongTo from "./table-ds-cong-to";
import { handleListCTTao, handleMultiDS, handleTaoBK } from "./services";
import { closeModal } from "@state/system-config/reducer";
import CheckPermission from "@components/CheckPermission";
import { kt_tb_do_dem } from "@permissions/n_kttbdodem";
import ConfirmModal from "./modal-confirm";

export default function Index() {
  const [dataCongTo, setDataCongTo] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [columnsBK, setColumnsBK] = useState();
  const { user } = useSelector(authSelector);
  const [loaiBangKe, setLoaiBangKe] = useState([]);
  const [nguoiThucHien, setNguoiThucHien] = useState([]);
  const [visibleKiemTraLai, setVisibleKiemTraLai] = useState(false);
  const [id, setId] = useState("");
  const [selectedRowKey, setSelectedRowKeys] = useState([]);

  //Hiển thị checkbox ở đầu bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKey,
    onChange: onSelectChange,
  };

  // column table

  const columns1Pha = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
      sorter: true,
    },
    {
      title: "Mã số GCS",
      dataIndex: "maSoGhiChiSo",
      key: "maSoGhiChiSo",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
      sorter: true,
    },
    {
      title: "Tên khách hàng (mã khách hàng)",
      dataIndex: "tenVaMaKhachHang",
      key: "tenVaMaKhachHang",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Địa chỉ sử dụng điện(Vị trí công tơ)",
      dataIndex: "diaChiDungDien",
      key: "diaChiDungDien",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "Thông số công tơ (chủng loại, số công tơ,U,I,K,CCX...)",
      dataIndex: "soCongTo",
      key: "soCongTo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Chỉ số công tơ",
      dataIndex: "chiSoCongTo",
      key: "chiSoCongTo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title:
        "Tình trạng niêm phong(mã chì kiểm định, chì hộp đấu dây,chì hòm, tem, đai, khóa)",
      dataIndex: "tinhTrang",
      key: "tinhTrang",
      render: (text) => (
        <div className="table-text-left">
          {text &&
            text.split("!@").map((item) => {
              return (
                <Fragment>
                  <span>{item}</span>
                  <br />
                </Fragment>
              );
            })}
        </div>
      ),
      width: "15%",
    },
    {
      title: "Tình trạng hoạt động công tơ, sơ đồ đấy dây(có phụ tải)",
      dataIndex: "tinhTrangHoatDong",
      key: "tinhTrangHoatDong",
      render: (text) => (
        <div className="table-text-left">
          {text &&
            text.split("!@").map((item) => {
              return (
                <Fragment>
                  <span>{item}</span>
                  <br />
                </Fragment>
              );
            })}
        </div>
      ),
      width: "15%",
    },
    {
      title:
        "Sau khi kiểm tra đã kẹp lại chì niêm phong công tơ, kết luận, kiến nghị sau kiểm tra công tơ",
      dataIndex: "ketLuanKienNghi",
      key: "ketLuanKienNghi",
      render: (text) => (
        <div className="table-text-left">
          {text &&
            text.split("!@").map((item) => {
              return (
                <Fragment>
                  <span>{item.includes("Kiến nghị") ? "" : item}</span>
                  <br />
                </Fragment>
              );
            })}
        </div>
      ),
      width: "20%",
    },
    {
      title: "Kiến nghị sau kiểm tra công tơ",
      dataIndex: "ketLuanTemp",
      key: "ketLuanTemp",
      render: function (text, record) {
        const regex = "!@Kiến nghị:";
        const tempRegex = "Kiến nghị:";
        const result =
          record &&
          record?.ketLuanKienNghi &&
          record?.ketLuanKienNghi?.includes(regex)
            ? record?.ketLuanKienNghi?.split(regex)[1]
            : record?.ketLuanKienNghi?.split(tempRegex)[1];
        return (
          <div className="table-text-left">
            {result ? result.replace(/\s/g, "") : ""}
          </div>
        );
      },
      width: "10%",
    },
    {
      title: "Tác vụ",
      key: "action",
      align: "center",
      width: 120,
      render: (record) => (
        <CheckPermission permissionCode={kt_tb_do_dem.n47kt}>
          <div
            className="ant-btn-link cursor-pointer"
            style={{ textAlign: "center" }}
            onClick={() => handleOpenModalKTL(record.congToId)}
          >
            <Button danger type="primary">
              Kiểm tra lại
            </Button>
          </div>
        </CheckPermission>
      ),
    },
  ];
  const columns3Pha = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
      sorter: true,
    },
    {
      title: "Mã số GCS",
      dataIndex: "maSoGhiChiSo",
      key: "maSoGhiChiSo",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "7%",
      sorter: true,
    },
    {
      title: "Tên khách hàng (mã khách hàng)",
      dataIndex: "tenVaMaKhachHang",
      key: "tenVaMaKhachHang",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
      sorter: true,
    },
    {
      title: "Địa chỉ sử dụng điện(Vị trí công tơ)",
      dataIndex: "diaChiDungDien",
      key: "diaChiDungDien",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
      sorter: true,
    },
    {
      title: "Thông số công tơ",
      dataIndex: "soCongTo",
      key: "soCongTo",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "10%",
      sorter: true,
    },
    {
      title: "Chỉ số công tơ",
      dataIndex: "chiSoCongTo",
      key: "chiSoCongTo",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
      sorter: true,
    },
    {
      title: "Số pha",
      dataIndex: "soPha",
      key: "soPha",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "7%",
      sorter: true,
    },
    {
      title: "Tình trạng niêm phong",
      dataIndex: "tinhTrang",
      key: "tinhTrang",
      render: (text) => (
        <div className="table-text-left">
          {text &&
            text.split("!@").map((item) => {
              return (
                <Fragment>
                  <span>{item}</span>
                  <br />
                </Fragment>
              );
            })}
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Tình trạng hoạt động công tơ, sơ đồ đấy dây(có phụ tải)",
      dataIndex: "tinhTrangHoatDong",
      key: "tinhTrangHoatDong",
      render: (text) => (
        <div className="table-text-left">
          {text &&
            text.split("!@").map((item) => {
              return (
                <Fragment>
                  <span>{item}</span>
                  <br />
                </Fragment>
              );
            })}
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title:
        "Sau khi kiểm tra đã kẹp lại chì niêm phong công tơ, kết luận, kiến nghị sau kiểm tra công tơ",
      dataIndex: "ketLuanKienNghi",
      key: "ketLuanKienNghi",
      render: (text) => (
        <div className="table-text-left">
          {text &&
            text.split("!@").map((item) => {
              return (
                <Fragment>
                  <span>{item.includes("Kiến nghị") ? "" : item}</span>
                  <br />
                </Fragment>
              );
            })}
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Kiến nghị sau kiểm tra công tơ",
      dataIndex: "ketLuanTemp",
      key: "ketLuanTemp",
      render: function (text, record) {
        const regex = "!@Kiến nghị:";
        const tempRegex = "Kiến nghị:";
        const result =
          record &&
          record?.ketLuanKienNghi &&
          record?.ketLuanKienNghi?.includes(regex)
            ? record?.ketLuanKienNghi?.split(regex)[1]
            : record?.ketLuanKienNghi?.split(tempRegex)[1];
        return (
          <div className="table-text-left">
            {result ? result.replace(/\s/g, "") : ""}
          </div>
        );
      },
      width: "10%",
    },
    {
      title: "Tác vụ",
      key: "action",
      align: "center",
      width: 120,
      render: (record) => (
        <CheckPermission permissionCode={kt_tb_do_dem.n47kt}>
          <div
            className="ant-btn-link cursor-pointer"
            style={{ textAlign: "center" }}
            onClick={() => handleOpenModalKTL(record.congToId)}
          >
            <Button danger type="primary">
              Kiểm tra lại
            </Button>
          </div>
        </CheckPermission>
      ),
    },
  ];
  const columnsPhucTra = [
    {
      title: "Họ tên khách hàng",
      dataIndex: "tenVaMaKhachHang",
      key: "tenVaMaKhachHang",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChiDungDien",
      key: "diaChiDungDien",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Mã sổ GCS",
      dataIndex: "maSoGhiChiSo",
      key: "maSoGhiChiSo",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Chủng loại công tơ",
      dataIndex: "chungLoaiCongTo",
      key: "chungLoaiCongTo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Loại BCS",
      dataIndex: "loaiBCS",
      key: "loaiBCS",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "7%",
    },
    {
      title: "Đã ghi chỉ số",
      dataIndex: "daGhiChiSo",
      key: "daGhiChiSo",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "5%",
      children: [
        {
          title: "Ngày ghi",
          dataIndex: "ngayGhiChiSo",
          key: "ngayGhiChiSo",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
        },
        {
          title: "Chỉ số",
          dataIndex: "chiSoGhi",
          key: "chiSoGhi",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
        },
      ],
    },
    {
      title: "Thực hiện phúc tra",
      dataIndex: "thucHienPhucTra",
      key: "thucHienPhucTra",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "20%",
      children: [
        {
          title: "Ngày phúc tra",
          dataIndex: "ngayPhucTra",
          key: "ngayPhucTra",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
        },
        {
          title: "Chỉ số phúc tra",
          dataIndex: "chiSoPhucTra",
          key: "chiSoPhucTra",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
        },
        {
          title: "Tình trạng bên trong hòm(hòm, cáp, mã chì hòm, đánh đai...)",
          dataIndex: "tinhTrang",
          key: "tinhTrang",
          render: (text) => (
            <div className="table-text-left">
              {text &&
                text.split("!@").map((item) => {
                  return (
                    <Fragment>
                      <span>{item}</span>
                      <br />
                    </Fragment>
                  );
                })}
            </div>
          ),
        },
        {
          title: "Tình trạng hoạt động(bình thường, kẹt, chết...)",
          dataIndex: "tinhTrangHoatDong",
          key: "tinhTrangHoatDong",
          render: (text) => (
            <div className="table-text-left">
              {text &&
                text.split("!@").map((item) => {
                  return (
                    <Fragment>
                      <span>{item}</span>
                      <br />
                    </Fragment>
                  );
                })}
            </div>
          ),
        },
      ],
    },
    {
      title: "Kết luận(kiến nghị)",
      dataIndex: "ketLuanKienNghi",
      key: "ketLuanKienNghi",
      render: (text) => (
        <div className="table-text-left">
          {text &&
            text.split("!@").map((item) => {
              return (
                <Fragment>
                  <span>{item}</span>
                  <br />
                </Fragment>
              );
            })}
        </div>
      ),
      width: "10%",
    },
    {
      title: "Tác vụ",
      key: "action",
      align: "center",
      width: 120,
      render: (record) => (
        <CheckPermission permissionCode={kt_tb_do_dem.n47kt}>
          <div
            className="ant-btn-link cursor-pointer"
            style={{ textAlign: "center" }}
            onClick={() => handleOpenModalKTL(record.congToId)}
          >
            <Button danger type="primary">
              Kiểm tra lại
            </Button>
          </div>
        </CheckPermission>
      ),
    },
    // {
    //   title: "Tác vụ",
    //   key: "action",
    //   align: "center",
    //   width: "5%",
    //   render: (record) => (
    //     <div style={{ textAlign: "center" }}>
    //       <DeleteOutlined
    //         style={{ color: "#FF2D00", fontSize: 20, marginLeft: 10 }}
    //         onClick={() => {
    //           handleDelete(record.congToId);
    //         }}
    //       />
    //     </div>
    //   ),
    // },
  ];
  // hàm xóa row table

  const handleDelete = (key) => {
    const newDataCongTo = dataCongTo.filter((item) => item.congToId !== key);
    setDataCongTo(newDataCongTo);
  };
  // call api lấy list công tơ

  const handleLayDuLieu = () => {
    const data = {
      donViId: form.getFieldValue("donViId"),
      doiId: form.getFieldValue("doiId"),
      loaiBangKe: form.getFieldValue("loaiBangKe"),
      nguoiThucHienId: form.getFieldValue("nguoiThucHienId"),
    };
    if (data && Object.keys(data).length > 0) {
      handleListCTTao(data, setLoading, setDataCongTo, setColumnsBK);
    }
  };
  // đóng, mở modal kiểm tra lại

  const handleOpenModalKTL = (id) => {
    setId(id);
    setVisibleKiemTraLai(!visibleKiemTraLai);
  };
  const handleCloseModalKTL = () => {
    setVisibleKiemTraLai(false);
  };
  //render modal kiểm tra lại

  const showModalConfirm = useMemo(
    () =>
      visibleKiemTraLai && (
        <ConfirmModal
          visibleKiemTraLai={visibleKiemTraLai}
          handleCloseModal={handleCloseModalKTL}
          setVisibleKiemTraLai={setVisibleKiemTraLai}
          id={id}
          handleLayDuLieu={handleLayDuLieu}
        />
      ),
    // eslint-disable-next-line
    [visibleKiemTraLai]
  );
  // call api tạo bảng kê

  const handleTaoBangKe = () => {
    if (Array.isArray(selectedRowKey) && selectedRowKey.length > 0) {
      const dataSelected = dataCongTo.filter((x, index) =>
        selectedRowKey.includes(index + 1)
      );
      const data = {
        loaiBangKe: columnsBK,
        congToIds: dataSelected.map(({ congToId }) => congToId).toString(),
      };
      handleTaoBK(data, setLoading, closeModal, form, handleLayDuLieu, () =>
        setSelectedRowKeys([])
      );
    } else {
      const data = {
        loaiBangKe: columnsBK,
        congToIds: dataCongTo.map(({ congToId }) => congToId).toString(),
      };
      handleTaoBK(data, setLoading, closeModal, form, handleLayDuLieu, () =>
        setSelectedRowKeys([])
      );
    }
  };
  // call api multilist data dropdown

  useEffect(() => {
    handleMultiDS(setLoading, setLoaiBangKe, setNguoiThucHien);
  }, []);

  return (
    <Fragment>
      <TableDSCongTo
        user={user}
        loading={loading}
        handleLayDuLieu={handleLayDuLieu}
        dataTable={dataCongTo}
        handleTaoBangKe={handleTaoBangKe}
        handleDelete={handleDelete}
        columns={
          columnsBK === TYPE_TABLE_BANGKE.MOTPHA
            ? columns1Pha
            : columnsBK === TYPE_TABLE_BANGKE.PHUCTRA
            ? columnsPhucTra
            : columnsBK === TYPE_TABLE_BANGKE.BAPHA
            ? columns3Pha
            : null
        }
        loaiBangKe={loaiBangKe}
        form={form}
        nguoiThucHien={nguoiThucHien}
        rowSelection={rowSelection}
        rowKey="stt"
      />
      {showModalConfirm}
    </Fragment>
  );
}
