import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { authPostData } from "@utils/request";

export const handleXacNhanKyDuyet = (
  url,
  ids,
  setLoading,
  setFetchList,
  closeModal
) => {
  authPostData({
    url,
    method: METHOD_PUT,
    payload: {
      ids,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchList(true);
        closeModal();
      }
    },
  });
};

export const handleReturn = (
  url,
  payload,
  setLoading,
  setFetchList,
  setSelectedRowKeys,
  closeModal
) => {
  authPostData({
    url,
    method: METHOD_PUT,
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchList(true);
        setSelectedRowKeys([]);
        closeModal();
      }
    },
  });
};
