import styled from "styled-components";
import { Tabs, Upload } from "antd";

export const ContainerBody = styled.div`
  padding: 16px 24px;
`;

export const ContainerMultiUpload = styled(Upload)`
  .ant-upload.ant-upload-select {
    width: 100%;
  }
  button {
    height: 100px;
    width: 100%;
    background: rgb(250, 250, 250);
    border: 1px dashed rgb(217, 217, 217);
    text-align: center;
  }

  .upload-container {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .upload-choice {
      font-size: 16px;
    }
  }
`;

export const ContainerChoice = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  // position: fixed;
  // top: 153px;
  // box-shadow: 5px 5px rgb(0 0 0 / 15%);
  // z-index: 99999;
  // background: #ffffff;
  width: 100%;
  .text {
    // width: 20%;
    flex: 0 0 160px;
  }
  .choice {
    width: 30%;
  }
`;

export const TabsListContainer = styled(Tabs)`
  margin-left: -12px;
  margin-right: -12px;
  padding: 0px 15px;
  .ant-tabs-nav {
    position: inherit;
    top: 153px;
    /* box-shadow: 5px 5px rgb(0 0 0 / 15%); */
    /* border: 1px solid #f0f0f0; */
    z-index: 99999;
    background: #ffffff;
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 5px !important;
    ::before {
      content: none;
    }
  }
`;
