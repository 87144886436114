import {
  ENUM_DS_YEU_CAU,
  FUNC_ID_DSYC_TREOTHAO,
  TYPE_PHIEU_YEUCAU,
} from "@utils/constants";
import { useCallback, useState } from "react";
import DanhSachYeuCau from "../../common-ver1/danh-sach-yeu-cau";
import { treo_thao } from "@permissions/b_treothao";
import { Endpoint } from "@utils/endpoint";
import ModalConfirm from "@modules/treo-thao/common-ver1/confirm-xac-nhan";
import { Fragment } from "react";
import { Button } from "antd";
export default function Index() {
  const [fetchList, setFetchList] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [arrSelectedRows, setArrSelectedRows] = useState([]);
  const [visibled, setVisibled] = useState(false);
  const handleOpenXN = useCallback(() => {
    setVisibled(!visibled);
  }, [visibled]);

  const handleCloseXN = () => {
    setVisibled(false);
  };

  //show popup xác nhận
  const showPopupXN = useCallback(() => {
    return (
      visibled && (
        <ModalConfirm
          visible={visibled}
          closeModal={handleCloseXN}
          payload={{
            ids: selectedRowKeys.toString(),
          }}
          url={Endpoint.XAC_NHAN_KY_DUYET_YEU_CAU_DOI_TRUONG}
          setFetchList={setFetchList}
          content={"Yêu cầu sẽ được chuyển tiếp cho Lãnh đạo !"}
          title="Đội trưởng xác nhận yêu cầu treo tháo"
          dataSource={arrSelectedRows}
          type={TYPE_PHIEU_YEUCAU.YEUCAU}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      )
    );
  }, [arrSelectedRows, selectedRowKeys, visibled]);

  //button xác nhận
  const genExtra = () => (
    <Button
      type="primary"
      className="mr-5px btn-bg-yellow"
      onClick={() => handleOpenXN()}
      disabled={!selectedRowKeys.length}
    >
      Xác nhận
    </Button>
  );

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setArrSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  return (
    <Fragment>
      <DanhSachYeuCau
        funcId={FUNC_ID_DSYC_TREOTHAO.DOITRUONG_DUYETYEUCAU}
        typeList={ENUM_DS_YEU_CAU.DOITRUONG_KYDUYETYEUCAU}
        genExtra={genExtra}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        rowSelection={rowSelection}
        permissionCodeBB={treo_thao.b24bb}
        permissionCodeXemPhieu={treo_thao.b24v}
        setSelectedRowKeys={setSelectedRowKeys}
        title={"Đội trưởng xác nhận yêu cầu"}
        content={
          "Yêu cầu treo tháo đang ở bước Đội trưởng. Sau khi xác nhận, yêu cầu sẽ được chuyển sang bước tiếp theo"
        }
        selectedRowKeys={selectedRowKeys}
        urlXN={Endpoint.XAC_NHAN_KY_DUYET_YEU_CAU_DOI_TRUONG}
        permissionCodeXN={treo_thao.b24xn}
        isKySo={false}
      />
      {showPopupXN()}
    </Fragment>
  );
}
