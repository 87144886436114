import TableComponent from "@components/TableComponent";
import Filter from "@components/Filter/Filter";
import { Fragment } from "react";
import { Container } from "../../css-styled";
import ListFilter from "../../list-bo-loc";
import { customColumn } from "@utils/function";

export default function BaoCaoTongHop(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    handleBaoCaoChiTiet,
    totalCount,
    loading,
    onChangePagination,
    columns,
    data,
    countTotals,
    donVi,
    rowSelection,
    selectedRowKeys,
    isCompany,
    getListBaoCao,
    setSelectedRowKeys,
    disabledDaChot,
    disabledHuyChot,
    loaiYeuCau,
    setType,
  } = props;

  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          handleBaoCaoChiTiet={handleBaoCaoChiTiet}
          donVi={donVi}
          selectedRowKeys={selectedRowKeys}
          isCompany={isCompany}
          getListBaoCao={getListBaoCao}
          setSelectedRowKeys={setSelectedRowKeys}
          disabledDaChot={disabledDaChot}
          disabledHuyChot={disabledHuyChot}
          loaiYeuCau={loaiYeuCau}
          setType={setType}
        />
      </Filter>
      <Container>
        <TableComponent
          header={"Báo cáo tổng hợp"}
          totalData={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          // pagination={filterConditions}
          scrollX={3760}
          columns={customColumn(columns, filterConditions)}
          dataSource={data}
          summary={(pageData) => countTotals(pageData)}
          scrollY={500}
          rowSelection={rowSelection}
        />
      </Container>
    </Fragment>
  );
}
