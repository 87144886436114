import LoadingComponent from "@components/Loading";
import { Button, Form, Modal, Input } from "antd";

const { TextArea } = Input;

export default function ModalConfirm(props) {
  const {
    // detail,
    visible,
    handleOk,
    content,
    closeModal,
    loading,
    title,
    isTL,
    form,
  } = props;

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={title}
        visible={visible}
        // onOk={handleOk}
        onCancel={closeModal}
        footer={null}
      >
        <div>{content}</div>
        {isTL ? (
          <div style={{ marginTop: "15px" }}>
            <Form
              layout="vertical"
              onFinish={handleOk}
              form={form}
              name="filter-form"
            >
              <Form.Item
                name="lyDoTraLai"
                label="Lý do trả lại"
                className="required-field"
              >
                <TextArea rows={3} />
              </Form.Item>
              <div style={{ textAlign: "center", marginTop: "48px" }}>
                <Button
                  key="back"
                  onClick={closeModal}
                  style={{ marginRight: "24px" }}
                >
                  Đóng
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Đồng ý
                </Button>
              </div>
            </Form>
          </div>
        ) : (
          <div
            style={{ textAlign: "center", marginTop: "48px" }}
            key="footer-confirm"
          >
            <Button
              key="back"
              onClick={closeModal}
              style={{ marginRight: "24px" }}
            >
              Đóng
            </Button>
            <Button
              key="success"
              type="primary"
              loading={loading}
              onClick={() => handleOk()}
            >
              Đồng ý
            </Button>
          </div>
        )}
      </Modal>
    </LoadingComponent>
  );
}
