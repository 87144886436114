import { BASE_API_URL, BASE_API_URL_DANH_MUC } from "@utils/constants";

export const APICommonDanhMuc = {
  LIST_TRO_NGAI: `${BASE_API_URL_DANH_MUC}/common/list-tro-ngai`,
  LIST_NGAN_HANG: `${BASE_API_URL_DANH_MUC}/common/list-ngan-hang`,
  LIST_LO: `${BASE_API_URL_DANH_MUC}/common/list-lo`,
  LIST_TRAM: `${BASE_API_URL_DANH_MUC}/common/list-tram`,
  LIST_QUYEN: `${BASE_API_URL_DANH_MUC}/common/list-so-ghi-chi-so`,
  LIST_HINH_THUC_LAP_DAT: `${BASE_API_URL_DANH_MUC}/common/list-hinh-thuc-lap-dat`,
  LIST_LY_DO_TREO_THAO: `${BASE_API_URL_DANH_MUC}/common/list-ly-do-treo-thao`,
  LIST_NGANH_NGHE: `${BASE_API_URL_DANH_MUC}/common/list-nganh-nghe`,
  LIST_NHOM_NGANH_NGHE: `${BASE_API_URL_DANH_MUC}/common/list-nhom-nganh-nghe`,
  LIST_GIA_NHOM_NGANH_NGHE: `${BASE_API_URL_DANH_MUC}/common/list-gia-nhom-nganh-nghe`,
  LIST_CAP_DIEN_AP: `${BASE_API_URL_DANH_MUC}/common/list-cap-dien-ap`,
  LIST_THAM_CHIEU_CAP_DA: `${BASE_API_URL_DANH_MUC}/common/list-tham-chieu-cap-dien-ap`,
  LIST_AP_GIA_UY_QUYEN: `${BASE_API_URL_DANH_MUC}/common/list-uy-quyen`,
  LIST_AP_GIA_UY_QUYEN_MAC_DINH: `${BASE_API_URL_DANH_MUC}/common/get-default-uy-quyen`,
  DONG_BO_CMIS: `${BASE_API_URL}/common/sync-cmis-data-seperate`,
};
