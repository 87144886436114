import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { handlePagination } from "@utils/function";
import { Button, Col, Divider, Form, Input, Row, Spin } from "antd";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { exportData } from "./service";

export default function TimKiem() {
  const [dataTimKiem, setDataTimKiem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(authSelector);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });
  //pagination table
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const columns = [
    {
      title: "ID SMS",
      dataIndex: "IDSMS",
      key: "IDSMS",
      width: "5%",
      align: "center",
    },
    {
      title: "Mã khách hàng",
      dataIndex: "MA_KHANG",
      key: "MA_KHANG",
      width: "5%",
      align: "center",
    },
    {
      title: "Số điện thoại",
      dataIndex: "DTHOAI",
      key: "DTHOAI",
      width: "5%",
      align: "center",
    },
    {
      title: "Trạng Thái",
      dataIndex: "TRANG_THAI",
      key: "TRANG_THAI",
      width: "10%",
      align: "center",
    },
    {
      title: "Nội dung",
      dataIndex: "NOI_DUNG",
      key: "NOI_DUNG",
      align: "center",
    },

    {
      title: "Thời gian yêu cầu",
      dataIndex: "TGIAN_YC",
      key: "TGIAN_YC",
      align: "center",
    },
    {
      title: "Nội dung",
      dataIndex: "NOI_DUNG",
      key: "NOI_DUNG",
      align: "center",
    },
    {
      title: "Thời gian thực hiện",
      dataIndex: "TGIAN_TH",
      key: "TGIAN_TH",
      align: "center",
    },
  ];
  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Tìm kiếm
        </Divider>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              initialValues={{ unitId: user.user.unitCode }}
              colon={false}
            >
              <Form.Item
                name="MaKhachHang"
                label="Mã khách hàng / SĐT"
                labelAlign="left"
                required
              >
                <Input
                  placeholder="Nhập mã khách hàng"
                  formKey="MaKhachHang"
                  form={form}
                />
              </Form.Item>

              <Form.Item
                name="unitId"
                label="Đơn vị"
                labelAlign="left"
                required
              >
                <Selection
                  url={Endpoint.GET_TEAM_LIST_CODE}
                  formKey="unitId"
                  form={form}
                />
              </Form.Item>

              <Form.Item
                name="TuNgay"
                label="Từ ngày"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="Day"
                  formKey="TuNgay"
                  form={form}
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>

              <Form.Item
                name="DenNgay"
                label="Đến ngày"
                labelAlign="left"
                required
              >
                <DatePickerComponent
                  picker="Day"
                  formKey="DenNgay"
                  form={form}
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>

              <Row>
                <Col span={4}></Col>
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="full"
                    onClick={() => {
                      exportData(
                        {
                          ...form.getFieldValue(),
                          reportType: Endpoint.BCTHSMS_TIM_KIEM,
                        },
                        setLoading,
                        setDataTimKiem
                      );
                    }}
                  >
                    Tìm kiếm
                  </Button>
                </Col>
              </Row>
            </FormComponent>
            <Divider className="section-text-line" orientation="left"></Divider>
            <TableComponent
              header="Kết quả tìm kiếm"
              columns={columns}
              dataSource={dataTimKiem}
              pagination={filterConditions}
              onChangePagination={onChangePagination}
            />
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
