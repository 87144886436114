import { Endpoint } from "@utils/endpoint";
import { authGetData, downloadFile } from "@utils/request";

export const getDanhSachYeuCauDiemDo = (setLoading, setData, query) => {
  authGetData({
    url: `${Endpoint.LOTRINH_QUAN_LY_DIEM_DO}?${query}`,
    setLoading: setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const exportDanhSachYeuCauDiemDo = (query) => {
  downloadFile({
    endpoint: `${Endpoint.EXPORT_QUAN_LY_DIEM_DO}?${query}`,
    setLoading: () => {},
  });
};

export const getListDonVi = (setLoading, setData) => {
  authGetData({
    url: `${Endpoint.GET_UNIT_LIST}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getListDoi = (setLoading, setData, donVi) => {
  authGetData({
    url: `${Endpoint.GET_TEAM_LIST}?DonViId=${donVi}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getListTram = (setLoading, setData, donVi) => {
  authGetData({
    url: `${Endpoint.lOTRINH_LIST_TRAM}?DonViId=${donVi}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};
export const getSoGCS = (setLoading, setData, query) => {
  authGetData({
    url: `${Endpoint.LOTRINH_SO_GCS}?${query}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};
