import { Col, Form, Row, Select, Button } from "antd";
import FormComponent from "@components/Form";
import { Fragment } from "react";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";
import { TYPE_TABLE_BANGKE } from "@utils/constants";
import CheckPermission from "@components/CheckPermission";
import { kt_tb_do_dem } from "@permissions/n_kttbdodem";

const { Option } = Select;

export default function FormBoLoc(props) {
  const {
    user,
    form,
    loaiBangKe,
    handleTaoBangKe,
    handleLayDuLieu,
    handleChangeDoi,
  } = props;
  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleLayDuLieu}
      layout="vertical"
      initialValues={{
        donViId:
          !user.isTongCongTy && user.unitId ? user.unitId.toString() : null,
        doiId:
          !user.isTongCongTy && user.teamId ? user.teamId.toString() : null,
        nguoiThucHienId:
          !user.isTongCongTy && user.id ? user.id.toString() : null,
        loaiBangKe: TYPE_TABLE_BANGKE.MOTPHA.toString(),
      }}
      autoComplete="off"
    >
      <Row gutter={24}>
        <Fragment>
          <Col span={24} md={6}>
            <Form.Item
              name="donViId"
              label="Đơn vị"
              rules={[{ required: true, message: "Không được để trống!" }]}
            >
              {!user.isTongCongTy ? (
                <Select defaultValue={user.unitId} disabled>
                  <Option value={user.unitId}>{user.unitName}</Option>
                </Select>
              ) : (
                <Selection
                  url={`${Endpoint.LIST_DONVI_KTCT}?isSkipTongCongTy=true`}
                  form={form}
                  formKey="donViId"
                  notClear={true}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                if (prevValues.donViId !== currentValues.donViId) {
                  form.setFieldsValue({
                    doiId: undefined,
                  });
                }
                return prevValues.donViId !== currentValues.donViId;
              }}
            >
              {({ getFieldValue }) => {
                if (getFieldValue("donViId")) {
                  return (
                    <Form.Item
                      name="doiId"
                      label="Đội"
                      rules={[
                        { required: true, message: "Không được để trống!" },
                      ]}
                    >
                      <Selection
                        url={`${Endpoint.LIST_DOI_KTCT}?donviId=${getFieldValue(
                          "donViId"
                        )}`}
                        formKey="doiId"
                        form={form}
                        setValue={handleChangeDoi}
                        notClear={true}
                      />
                    </Form.Item>
                  );
                }
                return (
                  <Form.Item
                    name="doiId"
                    label="Đội"
                    rules={[
                      { required: true, message: "Không được để trống!" },
                    ]}
                  >
                    <Selection
                      url={`${Endpoint.LIST_DOI_KTCT}?donviId=${user.unitId}`}
                      formKey="doiId"
                      form={form}
                      setValue={handleChangeDoi}
                      notClear={true}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item
              name="loaiBangKe"
              label="Loại bảng kê"
              rules={[
                {
                  required: true,
                  message: "Không được để trống!",
                },
              ]}
            >
              <Select defaultValue={TYPE_TABLE_BANGKE.MOTPHA.toString()}>
                {loaiBangKe && loaiBangKe.length > 0
                  ? loaiBangKe.map((item, index) => {
                      if (item.value !== null) {
                        return (
                          <Option key={index} value={item.value.toString()}>
                            {item.name}
                          </Option>
                        );
                      }
                      return null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                if (prevValues.donViId !== currentValues.donViId) {
                  form.setFieldsValue({
                    nguoiThucHienId: undefined,
                  });
                }
                return prevValues.donViId !== currentValues.donViId;
              }}
            >
              {({ getFieldValue }) => {
                if (
                  user.isTongCongTy ||
                  (getFieldValue("donViId") && getFieldValue("doiId"))
                ) {
                  return (
                    <Form.Item
                      name="nguoiThucHienId"
                      label="Người thực hiện"
                      rules={[
                        {
                          required: true,
                          message: "Không được để trống!",
                        },
                      ]}
                    >
                      <Selection
                        url={`${
                          Endpoint.LIST_NGUOI_THUC_HIEN
                        }?donviId=${getFieldValue(
                          "donViId"
                        )}&doiId=${form.getFieldValue("doiId")}`}
                        formKey="nguoiThucHienId"
                        form={form}
                      />
                    </Form.Item>
                  );
                } else if (!user.isTongCongTy) {
                  return (
                    <Form.Item
                      name="nguoiThucHienId"
                      label="Người thực hiện"
                      rules={[
                        {
                          required: true,
                          message: "Không được để trống!",
                        },
                      ]}
                    >
                      <Select defaultValue={user.id}>
                        <Option value={user.id}>{user.name}</Option>
                      </Select>
                    </Form.Item>
                  );
                }
              }}
            </Form.Item>
          </Col>
          <Col
            span={24}
            md={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <CheckPermission permissionCode={kt_tb_do_dem.n47x}>
              <Button
                type="primary"
                htmlType="submit"
                form="filter-form"
                style={{ float: "right", marginBottom: 10 }}
              >
                Lấy dữ liệu
              </Button>
            </CheckPermission>
            <CheckPermission permissionCode={kt_tb_do_dem.n47c}>
              <Button
                type="primary"
                style={{ float: "right", marginBottom: 10, marginLeft: 10 }}
                onClick={() => handleTaoBangKe()}
              >
                Tạo bảng kê
              </Button>
            </CheckPermission>
          </Col>
        </Fragment>
      </Row>
    </FormComponent>
  );
}
