import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import FormComponent from "@components/Form";
import Thanglamviec from "@modules/giao-nhan-dien-nang/common/pdf-viewer/Thanglamviec";
import { Col, Form, Input, Row, Tooltip } from "antd";
import styled from "styled-components";
export function FilterTab2(props) {
  const { handleSearch, setFilterConditions, dataSoLuong } = props;
  const [form] = Form.useForm();
  const onChangeInput = (e) => {
    setFilterConditions(e.target.value);
  };
  return (
    <FormComponent
      form={form}
      name="filter-form"
      layout="vertical"
      onFinish={handleSearch}
      autoComplete="off"
    >
      <Row>
        <Col span={12}>
          {" "}
          <Thanglamviec />
        </Col>
        <Col span={4}></Col>
        <Col span={8}>
          <Form.Item name="search" style={{ margin: "10px 0 0 0" }}>
            <Input
              prefix={<SearchOutlined />}
              onChange={onChangeInput}
              suffix={
                <Tooltip title={"Nhấn phím Enter để hỗ trợ tìm kiếm"}>
                  <InfoCircleOutlined />
                </Tooltip>
              }
              placeholder="Tìm kiếm theo Mã điểm đo, Thông tin điểm đo, Mã công tơ"
            />
          </Form.Item>
        </Col>
      </Row>
      <Infomations>
        <div style={{ paddingTop: "10px" }} className="infomation-diem-do">
          <span>Danh sách điểm đo:</span>
          <span
            className="info-middle"
            style={{ color: "red", margin: "0 10px 0 10px" }}
          >
            {dataSoLuong?.donViQuangLyKhac || 0}
          </span>
          điểm đo
        </div>
      </Infomations>
    </FormComponent>
  );
}
const Infomations = styled.div`
  .infomation-diem-do {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .info-middle {
    font-size: 16px;
  }
`;
