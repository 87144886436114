import { Fragment } from "react";
import TableComponent from "@components/TableComponent";
import Filter from "@components/Filter/Filter";

import { customColumn } from "@utils/function";
import { ListFilter } from "../list-bo-loc";
import { Container } from "./css-styled";
export default function Index(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    genExtra,
    dataSource,
    columns,
    totalCount,
    loading,
    onChangePagination,
    t,
  } = props;

  return (
    <Fragment>
      <Filter>
        <ListFilter
          tooltip={"Tìm theo mã lộ, tên lộ hoặc loại lộ"}
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
        />
      </Filter>
      <Container>
        <TableComponent
          header={t("lo.danh_muc_lo")}
          renderExtraAction={() => genExtra()}
          dataSource={dataSource}
          columns={customColumn(columns, filterConditions)}
          totalData={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
          scrollY={530}
        />
      </Container>
    </Fragment>
  );
}
