import { UploadOutlined } from "@ant-design/icons";
import TrashIcon from "@assets/icon/TrashIcon";
import { Form, Button, Row, Col, Select } from "antd";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import { removeAccents } from "@utils/function";
import { useCallback } from "react";
import { ContainerUpload, UploadFileContainer } from "../css-styled";
import { ENUM_MUCDICH_SDD } from "@utils/constants";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";
const { Option } = Select;
export default function Content(props) {
  const { fileEx,
    loading,
    removeFile,
    handleImportFile,
    uploadFile,
    messErr,
    // dataNV,
    handleCloseImport,
    // changeNV,
    changeUyQuyen,
    changeLoaiKH,
    dataApGiaUyQuyen,
    dataApGiaUyQuyenMacDinh } = props;
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  //render file đã upload
  const renderFile = useCallback(() => {
    if (fileEx && fileEx.name) {
      return (
        <div
          key={fileEx.name}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "12px",
            width: "85%",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            {fileEx.name}
          </div>
          <div onClick={() => removeFile()} style={{ cursor: "pointer" }}>
            <TrashIcon />
          </div>
        </div>
      );
    } else return null;
  }, [fileEx, removeFile]);

  return (
    <div>
      <Form
        form={form}
        name="form-phan-cong"
        layout="horizontal"
        onFinish={handleImportFile}
        initialValues={{
          uyQuyenId: dataApGiaUyQuyenMacDinh && dataApGiaUyQuyenMacDinh.value
        }}
      >
        <Row gutter={24}>
          <Col span={6}>
            <span>Đơn vị:</span>
          </Col>
          <Col span={18}>
            <Form.Item name="unitId" label="">
              <span>{user.unitName}</span>
            </Form.Item>
          </Col>
        </Row>

        {/* <Row gutter={24}>
          <Col span={6}>
            <span>Nhân viên kiểm tra:</span>
          </Col>
          <Col span={18}>
            <Form.Item name="userId" label=""
              rules={[
                {
                  required: true,
                  message: `Nhân viên kiểm tra không được bỏ trống.`,
                },
              ]}>
              <Select onChange={changeNV} showSearch filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
              >
                {dataNV && dataNV.length > 0
                  ? dataNV.map((item, index) => (
                    <Option key={index} value={item.value}>
                      {item.name}
                    </Option>
                  ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={24}>
          <Col span={6}>
            <span>Đội:</span>
          </Col>
          <Col span={18}>
            <Form.Item name="doiId" label=""
              rules={[
                {
                  required: true,
                  message: `Đội không được bỏ trống.`,
                },
              ]}
            >
              <Selection
                url={`${Endpoint.GET_TEAM_LIST}?donviId=${user.unitId}`}
                formKey="doiId"
                form={form}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <span>Nhân viên kiểm tra:</span>
          </Col>
          <Col span={24} md={18}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                if (prevValues.doiId !== currentValues.doiId) {
                  form.setFieldsValue({
                    userId: undefined,
                  });
                }
                return prevValues.doiId !== currentValues.doiId;
              }}
              rules={[
                {
                  required: true,
                  message: `Nhân viên kiểm tra không được bỏ trống.`,
                },
              ]}
            >
              {({ getFieldValue }) => {
                if (getFieldValue("doiId")) {
                  return (
                    <Form.Item name="userId" label=""
                      rules={[
                        {
                          required: true,
                          message: `Nhân viên kiểm tra không được bỏ trống.`,
                        },
                      ]}
                    >
                      <Selection
                        url={`${Endpoint.AG_LIST_NV_PHAN_CONG}?donViId=${user.unitId}&doiId=${getFieldValue(
                          "doiId"
                        )}`}
                        formKey="userId"
                        form={form}
                      />
                    </Form.Item>
                  );
                }
                return (
                  <Form.Item name="userId" label=""
                    rules={[
                      {
                        required: true,
                        message: `Nhân viên kiểm tra không được bỏ trống.`,
                      },
                    ]}
                  >
                    <Selection
                      // url={`${Endpoint.AG_LIST_NV_PHAN_CONG}?donviId=${user.unitId}&doiId=${user.teamId}`}
                      formKey="userId"
                      form={form}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={24}>
          <Col span={6}>
            <span>Nhân viên kiểm tra:</span>
          </Col>
          <Col span={18}>
            <Form.Item
              name="userId" label=""
              rules={[
                {
                  required: true,
                  message: `Nhân viên áp giá không được bỏ trống.`,
                },
              ]}
            >
              <Selection
                url={`${Endpoint.AG_LIST_NV_PHAN_CONG}?donViId=${user.unitId}&doiId=${user.teamId}`}
                formKey="userId"
                form={form}
              />
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={24}>
          <Col span={6}>
            <span>Ủy quyền áp giá:</span>
          </Col>
          <Col span={18}>
            <Form.Item name="uyQuyenId" label=""
              rules={[
                {
                  required: true,
                  message: `Ủy quyền áp giá không được bỏ trống.`,
                },
              ]}>
              <Select onChange={changeUyQuyen} showSearch filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
                defaultValue={dataApGiaUyQuyenMacDinh && dataApGiaUyQuyenMacDinh.value}
              >
                {dataApGiaUyQuyen && dataApGiaUyQuyen.length > 0
                  ? dataApGiaUyQuyen.map((item, index) => (
                    <Option key={index} value={item.value}>
                      {item.name}
                    </Option>
                  ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <span>Loại khách hàng:</span>
          </Col>
          <Col span={18}>
            <Form.Item name="LoaiKhachHang" label="">
              <Select defaultValue={ENUM_MUCDICH_SDD.SINHHOAT} onChange={changeLoaiKH}>
                <Option value={ENUM_MUCDICH_SDD.SINHHOAT}>Sinh hoạt</Option>
                <Option value={ENUM_MUCDICH_SDD.NGOAISINHHOAT}>Ngoài sinh hoạt</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <ContainerUpload>
          <div className="choice-file">
            <UploadFileContainer>
              <input type="file" onChange={uploadFile} accept=".xls, .xlsx" />
              <p>
                <span>
                  <UploadOutlined /> Chọn tệp
                </span>
                <br />
                <span>Có thể kéo & thả để tải tệp (*.xlsx)</span>
              </p>
            </UploadFileContainer>
          </div>
        </ContainerUpload>
        {renderFile()}
        {messErr !== "" ? (
          <div style={{ marginTop: "12px", color: "red", fontSize: "16px" }}>
            {messErr}
          </div>
        ) : null}
        <br />
        <Row justify="center">
          <Button
            // onClick={handleImportFile}
            key="submit"
            type="primary"
            className="button-primary mr-24px"
            htmlType="submit"
            form="form-phan-cong"
            loading={loading}
            disabled={!fileEx.name}
          >
            Phân công
          </Button>
          <Button type="default" onClick={() => handleCloseImport()}>
            Đóng lại
          </Button>
        </Row>
      </Form>

    </div>
  );
}
