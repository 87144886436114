import { METHOD_PUT } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, authPutData } from "@utils/request";

export const getDataTram = (setDataTramTCT, setDataTramToanDV, setLoading) => {
  authGetData({
    url: `${Endpoint?.GET_ALL_TRAM}`,
    setLoading,
    onSuccess: (res) => {
      if (res.length > 0) setDataTramTCT(res);
      else setDataTramTCT(res.data);
    },
  });
  authGetData({
    url: `${Endpoint.GET_SELECTION_DVGN}`,
    setLoading,
    onSuccess: (res) => {
      setDataTramToanDV(res.data);
    },
  });
};

export const getData = (
  filterConditions,
  setDataTable,
  setTargetKeys,
  setOriginData,
  setLoading
) => {
  const queryString = buildQueryString(
    parseParams({
      TramId: filterConditions.TramId,
      DonViGNId: filterConditions.DonViGNId,
    })
  );
  authGetData({
    url: `${Endpoint?.GET_DS_PHANQUYEN_DIEM_DO}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res) {
        const item = res?.data?.map((e, i) => {
          return { ...e, key: i + 1, disabled: !e.isMove };
        });
        setDataTable(
          item?.filter(function (element) {
            return element !== undefined;
          })
        );
        const originTargetKeys = item
          .filter((i) => i.disabled)
          ?.map((i) => i.id);
        setTargetKeys(originTargetKeys);
        setOriginData(originTargetKeys);
      }
    },
  });
};

export const onFinish = (
  targetKeys,
  filterConditions,
  setDataTable,
  setTargetKeys,
  setOriginData,
  setLoading,
  setListDiemDoSelected
) => {
  authPutData({
    url: `${Endpoint.POST_CHANGE_TRAM}`,
    method: METHOD_PUT,
    payload: {
      listId: targetKeys,
      donVIGiaoNhanId: filterConditions.DonViGNId,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        getData(
          filterConditions,
          setDataTable,
          setTargetKeys,
          setOriginData,
          setLoading
        );
      }
      setListDiemDoSelected([]);
    },
  });
};
