import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_DS_BANGKE,
  FORMAT_ENGLISH,
  TYPE_MODAL,
  TYPE_PHANCONG,
} from "@utils/constants";
import {
  alertMessage,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { handleListData, handleMultiDS, inPDF } from "./services";
import DSCongToTable from "./table";
import CheckPermission from "@components/CheckPermission";
import { kt_tb_do_dem } from "@permissions/n_kttbdodem";
import { Button, Row, Tag, Tooltip } from "antd";
import TraLaiModal from "../tra-lai-bang-ke";
import XacNhanBangKeModal from "../modal-xac-nhan-bang-ke/index";
import KySoModal from "../modal-ky-so-hsm";
import HuyBangKeModal from "../modal-huy-bang-ke";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Endpoint } from "@utils/endpoint";
import moment from "moment";
import { Grid } from "antd";
const { useBreakpoint } = Grid;

export default function Index(props) {
  const {
    typeList,
    typeDS,
    isRowSelect,
    rowSelection,
    header,
    selectedRowKeys,
    typeGenExtra,
    setSelectedRowKeys,
    setIsDisableAction,
    isDisableAction,
    urlXuatExcel,
    permissionExcel,
  } = props;
  const { user } = useSelector(authSelector);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [firstTrangThai, setFirstTrangThai] = useState("");
  const [filterConditions, setFilterConditions] = useState({});
  const [visibleXacNhan, setVisibleXacNhan] = useState(false);
  const [visibleHuy, setVisibleHuy] = useState(false);
  const [visiblePDF, setVisiblePDF] = useState(false);
  const [visibleTraLai, setVisibleTraLai] = useState(false);
  const [loaiBangKe, setLoaiBangKe] = useState([]);
  const [trangThai, setTrangThai] = useState([]);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [visibleLeadXacNhan, setVisibleLeadXacNhan] = useState(false);
  const [id, setId] = useState("");
  const [biTraLai, setBiTraLai] = useState(false);
  const [check, setCheck] = useState(false);
  const [visibleKySo, setVisibleKySo] = useState(false);
  const screens = useBreakpoint();
  const mobile = screens.xs && !screens.md;

  // column table

  const columnsTableKDBK = [
    {
      title: "Mã bảng kê",
      dataIndex: "maBangKe",
      key: "maBangKe",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => showViewPDF(record.bangKeId)}
        >
          <span>{text}</span>
        </div>
      ),
      width: "20%",
      sorter: true,
    },
    {
      title: "Loại bảng kê",
      dataIndex: "loaiBangKe",
      key: "loaiBangKe",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "20%",
      sorter: true,
    },
    {
      title: "Đội",
      dataIndex: "tenDoi",
      key: "tenDoi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "20%",
      sorter: true,
    },
    {
      title: "Ngày lập",
      dataIndex: "ngayTao",
      key: "ngayTao",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: 250,
      sorter: true,
    },
    {
      title: "Người lập",
      dataIndex: "nguoiTao",
      key: "nguoiTao",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "20%",
      sorter: true,
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text, record) => (
        <div className="table-text-center">
          <Tag color={record.maMau}>{text}</Tag>
          {record.biTraLai ? (
            <div
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: "#FF5247",
                cursor: "pointer",
              }}
            >
              <Tooltip title={record.lyDoTraLai}>
                <InfoCircleOutlined /> Bị trả lại
              </Tooltip>
            </div>
          ) : null}
        </div>
      ),
      width: "20%",
      sorter: true,
      hidden: true,
    },
    isDisableAction
      ? {}
      : {
          title: "Tác vụ",
          key: "action",
          align: "center",
          width: 120,
          render: (row) => (
            <Fragment>
              {row.isDisable ? null : (
                <Fragment>
                  {typeGenExtra === TYPE_PHANCONG.NHANVIEN ? (
                    <CheckPermission permissionCode={kt_tb_do_dem.n45h}>
                      <div
                        className="ant-btn-link cursor-pointer"
                        style={{ textAlign: "center" }}
                        onClick={() => handleOpenModalHuy(row.bangKeId)}
                      >
                        <Button danger size="small">
                          Hủy bảng kê
                        </Button>
                      </div>
                    </CheckPermission>
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CheckPermission
                        permissionCode={
                          typeDS === ENUM_DS_BANGKE.KYDUYET_PKD
                            ? kt_tb_do_dem.n23k
                            : typeDS === ENUM_DS_BANGKE.KYDUYET_DT
                            ? kt_tb_do_dem.n33k
                            : typeDS === ENUM_DS_BANGKE.PHUCTRA_BPPT
                            ? kt_tb_do_dem.n71k
                            : null
                        }
                      >
                        <div
                          className="ant-btn-link cursor-pointer"
                          style={{ textAlign: "center" }}
                          onClick={() => handleOpenModalTraLai(row.bangKeId)}
                        >
                          <Button danger size="small">
                            <span style={{ padding: "0px 20px" }}>Trả lại</span>
                          </Button>
                        </div>
                      </CheckPermission>
                    </div>
                  )}
                </Fragment>
              )}
            </Fragment>
          ),
        },
  ];
  const columnsTableKDBKTC = [
    {
      title: "Mã bảng kê",
      dataIndex: "maBangKe",
      key: "maBangKe",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => showViewPDF(record.bangKeId)}
        >
          <span>{text}</span>
        </div>
      ),
      width: "15%",
      sorter: true,
    },
    {
      title: "Loại bảng kê",
      dataIndex: "loaiBangKe",
      key: "loaiBangKe",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
      sorter: true,
    },
    {
      title: "Đội",
      dataIndex: "tenDoi",
      key: "tenDoi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
      sorter: true,
    },
    {
      title: "Ngày lập",
      dataIndex: "ngayTao",
      key: "ngayTao",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "10%",
      sorter: true,
    },
    {
      title: "Người lập",
      dataIndex: "nguoiTao",
      key: "nguoiTao",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
      sorter: true,
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text, record) => (
        <div className="table-text-center">
          <Tag color={record.maMau}>{text}</Tag>
          {record.biTraLai ? (
            <div
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: "#FF5247",
                cursor: "pointer",
              }}
            >
              <Tooltip title={record.lyDoTraLai}>
                <InfoCircleOutlined /> Bị trả lại
              </Tooltip>
            </div>
          ) : null}
        </div>
      ),
      // width: "15%",
      sorter: true,
    },
  ];
  // các hàm đóng modal

  const handleCloseModalXN = () => {
    setVisibleXacNhan(false);
  };
  const handleCloseModalTraLai = () => {
    setVisibleTraLai(false);
  };
  const handleCloseModalHuy = () => {
    setVisibleHuy(false);
  };
  const handleCloseModalLeadXN = () => {
    setVisibleLeadXacNhan(false);
  };
  const handleCloseModalKySo = () => {
    setVisibleKySo(false);
  };
  // các hàm mở modal

  const handleOpenModalXN = () => {
    setVisibleXacNhan(!visibleXacNhan);
  };
  const handleOpenModalTraLai = (id) => {
    setId(id);
    setVisibleTraLai(!visibleTraLai);
  };
  const handleOpenModalHuy = (id) => {
    setId(id);
    setVisibleHuy(!visibleHuy);
  };
  const handleOpenModalLeadXN = () => {
    setVisibleLeadXacNhan(!visibleLeadXacNhan);
  };
  const handleOpenModalKySo = () => {
    setVisibleKySo(!visibleKySo);
  };

  // Hiển thị  các buttom

  const genExtra = () => (
    <Fragment>
      {typeGenExtra === TYPE_PHANCONG.NHANVIEN ? (
        <CheckPermission permissionCode={kt_tb_do_dem.n45xn}>
          <Button
            type="primary"
            onClick={() => handleOpenModalXN()}
            disabled={!selectedRowKeys.length}
          >
            Xác nhận hoàn thành
          </Button>
        </CheckPermission>
      ) : typeDS === ENUM_DS_BANGKE.TRACUU ||
        typeDS === ENUM_DS_BANGKE.CHOKHKY_PKD_CMIS ? null : (
        <Row
          gutter={24}
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: mobile ? "column" : "",
          }}
        >
          {/* <KySoComponent
              url={Endpoint.KY_BANGKE}
              urlGetHash={Endpoint.GET_HASH_KY_BANGKE}
              payload={{
                maLoaiDanhSach: typeDS,
                bangKeIds: selectedRowKeys.toString(),
              }}
              disabled={!selectedRowKeys.length}
              setFetchDataTable={setFetchDataTable}
              text={"Ký số"}
              setLoading={setLoading}
              loading={loading}
              typeKySo={ENUM_KYSO.KTCONGTO}
            /> */}
          <CheckPermission
            permissionCode={
              typeDS === ENUM_DS_BANGKE.KYDUYET_PKD
                ? kt_tb_do_dem.n23k
                : typeDS === ENUM_DS_BANGKE.KYDUYET_DT
                ? kt_tb_do_dem.n33k
                : typeDS === ENUM_DS_BANGKE.PHUCTRA_BPPT
                ? kt_tb_do_dem.n71k
                : null
            }
          >
            <Button
              type="primary"
              className={mobile ? "mt-8px" : "mr-5px"}
              onClick={() => handleOpenModalKySo()}
              disabled={!selectedRowKeys.length}
            >
              Ký số
            </Button>
          </CheckPermission>

          <CheckPermission
            permissionCode={
              typeDS === ENUM_DS_BANGKE.KYDUYET_PKD
                ? kt_tb_do_dem.n23xn
                : typeDS === ENUM_DS_BANGKE.KYDUYET_DT
                ? kt_tb_do_dem.n33xn
                : typeDS === ENUM_DS_BANGKE.PHUCTRA_BPPT
                ? kt_tb_do_dem.n71xn
                : null
            }
          >
            <Button
              type="primary"
              className={mobile ? "mt-8px" : "mr-5px"}
              onClick={() => handleOpenModalLeadXN()}
              disabled={!selectedRowKeys.length}
            >
              Xác nhận
            </Button>
          </CheckPermission>
        </Row>
      )}
    </Fragment>
  );

  const detailDouble = (record) => {
    return {
      onDoubleClick: () => {
        setId(record.bangKeId);
        setVisiblePDF(!visiblePDF);
      },
    };
  };
  //render modal trả lại

  const showModalTraLai = useMemo(
    () =>
      visibleTraLai && (
        <TraLaiModal
          visibleTraLai={visibleTraLai}
          handleCloseModal={handleCloseModalTraLai}
          setFetchDataTable={setFetchDataTable}
          typeDS={typeDS}
          id={id}
          content={
            typeDS === ENUM_DS_BANGKE.KYDUYET_PKD
              ? "Bảng kê sẽ được trả về cho Phòng phúc tra !"
              : typeDS === ENUM_DS_BANGKE.PHUCTRA_BPPT
              ? "Bảng kê sẽ được trả về cho Đội trưởng !"
              : typeDS === ENUM_DS_BANGKE.KYDUYET_DT
              ? "Bảng kê sẽ được trả về cho Nhân viên kiểm tra !"
              : ""
          }
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleTraLai]
  );
  //render modal ký số

  const showModalKySo = useMemo(
    () =>
      visibleKySo && (
        <KySoModal
          url={Endpoint.KYSO_HSM_KTCT}
          params={{
            maLoaiDanhSach: typeDS,
            bangKeIds: selectedRowKeys.toString(),
          }}
          setFetchDataTable={setFetchDataTable}
          disable={!selectedRowKeys.length}
          setSelectedRowKeys={setSelectedRowKeys}
          visibleKySo={visibleKySo}
          handleCloseModalKySo={handleCloseModalKySo}
          content={
            typeDS === ENUM_DS_BANGKE.KYDUYET_PKD
              ? "Bảng kê sẽ được chuyển tiếp cho Phòng kinh doanh !"
              : typeDS === ENUM_DS_BANGKE.PHUCTRA_BPPT
              ? "Bảng kê sẽ được chuyển tiếp cho Phòng kinh doanh !"
              : typeDS === ENUM_DS_BANGKE.KYDUYET_DT
              ? "Bảng kê sẽ được chuyển tiếp cho Bộ phận phúc tra !"
              : ""
          }
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleKySo]
  );
  //render modal nhân viên xác nhận bảng kê

  const showModalXacNhan = useMemo(
    () =>
      visibleXacNhan && (
        <XacNhanBangKeModal
          visibleXacNhan={visibleXacNhan}
          handleCloseModalXN={handleCloseModalXN}
          setFetchDataTable={setFetchDataTable}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          title={"Xác nhận hoàn thành bảng kê"}
          content={"Bảng kê sẽ được chuyển tiếp cho Đội trưởng !"}
          type={TYPE_MODAL.XN}
        />
      ),
    // eslint-disable-next-line
    [visibleXacNhan]
  );

  //render modal lead xác nhận bảng kê

  const showModalLeadXacNhan = useMemo(
    () =>
      visibleLeadXacNhan && (
        <XacNhanBangKeModal
          visibleXacNhan={visibleLeadXacNhan}
          handleCloseModalXN={handleCloseModalLeadXN}
          setFetchDataTable={setFetchDataTable}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          title={"Xác nhận bảng kê"}
          content={
            typeDS === ENUM_DS_BANGKE.KYDUYET_PKD
              ? "Bảng kê sẽ được chuyển tiếp cho Phòng kinh doanh !"
              : typeDS === ENUM_DS_BANGKE.PHUCTRA_BPPT
              ? "Bảng kê sẽ được chuyển tiếp cho Phòng kinh doanh !"
              : typeDS === ENUM_DS_BANGKE.KYDUYET_DT
              ? "Bảng kê sẽ được chuyển tiếp cho Bộ phận phúc tra !"
              : ""
          }
          type={TYPE_MODAL.LEADXN}
          typeDS={typeDS}
        />
      ),
    // eslint-disable-next-line
    [visibleLeadXacNhan]
  );
  //render modal hủy bảng kê

  const showModalHuy = useMemo(
    () =>
      visibleHuy && (
        <HuyBangKeModal
          visibleHuy={visibleHuy}
          handleCloseModalhuy={handleCloseModalHuy}
          setFetchDataTable={setFetchDataTable}
          setFetchList={fetchDataTableIfNeed}
          id={id}
        />
      ),
    //eslint-disable-next-line
    [visibleHuy]
  );
  //change show page size

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };
  //get list data

  const getListData = useCallback(() => {
    handleListData(
      filterConditions,
      setLoading,
      setDataSource,
      setTotalCount,
      location
    );
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (firstTrangThai !== "" || typeDS === ENUM_DS_BANGKE.TRACUU)
      setFilterConditions({
        pageIndex: DEFAULT_PAGEINDEX,
        pageSize: DEFAULT_PAGESIZE,
        donViId: user.unitId,
        ...parseParams(location.search),
        ngayBatDau: moment().startOf("year").format(FORMAT_ENGLISH),
        ngayKetThuc: moment().format(FORMAT_ENGLISH),
        trangThaiId: firstTrangThai,
        maLoaiDanhSach: typeDS,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstTrangThai, typeDS]);

  useEffect(() => {
    if (filterConditions && Object.keys(filterConditions).length > 0) {
      getListData();
    }
    // eslint-disable-next-line
  }, [getListData]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListData();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
    // eslint-disable-next-line
  }, [fetchDataTableIfNeed]);
  // get multilist data dropdown

  useEffect(() => {
    handleMultiDS(
      setLoading,
      setTrangThai,
      setLoaiBangKe,
      typeDS,
      setFirstTrangThai
    );
  }, [typeDS]);
  // hàm tìm kiếm dữ liệu

  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        pageIndex: DEFAULT_PAGEINDEX,
        ...values,
        donViId: values.donViId,
        biTraLai: biTraLai,
        maLoaiDanhSach: typeDS,
      }));
      setSelectedRowKeys([]);
      setIsDisableAction(check);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [biTraLai, check]
  );
  // view pdf

  const base64toBlob = useCallback((data) => {
    try {
      const base64WithoutPrefix = data.substr(
        "data:application/pdf;base64,".length
      );
      const bytes = atob(base64WithoutPrefix);
      let length = bytes.length;
      const out = new Uint8Array(length);

      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      return new Blob([out], { type: "application/pdf" });
    } catch (error) {
      alertMessage("error", "Thông báo", "Định dạng file không hỗ trợ!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const showViewPDF = useCallback(
    (row) => {
      if (row !== undefined) {
        const id = row;
        inPDF(
          id,
          (data) => {
            if (data) {
              //pdf
              const blob = base64toBlob(data);
              const url = URL.createObjectURL(blob);
              window.open(url);
            }
          },
          setLoadingPDF
        );
      }
    },
    [base64toBlob]
  );
  //change checkbox

  const checkTraLai = (e) => {
    setBiTraLai(e.target.checked);
  };
  // hàm clear bộ lọc

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
      ngayBatDau: moment().startOf("year").format(FORMAT_ENGLISH),
      ngayKetThuc: moment().format(FORMAT_ENGLISH),
      trangThaiId: firstTrangThai,
      maLoaiDanhSach: typeDS,
    });
    setIsDisableAction(false);
    setSelectedRowKeys([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.unitId, firstTrangThai]);

  const onChangeTrangThai = (values) => {
    if (values === undefined) {
      setCheck(false);
    } else {
      trangThai &&
        trangThai.length > 0 &&
        // eslint-disable-next-line array-callback-return
        trangThai.map((item) => {
          if (item.value === values) {
            setCheck(item.isDisableAction);
          }
        });
    }
  };

  return (
    <Fragment>
      <DSCongToTable
        loading={loading}
        dataSource={dataSource}
        totalData={totalCount}
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        onChangePagination={onChangePagination}
        filterConditions={filterConditions}
        rowSelection={isRowSelect ? rowSelection : null}
        genExtra={genExtra}
        user={user}
        typeList={typeList}
        columns={
          typeDS === ENUM_DS_BANGKE.TRACUU ||
          typeDS === ENUM_DS_BANGKE.CHOKHKY_PKD_CMIS
            ? columnsTableKDBKTC
            : columnsTableKDBK
        }
        header={header}
        typeDS={typeDS}
        loaiBangKe={loaiBangKe}
        trangThai={trangThai}
        firstTrangThai={firstTrangThai}
        rowKey={"bangKeId"}
        onRow={detailDouble}
        checkTraLai={checkTraLai}
        onChangeTrangThai={onChangeTrangThai}
        urlXuatExcel={urlXuatExcel}
        permissionExcel={permissionExcel}
      />
      {showModalTraLai}
      {/* {showModalPdf} */}
      {showModalXacNhan}
      {showModalHuy}
      {showModalLeadXacNhan}
      {showModalKySo}
    </Fragment>
  );
}
