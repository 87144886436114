import PencilIcon from "@assets/icon/PencilIcon";
import ButtonComponent from "@components/Button";
import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import { handlePagination } from "@utils/function";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import React, { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { handleCreateCongTo } from "../service";
import { ModalSuaTHKiemTraDKCongTo } from "./md-sua/md-sua-th-kiem-tra-dinh-ky";

function ModalCapNhatThKiemTraDinhKy(props) {
  const {
    visible,
    onCancel,
    dataTable,
    filterConditions,
    setFilterConditions,
    getListData,
    loading,
    setLoading,
  } = props;

  const [form] = Form.useForm();
  const [detailTable, setDetailTable] = useState();
  const user = useSelector(authSelector);
  const [visibleModalUpdate, setVisibleModalUpdate] = useState();

  //HandleChange Năm
  const handleChange = (value) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      Nam: moment(value).get("year"),
    }));
  };

  //Open-close modal
  const handleOpenModal = useCallback(
    (row) => {
      setDetailTable(row);
      setVisibleModalUpdate(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleModalUpdate]
  );

  const handleCloseModal = () => {
    setVisibleModalUpdate(false);
    setLoading(false);
  };

  //Onfinish form
  const onFinish = (values) => {
    const url = Endpoint.BCCT_THEM_KT_DK_CT;
    const data = {
      ma_dviqly: values.unitId,
      ...(values.reportDate && { nam: values.reportDate.split("-")[0] }),
      ...(values.reportDate && { thang: values.reportDate.split("-")[1] }),
      tong_cto: values.tong_cto,
      tong_cto_ktra: values.tong_cto_ktra,
    };
    handleCreateCongTo(data, setLoading, getListData, url, form);
  };

  //Columns
  const columns = [
    {
      title: "Đơn vị",
      dataIndex: "MA_DVIQLY",
      key: "MA_DVIQLY",
      align: "center",
    },
    {
      title: "Tháng",
      dataIndex: "THANG",
      key: "THANG",
      align: "center",
    },
    {
      title: "Năm",
      dataIndex: "NAM",
      key: "NAM",
      align: "center",
    },
    {
      title: "Tổng công tơ",
      dataIndex: "TONG_CTO",
      key: "TONG_CTO",
      align: "center",
    },
    {
      title: "Tác vụ",
      width: 100,
      fixed: "right",
      render: (row) => (
        <div className="action-table-column">
          <Tooltip title={"Sửa"}>
            <ButtonComponent
              type="link"
              onClick={() => handleOpenModal(row)}
              className="pd-5px"
            >
              <PencilIcon />
            </ButtonComponent>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Modal
        visible={visible}
        title="Cập nhật tình hình kiểm tra định kỳ công tơ"
        onCancel={onCancel}
        width={"70%"}
        footer={[
          <Button key="cancel" onClick={onCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <FormComponent
            onFinish={onFinish}
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{
              unitId: user.user.unitCode,
              tong_cto_ktra: 0,
              tong_cto: 0,
            }}
          >
            <Row>
              <Col span={16}>
                <Form.Item name="unitId" label="Đơn vị" labelAlign="left">
                  <Selection
                    url={Endpoint.GET_TEAM_LIST_CODE}
                    formKey="unitId"
                    form={form}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item
                  name="reportDate"
                  label="Tháng/ năm"
                  labelAlign="left"
                >
                  <DatePickerComponent
                    picker="month"
                    formKey="reportDate"
                    form={form}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item
                  name="tong_cto_ktra"
                  label="Tổng số công tơ đã kiểm tra"
                  labelAlign="left"
                >
                  <InputNumber placeholder="Nhập tổng số công tơ đã kiểm tra" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item
                  name="tong_cto"
                  label="Tổng số công tơ theo kế hoạch"
                  labelAlign="left"
                >
                  <InputNumber placeholder="Nhập tổng số công tơ theo kế hoạch" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Cập nhật
              </Button>
            </Form.Item>
          </FormComponent>
          <Divider className="section-text-line" orientation="left"></Divider>
          <Row>
            <Col>
              <Space style={{ marginBottom: "1rem" }}>
                <Typography.Text>Năm: </Typography.Text>
                <DatePicker
                  picker="year"
                  defaultValue={moment(filterConditions?.Nam, "YYYY")}
                  onChange={(value) => handleChange(value)}
                />
              </Space>
            </Col>
          </Row>
          <Table
            header="Thông tin hiển thị"
            columns={columns}
            dataSource={dataTable}
            scroll={{ y: 420 }}
            pagination={false}
          />

          {visibleModalUpdate ? (
            <ModalSuaTHKiemTraDKCongTo
              detailTable={detailTable}
              visible={visibleModalUpdate}
              closeModal={handleCloseModal}
              getListData={getListData}
              detailDataTable
            />
          ) : null}
        </Spin>
      </Modal>
    </div>
  );
}
export default memo(ModalCapNhatThKiemTraDinhKy);
