import React from "react";
import { Button, Modal, Row, Col, Form, Input } from "antd";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { Container } from "./css-styled";
import { TYPE_TRA_HO_SO } from "@utils/constants";

const { TextArea } = Input;

export default function TraHoSoModal(props) {
  const {
    loading,
    title,
    visible,
    closeModal,
    form,
    onFinish,
    t,
    contentMessage,
    typeTraLai,
  } = props;
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={title}
        visible={visible}
        onCancel={closeModal}
        footer={null}
      >
        <FormComponent
          form={form}
          name="form-tra-doi"
          onFinish={onFinish}
          initialValues={{}}
          layout="vertical"
          autoComplete="off"
        >
          <Container>
            <p>
              {contentMessage
                ? contentMessage
                : "Hồ sơ sẽ được trả về cho Nhân viên khảo sát !"}
            </p>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label={t("nghiem_thu.ly_do_tra_lai")}
                  name="noiDungTraHoSo"
                  className={
                    typeTraLai !== TYPE_TRA_HO_SO.THI_CONG
                      ? "required-field"
                      : ""
                  }
                >
                  <TextArea rows={3} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} justify="center">
              <Col span={12} style={{ textAlign: "right" }}>
                <Button
                  key="back"
                  onClick={closeModal}
                  className="button-closed"
                >
                  {t("button.dong_lai")}
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  key="submit"
                  type="primary"
                  className="button-primary"
                  htmlType="submit"
                  form="form-tra-doi"
                >
                  {t("button.xac_nhan")}
                </Button>
              </Col>
            </Row>
          </Container>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}
