import React, { useCallback, useEffect } from "react";
import { Col, Form, Input, Row, Tooltip, Button, Select } from "antd";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { ITEM_STATUS } from "@utils/constants";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { useSelector } from "react-redux";
import { Endpoint } from "@utils/endpoint";
import FormComponent from "@components/Form";
export function ListFilter(props) {
  const { handleSearch, clearFilter, filterConditions } = props;
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  useEffect(() => {
    form.resetFields();
    clearFilter();
  }, []);

  const handleChangeUnit = useCallback(() => {
    form.setFieldsValue({
      teamId: undefined,
    });
  }, [form]);
  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      initialValues={{
        unitId: user.unitId,
      }}
      layout="vertical"
      autoComplete="off"
    >
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="unitId" label="Đơn vị">
            <Selection
              url={Endpoint.GET_UNIT_LIST}
              form={form}
              formKey="unitId"
              setValue={handleChangeUnit}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.unitId !== currentValues.unitId
            }
          >
            {({ getFieldValue }) => {
              if (getFieldValue("unitId")) {
                return (
                  <Form.Item name="teamId" label="Tổ/Đội">
                    <Selection
                      url={`${
                        Endpoint.GET_LIST_TEAM_USER_CLAIM
                      }?unitId=${getFieldValue("unitId")}`}
                      formKey="teamId"
                      form={form}
                      disabled={!getFieldValue("unitId")}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="teamId" label="Tổ/Đội">
                  <Select disabled />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="jobId" label="Vị trí công việc ">
            <Selection
              url={Endpoint.GET_VI_TRI_CONG_VIEC}
              form={form}
              formKey="jobId"
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="status" label="Trạng thái">
            <Selection url={ITEM_STATUS} form={form} formKey="status" />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="searchTerm" label="Tìm kiếm dữ liệu">
            <Input
              prefix={<SearchOutlined />}
              suffix={
                <Tooltip
                  title={
                    "Hỗ trợ tìm kiếm theo Tên đăng nhập, Tên đầy đủ, Mã nhân viên"
                  }
                >
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Button className="mr-24px" onClick={clear}>
          Bỏ lọc
        </Button>
        <Button type="primary" htmlType="submit" form="filter-form">
          Tìm kiếm
        </Button>
      </Row>
    </FormComponent>
  );
}
