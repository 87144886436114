import React, { useCallback, useState } from "react";
import { Tree, Spin, Tooltip, Modal } from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import styled from "styled-components";
import ButtonComponent from "@components/Button";
import { permission } from "@permissions/k_qtht";
import CheckPermission from "@components/CheckPermission";
import ModalCreateMenuItem from "./modal-create-menu-item";
import ModalEditMenuItem from "./modal-edit-menu-item";

const MenuList = ({
  moduleSelected,
  save,
  dataSource,
  setDataSource,
  loading,
}) => {
  const [visibleModalCreateMenuItem, setVisibleModalCreateMenuItem] =
    useState(false);
  const [visibleModalEditMenuItem, setVisibleModalEditMenuItem] =
    useState(false);
  const [parentMenuSelected, setParentMenuSelected] = useState(null);

  const handleOnDrop = useCallback(
    (info) => {
      const { dropToGap, node, dragNode } = info;
      const data = [...dataSource];
      if (dropToGap) {
        const loop = (data, permissionCode, callback) => {
          for (let i = 0; i < data.length; i++) {
            if (
              data[i].permissionCode === permissionCode &&
              dragNode.parentKey === data[i].parentKey
            ) {
              return callback(data[i], i, data);
            }
            if (data[i].children) {
              loop(data[i].children, permissionCode, callback);
            }
          }
        };

        let indexDropKey;
        let indexDragKey;
        loop(data, node.permissionCode, (item, index) => {
          indexDropKey = index;
        });

        let dragObj;
        loop(data, dragNode.permissionCode, (item, index, arr) => {
          if (node.parentKey === item.parentKey) {
            arr.splice(index, 1);
          }
          indexDragKey = index;
          dragObj = item;
        });
        let ar;
        let it;
        let i;
        loop(data, node.permissionCode, (item, index, arr) => {
          ar = arr;
          it = item;
          i = index;
        });

        if (
          dragObj &&
          typeof indexDragKey === "number" &&
          typeof indexDropKey === "number" &&
          it &&
          ar
        ) {
          if (it.parentKey === dragNode.parentKey) {
            if (indexDragKey - indexDropKey === -1) {
              ar.splice(i + 1, 0, dragNode);
            } else {
              ar.splice(indexDropKey, 0, dragNode);
            }
          }
        }
        setDataSource(data);
      }
    },
    [dataSource, setDataSource]
  );

  const handleOpenModalCreateMenuItem = useCallback(
    (data) => {
      setVisibleModalCreateMenuItem(!visibleModalCreateMenuItem);
      setParentMenuSelected(data);
    },
    [visibleModalCreateMenuItem]
  );

  const handleOpenModalEditMenuItem = useCallback(
    (data) => {
      setVisibleModalEditMenuItem(!visibleModalEditMenuItem);
      setParentMenuSelected(data);
    },
    [visibleModalEditMenuItem]
  );
  const handleDeleteMenuItem = useCallback(
    (item) => {
      Modal.confirm({
        centered: true,
        title: "Xác nhận",
        content: "Bạn có chắc chắn muốn xóa!",
        onOk() {
          const data = [...dataSource];
          const loop = (data, permissionCode, callback) => {
            for (let i = 0; i < data.length; i++) {
              if (data[i].permissionCode === permissionCode) {
                return callback(data[i], i, data);
              }
              if (data[i].children) {
                loop(data[i].children, permissionCode, callback);
              }
            }
          };
          loop(data, item.permissionCode, (item, index, arr) => {
            arr.splice(index, 1);
          });
          setDataSource(data);
          save(data);
        },
        onCancel() {},
        okText: "Đồng ý",
        okButtonProps: { type: "danger" },
        cancelText: "Hủy",
      });
    },
    [dataSource, setDataSource, save]
  );

  const addMenuItem = useCallback(
    (values) => {
      const data = [...dataSource];

      if (parentMenuSelected) {
        const loop = (data, permissionCode) => {
          for (let i = 0; i < data.length; i++) {
            if (data[i].permissionCode === permissionCode) {
              let maxValue = 0;
              if (data[i].children.length) {
                const arr = data[i].children.map((item) =>
                  parseInt(
                    item.permissionCode.replace(parentMenuSelected.key, "")
                  )
                );
                maxValue = Math.max(...arr);
              }
              data[i].children.push({
                name: values.menuName,
                pageId: values.pageId,
                isActive: parentMenuSelected.isActive,
                permissionCode: `${parentMenuSelected.key}${maxValue + 1}`,
                key: `${parentMenuSelected.key}${maxValue + 1}`,
                parentKey: parentMenuSelected.key,
                moduleId: moduleSelected,
                children: [],
              });
            }
            if (data[i].children) {
              loop(data[i].children, permissionCode);
            }
          }
        };
        loop(data, parentMenuSelected.permissionCode);
      } else {
        let maxValue = 0;
        if (data.length) {
          const arr = data.map((item) =>
            parseInt(item.permissionCode.replace(item.parentKey, ""))
          );
          maxValue = Math.max(...arr);
        }
        data.push({
          name: values.menuName,
          pageId: values.pageId,
          isActive: true,
          permissionCode: `${data[0].parentKey}${maxValue + 1}`,
          key: `${data[0].parentKey}${maxValue + 1}`,
          parentKey: data[0].parentKey,
          moduleId: moduleSelected,
          children: [],
        });
      }

      setDataSource(data);
      save(data);
    },
    [dataSource, parentMenuSelected, setDataSource, moduleSelected, save]
  );
  const updateMenuItem = useCallback(
    (values) => {
      const data = [...dataSource];
      const loop = (data, permissionCode) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].permissionCode === permissionCode) {
            data[i].pageId = values.pageId;
            data[i].name = values.menuName;
            data[i].isActive = values.isActive;
            if (!values.isActive && data[i].children.length) {
              const loop = (childrenData) => {
                for (let i = 0; i < childrenData.length; i++) {
                  childrenData[i].isActive = values.isActive;
                  if (childrenData[i].children) {
                    loop(childrenData[i].children);
                  }
                }
              };
              loop(data[i].children);
            }
          }
          if (data[i].children) {
            loop(data[i].children, permissionCode);
          }
        }
      };
      loop(data, parentMenuSelected.permissionCode);
      setDataSource(data);
      save(data);
    },
    [dataSource, parentMenuSelected, setDataSource, save]
  );

  const checkMenuPosition = useCallback(
    (key) => {
      const data = [...dataSource];
      let level = 1;
      const loop = (data, key, level, callback) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].permissionCode === key) {
            return callback(level);
          }
          if (data[i].children) {
            loop(data[i].children, key, level + 1, callback);
          }
        }
      };
      loop(data, key, level, (lv) => {
        level = lv;
      });
      if (level < 3) {
        return true;
      }
      return false;
    },
    [dataSource]
  );
  const renderContent = useCallback(() => {
    if (!moduleSelected) {
      return (
        <div className="text-center mt-24px font-bold">Lựa chọn module</div>
      );
    } else {
      if (loading) {
        return (
          <div className="text-center mt-24px">
            <Spin tip="Đang tải..." />
          </div>
        );
      } else if (dataSource) {
        return (
          <div className="mt-24px">
            <Tree
              treeData={dataSource}
              draggable
              onDrop={handleOnDrop}
              showLeafIcon={true}
              fieldNames={{
                key: "id",
                permissionCode: "key",
              }}
              showLine={true}
              titleRender={(nodeData) => {
                const showBtnAdd = checkMenuPosition(nodeData.key);
                return (
                  <div className="action-tree">
                    <span
                      className={`mr-5px ${
                        nodeData.isActive ? "active-color" : "unactive-color"
                      }`}
                    >
                      {`${nodeData.name}`}
                    </span>
                    <div className="action-table-column">
                      {showBtnAdd && (
                        <CheckPermission permissionCode={permission.k3_tao}>
                          <Tooltip title={"Thêm"}>
                            <ButtonComponent
                              type="link"
                              onClick={() =>
                                handleOpenModalCreateMenuItem(nodeData)
                              }
                              className="pd-none mr-5px"
                            >
                              <PlusOutlined />
                            </ButtonComponent>
                          </Tooltip>
                        </CheckPermission>
                      )}

                      <CheckPermission permissionCode={permission.k3_sua}>
                        <Tooltip title={"Sửa"}>
                          <ButtonComponent
                            type="link"
                            onClick={() =>
                              handleOpenModalEditMenuItem(nodeData)
                            }
                            className="pd-none mr-5px"
                          >
                            <EditOutlined />
                          </ButtonComponent>
                        </Tooltip>
                      </CheckPermission>
                      <CheckPermission permissionCode={permission.k3_xoa}>
                        <Tooltip title={"Xóa"}>
                          <ButtonComponent
                            type="link"
                            onClick={() => handleDeleteMenuItem(nodeData)}
                            className="pd-none"
                          >
                            <DeleteOutlined style={{ color: "red" }} />
                          </ButtonComponent>
                        </Tooltip>
                      </CheckPermission>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        );
      }
      return null;
    }
  }, [
    loading,
    moduleSelected,
    dataSource,
    handleOnDrop,
    handleOpenModalCreateMenuItem,
    handleOpenModalEditMenuItem,
    checkMenuPosition,
    handleDeleteMenuItem,
  ]);
  return (
    <Container>
      {renderContent()}
      {moduleSelected && (
        <Tooltip title={"Thêm menu cấp 1"}>
          <ButtonComponent
            type="dashed"
            size="small"
            onClick={() => handleOpenModalCreateMenuItem()}
            style={{ width: 200, marginTop: 10 }}
          >
            <PlusOutlined />
          </ButtonComponent>
        </Tooltip>
      )}
      {visibleModalCreateMenuItem && (
        <ModalCreateMenuItem
          visible={visibleModalCreateMenuItem}
          close={handleOpenModalCreateMenuItem}
          moduleSelected={moduleSelected}
          addMenuItem={addMenuItem}
        />
      )}
      {visibleModalEditMenuItem && (
        <ModalEditMenuItem
          visible={visibleModalEditMenuItem}
          close={handleOpenModalEditMenuItem}
          data={parentMenuSelected}
          moduleSelected={moduleSelected}
          updateMenuItem={updateMenuItem}
          dataSource={dataSource}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  .action-tree {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export default MenuList;
