import { memo, useState } from "react";
import { Form, Button, Modal, Row, Col, Input, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import FormComponent from "@components/Form";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import LoadingComponent from "@components/Loading";
import { handleSubmitForm } from "./services";

const { TextArea } = Input;
function TrangThaiModal({ detaiDataTable, visible, closeModal, getListData }) {
  const detailColor =
    detaiDataTable.mau !== undefined ? detaiDataTable.mau : "";
  const [color, setColor] = useColor("hex", detailColor);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(detaiDataTable.displayFlag);

  const handleSubmitData = (values) => {
    const data = {
      ...values,
      displayFlag: isActive ? isActive : false,
      displayOrder: parseInt(values.displayOrder) ? values.displayOrder : 0,
    };

    handleSubmitForm(
      data,
      detaiDataTable,
      setLoading,
      closeModal,
      getListData,
      form,
      color
    );
  };
  // thay đổi màu

  const handleColor = (color) => {
    setColor(color);
  };

  const handleChangeActive = (e) => {
    setIsActive(e.target.checked);
  };

  //Handle Số thứ tự
  const handleSoThuTu = (e) => {
    if (e.target.value === "")
      form.setFieldsValue({
        displayOrder: 0,
      });
  };

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={"Cập nhật trạng thái"}
        visible={visible}
        onCancel={closeModal}
        className="modal-trang-thai"
        footer={null}
      >
        <FormComponent
          autoComplete="off"
          form={form}
          name="form-trang-thai"
          layout="vertical"
          onFinish={handleSubmitData}
          initialValues={{
            ...detaiDataTable,
          }}
          scrollToFirstError
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label={t("trang_thai.ten_trang_thai")}
                name="tenTrangThai"
                className="required-field"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t("trang_thai.mau")} name="mau">
                <ColorPicker
                  width={456}
                  height={228}
                  color={color}
                  onChange={handleColor}
                  hideHSV
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="displayOrder"
                label="Thứ tự hiển thị"
                labelAlign="left"
              >
                <Input
                  type="number"
                  min={0}
                  onChange={handleSoThuTu}
                  placeholder="Nhập số thứ tự"
                />
              </Form.Item>
            </Col>
            <Col span={8} style={{ display: "flex", alignItems: "center" }}>
              <Form.Item
                label=""
                name="displayFlag"
                style={{ marginBottom: "0" }}
              >
                <Checkbox onChange={handleChangeActive} checked={isActive}>
                  Hiển thị
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8}></Col>
            <Col span={24}>
              <Form.Item
                label={t("trang_thai.mo_ta")}
                name="moTaTrangThai"
                className="required-field"
              >
                <TextArea row={4} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-trang-thai"
                loading={loading}
              >
                {t("button.cap_nhat")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}

export default memo(TrangThaiModal);
