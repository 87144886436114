import { authSelector } from "@state/auth";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import FormBoLoc from "./form-bo-loc";

export function ListFilter(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    donVi,
    loaiBangKe,
    locale,
    handleChangeThangLV,
    form,
  } = props;
  const { user } = useSelector(authSelector);

  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);
  return (
    <FormBoLoc
      form={form}
      user={user}
      handleSearch={handleSearch}
      clear={clear}
      donVi={donVi}
      filterConditions={filterConditions}
      loaiBangKe={loaiBangKe}
      handleChangeThangLV={handleChangeThangLV}
      locale={locale}
    />
  );
}
