import React, { Fragment, useEffect, useState } from "react";
import { Viewer, Worker, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { ContainerModal } from "./css-styled";
import vi_VN from "@react-pdf-viewer/locales/lib/vi_VN.json";
import { Button, Progress, Result, Tooltip, Skeleton } from "antd";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/properties/lib/styles/index.css";
import { FileExcelOutlined, ReloadOutlined } from "@ant-design/icons";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import { stringToSlug } from "@utils/function";

export const PDFModal = (props) => {
  const {
    visible,
    setVisible,
    title,
    loadingDownload,
    loadingGetBienBan,
    isDownloadExcel,
    isReload,
    hasError,
    type,
  } = props;
  const [urlPDF, setUrlPDF] = useState("");
  const [titleModal, setTitle] = useState("");

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (Toolbar) => {
      return Toolbar({
        children({
          CurrentPageInput,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Print,
          Zoom,
          ZoomIn,
          ZoomOut,
        }) {
          return (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                width: "100%",
                flexWrap: "nowrap",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              {/* <div style={{ padding: "0px 2px" }}>
            <ShowSearchPopover />
          </div> */}
              <div style={{ padding: "0px 2px" }}>
                <ZoomOut />
              </div>
              <div style={{ padding: "0px 8px" }}>
                <Zoom />
              </div>
              <div style={{ padding: "0px 2px" }}>
                <ZoomIn />
              </div>
              <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                <GoToPreviousPage />
              </div>
              <div style={{ padding: "0px 2px", width: "4rem" }}>
                <CurrentPageInput />
              </div>
              <div style={{ padding: "0px 2px" }}>
                / <NumberOfPages />
              </div>
              <div style={{ padding: "0px 2px" }}>
                <GoToNextPage />
              </div>
              <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                <EnterFullScreen />
              </div>
              <div style={{ padding: "0px 2px" }}>
                <DownloadButton />
              </div>

              {isDownloadExcel ? (
                <div style={{ padding: "0px 2px" }}>
                  <Tooltip title="Tải về định dạng Excel">
                    <Button
                      type="text"
                      icon={<FileExcelOutlined />}
                      onClick={() => props.downloadFileExcel()}
                      loading={loadingDownload}
                    />
                  </Tooltip>
                </div>
              ) : null}
              <div style={{ padding: "0px 2px " }}>
                <Print />
              </div>
              {isReload ? (
                <div style={{ padding: "0px 2px" }}>
                  <Tooltip title="Làm mới biên bản">
                    <Button
                      type="text"
                      icon={<ReloadOutlined />}
                      onClick={() => props.getBienBan(setUrlPDF)}
                      loading={loadingGetBienBan}
                    />
                  </Tooltip>
                </div>
              ) : null}
            </div>
          );
        },
      });
    },
  });

  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: () => {
      // `file.name` is the URL of opened file
      const fileName = stringToSlug(title).toUpperCase();
      return `${fileName}`;
    },
  });

  const { DownloadButton } = getFilePluginInstance;

  useEffect(() => {
    props.getBienBan(setUrlPDF);
    if (type) {
      setTitle(`Xem trước ${String(type).toLowerCase()}`);
    } else {
      setTitle("Xem trước biên bản");
    }
  }, [props, type]);

  return (
    <Fragment>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
        <ContainerModal
          title={titleModal}
          visible={visible}
          onCancel={() => setVisible(false)}
          style={{ top: "4px", bottom: 0 }}
          footer={null}
          destroyOnClose={true}
        >
          {urlPDF ? (
            <Viewer
              fileUrl={urlPDF}
              plugins={[defaultLayoutPluginInstance, getFilePluginInstance]}
              style={{ padding: 0 }}
              localization={vi_VN}
              renderError={() => {
                // props.loadError()
                return (
                  <Result
                    status="500"
                    title="Không tìm thấy dữ liệu"
                    subTitle="Vui lòng thử lại sau."
                  />
                );
              }}
              renderLoader={(percentages) => (
                <Progress type="circle" percent={Math.round(percentages)} />
              )}
              defaultScale={SpecialZoomLevel.PageWidth}
            />
          ) : (
            <Fragment>
              {hasError ? (
                <Result
                  status="500"
                  title="Không tìm thấy dữ liệu"
                  subTitle="Vui lòng thử lại sau."
                />
              ) : (
                <Fragment>
                  <Skeleton
                    active
                    size="large"
                    style={{ padding: 10 }}
                    paragraph={{ rows: 8 }}
                  />
                </Fragment>
              )}
            </Fragment>
          )}
        </ContainerModal>
      </Worker>
    </Fragment>
  );
};
