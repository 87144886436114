import Filter from "@components/Filter/Filter";
import { buildQueryString, parseParams } from "@utils/function";
import { Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import QuanLyLoTrinhFilter from "./quan-li-lo-trinh-list-bo-loc";
import moment from "moment";
import { dateConditionLogic } from "@modules/lo-trinh-nhan-vien/functions";
import { getNhanVien, getTrangThaiNhanVien } from "./quan-ly-lo-trinh-services";
import { XacDinhNhanVienOnline } from "./modal/xac-dinh-nhan-vien";
import styled from "styled-components";
import MapComponent from "./modal/map-component";

const QuanLyLoTrinh = () => {
  const unitId = useSelector((state) => state.auth.user.unitId);
  const teamId = useSelector((state) => state.auth.user.teamId);
  const [form] = Form.useForm();
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState();
  const [filterConditions] = useState({
    DonViId: unitId,
    DoiId: teamId,
    TuNgay: `${moment().startOf("month").format("YYYY-MM-DD")}`,
    DenNgay: `${moment().endOf("month").format("YYYY-MM-DD")}`,
    ...parseParams(location.search),
  });
  const [donVi, setDonVi] = useState(filterConditions.DonViId);
  const [doiId, setDoiId] = useState(filterConditions.DoiId);
  const [trangThaiNhanVien, setTrangThaiNhanVien] = useState([]);
  const [startDate, setStartDate] = useState(filterConditions.TuNgay);
  const [endDate, setEndDate] = useState(filterConditions.DenNgay);
  const [loTrinhNhanVien, setLoTrinhNhanVien] = useState([]);

  useEffect(() => {
    const payload = {
      ...filterConditions,
    };

    const query = buildQueryString(payload);
    getNhanVien(setLoading, setLoTrinhNhanVien, query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const payload = {
      DonViId: donVi,
      DoiId: doiId,
    };

    const query = buildQueryString(payload);
    getTrangThaiNhanVien(setLoading, setTrangThaiNhanVien, query);
  }, [doiId, donVi]);

  // Bo-loc
  const handleSearch = (values) => {
    const ojbStartDate = new Date(values.TuNgay);
    const objEndDate = new Date(values.DenNgay);
    const dateCondition = dateConditionLogic(ojbStartDate, objEndDate);

    form.setFieldsValue({
      TuNgay: moment(dateCondition.formatStartDate),
      DenNgay: moment(dateCondition.formatEndDate),
    });

    const payload = {
      ...(values.DonViId && { DonViId: values.DonViId }),
      ...(values.DoiId && { DoiId: values.DoiId }),
      ...(values.NhanVien && { NhanVien: values.NhanVien }),
      TuNgay: dateCondition.formatStartDate,
      DenNgay: dateCondition.formatEndDate,
    };

    setSearchParams(payload);
    const query = buildQueryString(payload);

    getNhanVien(setLoading, setLoTrinhNhanVien, query);
  };

  const clearFilter = () => {
    const payload = {
      ...(unitId && { DonViId: unitId }),
      ...(teamId && { DoiId: teamId }),
      TuNgay: `${moment().startOf("month").format("YYYY-MM-DD")}`,
      DenNgay: `${moment().endOf("month").format("YYYY-MM-DD")}`,
    };
    setSearchParams(payload);
    const query = buildQueryString(payload);
    getNhanVien(setLoading, setLoTrinhNhanVien, query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  return (
    <>
      <Row>
        <Filter>
          <QuanLyLoTrinhFilter
            filterConditions={filterConditions}
            handleSearch={handleSearch}
            form={form}
            clearFilter={clearFilter}
            donVi={donVi}
            setDonVi={setDonVi}
            doiId={doiId}
            setDoiId={setDoiId}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setTrangThaiNhanVien={setTrangThaiNhanVien}
          />
        </Filter>
      </Row>
      <Row>
        <Col span={5}>
          <XacDinhNhanVienOnline listNhanVien={trangThaiNhanVien} />
        </Col>

        <Col span={19}>
          <MapWrapper>
            <MapComponent loTrinhNhanVien={loTrinhNhanVien} loading={loading} />
          </MapWrapper>
        </Col>
      </Row>
    </>
  );
};

export default QuanLyLoTrinh;
const MapWrapper = styled.div`
  height: calc(104vh - 80px);
  width: 100%;
  text-align: center;
`;
