import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  getErrorMessage,
  parseParams,
} from "@utils/function";
import { authDeleteData, authGetData, authPostData } from "@utils/request";

export const handleListData = (
  filterConditions,
  setLoading,
  setData,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.LIST_CAU_HINH_HO_SO}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setData(res.data);
        setTotalCount(res.paging.totalCount);
      } else setData([]);
    },
  });
};

export const handleListDataTime = (
  params,
  setLoading,
  setDataTime,
  setPaging
) => {
  authGetData({
    url: `${Endpoint.LIST_THOI_GIAN_HIEN_LUC_CAU_HINH_HO_SO}?HoSoCauHinhId=${params.id}&PageIndex=${params.pageIndex}&PageSize=1`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null && res.statusCode === STATUSCODE_200) {
        setDataTime({
          ...res.data[0],
          ngayHetHieuLuc:
            res.data[0].ngayHetHieuLuc === null ||
            res.data[0].ngayHetHieuLuc === undefined
              ? undefined
              : res.data[0].ngayHetHieuLuc,
        });
        // const ngayBatDau = res.data[0]?.ngayHieuLucString
        //   .split("/")
        //   .reverse()
        //   .join("-");
        // const ngayKetThuc =
        //   res.data[0]?.ngayHetHieuLucString === null
        //     ? undefined
        //     : res.data[0]?.ngayHetHieuLucString.split("/").reverse().join("-");
        // setNgayBatDau(ngayBatDau);
        // setNgayKetThuc(ngayKetThuc);
        setPaging(res.paging);
      }
    },
  });
};

const handleCreateData = (data, setLoading, closeModal, getListData, form) => {
  authPostData({
    url: Endpoint.CREATE_CAU_HINH_HO_SO,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        closeModal();
        getListData();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

const handleUpdateData = (data, setLoading, closeModal, getListData, form) => {
  authPostData({
    url: Endpoint.UPDATE_CAU_HINH_HO_SO,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListData();
        closeModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleRemoveData = (ids, setLoading, getListData) => {
  authDeleteData({
    url: Endpoint.REMOVE_CAU_HINH_HO_SO + "?Ids=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) getListData();
    },
  });
};

export const handleRemoveListTime = (id, setLoading, handleListTime) => {
  authPostData({
    url: Endpoint.REMOVE_THOI_GIAN_HIEN_LUC_CAU_HINH_HO_SO,
    method: METHOD_PUT,
    payload: {
      id: id,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        handleListTime();
      }
    },
  });
};

export const handleSubmitForm = (
  values,
  detail,
  setLoading,
  closeModal,
  getListData,
  form
) => {
  const data = {
    ...values,
  };

  if (detail.id) {
    data.id = detail.id;
  }

  handleCreateUpdate(data, setLoading, closeModal, getListData, form);
};

const handleCreateUpdate = (
  data,
  setLoading,
  closeModal,
  getListData,
  form
) => {
  if (data.id) {
    //sửa dữ liệu

    handleUpdateData(data, setLoading, closeModal, getListData, form);
  } else {
    //tạo dữ liệu

    handleCreateData(data, setLoading, closeModal, getListData, form);
  }
};
