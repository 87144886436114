import { QUAN_LY_LOG, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";

export const getChiTiet = ({setLoading, setData, activeKey, id, filterConditions}) => {
  let url;
  if (activeKey === QUAN_LY_LOG.NOI_BO) {
    url = `${Endpoint.LOG_INTERNAL_DETAIL}?Id=${id}&&moduleCode=${filterConditions?.moduleCode}`;
  } else if (activeKey === QUAN_LY_LOG.HE_THONG_NGOAI) {
    url = `${Endpoint.LOG_EXTERNAL_DETAIL}?Id=${id}&&moduleCode=${filterConditions?.moduleCode}`;
  } else if (activeKey === QUAN_LY_LOG.HE_THONG) {
    url = `${Endpoint.LOG_ERROR_DETAIL}?Id=${id}&&moduleCode=${filterConditions?.moduleCode}`;
  }
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getListModule = (setData) => {
  authGetData({
    url: Endpoint.GET_LIST_MODULE,
    setLoading: () => {},
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getListDataInternal = (
  setLoading,
  setData,
  filterConditions,
  setTotal,
  query
) => {
  authGetData({
    url: `${Endpoint.LOG_INTERNAL_LIST}?${query}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              STT:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            };
          })
        );
        setTotal(res.paging.totalCount);
      }
    },
  });
};

export const getListDataExternal = (
  setLoading,
  setData,
  filterConditions,
  setTotal,
  query
) => {
  authGetData({
    url: `${Endpoint.LOG_EXTERNAL_LIST}?${query}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              STT:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            };
          })
        );
        setTotal(res.paging.totalCount);
      }
    },
  });
};

export const getListDataError = (
  setLoading,
  setData,
  filterConditions,
  setTotal,
  query
) => {
  authGetData({
    url: `${Endpoint.LOG_ERROR}?${query}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              STT:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            };
          })
        );
        setTotal(res.paging.totalCount);
      }
    },
  });
};
