import { buildQueryString, parseParams } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData} from "@utils/request";
import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { alertMessage } from "@utils/function";

export const handleListData = (
  location,
  filterConditions,
  setLoading,
  setDataDaySoCmis,
  setTempDataDaySoCmis,
  setTotalCount,
  setTempTotalCount,
  setWidthTacVu
) => {
  let url = "";
  url = `${Endpoint.LIST_DAY_SO_VE_CMIS}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        const temp = [];
        for (let i = 0; i< res.data?.length; i++) {
          temp.push(res.data[i].danhSachBangKe?.length)
        }
        setWidthTacVu(temp.length === 0 ? 0 : Math.max(...temp));
        setDataDaySoCmis(res.data);
        setTempDataDaySoCmis(res.data);
        setTotalCount(res.paging.totalCount);
        setTempTotalCount(res.paging.totalCount);
      } else setDataDaySoCmis([]);
    },
  });
};
export const submitTraSoCMIS = (
  t,
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.DAY_SO_VE_CMIS,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys.toString(),
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};
export const submitTraLaiBK = (id, setLoading, setFetchDataTable, t) => {
  authPostData({
    url: Endpoint.TRA_LAI_BK_CHO_PKD,
    method: METHOD_PUT,
    payload: {
      id: id,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const submitHuyTraSoCMIS = (
  t,
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.HUY_DAY_SO_VE_CMIS,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys.toString(),
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};