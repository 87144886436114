import { createSlice } from "@reduxjs/toolkit";
export const namespace = "lapPhuongAn";

const INITAL_STATE = {
  phuongAnCapDien: false,
  thongTinCoBan: false,
  inTab: false,
  canShowDialogLeavingPage: false,
  tongChiPhi: undefined,
  tongChiPhiChuaVAT: undefined,
  isChangeHeSoDongThoi: false,
  loadingTinhChiPhi: false,
  hadChangeHTLD: false,
};

const slice = createSlice({
  name: namespace,
  initialState: INITAL_STATE,
  reducers: {
    notSavedPhuongAnCapDien: (state, action) => {
      return {
        ...state,
        phuongAnCapDien: action.payload,
      };
    },
    notSavedThongTinCoBan: (state, action) => {
      return {
        ...state,
        thongTinCoBan: action.payload,
      };
    },
    rangeOfChange: (state, action) => {
      return {
        ...state,
        inTab: action.payload,
      };
    },
    setCanShowDialogLeavingPACD: (state, action) => {
      return {
        ...state,
        canShowDialogLeavingPage: action.payload,
      };
    },
    updateTongChiPhi: (state, action) => {
      return {
        ...state,
        tongChiPhi: action.payload?.tongChiPhi,
        tongChiPhiChuaVAT: action.payload?.tongChiPhiChuaVAT,
      };
    },
    setLoadingTinhChiPhi: (state, action) => {
      return {
        ...state,
        loadingTinhChiPhi: action.payload,
      };
    },
    changedHeSoDongThoi: (state, action) => {
      return {
        ...state,
        isChangeHeSoDongThoi: action.payload,
      };
    },
    changeHinhThucLapDat: (state, action) => {
      return {
        ...state,
        hadChangeHTLD: action.payload,
      };
    },
  },
});

export const reducer = slice.reducer;

export const {
  notSavedPhuongAnCapDien,
  notSavedThongTinCoBan,
  rangeOfChange,
  setCanShowDialogLeavingPACD,
  updateTongChiPhi,
  changedHeSoDongThoi,
  setLoadingTinhChiPhi,
  changeHinhThucLapDat,
} = slice.actions;

export const lapPhuongAnSelector = (state) => state[namespace];
