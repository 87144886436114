import FormComponent from "@components/Form";
import { buildQueryString, parseParams, removeAccents } from "@utils/function";
import { Col, DatePicker, Empty, Form, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { tongSoLuongDonViDangKy } from "../common/functions";
import { Style } from "./css-styled";
import BarChartComponent from "./models-chart/bar-chart-component";
import BieuDoTheoDonViQuanLy from "./models-chart/bieu-do-theo-don-vi-quan-ly";
import BieuDoThongKeThanhToan from "./models-chart/bieu-do-thong-ke-thanh-toan.";
import {
  getListDonVi,
  getListDonViDangKyThanhToan,
  getListNganHang,
} from "./services";
function DashBoard() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState([]);
  const [listDonVi, setListDonVi] = useState([]);
  const [listNganHang, setListNganHang] = useState([]);
  // const [listTeam, setListTeam] = useState([]);
  const unitId = useSelector((state) => state.auth.user.unitId);
  const isTongCongTy = useSelector((state) => state.auth.user.isTongCongTy);
  // const teamId = useSelector((state) => state.auth.user.teamId);

  const [, setSearchParams] = useSearchParams();
  const { RangePicker } = DatePicker;
  const location = useLocation();
  const [date, setDate] = useState();
  const [listDonViDangKy, setListDonViDangKy] = useState([]);
  const [filterConditions] = useState({
    TuNgay: moment().startOf("month").format("YYYY-MM-DD"),
    DenNgay: moment().endOf("month").format("YYYY-MM-DD"),
    DonViId: unitId,
    ...parseParams(location.search),
  });
  const [valueDonVi, setValueDonVi] = useState(filterConditions.DonViId);
  // const [valueTeam, setValueTeam] = useState(teamId);

  const tongDonViDangKy = tongSoLuongDonViDangKy(listDonViDangKy);

  const nganHangHeader =
    "Số lượng khách hàng thanh toán theo ngân hàng và ví điện tử";

  useEffect(() => {
    getListDonVi(setLoading, setListDonVi);
  }, []);
  useEffect(() => {
    const payload = {
      TuNgay:
        date?.length > 0
          ? date[0].format("YYYY-MM-DD")
          : filterConditions.TuNgay,
      DenNgay:
        date?.length > 0
          ? date[1].format("YYYY-MM-DD")
          : filterConditions.DenNgay,
      DonViId: valueDonVi ? valueDonVi : unitId,
    };
    console.log("payload", payload);
    const query = buildQueryString(payload);
    getListNganHang(setLoading, setListNganHang, query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, valueDonVi]);

  const handleChangeDonVi = (value) => {
    setValueDonVi(value);
  };

  useEffect(() => {
    const payload = {
      DonViId: valueDonVi,
      // ...(valueTeam && { DoiId: valueTeam }),
      TuNgay:
        date?.length > 0
          ? date[0].format("YYYY-MM-DD")
          : filterConditions.TuNgay,
      DenNgay:
        date?.length > 0
          ? date[1].format("YYYY-MM-DD")
          : filterConditions.DenNgay,
    };

    const query = buildQueryString(payload);
    setSearchParams(query);
    // if (isTongCongTy || (!isTongCongTy && valueTeam)) {
    getListDonViDangKyThanhToan(setLoading, setListDonViDangKy, query);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    valueDonVi,
    date,
    filterConditions.TuNgay,
    filterConditions.DenNgay,
    // valueTeam,
  ]);

  const handleChange = (dates) => {
    setDate(dates);
  };

  const patchValueToDateRange = () => {
    if (filterConditions.TuNgay && filterConditions.DenNgay) {
      const dateRange = [
        moment(new Date(filterConditions.TuNgay)),
        moment(new Date(filterConditions.DenNgay)),
      ];
      return dateRange;
    } else {
      return [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ];
    }
  };

  // const handleChangeDoi = (value) => {
  //   setValueTeam(value);
  // };

  // useEffect(() => {
  //   if (!isTongCongTy) {
  //     getListTeam(setLoading, setListTeam, unitId, form, setValueTeam);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Style>
      <FormComponent
        form={form}
        initialValues={{
          DonViId: valueDonVi,
          thoiGian: patchValueToDateRange(),
          // DoiId: teamId,
        }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="DonViId" label="Đơn vị">
              <Select
                allowClear={listDonVi.length > 1 ? true : false}
                loading={loading}
                onChange={handleChangeDonVi}
                placeholder={"Tất cả"}
                showSearch
                disabled={!isTongCongTy}
                filterOption={(input, option) => {
                  if (option && option.children) {
                    return (
                      removeAccents(option.children)
                        .toLowerCase()
                        .indexOf(removeAccents(input).toLowerCase()) >= 0
                    );
                  }
                }}
              >
                {listDonVi &&
                  listDonVi.map((item, id) => {
                    return (
                      <Select.Option key={id} value={item.value}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="thoiGian" label="Thời gian">
              <RangePicker
                format={"DD/MM/YYYY"}
                onChange={handleChange}
                allowClear={false}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 24]}>
          <Col span={10}>
            <BieuDoThongKeThanhToan data={listNganHang} />
          </Col>
          <Col span={14}>
            <div
              className="bieu-do-theo-don-vi-quan-ly"
              style={{ height: "100%" }}
            >
              <h2>Biểu đồ top số lượng ngân hàng và ví theo đơn vị quản lý</h2>
              {listDonViDangKy.length > 0 ? (
                listDonViDangKy.map((donViDangKy, index) => (
                  <BieuDoTheoDonViQuanLy
                    key={index}
                    tenDonVi={donViDangKy.tenDonVi}
                    total={tongDonViDangKy}
                    quantity={donViDangKy.soLuong}
                  />
                ))
              ) : (
                <Empty />
              )}
            </div>
          </Col>
          <Col span={24}>
            <BarChartComponent
              headerText={nganHangHeader}
              data={listNganHang}
            />
          </Col>
        </Row>
      </FormComponent>
    </Style>
  );
}

export default DashBoard;
