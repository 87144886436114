import { Pie } from "@ant-design/plots";
import { Empty } from "antd";
import React from "react";

function BieuDoThongKeThanhToan(props) {
  const { data } = props;
  // const nganLuong = data.filter((item) => item.loaiDichVu === "Ngân Lượng");
  // const viMo = data.filter((item) => item.loaiDichVu === "VIMO");
  const config = {
    appendPadding: 10,
    data,
    angleField: "soLuong",
    colorField: "loaiDichVu",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  return (
    <div className="bieu-do-thong-ke-thanh-toan">
      <h2>Biểu đồ thống kê dịch vụ thanh toán</h2>
      {data.length > 0 ? (
        <>
          {/* <Row gutter={24}>
            <Col span={12}>
              <span>
                {nganLuong.length === 0 ? " " : nganLuong[0]?.loaiDichVu}
              </span>
              <span>
                {nganLuong.length === 0 ? " " : nganLuong[0]?.soLuong}
              </span>
            </Col>
            <Col span={12}>
              <span>{viMo.length === 0 ? " " : viMo[0].loaiDichVu}</span>
              <span>{viMo.length === 0 ? " " : viMo[0].soLuong}</span>
            </Col>
          </Row> */}
          <div className="bieu-do-thong-ke-thanh-toan-chart">
            <Pie {...config} />
          </div>
        </>
      ) : (
        <Empty />
      )}
    </div>
  );
}

export default BieuDoThongKeThanhToan;
