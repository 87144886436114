import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useMemo,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import Filter from "@components/Filter/Filter";
import { Container } from "./css-styled";
import TableComponent from "@components/TableComponent";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilterQLChungThu } from "./list-bo-loc";
import { Button, Image } from "antd";
import QLChungThuModal from "./modal-ql-chung-thu";
import { handleListDataQLChungThu, handleRemoveChungThu } from "./services";
import { DEFAULT_PAGESIZE, DEFAULT_PAGEINDEX } from "@utils/constants";
import CheckPermission from "@components/CheckPermission";
import { tien_ich_chung } from "@permissions/m_tienichchung";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";

export default function Index() {
  const { t } = useTranslation();
  const { user } = useSelector(authSelector);
  const [dataQLChungThu, setDataQLChungThu] = useState([]);
  const [detailChungThu, setDetailChungThu] = useState({});
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    DonViId: user.unitId,
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu
  const getListDataChungThu = useCallback(() => {
    handleListDataQLChungThu(
      setLoading,
      location,
      filterConditions,
      setDataQLChungThu,
      setTotalCount
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getListDataChungThu();
  }, [getListDataChungThu]);

  //pagination table
  const onChangePagination = (paging, _filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //permission button them moi
  const genExtra = () => (
    <div>
      <CheckPermission permissionCode={tien_ich_chung.m125tm}>
        <Button
          size="default"
          type="primary"
          className="mr-5px"
          onClick={() => handleOpenModal()}
        >
          {t("button.tao_moi")}
        </Button>
      </CheckPermission>
    </div>
  );

  //tìm kiếm dữ liệu
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
      TenNguoiDung: values.TenNguoiDung === undefined ? values.TenNguoiDung : values.TenNguoiDung.trim(),
      SoSerial: values.SoSerial === undefined ? values.SoSerial : values.SoSerial.trim(),
    }));
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  //hiển thị popup
  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDetailChungThu(row);
      else setDetailChungThu({});
      setVisible(!visible);
    },
    [visible]
  );

  //đóng popup
  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
  };

  //render popup
  const showModal = useMemo(
    () =>
      visible && (
        <QLChungThuModal
          detailDataTable={detailChungThu}
          visible={visible}
          closeModal={handleCloseModal}
          getListDataChungThu={getListDataChungThu}
        />
      ),
    [visible, detailChungThu, getListDataChungThu]
  );

  const columns = [
    {
      title: t("ql_chung_thu.ma_don_vi_quan_ly"),
      dataIndex: "maDonVi",
      key: "maDonVi",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "13%",
      sorter: true,
      fixed: "left",
    },
    {
      title: t("ql_chung_thu.ten_tai_khoan"),
      dataIndex: "tenDangNhap",
      key: "tenDangNhap",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
      fixed: "left",
    },
    {
      title: t("ql_chung_thu.nhan_vien"),
      dataIndex: "tenNhanVien",
      key: "tenNhanVien",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: t("ql_chung_thu.chuc_vu"),
      dataIndex: "vaiTro",
      key: "vaiTro",
      render: (text) => <span>{text}</span>,
      width: "10%",
      sorter: true,
    },
    {
      title: t("ql_chung_thu.so_serial"),
      dataIndex: "soSerial",
      key: "soSerial",
      render: (text) => <span>{text}</span>,
      width: "10%",
    },
    {
      title: t("ql_chung_thu.ngay_hieu_luc"),
      dataIndex: "ngayBatDau",
      key: "ngayBatDau",
      className: "text-center",
      render: (text) => <span>{text}</span>,
      width: "10%",
    },
    {
      title: t("ql_chung_thu.ngay_het_hieu_luc"),
      dataIndex: "ngayKetThuc",
      key: "ngayKetThuc",
      className: "text-center",
      render: (text) => <span>{text}</span>,
      width: "10%",
    },
    {
      title: t("ql_chung_thu.trang_thai"),
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Chữ ký",
      dataIndex: "chuKy",
      key: "chuKy",
      render: (_text, record) => (
        record.chuKy === null ? record.chuKy :
          <Image
            preview={false}
            src={record.chuKy}
            alt={"image"}
            width={"100%"}
            height={"200px"}
            style={{ objectFit: "cover" }}
            // onClick={() => handleOpenImage(record.anhGCS)}
          />
      ),
      width: "15%",
    },
    {
      title: t("ql_chung_thu.tac_vu"),
      dataIndex: "",
      key: "tacVu",
      render: (_, record) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={tien_ich_chung.m125s}>
            {/* <Button
              type="primary" 
              onClick={() => handleOpenModal(record)}
              style={{marginRight: 8}}
            >
              Sửa
            </Button> */}
            <div className="action-icon" onClick={() => handleOpenModal(record)}>
              <PencilIcon />
            </div>
          </CheckPermission>
          <CheckPermission permissionCode={tien_ich_chung.m125x}>
            {/* <Button
              type="danger"
              onClick={() => handleDeleteChungThu(record.id)}
            >
              Xóa
            </Button> */}
            <div
              className="action-icon"
              onClick={() => handleDeleteChungThu(record.id)}
            >
              <TrashIcon />
            </div>
          </CheckPermission>
        </div>
      ),
      width: "10%",
    },
  ];

  //xóa dữ liệu
  const handleDeleteChungThu = useCallback(
    (ids) => {
      handleRemoveChungThu(ids, setLoading, getListDataChungThu, t);
    },
    [getListDataChungThu, t]
  );

  return (
    <Fragment>
      <Filter>
        <ListFilterQLChungThu
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
        />
      </Filter>
      <Container>
        <TableComponent
          header={t("ql_chung_thu.quan_ly_chung_thu")}
          dataSource={dataQLChungThu}
          columns={customColumn(columns, filterConditions)}
          renderExtraAction={() => genExtra()}
          totalData={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
          scrollX={1700}
        />
      </Container>
      {showModal}
    </Fragment>
  );
}
