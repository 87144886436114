import { ENUM_FUNCTION, METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import {
  authGetData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";
import { Endpoint } from "@utils/endpoint";

export const handleListTroNgai = (setLoading, setDataTroNgai) => {
  authGetData({
    url: `${Endpoint.XU_LY_LIST_TRO_NGAI}?funcId=${ENUM_FUNCTION.TAICHINH}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) setDataTroNgai(res.data);
    },
  });
};

export const handleConfirmThuTien = (
  setLoading,
  values,
  detail,
  setFetchDataTable,
  handleCloseModalXacNhan,
  checked,
  content
) => {
  const data = {
    ...values,
    dangKyTriAn: checked,
    hoSoId: detail.id,
    thoiGianBatDauCoHieuLuc: content.ngayCoHieuLuc,
    thoiGianHetHieuLuc: content.ngayHetHieuLuc,
  };
  authPostData({
    url: Endpoint.XAC_NHAN_THU_TIEN,
    setLoading,
    method: METHOD_POST,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModalXacNhan();
      }
    },
  });
};

export const tinhTongChiPhi = (detail, setLoading, onComplete) => {
  authGetData({
    url: `${Endpoint.GET_TONG_CHI_PHI}?hoSoId=${detail?.hoSoId ?? detail?.id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) onComplete(res.data);
    },
  });
};

// call api xem lại phương án cấp điện

export const handleXemLaiPACD = (
  detail,
  setDataPAXemLai,
  setVisibleXemLaiPACD,
  visibleXemLaiPACD
) => {
  const endpoints = [
    `${Endpoint.KS_GET_PHUONG_AN_CAP_DIEN}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    `${Endpoint.KS_TONG_CONG_SUAT}?HoSoId=${detail?.hoSoId ?? detail?.id}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading: () => false,
    onSuccess: (res) => {
      if (res[0]?.data !== null && res[1]?.data !== null) {
        setDataPAXemLai({ ...res[0].data.data, ...res[1].data.data });
        setVisibleXemLaiPACD(!visibleXemLaiPACD);
      }
    },
  });
};
