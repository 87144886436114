import { BASE_API_URL_DANH_MUC } from "@utils/constants";

export const APIHinhThucLapDat = {
  LIST_HINH_THUC_LAP_DAT: `${BASE_API_URL_DANH_MUC}/hinh-thuc-lap-dat/list`,
  SYNC_HINH_THUC_LAP_DAT: `${BASE_API_URL_DANH_MUC}/hinh-thuc-lap-dat/sync`,
  CREATE_HINH_THUC_LAP_DAT: `${BASE_API_URL_DANH_MUC}/hinh-thuc-lap-dat/create`,
  UPDATE_HINH_THUC_LAP_DAT: `${BASE_API_URL_DANH_MUC}/hinh-thuc-lap-dat/update`,
  REMOVE_HINH_THUC_LAP_DAT: `${BASE_API_URL_DANH_MUC}/hinh-thuc-lap-dat/delete`,
  GET_HINH_THUC_LAP_DAT: `${BASE_API_URL_DANH_MUC}/hinh-thuc-lap-dat/get`,
  ACTIVE_HINH_THUC_LAP_DAT: `${BASE_API_URL_DANH_MUC}/hinh-thuc-lap-dat/active`,
};
