import { Button, Col, Form, Row, Select } from "antd";
import FormComponent from "@components/Form";
import { Endpoint } from "@utils/endpoint";
import {
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
  FORMAT_MONTH,
} from "@utils/constants";
import "moment/locale/vi";
import moment from "moment";
import DatePickerComponent from "@components/DatePicker";
import CheckPermission from "@components/CheckPermission";
import { cap_dien } from "@permissions/a_capdien";
import ChotBaoCao from "../common/chot-bao-cao";
import HuyChotBaoCao from "../common/huy-chot-bao-cao";
import { removeAccents } from "@utils/function";

const { Option } = Select;

export default function FormBoLoc(props) {
  const {
    form,
    handleSearch,
    user,
    filterConditions,
    clear,
    donVi,
    // handleBaoCaoChiTiet,
    selectedRowKeys,
    isCompany,
    getListBaoCao,
    setSelectedRowKeys,
    disabledDaChot,
    disabledHuyChot,
    loaiYeuCau,
    setType,
  } = props;
  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
        loaiTongHopId: null,
      }}
      autoComplete="off"
    >
      <Row gutter={24} justify="space-between" align="middle">
        <Col span={24} md={6}>
          <Form.Item name="donViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Select
                showSearch
                filterOption={(input, option) => {
                  if (option && option.children) {
                    return (
                      removeAccents(option.children)
                        .toLowerCase()
                        .indexOf(removeAccents(input).toLowerCase()) >= 0
                    );
                  }
                }}
              >
                {donVi && donVi.length > 0
                  ? donVi.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.tenDonVi}
                      </Option>
                    ))
                  : null}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item name="loaiYeuCauId" label="Loại tổng hợp">
            <Select placeholder="Tất cả" allowClear>
              {loaiYeuCau && loaiYeuCau.length > 0
                ? loaiYeuCau.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="ngayBaoCao" label="Tháng báo cáo">
            <DatePickerComponent
              defaultValue={
                filterConditions.ngayBaoCao
                  ? moment(filterConditions.ngayBaoCao, FORMAT_ENGLISH)
                  : moment(moment(), FORMAT_MONTH)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"ngayBaoCao"}
              picker="month"
              notClear={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={9} style={{ textAlign: "right" }}>
          <Button className="mr-24px" onClick={clear} key={1}>
            Bỏ lọc
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            form="filter-form"
            className="mr-24px"
            key={2}
            onClick={() => setType(ENUM_BUTTON_TYPE.TIM_KIEM)}
          >
            Tìm kiếm
          </Button>
          <CheckPermission permissionCode={cap_dien.a501_xuat}>
            <Button
              type="primary"
              className="mr-24px"
              htmlType="submit"
              form="filter-form"
              onClick={() => setType(ENUM_BUTTON_TYPE.XUAT_EXCEL)}
            >
              Xuất excel
            </Button>
          </CheckPermission>
        </Col>
      </Row>
      <Row gutter={24} justify="space-between" align="middle">
        <Col span={24} md={20}>
          {!user.isTongCongTy ? (
            <ChotBaoCao
              endpoint={Endpoint.CHOT_BAO_CAO_TONG_HOP}
              permissionCode={cap_dien.a501_chot}
              text={"Chốt báo cáo"}
              payload={{
                isTongCongTy: isCompany,
                ids: selectedRowKeys.toString(),
              }}
              getListBaoCao={getListBaoCao}
              setSelectedRowKeys={setSelectedRowKeys}
              disabled={disabledDaChot}
            />
          ) : null}
          {user.isTongCongTy ? (
            <HuyChotBaoCao
              endpoint={Endpoint.HUY_CHOT_BAO_CAO_TONG_HOP}
              permissionCode={cap_dien.a501_huy}
              text={"Hủy chốt báo cáo"}
              filter={filterConditions}
              payload={{
                isTongCongTy: isCompany,
                ids: selectedRowKeys.toString(),
              }}
              getListBaoCao={getListBaoCao}
              setSelectedRowKeys={setSelectedRowKeys}
              disabled={disabledHuyChot}
            />
          ) : null}
        </Col>
      </Row>
    </FormComponent>
  );
}
