import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleDuyetHoSoService } from "./services";
import ModalDoiTruong from "./modal-doi-truong";

function DoiTruongDuyetModal({
  visible,
  closeModal,
  setFetchDataTable,
  detail,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleDuyetHoSo = () => {
    const hoSoId = { id: detail.id };
    handleDuyetHoSoService(
      hoSoId,
      setLoading,
      closeModal,
      setFetchDataTable,
      t
    );
  };
  return (
    <ModalDoiTruong
      loading={loading}
      visible={visible}
      closeModal={closeModal}
      handleDuyetHoSo={handleDuyetHoSo}
      t={t}
    ></ModalDoiTruong>
  );
}

export default memo(DoiTruongDuyetModal);
