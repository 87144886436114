import { Col, DatePicker, Row, Select } from "antd";
import ExportExcel from "../common/xuat-excel";
import { FORMAT_MONTH, TYPE_CHANGE_DATE } from "@utils/constants";
import moment from "moment";
import { Container, FullSelect } from "./css-styled";
import { Endpoint } from "@utils/endpoint";
import { cap_dien } from "@permissions/a_capdien";
import LoadingComponent from "@components/Loading";
import { removeAccents } from "@utils/function";

const { Option } = Select;

export default function BaoCaoTongHopTTKH(props) {
  const {
    user,
    donVi,
    loading,
    handleChangeNgay,
    handleChangeUnit,
    tuNgay,
    denNgay,
    thangKT,
    unit,
  } = props;

  return (
    <LoadingComponent loading={loading}>
      <Container>
        <Row gutter={24}>
          <Col span={24} md={9}>
            <div>
              <label>Đơn vị: </label>
              <FullSelect>
                {!user.isTongCongTy ? (
                  <Select defaultValue={user.unitId} disabled>
                    <Option value={user.unitId}>{user.unitName}</Option>
                  </Select>
                ) : (
                  <Select
                    defaultValue={user.unitId}
                    onChange={handleChangeUnit}
                    showSearch
                    filterOption={(input, option) => {
                      if (option && option.children) {
                        return (
                          removeAccents(option.children)
                            .toLowerCase()
                            .indexOf(removeAccents(input).toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {donVi && donVi.length > 0
                      ? donVi.map((item, index) => (
                          <Option key={index} value={item.value}>
                            {item.name}
                          </Option>
                        ))
                      : null}
                  </Select>
                )}
              </FullSelect>
            </div>
          </Col>
          <Col span={24} md={4}>
            <label>Từ tháng: </label>
            <div>
              <DatePicker
                defaultValue={moment(tuNgay)}
                format={FORMAT_MONTH}
                picker="month"
                allowClear={false}
                onChange={(date) =>
                  handleChangeNgay(date, TYPE_CHANGE_DATE.TUNGAY)
                }
              />
            </div>
          </Col>
          <Col span={24} md={4}>
            <label>Đến tháng: </label>
            <div>
              <DatePicker
                defaultValue={moment(denNgay)}
                format={FORMAT_MONTH}
                picker="month"
                allowClear={false}
                onChange={(date) =>
                  handleChangeNgay(date, TYPE_CHANGE_DATE.DENNGAY)
                }
              />
            </div>
          </Col>
          <Col span={24} md={4}>
            {/* <Form.Item
            name="thangKiemTra"
            label="Tháng kiểm tra"
            labelAlign="left"
          > */}
            <label>Tháng kiểm tra: </label>
            <div>
              <DatePicker
                defaultValue={moment(thangKT)}
                format={FORMAT_MONTH}
                picker="month"
                allowClear={false}
                onChange={(date) =>
                  handleChangeNgay(date, TYPE_CHANGE_DATE.THANGKIEMTRA)
                }
              />
            </div>
          </Col>
          <Col
            span={24}
            md={3}
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "start",
            }}
          >
            <ExportExcel
              endpoint={
                Endpoint.EXPORT_EXCEL_BAO_CAO_TONG_HOP_THONG_TIN_KH +
                "?donViId=" +
                unit +
                "&tuThang=" +
                tuNgay +
                "&denThang=" +
                denNgay +
                "&thangKiemTra=" +
                thangKT
              }
              permissionCode={cap_dien.a540_xuat}
              text={"Xuất Excel"}
              key={"xuat-excel"}
            />
          </Col>
        </Row>
      </Container>
    </LoadingComponent>
  );
}
