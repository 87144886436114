// import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import { Button, Col, Image, Modal, Row, Table } from "antd";
import { Container } from "./css-styled";

export default function InHoSoCMISModal(props) {
  const {
    t,
    visible,
    handleCloseModal,
    // form,
    loading,
    data,
    columns,
    onChangePagination,
    visibleImage,
    setVisibleImage,
    listImage,
  } = props;

  return (
    <Modal
      title={t("giao_dich_vien.tiep_nhan_phan_cong.danh_sach_ho_so")}
      visible={visible}
      onCancel={handleCloseModal}
      className="modal-ho-so-cmis"
      width={"60%"}
      footer={null}
    >
      <LoadingComponent loading={loading}>
        <Container>
          <Table
            onChange={onChangePagination}
            dataSource={data}
            columns={columns}
            pagination={false}
            scroll={{ y: 500, x: 800 }}
            bordered={true}
          />
          <Row gutter={24} justify="center" style={{ paddingTop: 40 }}>
            <Col span={24} className="table-text-center">
              <Button
                onClick={handleCloseModal}
                key="back"
                className="button-closed"
              >
                {t("button.dong_lai")}
              </Button>
            </Col>
            <div style={{ display: "none" }}>
              <Image.PreviewGroup
                preview={{
                  visible: visibleImage,
                  onVisibleChange: (vis) => setVisibleImage(vis),
                }}
              >
                {listImage &&
                  listImage.map((data, i) => (
                    <Image src={`data:image/jpeg;base64,${data.fileData}`} key={i} />
                  ))}
              </Image.PreviewGroup>
            </div>
          </Row>
        </Container>
      </LoadingComponent>
    </Modal>
  );
}
