import { BASE_API_URL_CRM } from "@utils/constants";

export const APIGDV_CRM = {
  CRMGDV_DANH_SACH_YEU_CAU: `${BASE_API_URL_CRM}/yeucau/danh-sach-yeu-cau`,
  CRMGDV_DANH_SACH_TRUONG_CA_DUYET_YEU_CAU: `${BASE_API_URL_CRM}/yeucau/truong-ca-duyet-yeu-cau`,
  CRMGDV_DANH_SACH_YEU_CAU_CO_TRO_NGAI: `${BASE_API_URL_CRM}/yeucau/giao-dich-vien-yeu-cau-tro-ngai`,
  CRMGDV_TRA_LAI_YEU_CAU: `${BASE_API_URL_CRM}/tralai/giao-dich-vien`,
  CRMGDV_PHAN_CONG: `${BASE_API_URL_CRM}/phancong/giao-dich-vien`,
  CRMGDV_DANH_SACH_YEU_CAU_DA_PHAN_CONG: `${BASE_API_URL_CRM}/yeucau/giao-dich-vien-yeu-cau-da-phan-cong`,
  CRMGDV_DANH_SACH_DUYET_YEU_CAU: `${BASE_API_URL_CRM}/yeucau/gdv-duyet-yeu-cau`,
  CRMGDV_XAC_NHAN: `${BASE_API_URL_CRM}/xacnhan/giao-dich-vien`,
  CRMGDV_PHAN_PHONG: `${BASE_API_URL_CRM}/phancong/phan-phong`,
  CRMGDV_KY_SO: `${BASE_API_URL_CRM}/kyso/giao-dich-vien`,
  CRMGDV_PHAN_CONG_LAI: `${BASE_API_URL_CRM}/phancong/giao-dich-vien-phan-cong-lai`,
  CRMGDV_KET_QUA_XU_LY: `${BASE_API_URL_CRM}/yeucau/ket-qua-xu-ly`,
  CRMGDV_CAP_NHAT_KET_QUA_XU_LY: `${BASE_API_URL_CRM}/yeucau/cap-nhat-ket-qua-xu-ly`,
  CRMGDV_CAP_NHAT_YEU_CAU_SAI: `${BASE_API_URL_CRM}/yeucau/cap-nhat-yeu-cau-sai`,
  CRMGDV_KY_HSM: `${BASE_API_URL_CRM}/kyhsm/giao-dich-vien`,
};
