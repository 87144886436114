import React, { useState, useCallback } from "react";
import { Popover, Button, Modal, Image } from "antd";
import styled from "styled-components";
import ModalEditUserAccount from "./modal-edit-user-account";
import AvatarDefault from "@assets/images/avatar-default.png";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, signOut } from "@state/auth/reducer";
export default function UserProfile() {
  const auth = useSelector(authSelector);
  const {user} = auth;

  const dispatch = useDispatch();
  const [visibleUserAccount, setVisibleUserAccount] = useState();
  const [userId, setUserId] = useState(null);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const handleClickPopover = useCallback(() => {
    setVisiblePopover(!visiblePopover);
  }, [visiblePopover]);
  const handleSignOut = () => {
    setVisiblePopover(false);
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: "Đăng xuất hệ thống",
      onOk() {
        dispatch(signOut({isLoginSSO: auth.isLoginSSO}));
      },
      onCancel() {},
      okText: "Đồng ý",
      okButtonProps: { type: "danger" },
      cancelText: "Hủy",
    });
  };

  const handlUser = useCallback(
    (userId) => {
      setUserId(userId);
      setVisibleUserAccount(!visibleUserAccount);
      setVisiblePopover(false);
    },
    [visibleUserAccount]
  );
  return (
    <Popover
      onVisibleChange={handleClickPopover}
      visible={visiblePopover}
      title="Thông tin tài khoản"
      trigger="click"
      placement="bottomRight"
      content={
        <ProfileContent>
          <div className="avatar-user">
            <img
              src={user.avatar ? user.avatar : AvatarDefault}
              alt="avatar"
              width={96}
              height={96}
              onError={(e) => {
                e.currentTarget.src = AvatarDefault;
              }}
            />
          </div>
          <div className="text-center user-name">{user.name}</div>
          <div className="info-user" onClick={handlUser}>
            <span className="info-name">Thay đổi thông tin</span>
          </div>

          <Button onClick={handleSignOut} className="full" type="danger">
            <div className="logout-text">
              <span>Đăng xuất</span>
            </div>
          </Button>
          {visibleUserAccount && (
            <ModalEditUserAccount
              visible={visibleUserAccount}
              close={handlUser}
              userId={userId}
            />
          )}
        </ProfileContent>
      }
    >
      <ProfileWrapper>
        <div className="login-avatar">
          <Image
            preview={false}
            src={user.avatar ? user.avatar : AvatarDefault}
            alt="avatar"
            // width={96}
            // height={96}
            onError={(e) => {
              e.currentTarget.src = AvatarDefault;
            }}
          />
        </div>
      </ProfileWrapper>
    </Popover>
  );
}
const ProfileWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;
const ProfileContent = styled.div`
  margin: 0px -10px;
  .user-name {
    font-size: 16px;
    font-weight: bold;
  }
`;
