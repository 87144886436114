/* eslint-disable react-hooks/exhaustive-deps */
import { formatDateWithTimezoneGB, numberWithSpaces } from "@utils/function";
import { Tooltip } from "antd";
import React, { useMemo } from "react";
import NumericInput from "./input-number";

export default function DanhSachDiemDoColumn({
  handleDetailDiemDo,
  dataSource,
  setDataSource,
  dataSourceOriginal,
  setDataSourceInitial,
}) {
  const columns = useMemo(() => {
    return [
      {
        title: "Thông tin điểm đo",
        dataIndex: `tenDiemDo`,
        key: `tenDiemDo`,
        align: "center",
        fixed: true,
        width: 185,
        render: (text, record) => {
          if (record.isRecordFisrt) {
            return (
              <div className="table-text-left cursor-pointer trun-cate text-13" onClick={() => handleDetailDiemDo(record.diemDoId)}>
                <Tooltip title={text}>
                  <span className="mr-10px ">{text}</span>
                </Tooltip>
              </div>
            );
          }
        },
      },
      {
        title: "Bộ số",
        dataIndex: "boSo",
        key: "boSo",
        width: 55,
        render: (text) => <div className="table-text-center text-13">{text}</div>,
      },
      {
        title: "Chỉ số đầu",
        dataIndex: "chiSoDau",
        key: "chiSoDau",
        width: 100,
        align: "right",
        render: (text) => {
          return (
            <Tooltip title={text}>
              <div className="trun-cate text-13">{numberWithSpaces(text)}</div>
            </Tooltip>
          );
        },
      },
      {
        title: "Chỉ số cuối",
        dataIndex: "chiSoCuoi",
        width: 110,
        key: "chiSoCuoi",
        render: (value, record) => {
          return (
            <Tooltip title={value}>
              <div style={{ maxHeight: 20 }} className="table-mh-20 text-13">
                <NumericInput
                  key={`${record?.id}-chiSoCuoi`}
                  id={`${record?.id}-chiSoCuoi`}
                  className="table-text-right table-text-transparent last-stat"
                  style={{ height: 20, fontSize: 13, fontWeight: 400 }}
                  digit
                  record={record}
                  value={value}
                  dataSource={dataSource}
                  setDataSource={setDataSource}
                  dataSourceOriginal={dataSourceOriginal}
                  setDataSourceInitial={setDataSourceInitial}
                  name="chiSoCuoi"
                />
              </div>
            </Tooltip>
          );
        },
      },
      {
        title: "HSN",
        dataIndex: "heSoNhan",
        key: "heSoNhan",
        width: 55,
        render: (value) => (
          <Tooltip title={value}>
            <div style={{ maxHeight: 20 }} className="table-mh-20 table-text-right text-13">
              <div className="trun-cate">{numberWithSpaces(value)}</div>
            </div>
          </Tooltip>
        ),
      },
      {
        title: "Sản lượng",
        dataIndex: "sanLuong",
        key: "sanLuong",
        width: 130,
        render: (value) => {
          return (
            <Tooltip title={value}>
              <div style={{ maxHeight: 20 }} className="table-mh-20 text-13">
                <NumericInput
                  disablefield
                  className="table-text-right table-text-transparent quantity"
                  style={{ height: 20, fontSize: 13, fontWeight: 400 }}
                  value={value}
                />
              </div>
            </Tooltip>
          );
        },
      },
      {
        title: "SL(+/-)",
        dataIndex: "sanLuongTrucTiep",
        key: "sanLuongTrucTiep",
        width: 100,
        render: (value, record) => {
          return (
            <Tooltip title={value}>
              <div>
                <NumericInput
                  digit
                  className="table-text-transparent text-13"
                  style={{ height: 20, fontSize: 13, fontWeight: 400 }}
                  record={record}
                  isNegativeValue
                  value={value}
                  dataSource={dataSource}
                  setDataSource={setDataSource}
                  dataSourceOriginal={dataSourceOriginal}
                  setDataSourceInitial={setDataSourceInitial}
                  name="sanLuongTrucTiep"
                />
              </div>
            </Tooltip>
          );
        },
      },
      {
        title: "SL Tháo",
        dataIndex: "sanLuongThao",
        key: "sanLuongThao",
        width: 100,
        align: "right",
        render: (value) => (
          <div style={{ maxHeight: 20 }} className="table-mh-20 table-text-right text-13">
            <div>{numberWithSpaces(value)}</div>
          </div>
        ),
      },
      {
        title: "Tổng cộng",
        dataIndex: "tongCong",
        key: "tongCong",
        width: 120,
        align: "right",
        render: (value) => {
          return (
            <Tooltip title={value}>
              <div style={{ maxHeight: 20 }} className="table-mh-20 table-text-right text-13">
                <div className="trun-cate">{numberWithSpaces(value)}</div>
              </div>
            </Tooltip>
          );
        },
      },
      {
        title: "Loại CS",
        dataIndex: "loaiCS",
        key: "loaiCS",
        width: 130,
        render: (value) => <div className="table-text-center text-13">{value}</div>,
      },
      {
        title: "Ngày GCS",
        dataIndex: "ngayGCS",
        width: 170,
        key: "ngayGCS",
        render: (value) => <div className="table-text-center text-13">{formatDateWithTimezoneGB(value)}</div>,
      },
      {
        title: "Đơn vị giao nhận",
        dataIndex: "donViGiaoNhan",
        key: "donViGiaoNhan",
        width: 360,
        align: "center",
        render: (value) => (
          <Tooltip title={value}>
            <div className="trun-cate text-13">{value}</div>
          </Tooltip>
        ),
      },
      {
        title: "Đơn vị nhập chỉ số",
        dataIndex: "donViNhapCS",
        key: "donViNhapCS",
        align: "center",
        width: 360,
        render: (value) => (
          <Tooltip title={value}>
            <div className="trun-cate text-13">{value}</div>
          </Tooltip>
        ),
      },
    ];
  }, [dataSource]);

  return columns;
}
