import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { closeModal, openModal } from "@state/system-config/reducer";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";

import { Button, Dropdown, Form } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import ThongTinChiTietTabs from "@modules/tich-hop-CRM/common-ho-so/chi-tiet-yeu-cau/index";
import { TABLE_COLUMNS_THEO_LOAI_YEU_CAU } from "@modules/tich-hop-CRM/common-ho-so/constant";
import { dateConditionLogic } from "@modules/tich-hop-CRM/common-ho-so/functions";
import CRMFilter from "@modules/tich-hop-CRM/common-ho-so/list-bo-loc";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { Style } from "../../css-styled";
import ListChucNang from "./list-chuc-nang";
import { getLinhVucList } from "./services";
function YeuCauDangThucHien() {
  // const [, setVisibleDuyet] = useState(false);
  const [, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [form] = Form.useForm();
  const [listLoading, setListLoading] = useState(false);
  const [linhVucList, setLinhVucList] = useState([]);

  const donViId = useSelector((state) => state.auth.user.unitId);

  const listTrangThai = [
    {
      name: "Đã phân công",
      value: "ca71dfb1-b72f-4bdc-865b-01c0e3d1f3f9",
    },
  ];

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DenNgay: `${moment().format("YYYY-MM-DD")}`,
    TuNgay: `${moment().subtract(7, "d").format("YYYY-MM-DD")}`,
    DonViId: donViId,
    ...parseParams(location.search),
  });
  const [isDoubleClick] = useState(true);
  const dispatch = useDispatch();

  const columns = [
    {
      title: "",
      dataIndex: "",
      width: 60,
      render: (row) => (
        <div style={{ textAlign: "center" }}>
          <Dropdown
            overlay={
              <div
                className="list-menu-function"
                style={{
                  boxShadow:
                    " 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
                }}
              >
                <ListChucNang
                  detail={row}
                  columns={columns}
                  setData={setData}
                  setListLoading={setListLoading}
                  filterConditions={filterConditions}
                  setTotal={setTotal}
                  location={location}
                ></ListChucNang>
              </div>
            }
            trigger={["click"]}
          >
            <Button type="primary" icon={<MenuOutlined />}></Button>
          </Dropdown>
        </div>
      ),
    },
    ...TABLE_COLUMNS_THEO_LOAI_YEU_CAU.LOAI_4,
  ];

  const handleSearch = useCallback(
    (values) => {
      const ojbStartDate = new Date(values.TuNgay);
      const objEndDate = new Date(values.DenNgay);
      const dateCondition = dateConditionLogic(ojbStartDate, objEndDate);

      const payload = {
        TuNgay: dateCondition.formatStartDate,
        DenNgay: dateCondition.formatEndDate,
        ...(values.KenhId && { KenhId: values.KenhId }),
        ...(values.DichVuId && { DichVuId: values.DichVuId }),
        ...(values.DoiId && { DoiId: values.DoiId }),
        ...(values.DonViId && { DonViId: values.DonViId }),
        ...(values.TuKhoaTimKiem && { TuKhoaTimKiem: values.TuKhoaTimKiem }),
        ...(values.LinhVuc && { LinhVuc: values.LinhVuc }),
        ...(values.TrangThai && { TrangThai: values.TrangThai }),
        ...(values.LoaiYeuCau && { LoaiYeuCau: values.LoaiYeuCau }),
        pageIndex: DEFAULT_PAGEINDEX,
        pageSize: DEFAULT_PAGESIZE,
      };
      setFilterConditions(payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterConditions]
  );

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const detailHoSo = (record) => {
    if (isDoubleClick)
      return {
        onDoubleClick: () => {
          dispatch(
            openModal({
              content: (
                <ThongTinChiTietTabs
                  detailHoSo={record}
                  closeModal={closeModal}
                />
              ),
              size: "large",
              title: <>Chi tiết yêu cầu</>,
            })
          );
        },
      };
  };
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);
  useEffect(() => {
    getLinhVucList(
      setLoading,
      form,
      setData,
      filterConditions,
      setTotal,
      location,
      setListLoading,
      setLinhVucList
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConditions]);

  const clearFilter = useCallback(() => {
    const payload = {
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DenNgay: `${moment().format("YYYY-MM-DD")}`,
      TuNgay: `${moment().subtract(7, "d").format("YYYY-MM-DD")}`,
      DonViId: donViId,
      LinhVuc: linhVucList[0].value,
    };
    setFilterConditions(payload);

    form.setFieldsValue({
      LinhVuc: linhVucList[0].value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Style>
      <div className="tich-hop-CRM-gdv">
        <Filter>
          <CRMFilter
            handleSearch={handleSearch}
            filterConditions={filterConditions}
            form={form}
            clearFilter={clearFilter}
            listTrangThai={listTrangThai}
          />
        </Filter>
        <div className="tich-hop-CRM-gdv-content">
          <TableComponent
            loading={listLoading}
            dataSource={data}
            columns={columns}
            scrollX={1600}
            expandable={false}
            header="Danh sách"
            rowKey="key"
            totalData={total}
            onRow={detailHoSo}
            pagination={filterConditions}
            onChangePagination={onChangePagination}
          />
        </div>
      </div>
    </Style>
  );
}

export default YeuCauDangThucHien;
