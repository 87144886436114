import { Endpoint } from "@utils/endpoint";
import { authSelector } from "@state/auth";
import { Col, Form, Input, Row, Select, Button } from "antd";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import Selection from "@components/Select";
import FormComponent from "@components/Form";
const { Option } = Select;

export function ListFilterQLChungThu(props) {
  const { handleSearch, clearFilter, filterConditions } = props;
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        DonViId: user.unitId.toString(),
      }}
    >
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="DonViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Selection
                url={Endpoint.GET_UNIT_LIST}
                form={form}
                formKey="DonViId"
              />
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="TenNguoiDung" label="Tên đăng nhập">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="SoSerial" label="Số serial">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="VaiTroId" label="Chức vụ">
            <Selection
              url={Endpoint.GET_POSITION_LIST}
              form={form}
              formKey="VaiTroId"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Button className="mr-24px" onClick={clear}>
          Bỏ lọc
        </Button>
        <Button type="primary" htmlType="submit" form="filter-form">
          Tìm kiếm
        </Button>
      </Row>
    </FormComponent>
  );
}
