import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  TYPE_TABLE_BAOCAO,
} from "@utils/constants";
import {
  alertMessage,
  buildQueryString,
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { handleListData, inPDF } from "./services";
import DSCongToTable from "./table";
import CheckPermission from "@components/CheckPermission";
import { kt_tb_do_dem } from "@permissions/n_kttbdodem";
import { Button } from "antd";
// import { closeModal, openModal } from "@state/system-config/reducer";
// import ChiTietCongTo from "../chi-tiet-cong-to";
import PDFModal from "@modules/cap-dien/common-ho-so/pdf-viewer";
import { downloadFile } from "@utils/request";
import { Endpoint } from "@utils/endpoint";

export default function Index(props) {
  const {
    url,
    typeList,
    fetchList,
    setFetchList,
    isRowSelect,
    rowSelection,
    firstColumns,
    header,
    detail,
  } = props;
  const { user } = useSelector(authSelector);
  // const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    donViId: user.unitId,
    ...parseParams(location.search),
  });
  // eslint-disable-next-line no-unused-vars
  const [visiblePDF, setVisiblePDF] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingPDF, setLoadingPDF] = useState(false);
  const columnsBCBatThuong = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text) => (
        <div
          className="table-text-left"
          // onClick={() => detailCongTo(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: 170,
    },
    {
      title: "Trạm",
      dataIndex: "tenTram",
      key: "tenTram",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "20%",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Mã công tơ",
      dataIndex: "maCongTo",
      key: "maCongTo",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 180,
    },
    {
      title: "Số pha",
      dataIndex: "soPha",
      key: "soPha",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 100,
    },
    {
      title: "Loại kiểm tra",
      dataIndex: "loaiKiemTra",
      key: "loaiKiemTra",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Tình trạng",
      dataIndex: "tinhTrang",
      key: "tinhTrang",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Ngày kiểm tra",
      dataIndex: "ngayKiemTra",
      key: "ngayKiemTra",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "15%",
    },
    {
      title: "Đội",
      dataIndex: "tenDoi",
      key: "tenDoi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Nhân viên",
      dataIndex: "nhanVien",
      key: "nhanVien",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Kiếm nghị giải quyết",
      dataIndex: "kienNghiGiaiQuyet",
      key: "kienNghiGiaiQuyet",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Đồng bộ kiểm tra giám sát",
      dataIndex: "dongBoKiemTraGiamSat",
      key: "dongBoKiemTraGiamSat",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Ngày đồng bộ",
      dataIndex: "ngayDongBo",
      key: "ngayDongBo",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "10%",
    },
  ];

  const columnsBCPT = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "4%",
      sorter: false,
    },
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text) => (
        <div
          className="table-text-left"
          // onClick={() => detailCongTo(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: 170,
    },
    {
      title: "Trạm",
      dataIndex: "tenTram",
      key: "tenTram",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Mã sổ",
      dataIndex: "maSoGCS",
      key: "maSoGCS",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "8%",
    },
    {
      title: "Mã công tơ",
      dataIndex: "maCongTo",
      key: "maCongTo",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 180,
    },
    {
      title: "Bộ chỉ số",
      dataIndex: "bcs",
      key: "bcs",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "7%",
    },
    {
      title: "Ngày GCS",
      dataIndex: "ngayGCS",
      key: "ngayGCS",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "7%",
    },
    {
      title: "Chỉ số",
      dataIndex: "chiSo",
      key: "chiSo",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "8%",
    },
    {
      title: "Ngày PT",
      dataIndex: "ngayPT",
      key: "ngayPT",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "8%",
    },
    {
      title: "Chỉ số PT",
      dataIndex: "chiSoPT",
      key: "chiSoPT",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "8%",
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "8%",
    },
    {
      title: "Đội",
      dataIndex: "tenDoi",
      key: "tenDoi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: 150,
    },
    {
      title: "Nhân viên",
      dataIndex: "nhanVien",
      key: "nhanVien",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
    },
  ];
  const columnsBCDK = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "4%",
      sorter: false,
    },
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text) => (
        <div
          className="table-text-left"
          // onClick={() => detailCongTo(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: 170,
    },
    {
      title: "Trạm",
      dataIndex: "tenTram",
      key: "tenTram",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Mã công tơ",
      dataIndex: "maCongTo",
      key: "maCongTo",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 180,
    },
    {
      title: "Số Pha",
      dataIndex: "soPha",
      key: "soPha",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: 100,
    },
    {
      title: "Ngày kiểm tra",
      dataIndex: "ngayKiemTra",
      key: "ngayKiemTra",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "10%",
    },
    {
      title: "Đội",
      dataIndex: "tenDoi",
      key: "tenDoi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "10%",
    },
    {
      title: "Nhân viên",
      dataIndex: "nhanVien",
      key: "nhanVien",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
  ];
  const [columns, setColumns] = useState(
    typeList === TYPE_TABLE_BAOCAO.BATTHUONG
      ? columnsBCBatThuong
      : typeList === TYPE_TABLE_BAOCAO.PHUCTRADAKIEMTRA
      ? columnsBCPT
      : typeList === TYPE_TABLE_BAOCAO.DINHKYDAKIEMTRA
      ? columnsBCDK
      : []
  );
  // xem PDF

  const showModalPdf = useMemo(
    () =>
      visiblePDF && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visiblePDF}
            setVisible={setVisiblePDF}
            title={
              typeList === TYPE_TABLE_BAOCAO.BATTHUONG
                ? "Báo cáo công tơ bất thường"
                : typeList === TYPE_TABLE_BAOCAO.DINHKYDAKIEMTRA
                ? "Báo cáo chi tiết công tơ đinh kỳ đã kiểm tra"
                : typeList === TYPE_TABLE_BAOCAO.PHUCTRADAKIEMTRA
                ? "Báo cáo chi tiết công tơ phúc tra đã kiểm tra"
                : null
            }
            getBienBan={(setUrl) => showModalPDF(setUrl)}
            setLoadingDownload={setLoadingDownload}
            loadingDownload={loadingDownload}
            loadingGetBienBan={loadingPDF}
            isReload={true}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visiblePDF]
  );

  const showModalPDF = useCallback(
    (setUrl) => {
      inPDF(
        setLoadingPDF,
        (value) => {
          setUrl(value);
        },
        typeList,
        filterConditions
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );

  //call api xuất excel
  const handleExportExcel = useCallback((endpoint) => {
    if (endpoint !== undefined)
      downloadFile({
        endpoint,
        setLoading,
      });
  }, []);
  // Hiển thị  các buttom

  const genExtra = () => (
    <div>
      {typeList === TYPE_TABLE_BAOCAO.BATTHUONG ? (
        <CheckPermission permissionCode={kt_tb_do_dem.n51e}>
          <Button size="small" type="primary" className="mr-24px">
            Đồng bộ về Kiểm tra giám sát
          </Button>
        </CheckPermission>
      ) : null}
      <CheckPermission
        permissionCode={
          typeList === TYPE_TABLE_BAOCAO.BATTHUONG
            ? kt_tb_do_dem.n51e
            : typeList === TYPE_TABLE_BAOCAO.DINHKYDAKIEMTRA
            ? kt_tb_do_dem.n52e
            : typeList === TYPE_TABLE_BAOCAO.PHUCTRADAKIEMTRA
            ? kt_tb_do_dem.n53e
            : null
        }
      >
        <Fragment>
          <Button
            size="small"
            type="primary"
            className="mr-5px"
            onClick={() => showViewPDF()}
          >
            Xuất PDF
          </Button>
          <Button
            size="small"
            type="primary"
            className="mr-5px"
            onClick={() =>
              handleExportExcel(
                `${
                  typeList === TYPE_TABLE_BAOCAO.BATTHUONG
                    ? Endpoint.EXCEL_BC_CT_BATTHUONG
                    : typeList === TYPE_TABLE_BAOCAO.DINHKYDAKIEMTRA
                    ? Endpoint.EXCEL_BC_CT_DINHKY
                    : typeList === TYPE_TABLE_BAOCAO.PHUCTRADAKIEMTRA
                    ? Endpoint.EXCEL_BC_CT_PHUCTRA
                    : null
                }?${buildQueryString(parseParams(filterConditions))}`
              )
            }
          >
            Xuất Excel
          </Button>
        </Fragment>
      </CheckPermission>
    </div>
  );
  // view pdf

  const base64toBlob = useCallback((data) => {
    try {
      const base64WithoutPrefix = data.substr(
        "data:application/pdf;base64,".length
      );
      const bytes = atob(base64WithoutPrefix);
      let length = bytes.length;
      const out = new Uint8Array(length);

      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      return new Blob([out], { type: "application/pdf" });
    } catch (error) {
      alertMessage("error", "Thông báo", "Định dạng file không hỗ trợ!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const showViewPDF = useCallback(() => {
    inPDF(
      setLoadingPDF,
      (value) => {
        const blob = base64toBlob(value);
        const url = URL.createObjectURL(blob);
        window.open(url);
      },
      typeList,
      filterConditions,
      location
    );
  }, [base64toBlob, filterConditions, location, typeList]);

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //change show page size
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //get list data
  const getListData = useCallback(() => {
    handleListData(
      filterConditions,
      url,
      setLoading,
      setDataSource,
      setTotalCount,
      location
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, url]);

  useEffect(() => {
    getListData();
  }, [getListData]);

  useEffect(() => {
    //call api list if fetchList = true
    if (fetchList) {
      getListData();
      setFetchList(false);
    }
  }, [fetchList, getListData, setFetchList]);

  //tìm kiếm dữ liệu
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
      donViId: values.donViId,
    }));
  }, []);

  //clear bộ lọc
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
    });
  }, [user.unitId]);

  useEffect(() => {
    let newColumns = [];
    if (firstColumns && Object.keys(firstColumns).length > 0) {
      columns.unshift(firstColumns);
      columns[0].width = 80;
      columns[0].fixed = "left";
    }
    newColumns = columns;
    setColumns(newColumns);
  }, [columns, firstColumns]);

  return (
    <Fragment>
      <DSCongToTable
        loading={loading}
        dataSource={dataSource}
        totalData={totalCount}
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        onChangePagination={onChangePagination}
        filterConditions={filterConditions}
        rowSelection={isRowSelect ? rowSelection : null}
        genExtra={genExtra}
        user={user}
        typeList={typeList}
        columns={customColumn(columns, filterConditions)}
        header={header}
      />
      {showModalPdf}
    </Fragment>
  );
}
