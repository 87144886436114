import { FieldSetContainer } from "@modules/cap-dien/nhan-vien-khao-sat/css-styled";
import { Button, Col, ConfigProvider, Divider, Row, Table } from "antd";
import vn from "antd/es/locale-provider/vi_VN";
import React, { Fragment, useEffect, useState } from "react";
// import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
import { calculateColumnsWidth } from "@utils/function";
import { ENUM_MODE_PHUONGANCAPDIEN } from "@utils/constants";

export default function TableKhachHang(props) {
  const {
    genExtra,
    columns,
    dataKH,
    handleOpenThongTinHS,
    thongTinHoSo,
    listLoaiCap,
    listDuToan,
    mode,
  } = props;
  const [titleLoaiCap, setTitleLoaiCap] = useState("");
  const [titleLoaiDuToan, setTitleLoaiDT] = useState("");

  useEffect(() => {
    if (Array.isArray(listLoaiCap) && listLoaiCap.length > 0) {
      setTitleLoaiCap(
        listLoaiCap.find((rs) => rs.value === thongTinHoSo.loaiCap)?.name
      );
    }
    if (Array.isArray(listDuToan) && listDuToan.length > 0) {
      setTitleLoaiDT(
        listDuToan.find((rs) => rs.value === thongTinHoSo.loaiDuToan)?.name
      );
    }
  }, [thongTinHoSo, listLoaiCap, listDuToan]);

  const styledTable = !_.isEmpty(dataKH)
    ? calculateColumnsWidth(columns, dataKH, 400)
    : {};

  const scroll = {
    x: dataKH ? 1400 : styledTable.tableWidth,
    y: 350,
  };

  return (
    <Fragment>
      <FieldSetContainer>
        <Divider
          orientation="left"
          style={{ fontWeight: "bold", borderWidth: 5, borderColor: "black" }}
        >
          Thông tin hồ sơ
        </Divider>
        <Row gutters={24} style={{ textAlign: "center", padding: 5 }}>
          <Col span={24} md={8} flex="1 0 auto">
            <span>Loại dự toán: </span>
            <span className="font-bold fs-14px">{titleLoaiDuToan}</span>
          </Col>
          <Col span={24} md={8} flex="1 0 auto">
            <span>Loại cáp: </span>
            <span className="font-bold fs-14px">{titleLoaiCap}</span>
          </Col>
          <Col span={24} md={8} style={{ textAlign: "right" }}>
            {mode !== ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
              <Button
                size="small"
                type="primary"
                className="mr-5px"
                onClick={handleOpenThongTinHS}
              >
                Thay đổi
              </Button>
            ) : null}
          </Col>
        </Row>
        <Divider style={{ borderWidth: 2, borderColor: "#919191" }} />
      </FieldSetContainer>
      {genExtra()}
      <div style={{ margin: 16 }}>
        <ConfigProvider locale={vn}>
          <Table
            dataSource={dataKH}
            columns={columns}
            rowKey={(object) => object.id}
            pagination={false}
            bordered
            scroll={{ x: scroll.x, y: scroll.y }}
            // components={dataKH.length > 0 ? components : null}
          />
        </ConfigProvider>
      </div>
    </Fragment>
  );
}
