import { closeModal } from "@state/system-config/reducer";
import { Button, Tabs } from "antd";
import { Fragment } from "react";
import styled from "styled-components";
// import { Container } from "./css-styled";
import ChiTietYeuCauTabMaTram from "../tabs/chi-tiet-yeu-cau";
// import LichSuThaoTacTab from "./tabs/lich-su-thao-tac";
const { TabPane } = Tabs;

export default function ChiTietYeuCauModal(props) {
  const {
    indexTab,
    callback,
    dataThoiGianGCS,
    loading,
    dispatch,
    t,
    detailThoiGian,
  } = props;
  return (
    <Fragment>
      <Container>
        <Fragment>
          <Tabs defaultActiveKey={indexTab} onChange={callback}>
            <TabPane tab={"Chi tiết mã trạm"} key="1">
              <ChiTietYeuCauTabMaTram
                dataSource={dataThoiGianGCS}
                loading={loading}
                detailThoiGian={detailThoiGian}
              />
            </TabPane>
          </Tabs>
        </Fragment>

        <div className="ant-modal-footer text-center">
          <Button type="default" onClick={() => dispatch(closeModal())}>
            {t("button.dong_lai")}
          </Button>
        </div>
      </Container>
    </Fragment>
  );
}
const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
  .ant-collapse.ant-collapse-icon-position-left {
    margin-top: 20px;
  }
`;
