import { Col, Form, Row, Select, Button, Input, Checkbox } from "antd";
import DatePickerComponent from "../datepicker/DatePicker";
// import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import FormComponent from "@components/Form";
import moment from "moment";
import {
  FORMAT_DATE,
  FORMAT_ENGLISH,
  // TYPE_TABLE_CONGTO,
} from "@utils/constants";
import { Fragment } from "react";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";
import CheckPermission from "@components/CheckPermission";
import { buildQueryString, parseParams } from "@utils/function";

const { Option } = Select;

export default function FormBoLoc(props) {
  const {
    user,
    form,
    handleSearch,
    // tooltip,
    clear,
    loaiBangKe,
    filterConditions,
    trangThai,
    // typeList,
    checkTraLai,
    firstTrangThai,
    onChangeTrangThai,
    urlXuatExcel,
    permissionExcel,
    handleExportExcel,
  } = props;
  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
      }}
      autoComplete="off"
    >
      <Row gutter={24}>
        {/* {typeList !== TYPE_TABLE_CONGTO.KIEMTRAPHUCTRA ? ( */}
        <Fragment>
          <Col span={24} md={8}>
            <Form.Item name="donViId" label="Đơn vị">
              {!user.isTongCongTy ? (
                <Select defaultValue={user.unitId} disabled>
                  <Option value={user.unitId}>{user.unitName}</Option>
                </Select>
              ) : (
                <Selection
                  url={Endpoint.LIST_DONVI_KTCT}
                  form={form}
                  formKey="donViId"
                  notClear={true}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                if (prevValues.donViId !== currentValues.donViId) {
                  form.setFieldsValue({
                    doiId: undefined,
                  });
                }
                return prevValues.donViId !== currentValues.donViId;
              }}
            >
              {({ getFieldValue }) => {
                if (getFieldValue("donViId")) {
                  return (
                    <Form.Item name="doiId" label="Đội">
                      <Selection
                        url={`${Endpoint.LIST_DOI_KTCT}?donviId=${getFieldValue(
                          "donViId"
                        )}`}
                        formKey="doiId"
                        form={form}
                      />
                    </Form.Item>
                  );
                }
                return (
                  <Form.Item name="doiId" label="Đội">
                    <Selection
                      url={`${Endpoint.LIST_DOI_KTCT}?donviId=${user.unitId}`}
                      formKey="doiId"
                      form={form}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={24} md={5}>
            <Form.Item name="loaiBangKe" label="Loại bảng kê">
              <Select placeholder="Tất cả" allowClear>
                {loaiBangKe && loaiBangKe.length > 0
                  ? loaiBangKe.map((item, index) => {
                      if (item.value !== null) {
                        return (
                          <Option key={index} value={item.value.toString()}>
                            {item.name}
                          </Option>
                        );
                      }
                      return null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={5}>
            <Form.Item name="trangThaiId" label="Trạng thái">
              <Select
                defaultValue={firstTrangThai}
                allowClear
                onChange={onChangeTrangThai}
              >
                {trangThai && trangThai.length > 0
                  ? trangThai.map((item, index) => {
                      if (item.value !== null) {
                        return (
                          <Option key={index} value={item.value.toString()}>
                            {item.name}
                          </Option>
                        );
                      }
                      return null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={4}>
            <Form.Item name="ngayBatDau" label="Ngày tạo">
              <DatePickerComponent
                defaultValue={
                  filterConditions.ngayBatDau
                    ? moment(filterConditions.ngayBatDau, FORMAT_ENGLISH)
                    : moment(moment(), FORMAT_DATE)
                }
                format={FORMAT_ENGLISH}
                form={form}
                formKey={"ngayBatDau"}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={4}>
            <Form.Item name="ngayKetThuc" label="&nbsp;">
              <DatePickerComponent
                defaultValue={
                  filterConditions.ngayKetThuc
                    ? moment(filterConditions.ngayKetThuc, FORMAT_ENGLISH)
                    : moment(moment(), FORMAT_DATE)
                }
                format={FORMAT_ENGLISH}
                form={form}
                formKey={"ngayKetThuc"}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={5}>
            <Form.Item name="maBangKe" label="Mã bảng kê">
              <Input />
            </Form.Item>
          </Col>
          <Col
            span={24}
            md={3}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Form.Item name="biTraLai" style={{ margin: 0 }}>
              <Checkbox
                onChange={checkTraLai}
                defaultChecked={filterConditions.biTraLai ? true : false}
              >
                Bị trả lại
              </Checkbox>
            </Form.Item>
          </Col>
          <Col
            span={24}
            md={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button className="mr-24px" onClick={clear}>
              Bỏ lọc
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              form="filter-form"
              className="mr-24px"
            >
              Tìm kiếm
            </Button>
            {urlXuatExcel ? (
              <CheckPermission permissionCode={permissionExcel}>
                <Button
                  type="primary"
                  onClick={() =>
                    handleExportExcel(
                      `${urlXuatExcel}?${buildQueryString(
                        parseParams(filterConditions)
                      )}`
                    )
                  }
                >
                  Xuất Excel
                </Button>
              </CheckPermission>
            ) : null}
          </Col>
        </Fragment>
        {/* ) : (
          <Fragment>
            <Col span={24} md={8}>
              <Form.Item name="donViId" label="Đơn vị">
                {!user.isTongCongTy ? (
                  <Select defaultValue={user.unitId} disabled>
                    <Option value={user.unitId}>{user.unitName}</Option>
                  </Select>
                ) : (
                  <Selection
                    url={Endpoint.LIST_DONVI_KTCT}
                    form={form}
                    formKey="donViId"
                    notClear={true}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={24} md={5}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  if (prevValues.donViId !== currentValues.donViId) {
                    form.setFieldsValue({
                      doiId: undefined,
                    });
                  }
                  return prevValues.donViId !== currentValues.donViId;
                }}
              >
                {({ getFieldValue }) => {
                  if (getFieldValue("donViId")) {
                    return (
                      <Form.Item name="doiId" label="Đội">
                        <Selection
                          url={`${
                            Endpoint.LIST_DOI_KTCT
                          }?donviId=${getFieldValue("donViId")}`}
                          formKey="doiId"
                          form={form}
                        />
                      </Form.Item>
                    );
                  }
                  return (
                    <Form.Item name="doiId" label="Đội">
                      <Selection
                        url={`${Endpoint.LIST_DOI_KTCT}?donviId=${user.unitId}`}
                        formKey="doiId"
                        form={form}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={24} md={4}>
              <Form.Item name="maSoGCS" label="Mã sổ">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={5}>
              <Form.Item name="loaiBangKe" label="Loại bảng kê">
                <Select placeholder="Tất cả" allowClear>
                  {loaiBangKe && loaiBangKe.length > 0
                    ? loaiBangKe.map((item, index) => {
                        if (item.value !== null) {
                          return (
                            <Option key={index} value={item.value.toString()}>
                              {item.name}
                            </Option>
                          );
                        }
                        return null;
                      })
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={5}>
              <Form.Item name="trangThaiId" label="Trạng thái">
                <Select defaultValue={firstTrangThai} allowClear>
                  {trangThai && trangThai.length > 0
                    ? trangThai.map((item, index) => {
                        if (item.value !== null) {
                          return (
                            <Option key={index} value={item.value.toString()}>
                              {item.name}
                            </Option>
                          );
                        }
                        return null;
                      })
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={4}>
              <Form.Item name="ngayBatDau" label="Ngày phúc tra">
                <DatePickerComponent
                  defaultValue={
                    filterConditions.ngayTaoTuNgay
                      ? moment(filterConditions.ngayTaoTuNgay, FORMAT_ENGLISH)
                      : moment(moment(), FORMAT_DATE)
                  }
                  format={FORMAT_ENGLISH}
                  form={form}
                  formKey={"ngayTaoTuNgay"}
                  notClear={true}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={4}>
              <Form.Item name="ngayPhucTraDenNgay" label=" ">
                <DatePickerComponent
                  defaultValue={
                    filterConditions.ngayTaoDenNgay
                      ? moment(filterConditions.ngayTaoDenNgay, FORMAT_ENGLISH)
                      : moment(moment(), FORMAT_DATE)
                  }
                  format={FORMAT_ENGLISH}
                  form={form}
                  formKey={"ngayTaoDenNgay"}
                  notClear={true}
                />
              </Form.Item>
            </Col>

            <Col span={24} md={4}>
              <Form.Item name="searchTerm" label="Từ khóa">
                <Input
                  prefix={<SearchOutlined />}
                  suffix={
                    <Tooltip title={tooltip}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={3}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Item name="isTraLai" style={{ margin: 0 }}>
                <Checkbox
                  onChange={checkTraLai}
                  defaultChecked={filterConditions.isCanhBao ? true : false}
                >
                  Bị trả lại
                </Checkbox>
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Button className="mr-24px" onClick={clear}>
                Bỏ lọc
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                form="filter-form"
                className="mr-24px"
              >
                Tìm kiếm
              </Button>
            </Col>
          </Fragment>
        )} */}
      </Row>
    </FormComponent>
  );
}
