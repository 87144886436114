import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { Style } from "@modules/dang-ky-thanh-toan/css-styled";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import { buildQueryString, handlePagination, parseParams, removeUndefinedAttribute } from "@utils/function";
import { Button, Col, Form, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Boloc from "./bo-loc";
import EditModal from "./modal";
import { deleteDoccument, getListLoaiTaiLieu, getListTaiLieu } from "./services";
import { StyledAction } from "./styled";

function QuanLyThongTinHoTroKhachHang() {
  const [form] = Form.useForm();
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  // Table state
  const [listLoading, setListLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();

  const [listLoaiTaiLieu, setListLoaiTaiLieu] = useState([]);

  const getOptionLoaiTaiLieu = () => {
    getListLoaiTaiLieu(() => {}, setListLoaiTaiLieu);
  };

  useEffect(() => {
    getOptionLoaiTaiLieu();
  }, []);

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  const [dataRecord, setDataRecord] = useState({});

  //Modal Dong Bo CMIS
  const [openModal, setOpenModal] = useState(false);
  // Search by filter
  const handleSearch = (values) => {
    setFilterConditions({ ...filterConditions, ...values });
  };

  const handleGetListTaiLieu = () => {
    const query = buildQueryString({ ...filterConditions });
    getListTaiLieu({
      setLoading: setListLoading,
      query,
      onSuccess: (res) => {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              stt:
              (filterConditions.pageIndex - 1) * filterConditions.pageSize +
              (index + 1),
            };
          })
        );
        setTotal(res.paging.totalCount);
      },
    });
  };

  useEffect(() => {
    handleGetListTaiLieu();
    form.setFieldsValue({ ...filterConditions });
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [filterConditions]);

  // Search by default condition
  const clearFilter = () => {
    form.resetFields();
    setFilterConditions({ ...filterConditions, loaiTaiLieuId: "", tenTaiLieu: "" });
  };

  // onChange PageSize and PageIdex
  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const onEditRecord = (record) => {
    setDataRecord(record);
    setOpenModal(true);
  };

  const onDeleteRecord = (record) => {
    deleteDoccument({
      id: record.id,
      onSuccess: () => {
        handleGetListTaiLieu();
      },
    });
  };

  const updateSucces = () => {
    handleGetListTaiLieu();
    setOpenModal(false);
    getOptionLoaiTaiLieu();
    setDataRecord({});
  };

  const columns = [
    {
      title: "STT",
      width: "8%",
      align: "center",
      dataIndex: "stt",
    },
    {
      title: "Phân loại",
      dataIndex: "tenLoaiTaiLieu",
      width: "20%",
      align: "left",
    },
    {
      title: "Tên tài liệu",
      dataIndex: "tenTaiLieu",
      width: "25%",
      align: "left",
      render: (value, record) => {
        return (
          <a href={record.fileUrl} target="_blank" rel="noreferrer">
            {value}
          </a>
        );
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "ngayTao",
      width: "15%",
      align: "left",
    },
    {
      title: "Người tạo",
      dataIndex: "nguoiTao",
      width: "20%",
      align: "left",
    },
    {
      title: "Tác vụ",
      dataIndex: "aciton",
      // width: "12%",
      align: "center",
      render: (value, record) => {
        return (
          <StyledAction>
            <Tooltip title="Xóa">
              <Button onClick={() => onDeleteRecord(record)}>
                <TrashIcon style={{ cursor: "pointer" }} />
              </Button>
            </Tooltip>
            <Tooltip title="Sửa">
              <Button onClick={() => onEditRecord(record)}>
                <PencilIcon />
              </Button>
            </Tooltip>
          </StyledAction>
        );
      },
    },
  ];

  // Handle modal Dong bo CMIS
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setDataRecord({});
  };

  return (
    <Style>
      <div className="dang-ky-thanh-toan">
        <Filter>
          <Boloc
            form={form}
            filterConditions={filterConditions}
            handleSearch={handleSearch}
            clearFilter={clearFilter}
            listLoaiTaiLieu={listLoaiTaiLieu}
          />
        </Filter>

        <div className="dang-ky-thanh-toan-content">
          <Row gutter={24}>
            <Col span={5} offset={19}>
              <div className="dang-ky-thanh-toan-content-button">
                <Button type="primary" onClick={() => handleOpenModal()} className="btn">
                  Upload file
                </Button>
              </div>
            </Col>
          </Row>
          <TableComponent
            dataSource={data}
            columns={columns}
            expandable={false}
            header="Danh sách"
            rowKey="key"
            totalData={total}
            pagination={filterConditions}
            onChangePagination={onChangePagination}
            loading={listLoading}
          />
        </div>
      </div>
      {openModal && (
        <EditModal
          open={openModal}
          handleCloseModal={handleCloseModal}
          listLoaiTaiLieu={listLoaiTaiLieu}
          handleGetListTaiLieu={handleGetListTaiLieu}
          setOpenModal={setOpenModal}
          dataRecord={dataRecord}
          updateSucces={updateSucces}
        />
      )}
    </Style>
  );
}

export default QuanLyThongTinHoTroKhachHang;
