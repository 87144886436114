import {
  FORMAT_ENGLISH,
  METHOD_GET,
  METHOD_POST,
  METHOD_PUT,
  STATUSCODE_200,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { getErrorForm } from "@utils/function";
import { authGetData, authPostData, startDelete } from "@utils/request";
import moment from "moment";
import { getDanhSachDiemDo } from "../services";
export const handlerSubmitDiemDo = (
  payload,
  setData,
  values,
  closeModal,
  form,
  filterConditions,
  setLoading,
  setDataDanhSach,
  setLoadingDanhSach,
  getDanhSachDiemDo,
  dispatch
) => {
  authPostData({
    url: Endpoint.KHAI_BAO_DIEM_DO,
    method: METHOD_POST,
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setData(res.data);
        getDanhSachDiemDo(
          filterConditions,
          setLoadingDanhSach,
          setDataDanhSach
        );
        dispatch(closeModal());
      } else {
        getErrorForm(res, form);
      }
    },
  });
};

export const handlerSubmitThayDoiDiemDo = (
  payload,
  closeModal,
  setLoadingDanhSach,
  form,
  filterConditions,
  dispatch,
  setDataDanhSach,
  getDanhSachDiemDo,
  setLoading
) => {
  authPostData({
    url: Endpoint.THAY_DOI_DIEM_DO,
    method: METHOD_POST,
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getDanhSachDiemDo(
          filterConditions,
          setLoadingDanhSach,
          setDataDanhSach
        );
        dispatch(closeModal());
      } else {
        getErrorForm(res, form);
      }
    },
  });
};
// huyr thay doi diem do
export const handlerXoaTraCuuThongTinDiemDo = (
  setLoading,
  id,
  setFetchList,
  filterConditions,
  setLoadingDanhSach,
  setDataDanhSach,
  setFilterConditions,
  handleCloseModal,
  dispatch,
  setIndexPage,
  indexPage
) => {
  startDelete({
    url: `${Endpoint.XOA_DIEM_DO}?Id=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchList(true);
        setFilterConditions("");
        getDanhSachDiemDo(
          filterConditions,
          setLoadingDanhSach,
          setDataDanhSach,
          setIndexPage,
          indexPage.pageSize
        );
      }
      dispatch(handleCloseModal());
    },
  });
};

//hủy thay đổi phương thức

export const handleHuyPhuongThuc = (
  setLoading,
  data,
  handleCloseModal,
  setFetchList,
  getListChiSoPhuongThuc
) => {
  authPostData({
    url: Endpoint.HUY_PHUONG_THUC,
    method: METHOD_PUT,
    payload: {
      arrayId: data,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        handleCloseModal();
        getListChiSoPhuongThuc(setLoading);
        setFetchList(true);
      }
    },
  });
};

// thay doi phuong thuc
export const handlerThayDoiPhuongThuc = (
  values,
  closeModal,
  setLoading,
  form,
  payload,
  filterConditions,
  setDataDanhSach,
  setLoadingDanhSach,
  dispatch,
  getDanhSachDiemDo
) => {
  authPostData({
    url: Endpoint.THAY_DOI_PHUONG_THUC,
    method: METHOD_POST,
    payload,
    setLoading: () => {},
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getDanhSachDiemDo(
          filterConditions,
          setLoadingDanhSach,
          setDataDanhSach
        );
        dispatch(closeModal());
      } else {
        getErrorForm(res, form);
      }
    },
  });
};
// sua phuong thuc
export const handlerSuaPhuongThuc = (
  values,
  setDataSuaPhuongThuc,
  arrayId,
  closeModal,
  setLoading,
  form,
  payload,
  filterConditions,
  setDataDanhSach,
  setLoadingDanhSach,
  dispatch,
  getDanhSachDiemDo
) => {
  authPostData({
    url: Endpoint.SUA_DOI_PHUONG_THUC,
    method: METHOD_POST,
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataSuaPhuongThuc(res.data);
        getDanhSachDiemDo(filterConditions, setLoading, setDataDanhSach);
        dispatch(closeModal());
      } else {
        getErrorForm(res, form);
      }
    },
  });
};

// lay phuong thuc trong màn thay đổi
export const getLayPhuongThuc = (
  setDataPhuongThuc,
  setLoading,
  closeModal,
  form,
  detail
) => {
  authGetData({
    url: `${Endpoint.LAY_PHUONG_THUC_TAB}?DiemDoId=${detail.id}`,
    setLoading: () => {},
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataPhuongThuc(res.data);
      }
    },
  });
};
// lấy chỉ số chốt màn thay đổi phương thức
export const getLayChiSoPhuongThuc = (
  setDataChiSoTable,
  setDataChiSoTableRight,
  setLoading,
  form,
  query
) => {
  authGetData({
    url: `${Endpoint.LAY_CHI_SO_THAY_DOI_PHUONG_THUC}?${query}`,
    setLoading: () => {},
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataChiSoTableRight(res.data.danhSachChiSoHienTai);
        setDataChiSoTable(res.data.danhSachChiSoCu);
      }
    },
  });
};
// lay phuong thuc màn sửa phương thức
export const getLayPhuongThucManSua = (
  setDataPhuongThucManSuaLeft,
  setDataPhuongThucManSuaRight,
  setLoading,
  closeModal,
  form,
  detail
) => {
  authGetData({
    url: `${Endpoint.LAY_PHUONG_THUC_MAN_SUA}?id=${detail.id}`,
    setLoading: () => {},
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataPhuongThucManSuaLeft(res.data.ptCu);
        setDataPhuongThucManSuaRight(res.data.ptHienTai);
      }
    },
  });
};

// lấy chỉ số chốt màn sửa phương thức
export const getListSuaPhuongThuc = (
  setDataChiSoTableSua,
  setDataChiSoTableRightSua,
  setLoading,
  form,
  query
) => {
  authGetData({
    // url: `${Endpoint.LAY_CHI_SO_SUA_PHUONG_THUC}?id=${detail.id}`,
    url: `${Endpoint.LAY_CHI_SO_SUA_PHUONG_THUC}?${query}`,
    setLoading: () => {},
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataChiSoTableRightSua(res.data.danhSachChiSoHienTai);
        setDataChiSoTableSua(res.data.danhSachChiSoCu);
      }
    },
  });
};

export const TaoMaDiemDo = (setTaoMa, setLoading, form, closeModal) => {
  authGetData({
    url: Endpoint.TAO_MA_DD_NGAY_HIEU_LUC,
    method: METHOD_GET,
    setLoading: () => {},
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setTaoMa(res.data);
        closeModal();
      } else {
        getErrorForm(res, form);
      }
    },
  });
};

// thay doi thong tin diem do

export const TaoMaNgayHieuLucThayDoi = (
  setTaoMaHieuLuc,
  setLoading,
  form,
  detail
) => {
  authGetData({
    url: `${Endpoint.TAO_NGAY_HIEU_LUC_THAY_DOI}?DiemDoId=${detail.id}`,
    method: METHOD_GET,
    setLoading: () => {},
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setTaoMaHieuLuc(res.data);
      } else {
        getErrorForm(res, form);
      }
    },
  });
};

//  lay thong tin diem do

export const getLayThongTin = (
  filterConditions,
  setLoading,
  setDataThongTin,
  detail
) => {
  authGetData({
    url: `${Endpoint.LAY_THONG_TIN_DIEM_DO}?DiemDoId=${detail.id}`,
    setLoading,
    onSuccess: (res) => {
      setDataThongTin(res.data);
    },
  });
};

//  get anh xa
export const getAnhXaDiemDo = (
  filterConditions,
  setLoading,
  setDataAnhXa,
  detail
) => {
  authGetData({
    url: `${Endpoint.GET_ANH_XA_DIEM_DO}?DiemDoId=${detail.id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataAnhXa(res.data);
      }
    },
  });
};

// cap dien ap
export const getCapDienAp = (filterConditions, setLoading, setUrlCapDienAp) => {
  authGetData({
    url: `${Endpoint.GET_SELECTION_CAP_DIEN_AP}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setUrlCapDienAp(res.data);
      }
    },
  });
};

// get httt
export const getHinhThucThanhToan = (
  filterConditions,
  setLoading,
  setUrlHTTT
) => {
  authGetData({
    url: `${Endpoint.GET_SELECTION_HINH_THUC_TT}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setUrlHTTT(res.data);
      }
    },
  });
};

// get url tinh chat diem do
export const getTinhChatDD = (
  filterConditions,
  setLoading,
  setUrlTinhChatDD
) => {
  authGetData({
    url: `${Endpoint.GET_SELECTION_HT_DIEM_DO}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setUrlTinhChatDD(res.data);
      }
    },
  });
};

// get url tram
export const getTram = (setLoading, setUrlTram, valueDD) => {
  if (valueDD) {
    authGetData({
      url: `${Endpoint.GET_SELECTION_TRAM}?TinhChatDiemDoId=${valueDD}`,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          setUrlTram(res.data);
        }
      },
    });
  }
};

// get url tram
export const getDonViGN = (setLoading, setUrlDvgn) => {
  authGetData({
    url: `${Endpoint.GET_SELECTION_DVGN}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setUrlDvgn(res.data);
      }
    },
  });
};

// get thông tin điểm đo

export const getTTDiemDo = (setLoading, setDataThongTin, id) => {
  authGetData({
    url: `${Endpoint.LAY_THONG_TIN_DIEM_DO}?DiemDoId=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataThongTin(res.data);
      }
    },
  });
};

// call api lấy lịch sử phương thức

export const getLichSuPT = (
  setLoading,
  setDataTablePT,
  maDiemDo,
  setDataChiSoPTTable
) => {
  authGetData({
    url: `${Endpoint.LAY_LICH_SU_PHUONG_THUC}?MaDiemDo=${maDiemDo}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataTablePT(res.data);
        const firstCall = true;
        getChiSoPT(
          setLoading,
          setDataChiSoPTTable,
          maDiemDo,
          res.data[0]?.ngayThayDoiCu,
          firstCall
        );
      }
    },
  });
};

// call api lấy chỉ số lịch sử phương thức

export const getChiSoPT = (
  setLoading,
  setDataChiSoPTTable,
  maDiemDo,
  record,
  firstCall
) => {
  authGetData({
    url: `${Endpoint.LAY_CHI_SO_LS_PHUONG_THUC}?maDiemDo=${maDiemDo}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        const newArray = [];

        res.data.map((item) => {
          const newdate = firstCall
            ? moment(record).format(FORMAT_ENGLISH)
            : moment(record.ngayThayDoiCu).format(FORMAT_ENGLISH);
          const newdatePT = moment(item.ngayCuoiKy).format(FORMAT_ENGLISH);
          if (newdate === newdatePT) {
            return newArray.push(item);
          }
          return null;
        });
        setDataChiSoPTTable(
          newArray[0]?.chiSo.map((e) => ({
            ...e,
          }))
        );
      }
    },
  });
};
// call api lấy lịch sử điểm đo

export const getLichSuDiemDo = (
  setLoading,
  setDataTableDD,
  maDiemDo,
  dispatch,
  closeModal
) => {
  authGetData({
    url: `${Endpoint.LAY_LICH_SU_DIEM_DO}?MaDiemDo=${maDiemDo}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataTableDD(res.data);
      }
      if (res.data?.length === 0) {
        dispatch(closeModal());
      }
    },
  });
};

export function covertThousand(n) {
  const parts = n.toString().split(".");
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return (
    numberPart.replace(thousands, " ") + (decimalPart ? "." + decimalPart : "")
  );
}
