import { useCallback, useEffect, useState } from "react";
import ViewImage from "./view-image";

export default function PreviewImage(props) {
  const { srcImg, isOpen, setIsOpen } = props;

  const [disabledZoomIn, setDisabledZoomIn] = useState(false);
  const [disabledZoomOut, setDisabledZoomOut] = useState(true);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [transform, setTransform] = useState(`scale3d(1,1,1) rotate(0deg)`);

  const escFunction = useCallback(
    (event) => {
      if (event.key === "Escape") {
        //Do whatever when esc is pressed
        setIsOpen(false);
      }
    },
    [setIsOpen]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const handleChange = (string) => {
    let x = scale;
    let y = rotate;
    let zoomIn = disabledZoomIn;
    let zoomOut = disabledZoomOut;

    if (string === "zoomIn") {
      x += 1;
    } else if (string === "zoomOut") {
      x -= 1;
    } else if (string === "rotateLeft") {
      y -= 90;
    } else {
      y += 90;
    }

    if (x > 5) {
      x = 5;
      zoomIn = true;
    } else if (x <= 1) {
      x = 1;
      zoomOut = true;
    } else {
      zoomIn = false;
      zoomOut = false;
    }

    setScale(x);
    setRotate(y);
    setTransform(`scale3d(${x},${x},1) rotate(${y}deg)`);
    setDisabledZoomIn(zoomIn);
    setDisabledZoomOut(zoomOut);
  };

  return (
    <ViewImage
      showModal={isOpen}
      setShowModal={setIsOpen}
      handleChange={handleChange}
      modalSrc={srcImg}
      transform={transform}
      isSilder={false}
      disabledZoomIn={disabledZoomIn}
      disabledZoomOut={disabledZoomOut}
    />
  );
}
