import { customColumn } from "@utils/function";
import TableComponent from "@components/TableComponent";
import { Container } from "./css-styled";

export default function Index(props) {
  const {
    dataSource,
    columns,
    totalCount,
    loading,
    filterConditions,
    onChangePagination,
  } = props;

  return (
    <Container>
      <TableComponent
        header={"Cấu hình hồ sơ"}
        dataSource={dataSource}
        columns={customColumn(columns, filterConditions)}
        totalData={totalCount}
        loading={loading}
        pagination={filterConditions}
        onChangePagination={onChangePagination}
        scrollY={550}
      />
    </Container>
  );
}
