import { Form } from "antd";
import { handleSubmitTongHop, handleGetNewDate } from "../../services";
import { useCallback, useEffect, useState } from "react";
import TongHopSoLieu from "./layout";
import moment from "moment";
import { FORMAT_ENGLISH } from "@utils/constants";

export default function Index(props) {
  const { user } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");

  //submit
  const onFinish = (values) => {
    const ngayBaoCao = moment(values.ngayBaoCao).format(FORMAT_ENGLISH);
    handleSubmitTongHop(ngayBaoCao, setLoading, getNewDate);
  };

  //lấy ngày giờ tổng hợp mới nhất
  const getNewDate = useCallback((ngayBaoCao) => {
    handleGetNewDate(ngayBaoCao, setLoading, setData);
  }, []);

  useEffect(() => {
    const ngayBaoCao = moment().format(FORMAT_ENGLISH);
    getNewDate(ngayBaoCao);
  }, [getNewDate]);

  //change date call get new date
  const handleChangeDate = (date) => {
    let convert = "";
    if (date === null) convert = undefined;
    else convert = moment(date).format(FORMAT_ENGLISH);
    handleGetNewDate(convert, setLoading, setData);
  };
  return (
    <TongHopSoLieu
      loading={loading}
      form={form}
      onFinish={onFinish}
      user={user}
      data={data}
      handleChangeDate={handleChangeDate}
    />
  );
}
