import Filter from "@components/Filter/Filter";
import TableHideColumns from "../common/show-hide-columns";

import { Fragment } from "react";
import { Container } from "./css-styled";
import ListFilter from "./list-bo-loc.jsx";
import { customColumn } from "@utils/function";
import { TYPE_HIDE_SHOW_COLUMNS } from "@utils/constants";

export default function BaoCaoChiTietTable(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    totalCount,
    loading,
    onChangePagination,
    getListColumns,
    columns,
    data,
    handleChange,
    value,
    setType,
  } = props;
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          handleChange={handleChange}
          value={value}
          setType={setType}
        />
      </Filter>
      <Container>
        <TableHideColumns
          header={"Báo cáo danh sách chậm tiến độ"}
          totalData={totalCount}
          loading={loading}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
          columns={customColumn(columns, filterConditions)}
          dataSource={data}
          titleHeader={"Cấu hình cột cho bảng báo cáo chậm tiến độ"}
          type={TYPE_HIDE_SHOW_COLUMNS.CHAMTIENDO}
          getListColumns={getListColumns}
          lengthFixed={5}
        />
      </Container>
    </Fragment>
  );
}
