import { authDeleteData, authGetData, authPostData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import {
  alertMessage,
  buildQueryString,
  getErrorMessage,
  parseParams,
} from "@utils/function";
export const getListGCSService = (
  filterConditions,
  setLoading,
  setDataSoGhiChiSo,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.LIST_SO_GHI_CHI_SO}?${buildQueryString(
      parseParams(filterConditions)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataSoGhiChiSo(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataSoGhiChiSo([]);
    },
  });
};
export const getListDonViService = (setLoading, setDataDonVi) => {
  authGetData({
    url: `${Endpoint.GET_UNIT_LIST}?pageSize=-1`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDonVi(res.data);
      } else setDataDonVi([]);
    },
  });
};
export const deleteSGCSService = (ids, setLoading, getListGhiChiSo, t) => {
  authDeleteData({
    url: Endpoint.REMOVE_SO_GHI_CHI_SO + "?IDs=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) getListGhiChiSo();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

export const handleCreateUpdateService = (
  data,
  setLoading,
  getListGhiChiSo,
  closeModal,
  form
) => {
  // if (data.id) {
    authPostData({
      url: Endpoint.UPDATE_SO_GHI_CHI_SO,
      method: METHOD_POST,
      payload: data,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          getListGhiChiSo();
          closeModal();
        } else {
          getErrorMessage(res, form);
        }
      },
    });
  // } else {
  //   authPostData({
  //     url: Endpoint.CREATE_SO_GHI_CHI_SO,
  //     method: METHOD_POST,
  //     payload: data,
  //     setLoading,
  //     onSuccess: (res) => {
  //       if (res.statusCode === STATUSCODE_200 && res.data) {
  //         getListGhiChiSo();
  //         closeModal();
  //       } else {
  //         getErrorMessage(res, form);
  //       }
  //     },
  //   });
  // }
};

export const getDongBoDataMaster = (
  getListGhiChiSo,
  setLoading
) => {
  authGetData({
    url: `${Endpoint.DONG_BO_MASTER_TO_CMIS}?tableIndex=18`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        getListGhiChiSo()
      }
    },
  });
};
