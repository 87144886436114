import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import CheckPermission from "@components/CheckPermission";
import { tien_ich_chung } from "@permissions/m_tienichchung";
import CauHinhDongBoModal from "./modal-cau-hinh-dong-bo";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import { handleRemoveData } from "./services";
import { handleListData } from "./services";
import Table from "./table";
import { CheckCircleFilled } from "@ant-design/icons";
import EllipsisTooltip from "@components/EllipsisTooltip";
export default function Index() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [detailCauHinhDongBo, setDetailCauHinhDongBo] = useState({});
  const [dataCauHinhDongBo, setDataCauHinhDongBo] = useState([]);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const getListData = useCallback(() => {
    handleListData(
      setLoading,
      filterConditions,
      setDataCauHinhDongBo,
      setTotalCount
    );
  }, [filterConditions]);

  useEffect(() => {
    getListData();
    // eslint-disable-next-line
  }, [getListData]);

  //thay đổi pagination table

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //khai bái columns

  const columns = [
    {
      title: "Tên cấu hình",
      dataIndex: "tenCauHinh",
      key: "tenCauHinh",
      width: "15%",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Từ ngày",
      dataIndex: "tuNgay",
      key: "tuNgay",
      width: "9%",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Đến ngày",
      dataIndex: "denNgay",
      key: "denNgay",
      width: "9%",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Giờ",
      dataIndex: "gio",
      key: "gio",
      width: "5%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Phút",
      dataIndex: "phut",
      key: "phut",
      width: "5%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Giây",
      dataIndex: "giay",
      key: "giay",
      width: "5%",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Mô tả",
      dataIndex: "moTa",
      key: "moTa",
      width: "12%",
      render: (text) => <EllipsisTooltip title={text}>{text}</EllipsisTooltip>,
      sorter: true,
    },
    {
      title: "Route name",
      dataIndex: "routeName",
      key: "routeName",
      width: "25%",
      render: (text) => <EllipsisTooltip title={text}>{text}</EllipsisTooltip>,
      sorter: true,
    },
    {
      title: "Chạy tự động",
      dataIndex: "runJob",
      key: "runJob",
      width: "12%",
      ellipsis: true,
      render: (actived) => (
        <div className="table-text-center">
          {actived ? (
            <CheckCircleFilled style={{ fontSize: "20px", color: "#03A4FF" }} />
          ) : null}
        </div>
      ),
    },
    {
      title: t("tac_vu"),
      key: "action",
      align: "center",
      width: "8%",
      render: (row) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={tien_ich_chung.m116s}>
            <div className="action-icon" onClick={() => handleOpenModal(row)}>
              <PencilIcon />
            </div>
          </CheckPermission>
          <CheckPermission permissionCode={tien_ich_chung.m116x}>
            <div
              className="action-icon"
              onClick={() => handleDeleteData(row.id)}
            >
              <TrashIcon />
            </div>
          </CheckPermission>
        </div>
      ),
    },
  ];

  //tìm kiếm dữ liệu

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);

  //clear search
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);
  // Hiển thị  các buttom tạo mới, ...

  const genExtra = () => (
    <div>
      <CheckPermission permissionCode={tien_ich_chung.m116t}>
        <Button
          size="small"
          type="primary"
          className="mr-5px"
          onClick={() => handleOpenModal()}
        >
          Tạo mới
        </Button>
      </CheckPermission>
    </div>
  );

  //xóa dữ liệu

  const handleDeleteData = useCallback(
    (ids) => {
      handleRemoveData(ids, setLoading, getListData, t);
    },
    [getListData, t]
  );

  //hiển thị popup, set dữ liệu chi tiết

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDetailCauHinhDongBo(row);
      else setDetailCauHinhDongBo({});
      setVisible(!visible);
    },
    [visible]
  );
  //đóng popup

  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
  };
  //render popup

  const showModal = useMemo(
    () =>
      visible && (
        <CauHinhDongBoModal
          detaiDataTable={detailCauHinhDongBo}
          visible={visible}
          closeModal={handleCloseModal}
          getListData={getListData}
        />
      ),
    // eslint-disable-next-line
    [visible]
  );

  return (
    <Fragment>
      <Table
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        genExtra={genExtra}
        dataSource={dataCauHinhDongBo}
        columns={columns}
        totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        t={t}
      />
      {showModal}
    </Fragment>
  );
}
