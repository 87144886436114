import React, { Fragment, memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import FormDetailThongTin from "./get-form-lap-phuong-an";
import { useSelector } from "react-redux";
import { lapPhuongAnSelector } from "@state/cap-dien/nhan-vien-khao-sat/lap-phuong-an/reducer";
import {
  notSavedPhuongAnCapDien,
  notSavedThongTinCoBan,
  rangeOfChange,
  setCanShowDialogLeavingPACD,
} from "@state/cap-dien/nhan-vien-khao-sat/lap-phuong-an/reducer";
import ModalWarningChange from "@components/Layout/ModalWarningChange";
// import { useNavigatingAway } from "@hooks/useNavigatingAway";

function LapPhuongAnDetail({
  detailLapPhuongAn,
  closeModal,
  setFetchDataTable,
}) {
  const { t } = useTranslation();
  const {
    phuongAnCapDien,
    thongTinCoBan,
    // canShowDialogLeavingPage
  } = useSelector(lapPhuongAnSelector);
  const dispatch = useDispatch();
  const [indexTab, setIndexTab] = useState("1");
  const [formName, setFormName] = useState("");
  const [notSaved, setNotSaved] = useState({
    phuongAnCapDien: false,
    thongTinCoBan: false,
  });
  const [initPACD, setInitPACD] = useState(false);
  const [initTTCB, setInitTTCB] = useState(false);
  // const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
  //   useNavigatingAway(canShowDialogLeavingPage);

  useEffect(() => {
    if (phuongAnCapDien || thongTinCoBan) {
      dispatch(setCanShowDialogLeavingPACD(true));
    } else {
      dispatch(setCanShowDialogLeavingPACD(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phuongAnCapDien, thongTinCoBan]);

  // useEffect(() => {
  //   if (showDialogLeavingPage) {
  //     ModalWarningChange(
  //       () => {
  //         confirmNavigation();
  //         dispatch(rangeOfChange(false));
  //         if (phuongAnCapDien) dispatch(notSavedPhuongAnCapDien(false));
  //         if (thongTinCoBan) dispatch(notSavedThongTinCoBan(false));
  //       },
  //       () => {
  //         cancelNavigation();
  //       }
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [cancelNavigation, confirmNavigation, showDialogLeavingPage]);

  const callback = (key) => {
    dispatch(rangeOfChange(true));
    if (phuongAnCapDien || thongTinCoBan) {
      ModalWarningChange(
        () => {
          setIndexTab(key);
          if (phuongAnCapDien) {
            dispatch(notSavedPhuongAnCapDien(false));

            //Phuong An Cap dien
            setInitPACD(true);
          }
          if (thongTinCoBan) {
            dispatch(notSavedThongTinCoBan(false));
            //Thông tin cơ bản
            setInitTTCB(true);
          }
        },
        () => false
      );
    } else {
      setIndexTab(key);
    }
  };

  useEffect(() => {
    if (indexTab === "1") setFormName("form-lap-phuong-an-info");
    if (indexTab === "4") setFormName("form-phuong-an-cap-dien");
  }, [indexTab]);

  return (
    <Fragment>
      <FormDetailThongTin
        indexTab={indexTab}
        callback={callback}
        detailLapPhuongAn={detailLapPhuongAn}
        setNotSaved={setNotSaved}
        t={t}
        notSaved={notSaved}
        initTTCB={initTTCB}
        setInitTTCB={setInitTTCB}
        setFetchDataTable={setFetchDataTable}
        initPACD={initPACD}
        setInitPACD={setInitPACD}
        dispatch={dispatch}
        closeModal={closeModal}
        formName={formName}
      />
    </Fragment>
  );
}

export default memo(LapPhuongAnDetail);
