import { memo, useRef, useState, useEffect } from "react";
import { Form, Input, Button, Modal, Row, Col, Checkbox } from "antd";
import FormComponent from "@components/Form";
import { useTranslation } from "react-i18next";
import LoadingComponent from "@components/Loading";
import { createOrUpdateGiayTo } from "./services";

const { TextArea } = Input;

function LoaiHoSoModal({ detailGiayTo, visible, closeModal, getListGiayTo }) {
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values) => {
    const data = {
      ...values,
    };
    if (detailGiayTo.id) {
      data.id = detailGiayTo.id;
    }
    handleCreateUpdate(data, form);
  };

  useEffect(() => {
    if (visible) inputRef.current.focus();

    // eslint-disable-next-line
  }, []);

  //xử lý call api sửa/tạo

  const handleCreateUpdate = (data, form) => {
    createOrUpdateGiayTo(data, setLoading, getListGiayTo, closeModal, form);
  };

  return (
    <Modal
      title={detailGiayTo.id ? "Cập nhật loại hồ sơ" : "Tạo mới loại hồ sơ"}
      visible={visible}
      onCancel={closeModal}
      className="modal"
      footer={null}
    >
      <LoadingComponent loading={loading}>
        <FormComponent
          autoComplete="off"
          form={form}
          name="form-submit"
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            ...detailGiayTo,
            loaiDDO: detailGiayTo.loaiDDO ? detailGiayTo.loaiDDO : 1,
          }}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Mã loại hồ sơ"
                name="maLoaiHoSo"
                className="required-field"
              >
                <Input placeholder="Nhập mã loại hồ sơ" ref={inputRef} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Tên loại hồ sơ"
                name="tenLoaiHoSo"
                className="required-field"
              >
                <Input placeholder="Nhập tên loại hò sơ" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Mô tả" name="moTa">
                <TextArea placeholder="Nhập mô tả loại hồ sơ" row={3} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="" name="isTreoThao" valuePropName="checked">
                <Checkbox>Sử dụng ở module treo tháo</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={closeModal} className="button-closed">
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                type="primary"
                className="button-primary"
                htmlType="submit"
                form="form-submit"
                loading={loading}
              >
                {detailGiayTo.id ? t("button.cap_nhat") : t("button.tao_moi")}
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </LoadingComponent>
    </Modal>
  );
}

export default memo(LoaiHoSoModal);
