import { CloseCircleTwoTone, UploadOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Col, Form, Input, message, Modal, notification, Row, Tooltip } from "antd";
import { isEmpty } from "lodash";
import React, { useRef, useState } from "react";
import { createOrUpdateDocument } from "../services";
import { StyleIconClose, StyleModal, StyleNote } from "../styled";

const { Option } = AutoComplete;

// 50MB
const fileMaxSize = 50000000;

message.config({
  duration: 5,
  maxCount: 1,
});

function EditModal(props) {
  const { open, handleCloseModal, listLoaiTaiLieu, dataRecord, updateSucces } = props;

  const [loading, setLoading] = useState(false);

  const fileRef = useRef();

  const [form] = Form.useForm();

  const [fileName, setFileName] = useState(dataRecord.fileUrl);

  const handleSubmit = (values) => {
    const fileSize = form.getFieldValue("file")?.size;
    if(fileSize > fileMaxSize){
      message.error(
        "Dung lượng File không lớn hơn 50Mb",
      );
      return;
    }
    createOrUpdateDocument({
      setLoading: setLoading,
      payload: { ...values, file: form.getFieldValue("file") || null, id: dataRecord.id },
      onSuccess: () => {
        updateSucces();
      },
      method: isEmpty(dataRecord) ? "POST" : "PUT",
    });
  };

  const initialValues = () => {
    return {
      tenTaiLieu: dataRecord.tenTaiLieu,
      tenLoaiTaiLieu: dataRecord.tenLoaiTaiLieu,
      file: dataRecord.file,
    };
  };

  const openInputFile = () => {
    fileRef && fileRef.current && fileRef.current.click();
  };

  const importFile = async (e, fileRef) => {
    try {
      const { files } = e.target;
      const file = files?.[0] || null;
      form.setFieldsValue({ file: file });
      setFileName(file.name);
      fileRef.current.value = null;
    } catch (err) {}
  };

  const renderFileName = () => {
    if (fileName) {
      return (
        <Row gutter={24} className="mb-15px">
          <Col span={22} className="trun-cate">
            <Tooltip title={fileName}>{fileName}</Tooltip>
          </Col>
          <Col span={2}>
            <StyleIconClose
              onClick={() => {
                form.setFieldsValue({ file: "" });
                setFileName("");
              }}
            >
              <CloseCircleTwoTone className="icon" />
            </StyleIconClose>
          </Col>
        </Row>
      );
    }
    return "";
  };

  const onBlur = (event, fieldName) => {
    const { value } = event.target;
    form.setFieldsValue({[fieldName]: value.trim()});
  }

  return (
    <Modal
      title="Upload tài liệu"
      visible={open}
      onCancel={handleCloseModal}
      footer={null}
      destroyOnClose
      centered
      maskClosable={false}
    >
      <Form form={form} onFinish={handleSubmit} initialValues={initialValues()} autoComplete="off" name="edit_document">
        <StyleModal>
          <Row gutter={24} justify="center" style={{ marginTop: "20px" }}>
            {/* Tên tài liệu */}
            <>
              <Col span={4}>
                <div>Tên tài liệu</div>
              </Col>

              <Col span={2}>
                <div>(*)</div>
              </Col>
              <Col span={18}>
                <Form.Item name="tenTaiLieu" label="" rules={[{ required: true, message: "Tên tài liệu là bắt buộc" }]}>
                  <Input allowClear onBlur={(e) => onBlur(e, "tenTaiLieu")}/>
                </Form.Item>
              </Col>
            </>

            {/* Loai tai lieu */}
            <>
              <Col span={4}>
                <div>Phân loại</div>
              </Col>

              <Col span={2}>
                <div>(*)</div>
              </Col>

              <Col span={18}>
                <Form.Item
                  name="tenLoaiTaiLieu"
                  label=""
                  rules={[{ required: true, message: "Phân loại tài liệu là bắt buộc" }]}
                >
                  <AutoComplete allowClear onBlur={(e) => onBlur(e, "tenLoaiTaiLieu")}>
                    {listLoaiTaiLieu.map((item) => {
                      return (
                        <Option value={item.name} key={item.value}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </AutoComplete>
                </Form.Item>
              </Col>
            </>

            {/* File */}
            <>
              <Col span={4}>
                <div>Chọn file</div>
              </Col>

              <Col span={2}>
                <div>(*)</div>
              </Col>

              <Col span={18}>
                <Form.Item
                  name="file"
                  label=""
                  rules={
                    (fileName === dataRecord.fileUrl && !isEmpty(dataRecord)) ? null : [{ required: true, message: "File là bắt buộc" }]
                  }
                >
                  <input type="file" style={{ display: "none" }} ref={fileRef} onChange={(e) => importFile(e, fileRef)} accept="image/*,video/*, .pdf" />
                  <Button icon={<UploadOutlined />} onClick={openInputFile}>
                    Click to Upload
                  </Button>
                  <StyleNote>Dung lượng File không lớn hơn 50Mb </StyleNote>
                </Form.Item>
                {renderFileName()}
              </Col>
            </>

            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={handleCloseModal} className="button-closed">
                Đóng lại
              </Button>
            </Col>
            <Col span={12}>
              <Button key="submit" type="primary" className="button-primary" htmlType="submit" loading={loading}>
                Xác nhận
              </Button>
            </Col>
          </Row>
        </StyleModal>
      </Form>
    </Modal>
  );
}

export default EditModal;
