import { message } from "antd";
import React, { useState, useEffect } from "react";
import UISoDoCapDien from "./ui-panels/so-do-cap-dien";
export default function SoDoCapDienPanels(props) {
  const { form, mode, handleFieldChange, setChangeSoDo } = props;
  const [preview, setPreview] = useState();
  const [loading, setLoading] = useState(false);

  const beforeUpload = (file) => {
    const isTypeImage =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isTypeImage) {
      message.error(
        "Chỉ được phép tải ảnh định dạng .JPEG, .JPG, .PNG, .PDF !"
      );
    }
    const isLt5M = file.size / 1024 / 1024 <= 5;
    if (!isLt5M) {
      setLoading(false)
      message.error(`Ảnh ${file.name} vượt quá 5MB`);
    }
    return isTypeImage && isLt5M;
  };

  const handleChange = async (file) => {
    setLoading(true);
    if (!file.file || file.fileList.length === 0) {
      return;
    }

    const checkSize = beforeUpload(file.file);

    const soDo = {};

    if (checkSize) {
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onload = (e) => {
        soDo.fileName = file.file.name || "default";
        soDo.base64Data = e.target.result;
      };
      reader.onloadend = () => {
        setPreview(reader.result);
        const values = form.setFieldsValue();
        setChangeSoDo(true)
        form.setFieldsValue({ ...values, soDoCapDien: soDo });
        handleFieldChange();
      };
    }
  };

  const deleteHandler = () => {
    setPreview(null);
    form.setFieldsValue({
      [`soDoCapDien`]: null,
    });
    handleFieldChange()
  };

  useEffect(() => {
    if (
      form.getFieldValue("soDoCapDien") instanceof Object &&
      Object.keys(form.getFieldValue("soDoCapDien")).length > 0
    ) {
      setPreview(form.getFieldValue("soDoCapDien").base64Data);
      setLoading(false);
    } else {
      setPreview(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue("soDoCapDien")]);

  return (
    <UISoDoCapDien
      handleChange={handleChange}
      mode={mode}
      preview={preview}
      loading={loading}
      deleteHandler={deleteHandler}
    />
  );
}
