import Selection from "@components/Select";
import { FORMAT_DATE, FORMAT_MONTH } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import { Fragment, useState } from "react";
import CheckPermission from "@components/CheckPermission";
import { cham_soc_khach_hang } from "@permissions/j_cskh";
import {
  NAME_TB_CS_THAY_CT_DINHKY_THAO_COKHI_TREO_COKHI,
  NAME_TB_CS_THAY_CT_DINHKY_THAO_COKHI_TREO_DIENTU,
  NAME_TB_CS_THAY_CT_DINHKY_THAO_DIENTU_TREO_DIENTU,
  NAME_TB_KH_CO_SL_TANG_30PT,
  NAME_TB_KH_CO_SL_TANG_50PT,
  NAME_TB_KH_THAY_DINH_KY_CT,
  NAME_TB_KIEM_TRA_AP_GIA_BAN_DIEN,
  NAME_TB_KIEM_TRA_KH_2_DINH_MUC,
  NAME_TB_Ngung_Cung_Cap_Dien,
  NAME_TB_THAY_DOI_LICH_GCS_KH_1_DIEM_DO_VA_GCS_1_KY,
  NAME_TB_THAY_DOI_LICH_GCS_KH_GCS_TU_2KY,
  NAME_TB_THAY_DOI_LICH_GCS_KH_GHEP_TONG_NHIEU_DIEM_DO,
  NAME_TB_THAY_DOI_LICH_GCS_TET,
  NAME_TB_TIEN_DIEN_LAN_2,
  NAME_TB_XAC_NHAN_SL_DIEN_MT_SAU_1GIA,
  NAME_TB_XAC_NHAN_SL_DIEN_MT_SAU_3GIA,
  NAME_TB_XAC_NHAN_SL_DIEN_MT_TRUOC_1GIA,
  NAME_TB_XAC_NHAN_SL_DIEN_MT_TRUOC_3GIA,
  NAME_TB_KH_SH_KHONG_SUDUNG_DIEN_6_THANG,
  NAME_TB_TIEN_DIEN_LAN_2_ZALO_MAIL_KHONG_THANHCONG,
  NAME_HINHTHUC_SMS,
  NAME_HINHTHUC_EMAIL_ZALO,
} from "./constant";
import SelectionDoiTuong from "../common/SelectDoiTuong";
import SelectionSo from "../common/SelectSo";

const danhSachDoiTuong = [
  NAME_TB_CS_THAY_CT_DINHKY_THAO_COKHI_TREO_COKHI,
  NAME_TB_CS_THAY_CT_DINHKY_THAO_COKHI_TREO_DIENTU,
  NAME_TB_CS_THAY_CT_DINHKY_THAO_DIENTU_TREO_DIENTU,
  NAME_TB_KH_CO_SL_TANG_30PT,
  NAME_TB_KH_CO_SL_TANG_50PT,
  NAME_TB_KH_SH_KHONG_SUDUNG_DIEN_6_THANG,
  NAME_TB_KH_THAY_DINH_KY_CT,
  NAME_TB_KIEM_TRA_AP_GIA_BAN_DIEN,
  NAME_TB_KIEM_TRA_KH_2_DINH_MUC,
  NAME_TB_Ngung_Cung_Cap_Dien,
  NAME_TB_THAY_DOI_LICH_GCS_KH_1_DIEM_DO_VA_GCS_1_KY,
  NAME_TB_THAY_DOI_LICH_GCS_KH_GCS_TU_2KY,
  NAME_TB_THAY_DOI_LICH_GCS_KH_GHEP_TONG_NHIEU_DIEM_DO,
  NAME_TB_THAY_DOI_LICH_GCS_TET,
  NAME_TB_TIEN_DIEN_LAN_2,
  NAME_TB_TIEN_DIEN_LAN_2_ZALO_MAIL_KHONG_THANHCONG,
  NAME_TB_XAC_NHAN_SL_DIEN_MT_SAU_1GIA,
  NAME_TB_XAC_NHAN_SL_DIEN_MT_SAU_3GIA,
  NAME_TB_XAC_NHAN_SL_DIEN_MT_TRUOC_1GIA,
  NAME_TB_XAC_NHAN_SL_DIEN_MT_TRUOC_3GIA,
];

export function FillterTinNhan(props) {
  const {
    data,
    setData,
    filterConditions,
    handleChange,
    form,
    handleChangeToDoi,
    onChange,
    onChangeNgayKiemTra,
    onChangeNgayThayCT,
    id,
    handleChangeMaSo,
    queryMaSo,
    handleOpenPdf,
    setLoading,
    onChangeKy,
    handleNgayCatDien,
    handleChangeGio,
    handleChangePhut,
    hinhThucNhanTin,
    isDisable, 
    setIsDisable
  } = props;
  const { Option } = Select;
  return (
    <>
      <Fragment>
        <Row gutter={10}>
          <Col span={16}>
            <Form.Item
              name="doituong"
              label="Đối tượng"
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "Đối tượng không được để trống",
                },
              ]}
            >
              <SelectionDoiTuong
                data={data}
                setData={setData}
                url={Endpoint.CSKH_NHAN_TIN}
                placeholder="Chọn đối tượng"
                formKey="doituong"
                form={form}
                setValue={handleChange}
              />
            </Form.Item>
          </Col>

          {id === NAME_TB_KIEM_TRA_KH_2_DINH_MUC ||
          id === NAME_TB_KH_THAY_DINH_KY_CT ||
          id === NAME_TB_KIEM_TRA_AP_GIA_BAN_DIEN ? null : (
            <Col span={8}>
              <Form.Item name="reportDate" label="Tháng Năm" labelAlign="left">
                {form.setFieldsValue({
                  [`ThangNam`]: moment(),
                })}
                <DatePicker
                  onChange={onChange}
                  picker="month"
                  placeholder={["Tháng Năm"]}
                  format={FORMAT_MONTH}
                  defaultValue={moment()}
                  form={form}
                />
              </Form.Item>
            </Col>
          )}

          {id === NAME_TB_KIEM_TRA_KH_2_DINH_MUC ||
          id === NAME_TB_KIEM_TRA_AP_GIA_BAN_DIEN ? (
            <Col span={5}>
              <Form.Item
                name="ngaY_KIEM_TRA"
                label="Ngày kiểm tra"
                labelAlign="left"
              >
                {form.setFieldsValue({
                  [`ngaY_KIEM_TRA`]: moment(),
                })}
                <DatePicker
                  onChange={onChangeNgayKiemTra}
                  placeholder={["ngaykiemtra"]}
                  format={FORMAT_DATE}
                  defaultValue={moment()}
                  form={form}
                />
              </Form.Item>
            </Col>
          ) : null}
          {id === NAME_TB_KH_THAY_DINH_KY_CT ? (
            <>
              <Col span={5}>
                <Form.Item
                  name="ngaY_THAY_CT"
                  label="Ngày thay công tơ"
                  labelAlign="left"
                >
                  {form.setFieldsValue({
                    [`ngaY_THAY_CT`]: moment(),
                  })}
                  <DatePicker
                    onChange={onChangeNgayThayCT}
                    placeholder={["ngaykiemtra"]}
                    format={FORMAT_DATE}
                    defaultValue={moment()}
                    form={form}
                  />
                </Form.Item>
              </Col>
            </>
          ) : null}
        </Row>
        <Row gutter={20}>
          {id === NAME_TB_TIEN_DIEN_LAN_2_ZALO_MAIL_KHONG_THANHCONG ||
          id === NAME_TB_TIEN_DIEN_LAN_2 ? (
            <Col span={5}>
              <Form.Item name="p_ky" label="Kỳ" labelAlign="left">
                <Select defaultValue={1} onChange={onChangeKy}>
                  <Option value={1}>1</Option>
                  <Option value={2}>2</Option>
                  <Option value={3}>3</Option>
                </Select>
              </Form.Item>
            </Col>
          ) : null}
        </Row>
        <Row gutter={20}>
          {id === NAME_TB_Ngung_Cung_Cap_Dien &&
          hinhThucNhanTin === NAME_HINHTHUC_SMS ? (
            <>
              {" "}
              <Col span={5}>
                <Form.Item name="p_ky" label="Kỳ" labelAlign="left">
                  <Select defaultValue={1} onChange={onChangeKy}>
                    <Option value={1}>1</Option>
                    <Option value={2}>2</Option>
                    <Option value={3}>3</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="NgayGioCatDien"
                  label="Ngày cắt điện"
                  labelAlign="left"
                  rules={[
                    {
                      required: true,
                      message: "Ngày cắt điện không được để trống",
                    },
                  ]}
                >
                  <DatePicker
                    formKey="NgayGioCatDien"
                    form={form}
                    onChange={handleNgayCatDien}
                    format={"DD-MM-YYYY"}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Giờ" name="Gio" className="hours-filed">
                  {form.setFieldsValue({
                    [`Gio`]: 8,
                  })}
                  <Input
                    type="number"
                    min={0}
                    max={23}
                    placeholder="Nhập giờ"
                    defaultValue={8}
                    onChange={handleChangeGio}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Phút" name="Phut" className="minute-field">
                  {form.setFieldsValue({
                    [`Phut`]: 0,
                  })}
                  <Input
                    type="number"
                    min={0}
                    defaultValue={0}
                    max={59}
                    placeholder="Nhập phút"
                    onChange={handleChangePhut}
                  />
                </Form.Item>
              </Col>
            </>
          ) : null}
          {id === NAME_TB_Ngung_Cung_Cap_Dien &&
          hinhThucNhanTin === NAME_HINHTHUC_EMAIL_ZALO ? (
            <>
              <Col span={5}>
                <Form.Item name="p_ky" label="Kỳ" labelAlign="left">
                  <Select defaultValue={1} onChange={onChangeKy}>
                    <Option value={1}>1</Option>
                    <Option value={2}>2</Option>
                    <Option value={3}>3</Option>
                  </Select>
                </Form.Item>
              </Col>
            </>
          ) : null}
        </Row>

        <Row gutter={20}>
          <Col span={5}>
            <Form.Item
              name="todoi"
              label="Tổ Đội"
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "Tổ đội không được để trống",
                },
              ]}
            >
              <Selection
                url={Endpoint.TODOI_LOGIN}
                placeholder="Chọn tổ đội"
                formKey="todoi"
                form={form}
                setValue={handleChangeToDoi}
              />
            </Form.Item>
          </Col>
          <Col span={13}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.unitId !== currentValues.unitId
              }
            >
              <Form.Item
                name="danhsachso"
                label="Danh sách số"
                rules={[
                  {
                    required: true,
                    message: "Danh sách sổ không được để trống",
                  },
                ]}
              >
                {id === NAME_TB_KH_CO_SL_TANG_50PT &&
                filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_SL_QUA_150}?${queryMaSo}`}
                    setValue={handleChangeMaSo}
                    mode="multiple"
                    placeholder="Chọn danh sách sổ"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                    allowClear
                  />
                ) : null}
                {id === NAME_TB_KH_CO_SL_TANG_30PT &&
                filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_SL_QUA_130}?${queryMaSo}`}
                    setValue={handleChangeMaSo}
                    placeholder="Chọn danh sách sổ"
                    mode="multiple"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}
                {id === NAME_TB_TIEN_DIEN_LAN_2 && filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_TBTD_LAN_2}?${queryMaSo}`}
                    setValue={handleChangeMaSo}
                    placeholder="Chọn danh sách sổ"
                    mode="multiple"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}

                {id === NAME_TB_TIEN_DIEN_LAN_2_ZALO_MAIL_KHONG_THANHCONG &&
                filterConditions.ma_to_1 ? (
                  // Chờ api
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_TBTD_LAN_2_KTC}?${queryMaSo}`}
                    setValue={handleChangeMaSo}
                    placeholder="Chọn danh sách sổ"
                    mode="multiple"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}
                {id === NAME_TB_Ngung_Cung_Cap_Dien &&
                filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_TB_NGUNG_CAP_DIEN}?${queryMaSo}`}
                    setValue={handleChangeMaSo}
                    placeholder="Chọn danh sách sổ"
                    mode="multiple"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}
                {id === NAME_TB_CS_THAY_CT_DINHKY_THAO_DIENTU_TREO_DIENTU &&
                filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_TB_CHI_SO_THAY_DK_CT}?${queryMaSo}&p_loai_tt=3`}
                    setValue={handleChangeMaSo}
                    placeholder="Chọn danh sách sổ"
                    mode="multiple"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}
                {id === NAME_TB_CS_THAY_CT_DINHKY_THAO_COKHI_TREO_COKHI &&
                filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_TB_CHI_SO_THAY_DK_CT}?${queryMaSo}&p_loai_tt=1`}
                    setValue={handleChangeMaSo}
                    mode="multiple"
                    placeholder="Chọn danh sách sổ"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}
                {id === NAME_TB_CS_THAY_CT_DINHKY_THAO_COKHI_TREO_DIENTU &&
                filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_TB_CHI_SO_THAY_DK_CT}?${queryMaSo}&p_loai_tt=2`}
                    setValue={handleChangeMaSo}
                    mode="multiple"
                    placeholder="Chọn danh sách sổ"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}
                {id === NAME_TB_KH_THAY_DINH_KY_CT &&
                filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_TB_THAY_DK_CT}?${queryMaSo}&p_loai_tt=1`}
                    setValue={handleChangeMaSo}
                    placeholder="Chọn danh sách sổ"
                    mode="multiple"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}
                {id === NAME_TB_THAY_DOI_LICH_GCS_KH_GHEP_TONG_NHIEU_DIEM_DO &&
                filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_TB_LICH_GHI_CS}?${queryMaSo}&p_Loai_KH=3`}
                    setValue={handleChangeMaSo}
                    mode="multiple"
                    placeholder="Chọn danh sách sổ"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}
                {id === NAME_TB_THAY_DOI_LICH_GCS_KH_GCS_TU_2KY &&
                filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_TB_LICH_GHI_CS}?${queryMaSo}&p_Loai_KH=2`}
                    setValue={handleChangeMaSo}
                    placeholder="Chọn danh sách sổ"
                    mode="multiple"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}
                {id === NAME_TB_THAY_DOI_LICH_GCS_KH_1_DIEM_DO_VA_GCS_1_KY &&
                filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_TB_LICH_GHI_CS}?${queryMaSo}&p_Loai_KH=1`}
                    setValue={handleChangeMaSo}
                    placeholder="Chọn danh sách sổ"
                    mode="multiple"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}
                {id === NAME_TB_THAY_DOI_LICH_GCS_TET &&
                filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_TB_LICH_GHI_CS_TET}?${queryMaSo}&p_Loai_KH=1`}
                    setValue={handleChangeMaSo}
                    mode="multiple"
                    placeholder="Chọn danh sách sổ"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}
                {id === NAME_TB_KIEM_TRA_KH_2_DINH_MUC &&
                filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_TB_KH_2_DINH_MUC_TRO_LEN}?${queryMaSo}`}
                    setValue={handleChangeMaSo}
                    placeholder="Chọn danh sách sổ"
                    mode="multiple"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}
                {id === NAME_TB_KIEM_TRA_AP_GIA_BAN_DIEN &&
                filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_TB_KIEM_TRA_AP_GIA_BAN_DIEN}?${queryMaSo}`}
                    setValue={handleChangeMaSo}
                    placeholder="Chọn danh sách sổ"
                    mode="multiple"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}
                {id === NAME_TB_XAC_NHAN_SL_DIEN_MT_TRUOC_1GIA &&
                filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_TB_XACNHAN_SLDIEN_MT}?${queryMaSo}&loai_cto=1&loai_kh=1`}
                    setValue={handleChangeMaSo}
                    mode="multiple"
                    placeholder="Chọn danh sách sổ"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}
                {id === NAME_TB_XAC_NHAN_SL_DIEN_MT_TRUOC_3GIA &&
                filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_TB_XACNHAN_SLDIEN_MT}?${queryMaSo}&loai_cto=3&loai_kh=1`}
                    setValue={handleChangeMaSo}
                    placeholder="Chọn danh sách sổ"
                    mode="multiple"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}
                {id === NAME_TB_XAC_NHAN_SL_DIEN_MT_SAU_1GIA &&
                filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_TB_XACNHAN_SLDIEN_MT}?${queryMaSo}&loai_cto=1&loai_kh=2`}
                    setValue={handleChangeMaSo}
                    mode="multiple"
                    placeholder="Chọn danh sách sổ"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}
                {id === NAME_TB_XAC_NHAN_SL_DIEN_MT_SAU_3GIA &&
                filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_TB_XACNHAN_SLDIEN_MT}?${queryMaSo}&loai_cto=3&loai_kh=2`}
                    setValue={handleChangeMaSo}
                    mode="multiple"
                    placeholder="Chọn danh sách sổ"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}
                {id === NAME_TB_KH_SH_KHONG_SUDUNG_DIEN_6_THANG &&
                filterConditions.ma_to_1 ? (
                  <SelectionSo
                    url={`${Endpoint.CSKH_DSS_KH_6_THANG_KHONG_DUNG}?${queryMaSo}`}
                    setValue={handleChangeMaSo}
                    mode="multiple"
                    placeholder="Chọn danh sách sổ"
                    form={form}
                    formKey="danhsachso"
                    isDisable={isDisable} 
                    setIsDisable={setIsDisable}
                  />
                ) : null}
                {!danhSachDoiTuong.includes(id) && filterConditions.ma_to_1 ? (
                  <Select disabled />
                ) : null}
                {!filterConditions.ma_to_1 ? <Select disabled /> : null}
              </Form.Item>
            </Form.Item>
          </Col>

          <Col style={{ paddingTop: "27px" }}>
            <CheckPermission permissionCode={cham_soc_khach_hang.j21g}>
              <Button type="primary" htmlType="submit" className="mr-10px ">
                Gửi tin nhắn
              </Button>
            </CheckPermission>

            <CheckPermission permissionCode={cham_soc_khach_hang.j21xe}>
              <Button
                type="primary"
                onClick={() => {
                  handleOpenPdf(form.getFieldValue(), setLoading);
                }}
              >
                Xuất Excel
              </Button>
            </CheckPermission>
          </Col>
        </Row>
      </Fragment>
    </>
  );
}
