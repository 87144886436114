import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";
import {
  buildQueryString,
  parseParams
} from "@utils/function";

// get list data bảng kê nhân viên cần xác nhận
export const handleListBK = (
  setLoading,
  location,
  setListBK,
  setTotalCount,
  filterConditions,
) => {
  let url = "";
  url = `${Endpoint.AG_TIM_KIEM_BK}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
    authGetData({
      url,
      setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setListBK(res.data);
        setTotalCount(res.paging.totalCount);
      } else setListBK([]);
    },
  });
};