import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useEffect,
} from "react";
import Filter from "@components/Filter/Filter";
import { TableStyled, Container } from "./css-styled";
import { Form, Modal, Tag, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "@state/auth/reducer";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  MONTH_STRING,
  FORMAT_ENGLISH,
  AP_GIA_TRANG_THAI_BK
} from "@utils/constants";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilterBK } from "../../common/list-bo-loc-ky-bk";
import CheckPermission from "@components/CheckPermission";
import { ap_gia } from "@permissions/e_apgia";
import TableComponent from "@components/TableComponent";
import { handleListBKNVXacNhan, submitNhanVienXacNhan, submitHuyBK } from "./services";
import { openModal } from "@state/system-config/reducer";
import ChiTietBangKe from "../../common/chi-tiet-bang-ke";
import ButtonComponent from "@components/Button";
import moment from "moment";
import { InfoCircleOutlined } from "@ant-design/icons";
import DuyetBangKe from "../../common/duyet-bang-ke";
import { FilePdfOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import TrashIcon from "@assets/icon/TrashIcon";

export default function Index() {
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [listBKNVXacNhan, setListBKNVXacNhan] = useState([]);
  const [fetchDataTableIfNeed, setFetchDataTable] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const location = useLocation();
  const [biTraLai, setBiTraLai] = useState(false);

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    ...parseParams(location.search),
    NgayBatDau: moment(moment().startOf(MONTH_STRING)).format(FORMAT_ENGLISH),
    NgayKetThuc: moment().format(FORMAT_ENGLISH),
    TrangThai: AP_GIA_TRANG_THAI_BK.CHO_NVAG_DUYET,
    // TenNhanVien: user.id
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu
  const getListBKNVXacNhan = useCallback(() => {
    handleListBKNVXacNhan(
      setLoading,
      location,
      setListBKNVXacNhan,
      setTotalCount,
      filterConditions,
      setSelectedRowKeys
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getListBKNVXacNhan();
  }, [getListBKNVXacNhan]);

  useEffect(() => {
    if (fetchDataTableIfNeed) {
      getListBKNVXacNhan();
      setFetchDataTable(!fetchDataTableIfNeed);
    }
  }, [fetchDataTableIfNeed, getListBKNVXacNhan, setFetchDataTable]);

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setSelectedRowKeys([]);
  };

  //tìm kiếm dữ liệu
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
      TraLai: biTraLai ? biTraLai : null,
    }));
  }, [biTraLai]);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      TuNgay: moment(moment().startOf(MONTH_STRING)).format(FORMAT_ENGLISH),
      DenNgay: moment().format(FORMAT_ENGLISH),
      TrangThai: AP_GIA_TRANG_THAI_BK.CHO_NVAG_DUYET,
      // TenNhanVien: user.id
    });
  }, [user.unitId]);

  const detailBangKe = (record) => {
    return dispatch(
      openModal({
        content: (
          <ChiTietBangKe
            detail={record}
          />
        ),
        size: "large",
        title: `Chi tiết bảng kê - ${record.maBangKe}`
      })
    );
  };

  const detailBK = (record) => {
    return {
      onDoubleClick: () => {
        detailBangKe(record)
      },
    };
  };

  const checkTraLai = (e) => {
    console.log(e.target.checked);
    setBiTraLai(e.target.checked);
  };

  const duyetBangKe = (record) => {
    return dispatch(
      openModal({
        content: (
          <DuyetBangKe
            detail={record}
            setFetchDataTable={setFetchDataTable}
            role="NV"
          />
        ),
        size: "large",
        title: `Duyệt bảng kê - ${record.maBangKe}`
      })
    );
  };

  const columns = [
    {
      title: "Mã bảng kê",
      dataIndex: "maBangKe",
      key: "maBangKe",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => detailBangKe(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: "8%",
      sorter: true,
      fixed: "left"
    },
    {
      title: "Loại bảng kê",
      dataIndex: "loaiBangKe",
      key: "loaiBangKe",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "NV lập bảng kê",
      dataIndex: "tenNhanVien",
      key: "tenNhanVien",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "12%",
      sorter: true,
    },
    {
      title: "Ngày tạo",
      dataIndex: "thoiGianTao",
      key: "thoiGianTao",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: "Chữ ký 1",
      dataIndex: "chuKi1",
      key: "chuKi1",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Ngày ký 1",
      dataIndex: "ngayKi1",
      key: "ngayKi1",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: "Chữ ký 2",
      dataIndex: "chuKi2",
      key: "chuKi2",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Ngày ký 2",
      dataIndex: "ngayKi2",
      key: "ngayKi2",
      render: (text) => (
        <div className="table-text-center">
          <span>{text}</span>
        </div>
      ),
      width: "8%",
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text, record) => (
        <>
          <div className="table-text-center">
            {record.trangThai?.toString() === AP_GIA_TRANG_THAI_BK.CHO_NVAG_DUYET ? (
              <Tag
                style={{ display: "block", textAlign: "center" }}
                color={"#26c0e9"}
              >
                {"Chưa xác nhận"}
              </Tag>
            ) : (<Tag
              style={{ display: "block", textAlign: "center" }}
              color={"#faad14"}
            >
              {"Đã xác nhận"}
            </Tag>
            )}
          </div>
          {record.traLai ?
            <div
              className="table-text-center"
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: "#FF5247",
                cursor: "pointer",
              }}
            >
              <Tooltip title={record.lyDoTraLai}>
                <InfoCircleOutlined /> Bị trả lại
              </Tooltip>
            </div> : null
          }
        </>
      ),
      width: "8%",
    },
    {
      title: "Tác vụ",
      dataIndex: "",
      key: "tacVu",
      render: (text, record) => (
        <div className="table-text-center">
          {record.filePath ?
            <CheckPermission permissionCode={ap_gia.e16xb}>
              <Tooltip title="Xem bảng kê">
                <a
                  href={record.filePath}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FilePdfOutlined
                    style={{ fontSize: "22px" }}
                  />
                </a>
              </Tooltip>
            </CheckPermission>
            : null
          }
          {
            record.trangThai?.toString() === AP_GIA_TRANG_THAI_BK.CHO_NVAG_DUYET ?
              <CheckPermission permissionCode={ap_gia.e16h}>
                <Tooltip title="Duyệt bảng kê">
                  {/* <div style={{ color: "#279BEF" }}> */}
                  <CheckCircleTwoTone
                    twoToneColor="#52c41a"
                    style={{ fontSize: "22px", marginLeft: '10px' }}
                    onClick={() => duyetBangKe(record)}
                  />
                  {/* </div> */}
                </Tooltip>
              </CheckPermission>
              : null
          }
          {
            // record.trangThai?.toString() === AP_GIA_TRANG_THAI_BK.CHO_NVAG_DUYET ?
            <CheckPermission permissionCode={ap_gia.e16h}>
              <Tooltip title={"Hủy bảng kê"}>
                <ButtonComponent type="link" onClick={() => handleHuyBK(record.id)} className="pd-5px">
                  <TrashIcon />
                </ButtonComponent>
              </Tooltip>
            </CheckPermission>

          }
        </div>
      ),
      width: "6%",
      fixed: "right"
    },
  ];

  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: "3%",
    fixed: "left",
    getCheckboxProps: (record) => ({
      disabled:
        (record.trangThai?.toString() === AP_GIA_TRANG_THAI_BK.CHO_BPAG_DUYET)
    }),
  };

  const handleNhanVienXacNhan = () => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: `Các bảng kê được chọn sẽ chuyển tiếp cho Bộ phận áp giá!`,
      onOk() {
        //call api
        submitNhanVienXacNhan(setLoading, setFetchDataTable, selectedRowKeys, setSelectedRowKeys);
      },
      onCancel() { },
      okText: "Đồng ý",
      cancelText: "Hủy",
    });
  };

  const handleHuyBK = (id) => {
    //call api
    submitHuyBK(setLoading, setFetchDataTable, id);
  };
  const genExtra = useCallback(
    () => (
      <div>
        <CheckPermission permissionCode={ap_gia.e16x}>
          <ButtonComponent
            size="default"
            type="primary"
            className="mr-5px"
            disabled={!selectedRowKeys.length}
            onClick={() => handleNhanVienXacNhan()}
          >
            Xác nhận
          </ButtonComponent>
        </CheckPermission>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRowKeys]
  );
  return (
    <Fragment>
      <Filter>
        <ListFilterBK
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          checkTraLai={checkTraLai}
          filterScreen={"NVAG"}
        />
      </Filter>
      <Container>
        <TableStyled>
          <TableComponent
            header={"Xác nhận bảng kê"}
            dataSource={listBKNVXacNhan}
            listFilter={<ListFilterBK form={form} />}
            columns={customColumn(columns, filterConditions)}
            renderExtraAction={() => genExtra()}
            totalData={totalCount}
            loading={loading}
            scrollX={2000}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            rowSelection={rowSelection}
            onRow={detailBK}
            rowKey="id"
          />
        </TableStyled>
      </Container>
    </Fragment>
  );
}
