import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";
import { buildQueryString } from "@utils/function";

// get cấu hình chỉ số
export const getCauHinhchiSo = (
  setLoading,
  setCauHinhChiSo,
) => {
  let url = "";
  url = `${Endpoint.LIST_CAU_HINH_CHI_SO}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setCauHinhChiSo(res.data[0].boChiSoHienThi);
      } else setCauHinhChiSo();
    },
  });
};
export const getListDonViService = (setLoading, setDataDonVi) => {
  authGetData({
    url: `${Endpoint.GET_UNIT_LIST}?pageSize=-1`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDonVi(res.data);
      } else setDataDonVi([]);
    },
  });
};

export const getBienBanService = (setLoading, location, setDataBienBan, filterBB) => {
  let url = "";
  url = `${Endpoint.CTOTHAO_LIST_BIEN_BAN}?${buildQueryString(filterBB)}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        const tempBienBan = [];
        for (let i = 0; i < res.data?.length; i++) {
          const newArrayOfObj = res.data[i].children.map(({
            label: title,
            data: value,
            data: key,
            ...rest
          }) => ({
            title,
            value,
            key,
            ...rest
          }));
          tempBienBan.push({
            title: res.data[i].label,
            key: res.data[i].label,
            value: res.data[i].label,
            selectable: false,
            children: newArrayOfObj
          })
        }
        setDataBienBan(tempBienBan);
      } else setDataBienBan([]);
    },
  });
};

export const getBienBanWebService = (setLoading, location, setDataBienBan, filterBB) => {
  let url = "";
  url = `${Endpoint.CTOTHAO_LIST_BIEN_BAN_WEB}?${buildQueryString(filterBB)}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        // const tempBienBan = [];
        // for (let i = 0; i < res.data?.length; i++) {
        //   const newArrayOfObj = res.data[i].children.map(({
        //     label: title,
        //     data: value,
        //     data: key,
        //     ...rest
        //   }) => ({
        //     title,
        //     value,
        //     key,
        //     ...rest
        //   }));
        //   tempBienBan.push({
        //     title: res.data[i].label,
        //     key: res.data[i].label,
        //     value: res.data[i].label,
        //     selectable: false,
        //     children: newArrayOfObj
        //   })
        // }
        setDataBienBan(res.data);
      } else setDataBienBan([]);
    },
  });
};