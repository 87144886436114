import { Col, DatePicker, Row, Select } from "antd";
import ExportExcel from "../common/xuat-excel";
import {
  FORMAT_DATE,
  FORMAT_ENGLISH,
  FORMAT_MONTH,
  MONTH_STRING,
  TYPE_CHANGE_DATE,
} from "@utils/constants";
import moment from "moment";
import { Container, FullCol } from "./css-styled";
import { Endpoint } from "@utils/endpoint";
import { cap_dien } from "@permissions/a_capdien";
import LoadingComponent from "@components/Loading";
import { removeAccents } from "@utils/function";

const { Option } = Select;

export default function BaoCaoTongHopTTKH(props) {
  const {
    user,
    donVi,
    loading,
    handleChangeNgay,
    handleChangeUnit,
    tuNgay,
    denNgay,
    thang,
    //maDonVi,
    unit,
  } = props;
  const ngayDauThang = moment(tuNgay).format(FORMAT_ENGLISH);
  const ngayCuoiThang = moment(denNgay).format(FORMAT_ENGLISH);
  return (
    <LoadingComponent loading={loading}>
      <Container>
        <Row gutter={24}>
          <Col span={24} md={8}>
            <div>
              <label>Đơn vị: </label>
              <FullCol>
                {!user.isTongCongTy ? (
                  <Select defaultValue={user.unitId} disabled>
                    <Option value={user.unitId}>{user.unitName}</Option>
                  </Select>
                ) : (
                  <Select
                    defaultValue={user.unitId}
                    onChange={handleChangeUnit}
                    showSearch
                    filterOption={(input, option) => {
                      if (option && option.children) {
                        return (
                          removeAccents(option.children)
                            .toLowerCase()
                            .indexOf(removeAccents(input).toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {donVi && donVi.length > 0
                      ? donVi.map((item, index) => (
                          <Option key={index} value={item.id}>
                            {item.tenDonVi}
                          </Option>
                        ))
                      : null}
                  </Select>
                )}
              </FullCol>
            </div>
          </Col>
          <Col span={24} md={5}>
            <label>Từ tháng: </label>
            <FullCol>
              <DatePicker
                defaultValue={moment(tuNgay)}
                format={FORMAT_DATE}
                // picker="month"
                allowClear={false}
                onChange={(date) =>
                  handleChangeNgay(date, TYPE_CHANGE_DATE.TUNGAY)
                }
              />
            </FullCol>
          </Col>
          <Col span={24} md={5}>
            <label>Đến tháng: </label>
            <FullCol>
              <DatePicker
                defaultValue={moment(denNgay)}
                format={FORMAT_DATE}
                // picker="month"
                allowClear={false}
                onChange={(date) =>
                  handleChangeNgay(date, TYPE_CHANGE_DATE.DENNGAY)
                }
              />
            </FullCol>
          </Col>
          <Col span={24} md={4}>
            <label>Tháng kiểm tra: </label>
            <FullCol>
              <DatePicker
                defaultValue={moment(thang)}
                format={FORMAT_DATE}
                // picker="month"
                allowClear={false}
                onChange={(date) =>
                  handleChangeNgay(date, TYPE_CHANGE_DATE.THANGKIEMTRA)
                }
              />
            </FullCol>
          </Col>
          {/* <Col
            span={24}
            md={2}
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "start",
            }}
          >
            <a
              className="ant-btn ant-btn-primary mr-24px"
              href={`${
                Endpoint.EXPORT_EXCEL_BAO_CAO_TINH_HINH_PHAT_TRIEN_THEO_LO +
                "?thangnamtu=" +
                ngayBD +
                "&thangnamden=" +
                ngayKT +
                "&thangnam=" +
                thangBD +
                "&ma_dvi=" +
                maDonVi
              }`}
              target="_blank"
              rel="noreferrer"
            >
              Xuất Excel
            </a>
          </Col> */}
          <Col
            span={24}
            md={2}
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "start",
            }}
          >
            <ExportExcel
              endpoint={
                Endpoint.EXPORT_EXCEL_BAO_CAO_TINH_HINH_PHAT_TRIEN +
                "?donViId=" +
                unit +
                "&tuThang=" +
                ngayDauThang +
                "&denThang=" +
                ngayCuoiThang +
                "&thangKiemTra=" +
                thang
              }
              permissionCode={cap_dien.a560_xuat}
              text={"Xuất Excel"}
              key={"xuat-excel"}
            />
          </Col>
        </Row>
      </Container>
    </LoadingComponent>
  );
}

// import Filter from "@components/Filter/Filter";
// import TableComponent from "@components/TableComponent";
// import { Fragment } from "react";
// import { Container } from "./css-styled";
// import { ColumnBaoCaoChiTiet } from "./header";
// import ListFilter from "./list-bo-loc";

// export default function BaoCaoDSKHTable(props) {
//   const {
//     handleSearch,
//     filterConditions,
//     clearFilter,
//     totalCount,
//     loading,
//     onChangePagination,
//     data,
//   } = props;
//   return (
//     <Fragment>
//       <Filter>
//         <ListFilter
//           handleSearch={handleSearch}
//           filterConditions={filterConditions}
//           clearFilter={clearFilter}
//         />
//       </Filter>
//       <Container>
//         <TableComponent
//           header={"Báo cáo danh sách khách hàng"}
//           totalData={totalCount}
//           loading={loading}
//           onChangePagination={onChangePagination}
//           pagination={filterConditions}
//           scrollX={5400}
//           columns={ColumnBaoCaoChiTiet()}
//           dataSource={data}
//         />
//       </Container>
//     </Fragment>
//   );
// }

// import { headerWithSummary } from "@utils/function";
// import Filter from "@components/Filter/Filter";
// import TableComponent from "@components/TableComponent";
// import { Fragment } from "react";
// import { Container } from "./css-styled";
// import ListFilter from "./list-bo-loc.jsx";

// export default function BaoCaoPhatTrienTheoLoTable(props) {
//   const {
//     form,
//     handleSearch,
//     filterConditions,
//     clearFilter,
//     totalCount,
//     loading,
//     onChangePagination,
//     columns,
//     noteColumns,
//     data,
//     countTotals,
//   } = props;
//   return (
//     <Fragment>
//       <Filter>
//         <ListFilter
//           form={form}
//           handleSearch={handleSearch}
//           filterConditions={filterConditions}
//           clearFilter={clearFilter}
//         />
//       </Filter>
//       <Container>
//         <TableComponent
//           header={"Báo cáo tình hình phát triển theo lô"}
//           totalData={totalCount}
//           loading={loading}
//           onChangePagination={onChangePagination}
//           // pagination={filterConditions}
//           columns={columns}
//           components={{
//             header: {
//               wrapper: (props) => headerWithSummary(props, noteColumns),
//             },
//           }}
//           dataSource={data}
//           summary={countTotals}
//           scrollY={450}
//         />
//       </Container>
//     </Fragment>
//   );
// }
