/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import LoadingComponent from "@components/Loading";
import TaiAnhThanhToan from "@modules/dang-ky-thanh-toan/common/tai-anh";
import { removeAccents } from "@utils/function";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Style } from "../css-styled";
import {
  getChiTietKhuyenMai,
  getCodeKhuyenMai,
  taoThongTinKhuyenMai,
} from "../services";
function ThemMoiThongTinKhuyenMaiModal({
  closeModal,
  edit,
  listNganHang,
  setListLoading,
  setData,
  filterConditions,
  setTotal,
  id,
  location,
  callCode,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [addData, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataItem, setDataItem] = useState();
  const [code, setCode] = useState("");
  const { RangePicker } = DatePicker;
  // create new
  const handleFinish = (values) => {
    const payload = {
      TenKhuyenMai: values.tenKhuyenMai,
      MaKhuyenMai: code,
      MaNganHang: values.nganHang,
      ThongTinChiTiet: addData,
      ...(values.hanKhuyenMai && {
        TuNgay: values.hanKhuyenMai[0].format("YYYY-MM-DD"),
      }),
      ...(values.hanKhuyenMai && {
        DenNgay: values.hanKhuyenMai[1].format("YYYY-MM-DD"),
      }),
      File: fileList.map((item) => item.originFileObj),
      NoiDung: values.noiDung,
    };

    taoThongTinKhuyenMai(
      payload,
      setLoading,
      setFileList,
      setListLoading,
      setData,
      location,
      filterConditions,
      setTotal,
      dispatch,
      closeModal
    );
  };

  useEffect(() => {
    if (id) {
      getChiTietKhuyenMai(id, setLoading, setDataItem);
    }
  }, [id]);

  useEffect(() => {
    if (callCode) {
      getCodeKhuyenMai(setLoading, setCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (dataItem) {
      form.setFieldsValue({
        ...dataItem,
        nganHang: dataItem.maNganHang,
      });
      if (dataItem.thongTinChiTiet) {
        setValue(dataItem.thongTinChiTiet);
      }
    }
  }, [dataItem, form]);

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setValue(data);
  };
  //getCodeKhuyenMai
  useEffect(() => {
    if (code) {
      form.setFieldsValue({
        maKhuyenMai: `${code}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <LoadingComponent loading={loading}>
      <Style>
        <div className="them-moi-thong-tin">
          <Form
            form={form}
            onFinish={handleFinish}
            layout="vertical"
            initialValues={{
              hanKhuyenMai: [moment(), moment().add(30, "d")],
            }}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="tenKhuyenMai"
                  label="Tên khuyến mãi"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập tên khuyến mãi!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              {/* <Col span={6}>
                <Form.Item name="maKhuyenMai" label="Mã khuyến mãi" required
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập mã khuyến mãi!",
                      // whitespace: true
                    },
                  ]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col> */}
              <Col span={6}>
                <Form.Item
                  name="nganHang"
                  label="Ngân hàng/Ví điện tử"
                  required
                  className="them-moi-thong-tin-nganHang"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng chọn ngân hàng!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="--Chọn ngân hàng--"
                    filterOption={(input, option) => {
                      if (option && option.children) {
                        return (
                          removeAccents(option.children)
                            .toLowerCase()
                            .indexOf(removeAccents(input).toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {listNganHang &&
                      listNganHang.map((item, id) => {
                        return (
                          <Select.Option
                            key={id}
                            value={item.value}
                            disabled={item.value === null}
                          >
                            {item.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col span={6}>
                <Form.Item name="LoaiCTKM" label="Loại CTKM">
                  <Select mode='tags' maxTagCount='responsive' disabled={disable} />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item name="noiDung" label="Mô tả ngắn">
                  <Input.TextArea rows={4} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Chọn ảnh">
                  <TaiAnhThanhToan
                    setFileList={setFileList}
                    fileList={fileList}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="thongTinChiTiet" label="Nội dung khuyến mãi">
                  <CKEditor
                    editor={ClassicEditor}
                    data={addData}
                    onChange={handleChange}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "|",
                        "insertTable",
                        "tableColumn",
                        "tableRow",
                        "mergeTableCells",
                        "|",
                        "undo",
                        "redo",
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="hanKhuyenMai" label="Hạn khuyến mãi">
                  <RangePicker format={"DD/MM/YYYY"} />
                </Form.Item>
              </Col>
              <Col span={8} offset={8} style={{ textAlign: "center" }}>
                <>
                  <Button
                    onClick={() => dispatch(closeModal())}
                    type="danger"
                    style={{ marginRight: "5px" }}
                  >
                    Hủy
                  </Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginLeft: "5px" }}
                  >
                    {edit ? "Lưu" : "Thêm mới"}
                  </Button>
                </>
              </Col>
            </Row>
          </Form>
        </div>
      </Style>
    </LoadingComponent>
  );
}

export default ThemMoiThongTinKhuyenMaiModal;
