import { BASE_API_URL_KT_THIETBI } from "@utils/constants";

export const APICommon = {
  LIST_TINHTRANG_CT: `${BASE_API_URL_KT_THIETBI}/common/list-trang-thai-cong-to`,
  LIST_TINHTRANG_BK: `${BASE_API_URL_KT_THIETBI}/common/list-trang-thai-bang-ke`,
  LIST_TRAM_CT: `${BASE_API_URL_KT_THIETBI}/common/list-tram`,
  LIST_LOAI_BANGKE: `${BASE_API_URL_KT_THIETBI}/common/list-loai-bang-ke`,
  LIST_NGUOI_THUC_HIEN: `${BASE_API_URL_KT_THIETBI}/common/list-nguoi-thuc-hien`,
  LIST_DONVI_KTCT: `${BASE_API_URL_KT_THIETBI}/common/list-don-vi`,
  LIST_DOI_KTCT: `${BASE_API_URL_KT_THIETBI}/common/list-doi`,
  XUAT_EXCEL_DINHKY: `${BASE_API_URL_KT_THIETBI}/exportexcel/tra-cuu-dinh-ky`,
  XUAT_EXCEL_PHUCTRA: `${BASE_API_URL_KT_THIETBI}/exportexcel/tra-cuu-phuc-tra`,
  XUAT_EXCEL_BANGKE: `${BASE_API_URL_KT_THIETBI}/exportexcel/tra-cuu-bang-ke`,
};
