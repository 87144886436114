import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import SelectMaSoGCS from "@modules/ghi-chi-so/bangke/bangkechitiet/common/SelectGetMaSoGCS";
import shouldUpdateMaso from "@modules/ghi-chi-so/bangke/bangkechitiet/common/should-update-ma-so";
import { authSelector } from "@state/auth/reducer";
import { FORMAT_MONTH_ENGLISH } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString } from "@utils/function";
import { Button, Col, Form, Row, Select } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const FilterBKDsCacKHCapNhatThayDoi = (props) => {
  const initDate = new Date();
  const { setFilterConditions, clearSearch, filterConditions, loading } = props;
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [query, setQuery] = useState({
    pageIndex: 1,
    pageSize: 50,
  });
  const [listMaso, setListMaso] = useState([]);
  useEffect(
    () =>
      filterConditions?.MaSoGCS
        ? form.setFieldsValue({
            ...filterConditions,
            MaSoGCS: filterConditions.MaSoGCS,
          })
        : form.setFieldsValue({
            ...filterConditions,
          }),
    [form, filterConditions]
  );

  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        ...values,
      }));
    },
    [setFilterConditions]
  );
  const clear = useCallback(() => {
    clearSearch();
    form.resetFields();
    form.setFieldsValue({ToDoiId: undefined,});
  }, [clearSearch, form]);

  const handleChangeUnit = useCallback(() => {
    form.setFieldsValue({});
  }, [form]);

  return (
    <FormComponent
      form={form}
      initialValues={{
        DonViId: user.unitId,
      }}
      name="filter-form"
      onFinish={handleSearch}
    >
      <Row gutter={24}>
        <Col span={24} md={12}>
          <Form.Item name="DonViId" label="Đơn vị">
            <Selection url={Endpoint.GET_UNIT_LIST} form={form} formKey="DonViId" setValue={handleChangeUnit} />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.DonViId !== currentValues.DonViId}>
            {({ getFieldValue }) => {
              if (getFieldValue("DonViId")) {
                return (
                  <Form.Item name="ToDoiId" label="Tổ/Đội">
                    <Selection
                      url={`${Endpoint.AUTH_LIST_TO_DOI}?DonViId=${getFieldValue("DonViId")}`}
                      formKey="ToDoiId"
                      form={form}
                      disabled={!getFieldValue("DonViId")}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="ToDoiId" label="Tổ/Đội">
                  <Select disabled />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="ThangNam" label="Tháng/Năm" labelAlign="right">
            <DatePickerComponent
              defaultValue={moment(initDate.toISOString())}
              picker="month"
              formKey="ThangNam"
              format={FORMAT_MONTH_ENGLISH}
              form={form}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24} md={24}>
          <Form.Item
            name="MaSoGCS"
            label="Mã sổ"
            shouldUpdate={(prevValues, currentValues) => {
              shouldUpdateMaso({ prevValues, currentValues, setQueryMaso: setQuery, form, setListMaso, fieldUpdate:"MaSoGCS" });
            }}
          >
            <SelectMaSoGCS
              query={query}
              setQuery={setQuery}
              url={Endpoint.LIST_MA_SO_BANG_KE_NO_TO_DOI}
              formKey="MaSoGCS"
              form={form}
              loading
              mode={"multiple"}
              listMaso={listMaso}
              setListMaso={setListMaso}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Button className="mr-24px" onClick={clear}>
          Bỏ lọc
        </Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          Tìm kiếm
        </Button>
      </Row>
    </FormComponent>
  );
};

export default FilterBKDsCacKHCapNhatThayDoi;
