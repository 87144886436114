import DatePicker from "@components/DatePicker";
import FormComponent from "@components/Form";
import { FORMAT_DATE, FORMAT_ENGLISH, MONTH_STRING } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { downloadFile } from "@utils/request";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import moment from "moment";
import React, { Fragment, useCallback, useLayoutEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import B09ChiTietNTTBTienDienLan1Zalo from "../b09-chi-tiet-nt-td-lan-1-zalo";
import B09THTienDien from "../b09-th-nt-tien-dien-lan-1-zalo";
import BCDSKHPhatTrienMoi from "../bc-phat-trien-moi/bc-ptm";
import BCSlKhPhatTrienMoiEvnHaNoi from "../bc-phat-trien-moi/bc-ptm-evn";
import BCTongHopTinNhan from "../bc-tong-hop-tin-nhan";
import TongHopTyLeNhanTin from "../bc-tong-hop-ty-le-tin-nhan";
import DSKHGuiTinNhanKhongThanhCongChiTiet from "../ds-kh-gui-tn-khong-thanh-cong/chi-tiet";
import DSKHGuiTinNhanKhongThanhCong from "../ds-kh-gui-tn-khong-thanh-cong/tong-hop";
import DSKHGuiTinNhanThanhCongChiTiet from "../ds-kh-gui-tn-thanh-cong/chi-tiet";
import DSKHGuiTinNhanThanhCong from "../ds-kh-gui-tn-thanh-cong/tong-hop";
import DSKHZaloChiTiet from "../ds-kh-zalo/chi-tiet";
import DSKHZaloTongHop from "../ds-kh-zalo/tong-hop";
import { selectBaoCaoDoiSoatZalo } from "../services";

export default function B09THKhachHang() {
  const [screen, handleChange] = useState();
  const [, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const optionValue = [
    {
      label: "B09 - Tổng hợp tình hình nhắn tin thông báo KH hình thức Zalo",
      value: 1,
    },
  ];
  const [form] = Form.useForm();
  const [filterConditions] = useState({
    TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
    DenNgay: moment().format(FORMAT_ENGLISH),
    ...parseParams(location.search),
  });
  // layout effect
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const handleSearch = useCallback((values) => {
    const params = { ...values };
    const endpoint =
      Endpoint.B09_TB_EXCEL + "?" + buildQueryString(parseParams(params));
    downloadFile({
      endpoint,
      setLoading,
    });
  }, []);

  return (
    <Fragment>
      {screen === Endpoint.TH_TINH_HINH_NT_TB_KH_ZALO ? (
        <B09THKhachHang />
      ) : screen === Endpoint.TH_TINH_HINH_NT_TB_TIEN_DIEN_L1_ZALO ? (
        <B09THTienDien screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.CHI_TIET_TH_NT_TB_TIEN_DIEN_L1_ZALO ? (
        <B09ChiTietNTTBTienDienLan1Zalo
          screen={screen}
          handleChange={handleChange}
        />
      ) : screen === Endpoint.DS_KH_DANG_KY_ZALO_TONG_HOP ? (
        <DSKHZaloTongHop screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.DS_KH_DANG_KY_ZALO_CHI_TIET ? (
        <DSKHZaloChiTiet screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.DANH_SACH_KH_GUI_TN_THANH_CONG_TH ? (
        <DSKHGuiTinNhanThanhCong screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.DANH_SACH_KH_GUI_TN_THANH_CONG_CHI_TIET ? (
        <DSKHGuiTinNhanThanhCongChiTiet
          screen={screen}
          handleChange={handleChange}
        />
      ) : screen === Endpoint.DANH_SACH_KH_GUI_TN_KO_THANH_CONG_TH ? (
        <DSKHGuiTinNhanKhongThanhCong
          screen={screen}
          handleChange={handleChange}
        />
      ) : screen === Endpoint.DANH_SACH_KH_GUI_TN_KO_THANH_CONG_CHI_TIET ? (
        <DSKHGuiTinNhanKhongThanhCongChiTiet
          screen={screen}
          handleChange={handleChange}
        />
      ) : screen === Endpoint.BC_TH_TIN_NHAN ? (
        <BCTongHopTinNhan screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.BC_TH_TY_LE_NHAN_TIN ? (
        <TongHopTyLeNhanTin screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.BC_SL_KH_PHAT_TRIEN_MOI_EVNHANOI ? (
        <BCSlKhPhatTrienMoiEvnHaNoi
          screen={screen}
          handleChange={handleChange}
        />
      ) : screen === Endpoint.BC_DS_KH_PHAT_TRIEN_MOI ? (
        <BCDSKHPhatTrienMoi screen={screen} handleChange={handleChange} />
      ) : (
        <Container>
          <ContentWrapper>
            <FormComponent
              onFinish={handleSearch}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              initialValues={{
                reportType: screen,
                TuNgay: form.setFieldsValue(filterConditions.TuNgay)
                  ? form.setFieldsValue(filterConditions.TuNgay)
                  : moment().startOf(MONTH_STRING),
              }}
            >
              <Row gutter={12}>
                <Col span={24}>
                  <Form.Item
                    name="reportType"
                    label="Tên báo cáo"
                    labelAlign="left"
                  >
                    <Select
                      formKey="reportType"
                      form={form}
                      defaultValue={optionValue}
                      options={selectBaoCaoDoiSoatZalo}
                      onChange={() =>
                        handleChange(form.getFieldValue("reportType"))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item
                    name="TuNgay"
                    label="Ngày bắt đầu"
                    labelAlign="left"
                    labelCol={{ span: 12 }}
                  >
                    <div style={{ marginLeft: "3%" }}>
                      <DatePicker
                        allowClear={false}
                        formKey="TuNgay"
                        form={form}
                        labelAlign="left"
                        format={FORMAT_ENGLISH}
                        defaultValue={
                          filterConditions.TuNgay
                            ? moment(filterConditions.TuNgay, FORMAT_ENGLISH)
                            : moment().startOf(FORMAT_DATE)
                        }
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="DenNgay"
                    label="Ngày kết thúc"
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                  >
                    <DatePicker
                      formKey="DenNgay"
                      form={form}
                      labelAlign="left"
                      format={FORMAT_ENGLISH}
                      defaultValue={
                        filterConditions.DenNgay
                          ? moment(filterConditions.DenNgay, FORMAT_ENGLISH)
                          : moment()
                      }
                    />
                  </Form.Item>
                </Col>
                <Row justify="end">
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Xuất excel
                  </Button>
                </Row>
              </Row>
            </FormComponent>
            <Divider className="section-text-line" orientation="left"></Divider>
          </ContentWrapper>
        </Container>
      )}
    </Fragment>
  );
}

const Container = styled.div`
  margin-top: 40px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
