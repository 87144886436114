import { STATUSCODE_200, METHOD_PUT } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { getErrorMessage } from "@utils/function";
import { authPostData } from "@utils/request";
// call api hủy bảng kê

export const handleHuyBangKe = (
  setLoading,
  setFetchDataTable,
  closeModal,
  values,
  form
) => {
  authPostData({
    url: `${Endpoint.TRA_BANGKE}`,
    method: METHOD_PUT,
    payload: values,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        closeModal();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};
