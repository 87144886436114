import React, { Fragment } from "react";

import BaoCaoCTKDDVKHMoi from "@modules/bao-cao-kinh-doanh/bao-cao-quy-trinh-kinh-doanh/bao-cao-cong-tac-kinh-doanh-dich-vu-khach-hang-moi";
import { listOptionKiemChi } from "@modules/bao-cao-kinh-doanh/bao-cao-quy-trinh-kinh-doanh/constant";
import { Endpoint } from "@utils/endpoint";

export default function BaoCaoKiemChiNiemPhong() {
  return (
    <Fragment>
      <BaoCaoCTKDDVKHMoi
        optionType={listOptionKiemChi}
        initialReportType={Endpoint.BCCTKD_CT_QL_KIM_CHI_NIEM_PHONG_TONG_HOP}
        titlePage="Báo cáo kìm chì niêm phong"
      />
    </Fragment>
  );
}
