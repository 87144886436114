import { alertMessage422 } from "@modules/bao-cao-kinh-doanh/service";
import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";

export const selectBcThtt = [
  {
    label:
      "1. Tổng hợp tình hình thực hiện chỉ tiêu tổn thất điện năng theo phiên ghi chỉ số",
    value: Endpoint.BCTHTT_TH_CHI_TIEU_TON_THAT_DN_THEO_PHIEN_GHI_CHI_SO,
  },
  {
    label: "2. Báo cáo sản lượng điện năng đầu nguồn 3 giá",
    value: Endpoint.BCTHTT_BC_SAN_LUONG_DIEN_NANG_DAU_NGUON_3_GIA,
  },
];

export const exportData = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LuyKe: data.LuyKe,
      LoaiBC: data.LoaiBC,
    })
  );
  authGetData({
    url: `${data.reportType}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LuyKe: data.LuyKe,
      LoaiBC: data.LoaiBC,
      luyKe: data.luyKe
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      ...(data.reportDate && { Nam: data.reportDate.split("-")[0] }),
      ...(data.reportDate && { Thang: data.reportDate.split("-")[1] }),
      LuyKe: data.LuyKe,
      LoaiBC: data.LoaiBC,
    })
  );

  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
