import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  Suspense,
  Fragment,
} from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import {
  ENUM_DUTRU,
  ENUM_MODE_PHUONGANCAPDIEN,
  ENUM_VATTU,
  TYPE_BIEN_BAN,
} from "@utils/constants";
import LoadingComponent from "@components/Loading";
import ThongTinHoSoModal from "./modals/modal-thong-tin-ho-so";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import {
  getDataDuToanLoaiCap,
  getThongTinHoSo,
  handleListVatTuKhachHang,
  deleteVatTuKHDT,
  saveThongTinHoSo,
  deleteVatTuKHKG,
  getPDFBienBanKhaiGia,
  downLoadBienBan,
} from "../../../services";
import TableKhachHang from "./table/table-khach-hang";
import { CheckCircleFilled } from "@ant-design/icons";
import VatTuKhachHangKhaiGiaModal from "./modals/modal-vat-tu-khach-hang-khai-gia";
import VatTuKhachHangDuToanModal from "./modals/modal-vat-tu-khach-hang-du-toan";
import _ from "lodash";
import PDFModal from "@modules/cap-dien/common-ho-so/pdf-viewer";
import { useDispatch, useSelector } from "react-redux";
import {
  changeHinhThucLapDat,
  lapPhuongAnSelector,
} from "@state/cap-dien/nhan-vien-khao-sat/lap-phuong-an/reducer";

export default function VatTuKhachHangPanel({
  dataChungLoaiVatTu,
  detailLapPhuongAn,
  mode,
  indexTab,
}) {
  const { hadChangeHTLD } = useSelector(lapPhuongAnSelector);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [detailVatTu, setDetailVatTu] = useState({});
  const [visibleThongTinHS, setVisibleThongTinHS] = useState(false);
  const [typeVatTu, setTypeVatTu] = useState();
  const [dataKH, setDataKH] = useState([]);
  const [thongTinHoSo, setThongTinHoSo] = useState({});
  const [loadingDTLC, setLoadingDTLC] = useState(false);
  const [listDuToan, setListDuToan] = useState([]);
  const [listLoaiCap, setListLoaiCap] = useState([]);
  const [flagDTLC, setFlagDTLC] = useState(false);
  const [visibleKhachHangKhaiGia, setVisibleKhachHangKhaiGia] = useState(false);
  const [visibleKhachHangDuToan, setVisibleKhachHangDuToan] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [visiblePDF, setVisiblePDF] = useState(false);

  const dispatch = useDispatch();

  const getListData = () => {
    handleListVatTuKhachHang(setLoading, setDataKH, t, detailLapPhuongAn);
  };

  useEffect(() => {
    getListData();
    getDataDuToanLoaiCap(
      setLoadingDTLC,
      setListDuToan,
      setListLoaiCap,
      setFlagDTLC
    );
    getThongTinHoSo(detailLapPhuongAn, setLoading, setThongTinHoSo, t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailLapPhuongAn]);

  useEffect(() => {
    if (refresh) {
      getListData();
      getDataDuToanLoaiCap(
        setLoadingDTLC,
        setListDuToan,
        setListLoaiCap,
        setFlagDTLC
      );
      getThongTinHoSo(detailLapPhuongAn, setLoading, setThongTinHoSo, t);
      if (refresh) setRefresh(!refresh);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (indexTab === "5" && hadChangeHTLD) {
      setRefresh(true);
      dispatch(changeHinhThucLapDat(false));
    }
  }, [dispatch, hadChangeHTLD, indexTab]);

  const formatter = (value) =>
    value !== null ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;

  //khai báo columns

  const columns =
    mode !== ENUM_MODE_PHUONGANCAPDIEN.XEMLAI
      ? [
          {
            title: t("vat_tu.vat_tu_khach_hang.ten_vat_tu_thiet_bi"),
            dataIndex: "tenVatTu",
            key: "tenVatTu",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "20%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.so_luong"),
            dataIndex: "soLuong",
            key: "soLuong",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "5%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.don_gia_dich_vu"),
            dataIndex: "donGiaDichVu",
            key: "donGiaDichVu",
            render: (text) => (
              <div className="table-text-right">
                <span>{formatter(text)}</span>
              </div>
            ),
            width: "8%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.vat_tu_phu_tinh_thue"),
            dataIndex: "isVatTuPhuTinhThue",
            key: "isVatTuPhuTinhThue",
            render: (text) => (
              <div className="table-text-center">
                {text ? (
                  <CheckCircleFilled
                    style={{ fontSize: "20px", color: "#03A4FF" }}
                  />
                ) : null}
              </div>
            ),
            width: "8%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.vat_tu_chinh"),
            dataIndex: "isVatTuChinh",
            key: "isVatTuChinh",
            render: (text) => (
              <div className="table-text-center">
                {text ? (
                  <CheckCircleFilled
                    style={{ fontSize: "20px", color: "#03A4FF" }}
                  />
                ) : null}
              </div>
            ),
            width: "5%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.vat_tu_mua_ngoai"),
            dataIndex: "isVatTuMuaNgoai",
            key: "isVatTuMuaNgoai",
            render: (text) => (
              <div className="table-text-center">
                {text ? (
                  <CheckCircleFilled
                    style={{ fontSize: "20px", color: "#03A4FF" }}
                  />
                ) : null}
              </div>
            ),
            width: "5%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.don_vi_tinh"),
            dataIndex: "donViTinh",
            key: "donViTinh",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "5%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.chung_loai"),
            dataIndex: "chungLoai",
            key: "chungLoai",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },

          {
            title: t("tac_vu"),
            key: "action",
            width: "5%",
            render: (row) => (
              <div className="action-table-column">
                <div
                  className="action-icon"
                  onClick={() =>
                    handleOpenModal({
                      row: row,
                      type: row.isVatTuMuaNgoai
                        ? ENUM_VATTU.MUANGOAI
                        : ENUM_VATTU.COSAN,
                    })
                  }
                >
                  <PencilIcon />
                </div>
                <div
                  className="action-icon"
                  onClick={() =>
                    handleDeleteVatTuKHDT({
                      id: row.id,
                      type: row.isVatTuMuaNgoai
                        ? ENUM_VATTU.MUANGOAI
                        : ENUM_VATTU.COSAN,
                    })
                  }
                >
                  <TrashIcon />
                </div>
              </div>
            ),
          },
        ]
      : [
          {
            title: t("vat_tu.vat_tu_khach_hang.ten_vat_tu_thiet_bi"),
            dataIndex: "tenVatTu",
            key: "tenVatTu",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "20%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.so_luong"),
            dataIndex: "soLuong",
            key: "soLuong",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "5%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.don_gia_dich_vu"),
            dataIndex: "donGiaDichVu",
            key: "donGiaDichVu",
            render: (text) => (
              <div className="table-text-right">
                <span>{formatter(text)}</span>
              </div>
            ),
            width: "8%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.vat_tu_phu_tinh_thue"),
            dataIndex: "isVatTuPhuTinhThue",
            key: "isVatTuPhuTinhThue",
            render: (text) => (
              <div className="table-text-center">
                {text ? (
                  <CheckCircleFilled
                    style={{ fontSize: "20px", color: "#03A4FF" }}
                  />
                ) : null}
              </div>
            ),
            width: "8%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.vat_tu_chinh"),
            dataIndex: "isVatTuChinh",
            key: "isVatTuChinh",
            render: (text) => (
              <div className="table-text-center">
                {text ? (
                  <CheckCircleFilled
                    style={{ fontSize: "20px", color: "#03A4FF" }}
                  />
                ) : null}
              </div>
            ),
            width: "5%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.vat_tu_mua_ngoai"),
            dataIndex: "isVatTuMuaNgoai",
            key: "isVatTuMuaNgoai",
            render: (text) => (
              <div className="table-text-center">
                {text ? (
                  <CheckCircleFilled
                    style={{ fontSize: "20px", color: "#03A4FF" }}
                  />
                ) : null}
              </div>
            ),
            width: "5%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.don_vi_tinh"),
            dataIndex: "donViTinh",
            key: "donViTinh",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "5%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.chung_loai"),
            dataIndex: "chungLoai",
            key: "chungLoai",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
        ];

  const columnsKhaiGia =
    mode !== ENUM_MODE_PHUONGANCAPDIEN.XEMLAI
      ? [
          {
            title: t("vat_tu.vat_tu_khach_hang.ten_vat_tu_thiet_bi"),
            dataIndex: "tenVatTu",
            key: "tenVatTu",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "35%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.so_luong"),
            dataIndex: "soLuong",
            key: "soLuong",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.dg_vat_lieu"),
            dataIndex: "donGiaVatLieu",
            key: "donGiaVatLieu",
            render: (text) => (
              <div className="table-text-right">
                <span>{formatter(text)}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.dg_nhan_cong"),
            dataIndex: "donGiaNhanCong",
            key: "donGiaNhanCong",
            render: (text) => (
              <div className="table-text-right">
                <span>{formatter(text)}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.dg_mtc"),
            dataIndex: "donGiaMTC",
            key: "donGiaMTC",
            render: (text) => (
              <div className="table-text-right">
                <span>{formatter(text)}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: "Định mức",
            dataIndex: "loaiChiPhiGianTiep",
            key: "loaiChiPhiGianTiep",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "15%",
          },
          {
            title: "Vật tư phụ tính thuế",
            dataIndex: "isVatTuPhuTinhThue",
            key: "isVatTuPhuTinhThue",
            render: (text) => (
              <div className="table-text-center">
                {text ? (
                  <CheckCircleFilled
                    style={{ fontSize: "20px", color: "#03A4FF" }}
                  />
                ) : null}
              </div>
            ),
            width: "10%",
          },
          {
            title: "Vật tư mua ngoài",
            dataIndex: "isVatTuMuaNgoai",
            key: "isVatTuMuaNgoai",
            render: (text) => (
              <div className="table-text-center">
                {text ? (
                  <CheckCircleFilled
                    style={{ fontSize: "20px", color: "#03A4FF" }}
                  />
                ) : null}
              </div>
            ),
            width: "10%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.don_vi_tinh"),
            dataIndex: "donViTinh",
            key: "donViTinh",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.chung_loai"),
            dataIndex: "chungLoai",
            key: "chungLoai",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: t("tac_vu"),
            key: "action",
            width: "8%",
            render: (row) => (
              <div className="action-table-column">
                <div
                  className="action-icon"
                  onClick={() =>
                    handleOpenModal({
                      row: row,
                      type: row.isVatTuMuaNgoai
                        ? ENUM_VATTU.MUANGOAI
                        : ENUM_VATTU.COSAN,
                    })
                  }
                >
                  <PencilIcon />
                </div>
                <div
                  className="action-icon"
                  onClick={() =>
                    handleDeleteVatTuKHKG({
                      id: row.id,
                      type: row.isVatTuMuaNgoai
                        ? ENUM_VATTU.MUANGOAI
                        : ENUM_VATTU.COSAN,
                    })
                  }
                >
                  <TrashIcon />
                </div>
              </div>
            ),
          },
        ]
      : [
          {
            title: t("vat_tu.vat_tu_khach_hang.ten_vat_tu_thiet_bi"),
            dataIndex: "tenVatTu",
            key: "tenVatTu",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "35%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.so_luong"),
            dataIndex: "soLuong",
            key: "soLuong",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.dg_vat_lieu"),
            dataIndex: "donGiaVatLieu",
            key: "donGiaVatLieu",
            render: (text) => (
              <div className="table-text-right">
                <span>{formatter(text)}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.dg_nhan_cong"),
            dataIndex: "donGiaNhanCong",
            key: "donGiaNhanCong",
            render: (text) => (
              <div className="table-text-right">
                <span>{formatter(text)}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.dg_mtc"),
            dataIndex: "donGiaMTC",
            key: "donGiaMTC",
            render: (text) => (
              <div className="table-text-right">
                <span>{formatter(text)}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: "Định mức",
            dataIndex: "loaiChiPhiGianTiep",
            key: "loaiChiPhiGianTiep",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "15%",
          },
          {
            title: "Vật tư phụ tính thuế",
            dataIndex: "isVatTuPhuTinhThue",
            key: "isVatTuPhuTinhThue",
            render: (text) => (
              <div className="table-text-center">
                {text ? (
                  <CheckCircleFilled
                    style={{ fontSize: "20px", color: "#03A4FF" }}
                  />
                ) : null}
              </div>
            ),
            width: "10%",
          },
          {
            title: "Vật tư mua ngoài",
            dataIndex: "isVatTuMuaNgoai",
            key: "isVatTuMuaNgoai",
            render: (text) => (
              <div className="table-text-center">
                {text ? (
                  <CheckCircleFilled
                    style={{ fontSize: "20px", color: "#03A4FF" }}
                  />
                ) : null}
              </div>
            ),
            width: "10%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.don_vi_tinh"),
            dataIndex: "donViTinh",
            key: "donViTinh",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: t("vat_tu.vat_tu_khach_hang.chung_loai"),
            dataIndex: "chungLoai",
            key: "chungLoai",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
        ];

  const showModalPdfKhaiGia = useMemo(
    () =>
      visiblePDF && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visiblePDF}
            setVisible={setVisiblePDF}
            title={`${TYPE_BIEN_BAN.KHAI_GIA} ${detailLapPhuongAn?.maYCKNCmis} ${detailLapPhuongAn.tenKH}`}
            getBienBan={(setUrl) => showModalPDF(setUrl)}
            downloadFileExcel={() =>
              downLoadBienBan(detailLapPhuongAn, setLoadingDownload)
            }
            setLoadingDownload={setLoadingDownload}
            loadingDownload={loadingDownload}
            loadingGetBienBan={loadingPDF}
            isDownloadExcel={true}
            isReload={true}
            type={TYPE_BIEN_BAN.KHAI_GIA}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visiblePDF]
  );

  const genExtra = () => (
    <div style={{ textAlign: "right", margin: "10px 0 16px 0" }}>
      {mode !== ENUM_MODE_PHUONGANCAPDIEN.XEMLAI ? (
        <Fragment>
          {thongTinHoSo.loaiDuToan === ENUM_DUTRU.KHAI_GIA ? (
            <Button
              size="small"
              type="primary"
              className="mr-5px"
              onClick={() => setVisiblePDF(true)}
            >
              Biên bản khai giá
            </Button>
          ) : null}
          <Button
            size="small"
            type="primary"
            className="mr-5px"
            onClick={() =>
              handleOpenModal({ row: null, type: ENUM_VATTU.COSAN })
            }
          >
            <PlusOutlined /> Vật tư có sẵn
          </Button>
          <Button
            size="small"
            type="primary"
            className="mr-5px"
            onClick={() =>
              handleOpenModal({ row: null, type: ENUM_VATTU.MUANGOAI })
            }
          >
            <PlusOutlined /> Vật tư mua ngoài
          </Button>
        </Fragment>
      ) : null}
    </div>
  );

  //xử lý call api sửa/tạo

  const handleCreateUpdate = () => {};

  //xóa dữ liệu du toan
  const handleDeleteVatTuKHDT = useCallback(
    ({ id, type }) => {
      deleteVatTuKHDT(
        id,
        setLoading,
        () => setRefresh(true),
        t,
        type,
        detailLapPhuongAn,
        // (value) => {
        //   if (value && tongChiPhi) {
        //     const newTongChiPhi =
        //       toNumber(tongChiPhi) -
        //       toNumber(value.donGiaCu) +
        //       toNumber(value.donGiaMoi);
        //     dispatch(updateTongChiPhi(newTongChiPhi));
        //   }
        // }
        () => false
      );
    },

    [detailLapPhuongAn, t]
  );

  //xóa dữ liệu khai gia
  const handleDeleteVatTuKHKG = useCallback(
    ({ id, type }) => {
      deleteVatTuKHKG(
        id,
        setLoading,
        () => setRefresh(true),
        t,
        type,
        detailLapPhuongAn,
        // (value) => {
        //   if (value && tongChiPhi) {
        //     const newTongChiPhi =
        //       toNumber(tongChiPhi) -
        //       toNumber(value.donGiaCu) +
        //       toNumber(value.donGiaMoi);
        //     dispatch(updateTongChiPhi(newTongChiPhi));
        //   }
        // }
        () => false
      );
    },

    [detailLapPhuongAn, t]
  );

  //hiển thị popup, set dữ liệu chi tiết

  const handleOpenModal = useCallback(
    ({ row, type }) => {
      if (row) {
        setDetailVatTu(row);
        if (thongTinHoSo.loaiDuToan === ENUM_DUTRU.KHAI_GIA) {
          setTypeVatTu(type);
          setVisibleKhachHangKhaiGia(!visibleKhachHangKhaiGia);
        } else {
          setTypeVatTu(type);
          setVisibleKhachHangDuToan(!visibleKhachHangDuToan);
        }
      } else {
        setDetailVatTu({});
        if (thongTinHoSo.loaiDuToan === ENUM_DUTRU.KHAI_GIA) {
          setTypeVatTu(type);
          setVisibleKhachHangKhaiGia(!visibleKhachHangKhaiGia);
        } else {
          setTypeVatTu(type);
          setVisibleKhachHangDuToan(!visibleKhachHangDuToan);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleKhachHangKhaiGia, visibleKhachHangDuToan, thongTinHoSo]
  );
  const handleOpenThongTinHS = () => {
    setFlagDTLC(true);
    setVisibleThongTinHS(!visibleThongTinHS);
  };

  const showModalPDF = useCallback(
    (setUrl) => {
      getPDFBienBanKhaiGia(
        detailLapPhuongAn,
        (value) => {
          setUrl(value);
        },
        setLoadingPDF,
        t
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detailLapPhuongAn]
  );

  //đóng popup

  const handleCloseThongTinModal = () => {
    setVisibleThongTinHS(false);
  };
  const handleCloseKHKGModal = () => {
    setVisibleKhachHangKhaiGia(false);
  };
  const handleCloseKHDTModal = () => {
    setVisibleKhachHangDuToan(false);
  };

  const updateThongTinHoSo = (values, form) => {
    if (thongTinHoSo.loaiDuToan === ENUM_DUTRU.DU_TOAN) {
      _.omit(values, ["ngayLapKhaiGia", "boPhanLap", "boPhanKiemTra"]);
    }
    saveThongTinHoSo(
      { ...values, hoSoId: detailLapPhuongAn?.hoSoId ?? detailLapPhuongAn?.id },
      setLoading,
      () => setRefresh(true),
      () => {
        handleCloseKHKGModal();
        handleCloseKHDTModal();
        setVisibleThongTinHS(false);
      },
      form
    );
  };

  const showModalKhachHangKhaiGia = useMemo(
    () =>
      visibleKhachHangKhaiGia && (
        <VatTuKhachHangKhaiGiaModal
          detailVatTu={detailVatTu}
          visible={visibleKhachHangKhaiGia}
          handleCreateUpdate={handleCreateUpdate}
          closeModal={handleCloseKHKGModal}
          dataChungLoaiVatTu={dataChungLoaiVatTu}
          typeVatTu={typeVatTu}
          detailLapPhuongAn={detailLapPhuongAn}
          refreshTable={() => setRefresh(true)}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleKhachHangKhaiGia]
  );

  const showModalKhachHangDuToan = useMemo(
    () =>
      visibleKhachHangDuToan && (
        <VatTuKhachHangDuToanModal
          detailVatTu={detailVatTu}
          visible={visibleKhachHangDuToan}
          closeModal={handleCloseKHDTModal}
          typeVatTu={typeVatTu}
          detailLapPhuongAn={detailLapPhuongAn}
          refreshTable={() => setRefresh(true)}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleKhachHangDuToan]
  );

  const showModalThayDoi = useMemo(
    () =>
      visibleThongTinHS && (
        <ThongTinHoSoModal
          closeModal={handleCloseThongTinModal}
          visible={visibleThongTinHS}
          updateThongTinHoSo={updateThongTinHoSo}
          setThongTinHoSo={setThongTinHoSo}
          thongTinHoSo={thongTinHoSo}
          listDuToan={listDuToan}
          listLoaiCap={listLoaiCap}
          flagDTLC={flagDTLC}
          setLoadingDTLC={setLoadingDTLC}
          loadingDTLC={loadingDTLC}
          setFlagDTLC={setFlagDTLC}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleThongTinHS]
  );

  return (
    <LoadingComponent loading={loading}>
      <TableKhachHang
        mode={mode}
        genExtra={genExtra}
        columns={
          thongTinHoSo.loaiDuToan === ENUM_DUTRU.KHAI_GIA
            ? columnsKhaiGia
            : columns
        }
        dataKH={dataKH}
        handleOpenThongTinHS={handleOpenThongTinHS}
        thongTinHoSo={thongTinHoSo}
        listDuToan={listDuToan}
        listLoaiCap={listLoaiCap}
      />
      {showModalThayDoi}
      {showModalKhachHangKhaiGia}
      {showModalKhachHangDuToan}
      {showModalPdfKhaiGia}
    </LoadingComponent>
  );
}
