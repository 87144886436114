import { Endpoint } from "@utils/endpoint";
import { authGetData, authGetMultiplesRequest } from "@utils/request";
import { STATUSCODE_200 } from "@utils/constants";

export const handleListNguonTiepNhan = (
  donViId,
  setLoading,
  setDataBDThang,
  setDataBDLK,
  setDataMonth,
  setDataLK,
  setTitlteThangP1,
  setTitlteLKP1
) => {
  authGetData({
    url: Endpoint.NGUON_TIEP_NHAN_TRANG_CHU + "?donViId=" + donViId,
    setLoading,
    onSuccess: (res) => {
      if (res.data && res.statusCode === STATUSCODE_200) {
        setDataBDThang(res.data.dataChartThang.series);
        setDataMonth(res.data.detailsThang);
        setDataBDLK(res.data.dataChartLuyKe.series);
        setDataLK(res.data.detailsLuyKe);
        setTitlteThangP1(res.data.dataChartThang.title);
        setTitlteLKP1(res.data.dataChartLuyKe.title);
      }
    },
  });
};

export const handleListThongKeKQGQn = (
  donViId,
  setLoading,
  setDataYCThang,
  setDataYCQuy,
  setDataYCNam,
  setDataTableKQGQ,
  setTitleThang,
  setTitleQuy,
  setTitleNam
) => {
  authGetData({
    url: Endpoint.TONG_HOP_KET_QUA_GIAI_QUYET_YEU_CAU + "?donViId=" + donViId,
    setLoading,
    onSuccess: (res) => {
      if (res.data && res.statusCode === STATUSCODE_200) {
        setDataYCThang(res.data.dataChartThang.series);
        setDataYCQuy(res.data.dataChartQuy.series);
        setDataYCNam(res.data.dataChartNam.series);
        setDataTableKQGQ(res.data.details);
        setTitleThang(res.data.dataChartThang.title);
        setTitleQuy(res.data.dataChartQuy.title);
        setTitleNam(res.data.dataChartNam.title);
      }
    },
  });
};

export const handleListYeuCauTheoTT = (
  donViId,
  setLoading,
  setDataTongHop,
  setTitleP3
) => {
  authGetData({
    url: Endpoint.LIST_DATA_YEU_CAU_THEO_TRANG_THAI + "?donViId=" + donViId,
    setLoading,
    onSuccess: (res) => {
      if (res.data && res.statusCode === STATUSCODE_200) {
        setDataTongHop(res.data.series);
        setTitleP3(res.data.title);
      }
    },
  });
};

export const handleListYeuCauChamTD = (
  donViId,
  setLoading,
  setDataChamTienDo
) => {
  authGetData({
    url: Endpoint.LIST_DATA_YEU_CAU_CHAM_TIEN_DO + "?donViId=" + donViId,
    setLoading,
    onSuccess: (res) => {
      if (res.data && res.statusCode === STATUSCODE_200) {
        setDataChamTienDo(res.data.series);
      }
    },
  });
};

export const handleMultiBoLoc = (setLoading, setDonVi) => {
  const endpoints = [`${Endpoint.LIST_DON_VI}?pageSize=-1`];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDonVi(res[0].data.data);
    },
  });
};
