import { openModal } from "@state/system-config/reducer";
import ModalBienBanTreoThao from "./modal/tao-moi-bien-ban-treo-thao";
import ModalSuaBienBanTreoThao from "./modal/sua-bien-ban-treo-thao";
import { Menu } from "antd";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { authPostData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { METHOD_POST } from "@utils/constants";
export default function ListMenu(props) {
  const { details, loading, setLoading, getListDD, setDataTable } = props;
  const dispatch = useDispatch();
  const handleDelete = useCallback((maCongTo, maDiemDo) => {
    authPostData({
      url: `${Endpoint.DELETE_BIEN_BAN}`,
      payload: { maDiemDo: maDiemDo, maCongTo: maCongTo },
      method: METHOD_POST,
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          getListDD();
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Menu mode="vertical" style={{ width: "100%" }}>
      <Menu.Item
        eventKey="1"
        onClick={() =>
          dispatch(
            openModal({
              title: "Tạo biên bản",
              content: (
                <ModalBienBanTreoThao
                  details={details}
                  loading={loading}
                  setLoading={setLoading}
                  getListDD={getListDD}
                />
              ),
              size: "large",
            })
          )
        }
      >
        Tạo biên bản
      </Menu.Item>
      {/* Check đk hien thi  */}
      {details?.hienThiSuaHuy === true ? (
        <>
          {" "}
          <Menu.Item
            eventKey="2"
            onClick={() =>
              dispatch(
                openModal({
                  title: "Sửa biên bản",
                  content: (
                    <ModalSuaBienBanTreoThao
                      details={details}
                      loading={loading}
                      setLoading={setLoading}
                      getListDD={getListDD}
                    />
                  ),
                  size: "large",
                })
              )
            }
          >
            Sửa biên bản
          </Menu.Item>
          <Menu.Item
            eventKey="3"
            onClick={() =>
              handleDelete(
                details?.maCongTo ? details?.maCongTo : details?.maCongToThao,
                details?.maDiemDo
              )
            }
          >
            Hủy biên bản
          </Menu.Item>
        </>
      ) : null}
    </Menu>
  );
}
