import { CaretRightOutlined } from "@ant-design/icons";
import { DEFAULT_PAGESIZE } from "@utils/constants";
import { Collapse, ConfigProvider, Layout, Table } from "antd";
import vn from "antd/es/locale-provider/vi_VN";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Resizable } from "react-resizable";
const { Panel } = Collapse;

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

function TableComponent(props) {
  const tableRef = useRef(null);
  const {
    renderExtraAction,
    dataSource = [],
    columns,
    header,
    totalData,
    rowSelection,
    onRow,
    loading,
    onChangePagination,
    pagination,
    scrollX,
    rowKey,
    expandable,
    summary,
    components,
    scrollY,
    rowClassName,
    id,
    addRow,
    isNoResize,
    pageSizeOptionKTCT,
    rowId,
  } = props;

  const [columnsResize, setColumnsResize] = useState(columns?.filter((item) => item) || []);  

  useEffect(() => {
    const columnsWithoutEmpty = columns?.filter((item) => item) || [];
    const columnsResizeMap = columnsWithoutEmpty.map((item) => {
      const index = columnsResize.findIndex((e) => (item?.key ? item?.key === e?.key : item?.dataIndex === e?.dataIndex));
      return {
        ...item,
        width: columnsResize[index]?.width,
      };
    });
    setColumnsResize(columnsResizeMap || []);
  }, [columns]);

  const componentsCustom = {
    ...components,
    header: {
      cell: ResizableTitle,
    },
  };

  const handleResize =
    (index) =>
    (e, { size }) => {
      const nextColumns = [...columnsResize];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      setColumnsResize([...nextColumns]);
    };

  const columnsMap = columnsResize.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: typeof column.width === "string" || !column.width ? 200 : column.width,
      onResize: handleResize(index),
    }),
  }));

  return (
    <ContainerStyled id={id ? id : "collapse-container"}>
      <Collapse
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => <CaretRightOutlined style={{ fontSize: 18 }} rotate={isActive ? 90 : 0} />}
        className="table-component-panel"
      >
        <Panel header={header} extra={renderExtraAction ? renderExtraAction() : null} collapsible={"header"} key="1">
          <ConfigProvider locale={vn}>
            <TableStyled id="container" ref={tableRef}>
              {addRow && <Layout>{addRow}</Layout>}

              <Table
                loading={loading}
                rowSelection={rowSelection ? rowSelection : null}
                columns={isNoResize ? columns : columnsMap}
                bordered={true}
                dataSource={dataSource}
                rowKey={(record) => (record ? record[rowKey] ?? record.id : null)}
                scroll={{
                  x: scrollX ? scrollX : true,
                  y: scrollY ? scrollY : false,
                }}
                summary={summary ? summary : null}
                onChange={onChangePagination}
                pagination={
                  pagination
                    ? {
                        total: totalData,
                        showTotal: (total) => `Tổng ${total} bản ghi`,
                        defaultpageSize: DEFAULT_PAGESIZE,
                        defaultCurrent: 1,
                        current: parseInt(pagination.pageIndex),
                        pageSize: parseInt(pagination.pageSize),
                        showSizeChanger: true,
                        pageSizeOptions: pageSizeOptionKTCT
                          ? pageSizeOptionKTCT
                          : totalData > 200
                          ? [10, 20, 50, 100, 200, totalData]
                          : [10, 20, 50, 100, 200],
                      }
                    : false
                }
                onRow={onRow || null}
                expandable={expandable || null}
                components={componentsCustom}
                rowClassName={
                  rowClassName
                    ? rowClassName
                    : (record) => (rowId !== null && record?.id && rowId === record?.id ? "ant-table-cell-row-click" : "")
                }
              />
            </TableStyled>
          </ConfigProvider>
        </Panel>
      </Collapse>
    </ContainerStyled>
  );
}

export default TableComponent;

const ContainerStyled = styled.div`
  padding: 10px;
`;
const TableStyled = styled.div`
  .ant-table-thead {
    .ant-table-cell {
      background: #d9d9d9;
      color: #1f1f1f;
    }
  }
  && tbody > tr:hover > td,
  && tbody > tr.ant-table-cell-row-click > td {
    background: rgb(207, 232, 247);
  }
  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }

  .react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
  }
  && tbody > tr:hover > td,
  && tbody > tr.ant-table-cell-row-click > td {
    background: rgb(207, 232, 247);
  }
`;
