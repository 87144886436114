import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";

import { Button } from "antd";
import { tien_ich_chung } from "@permissions/m_tienichchung";
import CheckPermission from "@components/CheckPermission";
import LoaiDiemDoModal from "./modal-loai-diem-do";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  TABINDEX_CMIS,
} from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import { handleListData, handleRemoveData } from "./services";
import Table from "./table";
import EllipsisTooltip from "@components/EllipsisTooltip";
import { Endpoint } from "@utils/endpoint";
import { dongBoCMIS } from "../services";

export default function Index() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [detailDiemDo, setDetailDiemDo] = useState({});
  const [dataDiemDo, setDataDiemDo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu

  const getListDiemDo = useCallback(() => {
    handleListData(setLoading, filterConditions, setDataDiemDo, setTotalCount);
  }, [filterConditions]);

  useEffect(() => {
    getListDiemDo();
    // eslint-disable-next-line
  }, [getListDiemDo]);

  //thay đổi pagination table

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //hiển thị các button thêm,...

  const genExtra = () => (
    <div>
      <CheckPermission permissionCode={tien_ich_chung.m120t}>
        <Button
          size="small"
          type="primary"
          className="mr-5px"
          onClick={() => handleOpenModal()}
        >
          {t("button.tao_moi")}
        </Button>
      </CheckPermission>
      <CheckPermission permissionCode={tien_ich_chung.m120cmis}>
        <Button
          size="small"
          type="primary"
          className="btn-bg-yellow"
          onClick={handleDongBoCMIS}
          loading={loading}
        >
          Đồng bộ CMIS
        </Button>
      </CheckPermission>
    </div>
  );

  const handleDongBoCMIS = useCallback(() => {
    const url = Endpoint.DONG_BO_CMIS + `?tabIndex=${TABINDEX_CMIS.LOAI_DDO}`;
    dongBoCMIS(url, setLoading, getListDiemDo);
  }, [getListDiemDo]);

  //khai bái columns

  const columns = [
    {
      title: "Loại điểm đo",
      dataIndex: "loaiDDO",
      key: "loaiDDO",
      sorter: true,
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Loại BCS",
      dataIndex: "loaiBCS",
      key: "loaiBCS",
      sorter: true,
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "Thời gian bán điện",
      dataIndex: "tGianBDien",
      key: "tGianBDien",
      sorter: true,
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "Mô tả",
      dataIndex: "moTa",
      key: "moTa",
      sorter: true,
      render: (text) => <EllipsisTooltip title={text}>{text}</EllipsisTooltip>,
      width: "40%",
    },
    {
      title: "Mã chức năng",
      dataIndex: "maChucNang",
      key: "maChucNang",
      sorter: true,
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: t("tac_vu"),
      key: "action",
      fixed: "right",
      render: (row) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={tien_ich_chung.m120s}>
            <div className="action-icon" onClick={() => handleOpenModal(row)}>
              <PencilIcon />
            </div>
          </CheckPermission>
          <CheckPermission permissionCode={tien_ich_chung.m120x}>
            <div
              className="action-icon"
              onClick={() => handleDeleteData(row.id)}
            >
              <TrashIcon />
            </div>
          </CheckPermission>
        </div>
      ),
    },
  ];

  //tìm kiếm dữ liệu

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  //xóa dữ liệu

  const handleDeleteData = useCallback(
    (ids) => {
      handleRemoveData(ids, setLoading, getListDiemDo, t);
    },
    [getListDiemDo, t]
  );

  //hiển thị popup, set dữ liệu chi tiết

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDetailDiemDo(row);
      else setDetailDiemDo({});
      setVisible(!visible);
    },
    [visible]
  );

  //đóng popup

  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
  };

  //render popup

  const showModal = useMemo(
    () =>
      visible && (
        <LoaiDiemDoModal
          detailDiemDo={detailDiemDo}
          visible={visible}
          closeModal={handleCloseModal}
          getListDiemDo={getListDiemDo}
        />
      ),
    // eslint-disable-next-line
    [visible]
  );

  return (
    <Fragment>
      <Table
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        genExtra={genExtra}
        dataSource={dataDiemDo}
        columns={columns}
        totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        t={t}
      />
      {showModal}
    </Fragment>
  );
}
