import { Form } from "antd";
import { useCallback, useEffect } from "react";
import FormBoLoc from "./form-bo-loc";

export function ListFilter(props) {
  const { handleSearch, clearFilter, filterConditions, tooltip } = props;
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);
  return (
    <FormBoLoc
      form={form}
      handleSearch={handleSearch}
      tooltip={tooltip}
      clear={clear}
    />
  );
}
