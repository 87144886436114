import { useCallback, useEffect } from "react";
import FormBoLocXuatKho from "./form-bo-loc";

export default function ListFilterDinhKy(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    tooltip,
    user,
    loaiBangKe,
    handleKiemTraLai,
    handleTaoBangKe,
    handleLayDuLieu,
    form,
    nguoiThucHien,
  } = props;

  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);
  // set default value người thực hiện

  const handleChangeDoi = useCallback(() => {
    form.setFieldsValue({
      nguoiThucHienId: undefined,
    });
  }, [form]);

  return (
    <FormBoLocXuatKho
      user={user}
      form={form}
      handleSearch={handleSearch}
      tooltip={tooltip}
      clear={clear}
      filterConditions={filterConditions}
      loaiBangKe={loaiBangKe}
      handleKiemTraLai={handleKiemTraLai}
      handleTaoBangKe={handleTaoBangKe}
      handleLayDuLieu={handleLayDuLieu}
      nguoiThucHien={nguoiThucHien}
      handleChangeDoi={handleChangeDoi}
    />
  );
}
