import FormComponent from "@components/Form";
import { Col, Input, Row, Form, Button, Divider } from "antd";
import React, { useState, useEffect, Fragment, useCallback } from "react";
import { handleDetailCongTo } from "../../services";
import LoadingComponent from "@components/Loading";
import { debounce, isEqual } from "lodash";
import { BCS_CONG_TO, CS_CONG_TO } from "@utils/constants";
import { notSavedCongTo } from "@state/cap-dien/thi-cong/xac-nhan-thi-cong/reducer";
import CheckBSCCongTo from "@components/CheckBCSCongTo";

export default function ThongTinCongToTabs(props) {
  const {
    // initCT,
    // setInitCT,
    // selectedTab,
    handleSubmitCongTo,
    t,
    dispatch,
    closeModal,
    detail,
    loadingSubmit,
    initCongTo,
    setInitCongTo,
  } = props;
  const [form] = Form.useForm();
  const [, setLoading] = useState(false);
  const [dataCongTo, setDataCongTo] = useState({});
  const [isThao, setIsThao] = useState(false);

  //get thông tin công tơ
  const getThongTinCongTo = useCallback(() => {
    handleDetailCongTo(detail, setLoading, setDataCongTo, setIsThao);
  }, [detail]);

  //fetch thông tin
  useEffect(() => {
    getThongTinCongTo();
  }, [getThongTinCongTo]);

  const [sequenceTreo, setSequenceTreo] = useState([
    BCS_CONG_TO.OTHER.sequence,
  ]);
  const [sequenceThao, setSequenceThao] = useState([
    BCS_CONG_TO.OTHER.sequence,
  ]);
  useEffect(() => {
    if (dataCongTo && Object.keys(dataCongTo).length > 0) {
      if (
        dataCongTo.congToTreoBoChiSo &&
        (dataCongTo.congToTreoBoChiSo.includes("BN") ||
          dataCongTo.congToTreoBoChiSo.includes("CN") ||
          dataCongTo.congToTreoBoChiSo.includes("TN"))
      ) {
        //Truong hop co BN CN TN
        setSequenceTreo([BCS_CONG_TO.BN.sequence]);
      } else {
        const sequenceBCSCongToTreo = Object.values(BCS_CONG_TO)
          .map((value) => {
            if (dataCongTo.congToTreoBoChiSo === value.name) {
              return value.sequence;
            } else return undefined;
          })
          .filter((item) => item !== undefined);
        if (sequenceBCSCongToTreo.length > 0) {
          //default
          setSequenceTreo(sequenceBCSCongToTreo);
        }
      }

      if (
        dataCongTo.congToThaoBoChiSo &&
        (dataCongTo.congToThaoBoChiSo.includes("BN") ||
          dataCongTo.congToThaoBoChiSo.includes("CN") ||
          dataCongTo.congToThaoBoChiSo.includes("TN"))
      ) {
        //Truong hop co BN CN TN
        setSequenceThao([BCS_CONG_TO.BN.sequence]);
      } else {
        const sequenceBCSCongToThao = Object.values(BCS_CONG_TO)
          .map((value) => {
            if (dataCongTo.congToThaoBoChiSo === value.name) {
              return value.sequence;
            } else return undefined;
          })
          .filter((item) => item !== undefined);

        if (sequenceBCSCongToThao.length > 0) {
          //default
          setSequenceThao(sequenceBCSCongToThao);
        }
      }
      //SET init value
      form.setFieldsValue({
        congToThaoBoChiSo: dataCongTo.congToThaoBoChiSo,
        congToThaoChiSoBinhThuong: dataCongTo.congToThaoChiSoBinhThuong,
        congToThaoChiSoCaoDiem: dataCongTo.congToThaoChiSoCaoDiem,
        congToThaoChiSoP: dataCongTo.congToThaoChiSoP,
        congToThaoChiSoQ: dataCongTo.congToThaoChiSoQ,
        congToThaoChiSoThapDiem: dataCongTo.congToThaoChiSoThapDiem,

        congToThaoKenhNhanBoChiSo: dataCongTo.congToThaoKenhNhanBoChiSo,
        congToThaoKenhNhanChiSoBinhThuong:
          dataCongTo.congToThaoKenhNhanChiSoBinhThuong,
        congToThaoKenhNhanChiSoCaoDiem:
          dataCongTo.congToThaoKenhNhanChiSoCaoDiem,
        congToThaoKenhNhanChiSoP: dataCongTo.congToThaoKenhNhanChiSoP,
        congToThaoKenhNhanChiSoQ: dataCongTo.congToThaoKenhNhanChiSoQ,
        congToThaoKenhNhanChiSoThapDiem:
          dataCongTo.congToThaoKenhNhanChiSoThapDiem,

        congToTreoBoChiSo: dataCongTo.congToTreoBoChiSo,
        congToTreoChiSoBinhThuong: dataCongTo.congToTreoChiSoBinhThuong,
        congToTreoChiSoCaoDiem: dataCongTo.congToTreoChiSoCaoDiem,
        congToTreoChiSoP: dataCongTo.congToTreoChiSoP,
        congToTreoChiSoQ: dataCongTo.congToTreoChiSoQ,
        congToTreoChiSoThapDiem: dataCongTo.congToTreoChiSoThapDiem,
        congToTreoKenhNhanChiSoBinhThuong:
          dataCongTo.congToTreoKenhNhanChiSoBinhThuong,
        congToTreoKenhNhanChiSoCaoDiem:
          dataCongTo.congToTreoKenhNhanChiSoCaoDiem,
        congToTreoKenhNhanChiSoP: dataCongTo.congToTreoKenhNhanChiSoP,
        congToTreoKenhNhanChiSoQ: dataCongTo.congToTreoKenhNhanChiSoQ,
        congToTreoKenhNhanChiSoThapDiem:
          dataCongTo.congToTreoKenhNhanChiSoThapDiem,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCongTo]);
  //change field form
  const handleFieldChange = () => {
    const dataConvert = {
      ...form.getFieldsValue(),
      coCongToThao: isThao,
      congToTreoBoChiSo: dataCongTo.congToTreoBoChiSo,
    };
    if (isThao) {
      dataConvert.congToThaoBoChiSo = dataCongTo.congToThaoBoChiSo;
    }
    if (
      dataConvert.congToTreoChiSoThapDiem === undefined ||
      dataConvert.congToTreoChiSoThapDiem === null
    ) {
      dataConvert.congToTreoChiSoThapDiem = "";
    }
    if (isEqual(dataCongTo, dataConvert)) {
      dispatch(notSavedCongTo(false));
      setInitCongTo(true);
    } else {
      dispatch(notSavedCongTo(true));
    }
  };

  //get init value if data not change
  useEffect(() => {
    if (initCongTo) {
      form.setFieldsValue({
        congToThaoBoChiSo: dataCongTo.congToThaoBoChiSo,
        congToThaoChiSoBinhThuong: dataCongTo.congToThaoChiSoBinhThuong,
        congToThaoChiSoCaoDiem: dataCongTo.congToThaoChiSoCaoDiem,
        congToThaoChiSoP: dataCongTo.congToThaoChiSoP,
        congToThaoChiSoQ: dataCongTo.congToThaoChiSoQ,
        congToThaoKenhNhanBoChiSo: dataCongTo.congToThaoKenhNhanBoChiSo,
        congToThaoKenhNhanChiSoBinhThuong:
          dataCongTo.congToThaoKenhNhanChiSoBinhThuong,
        congToThaoKenhNhanChiSoCaoDiem:
          dataCongTo.congToThaoKenhNhanChiSoCaoDiem,
        congToThaoKenhNhanChiSoP: dataCongTo.congToThaoKenhNhanChiSoP,
        congToThaoKenhNhanChiSoQ: dataCongTo.congToThaoKenhNhanChiSoQ,
        congToThaoKenhNhanChiSoThapDiem:
          dataCongTo.congToThaoKenhNhanChiSoThapDiem,

        congToTreoBoChiSo: dataCongTo.congToTreoBoChiSo,
        congToThaoChiSoThapDiem: dataCongTo.congToThaoChiSoThapDiem,
        congToTreoChiSoBinhThuong: dataCongTo.congToTreoChiSoBinhThuong,
        congToTreoChiSoCaoDiem: dataCongTo.congToTreoChiSoCaoDiem,
        congToTreoChiSoP: dataCongTo.congToTreoChiSoP,
        congToTreoChiSoQ: dataCongTo.congToTreoChiSoQ,
        congToTreoKenhNhanChiSoBinhThuong:
          dataCongTo.congToTreoKenhNhanChiSoBinhThuong,
        congToTreoKenhNhanChiSoCaoDiem:
          dataCongTo.congToTreoKenhNhanChiSoCaoDiem,
        congToTreoKenhNhanChiSoP: dataCongTo.congToTreoKenhNhanChiSoP,
        congToTreoKenhNhanChiSoQ: dataCongTo.congToTreoKenhNhanChiSoQ,
        congToTreoKenhNhanChiSoThapDiem:
          dataCongTo.congToTreoKenhNhanChiSoThapDiem,
      });
    }
  }, [dataCongTo, form, initCongTo, setInitCongTo]);
  return (
    <LoadingComponent loading={loadingSubmit}>
      <FormComponent
        form={form}
        autoComplete="off"
        name="thongTinCongTo"
        onFinish={handleSubmitCongTo}
        initialValues={{
          ...dataCongTo,
        }}
        scrollToFirstError
        layout="horizonal"
        onValuesChange={debounce(handleFieldChange, 200)}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        labelAlign="left"
      >
        <Row gutter={24}>
          <Col span={24} md={12}>
            <span className="fs-16px font-bold">
              Thông tin chỉ số công tơ treo
            </span>
          </Col>
          <Col span={24} md={12}>
            <span className="fs-16px ">
              Bộ chỉ số:
              <span className="font-bold"> {dataCongTo.congToTreoBoChiSo}</span>
            </span>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} md={12}>
            <h3
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                textDecorationLine: "underline",
                marginTop: "12px",
              }}
            >
              Kênh giao
            </h3>

            <CheckBSCCongTo
              sequenceCongTo={sequenceTreo}
              csCongTo={CS_CONG_TO.CS_BINHTHUONG}
              isKenhGiao={true}
            >
              <Form.Item
                name="congToTreoChiSoBinhThuong"
                label="Chỉ số lúc bình thường"
              >
                <Input
                  type="number"
                  min={0}
                  placeholder="Nhập chỉ số lúc bình thường"
                />
              </Form.Item>
            </CheckBSCCongTo>
          </Col>
          <Col span={24} md={12}>
            <h3
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                textDecorationLine: "underline",
                marginTop: "12px",
              }}
            >
              Kênh nhận
            </h3>

            <CheckBSCCongTo
              sequenceCongTo={sequenceTreo}
              csCongTo={CS_CONG_TO.CS_BINHTHUONG}
              isKenhGiao={false}
            >
              <Form.Item
                name="congToTreoKenhNhanChiSoBinhThuong"
                label="Chỉ số lúc bình thường"
              >
                <Input
                  type="number"
                  min={0}
                  placeholder="Nhập chỉ số lúc bình thường"
                />
              </Form.Item>
            </CheckBSCCongTo>
          </Col>
          <Col span={24} md={12}>
            <CheckBSCCongTo
              sequenceCongTo={sequenceTreo}
              csCongTo={CS_CONG_TO.CS_CAODIEM}
              isKenhGiao={true}
            >
              <Form.Item
                name="congToTreoChiSoCaoDiem"
                label="Chỉ số lúc cao điểm"
              >
                <Input
                  type="number"
                  min={0}
                  placeholder="Nhập chỉ số lúc cao điểm"
                />
              </Form.Item>
            </CheckBSCCongTo>
          </Col>
          <Col span={24} md={12}>
            <CheckBSCCongTo
              sequenceCongTo={sequenceTreo}
              csCongTo={CS_CONG_TO.CS_CAODIEM}
              isKenhGiao={false}
            >
              <Form.Item
                name="congToTreoKenhNhanChiSoCaoDiem"
                label="Chỉ số lúc cao điểm"
              >
                <Input
                  type="number"
                  min={0}
                  placeholder="Nhập chỉ số lúc cao điểm"
                />
              </Form.Item>
            </CheckBSCCongTo>
          </Col>
          <Col span={24} md={12}>
            <CheckBSCCongTo
              sequenceCongTo={sequenceTreo}
              csCongTo={CS_CONG_TO.CS_THAPDIEM}
              isKenhGiao={true}
            >
              <Form.Item
                name="congToTreoChiSoThapDiem"
                label="Chỉ số lúc thấp điểm"
              >
                <Input
                  type="number"
                  min={0}
                  placeholder="Nhập chỉ số lúc thấp điểm"
                />
              </Form.Item>
            </CheckBSCCongTo>
          </Col>
          <Col span={24} md={12}>
            <CheckBSCCongTo
              sequenceCongTo={sequenceTreo}
              csCongTo={CS_CONG_TO.CS_THAPDIEM}
              isKenhGiao={false}
            >
              <Form.Item
                name="congToTreoKenhNhanChiSoThapDiem"
                label="Chỉ số lúc thấp điểm"
              >
                <Input
                  type="number"
                  min={0}
                  placeholder="Nhập chỉ số lúc thấp điểm"
                />
              </Form.Item>
            </CheckBSCCongTo>
          </Col>
          <Col span={24} md={12}>
            <CheckBSCCongTo
              sequenceCongTo={sequenceTreo}
              csCongTo={CS_CONG_TO.CS_P}
              isKenhGiao={true}
            >
              <Form.Item name="congToTreoChiSoP" label="Chỉ số P">
                <Input type="number" min={0} placeholder="Nhập chỉ số P" />
              </Form.Item>
            </CheckBSCCongTo>
          </Col>
          <Col span={24} md={12}>
            <CheckBSCCongTo
              sequenceCongTo={sequenceTreo}
              csCongTo={CS_CONG_TO.CS_P}
              isKenhGiao={false}
            >
              <Form.Item name="congToTreoKenhNhanChiSoP" label="Chỉ số P">
                <Input type="number" min={0} placeholder="Nhập chỉ số P" />
              </Form.Item>
            </CheckBSCCongTo>
          </Col>
          <Col span={24} md={12}>
            <CheckBSCCongTo
              sequenceCongTo={sequenceTreo}
              csCongTo={CS_CONG_TO.CS_Q}
              isKenhGiao={true}
            >
              <Form.Item name="congToTreoChiSoQ" label="Chỉ số Q">
                <Input type="number" min={0} placeholder="Nhập chỉ số Q" />
              </Form.Item>
            </CheckBSCCongTo>
          </Col>
          <Col span={24} md={12}>
            <CheckBSCCongTo
              sequenceCongTo={sequenceTreo}
              csCongTo={CS_CONG_TO.CS_Q}
              isKenhGiao={false}
            >
              <Form.Item name="congToTreoKenhNhanChiSoQ" label="Chỉ số Q">
                <Input type="number" min={0} placeholder="Nhập chỉ số Q" />
              </Form.Item>
            </CheckBSCCongTo>
          </Col>
        </Row>
        {isThao ? (
          <Fragment>
            <Divider />

            <Row gutter={24}>
              <Col span={24} md={12}>
                <span className="fs-16px font-bold">
                  Thông tin chỉ số công tơ tháo
                </span>
              </Col>
              <Col span={24} md={12}>
                <span className="fs-16px ">
                  Bộ chỉ số:
                  <span className="font-bold">
                    {" "}
                    {dataCongTo.congToThaoBoChiSo}
                  </span>
                </span>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} md={12}>
                <h3
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    textDecorationLine: "underline",
                    marginTop: "12px",
                  }}
                >
                  Kênh giao
                </h3>

                <CheckBSCCongTo
                  sequenceCongTo={sequenceThao}
                  csCongTo={CS_CONG_TO.CS_BINHTHUONG}
                  isKenhGiao={true}
                >
                  <Form.Item
                    name="congToThaoChiSoBinhThuong"
                    label="Chỉ số lúc bình thường"
                  >
                    <Input
                      type="number"
                      min={0}
                      placeholder="Nhập chỉ số lúc bình thường"
                    />
                  </Form.Item>
                </CheckBSCCongTo>
              </Col>
              <Col span={24} md={12}>
                <h3
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    textDecorationLine: "underline",
                    marginTop: "12px",
                  }}
                >
                  Kênh nhận
                </h3>

                <CheckBSCCongTo
                  sequenceCongTo={sequenceThao}
                  csCongTo={CS_CONG_TO.CS_BINHTHUONG}
                  isKenhGiao={false}
                >
                  {" "}
                  <Form.Item
                    name="congToThaoKenhNhanChiSoBinhThuong"
                    label="Chỉ số lúc bình thường"
                  >
                    <Input
                      type="number"
                      min={0}
                      placeholder="Nhập chỉ số lúc bình thường"
                    />
                  </Form.Item>
                </CheckBSCCongTo>
              </Col>
              <Col span={24} md={12}>
                <CheckBSCCongTo
                  sequenceCongTo={sequenceThao}
                  csCongTo={CS_CONG_TO.CS_CAODIEM}
                  isKenhGiao={true}
                >
                  <Form.Item
                    name="congToThaoChiSoCaoDiem"
                    label="Chỉ số lúc cao điểm"
                  >
                    <Input
                      type="number"
                      min={0}
                      placeholder="Nhập chỉ số lúc cao điểm"
                    />
                  </Form.Item>
                </CheckBSCCongTo>
              </Col>
              <Col span={24} md={12}>
                <CheckBSCCongTo
                  sequenceCongTo={sequenceThao}
                  csCongTo={CS_CONG_TO.CS_CAODIEM}
                  isKenhGiao={false}
                >
                  <Form.Item
                    name="congToThaoKenhNhanChiSoCaoDiem"
                    label="Chỉ số lúc cao điểm"
                  >
                    <Input
                      type="number"
                      min={0}
                      placeholder="Nhập chỉ số lúc cao điểm"
                    />
                  </Form.Item>
                </CheckBSCCongTo>
              </Col>
              <Col span={24} md={12}>
                <CheckBSCCongTo
                  sequenceCongTo={sequenceThao}
                  csCongTo={CS_CONG_TO.CS_THAPDIEM}
                  isKenhGiao={true}
                >
                  <Form.Item
                    name="congToThaoChiSoThapDiem"
                    label="Chỉ số lúc thấp điểm"
                  >
                    <Input
                      type="number"
                      min={0}
                      placeholder="Nhập chỉ số lúc thấp điểm"
                    />
                  </Form.Item>
                </CheckBSCCongTo>
              </Col>
              <Col span={24} md={12}>
                <CheckBSCCongTo
                  sequenceCongTo={sequenceThao}
                  csCongTo={CS_CONG_TO.CS_THAPDIEM}
                  isKenhGiao={false}
                >
                  <Form.Item
                    name="congToThaoKenhNhanChiSoThapDiem"
                    label="Chỉ số lúc thấp điểm"
                  >
                    <Input
                      type="number"
                      min={0}
                      placeholder="Nhập chỉ số lúc thấp điểm"
                    />
                  </Form.Item>
                </CheckBSCCongTo>
              </Col>
              <Col span={24} md={12}>
                <CheckBSCCongTo
                  sequenceCongTo={sequenceThao}
                  csCongTo={CS_CONG_TO.CS_P}
                  isKenhGiao={true}
                >
                  <Form.Item name="congToThaoChiSoP" label="Chỉ số P">
                    <Input type="number" min={0} placeholder="Nhập chỉ số P" />
                  </Form.Item>
                </CheckBSCCongTo>
              </Col>
              <Col span={24} md={12}>
                <CheckBSCCongTo
                  sequenceCongTo={sequenceThao}
                  csCongTo={CS_CONG_TO.CS_P}
                  isKenhGiao={false}
                >
                  <Form.Item name="congToThaoKenhNhanChiSoP" label="Chỉ số P">
                    <Input type="number" min={0} placeholder="Nhập chỉ số P" />
                  </Form.Item>
                </CheckBSCCongTo>
              </Col>
              <Col span={24} md={12}>
                <CheckBSCCongTo
                  sequenceCongTo={sequenceThao}
                  csCongTo={CS_CONG_TO.CS_Q}
                  isKenhGiao={true}
                >
                  <Form.Item name="congToThaoChiSoQ" label="Chỉ số Q">
                    <Input type="number" min={0} placeholder="Nhập chỉ số Q" />
                  </Form.Item>
                </CheckBSCCongTo>
              </Col>
              <Col span={24} md={12}>
                <CheckBSCCongTo
                  sequenceCongTo={sequenceThao}
                  csCongTo={CS_CONG_TO.CS_Q}
                  isKenhGiao={false}
                >
                  <Form.Item name="congToThaoKenhNhanChiSoQ" label="Chỉ số Q">
                    <Input type="number" min={0} placeholder="Nhập chỉ số Q" />
                  </Form.Item>
                </CheckBSCCongTo>
              </Col>
            </Row>
          </Fragment>
        ) : null}

        <div className="ant-modal-footer text-center">
          <Button type="default" onClick={() => dispatch(closeModal())}>
            {t("button.dong_lai")}
          </Button>
          <Button
            key="submit"
            type="primary"
            className="button-primary"
            htmlType="submit"
            form="thongTinCongTo"
          >
            Cập nhật thông tin công tơ
          </Button>
        </div>
      </FormComponent>
    </LoadingComponent>
  );
}
