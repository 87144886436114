import styled from "styled-components";

export const Container = styled.div`
margin-top: 12px;
padding: 0px 10px;
`;
export const TableStyled = styled.div`
.ant-table-thead {
  .ant-table-cell {
    background: #d9d9d9;
    color: #1f1f1f;
    font-size: 13px;
  }
}
.table-component-panel .ant-collapse-content.ant-collapse-content-active {
  min-height: unset;
}
`;