import styled from "styled-components";
export const Style = styled.div`
  .dang-ky-thanh-toan {
    .dang-ky-thanh-toan-content {
      padding: 20px;
      .dang-ky-thanh-toan-content-button {
        text-align: right;
        margin-right: 10px;
      }
      .noi-dung-km {
        word-break: break-word;
      }
      .thanh-toan-khuyen-mai-tac-vu {
        .thanh-toan-khuyen-mai-tac-vu-item {
          display: flex;
          justify-content: space-around;
          align-items: center;

          button {
            outline: none;
            border: none;
            padding: 0;
            background-color: transparent;
          }
        }
      }
    }
    .dang-ky-thanh-toan-loai-khuyen-mai {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      input {
        width: 343px;
      }
      .ant-form {
        display: flex;
        gap: 10px;
      }
    }

    .dang-ky-thanh-toan-tt-khuyen-mai {
      display: flex;
      padding: 10px 30px;
      justify-content: space-between;
      .ant-select {
        flex-basis: 20%;
      }
      .ant-form {
        display: flex;
        gap: 10px;
        flex-basis: 70%;
        .ant-form-item {
          margin: 0px;
          width: 300px;
        }
      }
      .dang-ky-thanh-toan-tt-khuyen-mai-ngan-hang {
        flex: 1;
      }
    }
  }
`;
