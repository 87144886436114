import { ENUM_MODE_PHUONGANCAPDIEN } from "@utils/constants";
import PhuongAnCapDienTabs from "../../nhan-vien-khao-sat/lap-phuong-an/tabs/tab-phuong-an-cap-dien";
import { Fragment, memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "@state/system-config/reducer";
import { ContainerBody } from "./css-styled";

function XemLaiPhuongAnCapDien({
  detail,
  dataPAXemLai,
  handleCloseModal,
  visible,
}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (visible) {
      dispatch(
        openModal({
          content: (
            <ContainerBody>
              <PhuongAnCapDienTabs
                detailLapPhuongAn={detail}
                mode={ENUM_MODE_PHUONGANCAPDIEN.XEMLAI}
                dataPAXemLai={dataPAXemLai}
                indexTab={"4"}
                setLoading={setLoading}
                loading={loading}
              />
            </ContainerBody>
          ),
          size: "large",
          title: "Xem lại phương án cấp điện",
        })
      );
      handleCloseModal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  return <Fragment />;
}

export default memo(XemLaiPhuongAnCapDien);
