import styled from "styled-components";

export const Style = styled.div`
  .them-moi-thong-tin {
    .thanh-toan-khuyen-mai-tac-vu {
    }
    padding: 10px;
    .ant-form {
      position: relative;
      .ant-radio-wrapper:first-child {
        position: absolute;
        top: 110px;
        z-index: 100;
      }
      .ant-radio-wrapper:last-child {
        position: absolute;
        top: 110px;
        left: 25.5%;
        z-index: 100;
      }
      .ant-image {
        width: 200px;
        height: 200px;
        margin-right: 5px;
        .ant-image-img {
          height: 100%;
        }
      }
    }
    .them-moi-thong-tin-viDienTu {
      .ant-form-item-label {
        margin-left: 20px;
      }
    }
  }
`;
