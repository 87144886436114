import {
  MODULE_TREOTHAOTHIETBI,
  // NAVIGATE Treo Thao
  NAVIGATE_TREOTHAO,
  NAVIGATE_TREOTHAO_PHONGKD,
  NAVIGATE_TREOTHAO_TIEPNHANYC,
  NAVIGATE_TREOTHAO_NGHIEMTHUYC,
  //Navigation DT
  NAVIGATE_TREOTHAO_DOITRUONG,
  NAVIGATE_TREOTHAO_PHIEUDAXUATVT,
  NAVIGATE_TREOTHAO_PHIEUCHOTRAVT,
  NAVIGATE_TREOTHAO_YCCANTRAVT,
  NAVIGATE_TREOTHAO_YCDAXUATVT,
  NAVIGATE_TREOTHAO_YCCANXUATVT,
  NAVIGATE_TREOTHAO_DTKYDUYETYC,
  //NAVIGATION  NV - Treo tháo
  NAVIGATE_TREOTHAO_NVTREOTHAO,
  NAVIGATE_TREOTHAO_YCDUOCPHANCONG,
  NAVIGATE_TREOTHAO_YCDANGTREOTHAO,
  NAVIGATE_TREOTHAO_YCKHCHUAKYSO,
  NAVIGATE_TREOTHAO_NVKYDUYETYC,
  //NAVIGATION THI CÔNG
  NAVIGATE_TREOTHAO_THICONG,
  NAVIGATE_TREOTHAO_KETQUATC,
  NAVIGATE_TREOTHAO_TRONGAI,
  NAVIGATE_TREOTHAO_KHOIPHUCTC,
  //NAVIGATION NV-antoan
  NAVIGATE_TREOTHAO_NVANTOAN,
  NAVIGATE_TREOTHAO_XACNHANANTOAN,
  //NAVIGATION Phòng VT
  NAVIGATE_TREOTHAO_DUYETPHIEUVT,
  NAVIGATE_TREOTHAO_PHONGVATTU,
  //NAVIGATION Lãnh Đạo
  NAVIGATE_TREOTHAO_LANHDAO,
  NAVIGATE_TREOTHAO_LDKYDUYETYC,
  NAVIGATE_TREOTHAO_LDDUYETPHIEUVT,
  //NAVIGATION Báo Cáo
  NAVIGATE_TREOTHAO_BAOCAO,
  //NAVIGATION Tìm kiếm
  NAVIGATE_TREOTHAO_TIMKIEMTRACUU,
  NAVIGATE_TREOTHAO_TRACUU,
  NAVIGATE_TREOTHAO_TRACUUVT,
  TITLE_TREOTHAO_DUYETPHIEUVT,
  //TITLE Treo Thao - Phòng Kinh Doanh
  TITLE_TREOTHAO_PHONGKD,
  TITLE_TREOTHAO_TIEPNHANYC,
  TITLE_TREOTHAO_NGHIEMTHUYC,
  //Treo Thao - Nhân Viên Treo Tháo
  TITLE_TREOTHAO_NVTREOTHAO,
  TITLE_TREOTHAO_YCDUOCPHANCONG,
  TITLE_TREOTHAO_YCDANGTREOTHAO,
  TITLE_TREOTHAO_YCKHCHUAKYSO,
  TITLE_TREOTHAO_NVKYDUYETYC,
  //Treo Thao - TITLE Đội Trưởng
  TITLE_TREOTHAO_DOITRUONG,
  // TITLE_TREOTHAO_PHIEUCHOXUATVT,
  TITLE_TREOTHAO_PHIEUDAXUATVT,
  TITLE_TREOTHAO_YCCANTRAVT,
  TITLE_TREOTHAO_PHIEUCHOTRAVT,
  TITLE_TREOTHAO_YCDAXUATVT,
  TITLE_TREOTHAO_YCCANXUATVT,
  TITLE_TREOTHAO_DTKYDUYETYC,
  //Treo Thao - Title Nhân Viên An Toàn
  TITLE_TREOTHAO_NVANTOAN,
  TITLE_TREOTHAO_XACNHANANTOAN,
  //Treo Thao Title Thi Công
  TITLE_TREOTHAO_THICONG,
  TITLE_TREOTHAO_KETQUATC,
  TITLE_TREOTHAO_TRONGAI,
  TITLE_TREOTHAO_KHOIPHUCTC,
  //Treo Thao - Title Phòng Vật Tư
  TITLE_TREOTHAO_PHONGVATTU,
  TITLE_TREOTHAO_DUYETPHIEUTRAVT,
  // Treo Thao - Title Lãnh Đạo
  TITLE_TREOTHAO_LANHDAO,
  TITLE_TREOTHAO_LDKYDUYETYC,
  TITLE_TREOTHAO_LDDUYETPHIEUVT,
  //Treo Thao - Title Báo Cáo
  TITLE_TREOTHAO_BAOCAO,
  //Treo Thao - Title Tìm Kiếm Tra Cứu
  TITLE_TREOTHAO_TIMKIEMTRACUU,
  TITLE_TREOTHAO_TRACUU,
  TITLE_TREOTHAO_TRACUUVT,
} from "@utils/constants";
import TrangChu from "@modules/treo-thao";
import TiepNhanYeuCau from "@modules/treo-thao/nhan-vien-kinh-doanh/tiep-nhan-yeu-cau";
import NghiemThuYeuCau from "@modules/treo-thao/nhan-vien-kinh-doanh/nghiem-thu-yeu-cau";
import YeuCauCanXuatVT from "@modules/treo-thao/doi-truong/yc-can-xuat-vat-tu";
import PKDDuyetPhieuVatTu from "@modules/treo-thao/nhan-vien-kinh-doanh/duyet-phieu-vt";
import DTKyDuyetYeuCau from "@modules/treo-thao/doi-truong/ky-duyet";
import DTDuyetPhieuVT from "@modules/treo-thao/doi-truong/duyet-phieu";
import PhieuDaXuatVT from "@modules/treo-thao/doi-truong/phieu-xuat-vat-tu";
import YeuCauCanTraVT from "@modules/treo-thao/doi-truong/yc-can-tra-vat-tu";
import YeuCauDaXuatVT from "@modules/treo-thao/doi-truong/yc-da-xuat-vat-tu";
import PhieuChoTraVT from "@modules/treo-thao/doi-truong/phieu-tra-vat-tu";
import YCDuocPhanCong from "@modules/treo-thao/nhan-vien-treo-thao/yeu-cau-duoc-phan-cong";
import YCDangTreoThao from "@modules/treo-thao/nhan-vien-treo-thao/yeu-cau-dang-treo-thao";
import YCKHChuaKySo from "@modules/treo-thao/nhan-vien-treo-thao/yc-kh-chua-ky-so";
import NVKyDuyetYC from "@modules/treo-thao/nhan-vien-treo-thao/ky-duyet";
import XacNhanAnToan from "@modules/treo-thao/nhan-vien-an-toan/xac-nhan-an-toan";
import DuyetPhieuXuatVT from "@modules/treo-thao/phong-vat-tu/duyet-phieu-vat-tu";
import LDKyDuyetYC from "@modules/treo-thao/lanh-dao/ky-duyet";
import LDDuyetPhieuVT from "@modules/treo-thao/lanh-dao/duyet-phieu";
import BaoCaoHanKTCTTable from "@modules/treo-thao/bao-cao/han-kiem-dinh-ct";
import TraCuuYC from "@modules/treo-thao/tim-kiem-tra-cuu/tra-cuu-yeu-cau";
import TraCuuPhieuVT from "@modules/treo-thao/tim-kiem-tra-cuu/tra-cuu-phieu-vat-tu";
import KetQuaTC from "@modules/treo-thao/thi-cong/ket-qua-thi-cong";
import TroNgaiTC from "@modules/treo-thao/thi-cong/tro-ngai-thi-cong";
import KhoiPhucTC from "@modules/treo-thao/thi-cong/khoi-phuc-thi-cong";
import { treo_thao } from "@permissions/b_treothao";
import CauHinhGuiMail from "@modules/treo-thao/cau-hinh/gui-mail";
import XacNhanXuatVT from "@modules/treo-thao/phong-vat-tu/xac-nhan-xuat-vat-tu";
import BaoCaoCongToBiMat from "@modules/treo-thao/bao-cao/cong-to-bi-mat";
const routes = [
  {
    title: "",
    path: NAVIGATE_TREOTHAO,
    component: TrangChu,
    privateRoute: true,
    subItems: [],
    permissionCode: MODULE_TREOTHAOTHIETBI,
  },
  {
    title: TITLE_TREOTHAO_PHONGKD,
    path: NAVIGATE_TREOTHAO + NAVIGATE_TREOTHAO_PHONGKD,
    privateRoute: true,
    permissionCode: treo_thao.b1,
    subItems: [
      {
        subTitle: TITLE_TREOTHAO_TIEPNHANYC,
        subPath: NAVIGATE_TREOTHAO_TIEPNHANYC,
        subComponent: TiepNhanYeuCau,
        permissionCode: treo_thao.b11,
      },
      {
        subTitle: TITLE_TREOTHAO_NGHIEMTHUYC,
        subPath: NAVIGATE_TREOTHAO_NGHIEMTHUYC,
        subComponent: NghiemThuYeuCau,
        permissionCode: treo_thao.b12,
      },
      {
        subTitle: "Duyệt phiếu vật tư",
        subPath: "/duyet-phieu-vat-tu",
        subComponent: PKDDuyetPhieuVatTu,
        permissionCode: treo_thao.b13,
      },
    ],
  },
  {
    title: TITLE_TREOTHAO_DOITRUONG,
    path: NAVIGATE_TREOTHAO + NAVIGATE_TREOTHAO_DOITRUONG,
    privateRoute: true,
    permissionCode: treo_thao.b2,
    subItems: [
      {
        subTitle: TITLE_TREOTHAO_YCCANXUATVT,
        subPath: NAVIGATE_TREOTHAO_YCCANXUATVT,
        subComponent: YeuCauCanXuatVT,
        permissionCode: treo_thao.b21,
      },
      {
        subTitle: TITLE_TREOTHAO_YCCANTRAVT,
        subPath: NAVIGATE_TREOTHAO_YCCANTRAVT,
        subComponent: YeuCauCanTraVT,
        permissionCode: treo_thao.b22,
      },
      {
        subTitle: TITLE_TREOTHAO_YCDAXUATVT,
        subPath: NAVIGATE_TREOTHAO_YCDAXUATVT,
        subComponent: YeuCauDaXuatVT,
        permissionCode: treo_thao.b23,
      },
      {
        subTitle: TITLE_TREOTHAO_DTKYDUYETYC,
        subPath: NAVIGATE_TREOTHAO_DTKYDUYETYC,
        subComponent: DTKyDuyetYeuCau,
        permissionCode: treo_thao.b24,
      },
      {
        subTitle: TITLE_TREOTHAO_PHIEUDAXUATVT,
        subPath: NAVIGATE_TREOTHAO_PHIEUDAXUATVT,
        subComponent: PhieuDaXuatVT,
        permissionCode: treo_thao.b25,
      },
      {
        subTitle: TITLE_TREOTHAO_PHIEUCHOTRAVT,
        subPath: NAVIGATE_TREOTHAO_PHIEUCHOTRAVT,
        subComponent: PhieuChoTraVT,
        permissionCode: treo_thao.b26,
      },
      {
        subTitle: TITLE_TREOTHAO_DUYETPHIEUTRAVT,
        subPath: NAVIGATE_TREOTHAO_DUYETPHIEUVT,
        subComponent: DTDuyetPhieuVT,
        permissionCode: treo_thao.b27,
      },
    ],
  },
  {
    title: TITLE_TREOTHAO_NVTREOTHAO,
    path: NAVIGATE_TREOTHAO + NAVIGATE_TREOTHAO_NVTREOTHAO,
    privateRoute: true,
    permissionCode: treo_thao.b3,
    subItems: [
      {
        subTitle: TITLE_TREOTHAO_YCDANGTREOTHAO,
        subPath: NAVIGATE_TREOTHAO_YCDANGTREOTHAO,
        subComponent: YCDangTreoThao,
        permissionCode: treo_thao.b31,
      },
      {
        subTitle: TITLE_TREOTHAO_YCDUOCPHANCONG,
        subPath: NAVIGATE_TREOTHAO_YCDUOCPHANCONG,
        subComponent: YCDuocPhanCong,
        permissionCode: treo_thao.b32,
      },
      {
        subTitle: TITLE_TREOTHAO_YCKHCHUAKYSO,
        subPath: NAVIGATE_TREOTHAO_YCKHCHUAKYSO,
        subComponent: YCKHChuaKySo,
        permissionCode: treo_thao.b33,
      },
      {
        subTitle: TITLE_TREOTHAO_NVKYDUYETYC,
        subPath: NAVIGATE_TREOTHAO_NVKYDUYETYC,
        subComponent: NVKyDuyetYC,
        permissionCode: treo_thao.b34,
      },
    ],
  },
  {
    title: TITLE_TREOTHAO_THICONG,
    path: NAVIGATE_TREOTHAO + NAVIGATE_TREOTHAO_THICONG,
    privateRoute: true,
    permissionCode: treo_thao.b4,
    subItems: [
      {
        subTitle: TITLE_TREOTHAO_KETQUATC,
        subPath: NAVIGATE_TREOTHAO_KETQUATC,
        subComponent: KetQuaTC,
        permissionCode: treo_thao.b41,
      },
      {
        subTitle: TITLE_TREOTHAO_TRONGAI,
        subPath: NAVIGATE_TREOTHAO_TRONGAI,
        subComponent: TroNgaiTC,
        permissionCode: treo_thao.b42,
      },
      {
        subTitle: TITLE_TREOTHAO_KHOIPHUCTC,
        subPath: NAVIGATE_TREOTHAO_KHOIPHUCTC,
        subComponent: KhoiPhucTC,
        permissionCode: treo_thao.b43,
      },
    ],
  },
  {
    title: TITLE_TREOTHAO_NVANTOAN,
    path: NAVIGATE_TREOTHAO + NAVIGATE_TREOTHAO_NVANTOAN,
    privateRoute: true,
    permissionCode: treo_thao.b5,
    subItems: [
      {
        subTitle: TITLE_TREOTHAO_XACNHANANTOAN,
        subPath: NAVIGATE_TREOTHAO_XACNHANANTOAN,
        subComponent: XacNhanAnToan,
        permissionCode: treo_thao.b51,
      },
    ],
  },
  {
    title: TITLE_TREOTHAO_PHONGVATTU,
    path: NAVIGATE_TREOTHAO + NAVIGATE_TREOTHAO_PHONGVATTU,
    privateRoute: true,
    permissionCode: treo_thao.b6,
    subItems: [
      {
        subTitle: TITLE_TREOTHAO_DUYETPHIEUVT,
        subPath: NAVIGATE_TREOTHAO_DUYETPHIEUVT,
        subComponent: DuyetPhieuXuatVT,
        permissionCode: treo_thao.b61,
      },
      {
        subTitle: "Xác nhận xuất vật tư",
        subPath: "/xac-nhan-xuat-vt",
        subComponent: XacNhanXuatVT,
        permissionCode: treo_thao.b62,
      },
    ],
  },
  {
    title: TITLE_TREOTHAO_LANHDAO,
    path: NAVIGATE_TREOTHAO + NAVIGATE_TREOTHAO_LANHDAO,
    privateRoute: true,
    permissionCode: treo_thao.b7,
    subItems: [
      {
        subTitle: TITLE_TREOTHAO_LDKYDUYETYC,
        subPath: NAVIGATE_TREOTHAO_LDKYDUYETYC,
        subComponent: LDKyDuyetYC,
        permissionCode: treo_thao.b71,
      },
      {
        subTitle: TITLE_TREOTHAO_LDDUYETPHIEUVT,
        subPath: NAVIGATE_TREOTHAO_LDDUYETPHIEUVT,
        subComponent: LDDuyetPhieuVT,
        permissionCode: treo_thao.b72,
      },
    ],
  },
  {
    title: TITLE_TREOTHAO_BAOCAO,
    path: NAVIGATE_TREOTHAO + NAVIGATE_TREOTHAO_BAOCAO,
    privateRoute: true,
    // component: BaoCao,
    permissionCode: treo_thao.b8,
    subItems: [
      {
        subTitle: "Báo cáo hạn kiểm định công tơ",
        subPath: "/kiem-dinh-cong-to",
        subComponent: BaoCaoHanKTCTTable,
        permissionCode: treo_thao.b81,
      },
      {
        subTitle: "Danh sách công tơ bị mất",
        subPath: "/cong-to-bi-mat",
        subComponent: BaoCaoCongToBiMat,
        permissionCode: treo_thao.b82,
      },
    ],
  },
  {
    title: TITLE_TREOTHAO_TIMKIEMTRACUU,
    path: NAVIGATE_TREOTHAO + NAVIGATE_TREOTHAO_TIMKIEMTRACUU,
    privateRoute: true,
    permissionCode: treo_thao.b9,
    subItems: [
      {
        subTitle: TITLE_TREOTHAO_TRACUU,
        subPath: NAVIGATE_TREOTHAO_TRACUU,
        subComponent: TraCuuYC,
        permissionCode: treo_thao.b91,
      },
      {
        subTitle: TITLE_TREOTHAO_TRACUUVT,
        subPath: NAVIGATE_TREOTHAO_TRACUUVT,
        subComponent: TraCuuPhieuVT,
        permissionCode: treo_thao.b92,
      },
    ],
  },
  {
    title: "Cấu hình gửi mail",
    path: NAVIGATE_TREOTHAO + "/cau-hinh-gui-mail",
    privateRoute: true,
    component: CauHinhGuiMail,
    permissionCode: treo_thao.b10,
    subItems: [],
  },
];

export default routes;
