import { useCallback, useEffect, useLayoutEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  TABINDEX_CMIS,
} from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";

import { useLocation, useSearchParams } from "react-router-dom";
import { handleListData } from "./services";
import TramTable from "./table";
import { Button } from "antd";
import { Endpoint } from "@utils/endpoint";
import { dongBoCMIS } from "../services";
import CheckPermission from "@components/CheckPermission";
import { tien_ich_chung } from "@permissions/m_tienichchung";

export default function Index() {
  const { t } = useTranslation();

  const [dataTram, setDataTram] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu

  const getListTram = useCallback(() => {
    handleListData(filterConditions, setLoading, setDataTram, setTotalCount);
  }, [filterConditions]);

  useEffect(() => {
    getListTram();
    // eslint-disable-next-line
  }, [getListTram]);

  //thay đổi pagination table

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const genExtra = () => (
    <CheckPermission permissionCode={tien_ich_chung.m15cmis}>
      <Button
        size="small"
        type="primary"
        className="btn-bg-yellow"
        onClick={handleDongBoCMIS}
        loading={loading}
      >
        Đồng bộ CMIS
      </Button>
    </CheckPermission>
  );

  const handleDongBoCMIS = useCallback(() => {
    const url = Endpoint.DONG_BO_CMIS + `?tabIndex=${TABINDEX_CMIS.TRAM}`;
    dongBoCMIS(url, setLoading, getListTram);
  }, [getListTram]);

  //khai bái columns

  const columns = [
    {
      title: t("tram.ma_tram"),
      dataIndex: "maTram",
      key: "maTram",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: t("tram.ten_tram"),
      dataIndex: "tenTram",
      key: "tenTram",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "25%",
      sorter: true,
    },
    {
      title: t("tram.loai_tram"),
      dataIndex: "loaiTram",
      key: "loaiTram",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
      sorter: true,
    },
    {
      title: t("tram.cong_suat"),
      dataIndex: "congSuat",
      key: "congSuat",
      sorter: true,
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Mã cấp điện áp",
      dataIndex: "maCapDienAp",
      key: "maCapDienAp",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
    },
    {
      title: "Mã cấp điện áp ra",
      dataIndex: "maCapDienApRa",
      key: "maCapDienApRa",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
      sorter: true,
    },
    {
      title: t("tram.dinh_danh"),
      dataIndex: "dinhDanh",
      key: "dinhDanh",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      // width: "15%",
      sorter: true,
    },
  ];

  //tìm kiếm dữ liệu

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  return (
    <TramTable
      handleSearch={handleSearch}
      clearFilter={clearFilter}
      filterConditions={filterConditions}
      genExtra={genExtra}
      dataTram={dataTram}
      totalCount={totalCount}
      loading={loading}
      onChangePagination={onChangePagination}
      columns={columns}
    />
  );
}
