import { buildQueryString, parseParams } from "@utils/function";
import { ENUM_API, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authGetData, downloadFile } from "@utils/request";

export const handleListData = (
  typeAPI,
  location,
  filterConditions,
  setLoading,
  setDataHoSo,
  setTotalCount,
  hasMessage
) => {
  let url = "";
  if (typeAPI === ENUM_API.DONGBOTHOIGIAN) {
    url = `${Endpoint.LIST_TIEP_NHAN_YEU_CAU}?${buildQueryString(
      Object.keys(parseParams(location.search)).length > 0
        ? parseParams(location.search)
        : filterConditions
    )}`;
  } else {
    url = `${Endpoint.LIST_HO_SO}?${buildQueryString(
      Object.keys(parseParams(location.search)).length > 0
        ? parseParams(location.search)
        : filterConditions
    )}`;
  }
  url = hasMessage ? `${url}&HasMessage=${hasMessage}` : url;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataHoSo(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataHoSo([]);
    },
  });
};

export function inBienBan(detail, setLoading, onComplete) {
  authGetData({
    url: Endpoint.IN_BIEN_BAN_AP_GIA + "?hoSoId=" + detail.id,
    setLoading: setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        onComplete(res.data);
      } else {
        onComplete("error");
      }
    },
  });
}

export function inBieuDoPhuTai(detail, setLoading, onComplete) {
  authGetData({
    url: Endpoint.IN_BIEU_DO_PHU_TAI_CAP_DIEN + "?hoSoId=" + detail.id,
    setLoading: setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        if (Number(res.data.congSuatLapDat) < 40) {
          onComplete("error-1");
        } else {
          onComplete(res.data);
        }
      } else {
        onComplete("error");
      }
    },
  });
}

export function downloadExcelBieuDoPT(detail, setLoading) {
  downloadFile({
    endpoint: `${Endpoint.KS_EXPORT_EXCEL_BIEU_DO_PT}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    setLoading,
  });
}
