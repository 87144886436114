// import { TYPE_XUAT_TRA } from "@utils/constants";
import { Fragment, useState } from "react";
// import { useDispatch } from "react-redux";
import PhieuXuatVTTable from "../../common-ver1/phieu-vat-tu";
// import { openModal } from "@state/system-config/reducer";
// import TaoMoiPhieuXuatVT from "../../common-ver1/tao-thay-doi-tra-duyet-phieu-xuat";
import {
  ENUM_PHIEU_VAT_TU,
  NAVIGATE_TREOTHAO,
  NAVIGATE_TREOTHAO_DOITRUONG,
  NAVIGATE_TREOTHAO_YCCANXUATVT,
  TYPE_PHIEUXUAT_CHUCNANG,
} from "@utils/constants";
import { treo_thao } from "@permissions/b_treothao";
import { Endpoint } from "@utils/endpoint";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "@state/system-config/reducer";
import { authSelector } from "@state/auth";
import TaoSuaPhieuXuat from "../../common-ver1/thao-tac-phieu-xuat/tao-sua-phieu";
import CheckPermission from "@components/CheckPermission";
import { alertMessage } from "@utils/function";

export default function Index() {
  const localFilter = localStorage.getItem("createFilterPhieu");
  const [fetchList, setFetchList] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filterRedirect, setFilterRedirect] = useState({});
  const { user } = useSelector(authSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //Hiển thị checkbox ở đầu mỗi bảng
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  //redirect tới tạo phiếu
  const handleClickRedirect = (permissionCode) => {
    if (
      permissionCode &&
      user &&
      user.permission &&
      user.permission.length &&
      !user.permission.find((value) => value.includes(permissionCode))
    ) {
      alertMessage(
        "error",
        "Thông báo!",
        "Bạn không có quyền tạo phiếu xuất vật tư!"
      );
    } else {
      localStorage.setItem("createFilterPhieu", JSON.stringify(filterRedirect));
      navigate(
        NAVIGATE_TREOTHAO +
          NAVIGATE_TREOTHAO_DOITRUONG +
          NAVIGATE_TREOTHAO_YCCANXUATVT
      );
    }
  };

  //show button tạo
  const renderButtonTao = () => (
    <CheckPermission permissionCode={treo_thao.b25t}>
      <Button
        type="primary"
        className="mr-5px"
        id="create-phieu"
        onClick={() => handleClickRedirect(treo_thao.b21t)}
      >
        Tạo phiếu
      </Button>
    </CheckPermission>
  );

  //show button sửa
  const renderPopupSuaPhieu = (row) => {
    return (
      <CheckPermission permissionCode={treo_thao.b25s}>
        {dispatch(
          openModal({
            content: (
              <TaoSuaPhieuXuat
                data={[row]}
                type={TYPE_PHIEUXUAT_CHUCNANG.THAYDOI}
                selectRowKeys={[row.id]}
                typeLoaiPhieu={row.loaiPhieuNumber}
                typeTreoThao={row.loaiTreoThao}
                dispatch={dispatch}
                nguoiTao={user.name}
                doiId={row.doiId}
                setFetchList={setFetchList}
              />
            ),
            size: "large",
            title: "Sửa phiếu xuất vật tư",
          })
        )}
      </CheckPermission>
    );
  };

  return (
    <Fragment>
      <PhieuXuatVTTable
        url={Endpoint.LIST_PHIEU_VAT_TU}
        funcId={ENUM_PHIEU_VAT_TU.DTPHIEUXUATVT}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={false}
        rowSelection={rowSelection}
        selectedRowKeys={selectedRowKeys}
        permissionCodeSua={treo_thao.b25s}
        permissionCodeXoa={treo_thao.b25x}
        renderButtonTao={renderButtonTao}
        typeSua={TYPE_PHIEUXUAT_CHUCNANG.THAYDOI}
        typeDuyet={TYPE_PHIEUXUAT_CHUCNANG.TRALAI_DUYET}
        permissionCodeXemPhieu={treo_thao.b25v}
        urlRemove={Endpoint.REMOVE_PHIEU_XUAT_VAT_TU}
        setFilterRedirect={setFilterRedirect}
        renderPopupSuaPhieu={renderPopupSuaPhieu}
        setSelectedRowKeys={setSelectedRowKeys}
        localFilter={localFilter ? JSON.parse(localFilter) : null}
      />
    </Fragment>
  );
}
