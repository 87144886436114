import {
  Select,
  Form,
  Col,
  Input,
  Row,
  DatePicker,
  Tooltip,
  Checkbox,
} from "antd";
import { FORMAT_DATE } from "@utils/constants";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import moment from "moment";
import { TextBottom } from "../../../css-styled";
import { debounce } from "lodash";

const { Option } = Select;

export default function ThongTinCoBanTabs(props) {
  const {
    t,
    loadingSubmit,
    form,
    dataDetail,
    ngay,
    handleSubmit,
    handleFieldChange,
    handleChangeCoQuan,
    defaultValueTenCoQuan,
    dataGioiTinhKH,
    handleChangeNguoiDaiDien,
    defaultValueTenNguoiDaiDien,
    handlChangeNgay,
    handleChangeDiaChinhGiaoDich,
    dataDiaChinh,
    dataXaPhuongGiaoDich,
    handleChangeDiaChinhSuDung,
    dataXaPhuongSuDung,
    displayNangCongSuat,
    dataSoPha,
    dataTinhTrangSuDung,
    dataHinhThucGhiChiSo,
    dataLoaiHopDong,
    handleChangeHinhThucTraTien,
    dataHinhThucTraTien,
    displayHinhThucKhac,
    displayLanhDao,
    dataLanhDao,
    dataTinhTrangThu,
    dataMucDich,
    handleChangeDatCoc,
    isDatCoc,
    handleChangeInput,
  } = props;
  const innerWidth = window.innerWidth;
  return (
    <LoadingComponent loading={loadingSubmit}>
      <FormComponent
        autoComplete="off"
        form={form}
        name="form-lap-phuong-an-info"
        layout="vertical"
        initialValues={{
          ...dataDetail,
          ngayCap: moment(ngay, FORMAT_DATE),
        }}
        onFinish={handleSubmit}
        onValuesChange={debounce(handleFieldChange, 200)}
        scrollToFirstError
      >
        <Row gutter={24}>
          <Col span={24} md={6}>
            <Form.Item
              label={t("cap_nhat_ho_so.ten_khach_hang")}
              name="gioiTinhKH"
              className="required-field"
            >
              <Select
                onChange={handleChangeCoQuan}
                defaultValue={defaultValueTenCoQuan}
              >
                {dataGioiTinhKH && dataGioiTinhKH.length > 0
                  ? dataGioiTinhKH.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item label={" "} name="tenKH" className="required-field">
              <Input placeholder={t("cap_nhat_ho_so.ten_khach_hang")} />
            </Form.Item>
          </Col>
          {/* <Col span={24} md={6}></Col> */}
        </Row>
        <Row
          gutter={24}
          // style={{ display: displayNguoiDaiDien ? "none" : "flex" }}
        >
          <Col span={24} md={6}>
            <Form.Item
              label={t("cap_nhat_ho_so.nguoi_dai_dien")}
              name="gioiTinhNDD"
              className="required-field"
            >
              <Select
                onChange={handleChangeNguoiDaiDien}
                defaultValue={defaultValueTenNguoiDaiDien}
              >
                {dataGioiTinhKH && dataGioiTinhKH.length > 0
                  ? dataGioiTinhKH
                      .filter((data) => !data.name.includes("Cơ quan"))
                      .map((item, index) => (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item label={" "} name="tenNDD" className="required-field">
              <Input placeholder={t("cap_nhat_ho_so.ten_nguoi_dai_dien")} />
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item label={t("cap_nhat_ho_so.chuc_vu")} name="chucVu">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24} md={6}>
            <Form.Item
              label={t("cap_nhat_ho_so.chung_minh_thu_ho_chieu")}
              name="cmt"
            >
              <Input onChange={handleChangeInput} />
              {/* <InputNumber type="number" controls={false} /> */}
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item label={t("cap_nhat_ho_so.ngay_cap")} name="ngayCap">
              <DatePicker
                locale={locale}
                format={FORMAT_DATE}
                onChange={handlChangeNgay}
                placeholder={FORMAT_DATE}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item label={t("cap_nhat_ho_so.noi_cap")} name="noiCap">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <TextBottom span={24} md={24}>
            {t("cap_nhat_ho_so.dia_chi_giao_dich")}
          </TextBottom>
        </Row>
        <Row gutter={24}>
          <Col span={24} md={6}>
            <Form.Item
              label={t("cap_nhat_ho_so.quan_huyen")}
              name="quanHuyenGiaoDichId"
            >
              <Select
                onChange={handleChangeDiaChinhGiaoDich}
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="Chọn quận huyện"
                defaultValue={
                  dataDetail && dataDetail.quanHuyenGiaoDichId
                    ? dataDetail.quanHuyenGiaoDichId
                    : null
                }
              >
                <Option value={null}>--Chọn--</Option>
                {dataDiaChinh && dataDiaChinh.length > 0
                  ? dataDiaChinh.map((element, index) => (
                      <Option value={element.id} key={index}>
                        {element.tenDiaChinh}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item
              label={t("cap_nhat_ho_so.phuong_xa")}
              name="phuongXaGiaoDichId"
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                defaultValue={
                  dataDetail && dataDetail.phuongXaGiaoDichId
                    ? dataDetail.phuongXaGiaoDichId
                    : null
                }
              >
                <Option value={null}>--Chọn--</Option>
                {dataXaPhuongGiaoDich && dataXaPhuongGiaoDich.length > 0
                  ? dataXaPhuongGiaoDich.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.tenDiaChinh}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label={t("cap_nhat_ho_so.dia_chi")}
              name="diaChiGiaoDich"
              className="required-field"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <TextBottom span={24} md={24}>
            {t("cap_nhat_ho_so.dia_chi_su_dung_dien")}
          </TextBottom>
        </Row>

        <Row gutter={24}>
          <Col span={24} md={6}>
            <Form.Item
              label={t("cap_nhat_ho_so.quan_huyen")}
              name="quanHuyenSuDungId"
              className="required-field"
            >
              <Select
                onChange={handleChangeDiaChinhSuDung}
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="Chọn quận huyện"
              >
                <Option value={null}>--Chọn--</Option>
                {dataDiaChinh && dataDiaChinh.length > 0
                  ? dataDiaChinh.map((element, index) => (
                      <Option value={element.id} key={index}>
                        {element.tenDiaChinh}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item
              name="phuongXaSuDungId"
              label={t("cap_nhat_ho_so.phuong_xa")}
              className="required-field"
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option>--Chọn--</Option>
                {dataXaPhuongSuDung && dataXaPhuongSuDung.length > 0
                  ? dataXaPhuongSuDung.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.tenDiaChinh}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label={t("cap_nhat_ho_so.dia_chi")}
              name="diaChiSuDung"
              className="required-field"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24} md={6}>
            <Form.Item
              label={t("cap_nhat_ho_so.so_dien_thoai_di_dong")}
              name="sdt"
            >
              <Input />
              {/* <InputNumber type="number" controls={false} /> */}
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item
              label={t("cap_nhat_ho_so.so_dien_thoai_khac")}
              name="sdtKhac"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item label={t("cap_nhat_ho_so.email")} name="email">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24} md={6}></Col>
        </Row>

        {displayNangCongSuat ? null : (
          <Row
            gutter={24}
            style={{ display: displayNangCongSuat ? "none" : "flex" }}
          >
            <Col span={24} md={6}>
              <Form.Item
                label={
                  innerWidth < 1600 ? (
                    <Tooltip
                      title={t(
                        "cap_nhat_ho_so.cong_suat_su_dung_dien_lon_nhat"
                      )}
                    >
                      {t("cap_nhat_ho_so.cong_suat_su_dung_dien_lon_nhat")}
                    </Tooltip>
                  ) : (
                    t("cap_nhat_ho_so.cong_suat_su_dung_dien_lon_nhat")
                  )
                }
                name="congSuatSuDung"
                className="text-ellipsis"
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={24} md={6}>
              <Form.Item label={t("cap_nhat_ho_so.so_pha_cu")} name="soPhaCu">
                <Select placeholder="--Chọn--">
                  {dataSoPha && dataSoPha.length > 0
                    ? dataSoPha.map((item, index) => (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={6}>
              <Form.Item
                label={t("cap_nhat_ho_so.muc_dich_su_dung_cu")}
                name="mucDichSuDungCu"
              >
                <Select>
                  {dataMucDich && dataMucDich.length > 0
                    ? dataMucDich.map((item, index) => (
                        <Option
                          key={index}
                          value={
                            item.value !== null
                              ? item.value.toString()
                              : item.value
                          }
                        >
                          {item.name}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={6}>
              <Form.Item
                label={t("cap_nhat_ho_so.cuong_do_cu")}
                name="cuongDoCu"
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={24} md={6}>
              <Form.Item
                label={t("cap_nhat_ho_so.hieu_dien_the_cu")}
                name="hieuDienTheCu"
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={24} md={6}>
              <Form.Item label={t("cap_nhat_ho_so.ti_cu")} name="tiCu">
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t("cap_nhat_ho_so.ly_do_nang_cong_suat")}
                name="lyDoNangCongSuat"
                className="required-field"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={24}>
          <Col span={24} md={6}>
            <Form.Item
              label={t("cap_nhat_ho_so.so_pha")}
              name="soPha"
              className="required-field"
            >
              <Select placeholder="--Chọn--">
                {dataSoPha && dataSoPha.length > 0
                  ? dataSoPha.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item
              label={t("cap_nhat_ho_so.tinh_trang_su_dung_dien")}
              name="tinhTrangSuDungDienId"
              className="required-field"
            >
              <Select placeholder="--Chọn--">
                {dataTinhTrangSuDung && dataTinhTrangSuDung.length > 0
                  ? dataTinhTrangSuDung.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item
              label={t("cap_nhat_ho_so.hinh_thuc_gcs_hang_thang")}
              name="hinhThucGhiChiSoId"
              className="required-field"
            >
              <Select placeholder="--Chọn--">
                {dataHinhThucGhiChiSo && dataHinhThucGhiChiSo.length > 0
                  ? dataHinhThucGhiChiSo.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item
              label={t("cap_nhat_ho_so.loai_hop_dong")}
              name="loaiHopDongId"
              className="required-field"
            >
              <Select placeholder="--Chọn--">
                {dataLoaiHopDong && dataLoaiHopDong.length > 0
                  ? dataLoaiHopDong.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24} md={6}>
            <Form.Item
              label={t("cap_nhat_ho_so.hinh_thuc_tra_tien_hang_thang")}
              name="hinhThucTraTienId"
              className="required-field"
            >
              <Select
                placeholder="--Chọn--"
                onChange={handleChangeHinhThucTraTien}
              >
                {dataHinhThucTraTien && dataHinhThucTraTien.length > 0
                  ? dataHinhThucTraTien.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item
              label={t("cap_nhat_ho_so.hinh_thuc_khac")}
              name="hinhThucTraTienKhac"
            >
              <Input disabled={displayHinhThucKhac} />
            </Form.Item>
          </Col>
          <Col
            span={24}
            md={6}
            style={{ display: displayLanhDao ? "block" : "none" }}
          >
            <Form.Item
              label={t("cap_nhat_ho_so.lanh_dao_ky")}
              name="lanhDaoId"
              className="required-field"
            >
              <Select>
                <Option value={null}>--Chọn lãnh đạo ký--</Option>
                {dataLanhDao && dataLanhDao.length > 0
                  ? dataLanhDao.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item
              label={t("cap_nhat_ho_so.tinh_trang_thu")}
              name="tinhTrangThuId"
              className="required-field"
            >
              <Select placeholder="--Chọn--">
                {dataTinhTrangThu && dataTinhTrangThu.length > 0
                  ? dataTinhTrangThu.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={24} md={6}>
            <Form.Item
              label={t("cap_nhat_ho_so.he_so_dong_thoi")}
              name="heSoDongThoi"
            >
              <InputFormatter type={TYPE_INPUT.DECIMAL} decimals={2} />
            </Form.Item>
          </Col> */}
          <Col
            span={24}
            md={6}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Form.Item name={"isDatCoc"} style={{ marginBottom: "0" }}>
              <Checkbox checked={isDatCoc} onChange={handleChangeDatCoc}>
                Cần nộp tiền đặt cọc
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </FormComponent>
    </LoadingComponent>
  );
}
