import { alertMessage422 } from "@modules/bao-cao-kinh-doanh/service";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";

export const selectBCTongHop = [
  {
    label:
      "1. Báo cáo số lượng hợp đồng mua bán điện tính đến thời điểm (Ngày báo cáo)",
    value: Endpoint.BCAGBD_BC_SL_HOP_DONG_MUA_BAN_DIEN_TINH_TOI_THOI_DIEM,
  },
  {
    label: "2. Báo cáo công tơ tính tới thời điểm (Ngày báo cáo)",
    value: Endpoint.BCAGBD_BC_CONG_TO_TINH_TOI_THOI_DIEM,
  },
  {
    label:
      "3. Báo cáo tổng hợp công tác kiểm tra áp giá bán điện tính đến thời điểm (Ngày báo cáo)",
    value: Endpoint.BCAGBD_BC_TH_CT_KT_AP_GIA_BAN_DIEN_TINH_TOI_THOI_DIEM,
  },
  {
    label:
      "4. Báo cáo kết quả kiểm tra định mức các hộ sử dụng có 2 định mức trở lên",
    value: Endpoint.BCAGBD_BCKQ_KT_DINH_MUC_CAC_HO_SD_CO_2_DINH_MUC,
  },
];

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      NgayThang: data.reportDate,
    })
  );
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      LoaiBC: data.LoaiBC,
      SoDinhMuc: data.SoDinhMuc,
      NgayThang: data.reportDate,
    })
  );

  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
      if (res.statusCode === 422) {
        alertMessage422(res);
      }
    },
  });
};
