import { Row, Col, Form, Button, DatePicker } from "antd";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
// import DatePickerComponent from "@components/DatePicker";
import moment from "moment";
import { FORMAT_MONTH } from "@utils/constants";
import { disabledDateFuture } from "@utils/function";

export default function TongHopSoLieu(props) {
  const { loading, form, onFinish, user, data, handleChangeDate } = props;

  return (
    <LoadingComponent loading={loading}>
      <FormComponent
        form={form}
        name="tong-hop-form"
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          ngayBaoCao: moment(),
        }}
        autoComplete="off"
        style={{ padding: "0 80px" }}
      >
        <Row gutter={24}>
          <Col
            span={24}
            md={8}
            style={{ marginBottom: 0, display: "flex", alignItems: "center" }}
          >
            <h4>Đơn vị:</h4>
            <h3 className="font-bold" style={{ marginLeft: "20px" }}>
              {" "}
              {user.unitName}
            </h3>
          </Col>
          <Col span={24} md={4}>
            <Form.Item name="ngayBaoCao" label="Tháng báo cáo">
              <DatePicker
                // defaultValue={moment()}
                format={FORMAT_MONTH}
                form={form}
                // formKey={"ngayBaoCao"}
                picker="month"
                allowClear={false}
                onChange={handleChangeDate}
                disabledDate={disabledDateFuture}
                // notClear={true}
              />
            </Form.Item>
          </Col>
          <Col
            span={24}
            md={8}
            style={{
              textAlign: "right",
              marginBottom: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              form="tong-hop-form"
              style={{ marginTop: "5px" }}
            >
              Tổng hợp số liệu
            </Button>
          </Col>
        </Row>
      </FormComponent>
      <h3 style={{ padding: "0 80px", marginTop: "20px" }}>
        Ngày giờ tổng hợp báo cáo mới nhất:
        <span
          className="font-bold"
          style={{ fontSize: "20px", marginLeft: "20px", color: "#52c41a" }}
        >
          {data}
        </span>
      </h3>
    </LoadingComponent>
  );
}
