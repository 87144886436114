import { Col, Row } from "antd";
import React from "react";
import { ContainerStyled, ContentTB } from "../css-styled";
import DanhSachDiemDo from "./danh-sach-diem-do";
import DanhSachTram from "./danh-sach-tram";
import ThongTinDiemDo from "./thong-tin-diem-do";
export default function ContentQuanLyChiSo() {
  return (
    <ContainerStyled>
      <ContentTB>
        <ThongTinDiemDo />
        <Row gutter={16}>
          <Col span={4}>
            <DanhSachTram />
          </Col>
          <Col span={20}>
            <DanhSachDiemDo />
          </Col>
        </Row>
      </ContentTB>
    </ContainerStyled>
  );
}
