import { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Form, Input } from "antd";
import LoadingComponent from "@components/Loading";
import { submitPKDTraLai } from "./services";
const { TextArea } = Input;

export default function TraLaiModal(props) {
  const {
    visible,
    handleCloseModalTraLai,
    setFetchDataTable,
    selectedRowKeys,
    setSelectedRowKeys,
  } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);
  const onFinish = (value) => {
    submitPKDTraLai(
      setLoading,
      setFetchDataTable,
      selectedRowKeys,
      setSelectedRowKeys,
      handleCloseModalTraLai,
      value.lyDoTraLai
    );
  };
  return (
    <Modal
      title="Phòng kinh doanh trả lại yêu cầu!"
      visible={visible}
      onCancel={handleCloseModalTraLai}
      className="modal-bpag-tra-lai"
      footer={null}
    >
      <LoadingComponent loading={loading}>
        <Form
          form={form}
          name="form-bpag-tra-lai"
          layout="horizontal"
          onFinish={onFinish}
        >
          <Row gutter={24}>
            <span>
              Lý do trả lại<span style={{ color: "red" }}>*</span>
            </span>
            <Col span={20}>
              <Form.Item
                name="lyDoTraLai"
                label=""
                rules={[
                  {
                    required: true,
                    message: "Lý do trả lại không được để trống",
                    whitespace: true,
                  },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row gutter={24} justify="center">
            <Button
              key="back"
              onClick={handleCloseModalTraLai}
              className="button-closed mr-24px"
            >
              Đóng lại
            </Button>
            <Button
              key="submit"
              type="primary"
              className="button-primary mr-24px"
              htmlType="submit"
              form="form-bpag-tra-lai"
            >
              Trả lại
            </Button>
          </Row>
        </Form>
      </LoadingComponent>
    </Modal>
  );
}
