import { BASE_API_URL_CAP_DIEN } from "@utils/constants";

export const APIDuyetDoiTruong = {
  LIST_HO_SO: `${BASE_API_URL_CAP_DIEN}/ho-so/list`,
  CREATE_HO_SO: `${BASE_API_URL_CAP_DIEN}/ho-so/create`,
  UPDATE_HO_SO: `${BASE_API_URL_CAP_DIEN}/ho-so/update`,
  REMOVE_HO_SO: `${BASE_API_URL_CAP_DIEN}/ho-so/xoa-ho-so-new-version`,
  CANCEL_HO_SO: `${BASE_API_URL_CAP_DIEN}/ho-so/cancel`,
  DETAIL_HO_SO: `${BASE_API_URL_CAP_DIEN}/ho-so/details`,
  CHECK_TRA_HO_SO: `${BASE_API_URL_CAP_DIEN}/ho-so/check-tra-ho-so`,
  UPDATE_DETAIL_HO_SO: `${BASE_API_URL_CAP_DIEN}/ho-so/cap-nhat-ho-so/chi-tiet`,
  TRA_LAI_HO_SO: `${BASE_API_URL_CAP_DIEN}/ho-so/return`,
  RE_NEW_HO_SO: `${BASE_API_URL_CAP_DIEN}/ho-so/renew`,
  KHAO_SAT_LAI: `${BASE_API_URL_CAP_DIEN}/xu-ly-ho-so/khao-sat-lai`,
  XU_LY_CAP_NHAT_TRO_NGAI: `${BASE_API_URL_CAP_DIEN}/xu-ly-ho-so/cap-nhat-tro-ngai`,
  XU_LY_LIST_TRO_NGAI: `${BASE_API_URL_CAP_DIEN}/xu-ly-ho-so/list-tro-ngai`,
  XU_LY_CHUYEN_DON_VI: `${BASE_API_URL_CAP_DIEN}/xu-ly-ho-so/chuyen-don-vi`,
  XU_LY_XAC_NHAN_PHUONG_AN: `${BASE_API_URL_CAP_DIEN}/xu-ly-ho-so/xac-nhan-phuong-an`,
  DATA_THONG_TIN_KH_CMIS: `${BASE_API_URL_CAP_DIEN}/ho-so/thong-tin-kh`,
};
