import { Modal } from "antd";

const ModalWarningChange = (onOkClick, onCancelClick) =>
  Modal.confirm({
    centered: true,
    width: 600,
    title: "Lưu thay đổi?",
    content:
      "Nếu bạn di chuyển / đóng tab khác mà không lưu, những thay đổi bạn đã thực hiện trước đó sẽ bị mất",
    onOk() {
      onOkClick();
    },
    onCancel() {
      onCancelClick();
    },
    okText: "Đồng ý",
    okButtonProps: { type: "primary" },
    cancelText: "Hủy",
  });

export default ModalWarningChange;
