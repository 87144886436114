import LoadingComponent from "@components/Loading";
import { Checkbox, Col, Form, Row } from "antd";

export default function ThongTinTUTreoMoi(props) {
  const {
    loading,
    isChecked,
    dataTreo,
    handleChange,
    renderTable,
    renderDSCT,
  } = props;

  return (
    <LoadingComponent loading={loading}>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name={"isNangHaTU"} style={{ margin: 0 }}>
            <Checkbox
              checked={isChecked}
              onChange={handleChange}
              disabled={dataTreo && !dataTreo?.tutIs?.length}
            >
              Nâng hạ công suất
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={24}>{renderTable()}</Col>
      </Row>
      {renderDSCT()}
    </LoadingComponent>
  );
}
