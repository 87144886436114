import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_DATE,
  FORMAT_ENGLISH,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { downloadFile } from "@utils/request";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import moment from "moment";
import React, { Fragment, useCallback, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { selectBaoCaoDoiSoatZalo } from "../../services";
// Báo cáo danh sách khách hàng phát triển mới
export default function BCSlKhPhatTrienMoiEvnHaNoi(props) {
  const { screen, handleChange } = props;
  const [form] = Form.useForm();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const { user } = useSelector(authSelector);
  const [filterConditions,] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
    DenNgay: moment().format(FORMAT_ENGLISH),
    ...parseParams(location.search),
  });

  // layout effect
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const handleSearch = useCallback((values) => {
    const params = { ...values };
    const endpoint =
      Endpoint.BC_EVNHANOI_EXCEL + "?" + buildQueryString(parseParams(params));
    downloadFile({
      endpoint,
      setLoading,
    });
  }, []);

  return (
    <Fragment>
      <Container>
        <Divider className="section-text" orientation="left">
          Hiển thị báo cáo
        </Divider>
        <ContentWrapper>
          <FormComponent
            onFinish={handleSearch}
            form={form}
            labelCol={{ span: 3 }}
            initialValues={{
              reportType: screen,
            }}
          >
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="reportType"
                  label="Tên báo cáo"
                  labelAlign="left"
                >
                  <Select
                    formKey="reportType"
                    form={form}
                    options={selectBaoCaoDoiSoatZalo}
                    onChange={() =>
                      handleChange(form.getFieldValue("reportType"))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="DonViId" label="Đơn vị" labelAlign="left">
                  <Selection
                    url={Endpoint.GET_UNIT_LIST}
                    formKey="DonViId"
                    form={form}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="TuNgay"
                  label="Ngày bắt đầu"
                  labelAlign="left"
                  labelCol={{ span: 9 }}
                >
                  <div style={{ marginLeft: "3%" }}>
                    <DatePickerComponent
                      formKey="TuNgay"
                      form={form}
                      format={FORMAT_ENGLISH}
                      defaultValue={
                        filterConditions.TuNgay
                          ? moment(filterConditions.TuNgay, FORMAT_ENGLISH)
                          : moment().startOf(FORMAT_DATE)
                      }
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="DenNgay"
                  label="Ngày kết thúc"
                  labelAlign="left"
                  labelCol={{ span: 10 }}
                >
                  <DatePickerComponent
                    formKey="DenNgay"
                    form={form}
                    format={FORMAT_ENGLISH}
                    defaultValue={
                      filterConditions.DenNgay
                        ? moment(filterConditions.DenNgay, FORMAT_ENGLISH)
                        : moment().startOf(FORMAT_ENGLISH)
                    }
                  />
                </Form.Item>
              </Col>
              <Row justify="end">
                <Button type="primary" htmlType="submit" loading={loading}>
                  Xuất excel
                </Button>
              </Row>
            </Row>
          </FormComponent>
          <Divider className="section-text-line" orientation="left"></Divider>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
