import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";
import { buildQueryString, parseParams, alertMessage } from "@utils/function";
import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";

// get list data điều hành ký bảng kê
export const handleListDataDieuHanhKBK = (
  setLoading,
  location,
  setDataDieuHanhKBK,
  setTempDataDieuHanhKBK,
  setTotalCount,
  setTempTotalCount,
  filterConditions,
  setSelectedRowKeys
) => {
  let url = "";
  url = `${Endpoint.LIST_NV_DIEU_HANH_KBK}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      setSelectedRowKeys([]);
      if (res.data !== null) {
        setDataDieuHanhKBK(res.data);
        setTempDataDieuHanhKBK(res.data);
        setTotalCount(res.paging.totalCount);
        setTempTotalCount(res.paging.totalCount);
      } else setDataDieuHanhKBK([]);
    },
  });
};

export const submitDieuHanhXacNhan = (
  t,
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.DIEU_HANH_XAC_NHAN,
    method: METHOD_PUT,
    payload: {
      id: selectedRowKeys,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};
export const submitDHKyHSM = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.GCS_DH_KY_HSM,
    method: METHOD_PUT,
    payload: {
      id: selectedRowKeys,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
      }
    },
  });
};
//điều hành trả lại bảng kê
export const handleDieuHanhTraLaiBK = (
  id,
  values,
  setLoading,
  setFetchDataTable,
  closeModal,
  t
) => {
  authPostData({
    url: Endpoint.DIEU_HANH_TRA_LAI_BK,
    method: METHOD_PUT,
    payload: {
      id: id,
      lyDoTraLai: values.lyDoTraLai,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        closeModal();
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};
