import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  FORMAT_YEAR,
} from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { Table } from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { getLichGCSTheoQuy } from "./services";
import BaoCaoGCSTheoQuy from "./table";
export default function GCSTheoQuy() {
  const [loading, setLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const [dataTheoQuy, setDataTheoQuy] = useState();
  const { user } = useSelector(authSelector);
  const [totalCount, setTotalCount] = useState();
  const location = useLocation();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    Quy: moment().quarter(),
    Nam: moment().format(FORMAT_YEAR),
    DonViId: user.unitId,
    ...parseParams(location.search),
  });
  const quarter = parseInt(filterConditions.Quy);
  const getLichGCS = useCallback(() => {
    getLichGCSTheoQuy(
      filterConditions,
      setLoading,
      setDataTheoQuy,
      setTotalCount
    );
  }, [filterConditions]);

  useEffect(() => {
    getLichGCS();
  }, [getLichGCS]);

  const handleSearch = useCallback(
    (values) => {
      const date = new Date(values.Quy);
      setFilterConditions((oldState) => ({
        ...oldState,
        ...values,
        Quy: moment(values.Quy).quarter(),
        Nam: date.getFullYear(),
      }));
    },
    [setFilterConditions]
  );

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      Quy: moment().quarter(),
      Nam: moment().format(FORMAT_YEAR),
      DonViId: user.unitId,
    });
  }, [user.unitId]);

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const columns = [
    {
      title: "STT",
      key: "index",
      render: (a, b, index) => {
        return (
          (filterConditions.pageIndex - 1) * filterConditions.pageSize +
          (index + 1)
        );
      },
      width: 30,
      align: "center",
    },
    {
      title: "Đơn vị",
      dataIndex: "maDonVi",
      key: "maDonVi",
      align: "center",
      width: 70,
    },
    {
      title: `Tháng ${(quarter - 1) * 3 + 1}`,
      dataIndex: `thang${(quarter - 1) * 3 + 1}`,
      children: [
        {
          title: "Tổng (SL sổ GCS)",
          dataIndex: `tongSoGCSthang${(quarter - 1) * 3 + 1}`,
          key: "tongSoGCS",
          width: 70,
          align: "center",
        },
        {
          title: "Tổng (SL sổ GCS lệch ngày ghi)",
          dataIndex: `tongSoLechNgaythang${(quarter - 1) * 3 + 1}`,
          width: 70,
          align: "center",
        },
      ],
    },
    {
      title: `Tháng ${(quarter - 1) * 3 + 2}`,
      dataIndex: `thang${(quarter - 1) * 3 + 2}`,
      children: [
        {
          title: "Tổng (SL sổ GCS)",
          dataIndex: `tongSoGCSthang${(quarter - 1) * 3 + 2}`,
          width: 70,
          align: "center",
        },
        {
          title: "Tổng (SL sổ GCS lệch ngày ghi)",
          dataIndex: `tongSoLechNgaythang${(quarter - 1) * 3 + 2}`,
          width: 70,
          align: "center",
        },
      ],
    },
    // t3
    {
      title: `Tháng ${(quarter - 1) * 3 + 3}`,
      dataIndex: `thang${(quarter - 1) * 3 + 3}`,
      children: [
        {
          title: "Tổng (SL sổ GCS)",
          dataIndex: `tongSoGCSthang${(quarter - 1) * 3 + 3}`,
          width: 70,
          align: "center",
        },
        {
          title: "Tổng (SL sổ GCS lệch ngày ghi)",
          dataIndex: `tongSoLechNgaythang${(quarter - 1) * 3 + 3}`,
          width: 70,
          align: "center",
        },
      ],
    },
  ];
  const countTotals = (pageData) => {
    let countTongSoGCS1 = 0;
    let countTongSoGCS2 = 0;
    let countTongSoGCS3 = 0;
    let countTongSoLechNgaythang1 = 0;
    let countTongSoLechNgaythang2 = 0;
    let countTongSoLechNgaythang3 = 0;

    let countTongSoGCS4 = 0;
    let countTongSoGCS5 = 0;
    let countTongSoGCS6 = 0;
    let countTongSoLechNgaythang4 = 0;
    let countTongSoLechNgaythang5 = 0;
    let countTongSoLechNgaythang6 = 0;

    let countTongSoGCS7 = 0;
    let countTongSoGCS8 = 0;
    let countTongSoGCS9 = 0;
    let countTongSoLechNgaythang7 = 0;
    let countTongSoLechNgaythang8 = 0;
    let countTongSoLechNgaythang9 = 0;

    let countTongSoGCS10 = 0;
    let countTongSoGCS11 = 0;
    let countTongSoGCS12 = 0;
    let countTongSoLechNgaythang10 = 0;
    let countTongSoLechNgaythang11 = 0;
    let countTongSoLechNgaythang12 = 0;

    pageData.forEach(
      ({
        tongSoGCSthang1,
        tongSoGCSthang2,
        tongSoGCSthang3,
        tongSoLechNgaythang1,
        tongSoLechNgaythang2,
        tongSoLechNgaythang3,

        tongSoGCSthang4,
        tongSoGCSthang5,
        tongSoGCSthang6,
        tongSoLechNgaythang4,
        tongSoLechNgaythang5,
        tongSoLechNgaythang6,

        tongSoGCSthang7,
        tongSoGCSthang8,
        tongSoGCSthang9,
        tongSoLechNgaythang7,
        tongSoLechNgaythang8,
        tongSoLechNgaythang9,

        tongSoGCSthang10,
        tongSoGCSthang11,
        tongSoGCSthang12,
        tongSoLechNgaythang10,
        tongSoLechNgaythang11,
        tongSoLechNgaythang12,
      }) => {
        if (parseInt(filterConditions.Quy) === 1) {
          countTongSoGCS1 += tongSoGCSthang1;
          countTongSoGCS2 += tongSoGCSthang2;
          countTongSoGCS3 += tongSoGCSthang3;
          countTongSoLechNgaythang1 += tongSoLechNgaythang1;
          countTongSoLechNgaythang2 += tongSoLechNgaythang2;
          countTongSoLechNgaythang3 += tongSoLechNgaythang3;
        } else if (parseInt(filterConditions.Quy) === 2) {
          countTongSoGCS4 += tongSoGCSthang4;
          countTongSoGCS5 += tongSoGCSthang5;
          countTongSoGCS6 += tongSoGCSthang6;

          countTongSoLechNgaythang4 += tongSoLechNgaythang4;
          countTongSoLechNgaythang5 += tongSoLechNgaythang5;
          countTongSoLechNgaythang6 += tongSoLechNgaythang6;
        } else if (parseInt(filterConditions.Quy) === 3) {
          countTongSoGCS7 += tongSoGCSthang7;
          countTongSoGCS8 += tongSoGCSthang8;
          countTongSoGCS9 += tongSoGCSthang9;

          countTongSoLechNgaythang7 += tongSoLechNgaythang7;
          countTongSoLechNgaythang8 += tongSoLechNgaythang8;
          countTongSoLechNgaythang9 += tongSoLechNgaythang9;
        } else if (parseInt(filterConditions.Quy) === 4) {
          countTongSoGCS10 += tongSoGCSthang10;
          countTongSoGCS11 += tongSoGCSthang11;
          countTongSoGCS12 += tongSoGCSthang12;

          countTongSoLechNgaythang10 += tongSoLechNgaythang10;
          countTongSoLechNgaythang11 += tongSoLechNgaythang11;
          countTongSoLechNgaythang12 += tongSoLechNgaythang12;
        }
        return;
      }
    );
    return (
      <Table.Summary.Row style={{ backgroundColor: "#ccccb3" }}>
        <Table.Summary.Cell align="center" index={0} colSpan={2}>
          <Text className="font-bold">Tổng cộng</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={1}>
          <Text>
            {countTongSoGCS1 ||
              countTongSoGCS4 ||
              countTongSoGCS7 ||
              countTongSoGCS10}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={2}>
          <Text>
            {countTongSoLechNgaythang1 ||
              countTongSoLechNgaythang4 ||
              countTongSoLechNgaythang7 ||
              countTongSoLechNgaythang10}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={3}>
          <Text>
            {countTongSoGCS2 ||
              countTongSoGCS5 ||
              countTongSoGCS8 ||
              countTongSoGCS11}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={4}>
          <Text>
            {countTongSoLechNgaythang2 ||
              countTongSoLechNgaythang5 ||
              countTongSoLechNgaythang8 ||
              countTongSoLechNgaythang11}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={5}>
          <Text>
            {countTongSoGCS3 ||
              countTongSoGCS6 ||
              countTongSoGCS9 ||
              countTongSoGCS12}
          </Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell className="font-bold" align="center" index={6}>
          <Text>
            {countTongSoLechNgaythang3 ||
              countTongSoLechNgaythang6 ||
              countTongSoLechNgaythang9 ||
              countTongSoLechNgaythang12}
          </Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };
  return (
    <BaoCaoGCSTheoQuy
      quarter={quarter}
      columns={columns}
      handleSearch={handleSearch}
      filterConditions={filterConditions}
      loading={loading}
      setLoading={setLoading}
      onChangePagination={onChangePagination}
      data={dataTheoQuy}
      totalCount={totalCount}
      countTotals={countTotals}
      scrollX={1400}
      clearFilter={clearFilter}
    />
  );
}
