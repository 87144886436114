import { STATUSCODE_200 } from "@utils/constants";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, downloadFile } from "@utils/request";

export const selectBCTongHop = [
  {
    label: "Biểu 17: Tổng hợp số lượng tin nhắn qua các hình thức",
    value: 1,
  },
  {
    label: "Biểu: Tổng hợp số lượng KH sử dụng APP CSKH",
    value: 2,
  },
  {
    label:
      "Biểu: Tổng hợp số lượng KH cài đặt APP CSKH theo hệ điều hành IOSANDROID",
    value: 3,
  },
  {
    label: "Biểu: Báo cáo danh sách yêu cầu có số điện thoại nhiều hồ sơ",
    value: 4,
  },
];

export const exportData = (data, setLoading) => {
  const queryString = buildQueryString(
    parseParams({
      MaDonVi: data.unitId,
      TuNgay: data.TuNgay,
      DenNgay: data.DenNgay,
      LoaiCongTo: data.LoaiCongTo,
      KiemDinh: data.KiemDinh,
      KhoThietBi: data.KhoThietBi,
      SoPha: data.SoPha,
      TinhTrang: data.TinhTrang,
      SoNgay: data.SoNgay,
      LoaiKhachHang: data.LoaiKhachHang,
      LuyKe: data.LuyKe,
    })
  );
  authGetData({
    url: `${data.reportType}?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
      }
    },
  });
};

export const handleDownload = (data, setLoading) => {
  const queryString = buildQueryString(parseParams({}));
  const endpoint = `${data.reportType}-excel?${queryString}`;
  if (endpoint !== undefined) downloadFile({ endpoint, setLoading });
};

export const handleOpenPdf = (data, setLoading) => {
  const queryString = buildQueryString(parseParams({}));
  authGetData({
    url: `${data.reportType}-pdf?${queryString}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        window.open(res.data, "_blank");
      }
    },
  });
};
