import styled from "styled-components";

const PaginationStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  .pagination-container {
    display: flex;
    list-style-type: none;

    .pagination-item {
      display: flex;
      padding: 0 6px;
      height: 32px;
      margin: auto 4px;
      color: #000000d9;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      letter-spacing: 0.01071em;
      line-height: 1.43;
      min-width: 32px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      outline: 0;
      text-align: center;
      vertical-align: middle;
      list-style: none;

      & a{
        color: #000000d9;
      }

      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }
      &:hover {
        border-color: #1890ff;
        background: #fff;
        color: #1890ff;
        cursor: pointer;
      }
      &:hover a{
        color: #1890ff;
      }

      &.selected {
        border-color: #1890ff;
        background: #fff;
      }
      &.selected a{
        color: #1890ff;
      }

      .arrow {
        &::before {
          position: relative;
          /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
          content: "";
          /* By using an em scale, the arrows will size with the font */
          display: inline-block;
          width: 0.7em;
          height: 0.7em;
          border-right: 0.12em solid rgba(0, 0, 0, 0.87);
          border-top: 0.12em solid rgba(0, 0, 0, 0.87);
        }

        &.left {
          transform: rotate(-135deg) translate(-10%);
        }

        &.right {
          transform: rotate(45deg) translate(0%);
        }
      }

      &.disabled {
        pointer-events: none;

        .arrow::before {
          border-right: 0.12em solid rgba(0, 0, 0, 0.43);
          border-top: 0.12em solid rgba(0, 0, 0, 0.43);
        }

        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }
`;
export { PaginationStyle };
