
import TrangChu from "@modules/bao-cao-kinh-doanh";
import BaoCaoCTKDDVKHMoi from "@modules/bao-cao-kinh-doanh/bao-cao-quy-trinh-kinh-doanh/bao-cao-cong-tac-kinh-doanh-dich-vu-khach-hang-moi";
import BaoCaoKinhDoanh from "@modules/bao-cao-kinh-doanh/bao-cao-quy-trinh-kinh-doanh/bao-cao-kinh-doanh/bc-kinh-doanh";
import BaoCaoTHHDTheoGiaiDoan from "@modules/bao-cao-kinh-doanh/bao-cao-quy-trinh-kinh-doanh/bao-cao-th-hoa-don-theo-giai-doan";
import BaoCaoTHHDTheoKy from "@modules/bao-cao-kinh-doanh/bao-cao-quy-trinh-kinh-doanh/bao-cao-th-hoa-don-theo-ky";
import BaoCaoTongHopTonThat from "@modules/bao-cao-kinh-doanh/bao-cao-quy-trinh-kinh-doanh/bao-cao-th-ton-that/bao-cao-tong-hop-ton-that";
import BaoCaoTinNhanZalo from "@modules/bao-cao-kinh-doanh/bao-cao-quy-trinh-kinh-doanh/bao-cao-tin-nhan-zalo";
import DSKHCBAG_HCNS from "@modules/bao-cao-kinh-doanh/bao-cao-quy-trinh-kinh-doanh/ds-khach-hang-canh-bao-ap-gia-hanh-chinh-su-nghiep";

import BaoCaoCTQLTBDoDem from "@modules/bao-cao-kinh-doanh/bao-cao-cong-to/bao-cao-cong-tac-quan-li-thiet-bi-do-dem";
import BaoCaoTHSLCongTo from "@modules/bao-cao-kinh-doanh/bao-cao-cong-to/bao-cao-th-so-luong-cong-to";
import BaoCaoTBDD2Cap from "@modules/bao-cao-kinh-doanh/bao-cao-cong-to/bao-cao-thiet-bi-do-dem-2-cap";

import BaoCaoCDDongTien from "@modules/bao-cao-kinh-doanh/bao-cao-cong-no/bao-cao-cham-diem-dong-tien";
import BaoCaoDuNoDenDuong from "@modules/bao-cao-kinh-doanh/bao-cao-cong-no/bao-cao-du-no-den-duong";
import BaoCaoDuNoThuyNong from "@modules/bao-cao-kinh-doanh/bao-cao-cong-no/bao-cao-du-no-thuy-nong";
import BaoCaoHTCNVaDongTien from "@modules/bao-cao-kinh-doanh/bao-cao-cong-no/bao-cao-ho-tro-cong-no-va-dong-tien";
import BaoCaoQuanLyDongTien from "@modules/bao-cao-kinh-doanh/bao-cao-cong-no/bao-cao-quan-ly-dong-tien";
import BaoCaoTHKiemKe0Gio from "@modules/bao-cao-kinh-doanh/bao-cao-cong-no/bao-cao-tong-hop-kiem-ke-0gio";
import BaoCaoTHSTDACuaKHDungDien13G from "@modules/bao-cao-kinh-doanh/bao-cao-cong-no/bao-cao-tong-hop-so-tien-da-thu-cua-khach-hang-dung-dien-13g";
import BaoCaoTHSTKHNo13Gio from "@modules/bao-cao-kinh-doanh/bao-cao-cong-no/bao-cao-tong-hop-so-tien-khach-hang-no-13h";
import BieuTiLeThuNopTienDien from "@modules/bao-cao-kinh-doanh/bao-cao-cong-no/bieu-ty-le-thuc-hien-thu-nop-tien-dien";
import TongHopTTQNganHangTCTGThanhToan from "@modules/bao-cao-kinh-doanh/bao-cao-cong-no/th-thanh-toan-qua-ngan-hang-to-chuc-trung-gian-thanh-toan";

import BaoCaoChiTietApGia from "@modules/bao-cao-kinh-doanh/bao-cao-ap-gia-ban-dien/bao-cao-chi-tiet";
import BaoCaoTongHopApGia from "@modules/bao-cao-kinh-doanh/bao-cao-ap-gia-ban-dien/bao-cao-tong-hop";

import BCDichVuBanLeDienNang from "@modules/bao-cao-kinh-doanh/bao-cao-dich-vu-ban-le-dien-nang/bc-dich-vu-ban-le-dien-nang";

import BaoCaoHeThongDoXa from "@modules/bao-cao-kinh-doanh/bao-cao-he-thong-do-xa/bao-cao-he-thong-do-xa";

import BaoCaoChiTietCapDien from "@modules/bao-cao-kinh-doanh/bao-cao-cap-dien/bao-cao-chi-tiet-cap-dien";
import TongHopTrangWebCapDien from "@modules/bao-cao-kinh-doanh/bao-cao-cap-dien/tong-hop-trang-web-cap-dien";

import BCTDPhatTrienKhachHang from "@modules/bao-cao-kinh-doanh/bao-cao-theo-doi-phat-trien-khach-hang/bc-theo-doi-phat-trien-khach-hang";

import BaoCaoKHPhien9 from "@modules/bao-cao-kinh-doanh/bao-cao-khach-hang-phien-9/bao-cao-khach-hang-phien-9";

import BaoCaoDichVuCong from "@modules/bao-cao-kinh-doanh/bao-cao-theo-doi-dich-vu-khach-hang/bao-cao-dich-vu-cong";
import BaoCaoDichVuDienTu from "@modules/bao-cao-kinh-doanh/bao-cao-theo-doi-dich-vu-khach-hang/bao-cao-dich-vu-dien-tu";
import BaoCaoKinhDoanhMoi from "@modules/bao-cao-kinh-doanh/bao-cao-theo-doi-dich-vu-khach-hang/bao-cao-kinh-doanh-moi";
import BaoCaoTheoDoiCMIS3 from "@modules/bao-cao-kinh-doanh/bao-cao-theo-doi-dich-vu-khach-hang/bao-cao-theo-doi-cong-tac-dich-vu-khach-hang-cmis3";

import BCTongHopSoLuongAnhGCS from "@modules/bao-cao-kinh-doanh/bao-cao-tinh-hinh-sms/bao-cao-tong-hop-so-luong-anh-gcs";
import TimKiem from "@modules/bao-cao-kinh-doanh/bao-cao-tinh-hinh-sms/tim-kiem";
import TinNhanChuDongMT from "@modules/bao-cao-kinh-doanh/bao-cao-tinh-hinh-sms/tin-nhan-chu-dong-mt";
import TinNhanMoMT from "@modules/bao-cao-kinh-doanh/bao-cao-tinh-hinh-sms/tin-nhan-mo-mt";

import BaoCaoGhiChiSo from "@modules/bao-cao-kinh-doanh/bao-cao-ghi-chi-so/bao-cao-ghi-chi-so";

import BaoCaoHoaDon from "@modules/bao-cao-kinh-doanh/bao-cao-hoa-don/bao-cao-hoa-don";
import BaoCaoEmailCSKH from "@modules/bao-cao-kinh-doanh/bao-cao-tinh-hinh-email/bao-cao-email-cskh";
import BaoCaoThuongPham from "@modules/bao-cao-kinh-doanh/bao-cao-ton-that/bao-cao-thuong-pham";

import BaoCaoThueTro from "@modules/bao-cao-kinh-doanh/bao-cao-ap-gia-ban-dien/bao-cao-thue-tro";
import BCTongHopKhachHangNoQuaHan from "@modules/bao-cao-kinh-doanh/bao-cao-cong-no/bao-cao-th-khach-hang-no-qua-hang";
import BCTongHopQuanLyDongTien from "@modules/bao-cao-kinh-doanh/bao-cao-cong-no/bao-cao-th-quan-ly-dong-tien";
import BCTyLeSoThuTheoHoaDon from "@modules/bao-cao-kinh-doanh/bao-cao-cong-no/bao-cao-ty-le-so-thu-theo-hoa-don";
import BCTyLeSoThuTheoHoaSoTien from "@modules/bao-cao-kinh-doanh/bao-cao-cong-no/bao-cao-ty-le-so-thu-theo-so-tien";
import BaoCaoKHSuDungHeSinhThaiMoi from "@modules/bao-cao-kinh-doanh/bao-cao-theo-doi-dich-vu-khach-hang/bao-cao-kh-su-dung-he-sinh-thai-moi";
import TKThongTinLienHeLQDenKHDoTTCSKHCapNhat from "@modules/bao-cao-kinh-doanh/bao-cao-thong-ke-thong-tin-lien-he-lien-quan-den-khach-hang/thong-ke-thong-tin-lien-he-lien-quan-den-khach-hang-do-tt-cskh-cap-nhat";
import TKThongTinLienHeLQDenKhCuaCTDL from "@modules/bao-cao-kinh-doanh/bao-cao-thong-ke-thong-tin-lien-he-lien-quan-den-khach-hang/thong-ke-thong-tin-lien-he-lien-quan-den-khách-hang-cua-ctdl.jsx";
import TKThongTinLienHeLQDenKHCuaCacCTDLVaCSKH from "@modules/bao-cao-kinh-doanh/bao-cao-thong-ke-thong-tin-lien-he-lien-quan-den-khach-hang/thong-ke-thong-tin-lien-he-lq-den-kh-cua-cac-cty-dien-luc-va-tt-cskh";
import TKThongTinLienHeLQDenKHTheoTungCTDLCuaToanTCT from "@modules/bao-cao-kinh-doanh/bao-cao-thong-ke-thong-tin-lien-he-lien-quan-den-khach-hang/thsl-thong-tin-lien-he-lien-quan-den-kh-theo-tung-cong-ty-dien-luc-cua-toan-tong-cty";
import BaoCaoWebEVN from "@modules/bao-cao-kinh-doanh/bao-cao-web-evn-ha-noi/bao-cao-web-evn-hn";
import BaoCaoPhanHeHoaDonNghienCuuPhuTai from "@modules/bao-cao-kinh-doanh/nghien-cuu-phu-tai/bao-cao-phan-he-hoa-don";
import CongSuatDuocYCSeTietGiamEVNHaNoi from "@modules/bao-cao-kinh-doanh/nghien-cuu-phu-tai/cong-suat-duoc-yeu-cau-se-tiet-giam-cua-evnhanoi";
import DanhMucDiemDoDRMS from "@modules/bao-cao-kinh-doanh/nghien-cuu-phu-tai/danh-muc-diem-do-drms";
import NgayDuocTinhDeDuaRaBDCoSo from "@modules/bao-cao-kinh-doanh/nghien-cuu-phu-tai/ngay-duoc-tinh-de-dua-ra-bieu-do-co-so";
import { bao_cao_kinh_doanh } from "@permissions/f_bc";
import { MODULE_BAOCAO } from "@utils/constants";
import BaoCaoKiemChiNiemPhong from "@modules/bao-cao-kinh-doanh/bao-cao-kiem-chi-niem-phong";
const mainPath = "/bao-cao-kinh-doanh";
const routes = [
  {
    title: "",
    path: mainPath,
    privateRoute: true,
    component: TrangChu,
    subItems: [],
    permissionCode: MODULE_BAOCAO,
  },
  // bao cao kinh doanh
  {
    title: "Báo cáo quy trình kinh doanh",
    path: mainPath + "/quy-trinh-kinh-doanh",
    privateRoute: true,
    permissionCode: bao_cao_kinh_doanh.f17,
    subItems: [
      {
        subTitle: "Báo cáo kinh doanh",
        subPath: "/kinh-doanh",
        subComponent: BaoCaoKinhDoanh,
        permissionCode: bao_cao_kinh_doanh.f1711,
      },
      {
        subTitle: "Báo cáo tổng hợp tổn thất",
        subPath: "/tong-hop-ton-that",
        subComponent: BaoCaoTongHopTonThat,
        permissionCode: bao_cao_kinh_doanh.f1721,
      },
      {
        subTitle: "Báo cáo phân hệ hóa đơn",
        subPath: "/bao-cao-phan-he-hoa-don",
        subComponent: BaoCaoHoaDon,
        permissionCode: bao_cao_kinh_doanh.f173,
      },
      {
        subTitle: "Báo cáo tổng hợp hóa đơn theo kỳ",
        subPath: "/bao-cao-thhd-theo-ky",
        subComponent: BaoCaoTHHDTheoKy,
        permissionCode: bao_cao_kinh_doanh.f174,
      },
      {
        subTitle: "Báo cáo tổng hợp hóa đơn theo giai đoạn",
        subPath: "/bao-cao-thhd-theo-giai-doan",
        subComponent: BaoCaoTHHDTheoGiaiDoan,
        permissionCode: bao_cao_kinh_doanh.f175,
      },
      {
        subTitle: "Báo cáo tin nhắn zalo ",
        subPath: "/bao-cao-tin-nhan-zalo",
        subComponent: BaoCaoTinNhanZalo,
        permissionCode: bao_cao_kinh_doanh.f176,
      },
      {
        subTitle: "Danh sách khách hàng cảnh báo áp giá hành chính sự nghiệp ",
        subPath: "/danh-sach-khach-hang-canh-bao-ap-gia-hanh-chinh-nhan-su",
        subComponent: DSKHCBAG_HCNS,
        permissionCode: bao_cao_kinh_doanh.f177,
      },

      {
        subTitle: "Báo cáo công tác kinh doanh dịch vụ khách hàng mới ",
        subPath: "/bao-cao-cong-tac-kinh-doanh-dich-vu-khach-hhang-moi",
        subComponent: BaoCaoCTKDDVKHMoi,
        permissionCode: bao_cao_kinh_doanh.f178,
      },
    ],
  },
  // bao cao cong to
  {
    title: "Báo cáo công tơ",
    path: mainPath + "/bao-cao-cong-to",
    privateRoute: true,
    permissionCode: bao_cao_kinh_doanh.f1,
    subItems: [
      {
        subTitle: "Báo cáo tổng hợp số lượng công tơ",
        subPath: "/bao-cao-tong-hop-so-luong-cong-to",
        subComponent: BaoCaoTHSLCongTo,
        permissionCode: bao_cao_kinh_doanh.f11,
      },
      {
        subTitle: "Báo cáo thiết bị đo đếm 2 cấp",
        subPath: "/bao-cao-thiet-bi-do-dem-2-cap",
        subComponent: BaoCaoTBDD2Cap,
        permissionCode: bao_cao_kinh_doanh.f12,
      },
      {
        subTitle: "Báo cáo công tác quản lý thiết bị đo đếm",
        subPath: "/bao-cao-cong-tac-quan-ly-thiet-bi-do-dem",
        subComponent: BaoCaoCTQLTBDoDem,
        permissionCode: bao_cao_kinh_doanh.f13,
      },
    ],
  },
  // Báo cáo kìm chì niêm phong
  {
    title: "Báo cáo kìm chì niêm phong",
    path: mainPath + "/bao-cao-kim-chi-niem-phong",
    privateRoute: true,
    permissionCode: bao_cao_kinh_doanh.f1,
    component: BaoCaoKiemChiNiemPhong,
    subItems: [],
  },
  // bao cao cong no
  {
    title: "Báo cáo công nợ",
    path: mainPath + "/bao-cao-cong-no",
    privateRoute: true,
    permissionCode: bao_cao_kinh_doanh.f2,
    subItems: [
      {
        subTitle: "Báo cáo chấm điểm dòng tiền",
        subPath: "/bao-cao-cham-diem-dong-tien",
        subComponent: BaoCaoCDDongTien,
        permissionCode: bao_cao_kinh_doanh.f2,
      },
      {
        subTitle: "Báo cáo quản lý dòng tiền",
        subPath: "/bao-cao-quan-ly-dong-tien",
        subComponent: BaoCaoQuanLyDongTien,
        permissionCode: bao_cao_kinh_doanh.f2,
      },
      {
        subTitle: "Biểu tỷ lệ thực hiện thu nộp tiền điện",
        subPath: "/bieu-ty-le-thuc-hien-thu-nop-tien-dien",
        subComponent: BieuTiLeThuNopTienDien,
        permissionCode: bao_cao_kinh_doanh.f2,
      },
      {
        subTitle:
          "Tổng hợp thanh toán qua ngân hàng tổ chức trung gian thanh toán",
        subPath:
          "/tong-hop-thanh-toan-qua-ngan-hang-to-chuc-trung-gian-thanh-toan",
        subComponent: TongHopTTQNganHangTCTGThanhToan,
        permissionCode: bao_cao_kinh_doanh.f2,
      },
      {
        subTitle: "Báo cáo dư nợ thủy nông",
        subPath: "/bao-cao-du-no-thuy-nong",
        subComponent: BaoCaoDuNoThuyNong,
        permissionCode: bao_cao_kinh_doanh.f2,
      },
      {
        subTitle: "Báo cáo dư nợ đèn đường",
        subPath: "/bao-cao-du-no-den-duong",
        subComponent: BaoCaoDuNoDenDuong,
        permissionCode: bao_cao_kinh_doanh.f2,
      },
      {
        subTitle: "Báo cáo tổng hợp số tiền khách hàng còn nợ 13h",
        subPath: "/bao-cao-tong-hop-so-tien-khach-hang-con-no-13h",
        subComponent: BaoCaoTHSTKHNo13Gio,
        permissionCode: bao_cao_kinh_doanh.f2,
      },
      {
        subTitle: "Báo cáo tổng hợp số tiền đã thu của khách hàng dùng điện13g",
        subPath:
          "/bao-cao-tong-hop-so-tien-da-thu-cua-khach-hang-dung-dien-13g",
        subComponent: BaoCaoTHSTDACuaKHDungDien13G,
        permissionCode: bao_cao_kinh_doanh.f2,
      },
      {
        subTitle: "Báo cáo tổng hợp kiểm kê 0g",
        subPath: "/bao-cao-tong-hop-kiem-ke-0-gio",
        subComponent: BaoCaoTHKiemKe0Gio,
        permissionCode: bao_cao_kinh_doanh.f2,
      },
      {
        subTitle: "Báo cáo hỗ trợ công nợ và dòng tiền",
        subPath: "/bao-cao-ho-tro-cong-no-va-dong-tien",
        subComponent: BaoCaoHTCNVaDongTien,
        permissionCode: bao_cao_kinh_doanh.f2,
      },
      {
        subTitle: "Báo cáo tỷ lệ số thu theo hóa đơn",
        subPath: "/bao-cao-ty-le-so-thu-theo-hoa-don",
        subComponent: BCTyLeSoThuTheoHoaDon,
        permissionCode: bao_cao_kinh_doanh.f2,
      },
      {
        subTitle: "Báo cáo tỷ lệ số thu theo số tiền",
        subPath: "/bao-cao-ty-le-so-thu-theo-so-tien",
        subComponent: BCTyLeSoThuTheoHoaSoTien,
        permissionCode: bao_cao_kinh_doanh.f2,
      },
      {
        subTitle: "Báo cáo tổng hợp quản lý dòng tiền",
        subPath: "/bao-cao-tong-hop-quan-ly-dong-tien",
        subComponent: BCTongHopQuanLyDongTien,
        permissionCode: bao_cao_kinh_doanh.f2,
      },
      {
        subTitle: "Báo cáo tổng hợp khách hàng nợ quá hạn",
        subPath: "/bao-cao-tong-hop-khach-hang-no-qua-han",
        subComponent: BCTongHopKhachHangNoQuaHan,
        permissionCode: bao_cao_kinh_doanh.f2,
      },
    ],
  },
  // bao cao ap gia ban dien
  {
    title: "Báo cáo áp giá bán điện",
    path: mainPath + "/bao-cao-ap-gia-ban-dien",
    privateRoute: true,
    permissionCode: bao_cao_kinh_doanh.f3,
    subItems: [
      {
        subTitle: "Báo cáo tổng hợp",
        subPath: "/bao-cao-tong-hop",
        subComponent: BaoCaoTongHopApGia,
        permissionCode: bao_cao_kinh_doanh.f3,
      },
      {
        subTitle: "Báo cáo chi tiết",
        subPath: "/bao-cao-chi-tiet",
        subComponent: BaoCaoChiTietApGia,
        permissionCode: bao_cao_kinh_doanh.f3,
      },
      {
        subTitle: "Báo cáo thuê trọ",
        subPath: "/bao-cao-thue-tro",
        subComponent: BaoCaoThueTro,
        permissionCode: bao_cao_kinh_doanh.f3,
      },
    ],
  },
  // bao cao dich vu ban le dien nang
  {
    title: "Báo cáo dịch vụ bán lẻ điện năng",
    path: mainPath + "/bao-cao-dich-vu-ban-le-dien-nang",
    privateRoute: true,
    subItems: [],
    component: BCDichVuBanLeDienNang,
    permissionCode: bao_cao_kinh_doanh.f4,
  },
  // bao cao he thong do xa
  {
    title: "Báo cáo hệ thống đo xa",
    path: mainPath + "/bao-cao-he-thong-do-xa",
    privateRoute: true,
    permissionCode: bao_cao_kinh_doanh.f5,
    subItems: [
      {
        subTitle: "Báo cáo hệ thống đo xa",
        subPath: "/bao-cao-he-thong-do-xa",
        subComponent: BaoCaoHeThongDoXa,
        permissionCode: bao_cao_kinh_doanh.f5,
      },
    ],
  },
  // bao cao cap dien
  {
    title: "Báo cáo cấp điện",
    path: mainPath + "/bao-cao-cap-dien",
    privateRoute: true,
    permissionCode: bao_cao_kinh_doanh.f6,
    subItems: [
      {
        subTitle: "Tổng hợp trang web cấp điện",
        subPath: "/tong-hop-trang-web-cap-dien",
        subComponent: TongHopTrangWebCapDien,
        permissionCode: bao_cao_kinh_doanh.f6,
      },
      {
        subTitle: "Báo cáo chi tiết cấp điện",
        subPath: "/bao-cao-chi-tiet-cap-dien",
        subComponent: BaoCaoChiTietCapDien,
        permissionCode: bao_cao_kinh_doanh.f6,
      },
    ],
  },
  // bao cao theo doi dich vu khach hang
  {
    title: "Báo cáo theo dõi dịch vụ khách hàng",
    path: mainPath + "/bao-cao-theo-doi-dich-vu-khach-hang",
    privateRoute: true,
    permissionCode: bao_cao_kinh_doanh.f9,
    subItems: [
      {
        subTitle: "Báo cáo theo dõi công tác dịch vụ khách hàng trên CMIS3",
        subPath: "/bao-cao-theo-doi-cong-tac-dich-vu-khach-hang-tren-cmis3",
        subComponent: BaoCaoTheoDoiCMIS3,
        permissionCode: bao_cao_kinh_doanh.f9,
      },
      {
        subTitle: "Báo cáo dịch vụ điện tử",
        subPath: "/bao-cao-dich-vu-dien-tu",
        subComponent: BaoCaoDichVuDienTu,
        permissionCode: bao_cao_kinh_doanh.f9,
      },
      {
        subTitle: "Báo cáo dịch vụ công",
        subPath: "/bao-cao-dich-vu-cong",
        subComponent: BaoCaoDichVuCong,
        permissionCode: bao_cao_kinh_doanh.f9,
      },
      {
        subTitle: "Báo cáo kinh doanh mới",
        subPath: "/bao-cao-kinh-doanh-moi",
        subComponent: BaoCaoKinhDoanhMoi,
        permissionCode: bao_cao_kinh_doanh.f9,
      },
      {
        subTitle: "Báo cáo khách hàng sử dụng hệ sinh thái",
        subPath: "/bao-cao-khach-hang-su-dung-he-sinh-thai",
        subComponent: BaoCaoKHSuDungHeSinhThaiMoi,
        permissionCode: bao_cao_kinh_doanh.f9,
      },
    ],
  },
  // bao cao kh phien 9
  {
    title: "Báo cáo khách hàng phiên 9",
    path: mainPath + "/bao-cao-khach-hang-phien-9",
    privateRoute: true,
    subItems: [],
    component: BaoCaoKHPhien9,
    permissionCode: bao_cao_kinh_doanh.f8,
  },
  // bao cao theo doi phat trien khach hang
  {
    title: "Báo cáo theo dõi phát triển khách hàng",
    path: mainPath + "/bao-cao-theo-doi-phat-trien-khach-hang",
    privateRoute: true,
    subItems: [],
    component: BCTDPhatTrienKhachHang,
    permissionCode: bao_cao_kinh_doanh.f7,
  },
  // tin nhan sms
  {
    title: "Báo cáo tình hình sms",
    path: mainPath + "/bao-cao-tinh-hinh-sms",
    privateRoute: true,
    permissionCode: bao_cao_kinh_doanh.f10,
    subItems: [
      {
        subTitle: "Tin nhắn mo_mt",
        subPath: "/tin-nhan-mo-mt",
        subComponent: TinNhanMoMT,
        permissionCode: bao_cao_kinh_doanh.f10,
      },
      {
        subTitle: "Tin nhắn chủ động mt",
        subPath: "/tin-nhan-chu-dong-mt",
        subComponent: TinNhanChuDongMT,
        permissionCode: bao_cao_kinh_doanh.f10,
      },
      {
        subTitle: "Tìm kiếm",
        subPath: "/tim-kiem",
        subComponent: TimKiem,
        permissionCode: bao_cao_kinh_doanh.f10,
      },
    ],
  },
  // bao cao ghi chi so
  {
    title: "Báo cáo ghi chỉ số",
    path: mainPath + "/bao-cao-ghi-chi-so",
    privateRoute: true,
    permissionCode: bao_cao_kinh_doanh.f11,
    subItems: [
      {
        subTitle: "Báo cáo ghi chỉ số",
        subPath: "/bao-cao-ghi-chi-so",
        subComponent: BaoCaoGhiChiSo,
        permissionCode: bao_cao_kinh_doanh.f11,
      },
      {
        subTitle: "Báo cáo tổng hợp số lượng ảnh GCS",
        subPath: "/bao-cao-tong-hop-so-luong-anh-gcs",
        subComponent: BCTongHopSoLuongAnhGCS,
        permissionCode: bao_cao_kinh_doanh.f10,
      },
      {
        subTitle: "Báo cáo tổng hợp số lượng ảnh GCS",
        subPath: "/bao-cao-tong-hop-so-luong-anh-gcs",
        subComponent: BCTongHopSoLuongAnhGCS,
        permissionCode: bao_cao_kinh_doanh.f10,
      },
    ],
  },
  // bao cao email cskh
  {
    title: "Báo cáo tình hình Email",
    path: mainPath + "/bao-cao-tinh-hinh-email",
    privateRoute: true,
    permissionCode: bao_cao_kinh_doanh.f12,
    subItems: [
      {
        subTitle: "Báo cáo Email CSKH",
        subPath: "/bao-cao-email-cskh",
        subComponent: BaoCaoEmailCSKH,
        permissionCode: bao_cao_kinh_doanh.f12,
      },
    ],
  },
  // bao cao ton that
  {
    title: "Báo cáo tổn thất",
    path: mainPath + "/bao-cao-ton-that",
    privateRoute: true,
    permissionCode: bao_cao_kinh_doanh.f13,
    subItems: [
      {
        subTitle: "Báo cáo thương phẩm",
        subPath: "/bao-cao-thuong-pham",
        subComponent: BaoCaoThuongPham,
        permissionCode: bao_cao_kinh_doanh.f13,
      },
    ],
  },
  // bao cao web evn
  {
    title: "Báo cáo web evn hà nội",
    path: mainPath + "/bao-cao-web-evn-hanoi",
    privateRoute: true,
    subItems: [],
    component: BaoCaoWebEVN,
    permissionCode: bao_cao_kinh_doanh.f16,
  },
  // bao cao thống kê thông tin liên hệ liên quan đến khách hàng
  {
    title: "Báo cáo thống kê thông tin liên hệ liên quan đến khách hàng",
    path: mainPath + "/thong-ke-thong-tin-lien-he-lien-quan-den-khach-hang",
    privateRoute: true,
    subItems: [
      {
        subTitle:
          "Thống kê thông tin liên hệ liên quan đến khách hàng của công ty điện lực",
        subPath: "/thong-ke-thong-tin-lien-he-lq-den-kh-cua-ctdl",
        subComponent: TKThongTinLienHeLQDenKhCuaCTDL,
        permissionCode: bao_cao_kinh_doanh.f18,
      },
      {
        subTitle:
          "Thống kê thông tin liên hệ liên quan đến khách hàng do trung tâm cskh cập nhật",
        subPath: "/thong-ke-thong-tin-lien-he-lq-den-kh-do-tt-cskh-cap-nhat",
        subComponent: TKThongTinLienHeLQDenKHDoTTCSKHCapNhat,
        permissionCode: bao_cao_kinh_doanh.f18,
      },
      {
        subTitle:
          "Thống kê thông tin liên hệ liên quan đến khách hàng của các công ty điện lực và trung tâm cskh",
        subPath:
          "/thong-ke-thong-tin-lien-he-lq-den-kh-cua-cac-ctdl-va-tt-cskh",
        subComponent: TKThongTinLienHeLQDenKHCuaCacCTDLVaCSKH,
        permissionCode: bao_cao_kinh_doanh.f18,
      },
      {
        subTitle:
          "Tổng hợp số liệu thông tin liên hệ liên quan đến khách hàng theo từng công ty điện lực của toàn tổng công ty",
        subPath:
          "/thsl-thong-tin-lien-he-lq-den-kh-theo-tung-ctdl-cua-toan-tong-ct",
        subComponent: TKThongTinLienHeLQDenKHTheoTungCTDLCuaToanTCT,
        permissionCode: bao_cao_kinh_doanh.f18,
      },
    ],
    permissionCode: bao_cao_kinh_doanh.f18,
  },
  // Nghiên cứu phụ tải
  {
    title: "Nghiên cứu phụ tải",
    path: mainPath + "/nghien-cuu-phu-tai",
    privateRoute: true,
    subItems: [
      {
        subTitle: "Báo cáo khách hàng nghiên cứu phụ tải",
        subPath: "/khach-hang-nghien-cuu-phu-tai",
        subComponent: BaoCaoPhanHeHoaDonNghienCuuPhuTai,
        permissionCode: bao_cao_kinh_doanh.f191,
      },
      {
        subTitle: "Công suất được yêu cầu sẽ tiết giảm của evnhanoi",
        subPath: "/cong-suat-duoc-yeu-cau-se-tiet-giam-cua-evnhanoi",
        subComponent: CongSuatDuocYCSeTietGiamEVNHaNoi,
        // permissionCode: bao_cao_kinh_doanh.f1911,
        permissionCode: bao_cao_kinh_doanh.f19,
      },
      {
        subTitle: "Ngày được tính để đưa ra biểu đồ cơ sở",
        subPath: "/ngay-duoc-tinh-de-dua-ra-bieu-do-co-so",
        subComponent: NgayDuocTinhDeDuaRaBDCoSo,
        permissionCode: bao_cao_kinh_doanh.f19,
      },
      {
        subTitle: "Danh mục điểm đo DRMS",
        subPath: "/danh-muc-diem-do-drms",
        subComponent: DanhMucDiemDoDRMS,
        permissionCode: bao_cao_kinh_doanh.f19,
      },
    ],
    // permissionCode: bao_cao_kinh_doanh.f19,
  },
];

export default routes;