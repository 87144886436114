import { Tooltip } from "antd";
import React from "react";

function BieuDoTheoDonViQuanLy({ tenDonVi, quantity, total }) {
  const barWidth = (quantity / total) * 100;
  return (
    <div className="bieu-do-theo-don-vi-quan-ly-bar-container">
      <div className="bieu-do-theo-don-vi-quan-ly-bar-container-header">
        <span>{tenDonVi}</span>
        <span>{quantity}</span>
      </div>
      <Tooltip title={`${quantity}`}>
        <div className="bieu-do-theo-don-vi-quan-ly-bar">
          <div style={{ width: `${barWidth}%` }}></div>
        </div>
      </Tooltip>
    </div>
  );
}

export default BieuDoTheoDonViQuanLy;
