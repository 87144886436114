import LoadingComponent from "@components/Loading";
import { Button, Col, Form, Input, Modal, Row } from "antd";

const { TextArea } = Input;
export default function TraLaiApGiaModal(props) {
  const { loading, visible, closeModal, handleSubmit, form } = props;
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title="Trả lại áp giá"
        visible={visible}
        onCancel={closeModal}
        footer={null}
      >
        <h4>
          Hồ sơ của bạn sẽ được cập nhật trạng thái về Chờ chuyển áp giá, bạn có
          chắc chắn muốn trả lại?
        </h4>
        <Form
          form={form}
          name="form-tra-lai"
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row
            gutter={24}
            style={{
              marginTop: "24px",
            }}
          >
            {/* <span>
              Lý do trả lại<span style={{ color: "red" }}>*</span>
            </span> */}
            <Col span={24}>
              <Form.Item
                name="lyDoTraLai"
                label="Lý do trả lại"
                rules={[
                  {
                    required: true,
                    message: "Lý do trả lại không được để trống",
                    whitespace: true,
                  },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row gutter={24} justify="center">
            <Button
              key="back"
              onClick={closeModal}
              className="button-closed mr-24px"
            >
              Đóng lại
            </Button>
            <Button
              key="submit"
              type="primary"
              className="button-primary mr-24px"
              htmlType="submit"
              form="form-tra-lai"
            >
              Trả lại
            </Button>
          </Row>
        </Form>
      </Modal>
    </LoadingComponent>
  );
}
