import { authPostData } from "@utils/request";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";

// Từ chối kí
export const handleTuChoiKyService = (
  hoSoId,
  setLoadingTuChoi,
  closeModal,
  setFetchDataTable
) => {
  authPostData({
    url: Endpoint.LANH_DAO_TU_CHOI_KY_QUYET_TOAN,
    setLoading: setLoadingTuChoi,
    method: METHOD_POST,
    payload: hoSoId,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        closeModal();
      }
    },
  });
};

//Quyết toán USB
export const handleQuyetToanUSBService = (
  hoSoId,
  setLoadingQuyetToanUSB,
  closeModal,
  setFetchDataTable
) => {
  authPostData({
    url: Endpoint.LANH_DAO_DUYET_QUYET_TOAN_USB,
    setLoading: setLoadingQuyetToanUSB,
    method: METHOD_POST,
    payload: hoSoId,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        closeModal();
      }
    },
  });
};

//Quyết toán USB
export const handleQuyetToanHSMService = (
  hoSoId,
  setLoadingQuyetToanHSM,
  closeModal,
  setFetchDataTable
) => {
  authPostData({
    url: Endpoint.LANH_DAO_DUYET_QUYET_TOAN_HSM,
    method: METHOD_POST,
    payload: hoSoId,
    setLoading: setLoadingQuyetToanHSM,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        closeModal();
      }
    },
  });
};
