import React, { Fragment, memo, useState } from "react";
import { Button, Modal, Row, Col, Table } from "antd";
import { TableLSStyled } from "./css-styled";
import LoadingComponent from "@components/Loading";
function ChiTietCongTo({
  visible,
  closeModal,
  recordDetail
}) {
  const [loading,] = useState(false);
  const columns = [
    {
      title: "BCS",
      dataIndex: "bcs",
      key: "bcs",
      render: (text) => <span>{text}</span>,
      width: "4%",
    },
    {
      title: "Chỉ số tháo",
      dataIndex: "chiSoCMIS",
      key: "chiSoCMIS",
      render: (text) => (
        <div className="table-text-right">{text}</div>
      ),
      width: "5%",
    },
    {
      title: "Chỉ số HES",
      dataIndex: "chiSoHes",
      key: "chiSoHes",
      render: (text) => (
        <div className="table-text-right">{text}</div>
      ),
      width: "5%",
    },
    {
      title: "Chỉ số X5",
      dataIndex: "chiSoX5",
      key: "chiSoX5",
      render: (text) => (
        <div className="table-text-right">{text}</div>
      ),
      width: "5%",
    },
    {
      title: "HSN tháo",
      render: () => (
        <div className="table-text-right">{recordDetail.heSoNhan}</div>
      ),
      width: "4%",
    },
    {
      title: "% chênh lệch",
      dataIndex: "chenhLechSanLuong",
      key: "chenhLechSanLuong",
      render: (text) => (
        <div className="table-text-right">{text}</div>
      ),
      onCell: (record) => ({
        record,
        style: record.chenhLechSanLuong !== 0 ? {
          color: "red",
          fontWeight: "bold"
        } : {},
      }),
      width: "7%",
    },
  ];
  return (
    <Fragment>
      <Modal
        title={"Chi tiết công tơ tháo"}
        visible={visible}
        onCancel={closeModal}
        footer={null}
        width="70%"
      >
        <LoadingComponent loading={loading}>
          <Row gutter={24}>
            <Col md={6}>
              <span>Mã điểm đo: </span>
            </Col>
            <Col md={6}>
              <span>{recordDetail.maDiemDo}</span>
            </Col>
            <Col md={6}>
              <span>Mã công tơ: </span>
            </Col>
            <Col md={6}>
              <span>{recordDetail.maThietBi}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={6}>
              <span>Số biên bản: </span>
            </Col>
            <Col md={6}>
              <span>{recordDetail.maBienBan}</span>
            </Col>
            <Col md={6}>
              <span>Số pha: </span>
            </Col>
            <Col md={6}>
              <span>{recordDetail.soPha}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={6}>
              <span>Ngày tháo: </span>
            </Col>
            <Col md={6}>
              <span>{recordDetail.ngayBienDongCT}</span>
            </Col>
            <Col md={6}>
              <span>Ngày kiểm định: </span>
            </Col>
            <Col md={6}>
              <span>{recordDetail.ngayKiemDinh}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={6}>
              <span>Lý do tháo: </span>
            </Col>
            <Col md={6}>
              <span>{recordDetail.tenLyDo}</span>
            </Col>
            <Col md={6}>
              <span>Năm sản xuất: </span>
            </Col>
            <Col md={6}>
              <span>{recordDetail.namSanXuat}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={6}>
              <span>Mã khách hàng: </span>
            </Col>
            <Col md={6}>
              <span>{recordDetail.maKhachHang}</span>
            </Col>
            <Col md={6}>
              <span>Tên khách hàng: </span>
            </Col>
            <Col md={6}>
              <span>{recordDetail.tenKhachhang}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={6}>
              <span>Địa chỉ: </span>
            </Col>
            <Col md={18}>
              <span>{recordDetail.diaChi}</span>
            </Col>
          </Row>
          <br />
          <TableLSStyled>
            <Table
              header={"Chi tiết biến động"}
              dataSource={recordDetail.danhSachBoChiSo}
              columns={(columns)}
              loading={loading}
              bordered
              pagination={false}
            />
          </TableLSStyled>
          <Row gutter={24} style={{ marginTop: "30px" }}>
            <Col md={6}>
              <span>Ngày gửi kiểm định: </span>
            </Col>
            <Col md={6}>
              <span>{recordDetail.ngayGui}</span>
            </Col>
            <Col md={6}>
              <span>Ngày nhận: </span>
            </Col>
            <Col md={6}>
              <span>{recordDetail.ngayNhap}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={6}>
              <span>Người nhận: </span>
            </Col>
            <Col md={6}>
              <span>{recordDetail.maNhanVienNhan}</span>
            </Col>
            <Col md={6}>
              <span>Kết quả giao nhận: </span>
            </Col>
            <Col md={6}>
              {
                recordDetail.isSelected === "2" ?
                  <div style={{ fontWeight: "bold", color: "red" }} >{"Không đạt"}</div> :
                  recordDetail.isSelected === "1" ?
                    <div style={{ fontWeight: "bold", color: "#1915eb" }}>{"Đạt"}</div> :
                    <div></div>
              }
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={6}>
              <span>Mã kho nhận: </span>
            </Col>
            <Col md={6}>
              <span>{recordDetail.maKhoNhan}</span>
            </Col>
          </Row>
          <Row gutter={24} justify="center" style={{ marginTop: "30px" }}>
            <Button
              type="primary"
              className="btn-bg-yellow"
              loading={loading}
              onClick={closeModal}
            >
              {"Đóng lại"}
            </Button>
          </Row>
        </LoadingComponent>
      </Modal>
    </Fragment>
  );
}
export default memo(ChiTietCongTo);
