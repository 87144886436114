import { authGetData, authPutData, downloadFile } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { alertMessage, buildQueryString, parseParams } from "@utils/function";
import { STATUSCODE_200 } from "@utils/constants";

export const getDonVi = (setLoading, setData) => {
  authGetData({
    url: `${Endpoint.GET_UNIT_LIST}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getDoiTruongPhanCongDoi = (setLoading, setData) => {
  authGetData({
    url: `${Endpoint.CRM_DOI_TRUONG_PHAN_CONG_DOI}?`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getDanhSachYeuCau = (
  setListLoading,
  setData,
  query,
  filterConditions,
  setTotal
) => {
  authGetData({
    url: `${Endpoint.CRM_DOI_TRUONG_DANH_SACH_YEU_CAU}?${query}`,
    setLoading: setListLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              STT:
                (filterConditions.pageIndex - 1) * filterConditions.pageSize +
                (index + 1),
            };
          })
        );
        setTotal(res.paging.totalCount);
      }
    },
  });
};

export const getPhanCongNguoiKhaoSat = (setLoading, setData, id) => {
  authGetData({
    url: `${Endpoint.CRM_DOI_TRUONG_PHAN_CONG_NGUOI_KHAO_SAT}?DoiId=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getPDFBienBanKH = (detail, onComplete, setLoading, t) => {
  authGetData({
    url: `${Endpoint.IN_BIEN_BAN_QUYET_TOAN_KH}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        onComplete(res.data);
      } else {
        onComplete("error");
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};
export const getExcelBienBanKH = (detail, onComplete, setLoading, t) => {
  authGetData({
    url: `${Endpoint.IN_BIEN_BAN_QUYET_TOAN_KH_EXCEL}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        onComplete(res.data);
      } else {
        onComplete(0);
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const downLoadBienBan = (detail, setLoading) => {
  downloadFile({
    endpoint: `${Endpoint.IN_BIEN_BAN_QUYET_TOAN_KH_EXCEL}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    setLoading,
  });
};

export const putDoiTruongPhanCong = (
  payload,
  setLoading,
  setListLoading,
  setVisiblePhanCongModal,
  location,
  filterConditions,
  setData,
  setTotal,
  setSelectedRowKeys,
  form_modal,
  setDataIds
) => {
  authPutData({
    url: `${Endpoint.CRM_DOI_TRUONG_PHAN_CONG}`,
    method: "PUT",
    payload,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setVisiblePhanCongModal(false);
        const query =
          Object.keys(parseParams(location.search)).length > 0
            ? buildQueryString(parseParams(location.search))
            : buildQueryString(filterConditions);
        getDanhSachYeuCau(
          setListLoading,
          setData,
          query,
          filterConditions,
          setTotal
        );
        setSelectedRowKeys([]);
        form_modal.resetFields();
        setDataIds([]);
      }
    },
  });
};
