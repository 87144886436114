import { buildQueryString, parseParams, getErrorMessage } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData, authDeleteData, authPostFileData } from "@utils/request";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { alertMessage } from "@utils/function"

export const handleListData = (
  location,
  filterConditions,
  setLoading,
  setDataPhanCong,
  setTotalCount,
  setSelectedRowKeys
) => {
  let url = "";
  url = `${Endpoint.AG_PKD_LIST_YC_PHAN_CONG}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      setSelectedRowKeys([]);
      if (res.data !== null) {
        setDataPhanCong(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataPhanCong([]);
    },
  });
};
export const handleSubmitPhanCong = (
  doiId,
  setLoading,
  handleCloseModalPC,
  setFetchDataTable,
  setSelectedRowKeys,
  selectedRowKeys,
  form

) => {
  authPostData({
    url: Endpoint.AG_PKD_PHAN_CONG,
    method: METHOD_POST,
    payload: {
      doiId: doiId,
      ids: selectedRowKeys,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModalPC();
        setSelectedRowKeys([]);
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleListNVAG = (
  user,
  setLoadingTenNV,
  setDataNV,
) => {
  authGetData({
    url: `${Endpoint.AG_LIST_NV_PHAN_CONG}?&DonViId=${user.unitId}`,
    setLoading: setLoadingTenNV,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataNV(res.data);
      } else setDataNV(res.data);
    },
  });
};
export const handleHuyPhanCong = (id, setLoading, getListData, t) => {
  authDeleteData({
    url: `${Endpoint.AG_PKD_HUY_PHAN_CONG}/${id}`,
    setLoading,
    content: `Bạn chắc chắn muốn hủy phân công đội?`,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) getListData();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

export const submitImportPC = (
  setLoading,
  file,
  userId,
  doiId,
  uyQuyen,
  setFetchDataTable,
  handleCloseImport,
  setDataResponse,
  loaiKH
) => {
  authPostFileData({
    url: Endpoint.AG_IMPORT_PC,
    method: METHOD_POST,
    payload: {
      excelFile: file,
      loaiKhachHang: loaiKH,
      doiId: doiId,
      userId: userId,
      uyQuyenId: uyQuyen
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchDataTable(true);
        setDataResponse(res.data);
        if (res.data?.fileLoi) {
          function DownloadExcel() {
            window.location.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + res.data?.fileLoi
            }
            DownloadExcel();
          }
        handleCloseImport();
      }
    },
  });
};

export const handleListApGiaUyQuyen = (
  setLoading,
  unit,
  setDataApGiaUyQuyen,
) => {
  authGetData({
    url: `${Endpoint.COMMON_DM_LIST_AP_GIA_UY_QUYEN}?DonViId=${unit}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataApGiaUyQuyen(res.data);
      } else setDataApGiaUyQuyen([]);
    },
  });
};

export const handleApGiaUyQuyenMacDinh = (
  setLoading,
  unit,
  setDataApGiaUyQuyenMacDinh,
) => {
  authGetData({
    url: `${Endpoint.COMMON_DM_LIST_AP_GIA_UY_QUYEN_MAC_DINH}?DonViId=${unit}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataApGiaUyQuyenMacDinh(res.data);
      } else setDataApGiaUyQuyenMacDinh();
    },
  });
};

export const submitHuyYeuCau = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys,
) => {
  authPostData({
    url: Endpoint.AG_PKD_HUY_YC,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys.toString()
    },
    setLoading: setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
      }
    },
  });
};

export const submitTraYC = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys,
) => {
  authPostData({
    url: Endpoint.AG_BP_TRA_YC,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys,
      lyDoTraLai: "Bộ phận áp giá trả PKD"
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
      }
    },
  });
};