import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authGetMultiplesRequest } from "@utils/request";
// call api thông tin hồ sơ

export const handleDetailNhatKyHoSo = (setLoading, setDataNhatKy, hoSoId) => {
  authGetData({
    url: Endpoint.DETAIL_HO_SO + "?HoSoId=" + hoSoId,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) setDataNhatKy(res.data.nhatKyHoSo);
      else setDataNhatKy([]);
    },
  });
};
// call api list cấu hình chung tiến trình dịch vụ điện

export const handleListCauHinhChung = (setLoading, setDataDichVuDien) => {
  authGetData({
    url: Endpoint.LIST_CAU_HINH_CHUNG + "?PageSize=-1",
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) setDataDichVuDien(res.data);
      else setDataDichVuDien([]);
    },
  });
};
// call api xem lại phương án cấp điện

export const handleApiOpenXemLaiPACD = (
  detail,
  setDataPAXemLai,
  setVisibleXemLaiPACD,
  visibleXemLaiPACD
) => {
  const endpoints = [
    `${Endpoint.KS_GET_PHUONG_AN_CAP_DIEN}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    `${Endpoint.KS_TONG_CONG_SUAT}?HoSoId=${detail?.hoSoId ?? detail?.id}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading: () => false,
    onSuccess: (res) => {
      if (res[0]?.data !== null && res[1]?.data !== null) {
        setDataPAXemLai({ ...res[0].data.data, ...res[1].data.data });
        setVisibleXemLaiPACD(!visibleXemLaiPACD);
      }
    },
  });
};

export const checkTraHoSoService = (detail, setVisibleTraHoSo, visibleTraHoSo) => {
  const url = `${Endpoint.CHECK_TRA_HO_SO}?HoSoId=${
    detail?.hoSoId ?? detail?.id
  }`;
  authGetData({
    url,
    setLoading: () => false,
    onSuccess: (res) => {
      if (res && res.data && res.statusCode === STATUSCODE_200) {
        setVisibleTraHoSo(!visibleTraHoSo);
      }
    },
  });
};
