import { isNumber, numberWithNoSpaces } from "@utils/function";

import { BCS_GNDN } from "@utils/constants";
import { isEmpty, isNull } from "lodash";
import { updateDanhSachDiemDoCoChiSoNhapSai } from "@state/giao-nhan-dien-nang/quan-ly-chi-so/reducer";
import { fixedNumber } from "./calculate";

export function validateSanLuong({ keyId, dataSourceOriginal, record = {}, dispatch }) {
  let findItem = {};

  if (isEmpty(record)) {
    findItem = dataSourceOriginal.find((item) => item.id === keyId);
  } else {
    findItem = { ...record };
  }
  const boChiSo = dataSourceOriginal.filter((item) => item.diemDoId === findItem?.diemDoId);

  // Sản lượng Chỉ số giao
  let sanLuongBT = 0;
  let sanLuongCD = 0;
  let sanLuongTD = 0;
  let sanLuongSG = 0;

  // Sản lượng Chỉ số nhận
  let sanLuongBN = 0;
  let sanLuongCN = 0;
  let sanLuongTN = 0;
  let sanLuongSN = 0;

  // Kết quả validate
  let resultBCSGiao; // Bộ chỉ số giao
  let resultBCSNhan; // Bộ chỉ số nhận

  for (let index = 0; index < boChiSo.length; index++) {
    const element = boChiSo[index];
    const sanLuong = dataSourceOriginal[index].sanLuong;
    switch (element.boSo) {
      case BCS_GNDN.BT:
        sanLuongBT = sanLuong;
        break;
      case BCS_GNDN.CD:
        sanLuongCD = sanLuong;
        break;
      case BCS_GNDN.TD:
        sanLuongTD = sanLuong;
        break;
      case BCS_GNDN.SG:
        sanLuongSG = sanLuong;
        break;
      case BCS_GNDN.BN:
        sanLuongBN = sanLuong;
        break;
      case BCS_GNDN.CN:
        sanLuongCN = sanLuong;
        break;
      case BCS_GNDN.TN:
        sanLuongTN = sanLuong;
        break;
      case BCS_GNDN.SN:
        sanLuongSN = sanLuong;
        break;
      default:
    }
  }
  if (isNumber(sanLuongBT) || isNumber(sanLuongCD) || isNumber(sanLuongTD) || isNumber(sanLuongSG)) {
    const total = fixedNumber((+sanLuongBT || 0) + (+sanLuongCD || 0) + (+sanLuongTD || 0));
    if (sanLuongSG !== total) {
      resultBCSGiao = false;
    } else {
      resultBCSGiao = true;
    }
  }

  if (isNumber(sanLuongBN) || isNumber(sanLuongCN) || isNumber(sanLuongTN) || isNumber(sanLuongSN)) {
    const total = fixedNumber((+sanLuongBN || 0) + (+sanLuongCN || 0) + (+sanLuongTN || 0));
    if (sanLuongSN !== total) {
      resultBCSNhan = false;
    } else {
      resultBCSNhan = true;
    }
  }
  let arrChiSoNhapSai = [];
  if (resultBCSNhan && resultBCSGiao) {
    arrChiSoNhapSai = arrChiSoNhapSai.filter((e) => e !== findItem?.boChiSoId);
  } else {
    arrChiSoNhapSai = [...new Set([...arrChiSoNhapSai, findItem?.boChiSoId])];
  }
  dispatch(updateDanhSachDiemDoCoChiSoNhapSai([...arrChiSoNhapSai]));
}

export function setClassErrorSanLuong({dataSource, record }) {

  const boChiSo = dataSource.filter((item) => item.diemDoId === record?.diemDoId);

  // Sản lượng Chỉ số giao
  let sanLuongBT = 0;
  let sanLuongCD = 0;
  let sanLuongTD = 0;
  let sanLuongSG = 0;

  // Sản lượng Chỉ số nhận
  let sanLuongBN = 0;
  let sanLuongCN = 0;
  let sanLuongTN = 0;
  let sanLuongSN = 0;

  // Kết quả validate
  for (let index = 0; index < boChiSo.length; index++) {
    const element = boChiSo[index];
    const idx = dataSource.findIndex((e) => e.id === element.id);
    const valueFieldChiSoCuoi = dataSource[idx].chiSoCuoi;
    const valueFieldSanLuong = dataSource[idx].sanLuong;

    const chiSoDau = +numberWithNoSpaces(dataSource[idx].chiSoDau);
    const chiSoCuoi =
      !isNull(valueFieldChiSoCuoi) && !isEmpty(valueFieldChiSoCuoi) ? +numberWithNoSpaces(valueFieldChiSoCuoi) : null;

    let sanLuong =
      !isNull(valueFieldSanLuong) && !isEmpty(valueFieldSanLuong) ? +numberWithNoSpaces(valueFieldSanLuong) : null;
    const heSoNhan = +numberWithNoSpaces(element.heSoNhan);
    if (isNumber(chiSoCuoi)) {
      sanLuong = parseFloat(((parseFloat(chiSoCuoi) - chiSoDau) * heSoNhan).toFixed(1));
    }

    switch (element.boSo) {
      case BCS_GNDN.BT:
        sanLuongBT = sanLuong;
        break;
      case BCS_GNDN.CD:
        sanLuongCD = sanLuong;
        break;
      case BCS_GNDN.TD:
        sanLuongTD = sanLuong;
        break;
      case BCS_GNDN.SG:
        sanLuongSG = sanLuong;
        break;
      case BCS_GNDN.BN:
        sanLuongBN = sanLuong;
        break;
      case BCS_GNDN.CN:
        sanLuongCN = sanLuong;
        break;
      case BCS_GNDN.TN:
        sanLuongTN = sanLuong;
        break;
      case BCS_GNDN.SN:
        sanLuongSN = sanLuong;
        break;
      default:
    }
  }

  if (
    record.boSo === BCS_GNDN.BT ||
    record.boSo === BCS_GNDN.CD ||
    record.boSo === BCS_GNDN.TD ||
    record.boSo === BCS_GNDN.SG
  ) {
    if (isNumber(sanLuongBT) || isNumber(sanLuongCD) || isNumber(sanLuongTD) || isNumber(sanLuongSG)) {
      const total = (sanLuongBT || 0) + (sanLuongCD || 0) + (sanLuongTD || 0);
      if ((sanLuongSG || 0) !== total) {
        // hiển thị báo lỗi ở từng bộ chỉ số khi công thức không chính xác
        return " error-quantity";
      }
      return "";
    }
  }

  if (
    record.boSo === BCS_GNDN.BN ||
    record.boSo === BCS_GNDN.CN ||
    record.boSo === BCS_GNDN.TN ||
    record.boSo === BCS_GNDN.SN
  ) {
    if (isNumber(sanLuongBN) || isNumber(sanLuongCN) || isNumber(sanLuongTN) || isNumber(sanLuongSN)) {
      const total = sanLuongBN + sanLuongCN + sanLuongTN;
      if (sanLuongSN !== total) {
        // hiển thị báo lỗi ở từng bộ chỉ số khi công thức không chính xác
        return " error-quantity";
      } else {
        // xóa báo lỗi
        return "";
      }
    }
  }
  return "";
}

export function setClassErrorChiSoCuoi({ dataSource, index }) {
  const chiSoCuoi = dataSource[index].chiSoCuoi;
  const chiSoDau = dataSource[index].chiSoDau;
  if (isNull(chiSoCuoi) || isEmpty(chiSoCuoi)) {
    return "";
  }
  if (+numberWithNoSpaces(chiSoCuoi) < +numberWithNoSpaces(chiSoDau)) {
    return " error-last-stat";
  }
  return "";
}
