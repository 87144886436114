import { METHOD_PUT } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { parseParams } from "@utils/function";
import { authPostData } from "@utils/request";
import { Button, Checkbox, Col, Form, Input, Modal, Row, Spin } from "antd";
import React, { memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

function ModalThongTinDoiTuong({ doituong, getData, visible, onCancel, form }) {
  const [loading, setLoading] = useState(false);
  const [noiDung, setNoiDung] = useState();
  const [phanLoai, setPhanLoai] = useState();
  const location = useLocation();
  const [thuTuHienThi, setThuTuHienThi] = useState();
  const [checkBoxWeb, setCheckBoxWeb] = useState();
  const [checkBoxSMSTDL1, setCheckBoxSMSTDL1] = useState();
  const [checkBoxCuPhap, setCheckCuPhap] = useState();
  const [checkBoxZalo, setCheckBoxZalo] = useState();
  const [checkBoxCSKH, setCheckBoxCSKH] = useState();
  const [checkBoxSMSKHAC, setCheckBoxSMSKHAC] = useState(false);
  const [checkBoxEmail, setCheckBoxEmail] = useState(false);
  const [filterConditions, setFilterConditions] = useState({
    ...parseParams(location.search),
  });

  useEffect(() => {
    form.resetFields();
    setFilterConditions(() => ({}));
  }, []);

  useEffect(() => {
    if (doituong && doituong.stt) {
      form.setFieldsValue({
        [`stt`]: doituong?.stt,
      });
    }
  }, [doituong]);

  useEffect(() => {
    setFilterConditions((oldState) => ({
      ...oldState,
      ...doituong,
      setNoiDung,
    }));
  }, [doituong, setFilterConditions]);

  useEffect(() => {
    setNoiDung(doituong?.noiDung);
    setPhanLoai(doituong?.phanLoai);
    setThuTuHienThi(doituong?.stt);
    setCheckBoxWeb(doituong?.isDoiTuongNhanTinWeb);
    setCheckBoxSMSTDL1(doituong?.isBaoCaoDoiSoatSMSLan1);
    setCheckCuPhap(doituong?.isDoiTuongCuPhap);
    setCheckBoxZalo(doituong?.isBaoCaoDoiSoatZalo);
    setCheckBoxCSKH(doituong?.isBaoCaoDoiSoatAppCSKH);
    setCheckBoxSMSKHAC(doituong?.isBaoCaoDoiSoatSMSDichVuKhac);
    setCheckBoxEmail(doituong?.isBaoCaoDoiSoatEmail);
  }, [doituong]);

  const onChangeNoiDung = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      noiDung: e.target.value.trim(),
    }));
  };
  const onChangeSTT = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      stt: e.target.value,
    }));
  };
  const onChangePhanLoai = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      phanLoai: e.target.value,
    }));
  };

  const onChangeCheckboxWeb = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      isDoiTuongNhanTinWeb: e.target.checked,
    }));
  };
  const onChangeCheckboxSMS_TDL1 = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      isBaoCaoDoiSoatSMSLan1: e.target.checked,
    }));
  };
  const onChangeCheckboxEMAIL = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      isBaoCaoDoiSoatEmail: e.target.checked,
    }));
  };
  const onChangeCheckboxAPP = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      isBaoCaoDoiSoatAppCSKH: e.target.checked,
    }));
  };
  const onChangeCheckboxCUPHAP = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      isDoiTuongCuPhap: e.target.checked,
    }));
  };
  const onChangeCheckboxSMS_DICHVUKHAC = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      isBaoCaoDoiSoatSMSDichVuKhac: e.target.checked,
    }));
  };
  const onChangeCheckboxZalo = (e) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      isBaoCaoDoiSoatZalo: e.target.checked,
    }));
  };

  const onFinish = (values) => {
    authPostData({
      url: `${Endpoint.CSKH_DOITUONG}`,
      method: METHOD_PUT,
      payload: {
        isBaoCaoDoiSoatAppCSKH: filterConditions.isBaoCaoDoiSoatAppCSKH,
        isBaoCaoDoiSoatEmail: filterConditions.isBaoCaoDoiSoatEmail,
        isBaoCaoDoiSoatSMSDichVuKhac:
          filterConditions.isBaoCaoDoiSoatSMSDichVuKhac,
        isBaoCaoDoiSoatSMSLan1: filterConditions.isBaoCaoDoiSoatSMSLan1,
        isBaoCaoDoiSoatZalo: filterConditions.isBaoCaoDoiSoatZalo,
        isDoiTuongCuPhap: filterConditions.isDoiTuongCuPhap,
        isDoiTuongNhanTinWeb: filterConditions.isDoiTuongNhanTinWeb,
        id: doituong.id,
        stt: parseInt(filterConditions?.stt?.toString().trim()),
        phanloai: filterConditions?.phanloai?.trim(),
        noiDung: filterConditions?.noiDung.trim(),
      },
      setLoading,
      onSuccess: (res) => {
        if (res.statusCode === 200) {
          getData();
        }
      },
    });
  };

  console.log("form", form.getFieldValue().noiDung);

  return (
    <Modal
      visible={visible}
      title="Thay đổi thông tin đối tượng"
      onCancel={onCancel}
      width={"70%"}
      footer={null}
    >
      {filterConditions && filterConditions?.id === doituong?.id ? (
        <Container>
          <Spin spinning={loading}>
            <Form
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              colon={false}
            >
              <div style={{ marginBottom: 10 }}>
                <Row>
                  <Col span={24}>
                    {form.getFieldValue().noiDung !==
                    filterConditions?.noiDung ? (
                      <Form.Item
                        name="noiDung"
                        label="Nội dung"
                        labelAlign="left"
                        rules={[
                          {
                            required: true,
                            message: "Nội dung không được để trống",
                          },
                        ]}
                      >
                        {form.setFieldsValue({
                          [`noiDung`]: doituong?.noiDung,
                        })}
                        <Input
                          defaultValue={noiDung}
                          onChange={onChangeNoiDung}
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        name="noiDung"
                        label="Nội dung"
                        labelAlign="left"
                        rules={[
                          {
                            required: true,
                            message: "Nội dung không được để trống",
                          },
                        ]}
                      >
                        <Input
                          defaultValue={noiDung}
                          onChange={onChangeNoiDung}
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
              </div>
              <div style={{ marginBottom: 10 }}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="phanloai"
                      label="Phân loại"
                      labelAlign="left"
                      required
                    >
                      <Input
                        disabled
                        defaultValue={phanLoai}
                        onChange={onChangePhanLoai}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col span={24}>
                  {form.getFieldValue().noiDung !==
                  filterConditions?.noiDung ? (
                    <Form.Item
                      name="stt"
                      label="Thứ tự hiển thị"
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: "Thứ tự hiển thị không được để trống",
                        },
                      ]}
                    >
                      {form.setFieldsValue({
                        [`stt`]: doituong?.stt,
                      })}
                      <Input
                        defaultValue={thuTuHienThi}
                        onChange={onChangeSTT}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="stt"
                      label="Thứ tự hiển thị"
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: "Thứ tự hiển thị không được để trống",
                        },
                      ]}
                    >
                      <Input
                        defaultValue={thuTuHienThi}
                        onChange={onChangeSTT}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={12} offset={6}>
                  <Form.Item name="doituong">
                    <CHECKBOXITEM>
                      <div style={{ minWidth: 300 }}>
                        <div style={{ maxHeight: "30px" }}>
                          <Form.Item name="isDoiTuongNhanTinWeb">
                            <Checkbox
                              defaultChecked={checkBoxWeb}
                              defaultValue
                              onChange={onChangeCheckboxWeb}
                            >
                              {form.setFieldsValue({
                                [`isDoiTuongNhanTinWeb`]:
                                  doituong?.isDoiTuongNhanTinWeb,
                              })}
                              Đối tượng nhắn tin ở web
                            </Checkbox>
                          </Form.Item>
                        </div>
                        <div style={{ maxHeight: "30px" }}>
                          <Form.Item name="isBaoCaoDoiSoatSMSLan1">
                            <Checkbox
                              defaultChecked={checkBoxSMSTDL1}
                              onChange={onChangeCheckboxSMS_TDL1}
                            >
                              {form.setFieldsValue({
                                [`isBaoCaoDoiSoatSMSLan1`]:
                                  doituong?.isBaoCaoDoiSoatSMSLan1,
                              })}
                              Báo cáo đối soát SMS - Tiền điện lần 1
                            </Checkbox>
                          </Form.Item>
                        </div>
                        <div style={{ maxHeight: "30px" }}>
                          <Form.Item name="isBaoCaoDoiSoatEmail">
                            <Checkbox
                              defaultChecked={checkBoxEmail}
                              onChange={onChangeCheckboxEMAIL}
                            >
                              {form.setFieldsValue({
                                [`isBaoCaoDoiSoatEmail`]:
                                  doituong?.isBaoCaoDoiSoatEmail,
                              })}
                              Báo cáo đối soát Email
                            </Checkbox>
                          </Form.Item>
                        </div>
                        <div style={{ maxHeight: "30px" }}>
                          <Form.Item name="isBaoCaoDoiSoatAppCSKH">
                            <Checkbox
                              defaultChecked={checkBoxCSKH}
                              onChange={onChangeCheckboxAPP}
                            >
                              {form.setFieldsValue({
                                [`isBaoCaoDoiSoatAppCSKH`]:
                                  doituong?.isBaoCaoDoiSoatAppCSKH,
                              })}
                              Báo cáo đối soát App CSKH
                            </Checkbox>
                          </Form.Item>
                        </div>
                      </div>
                      <div style={{ minWidth: 300 }}>
                        <div style={{ maxHeight: "30px" }}>
                          <Form.Item name="isDoiTuongCuPhap">
                            <Checkbox
                              name="isDoiTuongCuPhap"
                              defaultChecked={checkBoxCuPhap}
                              onChange={onChangeCheckboxCUPHAP}
                            >
                              {form.setFieldsValue({
                                [`isDoiTuongCuPhap`]:
                                  doituong?.isDoiTuongCuPhap,
                              })}
                              Đối tượng cú pháp
                            </Checkbox>
                          </Form.Item>
                        </div>
                        <div style={{ maxHeight: "30px" }}>
                          <Form.Item name="isBaoCaoDoiSoatSMSDichVuKhac">
                            <Checkbox
                              defaultChecked={checkBoxSMSKHAC}
                              onChange={onChangeCheckboxSMS_DICHVUKHAC}
                            >
                              {form.setFieldsValue({
                                [`isBaoCaoDoiSoatSMSDichVuKhac`]:
                                  doituong?.isBaoCaoDoiSoatSMSDichVuKhac,
                              })}
                              Báo cáo đối soát SMS - Dịch vụ khác
                            </Checkbox>
                          </Form.Item>
                        </div>
                        <div style={{ maxHeight: "30px" }}>
                          <Form.Item name="isBaoCaoDoiSoatZalo">
                            <Checkbox
                              name="isBaoCaoDoiSoatZalo"
                              defaultChecked={checkBoxZalo}
                              onChange={onChangeCheckboxZalo}
                            >
                              {form.setFieldsValue({
                                [`isBaoCaoDoiSoatZalo`]:
                                  doituong?.isBaoCaoDoiSoatZalo,
                              })}
                              Báo cáo đối soát Zalo
                            </Checkbox>
                          </Form.Item>
                        </div>
                      </div>
                    </CHECKBOXITEM>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Row>
                  <Item>
                    <Button style={{ width: 200 }} onClick={onCancel}>
                      Hủy
                    </Button>
                    <Button
                      style={{ width: 200, marginLeft: 40 }}
                      type="primary"
                      htmlType="submit"
                    >
                      Lưu
                    </Button>
                  </Item>
                </Row>
              </Form.Item>
            </Form>
          </Spin>
        </Container>
      ) : null}
    </Modal>
  );
}
export default memo(ModalThongTinDoiTuong);

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
  .ant-form-item {
    margin: 0 !important;
  }
`;
const Item = styled.div`
  width: 1200px;
  padding-left: 33%;
  display: flex;
`;
const CHECKBOXITEM = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;
