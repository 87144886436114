/* eslint-disable array-callback-return */
import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { closeModal } from "@state/system-config/reducer";
import { FORMAT_ENGLISH } from "@utils/constants";
import {
  isNumber,
  numberWithNoSpaces,
  numberWithSpaces,
} from "@utils/function";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
} from "antd";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getDonViGN, handlerSuaPhuongThuc } from "./services";

export default function ContentEditPhuongThuc(props) {
  const {
    detail,
    dataPhuongThucManSuaLeft,
    dataPhuongThucManSuaRight,
    dataChisoTableSua,
    dataChisoTableRightSua,
    setLoadingDanhSach,
    setDataDanhSach,
    getDanhSachDiemDo,
    filterConditions,
  } = props;

  const dataChisoTableRightSuaNew = dataChisoTableRightSua.map((object) => {
    return { ...object, chiSoCuoi: numberWithNoSpaces(object.chiSoCuoi) };
  });

  const [isCheked, setIsChecked] = useState(false);
  const [isChekedRight, setIsCheckedRight] = useState(false);
  const [loading, setLoading] = useState(false);
  const [urlDvgn, setUrlDvgn] = useState([]);
  const [arrayId, setArrayId] = useState([]);
  const [dataSuaPhuongThuc, setDataSuaPhuongThuc] = useState([]);
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  useEffect(() => {
    getDonViGN(setLoading, setUrlDvgn);
  }, []);

  const NumericInput = useCallback((props) => {
    const { onChange, disabledfield, digit, defaultValue } = props;
    const handleChange = (e) => {
      if (disabledfield === "true") return;
      const inputValue = numberWithNoSpaces(e.target.value);

      let regex = /^(\d*)\.?(\d){0,1}$/;
      if (digit === "true") {
        regex = /^-?[0,4]\d*(\.\d+)?$/;
      }
      if (regex.test(inputValue) || !inputValue || inputValue === "-") {
        onChange(inputValue);
      }
    };

    return (
      <Input
        style={{ textAlign: "right" }}
        {...props}
        onChange={handleChange}
        maxLength={25}
      />
    );
  }, []);

  const formatInputNumber = useCallback(
    (event) => {
      const { value, id } = event.target;
      if (isNumber(value)) {
        form.setFieldsValue({
          [`${id}`]: numberWithSpaces(value),
        });
      }
    },
    [form]
  );

  const handlerTinhSanLuong = (e, index) => {
    const count =
      (e - dataChisoTableRightSua[index].chiSoDau) *
      dataChisoTableRightSua[index].hsNhan;
    form.setFieldsValue({
      [`sanLuong-${index}`]: Math.round(count),
    });
    dataChisoTableRightSua[index].chiSoCuoi = e;
    dataChisoTableRightSua[index].sanLuong = count;

    form.setFieldsValue(
      e
        ? {
            [`sanLuong-${index}`]: numberWithSpaces(Math.round(count)),
          }
        : {
            [`sanLuong-${index}`]: "",
          }
    );

    dataChisoTableRightSua[index].chiSoCuoi = e;
    dataChisoTableRightSua[index].sanLuong = count;
  };
  // fetch data vao input có sẵn
  useEffect(() => {
    if (dataPhuongThucManSuaLeft) {
      form.setFieldsValue({
        soBienBanCu: dataPhuongThucManSuaLeft?.soBienBanCu,
        ngayThayDoiCu: dataPhuongThucManSuaLeft?.ngayThayDoiCu,
        lyDoThayDoiCu: dataPhuongThucManSuaLeft?.lyDoThayDoiCu,
        kenhNhan1Cu: dataPhuongThucManSuaLeft?.kenhNhan1Cu,
        kenhNhan2Cu: dataPhuongThucManSuaLeft?.kenhNhan2Cu,
        donViGiaoNhan1Cu: dataPhuongThucManSuaLeft?.donViGiaoNhan1Cu,
        donViGiaoNhan2Cu: dataPhuongThucManSuaLeft?.donViGiaoNhan2Cu,
        kenhGiao1Cu: dataPhuongThucManSuaLeft?.kenhGiao1Cu,
        kenhGiao2Cu: dataPhuongThucManSuaLeft?.kenhGiao2Cu,
        soBienBan_PT: dataPhuongThucManSuaRight?.soBienBanCu,
        lyDoThayDoi: dataPhuongThucManSuaRight?.lyDoThayDoiCu,
        ngayThayDoi: dataPhuongThucManSuaRight?.ngayThayDoiCu,
        donViQuanLyGiaoNhanId1: dataPhuongThucManSuaRight?.donViGiaoNhan1CuId,
        kenhNhan_PT1: dataPhuongThucManSuaRight?.maKenhNhan1Cu,
        kenhGiao_PT1: dataPhuongThucManSuaRight?.maKenhGiao1Cu,
        donViQuanLyGiaoNhanId2: dataPhuongThucManSuaRight?.donViGiaoNhan2CuId,
        kenhNhan_PT2: dataPhuongThucManSuaRight?.maKenhNhan2Cu,
        kenhGiao_PT2: dataPhuongThucManSuaRight?.maKenhGiao2Cu,
      });
    } else {
      form.setFieldsValue({
        donViGiaoNhan2Cu: null,
        kenhGiao2Cu: null,
        kenhNhan2Cu: null,
      });
    }
  }, [
    dataPhuongThucManSuaLeft,
    dataPhuongThucManSuaRight,
    dataSuaPhuongThuc,
    form,
  ]);

  // checkbox giao nhận tay ba trong phương thức cũ
  useEffect(() => {
    if (
      dataPhuongThucManSuaLeft?.donViGiaoNhan2Cu === null ||
      dataPhuongThucManSuaLeft?.kenhGiao2Cu === "" ||
      dataPhuongThucManSuaLeft?.kenhNhan2Cu === undefined
    ) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  }, [dataPhuongThucManSuaLeft, form]);

  useEffect(() => {
    if (
      dataPhuongThucManSuaRight?.donViGiaoNhan2Cu &&
      dataPhuongThucManSuaRight?.maKenhNhan2Cu &&
      dataPhuongThucManSuaRight?.maKenhGiao2Cu
    ) {
      setVisible(true);
      setIsCheckedRight(true);
    } else {
      setVisible(false);
      setIsCheckedRight(false);
    }
  }, [dataPhuongThucManSuaRight]);
  // ẩn đơn vị giao nhân, kênh nhận, kênh giao trong giao nhận tay ba
  const handleChangeCheckBox = () => {
    setVisible(!visible);
    setIsCheckedRight(!isChekedRight);
  };
  // lấy id trong phương thức cũ truyền vào phương thức mới trong api khi nhấn button cập nhật
  useEffect(() => {
    if (dataPhuongThucManSuaRight) {
      const arrayId = [];
      const { id1, id2, id3, id4 } = dataPhuongThucManSuaRight;
      if (id1 !== null) arrayId.push(id1);
      if (id2 !== null) arrayId.push(id2);
      if (id3 !== null) arrayId.push(id3);
      if (id4 !== null) arrayId.push(id4);
      setArrayId(arrayId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPhuongThucManSuaRight]);

  // submit dữ liệu khi nhấn buttion cập nhật
  const onFinish = useCallback(
    (values) => {
      const payload = {
        arrayId,
        diemDoId: detail.id,
        soBienBan_PT: values.soBienBan_PT,
        ngayThayDoi: values.ngayThayDoi,
        lyDoThayDoi: values.lyDoThayDoi,
        kenhNhan_PT1: values.kenhNhan_PT1,
        donViQuanLyGiaoNhanId1: values.donViQuanLyGiaoNhanId1,
        kenhGiao_PT1: values.kenhGiao_PT1,
        donViQuanLyGiaoNhanId2: values.donViQuanLyGiaoNhanId2,
        kenhNhan_PT2: values.kenhNhan_PT2,
        kenhGiao_PT2: values.kenhGiao_PT2,
        danhSachChiSo: dataChisoTableRightSua,
      };
      // gọi sự kiện handler khi nhấn buttion cập nhật
      let validate = false;
      dataChisoTableRightSua.forEach((_, index) => {
        if (
          values[`chiSoCuoi-${index}`] === null ||
          values[`chiSoCuoi-${index}`] === "" ||
          values[`chiSoCuoi-${index}`] === undefined
        ) {
          form.setFields([
            {
              name: `chiSoCuoi-${index}`,
              errors: ["Không được bỏ trống"],
            },
          ]);
          validate = true;
        }
        if (
          dataChisoTableRightSua[index]?.chiSoCuoi <
          dataChisoTableRightSua[index]?.chiSoDau
        ) {
          form.setFields([
            {
              name: `chiSoCuoi-${index}`,
              errors: ["Nhập sai chỉ số"],
            },
          ]);
          validate = true;
        }
      });
      if (!validate) {
        handlerSuaPhuongThuc(
          values,
          setDataSuaPhuongThuc,
          arrayId,
          closeModal,
          setLoading,
          form,
          payload,
          filterConditions,
          setDataDanhSach,
          setLoadingDanhSach,
          dispatch,
          getDanhSachDiemDo
        );
      }
    },
    [
      arrayId,
      detail.id,
      dataChisoTableRightSua,
      form,
      filterConditions,
      setDataDanhSach,
      setLoadingDanhSach,
      dispatch,
      getDanhSachDiemDo,
    ]
  );

  useEffect(() => {
    const newdata = [];
    if (dataChisoTableRightSua) {
      dataChisoTableRightSua.map((item, index) => {
        newdata.push({
          boChiSoId: item.boChiSoId,
          boSo: item.boSo,
          chiSoDau: item.chiSoDau,
          chiSoCuoi: item.chiSoCuoi,
          chiSoId: item.chiSoId,
          soCongTo: item.soCongTo,
          hsNhan: item.hsNhan,
          thuTuUuTien: item.thuTuUuTien,
          [`chiSoCuoi-${index}`]: item[`chiSoCuoi-${index}`],
          [`sanLuong-${index}`]: item[`sanLuong-${index}`],
        });
      });
    }
  }, [dataChisoTableRightSua]);

  const onPressEnter = (e, name) => {
    e.preventDefault();
    document.getElementsByName(name)[0]?.focus();
  };
  // fetch daata thông tin table trái vào table phải
  useEffect(() => {
    if (dataChisoTableRightSua) {
      dataChisoTableRightSua.map((item, index) => {
        return form.setFieldsValue({
          [`chiSoCuoi-${index}`]: numberWithSpaces(item[`chiSoCuoi`]),
          [`sanLuong-${index}`]: numberWithSpaces(item[`sanLuong`]),
        });
      });
    }
  }, [dataChisoTableRightSua, form]);
  // check validate
  const columns_left = [
    {
      title: "Số công tơ",
      dataIndex: "soCongTo",
      key: "soCongTo",
      align: "center",
      width: 100,
    },
    {
      title: "Bộ số",
      dataIndex: "boSo",
      width: 70,
      align: "center",
    },
    {
      title: "Chỉ số đầu",
      dataIndex: "chiSoDau",
      align: "center",
      width: 100,
      render: (text) => {
        return <span>{numberWithSpaces(text)}</span>;
      },
    },
    {
      title: "Chỉ số cuối",
      dataIndex: "chiSoCuoi",
      align: "center",
      width: 100,
      render: (text) => {
        return <span>{numberWithSpaces(text)}</span>;
      },
    },
    {
      title: "HS nhân",
      dataIndex: "hsNhan",
      align: "right",
      width: 70,
    },
    {
      title: "Sản lượng",
      dataIndex: "sanLuong",
      align: "center",
      width: 100,
      render: (text) => {
        return <span>{numberWithSpaces(text)}</span>;
      },
    },
  ];
  const columns_right = [
    {
      title: "Số công tơ",
      dataIndex: "soCongTo",
      key: "soCongTo",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        const rowKeys = Object.keys(record);
        if (rowKeys.find((e) => e === "soCongTo") !== undefined) {
          return (
            <div key={index}>
              <span>{text}</span>
            </div>
          );
        } else {
          return <div>{text}</div>;
        }
      },
    },
    {
      title: "Bộ số",
      dataIndex: "boSo",
      align: "center",
      width: 70,
    },
    {
      title: "Chỉ số đầu",
      dataIndex: `chiSoDau`,
      width: 100,
      align: "right",
      render: (text) => {
        return <span>{numberWithSpaces(text)}</span>;
      },
    },
    {
      title: "Chỉ số chốt",
      dataIndex: `chiSoCuoi`,
      width: 100,
      render: (text, record, index) => {
        return (
          <Form.Item key={index} name={`chiSoCuoi-${index}`}>
            <NumericInput
              defaultValue={numberWithSpaces(text)}
              name={`chiSoCuoi-${index}`}
              onPressEnter={(e) => onPressEnter(e, `chiSoCuoi-${index + 1}`)}
              onBlur={(e) => {
                formatInputNumber(e);
              }}
              onChange={(e) => handlerTinhSanLuong(e, index)}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "HS nhân",
      dataIndex: "hsNhan",
      align: "right",
      width: 70,
    },
    {
      title: "Sản lượng",
      dataIndex: `sanLuong`,
      align: "right",
      width: 100,
      render: (text, record, index) => {
        return (
          <Form.Item name={`sanLuong-${index}`}>
            <Input
              disabled
              style={{ textAlign: "right", color: "#000", fontSize: "16px" }}
            />
          </Form.Item>
        );
      },
    },
  ];

  return (
    <Fragment>
      <ContainerFlex className="container">
        <FormComponent
          layout="horizontal"
          form={form}
          onFinish={onFinish}
          className="flex-wrapper-form"
          style={{ width: "100%" }}
        >
          <div className="container-flex">
            <Spin spinning={loading} wrapperClassName="container-spin">
              <div className="content-flex">
                <div className="title-header">
                  <h2>Thông tin phương thức giao nhận hiện tại</h2>
                </div>
                <div className="content">
                  <div className="label">
                    <p className="label-text">Số biên bản</p>
                    <p className="label-text">Ngày thay đổi</p>
                    <p className="label-text">Lý do thay đổi</p>
                    <p className="label-text">Đơn vị giao nhận cũ</p>
                    <p className="label-text">Kênh nhận công tơ</p>
                    <p className="label-text">Kênh giao công tơ</p>
                  </div>
                  <div className="content-input">
                    <Form.Item
                      name="soBienBanCu"
                      wrapperCol={{ span: 10 }}
                      rules={[
                        {
                          required: true,
                          message: "Không được để trống",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        size="default"
                        form={form}
                        formKey="soBienBanCu"
                        placeholder="Số biên bản"
                        disabled
                        className="border-input-disable"
                      />
                    </Form.Item>
                    <Row>
                      <Col span={10}>
                        <Form.Item name="ngayThayDoiCu">
                          <DatePickerComponent
                            formKey="ngayThayDoiCu"
                            form={form}
                            format={FORMAT_ENGLISH}
                            inputReadOnly={true}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1} />
                      <Col span={10}>
                        <Form.Item name="DonViGiaoNhan">
                          <Checkbox checked={isCheked} disabled>
                            Giao nhận tay ba
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item name="lyDoThayDoiCu" wrapperCol={{ span: 10 }}>
                      <Input
                        formKey="lyDoThayDoiCu"
                        type="text"
                        size="default"
                        form={form}
                        placeholder="Lý do thay đổi"
                        disabled
                        className="border-input-disable"
                      ></Input>
                    </Form.Item>

                    <Row>
                      <Col span={10}>
                        <Form.Item name="donViGiaoNhan1Cu">
                          <Selection
                            formKey="donViGiaoNhan1Cu"
                            form={form}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      {isCheked && (
                        <Col span={10}>
                          <Form.Item name="donViGiaoNhan2Cu">
                            <Selection
                              formKey="donViGiaoNhan2Cu"
                              form={form}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>

                    <Row>
                      <Col span={10}>
                        <Form.Item name="kenhNhan1Cu">
                          <Selection
                            formKey="kenhNhan1Cu"
                            form={form}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      {isCheked && (
                        <Col span={10}>
                          {" "}
                          <Form.Item name="kenhNhan2Cu">
                            <Selection
                              formKey="kenhNhan2Cu"
                              form={form}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>

                    <Row>
                      <Col span={10}>
                        <Form.Item name="kenhGiao1Cu">
                          <Selection
                            url=""
                            formKey="kenhGiao1Cu"
                            placeholder="kênh giao công tơ"
                            form={form}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      {isCheked && (
                        <Col span={10}>
                          <Form.Item name="kenhGiao2Cu">
                            <Selection
                              url=""
                              formKey="kenhGiao2Cu"
                              placeholder="kênh giao công tơ"
                              form={form}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  </div>
                </div>
              </div>
            </Spin>
            {/* 2 */}
            <div className="container-left">
              <div className="title-header">
                <h2>Thông tin phương thức giao nhận mới</h2>
              </div>
              <div className="content-left">
                <div className="label">
                  <div className="start">
                    <p className="label-text">Số biên bản</p>
                    <span>(*)</span>
                  </div>
                  <div className="start">
                    <p className="label-text">Ngày thay đổi</p>
                    <span>(*)</span>
                  </div>
                  <div className="start">
                    <p className="label-text">Lý do thay đổi</p>
                    <span>(*)</span>
                  </div>
                  <div className="start">
                    <p className="label-text">Đơn vị giao nhận</p>
                    <span>(*)</span>
                  </div>
                  <div className="start">
                    <p className="label-text">Kênh nhận công tơ</p>
                    <span>(*)</span>
                  </div>

                  <div className="start">
                    <p className="label-text">Kênh giao công tơ</p>
                    <span>(*)</span>
                  </div>
                </div>
                <div className="content-input">
                  <Form.Item
                    name="soBienBan_PT"
                    wrapperCol={{ span: 10 }}
                    rules={[
                      {
                        required: true,
                        message: "Không được để trống",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      size="default"
                      form={form}
                      formKey="soBienBan_PT"
                      placeholder="Nhập số biên bản"
                    />
                  </Form.Item>

                  <Row>
                    <Col span={10}>
                      {" "}
                      <Form.Item
                        name="ngayThayDoi"
                        rules={[
                          {
                            required: true,
                            message: "Không được để trống",
                          },
                        ]}
                      >
                        <DatePickerComponent
                          formKey="ngayThayDoi"
                          form={form}
                          format={FORMAT_ENGLISH}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1} />
                    <Col span={10}>
                      {" "}
                      <Form.Item name="checkbox">
                        <Checkbox
                          onChange={handleChangeCheckBox}
                          checked={isChekedRight}
                        >
                          Giao nhận tay ba
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    name="lyDoThayDoi"
                    wrapperCol={{ span: 10 }}
                    rules={[
                      {
                        required: true,
                        message: "Không được để trống",
                      },
                    ]}
                  >
                    <Input
                      formKey="lyDoThayDoi"
                      type="text"
                      size="default"
                      form={form}
                      placeholder="Nhập lý do thay đổi"
                    ></Input>
                  </Form.Item>
                  <Row>
                    <Col span={10}>
                      {" "}
                      <Form.Item
                        name="donViQuanLyGiaoNhanId1"
                        rules={[
                          {
                            required: true,
                            message: "Không được để trống",
                          },
                        ]}
                      >
                        <Select
                          formKey="donViQuanLyGiaoNhanId1"
                          placeholder="Chọn đơn vị giao nhận"
                          form={form}
                        >
                          {urlDvgn &&
                            urlDvgn.length &&
                            urlDvgn.map((item, idx) => (
                              <Option
                                key={idx}
                                value={item.id ? item.id.toString() : item.id}
                              >
                                {/* {item.maDonVi} - {item.tenDonVi} */}
                                <Tooltip title={item.tenDonVi}>
                                  {item.maDonVi} - {item.tenDonVi}
                                </Tooltip>
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      {" "}
                      {visible && (
                        <Form.Item
                          name="donViQuanLyGiaoNhanId2"
                          rules={[
                            {
                              required: true,
                              message: "Không được để trống",
                            },
                          ]}
                        >
                          <Select
                            formKey="donViQuanLyGiaoNhanId2"
                            placeholder="Chọn đơn vị giao nhận"
                            form={form}
                          >
                            {urlDvgn &&
                              urlDvgn.length &&
                              urlDvgn.map((item, idx) => (
                                <Option
                                  key={idx}
                                  value={item.id ? item.id.toString() : item.id}
                                >
                                  <Tooltip title={item.tenDonVi}>
                                    {item.maDonVi} - {item.tenDonVi}
                                  </Tooltip>
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col span={10}>
                      {" "}
                      <Form.Item
                        name="kenhNhan_PT1"
                        rules={[
                          {
                            required: true,
                            message: "Không được để trống",
                          },
                        ]}
                      >
                        <Select
                          formKey="kenhNhan_PT1"
                          placeholder="Chọn kênh nhận công tơ"
                          form={form}
                        >
                          <Option value="N">
                            1. Chiều nhận điện từ đơn vị khác
                          </Option>
                          <Option value="G">
                            2. Chiều giao điện từ đơn vị khác
                          </Option>
                          <Option value="X">3. Không có giao nhận</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      {" "}
                      {visible && (
                        <Form.Item
                          name="kenhNhan_PT2"
                          rules={[
                            {
                              required: true,
                              message: "Không được để trống",
                            },
                          ]}
                        >
                          <Select
                            formKey="kenhNhan_PT2"
                            placeholder="Chọn kênh nhận công tơ"
                            form={form}
                          >
                            <Option value="N">
                              1. Chiều nhận điện từ đơn vị khác
                            </Option>
                            <Option value="G">
                              2. Chiều giao điện từ đơn vị khác
                            </Option>
                            <Option value="X">3. Không có giao nhận</Option>
                          </Select>
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      {" "}
                      <Form.Item
                        name="kenhGiao_PT1"
                        rules={[
                          {
                            required: true,
                            message: "Không được để trống",
                          },
                        ]}
                      >
                        <Select
                          formKey="kenhGiao_PT1"
                          placeholder="Chọn kênh giao công tơ"
                          form={form}
                        >
                          <Option value="N">
                            1. Chiều nhận điện từ đơn vị khác
                          </Option>
                          <Option value="G">
                            2. Chiều giao điện từ đơn vị khác
                          </Option>
                          <Option value="X">3. Không có giao nhận</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      {" "}
                      {visible && (
                        <Form.Item
                          name="kenhGiao_PT2"
                          rules={[
                            {
                              required: true,
                              message: "Không được để trống",
                            },
                          ]}
                        >
                          <Select
                            formKey="kenhGiao_PT2"
                            placeholder="Chọn kênh giao công tơ"
                            form={form}
                          >
                            <Option value="N">
                              1. Chiều nhận điện từ đơn vị khác
                            </Option>
                            <Option value="G">
                              2. Chiều giao điện từ đơn vị khác
                            </Option>
                            <Option value="X">3. Không có giao nhận</Option>
                          </Select>
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div className="table-thay-doi-phuong-thuc">
            <div className="container-table-left">
              <span>
                <h2>Chỉ số chốt thay đổi phương thức</h2>
              </span>
              <Table
                columns={columns_left}
                loading={loading}
                dataSource={dataChisoTableSua}
                bordered
                form={form}
                pagination={false}
              />
            </div>
            <div className="container-table-right">
              <span>
                <h2>Chỉ số chốt thay đổi phương thức</h2>
              </span>
              <Table
                columns={columns_right}
                loading={loading}
                dataSource={dataChisoTableRightSuaNew}
                bordered
                form={form}
                pagination={false}
              />
            </div>
          </div>
          <ButtonStyle>
            <Row justify="center" gutter={12}>
              <Col span={4}>
                <Button
                  type="block"
                  htmlType="submit"
                  size="large"
                  className="full"
                  onClick={handleCloseModal}
                >
                  Đóng lại
                </Button>
              </Col>
              <Col span={4}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="full"
                  loading={loading}
                >
                  Cập nhật
                </Button>
              </Col>
            </Row>
          </ButtonStyle>
        </FormComponent>
      </ContainerFlex>
    </Fragment>
  );
}

const ContainerFlex = styled.div`
  .container-flex {
    display: flex;
    border: 1px solid #e9e5e5;
    border-radius: 2px;
    padding: 1px;
  }
  .content-flex {
    border-radius: 2px;
    padding: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .content-left {
    display: flex;
  }
  .maxw145-left {
    width: 190px !important;
  }
  .minw300-left {
    max-width: 340px !important;
  }
  .container-left {
    border-radius: 2px;
    padding: 5px;
  }
  .content-input-left {
    padding-left: 10px;
    flex: 4;
  }
  .container-left .content .label {
    padding-right: 35px;
  }
  .container-left .content .label .start span {
    padding-left: 30px;
  }

  .content {
    display: flex;
  }
  .label {
    min-width: 145px;
    flex: 1;
  }
  .content-input {
    flex: 4;
  }
  .label-text {
    margin-bottom: 24px;
    padding: 5px;
  }
  .checkbox {
    width: 20px;
  }
  .DatePicker {
    margin-right: 10px;
  }
  .checkbox-width {
    display: flex;
  }
  .text {
    padding-left: 10px;
  }

  p.label-text {
    display: flex;
  }
  .start {
    display: flex;
    margin-right: 5px;
  }
  .start .label-text {
    min-width: 91%;
  }
  .start span {
    padding-top: 5px;
    color: red;
  }
  .container-left {
    border-left: 1px solid #e9e5e5;
    border-radius: 2px;
    padding: 5px;
    flex: 1;
  }
  .container-spin {
    flex: 0.9;
  }
  .table-thay-doi-phuong-thuc {
    display: flex;
    justify-content: space-between;
  }
  .container-table-left .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding: 4.5px !important;
  }
  .container-table-right .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding: 1px !important;
  }
  .container-table-left .ant-table-thead .ant-table-cell {
    padding: 0 !important;
  }
  .container-table-right .ant-form-item {
    margin: 0 !important;
  }
  .container-table-left {
    width: 46%;
  }
  .container-table-right {
    width: 52%;
  }
  .container-table-right
    .ant-row.ant-form-item
    .ant-col.ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content {
    padding: 0 !important;
  }
  .maxw145 .ant-col.ant-form-item-control {
    border: 1px solid #ebdcdc;
  }

  .maxw145-left .ant-col.ant-form-item-control {
    border: 1px solid #ebdcdc;
  }

  .minw300 .ant-col.ant-form-item-control {
    border: 1px solid #ebdcdc;
  }

  .border-input-disable {
    border: 1px solid #ebdcdc;
  }

  thead tr th {
    text-align: center !important;
  }

  table input {
    font-size: 16px;
  }
`;
const ButtonStyle = styled.div`
  margin-top: 40px;
`;
