import React from "react";
import ListMenu from "./list-menu";

function ListChucNang({
  detail,
  setData,
  setListLoading,
  filterConditions,
  setTotal,
  location,
}) {
  return (
    <>
      <ListMenu
        detail={detail}
        setData={setData}
        setListLoading={setListLoading}
        filterConditions={filterConditions}
        setTotal={setTotal}
        location={location}
      />
    </>
  );
}

export default ListChucNang;
