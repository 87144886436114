import { closeModal } from "@state/system-config/reducer";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { alertMessage } from "@utils/function";
import { authPostData } from "@utils/request";

export const handleKySo = (
  setLoading,
  url,
  params,
  setFetchList,
  dispatch,
  handleCloseModal,
  setSelectedRowKeys
) => {
  authPostData({
    url,
    setLoading,
    method: METHOD_POST,
    payload: params,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchList(true);
        dispatch(closeModal());
        if (setSelectedRowKeys) setSelectedRowKeys([]);
        if (handleCloseModal) handleCloseModal();
        // if (res.data.statusCode === STATUSCODE_400) {
        //   alertMessage(
        //     "error",
        //     "Thông báo!",
        //     res.data && res.data.message ? res.data.message : "Lỗi ký số HSM"
        //   );
        // } else {
        //   setFetchList(true);
        //   dispatch(closeModal());
        //   if (setSelectedRowKeys) setSelectedRowKeys([]);
        //   if (handleCloseModal) handleCloseModal();
        // }
      } else {
        alertMessage(
          "error",
          "Thông báo!",
          res && res.message ? res.message : "Lỗi ký số HSM"
        );
      }
    },
  });
};
