import { 
  buildQueryString, 
  parseParams
} from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";

export const handleListData = (
  setLoading,
  location,
  setDataDaPhanCong,
  setTotalCount,
  filterConditions,
) => {
  let url = "";
  url = `${Endpoint.AG_LIST_YEU_CAU_DA_PC}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDaPhanCong(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataDaPhanCong([]);
    },
  });
};