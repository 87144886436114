import TrangChu from "@modules/tich-hop-CRM";
import { DieuDoDuyetYeuCau } from "@modules/tich-hop-CRM/dieu-do/duyet-yeu-cau";
import DuyetYeuCauDoiTruong from "@modules/tich-hop-CRM/doi-truong/duyet-yeu-cau/index";
import PhanCong from "@modules/tich-hop-CRM/doi-truong/phan-cong/index";
import DuyetYeuCau from "@modules/tich-hop-CRM/giao-dich-vien/duyet-yeu-cau/index";
import TruongCaDuyetYeuCau from "@modules/tich-hop-CRM/giao-dich-vien/truong-ca-duyet-yeu-cau/index";
import YeuCauCoTroNgai from "@modules/tich-hop-CRM/giao-dich-vien/yeu-cau-co-tro-ngai/index";
import YeuCauDaPhanCong from "@modules/tich-hop-CRM/giao-dich-vien/yeu-cau-da-phan-cong/index";
import YeuCauTiepNhan from "@modules/tich-hop-CRM/giao-dich-vien/yeu-cau-tiep-nhan/index";
import DuyetYeuCauDoi from "@modules/tich-hop-CRM/kinh-doanh/duyet-yeu-cau-doi";
import DuyetYeuCauPhongKD from "@modules/tich-hop-CRM/kinh-doanh/duyet-yeu-cau-phong-kd";
import YeuCauDangThucHienKD from "@modules/tich-hop-CRM/kinh-doanh/yeu-cau-dang-thuc-hien/index";
import { LanhDaoDuyetYeuCau } from "@modules/tich-hop-CRM/lanh-dao";
import { NVGiamSatYeuCauGiamSat } from "@modules/tich-hop-CRM/nhan-vien-giam-sat/yeu-cau-giam-sat";
import YeuCauDangThucHien from "@modules/tich-hop-CRM/nhan-vien-hien-truong/yeu-cau-dang-thuc-hien/index";
import YeuCauDuocPhanCong from "@modules/tich-hop-CRM/nhan-vien-hien-truong/yeu-cau-duoc-phan-cong/index";
import TienIchTraCuuYeuCau from "@modules/tich-hop-CRM/tien-ich/tra-cuu-yeu-cau/tra-cuu-yeu-cau";
import { tich_hop_CRM } from "@permissions/o_thCRM";
import { MODULE_TICHHOPCRM } from "@utils/constants";
const mainPath = "/tich-hop-crm";

const routes = [
  {
    title: "",
    path: mainPath,
    privateRoute: true,
    component: TrangChu,
    subItems: [],
    permissionCode: MODULE_TICHHOPCRM,
  },
  {
    title: "Giao dịch viên",
    path: mainPath + "/giao-dich-vien",
    privateRoute: true,
    permissionCode: tich_hop_CRM.o1,
    subItems: [
      {
        subTitle: "Yêu cầu tiếp nhận",
        subPath: "/yeu-cau-tiep-nhan",
        subComponent: YeuCauTiepNhan,
        permissionCode: tich_hop_CRM.o11,
      },
      {
        subTitle: "Duyệt yêu cầu",
        subPath: "/duyet-yeu-cau",
        subComponent: DuyetYeuCau,
        permissionCode: tich_hop_CRM.o14,
      },
      {
        subTitle: "Quản lý yêu cầu có trở ngại",
        subPath: "/yeu-cau-co-tro-ngai",
        subComponent: YeuCauCoTroNgai,
        permissionCode: tich_hop_CRM.o13,
      },
      {
        subTitle: "Yêu cầu đã phân công",
        subPath: "/yeu-cau-da-phan-cong",
        subComponent: YeuCauDaPhanCong,
        permissionCode: tich_hop_CRM.o12,
      },
      {
        subTitle: "Trưởng ca duyệt yêu cầu",
        subPath: "/truong-ca-duyet-yeu-cau",
        subComponent: TruongCaDuyetYeuCau,
        permissionCode: tich_hop_CRM.o15,
      },
    ],
  },
  {
    title: "Đội trưởng",
    path: mainPath + "/doi-truong",
    privateRoute: true,
    permissionCode: tich_hop_CRM.o2,
    subItems: [
      {
        subTitle: "Phân công",
        subPath: "/phan-cong",
        subComponent: PhanCong,
        permissionCode: tich_hop_CRM.o21,
      },
      {
        subTitle: "Duyệt yêu cầu",
        subPath: "/duyet-yeu-cau",
        subComponent: DuyetYeuCauDoiTruong,
        permissionCode: tich_hop_CRM.o22,
      },
    ],
  },
  {
    title: "Nhân viên hiện trường",
    path: mainPath + "/nv-hien-truong",
    privateRoute: true,
    permissionCode: tich_hop_CRM.o3,
    subItems: [
      {
        subTitle: "Yêu cầu được phân công",
        subPath: "/yeu-cau-duoc-phan-cong",
        subComponent: YeuCauDuocPhanCong,
        permissionCode: tich_hop_CRM.o31,
      },
      {
        subTitle: "Yêu cầu đang thực hiện",
        subPath: "/yeu-cau-dang-thuc-hien",
        subComponent: YeuCauDangThucHien,
        permissionCode: tich_hop_CRM.o32,
      },
    ],
  },
  {
    title: "NV giám sát",
    path: mainPath + "/nv-giam-sat",
    privateRoute: true,
    permissionCode: tich_hop_CRM.o4,
    subItems: [
      {
        subTitle: "Yêu cầu giám sát",
        subPath: "/yeu-cau-giam-sat",
        subComponent: NVGiamSatYeuCauGiamSat,
        permissionCode: tich_hop_CRM.o41,
      },
    ],
  },
  {
    title: "Điều độ",
    path: mainPath + "/dieu-do",
    privateRoute: true,
    permissionCode: tich_hop_CRM.o5,
    subItems: [
      {
        subTitle: "Duyệt yêu cầu",
        subPath: "/duyet-yeu-cau",
        subComponent: DieuDoDuyetYeuCau,
        permissionCode: tich_hop_CRM.o51,
      },
    ],
  },
  {
    title: "Kinh doanh",
    path: mainPath + "/kinh-doanh",
    privateRoute: true,
    permissionCode: tich_hop_CRM.o6,
    subItems: [
      {
        subTitle: "Yêu cầu đang thực hiện",
        subPath: "/yeu-cau-dang-thuc-hien",
        subComponent: YeuCauDangThucHienKD,
        permissionCode: tich_hop_CRM.o61,
      },
      {
        subTitle: "Kiểm duyệt yêu cầu đã giải quyết",
        subPath: mainPath + "/kinh-doanh/kiem-duyet-yc-da-giai-quyet",
        path: "",
        permissionCode: tich_hop_CRM.o62,
        subItems: [
          {
            subTitle: "Duyệt yêu cầu xử lý tại hiện trường",
            subPath: "/kiem-duyet-yc-da-giai-quyet/duyet-yc-xu-ly-hien-truong",
            subComponent: DuyetYeuCauDoi,
            permissionCode: tich_hop_CRM.o621,
          },
          {
            subTitle: "Duyệt yêu cầu xử lý tại phòng kinh doanh",
            subPath: "/kiem-duyet-yc-da-giai-quyet/duyet-yc-xu-ly-pkd",
            subComponent: DuyetYeuCauPhongKD,
            permissionCode: tich_hop_CRM.o622,
          },
        ],
      },
    ],
  },
  {
    title: "Lãnh đạo",
    path: mainPath + "/lanh-dao",
    privateRoute: true,
    permissionCode: tich_hop_CRM.o7,
    subItems: [
      {
        subTitle: "Duyệt yêu cầu",
        subPath: "/duyet-yeu-cau",
        subComponent: LanhDaoDuyetYeuCau,
        permissionCode: tich_hop_CRM.o71,
      },
    ],
  },
  {
    title: "Tra cứu tìm kiếm",
    path: mainPath + "/tra-cuu-tim-kiem",
    privateRoute: true,
    permissionCode: tich_hop_CRM.o8,
    subItems: [
      {
        subTitle: "Tìm kiếm",
        subPath: "/tim-kiem",
        subComponent: TienIchTraCuuYeuCau,
        permissionCode: tich_hop_CRM.o81,
      },
    ],
  },
];
export default routes;
