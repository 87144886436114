import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useState,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Filter from "@components/Filter/Filter";
import { TableStyled, Container } from "./css-styled";
import { Tag, Tooltip } from "antd";
import { authSelector } from "@state/auth/reducer";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  AP_GIA_TRANG_THAI,
  ENUM_MUCDICH_SDD,
  MONTH_STRING,
  FORMAT_ENGLISH,
  // FORMAT_MONTH_ENGLISH,
} from "@utils/constants";
import {
  customColumn,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { handleListData, exportExcel } from "./services";
import { useLocation, useSearchParams } from "react-router-dom";
import { ListFilter } from "../../common/list-bo-loc";
import TableComponent from "@components/TableComponent";
import { openModal } from "@state/system-config/reducer";
import ChiTietDiemDo from "../../common/chi-tiet-diem-do";
import { InfoCircleOutlined, FilePdfOutlined, FileOutlined } from "@ant-design/icons";
import ButtonComponent from "@components/Button";
import moment from "moment";

export default function Index() {
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);
  const [dataYC, setDataYC] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [biTraLai, setBiTraLai] = useState(false);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    DonViId: user.unitId,
    NgayBatDau: moment(moment().startOf(MONTH_STRING)).format(FORMAT_ENGLISH),
    NgayKetThuc: moment().format(FORMAT_ENGLISH),
    // ThangKiemTra: moment().format(FORMAT_MONTH_ENGLISH),
    // LoaiKhachHang: ENUM_MUCDICH_SDD.SINHHOAT,
    ...parseParams(location.search),
  });
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const onChangePagination = (paging, _filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };
  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        pageIndex: DEFAULT_PAGEINDEX,
        ...values,
        BiTraLai: biTraLai ? biTraLai : null,
        Tram: values.Tram
          ? Array.isArray(values.Tram) && values.Tram.length > 0
            ? values.Tram.join()
            : values.Tram
          : [],
      }));
    },
    [biTraLai]
  );

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      DonViId: user.unitId,
      NgayBatDau: moment(moment().startOf(MONTH_STRING)).format(FORMAT_ENGLISH),
      NgayKetThuc: moment().format(FORMAT_ENGLISH),
      // ThangKiemTra: moment().format(FORMAT_MONTH_ENGLISH),
      // LoaiKhachHang: ENUM_MUCDICH_SDD.SINHHOAT,
    });
  }, [user.unitId]);
  //search list
  const getListData = useCallback(() => {
    handleListData(
      location,
      filterConditions,
      setLoading,
      setDataYC,
      setTotalCount
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getListData();
  }, [getListData, user]);

  const checkTraLai = (e) => {
    setBiTraLai(e.target.checked);
  };

  const detailDiemDo = (record) => {
    return dispatch(
      openModal({
        content: <ChiTietDiemDo detailRecord={record} />,
        size: "large",
        title: `Chi tiết điểm đo - ${record.maDiemDo}`,
      })
    );
  };

  const detailDD = (record) => {
    return {
      onDoubleClick: () => {
        detailDiemDo(record);
      },
    };
  };

  const columns = [
    {
      title: "Mã điểm đo",
      dataIndex: "maDiemDo",
      key: "maDiemDo",
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() => detailDiemDo(record)}
        >
          <span>{text}</span>
        </div>
      ),
      width: "10%",
      sorter: true,
      fixed: "left",
    },
    {
      title: "Mã khách hàng",
      dataIndex: "maKhachHang",
      key: "maKhachHang",
      render: (text) => <span>{text}</span>,
      width: "10%",
      sorter: true,
      fixed: "left",
    },
    {
      title: "Tên KH",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      render: (text) => <span>{text}</span>,
      width: "12%",
      sorter: true,
    },
    {
      title: "Điện thoại",
      dataIndex: "soDienThoai",
      key: "soDienThoai",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: "Trước kiểm tra",
      children: [
        {
          title: "Ngày áp dụng",
          dataIndex: "ngayApDung",
          key: "ngayApDung",
          render: (text) => (
            <div className="table-text-center">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
        {
          title: "Số hộ",
          dataIndex: "soHo",
          key: "soHo",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
        },
        {
          title: "Chuỗi giá",
          dataIndex: "chuoiGia",
          key: "chuoiGia",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
      ],
    },
    {
      title: "Sau kiểm tra",
      children: [
        {
          title: "Ngày áp dụng",
          dataIndex: "ngayApDungMoi",
          key: "ngayApDungMoi",
          render: (text) => (
            <div className="table-text-center">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
        {
          title: "Số hộ",
          dataIndex: "soHoMoi",
          key: "soHoMoi",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "6%",
        },
        {
          title: "Chuỗi giá",
          dataIndex: "chuoiGiaMoi",
          key: "chuoiGiaMoi",
          render: (text) => (
            <div className="table-text-left">
              <span>{text}</span>
            </div>
          ),
          width: "12%",
        },
      ],
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (text, record) => (
        <>
          {record.trangThai?.toString() === AP_GIA_TRANG_THAI.DA_LAY_TU_WEB ? (
            <Tag
              style={{ display: "block", textAlign: "center" }}
              color={"#5678d7"}
            >
              {"Đã tiếp nhận"}
            </Tag>
          ) : record.trangThai?.toString() === AP_GIA_TRANG_THAI.PKD_DA_PHAN_CONG ? (
            <Tag
              style={{ display: "block", textAlign: "center" }}
              color={"#175bd1"}
            >
              {"Đã phân công cho đội"}
            </Tag>
          )
            : record.trangThai?.toString() ===
              AP_GIA_TRANG_THAI.DA_PHAN_CONG ? (
              <Tag
                style={{ display: "block", textAlign: "center" }}
                color={"#c91a34"}
              >
                {"Đã phân công cho NV"}
              </Tag>
            ) : record.trangThai?.toString() ===
              AP_GIA_TRANG_THAI.DANG_THUC_HIEN ? (
              <Tag
                style={{ display: "block", textAlign: "center" }}
                color={"#9516f0"}
              >
                {"Đã lấy về MTB"}
              </Tag>
            ) : record.trangThai?.toString() ===
              AP_GIA_TRANG_THAI.MTB_DA_GUI_DL ? (
              <Tag
                style={{ display: "block", textAlign: "center" }}
                color={"#e060a5"}
              >
                {"Chờ NV áp giá duyệt"}
              </Tag>
            ) : record.trangThai?.toString() ===
              AP_GIA_TRANG_THAI.NVAG_DA_XAC_NHAN ? (
              <Tag
                style={{ display: "block", textAlign: "center" }}
                color={"#59e71f"}
              >
                {"Chờ bộ phận áp giá duyệt"}
              </Tag>
            ) : record.trangThai?.toString() ===
              AP_GIA_TRANG_THAI.BPAG_DA_XAC_NHAN ? (
              <Tag
                style={{ display: "block", textAlign: "center" }}
                color={"#0aad98"}
              >
                {"Chờ PKD duyệt"}
              </Tag>
            ) : record.trangThai?.toString() ===
              AP_GIA_TRANG_THAI.PKD_DA_XAC_NHAN ? (
              <Tag
                style={{ display: "block", textAlign: "center" }}
                color={"#9d41e3"}
              >
                {"Chờ lãnh đạo duyệt"}
              </Tag>
            ) : record.trangThai?.toString() ===
              AP_GIA_TRANG_THAI.LD_DA_XAC_NHAN ? (
              <Tag
                style={{ display: "block", textAlign: "center" }}
                color={"#949d09"}
              >
                {"Chờ đồng bộ về CMIS"}
              </Tag>
            ) : (
              <Tag
                style={{ display: "block", textAlign: "center" }}
                color={"#26c0e9"}
              >
                {"Đã đồng bộ về CMIS"}
              </Tag>
            )}
          {record.biTraLai ? (
            <div
              className="table-text-center"
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: "#FF5247",
                cursor: "pointer",
              }}
            >
              <Tooltip
                overlayStyle={{ whiteSpace: 'pre-line' }}
                title={`Lý do: ${record.lyDoTraLai}
                 Thời gian: ${record.thoiGianTraLai}`}
              >
                <InfoCircleOutlined /> Bị trả lại
              </Tooltip>
            </div>
          ) : null}
        </>
        // <Tag style={{ display: "block", textAlign: "center" }} color={"#faad14"}>{"Chưa phân công"}</Tag>
      ),
      width: "8%",
    },
    // {
    //   title: "Tháng kiểm tra",
    //   dataIndex: "thangKiemTra",
    //   key: "thangKiemTra",
    //   render: (text) => (
    //     <div className="table-text-center">
    //       <span>{text}</span>
    //     </div>
    //   ),
    //   width: "7%",
    // },
    {
      title: "Loại khách hàng",
      dataIndex: "loaiKhachHang",
      key: "loaiKhachHang",
      render: (text, record) =>
        record.loaiKhachHang?.toString() === ENUM_MUCDICH_SDD.NGOAISINHHOAT ? (
          <span>{"Ngoài sinh hoạt"}</span>
        ) : (
          <span>{"Sinh hoạt"}</span>
        ),
      width: "8%",
    },
    {
      title: "Trạm",
      dataIndex: "tram",
      key: "tram",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: "Sổ GCS",
      dataIndex: "soGCS",
      key: "soGCS",
      render: (text) => <span>{text}</span>,
      width: "6%",
    },
    {
      title: "Địa chỉ",
      dataIndex: "diaChi",
      key: "diaChi",
      render: (text) => <span>{text}</span>,
      width: "16%",
    },
    {
      title: "Mã bảng kê",
      dataIndex: "maBangKe",
      key: "maBangKe",
      render: (text) => <span>{text}</span>,
      width: "9%",
    },
    {
      title: "Tác vụ",
      dataIndex: "",
      key: "tacVu",
      render: (text, record) => (
        <div className="table-text-center">
          {record.pathFileBienBan ? (
            <Tooltip title="Xem biên bản">
              <a
                href={record.pathFileBienBan}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FilePdfOutlined
                  style={{ fontSize: "22px" }}
                />
              </a>
            </Tooltip>
          ) : null}
          {record.pathFileBienBanGiay ?
            <Tooltip title="Xem BBAG giấy">
              <a
                href={record.pathFileBienBanGiay}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FileOutlined
                  style={{ fontSize: "22px", marginLeft: '10px' }}
                />
              </a>
            </Tooltip>
            : null}
        </div>
      ),
      width: "4%",
      fixed: "right"
    },
  ];

  // const onFinish = (values) => {
  //   //call api
  //   handleSubmitPhanCong(
  //     (isDT === true && user.teamName !== null) ? dataDoi.find(element => element.name === user.teamName)?.value : values.doiId,
  //     values.userId,
  //     t,
  //     setLoading,
  //     handleCloseModalKy,
  //     setFetchDataTable,
  //     setSelectedRowKeys,
  //     selectedRowKeys.toString()
  //   );
  // };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleXuatExcel = () => {
    exportExcel(filterConditions, setLoading);
  };

  const genExtra = useCallback(
    () => (
      <div>
        <ButtonComponent
          type="primary"
          style={{ marginRight: "10px" }}
          onClick={() => handleXuatExcel()}
        >
          Xuất Excel
        </ButtonComponent>
      </div>
    ),
    [handleXuatExcel]
  );

  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          checkTraLai={checkTraLai}
        />
      </Filter>
      <Container>
        <TableStyled>
          <TableComponent
            header={"Tra cứu yêu cầu"}
            handleSearch={handleSearch}
            dataSource={dataYC}
            columns={customColumn(columns, filterConditions)}
            totalData={totalCount}
            loading={loading}
            scrollX={3000}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
            rowKey="id"
            onRow={detailDD}
            renderExtraAction={() => genExtra()}
          />
        </TableStyled>
      </Container>
    </Fragment>
  );
}
