import { Fragment, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import ListMenu from "./list-menu";
import { openModal, closeModal } from "@state/system-config/reducer";
import DongBoYeuThoiGian from "../../common-ho-so/dong-bo-thoi-gian";

export default function ListChucNang(props) {
  const { detail, setIsDoubleClick, setFetchDataTable, getListHoSo } = props;
  const [visibleDongBo, setVisibleDongBo] = useState(false);
  const dispatch = useDispatch();

  //open modal
  const handleOpenModalDongBo = () => {
    setVisibleDongBo(!visibleDongBo);
    setIsDoubleClick(false);
  };

  //close modal
  const handleCloseModalDongBo = useCallback(() => {
    setVisibleDongBo(false);
    setIsDoubleClick(true);
  }, [setIsDoubleClick]);

  //render modal
  const showModalDongBo = useMemo(
    () =>
      visibleDongBo && (
        <DongBoYeuThoiGian
          detail={detail}
          visible={visibleDongBo}
          closeModal={handleCloseModalDongBo}
          setFetchDataTable={setFetchDataTable}
          getListHoSo={getListHoSo}
          isMultiple={false}
        />
      ),
    [
      detail,
      getListHoSo,
      handleCloseModalDongBo,
      setFetchDataTable,
      visibleDongBo,
    ]
  );

  return (
    <Fragment>
      <ListMenu
        dispatch={dispatch}
        openModal={openModal}
        closeModal={closeModal}
        detail={detail}
        handleOpenModalDongBo={handleOpenModalDongBo}
      />
      {showModalDongBo}
    </Fragment>
  );
}
