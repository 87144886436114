import { buildQueryString, parseParams } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import { authGetData, downloadFile } from "@utils/request";

export const handleListData = (
  location,
  filterConditions,
  setLoading,
  setDataYC,
  setTotalCount
) => {
  let url = "";
  url = `${Endpoint.AG_TIM_KIEM_YC}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataYC(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataYC([]);
    },
  });
};

export const exportExcel = (filterConditions, setLoading) => {
  const query = buildQueryString(filterConditions);
  downloadFile({
    endpoint: `${Endpoint.AG_TIM_KIEM_YC_EXCEL}?${query}`,
    setLoading,
  });
};