import React, { useState, useCallback, useEffect, useMemo } from "react";
import { authPostData } from "@utils/request";
import { Column } from "@ant-design/plots";
import { Col, Row } from "antd";
import NoRecordFound from "@components/NoRecordFound";
import { Endpoint } from "@utils/endpoint";
const BaoCaoCham = () => {
  const [dataCham, setDataCham] = useState(null);
  const [loading, setLoading] = useState();
  const getChartDasboardCham = useCallback(() => {
    authPostData({
      url: `${Endpoint.SO_GHI_CHAM}`,
      method: "POST",
      setLoading,
      payload: {},
      onSuccess: (res) => {
        const newData = res.data.xValues.map((e, i) => ({
          type: e,
          sales: res.data.series.data[i],
        }));
        setDataCham(newData);
      },
    });
  }, []);
  useEffect(() => {
    getChartDasboardCham();
  }, [getChartDasboardCham]);
  const config = useMemo(
    () => ({
      xField: "type",
      yField: "sales",
      columnWidthRatio: 0.5,
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      meta: {
        type: {
          alias: " không dữ liệu",
        },
        sales: {
          alias: "dữ liệu",
        },
      },
    }),
    []
  );
  const renderContent = useCallback(() => {
    if (!loading && !dataCham) {
      return (
        <div className="text-center">
          <NoRecordFound />
        </div>
      );
    }
    if (dataCham) {
      return <Column {...config} data={dataCham} />;
    }
  }, [dataCham, loading, config]);
  return (
    <div className="chart-container">
      <div className="container">
        <h2 className="chart-cham">Báo cáo sổ ghi chậm và xuất chậm dữ liệu</h2>
        <Row gutter={5}>
          <Col span={12}>{renderContent()}</Col>
        </Row>
      </div>
    </div>
  );
};
export default BaoCaoCham;
