import { Fragment, useCallback, useMemo, useState } from "react";
import { Table, Typography } from "antd";
import { useTranslation } from "react-i18next";
import BangKeThietBiModal from "../../modals/modal-bang-ke-thiet-bi";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import FormSuDung from "./form/form-su-dung";
import {
  handleCreateCongSuatSD,
  handleRemoveCongSuatSD,
  handleUpdateCongSuatSD,
} from "../../../services";
import { ENUM_MODE_PHUONGANCAPDIEN } from "@utils/constants";
const { Text } = Typography;

export default function PanelCongSuatSuDungDien(props) {
  const {
    detailLapPhuongAn,
    setLoadingIndex,
    setFetchDataCSTB,
    dataTable,
    sumCongSuat,
    mode,
  } = props;
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [dataCSSuDungDien, setDataCSSuDungDien] = useState({});

  //Khai báo columns

  const columns =
    mode !== ENUM_MODE_PHUONGANCAPDIEN.XEMLAI
      ? [
          {
            title: t("lap_phuong_an.thiet_bi.muc_dich_su_dung"),
            dataIndex: "mucDichSuDung",
            key: "mucDichSuDung",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "30%",
          },
          {
            title: t("lap_phuong_an.thiet_bi.ten_thiet_bi"),
            dataIndex: "tenThietBi",
            key: "tenThietBi",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "20%",
          },
          {
            title: t("lap_phuong_an.thiet_bi.dien_ap_su_dung"),
            dataIndex: "dienApSuDung",
            key: "dienApSuDung",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: t("lap_phuong_an.thiet_bi.cong_suat"),
            dataIndex: "congSuat",
            key: "congSuat",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: t("lap_phuong_an.thiet_bi.so_luong"),
            dataIndex: "soLuong",
            key: "soLuong",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: "Hệ số đồng thời",
            dataIndex: "heSoDongThoi",
            key: "heSoDongThoi",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: t("lap_phuong_an.thiet_bi.tong_so"),
            dataIndex: "tongSo",
            key: "tongSo",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: t("tac_vu"),
            key: "action",
            align: "center",
            width: "10%",
            render: (row) => (
              <div style={{ textAlign: "center" }}>
                <EditOutlined
                  style={{ color: "#279BEF", fontSize: 20 }}
                  onClick={() => handleOpenModal(row)}
                />
                <DeleteOutlined
                  style={{ color: "#FF2D00", fontSize: 20, marginLeft: 10 }}
                  onClick={() => handleDelete(row.id)}
                />
              </div>
            ),
          },
        ]
      : [
          {
            title: t("lap_phuong_an.thiet_bi.muc_dich_su_dung"),
            dataIndex: "mucDichSuDung",
            key: "mucDichSuDung",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "30%",
          },
          {
            title: t("lap_phuong_an.thiet_bi.ten_thiet_bi"),
            dataIndex: "tenThietBi",
            key: "tenThietBi",
            render: (text) => (
              <div className="table-text-left">
                <span>{text}</span>
              </div>
            ),
            width: "20%",
          },
          {
            title: t("lap_phuong_an.thiet_bi.dien_ap_su_dung"),
            dataIndex: "dienApSuDung",
            key: "dienApSuDung",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: t("lap_phuong_an.thiet_bi.cong_suat"),
            dataIndex: "congSuat",
            key: "congSuat",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: t("lap_phuong_an.thiet_bi.so_luong"),
            dataIndex: "soLuong",
            key: "soLuong",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: "Hệ số đồng thời",
            dataIndex: "heSoDongThoi",
            key: "heSoDongThoi",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
          {
            title: t("lap_phuong_an.thiet_bi.tong_so"),
            dataIndex: "tongSo",
            key: "tongSo",
            render: (text) => (
              <div className="table-text-right">
                <span>{text}</span>
              </div>
            ),
            width: "10%",
          },
        ];

  //xử lý call api sửa/tạo

  const handleCreateUpdate = (data, form) => {
    if (data.id) {
      //sửa công suất sử dụng điện

      handleUpdateCongSuatSD(
        data,
        detailLapPhuongAn,
        setLoadingIndex,
        setFetchDataCSTB,
        handleCloseModal,
        form
      );
    } else {
      //tạo công suất sử dụng điện

      handleCreateCongSuatSD(
        data,
        detailLapPhuongAn,
        setLoadingIndex,
        setFetchDataCSTB,
        handleCloseModal,
        form
      );
    }
  };

  //xóa dữ liệu

  const handleDelete = (id) => {
    //call api xóa dữ liệu

    handleRemoveCongSuatSD(
      id,
      setLoadingIndex,
      setFetchDataCSTB,
      handleCloseModal,
      t,
      detailLapPhuongAn
    );
  };

  //hiển thị popup, set dữ liệu chi tiết

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDataCSSuDungDien(row);
      else setDataCSSuDungDien({});
      setVisible(!visible);
    },
    [visible]
  );

  //đóng modal

  const handleCloseModal = () => {
    setVisible(false);
  };

  const showModal = useMemo(
    () =>
      visible && (
        <BangKeThietBiModal
          detailBangKeThietBi={dataCSSuDungDien}
          visible={visible}
          handleCreateUpdate={handleCreateUpdate}
          handleCloseModal={handleCloseModal}
        />
      ),
    // eslint-disable-next-line
    [visible]
  );

  const countTotals = () => {
    return (
      <Table.Summary fixed>
        <Table.Summary.Row
          style={{ backgroundColor: "#FFEAEA", border: "none" }}
        >
          <Table.Summary.Cell align="center" colSpan={6} index={0}>
            <Text className="font-bold">Tổng cộng</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" colSpan={1} index={1}>
            <Text className="font-bold">{sumCongSuat} kW</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell colSpan={1} index={2}></Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <Fragment>
      <FormSuDung
        handleOpenModal={handleOpenModal}
        columns={columns}
        countTotals={countTotals}
        dataTable={dataTable}
        mode={mode}
      />
      {showModal}
    </Fragment>
  );
}
