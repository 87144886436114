import { authSelector } from "@state/auth";
import { Col, Form, Row, Select, Button, Input } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DatePickerComponent from "@components/DatePicker";
import {
  ENUM_LOAI_PHIEU,
  ENUM_PHIEU_VAT_TU,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import moment from "moment";
// import Selection from "@components/Select";
import FormComponent from "@components/Form";
import { getListMulti } from "./services";
import { removeAccents } from "@utils/function";
const { Option } = Select;

const dataSoPha = [
  {
    value: null,
    name: "Tất cả",
  },
  {
    value: 1,
    name: "1 Pha",
  },
  {
    value: 3,
    name: "3 Pha",
  },
];
export function ListFilter(props) {
  const { funcId, handleSearch, clearFilter, filterConditions } = props;
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [, setLoading] = useState(false);
  const [loaiPhieu, setLoaiPhieu] = useState([]);
  const [trangThai, setTrangThai] = useState([]);
  const [loaiTreoThao, setLoaiTreoThao] = useState([]);
  const [disableLoaiPhieu, setDisableLoaiPhieu] = useState(false);
  const [donVi, setDonVi] = useState([]);
  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  useEffect(() => {
    getListMulti(
      setLoading,
      setLoaiPhieu,
      setTrangThai,
      setDonVi,
      setLoaiTreoThao,
      funcId
    );
  }, [funcId]);

  useEffect(() => {
    if (funcId === ENUM_PHIEU_VAT_TU.DTPHIEUTRAVT) {
      setDisableLoaiPhieu(true);
      form.setFieldsValue({
        loaiPhieu: ENUM_LOAI_PHIEU.PHIEU_TRAVT,
      });
    } else if (funcId === ENUM_PHIEU_VAT_TU.DTPHIEUXUATVT) {
      setDisableLoaiPhieu(true);
      form.setFieldsValue({
        loaiPhieu: ENUM_LOAI_PHIEU.PHIEU_XUATVT,
      });
    } else {
      setDisableLoaiPhieu(false);
    }
  }, [form, funcId]);

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
      }}
      autoComplete="off"
    >
      <Row gutter={24}>
        <Col span={24} md={8}>
          <Form.Item name="donViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Select
                placeholder="Tất cả"
                showSearch
                filterOption={(input, option) => {
                  if (option && option.children) {
                    return (
                      removeAccents(option.children)
                        .toLowerCase()
                        .indexOf(removeAccents(input).toLowerCase()) >= 0
                    );
                  }
                }}
              >
                {donVi && donVi.length > 0
                  ? donVi.map((item, index) => {
                      if (item.value !== null) {
                        return (
                          <Option key={index} value={item.value}>
                            {item.name}
                          </Option>
                        );
                      }
                      return null;
                    })
                  : null}
              </Select>
            )}
          </Form.Item>
        </Col>

        <Col span={24} md={5}>
          <Form.Item name="loaiTreoThao" label="Loại treo tháo">
            <Select
              placeholder="Tất cả"
              allowClear
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {loaiTreoThao && loaiTreoThao.length > 0
                ? loaiTreoThao.map((item, index) => {
                    if (
                      item.value === null ||
                      item.value === 4 ||
                      item.value === 5 ||
                      item.value === 6
                    ) {
                      return null;
                    } else {
                      return (
                        <Option key={index} value={item.value.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item name="loaiPhieu" label="Loại phiếu">
            <Select
              placeholder="Tất cả"
              disabled={disableLoaiPhieu}
              allowClear
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {loaiPhieu && loaiPhieu.length > 0
                ? loaiPhieu.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={5}>
          <Form.Item name="trangThaiId" label="Trạng thái">
            <Select
              placeholder="Tất cả"
              allowClear
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {trangThai && trangThai.length > 0
                ? trangThai.map((item, index) => {
                    if (item.key !== null) {
                      return (
                        <Option key={index} value={item.key.toString()}>
                          {item.value}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="soPha" label="Số pha">
            <Select
              placeholder="Tất cả"
              allowClear
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {dataSoPha.map((item, index) => {
                if (item.value !== null) {
                  return (
                    <Option key={index} value={item.value.toString()}>
                      {item.name}
                    </Option>
                  );
                }
                return null;
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="ngayTaoTu" label="Từ ngày">
            <DatePickerComponent
              defaultValue={
                filterConditions.startDate
                  ? moment(filterConditions.startDate, FORMAT_ENGLISH)
                  : moment().startOf(MONTH_STRING)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"ngayTaoTu"}
              notClear={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="ngayTaoDen" label="Đến ngày">
            <DatePickerComponent
              defaultValue={
                filterConditions.endDate
                  ? moment(filterConditions.endDate, FORMAT_ENGLISH)
                  : moment()
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"ngayTaoDen"}
              notClear={true}
            />
          </Form.Item>
        </Col>

        <Col span={24} md={4}>
          <Form.Item name="searchTerm" label="Mã phiếu">
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col
          span={24}
          md={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button className="mr-24px" onClick={clear}>
            Bỏ lọc
          </Button>
          <Button type="primary" htmlType="submit" form="filter-form">
            Tìm kiếm
          </Button>
        </Col>
      </Row>
    </FormComponent>
  );
}
