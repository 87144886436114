import { Endpoint } from "@utils/endpoint";
import { Col, Form, Row, Select, Button, Input, Tooltip } from "antd";
import DatePickerComponent from "@components/DatePicker";
import {
  FORMAT_DATE,
  FORMAT_ENGLISH,
  FORMAT_MONTH,
  MONTH_STRING,
} from "@utils/constants";
import moment from "moment";
import FormComponent from "@components/Form";
import { cap_dien } from "@permissions/a_capdien";
import ExportExcel from "../common/xuat-excel";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { buildQueryString, parseParams, removeAccents } from "@utils/function";

const { Option } = Select;

export default function FormBoLoc(props) {
  const { form, handleSearch, user, donVi, filterConditions, clear, taiKhoan } =
    props;
  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
        nguoiThaoTacId: user.id.toString(),
      }}
      autoComplete="off"
    >
      <Row gutter={24}>
        <Col span={24} md={8}>
          <Form.Item name="donViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Select
                showSearch
                filterOption={(input, option) => {
                  if (option && option.children) {
                    return (
                      removeAccents(option.children)
                        .toLowerCase()
                        .indexOf(removeAccents(input).toLowerCase()) >= 0
                    );
                  }
                }}
              >
                {donVi && donVi.length > 0
                  ? donVi.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.name}
                      </Option>
                    ))
                  : null}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="tuNgay" label="Từ ngày">
            <DatePickerComponent
              defaultValue={
                filterConditions.tuNgay
                  ? moment(filterConditions.tuNgay, FORMAT_ENGLISH)
                  : moment().startOf(MONTH_STRING)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"tuNgay"}
              picker={FORMAT_DATE}
              notClear={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="denNgay" label="Đến ngày">
            <DatePickerComponent
              defaultValue={
                filterConditions.denNgay
                  ? moment(filterConditions.denNgay, FORMAT_ENGLISH)
                  : moment(moment(), FORMAT_MONTH)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"denNgay"}
              picker={FORMAT_DATE}
              notClear={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={8}>
          <Form.Item name="nguoiThaoTac" label="Người thao tác">
            <Select
              placeholder="Chọn người thao tác"
              allowClear
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {taiKhoan && taiKhoan.length > 0
                ? taiKhoan.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.userName + " - " + item.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={16}>
          <Form.Item name="searchTerm" label="Từ khóa tìm kiếm">
            <Input
              prefix={<SearchOutlined />}
              suffix={
                <Tooltip title={""}>
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
        <Col
          span={24}
          md={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button className="mr-24px" onClick={clear}>
            Bỏ lọc
          </Button>
          <Button
            type="primary"
            className="mr-24px"
            htmlType="submit"
            form="filter-form"
          >
            Tìm kiếm
          </Button>
          <ExportExcel
            endpoint={
              Endpoint.EXPORT_EXCEL_LOG_THAO_TAC +
              "?" +
              buildQueryString(parseParams(filterConditions))
            }
            permissionCode={cap_dien.a570_xuat}
            text={"Xuất excel"}
          />
        </Col>
      </Row>
    </FormComponent>
  );
}
