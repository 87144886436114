import React from "react";
import { Button, Col, Modal, Row } from "antd";
import LoadingComponent from "@components/Loading";
import { Container } from "./css-styled";

export default function DeleteHoSoModal(props) {
  const { loading, visible, handleCloseModal, onFinish, t } = props;
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={"Xóa hồ sơ"}
        visible={visible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Container>
          <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
            Hồ sơ của bạn sẽ bị xoá khỏi cơ sở dữ liệu. Bạn có chắc chắn muốn
            thực hiện?
          </h4>
          <Row gutter={24} justify="center" style={{ marginTop: "48px" }}>
            <Col span={12} style={{ textAlign: "right" }}>
              <Button
                key="back"
                onClick={handleCloseModal}
                className="button-closed"
              >
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="danger"
                className="primary"
                onClick={onFinish}
                key="confirm"
                loading={loading}
              >
                {t("button.dong_y")}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal>
    </LoadingComponent>
  );
}
