import { BASE_API_URL_DANH_MUC } from "@utils/constants";

export const APIDiaChinh = {
  LIST_DIA_CHINH: `${BASE_API_URL_DANH_MUC}/dia-chinh/list`,
  LIST_PHUONG_XA: `${BASE_API_URL_DANH_MUC}/dia-chinh/list-phuong-xa`,
  CREATE_DIA_CHINH: `${BASE_API_URL_DANH_MUC}/dia-chinh/create`,
  UPDATE_DIA_CHINH: `${BASE_API_URL_DANH_MUC}/dia-chinh/update`,
  REMOVE_DIA_CHINH: `${BASE_API_URL_DANH_MUC}/dia-chinh/delete`,
  SYNC_DIA_CHINH: `${BASE_API_URL_DANH_MUC}/dia-chinh/sync`,
};
