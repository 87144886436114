import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { getErrorMessage } from "@utils/function";
import { startDelete } from "@utils/request";
// call api hủy bảng kê

export const handleHuyBangKe = (
  id,
  setLoading,
  setFetchDataTable,
  handleCloseModalhuy,
  values,
  form
) => {
  const lyDo = values.lyDoHuy ? "&LyDoHuy=" + values.lyDoHuy : "";
  startDelete({
    url: `${Endpoint.HUY_BANGKE}?BangKeId=${id}${lyDo}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModalhuy();
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};
