import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";
import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";

export const handleListDataNN = (setLoading, setDataNhomNN) => {
  authGetData({
    url: Endpoint.AG_COMMON_NHOM_NN,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataNhomNN(res.data);
      }
      else setDataNhomNN(res.data);
    },
  });
};

export const getListDonViService = (setLoading, setDataDonVi) => {
  authGetData({
    url: `${Endpoint.GET_UNIT_LIST}?pageSize=-1`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDonVi(res.data);
      } else setDataDonVi([]);
    },
  });
};
export const handleListDataTram = (setLoading, setDataNhomTram, id) => {
  authGetData({
    url: `${Endpoint.LIST_TRAM_CT}?donviId=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        res.data.forEach(function (row, i) {
          row.maTram = res.data[i].name.substr(0, res.data[i].name.indexOf('-')).trim();
        });
        setDataNhomTram(res.data);
      }
      else setDataNhomTram(res.data);
    },
  });
};

export const handleDetailDiemDo = (id, setDetail, setLoading) => {
  authGetData({
    url: `${Endpoint.AG_CHI_TIET_YC}?Id=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        res.data?.danhSachHoDungChung.forEach(function (row, index) {
          row.rowNumber = index;
        });
        res.data?.danhSachHoDungChungNew.forEach(function (row, index) {
          row.rowNumber = index;
        });
        res.data?.nhatKy.forEach(function (row, index) {
          row.rowNumber = index;
        });
        setDetail(res.data);
      }
      else setDetail(res.data);
    },
  });
};

export const submitBPKySoBK = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
) => {
  authPostData({
    url: Endpoint.AG_BP_KY_BK,
    method: METHOD_PUT,
    payload: {
      ids: [selectedRowKeys],
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
      }
    },
  });
};

export const submitNVKySoBK = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
) => {
  authPostData({
    url: Endpoint.AG_NV_KY_BK,
    method: METHOD_PUT,
    payload: {
      ids: [selectedRowKeys],
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
      }
    },
  });
};

export const submitBPTraLaiBK = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys,
  lyDo,
  getDetaiBK
) => {
  authPostData({
    url: Endpoint.AG_BP_TRA_LAI_BK,
    method: METHOD_PUT,
    payload: {
      ids: selectedRowKeys,
      lyDoTraLai: lyDo
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
        getDetaiBK();
      }
    },
  });
};

export const handleDetailBK = (id, setDetailBK, setLoading) => {
  authGetData({
    url: `${Endpoint.AG_CHI_TIET_BK}?Id=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDetailBK(res.data);
      }
      else setDetailBK(res.data);
    },
  });
};

export const submitBPTraLai = (
  setLoading,
  setFetchDataTable,
  idBK,
  selectedRowKeys,
  setSelectedRowKeys,
  lyDo,
  getDetaiBK
) => {
  authPostData({
    url: Endpoint.AG_BP_TRA_LAI_BK_YC,
    method: METHOD_PUT,
    payload: {
      id:idBK,
      yeuCauIds: selectedRowKeys,
      lyDoTraLai: lyDo
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
        getDetaiBK();
      }
    },
  });
};

export const handleDBAnhHT = (id, setLoading, setDetail, handleDetailDiemDo) => {
  authGetData({
    url: `${Endpoint.AG_DONG_BO_ANH_HT}?YeuCauId=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        handleDetailDiemDo(id, setDetail, setLoading);
      }
    },
  });
};