import { useCallback } from "react";
import DSCongToModal from "./modal";
import { FORMAT_DATE, TYPE_THIET_BI } from "@utils/constants";
import moment from "moment";

export default function Index(props) {
  const {
    loading,
    setLoading,
    visible,
    closeModal,
    data,
    handleSearch,
    typeTreoThao,
    dataDetailBBLD,
    setDataDetailBBLD,
    typeThietBi,
    form,
    rowActive,
    isShowCongToThao,
  } = props;

  const handleChoice = useCallback(
    (item) => {
      //Call api lấy data detail CMIS
      // handleGetDetailCongToCMIS(
      //   item.maCongTo,
      //   setLoading,
      //   dataDetailBBLD,
      //   setDataDetailBBLD,
      //   closeModal,
      //   typeThietBi
      // );
      if (typeThietBi === TYPE_THIET_BI.CT) {
        setLoading(true);

        const data = {
          ...dataDetailBBLD,
          congToTreo: {
            ...dataDetailBBLD.congToTreo,
            ...item,
          },
        };
        setDataDetailBBLD(data);
        form.setFieldsValue({
          soBienBan: form.getFieldValue("soBienBan"),
          tenNhanVien: form.getFieldValue("tenNhanVien"),
          lyDoTreoThaoId: form.getFieldValue("lyDoTreoThaoId"),
          maSoChiHomTreo: form.getFieldValue("maSoChiHomTreo"),
          soVienChiHomTreo: form.getFieldValue("soVienChiHomTreo"),
          kimNiemChiSoTreo: form.getFieldValue("kimNiemChiSoTreo"),
          heSoNhanHeThongTreo: form.getFieldValue("heSoNhanHeThongTreo"),
          tinhTrangNiemPhongTreo: form.getFieldValue("tinhTrangNiemPhongTreo"),
        });
        if (isShowCongToThao) {
          form.setFieldsValue({
            heSoNhanHeThongThao: form.getFieldValue("heSoNhanHeThongThao"),
            tinhTrangNiemPhongThao: form.getFieldValue(
              "tinhTrangNiemPhongThao"
            ),
          });
        }

        closeModal(true);
        setLoading(false);
      } else if (typeThietBi === TYPE_THIET_BI.TI) {
        if (rowActive !== null && rowActive !== undefined) {
          setLoading(true);
          form.setFieldsValue({
            soBienBan: form.getFieldValue("soBienBan"),
            tenNhanVien: form.getFieldValue("tenNhanVien"),
            lyDoTreoThaoId: form.getFieldValue("lyDoTreoThaoId"),
            maSoChiHomTreo: form.getFieldValue("maSoChiHomTreo"),
            soVienChiHomTreo: form.getFieldValue("soVienChiHomTreo"),
            kimNiemChiSoTreo: form.getFieldValue("kimNiemChiSoTreo"),
            heSoNhanHeThongTreo: form.getFieldValue("heSoNhanHeThongTreo"),
            tinhTrangNiemPhongTreo: form.getFieldValue(
              "tinhTrangNiemPhongTreo"
            ),
            [`soThietBi-${rowActive}-ti`]: item[`soCongTo`] ?? "",
            [`namSanXuat-${rowActive}-ti`]: item[`namSanXuatStr`]
              ? moment(item[`namSanXuatStr`].toString())
              : "",

            [`ngayKiemDinh-${rowActive}-ti`]: item[`ngayKiemDinh`]
              ? moment(item[`ngayKiemDinh`], FORMAT_DATE)
              : "",
            [`loai-${rowActive}-ti`]: item[`maChungLoai`] ?? "",
            [`tySoBien-${rowActive}-ti`]: item["tySoBien"] ?? "",
            [`chiHopVien-${rowActive}-ti`]:
              item["maSoChiKiemDinh"] ?? item["soVienChiKiemDinh"] ?? "",
            [`temKDVien-${rowActive}-ti`]: item["maSoTemKiemDinh"] ?? "",
          });
          if (isShowCongToThao) {
            form.setFieldsValue({
              heSoNhanHeThongThao: form.getFieldValue("heSoNhanHeThongThao"),
              tinhTrangNiemPhongThao: form.getFieldValue(
                "tinhTrangNiemPhongThao"
              ),
            });
          }
          closeModal(true);
          setLoading(false);
        }
      } else {
        setLoading(true);
        form.setFieldsValue({
          soBienBan: form.getFieldValue("soBienBan"),
          tenNhanVien: form.getFieldValue("tenNhanVien"),
          lyDoTreoThaoId: form.getFieldValue("lyDoTreoThaoId"),
          maSoChiHomTreo: form.getFieldValue("maSoChiHomTreo"),
          soVienChiHomTreo: form.getFieldValue("soVienChiHomTreo"),
          kimNiemChiSoTreo: form.getFieldValue("kimNiemChiSoTreo"),
          heSoNhanHeThongTreo: form.getFieldValue("heSoNhanHeThongTreo"),
          tinhTrangNiemPhongTreo: form.getFieldValue("tinhTrangNiemPhongTreo"),
          [`soThietBi-${rowActive}-tu`]: item[`soCongTo`] ?? "",
          [`namSanXuat-${rowActive}-tu`]: item[`namSanXuatStr`]
            ? moment(item[`namSanXuatStr`].toString())
            : "",

          [`ngayKiemDinh-${rowActive}-tu`]: item[`ngayKiemDinh`]
            ? moment(item[`ngayKiemDinh`], FORMAT_DATE)
            : "",
          [`loai-${rowActive}-tu`]: item[`maChungLoai`] ?? "",
          [`tySoBien-${rowActive}-tu`]: item["tySoBien"] ?? "",
          [`chiHopVien-${rowActive}-tu`]:
            item["maSoChiKiemDinh"] ?? item["soVienChiKiemDinh"] ?? "",
          [`temKDVien-${rowActive}-tu`]: item["maSoTemKiemDinh"] ?? "",
        });
        if (isShowCongToThao) {
          form.setFieldsValue({
            heSoNhanHeThongThao: form.getFieldValue("heSoNhanHeThongThao"),
            tinhTrangNiemPhongThao: form.getFieldValue(
              "tinhTrangNiemPhongThao"
            ),
          });
        }
        closeModal(true);
        setLoading(false);
      }
    },
    [
      closeModal,
      dataDetailBBLD,
      form,
      isShowCongToThao,
      rowActive,
      setDataDetailBBLD,
      setLoading,
      typeThietBi,
    ]
  );

  return (
    <DSCongToModal
      loading={loading}
      visible={visible}
      closeModal={closeModal}
      data={data}
      handleSearch={handleSearch}
      typeTreoThao={typeTreoThao}
      dataDetailBBLD={dataDetailBBLD}
      setDataDetailBBLD={setDataDetailBBLD}
      handleChoice={handleChoice}
      typeThietBi={typeThietBi}
    />
  );
}
