import { Fragment, useState } from "react";
import DanhSachHoSo from "../../common-ho-so";
import { Form } from "antd";
// import { MenuOutlined } from "@ant-design/icons";
// import ListChucNang from "./list-chuc-nang";
import { getParamsUrlHoSo } from "@utils/function";
import { ENUM_FUNCTION } from "@utils/constants";
import { closeModal, openModal } from "@state/system-config/reducer";
import { useDispatch } from "react-redux";
import ThongTinChiTietTabs from "../../common-ho-so/chi-tiet-ho-so";
import { ListFilter } from "../../common-ho-so/list-bo-loc";
export default function Index() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fetchDataTableIfNeed] = useState(false);
  const [isDoubleClick] = useState(true);
  // const firstColumns = {
  //   title: "",
  //   key: "",
  //   dataIndex: "",
  //   render: () => (
  //     <div style={{ textAlign: "center" }}>
  //       <Dropdown
  //         overlay={
  //           <div className="list-function"
  //   style={{boxShadow:
  //       " 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
  //   }}
  // >
  //             {/* <ListChucNang
  //               data={row}
  //               setFetchDataTable={setFetchDataTable}
  //               setIsDoubleClick={setIsDoubleClick}
  //             /> */}
  //           </div>
  //         }
  //         trigger={["click"]}
  //       >
  //         <Button type="primary" icon={<MenuOutlined />}></Button>
  //       </Dropdown>
  //     </div>
  //   ),
  //   width: "2%",
  // };

  const detailHoSo = (record) => {
    if (isDoubleClick)
      return {
        onDoubleClick: () => {
          dispatch(
            openModal({
              content: (
                <ThongTinChiTietTabs
                  detailHoSo={record}
                  closeModal={closeModal}
                />
              ),
              size: "large",
              title: `${
                record.maYCKNCmis !== null ? record.maYCKNCmis + " - " : ""
              }${record.tenKhachHang !== null ? record.tenKhachHang : ""}`,
            })
          );
        },
      };
  };

  return (
    <Fragment>
      <DanhSachHoSo
        firstColumns={{}}
        onRow={detailHoSo}
        paramsUrl={getParamsUrlHoSo(ENUM_FUNCTION.DUOCPHANCONG)}
        funcID={ENUM_FUNCTION.DUOCPHANCONG}
        listFilter={
          <ListFilter form={form} fetchDataTableIfNeed={fetchDataTableIfNeed} />
        }
      />
    </Fragment>
  );
}
