import { Col, Row } from "antd";

export default function Index(props) {
  const { data } = props;
  return (
    <Row gutter={24} style={{ padding: "16px" }}>
      <Col span={12} md={8}>
        <h6 className="fs-12px c-t-label">Mã phiếu lệnh</h6>
        <h5 className="fs-14px font-bold">{data?.maPhieuLenh}</h5>
      </Col>
      <Col span={12} md={8}>
        <h6 className="fs-12px c-t-label">Địa điểm</h6>
        <h5 className="fs-14px font-bold">{data?.diaDiem}</h5>
      </Col>
      <Col span={12} md={8}>
        <h6 className="fs-12px c-t-label">Nội dung công tác</h6>
        <h5 className="fs-14px font-bold">{data?.noiDungCongTac}</h5>
      </Col>
      <Col span={12} md={12}>
        <h6 className="fs-12px c-t-label">Thời gian</h6>
        <h5 className="fs-14px font-bold">{data?.thoiGian}</h5>
      </Col>
      <Col span={12} md={12}>
        <h6 className="fs-12px c-t-label">Người chỉ huy</h6>
        <h5 className="fs-14px font-bold">{data?.nguoiChiHuy}</h5>
      </Col>

      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Nhân viên treo tháo</h6>
        <h5 className="fs-14px font-bold">{data?.maSSONVTT}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Bậc an toàn</h6>
        <h5 className="fs-14px font-bold">{data?.bacAnToanNVTT}</h5>
      </Col>
      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Nhân viên niêm phong</h6>
        <h5 className="fs-14px font-bold">{data?.maSSONVNP}</h5>
      </Col>

      <Col span={12} md={6}>
        <h6 className="fs-12px c-t-label">Bậc an toàn</h6>
        <h5 className="fs-14px font-bold">{data?.bacAnToanNVNP}</h5>
      </Col>
    </Row>
  );
}
