import CongSuatSuDungDienPanel from "../../panels/panels-cong-suat-dien/panel-cong-suat-su-dung-dien";
import CSTheoMucDichPanel from "../../panels/panels-cong-suat-dien/panel-cong-suat-theo-muc-dich";
import CSDaDangKyPanel from "../../panels/panels-cong-suat-dien/panel-cong-sat-da-dang-ky";
import LoadingComponent from "@components/Loading";
import { ENUM_MODE_PHUONGANCAPDIEN } from "@utils/constants";

export default function CongSuatThietBiTabs(props) {
  const {
    loading,
    dataCongSuatThietBi,
    detailLapPhuongAn,
    setLoading,
    setFetchDataCSTB,
    mode,
  } = props;
  return (
    <LoadingComponent loading={loading}>
      <CSTheoMucDichPanel detailCongSuat={dataCongSuatThietBi} />

      <CongSuatSuDungDienPanel
        detailLapPhuongAn={detailLapPhuongAn}
        setLoadingIndex={setLoading}
        setFetchDataCSTB={setFetchDataCSTB}
        dataTable={dataCongSuatThietBi?.thietBiDiens}
        sumCongSuat={dataCongSuatThietBi?.tongCong}
        mode={mode}
      />

      {
        Object.keys(dataCongSuatThietBi).length > 0 &&
          Number(dataCongSuatThietBi.tongCongSuatLapDat) < 40 ? null : (
          <CSDaDangKyPanel data={dataCongSuatThietBi} />
        )}
    </LoadingComponent>
  );
}
