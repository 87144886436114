import React, { useEffect, useRef, useState } from "react";
import LoadingComponent from "@components/Loading";
import { Button, Col, Collapse, Row } from "antd";
import FormComponent from "@components/Form";
import { ENUM_KYSO } from "@utils/constants";
import KySoComponent from "@components/KySo";
import ThongTinChung from "./panels/thong-tin-chung";
import DSVatTuTable from "./panels/danh-sach-vt";
import { closeModal } from "@state/system-config/reducer";
import moment from "moment";
import { Endpoint } from "@utils/endpoint";
import { scrollToTop } from "@utils/function";
import ButtonKySoHSM from "../../ky-so-hsm";

const { Panel } = Collapse;

export default function LayoutPhieuXuat(props) {
  const {
    loading,
    form,
    onFinishSubmit,
    checkTraLai,
    dataVT,
    columns,
    // handleTuChoi,
    defautInfo,
    setFetchList,
    selectRowKeys,
    dispatch,
    isTraLai,
    permissionCodeKySo,
    components,
    maTrangThai,
    defaultDataVT,
  } = props;
  const [, setLoading] = useState(false);
  useEffect(() => {
    form.setFieldsValue({
      loaiTreoThao: defautInfo.loaiTreoThao,
      doiId: defautInfo.doi,
      ngayTao: defautInfo.ngayTao ? moment(defautInfo.ngayTao) : moment(),
      nguoiTao: defautInfo.nguoiTao,
    });
  }, [defautInfo, form]);
  const ref1 = useRef();

  useEffect(() => {
    scrollToTop(ref1);
  }, []);
  return (
    <LoadingComponent loading={loading}>
      <div ref={ref1}>
        <FormComponent
          form={form}
          name="form-submit"
          layout="vertical"
          onFinish={onFinishSubmit}
          autoComplete="off"
        >
          <Collapse defaultActiveKey={["1", "2", "3"]}>
            <Panel header="Thông tin chung" key="1">
              <ThongTinChung
                form={form}
                checkTraLai={checkTraLai}
                defautInfo={defautInfo}
                isTraLai={isTraLai}
              />
            </Panel>
            <Panel header="Danh sách vật tư" key="2">
              <DSVatTuTable
                components={components}
                dataVT={dataVT}
                columns={columns}
              />
            </Panel>
          </Collapse>
          <Row gutter={24}>
            <Col span={24}>
              {isTraLai ? (
                <h4>Phiếu sẽ được trả về cho Đội trưởng !</h4>
              ) : (
                <h4>Phiếu sẽ được chuyển tiếp cho Lãnh đạo !</h4>
              )}
            </Col>
          </Row>
          <Row gutter={24} justify="center" style={{ marginTop: "48px" }}>
            <Button
              key="back"
              onClick={() => dispatch(closeModal())}
              className="button-closed"
              style={{ marginRight: "12px" }}
            >
              Đóng lại
            </Button>

            {isTraLai ? (
              <Button
                style={{ marginRight: "12px" }}
                key="tu-choi"
                className="button-closed  btn-bg-yellow"
                htmlType="submit"
              >
                Từ chối
              </Button>
            ) : null}

            {isTraLai ? null : (
              <Button
                type="primary"
                key="submit"
                className="button-closed"
                loading={loading}
                htmlType="submit"
                form="form-submit"
                style={{ marginRight: "12px" }}
              >
                Xác nhận
              </Button>
            )}
            {isTraLai ? null : (
              // <KySoComponent
              //   url={Endpoint.XAC_THUC_KY_SO_PHIEUVT}
              //   urlGetHash={Endpoint.KY_SO_GET_HASH_PHIEUVT}
              //   payload={{
              //     phieuIds: selectRowKeys,
              //     maTrangThai: maTrangThai,
              //   }}
              //   permissionCode={permissionCodeKySo}
              //   disabled={false}
              //   setFetchDataTable={setFetchList}
              //   text={"Ký số USB"}
              //   setLoading={setLoading}
              //   loading={loading}
              //   bgColor={"#faad14"}
              //   typeKySo={ENUM_KYSO.TREOTHAO}
              // />
              <ButtonKySoHSM
                url={Endpoint.KY_SO_PHIEU_VAT_TU_HSM}
                params={{ phieuIds: selectRowKeys, vatTuXuats: defaultDataVT }}
                setFetchList={setFetchList}
                data={dataVT}
              />

              // <ButtonKySoHSM
              //   url={Endpoint.KY_SO_YEU_CAU_HSM}
              //   params={{ yeuCauIds: selectRowKeys }}
              //   setFetchList={setFetchList}
              // />
            )}
          </Row>
        </FormComponent>
      </div>
      {/* </Modal> */}
    </LoadingComponent>
  );
}
