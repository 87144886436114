import styled from "styled-components";

export const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
`;

export const ContainerStyled = styled.div`
  padding: 10px;
`;
