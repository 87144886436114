import { Endpoint } from "@utils/endpoint";
import { authPostData } from "@utils/request";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";

export const submitLayDLCMIS = (
  setLoading,
  setDataDB,
  objectValue,
  setSelectedRowKeys,
  setFetchDataTable
) => {
  authPostData({
    url: Endpoint.CTOTHAO_DONG_BO_VE_WEB,
    method: METHOD_POST,
    payload: objectValue,
    setLoading,
    onSuccess: (res) => {
      const data = [];
      if (res.statusCode === STATUSCODE_200) {
        setSelectedRowKeys([]);
        setFetchDataTable(true);
        for (const key in res.data) {
          data.push({ bienBan: key, tongSo: res.data[key] })
        }
        setDataDB(data);
      } else {
        setDataDB();
      }
    },
  });
};

export const getDanhSachBienBan = (
  setLoading,
  setDataSource,
  bienBan,
  dataBienBan
) => {
  authPostData({
    url: Endpoint.CTOTHAO_DS_CONG_TO_DB,
    method: METHOD_POST,
    payload: {
      ids: bienBan
    },
    setLoading,
    onSuccess: (res) => {
      const tempBB = [];
      let temp = {};
      const tempdata = {}
      const tempData = [];
      const merged = [];
      if (res.statusCode === STATUSCODE_200) {
        // for (const key in res.data) {
        //   tempBB.push({ idBienBan: key, trangThai: res.data[key] })
        // }
        for (let i = 0; i < res.data?.length; i++ ) {
          tempBB.push({ idBienBan: res.data[i].maBienBan, trangThai: res.data[i].isSyncCmis ? 3 : res.data[i].isSyncWeb ? 2 : 1, })
        }
        for (let i = 0; i < dataBienBan.length; i++) {
          for (let j = 0; j < dataBienBan[i].children.length; j++) {
            tempdata.ngayGui = dataBienBan[i].title;
            tempdata.idBienBan = dataBienBan[i].children[j].title.split(" - ")[0];
            temp = { ...tempdata }
            tempData.push(temp)
          }
        }
        const myArrayFiltered = tempData.filter((el) => {
          return tempBB.some((f) => {
            return f.idBienBan === el.idBienBan;
          });
        });
        for(let i=0; i<tempBB.length; i++) {
          merged.push({
           ...tempBB[i], 
           ...(myArrayFiltered.find((itmInner) => itmInner.idBienBan === tempBB[i].idBienBan))}
          );
        }
        setDataSource(merged);
      }
    },
  });
};
