import React, { Fragment } from "react";

import LoadingComponent from "@components/Loading";
import styled from "styled-components";
export default function ChiTietYeuCauTabMaTram(props) {
  const { detailThoiGian, loading } = props;
  // // lay danh sach chi tiet yeu cau

  return (
    <Fragment>
      <Container>
        {detailThoiGian.maTramList && detailThoiGian.maTramList.length > 0 ? (
          <LoadingComponent loading={loading}>
            {/* thông tin hồ sơ */}
            <div className="wrap-fields">
              <span className="fs-14px font-bold">
                <p className="wrap-text-tram">
                  {/* {detailThoiGian.maTramList
                  .split(" ")
                  .join("-")} */}
                  {detailThoiGian.maTramList.join(" - ")}
                </p>
              </span>
            </div>
          </LoadingComponent>
        ) : (
          <Container>
            <div className="wrap-fields">
              <span className="fs-14px font-bold">
                <p className="wrap-text-tram">
                  <p className="wrap-text-tram-data">
                    Hiện tại đơn vị không có mã trạm nào.
                  </p>
                </p>
              </span>
            </div>
          </Container>
        )}
      </Container>
    </Fragment>
  );
}
const Container = styled.div`
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
  .ant-collapse.ant-collapse-icon-position-left {
    margin-top: 20px;
  }
  .wrap-fields {
    word-break: break-all;
  }
  p.wrap-text-tram {
    color: black;
    padding: 0 40px 0 40px;
  }
  p.wrap-text-tram-data {
    text-align: center;
    color: red;
  }
`;
