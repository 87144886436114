import { Form } from "antd";
import { useCallback, useEffect } from "react";
import "moment/locale/vi";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import FormBoLoc from "./form-bo-loc";

export default function ListFilter(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    handleBaoCaoChiTiet,
    donVi,
    selectedRowKeys,
    isCompany,
    getListBaoCao,
    setSelectedRowKeys,
    disabledDaChot,
    disabledHuyChot,
    loaiYeuCau,
    setType,
  } = props;
  const [form] = Form.useForm();
  const { user } = useSelector(authSelector);

  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);

  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  return (
    <FormBoLoc
      form={form}
      handleSearch={handleSearch}
      user={user}
      filterConditions={filterConditions}
      clear={clear}
      donVi={donVi}
      handleBaoCaoChiTiet={handleBaoCaoChiTiet}
      selectedRowKeys={selectedRowKeys}
      isCompany={isCompany}
      getListBaoCao={getListBaoCao}
      setSelectedRowKeys={setSelectedRowKeys}
      disabledDaChot={disabledDaChot}
      disabledHuyChot={disabledHuyChot}
      loaiYeuCau={loaiYeuCau}
      setType={setType}
    />
  );
}
