//1. Tổng hợp số lượng KH đăng ký nhận thông báo qua Email, App EVNHANOI, Epoint, Zalo
export const colummsTHSL = [
  {
    title: "STT",
    dataIndex: "STT",
    key: "STT",
    width: "70px",
  },
  {
    title: "Đơn vị",
    dataIndex: "MA_DVIQLY",
    key: "MA_DVIQLY",
  },
  {
    title: "Email",
    dataIndex: "TONG_EMAIL",
    key: "TONG_EMAIL",
  },
  {
    title: "App EVNHANOI",
    dataIndex: "TONG_APP",
    key: "TONG_APP",
  },
  {
    title: "Epoint",
    dataIndex: "TONG_EPONT",
    key: "TONG_EPONT",
  },
  {
    title: "Zalo",
    dataIndex: "TONG_ZALO",
    key: "TONG_ZALO",
  },
  {
    title: "Tổng",
    dataIndex: "TONG",
    key: "TONG",
  },
];

// 2. Chi tiết KH đăng ký nhận thông báo qua Email
export const colummsKHnhanEmail = [
  {
    title: "STT",
    dataIndex: "STT",
    key: "STT",
  },
  {
    title: "Đơn vị",
    dataIndex: "MA_DVIQLY",
    key: "MA_DVIQLY",
  },
  {
    title: "Mã tổ",
    dataIndex: "MA_TO",
    key: "MA_TO",
  },
  {
    title: "Mã sổ GCS",
    dataIndex: "MA_SOGCS",
    key: "MA_SOGCS",
  },
  {
    title: "Mã khách hàng",
    dataIndex: "MA_KHANG",
    key: "MA_KHANG",
  },
  {
    title: "Tên khách hàng",
    dataIndex: "TEN_KHANG",
    key: "TEN_KHANG",
  },
  {
    title: "Địa chỉ khách hàng",
    dataIndex: "DUONG_PHO",
    key: "DUONG_PHO",
  },
  {
    title: "Địa chỉ email",
    dataIndex: "EMAIL",
    key: "EMAIL",
  },
  {
    title: "Ngày đăng ký",
    dataIndex: "NGAY_DK",
    key: "NGAY_DK",
  },
];

// 3. Chi tiết KH đăng ký nhận thông báo qua App
export const colummsKHnhanApp = [
  {
    title: "STT",
    dataIndex: "STT",
    key: "STT",
    width: "70px",
  },
  {
    title: "Đơn vị",
    dataIndex: "MA_DVIQLY",
    key: "MA_DVIQLY",
  },
  {
    title: "Mã tổ",
    dataIndex: "MA_TO",
    key: "MA_TO",
  },
  {
    title: "Mã sổ GCS",
    dataIndex: "MA_SOGCS",
    key: "MA_SOGCS",
  },
  {
    title: "Mã khách hàng",
    dataIndex: "MA_KHANG",
    key: "MA_KHANG",
  },
  {
    title: "Tên khách hàng",
    dataIndex: "TEN_KHANG",
    key: "TEN_KHANG",
  },
  {
    title: "Địa chỉ khách hàng",
    dataIndex: "DUONG_PHO",
    key: "DUONG_PHO",
  },
  {
    title: "Ngày đăng ký",
    dataIndex: "NGAY_DK",
    key: "NGAY_DK",
  },
];

// 4. Chi tiết KH đăng ký nhận thông báo qua Zalo
export const colummsKHnhanZalo = [
  {
    title: "STT",
    dataIndex: "STT",
    key: "STT",
    width: "70px",
  },
  {
    title: "Đơn vị",
    dataIndex: "MA_DVIQLY",
    key: "MA_DVIQLY",
  },
  {
    title: "Mã tổ",
    dataIndex: "MA_TO",
    key: "MA_TO",
  },
  {
    title: "Mã sổ GCS",
    dataIndex: "MA_SOGCS",
    key: "MA_SOGCS",
  },
  {
    title: "Mã khách hàng",
    dataIndex: "MA_KHANG",
    key: "MA_KHANG",
  },
  {
    title: "Tên khách hàng",
    dataIndex: "TEN_KHANG",
    key: "TEN_KHANG",
  },
  {
    title: "Địa chỉ khách hàng",
    dataIndex: "DUONG_PHO",
    key: "DUONG_PHO",
  },
  {
    title: "ID Zalo",
    dataIndex: "unitId",
    key: "unitId",
  },
  {
    title: "Ngày đăng ký",
    dataIndex: "NGAY_DK",
    key: "NGAY_DK",
  },
];

// 5. Chi tiết KH đăng ký nhận thông báo qua Epoint
export const colummsKHnhanEpoint = [
  {
    title: "STT",
    dataIndex: "STT",
    key: "STT",
    width: "70px",
  },
  {
    title: "Đơn vị",
    dataIndex: "MA_DVIQLY",
    key: "MA_DVIQLY",
  },
  {
    title: "Mã tổ",
    dataIndex: "MA_TO",
    key: "MA_TO",
  },
  {
    title: "Mã sổ GCS",
    dataIndex: "MA_SOGCS",
    key: "MA_SOGCS",
  },
  {
    title: "Mã khách hàng",
    dataIndex: "MA_KHANG",
    key: "MA_KHANG",
  },
  {
    title: "Tên khách hàng",
    dataIndex: "TEN_KHANG",
    key: "TEN_KHANG",
  },
  {
    title: "Địa chỉ khách hàng",
    dataIndex: "DUONG_PHO",
    key: "DUONG_PHO",
  },
  {
    title: "Ngày đăng ký",
    dataIndex: "NGAY_DK",
    key: "NGAY_DK",
  },
];

// 6. Chi tiết TB Tiền điện lần 1 gửi qua Email, App, Epoint, Zalo

export const colummsCTTBTienDienLan1 = [
  {
    title: "STT",
    dataIndex: "STT",
    key: "STT",
    width: "70px",
  },
  {
    title: "Đơn vị",
    dataIndex: "MA_DVIQLY",
    key: "MA_DVIQLY",
  },
  {
    title: "Mã tổ",
    dataIndex: "MA_TO",
    key: "MA_TO",
  },
  {
    title: "Mã sổ GCS",
    dataIndex: "MA_SOGCS",
    key: "MA_SOGCS",
  },
  {
    title: "Mã khách hàng",
    dataIndex: "MA_KHANG",
    key: "MA_KHANG",
  },
  {
    title: "Tên khách hàng",
    dataIndex: "TEN_KHANG",
    key: "TEN_KHANG",
  },
  {
    title: "Địa chỉ khách hàng",
    dataIndex: "DUONG_PHO",
    key: "DUONG_PHO",
  },
  {
    title: "Địa chỉ email",
    dataIndex: "EMAIL",
    key: "EMAIL",
  },
  {
    title: "Ngày đăng ký",
    dataIndex: "NGAY_DK",
    key: "NGAY_DK",
  },
];
