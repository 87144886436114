import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  METHOD_GET,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  handlePagination,
  nullToString,
  removeAccents,
  stringToSlug,
} from "@utils/function";
import { authGetData } from "@utils/request";
import { Button, Col, Form, Input, InputNumber, Row, Spin } from "antd";
import { useRef } from "react";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { handleCreateData, SelectKhuCongNghiep } from "./service";
export default function DanhMucDiemDoDRMS() {
  const [screen] = useState();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState(data);
  const [maDV, setMaDV] = useState("");
  const [maDD, setMaDD] = useState("");
  const [tenDD, setTenDD] = useState("");
  const [maKH, setMaKH] = useState("");
  const [khuCongNghiep, setKhuCongNghiep] = useState("");
  const [tenLoTA, setTenLoTA] = useState("");
  const [congSuat, setCongSuat] = useState("");
  const [heSoNhan, setHeSoNhan] = useState("");

  useEffect(() => {
    setDataFilter(data);
  }, [data]);

  const user = useSelector(authSelector);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
  });

  const getListDanhMucDiemDo = useEffect(() => {
    authGetData({
      url: `${Endpoint.BC_NCPT_DANH_SACH_DIEM_DO_DRMS}`,
      method: METHOD_GET,
      payload: {},
      setLoading,
      onSuccess: (res) => {
        setData(res.data);
      },
    });
  }, []);

  //pagination table
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const onFinish = (values) => {
    const url = Endpoint.BC_NCPT_THEM_DIEM_DO_DRMS;
    handleCreateData(values, setLoading, getListDanhMucDiemDo, form, url);
  };

  const FilterMaDonVi = () => (
    <div>
      <div style={{ minHeight: "50px" }}>Mã Đơn Vị</div>
      <div onClick={(e) => e.stopPropagation()}>
        <Input
          style={{ height: 30, marginTop: "10px" }}
          onChange={(e) => {
            const currValue = e.target.value;
            setMaDV(currValue);
          }}
        />
      </div>
    </div>
  );

  const FilterTenLoTrungAp = () => (
    <div>
      <div style={{ minHeight: "50px" }}>Tên lộ trung áp</div>
      <div onClick={(e) => e.stopPropagation()}>
        <Input
          style={{ height: 30, marginTop: "10px" }}
          onChange={(e) => {
            const currValue = e?.target.value;
            setTenLoTA(currValue);
          }}
        />
      </div>
    </div>
  );

  const FilterMaDD = (
    <div>
      <div style={{ minHeight: "50px" }}>Mã điểm đo</div>
      <div onClick={(e) => e.stopPropagation()}>
        <Input
          style={{ height: 30, marginTop: "10px" }}
          onChange={(e) => {
            const currValue = e ? e.target.value : "";
            setMaDD(currValue);
          }}
        />
      </div>
    </div>
  );

  const FilterTenDiemDo = (
    <div>
      <div style={{ minHeight: "50px" }}>Tên điểm đo</div>
      <div onClick={(e) => e.stopPropagation()}>
        <Input
          style={{ height: 30, marginTop: "10px" }}
          onChange={(e) => {
            const currValue = e ? e.target.value : "";
            setTenDD(currValue);
          }}
        />
      </div>
    </div>
  );

  const FilterMaKH = (
    <div>
      <div style={{ minHeight: "50px" }}>Mã khách hàng</div>
      <div onClick={(e) => e.stopPropagation()}>
        <Input
          style={{ height: 30, marginTop: "10px" }}
          onChange={(e) => {
            const currValue = e ? e.target.value : "";
            setMaKH(currValue);
          }}
        />
      </div>
    </div>
  );

  const FilterKhuCN = (
    <div>
      <div style={{ minHeight: "50px" }}>Khu công nghiệp</div>
      <div onClick={(e) => e.stopPropagation()}>
        <Input
          style={{ height: 30, marginTop: "10px" }}
          onChange={(e) => {
            const currValue = e ? e.target.value : "";
            setKhuCongNghiep(currValue);
          }}
        />
      </div>
    </div>
  );

  const FilterCongSuat = (
    <div>
      <div style={{ minHeight: "50px" }}>Công suất</div>
      <div onClick={(e) => e.stopPropagation()}>
        <Input
          style={{ height: 30, marginTop: "10px" }}
          onChange={(e) => {
            const currValue = e ? e.target.value : "";
            setCongSuat(currValue);
          }}
        />
      </div>
    </div>
  );

  const FilterHeSoNhan = (
    <div>
      <div style={{ minHeight: "50px" }}>Hệ số nhân</div>
      <div onClick={(e) => e.stopPropagation()}>
        <Input
          style={{ height: 30, marginTop: "10px" }}
          onChange={(e) => {
            const currValue = e ? e.target.value : "";
            setHeSoNhan(currValue);
          }}
        />
      </div>
    </div>
  );

  const columns = [
    {
      title: FilterMaDonVi,
      dataIndex: "MA_DVIQLY",
      key: "MA_DVIQLY",
      align: "center",
      width: "10%",
      sorter: (a, b) => a.MA_DVIQLY.slice(2) - b.MA_DVIQLY.slice(2),
    },
    {
      title: FilterMaDD,
      dataIndex: "MA_DDO",
      key: "MA_DDO",
      sorter: (a, b) => a.MA_DVIQLY.slice(2) - b.MA_DVIQLY.slice(2),
    },
    {
      title: FilterTenDiemDo,
      dataIndex: "TEN_DDO",
      key: "TEN_DDO",
      align: "center",
    },
    {
      title: FilterMaKH,
      dataIndex: "MA_KHANG",
      key: "MA_KHANG",
      sorter: (a, b) => a.MA_KHANG - b.MA_KHANG,
      align: "center",
    },
    {
      title: FilterKhuCN,
      dataIndex: "KHU_CONGNGHIEP",
      key: "KHU_CONGNGHIEP",
      align: "center",
    },
    {
      title: FilterTenLoTrungAp,
      dataIndex: "TENLO_TRUNGAP",
      key: "TENLO_TRUNGAP",
      align: "center",
    },
    {
      title: FilterCongSuat,
      dataIndex: "CONGSUAT",
      key: "CONGSUAT",
      sorter: (a, b) => a.CONGSUAT - b.CONGSUAT,
      align: "center",
    },
    {
      title: FilterHeSoNhan,
      dataIndex: "HSN",
      key: "HSN",
      sorter: (a, b) => a.HSN - b.HSN,
      align: "center",
    },
  ];

  //mutil filter
  useEffect(() => {
    setDataFilter(
      data?.filter(
        (e) =>
          stringToSlug(removeAccents(nullToString(e?.MA_DVIQLY))).includes(
            stringToSlug(removeAccents(maDV))
          ) &&
          stringToSlug(removeAccents(nullToString(e?.MA_DDO))).includes(
            stringToSlug(removeAccents(maDD))
          ) &&
          stringToSlug(removeAccents(nullToString(e?.TEN_DDO))).includes(
            stringToSlug(removeAccents(tenDD))
          ) &&
          stringToSlug(removeAccents(nullToString(e?.MA_KHANG))).includes(
            stringToSlug(removeAccents(maKH))
          ) &&
          stringToSlug(removeAccents(nullToString(e?.KHU_CONGNGHIEP))).includes(
            stringToSlug(removeAccents(khuCongNghiep))
          ) &&
          stringToSlug(removeAccents(nullToString(e?.TENLO_TRUNGAP))).includes(
            stringToSlug(removeAccents(tenLoTA))
          ) &&
          stringToSlug(removeAccents(nullToString(e?.CONGSUAT))).includes(
            stringToSlug(removeAccents(congSuat))
          ) &&
          stringToSlug(removeAccents(nullToString(e?.HSN))).includes(
            stringToSlug(removeAccents(heSoNhan))
          )
      )
    );
  }, [maKH, maDV, tenDD, maDD, khuCongNghiep, tenLoTA, congSuat, heSoNhan]);

  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <Spin spinning={loading}>
            <Title>Danh mục điểm đo DRMS</Title>
            <FormComponent
              onFinish={onFinish}
              initialValues={{
                reportType: screen,
                unitId: user.user.unitCode,
                CongSuat: 0,
                HeSoNhan: 0,
              }}
              form={form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              colon={false}
            >
              <Row>
                <Col span={12}>
                  {" "}
                  <Form.Item
                    name="mA_DVIQLY"
                    label="Đơn vị"
                    labelAlign="left"
                    required
                  >
                    <Selection
                      url={Endpoint.GET_TEAM_LIST_CODE}
                      formKey="mA_DVIQLY"
                      form={form}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="mA_DDO"
                    label="Mã điểm đo"
                    labelAlign="left"
                    required
                  >
                    <Input placeholder="Nhập mã điểm đo" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  {" "}
                  <Form.Item
                    name="teN_DDO"
                    label="Tên điểm đo"
                    labelAlign="left"
                    required
                  >
                    <Input placeholder="Nhập mã điểm đo" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {" "}
                  <Form.Item
                    name="mA_KHANG"
                    label="Mã khách hàng"
                    labelAlign="left"
                    required
                  >
                    <Input placeholder="Nhập mã khách hàng" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  {" "}
                  <Form.Item
                    name="kcn"
                    label="Khu Công nghiệp"
                    labelAlign="left"
                    required
                  >
                    <SelectKhuCongNghiep
                      url={Endpoint.BC_NCPT_DANH_SACH_KHU_CONG_NGHIEP}
                      formKey="KhuCongNghiep"
                      form={form}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {" "}
                  <Form.Item
                    name="tenlO_TRUNGAP"
                    label="Tên lộ trung áp"
                    labelAlign="left"
                    required
                  >
                    <Input placeholder="Nhập tên lộ trung áp" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="congsuat"
                    label="Công suất"
                    labelAlign="left"
                    required
                  >
                    <InputNumber placeholder="Nhập công suất" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {" "}
                  <Form.Item
                    name="hsn"
                    label="Hệ số nhân"
                    labelAlign="left"
                    required
                  >
                    <InputNumber placeholder="Nhập hệ số nhân" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                {" "}
                <Col span={12}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginBottom: "20px" }}
                  >
                    Cập nhật
                  </Button>
                </Col>{" "}
                <Col span={12}>
                  <Button type="primary">Import</Button>
                </Col>
              </Row>
            </FormComponent>
            <TableComponent
              header="Thông tin hiển thị"
              columns={columns}
              dataSource={dataFilter}
              pagination={filterConditions}
              onChangePagination={onChangePagination}
            />
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}
const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;

const Title = styled.div`
  font-size: 20px;
  padding-bottom: 25px;
  color: red;
`;
