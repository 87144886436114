import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import {
  alertMessage,
  // buildQueryString,
  formatDisplayMoney,
  formatSplitDate,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import { openModal } from "@state/system-config/reducer";
import DuLieuDienHaAp from "../../panels";
import UIBaoCaoFrom89To160 from "./table";
import {
  apiChotBaoCao,
  apiHuyChotBaoCao,
  handleListBaoCao,
} from "../../services";
import PencilIcon from "@assets/icon/PencilIcon";
import moment from "moment";
// import { Endpoint } from "@utils/endpoint";

export default function Index(props) {
  const {
    handleChangeUnit,
    donVi,
    rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,
  } = props;
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector(authSelector);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);
  const [addParam, setAddParam] = useState(false);
  const [isChot, setIsChot] = useState(false);
  //filter
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    donViId: user.unitId,
    loaiBaoCao: 1,
    thangBaoCao: moment(moment().startOf(MONTH_STRING)).format(FORMAT_ENGLISH),
    // startDateBaoCao: undefined,
    // endDateBaoCao: undefined,
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //change pagination
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  };

  const [value, setValue] = useState(1);
  const handleChangeValue = (value) => {
    setValue(value);
  };

  //fetch báo cáo
  const getListBaoCao = useCallback(() => {
    handleListBaoCao(
      setLoading,
      filterConditions,
      setData,
      setTotalCount,
      setIsChot
    );
  }, [filterConditions]);

  const [chotDuLieu, setChotDuLieu] = useState(false);
  const [huyChotDuLieu, setHuyChotDuLieu] = useState(false);

  const handleChotDuLieu = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.TIM_KIEM) {
      setType(ENUM_BUTTON_TYPE.TIM_KIEM);
    } else if (type === ENUM_BUTTON_TYPE.CHOT_DU_LIEU) {
      setChotDuLieu(true);
    } else if (type === ENUM_BUTTON_TYPE.HUY_CHOT_DU_LIEU) {
      setHuyChotDuLieu(true);
    } else {
      setChotDuLieu(false);
      setHuyChotDuLieu(false);
    }
  }, [type]);
  //search
  const handleSearch = useCallback(
    (values) => {
      if (values.loaiBaoCao === 1) {
        // console.log();
        setFilterConditions(() => ({
          // ...oldState,
          pageIndex: DEFAULT_PAGEINDEX,
          pageSize: DEFAULT_PAGESIZE,
          // ...values,
          loaiBaoCao: value,
          donViId: values.donViId,
          thangBaoCao: formatSplitDate(values.thangBaoCao, true),
        }));
        setAddParam(false);
        handleChotDuLieu();
      } else if (values.loaiBaoCao === 4) {
        if (
          values.startDateBaoCao !== undefined &&
          values.startDateBaoCao !== null &&
          values.endDateBaoCao !== undefined &&
          values.endDateBaoCao !== null
        ) {
          // eslint-disable-next-line no-self-compare
          if (values.startDateBaoCao <= values.endDateBaoCao) {
            setFilterConditions(() => ({
              pageIndex: DEFAULT_PAGEINDEX,
              pageSize: DEFAULT_PAGESIZE,
              loaiBaoCao: value,
              donViId: values.donViId,
              // thangBaoCao: formatSplitDate(values.thangBaoCao, true),
              startDateBaoCao: values.startDateBaoCao,
              endDateBaoCao: values.endDateBaoCao,
            }));
            setAddParam(true);
            handleChotDuLieu();
          } else {
            alertMessage(
              "error",
              "Thông báo",
              "Từ ngày báo cáo phải nhỏ hơn đến ngày báo cáo!"
            );
          }
        } else {
          alertMessage(
            "error",
            "Thông báo",
            "Vui lòng chọn giá trị ngày báo cáo!"
          );
        }
      }
    },
    [handleChotDuLieu, value]
  );

  //clear
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      donViId: user.unitId,
      loaiBaoCao: 1,
      thangBaoCao: moment(moment().startOf(MONTH_STRING)).format(
        FORMAT_ENGLISH
      ),
    });
    setAddParam(false);
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
    setValue(1);
  }, [user.unitId]);

  useEffect(() => {
    if (chotDuLieu) {
      let payload = {
        donViId: filterConditions.donViId,
        thangBaoCao: filterConditions.thangBaoCao,
        loaiBaoCao: value,
      };
      if (addParam) {
        payload = {
          donViId: filterConditions.donViId,
          // thangBaoCao: filterConditions.thangBaoCao,
          loaiBaoCao: value,
          startDateBaoCao: moment(
            moment(filterConditions.startDateBaoCao, FORMAT_ENGLISH)
          ).format(FORMAT_ENGLISH),
          endDateBaoCao: moment(
            moment(filterConditions.endDateBaoCao, FORMAT_ENGLISH)
          ).format(FORMAT_ENGLISH),
        };
      }
      apiChotBaoCao(setLoading, payload, getListBaoCao);
      setChotDuLieu(false);
    } else if (huyChotDuLieu) {
      let payload = {
        donViId: filterConditions.donViId,
        thangBaoCao: filterConditions.thangBaoCao,
        loaiBaoCao: value,
      };
      if (addParam) {
        payload = {
          donViId: filterConditions.donViId,
          // thangBaoCao: filterConditions.thangBaoCao,
          loaiBaoCao: value,
          startDateBaoCao: moment(
            moment(filterConditions.startDateBaoCao, FORMAT_ENGLISH)
          ).format(FORMAT_ENGLISH),
          //filterConditions.startDateBaoCao.format(FORMAT_ENGLISH),
          endDateBaoCao: moment(
            moment(filterConditions.endDateBaoCao, FORMAT_ENGLISH)
          ).format(FORMAT_ENGLISH),
          //filterConditions.endDateBaoCao.format(FORMAT_ENGLISH),
        };
      }
      apiHuyChotBaoCao(setLoading, payload, getListBaoCao);
      setHuyChotDuLieu(false);
    }
  }, [
    value,
    chotDuLieu,
    huyChotDuLieu,
    addParam,
    filterConditions,
    getListBaoCao,
  ]);

  useEffect(() => {
    if (type === ENUM_BUTTON_TYPE.TIM_KIEM) getListBaoCao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListBaoCao]);

  //columns
  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 80,
      render: (text, record, index) => (
        <div className="table-text-center">{index + 1}</div>
      ),
      fixed: "left",
    },
    {
      title: "Mã hồ sơ",
      dataIndex: "maHoSo",
      key: "maHoSo",
      width: 120,
      render: (text) => <div className="table-text-left">{text}</div>,
      sorter: true,
      fixed: "left",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      width: 200,
      render: (text) => <div className="table-text-left">{text}</div>,
      sorter: true,
      fixed: "left",
    },
    {
      title: "Mã yêu cầu",
      dataIndex: "maYeuCau",
      key: "maYeuCau",
      width: 130,
      render: (text, record) => (
        <div
          className="table-text-left ant-btn-link cursor-pointer"
          onClick={() =>
            dispatch(
              openModal({
                content: (
                  <DuLieuDienHaAp
                    typeDetail={true}
                    detailBaoCao={record}
                    getListBaoCao={getListBaoCao}
                    thangBaoCao={filterConditions.thangBaoCao}
                  />
                ),
                size: "large",
                title: "Xem dữ liệu cấp điện hạ áp",
              })
            )
          }
        >
          {text}
        </div>
      ),
      sorter: true,
      fixed: "left",
    },
    {
      title: "Địa chỉ sử dụng điện",
      dataIndex: "diaChiSuDungDien",
      key: "diaChiSuDungDien",
      width: 250,
      render: (text) => <div className="table-text-left">{text}</div>,
      sorter: true,
    },
    {
      title: "Loại yêu cầu",
      dataIndex: "loaiYeuCau",
      key: "loaiYeuCau",
      width: 130,
      render: (text) => <div className="table-text-left">{text}</div>,
      sorter: true,
    },
    {
      title: "Hình thức cấp điện",
      dataIndex: "hinhThucCapDien",
      key: "hinhThucCapDien",
      width: 150,
      render: (text) => <div className="table-text-left">{text}</div>,
      sorter: true,
    },
    {
      title: "Tên trạm biến áp",
      dataIndex: "tenTramBienAp",
      key: "tenTramBienAp",
      width: 150,
      render: (text) => <div className="table-text-left">{text}</div>,
      sorter: true,
    },
    {
      title: "Mã trạm",
      dataIndex: "maTram",
      key: "maTram",
      width: 130,
      render: (text) => <div className="table-text-left">{text}</div>,
      sorter: true,
    },
    {
      title: "Số lượng MBA (Máy)",
      dataIndex: "soLuongMBA",
      key: "soLuongMBA",
      width: 120,
      render: (text) => <div className="table-text-right">{text}</div>,
      sorter: true,
    },
    {
      title: "Tổng công suất lắp (kW)",
      dataIndex: "tongCongSuatLapDat",
      key: "tongCongSuatLapDat",
      width: 120,
      render: (text) => <div className="table-text-right">{text}</div>,
      sorter: true,
    },
    {
      title: "Yêu cầu cấp điện đã giải quyết",
      children: [
        {
          title: "Tỉ số biến ti",
          key: "tySoBienTi",
          dataIndex: "tySoBienTi",
          width: 100,
          render: (text) => <div className="table-text-left">{text}</div>,
          sorter: true,
        },
        {
          title: "Mục đích sử dụng điện",
          key: "mucDichSuDung",
          dataIndex: "mucDichSuDung",
          width: 100,
          render: (text) => <div className="table-text-left">{text}</div>,
          sorter: true,
        },
        {
          title: "Chi phí",
          children: [
            {
              title: "Chi phí ngành điện",
              children: [
                {
                  title: "Cáp ngầm",
                  children: [
                    {
                      title: "Khối lượng (mét)",
                      key: "khoiLuongCapNgam",
                      dataIndex: "khoiLuongCapNgam",
                      width: 100,
                      render: (text) => (
                        <div className="table-text-right">{text}</div>
                      ),
                      sorter: true,
                    },
                    {
                      title: "Chi phí (VNĐ)",
                      key: "chiPhiCapNgam",
                      dataIndex: "chiPhiCapNgam",
                      width: 100,
                      render: (text) => (
                        <div className="table-text-right">
                          {text ? formatDisplayMoney(text, 0) : null}
                        </div>
                      ),
                      sorter: true,
                    },
                  ],
                },
                {
                  title: "Số lượng DDK",
                  children: [
                    {
                      title: "Khối lượng (mét)",
                      key: "khoiLuongDDK",
                      dataIndex: "khoiLuongDDK",
                      width: 100,
                      render: (text) => (
                        <div className="table-text-right">{text}</div>
                      ),
                      sorter: true,
                    },
                    {
                      title: "Chi phí (VNĐ)",
                      key: "chiPhiDDK",
                      dataIndex: "chiPhiDDK",
                      width: 100,
                      render: (text) => (
                        <div className="table-text-right">
                          {text ? formatDisplayMoney(text, 0) : null}
                        </div>
                      ),
                      sorter: true,
                    },
                  ],
                },
                {
                  title: "TBA",
                  children: [
                    {
                      title: "Công suất (KVA)",
                      key: "congSuatTBA",
                      dataIndex: "congSuatTBA",
                      width: 100,
                      render: (text) => (
                        <div className="table-text-right">{text}</div>
                      ),
                      sorter: true,
                    },
                    {
                      title: "Chi phí (VNĐ)",
                      key: "chiPhiTBA",
                      dataIndex: "chiPhiTBA",
                      width: 100,
                      render: (text) => (
                        <div className="table-text-right">
                          {text ? formatDisplayMoney(text, 0) : null}
                        </div>
                      ),
                      sorter: true,
                    },
                  ],
                },
                {
                  title: "Chi phí khác",
                  children: [
                    {
                      title: "Khối lượng (mét)",
                      key: "khoiLuongKhac",
                      dataIndex: "khoiLuongKhac",
                      width: 100,
                      render: (text) => (
                        <div className="table-text-right">{text}</div>
                      ),
                      sorter: true,
                    },
                    {
                      title: "Chi phí (VNĐ)",
                      key: "chiPhiKhac",
                      dataIndex: "chiPhiKhac",
                      width: 100,
                      render: (text) => (
                        <div className="table-text-right">
                          {text ? formatDisplayMoney(text, 0) : null}
                        </div>
                      ),
                      sorter: true,
                    },
                  ],
                },
                {
                  title: "Tổng cộng chi phí",
                  key: "tongCongChiPhi",
                  dataIndex: "tongCongChiPhi",
                  width: 100,
                  render: (text) => (
                    <div className="table-text-right">
                      {text ? formatDisplayMoney(text, 0) : null}
                    </div>
                  ),
                  sorter: true,
                },
              ],
            },
            {
              title: "Chi phí khách hàng",
              children: [
                {
                  title: "Cáp sau công tơ",
                  children: [
                    {
                      title: "Chiều dài cáp sau công tơ (mét)",
                      key: "chieuDai",
                      dataIndex: "chieuDai",
                      width: 100,
                      render: (text) => (
                        <div className="table-text-right">{text}</div>
                      ),
                      sorter: true,
                    },
                    {
                      title: "Chủng loại cáp sau công tơ",
                      key: "chungLoai",
                      dataIndex: "chungLoai",
                      width: 100,
                      render: (text) => (
                        <div className="table-text-left">{text}</div>
                      ),
                      sorter: true,
                    },
                    {
                      title: "Tổng chi phí (Trước thuế)",
                      key: "tongChiPhiTruocThue",
                      dataIndex: "tongChiPhiTruocThue",
                      width: 100,
                      render: (text) => (
                        <div className="table-text-right">
                          {text ? formatDisplayMoney(text, 0) : null}
                        </div>
                      ),
                      sorter: true,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "Thời gian giải quyết cấp điện (ngày)",
          key: "thoiGianGiaiQuyet",
          dataIndex: "thoiGianGiaiQuyet",
          width: 150,
          render: (text) => <div className="table-text-center">{text}</div>,
          sorter: true,
        },
        {
          title: "Ghi chú",
          key: "ghiChu",
          dataIndex: "ghiChu",
          width: 150,
          render: (text) => <div className="table-text-left">{text}</div>,
          sorter: true,
        },
      ],
    },
    {
      title: "Yêu cầu cấp điện không giải quyết (Lý do từ chối cấp điện)",
      dataIndex: "lyDo",
      key: "lyDo",
      width: 150,
      render: (text) => <div className="table-text-left">{text}</div>,
      sorter: true,
    },
    // {
    //   title: "Yêu cầu cấp điện không giải quyết",
    //   dataIndex: "",
    //   key: "",
    //   width: "10%",
    // },
    // { title: "Địa chỉ sử dụng điện", dataIndex: "", key: "", width: "15%" },
    {
      title: "Tác vụ",
      width: 80,
      fixed: "right",
      // className: "right-6px",
      render: (row) => (
        <div className="action-table-column">
          <div
            className="action-icon"
            onClick={() =>
              dispatch(
                openModal({
                  content: (
                    <DuLieuDienHaAp
                      typeDetail={false}
                      detailBaoCao={row}
                      getListBaoCao={getListBaoCao}
                      thangBaoCao={filterConditions.thangBaoCao}
                    />
                  ),
                  size: "large",
                  title: "Cập nhật dữ liệu cấp điện hạ áp",
                })
              )
            }
          >
            <PencilIcon />
          </div>
        </div>
      ),
    },
  ];

  //show detail popup
  const detailData = useCallback(
    (record) => {
      return {
        onDoubleClick: () => {
          dispatch(
            openModal({
              content: (
                <DuLieuDienHaAp
                  typeDetail={true}
                  detailBaoCao={record}
                  getListBaoCao={getListBaoCao}
                  thangBaoCao={filterConditions.thangBaoCao}
                />
              ),
              size: "large",
              title: "Xem dữ liệu cấp điện hạ áp",
            })
          );
        },
      };
    },
    [dispatch, filterConditions.thangBaoCao, getListBaoCao]
  );

  return (
    <UIBaoCaoFrom89To160
      loading={loading}
      onChangePagination={onChangePagination}
      detailData={detailData}
      handleSearch={handleSearch}
      clearFilter={clearFilter}
      filterConditions={filterConditions}
      columns={columns}
      // expandedRowRender={expandedRowRender}
      dataSource={data}
      totalCount={totalCount}
      handleChangeUnit={handleChangeUnit}
      donVi={donVi}
      rowSelection={rowSelection}
      selectedRowKeys={selectedRowKeys}
      setSelectedRowKeys={setSelectedRowKeys}
      getListBaoCao={getListBaoCao}
      setType={setType}
      disabledChot={isChot}
      value={value}
      handleChangeValue={handleChangeValue}
    />
  );
}
