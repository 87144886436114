import React, { Fragment, useCallback, useEffect, useState, useLayoutEffect } from "react";
import styled from "styled-components";
import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import ButtonComponent from "@components/Button";
import ModalCreateOrEditUser from "./modal-create-or-edit-user";
import { authGetData, authDeleteData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { Tag, Tooltip } from "antd";
import { FORMAT_DATE } from "@utils/constants";
import moment from "moment";
import PencilIcon from "@assets/icon/PencilIcon";
import TrashIcon from "@assets/icon/TrashIcon";
import { CheckOutlined, RetweetOutlined } from "@ant-design/icons";
import { useSearchParams, useLocation } from "react-router-dom";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import { buildQueryString, parseParams, removeUndefinedAttribute, customColumn, handlePagination } from "@utils/function";
import { ListFilter } from "./list-bo-loc";
import { useDispatch } from "react-redux";
import { openModal } from "@state/system-config/reducer";
import PermissionAssignedTable from "./table-permission-assigned";
import { permission } from "@permissions/k_qtht";
import CheckPermission from "@components/CheckPermission";
import PopUpChangeUnit from "./popup-change-unit";

export default function QuanLyNguoiDung() {
  const [, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [idUser, setIdUser] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);
  const getUserList = useCallback(() => {
    authGetData({
      url: `${Endpoint.USER}?${buildQueryString(parseParams(location.search))}`,
      setLoading,
      onSuccess: (res) => {
        setDataSource(res.data);
        setTotalCount(res.paging.totalCount);
        setSelectedRowKey(null);
      },
    });
  }, [location.search]);
  useEffect(() => {
    getUserList();
  }, [getUserList]);

  const genExtra = useCallback(
    () => (
      <CheckPermission permissionCode={permission.k2_user_tao}>
        <ButtonComponent
          size="small"
          type="primary"
          onClick={() =>
            dispatch(
              openModal({
                title: "Tạo mới người dùng",
                content: <ModalCreateOrEditUser getUserList={getUserList} />,
                size: "large",
              })
            )
          }
        >
          Tạo mới
        </ButtonComponent>
      </CheckPermission>
    ),
    [dispatch, getUserList]
  );
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const handleOpenModalEditUser = useCallback(
    (user) => {
      dispatch(
        openModal({
          title: "Thay đổi thông tin người dùng",
          content: <ModalCreateOrEditUser user={user} getUserList={getUserList} />,
          size: "large",
        })
      );
    },
    [dispatch, getUserList]
  );
  const OpenPopupChangeUnit = (row) => {
    setVisible(true);
    setIdUser(row.id);
  };
  const ClosePopupChangeUnit = () => {
    setVisible(false);
  };
  const handleDeleteUser = useCallback(
    (userId) => {
      authDeleteData({
        url: `${Endpoint.USER}/${userId}`,
        setLoading,
        onSuccess: () => {
          getUserList();
        },
      });
    },
    [getUserList]
  );
  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      ...values,
    }));
  }, []);
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);
  const columns = [
    {
      title: "Đơn vị",
      dataIndex: "unitName",
      sorter: true,
      width: "10%",
    },
    {
      title: "Đội quản lý",
      dataIndex: "teamName",
      width: "10%",
      sorter: true,
    },
    {
      title: "Tên đăng nhập",
      dataIndex: "userName",
      width: "10%",
      sorter: true,
    },
    {
      title: "Tên đầy đủ",
      dataIndex: "name",
      width: "10%",
      sorter: true,
    },
    {
      title: "Mã NV CMIS",
      dataIndex: "cmisCode",
      className: "text-center",
      width: "10%",
      sorter: true,
    },
    {
      title: "Chức vụ",
      dataIndex: "positionName",
      width: "10%",
    },
    {
      title: "Vị trí công việc",
      width: "18%",
      dataIndex: "jobPosition",
      render: (tags) => {
        if (tags && tags.length) {
          return (
            <div>
              {tags.map((tag) => {
                return (
                  <Tag key={tag} className="mg-2px">
                    {tag.toUpperCase()}
                  </Tag>
                );
              })}
            </div>
          );
        }
        return null;
      },
    },
    {
      title: "Trạng thái",
      width: "7%",
      dataIndex: "actived",
      className: "text-center",
      render: (actived) => (
        <span className={actived ? `active-color` : `unactive-color`}>{actived ? "Hoạt động" : "Khóa"}</span>
      ),
    },
    {
      title: "SSO",
      dataIndex: "sso",
      width: "5%",
      className: "text-center",
      render: (sso) => <>{sso ? <CheckOutlined className="check-color-icon" /> : null}</>,
    },
    {
      title: "Ngày tạo",
      width: "10%",
      dataIndex: "createdDate",
      className: "text-center",
      sorter: true,
      render: (createdDate) => <span>{createdDate ? moment(createdDate).format(FORMAT_DATE) : null}</span>,
    },

    {
      title: "Tác vụ",
      width: 100,
      fixed: "right",
      render: (row) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={permission.k2_user_sua}>
            <Tooltip title={"Sửa"}>
              <ButtonComponent type="link" onClick={() => handleOpenModalEditUser(row)} className="pd-5px">
                <PencilIcon />
              </ButtonComponent>
            </Tooltip>
          </CheckPermission>
          <CheckPermission permissionCode={permission.k2_user_xoa}>
            <Tooltip title={"Xóa"}>
              <ButtonComponent type="link" onClick={() => handleDeleteUser(row.id)} className="pd-5px">
                <TrashIcon />
              </ButtonComponent>
            </Tooltip>
          </CheckPermission>
          <CheckPermission permissionCode={permission.k2_user_chuyendv}>
            <Tooltip title={"Chuyển Đơn vị"}>
              <ButtonComponent type="link" onClick={() => OpenPopupChangeUnit(row)} className="pd-5px">
                <RetweetOutlined />
              </ButtonComponent>
            </Tooltip>
          </CheckPermission>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKey) => {
      setSelectedRowKey(selectedRowKey);
    },
  };

  return (
    <>
      {visible && (
        <PopUpChangeUnit
          visible={visible}
          close={ClosePopupChangeUnit}
          getUserList={getUserList}
          idUser={idUser}
        />
      )}
      <Fragment>
        <Filter>
          <ListFilter handleSearch={handleSearch} clearFilter={clearFilter} filterConditions={filterConditions} />
        </Filter>
        <Container>
          <TableComponent
            scrollX={1500}
            scrollY={600}
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            header={"Danh sách người dùng"}
            renderExtraAction={() => genExtra()}
            dataSource={dataSource}
            columns={customColumn(columns, filterConditions)}
            totalData={totalCount}
            loading={loading}
            onChangePagination={onChangePagination}
            pagination={filterConditions}
          />
        </Container>
        <PermissionAssignedTable userId={selectedRowKey} />
      </Fragment>
    </>
  );
}
const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
`;
