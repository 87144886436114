import CheckPermission from "@components/CheckPermission";
import { treo_thao } from "@permissions/b_treothao";
import { ENUM_DS_YEU_CAU, FUNC_ID_DSYC_TREOTHAO } from "@utils/constants";
import { Button, Modal } from "antd";
import { Fragment, useState } from "react";
import DanhSachYeuCau from "../../common-ver1/danh-sach-yeu-cau";
import { handleLamMoi } from "./services";

export default function Index() {
  const [fetchList, setFetchList] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  //Hiển thị checkbox ở đầu mỗi bảng
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  const handleFetchNew = () => {
    Modal.confirm({
      centered: true,
      title: "Xác nhận",
      content: "Bạn có chắn rằng muốn làm mới các yêu cầu đã chọn?",
      onOk() {
        handleLamMoi(
          setLoading,
          selectedRowKeys,
          setFetchList,
          setSelectedRowKeys
        );
      },
      onCancel() {},
      okText: "Đồng ý",
      okButtonProps: { type: "primary" },
      cancelText: "Hủy",
    });
  };

  //Hiển thị  các buttom tạo mới, ...
  const genExtra = () => (
    <CheckPermission permissionCode={treo_thao.b31_lam_moi}>
      <Button
        type="primary"
        onClick={handleFetchNew}
        loading={loading}
        disabled={!selectedRowKeys.length}
      >
        Làm mới yêu cầu
      </Button>
    </CheckPermission>
  );

  return (
    <Fragment>
      <DanhSachYeuCau
        funcId={FUNC_ID_DSYC_TREOTHAO.NVTREOTHAO_DANGTREOTHAO}
        typeList={ENUM_DS_YEU_CAU.NVTREOTHAO_YCDANGTREOTHAO}
        genExtra={genExtra}
        fetchList={fetchList}
        setFetchList={setFetchList}
        isRowSelect={true}
        rowSelection={rowSelection}
      />
    </Fragment>
  );
}
