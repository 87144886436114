import {
  authGetData,
  authPostData,
  authGetMultiplesRequest,
} from "@utils/request";
import {
  ENUM_DS_YEU_CAU,
  METHOD_POST,
  STATUSCODE_200,
  ENUM_LOAI_DS_YEU_CAU,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";

//api list yêu cầu
export const handleListData = (
  filterConditions,
  typeList,
  setLoading,
  setDataSource,
  setTotalCount
) => {
  //Nếu danh sách yêu cầu loại 1
  let url = `${Endpoint.DANH_SACH_YEU_CAU}/${ENUM_LOAI_DS_YEU_CAU.DANH_SACH_1}`;
  if (typeList === ENUM_DS_YEU_CAU.NVANTOAN_XACNHANANTOAN) {
    //Nếu danh sách yêu cầu loại 3
    url = `${Endpoint.DANH_SACH_YEU_CAU}/${ENUM_LOAI_DS_YEU_CAU.DANH_SACH_3}`;
  }
  authPostData({
    url: url,
    method: METHOD_POST,
    payload: filterConditions,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataSource(res.data);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};

//multi data ở bộ lọc
export const handleMultiDS = (
  setLoading,
  setDonVi,
  setTrangThai,
  setTram,
  setLoaiTreoThao,
  type,
  funcId
) => {
  let urlTrangThai = `${Endpoint.COMMON_LIST_TRANG_THAI_TREO_THAO}?Type=${type}&FuncId=${funcId}`;
  if (funcId === undefined || funcId === null) {
    urlTrangThai = `${Endpoint.COMMON_LIST_TRANG_THAI_TREO_THAO}?Type=${type}`;
  }
  let urlLoaiTreoThao = `${Endpoint.COMMON_LIST_LOAI_TREO_THAO}?Type=${type}&FuncId=${funcId}`;
  if (funcId === undefined || funcId === null) {
    urlLoaiTreoThao = `${Endpoint.COMMON_LIST_LOAI_TREO_THAO}?Type=${type}`;
  }
  const endpoints = [
    `${Endpoint.LIST_DON_VI}?pageSize=-1`,
    `${Endpoint.COMMON_LIST_TRAM_TREO_THAO}`,
    urlLoaiTreoThao,
    urlTrangThai,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDonVi(res[0].data.data);
      setTram(res[1].data.data);
      setLoaiTreoThao(res[2].data.data);
      setTrangThai(res[3].data.data);
    },
  });
};

//multi data ở bộ lọc
export const handleMultiBoLocXuatKho = (
  setLoading,
  setDonVi,
  setTo,
  // setTram,
  setMaChungLoai,
  setTrangThai,
  setLyDoTreoThao
) => {
  const endpoints = [];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDonVi(res[0].data.data);
      setTo(res[1].data.data);
      // setTram(res[2].data.data);
      setMaChungLoai(res[2].data.data);
      setTrangThai(res[3].data.data);
      setLyDoTreoThao(res[4].data.data);
    },
  });
};

//danh sách chi tiết
export const handleChiTiet = (id, setLoading, callback) => {
  authGetData({
    url: `${Endpoint.CHI_TIET_YEU_CAU}/${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        callback(res.data);
      }
    },
  });
};

export const handleGetDataBienBan = (
  url,
  setLoading,
  setData,
  setVisiblePopupViewBB,
  setVisiblePDF,
  setFileData
) => {
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        if (res.data.length > 1) {
          setVisiblePopupViewBB(true);
          setVisiblePDF(false);
        } else {
          if (
            res.data[0]?.filePath.indexOf(".pdf") > -1 ||
            res.data[0]?.filePath.indexOf(".PDF") > -1
          ) {
            setVisiblePDF(true);
          } else {
            window.open(res.data[0]?.filePath, "_blank");
            setVisiblePDF(false);
          }
          setVisiblePopupViewBB(false);
        }
        setFileData(res.data[0]?.fileData);
        setData(res.data);
      }
    },
  });
};
