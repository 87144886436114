import React, { useCallback, useEffect, useState } from "react";
import { Layout, Menu, Tooltip, Spin, Select, Modal, Grid } from "antd";
import {
  MenuOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import EVNLogo from "@assets/images/logo-text-evn.png";
import styled from "styled-components";

import { useNavigate, useLocation } from "react-router-dom";
import {
  systemConfigSelector,
  handleCollapse,
  closeModal,
  handleCollapseWidth,
} from "@state/system-config/reducer";
import { authSelector, getMenuList } from "@state/auth/reducer";
import { useSelector, useDispatch } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import {
  WIDTH_LEFT_MENU,
  MODULE_BAOCAO,
  MODULE_APGIA,
  MODULE_CHAMSOCKHACHHANG,
  MODULE_CHISOCONGTO,
  MODULE_DICHVUDIEN,
  MODULE_DODEM,
  MODULE_GHICHISO,
  MODULE_HETHONG,
  MODULE_LOTRINH,
  MODULE_THANHTOAN,
  MODULE_TIENICHCHUNG,
  MODULE_TREOTHAOTHIETBI,
  MODULE_TICHHOPCRM,
  NAVIGATE_DEFAULT_PAGE,
  MODULE_KIEMTRACONGTO,
  NAVIGATE_TRANGCHU,
  MODULE_TRUNG_AP,
} from "@utils/constants";
import routeCapDien from "@routes/route_cap_dien";
import routeTreoThao from "@routes/route_treo_thao";
import routeQuanTriHeThong from "@routes/route_quan_tri_he_thong";
import routeTienIchChung from "@routes/route_tien_ich_chung";
import routeBaoCaoKinhDoanh from "@routes/route_bao_cao_kinh_doanh";
import routeChamSocKhachHang from "@routes/route_cham_soc_khach_hang";
import routeGhiChiSo from "@routes/route_ghi_chi_so";
import routeGNDN from "@routes/route_giao_nhan_dien_nang";
import routeApGia from "@routes/route_ap_gia";
import routeTichHopCRM from "@routes/route_tich_hop_CRM";
import routeChiSoCongTo from "@routes/route_ktcs_cong_to_thao";
import routeLoTrinhNhanVien from "@routes/route_lo_trinh_nhan_vien";
import routeKiemTraCongTo from "@routes/route_kttb_do_diem";
import routeDangKyThanhToan from "@routes/route_dang_ky_thanh_toan";
import routeTrungAp from "@routes/route_trung_ap";
import {
  lapPhuongAnSelector,
  notSavedPhuongAnCapDien,
  notSavedThongTinCoBan,
  rangeOfChange,
  setCanShowDialogLeavingPACD,
} from "@state/cap-dien/nhan-vien-khao-sat/lap-phuong-an/reducer";
import { xacNhanThiCongSelector } from "@state/cap-dien/thi-cong/xac-nhan-thi-cong";
import { yeuCauDuocPhanCongSelector } from "@state/treo-thao/nhan-vien-treo-thao";
import ModalWarningChange from "./ModalWarningChange";
import {
  getSaveDataPopup,
  setCanShowDialogLeaving,
} from "@state/treo-thao/nhan-vien-treo-thao/reducer";
import {
  notSavedPhanQuyenDiemDo,
  phanQuyenDiemDoSelector,
} from "@state/giao-nhan-dien-nang/reducer";
import { isEmpty } from "lodash";
import {
  callAPIImageCMIS,
  saveDongBoImageScan,
  taiGiayToScanSelector,
} from "@state/cap-dien/tai-anh-scan/reducer";

const { SubMenu } = Menu;
const { Sider } = Layout;
const { useBreakpoint } = Grid;

export default function SiderComponent({ mobile }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { collapsed } = useSelector(systemConfigSelector);
  const { phuongAnCapDien, thongTinCoBan } = useSelector(lapPhuongAnSelector);
  const { saveData } = useSelector(yeuCauDuocPhanCongSelector);
  const { thongTin, congTo, thiCong } = useSelector(xacNhanThiCongSelector);
  const { moduleCode, moduleId, menuList } = useSelector(authSelector);
  const [currentRouteKey, setCurrentRouteKey] = useState("");
  const [currentOpenKey, setCurrentOpenKey] = useState("");
  const [routes, setRoutes] = useState([]);
  const [optionSearch, setOptionSearch] = useState([]);
  const { dongBoImage } = useSelector(taiGiayToScanSelector);
  const { phanQuyenDiemDo } = useSelector(phanQuyenDiemDoSelector);
  // const screens = useBreakpoint();
  // const maxMobile991 = !screens.lg;

  // useEffect(() => {
  //   if (maxMobile991) {
  //     dispatch(handleCollapseWidth());
  //   }
  // }, [dispatch, maxMobile991]);
  //Rút ngắn số lượng ký tự hiển thị trên title sider
  const shortTitle = (title) =>
    title?.length < 24 ? title : `${title?.slice(0, 24)}...`;

  const handleGetOptionSearch = () => {
    const dataSearch = [];

    menuList.data?.forEach((element) => {
      if (isEmpty(element.subItems)) {
        dataSearch?.push({ label: element.name, value: element.path });
      } else {
        element?.subItems?.forEach((item) => {
          dataSearch.push({ label: item.name, value: item.path });
        });
      }
    });
    setOptionSearch([...dataSearch]);
  };

  useEffect(() => {
    if (moduleCode === MODULE_BAOCAO) {
      handleGetOptionSearch();
    }
    switch (moduleCode) {
      case MODULE_DICHVUDIEN:
        return setRoutes(routeCapDien);
      case MODULE_TREOTHAOTHIETBI:
        setRoutes(routeTreoThao);
        return;
      case MODULE_DODEM:
        return setRoutes(routeGNDN);
      case MODULE_GHICHISO:
        return setRoutes(routeGhiChiSo);
      case MODULE_APGIA:
        return setRoutes(routeApGia);
      case MODULE_BAOCAO:
        return setRoutes(routeBaoCaoKinhDoanh);
      case MODULE_LOTRINH:
        return setRoutes(routeLoTrinhNhanVien);
      case MODULE_THANHTOAN:
        return setRoutes(routeDangKyThanhToan);
      case MODULE_CHISOCONGTO:
        return setRoutes(routeChiSoCongTo);
      case MODULE_CHAMSOCKHACHHANG:
        return setRoutes(routeChamSocKhachHang);
      case MODULE_HETHONG:
        return setRoutes(routeQuanTriHeThong);
      case MODULE_TIENICHCHUNG:
        return setRoutes(routeTienIchChung);
      case MODULE_TICHHOPCRM:
        return setRoutes(routeTichHopCRM);
      case MODULE_KIEMTRACONGTO:
        return setRoutes(routeKiemTraCongTo);
      case MODULE_TRUNG_AP:
        return setRoutes(routeTrungAp);
      default:
        return;
    }
  }, [moduleCode]);

  useEffect(() => {
    if (moduleId) {
      dispatch(getMenuList({ moduleId }));
    }
  }, [dispatch, moduleId]);

  useEffect(() => {
    setCurrentRouteKey(location.pathname);
  }, [location]);

  useEffect(() => {
    if (menuList.data?.length) {
      const findOpenKey = menuList.data.find(
        (item) => item.subItems?.length && location.pathname.includes(item.path)
      );
      if (findOpenKey && !collapsed) {
        setCurrentOpenKey((oldState) => [...oldState, ...[findOpenKey.path]]);
      }
    }
  }, [location, menuList, collapsed]);

  const renderPageAfterGoTo = useCallback(
    (item, key) => {
      if (routes?.length) {
        const loop = (data, key, callback) => {
          for (let i = 0; i < data?.length; i++) {
            if (key.endsWith(data[i].path) || key.endsWith(data[i].subPath)) {
              return callback(key);
            }

            if (data[i].subItems) {
              loop(data[i].subItems, key, callback);
            }
          }
        };
        let route;
        loop(routes, key, (routeExist) => {
          if (routeExist) {
            route = routeExist;
          }
        });
        if (route) {
          navigate(route);
        } else {
          navigate(NAVIGATE_DEFAULT_PAGE, {
            state: { namePage: item.props.name },
          });
        }
      }
    },
    [navigate, routes]
  );
  const goToPage = useCallback(
    ({ item, key }) => {
      if (dongBoImage === false) {
        Modal.confirm({
          centered: true,
          width: 600,
          title: "Xác nhận?",
          content:
            "Tồn tại ảnh/tài liệu chưa đồng bộ về CMIS, hệ thống sẽ tự động đồng bộ sau khi đóng màn hình !",
          onOk() {
            dispatch(callAPIImageCMIS(true));
            dispatch(saveDongBoImageScan(true));
            dispatch(closeModal());
            if (currentRouteKey !== key) {
              renderPageAfterGoTo(item, key);
            }
          },
          onCancel() {},
          okText: "Đồng ý",
          okButtonProps: { type: "primary" },
          cancelText: "Hủy",
        });
      } else {
        if (
          !phuongAnCapDien &&
          !thongTinCoBan &&
          !thongTin &&
          !congTo &&
          !thiCong &&
          !saveData &&
          !phanQuyenDiemDo
        ) {
          dispatch(closeModal());
          if (currentRouteKey !== key) {
            renderPageAfterGoTo(item, key);
          }
        } else {
          ModalWarningChange(
            () => {
              // dispatch(rangeOfChange(false));
              dispatch(rangeOfChange(false));
              if (phuongAnCapDien) dispatch(notSavedPhuongAnCapDien(false));
              if (thongTinCoBan) dispatch(notSavedThongTinCoBan(false));
              dispatch(setCanShowDialogLeavingPACD(false));

              if (phanQuyenDiemDo) dispatch(notSavedPhanQuyenDiemDo(false));
              // dispatch(setCanShowDialogLeavingTT(false));

              if (saveData) dispatch(getSaveDataPopup(false));
              dispatch(setCanShowDialogLeaving(false));
              renderPageAfterGoTo(item, key);
            },
            () => {}
          );
        }
      }

      // if (currentRouteKey !== key) renderPageAfterGoTo(item, key);

      // GNDN-Baocao Chuyen thang lam viec
      if (currentRouteKey.split("/")[2] !== key.split("/")[2])
        localStorage.removeItem("reportDate");
    },
    [
      dongBoImage,
      currentRouteKey,
      dispatch,
      phuongAnCapDien,
      thongTinCoBan,
      thongTin,
      congTo,
      thiCong,
      saveData,
      phanQuyenDiemDo,
      renderPageAfterGoTo,
    ]
  );

  const handleOpenMenu = useCallback((openKeys) => {
    setCurrentOpenKey(openKeys);
  }, []);

  const onSelectMenu = (e) => {
    navigate(e);
  };

  const renderMenu = useCallback(() => {
    if (menuList?.data?.length) {
      return (
        <>
          {moduleCode === MODULE_BAOCAO && (
            <StyleSelect>
              <div>
                <Select
                  showSearch
                  options={optionSearch}
                  allowClear
                  filterOption={(inputValue, option) => {
                    return option?.label
                      ?.toLowerCase()
                      ?.includes(inputValue.toLowerCase().trimStart());
                  }}
                  onChange={onSelectMenu}
                />
              </div>
            </StyleSelect>
          )}
          <Menu
            theme="light"
            mode="inline"
            onClick={goToPage}
            openKeys={currentOpenKey}
            selectedKeys={currentRouteKey}
            onOpenChange={handleOpenMenu}
          >
            {menuList.data &&
              menuList.data.map((item) => {
                if (item.subItems?.length) {
                  return (
                    <SubMenu
                      key={item.path}
                      icon={<MenuOutlined />}
                      onClick={() => mobile && dispatch(handleCollapse())}
                      title={
                        <Tooltip placement="right" title={item.title}>
                          <span className="menu-item-text">
                            {shortTitle(item.title)}
                          </span>
                        </Tooltip>
                      }
                      className="menu-item-text"
                    >
                      {item.subItems.map((subItem) => {
                        if (subItem.subItems?.length) {
                          return (
                            <SubMenu
                              key={subItem.path}
                              title={
                                <Tooltip
                                  placement="right"
                                  title={subItem.title}
                                >
                                  <span className="menu-item-text">
                                    {shortTitle(subItem.title)}
                                  </span>
                                </Tooltip>
                              }
                              icon={<MenuOutlined />}
                              className="menu-item-text"
                            >
                              {subItem.subItems.map((subItem2) => {
                                return (
                                  <Menu.Item
                                    name={subItem2.title}
                                    key={subItem2.path}
                                  >
                                    <Tooltip
                                      placement="right"
                                      title={subItem2.title}
                                    >
                                      <span className="menu-item-text">
                                        {shortTitle(subItem2.title)}
                                      </span>
                                    </Tooltip>
                                  </Menu.Item>
                                );
                              })}
                            </SubMenu>
                          );
                        }
                        return (
                          <Menu.Item key={subItem.path} name={subItem.title}>
                            <Tooltip placement="right" title={subItem.title}>
                              <span className="menu-item-text">
                                {shortTitle(subItem.title)}
                              </span>
                            </Tooltip>
                          </Menu.Item>
                        );
                      })}
                    </SubMenu>
                  );
                } else {
                  return (
                    <Menu.Item
                      icon={<MenuOutlined />}
                      key={item.path}
                      name={item.title}
                    >
                      <Tooltip placement="right" title={item.title}>
                        <span className="menu-item-text">
                          {shortTitle(item.title)}
                        </span>
                      </Tooltip>
                    </Menu.Item>
                  );
                }
              })}
          </Menu>
        </>
      );
    }
    if (menuList.loading) {
      return (
        <div className="text-center mt-24px">
          <Spin tip="Đang tải..." />
        </div>
      );
    }
    return null;
  }, [currentRouteKey, goToPage, currentOpenKey, handleOpenMenu, menuList]);
  return (
    <SideStyled
      width={WIDTH_LEFT_MENU}
      theme="light"
      collapsed={collapsed}
      onCollapse={() => dispatch(handleCollapse())}
      collapsible={true}
      className={collapsed ? "close-sider-bar" : "open-sider-bar"}
    >
      <div
        className="logo"
        style={{ justifyContent: collapsed ? "center" : "space-between" }}
      >
        <div
          className="logo-image-action"
          onClick={() => navigate(NAVIGATE_TRANGCHU)}
        >
          {!collapsed && <img src={EVNLogo} alt="logo" />}
        </div>
        <div
          className="collapse-icon"
          onClick={() => dispatch(handleCollapse())}
        >
          {!collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
        </div>
      </div>
      <Scrollbars style={{ height: "calc(100vh - 96px)" }} autoHide>
        {!collapsed && (
          <div
            className="system-name"
            style={{ textAlign: "center", padding: "12px 10px 10px 12px" }}
          >
            {localStorage.getItem("title-menu")}
          </div>
        )}

        {renderMenu()}
      </Scrollbars>
    </SideStyled>
  );
}

const SideStyled = styled(Sider)`
  box-shadow: inset -0.5px 0px 0px rgba(181, 181, 181, 0.25);
  z-index: 999;
  height: 100%;
  .logo {
    min-height: 48px;
    display: flex;
    align-items: center;
    padding: 12px 24px;
    box-shadow: inset 0px -1px 0px #d9d9d9;
    border-right: 1px solid #f0f0f0;
  }
  .collapse-icon {
    :hover {
      cursor: pointer;
    }
  }
  .logo-image-action {
    :hover {
      cursor: pointer;
    }
  }

  .system-name {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    font-weight: bold;
    font-size: 16px;
    border-right: 1px solid #f0f0f0;
  }
`;

const StyleSelect = styled.div`
  padding: 20px;
  .ant-select {
    width: 100%;
  }
`;
