import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString } from "@utils/function";
import {
  authGetData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";

export const getListDiemDo = (
  setDataDiemDo,
  setLoading,
  setTotalCount,
  filterConditions
) => {
  authGetData({
    url: `${Endpoint.LIST_SANLUONG_CHITIET}?${buildQueryString(
      filterConditions
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataDiemDo(res.data);
        setTotalCount(res.paging.totalCount);
      } else {
        setDataDiemDo([]);
      }
    },
  });
};
export const getListDiemDoChuaNhapCS = (
  setDataDiemDoChuaNhap,
  setLoading,
  query,
) => {
  authPostData({
    url: Endpoint.GNDN_DD_CHUA_NHAP_CS,
    setLoading: setLoading,
    method: METHOD_POST,
    payload: query,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataDiemDoChuaNhap(res.data);
      }
    },
  });
  
};

export const handleMultiData = (setLoading, setDataTable) => {
  const endpoints = [`${Endpoint.GNDN_DANH_MUC_DON_VI_GIAO_NHAN}`];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setDataTable(res[0].data.data);
    },
  });
};

export const getListSLTCT = (
  setDataDonViTong,
  setFetchList,
  setLoading,
  thang,
  nam
) => {
  authGetData({
    url: `${Endpoint.LIST_SANLUONG_TCT}?Thang=${thang}&Nam=${nam}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataDonViTong(
          res.data.filter(
            (e) =>
              e.maDonVi !== "PD" &&
              e.maDonVi !== "HT" &&
              e.maDonVi !== "HY" &&
              e.maDonVi !== "LT" &&
              e.maDonVi !== "PDBC" &&
              e.maDonVi !== "PDHD" &&
              e.maDonVi !== "PDTC" &&
              e.maDonVi !== "VP" &&
              e.laDongBo === true
          )
        );
        setFetchList(true);
      }
    },
  });
};

export const getListSLDonVi = (
  setDataDonVi,
  setDataDVGiaoNhan,
  setFetchList,
  setLoading,
  thang,
  nam,
  donViId
  // setDisableMessError
) => {
  authGetData({
    url: `${Endpoint.LIST_SANLUONG_DONVI}?donViId=${donViId}&Thang=${thang}&Nam=${nam}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        // const ngayDV = new Date(res.data.ngayDonViXacNhan);
        // const ngayTCT = new Date(res.data.ngayTCTChot);
        // if (
        //   res.data.ngayDonViXacNhan &&
        //   res.data.ngayTCTChot &&
        //   ngayDV > ngayTCT
        // ) {
        //   setDisableMessError(false);
        // } else {
        //   setDisableMessError(true);
        // }
        setDataDonVi([res.data]);
        setDataDVGiaoNhan(res.data.danhSachDonViGiaoNhan);
        setFetchList(true);
      }
    },
  });
};

export const getThangLamViec = (
  setThangLamViec,
  setLoading,
  setdateYYYY,
  setdateMM
) => {
  authGetData({
    url: `${Endpoint.GET_THANGLAMVIEC}`,
    setLoading: () => {},
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        const date = `${res.data.nam}-${
          parseInt(res.data.thang) < 10 ? `0${res.data.thang}` : res.data.thang
        }-01`;
        setThangLamViec(date);
        setdateYYYY(res.data.nam);
        setdateMM(res.data.thang);
      }
    },
  });
};

export const handleTinhSL = (setFetchList, setLoading, data) => {
  authPostData({
    url: `${Endpoint.TINH_SANLUONG_GNDN}`,
    setLoading,
    method: METHOD_POST,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
      }
    },
  });
};

export const handleHuyTinhSL = (
  setFetchList,
  setLoading,
  data,
  handleCloseModal
) => {
  authPostData({
    url: `${Endpoint.HUY_SANLUONG_GNDN}`,
    setLoading,
    method: METHOD_POST,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
        handleCloseModal();
      }
    },
  });
};

export const handleKTDKHuyTinhSL = (
  setFetchList,
  setLoading,
  data,
  setMessKTDK,
  setVisibleModalKTDK
) => {
  authPostData({
    url: `${Endpoint.KTDK_HUY_SANLUONG}`,
    setLoading: () => {},
    method: METHOD_POST,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        if (res.data.hienThiPopUp) {
          setVisibleModalKTDK(true);
          setMessKTDK(res.data.noidungPopUp);
        } else {
          handleHuyTinhSL(setFetchList, setLoading, data);
        }
      }
    },
  });
};

export const handleKTDKHuyChotSL = (
  setFetchList,
  setLoading,
  data,
  setMessKTDK,
  setVisibleModalKTDK,
  setSelectedRowKeys,
  setIsDaChot
) => {
  authPostData({
    url: `${Endpoint.KTDK_HUYCHOT_SANLUONG}`,
    setLoading: () => {},
    method: METHOD_POST,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        if (res.data.hienThiPopUp) {
          setVisibleModalKTDK(true);
          setMessKTDK(res.data.noidungPopUp);
        } else {
          handleHuyChotSL(setSelectedRowKeys, setFetchList, setLoading, data);
          setIsDaChot(true);
          setSelectedRowKeys([]);
        }
      }
    },
  });
};

export const handleKTDKXacNhan = (
  data,
  setLoading,
  setCheckTaoBangKe,
  setNoidungPopUp
) => {
  authPostData({
    url: `${Endpoint.KTDK_XACNHAN_SANLUONG}`,
    setLoading,
    method: METHOD_POST,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setCheckTaoBangKe(res.data.hienThiPopUp);
        setNoidungPopUp(res.data.noidungPopUp);
      }
    },
  });
};

export const handleXacNhanSL = (
  setFetchList,
  setLoading,
  data,
  handleCloseModal
) => {
  authPostData({
    url: `${Endpoint.XACNHAN_SANLUONG_GNDN}`,
    setLoading,
    method: METHOD_POST,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
      }
      handleCloseModal();
    },
  });
};

export const handleChotSL = (
  setFetchList,
  setLoading,
  data,
  setSelectedRowKeys,
  setIsDaChot
) => {
  authPostData({
    url: `${Endpoint.CHOT_SANLUONG_GNDN}`,
    setLoading,
    method: METHOD_POST,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
        setSelectedRowKeys([]);
      }
      setIsDaChot(true);
    },
  });
};

export const handleHuyChotSL = (
  setSelectedRowKeys,
  setFetchList,
  setLoading,
  data,
  handleCloseModal,
  setIsDaChot
) => {
  authPostData({
    url: `${Endpoint.HUY_CHOT_SANLUONG_GNDN}`,
    setLoading,
    method: METHOD_POST,
    payload: data,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchList(true);
      }
      handleCloseModal();
      setIsDaChot(true);
      setSelectedRowKeys([]);
    },
  });
};
