const f1 = {
  f11: "f11",
  f11a: "f11a",
  f11b: "f11b",
  f11c: "f11c",
  f11d: "f11d",
  f11e: "f11e",
  f11f: "f11f",
  f11g: "f11g",
  f12: "f12",
  f13: "f13",
};
const f2 = {
  f21: "f21",
  f22: "f22",
  f23: "f23",
  f24: "f24",
  f25: "f25",
  f26: "f26",
  f27: "f27",
  f28: "f28",
  f29: "f29",
  f210: "f210",
  f310: "f310",
};
const f3 = {
  f3: "f3",
  f31: "f31",
  f32: "f32",
  f33: "f33",
  f34: "f34",
  f35: "f35",
  f36: "f36",
  f37: "f37",
};
const f4 = {
  f4: "f4",
  f4v: "f4v",
};
const f5 = {
  f5: "f5",
  f5v: "f5v",
};
const f6 = {
  f6: "f6",
  f61: "f61",
  f62: "f62",
};
const f7 = {
  f7: "f7",
  f7v: "f7v",
};
const f8 = {
  f8: "f8",
  f8v: "f8v",
};
const f9 = {
  f91: "f91",
  f92: "f92",
  f93: "f93",
  f94: "f94",
};
const f10 = {
  f101: "f101",
  f102: "f102",
  f103: "f103",
  f104: "f104",
};
const f111 = {
  f11: "f11",
  f11v: "f11v",
};
const f121 = {
  f12: "f12",
  f12v: "f12v",
};
const f131 = {
  f13: "f13",
  f13v: "f13v",
};
const f141 = {
  f14: "f14",
  f14v: "f14v",
};
const f151 = {
  f15: "f15",
  f15v: "f15v",
};
const f161 = {
  f16: "f16",
  f16v: "f16v",
};
const f171 = {
  f17: "f17",
  f1711: "f1711",
  f1721: "f1721",
  f1721a: "f1721",
  f1721b: "f1721b",
  f1721c: "f1721c",
  f173: "f173",
  f174: "f174",
  f175: "f175",
  f176: "f176",
  f177: "f177",
  f178: "f178",
  f191: "f191"
};

const f181 = {
  f18: "f18",
  f1811: "f1811",
  f1812: "f1812",
  f1813: "f1813",
  f1814: "f1814",
};

const f191 = {
  f19: "f19",
  f1911: "f1911",
  f1912: "f1912",
  f1913: "f1913",
  f1921: "f1921",
  f1922: "f1922",
};

export const bao_cao_kinh_doanh = {
  ...f1,
  ...f2,
  ...f3,
  ...f4,
  ...f5,
  ...f6,
  ...f7,
  ...f8,
  ...f9,
  ...f10,
  ...f111,
  ...f121,
  ...f131,
  ...f141,
  ...f151,
  ...f161,
  ...f171,
  ...f181,
  ...f191,
};
