import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";
import {
  buildQueryString,
  parseParams,
} from "@utils/function";
import { METHOD_POST, STATUSCODE_200 } from "@utils/constants";

// get list data cập nhật chỉ số công tơ tháo
export const handleListDataCapNhatChiSo = (
  setLoading,
  location,
  setDataCapNhatChiSo,
  filterConditions,
  cauHinhValue,
  term
) => {
  let url = "";
  url = `${Endpoint.LIST_CAP_NHAT_CONG_TO_THAO}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      let temp = {};
      let tempCS = {};
      const SG = ["BT", "CD", "TD", "SG"];
      const SN = ["BN", "CN", "TN", "SN"];
      const tempData = [];
      let termData = [];
      if (res.data !== null) {
        for (let i = 0; i < res.data.length; i++) {
          let sumSG = 0;
          let sumSN = 0;
          let greater = false;
          let smaller = false;
          let thieuCS = false;
          sumSG = res.data[i].danhSachBoChiSo.filter(({ bcs }) => SG.includes(bcs)).reduce((sum, record) => sum + record.chiSoX5, 0);
          sumSN = res.data[i].danhSachBoChiSo.filter(({ bcs }) => SN.includes(bcs)).reduce((sum, record) => sum + record.chiSoX5, 0);
          if (cauHinhValue){
            greater = res.data[i].danhSachBoChiSo.some((element => element.chenhLechSanLuong >= cauHinhValue));
            smaller = res.data[i].danhSachBoChiSo.every((element => element.chenhLechSanLuong < cauHinhValue));
          }
          thieuCS = res.data[i].danhSachBoChiSo.some((element => element.chiSoX5 === null));
          if (res.data[i].danhSachBoChiSo.length > 0) {
            for (let j = 0; j < res.data[i].danhSachBoChiSo.length; j++) {
              const foundSG = res.data[i].danhSachBoChiSo.find(element => element.bcs === 'SG');
              const foundSN = res.data[i].danhSachBoChiSo.find(element => element.bcs === 'SN');
              if (SG.includes(res.data[i].danhSachBoChiSo[j].bcs)) {
                if (foundSG && foundSG.chiSoX5 !== null && (foundSG.chiSoX5 < Math.abs(sumSG - foundSG.chiSoX5 - 3) || foundSG.chiSoX5 > Math.abs(sumSG - foundSG.chiSoX5 + 3))) {
                  res.data[i].danhSachBoChiSo[j].checkSG = true;
                }
              }
              if (SN.includes(res.data[i].danhSachBoChiSo[j].bcs)) {
                if (foundSN && foundSN.chiSoX5 !== null && (foundSN.chiSoX5 < Math.abs(sumSN - foundSN.chiSoX5 - 3) || foundSN.chiSoX5 > Math.abs(sumSN - foundSN.chiSoX5 + 3))) {
                  res.data[i].danhSachBoChiSo[j].checkSN = true;
                }
              }
              res.data[i].danhSachBoChiSo[j].greater = greater;
              res.data[i].danhSachBoChiSo[j].smaller = smaller;
              res.data[i].danhSachBoChiSo[j].thieuCS = thieuCS;
              if (j === 0) {
                tempCS = res.data[i].danhSachBoChiSo[j];
                tempCS.idCapNhat = res.data[i].danhSachBoChiSo[j].id;
                tempCS.idChiTiet = res.data[i].id;
                delete tempCS.id
                delete tempCS.trangThai
                temp = { ...res.data[i], ...tempCS }
                tempData.push(temp);
              } 
              else {
                tempCS = res.data[i].danhSachBoChiSo[j];
                tempCS.idCapNhat = res.data[i].danhSachBoChiSo[j].id;
                tempCS.idChiTiet = res.data[i].id;
                delete tempCS.id
                delete tempCS.trangThai
                temp = { ...tempCS, heSoNhan: res.data[i].heSoNhan,
                   isScanned: res.data[i].isScanned,
                    danhSachBoChiSo: res.data[i].danhSachBoChiSo, isSelected: res.data[i].isSelected }
                tempData.push(temp);
              }
            } 
          } else {
            tempData.push(res.data[i]);
          }
        }
        tempData.forEach(function(row, index) {
          row.rowNumber = index + 1;
        });
        if (term) {
          termData =tempData.filter(x => x.maThietBi === term).map(x => x);
          setDataCapNhatChiSo(termData);       
        }else {
          setDataCapNhatChiSo(tempData);
        }
      } else {
        setDataCapNhatChiSo([]);
      }
    },
  });
};

export const submitCapNhatChiSo = (
  setLoading,
  setFetchDataTable,
  id,
  boCS,
  value
) => {
  authPostData({
    url: Endpoint.CAP_NHAT_CONG_TO_THAO,
    method: METHOD_POST,
    payload: {
      id: id,
      chiSo: value.chiSoX5,
      bcs: boCS
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
      }
    },
  });
};

export const submitCapNhatMDMS = (
  setLoading,
  setFetchDataTable,
  id,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.CAP_NHAT_MDMS,
    method: METHOD_POST,
    payload: {
      ids: id,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([])
      }
    },
  });
};
export const submitCapNhatMDMSAll = (
  setLoading,
  setFetchDataTable,
  filterConditions
) => {
  authPostData({
    url: Endpoint.CAP_NHAT_MDMS_ALL,
    method: METHOD_POST,
    payload: {
        donViId: filterConditions.DonViId,
        ngayBatDau: filterConditions.NgayBatDau,
        ngayKetThuc: filterConditions.NgayKetThuc,
        maBienBan: filterConditions.MaBienBan
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        // setSelectedRowKeys([])
      }
    },
  });
};
export const submitDongBoCmis = (
  setLoading,
  setFetchDataTable,
  handleCloseModalDongBo,
  ngayNhan,
  maKho,
  maNhanVien,
  maBienBan,
  setIsDB,

) => {
  authPostData({
    url: Endpoint.CTOTHAO_DONG_BO_CMIS,
    method: METHOD_POST,
    payload: {
      maBienBan: maBienBan,
      ngayNhan: ngayNhan,
      maKho: maKho,
      nhanVienNhan: maNhanVien
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModalDongBo();
        setIsDB(true);
      }
    },
  });
};

export const getNhanVienService = (setLoading, setDataNhanVien, donVI) => {
  let url = "";
  url = `${Endpoint.CTOTHAO_NHAN_VIEN}?DonViId=${donVI}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataNhanVien(res.data);
      } else setDataNhanVien([]);
    },
  });
};

export const submitQuet = (
  setLoading,
  setFetchDataTable,
  id,
  trangThai
) => {
  authPostData({
    url: Endpoint.CTOTHAO_QUET,
    method: METHOD_POST,
    payload: {
      id: id,
      trangThai: trangThai === "1" ? "0" : "1"
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
      }
    },
  });
};

export const submitTrangThai = (
  setLoading,
  setFetchDataTable,
  id,
  trangThai
) => {
  authPostData({
    url: Endpoint.CTOTHAO_UPDATE_TRANG_THAI,
    method: METHOD_POST,
    payload: {
      id: id,
      trangThai: trangThai.isSelected
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
      }
    },
  });
};
export const getCauHinhchiSoGanNhat = (
  setLoading,
  setDataCauHinh,
) => {
  let url = "";
  url = `${Endpoint.CTOTHAO_CAU_HINH_CSO_GAN_NHAT}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataCauHinh(res.data.chenhLechSanLuong);
      } else setDataCauHinh();
    },
  });
};
// get so luong doi soat
export const handleSoLuongDoiSoat = (
  setLoading,
  location,
  setDataSLDoiSoat,
  filterConditions
) => {
  let url = "";
  url = `${Endpoint.SO_LUONG_DOI_SOAT}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataSLDoiSoat(res.data);
      } else setDataSLDoiSoat([]);
    },
  });
};

// get so luong doi soat
export const handleChiTietBienBanDB = (
  setLoading,
  setDataChiTietDB,
  bienBan
) => {
  let url = "";
  url = `${Endpoint.CTOTHAO_CHI_TIET_DONG_BO}?MaBienBan=${bienBan}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataChiTietDB(res.data);
      } else setDataChiTietDB();
    },
  });
};