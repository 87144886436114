import React from "react";
import LoadingComponent from "@components/Loading";
import { Container, ContainerButonKySo } from "./css-styled";
import { Button, Col, Form, Modal, Row, Select } from "antd";
// import KySoComponent from "@components/KySo";
// import { ENUM_KYSO } from "@utils/constants";
import ButtonKySoHSM from "../ky-so-hsm";

const { Option } = Select;

export default function Index(props) {
  const {
    visible,
    closeModal,
    title,
    content,
    onFinish,
    loading,
    textButton,
    isKySo,
    urlKySo,
    // urlGetHash,
    setFetchList,
    // permissionCode,
    payloadKySo,
    dataSelect,
    onChangedSelect,
    selected,
    nvnp,
    nvtt,
  } = props;
  return (
    // <LoadingComponent loading={loading}>
    <Modal title={title} visible={visible} onCancel={closeModal} footer={null}>
      <LoadingComponent loading={loading}>
        {/* Truyền content */}
        <Container>
          {content}
          {dataSelect && (
            <Row gutter={24} style={{ paddingTop: "24px" }}>
              <Col span={24}>
                <Form.Item
                  label="Phiếu lệnh công tác"
                  name="phieuLenhCongTacId"
                >
                  <Select
                    placeholder="Chọn phiếu lệnh công tác"
                    onChange={onChangedSelect}
                    allowClear
                  >
                    {dataSelect.map((item, index) => (
                      <Option key={index} value={item?.maPhieuLenh}>
                        {`${item.hienThi}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {Object.keys(selected).length > 0 && (
                <Col
                  span={24}
                  style={{
                    // maxWidth: "300px",
                    margin: "0 auto",
                    fontSize: "16px",
                    // marginLeft: "135px",
                  }}
                >
                  <Row gutter={24}>
                    <Col span={8}>{nvtt}:</Col>
                    <Col span={6}>{selected.maSSONVTT}</Col>
                    <Col span={6}>{selected.bacAnToanNVTT}</Col>
                    <Col span={8}>{nvnp}:</Col>
                    <Col span={6}>{selected.maSSONVNP}</Col>
                    <Col span={6}>{selected.bacAnToanNVNP}</Col>
                  </Row>
                  {/* <div
                    style={{
                      display: "flex",
                      width: "350px",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>{nvtt}:</span>
                    <span style={{ marginLeft: "32px" }}>
                      {selected.maSSONVTT}
                    </span>
                    <span style={{ marginLeft: "32px" }}>
                      {selected.bacAnToanNVTT}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "350px",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>{nvnp}:</span>
                    <span style={{ marginLeft: "32px" }}>
                      {selected.maSSONVNP}
                    </span>
                    <span style={{ marginLeft: "32px" }}>
                      {selected.bacAnToanNVNP}
                    </span>
                  </div> */}
                </Col>
              )}
            </Row>
          )}
        </Container>
        <Row gutter={24} justify="center" style={{ marginTop: "48px" }}>
          <Col span={24} md={isKySo ? 8 : 12} style={{ textAlign: "right" }}>
            <Button key="back" onClick={closeModal} className="button-closed">
              Đóng lại
            </Button>
          </Col>
          <Col
            span={24}
            md={isKySo ? 8 : 12}
            style={{ textAlign: isKySo ? "center" : "left" }}
          >
            <Button
              type="primary"
              key="submit"
              loading={loading}
              onClick={onFinish}
            >
              {textButton ? textButton : "Đồng ý"}
            </Button>
          </Col>
          {/* nếu có ký số thì hiển thị button ký số */}
          {isKySo ? (
            <Col span={24} md={8}>
              <ContainerButonKySo>
                {/* <KySoComponent
                  url={urlKySo}
                  urlGetHash={urlGetHash}
                  payload={payloadKySo}
                  permissionCode={permissionCode}
                  disabled={false}
                  setFetchDataTable={setFetchList}
                  text={"Ký số USB"}
                  setLoading={setLoading}
                  loading={loading}
                  bgColor={"#faad14"}
                  typeKySo={ENUM_KYSO.TREOTHAO}
                /> */}
                <ButtonKySoHSM
                  url={urlKySo}
                  params={payloadKySo}
                  setFetchList={setFetchList}
                />
              </ContainerButonKySo>
            </Col>
          ) : null}
        </Row>
      </LoadingComponent>
    </Modal>
    // </LoadingComponent>
  );
}
