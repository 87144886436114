import LoadingComponent from "@components/Loading";
import { Fragment, useState, useEffect } from "react";
import { ContainerPT } from "../css-styled";
import { handlePhucTra } from "../services";

export default function PhucTra() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    handlePhucTra(setLoading, setData);
  }, []);
  return (
    <Fragment>
      <LoadingComponent loading={loading}>
        <ContainerPT>
          <div className="container">
            <p>Lấy thông tin đo xa</p>
            <p>Phúc tra ngoài hiện trường</p>
            <p>Tổng</p>
          </div>
          <div className="container">
            <p className="value">{data?.doxa}</p>
            <p className="value">{data?.mtb}</p>
            <p className="value">{data?.tong}</p>
          </div>
          <div className="container">
            <p className="value">
              {isNaN(((data?.doxa * 100) / data?.tong).toFixed(1))
                ? 0
                : ((data?.doxa * 100) / data?.tong).toFixed(1)}
              %
            </p>
            <p className="value">
              {isNaN(((data?.mtb * 100) / data?.tong).toFixed(1))
                ? 0
                : ((data?.mtb * 100) / data?.tong).toFixed(1)}
              %
            </p>
            <p>&nbsp;</p>
          </div>
        </ContainerPT>
      </LoadingComponent>
    </Fragment>
  );
}
