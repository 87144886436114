import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";

// data hồ sơ

export const handleDataHoSo = (
  hoSoId,
  setLoading,
  setDataDetail,
  setDataAnhHoSo,
  setDataNhatKiHoSo,
  setDataTienTrinh
) => {
  authGetData({
    url: Endpoint.DETAIL_HO_SO + "?HoSoId=" + hoSoId,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDetail(res.data[0].thongTinChiTiet);
        setDataAnhHoSo(res.data[0].anhHoSo);
        setDataNhatKiHoSo(res.data[0].nhatKyHoSo);
        setDataTienTrinh(res.data[0].tienTrinhDichVuDien);
      } else setDataDetail({});
    },
  });
};

export const handleListLoaiGT = (setLoading, setDataLoaiGT, setFirstLoaiGT) => {
  authGetData({
    url: Endpoint.LIST_LOAI_GIAY_TO + "?pageSize=-1",
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataLoaiGT(res.data);
        setFirstLoaiGT(res.data[0].id);
      } else setDataLoaiGT([]);
    },
  });
};

export const handleListImage = (
  detailHoSo,
  setLoading,
  setDataImages,
  setListId
) => {
  const hoSoId = detailHoSo.id ? detailHoSo.id : detailHoSo.hoSoId;
  authGetData({
    url: Endpoint.LIST_ANH_HIEN_TRUONG + "?hoSoId=" + hoSoId,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null && res.data.length > 0) {
        const arr = [];
        const arrId = [];
        // eslint-disable-next-line array-callback-return
        res.data.map((item, index) => {
          arr.push(res.data[index].images);
          arrId.push({
            loaiGiayToId: res.data[index].loaiGiayToId,
            tenLoaiGiayTo: res.data[index].tenLoaiGiayTo,
          });
        });
        setDataImages(arr);
        setListId(arrId);
      }
    },
  });
};

export const getChiTietYeuCau = (setLoading, id, setData) => {
  authGetData({
    url: `${Endpoint.CRM_CHI_TIET_YEU_CAU}?Id=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(res.data);
      }
    },
  });
};

export const getLichSuThaoTac = (setLoading, id, setData) => {
  authGetData({
    url: `${Endpoint.CRM_LICH_SU_THAO_TAC}?Id=${id}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        setData(
          res.data.map((item, index) => {
            return {
              ...item,
              stt: index + 1,
            };
          })
        );
      }
    },
  });
};
