import { BASE_API_URL_DANH_MUC } from "@utils/constants";

export const APIDonVi = {
  LIST_DON_VI: `${BASE_API_URL_DANH_MUC}/don-vi/list`,
  LIST_DON_VI_CHUYEN_DV: `${BASE_API_URL_DANH_MUC}/don-vi/list-all`,
  SYNC_DON_VI: `${BASE_API_URL_DANH_MUC}/don-vi/sync`,
  CREATE_DON_VI: `${BASE_API_URL_DANH_MUC}/don-vi/create`,
  UPDATE_DON_VI: `${BASE_API_URL_DANH_MUC}/don-vi/update`,
  REMOVE_DON_VI: `${BASE_API_URL_DANH_MUC}/don-vi/delete`,
  GET_DON_VI: `${BASE_API_URL_DANH_MUC}/don-vi/get`,
};
