import { FORMAT_ENGLISH, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData } from "@utils/request";
import { Col, Row } from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import TabThongTinDiemDo from "../tabs/tabContainer-tt-diem-do";
import Content from "./content";
import { getLayChiSoPhuongThuc, getLayPhuongThuc } from "./services";
export default function ModalThayDoiPhuongThuc(props) {
  const {
    detail,
    setLoading,
    closeModal,
    form,
    setLoadingDanhSach,
    setDataDanhSach,
    getDanhSachDiemDo,
  } = props;
  const [dataTTPhuongThuc, setDataTTPhuongThuc] = useState();
  const [dataPhuongThuc, setDataPhuongThuc] = useState({});
  const [dataChisoTable, setDataChiSoTable] = useState([]);
  const [dataChisoTableRight, setDataChiSoTableRight] = useState([]);

  // fetch api lấy thông tin điểm đo
  useEffect(() => {
    authGetData({
      url: `${Endpoint.LAY_THONG_TIN_DIEM_DO}?DiemDoId=${detail.id}`,
      setLoading: () => {},
      onSuccess: (res) => {
        if (res.statusCode === STATUSCODE_200 && res.data) {
          setDataTTPhuongThuc(res.data);
        }
      },
    });
  }, [detail.id, setLoading]);

  // lấy data thông tin phương thức giao nhận hiện tại
  useEffect(() => {
    getLayPhuongThuc(setDataPhuongThuc, setLoading, closeModal, form, detail);
  }, [closeModal, detail, form, setLoading]);

  // lấy data chỉ số chốt thay đổi phương thức
  const getListChiSo = useCallback(() => {
    if (Object.keys(dataPhuongThuc).length > 0) {
      const query = buildQueryString(
        parseParams({
          id: detail.id,
          ngayCuoiKy: moment(dataPhuongThuc?.ngayThayDoiCu).format(
            FORMAT_ENGLISH
          ),
        })
      );
      getLayChiSoPhuongThuc(
        setDataChiSoTable,
        setDataChiSoTableRight,
        setLoading,
        form,
        query,
        detail
      );
    }
  }, [detail, form, setLoading, dataPhuongThuc]);

  useEffect(() => {
    getListChiSo();
  }, [getListChiSo]);

  return (
    <Fragment>
      <Container>
        <Row>
          <Col span={24}>
            <TabThongTinDiemDo
              dataTTPhuongThuc={dataTTPhuongThuc}
              detail={detail}
            />
          </Col>
        </Row>
        <Content
          dataPhuongThuc={dataPhuongThuc}
          detail={detail}
          dataChisoTable={dataChisoTable}
          dataChisoTableRight={dataChisoTableRight}
          setLoadingDanhSach={setLoadingDanhSach}
          setDataDanhSach={setDataDanhSach}
          getDanhSachDiemDo={getDanhSachDiemDo}
        />
      </Container>
    </Fragment>
  );
}
const Container = styled.div`
  margin-top: 12px;
`;
