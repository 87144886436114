import LoadingComponent from "@components/Loading";
import { buildQueryString } from "@utils/function";
import { ConfigProvider, Table } from "antd";
import vn from "antd/es/locale-provider/vi_VN";
import moment from "moment";
import { Fragment, memo, useEffect, useState } from "react";
import { ContainerTab } from "./css-styled";
import { getTienDoThucHien } from "./services";

function TienDoThucHien({ detailHoSo }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();

  const { maYeuCau } = detailHoSo;

  // lay danh sach chi tiet yeu cau
  useEffect(() => {
    const queryString = buildQueryString({ keyWord: maYeuCau });
    getTienDoThucHien(setLoading, queryString, setData);
  }, [maYeuCau]);

  // khai báo columns
  const columns = [
    {
      title: "Công việc",
      key: "macviec",
      align: "left",
      width: "30%",
      render: (text, record) => {
        return (
          <>
            {record.macviec} - {record.ndungxly}
          </>
        );
      },
    },
    {
      title: "Ngày bắt đầu",
      key: "ngaybdau",
      align: "center",
      dataIndex: "ngaybdau",
      width: "15%",
    },
    // {
    //   title: "Ngày hẹn",
    //   key: "ngayhen",
    //   align: "center",
    //   dataIndex: "ngayhen",
    //   width: "15%",
    // },
    {
      title: "Ngày kết thúc",
      key: "ngaykthuc",
      align: "center",
      dataIndex: "ngaykthuc",
      width: "15%",
    },
    {
      title: "Tổng thời gian (ngày)",
      dataIndex: "songaylviec",
      key: "songaylviec",
      align: "right",
      width: "15%",
    },
  ];

  return (
    <Fragment>
      <LoadingComponent loading={loading}>
        <ConfigProvider locale={vn}>
          <ContainerTab>
            <Table
              dataSource={data}
              columns={columns}
              pagination={false}
              scroll={{ y: 700 }}
            />
          </ContainerTab>
        </ConfigProvider>
      </LoadingComponent>
    </Fragment>
  );
}

export default memo(TienDoThucHien);
