import { createSlice } from "@reduxjs/toolkit";
export const namespace = "tiepNhanPhanCong";

const INITAL_STATE = {
  dongBoImage: true,
  callAPI: false,
  getId: "",
};

const slice = createSlice({
  name: namespace,
  initialState: INITAL_STATE,
  reducers: {
    saveDongBoImageScan: (state, action) => {
      return {
        ...state,
        dongBoImage: action.payload,
      };
    },
    callAPIImageCMIS: (state, action) => {
      return {
        ...state,
        callAPI: action.payload,
      };
    },
    getIDHoSo: (state, action) => {
      return {
        ...state,
        getId: action.payload,
      };
    },
  },
});

export const reducer = slice.reducer;

export const { saveDongBoImageScan, callAPIImageCMIS, getIDHoSo } =
  slice.actions;

export const taiGiayToScanSelector = (state) => state[namespace];
