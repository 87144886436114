import { STATUSCODE_200 } from "@utils/constants";
import { authGetData } from "@utils/request";

export const dongBoCMIS = (url, setLoading, getListData) => {
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListData();
      }
    },
  });
};
