import LoadingComponent from "@components/Loading";
import { Button, Col, Modal, Row } from "antd";
import { ContainerModalTroNgai } from "../../../css-styled";

export default function XacNhanThiCongModal(props) {
  const { loading, visible, t, handleSubmit, handleCloseModalXacNhanThiCong } =
    props;
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={"Xác nhận thi công"}
        visible={visible}
        onCancel={handleCloseModalXacNhanThiCong}
        footer={null}
      >
        <ContainerModalTroNgai>
          <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
            Bạn chắc chắn đã cập nhật đầy đủ thông tin hồ sơ trước khi xác nhận
            thi công. Hoàn thành xác nhận thi công, hồ sơ sẽ được chuyển cho bộ
            phận nghiệm thu.
          </h4>
          <Row gutter={24} justify="center" style={{ marginTop: "48px" }}>
            <Col span={12} style={{ textAlign: "right" }}>
              <Button
                key="back"
                onClick={handleCloseModalXacNhanThiCong}
                className="button-closed"
              >
                {t("button.dong_lai")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                className="btn-bg-yellow"
                onClick={handleSubmit}
                loading={loading}
                key="submit"
              >
                {t("button.dong_y")}
              </Button>
            </Col>
          </Row>
        </ContainerModalTroNgai>
      </Modal>
    </LoadingComponent>
  );
}
