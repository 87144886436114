import { useState, useMemo, useCallback, Suspense, Fragment } from "react";
import PDFModal from "../pdf-viewer";
import ModalViewList from "./modal";
export default function Index(props) {
  const {
    dataSource,
    visiblePopupViewBB,
    visiblePDF,
    fileData,
    closeModal,
    setFileData,
    setVisiblePDF,
  } = props;
  const [loading] = useState(false);

  const showViewPDF = useCallback(
    (row) => {
      if (row && row?.filePath) {
        if (
          row.filePath.indexOf(".pdf") > -1 ||
          row.filePath.indexOf(".PDF") > -1
        ) {
          setFileData(row?.filePath);
          setVisiblePDF(!visiblePDF);
        } else {
          setFileData(row?.filePath);
          setVisiblePDF(false);
          window.open(row.filePath, "_blank");
        }
      }
    },
    [setFileData, setVisiblePDF, visiblePDF]
  );

  const showModalInBienBan = useMemo(
    () =>
      visiblePDF && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visiblePDF}
            setVisible={setVisiblePDF}
            title={"Biên bản treo tháo"}
            getBienBan={(setUrl) => setUrl(fileData)}
            loadingGetBienBan={false}
            isDownloadExcel={false}
            isReload={false}
            type={"Biên bản treo tháo"}
          />
        </Suspense>
      ),
    [visiblePDF, setVisiblePDF, fileData]
  );

  return (
    <Fragment>
      <ModalViewList
        dataSource={dataSource}
        visible={visiblePopupViewBB}
        closeModal={closeModal}
        loading={loading}
        showViewPDF={showViewPDF}
      />
      {showModalInBienBan}
    </Fragment>
  );
}
