import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import { authSelector } from "@state/auth";
import { Endpoint } from "@utils/endpoint";
import { Button, Form, InputNumber, Modal, Table } from "antd";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { handleCapNhatBaoCao } from "../service";
export default function ModalUpdateThuTienDien(props) {
  const { visible, setVisible, dataTable, setLoading } = props;
  const [form] = Form.useForm();
  const user = useSelector(authSelector);

  const onFinish = (values) => {
    handleCapNhatBaoCao(
      values,
      setLoading,
      Endpoint.BCCN_THEM_THU_TIEN_DIEN_CUA_NGUOI_THUE_NHA,
      form
    );
  };

  const columns = [
    {
      title: "Mã đơn vị",
      dataIndex: "MA_DVIQLY",
      key: "MA_DVIQLY",
    },
    {
      title: "Tháng",
      dataIndex: "THANG",
      key: "THANG",
      align: "center",
    },
    {
      title: "Năm",
      dataIndex: "NAM",
      key: "NAM",
      align: "center",
    },
    {
      title: "Phối hợp kiểm tra",
      dataIndex: "PHOI_HOP_KTRA",
      key: "PHOI_HOP_KTRA",
    },
    {
      title: "Tự kiểm tra",
      dataIndex: "TU_KTRA",
      key: "TU_KTRA",
    },
    {
      title: "Vi phạm",
      dataIndex: "VI_PHAM",
      key: "VI_PHAM",
    },
  ];

  return (
    <Fragment>
      <Container>
        <Modal
          width="1000px"
          title="Cập nhật thông tin thu tiền điện của người thuê nhà"
          visible={visible}
          onFinish={onFinish}
          footer={[
            <Button key="cancel" onClick={() => setVisible(false)}>
              Cancel
            </Button>,
          ]}
          onCancel={() => setVisible(false)}
        >
          <FormComponent
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            colon={false}
            onFinish={onFinish}
            initialValues={{ ma_dviqly: user.user.unitCode }}
          >
            <Form.Item
              name="reportDate"
              label="Tháng báo cáo"
              labelAlign="left"
              required
            >
              <DatePickerComponent
                picker="month"
                formKey="reportDate"
                form={form}
              />
            </Form.Item>

            <Form.Item
              name="ma_dviqly"
              label="Đơn vị"
              labelAlign="left"
              required
            >
              <Selection
                url={Endpoint.GET_TEAM_LIST_CODE}
                formKey="ma_dviqly"
                form={form}
              />
            </Form.Item>

            <Form.Item
              name="phoi_hop_ktra"
              label="Phối hợp kiểm tra"
              labelAlign="left"
              required
            >
              <InputNumber type="number" placeholder="Nhập phối hợp kiểm tra" />
            </Form.Item>

            <Form.Item name="vipham" label="Vi phạm" labelAlign="left" required>
              <InputNumber type="number" placeholder="Nhập vi phạm" />
            </Form.Item>

            <Form.Item
              name="tu_ktra"
              label="Tự kiểm tra"
              labelAlign="left"
              required
            >
              <InputNumber placeholder="Nhập tự kiểm tra" />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="mb-15px"
            >
              Cập nhật
            </Button>
          </FormComponent>
          <Table
            columns={columns}
            dataSource={dataTable}
            scroll={{ y: 420 }}
            pagination={false}
          />
        </Modal>
      </Container>
    </Fragment>
  );
}
const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
  .full-update {
    margin-bottom: 20px;
  }
`;
