/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Select, Form, Col, Input, Row, DatePicker, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import {
  authGetData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import {
  ENUM_TINHTRANGTHU,
  ENUM_LOAIHOPDONG,
  ENUM_HINHTHUCGCS,
  ENUM_HINHTHUCTRATIEN,
  ENUM_TINHTRANGSUDUNG,
  ENUM_SOPHA,
  TYPE_INPUT,
  ENUM_DIACHINH,
  HINH_THUC_KHAC,
  ONG,
  CO_QUAN,
  BA,
  FORMAT_DATE,
  METHOD_POST,
  STATUSCODE_200,
  FORMAT_ENGLISH,
  ENUM_MUCDICHSINHHOAT,
  FORMAT_ENGLISH_TIME_FULL,
} from "@utils/constants";
import styled from "styled-components";
import FormComponent from "@components/Form";
import LoadingComponent from "@components/Loading";
import {
  alertMessage,
  formatDDMMYYYYString,
  getErrorMessage,
  scrollToTop,
} from "@utils/function";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import moment from "moment";
import { isEqual } from "lodash";
import InputFormatter from "@components/InputFormatter";
import {
  handleListPhuongXaGd,
  handleListPhuongXaSd,
  handleMultiTabsThongTin,
  handlePhuongGiaoDich,
  handlePhuongXaSuDung,
  handleSaveThongTin,
} from "../../../services";
import ThongTinCoBanTabs from "./tabs-thong-tin-co-ban";
import {
  notSavedThongTinCoBan,
  changedHeSoDongThoi,
} from "@state/cap-dien/nhan-vien-khao-sat/lap-phuong-an/reducer";
import { useDispatch, useSelector } from "react-redux";
import { systemConfigSelector } from "@state/system-config";

const { Option } = Select;

export default function Index(props) {
  const { detailHoSo, initTTCB, setInitTTCB, setFetchDataTable, notSaved } =
    props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [displayLanhDao, setDisplayLanhDao] = useState();
  const [, setLoading] = useState(true);
  const [dataDetail] = useState(detailHoSo);
  const { collapsed } = useSelector(systemConfigSelector);

  const [dataDiaChinh, setDataDiaChinh] = useState([]);
  const [dataXaPhuongSuDung, setDataXaPhuongSuDung] = useState([]);
  const [dataXaPhuongGiaoDich, setDataXaPhuongGiaoDich] = useState([]);
  const [dataSoPha, setDataSoPha] = useState([]);
  const [dataTinhTrangSuDung, setDataTinhTrangSuDung] = useState([]);
  const [dataHinhThucTraTien, setDataHinhThucTraTien] = useState([]);
  const [dataTinhTrangThu, setDataTinhTrangThu] = useState([]);
  const [dataHinhThucGhiChiSo, setDataHinhThucGhiChiSo] = useState([]);
  const [dataLoaiHopDong, setDataLoaiHopDong] = useState([]);
  const [dataGioiTinhKH, setDataGioiTinhKhachHang] = useState([]);
  const [dataLanhDao, setDataLanhDao] = useState([]);
  const [dataMucDich, setDataMucDich] = useState([]);
  const [isDatCoc, setIsDatCoc] = useState(dataDetail.isDatCoc);
  const [initialValues, setInitialValues] = useState({});
  const [loadingSubmit, setLoadingSubmit] = useState(true);
  const elRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    handleMultiTabsThongTin(
      detailHoSo.hoSoId,
      setLoadingSubmit,
      setDataDiaChinh,
      setDataSoPha,
      setDataTinhTrangSuDung,
      setDataHinhThucGhiChiSo,
      setDataLoaiHopDong,
      setDataHinhThucTraTien,
      setDataTinhTrangThu,
      setDataGioiTinhKhachHang,
      setDataLanhDao,
      setDataMucDich
    );
  }, [detailHoSo]);

  const [defaultValueTenCoQuan, setDefaultValueTenCoQuan] = useState(
    dataDetail.gioiTinhKH === parseInt(ONG) ||
      dataDetail.gioiTinhKH === parseInt(BA) ||
      dataDetail.gioiTinhKH === parseInt(CO_QUAN)
      ? dataDetail.gioiTinhKH
      : parseInt(ONG)
  );

  const [defaultValueTenNguoiDaiDien, setDefaultValueTenNguoiDaiDien] =
    useState(
      dataDetail.gioiTinhNDD === parseInt(ONG) ||
        dataDetail.gioiTinhNDD === parseInt(BA)
        ? dataDetail.gioiTinhNDD
        : parseInt(ONG)
    );

  const [displayNangCongSuat] = useState(
    dataDetail.isNangCongSuat === false ? true : false
  );

  const [displayHinhThucKhac, setDisplayHinhThucKhac] = useState(
    dataDetail.hinhThucTraTienId === HINH_THUC_KHAC ? false : true
  );

  // mở/đóng Thông tin NguoiDaiDien
  const [displayNguoiDaiDien, setDisplayNguoiDaiDien] = useState(
    dataDetail.gioiTinhKH === parseInt(CO_QUAN) ? false : true
  );

  // mở/đóng thông tin giới tính người đại diện
  useEffect(() => {
    if (!notSaved.thongTinCoBan) {
      // if (dataDetail.gioiTinhKH === parseInt(CO_QUAN))
      //   setDisplayNguoiDaiDien(false);
      // else setDisplayNguoiDaiDien(true);
    }
  }, [dataDetail.gioiTinhKH, notSaved.thongTinCoBan]);

  const toDay = moment().format(FORMAT_ENGLISH);
  const [ngay, setNgay] = useState(
    dataDetail.ngayCap ? moment(dataDetail.ngayCap) : moment(toDay)
  );

  //Khởi tạo thông tin cơ bản
  useEffect(() => {
    if (initTTCB) {
      form.setFieldsValue(initialValues);
      setInitTTCB(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initTTCB]);

  useEffect(() => {
    if (dataDetail && Object.keys(dataDetail).length > 0) {
      form.setFieldsValue({
        ...dataDetail,
        ngayCap: moment(ngay, FORMAT_DATE),
        gioiTinhNDD:
          dataDetail.gioiTinhNDD === parseInt(ONG) ||
          dataDetail.gioiTinhNDD === parseInt(BA)
            ? dataDetail.gioiTinhNDD
            : parseInt(ONG),
      });
      setInitialValues(form.getFieldsValue());
    }
  }, [dataDetail, form, ngay]);

  // Thông báo khi thay đổi dữ liệu
  const handleFieldChange = () => {
    if (isEqual(initialValues, form.getFieldsValue())) {
      dispatch(notSavedThongTinCoBan(false));
    } else {
      dispatch(notSavedThongTinCoBan(true));
    }
  };

  //Init thông tin cho form
  useEffect(() => {
    let ngayConvert = undefined;
    if (
      dataDetail.ngayCap !== null &&
      dataDetail.ngayCap !== undefined &&
      dataDetail.ngayCap !== ""
    ) {
      const ngay = moment(dataDetail.ngayCap);
      ngayConvert = formatDDMMYYYYString(
        ngay.year(),
        ngay.month(),
        ngay.date()
      );
    }
    form.setFieldsValue({
      cmt: dataDetail.cmt,
      tenKH: dataDetail.tenKH,
      tenCoQuan: dataDetail.tenCoQuan,
      tenNDD: dataDetail.tenNDD,
      chucVu: dataDetail.chucVu,
      congSuatSuDung: dataDetail.congSuatSuDung,
      tenNguoiDaiDien: dataDetail.tenNguoiDaiDien,
      email: dataDetail.email,
      gioiTinhKH:
        dataDetail.gioiTinhKH === parseInt(ONG) ||
        dataDetail.gioiTinhKH === parseInt(BA) ||
        dataDetail.gioiTinhKH === parseInt(CO_QUAN)
          ? dataDetail.gioiTinhKH
          : parseInt(ONG),
      gioiTinhNDD:
        dataDetail.gioiTinhNDD === parseInt(ONG) ||
        dataDetail.gioiTinhNDD === parseInt(BA)
          ? dataDetail.gioiTinhNDD
          : parseInt(ONG),
      hinhThucTraTienId:
        dataDetail.hinhThucTraTienId === ENUM_HINHTHUCTRATIEN.TIEN_MAT ||
        dataDetail.hinhThucTraTienId === ENUM_HINHTHUCTRATIEN.QUA_NGAN_HANG ||
        dataDetail.hinhThucTraTienId === ENUM_HINHTHUCTRATIEN.EVN_HANOI ||
        dataDetail.hinhThucTraTienId === ENUM_HINHTHUCTRATIEN.HINHTHUCKHAC
          ? dataDetail.hinhThucTraTienId
          : null,
      hinhThucTraTien: dataDetail.hinhThucTraTien,
      hinhThucGhiChiSo: dataDetail.hinhThucGhiChiSo,
      hinhThucGhiChiSoId:
        dataDetail.hinhThucGhiChiSoId === ENUM_HINHTHUCGCS.TRUC_TIEP ||
        dataDetail.hinhThucGhiChiSoId === ENUM_HINHTHUCGCS.TU_XA ||
        dataDetail.hinhThucGhiChiSoId === ENUM_HINHTHUCGCS.HHU
          ? dataDetail.hinhThucGhiChiSoId
          : null,
      loaiHopDong: dataDetail.loaiHopDong,
      loaiHopDongId:
        dataDetail.loaiHopDongId === ENUM_LOAIHOPDONG.SINHHOAT ||
        dataDetail.loaiHopDongId === ENUM_LOAIHOPDONG.NGOAISINHHOAT
          ? dataDetail.loaiHopDongId
          : null,
      hinhThucTraTienKhac: dataDetail.hinhThucTraTienKhac,
      isNangCongSuat: dataDetail.isNangCongSuat,
      cmnd: dataDetail.cmnd,
      ngayCap: ngayConvert ? moment(ngayConvert, FORMAT_DATE) : undefined,
      noiCap: dataDetail.noiCap,
      diaChiGiaoDich: dataDetail.diaChiGiaoDich,
      quanHuyenGiaoDichId: dataDetail.quanHuyenGiaoDichId,
      phuongXaGiaoDichId:
        dataDetail.phuongXaGiaoDichId == null
          ? null
          : dataDetail.phuongXaGiaoDichId,
      diaChiSuDung: dataDetail.diaChiSuDung,
      phuongXaSuDungId:
        dataDetail.phuongXaSuDungId === null
          ? null
          : dataDetail.phuongXaSuDungId,
      quanHuyenSuDungId: dataDetail.quanHuyenSuDungId,
      heSoDongThoi: dataDetail.heSoDongThoi,
      hieuDienTheCu: dataDetail.hieuDienTheCu,
      lyDoNangCongSuat: dataDetail.lyDoNangCongSuat,
      maLanhDao: dataDetail.maLanhDao,
      mucDichSuDungCu:
        dataDetail.mucDichSuDungCu === ENUM_MUCDICHSINHHOAT.SINHHOAT ||
        ENUM_MUCDICHSINHHOAT.NGOAISINHHOAT
          ? dataDetail.mucDichSuDungCu
          : null,
      sdt: dataDetail.sdt,
      sdtKhac: dataDetail.sdtKhac,
      soPha:
        dataDetail.soPha === ENUM_SOPHA.MOT_PHA ||
        dataDetail.soPha === ENUM_SOPHA.BA_PHA
          ? dataDetail.soPha
          : null,
      soPhaCu:
        dataDetail.soPhaCu === ENUM_SOPHA.MOT_PHA ||
        dataDetail.soPhaCu === ENUM_SOPHA.BA_PHA
          ? dataDetail.soPhaCu
          : null,
      lanhDaoId: dataDetail.lanhDaoId,
      tenLanhDao: dataDetail.tenLanhDao,
      tiCu: dataDetail.tiCu,
      tinhTrangSuDungDien: dataDetail.tinhTrangSuDungDien,
      tinhTrangSuDungDienId:
        dataDetail.tinhTrangSuDungDienId ===
          ENUM_TINHTRANGSUDUNG.CHUA_CO_DIEN ||
        dataDetail.tinhTrangSuDungDienId ===
          ENUM_TINHTRANGSUDUNG.DUNG_CHUNG_CONG_TO
          ? dataDetail.tinhTrangSuDungDienId
          : null,
      tinhTrangThuId:
        dataDetail.tinhTrangThuId === ENUM_TINHTRANGTHU.CHUA_THU ||
        dataDetail.tinhTrangThuId === ENUM_TINHTRANGTHU.THU_NVKS
          ? dataDetail.tinhTrangThuId
          : null,
    });
  }, [dataDetail, form]);

  // //Submit form

  const handleSubmit = (values) => {
    const data = {
      ...values,
      hoSoId: detailHoSo.hoSoId,
      ngayCap: values.ngayCap
        ? moment(values.ngayCap).format(FORMAT_ENGLISH_TIME_FULL)
        : null,
      cmt: values.cmt ? values.cmt.toString() : null,
      sdt: values.sdt ? values.sdt.toString() : null,
      mucDichSuDungCu: values.mucDichSuDungCu
        ? values.mucDichSuDungCu.toString()
        : null,
      isDatCoc: isDatCoc,
    };
    handleCreateUpdate(data);
    //Xóa message lỗi sau khi submit form
    form.setFields([
      {
        name: ["hinhThucTraTienKhac"],
        errors: false,
      },
    ]);
    form.setFields([
      {
        name: ["tenNDD"],
        errors: false,
      },
    ]);
  };

  // //xử lý call api sửa/tạo

  const handleCreateUpdate = (data) => {
    handleSaveThongTin(
      data,
      setLoading,
      setFetchDataTable,
      setInitialValues,
      (value) => dispatch(notSavedThongTinCoBan(value)),
      setDisplayNguoiDaiDien,
      form,
      (data) => {
        if (data.hasOwnProperty("heSoDongThoi")) {
          dispatch(changedHeSoDongThoi(true));
        }
        scrollToTop(elRef);
      }
    );
  };

  // //handle Giới tính Cơ Quan

  const handleChangeCoQuan = (value) => {
    setDefaultValueTenCoQuan(value);

    //Thay đổi giá trị displayNguoiDaiDien

    if (value === parseInt(CO_QUAN)) {
      setDisplayNguoiDaiDien(false);
      setDefaultValueTenNguoiDaiDien(1);
      const values = form.getFieldsValue();
      form.setFieldsValue({
        ...values,
        ngayCap: moment(ngay),
        gioiTinhNDD: dataDetail.gioiTinhNDD
          ? dataDetail.gioiTinhNDD
          : parseInt(ONG),
        tenNDD: dataDetail.tenNDD,
        chucVu: dataDetail.chucVu,
      });
    } else {
      setDisplayNguoiDaiDien(true);
      setDefaultValueTenNguoiDaiDien(0);
      const values = form.getFieldsValue();
      form.setFieldsValue({
        ...values,
        ngayCap: moment(ngay),
        gioiTinhNDD: parseInt(ONG),
        tenNDD: null,
        chucVu: null,
      });
    }

    //Nếu giá trị value bằng với gioiTinhKH ban đầu thì không cần báo warning
    if (value === dataDetail.gioiTinhKH) {
      dispatch(notSavedThongTinCoBan(false));
    } else {
      dispatch(notSavedThongTinCoBan(true));
    }
  };

  // // handle display người đại diện

  const handleChangeNguoiDaiDien = (value) => {
    setDefaultValueTenNguoiDaiDien(value);
  };

  const handleChangeHinhThucTraTien = (value) => {
    if (value !== null && value === HINH_THUC_KHAC) {
      setDisplayHinhThucKhac(false);
      form.setFieldsValue({
        hinhThucTraTienKhac: dataDetail.hinhThucTraTienKhac,
      });
    } else {
      setDisplayHinhThucKhac(true);
      form.setFieldsValue({
        hinhThucTraTienKhac: null,
      });
    }
    //Kiểm tra nếu giá trị truyền vào khác so với lúc đầu thì ko hiển thị dấu * báo warning
    if (value === dataDetail.hinhThucTraTienKhac) {
      dispatch(notSavedThongTinCoBan(false));
    } else {
      dispatch(notSavedThongTinCoBan(true));
    }
  };

  // //thay đổi quận huyện call api xã phường

  const handleChangeDiaChinhSuDung = (value) => {
    handlePhuongXaSuDung(setLoading, value, setDataXaPhuongSuDung, form);
  };

  // //thay đổi quận huyện call api xã phường

  const handleChangeDiaChinhGiaoDich = (value) => {
    handlePhuongGiaoDich(setLoading, value, setDataXaPhuongGiaoDich, form);
  };

  //Thay đổi ngày
  const handlChangeNgay = (date) => {
    let convert = "";
    if (date === null) convert = undefined;
    else convert = moment(date).format(FORMAT_DATE);
  };

  const renderPhuongXa = useCallback((id) => {
    handleListPhuongXaSd(setLoading, id, setDataXaPhuongSuDung);
  }, []);

  const renderPhuongXaGiaoDich = useCallback((id) => {
    handleListPhuongXaGd(setLoading, id, setDataXaPhuongGiaoDich);
  }, []);

  useEffect(() => {
    if (Object.keys(dataDetail).length > 0) {
      renderPhuongXa(dataDetail.quanHuyenSuDungId);
      renderPhuongXaGiaoDich(dataDetail.quanHuyenGiaoDichId);
    }
  }, [dataDetail, renderPhuongXa, renderPhuongXaGiaoDich]);

  //Ẩn/hiện dropdown danh sách lãnh đạo
  useEffect(() => {
    if (dataDetail.isLanhDaoKy) {
      setDisplayLanhDao(true);
    } else {
      setDisplayLanhDao(false);
    }
  }, [dataDetail.isLanhDaoKy]);

  //check đặt cọc
  const handleChangeDatCoc = (e) => {
    setIsDatCoc(e.target.checked);
  };

  const handleChangeInput = (e) => {
    const val = e.target.value.trim();
    if (val.includes(" ")) {
      form.setFields([
        {
          name: "cmt",
          errors: ["Vui lòng không nhập khoảng trắng"],
        },
      ]);
    } else if (val.length > 13) {
      form.setFields([
        {
          name: "cmt",
          errors: ["Vui lòng không nhập quá 13 ký tự"],
        },
      ]);
    } else {
      form.setFields([
        {
          name: "cmt",
          errors: false,
        },
      ]);
      form.setFieldsValue({
        cmt: val.trim(),
      });
    }
  };

  return (
    <div ref={elRef}>
      <ThongTinCoBanTabs
        t={t}
        loadingSubmit={loadingSubmit}
        form={form}
        dataDetail={dataDetail}
        ngay={ngay}
        handleSubmit={handleSubmit}
        handleFieldChange={handleFieldChange}
        handleChangeCoQuan={handleChangeCoQuan}
        defaultValueTenCoQuan={defaultValueTenCoQuan}
        dataGioiTinhKH={dataGioiTinhKH}
        displayNguoiDaiDien={displayNguoiDaiDien}
        handleChangeNguoiDaiDien={handleChangeNguoiDaiDien}
        defaultValueTenNguoiDaiDien={defaultValueTenNguoiDaiDien}
        handlChangeNgay={handlChangeNgay}
        handleChangeDiaChinhGiaoDich={handleChangeDiaChinhGiaoDich}
        dataDiaChinh={dataDiaChinh}
        dataXaPhuongGiaoDich={dataXaPhuongGiaoDich}
        handleChangeDiaChinhSuDung={handleChangeDiaChinhSuDung}
        dataXaPhuongSuDung={dataXaPhuongSuDung}
        displayNangCongSuat={displayNangCongSuat}
        dataSoPha={dataSoPha}
        dataTinhTrangSuDung={dataTinhTrangSuDung}
        dataHinhThucGhiChiSo={dataHinhThucGhiChiSo}
        dataLoaiHopDong={dataLoaiHopDong}
        handleChangeHinhThucTraTien={handleChangeHinhThucTraTien}
        dataHinhThucTraTien={dataHinhThucTraTien}
        displayHinhThucKhac={displayHinhThucKhac}
        displayLanhDao={displayLanhDao}
        dataLanhDao={dataLanhDao}
        dataTinhTrangThu={dataTinhTrangThu}
        dataMucDich={dataMucDich}
        collapsed={collapsed}
        handleChangeDatCoc={handleChangeDatCoc}
        isDatCoc={isDatCoc}
        handleChangeInput={handleChangeInput}
      />
    </div>
  );
}
