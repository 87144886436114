import { reducers } from "./modules";
import { combineReducers } from "redux";

const root = combineReducers({
  ...reducers,
});

export const rootReducer = (state, action) =>
  root(
    action.type === "auth/signOut"
      ? { ...state, lapPhuongAn: undefined, filterCapDien : undefined }
      : state,
    action
  );
