import { Form } from "antd";
import { useCallback, useEffect, useState } from "react";
import { handleMultiBoLoc } from "./services";
import FormBoLoc from "./form-bo-loc";

export default function ListFilter(props) {
  const { handleSearch, clearFilter, filterConditions, user, setType } = props;
  const [, setLoading] = useState(false);
  const [loaiYeuCau, setLoaiYeuCau] = useState([]);
  const [soPha, setSoPha] = useState([]);
  const [donVi, setDonVi] = useState([]);
  const [disabled, setDisabled] = useState(user.isTongCongTy ? true : false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);

  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  useEffect(() => {
    handleMultiBoLoc(setLoading, setLoaiYeuCau, setSoPha, setDonVi);
  }, []);

  const handleChangeUnit = (values) => {
    let disabled = false;

    donVi.map((item) => {
      if ([values].includes(item.id)) {
        if (item.isTongCongTy) {
          disabled = true;
        }
      }
      return true;
    });
    setDisabled(disabled);
  };
  return (
    <FormBoLoc
      form={form}
      handleSearch={handleSearch}
      loaiYeuCau={loaiYeuCau}
      filterConditions={filterConditions}
      clear={clear}
      user={user}
      soPha={soPha}
      handleChangeUnit={handleChangeUnit}
      donVi={donVi}
      disabled={disabled}
      setType={setType}
    />
  );
}
