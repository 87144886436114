const o1 = {
  o1: "o1",
  o1x: "o1x",
};

const o11 = {
  o11: "o11",
  o11x: "o11x",
};

const o12 = {
  o12: "o12",
  o12x: "o12x",
};

const o13 = {
  o13: "o13",
  o13x: "o13x",
};

const o14 = {
  o14: "o14",
  o14x: "o14x",
};

const o15 = {
  o15: "o15",
  o15x: "o15x",
};

const o2 = {
  o2: "o2",
  o2x: "o2x",
};

const o21 = {
  o21: "o21",
  o21x: "o21x",
};

const o22 = {
  o22: "o22",
  o22x: "o22x",
};

const o3 = {
  o3: "o3",
  o3x: "o3x",
};

const o31 = {
  o31: "o31",
  o31x: "o31x",
};

const o32 = {
  o32: "o32",
  o32x: "o32x",
};

const o33 = {
  o33: "o33",
  o33x: "o33x",
};

const o34 = {
  o34: "o34",
  o34x: "o34x",
};

const o4 = {
  o4: "o4",
  o4x: "o4x",
};

const o41 = {
  o41: "o41",
  o41x: "o41x",
};

const o5 = {
  o5: "o5",
  o5x: "o5x",
};

const o51 = {
  o51: "o51",
  o51x: "o51x",
};

const o6 = {
  o6: "o6",
  o6x: "o6x",
};

const o61 = {
  o61: "o61",
  o61x: "o61x",
};

const o62 = {
  o62: "o62",
  o62x: "o62x",
};

const o621 = {
  o621: "o621",
  o621x: "o621x",
};

const o622 = {
  o622: "o622",
  o622x: "o622x",
};

const o63 = {
  o63: "o63",
  o63x: "o63x",
};

const o7 = {
  o7: "o7",
  o7x: "o7x",
};

const o71 = {
  o71: "o71",
  o71x: "o71x",
};

const o8 = {
  o8: "o8",
  o8x: "o8x",
};

const o81 = {
  o81: "o81",
  o81x: "o81x",
};

const thCRM = {
  o: "o",
};

export const tich_hop_CRM = {
  ...thCRM,
  ...o1,
  ...o11,
  ...o12,
  ...o13,
  ...o14,
  ...o15,
  ...o2,
  ...o21,
  ...o22,
  ...o3,
  ...o31,
  ...o32,
  ...o33,
  ...o34,
  ...o4,
  ...o41,
  ...o5,
  ...o51,
  ...o6,
  ...o61,
  ...o62,
  ...o621,
  ...o622,
  ...o63,
  ...o7,
  ...o71,
  ...o8,
  ...o81,
};
