import React, { useState, useCallback, useEffect, Fragment } from "react";
import { Tabs, Spin } from "antd";
import EditUserForm from "./form-edit-user";
import CreateUserForm from "./form-create-user";
import ChangePasswordForm from "./form-change-password";
import FunctionDecentralizationForm from "./form-function-decentralization";
import DataDecentralizationForm from "./form-data-decentralization";
import styled from "styled-components";
import { authGetData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
export default function ModalCreateOrEditUser(props) {
  const { user, getUserList } = props;
  const { TabPane } = Tabs;
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [activeKey, setActiveKey] = useState("1");
  const getUserDetail = useCallback(() => {
    authGetData({
      url: `${Endpoint.USER}/user-detail?id=${user ? user.id : userInfo.id}`,
      setLoading,
      onSuccess: (res) => {
        setUserInfo(res.data);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  useEffect(() => {
    if (user && user.id) {
      getUserDetail();
    }
  }, [getUserDetail, user]);
  const handleChangeTab = useCallback((key) => {
    setActiveKey(key);
  }, []);
  const renderFirstTab = useCallback(() => {
    if (loading) {
      return <div className="text-center">Đang tải dữ liệu...</div>;
    }
    if (!loading && !user && !userInfo) {
      return (
        <CreateUserForm
          setUserInfo={setUserInfo}
          getUserList={getUserList}
          user={userInfo}
        />
      );
    }
    if (!loading && userInfo && userInfo.id) {
      return (
        <EditUserForm
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          getUserList={getUserList}
          getUserDetail={getUserDetail}
          setActiveKey={setActiveKey}
        />
      );
    }
  }, [loading, getUserDetail, getUserList, userInfo, user]);
  return (
    <Spin spinning={loading}>
      <Container>
        <Tabs
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={handleChangeTab}
        >
          <TabPane tab="Thông tin người dùng" key="1">
            {renderFirstTab()}
          </TabPane>

          {userInfo && (
            <Fragment>
              <TabPane tab="Phân quyền chức năng" key="2">
                <FunctionDecentralizationForm
                  user={userInfo}
                  activeKey={activeKey}
                />
              </TabPane>
              {!userInfo.isTongCongTy && (
                <TabPane tab="Phân quyền dữ liệu" key="3">
                  <DataDecentralizationForm
                    user={userInfo}
                    getUserList={getUserList}
                    activeKey={activeKey}
                    getUserDetail={getUserDetail}
                  />
                </TabPane>
              )}
              {user && user.id && !userInfo.sso && (
                <TabPane tab="Thay đổi mật khẩu" key="4">
                  <ChangePasswordForm
                    userId={userInfo.id}
                    getUserList={getUserList}
                    getUserDetail={getUserDetail}
                  />
                </TabPane>
              )}
            </Fragment>
          )}
        </Tabs>
      </Container>
    </Spin>
  );
}

const Container = styled.div`
  padding: 10px 20px;
`;
