import { Collapse, Col, Row } from "antd";
import { Fragment } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { ENUM_MUCDICH_SDD } from "@utils/constants";
const { Panel } = Collapse;
export default function Index(props) {
  const { detail } = props;
  return (
    <Fragment>
      <Col span={24}>
        <Row gutter={24}>
          <Col span={8}>
            <h6 className="fs-12px c-t-label">Tên khách hàng</h6>
            <h5 className="fs-14px font-bold">{detail?.tenKhachHang}</h5>
          </Col>
          <Col span={8}>
            <h6 className="fs-12px c-t-label">Mã khách hàng</h6>
            <h5 className="fs-14px font-bold">{detail?.maKhachHang}</h5>
          </Col>
          <Col span={8}>
            <h6 className="fs-12px c-t-label">Điện thoại</h6>
            <h5 className="fs-14px font-bold">{detail?.soDienThoai}</h5>
          </Col>
          <Col span={24} md={16}>
            <h6 className="fs-12px c-t-label">Địa chỉ</h6>
            <h5 className="fs-14px font-bold">{detail?.diaChi}</h5>
          </Col>
          <Col span={24} md={8}>
            <h6 className="fs-12px c-t-label">Loại khách hàng</h6>
            <h5 className="fs-14px font-bold">
              {detail?.loaiKhachHang?.toString() ===
              ENUM_MUCDICH_SDD.NGOAISINHHOAT
                ? "Ngoài sinh hoạt"
                : "Sinh hoạt"}
            </h5>
          </Col>
          <Col span={24} md={8}>
            <h6 className="fs-12px c-t-label">Mã công tơ</h6>
            <h5 className="fs-14px font-bold">{detail?.maCongTo}</h5>
          </Col>
          <Col span={24} md={8}>
            <h6 className="fs-12px c-t-label">Số công tơ</h6>
            <h5 className="fs-14px font-bold">{detail?.soCongTo}</h5>
          </Col>
          <Col span={24} md={8}>
            <h6 className="fs-12px c-t-label">Số pha</h6>
            <h5 className="fs-14px font-bold">{detail?.soPha}</h5>
          </Col>
          <Col span={24} md={8}>
            <h6 className="fs-12px c-t-label">Loại điểm đo</h6>
            <h5 className="fs-14px font-bold">{detail?.loaiDiemDo}</h5>
          </Col>
          <Col span={24} md={8}>
            <h6 className="fs-12px c-t-label">Cấp điện áp</h6>
            <h5 className="fs-14px font-bold">{detail?.capDienAp}</h5>
          </Col>
          <Col span={24} md={8}>
            <h6 className="fs-12px c-t-label">Ký mua CSPK</h6>
            <h5 className="fs-14px font-bold">
              {detail?.kiMuaCSPK === 0 ? "Không" : "Có"}
            </h5>
          </Col>
          <Col span={24} md={8}>
            <h6 className="fs-12px c-t-label">Đội</h6>
            <h5 className="fs-14px font-bold">{detail?.doi}</h5>
          </Col>
          <Col span={24} md={8}>
            <h6 className="fs-12px c-t-label">Nhân viên được phân công</h6>
            <h5 className="fs-14px font-bold">{detail?.user}</h5>
          </Col>
          {detail?.loaiKhachHang === "0"  && detail?.isChuyenApGia === false ? (
            <Col span={24} md={24}>
              <span className="fs-12px c-t-label">Chuỗi giá</span>
              <span className="fs-14px font-bold ml-20px">
                {detail?.chuoiGia}
              </span>
            </Col>
          ) : null}
        </Row>
        <br />
        <Collapse
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined
              style={{ fontSize: 18 }}
              rotate={isActive ? 90 : 0}
            />
          )}
        >
          {detail?.loaiKhachHang === "0" && detail?.isChuyenApGia === false ? (
            <Panel
              header={
                <h3 className="font-bold" style={{ position: "relative" }}>
                  Thay đổi định mức
                </h3>
              }
              collapsible={"header"}
              key="2"
            >
              <Row gutter={24}>
                <Col span={4}>
                  <span className="fs-12px c-t-label">Số hộ hiện tại</span>
                </Col>
                <Col span={8}>
                  <span className="fs-14px font-bold">{detail?.soHo}</span>
                </Col>
                <Col span={4}>
                  <h6 className="fs-12px c-t-label">Ngày áp dụng</h6>
                </Col>
                <Col span={8}>
                  <h5 className="fs-14px font-bold">{detail?.ngayApDung}</h5>
                </Col>
                {detail?.thayDoi === true ? (
                  <Col span={12}>
                    <Row gutter={24}>
                      <Col span={8}>
                        <h6 className="fs-12px c-t-label">Số hộ mới</h6>
                      </Col>
                      <Col span={16}>
                        <h5 className="fs-14px font-bold">{detail?.soHoMoi}</h5>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span={12}></Col>
                )}
                {/* {detail?.thayDoi === true ? (
                  <Col span={12}>
                    <Row gutter={24}>
                      <Col span={8}>
                        <h6 className="fs-12px c-t-label">Ngày áp dụng mới</h6>
                      </Col>
                      <Col span={16}>
                        <h5 className="fs-14px font-bold">
                          {detail?.ngayApDungMoi}
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                ) : null} */}
                <Col span={4}>
                  <h6 className="fs-12px c-t-label">Ngày áp dụng mới</h6>
                </Col>
                <Col span={8}>
                  <h5 className="fs-14px font-bold">{detail?.ngayApDungMoi}</h5>
                </Col>
                {detail?.thayDoi === true ? (
                  <Col span={24}>
                    <Row gutter={24}>
                      <Col span={4}>
                        <h6 className="fs-12px c-t-label">Có truy thu</h6>
                      </Col>
                      <Col span={20}>
                        <h5 className="fs-14px font-bold">
                          {detail?.truyThu ? "Có" : "Không"}
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                ) : null}
                {detail?.thayDoi === true ? (
                  <Col span={24}>
                    <Row gutter={24}>
                      <Col span={4}>
                        <h6 className="fs-12px c-t-label">Nội dung truy thu</h6>
                      </Col>
                      <Col span={20}>
                        <h5 className="fs-14px font-bold">
                          {detail?.noiDungTruyThu}
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                ) : null}
              </Row>
            </Panel>
          ) : (
            <Panel
              header={
                <h3 className="font-bold" style={{ position: "relative" }}>
                  Thay đổi áp giá
                </h3>
              }
              collapsible={"header"}
              key="2"
            >
              <Row gutter={24}>
                <Col span={4}>
                  <span className="fs-12px c-t-label">Chuỗi giá cũ</span>
                </Col>
                <Col span={8}>
                  <span className="fs-14px font-bold">{detail?.chuoiGia}</span>
                </Col>
                <Col span={4}>
                  <h6 className="fs-12px c-t-label">Ngày áp dụng cũ</h6>
                </Col>
                <Col span={8}>
                  <h5 className="fs-14px font-bold">{detail?.ngayApDung}</h5>
                </Col>
                {detail?.thayDoi === true ? (
                  <Col span={12}>
                    <Row gutter={24}>
                      <Col span={8}>
                        <h6 className="fs-12px c-t-label">Chuỗi giá mới</h6>
                      </Col>
                      <Col span={16}>
                        <h5 className="fs-14px font-bold">
                          {detail?.chuoiGiaMoi}
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span={12}></Col>
                )}
                {/* {detail?.thayDoi === true ? (
                  <Col span={12}>
                    <Row gutter={24}>
                      <Col span={8}>
                        <h6 className="fs-12px c-t-label">Ngày áp dụng mới</h6>
                      </Col>
                      <Col span={16}>
                        <h5 className="fs-14px font-bold">
                          {detail?.ngayApDungMoi}
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                ) : null} */}
                <Col span={4}>
                  <h6 className="fs-12px c-t-label">Ngày áp dụng mới</h6>
                </Col>
                <Col span={8}>
                  <h5 className="fs-14px font-bold">{detail?.ngayApDungMoi}</h5>
                </Col>
                {detail?.thayDoi === true ? (
                  <Col span={12}>
                    <Row gutter={24}>
                      <Col span={8}>
                        <h6 className="fs-12px c-t-label">Có truy thu</h6>
                      </Col>
                      <Col span={16}>
                        <h5 className="fs-14px font-bold">
                          {detail?.truyThu ? "Có" : "Không"}
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                ) : null}
                {detail?.thayDoi === true ? (
                  <Col span={12}>
                    <Row gutter={24}>
                      <Col span={8}>
                        <h6 className="fs-12px c-t-label">Ký mua CSPK</h6>
                      </Col>
                      <Col span={16}>
                        <h5 className="fs-14px font-bold">
                          {detail?.kiMuaCSPKMoi === 0 ? "Không" : "Có"}
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                ) : null}
                {detail?.thayDoi === true ? (
                  <Col span={24}>
                    <Row gutter={24}>
                      <Col span={4}>
                        <h6 className="fs-12px c-t-label">Nội dung truy thu</h6>
                      </Col>
                      <Col span={20}>
                        <h5 className="fs-14px font-bold">
                          {detail?.noiDungTruyThu}
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                ) : null}
              </Row>
            </Panel>
          )}
        </Collapse>
      </Col>
    </Fragment>
  );
}
