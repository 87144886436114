import FormComponent from "@components/Form";
import { removeAccents } from "@utils/function";
import { Button, Col, Form, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getListDonVi, getListNhanVien } from "./services";

export default function KiemTraThongTinBoLoc(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    form,
  } = props;
  const [loading, setLoading] = useState(false);
  const [listDonVi, setListDonVi] = useState([]);
  const [listNhanVien, setListNhanVien] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      ...filterConditions,
    });
  }, [form, filterConditions]);

  useEffect(() => {
    getListDonVi(setLoading, setListDonVi);
    getListNhanVien(setLoading, setListNhanVien);
  }, []);



  const clear = () => {
    form.resetFields();
    clearFilter();
  };

  return (
    <Styled>
      <div className="list-bo-loc">
        <FormComponent
          layout="vertical"
          form={form}
          onFinish={handleSearch}
          initialValues={{
            DonViId: filterConditions.DonViId,
            // ...(filterConditions.DoiId && { DoiId: filterConditions.DoiId }),
          }}
        >
          <Row gutter={[8, 16]}>
            <Col span={8}>
              <Form.Item name="DonViId" label="Đơn vị">
                <Select
                  loading={loading}
                  showSearch
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                  disabled={listDonVi.length < 2}
                >
                  {listDonVi &&
                    listDonVi.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
           
            <Col span={8} offset={1}>
              <Form.Item name="TenNhanVienHRMS" label="Tên nhân viên (HRMS)">
                <Select
                  allowClear
                  showSearch
                  loading={loading}
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        removeAccents(option.children)
                          .toLowerCase()
                          .indexOf(removeAccents(input).toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {listNhanVien &&
                    listNhanVien.map((item, id) => {
                      return (
                        <Select.Option key={id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col
              span={4}
              offset={1}
              className="list-bo-loc-button"
            >
              <Button onClick={clear}>Bỏ lọc</Button>
              <Button type="primary" htmlType="submit">
                Tìm kiếm
              </Button>
            </Col>
          </Row>
        </FormComponent>
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  .list-bo-loc {
    .list-bo-loc-button {
      display: flex;
      gap: 20px !important;
      align-items: center;
      padding-right: 14px !important;
      justify-content: end;
    }
  }
`;
