import { memo, useCallback, useEffect, useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FORMAT_DATE, FORMAT_ENGLISH } from "@utils/constants";
import "moment/locale/vi";
import {
  handleDelete,
  handleListThoiGian,
  handleSubmitUpdateDataVatTu,
  handleSubmitUpdateDataVatTuThoiGian,
} from "../services";
import UICapNhatVatTu from "../cap-nhat-vat-tu/popup-cap-nhat-vat-tu";

function CapNhatTuModal({
  closeModal,
  getListData,
  dataHinhThuc,
  detailTableData,
  dataLoaiChiPhi,
  dataChungLoai,
  tableColums,
  nameFields,
  dataLoaiYC,
  dataHinhThucNone,
  countYC,
}) {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const { t } = useTranslation();
  const toDay = moment().format(FORMAT_DATE);
  const [loading, setLoading] = useState(false);
  const [ngayBatDau, setNgayBatDau] = useState(
    detailTableData.ngayHieuLuc ? detailTableData.ngayHieuLuc : toDay
  );
  const [ngayKetThuc, setNgayKetThuc] = useState(
    detailTableData.ngayHetHieuLuc ? detailTableData.ngayHetHieuLuc : toDay
  );
  const [many, setMany] = useState(
    detailTableData.loaiHinhThucThiCong ? true : false
  );
  const [vatTuChinh, setVatTuChinh] = useState(
    detailTableData.isVatTuChinh ? detailTableData.isVatTuChinh : false
  );
  const [keyData, setKeyData] = useState([1]);
  const [dataSource, setDataSource] = useState([
    {
      key: 0,
    },
  ]);
  const [dataTable, setDataTable] = useState([]);
  const [, setCountData] = useState(0);
  const [count, setCount] = useState(1);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [addThongTin, setAddThongTin] = useState(false);
  const [, setNotSaved] = useState({
    thongTinThoiGian: false,
  });
  const [pageDefault, setPageDefault] = useState(1);
  //fetch dữ liệu

  const getListVatTuThoiGian = useCallback(() => {
    handleListThoiGian(
      detailTableData,
      setLoading,
      setData,
      setTotalCount,
      1,
      setDataTable,
      setCountData
    );
  }, [detailTableData]);
  //call page size
  const handleChangePage = useCallback(
    (page) => {
      setPageDefault(page);

      handleListThoiGian(
        detailTableData,
        setLoading,
        setData,
        setTotalCount,
        page,
        setDataTable,
        setCountData
      );
    },
    [detailTableData]
  );
  useEffect(() => {
    getListVatTuThoiGian();
    // eslint-disable-next-line
  }, [getListVatTuThoiGian]);

  //cập nhật vật tư
  const onFinishVatTu = (values) => {
    values.vatTuId = detailTableData.vatTuId;

    handleSubmitUpdateDataVatTu(
      values,
      setLoading,
      getListData,
      closeModal,
      form1,
      vatTuChinh,
      many
    );
  };

  //back form cập nhật
  const handleBackForm = () => {
    setAddThongTin(false);
    form2.getFieldsValue({ ...detailTableData });
  };

  const handleRemoveThoiGian = (ids) => {
    handleDelete(ids, setLoading, t, getListVatTuThoiGian);
  };

  //cập nhật thông tin giá
  const onFinishVatTuThoiGian = (values) => {
    values.id = detailTableData.vatTuId;
    const idVatTuThoiGian = data[0]?.vatTuThoiGianId;
    const dataUpdate = addThongTin ? dataSource : dataTable;
    handleSubmitUpdateDataVatTuThoiGian(
      values,
      dataUpdate,
      setLoading,
      getListData,
      form2,
      vatTuChinh,
      many,
      ngayBatDau,
      ngayKetThuc,
      dataLoaiYC,
      dataHinhThucNone,
      nameFields,
      countYC,
      idVatTuThoiGian,
      addThongTin,
      keyData,
      getListVatTuThoiGian,
      handleBackForm
    );
  };

  const handleChangeBatDau = (date) => {
    let convert = "";
    if (date === null) convert = undefined;
    else convert = moment(date).format(FORMAT_ENGLISH);
    setNgayBatDau(convert);
  };

  const handleChangeKetThuc = (date) => {
    let convert = "";
    if (date === null) convert = undefined;
    else convert = moment(date).format(FORMAT_ENGLISH);
    setNgayKetThuc(convert);
  };

  const handleChangeMany = (e) => {
    setMany(e.target.checked);
  };

  const handleChangeVatTuChinh = (e) => {
    setVatTuChinh(e.target.checked);
  };
  const [countAdd, setCountAdd] = useState([]);

  const handleAdd = useCallback(() => {
    const countKey = keyData[keyData.length - 1] + 1;
    setCount(countKey);

    setCountAdd((old) => [...old, countKey]);
    const newData = {
      key: countKey,
      hinhThucThiCongId: null,
      [`dgtg1-${countKey}`]: null,
      [`dgtg2-${countKey}`]: null,
      [`dgtg3-${countKey}`]: null,
      [`dgttc1-${countKey}`]: null,
      [`dgttc2-${countKey}`]: null,
      [`dgttc3-${countKey}`]: null,
      [`dgttcvt1-${countKey}`]: null,
      [`dgttcvt2-${countKey}`]: null,
      [`dgttcvt3-${countKey}`]: null,
    };
    setDataTable((oldState) => [...oldState, newData]);
  }, [keyData]);

  const handleRemove = useCallback(
    (idx) => {
      const currentColumns = [...dataTable.filter((item) => item.key !== idx)];
      setDataTable(currentColumns);
      const keys = [];
      currentColumns.forEach((item) => {
        return keys.push(item.key);
      });
      setKeyData(keys);

      const currenCount = [...countAdd.filter((item) => item !== idx)];
      setCountAdd(currenCount);
    },
    [countAdd, dataTable]
  );

  const handleAddForUpdateData = useCallback(() => {
    const countKey = count + 1;
    setCountData(countKey);

    const newData = {
      key: countKey,
    };
    setDataSource((oldState) => [...oldState, newData]);
  }, [count]);

  // const [initialValues, setInitialValues] = useState(dataTable);

  //add thông tin thời gian
  const handleAddThongTin = () => {
    setDataSource([
      {
        key: 1,
      },
    ]);
    setAddThongTin(true);
    form2.resetFields();
  };

  return (
    <UICapNhatVatTu
      loading={loading}
      onFinishVatTu={onFinishVatTu}
      onFinishVatTuThoiGian={onFinishVatTuThoiGian}
      ngayBatDau={ngayBatDau}
      ngayKetThuc={ngayKetThuc}
      vatTuChinh={vatTuChinh}
      form1={form1}
      form2={form2}
      handleChangeVatTuChinh={handleChangeVatTuChinh}
      handleChangeMany={handleChangeMany}
      handleChangeBatDau={handleChangeBatDau}
      handleChangeKetThuc={handleChangeKetThuc}
      many={many}
      // column={column}
      // columns={columns}
      dataSource={dataSource}
      handleAdd={handleAdd}
      dataTable={dataTable}
      handleAddForUpdateData={handleAddForUpdateData}
      closeModal={closeModal}
      handleAddThongTin={handleAddThongTin}
      addThongTin={addThongTin}
      handleBackForm={handleBackForm}
      data={data[0]}
      totalCount={totalCount}
      t={t}
      setNotSaved={setNotSaved}
      dataLoaiChiPhi={dataLoaiChiPhi}
      dataChungLoai={dataChungLoai}
      detailTableData={detailTableData}
      handleChangePage={handleChangePage}
      dataHinhThuc={dataHinhThuc}
      tableColums={tableColums}
      nameFields={nameFields}
      handleRemove={handleRemove}
      handleRemoveThoiGian={handleRemoveThoiGian}
      toDay={toDay}
      setKeyData={setKeyData}
      keyData={keyData}
      pageDefault={pageDefault}
      countAdd={countAdd}
    />
  );
}

export default memo(CapNhatTuModal);
