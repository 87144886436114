import CheckPermission from "@components/CheckPermission";
import LoadingComponent from "@components/Loading";
import { giao_nhan_dien_nang } from "@permissions/c_gndn";
import { Button, Form, Row, Table } from "antd";
import { Fragment, useEffect, useMemo, useState } from "react";
import ModalDanhSachDiemDoChuaNhap from "../modal/modal-danh-sach-don-vi-chua-nhap";
import { getListDiemDoChuaNhapCS } from "../tinh-san-luong/services";
import ConfirmModal from "./modal-confirm";
import { getThangLamViec, HUY_CHUYEN_THANG_LAMVIEC, KIEMTRA_CHUYEN_THANG_LAMVIEC } from "./service";

export default function Index() {
  const [loading, setLoading] = useState(false);
  const [loadingHuy] = useState(false);
  const [loadingChuyen] = useState(false);
  const [displayMess, setDisplayMess] = useState(true);
  const [displayMessHuy, setDisplayMessHuy] = useState(true);
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [visibleHuy, setVisibleHuy] = useState(false);
  const [visibleTable, setVisibleTable] = useState(false);
  const [visibleTableHuy, setVisibleTableHuy] = useState(false);
  const [thangLamViec, setThangLamViec] = useState("");
  const [KiemTraThangLamViec, setKiemTraThangLamViec] = useState([]);
  const [KiemTraHuy, setKiemTraHuy] = useState([]);
  const [fetchThangLamViec, setFetchThangLamViec] = useState(false);
  const [visibleDiemDoChuaNhap, setVisibleDiemDoChuaNhap] = useState(false);
  const [dataTable, setDaTaTable] = useState([]);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: "15%",
      render: (text) => <div className="table-text-center">{text}</div>,
    },
    {
      title: "Đơn vị",
      dataIndex: "tenDonVi",
      key: "tenDonVi",
      width: "80%",
    },
  ];

  const handleOpenModalConfirm = () => {
    setVisibleConfirm(!visibleConfirm);
  };
  const handleOpenModalHuy = () => {
    setVisibleHuy(!visibleHuy);
  };

  const handleCloseModalHuy = () => {
    setVisibleHuy(false);
  };

  const handleCloseModalConfirm = () => {
    setVisibleConfirm(false);
  };

  useEffect(() => {
    const query = {
      Thang: thangLamViec.thang,
      Nam: thangLamViec.nam,
    };
    if(thangLamViec.thang && thangLamViec.nam){
      getListDiemDoChuaNhapCS(setDaTaTable, setLoading, query);
    }
  }, [thangLamViec.thang, thangLamViec.nam]);
  // Check diem do chua nhap chi so
  const handleOpenModalDiemDoChuaNhap = () => {
    if (dataTable.length > 0) {
      setVisibleDiemDoChuaNhap(true);
    } else {
      handleOpenModalConfirm();
    }
  };

  const handleCloseModalDiemDoChuaNhap = () => {
    setVisibleDiemDoChuaNhap(false);
  };

  const showModalDiemDoChuaNhap = useMemo(
    () =>
      visibleDiemDoChuaNhap && (
        <ModalDanhSachDiemDoChuaNhap
          visible={visibleDiemDoChuaNhap}
          close={handleCloseModalDiemDoChuaNhap}
          loading={loading}
          setLoading={setLoading}
          data={dataTable}
        />
      ),
    [visibleDiemDoChuaNhap, loading, dataTable]
  );

  //render popup chuyển tháng
  const showModalConfirm = useMemo(
    () =>
      visibleConfirm && (
        <ConfirmModal
          visible={visibleConfirm}
          handleCloseModal={handleCloseModalConfirm}
          setVisible={setVisibleConfirm}
          content={`Thực hiện chuyển tháng làm việc, bạn sẽ không được phép chỉnh sửa dữ liệu của tháng ${
            thangLamViec && Object.keys(thangLamViec).length > 0
              ? `${thangLamViec.thang}/${thangLamViec.nam}`
              : ""
          }.
           Bạn muốn thực hiện chuyển tháng làm việc mới ${
             thangLamViec && Object.keys(thangLamViec).length > 0
               ? `${thangLamViec.thang + 1}/${thangLamViec.nam}`
               : ""
           } ?`}
          title={"Chuyển tháng"}
          type={KIEMTRA_CHUYEN_THANG_LAMVIEC}
          setFetchThangLamViec={setFetchThangLamViec}
          setKiemTraThangLamViec={setKiemTraThangLamViec}
          setDisplayMess={setDisplayMess}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleConfirm]
  );

  //render popup hủy chuyển tháng

  const showModalHuy = useMemo(
    () =>
      visibleHuy && (
        <ConfirmModal
          visible={visibleHuy}
          handleCloseModal={handleCloseModalHuy}
          setVisible={setVisibleHuy}
          content={`Bạn muốn thực hiện Hủy chuyển tháng về tháng ${
            thangLamViec && Object.keys(thangLamViec).length > 0
              ? `${thangLamViec.thang - 1}/${thangLamViec.nam}`
              : ""
          } ?`}
          title={"Hủy chuyển tháng"}
          type={HUY_CHUYEN_THANG_LAMVIEC}
          setFetchThangLamViec={setFetchThangLamViec}
          setKiemTraHuy={setKiemTraHuy}
          setDisplayMessHuy={setDisplayMessHuy}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleHuy]
  );

  useEffect(() => {
    if (KiemTraThangLamViec?.length > 0) {
      setVisibleTable(true);
      setDisplayMess(false);
    }
  }, [KiemTraThangLamViec?.length]);

  useEffect(() => {
    if (KiemTraHuy?.length > 0) {
      setVisibleTableHuy(true);
      setDisplayMessHuy(false);
    }
  }, [KiemTraHuy?.length]);

  useEffect(() => {
    getThangLamViec(setThangLamViec, setLoading);
  }, []);

  useEffect(() => {
    if (fetchThangLamViec) {
      getThangLamViec(setThangLamViec, setLoading);
      setFetchThangLamViec(!fetchThangLamViec);
    }
  }, [fetchThangLamViec]);
  const title = "Các đơn vị chưa hoàn thành ký duyệt bảng kê";
  const title2 = `Các đơn vị đã có dữ liệu biến động ở tháng làm việc ${
    thangLamViec && Object.keys(thangLamViec).length > 0
      ? `${thangLamViec.thang}/${thangLamViec.nam}`
      : ""
  }`;
  return (
    <Fragment>
      <LoadingComponent loading={loading}>
        <div
          style={{
            marginTop: 12,
            padding: "30px 233px",
          }}
        >
          <div
            style={{
              border: "1px solid #e9e5e5",
              borderRadius: 4,
            }}
          >
            <div
              style={{
                fontSize: 16,
                fontWeight: 600,
                borderBottom: "1px solid #e9e5e5",
                padding: "5px 0px 5px 10px",
              }}
            >
              Chuyển tháng làm việc
            </div>
            <Row
              gutter={24}
              style={{
                justifyContent: "center",
              }}
            >
              <Form.Item>
                <div
                  type="danger"
                  style={{
                    display: displayMess ? "none" : "block",
                    color: "red",
                    paddingRight: 100,
                  }}
                >
                  Chưa thể chuyển tháng làm việc do có đơn vị chưa hoàn thành ký
                  duyệt bảng kê !
                </div>
                <div
                  type="danger"
                  style={{
                    display: displayMessHuy ? "none" : "block",
                    color: "red",
                  }}
                >
                  Hệ thống không đủ điều kiện hủy chuyển tháng làm việc.
                  <br></br>
                  Điều kiện để thực hiện hủy chuyển tháng làm việc là chưa có
                  các biến động về chỉ số, điểm đo,
                  <br></br>
                  {`thông tin treo tháo thiết bị đo đếm, thông tin phương thức
                  giao nhận trong tháng làm việc ${
                    thangLamViec && Object.keys(thangLamViec).length > 0
                      ? `${thangLamViec.thang}/${thangLamViec.nam}`
                      : ""
                  }`}
                </div>
                <div
                  style={{
                    marginTop: 12,
                    // padding: "0px 325px"
                    textAlign: "center",
                  }}
                >
                  <div style={{ marginBottom: 20, paddingRight: 75 }}>
                    Tháng làm việc hiện tại: &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{ fontWeight: 600 }}>
                      {thangLamViec && Object.keys(thangLamViec).length > 0
                        ? `${thangLamViec.thang}/${thangLamViec.nam}`
                        : ""}
                    </span>
                  </div>
                  <div style={{ marginBottom: 30, paddingRight: 70 }}>
                    Tháng làm việc tiếp theo: &nbsp;&nbsp;&nbsp;
                    <span style={{ fontWeight: 600 }}>
                        {thangLamViec && Object.keys(thangLamViec).length > 0 && thangLamViec.thang !== 12
                  ? `${thangLamViec.thang + 1}/${thangLamViec.nam}`
                  : `01/${thangLamViec.nam + 1}`}
                    </span>
                  </div>
                  <CheckPermission permissionCode={giao_nhan_dien_nang.c75h}>
                    <Button
                      danger
                      type="primary"
                      loading={loadingHuy}
                      onClick={handleOpenModalHuy}
                      disabled={
                        KiemTraHuy?.length > 0 ||
                        KiemTraThangLamViec?.length > 0
                      }
                    >
                      Hủy chuyển tháng
                    </Button>
                  </CheckPermission>
                  <CheckPermission permissionCode={giao_nhan_dien_nang.c75c}>
                    <Button
                      className="ml-20px"
                      type="primary"
                      loading={loadingChuyen}
                      onClick={handleOpenModalDiemDoChuaNhap}
                      disabled={
                        KiemTraThangLamViec?.length > 0 || KiemTraHuy.length > 0
                      }
                    >
                      Chuyển tháng
                    </Button>
                  </CheckPermission>
                </div>
              </Form.Item>
            </Row>
            {visibleTable && <p style={{ paddingLeft: 20 }}>{title}</p>}
            {visibleTable && (
              <Table
                showHeader={true}
                columns={columns}
                bordered
                dataSource={KiemTraThangLamViec}
                scroll={{ y: 400 }}
                pagination={false}
              />
            )}
            {visibleTableHuy && <p style={{ paddingLeft: 18 }}>{title2}</p>}
            {visibleTableHuy && (
              <Table
                columns={columns}
                bordered
                dataSource={KiemTraHuy}
                scroll={{ y: 400 }}
                pagination={false}
              />
            )}
          </div>
        </div>
      </LoadingComponent>
      {showModalConfirm}
      {showModalHuy}
      {showModalDiemDoChuaNhap}
    </Fragment>
  );
}
