import React, { useEffect, useState } from "react";
import { InputNumber } from "antd";
import { TYPE_INPUT } from "@utils/constants";
export default function InputFormatter(props) {
  const {
    type,
    decimals,
    decimalSeparator,
    thousandsSeparator, // hàng nghìn
    value,
  } = props;
  const [state, setState] = useState({
    formatter: null,
    parser: null,
    controls: false,
  });

  // eslint-disable-next-line no-unused-vars
  const format = (
    number,
    decimals = 2,
    decimalSeparator = ".",
    thousandsSeparator = ","
  ) => {
    const roundedNumber = number.toFixed(decimals);
    let integerPart = "",
      fractionalPart = "";
    if (decimals === 0) {
      integerPart = roundedNumber;
      decimalSeparator = "";
    } else {
      const numberParts = roundedNumber.split(".");
      integerPart = numberParts[0];
      fractionalPart = numberParts[1];
    }
    integerPart = integerPart.replace(
      /(\d)(?=(\d{3})+(?!\d))/g,
      `$1${thousandsSeparator}`
    );
    return `${integerPart}${decimalSeparator}${fractionalPart}`;
  };

  useEffect(() => {
    if (type === TYPE_INPUT.PERCENT) {
      setState({
        formatter: (value) => `${value} %`,
        parser: (value) => value.replace("%", ""),
        controls: true,
      });
    }
    if (type === TYPE_INPUT.WATTAGE) {
      setState({
        formatter: (value) =>
          value
            ? `${value}`
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                .replace(/\.(?=\d{0,2}$)/g, ",")
            : 0,
        parser: (value) =>
          value
            ? Number.parseFloat(
                value.replace(/\$\s?|(\.*)/g, "").replace(/(,{1})/g, ".")
              ).toFixed(2)
            : 0,
      });
    }
    // if (type === TYPE_INPUT.DECIMAL) {
    //   setState({
    //     formatter: (value) =>
    //       format(Number(value), decimals, decimalSeparator, thousandsSeparator),
    //     parser: null,
    //   });
    // }
    if (type === TYPE_INPUT.TWO_DECIMAL) {
      setState({
        formatter: (value) => Number(`${value}`).toFixed(2),
        parser: null,
      });
    }
    if (type === TYPE_INPUT.VND) {
      setState({
        formatter: (value) =>
          `${value}`.replace(/\./g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        parser: (value) => value.replace(/VND\s?|(,*)/g, ""),
        controls: false,
      });
    }
    if (type === TYPE_INPUT.INTEGER) {
      setState({
        formatter: (value) => `${value}`.replace(/\./g, ""),
        parser: (value) => value.replace(/VND\s?|(,*)/g, ""),
        controls: false,
      });
    }
    if (type === TYPE_INPUT.DECIMAL) {
      const regex = new RegExp(`(\\.\\d{${decimals}})\\d+`, "g");
      setState({
        formatter: (value) =>
          `${value}`.replace(regex, "$1").replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        parser: null,
        controls: false,
      });
    }
  }, [decimalSeparator, decimals, thousandsSeparator, type, value]);
  return (
    <InputNumber
      {...props}
      formatter={state.formatter}
      parser={state.parser}
      controls={state.controls}
    />
  );
}
