import CheckPermission from "@components/CheckPermission";
import { cap_dien } from "@permissions/a_capdien";
import { Menu } from "antd";

export default function ListMenu(props) {
  const { handleOpenModalDongBo } = props;

  return (
    <Menu mode="vertical" style={{ width: "100%" }} selectedKeys={["0"]}>
      <CheckPermission permissionCode={cap_dien.a404_dong_bo_tg}>
        <Menu.Item eventKey="1" onClick={handleOpenModalDongBo}>
          Đồng bộ thời gian
        </Menu.Item>
      </CheckPermission>
    </Menu>
  );
}
