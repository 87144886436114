import { TYPE_LOAIYEUCAU_HOSO } from "@utils/constants";
import { Collapse } from "antd";
import { Fragment } from "react";
import ThongTin from "./thong-tin";
const { Panel } = Collapse;

export default function Index(props) {
  const { detail, isThaoCongTo, isLapTrinhLaiCongTo, maLoaiYeuCau } = props;

  return (
    <Collapse defaultActiveKey={["1", "2"]}>
      {maLoaiYeuCau !== TYPE_LOAIYEUCAU_HOSO.CAPDIEN ? (
        <Fragment>
          {detail?.isCapMoi || detail?.congToThao === null ? null : (
            <Panel header="Công tơ hiện tại" key="1">
              <ThongTin
                detail={detail?.congToThao}
                isThaoCongTo={isThaoCongTo === null ? false : isThaoCongTo}
              />
            </Panel>
          )}
        </Fragment>
      ) : null}

      {detail?.congToTreo === null ? null : (
        <Panel header="Công tơ treo" key="2">
          <ThongTin
            detail={detail?.congToTreo}
            isLapTrinhLaiCongTo={
              isLapTrinhLaiCongTo === null ? false : isLapTrinhLaiCongTo
            }
          />
        </Panel>
      )}
    </Collapse>
  );
}
