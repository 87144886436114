import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";
import {
  buildQueryString,
  parseParams,
  alertMessage,
} from "@utils/function";
import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";

// get list data phòng kinh doanh ký bảng kê
export const handleListDataPhongKDKBK = (
  setLoading,
  location,
  setDataPhongKDKBK,
  setTempDataPhongKDKBK,
  setTotalCount,
  setTempTotalCount,
  filterConditions,
  setSelectedRowKeys
) => {
  let url = "";
  url = `${Endpoint.LIST_PHONG_KINH_DOANH_KBK}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
    ? parseParams(location.search)
    : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      setSelectedRowKeys([]);
      if (res.data !== null) {
        setDataPhongKDKBK(res.data);
        setTempDataPhongKDKBK(res.data);
        setTotalCount(res.paging.totalCount);
        setTempTotalCount(res.paging.totalCount);
      } else setDataPhongKDKBK([]);
    },
  });
};

export const submitPhongKDXacNhan = (
  t,
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
) => {
  authPostData({
    url: Endpoint.PHONG_KD_XAC_NHAN,
    method: METHOD_PUT,
    payload: {
      id: selectedRowKeys,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const submitPhongKDKyHSM = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.GCS_PKD_KY_HSM,
    method: METHOD_PUT,
    payload: {
      id: selectedRowKeys,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
      }
    },
  });
};

//phòng kinh doanh trả lại bảng kê
export const handlePhongKDTraLaiBK = (
  id,
  values,
  setLoading,
  setFetchDataTable,
  closeModal,
  t,
) => {
  authPostData({
    url: Endpoint.PHONG_KD_TRA_LAI_BK,
    method: METHOD_PUT,
    payload: {
      id: id,
      lyDoTraLai: values.lyDoTraLai
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        closeModal();
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};