import { Col, DatePicker, Row, Select } from "antd";
import ExportExcel from "../common/xuat-excel";
import {
  FORMAT_DATE,
  // FORMAT_DATE,
  FORMAT_ENGLISH,
  FORMAT_MONTH,
  MONTH_STRING,
  TYPE_CHANGE_DATE,
} from "@utils/constants";
import moment from "moment";
import { Container, FullCol } from "./css-styled";
import { Endpoint } from "@utils/endpoint";
import { cap_dien } from "@permissions/a_capdien";
import LoadingComponent from "@components/Loading";
import { removeAccents } from "@utils/function";

const { Option } = Select;

export default function BaoCaoTongHopTTKH(props) {
  const {
    user,
    donVi,
    loading,
    handleChangeNgay,
    handleChangeUnit,
    tuNgay,
    denNgay,
    thangKT,
    unit,
    //maDonVi,
  } = props;
  const ngayDauThang = moment(tuNgay).format(FORMAT_ENGLISH);
  const ngayCuoiThang = moment(denNgay).format(FORMAT_ENGLISH);
  return (
    <LoadingComponent loading={loading}>
      <Container>
        <Row gutter={24}>
          <Col span={24} md={8}>
            <div>
              <label>Đơn vị: </label>
              <FullCol>
                {!user.isTongCongTy ? (
                  <Select defaultValue={user.unitId} disabled>
                    <Option value={user.unitId}>{user.unitName}</Option>
                  </Select>
                ) : (
                  <Select
                    defaultValue={user.unitId}
                    onChange={handleChangeUnit}
                    showSearch
                    filterOption={(input, option) => {
                      if (option && option.children) {
                        return (
                          removeAccents(option.children)
                            .toLowerCase()
                            .indexOf(removeAccents(input).toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {donVi && donVi.length > 0
                      ? donVi.map((item, index) => (
                          <Option key={index} value={item.id}>
                            {item.tenDonVi}
                          </Option>
                        ))
                      : null}
                  </Select>
                )}
              </FullCol>
            </div>
          </Col>
          <Col span={24} md={5}>
            <label>Từ tháng: </label>
            <FullCol>
              <DatePicker
                defaultValue={moment(tuNgay)}
                format={FORMAT_DATE}
                // picker="month"
                allowClear={false}
                onChange={(date) =>
                  handleChangeNgay(date, TYPE_CHANGE_DATE.TUNGAY)
                }
              />
            </FullCol>
          </Col>
          <Col span={24} md={5}>
            <label>Đến tháng: </label>
            <FullCol>
              <DatePicker
                defaultValue={moment(denNgay)}
                format={FORMAT_DATE}
                // picker="month"
                allowClear={false}
                onChange={(date) =>
                  handleChangeNgay(date, TYPE_CHANGE_DATE.DENNGAY)
                }
              />
            </FullCol>
          </Col>
          <Col span={24} md={4}>
            <label>Tháng kiểm tra: </label>
            <FullCol>
              <DatePicker
                defaultValue={moment(thangKT)}
                format={FORMAT_DATE}
                // picker="month"
                allowClear={false}
                onChange={(date) =>
                  handleChangeNgay(date, TYPE_CHANGE_DATE.THANGKIEMTRA)
                }
              />
            </FullCol>
          </Col>
          {/* <Col
            span={24}
            md={2}
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "start",
            }}
          >
            <a
              className="ant-btn ant-btn-primary mr-24px"
              href={`${
                Endpoint.EXPORT_EXCEL_BAO_CAO_DANH_SACH_KH_THEO_LO +
                "?thangnamtu=" +
                ngayBD +
                "&thangnamden=" +
                ngayKT +
                "&thangnam=" +
                thangBD +
                "&ma_dvi=" +
                maDonVi
              }`}
              target="_blank"
              rel="noreferrer"
            >
              Xuất Excel
            </a>
          </Col> */}
          <Col
            span={24}
            md={2}
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "start",
            }}
          >
            <ExportExcel
              endpoint={
                Endpoint.EXPORT_EXCEL_BAO_CAO_DANH_SACH_KH +
                "?donViId=" +
                unit +
                "&tuThang=" +
                ngayDauThang +
                "&denThang=" +
                ngayCuoiThang +
                "&thangKiemTra=" +
                thangKT
              }
              permissionCode={cap_dien.a550_xuat}
              text={"Xuất Excel"}
              key={"xuat-excel"}
            />
          </Col>
        </Row>
      </Container>
    </LoadingComponent>
  );
}
