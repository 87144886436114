import React from "react";

function PencilIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#279BEF"
        d="M3.323 14.355c.045 0 .09-.004.134-.011l3.755-.659a.219.219 0 00.118-.062l9.462-9.462a.222.222 0 000-.315L13.082.134a.22.22 0 00-.158-.065.22.22 0 00-.159.065L3.303 9.596a.227.227 0 00-.062.118l-.659 3.755a.748.748 0 00.21.665.756.756 0 00.531.221zm1.505-3.893l8.096-8.094 1.636 1.637-8.096 8.093-1.984.35.348-1.986zm12.386 5.768H.785a.714.714 0 00-.714.714v.804c0 .098.08.178.179.178h17.5c.098 0 .178-.08.178-.178v-.804a.714.714 0 00-.714-.714z"
      ></path>
    </svg>
  );
}

export default PencilIcon;
