import { authSelector } from "@state/auth";
import { Col, Form, Input, Row, Select, Tooltip, Button, Checkbox } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormComponent from "@components/Form";
import DatePickerComponent from "@components/DatePicker";
import moment from "moment";
import {
  ENUM_MUCDICH_SDD,
  FORMAT_DATE,
  FORMAT_ENGLISH,
  MONTH_STRING,
  AP_GIA_TRANG_THAI,
  AP_GIA_KTGS,
} from "@utils/constants";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import {
  handleListDataNN,
  handleListDataTram,
  getListDonViService,
} from "./services";
import { removeAccents } from "@utils/function";
import Selection from "@components/Select";
import { Endpoint } from "@utils/endpoint";
const { Option } = Select;

export function ListFilter(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    tooltip,
    checkTraLai,
    screen,
    // traLai
  } = props;
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  const [dataNhomNN, setDataNhomNN] = useState([]);
  const [dataDonVi, setDataDonVi] = useState([]);
  const [dataNhomTram, setDataNhomTram] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);

  useEffect(() => {
    handleListDataNN(setLoading, setDataNhomNN);
  }, []);

  useEffect(() => {
    getListDonViService(setLoading, setDataDonVi);
  }, []);

  useEffect(() => {
    handleListDataTram(setLoading, setDataNhomTram, user.unitId);
  }, [user.unitId]);

  const handleChangeDonVi = useCallback((values) => {
    handleListDataTram(setLoading, setDataNhomTram, values);
  }, []);

  useEffect(() => {
    let object = {};
    if (!!filterConditions.Tram && !Array.isArray(filterConditions.Tram)) {
      object = {
        ...filterConditions,
        Tram: filterConditions.Tram.split(","),
      };
    } else {
      if (Array.isArray(filterConditions.Tram)) {
        object = {
          ...filterConditions,
          Tram: filterConditions.Tram,
        };
      } else {
        object = {
          ...filterConditions,
        };
      }
    }
    form.setFieldsValue(object);
  }, [form, filterConditions]);

  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        DonViId: user.unitId.toString(),
      }}
    >
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="DonViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Select onChange={handleChangeDonVi} showSearch
                filterOption={(input, option) => {
                  if (option && option.children) {
                    return (
                      removeAccents(option.children)
                        .toLowerCase()
                        .indexOf(removeAccents(input).toLowerCase()) >= 0
                    );
                  }
                }}>
                {dataDonVi && dataDonVi.length > 0
                  ? dataDonVi.map((item, index) => (
                    <Option key={index} value={item.value}>
                      {item.name}
                    </Option>
                  ))
                  : null}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.DonViId !== currentValues.DonViId) {
                form.setFieldsValue({
                  DoiId: undefined,
                });
              }
              return prevValues.DonViId !== currentValues.DonViId;
            }}
          >
            {({ getFieldValue }) => {
              if (getFieldValue("DonViId")) {
                return (
                  <Form.Item name="DoiId" label="Tổ đội">
                    <Selection
                      url={`${Endpoint.GET_TEAM_LIST}?donviId=${getFieldValue(
                        "DonViId"
                      )}`}
                      formKey="DoiId"
                      form={form}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="DoiId" label="Tổ đội">
                  <Selection
                    url={`${Endpoint.GET_TEAM_LIST}?donviId=${user.unitId}`}
                    formKey="DoiId"
                    form={form}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            name="Tram"
            label="Trạm"
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.DonViId !== currentValues.DonViId) {
                form.setFieldsValue({
                  Tram: undefined,
                });
              }
              return prevValues.DonViId !== currentValues.DonViId;
            }}
          >
            <Select
              placeholder={"Tất cả"}
              allowClear
              loading={loading}
              showSearch
              mode="multiple"
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {dataNhomTram && dataNhomTram.length > 0
                ? dataNhomTram.map((item, index) => {
                  if (item.maTram !== null) {
                    return (
                      <Option key={index} value={item.maTram}>
                        {item.name}
                      </Option>
                    );
                  }
                  return null;
                })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="SoGCS" label="Sổ GCS">
            <Input suffix={<Tooltip title={tooltip}></Tooltip>} />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="NhomNganhNghe" label="Nhóm ngành nghề">
            <Select
              placeholder={"Tất cả"}
              allowClear
              loading={loading}
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {dataNhomNN && dataNhomNN.length > 0
                ? dataNhomNN.map((item, index) => {
                  if (item.maNhomNN !== null) {
                    return (
                      <Option key={index} value={item.maNhomNN}>
                        {item.tenNhomNN}
                      </Option>
                    );
                  }
                  return null;
                })
                : null}
            </Select>
          </Form.Item>
        </Col>
        {screen !== "NVKHCK" &&
          <Col span={24} md={6}>
            <Form.Item name="TrangThai" label="Trạng thái">
              {/* <Select placeholder={"Tất cả"} allowClear> */}
              {screen === "NVDPC" ? (
                <Select defaultValue={AP_GIA_TRANG_THAI.DA_PHAN_CONG}>
                  <Option value={AP_GIA_TRANG_THAI.DA_PHAN_CONG}>
                    Đã phân công
                  </Option>
                </Select>
              ) : screen === "NVDTH" ? (
                <Select defaultValue={AP_GIA_TRANG_THAI.DANG_THUC_HIEN}>
                  <Option value={AP_GIA_TRANG_THAI.DANG_THUC_HIEN}>
                    Đang thực hiện
                  </Option>
                </Select>
              ) : screen === "TNPC" ? (
                <Select defaultValue={AP_GIA_TRANG_THAI.DA_LAY_TU_WEB} allowClear placeholder="Tất cả">
                  <Option value={AP_GIA_TRANG_THAI.DA_LAY_TU_WEB}>
                    Đã tiếp nhận
                  </Option>
                  <Option value={AP_GIA_TRANG_THAI.PKD_DA_PHAN_CONG}>
                    Đã phân công cho đội
                  </Option>
                </Select>
              ) : screen === "NVDuyet" ? (
                <Select placeholder="Tất cả" allowClear defaultValue={AP_GIA_TRANG_THAI.MTB_DA_GUI_DL}>
                  <Option value={AP_GIA_TRANG_THAI.MTB_DA_GUI_DL}>
                    Chưa xác nhận
                  </Option>
                  <Option value={AP_GIA_TRANG_THAI.NVAG_DA_XAC_NHAN}>
                    Đã xác nhận
                  </Option>
                </Select>
              ) : screen === "BPAGD" ? (
                <Select placeholder="Tất cả" allowClear defaultValue={AP_GIA_TRANG_THAI.NVAG_DA_XAC_NHAN}>
                  <Option value={AP_GIA_TRANG_THAI.NVAG_DA_XAC_NHAN}>
                    Chưa Ký
                  </Option>
                  <Option value={AP_GIA_TRANG_THAI.BPAG_DA_XAC_NHAN}>
                    Đã Ký
                  </Option>
                </Select>
              ) : screen === "PKDD" ? (
                <Select placeholder="Tất cả" allowClear defaultValue={AP_GIA_TRANG_THAI.BPAG_DA_XAC_NHAN}>
                  <Option value={AP_GIA_TRANG_THAI.BPAG_DA_XAC_NHAN}>
                    Chưa Ký
                  </Option>
                  <Option value={AP_GIA_TRANG_THAI.PKD_DA_XAC_NHAN}>Đã Ký</Option>
                </Select>
              ) : screen === "LDD" ? (
                <Select placeholder="Tất cả" allowClear defaultValue={AP_GIA_TRANG_THAI.PKD_DA_XAC_NHAN}>
                  <Option value={AP_GIA_TRANG_THAI.PKD_DA_XAC_NHAN}>
                    Chưa Ký
                  </Option>
                  <Option value={AP_GIA_TRANG_THAI.LD_DA_XAC_NHAN}>Đã Ký</Option>
                </Select>
              ) : screen === "PC" ? (
                <Select placeholder="Tất cả" allowClear defaultValue={AP_GIA_TRANG_THAI.DA_LAY_TU_WEB}>
                  <Option value={AP_GIA_TRANG_THAI.DA_LAY_TU_WEB}>
                    Chưa phân công
                  </Option>
                  <Option value={AP_GIA_TRANG_THAI.DA_PHAN_CONG}>
                    Đã phân công
                  </Option>
                </Select>
              ) : screen === "KTGS" ? (
                <Select placeholder="Tất cả" allowClear defaultValue={AP_GIA_KTGS.CHUA_CHUYEN_KTGS}>
                  <Option value={AP_GIA_KTGS.CHUA_CHUYEN_KTGS}>
                    Chưa chuyển KTGS
                  </Option>
                  <Option value={AP_GIA_KTGS.DA_CHUYEN_KTGS}>
                    Đã chuyển KTGS
                  </Option>
                </Select>
              ) : screen === "DB" ? (
                <Select placeholder="Tất cả" allowClear defaultValue={AP_GIA_TRANG_THAI.LD_DA_XAC_NHAN}>
                  <Option value={AP_GIA_TRANG_THAI.LD_DA_XAC_NHAN}>
                    Chờ đồng bộ về CMIS
                  </Option>
                  <Option value={AP_GIA_TRANG_THAI.DA_DB_CMIS}>Đã đồng bộ về CMIS</Option>
                </Select>
              ) : (
                <Select placeholder="Tất cả" allowClear>
                  <Option value={AP_GIA_TRANG_THAI.DA_LAY_TU_WEB}>
                    Đã tiếp nhận
                  </Option>
                  <Option value={AP_GIA_TRANG_THAI.PKD_DA_PHAN_CONG}>
                    Đã phân công cho đội
                  </Option>
                  <Option value={AP_GIA_TRANG_THAI.DA_PHAN_CONG}>
                    Đã phân công cho NV
                  </Option>
                  <Option value={AP_GIA_TRANG_THAI.DANG_THUC_HIEN}>
                    Đã lấy về MTB
                  </Option>
                  <Option value={AP_GIA_TRANG_THAI.MTB_DA_GUI_DL}>
                    Chờ NV áp giá duyệt
                  </Option>
                  <Option value={AP_GIA_TRANG_THAI.NVAG_DA_XAC_NHAN}>
                    Chờ bộ phận áp giá duyệt
                  </Option>
                  <Option value={AP_GIA_TRANG_THAI.BPAG_DA_XAC_NHAN}>
                    Chờ PKD duyệt
                  </Option>
                  <Option value={AP_GIA_TRANG_THAI.PKD_DA_XAC_NHAN}>
                    Chờ lãnh đạo duyệt
                  </Option>
                  <Option value={AP_GIA_TRANG_THAI.LD_DA_XAC_NHAN}>
                    Chờ đồng bộ về CMIS
                  </Option>
                  <Option value={AP_GIA_TRANG_THAI.DA_DB_CMIS}>Đã đồng bộ về CMIS</Option>
                </Select>
              )}
            </Form.Item>
          </Col>}
        {!screen &&
          <>
            <Col span={24} md={4}>
              <Form.Item name="NgayBatDau" label="Ngày kiểm tra áp giá">
                <DatePickerComponent
                  defaultValue={
                    filterConditions.NgayBatDau
                      ? moment(filterConditions.NgayBatDau, FORMAT_ENGLISH)
                      : moment(moment().startOf(MONTH_STRING), FORMAT_DATE)
                  }
                  format={FORMAT_ENGLISH}
                  form={form}
                  formKey={"NgayBatDau"}
                  allowClear={true}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={4}>
              <Form.Item name="NgayKetThuc" label=" ">
                <DatePickerComponent
                  defaultValue={
                    filterConditions.NgayKetThuc
                      ? moment(filterConditions.NgayKetThuc, FORMAT_ENGLISH)
                      : moment(moment(), FORMAT_DATE)
                  }
                  format={FORMAT_ENGLISH}
                  form={form}
                  formKey={"NgayKetThuc"}
                  allowClear={true}
                />
              </Form.Item>
            </Col>
          </>
        }
        {/* <Col span={24} md={4}>
          <Form.Item name="ThangKiemTra" label="Tháng kiểm tra">
            <DatePickerComponent
              defaultValue={
                filterConditions.ThangKiemTra
                  ? moment(filterConditions.ThangKiemTra, FORMAT_MONTH_ENGLISH)
                  : moment(moment(), FORMAT_MONTH)
              }
              format={FORMAT_MONTH_ENGLISH}
              form={form}
              formKey={"ThangKiemTra"}
              picker="month"
              allowClear={true}
            />
          </Form.Item>
        </Col> */}
        <Col span={24} md={4}>
          <Form.Item name="LoaiKhachHang" label="Loại khách hàng">
            <Select
              // defaultValue={ENUM_MUCDICH_SDD.SINHHOAT}
              placeholder={"Tất cả"}
              allowClear
            >
              <Option value={ENUM_MUCDICH_SDD.SINHHOAT}>Sinh hoạt</Option>
              <Option value={ENUM_MUCDICH_SDD.NGOAISINHHOAT}>
                Ngoài sinh hoạt
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="SearchTerm" label="Từ khóa">
            <Input
              prefix={<SearchOutlined />}
              suffix={
                <Tooltip
                  title={
                    "Tìm kiếm theo mã điểm đo, mã KH, tên KH, số điện thoại, địa chỉ sử dụng điện"
                  }
                >
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
        {screen === "KTGS" ? null : (
          <Col
            span={24}
            md={3}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Form.Item name="BiTraLai" style={{ margin: 0 }}>
              <Checkbox
                onChange={checkTraLai}
                defaultChecked={filterConditions.BiTraLai ? true : false}
              // value={traLai}
              >
                Bị trả lại
              </Checkbox>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row justify="end">
        <Button className="mr-24px" onClick={clear}>
          Bỏ lọc
        </Button>
        <Button type="primary" htmlType="submit" form="filter-form">
          Tìm kiếm
        </Button>
      </Row>
    </FormComponent>
  );
}
