import { buildQueryString, parseParams } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import {
  authGetData,
  authGetMultiplesRequest,
  authPostData,
} from "@utils/request";
import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";

export const handleListData = (
  location,
  setLoading,
  setDataHoSo,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.LIST_TIEP_NHAN_YEU_CAU}?${buildQueryString(
      parseParams(location.search)
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataHoSo(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataHoSo([]);
    },
  });
};
// multi list

export const handleMultileListData = (
  setLoading,
  setLoaiYeuCau,
  setTrangThai,
  setMucDich
) => {
  const endpoints = [
    `${Endpoint.COMMON_LIST_LOAI_YEU_CAU}`,
    `${Endpoint.COMMON_LIST_TRANG_THAI}`,
    `${Endpoint.COMMON_LIST_MUC_DICH}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setLoaiYeuCau(res[0].data.data);
      setTrangThai(res[1].data.data);
      setMucDich(res[2].data.data);
    },
  });
};

export const submitDongBoService = (
  selectedRowKeys,
  setLoading,
  setFetchDataTable,
  getListHoSo
) => {
  const ids = {
    ids: selectedRowKeys.toString(),
  };
  authPostData({
    url: Endpoint.SYNC_TIEP_NHAN_YEU_CAU_THOIGIAN,
    payload: ids,
    method: METHOD_PUT,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setFetchDataTable(true);
        getListHoSo();
      }
    },
  });
};
