import { Button, Modal, Row, Form } from "antd";
import LoadingComponent from "@components/Loading";

export default function ModalReturn(props) {
  const { visible, closeModal, loading, form, onFinish } = props;
  return (
    <Modal
      title="Trả lại yêu cầu"
      visible={visible}
      onCancel={closeModal}
      className="modal-bpag-tra-lai"
      footer={null}
    >
      <LoadingComponent loading={loading}>
        <Form form={form} name="tra-lai" layout="vertical" onFinish={onFinish}>
          <Row gutter={24}>
            <p>Yêu cầu sẽ được trả về cho Đội trưởng !</p>
            {/* <span>Lý do trả lại:</span>
            <Col span={24}>
              <Form.Item
                name="lyDoTraLai"
                label="Lý do trả lại"
                rules={[
                  {
                    required: true,
                    message: "Lý do trả lại không được để trống",
                  },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
            </Col> */}
          </Row>
          <br />
          <Row gutter={24} justify="center">
            <Button
              key="back"
              onClick={closeModal}
              className="button-closed mr-24px"
            >
              Đóng lại
            </Button>
            <Button
              key="submit"
              type="primary"
              className="button-primary"
              htmlType="submit"
              form="tra-lai"
            >
              Trả lại
            </Button>
          </Row>
        </Form>
      </LoadingComponent>
    </Modal>
  );
}
