import { buildQueryString, parseParams, getErrorMessage, } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData, authDeleteData } from "@utils/request";
import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { alertMessage } from "@utils/function";

export const handleListDataDH = (
  location,
  filterConditions,
  setLoading,
  setDataPhanCongGCS,
  setTempDataPhanCongGCS,
  setTotalCount,
  setTempTotalCount,
) => {
  let url = "";
  url = `${Endpoint.LIST_PHAN_CONG_GCS_DH}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataPhanCongGCS(res.data);
        setTempDataPhanCongGCS(res.data);
        setTotalCount(res.paging.totalCount);
        setTempTotalCount(res.paging.totalCount);
      } else setDataPhanCongGCS([]);
    },
  });
};

export const handleListDataDT = (
  location,
  filterConditions,
  setLoading,
  setDataPhanCongGCS,
  setTempDataPhanCongGCS,
  setTotalCount,
  setTempTotalCount,
) => {
  let url = "";
  url = `${Endpoint.LIST_PHAN_CONG_GCS_DT}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataPhanCongGCS(res.data);
        setTempDataPhanCongGCS(res.data);
        setTotalCount(res.paging.totalCount);
        setTempTotalCount(res.paging.totalCount);
      } else setDataPhanCongGCS([]);
    },
  });
};

export const handleSubmitPhanCong = (
  doiId,
  userId,
  t,
  setLoading,
  handleCloseModalPC,
  setFetchDataTable,
  setSelectedRowKeys,
  selectedRowKeys,
  user,
  form

) => {
  authPostData({
    url: Endpoint.PHAN_CONG_GCS,
    method: METHOD_POST,
    payload: {
      doiId: doiId,
      userId: userId,
      maSoGCS: selectedRowKeys,
      donViId: user.unitId
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        handleCloseModalPC();
        setSelectedRowKeys([]);
      } else {
        getErrorMessage(res, form);
      }
    },
  });
};

export const handleHuyPhanCong = (id, maSo, setLoading, getListData, t) => {
  authDeleteData({
    url: `${Endpoint.HUY_PHAN_CONG_GCS}/${id}`,
    setLoading,
    content: `Bạn chắc chắn muốn hủy phân công sổ GCS ${maSo} ?`,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) getListData();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

export const handleHuyPhanCongNhieu = (
  selectedRowKeys,
  setLoading,
  setFetchDataTable,
  setSelectedRowKeys
) => {
  authPostData({
    url: Endpoint.GCS_HUY_PC_NHIEU,
    method: METHOD_PUT,
    payload: {
      ids: selectedRowKeys,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
      }
    },
  });
}

export const handleListDoi = (setLoading, setDataDoi, unitId) => {
  authGetData({
    url: `${Endpoint.GET_TEAM_LIST}?donviId=${unitId}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataDoi(res.data);
      }
      else setDataDoi(res.data);
    },
  });
};

export const handleListNVTheoDoi = (
  value,
  setLoadingTenNV,
  setDataNV,
  form
) => {
  authGetData({
    url: `${Endpoint.NHAN_VIEN_THEO_DOI}?&DoiId=${value}`,
    setLoading: setLoadingTenNV,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataNV(res.data);
        form.resetFields(["userId"]);
      } else setDataNV(res.data);
    },
  });
};

export const handleCheckPC = (
  selectedRowKeys,
  setLoading,
  setVisiblePC,
  t
) => {
  authPostData({
    url: Endpoint.CHECK_PHAN_CONG,
    method: METHOD_POST,
    payload: {
      id: selectedRowKeys,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setVisiblePC(true);
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const getListPostionService = (setLoading, user, setIsDT) => {
  authGetData({
    url: `${Endpoint.GET_POSITION_LIST}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        const result = res.data.filter(position => position.value === user.positionId);
        if (result[0]?.name === "Đội trưởng") {
          setIsDT(true)
        }
      } else
        setIsDT(false);
    },
  });
};