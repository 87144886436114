import styled from "styled-components";

export const Container = styled.div`
  margin-top: 12px;
  padding: 30px 80px;
`;

export const ViewModal = styled.div`
  margin-top: 12px;
  padding: 0px 325px;
`;

export const ContainerConfirm = styled.div`
  padding: 0 8px;
`;
