const i1 = {
  i1: "i1",
  i1v: "i1v",
  i1dt: "i1dt",
  i1dc: "i1dc",
  i1db: "i1db",
};
const i2 = {
  i2: "i2",
  i2v: "i2v",
  i2l: "i2L",
};
const i3 = {
  i3: "i3",
  i3v: "i3v",
  i3xe: "i3xe",
  i3xp: "i3xp"
};
const i4 = {
  i4: "i4",
  i4v: "i4v",
  i4t: "i4t",
  i4s: "i4s",
  i4x: "i4x"
};
const csct = {
  i: "i",
};

export const chi_so_cong_to = {
  ...csct,
  ...i1,
  ...i2,
  ...i3,
  ...i4
};
