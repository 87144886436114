import { authDeleteData, authGetData, authPostData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import { ENUM_FUNCTION, METHOD_POST, STATUSCODE_200 } from "@utils/constants";
import { alertMessage } from "@utils/function";
import { saveDongBoImageScan } from "@state/cap-dien/tai-anh-scan/reducer";
export const handleDetailHoSo = (detail, setLoading, setDataDetail) => {
  authGetData({
    url: Endpoint.DETAIL_HO_SO + "?HoSoId=" + detail.id,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null && Array.isArray(res.data)) {
        if (res.data.length > 0) {
          setDataDetail(res.data[0]);
        }
      } else setDataDetail({});
    },
  });
};

export const handleUploadImage = (
  values,
  imageFiles,
  detail,
  setLoading,
  setDataDetail,
  setFetchDataTable,
  setImageFiles,
  firstData,
  setDataImage,
  setIsSubmit,
  dispatch,
  funcID
) => {
  const loaiGiayToId = firstData;
  const data = {
    ...values,
  };
  data.hoSoId = detail.id;
  data.loaiHoSoId = data.loaiGiayToId;

  const images = [];
  imageFiles.map((item) => {
    return images.push({
      base64Data: item.base64Data,
      ghiChu: "",
      fileName: item.name,
    });
  });
  data.images = images;

  delete data.anh;
  delete data.loaiGiayToId;
  authPostData({
    url: Endpoint.UPLOAD_ANH_HIEN_TRUONG,
    payload: data,
    method: METHOD_POST,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setImageFiles([]);
        setIsSubmit(true);
        setFetchDataTable(true);
        handleDetailHoSo(detail, setLoading, setDataDetail);

        // handleListImage(detail, setLoading, setDataImage);
        if (funcID === ENUM_FUNCTION.TRACUUHOSO) {
          dispatch(saveDongBoImageScan(false));
        }
      } else if (res.statusCode === 422) {
        const message = JSON.parse(res.message);
        alertMessage("error", "Thông báo!", message[0].value);
      }
    },
  });
};

export const handleListLoaiGT = (setLoading, setDataLoaiGT, setFistData) => {
  authGetData({
    url: Endpoint.LIST_LOAI_GIAY_TO + "?pageSize=-1",
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataLoaiGT(res.data);
        setFistData(res.data[0].id);
      } else setDataLoaiGT([]);
    },
  });
};

export const handleListImage = (
  detail,
  setLoading,
  setDataImage,
  setListId
) => {
  authGetData({
    url: Endpoint.LIST_ANH_HIEN_TRUONG + "?hoSoId=" + detail.id,
    // "&loaiHoSoId=" +
    // loaiGiayToId
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null && res.data.length > 0) {
        const arr = [];
        const arrId = [];
        res.data.map((item, index) => {
          arr.push(res.data[index].images);
          arrId.push({
            loaiGiayToId: res.data[index].loaiGiayToId,
            tenLoaiGiayTo: res.data[index].tenLoaiGiayTo,
          });
        });
        setDataImage(arr);
        setListId(arrId);
      } else setDataImage([]);
    },
  });
};

export const handleRemoveImage = (
  setLoading,
  imageId,
  setReloadListImage,
  setFetchDataTable
) => {
  authDeleteData({
    url: Endpoint.REMOVE_ANH_HIEN_TRUONG + "?Id=" + imageId,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setReloadListImage(true);
      }
      setFetchDataTable(true);
    },
  });
};

export const handleSyncListImage = (
  setLoading,
  detail,
  setDataDetail,
  setReloadListImage
) => {
  authPostData({
    url: `${Endpoint.SYNC_ANH_HIEN_TRUONG}?HoSoId=${
      detail?.hoSoId ?? detail?.id
    }`,
    method: METHOD_POST,
    setLoading,
    payload: {
      hoSoId: detail?.hoSoId ?? detail?.id,
    },
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        //do nothing
        handleDetailHoSo(detail, setLoading, setDataDetail);
        setReloadListImage(true);
      }
    },
  });
};
