import { authPostData, authGetData } from "@utils/request";
import { STATUSCODE_200, METHOD_PUT } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";

export const submitInHoSoCMISService = (setLoading, handleCloseModal) => {
  authPostData({
    url: "",
    method: METHOD_PUT,
    payload: {},
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        handleCloseModal();
      }
    },
  });
};

export const handleList = (hoSoId, setLoading, setData) => {
  authGetData({
    url: Endpoint.GET_LIST_IN_BIEN_BAN_CMIS + "?HoSoId=" + hoSoId,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setData(res.data);
      }
    },
  });
};

export const handleFileTaiLieu = (id, setLoading, setGetFileData, maLoaiHoSo) => {
  authGetData({
    url: Endpoint.GET_FILE_TAI_LIEU_HO_SO + "?HoSoId=" + id + `&MaLoaiHoSo=${maLoaiHoSo}`,
    setLoading,
    onSuccess: (res) => {
      if (res && res.data !== null) {
        setGetFileData(res.data);
      } else {
        setGetFileData({});
      }
    },
  });
};

export const handleAnhHienTruong = (id, setLoading, onComplete, maLoaiHoSo) => {
  authGetData({
    url: Endpoint.GET_ANH_HIEN_TRUONG_HO_SO + "?HoSoId=" + id + `&MaLoaiHoSo=${maLoaiHoSo}`,
    setLoading,
    onSuccess: (res) => {
      if (res && res.data !== null) {
        onComplete(res.data);
      } else {
        onComplete([]);
      }
    },
  });
};
