import { Button, Col, Modal, Row } from "antd";
import React from "react";
function CapNhatModal(props) {
  const { visible, handleCloseModal, handleSubmit, loading } = props;

  return (
    <Modal
      title="Yêu cầu của bạn đang ở Bộ phận Giao dịch viên."
      visible={visible}
      onCancel={handleCloseModal}
      footer={null}
    >
      <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
        Yêu cầu của bạn chọn sẽ được cập nhật. Bạn chắc chắn đồng ý?
      </h4>
      <Row gutter={24} justify="center" style={{ marginTop: "20px" }}>
        <Col span={12} style={{ textAlign: "right" }}>
          <Button
            key="back"
            onClick={handleCloseModal}
            className="button-closed"
          >
            Đóng lại
          </Button>
        </Col>
        <Col span={12}>
          <Button
            key="submit"
            type="primary"
            className="button-primary"
            htmlType="submit"
            onClick={handleSubmit}
            loading={loading}
          >
            Cập nhật
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

export default CapNhatModal;
