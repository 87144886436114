import { Fragment } from "react";
import { Form, Button, Modal, Row, Col, Select, DatePicker } from "antd";
import FormComponent from "@components/Form";
import "moment/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import moment from "moment";
import { FORMAT_DATE } from "@utils/constants";
import LoadingComponent from "@components/Loading";
import { Container } from "../css-styled";
const { Option } = Select;

export default function Index(props) {
  const {
    loading,
    typePhanCong,
    visible,
    handleCloseModal,
    form,
    handleSubmitData,
    ngayHen,
    tenDonVi,
    handleDoi,
    dataDoi,
    dataNguoiKhaoSat,
    onChangeNgayHen,
    t,
  } = props;
  return (
    <Fragment>
      <Modal
        title={
          typePhanCong === 1
            ? t("giao_dich_vien.quan_ly_ho_so_co_tro_ngai.phan_cong_lai_ho_so")
            : t("giao_dich_vien.ho_so_da_phan_cong.phan_cong")
        }
        visible={visible}
        onCancel={handleCloseModal}
        className="modal-phan-cong"
        footer={null}
      >
        <FormComponent
          form={form}
          name="form-phan-cong"
          layout="vertical"
          onFinish={handleSubmitData}
          initialValues={{
            ngayHen: moment(ngayHen, FORMAT_DATE),
          }}
          autoComplete="off"
        >
          <LoadingComponent loading={loading}>
            <Container>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="hoSoId"
                    className="display-error-message"
                  ></Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    <span>{t("uy_quyen.title.ma_don_vi_quan_ly")}</span>
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item>
                    <span className="font-bold">{tenDonVi}</span>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label={t("giao_dich_vien.ho_so_da_phan_cong.doi")}
                name="doiId"
                className="required-field"
              >
                <Select
                  loading={loading}
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handleDoi}
                >
                  {dataDoi &&
                    dataDoi.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.tenDoi}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("giao_dich_vien.ho_so_da_phan_cong.nguoi_khao_sat")}
                name="nguoiKSId"
                className="required-field"
              >
                <Select
                  loading={loading}
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={() => false}
                >
                  {dataNguoiKhaoSat &&
                    dataNguoiKhaoSat.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.nguoiKhaoSat}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("giao_dich_vien.ho_so_da_phan_cong.ngay_hen_khao_sat")}
                name="ngayHen"
                className="required-field"
              >
                <DatePicker
                  locale={locale}
                  format={FORMAT_DATE}
                  allowClear={false}
                  disabledDate={(current) => {
                    const customDate = moment().format("DD/MM/YYYY");
                    return (
                      current && current < moment(customDate, "DD/MM/YYYY")
                    );
                  }}
                  onChange={onChangeNgayHen}
                />
              </Form.Item>
              <Row gutter={24} justify="center">
                <Col span={12} style={{ textAlign: "right" }}>
                  <Button
                    key="back"
                    onClick={handleCloseModal}
                    className="button-closed"
                  >
                    {t("button.dong_lai")}
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    key="submit"
                    type="primary"
                    className="button-primary"
                    htmlType="submit"
                    form="form-phan-cong"
                    loading={loading}
                  >
                    {typePhanCong === 1
                      ? t(
                          "giao_dich_vien.quan_ly_ho_so_co_tro_ngai.phan_cong_lai"
                        )
                      : t("giao_dich_vien.ho_so_da_phan_cong.phan_cong")}
                  </Button>
                </Col>
              </Row>
            </Container>
          </LoadingComponent>
        </FormComponent>
      </Modal>
    </Fragment>
  );
}
