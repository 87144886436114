import { cap_dien } from "@permissions/a_capdien";
import CheckStatus from "@components/CheckStatus";
import {
  LITMIT_TRANGTHAI,
  MATRIX_CAP_DIEN,
  SEQUENCE_TRANGTHAI,
} from "@utils/constants";
import { Menu } from "antd";
import CheckPermission from "@components/CheckPermission";
import XemLaiBBLapDatComponent from "@modules/cap-dien/common-ho-so/xem-bien-ban-lap-dat";

const { SubMenu } = Menu;

export default function ListMenu(props) {
  const {
    handleOpenModalKhaoSat,
    handleOpenInHoSoCMIS,
    handleOpenXemLaiPACD,
    handleOpenQuyetToanKH,
    handleOpenQuyetToanCT,
    handleOpenInBieuKG,
    inBienBanApGia,
    t,
    detail,
    handleOpenInPhuTai,
  } = props;
  return (
    <Menu mode="vertical" style={{ width: "100%" }} selectedKeys={["0"]}>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.KHAOSATLAI}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.HOSOCOTRONGAI[1]}
        permissionCode={cap_dien.a34_khao_sat_lai}
      >
        <Menu.Item eventKey="2" onClick={handleOpenModalKhaoSat}>
          Khảo sát lại
        </Menu.Item>
      </CheckStatus>

      <CheckPermission permissionCode={cap_dien.a34_in_hs_cmis}>
        <Menu.Item eventKey="3" onClick={handleOpenInHoSoCMIS}>
          {t("common_cap_dien.in_ho_so_cmis")}
        </Menu.Item>
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a34_in_hs_web}>
        {Number(detail.maTrangThai) >= LITMIT_TRANGTHAI ? (
          <SubMenu
            eventKey="inHoSoWeb"
            title={t("common_cap_dien.in_ho_so_web")}
          >
            <Menu.Item eventKey="4" onClick={handleOpenXemLaiPACD}>
              {t("common_cap_dien.xem_lai_phuong_an_cap_dien")}
            </Menu.Item>
            <XemLaiBBLapDatComponent idHoSo={detail?.id ?? detail?.hoSoId} />
            <Menu.Item eventKey="20" onClick={handleOpenInPhuTai}>
              In biểu đồ phụ tải
            </Menu.Item>
            <Menu.Item eventKey="5" onClick={handleOpenQuyetToanKH}>
              {t("common_cap_dien.quyet_toan_khach_hang")}
            </Menu.Item>
            <Menu.Item eventKey="6" onClick={handleOpenQuyetToanCT}>
              {t("common_cap_dien.quyet_toan_cong_ty")}
            </Menu.Item>
            <Menu.Item eventKey="7" onClick={handleOpenInBieuKG}>
              {t("common_cap_dien.in_bieu_khai_gia")}
            </Menu.Item>
            <Menu.Item eventKey="8" onClick={inBienBanApGia}>
              {t("common_cap_dien.in_bien_ban_ap_gia")}
            </Menu.Item>
          </SubMenu>
        ) : null}
      </CheckPermission>
    </Menu>
  );
}
