import React, { useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/vi";
import { submitDongBoService } from "./services";
import DongBoYeuCauCMIS from "./modal-dong-bo-yc";
import { FORMAT_ENGLISH } from "@utils/constants";

export default function DongBoYeuCauCMISModal(props) {
  const { typeDongBo } = props;

  const [dataSync, setDataSync] = useState({});
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const toDay = moment().format(FORMAT_ENGLISH);
  const [ngay, setNgay] = useState(toDay);
  const [loading, setLoading] = useState(false);
  // gọi api khi submit

  const handleSubmit = (values) => {
    submitDongBoService(typeDongBo, values, setLoading, setDataSync);
  };

  // set lại ngày khi thay đổi
  const handleChangeNgay = (date) => {
    let convert = "";
    if (date === null) convert = undefined;
    else convert = moment(date).format(FORMAT_ENGLISH);
    setNgay(convert);
  };

  return (
    <DongBoYeuCauCMIS
      form={form}
      handleSubmit={handleSubmit}
      loading={loading}
      ngay={ngay}
      handleChangeNgay={handleChangeNgay}
      t={t}
      dataSync={dataSync}
      typeDongBo={typeDongBo}
    />
  );
}
