import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData } from "@utils/request";

export const getListDataB4_TH = (payload, setLoading, setDataB4TH) => {
  authGetData({
    url: `${Endpoint.B4_TH_DOI_SOAT}?${buildQueryString(parseParams(payload))}`,

    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setDataB4TH(res.data);
      }
    },
  });
};
