import { Menu } from "antd";
import { openModal, closeModal } from "@state/system-config/reducer";
import {
  LITMIT_TRANGTHAI,
  MATRIX_CAP_DIEN,
  SEQUENCE_TRANGTHAI,
} from "@utils/constants";
import CheckStatus from "@components/CheckStatus";
import { checkPermissionViewImage } from "../../ap-gia/duyet-ho-so/services";
import { cap_dien } from "@permissions/a_capdien";
import CheckPermission from "@components/CheckPermission";
import XemLaiBBLapDatComponent from "@modules/cap-dien/common-ho-so/xem-bien-ban-lap-dat";

const { SubMenu } = Menu;

export default function ListMenu(props) {
  const {
    t,
    dispatch,
    detail,
    handleOpenModalXacNhanNghiemThu,
    handleOpenModalTraDoiThiCong,
    handleOpenModalInHoSoCMIS,
    handleOpenModalXemLaiPhuongAnCapDien,
    handleOpenModalQuyetToanKhachHang,
    handleOpenModalQuyetToanCongTy,
    handleOpenModalInBieuKhaiGia,
    inBienBanApGia,
    setLoading,
    handleOpenInPhuTai,
    setVisibleInBBLD,
  } = props;
  return (
    <Menu mode="vertical" style={{ width: "100%" }} selectedKeys={["0"]}>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.TAIANH}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.NGHIEMTHUHOSO[1]}
        permissionCode={cap_dien.a201_tai_anh_hskh}
      >
        <Menu.Item
          eventKey="1"
          onClick={() => {
            checkPermissionViewImage(
              detail,
              setLoading,
              dispatch,
              openModal,
              closeModal
            );
          }}
        >
          {t("common_cap_dien.tai_anh_ho_so_khach_hang")}
        </Menu.Item>
      </CheckStatus>
      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.XACNHANNGHIEMTHU}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.NGHIEMTHUHOSO[1]}
        permissionCode={cap_dien.a201xn}
      >
        <Menu.Item eventKey="2" onClick={handleOpenModalXacNhanNghiemThu}>
          {t("nghiem_thu.xac_nhan_nghiem_thu")}
        </Menu.Item>
      </CheckStatus>

      <CheckStatus
        sequenceNumberTrangThai={SEQUENCE_TRANGTHAI.TRADOITHICONG}
        maTrangThai={detail?.maTrangThai}
        matrix={MATRIX_CAP_DIEN.NGHIEMTHUHOSO[1]}
        permissionCode={cap_dien.a201_tra_doi_tc}
      >
        <Menu.Item eventKey="3" onClick={handleOpenModalTraDoiThiCong}>
          {t("nghiem_thu.tra_doi_thi_cong")}
        </Menu.Item>
      </CheckStatus>
      <CheckPermission permissionCode={cap_dien.a201_in_hs_cmis}>
        <Menu.Item eventKey="4" onClick={handleOpenModalInHoSoCMIS}>
          {t("common_cap_dien.in_ho_so_cmis")}
        </Menu.Item>
      </CheckPermission>
      <CheckPermission permissionCode={cap_dien.a201_in_hs_web}>
        {Number(detail.maTrangThai) >= LITMIT_TRANGTHAI ? (
          <SubMenu eventKey="sub1" title={t("common_cap_dien.in_ho_so_web")}>
            <Menu.Item
              eventKey="5"
              onClick={handleOpenModalXemLaiPhuongAnCapDien}
            >
              {t("common_cap_dien.xem_lai_phuong_an_cap_dien")}
            </Menu.Item>
            <XemLaiBBLapDatComponent idHoSo={detail?.id ?? detail?.hoSoId} />
            <Menu.Item eventKey="20" onClick={handleOpenInPhuTai}>
              In biểu đồ phụ tải
            </Menu.Item>
            <Menu.Item eventKey="21" onClick={() => setVisibleInBBLD(true)}>
              In biên bản treo tháo
            </Menu.Item>
            <Menu.Item eventKey="6" onClick={handleOpenModalQuyetToanKhachHang}>
              {t("common_cap_dien.quyet_toan_khach_hang")}
            </Menu.Item>
            <Menu.Item eventKey="7" onClick={handleOpenModalQuyetToanCongTy}>
              {t("common_cap_dien.quyet_toan_cong_ty")}
            </Menu.Item>
            <Menu.Item eventKey="8" onClick={handleOpenModalInBieuKhaiGia}>
              {t("common_cap_dien.in_bieu_khai_gia")}
            </Menu.Item>
            <Menu.Item eventKey="9" onClick={inBienBanApGia}>
              {t("common_cap_dien.in_bien_ban_ap_gia")}
            </Menu.Item>
          </SubMenu>
        ) : null}
      </CheckPermission>
    </Menu>
  );
}
