import { Form } from "antd";
import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import PopupTaiAnh from "./popup-tai-anh";

export default function TaiAnhMoiTabs(props) {
  const { detail, loading, imageFiles, setImageFiles, isSubmit } = props;

  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [form] = Form.useForm();
  const [isUpload, setIsUpload] = useState(false);

  const fileToDataUri = (image) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { type, name, size } = image;
      reader.addEventListener("load", () => {
        res({
          base64Data: reader.result,
          name,
          type,
          size,
          upload: true,
        });
      });
      reader.readAsDataURL(image);
    });
  };

  return (
    <PopupTaiAnh
      form={form}
      t={t}
      setImageFiles={setImageFiles}
      imageFiles={imageFiles}
      inputRef={inputRef}
      detail={detail}
      loading={loading}
      fileToDataUri={fileToDataUri}
      isUpload={isUpload}
      setIsUpload={setIsUpload}
      isSubmit={isSubmit}
    />
  );
}
