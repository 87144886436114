import { BASE_API_URL_CONG_TO_THAO } from "@utils/constants";

export const APICONGTOTHAO = {
  //Cập nhật chỉ số công tơ tháo
  LIST_CAP_NHAT_CONG_TO_THAO: `${BASE_API_URL_CONG_TO_THAO}/danhsachcongto/danh-sach-cap-nhat-cong-to-thao`,
  UPLOAD_EXCEL: `${BASE_API_URL_CONG_TO_THAO}/updatechisocongto/uploadexcel`,
  CAP_NHAT_CONG_TO_THAO: `${BASE_API_URL_CONG_TO_THAO}/danhsachcongto/cap-nhat-chi-so`,
  CAP_NHAT_MDMS: `${BASE_API_URL_CONG_TO_THAO}/danhsachcongto/get-mdms`,
  CAP_NHAT_MDMS_ALL: `${BASE_API_URL_CONG_TO_THAO}/danhsachcongto/get-mdms-all`,
  CTOTHAO_LAY_DU_LIEU_CMIS: `${BASE_API_URL_CONG_TO_THAO}/danhsachcongto/sync`,
  DOWNLOAD_FILE_SAMPLE: `${BASE_API_URL_CONG_TO_THAO}/updatechisocongto/file-import-mau`,
  CTOTHAO_LIST_BIEN_BAN: `${BASE_API_URL_CONG_TO_THAO}/danhsachcongto/danh-sach-bien-ban`,
  CTOTHAO_LIST_BIEN_BAN_WEB: `${BASE_API_URL_CONG_TO_THAO}/danhsachcongto/danh-sach-bien-ban-web`,
  CTOTHAO_QUET: `${BASE_API_URL_CONG_TO_THAO}/danhsachcongto/cap-nhat-quet`,
  CTOTHAO_UPDATE_TRANG_THAI: `${BASE_API_URL_CONG_TO_THAO}/danhsachcongto/cap-nhat-trang-thai`,
  CTOTHAO_CAU_HINH_CSO_GAN_NHAT: `${BASE_API_URL_CONG_TO_THAO}/cauhinhchiso/danh-sach-cau-hinh-chi-so-ngay-gan-ngay-hien-tai`,
  SO_LUONG_DOI_SOAT: `${BASE_API_URL_CONG_TO_THAO}/danhsachcongto/tong-so-luong-doi-soat`,
  CTOTHAO_DONG_BO_CMIS: `${BASE_API_URL_CONG_TO_THAO}/danhsachcongto/push-cmis-sync`,
  CTOTHAO_DONG_BO_VE_WEB: `${BASE_API_URL_CONG_TO_THAO}/danhsachcongto/lay-thong-tin-cua-bien-ban-gui-kiem-dinh`,
  CTOTHAO_DS_CONG_TO_DB: `${BASE_API_URL_CONG_TO_THAO}/danhsachcongto/kiem-tra-ket-qua-dong-bo-web-danh-sach-bien-ban`,
  CTOTHAO_CHI_TIET_DONG_BO: `${BASE_API_URL_CONG_TO_THAO}/bien-ban/get-bien-ban-sync`,

  //Báo cáo
  LIST_BAO_CAO_CONG_TO_THAO: `${BASE_API_URL_CONG_TO_THAO}/danhsachcongto/danh-sach-bao-cao-cong-to-thao`,
  LIST_BAO_CAO_CONG_TO_THAO_PDF: `${BASE_API_URL_CONG_TO_THAO}/danhsachcongto/danh-sach-bao-cao-cong-to-thao-pdf`,
  LIST_BAO_CAO_CONG_TO_THAO_EXCEL: `${BASE_API_URL_CONG_TO_THAO}/danhsachcongto/danh-sach-bao-cao-cong-to-thao-excel`,

  //Cấu hình chỉ số
  LIST_CAU_HINH_CHI_SO: `${BASE_API_URL_CONG_TO_THAO}/cauhinhchiso/danh-sach-cau-hinh-chi-so`,
  SAVE_CAU_HINH_CHI_SO: `${BASE_API_URL_CONG_TO_THAO}/cauhinhchiso/luu-cau-hinh-chi-so`,
  UPDATE_CAU_HINH_CHI_SO: `${BASE_API_URL_CONG_TO_THAO}/cauhinhchiso/cap-nhat-cau-hinh-chi-so`,
  DELETE_CAU_HINH_CHI_SO: `${BASE_API_URL_CONG_TO_THAO}/cauhinhchiso/`,
  CTOTHAO_NHAN_VIEN: `${BASE_API_URL_CONG_TO_THAO}/cauhinhchiso/nhanvien`,
};