import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";
import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import {
  buildQueryString,
  parseParams,
  alertMessage
} from "@utils/function";

// get list data bảng kê nhân viên cần xác nhận
export const handleListBKBPXacNhan = (
  setLoading,
  location,
  setListBKBP,
  setTotalCount,
  filterConditions,
  setSelectedRowKeys
) => {
  let url = "";
  url = `${Endpoint.AG_BP_LIST_BK}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
    authGetData({
      url,
      setLoading,
    onSuccess: (res) => {
      setSelectedRowKeys([]);
      if (res.data !== null) {
        setListBKBP(res.data);
        setTotalCount(res.paging.totalCount);
      } else setListBKBP([]);
    },
  });
};

export const submitBPXacNhanBK = (
  t,
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
) => {
  authPostData({
    url: Endpoint.AG_BP_XAC_NHAN_BK,
    method: METHOD_PUT,
    payload: {
      ids: selectedRowKeys,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
      } else {
        alertMessage("error", t("modal.thong_bao"), res.message);
      }
    },
  });
};

export const submitBPTraLaiBK = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys,
  handleCloseModalTraLai,
  lyDo
) => {
  authPostData({
    url: Endpoint.AG_BP_TRA_LAI_BK,
    method: METHOD_PUT,
    payload: {
      ids: selectedRowKeys,
      lyDoTraLai: lyDo
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
        handleCloseModalTraLai()
      }
    },
  });
};

  