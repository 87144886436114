import { METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { authPostData } from "@utils/request";

export const handleXacNhanKyDuyet = (url, data, setLoading, setFetchList) => {
  authPostData({
    url,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchList(true);
      }
    },
  });
};
