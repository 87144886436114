import { Endpoint } from "@utils/endpoint";

export const selectKKOG = [
  {
    label: "1. Bảng kiểm kê công nợ phải trả",
    value: Endpoint.BCCN_BANG_KIEM_KE_CONG_NO_PHAI_TRA,
  },
  {
    label: "2. Bảng kiểm kê công nợ phải thu",
    value: Endpoint.BCCN_BANG_KIEM_KE_CONG_NO_PHAI_THU,
  },
  {
    label: "3. Báo cáo số phải thu trừ số phải trả",
    value: Endpoint.BCCN_BC_SO_PHAI_THU_TRU_SO_PHAI_TRA,
  },
];
