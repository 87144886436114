import {
  buildQueryString,
  handlePagination,
  // handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { handleListBaoCao } from "./services";
import { Table } from "antd";
import Text from "antd/lib/typography/Text";
import BaoCaoTongHopSoPhaTable from "./table";
import { useSelector } from "react-redux";
import { authSelector } from "@state/auth";
import {
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
  MONTH_STRING,
} from "@utils/constants";
import { downloadFile } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import moment from "moment";

export default function Index() {
  const { user } = useSelector(authSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [, setTotalCount] = useState(0);
  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);
  // const { user } = useSelector(authSelector);
  //filter
  const [filterConditions, setFilterConditions] = useState({
    // pageIndex: DEFAULT_PAGEINDEX,
    // pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
    loaiYeuCauId: searchParams.get("loaiYeuCauId")
      ? searchParams.get("loaiYeuCauId")
      : undefined,
    // donViId: user.unitId,
    ngayBaoCao: moment(moment().startOf(MONTH_STRING)).format(FORMAT_ENGLISH),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //change pagination
  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  };
  //fetch báo cáo
  const getListBaoCao = useCallback(() => {
    handleListBaoCao(filterConditions, setLoading, setData, setTotalCount);
  }, [filterConditions]);
  const [filterExcel, setFilterExcel] = useState(false);
  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type]);

  //search
  const handleSearch = useCallback(
    (values) => {
      setFilterConditions(() => ({
        // pageIndex: DEFAULT_PAGEINDEX,
        ...values,
        // donViId: values.donViId,
      }));
      handleAPI();
    },
    [handleAPI]
  );

  //clear search
  const clearFilter = useCallback(() => {
    setFilterConditions({
      loaiYeuCauId: undefined,
      // pageIndex: DEFAULT_PAGEINDEX,
      // pageSize: DEFAULT_PAGESIZE,
      // donViId: user.unitId,
      ngayBaoCao: moment(moment().startOf(MONTH_STRING)).format(FORMAT_ENGLISH),
    });
    setType(ENUM_BUTTON_TYPE.TIM_KIEM);
  }, []);

  useEffect(() => {
    if (filterExcel) {
      const endpoint = `${
        Endpoint.EXPORT_EXCEL_BAO_CAO_SO_PHA
      }?${buildQueryString(parseParams(filterConditions))}`;
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel]);

  useEffect(() => {
    if (type === ENUM_BUTTON_TYPE.TIM_KIEM) getListBaoCao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListBaoCao]);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text, record, index) => (
        <div className="table-text-right">
          <span>{index + 1}</span>
        </div>
      ),
      width: "5%",
    },
    {
      title: user.isTongCongTy ? "Đơn vị" : "Tổ đội",
      dataIndex: "tenDonViHoacDoi",
      key: "tenDonViHoacDoi",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "17%",
      sorter: true,
    },
    {
      title: "Tháng",
      dataIndex: "thang",
      key: "thang",
      children: [
        {
          title: "Công tơ 1p",
          dataIndex: "soPhaThang1Pha",
          key: "soPhaThang1Pha",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "13%",
          sorter: true,
        },
        {
          title: "Công tơ 3p",
          dataIndex: "soPhaThang3Pha",
          key: "soPhaThang3Pha",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "13%",
          sorter: true,
        },
      ],
    },
    {
      title: "Quý",
      dataIndex: "quy",
      key: "quy",
      children: [
        {
          title: "Công tơ 1p",
          dataIndex: "soPhaQuy1Pha",
          key: "soPhaQuy1Pha",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "13%",
          sorter: true,
        },
        {
          title: "Công tơ 3p",
          dataIndex: "soPhaQuy3Pha",
          key: "soPhaQuy3Pha",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "13%",
          sorter: true,
        },
      ],
    },
    {
      title: "Năm",
      dataIndex: "nam",
      key: "nam",
      children: [
        {
          title: "Công tơ 1p",
          dataIndex: "soPhaNam1Pha",
          key: "soPhaNam1Pha",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "13%",
          sorter: true,
        },
        {
          title: "Công tơ 3p",
          dataIndex: "soPhaNam3Pha",
          key: "soPhaNam3Pha",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: "13%",
          sorter: true,
        },
      ],
    },
  ];
  //footer total count table
  const countTotals = (pageData) => {
    let thang1Pha = 0;
    let thang3Pha = 0;
    let quy1Pha = 0;
    let quy3Pha = 0;
    let nam1Pha = 0;
    let nam3Pha = 0;
    pageData.forEach(
      ({
        soPhaThang1Pha,
        soPhaThang3Pha,
        soPhaQuy1Pha,
        soPhaQuy3Pha,
        soPhaNam1Pha,
        soPhaNam3Pha,
      }) => {
        thang1Pha += soPhaThang1Pha;
        thang3Pha += soPhaThang3Pha;
        quy1Pha += soPhaQuy1Pha;
        quy3Pha += soPhaQuy3Pha;
        nam1Pha += soPhaNam1Pha;
        nam3Pha += soPhaNam3Pha;
      }
    );
    return (
      <Table.Summary fixed>
        <Table.Summary.Row style={{ backgroundColor: "#FFEAEA" }}>
          <Table.Summary.Cell align="center" colSpan={2} index={0}>
            <Text className="font-bold">Tổng cộng</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={1}>
            <Text className="font-bold">{thang1Pha}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={2}>
            <Text className="font-bold">{thang3Pha}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={3}>
            <Text className="font-bold">{quy1Pha}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={4}>
            <Text className="font-bold">{quy3Pha}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={5}>
            <Text className="font-bold">{nam1Pha}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right" index={6}>
            <Text className="font-bold">{nam3Pha}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <BaoCaoTongHopSoPhaTable
      handleSearch={handleSearch}
      clearFilter={clearFilter}
      filterConditions={filterConditions}
      loading={loading}
      columns={columns}
      data={data}
      countTotals={countTotals}
      onChangePagination={onChangePagination}
      setType={setType}
    />
  );
}
