import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { handlePagination } from "@utils/function";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import React, { Fragment, useCallback, useState } from "react";
import styled from "styled-components";
import { exportData, handleDownload, selectSMSCovid2 } from "./service";

export default function Bieu2ChiTietSLTinNhan() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [screen, setScreen] = useState();
  const [data, setData] = useState([]);

  const optionValue = [
    {
      label: "Biểu 2: Chi tiết số lượng tin nhắn theo từng công ty điện lực",
    },
  ];

  const handleChange = useCallback((values) => {
    setScreen(values);
  }, []);

  //pagination table

  const onFinish = (values) => {
    const dataRequest = {
      ...values,
      reportType:
        Endpoint.BCDOISOATSMSCOVID2_CHI_TIET_SO_LUONG_TIN_NHAN_THEO_TUNG_CONG_TY_DIEN_LUC,
    };
    exportData(dataRequest, setLoading, setData);
  };

  return (
    <Fragment>
      <Container>
        <ContentWrapper>
          <Spin spinning={loading}>
            <FormComponent
              onFinish={onFinish}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
            >
              <Form.Item
                name="reportType"
                label="Tên báo cáo"
                labelAlign="left"
              >
                <Select
                  defaultValue={optionValue}
                  placeholder="Tất cả"
                  options={selectSMSCovid2}
                  formKey="reportType"
                  onChange={() => {
                    handleChange(form.getFieldValue("reportType"));
                    console.log(form.getFieldValue("reportType"));
                  }}
                />
              </Form.Item>

              <Form.Item name="unitId" label="Đơn vị" labelAlign="left">
                <Selection
                  url={Endpoint.GET_TEAM_LIST_CODE}
                  formKey="unitId"
                  form={form}
                />
              </Form.Item>

              <Row justify="end">
                <Button
                  type="primary"
                  onClick={() => {
                    handleDownload(
                      {
                        ...form.getFieldValue(),
                        reportType:
                          Endpoint.BCDOISOATSMSCOVID2_CHI_TIET_SO_LUONG_TIN_NHAN_THEO_TUNG_CONG_TY_DIEN_LUC,
                      },
                      setLoading
                    );
                  }}
                >
                  Xuất Excel
                </Button>
              </Row>
            </FormComponent>
            <Divider className="section-text-line" orientation="left"></Divider>
          </Spin>
        </ContentWrapper>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 0px 16px;
`;
