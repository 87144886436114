import Filter from "../../../common/Filter";
import TableComponent from "@components/TableComponent";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { Fragment, useCallback } from "react";
import { Export } from "../../export/Export";
import { FillerCTThoiGian } from "../list-bo-loc";
export default function BaoCaoTongHopTheoThoiGian(props) {
  const {
    columns,
    handleSearch,
    clearFilter,
    filterConditions,
    loading,
    data,
    countTotals,
    setLoading,
    onChangePagination,
    totalCount,
  } = props;
  const queryString = buildQueryString(
    parseParams({
      ...filterConditions,
    })
  );
  const genExtra = useCallback(
    () => (
      <div className="export">
        <Export
          excelEndpoint={`${Endpoint.LICH_GCS_THEO_THOI_GIAN_EXCEL}?${queryString}`}
          pdfEndpoint={`${Endpoint.LICH_GCS_THEO_THOI_GIAN_PDF}?${queryString}`}
          setLoading={setLoading}
        />
      </div>
    ),
    [queryString, setLoading]
  );
  return (
    <Fragment>
      <Filter>
        <FillerCTThoiGian
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
        />
      </Filter>
      <TableComponent
        header="Tổng hợp thời gian GCS"
        columns={columns}
        bordered
        loading={loading}
        totalData={totalCount}
        dataSource={data}
        onChangePagination={onChangePagination}
        pagination={filterConditions}
        scrollX={1400}
        renderExtraAction={() => genExtra()}
        summary={(pageData) => countTotals(pageData)}
      />
    </Fragment>
  );
}
