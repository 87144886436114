import { Form } from "antd";
import { useCallback, useEffect, useState } from "react";
import { handleMultiBoLoc } from "./services";
import FormBoLoc from "./form-bo-loc";
import { authSelector } from "@state/auth";
import { useSelector } from "react-redux";

export default function ListFilter(props) {
  const { handleSearch, clearFilter, filterConditions, setType } = props;
  const [, setLoading] = useState(false);
  const [loaiTT, setLoaiTT] = useState([]);
  const [form] = Form.useForm();
  const { user } = useSelector(authSelector);
  const [donVi, setDonVi] = useState([]);
  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);

  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  useEffect(() => {
    handleMultiBoLoc(setLoading, setLoaiTT, setDonVi);
  }, []);

  return (
    <FormBoLoc
      form={form}
      handleSearch={handleSearch}
      loaiTT={loaiTT}
      user={user}
      donVi={donVi}
      filterConditions={filterConditions}
      clear={clear}
      setType={setType}
    />
  );
}
