import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Row, Select, Form, Button, Input } from "antd";
import { Fragment, useMemo, useEffect, useCallback, useState } from "react";
import { BodyTable, HeaderTable } from "./css-styled";

const { Option } = Select;

export default function Columns(props) {
  const {
    dataSource,
    handleAdd,
    isHinhThuc,
    dataHinhThuc,
    tableColums,
    nameFields,
    handleRemove,
    setKeyData,
    countAdd,
    form,
  } = props;
  useEffect(() => {
    const keys = [];
    dataSource.map((item) => {
      return keys.push(item.key);
    });
    setKeyData(keys);
  }, [dataSource, isHinhThuc, setKeyData]);
  const [data] = useState([]);
  const handleChangeFormSelect = (value, name) => {
    data[`${name}`] = value;
    setNewData();
  };

  const handleChangeFormInput = (e, name) => {
    data[`${name}`] = e.target.value;
    setNewData();
  };
  const setNewData = useCallback(() => {
    countAdd &&
      countAdd.length > 0 &&
      // eslint-disable-next-line array-callback-return
      countAdd.map((itemCount) => {
        // eslint-disable-next-line array-callback-return
        dataSource.map((item) => {
          item[`hinhThucThiCongId-${itemCount}`] =
            data[`hinhThucThiCong-${itemCount}`];
          item[`dgtg1-${itemCount}`] = data[`dgtg1-${itemCount}`];
          item[`dgtg2-${itemCount}`] = data[`dgtg2-${itemCount}`];
          item[`dgtg3-${itemCount}`] = data[`dgtg3-${itemCount}`];
          item[`dgttc1-${itemCount}`] = data[`dgttc1-${itemCount}`];
          item[`dgttc2-${itemCount}`] = data[`dgttc2-${itemCount}`];
          item[`dgttc3-${itemCount}`] = data[`dgttc3-${itemCount}`];
          item[`dgttcvt1-${itemCount}`] = data[`dgttcvt1-${itemCount}`];
          item[`dgttcvt2-${itemCount}`] = data[`dgttcvt2-${itemCount}`];
          item[`dgttcvt3-${itemCount}`] = data[`dgttcvt3-${itemCount}`];
        });
      });
  }, [countAdd, data, dataSource]);

  useEffect(() => {
    if (dataSource.length > 0 && dataSource[0].key) {
      dataSource.forEach((item) => {
        form.setFieldsValue({
          [`dgtg1-${item.key}`]: 0,
          [`dgtg2-${item.key}`]: 0,
          [`dgtg3-${item.key}`]: 0,
          [`dgttc1-${item.key}`]: 0,
          [`dgttc2-${item.key}`]: 0,
          [`dgttc3-${item.key}`]: 0,
          [`dgttcvt1-${item.key}`]: 0,
          [`dgttcvt2-${item.key}`]: 0,
          [`dgttcvt3-${item.key}`]: 0,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderData = useMemo(
    () => (
      <BodyTable>
        {dataSource && dataSource.length > 0
          ? dataSource.map((item, index) => (
              <Row key={index}>
                {!isHinhThuc ? null : (
                  <Fragment>
                    <Form.Item name={"nameKey"} hidden>
                      <Input value={item.key} />
                    </Form.Item>
                    <Form.Item
                      name={`hinhThucThiCong-${item.key}`}
                      style={{
                        marginBottom: 0,
                        width: "15%",
                        textAlign: "left",
                      }}
                    >
                      <Select
                        onChange={(value) =>
                          handleChangeFormSelect(
                            value,
                            `hinhThucThiCong-${item.key}`
                          )
                        }
                      >
                        {dataHinhThuc && dataHinhThuc.length > 0
                          ? dataHinhThuc.map((item, index) => (
                              <Option value={item.id} key={index}>
                                {item.tenHinhThucTC}
                              </Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                  </Fragment>
                )}
                {nameFields && nameFields.length > 0
                  ? nameFields.map((nameField, inx) => (
                      <Fragment key={inx}>
                        <Form.Item
                          name={`${nameField}-${item.key}`}
                          style={{
                            marginBottom: 0,
                            width: !isHinhThuc ? "11.11%" : "8.33%",
                          }}
                        >
                          <Input
                            style={{ textAlign: "right" }}
                            placeholder="Nhập đơn giá"
                            type="number"
                            min={0}
                            onChange={(e) =>
                              handleChangeFormInput(
                                e,
                                `${nameField}-${item.key}`
                              )
                            }
                          />
                        </Form.Item>
                      </Fragment>
                    ))
                  : null}
                {!isHinhThuc ? null : (
                  <Form.Item style={{ marginBottom: 0, width: "10%" }}>
                    <div className="table-text-center">
                      {index === 0 ? null : (
                        <MinusCircleOutlined
                          onClick={() => handleRemove(item.key)}
                          type="primary"
                          style={{ color: "red" }}
                        />
                      )}
                    </div>
                  </Form.Item>
                )}
              </Row>
            ))
          : null}
      </BodyTable>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataHinhThuc, dataSource, handleRemove, isHinhThuc, nameFields]
  );
  return (
    <Fragment>
      {!isHinhThuc ? null : (
        <div
          style={{
            width: "100%",
            textAlign: "right",
          }}
        >
          <Button
            onClick={handleAdd}
            type="primary"
            style={{ marginBottom: 16 }}
            icon={<PlusOutlined />}
            disabled={false}
          />
        </div>
      )}
      <HeaderTable
        style={{ display: "flex", height: "80px" }}
        className="ant-table-thead"
      >
        {!isHinhThuc ? null : (
          <div
            style={{
              width: "15%",
              lineHeight: "80px",
              position: "relative",
            }}
            className="ant-table-cell"
          >
            Hình thức thi công
          </div>
        )}

        {tableColums.map((column, index) => {
          if (column.loaiYeuCaus) {
            return (
              <Fragment>
                <div
                  style={{
                    width: !isHinhThuc ? "33.33%" : "25%",
                  }}
                  key={index}
                >
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "4px",
                      height: "28px",
                    }}
                  >
                    {column.tenLoaiDonGia}
                  </span>
                  <div style={{ display: "flex", lineHeight: 1.5 }}>
                    {column.loaiYeuCaus.map((item, ind) => (
                      <div
                        style={{
                          width: "33.33%",
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        key={ind}
                        className="ant-table-cell"
                      >
                        {item.tenLoaiYeuCau}
                      </div>
                    ))}
                  </div>
                </div>
              </Fragment>
            );
          }
          return (
            <div style={{ width: "15%" }} key={index}>
              {tableColums.tenDonGia}
            </div>
          );
        })}
        {!isHinhThuc ? null : (
          <div style={{ width: "10%", lineHeight: "55px" }}>Tác vụ</div>
        )}
      </HeaderTable>
      {renderData}
    </Fragment>
  );
}
