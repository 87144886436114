import { METHOD_POST, METHOD_PUT, STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  parseParams,
  alertMessage,
  getErrorMessage,
} from "@utils/function";
import { authDeleteData, authGetData, authPostData } from "@utils/request";

//get list data
export const handleListData = (
  setLoading,
  filterConditions,
  setDataThiCong,
  setTotalCount,
  skip
) => {
  let url = `${Endpoint.LIST_HINH_THUC_THI_CONG}?${buildQueryString(
    parseParams(filterConditions)
  )}`;
  if (skip)
    url = `
  ${Endpoint.LIST_HINH_THUC_THI_CONG}?pageIndex=1&pageSize=-1&skipNone=${skip}`;
  authGetData({
    url: url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataThiCong(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataThiCong([]);
    },
  });
};

//create data
const handleCreateData = (
  data,
  setLoading,
  closeModal,
  getListThiCong,
  form
) => {
  authPostData({
    url: Endpoint.CREATE_HINH_THUC_THI_CONG,
    method: METHOD_POST,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListThiCong();
        closeModal();
      } else getErrorMessage(res, form);
    },
  });
};

//update data
const handleUpdateData = (
  data,
  setLoading,
  closeModal,
  getListThiCong,
  form
) => {
  authPostData({
    url: Endpoint.UPDATE_HINH_THUC_THI_CONG,
    method: METHOD_PUT,
    payload: data,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        getListThiCong();
        closeModal();
      } else getErrorMessage(res, form);
    },
  });
};

//remove data
export const handleRemoveData = (ids, setLoading, getListThiCong, t) => {
  authDeleteData({
    url: Endpoint.REMOVE_HINH_THUC_THI_CONG + "?Ids=" + ids,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) getListThiCong();
      else alertMessage("error", t("modal.thong_bao"), res.message);
    },
  });
};

//submit form
export const handleSubmitForm = (
  values,
  detaiDataTable,
  setLoading,
  closeModal,
  getListData,
  form
) => {
  const data = {
    ...values,
  };

  if (detaiDataTable.id) {
    data.id = detaiDataTable.id;
  }

  handleCreateUpdate(data, setLoading, closeModal, getListData, form);
};

// create/update dữ liệu
const handleCreateUpdate = (
  data,
  setLoading,
  closeModal,
  getListData,
  form
) => {
  if (data.id) {
    //sửa dữ liệu

    handleUpdateData(data, setLoading, closeModal, getListData, form);
  } else {
    //tạo dữ liệu

    handleCreateData(data, setLoading, closeModal, getListData, form);
  }
};
