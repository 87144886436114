import { Col, Row } from "antd";
import styled from "styled-components";

export const TableInfo = styled(Row)`
  margin: 0;
  border: 1px solid #f5f5f5;
  border-bottom: unset;
  margin-bottom: 12px;
  border-radius: 4px;
  width: 58%;
  margin: 0 auto;
  margin-left: 21% !important;

  .ant-col {
    padding: 8px;
    border-bottom: 1px solid #f5f5f5;
    // cursor: pointer;
  }

  // width: 100%;
  // margin-bottom: 20px;

  // tr td {
  //   width: 33.33%;
  //   border: 1px solid;
  //   padding: 4px;
  // }

  // tr td:not(:last-child) {
  //   border-right: unset;
  // }

  // tr td:not(:first-child) {
  //   border-left: unset;
  //   text-align: center;
  // }

  // tr:first-child,
  // tr:nth-child(2) {
  //   background-color: #fff2cc;
  // }

  // tr:nth-child(2n + 1) td {
  //   border-bottom: unset;
  // }

  // tr:nth-child(2n) td {
  //   border-top: unset;
  // }

  // tr td span:last-child {
  //   margin-left: 32px;
  // }
`;

export const ColLgSpan = styled(Col)`
  @media screen and (min-width: 1400px) {
    max-width: 11% !important;
  }
`;
