import { FORMAT_DATE } from "@utils/constants";
import { removeAccents } from "@utils/function";
import { Checkbox, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { Fragment } from "react";
import { CustomRow } from "./css-styled";
import moment from "moment";

const { Option } = Select;

export default function ThongTinChungTabs(props) {
  const {
    detail,
    dataLyDo,
    // setSoCot,
    dataNVNiemPhong,
    dataNVTreoThao,
    onChange,
    isCheck2,
    isCheck1,
    arrLoaiBienBan,
    arrViTriTreo,
    dataSBB,
    handleChangeLoaiBB,
    valueLoaiBienBan,
    handleChangeDate,
    ngay,
  } = props;
  return (
    <Fragment>
      <Row gutter={24} style={{ padding: "8px" }}>
        {detail?.loaiBienBan === "4" || detail?.loaiBienBan === 4 ? null : (
          <Col span={24} md={6}>
            <Form.Item
              label="Loại treo tháo"
              name="loaiBienBan"
              // rules={[{ required: true, message: "Không được bỏ trống" }]}
            >
              <Select
                placeholder="Chọn loại biên bản"
                value={valueLoaiBienBan}
                onChange={handleChangeLoaiBB}
                // disabled
              >
                {arrLoaiBienBan.map((item, index) => (
                  <Option key={index} value={item.value.toString()}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        <Col
          span={24}
          md={detail?.loaiBienBan === "4" || detail?.loaiBienBan === 4 ? 8 : 6}
        >
          <Form.Item
            label="Lý do treo tháo"
            name="lyDoTreoThaoId"
            rules={[{ required: true, message: "Không được bỏ trống" }]}
          >
            <Select
              placeholder="Nhập lý do treo tháo"
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
              showSearch
            >
              {dataLyDo && dataLyDo.length > 0
                ? dataLyDo.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.maLyDo + " - " + item.tenLyDo}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col
          span={24}
          md={detail?.loaiBienBan === "4" || detail?.loaiBienBan === 4 ? 8 : 6}
        >
          <Form.Item
            label="Vị trí treo"
            name="viTriTreoThao"
            // rules={[{ required: true, message: "Không được bỏ trống" }]}
          >
            <Select
              // placeholder="Chọn vị trí treo"
              // onChange={handleChangeViTriTreo}
              disabled
            >
              {arrViTriTreo.map((item, index) => (
                <Option key={index} value={item.value.toString()}>
                  {detail.viTriTreoThao === 3 || detail.viTriTreoThao === "3"
                    ? `Vị trí khác: ${detail.viTriKhac}`
                    : item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col
          span={24}
          md={detail?.loaiBienBan === "4" || detail?.loaiBienBan === 4 ? 8 : 6}
        >
          <Form.Item label="Ngày treo tháo" name="ngayTreoThaoTB">
            <DatePicker
              defaultValue={moment(ngay, FORMAT_DATE)}
              format={FORMAT_DATE}
              onChange={handleChangeDate}
              allowClear={false}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            label="Số cột"
            name="soCot"
            // rules={[
            //   { required: isRequired, message: "Không được bỏ trống" },
            //   () => ({
            //     validator(_, value) {
            //       if (parseInt(value) < 0) {
            //         return Promise.reject(
            //           `Số cột nhập phải lớn hơn hoặc bằng 0`
            //         );
            //       }
            //       return Promise.resolve();
            //     },
            //   }),
            // ]}
          >
            <Input
              // placeholder="Nhập số cột"
              // type="number"
              // onChange={(e) => setSoCot(e.target.value)}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            label="Số hộp"
            name="soHop"
            rules={[
              () => ({
                validator(_, value) {
                  if (parseInt(value) < 0) {
                    return Promise.reject(
                      `Số hộp nhập phải lớn hơn hoặc bằng 0`
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Nhập số hộp" type="number" />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            label="HSN điểm đo"
            name="hsn"
            // rules={[
            //   { required: true, message: "Không được bỏ trống" },
            //   () => ({
            //     validator(_, value) {
            //       const re = /^[0-9\b]+$/;
            //       if (re.test(value)) {
            //         if (parseInt(value) <= 0) {
            //           return Promise.reject(`Hệ số nhân nhập phải lớn hơn 0`);
            //         }
            //       } else if (value.indexOf(".")) {
            //         if (parseFloat(value) <= 0) {
            //           return Promise.reject(`Hệ số nhân nhập phải lớn hơn 0`);
            //         }
            //       }
            //       return Promise.resolve();
            //     },
            //   }),
            // ]}
          >
            <Input placeholder="Nhập HSN điểm đo" />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          {/* <Form.Item
            label="Số biên bản"
            name="soBienBan"
            // rules={[
            //   { required: true, message: "Không được bỏ trống" },
            //   () => ({
            //     validator(_, value) {
            //       if (parseInt(value) < 0) {
            //         return Promise.reject(
            //           `Số biên bản nhập phải lớn hơn hoặc bằng 0`
            //         );
            //       }
            //       return Promise.resolve();
            //     },
            //   }),
            // ]}
          > */}
          <div
            style={{
              padding: "0 0 8px",
            }}
          >
            Số biên bản
          </div>
          <Input
            // placeholder="Nhập số biên bản"
            // type="number"
            readOnly
            value={dataSBB}
            // onChange={(e) => setSoCot(e.target.value)}
          />
          {/* </Form.Item> */}
        </Col>
        {/* <Col span={24} md={12}>
          <Form.Item
            label="HSN điểm đo"
            name="hsn"
            rules={[
              { required: true, message: "Không được bỏ trống" },
              () => ({
                validator(_, value) {
                  if (parseInt(value) < 0) {
                    return Promise.reject(
                      `Hệ số nhân nhập phải lớn hơn hoặc bằng 0`
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Nhập HSN điểm đo" />
          </Form.Item>
        </Col> */}
        <CustomRow span={24} md={6}>
          <Form.Item label="Chì hộp/Số viên" name="maChiHop">
            <Input placeholder="Nhập mã chì hộp" />
          </Form.Item>
        </CustomRow>
        <Col
          span={24}
          md={6}
          style={{
            alignItems: "center",
            display: "flex",
            marginTop: "2px",
          }}
        >
          <Form.Item
            label=""
            name="soVienChiHop"
            style={{ margin: 0, width: "100%" }}
            rules={[
              () => ({
                validator(_, value) {
                  if (parseInt(value) < 0) {
                    return Promise.reject(
                      `Số viên chì hộp nhập phải lớn hơn hoặc bằng 0`
                    );
                  } else if (parseInt(value) >= 100) {
                    return Promise.reject(
                      `Số viên chì hộp nhập phải bé hơn 100`
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Nhập số viên chì hộp" type="number" />
          </Form.Item>
        </Col>
        <CustomRow span={24} md={6} className="after-icon">
          <Form.Item label="Chì booc/Số viên" name="maChiBOOC">
            <Input placeholder="Nhập mã chì BOOC" />
          </Form.Item>
        </CustomRow>

        <Col
          span={24}
          md={6}
          style={{
            alignItems: "center",
            display: "flex",
            marginTop: "2px",
          }}
        >
          <Form.Item
            label=""
            name="soVienChiBOOC"
            style={{ margin: 0, width: "100%" }}
            rules={[
              () => ({
                validator(_, value) {
                  if (parseInt(value) < 0) {
                    return Promise.reject(
                      `Số viên chì BOOC nhập phải lớn hơn hoặc bằng 0`
                    );
                  } else if (parseInt(value) >= 100) {
                    return Promise.reject(
                      `Số viên chì BOOC nhập phải bé hơn 100`
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Nhập số viên chì BOOC" type="number" />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="nhanVienTreoThaoId"
            label="Nhân viên treo tháo"
            rules={[
              {
                required: true,
                message: "Nhân viên treo tháo không được bỏ trống.",
              },
            ]}
          >
            <Select
              placeholder="Nhập nhân viên treo tháo"
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {dataNVTreoThao &&
                dataNVTreoThao.length > 0 &&
                dataNVTreoThao.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {`${item.hoTen} - ${item.tenDangNhap}${
                      !item.maNhanVienCmis ? "" : `- ${item.maNhanVienCmis}`
                    }`}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="nhanVienNiemPhongId"
            label="Nhân viên niêm phong"
            rules={[
              {
                required: true,
                message: "Nhân viên niêm phong không được bỏ trống.",
              },
            ]}
          >
            <Select
              placeholder="Nhập nhân viên niêm phong"
              showSearch
              filterOption={(input, option) => {
                if (option && option.children) {
                  return (
                    removeAccents(option.children)
                      .toLowerCase()
                      .indexOf(removeAccents(input).toLowerCase()) >= 0
                  );
                }
              }}
            >
              {dataNVNiemPhong &&
                dataNVNiemPhong.length > 0 &&
                dataNVNiemPhong.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {`${item.hoTen} - ${item.tenDangNhap}${
                      !item.maNhanVienCmis ? "" : `- ${item.maNhanVienCmis}`
                    }`}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col
          span={24}
          md={12}
          style={{
            alignItems: "center",
            display: "flex",
            marginTop: "2px",
            marginBottom: "16px",
          }}
        >
          <Form.Item label="" name="isDoXa" style={{ margin: 0 }}>
            <Checkbox onChange={(e) => onChange(e, 2)} checked={isCheck2}>
              Thông tin đo xa
            </Checkbox>
          </Form.Item>
        </Col>
        <Col
          span={24}
          md={12}
          style={{
            alignItems: "center",
            display: "flex",
            marginTop: "2px",
            marginBottom: "16px",
          }}
        >
          <Form.Item label="" name="isTreoThaoTUTI" style={{ margin: 0 }}>
            <Checkbox
              onChange={(e) => onChange(e, 1)}
              checked={isCheck1}
              disabled={detail.loaiBienBan === 4 ? true : false}
            >
              Treo tháo TU/TI chốt chỉ số
            </Checkbox>
          </Form.Item>
        </Col>

        <Col span={24} md={6}>
          <Form.Item label="Giờ" name="gioDoXa">
            <Input
              type="number"
              min={0}
              max={23}
              disabled={!isCheck2}
              placeholder="Nhập giờ"
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item label="Phút" name="phutDoXa">
            <Input
              type="number"
              min={0}
              max={59}
              disabled={!isCheck2}
              placeholder="Nhập phút"
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item label="HESID" name="hesid">
            <Input disabled={!isCheck2} placeholder="Nhập HESID" />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item label="Gateway ID" name="gatewayID">
            <Input disabled={!isCheck2} placeholder="Nhập Gateway ID" />
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  );
}
