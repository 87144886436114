import {
  ENUM_DS_CONGTO,
  STATUSCODE_200,
  TYPE_TABLE_BAOCAO,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { buildQueryString, parseParams } from "@utils/function";
import { authGetData, authGetMultiplesRequest } from "@utils/request";

export const handleListData = (
  filterConditions,
  url,
  setLoading,
  setDataSource,
  setTotalCount,
  location
) => {
  url = `${url}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setDataSource(res.data);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};

export const handleMultiDS = (setLoading, setTrangThai, setSoPha) => {
  const endpoints = [
    `${Endpoint.COMMON_LIST_SO_PHA}`,
    `${Endpoint.LIST_TINHTRANG_CT}?MaLoaiDanhSach=${ENUM_DS_CONGTO.DINHKY_TRACUU}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setSoPha(res[0].data.data);
      setTrangThai(res[1].data.data);
    },
  });
};

export function inPDF(
  setLoading,
  onComplete,
  typeList,
  filterConditions,
  location
) {
  const url = `${
    typeList === TYPE_TABLE_BAOCAO.BATTHUONG
      ? Endpoint.PDF_BC_CT_BATTHUONG
      : typeList === TYPE_TABLE_BAOCAO.DINHKYDAKIEMTRA
      ? Endpoint.PDF_BC_CT_DINHKY
      : typeList === TYPE_TABLE_BAOCAO.PHUCTRADAKIEMTRA
      ? Endpoint.PDF_BC_CT_PHUCTRA
      : null
  }?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url: url,
    setLoading: setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        onComplete(res.data);
      } else {
        onComplete("error");
      }
    },
  });
}
