import { Endpoint } from "@utils/endpoint";
import { authSelector } from "@state/auth";
import { Col, Form, Input, Row, Select, Tooltip, Button, Checkbox } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DatePickerComponent from "@components/DatePicker";
import { FORMAT_ENGLISH, MONTH_STRING } from "@utils/constants";
import moment from "moment";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import Selection from "@components/Select";
import FormComponent from "@components/Form";
import { authGetMultiplesRequest, downloadFile } from "@utils/request";
import { buildQueryString, parseParams } from "@utils/function";
import CheckPermission from "@components/CheckPermission";

const { Option } = Select;

export function ListFilter(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    tooltip,
    funcID,
    checkCanhBao,
    urlXuatExcel,
    permissionExcel,
  } = props;
  const { user } = useSelector(authSelector);
  const [loading, setLoading] = useState(false);
  const [loaiYeuCau, setLoaiYeuCau] = useState([]);
  const [trangThai, setTrangThai] = useState([]);
  const [mucDich, setMucDich] = useState([]);
  const [soPha, setSoPha] = useState([]);
  const [form] = Form.useForm();
  useEffect(() => {
    let object = {};
    if (
      !!filterConditions.maTrangThai &&
      !Array.isArray(filterConditions.maTrangThai)
    ) {
      object = {
        ...filterConditions,
        maTrangThai: filterConditions.maTrangThai.split(",").map(Number),
      };
    } else {
      if (Array.isArray(filterConditions.maTrangThai)) {
        object = {
          ...filterConditions,
          maTrangThai: filterConditions.maTrangThai,
        };
      } else {
        object = {
          ...filterConditions,
        };
      }
    }
    form.setFieldsValue(object);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  useEffect(() => {
    const endpoints = [
      `${Endpoint.COMMON_LIST_LOAI_YEU_CAU}`,
      `${Endpoint.COMMON_LIST_TRANG_THAI + "?FuncId=" + funcID}`,
      `${Endpoint.COMMON_LIST_MUC_DICH}`,
      `${Endpoint.COMMON_LIST_SO_PHA}`,
    ];
    authGetMultiplesRequest({
      endpoints,
      setLoading,
      onSuccess: (res) => {
        setLoaiYeuCau(res[0].data.data);
        setTrangThai(res[1].data.data);
        setMucDich(res[2].data.data);
        setSoPha(res[3].data.data);
      },
    });
    // eslint-disable-next-line
  }, []);

  //call api xuất excel
  const handleExportExcel = useCallback((endpoint) => {
    if (endpoint !== undefined)
      downloadFile({
        endpoint,
        setLoading,
      });
  }, []);

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        donViId: user.unitId.toString(),
      }}
      autoComplete="off"
    >
      <Row gutter={24}>
        <Col span={24} xs={24} sm={24} md={12} lg={6}>
          <Form.Item name="donViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Selection
                url={Endpoint.GET_UNIT_LIST}
                form={form}
                formKey="donViId"
                notClear={true}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={24} xs={24} sm={24} md={12} lg={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.donViId !== currentValues.donViId) {
                form.setFieldsValue({
                  doiId: undefined,
                });
              }
              return prevValues.donViId !== currentValues.donViId;
            }}
          >
            {({ getFieldValue }) => {
              if (getFieldValue("donViId")) {
                return (
                  <Form.Item name="doiId" label="Đội">
                    <Selection
                      url={`${Endpoint.GET_TEAM_LIST}?donviId=${getFieldValue(
                        "donViId"
                      )}`}
                      formKey="doiId"
                      form={form}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="doiId" label="Đội">
                  <Selection
                    url={`${Endpoint.GET_TEAM_LIST}?donviId=${user.unitId}`}
                    formKey="doiId"
                    form={form}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={24} xs={24} sm={24} md={8} lg={6}>
          <Form.Item name="loaiYeuCauId" label="Loại yêu cầu">
            <Select placeholder="Tất cả" loading={loading} allowClear>
              {loaiYeuCau && loaiYeuCau.length > 0
                ? loaiYeuCau.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24} xs={24} sm={24} md={8} lg={6}>
          <Form.Item name="maTrangThai" label="Trạng thái">
            <Select
              placeholder="Tất cả"
              mode="multiple"
              loading={loading}
              allowClear
            >
              {trangThai && trangThai.length > 0
                ? trangThai.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} xs={24} sm={24} md={8} lg={6}>
          <Form.Item name="mucDich" label="Mục đích">
            <Select placeholder="Tất cả" loading={loading} allowClear>
              {mucDich && mucDich.length > 0
                ? mucDich.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value.toString()}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} xs={24} sm={24} md={8} lg={6}>
          <Form.Item name="soPha" label="Số pha">
            <Select placeholder="Tất cả" loading={loading} allowClear>
              {soPha && soPha.length > 0
                ? soPha.map((item, index) => {
                    if (item.value !== null) {
                      return (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      );
                    }
                    return null;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} xs={24} sm={12} md={8} lg={6}>
          <Form.Item name="startDate" label="Từ ngày">
            <DatePickerComponent
              defaultValue={
                filterConditions.startDate
                  ? moment(filterConditions.startDate, FORMAT_ENGLISH)
                  : moment().startOf(MONTH_STRING)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"startDate"}
              notClear={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} sm={12} md={8} lg={6}>
          <Form.Item name="endDate" label="Đến ngày">
            <DatePickerComponent
              defaultValue={
                filterConditions.endDate
                  ? moment(filterConditions.endDate, FORMAT_ENGLISH)
                  : moment()
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"endDate"}
              notClear={true}
            />
          </Form.Item>
        </Col>

        <Col span={24} xs={24} sm={24} md={16} lg={18}>
          <Form.Item name="searchTerm" label="Từ khóa tìm kiếm">
            <Input
              prefix={<SearchOutlined />}
              suffix={
                <Tooltip title={tooltip}>
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
        <Col
          span={24}
          xs={24}
          sm={24}
          md={8}
          lg={6}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Form.Item name="isCanhBao" style={{ margin: 0 }}>
            <Checkbox
              onChange={checkCanhBao}
              defaultChecked={filterConditions.isCanhBao ? true : false}
            >
              Cảnh báo
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Button className="mr-24px" onClick={clear}>
          Bỏ lọc
        </Button>
        <Button type="primary" htmlType="submit" form="filter-form">
          Tìm kiếm
        </Button>
        {urlXuatExcel ? (
          <CheckPermission permissionCode={permissionExcel}>
            <Button
              type="primary"
              className="ml-24px"
              onClick={() =>
                handleExportExcel(
                  `${urlXuatExcel}?${buildQueryString(
                    parseParams(filterConditions)
                  )}`
                )
              }
            >
              Xuất Excel
            </Button>
          </CheckPermission>
        ) : null}
      </Row>
    </FormComponent>
  );
}
