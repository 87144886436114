import { buildQueryString, parseParams } from "@utils/function";
import { Endpoint } from "@utils/endpoint";
import { authGetData, authPostData } from "@utils/request";
import { METHOD_POST, STATUSCODE_200, ENUM_MUCDICH_SDD } from "@utils/constants";

export const handleListData = (
  location,
  filterConditions,
  setLoading,
  setDataGuiDuLieuCMIS,
  setTotalCount,
  setSelectedRowKeys
) => {
  let url = "";
  url = `${Endpoint.AG_PKD_LIS_YC_DB}?${buildQueryString(
    Object.keys(parseParams(location.search)).length > 0
      ? parseParams(location.search)
      : filterConditions
  )}`;
  authGetData({
    url,
    setLoading,
    onSuccess: (res) => {
      setSelectedRowKeys([]);
      if (res.data !== null) {
        setDataGuiDuLieuCMIS(res.data);
        setTotalCount(res.paging.totalCount);
      } else setDataGuiDuLieuCMIS([]);
    },
  });
};

export const submitPKDTraLaiYCDongBo = (
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys,
  handleCloseModalTraLai,
  lyDo,
) => {
  authPostData({
    url: Endpoint.AG_PKD_TRA_LAI_YC_DB,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys.toString(),
      lyDoTraLai: lyDo
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setSelectedRowKeys([]);
        handleCloseModalTraLai();
      }
    },
  });
};

export const submitDBYeuCauCMIS = (
  loaiKH,
  setLoading,
  setFetchDataTable,
  selectedRowKeys,
  setSelectedRowKeys,
  setDataResponse
) => {
  authPostData({
    url: loaiKH === ENUM_MUCDICH_SDD.SINHHOAT ? 
    Endpoint.AG_DONG_BO_DM_VE_CMIS : Endpoint.AG_DONG_BO_AG_VE_CMIS,
    method: METHOD_POST,
    payload: {
      ids: selectedRowKeys,
    },
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200) {
        setFetchDataTable(true);
        setDataResponse(res.data)
        setSelectedRowKeys([]);
      }
    },
  });
};