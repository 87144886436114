import styled from "styled-components";

export const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  };
  .mb-24px {
    margin-bottom: 24px;
    align-items: center;
  };
  .mb-5px {
    margin-bottom: 5px;
  };
`;
export const ContentWrapper = styled.div`
  padding: 0px 16px;
`;


export const Containers = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
`;
export const TableStyled = styled.div`
  .ant-table-thead {
    .ant-table-cell {
    background: #d9d9d9;
    color: #1f1f1f;
    font-size: 13px;
    }
  }
`;