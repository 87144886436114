import { Table } from "antd";

export default function Index(props) {
  const { components, dataVTTL, columns } = props;
  return (
    <Table
      components={components}
      rowClassName={() => "editable-row"}
      bordered
      dataSource={dataVTTL}
      columns={columns}
      rowKey={(obj) => obj.stt}
      pagination={false}
      scroll={{ y: 300 }}
    />
  );
}
