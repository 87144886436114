import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "antd";
import LoadingComponent from "@components/Loading";
import styled from "styled-components";
import FormComponent from "@components/Form";
import { handlerNgungDiemDo } from "./services";

export default function ModalNgungDiemDo(props) {
  const {
    visible,
    setLoadingDanhSach,
    handleCloseModal,
    detail,
    setDataDanhSach,
    dispatch,
    closeModal,
    getDanhSachDiemDo,
    filterConditions,
  } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = () => {
    handlerNgungDiemDo(
      detail,
      filterConditions,
      setLoading,
      handleCloseModal,
      setDataDanhSach,
      dispatch,
      closeModal,
      setLoadingDanhSach,
      getDanhSachDiemDo
    );
  };

  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={"Ngưng điểm đo"}
        visible={visible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <FormComponent form={form} onFinish={onFinish}>
          <Container>
            <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
              <p>Ngưng sử dụng thông tin của điểm đo!</p>
              <p>
                Nếu thực hiện ngưng sử dụng, tất cả các thông tin của điểm đo sẽ
                hết hiệu lực hoàn toàn!
              </p>
            </h4>
            <Row gutter={24} justify="center" style={{ marginTop: "48px" }}>
              <Col span={12} style={{ textAlign: "right" }}>
                <Button
                  key="back"
                  onClick={handleCloseModal}
                  className="button-closed"
                >
                  Hủy
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  type="danger"
                  className="primary"
                  htmlType="submit"
                  key="confirm"
                  loading={loading}
                >
                  Đồng ý
                </Button>
              </Col>
            </Row>
          </Container>
        </FormComponent>
      </Modal>
    </LoadingComponent>
  );
}
const Container = styled.div`
  margin-top: 12px;
  padding: 0px 10px;
`;
