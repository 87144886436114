import { memo } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import UIBangKeThietBi from "./ui-modal/bang-ke-thiet-bi";
function BangKeThietBiModal({
  detailBangKeThietBi,
  visible,
  handleCreateUpdate,
  handleCloseModal,
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  //submit dữ liệu congSuatValue

  const handleSubmit = (values) => {
    const data = {
      ...values,
    };
    if (detailBangKeThietBi.id) {
      data.id = detailBangKeThietBi.id;
    }
    handleCreateUpdate(data, form);
  };

  return (
    <UIBangKeThietBi
      detailBangKeThietBi={detailBangKeThietBi}
      t={t}
      visible={visible}
      handleCloseModal={handleCloseModal}
      form={form}
      handleSubmit={handleSubmit}
    />
  );
}

export default memo(BangKeThietBiModal);
