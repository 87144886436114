import DatePickerComponent from "@components/DatePicker";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import {
  DEFAULT_PAGEINDEX,
  DEFAULT_PAGESIZE,
  ENUM_BUTTON_TYPE,
  FORMAT_ENGLISH,
} from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  buildQueryString,
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { downloadFile } from "@utils/request";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { selectBaoCaoSMS } from "../../services";
import Bieu2ChiTietSLTinNhanTheoCtyDienLuc from "../bieu2-chi-tiet-sl-tin-nhan-theo-tung-cty-dien-luc";
import Bieu3THSLTNTheoNhaMang from "../bieu3-th-so-luong-tin-nhan-theo-tung-nha-mang";
import Bieu4DoiSoatSLTNThanhCong from "../bieu4-doi-soat-sl-tn-thanh-cong";
import Bieu5ChiTietSLTn from "../bieu5-chi-tiet-tn-theo-thanh-cong-theo-tung-cty-dl";
import Bieu7DSTnGuiChoKh from "../bieu7-ds-tn-chua-gui-cho-kh";
import Bieu8DoiSoat from "../bieu8-doi-soat-theo-ngay";
import Bieu9DoiSoatDonVi from "../bieu9-doi-soat-ngay-theo-don-vi";
import { getListDataB1 } from "./services";
export default function BaoCaoDoiSoat() {
  const [loading, setLoading] = useState();
  const [form] = Form.useForm();
  const [data, setDataB1] = useState([]);
  const { Option } = Select;
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [screen, handleChange] = useState();
  const optionValue = [
    { label: "Biểu 1: Tổng hợp tin nhắn toàn Tổng công ty" },
  ];

  const defaultOptionValue = "e12382fc-5ebb-474d-a1a7-de277b424007";

  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    TuNgay: moment().startOf("month").format(FORMAT_ENGLISH),
    DenNgay: moment().format(FORMAT_ENGLISH),
    doiTuong: "e12382fc-5ebb-474d-a1a7-de277b424007",
    ...parseParams(location.search),
  });

  // layout effect
  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const [type, setType] = useState(ENUM_BUTTON_TYPE.TIM_KIEM);

  const [filterExcel, setFilterExcel] = useState(false);
  const handleAPI = useCallback(() => {
    if (type === ENUM_BUTTON_TYPE.XUAT_EXCEL) {
      setFilterExcel(true);
    } else setFilterExcel(false);
  }, [type]);

  const handleSearch = useCallback(
    (values) => {
      setFilterConditions((oldState) => ({
        ...oldState,
        phanLoai: values.PhanLoai,
        doiTuong: values.doiTuong,
        TuNgay: values.TuNgay,
        DenNgay: values.DenNgay,
      }));
      const payload = {
        phanLoai: values.PhanLoai,
        doiTuong: values.doiTuong,
        TuNgay: values.TuNgay,
        DenNgay: values.DenNgay,
      };
      handleAPI();
      getListDataB1(payload, setLoading, setDataB1);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleAPI]
  );
  const onChangePagination = (paging, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      width: 70,
      align: "center",
    },
    {
      title: "Đơn vị",
      dataIndex: "MA_DVIQLY",
      key: "MA_DVIQLY",
      width: 150,
    },
    {
      title: "Số lượng chưa nhắn",
      dataIndex: "SL_CHUANHAN",
      width: 150,
      key: "SL_CHUANHAN",
    },
    {
      title: "Tỷ lệ chưa nhắn",
      dataIndex: "TYLE_CHUANHAN",
      width: 150,
      key: "TYLE_CHUANHAN",
    },
    {
      title: "Số lượng đã nhắn",
      dataIndex: "SL_DANHAN",
      width: 150,
      key: "SL_DANHAN",
    },
    {
      title: "Tỷ lệ đã nhắn",
      dataIndex: "TYLE_NHAN",
      key: "TYLE_NHAN",
      width: 150,
    },
    {
      title: "Số lượng lỗi",
      dataIndex: "SL_LOI",
      width: 150,
      key: "SL_LOI",
    },
    {
      title: "Tỷ lệ lỗi",
      dataIndex: "TYLE_LOI",
      width: 150,
      key: "TYLE_LOI",
    },
    {
      title: "Tổng",
      dataIndex: "TONG",
      width: 150,
      key: "TONG",
    },
  ];

  useEffect(() => {
    if (filterExcel) {
      const endpoint =
        Endpoint.B1_EXCEL +
        "?" +
        buildQueryString(parseParams(filterConditions));
      downloadFile({
        endpoint,
        setLoading,
      });
      setFilterExcel(false);
    }
  }, [filterConditions, filterExcel]);

  return (
    <Fragment>
      {screen === Endpoint.B1_TH_TIN_NHAN_TOAN_CONG_TY ? (
        <BaoCaoDoiSoat />
      ) : screen === Endpoint.B2_CT_SL_TN_THEO_CTY_DIEN_LUC ? (
        <Bieu2ChiTietSLTinNhanTheoCtyDienLuc
          screen={screen}
          handleChange={handleChange}
        />
      ) : screen === Endpoint.B3_TH_TIN_NHAN_THEO_TUNG_NHA_MANG ? (
        <Bieu3THSLTNTheoNhaMang screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.B4_DOI_SOAT_SL_TIN_NHAN_THANH_CONG ? (
        <Bieu4DoiSoatSLTNThanhCong
          screen={screen}
          handleChange={handleChange}
        />
      ) : screen === Endpoint.B5_CHI_TIET_SL_TIN_NHAN_THANH_CONG ? (
        <Bieu5ChiTietSLTn screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.B7_DS_TN_CHUA_GUI_KH ? (
        <Bieu7DSTnGuiChoKh screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.B8_DOI_SOAT_THEO_NGAY ? (
        <Bieu8DoiSoat screen={screen} handleChange={handleChange} />
      ) : screen === Endpoint.B9_DOI_SOAT_NGAY_THEO_DON_VI ? (
        <Bieu9DoiSoatDonVi screen={screen} handleChange={handleChange} />
      ) : (
        <Container>
          <ContentWrapper>
            <FormComponent
              onFinish={handleSearch}
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              initialValues={{
                reportType: screen,
                doiTuong: defaultOptionValue,
                PhanLoai: "1",
              }}
            >
              <Row gutter={12}>
                <Col span={24}>
                  <Form.Item
                    name="reportType"
                    label="Tên báo cáo"
                    labelAlign="left"
                  >
                    <Select
                      formKey="reportType"
                      form={form}
                      defaultValue={optionValue}
                      options={selectBaoCaoSMS}
                      onChange={() =>
                        handleChange(form.getFieldValue("reportType"))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="PhanLoai"
                    label="Phân loại"
                    labelAlign="left"
                  >
                    <Select>
                      {/* <Option value="0"> Tất cả</Option> */}
                      <Option value="1"> Tiền điện lần 1</Option>
                      {/* <Option value="2"> Dịch vụ điện tử</Option>
                      <Option value="3"> Dịch vụ khác</Option>
                      <Option value="4">
                        Thông báo tiền điện lần 1 và DVĐT
                      </Option> */}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Col span={24}>
                <Form.Item name="doiTuong" label="Nội dung" labelAlign="left">
                  <Selection
                    url={Endpoint.DOITUONG_SMS_TIEN_DIEN_L1}
                    formKey="doiTuong"
                    form={form}
                  />
                </Form.Item>
              </Col>
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item
                    name="TuNgay"
                    label="Ngày bắt đầu"
                    labelAlign="left"
                    labelCol={{ span: 12 }}
                  >
                    <div style={{ marginLeft: "3%" }}>
                      <DatePickerComponent
                        formKey="TuNgay"
                        form={form}
                        labelAlign="left"
                        format={FORMAT_ENGLISH}
                        defaultValue={
                          filterConditions.TuNgay
                            ? moment(filterConditions.TuNgay, FORMAT_ENGLISH)
                            : moment()
                        }
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="DenNgay"
                    label="Ngày kết thúc"
                    labelAlign="right"
                    labelCol={{ span: 10 }}
                  >
                    <DatePickerComponent
                      formKey="DenNgay"
                      form={form}
                      labelAlign="left"
                      format={FORMAT_ENGLISH}
                      defaultValue={
                        filterConditions.DenNgay
                          ? moment(filterConditions.DenNgay, FORMAT_ENGLISH)
                          : moment()
                      }
                    />
                  </Form.Item>
                </Col>
                <Row justify="end">
                  <Button
                    type="primary"
                    className="mr-5px"
                    htmlType="submit"
                    onClick={() => setType(ENUM_BUTTON_TYPE.TIM_KIEM)}
                  >
                    Xem báo cáo
                  </Button>
                  <Button
                    type="primary"
                    className="mr-5px"
                    htmlType="submit"
                    onClick={() => setType(ENUM_BUTTON_TYPE.XUAT_EXCEL)}
                  >
                    Xuất excel
                  </Button>
                  {/* <XuatExcel
                    endpoint={
                      Endpoint.B1_EXCEL +
                      "?" +
                      buildQueryString(parseParams(filterConditions))
                    }
                    text={"Xuất Excel"}
                    key={"xuat-excel"}
                  /> */}
                </Row>
              </Row>
              <Divider
                className="section-text-line"
                orientation="left"
              ></Divider>
            </FormComponent>
            <TableComponent
              header={"Biểu 1 - Tổng hợp tin nhắn toàn Tổng công ty"}
              columns={columns}
              loading={loading}
              dataSource={data}
              scrollX={1400}
              pagination={filterConditions}
              onChangePagination={onChangePagination}
              filterConditions={filterConditions}
            />
          </ContentWrapper>
        </Container>
      )}
    </Fragment>
  );
}

const Container = styled.div`
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
`;
const ContentWrapper = styled.div`
  padding: 10px 16px;
`;
