import { Fragment } from "react";
import TableTest from "./table";
export default function Index(props) {
  const {
    loaiCongTo,
    dataSourceDSVTTL,
    setDataSourceDSVTTL,
    selectRowKeys,
    form,
  } = props;

  return (
    <Fragment>
      <TableTest
        loaiCongTo={loaiCongTo}
        dataSourceDSVTTL={dataSourceDSVTTL}
        setDataSourceDSVTTL={setDataSourceDSVTTL}
        selectRowKeys={selectRowKeys}
        form={form}
      />
    </Fragment>
  );
}
