import LoadingComponent from "@components/Loading";
import { Button, Col, Image, Modal, Row, Select } from "antd";
import { Fragment, useEffect, useState, useCallback } from "react";
import { ContainerChoice } from "../css-styled";
import { removeAccents } from "@utils/function";
import noImage from "@assets/images/no-image.png";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

export default function AnhHienTruong(props) {
  const { dataAnh } = props;
  // const [dataGiayTo, setDataGiayTo] = useState([]);
  const [dataAnhGT, setDataAnhGT] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [, setCaption] = useState("");
  const [modalSrc, setModalSrc] = useState("");
  const [sectionIndex, setSectionIndex] = useState(null);
  const [imageIndex, setImageIndex] = useState(null);
  const [keyImage, setKeyImage] = useState(0);
  const [currentSectionLength, setCurrentSectionLength] = useState(null);

  const [hideButtonLeft, setHideButtonLeft] = useState(false);
  const [hideButtonRight, setHideButtonRight] = useState(false);
  const [note, setNote] = useState("");

  useEffect(() => {
    setDataAnhGT(dataAnh && dataAnh[0].thongTinAnhs);
  }, [dataAnh]);

  const handleChangeGiayTo = useCallback(
    (values) => {
      setDataAnhGT(dataAnh.find((x) => x.loaiHoSoId === values).thongTinAnhs);
    },
    [dataAnh]
  );

  const showSliderImage = () => {
    if (sectionIndex <= 0 && imageIndex === 0) {
      setHideButtonLeft(true);
    } else setHideButtonLeft(false);

    if (imageIndex === dataAnhGT.length - 1 || dataAnhGT.length === 1) {
      setHideButtonRight(true);
    } else setHideButtonRight(false);
    setShowModal(true);
  };

  const plusSlides = (n, index) => {
    const nextIndex = n;
    if (nextIndex <= 0 && sectionIndex <= 1) {
      setHideButtonLeft(true);
    } else setHideButtonLeft(false);
    if (nextIndex === dataAnhGT.length - 1 || dataAnhGT.length === 1) {
      setHideButtonRight(true);
    } else setHideButtonRight(false);
    let nextImage = nextIndex;
    let nextImageIndex = nextIndex;
    let nextSectionIndex = sectionIndex;
    let nextSectionLength = currentSectionLength;
    if (nextIndex >= sectionIndex) {
      nextImage = dataAnhGT[nextIndex].filePath;
      nextImageIndex = nextIndex;
      nextSectionIndex = sectionIndex;
      nextSectionLength = currentSectionLength;
    } else {
      nextImage = dataAnhGT[sectionIndex - 1].filePath;
      nextImageIndex = 0;
      nextSectionIndex = sectionIndex - 1;
      nextSectionLength = dataAnhGT[sectionIndex - 1].filePath;
    }
    setCaption(nextImage);
    setModalSrc(nextImage);
    setImageIndex(nextImageIndex);
    setSectionIndex(nextSectionIndex);
    setCurrentSectionLength(nextSectionLength);
    setNote(note);
    setKeyImage(index);
  };
  return (
    <LoadingComponent loading={false}>
      <ContainerChoice>
        <span className="text">Chọn loại hồ sơ giấy tờ: </span>
        <Select
          placeholder="Chọn loại hồ sơ giấy tờ"
          onChange={handleChangeGiayTo}
          defaultValue={dataAnh && dataAnh.length ? dataAnh[0].loaiHoSoId : ""}
          className="choice"
          showSearch
          filterOption={(input, option) => {
            if (option && option.children) {
              return (
                removeAccents(option.children)
                  .toLowerCase()
                  .indexOf(removeAccents(input).toLowerCase()) >= 0
              );
            }
          }}
        >
          {dataAnh && dataAnh.length
            ? dataAnh.map((item, index) => (
                <Option value={item.loaiHoSoId} key={index}>
                  {item.tenLoaiHoSo}
                </Option>
              ))
            : null}
        </Select>
      </ContainerChoice>
      <div style={{ marginTop: "24px" }}>
        <Row gutter={24}>
          {dataAnhGT && dataAnhGT.length > 0 ? (
            dataAnhGT.map((item, index) => {
              return (
                <Col
                  span={24}
                  md={4}
                  key={index}
                  style={{
                    textAlign: "center",
                    marginTop: "12px",
                    marginBottom: item.ghiChu ? "36px" : "16px",
                  }}
                >
                  <Fragment>
                    <Image
                      preview={false}
                      src={item.filePath}
                      alt={""}
                      width={"100%"}
                      height={"100%"}
                      onError={(e) => {
                        e.currentTarget.src = noImage;
                      }}
                      style={{ objectFit: "cover" }}
                      onClick={() => {
                        showSliderImage();
                        setCaption(item);
                        setModalSrc(item.filePath);
                        setImageIndex(index);
                        setKeyImage(index);
                        setSectionIndex(index);
                        setNote(item.ghiChu);
                        setCurrentSectionLength(dataAnhGT.length);
                        if (index === 0) setHideButtonLeft(true);
                        else setHideButtonLeft(false);
                        if (dataAnhGT.length === index + 1)
                          setHideButtonRight(true);
                        else setHideButtonRight(false);
                      }}
                    />
                    {item.isNew ? (
                      <span style={{ color: "red" }}>
                        <InfoCircleOutlined /> Được thêm mới
                      </span>
                    ) : (
                      ""
                    )}
                  </Fragment>
                </Col>
                // </Row>
              );
            })
          ) : (
            <div style={{ minHeight: "300px" }}></div>
          )}
        </Row>

        {/* </ScrollContentComponent> */}
      </div>
      {/* {renderListImages} */}
      <Modal
        visible={showModal}
        width={800}
        onCancel={() => false}
        onOk={() => false}
        className="modal-image"
        footer={null}
      >
        <Button
          className="close"
          onClick={() => setShowModal(false)}
          style={{
            fontSize: "24px",
            border: "unset",
            position: "absolute",
            top: "0",
            right: "10px",
          }}
        >
          &times;
        </Button>

        <div
          className="mySlides"
          style={{
            display: showModal ? "block" : "none",
            marginTop: "20px",
            height: "450px",
          }}
        >
          <Image
            className="modal-content"
            src={modalSrc}
            alt=""
            height={"100%"}
            width={"100%"}
            style={{ objectFit: "contain", padding: 0 }}
          />
          <Button
            className="prev"
            onClick={() => {
              plusSlides(imageIndex - 1, keyImage);
            }}
            style={{
              position: "absolute",
              top: "50%",
              left: "-10%",
              transform: "translate(50%, -50%)",
            }}
            disabled={hideButtonLeft ? true : false}
          >
            &#10094;
          </Button>
          <Button
            className="next"
            onClick={() => {
              plusSlides(imageIndex + 1, keyImage);
            }}
            style={{
              position: "absolute",
              top: "50%",
              right: "-10%",
              transform: "translate(-50%, -50%)",
            }}
            disabled={hideButtonRight ? true : false}
          >
            &#10095;
          </Button>
        </div>
      </Modal>
    </LoadingComponent>
  );
}
