import { Fragment } from "react";
import Filter from "@components/Filter/Filter";
import TableComponent from "@components/TableComponent";
import { Container } from "./css-styled";
import ListFilter from "./list-bo-loc";
import { ENUM_DS_YEU_CAU } from "@utils/constants";

export default function ListYeuCauTable(props) {
  const {
    typeList,
    loading,
    dataSource,
    totalData,
    handleSearch,
    clearFilter,
    onChangePagination,
    filterConditions,
    rowSelection,
    genExtra,
    user,
    columns,
    handleChange,
    donVi,
    trangThai,
    tram,
    loaiTreoThao,
    genExtraDongBo,
    onRow
  } = props;
  return (
    <Fragment>
      <Filter>
        <ListFilter
          handleSearch={handleSearch}
          clearFilter={clearFilter}
          filterConditions={filterConditions}
          user={user}
          handleChange={handleChange}
          donVi={donVi}
          trangThai={trangThai}
          tram={tram}
          loaiTreoThao={loaiTreoThao}
        />
      </Filter>
      {genExtraDongBo ? (
        <div className="dong-bo-yc" style={{ padding: "20px 20px 0 20px" }}>
          {genExtraDongBo()}
        </div>
      ) : null}
      <Container>
        <TableComponent
          header={"Danh sách yêu cầu"}
          renderExtraAction={() => genExtra(filterConditions)}
          dataSource={dataSource}
          columns={columns}
          totalData={totalData}
          loading={loading}
          onChangePagination={onChangePagination}
          pagination={filterConditions}
          // onRow={onRow ? onRow : null}
          scrollX={
            typeList === ENUM_DS_YEU_CAU.NVANTOAN_XACNHANANTOAN ? 2470 : 2820
          }
          scrollY={520}
          rowSelection={rowSelection ? rowSelection : null}
          onRow={onRow}
          isNoResize
          // rowKey={rowKey}
        />
      </Container>
    </Fragment>
  );
}
