import LoadingComponent from "@components/Loading";
import TableComponent from "@components/TableComponent";
import Collapse from "@modules/tich-hop-CRM/common-ho-so/Collapse";
import { removeAccents } from "@utils/function";
import { Button, Col, Form, Input, notification, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChiTietYeuCauStyle } from "../css-styled";
import {
  getChiTietYeuCau,
  getKetQuaXuLy,
  getListNvGiamSat,
  putCapNhatKetQua,
  putCapNhatYeuCauSai,
  putPhanCong,
} from "../services";
import CapNhatModal from "./cap-nhat-modal";
import PhanCongKinhDoanhModal from "./phan-cong-kinh-doanh-modal";
import PhanCongKyThuatModal from "./phan-cong-ky-thuat-modal";
import YeuCauSaiModal from "./yeu-cau-sai-modal";
import { closeModal } from "@state/system-config/reducer";
import { CloseOutlined } from "@ant-design/icons";
export default function CapNhatYeuCauModal(props) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    detail,
    linhVucList,
    setListLoading,
    filterConditions,
    setTotal,
    location,
    setData,
    formFilter,
    setLinhVucList,
    setIsShowDetail,
  } = props;
  const [visiblePhanCong, setVisiblePhanCong] = useState(false);
  const [visibleCapNhat, setVisibleCapNhat] = useState(false);
  const [visibleYeuCauSai, setVisibleYeuCauSai] = useState(false);
  const [dataChiTiet, setDataChiTiet] = useState([]);
  const [form] = Form.useForm();
  const [loadingNvGiamSat, setLoadingNvGiamSat] = useState(false);
  const [dataNvGiamSat, setDataNvGiamSat] = useState([]);
  const [nguoiGiamSat, setNguoiGiamSat] = useState(null);
  const [textArea, setTextArea] = useState("");
  const [ketQuaXuLyLoading, setKetQuaXuLyLoading] = useState(false);
  const [dataKetQuaXuLy, setDataKetQuaXuLy] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const unitId = useSelector((state) => state.auth.user.unitId);

  useEffect(() => {
    getChiTietYeuCau(setLoading, detail.id, setDataChiTiet);
    getKetQuaXuLy(setKetQuaXuLyLoading, setDataKetQuaXuLy, detail.id);
  }, [detail.id]);

  useEffect(() => {
    getListNvGiamSat(setLoadingNvGiamSat, setDataNvGiamSat, unitId);
  }, [unitId]);

  const handleChange = (value) => {
    setNguoiGiamSat(value);
  };

  const columns = [
    {
      title: "Ngày giờ",
      dataIndex: "ngayGio",
      align: "left",
      render: (text) => <>{moment(text).format("DD/MM/YYYY HH:mm")}</>,
    },
    {
      title: "Người thực hiện",
      dataIndex: "nguoiThucHien",
      align: "left",
    },
    {
      title: "Nội dung",
      dataIndex: "noiDung",
      align: "left",
    },
  ];

  // Phan Cong Modal
  const handleOpenModalPhanCong = () => {
    if (linhVucList.length > 1 && detail.linhVuc === "kythuat") {
      notification.error({
        message: "Thông báo!",
        description: "Không được phép phân công cho kỹ thuật.",
      });
    } else {
      setVisiblePhanCong(true);
    }
  };

  const handleCloseModalPhanCong = () => {
    setVisiblePhanCong(false);
  };

  const handleSubmitPhanCong = (value) => {
    const payload = {
      id: detail.id,
      userId: value.nvHienTruong || value.nvDieuDo1 || value.nvKinhDoanh,
      ...(value.ghichu && { ghiChu: value.ghichu }),
      ...(value.doi && { doiId: value.doi }),
      ...(value.nvDieuDo2 && { userId2: value.nvDieuDo2 }),
      ...(nguoiGiamSat && { nvGiamSat: nguoiGiamSat }),
    };
    putPhanCong(
      payload,
      setLoading,
      setListLoading,
      filterConditions,
      setTotal,
      setVisiblePhanCong,
      form,
      location,
      setData,
      dispatch,
      closeModal,
      formFilter,
      setLinhVucList,
      setIsShowDetail
    );
  };

  const handleChangeInput = (e) => {
    setTextArea(e.target.value);
  };

  const showPhanCongModal = useMemo(() => {
    if (visiblePhanCong) {
      if (detail.linhVuc === "kinhdoanh") {
        return (
          <PhanCongKinhDoanhModal
            handleCloseModal={handleCloseModalPhanCong}
            visible={visiblePhanCong}
            handleSubmit={handleSubmitPhanCong}
            form={form}
            loading={loading}
          />
        );
      } else {
        return (
          <PhanCongKyThuatModal
            handleCloseModal={handleCloseModalPhanCong}
            visible={visiblePhanCong}
            handleSubmit={handleSubmitPhanCong}
            form={form}
            loading={loading}
          />
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visiblePhanCong, loading]);

  // Cap Nhat Modal
  const handleOpenModalCapNhat = () => {
    if (textArea.trim().length === 0) {
      notification.error({
        message: "Thông báo!",
        description: "Không được để trống kết quả xử lý!",
      });
    } else {
      setVisibleCapNhat(true);
    }
  };

  const handleCloseModalCapNhat = () => {
    setVisibleCapNhat(false);
  };

  const handleSubmitCapNhat = () => {
    const payload = {
      id: detail.id,
      ketQuaXuLy: textArea,
    };
    putCapNhatKetQua(
      payload,
      setLoadingBtn,
      setListLoading,
      setData,
      location,
      filterConditions,
      setTotal,
      setVisibleCapNhat,
      dispatch,
      closeModal,
      formFilter,
      setLinhVucList,
      setIsShowDetail
    );
  };

  const showCapNhatModal = useMemo(() => {
    if (visibleCapNhat) {
      return (
        <CapNhatModal
          visible={visibleCapNhat}
          handleCloseModal={handleCloseModalCapNhat}
          handleSubmit={handleSubmitCapNhat}
          loading={loadingBtn}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingBtn, visibleCapNhat]);

  // Yeu Cau Sai

  const handleOpenModalYeuCauSai = () => {
    if (textArea.trim().length === 0) {
      notification.error({
        message: "Thông báo!",
        description: "Không được để trống kết quả xử lý!",
      });
    } else {
      setVisibleYeuCauSai(true);
    }
  };

  const handleCloseModalYeuCauSai = () => {
    setVisibleYeuCauSai(false);
  };

  const handleSubmitYeuCauSai = () => {
    const payload = {
      id: detail.id,
      ketQuaXuLy: textArea,
    };
    putCapNhatYeuCauSai(
      payload,
      setLoadingBtn,
      setListLoading,
      setData,
      location,
      filterConditions,
      setTotal,
      setVisibleCapNhat,
      dispatch,
      closeModal,
      formFilter,
      setLinhVucList,
      setIsShowDetail
    );
  };

  const showYeuCauSaiModal = useMemo(() => {
    if (visibleYeuCauSai) {
      return (
        <YeuCauSaiModal
          visible={visibleYeuCauSai}
          handleCloseModal={handleCloseModalYeuCauSai}
          handleSubmit={handleSubmitYeuCauSai}
          loading={loadingBtn}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, visibleYeuCauSai]);

  return (
    <LoadingComponent loading={loading}>
      {/* thông tin hồ sơ */}
      <ChiTietYeuCauStyle>
        <div className="modal-header">
          <span>Cập nhật</span>
          <div className="close-icon" onClick={() => setIsShowDetail(false)}>
            <CloseOutlined />
          </div>
        </div>
        <Row gutter={24}>
          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Mã khách hàng</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.maKhachHang}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Tên khách hàng</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.tenKhachHang}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Điện thoại khách hàng</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.dienThoaiKhachHang}</h5>
          </Col>

          <Col className="field_wrap" span={24}>
            <h5 className="fs-14px c-t-label">Địa chỉ khách hàng</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.diaChiKhachHang}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Tổ/đội khách hàng</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.toDoiKH}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Tên trạm PP</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.tenTramPP}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Mã trạm PP</h5>
            <h5 className="fs-14px font-bold">{/* {dataChiTiet?.maTramPP } */}</h5>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Mã tin</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.maYeuCau}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Loại yêu cầu</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.loaiYeuCau}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Trạng thái phiếu</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.trangThaiPhieu}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Tên người yêu cầu</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.tenNguoiYeuCau}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Điện thoại</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.dienThoaiNguoiYeuCau}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Số lần gọi</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.soLanGoi}</h5>
          </Col>

          <Col className="field_wrap" span={16}>
            <h5 className="fs-14px c-t-label">Địa chỉ người yêu cầu</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.diaChiNguoiYeuCau}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Đội</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.doi}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Ngày yêu cầu</h5>
            <h5 className="fs-14px font-bold">
              {dataChiTiet?.ngayYeuCau ? moment(dataChiTiet.ngayYeuCau).format("DD/MM/YYYY HH:mm") : ""}
            </h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Ngày tiếp nhận</h5>
            <h5 className="fs-14px font-bold">
              {dataChiTiet?.ngayTiepNhan ? moment(dataChiTiet.ngayTiepNhan).format("DD/MM/YYYY HH:mm") : ""}
            </h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Ngày hết hạn</h5>
            <h5 className="fs-14px font-bold">
              {dataChiTiet?.ngayHetHan ? moment(dataChiTiet.ngayTiepNhan).format("DD/MM/YYYY HH:mm") : ""}
            </h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Người tạo</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.nguoiTao}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Người tiếp nhận</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.nguoiTiepNhan}</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Nhân viên hiện trường</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.nhanVienHienTruong}</h5>
          </Col>
          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Trở ngại</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.troNgai}</h5>
          </Col>
          <Col className="field_wrap" span={8}>
            <h5 className="fs-14px c-t-label">Ngày hẹn</h5>
            <h5 className="fs-14px font-bold">
              {dataChiTiet?.ngayHen ? moment(dataChiTiet.ngayHen).format("DD-MM-YYYY") : ""}
            </h5>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col className="field_wrap" span={8}>
            <h5 style={{ margin: "0", fontSize: "14px" }}>Chọn thông tin người giám sát (nếu cần)</h5>
          </Col>

          <Col className="field_wrap" span={8}>
            <Select
              placeholder="Chọn NV hỗ trợ, giám sát"
              allowClear
              showSearch
              loading={loadingNvGiamSat}
              onChange={handleChange}
              filterOption={(input, option) => {
                if (option && option.children) {
                  return removeAccents(option.children).toLowerCase().indexOf(removeAccents(input).toLowerCase()) >= 0;
                }
              }}
            >
              {dataNvGiamSat &&
                dataNvGiamSat.map((item, id) => {
                  return (
                    <Select.Option key={id} value={item.value}>
                      {item.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col className="field_wrap" span={24}>
            <h5 className="fs-14px c-t-label">Nội dung yêu cầu</h5>
            <h5 className="fs-14px font-bold">{dataChiTiet?.noiDungYeuCau}</h5>
          </Col>
        </Row>
        <br />
        <Row gutters={24}>
          <Col className="field_wrap" span={24}>
            <h5 className="fs-14px c-t-label">Kết quả xử lý</h5>
            <Input.TextArea row={4} onChange={handleChangeInput} />
          </Col>
        </Row>
        <br />
        <Collapse>
          <TableComponent
            dataSource={dataKetQuaXuLy}
            columns={columns}
            expandable={false}
            header="Kết quả xử lý ở Web/App"
            rowKey="key"
            loading={ketQuaXuLyLoading}
          />
        </Collapse>

        <Row gutter={24}>
          <Col className="field_wrap" span={24} style={{ gap: "30px", justifyContent: "center", marginTop: "10px" }}>
            <Button onClick={() => setIsShowDetail(false)}>Đóng lại</Button>
            <Button style={{ backgroundColor: "lime" }} onClick={handleOpenModalPhanCong}>
              Phân công lại
            </Button>
            <Button style={{ backgroundColor: "chartreuse" }} onClick={handleOpenModalCapNhat}>
              Cập nhật
            </Button>
            <Button style={{ backgroundColor: "orange" }} onClick={handleOpenModalYeuCauSai}>
              Yêu cầu sai
            </Button>
          </Col>
        </Row>
      </ChiTietYeuCauStyle>
      {showPhanCongModal}
      {showCapNhatModal}
      {showYeuCauSaiModal}
    </LoadingComponent>
  );
}
