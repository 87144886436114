import React, { Suspense, useCallback } from "react";
import { useDispatch } from "react-redux";
import { openModal, closeModal } from "@state/system-config/reducer";
import { useTranslation } from "react-i18next";
import ModalInHoSoCMIS from "../../common-ho-so/in-ho-so-CMIS";
import ModalXemLaiPhuongAnCapDien from "../../common-ho-so/xem-lai-phuong-an-cap-dien";
import ModalQuyetToanKhachHang from "../../common-ho-so/quyet-toan-khach-hang";
import ModalQuyetToanCongTy from "../../common-ho-so/quyet-toan-cong-ty";
import DetailLapPhuongAn from "./lap-phuong-an";
import ModalChuyenDoiTruong from "../../common-ho-so/chuyen-doi-truong";
import ModalTroNgaiKS from "../lap-phuong-an/modals/modal-tro-ngai-khao-sat";

import { Fragment, useMemo, useState } from "react";
import { authGetData } from "@utils/request";
import { Endpoint } from "@utils/endpoint";
import {
  ONG,
  BA,
  CO_QUAN,
  TYPEPOPUP_TRONGAI,
  TYPE_BIEN_BAN,
} from "@utils/constants";
import { handleXemLaiPhuongAn } from "../services";
import ListMenu from "./list-menu";
import {
  downLoadBienBan,
  getPDFBienBanKhaiGia,
} from "../../nhan-vien-khao-sat/services";
import PDFModal from "@modules/cap-dien/common-ho-so/pdf-viewer";
import {
  inBienBan,
  inBieuDoPhuTai,
  downloadExcelBieuDoPT,
} from "../../common-ho-so/services";
import ReactPDF from "@react-pdf/renderer";
import generateChart from "@modules/cap-dien/common-ho-so/pdf-viewer/charts/generate-chart";
import BieuDoPhuTai from "@modules/cap-dien/common-ho-so/pdf-viewer/bieu-do-phu-tai";
import { alertMessage } from "@utils/function";
import ModalChuyenAG from "./modals/moda-chuyen-ap-gia";

export default function ListChucNang({
  detail,
  data,
  setFetchDataTable,
  funcID,
  dataTroNgai,
  loading,
  setIsDoubleClick,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [visibleCMIS, setVisibleCMIS] = useState(false);
  const [visibleXemLaiPhuongAnCapDien, setVisibleXemLaiPhuongAnCapDien] =
    useState(false);
  const [visibleQuyetToanKhachHang, setVisibleQuyetToanKhachHang] =
    useState(false);
  const [visibleQuyetToanCongTy, setVisibleQuyetToanCongTy] = useState(false);
  const [visibleInBieuKhaiGia, setVisibleInBieuKhaiGia] = useState(false);
  const [visibleChuyenDT, setVisibleChuyenDT] = useState(false);
  const [visibleTroNgaiKS, setVisibleTroNgaiKS] = useState(false);
  const [, setLoadingDetail] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [dataPAXemLai, setDataPAXemLai] = useState({});
  const [visibleChuyenAG, setVisibleChuyenAG] = useState(false);
  // open modal

  // const handleOpenModalXoaHS = () => {
  //   setVisibleXoaHS(true);
  //   setIsDoubleClick(false);
  // };

  const handleOpenModalInHoSoCMIS = () => {
    setVisibleCMIS(!visibleCMIS);
    setIsDoubleClick(false);
  };
  const handleOpenModalXemLaiPhuongAnCapDien = () => {
    //call api
    handleXemLaiPhuongAn(
      detail,
      setDataPAXemLai,
      setVisibleXemLaiPhuongAnCapDien,
      visibleXemLaiPhuongAnCapDien
    );

    setIsDoubleClick(false);
  };
  const handleOpenModalQuyetToanKhachHang = () => {
    setVisibleQuyetToanKhachHang(!visibleQuyetToanKhachHang);
    setIsDoubleClick(false);
  };
  const handleOpenModalQuyetToanCongTy = () => {
    setVisibleQuyetToanCongTy(!visibleQuyetToanCongTy);
    setIsDoubleClick(false);
  };
  const handleOpenModalInBieuKhaiGia = () => {
    setVisibleInBieuKhaiGia(!visibleInBieuKhaiGia);
    setIsDoubleClick(false);
  };
  const handleOpenModalChuyenDT = () => {
    setVisibleChuyenDT(!visibleChuyenDT);
    setIsDoubleClick(false);
  };
  const handleOpenModalTroNgaiKS = () => {
    setVisibleTroNgaiKS(!visibleTroNgaiKS);
    setIsDoubleClick(false);
  };

  const handleOpenModalChuyenAG = () => {
    setVisibleChuyenAG(!visibleChuyenAG);
    setIsDoubleClick(false);
  };

  // đóng modal

  const handleCloseModalInHoSoCMIS = () => {
    setVisibleCMIS(false);
    setIsDoubleClick(true);
  };
  const handleCloseModalXemLaiPhuongAnCapDien = () => {
    setVisibleXemLaiPhuongAnCapDien(false);
    setIsDoubleClick(true);
  };
  const handleCloseModalChuyenDT = () => {
    setVisibleChuyenDT(false);
    setIsDoubleClick(true);
  };
  const handleCloseModalTroNgaiKS = () => {
    setVisibleTroNgaiKS(false);
    setIsDoubleClick(true);
  };

  const handleCloseModalChuyenAG = () => {
    setVisibleChuyenAG(false);
    setIsDoubleClick(true);
  };
  // const handleCloseModalXoaHS = () => {
  //   setVisibleXoaHS(false);
  //   setIsDoubleClick(true);
  // };

  //modal quyết toán khách hàng

  const showModalQuyettoanKhachHang = useMemo(
    () =>
      visibleQuyetToanKhachHang && (
        <ModalQuyetToanKhachHang
          visible={visibleQuyetToanKhachHang}
          detail={detail}
          setVisibleQuyetToanKhachHang={setVisibleQuyetToanKhachHang}
          visibleQuyetToanKhachHang={visibleQuyetToanKhachHang}
        />
      ),
    // eslint-disable-next-line
    [visibleQuyetToanKhachHang]
  );
  //modal quyết toán công ty

  const showModalQuyetToanCongTy = useMemo(
    () =>
      visibleQuyetToanCongTy && (
        <ModalQuyetToanCongTy
          visible={visibleQuyetToanCongTy}
          detail={detail}
          setVisibleQuyetToanCongTy={setVisibleQuyetToanCongTy}
          visibleQuyetToanCongTy={visibleQuyetToanCongTy}
        />
      ),
    // eslint-disable-next-line
    [visibleQuyetToanCongTy]
  );

  //in biên bản áp giá
  const [loadingBBApGia, setLoadingBBApGia] = useState(false);
  const [visibleInBBApGia, setVisibleInBBApGia] = useState(false);
  const inBienBanApGia = () => {
    setVisibleInBBApGia(!visibleInBBApGia);
    setIsDoubleClick(false);
  };
  const showModalInBienBanApGia = useMemo(
    () =>
      visibleInBBApGia && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visibleInBBApGia}
            setVisible={setVisibleInBBApGia}
            title={`${TYPE_BIEN_BAN.AP_GIA} ${detail?.maYCKNCmis} ${detail.tenKhachHang}`}
            getBienBan={(setUrl) => showModalBBApGia(setUrl)}
            loadingGetBienBan={loadingBBApGia}
            isDownloadExcel={false}
            isReload={false}
            type={TYPE_BIEN_BAN.AP_GIA}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visibleInBBApGia]
  );

  const showModalBBApGia = useCallback(
    (setUrl) => {
      inBienBan(
        detail,
        setLoadingBBApGia,
        (value) => {
          setUrl(value);
        },
        setLoadingPDF,
        t
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );

  //BIỂU ĐỒ PHỤ TẢI
  const [loadingPhuTai, setLoadingPhuTai] = useState(false);
  const [loadingDownloadPT, setLoadinDownloadPT] = useState(false);
  const [visiblePhuTai, setVisiblePhuTai] = useState(false);
  const handleOpenInPhuTai = () => {
    setVisiblePhuTai(!visiblePhuTai);
    setIsDoubleClick(false);
  };

  //show popup
  const showModalInBieuDoPhuTai = useMemo(
    () =>
      visiblePhuTai && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visiblePhuTai}
            setVisible={setVisiblePhuTai}
            title={`In biểu đồ phụ tải`}
            getBienBan={(setUrl) => callAPIBieuDoPhuTai(setUrl)}
            loadingGetBienBan={loadingPhuTai}
            isDownloadExcel={true}
            isReload={false}
            downloadFileExcel={() =>
              downloadExcelBieuDoPT(detail, setLoadinDownloadPT)
            }
            loadingDownload={loadingDownloadPT}
            // type={TYPE_BIEN_BAN.AP_GIA}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visiblePhuTai]
  );

  //call api
  const callAPIBieuDoPhuTai = useCallback(
    (setUrl) => {
      inBieuDoPhuTai(
        detail,
        setLoadingPhuTai,
        async (values) => {
          if (values === "error-1") {
            alertMessage(
              "error",
              t("modal.thong_bao"),
              "Tổng công suất lắp đặt phải > 40"
            );
            setVisiblePhuTai(false);
            return;
          }
          if (values && Object.values(values).length > 0) {
            const labels = values?.duLieuBieuDoCongSuat?.xValues || [];
            const datasets = [
              {
                label: "Công suất",
                fill: false,
                data: values?.duLieuBieuDoCongSuat?.yValues?.data || [], // Add data to the chart
              },
            ];
            const title = values?.duLieuBieuDoCongSuat?.title || "";
            const tilteY = values?.duLieuBieuDoCongSuat?.yInfo?.tilteY || "";

            const chartImage = await generateChart(
              datasets,
              labels,
              title,
              tilteY
            );

            const blob = await ReactPDF.pdf(
              <BieuDoPhuTai
                chartImage={chartImage}
                tenDonVi={values.tenDonVi}
                tenKhachHang={values.tenKhachHang}
                diaChiSuDungDien={values.diaChiSuDungDien}
                ngayHienTai={values.ngayHienTai}
                congSuatSuDungTrungBinhNgay={values.congSuatSuDungTrungBinhNgay}
                congSuatSuDungLonNhatTrongNgay={
                  values.congSuatSuDungLonNhatTrongNgay
                }
                congSuatSuDungNhoNhatTrongNgay={
                  values.congSuatSuDungNhoNhatTrongNgay
                }
                luongDienSuDungTrungBinhThang={
                  values.luongDienSuDungTrungBinhThang
                }
                congSuatLonNhatGioCaoDiemNgay={
                  values.congSuatLonNhatGioCaoDiemNgay
                }
                congSuatLonNhatGioCaoDiemToi={
                  values.congSuatLonNhatGioCaoDiemToi
                }
              />
            ).toBlob();
            const url = URL.createObjectURL(blob);
            setUrl(url);
          } else {
            setUrl("error");
          }
        },
        setLoadingPDF,
        t
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );
  //END BIỂU ĐỒ PHỤ TẢI

  //modal xem lại phương án cấp điện

  const showModalXemLaiPhuongAnCapDien = useMemo(
    () =>
      visibleXemLaiPhuongAnCapDien && (
        <ModalXemLaiPhuongAnCapDien
          visible={visibleXemLaiPhuongAnCapDien}
          handleCloseModal={handleCloseModalXemLaiPhuongAnCapDien}
          detail={detail}
          dataPAXemLai={dataPAXemLai}
        />
      ),
    // eslint-disable-next-line
    [visibleXemLaiPhuongAnCapDien]
  );
  //modal in hồ sơ cmis

  const showModalInHoSoCMIS = useMemo(
    () =>
      visibleCMIS && (
        <ModalInHoSoCMIS
          visible={visibleCMIS}
          detail={detail}
          handleCloseModal={handleCloseModalInHoSoCMIS}
        />
      ),
    // eslint-disable-next-line
    [visibleCMIS]
  );

  // modal xoa ho so

  // const showModalXoaHS = useMemo(
  //   () =>
  //     visibleXoaHS && (
  //       <ModalXoaHoSo
  //         visible={visibleXoaHS}
  //         handleCloseModal={handleCloseModalXoaHS}
  //         dataRemove={detail}
  //         setFetchDataTable={setFetchDataTable}
  //       />
  //     ),
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [visibleXoaHS]
  // );

  // modal chuyen doi truong

  const showModalChuyenDT = useMemo(
    () =>
      visibleChuyenDT && (
        <ModalChuyenDoiTruong
          visible={visibleChuyenDT}
          closeModal={handleCloseModalChuyenDT}
          setFetchDataTable={setFetchDataTable}
          detail={detail}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleChuyenDT]
  );

  // modal chuyen áp giá

  const showModalChuyenAG = useMemo(
    () =>
      visibleChuyenAG && (
        <ModalChuyenAG
          visible={visibleChuyenAG}
          handleCloseModal={handleCloseModalChuyenAG}
          setFetchDataTable={setFetchDataTable}
          detail={detail}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleChuyenAG]
  );

  // modal tro ngai khao sat

  const showModalTroNgaiKS = useMemo(
    () =>
      visibleTroNgaiKS && (
        <ModalTroNgaiKS
          visible={visibleTroNgaiKS}
          closeModal={handleCloseModalTroNgaiKS}
          setFetchDataTable={setFetchDataTable}
          funcID={funcID}
          dataTroNgai={dataTroNgai}
          loading={loading}
          detail={detail}
          type={TYPEPOPUP_TRONGAI.TYPE_NVKS}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataTroNgai, detail, funcID, loading, setFetchDataTable, visibleTroNgaiKS]
  );

  //hàm call chức năng lập phương án
  const getDetail = useCallback(() => {
    authGetData({
      url: Endpoint.KS_GET_THONG_TIN_CO_BAN + "?HoSoId=" + data.id,
      setLoading: setLoadingDetail,
      onSuccess: (res) => {
        if (res.statusCode === 200 && Object.keys(res.data).length > 0) {
          //Nhận giá trị từ response
          const values = res.data;
          //gán lại giá trị của Giới tính KH và Giới tính NDD
          const data = {
            ...values,
            gioiTinhKH:
              values.gioiTinhKH === parseInt(ONG) ||
              values.gioiTinhKH === parseInt(BA) ||
              values.gioiTinhKH === parseInt(CO_QUAN)
                ? values.gioiTinhKH
                : parseInt(ONG),
            gioiTinhNDD:
              values.gioiTinhNDD === parseInt(ONG) ||
              values.gioiTinhNDD === parseInt(BA)
                ? values.gioiTinhNDD
                : parseInt(ONG),
            maYCKNCmis: detail?.maYCKNCmis,
          };

          dispatch(
            openModal({
              content: (
                <DetailLapPhuongAn
                  hoSoId={data.id}
                  detailLapPhuongAn={data}
                  closeModal={closeModal}
                  loadingDetail={setLoadingDetail}
                  setFetchDataTable={setFetchDataTable}
                />
              ),
              size: "large",
              title: "Lập phương án",
            })
          );
        }
      },
    });
  }, [data.id, detail?.maYCKNCmis, dispatch, setFetchDataTable]);

  const showModalPdfKhaiGia = useMemo(
    () =>
      visibleInBieuKhaiGia && (
        <Suspense fallback={null}>
          <PDFModal
            style={{ margin: "0% 5%" }}
            visible={visibleInBieuKhaiGia}
            setVisible={setVisibleInBieuKhaiGia}
            title={`${TYPE_BIEN_BAN.KHAI_GIA} ${detail?.maYCKNCmis} ${detail.tenKhachHang}`}
            getBienBan={(setUrl) => showModalPDF(setUrl)}
            downloadFileExcel={() =>
              downLoadBienBan(detail, setLoadingDownload)
            }
            setLoadingDownload={setLoadingDownload}
            loadingDownload={loadingDownload}
            loadingGetBienBan={loadingPDF}
            isDownloadExcel={true}
            isReload={true}
            type={TYPE_BIEN_BAN.KHAI_GIA}
          />
        </Suspense>
      ),
    // eslint-disable-next-line
    [visibleInBieuKhaiGia]
  );

  const showModalPDF = useCallback(
    (setUrl) => {
      getPDFBienBanKhaiGia(
        detail,
        (value) => {
          setUrl(value);
        },
        setLoadingPDF,
        t
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detail]
  );

  return (
    <Fragment>
      <ListMenu
        dispatch={dispatch}
        openModal={openModal}
        closeModal={closeModal}
        detail={detail}
        getDetail={getDetail}
        handleOpenModalChuyenDT={handleOpenModalChuyenDT}
        handleOpenModalTroNgaiKS={handleOpenModalTroNgaiKS}
        t={t}
        handleOpenModalInHoSoCMIS={handleOpenModalInHoSoCMIS}
        handleOpenModalXemLaiPhuongAnCapDien={
          handleOpenModalXemLaiPhuongAnCapDien
        }
        handleOpenModalQuyetToanKhachHang={handleOpenModalQuyetToanKhachHang}
        handleOpenModalQuyetToanCongTy={handleOpenModalQuyetToanCongTy}
        handleOpenModalInBieuKhaiGia={handleOpenModalInBieuKhaiGia}
        inBienBanApGia={inBienBanApGia}
        handleOpenInPhuTai={handleOpenInPhuTai}
        handleOpenModalChuyenAG={handleOpenModalChuyenAG}
      />
      {showModalInHoSoCMIS}
      {showModalXemLaiPhuongAnCapDien}
      {showModalQuyettoanKhachHang}
      {showModalQuyetToanCongTy}
      {showModalPdfKhaiGia}

      {showModalChuyenDT}
      {showModalTroNgaiKS}
      {showModalInBienBanApGia}
      {showModalInBieuDoPhuTai}
      {showModalChuyenAG}
    </Fragment>
  );
}
