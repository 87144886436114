import { Button, Col, Form, Input, Row, Tooltip } from "antd";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import FormComponent from "@components/Form";

export default function FormBoLoc(props) {
  const { form, handleSearch, tooltip, clear } = props;
  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      autoComplete="off"
    >
      <Row gutter={24} justify="space-between" align="middle">
        <Col span={24} md={10}>
          <Form.Item name="searchTerm" label="Tìm kiếm dữ liệu">
            <Input
              prefix={<SearchOutlined />}
              suffix={
                <Tooltip title={tooltip}>
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={14} style={{ textAlign: "right" }}>
          <Button className="mr-24px" onClick={clear}>
            Bỏ lọc
          </Button>
          <Button type="primary" htmlType="submit" form="filter-form">
            Tìm kiếm
          </Button>
        </Col>
      </Row>
    </FormComponent>
  );
}
