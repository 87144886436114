import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { authSelector } from "@state/auth/reducer";
import { useDispatch, useSelector } from "react-redux";
import Main from "@components/Layout/Main";
import commonRoute from "@routes/common";
import routeCapDien from "@routes/route_cap_dien";
import routeDoDem from "@routes/route_giao_nhan_dien_nang";
import routeQuanTriHeThong from "@routes/route_quan_tri_he_thong";
import routeGhiChiSo from "@routes/route_ghi_chi_so";
import routeApGia from "@routes/route_ap_gia";
import routeTienIchChung from "@routes/route_tien_ich_chung";
import routeBaoCaoKinhDoanh from "@routes/route_bao_cao_kinh_doanh";
import routeChamSocKhachHang from "@routes/route_cham_soc_khach_hang";
import routeTreoThao from "@routes/route_treo_thao";
import routeTichHopCRM from "@routes/route_tich_hop_CRM";
import routeChiSoCongTo from "@routes/route_ktcs_cong_to_thao";
import routeKiemTraCongTo from "@routes/route_kttb_do_diem";
import routeLoTrinhNhanVien from "@routes/route_lo_trinh_nhan_vien";
import routeDangKyThanhToan from "@routes/route_dang_ky_thanh_toan";
import routeTrungAp from "@routes/route_trung_ap";

import {
  NAVIGATE_DANGNHAP,
  NAVIGATE_TRANGCHU,
  MODULE_DICHVUDIEN,
  MODULE_HETHONG,
  MODULE_TIENICHCHUNG,
  MODULE_BAOCAO,
  MODULE_CHAMSOCKHACHHANG,
  MODULE_GHICHISO,
  MODULE_APGIA,
  MODULE_DODEM,
  NAVIGATE_DEFAULT_PAGE,
  MODULE_TREOTHAOTHIETBI,
  MODULE_TICHHOPCRM,
  MODULE_CHISOCONGTO,
  MODULE_KIEMTRACONGTO,
  MODULE_LOTRINH,
  MODULE_THANHTOAN,
  MODULE_TRUNG_AP,
} from "./utils/constants";
import DefaultPage from "@pages/DefaultPage/DefaultPage";
import { closeModal } from "@state/system-config/reducer";
import {
  lapPhuongAnSelector,
  notSavedPhuongAnCapDien,
  notSavedThongTinCoBan,
  rangeOfChange,
  setCanShowDialogLeavingPACD,
} from "@state/cap-dien/nhan-vien-khao-sat/lap-phuong-an/reducer";
import { yeuCauDuocPhanCongSelector } from "@state/treo-thao/nhan-vien-treo-thao";
import {
  getSaveDataPopup,
  setCanShowDialogLeaving,
} from "@state/treo-thao/nhan-vien-treo-thao/reducer";
import {
  notSavedPhanQuyenDiemDo,
  phanQuyenDiemDoSelector,
  setCanShowDialogLeavingTT,
} from "@state/giao-nhan-dien-nang/reducer";
import { Helmet } from "react-helmet";
import LogoEVNHN from "@assets/images/logo-evn-hn.png";

const dataRouters = [
  {
    route: routeQuanTriHeThong,
    moduleCode: MODULE_HETHONG,
  },
  {
    route: routeCapDien,
    moduleCode: MODULE_DICHVUDIEN,
  },
  {
    route: routeTienIchChung,
    moduleCode: MODULE_TIENICHCHUNG,
  },
  {
    route: routeBaoCaoKinhDoanh,
    moduleCode: MODULE_BAOCAO,
  },
  {
    route: routeChamSocKhachHang,
    moduleCode: MODULE_CHAMSOCKHACHHANG,
  },
  {
    route: routeGhiChiSo,
    moduleCode: MODULE_GHICHISO,
  },
  {
    route: routeApGia,
    moduleCode: MODULE_APGIA,
  },
  {
    route: routeDoDem,
    moduleCode: MODULE_DODEM,
  },
  {
    route: routeTreoThao,
    moduleCode: MODULE_TREOTHAOTHIETBI,
  },
  {
    route: routeTichHopCRM,
    moduleCode: MODULE_TICHHOPCRM,
  },
  { route: routeChiSoCongTo, moduleCode: MODULE_CHISOCONGTO },
  { route: routeKiemTraCongTo, moduleCode: MODULE_KIEMTRACONGTO },
  { route: routeLoTrinhNhanVien, moduleCode: MODULE_LOTRINH },
  { route: routeDangKyThanhToan, moduleCode: MODULE_THANHTOAN },
  { route: routeTrungAp, moduleCode: MODULE_TRUNG_AP },
];
function App() {
  const { isAuthenticated, moduleCode, user, logout } =
    useSelector(authSelector);
  const { phuongAnCapDien, thongTinCoBan } = useSelector(lapPhuongAnSelector);
  const { phanQuyenDiemDo } = useSelector(phanQuyenDiemDoSelector);
  const { saveData } = useSelector(yeuCauDuocPhanCongSelector);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onUnload = (e) => {
    // the method that will be used for both add and remove event
    e.preventDefault();
    dispatch(closeModal());
  };
  useEffect(() => {
    window.addEventListener("beforeunload", onUnload);
    return () => window.removeEventListener("beforeunload", onUnload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoad = (e) => {
    // the method that will be used for both add and remove event
    e.preventDefault();
    dispatch(rangeOfChange(false));
    if (phuongAnCapDien) dispatch(notSavedPhuongAnCapDien(false));
    if (thongTinCoBan) dispatch(notSavedThongTinCoBan(false));
    dispatch(setCanShowDialogLeavingPACD(false));
    if (phanQuyenDiemDo) dispatch(notSavedPhanQuyenDiemDo(false));
    dispatch(setCanShowDialogLeavingTT(false));
    if (saveData) dispatch(getSaveDataPopup(false));
    dispatch(setCanShowDialogLeaving(false));
    dispatch(closeModal());
  };
  useEffect(() => {
    window.addEventListener("load", onLoad);
    return () => window.removeEventListener("load", onLoad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuthenticated && location.pathname !== NAVIGATE_DANGNHAP) {
      navigate(NAVIGATE_DANGNHAP);
    }
  }, [isAuthenticated, location.pathname, navigate]);

  const renderRouters = useCallback(() => {
    return dataRouters.map((dataItem) => {
      return dataItem.route.map(
        ({
          path,
          title,
          component: Component,
          subItems,
          privateRoute,
          permissionCode,
        }) => {
          if (subItems && subItems.length) {
            return subItems.map(
              ({
                subPath,
                subTitle,
                subComponent: Component,
                permissionCode,
                subItems,
              }) => {
                if (subItems && subItems.length) {
                  return subItems.map(
                    ({
                      subPath,
                      subTitle,
                      subComponent: Component,
                      permissionCode,
                    }) => {
                      return (
                        <Route
                          key={`${path}${subPath}`}
                          path={`${path}${subPath}`}
                          element={
                            privateRoute ? (
                              <PrivateRoute
                                isAuthenticated={isAuthenticated}
                                logout={logout}
                                currentPath={location}
                              >
                                <ModuleRoute
                                  moduleCode={moduleCode}
                                  module={dataItem.moduleCode}
                                  permissionCode={permissionCode}
                                  user={user}
                                >
                                  <Main
                                    title={subTitle}
                                    parentTitle={title}
                                    moduleCode={moduleCode}
                                  >
                                    <Component />
                                  </Main>
                                </ModuleRoute>
                              </PrivateRoute>
                            ) : (
                              <ModuleRoute
                                moduleCode={moduleCode}
                                module={dataItem.moduleCode}
                                permissionCode={permissionCode}
                                user={user}
                              >
                                <Main
                                  title={subTitle}
                                  parentTitle={title}
                                  moduleCode={moduleCode}
                                >
                                  <Component />
                                </Main>
                              </ModuleRoute>
                            )
                          }
                        />
                      );
                    }
                  );
                }
                return (
                  <Route
                    key={`${path}${subPath}`}
                    path={`${path}${subPath}`}
                    element={
                      privateRoute ? (
                        <PrivateRoute
                          isAuthenticated={isAuthenticated}
                          logout={logout}
                          currentPath={location}
                        >
                          <ModuleRoute
                            moduleCode={moduleCode}
                            module={dataItem.moduleCode}
                            permissionCode={permissionCode}
                            user={user}
                          >
                            <Main
                              title={subTitle}
                              parentTitle={title}
                              moduleCode={moduleCode}
                            >
                              <Component />
                            </Main>
                          </ModuleRoute>
                        </PrivateRoute>
                      ) : (
                        <ModuleRoute
                          moduleCode={moduleCode}
                          module={dataItem.moduleCode}
                          permissionCode={permissionCode}
                          user={user}
                        >
                          <Main
                            title={subTitle}
                            parentTitle={title}
                            moduleCode={moduleCode}
                          >
                            <Component />
                          </Main>
                        </ModuleRoute>
                      )
                    }
                  />
                );
              }
            );
          }
          return (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRoute
                  isAuthenticated={isAuthenticated}
                  logout={logout}
                  currentPath={location}
                >
                  <ModuleRoute
                    moduleCode={moduleCode}
                    module={dataItem.moduleCode}
                    permissionCode={permissionCode}
                    user={user}
                  >
                    <Main title={title} moduleCode={moduleCode}>
                      <Component />
                    </Main>
                  </ModuleRoute>
                </PrivateRoute>
              }
            />
          );
        }
      );
    });
  }, [isAuthenticated, location, logout, moduleCode, user]);
  return (
    <MainWrapper>
      <Helmet>
      <meta name="twitter:image" content={`${LogoEVNHN}`} />
      <meta property="og:image" content={`${LogoEVNHN}`} />
      </Helmet>
      <Routes>
        <Route
          path="*"
          element={
            isAuthenticated ? (
              <Navigate to={NAVIGATE_TRANGCHU} />
            ) : (
              <Navigate to={NAVIGATE_DANGNHAP} />
            )
          }
        />
        <Route
          path={NAVIGATE_DEFAULT_PAGE}
          key={NAVIGATE_DEFAULT_PAGE}
          element={
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              logout={logout}
              currentPath={location}
            >
              <Main title={""} moduleCode={moduleCode}>
                <DefaultPage />
              </Main>
            </PrivateRoute>
          }
        />
        {commonRoute.map(({ path, component: Component, privateRoute }) => {
          return (
            <Route
              path={path}
              key={path}
              element={
                privateRoute ? (
                  <PrivateRoute
                    isAuthenticated={isAuthenticated}
                    logout={logout}
                    currentPath={location}
                  >
                    <Component />
                  </PrivateRoute>
                ) : (
                  <Component />
                )
              }
            />
          );
        })}
        {renderRouters()}
      </Routes>
    </MainWrapper>
  );
}

function PrivateRoute({ children, isAuthenticated, currentPath, logout }) {
  if (!isAuthenticated) {
    // localStorage.setItem("previousAccessLink", currentPath.pathname);
    localStorage.setItem("previousSearchQuery", currentPath.search);
  }
  if (logout) localStorage.setItem("previousAccessLink", "");
  else localStorage.setItem("previousAccessLink", currentPath.pathname);
  return isAuthenticated ? children : <Navigate to="/dang-nhap" />;
}
function ModuleRoute({ children, moduleCode, module, permissionCode, user }) {
  // if (user && user.permission && user.permission.length) {
  if (
    user.permission?.find((item) => item.includes(permissionCode)) ||
    user.isAdministrator ||
    moduleCode === module
  ) {
    return children;
  } else {
    return <Navigate to="/trang-chu" />;
  }
  // }
  // return moduleCode === module ? children : <Navigate to="/trang-chu" />;
}

const MainWrapper = styled.div`
  height: 100vh;
`;

export default App;
