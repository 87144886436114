import LoadingComponent from "@components/Loading";
import { Button, Col, Modal, Row } from "antd";

export default function ChuyenApGiaModal(props) {
  const { t, loading, visible, handleCloseModal, handleSubmit } = props;
  return (
    <LoadingComponent loading={loading}>
      <Modal
        title={t("ap_gia_ho_so.xac_nhan_chuyen_ap_gia")}
        visible={visible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <h4 className="fs-14px" style={{ color: "#1F1F1F" }}>
          Hồ sơ của bạn sẽ được chuyển sang chức năng áp giá, bạn thực sự muốn
          điều đó?
        </h4>
        <Row gutter={24} justify="center" style={{ marginTop: "48px" }}>
          <Col span={12} style={{ textAlign: "right" }}>
            <Button
              key="back"
              onClick={handleCloseModal}
              className="button-closed"
            >
              {t("button.dong_lai")}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              key="submit"
              type="primary"
              className="button-primary"
              htmlType="submit"
              onClick={handleSubmit}
            >
              {t("button.xac_nhan")}
            </Button>
          </Col>
        </Row>
      </Modal>
    </LoadingComponent>
  );
}
