import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import FormDaDangKy from "./form/form-da-dang-ky";

export default function CSDaDangKyPanels(props) {
  const { data } = props;
  const { t } = useTranslation();
  const [dataTable, setDataTable] = useState([]);
  const [dataChart, setDataChart] = useState([]);

  useEffect(() => {
    setDataTable([
      {
        id: 1,
        tenTruong: "Công suất sử dụng trung bình ngày Ptb",
        giaTri: data?.congSuatSuDungTrungBinhNgay,
        donVi: "kW",
      },
      {
        id: 2,
        tenTruong: "Công suất sử dụng lớn nhất trong ngày Pmax",
        giaTri: data?.congSuatSuDungLonNhatTrongNgay,
        donVi: "kW",
      },
      {
        id: 3,
        tenTruong: "Công suất sử dụng nhỏ nhất trong ngày Pmin",
        giaTri: data?.congSuatSuDungNhoNhatTrongNgay,
        donVi: "kW",
      },
      {
        id: 4,
        tenTruong: "Lượng điện năng sử dụng trung bình tháng Atb",
        giaTri: data?.luongDienSuDungTrungBinhThang,
        donVi: "kWh/tháng",
      },
      {
        id: 5,
        tenTruong:
          "Công suất sử dụng lớn nhất trong giờ cao điểm ngày Pcđn (9h30-11h30)",
        giaTri: data?.congSuatLonNhatGioCaoDiemNgay,
        donVi: "kW",
      },
      {
        id: 6,
        tenTruong:
          "Công suất sử dụng lớn nhất trong giờ cao điểm tối Pcđt (17h00-20h00)",
        giaTri: data?.congSuatLonNhatGioCaoDiemToi,
        donVi: "kW",
      },
    ]);
    if (
      data.duLieuBieuDoCongSuat &&
      Object.keys(data.duLieuBieuDoCongSuat).length > 0
    ) {
      if (
        Array.isArray(data.duLieuBieuDoCongSuat.xValues) &&
        Array.isArray(data.duLieuBieuDoCongSuat.yValues?.data)
      ) {
        const dataChartRaw = data.duLieuBieuDoCongSuat.xValues
          .map((rs, index) => {
            return {
              x: `${rs}`,
              y: data.duLieuBieuDoCongSuat.yValues.data[index],
              type: "Công suất",
            };
          })
          .filter(Boolean);
        setDataChart(dataChartRaw);
      }
    }
  }, [data]);
  //Khai báo columns

  const columns = [
    {
      title: t("lap_phuong_an.ten_truong"),
      dataIndex: "tenTruong",
      key: "tenTruong",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "25%",
    },
    {
      title: t("lap_phuong_an.gia_tri"),
      dataIndex: "giaTri",
      key: "giaTri",
      render: (text) => (
        <div className="table-text-right">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: t("lap_phuong_an.don_vi_tinh"),
      dataIndex: "donVi",
      key: "donVi",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
  ];

  const config = {
    data: dataChart,
    xField: "x",
    yField: "y",
    seriesField: "type",
    legend: {
      position: "top-right",
      offsetX: 8,
      itemName: {
        style: {
          fill: "black",
          fontWeight: 900,
        },
      },
      marker: (text, index, item) => {
        const color = item.style.fill;
        return {
          style: {
            color,
            r: 18,
          },
        };
      },
    },
    label: {
      position: "middle", // 'top', 'bottom', 'middle'
    },
    point: {
      size: 5,
      shape: "custom-point",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [
      {
        type: "custom-marker-interaction",
      },
    ],
  };
  return (
    <FormDaDangKy
      t={t}
      data={data}
      config={config}
      columns={columns}
      dataTable={dataTable}
    />
  );
}
