import { Endpoint } from "@utils/endpoint";
import { authGetData, authGetMultiplesRequest } from "@utils/request";
import { buildQueryString } from "@utils/function";

export const handleListBaoCao = (
  filterConditions,
  setLoading,
  setData,
  setTotalCount
) => {
  authGetData({
    url: `${Endpoint.GET_LIST_BAO_CAO_SO_PHA}?${buildQueryString(
      filterConditions
    )}`,
    setLoading,
    onSuccess: (res) => {
      if (res.data !== null) {
        setData(res.data);
        setTotalCount(res.paging.totalCount);
      }
    },
  });
};

export const handleMultiBoLoc = (
  setLoading,
  setLoaiYeuCau,
  setMucDich,
  setHinhThucLapDat
) => {
  const endpoints = [
    `${Endpoint.COMMON_LIST_LOAI_YEU_CAU}`,
    `${Endpoint.COMMON_LIST_MUC_DICH}`,
    `${Endpoint.COMMON_LIST_HINH_THUC_LAP_DAT}`,
  ];
  authGetMultiplesRequest({
    endpoints,
    setLoading,
    onSuccess: (res) => {
      setLoaiYeuCau(res[0].data.data);
      setMucDich(res[1].data.data);
      setHinhThucLapDat(res[2].data.data);
    },
  });
};
