import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";

import { Button } from "antd";

import ImeiModal from "./modal-lmei";
import { useTranslation } from "react-i18next";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { useLocation, useSearchParams } from "react-router-dom";
import PencilIcon from "@assets/icon/PencilIcon";
import { tien_ich_chung } from "@permissions/m_tienichchung";
import CheckPermission from "@components/CheckPermission";
import TrashIcon from "@assets/icon/TrashIcon";
import {
  handleListData,
  handleListNguoiDung,
  handleRemoveData,
} from "./services";
import Table from "./table";

export default function Index() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [detailImei, setDetailImei] = useState({});
  const [dataImei, setDataImei] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const [dataUser, setDataUser] = useState([]);
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  //fetch dữ liệu
  const getListImei = useCallback(() => {
    handleListData(setLoading, filterConditions, setDataImei, setTotalCount);
  }, [filterConditions]);

  useEffect(() => {
    getListImei();
    // eslint-disable-next-line
  }, [getListImei]);

  useEffect(() => {
    handleListNguoiDung(setLoading, setDataUser);
  }, []);

  //thay đổi pagination table

  const onChangePagination = (paging, filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };

  //hiển thị các button thêm,...

  const genExtra = () => (
    <div>
      <CheckPermission permissionCode={tien_ich_chung.m14t}>
        <Button
          size="small"
          type="primary"
          className="mr-5px"
          onClick={() => handleOpenModal()}
        >
          {t("button.tao_moi")}
        </Button>
      </CheckPermission>
    </div>
  );

  //khai bái columns

  const columns = [
    {
      title: t("imei.ma_imei"),
      dataIndex: "maImei",
      key: "maImei",
      sorter: true,
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: t("imei.loai_may"),
      dataIndex: "loaiMay",
      key: "loaiMay",
      sorter: true,
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "20%",
    },
    {
      title: t("imei.ngay_cap"),
      dataIndex: "ngayCap",
      key: "ngayCap",
      sorter: true,
      render: (text, record) => (
        <div className="table-text-right">
          <span>{record.ngayCapStr}</span>
        </div>
      ),
      width: "10%",
    },
    {
      title: t("imei.nguoi_cap"),
      dataIndex: "tenNguoiCap",
      key: "tenNguoiCap",
      sorter: true,
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "25%",
    },
    {
      title: t("imei.nguoi_dung"),
      dataIndex: "nguoiDung",
      key: "nguoiDung",
      sorter: true,
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: t("tac_vu"),
      key: "action",
      // width: "10%",
      fixed: "right",
      render: (row) => (
        <div className="action-table-column">
          <CheckPermission permissionCode={tien_ich_chung.m14s}>
            <div className="action-icon" onClick={() => handleOpenModal(row)}>
              <PencilIcon />
            </div>
          </CheckPermission>
          <CheckPermission permissionCode={tien_ich_chung.m14x}>
            <div
              className="action-icon"
              onClick={() => handleDeleteData(row.id)}
            >
              <TrashIcon />
            </div>
          </CheckPermission>
        </div>
      ),
    },
  ];

  //tìm kiếm dữ liệu

  const handleSearch = useCallback((values) => {
    setFilterConditions((oldState) => ({
      ...oldState,
      pageIndex: DEFAULT_PAGEINDEX,
      ...values,
    }));
  }, []);
  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  }, []);

  //xóa dữ liệu

  const handleDeleteData = useCallback(
    (ids) => {
      handleRemoveData(ids, setLoading, getListImei, t);
    },
    [getListImei, t]
  );

  //hiển thị popup, set dữ liệu chi tiết

  const handleOpenModal = useCallback(
    (row) => {
      if (row !== undefined) setDetailImei(row);
      else setDetailImei({});
      setVisible(!visible);
    },
    [visible]
  );

  //đóng popup

  const handleCloseModal = () => {
    setVisible(false);
    setLoading(false);
  };

  //render popup

  const showModal = useMemo(
    () =>
      visible && (
        <ImeiModal
          detailImei={detailImei}
          visible={visible}
          closeModal={handleCloseModal}
          getListImei={getListImei}
          dataUser={dataUser}
        />
      ),
    // eslint-disable-next-line
    [visible]
  );

  return (
    <Fragment>
      <Table
        handleSearch={handleSearch}
        clearFilter={clearFilter}
        filterConditions={filterConditions}
        genExtra={genExtra}
        dataSource={dataImei}
        columns={columns}
        totalCount={totalCount}
        loading={loading}
        onChangePagination={onChangePagination}
        t={t}
      />
      {showModal}
    </Fragment>
  );
}
