export const KH_SUDUNG_APP_CSKH = [
  {
    title: "Mã đơn vị",
    dataIndex: "mA_DVIQLY",
    align: "center",
    key: "mA_DVIQLY",
  },
  {
    title: "Mã khách hàng",
    align: "center",
    dataIndex: "mA_KHANG",
    key: "mA_KHANG",
  },
  {
    title: "Tên khách hàng",
    dataIndex: "teN_KHANG",
    key: "teN_KHANG",
  },
  {
    title: "Địa chỉ",
    dataIndex: "diA_CHI",
    key: "diA_CHI",
  },
  {
    title: "Số GCS",
    align: "center",
    dataIndex: "mA_SOGCS",
    key: "mA_SOGCS",
  },
];

export const KH_DANGKI_SUDUNG_EMAIL = [
  {
    title: "Mã đơn vị",
    dataIndex: "mA_DVIQLY",
    align: "center",
    key: "mA_DVIQLY",
  },
  {
    title: "Mã khách hàng",
    align: "center",
    dataIndex: "mA_KHANG",
    key: "mA_KHANG",
  },
  {
    title: "Tên khách hàng",
    dataIndex: "teN_KH_LHE",
    key: "teN_KH_LHE",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Địa chỉ",
    dataIndex: "dchI_KH_LHE",
    key: "dchI_KH_LHE",
  },
  {
    title: "Số GCS",
    align: "center",
    dataIndex: "mA_SOGCS",
    key: "mA_SOGCS",
  },
];

export const KH_DANGKI_SUDUNG_ZALO = [
  {
    title: "Mã đơn vị",
    dataIndex: "mA_DVIQLY",
    align: "center",
    key: "mA_DVIQLY",
  },
  {
    title: "Mã khách hàng",
    align: "center",
    dataIndex: "mA_KHANG",
    key: "mA_KHANG",
  },
  {
    title: "Tên khách hàng",
    dataIndex: "teN_KHANG",
    key: "teN_KHANG",
  },
  {
    title: "Số điện thoại",
    dataIndex: "sO_DTHOAI",
    key: "sO_DTHOAI",
  },
  {
    title: "Địa chỉ",
    dataIndex: "duonG_PHO",
    key: "duonG_PHO",
  },
  {
    title: "Số GCS",
    align: "center",
    dataIndex: "mA_SOGCS",
    key: "mA_SOGCS",
  },
];

export const PHAN_LOAI_TIN_NHAN_SMS_TIEN_DIEN_LAN_1 = 0
export const PHAN_LOAI_TIN_NHAN_SMS_DICH_VU_KHAC = 1
export const PHAN_LOAI_DICH_VU_DIEN_TU = 2
export const PHAN_LOAI_CONG_TIN_NHAN_CSKH = 3

export const HINH_THUC_SMS = 1
export const HINH_THUC_EMAIL = 2
export const HINH_THUC_ZALO = 3


