import { Table, ConfigProvider, Col } from "antd";
import vn from "antd/es/locale-provider/vi_VN";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import { handleListTyLeGiaBanDien } from "../../../services";
import LoadingComponent from "@components/Loading";

export default function TyLeGiaBanDienTabs({ detaiHoSo }) {
  const [dataGiaBanDien, setDataGiaBanDien] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    handleListTyLeGiaBanDien(detaiHoSo, setLoading, setDataGiaBanDien);
  }, [detaiHoSo]);

  //khai bái columns

  const columns = [
    {
      title: t("lap_phuong_an.thiet_bi.muc_dich_su_dung"),
      dataIndex: "mucDichSuDung",
      key: "mucDichSuDung",
      render: (text) => (
        <div className="table-text-left">
          <span>{text}</span>
        </div>
      ),
      width: 250,
    },
    {
      title: t("ap_gia_ho_so.ti_le_%_hoac_kwh"),
      dataIndex: "tiLePhanTram",
      key: "tiLePhanTram",
      render: (text, record) => (
        <div className="table-text-right">
          <span>
            {record.tiLePhanTram +
              " " +
              (record.isPhanTram === 0 ? "kWh" : "%")}
          </span>
        </div>
      ),
      width: 100,
    },
    {
      title: t("ap_gia_ho_so.gia_ban_dien_chua_thue"),
      children: [
        {
          title: "Không theo thời gian",
          dataIndex: "gioKT",
          key: "gioKT",
          render: (text) => (
            <div className="table-text-right">
              <span>{text}</span>
            </div>
          ),
          width: 130,
        },
        {
          title: "Theo thời gian",
          children: [
            {
              title: t("ap_gia_ho_so.gio_binh_thuong"),
              dataIndex: "gioBinhThuong",
              key: "gioBinhThuong",
              render: (text) => (
                <div className="table-text-right">
                  <span>{text}</span>
                </div>
              ),
              width: 100,
            },
            {
              title: t("ap_gia_ho_so.gio_cao_diem"),
              dataIndex: "gioCaoDiem",
              key: "gioCaoDiem",
              render: (text) => (
                <div className="table-text-right">
                  <span>{text}</span>
                </div>
              ),
              width: 100,
            },
            {
              title: t("ap_gia_ho_so.gio_thap_diem"),
              dataIndex: "gioThapDiem",
              key: "gioThapDiem",
              render: (text) => (
                <div className="table-text-right">
                  <span>{text}</span>
                </div>
              ),
              width: 100,
            },
          ],
          width: 300,
        },
      ],
    },
  ];

  return (
    <Fragment>
      <Col span={24} md={20}>
        <span className="fs-16px font-bold">6. Tỷ lệ giá bán điện</span>
      </Col>
      <Col className="mt-16px">
        <LoadingComponent loading={loading}>
          <ConfigProvider locale={vn}>
            <Table
              dataSource={dataGiaBanDien}
              columns={columns}
              pagination={false}
              scroll={{ y: "500px" }}
            />
          </ConfigProvider>
        </LoadingComponent>
      </Col>
    </Fragment>
  );
}
