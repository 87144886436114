import { Collapse } from "antd";
import Layout from "./layout";

const { Panel } = Collapse;

export default function Index(props) {
  const { detail } = props;
  const columnTIHienTai = [
    {
      title: "Mã TI",
      dataIndex: "ma",
      key: "ma",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "20%",
    },
    {
      title: "Cháy/Hỏng",
      dataIndex: "chayHong",
      key: "chayHong",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Số pha",
      dataIndex: "soPha",
      key: "soPha",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "10%",
    },
    {
      title: "Tỷ số đấu",
      dataIndex: "tySoDau",
      key: "tySoDau",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "15%",
    },
    {
      title: "Ngày kiểm định",
      dataIndex: "ngayKiemDinh",
      key: "ngayKiemDinh",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "15%",
    },
    {
      title: "Tem kiểm định",
      dataIndex: "temKiemDinh",
      key: "temKiemDinh",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Mã chì tem kiểm định",
      dataIndex: "maChiTemKD",
      key: "maChiTemKD",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "10%",
    },
    {
      title: "Số viên chì kiểm định",
      dataIndex: "soVienChiKD",
      key: "soVienChiKD",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "10%",
    },
  ];

  const columnTITreo = [
    {
      title: "Mã TI",
      dataIndex: "ma",
      key: "ma",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "20%",
    },
    {
      title: "Số pha",
      dataIndex: "soPha",
      key: "soPha",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "10%",
    },
    {
      title: "Tỷ số đấu",
      dataIndex: "tySoDau",
      key: "tySoDau",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
    },
    {
      title: "Ngày kiểm định",
      dataIndex: "ngayKiemDinh",
      key: "ngayKiemDinh",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "20%",
    },
    {
      title: "Tem kiểm định",
      dataIndex: "temKiemDinh",
      key: "temKiemDinh",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Mã chì tem kiểm định",
      dataIndex: "maChiTemKD",
      key: "maChiTemKD",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "15%",
    },
    {
      title: "Số viên chì kiểm định",
      dataIndex: "soVienChiKD",
      key: "soVienChiKD",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "10%",
    },
  ];

  const columnTUHienTai = [
    {
      title: "Mã TU",
      dataIndex: "ma",
      key: "ma",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "20%",
    },
    {
      title: "Cháy/Hỏng",
      dataIndex: "chayHong",
      key: "chayHong",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Số pha",
      dataIndex: "soPha",
      key: "soPha",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "10%",
    },
    {
      title: "Tỷ số đấu",
      dataIndex: "tySoDau",
      key: "tySoDau",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "15%",
    },
    {
      title: "Ngày kiểm định",
      dataIndex: "ngayKiemDinh",
      key: "ngayKiemDinh",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "15%",
    },
    {
      title: "Tem kiểm định",
      dataIndex: "temKiemDinh",
      key: "temKiemDinh",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Mã chì tem kiểm định",
      dataIndex: "maChiTemKD",
      key: "maChiTemKD",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "10%",
    },
    {
      title: "Số viên chì kiểm định",
      dataIndex: "soVienChiKD",
      key: "soVienChiKD",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "10%",
    },
  ];

  const columnTUTreo = [
    {
      title: "Mã TU",
      dataIndex: "ma",
      key: "ma",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "20%",
    },
    {
      title: "Số pha",
      dataIndex: "soPha",
      key: "soPha",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "10%",
    },
    {
      title: "Tỷ số đấu",
      dataIndex: "tySoDau",
      key: "tySoDau",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "15%",
    },
    {
      title: "Ngày kiểm định",
      dataIndex: "ngayKiemDinh",
      key: "ngayKiemDinh",
      render: (text) => <div className="table-text-center">{text}</div>,
      width: "20%",
    },
    {
      title: "Tem kiểm định",
      dataIndex: "temKiemDinh",
      key: "temKiemDinh",
      render: (text) => <div className="table-text-left">{text}</div>,
      width: "10%",
    },
    {
      title: "Mã chì tem kiểm định",
      dataIndex: "maChiTemKD",
      key: "maChiTemKD",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "15%",
    },
    {
      title: "Số viên chì kiểm định",
      dataIndex: "soVienChiKD",
      key: "soVienChiKD",
      render: (text) => <div className="table-text-right">{text}</div>,
      width: "10%",
    },
  ];

  return (
    <Collapse defaultActiveKey={["1", "2", "3", "4"]}>
      <Panel header="TI hiện tại" key={"1"}>
        <Layout
          data={
            detail.congToThao &&
            detail.congToThao.tIs !== null &&
            detail.congToThao.tIs.length > 0
              ? detail.congToThao.tIs
              : []
          }
          columns={columnTIHienTai}
          isCheck={
            detail.congToThao && detail.congToThao.isThaoTI
              ? detail.congToThao.isThaoTI
              : false
          }
        />
      </Panel>
      <Panel header="TI Treo" key={"2"}>
        <Layout
          data={
            detail.congToTreo &&
            detail.congToTreo.tIs !== null &&
            detail.congToTreo.tIs.length > 0
              ? detail.congToTreo.tIs
              : []
          }
          columns={columnTITreo}
          isNameTreo={true}
          isCheck={
            detail.congToTreo && detail.congToTreo.isThaoTI
              ? detail.congToTreo.isThaoTI
              : false
          }
        />
      </Panel>
      <Panel header="TU hiện tại" key={"3"} showArrow={true}>
        <Layout
          data={
            detail.congToThao &&
            detail.congToThao.tUs !== null &&
            detail.congToThao.tUs.length > 0
              ? detail.congToThao.tUs
              : []
          }
          isTU
          columns={columnTUHienTai}
          isCheck={
            detail.congToThao && detail.congToThao.isThaoTU
              ? detail.congToThao.isThaoTU
              : false
          }
        />
      </Panel>
      <Panel header="TU Treo" key={"4"}>
        <Layout
          data={
            detail.congToTreo &&
            detail.congToTreo.tUs !== null &&
            detail.congToTreo.tUs.length > 0
              ? detail.congToTreo.tUs
              : []
          }
          columns={columnTUTreo}
          isNameTreo={true}
          isCheck={
            detail.congToTreo && detail.congToTreo.isThaoTU
              ? detail.congToTreo.isThaoTU
              : false
          }
        />
      </Panel>
    </Collapse>
  );
}
