import { Endpoint } from "@utils/endpoint";
import { authSelector } from "@state/auth";
import { Col, Form, Input, Row, Select, Tooltip, Button, Checkbox } from "antd";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import Selection from "@components/Select";
import FormComponent from "@components/Form";
import DatePickerComponent from "@components/DatePicker";
import moment from "moment";
import {
  FORMAT_DATE,
  FORMAT_ENGLISH,
  MONTH_STRING,
  AP_GIA_TRANG_THAI_BK,
} from "@utils/constants";
const { Option } = Select;

export function ListFilterBK(props) {
  const {
    handleSearch,
    clearFilter,
    filterConditions,
    tooltip,
    checkTraLai,
    filterScreen,
    // traLai
  } = props;
  const { user } = useSelector(authSelector);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(filterConditions);
  }, [form, filterConditions]);
  const clear = useCallback(() => {
    form.resetFields();
    clearFilter();
  }, [form, clearFilter]);

  return (
    <FormComponent
      form={form}
      name="filter-form"
      onFinish={handleSearch}
      layout="vertical"
      initialValues={{
        DonViId: user.unitId.toString(),
        // TenNhanVien: filterScreen === "NVAG" ? user.id : null
      }}
    >
      <Row gutter={24}>
        <Col span={24} md={6}>
          <Form.Item name="DonViId" label="Đơn vị">
            {!user.isTongCongTy ? (
              <Select defaultValue={user.unitId} disabled>
                <Option value={user.unitId}>{user.unitName}</Option>
              </Select>
            ) : (
              <Selection
                url={Endpoint.GET_UNIT_LIST}
                form={form}
                formKey="DonViId"
                notClear={true}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.DonViId !== currentValues.DonViId) {
                form.setFieldsValue({
                  DoiId: undefined,
                });
              }
              return prevValues.DonViId !== currentValues.DonViId;
            }}
          >
            {({ getFieldValue }) => {
              if (getFieldValue("DonViId")) {
                return (
                  <Form.Item name="DoiId" label="Tổ đội">
                    <Selection
                      url={`${Endpoint.GET_TEAM_LIST}?donviId=${getFieldValue(
                        "DonViId"
                      )}`}
                      formKey="DoiId"
                      form={form}
                    />
                  </Form.Item>
                );
              }
              return (
                <Form.Item name="DoiId" label="Tổ đội">
                  <Selection
                    url={`${Endpoint.GET_TEAM_LIST}?donviId=${user.unitId}`}
                    formKey="DoiId"
                    form={form}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="LoaiBangKe" label="Loại bảng kê">
            <Select placeholder={"Tất cả"} allowClear>
              <Option value={"Kiểm tra định mức"}>Kiểm tra định mức</Option>
              <Option value={"Kiểm tra áp giá"}>Kiểm tra áp giá</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          {filterScreen === "NVAG" && !user.isTongCongTy ? (
            <Form.Item name="UserId" label="Nhân viên lập bảng kê">
              <Select defaultValue={user.id} disabled>
                <Option value={user.id}>{user.name}</Option>
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                if (prevValues.DonViId !== currentValues.DonViId || prevValues.DoiId !== currentValues.DoiId) {
                  form.setFieldsValue({
                    UserId: undefined,
                  });
                }
                return prevValues.DonViId !== currentValues.DonViId;
              }}
            >
              {({ getFieldValue }) => {
                if (getFieldValue("DonViId") && getFieldValue("DoiId") ) {
                  return (
                    <Form.Item name="UserId" label="Nhân viên lập bảng kê">
                      <Selection
                        url={`${
                          Endpoint.AG_LIST_NV_PHAN_CONG
                        }?DonViId=${getFieldValue("DonViId")}&DoiId=${getFieldValue("DoiId")}`}
                        formKey="UserId"
                        form={form}
                      />
                    </Form.Item>
                  );
                }
                return (
                  <Form.Item name="UserId" label="Nhân viên lập bảng kê">
                    <Selection
                      url={`${Endpoint.AG_LIST_NV_PHAN_CONG}?DonViId=${user.unitId}`}
                      formKey="UserId"
                      form={form}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          )}
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="TrangThai" label="Trạng thái">
            {filterScreen === "BPAG" ? (
              <Select
                placeholder={"Tất cả"}
                allowClear
                defaultValue={AP_GIA_TRANG_THAI_BK.CHO_BPAG_DUYET}
              >
                <Option value={AP_GIA_TRANG_THAI_BK.CHO_BPAG_DUYET}>
                  Chưa ký
                </Option>
                <Option value={AP_GIA_TRANG_THAI_BK.BPAG_DA_DUYET}>
                  Đã ký
                </Option>
              </Select>
            ) : filterScreen === "NVAG" ? (
              <Select
                placeholder={"Tất cả"}
                allowClear
                defaultValue={AP_GIA_TRANG_THAI_BK.CHO_NVAG_DUYET}
              >
                <Option value={AP_GIA_TRANG_THAI_BK.CHO_NVAG_DUYET}>
                  Chưa xác nhận
                </Option>
                <Option value={AP_GIA_TRANG_THAI_BK.CHO_BPAG_DUYET}>
                  Đã xác nhận
                </Option>
              </Select>
            ) : (
              <Select placeholder={"Tất cả"} allowClear>
                <Option value={AP_GIA_TRANG_THAI_BK.CHO_NVAG_DUYET}>
                  Chờ NV áp giá duyệt
                </Option>
                <Option value={AP_GIA_TRANG_THAI_BK.CHO_BPAG_DUYET}>
                  Chờ bộ phập áp giá duyệt
                </Option>
                <Option value={AP_GIA_TRANG_THAI_BK.BPAG_DA_DUYET}>
                  Bộ phập áp giá đã duyệt
                </Option>
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="NgayBatDau" label="Thời gian tạo">
            <DatePickerComponent
              defaultValue={
                filterConditions.NgayBatDau
                  ? moment(filterConditions.NgayBatDau, FORMAT_ENGLISH)
                  : moment(moment().startOf(MONTH_STRING), FORMAT_DATE)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"NgayBatDau"}
              allowClear={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={4}>
          <Form.Item name="NgayKetThuc" label=" ">
            <DatePickerComponent
              defaultValue={
                filterConditions.NgayKetThuc
                  ? moment(filterConditions.NgayKetThuc, FORMAT_ENGLISH)
                  : moment(moment(), FORMAT_DATE)
              }
              format={FORMAT_ENGLISH}
              form={form}
              formKey={"NgayKetThuc"}
              allowClear={true}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={6}>
          <Form.Item name="MaBangKe" label="Mã bảng kê">
            <Input suffix={<Tooltip title={tooltip}></Tooltip>} />
          </Form.Item>
        </Col>
        <Col span={24} md={3} style={{ display: "flex", alignItems: "center" }}>
          <Form.Item name="TraLai" style={{ margin: 0 }}>
            <Checkbox
              onChange={checkTraLai}
              defaultChecked={filterConditions.TraLai ? true : false}
              // value={traLai}
            >
              Bị trả lại
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Button className="mr-24px" onClick={clear}>
          Bỏ lọc
        </Button>
        <Button type="primary" htmlType="submit" form="filter-form">
          Tìm kiếm
        </Button>
      </Row>
    </FormComponent>
  );
}
