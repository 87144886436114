export const TABLE_THOA_THUAN_TRUNG_AP = [
  {
    title: "STT",
    dataIndex: "STT",
    align: "center",
    key: "STT",
    width: "4%",
  },
  {
    title: "Mã yêu cầu",
    dataIndex: "maYeuCau",
    key: "maYeuCau",
    align: "center",
  },
  {
    title: "Bên gửi",
    align: "left",
    dataIndex: "tenKhachHang",
    key: "tenKhachHang",
  },

  {
    title: "Công trình điện",
    align: "left",
    dataIndex: "duAnDien",
    key: "duAnDien",
  },
  {
    title: "Địa chỉ xây dựng",
    dataIndex: "diaChiDungDien",
    key: "diaChiDungDien",
    align: "left",
    width: "20%",
  },
  {
    title: "Bên nhận",
    dataIndex: "benNhan",
    key: "benNhan",
    width: "15%",

  },
  {
    title: "Ngày lập",
    align: "center",
    key: "ngayLap",
    dataIndex: "ngayLap",
    width: "10%",

  },
  {
    title: "Trạng thái",
    dataIndex: "trangThai",
    align: "center",
    key: "trangThai",
  },
];

export const TABLE_THOA_KIEM_TRA_DIEU_KIEN = [
  {
    title: "STT",
    dataIndex: "STT",
    align: "center",
    key: "STT",
    width: "4%",
  },
  {
    title: "Mã yêu cầu",
    dataIndex: "maYeuCau",
    key: "maYeuCau",
    align: "center",
    width: "10%",
  },
  {
    title: "Số thỏa thuận",
    align: "left",
    dataIndex: "soThoaThuanDN",
    key: "soThoaThuanDN",
    width: "14%",
  },

  {
    title: "Người yêu cầu",
    align: "left",
    dataIndex: "nguoiYeuCau",
    key: "nguoiYeuCau",
    width: "10%",
  },
  {
    title: "Chủ đầu tư",
    dataIndex: "duAnDien",
    align: "left",
    key: "duAnDien",
  },
  {
    title: "Công trình điện",
    dataIndex: "diaChiDungDien",
    align: "left",
    key: "diaChiDungDien",
    width: "16%",
  },
  {
    title: "Bên nhận",
    dataIndex: "coQuanChuQuan",
    align: "left",
    key: "coQuanChuQuan",
  },
  {
    title: "Ngày lập",
    align: "center",
    key: "NgayLap",
    dataIndex: "ngayYeuCau",
    width: "10%",
    // render: (_, record) => {
    //   if (record.NgayLap) {
    //     return <>{moment(record.NgayLap).format("DD-MM-YYYY")}</>;
    //   }
    // },
  },
  {
    title: "Trạng thái",
    dataIndex: "trangThai",
    align: "center",
    key: "trangThai",
    width: "10%",
  },
];
