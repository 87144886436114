import Filter from "@components/Filter/Filter";
import FormComponent from "@components/Form";
import Selection from "@components/Select";
import TableComponent from "@components/TableComponent";
import { authSelector } from "@state/auth";
import { DEFAULT_PAGEINDEX, DEFAULT_PAGESIZE } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import {
  handlePagination,
  parseParams,
  removeUndefinedAttribute,
} from "@utils/function";
import { Button, Col, DatePicker, Form, Input, Row, Spin } from "antd";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { handleDownload, handleListData } from "./service";
export default function TongHopHoSoDangKy() {
  const location = useLocation();
  const user = useSelector(authSelector);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [, setSearchParams] = useSearchParams();
  const [totalData, setTotalData] = useState();
  const [filterConditions, setFilterConditions] = useState({
    pageIndex: DEFAULT_PAGEINDEX,
    pageSize: DEFAULT_PAGESIZE,
    maDonVi: user.user.unitCode,
    tuNgay: moment().startOf("month").format("YYYY/MM/DD"),
    denNgay: moment().format("YYYY/MM/DD"),
    ...parseParams(location.search),
  });

  useLayoutEffect(() => {
    setSearchParams(removeUndefinedAttribute(filterConditions));
  }, [setSearchParams, filterConditions]);

  const onChangePagination = (paging, _filters, sorter) => {
    handlePagination(paging, sorter, setFilterConditions);
  };
  const getListData = useCallback(() => {
    handleListData(
      location,
      filterConditions,
      setLoading,
      setData,
      setTotalData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getListData();
  }, [getListData]);

  const onFinish = (values) => {
    setFilterConditions({
      ...values,
      tuNgay: values.tuNgay.format("YYYY/MM/DD"),
      denNgay: values.denNgay.format("YYYY/MM/DD"),
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
    });
  };

  const clearFilter = useCallback(() => {
    setFilterConditions({
      pageIndex: DEFAULT_PAGEINDEX,
      pageSize: DEFAULT_PAGESIZE,
      maDonVi: user.user.unitCode,
      tuNgay: moment().startOf("month").format("YYYY/MM/DD"),
      denNgay: moment().format("YYYY/MM/DD"),
    });
    form.setFieldsValue({
      maDonVi: user.user.unitCode,
      tuNgay: moment().startOf("month"),
      denNgay: moment(),
      keyWord: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      align: "center",
      key: "STT",
      width: "6%",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "tenKhachHang",
      key: "tenKhachHang",
      width: "15%",
      render: (text, record) => (
        <div>{record.tenKhachHang || record.nguoiYeuCau}</div>
      ),
    },
    {
      title: "Mã yêu cầu ",
      dataIndex: "maYeuCau",
      key: "MaYeuCau",
      align: "center",
      width: "10%",
    },
    {
      title: "Điện thoại liên hệ",
      align: "center",
      dataIndex: "dienThoai",
      key: "dienThoai",
      width: "10%",
    },

    {
      title: "Ngày tiếp nhận",
      dataIndex: "ngayYeuCau",
      align: "center",
      key: "ngayYeuCau",
      width: "10%",
    },
    {
      title: "Địa chỉ dùng điện",
      dataIndex: "diaChiDungDien",
      key: "diaChiDungDien",
      width: "16%",
    },
    {
      title: "Đơn vị quản lí khách hàng",
      dataIndex: "maDViQLy",
      align: "center",
      key: "maDViQLy",
    },
    {
      title: "Công suất đăng ký (KVA)",
      dataIndex: "congSuat",
      align: "right",
      key: "CSDangKy",
      width: "10%",
    },
    {
      title: "Hình thức tiếp cận",
      dataIndex: "maHinhThuc",
      align: "center",
      key: "maHinhThuc",
      width: "10%",
    },
  ];

  return (
    <Fragment>
      <Container>
        <Filter>
          <FormComponent
            layout="vertical"
            onFinish={onFinish}
            name="filter-form"
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{
              ...parseParams(location.search),
              maDonVi: parseParams(location.search).maDonVi
                ? parseParams(location.search).maDonVi
                : user.user.unitCode,
              tuNgay: parseParams(location.search).tuNgay
                ? moment(new Date(parseParams(location.search).tuNgay))
                : moment().startOf("month"),
              denNgay: parseParams(location.search).denNgay
                ? moment(new Date(parseParams(location.search).denNgay))
                : moment(),
            }}
          >
            <Row gutter={10}>
              <Col span={6}>
                <Form.Item name="maDonVi" label="Đơn vị" labelAlign="left">
                  <Selection
                    url={Endpoint.GET_TEAM_LIST_CODE}
                    formKey="maDonVi"
                    form={form}
                    placeholder="Chọn đơn vị"
                    allowClear={false}
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item name="keyWord" label="Từ khóa" labelAlign="left">
                  <Input placeholder="Nhập từ khóa" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="tuNgay"
                  label="Từ ngày"
                  labelAlign="left"
                  required
                >
                  <DatePicker
                    picker="date"
                    formKey="tuNgay"
                    form={form}
                    format={"DD/MM/YYYY"}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="denNgay"
                  label="Đến ngày"
                  labelAlign="left"
                  required
                >
                  <DatePicker
                    picker="date"
                    formKey="denNgay"
                    form={form}
                    format={"DD/MM/YYYY"}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={15}></Col>
              <Col span={3}>
                <Form.Item>
                  <Button onClick={clearFilter} className="full">
                    Bỏ lọc
                  </Button>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="full"
                    loading={loading}
                  >
                    Tìm kiếm
                  </Button>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item>
                  <Button
                    type="primary"
                    className="full"
                    onClick={() => {
                      handleDownload(form.getFieldsValue(), setLoading);
                    }}
                  >
                    Xuất excel
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </FormComponent>
        </Filter>
        <Spin spinning={loading}>
          <TableComponent
            header={"Kết quả hiển thị"}
            columns={columns}
            dataSource={data}
            pagination={filterConditions}
            onChangePagination={onChangePagination}
            totalData={totalData}
          />
        </Spin>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  padding: 16px;
  .section-text {
    font-size: 14px;
    color: #a3a3a3;
  }
  .ant-form-item {
    margin: 0 !important;
  }
  .ant-col-24 {
    padding-bottom: 4px;
  }

  .ant-table-thead th.ant-table-cell {
    text-align: center !important;
  }
`;
