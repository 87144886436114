import { STATUSCODE_200 } from "@utils/constants";
import { Endpoint } from "@utils/endpoint";
import { authGetData } from "@utils/request";

export const getThangLamViec = (setThangLamViec, setLoading) => {
  authGetData({
    url: `${Endpoint.GET_THANGLAMVIEC}`,
    setLoading,
    onSuccess: (res) => {
      if (res.statusCode === STATUSCODE_200 && res.data) {
        setThangLamViec(res.data);
      }
    },
  });
};
